import cx from "classnames";

interface Props {
  className?: string;
}

const YoutubeIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={cx(className)}
    >
      <path
        d="M11.6667 10.0007L8.75 11.6673V8.33398L11.6667 10.0007Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66675 10.5903V9.41107C1.66675 6.99828 1.66675 5.79189 2.42132 5.01567C3.17591 4.23944 4.36388 4.20584 6.73982 4.13864C7.86569 4.1068 9.01575 4.08398 10.0001 4.08398C10.9844 4.08398 12.1345 4.1068 13.2603 4.13864C15.6362 4.20584 16.8242 4.23944 17.5788 5.01567C18.3334 5.79189 18.3334 6.99828 18.3334 9.41107V10.5903C18.3334 13.0031 18.3334 14.2095 17.5788 14.9857C16.8242 15.7619 15.6363 15.7955 13.2604 15.8627C12.1345 15.8946 10.9844 15.9174 10.0001 15.9174C9.01575 15.9174 7.86564 15.8946 6.73975 15.8627C4.36384 15.7955 3.17589 15.7619 2.42131 14.9857C1.66675 14.2095 1.66675 13.0031 1.66675 10.5903Z"
        stroke="currentColor"
      />
    </svg>
  );
};

export default YoutubeIcon;
