interface Props {
  className?: string;
}

const BooleanTypeIcon = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" fill="none" className={className}>
    <path
      d="M13.75 0.375H6.25C4.75816 0.375 3.32742 0.967632 2.27252 2.02252C1.21763 3.07742 0.625 4.50816 0.625 6C0.625 7.49184 1.21763 8.92258 2.27252 9.97748C3.32742 11.0324 4.75816 11.625 6.25 11.625H13.75C15.2418 11.625 16.6726 11.0324 17.7275 9.97748C18.7824 8.92258 19.375 7.49184 19.375 6C19.375 4.50816 18.7824 3.07742 17.7275 2.02252C16.6726 0.967632 15.2418 0.375 13.75 0.375ZM13.75 10.375H6.25C5.08968 10.375 3.97688 9.91406 3.15641 9.09359C2.33594 8.27312 1.875 7.16032 1.875 6C1.875 4.83968 2.33594 3.72688 3.15641 2.90641C3.97688 2.08594 5.08968 1.625 6.25 1.625H13.75C14.9103 1.625 16.0231 2.08594 16.8436 2.90641C17.6641 3.72688 18.125 4.83968 18.125 6C18.125 7.16032 17.6641 8.27312 16.8436 9.09359C16.0231 9.91406 14.9103 10.375 13.75 10.375ZM6.25 2.875C5.63193 2.875 5.02775 3.05828 4.51384 3.40166C3.99994 3.74504 3.5994 4.2331 3.36288 4.80411C3.12635 5.37513 3.06447 6.00347 3.18505 6.60966C3.30562 7.21585 3.60325 7.77267 4.04029 8.20971C4.47733 8.64675 5.03415 8.94438 5.64034 9.06495C6.24653 9.18553 6.87487 9.12365 7.44589 8.88712C8.0169 8.6506 8.50496 8.25006 8.84834 7.73616C9.19172 7.22225 9.375 6.61807 9.375 6C9.375 5.1712 9.04576 4.37634 8.45971 3.79029C7.87366 3.20424 7.0788 2.875 6.25 2.875Z"
      fill="currentColor"
    />
  </svg>
);

export default BooleanTypeIcon;
