import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import Tooltip from '../Tooltip';
import { Typography } from '../Typography';

interface Props {
  options: Array<Array<string>>;
  tooltips?: { [key: string]: string };
  currentSelections: string[];
  inputName: string;
  onChange: (values: string[]) => void;
  label?: string;
  helperText?: string | React.ReactNode;
  maxSelections?: number;
  isMoreShowing?: boolean;
  buttonClassName?: string;
}

const MultiSelect = (props: Props) => {
  const {
    options,
    tooltips,
    currentSelections,
    inputName,
    maxSelections,
    label,
    onChange,
    helperText,
    isMoreShowing = true,
    buttonClassName,
  } = props;
  const [isShowingMore, setIsShowingMore] = React.useState(!!isMoreShowing);

  const optionsCount = options.length;
  const hideOptionsCount = optionsCount >= 15;
  let optionsToRender = options;

  if (hideOptionsCount) {
    const offset = 10;
    const someOptions = [...options].slice(0, offset);
    optionsToRender = isShowingMore ? options : someOptions;
  }

  const selections = currentSelections.map((s) => s.toString());

  const toggleSelection = (id: string) => {
    onChange(selections.includes(id) ? selections.filter((s) => s !== id) : selections.concat(id));
  };

  return (
    <div>
      {label && (
        <label htmlFor={inputName} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
      {hideOptionsCount && (
        <div className="flex mt-2 flex-wrap transition-all">
          {!isShowingMore &&
            options.map(([id, value]) => {
              const isSelected = selections.includes(String(id));

              if (isSelected) {
                return (
                  <button
                    key={id}
                    type="button"
                    className={cx(
                      buttonClassName,
                      'flex items-center border rounded text-xs py-1 px-2 relative group disabled:cursor-not-allowed disabled:opacity-50 border-action-secondary-300 text-action-secondary-900  bg-action-secondary-200 mr-2 mb-2'
                    )}
                    onClick={() => toggleSelection(id)}
                  >
                    <Typography token="font-medium/text/sm" as="span" colorWeight="600">
                      {value}
                    </Typography>
                    {isSelected ? (
                      <div className="opacity-0 group-hover:opacity-100 transition duration-200 bg-white shadow-md rounded-full h-4 w-4 absolute -top-2 -right-2 flex items-center justify-center text-gray-500">
                        <XMarkIcon className="h-2 w-2" />
                      </div>
                    ) : (
                      <div className="opacity-0 group-hover:opacity-100 transition duration-200 bg-white shadow-md rounded-full h-4 w-4 absolute -top-2 -right-2 flex items-center justify-center text-gray-500">
                        <PlusIcon className="h-2 w-2" />
                      </div>
                    )}
                  </button>
                );
              }

              return null;
            })}
        </div>
      )}
      <ul className="flex flex-wrap transition-all">
        {optionsToRender.map(([id, value]: string[]) => {
          const isSelected = selections.includes(String(id));

          return (
            <li key={id} className="mr-2 mb-2">
              <button
                type="button"
                onClick={() => toggleSelection(id)}
                className={cx(
                  buttonClassName,
                  'flex gap-x-1 items-center border rounded-md text-xs py-2 px-3 relative group disabled:cursor-not-allowed disabled:opacity-50',
                  selections.includes(id)
                    ? 'border-action-secondary-200 text-action-secondary-900  bg-action-secondary-50'
                    : 'bg-white border-surface-200'
                )}
                disabled={maxSelections !== undefined && !isSelected && selections.length >= maxSelections}
              >
                <Typography token="font-semibold/text/xs" as="span" color={isSelected ? 'secondary' : 'primary'}>
                  {value}
                </Typography>
                {isSelected ? (
                  <div className="opacity-0 group-hover:opacity-100 transition duration-200 bg-white shadow-md rounded-full h-4 w-4 absolute -top-2 -right-2 flex items-center justify-center text-gray-500">
                    <XMarkIcon className="h-2 w-2" />
                  </div>
                ) : (
                  <div className="opacity-0 group-hover:opacity-100 transition duration-200 bg-white shadow-md rounded-full h-4 w-4 absolute -top-2 -right-2 flex items-center justify-center text-gray-500">
                    <PlusIcon className="h-2 w-2" />
                  </div>
                )}
                {tooltips && tooltips[id] ? (
                  <Tooltip id={id} text={tooltips[id]} showIcon={false}>
                    <InformationCircleIcon className="w-4 h-4" />
                  </Tooltip>
                ) : null}
              </button>
            </li>
          );
        })}
      </ul>
      <input name={inputName} type="hidden" value={selections} />
      {hideOptionsCount && (
        <div>
          {isShowingMore ? (
            <button
              type="button"
              className="border-b border-primary-600 font-semibold text-xs text-primary-600 hover:text-primary-500 hover:border-primary-500 flex space-x-1 items-center transition-all mt-2"
              onClick={() => setIsShowingMore(false)}
            >
              <MinusIcon className="h-3 w-3" />
              <span>Show less</span>
            </button>
          ) : (
            <button
              type="button"
              className="border-b border-primary-600 font-semibold text-xs text-primary-600 hover:text-primary-500 hover:border-primary-500 flex space-x-1 items-center transition-all mt-4"
              onClick={() => setIsShowingMore(true)}
            >
              <PlusIcon className="h-3 w-3" />
              <span>Show more</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
