import { ElementType, Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import useWebBuilderOverlay from '@/hooks/useWebBuilderOverlay';

import OptionsIcon from '../Icons/OptionsIcon';

type ChangeDropdown = {
  title?: string;
  alignRight?: boolean;
  options: OptionProps[];
};

type OptionProps = {
  label: string;
  Icon?: ElementType;
  onClick: () => void;
  chainedDropdown?: ChangeDropdown;
};

const Option = ({ label, Icon, onClick = () => {}, chainedDropdown }: OptionProps) => {
  if (chainedDropdown) {
    return (
      <Popover className="relative">
        <Popover.Button className="w-full flex justify-between hover:bg-surface-100 transition-all px-2 py-1 cursor-pointer">
          <Typography token="font-medium/text/xs" colorWeight="700">
            {label}
          </Typography>
          {Icon && <Icon className="w-4 h-4 text-surface-400" />}
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className={cx(
              'absolute z-10 bg-white rounded border border-gray-100 shadow-md top-6 w-40 py-1',
              chainedDropdown?.alignRight ? 'right-40' : 'left-4'
            )}
          >
            {chainedDropdown?.title && (
              <div className="px-2 pb-1 border-b border-surface-b-300 mb-2">
                <Typography token="font-medium/text/xs" colorWeight="700">
                  {chainedDropdown.title}
                </Typography>
              </div>
            )}
            {chainedDropdown?.options && (
              <div className="flex flex-col">
                {chainedDropdown?.options.map((option) => {
                  return <Option key={option.label} label={option.label} Icon={option.Icon} onClick={option.onClick} />;
                })}
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    );
  }

  return (
    <button
      type="button"
      className="w-full flex justify-between items-center hover:bg-surface-100 transition-all px-2 py-1 cursor-pointer space-x-2"
      onClick={onClick}
    >
      <Typography token="font-medium/text/xs" colorWeight="700" className="text-left">
        {label}
      </Typography>
      {Icon && (
        <div>
          <Icon className="w-4 h-4 text-surface-400" />
        </div>
      )}
    </button>
  );
};

interface Props {
  title?: string;
  options: OptionProps[];
  children?: React.ReactNode;
  dropdownClassname?: string;
  iconOrientation?: 'horizontal' | 'vertical';
}

const OptionsDropdown = ({ options = [], title, children, dropdownClassname, iconOrientation = 'vertical' }: Props) => {
  const { styles, attributes, setReferenceElement, setPopperElement } = useWebBuilderOverlay({
    align: 'end',
  });
  const isVertical = iconOrientation === 'vertical';

  return (
    <Popover className="relative">
      <>
        <Popover.Button ref={setReferenceElement}>
          {children || (
            <div className={cx(isVertical && 'transform rotate-90')}>
              <OptionsIcon />
            </div>
          )}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={cx(
              'absolute z-10 bg-white rounded border border-gray-100 shadow-md right-0 w-40 py-1',
              dropdownClassname
            )}
          >
            {title && (
              <div className="px-2 pb-1 border-b border-surface-b-300 mb-2 truncate">
                <Typography token="font-medium/text/xs" colorWeight="700" className="truncate">
                  {title}
                </Typography>
              </div>
            )}
            <div className="flex flex-col">
              {options.map((option) => {
                return (
                  <Option
                    key={option.label}
                    label={option.label}
                    Icon={option.Icon}
                    onClick={option.onClick}
                    chainedDropdown={option.chainedDropdown}
                  />
                );
              })}
            </div>
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
};

export default OptionsDropdown;
