import { createContext, ReactNode, useContext, useMemo } from 'react';

export interface PublicationContextValue {
  publicationId: string;
}

export const PublicationContext = createContext<PublicationContextValue>({
  publicationId: '',
});

export const usePublicationContext = () => useContext(PublicationContext);

export const PublicationProvider = ({ children, id }: { children: ReactNode; id: string }) => {
  const value = useMemo(() => ({ publicationId: id }), [id]);
  return <PublicationContext.Provider value={value}>{children}</PublicationContext.Provider>;
};
