import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { ReferralProgram } from '@/interfaces/referral_program';
import api from '@/services/swarm';

export default function useReferralProgram() {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchReferralProgram = () =>
    api
      .get('/referral_program', {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<ReferralProgram>([currentPublicationId, 'referral_program'], fetchReferralProgram, {
    keepPreviousData: true,
  });
}
