import React, { useRef, useState } from 'react';
import {
  CountryIso2,
  CountrySelectorDropdown,
  defaultCountries,
  FlagImage,
  usePhoneInput,
} from 'react-international-phone';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import Input from '../../../ui/TextInput';

import './index.css';

interface PhoneInputProps {
  value: string;
  onChange: (v: string) => void;
  onCountryChange?: (c: CountryIso2) => void;
  disabled?: boolean;
  defaultCountry?: CountryIso2;
  forceDialCode?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  onChange,
  onCountryChange,
  disabled,
  defaultCountry = 'us',
  forceDialCode = true,
}) => {
  const wrapper = useRef<HTMLDivElement>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry,
    value,
    forceDialCode,
    countries: defaultCountries,
    onChange: (data) => onChange(data.phone),
  });

  return (
    <div ref={wrapper} className="flex w-full rounded border border-surface-200 shadow-sm">
      <div className="relative">
        <button
          type="button"
          className="grid grid-cols-2 gap-2 bg-surface-50 py-2 px-3 rounded-l hover:bg-surface-100 border-r border-surface-200"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
            wrapper?.current?.classList.add('border-surface-500');
          }}
        >
          <FlagImage iso2={country.iso2} className="w-8" />
          {dropdownOpen ? (
            <ChevronUpIcon className="h-5 w-5 text-surface-500 my-auto" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-surface-500 my-auto" aria-hidden="true" />
          )}
        </button>
        <CountrySelectorDropdown
          show={dropdownOpen}
          className="absolute rounded mt-1"
          selectedCountry="us"
          onSelect={({ iso2 }) => {
            setCountry(iso2);
            onCountryChange?.(iso2);
            setDropdownOpen(false);
            inputRef.current?.focus();
          }}
          onClose={() => {
            setDropdownOpen(false);
            wrapper?.current?.classList.remove('border-surface-500');
          }}
        />
      </div>
      <Input
        value={inputValue}
        variant="primary"
        className="w-full"
        inputClassOverride="border-none rounded-r focus:ring-0 w-full"
        onChange={handlePhoneValueChange}
        name="phone_number"
        type="tel"
        ref={inputRef}
        disabled={disabled}
        onFocus={() => wrapper?.current?.classList.add('border-surface-500')}
        onBlur={() => wrapper?.current?.classList.remove('border-surface-500')}
      />
    </div>
  );
};

export default PhoneInput;
