import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { usePostTextToSpeechConfig } from '@/hooks/usePostTextToSpeechConfig';
import { Post } from '@/interfaces/post';

interface PrePublishWarningProps {
  post: Post;
  publishNow: boolean;
}

const PrePublishWarnings: React.FC<PrePublishWarningProps> = ({ post, publishNow }) => {
  const { data: postDeliveryData } = usePostDeliveryData(post.id);
  const { data: textToSpeechConfig } = usePostTextToSpeechConfig(post.id);

  return (
    <div className="bg-gray-100 p-4 rounded mt-4 space-y-3">
      <p className="font-medium text-sm">Notes:</p>
      <p className="text-sm">
        <em>Once this post is scheduled, you will no longer be able to change the delivery settings for this post.</em>
      </p>
      {!publishNow && postDeliveryData?.for_email && (
        <p className="text-sm">
          <em>
            You are scheduling this post for the future. This means that the email audience outlined above is subject to
            change should you acquire new subscribers or recalculate any segments used in your audience before the
            scheduled send time. If no active subscribers are in the selected audience at send time, the post will be
            moved back to a draft state.
          </em>
        </p>
      )}
      {!postDeliveryData?.for_web && textToSpeechConfig?.enabled && (
        <p className="text-sm">
          <em>
            You must publish your post to the web in order to utilize the audio newsletter feature enabled for this post.
            You can continue to publish without the audio feature or update your audience to the web.
          </em>
        </p>
      )}
    </div>
  );
};

export default PrePublishWarnings;
