import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { FormQuestionTypes } from '../../interfaces/form';
import api from '../../services/swarm';

interface ApiResponse {
  id: string;
}

interface Props {
  formId: string;
  formQuestionId: string;
  onSuccess: (result: ApiResponse) => void;
}

const useUpdateFormQuestion = ({ formId, formQuestionId, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<
    ApiResponse,
    unknown,
    {
      order: number;
      prompt: string;
      custom_field_id: string;
      question_type: FormQuestionTypes;
      required?: boolean;
      multi_select?: boolean;
      show_max_characters?: boolean;
      max_character_limit?: number;
      min_character_limit?: number;
    }
  >(
    ({
      order,
      prompt,
      custom_field_id,
      question_type,
      required,
      multi_select,
      show_max_characters,
      max_character_limit,
      min_character_limit,
    }) =>
      api
        .patch(`/form_questions/${formQuestionId}`, {
          publication_id: currentPublicationId,
          form_id: formId,
          form_question: {
            order,
            prompt,
            required,
            multi_select,
            show_max_characters,
            max_character_limit,
            min_character_limit,
            custom_field_id,
            question_type,
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useUpdateFormQuestion;
