import React, { useEffect, useState } from 'react';

import { CountryInput, Input, PercentageInput } from '@/components/Form';
import TierSelect from '@/components/TierSelect';
import { Condition, Filter } from '@/interfaces/segment';

import AutomationActionInput from './AutomationActionInput';
import ChannelInput from './Channelnput';
import ConditionDatetimeInput from './ConditionDatetimeInput';
import EmailActionInput from './EmailActionInput';
import ExternalEmbedInput from './ExternalEmbedInput';
import {
  channelAcquisitionAttributeOption,
  countryLocationAttributeOption,
  customFieldAttributeOption,
  dataTypeByNameValue,
  pollResponseBehaviorOption,
  referralCountBehaviorOption,
  stateLocationAttributeOption,
  subscriberStatusAttributeOption,
  subscriberTagAttributeOption,
} from './nameOptions';
import { defaultOperatorByConditionName } from './operatorOptions';
import PollActionInput from './PollActionInput';
import StatusInput from './StatusInput';
import SubscriberTagInput from './SubscriberTagInput';
import { DataTypes } from './types';

const mergeDefaultFilters = (condition: Condition, value: string) => {
  const updatedFilters = {
    operator: condition?.filters?.operator || defaultOperatorByConditionName(condition?.name),
    value,
  };

  return updatedFilters;
};

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleFilterChange: (filters: Filter, blockIndex: number, subIndex?: number) => void;
}

const checkForWarnings = (condition: Condition, value: string): string => {
  if (condition.name === customFieldAttributeOption.value && value.length < 3) {
    return '3 or more characters recommended';
  }
  if (condition.name === stateLocationAttributeOption.value && value.length <= 2) {
    return 'Stored by full name, e.g. Texas not TX';
  }
  return '';
};

const ConditionInput = ({ condition, handleFilterChange, index, subIndex }: Props) => {
  const dataType = dataTypeByNameValue(condition.name || '');
  const stringValue = condition.filters?.value as string;

  const [warnings, setWarnings] = useState(checkForWarnings(condition, stringValue || ''));

  useEffect(() => {
    setWarnings(checkForWarnings(condition, stringValue || ''));
  }, [condition, stringValue]);

  const renderInput = () => {
    if (dataType === DataTypes.DATETIME) {
      return (
        <ConditionDatetimeInput
          condition={condition}
          handleFilterChange={handleFilterChange}
          index={index}
          subIndex={subIndex}
        />
      );
    }

    if (dataType === DataTypes.PERCENTAGE) {
      return (
        <PercentageInput
          className="w-full"
          name="value"
          value={`${typeof stringValue === 'undefined' ? '' : stringValue}`}
          placeholder="Set a value"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const parsedValue = Number(e.target.value);
            const updatedFilters = {
              operator: condition?.filters?.operator || defaultOperatorByConditionName(condition?.name),
              value: parsedValue > 100 ? 100 : parsedValue,
            };
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    if (dataType === DataTypes.INTEGER || condition.name === referralCountBehaviorOption.value) {
      return (
        <Input
          className="w-full"
          name="value"
          type="number"
          min="0"
          value={typeof stringValue === 'undefined' ? '' : stringValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const updatedFilters = {
              operator: condition?.filters?.operator || defaultOperatorByConditionName(condition?.name),
              value: Number(e.target.value),
            };
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    if (dataType === DataTypes.EMAIL_ACTION) {
      return (
        <EmailActionInput
          value={stringValue || ''}
          onChange={(value: string) => {
            const updatedFilters = mergeDefaultFilters(condition, value);
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    if (dataType === DataTypes.AUTOMATION_ACTION) {
      return (
        <AutomationActionInput
          value={stringValue || ''}
          onChange={(value: string) => {
            const updatedFilters = mergeDefaultFilters(condition, value);
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    if (dataType === DataTypes.BEHAVIOR_ACTION) {
      if (condition.name === pollResponseBehaviorOption.value) {
        return (
          <PollActionInput
            value={stringValue || ''}
            filter={condition.filters}
            onChange={(value: string) => {
              const updatedFilters = mergeDefaultFilters(condition, value);
              const newFilters = { ...condition.filters, ...updatedFilters };
              handleFilterChange(newFilters, index, subIndex);
            }}
          />
        );
      }
    }

    if (dataType === DataTypes.EXTERNAL_EMBED) {
      return (
        <ExternalEmbedInput
          value={stringValue || ''}
          onChange={(value: string) => {
            const updatedFilters = mergeDefaultFilters(condition, value);
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    if (dataType === DataTypes.TIER) {
      return (
        <TierSelect
          tierId={stringValue || ''}
          includeFree
          onChange={(_name: string, value: string | string[]) => {
            const updatedFilters = mergeDefaultFilters(condition, String(value));
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    if (condition.name === subscriberStatusAttributeOption.value) {
      return (
        <StatusInput
          value={stringValue || ''}
          onChange={(value: string) => {
            const updatedFilters = mergeDefaultFilters(condition, value);
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    if (condition.name === channelAcquisitionAttributeOption.value) {
      return (
        <ChannelInput
          value={stringValue || ''}
          onChange={(value: string) => {
            const updatedFilters = mergeDefaultFilters(condition, value);
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    if (condition.name === countryLocationAttributeOption.value) {
      return (
        <CountryInput
          value={stringValue || ''}
          onChange={(value: string) => {
            const updatedFilters = mergeDefaultFilters(condition, value);
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    if (condition.name === subscriberTagAttributeOption.value) {
      return (
        <SubscriberTagInput
          condition={condition}
          onChange={(value: string) => {
            const updatedFilters = mergeDefaultFilters(condition, value);
            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      );
    }

    return (
      <Input
        className="w-full"
        name="value"
        type="text"
        placeholderText="Set a value"
        value={stringValue || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const updatedFilters = mergeDefaultFilters(condition, e.target.value);
          const newFilters = { ...condition.filters, ...updatedFilters };

          handleFilterChange(newFilters, index, subIndex);
        }}
      />
    );
  };

  return (
    <div className="flex flex-col w-full">
      {renderInput()}
      {warnings && <span className="text-xs text-yellow-500 ml-2">{warnings}</span>}
    </div>
  );
};

export default ConditionInput;
