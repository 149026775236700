import { useState } from 'react';
import cx from 'classnames';
import moment from 'moment-mini';

import SlideOver from '@/components/SlideOver';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import { Day } from './types';

const totalDays = 7 * 6; // 6 weeks

const CalendarButton = ({ day }: { day: Day }) => {
  const { date, isCurrentMonth, isToday, index, events } = day;
  const formattedDate = date.format('YYYY-MM-DD');
  const [isOpen, setIsOpen] = useState(false);

  let colors = 'bg-gray-50 text-gray-400 hover:bg-gray-100 disabled:cursor-not-allowed';
  if (isCurrentMonth) {
    colors = 'bg-white text-gray-900 hover:bg-gray-100 disabled:cursor-not-allowed';
  }

  const isPending = events.some((event) => event.type === 'pending');
  const isAccepted = events.some((event) => event.type === 'accepted');
  const pendingColor = moment().add(2, 'day').isAfter(moment(date))
    ? 'bg-feedback-danger-500'
    : 'bg-feedback-warning-500';
  const acceptedColor = moment(date).isBefore(moment()) ? 'bg-gray-500' : 'bg-feedback-info-500';

  const pendingEvents = events.filter((event) => event.type === 'pending');
  const scheduledPlacements = events.filter((event) => event.type === 'accepted' && event.date.isAfter(moment()));
  const pastPlacements = events.filter((event) => event.type === 'accepted' && event.date.isBefore(moment()));

  return (
    <>
      <button
        type="button"
        className={cx(
          colors,
          index === 0 && 'rounded-tl-lg',
          index === 6 && 'rounded-tr-lg',
          index === totalDays - 7 && 'rounded-bl-lg',
          index === totalDays - 1 && 'rounded-br-lg',
          'relative focus:z-10 flex items-center justify-center aspect-square'
        )}
        onClick={() => {
          setIsOpen(true);
        }}
        style={{ paddingTop: '100%', width: '100%' }}
        disabled={events.length === 0}
      >
        <time
          dateTime={formattedDate}
          className={cx(
            isToday && isCurrentMonth && 'bg-primary-600 font-semibold text-white',
            isToday && !isCurrentMonth && 'bg-gray-300 font-semibold text-gray-800',

            'duration-200 flex h-5 w-5 sm:h-7 sm:w-7 items-center justify-center rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs sm:text-sm'
          )}
        >
          {date.date()}
        </time>

        <div className="absolute bottom-2 right-1/2 flex transform translate-x-1/2 space-x-0.5 justify-center">
          {isPending && <span className={cx('h-1.5 w-1.5 rounded-full', pendingColor)} aria-hidden="true" />}
          {isAccepted && <span className={cx('h-1.5 w-1.5 rounded-full', acceptedColor)} aria-hidden="true" />}
        </div>
      </button>
      {events.length > 0 && (
        <SlideOver isOpen={isOpen} onClose={() => setIsOpen(false)} headerText={date.format('dddd, MMMM Do YYYY')}>
          <div className="space-y-6">
            {pendingEvents.length > 0 && (
              <div className="space-y-2">
                <p className="font-medium text-gray-700">Deadline approaching</p>
                <div className="space-y-4">
                  {pendingEvents.map((event) => (
                    <Card density="compact">
                      <div className="space-y-2">
                        <div className="space-y-1">
                          <p className="font-bold">{event.name}</p>
                          <p className="text-sm text-gray-500">
                            Respond by {event.date.format('MMMM Do YYYY [at] h:mm a')}
                          </p>
                        </div>
                        <div>
                          <Button variant="primary-inverse" size="xs" to={`/monetize/ads/opportunities/${event.id}`}>
                            View details
                          </Button>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            )}
            {scheduledPlacements.length > 0 && (
              <div className="space-y-2">
                <p className="font-medium text-gray-700">Upcoming placements</p>
                <div className="space-y-4">
                  {scheduledPlacements.map((event) => (
                    <Card density="compact">
                      <div className="space-y-2">
                        <div className="space-y-1">
                          <p className="font-bold">{event.name}</p>
                          <p className="text-sm text-gray-500">
                            Send by {event.date.format('MMMM Do YYYY [at] h:mm a')}
                          </p>
                        </div>
                        <div>
                          <Button variant="primary-inverse" size="xs" to={`/monetize/ads/opportunities/${event.id}`}>
                            View details
                          </Button>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            )}
            {pastPlacements.length > 0 && (
              <div className="space-y-2">
                <p className="font-medium text-gray-700">Past placements</p>
                <div className="space-y-4">
                  {pastPlacements.map((event) => (
                    <Card density="compact">
                      <div className="space-y-2">
                        <div className="space-y-1">
                          <p className="font-bold">{event.name}</p>
                          <p className="text-sm text-gray-500">{event.date.format('MMMM Do YYYY')}</p>
                        </div>
                        <div>
                          <Button variant="primary-inverse" size="xs" to={`/monetize/ads/opportunities/${event.id}`}>
                            View details
                          </Button>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            )}
          </div>
        </SlideOver>
      )}
    </>
  );
};

export default CalendarButton;
