import { useCallback } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { Tier } from '@/interfaces/tier';

import { Section } from '../../../../../extensions';
import { convertToSection, nodeIsSection } from '../../../utils';

const useVisibilitySettingsPanel = (editor: Editor, currentNode: Node | null, currentNodePos: number) => {
  const isSection = nodeIsSection(currentNode);

  const onToggleShowOnWebsite = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);

    const { showOnWebsite, showToNonSubscribers } = editor.getAttributes('section');

    const newValue = !showOnWebsite;
    const resetShowToNonSubscribers = !newValue;
    const newShowToNonSubscribers = resetShowToNonSubscribers ? true : showToNonSubscribers;

    editor.commands.updateAttributes(Section.name, {
      showOnWebsite: newValue,
      showToNonSubscribers: newShowToNonSubscribers,
    });
  }, [editor, currentNode, currentNodePos]);

  const onToggleShowOnEmail = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);

    const { showInEmail } = editor.getAttributes('section');

    editor.commands.updateAttributes(Section.name, { showInEmail: !showInEmail });
  }, [editor, currentNode, currentNodePos]);

  const onToggleShowToNonSubscribers = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);

    const { showToNonSubscribers } = editor.getAttributes('section');

    editor.commands.updateAttributes(Section.name, { showToNonSubscribers: !showToNonSubscribers });
  }, [editor, currentNode, currentNodePos]);

  const onToggleShowToFreeSubscribers = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);

    const { showToFreeSubscribers, showToPaidSubscribers, showWithReferralCount } = editor.getAttributes('section');

    const newValue = !showToFreeSubscribers;
    const resetShowWithReferralCount = !newValue && !showToPaidSubscribers;
    const newShowWithReferralCount = resetShowWithReferralCount ? false : showWithReferralCount;

    editor.commands.updateAttributes(Section.name, {
      showToFreeSubscribers: newValue,
      showWithReferralCount: newShowWithReferralCount,
    });
  }, [editor, currentNode, currentNodePos]);

  const onToggleShowToPaidSubscribers = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);

    const { showToPaidSubscribers, showToFreeSubscribers, showWithReferralCount } = editor.getAttributes('section');

    const newValue = !showToPaidSubscribers;
    const resetShowWithReferralCount = !newValue && !showToFreeSubscribers;
    const newShowWithReferralCount = resetShowWithReferralCount ? false : showWithReferralCount;
    editor.commands.updateAttributes(Section.name, {
      showToPaidSubscribers: newValue,
      showWithReferralCount: newShowWithReferralCount,
      showToTieredSubscribers: [],
    });
  }, [editor, currentNode, currentNodePos]);

  const onToggleShowToTieredSubscribers = useCallback(
    (tierId: string, tiers: Tier[]) => () => {
      convertToSection(editor, currentNode, currentNodePos);

      const { showToPaidSubscribers: visibleToAllTiers, showToTieredSubscribers: currentValue } =
        editor.getAttributes('section');

      if (visibleToAllTiers) {
        // showToPaidSubscriber is essentially an "all" toggle. So if it's currently enabled, and
        // we're toggling a tier off, we want to disable it and enable the other tiers.
        editor.commands.updateAttributes(Section.name, {
          showToPaidSubscribers: false,
          showToTieredSubscribers: tiers.filter((tier) => tier.id !== tierId).map((tier) => tier.id),
        });
      } else {
        // Alternatively, if we're toggling a tier on, and this results in all tiers being enabled,
        // we want to enable the "all" toggle.
        const newValue = currentValue.includes(tierId)
          ? currentValue.filter((id: any) => id !== tierId)
          : [...currentValue, tierId];

        editor.commands.updateAttributes(Section.name, {
          showToPaidSubscribers: tiers.map((tier) => tier.id).every((id) => newValue.includes(id)),
          showToTieredSubscribers: newValue,
        });
      }
    },
    [editor, currentNode, currentNodePos]
  );

  const onToggleShowWithReferralCount = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);

    const { showWithReferralCount } = editor.getAttributes('section');

    editor.commands.updateAttributes(Section.name, { showWithReferralCount: !showWithReferralCount });
  }, [editor, currentNode, currentNodePos]);

  const onSetShowWithReferralCount = useCallback(
    (referralCount: { value: number }) => {
      convertToSection(editor, currentNode, currentNodePos);

      editor.commands.updateAttributes(Section.name, { referralCountValue: referralCount.value });
    },
    [editor, currentNode, currentNodePos]
  );

  return {
    isSection,
    onToggleShowOnWebsite,
    onToggleShowOnEmail,
    onToggleShowToNonSubscribers,
    onToggleShowToFreeSubscribers,
    onToggleShowToPaidSubscribers,
    onToggleShowWithReferralCount,
    onSetShowWithReferralCount,
    onToggleShowToTieredSubscribers,
  };
};

export default useVisibilitySettingsPanel;
