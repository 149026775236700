import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import Card from '@/components/Card';
import { Typography, TypographyStack } from '@/components/Typography';
import useBoostInvite from '@/hooks/boosts/monetize/useBoostInvite';
import useBoostInvites from '@/hooks/boosts/monetize/useBoostInvites';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import pluralize from '@/utils/pluralize';

import { ReviewBoostInvites } from './ReviewBoostInvites';

const BoostInvitationsCard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const boostInviteIdInUrl = searchParams.get('boost_invite_id');

  const [isReviewBoostInvitesOpen, setIsReviewBoostInvitesOpen] = useState(!!boostInviteIdInUrl);

  const { data, isLoading } = useBoostInvites({ isEnabled: boostInviteIdInUrl === null });
  const { data: boostInviteData } = useBoostInvite(boostInviteIdInUrl);
  const boostInvites = data?.pages.flatMap((page) => page.boost_invites) || [];
  const invitesCount = data?.pages[0]?.pagination?.total || 0;

  const handleClickReview = () => setIsReviewBoostInvitesOpen(true);

  const handleCloseReviewBoostInvites = () => {
    setIsReviewBoostInvitesOpen(false);
    searchParams.delete('boost_invite_id');
    setSearchParams(searchParams);
  };

  const handleAcceptOrDeclineInvitation = () => {
    if (invitesCount === 0) {
      handleCloseReviewBoostInvites();
    }
  };

  const canShowReviewModal = boostInvites.length > 0 || boostInviteData?.boost_invite;

  return (
    <>
      {canShowReviewModal ? (
        <ReviewBoostInvites
          onAcceptInvitation={handleAcceptOrDeclineInvitation}
          onDeclineInvitation={handleAcceptOrDeclineInvitation}
          isOpen={isReviewBoostInvitesOpen}
          onClose={handleCloseReviewBoostInvites}
          isFetchingBoostInvites={isLoading}
          boostInvites={boostInviteData?.boost_invite ? [boostInviteData.boost_invite] : boostInvites}
          selectedBoostInviteId={boostInviteIdInUrl}
        />
      ) : null}
      <Card>
        <div className="flex flex-col gap-y-5 sm:flex-row sm:gap-x-5">
          <div className="flex flex-col sm:flex-row sm:gap-x-5">
            <div className="hidden sm:block">
              <img
                width="361"
                height="226"
                src="https://beehiiv-images-production.s3.amazonaws.com/static_assets/boosts_invite_envelope.jpg"
                alt=""
              />
            </div>
            <div className="flex flex-col gap-y-2 justify-between">
              <div className="flex flex-col gap-y-2 sm:gap-y-5">
                <Badge type="alert" className="w-fit">
                  {pluralize('invite', invitesCount)} pending
                </Badge>
                <TypographyStack>
                  <Typography token="font-semibold/text/base">Review Potential Partners</Typography>
                  <Typography token="font-normal/text/base" colorWeight="700">
                    Through Boost Invites, publications in the beehiiv network have invited you to Boost their
                    publications. Simply review and start making money.
                  </Typography>
                </TypographyStack>
              </div>
              <div className="flex justify-end">
                <Button
                  disabled={invitesCount === 0}
                  variant="secondary"
                  size="xs"
                  onClick={handleClickReview}
                  iconRight
                  Icon={ArrowUpRightIcon}
                >
                  Review Boost Invites
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default BoostInvitationsCard;
