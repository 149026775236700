import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { FileArrowDown } from '@phosphor-icons/react';

import { useUpdateSitePackage } from '@/hooks/useSitePackages';
import useSitePackages from '@/hooks/useSitePackages/useSitePackages';
import { Button } from '@/routes/website/_components/UI/Button';
import InputWrapper from '@/routes/website/_components/UI/InputWrapper';
import Modal from '@/routes/website/_components/UI/Modal';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/routes/website/_components/UI/Select';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

import { useNavbarContext } from '../../../NavbarContext';

const NavbarTemplate = () => {
  const { content } = useNavbarContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedSitePackageId, setSelectedSitePackageId] = useState<string | null>(null);

  const { data: sitePackages } = useSitePackages();
  const projects = useMemo(() => sitePackages?.pages.flatMap((pkg) => pkg.site_packages) || [], [sitePackages]);
  const { sitePackageId } = useParams();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const { mutate: updateSitePackage, isLoading: isUpdatingSitePackage } = useUpdateSitePackage({
    id: selectedSitePackageId || 'unknown-site-package-id',
    onSuccess: () => {
      toast.success('Successfully saved navbar as template');
    },
  });

  const onConfirm = useCallback(() => {
    updateSitePackage({ navbar: JSON.stringify(content) });
  }, [content, updateSitePackage]);

  const title = 'Save Navbar Template';
  const ctaText = 'Save';

  return (
    <>
      <Tooltip center="Save as Template" delay={300}>
        <Button
          variant="secondary"
          size="sm"
          LeftIcon={FileArrowDown}
          className="text-wb-secondary"
          onClick={() => setIsModalOpen(true)}
        />
      </Tooltip>
      <Modal
        isOpen={isModalOpen}
        onClose={handleClose}
        title={title}
        onConfirm={onConfirm}
        ctaText={ctaText}
        ctaVariant="primary"
        isLoading={isUpdatingSitePackage}
        isLoadingText="Saving..."
      >
        <InputWrapper name="category" labelText="Project" className="w-full">
          <Select
            defaultValue={sitePackageId}
            onValueChange={(value: any) => {
              setSelectedSitePackageId(value);
            }}
          >
            <SelectTrigger className="w-full" id="by_status">
              <SelectValue placeholder="Select a project" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="none">None</SelectItem>
                {projects?.map((project) => (
                  <SelectItem key={project.id} value={project.id}>
                    {project.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </InputWrapper>
      </Modal>
    </>
  );
};

export default NavbarTemplate;
