import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@/context/current-user-context';
import appendSettingsWorkspaceId from '@/utils/appendSettingsWorkspaceId';

import useCurrentPublicationId from './usePublications/useCurrentPublicationId';
import { usePublication } from './usePublications';

const useGoToBilling = () => {
  const navigate = useNavigate();
  const currentPublicationId = useCurrentPublicationId();
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;
  const { data: publication } = usePublication(currentPublicationId, hasSettingsV2);

  const handleClick = () => {
    navigate(
      hasSettingsV2
        ? appendSettingsWorkspaceId(
            '/settings/workspace/billing_and_plan',
            publication?.organization_id || '',
            currentPublicationId
          )
        : '/settings/billing'
    );
  };

  return handleClick;
};

export default useGoToBilling;
