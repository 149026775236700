export enum DowngradeStatus {
  STARTED = 'started',
  FEEDBACK_PROVIDED = 'feedback_provided',
  THREE_FOR_ONE_OFFER_CLAIMED = 'three_for_one_offer_claimed',
  COMPLETED = 'completed',
}

export enum DowngradeReason {
  TOO_EXPENSIVE = 'too_expensive',
  NO_GROWTH = 'no_growth',
  TAKING_BREAK = 'taking_break',
  TECHNICAL_ISSUES = 'technical_issues',
  MISSING_FEATURES = 'missing_features',
  TOO_DIFFICULT = 'too_difficult',
  SWITCHED_SERVICE = 'switched_service',
  OTHER = 'other',
}

export interface Downgrade {
  user_id?: string;
  organization_id?: string;
  plan_id?: string;
  status: DowngradeStatus;
  reason?: DowngradeReason;
  feedback?: string;
}
