import { FC, useCallback, useMemo } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';

interface FootnoteItemAttrs {
  footnoteId: string;
  order: number;
}
export const FootnoteItemView: FC<NodeViewProps> = ({ node, editor }) => {
  const attrs = useMemo(() => node.attrs as FootnoteItemAttrs, [node.attrs]);

  const focusFootNoteMarker = useCallback(() => {
    editor.commands.focusFootnoteMarker(attrs.footnoteId);
  }, [editor, attrs.footnoteId]);

  const removeFootnoteItemAndMarker = useCallback(() => {
    editor.commands.removeFootnoteItemAndMarker(attrs.footnoteId);
  }, [editor, attrs.footnoteId]);

  return (
    <NodeViewWrapper>
      <div className="flex items-baseline group">
        <Tippy
          content={
            <div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200 text-sm">
              Jump to the marker
            </div>
          }
          placement="top"
          trigger="mouseenter"
        >
          <button
            className="absolute -translate-x-full tabular-nums underline underline-offset-2 pr-1 inline translate-y-[3px]"
            contentEditable="false"
            onClick={focusFootNoteMarker}
            type="button"
            suppressContentEditableWarning
          >
            {attrs.order}
          </button>
        </Tippy>
        <div className="flex-1">
          <NodeViewContent />
        </div>
        <Tippy
          content={
            <div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200 text-sm">
              Delete here and in text
            </div>
          }
          placement="top"
          trigger="mouseenter"
        >
          <span className="group-hover:opacity-100 opacity-0 transition-opacity translate-y-[3px]">
            <Button
              $variant="tertiary"
              $size="small"
              editor={editor}
              $isIconButton
              $showTooltip
              onClick={removeFootnoteItemAndMarker}
            >
              <TrashIcon className="w-4 h-4" />
            </Button>
          </span>
        </Tippy>
      </div>
    </NodeViewWrapper>
  );
};
