import { NodeViewWrapper } from '@tiptap/react';

import pluralize from '../../../../../../utils/pluralize';

import { Styled } from './Instagram.styled';

interface Props {
  url: string;
  service: 'instagram';
  data: any;
  anchorEnabled: boolean;
  anchorId: string;
}

export const InstagramEmbed = ({ url, service, data: attributes, anchorEnabled = false, anchorId }: Props) => {
  return (
    <NodeViewWrapper
      data-url={url}
      data-service={service}
      data-drag-handle
      {...(anchorEnabled ? { 'data-anchor': '', id: anchorId } : {})}
    >
      <Styled.InstagramMedia>
        <div>
          <a href={url} className="p-0 text-center w-full no-underline" target="_blank" rel="noreferrer">
            <div className="flex items-center p-3">
              {attributes.author_profile_image && (
                <div className="flex-grow-0 mr-3">
                  <Styled.ProfileImage src={attributes.author_profile_image} />
                </div>
              )}
              <div>
                <Styled.Paragraph className="text-left text-gray-800">
                  <strong>{attributes.author_name}</strong>
                </Styled.Paragraph>
                {attributes.author_follower_count && (
                  <Styled.Paragraph className="m-0 text-left text-gray-500 text-sm">
                    {pluralize('follower', attributes.author_follower_count)}
                  </Styled.Paragraph>
                )}
              </div>
            </div>
            <div className="image-wrapper relative border-t border-b border-gray-300">
              <img
                className="border-0 h-auto max-w-full w-full block align-top"
                alt=""
                src={attributes.thumbnail_url}
              />
            </div>
            <div className="p-3">
              <Styled.Paragraph className="text-center ig-text">
                <strong>View more on Instagram</strong>
              </Styled.Paragraph>
            </div>

            <div className="px-4">
              <div className="border-t border-gray-300 w-full" />
              <div className="flex pt-2 pb-4 items-center">
                {/* Heart */}
                <div>
                  <Styled.HeartLeft />
                  <Styled.HeartTip />
                  <Styled.HeartRight />
                </div>

                {/* Comment Bubble */}
                <div className="ml-2">
                  <Styled.CommentBubble />
                  <Styled.CommentBubbleTip />
                </div>

                {/* Bookmark */}
                <div className="ml-auto">
                  <Styled.BookmarkLeft />
                  <Styled.BookmarkTop />
                  <Styled.BookmarkRight />
                </div>
              </div>
              <div className="mb-4">
                {(attributes.like_count || attributes.comment_count) && (
                  <Styled.Paragraph className="text-left text-gray-800">
                    <strong>
                      {[pluralize('like', attributes.like_count), pluralize('comment', attributes.comment_count)].join(
                        ' & '
                      )}
                    </strong>
                  </Styled.Paragraph>
                )}
                <Styled.Paragraph className="text-left text-gray-800">
                  <strong>{attributes.author_name}</strong>
                </Styled.Paragraph>
                {attributes.description && (
                  <Styled.Paragraph
                    className="text-left text-gray-800 py-3"
                    dangerouslySetInnerHTML={{ __html: attributes.description.replace('\n', '<br>') }}
                  />
                )}
              </div>
            </div>
            <div className="border-t border-gray-300 w-full" />
            <div className="flex justify-between p-3 items-center">
              <div className="text-gray-500 text-sm">Add a comment...</div>
              <div>
                <Styled.LogoImage
                  className="block border-0"
                  src="https://media.beehiiv.com/static_assets/instagram_embed/logo.png?fit=scale-down&format=auto"
                  alt=""
                />
              </div>
            </div>
          </a>
        </div>
      </Styled.InstagramMedia>
    </NodeViewWrapper>
  );
};

export default InstagramEmbed;
