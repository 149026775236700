import {
  ArrowPathRoundedSquareIcon,
  BanknotesIcon,
  BookOpenIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  ClipboardDocumentListIcon,
  InboxStackIcon,
  NoSymbolIcon,
  QueueListIcon,
  RocketLaunchIcon,
  SparklesIcon,
} from '@heroicons/react/20/solid';
import { Grid } from '@tremor/react';

import IconWrapper from '@/components/IconHelpers/IconWrapper';
import { Typography, TypographyStack } from '@/components/Typography';

interface Props {
  features: any[];
}

const ICON_MAP: any = Object.freeze({
  referrals: ArrowPathRoundedSquareIcon,
  automations: RocketLaunchIcon,
  paid_subscribers: BanknotesIcon,
  segmentation: InboxStackIcon,
  split_tests: QueueListIcon,
  web_builder: RocketLaunchIcon,
  analytics: ChartPieIcon,
  surveys: ClipboardDocumentListIcon,
  polls: ChartBarSquareIcon,
  ai_editor_tools: SparklesIcon,
  remove_branding: NoSymbolIcon,
  newsletter_xp_course: NoSymbolIcon,
  additional_publications: BookOpenIcon,
});

const TrialScreen1 = ({ features }: Props) => {
  return (
    <div className="border-t border-surface-200 pt-4 w-full space-y-4">
      <Grid className="gap-4 grid-cols-1 lg:grid-cols-2">
        {features.map((feature) => {
          const hasCount = feature.count > 0;

          return (
            <div key={feature.id} className="border p-4 rounded border-surface-200 w-full flex justify-center">
              <TypographyStack className="text-center">
                {hasCount && (
                  <Typography size="lg" weight="bold">
                    {feature.count}
                  </Typography>
                )}

                <div className="flex justify-center items-center space-x-2 h-full">
                  <div>
                    {ICON_MAP?.[`${feature.id}`] && (
                      <IconWrapper
                        Icon={ICON_MAP?.[`${feature.id}`]}
                        variant="tertiary"
                        type={hasCount ? 'solid' : 'ghost'}
                      />
                    )}
                  </div>
                  <Typography size="lg" weight="bold">
                    {feature.text}
                  </Typography>
                </div>
              </TypographyStack>
            </div>
          );
        })}
      </Grid>
    </div>
  );
};

export default TrialScreen1;
