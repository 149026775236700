import { NoSymbolIcon } from '@heroicons/react/24/outline';

import ActiveIndicator from '../../../../components/ActiveIndicator';
import OptionsDropdown, { OptionsDropdownProps } from '../../../../components/OptionsDropdown';
import { Recommendation, RecommendationStatus } from '../../../../interfaces/recommendation';

interface Props {
  recommendation: Recommendation;
  options: ((recommendation: Recommendation) => OptionsDropdownProps['options']) | OptionsDropdownProps['options'];
  publicationType: 'publication' | 'recommended_publication';
  verb?: string;
}

export default function Row({ recommendation, options, publicationType, verb = 'Generated' }: Props) {
  const formatter = new Intl.NumberFormat('en');
  const dropdownOptions = typeof options === 'function' ? options(recommendation) : options;
  const publicationDeleted = recommendation[publicationType]?.id == null;
  const recommendationActive = !publicationDeleted && recommendation.status === RecommendationStatus.ACTIVE;
  const color = recommendationActive ? 'text-green-500' : 'text-gray-500';
  const filteredDropdownOptions = publicationDeleted
    ? [dropdownOptions.flat().filter((opt) => opt.label === 'Remove')]
    : dropdownOptions;

  const publicationDetails = (
    <div className="sm:flex">
      <div className="mb-4 flex-shrink-0 sm:mb-0 mr-4">
        <div className="w-12 h-12 border rounded overflow-hidden">
          <figure className="aspect-w-1 aspect-h-1 relative h-full w-full">
            {publicationDeleted ? (
              <NoSymbolIcon className="text-red-200" />
            ) : (
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src={recommendation[publicationType].logo_url}
                alt={recommendation[publicationType].name}
              />
            )}
          </figure>
        </div>
      </div>
      <div className="flex justify-center flex-col space-y-1">
        <div className="inline-flex space-x-2 items-center">
          <p>{publicationDeleted ? 'Publication has been deleted' : recommendation[publicationType].name}</p>
          <ActiveIndicator active={recommendationActive} />
        </div>
        {recommendation.reason && (
          <p className="text-sm font-normal line-clamp-2 text-gray-500">&ldquo;{recommendation.reason}&rdquo;</p>
        )}
        <p className="text-sm font-normal line-clamp-2 text-gray-500">
          {verb}{' '}
          <strong className={color}>
            {formatter.format(recommendation.attribution_count)}{' '}
            {recommendation.attribution_count === 1 ? 'subscriber' : 'subscribers'}
          </strong>
        </p>
        <a
          href={`https://${recommendation[publicationType].hostname}/recommendations`}
          className="text-xs font-normal line-clamp-1 text-gray-500"
          target="_blank"
          rel="noreferrer"
        >
          {recommendation[publicationType].hostname}
        </a>
      </div>
    </div>
  );

  return (
    <li className="p-4 bg-white rounded border relative group">
      <div className="flex justify-between">
        <div className="flex items-center sm:w-2/3">
          <div className="text-gray-800 font-semibold">{publicationDetails}</div>
        </div>
        <div>
          <OptionsDropdown options={filteredDropdownOptions} />
        </div>
      </div>
    </li>
  );
}
