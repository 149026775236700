import moment from 'moment-mini';

import type { Day, Event } from './types';

const totalDays = 42; // 6 weeks

export default function generateCalendar(year: number, month: number, events: Event[]): { name: string; days: Day[] } {
  const result = {
    name: moment([year, month]).format('MMMM'),
    days: [] as Day[],
  };

  let startDate = moment([year, month]).startOf('month');

  while (startDate.day() !== 1) {
    startDate = startDate.subtract(1, 'days');
  }

  result.days = Array.from({ length: totalDays }, (_, i) => {
    const date = moment(startDate).add(i, 'days');

    return {
      date,
      isCurrentMonth: date.month() === month,
      isToday: date.isSame(moment(), 'day'),
      onClick: () => {},
      index: i,
      events: events.filter((event) => event.date.isSame(date, 'day')),
    };
  });

  return result;
}
