import { Lightning, Question } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

import { useNavbarContext } from '../../../NavbarContext';

const DEFAULT_ACTION = 'sign_in';
export const ActionSettingsReadOnly = () => {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item' || selectedContent.attrs?.type !== 'user_button') return null;

  const contentValue =
    selectedContent?.attrs && 'action' in selectedContent.attrs
      ? selectedContent.attrs.action || DEFAULT_ACTION
      : DEFAULT_ACTION;

  const options: Record<string, { title: string; tooltip: string }> = {
    sign_up: {
      title: 'Sign Up',
      tooltip: 'This button will open the sign up modal',
    },
    sign_in: {
      title: 'Sign In',
      tooltip: 'This button will open the sign in modal',
    },
    profile: {
      title: 'Profile',
      tooltip: 'This button will redirect to the profile page',
    },
  };

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px] min-w-[80px]" variant="secondary" size="2xs" weight="medium">
        Action
      </Text>
      <div className="grow w-full bg-wb-primary border border-wb-soft rounded-lg flex items-center gap-2 p-1 pr-2 cursor-default">
        <div className="flex items-center justify-center w-6 h-6 bg-wb-accent rounded-md">
          <Lightning size={12} className="text-wb-on-accent" weight="fill" />
        </div>
        <Text size="2xs" weight="medium" className="flex-1">
          {options[contentValue].title}
        </Text>
        <Tooltip center={options[contentValue].tooltip}>
          <Question size={16} className="text-wb-secondary" weight="bold" />
        </Tooltip>
      </div>
    </div>
  );
};
