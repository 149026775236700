import { useQuery } from 'react-query';

import { PartnerTierSetting } from '@/interfaces/partner_tier';
import api from '@/services/swarm';

interface ReturnData {
  partner_tier_settings: PartnerTierSetting[]
};

const usePartnerTierSettings = () => {
  const { data, isLoading, refetch } = useQuery<ReturnData>(['partner_program', 'partner_tier_settings'], () =>
    api.get('/partner_program/partner_tier_settings').then((res) => res.data)
  );

  return { data, isLoading, refetch };
};

export default usePartnerTierSettings;
