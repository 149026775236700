import { FC } from 'react';
import toast from 'react-hot-toast';

import SelectAutomationModal from '@/components/SelectAutomationModal/SelectAutomationModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface AutomationEnrollProps {
  segmentId: string;
  isOpen: boolean;
  onClose: () => void;
}

const AutomationEnroll: FC<AutomationEnrollProps> = ({ segmentId, isOpen, onClose }: AutomationEnrollProps) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const onSubmit = (automationIds: string[]) => {
    return api
      .post(`/segments/${segmentId}/segment_actions`, {
        publication_id: currentPublicationId,
        action_type: 'enroll_in_automation',
        automation_ids: automationIds,
      })
      .then(() => {
        toast.success('Segment is being enrolled into automation(s)');
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        onClose();
      });
  };

  return <SelectAutomationModal isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} />;
};

export default AutomationEnroll;
