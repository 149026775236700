import { ArrowPathIcon, PencilIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

type NoResultsProps = {
  handleResetFilter: () => void;
  handleResetSearch: () => void;
  isLoading: boolean;
  search: string;
  hasStatusFilter: boolean;
  createDraftMutation: any;
};

const NoResults = (props: NoResultsProps) => {
  const { search, hasStatusFilter, isLoading, handleResetFilter, handleResetSearch, createDraftMutation } = props;

  const noSearchResults = search;
  const noStatusFilterResults = !search && hasStatusFilter;

  if (noStatusFilterResults) {
    return (
      <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500 text-center">No results found</p>
          <Button variant="primary-inverse" onClick={handleResetFilter}>
            <div className="flex">
              <ArrowPathIcon className="h-5 w-5 mr-2" />
              Reset Filter
            </div>
          </Button>
        </div>
      </div>
    );
  }

  const text = noSearchResults
    ? `No results found for search, '${search}'`
    : `Once you create your first draft, it will show up here.`;

  return (
    <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
      <div className="flex flex-col items-center gap-4">
        <p className="text-gray-500 text-center">{text}</p>
        {noSearchResults ? (
          <Button variant="primary-inverse" onClick={handleResetSearch}>
            <div className="flex">
              <ArrowPathIcon className="h-5 w-5 mr-2" />
              Reset Search
            </div>
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => createDraftMutation.mutateAsync({})}
            loading={createDraftMutation.isLoading}
          >
            <div className="flex">
              <PencilIcon className="h-5 w-5 mr-2" />
              Start writing
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default NoResults;
