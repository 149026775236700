import { ElementType, forwardRef, HTMLProps, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../../lib/colors';
import { IconName } from '../../../lib/types';
import { Icon } from '../Icon';

import { Input } from './Input';

const Styled = {
  Wrapper: styled.label`
    align-items: center;
    display: flex;
    gap: 1rem;
  `,
  Label: styled.div`
    flex: 0 0 auto;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.2;
    margin-left: 0.375rem;
    width: 5rem;
  `,
  Field: styled.div`
    flex: 1 1 auto;
    position: relative;
  `,
  IconWrapper: styled.div`
    align-items: center;
    aspect-ratio: 1;
    color: #fff;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
  `,
  Suffix: styled.div`
    align-items: center;
    aspect-ratio: 1;
    color: ${colors.white[5]};
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    padding-inline: 0.5rem;
  `,
};

export type InputFieldProps = HTMLProps<HTMLInputElement> & {
  label?: string;
  icon?: IconName;
  suffix?: string;
  as?: ElementType;
  value?: string;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(({ label, icon, suffix, ...rest }, ref) => {
  // Determine suffix element offsetWidth and safe in suffixWidth state
  const refSuffix = useRef<any>(null);
  const [suffixWidth, setSuffixWidth] = useState(0);
  useLayoutEffect(() => {
    setSuffixWidth(refSuffix.current?.offsetWidth || 0);
  }, [refSuffix.current?.offsetWidth]);

  return (
    <Styled.Wrapper>
      {label && <Styled.Label>{label}</Styled.Label>}
      <Styled.Field>
        {icon && (
          <Styled.IconWrapper>
            <Icon name={icon} />
          </Styled.IconWrapper>
        )}
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          hasIcon={!!icon}
          hasSuffix={!!suffix}
          suffixWidth={suffixWidth}
          ref={ref}
        />
        {suffix && <Styled.Suffix ref={refSuffix}>{suffix}</Styled.Suffix>}
      </Styled.Field>
    </Styled.Wrapper>
  );
});
