interface Props {}

const FormTitle = ({ children }: React.PropsWithChildren<Props>) => {
  return (
    <div className="flex flex-col gap-1 w-full mb-4">
      <div className="flex items-center">
        <h1 className="text-lg pb-0.5 leading-6 font-medium text-gray-900 mr-2 flex items-center">{children}</h1>
      </div>
    </div>
  );
};

export default FormTitle;
