import { useCallback, useState } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CaretRight, Eye, EyeSlash, Layout } from '@phosphor-icons/react';
import { type SerializableNode, type SortableColumn, Serializer } from '@shared/dream-components';

import BrowserBar from '@/components/PreviewModalV2/BrowserBar';
import { SortableList } from '@/components/SortableList';
import { Dialog, DialogContent, DialogFooter, DialogTrigger } from '@/routes/website/_components/UI/Dialog';
import SimpleSortableItem from '@/routes/website/_components/UI/SimpleSortableItem';
import IframeWrapper from '@/routes/website/page/_components/Iframe';
import { useContainerRef } from '@/routes/website/page/_hooks';
import { useFontHrefsFromContent } from '@/routes/website/page/_hooks/useFontHrefs';

import { Button } from '../../../../UI/Button';
import { Text } from '../../../../UI/Text';
import { AttributeSettingProps } from '../../types';

type SortableColumnListProps = {
  // activeNode value when the popup is opened
  defaultActiveNode: AttributeSettingProps['activeNodeResult']['activeNode'];
  setColumns: (columns: SortableColumn[]) => void;
};

const parseMobileLayout = (defaultActiveNode: AttributeSettingProps['activeNodeResult']['activeNode']) => {
  const { mobileLayout } = defaultActiveNode?.attrs || {};
  if (!defaultActiveNode?.attrs.mobileLayout) {
    return Array.from({ length: defaultActiveNode?.childCount || 0 }, (_, index) => ({
      id: defaultActiveNode?.children[index].attrs.id,
      label: `Desktop Column ${index + 1}`,
      columnIndex: index,
    })) as SortableColumn[];
  }

  // typecheck on mobileLayout, if it's string, parse it using JSON.parse
  const parsedMobileLayout: SortableColumn[] =
    typeof mobileLayout === 'string' ? JSON.parse(mobileLayout) : mobileLayout;

  if (parsedMobileLayout.length > defaultActiveNode?.childCount) {
    // remove any columns that are not in the defaultActiveNode
    return parsedMobileLayout.filter((column) => column.columnIndex < defaultActiveNode?.childCount);
  }

  if (parsedMobileLayout.length < defaultActiveNode?.childCount) {
    return parsedMobileLayout.concat(
      Array.from({ length: (defaultActiveNode?.childCount || 0) - parsedMobileLayout.length }, (_, index) => ({
        id: `desktop-column-${index + parsedMobileLayout.length + 1}`,
        label: `Desktop Column ${index + parsedMobileLayout.length + 1}`,
        columnIndex: index + parsedMobileLayout.length,
      }))
    );
  }

  return parsedMobileLayout;
};

const ColumnVisibilityToggle = ({
  column,
  onChange,
}: {
  column: SortableColumn;
  onChange: (isHidden: boolean) => void;
}) => {
  const { isHidden } = column;

  return (
    <Button size="sm" variant="ghost" onClick={() => onChange(!isHidden)}>
      {isHidden ? <EyeSlash size={16} /> : <Eye size={16} />}
    </Button>
  );
};

const SortableColumnList = ({ defaultActiveNode, setColumns }: SortableColumnListProps) => {
  const [columns, setInternalColumns] = useState<SortableColumn[]>(parseMobileLayout(defaultActiveNode));

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = columns.findIndex((column) => column.id === active.id);
      const newIndex = columns.findIndex((column) => column.id === over?.id);
      const newList = arrayMove(columns, oldIndex, newIndex);
      setColumns(newList);
      setInternalColumns(newList);
    }
  };

  const handleToggleVisibility = useCallback(
    (index: number) => (isHidden: boolean) => {
      const newList = [...columns];
      newList[index].isHidden = isHidden;
      setColumns(newList);
    },
    [setColumns, columns]
  );

  return (
    <SortableList listItems={columns} onDragEndEvent={onDragEnd}>
      {(list) => {
        return list.map((item: SortableColumn, index: number) => {
          const toggleVisibilityAction = (
            <ColumnVisibilityToggle column={item} onChange={handleToggleVisibility(index)} />
          );
          return (
            <SimpleSortableItem
              id={item.id}
              key={item.id}
              label={item.label}
              onClick={() => {}}
              isSelected={false}
              actions={toggleVisibilityAction}
            />
          );
        });
      }}
    </SortableList>
  );
};

const BROWSER_BAR_HEIGHT = 30;

export const ColumnOrderSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNode, activeNodePos } = activeNodeResult;

  const setColumns = useCallback(
    (c: SortableColumn[]) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'mobileLayout', c);
        return true;
      });
    },
    [editor, activeNodePos]
  );

  const initialContent = activeNode?.toJSON();
  const [isOpen, setIsOpen] = useState(false);

  const {
    rect: previewRect,
    containerRef: previewContainerRef,
    // recalibrate: recalibratePreviewRect,
  } = useContainerRef({
    enabled: isOpen,
  });

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  const fontHrefs = useFontHrefsFromContent(initialContent);

  return (
    <div className="flex items-center justify-stretch gap-2">
      <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
        <Text variant="secondary" size="2xs" weight="medium">
          Mobile Layout
        </Text>
      </div>
      <Dialog open={isOpen} onOpenChange={handleOpenChange}>
        <DialogTrigger asChild>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 p-2 ">
              <div className="flex items-center gap-1">
                <Layout className="text-wb-secondary" weight="bold" />
                <Text size="2xs" weight="medium">
                  Setting
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </DialogTrigger>
        <DialogContent className="w-[800px] max-w-[95vw] p-0">
          <div className="flex flex-col p-6 pb-0 h-[60vh]">
            <div className="flex grow min-h-0">
              <div className="shrink-0">
                <Text size="lg" weight="semibold" className="block">
                  Mobile Layout
                </Text>
                <Text size="2xs" weight="medium" className="mb-6 block">
                  Drag and drop columns to reorder them.
                </Text>
                <ul>
                  <SortableColumnList defaultActiveNode={activeNode} setColumns={setColumns} />
                </ul>
              </div>
              <div className="h-full w-full rounded-r-md overflow-auto">
                <div
                  className="h-full w-fit m-auto flex flex-col shadow-xl rounded-xl border-b border-surface-200 max-w-md"
                  ref={previewContainerRef}
                >
                  <BrowserBar screenSize="mobile" />
                  <IframeWrapper
                    width={previewRect.width || 0}
                    height={(previewRect.height || 0) - BROWSER_BAR_HEIGHT}
                    contentWidth={375}
                    fontHrefs={fontHrefs}
                  >
                    {initialContent && <Serializer node={initialContent as SerializableNode} />}
                  </IframeWrapper>
                </div>
              </div>
            </div>
          </div>
          <DialogFooter className="flex justify-between items-center p-4 bg-white border-t border-wb-primary">
            <div className="flex gap-2 w-full justify-end">
              <Button variant="primary" onClick={() => setIsOpen(false)}>
                Done
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
