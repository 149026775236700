import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { FootnotesNodeView } from './views';

export const FootnotesNode = Node.create({
  name: 'footnotesNode',

  content: 'footnoteItem+',

  defining: true,

  selectable: false,

  parseHTML() {
    return [
      {
        tag: 'div[data-node-type="footnotesNode"]',
      },
    ];
  },

  renderHTML() {
    return ['div', { 'data-node-type': 'footnotesNode' }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(FootnotesNodeView);
  },
});
