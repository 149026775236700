import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient, UseQueryResult } from 'react-query';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import Card from '../../../components/Card';
import PageHeading from '../../../components/Layout/PageLayout/PageHeading';
import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { useWebTemplate } from '../../../hooks';
import { useTestimonials } from '../../../hooks/useTestimonials';
import { Testimonial } from '../../../interfaces/testimonial';
import { WebTemplate } from '../../../interfaces/web_template';
import api from '../../../services/swarm';

import TestimonialCard from './TestimonialCard';
import TestimonialForm from './TestimonialForm';

const TestimonialsIndex = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const [isAdding, setIsAdding] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: UseQueryResult<WebTemplate, Error> = webTemplateRequest;

  const testimonialsQuery = useTestimonials({ search: '' });
  const { data, isLoading } = testimonialsQuery;
  const testimonials = data?.pages.flatMap((page) => page.testimonials) || [];
  const totalTestimonialsCount = data?.pages[0]?.pagination?.total;
  const showingTestimonialsCount = testimonials?.length || 0;
  const isNoResults = !isLoading && testimonials.length === 0;

  const handleAddTestimonial = (payload: any): void => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('testimonial[name]', payload.name);
    formData.append('testimonial[description]', payload.description);
    formData.append('testimonial[message]', payload.message);
    formData.append('testimonial[picture]', payload.picture);

    // For now we only support one landing page per publication.
    // In the future this might change and this value should be more dynamic.
    if (payload.addToLandingPage) {
      formData.append('landing_page_id', webTemplate?.landing_page_id || '');
    }

    api
      .post('testimonials', formData)
      .then(() => {
        queryClient.invalidateQueries(['testimonials', currentPublicationId]);
        toast.success(`Created!`);
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast(error);
      })
      .finally(() => {
        setIsSaving(false);
        setIsAdding(false);
      });
  };

  const renderNotResults = () => {
    const text = `Once you create your first testimonial, it will show up here.`;

    return (
      <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500 text-center">{text}</p>

          <Button variant="primary" onClick={() => setIsAdding(true)}>
            <div className="flex">
              <DocumentPlusIcon className="h-5 w-5 mr-2" />
              Add Testimonial
            </div>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <TestimonialForm
        headerText="Add Testimonial"
        ctaText="Create"
        isOpen={isAdding}
        testimonial={null}
        onClose={() => setIsAdding(false)}
        isProcessing={isSaving}
        onProceed={handleAddTestimonial}
      />
      <PageHeading
        title="Testimonials"
        description="Add testimonials to share with your audience & boost your publication's credibility"
      >
        <div className="flex justify-end">
          <Button variant="primary" onClick={() => setIsAdding(true)} loading={false}>
            Add Testimonial
          </Button>
        </div>
      </PageHeading>

      <div className="mb-1">
        <span className="text-xs font-semibold text-gray-600">{`Showing ${showingTestimonialsCount} of ${totalTestimonialsCount} results`}</span>
      </div>
      <Card noPadding>
        {isNoResults ? (
          renderNotResults()
        ) : (
          <ul className="divide-y rounded">
            {testimonials.map((testimonial: Testimonial) => {
              return (
                <TestimonialCard
                  key={testimonial.id}
                  testimonial={testimonial}
                  landingPageId={webTemplate?.landing_page_id || ''}
                />
              );
            })}
          </ul>
        )}
      </Card>
    </div>
  );
};

export default TestimonialsIndex;
