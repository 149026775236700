import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '../../services/swarm';

type APIPayload = {
  name: string;
};

interface Props {
  keyId: string | undefined;
  onSuccess?: () => void;
}

const useUpdateApiKey = ({ keyId, onSuccess }: Props, publicationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (apiKey: APIPayload) =>
      api.patch(`/api_keys/${keyId}`, {
        publication_id: publicationId,
        api_key: apiKey,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['api_keys', publicationId]);
        toast.success('Key updated!');

        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export default useUpdateApiKey;
