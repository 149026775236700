import {
  BanknotesIcon,
  CursorArrowRaysIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';

export interface MetricsBadgeProps {
  totalSent?: number;
  openRate?: number;
  clickRate?: number;
  totalUpgrades?: number;
  totalWebViewed?: number;
  totalWebClicked?: number;
}

const MetricsBadge = ({
  totalSent,
  openRate,
  clickRate,
  totalUpgrades,
  totalWebViewed,
  totalWebClicked,
}: MetricsBadgeProps) => {
  const formatter = Intl.NumberFormat('en');

  return (
    <div className="flex gap-5">
      {typeof totalSent === 'number' && (
        <div>
          <Tooltip id="recipients-tooltip" text="Recipients" showIcon={false} autoWidth>
            <span className="flex items-center gap-3">
              <EnvelopeIcon className="h-5 w-5 text-gray-400" />
              <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
                {formatter.format(totalSent)}
              </Typography>
            </span>
          </Tooltip>
        </div>
      )}
      {typeof totalWebViewed === 'number' && (
        <div>
          <Tooltip id="web-views-tooltip" text="Views" showIcon={false} autoWidth>
            <span className="flex items-center gap-3">
              <EyeIcon className="h-5 w-5 text-gray-400" />
              <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
                {formatter.format(totalWebViewed)}
              </Typography>
            </span>
          </Tooltip>
        </div>
      )}
      {typeof openRate === 'number' && (
        <div>
          <Tooltip id="open-rate-tooltip" text="Open rate" showIcon={false} autoWidth>
            <span className="flex items-center gap-3">
              <EnvelopeOpenIcon className="h-5 w-5 text-gray-400" />
              <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
                {openRate}%
              </Typography>
            </span>
          </Tooltip>
        </div>
      )}
      {typeof clickRate === 'number' && (
        <div>
          <Tooltip id="click-rate-email-tooltip" text="Click rate" showIcon={false} autoWidth>
            <span className="flex items-center gap-3">
              <CursorArrowRaysIcon className="h-5 w-5 text-gray-400" />
              <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
                {clickRate}%
              </Typography>
            </span>
          </Tooltip>
        </div>
      )}
      {typeof totalWebClicked === 'number' && (
        <div className="flex-1 min-w-0">
          <Tooltip id="click-rate-web-tooltip" text="Click rate" showIcon={false} autoWidth>
            <span className="flex items-center gap-3">
              <CursorArrowRaysIcon className="h-5 w-5 text-gray-400" />
              <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
                {totalWebClicked}
              </Typography>
            </span>
          </Tooltip>
        </div>
      )}
      {typeof totalUpgrades === 'number' && (
        <div className="flex-1 min-w-0">
          <Tooltip id="upgradews-tooltip" text="Upgrades" showIcon={false} autoWidth>
            <span className="flex items-center gap-3">
              <BanknotesIcon className="h-5 w-5 text-gray-400" />
              <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
                {formatter.format(totalUpgrades)}
              </Typography>
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default MetricsBadge;
