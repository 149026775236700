import { WebhookMessage } from './message';

export enum MessageAttemptStatus {
  SUCCESS = 0,
  PENDING = 1,
  FAILED = 2,
  SENDING = 3,
}

export const MessageAttemptStatusString = {
  [MessageAttemptStatus.SUCCESS]: 'Succeeded',
  [MessageAttemptStatus.PENDING]: 'Pending',
  [MessageAttemptStatus.FAILED]: 'Failed',
  [MessageAttemptStatus.SENDING]: 'Sending',
};

export enum MessageAttemptTriggerType {
  SCHEDULED = 0,
  MANUAL = 1,
}

export interface WebhookMessageAttempt {
  id: string;
  endpoint_id: string;
  response: string;
  response_status_code: number;
  status: MessageAttemptStatus;
  trigger_type: MessageAttemptTriggerType;
  message_id: string;
  timestamp: string;
  message: WebhookMessage;
}
