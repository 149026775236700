import { Input, RadioSelect } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';

import BodyContainer from '../../../../_components/BodyContainer';
import CardHeader from '../../../../_components/CardHeader';

import CodeBlock from './CodeBlock';
import CustomOptionNode from './CustomOptionNode';

interface Props {
  hasErrors: boolean;
  webDomain: string | null;
  redirectToWWW: boolean;
  setRedirectToWWW: (value: boolean) => void;
  shouldRedirectToDomain: boolean;
  setShouldRedirectToDomain: (value: boolean) => void;
  redirectToDomain: string | null;
  setRedirectToDomain: (value: string | null) => void;
}

const RedirectForm = ({
  hasErrors,
  webDomain,
  redirectToWWW,
  setRedirectToWWW,
  shouldRedirectToDomain,
  setShouldRedirectToDomain,
  redirectToDomain,
  setRedirectToDomain,
}: Props) => {
  const webDomainHasWWW = webDomain && webDomain.indexOf('www') > -1;

  return (
    <BodyContainer>
      <CardHeader
        title="Redirect Web Domain"
        description="We recommend that you redirect your root domain to your website's subdomain as redirecting to a subdomain is generally considered a good practice for both web and email deliverability."
      />
      <TypographyStack>
        <Typography token="font-medium/text/sm">Example:</Typography>
        <Typography token="font-normal/text/sm" colorWeight="700">
          For a Web Domain, you can use <CodeBlock>www.yourdomain.com</CodeBlock> and then configure the{' '}
          <CodeBlock>yourdomain.com</CodeBlock> as a redirect to your beehiiv website.
        </Typography>
      </TypographyStack>
      <RadioSelect
        // eslint-disable-next-line no-nested-ternary
        value={
          // eslint-disable-next-line no-nested-ternary
          redirectToWWW ? 'redirect_to_www' : shouldRedirectToDomain ? 'should_redirect_to_domain' : 'no_redirect'
        }
        CustomOptionNode={CustomOptionNode}
        options={[
          {
            name: <Typography token="font-medium/text/sm">No Redirect</Typography>,
            value: 'no_redirect',
          },
          {
            name: (
              <Typography token="font-medium/text/sm">
                Redirect{' '}
                {webDomainHasWWW ? (
                  'your root domain'
                ) : (
                  <>
                    your <CodeBlock>www.</CodeBlock> subdomain
                  </>
                )}{' '}
                to your beehiiv website
              </Typography>
            ),
            value: 'redirect_to_www',
            isVisible: Boolean(webDomain),
          },
          {
            name: <Typography token="font-medium/text/sm">Redirect something else</Typography>,
            value: 'should_redirect_to_domain',
            isVisible: Boolean(webDomain),
            children: (
              <Input
                labelText="Redirect Location"
                required
                name="redirect_to_domain"
                placeholder="yourdomain.com"
                defaultValue={redirectToDomain || undefined}
                onChange={(e) => e.stopPropagation()}
                onBlur={(e) => setRedirectToDomain(e.target.value)}
                errorText={hasErrors ? 'Please enter a valid url' : undefined}
              />
            ),
          },
        ]}
        onSelect={(value: string) => {
          if (value === 'no_redirect') {
            setRedirectToWWW(false);
            setShouldRedirectToDomain(false);
            setRedirectToDomain(null);
          } else if (value === 'redirect_to_www') {
            setRedirectToWWW(!redirectToWWW);
            setShouldRedirectToDomain(false);
            setRedirectToDomain(null);
          } else if (value === 'should_redirect_to_domain') {
            setShouldRedirectToDomain(!shouldRedirectToDomain);
            setRedirectToWWW(false);
          }
        }}
      />
    </BodyContainer>
  );
};

export default RedirectForm;
