import { useMemo, useState } from 'react';

import { TypeaheadMultiSelect } from '@/components/Form';
import SelectedOptionBadges from '@/components/Form/SelectedOptionBadges';
import { Author } from '@/interfaces/author';
import { Option } from '@/interfaces/general';
import { normalizeString } from '@/utils';

type AuthorsProps = {
  defaultSelection: string[];
  handleChange: (data: any) => void;
  users: Author[];
};

const Authors = ({ users, defaultSelection, handleChange }: AuthorsProps) => {
  const [query, setQuery] = useState('');
  const [currentSelection, setCurrentSelection] = useState<string[]>(defaultSelection);

  const handleSearch = (): Promise<Option[]> => {
    const options =
      users
        ?.filter((user) => normalizeString(user.name).includes(query))
        .map((user) => ({ label: user.name, value: user.id })) || [];

    return new Promise((resolve) => {
      resolve(options);
    });
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setQuery(normalizeString(newQuery));
  };

  const handleOnClear = () => setQuery('');

  const handleSelect = (name: string, value: string) => {
    const newCurrentSelection = [...currentSelection, value];

    setCurrentSelection(newCurrentSelection);
    handleChange({ user_ids: newCurrentSelection });
  };

  const handleDeselect = (name: string, value: string) => {
    const newCurrentSelection = currentSelection.filter((item) => item !== value);

    setCurrentSelection(newCurrentSelection);
    handleChange({ user_ids: newCurrentSelection });
  };

  const handleDeselectViaBadge = (value: string) => {
    handleDeselect('', value);
  };

  const handleDeselectAll = () => {
    setCurrentSelection([]);
  };

  const userNameByUserId = useMemo(() => {
    const mappedUsers: {
      [key: string]: string;
    } = {};
    users.forEach((user) => {
      mappedUsers[user.id] = user.name;
    });

    return mappedUsers;
  }, [users]);

  const selectedOptions: Option[] = useMemo(
    () =>
      currentSelection.map((userId: string) => ({
        label: userNameByUserId[userId],
        value: userId,
      })),
    [currentSelection, userNameByUserId]
  );

  return (
    <div className="flex flex-col gap-y-2">
      <TypeaheadMultiSelect
        emptyLabel="No authors found"
        labelText="Authors"
        name="new-post-authors-multiselect"
        onClear={handleOnClear}
        onDeselect={handleDeselect}
        onDeselectAll={handleDeselectAll}
        onSearch={handleSearch}
        onSearchQueryChange={handleSearchQueryChange}
        onSelect={handleSelect}
        placeholderText="Select Author(s)"
        values={currentSelection}
        showClearAll={false}
        shouldCloseOnSelection={false}
        helperText={
          <span>
            Manage the authors page for your website{' '}
            <a
              href={`${window.location.origin}/website_builder/settings/static_pages`}
              target="_blank"
              rel="noreferrer"
              className="underline text-primary-500"
            >
              here
            </a>
            .
          </span>
        }
      />
      <SelectedOptionBadges options={selectedOptions} onDeselect={handleDeselectViaBadge} />
    </div>
  );
};

export default Authors;
