import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';

import { usePostContext } from '../../PostContext';

const VisibilitySettings = () => {
  const { formData, onChange } = usePostContext();
  const displayTitleInEmail = !!formData?.display_subtitle_in_email;

  const onToggleShowInEmail = () => {
    onChange({ display_subtitle_in_email: !displayTitleInEmail });
  };

  return (
    <Styled.ContentWrapper>
      <Panel>
        <PanelHeader>
          <h3 className="px-2 p-1 pt-2 text-sm text-surface-200 font-medium">Visibility</h3>
          <p className="text-xs px-2">
            The subtitle is shown on both web and email post. You can toggle it off for email.
          </p>
        </PanelHeader>
        <PanelSection>
          <Styled.PanelSectionInner>
            <ToggleWrapper
              icon={<Icon name="Mail" />}
              switchEl={<ToggleSwitch isChecked={displayTitleInEmail} onChange={onToggleShowInEmail} />}
            >
              Show in email
            </ToggleWrapper>
          </Styled.PanelSectionInner>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default VisibilitySettings;
