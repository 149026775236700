import { usePageContext } from './PageContext';
import { ScreenSize } from './ScreenSize';

const ScreenSizeHelper = () => {
  const { screenSize, setScreenSize } = usePageContext();

  return <ScreenSize screenSize={screenSize} setScreenSize={setScreenSize} />;
};

export default ScreenSizeHelper;
