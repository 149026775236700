import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { PremiumSettings } from '../../interfaces/premium_settings';
import api from '../../services/swarm';

const usePremiumSettings = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<PremiumSettings>(
    ['premium_settings', 'publications', currentPublicationId],
    () =>
      api
        .get(`/publications/${currentPublicationId}/premium_settings`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!currentPublicationId,
    }
  );
};

export default usePremiumSettings;
