export const getThemeFromBgColor = (backgroundColor: string): 'light' | 'dark' => {
  const color = backgroundColor.replaceAll('#', '');

  // Check if the color string has exactly 6 characters
  if (color.length !== 6) {
    throw new Error('Invalid background color format. Expected a 6 character hex string.');
  }

  // Convert to RGB values
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  // Check if the RGB values are valid numbers
  if (Number.isNaN(r) || Number.isNaN(g) || Number.isNaN(b)) {
    throw new Error('Invalid background color format. Expected a valid hex string.');
  }

  // Calculate the brightness
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return 'light' or 'dark' based on the brightness
  return brightness > 155 ? 'light' : 'dark';
};
