import { FC, useCallback } from 'react';
import toast from 'react-hot-toast';

import { useCurrentPublication } from '../../hooks';
import { EmailMessage, EmailMessagePurpose } from '../../interfaces/email_message';
import { Option } from '../../interfaces/general';
import api from '../../services/swarm';
import { SearchDropdown } from '../Form';

interface Props {
  labelText?: string;
  helperText?: string;
  className?: string;
  labelClassName?: string;
  emailMessageId?: string;
  emailMessagePurpose?: EmailMessagePurpose;
  onSelect: (id: string) => void;
  onClear: () => void;
  dropdownClassnames?: string;
}

const EmailMessageSelect: FC<Props> = (props: Props) => {
  const {
    labelText = 'Message',
    helperText,
    className,
    labelClassName,
    emailMessageId,
    emailMessagePurpose,
    onSelect,
    onClear,
    dropdownClassnames,
  } = props;
  const { data: currentPublication } = useCurrentPublication();

  const fetchEmailMessages = useCallback(
    (inputValue?: string) => {
      const params = {
        publication_id: currentPublication?.id,
        purpose: emailMessagePurpose,
        q: inputValue,
      };

      return api
        .get(`/email_messages`, { params })
        .then((res) => {
          const emailMessages = res.data?.email_messages || [];

          const options: Option[] = emailMessages
            .filter((e: EmailMessage) => !!e.last_sent_at) // We only care about seeing published messages right now
            .map((emailMessage: EmailMessage) => {
              const val: Option = {
                label: emailMessage.subject_line || 'No Subject',
                value: emailMessage.id,
              };
              return val;
            });
          return options;
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },

    [currentPublication?.id, emailMessagePurpose]
  );

  const loadInitialValue = useCallback(() => {
    if (!emailMessageId) {
      return undefined;
    }

    const params = {
      publication_id: currentPublication?.id,
    };

    return api
      .get(`/email_messages/${emailMessageId}`, { params })
      .then((res) => {
        const emailMessage = res.data;
        const option = emailMessage && {
          label: emailMessage.subject_line,
          value: emailMessage.id,
        };

        return option;
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [currentPublication, emailMessageId]);

  const handleSelect = (_name: string, value: string) => {
    onSelect(value);
  };

  const handleClear = () => {
    onClear();
  };

  if (!currentPublication) {
    return null;
  }

  return (
    <SearchDropdown
      name="email_message"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText="Select a message"
      value={emailMessageId || ''}
      onSelect={handleSelect}
      onClear={handleClear}
      loadOptions={fetchEmailMessages}
      loadInitialValue={loadInitialValue}
      labelClassName={labelClassName}
      className={className}
      dropdownClassnames={dropdownClassnames}
      emptyLabel="No messages found"
    />
  );
};

export default EmailMessageSelect;
