import {
  ArrowsOutLineVertical,
  ClipboardText,
  CurrencyCircleDollar,
  HardDrive,
  HardDrives,
  Image as ImageIcon,
  ListBullets,
  ListChecks,
  ListNumbers,
  Quotes,
  RectangleDashed,
  Slideshow,
  Square,
  SquareSplitHorizontal,
  Tag,
  Textbox,
  TextHFive,
  TextHFour,
  TextHOne,
  TextHSix,
  TextHThree,
  TextHTwo,
  TextT,
  Users,
  XLogo,
} from '@phosphor-icons/react';

import { selectThreeRandomAuthors } from '../dummyData/authors';
import { selectRandomPosts } from '../dummyData/posts';
import { tiers } from '../dummyData/pricing';
import {
  RECOMMENDATION_1,
  RECOMMENDATION_2,
  RECOMMENDATION_3,
  RECOMMENDATION_4,
  RECOMMENDATION_5,
  RECOMMENDATION_6,
} from '../dummyData/recommendations';
import { SOCIALS } from '../dummyData/socials';
import { survey } from '../dummyData/survey';
import { DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG } from '../dummyData/tags';
import { TESTIMONIALS } from '../dummyData/testimonials';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Authors,
  BulletList,
  Button,
  Columns,
  Container,
  Divider,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Paragraph,
  Post,
  Pricing,
  Recommendations,
  Section,
  Signup,
  Socials,
  Survey,
  Tags,
  Testimonials,
} from '../extensions';

export const LABELS_BY_CATEGORY: Record<string, string> = {
  templates: 'Templates',
  layout: 'Layout',
  widget: 'Widgets',
  text: 'Text',
  lists: 'Lists',
  media: 'Media',
};

export const components = {
  paragraph: {
    label: 'Text',
    category: 'text',
    Icon: TextT,
    block: () => ({
      type: Paragraph.name,
      attr: {
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'paragraph',
        },
      ],
    }),
  },
  heading1: {
    label: 'Head 1',
    category: 'text',
    Icon: TextHOne,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 1,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 1',
        },
      ],
    }),
  },
  heading2: {
    label: 'Head 2',
    category: 'text',
    Icon: TextHTwo,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 2,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 2',
        },
      ],
    }),
  },
  heading3: {
    label: 'Head 3',
    category: 'text',
    Icon: TextHThree,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 3,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 3',
        },
      ],
    }),
  },
  heading4: {
    label: 'Head 4',
    category: 'text',
    Icon: TextHFour,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 4,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 4',
        },
      ],
    }),
  },
  heading5: {
    label: 'Head 5',
    category: 'text',
    Icon: TextHFive,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 5,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 5',
        },
      ],
    }),
  },
  heading6: {
    label: 'Head 6',
    category: 'text',
    Icon: TextHSix,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 6,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 6',
        },
      ],
    }),
  },
  pricing: {
    label: 'Pricing Cards',
    category: 'widget',
    Icon: CurrencyCircleDollar,
    block: () => ({
      type: Pricing.name,
      attrs: {
        data: tiers,
        insertedFromSidebar: true,
        hasFakeData: true,
      },
    }),
  },
  testimonials: {
    label: 'Testimonials',
    category: 'widget',
    Icon: Quotes,
    block: () => ({
      type: Testimonials.name,
      attrs: {
        data: TESTIMONIALS,
      },
    }),
  },
  section: {
    label: 'Section',
    category: 'layout',
    Icon: RectangleDashed,
    block: () => ({
      type: Section.name,
      attrs: {
        innerWidthMax: '1280px',
      },
    }),
  },
  container: {
    label: 'Container',
    category: 'layout',
    Icon: Square,
    block: () => ({
      type: Container.name,
      attrs: {},
    }),
  },
  columns: {
    label: 'Columns',
    category: 'layout',
    Icon: SquareSplitHorizontal,
    block: () => ({
      type: Columns.name,
      attrs: {
        width: '100%',
        padding: '20px',
      },
      content: [
        {
          type: Container.name,
          attrs: {
            flexBasis: '50%',
          },
          content: [],
        },
        {
          type: Container.name,
          attrs: {
            flexBasis: '50%',
          },
          content: [],
        },
      ],
    }),
  },
  signup: {
    label: 'Signup',
    category: 'widget',
    Icon: Textbox,
    block: () => ({
      type: Signup.name,
      attrs: {
        width: '400px',
      },
      content: [],
    }),
  },
  recommendations: {
    label: 'Recommendations',
    category: 'widget',
    Icon: ListChecks,
    block: () => ({
      type: Recommendations.name,
      attrs: {
        data: [
          RECOMMENDATION_1,
          RECOMMENDATION_2,
          RECOMMENDATION_3,
          RECOMMENDATION_4,
          RECOMMENDATION_5,
          RECOMMENDATION_6,
        ],
      },
      content: [],
    }),
  },
  divider: {
    label: 'Divider',
    category: 'layout',
    Icon: ArrowsOutLineVertical,
    block: () => ({
      type: Divider.name,
      attrs: {},
      content: [],
    }),
  },
  numberedList: {
    label: 'Numbered List',
    category: 'lists',
    Icon: ListNumbers,
    block: () => ({
      type: OrderedList.name,
      attrs: {},
      content: [
        {
          type: ListItem.name,
          attrs: {},
          content: [
            {
              type: Paragraph.name,
              attrs: {},
              content: [
                {
                  type: 'text',
                  text: 'List Item 1',
                },
              ],
            },
          ],
        },
      ],
    }),
  },
  bulletedList: {
    label: 'Bullet List',
    category: 'lists',
    Icon: ListBullets,
    block: () => ({
      type: BulletList.name,
      attrs: {},
      content: [
        {
          type: ListItem.name,
          attrs: {},
          content: [
            {
              type: Paragraph.name,
              attrs: {},
              content: [
                {
                  type: 'text',
                  text: 'List Item 1',
                },
              ],
            },
          ],
        },
      ],
    }),
  },
  accordion: {
    label: 'Accordion',
    category: 'lists',
    Icon: HardDrives,
    block: () => ({
      type: Accordion.name,
      attrs: {},
      content: [
        {
          type: AccordionTitle.name,
          attrs: {},
          content: [
            {
              type: Paragraph.name,
              content: [
                {
                  type: 'text',
                  text: 'Title',
                },
              ],
            },
          ],
        },
        {
          type: AccordionContent.name,
          attrs: {},
          content: [
            {
              type: Paragraph.name,
              content: [
                {
                  type: 'text',
                  text: 'Content',
                },
              ],
            },
          ],
        },
      ],
    }),
  },
  post: {
    label: 'Post',
    category: 'widget',
    Icon: Slideshow,
    block: () => ({
      type: Post.name,
      attrs: {
        width: 'full',
        cardCount: 3,
        gap: 8,
        orientation: 'horizontal',

        data: {
          posts: selectRandomPosts({ count: 3 }),
        },
      },
      content: [],
    }),
  },
  survey: {
    label: 'Survey',
    Icon: ClipboardText,
    category: 'widget',
    block: () => ({
      type: Survey.name,
      attrs: {
        src: '',
        data: survey,
      },
      content: [],
    }),
  },
  tags: {
    label: 'Tags',
    Icon: Tag,
    category: 'widget',
    block: () => ({
      type: Tags.name,
      attrs: {
        src: '',
        data: [DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG],
      },
      content: [],
    }),
  },
  media: {
    label: 'Image',
    Icon: ImageIcon,
    category: 'media',
    block: () => ({
      type: Image.name,
      attrs: {},
      content: [],
    }),
  },
  authors: {
    label: 'Authors',
    category: 'widget',
    Icon: Users,
    block: () => ({
      type: Authors.name,
      attrs: {
        data: {
          authors: selectThreeRandomAuthors({ count: 3 }),
        },
      },
      content: [],
    }),
  },
  socials_complete: {
    label: 'Socials',
    category: 'widget',
    Icon: XLogo,
    block: () => ({
      type: Socials.name,
      attrs: {
        data: SOCIALS,
        padding: '10px',
      },
      content: [],
    }),
  },
  button: {
    label: 'Button',
    category: 'widget',
    Icon: HardDrive,
    block: () => ({
      type: Button.name,
      content: [
        {
          type: 'text',
          text: 'Subscribe',
        },
      ],
    }),
  },
};
