import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkWorkspaceView } from '../../../interfaces/ad_network/internal/workspace_view';
import api from '../../../services/swarm';

interface SuccessResponse extends AdNetworkWorkspaceView {}
interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

export default function useWorkspaceViewCreate() {
  const navigate = useNavigate();

  return useMutation<SuccessResponse, ErrorResponse, FormData>(
    (formData) => api.post(`/ad_network/internal/workspace_views`, formData).then((res) => res.data),
    {
      onSuccess: ({ id }) => {
        toast.success('View created successfully');
        navigate(`/ad_network/workspace_views/${id}`);
      },
      onError: (error) => {
        const { errors } = error.response.data;

        if (errors) {
          Object.keys(errors).forEach((key) => {
            toast.error(`${key} ${errors[key][0]}`);
          });
        } else {
          toast.error('Something went wrong');
        }
      },
    }
  );
}
