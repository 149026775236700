// eslint-disable-next-line
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from 'react-query';
import { useCurrentUser } from '@/context/current-user-context';
import { useCurrentPublication } from '@/hooks';
import analytics from '@/utils/analytics';
import { Layout } from '@/routes/signup/_components/Layout';
import OnboardingStepForm from '../_components/OnboardingStepForm';
import UseLaunchOption from './_components/UseLaunchOption';
import TrialMaxOption from './_components/TrialMaxOption';
import useUpdateOnboarding from '../_hooks/useUpdateOnboarding';
import useCreateTrial from '../_hooks/useCreateTrial';

const OnboardingNextSteps = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentUser } = useCurrentUser();
  const { data: currentPublication } = useCurrentPublication();
  const updateOnboarding = useUpdateOnboarding();
  const createTrial = useCreateTrial();

  const [isRequesting, setIsRequesting] = useState(false);
  const [selectedOption, setSelectedOption] = useState('explore_max');

  const startLaunch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsRequesting(true);

    updateOnboarding
      .mutateAsync({ status: 'complete' })
      .then(() => {
        analytics.track('Completed Onboarding', { email: currentUser?.email });
      })
      .finally(() => {
        navigate('/');
      });
  };

  const startTrial = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsRequesting(true);

    updateOnboarding
      .mutateAsync({ status: 'complete' })
      .then(() => {
        if (currentUser?.id) {
          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
            state: 'trialing',
            currentPlan: 'launch',
          });
        }
        analytics.track('Completed Onboarding', { email: currentUser?.email });
        analytics.track('Trial Started', {
          state: 'trialing',
          currentPlan: 'launch',
          trialPlan: 'max',
        });
      })
      .then(() => createTrial.mutateAsync({ selectedPlan: 'max' }))
      .finally(() => queryClient.invalidateQueries(['publications', currentPublication?.id]));
  };

  return (
    <>
      <Helmet>
        <title>Start Free Trial - beehiiv</title>
      </Helmet>

      <Layout>
        <OnboardingStepForm
          title="You're almost in, just one more step to go:"
          subtitle="How would you like to get started?"
          className="max-w-5xl max-h-[60vh]"
          onSubmit={(e) => (selectedOption === 'use_launch' ? startLaunch(e) : startTrial(e))}
          onBack={() => navigate('/onboarding/business_questionnaire')}
          isProcessing={isRequesting}
          centered
        >
          <div className="w-full items-start space-y-6 flex flex-col md:flex-row md:space-x-10 md:space-y-0">
            <TrialMaxOption
              selected={selectedOption === 'explore_max'}
              onClick={() => setSelectedOption('explore_max')}
            />
            <UseLaunchOption
              selected={selectedOption === 'use_launch'}
              onClick={() => setSelectedOption('use_launch')}
            />
          </div>
        </OnboardingStepForm>
      </Layout>
    </>
  );
};

export default OnboardingNextSteps;
