export const placeholder = {
  blockquote: 'Enter a quote',
  blockquoteFigcaption: 'Author',
  button: 'Button text',
  genericEmbed: {
    title: 'Enter a title',
    description: 'Enter a description',
    url: 'Enter a URL',
  },
  caption: 'Add a caption …',
  default: 'Click here to start writing …',
  slashCommand: 'Type  /  to browse options',
  embedInput: {
    generic: 'Paste or type a URL',
    bluesky: 'Paste or type a Bluesky URL',
    twitter: 'Paste or type a Twitter URL',
    instagram: 'Paste or type an Instagram URL',
    tiktok: 'Paste or type a Tiktok URL',
    youtube: 'Paste or type a YouTube URL',
  },
};

export const embeds = {
  twitter: {
    regex: /^https?:\/\/(?:twitter|x)\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\?|\/|$)(\S+)?$/i,
  },
  bluesky: {
    regex: /^https?:\/\/bsky\.app\/profile\/.+\/post\/.+/i,
  },
  youtube: {
    regex:
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|shorts\/|v\/)?)([\w-]+)(\S+)?$/i,
  },
  instagram: {
    regex: /^https?:\/\/(?:www\.)?instagram\.com\/(\S+\/)?(?:p|reel|reels)\/([^/?#&]+)(\S+)?$/i,
  },
  tiktok: {
    regex:
      /^(https?:\/\/)?(?:(?:www)\.(?:tiktok\.com)(?:\/)(?!foryou)(@[a-zA-Z0-9_.]+)(?:\/)(?:video)(?:\/)([\d]+)|(?:m)\.(?:tiktok\.com)(?:\/)(?!foryou)(?:v)(?:\/)?(?=([\d]+)\.html)|vm\.tiktok\.com(?:\/)([\S]+)(?:\/))(\S+)?$/i,
  },
  generic: { regex: /^(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/i },
};

export const imageRegex = /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp))\S*$/i;

export const fileTypeRegex = /(epub\+zip|pdf)$/i;

export const audioTypeRegex = /(audio\/(mpeg|wav|ogg|flac|aac|webm))/i;

export enum EmbedService {
  TIKTOK = 'tiktok',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  BLUESKY = 'bluesky',
}
