/* eslint-disable */
import { BetweenDateEnum, DateFields, DateFilterTypes, DateOptionsProps } from '../interfaces/dates';

export interface AdvancedSearchOptionsProps {
  advanced: boolean;
  afterDate: Date;
  beforeDate: Date;
  dateField: DateFields;
  dateFilterType: DateFilterTypes;
  datesEnabled: boolean;
  endDate: Date;
  startDate: Date;
}

export interface DefaultSearchOptionsProps {
  dates: DateOptionsProps;
  showAdvanced: boolean;
}

export default class AdvancedSearchOptions implements AdvancedSearchOptionsProps {
  advanced: AdvancedSearchOptionsProps['advanced'];

  afterDate: AdvancedSearchOptionsProps['afterDate'];

  beforeDate: AdvancedSearchOptionsProps['beforeDate'];

  dateField: AdvancedSearchOptionsProps['dateField'];

  dateFilterType: AdvancedSearchOptionsProps['dateFilterType'];

  datesEnabled: AdvancedSearchOptionsProps['datesEnabled'];

  endDate: AdvancedSearchOptionsProps['endDate'];

  startDate: AdvancedSearchOptionsProps['startDate'];

  defaultDateOptions(): DateOptionsProps {
    return {
      enabled: this.advanced,
      [DateFilterTypes.BETWEEN]: {
        [BetweenDateEnum.START]: this.startDate,
        [BetweenDateEnum.END]: this.endDate,
      },
      [DateFilterTypes.BEFORE]: this.beforeDate,
      [DateFilterTypes.AFTER]: this.afterDate,
      dateFilterType: this.dateFilterType,
      dateField: this.dateField,
      datesEnabled: this.datesEnabled,
    };
  }

  defaultSearchOptions(): DefaultSearchOptionsProps {
    return {
      dates: this.defaultDateOptions(),
      showAdvanced: this.advanced,
    };
  }

  constructor(props: AdvancedSearchOptionsProps) {
    this.advanced = props.advanced;
    this.afterDate = props.afterDate;
    this.beforeDate = props.beforeDate;
    this.dateField = props.dateField;
    this.dateFilterType = props.dateFilterType;
    this.datesEnabled = props.datesEnabled;
    this.endDate = props.endDate;
    this.startDate = props.startDate;
  }
}

export const recomposeSearchOptions = (searchOptions: DefaultSearchOptionsProps): AdvancedSearchOptionsProps => ({
  advanced: searchOptions.showAdvanced,
  afterDate: searchOptions.dates[DateFilterTypes.AFTER],
  beforeDate: searchOptions.dates[DateFilterTypes.BEFORE],
  dateField: searchOptions.dates.dateField,
  dateFilterType: searchOptions.dates.dateFilterType,
  datesEnabled: searchOptions.dates.datesEnabled,
  endDate: searchOptions.dates[DateFilterTypes.BETWEEN][BetweenDateEnum.END],
  startDate: searchOptions.dates[DateFilterTypes.BETWEEN][BetweenDateEnum.START],
});
