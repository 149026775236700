import { useInfiniteQuery } from 'react-query';

import { Pagination } from '../../interfaces/general';
import { Integration } from '../../interfaces/integration';
import api from '../../services/swarm';

interface ApiResponse {
  publicationId: string;
  integrations: Integration[];
  pagination: Pagination;
}

export default function useIntegrations({
  publicationId,
  provider,
  enabled = true,
}: {
  publicationId: string;
  provider: string;
  enabled?: boolean;
}) {
  const fetchIntegrations = ({ pageParam = 1 }) =>
    api
      .get(`/integrations`, {
        params: {
          provider,
          page: pageParam,
          per_page: 15,
          publication_id: publicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['publications', publicationId, 'mailchimp_integrations'], fetchIntegrations, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
    enabled,
  });
}
