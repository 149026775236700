import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PostNodeContent } from '@/interfaces/post';
import api from '@/services/swarm';

export default function usePostNodeContent({ id }: { id: string }) {
  const [currentPublicationId] = useCurrentPublicationState();
  const params = {
    publication_id: currentPublicationId,
  };

  return useQuery<PostNodeContent>(
    ['posts', id, 'post_node_content'],
    () => api.get(`/posts/${id}/node_content`, { params }).then((res) => res.data),
    { enabled: !!currentPublicationId }
  );
}
