import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{ className?: string }>;

const ContainerNodeBody = ({ children, className }: Props) => (
  <div className={cx('w-full p-3 bg-white rounded-md', className)}>{children}</div>
);

export default ContainerNodeBody;
