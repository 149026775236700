import ClicksTable from '@/components/_domain/EmailMessage/ClicksTable';
import MetricsTable from '@/components/_domain/EmailMessage/MetricsTable';
import LoadingBox from '@/components/LoadingBox';
import { useEmailMessageStats } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { useWelcomeEmailContext } from '../_layout';

const WelcomeEmailAnalytics = () => {
  const { welcomeEmail } = useWelcomeEmailContext();
  const currentPublicationId = useCurrentPublicationId();
  const { data, isLoading, isError, isIdle } = useEmailMessageStats(currentPublicationId, welcomeEmail?.id);

  const {
    total_sent: totalSent = 0,
    total_unique_opened: totalUniqueOpened = 0,
    total_clicked: totalClicked = 0,
    total_unique_clicked: totalUniqueClicked = 0,
    total_spam_reported: totalSpamReported = 0,
    open_rate: openRate = 0,
    click_rate: clickRate = 0,
    spam_rate: spamRate = 0,
  } = data || {};

  return (
    <LoadingBox isLoading={isLoading || isIdle} isError={isError}>
      <>
        <section>
          <div className="mt-5 w-full rounded-lg mb-12">
            <MetricsTable
              totalSent={totalSent}
              totalUniqueOpened={totalUniqueOpened}
              totalClicked={totalClicked}
              totalUniqueClicked={totalUniqueClicked}
              totalSpamReported={totalSpamReported}
              openRate={openRate}
              clickRate={clickRate}
              spamRate={spamRate}
            />
          </div>
        </section>
        <section>
          <div className="bg-white rounded p-4">
            <ClicksTable publicationId={currentPublicationId} emailMessageId={welcomeEmail?.id} />
          </div>
        </section>
      </>
    </LoadingBox>
  );
};

export default WelcomeEmailAnalytics;
