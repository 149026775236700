import SplitButton from '@/components/SplitButton';

import { useSubscriberContext } from '../../_context/subscriberContext';

import useApproveAction from './_hooks/useApproveAction';
import useDeleteAction from './_hooks/useDeleteAction';
import useDenyAction from './_hooks/useDenyAction';
import useEnrollInAutomationAction from './_hooks/useEnrollInAutomationAction';
import useGiftPremiumAction from './_hooks/useGiftPremiumAction';
import useResubscribeAction from './_hooks/useResubscribeAction';
import useUnsubscribeAction from './_hooks/useUnsubscribeAction';

const ActionsButton: React.FC = () => {
  const { subscriber, subscription } = useSubscriberContext();
  const { id: subscriberId } = subscriber;
  const { id: subscriptionId, status } = subscription;

  const buttons = [
    ...useApproveAction({ subscriberId, status }),
    ...useDenyAction({ subscriberId, status }),
    ...useUnsubscribeAction({ subscriberId, status }),
    ...useResubscribeAction({ subscriberId, status }),
    ...useGiftPremiumAction({ subscriberId, status }),
    ...useEnrollInAutomationAction({ subscriptionId, status }),
    ...useDeleteAction({ subscriberId }),
  ];

  return <SplitButton variant="primary" buttons={buttons} />;
};

export default ActionsButton;
