import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  onSuccess?: () => void;
}

export default function useCreateSiteTemplate({ onSuccess }: Props) {
  return useMutation((formData: FormData) => api.post(`/site_templates`, formData), {
    onSuccess: () => {
      onSuccess?.();
      toast.success('Created!');
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
  });
}
