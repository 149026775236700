export const survey = {
  id: 'd3f1e7d6-c149-4123-9e3b-f5e9a202aef6',
  title: 'Customer Satisfaction Survey',
  description: 'We value your feedback. Please take a moment to answer these questions.',
  cta_text: 'Complete Survey',
  thank_you_message: 'Thank you for sharing your thoughts with us!',
  status: 'live',
  url: 'http://survey.example.com/forms/d3f1e7d6-c149-4123-9e3b-f5e9a202aef6',
  form_response_count: 134,
  form_questions: [
    {
      id: 'cf234fa1-d62d-49b0-9f5f-9e9b944cb9b3',
      order: 1,
      prompt: 'What is your first name?',
      required: true,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 100,
      min_character_limit: null,
      question_type: 'free_form',
      custom_field: {
        id: 'f54f9bb1-0831-47da-88d9-45c7a062fe8d',
        kind: 'string',
      },
      form_question_options: [],
    },
    {
      id: 'f1f74590-64c9-4067-9c42-470482d75e58',
      order: 2,
      prompt: 'Do you prefer online shopping?',
      required: true,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 200,
      min_character_limit: null,
      question_type: 'free_form',
      custom_field: {
        id: '774d0c90-9e8b-4e1b-91f9-79eaf96db3b1',
        kind: 'boolean',
      },
      form_question_options: [],
    },
    {
      id: 'f09a5a0e-85c2-44cf-b1c1-d89f8300a3d2',
      order: 3,
      prompt: 'Please select your preferred shopping method',
      required: true,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 100,
      min_character_limit: null,
      question_type: 'multiple_choice',
      custom_field: {
        id: '26c8d636-5897-4021-bde4-eecf084f317d',
        kind: 'string',
      },
      form_question_options: [
        {
          id: '512c5b45-f013-4e9f-a1d1-b914575d49ff',
          value: 'In-store',
          order: 1,
        },
        {
          id: 'ba47b207-b9fe-4b02-b6bc-2b5fe57b2cf1',
          value: 'Online',
          order: 2,
        },
        {
          id: '9896fef6-cb6f-463b-96cf-7849d4e5d5de',
          value: 'Both',
          order: 3,
        },
      ],
    },
    {
      id: '5b58fd33-4643-4161-ae16-256ce3e97824',
      order: 4,
      prompt: 'What color is your preferred product packaging?',
      required: false,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 200,
      min_character_limit: null,
      question_type: 'multiple_choice',
      custom_field: {
        id: 'd1a13bdf-4c88-4780-9a52-51e237f48b65',
        kind: 'string',
      },
      form_question_options: [
        {
          id: '3b5eae9f-bda9-4001-9388-d96ff11e84b2',
          value: 'Red',
          order: 1,
        },
        {
          id: '78c87c4e-6b0c-4294-bf09-f1cd17c88f38',
          value: 'Green',
          order: 2,
        },
        {
          id: '1a1e0fa7-09ef-4196-a5f1-13f9a8ed7b0c',
          value: 'Blue',
          order: 3,
        },
        {
          id: 'e4f622a3-8b66-4c0f-8779-76f62f2c4b88',
          value: 'Black',
          order: 4,
        },
      ],
    },
    {
      id: 'b5b3288e-04b0-44e0-8db6-99e2b5a5d6f3',
      order: 5,
      prompt: 'How satisfied are you with our service?',
      required: true,
      multi_select: false,
      show_max_characters: false,
      max_character_limit: 200,
      min_character_limit: null,
      question_type: 'dropdown',
      custom_field: {
        id: '507c02e2-3b0a-475b-b340-32434b3a036d',
        kind: 'string',
      },
      form_question_options: [
        {
          id: 'c4723ff1-4e79-4788-8c61-38f2ed25f2d4',
          value: 'Very Satisfied',
          order: 1,
        },
        {
          id: '1f624356-56d7-467d-b7d0-1d53ec8fa53b',
          value: 'Satisfied',
          order: 2,
        },
        {
          id: 'f3b2a33c-b54a-423e-b034-d4a58b19e8b9',
          value: 'Neutral',
          order: 3,
        },
        {
          id: '8cda5bdb-1614-44ff-8f6b-d6b505ff9ed7',
          value: 'Unsatisfied',
          order: 4,
        },
        {
          id: '58786a34-5b33-4262-9024-54b86f22b3b9',
          value: 'Very Unsatisfied',
          order: 5,
        },
      ],
    },
    {
      id: '781dbfd5-c2e1-4d02-9a74-fb6a774f5191',
      order: 6,
      prompt: 'Please provide any additional comments or suggestions.',
      required: false,
      multi_select: false,
      show_max_characters: true,
      max_character_limit: 500,
      min_character_limit: null,
      question_type: 'long_text',
      custom_field: {
        id: 'f9e4f29a-7870-43db-bf4d-3a7fd85a5c59',
        kind: 'string',
      },
      form_question_options: [],
    },
  ],
  created_at: '2024-11-25T12:45:00.000Z',
  most_recent_response: null,
};
