import { Typography, TypographyStack } from '@/components/Typography';
import { Card } from '@/ui/Card';

import Insights from './Insights';
import ReferralsTable from './ReferralsTable';

const OverviewTab = () => {
  return (
    <>
      <Card className="space-y-5">
        <TypographyStack>
          <Typography token="font-bold/text/base">Referral Program</Typography>
          <Typography token="font-normal/text/base" colorWeight="400">
            Shows overview of all your rewards and the milestones required for subscribers to achieve them
          </Typography>
        </TypographyStack>

        <ReferralsTable />
      </Card>
      <Insights />
    </>
  );
};

export default OverviewTab;
