import { useState } from 'react';

import BoostAgreementStatus from '@/interfaces/boosts/grow/boost_agreement_status';
import { OrderBy } from '@/interfaces/boosts/order';
import { Order } from '@/interfaces/general';

import useBoostAgreements from './useBoostAgreements';

interface Options {
  tagsOperator?: string;
  tags?: string[];
  languagesOperator?: string;
  languages?: string[];
  countries?: string[];
  postCountOperator?: string;
  postCount?: number;
  sizeScores?: number[];
  growthScores?: number[];
  engagementScores?: number[];
  boostQualityScores?: number[];
  direction?: Order;
  perPage?: number;
  returnAllIds?: boolean;
}

const useWebApplications = (boostOfferId: string, options?: Options) => {
  const [search, setSearch] = useState<string>('');
  const boostAgreementsQuery = useBoostAgreements({
    boostOfferId,
    search,
    status: BoostAgreementStatus.PENDING,
    tagsOperator: options?.tagsOperator,
    tags: options?.tags,
    languagesOperator: options?.languagesOperator,
    languages: options?.languages,
    countries: options?.countries,
    postCountOperator: options?.postCountOperator,
    postCount: options?.postCount,
    sizeScores: options?.sizeScores,
    growthScores: options?.growthScores,
    engagementScores: options?.engagementScores,
    boostQualityScores: options?.boostQualityScores,
    orderBy: OrderBy.CREATED_AT,
    direction: options?.direction || Order.DESC,
    perPage: typeof options?.perPage === 'number' ? options.perPage : 5,
    returnAllIds: options?.returnAllIds,
  });
  const {
    data,
    isLoading,
    isFetching,
    isRefetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isFetched,
  } = boostAgreementsQuery;
  const boostAgreements = data?.pages.flatMap((page) => page.boost_agreements) || [];
  const noResults = !isRefetching && !isLoading && boostAgreements.length === 0;

  const pagination = data?.pages[0]?.pagination;
  const allBoostAgreementIds = pagination?.ids || [];

  return {
    search,
    setSearch,
    boostAgreements,
    allBoostAgreementIds,
    count: data?.pages[0]?.pagination?.total || 0,
    noResults,
    isLoading,
    isError,
    isFetching,
    isFetched,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

export default useWebApplications;
