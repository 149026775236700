import React, { useState } from 'react';
import cx from 'classnames';
import RcSlider, { SliderProps } from 'rc-slider';
import styled from 'styled-components';

import { Input } from '../Form';
import Text from '../Text';
import Tooltip from '../Tooltip';

import 'rc-slider/assets/index.css';

export const Slider = styled<any>(RcSlider).attrs<SliderProps>({
  className: 'mt-2',
  trackStyle: {
    backgroundColor: '#0F172A',
  },
  handleStyle: {
    backgroundColor: '#0F172A',
    border: 'none',
  },
})``;

interface Props extends SliderProps {
  onChange: (val: number) => void;
  label: string;
  max: number;
  min: number;
  name: string;
  value: number;
  step?: number;
  className?: string;
  tooltip?: string;
  onTooltip?: () => void;
  valueSuffix?: string;
  warningText?: string;
  disabled?: boolean;
  portalMountedId?: string;
  inputClassName?: string;
  inputWrapperClassName?: string;
  labelClassName?: string;
}

const SliderField = ({
  onChange,
  label,
  value,
  name,
  className = '',
  tooltip,
  onTooltip,
  max,
  min,
  step = 1,
  valueSuffix,
  warningText,
  disabled = false,
  portalMountedId,
  inputClassName,
  inputWrapperClassName,
  labelClassName,
  ...restProps
}: Props) => {
  const constrainedValue = (val: number) => Math.min(Math.max(val, min), max);

  const [currentValue, setCurrentValue] = useState(constrainedValue(value));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(Number(event.target.value));
  };

  const handleSliderChange = (newValue: number) => {
    setCurrentValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleBlur = () => {
    setCurrentValue(constrainedValue(currentValue));
    if (onChange) {
      onChange(currentValue);
    }
  };

  const handleTooltipOnHover = () => {
    onTooltip?.();
  };

  return (
    <div className={className}>
      <div className="flex justify-between">
        <div className="flex items-center space-x-2">
          <div className={cx('font-medium text-sm', labelClassName)}>{label}</div>
          {tooltip && (
            <div>
              <Tooltip
                iconClass="text-gray-800"
                id={name}
                text={tooltip}
                onHover={handleTooltipOnHover}
                portalMountedId={portalMountedId}
              />
            </div>
          )}
        </div>
        <div className={cx('flex items-center space-x-2 text-sm', inputWrapperClassName)}>
          <Input
            name={name}
            value={currentValue.toString()}
            type="number"
            min={min}
            max={max}
            step={step}
            inputClassOverride={inputClassName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />
          {valueSuffix && <Text>{valueSuffix}</Text>}
        </div>
      </div>
      <div>
        <Slider
          min={min}
          max={max}
          value={currentValue}
          onChange={handleSliderChange}
          disabled={disabled}
          {...restProps}
        />
        {warningText ? <div className="text-xs text-yellow-600">{warningText}</div> : <div className="mt-5" />}
      </div>
    </div>
  );
};

export default SliderField;
