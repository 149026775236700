import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Recommendation } from '../interfaces/recommendation';
import api from '../services/swarm';

interface ApiResponse {
  boosted_recommendations: Recommendation[];
}

export default function useBoostedRecommendations({ enabled }: { enabled?: boolean } = { enabled: true }) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetch = () =>
    api
      .get(`/boosted_recommendations`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<ApiResponse>(['publications', currentPublicationId, 'boosted_recommendations'], fetch, {
    keepPreviousData: true,
    enabled,
  });
}
