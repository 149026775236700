export interface ICard {
  heading: string;
  description: string;
  imageUrl: string;
}

const Card = ({ card, index }: { card: ICard; index: number }) => (
  <div
    className="grid grid-cols-1 md:grid-cols-2 gap-8"
    style={{
      direction: index % 2 === 0 ? 'ltr' : 'rtl',
    }}
  >
    <div className="rounded-xl overflow-hidden">
      <img src={card.imageUrl} alt="" />
    </div>
    <div
      className="flex items-center"
      style={{
        direction: 'ltr',
      }}
    >
      <div className="space-y-4">
        <h2 className="text-2xl font-bold">{card.heading}</h2>
        <p className="text-lg">{card.description}</p>
      </div>
    </div>
  </div>
);

export default Card;
