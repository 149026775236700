/* eslint-disable consistent-return */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { TabPills } from '@/components/TabPills';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePostBacklinks } from '@/hooks';
import { Backlink as IBacklink } from '@/interfaces/backlink';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';

import { BACKLINK_TYPE } from '../../extensions/Backlink/const';
import { Button } from '../ui/Button';

import { Backlink } from './Backlink';

const tabs: ('incoming' | 'outgoing')[] = ['outgoing', 'incoming'];

export const BacklinksSidebar = () => {
  const { postId } = useParams<'postId'>() as { postId: string | undefined };

  const [currentPublicationId] = useCurrentPublicationState();

  const { editor } = useEditorContext();

  const backlinkTitlesById =
    editor?.storage?.[BACKLINK_TYPE]?.backlinkTitlesById || ({} as Record<string, { id: string; title: string }[]>);

  const [activeTab, setActiveTab] = useState<'incoming' | 'outgoing'>('outgoing');

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, refetch } = usePostBacklinks({
    postId: postId || '',
    publicationId: currentPublicationId,
    type: activeTab,
  });

  const backlinks = useMemo(() => {
    return (
      data?.pages
        .flatMap((page) => page.backlinks)
        .map((backlink) => {
          return {
            ...backlink,
            postToShow: activeTab === 'incoming' ? backlink.source : backlink.target,
          };
        }) || []
    );
  }, [data, activeTab]);

  const backlinksPerPost = useMemo(() => {
    const innerBacklinksPerPost: Record<string, IBacklink[]> = {};

    backlinks.forEach((backlink) => {
      const postToShowId = backlink.postToShow?.id;

      if (!postToShowId) {
        return;
      }

      if (!innerBacklinksPerPost[postToShowId]) {
        innerBacklinksPerPost[postToShowId] = [backlink];
      } else {
        innerBacklinksPerPost[postToShowId].push(backlink);
      }
    });

    return innerBacklinksPerPost;
  }, [backlinks]);

  useEffect(() => {
    const debouncedRefetch = debounce(() => refetch(), 3000);

    editor?.on('update', debouncedRefetch);

    return () => {
      editor?.off('update', debouncedRefetch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReferenceClick = useCallback(
    (id: string) => {
      editor?.commands.focusBacklink(id);
    },
    [editor]
  );

  return (
    <div className="w-[25%] min-w-[360px] h-full border-l border-l-surface-100 flex-col pt-3 sticky top-0 hidden lg:flex">
      <section className="flex justify-between px-6 border-b border-surface-100 pb-2 items-center">
        <div>Backlinks</div>

        <TabPills.Wrapper className="m-0">
          {tabs?.map((tab) => (
            <TabPills.Item
              key={tab}
              active={activeTab === tab}
              onClick={() => setActiveTab(tab)}
              className="capitalize"
            >
              {tab}
            </TabPills.Item>
          ))}
        </TabPills.Wrapper>
      </section>

      <section className="px-6 py-4 flex flex-1 flex-col overflow-y-auto gap-2">
        {backlinks.length === 0 ? (
          <div className="flex flex-col items-center justify-center gap-2 text-gray-500">
            <p>No {activeTab} backlinks</p>
          </div>
        ) : (
          Object.entries(backlinksPerPost)
            .sort((a, b) => b[1].length - a[1].length)
            .map(([postToShowId, postToShowBacklinks]) => (
              <Backlink
                key={`backlilnk-${postToShowId}`}
                backlink={postToShowBacklinks[0]}
                length={postToShowBacklinks.length}
                references={backlinkTitlesById[postToShowId]}
                onFocusReference={handleReferenceClick}
              />
            ))
        )}

        {hasNextPage && (
          <div className="flex justify-center">
            <Button
              $variant="tertiary"
              $size="small"
              $isIconButton
              $isLoading={isFetchingNextPage}
              disabled={!hasNextPage}
              onClick={fetchNextPage}
            >
              Load more
            </Button>
          </div>
        )}
      </section>
    </div>
  );
};
