import { Outlet } from 'react-router-dom';

import { useSite } from '@/hooks/useSite';

const OnboardingRedirector = () => {
  // There is an internal redirect on failure within the hook here.
  const { isLoading } = useSite();

  if (isLoading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center p-8">
        <div className="animate-pulse h-full w-full bg-gray-100" />
      </div>
    );
  }

  return <Outlet />;
};

export default OnboardingRedirector;
