export const FALLBACK_NODE_TITLE: string = 'Individual anchor';

export const DEFAULT_NODE_TITLES: Record<string, string> = {
  blockquoteFigure: 'Quote',
  bulletList: 'Bulleted list',
  orderedList: 'Numbered list',
  imageBlock: 'Image',
  poll: 'Poll',
  recommendation: 'Recommendation',
  section: 'Section',
  columns: 'Columns',
  button: 'Button',
  codeBlock: 'Code block',
  referralProgram: 'Referral program',
  boost: 'Boost',
  paywallBreak: 'Content for paying clients',
  subscriberBreak: 'Content for subscribers',
  advertisementOpportunity: 'Advertisement',
  htmlSnippet: 'HTML snippet',

  // heading: 'Headline', => Use text content, max. 50 chars
  // paragraph: 'paragraph', => Use text content, max. 50 chars
  // serviceEmbed: => Use service name value
  // genericEmbed: => Use title/text returned in the embed
};
