import { Link } from 'react-router-dom';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';
import styled from 'styled-components';

import Card from '@/components/Card';
import LoadingBox from '@/components/LoadingBox';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import usePostsFactPostClickExport from '@/hooks/usePostsFactPostClickExport';
import { Post, PostPlatform, PostStatus } from '@/interfaces/post';
import { SubscriberPostClick } from '@/interfaces/subscriber_post_click';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import MetricsBanner from './MetricsBanner';
import PostFactPostClicksContainer from './PostFactPostClicksContainer';
import PostFactPostClicksMetricsContainer from './PostFactPostClicksMetricsContainer';

const TABLE_HEADERS = ['Email', 'Clicked At'];

const MaxHeightWrapper = styled.div.attrs({
  className: 'overflow-x-hidden rounded border border-gray-200 relative',
})``;

interface DetailsProps {
  post: Post;
  factPostClickId: string;
}

const Details = ({ post, factPostClickId }: DetailsProps) => {
  const postId = post.id;
  const { platform } = post;

  const showEmailStats = platform === PostPlatform.EMAIL || platform === PostPlatform.BOTH;
  const showWebStats = platform === PostPlatform.WEB || platform === PostPlatform.BOTH;

  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;
  const isV2 = settings?.posts_dashboard_v2 === true;

  const { mutateAsync, isLoading: isExporting } = usePostsFactPostClickExport({
    postId,
    factPostClickId,
    useApiary: canUseApiary,
  });

  if (post.status !== PostStatus.PUBLISHED && post.status !== PostStatus.ARCHIVED) {
    return (
      <p className="mb-64 text-gray-600 text-sm">
        Analytics are only available for published posts. Once published, come back here to see your post&apos;s
        performance.
      </p>
    );
  }

  return (
    <PostFactPostClicksMetricsContainer postId={postId} factPostClickId={factPostClickId}>
      {(subscriberPostClicks, totalRecords, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage) => {
        return (
          <PostFactPostClicksContainer postId={postId} factPostClickId={factPostClickId}>
            {(metrics, isMetricsLoading, isMetricsError) => {
              const hasNoResults = totalRecords === 0;

              if (isV2) {
                return (
                  <Card>
                    <div className="flex flex-col gap-y-4">
                      <Typography token="font-medium/text/base">Click Details</Typography>

                      <MetricsBanner showEmailStats={showEmailStats} showWebStats={showWebStats} metrics={metrics} />

                      <div className="flex flex-col gap-y-4">
                        <div className="flex justify-between items-center">
                          <ResourceListSearchText
                            showingCount={subscriberPostClicks.length}
                            totalCount={totalRecords || 0}
                          />
                          <Button onClick={() => mutateAsync()} disabled={isExporting || hasNoResults}>
                            {isExporting ? 'Exporting...' : 'Export CSV'}
                          </Button>
                        </div>

                        <Items>
                          <ItemHeaders isSticky>
                            <ItemHeader>Email</ItemHeader>
                            <ItemHeader>Clicked At</ItemHeader>
                          </ItemHeaders>
                          <ItemsBody>
                            {subscriberPostClicks.map((click: SubscriberPostClick) => {
                              return (
                                <ItemRow key={click.id}>
                                  <ItemColumn align={AlignType.LEFT}>
                                    <Link to={`/subscribers/${click.subscriber_id}`}>
                                      <Typography token="font-normal/text/sm" color="secondary" colorWeight="600">
                                        {click.email}
                                      </Typography>
                                    </Link>
                                  </ItemColumn>
                                  <ItemColumn>
                                    <Typography token="font-normal/text/sm" colorWeight="700">
                                      {moment(click.clicked_at).format('L LT')}
                                    </Typography>
                                  </ItemColumn>
                                </ItemRow>
                              );
                            })}
                            {hasNoResults ? (
                              <ItemRow>
                                <ItemColumn colSpan={4} align={AlignType.CENTER}>
                                  <Typography token="font-normal/text/sm" colorWeight="700">
                                    No Clicks yet!
                                  </Typography>
                                </ItemColumn>
                              </ItemRow>
                            ) : null}
                          </ItemsBody>
                        </Items>
                        {hasNextPage && (
                          <div className="flex justify-start mt-8 w-fit">
                            <LoadMoreButton isFetchingNextPage={isFetchingNextPage} fetchNextPage={fetchNextPage} />
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                );
              }

              return (
                <LoadingBox isLoading={isLoading || isMetricsLoading} isError={isError || isMetricsError}>
                  <div className="py-6 px-4 max-h-screen sm:p-6 space-y-6">
                    <div className="space-y-3">
                      <MetricsBanner showEmailStats={showEmailStats} showWebStats={showWebStats} metrics={metrics} />
                      <div className="flex items-center space-x-2 pt-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Subscriber Email Clicks</h3>
                        <Badge>
                          Showing {subscriberPostClicks.length} of {totalRecords} results
                        </Badge>
                        <button
                          className="relative inline-flex flex-col items-center group"
                          type="button"
                          onClick={() => mutateAsync()}
                        >
                          <ArrowDownTrayIcon className="h-4 w-4 text-gray-400" />
                          <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:opacity-100 opacity-0">
                            <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg rounded-sm w-max">
                              {isExporting ? 'Exporting...' : 'Export CSV'}
                            </span>
                            <div className="w-3 h-3 -mt-2 rotate-45 bg-black transform" />
                          </div>
                        </button>
                      </div>
                      <div className="overflow-x-hidden">
                        <a
                          href={metrics?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary-600 hover:text-primary-500 hover:underline"
                        >
                          {metrics?.url}
                        </a>
                      </div>
                    </div>
                    <div className="bg-white rounded">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <MaxHeightWrapper>
                            {subscriberPostClicks && subscriberPostClicks.length === 0 ? (
                              <div className="w-full h-full p-8">
                                <div className="h-full bg-gray-50 rounded flex items-center justify-center px-4 py-16">
                                  <div className="flex flex-col items-center gap-4">
                                    <h3>No email click data available</h3>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    {TABLE_HEADERS.map((header) => (
                                      <th
                                        key={header}
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        {header}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                  {subscriberPostClicks.map((postClick) => (
                                    <tr key={postClick.id}>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                          <div className="text-sm font-medium text-gray-900">
                                            {postClick.subscriber_deleted ? (
                                              <div className="text-sm text-gray-500 italic">{postClick.email}</div>
                                            ) : (
                                              <Link
                                                className="text-primary-600 hover:text-primary-900"
                                                to={`/subscribers/${postClick.subscriber_id}`}
                                              >
                                                {postClick.email}
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-900">
                                          {moment(postClick.clicked_at).format('L LT')}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                            {hasNextPage && (
                              <div className="flex justify-center align-middle p-6">
                                <Button
                                  variant="primary-inverse"
                                  onClick={() => fetchNextPage()}
                                  loading={isFetchingNextPage}
                                  disabled={!hasNextPage || isFetchingNextPage}
                                >
                                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                                </Button>
                              </div>
                            )}
                          </MaxHeightWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </LoadingBox>
              );
            }}
          </PostFactPostClicksContainer>
        );
      }}
    </PostFactPostClicksMetricsContainer>
  );
};

export default Details;
