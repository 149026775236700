/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/require-default-props */
import {
  Component,
  createRef,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { IFrameContextProvider } from './Context';

interface IFrameProps {
  children: ReactNode;
  className?: string;
  onLoad?: () => void;
  src: string;
  style?: CSSProperties;
  title: string;
}

interface IFrameImplProps extends IFrameProps {
  forwardedRef: ForwardedRef<HTMLIFrameElement>;
}

interface IFrameImplState {
  iframeLoaded: boolean;
}

class IFrameImpl extends Component<IFrameImplProps, IFrameImplState> {
  private _isMounted: boolean;

  private nodeRef: RefObject<HTMLIFrameElement | null>;

  // private nodeRef =

  constructor(props: IFrameImplProps) {
    super(props);
    // this.isMounted = false;
    this._isMounted = false;
    this.nodeRef = createRef<HTMLIFrameElement | null>();
    this.state = { iframeLoaded: false };
  }

  componentDidMount() {
    // this.setState({ isMounted: true });
    this.isMounted = true;

    const doc = this.getDoc();
    if (doc && doc.readyState === 'complete') {
      this.forceUpdate();
    } else {
      this.nodeRef.current?.addEventListener('load', this.handleLoad);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
    // this.setState({ isMounted: true });
    this.nodeRef.current?.removeEventListener('load', this.handleLoad);
  }

  set isMounted(value: boolean) {
    this._isMounted = value;
  }

  get isMounted() {
    return this._isMounted;
  }

  private getDoc() {
    return this.nodeRef.current ? this.nodeRef.current.contentDocument : null;
  }

  private setRef = (node: HTMLIFrameElement) => {
    // Only here allow assigning to ref.current
    // @ts-ignore
    this.nodeRef.current = node;

    const { forwardedRef } = this.props;
    if (typeof forwardedRef === 'function') {
      forwardedRef(node);
    } else if (forwardedRef) {
      forwardedRef.current = node;
    }
  };

  private handleLoad = () => {
    const { onLoad } = this.props;
    this.setState({ iframeLoaded: true });
    onLoad?.();
  };

  private renderFrameContents() {
    const { children } = this.props;

    if (!this._isMounted) {
      return null;
    }

    const doc = this.getDoc();
    const mountTarget = doc?.body;

    if (!doc || !mountTarget) {
      return null;
    }

    // @ts-ignore doc.parentView is for older browsers
    const win: Window & typeof globalThis = doc.defaultView || doc.parentView;

    return createPortal(
      <IFrameContextProvider value={{ document: doc, window: win }}>{children}</IFrameContextProvider>,
      mountTarget
    );
  }

  render() {
    const { className, src, style, title } = this.props;
    const { iframeLoaded } = this.state;

    return (
      <iframe ref={this.setRef} src={src} title={title} className={className} style={style} onLoad={this.handleLoad}>
        {iframeLoaded && this.renderFrameContents()}
      </iframe>
    );
  }
}

export const IFrame = forwardRef<HTMLIFrameElement, IFrameProps>((props, ref) => {
  // This is so that Iframe only rendered on client side
  // There are issues with mounting into iframe when the iframe is also rendered server side
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  if (!mounted) return null;
  return <IFrameImpl {...props} forwardedRef={ref} />;
});
