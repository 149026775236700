import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import PropertiesGrouping from './PropertiesGrouping';
import { SettingsNote, SettingsNoteDescription, SettingsNoteLink } from './SettingsNote';

interface Props {
  url: string;
  alt: string;
  label: string;
  description?: string;
}

const ImageSettingHelper = ({ url, alt, label, description }: Props) => {
  const currentPublicationId = useCurrentPublicationId();
  return (
    <PropertiesGrouping label={label} description={description}>
      <div className="flex flex-col space-y-1">
        <div className="h-[100px] w-full rounded overflow-hidden items-center justify-center bg-gray-100 flex">
          <img src={url} alt={alt} className="aspect-square w-auto h-1/2 shadow-md rounded-md" />
        </div>
        <SettingsNote>
          <SettingsNoteDescription>
            Logo is pulled in from your{' '}
            <SettingsNoteLink to={appendSettingsPublicationId('/settings/publication/general', currentPublicationId)}>
              publication settings
            </SettingsNoteLink>
            .
          </SettingsNoteDescription>
        </SettingsNote>
      </div>
    </PropertiesGrouping>
  );
};

export default ImageSettingHelper;
