import React from 'react';

import { StyledLabel, StyledLabelNoFill, StyledWrapper } from './styled';
import { ToggleWrapperProps } from './types';

export const ToggleWrapper = ({
  switchEl,
  children,
  icon = null,
  isDisabled = false,
  noFill = false,
  ...rest
}: ToggleWrapperProps) => {
  return (
    <StyledWrapper isDisabled={isDisabled} {...rest}>
      {noFill ? (
        <StyledLabelNoFill>
          {icon}
          <span>{children}</span>
        </StyledLabelNoFill>
      ) : (
        <StyledLabel>
          {icon}
          <span>{children}</span>
        </StyledLabel>
      )}
      {switchEl}
    </StyledWrapper>
  );
};

export default ToggleWrapper;
