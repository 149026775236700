import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

const useDeleteTier = (tierId: string) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation(() => api.delete(`/tiers/${tierId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['publication_settings', 'premium', 'tiers', currentPublicationId]);
      analytics.track('deleted a premium tier');
      toast.success('Tier Deleted!');
    },
  });
};

export default useDeleteTier;
