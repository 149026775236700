import { useCallback, useRef } from 'react';
import { Menu } from '@headlessui/react';
import { TCollabComment } from '@hocuspocus/provider';
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import moment from 'moment-mini';

import { suggestions } from '@/components/CommentEditor/suggestions';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip';
import { Avatar } from '@/components/UI/Avatar';
import { useCurrentUser } from '@/context/current-user-context';
import { useUser } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

const CommentDocument = Document.extend({
  content: 'paragraph+',
});

export type CommentProps = {
  onDelete: (id: string) => void;
  onDeleteThread: () => void;
  onResolveThread: () => void;

  comment: TCollabComment;

  isFirst?: boolean;
  isLast?: boolean;
  isResolved?: boolean;
  referenceText?: string;
};

// export const Comment = ({ createdAt, authorId, content, id, isFirst, onDelete }: CommentProps) => {
export const Comment = ({
  comment,
  onDelete,
  onDeleteThread,
  onResolveThread,
  isFirst,
  isLast,
  isResolved,
  referenceText,
}: CommentProps) => {
  const {
    createdAt,
    data: { authorId },
    content,
    id,
  } = comment;

  const currentPublicationId = useCurrentPublicationId();
  const author = useUser(currentPublicationId, authorId);
  const { currentUser } = useCurrentUser();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const getWrapperRef = useCallback(() => wrapperRef.current || document.body, []);

  const previewEditor = useEditor({
    content,
    extensions: [
      CommentDocument,
      StarterKit.configure({
        document: false,
        blockquote: false,
        code: false,
        codeBlock: false,
        listItem: false,
        hardBreak: false,
        bulletList: false,
        orderedList: false,
      }),
      Mention.extend({
        priority: 200,
      }).configure({
        renderText({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
        },
        HTMLAttributes: {
          class: 'mention-suggestion',
        },
        suggestion: suggestions(getWrapperRef),
      }),
      Underline.configure(),
    ],
    editable: false,
    editorProps: {
      attributes: {
        class: 'comment-preview',
      },
    },
  });

  const handleDeleteClick = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <div className="flex items-start w-full relative" ref={wrapperRef}>
      <div className="flex-none mr-2">
        <Avatar.Wrapper isLoading={author.isLoading && !author.data} fallback={author.data?.name}>
          {author.data?.profile_picture.url ? (
            <Avatar.Image src={author.data.profile_picture?.url} alt={author.data.name} />
          ) : null}
        </Avatar.Wrapper>
      </div>

      <div className="w-full flex flex-col gap-1">
        <div className="flex items-center justify-start gap-1">
          {author.isLoading && !author.data ? (
            <div className="h-[1em] text-xs w-12 animate-pulse bg-neutral-200 rounded-md" />
          ) : null}
          {author.data ? (
            <div className="text-xs leading-[1.1] font-semibold text-black">{author.data.name}</div>
          ) : null}
          <div className="text-xs leading-[1.1] font-semibold text-neutral-400">{moment(createdAt).fromNow()}</div>
        </div>
        {isFirst && referenceText && (
          <div className="mt-1 flex items-center text-xs text-neutral-600 border-l-2 border-l-yellow-400 pl-2 py-1 leading-3">
            <span>{referenceText}</span>
          </div>
        )}
        <div className="mt-1 text-sm">
          <EditorContent editor={previewEditor} />
        </div>
      </div>

      {isFirst && (
        <div className="flex-none ml-auto">
          <div className="flex items-center gap-1">
            <Tooltip title={isResolved ? 'Unresolve' : 'Resolve'}>
              <Button
                $leftSlot={<Icon name={isResolved ? 'Undo' : 'Check'} />}
                $isIconButton
                $isToggleButton
                $variant="tertiary"
                $size="small"
                onClick={(e: any) => {
                  e.stopPropagation();

                  onResolveThread();
                }}
              />
            </Tooltip>

            <Menu as="div" className="relative">
              <Menu.Button className="w-[1.75rem] h-[1.75rem] flex items-center justify-center hover:bg-black hover:bg-opacity-5 rounded-lg">
                <Icon name="DotsVertical" className="w-3 h-3" />
              </Menu.Button>

              <Menu.Items className="absolute right-0 w-48 p-2 text-white rounded-lg shadow-sm bg-surface-800 top-full z-10">
                <Menu.Item>
                  <button
                    className="flex items-center w-full gap-2 p-1 text-sm font-medium bg-transparent rounded hover:bg-white hover:bg-opacity-10 text-neutral-200 hover:text-white"
                    type="button"
                    onClick={onDeleteThread}
                  >
                    <Icon name="Trash" className="w-4 h-4 text-white" />
                    Delete thread
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      )}

      {currentUser?.id === authorId && !isFirst && (
        <div className="flex-none ml-auto">
          <Menu as="div" className="relative">
            <Menu.Button
              className="w-[1.75rem] h-[1.75rem] flex items-center justify-center hover:bg-black hover:bg-opacity-5 rounded-lg"
              onClick={(e: any) => e.stopPropagation()}
            >
              <Icon name="DotsVertical" className="w-3 h-3" />
            </Menu.Button>

            <Menu.Items className="absolute right-0 w-48 p-2 text-white rounded-lg shadow-sm bg-surface-800 top-full z-10">
              <Menu.Item>
                <button
                  className="flex items-center w-full gap-2 p-1 text-sm font-medium bg-transparent rounded hover:bg-white hover:bg-opacity-10 text-neutral-200 hover:text-white"
                  type="button"
                  onClick={handleDeleteClick}
                >
                  <Icon name="Trash" className="w-4 h-4 text-white" />
                  Delete comment
                </button>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      )}

      {isLast === false && (
        <div className="absolute left-2.5 top-6 h-[calc(100%-1rem)] w-[1.5px] bg-neutral-100 -translate-x-1/2" />
      )}
    </div>
  );
};
