import {
  ArrowDownIcon,
  ArrowPathIcon,
  ArrowRightStartOnRectangleIcon,
  ArrowUpIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftIcon,
  CheckCircleIcon,
  CurrencyDollarIcon,
  CursorArrowRippleIcon,
  EnvelopeOpenIcon,
  ExclamationCircleIcon,
  ForwardIcon,
  GiftIcon,
  InboxArrowDownIcon,
  NoSymbolIcon,
  PaperAirplaneIcon,
  UserMinusIcon,
  UserPlusIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';

import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

const useEventIcon = (type: ActivityFeedEventType) => {
  switch (type) {
    case ActivityFeedEventType.SENT:
      return PaperAirplaneIcon;
    case ActivityFeedEventType.DELIVERED:
      return InboxArrowDownIcon;
    case ActivityFeedEventType.OPEN:
      return EnvelopeOpenIcon;
    case ActivityFeedEventType.CLICK:
      return CursorArrowRippleIcon;
    case ActivityFeedEventType.UNSUBSCRIBE:
      return UserMinusIcon;
    case ActivityFeedEventType.SUBSCRIBE:
      return UserPlusIcon;
    case ActivityFeedEventType.SPAM_REPORT:
      return NoSymbolIcon;
    case ActivityFeedEventType.DEFERRAL:
      return ArrowPathIcon;
    case ActivityFeedEventType.DELIVERY_ERROR:
      return ExclamationCircleIcon;
    case ActivityFeedEventType.COMMENT:
      return ChatBubbleLeftIcon;
    case ActivityFeedEventType.AUTOMATION_ENROLLED:
      return ForwardIcon;
    case ActivityFeedEventType.AUTOMATION_COMPLETED:
      return CheckCircleIcon;
    case ActivityFeedEventType.AUTOMATION_EXITED_EARLY:
      return ArrowRightStartOnRectangleIcon;
    case ActivityFeedEventType.AUTOMATION_UNSUBSCRIBE:
      return UserMinusIcon;
    case ActivityFeedEventType.POLL_RESPONSE:
      return ChatBubbleLeftIcon;
    case ActivityFeedEventType.POLL_EXTENDED_FEEDBACK:
      return ChatBubbleLeftEllipsisIcon;
    case ActivityFeedEventType.REFERRAL_MILESTONE_EMAIL_SENT:
      return PaperAirplaneIcon;
    case ActivityFeedEventType.REFERRAL_MILESTONE_ACHIEVED:
      return GiftIcon;
    case ActivityFeedEventType.REFERRAL:
      return UsersIcon;
    case ActivityFeedEventType.UPGRADE:
      return ArrowUpIcon;
    case ActivityFeedEventType.DOWNGRADE:
      return ArrowDownIcon;
    case ActivityFeedEventType.PAYMENT_FAILED:
      return ExclamationCircleIcon;
    case ActivityFeedEventType.PAYMENT_SUCCEEDED:
      return CurrencyDollarIcon;
    default:
      return null;
  }
};

export default useEventIcon;
