import { Dispatch, SetStateAction, useRef } from 'react';
import toast from 'react-hot-toast';
import { toPng } from 'html-to-image';

import { Button } from '@/ui/Button';

import { AreaChart, ChartCard } from '../Chartsv2';
import Modal from '../Modal';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  description: string;
  firstValue: number;
  lastValue: number;
  chartData: any;
  categories: any;
  index: string;
  colors: any;
  valueFormatter: any;
}

const ExportImage = ({
  isOpen,
  setIsOpen,
  title,
  description,
  firstValue,
  lastValue,
  chartData,
  categories,
  index,
  colors,
  valueFormatter,
}: Props) => {
  const imageRef: any = useRef();

  function downloadSlide() {
    return new Promise((resolve, reject) => {
      toPng(imageRef.current, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'beehiiv.png';
          link.href = dataUrl;
          link.click();
          resolve('done');
        })
        .catch(() => {
          reject();
        });
    });
  }

  const handleSubmit = () => {
    toast.loading('Downloading image');
    downloadSlide()
      .then(() => {
        toast.remove();
        toast.success('Successfully downloaded chart!');
      })
      .catch(() => {
        toast.remove();
        toast.error('Failed downloading chart, please try again.');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      className="w-[95%] md:w-4/5 lg:w-[700px] xl:w-[800px] p-4"
    >
      <div className="flex flex-col w-full">
        <p className="text-xl font-bold text-[#363636] mb-6">Export Chart</p>
        <div ref={imageRef} className="relative w-full rounded-lg">
          <div className="bg-[#EC4899] rounded-lg p-3 md:p-8">
            <ChartCard
              title={title}
              description={description}
              noResultsText="No active subscribers within the chosen time frame."
              noResults={false}
              actionChildren={<img src="/images/beehiiv.png" height={12} className="my-auto" alt="beehiiv logo" />}
            >
              <AreaChart
                data={chartData}
                categories={categories}
                index={index}
                startEndOnly
                showGridLines={false}
                allowDecimals={false}
                minValue={firstValue || 0}
                maxValue={lastValue || 0}
                colors={colors}
                valueFormatter={valueFormatter}
              />
            </ChartCard>
          </div>
        </div>
        <div className="flex mt-6 flex-row space-x-3 w-full justify-end items-end">
          <Button variant="primary-inverse" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Download
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ExportImage;
