import { CalendarIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import moment from 'moment-mini';

import { Checkbox } from '@/components/Form';
import { ItemRow } from '@/components/ResourceList';
import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { Typography } from '@/components/Typography';
import useBoostsFrozen from '@/hooks/boosts/useBoostsFrozen';
import { ApplicationDemographics } from '@/interfaces/boosts/grow/application_demographics';
import { ApplicationScores } from '@/interfaces/boosts/grow/application_scores';
import { BoostPublication } from '@/interfaces/boosts/grow/boost_publication';
import { Badge, BadgeSize, BadgeType } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import pluralize from '@/utils/pluralize';

import ApplicationDemographicBadges from './ApplicationDemographicBadges';
import BoostApplicationScores from './BoostApplicationScores';

interface Props {
  publication: BoostPublication;
  applicationScores?: ApplicationScores;
  applicationScoresIsLoading: boolean;
  applicationDemographics?: ApplicationDemographics;
  applicationDemographicsIsLoading: boolean;
  narrowLayout: boolean;
  createdAt: Date | string;
  onClickReview: () => void;
  onAccept: () => void;
  onReject: (rejectReason: string) => void;
  isAccepting: boolean;
  isRejecting: boolean;
  isAccepted: boolean;
  isRejected: boolean;
  isSelected: boolean;
  onSelect: () => void;
}

const ApplicationItem = ({
  createdAt,
  publication,
  applicationScores,
  applicationScoresIsLoading,
  applicationDemographics = [],
  applicationDemographicsIsLoading,
  narrowLayout,
  onClickReview,
  onAccept,
  onReject,
  isAccepting,
  isRejecting,
  isAccepted,
  isRejected,
  isSelected,
  onSelect,
}: Props) => {
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen;
  const { name, description, tags, url, logo_url: logoUrl, created_at: joinDate, post_count: postCount } = publication;

  return (
    <ItemRow className="[&:first-child>td>button]:rounded-t-md [&:last-child>td>button]:rounded-b-md">
      <td className="w-full p-0">
        <button
          type="button"
          className={cx(
            "w-full flex flex-row text-left p-4 gap-4 rounded-none",
            "bg-neutrals-200 focus:bg-surface-100 transition-colors",
            "hover:bg-surface-50",
            "[&:has(.action-buttons:hover)]:hover:bg-neutrals-200",
            isSelected && "bg-surface-100",
          )}
          onClick={(e) => {
            const currentTarget = e.target as HTMLElement;
            // Trigger the Review Modal if the click didn't originate from:
            // - The publication name link
            // - The Accept/Reject action buttons
            // - The selection checkbox
            if (
              !currentTarget.closest('.action-buttons') &&
              !(currentTarget instanceof HTMLAnchorElement) &&
              !currentTarget.closest('a[role="button"]')
            ) {
              onClickReview();
            }
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => e.stopPropagation()}
            className="mt-1"
          >
            <Checkbox name="select-item" checked={isSelected} className="cursor-pointer" onChange={onSelect} />
          </div>
          <div className="flex-1 flex flex-col items-start lg:flex-row">
            <div className="flex-grow space-y-6 mb-6 lg:mb-0 lg:space-y-4">
              <div className="flex items-start space-x-3">
                {logoUrl && (
                  <img
                    src={logoUrl}
                    alt={`${name} logo`}
                    className="w-12 h-12 rounded-md"
                  />
                )}
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
                    <Typography token="font-medium/text/sm" colorWeight="900" as="h3">
                      <a href={url} target="_blank" rel="noreferrer" className="hover:underline">
                        {name}
                      </a>
                    </Typography>
                    <div className="flex flex-wrap gap-2">
                      {tags.map((tag) => (
                        <Badge
                          key={tag.id}
                          text={tag.name}
                          size={BadgeSize.SM}
                          type={BadgeType.DEFAULT_FILLED}
                        />
                      ))}
                    </div>
                  </div>
                  <Typography
                    token="font-normal/text/sm"
                    colorWeight="700"
                    as="p"
                    className="line-clamp-1"
                  >
                    {description}
                  </Typography>
                </div>
              </div>

              <div className="flex flex-col gap-6 lg:flex-row">
                <div className="flex space-x-5">
                  <div className="flex items-center space-x-1">
                    <CalendarIcon className="w-4 h-4 text-surface-500" />
                    <Typography token="font-normal/text/xs" colorWeight="700" as="span">
                      Joined {moment(joinDate).format('MMM YYYY')}
                    </Typography>
                  </div>
                  <SkeletonLoader
                    isLoading={applicationScoresIsLoading}
                    skeletons={
                      <div className="flex items-center space-x-1">
                        <div className="w-16"><Skeleton className="h-5 py-0.5" /></div>
                      </div>
                    }
                  >
                    <div className="flex items-center space-x-1">
                      <DocumentTextIcon className="w-4 h-4 text-surface-500" />
                      <Typography token="font-normal/text/xs" colorWeight="700" as="span">
                        {pluralize('post', postCount || 0)}
                      </Typography>
                    </div>
                  </SkeletonLoader>
                </div>

                <ApplicationDemographicBadges
                  publicationId={publication.id}
                  applicationDemographics={applicationDemographics}
                  maxVisible={narrowLayout ? 5 : 3}
                  isLoading={applicationDemographicsIsLoading}
                />
              </div>

              <BoostApplicationScores
                publicationId={publication.id}
                scores={applicationScores}
                isLoading={applicationScoresIsLoading}
              />
            </div>

            <div className="w-full flex flex-row justify-between align-middle mt-4 lg:mt-0 lg:w-auto lg:flex-col lg:space-x-10 lg:space-y-10">
              <Typography
                token="font-normal/text/xs"
                colorWeight="500"
                as="div"
                className="flex flex-grow items-center lg:justify-end"
              >
                <time dateTime={typeof createdAt === 'string' ? createdAt : createdAt.toDateString()}>
                  {moment(createdAt).fromNow()}
                </time>
              </Typography>

              {!isAccepted && !isRejected && (
                <div className="action-buttons flex gap-x-3">
                  <Button
                    variant="primary-inverse"
                    size="xs"
                    loading={isRejecting}
                    disabled={isAccepting || isRejecting || boostsFrozen}
                    onClick={(e) => {
                      e.preventDefault();
                      onReject("");
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="primary"
                    size="xs"
                    loading={isAccepting}
                    disabled={isAccepting || isRejecting || boostsFrozen}
                    onClick={(e) => {
                      e.preventDefault();
                      onAccept();
                    }}
                  >
                    Accept
                  </Button>
                </div>
              )}
            </div>
          </div>
        </button>
      </td>
    </ItemRow>
  );
};

export default ApplicationItem;
