import { useCurrentPublication } from '@/hooks';

import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import DividerSettings from '../DividerSettings';
import { GapSettings } from '../GapSettings';
import { GridColumnSetting } from '../GridColumnSetting';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

import { AuthorsPostSettings } from './AuthorsPostSettings';
import { ImagePostSetting } from './ImagePostSettings';
import { PaginationPostSetting } from './PaginationPostSettings';
import { PremiumPostSettings } from './PremiumPostSettings';
import { SearchPostSetting } from './SearchPostSetting';
import { TagsPostSetting } from './TagsPostSettings';
import { TimestampPostSettings } from './TimestampPostSettings';
import { TitlesPostSetting } from './TitlesPostSetting';

export const PostSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { data: currentPublication } = useCurrentPublication();

  const { activeNodeAttributes } = activeNodeResult;
  const isScrollOnMobile = activeNodeAttributes?.scrollOnMobile;

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['post']}>
      <SectionRenderer title="Layout">
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="scrollOnMobile"
          title="Horizontal Scroll"
        />
        <GridColumnSetting
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Columns"
          isDisabled={isScrollOnMobile}
          disabledTooltip="Columns are not supported when horizontal scroll is enabled"
        />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <DividerSettings editor={editor} activeNodeResult={activeNodeResult} />
        <SearchPostSetting editor={editor} activeNodeResult={activeNodeResult} />
        <PaginationPostSetting editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>

      <SectionRenderer title="Cards">
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings title="Background" property="background" editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="cardPadding" />
        <ImagePostSetting editor={editor} activeNodeResult={activeNodeResult} />
        <TitlesPostSetting editor={editor} activeNodeResult={activeNodeResult} />
        <TagsPostSetting editor={editor} activeNodeResult={activeNodeResult} />
        <AuthorsPostSettings editor={editor} activeNodeResult={activeNodeResult} />
        <TimestampPostSettings editor={editor} activeNodeResult={activeNodeResult} />
        {currentPublication?.is_premium_enabled && (
          <PremiumPostSettings editor={editor} activeNodeResult={activeNodeResult} />
        )}
      </SectionRenderer>
    </RenderCondition>
  );
};
