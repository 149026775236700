import { useMemo } from 'react';
import { ReactFlowState, useReactFlow, useStore } from 'reactflow';

import { AutomationTrigger } from '@/interfaces/automations/automation_trigger';

import { MAX_AUTOMATION_TRIGGERS } from '../../../../interfaces/automations/automation';

const useMaxAllowedTriggers = (triggers?: AutomationTrigger[]) => {
  const { getNodes, getNode, setNodes, setEdges, getEdges } = useReactFlow();
  const nodeCountSelector = (state: ReactFlowState) => state.nodeInternals.size;
  const nodeCount = useStore(nodeCountSelector);

  const returnValue = useMemo(
    () => getNodes().filter((node) => node.type === 'trigger').length >= MAX_AUTOMATION_TRIGGERS,
    [nodeCount, getEdges, getNodes, getNode, setNodes, setEdges]
  );

  if (triggers) {
    return triggers.length >= MAX_AUTOMATION_TRIGGERS;
  }

  return returnValue;
};

export default useMaxAllowedTriggers;
