import { FC } from 'react';
import moment from 'moment-mini';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { RewardPromoCode } from '@/interfaces/reward';
import { Badge } from '@/ui/Badge';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

interface IData {
  [key: string]: any;
}

const statusLabels: IData = {
  assigned: 'Assigned',
  not_assigned: 'Not Assigned',
};

interface Props {
  promoCodes: RewardPromoCode[] | undefined;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  onDeleteSelected: (rewardId: string) => void;
  slideOverBodyId: string;
}

const Table: FC<Props> = ({
  promoCodes,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  onDeleteSelected,
  slideOverBodyId,
}: Props) => {
  if (!promoCodes) {
    return null;
  }

  return (
    <>
      <Items>
        <ItemHeaders>
          <ItemHeader align={AlignType.LEFT}>Code</ItemHeader>
          <ItemHeader align={AlignType.LEFT}>Status</ItemHeader>
          <ItemHeader align={AlignType.LEFT}>Created At</ItemHeader>
          <ItemHeader />
        </ItemHeaders>
        <ItemsBody>
          {promoCodes.map((promoCode) => {
            const options = [
              {
                label: 'Delete',
                onClick: () => onDeleteSelected(promoCode.id),
              },
            ];
            return (
              <ItemRow key={`${promoCode.id}`}>
                <ItemColumn align={AlignType.TOP}>
                  <Typography token="font-normal/text/sm">{promoCode.code}</Typography>
                </ItemColumn>
                <ItemColumn align={AlignType.TOP}>
                  <Badge type={promoCode.status === 'assigned' ? 'success' : 'information'}>
                    {statusLabels[promoCode.status]}
                  </Badge>
                </ItemColumn>
                <ItemColumn align={AlignType.TOP}>
                  <Typography token="font-normal/text/sm">{moment(promoCode.created_at).format('lll')}</Typography>
                </ItemColumn>
                <ItemColumn align={AlignType.TOP}>
                  <EllipsisDropdown options={options} portalMountedId={slideOverBodyId} />
                </ItemColumn>
              </ItemRow>
            );
          })}
          {promoCodes.length === 0 ? (
            <ItemRow>
              <ItemColumn colSpan={4} align={AlignType.CENTER}>
                <Typography token="font-normal/text/sm" colorWeight="500">
                  No codes added yet
                </Typography>
              </ItemColumn>
            </ItemRow>
          ) : null}
        </ItemsBody>
      </Items>
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </>
  );
};

export default Table;
