import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { useThemeData } from '../../../../../lib/hooks/useThemeData';
import CustomColorPanel from '../../../../panels/CustomColor';
import { Button } from '../../../../ui/Button';
import { Icon } from '../../../../ui/Icon';
import { Panel, PanelDivider, PanelFooter, PanelHeader } from '../../../../ui/Panel';
import { PanelSection } from '../../../../ui/Panel/styled';
import { useCurrentNode } from '../../../hooks';
import Styled from '../../../styled';

import { BackgroundColor } from './BackgroundColor';
import { TextColor } from './TextColor';
import useVisualSettingsPanel from './VisualSettingsPanel.hooks';

export const VisualSettingsPanel = ({
  editor,
  onBack,
  navigateToPanel,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  onBack: Function;
  navigateToPanel: Function;
  currentNode: Node | null;
  currentNodePos: number;
}) => {
  const {
    isCustomBackgroundColorPanelOpen,
    isCustomTextColorPanelOpen,
    navigateToSpacings,
    navigateToBorders,
    onShowCustomBackgroundColorPanel,
    onCloseCustomBackgroundColorPanel,
    onShowCustomTextColorPanel,
    onCloseCustomTextColorPanel,
    onSetCustomBackgroundColor,
    onSetCustomTextColor,
    onResetAllVisualSettings,
  } = useVisualSettingsPanel(editor, currentNode, currentNodePos, navigateToPanel);

  const themeColors = useThemeData('colors');
  const { hasVisualSettingsOverrides, hasBorderOverrides, hasSpacingOverrides } = useCurrentNode(
    editor,
    currentNode,
    themeColors
  );

  const renderDefaultPanel = () => (
    <Styled.ContentWrapper>
      <Panel>
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            Visual settings
          </Button>
        </PanelHeader>
        <PanelSection>
          <Styled.MenuButtonWrapper>
            <Button
              $variant="quaternary"
              $size="small"
              $active={hasSpacingOverrides}
              onClick={navigateToSpacings}
              $fullWidth
              $leftSlot={<Icon name="Spacing" />}
              $rightSlot={<Icon name="ChevronRight" />}
            >
              Spacings
            </Button>
            <Button
              $variant="quaternary"
              $size="small"
              $active={hasBorderOverrides}
              onClick={navigateToBorders}
              $fullWidth
              $leftSlot={<Icon name="Border" />}
              $rightSlot={<Icon name="ChevronRight" />}
            >
              Borders
            </Button>
          </Styled.MenuButtonWrapper>
          <PanelDivider />
          <BackgroundColor
            editor={editor}
            onShowCustomBackgroundColorPanel={onShowCustomBackgroundColorPanel}
            currentNode={currentNode}
            currentNodePos={currentNodePos}
          />
          <TextColor
            editor={editor}
            onShowCustomTextColorPanel={onShowCustomTextColorPanel}
            currentNode={currentNode}
            currentNodePos={currentNodePos}
          />
        </PanelSection>
        {hasVisualSettingsOverrides && (
          <PanelFooter>
            <Button
              $variant="quaternary"
              $size="small"
              $rightSlot={<Icon name="Reset" />}
              onClick={onResetAllVisualSettings}
              $fullWidth
              $active
              $muted
            >
              Reset all
            </Button>
          </PanelFooter>
        )}
      </Panel>
    </Styled.ContentWrapper>
  );

  const renderCustomBackgroundColorPanel = () => (
    <Styled.ContentWrapper>
      <CustomColorPanel
        selectedColor={currentNode?.attrs.backgroundColor}
        onBack={onCloseCustomBackgroundColorPanel}
        onSelect={onSetCustomBackgroundColor}
      />
    </Styled.ContentWrapper>
  );

  const renderCustomTextColorPanel = () => (
    <Styled.ContentWrapper>
      <CustomColorPanel
        selectedColor={currentNode?.attrs.color}
        onBack={onCloseCustomTextColorPanel}
        onSelect={onSetCustomTextColor}
      />
    </Styled.ContentWrapper>
  );

  if (isCustomBackgroundColorPanelOpen) {
    return renderCustomBackgroundColorPanel();
  }

  if (isCustomTextColorPanelOpen) {
    return renderCustomTextColorPanel();
  }

  return renderDefaultPanel();
};

export default VisualSettingsPanel;
