export const handleCsvMappingChange =
  (setMappingErrors: (value: boolean) => void, setMapping: (value: { [key: number]: any }) => void) =>
  (newColumnMapping: any, newErrors: any) => {
    const validErrors =
      Object.keys(newErrors).filter((fieldName: any) => {
        const errorMsg = newErrors[fieldName];
        const columnNumber = fieldName.split('__')[1];
        return errorMsg.length > 0 && newColumnMapping[columnNumber]?.selected === true;
      }).length > 0;
    setMappingErrors(validErrors);
    setMapping(newColumnMapping);
  };
