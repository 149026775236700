import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Pagination } from '../interfaces/general';
import { SubscriberPostClick } from '../interfaces/subscriber_post_click';
import api from '../services/swarm';

interface ApiResponse {
  subscriber_post_clicks: SubscriberPostClick[];
  pagination: Pagination;
}

interface Arguments {
  postId: string;
  factPostClickId: string;
  useApiary?: boolean;
}

export default function usePostFactPostClickMetrics({ postId, factPostClickId, useApiary = false }: Arguments) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostFactPostClickMetrics = ({ pageParam = 1 }) =>
    api
      .get(`/posts/${postId}/fact_post_clicks/${factPostClickId}/metrics`, {
        params: {
          page: pageParam,
          per_page: 10,
          publication_id: currentPublicationId,
          use_apiary: useApiary === true ? '1' : undefined,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['postFactPostClickMetrics', postId, factPostClickId],
    fetchPostFactPostClickMetrics,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
      staleTime: 30000,
      enabled: Boolean(factPostClickId) && Boolean(postId),
    }
  );
}
