import { useCallback, useRef } from 'react';

interface Props {
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetching: boolean;
  isLoading: boolean;
}

export default function useInfiniteScroll({ fetchNextPage, hasNextPage, isFetching, isLoading }: Props) {
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );

  return { ref: lastElementRef };
}
