import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { SETTINGS_BODY_CONTAINER_ID } from '@/components/Layout/AppLayout/constants';
import { useCurrentUser } from '@/context/current-user-context';
import { Publication } from '@/interfaces/publication';
import NewPublication from '@/pages/Settings/Pages/Company/Publications/NewPublication';
import { ScrollOnMount } from '@/ui/ScrollOnMount';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import { PublicationSettingsProvider } from './publication/context';

export const SHOW_CREATE_PUBLICATION_GET_PARAM = 'create_publication';

// Note: This should only be used for settings v2
// settings v2 => currentUser?.has_settings_v2 === true
const SettingsLayout: React.FC = () => {
  const queryClient = useQueryClient();
  const { currentUser } = useCurrentUser();

  const location = useLocation();
  const navigate = useNavigate();

  const scrollToElementId = location.hash.replace('#', '');
  const scrollToTop = !scrollToElementId;

  const [searchParams, setSearchParams] = useSearchParams();
  const [disableButtons, setDisableButtons] = useState(false);

  const handleCloseCreatePublicationForm = () => {
    searchParams.delete(SHOW_CREATE_PUBLICATION_GET_PARAM);
    setSearchParams(searchParams);
  };

  const handleCreatePublicationSuccess = async (publication: Publication) => {
    try {
      setDisableButtons(true);
      await queryClient.invalidateQueries([currentUser?.id, 'organizations']);
      navigate(appendSettingsPublicationId('/settings/publication/general', publication.id));
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }

    setDisableButtons(true);
  };

  const handleCreatePublicationError = (error: string) => {
    toast.error(error);
  };

  return (
    <PublicationSettingsProvider>
      <ScrollOnMount
        elementId={scrollToTop ? SETTINGS_BODY_CONTAINER_ID : location.hash.replace('#', '')}
        scrollIntoView={!scrollToTop}
      >
        <div className="flex flex-row w-full justify-center">
          <NewPublication
            isOpen={!!searchParams.get(SHOW_CREATE_PUBLICATION_GET_PARAM)}
            onClose={handleCloseCreatePublicationForm}
            shouldUpdateCurrentPublication={false}
            onCreate={handleCreatePublicationSuccess}
            onError={handleCreatePublicationError}
            disableButtons={disableButtons}
          />
          <Outlet />
        </div>
      </ScrollOnMount>
    </PublicationSettingsProvider>
  );
};

export default SettingsLayout;
