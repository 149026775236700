import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/ui/Button';

// Components
import { SvgLogo } from '../../components/SvgLogo';
// Context
import { useAuth } from '../../context/auth-context';

interface Props {
  inviteId: string;
  inviteToken: string;
  tokenId: string;
  inviteableName: string;
}

const ExistingUser: FC<Props> = (props: Props) => {
  const { inviteId, inviteToken, tokenId, inviteableName } = props;
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const { inviteAcceptAuthenticatedUser } = useAuth();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      inviteToken,
      inviteId,
      tokenId,
    };

    setIsSaving(true);
    inviteAcceptAuthenticatedUser(payload)
      .then(() => {
        navigate('/');
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsSaving(false));
  };

  const handleIgnore = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col justify-start py-12 sm:px-6 lg:px-8 p-5">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-12 h-auto m-auto">
          <a href="https://www.beehiiv.com" aria-label="beehiiv" target="_blank" rel="noreferrer">
            <SvgLogo />
          </a>
        </div>
        <h2 className="mt-6 text-center text-2xl font-medium text-gray-900">
          You&apos;ve been invited to join {inviteableName}!
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <form onSubmit={handleSubmit} className="space-y-6">
            <Button type="submit" loading={isSaving} block>
              Accept
            </Button>
            <Button type="button" variant="primary-inverse" block onClick={handleIgnore}>
              Ignore
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExistingUser;
