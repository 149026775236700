/* eslint-disable */
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TrashIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { index } from 'd3';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useOrderNavGroups } from '../../../../../../hooks/useNavGroups';

import { NavGroup } from '../../../../../../interfaces/nav_group';
import NavGroupItem from './NavGroupItem';

interface Props {
  navGroups: NavGroup[];
}

const NavGroups = ({ navGroups }: Props) => {
  const [list, setList] = useState<any>(navGroups);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const orderGroups = useOrderNavGroups({
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  useEffect(() => {
    setList(navGroups);
  }, [navGroups]);

  const onDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activePostitionId = list.find((position: any) => position.id === active.id);
      const overPostitionId = list.find((position: any) => position.id === over.id);
      const oldIndex = list.indexOf(activePostitionId);
      const newIndex = list.indexOf(overPostitionId);
      const newList = arrayMove(list, oldIndex, newIndex);
      setList(newList);
      orderGroups.mutate(
        newList.map((item: any, index: number) => {
          return { id: item.id, order: index + 1 };
        })
      );
    }
  };

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-200 shadow sm:rounded-md mt-4 relative curser-pointer">
      <div className="divide-y divide-gray-200">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
          <SortableContext items={list} strategy={verticalListSortingStrategy}>
            {list.map((item: any) => {
              return (
                <NavGroupItem key={item.id} listItem={item} removeItemRequired={false} onRemoveItem={() => {}}>
                  <div className="flex space-x-4">
                    <div className="flex items-center space-x-1">
                      <div className="w-4 h-4">
                        <svg
                          clipRule="evenodd"
                          fillRule="evenodd"
                          strokeLinejoin="round"
                          strokeMiterlimit="2"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="opacity-20"
                        >
                          <path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                          <path d="m20 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                        </svg>
                      </div>
                    </div>

                    <p className="text-sm font-medium text-gray-800 truncate max-w-sm">{item.title}</p>
                  </div>
                </NavGroupItem>
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default NavGroups;
