export enum PremiumOfferType {
  ANNUAL = 'annual',
  MONTHLY = 'monthly',
  ONE_TIME = 'one_time',
}

export interface PremiumOffer {
  id: string;
  name: string;
  offer_url: string;
  description: string;
  terms: string;
  offer_type: string;
  product_exclusive_type: string;
  duration: string;
  durating_in_months: number;
  pct_discount: number;
  trial_period_days: number;
  expires_at: Date;
  default_annual: boolean;
  default_monthly: boolean;
  default_one_time: boolean;
  card_required: boolean;
  expires_at_set: boolean;
  tier_id: string;
  tier_name: string;
  upsell_email_enabled: boolean;
  upsell_email_period_days: number;
  upsell_email_send_before_end: boolean;
}
