import React, { useCallback } from 'react';

import { Range } from '@/components/UI/Range';

export type HueControlProps = {
  hue: number;
  setHue: (hue: number) => void;
};

export const HueControl = React.memo(({ hue, setHue }: HueControlProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setHue(parseFloat(e.target.value));
    },
    [setHue]
  );

  return <Range label="Hue" min={-180} max={180} step="5" value={hue} onChange={handleChange} />;
});

HueControl.displayName = 'HueControl';
