import { useQuery } from 'react-query';

import { PostEmailClickStats } from '../interfaces/post';
import api from '../services/swarm';

interface Arguments {
  id: string;
  isEnabled?: boolean;
  staleTime?: number | undefined;
}

export default function usePostEmailClickStats({ id, isEnabled = true, staleTime = 1000 * 60 }: Arguments) {
  return useQuery<PostEmailClickStats>(
    ['posts', id, 'email_click_stats'],
    () =>
      api
        .get(`/posts/${id}/email_click_stats`)
        .then((res) => res.data),
    {
      keepPreviousData: true,
      enabled: isEnabled,
      staleTime,
    }
  );
}
