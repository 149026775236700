import { useNavigate } from 'react-router-dom';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';
import { ProgressBar } from '@tremor/react';

import useMetrics from '@/hooks/useAdNetwork/publisher/useMetrics';
import { AdNetworkMetrics } from '@/interfaces/ad_network/publisher/metrics';
import { Button } from '@/ui/Button';
import { MetricCard } from '@/ui/MetricCard';

import OpportunityCalendar from './_components/OpportunityCalendar';
import PendingOpportunities from './_components/PendingOpportunities/PendingOpportunities';
import { UpcomingOpportunities } from './_components/UpcomingOpportunities';

interface Props {
  metrics: AdNetworkMetrics;
}

const formatter = new Intl.NumberFormat('en-US');

interface PercentageProps {
  planPercentage: number;
  planInterval: "month" | "year";
}

const PercentageComponent = ({ planPercentage = 0, planInterval }: PercentageProps) => {
  const planPercentageColor = planPercentage && (planPercentage >= 100 ? 'green' : 'red');
  const planPercentageLabel = () => {
    if (planInterval === "year") {
      return `This covers ${planPercentage >= 100 ? "100" : planPercentage}% of the current month of your annual subscription plan`;
    } 

    return `This covers ${planPercentage >= 100 ? "100" : planPercentage}% of your monthly subscription plan`;      
  }

  return (
    <div className="flex flex-col space-y-2">
      <ProgressBar percentageValue={planPercentage || 0} color={planPercentageColor || 'gray'} className="w-full pr-2" />
      <span className="text-xs">{planPercentageLabel()}</span>
    </div>
  );
};

const MonetizeAdsOverview = ({ metrics }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="py-6">
      <div className="grid gap-6 grid-cols-3">
        <div className="col-span-3 lg:col-span-2">
          <div className="grid grid-cols-2 gap-6">
            <div className="col-span-2 xl:col-span-1">
              <MetricCard
                variant="primary"
                label="Earnings This Month"
                value={metrics.total_earnings_this_month}
                initialVisibleComplements={0}
                additionalComponent={
                  metrics.percentage_earned.percentage !== false && metrics.percentage_earned.interval !== false && (
                    <PercentageComponent
                      planPercentage={metrics.percentage_earned.percentage}
                      planInterval={metrics.percentage_earned.interval}
                    />
                  )
                }
                bottomComponent={
                  <Button
                    size="xs"
                    Icon={ArrowUpRightIcon}
                    iconRight
                    variant="primary"
                    onClick={() => {
                      navigate('/wallet');
                    }}
                  >
                    See earnings in Wallet
                  </Button>
                }
                complements={[
                  {
                    label: 'Earned Last Month',
                    value: metrics.total_earnings_last_month,
                  },
                  {
                    label: 'Earned In Total',
                    value: metrics.total_earnings,
                  },
                  {
                    label: 'Realized',
                    value: metrics.total_realized_earnings,
                  },
                  {
                    label: 'Pending',
                    value: metrics.total_pending_earnings,
                  },
                ]}
              />
            </div>
            <div className="col-span-2 h-full xl:col-span-1">
              <MetricCard
                variant="primary"
                className="h-full"
                label="Clicks"
                value={formatter.format(metrics.total_unique_eligible_clicked)}
                complements={[
                  {
                    label: 'Total placements',
                    value: formatter.format(metrics.total_placements),
                  },
                  {
                    label: 'Avg. per placement',
                    value: formatter.format(metrics.average_clicks_per_placement),
                  },
                ]}
              />
            </div>
            <div className="col-span-2 space-y-6">
              <div className="border-b border-gray-200 pb-2">
                <p className="font-semibold text-gray-900">Available opportunities</p>
              </div>
              <PendingOpportunities />
            </div>
          </div>
        </div>

        <div className="col-span-3 lg:col-span-1 space-y-8">
          <OpportunityCalendar />
          <UpcomingOpportunities />
        </div>
      </div>
    </div>
  );
};

export default function Loader() {
  const { data, isSuccess } = useMetrics();

  if (!isSuccess) return null;

  return (
    <MonetizeAdsOverview metrics={data} />
  );
}
