/* eslint-disable */
import { FC, useState } from 'react';

import Slider from './Slider';

const ExpandableSlider: FC<any> = ({ fields, title, theme, onUpdate, className, max, min }) => {
  const attributeKeys = Object.keys(fields);

  const handleUpdate = (k: any, v: any) => {
    const obj: any = {};
    obj[k] = v;

    onUpdate(obj);
  };

  const normalizeSliders = (val: any) => {
    const values: any = {};
    attributeKeys.forEach((field) => {
      values[field] = val;
    });
    return values;
  };

  const handleUpdateAll = (e: any) => {
    const values = normalizeSliders(e);
    onUpdate(values);
  };

  const defaultExpanded = () => {
    return !attributeKeys.map((key) => theme[key]).every((v) => v === theme[attributeKeys[0]]);
  };

  const [expanded, setExpanded] = useState(defaultExpanded());

  const toggleExpanded = (_e: any) => {
    setExpanded(!expanded);

    if (expanded) {
      const values = normalizeSliders(theme[attributeKeys[0]]);
      onUpdate(values);
    }
  };

  return (
    <div className={className}>
      <div>
        {expanded ? (
          <>
            <div className="flex items-center space-x-2 mb-4">
              <h5 className="text-sm font-bold">{title}</h5>
            </div>
            {attributeKeys.map((field, index) => (
              <div key={index}>
                <Slider
                  name={field}
                  label={fields[field]}
                  onChange={(val) => handleUpdate(field, val)}
                  initialValue={theme[field]}
                  max={max}
                  min={min}
                  valueSuffix="px"
                  className="ml-4"
                  labelClassName="text-sm font-normal"
                />
              </div>
            ))}
          </>
        ) : (
          <Slider
            initialValue={theme[attributeKeys[0]]}
            onChange={handleUpdateAll}
            max={max}
            min={min}
            label={title}
            name={title}
            valueSuffix="px"
          />
        )}
      </div>

      <button
        className="duration-200 py-2 px-4 border text-xs font-medium focus:outline-none focus:ring-2 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap bg-white hover:bg-gray-100 text-gray-800 disabled:text-gray-600 disabled:bg-gray-100 rounded-md shadow-sm border-gray-300 w-full flex justify-center"
        type="button"
        onClick={toggleExpanded}
      >
        {expanded ? 'Fewer' : 'More'} options
      </button>
    </div>
  );
};

export default ExpandableSlider;
