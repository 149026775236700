import { useMemo } from 'react';
import { AlignCenterHorizontal, AlignLeft, AlignRight } from '@phosphor-icons/react';

import DisabledSetting from '../../helpers/DisabledSetting';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BackgroundSettings } from '../BackgroundSettings';
import BorderSettings from '../BorderSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';
import { SizingSettings } from '../SizingSettings';
import { VerticalAlignmentSettings } from '../VerticalAlignmentSettings';

import { FlexBasisSettings } from './FlexBasisSettings';

export const ContainerSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodePos } = activeNodeResult;

  const isParentNodeColumns = useMemo(() => {
    if (!editor || !editor.isFocused) return false;

    const { parent } = editor.state.doc.resolve(activeNodePos);
    return parent?.type.name === 'columns';
  }, [editor, activeNodePos]);

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['container']}>
      <SectionRenderer title="Background">
        <BackgroundSettings editor={editor} activeNodeResult={activeNodeResult} />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
      </SectionRenderer>

      <SectionRenderer title="Layout">
        <DisabledSetting disabled={isParentNodeColumns} tooltip="Select columns block to update width">
          <FlexBasisSettings editor={editor} activeNodeResult={activeNodeResult} />
        </DisabledSetting>
        <SizingSettings editor={editor} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} title="Text" />
        <AlignmentSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Horizontal"
          property="horizontalAlign"
          icons={{
            left: AlignLeft,
            center: AlignCenterHorizontal,
            right: AlignRight,
          }}
        />
        <VerticalAlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>
    </RenderCondition>
  );
};
