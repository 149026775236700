import { useEffect, useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

import { ImageSelect } from '@/components/Form';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';
import { TooltipProps } from '@/routes/website_builder/_types/misc';

import CustomizeMoreButton from '../../../Helpers/CustomizeMoreButton';

interface Props {
  label: string;
  dimensions?: string;
  children?: React.ReactNode;
  tooltip?: TooltipProps;
  saveKey: string;
  handleUploadImage: (type: string, file: File | null) => void;
  hasChildren?: boolean;
  alwaysShowingChildren?: React.ReactNode;
  imageValue: File | string;
}

const ImageForm = ({
  children,
  handleUploadImage,
  label,
  dimensions,
  saveKey,
  hasChildren = true,
  tooltip,
  alwaysShowingChildren,
  imageValue,
}: Props) => {
  const [isCustomizeShowing, setIsCustomizeShowing] = useState(false);
  const [bgImage, setBgImage] = useState<File | string>(imageValue);

  useEffect(() => {
    if (!bgImage && imageValue) {
      setBgImage(imageValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageValue]);

  return (
    <PropertiesGrouping
      className="!space-y-2"
      label={label}
      tooltip={tooltip}
      actionChild={
        hasChildren &&
        !alwaysShowingChildren && (
          <CustomizeMoreButton
            Icon={PencilSquareIcon}
            isOpen={isCustomizeShowing}
            text="Styles"
            onClose={() => setIsCustomizeShowing(!isCustomizeShowing)}
          />
        )
      }
    >
      <ImageSelect
        className="z-0"
        name={saveKey}
        dimensionSuggestion={dimensions}
        onFileSelect={(file) => {
          setBgImage(file);
          handleUploadImage(saveKey, file);
        }}
        onFileClear={() => {
          setBgImage('');
          handleUploadImage(saveKey, null);
        }}
        file={bgImage}
      />
      {(hasChildren && isCustomizeShowing) || alwaysShowingChildren ? (
        <div className="flex flex-col space-y-3 px-1">{children || null}</div>
      ) : null}
    </PropertiesGrouping>
  );
};

export default ImageForm;
