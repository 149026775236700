import { PropsWithChildren } from 'react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../UI/Accordion';
import { Text } from '../../../UI/Text';

type Props = PropsWithChildren<{
  title: string;
  defaultIsOpen?: boolean;
  actions?: React.ReactNode;
  collapsible?: boolean;
}>;

const SectionRenderer = ({ title, children, defaultIsOpen = true, actions, collapsible = true }: Props) => {
  return (
    <Accordion asChild type="single" defaultValue={defaultIsOpen ? title : undefined} collapsible={collapsible}>
      <AccordionItem value={title}>
        <AccordionTrigger hideIcon={!collapsible}>
          <div className="grow flex justify-between items-center">
            <Text size="xs" weight="semibold">
              {title}
            </Text>
            {actions}
          </div>
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-4">{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default SectionRenderer;
