import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import ActionsDropdown from '@/components/ActionsDropdown';
import { ActionOption } from '@/components/ActionsDropdown/ActionsDropdown';
import { useUpdateBoostSend } from '@/hooks/boosts/monetize/useBoostSends';
import {
  BoostSend,
  BoostSendStatus,
  BoostSendStatusTransitions,
  BoostSendTransitionLabels,
} from '@/interfaces/boosts/monetize/boost_send';

interface Props {
  boostSend: BoostSend;
  onSuccess: () => void;
  ref?: any;
}
const BoostSendActions = ({ boostSend, ref, onSuccess }: Props) => {
  const [requestedAction, setRequestedAction] = useState<BoostSendStatus | null>(null);
  const handleSuccess = () => {
    onSuccess();
    toast.success('Send request updated.');
  };
  const { mutateAsync: updateBoostSend, isLoading: updateLoading } = useUpdateBoostSend({
    id: boostSend.id,
    onSuccess: handleSuccess,
  });
  const availableTransitions = BoostSendStatusTransitions[boostSend?.status || ''] || [];
  const options: ActionOption[] = availableTransitions.map((transition: BoostSendStatus) => ({
    name: BoostSendTransitionLabels[transition],
    isVisible: () => true,
    onClick: () => {
      setRequestedAction(transition);
    },
  }));

  const handleProceed = () => {
    updateBoostSend({ status: requestedAction as BoostSendStatus });
    setRequestedAction(null);
  };

  if (!boostSend) return null;

  return (
    <>
      <div className="flex items-center justify-end">
        {options.length > 0 && <ActionsDropdown actions={[options]} node={boostSend} ref={ref} />}
      </div>

      <ActionModal
        isOpen={!!requestedAction}
        onClose={() => setRequestedAction(null)}
        onProceed={() => handleProceed()}
        resourceId={boostSend.id}
        headerText={BoostSendTransitionLabels[requestedAction || '']}
        isWorking={updateLoading}
        actionText="Confirm"
      >
        <p className="text-sm text-gray-500">
          Are you sure you would like to {BoostSendTransitionLabels[requestedAction || '']} this send?
        </p>
      </ActionModal>
    </>
  );
};

export default BoostSendActions;
