import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

export interface Leader {
  id: string;
  email: string;
  total_referrals: number;
}

interface Props {
  children: (
    leaders: Leader[] | undefined,
    pagination: Pagination | undefined,
    isLoading: boolean,
    onPageSelected: (pageNumber: number) => void
  ) => React.ReactNode;
}

const LeaderboardContainer = ({ children }: Props) => {
  const [page, setPage] = useQueryParam<number>('leaderboardPage', withDefault(NumberParam, 1));
  const [isLoading, setIsLoading] = useState(false);
  const [leaders, setLeaders] = useState<Leader[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const [currentPublicationId] = useCurrentPublicationState();

  const onPageSelected = (pageNum: number) => {
    setPage(pageNum);
  };

  const fetchLeaderboard = useCallback(() => {
    if (currentPublicationId) {
      const params = {
        publication_id: currentPublicationId,
        page: Number.isNaN(page) ? 1 : page,
      };

      setIsLoading(true);
      api
        .get('/referral_program/metrics/leaderboard', { params })
        .then((res) => {
          setLeaders(res.data?.leaders);
          setPagination(res.data?.pagination);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPublicationId, page]);

  useEffect(() => {
    fetchLeaderboard();
  }, [fetchLeaderboard]);

  return <>{children(leaders, pagination, isLoading, onPageSelected)}</>;
};

export default LeaderboardContainer;
