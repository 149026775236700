import LoadingBox from '@/components/LoadingBox';
import useBoostAgreementComparison, { isInsufficientData } from '@/hooks/boosts/grow/useBoostAgreementComparison';
import { BoostAgreement } from '@/interfaces/boosts/grow/boost_agreement';

import DisclosurePanel from './DisclosurePanel';
import QualityRadar from './QualityRadar';

const Comparison = ({ boostAgreement }: { boostAgreement: BoostAgreement }) => {
  const { data, isLoading, isError } = useBoostAgreementComparison(boostAgreement.id);

  if (isLoading || isError || !data) return null;

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height={400}>
      {!isInsufficientData(data?.quality) && (
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            <p>Compare</p>
            <DisclosurePanel className="hidden lg:block" />
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p className="break-word">{data.quality.explanation}</p>
            <div className="pt-4 space-y-2">
              <div>
                <QualityRadar data={data.radar} />
              </div>
              <DisclosurePanel className="block lg:hidden" />
            </div>
          </dd>
        </div>
      )}
    </LoadingBox>
  );
};

export default Comparison;
