import { ArrowPathIcon, PlusSmallIcon } from '@heroicons/react/20/solid';
import { SquaresPlusIcon } from '@heroicons/react/24/outline';

import { EmptyCard } from '@/components/ResourceList';

interface Props {
  hasNoSearchResults: boolean;
  searchQuery?: string | null;
  onResetSearch: () => void;
  onCreate: () => void;
}

export const Empty = ({ hasNoSearchResults, searchQuery, onResetSearch, onCreate }: Props) => {
  const description = hasNoSearchResults
    ? `No results found for search, '${searchQuery}'`
    : `Once you create your first automation, it will show up here.`;

  const primaryActionLabel = hasNoSearchResults ? 'Reset Search' : 'New Automation';
  const primaryActionIcon = hasNoSearchResults ? ArrowPathIcon : PlusSmallIcon;
  const handlePrimaryActionClick = hasNoSearchResults ? onResetSearch : onCreate;

  return (
    <EmptyCard
      title="No Automations"
      description={description}
      primaryIcon={SquaresPlusIcon}
      primaryActionLabel={primaryActionLabel}
      onPrimaryActionClick={handlePrimaryActionClick}
      primaryActionIcon={primaryActionIcon}
    />
  );
};
