import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

import type { CreateEntriModalRecordsSuccessResponse } from './types';

interface Props {
  publicationId: string;
  includeWeb?: boolean;
  includeWebRedirect?: boolean;
  includeEmail?: boolean;
  includeBrandedLink?: boolean;
}

const useCreateEntriModalRecords = ({
  publicationId,
  includeWeb = false,
  includeWebRedirect = false,
  includeEmail = false,
  includeBrandedLink = false,
}: Props) => {
  const queryClient = useQueryClient();

  const createEntriModalRecords = ({
    brandedLinkForCloudflare = false,
  }: {
    brandedLinkForCloudflare?: boolean;
  }): Promise<CreateEntriModalRecordsSuccessResponse> =>
    api
      .post('/custom_domains/entri_modal_records', {
        publication_id: publicationId,
        entri_modal_records: {
          include_web: includeWeb,
          include_web_redirect: includeWebRedirect,
          include_email: includeEmail,
          include_branded_link: includeBrandedLink,
          branded_link_for_cloudflare: brandedLinkForCloudflare,
        },
      })
      .then((res: any) => res.data);

  return useMutation(createEntriModalRecords, {
    onSuccess: () => {
      queryClient.invalidateQueries(['entri_modal_records', publicationId]);
    },
    onError: () => {
      toast.error('Failed to create Entri DNS records. Please try again.');
    },
  });
};

export default useCreateEntriModalRecords;
