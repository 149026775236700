import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import api from '@/services/swarm';
import analytics from '@/utils/analytics';

interface EmailConfirmationProps {
  token: string;
  tokenId: string;
}

const useConfirmEmail = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [requestSent, setRequestSent] = useState(false);
  const [errors, setErrors] = useState('');

  const tokenParam = searchParams.get('confirmation_token');
  const tokenIdParam = searchParams.get('token_id');

  const confirmEmail = useMutation(
    ({ token, tokenId }: EmailConfirmationProps) =>
      api
        .post('/signups/confirm_email', {
          confirmation_token: token,
          token_id: tokenId,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        localStorage.setItem('signupToken', data.token);

        analytics.identify(localStorage.getItem('email'), {
          email: localStorage.getItem('email'),
          firstName: localStorage.getItem('firstName'),
          lastName: localStorage.getItem('lastName'),
          emailVerified: true,
        });

        analytics.track('Email Confirmed', {
          email: localStorage.getItem('email'),
          firstName: localStorage.getItem('firstName'),
          lastName: localStorage.getItem('lastName'),
          emailVerified: true,
        });

        navigate('/signup/phone_collection');
      },
      onError: (e: any) => {
        if (e.response?.data?.status === 404) {
          setErrors('Could not find user. Please retry, or attempt to signup again.');
        } else {
          const fallbackMessage = 'Invalid token. Please retry, or request a new confirmation email.';
          const message = e.response?.data?.error || fallbackMessage;
          const includesTempUser = message.includes('TempUser');
          const errorMessage = includesTempUser ? fallbackMessage : message;
          setErrors(errorMessage);
        }
      },
    }
  );

  useEffect(() => {
    if (tokenParam && tokenIdParam && !confirmEmail.isLoading && !requestSent) {
      setRequestSent(true);
      confirmEmail.mutate({ token: tokenParam, tokenId: tokenIdParam });
    }
  }, [tokenParam, tokenIdParam, confirmEmail, requestSent]);

  return {
    errors,
    isRequesting: confirmEmail.isLoading,
  };
};

export default useConfirmEmail;
