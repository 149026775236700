import { createContext, useContext } from 'react';

export type PostMetaContextContent = {
  postMetaLastUpdated?: number;
  setPostMetaLastUpdated: (updated?: number) => void;
};

export const PostMetaContext = createContext<PostMetaContextContent>({
  postMetaLastUpdated: undefined,
  setPostMetaLastUpdated: () => {},
});

export const usePostMetaContext = () => useContext(PostMetaContext);
