import { useMemo } from 'react';

import { PostTargetAction, PostTargetPlatform, PostTargetTier } from '@/interfaces/post_target';

import usePostTargets from './usePostTargets';

const usePostTargetsAudiences = (postId: string, platform: PostTargetPlatform) => {
  const { data: postTargets } = usePostTargets(postId, platform);

  return useMemo(() => {
    if (!postTargets)
      return {
        includedAudiences: [],
        excludedAudiences: [],
      };
    const { post_targets: targets } = postTargets;

    const allPremium = targets.find(
      (target) =>
        target.receiver_type === 'Publication' &&
        target.tier === PostTargetTier.PREMIUM &&
        target.tier_id === PostTargetTier.PREMIUM
    );

    const includedAudiences = targets.filter(
      (t) =>
        t.action === PostTargetAction.INCLUDE &&
        // If allPremium is true, we only want to list the allPremium premium target.
        // In case other tiers were also selected, we want to exclude them from the list.
        ((allPremium && (t.tier !== PostTargetTier.PREMIUM || t === allPremium)) || !allPremium)
    );

    const excludedAudiences = targets.filter((t) => t.action === PostTargetAction.EXCLUDE);

    return {
      includedAudiences,
      excludedAudiences,
    };
  }, [postTargets]);
};

export default usePostTargetsAudiences;
