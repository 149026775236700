import { AutomationConditions } from './automation_condition';

export enum AutomationTriggerState {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum AutomationTriggerEvent {
  API = 'api',
  DOWNGRADE = 'downgrade',
  EMAIL_SUBMISSION = 'email_submission',
  FORM_SUBMISSION = 'form_submission',
  MANUAL = 'manual',
  POLL_SUBMISSION = 'poll_submission',
  REFERRAL_ACTION = 'referral_action',
  SEGMENT_ACTION = 'segment_action',
  SIGNUP = 'signup',
  UNENGAGED = 'unengaged',
  UPGRADE = 'upgrade',
}

export interface AutomationTrigger {
  id: string;
  state: AutomationTriggerState;
  event: AutomationTriggerEvent;
  automation_id: string;
  conditions: AutomationConditions;
  main_conditions: AutomationConditions;
}

export interface AutomationTriggerHelpText {
  text: string;
  url?: string;
  urlText?: string;
}
