import { Link } from 'react-router-dom';

import { Typography } from '@/components/Typography';
import { useAutomations } from '@/hooks/useAutomations';
import pluralize from '@/utils/pluralize';

interface EnrollInAutomationStepNodeProps {
  automationIds: string[];
}

const AutomationsDisplay = ({ automationIds = [] }: { automationIds: string[] }) => {
  const { data } = useAutomations({ ids: automationIds });
  const automations = data?.pages.flatMap((page) => page.automations);

  return (
    <>
      <Typography size="xs">Enroll in {pluralize('Automation', automationIds.length)}</Typography>

      <ul className="divide-y">
        {automations?.map((automation) => (
          <li key={automation.id}>
            <Typography token="font-semibold/text/xs">
              <Link to={`/automations/${automation.id}/workflow`} target="_blank" className="py-1 block group">
                <Typography
                  token="font-medium/text/xs"
                  className="text-gray-900 group-hover:text-gray-500 transition-colors"
                >
                  {automation.name}
                </Typography>
              </Link>
            </Typography>
          </li>
        ))}
      </ul>
    </>
  );
};

const EnrollInAutomationStepNode = ({ automationIds = [] }: EnrollInAutomationStepNodeProps) => {
  if (automationIds.length === 0) {
    return <Typography size="xs">Not configured</Typography>;
  }

  return <AutomationsDisplay automationIds={automationIds} />;
};

export default EnrollInAutomationStepNode;
