import cx from 'classnames';

interface Props {
  className?: string;
}

// NOTE: We use HeroIcons for Swarm. This is a custom Icon not available and provided by Design.
// We should add new Icons to the app sparingly to avoid bloating the app with custom Icons.

const TextIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      className={cx(className)}
    >
      <path
        d="M10 0.5V2.5C10 2.63261 9.94732 2.75979 9.85355 2.85355C9.75979 2.94732 9.63261 3 9.5 3C9.36739 3 9.24021 2.94732 9.14645 2.85355C9.05268 2.75979 9 2.63261 9 2.5V1H5.5V9H7C7.13261 9 7.25979 9.05268 7.35355 9.14645C7.44732 9.24021 7.5 9.36739 7.5 9.5C7.5 9.63261 7.44732 9.75979 7.35355 9.85355C7.25979 9.94732 7.13261 10 7 10H3C2.86739 10 2.74021 9.94732 2.64645 9.85355C2.55268 9.75979 2.5 9.63261 2.5 9.5C2.5 9.36739 2.55268 9.24021 2.64645 9.14645C2.74021 9.05268 2.86739 9 3 9H4.5V1H1V2.5C1 2.63261 0.947321 2.75979 0.853553 2.85355C0.759785 2.94732 0.632608 3 0.5 3C0.367392 3 0.240215 2.94732 0.146447 2.85355C0.0526785 2.75979 0 2.63261 0 2.5V0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H9.5C9.63261 0 9.75979 0.0526785 9.85355 0.146447C9.94732 0.240215 10 0.367392 10 0.5Z"
        fill="black"
      />
    </svg>
  );
};

export default TextIcon;
