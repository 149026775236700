import { useParams, useSearchParams } from 'react-router-dom';
import { ChartBarIcon } from '@heroicons/react/20/solid';

import Card from '@/components/Card';
import LoadingBox from '@/components/LoadingBox';
import { EmptyCard } from '@/components/ResourceList';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { PostPlatform, PostStatus } from '@/interfaces/post';
import TabNavigation from '@/ui/TabNavigation';

import NavContainer from '../NavContainer';

import ClicksTable from './ClicksTable';
import EmailClicksTable from './EmailClicksTable';
import EmailStats from './EmailStats';
import PostStatsContainer from './PostStatsContainer';
import WarningBanner from './WarningBanner';
import WebAndEmailClicksTable from './WebAndEmailClicksTable';
import WebAndEmailStats from './WebAndEmailStats';
import WebClicksTable from './WebClicksTable';
import WebStats from './WebStats';

interface RouteParams {
  postId: string;
}

const PostAnalytics = () => {
  const { postId } = useParams() as unknown as RouteParams;
  const [searchParams] = useSearchParams();
  const activeTabParam = searchParams.get('tab') || 'email';

  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  return (
    <NavContainer postId={postId}>
      {({ post }) => {
        const { platform } = post;

        if (post.status !== PostStatus.PUBLISHED && post.status !== PostStatus.ARCHIVED && !isV2) {
          return (
            <p className="mb-64 text-gray-600 text-sm">
              Analytics are only available for published posts. Once published, come back here to see your post&apos;s
              performance.
            </p>
          );
        }

        const hasBothPlatforms = platform === PostPlatform.BOTH;
        const isEmailTabActive = hasBothPlatforms ? activeTabParam === 'email' : platform === PostPlatform.EMAIL;
        const isWebTabActive = hasBothPlatforms ? activeTabParam === 'web' : platform === PostPlatform.WEB;
        const isBothTabActive = hasBothPlatforms ? activeTabParam === 'both' : false;

        const tabs = [
          {
            name: 'email',
            label: 'Email',
            selected: activeTabParam === 'email',
            targetRoute: `/posts/${postId}/analytics?tab=email`,
          },
          {
            name: 'web',
            label: 'Web',
            selected: activeTabParam === 'web',
            targetRoute: `/posts/${postId}/analytics?tab=web`,
          },
        ];

        if (hasBothPlatforms) {
          tabs.push({
            name: 'both',
            label: 'Email & Web',
            selected: activeTabParam === 'both',
            targetRoute: `/posts/${postId}/analytics?tab=both`,
          });
        }

        const showEmptyState = post.status !== PostStatus.PUBLISHED && post.status !== PostStatus.ARCHIVED;

        if (isV2) {
          return (
            <div className="flex flex-col gap-y-4">
              <Card>
                <div className="flex flex-col gap-y-6">
                  <WarningBanner />
                  <div className="flex justify-between items-center">
                    <Typography token="font-medium/text/lg">Analytics</Typography>
                    {hasBothPlatforms ? (
                      <div className="flex w-fit">
                        <TabNavigation tabs={tabs} variant="tertiary" size="sm" />
                      </div>
                    ) : null}
                  </div>
                  {showEmptyState ? (
                    <EmptyCard
                      title="No Data Available"
                      description="Analytics are only available for published posts. Once published, come back here to see your post's performance."
                      primaryIcon={ChartBarIcon}
                    />
                  ) : null}
                  {!showEmptyState ? (
                    <PostStatsContainer postId={post.id}>
                      {(stats, isLoading, isError) => (
                        <div className="w-full">
                          <LoadingBox isLoading={isLoading} isError={isError}>
                            {isEmailTabActive && <EmailStats postId={postId} stats={stats} />}
                            {isWebTabActive && <WebStats stats={stats} />}
                            {isBothTabActive && <WebAndEmailStats stats={stats} />}
                          </LoadingBox>
                        </div>
                      )}
                    </PostStatsContainer>
                  ) : null}
                </div>
              </Card>
              {isEmailTabActive && !showEmptyState ? (
                <Card>
                  <div className="flex flex-col gap-y-4">
                    <Typography token="font-medium/text/base">Clicks</Typography>
                    <EmailClicksTable postId={postId} isClickMapSupported={post.custom_link_tracking_enabled} />
                  </div>
                </Card>
              ) : null}
              {isWebTabActive && !showEmptyState ? (
                <Card>
                  <div className="flex flex-col gap-y-4">
                    <Typography token="font-medium/text/base">Clicks</Typography>
                    <WebClicksTable postId={postId} />
                  </div>
                </Card>
              ) : null}
              {isBothTabActive && !showEmptyState ? (
                <Card>
                  <div className="flex flex-col gap-y-4">
                    <Typography token="font-medium/text/base">Clicks</Typography>
                    <WebAndEmailClicksTable postId={postId} />
                  </div>
                </Card>
              ) : null}
            </div>
          );
        }

        if (showEmptyState) {
          return (
            <p className="mb-64 text-gray-600 text-sm">
              Analytics are only available for published posts. Once published, come back here to see your post&apos;s
              performance.
            </p>
          );
        }

        const showEmailStats = hasBothPlatforms || platform === PostPlatform.EMAIL;
        const showWebStats = hasBothPlatforms || platform === PostPlatform.WEB;

        return (
          <div className="flex flex-col gap-y-4">
            <Card>
              <div className="flex flex-col gap-y-6">
                <WarningBanner />
                <div className="flex justify-between items-center">
                  <Typography token="font-medium/text/lg">Analytics</Typography>
                </div>

                <PostStatsContainer postId={post.id}>
                  {(stats, isLoading, isError) => (
                    <div className="w-full">
                      <LoadingBox isLoading={isLoading} isError={isError}>
                        {showEmailStats && <EmailStats postId={postId} stats={stats} />}
                        {showWebStats && <WebStats stats={stats} />}
                      </LoadingBox>
                    </div>
                  )}
                </PostStatsContainer>
              </div>
            </Card>
            <ClicksTable postId={postId} showEmail={showEmailStats} showWeb={showWebStats} />
          </div>
        );
      }}
    </NavContainer>
  );
};

export default PostAnalytics;
