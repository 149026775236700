import { Grid } from '@tremor/react';

import { useSettings } from '@/context/settings-context';
import { MetricCard } from '@/ui/MetricCard';
import { VERIFIED_CLICKS_EXPAND_METRIC_LABEL_SHORT, VERIFIED_CLICKS_TOOLTIP } from '@/utils/verifiedClicksConstants';

import { useSubscriberContext } from '../../_context/subscriberContext';

const Metrics = () => {
  const { settings } = useSettings();
  const isVerifiedClicksV2 = settings?.verified_clicks_v2 === true;
  const { send_stats: sendStats, total_spend: totalSpend } = useSubscriberContext();
  const clickRate = isVerifiedClicksV2 ? sendStats.click_rate_raw : sendStats.click_rate

  return (
    <Grid numCols={1} numColsSm={5} className="gap-4">
      <MetricCard variant="primary" label="Total Emails Delivered" value={sendStats.total_delivered.toLocaleString()} />
      <MetricCard
        variant="primary"
        label="Total Emails Opened"
        value={sendStats.total_unique_opened.toLocaleString()}
      />
      <MetricCard variant="primary" label="Open Rate" value={`${sendStats.open_rate.toFixed(1)}%`} />
      <MetricCard
        variant="primary"
        label="Click Through Rate"
        value={`${clickRate.toFixed(1)}%`}
        initialVisibleComplements={0}
        expandComplementsLabel={VERIFIED_CLICKS_EXPAND_METRIC_LABEL_SHORT}
        expandComplementsTooltip={VERIFIED_CLICKS_TOOLTIP}
        complements={isVerifiedClicksV2 ? [{ label: 'Verified Clicks', value: `${sendStats.click_rate_verified.toFixed(1)}%` }] : []}
      />
      <MetricCard variant="primary" label="Total Revenue" value={`${totalSpend}`} />
    </Grid>
  );
};

export default Metrics;
