import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { BoostOffer, BoostOfferStatus, VerificationMode } from '../../../interfaces/boosts/grow/boost_offer';
import api from '../../../services/swarm';

interface Props {
  onSuccess?: (response: any) => void;
  resendBoostInvitations?: boolean;
}

export interface CreateBoostOfferPayload {
  remove_rejected: boolean;
  cost_per_lead_cents?: number;
  description?: string;
  name?: string;
  verification_mode?: VerificationMode;
  max_spend_cents?: number;
  status?: BoostOfferStatus;
  eligible_countries?: string[] | null;
}

const useCreateBoostOffer = ({ onSuccess, resendBoostInvitations = false }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const createBoostOffer = ({
    cost_per_lead_cents,
    description,
    name,
    verification_mode,
    max_spend_cents,
    remove_rejected,
    status,
    eligible_countries,
  }: CreateBoostOfferPayload): Promise<BoostOffer> =>
    api
      .post('/boosts/grow/boost_offers', {
        resend_boost_invitations: resendBoostInvitations,
        publication_id: currentPublicationId,
        boost_offer: {
          cost_per_lead_cents,
          description,
          name,
          verification_mode,
          max_spend_cents,
          remove_rejected,
          status,
          eligible_countries,
        },
      })
      .then((res) => res.data);

  return useMutation(createBoostOffer, {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
  });
};

export default useCreateBoostOffer;
