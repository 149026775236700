import styled from 'styled-components';

const Title = styled.h2.attrs({
  className: 'mt-6 text-3xl font-bold text-gray-900',
})`
  font-weight: bold;
  font-size: 80px;
  line-height: 85px;
  @media (max-width: 500px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

export default Title;
