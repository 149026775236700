/* eslint-disable */
import e, { useEffect as t, useRef as r, useState as n } from 'react';
import { DragHandlePlugin as l, dragHandlePluginDefaultKey as i } from '../DragHandle';

const o = (o) => {
  const {
    className: a = 'drag-handle',
    children: p,
    editor: s,
    pluginKey: u = i,
    onNodeChange: d,
    tippyOptions: c = {},
  } = o;
  const [g, m] = n(null);
  const h = r(null);
  return (
    t(
      () =>
        g
          ? s.isDestroyed
            ? () => null
            : (h.current ||
                ((h.current = l({ editor: s, element: g, pluginKey: u, tippyOptions: c, onNodeChange: d })),
                s.registerPlugin(h.current)),
              () => {
                s.unregisterPlugin(u);
              })
          : () => null,
      [g, s, d, u]
    ),
    e.createElement('div', { className: a, ref: m }, p)
  );
};
export { o as default, o as DragHandle };
