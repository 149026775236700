import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { ActivityFeedEvent, ActivityFeedEventType } from '@/interfaces/activity_feed_event';
import { Pagination } from '@/interfaces/general';
import { Subscription } from '@/interfaces/subscription';
import api from '@/services/swarm';

export interface ActivityFeedApiResponse {
  events: ActivityFeedEvent[];
  pagination: Pagination;
}

interface Props {
  subscription: Subscription;
  search?: string;
  perPage?: number;
  eventType?: ActivityFeedEventType;
}

const useActivityFeedEvents = (
  path: string,
  cacheKey: (string | undefined)[],
  { search, eventType, perPage = 5 }: Props
) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchEvents = ({ pageParam = 1 }) =>
    api
      .get(path, {
        params: {
          per_page: perPage,
          page: pageParam,
          publication_id: currentPublicationId,
          event_type: eventType,
          search,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ActivityFeedApiResponse>(cacheKey, fetchEvents, {
    getNextPageParam: (lastPage) => {
      const { page, total, per_page: responsePerPage } = lastPage.pagination;

      if (total >= responsePerPage) return page + 1;

      return false;
    },
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onError: (errPayload: any) => errPayload?.response?.data?.error || 'Could not retrieve activity feed',
  });
};

const useAutomationActivityFeedEvents = (props: Props) =>
  useActivityFeedEvents(
    `/subscriptions/${props.subscription.id}/automation_activity_feed_events`,
    ['subscriptions', props.subscription.id, 'automation_activity_events', props.search, props.eventType],
    props
  );

const useReferralActivityFeedEvents = (props: Props) =>
  useActivityFeedEvents(
    `/subscriptions/${props.subscription.id}/referral_activity_feed_events`,
    ['subscriptions', props.subscription.id, 'referral_activity_events', props.search, props.eventType],
    props
  );

const usePostActivityFeedEvents = (props: Props) =>
  useActivityFeedEvents(
    `/subscriptions/${props.subscription.id}/post_activity_feed_events`,
    ['subscriptions', props.subscription.id, 'post_activity_events', props.search, props.eventType],
    props
  );

const usePollActivityFeedEvents = (props: Props) =>
  useActivityFeedEvents(
    `/subscriptions/${props.subscription.id}/poll_activity_feed_events`,
    ['subscriptions', props.subscription.id, 'poll_activity_events', props.search, props.eventType],
    props
  );

const useOtherEmailActivityFeedEvents = (props: Props) =>
  useActivityFeedEvents(
    `/subscriptions/${props.subscription.id}/other_email_activity_feed_events`,
    ['subscriptions', props.subscription.id, 'other_email_activity_events', props.search, props.eventType],
    props
  );

const useSubscriberActivityFeedEvents = (props: Props) =>
  useActivityFeedEvents(
    `/subscriptions/${props.subscription.id}/subscriber_activity_feed_events`,
    ['subscriptions', props.subscription.id, 'subscriber_activity_events', props.search, props.eventType],
    props
  );

const useSubscriberBillingHistoryActivityFeedEvents = (props: Props) =>
  useActivityFeedEvents(
    `/subscriptions/${props.subscription.id}/billing_history_activity_feed_events`,
    ['subscriptions', props.subscription.id, 'billing_activity_events', props.search, props.eventType],
    props
  );

export {
  useAutomationActivityFeedEvents,
  useOtherEmailActivityFeedEvents,
  usePollActivityFeedEvents,
  usePostActivityFeedEvents,
  useReferralActivityFeedEvents,
  useSubscriberActivityFeedEvents,
  useSubscriberBillingHistoryActivityFeedEvents,
};
