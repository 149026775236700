import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import cx from 'classnames';

import { zoomToReactFlowNode } from '@/utils/zoomToReactFlowNode';

import AddNewQuestion from '../QuestionForm/AddNewQuestion';
import { useEditFormViewContent } from '..';

import { FORM_BUILDER_NODE_WIDTH_CLASSNAME } from './helpers';

const CustomAddQuestionNode = ({ targetPosition }: NodeProps) => {
  const { fitView } = useReactFlow();
  const { form, currentCustomFieldIds } = useEditFormViewContent();
  const handleClickAddQuestion = () => {};

  return (
    <div
      className={cx('flex flex-col items-center rounded-md nopan h-80', FORM_BUILDER_NODE_WIDTH_CLASSNAME)}
      draggable={false}
    >
      <Handle className="opacity-0" type="target" position={targetPosition || Position.Top} />
      <AddNewQuestion
        isInitialQuestion={form.form_questions.length === 0}
        currentCustomFieldIds={currentCustomFieldIds}
        nextQuestionOrder={form.form_questions.length + 1}
        onAddQuestionClick={handleClickAddQuestion}
        onAddQuestion={(nodeId: string) => zoomToReactFlowNode(nodeId, fitView)}
      />
    </div>
  );
};

export default CustomAddQuestionNode;
