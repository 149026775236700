import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { Dropdown } from '@/ui/Dropdown';
import Switch from '@/ui/Switch';
import { Textarea } from '@/ui/Textarea';

import { ImageSelect, Input } from '../../../../components/Form';
import ColorField from '../../../../components/Form/ColorField';
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { useUpdateUpgradePage } from '../../../../hooks/useUpgradePages';
import { FONT_SIZE_OPTIONS } from '../../../../interfaces/font';
import { UpgradePage } from '../../../../interfaces/upgrade_page';
import api from '../../../../services/swarm';
import ThemeEditorDropdown from '../../../ThemeEditor/components/ThemeEditorDropdown';
import ThemeFormDrawer from '../../../ThemeEditor/components/ThemeFormDrawer';
import { Divider, FormContainer, FormInput, FormSection } from '../../../ThemeEditor/helpers';
import MarkDownHelperText from '../../components/MardownHelperText';
import { usePageContext } from '../../components/PageContext';
import Features from '../components/Features';

const MainSection = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const {
    pageData: upgradePage,
    colorPalette,
    setIsSaving,
    handleSaved: onSave,
    fontOptions,
  } = usePageContext<UpgradePage>();

  // Main Section States
  const [bgImage, setBgImage] = useState<File | string>(upgradePage?.main_bg_image_url || '');
  // const [image, setImage] = useState<File | string>(upgradePage?.main_image_url || '');
  const [bgColor, setBgColor] = useState(upgradePage?.main_section?.bg_color || '#000000');
  const [overline, setOverline] = useState(upgradePage?.main_section?.overline || '');
  const [overlineColor, setOverlineColor] = useState(upgradePage?.main_section?.overline_color || '#000000');
  const [overlineFontFamily, setOverlineFontFamily] = useState(upgradePage?.main_section?.overline_font_family || '');
  const [overlineFontSize, setOverlineFontSize] = useState(upgradePage?.main_section?.overline_font_size || 'md');
  const [headline, setHeadline] = useState(upgradePage?.main_section?.headline || '');
  const [headlineColor, setHeadlineColor] = useState(upgradePage?.main_section?.headline_color || '#000000');
  const [headlineFontFamily, setHeadlineFontFamily] = useState(upgradePage?.main_section?.headline_font_family || '');
  const [headlineFontSize, setHeadlineFontSize] = useState(upgradePage?.main_section?.headline_font_size || '4xl');
  const [description, setDescription] = useState(upgradePage?.main_section?.description || '');
  const [descriptionColor, setDescriptionColor] = useState(upgradePage?.main_section?.description_color || '#000000');
  const [descriptionFontFamily, setDescriptionFontFamily] = useState(
    upgradePage?.main_section?.description_font_family || ''
  );
  const [descriptionFontSize, setDescriptionFontSize] = useState(
    upgradePage?.main_section?.description_font_size || 'xl'
  );
  const [enableExternalStripeCheckout, setEnableExternalStripeCheckout] = useState(
    upgradePage?.main_section?.enable_external_stripe_checkout || false
  );
  const [checkoutCtaBgColor, setCheckoutCtaBgColor] = useState(
    upgradePage?.main_section?.checkout_cta_bg_color || '#000000'
  );
  const [checkoutCtaTextColor, setCheckoutCtaTextColor] = useState(
    upgradePage?.main_section?.checkout_cta_text_color || '#000000'
  );
  const [checkoutCtaText, setCheckoutCtaText] = useState(upgradePage?.main_section?.checkout_cta_text || '');
  const [cardBgColor, setCardBgColor] = useState(upgradePage?.main_section?.card_bg_color || '#000000');
  const [cardAccentColor, setCardAccentColor] = useState(upgradePage?.main_section?.card_accent_color || '#000000');
  const [cardTextColor, setCardTextColor] = useState(upgradePage?.main_section?.card_text_color || '#000000');
  const [cardFontFamily, setCardFontFamily] = useState(upgradePage?.main_section?.card_font_family || '');
  const [enableFreeCard, setEnableFreeCard] = useState(upgradePage?.main_section?.enable_free_card || false);
  const [freeCardLabelText, setFreeCardLabelText] = useState(upgradePage?.main_section?.free_card_label_text || '');
  const [freeCardCtaText, setFreeCardCtaText] = useState(upgradePage?.main_section?.free_card_cta_text || '');
  // const [monthlyCardLabelText, setMonthlyCardLabelText] = useState(
  //   upgradePage?.main_section?.monthly_card_label_text || ''
  // );
  const [freeCardFeatures, setFreeCardFeatures] = useState(upgradePage?.main_section?.free_card_features || []);
  // const [monthlyCardDenominatorText, setMonthlyCardDenominatorText] = useState(
  //   upgradePage?.main_section?.monthly_card_denominator_text || ''
  // );
  // const [monthlyCardCtaText, setMonthlyCardCtaText] = useState(upgradePage?.main_section?.monthly_card_cta_text || '');
  // const [monthlyCardFeatures, setMonthlyCardFeatures] = useState(
  //   upgradePage?.main_section?.monthly_card_features || []
  // );
  // const [annualCardLabelText, setAnnualCardLabelText] = useState(
  //   upgradePage?.main_section?.annual_card_label_text || ''
  // );
  // const [annualCardDenominatorText, setAnnualCardDenominatorText] = useState(
  //   upgradePage?.main_section?.annual_card_denominator_text || ''
  // );
  // const [annualCardCtaText, setAnnualCardCtaText] = useState(upgradePage?.main_section?.annual_card_cta_text || '');
  // const [annualCardFeatures, setAnnualCardFeatures] = useState(upgradePage?.main_section?.annual_card_features || []);
  const [mostPopularPlanType, setMostPopularPlanType] = useState<'' | 'monthly' | 'annual'>(
    upgradePage?.main_section?.most_popular_plan_type || ''
  );
  const [mostPopularPlanLabelText, setMostPopularPlanLabelText] = useState(
    upgradePage?.main_section?.most_popular_plan_label_text || ''
  );
  const [mostPopularPlanBgColor, setMostPopularPlanBgColor] = useState(
    upgradePage?.main_section?.most_popular_plan_bg_color || '#000000'
  );
  const [mostPopularPlanLabelColor, setMostPopularPlanLabelColor] = useState(
    upgradePage?.main_section?.most_popular_plan_label_color || '#000000'
  );
  const [redirectText, setRedirectText] = useState(upgradePage?.main_section?.redirect_text || '');
  const [redirectTextColor, setRedirectTextColor] = useState(
    upgradePage?.main_section?.redirect_text_color || '#000000'
  );
  const [redirectTextFontFamily, setRedirectTextFontFamily] = useState(
    upgradePage?.main_section?.redirect_text_font_family || ''
  );
  const [redirectTextFontSize, setRedirectTextFontSize] = useState(
    upgradePage?.main_section?.description_font_size || 'md'
  );
  const [enableLogo, setEnableLogo] = useState(upgradePage?.main_section?.enable_logo || false);

  useEffect(() => {
    setBgImage(upgradePage?.main_bg_image_url || '');
    // setImage(upgradePage?.main_image_url || '');
    setBgColor(upgradePage?.main_section?.bg_color || '#000000');
    setOverline(upgradePage?.main_section?.overline || '');
    setOverlineColor(upgradePage?.main_section?.overline_color || '#000000');
    setOverlineFontFamily(upgradePage?.main_section?.overline_font_family || '');
    setOverlineFontSize(upgradePage?.main_section?.overline_font_size || 'md');
    setHeadline(upgradePage?.main_section?.headline || '');
    setHeadlineColor(upgradePage?.main_section?.headline_color || '#000000');
    setHeadlineFontFamily(upgradePage?.main_section?.headline_font_family || '');
    setHeadlineFontSize(upgradePage?.main_section?.headline_font_size || '4xl');
    setDescription(upgradePage?.main_section?.description || '');
    setDescriptionColor(upgradePage?.main_section?.description_color || '#000000');
    setDescriptionFontFamily(upgradePage?.main_section?.description_font_family || '');
    setDescriptionFontSize(upgradePage?.main_section?.description_font_size || 'xl');
    setEnableFreeCard(upgradePage?.main_section?.enable_free_card || false);
    setEnableExternalStripeCheckout(upgradePage?.main_section?.enable_external_stripe_checkout || false);
    setCheckoutCtaBgColor(upgradePage?.main_section?.checkout_cta_bg_color || '#000000');
    setCheckoutCtaTextColor(upgradePage?.main_section?.checkout_cta_text_color || '#000000');
    setCheckoutCtaText(upgradePage?.main_section?.checkout_cta_text || '');
    setCardBgColor(upgradePage?.main_section?.card_bg_color || '#000000');
    setCardAccentColor(upgradePage?.main_section?.card_accent_color || '#000000');
    setCardTextColor(upgradePage?.main_section?.card_text_color || '#000000');
    setCardFontFamily(upgradePage?.main_section?.card_font_family || '');
    setFreeCardLabelText(upgradePage?.main_section?.free_card_label_text || '');
    setFreeCardCtaText(upgradePage?.main_section?.free_card_cta_text || '');
    setFreeCardFeatures(upgradePage?.main_section?.free_card_features || []);
    // setMonthlyCardLabelText(upgradePage?.main_section?.monthly_card_label_text || '');
    // setMonthlyCardDenominatorText(upgradePage?.main_section?.monthly_card_denominator_text || '');
    // setMonthlyCardCtaText(upgradePage?.main_section?.monthly_card_cta_text || '');
    // setMonthlyCardFeatures(upgradePage?.main_section?.monthly_card_features || []);
    // setAnnualCardLabelText(upgradePage?.main_section?.annual_card_label_text || '');
    // setAnnualCardDenominatorText(upgradePage?.main_section?.annual_card_denominator_text || '');
    // setAnnualCardCtaText(upgradePage?.main_section?.annual_card_cta_text || '');
    // setAnnualCardFeatures(upgradePage?.main_section?.annual_card_features || []);
    setMostPopularPlanType(upgradePage?.main_section?.most_popular_plan_type || '');
    setMostPopularPlanLabelText(upgradePage?.main_section?.most_popular_plan_label_text || '');
    setMostPopularPlanBgColor(upgradePage?.main_section?.most_popular_plan_bg_color || '#000000');
    setMostPopularPlanLabelColor(upgradePage?.main_section?.most_popular_plan_label_color || '#000000');
    setRedirectText(upgradePage?.main_section?.redirect_text || '');
    setRedirectTextColor(upgradePage?.main_section?.redirect_text_color || '#000000');
    setRedirectTextFontFamily(upgradePage?.main_section?.redirect_text_font_family || '');
    setRedirectTextFontSize(upgradePage?.main_section?.description_font_size || 'md');
    setEnableLogo(upgradePage?.main_section?.enable_logo || false);
  }, [upgradePage]);

  const updateupgradePage = useUpdateUpgradePage({
    pageId: upgradePage?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['upgrade_page', currentPublicationId, 'draft']);
      onSave();
    },
  });

  const handleSaveMainSection = (payload: any) => {
    setIsSaving(true);
    updateupgradePage.mutate({ upgrade_page: { main_section: JSON.stringify(payload) } });
  };

  const handleUploadImage = (type: string, file: File | null) => {
    setIsSaving(true);
    const formData = new FormData();

    if (typeof file !== 'string') {
      formData.append(`upgrade_page[${type}]`, file || '');
    }

    formData.append('publication_id', currentPublicationId);

    api
      .patch(`/upgrade_pages/${upgradePage?.id}`, formData)
      .then(() => {
        queryClient.invalidateQueries(['upgrade_page', currentPublicationId, 'draft']);
        onSave();
      })
      .catch((err) => {
        setIsSaving(false);
        toast.error(err?.response?.data?.error || 'Something went wrong');
      });
  };

  // TODO: support multiple tiers/prices here
  const renderPlanDropdown = () => {
    return [
      {
        enabled: true,
        label: 'None',
        value: '',
      },
    ];
  };

  return (
    <ThemeFormDrawer active={false} title="Main Section" onClickHandler={() => {}}>
      <FormContainer>
        <FormSection title="General">
          <FormInput title="Show Logo">
            <Switch
              variant="primary"
              name="enable_logo"
              checked={enableLogo}
              onChange={(_name: string, updatedValue: boolean) => {
                setEnableLogo(updatedValue);
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  enable_logo: updatedValue,
                });
              }}
            />
          </FormInput>
          <FormInput title="Background Color">
            <ColorField
              placement="bottom"
              color={bgColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setBgColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  bg_color: bgColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Background Image">
            <ImageSelect
              name="main_bg_image"
              dimensionSuggestion="Upload a high-quality 1920px x 1080px PNG for best results."
              onFileSelect={(file) => {
                setBgImage(file);
                handleUploadImage('main_bg_image', file);
              }}
              onFileClear={() => {
                setBgImage('');
                handleUploadImage('main_bg_image', null);
              }}
              file={bgImage}
            />
          </FormInput>
          {/** TODO: Still need to think through the UI implementations of this section optional image. */}
          {/* <FormInput title="Image">
            <ImageSelect
              name="main_image"
              dimensionSuggestion="high-quality PNGs are recommended for best results."
              onFileSelect={(file) => {
                setImage(file);
                handleUploadImage('main_image', file);
              }}
              onFileClear={() => {
                setImage('');
                handleUploadImage('main_image', null);
              }}
              file={image}
            />
          </FormInput> */}
        </FormSection>
        <Divider />
        <FormSection title="Redirect Text">
          <FormInput title="Redirect Text Color">
            <ColorField
              placement="bottom"
              color={redirectTextColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setRedirectTextColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  redirect_text_color: redirectTextColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Redirect Text">
            <Input
              name="redirect_text"
              value={redirectText}
              maxLength={150}
              onChange={(e) => setRedirectText(e.target.value)}
              helperText="This button redirects users to the posts page of your site. If a user has just signed up and there is a signup flow in progress, they will be redirected instead to the next step in the signup flow."
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  redirect_text: redirectText,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={redirectTextFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  redirect_text_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={redirectTextFontSize}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  redirect_text_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Overline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={overlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setOverlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  overline_color: overlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text" helperText={<MarkDownHelperText />}>
            <Input
              name="overline"
              value={overline}
              maxLength={150}
              onChange={(e) => setOverline(e.target.value)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  overline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={overlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  overline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={overlineFontSize}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  overline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Headline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={headlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setHeadlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  headline_color: headlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text" helperText={<MarkDownHelperText />}>
            <Input
              name="headline"
              value={headline}
              maxLength={150}
              onChange={(e) => setHeadline(e.target.value)}
              onBlur={() => {
                setIsSaving(true);
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  headline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={headlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  headline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={headlineFontSize}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  headline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Description">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={descriptionColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setDescriptionColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  description_color: descriptionColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text" helperText={<MarkDownHelperText />}>
            <Textarea
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onBlur={() => {
                setIsSaving(true);
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  description,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={descriptionFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  description_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={descriptionFontSize}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  description_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Recommend a plan">
          <FormInput title="Plan Type">
            <Dropdown
              name="most_popular_plan"
              value={mostPopularPlanType}
              helperText="You can highlight a specific payment plan to recommend to your users."
              onSelect={(_name: string, value: any) => {
                setMostPopularPlanType(value);
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  most_popular_plan_type: value,
                });
              }}
              options={renderPlanDropdown()}
            />
          </FormInput>
          <FormInput title="Background Color">
            <ColorField
              placement="bottom"
              color={mostPopularPlanBgColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setMostPopularPlanBgColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  most_popular_plan_bg_color: mostPopularPlanBgColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Label">
            <Input
              name="recommended_plan_label"
              value={mostPopularPlanLabelText}
              maxLength={150}
              helperText="Update the text that appears in the recommended plan banner."
              onChange={(e) => setMostPopularPlanLabelText(e.target.value)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  most_popular_plan_label_text: mostPopularPlanLabelText,
                });
              }}
            />
          </FormInput>
          <FormInput title="Label Color">
            <ColorField
              placement="bottom"
              color={mostPopularPlanLabelColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setMostPopularPlanLabelColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  most_popular_plan_label_color: mostPopularPlanLabelColor,
                });
              }}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Stripe Checkout Experience">
          <FormInput
            title="External checkout"
            helperText="Enabling this adds a button to the Pricing Cards that redirects users to an external Stripe checkout and removes the Stripe-powered Credit Card input from the page."
          >
            <Switch
              variant="primary"
              name="enable_external_stripe_checkout"
              checked={enableExternalStripeCheckout}
              onChange={(_name: string, updatedValue: boolean) => {
                setEnableExternalStripeCheckout(updatedValue);
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  enable_external_stripe_checkout: updatedValue,
                });
              }}
            />
          </FormInput>
          {!enableExternalStripeCheckout && (
            <>
              <FormInput
                title="Button background color"
                helperText="This will update the background color of the submission button below the pricing cards."
              >
                <ColorField
                  placement="bottom"
                  color={checkoutCtaBgColor}
                  colorPalette={colorPalette}
                  onColorChange={(color: any) => setCheckoutCtaBgColor(color?.hexString)}
                  onBlur={() => {
                    handleSaveMainSection({
                      ...upgradePage?.main_section,
                      checkout_cta_bg_color: checkoutCtaBgColor,
                    });
                  }}
                />
              </FormInput>
              <FormInput
                title="Button text color"
                helperText="This will update the text color of the submission button below the pricing cards."
              >
                <ColorField
                  placement="bottom"
                  color={checkoutCtaTextColor}
                  colorPalette={colorPalette}
                  onColorChange={(color: any) => setCheckoutCtaTextColor(color?.hexString)}
                  onBlur={() => {
                    handleSaveMainSection({
                      ...upgradePage?.main_section,
                      checkout_cta_text_color: checkoutCtaTextColor,
                    });
                  }}
                />
              </FormInput>
              <FormInput
                title="Button text"
                helperText="This will update the text of the submission button below the pricing cards."
              >
                <Input
                  name="checkout_cta_text"
                  value={checkoutCtaText}
                  maxLength={150}
                  onChange={(e) => setCheckoutCtaText(e.target.value)}
                  onBlur={() => {
                    handleSaveMainSection({
                      ...upgradePage?.main_section,
                      checkout_cta_text: checkoutCtaText,
                    });
                  }}
                />
              </FormInput>
            </>
          )}
        </FormSection>
        <Divider />
        <FormSection title="Cards">
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={cardFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  card_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Background Color">
            <ColorField
              placement="bottom"
              color={cardBgColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setCardBgColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  card_bg_color: cardBgColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Accent Color">
            <ColorField
              placement="bottom"
              color={cardAccentColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setCardAccentColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  card_accent_color: cardAccentColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text Color">
            <ColorField
              placement="bottom"
              color={cardTextColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setCardTextColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  card_text_color: cardTextColor,
                });
              }}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Free Card">
          <FormInput title="Show Free Card">
            <Switch
              variant="primary"
              name="enable_free_card"
              checked={enableFreeCard}
              onChange={(_name: string, updatedValue: boolean) => {
                setEnableFreeCard(updatedValue);
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  enable_free_card: updatedValue,
                });
              }}
            />
          </FormInput>
          <FormInput title="Free Card Label">
            <Input
              name="free_card_label"
              value={freeCardLabelText}
              maxLength={150}
              onChange={(e) => setFreeCardLabelText(e.target.value)}
              onBlur={() => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  free_card_label_text: freeCardLabelText,
                });
              }}
            />
          </FormInput>
          {enableExternalStripeCheckout && (
            <FormInput title="Button">
              <Input
                name="free_card_cta"
                value={freeCardCtaText}
                maxLength={50}
                onChange={(e) => setFreeCardCtaText(e.target.value)}
                helperText="This button behaves similarly to the redirect button above. It will redirect users to the posts page of your site. If a user has just signed up and there is a signup flow in progress, they will be redirected instead to the next step in the signup flow."
                onBlur={() => {
                  handleSaveMainSection({
                    ...upgradePage?.main_section,
                    free_card_cta_text: freeCardCtaText,
                  });
                }}
              />
            </FormInput>
          )}
          <FormInput title="Features">
            <Features
              features={freeCardFeatures}
              onUpdate={(newList) => {
                handleSaveMainSection({
                  ...upgradePage?.main_section,
                  free_card_features: newList,
                });
              }}
            />
          </FormInput>
        </FormSection>
        {/* TODO: support Tier/Price customization, keyed by Tier or Price ID */}
        {/* {premiumSettings?.stripe_monthly && (
          <>
            <Divider />
            <FormSection title="Monthly Card">
              <FormInput title="Label">
                <Input
                  name="monthly_card_label"
                  value={monthlyCardLabelText}
                  maxLength={150}
                  onChange={(e) => setMonthlyCardLabelText(e.target.value)}
                  onBlur={() => {
                    handleSaveMainSection({
                      ...upgradePage?.main_section,
                      monthly_card_label_text: monthlyCardLabelText,
                    });
                  }}
                />
              </FormInput>
              <FormInput title="Denominator">
                <Input
                  name="monthly_card_denominator"
                  value={monthlyCardDenominatorText}
                  maxLength={150}
                  onChange={(e) => setMonthlyCardDenominatorText(e.target.value)}
                  onBlur={() => {
                    handleSaveMainSection({
                      ...upgradePage?.main_section,
                      monthly_card_denominator_text: monthlyCardDenominatorText,
                    });
                  }}
                />
              </FormInput>
              {enableExternalStripeCheckout && (
                <FormInput title="Button">
                  <Input
                    name="monthly_card_cta"
                    value={monthlyCardCtaText}
                    maxLength={150}
                    onChange={(e) => setMonthlyCardCtaText(e.target.value)}
                    onBlur={() => {
                      handleSaveMainSection({
                        ...upgradePage?.main_section,
                        monthly_card_cta_text: monthlyCardCtaText,
                      });
                    }}
                  />
                </FormInput>
              )}
              <FormInput title="Features">
                <Features
                  features={monthlyCardFeatures}
                  onUpdate={(newList) => {
                    handleSaveMainSection({
                      ...upgradePage?.main_section,
                      monthly_card_features: newList,
                    });
                  }}
                />
              </FormInput>
            </FormSection>
          </>
        )}
        {premiumSettings?.stripe_annual && (
          <>
            <Divider />
            <FormSection title="Annual Card">
              <FormInput title="Label">
                <Input
                  name="annual_card_label"
                  value={annualCardLabelText}
                  maxLength={150}
                  onChange={(e) => setAnnualCardLabelText(e.target.value)}
                  onBlur={() => {
                    handleSaveMainSection({
                      ...upgradePage?.main_section,
                      annual_card_label_text: annualCardLabelText,
                    });
                  }}
                />
              </FormInput>
              <FormInput title="denominator">
                <Input
                  name="annual_card_denominator"
                  value={annualCardDenominatorText}
                  maxLength={150}
                  onChange={(e) => setAnnualCardDenominatorText(e.target.value)}
                  onBlur={() => {
                    handleSaveMainSection({
                      ...upgradePage?.main_section,
                      annual_card_denominator_text: annualCardDenominatorText,
                    });
                  }}
                />
              </FormInput>
              {enableExternalStripeCheckout && (
                <FormInput title="Button">
                  <Input
                    name="annual_card_cta"
                    value={annualCardCtaText}
                    maxLength={150}
                    onChange={(e) => setAnnualCardCtaText(e.target.value)}
                    onBlur={() => {
                      handleSaveMainSection({
                        ...upgradePage?.main_section,
                        annual_card_cta_text: annualCardCtaText,
                      });
                    }}
                  />
                </FormInput>
              )}
              <FormInput title="Features">
                <Features
                  features={annualCardFeatures}
                  onUpdate={(newList) => {
                    handleSaveMainSection({
                      ...upgradePage?.main_section,
                      annual_card_features: newList,
                    });
                  }}
                />
              </FormInput>
            </FormSection>
          </>
        )} */}
      </FormContainer>
    </ThemeFormDrawer>
  );
};

export default MainSection;
