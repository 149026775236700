import AppliedFilter from '../AppliedFilter';
import { AttributeCondition, StringOperatorsList } from '../types';

const OwnerEmailFilterApplied = ({ condition, onRemove }: { condition: AttributeCondition; onRemove: () => void }) => {
  const operator = StringOperatorsList.find((o) => o.value === condition.filters.operator);
  if (!operator) return null;

  const operatorLabel = operator.shorthand;

  return <AppliedFilter name="Email" label={operatorLabel} value={condition.filters.value} onRemove={onRemove} />;
};

export default OwnerEmailFilterApplied;
