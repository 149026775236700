import { useNavLinks } from '@/hooks/useNavlinks';
import { NavLink } from '@/interfaces/nav_link';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import AddNewPage from '../AddPage';
import NonSortablePageGroup from '../Helpers/NonSortablePageGroup';

import UnlinkedPage from './UnlinkedPage';

const UnlinkedPages = () => {
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useNavLinks({
    search: '',
    externalLinks: true,
    pages: true,
    disabled: true,
    live: false,
  });
  const navLinks = data?.pages.flatMap((page) => page.nav_links) || [];

  return (
    <PropertiesGrouping
      label="Draft Pages"
      description="You can add internal or external pages to your website or navigation bar."
    >
      <div className="space-y-5">
        <AddNewPage />
        <NonSortablePageGroup>
          {navLinks?.map((link: NavLink) => {
            return <UnlinkedPage key={link.id} link={link} />;
          })}
        </NonSortablePageGroup>
        {hasNextPage && (
          <LoadMoreButton size="sm" isFetchingNextPage={isFetchingNextPage} fetchNextPage={fetchNextPage} />
        )}
      </div>
    </PropertiesGrouping>
  );
};

export default UnlinkedPages;
