import { Image } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { SliderSettings } from '../SliderSettings';

type Props = AttributeSettingProps & {};

const MAX_SIZE = 350;

export const ImageSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Image" text="Image" popoverTitle="Image" Icon={Image}>
      <>
        <SliderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="imageSize"
          title="Size"
          unit="px"
          min={4}
          max={MAX_SIZE}
          step={1}
        />
        <SliderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="imageRadius"
          title="Radius"
          unit="px"
          min={0}
          max={MAX_SIZE}
          step={1}
        />
      </>
    </PopoverHelper>
  );
};

export default ImageSettings;
