import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  onSuccess?: () => void;
}

export default function useDeletePage({ onSuccess }: Props) {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    async ({ pageId }: { pageId: string }) => {
      const { data } = await api.delete(`/pages/${pageId}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      return data
    },
    {
      onSuccess: () => {
        toast.success('Page deleted successfully');
        queryClient.invalidateQueries(['publications', currentPublicationId, 'pages']);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'sites', "null"]);

        if (onSuccess) onSuccess();
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
}
