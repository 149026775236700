import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  id?: string;
  onSuccess: () => void;
}

interface UpdateParams {
  existing_price_ids: string[];
  tier_mapping: Record<string, string>;
}

const useCreateMissingTargetPrices = ({ id, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<unknown, unknown, UpdateParams>(
    (params) =>
      api
        .post(`/stripe_migrations/${id}/create_matching_target_prices`, {
          publication_id: currentPublicationId,
          ...params,
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useCreateMissingTargetPrices;
