import { useMemo } from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/20/solid';
import { Color } from '@tremor/react';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import ChartHeader from '@/components/Chartsv2/ChartHeader';
import { Color as DesignTokenColor } from '@/components/DesignTokens';
import useEarningsPerPeriod from '@/hooks/boosts/monetize/useEarningsPerPeriod';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Option } from '@/interfaces/general';
import { TimePeriod } from '@/interfaces/time_period';
import { currencyFormatter } from '@/utils';
import pluralize from '@/utils/pluralize';
import { timePeriodLabels } from '@/utils/timePeriods';

interface Props {
  boost_agreement_id?: string;
  alternatePeriod?: TimePeriod;
}

export const EARNINGS_CATEGORY: Option = {
  value: 'earnings',
  label: 'Earnings',
};
export const PREVIOUS_EARNINGS_CATEGORY: Option = {
  value: 'previous_earnings',
  label: 'Previous Earnings',
};

export const CHART_CATEGORIES = [EARNINGS_CATEGORY.label, PREVIOUS_EARNINGS_CATEGORY.label];
export const CHART_COLORS: Color[] = ['emerald', 'gray'];

const EarningsPerPeriodChart = ({ boost_agreement_id, alternatePeriod }: Props) => {
  const { period, TimePeriodSelect } = useTimePeriodSelect();
  const { data: earningsData, isLoading: isEarningsDataLoading } = useEarningsPerPeriod({
    period: (alternatePeriod || period) as keyof typeof timePeriodLabels,
    boost_agreement_id,
  });

  const totalEarnings =
    useMemo(() => {
      return earningsData?.metrics.reduce((pv, cv) => pv + cv.earnings, 0);
    }, [earningsData]) || 0;
  // remove $ because we show the currency sign as an icon
  const formattedTotalEarnings = currencyFormatter(totalEarnings, 'USD').replace('$', '');

  const mappedChartData = useMemo(() => {
    if (isEarningsDataLoading || typeof earningsData !== 'object') {
      return [];
    }

    return earningsData?.metrics.map((row) => {
      return {
        date: row.name,
        [EARNINGS_CATEGORY.label]: row.earnings,
        [PREVIOUS_EARNINGS_CATEGORY.label]: row.previous_earnings,
      };
    });
  }, [earningsData, isEarningsDataLoading]);

  return (
    <ChartCard
      noResultsText={isEarningsDataLoading ? 'Fetching...' : 'No earnings data available within the chosen time range.'}
      noResults={mappedChartData.length === 0}
    >
      <div className="flex flex-col gap-y-5">
        <ChartHeader
          title="Total revenue from Boosts"
          description="Break down over time of how much revenue you have made via Boosts."
          kpiLabel={pluralize('total revenue', totalEarnings, 'total revenue', true)}
          kpiValue={formattedTotalEarnings}
          timePeriodSelector={<TimePeriodSelect />}
          Icon={CurrencyDollarIcon}
          textColor={DesignTokenColor.SUCCESS}
          iconColor="text-feedback-success-500"
        />
        <AreaChart
          index="date"
          data={mappedChartData}
          categories={CHART_CATEGORIES}
          colors={CHART_COLORS}
          showLegend={false}
          showXAxis
          showYAxis
          showGridLines
          valueFormatter={(value) => currencyFormatter(value, 'USD')}
        />
      </div>
    </ChartCard>
  );
};

export default EarningsPerPeriodChart;
