import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AutomationStepStepType } from '@/interfaces/automations/automation_step';
import api from '@/services/swarm';

const useAutomationStep = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  const createAutomationStep = (
    automationId: string,
    stepType: AutomationStepStepType,
    branchArm?: string,
    parentId?: string,
    parentStepId?: string
  ) => {
    return api.post(`/automations/${automationId}/automation_steps`, {
      publication_id: currentPublicationId,
      automation_step: {
        step_type: stepType,
        branch_arm: branchArm,
        ...(parentStepId ? { parent_id: parentStepId } : {}),
        ...(parentId ? { parent_id: parentId } : {}),
      },
    });
  };

  const deleteAutomationStep = (id: string) => {
    return api.delete(`/automation_steps/${id}`, {
      params: {
        publication_id: currentPublicationId,
      },
    });
  };

  const updateAutomationStep = (id: string, params: any) => {
    return api.patch(`/automation_steps/${id}`, {
      publication_id: currentPublicationId,
      automation_step: {
        ...params,
      },
    });
  };

  const reorderAutomationStep = (automation_id: string, id: string, parent_id: string, branch_arm: string | null) => {
    return api.patch(`/automation_steps/${id}/reorder`, {
      publication_id: currentPublicationId,
      automation_id,
      automation_step: {
        parent_id,
        branch_arm,
      },
    });
  };

  return {
    createAutomationStep,
    deleteAutomationStep,
    updateAutomationStep,
    reorderAutomationStep,
  };
};

export default useAutomationStep;
