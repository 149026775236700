import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import cx from 'classnames';

import Switch from '@/ui/Switch';

import NotEditing from './NotEditing';

interface Props {
  name?: string;
  label: string;
  value: boolean;
  description: string | React.ReactNode;
  helperText?: string;
  onSave: (val: boolean) => Promise<any>;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
  disabled?: boolean;
  tooltip?: string;
  noBorder?: boolean;
}

const SwitchForm = ({
  name,
  label,
  description,
  value,
  onSave,
  toastSuccessMessage,
  toastErrorMessage,
  helperText,
  disabled = false,
  tooltip,
  noBorder = false,
}: Props) => {
  const [inputValue, setInputValue] = useState<boolean>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleSave = async (val: boolean) => {
    await onSave(val)
      .then(() => {
        setInputValue(val);

        if (toastSuccessMessage) {
          toast.success(toastSuccessMessage);
        }
      })
      .catch(() => {
        if (toastErrorMessage) {
          toast.error(toastErrorMessage);
        }
      });
  };

  const id = `${name}-${Math.random().toString(36).slice(2)}`;

  return (
    <div
      className={cx(
        'flex justify-between items-center pb-4 border-gray-100 last:border-b-0',
        noBorder ? '' : 'border-b'
      )}
    >
      <div className="w-full max-w-md">
        <NotEditing id={id} label={label} value={description} helperText={helperText} tooltip={tooltip} />
      </div>

      <div className="pt-3">
        <Switch
          variant="primary"
          name={name || label.split(' ').join('-')}
          checked={Boolean(inputValue)}
          onChange={(_name: string, updatedValue: boolean) => handleSave(updatedValue)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SwitchForm;
