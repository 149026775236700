import Modal from '@/components/Modal';
import { Typography, TypographyStack } from '@/components/Typography';
import { Button } from '@/ui/Button';

export interface ApplyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotQualifiedModal = ({ isOpen, onClose }: ApplyModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} includeCloseButton={false} className="max-w-lg">
      <section className="p-6">
        <TypographyStack gap="4">
          <Typography as="p">
            Uh oh, it doesn&apos;t look like you qualify to monetize via Boosts at the moment.
          </Typography>
          <Typography as="p">
            Boosts is a trusted ecosystem of active newsletters. If you&apos;re just getting started on beehiiv, no need
            to worry - you should have access soon. Most newsletters receive access very shortly after they start
            publishing.
          </Typography>
          <Typography as="p">
            Monetization tools on beehiiv require careful vetting to prevent abuse, and we just haven&apos;t gotten a
            chance to know you yet. If you&apos;ve signed up strictly to Monetize using Boosts, beehiiv is not a fit for
            your needs, as the platform is designed for actively publishing newsletters.
          </Typography>
          <Typography as="p">For additional information, please contact support.</Typography>
        </TypographyStack>

        <div className="flex flex-row justify-end mt-6">
          <Button variant="primary" type="button" onClick={() => onClose()}>
            Close
          </Button>
        </div>
      </section>
    </Modal>
  );
};

export default NotQualifiedModal;
