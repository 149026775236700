import { FocusEventHandler, useCallback } from 'react';
import { Editor } from '@tiptap/core';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';
import RSSEntryLayoutPanel from '@/components/TiptapEditor/extensions/RSS/menus/RSSEntryLayoutPanel';
import { Typography } from '@/components/Typography';
import { RSSThumbnailPosition } from '@/interfaces/external_rss_feed';

import { FieldSet, InputField } from '../../ui/Input';
import { Panel, PanelHeader, PanelSection } from '../../ui/Panel/styled';

import Dropdown from './Dropdown';

export const Layout = ({
  editor,
  onBack,
  compact = false,
  entriesToShow,
  setEntriesToShow,
  onUpdateEntriesToShow,
}: {
  editor: Editor;
  onBack: Function;
  compact?: boolean;
  entriesToShow: string;
  setEntriesToShow: Function;
  onUpdateEntriesToShow: FocusEventHandler<HTMLInputElement>;
}) => {
  const onChangeEntriesToShow = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntriesToShow(Number(e.target.value));
    },
    [setEntriesToShow]
  );

  const { settings, entryLayout } = editor.getAttributes('rss');

  const onChangeToggleDisplayThumbnail = useCallback(() => {
    const newSettings = { ...settings, displayThumbnail: !settings.displayThumbnail };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor, settings]);

  const onChangeThumbnailPosition = useCallback(
    (name: string, value: string) => {
      const newSettings = { ...settings, thumbnailPosition: value as RSSThumbnailPosition };
      editor.chain().updateAttributes('rss', { settings: newSettings }).run();
    },
    [editor, settings]
  );

  return (
    <Panel>
      {!compact && (
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            Layout
          </Button>
        </PanelHeader>
      )}
      <PanelSection>
        <FieldSet>
          <div className="flex flex-col gap-2">
            <Typography token="font-medium/text/xs" color="white" colorWeight="900">
              Articles to show
            </Typography>
            <InputField
              value={entriesToShow}
              type="number"
              min={1}
              onChange={onChangeEntriesToShow}
              onBlur={onUpdateEntriesToShow}
              onSuspend={onUpdateEntriesToShow}
            />
          </div>
          <ToggleWrapper
            icon={<Icon name="Image" />}
            switchEl={<ToggleSwitch isChecked={settings.displayThumbnail} onChange={onChangeToggleDisplayThumbnail} />}
          >
            Show thumbnail
          </ToggleWrapper>
          {
            // eslint-disable-next-line no-nested-ternary
            settings.displayTitle && settings.displayThumbnail ? (
              <div className="flex flex-col gap-2">
                <Typography token="font-medium/text/xs" color="white" colorWeight="900">
                  Thumbnail Position
                </Typography>
                <Dropdown
                  actions={[
                    { label: RSSThumbnailPosition.TOP, value: RSSThumbnailPosition.TOP },
                    { label: RSSThumbnailPosition.BOTTOM, value: RSSThumbnailPosition.BOTTOM },
                    {
                      label: RSSThumbnailPosition.LEFT,
                      value: RSSThumbnailPosition.LEFT,
                      disabled: entryLayout !== '1-col',
                    },
                    {
                      label: RSSThumbnailPosition.RIGHT,
                      value: RSSThumbnailPosition.RIGHT,
                      disabled: entryLayout !== '1-col',
                    },
                    {
                      label: RSSThumbnailPosition.ALTERNATING_HORIZONTAL,
                      value: RSSThumbnailPosition.ALTERNATING_HORIZONTAL,
                      disabled: entryLayout !== '1-col',
                    },
                  ]}
                  value={
                    settings.thumbnailPosition ||
                    (settings.display_title_above_thumbnail ? RSSThumbnailPosition.BOTTOM : RSSThumbnailPosition.TOP)
                  }
                  onSelect={onChangeThumbnailPosition}
                />
              </div>
            ) : null
          }
          <RSSEntryLayoutPanel editor={editor} />
        </FieldSet>
      </PanelSection>
    </Panel>
  );
};
