import { useQuery } from 'react-query';

import { PostWebStats } from '../interfaces/post';
import api from '../services/swarm';

interface Arguments {
  id: string;
  isEnabled?: boolean;
  staleTime?: number | undefined;
}

export default function usePostWebStats({ id, isEnabled = true, staleTime = 1000 * 60 }: Arguments) {
  return useQuery<PostWebStats>(
    ['posts', id, 'web_stats'],
    () => api.get(`/posts/${id}/web_stats`).then((res) => res.data),
    {
      keepPreviousData: true,
      enabled: isEnabled,
      staleTime,
    }
  );
}
