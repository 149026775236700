import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClockCounterClockwise, Plus } from '@phosphor-icons/react';

import { useWebsiteContext, useWebsitePageRouteGetter, WebsiteProvider } from '@/context/website-context';
import useSiteTemplate from '@/hooks/useSiteTemplates/useSiteTemplate';

import { AttributesPanelNavbarFooterContextProvider } from '../../_components/DreamEditor/AttributesPanel/navbarFooterContextProvider';
import { Layout } from '../../_components/Layout';
import { ActionMenu } from '../../_components/Layout/ActionMenu';
import AttributesPanelPortal from '../../_components/Layout/AttributesPanelPortal';
import InsertPanelPortal from '../../_components/Layout/InsertPanelPortal';
import SidePanelTabs from '../../_components/Layout/SidePanelTabs';
import { NavSection } from '../../_components/SideNav/NavSection';
import { SavePageIndicator } from '../../_components/SideNav/SaveIndicator';
import { Button } from '../../_components/UI/Button';
import { Tooltip } from '../../_components/UI/Tooltip';
import VersionHistoryNotice from '../../_components/VersionHistoryNotice';

import { TemplatesEditor, TemplatesEditorProvider } from './_components/TemplatesEditor';

const TemplatesEditorPage = () => {
  const { pagesRoutes, defaultRoutes } = useWebsiteContext();
  const navigate = useNavigate();
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const { previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);
  const { pageId, templateId } = useParams();
  const { data: template } = useSiteTemplate({ siteTemplateId: templateId as string });
  const pageRouteGetter = useWebsitePageRouteGetter();
  const pageRoute = pageId ? pageRouteGetter?.getPageRouteFromID(pageId) : undefined;
  const homePageId = pageRouteGetter?.getHomePageID();

  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [editorRect, setEditorRect] = useState<Partial<DOMRect>>({ height: 0, width: 0 });
  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion]);

  useEffect(() => {
    if (pageId && homePageId && !pageRoute) {
      navigate(`/website_builder_v2/page/${homePageId}`);
    }
  }, [pageId, navigate, homePageId, pageRoute]);

  useEffect(() => {
    const editorContainer = editorContainerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.contentRect) {
          setEditorRect(entry.contentRect);
        }
      }
    });

    if (editorContainer) {
      // setup listener for element size change
      resizeObserver.observe(editorContainer);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary pb-0 pt-0"
      isSidebarResizable
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
        />
      }
      rightChildren={
        <Tooltip center="Version History" delay={300}>
          <Button
            variant="secondary"
            Icon={ClockCounterClockwise}
            iconWeight="bold"
            onClick={() => setIsVersionHistoryPanelOpen((prev) => !prev)}
            className="h-full"
          />
        </Tooltip>
      }
      sidenavChildren={
        <>
          <SidePanelTabs
            pagesRoutes={pagesRoutes}
            defaultRoutes={defaultRoutes}
            previewSiteVersion={previewSiteVersion}
            sitePackageId={template?.site_package_id || undefined}
            isTemplatePage
          />
          <InsertPanelPortal isInsertPanelOpen={isInsertPanelOpen} />

          {!previewSiteVersion && (
            <NavSection>
              <SavePageIndicator />
            </NavSection>
          )}
        </>
      }
      rightSideChildren={<AttributesPanelPortal />}
      titleType="template_name"
    >
      <div ref={editorContainerRef} className="h-full bg-transparent w-full relative">
        <TemplatesEditor editorRect={editorRect} />
      </div>
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <TemplatesEditorProvider>
      <AttributesPanelNavbarFooterContextProvider>
        <TemplatesEditorPage />
      </AttributesPanelNavbarFooterContextProvider>
    </TemplatesEditorProvider>
  </WebsiteProvider>
);
