import { useNavigate } from 'react-router-dom';
import { RectangleStackIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

interface Props {
  canCreateSegment: boolean;
  handleOpen: () => void;
}

const NewSegmentAction = ({ canCreateSegment, handleOpen }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-end justify-center space-y-2">
      <div className="flex space-x-2 items-end">
        {canCreateSegment ? (
          <Button
            variant="primary"
            disabled={!canCreateSegment}
            onClick={() => navigate('/segments/new')}
            Icon={RectangleStackIcon}
          >
            Create Segment
          </Button>
        ) : (
          <Button variant="primary" onClick={handleOpen} Icon={RectangleStackIcon}>
            Create Segment
          </Button>
        )}
      </div>
    </div>
  );
};

export default NewSegmentAction;
