import { useCallback, useLayoutEffect, useRef } from 'react';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { Doc } from 'yjs';

import { useEditorContext } from '@/pages/Post/Edit/EditorContext';

import { API } from '../api';

export const useProvider = ({
  postId,
  publicationId,
  initialDocument,
}: {
  postId: string;
  publicationId: string;
  initialDocument?: Doc;
}) => {
  const { collaborationEnabled, provider, setProvider } = useEditorContext();

  const providerRef = useRef<TiptapCollabProvider>();

  const loadingProviderRef = useRef<boolean>(false);

  // Intitialize a provider for the managed collaboration server
  const tiptapCollabInit = useCallback(async () => {
    const response = await API.getTiptapCollabToken({ resourceId: postId, resourceType: 'post', publicationId });
    const { token, document_name: documentName, app_id: appId } = response.data;

    const newProvider = new TiptapCollabProvider({
      appId,
      token,
      document: initialDocument,
      name: documentName,
      onSynced: (data) => {
        if (data.state === true) {
          setProvider(newProvider);
          loadingProviderRef.current = false;
        }

        const configMap = newProvider.document.getMap<number>(`__tiptapcollab__config`);

        const autoVersioningEnabled = configMap.get('autoVersioning');

        const intervalSeconds = configMap.get('intervalSeconds');

        if (!autoVersioningEnabled) {
          configMap.set('autoVersioning', 1);
        }

        if (intervalSeconds !== 600) {
          configMap.set('intervalSeconds', 600);
        }
      },
    });

    providerRef.current = newProvider;
  }, [initialDocument, postId, publicationId, setProvider]);

  useLayoutEffect(() => {
    if (!providerRef.current && !loadingProviderRef.current && collaborationEnabled) {
      loadingProviderRef.current = true;
      tiptapCollabInit();
    }

    return () => {
      providerRef.current?.destroy();
    };
  }, [tiptapCollabInit, collaborationEnabled]);

  return { provider };
};

export default useProvider;
