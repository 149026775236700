import { IconComponent } from '@heroicons/react';
import { PlusIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Button } from '@/ui/Button';

import { getBorderRadius, getColor } from '../DesignTokens';
import { Typography, TypographyStack } from '../Typography';

type Props = {
  title?: string;
  description?: string;
  primaryIcon?: IconComponent;
  primaryIconTransparentBackground?: boolean;
  primaryIconSize?: 'sm' | 'md' | 'lg' | 'xl';
  primaryActionLabel?: string;
  onPrimaryActionClick?: () => void;
  isPrimaryActionBusy?: boolean;
  primaryActionIcon?: IconComponent | null;
  iconRight?: boolean;
  isFiltering?: boolean;
};

const ICON_SIZE_MAP = {
  sm: 'w-5 h-5',
  md: 'w-8 h-8',
  lg: 'w-12 h-12',
  xl: 'w-16 h-16',
};

export const EmptyCard = ({
  title,
  description,
  primaryIcon,
  primaryIconTransparentBackground = false,
  primaryIconSize = 'sm',
  primaryActionLabel,
  onPrimaryActionClick,
  isPrimaryActionBusy = false,
  primaryActionIcon = PlusIcon,
  iconRight = false,
  isFiltering = false,
}: Props) => {
  const PrimaryIconComponent = !isFiltering && primaryIcon;
  const buttonVariant = isFiltering ? 'primary-inverse' : 'secondary';

  return (
    <div
      className={cx(
        'flex flex-col items-center justify-center h-96 border',
        getBorderRadius('border/rounded/large'),
        getColor('surface/200', 'border'),
        getColor('white/900', 'background')
      )}
    >
      {PrimaryIconComponent && (
        <div
          className={cx(
            'w-12 h-12 flex items-center justify-center rounded-md',
            primaryIconTransparentBackground ? 'bg-transparent' : 'bg-surface-100'
          )}
        >
          <PrimaryIconComponent className={cx(ICON_SIZE_MAP[primaryIconSize], 'text-surface-500')} />
        </div>
      )}
      {title || description ? (
        <TypographyStack className="items-center px-4 sm:pt-4 sm:pb-6 sm:px-0">
          {title ? (
            <Typography colorWeight="900" weight="semibold" size="sm">
              {title}
            </Typography>
          ) : null}
          {description ? (
            <Typography colorWeight="900" weight="normal" size="sm">
              {description}
            </Typography>
          ) : null}
        </TypographyStack>
      ) : null}
      {onPrimaryActionClick && primaryActionLabel && (
        <Button
          type="button"
          variant={buttonVariant}
          onClick={onPrimaryActionClick}
          Icon={primaryActionIcon !== null ? primaryActionIcon : undefined}
          loading={isPrimaryActionBusy}
          iconRight={iconRight}
        >
          {primaryActionLabel}
        </Button>
      )}
    </div>
  );
};
