import { useParams } from 'react-router-dom';

import LoadingBox from '../../../../components/LoadingBox';
import { useAdvertiser, useAdvertiserUpdate } from '../../../../hooks/useAdNetwork/internal';
import { PageHeading } from '../../_components/PageHeading';
import { Form } from '../_components/Form';

export default function Edit() {
  const { advertiser_id: id } = useParams<'advertiser_id'>() as { advertiser_id: string };
  const { data, isLoading, isError } = useAdvertiser({ id });
  const onSubmitMutation = useAdvertiserUpdate({ id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{data?.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Edit</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="mx-auto max-w-lg pt-4">
        <h1 className="text-xl">Edit advertiser</h1>
      </div>
      <LoadingBox isLoading={isLoading} isError={isError} height="100vh">
        {data && <Form onSubmitMutation={onSubmitMutation} advertiser={data} />}
      </LoadingBox>
    </>
  );
}
