import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { AdNetworkAdvertiserPublisherLists } from '@/interfaces/ad_network/internal/advertiser_publisher_lists';
import api from '@/services/swarm';

export default function usePublisherLists({
  advertiserId,
  showRemoved = true,
}: {
  advertiserId: string;
  showRemoved: boolean;
}) {
  const fetchAdvertiserPublisherLists = () =>
    api
      .get(`/ad_network/internal/advertisers/${advertiserId}/publisher_lists`, {
        params: {
          show_removed: showRemoved,
        },
      })
      .then((res) => res.data);

  return useQuery<AdNetworkAdvertiserPublisherLists, AxiosError>(
    ['ad_network', 'internal', 'advertisers', advertiserId, 'publisher_lists'],
    fetchAdvertiserPublisherLists,
    {
      keepPreviousData: true,
    }
  );
}
