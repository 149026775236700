import { Extension } from '@tiptap/core';
import { Node as ProsemirrorNode } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

const mergeTagsRegex = /{{(.*?)}}/gi;

const createDecorations = (doc: ProsemirrorNode) => {
  const decorations: any[] = [];
  const results: any[] = [];

  doc.descendants((node: any, position: number) => {
    if (!node.isText) {
      return;
    }

    let result: any;

    // eslint-disable-next-line no-cond-assign
    while ((result = mergeTagsRegex.exec(node.text)) !== null) {
      results.push({
        from: position + result.index,
        to: position + result.index + result[0].length,
      });
    }
  });

  results.forEach((result) => {
    decorations.push(
      Decoration.inline(result.from, result.to, {
        class: 'merge-tag',
      })
    );
  });

  return DecorationSet.create(doc, decorations);
};

export const MergeTags = Extension.create({
  name: 'mergeTags',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('mergeTags'),
        state: {
          init(_, { doc }) {
            return createDecorations(doc);
          },
          apply(transaction, oldState) {
            return transaction.docChanged ? createDecorations(transaction.doc) : oldState;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

export default MergeTags;
