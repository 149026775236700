import { useState } from 'react';
import toast from 'react-hot-toast';

import { Input } from '@/components/Form';
import { useCurrentUser } from '@/context/current-user-context';
import useUpdateCurrentUser from '@/hooks/useUsers/useUpdateUser';
import User from '@/models/user';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';

const SocialLinksForm = () => {
  const [savingAttribute, setSavingAttribute] = useState<string | null>(null);

  const { currentUser } = useCurrentUser();
  const { mutateAsync: updateUser } = useUpdateCurrentUser(() => {
    toast.success('Profile updated!');
  });

  const handleChangeInAttribute = (attributeName: keyof User) => async (value: any) => {
    if (currentUser?.[attributeName] === value) {
      // Don't save if the value hasn't changed
      return;
    }

    try {
      setSavingAttribute(attributeName);
      updateUser({
        [attributeName]: value,
      });
    } catch (error: any) {
      // do nothing
    }

    setSavingAttribute(null);
  };

  return (
    <BodyContainer>
      <CardHeader
        title="Your Social Links"
        description="Add links to your related social media profiles to have them displayed on your author page"
      />
      <Input
        labelText="X (Twitter) Handle"
        name="twitter_handle"
        placeholder="johndoe"
        defaultValue={currentUser?.twitter_handle}
        onBlur={(e) => handleChangeInAttribute('twitter_handle')(e.target.value)}
        disabled={savingAttribute === 'twitter_handle'}
      />
      <Input
        labelText="LinkedIn"
        name="linkedin_url"
        placeholder="www.linkedin.com/in/johnsnewsletter"
        defaultValue={currentUser?.linkedin_url}
        onBlur={(e) => handleChangeInAttribute('linkedin_url')(e.target.value)}
        disabled={savingAttribute === 'linkedin_url'}
      />
      <Input
        labelText="Facebook"
        name="facebook_url"
        placeholder="www.facebook.com/johndoe"
        defaultValue={currentUser?.facebook_url}
        onBlur={(e) => handleChangeInAttribute('facebook_url')(e.target.value)}
        disabled={savingAttribute === 'facebook_url'}
      />
      <Input
        labelText="Instagram"
        name="instagram_url"
        placeholder="www.instagram.com/johndoe"
        defaultValue={currentUser?.instagram_url}
        onBlur={(e) => handleChangeInAttribute('instagram_url')(e.target.value)}
        disabled={savingAttribute === 'instagram_url'}
      />
      <Input
        labelText="TikTok"
        name="tiktok_url"
        placeholder="www.tiktok.com/@beehiiv"
        defaultValue={currentUser?.tiktok_url}
        onBlur={(e) => handleChangeInAttribute('tiktok_url')(e.target.value)}
        disabled={savingAttribute === 'tiktok_url'}
      />
      <Input
        labelText="Threads"
        name="threads_url"
        placeholder="www.threads.net/@beehiiv"
        defaultValue={currentUser?.threads_url}
        onBlur={(e) => handleChangeInAttribute('threads_url')(e.target.value)}
        disabled={savingAttribute === 'threads_url'}
      />
      <Input
        labelText="Discord"
        name="discord_url"
        placeholder="www.discord.gg/mynewsletter"
        defaultValue={currentUser?.discord_url}
        onBlur={(e) => handleChangeInAttribute('discord_url')(e.target.value)}
        disabled={savingAttribute === 'discord_url'}
      />
      <Input
        labelText="YouTube"
        name="youtube_url"
        placeholder="www.youtube.com/@mynewsletter"
        defaultValue={currentUser?.youtube_url}
        onBlur={(e) => handleChangeInAttribute('youtube_url')(e.target.value)}
        disabled={savingAttribute === 'youtube_url'}
      />
    </BodyContainer>
  );
};

export default SocialLinksForm;
