import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import { AdNetworkAdvertiserPublisherListItem } from '@/interfaces/ad_network/internal/advertiser_publisher_list_item';
import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from '@/services/swarm';

interface Variables {
  advertiserId: string;
  publisherListItemId: string;
  listType: string;
}

export default function usePublisherListItemDestroy() {
  const { currentUser } = useCurrentUser();

  const destroyAdvertiserPublisherListItem = ({
    advertiserId,
    publisherListItemId,
    listType,
  }: Variables): Promise<AdNetworkAdvertiserPublisherListItem> =>
    api
      .patch(`/ad_network/internal/advertisers/${advertiserId}/publisher_list_items/${publisherListItemId}`, {
        list_type: listType,
        publisher_list_item: {
          removed_by_id: currentUser?.id,
          removed_by_type: currentUser ? 'User' : null,
        },
      })
      .then((res) => res.data);

  return useMutation<AdNetworkAdvertiserPublisherListItem, MutationErrorResponse, Variables>(
    destroyAdvertiserPublisherListItem,
    {
      onError: (error) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      },
    }
  );
}
