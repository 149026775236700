import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { ArrayParam, createEnumParam, DateParam, StringParam, useQueryParam, withDefault } from 'use-query-params';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCreateDraft, usePosts, useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { DateFilterTypes } from '@/interfaces/dates';
import { PostBulkActions, PostPreview } from '@/interfaces/post';
import PostSearch from '@/models/postSearch';
import SaveAsTemplateModal from '@/pages/Post/Edit/v2/Compose/SaveAsTemplateModal';
import api from '@/services/swarm';
import pluralize from '@/utils/pluralize';

import GiftLinkModal from '../GiftLinkModal';

import DeleteModal from './DeleteModal';
import DeletePostModal from './DeletePostModal';
import Filter from './Filter';
import Header from './Header';
import NoPosts from './NoPosts';
import PostsContainer from './PostsContainer';

export const Posts: React.FunctionComponent = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const createDraftMutation = useCreateDraft();

  const [selectedPosts, setSelectedPosts] = useState<string[]>([]);
  const [selectedPost, setSelectedPost] = useState<PostPreview | null>(null);
  const [deletePostModalIsOpen, setDeletePostModalIsOpen] = useState<boolean>(false);
  const [giftLinkModalOpen, setGiftLinkModalOpen] = useState<boolean>(false);
  const [saveAsTemplateModalOpen, setSaveAsTemplateModalOpen] = useState<boolean>(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState<boolean>(false);
  const [openBulkModal, setOpenBulkModal] = useState<PostBulkActions>(PostBulkActions.NONE);
  const [confirmedDelete, setConfirmedDelete] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState('');

  // Query params
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [statusFilters, setStatusFilters] = useQueryParam('status', ArrayParam);
  const [platforms, setPlatforms] = useQueryParam('platform', ArrayParam);
  const [publishStartDate, setPublishStartDate] = useQueryParam('publishStartDate', DateParam);
  const [publishEndDate, setPublishEndDate] = useQueryParam('publishEndDate', DateParam);
  const [defaultAudiences, setDefaultAudiences] = useQueryParam('defaultAudiences', ArrayParam);
  const [includeSegments, setIncludeSegments] = useQueryParam('includeSegments', ArrayParam);
  const [excludeSegments, setExcludeSegments] = useQueryParam('excludeSegments', ArrayParam);
  const [authors, setAuthors] = useQueryParam('authors', ArrayParam);
  const [contentTags, setContentTags] = useQueryParam('contentTags', ArrayParam);
  const SortParam = withDefault(createEnumParam(['newest_first', 'oldest_first', 'most_relevant']), 'newest_first');
  const [sort, setSort] = useQueryParam('sort', SortParam);

  const queryParams = {
    search,
    statusFilters,
    platforms,
    publishStartDate,
    publishEndDate,
    defaultAudiences,
    includeSegments,
    excludeSegments,
    authors,
    contentTags,
    sort,
  };

  const [reRunSearch, setReRunsearch] = useState(false);

  const filteredAudience = useMemo(
    () => defaultAudiences?.filter((value): value is string => value !== null),
    [defaultAudiences]
  );

  const filteredContentTags = useMemo(
    () => contentTags?.filter((value): value is string => value !== null),
    [contentTags]
  );

  const filteredStatuses = useMemo(() => statusFilters?.filter(Boolean) as string[], [statusFilters]);
  const filteredPlatforms = useMemo(() => platforms?.filter(Boolean) as string[], [platforms]);
  const filteredStartDate = useMemo(() => publishStartDate || undefined, [publishStartDate]);
  const filteredEndDate = useMemo(() => publishEndDate || undefined, [publishEndDate]);
  const filteredAuthorIds = useMemo(() => authors?.filter((value): value is string => value !== null), [authors]);
  const filteredIncludeSegments = useMemo(
    () => includeSegments?.filter((value): value is string => value !== null),
    [includeSegments]
  );
  const filteredExcludeSegments = useMemo(
    () => excludeSegments?.filter((value): value is string => value !== null),
    [excludeSegments]
  );
  const dateFilterType = useMemo(() => {
    if (filteredStartDate && filteredEndDate) {
      return DateFilterTypes.BETWEEN;
    }
    if (filteredStartDate) {
      return DateFilterTypes.AFTER;
    }
    if (filteredEndDate) {
      return DateFilterTypes.BEFORE;
    }
    return DateFilterTypes.BEFORE;
  }, [filteredStartDate, filteredEndDate]);

  const orderFromSort = useMemo(() => {
    if (sort === 'newest_first') {
      return 'desc';
    }
    if (sort === 'oldest_first') {
      return 'asc';
    }
    return 'desc';
  }, [sort]);

  const query = new PostSearch({
    search: search || '',
    statuses: filteredStatuses || '',
    platform: filteredPlatforms || [],
    startDate: filteredStartDate,
    endDate: filteredEndDate,
    audience: filteredAudience,
    includeSegments: filteredIncludeSegments,
    excludeSegments: filteredExcludeSegments,
    contentTags: filteredContentTags,
    authorIds: filteredAuthorIds,
    order: orderFromSort,
    sort: sort as 'newest_first' | 'oldest_first' | 'most_relevant',
    dateField: 'scheduled_at',
    options: {
      advanced: true,
      datesEnabled: !!(filteredStartDate || filteredEndDate),
      dateFilterType,
    },
  });

  const postsQuery = usePosts(query, reRunSearch, 10, true);

  const { data, isFetching, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } = postsQuery; // isFetchingNextPage

  const posts = data?.pages.flatMap((page) => page.posts) || [];
  const pagination = data?.pages[0]?.pagination;
  const totalPostCount = pagination?.total || 0;
  const allPostIds = pagination?.ids || [];

  const [shouldResetSearch, setShouldResetSearch] = useState(false);

  const webTemplateMutation = useUpdateWebTemplate();
  const webTemplateRequest = useWebTemplate();
  const webTemplate = webTemplateRequest?.data;
  const featuredPosts = webTemplate?.feature_post_ids;
  const featuredPostIds = featuredPosts?.map((post: PostPreview) => post.id);

  // Reset search when no results
  const handleResetSearch = () => {
    setSearch(undefined);
    setShouldResetSearch(true);
  };

  const handleClearFilters = () => {
    setSort(undefined);
    setSearch(undefined);
    setStatusFilters(undefined);
    setPlatforms(undefined);
    setPublishStartDate(undefined);
    setPublishEndDate(undefined);
    setDefaultAudiences(undefined);
    setIncludeSegments(undefined);
    setExcludeSegments(undefined);
    setContentTags(undefined);
    setAuthors(undefined);
  };

  const handleCreateTemplateFromPost = (post: PostPreview) => {
    setSelectedPost(post);
    setSaveAsTemplateModalOpen(true);
  };

  const handleDeletePost = (post: PostPreview) => {
    setSelectedPost(post);
    setDeletePostModalIsOpen(true);
  };

  const handleGiftLinkForPost = (post: PostPreview) => {
    setSelectedPost(post);
    setGiftLinkModalOpen(true);
  };

  const handleBulkChange = (type: PostBulkActions) => {
    setOpenBulkModal(type);
  };

  const handleBulkDeleteModalClose = () => {
    setOpenBulkModal(PostBulkActions.NONE);
    setConfirmedDelete(false);
    setDeleteConfirmationValue('');
  };

  const handleBulkDeleteModalProceed = () => {
    const params = { data: { ids: selectedPosts } };

    api
      .delete(`/posts/bulk_delete?publication_id=${currentPublicationId}`, params)
      .then(() => {
        setSelectedPosts([]);
        setReRunsearch(true);
        setConfirmedDelete(false);
        setDeleteConfirmationValue('');
        toast.success('Posts deleted');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setOpenBulkModal(PostBulkActions.NONE));
  };

  const filterValues = {
    search,
    statusFilters: statusFilters?.filter(Boolean) as string[],
    platforms: platforms?.filter(Boolean) as string[],
    publishStartDate,
    publishEndDate,
    defaultAudiences: defaultAudiences?.filter(Boolean) as string[],
    includeSegments: includeSegments?.filter(Boolean) as string[],
    excludeSegments: excludeSegments?.filter(Boolean) as string[],
    contentTags: contentTags?.filter(Boolean) as string[],
    authors: authors?.filter(Boolean) as string[],
    sort,
  };

  const filterActions = {
    setSearch,
    setStatusFilters: setStatusFilters as Dispatch<SetStateAction<string[] | null | undefined>>,
    setPlatforms: setPlatforms as Dispatch<SetStateAction<string[] | null | undefined>>,
    setPublishStartDate,
    setPublishEndDate,
    setDefaultAudiences: setDefaultAudiences as Dispatch<SetStateAction<string[] | null | undefined>>,
    setIncludeSegments: setIncludeSegments as Dispatch<SetStateAction<string[] | null | undefined>>,
    setExcludeSegments: setExcludeSegments as Dispatch<SetStateAction<string[] | null | undefined>>,
    setContentTags: setContentTags as Dispatch<SetStateAction<string[] | null | undefined>>,
    setAuthors: setAuthors as Dispatch<SetStateAction<string[] | null | undefined>>,
    setSort: setSort as Dispatch<SetStateAction<'newest_first' | 'oldest_first' | 'most_relevant'>>,
  };

  const selectCheckOptions = {
    posts,
    selectedPosts,
    setSelectedPosts,
    totalPostCount,
  };

  useEffect(() => {
    if (isFetching) {
      setReRunsearch(false);
    }
  }, [isFetching]);

  const filtersApplied = Object.entries(filterValues).some(
    ([key, value]) => key !== 'sort' && value !== undefined && value !== null && value !== ''
  );

  return (
    <div className="flex flex-col gap-6">
      <DeletePostModal
        isOpen={deletePostModalIsOpen}
        setIsOpen={setDeletePostModalIsOpen}
        postId={selectedPost?.id || ''}
        postsQuery={postsQuery}
      />

      <DeleteModal
        disabled={!confirmedDelete}
        postIds={selectedPosts}
        isOpen={openBulkModal === PostBulkActions.DELETE}
        deleteConfirmationValue={deleteConfirmationValue}
        setDeleteConfirmationValue={setDeleteConfirmationValue}
        setConfirmedDelete={setConfirmedDelete}
        handleOnProceed={handleBulkDeleteModalProceed}
        handleOnClose={handleBulkDeleteModalClose}
        headerText={`Delete ${pluralize('Post', selectedPosts.length)}`}
      />

      <SaveAsTemplateModal
        isOpen={saveAsTemplateModalOpen}
        onClose={() => setSaveAsTemplateModalOpen(false)}
        postId={selectedPost?.id || ''}
      />

      <GiftLinkModal
        isOpen={giftLinkModalOpen}
        onClose={() => setGiftLinkModalOpen(false)}
        postId={selectedPost?.id || ''}
      />

      <Header createDraftMutation={createDraftMutation} />
      <Filter
        filterValues={filterValues}
        filterActions={filterActions}
        shouldResetSearch={shouldResetSearch}
        handleResetSearch={handleResetSearch}
        handleClearFilters={handleClearFilters}
      />
      {posts.length > 0 && !isLoading && (
        <PostsContainer
          selectCheckOptions={selectCheckOptions}
          featuredPostIds={featuredPostIds}
          selectedCheckbox={selectedCheckbox}
          setSelectedCheckbox={setSelectedCheckbox}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          refetch={refetch}
          handleCreateTemplateFromPost={handleCreateTemplateFromPost}
          handleDeletePost={handleDeletePost}
          handleGiftLinkForPost={handleGiftLinkForPost}
          webTemplateMutation={webTemplateMutation}
          handleDeleteClick={handleBulkChange}
          allPostIds={allPostIds}
          queryParams={queryParams}
        />
      )}
      {posts.length === 0 && !isLoading && (
        <NoPosts
          noResultsFound={filtersApplied}
          resetFilters={handleClearFilters}
          createDraftMutation={createDraftMutation}
        />
      )}
    </div>
  );
};
