import React, { useState } from 'react';
import toast from 'react-hot-toast';

import Banner from '@/components/Banner';
import { FileInput } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';
import { useFormContext } from '@/pages/ReferralProgram/Configure/FormContext';
import { Dropdown } from '@/ui/Dropdown';
import { Textarea } from '@/ui/Textarea';

type Mode = 'manual' | 'fileUpload';

const PromoCodesForm = () => {
  const { promoCodesFile, setPromoCodesFile, setPromoCodes } = useFormContext();
  const [mode, setMode] = useState<Mode>('manual');

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const rawList = e.target.value.trim();
    if (!rawList) {
      return;
    }

    try {
      const list = rawList.split(',').map((item) => ({
        id: '',
        code: item,
      }));
      if (list.length > 0) {
        setPromoCodes(list);
      }
    } catch (error: any) {
      // do nothing
      toast.error('There was a problem parsing your promo codes!');
    }
  };

  const handleChangeInMode = (_name: string, value: Mode) => {
    setMode(value);
    setPromoCodes([]);
  };

  return (
    <>
      <TypographyStack>
        <Typography token="font-medium/text/base">Promo Code</Typography>
        <Typography token="font-normal/text/sm" colorWeight="500">
          A unique code from your list will be assigned to each subscribers who achieves this reward
        </Typography>
      </TypographyStack>

      <Dropdown
        name="promo-code-entry-mode"
        labelText="Import method"
        value={mode}
        onSelect={handleChangeInMode}
        options={[
          { label: 'Copy and paste a list of promo codes', value: 'manual' },
          { label: 'Upload promo codes', value: 'fileUpload' },
        ]}
      />
      {mode === 'manual' ? (
        <Textarea
          labelText="Input a list of unique codes, separated by commas"
          placeholderText="CODE1, CODE2, CODE3"
          name="promo-code-list"
          onBlur={handleBlur}
        />
      ) : null}
      {mode === 'fileUpload' ? (
        <>
          <Banner
            variant="info"
            title='All unique codes should be held in the column named "promo_code" in the CSV file'
            bodyText=""
            isScreenWide={false}
          />
          <FileInput
            name="file"
            labelText="Upload Promo Codes CSV"
            helperText="The entire CSV will be scanned for promo codes. Please only include codes that can be assigned. (Max size 5MB)"
            onFileSelect={setPromoCodesFile}
            onFileRemoved={setPromoCodesFile}
            file={promoCodesFile}
            accept=".csv"
          />
        </>
      ) : null}
    </>
  );
};

export default PromoCodesForm;
