import { useUpdateWebTemplate } from '@/hooks';
import { WebTheme } from '@/interfaces/web_template';
import FontPicker from '@/ui/Settings/FontPicker';

import { Option } from '../../_types/options';
import { usePageContext } from '../Context/PageContext';
import ColorInput from '../Form/ColorInput';
import SelectInput from '../Form/SelectInput';
import SwitchInput from '../Form/SwitchInput';
import TextInput from '../Form/TextInput';
import PropertiesGrouping from '../Helpers/PropertiesGrouping';
import { SectionGroupingSlideOut, SubscribeWidgetIcon } from '../Helpers/SectionGroupingSlideOut';
import SwitchHelper from '../Helpers/SwitchHelper';

export const TEMPLATE_OPTIONS: Option[] = [
  { label: 'Template 1', value: 'template-1' },
  { label: 'Template 2', value: 'template-2' },
  { label: 'Template 3', value: 'template-3' },
];

interface Props {
  sectionGroupChildren?: React.ReactNode;
}

const SubscribeWidgetSection = ({ sectionGroupChildren }: Props) => {
  const { handleSaved, defaultColors, fontOptions, webTemplate } = usePageContext();

  const webTemplateMutation = useUpdateWebTemplate(() => handleSaved());
  const webTheme: WebTheme | undefined | any = webTemplate?.web_theme_preview;
  const webThemeWithDefaults: WebTheme | undefined = webTemplate?.web_theme_preview_with_defaults;

  const handleUpdateWebTheme = (key: string, value: string | boolean) => {
    webTemplateMutation.mutate({
      web_theme_preview: {
        ...(webTheme as WebTheme),
        [key]: value,
      },
    });
  };

  return (
    <SectionGroupingSlideOut
      title="Subscribe Widget"
      sectionIcon={<SubscribeWidgetIcon />}
      sectionGroupChildren={sectionGroupChildren}
      highlightProps={{
        sectionId: 'subscribe-widget',
      }}
    >
      <PropertiesGrouping label="General" indent>
        <SelectInput
          label="Template"
          value={webThemeWithDefaults?.subscribe_widget_template || ''}
          options={TEMPLATE_OPTIONS}
          onSave={(value: string) => {
            handleUpdateWebTheme('subscribe_widget_template', value);
          }}
        />
        <FontPicker
          label="Font Family"
          searchPlaceholder="Search Font"
          value={webThemeWithDefaults?.subscribe_widget_font_family || ''}
          noOptionsText="No Matching Fonts"
          options={fontOptions}
          onSave={(value: string) => handleUpdateWebTheme('subscribe_widget_font_family', value)}
        />
        <ColorInput
          label="Body Color"
          color={webThemeWithDefaults?.subscribe_widget_body_color}
          onSave={(value: string) => handleUpdateWebTheme('subscribe_widget_body_color', value)}
          defaultColors={defaultColors}
        />
        <SwitchHelper
          label="Border Enabled"
          name="subscribe-widget-border-enabled"
          value={webThemeWithDefaults?.subscribe_widget_border_enabled || false}
          onSave={(value: boolean) => {
            handleUpdateWebTheme('subscribe_widget_border_enabled', value);
          }}
        >
          <ColorInput
            label="Border Color"
            color={webThemeWithDefaults?.subscribe_widget_border_color}
            resettable={{
              label: 'Global Primary Color',
              resetValue: null,
              check: webTheme?.subscribe_widget_border_color !== null,
            }}
            onSave={(value: string) => handleUpdateWebTheme('subscribe_widget_border_color', value)}
            defaultColors={defaultColors}
          />
        </SwitchHelper>
        <SwitchInput
          label="Email Icon Enabled"
          name="subscribe-widget-email-icon-enabled"
          enabled={webThemeWithDefaults?.subscribe_widget_email_icon_enabled || false}
          onSave={(value: boolean) => handleUpdateWebTheme('subscribe_widget_email_icon_enabled', value)}
        />
        <SwitchInput
          label="Hide Subscribe Form When User Logged In"
          name="subscribe-form-hidden"
          enabled={webTemplate?.logged_subscribe_form || false}
          onSave={(val: boolean) => webTemplateMutation.mutateAsync({ logged_subscribe_form: val })}
        />
      </PropertiesGrouping>

      <PropertiesGrouping label="Button" indent>
        <TextInput
          label="Text"
          value={webThemeWithDefaults?.subscribe_widget_cta_text || ''}
          onSave={(value: string) => handleUpdateWebTheme('subscribe_widget_cta_text', value)}
        />
        <FontPicker
          label="Button Font Family"
          searchPlaceholder="Search Font"
          value={webThemeWithDefaults?.subscribe_widget_button_font_family || ''}
          noOptionsText="No Matching Fonts"
          options={fontOptions}
          onSave={(value: string) => handleUpdateWebTheme('subscribe_widget_button_font_family', value)}
        />
        <ColorInput
          label="Background Color"
          color={webThemeWithDefaults?.subscribe_widget_button_background_color}
          resettable={{
            label: 'Global Primary Color',
            resetValue: null,
            check: webTheme?.subscribe_widget_button_background_color !== null,
          }}
          onSave={(value: string) => handleUpdateWebTheme('subscribe_widget_button_background_color', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Text Color"
          color={webThemeWithDefaults?.subscribe_widget_button_text_color}
          resettable={{
            label: 'Global Text on Primary Color',
            resetValue: null,
            check: webTheme?.subscribe_widget_button_text_color !== null,
          }}
          onSave={(value: string) => handleUpdateWebTheme('subscribe_widget_button_text_color', value)}
          defaultColors={defaultColors}
        />
      </PropertiesGrouping>

      <PropertiesGrouping label="Placeholder" indent>
        <TextInput
          label="Text"
          value={webThemeWithDefaults?.subscribe_widget_placeholder_text || ''}
          onSave={(value: string) => handleUpdateWebTheme('subscribe_widget_placeholder_text', value)}
        />
      </PropertiesGrouping>
    </SectionGroupingSlideOut>
  );
};

export default SubscribeWidgetSection;
