import { FC } from 'react';

import { Checkbox } from '../../components/Form';
import ColorField from '../../components/Form/ColorField';

import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { ThemeFormProps } from './types';

const LinkForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette, fonts }) => {
  return (
    <FormContainer>
      <FormSection title="Font Settings">
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.hyperlink_font_family}
              onUpdate={(val) => onUpdate({ ...theme, hyperlink_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Background color">
          <ColorField
            placement="bottom"
            color={theme.hyperlink_background_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, hyperlink_background_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Border">
        <FormInput>
          <Slider
            name="hyperlink_border_radius"
            label="Curve radius"
            onChange={(val: number) => onUpdate({ ...theme, hyperlink_border_radius: val.toString() })}
            initialValue={theme.hyperlink_border_radius}
            min={0}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="hyperlink_border_width"
            label="Border thickness"
            onChange={(val: number) => onUpdate({ ...theme, hyperlink_border_width: val.toString() })}
            initialValue={theme.hyperlink_border_width}
            min={0}
            max={5}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Border color">
          <ColorField
            placement="bottom"
            color={theme.hyperlink_border_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, hyperlink_border_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Spacing">
        <FormInput>
          <Slider
            name="hyperlink_y_spacing"
            label="Vertical spacing"
            onChange={(val: number) => onUpdate({ ...theme, hyperlink_y_spacing: val.toString() })}
            initialValue={theme.hyperlink_y_spacing}
            min={0}
            max={80}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="hyperlink_x_spacing"
            label="Horizontal spacing"
            onChange={(val: number) => onUpdate({ ...theme, hyperlink_x_spacing: val.toString() })}
            initialValue={theme.hyperlink_x_spacing}
            min={0}
            max={80}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="hyperlink_padding"
            label="Inner spacing"
            onChange={(val: number) => onUpdate({ ...theme, hyperlink_padding: val.toString() })}
            initialValue={theme.hyperlink_padding}
            min={0}
            max={40}
            valueSuffix="px"
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Image Settings">
        <FormInput title="Alignment">
          <ThemeEditorDropdown
            currentValue={theme.hyperlink_image_placement}
            onUpdate={(val: string) => onUpdate({ ...theme, hyperlink_image_placement: val })}
            options={[
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
              { label: 'Hidden', value: 'hidden' },
            ]}
          />
        </FormInput>
      </FormSection>
      <Divider />
      {[
        { label: 'Title', value: 'title' },
        { label: 'Description', value: 'description' },
        { label: 'URL', value: 'link' },
      ].map((tag) => (
        <>
          <FormSection title={`Link ${tag.label}`}>
            <FormInput>
              <Checkbox
                name={`hyperlink_hide_${tag.value}`}
                onChange={(checked: boolean) =>
                  onUpdate({ ...theme, [`hyperlink_hide_${tag.value}`]: checked.toString() })
                }
                labelText={`Hide ${tag.label}?`}
                checked={(theme as any)[`hyperlink_hide_${tag.value}`] === 'true'}
                className="select-none"
              />
            </FormInput>
            <FormInput>
              <Slider
                name={`hyperlink_${tag.value}_size`}
                label="Font size"
                onChange={(val: number) => onUpdate({ ...theme, [`hyperlink_${tag.value}_size`]: val.toString() })}
                initialValue={(theme as any)[`hyperlink_${tag.value}_size`]}
                min={12}
                max={32}
                valueSuffix="px"
              />
            </FormInput>
            <FormInput title="Font color">
              <ColorField
                placement="bottom"
                color={(theme as any)[`hyperlink_${tag.value}_color`]}
                colorPalette={colorPalette}
                onColorChange={(color: any) =>
                  onUpdate({ ...theme, [`hyperlink_${tag.value}_color`]: color?.hexString })
                }
              />
            </FormInput>
          </FormSection>
          {tag.value !== 'link' && <Divider />}
        </>
      ))}
    </FormContainer>
  );
};

export default LinkForm;
