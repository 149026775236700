import styled, { css } from 'styled-components';

import { Styled as StyledIcon } from '@/components/TiptapEditor/components/ui/Icon/styled';
import { colors } from '@/components/TiptapEditor/lib/colors';
import { placeholder } from '@/components/TiptapEditor/lib/constants';

const customColorCSSVar = {
  '--background-image':
    'conic-gradient(from 180deg at 50% 50%, #FF4444 0deg, #FEEA32 75deg, #30E964 123.75deg, #2FF1F1 172.5deg, #4842FF 221.25deg, #C100FF 292.5deg, #E73FBC 322.5deg, #FF4444 360deg)',
};

const basicElementsStyles = css`
  p {
    line-height: 1.6;
    margin: 0.75rem auto;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > p,
  .node-section-inner > p {
    margin: 1.5rem auto;
  }

  & > .react-renderer,
  .node-section-inner > .react-renderer {
    margin: 3rem auto;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;

    &:first-child {
      margin-top: 0;
    }
  }

  h2,
  h3 {
    margin-top: 3rem;
  }

  h4,
  h5,
  h6 {
    margin-top: 2rem;
  }

  a.link {
    color: ${colors.blue[5]};
    font-weight: 800;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  ul,
  ol {
    padding: 0 1.8rem;
    margin: 1.5rem auto;

    ul,
    ol {
      margin-bottom: 0.25rem;
      margin-top: 0.25rem;
    }

    li {
      margin: 0.25rem 0;
    }

    p {
      margin-bottom: 0.25rem;
      margin-top: 0;
    }
  }

  & > ul,
  & > ol,
  .node-section-inner > ul,
  .node-section-inner > ol {
    margin: 2rem auto;
  }

  code {
    background: ${colors.black[8]};
    border-radius: 2px;
    box-shadow: 0px 0px 0px 1px ${colors.black[6]};
    font-family: 'JetBrainsMono', monospace;
  }

  pre {
    background: ${colors.black[1]};
    border-radius: 0.5rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
    padding: 1rem;

    code {
      background: none;
      color: ${colors.white[1]};
      font-size: 1rem;
      padding: 0;
    }
  }

  .node-htmlSnippet:not(.upgrade-intent) {
    [data-node-view-wrapper] {
      position: relative;

      & > div > :first-child {
        border-radius: 0.5rem;
        padding: 1rem;
      }

      .html-snippet-label {
        background: #32353a;
        border-radius: 0.5rem;
        color: ${colors.white[3]};
        font-size: 0.75rem;
        padding: 4px 8px;
        position: absolute;
        right: 1rem;
        top: 1rem;
      }

      &.is-preview {
        & > div > *:first-child {
          border: 1px solid ${colors.black[7]};
        }

        .html-snippet-label {
          background: #f2f2f3;
          color: rgba(48, 51, 58, 0.6);
        }
      }
    }
  }

  mark {
    // Use first theme color as default
    background: var(--color-1);
    border-radius: 0.25em;
    box-decoration-break: clone;
    color: inherit;
    padding: 0.125em 0;
  }

  & img {
    height: auto;
    max-width: 100%;
  }

  .tiptap-composer-highlight {
    background: ${colors.pink[9]};
  }

  .tiptap-thread:not(.tiptap-thread--resolved) {
    background: ${colors.comment}00;
    border-bottom: 2px dashed ${colors.comment};
    transition: all 0.085s ease-in-out;
  }

  [data-type='horizontalRule'] {
    margin: 2rem auto;
    padding-block: 1rem;
    transition: all 0.1s ease-in-out;
    cursor: pointer;

    &.ProseMirror-selectednode {
      background-color: ${colors.black[8]};

      hr {
        border-top-color: ${colors.pink[5]};
      }
    }

    &:hover:not(&.ProseMirror-selectednode) {
      background-color: ${colors.black[9]};
    }

    hr {
      border: none;
      border-top: 1px solid ${colors.black[6]};
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`;

const imageblockStyles = css`
  figure[data-type='imageBlock'] {
    margin: 0;

    & img {
      display: block;
      width: 100%;
    }

    & figcaption {
      color: ${colors.black[2]};
      font-size: 0.875rem;
      padding-top: 1rem;
    }

    & a figcaption {
      color: ${colors.blue[5]};
      text-decoration: underline;
    }

    & figcaption.is-empty:before {
      content: '${placeholder.caption}';
    }
  }

  .node-section figure[data-type='imageBlock'] {
    & figcaption {
      color: inherit;
    }
  }
`;

const blockquoteStyles = css`
  figure[data-type='blockquoteFigure'] {
    margin: 3.5rem auto;
    background: white;
    color: ${colors.black[1]};
  }

  & > blockquote,
  .node-section-inner > blockquote,
  [data-type='blockquoteFigure'] {
    blockquote {
      margin: 0;

      & > *:first-child {
        margin-top: 0;
      }

      & > *:last-child {
        margin-bottom: 0;
      }
    }

    figcaption {
      font-size: 0.875rem;
      padding-top: 1rem;
    }
  }

  /* Columns */
  [data-type='columns'] {
    display: flex;
    gap: 0.5rem;
    margin: 3.5rem auto 3rem;

    @media (max-width: 640px) {
      &[data-stack-on-mobile='true'] {
        flex-direction: column;

        & > [data-type='column'] {
          flex-basis: auto;
        }
      }
    }

    & > [data-type='column'] {
      box-sizing: border-box;
      flex-basis: 0;
      flex-grow: 1;
    }

    [data-has-width] {
      flex: none;
    }

    &.layout-sidebar-left > [data-type='column']:first-child,
    &.layout-sidebar-right > [data-type='column']:last-child {
      flex: none;
      width: 40%;
    }

    /* &.layout-custom {

    } */
  }

  [data-type='column'] {
    overflow: auto;
    padding: 0.5rem;
  }

  [data-type='blockquoteFigure'][data-variant='1'] {
    padding: 1rem;
    outline-style: solid;
  }

  [data-type='blockquoteFigure'][data-variant='2'] {
    border-left: 3px solid ${colors.black[1]};
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }

  [data-type='blockquoteFigure'][data-variant='3'] {
    text-align: center;

    blockquote {
      font-family: var(--font-family-1);

      &::before {
        content: '❝';
        font-family: var(--font-family-1);
        font-size: 1.625rem;
        font-weight: 400;
        line-height: 1;
        user-select: none;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }
`;

const tableStyles = css`
  .tableWrapper {
    margin: 3rem auto;
  }

  table {
    border-collapse: collapse;
    border-color: ${colors.black[7]};
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;

    // max-width: 100%;

    td,
    th {
      border: 1px solid ${colors.black[7]};
      min-width: 100px;
      padding: 0.5rem;
      position: relative;
      text-align: left;
      vertical-align: top;

      &:first-of-type:not(a) {
        margin-top: 0;
      }

      // Attention to handle elements, that is why first- and last-child selectors won't work
      p {
        margin: 0;

        & + p {
          margin-top: 0.75rem;
        }
      }
    }

    td {
      & > :first-child {
        margin-top: 0;
      }

      & > :last-child {
        margin-bottom: 0;
      }
    }

    th {
      background-color: ${colors.black[9]};
      font-weight: bold;
    }

    .column-resize-handle {
      bottom: -2px;
      display: flex;
      pointer-events: none;
      position: absolute;
      right: -4px;
      top: 0;
      width: 7px;

      &::before {
        background: ${colors.pink[6]};
        content: '';
        height: 100%;
        margin-left: 3px;
        width: 1px;
      }
    }

    .selectedCell {
      background: ${colors.pink[9]};
      border-color: ${colors.pink[5]};
      border-style: double;
    }

    .grip-column,
    .grip-row {
      align-items: center;
      background: ${colors.black[8]};
      cursor: pointer;
      display: flex;
      justify-content: center;
      position: absolute;
      z-index: 10;
    }

    .grip-column {
      border-left: 1px solid ${colors.black[6]};
      height: 0.75rem;
      left: 0;
      margin-left: -1px;
      top: -0.75rem;
      width: calc(100% + 1px);

      &:hover,
      &.selected {
        &::before {
          content: '';
          width: 10px;
        }
      }

      &:hover {
        background: ${colors.black[7]};

        &::before {
          border-bottom: 2px dotted ${colors.black[3]};
        }
      }

      &.first {
        border-color: transparent;
        border-top-left-radius: 4px;
      }

      &.last {
        border-top-right-radius: 4px;
      }

      &.selected {
        background: ${colors.pink[5]};
        border-color: ${colors.pink[5]};
        box-shadow: 1px 0px 0px 0px ${colors.pink[5]};

        &::before {
          border-bottom: 2px dotted white;
        }
      }
    }

    .grip-row {
      border-top: 1px solid ${colors.black[6]};
      height: calc(100% + 1px);
      left: -0.75rem;
      margin-top: -1px;
      top: 0;
      width: 0.75rem;

      &:hover,
      &.selected {
        &::before {
          content: '';
          height: 10px;
        }
      }

      &:hover {
        background: ${colors.black[7]};

        &::before {
          border-left: 2px dotted ${colors.black[3]};
        }
      }

      &.first {
        border-color: transparent;
        border-top-left-radius: 4px;
      }

      &.last {
        border-bottom-left-radius: 4px;
      }

      &.selected {
        background: ${colors.pink[5]};
        box-shadow: 0px 1px 0px 0px ${colors.pink[5]};

        &::before {
          border-left: 2px dotted white;
        }
      }
    }
  }
`;

const contentElementButton = css`
  background: ${colors.pink[5]};
  border-radius: 0.5rem;
  color: ${colors.white[1]};
  display: inline-block;
  padding: 0.625rem;
  text-decoration: none;
`;

const buttonStyles = css`
  .node-button {
    margin: 2rem auto 3rem;

    [data-node-view-wrapper] {
      display: flex;
      flex-direction: column;
    }

    *::selection {
      background-color: ${colors.white[5]};
    }

    a {
      caret-color: ${colors.white[1]};
      font-size: 0.875rem;
      font-weight: 600;
      min-width: 3rem;

      ${contentElementButton};

      &[data-size='small'] {
        font-size: 0.75rem;
        padding: 0.375rem 0.625rem;
      }

      // &[data-size='normal']

      &[data-size='large'] {
        font-size: 1rem;
        padding: 0.8rem;
      }

      &[data-alignment='left'] {
        align-self: flex-start;
      }

      &[data-alignment='center'] {
        align-self: center;
      }

      &[data-alignment='right'] {
        align-self: flex-end;
      }
    }
  }
`;

const embedStyles = css`
  .embedTextLinkContainer + .node-serviceEmbed,
  .embedTextLinkContainer + .node-genericEmbed {
    margin-top: 1.5rem;
  }

  .node-genericEmbed {
    [data-node-view-wrapper] {
      color: ${colors.black[1]};
      background: white;
      display: flex;
      gap: 2rem;
      text-decoration: none;

      img {
        align-self: flex-start;
        height: auto;
        margin: 0;
      }

      [data-node-view-content] {
        flex: 1;

        & > div {
          & > h3,
          & > p {
            color: inherit;
            margin: 0;
            text-decoration: none;
          }

          & > h3 {
            margin-bottom: 0.5rem;
          }

          & > p {
            font-weight: normal;

            &:last-of-type {
              font-size: 0.75rem;
              margin-top: 1rem;
              word-break: break-all;
            }
          }
        }
      }
    }

    [data-node-view-wrapper][data-variant='text-left-image-right'] {
      flex-direction: row-reverse;

      img {
        max-width: 10rem;
      }
    }

    [data-node-view-wrapper][data-variant='image-left-text-right'] {
      img {
        max-width: 10rem;
      }
    }

    [data-node-view-wrapper][data-variant='image-top-text-bottom'] {
      flex-direction: column;

      img {
        width: 100%;
      }
    }

    [data-node-view-wrapper][data-variant='text-only'] {
      & > :first-child {
        display: none;
      }
    }

    [data-node-view-content] {
      // Placeholder
      h3.is-empty::before {
        content: '${placeholder.genericEmbed.title}';
      }

      p.is-empty:first-of-type::before {
        content: '${placeholder.genericEmbed.description}';
      }

      p.is-empty:last-of-type::before {
        content: '${placeholder.genericEmbed.url}';
      }
    }
  }

  .node-serviceEmbed {
    display: flex;
    justify-content: center;

    &.has-focus [data-node-view-wrapper] > * {
      box-shadow: 0px 0px 0px 2px ${colors.pink[5]};
      transition: box-shadow 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
    }

    [data-node-view-wrapper] {
      & > * {
        pointer-events: none;
      }

      iframe {
        border: 0;
      }

      &[data-service='youtube'] {
        line-height: 0;
        width: 100%;

        iframe {
          aspect-ratio: 16/9;
          width: 100%;
        }
      }
    }
  }

  .node-embedInput {
    background: rgb(242, 242, 242);
    color: ${colors.black[1]};
    border-radius: 4px;
    padding: 1rem;

    // Placeholder
    &.is-empty [data-node-view-content] > :last-child::before {
      color: ${colors.black[5]};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    &.is-empty [data-node-view-wrapper] [data-node-view-content] > :last-child::before {
      content: '${placeholder.embedInput.generic}';
    }

    &.is-empty [data-node-view-wrapper][data-service='twitter'] [data-node-view-content] > :last-child::before {
      content: '${placeholder.embedInput.twitter}';
    }

    &.is-empty [data-node-view-wrapper][data-service='youtube'] [data-node-view-content] > :last-child::before {
      content: '${placeholder.embedInput.youtube}';
    }

    &.is-empty [data-node-view-wrapper][data-service='tiktok'] [data-node-view-content] > :last-child::before {
      content: '${placeholder.embedInput.tiktok}';
    }

    &.is-empty [data-node-view-wrapper][data-service='instagram'] [data-node-view-content] > :last-child::before {
      content: '${placeholder.embedInput.instagram}';
    }

    [data-node-view-content] {
      align-items: center;
      display: flex;
      justify-content: space-between;

      & > div:first-child {
        display: flex;
        order: 1;
        pointer-events: none;
      }

      & > div:last-child {
        word-break: break-all;
      }

      ${StyledIcon.Wrapper} {
        margin-left: 0.5rem;

        svg path {
          fill: ${colors.black[4]};
        }
      }
    }
  }
`;

export const Styled = {
  CollaborationUsers: styled.div`
    display: flex;
    gap: 0.25rem;
    margin-right: 1.25rem;
  `,
  Container: styled.div``,
};

export default {
  basicElementsStyles,
  blockquoteStyles,
  buttonStyles,
  embedStyles,
  customColorCSSVar,
  imageblockStyles,
  tableStyles,
};
