import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useExtendedOnboarding = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchExtendedOnboarding = () =>
    api.get(`/publications/${currentPublicationId}/extended_onboarding`).then((res) => res.data);

  return useQuery(['publication-extended-onboarding', currentPublicationId], fetchExtendedOnboarding, {
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(currentPublicationId),
  });
};

export default useExtendedOnboarding;
