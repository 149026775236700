import cx from 'classnames';

import { getColor } from '../../components/DesignTokens';
import { Typography } from '../../components/Typography';

export interface Props {
  level: number;
  label?: string;
  alignLabel?: 'left' | 'right' | 'center';
}

const COLORS_BY_LEVEL = [
  getColor(`tertiary/200`, 'background'),
  getColor(`tertiary/300`, 'background'),
  getColor(`tertiary/400`, 'background'),
  getColor(`tertiary/500`, 'background'),
  getColor(`tertiary/700`, 'background'),
];

const alignClassNameByValue = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
};

const LevelIndicator = ({ level, label, alignLabel = 'center' }: Props) => {
  return (
    <div className="w-full flex flex-col gap-y-2">
      {label && (
        <div className={cx('w-full', alignClassNameByValue[alignLabel])}>
          <Typography weight="medium" size="xs" colorWeight="700" className="capitalize">
            {label}
          </Typography>
        </div>
      )}
      <div className="flex gap-x-1">
        {[...Array(5)].map((_, index) => {
          const currentLevel = index + 1;
          return (
            <div
              className={cx(
                'h-2 rounded-lg w-1/5',
                currentLevel <= level ? COLORS_BY_LEVEL[index] : getColor('surface/200', 'background')
              )}
            >
              &nbsp;
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LevelIndicator;
