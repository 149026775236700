import { NavbarSerializableNode } from '@shared/dream-components/dist/navbarElement';

import { ColorPicker } from '@/routes/website/_components/UI/ColorPicker';
import { SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';

type Props = {
  selectedContent: NavbarSerializableNode;
  title: string;
  property: 'color' | 'background' | string;
  defaultValue: string;
};

export const ColorSettings = ({ selectedContent, title, property, defaultValue }: Props) => {
  const { onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;

  const contentValue =
    selectedContent?.attrs && property in selectedContent.attrs
      ? (selectedContent.attrs as any)[property]
      : defaultValue;

  const onSetColor = (value: string | null) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { [property]: value });
  };

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2 select-none">
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <PopoverTrigger asChild>
          <SimpleInputWrapper className="justify-start">
            <div className="w-4 h-4 rounded-md" style={{ backgroundColor: contentValue }} />
            <Text size="2xs" weight="medium" className="">
              {contentValue?.toUpperCase()}
            </Text>
          </SimpleInputWrapper>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <ColorPicker
            selectedColor={contentValue}
            onSetColor={(value: string | null) => {
              onSetColor(value);
            }}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
