import styled from 'styled-components';

export const Styled = {
  Headline: styled.h3`
    &&& {
      margin: 0 0 0.5rem;
    }
  `,
  Paragraph: styled.div`
    margin-bottom: 1.5rem;
  `,
  Button: styled.a`
    margin-bottom: 1.5rem;
    // … see editor styles
  `,
  Footnote: styled.div`
    font-size: 0.75rem;

    a {
      color: inherit;
      font-weight: inherit;
    }
  `,
};

export default Styled;
