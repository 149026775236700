import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '../../../services/swarm';

interface Props {
  onSuccess: (response: any) => void;
}

interface RequestParams {
  country?: string;
}

interface ApiResponse {
  url: string;
}

const useCreateStripeConnectAccount = (publicationId: string, { onSuccess }: Props) => {
  const queryClient = useQueryClient();

  const createStripeConnectAccount = ({ country }: RequestParams): Promise<ApiResponse> =>
    api
      .post('/boosts/monetize/stripe_connect_account', {
        publication_id: publicationId,
        country,
      })
      .then((res) => res.data);

  return useMutation(createStripeConnectAccount, {
    onSuccess: (result) => {
      onSuccess(result);
      queryClient.invalidateQueries([publicationId, 'boosts', 'monetize', 'stripe_connect_account'], {
        exact: true,
      });
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
  });
};

export default useCreateStripeConnectAccount;
