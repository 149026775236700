import { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { QRCodeSVG } from 'qrcode.react';

import { Button } from '@/ui/Button';

import { Input } from '../../../../../components/Form';
import useCreateSecondFactor from '../../../../../hooks/useUsers/useCreateSecondFactor';
import useSecondFactor from '../../../../../hooks/useUsers/useSecondFactor';
import useUpdateSecondFactor from '../../../../../hooks/useUsers/useUpdateSecondFactor';

const TwoFactorSection = () => {
  const secondFactorRequest = useSecondFactor();
  const { data: secondFactor } = secondFactorRequest || {};
  const secondFactorActive = secondFactor?.enabled;
  const secondFactorPending = secondFactor?.enabled === false;
  const createSecondFactorMutation = useCreateSecondFactor();
  const updateSecondFactorMutation = useUpdateSecondFactor();
  const [otp, setOtp] = useState<string>('');

  const handleSecondFactorCreate = async (e: React.FormEvent) => {
    e.preventDefault();
    createSecondFactorMutation.mutate();
  };

  const handleSecondFactorUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    updateSecondFactorMutation.mutate({
      otp,
      enabled: true,
    });
  };

  const activeState = () => {
    return (
      <div className="flex items-center">
        <CheckIcon className="h-4 w-4 text-green-500 mr-2" />
        <span>Two-Factor Authentication is enabled for your account</span>
      </div>
    );
  };
  const pendingState = () => {
    return (
      <div>
        <p className="mt-4 mb-4 text-gray-500">Use an authenticator app to scan the QR code</p>
        <QRCodeSVG value={secondFactor.provisioning_uri} />
        <form onSubmit={handleSecondFactorUpdate}>
          <div className="mt-4 flex flex-col space-y-4">
            <Input
              name="otp"
              labelText="Verify the code from the app"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <div className="mt-4 py-4 flex justify-end">
            <Button type="submit" loading={updateSecondFactorMutation.isLoading}>
              {updateSecondFactorMutation.isLoading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </form>
      </div>
    );
  };

  if (secondFactorActive) return activeState();
  if (secondFactorPending) return pendingState();
  return (
    <form onSubmit={handleSecondFactorCreate}>
      <div className="mt-4 py-4 flex justify-end">
        <Button type="submit" loading={createSecondFactorMutation.isLoading}>
          {createSecondFactorMutation.isLoading ? 'Adding...' : 'Add'}
        </Button>
      </div>
    </form>
  );
};

export default TwoFactorSection;
