/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */

import { ElementType, ForwardedRef, forwardRef, HTMLProps, ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

import { LoadingSpinner } from '../../../LoadingSpinner';
import { ButtonGroupContext } from '../ButtonGroup';

export type Variants = 'write';

export interface BaseButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
  block?: boolean;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  Icon?: ElementType;
  disableWith?: ReactNode;
  iconRight?: boolean;
  confirm?: string;
  size?: 'sm' | 'default' | 'none' | 'lg';
  tooltip?: string;
  align?: string;
  spacing?: string;
}

type ButtonPropsWithOnClick = BaseButtonProps & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  to?: never;
};

type ButtonPropsWithSubmit = BaseButtonProps & {
  onClick?: never;
  to?: never;
  type: 'submit' | 'button';
};

type ButtonPropsWithTo = BaseButtonProps & {
  onClick?: never;
  to: string;
  target?: '_blank';
};

export type ButtonProps = ButtonPropsWithOnClick | ButtonPropsWithTo | ButtonPropsWithSubmit;

const BASE_BUTTON_CLASS_NAMES = `text-sm duration-200 border font-medium focus:outline-none focus:ring-2 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap`;

// Base classNames for each button variant
const BUTTON_CLASS_NAMES = {
  write: 'bg-[#F5F3FF] hover:bg-[#DDD6FE] text-[#4C1D95] disabled:text-gray-600 disabled:bg-gray-100',
};

// Base classNames for each button variant outside of a button group
const SINGLE_BUTTON_CLASS_NAMES = {
  write: 'rounded-md shadow-sm border-transparent',
};

// Base classNames for each button variant inside of a button group
const GROUP_BUTTON_CLASS_NAMES = {
  write: 'border-[#DDD6FE] first:rounded-l-md last:rounded-r-md focus:z-10 first:ml-0 -ml-px',
};

const SPACING_CLASS_NAMES = {
  none: 'py-1', // A way to remove side padding, helpful for flush buttons
  sm: 'py-1 px-3',
  default: 'py-2 px-4',
  lg: 'p-4',
};

export const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const groupContext = useContext(ButtonGroupContext);
  const isInGroup = !!groupContext.variant;

  const variant = 'write';
  const { disabled: disabledFromProps } = props;

  const {
    disabled = groupContext.disabled || disabledFromProps || false,
    block = false,
    loading = false,
    children,
    className,
    type,
    Icon,
    disableWith,
    iconRight = false,
    confirm,
    onClick: onClickFromProps,
    size = (groupContext.size || 'default') as 'sm' | 'default',
    tooltip,
    align,
    spacing,
    ...rest
  } = props;

  const navigate = useNavigate();

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // eslint-disable-next-line no-alert
    if (confirm && !window.confirm(confirm)) {
      e.preventDefault();
      return;
    }

    if (onClickFromProps) {
      onClickFromProps(e);
    }

    if (props.to && props.target === '_blank') {
      window.open(props.to, '_blank');
      return;
    }

    if (props.to) {
      navigate(props.to);
    }
  };

  const constructedClassName = cx(
    BASE_BUTTON_CLASS_NAMES,
    BUTTON_CLASS_NAMES[variant],
    block && 'w-full flex',
    align === 'left' ? 'justify-start' : 'justify-center',
    isInGroup ? GROUP_BUTTON_CLASS_NAMES[variant] : SINGLE_BUTTON_CLASS_NAMES[variant],
    spacing || SPACING_CLASS_NAMES[size],
    className
  );

  const smallOrNone = size === 'sm' || size === 'none';
  const iconSizeClassName = cx(smallOrNone ? 'h-4 w-4' : 'h-5 w-5');
  const iconMarginClassName = children
    ? cx(
        smallOrNone && iconRight && 'ml-1',
        smallOrNone && !iconRight && 'mr-1',
        size === 'default' && iconRight && 'ml-2',
        size === 'default' && !iconRight && 'mr-2'
      )
    : '';
  const iconClassName = cx(iconSizeClassName, iconMarginClassName);

  const isDisabled = loading || disabled;

  return (
    <button
      data-tip={tooltip}
      ref={ref}
      type={type}
      className={constructedClassName}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      {loading && <LoadingSpinner className="mr-2" color="gray" />}
      {!iconRight && Icon && !loading && <Icon className={iconClassName} />}
      {isDisabled && disableWith ? disableWith : children || <span>&#8203;</span>}
      {iconRight && Icon && !loading && <Icon className={iconClassName} />}
      {tooltip && <ReactTooltip delayShow={500} place="bottom" />}
      {tooltip && !children && <span className="sr-only">{tooltip}</span>}
    </button>
  );
});
