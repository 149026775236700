import { useEffect, useState } from 'react';
import { MagnifyingGlass } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { PageVersion } from '@/interfaces/dream_builder/page_version';
import { SiteVersion } from '@/interfaces/dream_builder/site_version';

import { Text } from '../../_components/UI/Text';

import SettingsInput from './SettingsInput';
import SettingsItem from './SettingsItem';


const HelperText = ({ value, recommended }: { value: string, recommended: number }) => (
  <span>
    Recommended: {recommended} characters. You&apos;ve used <span className="text-wb-primary">{value.length}</span>.
  </span>
);

export function MetadataSettings({
  data,
  onUpdate,
  isAccordionItem = false,
  isPage = false
}: {
  data: SiteVersion | PageVersion | undefined,
  onUpdate: (key: string, value: string) => void,
  isAccordionItem?: boolean,
  isPage?: boolean
}) {
  const currentPublication = useCurrentPublication();

  const title = data?.metadata?.meta_title;
  const description = data?.metadata?.meta_description;

  const [metaTitle, setMetaTitle] = useState<string>(title || '');
  const [metaDescription, setMetaDescription] = useState<string>(description || '');

  useEffect(() => {
    setMetaTitle(title || '');
    setMetaDescription(description || '');
  }, [title, description]);

  return (
    <SettingsItem title="Metadata" description={`Extra content for search engines to crawl and index your ${isPage ? "page" : "website"}.`} isAccordionItem={isAccordionItem}>
      <div className="flex flex-col lg:flex-row gap-6 w-full pt-4">
        <div className="flex flex-1 flex-col gap-6">
          <SettingsInput
            name="meta_title"
            labelText="Meta Title"
            type="input"
            placeholder={currentPublication?.data?.name}
            value={metaTitle}
            onSave={async (value: string) => {
              if (value === title) return;
              onUpdate('meta_title', value);
            }}
            onChangeMutateValue={(val: string) => {
              setMetaTitle(val);
              return val;
            }}
            helperText={
              <HelperText value={metaTitle} recommended={70} />
            }
          />

          <SettingsInput
            name="meta_description"
            labelText="Meta Description"
            type="textarea"
            placeholder={`A website for ${currentPublication?.data?.name}`}
            value={metaDescription}
            onSave={async (value: string) => {
              if (value === description) return;
              onUpdate('meta_description', value);
            }}
            onChangeMutateValue={(val: string) => {
              setMetaDescription(val);
              return val;
            }}
            helperText={
              <HelperText value={metaDescription} recommended={156} />
            }
          />

        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="is-size-7 with-text-color mb-2">Search Engine Result Preview</div>
          <div className="bg-wb-primary rounded-xl px-5 py-4 shadow-md">
            <div className="flex items-center gap-3 w-full mb-4">
              {/* TODO: Replace with uploaded google.png */}
              <img
                className="w-[60px] h-[35px]"
                src="https://build.typedream.com/images/google.png"
                alt="Google Logo"
              />
              <div className="border border-wb-tertiary rounded-full p-1.5 flex-1">
                <MagnifyingGlass size={15} className="text-wb-tertiary" />
              </div>
            </div>

            <Text size="xl" weight="medium" className="text-[#1a0dab]" as="p">
              {metaTitle || currentPublication?.data?.name}
            </Text>
            <Text size="sm" weight="medium" variant="secondary" as="p">
              {metaDescription || `A website for ${currentPublication?.data?.name}`}
            </Text>
          </div>
        </div>
      </div>
    </SettingsItem>
  );
}
