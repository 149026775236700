import { useMemo } from 'react';

import useOptions from '@/hooks/useOptions';
import { usePublication } from '@/hooks/usePublications';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import { LogicalOperators } from '@/interfaces/condition';
import { Option } from '@/interfaces/general';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  publicationId: string;
  mainConditions: AutomationConditions;
  onChange: (mainConditions: AutomationConditions) => void;
}

export const EmailSubmissionTrigger = ({ publicationId, mainConditions, onChange }: Props) => {
  const publication = usePublication(publicationId)?.data;
  const externalEmbeds = useOptions(publicationId, 'external_embeds');
  const { data: externalEmbedsData } = externalEmbeds;
  const condition = mainConditions.conditions?.[0];

  const nameOptions = useMemo(() => {
    const options: Option[] = [
      { label: 'Any Subscribe Form', value: 'any' },
      { label: `Website (${publication?.url})`, value: 'website' },
    ];

    if (externalEmbedsData?.options) {
      options.push({
        label: 'Subscribe Forms',
        value: '',
        isOptGroup: true,
      });

      externalEmbedsData.options.forEach((option: { id: string; name: string }) => {
        options.push({
          label: option.name,
          value: option.id,
        });
      });
    }

    return options;
  }, [publication, externalEmbedsData]);

  const handleChange = (params: any) => {
    onChange({ logical_operator: LogicalOperators.AND, conditions: [{ ...condition, ...params }] });
  };

  return (
    <Dropdown
      name="name"
      value={condition?.value || ''}
      onSelect={(_name, selectedValue) => {
        handleChange({ value: selectedValue });
      }}
      options={nameOptions}
    />
  );
};
