import toast from 'react-hot-toast';

import { useWebsiteContext } from '@/context/website-context';
import { usePublication, useUpdatePublication } from '@/hooks/usePublications';
import { Publication } from '@/interfaces/publication';

import SettingsInput from '../_components/SettingsInput';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';

const socials = [
  {
    name: 'facebook_url',
    title: 'Facebook',
    placeholder: 'www.facebook.com/trybeehiiv',
    divider: false,
  },
  {
    name: 'twitter_url',
    title: 'X (Twitter)',
    placeholder: 'www.twitter.com/beehiiv',
    divider: false,
  },
  {
    name: 'instagram_url',
    title: 'Instagram',
    placeholder: 'www.instagram.com/beehiiv',
    divider: false,
  },
  {
    name: 'linkedin_url',
    title: 'LinkedIn',
    placeholder: 'www.linkedin.com/channel/beehiiv',
    divider: false,
  },
  {
    name: 'tiktok_url',
    title: 'TikTok',
    placeholder: 'www.tiktok.com/@beehiiv',
    divider: false,
  },
  {
    name: 'youtube_url',
    title: 'YouTube',
    placeholder: 'www.youtube.com/@beehiiv',
    divider: false,
  },
  {
    name: 'threads_url',
    title: 'Threads',
    placeholder: 'www.threads.net/@beehiiv',
    divider: false,
  },
  {
    name: 'discord_url',
    title: 'Discord',
    placeholder: 'www.discord.gg/beehiiv',
    divider: false,
  },
  {
    name: 'bluesky_url',
    title: 'Bluesky',
    placeholder: 'bsky.app/profile/beehiiv.com',
    divider: false,
  },
];

const WebsiteSettingsSocials = () => {
  const { site } = useWebsiteContext();
  const { data: publication } = usePublication(site?.publication_id || '', !!site?.publication_id);
  const { mutate: updatePublication } = useUpdatePublication(site?.publication_id || '');

  return (
    <div>
      {site &&
        publication &&
        socials.map((item) => {
          const handleSave = (value: string) => {
            updatePublication({
              [item.name]: value,
            });
            toast.success('Saved!');
          };

          const initialValue = (publication[item.name as keyof Publication] as string) || '';

          return (
            <SettingsSection key={item.name} hasDivider={item.divider}>
              <SettingsItem title={item.title}>
                <SettingsInput
                  className="max-w-xs"
                  name={item.name}
                  placeholder={item.placeholder}
                  value={initialValue || ''}
                  onSave={(value: string) => {
                    if (value === initialValue) return;
                    handleSave(value);
                  }}
                />
              </SettingsItem>
            </SettingsSection>
          );
        })}
    </div>
  );
};

export default WebsiteSettingsSocials;
