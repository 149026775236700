const StaticSegmentIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.80707 12.3626C9.49315 12.3626 9.17603 12.1421 9.17603 11.8144C9.19448 11.6976 9.23876 11.5865 9.30563 11.489C9.3725 11.3915 9.46029 11.3102 9.56259 11.2509C12.1885 9.1837 12.0013 9.3085 12.0781 9.32066L14.1533 9.6125C14.18 9.61625 14.2048 9.62832 14.2242 9.64699C14.2436 9.66566 14.2567 9.68999 14.2615 9.7165C14.3027 9.94434 13.976 9.80258 10.5085 12.0515C10.3078 12.2179 10.0651 12.3255 9.80707 12.3626ZM12.0963 9.58146L9.70723 11.4611C9.55267 11.5549 9.45379 11.6915 9.43011 11.8355C9.43171 12.0035 9.64387 12.1082 9.79011 12.1082C9.99904 12.0734 10.1947 11.9828 10.3565 11.8461C11.5559 11.0672 12.6615 10.4016 13.7338 9.81218L12.0963 9.58146Z"
      fill="black"
    />
    <path
      d="M18.3067 11.2115L15.8101 10.0768C15.7889 10.0672 15.7655 10.0636 15.7424 10.0662C15.7193 10.0689 15.6973 10.0777 15.6789 10.0918C14.1228 11.2921 12.609 12.5463 11.1403 13.8522C11.0034 13.9549 10.8421 14.1398 10.8005 14.3482C10.7173 14.7229 11.1038 15.0877 11.4766 15.0877C11.8041 15.086 12.122 14.9779 12.3826 14.7795L18.3166 11.4394C18.3373 11.4278 18.3543 11.4107 18.3658 11.39C18.3773 11.3693 18.3828 11.3459 18.3818 11.3222C18.3807 11.2986 18.3732 11.2757 18.3599 11.2561C18.3467 11.2365 18.3283 11.2213 18.3067 11.2115Z"
      fill="black"
    />
    <path
      d="M20.8144 14.0326L19.4848 12.5085C19.4641 12.4846 19.4353 12.4694 19.404 12.4656C19.3726 12.4618 19.341 12.4698 19.3152 12.488C17.6049 13.6818 15.9208 14.9126 14.264 16.1795C14.0861 16.2864 13.9328 16.4672 13.8477 16.6858C13.7667 17.0899 14.1443 17.4221 14.5677 17.4221C14.9357 17.3853 15.2451 17.271 15.5011 17.0787C17.0746 16.08 18.7987 15.1494 20.7722 14.233C20.7902 14.2246 20.806 14.2122 20.8184 14.1966C20.8308 14.1811 20.8394 14.1629 20.8435 14.1434C20.8477 14.124 20.8472 14.1039 20.8422 14.0846C20.8372 14.0654 20.8275 14.0476 20.8144 14.0326Z"
      fill="black"
    />
    <path
      d="M22.0102 18.6275C21.5651 16.7126 21.5994 16.7657 21.5219 16.7264C21.5052 16.718 21.4869 16.7133 21.4682 16.7127C21.4495 16.7121 21.431 16.7156 21.4138 16.7228C19.7443 17.4332 18.2778 18.1136 16.9485 18.7945C16.7654 18.8553 16.5901 18.9718 16.4022 19.1603C16.2464 19.3552 16.2042 19.5936 16.2963 19.8595C16.4938 20.175 16.8832 20.152 17.2413 20.1113C18.7603 19.8016 20.3373 19.3526 21.9286 18.7769C21.9581 18.7662 21.9826 18.7451 21.9975 18.7176C22.0124 18.69 22.0173 18.658 22.0102 18.6275Z"
      fill="black"
    />
    <path
      d="M9.55209 11.4935C9.19369 11.4381 9.19465 11.4711 6.48201 12.2096C6.41193 12.2288 6.54249 12.0467 4.81865 14.7613C4.80632 14.7806 4.79941 14.8029 4.79865 14.8259C4.7979 14.8488 4.80331 14.8715 4.81433 14.8916C4.82535 14.9117 4.84158 14.9285 4.86131 14.9402C4.88104 14.9519 4.90355 14.9581 4.92649 14.9581C4.97673 14.9581 9.29225 12.6883 9.51369 12.5808C9.76425 12.4589 10.0481 12.3207 10.0465 11.9879C10.0049 11.7383 9.80201 11.5319 9.55209 11.4935Z"
      fill="black"
    />
    <path
      d="M4.01818 20.7949C3.99412 20.7949 3.97056 20.7881 3.95018 20.7753C3.9298 20.7625 3.91345 20.7442 3.90298 20.7226C3.85914 20.6317 2.84346 18.4822 4.01754 17.0826C4.04794 17.0464 4.02362 17.0656 10.5167 14.1942C10.7967 14.0381 11.134 13.9565 11.5091 13.9565C11.9215 13.9971 12.2262 14.3581 12.1878 14.7456C12.1046 15.0048 11.9142 15.225 11.6959 15.3338C4.03418 20.8131 4.08314 20.7949 4.01818 20.7949ZM4.19482 17.2698C3.3161 18.3498 3.87546 19.9846 4.07258 20.4707L11.5641 15.1152C11.7391 15.0259 11.8828 14.8602 11.9385 14.6925C11.9574 14.4707 11.7574 14.2381 11.4959 14.2118C11.1779 14.2125 10.8825 14.2835 10.6303 14.4234L4.19482 17.2698Z"
      fill="black"
    />
    <path
      d="M8.72005 24.5888C8.65893 24.5888 8.70853 24.6054 5.79461 22.6208C5.77781 22.6093 5.76399 22.594 5.75428 22.5762C5.74458 22.5583 5.73927 22.5384 5.7388 22.518C5.73833 22.4977 5.74272 22.4775 5.75159 22.4592C5.76046 22.4409 5.77356 22.425 5.78981 22.4128C13.8864 16.3312 13.6516 16.4502 13.9482 16.3907C14.322 16.3123 14.7773 16.753 14.6832 17.3155C14.5815 17.6915 14.3549 18.0381 14.026 18.3306C12.2301 20.1888 10.5258 22.2211 8.82341 24.5366C8.81147 24.5528 8.7959 24.566 8.77795 24.575C8.75999 24.5841 8.74016 24.5888 8.72005 24.5888ZM6.08645 22.5101L8.69029 24.2854C10.3754 22.0019 12.0637 19.9926 13.849 18.1459C14.1479 17.8797 14.3476 17.576 14.4336 17.2608C14.4804 16.9734 14.289 16.6234 13.9914 16.6432C13.7706 16.6912 13.9402 16.6118 6.08645 22.5101Z"
      fill="black"
    />
    <path
      d="M14.2977 25.9834L11.1316 25.4627C11.1093 25.459 11.0884 25.4496 11.071 25.4352C11.0536 25.4209 11.0403 25.4022 11.0324 25.381C11.0246 25.3599 11.0224 25.337 11.0263 25.3148C11.0301 25.2925 11.0397 25.2717 11.0541 25.2544L15.594 19.8067C15.7764 19.551 16.0276 19.3504 16.2961 19.2499C16.6769 19.1069 17.098 19.3715 17.1473 19.6365C17.1876 19.8394 17.1108 20.0298 17.0388 20.1728L14.4142 25.9081C14.404 25.9305 14.3876 25.9494 14.3669 25.9627C14.3463 25.976 14.3222 25.9834 14.2977 25.9834ZM11.394 25.2464L14.2222 25.7129C16.9441 19.7651 16.9297 19.8902 16.898 19.697C16.8212 19.5123 16.5774 19.4176 16.3857 19.4896C16.1646 19.5725 15.9553 19.7405 15.7962 19.9629L11.394 25.2464Z"
      fill="black"
    />
    <path
      d="M23.5427 32.2541C23.2099 32.2541 22.8992 32.0323 22.8297 31.6848C22.8086 31.5837 22.9139 31.209 23.2297 30.9453C23.7069 30.4672 26.8848 27.192 27.3718 26.705C27.3953 26.6815 27.427 26.6681 27.4602 26.6676C27.4934 26.667 27.5255 26.6794 27.5497 26.7021C29.207 28.2499 29.12 28.1453 29.1139 28.2311C29.1126 28.25 29.1072 28.2684 29.0979 28.2849C29.0887 28.3014 29.0759 28.3157 29.0605 28.3267C24.4137 31.6179 24.3216 32.2541 23.5427 32.2541ZM23.0857 31.6557C23.1443 31.8896 23.3869 32.0394 23.6438 31.9882C24.3721 31.8426 24.1696 31.4906 28.7849 28.209L27.4659 26.9741C26.9741 27.4682 23.8899 30.6474 23.4025 31.1344C23.231 31.2781 23.119 31.4644 23.0857 31.6557Z"
      fill="black"
    />
    <path
      d="M23.7933 31.7904C23.7133 31.4697 23.3955 31.2662 23.0371 31.3053C22.776 31.3379 22.5014 31.4333 22.2451 31.5795C21.4099 32.0403 17.7427 34.3145 16.5718 34.9737C16.5554 34.983 16.5413 34.9957 16.5303 35.011C16.5194 35.0264 16.512 35.0439 16.5086 35.0624C16.5053 35.0809 16.5061 35.1 16.5109 35.1182C16.5157 35.1364 16.5245 35.1533 16.5366 35.1677C18.1222 37.0531 18.0051 36.936 18.0806 36.9424C18.0974 36.9438 18.1143 36.9419 18.1303 36.9368C18.1463 36.9317 18.1612 36.9235 18.1741 36.9126C19.8791 35.4832 21.5872 34.0576 23.2985 32.6358C23.5619 32.432 23.8903 32.1786 23.7933 31.7904Z"
      fill="black"
    />
    <path
      d="M21.1011 29.4352C20.8467 29.4352 20.5984 29.2445 20.5286 28.9668C20.5043 28.8682 20.6182 28.5143 20.8646 28.3168C21.2419 27.9386 23.8038 25.2797 24.1942 24.889C24.218 24.8652 24.2502 24.8517 24.2839 24.8514C24.3175 24.8512 24.3499 24.8642 24.374 24.8877C25.6652 26.1434 25.6185 26.0717 25.6121 26.1565C25.6107 26.1752 25.6052 26.1934 25.596 26.2097C25.5867 26.226 25.574 26.2401 25.5587 26.2509C24.5366 26.9773 23.2006 27.9463 21.9347 29.001C21.6236 29.313 21.2828 29.4352 21.1011 29.4352ZM20.7843 28.9293C20.8393 29.0976 21.0134 29.2131 21.1692 29.1693C21.7084 29.0343 21.5312 28.8055 25.2854 26.1319L24.2864 25.1597C23.8918 25.5575 21.4249 28.1178 21.0355 28.5075C20.9052 28.6148 20.8164 28.7637 20.7843 28.9293Z"
      fill="black"
    />
    <path
      d="M20.9543 28.5808C20.443 28.512 20.1047 28.799 16.0187 30.6707C15.9922 30.6829 15.9706 30.7037 15.9575 30.7298C15.9444 30.7558 15.9406 30.7856 15.9467 30.8141C16.3755 32.8029 16.3326 32.7146 16.4219 32.7507C16.4412 32.7586 16.4621 32.7616 16.4829 32.7595C16.5037 32.7574 16.5236 32.7502 16.5409 32.7386C17.1419 32.3379 20.9863 29.7629 21.0529 29.7216C21.2782 29.583 21.5655 29.4096 21.5255 29.0794C21.4907 28.7923 21.2455 28.5853 20.9543 28.5808Z"
      fill="black"
    />
    <path
      d="M32.9636 35.3059C32.2657 33.6662 32.3165 33.734 32.2337 33.7094C32.2156 33.7041 32.1965 33.7029 32.1779 33.7057C32.1592 33.7086 32.1415 33.7155 32.1258 33.726C28.3604 36.2697 28.2221 35.9968 27.8753 36.5174C27.8618 36.5374 27.8544 36.5608 27.8538 36.5849C27.7185 36.8294 27.7853 37.182 28.1034 37.3584C28.2094 37.3944 28.3219 37.4069 28.4332 37.3949C28.5444 37.3829 28.6517 37.3467 28.7476 37.2889C29.2173 37.0784 32.4276 35.6838 32.8983 35.4729C32.9289 35.4592 32.9529 35.4341 32.9651 35.403C32.9774 35.3718 32.9766 35.3368 32.9636 35.3059Z"
      fill="black"
    />
    <path
      d="M26.2699 40.7264C26.2373 40.7264 26.2686 40.7283 24.6622 40.3769C24.6404 40.3722 24.6203 40.3618 24.6038 40.3469C24.5872 40.332 24.5749 40.313 24.5679 40.2918C24.561 40.2706 24.5597 40.248 24.5641 40.2262C24.5686 40.2044 24.5787 40.1841 24.5934 40.1673C25.5521 39.079 26.4702 38.0349 27.4987 36.9232C27.724 36.6595 27.9339 36.3177 28.3013 36.3987C28.5233 36.448 28.7374 36.7107 28.692 37.0285C28.6529 37.3014 28.5723 37.5065 28.4382 37.6739C28.132 38.1958 26.8574 39.9459 26.3765 40.6697C26.3648 40.6873 26.3489 40.7017 26.3303 40.7116C26.3117 40.7215 26.291 40.7264 26.2699 40.7264ZM24.9291 40.1734L26.212 40.4547C26.7886 39.5961 27.8817 38.1142 28.228 37.5286C28.3425 37.3833 28.4062 37.2176 28.4385 36.992C28.4606 36.8381 28.3787 36.6973 28.2392 36.6473C28.0853 36.6173 27.9601 36.7587 27.7861 36.9757C27.4785 37.3584 27.7556 36.9641 24.9291 40.1734Z"
      fill="black"
    />
    <path
      d="M31.2352 30.9424C30.096 29.1456 30.1588 29.2086 30.0727 29.1978C29.8791 29.1741 29.7975 29.584 25.9037 32.848C25.6234 33.023 25.4103 33.271 25.2708 33.5856C25.1165 33.9328 25.2874 34.3347 25.6599 34.5002C25.7504 34.5408 26.2068 34.4906 26.4336 34.2611C27.5018 33.5168 30.1335 31.8595 31.2004 31.1155C31.2274 31.0967 31.2462 31.0682 31.2527 31.0358C31.2592 31.0035 31.253 30.9702 31.2352 30.9424Z"
      fill="black"
    />
    <path
      d="M22.1447 39.9526C22.0858 39.9526 22.2305 40.0413 20.2865 38.6374C20.2714 38.6266 20.2589 38.6126 20.2498 38.5965C20.2407 38.5803 20.2353 38.5624 20.2338 38.5439C20.2323 38.5254 20.2349 38.5068 20.2414 38.4894C20.2478 38.472 20.258 38.4563 20.2711 38.4432C20.2711 38.4432 24.8148 33.9043 24.8778 33.8384C25.1089 33.5974 25.3706 33.3242 25.7233 33.4522C25.9876 33.5523 26.2186 33.8893 26.1281 34.2522C26.0052 34.7427 26.0564 34.679 24.0177 37.4518C23.2919 38.439 22.5418 39.4595 22.2513 39.8954C22.2396 39.9129 22.2237 39.9273 22.2052 39.9372C22.1866 39.9471 22.1658 39.9526 22.1447 39.9526ZM20.5582 38.5184L22.1127 39.6438C22.4458 39.1581 23.1393 38.215 23.8113 37.3005C25.8174 34.5718 25.7681 34.6355 25.8794 34.1904C25.9294 33.9904 25.8314 33.7952 25.6289 33.6909C25.4494 33.6301 25.2942 33.7741 25.0625 34.0157L20.5582 38.5184Z"
      fill="black"
    />
    <path
      d="M40.7713 43.9386C40.4846 43.9386 40.2523 43.744 40.2152 43.633C40.1425 43.4157 40.1688 43.1555 40.2869 42.919C40.5099 42.3766 42.0014 38.6477 42.2283 38.096C42.241 38.0656 42.265 38.0412 42.2951 38.0278C42.3253 38.0145 42.3595 38.0133 42.3905 38.0243L44.1742 38.673C44.1923 38.6796 44.2087 38.6902 44.2221 38.704C44.2355 38.7178 44.2457 38.7345 44.2517 38.7527C44.2578 38.771 44.2597 38.7904 44.2573 38.8095C44.2548 38.8286 44.2481 38.8469 44.2376 38.863C41.7288 42.7158 41.8037 43.3642 41.1566 43.8262C41.0357 43.9021 40.9016 43.9386 40.7713 43.9386ZM40.452 43.5335C40.5809 43.6967 40.8238 43.7325 41.0142 43.6138C41.5499 43.2304 41.5774 42.5018 43.9352 38.8589L42.4184 38.3072C42.1893 38.8672 40.7467 42.4736 40.5195 43.0253C40.4312 43.2022 40.4081 43.3837 40.452 43.5335Z"
      fill="black"
    />
    <path
      d="M41.0267 43.4566C40.8923 43.2554 40.5553 43.1373 40.2142 43.2835C39.851 43.4656 39.7525 43.5674 37.684 46.3901C36.4785 48.0355 36.4837 47.9773 36.5067 48.0662C36.5294 48.152 36.4395 48.0918 38.3307 48.9251C38.3603 48.9382 38.3938 48.9395 38.4244 48.9289C38.4549 48.9183 38.4804 48.8965 38.4955 48.8678C39.299 47.3562 40.8491 44.3894 40.8891 44.3216C41.0459 44.0544 41.2232 43.7517 41.0267 43.4566Z"
      fill="black"
    />
    <path
      d="M37.3566 42.1543C37.325 41.9632 37.3128 41.7575 37.4379 41.5485C37.7189 40.9127 38.2936 39.4323 38.677 38.488C38.6853 38.4674 38.6989 38.4493 38.7163 38.4356C38.7338 38.4218 38.7545 38.4129 38.7765 38.4096L40.5118 38.1479C40.5363 38.1442 40.5613 38.1476 40.5838 38.1579C40.6063 38.1681 40.6254 38.1846 40.6387 38.2054C40.652 38.2262 40.659 38.2504 40.6589 38.2751C40.6588 38.2998 40.6515 38.324 40.6379 38.3447C38.5525 41.5184 38.6814 42.0071 38.1374 42.3693C37.7986 42.5952 37.3864 42.3345 37.3566 42.1543ZM37.6046 42.0832C37.6997 42.2045 37.8686 42.241 37.9954 42.1565C38.4261 41.8698 38.413 41.3027 40.2677 38.4439L38.8869 38.6522C38.7538 38.9808 37.8606 41.2253 37.6648 41.6666C37.5928 41.7885 37.5774 41.9002 37.6046 42.0832Z"
      fill="black"
    />
    <path
      d="M38.2457 41.9789C38.2417 41.9709 38.2369 41.9634 38.2313 41.9565C38.0662 41.7501 37.8134 41.6723 37.5648 41.7552C37.1961 41.903 36.9577 42.2019 35.4102 43.8953C34.7337 44.6355 34.0342 45.4013 33.7606 45.6749C33.7465 45.6889 33.736 45.7061 33.7297 45.725C33.7234 45.7439 33.7216 45.764 33.7245 45.7837C33.7373 45.8723 33.6813 45.8163 35.2166 46.656C35.2444 46.6711 35.2769 46.6754 35.3077 46.668C35.3385 46.6606 35.3654 46.6421 35.3833 46.616C36.1571 45.488 38.0061 42.7417 38.039 42.7001C38.1942 42.5053 38.3875 42.2624 38.2457 41.9789Z"
      fill="black"
    />
    <path
      d="M46.4001 46.5414C46.3006 46.5414 46.1444 46.4701 46.0478 46.3379C45.8119 46.016 46.0174 45.6416 46.2676 45.3286C48.3946 42.5811 48.3786 42.2205 48.5585 42.2006C48.642 42.1913 48.586 42.1622 49.667 43.2086C49.6908 43.2317 49.7048 43.2632 49.7058 43.2964C49.7069 43.3296 49.695 43.3619 49.6727 43.3865C49.0558 44.0688 47.5345 45.5897 46.9143 46.2425C46.7774 46.4269 46.5095 46.5414 46.4001 46.5414ZM48.5943 42.527C46.9284 45.0464 46.2692 45.5779 46.2017 45.9805C46.1809 46.1056 46.2618 46.2518 46.3972 46.2829C46.5243 46.252 46.6375 46.1796 46.7188 46.0771C46.9633 45.8185 49.0023 43.7369 49.3988 43.3059L48.5943 42.527Z"
      fill="black"
    />
    <path
      d="M46.7856 46.0682C46.7562 45.8918 46.5427 45.6781 46.2544 45.6704C45.9066 45.7139 45.8608 46.1034 45.7606 46.3706C45.3747 47.4563 44.3338 50.4246 44.3338 50.4246C44.3263 50.4462 44.3247 50.4693 44.3291 50.4916C44.3335 50.514 44.3439 50.5347 44.359 50.5517C44.3741 50.5688 44.3935 50.5814 44.4152 50.5885C44.4369 50.5955 44.46 50.5966 44.4822 50.5917L45.9414 50.2672C45.967 50.2616 45.9902 50.2482 46.0079 50.229C46.0257 50.2098 46.0371 50.1856 46.0406 50.1597C46.1363 49.4586 46.6893 47.3715 46.785 46.6656C46.8205 46.4893 46.8205 46.2771 46.7856 46.0682Z"
      fill="black"
    />
    <path
      d="M46.8 40.2467C45.2307 39.095 45.3164 39.1331 45.231 39.1568C45.2124 39.1619 45.1952 39.1712 45.1807 39.1838C45.1662 39.1965 45.1547 39.2123 45.1472 39.2301C44.4854 40.7974 43.7289 42.2851 43.0812 43.5248C42.8937 43.825 42.8025 44.1021 42.8035 44.3869C42.8451 44.7174 43.1363 45.0646 43.47 45.016C43.6921 44.9837 43.8332 44.7974 43.9587 44.6371C43.9587 44.6371 46.5424 40.8538 46.831 40.4211C46.8493 40.3937 46.8563 40.3602 46.8505 40.3277C46.8447 40.2952 46.8266 40.2662 46.8 40.2467Z"
      fill="black"
    />
    <path
      d="M42.2819 50.6598C42.2464 50.6598 42.3526 50.6845 40.4966 50.1689C40.4792 50.1641 40.4631 50.1557 40.4492 50.1442C40.4353 50.1327 40.424 50.1184 40.416 50.1022C40.4081 50.0861 40.4036 50.0684 40.4029 50.0504C40.4022 50.0324 40.4054 50.0144 40.4121 49.9977C40.8368 48.9475 42.5331 44.8294 42.5552 44.7731C42.6646 44.4947 42.8009 44.1481 43.1446 44.1139C43.4572 44.1545 43.6892 44.3561 43.7667 44.6275C43.8816 45.0406 43.6592 45.7395 43.1651 47.5728C42.8524 48.7334 42.4979 50.0492 42.4076 50.5542C42.4024 50.5838 42.3869 50.6106 42.3638 50.6299C42.3407 50.6492 42.3119 50.6598 42.2819 50.6598ZM40.7033 49.9606L42.1827 50.3715C42.3024 49.7913 42.615 48.6313 42.918 47.5062C43.5536 45.1472 43.5728 45.0825 43.5184 44.6899C43.4923 44.6058 43.4427 44.5308 43.3756 44.4738C43.3084 44.4168 43.2264 44.3801 43.1392 44.368C42.9296 44.3942 43.014 44.3293 40.7033 49.9606Z"
      fill="black"
    />
    <path
      d="M7.35845 1.9568C7.42341 2.34592 7.42597 2.73472 7.48805 3.09184C7.90629 5.49312 7.46981 8.328 6.09381 10.8742C4.70725 13.44 2.42181 15.8362 2.72133 18.9485C3.00549 21.9002 5.31653 24.1664 7.94213 25.2394C14.1959 27.7952 21.9767 23.7514 27.4954 27.639C29.665 29.1674 31.114 31.4947 31.5162 34.1245C32.0404 37.551 30.7306 40.4637 32.3917 44.3066C33.5441 46.9722 35.961 48.9706 38.5853 50.0461C41.7335 51.3363 45.2973 51.2762 48.5728 50.5325C50.2913 50.1424 51.9453 49.4954 53.6637 49.1382C55.793 48.6954 57.4333 48.6957 60.4733 50.1434C60.7002 50.273 60.9921 50.2083 61.1866 50.0138C61.3489 49.7869 61.3489 49.495 61.1543 49.3005C60.5057 48.7491 59.7655 48.281 58.9492 47.9709C53.0938 45.7466 49.2186 50.6899 41.6333 49.3651C37.5265 48.648 33.7562 45.6592 33.17 41.3558C32.7114 37.9894 33.8554 35.1357 32.3917 31.401C31.3898 28.8442 29.3009 26.5536 26.5873 25.4346C21.2666 23.24 16.5095 25.2822 11.217 24.559C8.81349 24.2307 6.46245 23.1667 5.14629 21.1635C4.32037 19.9066 3.72933 18.5459 4.56965 16.5498C5.02373 15.2528 5.90437 14.0237 6.64485 12.8531C8.34277 10.1693 9.33637 7.04864 8.75269 3.8384C8.56005 3.13152 8.41925 2.44896 7.84485 1.73056C7.77989 1.60096 7.58533 1.56832 7.45573 1.63328C7.35845 1.73056 7.32613 1.85952 7.35845 1.9568Z"
      fill="black"
    />
    <path
      d="M3.07816 4.87518C3.20776 5.19934 3.37 5.49118 3.53224 5.78302C4.018 6.51166 5.14024 7.41566 5.96424 7.85822C10.5415 10.3184 15.0305 9.38302 18.5457 12.0736C21.9486 14.6781 21.6222 19.6544 19.4862 23.2931C17.9684 25.8784 15.3818 28.3216 15.2058 31.6915C15.1297 33.1501 15.5332 34.608 16.2759 35.8745C17.8714 38.5945 20.7495 40.3574 23.7988 40.9008C29.9543 41.9974 35.3556 39.1677 40.2391 38.8902C44.4471 38.6512 48.5082 41.24 50.1617 46.4781C50.6836 48.1309 51.0458 49.8771 52.01 51.3421C53.2266 53.1904 55.1447 54.6496 57.555 55.2009C58.3678 55.3869 58.6894 54.1654 57.9118 53.9686C56.5166 53.6157 55.2522 52.8992 54.2151 51.8934C51.7534 49.5053 52.0938 46.3949 50.2916 43.2678C49.5444 41.9715 48.5716 40.8051 47.4055 39.863C44.4718 37.4921 41.4017 37.184 37.7748 37.8201C33.9031 38.4995 31.7006 39.6246 26.9767 39.7981C25.258 39.8611 23.5015 39.6176 21.8858 38.9875C18.7985 37.7837 16.0113 34.4278 16.6327 31.1728C17.3617 27.3539 20.5204 25.5037 21.8858 21.1529C22.7396 18.4323 22.6718 15.1485 20.978 12.7545C19.138 10.1545 15.9834 9.2899 13.001 8.73374C8.94216 7.97662 6.1124 7.71134 3.5972 4.61566C3.40264 4.45342 2.91592 4.5507 3.07816 4.87518Z"
      fill="black"
    />
    <path
      d="M12.5466 49.8509C12.4682 50.1648 12.1958 50.3709 11.8656 50.305C11.467 50.2679 11.0967 50.0829 10.8278 49.7863C10.6832 49.6416 10.719 49.4141 10.7955 49.2999C11.671 47.4839 12.5466 45.6032 13.6166 43.8845C13.7219 43.6762 13.8781 43.4977 14.0707 43.3658C14.1676 43.2997 14.2834 43.2671 14.4005 43.2729C14.5176 43.2788 14.6296 43.3227 14.7194 43.3981C15.0026 43.6813 14.5559 44.8272 12.5466 49.8509Z"
      fill="black"
    />
    <path
      d="M53.2745 33.1837C53.1359 33.216 52.9917 33.216 52.8531 33.1837C52.7235 33.1187 52.6236 32.9904 52.5612 32.8595C52.3926 32.5059 52.3667 32.0813 52.4963 31.6921C52.6195 31.4457 52.6854 31.4326 52.8854 31.4326C54.8959 31.2704 56.9705 31.1731 58.9817 31.2057C59.5244 31.2144 60.086 31.3926 59.9545 31.919C59.7257 32.3763 56.2303 32.7328 53.2745 33.1837Z"
      fill="black"
    />
    <path
      d="M49.7397 18.4944C48.2796 17.2349 46.9593 15.633 45.6217 14.279C45.525 14.1811 45.4239 14.0528 45.3948 13.9222C45.326 13.616 45.6613 12.9686 46.3999 12.7226C46.4649 12.6902 46.5621 12.6902 46.6268 12.6902C46.7241 12.7226 46.789 12.7875 46.8537 12.8525C48.1506 14.409 49.8044 16.0304 50.9394 17.4246C51.1017 17.5869 51.1989 17.8138 51.2313 18.0406C51.2636 18.2675 50.8098 18.7866 50.5826 18.8838C50.3554 18.9811 50.0888 18.7955 49.7397 18.4944Z"
      fill="black"
    />
    <path
      d="M39.4281 26.9251C40.8723 25.6432 42.541 24.4608 44.0652 23.2934C44.1625 23.1962 44.2921 23.1312 44.4543 23.0989C44.7983 23.0989 45.381 23.5674 45.4921 24.2339C45.5522 24.3542 45.5116 24.5485 45.2975 24.6554C43.6114 25.7578 41.7907 27.2419 40.3039 28.2224C40.1087 28.351 39.8825 28.4493 39.6553 28.4493C39.4281 28.4493 38.942 27.9629 38.8771 27.736C38.8121 27.5091 39.0675 27.2451 39.4281 26.9251Z"
      fill="black"
    />
    <path
      d="M21.9894 52.8275C21.6694 52.5075 21.4211 52.0323 21.6832 51.6583C21.9827 51.2087 24.824 49.8867 26.008 49.2515C26.2941 49.0269 26.7088 48.9351 27.129 49.0115C27.1408 49.0138 27.1524 49.0177 27.1632 49.0231C27.7626 49.3226 27.9667 50.1472 27.0982 50.5815C25.6883 51.4074 24.1782 52.1168 22.5747 52.9082C22.4582 53.0083 22.2259 53.064 21.9894 52.8275ZM26.1469 49.4656C25.0221 50.0743 22.1475 51.4231 21.8944 51.8029C21.6867 52.0995 22.1194 52.8477 22.4442 52.6874C24.0493 51.8951 25.5616 51.1847 26.9763 50.3565C27.2781 50.2048 27.4976 49.985 27.4042 49.6592C27.3437 49.4474 27.1894 49.3261 27.0646 49.2605C26.7408 49.2061 26.4019 49.2675 26.1469 49.4656Z"
      fill="black"
    />
    <path
      d="M36.561 14.8339C36.0647 14.3456 34.9943 11.655 34.4154 10.3757C34.2074 10.0672 34.1405 9.64 34.2464 9.25152C34.2906 9.11808 34.3709 9.03808 34.4416 8.96768C34.7533 8.65632 35.2551 8.69216 35.5629 9.00032C36.0541 9.70976 37.3543 12.823 37.6986 13.6144C37.9107 14.1014 37.9351 14.0784 37.9351 14.3114C37.9351 14.4246 37.8432 14.5661 37.7347 14.6205C37.4023 14.8858 36.9008 15.0986 36.561 14.8339ZM34.6224 9.1488C34.5642 9.20672 34.5143 9.25664 34.4912 9.32576C34.4495 9.48182 34.441 9.64491 34.4663 9.80446C34.4917 9.964 34.5503 10.1164 34.6384 10.2518C35.1351 11.3395 36.2954 14.207 36.7299 14.6422C36.9351 14.8006 37.3152 14.6253 37.5984 14.4048C37.6423 14.3802 37.6762 14.3302 37.6787 14.3091C37.6787 14.1491 37.6787 14.2109 37.4637 13.7162C37.1171 12.92 35.8474 9.88384 35.3658 9.1616C35.1744 8.97792 34.8314 8.94016 34.6224 9.1488Z"
      fill="black"
    />
    <path
      d="M16.2637 61.9763C15.8707 61.9763 15.4832 61.8208 15.2125 61.5504C15.1258 61.4247 15.088 61.3488 15.0519 61.241C14.8919 60.6567 15.4311 60.104 16.0797 60.2656C17.5866 60.3597 19.192 60.5453 20.7021 60.7491C21.3463 60.8362 21.2698 60.8147 21.4234 60.8909C21.4871 60.9229 21.5888 61.0538 21.592 61.2218C21.6871 61.8061 21.5347 62.2307 21.1837 62.3584C20.6538 62.5354 17.7351 62.1043 16.2637 61.9763ZM15.2967 61.1664C15.3232 61.2458 15.3514 61.3015 15.4096 61.3888C15.617 61.5927 15.9386 61.721 16.2759 61.721C17.4055 61.8183 20.6762 62.2579 21.0995 62.1165C21.3645 62.0202 21.3968 61.6074 21.3379 61.2538C21.3315 61.2131 21.3466 61.1731 21.2899 61.1104C21.0768 61.0058 17.4147 60.6051 16.041 60.5175C15.5165 60.3952 15.1994 60.809 15.2967 61.1664Z"
      fill="black"
    />
  </svg>
);

export default StaticSegmentIcon;
