import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';

import useSubscriber, { SubscriberApiResponse } from '../_hooks/useSubscriber';

const SubscriberContext = React.createContext<SubscriberApiResponse | undefined>(undefined);

SubscriberContext.displayName = 'SubscriberContext';

const SubscriberProvider = ({ children }: { children: React.ReactNode }) => {
  const { subscriberId } = useParams();
  const { data, isLoading, isError } = useSubscriber({ id: subscriberId });
  const navigate = useNavigate();

  return (
    <LoadingBox
      isLoading={isLoading}
      isError={!subscriberId || isError}
      errorText="No subscriber found!"
      errorButtonText="Back to subscribers"
      errorButtonOnClick={() => navigate('/subscribers')}
    >
      <SubscriberContext.Provider value={data}>{children}</SubscriberContext.Provider>
    </LoadingBox>
  );
};

const useSubscriberContext = () => {
  const context = React.useContext(SubscriberContext);

  if (context === undefined) {
    throw new Error('useSubscriberContext must be used within a SubscriberProvider');
  }

  return context;
};

export { SubscriberContext, SubscriberProvider, useSubscriberContext };
