import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface ApiResponse {
  id: string;
}

interface Props {
  formQuestionId: string;
  onSuccess?: () => void;
}

const useReorderQuestionOptions = ({ formQuestionId, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, { optionsOrder: any }>(
    ({ optionsOrder }) =>
      api
        .patch(`/reorder_question_options/${formQuestionId}`, {
          publication_id: currentPublicationId,
          form_question_id: formQuestionId,
          question_options: optionsOrder,
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useReorderQuestionOptions;
