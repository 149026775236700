import { useInfiniteQuery } from 'react-query';

import { BoostInvite } from '@/interfaces/boosts/monetize/boost_invite';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  boost_invites: BoostInvite[];
  pagination: Pagination;
}

interface Props {
  isEnabled?: boolean;
  boostInviteId?: string;
  perPage?: number;
}

const useBoostInvites = ({ perPage = 10, isEnabled = true }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostInvites = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/boosts/monetize/boost_invites`, {
        params: {
          page: pageParam,
          per_page: perPage,
          publication_id: currentPublicationId,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery([currentPublicationId, 'boosts', 'monetize', 'boost_invites'], fetchBoostInvites, {
    enabled: isEnabled,
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
};

export default useBoostInvites;
