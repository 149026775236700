import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

import API from '@/components/TiptapEditor/lib/api';
import { Asset } from '@/interfaces/asset';

import { Button } from '../../../ui/Button';
import { BrightnessControl } from '../views/ColorEditor/Brightness';
import { ContrastControl } from '../views/ColorEditor/ContrastControl';
import { HueControl } from '../views/ColorEditor/HueControl';
import { SaturationControl } from '../views/ColorEditor/SaturationControl';

export type ColorEditModalContentProps = {
  fileName: string;
  assetId: string;
  publicationId: string;
  onClose: () => void;
  onChange: (asset: Asset) => void;
  onCreate: (asset: Asset) => void;
};

const useFormState = ({
  assetId,
  publicationId,
  onClose,
  onChange,
  onCreate,
}: {
  assetId: string;
  publicationId: string;
  onClose: () => void;
  onChange: (asset: Asset) => void;
  onCreate: (asset: Asset) => void;
}) => {
  const [asset, setAsset] = useState<Asset | null>(null);
  const [assetFetched, setAssetFetched] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [brightness, setBrightness] = useState(0);
  const [contrast, setContrast] = useState(0);
  const [hue, setHue] = useState(0);
  const [saturation, setSaturation] = useState(0);

  useEffect(() => {
    setAssetFetched(false);

    API.getAsset({ assetId, publicationId }).then((newAsset) => {
      if (!newAsset.data) {
        return;
      }

      setAsset(newAsset.data);

      setAssetFetched(true);
    });
  }, [assetId, publicationId]);

  const handleSave = useCallback(
    async (file: File) => {
      setIsSaving(true);

      try {
        const response = await API.uploadPublicationAsset({
          publicationId,
          file,
        });
        onCreate(response.data);
        toast.success('Asset saved as new copy.');
      } catch {
        toast.error('Something went wrong while saving the asset.');
      } finally {
        setIsSaving(false);
        onClose();
      }
    },
    [publicationId, onCreate, onClose]
  );

  const handleOverwrite = useCallback(
    async (file: File) => {
      setIsSaving(true);

      try {
        const response = await API.updateAsset(assetId, publicationId, {
          file,
        });
        onChange(response.data);
        toast.success('Updated asset.');
      } catch {
        toast.error('Something went wrong while updating the asset.');
      } finally {
        setIsSaving(false);
        onClose();
      }
    },
    [publicationId, assetId, onChange, onClose]
  );

  return {
    asset,
    handleSave,
    handleOverwrite,
    isSaving,
    assetFetched,
    brightness,
    contrast,
    hue,
    saturation,
    setBrightness,
    setContrast,
    setHue,
    setSaturation,
  };
};

export const ColorEditModalContent = ({
  fileName,
  onClose,
  assetId,
  publicationId,
  onChange,
  onCreate,
}: ColorEditModalContentProps) => {
  const form = useFormState({ assetId, publicationId, onChange, onClose, onCreate });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const img = useRef(new Image());
  const imgLoaded = useRef(false);

  useEffect(() => {
    const updateImage = (ctx: CanvasRenderingContext2D) => {
      ctx.filter = `brightness(${100 + form.brightness}%) contrast(${100 + form.contrast}%) hue-rotate(${
        form.hue
      }deg) saturate(${100 + form.saturation}%)`;
      ctx.drawImage(img.current, 0, 0);
    };

    if (canvasRef.current) {
      if (form.asset?.url && !imgLoaded.current) {
        const ctx = canvasRef.current?.getContext('2d');

        img.current.onload = () => {
          canvasRef.current?.setAttribute('width', img.current.naturalWidth.toString());
          canvasRef.current?.setAttribute('height', img.current.naturalHeight.toString());

          // apply filters
          if (ctx) {
            updateImage(ctx);
          }

          imgLoaded.current = true;
        };

        let imageUrl = form.asset.url;
        try {
          const url = new URL(form.asset.url);
          url.searchParams.set('x-refetch', 'true');
          imageUrl = url.href;
        } catch {
          imageUrl = `${form.asset.url}?x-refetch=true`;
        }

        img.current.crossOrigin = 'anonymous';
        img.current.src = imageUrl;
      }

      if (imgLoaded.current) {
        const ctx = canvasRef.current?.getContext('2d');
        if (ctx) {
          updateImage(ctx);
        }
      }
    }
  }, [form]);

  const onHandleSave = useCallback(() => {
    canvasRef.current?.toBlob(async (blob) => {
      const file = new File([blob as Blob], `edited_${publicationId}_${Date.now()}.png`, { type: 'image/png' });
      form.handleSave(file);
    });
  }, [form, publicationId]);

  const onHandleOverwrite = useCallback(() => {
    canvasRef.current?.toBlob(async (blob) => {
      const file = new File([blob as Blob], fileName, { type: 'image/png' });
      form.handleOverwrite(file);
    });
  }, [form, fileName]);

  if (!form.asset) {
    return null;
  }

  return (
    <>
      <div className="flex-1 overflow-auto">
        <div className="flex h-full gap-8">
          <div className="w-sidebar">
            <div className="flex flex-col gap-4">
              <BrightnessControl brightness={form.brightness} setBrightness={form.setBrightness} />
              <ContrastControl contrast={form.contrast} setContrast={form.setContrast} />
              <HueControl hue={form.hue} setHue={form.setHue} />
              <SaturationControl saturation={form.saturation} setSaturation={form.setSaturation} />
            </div>
          </div>
          <div className="flex items-center justify-center flex-1 h-full col-span-7 overflow-hidden bg-gray-100 border border-gray-200 rounded-xl">
            <canvas ref={canvasRef} className="object-contain w-full h-full" />
          </div>
        </div>
      </div>
      <div className="flex-none mt-6">
        <div className="flex items-center justify-end gap-2">
          <Button disabled={form.isSaving} onClick={onHandleOverwrite} type="button" variant="primary-inverse">
            Overwrite
          </Button>
          <Button disabled={form.isSaving} onClick={onHandleSave} type="button" variant="primary">
            Save as copy
            <ArrowRightIcon className="w-5 h-5 ml-1" />
          </Button>
        </div>
      </div>
    </>
  );
};
