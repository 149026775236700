import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/ui/Button';

import { Input } from '../../components/Form';
// Components
import { SvgLogo } from '../../components/SvgLogo';
// Context
import { useAuth } from '../../context/auth-context';

interface Props {
  inviteId: string;
  inviteToken: string;
  tokenId: string;
  inviteableName: string;
  email: string;
}

const NewUser: FC<Props> = (props: Props) => {
  const { inviteId, inviteToken, tokenId, inviteableName, email } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { inviteAcceptNewUser } = useAuth();
  const navigate = useNavigate();

  const isDisabled = () => {
    if (!firstName || !password || !passwordConfirmation) {
      return true;
    }

    if (password !== passwordConfirmation) {
      return true;
    }

    return false;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = e;

    if (name === 'firstName') {
      setFirstName(value);
    } else if (name === 'lastName') {
      setLastName(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'passwordConfirmation') {
      setPasswordConfirmation(value);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      email,
      inviteToken,
      inviteId,
      tokenId,
      firstName,
      lastName,
      password,
      passwordConfirmation,
    };

    setIsSaving(true);
    inviteAcceptNewUser(payload)
      .then(() => navigate('/'))
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 p-5">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-12 h-auto m-auto">
          <a href="https://www.beehiiv.com" aria-label="beehiiv" target="_blank" rel="noreferrer">
            <SvgLogo />
          </a>
        </div>
        <h2 className="mt-6 text-center text-2xl font-medium text-gray-900">
          You&apos;ve been invited to join {inviteableName}! Create an account to join.
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <form onSubmit={handleSubmit} className="space-y-6">
            <Input name="email" labelText="Email" type="email" value={email} required disabled />
            <Input
              name="firstName"
              labelText="First Name"
              type="text"
              value={firstName}
              onChange={handleChange}
              required
            />
            <Input name="lastName" labelText="Last Name" type="text" value={lastName} onChange={handleChange} />
            <Input
              name="password"
              labelText="Password"
              type="password"
              value={password}
              onChange={handleChange}
              required
            />
            <Input
              name="passwordConfirmation"
              labelText="Password confirmation"
              type="password"
              value={passwordConfirmation}
              onChange={handleChange}
              required
            />
            <div>
              <Button type="submit" loading={isSaving} disabled={isDisabled()} block>
                Accept
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
