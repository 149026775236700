import { Selection, Transaction } from '@tiptap/pm/state';
import { ReplaceAroundStep, ReplaceStep } from '@tiptap/pm/transform';

export const getInsertionEndPosition = (tr: Transaction, startLen: number, bias: number) => {
  const last = tr.steps.length - 1;

  if (last < startLen) {
    return -1;
  }

  const step = tr.steps[last];

  if (!(step instanceof ReplaceStep || step instanceof ReplaceAroundStep)) {
    return -1;
  }

  const map = tr.mapping.maps[last];
  let end = 0;

  map.forEach((_from, _to, _newFrom, newTo) => {
    if (end === 0) {
      end = newTo;
    }
  });

  return Selection.near(tr.doc.resolve(end), bias);
};

export default getInsertionEndPosition;
