import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import useUpgradeIntent from '@/hooks/useUpgradeIntent';
import { IntentAction } from '@/interfaces/upgrades';
import { PLAN, PREMIUM_PLANS } from '@/utils/plans';

const useUpgradeForPages = () => {
  const publicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(publicationId);

  const isPagesEnabled = settings?.custom_pages;

  const { isOpen, handleOpen, handleClose } = useUpgradeIntent({ publicationId, intentAction: IntentAction.USE_PAGES });

  return {
    intentAction: IntentAction.USE_PAGES,
    handleOpen,
    handleClose,
    isOpen,
    preselectedPlan: PLAN.SCALE as keyof typeof PREMIUM_PLANS,
    isPagesEnabled,
  };
};

export default useUpgradeForPages;
