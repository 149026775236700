import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentPublication } from '@/hooks';
import { CustomPage, CustomPagePayload } from '@/interfaces/custom_page';
import { Publication } from '@/interfaces/publication';

import { StatusType, useSavingIndicator } from '../Helpers/SavingIndicator';

interface ContextProps {
  content: any;
  setContent: (value: any) => void;
  thumbnail: string | File | any;
  setThumbnail: (value: string | File | any) => void;
  pagePayload: CustomPagePayload;
  setPagePayload: (value: CustomPagePayload) => void;
  changesMade: boolean;
  handleSetPagePayload: (payload: CustomPagePayload) => void;
  setChangesMade: (value: boolean) => void;
  currentPublication?: Publication;
  currentPublicationId: string;
  isLoading?: boolean;
  setIsSaving: (isSaving: boolean) => void;
  handleSaved: () => void;
  status: StatusType;
}

const CustomPageContext = createContext<ContextProps>({
  content: {},
  setContent: () => {},
  thumbnail: '',
  setThumbnail: () => {},
  pagePayload: { slug: '', status: 'draft' },
  handleSetPagePayload: () => {},
  setPagePayload: () => {},
  changesMade: false,
  setChangesMade: () => {},
  isLoading: true,
  setIsSaving: () => {},
  handleSaved: () => {},
  status: 'idle',
  currentPublicationId: '',
});
CustomPageContext.displayName = 'CustomPageContext';

interface Props {
  children: React.ReactNode;
  isLoading?: boolean;
  customPage?: CustomPage;
  isEditing?: boolean;
}

export const DEFAULT_PAGE_PAYLOAD: CustomPagePayload = {
  meta_title: '',
  meta_description: '',
  slug: '',
  cta_text: 'New Page',
  noindexing_enabled: false,
  status: 'draft',
  nav_group_id: '',
};

export const CustomPageProvider = ({ children, isLoading, customPage, isEditing = false }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: currentPublication } = useCurrentPublication();

  const [searchParams] = useSearchParams();
  const shouldPublish = searchParams.get('publish') === 'true';

  const [hasInitialised, setHasInitialised] = useState(isEditing !== true);
  const { setIsSaving, handleSaved, status } = useSavingIndicator();
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [content, setContent] = useState<CustomPage | null>(customPage?.content || {});
  const [thumbnail, setThumbnail] = useState<string | File | any>(customPage?.thumbnail || null);
  const [pagePayload, setPagePayload] = useState<CustomPagePayload>(
    customPage
      ? {
          meta_title: customPage.meta_title,
          meta_description: customPage.meta_description,
          slug: customPage.slug,
          noindexing_enabled: customPage.noindexing_enabled,
          status: customPage.status,
          cta_text: customPage.nav_link.cta_text,
          nav_group_id: customPage.nav_link.nav_group_id,
          premium: customPage.premium,
        }
      : {
          ...DEFAULT_PAGE_PAYLOAD,
          status: shouldPublish ? 'live' : 'draft',
        }
  );

  useEffect(() => {
    if (customPage) {
      setThumbnail(customPage.thumbnail);
      setContent(customPage.content);
      setPagePayload({
        meta_title: customPage.meta_title,
        meta_description: customPage.meta_description,
        slug: customPage.slug,
        noindexing_enabled: customPage.noindexing_enabled,
        status: customPage.status,
        cta_text: customPage.nav_link.cta_text,
        nav_group_id: customPage.nav_link.nav_group_id,
        premium: customPage.premium,
      });
      setHasInitialised(true);
    }
  }, [customPage]);

  const value = useMemo(() => {
    return {
      content,
      setContent,
      thumbnail,
      setThumbnail,
      pagePayload,
      setPagePayload,
      handleSetPagePayload: (payload: CustomPagePayload) => {
        setChangesMade(true);
        setPagePayload(payload);
      },
      changesMade,
      setChangesMade,
      currentPublication,
      currentPublicationId,
      setIsSaving,
      handleSaved,
      status,
    };
  }, [
    content,
    thumbnail,
    pagePayload,
    changesMade,
    currentPublication,
    currentPublicationId,
    setIsSaving,
    handleSaved,
    status,
  ]);

  return (
    <CustomPageContext.Provider value={value}>
      {isLoading || !hasInitialised ? null : children}
    </CustomPageContext.Provider>
  );
};

export const useCustomPageContext = () => useContext(CustomPageContext) as ContextProps;
