import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import SlideOver from '@/components/SlideOver';
import useCreateSubscriberPreference from '@/hooks/useSubscriberPreferences/useCreateSubscriberPreference';
import useUpdateSubscriberPreference from '@/hooks/useSubscriberPreferences/useUpdateSubscriberPreference';
import {
  CreatePublicationSubscriberPreferenceParams,
  SubscriberPreferenceType,
  UpdatePublicationSubscriberPreferenceParams,
} from '@/interfaces/subscribers_preferences';
import { Button } from '@/ui/Button';

import { usePreferencesContext } from '../../preferences/context';

import Form from './Form';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
}

const FormSlideOver = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  const handleOnClose = () => {
    navigate('/subscribers/preferences');
    onClose?.();
  };

  const { editablePreference } = usePreferencesContext();
  const { mutateAsync: create } = useCreateSubscriberPreference();
  const { mutateAsync: update } = useUpdateSubscriberPreference();

  const getValidationError = () => {
    if (!editablePreference.name) {
      return {
        message: 'To save the preference, add a Name.',
      };
    }

    if (
      [SubscriberPreferenceType.MULTIPLE_CHOICE, SubscriberPreferenceType.DROPDOWN].includes(
        editablePreference.preference_type
      ) &&
      (editablePreference.subscriber_preference_options.length === 0 ||
        // eslint-disable-next-line no-underscore-dangle
        editablePreference.subscriber_preference_options.filter((option) => !option._destroy).length === 0)
    ) {
      const optionType =
        editablePreference.preference_type === SubscriberPreferenceType.MULTIPLE_CHOICE
          ? 'Multiple Choice'
          : 'Dropdown';
      const message = `To save the preference, add a ${optionType} option.`;

      return { message };
    }

    if (!editablePreference.custom_field_id) {
      return {
        message: 'To save the preference, select a Custom Field.',
      };
    }

    return null;
  };

  const handleSave = () => {
    const validationError = getValidationError();

    if (validationError?.message) {
      toast.error(validationError?.message);
      return;
    }

    if (editablePreference.id) {
      const payload: UpdatePublicationSubscriberPreferenceParams = {
        id: editablePreference.id,
        name: editablePreference.name,
        preference_type: editablePreference.preference_type,
        display_type: editablePreference.display_type,
        visibility: editablePreference.visibility,
        custom_field_id: editablePreference.custom_field_id,
        description: editablePreference.description,
        required: editablePreference.required,
        subscriber_preference_options_attributes: editablePreference.subscriber_preference_options.map((option) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: option._isNew ? null : option.id,
          value: option.value,
          // eslint-disable-next-line no-underscore-dangle
          _destroy: option._destroy,
        })),
      };
      update(payload, {
        onSuccess: handleOnClose,
      });
    } else {
      const payload: CreatePublicationSubscriberPreferenceParams = {
        name: editablePreference.name,
        preference_type: editablePreference.preference_type,
        display_type: editablePreference.display_type,
        visibility: editablePreference.visibility,
        custom_field_id: editablePreference.custom_field_id,
        description: editablePreference.description,
        required: editablePreference.required,
        subscriber_preference_options_attributes: editablePreference.subscriber_preference_options.map((option) => ({
          value: option.value,
        })),
      };

      create(payload, {
        onSuccess: handleOnClose,
      });
    }
  };

  return (
    <SlideOver
      headerText={`${editablePreference.id ? 'Edit' : 'New'} Preference`}
      headerDescription="Edit the information below to update your a preference for your subscribers management page."
      isOpen={isOpen}
      onClose={handleOnClose}
      className="overflow-hidden"
      actionsChildren={
        <div className="space-x-3">
          <Button type="button" variant="primary-inverse" size="xs" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button type="button" size="xs" onClick={handleSave}>
            {editablePreference.id ? 'Update' : 'Create'}
          </Button>
        </div>
      }
    >
      <Form />
    </SlideOver>
  );
};

export default FormSlideOver;
