import { useQuery } from 'react-query';

import { AdNetworkAdvertisement } from '../../../interfaces/ad_network/internal/advertisement';
import api from '../../../services/swarm';

export default function useAdvertisement({ id }: { id: string }) {
  return useQuery<AdNetworkAdvertisement>(
    ['ad_network', 'internal', 'advertisements', id],
    () => api.get(`/ad_network/internal/advertisements/${id}`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
