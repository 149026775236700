import { FC, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  GiftIcon,
  RectangleStackIcon,
  TrashIcon,
  UserMinusIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';
import moment from 'moment-mini';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import SplitButton from '@/components/SplitButton';
import ApplyTagsModal from '@/components/SubscriberTags/ApplyTagsModal';
import SubscriberTagItem from '@/components/SubscriberTags/SubscriberTagItem';
import { useSettings } from '@/context/settings-context';
import { useAsyncExport, useCurrentPublication, useSegment } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { useSegmentSubscribers } from '@/hooks/useSegmentDetail';
import { SegmentStatus, SegmentType } from '@/interfaces/segment';
import { SubscriptionSubscriberTag } from '@/interfaces/subscriber_tag';
import { Subscription } from '@/interfaces/subscription';
import api from '@/services/swarm';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import SearchInput from '@/ui/SearchInput';
import analytics from '@/utils/analytics';

import { useSegmentViewContext } from '..';

import AutomationEnroll from './Actions/AutomationEnroll';
import PremiumGift from './Actions/PremiumGift';
import KPIs from './KPIs';

const TABLE_HEADERS = ['Email', 'Status', 'Tiers', 'Subscribed On', 'Acquisition Source'];
const BADGE_COLORS: any = { active: 'success', inactive: 'alert', pending: undefined };

const MaxHeightWrapper = styled.div.attrs({
  className: 'overflow-x-hidden border-b border-gray-200 relative',
})``;

const DetailSegment: FC = () => {
  const navigate = useNavigate();
  const { settings } = useSettings();

  const { segment } = useSegmentViewContext();
  const { data: currentPublication } = useCurrentPublication();
  const segmentQuery = useSegment({ segmentId: segment.id });

  const [isBulkUnsubscribing, setIsBulkUnsubscribing] = useState(false);
  const [bulkUnsubscribeRequested, setBulkUnsubscribeRequested] = useState(false);
  const [isBulkDeleting, setIsBulkDeleting] = useState(false);
  const [bulkDeleteRequested, setBulkDeleteRequested] = useState(false);
  const [premiumGiftRequested, setPremiumGiftRequested] = useState(false);
  const [automationEnrollRequested, setAutomationEnrollRequested] = useState(false);
  const [addTagsRequested, setAddTagsRequested] = useState(false);
  const currentPublicationId = useCurrentPublicationId();
  const { startExport } = useAsyncExport(currentPublicationId, {
    exportType: 'segment_subscriber',
    resourceType: 'segment',
    resourceId: segment.id,
  });
  const { startExport: startBasicExport } = useAsyncExport(currentPublicationId, {
    exportType: 'basic_segment_subscriber',
    resourceType: 'segment',
    resourceId: segment.id,
  });

  const [search, setSearch] = useQueryParam('search', StringParam);
  const subscriberQuery = useSegmentSubscribers({
    segmentId: segment.id,
    enabled: segment.status === SegmentStatus.COMPLETED || segment.segment_type === SegmentType.MANUAL,
    search: search || '',
  });
  const {
    data: subscriberData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching: isFetchSubscribers,
  } = subscriberQuery;
  const totalSubscriberCount = subscriberData?.pages[0]?.pagination?.total;
  const subscribersShowing = subscriberData?.pages?.flatMap((page) => page.subscriptions) || [];
  const giftingEnabled = settings?.gift_premium_subscriptions;
  const automationsEnabled = settings?.automations;
  const taggingEnabled = settings?.subscriber_tagging;
  const headers = taggingEnabled
    ? ['Email', 'Status', 'Tags', 'Tiers', 'Subscribed On', 'Acquisition Source']
    : TABLE_HEADERS;

  const onPremiumGiftClose = () => setPremiumGiftRequested(false);
  const onPremiumGiftRequested = () => setPremiumGiftRequested(true);
  const onAddTagsRequested = () => setAddTagsRequested(true);
  const onAddTagsClose = () => setAddTagsRequested(false);
  const onAutomationEnrollRequested = () => setAutomationEnrollRequested(true);
  const onAutomationEnrollClose = () => setAutomationEnrollRequested(false);
  const onBulkUnsubscribeRequested = () => setBulkUnsubscribeRequested(true);
  const onBulkUnsubscribeModalClose = () => setBulkUnsubscribeRequested(false);
  const onBulkUnsubscribe = () => {
    const params = {
      publication_id: currentPublication?.id,
      action_type: 'unsubscribe',
    };

    setIsBulkUnsubscribing(true);
    api
      .post(`/segments/${segment.id}/segment_actions`, { ...params })
      .then(() => {
        analytics.track('Performed a Segment Action');
        toast.success('Bulk unsubscribe initiated, check back later');
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setBulkUnsubscribeRequested(false);
        setIsBulkUnsubscribing(false);
      });
  };

  const onBulkDeleteRequested = () => setBulkDeleteRequested(true);
  const onBulkDeleteModalClose = () => setBulkDeleteRequested(false);
  const onBulkDelete = () => {
    const params = {
      publication_id: currentPublication?.id,
      action_type: 'delete',
    };

    setIsBulkDeleting(true);
    api
      .post(`/segments/${segment.id}/segment_actions`, { ...params })
      .then(() => {
        analytics.track('Performed a Segment Action');
        toast.success('Bulk delete initiated, check back later');
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setBulkDeleteRequested(false);
        setIsBulkDeleting(false);
      });
  };

  const recheckSegment = () => {
    segmentQuery.refetch();
    subscriberQuery.refetch();
  };

  const handleRemoveSubscriber = (subscription: Subscription) => {
    const params = { publication_id: currentPublication?.id };
    api
      .delete(`/segments/${segment.id}/subscriptions/${subscription.id}`, { data: { ...params } })
      .then(() => {
        analytics.track('Performed a Segment Action');
        toast.success('Subscriber removed from segment');
        recheckSegment();
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      });
  };

  const [shouldResetSearch, setShouldResetSearch] = useState(false);

  useEffect(() => {
    subscriberQuery.refetch();
  }, [search]);

  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  // Reset search when no results
  const handleResetSearch = () => {
    setSearch(undefined);
    setShouldResetSearch(true);
  };

  const renderNoResults = () => {
    const noSearchResults = subscribersShowing.length === 0 && search;

    // When clearing search after no results appear,
    // show the loading spinner instead of the "Update Conditions" button
    if (isFetchSubscribers) {
      return (
        <div className="w-full h-full p-8">
          <div className="h-full bg-gray-50 rounded flex items-center justify-center px-4 py-16">
            <div className="flex flex-col items-center gap-4">
              <div className="hidden md:block">
                <LoadingSpinner />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="w-full h-full p-8">
        <div className="h-full bg-gray-50 rounded flex items-center justify-center px-4 py-16">
          <div className="flex flex-col items-center gap-4">
            {noSearchResults ? (
              <>
                <p className="text-gray-500">{`No results found for search, '${search}'`}</p>
                <div>
                  <Button variant="primary-inverse" onClick={handleResetSearch}>
                    <div className="flex">
                      <ArrowPathIcon className="h-5 w-5 mr-2" />
                      Reset Search
                    </div>
                  </Button>
                </div>
              </>
            ) : (
              <>
                <p className="text-gray-500">You currently have no subscribers in this segment</p>
                <div>
                  <Button variant="primary" onClick={() => navigate(`/segments/${segment.id}/edit`)}>
                    <div className="flex">
                      <UsersIcon className="h-5 w-5 mr-2" />
                      {segment.segment_type === SegmentType.MANUAL ? 'Add Subscribers' : 'Update Conditions'}
                    </div>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderProcessing = () => (
    <div className="w-full h-full p-8">
      <div className="h-full flex items-center justify-center px-4 py-16">
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500">Segment is currently processing</p>
          <div>
            <Button variant="primary-inverse" onClick={() => recheckSegment()}>
              <div className="flex">
                <ArrowPathIcon className="h-5 w-5 mr-2" />
                Refresh Segment
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const renderBody = () => (
    <>
      <div className="py-6">
        <KPIs segmentId={segment.id} />
      </div>
      <div className="mb-4 flex justify-end">
        <ActionModal
          isOpen={bulkUnsubscribeRequested}
          onClose={onBulkUnsubscribeModalClose}
          onProceed={onBulkUnsubscribe}
          resourceId={segment.id}
          isWorking={isBulkUnsubscribing}
          headerText="Bulk Unsubscribe Segment"
          actionText="Bulk Unsubscribe"
        >
          Are you sure you want to bulk unsubscribe this segment?
        </ActionModal>
        <ActionModal
          isOpen={bulkDeleteRequested}
          onClose={onBulkDeleteModalClose}
          onProceed={onBulkDelete}
          resourceId={segment.id}
          isWorking={isBulkDeleting}
          headerText="Bulk Delete Segment"
          actionText="Bulk Delete"
        >
          Are you sure you want to permenantly delete these Subscribers? All associated data will be lost.
        </ActionModal>

        {giftingEnabled && (
          <PremiumGift segmentId={segment.id || ''} isOpen={premiumGiftRequested} onClose={onPremiumGiftClose} />
        )}
        {taggingEnabled && segment && (
          <ApplyTagsModal segmentId={segment.id} isOpen={addTagsRequested} onClose={onAddTagsClose} />
        )}
        {automationsEnabled && segment && (
          <AutomationEnroll
            segmentId={segment.id}
            isOpen={automationEnrollRequested}
            onClose={onAutomationEnrollClose}
          />
        )}
        <SplitButton
          variant="primary-inverse"
          buttons={[
            {
              label: 'Quick Export',
              helperText: 'Download data about this segment',
              onClick: startBasicExport,
              button: {
                Icon: ArrowDownTrayIcon,
              },
            },
            {
              label: 'Full Export',
              helperText: 'Download all data about this segment, this can be time consuming',
              onClick: startExport,
              button: {
                Icon: ArrowDownTrayIcon,
              },
            },
            {
              label: 'Unsubscribe',
              helperText: 'Unsubscribe this segment from your newsletter',
              onClick: onBulkUnsubscribeRequested,
              button: {
                text: 'Bulk Unsubscribe',
                Icon: UserMinusIcon,
              },
            },
            {
              label: 'Delete',
              helperText: "Permenantly Delete this segment's subscribers",
              onClick: onBulkDeleteRequested,
              button: {
                text: 'Bulk Delete',
                Icon: TrashIcon,
              },
            },
            ...(giftingEnabled
              ? [
                  {
                    label: 'Gift Paid Subscription',
                    helperText: 'Gift a paid subscription for free to this segment',
                    onClick: onPremiumGiftRequested,
                    button: {
                      Icon: GiftIcon,
                    },
                  },
                ]
              : []),
            ...(taggingEnabled
              ? [
                  {
                    label: 'Apply Tags to Subscribers',
                    helperText: 'Tag the subscribers in this segment',
                    onClick: onAddTagsRequested,
                    button: {
                      Icon: RectangleStackIcon,
                    },
                  },
                ]
              : []),
            ...(automationsEnabled
              ? [
                  {
                    label: 'Add to Automations',
                    helperText: 'Manually enroll this segment in automation flows',
                    onClick: onAutomationEnrollRequested,
                    button: {
                      Icon: RectangleStackIcon,
                    },
                  },
                ]
              : []),
          ]}
        />
      </div>
      <div className="bg-white p-6 rounded">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex flex-col gap-1 w-full">
            <div className="flex items-center">
              <h1 className="text-lg pb-0.5 leading-6 font-medium text-gray-900 mr-2 flex items-center">
                Subscribers{' '}
              </h1>
              <div className="flex items-center gap-1">
                <Badge>
                  Showing {subscribersShowing.length} of {totalSubscriberCount} results
                </Badge>
              </div>
            </div>
            <p className="text-sm text-gray-500">Subscribers that make up this segment</p>
          </div>
          <div className="w-full pt-4 md:pt-0 relative flex items-center justify-end">
            {isFetchSubscribers && search && (
              <div className="hidden md:block">
                <LoadingSpinner />
              </div>
            )}
            <div className="w-full sm:w-fit">
              <SearchInput
                defaultValue={search || ''}
                shouldDebounce={false}
                shouldReset={shouldResetSearch}
                onClearSearch={handleResetSearch}
                onSearch={setSearch}
                placeholder="Search subscribers"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <MaxHeightWrapper>
              {(subscriberData || segment.segment_type === SegmentType.MANUAL) && subscribersShowing.length === 0 ? (
                renderNoResults()
              ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {headers.map((header) => (
                        <th
                          key={header}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {subscribersShowing?.map((subscription: Subscription) => (
                      <tr key={subscription.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="text-sm font-medium text-gray-900">
                              <Link
                                className="text-primary-600 hover:text-primary-900"
                                to={`/subscribers/${subscription.uuid}`}
                              >
                                {subscription.email}
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            <Badge type={BADGE_COLORS[subscription.status]}>{subscription.status}</Badge>
                          </div>
                        </td>
                        {taggingEnabled && (
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {subscription.subscription_subscriber_tags?.map((tag: SubscriptionSubscriberTag) => (
                                <span key={tag.id}>
                                  <SubscriberTagItem name={tag.name} color={tag.color} />
                                </span>
                              ))}
                            </div>
                          </td>
                        )}
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{subscription.tiers.join(', ')}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{moment(subscription.created_at).format('LLL')}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{subscription.acquisition_source_display}</div>
                        </td>
                        <td className="whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            <EllipsisDropdown
                              options={[
                                {
                                  label: 'Remove',
                                  onClick: () => {
                                    handleRemoveSubscriber(subscription);
                                  },
                                },
                              ]}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {hasNextPage && (
                <div className="flex justify-center align-middle p-6">
                  <Button
                    variant="primary-inverse"
                    onClick={() => fetchNextPage()}
                    loading={isFetchingNextPage}
                    disabled={!hasNextPage || isFetchingNextPage}
                  >
                    {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                  </Button>
                </div>
              )}
            </MaxHeightWrapper>
          </div>
        </div>
      </div>
    </>
  );

  return !segment.is_locked &&
    (segment.status === SegmentStatus.COMPLETED || segment.segment_type === SegmentType.MANUAL)
    ? renderBody()
    : renderProcessing();
};

export default DetailSegment;
