import { useNavigate, useParams } from 'react-router-dom';

import { useSettings } from '@/context/settings-context';

import Engagement from '../Engagement';
import NavContainer from '../NavContainer';

interface RouteParams {
  postId: string;
}

const RecipientsContainer = () => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;
  const navigate = useNavigate();

  const { postId } = useParams() as unknown as RouteParams;

  if (isV2) {
    navigate(`/posts/${postId}/engagement-overview?tab=top_subscribers`);
    return null;
  }

  return <NavContainer postId={postId}>{() => <Engagement postId={postId} />}</NavContainer>;
};

export default RecipientsContainer;
