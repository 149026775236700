import { RadioOption } from '@/components/Form/RadioSelect';
import { ContentImport } from '@/interfaces/content_import';

export interface StepProps {
  contentImport: Partial<ContentImport>;
  updateContentImport: (newContentImport: Partial<ContentImport>) => void;
  errors: StepErrors;
}

export type StepErrors = {
  platform?: string;
  url?: string;
  apiKey?: string;
  integrationId?: string;
};

export const MailchimpImportOptions: RadioOption[] = [
  { name: 'All campaigns', value: '1' },
  { name: 'Customized by campaign folders', value: '2' },
];
