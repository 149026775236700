import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

const useDeleteStripeConnectAccount = (publicationId: string) => {
  const queryClient = useQueryClient();

  const params = {
    publication_id: publicationId,
  };

  return useMutation(() => api.delete('/boosts/monetize/stripe_connect_account', { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.message || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries([publicationId, 'boosts', 'monetize', 'stripe_connect_account']);
      toast.success('Account Disconnected!');
    },
  });
};

export default useDeleteStripeConnectAccount;
