import { Outlet } from 'react-router-dom';

import { SvgLogoWithName } from '@/components/SvgLogoWithName';

export default function Layout() {
  return (
    <div className="flex min-h-screen flex-col">
      <nav className="bg-gray-100 p-6 border-b-2 border-black">
        <SvgLogoWithName className="text-black w-32" />
      </nav>
      <main className="bg-gray-100 flex-1">
        <Outlet />
      </main>
      <footer className="bg-feedback-info-700 p-6">
        <div className="p-6">
          <div className="grid grid-cols-2 items-center">
            <div className="">
              <h2 className="text-white text-4xl font-black">QUESTIONS?</h2>
            </div>
            <div>
              <a
                type="button"
                href="mailto:ads@beehiiv.com"
                className="bg-black text-white text-lg px-4 py-6 rounded w-full text-center"
              >
                Get in touch
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
