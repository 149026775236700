import { useParams } from 'react-router-dom';

import NavContainer from '../NavContainer';
import Polls from '../Polls';

interface RouteParams {
  postId: string;
}

const PollsContainer = () => {
  const { postId } = useParams() as unknown as RouteParams;

  return <NavContainer postId={postId}>{({ post }) => <Polls post={post} />}</NavContainer>;
};

export default PollsContainer;
