import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Author } from '../interfaces/author';
import api from '../services/swarm';

const useUsers = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<Author[]>(['publications', currentPublicationId, 'users'], () =>
    api.get(`/publications/${currentPublicationId}/users`).then((response) => response.data)
  );
};

export default useUsers;
