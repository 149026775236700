import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { useCurrentUser } from '../../context/current-user-context';
import api from '../../services/swarm';

interface ApiResponse {
  client_secret: string;
}

interface Props {
  organizationId: string | undefined;
  onVerificationComplete?: () => void;
}

const useStripeIdentityVerificationSession = ({ organizationId, onVerificationComplete }: Props) => {
  const { currentUser } = useCurrentUser();
  const [stripe, setStripe] = useState<null | Stripe>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchStripeObject = async () => {
      await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '').then((res) => {
        setStripe(res as Stripe);
      });
    };

    fetchStripeObject();
  }, []);

  return {
    mutation: useMutation<ApiResponse, unknown, {}>(
      () =>
        api
          .post(`/users/${currentUser?.id}/stripe/identity_verification_sessions`, {
            organization_id: organizationId,
          })
          .then((res) => res.data),
      {
        onSuccess: async (data: { client_secret: string }) => {
          if (!stripe) {
            toast.error('Something went wrong while verifying your identity, please try again.');
            return;
          }

          setIsSubmitting(true);

          try {
            const response = await stripe.verifyIdentity(data.client_secret);

            if (response.error) {
              toast.error('Something went wrong while verifying your identity, please try again.');
            } else {
              setSubmitted(true);
              onVerificationComplete?.();
            }
          } catch (error: any) {
            toast.error('Something went wrong while verifying your identity, please try again.');
          }

          setIsSubmitting(false);
        },
        onError: (err: any) => {
          if (
            location.pathname === '/settings/integrations/api' &&
            err?.response?.data?.error === 'Please verify identity manually through the form'
          ) {
            toast.error('Stripe verification disabled - please contact support for further assistance.');
          } else {
            toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
          }
        },
      }
    ),
    stripe,
    isSubmitting,
    submitted,
  };
};

export default useStripeIdentityVerificationSession;
