import { PropsWithChildren } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { SortableConditionBlockData } from '@/pages/Segments/ConditionsEditor/types';

type SortableTreeItemsProps = PropsWithChildren<{
  uniqueIdentifier: string;
  listItems: string[];
  isFirstItemAGroup?: boolean;
  data?: SortableConditionBlockData;
}>;

const SortableTreeItems = ({
  listItems,
  isFirstItemAGroup = false,
  children,
  uniqueIdentifier,
  data,
}: SortableTreeItemsProps) => {
  const { setNodeRef, isOver, active } = useDroppable({
    id: uniqueIdentifier,
    data,
  });

  const canExpand = isOver && isFirstItemAGroup;
  const dropZoneStyle =
    canExpand && active?.rect?.current?.initial?.height !== undefined
      ? {
          height: `${active?.rect?.current?.initial?.height}px`,
        }
      : {};

  return (
    <SortableContext id={uniqueIdentifier} items={listItems} strategy={verticalListSortingStrategy}>
      {isFirstItemAGroup && (
        <div className="h-2 ml-4 transition-all" style={dropZoneStyle}>
          &nbsp;
        </div>
      )}
      <div ref={setNodeRef}>{children}</div>
    </SortableContext>
  );
};

export default SortableTreeItems;
