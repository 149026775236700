import { Plugin, PluginKey } from '@tiptap/pm/state';

export const NonDeletableNodesPluginKey = new PluginKey('nonDeletableNodes');

export const getNonDeletableNodes = (nonDeletableNodes: string[], notify?: Function) => {
  return new Plugin({
    key: NonDeletableNodesPluginKey,
    filterTransaction(tr, state) {
      if (!tr.docChanged) {
        return true;
      }

      const nodeCountMapBefore: Record<string, number> = {};
      state.doc.descendants((node) => {
        nodeCountMapBefore[node.type.name] = (nodeCountMapBefore[node.type.name] || 0) + 1;
      });

      const nodeCountMapAfter: Record<string, number> = {};
      tr.doc.descendants((node) => {
        nodeCountMapAfter[node.type.name] = (nodeCountMapAfter[node.type.name] || 0) + 1;
      });

      const nodeDeleted = nonDeletableNodes.some((nodeName) => {
        return (nodeCountMapBefore[nodeName] || 0) > (nodeCountMapAfter[nodeName] || 0);
      });

      if (nodeDeleted) {
        notify?.();
        return false;
      }

      return true;
    },
  });
};
