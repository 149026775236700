import { createContext, useContext } from 'react';

import useConditionManager from './useConditionManager';

export const FilteringContext = createContext<ReturnType<typeof useConditionManager> | null>(null);

export default function useFilteringContext() {
  const context = useContext(FilteringContext);
  if (!context) {
    throw new Error('useFilteringContext must be used within a FilteringContext.Provider');
  }
  return context;
}
