import styled from 'styled-components';

import { colors } from '../../../lib/colors';
import { Styled as StyledButton } from '../../ui/Button/styled';

export const Styled = {
  ButtonGroup: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    & > ${StyledButton.Button} {
      color: ${colors.white[5]}; // TODO: Revise
      flex: 0 0 calc(33.333333% - 0.25rem);
      justify-content: center;
      text-align: center;
    }
  `,
};

export default Styled;
