import React from 'react';
import cx from 'classnames';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  sizeVariant?: 'sm' | 'default';
}

const Input = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
  const sizeClassName = {
    sm: 'py-1 px-3',
    default: 'py-2 px-4',
  }[props.sizeVariant || 'default'];

  const baseClassName = 'text-sm py-1 px-3 rounded-md shadow-sm border-gray-200';
  const className = cx(baseClassName, sizeClassName, props.className);

  return <input ref={ref} className={className} {...props} />;
});

export default Input;
