import { useState } from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { Order } from '@/interfaces/general';
import { ClickMapPostClick, DeviceType } from '@/interfaces/post';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import PostClicksContainer from '../PostClicksContainer';

import { getClicksPercentage } from './getClicksPercentage';

interface Props {
  postId: string;
  onClickLink: (click: ClickMapPostClick) => void;
  filterByDevice?: DeviceType;
  totalEmailClicks: number;
}

const LinksSummary = ({ postId, filterByDevice, totalEmailClicks, onClickLink }: Props) => {
  const [currentDirection, setCurrentDirection] = useState(Order.DESC);
  const { settings } = useSettings();
  const canUseGroupLinksQuery = settings?.posts_click_map_link_grouping === true;

  return (
    <div className="flex flex-col gap-y-2">
      <Dropdown
        value={currentDirection}
        name="total-clicks-direction"
        labelText="Filter by"
        options={[
          { label: 'Appearance order', isOptGroup: true, value: '' },
          { label: 'Total clicks (highest to lowest)', value: Order.DESC },
          { label: 'Total clicks (lowest to highest)', value: Order.ASC },
        ]}
        onSelect={(_, value) => setCurrentDirection(value)}
      />
      <PostClicksContainer
        postId={postId}
        platform="email"
        direction={currentDirection}
        deviceType={filterByDevice}
        groupBy="link_id"
        useClickMap={canUseGroupLinksQuery}
        orderBy="total_unique_email_clicked"
      >
        {(clicks, _, isLoading) => {
          if (isLoading) {
            return (
              <div className="flex items-center">
                <LoadingSpinner className="mr-2" />
                <p className="text-gray-400 text-sm">Loading clicks…</p>
              </div>
            );
          }

          const hasNoResults = clicks.length === 0;

          if (hasNoResults) {
            return (
              <div className="w-full p-2 text-center">
                <Typography token="font-normal/text/sm" colorWeight="700">
                  No Clicks yet!
                </Typography>
              </div>
            );
          }

          return (
            <div className="flex flex-col gap-y-3">
              {(clicks as ClickMapPostClick[]).map((click) => {
                const clickPercentage = getClicksPercentage(click, totalEmailClicks);

                return (
                  <div className="flex justify-between items-center gap-y-4">
                    <Tooltip id={click.id} text={click.url} showIcon={false} tooltipClass="break-words">
                      <div className="w-36 relative py-2 px-4 flex items-center rounded-md">
                        <Typography
                          token="font-medium/text/sm"
                          className="relative z-10 w-full overflow-hidden truncate"
                        >
                          {click.url}
                        </Typography>
                        <div
                          className="bg-action-secondary-50 absolute h-full top-0 left-0"
                          style={{
                            width: `${Math.ceil(clickPercentage)}%`,
                          }}
                        >
                          &nbsp;
                        </div>
                      </div>
                    </Tooltip>
                    <Button
                      type="button"
                      size="xs"
                      variant="flush"
                      Icon={ArrowRightIcon}
                      iconRight
                      onClick={() => onClickLink(click)}
                    >
                      {clickPercentage.toFixed(2)}%
                    </Button>
                  </div>
                );
              })}
            </div>
          );
        }}
      </PostClicksContainer>
    </div>
  );
};

export default LinksSummary;
