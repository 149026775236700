import { useMemo } from 'react';
import { toHtml } from 'hast-util-to-html';
import json from 'highlight.js/lib/languages/json';
import { lowlight } from 'lowlight/lib/core';

import { Typography } from '@/components/Typography';

import 'highlight.js/styles/atom-one-light.css';

// We use custom colors for the payload to match our developer docs
const customStyles = `
.hljs-attr { color: #D32F2F; }
.hljs-number { color: #5A96DC; }
.hljs-string { color: #23863A; }
.hljs-boolean { color: #5A96DC; }
.hljs-punctuation { color: #242424; }
.hljs-bracket { color: #24292E; }
`;

type Props = {
  payload: any;
};

lowlight.registerLanguage('json', json);

const MessagePayload = ({ payload }: Props) => {
  const highlighted = useMemo(() => {
    const jsonString = JSON.stringify(payload, null, 2);
    const result = lowlight.highlight('json', jsonString);
    return toHtml(result);
  }, [payload]);

  return (
    <div className="space-y-4">
      <Typography token="font-medium/text/base">Payload</Typography>
      <div className="overflow-hidden bg-white border border-gray-200 rounded-lg shadow-sm">
        <style>{customStyles}</style>
        <pre className="overflow-x-auto p-5 m-0">
          <code
            className="hljs text-sm font-mono !bg-white leading-relaxed"
            dangerouslySetInnerHTML={{ __html: highlighted }}
          />
        </pre>
      </div>
    </div>
  );
};

export default MessagePayload;
