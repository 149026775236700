import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { Referral } from '@/interfaces/referral';
import api from '@/services/swarm';

interface ApiResponse {
  referrals: Referral[];
  pagination: Pagination;
}

const useReferrals = (referrerId?: number, enabled?: boolean) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    [currentPublicationId, 'referral_program', referrerId, 'referrals'],
    ({ pageParam = 1 }) =>
      api
        .get(`/referral_program/referrals`, {
          params: {
            publication_id: currentPublicationId,
            referrer_id: referrerId,
            page: pageParam,
          },
        })
        .then((res) => res.data),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
    }
  );
};

export default useReferrals;
