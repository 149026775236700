import { FC } from 'react';

import { SubscriptionMilestone } from '@/interfaces/milestone';

import { Badge } from '../../../ui/Badge';

interface Props {
  status: SubscriptionMilestone['status'];
}

const badgeTypeFromStatus = (status: string): 'success' | 'alert' | 'warning' => {
  if (status === 'fulfilled') {
    return 'success';
  }

  if (status === 'ignored') {
    return 'alert';
  }

  return 'warning';
};

const STATUS_LABELS: Record<SubscriptionMilestone['status'], string> = {
  ignored: 'Ignored',
  pending: 'Pending',
  fulfilled: 'Fulfilled',
  awaiting_promo_code: 'Awaiting Promo Code',
};

const StatusBadge: FC<Props> = (props: Props) => {
  const { status } = props;

  return <Badge type={badgeTypeFromStatus(status)}>{STATUS_LABELS[status]}</Badge>;
};

export default StatusBadge;
