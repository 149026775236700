import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Plus } from '@phosphor-icons/react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import useCreateSitePackage from '@/hooks/useSitePackages/useCreateSitePackage';
import { SitePackage } from '@/interfaces/site_package';

import { Button } from '../../_components/UI/Button';
import { Input } from '../../_components/UI/Input';
import Modal from '../../_components/UI/Modal';
import { Text } from '../../_components/UI/Text';

export const TopBar = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [currentPublicationId] = useCurrentPublicationState();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');

  const createSitePackage = useCreateSitePackage({
    onSuccess: (data: SitePackage) => {
      queryClient.invalidateQueries(['publications', currentPublicationId, 'site_packages']);
      setIsOpen(false);
      navigate(`/website_builder_v2/projects/${data.id}`);
    },
  });

  const handleCreateProject = () => {
    if (!name.trim()) {
      toast.error('Please enter a name for the project');
      return;
    }

    const formData = new FormData();
    formData.append('site_package[name]', name);

    createSitePackage.mutate(formData);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex flex-col space-y-2 max-w-[620px]">
          <div className="flex-1">
            <Text size="xl" weight="semibold" variant="primary" as="h4">
              Projects
            </Text>
          </div>
        </div>

        <Button LeftIcon={Plus} className="gap-2" onClick={() => setIsOpen(true)}>
          New Project
        </Button>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Add New Project"
        onConfirm={handleCreateProject}
        isLoading={createSitePackage.isLoading}
        ctaText="Create"
        isLoadingText="Creating..."
      >
        <Input
          name="name"
          labelText="Name"
          placeholder="New Project"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Modal>
    </>
  );
};
