import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { embeds as services } from '../../lib/constants';

import { GenericEmbedView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    genericEmbed: {
      setGenericEmbed: (attributes: { url: string }) => ReturnType;
      setGenericEmbedVariant: (
        variant: 'text-left-image-right' | 'image-left-text-right' | 'image-top-text-bottom' | 'text-only'
      ) => ReturnType;
    };
  }
}

export const GenericEmbed = Node.create({
  name: 'genericEmbed',

  group: 'block',

  content: 'genericEmbedTitle genericEmbedDescription genericEmbedHost',

  draggable: true,

  addOptions() {
    return {
      clientId: null,
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      url: {
        parseHTML: (element) => element.getAttribute('data-url'),
        renderHTML: (attributes) => ({
          'data-url': attributes.url,
        }),
      },
      target: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-target'),
        renderHTML: (attributes) => ({
          'data-target': attributes.target,
        }),
      },
      imageUrl: {
        parseHTML: (element) => element.getAttribute('data-image-url'),
        renderHTML: (attributes) => ({
          'data-image-url': attributes.imageUrl,
        }),
      },
      hasFetched: {
        parseHTML: (element) => element.getAttribute('data-has-fetched'),
        renderHTML: (attributes) => ({
          'data-has-fetched': attributes.hasFetched,
        }),
      },
      variant: {
        default: 'text-left-image-right',
        parseHTML: (element) => element.getAttribute('data-variant'),
        renderHTML: (attributes) => ({
          'data-variant': attributes.variant,
        }),
      },
      clientId: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: `.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setGenericEmbed:
        ({ url }) =>
        ({ chain }) =>
          chain()
            .focus()
            .selectParentNode()
            .insertContent({
              type: this.name,
              attrs: { url, clientId: this.options.clientId },
              content: [
                {
                  type: 'genericEmbedTitle',
                },
                {
                  type: 'genericEmbedDescription',
                },
                {
                  type: 'genericEmbedHost',
                },
              ],
            })
            .run(),
      setGenericEmbedVariant:
        (variant) =>
        ({ commands }) =>
          commands.updateAttributes('genericEmbed', { variant }),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(GenericEmbedView);
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      new Plugin({
        key: new PluginKey('genericEmbedPasteHandler'),
        props: {
          handleDOMEvents: {
            paste(view, event) {
              const { selection } = editor.state;
              const { $anchor, empty } = selection;
              const isRootDepth = $anchor.depth === 1;
              const isEmptyTextBlock =
                $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && !$anchor.parent.textContent;
              const isParagraph = editor.state.selection.$head.parent.type.name === 'paragraph';
              const isInSection = editor.isActive('section');
              const isSectionDepth = $anchor.depth === 2;

              if (
                !(empty && isRootDepth && isEmptyTextBlock && isParagraph && editor.isEditable) &&
                !(isInSection && isSectionDepth && isEmptyTextBlock && isParagraph && editor.isEditable)
              ) {
                return false;
              }

              const pastedText = event.clipboardData?.getData('text/plain');
              const isGenericLink =
                Object.entries(services).find(([, serviceSpecs]) => pastedText?.match(serviceSpecs.regex))?.[0] ===
                'generic';

              if (isGenericLink && pastedText) {
                event.preventDefault();

                editor.commands.setGenericEmbed({ url: pastedText });
              }

              return false;
            },
          },
        },
      }),
    ];
  },
});

export default GenericEmbed;
