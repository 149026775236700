import { useQuery } from 'react-query';

import { usePermissions } from '../../context/permissions-context';
import { CustomDomain } from '../../interfaces/custom_domain';
import api from '../../services/swarm';

const useCustomDomains = (publicationId: string) => {
  const { checkPermissions } = usePermissions();

  return useQuery<CustomDomain[]>(
    ['custom_domains', publicationId],
    () =>
      api
        .get(`/custom_domains`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
      enabled: checkPermissions('domain_setting', 'read'),
    }
  );
};

export default useCustomDomains;
