import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { ClockIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { useSettings } from '@/context/settings-context';

import Card from '../../../../components/Card';
import Text from '../../../../components/Text';
import { Comment } from '../../../../interfaces/comment';
import Report from '../CommentReport';

interface Props {
  title?: string;
  pendingReports: any[];
  isResolved?: boolean;
  onTakeAction: (comment: Comment) => void;
  onViewReports: (comment: any) => void;
  comments: Comment[];
}

const CommentReportSections = ({
  title,
  isResolved = false,
  onTakeAction,
  comments,
  onViewReports,
  pendingReports,
}: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  if (isV2) {
    return (
      <div className="flex flex-col gap-y-4">
        {comments?.map((comment: Comment) => {
          const isPending = pendingReports.includes(comment.id);
          return (
            <Card key={comment.id} background="bg-surface-50">
              <Report
                onTakeAction={onTakeAction}
                onViewReports={onViewReports}
                comment={comment}
                isResolved={isResolved}
                isPending={isPending}
              />
            </Card>
          );
        })}
      </div>
    );
  }

  return (
    <div className="space-y-1">
      <Text size="sm" type="body" className="block text-sm font-medium text-gray-700">
        {title}
      </Text>

      <div className="space-y-4">
        {comments?.map((comment: Comment) => {
          const isPending = pendingReports.includes(comment.id);
          return (
            <div key={comment.id} className="relative">
              {isResolved && (
                <div className="absolute z-10 -top-1 -left-1">
                  <CheckCircleIcon className="text-green-500 h-5 w-5" />
                </div>
              )}
              {isPending && (
                <div className="absolute z-10 -top-1 -left-1">
                  <ClockIcon className="text-gray-500 h-5 w-5" />
                </div>
              )}
              <div className={cx(isResolved || isPending ? 'opacity-30' : '')}>
                <Card noPadding>
                  <Report
                    onTakeAction={onTakeAction}
                    onViewReports={onViewReports}
                    comment={comment}
                    isResolved={isResolved}
                  />
                </Card>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CommentReportSections;
