import { SplitTestOption } from './split_test_option';

export enum SplitTestStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

interface Hash<T> {
  [key: string]: T;
}

export interface SplitTest {
  id: string;
  duration: number;
  sample_pct: number;
  sample_size: number;
  population_size: number;
  status: SplitTestStatus;
  post_id: string;
  split_test_options: SplitTestOption[];
  winning_option_id: string;
  statistically_significant: boolean;
}

export interface SplitTestWarning {
  warnings: Hash<string>;
}
