import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { CheckIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import { Button } from '@/ui/Button';

import EllipsisDropdown from '../../../../../../components/EllipsisDropdown';
import SlideOver from '../../../../../../components/SlideOver';
import { useUpdateCommentBan } from '../../../../../../hooks/useComments';
import { BanTypes, CommentBan } from '../../../../../../interfaces/comment_ban';
import { Badge } from '../../../../../../ui/Badge';

interface Props {
  commentBan: CommentBan;
}

const CommentBanItem = ({ commentBan }: Props) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const isBanLifted = Boolean(commentBan.lifted_at);
  const isPostLevelBan = commentBan.level === BanTypes.POST;

  const levelText = isPostLevelBan ? `Post - ${commentBan.post.title}` : 'All Posts';

  const updateCommentBan = useUpdateCommentBan({
    commentBanId: commentBan.id,
    onSuccess: () => {
      toast.success('Saved!');
      queryClient.invalidateQueries(['comment_bans']);
      setIsOpen(false);
    },
  });

  const handleRemoveBan = () => {
    updateCommentBan.mutate({
      lifted_at: moment().toISOString(),
    });
  };

  const handleUpdateToPublicationLevel = (level: string) => {
    updateCommentBan.mutate({
      level,
    });
  };

  const actionOptions = [];
  actionOptions.push({
    label: 'View Details',
    onClick: () => setIsOpen(true),
  });

  if (!isBanLifted) {
    actionOptions.push({
      label: 'Lift Ban',
      onClick: () => handleRemoveBan(),
    });
  }

  if (isPostLevelBan) {
    actionOptions.push({
      label: 'Bump to publication level',
      onClick: () => handleUpdateToPublicationLevel('publication'),
    });
  }

  return (
    <>
      <SlideOver
        bodyId={commentBan.id}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        headerText={commentBan?.subscription?.email}
      >
        <div className="space-y-4">
          <div className="space-y-0.5">
            <h4 className="font-semibold">Reason</h4>
            <p className="text-sm">{commentBan.reason}</p>
          </div>
          <div className="space-y-0.5">
            <h4 className="font-semibold">Level</h4>
            <p className="text-sm">{commentBan.level}</p>
          </div>
          <div className="space-y-0.5">
            <h4 className="font-semibold">Banned at</h4>
            <time className="text-sm">{moment(commentBan.created_at).format('LLL')}</time>
          </div>
          <div className="space-y-0.5">
            <h4 className="font-semibold">Status</h4>
            <p className="text-sm">{isBanLifted ? 'Lifted' : 'Active'}</p>
          </div>

          <div className="space-x-2 pt-4">
            <Button onClick={() => setIsOpen(false)} variant="primary-inverse">
              Close
            </Button>
            <Button onClick={handleRemoveBan}>Remove Ban</Button>
          </div>
        </div>
      </SlideOver>

      <div className="flex justify-between w-full border-b border-gray-100 pb-4 pt-3">
        <div className="flex space-x-1">
          <div className="space-y-1">
            <div className="space-y-1">
              <p className="block text-sm font-semibold text-gray-700">{commentBan?.subscription?.email}</p>
            </div>
            <p className="block text-xs font-light text-gray-700">{commentBan.reason}</p>
            <div className="pt-1">
              {!isBanLifted ? (
                <Badge type="success">
                  <CheckIcon className="w-4 h-4 text-green-800 mr-2" />
                  <span className="capitalize mr-1">{levelText} </span>
                </Badge>
              ) : (
                <Badge type="information">
                  <span className="capitalize mr-1">{levelText}</span>
                </Badge>
              )}
            </div>
          </div>
        </div>

        <EllipsisDropdown portalMountedId={commentBan.id} options={actionOptions} />
      </div>
    </>
  );
};

export default CommentBanItem;
