import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  navGroupId: string;
  onSuccess?: () => void;
}

const useDeleteNavGroup = ({ navGroupId, onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation(
    (idOverride?: string | undefined) => {
      const id = idOverride || navGroupId;
      return api.delete(`/nav_groups/${id}`, { params });
    },
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.message || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
        toast.success('Deleted!');
        onSuccess?.();
      },
    }
  );
};

export default useDeleteNavGroup;
