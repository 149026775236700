import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  formQuestionId: string;
  optionId: string;
  formId: string;
}

const useDeleteFormQuestionOption = ({ formId, optionId, formQuestionId }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    form_id: formId,
    form_question_id: formQuestionId,
    publication_id: currentPublicationId,
  };

  return useMutation(() => api.delete(`/form_question_options/${optionId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.message || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['forms', formId]);
      toast.success('Option Removed!');
    },
  });
};

export default useDeleteFormQuestionOption;
