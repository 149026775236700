import { SettingsNoteLink } from './SettingsNote';

interface Props {
  to: string;
}

const OverrideGlobalSettingNote = ({ to }: Props) => {
  return (
    <p>
      The styling for this feature is pulled in from its <SettingsNoteLink to={to}>global setting</SettingsNoteLink>.
      You can override it for this specific feature.
    </p>
  );
};

export default OverrideGlobalSettingNote;
