import moment from 'moment-mini';

import { ChartCard } from '@/components/Chartsv2';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { TitleSm, Typography } from '@/components/Typography';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Tab } from '@/interfaces/general';
import { useResourceListFilters } from '@/ui/ResourceListFilters';
import TabNavigation from '@/ui/TabNavigation';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import useGiftsByPeriod from '../_hooks/useGiftsByPeriod';

enum SortingOptions {
  PURCHASER_EMAIL = 'purchaser_email',
  RECIPIENT_EMAIL = 'recipient_email',
  TIER_NAME = 'tier_name',
  INTERVAL = 'interval',
  START_AT = 'start_at',
}

const GiftsCard = ({ tabs }: { tabs: Tab[] }) => {
  const { orderBy, direction, handleClickOrderButton } = useResourceListFilters<string, string>({
    defaultFilter: 'all',
    defaultSortOrder: SortingOptions.START_AT,
  });
  // Overview can only be broken down monthly at the moment so filter out the unneeded options
  const excludeOptions = [TimePeriod.LAST_24_HOURS, TimePeriod.LAST_4_WEEKS, TimePeriod.LAST_7_DAYS];
  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: TimePeriod.ALL_TIME, excludeOptions });
  const { data, isLoading, isError } = useGiftsByPeriod({
    period: period as keyof typeof timePeriodLabels,
    orderBy,
    direction,
  });
  const intervalDisplay = (interval: string) => {
    switch (interval) {
      case 'month':
        return 'One Month';
      case 'year':
        return 'One Year';
      case 'one_time':
        return 'Lifetime';
      default:
        return interval;
    }
  };

  return (
    <ChartCard
      title="Gifts History"
      isLoading={isLoading}
      noResults={isError}
      noResultsText=""
      actionChildren={<TimePeriodSelect />}
    >
      <TabNavigation tabs={tabs} variant="tertiary" />
      <div className="mt-6">
        {data?.chart_data ? (
          <Items className="!shadow-none">
            <ItemHeaders>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={SortingOptions.PURCHASER_EMAIL}
                isSorting={orderBy === SortingOptions.PURCHASER_EMAIL}
                currentDirection={direction}
              >
                Purchased By
              </ItemHeader>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={SortingOptions.RECIPIENT_EMAIL}
                isSorting={orderBy === SortingOptions.RECIPIENT_EMAIL}
                currentDirection={direction}
              >
                Recipient
              </ItemHeader>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={SortingOptions.TIER_NAME}
                isSorting={orderBy === SortingOptions.TIER_NAME}
                currentDirection={direction}
              >
                Tier
              </ItemHeader>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={SortingOptions.INTERVAL}
                isSorting={orderBy === SortingOptions.INTERVAL}
                currentDirection={direction}
              >
                Length
              </ItemHeader>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={SortingOptions.START_AT}
                isSorting={orderBy === SortingOptions.START_AT}
                currentDirection={direction}
              >
                Gift Start Date
              </ItemHeader>
            </ItemHeaders>
            <ItemsBody>
              {data.chart_data.map((row) => (
                <ItemRow className="last:border-b-0" key={row.created_at}>
                  <ItemColumn align={AlignType.LEFT}>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {row.purchaser_email}
                    </Typography>
                  </ItemColumn>
                  <ItemColumn align={AlignType.LEFT}>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {row.recipient_email}
                    </Typography>
                  </ItemColumn>
                  <ItemColumn align={AlignType.LEFT}>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {row.tier_name}
                    </Typography>
                  </ItemColumn>
                  <ItemColumn align={AlignType.LEFT}>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {intervalDisplay(row.interval)}
                    </Typography>
                  </ItemColumn>
                  <ItemColumn align={AlignType.LEFT}>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {moment(row.start_at).utc().format('LL')}
                    </Typography>
                  </ItemColumn>
                </ItemRow>
              ))}
            </ItemsBody>
          </Items>
        ) : (
          <div className="h-40 px-4 flex items-center justify-center">
            <TitleSm className="text-sm">No data for the chosen time range</TitleSm>
          </div>
        )}
      </div>
    </ChartCard>
  );
};

export default GiftsCard;
