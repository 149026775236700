export enum AdNetworkCampaignStatus {
  ACTIVE = 'active',
  UPCOMING = 'upcoming',
  PAST = 'past',
}

export enum AdNetworkCampaignPayoutMethod {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export const AdNetworkCampaignPayoutMethodOptions = [
  { value: AdNetworkCampaignPayoutMethod.MANUAL, label: 'Manual' },
  { value: AdNetworkCampaignPayoutMethod.AUTO, label: 'Automated' },
];

export enum AdNetworkCampaignGender {
  NO_PREFERENCE = 'no_preference',
  BALANCED = 'balanced',
  PREDOMINANTLY_MALE = 'predominantly_male',
  PREDOMINANTLY_FEMALE = 'predominantly_female',
}

export const AdNetworkCampaignGenderOptions = [
  { value: AdNetworkCampaignGender.NO_PREFERENCE, label: 'No preference' },
  { value: AdNetworkCampaignGender.BALANCED, label: 'Balanced' },
  { value: AdNetworkCampaignGender.PREDOMINANTLY_MALE, label: 'Predominantly male' },
  { value: AdNetworkCampaignGender.PREDOMINANTLY_FEMALE, label: 'Predominantly female' },
];

export enum AdNetworkCampaignAge {
  NO_PREFERENCE = 'no_preference',
  RANGE_18_24 = 'range_18_24',
  RANGE_25_34 = 'range_25_34',
  RANGE_35_44 = 'range_35_44',
  RANGE_45_55 = 'range_45_55',
  RANGE_55_PLUS = 'range_55_plus',
}

export const AdNetworkCampaignAgeOptions = [
  { value: AdNetworkCampaignAge.NO_PREFERENCE, label: 'No preference' },
  { value: AdNetworkCampaignAge.RANGE_18_24, label: '18-24' },
  { value: AdNetworkCampaignAge.RANGE_25_34, label: '25-34' },
  { value: AdNetworkCampaignAge.RANGE_35_44, label: '35-44' },
  { value: AdNetworkCampaignAge.RANGE_45_55, label: '45-55' },
  { value: AdNetworkCampaignAge.RANGE_55_PLUS, label: '55+' },
];

export enum AdNetworkCampaignHouseholdIncome {
  LESS_THAN_50K = 'less_than_50k',
  RANGE_50K_100K = 'range_50k_100k',
  RANGE_100K_200K = 'range_100k_200k',
  RANGE_200K_300K = 'range_200k_300k',
  RANGE_300K_400K = 'range_300k_400k',
  RANGE_400K_PLUS = 'range_400k_plus',
}

export const AdNetworkCampaignHouseholdIncomeOptions = [
  { value: AdNetworkCampaignHouseholdIncome.LESS_THAN_50K, label: 'Less than $50,000' },
  { value: AdNetworkCampaignHouseholdIncome.RANGE_50K_100K, label: '$50,000 - $100,000' },
  { value: AdNetworkCampaignHouseholdIncome.RANGE_100K_200K, label: '$100,000 - $200,000' },
  { value: AdNetworkCampaignHouseholdIncome.RANGE_200K_300K, label: '$200,000 - $300,000' },
  { value: AdNetworkCampaignHouseholdIncome.RANGE_300K_400K, label: '$300,000 - $400,000' },
  { value: AdNetworkCampaignHouseholdIncome.RANGE_400K_PLUS, label: '$400,000+' },
];

export enum AdNetworkCampaignPromotedItem {
  PRODUCT = 'product',
  SERVICE = 'service',
  KNOWLEDGE_PRODUCT = 'knowledge_product',
  EVENT = 'event',
  NEWSLETTER = 'newsletter',
  OTHER = 'other',
}

export const AdNetworkCampaignPromotedItemOptions = [
  { value: AdNetworkCampaignPromotedItem.PRODUCT, label: 'Product' },
  { value: AdNetworkCampaignPromotedItem.SERVICE, label: 'Service' },
  { value: AdNetworkCampaignPromotedItem.KNOWLEDGE_PRODUCT, label: 'Knowledge product (e.g. whitepaper, content)' },
  { value: AdNetworkCampaignPromotedItem.EVENT, label: 'Event (e.g. webinar, class)' },
  { value: AdNetworkCampaignPromotedItem.NEWSLETTER, label: 'Newsletter' },
  { value: AdNetworkCampaignPromotedItem.OTHER, label: 'Other (please specify)' },
];

export enum AdNetworkCampaignGoal {
  BRAND_AWARENESS = 'brand_awareness',
  ENGAGEMENT = 'engagement',
  THOUGHT_LEADERSHIP = 'thought_leadership',
  TRAFFIC_DRIVING = 'traffic_driving',
  LEAD_GENERATION = 'lead_generation',
  OTHER = 'other',
}

export const AdNetworkCampaignGoalOptions = [
  { value: AdNetworkCampaignGoal.BRAND_AWARENESS, label: 'Brand awareness' },
  { value: AdNetworkCampaignGoal.ENGAGEMENT, label: 'Engagement' },
  { value: AdNetworkCampaignGoal.THOUGHT_LEADERSHIP, label: 'Thought leadership & education' },
  { value: AdNetworkCampaignGoal.TRAFFIC_DRIVING, label: 'Traffic driving' },
  { value: AdNetworkCampaignGoal.LEAD_GENERATION, label: 'Lead generation' },
  { value: AdNetworkCampaignGoal.OTHER, label: 'Other (please specify)' },
];

export enum AdNetworkCampaignGeographicalRequirement {
  US = 'us',
  UK = 'uk',
  AFRICA = 'africa',
  ASIA = 'asia',
  CENTRAL_AMERICA = 'central_america',
  EUROPE = 'europe',
  MIDDLE_EAST = 'middle_east',
  NORTH_AMERICA = 'north_america',
  PACIFIC = 'pacific',
  SOUTH_AMERICA = 'south_america',
  OTHER = 'other',
}

export const AdNetworkCampaignGeographicalRequirementOptions = [
  { value: AdNetworkCampaignGeographicalRequirement.US, label: 'United States' },
  { value: AdNetworkCampaignGeographicalRequirement.UK, label: 'United Kingdom' },
  { value: AdNetworkCampaignGeographicalRequirement.AFRICA, label: 'Africa' },
  { value: AdNetworkCampaignGeographicalRequirement.ASIA, label: 'Asia' },
  { value: AdNetworkCampaignGeographicalRequirement.CENTRAL_AMERICA, label: 'Central America' },
  { value: AdNetworkCampaignGeographicalRequirement.EUROPE, label: 'Europe' },
  { value: AdNetworkCampaignGeographicalRequirement.MIDDLE_EAST, label: 'Middle East' },
  { value: AdNetworkCampaignGeographicalRequirement.NORTH_AMERICA, label: 'North America' },
  { value: AdNetworkCampaignGeographicalRequirement.PACIFIC, label: 'Pacific' },
  { value: AdNetworkCampaignGeographicalRequirement.SOUTH_AMERICA, label: 'South America' },
  { value: AdNetworkCampaignGeographicalRequirement.OTHER, label: 'Other (please specify)' },
];

export interface AdNetworkCampaign {
  id: string;
  advertiser: {
    id: string;
    name: string;
  };
  name: string;
  internal_name: string;
  clicks_goal: number;
  cost_per_click: string;
  cost_per_click_cents: number;
  cost_per_acquisition: string;
  cost_per_acquisition_cents: number;
  total_spend: number;
  total_spend_cents: number;
  window_end_date: string;
  window_start_date: string;
  window_always_on: boolean;
  created_at: string;
  updated_at: string;
  payout_method: AdNetworkCampaignPayoutMethod;
  default_message_to_publisher: string | null;
  expected_clicks?: number;
  approved_click_count: number;
  placed_ad_count: number;
  accepted_ad_count: number;
  pending_ad_count: number;
  ages: AdNetworkCampaignAge[];
  gender: AdNetworkCampaignGender;
  household_incomes: AdNetworkCampaignHouseholdIncome[];
  other_newsletters: string;
  promoted_item: AdNetworkCampaignPromotedItem;
  promoted_item_other: string;
  goal: AdNetworkCampaignGoal;
  goal_other: string;
  tags: string[];
  geographical_requirements: string[];
  geographical_requirements_other: string;
}
