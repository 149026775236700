import { useNavigate } from 'react-router-dom';
import { BanknotesIcon } from '@heroicons/react/20/solid';

import { EmptyCard } from '@/components/ResourceList';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import usePrimaryOffer from '@/hooks/boosts/grow/usePrimaryOffer';
import { BoostOfferStatus } from '@/interfaces/boosts/grow/boost_offer';

import RecommendingPublicationsList from '../../Shared/RecommendingPublicationsList';

import PrimaryOfferCard from './PrimaryOfferCard';

const Overview = () => {
  const { data: primaryOffer } = usePrimaryOffer();
  const navigate = useNavigate();
  const [currentPublicationId] = useCurrentPublicationState();
  const handleClickPrimaryAction = () => navigate('/grow/boosts/new');

  const offer = primaryOffer?.status === BoostOfferStatus.LIVE && primaryOffer ? primaryOffer : undefined;

  return (
    <>
      {offer && (
        <>
          <PrimaryOfferCard offer={offer} />
          <RecommendingPublicationsList publicationId={currentPublicationId} />
        </>
      )}
      {!offer && (
        <EmptyCard
          title="No Offer Created"
          description="Your Offer is what other publishers will see in the beehiiv Boost Marketplace."
          primaryIcon={BanknotesIcon}
          primaryActionLabel="New Offer"
          onPrimaryActionClick={handleClickPrimaryAction}
        />
      )}
    </>
  );
};

export default Overview;
