import { useCallback } from 'react';
import { useEditorState } from '@tiptap/react';

import { Button } from '@/routes/website/_components/UI/Button';

import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AdvancedWidthSettings } from '../AdvancedWidthSettings';
import { AlignmentSettings } from '../AlignmentSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { FontSizeSettings } from '../FontSizeSettings';
import { FontStyleSettings } from '../FontStyleSettings';
import { FormatSettings } from '../FormatSettings';
import { HighlightSettings } from '../HighlightSettings';
import { LinkToSettings } from '../LinkToSettings';
import { NewTabSettings } from '../NewTabSettings';

export const TypographySettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const hasLink = useEditorState({
    editor,
    selector: ({ editor: e }) => e.isActive('link'),
  });

  const onRemoveLink = useCallback(() => editor.chain().focus().unsetLink().run(), [editor]);
  const onAddLink = useCallback(() => editor.chain().focus().setLink({ href: '' }).run(), [editor]);

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['paragraph', 'heading']}>
      <SectionRenderer title="Typography">
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <FormatSettings editor={editor} />
        <FontSettings editor={editor} activeNodeResult={activeNodeResult} />

        <FontStyleSettings editor={editor} />
        <FontSizeSettings editor={editor} activeNodeResult={activeNodeResult} />
        <AdvancedWidthSettings editor={editor} activeNodeResult={activeNodeResult} property="width" />
        <ColorSettings
          title="Text"
          property="color"
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle
          hasGradientPicker
          isFontGradient
        />
        <HighlightSettings editor={editor} />
      </SectionRenderer>

      <SectionRenderer
        title="Link"
        actions={
          <Button variant="ghost" size="sm" className="text-wb-accent" onClick={hasLink ? onRemoveLink : onAddLink}>
            {hasLink ? 'Remove' : 'Add'}
          </Button>
        }
        collapsible={hasLink}
      >
        <LinkToSettings editor={editor} activeNodeResult={activeNodeResult} />
        <NewTabSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
