import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Browser, DotsThree, Hash, House, SquaresFour } from '@phosphor-icons/react';

import { useWebsitePageRouteGetter } from '@/context/website-context';
import { Page } from '@/interfaces/dream_builder/page';

import { Button } from '../UI/Button';
import { Text } from '../UI/Text';

import { PageOption } from './PageOption';

interface Props {
  page: Page;
}

export const PageCard = ({ page }: Props) => {
  const [searchParams] = useSearchParams();
  const path = searchParams.get('path');

  const pageRouteGetter = useWebsitePageRouteGetter();
  const route = pageRouteGetter?.getPageRouteFromID(page.id)?.route;
  const pagePath = pageRouteGetter?.getPageRouteFromID(page.id)?.path;
  const isDefaultPage = pageRouteGetter?.isDefaultPage(page.id);

  const isHome = pagePath?.length === 0;
  const isParentPage = path === pagePath?.join('/');
  const hasChildren = (route?.children_keys?.length || 0) > 0;
  const url = pagePath?.join('/') || ''; // TODO: @putrikarunia attach hostname to beginning
  const canClickToSeeChildren = !isHome && hasChildren && path !== url;

  const Icon = useMemo(() => {
    if (isHome) {
      return House;
    }
    if (isParentPage) {
      return Hash;
    }
    if (hasChildren) {
      return SquaresFour;
    }
    return Browser;
  }, [isHome, isParentPage, hasChildren]);

  return (
    <Link
      className="flex flex-col group"
      to={
        canClickToSeeChildren
          ? `/website_builder_v2?path=${encodeURIComponent(url)}`
          : `/website_builder_v2/page/${page.id}`
      }
    >
      <div className="cursor-pointer p-4 overflow-hidden bg-neutral-50 dark:bg-neutral-900 border border-solid border-wb-primary shadow-wb-md aspect-video rounded-xl h-[150px] relative">
        <div className="overflow-hidden bg-wb-primary shadow-wb-xl rounded-md h-[200px] border border-solid border-wb-primary">
          <div className="w-[800%] scale-[0.125] h-[800%] overflow-hidden origin-top-left">
            {/* TODO: @putrikarunia render the actual page preview */}
            {/* <iframe src="https://beehiiv.com" className="w-full h-full" title={page?.draft_page_version?.name} /> */}
            {/* this iframe was causing a recursive loop */}
            <div className="absolute top-0 left-0 w-full h-full cursor-pointer" />
          </div>
        </div>
        {hasChildren && !isHome && !isParentPage && (
          <div className="px-2 py-1 border border-solid border-wb-primary rounded-md bg-wb-primary shadow-wb-lg absolute top-2 right-2 z-2">
            <Text size="xs" weight="semibold">
              {route?.children_keys?.length} Subpages
            </Text>
          </div>
        )}
      </div>
      <div className="flex items-start gap-2 py-3">
        <Icon className="text-wb-secondary h-5 w-5" />

        <div className="flex flex-col flex-1 text-ellipsis overflow-hidden">
          <Text size="sm" weight="semibold" as="p" className="truncate">
            {page?.draft_page_version?.name}
          </Text>
          <Text size="xs" variant="secondary" weight="medium" as="p" className="truncate">
            {url}
          </Text>
        </div>

        <PageOption align="end" pageId={page.id} isDefaultPage={isDefaultPage || false} isHome={isHome}>
          <Button
            variant="secondary"
            size="sm"
            Icon={DotsThree}
            iconClassName="h-5 w-5 text-wb-secondary"
            className="p-1.5 opacity-0 group-hover:opacity-100 data-[state=open]:opacity-100 transition-all"
          />
        </PageOption>
      </div>
    </Link>
  );
};
