import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { AdNetworkOpportunity } from '../../../interfaces/ad_network/publisher/opportunity';
import api from '../../../services/swarm';

export default function useOpportunity({ id }: { id: string }) {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<AdNetworkOpportunity>(
    [publicationId, 'ad_network', 'publisher', 'opportunities', id],
    () =>
      api
        .get(`/ad_network/publisher/opportunities/${id}`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
