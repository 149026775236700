import React, { useState } from 'react';
import { getText } from '@tiptap/core';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';

import { HtmlSnippetMenu } from '../menus';

interface Props {
  editor: any;
  node: any;
  extension: any;
  getPos: any;
}

export const HtmlSnippetView = ({ editor, node, extension, getPos }: Props) => {
  const [showPreview, setShowPreview] = useState(false);
  const html = getText(node);

  return (
    <NodeViewWrapper
      data-drag-handle
      className={showPreview ? 'is-preview' : ''}
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <div {...{ inert: editor.isEditable ? undefined : '' }}>
        <HtmlSnippetMenu
          editor={editor}
          getPos={getPos}
          showHtmlPreview={(state: boolean) => {
            setShowPreview(state);
          }}
          isHtmlPreviewActive={showPreview}
        >
          {showPreview ? (
            <div
              data-type={extension.name}
              contentEditable={false}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: html || '&nbsp;' }}
            />
          ) : (
            <pre data-type={extension.name}>
              <NodeViewContent as="code" className={`${extension.options.languageClassPrefix}${node.attrs.language}`} />
            </pre>
          )}
        </HtmlSnippetMenu>
        <span contentEditable={false} className="html-snippet-label">
          HTML Snippet
        </span>
      </div>
    </NodeViewWrapper>
  );
};

export default HtmlSnippetView;
