import { useState } from 'react';
import { Color } from '@tremor/react';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import LoadingBox from '@/components/LoadingBox';
import { useSettings } from '@/context/settings-context';

import Chart from './Chart';
import PostOpensContainer from './PostOpensContainer';

interface OpensChartProps {
  postId: string;
}

const CHART_COLORS: Color[] = ['violet', 'gray'];

const getSuffix = (index: number) => {
  switch (index) {
    case 0:
      return 'th';
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    case 4:
      return 'th';
    default:
      return 'th';
  }
};

const getChartLabel = (index: number) => {
  if (index === 0) {
    return 'Sent';
  }

  return `${index}${getSuffix(index)} hour`;
};

const OpensChart: React.FC<OpensChartProps> = ({ postId }: OpensChartProps) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const [showMore, setShowMore] = useState(false);

  if (isV2) {
    return (
      <PostOpensContainer postId={postId} showMore={showMore}>
        {(chartData, isLoading) => {
          const mappedChartData = (() => {
            if (isLoading || typeof chartData !== 'object') {
              return [];
            }

            return chartData.datasets[0].data.map((row, index) => ({
              date: getChartLabel(index),
              'Unique Opens': row,
            }));
          })();

          return (
            <ChartCard
              className="space-y-2"
              noResultsText={isLoading ? 'Fetching...' : 'No data available yet'}
              noResults={mappedChartData.length === 0}
            >
              <AreaChart
                index="date"
                data={mappedChartData}
                categories={['Unique Opens']}
                colors={CHART_COLORS}
                showLegend={false}
                showXAxis
                showYAxis
                showGridLines
              />
            </ChartCard>
          );
        }}
      </PostOpensContainer>
    );
  }

  return (
    <PostOpensContainer postId={postId} showMore={showMore}>
      {(chartData, isLoading, isError) => (
        <LoadingBox isLoading={isLoading} isError={isError} height="400px">
          <>
            <Chart data={chartData} />
            <div>
              <button
                type="button"
                onClick={() => setShowMore(!showMore)}
                className="text-sm text-gray-500 hover:text-gray-700"
              >
                {showMore ? 'Show less' : 'Show more'}
              </button>
            </div>
          </>
        </LoadingBox>
      )}
    </PostOpensContainer>
  );
};

export default OpensChart;
