import { QueryParamConfig } from 'use-query-params';

import { EmailMessageStatsOverview } from './email_message';

export interface PollChoice {
  id: string;
  label: string;
}

export enum PollOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum PollListStyle {
  DISC = 'disc',
  DECIMAL = 'decimal',
  NONE = 'none',
}

export enum PollType {
  VOTING = 'voting',
  TRIVIA = 'trivia',
}

export enum PollStyle {
  DEFAULT = 'default',
  BULLET_POINTS = 'bullet_points',
  NUMBERED_LIST = 'numbered_list',
  UNSTYLED = 'unstyled',
  INLINE = 'inline',
}

export interface PollMetrics {
  [key: string]: number;
}

export interface Poll {
  id: string;
  name: string;
  question: string;
  description?: string;
  redirect_url?: string;
  is_locked: boolean;
  created_at: string;
  updated_at: string;
  poll_choices: PollChoice[];
  completions: number;
  appearances: number;
  status: string;
  orientation: PollOrientation;
  poll_type: PollType;
  poll_style: PollStyle;
  list_style: PollListStyle;
  metrics: PollMetrics;
  live_results_enabled: boolean;
  notes: string;
  most_recent_response: string;
  trivia_answer: string;
  extended_feedback_enabled: boolean;
}

export type PollFormQueryParams = {
  search: QueryParamConfig<string>;
  order: QueryParamConfig<string>;
  dir: QueryParamConfig<string>;
  filteredPost: QueryParamConfig<string>;
  filterByDateCreated: QueryParamConfig<string>;
  filterByDatePublished: QueryParamConfig<string>;
};

export const PollStatuses: any = {
  draft: 'draft',
  published: 'published',
};

export interface PollResourcePreview {
  id: string;
  title: string;
  subtitle: string;
  stats_overview: EmailMessageStatsOverview | null;
  send_timestamp: string;
  redirect_path: string;
  purpose: string | null;
}
