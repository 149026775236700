import { Grid } from '@tremor/react';

import { useSettings } from '@/context/settings-context';
import usePublicationInsights from '@/hooks/boosts/monetize/usePublicationInsights';
import useTopLineMetrics from '@/hooks/boosts/monetize/useTopLineMetrics';
import { MetricCard } from '@/ui/MetricCard';

interface Props {
  boostedPublicationId?: string;
}

const BoostsTopLineMetrics = ({ boostedPublicationId }: Props) => {
  const { settings } = useSettings();
  const { data: topLineMetricsData } = useTopLineMetrics({ boostedPublicationId });
  const { data: publicationInsightsData } = usePublicationInsights({ boostedPublicationId });
  const totalSubscribers = topLineMetricsData?.total_subscribers.toLocaleString() || '-';
  const verifiedSubscribers = topLineMetricsData?.total_accepted_subscribers.toLocaleString() || '-';
  const pendingSubscribers = topLineMetricsData?.total_pending_subscribers.toLocaleString() || '-';
  const pendingPayments = topLineMetricsData?.total_pending_payments || '-';
  const totalPayments = topLineMetricsData?.total_payments || '-';
  const avgCPA = topLineMetricsData?.average_cpa || '-';
  const avgRevenuePerSubscriber = topLineMetricsData?.average_payment_per_subscriber || '-';
  const acceptanceRate = topLineMetricsData?.acceptance_rate ? `${topLineMetricsData?.acceptance_rate}%` : '-';
  const pendingWebBoosts = publicationInsightsData?.total_pending_boost_agreements || '-';
  const activeWebBoosts = publicationInsightsData?.total_active_boost_agreements || 0;
  const pendingEmailBoosts = publicationInsightsData?.total_pending_boost_sends || '-';
  const activeEmailBoosts = publicationInsightsData?.total_active_boost_sends || 0;
  const activeBoostsCount = activeWebBoosts + activeEmailBoosts;
  const hasBoostsPlus = settings?.boosts_plus === true;

  const applicationMetricComplements = [{ label: 'Pending Web Boosts', value: pendingWebBoosts }];
  if (hasBoostsPlus) {
    applicationMetricComplements.push({ label: 'Pending Email Boosts', value: pendingEmailBoosts });
  }

  return (
    <Grid numCols={1} numColsSm={4} className="gap-4">
      <MetricCard
        variant="primary"
        label="Total Revenue"
        value={totalPayments}
        complements={[
          { label: 'Pending revenue', value: pendingPayments },
          { label: 'Revenue per subscriber', value: avgRevenuePerSubscriber },
        ]}
      />
      <MetricCard
        variant="primary"
        label="Verified Subscribers"
        value={verifiedSubscribers}
        complements={[
          { label: 'Pending subscribers', value: pendingSubscribers },
          { label: 'Total subscribers', value: totalSubscribers },
        ]}
      />
      <MetricCard
        variant="primary"
        label="Active Boosts"
        value={activeBoostsCount.toLocaleString()}
        complements={[{ label: 'Avg. CPA', value: avgCPA }]}
      />
      <MetricCard
        variant="primary"
        label="Verification Rate"
        tooltip="The percentage of signups that have passed our verification process."
        value={acceptanceRate}
        complements={applicationMetricComplements}
      />
    </Grid>
  );
};

export default BoostsTopLineMetrics;
