import { DevicePhoneMobileIcon } from '@heroicons/react/20/solid';

import { useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { SettingsContainer, SettingsGroup, SettingsLoader } from '@/ui/Settings/SettingsContainer';
import SwitchDescriptionInput from '@/ui/Settings/SwitchDescriptionInput';

const WebBuilderMobileApp = () => {
  const webTemplateMutation = useUpdateWebTemplate();
  const { data: webTemplate } = useWebTemplate();

  return (
    <SettingsContainer>
      <SettingsGroup
        title="Mobile App"
        description="Allow your subscribers to install a mobile app directly onto their Home Screen"
      >
        {!webTemplate ? (
          <SettingsLoader />
        ) : (
          <SwitchDescriptionInput
            title="Enable Mobile App"
            name="enable_mobile_app"
            value={webTemplate?.pwa_enabled || false}
            onSave={(val: boolean) => webTemplateMutation.mutateAsync({ pwa_enabled: val })}
            description={
              <div className="space-y-2">
                <p>
                  Enable mobile app installation for your subscribers. This will allow your subscribers to download a
                  native mobile app directly onto their Home Screen and benefit from features like longer login
                  sessions, push notifications, and more.
                </p>
              </div>
            }
            Icon={DevicePhoneMobileIcon}
            toastSuccessMessage="Saved!"
          />
        )}
      </SettingsGroup>
    </SettingsContainer>
  );
};

export default WebBuilderMobileApp;
