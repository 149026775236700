import useLocalStorage from './useLocalStorage';

export enum DismissableKey {
  HELPER_BLOCK_BOOSTS_APPLICATIONS = 'helper_block_boosts_applications',
  INTRODUCTION_BLOCK_GROW_VIA_BOOSTS = 'introduction_block_grow_via_boosts',
  POSTS_RECIPIENTS_TOTAL_CLICKS_INFO = 'posts_recipients_total_clicks_info',
}

export default function useDismissable(key: DismissableKey) {
  const [state, setState] = useLocalStorage<boolean>(false, key);

  return {
    wasDismissed: state === true,
    onDismiss: () => setState(true),
  };
}
