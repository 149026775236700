import { PostTargetPlatform } from '@/interfaces/post_target';

import usePostTargetsAudiences from './usePostTargetsAudiences';

const usePostTargetsDisplay = (postId: string, platform: PostTargetPlatform) => {
  const audiences = usePostTargetsAudiences(postId, platform);

  if (audiences.excludedAudiences.length === 0 && audiences.includedAudiences.length === 0) {
    return '-';
  }

  if (audiences.excludedAudiences.length === 0)
    return audiences.includedAudiences.map((audience) => audience.receiver_display_name).join(', ');

  return `${audiences.includedAudiences
    .map((audience) => audience.receiver_display_name)
    .join(', ')} excluding ${audiences.excludedAudiences.map((audience) => audience.receiver_display_name).join(', ')}`;
};

export default usePostTargetsDisplay;
