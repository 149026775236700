import { PropsWithChildren, useEffect, useState } from 'react';

import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';

export type Props = PropsWithChildren<{
  label: string;
  name?: string;
  placeholderText?: string;
  helperText?: string;
  value: string;
  onSave: (value: string) => void;
}>;

const TextInput = ({ label, value, onSave, name = '', placeholderText, helperText }: Props) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    if (!text && value) setText(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="space-y-1 flex flex-col">
      <Typography token="font-normal/text/xs" colorWeight="500">
        {label}
      </Typography>
      <Input
        inputClassOverride="appearance-none !text-xs block w-full px-3 py-2 border hover:border-surface-500 rounded-md placeholder-gray-400 focus:outline-none focus:ring-transparent focus:border-surface-900 sm:text-sm h-full disabled:cursor-not-allowed"
        name={name}
        value={text}
        placeholder={placeholderText}
        helperText={helperText}
        onChange={(e) => setText(e.target.value)}
        onBlur={() => onSave(text)}
      />
    </div>
  );
};

export default TextInput;
