import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostInvite } from '@/interfaces/boosts/grow/boost_invite';
import api from '@/services/swarm';

interface ApiResponse {
  boost_invite: BoostInvite;
}

interface Arguments {
  onSuccess: () => void;
}

interface Payload {
  boostOfferId: string;
  recommendingPublicationId: string;
  inviteReason: string;
}

const useAddBoostInvite = ({ onSuccess }: Arguments) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, Payload>(
    ({ boostOfferId, recommendingPublicationId, inviteReason }) =>
      api
        .post('/boosts/grow/boost_invites', {
          boost_invite: {
            recommending_publication_id: recommendingPublicationId,
            invite_reason: inviteReason,
          },
          boost_offer_id: boostOfferId,
          publication_id: currentPublicationId,
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useAddBoostInvite;
