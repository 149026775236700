import React, { createContext, useMemo } from 'react';
import { captureException } from '@sentry/react';

import useIsSettingPage from '@/hooks/useIsSettingPage';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { Settings } from '@/interfaces/setting';

import { useCurrentPublicationState } from './current-publication-context';
import { useCurrentUser } from './current-user-context';

interface ISettingsContext {
  settings?: Settings;
  isLoading: boolean;
  isError: boolean;
  reloadSettings: () => void;
}

const SettingsContext = createContext<ISettingsContext | undefined>(undefined);

SettingsContext.displayName = 'SettingsContext';

const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const [publicationId] = useCurrentPublicationState(false);

  const settingsQuery = usePublicationSettings(publicationId);

  const { data: settings, isLoading, isError } = settingsQuery;

  const contextProviderProps = useMemo(() => {
    const reloadSettings = () => settingsQuery.refetch();

    return { reloadSettings, settings, isLoading, isError };
  }, [isError, isLoading, settings, settingsQuery]);

  return <SettingsContext.Provider value={contextProviderProps}>{children}</SettingsContext.Provider>;
};

// Within settings pages (v2) we always fetch settings for the publication
// the user is looking at using the hook
// const { data: settings } = usePublicationSettings(currentPublicationId);
// To get the current publication id within settings use;
// const currentPublicationId = useCurrentPublicationId();
function useSettings(reportIllegalUse: boolean = true) {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }

  const isASettingPage = useIsSettingPage();
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;

  if (isASettingPage && hasSettingsV2 && reportIllegalUse) {
    const error = new Error(
      `ILLEGAL_USE_OF_HOOK: useSettings is being used on page ${window.location.href}, please use usePublicationSettings(publicationId) hook instead`
    );

    if (process.env.NODE_ENV === 'development') {
      throw error;
    } else {
      captureException(error);
    }
  }

  return context;
}

export { SettingsContext, SettingsProvider, useSettings };
