import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClockCounterClockwise, Eye, Plus } from '@phosphor-icons/react';

import { DreamEditorProvider, useDreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext, useWebsitePageRouteGetter, WebsiteProvider } from '@/context/website-context';
import { cn } from '@/utils/cn';

import { AttributesPanelNavbarFooterContextProvider } from '../_components/DreamEditor/AttributesPanel/navbarFooterContextProvider';
import { Layout } from '../_components/Layout';
import { ActionMenu } from '../_components/Layout/ActionMenu';
import AttributesPanelPortal from '../_components/Layout/AttributesPanelPortal';
import InsertPanelPortal from '../_components/Layout/InsertPanelPortal';
import SidePanelTabs from '../_components/Layout/SidePanelTabs';
import VersionHistoryPortal from '../_components/Layout/VersionHistoryPortal';
import { NavSection } from '../_components/SideNav/NavSection';
import { SavePageIndicator } from '../_components/SideNav/SaveIndicator';
import { Button } from '../_components/UI/Button';
import { Tooltip } from '../_components/UI/Tooltip';
import VersionHistoryNotice from '../_components/VersionHistoryNotice';
import { FooterPreview } from '../footer/_components/FooterEditor';
import { NavbarPreview } from '../navbar/_components/NavbarEditor/NavbarPreview';

import { Editor } from './_components/Editor';
import IframeWrapper from './_components/Iframe';
import PreviewModal from './_components/Preview';
import { useEditorFontHrefs } from './_hooks/useEditorFontHrefs';
import { useContainerRef } from './_hooks';
import { VIEWPORTS } from './constants';
import type { Viewport } from './types';

const PageEditorPage = () => {
  const { pagesRoutes, defaultRoutes, previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const navigate = useNavigate();
  const [viewport] = useState<Viewport>(VIEWPORTS[0]);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);
  const { pageId } = useParams();
  const pageRouteGetter = useWebsitePageRouteGetter();
  const pageRoute = pageId ? pageRouteGetter?.getPageRouteFromID(pageId) : undefined;
  const homePageId = pageRouteGetter?.getHomePageID();
  const { editor } = useDreamEditorContext();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  const { rect: editorRect, containerRef: editorContainerRef } = useContainerRef();

  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion]);

  useEffect(() => {
    if (pageId && homePageId && !pageRoute) {
      navigate(`/website_builder_v2/page/${homePageId}`);
    }
  }, [pageId, navigate, homePageId, pageRoute]);

  const insertPanelRef = useRef<HTMLDivElement>(null);
  const insertPanelTriggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    editor?.on('focus', () => {
      setIsInsertPanelOpen(false);
    });
  }, [editor]);

  useEffect(() => {
    if (!isInsertPanelOpen) return () => {};
    function handleClickOutside(event: MouseEvent) {
      if (
        insertPanelRef.current &&
        !insertPanelRef.current.contains(event.target as Node) &&
        insertPanelTriggerRef.current &&
        !insertPanelTriggerRef.current.contains(event.target as Node)
      ) {
        // close insert panel
        setIsInsertPanelOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isInsertPanelOpen, insertPanelRef, insertPanelTriggerRef]);

  const fontHrefs = useEditorFontHrefs();

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary"
      isSidebarResizable
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
          ref={insertPanelTriggerRef}
        />
      }
      rightChildren={
        <>
          <Tooltip center="Version History" delay={300}>
            <Button
              variant="secondary"
              Icon={ClockCounterClockwise}
              iconWeight="bold"
              onClick={() => setIsVersionHistoryPanelOpen((prev) => !prev)}
              className="h-full"
            />
          </Tooltip>
          <Tooltip center="Preview" delay={300}>
            <Button
              variant="secondary"
              Icon={Eye}
              iconWeight="fill"
              className="h-full"
              onClick={() => setIsPreviewModalOpen((prev) => !prev)}
            />
          </Tooltip>
        </>
      }
      sidenavChildren={
        <>
          <SidePanelTabs
            pagesRoutes={pagesRoutes}
            defaultRoutes={defaultRoutes}
            previewSiteVersion={previewSiteVersion}
          />
          <InsertPanelPortal isInsertPanelOpen={isInsertPanelOpen} ref={insertPanelRef} />
          {!previewSiteVersion && (
            <NavSection>
              <SavePageIndicator />
            </NavSection>
          )}
        </>
      }
      rightSideChildren={
        <>
          <AttributesPanelPortal />
          <VersionHistoryPortal
            isVersionHistoryPanelOpen={isVersionHistoryPanelOpen}
            setIsVersionHistoryPanelOpen={setIsVersionHistoryPanelOpen}
          />
        </>
      }
      titleType="page_name"
      onContainerClick={() => {
        editor?.commands.focusDoc();
      }}
    >
      <PreviewModal isOpen={isPreviewModalOpen} setIsOpen={setIsPreviewModalOpen} />
      <div
        ref={editorContainerRef}
        className={cn('h-full shadow-md bg-wb-primary', {
          'w-[375px] m-auto': viewport.type === 'mobile',
          'w-full': viewport.type === 'desktop',
        })}
      >
        <IframeWrapper
          width={editorRect.width || 0}
          height={editorRect.height || 0}
          ref={iframeRef}
          contentWidth={viewport.width}
          fontHrefs={fontHrefs}
        >
          <NavbarPreview
            portalRef={portalRef}
            iframeRef={iframeRef}
            onFocus={() => {
              setIsInsertPanelOpen(false);
            }}
          />
          <Editor key={pageId} />
          <FooterPreview
            iframeRef={iframeRef}
            onFocus={() => {
              setIsInsertPanelOpen(false);
            }}
          />
          <div ref={portalRef} id="portal-ref" className="z-50" />
        </IframeWrapper>
      </div>
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <DreamEditorProvider>
      <AttributesPanelNavbarFooterContextProvider>
        <PageEditorPage />
      </AttributesPanelNavbarFooterContextProvider>
    </DreamEditorProvider>
  </WebsiteProvider>
);
