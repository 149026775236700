import { ChangeEvent, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import MediaLibrary from '@/components/MediaLibrary/MediaLibrary';
import { Asset } from '@/interfaces/asset';

import { usePublicationContext } from '../../../lib/context/PublicationContext';
import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { IconProps } from '../../ui/Icon/types';
import { Panel } from '../../ui/Panel';
import { Tooltip } from '../../ui/Tooltip';

const Styled = {
  FileInput: styled.input`
    height: 0;
    overflow: hidden;
    width: 0;
  `,
};

export interface UpdateImagePanelProps {
  tooltip: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  onUpload: (file: File) => void;
  onReplace: (asset: Asset) => void;
  onRemove?: () => void;
  showRemoveOption?: boolean;
  offset?: [number, number];
  iconName?: IconProps['name'];
}

type PanelContentProps = {
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onShowLibrary: () => void;
  onRemove?: () => void;
  showRemoveOption?: boolean;
};

const PanelContent = forwardRef<HTMLDivElement, PanelContentProps>(
  ({ onFileChange, onShowLibrary, onRemove, showRemoveOption = false }, ref) => {
    const fileRef = useRef<HTMLInputElement>(null);

    const handleFileClick = useCallback(() => {
      if (fileRef.current) {
        fileRef.current.click();
      }
    }, []);

    return (
      <Panel ref={ref}>
        <Styled.FileInput onChange={onFileChange} type="file" accept=".jpg,.jpeg,.png,.webp,.gif" ref={fileRef} />
        <Button
          $size="small"
          $variant="quaternary"
          $fullWidth
          $leftSlot={<Icon name="Upload" />}
          onClick={handleFileClick}
        >
          Upload a new image
        </Button>
        <Button
          onClick={onShowLibrary}
          $size="small"
          $variant="quaternary"
          $fullWidth
          $leftSlot={<Icon name="Library" />}
        >
          Replace from image library
        </Button>
        {showRemoveOption && (
          <Button
            onClick={() => onRemove?.()}
            $size="small"
            $variant="quaternary"
            $fullWidth
            $leftSlot={<Icon name="Cancel" />}
          >
            Remove Thumbnail
          </Button>
        )}
      </Panel>
    );
  }
);

export const UpdateImagePanel = ({
  tooltip,
  parentRef,
  onUpload,
  onReplace,
  showRemoveOption,
  onRemove,
  offset,
  iconName,
}: UpdateImagePanelProps) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const { publicationId } = usePublicationContext();

  const togglePanel = useCallback(() => {
    setPanelOpen(!panelOpen);
  }, [panelOpen]);

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPanelOpen(false);
      if (e.target.files) {
        onUpload(e.target.files[0]);
      }
    },
    [onUpload]
  );

  useEffect(() => {
    if (panelOpen) {
      const outsideClickHandler = (e: Event) => {
        if (
          buttonRef.current &&
          panelRef.current &&
          !buttonRef.current.contains(e.target as HTMLElement) &&
          !panelRef.current.contains(e.target as HTMLElement)
        ) {
          setPanelOpen(false);
        }
      };

      document.addEventListener('click', outsideClickHandler);
      return () => {
        document.removeEventListener('click', outsideClickHandler);
      };
    }

    return () => {};
  }, [panelOpen]);

  return (
    <>
      <MediaLibrary
        onClose={() => setShowImageLibrary(false)}
        isOpen={showImageLibrary}
        onMediaSelect={(payload) => {
          onReplace(payload.media);
          setShowImageLibrary(false);
        }}
        publicationId={publicationId}
      />
      <Tippy
        render={() =>
          panelOpen && (
            <PanelContent
              ref={panelRef}
              onFileChange={handleFileChange}
              onShowLibrary={() => setShowImageLibrary(true)}
              showRemoveOption={showRemoveOption}
              onRemove={onRemove}
            />
          )
        }
        reference={parentRef?.current}
        visible={panelOpen}
        offset={offset || [0, 4]}
        placement="bottom-start"
        interactive
      >
        <div>
          <Tooltip title={tooltip}>
            <Button
              ref={buttonRef}
              $variant="quaternary"
              $size="small"
              $leftSlot={<Icon name={iconName || 'Refresh'} />}
              onClick={togglePanel}
              $active={panelOpen}
              $muted={panelOpen}
              $isIconButton
            />
          </Tooltip>
        </div>
      </Tippy>
    </>
  );
};

export default UpdateImagePanel;
