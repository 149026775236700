import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FileArrowDown, Trash } from '@phosphor-icons/react';
import { NodeSelection } from '@tiptap/pm/state';

import { Button } from '../../../../../UI/Button';
import { Text } from '../../../../../UI/Text';
import { Tooltip } from '../../../../../UI/Tooltip';
import { MultiNodeSelection } from '../../../../extensions/CustomSelections/selections';
import { TemplateModal } from '../../../../Templates';
import { AttributeSettingProps } from '../../../types';

import ThemeSettings from './ThemeSettings';

const ActionsSettings = ({
  editor,
  activeNodeResult,
  isDeletable = true,
  isFooter = false,
}: AttributeSettingProps & {
  isFooter?: boolean;
}) => {
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  const isSaveTemplateDisabled = useMemo(() => {
    if (!activeNodeResult?.activeNodeType) return false;

    if (['container', 'column', 'columns'].includes(activeNodeResult.activeNodeType)) {
      return true;
    }
    return false;
  }, [activeNodeResult?.activeNodeType]);

  const removeInProgress = useRef(false);

  useEffect(() => {
    removeInProgress.current = false;
  }, [activeNodeResult]);

  const onRemove = useCallback(() => {
    if (removeInProgress.current) return;

    removeInProgress.current = true;

    try {
      const { selection, doc } = editor.state;

      if (selection instanceof MultiNodeSelection || selection instanceof NodeSelection) {
        editor.chain().deleteSelection().focus().run();
        return;
      }

      const { activeNode, activeNodePos, activeNodeEnd } = activeNodeResult;

      if (
        !activeNode ||
        typeof activeNodePos !== 'number' ||
        typeof activeNodeEnd !== 'number' ||
        activeNodePos === -1 ||
        activeNodeEnd === -1
      ) {
        return;
      }

      editor
        .chain()
        .deleteRange({ from: activeNodePos, to: activeNodeEnd >= doc.nodeSize ? doc.nodeSize : activeNodeEnd })
        .focus()
        .run();
    } finally {
      setTimeout(() => {
        removeInProgress.current = false;
      }, 1000);
    }
  }, [editor, activeNodeResult]);

  const onTemplate = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setTemplateModalOpen(true);
    },
    [setTemplateModalOpen]
  );

  return (
    <div className="flex gap-2 justify-between">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Actions
      </Text>

      <div className="flex justify-end gap-2">
        <ThemeSettings editor={editor} activeNodeResult={activeNodeResult} />
        <Tooltip
          center="Save as Template"
          right={isSaveTemplateDisabled ? 'Only Section & Block templates are supported.' : null}
          delay={300}
        >
          <Button
            variant="secondary"
            size="sm"
            LeftIcon={FileArrowDown}
            className="text-wb-secondary"
            onClick={onTemplate}
            isDisabled={isSaveTemplateDisabled}
          />
        </Tooltip>
        {activeNodeResult.activeNode && (
          <TemplateModal
            isOpen={templateModalOpen}
            onClose={() => setTemplateModalOpen(false)}
            activeNodeResult={activeNodeResult}
            editor={editor}
            isFooter={isFooter}
          />
        )}
        <Tooltip center={isDeletable ? 'Delete' : 'You can not delete the element'} delay={300}>
          <Button
            isDisabled={!isDeletable}
            variant="secondary"
            size="sm"
            LeftIcon={Trash}
            className="text-wb-secondary"
            onClick={onRemove}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ActionsSettings;
