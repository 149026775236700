import { useParams } from 'react-router-dom';

import { useAuth } from '@/context/auth-context';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePost } from '@/hooks';
import Loading from '@/pages/Loading';
import NoPermission from '@/pages/NoPermission';

type ResourceType = 'post';

type Props = {
  redirectTo: string;
  resourceType: ResourceType;
  children: JSX.Element;
};

const ASSERTABLE_RESOURCES: Record<ResourceType, boolean> = {
  post: true,
};

const ERROR_TOAST_MESSAGE_BY_RESOURCE_TYPE: Record<ResourceType, string> = {
  post: 'You do not have permission to access the post, please try switching publications',
};

const RequireAccessToResource = ({ redirectTo, children, resourceType }: Props): JSX.Element => {
  const { isMasquerading } = useAuth();
  const [currentPublicationId] = useCurrentPublicationState();
  const isAssertionRequired = ASSERTABLE_RESOURCES[resourceType];

  const { postId } = useParams<{
    postId: string;
  }>();
  const { data: postData, isLoading: isPostLoading } = usePost({ id: postId, enabled: isAssertionRequired });

  if (isMasquerading || !isAssertionRequired) {
    return children;
  }

  const isLoading = isPostLoading;
  if (isLoading) {
    return <Loading />;
  }

  // User should not be able to;
  // * View posts that don't belong to the current publication
  const hasAccess = postData?.publication_id === currentPublicationId;

  // User is not authorized
  if (!hasAccess) {
    // Redirect to the provided path or the default path
    return <NoPermission redirectPath={redirectTo} errorMessage={ERROR_TOAST_MESSAGE_BY_RESOURCE_TYPE[resourceType]} />;
  }

  // If user has access, render the children (protected component)
  return children;
};

export default RequireAccessToResource;
