import { useCallback, useMemo, useState } from 'react';
import { ArrowRight, Image } from '@phosphor-icons/react';

import useSitePackages from '@/hooks/useSitePackages/useSitePackages';
import { Button } from '@/routes/website/_components/UI/Button';
import { Checkbox } from '@/routes/website/_components/UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogTrigger } from '@/routes/website/_components/UI/Dialog';
import { Label } from '@/routes/website/_components/UI/Label';
import { Text } from '@/routes/website/_components/UI/Text';
import { cn } from '@/utils/cn';

import { useNavbarContext } from '../../NavbarContext';
import { useNavbarDataContext } from '../../NavbarDataContext';

type Props = {
  actionButton: React.ReactNode;
};

const NavbarSelection = ({ actionButton }: Props) => {
  const { onUpdateContent } = useNavbarDataContext();
  const { setContent } = useNavbarContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isDisabled = false;

  const [selectedSitePackageId, setSelectedSitePackageId] = useState<string | null>(null);

  const { data: sitePackages } = useSitePackages();
  const projects = useMemo(() => sitePackages?.pages.flatMap((pkg) => pkg.site_packages) || [], [sitePackages]);

  const handleUseNavbar = useCallback(() => {
    const selectedProjectId = projects?.find((pkg) => pkg.id === selectedSitePackageId);
    if (!selectedProjectId) return;

    const selectedNavbarContent = selectedProjectId.navbar;
    if (!selectedNavbarContent) return;

    onUpdateContent(selectedNavbarContent);
    setContent(selectedNavbarContent);
    handleCloseModal();
  }, [onUpdateContent, projects, selectedSitePackageId, setContent]);

  return (
    <Dialog
      open={isModalOpen}
      onOpenChange={(open) => {
        if (open) {
          handleOpenModal();
        } else {
          handleCloseModal();
        }
      }}
    >
      <DialogTrigger asChild>{actionButton}</DialogTrigger>
      <DialogContent className="w-[800px] max-w-[95vw] p-0">
        <div className="flex flex-col p-4 h-[60vh]">
          <Text size="md" weight="semibold">
            Select Navbar
          </Text>
          <div className="flex h-full">
            <div className="grid grid-cols-2 gap-x-4 gap-y-8 pb-32 pr-4 pt-4 w-full">
              {projects?.map((sitePackage) => {
                const isSelected = selectedSitePackageId === sitePackage.id;
                return (
                  <Label
                    key={sitePackage.id}
                    htmlFor={sitePackage.id}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md focus:outline-none relative',
                      selectedSitePackageId === sitePackage.id ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    <div className="flex justify-center items-center h-full p-4">
                      <Image size={48} />
                    </div>
                    <Checkbox
                      id={sitePackage.id}
                      className={cn('absolute -top-2 -right-2', isSelected ? 'opacity-100' : 'opacity-0')}
                      checked={isSelected}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onCheckedChange={() => setSelectedSitePackageId(isSelected ? null : sitePackage.id)}
                    />

                    <Text
                      weight="semibold"
                      variant="primary"
                      size="sm"
                      as="span"
                      className="absolute -bottom-6 left-1/2 transform -translate-x-1/2"
                    >
                      {sitePackage.name}&apos; Navbar
                    </Text>
                  </Label>
                );
              })}
            </div>
          </div>
        </div>

        <DialogFooter>
          <div className="flex justify-between items-center border-t w-full p-4 z-20 bg-white">
            <div />
            <div className="flex gap-2">
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleUseNavbar} isDisabled={isDisabled} RightIcon={ArrowRight}>
                Use Navbar
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default NavbarSelection;
