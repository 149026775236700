import { AdNetworkAdvertisementKind } from '../internal/advertisement';
import { AdNetworkCampaignPromotedItem } from '../internal/campaign';
import { AdNetworkPayoutModel } from '../shared/payout_model';

export enum AdNetworkOpportunityStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}

interface AdNetworkOpportunityAdvertiser {
  name: string;
  description: string;
  url: string;
  hostname: string;
  logo_url: string;
}

interface AdNetworkOpportunityCampaign {
  id: string;
  name: string;
  default_message_to_publisher: string | null;
  window_end_date: string;
  window_start_date: string;
  window_always_on: boolean;
  window_exceptions: Array<{
    id: string;
    date: string;
  }>;
  promoted_item: AdNetworkCampaignPromotedItem;
  promoted_item_other: string;
}

export interface AdNetworkOpportunity {
  id: string;
  payout_per_click_cents: number;
  payout_per_click: string;
  payout_per_mille_cents: number;
  payout_per_mille: string;
  deadline: string;
  estimated_payout_cents: number;
  estimated_payout: string;
  estimated_opens: number;
  payout_duration_hours: number;
  payout_model: AdNetworkPayoutModel;
  payout_model_flexible: boolean;
  selected_date: string | null;
  status: AdNetworkOpportunityStatus;
  advertiser: AdNetworkOpportunityAdvertiser;
  campaign: AdNetworkOpportunityCampaign;
  advertisement_id: string | null;
  created_at: string;
  updated_at: string;
  message_to_publisher: string | null;
  advertisement: AdNetworkOpportunityAdvertisementMinimalDetails;
}

export interface AdNetworkOpportunityAdvertisement {
  id: string;
  name: string;
  description: string | null;
  html: string;
  kind: AdNetworkAdvertisementKind;
  url: string;
}

export interface AdNetworkOpportunityAdvertisementMinimalDetails {
  name: string;
  description: string | null;
  sponsor_text: string;
}
