import React, { useEffect } from 'react';
import { NodeViewWrapper } from '@tiptap/react';

interface Props {
  url: string;
  service: 'tiktok';
  data: any;
  anchorEnabled: boolean;
  anchorId: string;
}

export const TiktokEmbed = ({ url, service, data: attributes, anchorEnabled = false, anchorId }: Props) => {
  useEffect(() => {
    const existingScript = document.getElementById('tiktok-script');

    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');

    script.src = `//www.tiktok.com/embed.js?t=${Date.now()}`;
    script.async = true;
    script.id = 'tiktok-script';

    document.body.appendChild(script);
  }, []);

  return (
    <NodeViewWrapper
      data-url={url}
      data-service={service}
      data-drag-handle
      {...(anchorEnabled ? { 'data-anchor': '', id: anchorId } : {})}
    >
      <blockquote
        className="tiktok-embed"
        cite={url}
        style={{ maxWidth: '520px', minWidth: '325px' }}
        data-video-id={attributes.video_id}
        data-from-oembed
      >
        <section>
          <a
            target="_blank"
            title={`@${attributes.author_id}`}
            href={`https://www.tiktok.com/@${attributes.author_id}`}
            rel="noreferrer"
          >
            @{attributes.author_id}
          </a>
          <p>{attributes.title}</p>
        </section>
      </blockquote>
    </NodeViewWrapper>
  );
};

export default TiktokEmbed;
