import { Outlet } from 'react-router-dom';
import cx from 'classnames';

import { useAppLayout } from '@/context/app-layout-context';

const PageLayout = () => {
  const { hasFullHeight } = useAppLayout();

  return (
    <div className={cx('w-full px-4 md:px-6', hasFullHeight && 'h-full')}>
      <Outlet />
      <div className="h-8" />
    </div>
  );
};

export default PageLayout;
