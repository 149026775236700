import classNames from 'classnames';
import { v4 as generateUuid } from 'uuid';

import Tooltip from '@/components/Tooltip';

import { ComplementProps } from './MetricCard.types';

const Complement = ({ complement }: ComplementProps) => (
  <div className={classNames('grid grid-cols-2 gap-x-1 pb-1')} key={complement.label}>
    <div className="flex flex-row space-x-2">
      <p className="text-xs text-gray-700 font-medium ">{complement.label}</p>
      {complement.tooltipText && <Tooltip text={complement.tooltipText} id={generateUuid()} />}
    </div>
    <p className="text-xs text-right text-gray-900 font-semibold truncate">{complement.value}</p>
  </div>
);

export default Complement;
