import { useMemo } from 'react';

import { cssVar } from '../utils/cssVar';

export type Category = 'colors' | 'border-styles' | 'font-sizes' | 'font-families';

// Example in CSS: var(--color-1)
const categories = {
  colors: [
    { key: 'color-1', label: 'Theme color 1' },
    { key: 'color-2', label: 'Theme color 2' },
    { key: 'color-3', label: 'Theme color 3' },
    { key: 'color-4', label: 'Theme color 4' },
    { key: 'color-5', label: 'Theme color 5' },
    { key: 'color-6', label: 'Theme color 6' },
  ],
  'border-styles': [
    { key: 'solid', label: 'Solid' },
    { key: 'dashed', label: 'Dashed' },
    { key: 'dotted', label: 'Dotted' },
  ],
  'font-sizes': [
    { key: 'font-size-default', label: 'Default' },
    { key: 'font-size-small', label: 'Small' },
    { key: 'font-size-medium', label: 'Medium' },
    { key: 'font-size-large', label: 'Large' },
    { key: 'font-size-x-large', label: 'X-Large' },
    { key: 'font-size-huge', label: 'Huge' },
  ],
  'font-families': [
    { key: 'font-family-default', label: 'None' },
    { key: 'font-family-0', label: 'Helvetica' },
    { key: 'font-family-1', label: 'Arial' },
    { key: 'font-family-2', label: 'Courier' },
    { key: 'font-family-3', label: 'Didot' },
    { key: 'font-family-4', label: 'Garamond' },
    { key: 'font-family-5', label: 'Georgia' },
    { key: 'font-family-6', label: 'Lucida Console' },
    { key: 'font-family-7', label: 'Monaco' },
    { key: 'font-family-8', label: 'Palatino' },
    { key: 'font-family-9', label: 'Times New Roman' },
    { key: 'font-family-10', label: 'Trebuchet MS' },
    { key: 'font-family-11', label: 'Verdana' },
    { key: 'font-family-12', label: 'Alegreya Sans' },
    { key: 'font-family-13', label: 'Alegreya' },
    { key: 'font-family-14', label: 'Cormorant' },
    { key: 'font-family-15', label: 'DM Sans' },
    { key: 'font-family-16', label: 'Eczar' },
    { key: 'font-family-17', label: 'IBM Plex Sans' },
    { key: 'font-family-18', label: 'Inter' },
    { key: 'font-family-19', label: 'Karla' },
    { key: 'font-family-20', label: 'Lato' },
    { key: 'font-family-21', label: 'Libre Baskerville' },
    { key: 'font-family-22', label: 'Libre Franklin' },
    { key: 'font-family-23', label: 'Noto Sans' },
    { key: 'font-family-24', label: 'Oswald' },
    { key: 'font-family-25', label: 'PT Sans' },
    { key: 'font-family-26', label: 'PT Serif' },
    { key: 'font-family-27', label: 'Raleway' },
    { key: 'font-family-28', label: 'Source Sans 3' },
    { key: 'font-family-29', label: 'Space Grotesk' },
    { key: 'font-family-30', label: 'Space Mono' },
    { key: 'font-family-31', label: 'Syne' },
    { key: 'font-family-32', label: 'Work Sans' },
  ],
};

export const useThemeData = (category: Category) =>
  useMemo(() => categories[category].map((data) => ({ ...data, value: cssVar(data.key) })), [category]);
