import { PropsWithChildren } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Typography } from '@/components/Typography';

type Props = PropsWithChildren<{
  label?: string;
}>;

const SettingsNote = ({ label, children }: Props) => {
  return (
    <div className="flex flex-col space-y-1">
      <Typography token="font-normal/text/xs">{label}</Typography>
      <p>{children}</p>
    </div>
  );
};

type TextProps = PropsWithChildren<{}>;

const SettingsNoteDescription = ({ children }: TextProps) => {
  return (
    <Typography token="font-light/text/xs" as="p">
      {children}
    </Typography>
  );
};

type LinkProps = PropsWithChildren<{
  to: string;
  returnTo?: {
    title: string;
    path: string;
  };
}>;

const SettingsNoteLink = ({ to, children, returnTo }: LinkProps) => {
  const path = returnTo ? `${to}&return_to_title=${returnTo.title}&return_to=${returnTo.path}` : to;

  return (
    <Typography token="font-normal/text/xs" as="span" colorWeight="700">
      <Link to={path} className="text-action-secondary-700 underline">
        {children}
      </Link>
    </Typography>
  );
};

interface ReturnProps {
  returnToTitle: string | null;
  returnToPath: string | null;
}

const useReturnToSetting = (): ReturnProps => {
  const [searchParams] = useSearchParams();
  const returnToTitle = searchParams.get('return_to_title');
  const returnToPath = searchParams.get('return_to');

  return {
    returnToTitle,
    returnToPath,
  };
};

export { SettingsNote, SettingsNoteDescription, SettingsNoteLink, useReturnToSetting };
