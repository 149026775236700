import styled from 'styled-components';

import { colors } from '../../../lib/colors';
import { Styled as StyledButton } from '../Button/styled';

import { StyledToolbarProps } from './types';

const Divider = styled.div<StyledToolbarProps>`
  background: rgba(255, 255, 255, 0.1);

  && {
    margin-left: 0.25rem;
    margin-right: 0.125rem;
  }
`;

const Toolbar = styled.div<StyledToolbarProps>`
  background: ${colors.black[1]};
  border-radius: 8px;
  display: inline-flex;
  flex-direction: ${({ $isVertical }) => ($isVertical ? 'column' : 'row')};
  flex-wrap: wrap;
  line-height: 1;
  padding: ${({ $isVertical }) => ($isVertical ? '0.5rem' : '0.25rem')};

  & > ${StyledButton.Button}, & > div {
    margin-left: ${({ $isVertical }) => ($isVertical ? '0' : '0.125rem')};
    margin-top: ${({ $isVertical }) => ($isVertical ? '0.25rem' : '0')};

    &:first-child {
      margin-left: 0;
      margin-top: 0;
    }
  }

  ${Divider} {
    height: ${({ $isVertical }) => ($isVertical ? '1px' : 'auto')};
    width: ${({ $isVertical }) => ($isVertical ? '100%' : '1px')};
  }
`;

export const Styled = {
  Divider,
  Toolbar,
};

export default Styled;
