import { useMemo } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { EditorColor } from '@/components/TiptapEditor/lib/types';
import { getBackgroundColors } from '@/utils/documentColors';

import { useThemeData } from '../../../../../lib/hooks/useThemeData';
import styles from '../../../../../styled';
import { Button } from '../../../../ui/Button';
import { Icon } from '../../../../ui/Icon';
import { PanelHeadline, PanelSection } from '../../../../ui/Panel';
import { useCurrentNode } from '../../../hooks';
import ThemeColorButton from '../../ThemeColorButton';

import useVisualSettingsPanel from './VisualSettingsPanel.hooks';

export const BackgroundColor = ({
  editor,
  onShowCustomBackgroundColorPanel,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  onShowCustomBackgroundColorPanel: Function;
  currentNode: Node | null;
  currentNodePos: number;
}) => {
  const themeColors = useThemeData('colors');
  const { isCustomBackgroundColor } = useCurrentNode(editor, currentNode, themeColors);

  const { isSection, onSetBackgroundColor, onResetBackgroundColor } = useVisualSettingsPanel(
    editor,
    currentNode,
    currentNodePos,
    () => {}
  );

  const documentColors = editor.storage.documentColors?.colors as Array<EditorColor> | undefined;

  const backgroundColors = useMemo(() => {
    return getBackgroundColors(
      documentColors,
      themeColors.map((color) => color.value)
    );
  }, [documentColors, themeColors]);

  return (
    <PanelSection>
      <PanelHeadline>Background color</PanelHeadline>
      <div className="flex items-center gap-[1px]">
        {themeColors.map((themeColor) => {
          return (
            <ThemeColorButton
              key={themeColor.key}
              onClick={onSetBackgroundColor}
              active={currentNode?.attrs.backgroundColor === themeColor.value}
              themeColor={themeColor}
            />
          );
        })}
      </div>
      <div className="flex items-center gap-[1px] pt-0.5 border-t border-white border-opacity-10 mt-0.5">
        <Button
          $variant="quaternary"
          $isColorTileButton
          $active={isCustomBackgroundColor}
          onClick={onShowCustomBackgroundColorPanel}
          style={styles.customColorCSSVar}
        />
        <Button
          $variant="quaternary"
          $size="small"
          $isIconButton
          $leftSlot={<Icon name="Cancel" />}
          disabled={!isSection || !currentNode?.attrs.backgroundColor}
          onClick={onResetBackgroundColor}
        />
        {backgroundColors.map((color) => (
          <ThemeColorButton
            key={color}
            onClick={onSetBackgroundColor}
            active={currentNode?.attrs.backgroundColor === color}
            themeColor={{ key: color, value: color, label: color }}
          />
        ))}
      </div>
    </PanelSection>
  );
};

export default BackgroundColor;
