import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';

interface Props {
  title: string;
  to: string;
  disabled?: boolean;
}

export const NavItem = ({ title, to, disabled }: Props) => {
  const location = useLocation();
  const isCurrentPath = location.pathname === to;

  if (disabled) {
    return (
      <Tooltip
        tooltipClass="text-center"
        id={`large-nav-${title}-tooltip`}
        text={NO_PERMISSION_MESSAGE}
        showIcon={false}
        autoWidth={false}
        placement="right"
      >
        <div className="flex flex-col justify-center items-center w-full pl-6 pr-2">
          <div className="py-1.5 px-2 mt-1 rounded-lg text-start w-full hover:bg-surface-100 bg-transparent cursor-not-allowed">
            <Typography token="font-medium/text/sm" colorWeight="400">
              {title}
            </Typography>
          </div>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center w-full pl-6 pr-2">
      <Link
        to={to}
        className={cx(
          'py-1.5 px-2 mt-1 rounded-lg text-start w-full text-gray-700 duration-300',
          isCurrentPath ? 'bg-surface-100' : 'hover:bg-surface-100 bg-transparent'
        )}
      >
        <Typography token="font-medium/text/sm" className="text-gray-700">
          {title}
        </Typography>
      </Link>
    </div>
  );
};
