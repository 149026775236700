import Icon from '@/components/TiptapEditor/components/ui/Icon';
import * as DropdownMenu from '@/components/UI/Dropdown';

interface SubscriberTagOption {
  label: string;
  onClick: any;
  icon: any;
  key: string;
}

interface Props {
  options: SubscriberTagOption[];
}

const SubscriberTagDropdown = ({ options }: Props) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Button className="flex p-2 bg-transparent rounded outline-none ring-0">
        <Icon name="DotsVertical" $size=".875rem" />
      </DropdownMenu.Button>
      <DropdownMenu.Content className="p-1 bg-white rounded-lg shadow-lg min-w-[178px] right-0">
        {options &&
          options.map((option: any) => {
            return (
              <DropdownMenu.Item key={option.key} onClick={option.onClick}>
                {option.icon && <span className="h-3 w-3 mr-2">{option.icon}</span>}
                <span className="flex-1">{option.label}</span>
              </DropdownMenu.Item>
            );
          })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default SubscriberTagDropdown;
