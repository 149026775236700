import React, { useEffect, useRef } from 'react';

import './SniperLink.css';

const SniperLink: React.FC<{ recipient: string; sender: string }> = ({ recipient, sender }) => {
  const sniperLinkRef = useRef<HTMLElement>(null);
  const recipientDomain = recipient.split('@')[1];

  useEffect(() => {
    const scriptId = 'sniper-link-script';
    let script = document.getElementById(scriptId) as HTMLScriptElement;
    if (!script) {
      script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://sniperl.ink/v1/sniper-link.js';
      script.defer = true;
      script.onload = () => {
        if (sniperLinkRef.current) {
          sniperLinkRef.current.setAttribute('recipient', recipientDomain);
          sniperLinkRef.current.setAttribute('sender', sender);
        }
      };
      document.body.appendChild(script);
    } else if (sniperLinkRef.current) {
      sniperLinkRef.current.setAttribute('recipient', recipientDomain);
      sniperLinkRef.current.setAttribute('sender', sender);
    }

    return () => {
      if (script) {
        script.onload = null;
      }
    };
  }, [recipient, sender]);

  return <sniper-link ref={sniperLinkRef} className="sniper-link" />;
};

export default SniperLink;
