import { Link } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/20/solid';

import { Automation } from '@/interfaces/automations/automation';

interface Props {
  items: Automation[];
  onDeselect?: (automationId: string) => void;
}

const SelectedAutomations = ({ items, onDeselect }: Props) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <ul className="min-w-64 bg-gray-50 px-4 py-2 rounded-md">
      {items.map((automation: Automation) => {
        return (
          <li
            key={`selected-automation-${automation.id}`}
            className="flex items-center gap-x-2 py-2 justify-between border-b border-b-gray-200 last:border-b-0 first:pt-0 last:pb-0"
          >
            <div className="flex flex-col overflow-hidden">
              <Link
                target="_blank"
                className="w-full truncate text-sm font-medium text-gray-800 hover:text-gray-500"
                to={`/automations/${automation.id}`}
              >
                {automation.name}
              </Link>
              <ul className="text-xs text-gray-500">
                <li>Enrolled: {automation.stats.running_count}</li>
                <li>Completed: {automation.stats.completed_count}</li>
              </ul>
            </div>
            {onDeselect && (
              <button
                type="button"
                onClick={() => onDeselect(automation.id)}
                className="text-gray-400 hover:text-gray-600 p-1"
              >
                <TrashIcon width={16} height={16} />
              </button>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default SelectedAutomations;
