enum TimePeriod {
  ALL_TIME = 'all_time',
  LAST_12_MONTHS = 'last_12_months',
  LAST_24_HOURS = 'last_24_hours',
  LAST_4_WEEKS = 'last_4_weeks',
  LAST_7_DAYS = 'last_7_days',
  MONTH_TO_DATE = 'month_to_date',
  QUARTER_TO_DATE = 'quarter_to_date',
  YEAR_TO_DATE = 'year_to_date',
}

export const timePeriodLabels = {
  [TimePeriod.ALL_TIME]: 'All time',
  [TimePeriod.LAST_12_MONTHS]: 'Last 12 months',
  [TimePeriod.LAST_24_HOURS]: 'Last 24 hours',
  [TimePeriod.LAST_4_WEEKS]: 'Last 4 weeks',
  [TimePeriod.LAST_7_DAYS]: 'Last 7 days',
  [TimePeriod.MONTH_TO_DATE]: 'Month to date',
  [TimePeriod.QUARTER_TO_DATE]: 'Quarter to date',
  [TimePeriod.YEAR_TO_DATE]: 'Year to date',
};

export const timePeriodsButtonOptions = [
  { id: TimePeriod.ALL_TIME, label: timePeriodLabels[TimePeriod.ALL_TIME] },
  { id: TimePeriod.LAST_24_HOURS, label: timePeriodLabels[TimePeriod.LAST_24_HOURS] },
  { id: TimePeriod.LAST_7_DAYS, label: timePeriodLabels[TimePeriod.LAST_7_DAYS] },
  { id: TimePeriod.LAST_4_WEEKS, label: timePeriodLabels[TimePeriod.LAST_4_WEEKS] },
  { id: TimePeriod.LAST_12_MONTHS, label: timePeriodLabels[TimePeriod.LAST_12_MONTHS] },
  { id: TimePeriod.MONTH_TO_DATE, label: timePeriodLabels[TimePeriod.MONTH_TO_DATE] },
  { id: TimePeriod.QUARTER_TO_DATE, label: timePeriodLabels[TimePeriod.QUARTER_TO_DATE] },
  { id: TimePeriod.YEAR_TO_DATE, label: timePeriodLabels[TimePeriod.YEAR_TO_DATE] },
];

export default TimePeriod;
