import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/internal/opportunity';
import api from '@/services/swarm';

interface Variables {
  status: string;
}

export default function useOpportunityUpdate({ id }: { id: string }) {
  const updateOpportunity = ({ status }: Variables): Promise<AdNetworkOpportunity> =>
    api
      .patch(`/ad_network/internal/opportunities/${id}/status`, {
        opportunity: {
          status,
        },
      })
      .then((res) => res.data);

  return useMutation<AdNetworkOpportunity, MutationErrorResponse, Variables>(updateOpportunity, {
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
