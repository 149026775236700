import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  className?: string;
  gap?: '0' | '1' | '2' | '3' | '4' | '5' | '6';
}>;

const GAP_MAP = Object.freeze({
  '0': 'space-y-0',
  '1': 'space-y-1',
  '2': 'space-y-2',
  '3': 'space-y-3',
  '4': 'space-y-4',
  '5': 'space-y-5',
  '6': 'space-y-6',
});

const TypographyStack = ({ children, className, gap = '1' }: Props) => {
  return <div className={cx(className, 'flex flex-col text-left', GAP_MAP[gap])}>{children}</div>;
};

export default TypographyStack;
