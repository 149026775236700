import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';
import analytics from '@/utils/analytics';

interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

interface Variables {
  publicationId: string;
  onSuccess?: () => void;
}

export default function useRssFeedsDestroy({ publicationId, onSuccess }: Variables) {
  const destroyRssFeed = ({ id }: { id: string }): Promise<void> =>
    api
      .delete(`/rss_feeds/${id}`, {
        data: {
          publication_id: publicationId,
        },
      })
      .then((res) => res.data);

  return useMutation<void, ErrorResponse, { id: string }>(destroyRssFeed, {
    onSuccess: () => {
      analytics.track('Deleted an RSS Feed');
      toast.success('RSS feed deleted');

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
