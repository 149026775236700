import { useMemo } from 'react';
import { BracketsCurly } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';

import { PopoverHelper, PopoverItem } from '../../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../../types';
import ActionRenderer from '../ActionRenderer';

const TextActions = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeType, activeNodePos } = activeNodeResult;
  const { data: currentPublication } = useCurrentPublication();

  const fields = useMemo(() => {
    return [
      {
        label: 'Name',
        mergeTag: 'publication.name',
        value: currentPublication?.name,
      },
      {
        label: 'Description',
        mergeTag: 'publication.description',
        value: currentPublication?.description,
      },
    ];
  }, [currentPublication]);

  const addMergeTag = (mergeTag: string, text: string) => {
    editor.commands.command(({ tr, state }) => {
      const parentNode = state.doc.nodeAt(activeNodePos);
      if (!parentNode || parentNode.childCount === 0) return false;

      const textNodePos = activeNodePos + 1;
      const textNode = parentNode.firstChild;

      if (textNode && textNode.isText) {
        tr.insertText(text, textNodePos, textNodePos + textNode.nodeSize);
        tr.setNodeAttribute(activeNodePos, 'mergeTag', mergeTag);
        return true;
      }
      return false;
    });
  };

  return (
    <ActionRenderer nodeType={['paragraph', 'heading']} activeNodeType={activeNodeType}>
      <PopoverHelper title="Data" Icon={BracketsCurly} text="Publication" popoverTitle="Publication" disabled={false}>
        <div className="flex flex-col gap-2">
          {fields.map((field) => (
            <PopoverItem
              value={field.label}
              onSelect={() => addMergeTag(field.mergeTag, field.value || '')}
              isSelected={false}
            />
          ))}
        </div>
      </PopoverHelper>
    </ActionRenderer>
  );
};

export default TextActions;
