import { useCallback, useEffect, useState } from 'react';
import { TCollabThread } from '@hocuspocus/provider';
import { Editor } from '@tiptap/core';
import throttle from 'lodash.throttle';

const getThreadIdResolvedAtMap = (threads: TCollabThread[]): Record<string, boolean> => {
  return threads.reduce((acc, thread) => {
    return {
      ...acc,
      [thread.id]: !!thread.resolvedAt,
    };
  }, {});
};

export const useFocusedThreads = (editor?: Editor | null) => {
  const [focusedThreads, setFocusedThreads] = useState<string[]>([]);

  const [activeThreadId, setActiveThreadId] = useState<string | null>(null);

  const [activeThreadIdObj, setActiveThreadIdObj] = useState<{ [key: string]: boolean }>({});

  const onTransaction = useCallback(() => {
    if (!editor) return;

    const throttledSetValues = throttle(() => {
      const threadIdResolvedAtMap = getThreadIdResolvedAtMap(editor.storage.comments.threads || []);

      const focusedThreadsToSet: string[] = Array.from(new Set(editor.storage.comments.focusedThreads || []));

      setFocusedThreads(focusedThreadsToSet);

      const activeThreadIdToSet = focusedThreadsToSet.filter((id) => !threadIdResolvedAtMap[id])[0];

      setActiveThreadId(activeThreadIdToSet);

      const activeThreadIdObjToSet = { [activeThreadIdToSet]: true };

      setActiveThreadIdObj(activeThreadIdObjToSet);
    }, 300);

    throttledSetValues();
  }, [editor]);

  useEffect(() => {
    if (!editor) return () => {};

    editor.on('transaction', onTransaction);
    editor.on('selectionUpdate', onTransaction);

    return () => {
      editor.off('transaction', onTransaction);
      editor.off('selectionUpdate', onTransaction);

      setFocusedThreads([]);
      setActiveThreadId(null);
      setActiveThreadIdObj({});
    };
  }, [editor, onTransaction]);

  return {
    focusedThreads,
    activeThreadId,
    activeThreadIdObj,
  };
};
