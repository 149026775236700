import { Question } from '@phosphor-icons/react';
import { NAVBAR_MENU_LIST_DEFAULTS } from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

import { useNavbarContext } from '../../../NavbarContext';
import { getParent } from '../../../utils';
import { PaddingSettings } from '../general/PaddingSettings';
import { SliderSettings } from '../general/SliderSettings';

import { StickyOptions } from './StickyOptions';
import { StyleOptions } from './StyleOptions';

export function NavMenuStyleSettings() {
  const { content, selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const rootMenuContent =
    selectedContent?.type === 'navbar_menu_list' && content ? getParent(content, selectedContent) : selectedContent;

  return (
    <SectionRenderer title="Styling">
      <StyleOptions />
      <StickyOptions />
      {rootMenuContent && (
        <SliderSettings
          selectedContent={rootMenuContent}
          attribute="blurBackground"
          title="Blur"
          min={0}
          max={16}
          unit="px"
          tooltip={
            <Tooltip
              placement="bottom-end"
              center="Make sure your background is somewhat transparent to see the blur effect"
            >
              <Question size={16} weight="bold" className="text-wb-secondary" />
            </Tooltip>
          }
        />
      )}
      {selectedContent.type === 'navbar_menu_list' && (
        <PaddingSettings
          selectedContent={selectedContent}
          attribute="padding"
          defaultValue={NAVBAR_MENU_LIST_DEFAULTS.padding}
        />
      )}
    </SectionRenderer>
  );
}
