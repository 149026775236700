export default function WorkCard({ label, img }: { label: string; img: string }) {
  return (
    <div key={img} className="flex flex-col items-center grow shrink-0" contentEditable={false}>
      <div className="h-[108px] bg-blue-200 relative flex items-center justify-center">
        {/* <img src={img} alt={label} className="w-full h-full object-cover" /> */}
        <div className="absolute inset-0 bg-white/80" />
      </div>
      <div className="py-[4px] text-center w-full">{label}</div>
    </div>
  );
}
