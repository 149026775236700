const valueFormatter = (number: number) => `${Intl.NumberFormat('us').format(number).toString()}`;

const currencyFormatter = (value: number, currency: string, minDigits: number = 0, maxDigits: number = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    // These options are needed to round to whole numbers.
    minimumFractionDigits: minDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: maxDigits, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(value);
};

export { currencyFormatter, valueFormatter };
