import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BarChart, Color, Legend } from '@tremor/react';

import Card from '@/components/Card';
import Grid, { Row } from '@/components/Grid';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useCurrentTimeZone } from '@/hooks';
import { TimePeriod } from '@/utils';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

import './acquisitionSourceByPeriod.css';

type StatType = {
  count: number;
  label: string;
};

type SourceType = {
  name: string;
  stats: StatType[];
};

type NewDataSet = {
  [x: string]: number | string;
};

const transformData = (sourceData: SourceType[]): NewDataSet[] => {
  return sourceData.flatMap((source) => {
    const { name, stats } = source;
    const newStats: { [x: string]: number } = {};

    stats.forEach((stat) => {
      newStats[stat.label] = stat.count;
    });

    return {
      name,
      ...newStats,
    };
  });
};

const AcquisitionsByPeriod = ({ period }: { period: TimePeriod }) => {
  const currentTimeZone = useCurrentTimeZone();
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState<SourceType[]>([]);
  const [sources, setSources] = useState<NewDataSet[]>([]);
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBreakdown = useCallback(() => {
    if (currentPublicationId) {
      setIsLoading(true);

      const params = {
        group_cols: ['channel', 'source', 'medium'],
        group_by_period: true,
        period,
        time_zone: currentTimeZone,
        tier: 'premium',
      };

      api
        .get(`/publications/${currentPublicationId}/acquisition_sources_stats_by_periods`, { params })
        .then((res) => {
          setResponseData(res.data);
          return res.data;
        })
        .then((data) => transformData(data))
        .then((data) => setSources(data))
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPublicationId, currentTimeZone, period]);

  useEffect(() => {
    fetchBreakdown();
  }, [fetchBreakdown]);

  const renderEmptyState = () => <p className="mt-4">No data</p>;

  const categories = responseData
    .flatMap((item) => item.stats.map((stat) => stat.label))
    .filter((value, index, array) => array.indexOf(value) === index);

  const colors = () => {
    let index = 0;

    const allColors: Color[] = ['pink', 'blue', 'gray', 'indigo', 'yellow', 'purple'];
    const newColors: Color[] = [];

    categories.forEach(() => {
      newColors.push(allColors[index]);

      if (index >= allColors.length) {
        index = 0;
      } else {
        index += 1;
      }
    });

    return newColors;
  };

  return (
    <Card title="Premium Subscribers by Source over Time" className="w-full">
      {isLoading && <LoadingSpinner className="ml-2" />}
      {!isLoading && sources && sources.length === 0 && renderEmptyState()}

      {!isLoading && sources && sources.length > 0 && (
        <Grid>
          <Row colSpan="md:2" className="xs:hidden md:flex generated-legend">
            <Legend categories={categories} colors={colors()} />
          </Row>

          <Row colSpan="md:10">
            <BarChart
              stack
              data={sources}
              index="name"
              categories={categories}
              colors={colors()}
              showLegend={false}
              allowDecimals={false}
            />
          </Row>

          <Row colSpan={12} className="md:hidden generated-legend">
            <Legend categories={categories} colors={colors()} />
          </Row>
        </Grid>
      )}
    </Card>
  );
};

export default AcquisitionsByPeriod;
