import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface ApiResponse {
  csv: string;
}

interface Arguments {
  useApiary?: boolean;
  platform?: 'web' | 'email';
}

export default function usePostClicksExport({ useApiary = false, platform }: Arguments) {
  const { postId } = useParams();
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostClicks = () =>
    api
      .get(`/posts/${postId}/clicks/export`, {
        params: {
          publication_id: currentPublicationId,
          use_apiary: useApiary === true ? '1' : undefined,
          platform,
        },
      })
      .then((res) => res.data);

  return useMutation<ApiResponse>(fetchPostClicks, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.csv]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `clicks-${postId}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onError: () => {
      toast.error('Something went wrong. Please try again.');
    },
  });
}
