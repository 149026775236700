import { useQuery } from 'react-query';

import api from '../services/swarm';

const useOptions = (publicationId: string, optionEndpoint: string, staleTime = 10000, additionalParams?: any) => {
  return useQuery(
    ['options', optionEndpoint, publicationId],
    () =>
      api
        .get(`/options/${optionEndpoint}`, {
          params: {
            publication_id: publicationId,
            ...additionalParams,
          },
        })
        .then((res) => res.data),
    {
      staleTime,
      enabled: Boolean(optionEndpoint),
    }
  );
};

export default useOptions;
