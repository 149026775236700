import { ChangeEvent } from 'react';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import Text from '@/components/Text';

interface ModalProps {
  postIds: string[];
  isOpen: boolean;
  disabled: boolean;
  deleteConfirmationValue: string;
  setDeleteConfirmationValue: (value: string) => void;
  setConfirmedDelete: (value: boolean) => void;
  handleOnClose: () => void;
  handleOnProceed: () => void;
  headerText: string;
}

const DeleteModal = ({
  postIds,
  headerText,
  disabled,
  deleteConfirmationValue,
  setDeleteConfirmationValue,
  setConfirmedDelete,
  isOpen = false,
  handleOnClose,
  handleOnProceed,
}: ModalProps) => {
  const modalId = postIds.filter((id) => id !== null).join('-');
  const CONFIRM_TEXT = 'delete';

  const handleDeleteConfirmChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setDeleteConfirmationValue(newValue);
    setConfirmedDelete(newValue === CONFIRM_TEXT);
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onProceed={handleOnProceed}
      onClose={handleOnClose}
      resourceId={modalId}
      isWorking={false}
      headerText={headerText}
      actionText="Delete"
      disabled={disabled}
    >
      <Text size="md">Warning: this is a permanent action that cannot be undone.</Text>

      <Text size="md">
        Type <code>{CONFIRM_TEXT}</code> Below:
        <Input type="text" name="delete-confirm" onChange={handleDeleteConfirmChange} value={deleteConfirmationValue} />
      </Text>
    </ActionModal>
  );
};

export default DeleteModal;
