import { useIndustryCreate } from '../../../hooks/useAdNetwork/internal';
import { PageHeading } from '../_components/PageHeading';

import { Form } from './_components/Form';

export default function New() {
  const onSubmitMutation = useIndustryCreate();

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Industries</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="new">New</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="mx-auto max-w-lg pt-4">
        <h1 className="text-xl">New industry</h1>
      </div>
      <Form onSubmitMutation={onSubmitMutation} />
    </>
  );
}
