import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostAgreement, BoostAgreementStatus } from '@/interfaces/boosts/monetize/boost_agreement';
import { Order, Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  boost_agreements: BoostAgreement[];
  pagination: Pagination;
}
interface Props {
  allResults?: boolean;
  boostOfferId?: string;
  search?: string;
  perPage?: number;
  status?: BoostAgreementStatus | string;
  orderBy?: string;
  direction?: Order;
  isEnabled?: boolean;
  isBoostAffiliate?: boolean;
  boostedPublicationId?: string;
}

const useBoostAgreements = ({
  allResults = false,
  search,
  perPage = 10,
  status,
  boostOfferId,
  orderBy,
  boostedPublicationId,
  isBoostAffiliate,
  direction = Order.DESC,
  isEnabled = false,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostAgreements = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/boosts/monetize/boost_agreements`, {
        params: {
          all_results: allResults,
          page: pageParam,
          per_page: perPage,
          publication_id: currentPublicationId,
          boosted_publication_id: boostedPublicationId,
          boost_affiliate_status: isBoostAffiliate,
          q: search,
          status,
          order_by: orderBy,
          direction,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery(
    [
      currentPublicationId,
      'boosts',
      'monetize',
      'boost_agreements',
      boostOfferId,
      allResults,
      search,
      orderBy,
      direction,
      isBoostAffiliate,
    ],
    fetchBoostAgreements,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
      enabled: isEnabled,
    }
  );
};

export default useBoostAgreements;
