import KPI from '@/components/KPI';
import KPIGroup from '@/components/KPIGroup';
import { KPIData } from '@/pages/ReferralProgram/Overview/KpisContainer';

interface Props {
  data: KPIData | undefined;
  isLoading: boolean;
}

const TopLineMetrics = ({ data, isLoading }: Props) => {
  return (
    <KPIGroup>
      <KPI
        title="Subscribers with referrals"
        value={data?.total_subscribers_with_referrals || 0}
        previousValue={data?.lag_total_subscribers_with_referrals || 0}
        pctDelta={data?.total_subscribers_with_referrals_delta || 0}
        isLoading={isLoading}
      />
      <KPI
        title="Total readers referred"
        value={data?.total_referrals || 0}
        previousValue={data?.lag_total_referrals || 0}
        pctDelta={data?.total_referrals_delta || 0}
        isLoading={isLoading}
      />
      <KPI
        title="Referrals per subscriber"
        tooltipText="Only includes subscribers with at least one referral"
        value={data?.avg_referrals_per_subscriber || 0}
        previousValue={data?.lag_avg_referrals_per_subscriber || 0}
        pctDelta={data?.avg_referrals_per_subscriber_delta || 0}
        isLoading={isLoading}
      />
    </KPIGroup>
  );
};

export default TopLineMetrics;
