import { Textbox } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { IconSettings } from '../IconSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

type Props = AttributeSettingProps & {};

export const InputSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Input" text="Input" popoverTitle="Input" Icon={Textbox} align="end">
      <>
        <ColorSettings
          title="Background"
          property="inputBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'inputBorderColor',
            style: 'inputBorderStyle',
            width: 'inputBorderWidth',
            radius: 'inputBorderRadius',
          }}
        />
        <ColorSettings title="Text" property="inputTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          title="Font"
          property="inputFontFamily"
        />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="inputPlaceholder"
          title="Placeholder"
        />
        <ColorSettings
          title="Placeholder Text"
          property="inputPlaceholderColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />

        <IconSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings title="Icon Color" property="iconColor" editor={editor} activeNodeResult={activeNodeResult} />
      </>
    </PopoverHelper>
  );
};

export default InputSettings;
