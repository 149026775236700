import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  organizationId: string | undefined;
  downgradeId: string;
}

const useCreatePausePlan = () => {
  return useMutation(({ organizationId, downgradeId }: Props) =>
    api.post(`/organizations/${organizationId}/pause_plan`, {
      organization_id: organizationId,
      downgrade_id: downgradeId,
    })
  );
};

export default useCreatePausePlan;
