import { forwardRef, HTMLProps } from 'react';

import { cn } from '@/utils/cn';

export type InputWrapperProps = HTMLProps<HTMLDivElement> & {};

const Wrapper = forwardRef<HTMLDivElement, InputWrapperProps>(({ children, className, ...rest }, ref) => {
  return (
    <div className={cn(className)} ref={ref} {...rest}>
      {children}
    </div>
  );
});

Wrapper.displayName = 'Input.Wrapper';

export type InputLabelProps = HTMLProps<HTMLLabelElement> & {
  htmlFor: string;
};

const Label = forwardRef<HTMLLabelElement, InputLabelProps>(({ className, children, htmlFor, ...rest }, ref) => {
  return (
    <label
      className={cn('text-xs font-semibold block mb-2 text-gray-700', className)}
      htmlFor={htmlFor}
      ref={ref}
      {...rest}
    >
      {children}
    </label>
  );
});

Label.displayName = 'Input.Label';

const Field = forwardRef<HTMLInputElement, HTMLProps<HTMLInputElement>>(({ className, ...rest }, ref) => {
  return (
    <input
      className={cn(
        'block w-full rounded bg-white border shadow-none border-gray-200 text-sm transition-all ring-0',
        !rest.disabled && 'hover:border-gray-300',
        !rest.disabled && 'focus:border-gray-300 focus:shadow-md focus:outline-none focus:ring-0',
        rest.disabled && 'bg-gray-200 border-gray-300',
        className
      )}
      ref={ref}
      {...rest}
    />
  );
});

Field.displayName = 'Input.Field';

const DescriptionText = forwardRef<HTMLParagraphElement, HTMLProps<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    return <p className={cn('text-xs text-gray-400 mt-1', className)} ref={ref} {...props} />;
  }
);

DescriptionText.displayName = 'Input.DescriptionText';

export const Input = {
  Wrapper,
  Label,
  Field,
  DescriptionText,
};
