import { SwatchIcon } from '@heroicons/react/20/solid';

import { useUpdateWebTemplate } from '@/hooks';
import { WebTheme } from '@/interfaces/web_template';

import { usePageContext } from '../Context/PageContext';
import ColorInput from '../Form/ColorInput';
import PropertiesGrouping from '../Helpers/PropertiesGrouping';
import { SectionGroupingSlideOut } from '../Helpers/SectionGroupingSlideOut';

interface Props {
  sectionGroupChildren?: React.ReactNode;
}

const GlobalColorsSection = ({ sectionGroupChildren }: Props) => {
  const { handleSaved, defaultColors, webTemplate } = usePageContext();

  const webTemplateMutation = useUpdateWebTemplate(() => handleSaved());
  const webTheme: WebTheme | undefined = webTemplate?.web_theme_preview;
  const webThemWithDefaults: WebTheme | undefined = webTemplate?.web_theme_preview_with_defaults;

  const handleUpdateWebTheme = (key: string, value: string | boolean) => {
    webTemplateMutation.mutate({
      web_theme_preview: {
        ...(webTheme as WebTheme),
        [key]: value,
      },
    });
  };

  return (
    <SectionGroupingSlideOut
      title="Global Colors"
      sectionIcon={<SwatchIcon className="w-4 h-4" />}
      sectionGroupChildren={sectionGroupChildren}
      highlightProps={{
        sectionId: 'global-colors',
      }}
      tooltipText="Global color configurations are applied across your website. If specific settings are not reflected in the site preview, this is because not every element is displayed on all pages."
    >
      <PropertiesGrouping
        label="Core"
        description="The primary and secondary colors make up the core brand palette."
        indent
      >
        <ColorInput
          label="Primary Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#030712',
            check: webTheme?.color_primary !== '#030712',
          }}
          color={webThemWithDefaults?.color_primary}
          onSave={(value: string) => handleUpdateWebTheme('color_primary', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Primary Font Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#F9FAFB',
            check: webTheme?.color_text_on_primary !== '#F9FAFB',
          }}
          color={webThemWithDefaults?.color_text_on_primary}
          onSave={(value: string) => handleUpdateWebTheme('color_text_on_primary', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Secondary Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#F9FAFB',
            check: webTheme?.color_secondary !== '#F9FAFB',
          }}
          color={webThemWithDefaults?.color_secondary}
          onSave={(value: string) => handleUpdateWebTheme('color_secondary', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Secondary Font Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#030712',
            check: webTheme?.color_text_on_secondary !== '#030712',
          }}
          color={webThemWithDefaults?.color_text_on_secondary}
          onSave={(value: string) => handleUpdateWebTheme('color_text_on_secondary', value)}
          defaultColors={defaultColors}
        />
      </PropertiesGrouping>

      <PropertiesGrouping
        label="Functional"
        description="Background, border, and link colors serve specific functional purposes in your website interface."
        indent
      >
        <ColorInput
          label="Background Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#FFFFFF',
            check: webTheme?.color_background !== '#FFFFFF',
          }}
          color={webThemWithDefaults?.color_background}
          onSave={(value: string) => handleUpdateWebTheme('color_background', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Border Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#E5E7EB',
            check: webTheme?.color_border !== '#E5E7EB',
          }}
          color={webThemWithDefaults?.color_border}
          onSave={(value: string) => handleUpdateWebTheme('color_border', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Link Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#0C4A6E',
            check: webTheme?.color_link !== '#0C4A6E',
          }}
          color={webThemWithDefaults?.color_link}
          onSave={(value: string) => handleUpdateWebTheme('color_link', value)}
          defaultColors={defaultColors}
        />
      </PropertiesGrouping>

      <PropertiesGrouping
        label="Content"
        description="Eyebrow heading and subheading colors differentiate and style supporting text content."
        indent
      >
        <ColorInput
          label="Overline Text Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#1F2937',
            check: webTheme?.color_overline_text !== '#1F2937',
          }}
          color={webThemWithDefaults?.color_overline_text}
          onSave={(value: string) => handleUpdateWebTheme('color_overline_text', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Heading Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#111827',
            check: webTheme?.color_heading !== '#111827',
          }}
          color={webThemWithDefaults?.color_heading}
          onSave={(value: string) => handleUpdateWebTheme('color_heading', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Body Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#030712',
            check: webTheme?.color_body !== '#030712',
          }}
          color={webThemWithDefaults?.color_body}
          onSave={(value: string) => handleUpdateWebTheme('color_body', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Underline Text Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#1F2937',
            check: webTheme?.color_underline_text !== '#1F2937',
          }}
          color={webThemWithDefaults?.color_underline_text}
          onSave={(value: string) => handleUpdateWebTheme('color_underline_text', value)}
          defaultColors={defaultColors}
        />
      </PropertiesGrouping>
    </SectionGroupingSlideOut>
  );
};

export default GlobalColorsSection;
