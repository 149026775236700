import { Typography } from '@/components/Typography';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import pluralize from '@/utils/pluralize';

import ConditionsList from './ConditionsList';

interface Props {
  automationConditions: AutomationConditions;
}

const NewBranchAutomationStepNode = ({ automationConditions }: Props) => {
  const conditionsCount = automationConditions.conditions?.length || 0;

  return (
    <>
      <Typography size="xs">Branch on {pluralize('condition', conditionsCount)}</Typography>
      <ConditionsList automationConditions={automationConditions} />
    </>
  );
};

export default NewBranchAutomationStepNode;
