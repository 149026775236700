import { Popover } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';

export interface WalkthroughCardProps {
  title: string;
  description: string;
  totalSteps?: number;
  currentStep?: number;
  arrowDirection?: 'top' | 'left' | 'bottom' | 'right';
  hasOverlay?: boolean;
  arrowPositionOverride?: string;
  continueButton: {
    text: string;
    onClick: () => void;
  };
  backButton: {
    text: string;
    onClick: () => void;
  };
  onClose: () => void;
  variant?: 'secondary' | 'tertiary';
}

const BUTTON_BASE_CLASS = 'text-sm font-semibold px-4 py-2 rounded-md';
const COLOR_MAP = Object.freeze({
  secondary: {
    cardBgColor: 'bg-action-secondary-50',
    buttonBgColor: 'bg-action-secondary-600',
  },
  tertiary: {
    cardBgColor: 'bg-action-tertiary-50',
    buttonBgColor: 'bg-action-tertiary-600',
  },
});

const ARROW_DIRECTION_MAP = Object.freeze({
  top: 'absolute -top-2 left-1/2 transform -translate-x-1/2',
  left: 'absolute -left-2 top-1/2 transform -translate-y-1/2',
  bottom: 'absolute -bottom-2 left-1/2 transform -translate-x-1/2',
  right: 'absolute -right-2 top-1/2 transform -translate-y-1/2',
});

const WalkthroughCard = ({
  title,
  description,
  totalSteps,
  currentStep,
  arrowDirection,
  hasOverlay = false,
  arrowPositionOverride,
  continueButton = {
    text: 'Continue',
    onClick: () => {},
  },
  backButton = {
    text: 'Back',
    onClick: () => {},
  },
  onClose,
  variant = 'tertiary',
}: WalkthroughCardProps) => {
  const isFirstStep = currentStep === 1;

  return (
    <Popover className="relative shadow-2xl">
      {hasOverlay && <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />}
      <Popover.Panel static>
        {arrowDirection && (
          <div
            className={cx(
              'w-4 h-4 transform rotate-45 z-1',
              COLOR_MAP[variant].cardBgColor,
              arrowPositionOverride || '',
              ARROW_DIRECTION_MAP[arrowDirection]
            )}
          />
        )}
        <div
          className={cx('relative rounded-lg p-4 flex flex-col space-y-6 w-80 z-10', COLOR_MAP[variant].cardBgColor)}
        >
          <div className="flex space-x-2">
            <TypographyStack>
              <Typography weight="bold" size="lg">
                {title}
              </Typography>
              <Typography weight="medium" colorWeight="700" size="sm">
                {description}
              </Typography>
            </TypographyStack>

            <div>
              <button type="button" onClick={onClose}>
                <XMarkIcon className="w-4 h-4" />
              </button>
            </div>
          </div>

          <div className="flex space-x-2 w-full justify-between items-center">
            {currentStep && totalSteps && (
              <div className="w-full">
                <Typography weight="normal" size="xs" colorWeight="500" className="whitespace-nowrap">
                  {currentStep} of {totalSteps}
                </Typography>
              </div>
            )}
            <div className="flex space-x-2 w-full justify-end">
              {!isFirstStep && (
                <button
                  className={cx(BUTTON_BASE_CLASS, 'border border-surface-300 text-action-primary-900')}
                  onClick={backButton?.onClick}
                  type="button"
                  aria-label="Close"
                >
                  {backButton?.text}
                </button>
              )}
              <button
                className={cx(BUTTON_BASE_CLASS, COLOR_MAP[variant].buttonBgColor, 'text-white')}
                onClick={continueButton?.onClick}
                type="button"
                aria-label="Close"
              >
                {continueButton?.text}
              </button>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default WalkthroughCard;
