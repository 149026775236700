import React from 'react';

import { Styled } from './styled';
import { ToolbarProps } from './types';

export const Toolbar = React.forwardRef<HTMLDivElement, ToolbarProps>(
  ({ shouldShowContent = true, children, $isVertical = false, ...rest }, ref) => {
    return (
      shouldShowContent && (
        <Styled.Toolbar
          ref={ref}
          $isVertical={$isVertical}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        >
          {children}
        </Styled.Toolbar>
      )
    );
  }
);

export const { Divider } = Styled;

export default Toolbar;
