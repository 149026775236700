import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { HomePage } from '@/interfaces/home_page';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import ColorInput from '@/routes/website_builder/_components/Form/ColorInput';
import FontForm from '@/routes/website_builder/_components/Form/PageSpecific/FontForm';
import FormHelper from '@/routes/website_builder/_components/Form/PageSpecific/FormHelper';
import ImageForm from '@/routes/website_builder/_components/Form/PageSpecific/ImageForm';
import SignupForm from '@/routes/website_builder/_components/Form/PageSpecific/SignupForm';
import SelectInput from '@/routes/website_builder/_components/Form/SelectInput';
import SwitchInput from '@/routes/website_builder/_components/Form/SwitchInput';
import TextInput from '@/routes/website_builder/_components/Form/TextInput';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';
import {
  HeroSectionIcon,
  SectionGroupingSlideOut,
} from '@/routes/website_builder/_components/Helpers/SectionGroupingSlideOut';
import {
  BORDER_SIDE_OPTIONS,
  BORDER_WIDTH_OPTIONS,
  HOME_PAGE_HEADER_OPTIONS,
  LOGO_SIZE_OPTIONS,
  OPACITY_OPTIONS,
  PADDING_OPTIONS,
  RADIUS_OPTIONS,
  SHADOW_OPTIONS,
} from '@/routes/website_builder/_types/options';
import api from '@/services/swarm';

interface Props {
  homePage?: HomePage;
  handleSave: (payload: any) => void;
  handleSaveMain: (payload: any) => void;
}

const HeroSection = ({ homePage, handleSave, handleSaveMain }: Props) => {
  const { currentPublication, setIsSaving, handleSaved } = usePageContext();
  const queryClient = useQueryClient();
  const isNews = homePage?.general_section.theme.header_type === 'news';
  const isHeadliner = homePage?.general_section.theme.header_type === 'headliner';
  const isDefault = !isNews && !isHeadliner;

  const isContentContainerBgShowing = homePage?.main_section?.content_container?.bg_color !== 'transparent';
  const isContentContainerBorderShowing = homePage?.main_section?.content_container?.border_color !== 'transparent';
  const showOtherContentContainerFields = isContentContainerBgShowing || isContentContainerBorderShowing;

  const generalSectionWithDefaults = homePage?.general_section_with_defaults;
  const mainSectionWithDefaults = homePage?.main_section_with_defaults;

  const handleUploadImage = (type: string, file: File | null) => {
    setIsSaving(true);
    const formData = new FormData();

    if (typeof file !== 'string') {
      formData.append(`home_page[${type}]`, file || '');
    }

    formData.append('publication_id', currentPublication?.id || '');

    api
      .patch(`/home_pages/${homePage?.id}`, formData)
      .then(() => {
        queryClient.invalidateQueries(['home_page', currentPublication?.id, 'draft']);
        handleSaved();
      })
      .catch((err) => {
        setIsSaving(false);
        toast.error(err?.response?.data?.error || 'Something went wrong');
      });
  };

  return (
    <SectionGroupingSlideOut
      title="Hero Section"
      sectionIcon={<HeroSectionIcon />}
      showPlanBadge
      highlightProps={{
        sectionId: 'home-hero-section',
      }}
    >
      <FormHelper
        label="Page Theme"
        actionLabel="Styles"
        alwaysShowingChildren={
          <SelectInput
            label="Hero Section Pattern"
            options={HOME_PAGE_HEADER_OPTIONS}
            value={generalSectionWithDefaults?.theme.header_type || 'default'}
            onSave={(value: string) => {
              handleSave({
                ...homePage?.general_section,
                theme: {
                  ...homePage?.general_section.theme,
                  header_type: value,
                },
              });
            }}
          />
        }
      >
        <ColorInput
          label="Background Color"
          resettable={{
            label: 'Global Background Color',
            resetValue: null,
            check: homePage?.general_section?.theme.bg_color !== null,
          }}
          color={generalSectionWithDefaults?.theme.bg_color}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.general_section,
              theme: {
                ...homePage?.general_section.theme,
                bg_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Heading Color"
          resettable={{
            label: 'Global Heading Color',
            resetValue: null,
            check: homePage?.general_section?.theme.text_color !== null,
          }}
          color={generalSectionWithDefaults?.theme.text_color}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.general_section,
              theme: {
                ...homePage?.general_section.theme,
                text_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Accent Color"
          tooltip={{
            id: 'home-page-hero-section-accent-color-tooltip',
            text: 'Applied to labels in the featured posts & widgets section of the page.',
          }}
          color={generalSectionWithDefaults?.theme.accent_color}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.general_section,
              theme: {
                ...homePage?.general_section.theme,
                accent_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Body Color"
          resettable={{
            label: 'Global Body Color',
            resetValue: null,
            check: homePage?.general_section?.theme.body_color !== null,
          }}
          color={generalSectionWithDefaults?.theme.body_color}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.general_section,
              theme: {
                ...homePage?.general_section.theme,
                body_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Border Color"
          resettable={{
            label: 'Global Border Color',
            resetValue: null,
            check: homePage?.general_section?.theme.border_color !== null,
          }}
          color={generalSectionWithDefaults?.theme.border_color}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.general_section,
              theme: {
                ...homePage?.general_section.theme,
                border_color: color,
              },
            });
          }}
        />
      </FormHelper>

      <SignupForm
        label="Signup Widget"
        textValue={mainSectionWithDefaults?.signup_widget.cta_text || ''}
        placeholderTextValue={mainSectionWithDefaults?.signup_widget.placeholder_text || ''}
        onSaveText={(value) => {
          handleSaveMain({
            ...homePage?.main_section,
            signup_widget: {
              ...homePage?.main_section.signup_widget,
              cta_text: value,
            },
          });
        }}
        onSavePlaceholderText={(value) => {
          handleSaveMain({
            ...homePage?.main_section,
            signup_widget: {
              ...homePage?.main_section.signup_widget,
              placeholder_text: value,
            },
          });
        }}
        onSave={(key, value) => {
          handleSaveMain({
            ...homePage?.main_section,
            signup_widget: {
              ...homePage?.main_section.signup_widget,
              [key]: value,
            },
          });
        }}
        editableProps={{
          bg_color: {
            type: 'colorInput',
            label: 'Background',
            value: mainSectionWithDefaults?.signup_widget.bg_color,
            resetCheckValue: homePage?.main_section.signup_widget.bg_color,
            resetLabel: 'Subscribe Widget Background Color',
          },
          border_color: {
            type: 'colorInput',
            label: 'Border',
            value: mainSectionWithDefaults?.signup_widget.border_color,
            resetCheckValue: homePage?.main_section.signup_widget.border_color,
            resetLabel: 'Subscribe Widget Border Color',
          },
          font: {
            type: 'fontFamilySelect',
            label: 'Font Family',
            value: mainSectionWithDefaults?.signup_widget.font,
            resetCheckValue: homePage?.main_section.signup_widget.font,
            resetLabel: 'Subscribe Widget Font Family',
          },
          text_color: {
            type: 'colorInput',
            label: 'Text',
            value: mainSectionWithDefaults?.signup_widget.text_color,
            resetCheckValue: homePage?.main_section.signup_widget.text_color,
            resetLabel: 'Subscribe Widget Button Text Color',
          },
        }}
      />

      {isDefault && (
        <>
          <ImageForm
            label="Background"
            dimensions="Upload a high-quality 1920px x 1080px PNG for best results."
            saveKey="main_bg_image"
            handleUploadImage={handleUploadImage}
            alwaysShowingChildren
            imageValue={homePage?.main_bg_image_url || ''}
          >
            <ColorInput
              label="Background Color"
              color={mainSectionWithDefaults?.general.bg_color}
              resettable={{
                label: 'Global Background Color',
                resetValue: null,
                check: homePage?.main_section?.general.bg_color !== null,
              }}
              onSave={(color: string) => {
                handleSaveMain({
                  ...homePage?.main_section,
                  general: {
                    ...homePage?.main_section.general,
                    bg_color: color,
                  },
                });
              }}
            />
            <SelectInput
              label="Gloss Opacity"
              options={OPACITY_OPTIONS}
              value={mainSectionWithDefaults?.general.bg_gloss_opacity || ''}
              onSave={(value: string) => {
                handleSaveMain({
                  ...homePage?.main_section,
                  general: {
                    ...homePage?.main_section.general,
                    bg_gloss_opacity: value,
                  },
                });
              }}
            />
          </ImageForm>

          <PropertiesGrouping label="Content Container" indent>
            <ColorInput
              label="Background Color"
              color={mainSectionWithDefaults?.content_container.bg_color}
              resettable={{
                label: 'Transparent',
                resetValue: 'transparent',
                check: homePage?.main_section?.content_container.bg_color !== 'transparent',
              }}
              onSave={(color: string) => {
                handleSaveMain({
                  ...homePage?.main_section,
                  content_container: {
                    ...homePage?.main_section.content_container,
                    bg_color: color,
                  },
                });
              }}
            />
            <ColorInput
              label="Border Color"
              color={mainSectionWithDefaults?.content_container.border_color}
              resettable={{
                label: 'Transparent',
                resetValue: 'transparent',
                check: homePage?.main_section?.content_container.border_color !== 'transparent',
              }}
              onSave={(color: string) => {
                handleSaveMain({
                  ...homePage?.main_section,
                  content_container: {
                    ...homePage?.main_section.content_container,
                    border_color: color,
                  },
                });
              }}
            />
            {showOtherContentContainerFields && (
              <>
                <SelectInput
                  label="Padding"
                  options={PADDING_OPTIONS}
                  value={mainSectionWithDefaults?.content_container.padding || 'none'}
                  onSave={(value: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      content_container: {
                        ...homePage?.main_section.content_container,
                        padding: value,
                      },
                    });
                  }}
                />
                <SelectInput
                  label="Border Radius"
                  options={RADIUS_OPTIONS}
                  value={mainSectionWithDefaults?.content_container.border_radius || 'none'}
                  onSave={(value: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      content_container: {
                        ...homePage?.main_section.content_container,
                        border_radius: value,
                      },
                    });
                  }}
                />
                <SelectInput
                  label="Border Width"
                  options={BORDER_WIDTH_OPTIONS}
                  value={mainSectionWithDefaults?.content_container.border_width || 'none'}
                  onSave={(value: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      content_container: {
                        ...homePage?.main_section.content_container,
                        border_width: value,
                      },
                    });
                  }}
                />
                <SelectInput
                  label="Border Sides"
                  options={BORDER_SIDE_OPTIONS}
                  value={mainSectionWithDefaults?.content_container.border_sides || 'all'}
                  onSave={(value: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      content_container: {
                        ...homePage?.main_section.content_container,
                        border_sides: value,
                      },
                    });
                  }}
                />
              </>
            )}
          </PropertiesGrouping>

          <PropertiesGrouping label="Logo" indent>
            <SwitchInput
              label="Enabled"
              name="main-section-logo-enabled"
              enabled={mainSectionWithDefaults?.logo.enabled || false}
              onSave={(value: boolean) => {
                handleSaveMain({
                  ...homePage?.main_section,
                  logo: {
                    ...homePage?.main_section.logo,
                    enabled: value,
                  },
                });
              }}
            />
            {mainSectionWithDefaults?.logo.enabled && (
              <>
                <SelectInput
                  label="Size"
                  options={LOGO_SIZE_OPTIONS}
                  value={mainSectionWithDefaults?.logo.size || 'none'}
                  onSave={(value: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      logo: {
                        ...homePage?.main_section.logo,
                        size: value,
                      },
                    });
                  }}
                />
                <SelectInput
                  label="Shadow"
                  options={SHADOW_OPTIONS}
                  value={mainSectionWithDefaults?.logo.shadow || ''}
                  onSave={(value: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      logo: {
                        ...homePage?.main_section.logo,
                        shadow: value,
                      },
                    });
                  }}
                />
              </>
            )}
          </PropertiesGrouping>

          <FontForm
            nodeId="headline"
            label="Headline"
            supportsMarkdown
            textValue={mainSectionWithDefaults?.headline.text || ''}
            onSaveText={(value) => {
              handleSaveMain({
                ...homePage?.main_section,
                headline: {
                  ...homePage?.main_section.headline,
                  text: value,
                },
              });
            }}
            onSave={(key, value) => {
              handleSaveMain({
                ...homePage?.main_section,
                headline: {
                  ...homePage?.main_section.headline,
                  [key]: value,
                },
              });
            }}
            editableProps={{
              color: {
                type: 'colorInput',
                value: mainSectionWithDefaults?.headline.color || '',
                resetCheckValue: homePage?.main_section?.headline?.color,
                resetLabel: 'Global Headline Color',
              },
              font: { type: 'fontFamilySelect', value: mainSectionWithDefaults?.headline.font || '' },
              size: { type: 'fontSizeSelect', value: mainSectionWithDefaults?.headline.size || '' },
            }}
          />

          <FontForm
            nodeId="description"
            label="Body"
            rows={3}
            supportsMarkdown
            textValue={mainSectionWithDefaults?.description.text || ''}
            onSaveText={(value) => {
              handleSaveMain({
                ...homePage?.main_section,
                description: {
                  ...homePage?.main_section.description,
                  text: value,
                },
              });
            }}
            onSave={(key, value) => {
              handleSaveMain({
                ...homePage?.main_section,
                description: {
                  ...homePage?.main_section.description,
                  [key]: value,
                },
              });
            }}
            editableProps={{
              color: {
                type: 'colorInput',
                value: mainSectionWithDefaults?.description.color,
                resetCheckValue: homePage?.main_section?.description?.color,
                resetLabel: 'Global Body Color',
              },
              font: { type: 'fontFamilySelect', value: mainSectionWithDefaults?.description.font },
              size: { type: 'fontSizeSelect', value: mainSectionWithDefaults?.description.size },
            }}
          />

          <PropertiesGrouping label="Author Widget" indent>
            <SwitchInput
              label="Enabled"
              name="main-section-authors-widget-enabled"
              enabled={mainSectionWithDefaults?.authors_widget.enabled || false}
              onSave={(value: boolean) => {
                handleSaveMain({
                  ...homePage?.main_section,
                  authors_widget: {
                    ...homePage?.main_section.authors_widget,
                    enabled: value,
                  },
                });
              }}
            />
            {mainSectionWithDefaults?.authors_widget.enabled && (
              <>
                <ColorInput
                  label="Text Color"
                  color={mainSectionWithDefaults?.authors_widget.text_color}
                  resettable={{
                    label: 'Global Heading Color',
                    resetValue: null,
                    check: homePage?.main_section?.authors_widget.text_color !== null,
                  }}
                  onSave={(color: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      authors_widget: {
                        ...homePage?.main_section.authors_widget,
                        text_color: color,
                      },
                    });
                  }}
                />
                <TextInput
                  name="authors_widget_label"
                  label="Label"
                  placeholderText="Customize the label"
                  value={mainSectionWithDefaults?.authors_widget.label || ''}
                  onSave={(value: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      authors_widget: {
                        ...homePage?.main_section.authors_widget,
                        label: value,
                      },
                    });
                  }}
                />
                <TextInput
                  name="authors_widget_text"
                  label="Text"
                  placeholderText="Author name"
                  value={mainSectionWithDefaults?.authors_widget.text || ''}
                  onSave={(value: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      authors_widget: {
                        ...homePage?.main_section.authors_widget,
                        text: value,
                      },
                    });
                  }}
                />
              </>
            )}
          </PropertiesGrouping>
          <PropertiesGrouping label="Social Widget" indent>
            <SwitchInput
              label="Enabled"
              name="main-section-social-widget-enabled"
              enabled={mainSectionWithDefaults?.social_widget.enabled || false}
              onSave={(value: boolean) => {
                handleSaveMain({
                  ...homePage?.main_section,
                  social_widget: {
                    ...homePage?.main_section.social_widget,
                    enabled: value,
                  },
                });
              }}
            />
            {mainSectionWithDefaults?.social_widget.enabled && (
              <>
                <ColorInput
                  label="Label Color"
                  color={mainSectionWithDefaults?.social_widget.text_color}
                  resettable={{
                    label: 'Global Heading Color',
                    resetValue: null,
                    check: homePage?.main_section?.social_widget.text_color !== null,
                  }}
                  onSave={(color: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      social_widget: {
                        ...homePage?.main_section.social_widget,
                        text_color: color,
                      },
                    });
                  }}
                />
                <ColorInput
                  label="Icons Background Color"
                  color={mainSectionWithDefaults?.social_widget.icons_bg_color}
                  resettable={{
                    label: 'Global Primary Color',
                    resetValue: null,
                    check: homePage?.main_section?.social_widget.icons_bg_color !== null,
                  }}
                  onSave={(color: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      social_widget: {
                        ...homePage?.main_section.social_widget,
                        icons_bg_color: color,
                      },
                    });
                  }}
                />
                <ColorInput
                  label="Icons Text Color"
                  color={mainSectionWithDefaults?.social_widget.icons_text_color}
                  resettable={{
                    label: 'Global Text on Primary Color',
                    resetValue: null,
                    check: homePage?.main_section?.social_widget.icons_text_color !== null,
                  }}
                  onSave={(color: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      social_widget: {
                        ...homePage?.main_section.social_widget,
                        icons_text_color: color,
                      },
                    });
                  }}
                />
                <TextInput
                  name="social_widget_label"
                  label="Label"
                  placeholderText="Customize the label"
                  value={mainSectionWithDefaults?.social_widget.label || ''}
                  onSave={(value: string) => {
                    handleSaveMain({
                      ...homePage?.main_section,
                      social_widget: {
                        ...homePage?.main_section.social_widget,
                        label: value,
                      },
                    });
                  }}
                />
              </>
            )}
          </PropertiesGrouping>
        </>
      )}
    </SectionGroupingSlideOut>
  );
};

export default HeroSection;
