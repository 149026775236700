import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useUpdateForm } from '@/hooks/useForms';
import { Form, FormStatus } from '@/interfaces/form';
import { Button } from '@/ui/Button';

interface Props {
  form: Form;
}

const ArchiveFormButton = ({ form }: Props) => {
  const queryClient = useQueryClient();

  const updateForm = useUpdateForm({
    formId: form.id,
    onSuccess: () => {
      toast.success('Survey archived!');
      queryClient.invalidateQueries(['forms', form.id]);
    },
    muteError: true,
  });

  const handleUpdateSurvey = async () => {
    try {
      await updateForm.mutateAsync({
        status: FormStatus.ARCHIVED,
        title: form?.title,
        description: form?.description,
        ctaText: form?.cta_text,
        thankYouMessage: form?.thank_you_message,
      });
    } catch (error) {
      toast.error('There was an error archiving this survey');
    }
  };

  return (
    <Button type="button" size="xs" variant="primary" onClick={handleUpdateSurvey}>
      Archive
    </Button>
  );
};

export default ArchiveFormButton;
