import { useQueryClient } from 'react-query';

import { useUpdateHomePage } from '../../../../hooks/useHomePages';
import { HomePage } from '../../../../interfaces/home_page';
import { usePageContext } from '../../components/PageContext';

export type Themes =
  | 'light'
  | 'dark'
  | 'secondary'
  | 'sunset'
  | 'sunrise'
  | 'ocean'
  | 'mint'
  | 'neon'
  | 'moonlight'
  | 'candy'
  | '';
type ThemeOption = { label: string; value: Themes };
const THEME_OPTIONS: ThemeOption[] = [
  { label: 'Select an option', value: '' },
  { label: 'Light', value: 'light' },
  { label: 'Dark', value: 'dark' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'Sunset', value: 'sunset' },
  { label: 'Sunrise', value: 'sunrise' },
  { label: 'Ocean', value: 'ocean' },
  { label: 'Mint', value: 'mint' },
  { label: 'Neon', value: 'neon' },
  { label: 'Moonlight', value: 'moonlight' },
  { label: 'Candy', value: 'candy' },
];

interface Theme {
  primaryColor: string;
  textOnPrimaryColor?: string;
  secondaryColor?: string;
  textOnSecondaryColor?: string;
}

const lightTheme = ({ primaryColor, textOnPrimaryColor }: Theme) => ({
  general_section: {
    theme: {
      bg_color: '#ffffff',
      text_color: '#222222',
      border_color: '#e5e7eb',
      accent_color: primaryColor,
    },
  },
  main_section: {
    general: {
      bg_color: '#f1f5f9',
    },
    signup_widget: {
      bg_color: primaryColor,
      text_color: textOnPrimaryColor,
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: '#222222',
    },
    description: {
      color: '#222222',
    },
    authors_widget: {
      text_color: '#222222',
      dropdown_bg_color: '#ffffff',
      dropdown_text_color: '#222222',
    },
    social_widget: {
      text_color: '#222222',
    },
  },
  posts_section: {
    card: {
      bg_color: '#ffffff',
      border_color: '#e5e7eb',
      text_color: '#222222',
      hover_text_color: '#222222',
      premium_text_color: primaryColor,
      tags_bg_color: primaryColor,
      tags_text_color: '#ffffff',
      timestamp_text_color: '#222222',
    },
  },
});

const darkTheme = ({ primaryColor, textOnPrimaryColor }: Theme) => ({
  general_section: {
    theme: {
      bg_color: '#222222',
      text_color: '#ffffff',
      border_color: '#555555',
      accent_color: primaryColor,
    },
  },
  main_section: {
    general: {
      bg_color: '#404040',
    },
    signup_widget: {
      bg_color: primaryColor,
      text_color: textOnPrimaryColor,
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: '#ffffff',
    },
    description: {
      color: '#ffffff',
    },
    authors_widget: {
      text_color: '#ffffff',
      dropdown_bg_color: '#222222',
      dropdown_text_color: '#ffffff',
    },
    social_widget: {
      text_color: '#ffffff',
    },
  },
  posts_section: {
    card: {
      bg_color: '#222222',
      border_color: '#555555',
      text_color: '#ffffff',
      premium_text_color: primaryColor,
      tags_bg_color: primaryColor,
      tags_text_color: '#ffffff',
      timestamp_text_color: '#ffffff',
    },
  },
});

const secondaryTheme = ({ primaryColor, textOnPrimaryColor, secondaryColor, textOnSecondaryColor }: Theme) => ({
  general_section: {
    theme: {
      bg_color: secondaryColor,
      text_color: textOnSecondaryColor,
      border_color: textOnSecondaryColor,
      accent_color: primaryColor,
    },
  },
  main_section: {
    general: {
      bg_color: secondaryColor,
    },
    signup_widget: {
      bg_color: primaryColor,
      text_color: textOnPrimaryColor,
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: textOnSecondaryColor,
    },
    description: {
      color: textOnSecondaryColor,
    },
    authors_widget: {
      text_color: textOnSecondaryColor,
      dropdown_bg_color: secondaryColor,
      dropdown_text_color: textOnSecondaryColor,
    },
    social_widget: {
      text_color: textOnSecondaryColor,
    },
  },
  posts_section: {
    card: {
      bg_color: secondaryColor,
      border_color: secondaryColor,
      text_color: textOnSecondaryColor,
      premium_text_color: primaryColor,
      tags_bg_color: primaryColor,
      tags_text_color: textOnSecondaryColor,
      timestamp_text_color: textOnSecondaryColor,
    },
  },
});

const sunriseTheme = () => ({
  general_section: {
    theme: {
      bg_color: '#f7f9fc',
      text_color: '#232323',
      border_color: '#8eb4f5',
      accent_color: '#ffbf00',
    },
  },
  main_section: {
    general: {
      bg_color: '#8eb4f5',
    },
    signup_widget: {
      bg_color: '#ffbf00',
      text_color: '#ffffff',
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: '#ffffff',
    },
    description: {
      color: '#ffffff',
    },
    authors_widget: {
      text_color: '#232323',
      dropdown_bg_color: '#8eb4f5',
      dropdown_text_color: '#232323',
    },
    social_widget: {
      text_color: '#232323',
    },
  },
  posts_section: {
    card: {
      bg_color: '#ffffff',
      border_color: '#8eb4f5',
      text_color: '#232323',
      premium_text_color: '#ffbf00',
      tags_bg_color: '#ffbf00',
      tags_text_color: '#ffffff',
      timestamp_text_color: '#232323',
    },
  },
});

const sunsetTheme = () => ({
  general_section: {
    theme: {
      bg_color: '#ffe5b4',
      text_color: '#5c3c00',
      border_color: '#f8a978',
      accent_color: '#ff9900',
    },
  },
  main_section: {
    general: {
      bg_color: '#f8a978',
    },
    signup_widget: {
      bg_color: '#ff9900',
      text_color: '#5c3c00',
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: '#ffffff',
    },
    description: {
      color: '#ffffff',
    },
    authors_widget: {
      text_color: '#5c3c00',
      dropdown_bg_color: '#f8a978',
      dropdown_text_color: '#5c3c00',
    },
    social_widget: {
      text_color: '#5c3c00',
    },
  },
  posts_section: {
    card: {
      bg_color: '#ffe5b4',
      border_color: '#f8a978',
      text_color: '#5c3c00',
      premium_text_color: '#ff9900',
      tags_bg_color: '#ff9900',
      tags_text_color: '#ffffff',
      timestamp_text_color: '#5c3c00',
    },
  },
});

const moonlightTheme = () => ({
  general_section: {
    theme: {
      bg_color: '#1a1732',
      text_color: '#ffffff',
      border_color: '#303040',
      accent_color: '#8a65aa',
    },
  },
  main_section: {
    general: {
      bg_color: '#0f0f1c',
    },
    signup_widget: {
      bg_color: '#8a65aa',
      text_color: '#ffffff',
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: '#ffffff',
    },
    description: {
      color: '#ffffff',
    },
    authors_widget: {
      text_color: '#ffffff',
      dropdown_bg_color: '#f8a978',
      dropdown_text_color: '#ffffff',
    },
    social_widget: {
      text_color: '#ffffff',
    },
  },
  posts_section: {
    card: {
      bg_color: '#1a1732',
      border_color: '#8a65aa',
      text_color: '#ffffff',
      premium_text_color: '#8a65aa',
      tags_bg_color: '#8a65aa',
      tags_text_color: '#ffffff',
      timestamp_text_color: '#ffffff',
    },
  },
});

const oceanTheme = () => ({
  general_section: {
    theme: {
      bg_color: '#eaf6ff',
      text_color: '#074b8f',
      border_color: '#3d95d1',
      accent_color: '#1e7cb7',
    },
  },
  main_section: {
    general: {
      bg_color: '#3d95d1',
    },
    signup_widget: {
      bg_color: '#1e7cb7',
      text_color: '#ffffff',
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: '#ffffff',
    },
    description: {
      color: '#ffffff',
    },
    authors_widget: {
      text_color: '#ffffff',
      dropdown_bg_color: '#eaf6ff',
      dropdown_text_color: '#074b8f',
    },
    social_widget: {
      text_color: '#ffffff',
    },
  },
  posts_section: {
    card: {
      bg_color: '#eaf6ff',
      border_color: '#3d95d1',
      text_color: '#074b8f',
      premium_text_color: '#1e7cb7',
      tags_bg_color: '#1e7cb7',
      tags_text_color: '#ffffff',
      timestamp_text_color: '#074b8f',
    },
  },
});

const mintTheme = () => ({
  general_section: {
    theme: {
      bg_color: '#d2f2f0',
      text_color: '#00303f',
      border_color: '#58a8a7',
      accent_color: '#2b7a78',
    },
  },
  main_section: {
    general: {
      bg_color: '#58a8a7',
    },
    signup_widget: {
      bg_color: '#2b7a78',
      text_color: '#ffffff',
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: '#ffffff',
    },
    description: {
      color: '#ffffff',
    },
    authors_widget: {
      text_color: '#ffffff',
      dropdown_bg_color: '#d2f2f0',
      dropdown_text_color: '#ffffff',
    },
    social_widget: {
      text_color: '#ffffff',
    },
  },
  posts_section: {
    card: {
      bg_color: '#d2f2f0',
      border_color: '#58a8a7',
      text_color: '#00303f',
      premium_text_color: '#2b7a78',
      tags_bg_color: '#2b7a78',
      tags_text_color: '#ffffff',
      timestamp_text_color: '#00303f',
    },
  },
});

const neonTheme = () => ({
  general_section: {
    theme: {
      bg_color: '#262626',
      text_color: '#ffffff',
      border_color: '#00e5ff',
      accent_color: '#00e5ff',
    },
  },
  main_section: {
    general: {
      bg_color: '#404040',
    },
    signup_widget: {
      bg_color: '#00e5ff',
      text_color: '#ffffff',
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: '#ffffff',
    },
    description: {
      color: '#ffffff',
    },
    authors_widget: {
      text_color: '#ffffff',
      dropdown_bg_color: '#262626',
      dropdown_text_color: '#ffffff',
    },
    social_widget: {
      text_color: '#ffffff',
    },
  },
  posts_section: {
    card: {
      bg_color: '#333333',
      border_color: '#00e5ff',
      text_color: '#ffffff',
      premium_text_color: '#00e5ff',
      tags_bg_color: '#00e5ff',
      tags_text_color: '#ffffff',
      timestamp_text_color: '#ffffff',
    },
  },
});

const candyTheme = () => ({
  general_section: {
    theme: {
      bg_color: '#f5f5f5',
      text_color: '#232323',
      border_color: '#ff0099',
      accent_color: '#00cc99',
    },
  },
  main_section: {
    general: {
      bg_color: '#ff0099',
    },
    signup_widget: {
      bg_color: '#00cc99',
      text_color: '#ffffff',
    },
    content_container: {
      bg_color: 'transparent',
      border_color: 'transparent',
    },
    headline: {
      color: '#ffffff',
    },
    description: {
      color: '#ffffff',
    },
    authors_widget: {
      text_color: '#ffffff',
      dropdown_bg_color: '#f5f5f5',
      dropdown_text_color: '#232323',
    },
    social_widget: {
      text_color: '#ffffff',
    },
  },
  posts_section: {
    card: {
      bg_color: '#ffffff',
      border_color: '#ff0099',
      text_color: '#232323',
      premium_text_color: '#00cc99',
      tags_bg_color: '#00cc99',
      tags_text_color: '#ffffff',
      timestamp_text_color: '#232323',
    },
  },
});

const useHomePageThemeUpdater = () => {
  const queryClient = useQueryClient();
  const { pageData, setIsSaving, handleSaved, currentPublication } = usePageContext<HomePage>();

  const updateHomePage = useUpdateHomePage({
    pageId: pageData.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['home_page', currentPublication?.id, 'draft']);
      handleSaved();
    },
  });

  const handleSaveGeneralSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: payload });
  };

  const generateTheme = (theme: Themes) => {
    const primaryColor = currentPublication?.web_theme?.primary_color || '#000000';
    const textOnPrimaryColor = currentPublication?.web_theme?.text_on_primary_color || '#ffffff';
    const secondaryColor = currentPublication?.web_theme?.secondary_color || '#ffffff';
    const textOnSecondaryColor = currentPublication?.web_theme?.text_on_secondary_color || '#000000';

    switch (theme) {
      case 'light':
        return lightTheme({ primaryColor, textOnPrimaryColor });
      case 'dark':
        return darkTheme({ primaryColor, textOnPrimaryColor });
      case 'secondary':
        return secondaryTheme({ primaryColor, textOnPrimaryColor, secondaryColor, textOnSecondaryColor });
      case 'sunset':
        return sunsetTheme();
      case 'sunrise':
        return sunriseTheme();
      case 'ocean':
        return oceanTheme();
      case 'mint':
        return mintTheme();
      case 'neon':
        return neonTheme();
      case 'moonlight':
        return moonlightTheme();
      case 'candy':
        return candyTheme();
      default:
        return () => {};
    }
  };

  const generateGeneralSection = (generalSection: any) => ({
    ...pageData.general_section,
    theme: {
      ...pageData.general_section.theme,
      ...generalSection.theme,
    },
  });

  const generateMainSection = (mainSection: any) => ({
    ...pageData.main_section,
    general: {
      ...pageData.main_section.general,
      ...mainSection.general,
    },
    signup_widget: {
      ...pageData.main_section.signup_widget,
      ...mainSection.signup_widget,
    },
    content_container: {
      ...pageData.main_section.content_container,
      ...mainSection.content_container,
    },
    headline: {
      ...pageData.main_section.headline,
      ...mainSection.headline,
    },
    description: {
      ...pageData.main_section.description,
      ...mainSection.description,
    },
    authors_widget: {
      ...pageData.main_section.authors_widget,
      ...mainSection.authors_widget,
    },
    social_widget: {
      ...pageData.main_section.social_widget,
      ...mainSection.social_widget,
    },
  });

  const generatePostsSection = (postsSection: any) => ({
    ...pageData.posts_section,
    card: {
      ...pageData.posts_section.card,
      ...postsSection.card,
    },
  });

  return {
    themeOptions: THEME_OPTIONS,
    updateTheme: (theme: Themes) => {
      const generatedTheme: any = generateTheme(theme);
      const generalSection = generateGeneralSection(generatedTheme.general_section);
      const mainSection = generateMainSection(generatedTheme.main_section);
      const postsSection = generatePostsSection(generatedTheme.posts_section);

      const payload = {
        general_section: JSON.stringify(generalSection),
        main_section: JSON.stringify(mainSection),
        posts_section: JSON.stringify(postsSection),
      };

      handleSaveGeneralSection(payload);
    },
    updateFonts: (font: any) => {
      const pageCopy = { ...pageData };
      pageCopy.general_section.theme.font = font;
      pageCopy.main_section.headline.font = font;
      pageCopy.main_section.description.font = font;
      pageCopy.main_section.signup_widget.font = font;
      pageCopy.posts_section.card.font = font;
      pageCopy.posts_section.featured_sections.font = font;

      const payload = {
        general_section: JSON.stringify(pageCopy.general_section),
        main_section: JSON.stringify(pageCopy.main_section),
        posts_section: JSON.stringify(pageCopy.posts_section),
      };

      handleSaveGeneralSection(payload);
    },
  };
};
export default useHomePageThemeUpdater;
