import { Link, useNavigate } from "react-router-dom";

import OptionsDropdown from "@/components/OptionsDropdown";
import useResourcePermissions from "@/hooks/useResourcePermissions/useResourcePermissions";
import { NO_PERMISSION_MESSAGE } from "@/interfaces/permissions";
import { PostTemplate } from "@/interfaces/post_template";

import { useTemplateViewContext } from "..";

interface Props {
  template: PostTemplate;
  onDelete: (resourceId: string) => void;
}

const TemplateListItem = ({ template, onDelete }: Props) => {
  const navigate = useNavigate();
  const { resourceType, onSelect } = useTemplateViewContext()
  const {data: recordPermissions, isSuccess: recordPermissionSuccess} = useResourcePermissions({
    resourceType: 'PostTemplate',
    resourceId: template.id,
    policyClass: "Api::V2::PostTemplatePolicy",
  });

  const canDelete = recordPermissionSuccess && recordPermissions.includes('delete');

  const options = [
          [
            {
              label: 'Use this template',
              onClick: () => {
                onSelect(template.id);
              },
            },
          ],
          [
            {
              label: 'View',
              onClick: () => {
                navigate(`/templates/${resourceType}/${template.id}`);
              },
            },
            {
              label: 'Edit',
              onClick: () => {
                navigate(`/templates/${resourceType}/${template.id}/edit`);
              },
            },
          ],
          [
            {
              label: 'Delete',
              color: 'red',
              onClick: () => {
                // eslint-disable-next-line no-alert
                const confirm = window.confirm(`Are you sure? This cannot be undone.`);

                if (confirm) { onDelete(template.id); }
              },
              disabled: !canDelete,
              disabledTooltip: NO_PERMISSION_MESSAGE,
            },
          ],
        ];

  return (
    <li className="p-4 " key={template.id}>
      <div className="flex justify-between items-center">
        <div className="w-2/3">
          <p className="text-gray-800 font-semibold truncate">
            <Link to={`/templates/${resourceType}/${template.id}`} className="hover:text-gray-900">
              {template.name}
            </Link>
          </p>
        </div>
        <div>
          <OptionsDropdown options={options} />
        </div>
      </div>
    </li>
  )
};

export default TemplateListItem;
