import { FC } from 'react';

import NavContainer from '../NavContainer';

import KPIS from './KPIS';
import Leaderboard from './Leaderboard';
import MilestoneBreakdownChart from './MilestoneBreakdownChart';
import SubscriberReferralChart from './SubscriberReferralChart';

const Overview: FC = () => (
  <NavContainer>
    <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
      <div className="grid grid-cols-1 gap-4 lg:col-span-2">
        <KPIS />
        <MilestoneBreakdownChart />
        <SubscriberReferralChart className="sm:w-full md:w-3/6" />
      </div>
      <div>
        <Leaderboard />
      </div>
    </div>
  </NavContainer>
);

export default Overview;
