import { PropsWithChildren } from 'react';
import cx from 'classnames';

import { ALIGN_CLASSNAME_MAP } from './constants';
import { AlignType } from './types';

type Props = PropsWithChildren<{
  className?: string;
  align?: AlignType;
  isSticky?: boolean;
  rowSpan?: number;
  colSpan?: number;
}>;

export const ItemColumn = ({
  children,
  className,
  align = AlignType.CENTER,
  isSticky = false,
  rowSpan,
  colSpan,
}: Props) => (
  <td
    rowSpan={rowSpan}
    colSpan={colSpan}
    className={cx(
      'relative',
      isSticky && 'max-[640px]:sticky max-[640px]:z-0 max-[640px]:right-0 max-[640px]:bg-white',
      ALIGN_CLASSNAME_MAP[align]
    )}
  >
    <div className={cx('py-3 px-2.5', className, isSticky && 'max-[640px]:border-l max-[640px]:border-gray-100')}>
      {children}
    </div>
  </td>
);
