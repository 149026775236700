import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Tag } from '../interfaces/tag';
import api from '../services/swarm';

interface ApiResponse {
  tags: Tag[];
}

export default function useTags() {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchTags = () =>
    api
      .get(`/tags`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<ApiResponse>('tags', fetchTags, {
    staleTime: 1000 * 60 * 30, // 30 minutes
  });
}
