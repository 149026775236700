import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import SubscriberTagSelectInput from '@/components/SubscriberTags/SubscriberTagSelectInput';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PublicationSubscriberTagReference, PublicationSubscriberTagSelectItem } from '@/interfaces/subscriber_tag';
import api from '@/services/swarm';

interface ApplyTagsModalProps {
  segmentId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const ApplyTagsModal = ({ segmentId, isOpen, onClose }: ApplyTagsModalProps) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState<PublicationSubscriberTagSelectItem[]>([]);
  const transformedSelectedTags: PublicationSubscriberTagReference[] = selectedTags.map((tag) => ({
    id: tag.value,
  }));

  const handleDeselect = () => {
    setSelectedTags([]);
  };

  const handleSelect = (tag: PublicationSubscriberTagSelectItem) => {
    const selected: PublicationSubscriberTagSelectItem = {
      id: tag.value,
      label: tag.label,
      value: tag.value,
      color: tag.color,
      deletableId: tag.value,
    };
    setSelectedTags([selected]);
  };

  const onApplyTags = () => {
    const selectedTagId = selectedTags[0]?.id || '';
    const params = {
      publication_subscriber_tag_id: selectedTagId,
      segment_id: segmentId,
    };
    setIsLoading(true);
    api
      .post(`/subscription_subscriber_tags/bulk_create`, {
        publication_id: currentPublicationId,
        subscriber_tag: params,
      })
      .then(() => {
        toast.success('Tags applied successfully');
        setSelectedTags([]);
        onClose();
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      onProceed={onApplyTags}
      resourceId={segmentId || ''}
      isWorking={isLoading}
      headerText="Apply Tags"
      actionText="Add"
      disabled={!selectedTags.length}
      overflow="visible"
    >
      <SubscriberTagSelectInput
        selectValue="id"
        selectedTags={transformedSelectedTags}
        handleDeselect={handleDeselect}
        handleSelect={handleSelect}
      />
    </ActionModal>
  );
};

export default ApplyTagsModal;
