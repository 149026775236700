import { Duration } from '@/interfaces/general';

// Given a value in seconds, we want to make the best guess as to what
// unit we should use when displaying it as by default.
export const durationFromSeconds = (secondsValue: number) => {
  if (secondsValue === 0) return Duration.MINUTES;
  if (secondsValue % 86400 === 0) return Duration.DAYS;
  if (secondsValue % 3600 === 0) return Duration.HOURS;
  return Duration.MINUTES;
};

export const DURATION_UNIT_LABELS = {
  [Duration.MINUTES]: 'minute',
  [Duration.HOURS]: 'hour',
  [Duration.DAYS]: 'day',
};

// Convert seconds to the correct display value for whatever unit is selected
export const secondsToDuration = (secondsValue: number, durationUnit: Duration) => {
  if (durationUnit === Duration.DAYS) return secondsValue / 86400;
  if (durationUnit === Duration.HOURS) return secondsValue / 3600;
  return secondsValue / 60;
};

// Convert the specified value back to seconds given a delay unit
export const secondsForDuration = (secondsValue: number, durationUnit: Duration) => {
  if (durationUnit === Duration.DAYS) return secondsValue * 86400;
  if (durationUnit === Duration.HOURS) return secondsValue * 3600;
  return secondsValue * 60;
};
