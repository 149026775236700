import { useEffect, useState } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';

import PageContainer from '@/routes/settings/_components/PageContainer';

import Banner from '../../../Banner';

const useDismiss = (): [boolean, () => void] => {
  const [dismissed, setDismissed] = useState(true);

  useEffect(() => {
    if (!document.cookie.includes('dismissSettingsV2Banner=true')) {
      setDismissed(false);
    }
  }, []);

  const handleDismiss = () => {
    const date = new Date();

    // expire in 1 day
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

    document.cookie = `dismissSettingsV2Banner=true;expires=${date.toUTCString()};path=/`;

    setDismissed(true);
  };

  return [dismissed, handleDismiss];
};

const SettingsV2Banner = () => {
  const [dismissed, handleDismiss] = useDismiss();

  const handleClick = () => {
    window.open(
      'https://www.beehiiv.com/support/article/27437625541015-Settings-menu-updates-what-moved-where',
      '_blank',
      'noreferrer'
    );
  };

  if (dismissed) return null;

  return (
    <div className="flex flex-row w-full justify-center">
      <PageContainer>
        <Banner
          variant="info"
          title="We've redesigned your settings pages"
          bodyText="To improve your beehiiv experience, we've moved around some settings. To learn more, visit the Knowledge Base."
          ctaText="Knowledge Base"
          ctaIcon={<ArrowTopRightOnSquareIcon className="w-4 h-4" />}
          onClick={handleClick}
          onClose={handleDismiss}
          isScreenWide={false}
        />
      </PageContainer>
    </div>
  );
};

export default SettingsV2Banner;
