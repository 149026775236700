import { useQuery } from 'react-query';
import { Author } from '@shared/dream-components';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface UseAuthorsProps {
  enabled?: boolean;
}

interface UseAuthorsResponse {
  authors: Author[];
}

const useAuthors = ({ enabled }: UseAuthorsProps = {}) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<UseAuthorsResponse>(
    ['dream_editor/authors', currentPublicationId],
    () =>
      api
        .get('/dream_editor/authors', {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!currentPublicationId && enabled,
    }
  );
};

export default useAuthors;
