import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{ colors: string[] }>`
  :root {
    // default: Helvetica,Arial,sans-serif;
    --font-family-0: Helvetica,sans-serif;
    --font-family-1: Arial,Helvetica,sans-serif;
    --font-family-2: Courier,'Lucida Typewriter',monospace;
    --font-family-3: Didot,'Didot LT STD',Garamond,'Times New Roman',serif;
    --font-family-4: Garamond,Baskerville,'Times New Roman',serif;
    --font-family-5: Georgia,'Times New Roman',serif;
    --font-family-6: "Lucida Console,Monaco,monospace";
    --font-family-7: Monaco,'Lucida Console',monospace;
    --font-family-8: Palatino,'Palatino Linotype','Palatino LT STD',Georgia,serif;
    --font-family-9: 'Times New Roman',Baskerville,Georgia,serif;
    --font-family-10: 'Trebuchet MS','Lucida Grande',Tahoma,sans-serif;
    --font-family-11: Verdana,Geneva,sans-serif;
    --font-family-12: Alegreya Sans,Georgia,serif;
    --font-family-13: Alegreya,Merriweather,Georgia,serif;
    --font-family-14: Cormorant,Playfair Display,Didot,serif;
    --font-family-15: DM Sans,Lato,Montserrat,sans-serif;
    --font-family-16: Eczar,Lora,'DejaVu Serif',Georgia,serif;
    --font-family-17: IBM Plex Sans,'Segoe UI',Roboto,sans-serif;
    --font-family-18: Inter,Roboto,sans-serif;
    --font-family-19: Karla,'Lucida Grande','Trebuchet MS',sans-serif;
    --font-family-20: Lato,'Open Sans','Segoe UI',Tahoma,sans-serif;
    --font-family-21: Libre Baskerville,'DejaVu Serif',Garamond,Georgia,serif;
    --font-family-22: Libre Franklin,'Source Sans Pro','Trebuchet MS',Helvetica,sans-serif;
    --font-family-23: Noto Sans,Roboto,'Open Sans',Arial,sans-serif;
    --font-family-24: Oswald,Montserrat,'Lucida Sans Unicode',sans-serif;
    --font-family-25: PT Sans,Helvetica,'Arial Unicode MS',sans-serif;
    --font-family-26: PT Serif,Georgia,'Times New Roman',serif;
    --font-family-27: Raleway,'Open Sans',Montserrat,sans-serif;
    --font-family-28: Source Sans 3,'Source Sans Pro',Roboto,sans-serif;
    --font-family-29: Space Grotesk,Helvetica,Arial,sans-serif;
    --font-family-30: Space Mono,Courier,'Lucida Console',Monaco,monospace;
    --font-family-31: Syne,Oswald,Montserrat,'Gill Sans',sans-serif;
    --font-family-32: Work Sans,'Lucida Grande',Verdana,sans-serif;

    --font-size-small: 0.6rem;
    --font-size-medium: 0.8rem;
    --font-size-default: 1rem;
    --font-size-large: 1.5rem;
    --font-size-x-large: 2rem;
    --font-size-huge: 3rem;

    --color-1: ${({ colors }) => colors[0]};
    --color-2: ${({ colors }) => colors[1]};
    --color-3: ${({ colors }) => colors[2]};
    --color-4: ${({ colors }) => colors[3]};
    --color-5: ${({ colors }) => colors[4]};
    --color-6: ${({ colors }) => colors[5]};
  }

  .threads-sidebar {
    @media screen and (min-width: 768px) {
      width: 66.6666666667%;
    }

    @media screen and (min-width: 1024px) {
      width: 50%;
    }

    @media screen and (min-width: 1280px) {
      width: 33.3333333333%;
    }
  }
`;

export default GlobalStyles;
