import { Fragment } from 'react';
import { Disclosure } from '@headlessui/react';
import cx from 'classnames';

import useQuestionResponses from '../../../hooks/useForms/useQuestionResponses';
import { QuestionResponse } from '../../../interfaces/form';

interface Props {
  formId: string | undefined;
  formResponseId: string | undefined;
  shouldFetch: boolean;
  formQuestions: any[];
}

const QuestionResponses = ({ formId, formResponseId, shouldFetch = false, formQuestions }: Props) => {
  const { data: responses, isLoading } = useQuestionResponses(formId, formResponseId, shouldFetch);

  if (isLoading) {
    return (
      <Disclosure.Panel className="bg-gray-50" as="tr">
        <td colSpan={5} className="px-6 pl-8 py-3 whitespace-nowrap text-xs font-semibold">
          Loading...
        </td>
      </Disclosure.Panel>
    );
  }

  return (
    <>
      <Disclosure.Panel className="bg-gray-50" as="tr">
        <td className="px-6 pl-8 py-3 whitespace-nowrap text-xs font-semibold">Question</td>
        <td colSpan={4} className="px-6 pl-8 py-3 whitespace-nowrap text-xs font-semibold">
          Answer
        </td>
      </Disclosure.Panel>
      {formQuestions?.map((question, idx) => {
        const questionResponses = responses
          ?.filter((response: QuestionResponse) => response.form_question_id === question.id)
          ?.map((response) => (
            <span key={response.id} className="block">
              {response.answer}
            </span>
          ));

        return (
          <Disclosure.Panel key={question.id} className={cx(idx % 2 ? 'bg-gray-50' : 'bg-gray-100')} as="tr">
            <td colSpan={5}>
              <div className="px-6 pl-8 py-3 w-1/5 float-left text-xs italic">{question.prompt}</div>
              <div className="px-6 pl-8 py-3 w-4/5 float-left text-xs italic">{questionResponses || '-'}</div>
            </td>
          </Disclosure.Panel>
        );
      })}
    </>
  );
};

export default QuestionResponses;
