import { PropsWithChildren } from 'react';
import cx from 'classnames';

const GAP_MAP = Object.freeze({
  '0': 'space-x-0',
  '1': 'space-x-1',
  '2': 'space-x-2',
  '3': 'space-x-3',
  '4': 'space-x-4',
});

const ALIGN_MAP = Object.freeze({
  center: 'items-center',
  start: 'items-start',
  end: 'items-end',
});

type Props = PropsWithChildren<{
  className?: string;
  align?: keyof typeof ALIGN_MAP;
  gap?: keyof typeof GAP_MAP;
}>;

const TypographyRow = ({ children, className, align = 'center', gap = '1' }: Props) => {
  return <div className={cx(className, 'flex text-left', ALIGN_MAP[align], GAP_MAP[gap])}>{children}</div>;
};

export default TypographyRow;
