import { useState } from 'react';
import toast from 'react-hot-toast';

import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import useWallet from '../../hooks/boosts/useWallet';
import api from '../../services/swarm';

const AddFundsButton = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const walletQuery = useWallet();
  const { data } = walletQuery;
  const [checkoutSessionProcessing, setCheckoutSessionProcessing] = useState(false);

  const handleCreateSessionRequest = () => {
    if (currentPublicationId) {
      const params = { publication_id: currentPublicationId };
      setCheckoutSessionProcessing(true);

      api
        .get('/boosts/grow/create_checkout_sessions', { params })
        .then((res) => {
          analytics.track('Clicked Add Funds');
          window.open(`${res.data.url}`, '_blank');
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.message || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setCheckoutSessionProcessing(false);
        });
    }
  };

  const { settings } = useSettings();
  if (settings && (!settings.organization_boosts || !settings.boosts)) return null;

  return (
    <Button loading={checkoutSessionProcessing} type="button" onClick={handleCreateSessionRequest}>
      Add funds
      {data?.available_balance && <span className="font-mono ml-2 text-gray-500">({data.available_balance})</span>}
    </Button>
  );
};

export default AddFundsButton;
