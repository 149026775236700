import { Option } from '@/interfaces/general';

import { automationActionOptions, behaviorActionOptions, emailActionOptions } from './nameOptions';
import { NameTypes } from './types';

export const subscriberDataLabel: Option = {
  label: 'Subscriber data',
  value: '',
  isOptGroup: true,
};
export const emailActionLabel: Option = {
  label: 'Email data',
  value: '',
  isOptGroup: true,
};
export const automationActionLabel: Option = {
  label: 'Automation data',
  value: '',
  isOptGroup: true,
};
export const behaviorActionLabel: Option = {
  label: 'Behavior data',
  value: '',
  isOptGroup: true,
};

export const subscriberDataOptions: Option[] = [
  {
    label: 'Attribute',
    value: NameTypes.ATTRIBUTE,
  },
  {
    label: 'Engagement',
    value: NameTypes.MEASURE,
  },
];

export const conditionOptions: Option[] = [
  subscriberDataLabel,
  ...subscriberDataOptions,
  emailActionLabel,
  ...emailActionOptions,
  automationActionLabel,
  ...automationActionOptions,
  behaviorActionLabel,
  ...behaviorActionOptions,
];
