import { useCallback, useEffect, useMemo, useState } from 'react';
import { TCollabThread, TiptapCollabProvider } from '@hocuspocus/provider';
import { Editor as TiptapEditor, JSONContent } from '@tiptap/react';

import ActionModal from '@/components/ActionModal';
import { FakeProgress } from '@/components/FakeProgress';
import { EditorUser } from '@/components/TiptapEditor/lib/types';
import { focusThreadInEditor } from '@/components/TiptapEditor/lib/utils/focusThreadInEditor';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { usePrompt, useReloadAlert } from '@/hooks';
import Editor from '@/pages/Post/Edit/Editor/indexv2';
import { EditorContext, EditorContextContent } from '@/pages/Post/Edit/EditorContext';

import { usePostContext } from '../PostContext';

const ComposeView = () => {
  const { formData } = usePostContext();
  const { settings } = useSettings();
  const collaborationEnabled = useMemo(() => !!settings?.collaborative_editing, [settings?.collaborative_editing]);
  const [provider, setProvider] = useState<TiptapCollabProvider>();
  const [editor, setEditor] = useState<TiptapEditor | null>(null);
  const [editorUsers, setEditorUsers] = useState<EditorUser[]>([]);
  const [wordCount, setWordCount] = useState<number>(0);
  const [showSidebar, setShowSidebar] = useState(true);
  const [openSidebar, setOpenSidebar] = useState<'threads' | 'backlinks' | null>(null);
  const [showSearchAndReplaceMenu, setShowSearchAndReplaceMenu] = useState(false);
  const { currentUser } = useCurrentUser();
  const userId = currentUser?.id;

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [errors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [editorIsLoading, setEditorIsLoading] = useState(false);

  const [loadingNodes, setLoadingNodes] = useState<Record<string, boolean>>({});

  const shouldPrompt = isSaving || unsavedChanges;
  const [isPrompting, confirmPrompt, cancelPrompt] = usePrompt(shouldPrompt);
  useReloadAlert(shouldPrompt, 'You have unsaved changes. Are you sure you want to leave this page?');

  const hasErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    if (isPrompting && !shouldPrompt) {
      confirmPrompt();
    }
  }, [isPrompting, shouldPrompt, confirmPrompt]);

  // threads
  const [showThreadsSidebar, setShowThreadsSidebar] = useState(false);

  const [activeThreadId, setActiveThreadId] = useState<string | null>(null);

  const selectThread = useCallback(
    (threadId: string) => {
      if (!editor) return;

      focusThreadInEditor(editor, threadId);
    },
    [editor]
  );

  const unselectThread = useCallback(() => {
    setActiveThreadId(null);
  }, []);

  const [threads, setThreads] = useState<TCollabThread[] | null>(null);

  const unresolvedThreadsCount = useMemo(() => {
    return threads?.filter((t) => !t.resolvedAt)?.length || 0;
  }, [threads]);

  useEffect(() => {
    if (provider) {
      const updater = () => {
        const currentThreads = provider.getThreads();

        const sortedThreads = [...currentThreads].sort((a, b) => {
          const timeA = new Date(a.createdAt).getTime();
          const timeB = new Date(b.createdAt).getTime();
          return timeB - timeA;
        });

        setThreads(sortedThreads);
      };

      updater();
      provider.watchThreads(updater);

      return () => {
        provider.unwatchThreads(updater);
      };
    }

    return () => {};
  }, [provider]);

  const createThread = useCallback(
    (content: JSONContent) => {
      if (!editor || !userId) return null;

      const chain = editor.chain();

      return chain
        .setThread({
          content,
          data: {
            authorId: userId,
          },
          commentData: {
            authorId: userId,
          },
        })
        .focus()
        .run();
    },
    [editor, userId]
  );

  const highlightThread = useCallback(
    (id: string) => {
      if (!editor) return;

      const { tr } = editor.state;
      tr.setMeta('threadMouseOver', id);
      editor.view.dispatch(tr);
    },
    [editor]
  );

  const removeHighlightThread = useCallback(
    (id: string) => {
      if (!editor) return;

      const { tr } = editor.state;
      tr.setMeta('threadMouseOut', id);
      editor.view.dispatch(tr);
    },
    [editor]
  );

  const providerValue = useMemo<EditorContextContent>(
    () => ({
      editor,
      setEditor,
      editorIsLoading,
      setEditorIsLoading,
      isSaving,
      setIsSaving,
      unsavedChanges,
      setUnsavedChanges,
      wordCount,
      setWordCount,
      provider,
      setProvider,
      formData,
      users: editorUsers,
      setUsers: setEditorUsers,
      showSidebar,
      setShowSidebar,
      openSidebar,
      setOpenSidebar,
      showSearchAndReplaceMenu,
      setShowSearchAndReplaceMenu,
      collaborationEnabled,
      loadingNodes,
      setLoadingNodes,

      // threads
      activeThreadId,
      createThread,
      highlightThread,
      removeHighlightThread,
      selectThread,
      setActiveThreadId,
      setShowThreadsSidebar,
      showThreadsSidebar,
      threads,
      unresolvedThreadsCount,
      unselectThread,
    }),
    [
      editor,
      editorIsLoading,
      editorUsers,
      isSaving,
      provider,
      formData,
      showSidebar,
      openSidebar,
      showSearchAndReplaceMenu,
      unsavedChanges,
      wordCount,
      collaborationEnabled,
      loadingNodes,

      // threads
      activeThreadId,
      createThread,
      highlightThread,
      removeHighlightThread,
      selectThread,
      showThreadsSidebar,
      threads,
      unresolvedThreadsCount,
      unselectThread,
    ]
  );

  return (
    <EditorContext.Provider value={providerValue}>
      <ActionModal
        isOpen={isPrompting}
        onClose={cancelPrompt}
        onProceed={confirmPrompt}
        headerText={hasErrors ? 'Are you sure?' : 'Hang tight...'}
        resourceId="post"
        actionText="Leave anyway"
        isWorking={false}
      >
        {hasErrors ? (
          <>
            Errors are preventing this post from being saved. Are you sure you want to leave? Any unsaved changes will
            be lost.
          </>
        ) : (
          <>
            <p className="mb-4">
              We&apos;re working on saving your latest changes. Once we&apos;re done, you&apos;ll be redirected
              automatically.
            </p>
            {isPrompting && <FakeProgress />}
          </>
        )}
      </ActionModal>
      {formData && <Editor errors={errors} post={formData} />}
    </EditorContext.Provider>
  );
};

export default ComposeView;
