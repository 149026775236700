import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  endpointId: string;
  onSuccess?: (response: any) => void;
  onError?: (err: any) => void;
}

interface TestSvixEndpointParams {
  eventType: string;
}

export default function useTestSvixEndpoint(publicationId: string, { endpointId, onSuccess, onError }: Props) {
  const createSvixEndpointTest = ({ eventType }: TestSvixEndpointParams) =>
    api
      .post(`/webhooks/svix_endpoints/${endpointId}/test`, {
        publication_id: publicationId,
        event_type: eventType,
      })
      .then((res) => res.data);

  return useMutation(createSvixEndpointTest, {
    onSuccess: (response) => {
      onSuccess?.(response);
    },
    onError: (err: any) => {
      if (onError) {
        onError(err);
      } else {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      }
    },
  });
}
