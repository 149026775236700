import { useInfiniteQuery } from 'react-query';

import { ContentImport } from '@/interfaces/content_import';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  content_imports: ContentImport[];
  pagination: Pagination;
}

interface Props {
  publicationId: string;
}

export default function useContentImports({ publicationId }: Props) {
  const fetchContentImports = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/content_imports`, {
        params: {
          publication_id: publicationId,
          page: pageParam,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(['content_imports'], fetchContentImports, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
