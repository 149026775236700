import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';

import { Icon } from '../Icon';

import { Styled } from './styled';
import { HintWrapperProps } from './types';

export const HintWrapper = ({ icon, title, description }: HintWrapperProps) => {
  return (
    <NodeViewWrapper data-drag-handle>
      <Styled.Container contentEditable={false}>
        <Icon name={icon} $size="1.125rem" />
        <Styled.Title>{title}</Styled.Title>
        {description && <Styled.Description>{description}</Styled.Description>}
      </Styled.Container>
    </NodeViewWrapper>
  );
};

export default HintWrapper;
