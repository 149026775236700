const SOCIALS = {
  twitter_url: 'https://twitter.com/user',
  facebook_url: 'https://facebook.com/user',
  instagram_url: 'https://instagram.com/user',
  tiktok_url: 'https://tiktok.com/user',
  youtube_url: 'https://youtube.com/user',
  linkedin_url: 'https://linkedin.com/user',
  discord_url: 'https://discord.com/user',
  threads_url: 'https://threads.com/user',
};

export { SOCIALS };
