import { useInfiniteQuery } from 'react-query';

import { AdNetworkWorkspaceView } from '@/interfaces/ad_network/internal/workspace_view';

import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  workspace_views: AdNetworkWorkspaceView[];
  pagination: Pagination;
}

interface Props {
  query: string;
}

export default function useWorkspaceViews({ query = '' }: Props) {
  const fetchWorkspaceViews = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/internal/workspace_views`, {
        params: {
          page: pageParam,
          per_page: 25,
          query,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['ad_network', 'internal', 'workspace_views', query], fetchWorkspaceViews, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
