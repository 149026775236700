import { FormQuestion, QuestionTypes } from '@/interfaces/form';

import Header from '../../../../../components/Header';

import DropdownPreview from './DropdownPreview';
import FreeFormPreview from './FreeFormPreview';
import LongTextPreview from './LongTextPreview';
import MultipleChoicePreview from './MultipleChoicePreview';

interface Props {
  question: FormQuestion;
}

const Preview = ({ question }: Props) => {
  const isFreeForm = question.question_type === QuestionTypes.FREE_FORM;
  const isMultipleChoice = question.question_type === QuestionTypes.MULTIPLE_CHOICE;
  const isLongText = question.question_type === QuestionTypes.LONG_TEXT;
  const isDropdown = question.question_type === QuestionTypes.DROPDOWN;

  return (
    <div key={question.id} className="space-y-1">
      <Header as="h6">
        <span className="mr-2">{question.order}.</span>
        {question.prompt}
        {question.required && <span className="mr-2">&#42;</span>}
      </Header>
      <div>
        {isLongText && <LongTextPreview question={question} />}
        {isFreeForm && <FreeFormPreview question={question} />}
        {isMultipleChoice && <MultipleChoicePreview question={question} />}
        {isDropdown && <DropdownPreview question={question} />}
      </div>
    </div>
  );
};

export default Preview;
