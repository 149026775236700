import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AdNetworkMetrics } from '@/interfaces/ad_network/publisher/metrics';
import api from '@/services/swarm';

export default function useMetrics() {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<AdNetworkMetrics>(
    [publicationId, 'ad_network', 'publisher', 'metrics'],
    () =>
      api
        .get(`/ad_network/publisher/metrics`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
