import { AdNetworkOpportunity, AdNetworkOpportunityStatus } from '@/interfaces/ad_network/internal/opportunity';

import StatusIndicator from './StatusIndicator';

const OpportunityStatusIndicator = ({ opportunity }: { opportunity: AdNetworkOpportunity }) => {
  const color = {
    [AdNetworkOpportunityStatus.DRAFT]: 'gray',
    [AdNetworkOpportunityStatus.PENDING]: 'blue',
    [AdNetworkOpportunityStatus.ACCEPTED]: 'green',
    [AdNetworkOpportunityStatus.REJECTED]: 'red',
    [AdNetworkOpportunityStatus.CANCELED]: 'yellow',
    [AdNetworkOpportunityStatus.EXPIRED]: 'red',
  }[opportunity.status] as 'blue' | 'gray' | 'green' | 'red' | 'yellow';

  return <StatusIndicator color={color} label={opportunity.status} />;
};

export default OpportunityStatusIndicator;
