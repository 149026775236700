import { Editor } from '@tiptap/core';
import { Fragment, Node, Slice } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';

type PasteHandlerOptions = {
  editor: Editor;
  validate: (url: string) => boolean;
};

let removeLink: boolean = false;

const checkForLinks = (fragment: Fragment, validate: (url: string) => boolean) => {
  const newNodes: Node[] = [];

  fragment.forEach((child) => {
    if (child.isInline && child.marks.length) {
      const newMarks = child.marks.filter((mark) => {
        if (mark.type.name === 'link' && !validate(mark.attrs.href)) {
          removeLink = true;

          return false;
        }

        return true;
      });

      newNodes.push(child.mark(newMarks));
    } else if (child.isBlock) {
      newNodes.push(child.copy(checkForLinks(child.content, validate)));
    } else {
      newNodes.push(child);
    }
  });
  return Fragment.fromArray(newNodes);
};

export function validateOnPasteHandler(options: PasteHandlerOptions): Plugin {
  return new Plugin({
    key: new PluginKey('handleOnPasteLinkValidation'),
    props: {
      handlePaste(view, _event, slice) {
        removeLink = false;

        const newSlice = new Slice(checkForLinks(slice.content, options.validate), slice.openStart, slice.openEnd);

        if (removeLink) {
          view.dispatch(view.state.tr.replaceSelection(newSlice));

          return true;
        }

        return false;
      },
    },
  });
}
