import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { DatePicker, Label } from '@/components/Form';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import SearchInput from '@/ui/SearchInput';

import { DisbursementSearchProps, DisbursementStatusFilter, statusFilterOptions } from './types';

interface ExportResponse {
  csv: string;
}

const DisbursementSearch = ({ onChange }: DisbursementSearchProps) => {
  const [search, setSearch] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<DisbursementStatusFilter>('all');
  const [runDateFilter, setRunrunDateFilter] = useState<string>('');
  const [payoutDateFilter, setPayoutDateFilter] = useState<string>('');
  const [exportParams, setExportParams] = useState<any>({});

  const fetchCsv = async () => {
    const response = await api.get(`ad_network/internal/disbursements/export`, { params: exportParams });
    return response.data;
  };

  const downloadCsvMutation = useMutation<ExportResponse>(fetchCsv, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.csv]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onError: () => {
      console.log('error');
    },
  });

  useEffect(() => {
    const params = {
      query: search,
      status: statusFilter,
      date: runDateFilter,
      payoutDate: payoutDateFilter,
    };
    onChange(params);
    setExportParams(params);
  }, [runDateFilter, onChange, search, statusFilter, payoutDateFilter]);

  return (
    <div className="flex w-full justify-between items-end p-4">
      <div className="w-full sm:w-fit">
        <SearchInput
          defaultValue={search}
          shouldDebounce
          onClearSearch={() => setSearch('')}
          onSearch={(val) => setSearch(val)}
          placeholder="Search disbursements"
        />
      </div>
      <div className="flex flex-row space-x-2 items-end">
        {/* Payout Date */}
        <div className="shadow-sm w-full md:w-80">
          <div className="flex items-center justify-between">
            <span className="text-md font-regular font-semibold text-gray-800 text-sm">Est. Payout Date</span>
            {payoutDateFilter && (
              <button
                type="button"
                className="text-xs text-primary-500 hover:underline hover:text-primary-800 hover:cursor-pointer"
                onClick={() => setPayoutDateFilter('')}
              >
                Clear
              </button>
            )}
          </div>
          <DatePicker
            inline
            value={payoutDateFilter}
            onChange={(date) => date && setPayoutDateFilter(date.toISOString())}
            displayTimezone={false}
            pickerOptions={{
              enableTime: false,
              altFormat: 'F j, Y',
              disable: [
                (date: any) => {
                  return date.getDate() !== 20;
                },
              ],
            }}
          />
        </div>
        {/* Run Date */}
        <div className="shadow-sm w-full md:w-80">
          <div className="flex items-center justify-between">
            <span className="text-md font-regular font-semibold text-gray-800 text-sm">Run Date</span>
            {runDateFilter && (
              <button
                type="button"
                className="text-xs text-primary-500 hover:underline hover:text-primary-800 hover:cursor-pointer"
                onClick={() => setRunrunDateFilter('')}
              >
                Clear
              </button>
            )}
          </div>
          <DatePicker
            inline
            value={runDateFilter}
            onChange={(date) => date && setRunrunDateFilter(date.toISOString())}
            displayTimezone={false}
            pickerOptions={{
              enableTime: false,
              altFormat: 'F j, Y',
            }}
          />
        </div>
        {/* Status */}
        <div className="shadow-sm w-full md:w-80">
          <Label htmlFor="status_filter" value="Status" />
          <Dropdown
            name="status_filter"
            value={statusFilter}
            options={statusFilterOptions}
            onSelect={(_name, value) => setStatusFilter(value as DisbursementStatusFilter)}
          />
        </div>

        <div className="w-full md:w-80">
          <Button type="button" onClick={() => downloadCsvMutation.mutate()}>
            Download CSV
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DisbursementSearch;
