import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { AccordionCard } from '@/components/Accordion/variants/AccordionCard';
import { Divider } from '@/components/Divider';
import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import Tag from '@/components/Tag';
import { Typography } from '@/components/Typography';
import { useSplitTest } from '@/hooks';
import { Post, PostStatus } from '@/interfaces/post';
import { SplitTestStatus } from '@/interfaces/split_test';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';

interface Props {
  post: Post;
  setPostEditorStep: Dispatch<SetStateAction<PostEditorSteps>>;
}

const EmailSettings = ({ post, setPostEditorStep }: Props) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const splitTestObject = useSplitTest(post.id).data;
  const [splitTestDuration, setSplitTestDuration] = useState<number>(0);
  const [splitTestSamplePct, setSplitTestSamplePct] = useState<number>(0);
  const [splitTestPopulationSize, setSplitTestSize] = useState<number>(0);
  const [splitTestTestGroupSize, setSplitTestTestGroupSize] = useState<number>(
    Math.round((splitTestSamplePct / 100) * splitTestPopulationSize)
  );
  const [remainingAudienceSize, setRemainingAudienceSize] = useState<number>(
    splitTestPopulationSize - splitTestTestGroupSize
  );
  const [postSent, setPostSent] = useState(false);
  const [iconColor, setIconColor] = useState('text-action-secondary-600');
  const [title, setTitle] = useState<React.ReactNode>(null);
  const [body, setBody] = useState<React.ReactNode>(null);

  const letterMap = (index: number) => {
    switch (index) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
      case 4:
        return 'E';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (splitTestObject) {
      setSplitTestDuration((splitTestObject.duration || 0) / 60);
      setSplitTestSamplePct(splitTestObject.sample_pct || 0);
      setSplitTestSize(splitTestObject.population_size || 0);
      setSplitTestTestGroupSize(
        Math.round(((splitTestObject.sample_pct || 0) / 100) * (splitTestObject.population_size || 0))
      );
      setRemainingAudienceSize(
        (splitTestObject.population_size || 0) -
          Math.round(((splitTestObject.sample_pct || 0) / 100) * (splitTestObject.population_size || 0))
      );
    }
  }, [splitTestObject]);

  useEffect(() => {
    if (post.status === PostStatus.ARCHIVED || post.status === PostStatus.PUBLISHED) {
      setIconColor('text-feedback-success-500');
      setPostSent(true);
    } else if (post.status === PostStatus.DRAFT) {
      setIconColor('text-action-secondary-600');
    } else {
      setIconColor('text-feedback-info-500');
    }
  }, [post.status]);

  const nonABTestTitle = useCallback(() => {
    return (
      <span className="flex flex-row gap-3">
        <EnvelopeIcon className={cx('h-6 w-6 flex-grow', iconColor)} />
        <div className="flex flex-row gap-1">
          <Typography token="font-normal/text/base" colorWeight="500">
            Subject line:{' '}
            <Typography token="font-medium/text/base" colorWeight="900">
              {post.email_subject_line}
            </Typography>
          </Typography>
        </div>
      </span>
    );
  }, [iconColor, post.email_subject_line]);

  const nonABTestBody = useCallback(() => {
    return (
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Typography token="font-medium/text/sm">Subject line</Typography>
          <Typography token="font-normal/text/sm" colorWeight="700">
            {post.email_subject_line}
          </Typography>
        </div>
        <div className="flex flex-col gap-2">
          <Typography token="font-medium/text/sm">Preview text</Typography>
          <Typography token="font-normal/text/sm" colorWeight="700">
            {post.email_preview_text || '-'}
          </Typography>
        </div>
      </div>
    );
  }, [post.email_preview_text, post.email_subject_line]);

  const abTestTitle = useCallback(() => {
    return (
      <span className="flex flex-row gap-3">
        <EnvelopeIcon className={cx('h-6 w-6 flex-grow', iconColor)} />
        <div className="flex flex-row gap-1">
          <Typography token="font-normal/text/base" colorWeight="500">
            A/B test for{' '}
            <Typography token="font-medium/text/base" colorWeight="900">{`${splitTestDuration} minutes`}</Typography>
          </Typography>
          {!accordionOpen && (
            <Typography token="font-normal/text/base" colorWeight="500">
              with{' '}
              <Typography token="font-medium/text/base" colorWeight="900">
                {splitTestSamplePct}% ({splitTestTestGroupSize.toLocaleString()}) subscribers
              </Typography>
            </Typography>
          )}
        </div>
      </span>
    );
  }, [accordionOpen, iconColor, splitTestDuration, splitTestSamplePct, splitTestTestGroupSize]);

  const abTestBody = useCallback(() => {
    return (
      <div className="flex flex-col gap-6">
        <div className="flex flex-row gap-14">
          <div className="flex flex-col gap-2">
            <Typography token="font-medium/text/sm">Test Group</Typography>
            <Badge type="alert" size="md">
              {splitTestSamplePct}% ({splitTestTestGroupSize.toLocaleString()} subscribers)
            </Badge>
          </div>
          <div className="flex flex-col gap-2">
            <Typography token="font-medium/text/sm">Remaining Audience</Typography>
            <Tag label={`${100 - splitTestSamplePct}% (${remainingAudienceSize.toLocaleString()} subscribers)`} />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Typography token="font-medium/text/sm">Subject lines</Typography>
          {splitTestObject?.status === SplitTestStatus.PENDING && (
            <span className="flex flex-row gap-2 items-center">
              <div className="w-[18px] h-[18px] flex items-center justify-center border text-xs font-medium border-surface-200 bg-surface-100 rounded-[4px]">
                A
              </div>

              <Typography token="font-normal/text/sm" colorWeight="500">
                {post.email_subject_line}
              </Typography>
            </span>
          )}
          {splitTestObject?.split_test_options?.map((splitTestOption, index) => (
            <span className="flex flex-row gap-2 items-center">
              <div className="w-[18px] h-[18px] flex items-center justify-center border text-xs font-medium border-surface-200 bg-surface-100 rounded-[4px]">
                {letterMap(index + (splitTestObject?.status === SplitTestStatus.PENDING ? 1 : 0))}
              </div>

              <Typography key={splitTestOption.id} token="font-normal/text/sm" colorWeight="500">
                {splitTestOption.value}
              </Typography>

              {splitTestOption.id === splitTestObject?.winning_option_id && (
                <Badge type="success" size="sm">
                  Winner
                </Badge>
              )}
            </span>
          ))}
        </div>
        <Typography token="font-normal/text/sm" colorWeight="500">
          {splitTestTestGroupSize.toLocaleString()} subscribers will receive one subject line variation. After{' '}
          {splitTestDuration} minutes {remainingAudienceSize.toLocaleString()} subscribers will receive the winning
          variation.
        </Typography>
        <div className="-ml-6 w-[calc(100%+3rem)]">
          <Divider variant="light"> </Divider>
        </div>
        <div className="flex flex-col gap-2">
          <Typography token="font-medium/text/sm">Preview text</Typography>
          <Typography token="font-normal/text/sm" colorWeight="700">
            {post.email_preview_text}
          </Typography>
        </div>
      </div>
    );
  }, [
    post.email_subject_line,
    post.email_preview_text,
    remainingAudienceSize,
    splitTestDuration,
    splitTestObject?.split_test_options,
    splitTestObject?.status,
    splitTestObject?.winning_option_id,
    splitTestSamplePct,
    splitTestTestGroupSize,
  ]);

  useEffect(() => {
    if (splitTestDuration === 0) {
      setTitle(nonABTestTitle());
      setBody(nonABTestBody());
    } else {
      setTitle(abTestTitle());
      setBody(abTestBody());
    }
  }, [
    splitTestDuration,
    splitTestSamplePct,
    splitTestTestGroupSize,
    accordionOpen,
    iconColor,
    abTestBody,
    abTestTitle,
    nonABTestTitle,
    nonABTestBody,
  ]);

  return (
    <div className="w-2/3 mx-auto max-w-[40rem]">
      <AccordionCard
        cardPaddingClass="p-6"
        marginTop="mt-0"
        padding="px-6 pb-6"
        titleGap="0"
        title={title}
        subText=""
        topBorder={false}
        isControlledState
        isControlledStateOpen={accordionOpen}
        onClick={() => setAccordionOpen(!accordionOpen)}
        footer={
          <div
            className={cx(
              'flex px-6 py-3 justify-end items-center gap-3 self-stretch bg-surface-50',
              postSent ? 'hidden' : ''
            )}
          >
            <ButtonGroup>
              <Button
                onClick={() => setPostEditorStep(PostEditorSteps.EMAIL)}
                type="button"
                variant="primary-inverse"
                size="sm"
              >
                Edit
              </Button>
            </ButtonGroup>
          </div>
        }
      >
        {body}
      </AccordionCard>
    </div>
  );
};

export default EmailSettings;
