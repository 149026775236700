import moment from 'moment-mini';

import Banner from '@/components/Banner';
import { BoostInvite } from '@/interfaces/boosts/monetize/boost_invite';
import { PublicationSearchWithBoostInvite } from '@/interfaces/publication_search';
import pluralize from '@/utils/pluralize';

import BoostOfferInformationBlock from '../../../Shared/BoostOfferCard/BoostOfferInformationBlock';
import PublicationInformationBlock from '../../../Shared/BoostOfferCard/PublicationInformationBlock';
import RegionalRestrictionsBlock from '../../../Shared/BoostOfferCard/RegionalRestrictionsBlock';

interface Props {
  publication: PublicationSearchWithBoostInvite<BoostInvite>;
}

const ActiveBoostInviteItem = ({ publication }: Props) => {
  const daysBeforeExpiry = moment(publication.boost_invite.expires_at).diff(moment(), 'days');
  const hoursBeforeExpiry = moment(publication.boost_invite.expires_at).diff(moment(), 'hours');

  const daysOrHoursText =
    daysBeforeExpiry > 0 ? pluralize('day', daysBeforeExpiry) : pluralize('hour', hoursBeforeExpiry);

  return (
    <div className="flex flex-col gap-y-3 p-6">
      <Banner
        title={`${daysOrHoursText} left to accept this invite before it expires`}
        variant="danger"
        isScreenWide={false}
      />

      <PublicationInformationBlock boostOffer={publication.boost_invite.boost_offer} isPreview />
      <BoostOfferInformationBlock boostOffer={publication.boost_invite.boost_offer} />
      <RegionalRestrictionsBlock boostOffer={publication.boost_invite.boost_offer} />
    </div>
  );
};

export default ActiveBoostInviteItem;
