import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';
import { TimePeriod } from '@/utils';

import useCurrentTimeZone from '../useCurrentTimeZone';

interface ApiResponse {
  num_subscribers_with_referrals: number;
  num_subscribers_without_referrals: number;
}

interface Props {
  period: TimePeriod;
  additionalParams?: any;
  allResults?: boolean;
}

const useSubscriberBreakdown = ({ period }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const currentTimeZone = useCurrentTimeZone();

  const fetchBreakdown = () => {
    return api
      .get('/referral_program/metrics/subscriber_breakdown', {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
          period,
        },
      })
      .then((res) => res.data);
  };

  return useQuery<ApiResponse>(
    ['subscriber_breakdown', currentPublicationId, period, currentTimeZone],
    fetchBreakdown,
    {
      keepPreviousData: true,
    }
  );
};

export default useSubscriberBreakdown;
