import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import CustomFieldForm from '../_components/CustomFieldForm';

const NewCustomField = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();

  const createCustomFieldMutation = useMutation(
    ({ display, kind }: { display: string; kind: string }) =>
      api.post('/custom_fields', {
        publication_id: currentPublicationId,
        custom_field: {
          display,
          kind,
        },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        toast.success('Custom Field created successfully');
        navigate(`/subscribers/custom_fields`);
      },
    }
  );

  const onSubmit = async (display: string, kind: string) => {
    analytics.track('Created a custom field');
    await createCustomFieldMutation.mutateAsync({ display, kind });
  };

  return (
    <CustomFieldForm
      onSubmit={onSubmit}
      onCancel={() => navigate('/subscribers/custom_fields')}
      header="New Custom Field"
    />
  );
};

export default NewCustomField;
