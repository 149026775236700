import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { useUpdateEmailMessage, useWelcomeEmail } from '../../../../../hooks';
import { SwitchForm } from '../../../Components';

const WelcomeEmail = ({ publication, organization }: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const currentPublicationId = useCurrentPublicationId();
  const { data: welcomeEmail } = useWelcomeEmail(currentPublicationId);

  const [welcomeEmailVal, setWelcomeEmailVal] = useState<boolean>(publication?.send_welcome_email || false);
  const isContentSet = welcomeEmail?.preview_text && welcomeEmail?.subject_line;
  const isVerified = Boolean(organization?.verified_at);

  useEffect(() => {
    if (publication) {
      setWelcomeEmailVal(publication.send_welcome_email);
    }
  }, [publication]);

  const updateMutation = useUpdateEmailMessage(currentPublicationId, {
    emailMessageId: welcomeEmail?.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['welcome_email', currentPublicationId], { exact: true });
      queryClient.invalidateQueries(['email_messages', 'preview', welcomeEmail?.id], { exact: true });
      queryClient.invalidateQueries(['publications', currentPublicationId]);
      toast.success('Welcome Email saved!');
    },
    onError: (err: any) => toast.error(err?.response?.data?.error || 'Something went wrong, please try again'),
  });

  const handleChange = async (value: boolean) => {
    setWelcomeEmailVal(value);

    updateMutation.mutate({
      active: value,
    });
  };

  if (!isContentSet) {
    return (
      <div className="space-y-2">
        <Button
          variant="primary-inverse"
          type="button"
          onClick={() => {
            analytics.track('Welcome Email Toggled on');
            return navigate('/welcome_email/edit');
          }}
        >
          Set Up Welcome Email
        </Button>
        {organization && !isVerified && (
          <p className=" text-xs text-gray-500">
            * Welcome emails will only begin sending once your publication has been verified!
          </p>
        )}
      </div>
    );
  }

  return (
    <div>
      <SwitchForm
        name="welcome-email"
        label="Enable Welcome Email?"
        description={
          welcomeEmailVal
            ? "You can customize the welcome email by clicking the 'view' and selecting 'edit' on the welcome email page."
            : 'Selecting this option will enable a welcome email for new subscribers.'
        }
        value={Boolean(welcomeEmailVal)}
        onSave={(val: boolean) => handleChange(val)}
      />
      {welcomeEmailVal && (
        <Button variant="primary-inverse" type="button" onClick={() => navigate('/welcome_email/analytics')}>
          View and Update Welcome Email
        </Button>
      )}
    </div>
  );
};

export default WelcomeEmail;
