import React, { FC, useState } from 'react';

import { Input } from '@/components/Form';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { useTiers } from '@/hooks/useTiers';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  onSubmit: (time_unit: string, duration: string, tier_id: string) => Promise<void>;
  premium_gift?: {
    time_unit?: string;
    duration?: string;
    tier_id?: string;
  };
}

const PremiumGiftForm: FC<Props> = ({ onSubmit, premium_gift: premiumGift }) => {
  const [timeUnit, setTimeUnit] = useState(premiumGift?.time_unit || '');
  const [duration, setDuration] = useState(premiumGift?.duration || '');
  const [tierId, setTierId] = useState(premiumGift?.tier_id || '');
  const [saving, setSaving] = useState(false);
  const currentPublicationId = useCurrentPublicationId();
  const { data: tiers = [] } = useTiers(currentPublicationId, { requireRecurringPrice: true, excludeDonation: true });
  const tierOptions = tiers.map((t) => ({
    label: t.name,
    value: t.id,
  }));

  const TIME_UNIT_OPTIONS: Option[] = [
    {
      label: 'Days',
      value: 'days',
    },
    {
      label: 'Months',
      value: 'months',
    },
    {
      label: 'Years',
      value: 'years',
    },
    {
      label: 'Lifetime',
      value: 'forever',
    },
  ];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSaving(true);

    onSubmit(timeUnit, duration, tierId).finally(() => {
      setSaving(false);
    });
  };

  const handleTimeUnitSelect = (name: string, value: string) => {
    setTimeUnit(value);

    if (value === 'forever') {
      setDuration('');
    }
  };

  const handleTierSelect = (name: string, value: string) => {
    setTierId(value);
  };

  const isDisabled = () => !timeUnit && !duration;

  return (
    <div>
      <div>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <div className="space-y-6">
              <Dropdown
                name="time_unit"
                labelText="Time Unit"
                value={timeUnit}
                options={TIME_UNIT_OPTIONS}
                onSelect={handleTimeUnitSelect}
                required
              />
            </div>
          </div>
          {timeUnit !== 'forever' && (
            <div>
              <div className="space-y-6">
                <Input
                  name="duration"
                  labelText="Duration"
                  type="text"
                  value={duration}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDuration(e.target.value)}
                  required
                />
              </div>
            </div>
          )}
          <div>
            <Dropdown
              name="tier_id"
              labelText="Tier"
              helperText="You can only gift a paid subscription for a tier that has a recurring price."
              options={tierOptions}
              value={tierId}
              onSelect={handleTierSelect}
              required
            />
          </div>

          <div>
            <Button type="submit" disabled={isDisabled()} loading={saving}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PremiumGiftForm;
