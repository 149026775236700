import { Link } from 'react-router-dom';

import { PostTarget, PostTargetAction } from '@/interfaces/post_target';

import SegmentList from './SegmentList';

interface CustomSegmentsProps {
  postSendTargets: PostTarget[];
  readOnly: boolean;
}

const CustomSegments: React.FC<CustomSegmentsProps> = ({ postSendTargets, readOnly }) => {
  return (
    <div className="space-y-6 mt-6">
      <hr />
      <SegmentList
        sectionTitle="Included Segments"
        postSendTargets={postSendTargets}
        readOnly={readOnly}
        action={PostTargetAction.INCLUDE}
      />
      <SegmentList
        sectionTitle="Excluded Segments"
        postSendTargets={postSendTargets}
        readOnly={readOnly}
        action={PostTargetAction.EXCLUDE}
      />
      {!readOnly && (
        <div className="px-4">
          <Link to="/segments/new" target="_blank" className="text-xs text-gray-500 hover:text-gray-700 underline">
            Create and manage segments
          </Link>
        </div>
      )}
    </div>
  );
};

export default CustomSegments;
