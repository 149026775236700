import { PropsWithChildren } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ExclamationTriangleIcon, EyeIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import Tooltip from '@/components/Tooltip';
import { Typography, TypographyStack } from '@/components/Typography';
import MarkDownHelperText from '@/pages/DesignLab/components/MardownHelperText';

import { TooltipProps } from '../../_types/misc';

type Props = PropsWithChildren<{
  label?: string;
  faintHeading?: boolean;
  description?: string | React.ReactNode;
  tooltip?: TooltipProps;
  actionChild?: React.ReactNode;
  className?: string;
  indent?: boolean;
  nodeId?: string;
  supportsMarkdown?: boolean;
  exclamationNote?: string;
}>;

const PropertiesGrouping = ({
  children,
  label,
  description,
  tooltip,
  actionChild,
  className,
  indent,
  nodeId,
  faintHeading = false,
  supportsMarkdown = false,
  exclamationNote,
}: Props) => {
  const [searchParams] = useSearchParams();
  const highlightedNodeId = searchParams.get('nodeId');
  const isHighlighted = nodeId === highlightedNodeId;

  return (
    <div className={cx('flex flex-col space-y-2', className)}>
      <div className="flex flex-col space-y-2">
        <div className="flex space-x-1 items-center justify-between">
          <TypographyStack gap="1">
            <div className="flex space-x-1 items-center">
              <Typography
                token={faintHeading ? 'font-normal/text/xs' : 'font-semibold/text/xs'}
                colorWeight={faintHeading ? '400' : '900'}
              >
                {label}
              </Typography>
              {exclamationNote && (
                <Tooltip
                  id={`${label}-warning-note`}
                  text={exclamationNote}
                  iconClass="!text-surface-300"
                  showIcon={false}
                >
                  <ExclamationTriangleIcon className="w-4 h-4 text-feedback-warning-300 mt-0.5" />
                </Tooltip>
              )}
              {supportsMarkdown && (
                <Typography token="font-light/text/xs" colorWeight="400" as="span">
                  <MarkDownHelperText />
                </Typography>
              )}
              {tooltip && <Tooltip id={tooltip.id} text={tooltip.text} iconClass="!text-surface-300" />}
              {isHighlighted && <EyeIcon className="w-4 h-4 text-action-secondary-600 animate-pulse" />}
            </div>
          </TypographyStack>
          {actionChild || null}
        </div>
        {description && (
          <Typography token="font-normal/text/xs" colorWeight="400">
            {description}
          </Typography>
        )}
      </div>

      <div className={cx('flex flex-col space-y-3', indent && 'px-1')}>{children}</div>
    </div>
  );
};

export default PropertiesGrouping;
