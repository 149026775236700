import { elementFromString, Extension } from '@tiptap/core';
import { DOMParser, Slice } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import MarkdownIt from 'markdown-it';

export const MarkdownPluginKey = new PluginKey('markdown-plugin-key');

export const Markdown = Extension.create({
  name: 'markdown',

  addProseMirrorPlugins() {
    const md = new MarkdownIt();
    const { schema } = this.editor;

    return [
      new Plugin({
        key: MarkdownPluginKey,

        props: {
          clipboardTextParser: (text, context, plainText) => {
            if (plainText) {
              return null as unknown as Slice;
            }

            const parsed = md.render(text);

            return DOMParser.fromSchema(schema).parseSlice(elementFromString(parsed), {
              preserveWhitespace: true,
              context,
            });
          },
        },
      }),
    ];
  },
});
