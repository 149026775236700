import { XMarkIcon } from '@heroicons/react/20/solid';

import { TabPills } from '@/components/TabPills';
import SubscriberAndTierSelect from '@/ui/SubscriberAndTierSelect';
import { capitalize } from '@/utils';

import { Platform, ScreenSize } from './types';

interface Props {
  tabs: Platform[] | undefined;
  activeTab: Platform;
  setActiveTab: (tab: Platform) => void;
  screenSize: ScreenSize;
  handleScreenSizeChange: (size: ScreenSize) => void;
  showSubscriberSelect: boolean;
  handleCurrentSubscriberSelect: (subscriberId: string, subscriberEmail: string) => void;
  handleCurrentSubscriberClear: () => void;
  handleCurrentTierSelect: (subscriberId: string, subscriberEmail: string) => void;
  handleCurrentTierClear: () => void;
  handleClose: () => void;
}

const screenSizes: ScreenSize[] = ['desktop', 'mobile'];

const Navbar = ({
  tabs,
  activeTab,
  setActiveTab,
  screenSize,
  handleScreenSizeChange,
  showSubscriberSelect,
  handleCurrentSubscriberSelect,
  handleCurrentSubscriberClear,
  handleCurrentTierSelect,
  handleCurrentTierClear,
  handleClose,
}: Props) => {
  return (
    <div className="w-full bg-white justify-center rounded-l-lg">
      <div className="h-full p-2 xl:p-4 border-r border-gray flex flex-row justify-between justify-items-center">
        <div className="flex flex-row gap-6 flex-1">
          <TabPills.Wrapper>
            {tabs?.map((tab) => (
              <TabPills.Item key={tab} active={activeTab === tab} onClick={() => setActiveTab(tab)}>
                {tab}
              </TabPills.Item>
            ))}
          </TabPills.Wrapper>

          <TabPills.Wrapper>
            {screenSizes.map((tab) => (
              <TabPills.Item key={tab} active={screenSize === tab} onClick={() => handleScreenSizeChange(tab)}>
                {capitalize(tab)}
              </TabPills.Item>
            ))}
          </TabPills.Wrapper>
        </div>
        {showSubscriberSelect && (
          <div className="flex-1 flex flex-row">
            <SubscriberAndTierSelect
              placeholderText="Select a subscriber or tier to see their view"
              className="w-2/3 mx-auto"
              onSelectSubscriber={handleCurrentSubscriberSelect}
              onClearSubscriber={handleCurrentSubscriberClear}
              onSelectTier={handleCurrentTierSelect}
              onClearTier={handleCurrentTierClear}
            />
          </div>
        )}
        <div
          role="button"
          tabIndex={0}
          onKeyDown={handleClose}
          onClick={handleClose}
          className="cursor-pointer flex-1 flex justify-end"
        >
          <XMarkIcon className="h-6 w-6" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
