import { Button } from '@/ui/Button';

type LoadPostsButtonProps = {
  hasNextPage: boolean;
  fetchNextPage: () => any;
  isFetchingNextPage: boolean;
};

const LoadPostsButton = ({ hasNextPage, fetchNextPage, isFetchingNextPage }: LoadPostsButtonProps) => {
  return (
    <div className="text-center py-6 border-t">
      {hasNextPage && (
        <div>
          <Button variant="primary-inverse" onClick={fetchNextPage} disabled={!hasNextPage || isFetchingNextPage}>
            {isFetchingNextPage ? 'Loading more...' : 'Load more'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LoadPostsButton;
