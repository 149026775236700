import React, { useRef, useState } from 'react';
import cx from 'classnames';

import Stack from '@/components/Stack';

interface OneLineFormProps {
  onSubmit: (value: any) => void;
  value: any;
  className?: string;
}

const OneLineForm: React.FC<OneLineFormProps> = ({ onSubmit, value, className }: OneLineFormProps) => {
  const inputRef = useRef<any | null>(null);
  const [newValue, setNewValue] = useState<any>(value);

  return (
    <Stack direction="col" gap={1}>
      <div className="flex group shadow-sm rounded-lg">
        <input
          ref={inputRef}
          type="text"
          className={cx(
            'appearance-none text-gray-600 block w-full px-3 py-2 border border-r-0 focus:ring-inset border-gray-200 placeholder-gray-400 focus:outline-none focus:ring-transparent focus:border-gray-200 sm:text-sm cursor-pointer disabled:cursor-not-allowed rounded-l-lg group-hover:bg-gray-50 overflow-hidden text-ellipsis',
            className
          )}
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
        <button
          type="button"
          onClick={() => onSubmit(newValue)}
          className="flex items-center space-x-1 rounded-r-lg bg-indigo-50 px-4 border border-indigo-200 focus:outline-none group-hover:bg-indigo-100"
        >
          <span className="text-indigo-700">Submit</span>
        </button>
      </div>
    </Stack>
  );
};

export default OneLineForm;
