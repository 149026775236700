import { createGlobalStyle } from 'styled-components';

import { Font } from '../../../interfaces/font';

const FontStyles = createGlobalStyle<{ fonts: Font[] }>`
${({ fonts }) =>
  fonts
    .map(
      (font) => `
        @font-face {
          font-family: '${font.name}';
          src: url('${font.normal_url}') format('${font.format}');
        }
        `
    )
    .join('')}
`;

export default FontStyles;
