import { ElementType, forwardRef, HTMLProps } from 'react';
import styled from 'styled-components';

import { colors } from '../../../lib/colors';

const Styled = {
  Input: styled.input<{
    hasIcon?: boolean;
    hasSuffix?: boolean;
    suffixWidth?: Number;
  }>`
    background-color: ${colors.white[8]};
    border-radius: 0.5rem;
    border: 0;
    caret-color: #ff67b7;
    color: ${colors.white[1]};
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    height: 1.75rem;
    padding: 0.375rem 0.5rem;
    width: 100%;

    ${({ hasIcon }) =>
      hasIcon &&
      `
      padding-left: 1.75rem;
    `}

    ${({ hasSuffix, suffixWidth }) =>
      hasSuffix &&
      `
      padding-right: ${suffixWidth ? `${suffixWidth}px` : '1.75rem'};
    `}

    &:hover {
      background-color: ${colors.white[7]};
    }

    &:focus,
    &:active {
      background-color: transparent;
      outline: 1px solid #ff67b7;
      outline-offset: 0;
    }
  `,
};

export type InputProps = HTMLProps<HTMLInputElement> & {
  as?: ElementType;
  hasIcon?: boolean;
  hasSuffix?: boolean;
  suffixWidth?: Number;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ as, hasIcon, hasSuffix, suffixWidth, ...rest }, ref) => (
    <Styled.Input
      as={as}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      hasIcon={!!hasIcon}
      hasSuffix={!!hasSuffix}
      ref={ref}
      suffixWidth={suffixWidth}
    />
  )
);
