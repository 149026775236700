import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { CustomDomainTypes } from '@/interfaces/custom_domain';
import api from '@/services/swarm';

interface Props {
  publicationId: string;
  onSuccess?: () => void;
}

const useCustomDomainConfigurationVerification = ({ publicationId, onSuccess }: Props) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ customDomainId, type }: { customDomainId: string; type: CustomDomainTypes }) =>
      api.post(`/custom_domains/${customDomainId}/configuration_verifications`, {
        publication_id: publicationId,
        type,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['custom_domains', publicationId]);
        toast.success('DNS Configuration Verified');

        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export default useCustomDomainConfigurationVerification;
