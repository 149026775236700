import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  onSuccess?: () => void;
}

const useRestoreSiteVersion = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const { pageId } = useParams();

  return useMutation(({ siteVersionId }: { siteVersionId: string }) =>
    api.post(`/site_versions/${siteVersionId}/restore`, {
      publication_id: currentPublicationId,
    }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['publications', currentPublicationId, 'sites', 'null']);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'pages']);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'site_versions']);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'pages', pageId]);
      },
    }
  );
};

export default useRestoreSiteVersion;
