import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PublicationSubscriberPreference } from '@/interfaces/subscribers_preferences';
import api from '@/services/swarm';

export default function useSubscriberPreference(id: string) {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchSubscriberPreference = () =>
    api
      .get(`/publication_subscriber_preferences/${id}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<PublicationSubscriberPreference>([currentPublicationId, 'subscriber_preference', id], fetchSubscriberPreference, {
    keepPreviousData: true,
    enabled: !!id,
  });
}
