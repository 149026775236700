import { FC } from 'react';

import ColorField from '../../components/Form/ColorField';

import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { ThemeFormProps } from './types';

const ButtonForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette, fonts }) => {
  return (
    <FormContainer>
      <FormSection title="Styles">
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.button_font_family}
              onUpdate={(val: string) => onUpdate({ ...theme, button_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.button_text_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, button_text_color: color?.hexString })}
          />
        </FormInput>
        <FormInput title="Background color">
          <ColorField
            placement="bottom"
            color={theme.button_background_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, button_background_color: color?.hexString })}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="button_padding_top"
            label="Spacing between elements"
            onChange={(val: number) =>
              onUpdate({ ...theme, button_padding_top: val.toString(), button_padding_bottom: val.toString() })
            }
            initialValue={theme.button_padding_top}
            min={0}
            max={80}
            valueSuffix="px"
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Border">
        <FormInput>
          <Slider
            name="button_border_radius"
            label="Curve radius"
            onChange={(val: number) => onUpdate({ ...theme, button_border_radius: val.toString() })}
            initialValue={theme.button_border_radius}
            min={0}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="button_border_width"
            label="Border thickness"
            onChange={(val: number) => onUpdate({ ...theme, button_border_width: val.toString() })}
            initialValue={theme.button_border_width}
            min={0}
            max={5}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Border color">
          <ColorField
            placement="bottom"
            color={theme.button_border_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, button_border_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default ButtonForm;
