import { memo } from 'react';
import Tippy from '@tippyjs/react/headless';
import { Editor as CoreEditor, NodeViewProps } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/react';

import { AnchorSettingsPanel } from '@/components/TiptapEditor/components/DragHandleButton/panels';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { InputField } from '@/components/TiptapEditor/components/ui/Input';
import { Panel, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip';

import { DEFAULT_TOC_TITLE } from './constants';
import useToC from './hooks';
import Styled from './styled';

type TableOfContentsPanelProps = {
  editor: CoreEditor;
  attrs: any;
  updateAttributes: (attrs: Record<string, string>) => void;
  onItemClick?: () => void;
};

const TableOfContentsPanel = memo(({ editor, attrs, updateAttributes, onItemClick }: TableOfContentsPanelProps) => {
  const {
    items,
    itemsRefs,
    selectedItem,
    setSelectedItem,
    onToggleItemVisibility,
    headingTitle,
    onChangeHeadingTitle,
    isEditHeadingPanelOpen,
    setIsEditHeadingPanelOpen,
    onToggleHeadingVisibility,
  } = useToC(editor, attrs, updateAttributes);

  const isOrderedList = attrs.listType === 'ordered';

  return (
    <>
      <Styled.ActionLine style={{ marginBottom: '1rem' }} $locked={isEditHeadingPanelOpen}>
        <Styled.Title
          style={{
            opacity: attrs.showTitle === false ? '0.2' : undefined,
            textDecoration: attrs.showTitle === false ? 'line-through' : undefined,
          }}
        >
          {headingTitle || DEFAULT_TOC_TITLE}
        </Styled.Title>
        <Styled.ButtonGroup>
          <Tippy
            render={(tippyAttrs) => (
              <Panel {...tippyAttrs}>
                <PanelSection>
                  <Styled.InputWrapper>
                    <InputField
                      label="Table of contents title"
                      placeholder={DEFAULT_TOC_TITLE}
                      onChange={onChangeHeadingTitle}
                      value={headingTitle?.toString() || ''}
                    />
                  </Styled.InputWrapper>
                </PanelSection>
              </Panel>
            )}
            visible={isEditHeadingPanelOpen}
            onClickOutside={() => {
              setIsEditHeadingPanelOpen(false);
            }}
            offset={[0, 4]}
            placement="bottom"
            interactive
          >
            <div>
              <Tooltip enabled={!isEditHeadingPanelOpen} title="Edit headline">
                <Button
                  $variant="tertiary"
                  $size="small"
                  $isIconButton
                  onClick={() => {
                    setIsEditHeadingPanelOpen(!isEditHeadingPanelOpen);
                  }}
                  $leftSlot={<Icon $size="0.75rem" name="Edit" />}
                  $active={isEditHeadingPanelOpen}
                  $muted={isEditHeadingPanelOpen}
                />
              </Tooltip>
            </div>
          </Tippy>
          <Tooltip title={`${attrs.showTitle === false ? 'Show' : 'Hide'} headline`}>
            <Button
              $variant="tertiary"
              $size="small"
              $isIconButton
              onClick={onToggleHeadingVisibility}
              $leftSlot={<Icon $size="0.75rem" name={attrs.showTitle === false ? 'Show' : 'Hide'} />}
            />
          </Tooltip>
        </Styled.ButtonGroup>
      </Styled.ActionLine>

      <Tippy
        render={(tippyAttrs) => {
          if (selectedItem) {
            const uid = selectedItem;
            const { node } = items.find((item: any) => item.node.attrs.id === uid);
            const nodePos = items.find((item: any) => item.node.attrs.id === uid).pos;

            return (
              <AnchorSettingsPanel
                editor={editor}
                currentNode={node}
                currentNodePos={nodePos}
                onBack={() => null}
                compact
                key={nodePos}
                {...tippyAttrs}
              />
            );
          }

          return null;
        }}
        reference={itemsRefs?.current[selectedItem] || null}
        visible={selectedItem}
        offset={[0, 4]}
        placement="bottom"
        interactive
        onClickOutside={() => {
          setSelectedItem(null);
        }}
      />
      {items.length > 0 ? (
        <div className="flex flex-col">
          {items.map((item: any) => {
            const { level, node } = item;
            const nodePos = item.pos;
            const includeInToc = node.attrs.anchorIncludeInToc;
            const isItemLocked = selectedItem === node.attrs.id;

            return (
              <Styled.ActionLine
                key={node.attrs.id}
                style={{
                  paddingLeft: `${1.2 * level - 1.2}rem`,
                }}
                $locked={isItemLocked}
              >
                {isOrderedList ? (
                  <Styled.ItemIndex>{item.index}.</Styled.ItemIndex>
                ) : (
                  <Styled.UnorderedIndicator name="UnorderedDot" />
                )}
                <a
                  href={`#${item.id}`}
                  onClick={onItemClick}
                  style={{
                    opacity: item.node.attrs.anchorIncludeInToc === false ? '0.4' : undefined,
                    textDecoration: item.node.attrs.anchorIncludeInToc === false ? 'line-through' : undefined,
                  }}
                >
                  {item.title}
                </a>

                <Styled.ButtonGroup>
                  <Tooltip
                    // enabled={!isItemLocked}
                    title="Edit anchor"
                  >
                    <Button
                      $variant="tertiary"
                      $size="small"
                      $isIconButton
                      onClick={() => {
                        setSelectedItem(selectedItem ? null : node.attrs.id);
                      }}
                      ref={(element) => {
                        itemsRefs.current[node.attrs.id] = element;
                      }}
                      $leftSlot={<Icon $size="0.75rem" name="Edit" />}
                      $active={isItemLocked}
                      $muted={isItemLocked}
                    />
                  </Tooltip>
                  <Tooltip title={`${includeInToc === false ? 'Show' : 'Hide'} anchor`}>
                    <Button
                      $variant="tertiary"
                      $size="small"
                      $isIconButton
                      onClick={() => {
                        onToggleItemVisibility(node, nodePos);
                      }}
                      $leftSlot={<Icon $size="0.75rem" name={includeInToc === false ? 'Show' : 'Hide'} />}
                    />
                  </Tooltip>
                </Styled.ButtonGroup>
              </Styled.ActionLine>
            );
          })}
        </div>
      ) : (
        <div className="text-sm text-gray-500">Start adding anchors to your document …</div>
      )}
    </>
  );
});

export const TableOfContentsView = (props: NodeViewProps) => {
  const {
    editor,
    node: { attrs },
    updateAttributes,
  } = props;

  return (
    <NodeViewWrapper data-drag-handle>
      <Styled.Container contentEditable={false}>
        <TableOfContentsPanel editor={editor} attrs={attrs} updateAttributes={updateAttributes} />
      </Styled.Container>
    </NodeViewWrapper>
  );
};
