import { useCallback, useRef, useState } from 'react';

import Text from '@/components/Text';
import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelDivider, PanelFooter } from '@/components/TiptapEditor/components/ui/Panel';

import { usePostContext } from '../../PostContext';

import VisibilitySettings from './VisiblitySettings';

interface Props {
  fileRef: React.RefObject<HTMLInputElement>;
  setShowImageLibrary: (value: boolean) => void;
  showFullMenu?: boolean;
}

const ThumbnailActionsMenu = ({ fileRef, setShowImageLibrary, showFullMenu }: Props) => {
  const { formData, onChange } = usePostContext();
  const [showVisibilitySettings, setShowVisibilitySettings] = useState(false);
  const panelRef = useRef<HTMLInputElement>(null);

  const handleFileClick = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showVisibilitySettings) {
    return <VisibilitySettings />;
  }

  const existingThumbnail = (formData?.thumbnail_id && formData.thumbnail_id !== 'delete') || formData?.thumbnail?.id;

  return (
    <Styled.ContentWrapper>
      <Panel ref={panelRef}>
        {showFullMenu && (
          <>
            <Button
              $active={!formData?.display_thumbnail_on_web}
              $variant="quaternary"
              $size="small"
              onClick={() => {
                setShowVisibilitySettings(true);
              }}
              $fullWidth
              $leftSlot={<Icon name="Show" />}
              $rightSlot={<Icon name="ChevronRight" />}
            >
              Visibility
            </Button>
            <PanelDivider />
          </>
        )}
        <Text className="mb-1" size="xs">
          Recommended: 1200x630 px
        </Text>
        <Button
          $size="small"
          $variant="quaternary"
          $fullWidth
          $leftSlot={<Icon name="Upload" />}
          onClick={handleFileClick}
        >
          Upload a new image
        </Button>
        <Button
          onClick={() => {
            setShowImageLibrary(true);
          }}
          $size="small"
          $variant="quaternary"
          $fullWidth
          $leftSlot={<Icon name="Library" />}
        >
          {existingThumbnail ? 'Replace from image library' : 'Use from library'}
        </Button>
        {existingThumbnail && (
          <PanelFooter>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => {
                onChange({ thumbnail_id: 'delete', thumbnail: null });
              }}
              $fullWidth
              $leftSlot={<Icon name="Trash" />}
              $muted
            >
              Delete
            </Button>
          </PanelFooter>
        )}
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default ThumbnailActionsMenu;
