import { PropsWithChildren } from 'react';
import classNames from 'classnames';

type Props = PropsWithChildren<{
  className?: string;
}>;

const FormContentPreviewContainer = ({ children, className }: Props) => (
  <div className={classNames('bg-surface-50 border border-surface-100 rounded-md p-6', className)}>{children}</div>
);

export default FormContentPreviewContainer;
