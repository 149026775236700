import PostPoll from '@/components/_domain/PostPoll';
import { EmptyBlock } from '@/components/EmptyBlock';
import { Typography } from '@/components/Typography';
import { useWelcomeEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';

import hasPollInTipTapState from '../hasPollInTipTapState';

interface Props {
  publication: Publication;
}

const Polls = ({ publication }: Props) => {
  const { data: welcomeEmail, isSuccess } = useWelcomeEmail(publication.id);
  const hasPollInContent = hasPollInTipTapState(welcomeEmail?.tiptap_state);

  if (isSuccess && welcomeEmail?.post_poll_ids.length === 0) {
    return (
      <EmptyBlock>
        <Typography token="font-normal/text/sm" colorWeight="600">
          {hasPollInContent ? 'No poll data' : 'Your welcome email does not currently include any polls.'}
        </Typography>
      </EmptyBlock>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {welcomeEmail?.post_poll_ids.map((postPollId) => (
        <PostPoll publicationId={publication.id} postPollId={postPollId} />
      ))}
    </>
  );
};

export default Polls;
