import React, { useCallback, useEffect, useRef, useState } from 'react';
import iro from '@jaames/iro';
import styled from 'styled-components';

const TextField = styled.input.attrs({
  type: 'text',
  className:
    'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-transparent sm:text-sm',
})``;

const ColorPicker = ({ width, onColorChange, themeColors, initialColor, preventDefault }) => {
  const pickerObj = useRef(null);
  const picker = useRef(null);

  // If string is rgb(...) format, convert to hex
  const convertRGB = (rgb) => {
    if (rgb && rgb.toLowerCase().includes('rgb')) {
      return rgb.replace(/rgb\((.+?)\)/gi, (_, str) => {
        return `#${str
          .split(',')
          .map((component) => parseInt(component, 10).toString(16).padStart(2, '0'))
          .join('')}`;
      });
    }
    return rgb;
  };

  const normalizeColor = useCallback((c) => {
    return convertRGB(c)
      .replaceAll(/[^a-f0-9]/gi, '')
      .substring(0, 6)
      .toUpperCase();
  }, []);

  const [text, setText] = useState(initialColor ? normalizeColor(initialColor.toUpperCase()) : '000000');

  useEffect(() => {
    if (picker.current && !pickerObj.current) {
      pickerObj.current = new iro.ColorPicker(picker.current, {
        color: normalizeColor(initialColor) ?? '#000000',
        width,
        layout: [
          {
            component: iro.ui.Box,
            options: {},
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: 'hue',
            },
          },
        ],
      });

      pickerObj.current.on('color:change', (newColor) => {
        setText(normalizeColor(newColor.hexString));
        if (onColorChange) onColorChange(newColor);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newColor = normalizeColor(text);
    const currColor = normalizeColor(pickerObj.current.color.hexString);

    setText(newColor);

    if (newColor.length === 6 && currColor !== newColor) {
      pickerObj.current.color.set(`#${newColor}`);
    }
  }, [text, normalizeColor]);

  return (
    <div>
      <div className="flex">
        <div className="p-4">
          <div ref={picker} />
          <div className="mt-4 w-full flex">
            <span className="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md inline-flex items-center text-gray-500 px-3">
              #
            </span>
            <TextField
              onKeyDown={(e) => {
                if (e.key === 'Enter' && preventDefault) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                setText(e.target.value);
              }}
              value={text}
              className={`rounded-l-none rounded-r-md ${
                text.length === 6 ? 'focus:border-primary-500' : 'focus:border-red-500'
              }`}
            />
          </div>
        </div>
        {themeColors?.length > 0 && (
          <div className="border-l border-gray-200 my-4 w-20 text-center text-gray-500">
            <div className="text-sm font-medium">Theme</div>
            {themeColors.map((themeColor, i) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="h-8 mx-2 mt-4 border border-gray-300 rounded-md cursor-pointer hover:shadow-md"
                style={{ backgroundColor: themeColor }}
                onClick={() => pickerObj.current.color.set(themeColor)}
                onKeyPress={() => pickerObj.current.color.set(themeColor)}
                role="button"
                tabIndex={0}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
