import FormIdSelect from '../FormIdSelect';

interface FormSelectProps {
  resourceId?: string;
  onChange: (name: string, value: string) => void;
  className?: string;
}

const FormSelect = ({ resourceId, onChange, className }: FormSelectProps) => {
  return <FormIdSelect formId={resourceId || ''} onChange={onChange} className={className} />;
};

export default FormSelect;
