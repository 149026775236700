import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { Milestone } from '@/interfaces/milestone';
import api from '@/services/swarm';

interface ApiResponse {
  milestones: Milestone[];
  pagination: Pagination;
}

interface Props {
  enabled?: boolean;
  additionalParams?: any;
  allResults?: boolean;
}

const useMilestones = ({ enabled = true, additionalParams = {}, allResults = false }: Props = {}) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    [currentPublicationId, 'milestones'],
    ({ pageParam = 1 }) =>
      api
        .get(`/referral_program/milestones`, {
          params: {
            publication_id: currentPublicationId,
            all_results: allResults,
            page: pageParam,
            ...additionalParams,
          },
        })
        .then((res) => res.data),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
    }
  );
};

export default useMilestones;
