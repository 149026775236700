import { useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

const WarningBanner = () => {
  const [isHidden, setIsHidden] = useState(false);
  const warning = window.env.REACT_APP_POST_WARNING;

  if (isHidden || !warning || warning === 'false' || warning === 'disabled') return null;

  return (
    <div className="sticky top-0 z-10 pb-4">
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 flex items-center justify-between">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <span className="text-sm text-yellow-700">
              <p>{warning}</p>
              <p>We apologize for the inconvenience and look to have this resolved shortly.</p>
            </span>
          </div>
        </div>
        <div>
          <Button variant="primary-inverse" className="mr-2" onClick={() => setIsHidden(true)}>
            Hide
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WarningBanner;
