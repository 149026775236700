enum NotificationType {
  AD_NETWORK_NEW_OPPORTUNITIES = 'AdNetwork::NewOpportunitiesNotification',
  AD_NETWORK_OPPORTUNITY_PENDING = 'AdNetwork::OpportunityPendingNotification',
  AD_NETWORK_OPPORTUNITY_ACCEPTED = 'AdNetwork::OpportunityAcceptedNotification',
  AD_NETWORK_OPPORTUNITY_REJECTED = 'AdNetwork::OpportunityRejectedNotification',
  AD_NETWORK_OPPORTUNITY_EXPIRED = 'AdNetwork::OpportunityExpiredNotification',
  AD_NETWORK_OPPORTUNITY_CANCELED = 'AdNetwork::OpportunityCanceledNotification',
  AD_NETWORK_REPORT_CREATED = 'AdNetwork::ReportCreatedNotification',
  PAYMENT_REQUIRES_ACTION = 'Stripe::PaymentRequiresActionNotification',
}

export interface Notification {
  id: string;
  type: NotificationType;
  read_at: string | null;
  message: string;
  url: string;
  created_at: string;
  updated_at: string;
}

export type Notifications = ReadonlyArray<Notification>;

export interface AdNetworkNewOpportunitiesNotification extends Notification {
  type: NotificationType.AD_NETWORK_NEW_OPPORTUNITIES;
}

export const isAdNetworkNewOpportunitiesNotification = (
  notification: Notification
): notification is AdNetworkNewOpportunitiesNotification =>
  notification.type === NotificationType.AD_NETWORK_NEW_OPPORTUNITIES;

export interface AdNetworkOpportunityPendingNotification extends Notification {
  type: NotificationType.AD_NETWORK_OPPORTUNITY_PENDING;
}

export const isAdNetworkOpportunityPendingNotification = (
  notification: Notification
): notification is AdNetworkOpportunityPendingNotification =>
  notification.type === NotificationType.AD_NETWORK_OPPORTUNITY_PENDING;

export interface AdNetworkOpportunityAcceptedNotification extends Notification {
  type: NotificationType.AD_NETWORK_OPPORTUNITY_ACCEPTED;
}

export const isAdNetworkOpportunityAcceptedNotification = (
  notification: Notification
): notification is AdNetworkOpportunityAcceptedNotification =>
  notification.type === NotificationType.AD_NETWORK_OPPORTUNITY_ACCEPTED;

export interface AdNetworkOpportunityRejectedNotification extends Notification {
  type: NotificationType.AD_NETWORK_OPPORTUNITY_REJECTED;
}

export const isAdNetworkOpportunityRejectedNotification = (
  notification: Notification
): notification is AdNetworkOpportunityRejectedNotification =>
  notification.type === NotificationType.AD_NETWORK_OPPORTUNITY_REJECTED;

export interface AdNetworkOpportunityExpiredNotification extends Notification {
  type: NotificationType.AD_NETWORK_OPPORTUNITY_EXPIRED;
}

export const isAdNetworkOpportunityExpiredNotification = (
  notification: Notification
): notification is AdNetworkOpportunityExpiredNotification =>
  notification.type === NotificationType.AD_NETWORK_OPPORTUNITY_EXPIRED;

export interface AdNetworkOpportunityCanceledNotification extends Notification {
  type: NotificationType.AD_NETWORK_OPPORTUNITY_CANCELED;
}

export const isAdNetworkOpportunityCanceledNotification = (
  notification: Notification
): notification is AdNetworkOpportunityCanceledNotification =>
  notification.type === NotificationType.AD_NETWORK_OPPORTUNITY_CANCELED;

export interface AdNetworkReportCreatedNotification extends Notification {
  type: NotificationType.AD_NETWORK_REPORT_CREATED;
}

export const isAdNetworkReportCreatedNotification = (
  notification: Notification
): notification is AdNetworkReportCreatedNotification =>
  notification.type === NotificationType.AD_NETWORK_REPORT_CREATED;

export interface PaymentRequiresActionNotification extends Notification {
  type: NotificationType.PAYMENT_REQUIRES_ACTION;
}

export const isPaymentRequiresActionNotification = (
  notification: Notification
): notification is PaymentRequiresActionNotification => notification.type === NotificationType.PAYMENT_REQUIRES_ACTION;
