import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { RecommendationWidget, RecommendationWidgetMutatorParams } from '@/interfaces/recommendation_widget';
import api from '@/services/swarm';

const useCreateRecommendationWidget = ({ onSuccess }: RecommendationWidgetMutatorParams) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    (recommendationWidget: Pick<RecommendationWidget, 'trigger_option' | 'redirect_url' | 'trigger_urls'>) =>
      api.post(`/publications/${currentPublicationId}/recommendation_widgets`, {
        recommendation_widget: recommendationWidget,
      }),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response.data.recommendation_widget);
        queryClient.invalidateQueries(['recommendationWidget', currentPublicationId]);

        toast.success('Widget settings updated successfully!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Could not update widget settings.');
      },
    }
  );
};

export default useCreateRecommendationWidget;
