import styled from 'styled-components';

import { colors } from '../../../lib/colors';
import { Styled as StyledIcon } from '../Icon/styled';

export const Styled = {
  Container: styled.div`
    align-items: center;
    background: ${colors.black[8]};
    border-radius: 0.25rem;
    color: ${colors.black[3]};
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: 500;
    margin: -0.5rem;
    padding: 2rem;

    & > ${StyledIcon.Wrapper} {
      svg path {
        fill: ${colors.black[4]};
      }
    }
  `,
  Text: styled.p`
    &&& {
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;
    }
  `,
  Link: styled.a`
    margin-top: 0.5rem;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  `,
};

export default Styled;
