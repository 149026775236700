import React, { FC } from 'react';

// Components
import CustomFieldSelect from '../../../components/CustomFieldSelect';
// Interfaces
import { Condition, Filter } from '../../../interfaces/segment';

import { defaultOperatorByConditionName } from './operatorOptions';

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleFilterChange: (filters: Filter, blockIndex: number, subIndex?: number) => void;
}

const CustomFieldInput: FC<Props> = ({ condition, handleFilterChange, index, subIndex }) => {
  const defaultOperator = defaultOperatorByConditionName(condition?.name);

  if (condition?.name === 'custom_field') {
    return (
      <CustomFieldSelect
        className="w-full"
        customFieldId={condition.filters?.resource_id || ''}
        onSelectCustomField={(customFieldId: string) => {
          const updatedFilters = {
            resource_id: customFieldId,
            operator: condition?.filters?.operator || defaultOperator,
          };

          const newFilters = { ...condition.filters, ...updatedFilters };

          handleFilterChange(newFilters, index, subIndex);
        }}
        onClearCustomField={() => {
          const updatedFilters = {
            resource_id: undefined,
            operator: condition?.filters?.operator || defaultOperator,
          };

          const newFilters = { ...condition.filters, ...updatedFilters };

          handleFilterChange(newFilters, index, subIndex);
        }}
      />
    );
  }

  return null;
};

export default CustomFieldInput;
