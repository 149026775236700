import { ArrowDownTrayIcon, ShareIcon } from '@heroicons/react/20/solid';

import { useAsyncExport, useCopyToClipboard, useEmojiChart } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Poll } from '@/interfaces/poll';
import { Button } from '@/ui/Button';

const ShareResults = ({ poll }: { poll: Poll }) => {
  const copy = useCopyToClipboard();

  const chartData = Object.keys(poll.metrics).map((metricKey) => ({
    label: metricKey,
    value: poll.metrics[metricKey],
  }));

  const emojiChart = useEmojiChart({
    data: chartData,
    title: poll.question,
    describes: 'Votes',
    signature: 'via @beehiiv polls',
  });

  const currentPublicationId = useCurrentPublicationId();
  const { isExporting, startExport } = useAsyncExport(currentPublicationId, {
    exportType: 'poll_responses',
    resourceType: 'poll',
    resourceId: poll?.id || '',
  });

  return (
    <div className="flex-shrink-0 space-x-2 items-end flex">
      <Button variant="primary-inverse" onClick={startExport} loading={isExporting}>
        <div className="flex">
          <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
          Export Results
        </div>
      </Button>
      <Button
        variant="primary-inverse"
        onClick={() => copy({ text: emojiChart, onSuccessText: 'Copied results to clipboard' })}
      >
        <ShareIcon className="mr-2 w-4 h-4" />
        Share Results
      </Button>
    </div>
  );
};

export default ShareResults;
