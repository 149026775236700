import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import * as DialogModal from '@/components/DialogModal';
import IconButton from '@/components/IconHelpers/IconButton';
import { Asset } from '@/interfaces/asset';

import { MetaDataModalContent } from '../components/MetaDataModalContent';

export type MetaDataModalProps = {
  assetId: string;
  publicationId: string;
  open: boolean;
  onClose: () => void;
  onChange: (asset: Asset) => void;
};

export const MetaDataModal = ({ open, onClose, onChange, assetId, publicationId }: MetaDataModalProps) => {
  return (
    <DialogModal.Wrapper className="px-6 pt-3 pb-6" open={open} onClose={onClose}>
      <DialogModal.Header className="mb-4" title="Meta data" onClose={onClose}>
        <IconButton onClick={onClose}>
          <ArrowLeftIcon className="w-5 h-5" />
        </IconButton>
      </DialogModal.Header>
      {open ? (
        <MetaDataModalContent assetId={assetId} onClose={onClose} onChange={onChange} publicationId={publicationId} />
      ) : null}
    </DialogModal.Wrapper>
  );
};
