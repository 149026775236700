import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface ApiResponse {
  id: string;
}

interface Arguments {
  subscriptionMilestoneId: string;
  onSuccess: () => void;
}

const useAssignRewardPromoCode = ({ subscriptionMilestoneId, onSuccess }: Arguments) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, { promoCodeId: string }>(
    ({ promoCodeId }) =>
      api
        .post(`/referral_program/subscription_milestones/${subscriptionMilestoneId}/assign_promo_code/${promoCodeId}`, {
          publication_id: currentPublicationId,
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useAssignRewardPromoCode;
