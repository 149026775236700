import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBox from '../../../components/LoadingBox';
import { useCurrentPublicationState } from '../../../context/current-publication-context';
// Hooks
import { useContentTag } from '../../../hooks';
// Services
import api from '../../../services/swarm';
// Components
import ContentTagForm from '../ContentTagForm';

interface RouteParams {
  contentTagId: string;
}

const EditContentTag = () => {
  const { contentTagId: id } = useParams() as unknown as RouteParams;
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useContentTag(id);

  const updateContentTagMutation = useMutation(
    ({ display, description }: { display: string; description: string }) =>
      api.patch(`/content_tags/${id}`, {
        publication_id: currentPublicationId,
        content_tag: {
          display,
          description,
        },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        toast.success('Content Tag updated successfully');
        navigate(`/content_tags`);
      },
    }
  );

  const onSubmit = async (display: string, description: string) => {
    await updateContentTagMutation.mutateAsync({ display, description });
  };

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <>
        {data && (
          <ContentTagForm
            onSubmit={onSubmit}
            header="Edit Content Tag"
            content_tag={{ display: data?.display, description: data?.description }}
          />
        )}
      </>
    </LoadingBox>
  );
};

export default EditContentTag;
