import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface ResendPublicationChangeConfirmationEmailParams {
  field: string;
}

const useResendPublicationChangeConfirmation = (publicationId: string) => {
  return useMutation<{ message: string }, { error: string }, ResendPublicationChangeConfirmationEmailParams>(
    (requestParams) =>
      api.post(`/publication_changes/resend_confirmation_email`, {
        publication_id: publicationId,
        publication_change: {
          ...requestParams,
        },
      }).then((resp) => resp.data),
    {
      onSuccess: (data) => {
        toast.success(data.message);
      },
      onError: (err) => {
        toast.error(err.error);
      },
    }
  );
};

export default useResendPublicationChangeConfirmation;
