import AppliedFilter from "../AppliedFilter";
import { AttributeCondition, TierOperatorsList } from "../types";

const TierFilterApplied = ({condition, onRemove}: { condition: AttributeCondition, onRemove: () => void}) => {
  const operator = TierOperatorsList.find((o) => o.value === condition.filters.operator);
  if (!operator) return null;

  return <AppliedFilter name="Tier" label={operator.label} onRemove={onRemove} value={condition.filters.value} />;
}

export default TierFilterApplied;
