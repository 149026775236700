import LoadingBox from '@/components/LoadingBox';
import { ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { useContentImports } from '@/hooks/useContentImports';
import { Card } from '@/ui/Card';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import { usePublicationSettings } from '../context';

import ListItem from './ListItem';

const HistoricalImports: React.FC = () => {
  const { currentPublicationId } = usePublicationSettings();
  const { data: contentImportsData, isLoading, isError } = useContentImports({ publicationId: currentPublicationId });
  const contentImports = contentImportsData?.pages.flatMap((page) => page.content_imports) || [];
  const description = contentImports.length < 1 ? 'No historical imports available.' : undefined;

  return (
    <Card id="history" showCopyIdButton>
      <LoadingBox isLoading={isLoading} isError={isError}>
        <BodyContainer>
          <CardHeader title="Historical Imports" description={description} />

          {contentImports.length > 0 ? (
            <div className="overflow-scroll max-h-96 border rounded-md border-gray-200 relative">
              <Items>
                {(isInViewport) => (
                  <>
                    <ItemHeaders isSticky={isInViewport} canIntersectWithTopBar={false}>
                      <ItemHeader align={AlignType.LEFT}>Import From</ItemHeader>
                      <ItemHeader align={AlignType.LEFT}>Uploaded By</ItemHeader>
                      <ItemHeader align={AlignType.LEFT}>Status</ItemHeader>
                      <ItemHeader align={AlignType.LEFT}>Date Uploaded</ItemHeader>
                      <ItemHeader align={AlignType.LEFT}>Posts Created</ItemHeader>
                      <ItemHeader align={AlignType.LEFT}>Posts Failed</ItemHeader>
                    </ItemHeaders>

                    <ItemsBody>
                      {contentImports.map((contentImport) => (
                        <ListItem key={contentImport.id} contentImport={contentImport} />
                      ))}
                    </ItemsBody>
                  </>
                )}
              </Items>
            </div>
          ) : null}
        </BodyContainer>
      </LoadingBox>
    </Card>
  );
};

export default HistoricalImports;
