import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { ExternalEmbedsShowResponse } from '../../interfaces/external_embed';
import api from '../../services/swarm';

const useExternalEmbed = (embedId?: string | undefined | null) => {
  const [publicationId] = useCurrentPublicationState();
  const { externalEmbedId } = useParams();
  const id = embedId || externalEmbedId;

  return useQuery<ExternalEmbedsShowResponse>(
    ['externalEmbed', publicationId, externalEmbedId],
    () =>
      api
        .get<ExternalEmbedsShowResponse>(`/publications/${publicationId}/external_embeds/${id}`)
        .then((res) => res.data),
    {
      enabled: !!id,
    }
  );
};

export default useExternalEmbed;
