import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import { Placement } from 'tippy.js';

import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { API } from '@/components/TiptapEditor/lib/api';
import { useOpportunity } from '@/hooks/useAdNetwork/publisher';
import { AdNetworkAdvertisementSponsorText } from '@/interfaces/ad_network/internal/advertisement';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';

import { AdvertisementOpportunityLogoContext } from './AdvertisementOpportunityLogoContext';
import { Styled } from './AdvertisementOpportunityLogoView.styled';

export interface TippyProps {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

const ImageBlockWrapper = styled.div<{
  align: 'left' | 'center' | 'right';
}>`
  margin-left: ${(p) => (p.align === 'left' ? '0' : 'auto')};
  margin-right: ${(p) => (p.align === 'right' ? '0' : 'auto')};
`;

const SponsorTextWrapper = styled.div<{
  align: 'left' | 'center' | 'right';
}>`
  display: block;
  text-align: ${(p) => p.align};
  width: 100%;
`;

const ImageWrapper = styled.div<{
  align: 'left' | 'center' | 'right';
  width: number;
}>`
  width: ${(p) => `${p.width}px`};
  margin-left: ${(p) => (p.align === 'left' ? '0' : 'auto')};
  margin-right: ${(p) => (p.align === 'right' ? '0' : 'auto')};
`;

const Image = styled.img<{
  width: number;
}>`
  width: ${(p) => `${p.width}px !important`};
  max-width: 300px !important;
  display: block;
  height: auto;
`;

export const AdvertisementOpportunityLogoView = ({
  editor,
  node,
  updateAttributes,
  extension: {
    options: { publicationId },
  },
}: NodeViewProps) => {
  const opportunityId = useMemo(() => node.attrs.id, [node.attrs.id]);
  const [data, setData] = useState<AdNetworkOpportunity | undefined>(undefined);
  const image = data?.advertiser.logo_url;
  const { overrideSponsorText, overrideWidth, overrideAlign } = node.attrs;
  const [sponsorText, setSponsorText] = useState(overrideSponsorText);
  const [width, setWidth] = useState(overrideWidth);
  const [align, setAlign] = useState(overrideAlign);
  const imgWidthPx = width ? (width / 100) * 300 : 300;
  const { data: opportunity } = useOpportunity({ id: opportunityId });
  const defaultSponsorText = opportunity?.advertisement.sponsor_text;
  const sponsorTextArray = (
    Object.keys(AdNetworkAdvertisementSponsorText) as Array<keyof typeof AdNetworkAdvertisementSponsorText>
  ).map((key) => {
    const value = AdNetworkAdvertisementSponsorText[key];

    const label = (value.charAt(0).toUpperCase() + value.slice(1)).replace(/_/g, ' ').replace('Todays', "Today's");

    return {
      value,
      label,
    };
  });

  useEffect(() => {
    if (node.attrs.showOptionsInitially) {
      updateAttributes({
        showOptionsInitially: false,
      });
    }
  }, [updateAttributes, node.attrs.showOptionsInitially]);

  useEffect(() => {
    if (opportunityId) {
      API.getAdvertisementOpportunity({
        publicationId,
        opportunityId,
      })
        .then((res) => {
          setData(res.data);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    }
  }, [publicationId, opportunityId]);

  useEffect(() => {
    setSponsorText(overrideSponsorText || defaultSponsorText);
  }, [overrideSponsorText, defaultSponsorText]);

  useEffect(() => {
    setWidth(overrideWidth || 100);
  }, [overrideWidth]);

  useEffect(() => {
    setAlign(overrideAlign || 'center');
  }, [overrideAlign]);

  const providerValue = useMemo(
    () => ({
      sponsorText,
      setSponsorText,
      width,
      setWidth,
      align,
      setAlign,
    }),
    [align, sponsorText, width]
  );

  return (
    <AdvertisementOpportunityLogoContext.Provider value={providerValue}>
      <NodeViewWrapper data-drag-handle data-id={opportunityId}>
        <div {...{ inert: editor.isEditable ? undefined : '' }}>
          {data && (
            <Styled.DataContainer>
              <div className="flex items-center space-x-2 text-sm font-bold text-primary-600">
                <Icon name="Image" />
                <span>Advertisement Logo</span>
              </div>
              <Styled.TextContainer>
                <Styled.TextContainer>
                  <b>
                    {data.advertiser.name} / {data.campaign.name}
                  </b>
                </Styled.TextContainer>
                {sponsorText && sponsorText !== defaultSponsorText && (
                  <Styled.TextContainer className="my-2">
                    Custom Sponsor Text: {sponsorTextArray.find((item) => item.value === sponsorText)?.label}
                  </Styled.TextContainer>
                )}
              </Styled.TextContainer>
              <div className="flex items-center space-x-2 text-sm font-bold text-primary-600">
                <Icon name="Show" />
                <span>Preview</span>
              </div>
              <ImageBlockWrapper align={align}>
                <SponsorTextWrapper align={align}>
                  <b>{sponsorTextArray.find((item) => item.value === sponsorText)?.label}</b>
                </SponsorTextWrapper>
                <ImageWrapper width={imgWidthPx} align={align}>
                  <Image src={image} width={imgWidthPx} />
                </ImageWrapper>
              </ImageBlockWrapper>
            </Styled.DataContainer>
          )}
        </div>
      </NodeViewWrapper>
    </AdvertisementOpportunityLogoContext.Provider>
  );
};
