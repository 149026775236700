import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import HelpingHand from '@/components/HelpingHand';

import { useCurrentPublication } from '../../../hooks';
import { useHomePage, usePublishHomePage } from '../../../hooks/useHomePages';
import { HomePage } from '../../../interfaces/home_page';
import { Button } from '../../../ui/Button';
import Navigator from '../components/Navigator';
import { PageProvider } from '../components/PageContext';
import PageIframe from '../components/PageIframe';
import SavingIndicatorHelper from '../components/SavingIndicatorHelper';
import ScreenSizeHelper from '../components/ScreenSizeHelper';
import ViewSiteLink from '../components/ViewSiteLink';

import Cards from './Cards';
import FeaturedPostsSection from './FeaturedPostsSection';
import Theme from './Theme';
import WidgetsSection from './WidgetsSection';

const HomePageView = () => {
  const navigate = useNavigate();
  const { data: currentPublication } = useCurrentPublication();
  const hasLivePosts = currentPublication?.has_live_web_posts;

  const homePageQuery = useHomePage({ status: 'draft' });
  const { data: homePage } = homePageQuery;

  // Status was a reserved param on this route already for orchid so we are using version instead
  const iframeUrl = `${currentPublication?.url}?version=draft`;

  const publishHomePage = usePublishHomePage({
    pageId: homePage?.id || '',
    onSuccess: () => {
      toast.success('Published!');
    },
  });

  const renderChildren = () => {
    if (!hasLivePosts) {
      return (
        <div className="h-full w-full flex justify-center items-center">
          <div className="w-full max-w-2xl mx-auto">
            <HelpingHand>
              <p>It looks like you have no live web posts on your site. </p>
              <p>This page is only available on your site once the first post is live!</p>
              <p>First, get started writing and then come back to start designing your Home Page.</p>
            </HelpingHand>
          </div>
        </div>
      );
    }

    return <PageIframe title="Home Page" url={iframeUrl} />;
  };

  return (
    <PageProvider<HomePage>
      bgColor="#ffffff"
      pageData={homePage}
      actionChildren={
        <div className="flex items-center justify-between w-full">
          <div className="w-full flex justify-between items-center mr-4">
            <Navigator />
            <SavingIndicatorHelper />
          </div>

          <div className={cx('flex justify-center', !hasLivePosts && 'pointer-events-none opacity-40')}>
            <div className="w-20">
              <ScreenSizeHelper />
            </div>
          </div>

          <div className={cx('w-full flex between', !hasLivePosts && 'pointer-events-none opacity-40')}>
            <ViewSiteLink url={`${currentPublication?.url}`} />

            <div className="w-full flex justify-end">
              <div className="flex items-center space-x-2 pt-0.5">
                <Button variant="primary-inverse" type="button" onClick={() => navigate('/settings/website/pages')}>
                  Exit
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  Icon={CursorArrowRaysIcon}
                  disabled={publishHomePage.isLoading}
                  loading={publishHomePage.isLoading}
                  onClick={() => {
                    publishHomePage.mutate();
                  }}
                >
                  {publishHomePage.isLoading ? 'Publishing...' : 'Publish to site'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
      sidePanelChildren={
        <div className={cx(!hasLivePosts && 'pointer-events-none opacity-40')}>
          <Theme />
          <FeaturedPostsSection />
          <WidgetsSection />
          <Cards />
        </div>
      }
    >
      {renderChildren()}
    </PageProvider>
  );
};

export default HomePageView;
