import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import {
  DEFAULT_BACKGROUND_SECONDARY_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
} from '../constants';

import { SignupView } from './views/SignupView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    signup: {
      setSignup: () => ReturnType;
    };
  }
}

export const Signup = Node.create<{}, {}>({
  name: 'signup',

  group: 'block',

  draggable: true,

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      orientation: {
        default: 'horizontal',
        renderHTML: (attributes) => ({ 'data-orientation': attributes.orientation }),
        parseHTML: (element) => element.getAttribute('data-orientation') || 'horizontal',
      },
      gap: {
        default: '0px',
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
        parseHTML: (element) => element.getAttribute('data-gap') || '0px',
      },
      borderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
        parseHTML: (element) => element.getAttribute('data-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      borderWidth: {
        default: '0px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '0px',
      },
      borderColor: {
        default: DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_PRIMARY_COLOR,
      },
      borderStyle: {
        default: 'none',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },
      padding: {
        default: '4px',
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
        parseHTML: (element) => element.getAttribute('data-padding') || '4px',
      },
      backgroundColor: {
        default: DEFAULT_BACKGROUND_SECONDARY_COLOR,
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
        parseHTML: (element) => element.getAttribute('data-background-color') || DEFAULT_BACKGROUND_SECONDARY_COLOR,
      },
      height: {
        default: '48px',
        renderHTML: (attributes) => ({ 'data-height': attributes.height }),
        parseHTML: (element) => element.getAttribute('data-height'),
      },
      width: {
        default: 'auto',
        renderHTML: (attributes) => ({ 'data-width': attributes.width }),
        parseHTML: (element) => element.getAttribute('data-width'),
      },
      inputPlaceholder: {
        default: 'Email',
        parseHTML: (element) => element.getAttribute('data-input-placeholder') || 'Email',
        renderHTML: (attributes) => ({ 'data-input-placeholder': attributes.inputPlaceholder }),
      },
      inputBorderColor: {
        default: '#000',
        parseHTML: (element) => element.getAttribute('data-input-border-color') || '#000',
        renderHTML: (attributes) => ({ 'data-input-border-color': attributes.inputBorderColor }),
      },
      inputBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-input-border-width') || '1px',
        renderHTML: (attributes) => ({ 'data-input-border-width': attributes.inputBorderWidth }),
      },
      inputBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-input-border-radius') || DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-input-border-radius': attributes.inputBorderRadius }),
      },
      inputBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-input-border-style') || 'none',
        renderHTML: (attributes) => ({ 'data-input-border-style': attributes.inputBorderStyle }),
      },
      inputPlaceholderColor: {
        default: '#6B7280',
        parseHTML: (element) => element.getAttribute('data-input-placeholder-color') || '#6B7280',
        renderHTML: (attributes) => ({ 'data-input-placeholder-color': attributes.inputPlaceholderColor }),
      },
      inputTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-text-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-input-text-color': attributes.inputTextColor }),
      },
      inputFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-input-font-family') || 'inherit',
        renderHTML: (attributes) => ({ 'data-input-font-family': attributes.inputFontFamily }),
      },
      inputBackgroundColor: {
        default: '#00000000',
        parseHTML: (element) => element.getAttribute('data-input-background-color') || '#00000000',
        renderHTML: (attributes) => ({ 'data-input-background-color': attributes.inputBackgroundColor }),
      },
      buttonBackgroundColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-background-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-button-background-color': attributes.buttonBackgroundColor }),
      },
      buttonBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-button-border-width') || '1px',
        renderHTML: (attributes) => ({ 'data-button-border-width': attributes.buttonBorderWidth }),
      },
      buttonBorderColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-border-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-button-border-color': attributes.buttonBorderColor }),
      },
      buttonBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-button-border-style') || 'none',
        renderHTML: (attributes) => ({ 'data-button-border-style': attributes.buttonBorderStyle }),
      },
      buttonBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-button-border-radius') || DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-button-border-radius': attributes.buttonBorderRadius }),
      },
      buttonTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-text-color') || DEFAULT_TEXT_ON_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-button-text-color': attributes.buttonTextColor }),
      },
      buttonFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-button-font-family') || 'inherit',
        renderHTML: (attributes) => ({ 'data-button-font-family': attributes.buttonFontFamily }),
      },
      icon: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-icon') || '',
        renderHTML: (attributes) => ({ 'data-icon': attributes.icon }),
      },
      iconColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-icon-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-icon-color': attributes.iconColor }),
      },
      signupFlowId: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-signup-flow-id') || '',
        renderHTML: (attributes) => ({ 'data-signup-flow-id': attributes.signupFlowId }),
      },
      buttonText: {
        default: 'Subscribe',
        parseHTML: (element) => element.getAttribute('data-button-text') || 'Subscribe',
        renderHTML: (attributes) => ({ 'data-button-text': attributes.buttonText }),
      },
      buttonSubmittingText: {
        default: 'Subscribing',
        parseHTML: (element) => element.getAttribute('data-button-submitting-text') || 'Subscribing',
        renderHTML: (attributes) => ({ 'data-button-submitting-text': attributes.buttonSubmittingText }),
      },
      gdprTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-gdpr-text-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-gdpr-text-color': attributes.gdprTextColor }),
      },
      gdprCheckboxColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-gdpr-checkbox-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-gdpr-checkbox-color': attributes.gdprCheckboxColor }),
      },
    };
  },

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setSignup:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SignupView, {
      attrs: (props) => {
        return {
          ...props.HTMLAttributes,
          style: `max-width : ${props.node.attrs.width}; width: 100%;`,
        };
      },
    });
  },
});

export default Signup;
