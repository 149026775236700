export type ScopeTypes = 'active' | 'inactive' | 'all';
export type CommentBanTypes = 'none' | 'post' | 'publication';

export enum BanScopes {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ALL = 'all',
}

export enum BanTypes {
  NONE = 'none',
  POST = 'post',
  PUBLICATION = 'publication',
}

export interface CommentBan {
  id: string;
  level: CommentBanTypes;
  lifted_at: string;
  reason: string;
  created_at: string;
  subscription: {
    id: number;
    email: string;
    name: string;
  };
  post: {
    title: string;
  };
}
