import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';

import api from '../../services/swarm';

const useDeletePostTarget = (postId: string) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    (postSendTargetId: string) =>
      api.delete(`/posts/${postId}/post_targets/${postSendTargetId}`, {
        params: {
          publication_id: currentPublicationId,
        },
      }),
    {
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Oops there was an issue updating the Post');
      },
      onSettled: async () => {
        queryClient.invalidateQueries(['post', postId, 'deliveryData']);
        return queryClient.invalidateQueries(['post', postId, 'postTargets']);
      },
    }
  );
};

export default useDeletePostTarget;
