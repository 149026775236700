import { useParams } from 'react-router-dom';
import moment from 'moment-mini';

import { usePublication, usePublicationOpportunities } from '@/hooks/useAdNetwork/internal';
import { AdNetworkDisbursementStatus } from '@/interfaces/ad_network/internal/disbursement/types';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/internal/opportunity';
import { AdNetworkPublication } from '@/interfaces/ad_network/internal/publication';
import { Badge } from '@/routes/ad_network/_components/Badge';
import { BadgeColor } from '@/routes/ad_network/_components/Badge/types';
import { List } from '@/routes/ad_network/_components/List';
import { LoadingListPage } from '@/routes/ad_network/_components/LoadingListPage';
import { PageHeading } from '@/routes/ad_network/_components/PageHeading';
import OpportunityStatusIndicator from '@/routes/ad_network/_components/StatusIndicator/OpportunityStatusIndicator';
import { Button } from '@/ui/Button';

interface Props {
  publication: AdNetworkPublication;
  opportunities: AdNetworkOpportunity[];
  hasNextPage: boolean;
  fetchNextPage: () => Promise<any>;
}

function simplifiedDisbursementStatus(status: string) {
  switch (status) {
    case AdNetworkDisbursementStatus.NOT_STARTED:
      return 'Not Running';
    case AdNetworkDisbursementStatus.SCHEDULED:
      return 'Not Running';
    case AdNetworkDisbursementStatus.RUNNING:
      return 'Running';
    default:
      return 'Done Running';
  }
}

function simplifiedDisbursementStatusColor(status: string) {
  switch (status) {
    case AdNetworkDisbursementStatus.NOT_STARTED:
      return BadgeColor.BLUE;
    case AdNetworkDisbursementStatus.SCHEDULED:
      return BadgeColor.BLUE;
    case AdNetworkDisbursementStatus.RUNNING:
      return BadgeColor.YELLOW;
    default:
      return BadgeColor.GREEN;
  }
}

const PublicationOpportunities = ({ publication, opportunities, hasNextPage, fetchNextPage }: Props) => {
  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{publication.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Opportunities</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button to="new" size="xs" variant="primary-inverse">
            New
          </Button>
        </PageHeading.Side>
      </PageHeading>
      <List hasNextPage={hasNextPage} fetchNextPage={fetchNextPage}>
        {opportunities.map((opportunity) => {
          return (
            <List.Item to={`/ad_network/opportunities/${opportunity.id}`} key={opportunity.id}>
              <List.Item.Text variant="primary">
                <span>{opportunity.advertiser.name}</span>
                <span>/</span>
                <span>{opportunity.campaign.name}</span>
                <span>
                  <OpportunityStatusIndicator opportunity={opportunity} />
                </span>
                <span>
                  <Badge color={simplifiedDisbursementStatusColor(opportunity.disbursement.status)}>
                    {simplifiedDisbursementStatus(opportunity.disbursement.status)}
                  </Badge>
                </span>
              </List.Item.Text>
              {opportunity.provisioned_at ? (
                <List.Item.Text variant="secondary">
                  Provisioned {moment(opportunity.provisioned_at).format('LLLL')}
                </List.Item.Text>
              ) : (
                <List.Item.Text variant="secondary">N/A</List.Item.Text>
              )}
            </List.Item>
          );
        })}
      </List>
    </>
  );
};

export default function Loader() {
  const { publication_id: id } = useParams<'publication_id'>() as { publication_id: string };
  // const { data: publication, isSuccess, isLoading, isError } = usePublication({ id });
  const publicationQuery = usePublication({ id });
  const opportunitiesQuery = usePublicationOpportunities({ publicationId: id });

  const isSuccess = publicationQuery.isSuccess && opportunitiesQuery.isSuccess;
  const isError = publicationQuery.isError || opportunitiesQuery.isError;
  const isLoading = !isError && (publicationQuery.isLoading || opportunitiesQuery.isLoading);

  if (!isSuccess || isLoading || isError) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const publication = publicationQuery.data;
  const opportunities = opportunitiesQuery.data.pages.flatMap((page) => page.opportunities);
  return (
    <PublicationOpportunities
      publication={publication}
      opportunities={opportunities}
      hasNextPage={!!opportunitiesQuery.hasNextPage}
      fetchNextPage={opportunitiesQuery.fetchNextPage}
    />
  );
}
