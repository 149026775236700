import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { EmptyBlock } from '@/components/EmptyBlock';
import { SortableList } from '@/components/SortableList';
import UnstyledSortableListItem from '@/components/SortableList/UnstyledSortableListItem';
import { useSubscriberPreferences } from '@/hooks/useSubscriberPreferences';
import useUpdateSubscriberPreferences from '@/hooks/useSubscriberPreferences/useUpdateSubscriberPreferences';
import { Order } from '@/interfaces/general';
import {
  OrderBy,
  PublicationSubscriberPreference,
  SubscriberPreferenceVisibility,
} from '@/interfaces/subscribers_preferences';

const ConfigureSubscriberPreferences = () => {
  const [preferencesInState, setPreferencesInState] = useState<PublicationSubscriberPreference[]>([]);

  const { data, isLoading } = useSubscriberPreferences({
    all: true,
    visibility: SubscriberPreferenceVisibility.VISIBLE,
    orderBy: OrderBy.SORT_ORDER,
    direction: Order.ASC,
  });
  const preferences = useMemo(() => data?.pages.flatMap((page) => page.subscriber_preferences) || [], [data?.pages]);

  const { mutateAsync: update } = useUpdateSubscriberPreferences();

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const activePostitionId = preferences.find((position: any) => position.id === active.id);
      const overPostitionId = preferences.find((position: any) => position.id === over?.id);
      const oldIndex = preferences.indexOf(activePostitionId as any);
      const newIndex = preferences.indexOf(overPostitionId as any);
      const newList = arrayMove(preferences, oldIndex, newIndex);
      setPreferencesInState(newList);
      update(newList);
    }
  };

  useEffect(() => {
    if (!isLoading && preferences.length > 0) {
      setPreferencesInState(preferences);
    }
  }, [isLoading, preferences]);

  if (preferencesInState.length === 0) {
    return (
      <EmptyBlock className="gap-x-1">
        No Preferences added yet, click{' '}
        <Link className="text-primary-700" to="/subscribers/preferences/new">
          here
        </Link>{' '}
        to add one
      </EmptyBlock>
    );
  }

  return (
    <SortableList listItems={preferencesInState} onDragEndEvent={onDragEnd}>
      {(list) => {
        return list.map((item: PublicationSubscriberPreference) => {
          return (
            <UnstyledSortableListItem
              className="bg-white rounded hover:bg-gray-100 px-4 py-4 border rounded-md"
              key={item.id}
              listItem={item}
              text={item.name}
            />
          );
        });
      }}
    </SortableList>
  );
};

export default ConfigureSubscriberPreferences;
