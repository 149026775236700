import { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tiptap/react';

import { FieldSet, Range } from '@/components/TiptapEditor/components/ui/Input';
import { PanelHeadline, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { useDebounce } from '@/components/TiptapEditor/lib/hooks/useDebounce';

export const ImageWidth = ({ editor }: { editor: Editor }) => {
  const [value, setValue] = useState<number | null>(null);

  const changedViaInput = useRef(false);
  const debouncedValue = useDebounce(value, 250);

  const width = parseInt(editor.getAttributes('imageBlock').width, 10) || 100;

  useEffect(() => {
    setValue(width);
  }, [width]);

  useEffect(() => {
    if (debouncedValue !== null) {
      editor.commands.setImageBlockWidth(debouncedValue);
    }
  }, [editor, debouncedValue]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    changedViaInput.current = false;

    setValue(Number(e.target.value));
  }, []);

  const handleChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    changedViaInput.current = true;

    setValue(Number(e.target.value));
  }, []);

  return (
    <PanelSection>
      <PanelHeadline>Image width</PanelHeadline>
      <FieldSet>
        <Range
          withInput
          min="10"
          max="100"
          step="5"
          value={value?.toString() || '50'}
          onChangeInput={handleChangeInput}
          onChange={handleChange}
        />
      </FieldSet>
    </PanelSection>
  );
};

export default ImageWidth;
