import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { useCurrentPublication } from '../../../../../hooks';
import { DataExport, DataExportType } from '../../../../../interfaces/data_export';
import api from '../../../../../services/swarm';
import { Section } from '../../../Components';

import DataExportRow from './DataExportRow';

interface DataExportsCreateResponse {
  export: DataExport;
}

interface DataExportsIndexResponse {
  exports: DataExport[];
}

interface HeaderCellProps {
  width: number;
  text: string;
}

const HeaderCell: React.FunctionComponent<HeaderCellProps> = ({ width, text }) => (
  <div className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider col-span-${width}`}>
    {text}
  </div>
);

const ExportData = () => {
  const { data: currentPublication } = useCurrentPublication();
  const [disabled, setDisabled] = useState(false);
  const [arePostsExporting, setArePostsExporting] = useState<boolean>(false);
  const [areSubscribersExporting, setAreSubscribersExporting] = useState<boolean>(false);
  const [dataExports, addDataExport] = useReducer(
    (oldExports: DataExport[], newExport: DataExport) =>
      // Prepend dataExport to the dataExports array and filter the dataExports array for duplicate IDs.
      [newExport, ...oldExports].filter(
        (dataExport, index, unsortedExports) => unsortedExports.map((dEx) => dEx.id).indexOf(dataExport.id) === index
      ),
    []
  );

  useEffect(() => {
    if (typeof currentPublication === 'undefined') return;

    api
      .get<DataExportsIndexResponse>(`/publications/${currentPublication.id}/export_data`)
      .then((response) => response.data.exports.map((dataExport) => addDataExport(dataExport)))
      .catch(() => toast.error('Sorry, we ran into a problem trying to get your recent exports.'));
  }, [currentPublication]);

  const createExport = (id: string, type: DataExportType) => {
    if (type === 'posts') {
      analytics.track('Export Posts');
      setArePostsExporting(true);
    } else if (type === 'subscribers') {
      analytics.track('Export Subscribers (Full)');
      setAreSubscribersExporting(true);
    } else {
      analytics.track('Export Subscribers (Quick)');
    }

    const params = {
      export: { type },
    };

    setDisabled(true);

    api
      .post<DataExportsCreateResponse>(`/publications/${id}/export_data`, params)
      .then((response) => {
        addDataExport(response.data.export);
        toast.success("You'll get an email when your export is ready.");
      })
      .catch((errPayload) => {
        toast.error(errPayload?.response?.data?.error || 'Sorry, we ran into a problem trying to create your export.');
      })
      .finally(() => {
        setDisabled(false);
        setAreSubscribersExporting(false);
        setArePostsExporting(false);
      });
  };

  return (
    <>
      <Section
        title="Export Subscribers (Quick)"
        description="Download your subscribers broken out with email, status, and tier ordered by most recently subscribed"
        scrollToId="quick-export-subscribers"
        isLoading={!currentPublication}
      >
        <Button
          variant="primary-inverse"
          type="submit"
          loading={areSubscribersExporting}
          disabled={disabled}
          onClick={() => createExport(currentPublication?.id || '', 'basic_subscriber')}
        >
          {areSubscribersExporting ? 'Exporting...' : 'Export All Subscribers'}
        </Button>
      </Section>
      <Section
        title="Export Subscribers (Full)"
        description="Download your subscribers broken out with email, status, tier, custom fields and stats, ordered by most recently subscribed. This includes more information than the quick export but takes much longer to process."
        scrollToId="export-subscribers"
        isLoading={!currentPublication}
      >
        <Button
          variant="primary-inverse"
          type="submit"
          loading={areSubscribersExporting}
          disabled={disabled}
          onClick={() => createExport(currentPublication?.id || '', 'subscribers')}
        >
          {areSubscribersExporting ? 'Exporting...' : 'Export All Subscribers'}
        </Button>
      </Section>
      <Section
        title="Export Posts"
        description="Download your posts with your content broken out with metadata"
        scrollToId="export-posts"
        isLoading={!currentPublication}
      >
        <Button
          variant="primary-inverse"
          type="submit"
          loading={arePostsExporting}
          disabled={disabled}
          onClick={() => createExport(currentPublication?.id || '', 'posts')}
        >
          {arePostsExporting ? 'Exporting...' : 'Export All Posts'}
        </Button>
      </Section>
      <Section
        title="Recent Exports"
        description="Showing your recent exports"
        scrollToId="export_data"
        isLoading={!currentPublication}
        hasDivider={false}
      >
        {dataExports.length > 0 ? (
          <div className="rounded border border-gray-200">
            <div className="bg-gray-50 grid grid-cols-12 border-b border-gray-200 rounded-t-lg">
              <HeaderCell text="Date" width={3} />
              <HeaderCell text="Type" width={2} />
              <HeaderCell text="Status" width={4} />
              <HeaderCell text="Download Link" width={3} />
            </div>
            <div className="bg-white max-h-72 overflow-y-auto rounded-b-lg">
              {dataExports.map((dataExport) => (
                <DataExportRow key={dataExport.id} dataExport={dataExport} />
              ))}
            </div>
          </div>
        ) : (
          <p className="block text-md font-medium  text-gray-600">No Recent Exports</p>
        )}
      </Section>
    </>
  );
};

export default ExportData;
