import { mergeAttributes, Node } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    section: {
      setSection: () => ReturnType;
      unsetSection: () => ReturnType;

      // Background color
      setSectionBackgroundColor: (color: string | null) => ReturnType;

      // Text color
      setSectionTextColor: (color: string | null) => ReturnType;

      // Padding
      setIndividualSectionPadding: (value: boolean) => ReturnType;
      toggleIndividualSectionPadding: () => ReturnType;
      setSectionPadding: (value: number) => ReturnType;
      setSectionTopPadding: (value: number) => ReturnType;
      setSectionRightPadding: (value: number) => ReturnType;
      setSectionBottomPadding: (value: number) => ReturnType;
      setSectionLeftPadding: (value: number) => ReturnType;

      // Margin
      setIndividualSectionMargin: (value: boolean) => ReturnType;
      toggleIndividualSectionMargin: () => ReturnType;
      setSectionMargin: (value: number) => ReturnType;
      setSectionTopMargin: (value: number) => ReturnType;
      setSectionBottomMargin: (value: number) => ReturnType;
      setSectionLeftMargin: (value: number) => ReturnType;
      setSectionRightMargin: (value: number) => ReturnType;

      // Border width
      setIndividualSectionBorderWidth: (value: boolean) => ReturnType;
      toggleIndividualSectionBorderWidth: () => ReturnType;
      setSectionBorderWidth: (value: number) => ReturnType;
      setSectionTopBorderWidth: (value: number) => ReturnType;
      setSectionRightBorderWidth: (value: number) => ReturnType;
      setSectionBottomBorderWidth: (value: number) => ReturnType;
      setSectionLeftBorderWidth: (value: number) => ReturnType;

      // Border radius
      setIndividualSectionBorderRadius: (value: boolean) => ReturnType;
      toggleIndividualSectionBorderRadius: () => ReturnType;
      setSectionBorderRadius: (value: number) => ReturnType;
      setSectionTopLeftBorderRadius: (value: number) => ReturnType;
      setSectionTopRightBorderRadius: (value: number) => ReturnType;
      setSectionBottomRightBorderRadius: (value: number) => ReturnType;
      setSectionBottomLeftBorderRadius: (value: number) => ReturnType;

      // Border color
      setSectionBorderColor: (color: string | null) => ReturnType;

      // Border style
      setSectionBorderStyle: (style: 'solid' | 'dotted' | 'dashed') => ReturnType;
    };
  }
}

export interface SectionOptions {
  HTMLAttributes: Record<string, any>;
}

export const Section = Node.create<SectionOptions>({
  name: 'section',

  content: '(block|columns|tableBlock|footnotesNode|serviceEmbed|codeBlockGroup)+',

  group: 'section',

  selectable: true,

  draggable: true,

  isolating: true,

  addAttributes() {
    return {
      backgroundColor: {
        default: null,
        renderHTML: (attributes) => ({
          'data-background-color': attributes.backgroundColor,
        }),
      },
      color: {
        default: null,
        renderHTML: (attributes) => ({
          'data-color': attributes.color,
        }),
      },
      showOnWebsite: {
        default: true,
        renderHTML: (attributes) => ({
          'data-show-on-website': attributes.showOnWebsite,
        }),
      },
      showInEmail: {
        default: true,
        renderHTML: (attributes) => ({
          'data-show-in-email': attributes.showInEmail,
        }),
      },
      showToNonSubscribers: {
        default: true,
        renderHTML: (attributes) => ({
          'data-show-to-non-subscribers': attributes.showToNonSubscribers,
        }),
      },
      showToFreeSubscribers: {
        default: true,
        renderHTML: (attributes) => ({
          'data-show-to-free-subscribers': attributes.showToFreeSubscribers,
        }),
      },
      showToPaidSubscribers: {
        default: true,
        renderHTML: (attributes) => ({
          'data-show-to-paid-subscribers': attributes.showToPaidSubscribers,
        }),
      },
      showToTieredSubscribers: {
        default: [],
        renderHTML: (attributes) => ({
          'data-show-to-tiered-subscribers': attributes.showToTieredSubscribers,
        }),
      },

      // Referral count
      showWithReferralCount: {
        default: false,
        renderHTML: (attributes) => ({
          'data-show-with-referral-count': attributes.showWithReferralCount,
        }),
      },
      referralCountValue: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-referral-count-value': attributes.referralCountValue,
        }),
      },
      referralCondition: {
        default: 'lt',
        renderHTML: (attributes) => ({
          'data-referral-condition': attributes.referralCondition,
        }),
      },

      // Padding
      paddingTop: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-padding-top': attributes.paddingTop,
        }),
      },
      paddingRight: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-padding-right': attributes.paddingRight,
        }),
      },
      paddingBottom: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-padding-bottom': attributes.paddingBottom,
        }),
      },
      paddingLeft: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-padding-left': attributes.paddingLeft,
        }),
      },
      useIndividualPadding: {
        default: false,
        renderHTML: (attributes) => ({
          'data-use-individual-padding': attributes.useIndividualPadding,
        }),
      },

      // Margin
      marginTop: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-margin-top': attributes.marginTop,
        }),
      },
      marginBottom: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-margin-bottom': attributes.marginBottom,
        }),
      },
      marginLeft: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-margin-left': attributes.marginLeft,
        }),
      },
      marginRight: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-margin-right': attributes.marginRight,
        }),
      },
      useIndividualMargin: {
        default: false,
        renderHTML: (attributes) => ({
          'data-use-individual-margin': attributes.useIndividualMargin,
        }),
      },

      // Border width
      borderWidthTop: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-border-width-top': attributes.borderWidthTop,
        }),
      },
      borderWidthRight: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-border-width-right': attributes.borderWidthRight,
        }),
      },
      borderWidthBottom: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-border-width-bottom': attributes.borderWidthBottom,
        }),
      },
      borderWidthLeft: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-border-width-left': attributes.borderWidthLeft,
        }),
      },
      useIndividualBorderWidth: {
        default: false,
        renderHTML: (attributes) => ({
          'data-use-individual-border-width': attributes.useIndividualBorderWidth,
        }),
      },

      // Border radius
      borderTopLeftRadius: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-border-top-left-radius': attributes.borderTopLeftRadius,
        }),
      },
      borderTopRightRadius: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-border-top-right-radius': attributes.borderTopRightRadius,
        }),
      },
      borderBottomRightRadius: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-border-bottom-right-radius': attributes.borderBottomRightRadius,
        }),
      },
      borderBottomLeftRadius: {
        default: 0,
        renderHTML: (attributes) => ({
          'data-border-bottom-left-radius': attributes.borderBottomLeftRadius,
        }),
      },
      useIndividualBorderRadius: {
        default: false,
        renderHTML: (attributes) => ({
          'data-use-individual-border-radius': attributes.useIndividualBorderRadius,
        }),
      },

      // Border color
      borderColor: {
        default: null,
        renderHTML: (attributes) => ({
          'data-border-color': attributes.borderColor,
        }),
      },

      // Border style
      borderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({
          'data-border-style': attributes.borderStyle,
        }),
      },
    };
  },

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'node-section',
      },
    };
  },

  addCommands() {
    return {
      setSection:
        () =>
        ({ commands }: any) => {
          return commands.toggleWrap(this.name);
        },
      unsetSection:
        () =>
        ({ editor, state, tr, dispatch }: any) => {
          tr.setMeta('preventDispatch', true);

          if (!dispatch) {
            return true;
          }

          const { view } = editor;
          const { doc, selection } = state;
          const slice = doc.slice(selection.from + 1, selection.to - 1).content;

          tr.replaceWith(selection.from, selection.to, slice);
          view.dispatch(tr);

          return true;
        },

      // Background color
      setSectionBackgroundColor:
        (color: string | null) =>
        ({ chain }: any) => {
          return chain()
            .updateAttributes(this.name, {
              backgroundColor: color,
            })
            .run();
        },

      // Text color
      setSectionTextColor:
        (color: string | null) =>
        ({ commands }: any) => {
          return commands.updateAttributes(this.name, {
            color,
          });
        },

      // Padding
      setIndividualSectionPadding:
        (value: boolean) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { useIndividualPadding: value }),
      toggleIndividualSectionPadding:
        () =>
        ({ editor, chain }: any) => {
          const { useIndividualPadding, paddingTop } = editor.getAttributes(this.name);
          const newUseIndividualPadding = !useIndividualPadding;

          if (newUseIndividualPadding === false) {
            chain().updateAttributes(this.name, {
              paddingTop,
              paddingRight: paddingTop,
              paddingBottom: paddingTop,
              paddingLeft: paddingTop,
            });
          }

          return chain().updateAttributes(this.name, { useIndividualPadding: newUseIndividualPadding }).run;
        },
      setSectionPadding:
        (value: number) =>
        ({ chain }: any) =>
          chain()
            .setSectionTopPadding(value)
            .setSectionRightPadding(value)
            .setSectionBottomPadding(value)
            .setSectionLeftPadding(value)
            .run(),
      setSectionTopPadding:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { paddingTop: value }),
      setSectionRightPadding:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { paddingRight: value }),
      setSectionBottomPadding:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { paddingBottom: value }),
      setSectionLeftPadding:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { paddingLeft: value }),

      // Margin
      setIndividualSectionMargin:
        (value: boolean) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { useIndividualMargin: value }),
      toggleIndividualSectionMargin:
        () =>
        ({ editor, chain }: any) => {
          const { useIndividualMargin, marginTop } = editor.getAttributes(this.name);
          const newUseIndividualMargin = !useIndividualMargin;

          if (newUseIndividualMargin === false) {
            chain().updateAttributes(this.name, {
              marginTop,
              marginBottom: marginTop,
              marginLeft: marginTop,
              marginRight: marginTop,
            });
          }

          return chain().updateAttributes(this.name, { useIndividualMargin: newUseIndividualMargin }).run;
        },
      setSectionMargin:
        (value: number) =>
        ({ chain }: any) =>
          chain()
            .setSectionTopMargin(value)
            .setSectionBottomMargin(value)
            .setSectionLeftMargin(value)
            .setSectionRightMargin(value)
            .run(),
      setSectionTopMargin:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { marginTop: value }),
      setSectionBottomMargin:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { marginBottom: value }),
      setSectionLeftMargin:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { marginLeft: value }),
      setSectionRightMargin:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { marginRight: value }),

      // Border width
      setIndividualSectionBorderWidth:
        (value: boolean) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { useIndividualBorderWidth: value }),
      toggleIndividualSectionBorderWidth:
        () =>
        ({ editor, chain }: any) => {
          const { useIndividualBorderWidth, borderWidthTop } = editor.getAttributes(this.name);
          const newUseIndividualBorderWidth = !useIndividualBorderWidth;

          if (newUseIndividualBorderWidth === false) {
            chain().updateAttributes(this.name, {
              borderWidthTop,
              borderWidthRight: borderWidthTop,
              borderWidthBottom: borderWidthTop,
              borderWidthLeft: borderWidthTop,
            });
          }

          return chain().updateAttributes(this.name, { useIndividualBorderWidth: newUseIndividualBorderWidth }).run;
        },
      setSectionBorderWidth:
        (value: number) =>
        ({ chain }: any) =>
          chain()
            .setSectionTopBorderWidth(value)
            .setSectionRightBorderWidth(value)
            .setSectionBottomBorderWidth(value)
            .setSectionLeftBorderWidth(value)
            .run(),
      setSectionTopBorderWidth:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderWidthTop: value }),
      setSectionRightBorderWidth:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderWidthRight: value }),
      setSectionBottomBorderWidth:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderWidthBottom: value }),
      setSectionLeftBorderWidth:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderWidthLeft: value }),

      // Border radius
      setIndividualSectionBorderRadius:
        (value: boolean) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { useIndividualBorderRadius: value }),
      toggleIndividualSectionBorderRadius:
        () =>
        ({ editor, chain }: any) => {
          const { useIndividualBorderRadius, borderTopLeftRadius } = editor.getAttributes(this.name);
          const newUseIndividualBorderRadius = !useIndividualBorderRadius;

          if (newUseIndividualBorderRadius === false) {
            chain().updateAttributes(this.name, {
              borderTopLeftRadius,
              borderTopRightRadius: borderTopLeftRadius,
              borderBottomRightRadius: borderTopLeftRadius,
              borderBottomLeftRadius: borderTopLeftRadius,
            });
          }

          return chain().updateAttributes(this.name, { useIndividualBorderRadius: newUseIndividualBorderRadius }).run;
        },
      setSectionBorderRadius:
        (value: number) =>
        ({ chain }: any) =>
          chain()
            .setSectionTopLeftBorderRadius(value)
            .setSectionTopRightBorderRadius(value)
            .setSectionBottomRightBorderRadius(value)
            .setSectionBottomLeftBorderRadius(value)
            .run(),
      setSectionTopLeftBorderRadius:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderTopLeftRadius: value }),
      setSectionTopRightBorderRadius:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderTopRightRadius: value }),
      setSectionBottomRightBorderRadius:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderBottomRightRadius: value }),
      setSectionBottomLeftBorderRadius:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderBottomLeftRadius: value }),

      // Border color
      setSectionBorderColor:
        (color: string | null) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderColor: color }),

      // Border style
      setSectionBorderStyle:
        (style: 'solid' | 'dotted' | 'dashed') =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderStyle: style }),
    } as any;
  },

  parseHTML() {
    return [
      {
        tag: '.node-section',
        getAttrs(node) {
          if (typeof node === 'string') {
            return {};
          }

          const {
            backgroundColor = null,
            color = null,
            showOnWebsite = 'true',
            showInEmail = 'true',
            showToNonSubscribers = 'true',
            showToFreeSubscribers = 'true',
            showToPaidSubscribers = 'true',
            showWithReferralCount = 'false',
            useIndividualPadding = 'false',
            paddingTop = '0',
            paddingRight = '0',
            paddingBottom = '0',
            paddingLeft = '0',
            useIndividualMargin = 'false',
            marginTop = '0',
            marginBottom = '0',
            marginLeft = '0',
            marginRight = '0',
            useIndividualBorderWidth = 'false',
            borderWidthTop = '0',
            borderWidthRight = '0',
            borderWidthBottom = '0',
            borderWidthLeft = '0',
            useIndividualBorderRadius = 'false',
            borderTopLeftRadius = '0',
            borderTopRightRadius = '0',
            borderBottomRightRadius = '0',
            borderBottomLeftRadius = '0',
            borderColor = null,
            borderStyle = 'solid',
          } = node.dataset;

          return {
            backgroundColor,
            color,
            showOnWebsite: showOnWebsite === 'true',
            showInEmail: showInEmail === 'true',
            showToNonSubscribers: showToNonSubscribers === 'true',
            showToFreeSubscribers: showToFreeSubscribers === 'true',
            showToPaidSubscribers: showToPaidSubscribers === 'true',
            showWithReferralCount: showWithReferralCount === 'true',
            useIndividualPadding: useIndividualPadding === 'true',
            paddingTop: parseFloat(paddingTop),
            paddingRight: parseFloat(paddingRight),
            paddingBottom: parseFloat(paddingBottom),
            paddingLeft: parseFloat(paddingLeft),
            useIndividualMargin: useIndividualMargin === 'true',
            marginTop: parseFloat(marginTop),
            marginBottom: parseFloat(marginBottom),
            marginLeft: parseFloat(marginLeft),
            marginRight: parseFloat(marginRight),
            useIndividualBorderWidth: useIndividualBorderWidth === 'true',
            borderWidthTop: parseFloat(borderWidthTop),
            borderWidthRight: parseFloat(borderWidthRight),
            borderWidthBottom: parseFloat(borderWidthBottom),
            borderWidthLeft: parseFloat(borderWidthLeft),
            useIndividualBorderRadius: useIndividualBorderRadius === 'true',
            borderTopLeftRadius: parseFloat(borderTopLeftRadius),
            borderTopRightRadius: parseFloat(borderTopRightRadius),
            borderBottomRightRadius: parseFloat(borderBottomRightRadius),
            borderBottomLeftRadius: parseFloat(borderBottomLeftRadius),
            borderColor,
            borderStyle,
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    const {
      showOnWebsite,
      showInEmail,
      showToNonSubscribers,
      showToFreeSubscribers,
      showToPaidSubscribers,
      showWithReferralCount,
    } = node.attrs;

    const differingVisibility =
      !showOnWebsite ||
      !showInEmail ||
      !showToNonSubscribers ||
      !showToFreeSubscribers ||
      !showToPaidSubscribers ||
      showWithReferralCount;

    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-differing-visibility': differingVisibility,
      }),
      [
        'div',
        mergeAttributes({
          class: 'node-section-inner',
          style: `${node.attrs.backgroundColor ? `background-color: ${node.attrs.backgroundColor}; ` : ''}${
            node.attrs.color ? `color: ${node.attrs.color}; ` : ''
          }${node.attrs.paddingTop ? `padding-top: ${node.attrs.paddingTop}px; ` : ''}${
            node.attrs.paddingRight ? `padding-right: ${node.attrs.paddingRight}px; ` : ''
          }${node.attrs.paddingBottom ? `padding-bottom: ${node.attrs.paddingBottom}px; ` : ''}${
            node.attrs.paddingLeft ? `padding-left: ${node.attrs.paddingLeft}px; ` : ''
          }${node.attrs.marginTop ? `margin-top: ${node.attrs.marginTop}px; ` : ''}${
            node.attrs.marginBottom ? `margin-bottom: ${node.attrs.marginBottom}px; ` : ''
          }${node.attrs.marginLeft ? `margin-left: ${node.attrs.marginLeft}px; ` : ''}${
            node.attrs.marginRight ? `margin-right: ${node.attrs.marginRight}px; ` : ''
          }${node.attrs.borderWidthTop ? `border-top-width: ${node.attrs.borderWidthTop}px; ` : ''}${
            node.attrs.borderWidthRight ? `border-right-width: ${node.attrs.borderWidthRight}px; ` : ''
          }${node.attrs.borderWidthBottom ? `border-bottom-width: ${node.attrs.borderWidthBottom}px; ` : ''}${
            node.attrs.borderWidthLeft ? `border-left-width: ${node.attrs.borderWidthLeft}px; ` : ''
          }${node.attrs.borderTopLeftRadius ? `border-top-left-radius: ${node.attrs.borderTopLeftRadius}px; ` : ''}${
            node.attrs.borderTopRightRadius ? `border-top-right-radius: ${node.attrs.borderTopRightRadius}px; ` : ''
          }${
            node.attrs.borderBottomRightRadius
              ? `border-bottom-right-radius: ${node.attrs.borderBottomRightRadius}px; `
              : ''
          }${
            node.attrs.borderBottomLeftRadius
              ? `border-bottom-left-radius: ${node.attrs.borderBottomLeftRadius}px; `
              : ''
          }${node.attrs.borderColor ? `border-color: ${node.attrs.borderColor}; ` : ''}${
            node.attrs.borderStyle ? `border-style: ${node.attrs.borderStyle};` : ''
          }`,
        }),
        0,
      ],
    ];
  },
});

export default Section;
