import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '../../services/swarm';

const useDeleteAssignment = (publicationId: string, assignmentId: number | null, userId: string | null) => {
  const queryClient = useQueryClient();

  const params = {
    publication_id: publicationId,
  };

  return useMutation(() => api.delete(`/assignments/${assignmentId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['user', userId]);
      toast.success('Role Updated!');
    },
  });
};

export default useDeleteAssignment;
