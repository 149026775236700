import GrowthPerPeriodChart from './GrowthPerPeriodChart';
import SpendPerPeriodChart from './SpendPerPeriodChart';

const Insights = () => {
  return (
    <>
      <GrowthPerPeriodChart />
      <SpendPerPeriodChart />
    </>
  );
};

export default Insights;
