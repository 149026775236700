import { useSearchParams } from "react-router-dom";

import useIsWorkspaceSettingPage from "../useIsWorkspaceSettingPage";

export const SETTINGS_ORGANIZATION_ID_PARAM_NAME = 's_organization_id';

const useCurrentOrganizationId = () => {
  const [searchParams] = useSearchParams();
  const isWorkspaceSettingPage = useIsWorkspaceSettingPage();

  if (!isWorkspaceSettingPage) {
    throw new Error(`Accessing hook outside of workspace settings!`);
  }

  const organizationIdInUrl = searchParams.get(SETTINGS_ORGANIZATION_ID_PARAM_NAME);
  
  if (!organizationIdInUrl) {
    throw new Error(`${SETTINGS_ORGANIZATION_ID_PARAM_NAME} get param missing!`);
  }
  
  return organizationIdInUrl as string;
};

export default useCurrentOrganizationId;