import { useParams } from 'react-router-dom';

import useCampaignOpportunityGroup from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroup';
import useCampaignOpportunityGroupOpportunities from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroupOpportunities';
import useCampaignOpportunityGroupOpportunitiesDestroy from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroupOpportunitiesDestroy';
import useCampaignOpportunityGroupProvision from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroupProvision';
import {
  AdNetworkCampaignOpportunityGroup,
  AdNetworkCampaignOpportunityGroupDraftOpportunity,
} from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import { Button } from '@/ui/Button';
import { MetricCard } from '@/ui/MetricCard';

import { List } from '../../_components/List';
import { LoadingListPage } from '../../_components/LoadingListPage';
import { PageHeading } from '../../_components/PageHeading';
import { Tabs } from '../../_components/Tabs';

interface Props {
  campaignOpportunityGroup: AdNetworkCampaignOpportunityGroup;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<any>;
  opportunities: AdNetworkCampaignOpportunityGroupDraftOpportunity[];
  refetch: () => Promise<any>;
}

const formatter = new Intl.NumberFormat('en-US');
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const CampaignOpportunityGroupReview = ({
  campaignOpportunityGroup,
  opportunities,
  hasNextPage,
  fetchNextPage,
  refetch,
}: Props) => {
  const estimatedClicksByStatus = campaignOpportunityGroup.estimated_clicks_by_status;
  const estimatedOpensByStatus = campaignOpportunityGroup.estimated_opens_by_status;
  const opportunityCountsByStatus = campaignOpportunityGroup.opportunity_counts_by_status;
  const estimatedPayoutCentsByStatus = campaignOpportunityGroup.estimated_payout_cents_by_status;

  const draftEstimatedClicks = estimatedClicksByStatus.draft;
  const draftEstimatedOpens = estimatedOpensByStatus.draft;
  const draftOpportunities = opportunityCountsByStatus.draft;
  const draftEstimatedPayout = estimatedPayoutCentsByStatus.draft / 100;

  const destroy = useCampaignOpportunityGroupOpportunitiesDestroy({ id: campaignOpportunityGroup.id });

  const destroyAll = () => {
    destroy.mutateAsync({ removeAll: true }).then(() => refetch());
  };

  const provisionMutation = useCampaignOpportunityGroupProvision({ id: campaignOpportunityGroup.id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{campaignOpportunityGroup.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to=".">Review</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="border-b border-gray-100 p-4 flex space-x-2 items-center justify-between">
        <div>
          <Tabs>
            <Tabs.Tab to="../provision">Find publications</Tabs.Tab>
            <Tabs.Tab to="../select">Select advertisements</Tabs.Tab>
            <Tabs.Tab to="">Review &amp; provision</Tabs.Tab>
          </Tabs>
        </div>
        <div className="space-x-2">
          <Button type="submit" variant="primary-inverse" onClick={destroyAll}>
            Unstage all
          </Button>
          <Button onClick={() => provisionMutation.mutateAsync()} loading={provisionMutation.isLoading}>
            Send to publishers <span className="ml-2">&rarr;</span>
          </Button>
        </div>
      </div>
      <div className="divide-y divide-gray-100">
        <div className="grid lg:grid-cols-5 grid-cols-1 gap-4 p-4">
          <MetricCard variant="primary" label="Draft opportunities" value={formatter.format(draftOpportunities)} />
          <MetricCard
            variant="primary"
            label="Draft estimated payout"
            value={currencyFormatter.format(draftEstimatedPayout)}
          />
          <MetricCard variant="primary" label="Draft estimated clicks" value={formatter.format(draftEstimatedClicks)} />
          <MetricCard variant="primary" label="Draft estimated opens" value={formatter.format(draftEstimatedOpens)} />
          <MetricCard
            variant="primary"
            label="Ad Units"
            value={campaignOpportunityGroup.ad_group_units.total}
            initialVisibleComplements={1}
            complements={[
              {
                label: 'Group',
                value: campaignOpportunityGroup.ad_group_name,
              },
              {
                label: 'Primary',
                value: campaignOpportunityGroup.ad_group_units.primary,
              },
              {
                label: 'Secondary',
                value: campaignOpportunityGroup.ad_group_units.secondary,
              },
            ]}
          />
        </div>
      </div>
      <List hasNextPage={hasNextPage} fetchNextPage={fetchNextPage}>
        {opportunities.map((opportunity) => {
          return (
            <List.Item key={opportunity.id}>
              <div className="flex justify-between">
                <div>
                  <List.Item.Text variant="primary">
                    <span>{opportunity.publication_name}</span>
                  </List.Item.Text>
                  <List.Item.Text variant="secondary">
                    <span>{opportunity.estimated_clicks} clicks</span>
                    <span>&middot;</span>
                    <span>{opportunity.estimated_payout} payout</span>
                  </List.Item.Text>
                </div>
                <div>
                  <Button
                    variant="primary-inverse"
                    size="xs"
                    onClick={() => destroy.mutateAsync({ opportunityId: opportunity.id }).then(() => refetch())}
                  >
                    Unstage
                  </Button>
                </div>
              </div>
            </List.Item>
          );
        })}
      </List>
    </>
  );
};

export default function Loader() {
  const { campaign_opportunity_group_id: id } = useParams<'campaign_opportunity_group_id'>() as {
    campaign_opportunity_group_id: string;
  };

  const {
    data: groupData,
    isSuccess: groupIsSuccess,
    isLoading: groupIsLoading,
    isError: groupIsError,
    refetch: groupRefetch,
  } = useCampaignOpportunityGroup({ id });

  const {
    data: opportunitiesData,
    hasNextPage,
    fetchNextPage,
    isSuccess: opportunitiesIsSuccess,
    isLoading: opportunitiesIsLoading,
    isError: opportunitiesIsError,
    refetch: opportunitiesRefetch,
  } = useCampaignOpportunityGroupOpportunities({ id });

  const isLoading = groupIsLoading || opportunitiesIsLoading;
  const isError = groupIsError || opportunitiesIsError;

  if (!groupIsSuccess || !opportunitiesIsSuccess || isLoading)
    return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const opportunities = opportunitiesData.pages?.flatMap((page) => page.opportunities) || [];

  const refetch = () => Promise.all([groupRefetch(), opportunitiesRefetch()]);

  return (
    <CampaignOpportunityGroupReview
      campaignOpportunityGroup={groupData}
      opportunities={opportunities}
      hasNextPage={!!hasNextPage}
      fetchNextPage={fetchNextPage}
      refetch={refetch}
    />
  );
}
