import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';

import { Site } from '../../interfaces/dream_builder/site';
import api from '../../services/swarm';

export default function useSite() {
  const navigate = useNavigate();
  const [currentPublicationId] = useCurrentPublicationState();
  const id = 'null'; // there's only 1 site per publication, this parameter is unused

  const fetchSite = () =>
    api
      .get(`/sites/${id}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<Site>({
    queryKey: ['publications', currentPublicationId, 'sites', id],
    queryFn: fetchSite,
    retry: false,
    onError: () => {
      navigate('/website_builder_v2/onboarding');
    },
  });
}
