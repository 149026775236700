import { useSearchParams } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentUser } from '@/context/current-user-context';

import useIsSettingPage from '../useIsSettingPage';

export const SETTINGS_PUBLICATION_ID_PARAM_NAME = 's_publication_id';

const useCurrentPublicationId = () => {
  const [publicationIdInState] = useCurrentPublicationState(false);
  const [searchParams] = useSearchParams();
  const isASettingPage = useIsSettingPage();
  const { currentUser } = useCurrentUser();

  if (!currentUser?.has_settings_v2 || !isASettingPage) {
    return publicationIdInState;
  }

  const publicationIdInUrl = searchParams.get(SETTINGS_PUBLICATION_ID_PARAM_NAME);
  if (!publicationIdInUrl) {
    throw new Error(`${SETTINGS_PUBLICATION_ID_PARAM_NAME} get param missing!`);
  }

  return publicationIdInUrl;
};

export default useCurrentPublicationId;
