import { CustomFieldOption } from '@/interfaces/general';

export const filterCustomFields =
  (
    currentCustomFieldIds: string[],
    isMultipleChoiceSelected: boolean,
    isDropdownSelected: boolean,
    isLongTextSelected: boolean,
    selectedFieldId?: string
  ) =>
  (fieldOptions: CustomFieldOption[]): CustomFieldOption[] => {
    const filteredOptions = fieldOptions.filter((option) => {
      return !currentCustomFieldIds.includes(option.value) || option.value === selectedFieldId;
    });

    if (isMultipleChoiceSelected) {
      return filteredOptions.filter((option) => option.kind === 'string' || option.kind === 'list');
    }

    if (isDropdownSelected || isLongTextSelected) {
      return filteredOptions.filter((option) => option.kind === 'string');
    }

    return filteredOptions;
  };
