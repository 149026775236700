import { useMemo } from 'react';

import { Tier } from '@/interfaces/tier';

function useRecommendedPriceOptions(tiers: Tier[]) {
  const recommendedPriceOptions = useMemo(() => {
    let options = [
      {
        label: 'None',
        value: '',
      },
    ];

    if (tiers) {
      options = options.concat(
        tiers.flatMap((tier) =>
          tier.prices.map((price) => ({
            label: `${tier.name}/${price.denominator}`,
            value: price.id,
          }))
        )
      );
    }
    return options;
  }, [tiers]);

  return recommendedPriceOptions;
}

export default useRecommendedPriceOptions;
