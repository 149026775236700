import cx from 'classnames';

import { getColor } from '../DesignTokens';

interface Props {
  className?: string;
  direction?: 'horizontal' | 'vertical';
  heightClassName?: string;
}

const LineDivider = ({ className, direction = 'horizontal', heightClassName = 'h-1/2' }: Props) => {
  if (direction === 'vertical') {
    return <div className={cx('w-px', className, heightClassName, getColor('surface/200', 'background'))}>&nbsp;</div>;
  }
  return <div className={cx('w-full border-t', className, getColor('surface/200', 'border'))} />;
};

export default LineDivider;
