import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface MutationParams {
  postId: string;
  onSuccess?: () => void;
}

const useResetPostGiftSlug = (mutationParams: MutationParams) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const { postId, onSuccess } = mutationParams;

  return useMutation(
    () =>
      api
        .patch(`/posts/${postId}/reset_gift_slug`, {
          publication_id: currentPublicationId,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['posts', postId], { exact: true });
        queryClient.invalidateQueries(['posts', postId, 'preview'], { exact: true });
        onSuccess?.();
      },
      onError: () => {
        toast.error('Something went wrong. Please try again.');
      },
    }
  );
};

export default useResetPostGiftSlug;
