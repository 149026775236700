import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';
import styled from 'styled-components';

import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { InputField } from '@/components/TiptapEditor/components/ui/Input';
import { PanelHeadline, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';

const Styled = {
  Grid: styled.div`
    display: flex;
    gap: 0.25rem;
    padding-inline: 0.375rem;
  `,
  IndividualBorderWidthSwitch: styled.div`
    flex: 0 0 auto;
  `,
  InputGroup: styled.div`
    display: grid;
    flex: 1 1 auto;
    gap: 0.25rem;
    grid-template-columns: repeat(2, 1fr);
  `,
  InputWrapper: styled.div<{ half?: boolean }>`
    flex: 1 1 auto;
  `,
};

export const BorderWidth = ({ editor }: { editor: Editor }) => {
  const [borderWidth, setBorderWidth] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  const attrs = editor.getAttributes('imageBlock');
  const { useIndividualBorderWidth = false } = attrs;

  useEffect(() => {
    setBorderWidth({
      top: attrs.borderWidthTop || 0,
      bottom: attrs.borderWidthBottom || 0,
      right: attrs.borderWidthRight || 0,
      left: attrs.borderWidthLeft || 0,
    });
  }, [attrs.borderWidthTop, attrs.borderWidthBottom, attrs.borderWidthRight, attrs.borderWidthLeft]);

  const onSetBorderWidth = useCallback(
    (newBorderWidth: { top: number; bottom: number; right: number; left: number }) => {
      if (!useIndividualBorderWidth) {
        editor.commands.setImageBlockBorderWidth(newBorderWidth.top);
      } else {
        editor
          .chain()
          .setImageBlockTopBorderWidth(newBorderWidth.top)
          .setImageBlockBottomBorderWidth(newBorderWidth.bottom)
          .setImageBlockRightBorderWidth(newBorderWidth.right)
          .setImageBlockLeftBorderWidth(newBorderWidth.left)
          .run();
      }
    },
    [editor, useIndividualBorderWidth]
  );

  const handleBorderWidthChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setBorderWidth({
      top: Number(e.target.value) || 0,
      bottom: Number(e.target.value) || 0,
      right: Number(e.target.value) || 0,
      left: Number(e.target.value) || 0,
    });
  }, []);

  const toggleIndividualBorderWidth = useCallback(() => {
    editor.commands.toggleIndividualImageBlockBorderWidth();
  }, [editor]);

  const updateBorderWidth = useCallback(() => {
    onSetBorderWidth(borderWidth);
  }, [borderWidth, onSetBorderWidth]);

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, newBorderWidth: any) => {
      event.stopPropagation();

      if (event.key === 'Enter') {
        onSetBorderWidth({ ...borderWidth, ...newBorderWidth });
      }

      return false;
    },
    [borderWidth, onSetBorderWidth]
  );

  return (
    <PanelSection>
      <PanelHeadline>Thickness</PanelHeadline>
      <Styled.Grid>
        <Styled.IndividualBorderWidthSwitch>
          <Button
            $isIconButton
            $active={attrs.useIndividualBorderWidth}
            $muted
            type="button"
            onClick={toggleIndividualBorderWidth}
            $size="small"
            $variant="quaternary"
            $leftSlot={<Icon name="BorderWidth" />}
          />
        </Styled.IndividualBorderWidthSwitch>
        {attrs.useIndividualBorderWidth ? (
          <Styled.InputGroup>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setBorderWidth({ ...borderWidth, left: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { left: Number(e.currentTarget.value) })}
                onBlur={updateBorderWidth}
                type="number"
                icon="BorderWidthLeft"
                suffix="px"
                value={borderWidth.left.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setBorderWidth({ ...borderWidth, right: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { right: Number(e.currentTarget.value) })}
                onBlur={updateBorderWidth}
                type="number"
                icon="BorderWidthRight"
                suffix="px"
                value={borderWidth.right.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setBorderWidth({ ...borderWidth, top: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { top: Number(e.currentTarget.value) })}
                onBlur={updateBorderWidth}
                type="number"
                icon="BorderWidthTop"
                suffix="px"
                value={borderWidth.top.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setBorderWidth({ ...borderWidth, bottom: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { bottom: Number(e.currentTarget.value) })}
                onBlur={updateBorderWidth}
                type="number"
                icon="BorderWidthBottom"
                suffix="px"
                value={borderWidth.bottom.toString()}
              />
            </Styled.InputWrapper>
          </Styled.InputGroup>
        ) : (
          <Styled.InputWrapper>
            <InputField
              min="0"
              onChange={handleBorderWidthChange}
              onKeyUp={(e) =>
                handleKeyUp(e, {
                  top: Number(e.currentTarget.value) || 0,
                  bottom: Number(e.currentTarget.value) || 0,
                  right: Number(e.currentTarget.value) || 0,
                  left: Number(e.currentTarget.value) || 0,
                })
              }
              onBlur={updateBorderWidth}
              type="number"
              suffix="px"
              value={borderWidth.top.toString()}
            />
          </Styled.InputWrapper>
        )}
      </Styled.Grid>
    </PanelSection>
  );
};

export default BorderWidth;
