import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link as LinkIcon } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { isInternalLink } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/isInternalLink';
import useResolvedRoutes from '@/routes/website/_hooks/useResolvedRoutes';

import {
  PopoverHelper,
  PopoverItem,
} from '../../../../../../_components/DreamEditor/AttributesPanel/helpers/PopoverHelper';
import { Button } from '../../../../../../_components/UI/Button';
import { SimpleInput, SimpleInputWrapper } from '../../../../../../_components/UI/Input';
import { ToggleGroup, ToggleGroupItem } from '../../../../../../_components/UI/ToggleGroup';
import { useNavbarContext } from '../../../NavbarContext';

export interface ComboboxOption {
  id: number | string;
  name: string;
  LeftIcon?: React.ReactNode;
}

const validateUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);

    if (process.env.NODE_ENV !== 'development') {
      return parsedUrl.protocol === 'https:';
    }

    return true;
  } catch (error) {
    toast.error('Invalid URL');
    return false;
  }
};

export const LinkToSettings = () => {
  const routes = useResolvedRoutes();
  const { data: currentPublication } = useCurrentPublication();

  const [currentURL, setCurrentURL] = useState('');
  const [userFriendlyName, setUserFriendlyName] = useState('');
  const [selectedLinkType, setSelectedLinkType] = useState<'routes' | 'custom'>('routes');

  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  const contentValue: string =
    selectedContent?.attrs && 'href' in selectedContent.attrs ? (selectedContent.attrs.href as string) : '';

  useEffect(() => {
    if (!contentValue) {
      setCurrentURL('');
      setUserFriendlyName('');
      setSelectedLinkType('routes');
      return;
    }

    if (isInternalLink(currentPublication?.url || '', contentValue)) {
      setSelectedLinkType('routes');

      try {
        const url = new URL(contentValue);
        const slug = url.pathname;
        const route = routes.find((r: any) => `/${r.slug}` === slug);
        setUserFriendlyName(route?.name || '');
      } catch (error) {
        setUserFriendlyName('');
      }
    } else {
      setSelectedLinkType('custom');
      setUserFriendlyName('');
      setCurrentURL(contentValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPublication?.url, contentValue]);

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  const handleSetLink = (url: string) => {
    if (!selectedContent.attrs?.id) return;
    if (!validateUrl(url)) return;

    onUpdateNodeAttributes(selectedContent.attrs?.id, { href: url });
  };

  return (
    <PopoverHelper title="Link" text={userFriendlyName || contentValue} align="end" popoverTitle="Link" Icon={LinkIcon}>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="routes"
          value={selectedLinkType}
          onValueChange={(value: 'routes' | 'custom') => setSelectedLinkType(value)}
        >
          <ToggleGroupItem value="routes" className="grow">
            Pages
          </ToggleGroupItem>
          <ToggleGroupItem value="custom" className="grow">
            Custom
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
      <div className="flex flex-col gap-2 max-h-[200px] mt-2 pb-4">
        {selectedLinkType === 'custom' && (
          <div className="flex flex-col gap-2">
            <SimpleInputWrapper>
              <SimpleInput
                type="text"
                value={currentURL}
                onChange={(e) => setCurrentURL(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSetLink(currentURL);
                  }
                }}
              />
            </SimpleInputWrapper>
            <Button variant="outlined" size="sm" onClick={() => handleSetLink(currentURL)}>
              Apply
            </Button>
          </div>
        )}
        {selectedLinkType === 'routes' && (
          <>
            {routes.map((route: any) => {
              return (
                <PopoverItem
                  key={route.id}
                  isSelected={false}
                  value={route.name}
                  onSelect={() => {
                    const settableUrl = `${currentPublication?.url}${route.slug}`;
                    setUserFriendlyName(route.name);
                    handleSetLink(settableUrl);
                  }}
                />
              );
            })}
          </>
        )}
      </div>
    </PopoverHelper>
  );
};
