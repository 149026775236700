import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '../../components/ui/Button';
import { Icon } from '../../components/ui/Icon';
import { colors } from '../../lib/colors';

import { ColumnCount, GlobalStyles, Styled } from './styled';
import { Command, MenuListProps } from './types';

export const MenuList = React.forwardRef((props: MenuListProps, ref) => {
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(0);

  // Anytime the groups change, i.e. the user types to narrow it down, we want to
  // reset the current selection to the first menu item
  useEffect(() => {
    setSelectedGroupIndex(0);
    setSelectedCommandIndex(0);
  }, [props.items]);

  // Returns a ratio that ensures that we are covering the full gradient regardless of how
  // many options we have in the menu
  const gradientSteps = useMemo((): number => {
    return Object.keys(colors.gradient).length / props.items.reduce((acc, curr) => acc + curr.commands.length, 0);
  }, [props.items]);

  const getColor = useCallback(
    (groupIndex: number, commandIndex: number): string => {
      const prevCommandsCount = props.items.slice(0, groupIndex).reduce((sum, curr) => sum + curr.commands.length, 0);

      const step = Math.round((prevCommandsCount + commandIndex + 1) * gradientSteps);

      if (step < Object.keys(colors.gradient).length) {
        return colors.gradient[step];
      }

      return colors.gradient[Object.keys(colors.gradient).length];
    },
    [gradientSteps, props.items]
  );

  const selectItem = useCallback(
    (groupIndex: number, commandIndex: number) => {
      const command = props.items[groupIndex].commands[commandIndex];
      props.command(command);
    },
    [props]
  );

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (event.key === 'ArrowLeft') {
        if (!props.items.length) {
          return false;
        }

        if (selectedCommandIndex % ColumnCount === 0) {
          setSelectedCommandIndex(
            selectedCommandIndex + Math.min(ColumnCount, props.items[selectedGroupIndex].commands.length) - 1
          );
          return true;
        }

        setSelectedCommandIndex(selectedCommandIndex - 1);
        return true;
      }

      if (event.key === 'ArrowRight') {
        const newCommandIndex = selectedCommandIndex + 1;

        if (!props.items.length) {
          return false;
        }

        if (newCommandIndex % ColumnCount === 0 || newCommandIndex >= props.items[selectedGroupIndex].commands.length) {
          setSelectedCommandIndex(Math.max(0, newCommandIndex - ColumnCount));
          return true;
        }

        setSelectedCommandIndex(selectedCommandIndex + 1);
        return true;
      }

      if (event.key === 'ArrowDown') {
        if (!props.items.length) {
          return false;
        }

        if (selectedCommandIndex + ColumnCount < props.items[selectedGroupIndex].commands.length) {
          setSelectedCommandIndex(selectedCommandIndex + ColumnCount);
          return true;
        }

        let newGroupIndex = selectedGroupIndex + 1;
        let newCommandIndex = selectedCommandIndex % ColumnCount;

        if (newGroupIndex >= props.items.length) {
          newGroupIndex = 0;
        }

        if (newCommandIndex >= props.items[newGroupIndex].commands.length) {
          newCommandIndex = props.items[newGroupIndex].commands.length - 1;
        }

        setSelectedGroupIndex(newGroupIndex);
        setSelectedCommandIndex(newCommandIndex);

        return true;
      }

      if (event.key === 'ArrowUp') {
        if (!props.items.length) {
          return false;
        }

        if (selectedCommandIndex - ColumnCount >= 0) {
          setSelectedCommandIndex(selectedCommandIndex - ColumnCount);
          return true;
        }

        let newGroupIndex = selectedGroupIndex - 1;

        if (newGroupIndex < 0) {
          newGroupIndex = props.items.length - 1;
        }

        const newGroupLength = props.items[newGroupIndex].commands.length;

        if (newGroupLength <= selectedCommandIndex) {
          setSelectedCommandIndex(newGroupLength - 1);
          setSelectedGroupIndex(newGroupIndex);
          return true;
        }

        const desiredModulo = selectedCommandIndex % ColumnCount;
        let newCommandIndex = newGroupLength - 1;

        while (newCommandIndex % ColumnCount !== desiredModulo) {
          newCommandIndex -= 1;
        }

        setSelectedGroupIndex(newGroupIndex);
        setSelectedCommandIndex(newCommandIndex);
        return true;
      }

      if (event.key === 'Enter') {
        if (!props.items.length || selectedGroupIndex === -1 || selectedCommandIndex === -1) {
          return false;
        }

        selectItem(selectedGroupIndex, selectedCommandIndex);

        return true;
      }

      return false;
    },
  }));

  if (!props.items.length) {
    return null;
  }

  return (
    <Styled.Container>
      <GlobalStyles />
      {props.items.map((group, groupIndex: number) => (
        <React.Fragment key={`${group.title}-wrapper`}>
          <Styled.GroupTitle key={`${group.title}`}>{group.title}</Styled.GroupTitle>
          {group.commands.map((command: Command, commandIndex: number) => (
            <Button
              key={`${command.label}`}
              $active={groupIndex === selectedGroupIndex && commandIndex === selectedCommandIndex}
              onClick={() => {
                selectItem(groupIndex, commandIndex);
              }}
              $leftSlot={
                <Icon
                  name={command.iconName}
                  style={{
                    color: getColor(groupIndex, commandIndex),
                  }}
                />
              }
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(!command.isEnabled
                ? {
                    $rightSlot: (
                      <Icon
                        name="Lock"
                        style={{
                          color: colors.white[3],
                        }}
                      />
                    ),
                  }
                : {})}
              $size="small"
              $variant="quaternary"
              $fullWidth
            >
              {/* eslint-disable react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: command.highlightedLabel || command.label }} />
            </Button>
          ))}
        </React.Fragment>
      ))}
    </Styled.Container>
  );
});

export default MenuList;
