import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Font } from '../../interfaces/font';
import api from '../../services/swarm';

interface Props {
  webSafe?: boolean;
}

const useAllFonts = ({ webSafe }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<Font[]>(['fonts', webSafe], () =>
    api
      .get(`/fonts`, {
        params: {
          publication_id: currentPublicationId,
          web_safe: webSafe,
        },
      })
      .then((res) => res.data)
  );
};

export default useAllFonts;
