import { PropsWithChildren, useState } from 'react';
import { useReactFlow } from 'reactflow';
import cx from 'classnames';

type Props = PropsWithChildren<{
  isActive: boolean;
}>;

enum PanelPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

const SCROLL_SPEED = 20; // pixels per frame

const IntersectionPanels = ({ isActive, children }: Props) => {
  const [, setIntersectionPosition] = useState<PanelPosition | null>(null);
  const { getViewport, setViewport } = useReactFlow();

  const handleDragOver = (panel: PanelPosition) => () => {
    setIntersectionPosition(panel);
    const currentViewPort = getViewport();
    let newX = currentViewPort.x;
    let newY = currentViewPort.y;

    if (panel === PanelPosition.TOP) {
      newY += SCROLL_SPEED;
    } else if (panel === PanelPosition.BOTTOM) {
      newY -= SCROLL_SPEED;
    } else if (panel === PanelPosition.RIGHT) {
      newX -= SCROLL_SPEED;
    } else if (panel === PanelPosition.LEFT) {
      newX += SCROLL_SPEED;
    }

    window.requestAnimationFrame(() => {
      setViewport({
        x: newX,
        y: newY,
        zoom: currentViewPort.zoom,
      });
    });
  };

  const handleDragLeave = () => () => {
    setIntersectionPosition(null);
  };

  return (
    <>
      {isActive && (
        <div className="absolute w-screen h-screen pt-40 md:pt-28">
          <div
            onDragOver={handleDragOver(PanelPosition.TOP)}
            onDragExit={handleDragLeave}
            className={cx('absolute w-screen h-10 z-20 text-center')}
          >
            &nbsp;
          </div>
          <div
            onDragOver={handleDragOver(PanelPosition.BOTTOM)}
            onDragExit={handleDragLeave}
            className={cx('absolute bottom-0 w-screen h-10 z-20 text-center')}
          >
            &nbsp;
          </div>
          <div
            onDragOver={handleDragOver(PanelPosition.LEFT)}
            onDragExit={handleDragLeave}
            className={cx('absolute left-0 h-screen w-10 z-20 text-center')}
          >
            &nbsp;
          </div>
          <div
            onDragOver={handleDragOver(PanelPosition.RIGHT)}
            onDragExit={handleDragLeave}
            className={cx('absolute right-0 h-screen w-10 z-20 text-center')}
          >
            &nbsp;
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default IntersectionPanels;
