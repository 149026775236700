import { SortableListItem } from '@/components/SortableList';
import { NavLink } from '@/interfaces/nav_link';

import LinkActions from '../Helpers/LinkActions';
import PageHelperTitle from '../Helpers/PageTitleHelper';

interface Props {
  navLink: NavLink;
}

const SortableNavLink = ({ navLink }: Props) => {
  const isExternalLink = navLink?.external_link;

  return (
    <SortableListItem
      className="!py-0.5 bg-surface-50"
      listItem={navLink}
      text={<PageHelperTitle isExternalLink={isExternalLink} text={navLink.cta_text} />}
      onRemoveItem={() => {}}
      actionChild={<LinkActions navLink={navLink} />}
    />
  );
};

export default SortableNavLink;
