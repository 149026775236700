import { FC, useEffect, useState } from 'react';

import { Order, Sort as SortProps } from '@/interfaces/general';

import Sort from '../../pages/Posts/Sort';

interface TableHeaderProps {
  headers: Map<string, string>;
  handleSortChange: (newSort: SortProps) => void;
  sort: SortProps;
  sortableHeaders?: string[];
  headerSidePadding?: number;
}

const TableHeaders: FC<TableHeaderProps> = ({
  headers,
  sort,
  sortableHeaders = [],
  handleSortChange,
  headerSidePadding = 6,
}: TableHeaderProps) => {
  const [currentSort, setCurrentSort] = useState(sort);

  useEffect(() => {
    if (currentSort !== sort) {
      handleSortChange(currentSort);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSort]);

  const handleSortClick = (order: string, isCurrentHeader: boolean) => (): void => {
    const newSortDir = currentSort.dir === Order.ASC ? Order.DESC : Order.ASC;
    const newSort = isCurrentHeader ? { ...currentSort, dir: newSortDir } : { ...currentSort, order };

    setCurrentSort(newSort);
  };

  return (
    <thead className="bg-gray-50">
      <tr>
        {Array.from(headers.entries()).map(([key, value]) => {
          const isCurrentHeader = key === currentSort.order;
          const variant = isCurrentHeader ? 'primary-inverse' : 'flush';

          return (
            <th
              key={value}
              scope="col"
              className={`px-${headerSidePadding} py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap`}
            >
              {value}

              {sortableHeaders.includes(key) && (
                <>
                  {' '}
                  <Sort
                    orderDir={currentSort.dir}
                    handleClick={handleSortClick(key, isCurrentHeader)}
                    variant={variant}
                    className={`opacity-${isCurrentHeader ? '100' : '25'}`}
                  />
                </>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeaders;
