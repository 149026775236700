import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import TestimonialsForm from '@/routes/website_builder/_components/Form/PageSpecific/Testimonials/TestimonialsForm';
import { Dropdown } from '@/ui/Dropdown';
import Switch from '@/ui/Switch';

import { Input } from '../../../../components/Form';
import ColorField from '../../../../components/Form/ColorField';
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { useUpdateUpgradePage } from '../../../../hooks/useUpgradePages';
import { FONT_SIZE_OPTIONS } from '../../../../interfaces/font';
import { UpgradePage } from '../../../../interfaces/upgrade_page';
import ThemeEditorDropdown from '../../../ThemeEditor/components/ThemeEditorDropdown';
import ThemeFormDrawer from '../../../ThemeEditor/components/ThemeFormDrawer';
import { Divider, FormContainer, FormInput, FormSection } from '../../../ThemeEditor/helpers';
import { usePageContext } from '../../components/PageContext';

const TestimonialsSection = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const {
    pageData: upgradePage,
    colorPalette,
    setIsSaving,
    handleSaved: onSave,
    fontOptions,
  } = usePageContext<UpgradePage>();

  // Testimonials General States
  const [sectionEnabled, setSectionEnabled] = useState(upgradePage?.testimonials_section?.enabled || false);
  const [bgColor, setBgColor] = useState(upgradePage?.testimonials_section?.bg_color || '#000000');
  const [overlineColor, setOverlineColor] = useState(upgradePage?.testimonials_section?.overline_color || '#000000');
  const [overline, setOverline] = useState(upgradePage?.testimonials_section?.overline || '');
  const [overlineFontFamily, setOverlineFontFamily] = useState(
    upgradePage?.testimonials_section?.overline_font_family || ''
  );
  const [overlineFontSize, setOverlineFontSize] = useState(
    upgradePage?.testimonials_section?.overline_font_size || 'sm'
  );
  const [headlineColor, setHeadlineColor] = useState(upgradePage?.testimonials_section?.headline_color || '#000000');
  const [headline, setHeadline] = useState(upgradePage?.testimonials_section?.headline || '');
  const [headlineFontFamily, setHeadlineFontFamily] = useState(
    upgradePage?.testimonials_section?.headline_font_family || ''
  );
  const [headlineFontSize, setHeadlineFontSize] = useState(
    upgradePage?.testimonials_section?.headline_font_size || '4xl'
  );
  const [textAlign, setTextAlign] = useState(upgradePage?.testimonials_section?.text_align || 'left');
  const [cardAccentColor, setCardAccentColor] = useState(upgradePage?.testimonials_section?.card_accent_color || '');
  const [cardTextColor, setCardTextColor] = useState(upgradePage?.testimonials_section?.card_text_color || '');
  const [cardFontFamily, setCardFontFamily] = useState(upgradePage?.testimonials_section?.card_font_family || '');
  const [cardBgColor, setCardBgColor] = useState(upgradePage?.testimonials_section?.card_bg_color || '');
  const [cardShadowSize, setCardShadowSize] = useState(upgradePage?.testimonials_section?.card_shadow_size || '');

  useEffect(() => {
    setSectionEnabled(upgradePage?.testimonials_section?.enabled || false);
    setBgColor(upgradePage?.testimonials_section?.bg_color || '#000000');
    setOverlineColor(upgradePage?.testimonials_section?.overline_color || '#000000');
    setOverline(upgradePage?.testimonials_section?.overline || '');
    setOverlineFontFamily(upgradePage?.testimonials_section?.overline_font_family || '');
    setOverlineFontSize(upgradePage?.testimonials_section?.overline_font_size || 'sm');
    setHeadlineColor(upgradePage?.testimonials_section?.headline_color || '#000000');
    setHeadline(upgradePage?.testimonials_section?.headline || '');
    setHeadlineFontFamily(upgradePage?.testimonials_section?.headline_font_family || '');
    setHeadlineFontSize(upgradePage?.testimonials_section?.headline_font_size || '4xl');
    setTextAlign(upgradePage?.testimonials_section?.text_align || 'left');
    setCardAccentColor(upgradePage?.testimonials_section?.card_accent_color || '');
    setCardTextColor(upgradePage?.testimonials_section?.card_text_color || '');
    setCardFontFamily(upgradePage?.testimonials_section?.card_font_family || '');
    setCardBgColor(upgradePage?.testimonials_section?.card_bg_color || '');
    setCardShadowSize(upgradePage?.testimonials_section?.card_shadow_size || '');
  }, [upgradePage]);

  const updateUpgradePage = useUpdateUpgradePage({
    pageId: upgradePage?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['upgrade_page', currentPublicationId, 'draft']);
      onSave();
    },
  });

  const handleSaveTestimonialsSection = (payload: any) => {
    setIsSaving(true);
    updateUpgradePage.mutate({ upgrade_page: { testimonials_section: JSON.stringify(payload) } });
  };

  return (
    <ThemeFormDrawer active={false} title="Testimonials Section" onClickHandler={() => {}}>
      <FormContainer>
        <FormSection title="General">
          <FormInput title="Show Section">
            <Switch
              variant="primary"
              name="enabled"
              checked={sectionEnabled}
              onChange={(_name: string, updatedValue: boolean) => {
                setSectionEnabled(updatedValue);
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  enabled: updatedValue,
                });
              }}
            />
          </FormInput>
          <FormInput title="Background Color">
            <ColorField
              placement="bottom"
              color={bgColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setBgColor(color?.hexString)}
              onBlur={() => {
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  bg_color: bgColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Testimonials">
            {upgradePage && (
              <TestimonialsForm
                page={upgradePage}
                handleSave={handleSaveTestimonialsSection}
                type="upgrade_page"
                onSave={onSave}
              />
            )}
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Alignment">
          <Dropdown
            name="text_align"
            value={textAlign}
            onSelect={(_name: string, value: string) => {
              setTextAlign(value);
              handleSaveTestimonialsSection({
                ...upgradePage?.testimonials_section,
                text_align: value,
              });
            }}
            options={[
              {
                label: 'Left',
                value: 'left',
              },
              {
                label: 'Center',
                value: 'center',
              },
              {
                label: 'Right',
                value: 'right',
              },
            ]}
          />
        </FormSection>
        <Divider />
        <FormSection title="Overline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={overlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setOverlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  overline_color: overlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text">
            <Input
              name="overline"
              value={overline}
              maxLength={150}
              onChange={(e) => setOverline(e.target.value)}
              onBlur={() => {
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  overline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={overlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  overline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={overlineFontSize}
              onUpdate={(val: string) =>
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  overline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Headline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={headlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setHeadlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  headline_color: headlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text">
            <Input
              name="headline"
              value={headline}
              maxLength={150}
              supportsMarkdown
              onChange={(e) => setHeadline(e.target.value)}
              onBlur={() => {
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  headline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={headlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  headline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={headlineFontSize}
              onUpdate={(val: string) =>
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  headline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Cards">
          <FormInput title="Font Family">
            <ThemeEditorDropdown
              currentValue={cardFontFamily}
              onUpdate={(val: string) =>
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  card_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Shadow Size">
            <Dropdown
              name="shadow_size"
              value={cardShadowSize}
              onSelect={(_name: string, value: string) => {
                setCardShadowSize(value);
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  card_shadow_size: value,
                });
              }}
              options={[
                {
                  label: 'None',
                  value: 'none',
                },
                {
                  label: 'SM',
                  value: 'sm',
                },
                {
                  label: 'MD',
                  value: 'md',
                },
                {
                  label: 'LG',
                  value: 'lg',
                },
              ]}
            />
          </FormInput>
          <FormInput title="Accent Color">
            <ColorField
              placement="bottom"
              color={cardAccentColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setCardAccentColor(color?.hexString)}
              onBlur={() => {
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  card_accent_color: cardAccentColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Background Color">
            <ColorField
              placement="bottom"
              color={cardBgColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setCardBgColor(color?.hexString)}
              onBlur={() => {
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  card_bg_color: cardBgColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text Color">
            <ColorField
              placement="bottom"
              color={cardTextColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setCardTextColor(color?.hexString)}
              onBlur={() => {
                handleSaveTestimonialsSection({
                  ...upgradePage?.testimonials_section,
                  card_text_color: cardTextColor,
                });
              }}
            />
          </FormInput>
        </FormSection>
      </FormContainer>
    </ThemeFormDrawer>
  );
};

export default TestimonialsSection;
