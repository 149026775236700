import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useInfiniteScroll } from '@/hooks';
import useWorkspaceViews from '@/hooks/useAdNetwork/internal/useWorkspaceViews';
import { AdNetworkWorkspaceView } from '@/interfaces/ad_network/internal/workspace_view';

import { Shortcut } from '../Shortcut';

import useFilteringContext from './filtering-context';
import ViewSelectButton from './ViewSelectButton';

interface Props {
  workspaceViews: AdNetworkWorkspaceView[];
  query: string;
  setQuery: (query: string) => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  isFetching: boolean;
}

const ViewSelector = ({
  workspaceViews,
  query,
  setQuery,
  hasNextPage,
  fetchNextPage,
  isLoading,
  isFetching,
}: Props) => {
  const [highlightedFilterIndex, setHighlightedFilterIndex] = useState(0);
  const { setState } = useFilteringContext();

  const onSelectView = useCallback(
    (view: AdNetworkWorkspaceView) => {
      setState(view.conditions);
    },
    [setState]
  );

  const handleTypeahead = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setHighlightedFilterIndex(0);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  // autofocus on input after 200ms
  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();

        setHighlightedFilterIndex((prevIndex) => {
          if (prevIndex + 1 < workspaceViews.flat().length) {
            return prevIndex + 1;
          }

          if (prevIndex + 1 === workspaceViews.flat().length) {
            return 0;
          }

          return prevIndex;
        });
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();

        setHighlightedFilterIndex((prevIndex) => {
          if (prevIndex - 1 >= 0) {
            return prevIndex - 1;
          }

          if (prevIndex - 1 < 0) {
            return workspaceViews.flat().length - 1;
          }

          return prevIndex;
        });
      }
    };

    const handleEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        const filter = workspaceViews.flat()[highlightedFilterIndex];
        onSelectView(filter);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keyup', handleEnter);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keyup', handleEnter);
    };
  }, [workspaceViews, highlightedFilterIndex, onSelectView]);

  const { ref: lastElementRef } = useInfiniteScroll({ fetchNextPage, hasNextPage, isFetching, isLoading });

  return (
    <div className="bg-white border border-gray-300 shadow-2xl rounded-md text-sm left-0 w-fit text-gray-600 font-medium divide-y max-h-128 overflow-scroll no-scrollbar">
      <div className="p-2 relative">
        <input
          type="text"
          className="px-2 py-1 text-sm appearance-none border-none ring-0 focus:outline-none focus:ring-0"
          placeholder="Apply a view..."
          value={query}
          onChange={handleTypeahead}
          ref={inputRef}
        />
        <div className="absolute flex transform top-1/2 right-2 -translate-y-1/2">
          <Shortcut>V</Shortcut>
        </div>
      </div>

      <ul className="p-2">
        {workspaceViews.map((view) => {
          const isLastRow = workspaceViews.length - 1 === workspaceViews.indexOf(view);

          return (
            <li key={view.id} ref={isLastRow ? lastElementRef : undefined}>
              <ViewSelectButton
                workspaceView={view}
                onSelectWorkspaceView={onSelectView}
                highlighted={highlightedFilterIndex === workspaceViews.indexOf(view)}
                onHighlight={() => setHighlightedFilterIndex(workspaceViews.indexOf(view))}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default function Loader() {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 800);
  const { data, isSuccess, hasNextPage, fetchNextPage, isLoading, isFetching } = useWorkspaceViews({
    query: debouncedQuery,
  });

  if (!isSuccess) return null;

  const workspaceViews = data?.pages.map((page) => page.workspace_views).flat();

  return (
    <ViewSelector
      workspaceViews={workspaceViews}
      query={query}
      setQuery={setQuery}
      hasNextPage={!!hasNextPage}
      fetchNextPage={fetchNextPage}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  );
}
