import { Ledger } from '../../../../interfaces/admin_reports/ledger';

interface Props {
  ledger: Ledger;
}

const LedgerRow = ({ ledger }: Props) => {
  const ledgerKind = ledger.kind === 'grow' ? 'Spend' : 'Earnings';
  return (
    <li className="py-3" key={ledger.id}>
      <div className="w-full max-w-2/3">
        <div className="grid grid-cols-3 gap-4">
          <div className="px-4 py-1 font-normal text-left">{ledger.publication.name}</div>
          <div className="px-4 py-1 font-normal text-right invisible sm:visible">{ledger.formatted_balance}</div>
          <div className="px-4 py-1 font-normal text-right">{ledgerKind}</div>
        </div>
      </div>
    </li>
  );
};

export default LedgerRow;
