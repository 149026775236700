import { ReactNodeViewRenderer } from '@tiptap/react';

import { Heading as TiptapHeading } from '@/components/TiptapEditor/extensions/Heading';

import { HeadingView } from './views/HeadingView';

export const Heading = TiptapHeading.extend({
  addAttributes() {
    return {
      mergeTag: {
        default: null,
        renderHTML: (attributes) => ({ 'data-merge-tag': attributes.mergeTag }),
        parseHTML: (element) => element.getAttribute('data-merge-tag') || null,
      },
      level: {
        default: 1,
        renderHTML: (attributes) => ({ 'data-level': attributes.level }),
        parseHTML: (element) => element.getAttribute('data-level') || null,
      },
      width: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
        }),
      },
      maxWidth: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-max-width'),
        renderHTML: (attributes) => ({
          'data-max-width': attributes.maxWidth,
        }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    // only take the data- attributes prefix
    const dataAttributes = Object.fromEntries(
      Object.entries(HTMLAttributes).filter(([key]) => key.startsWith('data-'))
    );

    return ['h1', dataAttributes, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(HeadingView, { className: 'w-full' });
  },
});
