import { useQuery } from 'react-query';

import { AdNetworkWorkspaceView } from '@/interfaces/ad_network/internal/workspace_view';

import api from '../../../services/swarm';

export default function useWorkspaceView({ id }: { id: string }) {
  return useQuery<AdNetworkWorkspaceView>(
    ['ad_network', 'internal', 'workspace_view', id],
    () => api.get(`/ad_network/internal/workspace_views/${id}`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
