import { PropsWithChildren } from 'react';

import ActionModal from '@/components/ActionModal';

type Props = PropsWithChildren<{
  headerText: string;
  isOpen: boolean;
  isSaving: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}>;

const ConfirmModal = ({ isOpen, isSaving, onConfirm, onCancel, headerText, children }: Props) => {
  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onCancel}
      resourceId="confirm-automation"
      headerText={headerText}
      onProceed={onConfirm}
      isWorking={isSaving}
    >
      {children}
    </ActionModal>
  );
};

export default ConfirmModal;
