import { FC } from 'react';

import { Badge } from '../../../../../ui/Badge';

interface Props {
  status: string;
}

const badgeTypeFromStatus = (status: string): 'information' | 'success' | 'warning' | 'alert' => {
  if (status === 'pending') {
    return 'information';
  }

  if (status === 'complete') {
    return 'success';
  }

  if (status === 'failed') {
    return 'alert';
  }

  return 'warning';
};

const StatusBadge: FC<Props> = (props: Props) => {
  const { status } = props;

  return <Badge type={badgeTypeFromStatus(status)}>{status}</Badge>;
};

export default StatusBadge;
