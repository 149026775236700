import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { PublicationChange } from '@/interfaces/mailer_actions/publication_change';
import api from '@/services/swarm';

const useConfirmPublicationChange = (
  publicationId: string,
  token: String,
  { onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }
) => {
  const queryClient = useQueryClient();

  return useMutation<PublicationChange>(
    () =>
      api
        .patch(`/mailer_actions/publication_change_confirmations/${token}`, {
          publication_id: publicationId,
          publication_change: {
            confirm: true,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast.success('Publication change confirmed');
        queryClient.invalidateQueries(['mailer_actions', 'publication_change', token]);
        queryClient.invalidateQueries(['publications', publicationId]);
        onSuccess?.();
      },
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong', { duration: 7000 });
        onError?.();
      },
    }
  );
};

export default useConfirmPublicationChange;
