import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{}>;

const NonSortablePageWrapper = ({ children }: Props) => {
  return (
    <div className="w-full justify-between items-center bg-surface-100 shadow-sm border border-surface-200 rounded flex px-2 py-1">
      {children}
    </div>
  );
};

export default NonSortablePageWrapper;
