import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

export default function useStreakBadges() {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<any>([currentPublicationId, 'streak_badges'], () =>
    api
      .get(`/publications/${currentPublicationId}/streak`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data)
  );
}
