import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useContentImportsCreate } from '@/hooks/useContentImports';
import { ContentImport, ContentImportPlatform } from '@/interfaces/content_import';
import { Button } from '@/ui/Button';
import { FormStep } from '@/ui/FormStep';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import TwoColumnPageContainer from '../../../_components/TwoColumnPageContainer';
import { usePublicationSettings } from '../../context';
import GhostApiForm from '../components/GhostApiForm';
import MailchimpConnect from '../components/MailchimpConnect';
import MailchimpFolders from '../components/MailchimpFolders';
import OtherPlatform from '../components/OtherPlatform';
import PlatformSelection from '../components/PlatformSelection';
import SubstackPremiumContentUpload from '../components/SubstackPremiumContentUpload';
import SubstackPublicationUrlForm from '../components/SubstackUrlForm';
import Summary from '../components/Summary';
import WordpressInstructions from '../components/WordpressInstructions';
import WordpressUrlForm from '../components/WordpressUrlForm';

import { StepErrors, StepProps } from './types';

enum Steps {
  SELECT_SOURCE = 'select_source',
  SUBSTACK_URL = 'substack_url',
  SUBSTACK_PREMIUM_CONTENT_UPLOAD = 'substack_premium_content_upload',
  WORDPRESS_INSTRUCTIONS = 'wordpress_instructions',
  WORDPRESS_URL = 'wordpress_url',
  MAILCHIMP_CONNECT = 'mailchimp_connect',
  MAILCHIMP_FOLDERS = 'mailchimp_folders',
  GHOST_API = 'ghost_api',
  OTHER_PLATFORM = 'other_platform',
}

const stepConfig: Record<
  ContentImportPlatform,
  { step: Steps; stepTitle: string; component: React.FunctionComponent<StepProps> }[]
> = {
  substack: [
    {
      step: Steps.SUBSTACK_URL,
      stepTitle: 'Publication URL',
      component: SubstackPublicationUrlForm,
    },
    {
      step: Steps.SUBSTACK_PREMIUM_CONTENT_UPLOAD,
      stepTitle: 'Paywall Content Upload (Optional)',
      component: SubstackPremiumContentUpload,
    },
  ],
  wordpress: [
    {
      step: Steps.WORDPRESS_INSTRUCTIONS,
      stepTitle: 'Include Full Posts',
      component: WordpressInstructions,
    },
    {
      step: Steps.WORDPRESS_URL,
      stepTitle: 'Publication URL',
      component: WordpressUrlForm,
    },
  ],
  mailchimp: [
    {
      step: Steps.MAILCHIMP_CONNECT,
      stepTitle: 'Connect your Mailchimp account',
      component: MailchimpConnect,
    },
    {
      step: Steps.MAILCHIMP_FOLDERS,
      stepTitle: 'Select Folders',
      component: MailchimpFolders,
    },
  ],
  ghost: [
    {
      step: Steps.GHOST_API,
      stepTitle: 'API Settings',
      component: GhostApiForm,
    },
  ],
  other: [
    {
      step: Steps.OTHER_PLATFORM,
      stepTitle: 'Other Platform',
      component: OtherPlatform,
    },
  ],
};

const getStepErrors = (contentImportInState: Partial<ContentImport>, step: Steps): StepErrors | null => {
  const { url, integration_id: integrationId } = contentImportInState;

  switch (step) {
    case 'substack_url':
    case 'wordpress_url':
      if (!url) {
        return { url: 'Please enter a valid URL' };
      }
      break;
    case 'mailchimp_folders':
      if (!integrationId) {
        return { integrationId: 'Please select an account' };
      }
      break;
    default:
      return null;
  }

  return null;
};

const NewContentImport = () => {
  const navigate = useNavigate();
  const { currentPublicationId, settings } = usePublicationSettings();
  const hasMailChimpIntegration = settings?.mailchimp_integration;
  const onSaveMutation = useContentImportsCreate({ publicationId: currentPublicationId });
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.SELECT_SOURCE);
  const [stepErrors, setStepErrors] = useState<StepErrors>({});
  const [contentImportInState, setContentImportInState] = useState<Partial<ContentImport>>({
    platform: ContentImportPlatform.SUBSTACK,
  });
  const {
    platform,
    url,
    file,
    api_key: apiKey,
    integration_id: integrationId,
    integration_provider: integrationProvider,
    mailchimp_folders: mailchimpFolders,
  } = contentImportInState;
  const platformSteps = stepConfig[platform as ContentImportPlatform] || [];

  const handleChangePlatform = (value: ContentImportPlatform) => {
    setContentImportInState({ platform: value });
  };

  const handleUpdateContentImport = (payload: Partial<ContentImport>) => {
    setContentImportInState((prev) => ({ ...prev, ...payload }));
  };

  const handleSave = () => {
    const errors = getStepErrors(contentImportInState, currentStep);
    if (errors) {
      setStepErrors(errors);
    } else {
      const payload = new FormData();

      payload.append('publication_id', currentPublicationId);
      payload.append('content_import[platform]', platform || '');
      payload.append('content_import[url]', (url && `https://${url}`) || '');
      payload.append('content_import[api_key]', apiKey || '');
      payload.append('content_import[integration_id]', integrationId || '');
      payload.append('content_import[integration_provider]', integrationProvider || '');

      if (file instanceof File) {
        payload.append('content_import[file]', file);
      }

      mailchimpFolders?.forEach((val) => {
        payload.append('content_import[mailchimp_folders][]', val);
      });

      onSaveMutation.mutate(payload);
    }
  };

  const handleCancel = () =>
    navigate(appendSettingsPublicationId('/settings/publication/content_import', currentPublicationId));

  const handleClickPreviousStep = () => {
    const currentIndex = platformSteps.findIndex((step) => step.step === currentStep);
    if (currentIndex > 0) {
      setCurrentStep(platformSteps[currentIndex - 1].step);
    } else {
      setCurrentStep(Steps.SELECT_SOURCE);
    }
  };

  const handleClickNextStep = () => {
    const errors = getStepErrors(contentImportInState, currentStep);

    if (errors) {
      setStepErrors(errors);
    } else {
      const currentIndex = platformSteps.findIndex((step) => step.step === currentStep);

      if (currentIndex < platformSteps.length - 1) {
        setCurrentStep(platformSteps[currentIndex + 1].step);
      }
      setStepErrors({});
    }
  };

  const renderStep = (step: any) => {
    const config = stepConfig[contentImportInState.platform as ContentImportPlatform]?.find((s) => s.step === step);
    if (!config) return null;

    const Component = config.component;
    return (
      <FormStep
        key={step}
        isOpen={currentStep === step}
        stepTitle={config.stepTitle}
        footer={
          <>
            {currentStep === Steps.SELECT_SOURCE && (
              <Button type="button" variant="primary-inverse" onClick={handleCancel} size="sm">
                Cancel
              </Button>
            )}
            {currentStep !== Steps.SELECT_SOURCE && (
              <Button type="button" variant="primary-inverse" onClick={handleClickPreviousStep} size="sm">
                Back
              </Button>
            )}
            {currentStep !== platformSteps[platformSteps.length - 1]?.step && (
              <Button type="button" variant="primary" onClick={handleClickNextStep} size="sm">
                Next
              </Button>
            )}
            {currentStep === platformSteps[platformSteps.length - 1]?.step && currentStep !== Steps.OTHER_PLATFORM && (
              <Button type="button" variant="primary" onClick={handleSave} size="sm">
                Save
              </Button>
            )}
          </>
        }
      >
        <Component
          contentImport={contentImportInState}
          updateContentImport={handleUpdateContentImport}
          errors={stepErrors}
        />
      </FormStep>
    );
  };

  return (
    <TwoColumnPageContainer key={currentPublicationId} rhsColumn={<Summary contentImport={contentImportInState} />}>
      <FormStep
        isOpen={currentStep === Steps.SELECT_SOURCE}
        stepTitle="Content Location"
        footer={
          <>
            <Button type="button" variant="primary-inverse" onClick={handleCancel} size="sm">
              Back
            </Button>
            <Button type="button" variant="primary" onClick={handleClickNextStep} size="sm">
              Next
            </Button>
          </>
        }
      >
        <PlatformSelection
          contentImport={contentImportInState}
          handleChangePlatform={handleChangePlatform}
          errors={stepErrors}
          showMailchimp={hasMailChimpIntegration || false}
        />
      </FormStep>
      {platformSteps.map((step) => renderStep(step.step))}
    </TwoColumnPageContainer>
  );
};

export default NewContentImport;
