import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

type RecommendationProps = {
  recommendationId: string;
  newStatus: string;
};

type TransitionProps = {
  onSuccess?: (data: any) => void;
  onError?: () => void;
};

const useTransitionRecommendation = ({ onSuccess, onError }: TransitionProps) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    ({ recommendationId, newStatus }: RecommendationProps) =>
      api
        .patch(`/recommendations/${recommendationId}/status`, {
          publication_id: currentPublicationId,
          recommendation: {
            status: newStatus,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (err: any) => {
        onError?.();
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useTransitionRecommendation;
