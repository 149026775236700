import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  upgradePageTestimonialId: string;
  onSuccess?: () => void;
}

const useDeleteUpgradePageTestimominal = ({ upgradePageTestimonialId, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation(() => api.delete(`/upgrade_page_testimonials/${upgradePageTestimonialId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.message || 'Something went wrong');
    },
    onSuccess,
  });
};

export default useDeleteUpgradePageTestimominal;
