import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import moment from 'moment-mini';

import { Typography } from '@/components/Typography';

import { CALENDAR_POPOVER_Z_INDEX } from '../zIndexes';

import GridEvent from './GridEvent';
import { useCalendarContext } from './useCalendarContext';

const Grid = () => {
  const { calendars, days, selectedDate, setSelectedDate } = useCalendarContext();

  return (
    <div className="lg:col-span-6 col-span-8 overflow-y-auto" data-testid="calendar-grid">
      <div className="border border-t-0 border-gray-100">
        <div className="grid grid-cols-7 border-b border-gray-100 sm:border-none gap-6 sm:gap-0">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div
              key={day}
              className="text-center sm:p-2 py-2 sm:border-r sm:[&:nth-child(7)]:border-r-0 border-gray-100"
            >
              <Typography as="p" token="font-medium/text/sm" className="truncate" colorWeight="700">
                {day}
              </Typography>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 relative gap-6 sm:gap-0">
          {days.map(({ date, isCurrentMonth, events }) => {
            const isMoreThanTwoEvents = (events || []).length > 2;
            const calendarsInDay = calendars.filter((calendar) =>
              events.some((event) => event.calendar === calendar.id)
            );

            return (
              <div
                key={date.format()}
                className="min-h-full relative flex flex-col sm:border-t sm:border-r [&:nth-child(7n)]:border-r-0 border-gray-100 sm:[&:nth-child(7n)]:bg-gray-50 sm:[&:nth-child(7n+1)]:bg-gray-50 z-0"
              >
                <div
                  className={cx(
                    'relative aspect-1 flex flex-col px-3 py-2 justify-center sm:justify-normal',
                    (events || []).length === 0 && 'h-full'
                  )}
                >
                  <button
                    type="button"
                    key={date.format()}
                    className={cx(
                      'absolute inset-0 z-0 border active:border-gray-900 focus:border-gray-900 sm:border-none sm:active:bg-violet-200 sm:focus:bg-violet-200 rounded sm:rounded-none outline-none',
                      selectedDate && date.isSame(selectedDate, 'day') ? 'border-gray-900' : 'border-transparent'
                    )}
                    onClick={() => setSelectedDate(date)}
                  >
                    <span className="sr-only">Select {date.format('LL')}</span>
                  </button>
                  <div
                    className={cx(
                      'z-10 sm:text-right pointer-events-none flex sm:mb-1 sm:justify-end justify-center sm:items-center'
                    )}
                  >
                    <div
                      className={cx(
                        date.isSame(moment(), 'day') &&
                          'p-0.5 border h-6 w-6 sm:h-5 sm:w-5 bg-red-100 border-red-200 text-gray-800 rounded inline-flex text-center items-center justify-center'
                      )}
                    >
                      <div className="hidden sm:inline-block">
                        <Typography as="p" token="font-medium/text/xs" colorWeight={isCurrentMonth ? '900' : '400'}>
                          {date.format('D')}
                        </Typography>
                      </div>
                      <div className="inline-block sm:hidden">
                        <Typography as="p" token="font-normal/text/sm" colorWeight={isCurrentMonth ? '900' : '400'}>
                          {date.format('D')}
                        </Typography>
                      </div>

                      <div className="flex sm:hidden space-x-1 absolute bottom-1.5 right-1/2 transform translate-x-1/2 w-full justify-center">
                        {calendarsInDay.map((calendar) => {
                          const colorClass = {
                            pink: 'bg-pink-500',
                            violet: 'bg-violet-500',
                          }[calendar.color];

                          return <div key={calendar.id} className={cx('h-0.5 w-2.5 rounded-full', colorClass)} />;
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="hidden sm:block space-y-1" style={{ zIndex: 9999 }}>
                    {events.slice(0, 2).map((event) => (
                      <GridEvent key={event.id} event={event} />
                    ))}
                    {isMoreThanTwoEvents && (
                      <Popover.Root>
                        <Popover.Trigger aria-label="View more events">
                          <Typography as="p" token="font-medium/text/xs" colorWeight="900" className="hover:underline">
                            {events.length - 2} more
                          </Typography>
                        </Popover.Trigger>

                        <Popover.Portal>
                          <Popover.Content
                            align="center"
                            className={cx('max-w-52', CALENDAR_POPOVER_Z_INDEX)}
                            sideOffset={5}
                          >
                            <div className="bg-white border py-1.5 shadow-lg rounded">
                              {events.map((event) => (
                                <div className="py-2 px-3 hover:bg-gray-50" key={event.id}>
                                  <GridEvent event={event} />
                                </div>
                              ))}
                            </div>
                          </Popover.Content>
                        </Popover.Portal>
                      </Popover.Root>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Grid;
