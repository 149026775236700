/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { Editor, mergeAttributes } from '@tiptap/core';
import Mention, { MentionOptions } from '@tiptap/extension-mention';
import { ReactNodeViewRenderer } from '@tiptap/react';
import throttle from 'lodash.throttle';

import { BacklinkView } from './views/BacklinkView';
import { BACKLINK_TYPE } from './const';
import { backlinkSuggestions } from './suggestions';
import { TBacklinkAttrs } from './types';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    backlink: {
      /**
       * Set the backlink
       */
      setBacklink: (attrs: TBacklinkAttrs) => ReturnType;
      /**
       * Focus the backlink
       */
      focusBacklink: (id: string) => ReturnType;
    };
  }
}

const extractBacklinkIdsAndTitles = (editor: Editor) => {
  const map: Record<string, { id: string; title: string }[]> = {};

  editor.state.doc.descendants((node) => {
    if (node.type.name === 'backlink') {
      const { target, title, id } = node.attrs;

      if (map[target]) {
        map[target].push({ id, title });
      } else {
        map[target] = [{ id, title }];
      }
    }
  });

  editor.storage[BACKLINK_TYPE].backlinkTitlesById = map;
};

const throttledExtractBacklinkIdsAndTitles = throttle(extractBacklinkIdsAndTitles, 1000);

interface BacklinkStorage {
  backlinkTitlesById: Record<string, string[]>;
}

export const Backlink = Mention.extend<MentionOptions, BacklinkStorage>({
  name: BACKLINK_TYPE,

  draggable: true,

  selectable: true,

  addOptions() {
    return {
      ...this.parent?.(),
      suggestion: backlinkSuggestions(() => document.body),
    };
  },

  addStorage() {
    return {
      backlinkTitlesById: {},
    };
  },

  onUpdate() {
    throttledExtractBacklinkIdsAndTitles(this.editor);
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      target: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-target'),
        renderHTML: (attributes) => ({ 'data-target': attributes.target }),
      },
      title: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-title'),
        renderHTML: (attributes) => ({ 'data-title': attributes.title }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${BACKLINK_TYPE}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setBacklink:
        (attrs) =>
        ({ chain }) => {
          return chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs,
            })
            .run();
        },
      focusBacklink:
        (id: string) =>
        ({ state, chain }) => {
          let focusPos = -1;

          state.doc.descendants((node, pos) => {
            if (focusPos > -1) {
              return false;
            }

            if (node.type.name === 'backlink' && node.attrs.id === id) {
              focusPos = pos;
            }
          });

          if (focusPos > -1) {
            return chain().setNodeSelection(focusPos).focus().run();
          }

          return true;
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(BacklinkView);
  },
});

export default Backlink;
