import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import debounce from 'lodash.debounce';

interface Props {
  className?: string;
  name: string;
  value?: number;
  onChange: (name: string, value: number) => void;
  disabled?: boolean;
  increment?: number;
  min?: number;
  max?: number;
  suffix?: string;
  warning?: boolean;
}

const Stepper: FC<Props> = ({
  className,
  name,
  onChange,
  value = 0,
  disabled,
  increment = 1,
  min = 0,
  max = 100,
  suffix,
  warning,
}: Props) => {
  const [internalValue, setInternalValue] = useState<number | string>(value);
  const [inputWidth, setInputWidth] = useState<string>('1ch');
  const inputRef = useRef<HTMLInputElement>(null);
  const inputId = `${name}-input`;

  const updateInputWidth = (valueToSet: number | string) => {
    const { length } = valueToSet.toString();
    setInputWidth(`${Math.max(length, 1)}ch`);
  };

  useEffect(() => {
    setInternalValue(value);
    updateInputWidth(value);
  }, [value]);

  useEffect(() => {
    updateInputWidth(internalValue);
  }, [internalValue]);

  const handleIncrement = () => {
    // Ensure internalValue is a number before performing increment
    if (typeof internalValue === 'number') {
      if (max !== undefined && internalValue + increment > max) return;
      const newValue = internalValue + increment;
      setInternalValue(newValue);
      onChange(name, newValue);
    }
  };

  const handleDecrement = () => {
    // Ensure internalValue is a number before performing decrement
    if (typeof internalValue === 'number') {
      if (internalValue - increment < min) return;
      const newValue = internalValue - increment;
      setInternalValue(newValue);
      onChange(name, newValue);
    }
  };

  const validateAndAdjustValue = useCallback(
    debounce((newValue: number) => {
      if (max !== undefined && newValue > max) {
        setInternalValue(max);
        onChange(name, max);
      } else if (newValue < min) {
        setInternalValue(min);
        onChange(name, min);
      } else {
        onChange(name, newValue);
      }
    }, 200),
    [min, max, onChange, name]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    // Allow the user to clear the input temporarily (handle empty string)
    if (newValue === '') {
      setInternalValue(''); // Set the empty string to internalValue so the input clears
      return;
    }

    const parsedValue = parseFloat(newValue);

    // If the parsed value is NaN, don't proceed with validation
    if (Number.isNaN(parsedValue)) {
      return;
    }

    // Set the internal value as a number if parsed correctly
    setInternalValue(parsedValue);
  };

  return (
    <div className={className}>
      <div
        className={cx(
          'relative w-fit flex gap-2 items-center justify-around rounded-md focus:outline-none max-h-[2.375rem]',
          'focus:none focus:border-surface-200',
          'text-left sm:text-sm cursor-default shadow-sm py-1.5 px-3',
          'bg-white',
          'border border-surface-200',
          disabled ? 'bg-gray-50 cursor-not-allowed' : '',
          warning
            ? 'border-feedback-warning-600 hover:border-feedback-warning-300 hover:shadow-warning'
            : ' border-gray-300'
        )}
      >
        <div>
          <input
            id={inputId}
            ref={inputRef}
            value={internalValue}
            onChange={handleChange}
            disabled={disabled}
            onBlur={() => {
              if (internalValue === '') {
                setInternalValue(min);
              }

              validateAndAdjustValue(internalValue as number);
            }}
            type="number"
            pattern="\d*"
            style={{ width: inputWidth }}
            className={cx(
              'form-input min-w-fit',
              'text-sm text-gray-900 placeholder-gray-400',
              'border-none focus:border-none focus:ring-0 bg-transparent cursor-pointer pl-0'
            )}
            min={min}
            max={max}
          />
        </div>
        {suffix && <span className="text-sm text-gray-500">{suffix}</span>}
        <div className="flex flex-col items-center justify-center">
          <button
            type="button"
            onClick={handleIncrement}
            disabled={
              disabled ||
              typeof internalValue === 'string' ||
              (max !== undefined && typeof internalValue === 'number' && internalValue + increment > max)
            }
          >
            <ChevronUpIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
          </button>
          <button
            type="button"
            onClick={handleDecrement}
            disabled={
              disabled ||
              typeof internalValue === 'string' ||
              (typeof internalValue === 'number' && internalValue - increment < min)
            }
          >
            <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
