import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

const useDenyAction = ({ subscriberId, status }: { subscriberId: string; status: string | undefined }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDenying, setIsDenying] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const denyMutation = useMutation(
    () => {
      return api
        .patch(`/subscriptions/${subscriberId}/deny`, { publication_id: currentPublicationId })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['subscriber', subscriberId]);
        toast.success('Subscriber denied');
      },
      onError: () => {
        toast.error('Something went wrong');
      },
      onSettled: () => {
        setIsDenying(false);
        setConfirmOpen(false);
      },
    }
  );

  const onDeny = () => {
    setIsDenying(true);
    denyMutation.mutate();
  };

  if (status !== 'needs_attention') {
    return [];
  }

  return [
    {
      label: 'Deny',
      helperText: 'Deny subscriber',
      onClick: () => setConfirmOpen(true),
      children: (
        <ActionModal
          isOpen={confirmOpen}
          onProceed={onDeny}
          onClose={() => setConfirmOpen(false)}
          resourceId={subscriberId}
          isWorking={isDenying}
          headerText="Deny Subscription"
          actionText="Deny"
        >
          Are you sure you want to deny this subscriber from receiving your newsletters?
        </ActionModal>
      ),
    },
  ];
};

export default useDenyAction;
