import toast from 'react-hot-toast';
import { ResolvedPos } from '@tiptap/pm/model';
import { Plugin, PluginKey, Transaction } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';

import { API } from '../../lib/api';
import { imageRegex } from '../../lib/constants';

export const replaceImageWithUpload = (res: { data: { id: string } }, view: EditorView, pos: ResolvedPos) => {
  const { tr } = view.state;
  const caption = view.state.schema.nodes.figcaption.create();
  const node = view.state.schema.nodes.imageBlock.create(
    {
      id: res.data.id,
      width: '100%',
      align: 'center',
      captionAlign: 'center',
    },
    [caption]
  );
  tr.replaceRangeWith(pos.pos, pos.pos, node);
  view.dispatch(tr);
};

const replaceFigures = (element: HTMLElement, clientId?: number) => {
  const figures = element.querySelectorAll('figure');

  figures.forEach((figure) => {
    const image = figure.querySelector('img');

    if (!image) {
      return;
    }

    const figcaption = figure.querySelector('figcaption');

    const src = image.getAttribute('src');
    const caption = figcaption?.innerText || '';
    const imageBlockNode = document.createElement('figure');

    imageBlockNode.setAttribute('data-client-id', clientId?.toString() || '');
    imageBlockNode.setAttribute('data-type', 'imageBlock');
    imageBlockNode.setAttribute('data-src', src || '');
    imageBlockNode.setAttribute('data-caption', caption);

    const captionEl = document.createElement('figcaption');
    captionEl.innerText = caption;

    imageBlockNode.appendChild(captionEl);

    figure.replaceWith(imageBlockNode);
  });
};

const replaceImages = (element: HTMLElement, clientId?: number) => {
  const images = element.querySelectorAll('img');

  images.forEach((image) => {
    // If image is already wrapped in a figure, don't wrap it again.
    if (image.closest('figure')) {
      return;
    }

    const src = image.getAttribute('src');
    const imageBlockNode = document.createElement('figure');
    imageBlockNode.setAttribute('data-client-id', clientId?.toString() || '');
    imageBlockNode.setAttribute('data-type', 'imageBlock');
    imageBlockNode.setAttribute('data-src', src || '');
    image.replaceWith(imageBlockNode);
  });
};

export const ImageBlockPlugin = (pluginSettings: { publicationId: string; clientId?: number }): Plugin =>
  new Plugin({
    key: new PluginKey('imageBlockPasteHandler'),

    props: {
      transformPastedHTML(html) {
        const temporaryElement = document.createElement('div');
        temporaryElement.innerHTML = html;

        replaceFigures(temporaryElement, pluginSettings.clientId);
        replaceImages(temporaryElement, pluginSettings.clientId);

        const newHtml = temporaryElement.innerHTML;

        temporaryElement.remove();

        return newHtml;
      },

      handleDOMEvents: {
        paste(view, event) {
          if (!event.clipboardData) {
            return;
          }

          const pastedPosition = view.state.selection.$from;

          if (event.clipboardData.files.length > 0) {
            const { types } = event.clipboardData;
            const containsRichText = ['text/plain', 'text/html', 'text/rtf', 'Files'].every((type) =>
              types.includes(type)
            );

            if (containsRichText) {
              return;
            }

            event.preventDefault();

            const file = event.clipboardData.files[0];

            API.uploadPublicationAsset({
              publicationId: pluginSettings.publicationId,
              file,
            })
              .then((res) => replaceImageWithUpload(res, view, pastedPosition))
              .catch(() =>
                toast.error('There was a problem uploading this image. Make sure it is a valid image file.')
              );
          }

          const pastedText = event.clipboardData.getData('text/plain');
          const match = imageRegex.exec(pastedText);

          if (match) {
            event.preventDefault();

            const url = match.input;

            API.uploadPublicationAssetFromUrl({
              publicationId: pluginSettings.publicationId,
              url,
            })
              .then((res) => replaceImageWithUpload(res, view, pastedPosition))
              .catch(() =>
                toast.error('There was a problem uploading this image. Make sure it is a valid image file.')
              );
          }
        },
        drop: (view, event) => {
          if (!event.dataTransfer || event.dataTransfer.files.length === 0) {
            return;
          }

          const file = event.dataTransfer.files[0];

          if (file.type.indexOf('image') === -1) {
            return;
          }

          event.preventDefault();
          API.uploadPublicationAsset({
            publicationId: pluginSettings.publicationId,
            file,
          })
            .then((res) => {
              replaceImageWithUpload(res, view, view.state.selection.$from);
            })
            .catch(() => toast.error('There was a problem uploading this image. Make sure it is a valid image file.'));
        },
      },
    },

    filterTransaction(transaction: Transaction) {
      transaction.doc.descendants((node, pos) => {
        const isSameNodeType = node.type.name === 'imageBlock';

        if (isSameNodeType) {
          const { src } = node.attrs as { src: string };

          if (src?.startsWith('data:image')) {
            const buffer = Buffer.from(src.substring(src.indexOf(',') + 1));
            const sizeInMB = buffer.length / 1e6;

            if (sizeInMB > 5) {
              toast.error('Uploading of Base64 image failed …');

              transaction.delete(pos, pos + node.nodeSize);
            }
          }
        }
      });

      return true;
    },
  });

export default ImageBlockPlugin;
