import { FC } from 'react';
import { Link } from 'react-router-dom';

import Card from '@/components/Card';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import TablePagination from '@/components/TablePagination';
import { Pagination } from '@/interfaces/general';

import LeaderboardContainer from './LeaderboardContainer';

export interface Leader {
  id: string;
  email: string;
  total_referrals: number;
}

const Leaderboard: FC = () => {
  const renderListHeading = () => (
    <li className="py-4">
      <div className="flex space-x-3">
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">Email</h3>
            <h3 className="text-sm font-medium">Total Referrals</h3>
          </div>
        </div>
      </div>
    </li>
  );

  const renderListItem = (leader: Leader) => (
    <li className="py-4" key={leader.id}>
      <div className="flex space-x-3">
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <Link
              type="button"
              className="text-sm font-medium text-primary-600 hover:text-primary-900"
              to={`/subscribers/${leader.id}`}
            >
              {leader.email}
            </Link>
            <p className="text-sm text-gray-500">{leader.total_referrals}</p>
          </div>
        </div>
      </div>
    </li>
  );

  const renderEmptyState = () => <p className="mt-4">No leaders</p>;

  const renderBody = (
    leaders: Leader[] | undefined,
    pagination: Pagination | undefined,
    isLoading: boolean,
    onPageSelected: (pageNumber: number) => void
  ) => {
    if (!isLoading && pagination && pagination.total === 0) {
      return renderEmptyState();
    }

    return (
      <>
        <ul className="divide-y divide-gray-200">
          {renderListHeading()}
          {leaders && leaders.map((leader) => renderListItem(leader))}
        </ul>
        {pagination && (
          <TablePagination
            className="border-t border-gray-200"
            pagination={pagination}
            onPageSelected={onPageSelected}
            maxLinksDisplayed={3}
          />
        )}
      </>
    );
  };

  return (
    <LeaderboardContainer>
      {(leaders, pagination, isLoading, onPageSelected) => (
        <Card>
          <h3 className="text-lg leading-6 font-medium text-gray-900 flex">
            Leaderboard {isLoading && <LoadingSpinner className="ml-2" />}
          </h3>
          {renderBody(leaders, pagination, isLoading, onPageSelected)}
        </Card>
      )}
    </LeaderboardContainer>
  );
};

export default Leaderboard;
