import { useQuery } from 'react-query';

import useCurrentTimeZone from '@/hooks/useCurrentTimeZone';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { TimePeriod } from '../../../interfaces/time_period';
import api from '../../../services/swarm';

interface ApiResponse {
  metrics: { name: string; spend: number; previous_spend: number }[];
  period: TimePeriod;
  time_zone: string;
  total: number;
}

const useSpendPerPeriod = (period: TimePeriod) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const currentTimeZone = useCurrentTimeZone();

  const fetchSpend = (): Promise<ApiResponse> =>
    api
      .get(`/boosts/grow/spend_per_period/${period}`, {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
        },
      })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'grow', 'spend_per_period', period, currentTimeZone], fetchSpend, {
    keepPreviousData: true,
  });
};

export default useSpendPerPeriod;
