import { Link, useParams } from 'react-router-dom';
import { ArrowRight } from '@phosphor-icons/react';

import { usePage } from '@/hooks/usePages';
import useUpdatePageVersion from '@/hooks/usePageVersion/useUpdatePageVersion';
import { useSiteTemplate } from '@/hooks/useSiteTemplates';
import { Button } from '@/routes/website/_components/UI/Button';

import SectionRenderer from '../../helpers/SectionRenderer';
import { useAttributesPanelNavbarFooterContext } from '../../navbarFooterContextProvider';
import { ShowOnPageSettings } from '../ShowOnPageSettings';

export function FooterSettings() {
  const { pageId, templateId } = useParams();

  const { data: template } = useSiteTemplate({ siteTemplateId: templateId });

  const { data: page } = usePage({ pageId: pageId as string });
  const context = useAttributesPanelNavbarFooterContext();
  const { isFooterSelected } = context || {};

  const { mutate: updatePageVersion, isLoading } = useUpdatePageVersion({
    pageId: page?.id || '',
    id: page?.draft_page_version?.id || '',
  });

  const isShowOnPageEnabled =
    'show_footer' in (page?.draft_page_version?.customization || {})
      ? page?.draft_page_version?.customization?.show_footer
      : true; // default to true if show_footer is not defined

  if (!isFooterSelected) {
    return null;
  }

  const handleShowOnPageChange = (value: boolean) => {
    updatePageVersion({
      customization: { show_footer: value },
    });
  };

  return (
    <div className="footer-settings">
      <SectionRenderer title="Footer" collapsible={false}>
        <Link
          to={
            templateId
              ? `/website_builder_v2/footer_template/${template?.site_package_id}`
              : `/website_builder_v2/footer?previousPageID=${pageId}`
          }
        >
          <Button variant="secondary" RightIcon={ArrowRight} className="w-full">
            Edit Footer
          </Button>
        </Link>
      </SectionRenderer>
      <SectionRenderer title="Show on Page">
        {page && (
          <ShowOnPageSettings value={isShowOnPageEnabled} onChange={handleShowOnPageChange} isLoading={isLoading} />
        )}
      </SectionRenderer>
    </div>
  );
}
