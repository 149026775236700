import { useQuery } from 'react-query';

import { PublicationRewind } from '@/interfaces/publication_rewind';
import api from '@/services/swarm';

const usePublicationRewind = (enabled: boolean = true, publicationId?: string) =>
  useQuery<PublicationRewind>(
    ['publications', publicationId, 'rewind'],
    () => api.get(`/publications/${publicationId}/rewind`).then((res) => res.data),
    { enabled: enabled && !!publicationId }
  );

export default usePublicationRewind;
