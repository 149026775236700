import { useCreateDraft } from '@/hooks';

interface Props {
  opportunityId: string;
}

function useAddOpportunityToPost({ opportunityId }: Props) {
  const createDraftMutation = useCreateDraft();

  return () => {
    createDraftMutation.mutateAsync({
      tiptap_state: {
        type: 'doc',
        content: [
          {
            type: 'advertisementOpportunity',
            attrs: {
              id: opportunityId,
            },
          },
        ],
      },
    });
  };
}

export default useAddOpportunityToPost;
