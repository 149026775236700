import { useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import Card from '@/components/Card';
import { Typography } from '@/components/Typography';
import { BoostSend } from '@/interfaces/boosts/monetize/boost_send';

import BoostSendsList from './BoostSendsList';
import EmailBoostCard from './EmailBoostCard';

interface Props {
  sendableBoostSends: BoostSend[];
}

const EmailChannelBoosts = ({ sendableBoostSends }: Props) => {
  const navigate = useNavigate();

  const handleClickSend = () => {
    navigate('/posts');
  };

  return (
    <>
      {sendableBoostSends.length > 0 && (
        <Card>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <Typography token="font-bold/text/sm">New Email Boosts</Typography>
              <Typography token="font-medium/text/sm">
                You have 72 hours from approval to send emails boosts in a post. Take action below
              </Typography>
            </div>
            <div className="grid sm:grid-cols-3 gap-5">
              {sendableBoostSends.map((boostSend) => {
                const sendByHoursLeft = boostSend.send_by ? moment(boostSend.send_by).diff(new Date(), 'hours') : 0;
                return (
                  <EmailBoostCard
                    key={`email-boost-card-${boostSend.id}`}
                    isCTADisabled={false}
                    onCTAClick={handleClickSend}
                    ctaText="Send in Post"
                    ctaVariant="secondary"
                    boostedPublication={boostSend.boosted_publication}
                    sendBy={sendByHoursLeft}
                  />
                );
              })}
            </div>
          </div>
        </Card>
      )}
      <BoostSendsList />
    </>
  );
};

export default EmailChannelBoosts;
