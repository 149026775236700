import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PostTarget, PostTargetPlatform } from '@/interfaces/post_target';
import api from '@/services/swarm';

interface ApiResponse {
  post_targets: PostTarget[];
}

const usePostTargets = (postId: string | undefined, platform: PostTargetPlatform) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostTargets = () => {
    return api
      .get(`/posts/${postId}/post_targets`, { params: { publication_id: currentPublicationId, platform } })
      .then((res) => res.data);
  };

  return useQuery<ApiResponse>(['post', postId, 'postTargets', platform], fetchPostTargets, { enabled: !!postId });
};

export default usePostTargets;
