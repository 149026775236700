import { FC } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import PageHeading from '../../../components/Layout/PageLayout/PageHeading';
// Contexts
import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { useSettings } from '../../../context/settings-context';
import { useTutorial } from '../../../hooks/useTutorials';
import { Tab } from '../../../interfaces/general';
import { TutorialType } from '../../../interfaces/tutorial';
// Services
import api from '../../../services/swarm';
import TabNavigation from '../../../ui/TabNavigation';
import Teaser from '../Teaser';

interface IKPIS {
  pending: number;
  fulfilled: number;
  ignored: number;
  awaiting_promo_code: number;
}

interface Props {
  children: React.ReactNode;
}

const constructTabs: (currentPath: string, kpis?: IKPIS) => Tab[] = (currentPath: string, kpis?: IKPIS): Tab[] => [
  {
    name: 'overview',
    label: 'Overview',
    targetRoute: '/referral_program/overview',
    selected: currentPath.includes('/referral_program/overview'),
  },
  {
    name: 'fulfillment',
    label: 'Fulfillment',
    targetRoute: '/referral_program/fulfillment',
    selected: currentPath.includes('/referral_program/fulfillment'),
    count: kpis ? kpis.pending + kpis.awaiting_promo_code : 0,
  },
  {
    name: 'configure',
    label: 'Configure',
    targetRoute: '/referral_program/configure',
    selected: currentPath.includes('/referral_program/configure'),
  },
];

const NavContainer: FC<Props> = (props: Props) => {
  const tutorial = useTutorial(TutorialType.REFERRAL_PROGRAM);

  const { settings } = useSettings();
  const { pathname } = useLocation();
  const [currentPublicationId] = useCurrentPublicationState();

  const { data } = useQuery<IKPIS, Error>(
    ['referral_program_nav_kpis', currentPublicationId, settings],
    () =>
      api
        .get('/referral_program/subscription_milestones/kpis', {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!currentPublicationId && !!settings && settings.referral_program,
      staleTime: Infinity,
    }
  );

  if (!settings) return null;

  if (!settings.referral_program) {
    return <Teaser />;
  }

  const { children } = props;

  const constructedTabs = constructTabs(pathname, data);

  return (
    <div>
      <PageHeading
        title="Referral Program"
        description="Build a fully integrated referral program"
        tutorial={tutorial}
      />
      <main>
        <div>
          <TabNavigation tabs={constructedTabs} className="sm:border-b mb-4 px-4 lg:px-0" variant="tertiary" />
          <div className="px-4 py-6 sm:px-0">{children}</div>
        </div>
      </main>
    </div>
  );
};

export default NavContainer;
