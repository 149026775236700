import { useQuery } from 'react-query';

import { PostSendAndOpenStats } from '../interfaces/post';
import api from '../services/swarm';

interface Arguments {
  id: string;
  isEnabled?: boolean;
  staleTime?: number | undefined;
}

export default function usePostSendAndOpenStats({ id, isEnabled = true, staleTime = 1000 * 60 }: Arguments) {
  return useQuery<PostSendAndOpenStats>(
    ['posts', id, 'send_and_open_stats'],
    () => api.get(`/posts/${id}/send_and_open_stats`).then((res) => res.data),
    {
      keepPreviousData: true,
      enabled: isEnabled,
      staleTime,
    }
  );
}
