import { PropsWithChildren, ReactNode } from 'react';

import Card from '@/components/Card';
import { getColor } from '@/components/DesignTokens';
import { Typography } from '@/components/Typography';

type Props = PropsWithChildren<{
  stepNumber?: number;
  stepTitle: string;
  stepDescription?: string;
  isOpen: boolean;
  footer: ReactNode;
}>;

const FormStep = ({ children, footer, stepNumber, stepTitle, stepDescription, isOpen }: Props) => (
  <Card noPadding background={!isOpen ? getColor('surface/200', 'background') : undefined}>
    <div className="flex flex-col">
      <div className="flex gap-x-2 p-4">
        {typeof stepNumber !== 'undefined' ? (
          <Typography weight="medium" colorWeight="400">
            Step {stepNumber}:
          </Typography>
        ) : null}
        <div className="flex flex-col gap-y-2">
          <Typography weight="medium" colorWeight={isOpen ? '700' : '400'}>
            {stepTitle}
          </Typography>
          {stepDescription && isOpen ? (
            <Typography token="font-normal/text/sm" colorWeight="500">
              {stepDescription}
            </Typography>
          ) : null}
        </div>
      </div>
      {isOpen ? (
        <>
          <div className="flex flex-col gap-y-6 p-4 border-b">{children}</div>
          <div className="flex gap-x-2 p-4 justify-end bg-surface-50 rounded-b-md">{footer}</div>
        </>
      ) : null}
    </div>
  </Card>
);

export default FormStep;
