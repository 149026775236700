import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-mini';

import useAdvertiserPublisherLists from '@/hooks/useAdNetwork/internal/useAdvertiserPublisherLists';

import LoadingBox from '../../../../components/LoadingBox';
import { useAdvertiser } from '../../../../hooks/useAdNetwork/internal';
import { MultiActionCard } from '../../_components/MultiActionCard';
import { PageHeading } from '../../_components/PageHeading';

interface ListEntryProps {
  id: string;
  publisherName: string;
  expiresOn: Date;
}

const ListEntry = ({ id, publisherName, expiresOn }: ListEntryProps) => {
  const navigate = useNavigate();

  return (
    <div key={id} className="text-lg">
      <div className="cursor-pointer" onClick={() => navigate(id)} aria-hidden="true">
        {publisherName}
        {expiresOn && <span className="text-sm pl-1">(Expires: {moment.utc(expiresOn).format('YYYY-MM-DD')})</span>}
      </div>
    </div>
  );
};

export default function PublisherList() {
  const { advertiser_id: id } = useParams<'advertiser_id'>() as { advertiser_id: string };
  const { data, isLoading, isError } = useAdvertiserPublisherLists({ advertiserId: id, showRemoved: false });
  const { data: advertiserData } = useAdvertiser({ id });

  const navigate = useNavigate();

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="../..">{advertiserData?.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Publisher Preferred/Excluded List</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="max-w-lg pt-4 pl-4">
        <h1 className="text-xl">Publisher Preferred/Excluded List</h1>
      </div>
      <LoadingBox isLoading={isLoading} isError={isError} height="100vh">
        {data && (
          <div className="p-4">
            <div className="w-full grid grid-cols-4">
              <div className="col-span-2">
                <div className="text-xl my-4">Preferred</div>
                {data.preferred &&
                  data.preferred.list_items.map((item) => (
                    <ListEntry
                      id={item.id}
                      key={item.id}
                      publisherName={item.publisher_name}
                      expiresOn={item.expires_on}
                    />
                  ))}
              </div>
              <div className="col-span-2">
                <div className="text-xl my-4">Excluded</div>
                {data.excluded &&
                  data.excluded.list_items.map((item) => (
                    <ListEntry
                      id={item.id}
                      key={item.id}
                      publisherName={item.publisher_name}
                      expiresOn={item.expires_on}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </LoadingBox>
      <MultiActionCard>
        <MultiActionCard.Item
          title="Add to list"
          description="Add a Publisher to the Preferred or Excluded list"
          onClick={() => navigate('new')}
        />
      </MultiActionCard>
    </>
  );
}
