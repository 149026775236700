import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Input } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';
import { Button } from '@/ui/Button';

import Wizard from '../../_components/Wizard';
import useUpdateStripeMigration from '../../_hooks/useUpdateStripeMigration';

const STRIPE_USER_PROFILE_URL = 'https://dashboard.stripe.com/settings/user';
const STRIPE_API_KEY_URL = 'https://dashboard.stripe.com/apikeys';

const StripeMigrationWizardStepTwo = () => {
  const [accountId, setAccountId] = useState<string>();
  const [apikey, setApikey] = useState<string>();
  const { stripeMigrationId } = useParams();
  const navigate = useNavigate();

  const { mutate, isLoading } = useUpdateStripeMigration({
    id: stripeMigrationId,
    onSuccess: () => navigate(`/stripe_migrations/${stripeMigrationId}/map_products`),
  });

  return (
    <Wizard step={2}>
      <div className="mt-4 py-4 px-4 sm:px-6 space-y-8">
        <Typography>
          Next you will need to input some information about the stripe account connected to your previous platform.
        </Typography>

        <TypographyStack gap="2">
          <Typography token="font-bold/text/base">Account ID</Typography>
          <Typography token="font-normal/text/sm">
            The accounts that you have access to, along with their IDs, can be found at the bottom of your{' '}
            <a
              className="underline text-action-tertiary-600"
              href={STRIPE_USER_PROFILE_URL}
              target="_blank"
              rel="noreferrer"
            >
              user profile page
            </a>{' '}
            in Stripe. Please input the ID for the account connected to your previous platform.
          </Typography>
          <Input
            className="max-w-4xl"
            name="account_id"
            placeholderText="acct_123456789abcd"
            value={accountId}
            onChange={(e) => setAccountId(e.target.value)}
          />
        </TypographyStack>

        <TypographyStack gap="2">
          <Typography token="font-bold/text/base">API Key</Typography>
          <Typography token="font-normal/text/sm">
            You will need to generate an API key with the proper permissions for us to be able to read the required data
            from your account. We recommend that you generate a restricted API key with only the necessary permissions
            instead of using the standard key for your account. In Stripe, please ensure that you are viewing the
            account connected to your previous platform and then go to{' '}
            <a
              className="underline text-action-tertiary-600"
              href={STRIPE_API_KEY_URL}
              target="_blank"
              rel="noreferrer"
            >
              Developers &gt; API keys
            </a>{' '}
            to create a restricted key. You may name the key whatever you like but it must have the following
            permissions:
            <ul className="ml-2 py-2 w-[10rem]">
              <li className="flex justify-between">
                <Typography token="font-normal/text/sm">Customers:</Typography>
                <Typography token="font-medium/text/sm">Read</Typography>
              </li>
              <li className="flex justify-between">
                <Typography token="font-normal/text/sm">Products:</Typography>
                <Typography token="font-medium/text/sm">Read</Typography>
              </li>
              <li className="flex justify-between">
                <Typography token="font-normal/text/sm">Prices:</Typography>
                <Typography token="font-medium/text/sm">Read</Typography>
              </li>
              <li className="flex justify-between">
                <Typography token="font-normal/text/sm">Coupons:</Typography>
                <Typography token="font-medium/text/sm">Read</Typography>
              </li>
              <li className="flex justify-between">
                <Typography token="font-normal/text/sm">Subscriptions:</Typography>
                <Typography token="font-medium/text/sm">Read</Typography>
              </li>
            </ul>
          </Typography>
          <Input
            className="max-w-4xl"
            name="api_key"
            placeholderText="rk_1234567890987654321abcdbca..."
            value={apikey}
            onChange={(e) => setApikey(e.target.value)}
          />
        </TypographyStack>

        <div className="flex justify-end">
          <Button
            variant="primary"
            type="button"
            loading={isLoading}
            onClick={() => mutate({ api_key: apikey, previous_account_id: accountId })}
          >
            Continue
          </Button>
        </div>
      </div>
    </Wizard>
  );
};

export default StripeMigrationWizardStepTwo;
