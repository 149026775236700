import moment from 'moment-mini';

import LoadingBox from '@/components/LoadingBox';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import { Card } from '@/ui/Card';

import { useBillingSummary, usePlanPrice } from '../../_hooks';

import CancelDowngradeModal from './CancelDowngradeModal';
import PlanCard from './PlanCard';

const CurrentPlan: React.FC<{ organizationId: string; publicationId: string }> = ({
  organizationId,
  publicationId,
}) => {
  const { data: billingSummary } = useBillingSummary({ organizationId, publicationId, includeLaunch: true });
  const { next_invoice: nextInvoice } = billingSummary || {};

  const {
    data: planPrice,
    isLoading: isLoadingCurrent,
    isError: isErrorCurrent,
  } = usePlanPrice({ organizationId, publicationId });

  const {
    data: upcomingPlanPrice,
    isLoading: isLoadingUpcoming,
    isError: isErrorUpcoming,
  } = usePlanPrice({ organizationId, publicationId, upcoming: true });

  const showUpcoming = upcomingPlanPrice && planPrice && upcomingPlanPrice.id !== planPrice.id;

  if (!showUpcoming) {
    return null;
  }

  return (
    <Card>
      <BodyContainer>
        <CardHeader
          title="Your Upcoming Plan"
          description={nextInvoice?.timestamp && (`(starting ${moment(nextInvoice.timestamp).format('LL')})`)}
          cta={
            <CancelDowngradeModal
              organizationId={organizationId}
              publicationId={publicationId}
              currentPlanPrice={planPrice}
              upcomingPlanPrice={upcomingPlanPrice}
            />
          }
        />
        <LoadingBox
          isLoading={isLoadingCurrent || isLoadingUpcoming}
          isError={isErrorCurrent || isErrorUpcoming}
          backgroundClassName="bg-transparent"
          height={300}
        >
          <div className="space-y-6">
            <PlanCard planPrice={upcomingPlanPrice} />
          </div>
        </LoadingBox>
      </BodyContainer>
    </Card>
  );
};

export default CurrentPlan;
