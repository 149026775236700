import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styled from 'styled-components';

import { useClickOutside } from '@/hooks/useUsers/useClickOutside';

import { ColorPalette } from '../../interfaces/color_palette';
import ColorPicker from '../ColorPopover/ColorPicker';

const ColorSwatch: any = styled.div.attrs({
  className:
    'relative w-8 h-8 rounded-md border border-gray-100 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
})`
  background: ${({ bgColor }: any) => bgColor || ''};
`;

const pickerSize = 215;
interface ColorFieldProps {
  placement: 'top' | 'bottom' | 'center';
  color?: string;
  colorPalette: ColorPalette | undefined;
  onColorChange: (color: string) => void;
  onBlur?: () => void;
}

const ColorField: React.FC<ColorFieldProps> = ({
  color = '#FFFFFF',
  onColorChange,
  placement,
  colorPalette,
  onBlur,
}: ColorFieldProps) => {
  const [isShowing, setIsShowing] = useState(false);

  const pickerContainer = useRef<any>(null);
  const colorBlock = useRef<any>(null);

  useClickOutside(() => {
    if (isShowing) {
      setIsShowing(false);
    }

    if (isShowing && onBlur) {
      onBlur();
    }
  }, pickerContainer);

  const isTop = placement === 'top';
  const isCenter = placement === 'center';
  const isBottom = placement === 'bottom';

  const closePicker = (e: Event) => {
    if (!pickerContainer?.current?.contains(e.target)) {
      setIsShowing(false);
    }
  };

  // Adjust position if color picker goes off screen
  useLayoutEffect(() => {
    if (pickerContainer?.current && colorBlock?.current) {
      const containerRect = pickerContainer.current.getBoundingClientRect();
      const blockRect = colorBlock.current.getBoundingClientRect();

      // Do the rectangles overlap - aka is the picker covering the color block
      const overlap = !(blockRect.left > containerRect.right || containerRect.left > blockRect.right);

      if (overlap) {
        if (containerRect.right + (containerRect.width + 10) > window.innerWidth) {
          pickerContainer.current.style.left = `-${containerRect.width + 10}px`;
        } else {
          pickerContainer.current.style.left = `${blockRect.width + 10}px`;
        }
      }

      // Sometimes the color square will not be rendered at this point, if it isn't then we
      // still want to take into account its size.
      const topPosition =
        containerRect.height < pickerSize
          ? containerRect.top - pickerSize - 32 // Account for padding
          : containerRect.top;
      if (topPosition < 0) {
        pickerContainer.current.classList.add('top-0');
        pickerContainer.current.classList.remove('bottom-0');
      }
    }
  }, [isShowing]);

  useEffect(() => {
    if (isShowing) {
      document.addEventListener('scroll', closePicker);
    }

    return () => {
      document.removeEventListener('scroll', closePicker);
    };
  }, [isShowing]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      ref={colorBlock}
      className={`relative w-10 md:w-36 cursor-pointer ${isShowing && 'z-30'}`}
      onClick={() => setIsShowing(true)}
    >
      <div className="p-1 flex flex-column justify-between items-center rounded-md ring-1 ring-gray-300">
        <div>
          <ColorSwatch bgColor={color} />
          {isShowing && (
            <div
              ref={pickerContainer}
              className={cx(
                'absolute left-full ml-2 p-4 bg-white rounded border border-gray-100 shadow',
                isTop && 'top-0',
                isCenter && '-top-40',
                isBottom && 'bottom-0'
              )}
            >
              <ColorPicker
                width={pickerSize}
                themeColors={colorPalette}
                initialColor={color}
                onColorChange={(colorVal: string) => onColorChange(colorVal)}
                preventDefault
              />
            </div>
          )}
        </div>
        <span className="mx-auto text-sm hidden md:flex">{color.toUpperCase()}</span>
      </div>
    </div>
  );
};

export default ColorField;
