import { BarChart as TremorBarChart, BarChartProps } from '@tremor/react';

// https://www.tremor.so/docs/components/area-chart
// Ideally we pull from this base chart with our foundational customizations
// But you can always use the Tremor chart directly if needed
const BarChart = (props: BarChartProps) => {
  const { colors, ...rest } = props;
  return <TremorBarChart colors={colors || ['pink']} {...rest} />;
};

export default BarChart;
