import SwitchSlider from '@/components/Form/SwitchSlider';
import { Typography } from '@/components/Typography';
import { TierStatus } from '@/interfaces/tier';

interface Props {
  currentStatus: TierStatus;
  onChange: (status: TierStatus) => void;
}

const StatusSwitch: React.FC<Props> = ({ currentStatus, onChange }) => {
  return (
    <SwitchSlider
      name="status"
      size="sm"
      className="w-40"
      type="recessed"
      checked={currentStatus === TierStatus.ARCHIVED}
      onChange={(_, checked: boolean) => {
        onChange(checked ? TierStatus.ARCHIVED : TierStatus.ACTIVE);
      }}
      child1={<Typography token="font-semibold/text/xs">Active</Typography>}
      child2={<Typography token="font-semibold/text/xs">Archived</Typography>}
    />
  );
};

export default StatusSwitch;
