import { PropsWithChildren } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

type Props = PropsWithChildren<{
  titleChildren: React.ReactNode;
  defaultOpen?: boolean;
  className?: string;
  isControlledState?: boolean;
  isControlledStateOpen?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
}>;

const Accordion = ({
  children,
  defaultOpen = true,
  titleChildren,
  className,
  onClick,
  icon,
  isControlledStateOpen,
  isControlledState = false,
}: Props) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className="flex flex-col">
          <Disclosure.Button onClick={onClick} className={cx('flex justify-between space-x-4', className)}>
            <div>{titleChildren}</div>
            <div>
              {icon ? (
                <div>{icon}</div>
              ) : (
                <div className="bg-transparent hover:bg-gray-200 rounded-sm p-1">
                  {isControlledState ? (
                    <ChevronUpIcon
                      className={cx(
                        'w-4 h-4 transition-transform transition-50',
                        isControlledStateOpen ? 'rotate-0' : 'transform rotate-180'
                      )}
                    />
                  ) : (
                    <ChevronUpIcon
                      className={cx(
                        'w-4 h-4 transition-transform transition-50',
                        open ? 'rotate-0' : 'transform rotate-180'
                      )}
                    />
                  )}
                </div>
              )}
            </div>
          </Disclosure.Button>

          {isControlledState ? (
            <Transition show={isControlledStateOpen}>
              <Disclosure.Panel static>{children}</Disclosure.Panel>
            </Transition>
          ) : (
            <Disclosure.Panel>{children}</Disclosure.Panel>
          )}
        </div>
      )}
    </Disclosure>
  );
};

export default Accordion;
