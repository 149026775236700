import { useEffect } from 'react';

const HumanTag = () => {
  useEffect(() => {
    if (window.env.REACT_APP_HUMAN_ENABLED === 'true') {
      try {
        const humanScript = document.createElement('script');
        humanScript.src = window.env.REACT_APP_HUMAN_URL;
        humanScript.async = true;
        document.head.appendChild(humanScript);

        return () => {
          document.head.removeChild(humanScript);
        };
      } catch {
        /* ignore */
      }
    }

    return () => {};
  }, []);

  return null;
};

export default HumanTag;
