import { useNavigate, useSearchParams } from 'react-router-dom';

export type PageTypeObject = {
  title: string;
  path: string;
};

export type PageTypes = {
  signup_page: PageTypeObject;
  upgrade_page: PageTypeObject;
  home_page: PageTypeObject;
};

const PAGE_TYPES = Object.freeze({
  signup_page: {
    title: 'Signup Page',
    path: 'subscribe',
  },
  upgrade_page: {
    title: 'Upgrade Page',
    path: 'upgrade',
  },
  home_page: {
    title: 'Home Page',
    path: '',
  },
});

export type Pages = 'home_page' | 'signup_page' | 'upgrade_page';

interface ReturnProps {
  page: PageTypeObject;
  pageType: Pages;
  handlePageChange: (props: PageChangeProps) => void;
  isHomePage: boolean;
  isSignupPage: boolean;
  isUpgradePage: boolean;
}

interface PageChangeProps {
  path: string;
  pageType: Pages;
}

const usePageNavigator = (): ReturnProps => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentPageType = searchParams.get('page') || 'home_page';
  const page = PAGE_TYPES[currentPageType as keyof PageTypes];

  const handlePageChange = ({ path, pageType }: PageChangeProps) => {
    searchParams.set('page', pageType);
    searchParams.delete('sectionId');
    const currentParams = searchParams.toString();
    navigate(`${path}?${currentParams}`);
  };

  return {
    isHomePage: currentPageType === 'home_page',
    isSignupPage: currentPageType === 'signup_page',
    isUpgradePage: currentPageType === 'upgrade_page',
    pageType: currentPageType as Pages,
    page,
    handlePageChange,
  };
};

export default usePageNavigator;
