import { Node } from 'reactflow';
import { v4 as generateUuid } from 'uuid';

import { AutomationStepStepType } from '@/interfaces/automations/automation_step';

interface AddAutomationStepNodeProps {
  automationId?: string;
  parentNode?: string;
  branchId?: string;
  branchArm?: string;
  position?: {
    x: number;
    y: number;
  };
}

export const makeNewAddAutomationStepNode = (props: AddAutomationStepNodeProps) => {
  const id = generateUuid();
  const { automationId, branchId, branchArm, position, parentNode } = props;

  const node: Node = {
    id,
    type: AutomationStepStepType.ADD_AUTOMATION_STEP,
    data: { automationId, branchId, branchArm, parentId: parentNode },
    position: position || { x: 0, y: 0 },
    parentNode,
  };

  return node;
};
