import cx from "classnames";

interface Props {
  className?: string;
}

const InstagramIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={cx(className)}
    >
      <path
        d="M10.0001 13.3327C11.841 13.3327 13.3334 11.8403 13.3334 9.99935C13.3334 8.1584 11.841 6.66602 10.0001 6.66602C8.15913 6.66602 6.66675 8.1584 6.66675 9.99935C6.66675 11.8403 8.15913 13.3327 10.0001 13.3327Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 13.3333V6.66667C2.5 4.36548 4.36548 2.5 6.66667 2.5H13.3333C15.6345 2.5 17.5 4.36548 17.5 6.66667V13.3333C17.5 15.6345 15.6345 17.5 13.3333 17.5H6.66667C4.36548 17.5 2.5 15.6345 2.5 13.3333Z"
        stroke="currentColor"
      />
      <path
        d="M14.5833 5.42527L14.5916 5.41602"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InstagramIcon;
