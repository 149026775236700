import { useParams } from 'react-router-dom';

import { useCampaign } from '@/hooks/useAdNetwork/internal';
import useCampaignPaymentRequestCreate from '@/hooks/useAdNetwork/internal/useCampaignPaymentRequestCreate';
import { PageHeading } from '@/routes/ad_network/_components/PageHeading';

import { Form } from './_components/Form';

const CampaignPaymentRequestsNew = () => {
  const { campaign_id: campaignId } = useParams<'campaign_id'>() as { campaign_id: string };
  const { data: campaign } = useCampaign({ id: campaignId });
  const onSubmitMutation = useCampaignPaymentRequestCreate({ campaignId });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="../..">{campaign?.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="..">Payment Requests</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb>New</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <Form onSubmitMutation={onSubmitMutation} />
    </>
  );
};

export default CampaignPaymentRequestsNew;
