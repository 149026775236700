import { useUpdateWebTemplate } from '@/hooks';
import { WebTheme } from '@/interfaces/web_template';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import SwitchInput from '@/routes/website_builder/_components/Form/SwitchInput';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';

const GlobalNavigationOptions = () => {
  const { handleSaved, webTemplate } = usePageContext();

  const webTemplateMutation = useUpdateWebTemplate(() => handleSaved());
  const webTheme: WebTheme | any | undefined = webTemplate?.web_theme_preview;
  const webThemWithDefaults: WebTheme | undefined = webTemplate?.web_theme_preview_with_defaults;

  const handleUpdateWebTheme = (key: string, value: string | boolean) => {
    webTemplateMutation.mutate({
      web_theme_preview: {
        ...(webTheme as WebTheme),
        [key]: value,
      },
    });
  };

  return (
    <PropertiesGrouping label="Navigation Menu Options" description="Customize your global navigation menu.">
      <div className="flex flex-col">
        <SwitchInput
          key="navbar-global-login"
          name="navbar-login-button-enabled"
          enabled={webThemWithDefaults?.navbar_login_button_desktop_enabled || false}
          onSave={(value: boolean) => handleUpdateWebTheme('navbar_login_button_desktop_enabled', value)}
          label="Show login button on desktop navbar"
        />
      </div>
    </PropertiesGrouping>
  );
};

export default GlobalNavigationOptions;
