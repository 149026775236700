import {
  AdNetworkPaymentRequest,
  AdNetworkPaymentRequestStatus,
} from '@/interfaces/ad_network/internal/payment_request';

import StatusIndicator from './StatusIndicator';

const PaymentRequestStatusIndicator = ({ paymentRequest }: { paymentRequest: AdNetworkPaymentRequest }) => {
  const color = {
    [AdNetworkPaymentRequestStatus.ARCHIVED]: 'gray',
    [AdNetworkPaymentRequestStatus.PAID]: 'green',
    [AdNetworkPaymentRequestStatus.SCHEDULED]: 'blue',
    [AdNetworkPaymentRequestStatus.SENT]: 'yellow',
  }[paymentRequest.status] as 'blue' | 'gray' | 'green' | 'red' | 'yellow';

  return <StatusIndicator color={color} label={paymentRequest.status} />;
};

export default PaymentRequestStatusIndicator;
