import { useCallback, useEffect, useMemo, useState } from 'react';
import { PriceInterval, Tier, TierStatus } from '@shared/dream-components';
import { Editor, NodeViewProps } from '@tiptap/core';

import { useSettings } from '@/context/settings-context';
import usePricingTiers from '@/hooks/useDreamBuilder/usePricingTiers';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { Button } from '../../../../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Text } from '../../../../UI/Text';
import { tiers } from '../../../dummyData/pricing';

import SelectedTierView from './SelectedTierView';
import SortableTiersList from './SortableTiersList';

const title = 'Select tiers to display';

const TiersSelectionModal = ({
  editor,
  node,
  isOpen,
  setIsModalOpen,
  onClose,
  getPos,
}: NodeViewProps & {
  editor: Editor;
  isOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
}) => {
  const [tiersInState, setTiersInState] = useState<Tier[]>([]);
  const [selectedTier, setSelectedTier] = useState<Tier | null>(null);

  const publicationId = useCurrentPublicationId();
  const FREE_TIER: Tier = useMemo(
    () => ({
      id: 'free',
      name: 'Free Tier',
      enabled: false,
      publication_id: publicationId,
      status: TierStatus.ACTIVE,
      is_default: false,
      prices: [
        {
          id: 'free',
          amount_cents: 0,
          original_amount_cents: 0,
          currency: 'usd',
          interval: PriceInterval.MONTH,
          interval_display: 'monthly ',
          denominator: '',
          cta: 'free cta',
          enabled: true,
          features: [],
        },
      ],
    }),
    [publicationId]
  );

  const reset = () => {
    setTiersInState([]);
    setSelectedTier(null);
  };

  const { settings } = useSettings();
  const { data: tiersData, isLoading } = usePricingTiers({ enabled: true });
  const hasPaidTiers = settings?.premium_subscriptions;
  const showEmptyState = !hasPaidTiers || tiersData?.tiers.length === 0;

  const handleSubmit = useCallback(() => {
    editor?.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', false);
      const isAnyTierVisible = tiersInState.some((tier) => tier.enabled);
      if (isAnyTierVisible) {
        tr.setNodeAttribute(getPos(), 'hasFakeData', false);
        tr.setNodeAttribute(getPos(), 'data', tiersInState);
      } else {
        tr.setNodeAttribute(getPos(), 'hasFakeData', true);
        tr.setNodeAttribute(getPos(), 'data', tiers);
      }
      return true;
    });

    setIsModalOpen(false);
  }, [editor?.commands, setIsModalOpen, getPos, tiersInState]);

  const handleChangeTier = (changedTier: Tier) => {
    const newTiers = [...tiersInState];
    const indexOfChangedTier = tiersInState.findIndex((tier) => tier.id === changedTier.id);
    newTiers[indexOfChangedTier] = {
      ...changedTier,
    };

    setSelectedTier(changedTier);
    setTiersInState(newTiers);
  };

  const handleClickTier = (tier: Tier) => {
    setSelectedTier(tier);
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  useEffect(() => {
    if (tiersData && tiersData.tiers.length > 0 && node.attrs.hasFakeData) {
      setSelectedTier(tiersData.tiers[0]);
      setTiersInState([
        ...tiersData.tiers.map((tier) => ({
          ...tier,
          enabled: false,
        })),
        FREE_TIER,
      ]);
    } else if (!node.attrs.hasFakeData && node.attrs.data.length > 0) {
      setSelectedTier(node.attrs.data[0]);
      setTiersInState(node.attrs.data);
    }
  }, [FREE_TIER, node.attrs.data, node.attrs.hasFakeData, tiersData]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) {
          onClose();
        }
      }}
    >
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col justify-between overflow-hidden p-0 gap-0">
        <DialogHeader className="grow-0">
          <DialogTitle>
            <div className="flex items-center gap-2 p-4">
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                {title}
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        {!showEmptyState && !isLoading && (
          <div className="flex flex-row p-4 grow h-auto overflow-hidden">
            <div className="h-full w-1/3 border-r border-surface-200 pr-2">
              <ul className="flex flex-col gap-y-1">
                <SortableTiersList
                  onClick={handleClickTier}
                  tiersInState={tiersInState}
                  setTiersInState={setTiersInState}
                  selectedTier={selectedTier}
                />
              </ul>
            </div>
            <div className="w-2/3 px-4 h-full overflow-y-scroll">
              {selectedTier && (
                <SelectedTierView key={selectedTier.id} tier={selectedTier} onChange={handleChangeTier} />
              )}
            </div>
          </div>
        )}
        {showEmptyState && !isLoading && (
          <div className="flex flex-col p-4 grow h-auto overflow-hidden justify-center items-center">
            <Text variant="primary">Configure Paid Subscription Tiers</Text>
            <Text variant="primary" size="sm">
              Click{' '}
              <a href={`${window.location.origin}/monetize/subscriptions/paid_tiers`} target="_blank" rel="noreferrer">
                <Text variant="accent" size="sm" className="underline">
                  here
                </Text>
              </a>{' '}
              to customize plans and pricing for your paid subscriptions.
            </Text>
          </div>
        )}

        <DialogFooter className="flex justify-between items-center p-4 bg-white border-t border-wb-primary grow-0">
          <div className="flex items-center gap-2 justify-end w-full">
            <div className="flex gap-2 w-full justify-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit} isDisabled={showEmptyState}>
                Save
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TiersSelectionModal;
