export const APP_LAYOUT_BODY_CONTAINER_ID = 'app-layout-body-container';
export const SETTINGS_BODY_CONTAINER_ID = 'settings-body-container';

// Both the values below should be in sync as `TOP_BAR_FROM_TOP_CLASS_NAME`
// is used to keep table headers sticky from top right under the top bar
export const TOP_BAR_HEIGHT_CLASS_NAME = 'h-[54px]';
export const TOP_BAR_FROM_TOP_CLASS_NAME = 'top-[54px]';

export const PUBLICATION_DROPDOWN_HEIGHT_CLASS_NAME = 'h-[54px]';
export const BODY_HEIGHT_WITH_TOP_BAR = 'h-[calc(100vh_-_54px)]';


export const SETTINGS_SIDEBAR_WIDTH = 'w-[304px] sm:w-[280px]';