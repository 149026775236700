import { useQuery } from 'react-query';

import { Paywall } from '@/interfaces/paywall';

import api from '../../services/swarm';

interface Props {
  publicationId: string;
  paywallId: string;
  defaultPremiumPaywall: boolean;
}

export default function usePaywall({ publicationId, paywallId, defaultPremiumPaywall }: Props) {
  const fetchPaywall = () =>
    api
      .get(`/paywalls/${paywallId}`, {
        params: {
          publication_id: publicationId,
          default_premium_paywall: defaultPremiumPaywall,
        },
      })
      .then((res) => res.data);

  return useQuery<Paywall>({
    queryKey: [defaultPremiumPaywall, 'publications', publicationId, 'paywall', paywallId],
    queryFn: fetchPaywall,
  });
}
