import React from 'react';

import { FileSelect, Input } from '../../../../../../components/Form';

interface Props {
  url: string;
  file: File | undefined;
  onUrlChange: (str: string) => void;
  onFileChange: (file: File) => void;
}

const Substack: React.FunctionComponent<Props> = ({ url, onUrlChange, file, onFileChange }: Props) => (
  <div>
    <p className="font-medium">Step 1</p>
    <p className="mt-2 mb-4 text-sm text-gray-500">
      Input your publication URL below. This will be used to pull content and information about your publication during
      the import process.
    </p>
    <Input
      name="url"
      labelText="Publication URL"
      value={url}
      placeholderText="https://www.publication.substack.com"
      onChange={(e) => onUrlChange(e.target.value)}
    />

    <p className="mt-12 font-medium">Step 2 (Required if you have premium content)</p>
    <p className="mt-2 text-sm text-gray-500">
      If you have premium content hidden behind a paywall, you will need to upload your exported content from substack
      here. Otherwise, this step can be skipped and we can pull your content just using the URL you entered above.
    </p>
    <p className="mt-2 mb-4 text-sm text-gray-500">
      To export your content from Substack, go to <b>Writer Dashboard</b> &gt; <b>Settings</b> &gt;{' '}
      <b>Export your data</b>. Do not modify or unzip the file before uploading.
    </p>
    <FileSelect
      name="file"
      labelText="Publication Content Upload"
      onFileSelect={onFileChange}
      file={file}
      accept=".zip"
    />
  </div>
);

export default Substack;
