import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { UpdatePublicationSubscriberPreferenceParams } from '@/interfaces/subscribers_preferences';
import api from '@/services/swarm';

const useUpdateSubscriberPreference = (onSuccess?: (response: any) => void) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    (params: UpdatePublicationSubscriberPreferenceParams) =>
      api.patch(`/publication_subscriber_preferences/${params.id}`, {
        publication_id: currentPublicationId,
        subscriber_preference: params,
      }),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        queryClient.invalidateQueries([currentPublicationId, 'subscriber_preferences']);
        toast.success('Preference updated successfully!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Could not update preference.');
      },
    }
  );
};

export default useUpdateSubscriberPreference;
