import { useQuery } from 'react-query';

import { Poll } from '../../interfaces/poll';
import api from '../../services/swarm';

export default function usePoll(publicationId: string, id?: string, keepPreviousData: boolean = true, retry?: boolean) {
  const fetchPoll = () =>
    api
      .get(`/polls/${id}`, {
        params: {
          publication_id: publicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<Poll>(['publications', publicationId, 'polls', id], fetchPoll, {
    keepPreviousData,
    enabled: !!id,
    retry,
  });
}