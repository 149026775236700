import { Typography } from '@/components/Typography';

const PoweredByBeehiiv = () => {
  return (
    <div className="flex flex-row gap-x-2 items-center w-fit border border-[#CACACA] py-2 px-3 rounded-sm">
      <div className="w-4 h-4">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.8274 8.25584H2.18687C1.89033 8.25584 1.6482 8.01158 1.6482 7.71344V6.88624C1.6482 5.95024 2.40233 5.19131 3.33193 5.19131H12.669C13.5986 5.19131 14.3527 5.95024 14.3527 6.88571V7.71238C14.3555 7.78293 14.3439 7.8533 14.3187 7.91925C14.2935 7.9852 14.2552 8.04536 14.2061 8.0961C14.157 8.14683 14.0981 8.1871 14.033 8.21447C13.968 8.24183 13.898 8.25572 13.8274 8.25531V8.25584ZM11.3741 4.29691H4.63807C4.49132 4.29579 4.35095 4.23677 4.24748 4.13271C4.14401 4.02864 4.08581 3.88793 4.08553 3.74118C4.08553 2.35824 5.20393 1.23291 6.5778 1.23291H9.43433C10.8082 1.23291 11.9266 2.35824 11.9266 3.74118C11.9266 4.05264 11.6839 4.29691 11.3741 4.29691ZM14.3661 9.15024H1.64767C0.811933 9.15024 0.125 9.84198 0.125 10.6825C0.125 11.523 0.811933 12.2142 1.64767 12.2142H6.16073C6.32233 11.3465 7.077 10.6825 7.99327 10.6825C8.43089 10.6837 8.85444 10.8373 9.19118 11.1168C9.52792 11.3963 9.75683 11.7843 9.8386 12.2142H14.3522C15.1879 12.2142 15.8749 11.523 15.8749 10.6825C15.8749 9.84198 15.2018 9.15024 14.3661 9.15024ZM13.8119 13.1092H9.86473V16.1732H12.8285C13.6642 16.1732 14.3511 15.4814 14.3511 14.6409V13.6649C14.3537 13.5927 14.3417 13.5207 14.3157 13.4532C14.2898 13.3857 14.2505 13.3242 14.2002 13.2723C14.1499 13.2204 14.0896 13.1792 14.023 13.1511C13.9564 13.1231 13.8842 13.1088 13.8119 13.1092ZM2.1858 13.1092C1.88927 13.1092 1.63327 13.3529 1.63327 13.6649V14.6409C1.63327 15.4814 2.3202 16.1732 3.15593 16.1732H6.11967V13.1092H2.1858Z"
            fill="#0B0D2A"
          />
        </svg>
      </div>
      <Typography token="font-normal/text/xs" className="text-[#767676]">
        Powered by beehiiv
      </Typography>
    </div>
  );
};

export default PoweredByBeehiiv;
