import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const Styled = {
  Container: styled.span<{ $color?: string }>`
    align-items: center;
    background: #fff;
    border: 2px solid ${({ $color }) => $color || 'red'};
    border-radius: 100%;
    display: flex;
    height: 1.875rem;
    justify-content: center;
    overflow: hidden;
    padding: 2px;
    cursor: default;
    width: 1.875rem;
  `,
  TextWrapper: styled.span<{ $color?: string }>`
    align-items: center;
    background: ${colors.black[8]};
    border-radius: 100%;
    color: ${colors.black[3]};
    display: flex;
    height: 100%;
    flex: 1;
    font-size: 0.75rem;
    font-weight: 600;
    justify-content: center;
  `,
  ImageWrapper: styled.span<{ $color?: string }>``,
};

export default Styled;
