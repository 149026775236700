import { useCallback, useMemo } from 'react';
import { sticky } from 'tippy.js';

import {
  Boost,
  EmbedInput,
  PaywallBreak,
  Recommendation,
  ReferralProgram,
  ServiceEmbed,
  SubscriberBreak,
} from '../../../extensions';
import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Toolbar } from '../../ui/Toolbar';
import { Tooltip } from '../../ui/Tooltip';
import { BubbleMenu as BaseBubbleMenu } from '../BubbleMenu';
import { MenuProps } from '../types';
import { getRenderContainer } from '../utils/getRenderContainer';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const RemoveBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const nodes = useMemo(() => {
    return [
      PaywallBreak.name,
      ServiceEmbed.name,
      EmbedInput.name,
      Recommendation.name,
      ReferralProgram.name,
      SubscriberBreak.name,
      Boost.name,
    ];
  }, []);

  const { selection } = editor.state;

  const activeNodeName = useMemo(() => {
    return nodes.find((type) => {
      return editor.isActive(type) && !!selection;
    });
  }, [editor, nodes, selection]);

  const getReferenceClientRect = useCallback(() => {
    const nodeName = nodes.find((type) => {
      return editor.isActive(type);
    });
    const container = getRenderContainer(editor, `node-${nodeName}`);

    return container?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);
  }, [editor, nodes]);

  const shouldShow = useCallback(() => {
    const isRemovableBlock = !!nodes.find((type) => {
      return editor.isActive(type);
    });

    return isRemovableBlock;
  }, [editor, nodes]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="removeBlockMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()}>
        <Tooltip title="Remove node">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $leftSlot={<Icon name="Trash" />}
            onClick={() => {
              if (activeNodeName) {
                editor.chain().focus().deleteNode(activeNodeName).deleteSelection().run();
              }
            }}
          />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default RemoveBlockMenu;
