import { PropsWithChildren } from 'react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

type Props = PropsWithChildren<{
  bgColor: string;
}>;

const ChartPill = ({ children, bgColor }: Props) => {
  return (
    <div className={cx('rounded-full py-1 px-2 flex', bgColor || 'bg-action-secondary-200')}>
      <Typography weight="semibold" size="sm" as="span" colorWeight="900">
        <div className="flex justify-center items-center">
          <span className="h-1.5 w-1.5 rounded-full bg-white mr-1.5" />
          {children}
        </div>
      </Typography>
    </div>
  );
};

export default ChartPill;
