import { useCallback, useMemo } from 'react';
import { CaretDoubleRight } from '@phosphor-icons/react';

import { useSignupFlows } from '@/hooks/useSignupFlows';

import { PopoverHelper, PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';

type Props = AttributeSettingProps & {};

export const SignupFlowSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const { data } = useSignupFlows();

  const signupFlowOptions = useMemo(() => {
    const signupFlows = data?.pages.flatMap((page) => page.signup_flows) || [];
    return [
      { label: 'None', value: '' },
      ...signupFlows.map((signupFlow) => ({
        label: signupFlow.name,
        value: signupFlow.id,
      })),
    ];
  }, [data]);

  const textValue =
    signupFlowOptions.find((option) => option.value === activeNodeAttributes.signupFlowId)?.label || 'None';

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'signupFlowId', value);
        return true;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos]
  );

  return (
    <PopoverHelper title="Signup Flow" text={textValue} popoverTitle="Signup Flow" Icon={CaretDoubleRight}>
      <div className="flex flex-col gap-2">
        {signupFlowOptions.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={false}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};
