import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import styled from 'styled-components';

import { SvgLogo } from '@/components/SvgLogo';
import { Typography, TypographyStack } from '@/components/Typography';

type Props = PropsWithChildren<{
  title: string;
  subtitle: React.ReactNode;
  maxWidth?: string;
}>;

const Layout = styled.div.attrs({
  className: 'min-h-screen w-full flex flex-row justify-center pt-14 pb-10 px-10 bg-cover bg-center',
})`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255), rgba(255, 255, 255), rgba(255, 255, 255, 0.73)),
    url('https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=90/static_assets/onboarding/background.png');
`;

const LogoWrapper: React.FC<Props> = ({ title, subtitle, children, maxWidth = 'max-w-sm' }) => (
  <div className="flex flex-row items-center">
    <div className={cx(maxWidth, 'flex flex-col w-full items-center')}>
      <div className="bg-black flex justify-center items-center rounded mb-6">
        <SvgLogo className="h-8 m-4" stopColorOverride="#FFFFFF" />
      </div>

      <TypographyStack gap="3" className="text-center mb-10">
        <Typography weight="semibold" size="2xl" as="h4">
          {title}
        </Typography>

        {subtitle}
      </TypographyStack>

      {children}
    </div>
  </div>
);

export { Layout, LogoWrapper };
