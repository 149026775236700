import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { PostTheme } from '../pages/ThemeEditor/types';
import api from '../services/swarm';

const useUpdatePostThemePreview = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (theme: PostTheme) =>
      api
        .post(`/post_theme/preview`, {
          publication_id: currentPublicationId,
          post_theme: theme,
        })
        .then((res) => res.data),
    {
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useUpdatePostThemePreview;
