import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Order } from '../interfaces/general';
import { PostClick } from '../interfaces/post';
import api from '../services/swarm';

interface ApiResponse {
  clicks: PostClick[];
}

interface Arguments {
  postId: string;
  direction?: Order;
  staleTime?: number | undefined;
  enabled?: boolean;
  orderBy?: string;
}

export default function usePostClickMap({ postId, direction, staleTime = 30000, enabled = true, orderBy }: Arguments) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetch = () =>
    api
      .get(`/posts/${postId}/clickmap`, {
        params: {
          publication_id: currentPublicationId,
          direction,
          order_by: orderBy,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([postId, 'posts', 'clickmap', direction], fetch, {
    enabled: enabled && Boolean(postId),
    staleTime,
    keepPreviousData: true,
  });
}
