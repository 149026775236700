import { APP_LAYOUT_BODY_CONTAINER_ID } from '@/components/Layout/AppLayout/constants';
import PublicationPremiumSettings from '@/routes/settings/publication/premium';
import { ScrollOnMount } from '@/ui/ScrollOnMount';

import PageWrapper from '../_components/PageWrapper';

const MonetizeSubscriptionsPaidTiers = () => {
  return (
    <PageWrapper>
      <ScrollOnMount elementId={APP_LAYOUT_BODY_CONTAINER_ID}>
        <PublicationPremiumSettings />
      </ScrollOnMount>
    </PageWrapper>
  );
};

export default MonetizeSubscriptionsPaidTiers;
