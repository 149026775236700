import { useMemo } from 'react';

import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Option } from '@/interfaces/general';
import { Dropdown } from '@/ui/Dropdown';

interface FormIdSelectProps {
  formId: string;
  onChange: (name: string, value: string) => void;
  className?: string;
}

const FormIdSelect = ({ formId, onChange, className }: FormIdSelectProps) => {
  const publicationId = useCurrentPublicationId();
  const forms = useOptions(publicationId, 'forms');
  const { data: formsData } = forms;

  const nameOptions = useMemo(() => {
    const options: Option[] = [{ label: 'Any Survey', value: 'any' }];

    if (formsData?.options?.length > 0) {
      options.push({
        label: 'Surveys',
        value: '',
        isOptGroup: true,
      });

      formsData.options.forEach((option: { id: string; title: string }) => {
        options.push({
          label: option.title,
          value: option.id,
        });
      });
    }

    return options;
  }, [formsData]);

  return (
    <Dropdown
      name="form_id"
      value={formId}
      onSelect={onChange}
      options={nameOptions}
      placeholderText="Select a Survey"
      className={className}
    />
  );
};

export default FormIdSelect;
