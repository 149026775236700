import React from 'react';
import { mergeAttributes } from '@tiptap/core';
import { CodeBlockLowlight, CodeBlockLowlightOptions } from '@tiptap/extension-code-block-lowlight';
import { ReactNodeViewRenderer } from '@tiptap/react';
import html from 'highlight.js/lib/languages/xml';
import { lowlight } from 'lowlight/lib/core';

import { UpgradeIntentPlaceholder } from '../../components/ui/UpgradeIntentPlaceholder';

import { HtmlSnippetView } from './views';

lowlight.registerLanguage('html', html);

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    htmlSnippet: {
      setHtmlSnippet: (attributes?: { language: string }) => ReturnType;
      toggleHtmlSnippet: (attributes?: { language: string }) => ReturnType;
    };
  }
}

interface Options extends CodeBlockLowlightOptions {
  enabled: boolean;
}

export const HtmlSnippet = CodeBlockLowlight.extend<Options>({
  name: 'htmlSnippet',

  draggable: true,

  content() {
    return this.options.enabled ? 'text*' : undefined;
  },

  addOptions() {
    return {
      ...this.parent?.(),
      enabled: false,
    };
  },

  parseHTML() {
    return [
      {
        tag: `pre[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    if (!this.options.enabled) {
      return ['div'];
    }

    return [
      'pre',
      mergeAttributes({ ...this.options.HTMLAttributes, HTMLAttributes, 'data-type': this.name }),
      [
        'code',
        {
          class: node.attrs.language ? this.options.languageClassPrefix + node.attrs.language : null,
        },
        0,
      ],
    ];
  },

  addCommands() {
    return {
      setHtmlSnippet:
        (attributes) =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attributes,
            })
            .run(),
      // commands.setNode(this.name, attributes),
      toggleHtmlSnippet:
        (attributes) =>
        ({ commands }) =>
          commands.toggleNode(this.name, 'paragraph', attributes),
    };
  },

  addKeyboardShortcuts() {
    return {
      // Remove html snippet when at start of document or if it is empty
      Backspace: () => {
        const { empty, $anchor } = this.editor.state.selection;
        const isAtStart = $anchor.pos === 1;

        if (!empty || $anchor.parent.type.name !== this.name) {
          return false;
        }

        if (isAtStart || !$anchor.parent.textContent.length) {
          return this.editor.commands.clearNodes();
        }

        return false;
      },

      // Exit node on triple enter
      Enter: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;
        const endsWithDoubleNewline = $from.parent.textContent.endsWith('\n\n');

        if (!isAtEnd || !endsWithDoubleNewline) {
          return false;
        }

        return editor
          .chain()
          .command(({ tr }) => {
            tr.delete($from.pos - 2, $from.pos);

            return true;
          })
          .exitCode()
          .run();
      },

      // Exit node on arrow down
      ArrowDown: ({ editor }) => {
        const { state } = editor;
        const { selection, doc } = state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (!isAtEnd) {
          return false;
        }

        const after = $from.after();

        if (after === undefined) {
          return false;
        }

        const nodeAfter = doc.nodeAt(after);

        if (nodeAfter) {
          return false;
        }

        return editor.commands.exitCode();
      },
    };
  },

  addInputRules() {
    return [];
  },

  addNodeView() {
    if (!this.options.enabled) {
      return ReactNodeViewRenderer(
        () => (
          <UpgradeIntentPlaceholder
            description="You need to upgrade to access HTML Snippets."
            url="https://www.beehiiv.com/newsletter-solutions/publish"
            iconName="HtmlSnippet"
          />
        ),
        { className: 'upgrade-intent' }
      );
    }

    return ReactNodeViewRenderer(HtmlSnippetView);
  },
}).configure({
  lowlight,
  defaultLanguage: 'html',
  languageClassPrefix: 'language-',
});

export default HtmlSnippet;
