import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import { PieChartDatum as Datum } from '@/components/charts';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

export interface Breakdown {
  num_subscribers_with_referrals: number;
  num_subscribers_without_referrals: number;
}

const transformBreakdownToData = (breakdown: Breakdown): Datum[] => [
  {
    label: 'With Referrals',
    value: breakdown.num_subscribers_with_referrals,
  },
  {
    label: 'Without Referrals',
    value: breakdown.num_subscribers_without_referrals,
  },
];

const checkIfEmpty = (breakdown: Breakdown): boolean => {
  if (breakdown.num_subscribers_with_referrals === 0 && breakdown.num_subscribers_without_referrals === 0) {
    return true;
  }
  return false;
};

interface Props {
  children: (subscriberBreakdown: Datum[] | undefined, isLoading: boolean, isEmpty: boolean) => React.ReactNode;
}

const SubscriberReferralChartContainer: FC<Props> = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [subscriberBreakdown, setSubscriberBreakdown] = useState<Datum[]>();
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBreakdown = useCallback(() => {
    if (currentPublicationId) {
      const params = {
        publication_id: currentPublicationId,
      };

      setIsLoading(true);
      api
        .get('/referral_program/metrics/subscriber_breakdown', { params })
        .then((res) => {
          const data = transformBreakdownToData(res.data);
          setSubscriberBreakdown(data);
          setIsEmpty(checkIfEmpty(res.data));
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPublicationId]);

  useEffect(() => {
    fetchBreakdown();
  }, [fetchBreakdown]);

  return <>{children(subscriberBreakdown, isLoading, isEmpty)}</>;
};

export default SubscriberReferralChartContainer;
