import { HomeIcon } from '@heroicons/react/20/solid';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { useFormContext } from '@/pages/ReferralProgram/Configure/FormContext';

const FormBreadCrumbs = () => {
  const { reward, milestone } = useFormContext();
  return (
    <Breadcrumbs>
      <Breadcrumbs.Item to="/">
        <HomeIcon className="w-4 h-4" />
      </Breadcrumbs.Item>
      <Breadcrumbs.Item to="/referral_program">Referral Program</Breadcrumbs.Item>
      {milestone.id && reward.id ? (
        <Breadcrumbs.Item to={`/referral_program/milestones/${milestone.id}/edit`}>{reward.name}</Breadcrumbs.Item>
      ) : (
        <Breadcrumbs.Item to="/referral_program/milestones/new">New</Breadcrumbs.Item>
      )}
    </Breadcrumbs>
  );
};

export default FormBreadCrumbs;
