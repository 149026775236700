import { DataTypes, NameTypes } from './types';

interface IStringData {
  [key: string]: string;
}

interface NameOption {
  label: string;
  value: string;
  dataType?: DataTypes;
  type: NameTypes;
  isOptGroup?: boolean;
}

export const subscriptionAttributeGroupLabel: NameOption = {
  label: 'Subscription',
  isOptGroup: true,
  value: '',
  type: NameTypes.ATTRIBUTE,
};
export const locationAttributeGroupLabel: NameOption = {
  label: 'Location',
  isOptGroup: true,
  value: '',
  type: NameTypes.ATTRIBUTE,
};
export const acquisitionAttributeGroupLabel: NameOption = {
  label: 'Acquisition',
  isOptGroup: true,
  value: '',
  type: NameTypes.ATTRIBUTE,
};

export const emailMeasurementGroupLabel: NameOption = {
  label: 'Email',
  isOptGroup: true,
  value: '',
  type: NameTypes.MEASURE,
};
export const mailboxRejectionsMeasureGroupLabel: NameOption = {
  label: 'Mailbox Rejections',
  isOptGroup: true,
  value: '',
  type: NameTypes.MEASURE,
};

export const mailAttributeOption: NameOption = {
  label: 'Email',
  value: 'email',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const subscriberStatusAttributeOption: NameOption = {
  label: 'Status',
  value: 'status',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const tierAttributeOption: NameOption = {
  label: 'Tier',
  value: 'tier',
  dataType: DataTypes.TIER,
  type: NameTypes.ATTRIBUTE,
};
export const nameAttributeOption: NameOption = {
  label: 'Signup Date',
  value: 'signup_date',
  dataType: DataTypes.DATETIME,
  type: NameTypes.ATTRIBUTE,
};
export const unsubscribeDateAttributeOption: NameOption = {
  label: 'Unsubscribe Date',
  value: 'unsub_date',
  dataType: DataTypes.DATETIME,
  type: NameTypes.ATTRIBUTE,
};
export const tagsAttributeOption: NameOption = {
  label: 'Tags',
  value: 'tags',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const customFieldAttributeOption: NameOption = {
  label: 'Custom Field',
  value: 'custom_field',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const subscriberTagAttributeOption: NameOption = {
  label: 'Subscriber Tags',
  value: 'subscriber_tag',
  dataType: DataTypes.SUBSCRIBER_TAG,
  type: NameTypes.ATTRIBUTE,
};

export const subscriptionAttributeOptions: NameOption[] = [
  mailAttributeOption,
  subscriberStatusAttributeOption,
  tierAttributeOption,
  nameAttributeOption,
  unsubscribeDateAttributeOption,
  customFieldAttributeOption,
  subscriberTagAttributeOption,
];

export const cityLocationAttributeOption: NameOption = {
  label: 'City',
  value: 'latest_location/city',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const stateLocationAttributeOption: NameOption = {
  label: 'State',
  value: 'latest_location/state',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const countryLocationAttributeOption: NameOption = {
  label: 'Country',
  value: 'latest_location/country',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const locationAttributeOptions: NameOption[] = [
  cityLocationAttributeOption,
  stateLocationAttributeOption,
  countryLocationAttributeOption,
];

export const channelAcquisitionAttributeOption: NameOption = {
  label: 'Channel',
  value: 'channel',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const utmSourceAcquisitionAttributeOption: NameOption = {
  label: 'UTM Source',
  value: 'source',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const utmMediumAcquisitionAttributeOption: NameOption = {
  label: 'UTM Medium',
  value: 'medium',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const utmCampaignAcquisitionAttributeOption: NameOption = {
  label: 'UTM Campaign',
  value: 'campaign',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const referringUrlAcquisitionAttributeOption: NameOption = {
  label: 'Referring URL',
  value: 'referring_url',
  dataType: DataTypes.STRING,
  type: NameTypes.ATTRIBUTE,
};
export const embedSourceAcquisitionAttributeOption: NameOption = {
  label: 'Embed Source',
  value: 'external_embed',
  dataType: DataTypes.EXTERNAL_EMBED,
  type: NameTypes.ATTRIBUTE,
};
export const acquisitionAttributeOptions: NameOption[] = [
  channelAcquisitionAttributeOption,
  utmSourceAcquisitionAttributeOption,
  utmMediumAcquisitionAttributeOption,
  utmCampaignAcquisitionAttributeOption,
  referringUrlAcquisitionAttributeOption,
  embedSourceAcquisitionAttributeOption,
];

export const attributeOptions: NameOption[] = [
  subscriptionAttributeGroupLabel,
  ...subscriptionAttributeOptions,
  locationAttributeGroupLabel,
  ...locationAttributeOptions,
  acquisitionAttributeGroupLabel,
  ...acquisitionAttributeOptions,
];

export const automationOption: NameOption = {
  label: 'Enrollment',
  value: 'enrollment',
  dataType: DataTypes.AUTOMATION_ACTION,
  type: NameTypes.AUTOMATION_ACTION,
};
export const automationActionOptions: NameOption[] = [automationOption];

export const uniqueOpensOption: NameOption = {
  label: 'Unique Opens',
  value: 'unique_opens',
  dataType: DataTypes.INTEGER,
  type: NameTypes.MEASURE,
};
export const openRateOption: NameOption = {
  label: 'Open Rate',
  value: 'open_rate',
  dataType: DataTypes.PERCENTAGE,
  type: NameTypes.MEASURE,
};
export const uniqueClicksOption: NameOption = {
  label: 'Unique Clicks',
  value: 'unique_clicks',
  dataType: DataTypes.INTEGER,
  type: NameTypes.MEASURE,
};
export const ctrOption: NameOption = {
  label: 'Click Through Rate',
  value: 'click_through_rate',
  dataType: DataTypes.PERCENTAGE,
  type: NameTypes.MEASURE,
};
export const uniqueSendsOption: NameOption = {
  label: 'Unique Sends',
  value: 'unique_sends',
  dataType: DataTypes.INTEGER,
  type: NameTypes.MEASURE,
};
export const daysWithoutOpensOption: NameOption = {
  label: 'Days without Opens',
  value: 'days_without_opens',
  dataType: DataTypes.INTEGER,
  type: NameTypes.MEASURE,
};
export const daysWithoutClicksOption: NameOption = {
  label: 'Days without Clicks',
  value: 'days_without_clicks',
  dataType: DataTypes.INTEGER,
  type: NameTypes.MEASURE,
};
export const emailMeasurementOptions: NameOption[] = [
  uniqueOpensOption,
  openRateOption,
  uniqueClicksOption,
  ctrOption,
  uniqueSendsOption,
];

export const deferredOption: NameOption = {
  label: 'Deferred',
  value: 'deferred',
  dataType: DataTypes.INTEGER,
  type: NameTypes.MEASURE,
};
export const bouncedOption: NameOption = {
  label: 'Bounced',
  value: 'bounce',
  dataType: DataTypes.INTEGER,
  type: NameTypes.MEASURE,
};
export const mailboxRejectionsMeasureOptions: NameOption[] = [deferredOption, bouncedOption];

export const referralCountOption: NameOption = {
  label: 'Referral Count',
  value: 'referral_count',
  dataType: DataTypes.INTEGER,
  type: NameTypes.MEASURE,
};

export const measureOptions: NameOption[] = [
  emailMeasurementGroupLabel,
  ...emailMeasurementOptions,
  mailboxRejectionsMeasureGroupLabel,
  ...mailboxRejectionsMeasureOptions,
];

export const anyEmailOption: NameOption = {
  label: 'Any email',
  value: 'all',
  dataType: DataTypes.EMAIL_ACTION,
  type: NameTypes.EMAIL_ACTION,
};
export const automationEmailOption: NameOption = {
  label: 'Automation email',
  value: 'automation_step_message',
  dataType: DataTypes.EMAIL_ACTION,
  type: NameTypes.EMAIL_ACTION,
};
export const emailToASegmentOption: NameOption = {
  label: 'Email to a segment',
  value: 'email_blast',
  dataType: DataTypes.EMAIL_ACTION,
  type: NameTypes.EMAIL_ACTION,
};
export const postOption: NameOption = {
  label: 'Post',
  value: 'post',
  dataType: DataTypes.EMAIL_ACTION,
  type: NameTypes.EMAIL_ACTION,
};
export const welcomeEmailOption: NameOption = {
  label: 'Welcome Email',
  value: 'welcome_email',
  dataType: DataTypes.EMAIL_ACTION,
  type: NameTypes.EMAIL_ACTION,
};
export const optInEmailOption: NameOption = {
  label: 'Opt-In Email',
  value: 'opt_in_email',
  dataType: DataTypes.EMAIL_ACTION,
  type: NameTypes.EMAIL_ACTION,
};
export const emailActionOptions: NameOption[] = [
  anyEmailOption,
  automationEmailOption,
  emailToASegmentOption,
  postOption,
  welcomeEmailOption,
  optInEmailOption,
];

export const referralCountBehaviorOption: NameOption = {
  label: 'Referral Count',
  value: 'referral_count',
  dataType: DataTypes.BEHAVIOR_ACTION,
  type: NameTypes.BEHAVIOR_ACTION,
};
export const pollResponseBehaviorOption: NameOption = {
  label: 'Poll Response',
  value: 'poll_response',
  dataType: DataTypes.BEHAVIOR_ACTION,
  type: NameTypes.BEHAVIOR_ACTION,
};
export const surveyResponseBehaviorOption: NameOption = {
  label: 'Survey Response',
  value: 'survey_response',
  dataType: DataTypes.BEHAVIOR_ACTION,
  type: NameTypes.BEHAVIOR_ACTION,
};
export const behaviorActionOptions: NameOption[] = [
  referralCountBehaviorOption,
  pollResponseBehaviorOption,
  surveyResponseBehaviorOption,
];

const allOptions = [
  ...attributeOptions,
  ...measureOptions,
  ...emailActionOptions,
  ...automationActionOptions,
  ...behaviorActionOptions,
];

const nameDataTypeByValue: IStringData = allOptions.reduce((acc, nameOption: NameOption) => {
  const { value, dataType } = nameOption;
  return { ...acc, [value]: dataType };
}, {});

export const dataTypeByNameValue = (value: string) => nameDataTypeByValue[value];

export default allOptions;
