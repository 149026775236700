import { useMemo, useState } from 'react';
import { Color } from '@tremor/react';

import { BarChart, ChartCard } from '@/components/Chartsv2';
import useMetrics, { AutomationMetricsEndpoints } from '@/hooks/useAutomations/useMetrics';
import { Option } from '@/interfaces/general';
import { TimePeriod } from '@/interfaces/time_period';

import ChartFilters from './ChartFilters';
import Filters from './Filters';

const STARTED_CATEGORY: Option = {
  value: 'started',
  label: 'Entered',
  description: 'Subscribers enrolled',
};
const RUNNING_CATEGORY: Option = {
  value: 'running',
  label: 'In Progress',
  description: 'Subscribers in workflow',
};
const COMPLETED_CATEGORY: Option = {
  value: 'completed',
  label: 'Completed',
  description: 'Subscribers completed workflow',
};
const FAILED_CATEGORY: Option = {
  value: 'failed',
  label: 'Exited Early',
  description: 'Subscribers exited early',
};

const COLOR_BY_CATEGORY = {
  [STARTED_CATEGORY.label]: 'gray',
  [RUNNING_CATEGORY.label]: 'amber',
  [COMPLETED_CATEGORY.label]: 'emerald',
  [FAILED_CATEGORY.label]: 'red',
};

const FILTER_COLOR_BY_CATEGORY = {
  [STARTED_CATEGORY.label]: 'text-gray-500',
  [RUNNING_CATEGORY.label]: 'text-amber-500',
  [COMPLETED_CATEGORY.label]: 'text-emerald-500',
  [FAILED_CATEGORY.label]: 'text-red-500',
};

interface Props {
  automationId: string;
}

interface ChartRow {
  name: string;
  started: number;
  running: number;
  completed: number;
  failed: number;
}

const JourneyVisualisation = ({ automationId }: Props) => {
  const [currentTimePeriod, setCurrentTimePeriod] = useState<TimePeriod>(TimePeriod.LAST_7_DAYS);
  const { data, isSuccess, isLoading } = useMetrics({
    automationId,
    endpoint: AutomationMetricsEndpoints.JOURNEY_METRICS,
    timePeriod: currentTimePeriod,
  });

  const [visibleCategories, setVisibleCategories] = useState<{ [key: string]: boolean }>({
    [STARTED_CATEGORY.label]: true,
    [RUNNING_CATEGORY.label]: true,
    [COMPLETED_CATEGORY.label]: true,
    [FAILED_CATEGORY.label]: true,
  });

  const handleChangeInShowCategory = (category: string, checked: boolean) => {
    const newVisibleCategories = { ...visibleCategories };
    newVisibleCategories[category] = checked;
    setVisibleCategories(newVisibleCategories);
  };

  const handleChangeInTimePeriod = (timePeriod: TimePeriod) => {
    setCurrentTimePeriod(timePeriod);
  };

  const mappedData: ChartRow[] = useMemo(() => {
    if (!isSuccess || typeof data !== 'object') {
      return [];
    }

    return data
      .filter((row: ChartRow) => row.started || row.running || row.completed)
      .map((row: ChartRow) => {
        return {
          name: row.name,
          [STARTED_CATEGORY.label]: row.started,
          [RUNNING_CATEGORY.label]: row.running,
          [COMPLETED_CATEGORY.label]: row.completed,
          [FAILED_CATEGORY.label]: row.failed,
        };
      });
  }, [data, isSuccess]);

  const categoryColors = useMemo(
    () =>
      Object.keys(visibleCategories)
        .filter((category) => visibleCategories[category])
        .map((category) => COLOR_BY_CATEGORY[category] as Color),
    [visibleCategories]
  );

  return (
    <div className="flex flex-col gap-y-6">
      <Filters onChangeInPeriod={handleChangeInTimePeriod} />
      <div className="flex w-full">
        <div className="hidden md:block">
          <ChartFilters
            filters={[STARTED_CATEGORY, RUNNING_CATEGORY, COMPLETED_CATEGORY, FAILED_CATEGORY]}
            onChange={handleChangeInShowCategory}
            colorByFilter={FILTER_COLOR_BY_CATEGORY}
          />
        </div>
        <ChartCard
          noResultsText={isLoading ? 'Fetching...' : 'No journey metrics available within the chosen time range.'}
          noResults={mappedData.length === 0}
        >
          <BarChart
            index="name"
            data={mappedData}
            categories={Object.keys(visibleCategories).filter((category) => visibleCategories[category])}
            colors={categoryColors}
            showLegend={false}
            showXAxis
            showGridLines
          />
        </ChartCard>
      </div>
    </div>
  );
};

export default JourneyVisualisation;
