import { PageRoute } from "@/interfaces/dream_builder/page_route";

export const buildPageIdToVersionMapFromRoute = (route: PageRoute, map: { [id: string]: string }) => {
  let updatedMap = { ...map };
  if (route.page_id && route.page_version_id) {
    updatedMap[route.page_id] = route.page_version_id;
  }

  if (route.children) {
    Object.values(route.children).forEach((childRoute) => {
      const childMap = buildPageIdToVersionMapFromRoute(childRoute, updatedMap);
      updatedMap = { ...updatedMap, ...childMap };
    });
  }

  return updatedMap;
}

export const findPageVersionFromRoute = (route: PageRoute, pageId: string) => {
  const pageIdToVersionMap = buildPageIdToVersionMapFromRoute(route, {});
  return pageIdToVersionMap[pageId];
}
