import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { usePermissions } from '../../context/permissions-context';
import { PermissionLevel, PermissionResource } from '../../interfaces/permissions';

interface Props {
  resource: PermissionResource;
  permission: PermissionLevel;
  message?: string;
  redirectTo?: string;
  enabled?: boolean;
}

const useRequirePermission = ({
  resource,
  permission,
  redirectTo = '/',
  message = 'Unauthorized',
  enabled = true,
}: Props) => {
  const { checkPermissions } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkPermissions(resource, permission) && enabled) {
      toast.error(message);
      navigate(redirectTo);
    }
  }, [resource, permission, navigate, message, redirectTo, enabled, checkPermissions]);
};

export default useRequirePermission;
