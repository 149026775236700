/* eslint-disable no-alert */

import { Link, useParams } from 'react-router-dom';
import moment from 'moment-mini';

import { useOpportunity, useOpportunityUpdate } from '@/hooks/useAdNetwork/internal';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/internal/opportunity';

import { PageHeading } from '../../_components/PageHeading';
import OpportunityStatusIndicator from '../../_components/StatusIndicator/OpportunityStatusIndicator';
import Form from '../../publications/$publication_id/opportunities/_components/Form/Form';

interface OpportunityEditProps {
  opportunity: AdNetworkOpportunity;
}

const OpportunityEdit = ({ opportunity }: OpportunityEditProps) => {
  const onSubmitMutation = useOpportunityUpdate({ id: opportunity.id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to={`/ad_network/publications/${opportunity.publication.id}/opportunities`}>
              {opportunity.publication.name}
            </PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb>{opportunity.campaign.name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>

      <div className="divide-y divide-gray-100">
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 space-y-4">
          <div>
            <div className="flex items-center gap-x-3">
              <h1 className="flex gap-x-3 text-base leading-7 items-center truncate">
                <Link
                  to={`/ad_network/publications/${opportunity.publication.id}`}
                  className="font-semibold text-gray-800 "
                >
                  {opportunity.advertiser.name}
                </Link>
                <span className="text-gray-400">/</span>
                <Link
                  to={`/ad_network/campaigns/${opportunity.campaign.id}`}
                  className="font-semibold text-gray-800 truncate"
                >
                  {opportunity.campaign.name}
                </Link>
              </h1>
              <div className="flex items-center">
                <OpportunityStatusIndicator opportunity={opportunity} />
              </div>
            </div>
            <p className="mt-2 text-xs leading-6 text-gray-500">
              <time>{moment(opportunity.campaign.window_start_date).format('LLL')}</time>
              <span>&nbsp;—&nbsp;</span>
              <time>{moment(opportunity.campaign.window_end_date).format('LLL')}</time>
            </p>
          </div>
        </div>
        <Form opportunity={opportunity} onSubmitMutation={onSubmitMutation} />
      </div>
    </>
  );
};

export default function Loader() {
  const { opportunity_id: id } = useParams<'opportunity_id'>() as { opportunity_id: string };
  const { data, isLoading, isError, isSuccess } = useOpportunity({ id });

  if (!isSuccess || isLoading || isError) return null;

  return <OpportunityEdit opportunity={data} />;
}
