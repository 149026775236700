import styled from 'styled-components';

import { colors } from '../../../lib/colors';
import { Styled as StyledIcon } from '../Icon/styled';

export const Styled = {
  Container: styled.div`
    align-items: center;
    background: ${colors.black[9]};
    border: solid 1px ${colors.black[9]};
    border-radius: 0.25rem;
    color: ${colors.black[3]};
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: 500;
    margin: -0.5rem;
    padding: 2rem;

    & > ${StyledIcon.Wrapper} {
      svg path {
        fill: ${colors.black[4]};
      }
    }
  `,
  Title: styled.p`
    &&& {
      color: ${colors.black[1]};
      line-height: 1.15;
      margin-bottom: 0.75rem;
      margin-top: 0.5rem;
      text-align: center;
    }
  `,
  Description: styled.p`
    &&& {
      line-height: 1.2;
      margin-top: 0;
      text-align: center;
    }
  `,
};

export default Styled;
