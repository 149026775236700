import { useState } from 'react';
import Tippy from '@tippyjs/react';
import cx from 'classnames';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip';
import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';

import { usePostContext } from '../../PostContext';
import VisibilitySettings from '../ThumbnailActionsMenu/VisiblitySettings';
import ThumbnailTooltipMenu from '../ThumbnailTooltipMenu';

interface Props {
  thumbnailFileRef: React.RefObject<HTMLInputElement>;
  setShowImageLibrary: (value: boolean) => void;
  onLoad: () => void;
}

const SHOW_IMAGE_DELAY = 100;

const Thumbnail = ({ thumbnailFileRef, setShowImageLibrary, onLoad }: Props) => {
  const { formData } = usePostContext();
  const [showImage, setShowImage] = useState(false);

  const onImageLoaded = () => {
    onLoad();

    setTimeout(() => {
      setShowImage(true);
    }, SHOW_IMAGE_DELAY);
  };

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 w-full h-full -ml-10 z-0" />
      <div className="absolute -ml-10">
        <Tippy
          offset={[0, 8]}
          placement="bottom-start"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          trigger="click"
          interactive
          content={
            <div className="absolute">
              <VisibilitySettings />
            </div>
          }
          zIndex={EDITOR_MENUS_Z_INDEX}
        >
          <div>
            <Tooltip title={formData?.display_thumbnail_on_web ? 'Visibility' : 'Hidden on top in web'}>
              <Button
                $leftSlot={
                  formData?.display_thumbnail_on_web ? (
                    <div className="relative w-4 h-4 mt-[.1rem]">
                      <img src="/images/eye-icon.svg" alt="Eye icon" />
                    </div>
                  ) : (
                    <div className="relative w-4 h-4">
                      <img src="/images/eye-off-icon.svg" alt="Eye icon" />
                    </div>
                  )
                }
                $variant="tertiary"
                $size="small"
                $isIconButton
              />
            </Tooltip>
          </div>
        </Tippy>
      </div>
      <div className="relative flex">
        <Tippy
          offset={[0, 8]}
          placement="top"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          trigger="click"
          interactive
          content={
            <div className="absolute mt-[-3rem]">
              <ThumbnailTooltipMenu fileRef={thumbnailFileRef} setShowImageLibrary={setShowImageLibrary} />
            </div>
          }
          zIndex={EDITOR_MENUS_Z_INDEX}
        >
          <div className={cx({ 'sr-only': !showImage })}>
            <div className="w-full rounded-lg overflow-hidden mb-8 max-w-100%">
              <figure className="relative h-auto w-full">
                <img
                  data-testid="post-thumbnail"
                  src={formData?.thumbnail?.url}
                  alt={formData?.thumbnail?.alt}
                  className="inset-0 w-full h-auto"
                  onLoad={onImageLoaded}
                />
              </figure>
            </div>
          </div>
        </Tippy>
      </div>
    </div>
  );
};

export default Thumbnail;
