import { useParams } from 'react-router-dom';
import moment from 'moment-mini';

import useCampaignOpportunityGroup from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroup';
import {
  AdNetworkCampaignOpportunityGroup,
  AdNetworkCampaignOpportunityGroupStatus,
} from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import { Button } from '@/ui/Button';
import { MetricCard } from '@/ui/MetricCard';

import { DescriptionList } from '../../_components/DescriptionList';
import { LoadingListPage } from '../../_components/LoadingListPage';
import { PageHeading } from '../../_components/PageHeading';
import CampaignOpportunityGroupStatusIndicator from '../../_components/StatusIndicator/CampaignOpportunityGroupStatusIndicator';

interface Props {
  campaignOpportunityGroup: AdNetworkCampaignOpportunityGroup;
}

const formatter = new Intl.NumberFormat('en-US');
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const CampaignOpportunityGroup = ({ campaignOpportunityGroup }: Props) => {
  const {
    name,
    campaign_id: campaignId,
    tier_one_payout_per_click: tierOnePayoutPerClick,
    tier_two_payout_per_click: tierTwoPayoutPerClick,
    tier_three_payout_per_click: tierThreePayoutPerClick,
    tier_four_payout_per_click: tierFourPayoutPerClick,
    tier_unknown_payout_per_click: tierUnknownPayoutPerClick,
    tier_one_payout_per_mille: tierOnePayoutPerMille,
    tier_two_payout_per_mille: tierTwoPayoutPerMille,
    tier_three_payout_per_mille: tierThreePayoutPerMille,
    tier_four_payout_per_mille: tierFourPayoutPerMille,
    tier_unknown_payout_per_mille: tierUnknownPayoutPerMille,
    opportunity_counts_by_status: opportunityCountsByStatus,
    estimated_clicks_by_status: estimatedClicksByStatus,
    estimated_opens_by_status: estimatedOpensByStatus,
    estimated_payout_cents_by_status: estimatedPayoutCentsByStatus,
    payout_model: payoutModel,
    deadline,
    message_to_publisher: messageToPublisher,
  } = campaignOpportunityGroup;

  const acceptedEstimatedClicks = estimatedClicksByStatus.accepted;
  const draftEstimatedClicks = estimatedClicksByStatus.draft;
  const pendingEstimatedClicks = estimatedClicksByStatus.pending;
  const totalEstimatedClicks = acceptedEstimatedClicks + draftEstimatedClicks + pendingEstimatedClicks;
  const rejectedEstimatedClicks = estimatedClicksByStatus.rejected;
  const expiredEstimatedClicks = estimatedClicksByStatus.expired;
  const canceledEstimatedClicks = estimatedClicksByStatus.canceled;
  const lostEstimatedClicks = rejectedEstimatedClicks + expiredEstimatedClicks + canceledEstimatedClicks;

  const acceptedEstimatedOpens = estimatedOpensByStatus.accepted;
  const draftEstimatedOpens = estimatedOpensByStatus.draft;
  const pendingEstimatedOpens = estimatedOpensByStatus.pending;
  const totalEstimatedOpens = acceptedEstimatedOpens + draftEstimatedOpens + pendingEstimatedOpens;
  const rejectedEstimatedOpens = estimatedOpensByStatus.rejected;
  const expiredEstimatedOpens = estimatedOpensByStatus.expired;
  const canceledEstimatedOpens = estimatedOpensByStatus.canceled;
  const lostEstimatedOpens = rejectedEstimatedOpens + expiredEstimatedOpens + canceledEstimatedOpens;

  const acceptedOpportunities = opportunityCountsByStatus.accepted;
  const draftOpportunities = opportunityCountsByStatus.draft;
  const pendingOpportunities = opportunityCountsByStatus.pending;
  const totalOpportunities = acceptedOpportunities + draftOpportunities + pendingOpportunities;
  const rejectedOpportunities = opportunityCountsByStatus.rejected;
  const expiredOpportunities = opportunityCountsByStatus.expired;
  const canceledOpportunities = opportunityCountsByStatus.canceled;
  const lostOpportunities = rejectedOpportunities + expiredOpportunities + canceledOpportunities;

  const acceptedEstimatedPayout = estimatedPayoutCentsByStatus.accepted / 100;
  const draftEstimatedPayout = estimatedPayoutCentsByStatus.draft / 100;
  const pendingEstimatedPayout = estimatedPayoutCentsByStatus.pending / 100;
  const totalEstimatedPayout = acceptedEstimatedPayout + draftEstimatedPayout + pendingEstimatedPayout;
  const rejectedEstimatedPayout = estimatedPayoutCentsByStatus.rejected / 100;
  const expiredEstimatedPayout = estimatedPayoutCentsByStatus.expired / 100;
  const canceledEstimatedPayout = estimatedPayoutCentsByStatus.canceled / 100;
  const lostEstimatedPayout = rejectedEstimatedPayout + expiredEstimatedPayout + canceledEstimatedPayout;

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to={`/ad_network/campaigns/${campaignId}/campaign_opportunity_groups`}>
              Opportunity groups
            </PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">{name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          {campaignOpportunityGroup.status === AdNetworkCampaignOpportunityGroupStatus.DRAFT && (
            <Button size="xs" variant="primary-inverse" type="button" to="provision">
              Begin provisioning
            </Button>
          )}
        </PageHeading.Side>
      </PageHeading>
      <div className="divide-y divide-gray-100">
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 p-4">
          <MetricCard
            variant="primary"
            label="Opportunities"
            value={formatter.format(totalOpportunities)}
            complements={[
              { label: 'Accepted', value: formatter.format(acceptedOpportunities) },
              { label: 'Pending', value: formatter.format(pendingOpportunities) },
              { label: 'Draft', value: formatter.format(draftOpportunities) },
              { label: 'Lost', value: formatter.format(lostOpportunities) },
            ]}
            tooltip='Total includes "Draft", "Accepted", and "Pending" opportunities.'
            initialVisibleComplements={4}
          />
          <MetricCard
            variant="primary"
            label="Estimated payout"
            value={currencyFormatter.format(totalEstimatedPayout)}
            complements={[
              { label: 'Accepted', value: currencyFormatter.format(acceptedEstimatedPayout) },
              { label: 'Pending', value: currencyFormatter.format(pendingEstimatedPayout) },
              { label: 'Draft', value: currencyFormatter.format(draftEstimatedPayout) },
              { label: 'Lost', value: currencyFormatter.format(lostEstimatedPayout) },
            ]}
            tooltip='Total includes "Draft", "Accepted", and "Pending" opportunities.'
            initialVisibleComplements={4}
          />
          <MetricCard
            variant="primary"
            label="Estimated clicks"
            value={formatter.format(totalEstimatedClicks)}
            complements={[
              { label: 'Accepted', value: formatter.format(acceptedEstimatedClicks) },
              { label: 'Pending', value: formatter.format(pendingEstimatedClicks) },
              { label: 'Draft', value: formatter.format(draftEstimatedClicks) },
              { label: 'Lost', value: formatter.format(lostEstimatedClicks) },
            ]}
            tooltip='Total includes "Draft", "Accepted", and "Pending" opportunities.'
            initialVisibleComplements={4}
          />
          <MetricCard
            variant="primary"
            label="Estimated opens"
            value={formatter.format(totalEstimatedOpens)}
            complements={[
              { label: 'Accepted', value: formatter.format(acceptedEstimatedOpens) },
              { label: 'Pending', value: formatter.format(pendingEstimatedOpens) },
              { label: 'Draft', value: formatter.format(draftEstimatedOpens) },
              { label: 'Lost', value: formatter.format(lostEstimatedOpens) },
            ]}
            tooltip='Total includes "Draft", "Accepted", and "Pending" opportunities.'
            initialVisibleComplements={4}
          />
        </div>
        <DescriptionList>
          <DescriptionList.Item term="Name">{name}</DescriptionList.Item>
          <DescriptionList.Item term="Status">
            <CampaignOpportunityGroupStatusIndicator campaignOpportunityGroup={campaignOpportunityGroup} />
          </DescriptionList.Item>
          <DescriptionList.Item term="Payout Model">{payoutModel}</DescriptionList.Item>
          <DescriptionList.Item term="Tier One Payout Per Click">{tierOnePayoutPerClick}</DescriptionList.Item>
          <DescriptionList.Item term="Tier Two Payout Per Click">{tierTwoPayoutPerClick}</DescriptionList.Item>
          <DescriptionList.Item term="Tier Three Payout Per Click">{tierThreePayoutPerClick}</DescriptionList.Item>
          <DescriptionList.Item term="Tier Four Payout Per Click">{tierFourPayoutPerClick}</DescriptionList.Item>
          <DescriptionList.Item term="Tier Unknown Payout Per Click">{tierUnknownPayoutPerClick}</DescriptionList.Item>
          <DescriptionList.Item term="Tier One Payout Per Mille">{tierOnePayoutPerMille}</DescriptionList.Item>
          <DescriptionList.Item term="Tier Two Payout Per Mille">{tierTwoPayoutPerMille}</DescriptionList.Item>
          <DescriptionList.Item term="Tier Three Payout Per Mille">{tierThreePayoutPerMille}</DescriptionList.Item>
          <DescriptionList.Item term="Tier Four Payout Per Mille">{tierFourPayoutPerMille}</DescriptionList.Item>
          <DescriptionList.Item term="Tier Unknown Payout Per Mille">{tierUnknownPayoutPerMille}</DescriptionList.Item>
          <DescriptionList.Item term="Deadline">{moment(deadline).format('LLL')}</DescriptionList.Item>
          {messageToPublisher && (
            <DescriptionList.Item term="Message To Publisher">{messageToPublisher}</DescriptionList.Item>
          )}
        </DescriptionList>
      </div>
    </>
  );
};

export default function Loader() {
  const { campaign_opportunity_group_id: id } = useParams<'campaign_opportunity_group_id'>() as {
    campaign_opportunity_group_id: string;
  };
  const { data, isSuccess, isLoading, isError } = useCampaignOpportunityGroup({ id });

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  return <CampaignOpportunityGroup campaignOpportunityGroup={data} />;
}
