import { createContext, useContext } from 'react';

import { useCurrentPublication, usePublicationColorPalette } from '../../../hooks';
import useFonts from '../../../hooks/useFonts';
import useAllFonts from '../../../hooks/useFonts/useAllFonts';
import { useRequirePermission } from '../../../hooks/useRequirePermission';
import { Publication } from '../../../interfaces/publication';

import FontStyles from './FontStyles';
import PageLayout from './PageLayout';
import { StatusType, useSavingIndicator } from './SavingIndicator';
import { ScreenSizes, ScreenSizeType, useScreenSize } from './ScreenSize';

interface ContextProps<T> {
  screenSize: ScreenSizeType;
  setScreenSize: (screenSize: ScreenSizeType) => void;
  fontOptions: {
    label: string;
    value: string;
  }[];
  pageData: T;
  setIsSaving: (isSaving: boolean) => void;
  handleSaved: () => void;
  status: StatusType;
  colorPalette?: any;
  currentPublication?: Publication;
}

const PageContext = createContext<ContextProps<unknown>>({
  screenSize: ScreenSizes.DESKTOP,
  fontOptions: [],
  pageData: {},
  setScreenSize: () => {},
  setIsSaving: () => {},
  handleSaved: () => {},
  status: 'idle',
  colorPalette: undefined,
  currentPublication: undefined,
});
PageContext.displayName = 'PageContext';

interface Props<T> {
  children: React.ReactNode;
  pageData?: T;
  sidePanelChildren: React.ReactNode;
  actionChildren?: React.ReactNode;
  bgColor?: string;
}

export const PageProvider = <T extends unknown>({
  children,
  pageData,
  sidePanelChildren,
  actionChildren,
  bgColor = '',
}: Props<T>) => {
  useRequirePermission({
    resource: 'web_theme',
    permission: 'update',
    message: 'You do not have access to the Design Lab',
    redirectTo: '/',
  });

  const { data: currentPublication } = useCurrentPublication();
  const { data: colorPalette } = usePublicationColorPalette(currentPublication?.id || '');
  const fontOptions = useFonts();
  // This is slighly counter-intuitive, but none web safe fonts are the ones that need to be loaded into the browser.
  const allFontsQuery = useAllFonts({
    webSafe: false,
  });
  const { data: fonts } = allFontsQuery;
  const isLoading = !pageData || !colorPalette;

  const { setIsSaving, handleSaved, status } = useSavingIndicator();
  const { screenSize, setScreenSize } = useScreenSize();

  return (
    <PageContext.Provider
      value={{
        screenSize,
        fontOptions: fontOptions || [],
        pageData: pageData as T,
        setScreenSize,
        setIsSaving,
        handleSaved,
        status,
        colorPalette,
        currentPublication,
      }}
    >
      {fonts && <FontStyles fonts={fonts} />}
      <PageLayout
        isLoading={isLoading}
        actionChildren={actionChildren}
        sidePanelChildren={sidePanelChildren}
        bgColor={bgColor}
      >
        {children}
      </PageLayout>
    </PageContext.Provider>
  );
};

export const usePageContext = <T extends unknown>() => useContext(PageContext) as ContextProps<T>;
