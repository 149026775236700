import { Typography } from '@/components/Typography';
import { DURATION_UNIT_LABELS, durationFromSeconds, secondsToDuration } from '@/utils/durationDisplay';
import pluralize from '@/utils/pluralize';

interface Props {
  seconds: number;
}

const WaitForStepNode = ({ seconds }: Props) => {
  const durationUnit = durationFromSeconds(seconds);

  return (
    <Typography size="xs">
      {seconds
        ? `Wait for ${pluralize(DURATION_UNIT_LABELS[durationUnit], secondsToDuration(seconds, durationUnit))}...`
        : 'Not configured'}
    </Typography>
  );
};

export default WaitForStepNode;
