import { FC } from 'react';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import TablePagination from '@/components/TablePagination';
import { Pagination } from '@/interfaces/general';
import { Reward } from '@/interfaces/reward';
import RewardTypeLabels from '@/values/rewardType';

import RewardImage from '../RewardImage';

interface Props {
  rewards: Reward[] | undefined;
  pagination: Pagination | undefined;
  onPageSelected: (pageNum: number) => void;
  onEditSelected: (rewardId: string) => void;
  onDeleteSelected: (rewardId: string) => void;
  onPromoCodesSelected: (rewardId: string) => void;
}

const Table: FC<Props> = (props: Props) => {
  const { rewards, pagination, onPageSelected, onEditSelected, onDeleteSelected, onPromoCodesSelected } = props;

  if (!rewards || !pagination) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-y-scroll border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rewards.map((reward: Reward) => {
                  const options = [
                    {
                      label: 'Edit',
                      onClick: () => onEditSelected(reward.id),
                    },
                    {
                      label: 'Delete',
                      onClick: () => onDeleteSelected(reward.id),
                    },
                  ];

                  if (reward.reward_type === 'promo_code') {
                    options.unshift({
                      label: 'Promo Codes',
                      onClick: () => onPromoCodesSelected(reward.id),
                    });
                  }

                  return (
                    <tr key={reward.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <RewardImage image={reward.image} />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{reward.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-900">{RewardTypeLabels[reward.reward_type]}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <EllipsisDropdown options={options} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <TablePagination className="mt-2" pagination={pagination} onPageSelected={onPageSelected} />
    </div>
  );
};

export default Table;
