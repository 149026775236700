import { useMemo } from 'react';

import { useCurrentUser } from '@/context/current-user-context';

import { useEditorContext } from '../../../EditorContext';
import { EmptyState } from '../components/EmptyState';
import { ThreadCard } from '../components/ThreadCard';

export const YourThreadsView = ({ onClickThread }: { onClickThread: (id: string) => void }) => {
  const { threads, highlightThread, removeHighlightThread, editor } = useEditorContext();

  const focusedThreads = editor?.storage.comments.focusedThreads || [];

  const user = useCurrentUser();

  const filtered = useMemo(() => {
    return threads?.filter((t) => t.data.authorId === user?.currentUser?.id);
  }, [threads, user]);

  if (!filtered || filtered.length === 0) {
    return <EmptyState message="You have not created any threads yet" />;
  }

  return (
    <div className="grid gap-2">
      {filtered?.map((thread) => (
        <ThreadCard
          key={thread.id}
          id={thread.id}
          authorId={thread.data.authorId}
          content={thread.comments[0]?.content}
          participantIds={thread.comments.map((c) => c.data.authorId)}
          createdAt={thread.createdAt}
          replies={thread.comments.length - 1}
          onClick={onClickThread}
          onMouseEnter={highlightThread}
          onMouseLeave={removeHighlightThread}
          focused={focusedThreads.includes(thread.id)}
          resolved={!!thread.resolvedAt}
        />
      ))}
    </div>
  );
};
