import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

const useUpdatePublicationWithFormData = (publicationId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData: FormData) =>
      api.patch(`/publications/${publicationId}`, formData),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['publications', publicationId]);
      },
    }
  );
};

export default useUpdatePublicationWithFormData;
