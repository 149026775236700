import Text from '@/components/Text';

import { useSubscriberContext } from '../../_context/subscriberContext';

import AcquisitionAttribute from './AcquisitionAttribute';

const AcquisitionDetails = () => {
  const { subscription } = useSubscriberContext();

  const {
    acquisition_source_channel: channel,
    acquisition_source_source: source,
    acquisition_source_medium: medium,
    acquisition_source_campaign: campaign,
    acquisition_source_landing_page: url,
    acquisition_source_device_type: deviceType,
    acquisition_source_referring_url: referringUrl,
    referrer_id: referrerId,
    referrer_email: referrerEmail,
    embed_name: embedName,
    location,
  } = subscription;

  return (
    <>
      <Text size="sm" className="text-gray-500 mt-1">
        Source attribution for this subscriber.
      </Text>
      <div className="mt-5 space-y-5">
        <AcquisitionAttribute label="Channel" value={channel || '-'} />
        {source && <AcquisitionAttribute label="Source" value={source} />}
        {medium && <AcquisitionAttribute label="Medium" value={medium} />}
        {campaign && <AcquisitionAttribute label="Campaign" value={campaign} />}
        {deviceType && <AcquisitionAttribute label="Device Type" value={deviceType} />}
        {location && <AcquisitionAttribute label="Location" value={location} />}
        {referringUrl && <AcquisitionAttribute label="Referring Site" value={referringUrl} />}
        {referrerEmail && (
          <AcquisitionAttribute label="Referred By" value={referrerEmail} href={`/subscribers/${referrerId}`} />
        )}
        {url && <AcquisitionAttribute label="URL" value={url} />}
        {embedName && <AcquisitionAttribute label="Embed Name" value={embedName} />}
      </div>
    </>
  );
};

export default AcquisitionDetails;
