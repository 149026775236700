import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import BoostAgreementStatus from '@/interfaces/boosts/grow/boost_agreement_status';
import { BoostOfferStatus } from '@/interfaces/boosts/grow/boost_offer';
import { BoostSend } from '@/interfaces/boosts/grow/boost_send';
import { Order, Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  boost_sends: BoostSend[];
  pagination: Pagination;
}

interface Props {
  allResults?: boolean;
  boostAgreementId?: string;
  boostOfferId?: string;
  recommendingPublicationId?: string;
  perPage?: number;
  search?: string | null;
  status?: string | null;
  offerStatus?: BoostOfferStatus | 'all';
  boostAgreementStatus?: BoostAgreementStatus;
  orderBy?: string;
  direction?: Order;
}

const useBoostSends = ({
  allResults = false,
  boostAgreementId,
  recommendingPublicationId,
  boostAgreementStatus,
  boostOfferId,
  orderBy,
  offerStatus = 'all',
  perPage = 10,
  search,
  status,
  direction = Order.DESC,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostSends = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/boosts/grow/boost_sends`, {
        params: {
          publication_id: currentPublicationId,
          recommending_publication_id: recommendingPublicationId,
          all_results: allResults,
          boost_agreement_id: boostAgreementId,
          boost_offer_id: boostOfferId,
          page: pageParam,
          per_page: perPage,
          search,
          status,
          offer_status: offerStatus,
          boost_agreement_status: boostAgreementStatus,
          order_by: orderBy,
          direction,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(
    [
      currentPublicationId,
      'boosts',
      'grow',
      'boost_sends',
      boostAgreementId,
      recommendingPublicationId,
      boostOfferId,
      status,
      boostAgreementStatus,
      offerStatus,
      search,
      orderBy,
      direction,
    ],
    fetchBoostSends,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
};

export default useBoostSends;
