import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Cursor, DotsThree, Flag } from '@phosphor-icons/react';
import moment from 'moment-mini';

import { useUpdateCommentBan } from '@/hooks/useComments';
import { BanTypes, CommentBan } from '@/interfaces/comment_ban';
import { Badge } from '@/ui/Badge';

import { Button } from '../../../_components/UI/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../_components/UI/DropdownMenu';
import { TableCell, TableRow } from '../../../_components/UI/Table';
import { Text } from '../../../_components/UI/Text';

interface Props {
  commentBan: CommentBan;
}

const CommentBanRow = ({ commentBan }: Props) => {
  const queryClient = useQueryClient();
  const isBanLifted = Boolean(commentBan.lifted_at);
  const isPostLevelBan = commentBan.level === BanTypes.POST;

  const levelText = isPostLevelBan ? `Post - ${commentBan.post.title}` : 'All Posts';

  const updateCommentBan = useUpdateCommentBan({
    commentBanId: commentBan.id,
    onSuccess: () => {
      toast.success('Saved!');
      queryClient.invalidateQueries(['comment_bans']);
    },
  });

  const handleRemoveBan = () => {
    updateCommentBan.mutate({
      lifted_at: moment().toISOString(),
    });
  };

  const handleUpdateToPublicationLevel = (level: string) => {
    updateCommentBan.mutate({
      level,
    });
  };

  return (
    <TableRow key={commentBan.id}>
      <TableCell className="font-medium">{commentBan.subscription.email}</TableCell>
      <TableCell className="truncate italic">{`"${commentBan.reason}"`}</TableCell>
      <TableCell className="text-right">
        <div>
          {!isBanLifted ? (
            <Badge type="success">
              <span className="capitalize mr-1">{levelText} </span>
            </Badge>
          ) : (
            <Badge type="information">
              <span className="capitalize mr-1">{levelText}</span>
            </Badge>
          )}
        </div>
      </TableCell>
      <TableCell className="text-right">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <DotsThree className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent sideOffset={2} align="end" className="flex w-[150px] flex-col bg-wb-primary p-1">
            {!isBanLifted && (
              <DropdownMenuItem>
                <Button
                  variant="ghost"
                  size="md"
                  LeftIcon={Cursor}
                  onClick={handleRemoveBan}
                  className="text-wb-primary py-1.5 px-2 justify-start gap-2 rounded-md w-full"
                >
                  <Text size="xs" variant="primary-soft" className="tracking-tight" weight="medium">
                    Lift Ban
                  </Text>
                </Button>
              </DropdownMenuItem>
            )}
            {isPostLevelBan && (
              <DropdownMenuItem>
                <Button
                  variant="ghost"
                  size="md"
                  LeftIcon={Flag}
                  onClick={() => handleUpdateToPublicationLevel('publication')}
                  className="text-wb-primary py-1.5 px-2 justify-start gap-2 rounded-md w-full"
                >
                  <Text size="xs" variant="primary-soft" className="tracking-tight" weight="medium">
                    Bump to publication level
                  </Text>
                </Button>
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
};

export default CommentBanRow;
