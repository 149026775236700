import { AutomationStep } from './automation_step';

export enum AutomationState {
  RUNNING = 'running',
  INACTIVE = 'inactive',
  FINISHING = 'finishing',
}

export const MAX_AUTOMATION_TRIGGERS = 3;

export interface AutomationStats {
  completed_count: number;
  running_count: number;
}

export enum AutomationEnrolmentType {
  LIMITED = 'limited',
  UNLIMITED = 'unlimited',
  CONDITIONAL = 'conditional',
}

export interface AutomationEnrolmentSettings {
  max_enrolments?: number;
  seconds_since_completed?: number;
  seconds_since_entered?: number;
}

export interface Automation {
  id: string;
  name: string;
  description?: string;
  publication_id: string;
  state: AutomationState;
  updated_at: string;
  created_at: string;
  stats: AutomationStats;
  enrolment_type: AutomationEnrolmentType;
  enrolment_settings: AutomationEnrolmentSettings;
  referring_automation_steps: AutomationStep[];
}
