import { useNavigate } from 'react-router-dom';

import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import { useOverview } from '@/hooks/useDashboard';
import { OverviewEndpoints } from '@/hooks/useDashboard/useOverview';
import { AcquisitionSource } from '@/interfaces/dashboard';

import getLocaleString from '../../../utils/getLocaleString';
import Sources from '../components/Sources';
import { PageProps } from '../types';

const TopAcquisitionSources = () => {
  const { period } = usePageContext<PageProps>();
  const navigate = useNavigate();

  const { data } = useOverview({
    endpoint: OverviewEndpoints.ACQUISITION_SOURCES,
    timePeriod: period,
  });

  const sources = data
    ? data?.acquisition_sources?.map((source: AcquisitionSource) => {
        return {
          name: source.display,
          value: getLocaleString(source.count),
        };
      })
    : [];

  return (
    <Sources
      title="Top Acquisition Source"
      listData={sources}
      cta={{
        text: 'View subscriber report',
        onClick: () => navigate('/reports/subscriber'),
      }}
    />
  );
};

export default TopAcquisitionSources;
