import { Link, useLocation } from 'react-router-dom';
import { Icon as PhosphorIcon } from '@phosphor-icons/react';
import cx from 'classnames';

import { Text } from '../UI/Text';

interface Props {
  Icon?: PhosphorIcon;
  title: string;
  to: string;
}

export const NavMenuItem = ({ Icon, title, to }: Props) => {
  const location = useLocation();
  const isCurrentPath = location.pathname === to;

  return (
    <Link
      to={to}
      data-tip
      data-for={`nav-tooltip-${title}`}
      className={cx(
        'pr-1.5 py-2 pl-[30px] flex rounded-lg flex-row items-center gap-2 w-full',
        isCurrentPath ? 'bg-wb-secondary' : 'hover:bg-wb-secondary'
      )}
    >
      {isCurrentPath
        ? Icon && <Icon weight="fill" className={cx('my-auto text-wb-primary h-4 w-4')} />
        : Icon && <Icon weight="bold" className={cx('my-auto text-wb-primary h-4 w-4')} />}

      <Text weight="medium" size="xs">
        {title}
      </Text>
    </Link>
  );
};
