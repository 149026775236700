import { IconComponent } from '@heroicons/react';
import { CheckIcon } from '@heroicons/react/20/solid';

import { useCurrentPublicationState } from '@/context/current-publication-context';

import { BoostAgreementStatus, BoostOffer } from '../../../interfaces/boosts/monetize/boost_offer';
import useBoostsFrozen from '../useBoostsFrozen';

import useCreateBoostAgreementsRestrictions from './useCreateBoostAgreementsRestrictions';

const LABEL_AND_ICON_BY_STATUS: Record<BoostAgreementStatus, { label: string; icon: IconComponent | undefined }> = {
  [BoostAgreementStatus.ACTIVE]: {
    label: 'Applied',
    icon: CheckIcon,
  },
  [BoostAgreementStatus.PENDING]: {
    label: 'Applied',
    icon: CheckIcon,
  },
  [BoostAgreementStatus.REJECTED]: {
    label: 'Rejected',
    icon: undefined,
  },
  [BoostAgreementStatus.ARCHIVED]: {
    label: 'Archived',
    icon: undefined,
  },
  [BoostAgreementStatus.INACTIVE]: {
    label: 'Inactive',
    icon: undefined,
  },
  [BoostAgreementStatus.PAUSED]: {
    label: 'Paused',
    icon: undefined,
  },
  [BoostAgreementStatus.FROZEN]: {
    label: 'Paused',
    icon: undefined,
  },
};

const useBoostOfferCard = (boostOffer?: BoostOffer) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { hasReachedApplyLimit } = useCreateBoostAgreementsRestrictions();

  const boostedPublication = boostOffer?.boosted_publication;
  const isOwnedByCurrentPublication = currentPublicationId === boostedPublication?.id;
  const boostsPlusEnabled = Number(boostOffer?.max_payout_cents) > 0;

  const labelAndIcon = boostOffer?.boost_agreement_status
    ? LABEL_AND_ICON_BY_STATUS[boostOffer.boost_agreement_status]
    : undefined;
  const { label: ctaText, icon: ctaIcon } = labelAndIcon || {};
  const isArchived = boostOffer?.boost_agreement_status === BoostAgreementStatus.ARCHIVED;

  const alreadyApplied = Boolean(boostOffer && !!boostOffer.boost_agreement_status && !isArchived);
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen;

  let tooltipText = '';
  if (isOwnedByCurrentPublication) {
    tooltipText = 'You cannot apply to your own offers';
  } else if (!alreadyApplied && hasReachedApplyLimit) {
    tooltipText = 'You have reached your monthly limit';
  } else if (boostsFrozen) {
    tooltipText = 'Your ability to apply for offers is currently disabled';
  }

  const shouldDisableApply = alreadyApplied ||
    isOwnedByCurrentPublication ||
    hasReachedApplyLimit ||
    boostsFrozen;

  return {
    alreadyApplied,
    ctaText,
    ctaIcon,
    tooltipText,
    boostsPlusEnabled,
    shouldDisableApply,
    boostedPublication,
  };
};

export default useBoostOfferCard;
