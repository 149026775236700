import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';
import analytics from '@/utils/analytics';

interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

interface Variables {
  publicationId: string;
  onSuccess?: () => void;
}

export default function useExternalRssFeedDestroy({ publicationId, onSuccess }: Variables) {
  const queryClient = useQueryClient();
  const destroyRssFeed = ({ id }: { id: string }): Promise<void> =>
    api
      .delete(`/publications/${publicationId}/external_rss_feeds/${id}`, {})
      .then((res) => res.data);

  return useMutation<void, ErrorResponse, { id: string }>(destroyRssFeed, {
    onSuccess: () => {
      analytics.track('Deleted an External RSS Feed');
      queryClient.invalidateQueries([publicationId, 'external_rss_feeds']);
      toast.success('External RSS feed deleted');

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
