import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

export default function useDeleteSecondFactor() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      api.delete('current_user/second_factor'),
    onSuccess: () => {
      queryClient.invalidateQueries(['second_factor']);
    },
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
  });
}
