import { type CustomField } from "./custom_field";

export enum SubscriberPreferenceType {
  TEXT = "text",
  MULTIPLE_CHOICE = "multiple_choice",
  DROPDOWN = "dropdown",
  BOOLEAN = "boolean",
}

export enum SubscriberPreferenceDisplayType {
  RADIO_BUTTONS = "radio_buttons",
  CHECKBOXES = "checkboxes",
  NUMERALS = "numerals",
  UNSTYLED = "unstyled",
}

export enum SubscriberPreferenceVisibility {
  VISIBLE = "visible",
  HIDDEN = "hidden",
}

export interface SubscriberPreferenceOption {
  id: string;
  sort_order: number;
  value: string;
  deleted_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_by_user_id: string | null;
  subscriber_preference_id: string;
  '_destroy'?: boolean;
  '_isNew'?: boolean;
}

export interface PublicationSubscriberPreference {
  id: string;
  name: string;
  description: string
  created_at: string;
  preference_type: SubscriberPreferenceType;
  display_type: SubscriberPreferenceDisplayType;
  visibility: SubscriberPreferenceVisibility;
  required: boolean;
  custom_field_id: string;
  sort_order: number;
  subscriber_preference_options: SubscriberPreferenceOption[];
  custom_field?: CustomField;
}

export enum OrderBy {
  NAME = 'name',
  DESCRIPTION = 'description',
  FORMAT_TYPE = 'format_type',
  CUSTOM_FIELD = 'custom_field',
  SETTINGS = 'settings',
  SORT_ORDER = 'sort_order',
}

export interface CreatePublicationSubscriberPreferenceOptionParams extends Omit<SubscriberPreferenceOption, 'id' | 'created_at' | 'deleted_at' | 'updated_at' | 'subscriber_preference_id' | 'deleted_by_user_id' | 'sort_order'> {}
export interface UpdatePublicationSubscriberPreferenceOptionParams {
  id: SubscriberPreferenceOption['id'] | null;
  value: SubscriberPreferenceOption['value'];
  '_destroy'?: SubscriberPreferenceOption['_destroy'];
}

export interface CreatePublicationSubscriberPreferenceParams extends Omit<PublicationSubscriberPreference, 'id' | 'created_at' | 'subscriber_preference_options' | 'sort_order'> {
  subscriber_preference_options_attributes: CreatePublicationSubscriberPreferenceOptionParams[]
}
export interface UpdatePublicationSubscriberPreferenceParams extends Omit<PublicationSubscriberPreference, 'created_at' | 'sort_order' | 'subscriber_preference_options'> {
  subscriber_preference_options_attributes: UpdatePublicationSubscriberPreferenceOptionParams[]
}

export interface DeletePublicationSubscriberPreferenceParams extends Pick<PublicationSubscriberPreference, 'id'> {}