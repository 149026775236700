import React from 'react';
import { ArrowTopRightOnSquareIcon, PresentationChartLineIcon } from '@heroicons/react/20/solid';

interface PillProps {
  url: string;
  plan: string;
}

const Pill: React.FC<PillProps> = ({ url, plan }) => (
  <div
    className="bg-white border rounded-full max-w-sm mx-auto relative"
    style={{
      height: 50,
    }}
  >
    <span
      className="flex items-center justify-center rounded-full bg-blue-500 absolute"
      style={{
        height: 38,
        width: 38,
        top: 6,
        left: 6,
      }}
    >
      <PresentationChartLineIcon
        className="text-white"
        style={{
          height: 25,
        }}
      />
    </span>

    <div
      className="flex items-center h-full justify-between text-sm"
      style={{
        marginLeft: 56,
        marginRight: 18,
      }}
    >
      <p>
        Part of the <strong className="capitalize">{plan}</strong> plan
      </p>
      <a href={url} target="_blank" rel="noreferrer" className="text-primary-600 flex items-center">
        Learn more
        <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
      </a>
    </div>
  </div>
);

export default Pill;
