import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface RequestProps {
  number: string;
  messageConsent?: boolean;
}

const useCreatePhoneNumber = (signupToken: string | null) => {
  return useMutation(
    ({ number, messageConsent }: RequestProps) =>
      api
        .post('/signups/create_phone_number', {
          number,
          signup_token: signupToken,
          ...(typeof messageConsent === 'boolean' && { message_consent: messageConsent }),
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        localStorage.setItem('phoneVerificationId', data.verification_id);
      },
    }
  );
};

export default useCreatePhoneNumber;
