import { useInfiniteQuery, useQueryClient } from 'react-query';

import { TimePeriod } from '@/utils';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Pagination } from '../interfaces/general';
import { Segment } from '../interfaces/segment';
import api from '../services/swarm';

interface ApiResponse {
  segments: Segment[];
  pagination: Pagination;
}

export default function useSegments({
  search,
  filterByDateCreated = TimePeriod.ALL_TIME,
  filterByDateLastUpdated = TimePeriod.ALL_TIME,
  shouldFetchAll = false,
  dir = 'desc',
  order = 'created_at',
}: {
  search?: string;
  filterByDateCreated?: string;
  filterByDateLastUpdated?: string;
  shouldFetchAll?: boolean;
  dir?: string;
  order?: string;
}) {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchSegments = ({ pageParam = 1 }) =>
    api
      .get(`/segments`, {
        params: {
          page: pageParam,
          per_page: 10,
          publication_id: currentPublicationId,
          q: search,
          filter_by_date_created: filterByDateCreated,
          filter_by_date_last_updated: filterByDateLastUpdated,
          all_results: shouldFetchAll,
          dir,
          order,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    [
      'publications',
      currentPublicationId,
      'segments',
      search,
      filterByDateCreated,
      filterByDateLastUpdated,
      order,
      dir,
    ],
    fetchSegments,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      retry: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        const segments = data.pages.flatMap((page) => page.segments);

        segments.forEach((segmentPreview: any) => {
          queryClient.setQueryData(['segments', segmentPreview.id], {
            id: segmentPreview.id,
            name: segmentPreview.name,
            num_members: segmentPreview.num_members,
            created_at: segmentPreview.created_at,
            last_processed_at: segmentPreview.last_processed_at,
            paused: segmentPreview.paused,
            segment_type: segmentPreview.segment_type,
          });
        });
      },
      onError: (errPayload: any) => errPayload?.response?.data?.error || 'Something went wrong',
    }
  );
}
