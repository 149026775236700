import { useQueryClient } from 'react-query';

import { useHomePage, useUpdateHomePage } from '@/hooks/useHomePages';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import ChildLoader from '@/routes/website_builder/_components/Form/PageSpecific/ChildLoader';

import usePageNavigator from '../../_hooks/usePageNavigator';
import useSetPublishId from '../../_hooks/useSetPublishId';
import useTabNavigator from '../../_hooks/useTabNavigator';

import ArchiveSection from './ArchiveSection';
import HeroSection from './HeroSection';
import FeaturedSection from './PostsSection';
import SideWidgetsSection from './SideWidgetsSection';

interface Props {
  setPublishId: (id: string) => void;
}

const HomePageSettings = ({ setPublishId }: Props) => {
  const queryClient = useQueryClient();
  const { handleSaved, setIsSaving, currentPublication } = usePageContext();
  const homePageQuery = useHomePage({ status: 'draft' });
  const { data: homePage } = homePageQuery;
  const { isHomePage } = usePageNavigator();
  const { isStylesTab } = useTabNavigator();

  useSetPublishId({
    updateIdCheck: isHomePage && !isStylesTab,
    setPublishId,
    idToPublish: homePage?.id || '',
  });

  const updateHomePage = useUpdateHomePage({
    pageId: homePage?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['home_page', currentPublication?.id, 'draft']);
      handleSaved();
    },
  });

  const handleSaveMainSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: { main_section: JSON.stringify(payload) } });
  };

  const handleSaveGeneralSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: { general_section: JSON.stringify(payload) } });
  };

  const handleSavePostsSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: { posts_section: JSON.stringify(payload) } });
  };

  const handleSaveWidgetsSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: { widgets_section: JSON.stringify(payload) } });
  };

  return (
    <ChildLoader isLoading={!homePage}>
      <HeroSection homePage={homePage} handleSave={handleSaveGeneralSection} handleSaveMain={handleSaveMainSection} />
      <FeaturedSection homePage={homePage} handleSave={handleSavePostsSection} />
      <SideWidgetsSection homePage={homePage} handleSave={handleSaveWidgetsSection} />
      <ArchiveSection homePage={homePage} handleSave={handleSavePostsSection} />
    </ChildLoader>
  );
};

export default HomePageSettings;
