import { useState } from 'react';

import { Button } from '@/ui/Button';

import useFilteringContext from '../filtering-context';
import { ConditionGroup, StringOperator, StringOperatorsList } from '../types';

const NameFilterDetails = ({ group }: { group: ConditionGroup }) => {
  const { addCondition, handleClose } = useFilteringContext();

  const operators = StringOperatorsList;

  const [selectedOperator, setSelectedOperator] = useState<StringOperator>(operators[0].value);
  const [value, setValue] = useState('');

  const isValueRequired = selectedOperator !== 'exists' && selectedOperator !== 'does_not_exist';

  const handleApply = () => {
    addCondition(
      {
        type: 'attribute',
        name: 'name',
        filters: { operator: selectedOperator, value: isValueRequired ? value : undefined },
      },
      group.uuid
    );

    handleClose();
  };

  return (
    <form
      className="absolute z-10 mt-2 bg-white border border-gray-300 shadow rounded-md text-sm left-0 w-max text-gray-600 p-4 space-y-2"
      onSubmit={handleApply}
    >
      <div className="space-x-2">
        <p className="inline">Pub name</p>
        <select
          className="appearance-none outline-none border rounded-md text-xs border-gray-300"
          onChange={(e) => setSelectedOperator(e.target.value as any)}
          value={selectedOperator}
          required
        >
          {operators.map((operator) => {
            return (
              <option key={operator.value} value={operator.value}>
                {operator.label}
              </option>
            );
          })}
        </select>
        {isValueRequired && (
          <input
            type="text"
            className="appearance-none border rounded-md text-xs border-gray-300 focus:border-gray-300 focus:outline-none focus:ring-0"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            required={isValueRequired}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        )}
      </div>
      <Button variant="primary-inverse" type="submit" size="xs">
        Apply
      </Button>
    </form>
  );
};

export default NameFilterDetails;
