import { AlignLeftSimple, AlignRightSimple } from '@phosphor-icons/react';
import { NavbarSerializableNode } from '@shared/dream-components';

import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';

import { useNavbarContext } from '../../../NavbarContext';

export const BurgerAlignmentSettings = ({ selectedContent }: { selectedContent: NavbarSerializableNode }) => {
  const { onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu') return null;

  const contentValue =
    selectedContent?.attrs && 'burgerJustify' in selectedContent.attrs ? selectedContent.attrs.burgerJustify : 'end';

  const handleBurgerJustifyChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { burgerJustify: value });
  };

  const value = contentValue?.toString() || 'true';

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Burger Position
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="true"
          value={value}
          onValueChange={handleBurgerJustifyChange}
        >
          <ToggleGroupItem value="start" className="grow">
            <AlignLeftSimple weight="fill" size={16} />
          </ToggleGroupItem>
          <ToggleGroupItem value="end" className="grow">
            <AlignRightSimple weight="fill" size={16} />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
