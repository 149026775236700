import { useCallback } from 'react';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';

import { DragHandle } from '../../extensions/DragHandleReact';
import { Button } from '../ui/Button';
import { Icon } from '../ui/Icon';

import { useDragHandleData } from './hooks';
import {
  AnchorSettingsPanel,
  BordersPanel,
  MainPanel,
  SpacingsPanel,
  VisibilitySettingsPanel,
  VisualSettingsPanel,
} from './panels';
import Styled from './styled';
import { PANEL } from './types';

interface IDragHandleButtonProps {
  editor: Editor;
  appendTo?: React.RefObject<any>;
  usesCollaboration?: boolean;
}

export const DragHandleButton = ({ editor, appendTo, usesCollaboration }: IDragHandleButtonProps) => {
  const { currentNode, currentNodePos, setCurrentNode, setCurrentNodePos, currentPanel, setCurrentPanel } =
    useDragHandleData();

  const navigateToPanel = useCallback(
    (panel: PANEL) => {
      setCurrentPanel(panel);
    },
    [setCurrentPanel]
  );

  const handleNodeChange = useCallback(
    ({ node, pos }: { node: Node | null; editor: Editor; pos: number }) => {
      if (node) {
        setCurrentNode(node);
        setCurrentNodePos(pos);
      }
    },
    [setCurrentNode, setCurrentNodePos]
  );

  let CurrentPanel = (
    <MainPanel
      editor={editor}
      currentNode={currentNode}
      currentNodePos={currentNodePos}
      navigateToPanel={navigateToPanel}
      usesCollaboration={usesCollaboration}
    />
  );

  const onTippyShown = useCallback(() => {
    setCurrentPanel(PANEL.main);
  }, [setCurrentPanel]);

  const onTippyShow = useCallback(() => {
    editor.commands.setMeta('lockDragHandle', true);
  }, [editor]);

  const onTippyHidden = useCallback(() => {
    setCurrentPanel(PANEL.main);
  }, [setCurrentPanel]);

  const onTippyHide = useCallback(() => {
    editor.commands.setMeta('lockDragHandle', false);
  }, [editor]);

  if (currentPanel === PANEL.visualSettings) {
    CurrentPanel = (
      <VisualSettingsPanel
        editor={editor}
        currentNode={currentNode}
        currentNodePos={currentNodePos}
        onBack={() => setCurrentPanel(PANEL.main)}
        navigateToPanel={navigateToPanel}
      />
    );
  }

  if (currentPanel === PANEL.spacings) {
    CurrentPanel = (
      <SpacingsPanel
        editor={editor}
        currentNode={currentNode}
        currentNodePos={currentNodePos}
        onBack={() => setCurrentPanel(PANEL.visualSettings)}
        navigateToPanel={navigateToPanel}
      />
    );
  }

  if (currentPanel === PANEL.borders) {
    CurrentPanel = (
      <BordersPanel
        editor={editor}
        currentNode={currentNode}
        currentNodePos={currentNodePos}
        onBack={() => setCurrentPanel(PANEL.visualSettings)}
        navigateToPanel={navigateToPanel}
      />
    );
  }

  if (currentPanel === PANEL.visibilitySettings) {
    CurrentPanel = (
      <VisibilitySettingsPanel
        editor={editor}
        currentNode={currentNode}
        currentNodePos={currentNodePos}
        onBack={() => setCurrentPanel(PANEL.main)}
      />
    );
  }

  if (currentPanel === PANEL.anchorSettings) {
    CurrentPanel = (
      <AnchorSettingsPanel
        editor={editor}
        currentNode={currentNode}
        currentNodePos={currentNodePos}
        onBack={() => setCurrentPanel(PANEL.main)}
      />
    );
  }

  return (
    <DragHandle
      editor={editor}
      className="drag-handle"
      onNodeChange={handleNodeChange}
      tippyOptions={{
        offset: [-1, 16],
        appendTo: () => {
          return appendTo?.current;
        },
        zIndex: EDITOR_MENUS_Z_INDEX,
      }}
    >
      <Styled.DragHandleButtonWrapper>
        <Tippy
          onShow={() => {
            if (currentNodePos !== -1) {
              const currentNodeSize = currentNode?.nodeSize || 0;
              const insertPos = currentNodePos + currentNodeSize;

              editor
                .chain()
                .command(({ dispatch, tr, state }) => {
                  if (dispatch) {
                    tr.insert(insertPos, state.schema.nodes.paragraph.create(null, [state.schema.text('/')]));

                    return dispatch(tr);
                  }

                  return true;
                })
                .focus(insertPos + 2)
                .run();
            }

            return false;
          }}
          trigger="click"
          zIndex={EDITOR_MENUS_Z_INDEX}
        >
          <div>
            <Button $leftSlot={<Icon name="Plus" />} $variant="tertiary" $size="small" $isIconButton />
          </div>
        </Tippy>

        <Tippy
          offset={[0, 8]}
          placement="bottom-start"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          onShown={onTippyShown}
          onShow={onTippyShow}
          onHidden={onTippyHidden}
          onHide={onTippyHide}
          trigger="click"
          interactive
          content={CurrentPanel}
          zIndex={EDITOR_MENUS_Z_INDEX}
        >
          <div>
            <Button $leftSlot={<Icon name="DragMenu" />} $variant="tertiary" $size="small" $isIconButton />
          </div>
        </Tippy>
      </Styled.DragHandleButtonWrapper>
    </DragHandle>
  );
};

export default DragHandleButton;
