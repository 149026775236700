import { FC, useCallback } from 'react';
import toast from 'react-hot-toast';

import { useCurrentPublication } from '@/hooks';
import { CustomFieldKind } from '@/interfaces/custom_field';
import { CustomFieldOption, Option } from '@/interfaces/general';
import { CustomField } from '@/interfaces/options';
import api from '@/services/swarm';

import { SearchDropdown } from '../Form';

interface Props {
  labelText?: string;
  helperText?: string;
  className?: string;
  labelClassName?: string;
  customFieldId?: string;
  onSelectCustomField: (customFieldId: string, option?: CustomFieldOption) => void;
  onClearCustomField: () => void;
  dropdownClassnames?: string;
  onFilterOptions?: (options: CustomFieldOption[]) => CustomFieldOption[];
  refetchFetchOptions?: boolean;
  disabled?: boolean;
  filterByKind?: CustomFieldKind;
}

const CustomFieldSelect: FC<Props> = (props: Props) => {
  const {
    labelText,
    helperText,
    className,
    labelClassName,
    customFieldId,
    onSelectCustomField,
    onClearCustomField,
    dropdownClassnames,
    onFilterOptions,
    refetchFetchOptions = false,
    disabled = false,
    filterByKind,
  } = props;
  const { data: currentPublication } = useCurrentPublication();

  const fetchCustomFields = useCallback(
    (inputValue?: string) => {
      const params = {
        publication_id: currentPublication?.id,
        q: inputValue,
        kind: filterByKind,
      };

      return api
        .get(`/options/custom_fields/`, { params })
        .then((res) => {
          const customFields = res.data?.options || [];

          const options: CustomFieldOption[] = customFields.map((custom_field: CustomField) => {
            const val: CustomFieldOption = {
              label: custom_field.display,
              value: custom_field.id,
              kind: custom_field.kind,
            };
            return val;
          });

          if (onFilterOptions) {
            return onFilterOptions(options);
          }
          return options;
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPublication, customFieldId, refetchFetchOptions, filterByKind]
  );

  const loadInitialValue = useCallback(() => {
    if (!customFieldId) {
      return undefined;
    }

    const params = {
      publication_id: currentPublication?.id,
      id: customFieldId,
      kind: filterByKind,
    };

    return api
      .get(`/options/custom_fields/`, { params })
      .then((res) => {
        const customFields = res.data?.options || [];
        const foundCustomField = customFields?.find((customField: CustomField) => customField.id === customFieldId);
        const onlyCustomField: CustomField = customFields.length > 0 ? customFields[0] : null;
        const option: Option = (foundCustomField || onlyCustomField) && {
          label: foundCustomField?.display || onlyCustomField?.display,
          value: foundCustomField?.display || onlyCustomField?.id,
        };

        return option;
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [currentPublication, customFieldId, filterByKind]);

  const handleSelect = (name: string, value: string, selectedOption?: Option) => {
    onSelectCustomField(value, selectedOption);
  };

  const handleClear = () => {
    onClearCustomField();
  };

  if (!currentPublication) {
    return null;
  }

  return (
    <SearchDropdown
      disabled={disabled}
      name="custom_field"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText="Select a Custom Field"
      value={customFieldId || ''}
      onSelect={handleSelect}
      onClear={handleClear}
      loadOptions={fetchCustomFields}
      loadInitialValue={loadInitialValue}
      labelClassName={labelClassName}
      className={className}
      dropdownClassnames={dropdownClassnames}
      emptyLabel="No custom fields found"
    />
  );
};

export default CustomFieldSelect;
