import { useInfiniteQuery, useQueryClient } from 'react-query';

import { Pagination } from '../../interfaces/general';
import { PublicationSubscriberTag } from '../../interfaces/subscriber_tag';
import api from '../../services/swarm';
import useCurrentPublicationId from '../usePublications/useCurrentPublicationId';

interface ApiResponse {
  subscriber_tags: PublicationSubscriberTag[];
  pagination: Pagination;
}

export default function useSearchSubscriberTags({
  search,
  perPage = 10,
  all = false,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
}) {
  const currentPublicationId = useCurrentPublicationId();
  const queryClient = useQueryClient();

  const fetchSubscriberTags = ({ pageParam = 1 }) =>
    api
      .get(`/publication_subscriber_tags`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([currentPublicationId, 'subscriber_tags', search], fetchSubscriberTags, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    retry: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      const subscriberTags = data.pages.flatMap((page) => page.subscriber_tags);

      subscriberTags.forEach((subscriberTagPreview: any) => {
        queryClient.setQueryData(['subscriber_tags', subscriberTagPreview?.id], {
          id: subscriberTagPreview?.id,
          name: subscriberTagPreview?.name,
          color: subscriberTagPreview?.color,
          subscriber_tag_id: subscriberTagPreview?.subscriber_tag_id,
          publication_subscriber_tag_id: subscriberTagPreview?.publication_subscriber_tag_id,
        });
      });
    },
    onError: (errPayload: any) => errPayload?.response?.data?.error || 'Something went wrong',
  });
}
