import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { Input } from './Input';

const Styled = {
  Wrapper: styled.label`
    align-items: center;
    display: flex;
    gap: 0.5rem;
  `,
  LabelWrapper: styled.div`
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 0.5rem;
    padding-left: 0.25rem;
  `,
  Label: styled.span`
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.6);
  `,
  InputWrapper: styled.div`
    flex: 0 0 auto;
    max-width: 3.75rem;

    input {
      -moz-appearance: textfield;
      appearance: text-field;
    }

    input::-webkit-inner-spin-button {
      appearance: none;
      display: hidden;
    }

    input::-moz-number-spin-box {
      appearance: none;
      display: hidden;
    }
  `,
  Input: styled.input`
    appearance: none;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 0.5rem;
    height: 1.75rem;
    margin: 0;
    outline: none;
    padding: 0;
    transition: all 0.3s;
    width: 100%;

    &::-webkit-slider-thumb {
      appearance: none;
      background-color: #77797c;
      border-radius: 0.5rem;
      border: 0;
      height: 1.75rem;
      transition: all 0.3s;
      width: 1rem;
    }

    &::-moz-range-thumb {
      appearance: none;
      background-color: #77797c;
      border-radius: 0.5rem;
      border: 0;
      height: 1.75rem;
      transition: all 0.3s;
      width: 1rem;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);

      &::-webkit-slider-thumb {
        background-color: #8e9194;
      }

      &::-moz-range-thumb {
        background-color: #8e9194;
      }
    }

    &:focus {
      &::-webkit-slider-thumb {
        transform: scale(1.025);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      }

      &::-moz-range-thumb {
        transform: scale(1.025);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &:active {
      &::-webkit-slider-thumb {
        transform: scale(1.1);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
      }

      &::-moz-range-thumb {
        transform: scale(1.1);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
      }
    }
  `,
};

export type RangeProps = {
  label?: string;
  min?: string;
  max?: string;
  step?: string;
  value: string;
  name?: string;
  withInput?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const Range = ({ label, withInput, onChange, onChangeInput, value, max, min, name, step }: RangeProps) => {
  return (
    <Styled.Wrapper>
      {(withInput || !!label) && (
        <Styled.LabelWrapper>
          {label && <Styled.Label>{label}</Styled.Label>}
          {withInput && (
            <Styled.InputWrapper>
              <Input
                type="number"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={onChangeInput || onChange}
                name={name}
              />
            </Styled.InputWrapper>
          )}
        </Styled.LabelWrapper>
      )}
      <Styled.Input type="range" value={value} min={min} max={max} step={step} onChange={onChange} name={name} />
    </Styled.Wrapper>
  );
};
