import { useQuery } from 'react-query';

import { ApplicationMetrics } from '@/interfaces/boosts/grow/application_metrics';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

const useApplicationMetrics = (boostAgreementId?: string, isEnabled: boolean = true) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchApplicationMetrics = (): Promise<ApplicationMetrics> =>
    api
      .get(`/boosts/grow/boost_agreements/${boostAgreementId}/application_metrics`, {
        params: { publication_id: currentPublicationId },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, 'boosts', 'grow', 'boost_agreement', boostAgreementId, 'application_metrics'],
    fetchApplicationMetrics,
    { enabled: isEnabled }
  );
};

export default useApplicationMetrics;
