import { FC } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-mini';

import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';

import { StripeMigration, StripeMigrationStatus } from '../../../interfaces/stripe';

import StatusBadge from './StatusBadge';

interface Props {
  migrations?: StripeMigration[];
}

const MigrationsTable: FC<Props> = ({ migrations }: Props) => {
  if (!migrations) {
    return null;
  }

  return (
    <Items>
      <ItemHeaders>
        <ItemHeader>Started At</ItemHeader>
        <ItemHeader>Finished At</ItemHeader>
        <ItemHeader>Status</ItemHeader>
        <ItemHeader>Total Subscriptions Created</ItemHeader>
        <ItemHeader />
      </ItemHeaders>
      <ItemsBody>
        {migrations.map((migration) => (
          <ItemRow key={migration.id}>
            <ItemColumn align={AlignType.MIDDLE}>
              <Typography token="font-medium/text/sm">
                {migration.started_at
                  ? moment(migration.started_at).format('lll')
                  : moment(migration.created_at).format('lll')}
              </Typography>
            </ItemColumn>
            <ItemColumn align={AlignType.MIDDLE}>
              <Typography token="font-medium/text/sm">
                {migration.finished_at ? moment(migration.finished_at).format('lll') : '-'}
              </Typography>
            </ItemColumn>
            <ItemColumn align={AlignType.MIDDLE}>
              <StatusBadge status={migration.status} />
            </ItemColumn>
            <ItemColumn align={AlignType.MIDDLE}>
              <Typography token="font-medium/text/sm">{migration.num_subscriptions_created}</Typography>
            </ItemColumn>
            <ItemColumn align={AlignType.MIDDLE}>
              {migration.status === StripeMigrationStatus.PENDING && (
                <Link to={`/stripe_migrations/${migration.id}/account_details`}>
                  <span className="text-action-tertiary-600">Resume</span>
                </Link>
              )}
              {migration.status === StripeMigrationStatus.PREVIEW_PROCESSED && (
                <Link to={`/stripe_migrations/${migration.id}/preview_subscriptions`}>
                  <span className="text-action-tertiary-600">Resume</span>
                </Link>
              )}
            </ItemColumn>
          </ItemRow>
        ))}
      </ItemsBody>
    </Items>
  );
};

export default MigrationsTable;
