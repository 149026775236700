import { HandRaisedIcon } from '@heroicons/react/20/solid';

interface Props {
  children: React.ReactNode;
}

const HelpingHand = ({ children }: Props) => {
  return (
    <div className="bg-blue-50 p-4 rounded space-y-2">
      <div className="flex space-x-1 items-center">
        <HandRaisedIcon className="h-3 w-3 font-bold text-xs text-blue-800" />
        <span className="font-bold text-xs text-blue-800">Helping Hand:</span>
      </div>
      <div className="text-xs text-blue-800 font-regular space-y-2">{children}</div>
    </div>
  );
};

export default HelpingHand;
