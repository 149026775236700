import React, { ErrorInfo, ReactNode } from 'react';
import { AxiosError } from 'axios';

import ErrorPage, { ErrorState } from '../../pages/Error';

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<Props, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any child components and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // Log the errors to a service here
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(errorInfo);
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    const isAxiosError = (e: Error | null): e is AxiosError => {
      return e?.name === 'Error';
    };

    if (errorInfo) {
      let statusCode = 500;

      if (isAxiosError(error)) {
        statusCode = error.response?.status || 500;
      }

      return <ErrorPage statusCode={statusCode} errorState={{ error, errorInfo }} />;
    }
    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
