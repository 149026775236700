import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { Chart as ChartJS, ChartOptions, CoreScaleOptions, Scale } from 'chart.js/auto';

import styledChartData from './utils/styledChartData';
import { ChartType, ChartVariant } from './utils/styledChartData.types';

interface DataChartProps {
  overrideVariant?: ChartVariant;
  data: {
    labels: string[];
    datasets: Array<{
      label: string;
      data: number[];
      type: ChartType;
      variant: ChartVariant;
    }>;
  };
  missingDataText?: string;
  height?: number;
}

const DataChart = ({ data, missingDataText, height, overrideVariant }: DataChartProps) => {
  const hasData = !data || !data.datasets.length || !(data.datasets[0].data.length < 1);

  const ref = useRef<ChartJS<'line'> | null>(null);

  const [chartData, setChartData] = useState(data);

  useEffect(() => {
    const chart = ref.current;

    if (!chart) return;

    const withStyles = styledChartData({
      data: {
        ...data,
        datasets: data.datasets.map((dataset) => ({
          ...dataset,
          variant: dataset.variant === 'muted' ? dataset.variant : overrideVariant || dataset.variant,
        })),
      },
      chart: { ctx: chart.ctx, area: chart.chartArea },
    });

    setChartData(withStyles);
  }, [data, overrideVariant]);

  const defaultOptions: ChartOptions<'line' | 'bar'> = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        display: true,
        beginAtZero: true,
        grid: { drawOnChartArea: false },
        ticks: { align: 'inner' },
        afterBuildTicks: (axis: Scale<CoreScaleOptions>) => {
          const { ticks } = axis;

          if (ticks.length > 2) {
            ticks.splice(1, ticks.length - 2);
          }
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        grid: { drawOnChartArea: false },
        position: 'left',
        ticks: { align: 'center' },
      },
    },
  };

  return hasData ? (
    <div style={{ height }}>
      <Chart type="line" ref={ref} data={chartData} options={defaultOptions} />
    </div>
  ) : (
    <div className="flex items-center justify-center h-full py-8">
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="rounded-full bg-gray-200 w-8 h-8 flex items-center justify-center">
          <ChartBarSquareIcon className="block w-5 h-5 text-gray-400" />
        </div>
        <p className=" text-gray-600">{missingDataText}</p>
      </div>
    </div>
  );
};

export default DataChart;
