import moment from 'moment-mini';

import { PublicationLogo } from '@/components/PublicationLogo';
import RegionalRestrictionsTooltip from '@/components/RegionalRestrictionsTooltip';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import useFormattedTopLineMetrics from '@/hooks/boosts/grow/useFormattedTopLineMetrics';
import useBoostOfferEligibleCountries from '@/hooks/boosts/useBoostOfferEligibleCountries';
import { BoostAgreement } from '@/interfaces/boosts/monetize/boost_agreement';

import AgreementActions from './AgreementActions';
import BoostAgreementStatusBadge from './BoostAgreementStatusBadge';

interface Props {
  boostAgreement: BoostAgreement;
  onActionSuccess: () => void;
  showCPA?: boolean;
  showActions?: boolean;
}

const BoostAgreementListItem = ({ boostAgreement, onActionSuccess, showCPA = false, showActions = true }: Props) => {
  const { totalSpend, pendingSpend } = useFormattedTopLineMetrics(boostAgreement.top_line_metrics);
  const { hasRegionalRestrictions, regionalRestrictionsTooltip } = useBoostOfferEligibleCountries(
    boostAgreement.boost_offer.eligible_countries
  );

  return (
    <ItemRow>
      <ItemColumn align={AlignType.TOP}>
        {showCPA ? (
          <TypographyStack>
            <Typography token="font-medium/text/base">{boostAgreement.boost_offer.payout_per_lead}</Typography>
            {hasRegionalRestrictions && (
              <RegionalRestrictionsTooltip
                id={`regional-restrictions-tooltip-${boostAgreement.boost_offer.id}`}
                text={regionalRestrictionsTooltip}
              />
            )}
            <Typography token="font-normal/text/xs" colorWeight="500">
              Created{' '}
              <time dateTime={boostAgreement.created_at as string}>{moment(boostAgreement.created_at).fromNow()}</time>{' '}
            </Typography>
          </TypographyStack>
        ) : (
          <div className="w-64 flex items-center gap-x-2">
            <PublicationLogo publication={boostAgreement.boost_offer.boosted_publication} size="sm" />
            <Typography weight="medium" colorWeight="900">
              {boostAgreement.boost_offer.boosted_publication.name}
            </Typography>
          </div>
        )}
      </ItemColumn>
      <ItemColumn>
        <BoostAgreementStatusBadge status={boostAgreement.status} />
      </ItemColumn>
      {!showCPA && (
        <ItemColumn>
          <Typography size="sm" colorWeight="700">
            {boostAgreement.boost_offer.payout_per_lead}
          </Typography>
        </ItemColumn>
      )}
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostAgreement.top_line_metrics.total_pending_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostAgreement.top_line_metrics.total_accepted_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostAgreement.top_line_metrics.total_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {pendingSpend}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {totalSpend}
        </Typography>
      </ItemColumn>
      {showActions && (
        <ItemColumn isSticky className="w-full h-full flex items-center">
          <AgreementActions boostAgreement={boostAgreement} onSuccess={onActionSuccess} />
        </ItemColumn>
      )}
    </ItemRow>
  );
};

export default BoostAgreementListItem;
