import { createContext } from 'react';

export const ImageBlockContext = createContext<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
  image: string | null;
  setImage: (image: string | null) => void;
  updateAttributes: (attrs: Record<string, string>) => void;
}>({
  loading: false,
  setLoading: () => {},
  image: null,
  setImage: () => {},
  updateAttributes: () => {},
});

export default ImageBlockContext;
