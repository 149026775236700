import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Author } from '@/interfaces/author';
import api from '@/services/swarm';

const useAuthors = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<Author[]>(
    ['authors', currentPublicationId],
    () =>
      api
        .get('/authors', {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!currentPublicationId,
    }
  );
};

export default useAuthors;
