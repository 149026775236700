import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { LandingPage } from '@/interfaces/landing_page';
import Switch from '@/ui/Switch';
import { Textarea } from '@/ui/Textarea';

import { ImageSelect, Input } from '../../../../components/Form';
import ColorField from '../../../../components/Form/ColorField';
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { useUpdateLandingPage } from '../../../../hooks/useLandingPages';
import { FONT_SIZE_OPTIONS } from '../../../../interfaces/font';
import api from '../../../../services/swarm';
import ThemeEditorDropdown from '../../../ThemeEditor/components/ThemeEditorDropdown';
import ThemeFormDrawer from '../../../ThemeEditor/components/ThemeFormDrawer';
import { Divider, FormContainer, FormInput, FormSection } from '../../../ThemeEditor/helpers';
import MarkDownHelperText from '../../components/MardownHelperText';
import { usePageContext } from '../../components/PageContext';
import { OPACITY_OPTIONS } from '../../utils/designOptions';

const MainSection = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const {
    pageData: landingPage,
    colorPalette,
    setIsSaving,
    handleSaved: onSave,
    fontOptions,
  } = usePageContext<LandingPage>();

  // Main Section States
  const [bgImage, setBgImage] = useState<File | string>(landingPage?.main_bg_image_url || '');
  const [enableFullHeight, setEnableFullHeight] = useState(!landingPage?.main_section?.disable_full_height || false);
  const [image, setImage] = useState<File | string>(landingPage?.main_image_url || '');
  const [bgColor, setBgColor] = useState(landingPage?.main_section?.bg_color || '#000000');
  const [bgGlossOpacity, setBgGlossOpacity] = useState(landingPage?.main_section?.bg_gloss_opacity || '0');
  const [overlineColor, setOverlineColor] = useState(landingPage?.main_section?.overline_color || '#000000');
  const [overline, setOverline] = useState(landingPage?.main_section?.overline || '');
  const [overlineFontFamily, setOverlineFontFamily] = useState(landingPage?.main_section?.overline_font_family || '');
  const [overlineFontSize, setOverlineFontSize] = useState(landingPage?.main_section?.overline_font_size || 'xs');
  const [headlineColor, setHeadlineColor] = useState(landingPage?.main_section?.headline_color || '#000000');
  const [headline, setHeadline] = useState(landingPage?.main_section?.headline || '');
  const [headlineFontFamily, setHeadlineFontFamily] = useState(landingPage?.main_section?.headline_font_family || '');
  const [headlineFontSize, setHeadlineFontSize] = useState(landingPage?.main_section?.headline_font_size || '6xl');
  const [descriptionColor, setDescriptionColor] = useState(landingPage?.main_section?.description_color || '#000000');
  const [description, setDescription] = useState(landingPage?.main_section?.description || '');
  const [descriptionFontFamily, setDescriptionFontFamily] = useState(
    landingPage?.main_section?.description_font_family || ''
  );
  const [descriptionFontSize, setDescriptionFontSize] = useState(
    landingPage?.main_section?.description_font_size || '6xl'
  );
  const [underlineColor, setUnderlineColor] = useState(landingPage?.main_section?.underline_color || '#000000');
  const [underline, setUnderline] = useState(landingPage?.main_section?.underline || '');
  const [underlineFontFamily, setUnderlineFontFamily] = useState(
    landingPage?.main_section?.underline_font_family || ''
  );
  const [underlineFontSize, setUnderlineFontSize] = useState(landingPage?.main_section?.underline_font_size || 'sm');
  const [readMoreCTAEnabled, setReadMoreCTAEnabled] = useState<boolean>(
    landingPage?.main_section?.read_more_cta_enabled || true
  );
  const [readMoreCTA, setReadMoreCTA] = useState(landingPage?.main_section?.read_more_cta || '');
  const [readMoreCTAColor, setReadMoreCTAColor] = useState(landingPage?.main_section?.read_more_cta_color || '#000000');
  const [readMoreCTAFontFamily, setReadMoreCTAFontFamily] = useState(
    landingPage?.main_section?.read_more_cta_font_family || ''
  );
  const [readMoreCTAFontSize, setReadMoreCTAFontSize] = useState(
    landingPage?.main_section?.read_more_cta_font_size || 'md'
  );
  const [signupBgColor, setSignupBgColor] = useState(landingPage?.main_section?.signup_bg_color || '#000000');
  const [signupTextColor, setSignupTextColor] = useState(landingPage?.main_section?.signup_text_color || '#000000');
  const [signupCTAFontFamily, setSignupCTAFontFamily] = useState(
    landingPage?.main_section?.signup_cta_font_family || ''
  );
  const [signupPlaceholderText, setSignupPlaceholderText] = useState(
    landingPage?.main_section?.signup_placeholder_text || 'Enter your email'
  );
  const [signupCtaText, setSignupCtaText] = useState(landingPage?.main_section?.signup_cta_text || 'Subscribe');

  useEffect(() => {
    setBgImage(landingPage?.main_bg_image_url || '');
    setBgGlossOpacity(landingPage?.main_section?.bg_gloss_opacity || '0');
    setImage(landingPage?.main_image_url || '');
    setBgColor(landingPage?.main_section?.bg_color || '#000000');
    setEnableFullHeight(!landingPage?.main_section?.disable_full_height || false);
    setOverlineColor(landingPage?.main_section?.overline_color || '#000000');
    setOverline(landingPage?.main_section?.overline || '');
    setOverlineFontFamily(landingPage?.main_section?.overline_font_family || '');
    setOverlineFontSize(landingPage?.main_section?.overline_font_size || 'xs');
    setHeadlineColor(landingPage?.main_section?.headline_color || '#000000');
    setHeadline(landingPage?.main_section?.headline || '');
    setHeadlineFontFamily(landingPage?.main_section?.headline_font_family || '');
    setHeadlineFontSize(landingPage?.main_section?.headline_font_size || '6xl');
    setDescriptionColor(landingPage?.main_section?.description_color || '#000000');
    setDescription(landingPage?.main_section?.description || '');
    setDescriptionFontFamily(landingPage?.main_section?.description_font_family || '');
    setDescriptionFontSize(landingPage?.main_section?.description_font_size || '2xl');
    setUnderlineColor(landingPage?.main_section?.underline_color || '#000000');
    setUnderline(landingPage?.main_section?.underline || '');
    setUnderlineFontSize(landingPage?.main_section?.underline_font_size || 'sm');
    setUnderlineFontFamily(landingPage?.main_section?.underline_font_family || '');
    setReadMoreCTAEnabled(landingPage?.main_section?.read_more_cta_enabled);
    setReadMoreCTAColor(landingPage?.main_section?.read_more_cta_color || '#000000');
    setReadMoreCTA(landingPage?.main_section?.read_more_cta || '');
    setReadMoreCTAFontFamily(landingPage?.main_section?.read_more_cta_font_family || '');
    setReadMoreCTAFontSize(landingPage?.main_section?.read_more_cta_font_size || 'md');
    setSignupBgColor(landingPage?.main_section?.signup_bg_color || '#000000');
    setSignupTextColor(landingPage?.main_section?.signup_text_color || '#000000');
    setSignupCTAFontFamily(landingPage?.main_section?.signup_cta_font_family || '');
    setSignupPlaceholderText(landingPage?.main_section?.signup_placeholder_text || 'Enter your email');
    setSignupCtaText(landingPage?.main_section?.signup_cta_text || 'Subscribe');
  }, [landingPage]);

  const updateLandingPage = useUpdateLandingPage({
    pageId: landingPage?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['landing_page', '/subscribe', 'draft']);
      onSave();
    },
  });

  const handleSaveMainSection = (payload: any) => {
    setIsSaving(true);
    updateLandingPage.mutate({ landing_page: { main_section: JSON.stringify(payload) } });
  };

  const handleUploadImage = (type: string, file: File | null) => {
    setIsSaving(true);
    const formData = new FormData();

    if (typeof file !== 'string') {
      formData.append(`landing_page[${type}]`, file || '');
    }

    formData.append('publication_id', currentPublicationId);

    api
      .patch(`/landing_pages/${landingPage?.id}`, formData)
      .then(() => {
        queryClient.invalidateQueries(['landing_page', '/subscribe', 'draft']);
        onSave();
      })
      .catch((err) => {
        setIsSaving(false);
        toast.error(err?.response?.data?.error || 'Something went wrong');
      });
  };

  return (
    <ThemeFormDrawer active={false} title="Main Section" onClickHandler={() => {}}>
      <FormContainer>
        <FormSection title="General">
          <FormInput title="Full Height Enabled">
            <Switch
              variant="primary"
              name="enabled"
              checked={enableFullHeight}
              onChange={(_name: string, updatedValue: boolean) => {
                setEnableFullHeight(!updatedValue);
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  disable_full_height: !updatedValue,
                });
              }}
            />
            <p className="mt-2 text-xs text-gray-500">
              By default we stretch the top section to fill the full height of the screen. If you want to disable this
              so other sections can appear at the bottom of the screen, disable this setting.
            </p>
          </FormInput>
          <FormInput
            title="Background Color"
            helperText="Note: If a background image has been applied this will be the color of the gloss-effect / overlay applied to the image."
          >
            <ColorField
              placement="bottom"
              color={bgColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setBgColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  bg_color: bgColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Gloss effect opacity" helperText="Add a gloss-effect to your background image.">
            <ThemeEditorDropdown
              currentValue={bgGlossOpacity}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  bg_gloss_opacity: val,
                })
              }
              options={OPACITY_OPTIONS.map((option) => {
                return {
                  label: `${option}%`,
                  value: option,
                };
              })}
            />
          </FormInput>
          <FormInput title="Background Image">
            <ImageSelect
              name="main_bg_image"
              dimensionSuggestion="Upload a high-quality 1920px x 1080px PNG for best results."
              onFileSelect={(file) => {
                setBgImage(file);
                handleUploadImage('main_bg_image', file);
              }}
              onFileClear={() => {
                setBgImage('');
                handleUploadImage('main_bg_image', null);
              }}
              file={bgImage}
            />
          </FormInput>
          <FormInput title="Image">
            <ImageSelect
              name="main_image"
              dimensionSuggestion="high-quality PNGs are recommended for best results."
              onFileSelect={(file) => {
                setImage(file);
                handleUploadImage('main_image', file);
              }}
              onFileClear={() => {
                setImage('');
                handleUploadImage('main_image', null);
              }}
              file={image}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Overline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={overlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setOverlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  overline_color: overlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text" helperText={<MarkDownHelperText />}>
            <Textarea
              name="overline"
              value={overline}
              maxLength={150}
              onChange={(e) => setOverline(e.target.value)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  overline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={overlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  overline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={overlineFontSize}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  overline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Headline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={headlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setHeadlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  headline_color: headlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text" helperText={<MarkDownHelperText />}>
            <Textarea
              name="headline"
              value={headline}
              maxLength={150}
              onChange={(e) => setHeadline(e.target.value)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  headline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={headlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  headline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={headlineFontSize}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  headline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Description">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={descriptionColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setDescriptionColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  description_color: descriptionColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text" helperText={<MarkDownHelperText />}>
            <Textarea
              name="description"
              value={description}
              maxLength={1000}
              onChange={(e) => setDescription(e.target.value)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  description,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={descriptionFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  description_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={descriptionFontSize}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  description_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Signup Form">
          <FormInput title="Background Color">
            <ColorField
              placement="bottom"
              color={signupBgColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setSignupBgColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  signup_bg_color: signupBgColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text Color">
            <ColorField
              placement="bottom"
              color={signupTextColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setSignupTextColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  signup_text_color: signupTextColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={signupCTAFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  signup_cta_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Button Text">
            <Input
              name="signup_cta_text"
              value={signupCtaText}
              maxLength={25}
              helperText="Character limit: 25"
              onChange={(e) => setSignupCtaText(e.target.value)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  signup_cta_text: signupCtaText,
                });
              }}
            />
          </FormInput>
          <FormInput title="Placeholder Text">
            <Input
              name="signup_placeholder_text"
              value={signupPlaceholderText}
              maxLength={30}
              // Char limit helper text added here because they are so short and we want to avoid confusing user.
              // The hope is char limits above won't ever be hit and are just protections against people bloating the json
              helperText="Character limit: 30"
              onChange={(e) => setSignupPlaceholderText(e.target.value)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  signup_placeholder_text: signupPlaceholderText,
                });
              }}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Underline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={underlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setUnderlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  underline_color: underlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text" helperText={<MarkDownHelperText />}>
            <Textarea
              name="underline"
              value={underline}
              maxLength={150}
              onChange={(e) => setUnderline(e.target.value)}
              onBlur={() => {
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  underline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={underlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  underline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={underlineFontSize}
              onUpdate={(val: string) =>
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  underline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Redirect Link">
          <FormInput title="Enabled">
            <Switch
              variant="primary"
              name="read_more_cta_enabled"
              checked={readMoreCTAEnabled}
              onChange={(_name: string, updatedValue: boolean) => {
                setReadMoreCTAEnabled(updatedValue);
                handleSaveMainSection({
                  ...landingPage?.main_section,
                  read_more_cta_enabled: updatedValue,
                });
              }}
            />
          </FormInput>
          {readMoreCTAEnabled && (
            <>
              <FormInput title="Color">
                <ColorField
                  placement="bottom"
                  color={readMoreCTAColor}
                  colorPalette={colorPalette}
                  onColorChange={(color: any) => setReadMoreCTAColor(color?.hexString)}
                  onBlur={() => {
                    handleSaveMainSection({
                      ...landingPage?.main_section,
                      read_more_cta_color: readMoreCTAColor,
                    });
                  }}
                />
              </FormInput>
              <FormInput title="Text">
                <Input
                  name="read_more_cta"
                  value={readMoreCTA}
                  maxLength={150}
                  helperText="In the Design Lab, this link and others are non-interactive. They will be clickable on the live site, with this particular link redirecting users to your homepage."
                  onChange={(e) => setReadMoreCTA(e.target.value)}
                  onBlur={() => {
                    handleSaveMainSection({
                      ...landingPage?.main_section,
                      read_more_cta: readMoreCTA,
                    });
                  }}
                />
              </FormInput>
              <FormInput title="Font family">
                <ThemeEditorDropdown
                  currentValue={readMoreCTAFontFamily}
                  onUpdate={(val: string) =>
                    handleSaveMainSection({
                      ...landingPage?.main_section,
                      read_more_cta_font_family: val,
                    })
                  }
                  options={fontOptions}
                />
              </FormInput>
              <FormInput title="Font size">
                <ThemeEditorDropdown
                  currentValue={readMoreCTAFontSize}
                  onUpdate={(val: string) =>
                    handleSaveMainSection({
                      ...landingPage?.main_section,
                      read_more_cta_font_size: val,
                    })
                  }
                  options={FONT_SIZE_OPTIONS}
                />
              </FormInput>
            </>
          )}
        </FormSection>
      </FormContainer>
    </ThemeFormDrawer>
  );
};

export default MainSection;
