import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import { Tab } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import TabNavigation from '@/ui/TabNavigation';

import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { ExternalEmbed } from '../../../../../interfaces/external_embed';
import api from '../../../../../services/swarm';

import EditSubscibeForm from './Edit';
import List from './List';
import NewSubscribeForm from './New';
import ShowSubscribeForm from './Show';

interface ExternalEmbedsIndexResponse {
  external_embeds: ExternalEmbed[];
}

const SubscribeForms = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab') || 'overview';

  const [publicationId] = useCurrentPublicationState();
  const [isCreatingNewForm, setIsCreatingNewForm] = useState<boolean>(false);

  const { data: externalEmbeds } = useQuery<ExternalEmbed[]>(['externalEmbeds', publicationId], () =>
    api
      .get<ExternalEmbedsIndexResponse>(`/publications/${publicationId}/external_embeds`)
      .then((res) => res.data.external_embeds)
  );

  const externalEmbedIdInUrl = searchParams.get('id');
  const shouldViewExternalEmbed = searchParams.get('view') === 'true';
  const editableExternalEmbed =
    externalEmbedIdInUrl && !shouldViewExternalEmbed
      ? externalEmbeds?.find(({ id }) => id === externalEmbedIdInUrl)
      : undefined;

  const closeEditForm = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  const closeViewExternalEmbedForm = () => {
    searchParams.delete('id');
    searchParams.delete('view');
    setSearchParams(searchParams);
  };

  const tabs: Tab[] = [
    {
      name: 'overview',
      label: 'Overview',
      selected: selectedTab === 'overview',
      onSelect: () => navigate(`/subscribe_forms?tab=overview`),
    },
  ];

  const isSettingsTabEnabled = window.env.REACT_APP_EXTERNAL_EMBED_CLOUDFLARE_TURNSTILE_ENABLED;
  if (isSettingsTabEnabled) {
    tabs.push({
      name: 'Settings',
      label: 'Settings',
      selected: selectedTab === 'settings',
      onSelect: () => navigate(`/subscribe_forms?tab=settings`),
    });
  }

  return (
    <>
      <EditSubscibeForm
        isOpen={Boolean(externalEmbedIdInUrl)}
        externalEmbed={editableExternalEmbed}
        onClose={closeEditForm}
      />
      <NewSubscribeForm isOpen={isCreatingNewForm} onClose={() => setIsCreatingNewForm(false)} />
      <ShowSubscribeForm
        isOpen={shouldViewExternalEmbed}
        externalEmbedId={externalEmbedIdInUrl}
        onClose={closeViewExternalEmbedForm}
      />
      <div className="space-y-5">
        <PageHeading
          title="Subscribe Forms"
          description="Create customizable and embeddable email subscribe forms for your other websites."
        >
          <div className="flex justify-end">
            <Button onClick={() => setIsCreatingNewForm(true)}>Create new form</Button>
          </div>
        </PageHeading>
        {isSettingsTabEnabled ? <TabNavigation tabs={tabs} variant="tertiary" className="w-full" /> : null}
        {selectedTab === 'overview' || !isSettingsTabEnabled ? <List externalEmbeds={externalEmbeds} /> : null}
      </div>
    </>
  );
};

export default SubscribeForms;
