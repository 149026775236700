import { CustomDomain } from '@/interfaces/custom_domain';
import { Publication } from '@/interfaces/publication';
import { Card } from '@/ui/Card';

import BodyContainer from '../../../_components/BodyContainer';
import CardHeader from '../../../_components/CardHeader';

import { DOMAINS_HEADER_TEXTS } from './constants';
import EmailCustom from './EmailCustom';
import EmailShared from './EmailShared';
import Redirect from './Redirect';
import WebCustom from './WebCustom';
import WebShared from './WebShared';

interface Props {
  publication: Publication;
  emailDomain?: CustomDomain;
  webDomain?: CustomDomain;
  redirectDomain?: CustomDomain;
  onSharedInfra: boolean;
  smartWarmingActive: boolean;
  disabled: boolean;
}

const DomainsCard: React.FC<Props> = ({
  publication,
  emailDomain,
  webDomain,
  redirectDomain,
  onSharedInfra,
  smartWarmingActive,
  disabled,
}) => {
  const { title, description } = onSharedInfra ? DOMAINS_HEADER_TEXTS.shared : DOMAINS_HEADER_TEXTS.other;

  return (
    <Card>
      <BodyContainer>
        <CardHeader title={title} description={description} />

        {!emailDomain && (
          <EmailShared
            username={publication.subdomain || ''}
            onSharedInfra={onSharedInfra}
            publicationId={publication.id}
            disabled={disabled}
          />
        )}
        {emailDomain && (
          <EmailCustom
            customDomain={emailDomain}
            subdomain={publication.subdomain || ''}
            onSharedWeb={!webDomain}
            publicationId={publication.id}
            disabled={disabled}
          />
        )}

        {!webDomain && (
          <WebShared
            subdomain={publication.subdomain || ''}
            onSharedInfra={onSharedInfra}
            smartWarmingActive={smartWarmingActive}
            publicationId={publication.id}
            disabled={disabled}
          />
        )}
        {webDomain && (
          <WebCustom
            publicationId={publication.id}
            customDomain={webDomain}
            customRedirectDomain={redirectDomain}
            disabled={disabled}
          />
        )}

        {redirectDomain && <Redirect customDomain={redirectDomain} disabled={disabled} />}
      </BodyContainer>
    </Card>
  );
};

export default DomainsCard;
