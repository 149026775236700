import { useState } from 'react';
import { UseMutationResult } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { v4 as generateUuid } from 'uuid';

import { Input } from '@/components/Form';
import { AdNetworkWorkspaceView } from '@/interfaces/ad_network/internal/workspace_view';
import { LogicalOperators } from '@/interfaces/condition';
import useFilteringContext, {
  FilteringContext,
} from '@/routes/ad_network/_components/PublicationFilters/filtering-context';
import Filters from '@/routes/ad_network/_components/PublicationFilters/Filters';
import { ConditionGroup } from '@/routes/ad_network/_components/PublicationFilters/types';
import useConditionManager from '@/routes/ad_network/_components/PublicationFilters/useConditionManager';
import { Button } from '@/ui/Button';

interface FormProps {
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  workspaceView?: AdNetworkWorkspaceView;
}

const Form = ({ onSubmitMutation, workspaceView }: FormProps) => {
  const { mutateAsync, isLoading } = onSubmitMutation;
  const [name, setName] = useState(workspaceView?.name || '');
  const [description, setDescription] = useState(workspaceView?.description || '');

  const { state } = useFilteringContext();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    formData.set('workspace_view[name]', name);
    formData.set('workspace_view[description]', description);
    formData.set('workspace_view[conditions]', JSON.stringify(state));

    mutateAsync(formData);
  };

  return (
    <div className="p-4">
      <div className="max-w-xl mx-auto w-full space-y-4 divide-y">
        <form className="divide-y" onSubmit={onSubmit} id="workspace-view-form">
          <div className="space-y-6 pb-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
              <p className="text-sm text-gray-500">
                Give your view a name and description to help you (and others) identify it later. Note that views can be
                seen and modified by all ad network admins.
              </p>
            </div>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="workspace_view[name]"
              labelText="Name"
              type="text"
              helperText={`A public identifier for the view (e.g. "With ad network access")`}
              required
            />
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              name="workspace_view[description]"
              labelText="Description"
              type="text"
              helperText={`A brief description to explain the purpose of the view (e.g. "Publications on scale or max plans.")`}
              required
            />
          </div>
        </form>
        <div className="space-y-6 py-6">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Filters</h2>
            <p className="text-sm text-gray-500">
              Add filters to your view to limit the publications that are shown. You can add multiple filters to create
              complex views.
            </p>
          </div>
          <Filters />
        </div>

        <Button type="submit" loading={isLoading} form="workspace-view-form">
          Save
        </Button>
      </div>
    </div>
  );
};

const ContextLoader = ({ workspaceView, ...rest }: FormProps) => {
  // get local storage key to find conditions from search params
  const [searchParams] = useSearchParams();
  const localStorageKey = searchParams.get('conditions_key');
  let localStorageConditions;
  try {
    localStorageConditions = localStorageKey ? JSON.parse(localStorage.getItem(localStorageKey) as string) : null;
  } catch (e) {
    localStorageConditions = null;
  }

  const defaultConditions = {
    type: 'group',
    logical_operator: LogicalOperators.AND,
    conditions: [],
    uuid: generateUuid(),
  } as ConditionGroup;

  const conditionsManager = useConditionManager({
    initialState: workspaceView?.conditions || localStorageConditions || defaultConditions,
    disableShortcuts: true,
  });

  return (
    <FilteringContext.Provider value={conditionsManager}>
      <Form workspaceView={workspaceView} {...rest} />
    </FilteringContext.Provider>
  );
};

export default ContextLoader;
