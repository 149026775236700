import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';

interface Props {
  label: string;
  to: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  isSelected: boolean;
  isDisabled?: boolean;
  className?: string;
}

const NavLink = ({ label, to, onClick, isSelected, isDisabled, className }: Props) => {
  if (isDisabled) {
    return (
      <Tooltip
        tooltipClass="text-center"
        id={`publication-${to}-tooltip`}
        text={NO_PERMISSION_MESSAGE}
        showIcon={false}
        autoWidth={false}
        placement="right"
      >
        <div className={classNames(
          'p-2 rounded-lg flex flex-row gap-x-0.5 cursor-not-allowed',
          isSelected && 'bg-surface-200',
          className
        )}
        >
          <Typography token="font-medium/text/sm" colorWeight="400">
            {label}
          </Typography>
        </div>
      </Tooltip>
    )
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      className={classNames(
        'p-2 rounded-lg flex flex-row gap-x-0.5',
        isSelected ? 'bg-surface-200' : 'hover:bg-surface-100',
        className
      )}
    >
      <Typography token="font-medium/text/sm" colorWeight={isSelected ? '900' : '700'}>
        {label}
      </Typography>
    </Link>
  );
};

export default NavLink;
