import { FC } from 'react';

import Card from '@/components/Card';
import { PieChart, PieChartDatum as Datum } from '@/components/charts';
import { LoadingSpinner } from '@/components/LoadingSpinner';

import SubscriberReferralChartContainer from './SubscriberReferralChartContainer';

export interface Breakdown {
  num_subscribers_with_referrals: number;
  num_subscribers_without_referrals: number;
}

interface Props {
  className?: string;
}

const SubscriberReferralChart: FC<Props> = (props: Props) => {
  const { className } = props;

  const renderEmptyState = () => <p className="mt-4">No data</p>;

  const renderBody = (subscriberBreakdown: Datum[] | undefined, isLoading: boolean, isEmpty: boolean) => {
    if (!isLoading && isEmpty) {
      return renderEmptyState();
    }

    return subscriberBreakdown && <PieChart data={subscriberBreakdown} />;
  };

  return (
    <SubscriberReferralChartContainer>
      {(subscriberBreakdown, isLoading, isEmpty) => (
        <Card className={className}>
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4 flex">
            Subscribers with at least 1 referral
            {isLoading && <LoadingSpinner className="ml-2" />}
          </h3>
          {renderBody(subscriberBreakdown, isLoading, isEmpty)}
        </Card>
      )}
    </SubscriberReferralChartContainer>
  );
};

export default SubscriberReferralChart;
