import { Outlet } from 'react-router-dom';

import MasqueradeBanner from '../../MasqueradeBanner';

export default function DefaultLayout() {
  return (
    <div className="h-full flex flex-col min-h-screen bg-gray-100">
      <div>
        <MasqueradeBanner />
        <Outlet />
      </div>
    </div>
  );
}
