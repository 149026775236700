import { RewardType } from '@/interfaces/reward';

const rewardTypeLabels: Record<RewardType, string> = {
  physical: 'Physical',
  promo_code: 'Promo Code',
  digital: 'Digital',
  premium_gift: 'Paid Subscription Gift',
};

export const SINGLE_PROMO_CODE_LABEL = 'Single Promo Code';

export default rewardTypeLabels;
