import { useState } from 'react';
import cx from 'classnames';

import { FormQuestion } from '@/interfaces/form';

interface Props {
  question: FormQuestion;
}

const LongTextPreview = ({ question }: Props) => {
  const [charCount, setCharCount] = useState(0);
  const maxCharacterLimit = question.max_character_limit ? Math.min(400, question.max_character_limit) : 400;
  const minCharCountColorClass =
    question.min_character_limit && charCount < question.min_character_limit ? 'text-red-500' : 'text-gray-500';
  const maxCharCountColorClass = maxCharacterLimit - charCount < 20 ? 'text-red-500' : 'text-gray-500';

  return (
    <>
      <textarea
        name={question.id}
        className="wt-body-font h-28 w-full rounded-wt border-2 border-gray-200 text-base font-light text-black placeholder-gray-400 shadow-md focus:border-wt-primary focus:outline-none focus:ring-0 sm:shadow-none"
        placeholder="Please type your response here"
        maxLength={maxCharacterLimit}
        minLength={question.min_character_limit}
        onChange={(e) => setCharCount(e.target.value.length)}
      />
      <div className="flex justify-between">
        <div className="flex flex-col gap-y-1">
          {question.min_character_limit > 0 && (
            <span className={cx(minCharCountColorClass, 'text-xs')}>
              Minimum entry size of {question.min_character_limit} characters
            </span>
          )}
          {question.show_max_characters && question.max_character_limit && (
            <span className="text-xs text-gray-500">Maximum entry size of {maxCharacterLimit} characters</span>
          )}
        </div>

        <span className={cx(minCharCountColorClass, maxCharCountColorClass, 'text-xs')}>
          Characters remaining: {maxCharacterLimit - charCount}
        </span>
      </div>
    </>
  );
};

export default LongTextPreview;
