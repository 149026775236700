import cx from 'classnames';

import { Skeleton, SkeletonLoader } from '../../../../../components/SkeletonLoader';
import Text from '../../../../../components/Text';
import { Plan } from '../../../../../interfaces/billing';

type Props = {
  plan: Plan | undefined;
  size: 'sm' | 'md';
};

const PlanBadge = (props: Props) => {
  const { plan, size } = props;

  return (
    <SkeletonLoader
      isLoading={Boolean(!plan)}
      skeletons={
        <div className="w-40">
          <Skeleton className={cx('rounded', size === 'sm' && 'h-6', size === 'md' && 'h-8')} />
        </div>
      }
    >
      <div className="flex items-center">
        <img
          className={cx('mr-2', size === 'sm' && 'w-6 h-6', size === 'md' && 'w-6 h-8')}
          alt="beehiiv logo"
          src="/images/dark_icon.png"
        />
        <Text type="bold" size={size}>
          beehiiv <span className="capitalize">{plan?.name}</span> Plan
        </Text>
      </div>
    </SkeletonLoader>
  );
};

export default PlanBadge;
