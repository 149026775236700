import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface ApiResponse {
  total_pending_boost_agreements: number;
  total_pending_boost_sends: number;
  total_active_boost_agreements: number;
  total_active_boost_sends: number;
}

interface Variables {
  boostedPublicationId?: string;
}

const usePublicationInsights = ({ boostedPublicationId }: Variables) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchInsights = (): Promise<ApiResponse> =>
    api
      .get('/boosts/monetize/publication_insights', {
        params: {
          publication_id: currentPublicationId,
          boosted_publication_id: boostedPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, boostedPublicationId, 'boosts', 'monetize', 'publication_insights'],
    fetchInsights,
    {
      keepPreviousData: true,
    }
  );
};

export default usePublicationInsights;
