import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import GroupBody from './GroupBody';

interface Props {
  label: string;
  to: string;
  isSelected: boolean;
  isFolded: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  showPlaceholderIcon?: boolean;
  placeholderImage?: string;
  showBorder?: boolean;
}

const GroupLink = ({
  label,
  to,
  isSelected,
  isFolded,
  className,
  onClick,
  placeholderImage,
  showPlaceholderIcon = false,
  showBorder = true,
}: Props) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={classNames(
        'rounded-md',
        isSelected && showBorder ? 'bg-surface-50 border border-surface-100' : 'hover:bg-surface-50'
      )}
    >
      <GroupBody
        label={label}
        className={className}
        showPlaceholderIcon={showPlaceholderIcon}
        placeholderImage={placeholderImage}
      >
        <div className="flex grow-0	shrink-0">
          {isFolded ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronUpIcon className="h-4 w-4" />}
        </div>
      </GroupBody>
    </Link>
  );
};

export default GroupLink;
