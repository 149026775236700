import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  signupFlowId: string;
  onSuccess: () => void;
}

interface SignupFlowPayload {
  name: string;
  urls?: string[];
}

const useUpdateSignupFlow = ({ signupFlowId, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    ({ name, urls }: SignupFlowPayload) =>
      api
        .patch(`/signup_flows/${signupFlowId}`, {
          publication_id: currentPublicationId,
          signup_flow: {
            name,
            urls,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        onSuccess?.();
        toast.success('Saved!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useUpdateSignupFlow;
