import { useState } from 'react';
import toast from 'react-hot-toast';
import { ArrowsUpDownIcon, FireIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import ActiveIndicator from '../../../components/ActiveIndicator';
import LoadingBox from '../../../components/LoadingBox';
import SlideOver from '../../../components/SlideOver';
import { useCurrentPublication } from '../../../hooks';
import useRecommendations from '../../../hooks/useRecommendations';
import { RecommendationStatus } from '../../../interfaces/recommendation';

import FormRow from './FormRow';
import PublicationSearch from './PublicationSearch';
import Sort from './Sort';

const RecommendationsViewList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } = useRecommendations();
  const { data: currentPublication } = useCurrentPublication();

  const recommendations = data?.pages.flatMap((page) => page.recommendations) || [];
  const isNoResults = !isLoading && recommendations.length === 0;
  const onSuccess = () => {
    setIsOpen(false);
    toast.success('Recommendation added');
    refetch();
  };

  const activeRecommendations = recommendations.filter(
    (recommendation) => recommendation.status === RecommendationStatus.ACTIVE
  );

  const inactiveRecommendations = recommendations.filter(
    (recommendation) => recommendation.status === RecommendationStatus.INACTIVE
  );

  const NoResults = () => (
    <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
      <div className="flex flex-col items-center gap-4">
        <p className="text-gray-500 text-center">Once you create your first recommendation, it will show up here.</p>
        <Button variant="primary" onClick={() => setIsOpen(true)} Icon={FireIcon}>
          <div className="flex">Add recommendation</div>
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className="grid mb-4 gap-4 grid-cols-8">
        <p className="text-gray-600 lg:col-span-4 col-span-8">
          Recommending other publications is a great way to establish yourself as an expert in your field and learn more
          about your audiences&apos; interests. It also can increase your chances of being recommended by others.
        </p>
        <div className="flex items-end justify-end w-full space-x-2 col-span-8 lg:col-span-4">
          {activeRecommendations.length > 0 && (
            <Button variant="primary-inverse" onClick={() => setIsSorting(true)} Icon={ArrowsUpDownIcon}>
              Arrange
            </Button>
          )}
          {currentPublication && recommendations.length > 0 && (
            <div>
              <a href={`${currentPublication.url}recommendations`} target="_blank" rel="noreferrer">
                <Button variant="primary-inverse" type="button">
                  <div className="flex">View site</div>
                </Button>
              </a>
            </div>
          )}
          <div>
            <Button variant="primary" Icon={FireIcon} onClick={() => setIsOpen(true)}>
              <div className="flex">Add recommendation</div>
            </Button>
          </div>
        </div>
      </div>

      <LoadingBox isLoading={isLoading} isError={isError}>
        <div>
          {isNoResults ? (
            <NoResults />
          ) : (
            <div className="divide-y">
              <ul className="space-y-2 py-2">
                {activeRecommendations.map((recommendation) => (
                  <FormRow key={recommendation.position} recommendation={recommendation} refetch={refetch} />
                ))}
              </ul>
              <ul className="space-y-2 py-2">
                {inactiveRecommendations.map((recommendation) => (
                  <FormRow key={recommendation.position} recommendation={recommendation} refetch={refetch} />
                ))}
              </ul>
            </div>
          )}
          <div className="text-center mt-6">
            {hasNextPage && (
              <div>
                <Button
                  variant="secondary"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </LoadingBox>

      <PublicationSearch isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={onSuccess} />
      {recommendations.length > 0 && (
        <SlideOver
          isOpen={isSorting}
          onClose={() => {
            setIsSorting(false);
            refetch();
          }}
          headerText="Arrange recommendations"
        >
          <div className="flex bg-gray-50 p-4 rounded-lg space-x-4 text-gray-700">
            <span>💡</span>
            <span>Drag and drop the recommendations below to change the order they appear on your website.</span>
          </div>
          <div className="divide-y">
            {activeRecommendations.length > 0 && (
              <div className="py-2">
                <div className="flex items-center space-x-2">
                  <p className="text-gray-600 text-sm my-2">Active</p>
                  <ActiveIndicator active />
                </div>
                <Sort recommendations={activeRecommendations} />
              </div>
            )}

            {inactiveRecommendations.length > 0 && (
              <div className="py-2">
                <div className="flex items-center space-x-2">
                  <p className="text-gray-600 text-sm my-2">Inactive</p>
                  <ActiveIndicator active={false} />
                </div>
                <Sort recommendations={inactiveRecommendations} />
              </div>
            )}
          </div>
          {hasNextPage && (
            <div>
              <Button
                variant="secondary"
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
                block
              >
                {isFetchingNextPage ? 'Loading more...' : 'Load more'}
              </Button>
            </div>
          )}
        </SlideOver>
      )}
    </>
  );
};

export default RecommendationsViewList;
