import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  removePadding: boolean;
}>;

const PageContainer = ({ children, removePadding }: Props) => {
  return <div className={cx('h-full pt-6 pb-0 w-full bg-white', !removePadding && 'px-14')}>{children}</div>;
};

export default PageContainer;
