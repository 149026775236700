import { useCallback, useRef, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { SerializableNode, Serializer } from '@shared/dream-components';

import Modal from '@/components/Modal';
import BrowserBar from '@/components/PreviewModalV2/BrowserBar';
import { TabPills } from '@/components/TabPills';
import { useDreamEditorContext } from '@/context/dream-editor-context';
import { Text } from '@/routes/website/_components/UI/Text';
import { FooterPreview } from '@/routes/website/footer/_components/FooterEditor';
import { NavbarPreview } from '@/routes/website/navbar/_components/NavbarEditor/NavbarPreview';
import { capitalize } from '@/utils';
import { cn } from '@/utils/cn';

import { useContainerRef } from '../../_hooks';
import { useFontHrefsFromContent } from '../../_hooks/useFontHrefs';
import { VIEWPORTS } from '../../constants';
import { Viewport } from '../../types';
import IframeWrapper from '../Iframe';

type PreviewModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const BROWSER_BAR_HEIGHT = 40;

const PreviewModal = ({ isOpen, setIsOpen }: PreviewModalProps) => {
  const [viewport, setViewport] = useState<Viewport>(VIEWPORTS[0]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const { initialContent } = useDreamEditorContext();
  const {
    rect: previewRect,
    containerRef: previewContainerRef,
    recalibrate: recalibratePreviewRect,
  } = useContainerRef({
    enabled: isOpen,
  });

  const handleViewportChange = useCallback(
    (vp: Viewport) => () => {
      setViewport(vp);
      requestAnimationFrame(recalibratePreviewRect);
    },
    [recalibratePreviewRect]
  );

  const fontHrefs = useFontHrefsFromContent(initialContent);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      includeCloseButton={false}
      customStyle={{ marginTop: 0, marginBottom: 0 }}
    >
      <div className="rounded-lg h-[95vh] w-[95vw] overflow-hidden">
        <div className="flex flex-col rounded-lg w-full h-full overflow-hidden">
          <div className="w-full bg-white justify-center rounded-l-lg">
            <div className="h-full p-2 xl:p-4 border-r border-gray flex flex-row justify-between justify-items-center">
              <div className="flex flex-row gap-6 flex-1">
                <TabPills.Wrapper>
                  {VIEWPORTS.map((tab) => (
                    <TabPills.Item
                      key={tab.type}
                      active={viewport.type === tab.type}
                      onClick={handleViewportChange(tab)}
                    >
                      {capitalize(tab.type)}
                    </TabPills.Item>
                  ))}
                </TabPills.Wrapper>
              </div>
              <Text weight="semibold">Preview</Text>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={handleClose}
                onClick={handleClose}
                className="cursor-pointer flex-1 flex justify-end"
              >
                <XMarkIcon className="h-6 w-6" />
              </div>
            </div>
          </div>
          <div className="h-full w-full rounded-r-md overflow-auto">
            <div
              className={cn(
                viewport.type === 'desktop' ? 'max-w-7xl' : 'max-w-md',
                'h-full m-auto flex flex-col shadow-xl rounded-xl border-b border-surface-200'
              )}
              ref={previewContainerRef}
            >
              <BrowserBar screenSize={viewport.type} />
              <IframeWrapper
                width={previewRect.width || 0}
                height={(previewRect.height || 0) - BROWSER_BAR_HEIGHT}
                contentWidth={viewport.width}
                fontHrefs={fontHrefs}
                ref={iframeRef}
              >
                <NavbarPreview portalRef={portalRef} iframeRef={iframeRef} selectable={false} />
                {initialContent && <Serializer node={initialContent as SerializableNode} />}
                <FooterPreview iframeRef={iframeRef} selectable={false} />
                <div ref={portalRef} id="portal-ref" className="z-50" />
              </IframeWrapper>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewModal;
