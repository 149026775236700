import { useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

import analytics from '@/utils/analytics';

import { CountryInput, Input } from '../../../components/Form';
import { Badge } from '../../../ui/Badge';

import NotEditing from './NotEditing';
import SaveButton from './SaveButton';

type Address = {
  id?: string | null;
  street?: string;
  country?: string;
  countryName?: string;
  city?: string;
  state?: string;
  zip?: string;
};

const buildAddress = (address: Address) => {
  const { street, city, state, zip, countryName } = address;
  const hasCompleteAddress = street && city && state && zip && countryName;

  if (!hasCompleteAddress) {
    return <p>No Address Set</p>;
  }

  return (
    <div className="flex flex-col">
      <p>{street}</p>
      <p>
        {city}, {state}, {countryName}, {zip}
      </p>
    </div>
  );
};

interface Props {
  label: string;
  id?: string | undefined | null;
  street: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;
  country: string | undefined;
  countryName: string | undefined;
  onSave: (val: Address) => Promise<any>;
  onDelete?: (val: Address) => Promise<any>;
  isSaving: boolean;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
}

const AddressForm = ({
  label,
  id,
  street,
  city,
  state,
  zip,
  country,
  countryName,
  onSave,
  onDelete,
  isSaving,
  toastSuccessMessage,
  toastErrorMessage,
}: Props) => {
  const [error, setError] = useState<boolean | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputStreet, setInputStreet] = useState<string>(street || '');
  const [inputCity, setInputCity] = useState<string>(city || '');
  const [inputState, setInputState] = useState<string>(state || '');
  const [inputZip, setInputZip] = useState<string>(zip || '');
  const [inputCountry, setInputCountry] = useState<string>(country || '');
  const showSaving = isSaving && isEditing;

  const handleSave = async () => {
    const addressItems = [inputStreet, inputState, inputState, inputZip, inputCountry].filter((item) =>
      Boolean(item)
    ).length;
    const hasAtleastOneItem = Boolean(addressItems);
    const isMissingAnyItems = !inputStreet || !inputState || !inputCity || !inputZip || !inputCountry;

    if (hasAtleastOneItem && isMissingAnyItems) {
      setError(true);
    } else {
      setError(false);
      await onSave({
        id,
        street: inputStreet,
        city: inputCity,
        state: inputState,
        zip: inputZip,
        country: inputCountry,
      })
        .then(() => {
          setIsEditing(false);
          if (toastSuccessMessage) {
            toast.success(toastSuccessMessage);
          }
        })
        .catch(() => {
          if (toastErrorMessage) {
            toast.error(toastErrorMessage);
          }
        });
    }
  };

  const handleDelete = async () => {
    if (!onDelete) {
      setIsEditing(false);
      return;
    }

    setError(false);
    await onDelete({ id })
      .then(() => {
        setIsEditing(false);
        analytics.track('Set Address');
        [setInputStreet, setInputCity, setInputState, setInputZip, setInputCountry].forEach((input) => input(''));
        if (toastSuccessMessage) {
          toast.success(toastSuccessMessage);
        }
      })
      .catch(() => {
        if (toastErrorMessage) {
          toast.error(toastErrorMessage);
        }
      });
  };

  if (isEditing) {
    return (
      <div className="flex justify-between pb-4 space-x-6 border-b border-gray-100 last:border-b-0">
        <div className="flex flex-col space-y-4 w-full">
          <Input
            className="w-full"
            name="street_address"
            labelText="Street Address"
            placeholder="228 Park Ave S, #29976"
            value={inputStreet}
            onChange={(e) => setInputStreet(e.target.value)}
          />
          <div className="w-full flex space-x-4">
            <Input
              className="w-full"
              name="city"
              labelText="City"
              value={inputCity}
              placeholder="New York"
              onChange={(e) => setInputCity(e.target.value)}
            />
            <Input
              className="w-full"
              name="state"
              labelText="State"
              placeholder="New York"
              value={inputState}
              onChange={(e) => setInputState(e.target.value)}
            />
          </div>
          <div className="w-full flex space-x-4">
            <Input
              className="w-full"
              name="zip_code"
              labelText="Postal Code"
              placeholder="10003"
              value={inputZip}
              onChange={(e) => setInputZip(e.target.value)}
            />
            <CountryInput value={inputCountry} labelText="Country" name="country" onChange={setInputCountry} />
          </div>
          {error && (
            <p className="mt-2 text-xs text-red-500">
              You need to input a value for each of these fields to have your address updated in your emails.
            </p>
          )}
        </div>

        <div className="flex flex-col justify-between w-1/8">
          <SaveButton
            isSaving={showSaving}
            isEditing={isEditing}
            closeEditing={() => setIsEditing(false)}
            changesDetected={isEditing}
            type="button"
            onClick={handleSave}
          />
          {handleDelete && id && (
            <button type="button" className="flex w-full justify-center" onClick={handleDelete}>
              <Badge>
                <TrashIcon className="w-6 text-red-800" />
              </Badge>
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-between pb-4 border-b border-gray-100 last:border-b-0">
      <NotEditing
        label={label}
        helperText="You need to input a value for each of these fields to have your address updated in your emails."
        value={buildAddress({
          street,
          city,
          state,
          zip,
          countryName,
        })}
      />

      <div className="pt-4">
        <button
          className="h-6 w-6 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
          type="button"
          onClick={() => setIsEditing(true)}
        >
          <PencilIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};

export default AddressForm;
