import cx from 'classnames';

interface Props {
  className?: string;
}

const SvgLogoWithName = ({ className }: Props) => (
  <svg
    version="1.1"
    id="a"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 439.4 110.2"
    xmlSpace="preserve"
    className={cx('fill-current', className)}
  >
    <g id="b">
      <g id="c">
        <g>
          <g id="d">
            <g>
              <path
                className="st0"
                d="M101.7,51.8H15.3c-2.2,0-4-1.8-4-4v-6.1c0-6.9,5.6-12.5,12.5-12.5h69.3c6.9,0,12.5,5.6,12.5,12.5v6.1 C105.7,50,103.9,51.8,101.7,51.8z"
              />
              <path
                className="st0"
                d="M83.5,22.6h-50c-2.2,0-4.1-1.8-4.1-4.1l0,0C29.4,8.3,37.7,0,47.9,0h21.2c10.2,0,18.5,8.3,18.5,18.5l0,0 C87.6,20.8,85.8,22.6,83.5,22.6C83.5,22.6,83.5,22.6,83.5,22.6z"
              />
              <path
                className="st0"
                d="M105.7,58.4H11.3C5.1,58.4,0,63.5,0,69.7S5.1,81,11.3,81h33.5c1.2-6.4,6.8-11.3,13.6-11.3 S70.9,74.6,72.1,81h33.5c6.2,0,11.3-5.1,11.3-11.3S111.9,58.4,105.7,58.4C105.7,58.4,105.7,58.4,105.7,58.4z"
              />
              <g>
                <path
                  className="st0"
                  d="M101.6,87.6H72.3v22.6h22c6.2,0,11.3-5.1,11.3-11.3v-7.2C105.7,89.5,103.9,87.6,101.6,87.6 C101.6,87.6,101.6,87.6,101.6,87.6z"
                />
                <path
                  className="st0"
                  d="M15.3,87.6c-2.2,0-4.1,1.8-4.1,4.1v7.2c0,6.2,5.1,11.3,11.3,11.3h22V87.6C44.6,87.6,15.3,87.6,15.3,87.6z "
                />
              </g>
            </g>
          </g>
          <g>
            <path
              className="st1"
              d="M206.4,63c0,12.3-8.5,20.2-19.6,20.2c-5.3,0-9.5-1.7-12.3-5.1v4.5h-10.9V28.5H175v19.1 c2.9-3.2,6.9-4.8,11.8-4.8C197.8,42.8,206.4,50.7,206.4,63z M194.8,63c0-6.8-4.3-10.9-10-10.9s-10,4.1-10,10.9s4.3,10.9,10,10.9 S194.8,69.8,194.8,63z"
            />
            <path
              className="st1"
              d="M243.6,70.3l6.1,6.6c-3.6,4.2-9.1,6.3-16.2,6.3c-13.6,0-22.4-8.5-22.4-20.2s8.9-20.2,21-20.2 c11.1,0,20.2,7.3,20.3,19.9l-29.1,5.6c1.7,3.9,5.3,5.8,10.4,5.8C237.9,74.1,240.9,72.9,243.6,70.3L243.6,70.3z M222.2,61.8 l19.1-3.7c-1.1-4-4.5-6.7-9.3-6.7C226.4,51.4,222.4,55.2,222.2,61.8L222.2,61.8z"
            />
            <path
              className="st1"
              d="M289.6,70.3l6.1,6.6c-3.6,4.2-9.1,6.3-16.2,6.3c-13.6,0-22.4-8.5-22.4-20.2s8.9-20.2,21-20.2 c11.1,0,20.2,7.3,20.3,19.9l-29,5.6c1.7,3.9,5.3,5.8,10.4,5.8C283.9,74.1,286.9,72.9,289.6,70.3L289.6,70.3z M268.2,61.8 l19.1-3.7c-1.1-4-4.5-6.7-9.3-6.7C272.4,51.4,268.5,55.2,268.2,61.8z"
            />
            <path
              className="st1"
              d="M346.2,60.2v22.5h-11.4V61.9c0-6.3-2.9-9.3-8-9.3c-5.5,0-9.4,3.4-9.4,10.6v19.4h-11.4V28.5h11.4v19 c3.1-3.1,7.5-4.7,12.5-4.7C339.2,42.8,346.2,48.2,346.2,60.2L346.2,60.2z"
            />
            <path
              className="st1"
              d="M355.1,31.5c0-3.6,2.9-6.4,7.1-6.4s7.1,2.6,7.1,6.1c0,3.8-2.9,6.6-7.1,6.6S355.1,35.1,355.1,31.5z M356.5,43.4h11.4v39.3h-11.4C356.5,82.6,356.5,43.4,356.5,43.4z"
            />
            <path
              className="st1"
              d="M377.1,31.5c0-3.6,2.9-6.4,7.1-6.4s7.1,2.6,7.1,6.1c0,3.8-2.9,6.6-7.1,6.6S377.1,35.1,377.1,31.5z M378.5,43.4h11.4v39.3h-11.4C378.5,82.6,378.5,43.4,378.5,43.4z"
            />
            <path
              className="st1"
              d="M439.4,43.4l-16.6,39.3h-11.8l-16.5-39.3h11.8l10.9,26.7l11.2-26.7C428.5,43.4,439.4,43.4,439.4,43.4z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgLogoWithName;
