import { Typography } from '@/components/Typography';
import { Badge, BadgeType } from '@/ui/Badge';
import { capitalize } from '@/utils';

import { PartialPost, Post, PostStatus } from '../../../interfaces/post';
import { SortableList, SortableListItem } from '../index';

type FeaturedPosts = Array<Post>;
type FeaturedPost = Post;

interface SortFeaturedPostsProps {
  featuredPosts: FeaturedPosts[] | any;
  onItemRemoved?: (newList: any) => void;
  onItemsOrdered?: (newList: any) => void;
  itemToAppend?: PartialPost | null;
  onItemAppended?: (newList: any) => void;
}

const SortFeaturedPosts = ({
  featuredPosts,
  onItemRemoved,
  onItemsOrdered,
  itemToAppend,
  onItemAppended,
}: SortFeaturedPostsProps) => {
  return (
    <SortableList
      listItems={featuredPosts}
      onItemRemoved={onItemRemoved}
      onItemsOrdered={(newList) => (onItemsOrdered ? onItemsOrdered(newList) : null)}
      itemToAppend={itemToAppend}
      onItemAppended={(newList) => (onItemAppended ? onItemAppended(newList) : null)}
    >
      {(list, onRemoveItem) => {
        return list.map((post: FeaturedPost, index: any) => {
          const postText =
            post.status === PostStatus.DRAFT || post.status === PostStatus.SCHEDULED ? (
              <div className="flex flex-row gap-2 items-center">
                <Typography token="font-semibold/text/xs">{post.web_title}</Typography>
                <Badge type={BadgeType.INFO} text={capitalize(post.status)} />
              </div>
            ) : (
              post.web_title
            );
          return (
            <SortableListItem key={post.id} listItem={post} onRemoveItem={() => onRemoveItem(index)} text={postText} />
          );
        });
      }}
    </SortableList>
  );
};

export default SortFeaturedPosts;
