import { useQuery } from 'react-query';

import api from '../services/swarm';

export default function usePostTheme(publicationId: string) {
  return useQuery(
    ['postTheme', publicationId],
    () =>
      api
        .get(`/post_theme`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: Infinity,
    }
  );
}
