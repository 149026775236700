import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

const useApproveAction = ({ subscriberId, status }: { subscriberId: string; status: string | undefined }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const approvalMutation = useMutation(
    () => {
      return api
        .patch(`/subscriptions/${subscriberId}/approve`, { publication_id: currentPublicationId })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        toast.success('Subscriber successfully approved!');
        queryClient.invalidateQueries(['subscriber', subscriberId]);
      },
      onError: () => {
        toast.error('Something went wrong');
      },
      onSettled: () => {
        setIsApproving(false);
        setConfirmOpen(false);
      },
    }
  );

  const onApprove = () => {
    setIsApproving(true);
    approvalMutation.mutate();
  };

  if (status !== 'needs_attention') {
    return [];
  }

  return [
    {
      label: 'Approve',
      helperText: 'Approve subscriber',
      onClick: () => setConfirmOpen(true),
      children: (
        <ActionModal
          isOpen={confirmOpen}
          onProceed={onApprove}
          onClose={() => setConfirmOpen(false)}
          resourceId={subscriberId}
          isWorking={isApproving}
          headerText="Approve Subscriber"
          actionText="Approve"
        >
          Are you sure you want to approve this subscriber to receive your newsletters?
        </ActionModal>
      ),
    },
  ];
};

export default useApproveAction;
