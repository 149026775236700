import { PageRoute } from '@/interfaces/dream_builder/page_route';

export function deletePageRouteFromPath(route: PageRoute, parentPath: string[], slug: string): PageRoute | undefined {
  if (parentPath.length === 0) {
    // delete page route here

    if (route.children?.[slug]) {
      // remove slug from children
      const newRoute = {
        ...route,
        children: Object.entries(route.children).reduce((acc, [key, value]) => {
          if (key !== slug) {
            return {
              ...acc,
              [key]: value,
            };
          }
          return acc;
        }, {}),
      };

      // remove slug from children_keys
      newRoute.children_keys = newRoute.children_keys?.filter((key) => key !== slug);

      return newRoute;
    }

    // slug not found
    return route;
  }

  const nextPath = parentPath[0];
  const nextRoute = route.children?.[nextPath];

  // can't find next path
  if (!nextRoute) {
    return route;
  }

  const updatedNextRoute = deletePageRouteFromPath(nextRoute, parentPath.slice(1), slug);

  // Unable to update next route
  if (!updatedNextRoute) {
    return route;
  }

  return {
    ...route,
    children: {
      ...route.children,
      [nextPath]: updatedNextRoute,
    },
  };
}
