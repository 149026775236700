import { Typography } from '@/components/Typography';
import { WebhookDestination } from '@/interfaces/webhook_destination';

interface Props {
  webhookDestination: WebhookDestination;
}

const WebhookStepNode = ({ webhookDestination }: Props) => {
  const shouldShowEmptyState =
    !webhookDestination.request_method && !webhookDestination.root_domain && !webhookDestination.description;

  if (shouldShowEmptyState) {
    return <Typography size="xs">Configure a webhook to call when this step is run</Typography>;
  }

  return (
    <ul className="space-y">
      <li>
        <Typography size="xs">
          {webhookDestination.request_method && (
            <>
              <strong>{webhookDestination.request_method.toUpperCase()} · </strong>
              {' '}
            </>
          )}
          {webhookDestination.root_domain}
        </Typography>
      </li>
      <li>
        <Typography size="xs">{webhookDestination.description}</Typography>
      </li>
    </ul>
  );
};

export default WebhookStepNode;
