import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { HandRaisedIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import { DelayedDisplay } from '../../components/DelayedDisplay';
import { Input } from '../../components/Form';
// Components
import { SvgLogo } from '../../components/SvgLogo';
// Context
import { useAuth } from '../../context/auth-context';

interface Props {
  inviteId: string;
  inviteToken: string;
  tokenId: string;
  inviteableName: string;
  email: string;
}

const ExistingUser: FC<Props> = (props: Props) => {
  const { inviteId, inviteToken, tokenId, inviteableName, email } = props;
  const [password, setPassword] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');

  const { inviteAcceptExistingUser, needsOtp } = useAuth();

  const isDisabled = () => {
    if (!password) {
      return true;
    }

    return false;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = e;

    if (name === 'password') {
      setPassword(value);
    } else if (name === 'otp') {
      setOtp(value);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      email,
      inviteToken,
      inviteId,
      tokenId,
      password,
      otp,
    };

    setIsSaving(true);
    inviteAcceptExistingUser(payload)
      .then((data: any) => {
        if (data?.code !== 'OTP_REQUIRED') {
          return navigate('/');
        }
        return null;
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 p-5">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-12 h-auto m-auto">
          <a href="https://www.beehiiv.com" aria-label="beehiiv" target="_blank" rel="noreferrer">
            <SvgLogo />
          </a>
        </div>
        <h2 className="mt-6 text-center text-2xl font-medium text-gray-900">
          You&apos;ve been invited to join {inviteableName}! Login to join.
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <form onSubmit={handleSubmit} className="space-y-6">
            {!needsOtp && <Input name="email" labelText="Email" type="email" value={email} required disabled />}
            {!needsOtp && (
              <Input
                name="password"
                labelText="Password"
                type="password"
                value={password}
                onChange={handleChange}
                required
              />
            )}
            {needsOtp && (
              <div>
                <label htmlFor="otp" className="block text-base font-semibold">
                  Two-factor code
                  <input
                    className="mt-1 appearance-none block w-full p-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 text-base"
                    name="otp"
                    id="otp"
                    value={otp}
                    onChange={handleChange}
                  />
                </label>
                <DelayedDisplay waitBeforeShow={10000}>
                  <div className="bg-blue-50 p-4 rounded space-y-2">
                    <div className="flex space-x-1 items-center">
                      <HandRaisedIcon className="h-3 w-3 font-bold text-xs text-blue-500" />
                      <span className="font-bold text-xs text-blue-500">Helping Hand:</span>
                    </div>
                    <p className="text-xs text-blue-500">
                      You can find this 6-digit code in the Two-Factor Authentication app on your phone.
                    </p>
                  </div>
                </DelayedDisplay>
              </div>
            )}
            <div>
              <Button type="submit" loading={isSaving} disabled={isDisabled()} block>
                Accept
              </Button>
            </div>
            <div className="text-center">
              <div className="text-sm">
                <a className="font-medium text-gray-600 hover:text-gray-500" target="_blank" href="/reset_password">
                  Forgot your password?
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExistingUser;
