import { useState } from 'react';

import { Dropdown } from '@/ui/Dropdown';

import { TimePeriod } from '../utils';
import { timePeriodLabels, timePeriodsButtonOptions } from '../utils/timePeriods';

type TimePeriodSelectProps = {
  handleChange?: (timePeriod: TimePeriod) => void;
};

type UseTimePeriodSelectProps = {
  defaultValue?: string;
  size?: 'sm' | 'md';
  label?: string;
  labelClassName?: string;
  excludeOptions?: TimePeriod[];
  variant?: 'tabMenu';
};

const useTimePeriodSelect = ({ defaultValue, label, excludeOptions = [], variant }: UseTimePeriodSelectProps = {}) => {
  const [period, setPeriod] = useState<string>(defaultValue || TimePeriod.ALL_TIME);

  const TimePeriodSelect = ({ handleChange }: TimePeriodSelectProps) => {
    const options = timePeriodsButtonOptions
      .filter((timePeriod) => !excludeOptions.includes(timePeriod.id))
      .map((timePeriod) => {
        return {
          label: timePeriod.label,
          value: timePeriod.id,
        };
      });

    const onSelect = (name: string, value: string | string[]) => {
      const selectedValue = Array.isArray(value) ? value[0] : value;

      setPeriod(selectedValue);
      if (handleChange) handleChange(selectedValue as keyof typeof timePeriodLabels);
    };

    return (
      <div className="min-w-36">
        <Dropdown
          variant={variant}
          labelText={label}
          name="time-period"
          onSelect={onSelect}
          options={options}
          value={period}
          className="w-full"
        />
      </div>
    );
  };

  return { period, periodLabel: timePeriodLabels[period as keyof typeof timePeriodLabels] as string, TimePeriodSelect };
};

export default useTimePeriodSelect;
