import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import useAssignRewardPromoCode from '@/hooks/useAssignRewardPromoCode';
import useRewardPromoCodes from '@/hooks/useRewardPromoCodes';
import { Option } from '@/interfaces/general';
import { RewardPromoCode } from '@/interfaces/reward';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  subscriptionMilestoneId: string;
  rewardId: string;
}

const AssignPromoCode = ({ isOpen, onClose, subscriptionMilestoneId, rewardId }: Props) => {
  const [selectedPromoCode, setSelectedPromoCode] = useState('');

  const handleSelect = (_name: string, value: string) => {
    setSelectedPromoCode(value);
  };

  const { data: rewardPromoCodesData, isLoading } = useRewardPromoCodes({
    rewardId,
    params: { notAssigned: true, allResults: true },
    isEnabled: isOpen,
  });
  const promoCodes = rewardPromoCodesData?.pages.flatMap((page) => page.promo_codes) || [];
  const selectOptions = promoCodes.map(
    (option: RewardPromoCode) =>
      ({
        label: option.code,
        value: option.id,
      } as Option)
  );

  const { mutateAsync: assignPromoCodeMutation, isLoading: isAssigning } = useAssignRewardPromoCode({
    subscriptionMilestoneId,
    onSuccess: () => {
      toast.success('Promo Code assigned successfully');
      onClose();
    },
  });

  const handleClickAssign = () => {
    if (!selectedPromoCode) {
      return;
    }

    assignPromoCodeMutation({
      promoCodeId: selectedPromoCode,
    });
  };

  return (
    <ActionModal
      headerText="Assign Promo Code"
      resourceId={subscriptionMilestoneId}
      isOpen={isOpen}
      onClose={onClose}
      isWorking={isAssigning}
      onProceed={handleClickAssign}
      actionText="Assign"
      disabled={!selectedPromoCode}
    >
      <Dropdown
        name="assign-promo-code-select"
        options={selectOptions}
        value={selectedPromoCode}
        onSelect={handleSelect}
        emptyLabel="No Promo Codes found"
        placeholderText={isLoading ? 'Loading Promo Codes…' : undefined}
        disabled={isLoading || isAssigning}
      />
    </ActionModal>
  );
};

export default AssignPromoCode;
