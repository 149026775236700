import moment from 'moment-mini';

import { PublicationLogo } from '@/components/PublicationLogo';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import useFormattedSendingMetrics from '@/hooks/boosts/grow/useFormattedSendingMetrics';
import useFormattedTopLineMetrics from '@/hooks/boosts/grow/useFormattedTopLineMetrics';
import useOfferStatus from '@/hooks/boosts/grow/useOfferStatus';
import useSendingMetrics from '@/hooks/boosts/grow/useSendingMetrics';
import { BoostAgreementCreateWithReferralCount } from '@/interfaces/boosts/grow/boost_agreement';

import BoostAgreementStatusBadge from '../Dashboard/components/BoostAgreementStatusBadge';

import BoostAgreementActionsDropdown from './BoostAgreementActionsDropdown';

interface Props {
  boostAgreement: BoostAgreementCreateWithReferralCount;
  onTransition: () => void;
  showCPA?: boolean;
  showSendingMetrics?: boolean;
  showSpendMetrics?: boolean;
  canReportPublication?: boolean;
}

const BoostAgreementListItem = ({
  boostAgreement,
  onTransition,
  showCPA,
  showSendingMetrics,
  showSpendMetrics,
  canReportPublication,
}: Props) => {
  const { cost } = useOfferStatus(boostAgreement.boost_offer);
  const { data: sendingMetricsData } = useSendingMetrics({
    boost_agreement_id: boostAgreement.id,
    isEnabled: showSendingMetrics,
  });
  const { openRate, unsubscribeRate, clickThroughRate } = useFormattedSendingMetrics(sendingMetricsData);
  const { acceptanceRate, pendingSpend, totalSpend } = useFormattedTopLineMetrics(boostAgreement.top_line_metrics);
  return (
    <ItemRow>
      <ItemColumn align={AlignType.TOP}>
        {showCPA ? (
          <TypographyStack>
            <Typography token="font-medium/text/base">{cost}</Typography>
            <Typography token="font-normal/text/xs" colorWeight="500">
              Created{' '}
              <time dateTime={boostAgreement.created_at as string}>{moment(boostAgreement.created_at).fromNow()}</time>{' '}
            </Typography>
          </TypographyStack>
        ) : (
          <div className="w-64 flex items-center gap-x-2">
            <PublicationLogo publication={boostAgreement.recommending_publication} size="sm" />
            <Typography weight="medium" colorWeight="900">
              {boostAgreement.recommending_publication.name}
            </Typography>
          </div>
        )}
      </ItemColumn>
      <ItemColumn>
        <BoostAgreementStatusBadge status={boostAgreement.status} />
      </ItemColumn>
      {showSendingMetrics && (
        <>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {openRate}
            </Typography>
          </ItemColumn>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {clickThroughRate}
            </Typography>
          </ItemColumn>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {unsubscribeRate}
            </Typography>
          </ItemColumn>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {acceptanceRate}
            </Typography>
          </ItemColumn>
        </>
      )}
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostAgreement.top_line_metrics.total_pending_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostAgreement.top_line_metrics.total_accepted_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostAgreement.top_line_metrics.total_subscribers}
        </Typography>
      </ItemColumn>
      {showSpendMetrics && (
        <>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {pendingSpend}
            </Typography>
          </ItemColumn>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {totalSpend}
            </Typography>
          </ItemColumn>
        </>
      )}
      <ItemColumn isSticky className="w-full h-full flex items-center">
        <BoostAgreementActionsDropdown
          boostAgreement={boostAgreement}
          onSuccess={onTransition}
          canReport={canReportPublication}
        />
      </ItemColumn>
    </ItemRow>
  );
};

export default BoostAgreementListItem;
