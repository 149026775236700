export enum WebhooksEventType {
  SUBSCRIPTION_CREATED = 'subscription.created',
  SUBSCRIPTION_CONFIRMED = 'subscription.confirmed',
  SUBSCRIPTION_DELETED = 'subscription.deleted',
  SUBSCRIPTION_UPGRADED = 'subscription.upgraded',
  SUBSCRIPTION_DOWNGRADED = 'subscription.downgraded',
  SUBSCRIPTION_TIER_CREATED = 'subscription.tier.created',
  SUBSCRIPTION_TIER_DELETED = 'subscription.tier.deleted',
  POST_SENT = 'post.sent',
  SURVEY_RESPONSE_SUBMITTED = 'survey.response_submitted',
}

export interface WebhookEndpoint {
  id: string;
  uid: string;
  application_id: string;
  description: string;
  url: string;
  disabled: boolean;
  event_types: WebhooksEventType[];
  version: string;
  secret: string;
  created_at: string;
  updated_at: string;
}

export interface WebhookEndpointUpdatePayload {
  id: string;
  url?: string;
  description?: string;
  event_types?: WebhooksEventType[];
  disabled?: boolean;
}

export interface WebhookEndpointCreatePayload {
  url: string;
  description: string;
  event_types: WebhooksEventType[];
  disabled: boolean;
}
