import React from 'react';

interface IconProps {
  className?: string;
}

const TopLeftIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 2H7.33333C4.38781 2 2 4.38781 2 7.33333V10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const TopRightIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 10V7.33333C10 4.38781 7.61219 2 4.66667 2H2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const BottomLeftIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2V4.66667C2 7.61219 4.38781 10 7.33333 10H10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const BottomRightIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 10H4.66667C7.61219 10 10 7.61219 10 4.66667V2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const BorderIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.57217 1H3.37217C2.04668 1 0.972168 2.07452 0.972168 3.4V4.6M9.37217 1H10.5722C11.8977 1 12.9722 2.07452 12.9722 3.4V4.6M12.9722 9.4V10.6C12.9722 11.9255 11.8977 13 10.5722 13H9.37217M4.47217 13H3.37217C2.04668 13 0.972168 11.9255 0.972168 10.6V9.4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const AllBorderIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.944336 3.9C0.944336 2.57452 2.01885 1.5 3.34434 1.5H10.5443C11.8698 1.5 12.9443 2.57452 12.9443 3.9V11.1C12.9443 12.4255 11.8698 13.5 10.5443 13.5H3.34434C2.01885 13.5 0.944336 12.4255 0.944336 11.1V3.9Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export { AllBorderIcon, BorderIcon, BottomLeftIcon, BottomRightIcon, TopLeftIcon, TopRightIcon };
