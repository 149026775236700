import { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';

import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { usePublication, useUpdatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { useTiers } from '@/hooks/useTiers';
import { TierStatus } from '@/interfaces/tier';
import { TutorialType } from '@/interfaces/tutorial';
import { SwitchForm } from '@/pages/Settings/Components';
import VideoTutorial from '@/pages/Settings/Components/VideoTutorial';
import Section from '@/routes/settings/_components/Section';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import ProTip from './ProTip';
import StatusSwitch from './StatusSwitch';
import TierCard, { TierCardWrapper } from './TierCard';

const TierConfigurationSection: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPublicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(currentPublicationId);
  const { data: currentPublication } = usePublication(currentPublicationId);
  const maxTierCount = settings?.max_premium_tiers || 0;

  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;

  const { data: tiers, isLoading, isError } = useTiers(currentPublicationId, { includeActiveSubscriptionsCount: true });
  const [showInfo, setShowInfo] = useState(true);
  const [currentStatus, setCurrentStatus] = useState<TierStatus>(TierStatus.ACTIVE);

  const activeTiers = useMemo(() => tiers?.filter((t) => t.status === TierStatus.ACTIVE) || [], [tiers]);
  const archivedTiers = useMemo(() => tiers?.filter((t) => t.status === TierStatus.ARCHIVED) || [], [tiers]);
  const currentTiers = currentStatus === TierStatus.ACTIVE ? activeTiers : archivedTiers;

  const canAddTier = activeTiers.length < maxTierCount;
  const gridColumns = Math.min(2, currentTiers.length + (canAddTier ? 1 : 0));
  const updatePublication = useUpdatePublication(currentPublicationId);

  const onPremiumChange = async (val: boolean) => {
    if (val) {
      analytics.track('Enabled paid subscription');
    }
    await updatePublication.mutate({ premium_enabled: val });
  };

  const onStatusChange = (status: TierStatus) => {
    setSearchParams({ tiers: status });
  };

  useEffect(() => {
    setCurrentStatus(searchParams.get('tiers') === 'archived' ? TierStatus.ARCHIVED : TierStatus.ACTIVE);
  }, [searchParams]);

  return (
    <LoadingBox isLoading={isLoading} isError={isError} backgroundClassName="bg-transparent">
      <Section
        title="Configure Paid Subscription Tiers"
        description={
          <>
            <p>Customize plans and pricing for your paid subscriptions.</p>
            <VideoTutorial type={TutorialType.PREMIUM_SUBSCRIPTIONS} />
          </>
        }
        maxWidthClassName="max-w-4xl"
        titleAction={
          <Badge size="sm" type="success" className="h-0 py-3 px-2">
            <span className="bg-green-800 w-2 h-2 rounded-full" />
            <span>Connected to Stripe</span>
          </Badge>
        }
      >
        <SwitchForm
          name="enable_premium"
          label="Enable Paid Subscriptions for your readers"
          description={currentPublication?.is_premium_enabled ? 'Toggle off to disable' : 'Toggle on to enable'}
          value={currentPublication?.is_premium_enabled || false}
          onSave={onPremiumChange}
          toastSuccessMessage="Saved!"
          noBorder
        />
        {tiers && currentPublication?.is_premium_enabled && (
          <div className="space-y-3">
            {tiers.length === 0 && showInfo && <ProTip onDismiss={() => setShowInfo(false)} />}
            {tiers.length > 0 && <StatusSwitch currentStatus={currentStatus} onChange={onStatusChange} />}

            <div
              className={`grid gap-5 grid-cols-1 lg:grid-cols-${Math.max(
                gridColumns - 1,
                2
              )} xl:grid-cols-${gridColumns}`}
            >
              {currentTiers.map((tier) => (
                <TierCard key={tier.id} tier={tier} allowUnarchive={canAddTier} />
              ))}

              {currentStatus === TierStatus.ACTIVE && canAddTier && (
                <TierCardWrapper centered>
                  <Link
                    to={
                      hasSettingsV2
                        ? '/monetize/subscriptions/paid_tiers/new'
                        : '/settings/publication/premium/tiers/new'
                    }
                  >
                    <Button variant="primary-inverse" Icon={PlusIcon} onClick={() => {}}>
                      Create New Tier
                    </Button>
                  </Link>
                </TierCardWrapper>
              )}
            </div>

            {activeTiers.length === maxTierCount && (
              <div>
                <Typography token="font-normal/text/base">
                  If you want to create more than {maxTierCount} tiers, please{' '}
                  <a href="/?new_support_ticket=true" target="_blank">
                    <Typography token="font-medium/text/base" color="secondary" colorWeight="600">
                      contact support.
                    </Typography>
                  </a>
                </Typography>
              </div>
            )}
          </div>
        )}
      </Section>
    </LoadingBox>
  );
};

export default TierConfigurationSection;
