import React from 'react';

import Modal from '@/components/Modal';
import PricingPanel from '@/components/PricingPanel';
import { PlanPrice, PlanPriceTierSizes } from '@/interfaces/plan_price';
import { PLAN } from '@/utils/plans';

import CustomButton from './CustomButton';
import LaunchButton from './LaunchButton';
import MaxButton from './MaxButton';
import ScaleButton from './ScaleButton';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (newPlanPriceId?: string) => void;
  planPrice?: PlanPrice;
}

const PricingModal: React.FC<Props> = ({ isOpen, onClose, planPrice, onSelect }) => {
  if (!planPrice) {
    return null;
  }

  const defaultMaxSubscribers =
    planPrice.active_subscriptions > planPrice.organization_max_subscriptions || planPrice.plan_name === PLAN.LAUNCH
      ? PlanPriceTierSizes.find((count) => count > planPrice.active_subscriptions) || 100_001
      : planPrice.max_subscriptions;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-8">
        <PricingPanel
          defaultBillingInterval={planPrice.interval === 'one_time' ? 'month' : planPrice.interval}
          defaultMaxSubscribers={defaultMaxSubscribers}
          allowDisablingLaunch={false}
          actions={{
            launch: (props) => LaunchButton({ planPrice, ...props }),
            scale: (props) => ScaleButton({ planPrice, onSelect, ...props }),
            max: (props) => MaxButton({ planPrice, onSelect, ...props }),
            custom: () => CustomButton(),
          }}
        />
      </div>
    </Modal>
  );
};

export default PricingModal;
