import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import cx from 'classnames';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { ReferralProgramLayout } from '@/interfaces/referral_program';
import api from '@/services/swarm';
import { Card } from '@/ui/Card';

interface Props {
  isLoading: boolean;
  layout: ReferralProgramLayout;
}

const HEIGHT_BY_LAYOUT: Record<ReferralProgramLayout, string> = {
  [ReferralProgramLayout.DEFAULT]: 'h-96',
  [ReferralProgramLayout.UPCOMING_MILESTONE_ONLY]: 'h-96',
  [ReferralProgramLayout.REWARDS_ONLY]: 'h-96',
  [ReferralProgramLayout.UPCOMING_MILESTONES_AND_REWARDS]: 'h-96',
};

const ReferralBlockPreview = ({ layout, isLoading }: Props) => {
  const [emailPreview, setEmailPreview] = useState('');
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPreview = useCallback(
    (platform: string) => {
      api
        .get(`/referral_program/preview`, {
          params: {
            publication_id: currentPublicationId,
            platform,
          },
        })
        .then((resp) => {
          setEmailPreview(resp.data.html);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [currentPublicationId]
  );

  useEffect(() => {
    fetchPreview('email');
  }, [fetchPreview, layout]);

  return (
    <Card>
      <iframe
        title="referral-block-email-preview"
        className={cx('w-full overflow-hidden rounded-md', HEIGHT_BY_LAYOUT[layout])}
        srcDoc={emailPreview}
        aria-disabled={isLoading}
      />
    </Card>
  );
};

export default ReferralBlockPreview;
