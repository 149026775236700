import toast from 'react-hot-toast';

import { useWebsiteContext } from '@/context/website-context';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';

import Callout from '../../_components/UI/Callout';
import { Switch } from '../../_components/UI/Switch';
import SettingsInput from '../_components/SettingsInput';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';

const WebsiteSettingsGDPR = () => {
  const { site } = useWebsiteContext();
  const updateSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  const tos = site?.draft_site_version?.settings?.terms_of_service || '';

  return (
    <div>
      <SettingsSection hasDivider={false}>
        <SettingsItem
          title="Cookie Banner"
          description="Enable this to show a cookie banner when someone first visits your publication site to allow them to opt out of certain cookies."
        >
          <Switch
            id="cookie_banner_enabled"
            labelText="Show Cookie Banner"
            checked={site?.draft_site_version?.settings?.cookie_banner_enabled || false}
            onCheckedChange={(checked: boolean) =>
              updateSiteVersion.mutate({
                settings: {
                  cookie_banner_enabled: checked,
                },
              })
            }
          />
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem
          title="Signup ToS & Privacy Policy"
          description="Enable this to display your terms of service & privacy policy upon signup. Subscribers must check this box to signup."
        >
          <Switch
            id="terms_of_service_enabled"
            labelText="Signup ToS & Privacy Policy"
            checked={site?.draft_site_version?.settings?.terms_of_service_enabled || false}
            onCheckedChange={(checked: boolean) =>
              updateSiteVersion.mutate({
                settings: {
                  terms_of_service_enabled: checked,
                },
              })
            }
          />
        </SettingsItem>

        <div>
          <Callout
            title=" Both of these must be toggled on for your publication to be GDPR & CCPA compliant."
            className="my-4"
          >
            Deletions and exports are handled at the platform level, make sure users email:{' '}
            <a href="mailto:privacy@beehiiv.com" target="_blank" rel="noreferrer" className="text-accent-500 underline">
              privacy@beehiiv.com
            </a>
            .
          </Callout>
        </div>
      </SettingsSection>

      <SettingsSection hasDivider={false}>
        <SettingsItem
          title="Subscriber Signup Terms of Service"
          description="Add a custom terms of service for your readers."
        >
          <SettingsInput
            className="max-w-sm"
            name="subscriber_signup_tos"
            labelText="Terms of Service"
            type="input"
            placeholder="https://example.com/tos"
            value={tos}
            helperText="Include a url for a ToS page. This will be accessible site if the cookie banner is enabled."
            onSave={(value: string) => {
              if (value === tos) return;

              try {
                const url = new URL(value);
                if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                  throw new Error('Invalid protocol');
                }

                updateSiteVersion.mutate({
                  settings: {
                    terms_of_service: value,
                  },
                });
              } catch (e) {
                toast.error('Please enter a valid URL.');
              }
            }}
          />
        </SettingsItem>
      </SettingsSection>
    </div>
  );
};

export default WebsiteSettingsGDPR;
