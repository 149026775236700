interface SegmentSearchProps {
  search?: string;
  order?: string;
  dir?: string;
  filterByDateCreated?: string;
  filterByDateLastUpdated?: string;
}

export default class SegmentSearch implements SegmentSearchProps {
  search: SegmentSearchProps['search'];

  order: SegmentSearchProps['order'];

  dir: SegmentSearchProps['dir'];

  filterByDateCreated: SegmentSearchProps['filterByDateCreated'];

  filterByDateLastUpdated: SegmentSearchProps['filterByDateLastUpdated'];

  params(): object {
    return {
      ...(this.search && { q: this.search }),
      ...(this.order && this.dir && { sortOrder: this.order, sortDir: this.dir }),
      ...(this.filterByDateCreated && { filterByDateCreated: this.filterByDateCreated }),
      ...(this.filterByDateLastUpdated && { filterByDateLastUpdated: this.filterByDateLastUpdated }),
    };
  }

  constructor(props: SegmentSearchProps) {
    this.search = props.search;
    this.order = props.order;
    this.dir = props.dir;
    this.filterByDateCreated = props.filterByDateCreated;
    this.filterByDateLastUpdated = props.filterByDateLastUpdated;
  }
}
