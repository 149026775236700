import { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

import { LandingPage } from '@/interfaces/landing_page';
import { UpgradePage } from '@/interfaces/upgrade_page';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import ColorInput from '@/routes/website_builder/_components/Form/ColorInput';
import FontForm from '@/routes/website_builder/_components/Form/PageSpecific/FontForm';
import TestimonialsForm from '@/routes/website_builder/_components/Form/PageSpecific/Testimonials/TestimonialsForm';
import SelectInput from '@/routes/website_builder/_components/Form/SelectInput';
import SwitchInput from '@/routes/website_builder/_components/Form/SwitchInput';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';
import { ALIGNMENT_OPTIONS } from '@/routes/website_builder/_types/options';
import FontPicker from '@/ui/Settings/FontPicker';

import CustomizeMoreButton from '../../Helpers/CustomizeMoreButton';
import { SectionGroupingSlideOut, TestimonialSectionIcon } from '../../Helpers/SectionGroupingSlideOut';

interface Props {
  handleSave: (payload: any) => void;
  page: LandingPage | UpgradePage;
  type: 'landing_page' | 'upgrade_page';
}

const TestimonialSection = ({ handleSave, page, type }: Props) => {
  const { fontOptions, handleSaved } = usePageContext();
  const [isCustomizeShowing, setIsCustomizeShowing] = useState(false);

  const testimonialsSectionWithDefaults = page?.testimonials_section_with_defaults;

  return (
    <SectionGroupingSlideOut
      title="Testimonial Section"
      showPlanBadge
      sectionIcon={<TestimonialSectionIcon />}
      highlightProps={{
        sectionId: 'testimonial-section',
      }}
    >
      <PropertiesGrouping label="General">
        <SwitchInput
          label="Enabled"
          name="testimonials-enabled"
          enabled={page?.testimonials_section.enabled || false}
          onSave={(value: boolean) => {
            handleSave({
              ...page?.testimonials_section,
              enabled: value,
            });
          }}
        />
        {page?.testimonials_section.enabled && (
          <>
            <ColorInput
              label="Background"
              color={testimonialsSectionWithDefaults.bg_color}
              resettable={{
                label: 'Global Background Color',
                resetValue: null,
                check: page?.testimonials_section.bg_color !== null,
              }}
              onSave={(color: string) => {
                handleSave({
                  ...page?.testimonials_section,
                  bg_color: color,
                });
              }}
            />
            <SelectInput
              label="Alignment"
              value={testimonialsSectionWithDefaults.text_align}
              options={ALIGNMENT_OPTIONS}
              onSave={(value: string) => {
                handleSave({
                  ...page?.testimonials_section,
                  text_align: value,
                });
              }}
            />
          </>
        )}
      </PropertiesGrouping>
      {page?.testimonials_section.enabled && (
        <>
          <FontForm
            label="Overline"
            supportsMarkdown
            textValue={testimonialsSectionWithDefaults.overline}
            onSaveText={(value) => {
              handleSave({
                ...page?.testimonials_section,
                overline: value,
              });
            }}
            onSave={(key, value) => {
              handleSave({
                ...page?.testimonials_section,
                [key]: value,
              });
            }}
            editableProps={{
              overline_color: {
                type: 'colorInput',
                value: testimonialsSectionWithDefaults.overline_color,
                resetCheckValue: page?.testimonials_section.overline_color,
                resetLabel: 'Global Overline Color',
              },
              overline_font_family: {
                type: 'fontFamilySelect',
                value: testimonialsSectionWithDefaults.overline_font_family,
                resetCheckValue: page?.testimonials_section.overline_font_family,
                label: 'Global Heading Font',
              },
              overline_font_size: { type: 'fontSizeSelect', value: testimonialsSectionWithDefaults.overline_font_size },
            }}
            tooltip={{
              id: 'overline',
              text: 'Small text that appears above the headline',
            }}
          />
          <FontForm
            label="Headline"
            supportsMarkdown
            textValue={testimonialsSectionWithDefaults.headline}
            onSaveText={(value) => {
              handleSave({
                ...page?.testimonials_section,
                headline: value,
              });
            }}
            onSave={(key, value) => {
              handleSave({
                ...page?.testimonials_section,
                [key]: value,
              });
            }}
            editableProps={{
              headline_color: {
                type: 'colorInput',
                value: testimonialsSectionWithDefaults.headline_color,
                resetCheckValue: page?.testimonials_section.headline_color,
                resetLabel: 'Global Headline Color',
              },
              headline_font_family: {
                type: 'fontFamilySelect',
                value: testimonialsSectionWithDefaults.headline_font_family,
                resetCheckValue: page?.testimonials_section.headline_font_family,
                label: 'Global Heading Font',
              },
              headline_font_size: {
                type: 'fontSizeSelect',
                value: testimonialsSectionWithDefaults.headline_font_size,
              },
            }}
          />
          <PropertiesGrouping
            label="Testimonials"
            className="space-y-2"
            actionChild={
              <CustomizeMoreButton
                Icon={PencilSquareIcon}
                isOpen={isCustomizeShowing}
                text="Styles"
                onClose={() => setIsCustomizeShowing(!isCustomizeShowing)}
              />
            }
          >
            {page && <TestimonialsForm page={page} handleSave={handleSave} type={type} onSave={handleSaved} />}
            {isCustomizeShowing && (
              <>
                <ColorInput
                  label="Card Background"
                  color={testimonialsSectionWithDefaults.card_bg_color}
                  resettable={{
                    label: 'Global Background Color',
                    resetValue: null,
                    check: page?.testimonials_section.card_bg_color !== null,
                  }}
                  onSave={(color: string) => {
                    handleSave({
                      ...page?.testimonials_section,
                      card_bg_color: color,
                    });
                  }}
                />
                <ColorInput
                  label="Card Border"
                  color={testimonialsSectionWithDefaults.card_border_color}
                  resettable={{
                    label: 'Global Border Color',
                    resetValue: null,
                    check: page?.testimonials_section.card_bg_color !== null,
                  }}
                  onSave={(color: string) => {
                    handleSave({
                      ...page?.testimonials_section,
                      card_border_color: color,
                    });
                  }}
                />
                <ColorInput
                  label="Card Text"
                  color={testimonialsSectionWithDefaults.card_text_color}
                  resettable={{
                    label: 'Global Body Color',
                    resetValue: null,
                    check: page?.testimonials_section.card_text_color !== null,
                  }}
                  onSave={(color: string) => {
                    handleSave({
                      ...page?.testimonials_section,
                      card_text_color: color,
                    });
                  }}
                />
                <FontPicker
                  label="Font Family"
                  searchPlaceholder="Search Font"
                  value={testimonialsSectionWithDefaults.card_font_family}
                  noOptionsText="No Matching Fonts"
                  options={fontOptions}
                  resettable={{
                    check: page.testimonials_section.card_font_family !== null,
                    resetValue: null,
                    label: 'Global Body Font',
                  }}
                  onSave={(value: string) => {
                    handleSave({
                      ...page?.testimonials_section,
                      card_font_family: value,
                    });
                  }}
                />
              </>
            )}
          </PropertiesGrouping>
        </>
      )}
    </SectionGroupingSlideOut>
  );
};

export default TestimonialSection;
