import { ChangeEvent, memo, useCallback } from 'react';

import { Range } from '@/components/UI/Range';

export type AlignmentInputProps = {
  value: number;
  disabled?: boolean;
  onChange: (value: number) => void;
};

type OnChangeEvent = ChangeEvent<HTMLInputElement>;

export const AlignmentInput = memo(({ onChange, value, disabled }: AlignmentInputProps) => {
  const handleChange = useCallback(
    (e: OnChangeEvent) => {
      onChange(Math.min(180, Math.max(-180, Number(e.currentTarget.value))));
    },
    [onChange]
  );

  return (
    <Range
      label="Alignment"
      id="rotate-input"
      name="rotate-input"
      min="-180"
      max="180"
      step="5"
      value={value}
      disabled={disabled}
      onChange={handleChange}
    />
  );
});

AlignmentInput.displayName = 'AlignmentInput';
