import React, { useCallback } from 'react';

import { Button } from '../ui/Button';
import { Icon } from '../ui/Icon';
import { Tooltip } from '../ui/Tooltip';

import { ButtonProps } from './types';

const icon = 'AddColumnBefore';
const title = 'Add column before';
const shortcut = undefined;

export const AddColumnBeforeButton = ({ editor, $showTooltip = false, ...rest }: ButtonProps): JSX.Element => {
  const addColumnBefore = useCallback(() => {
    editor.chain().focus().addColumnBefore().run();
  }, [editor]);

  return (
    <Tooltip enabled={$showTooltip} title={title} shortcut={shortcut}>
      <Button
        $leftSlot={<Icon name={icon} />}
        onClick={addColumnBefore}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export default AddColumnBeforeButton;
