import { FONT_SIZE_OPTIONS } from '@/interfaces/font';
import { HomePage } from '@/interfaces/home_page';
import SelectInput from '@/routes/website_builder/_components/Form/SelectInput';
import {
  ArchiveSectionIcon,
  SectionGroupingSlideOut,
} from '@/routes/website_builder/_components/Helpers/SectionGroupingSlideOut';
import { ARCHIVE_LAYOUT_OPTIONS, Option } from '@/routes/website_builder/_types/options';

import ColorInput from '../../Form/ColorInput';
import SwitchInput from '../../Form/SwitchInput';
import TextInput from '../../Form/TextInput';

interface Props {
  homePage?: HomePage;
  handleSave: (payload: any) => void;
}

const PAGINATION_TYPE_OPTIONS: Option[] = [
  {
    label: 'Pagination',
    value: 'pagination',
  },
  {
    label: 'Load More',
    value: 'load_more',
  },
];

const ArchiveSection = ({ homePage, handleSave }: Props) => {
  const postSectionWithDefaults = homePage?.posts_section_with_defaults;

  return (
    <SectionGroupingSlideOut
      title="Archive Section"
      showPlanBadge
      sectionIcon={<ArchiveSectionIcon />}
      highlightProps={{
        sectionId: 'archive-section',
      }}
    >
      <div className="flex flex-col space-y-3">
        <SwitchInput
          key="archive-header-enabled"
          label="Show Archive Header"
          name="archive-header-enabled"
          enabled={postSectionWithDefaults?.archive?.show_archive_header || false}
          onSave={(value: boolean) => {
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                show_archive_header: value,
              },
            });
          }}
        />
        <TextInput
          label="Header Text"
          value={postSectionWithDefaults?.archive.header_text || ''}
          placeholderText="Archive"
          onSave={(value: string) =>
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                header_text: value,
              },
            })
          }
        />
        <SelectInput
          key="font-size-archive-select"
          label="Font Size"
          value={postSectionWithDefaults?.archive.font_size || '4xl'}
          options={FONT_SIZE_OPTIONS}
          onSave={(value: string) => {
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                font_size: value,
              },
            });
          }}
        />
        <SelectInput
          label="Archive Section Pattern"
          options={ARCHIVE_LAYOUT_OPTIONS}
          value={postSectionWithDefaults?.archive.layout || 'grid'}
          onSave={(value: string) => {
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                layout: value,
              },
            });
          }}
        />
        <SelectInput
          label="More Posts Type"
          options={PAGINATION_TYPE_OPTIONS}
          value={postSectionWithDefaults?.archive.load_more_posts_type || 'pagination'}
          onSave={(value: string) => {
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                load_more_posts_type: value,
              },
            });
          }}
        />
        <ColorInput
          label="Header Text Color"
          color={postSectionWithDefaults?.archive.header_text_color}
          resettable={{
            label: 'Header Text Color',
            resetValue: null,
            check: homePage?.posts_section?.archive.header_text_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                header_text_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Button Background Color"
          color={postSectionWithDefaults?.archive.button_bg_color}
          resettable={{
            label: 'Global Secondary Color',
            resetValue: null,
            check: homePage?.posts_section?.archive.button_bg_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                button_bg_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Button Text Color"
          color={postSectionWithDefaults?.archive.button_text_color}
          resettable={{
            label: 'Global Text on Secondary Color',
            resetValue: null,
            check: homePage?.posts_section?.archive.button_text_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                button_text_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Button Active Background Color"
          color={postSectionWithDefaults?.archive.button_active_bg_color}
          resettable={{
            label: 'Global Primary Color',
            resetValue: null,
            check: homePage?.posts_section?.archive.button_active_bg_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                button_active_bg_color: color,
              },
            });
          }}
        />
        <ColorInput
          label="Button Active Text Color"
          color={postSectionWithDefaults?.archive.button_active_text_color}
          resettable={{
            label: 'Global Text on Primary Color',
            resetValue: null,
            check: homePage?.posts_section?.archive.button_active_text_color !== null,
          }}
          onSave={(color: string) => {
            handleSave({
              ...homePage?.posts_section,
              archive: {
                ...homePage?.posts_section?.archive,
                button_active_text_color: color,
              },
            });
          }}
        />
      </div>
    </SectionGroupingSlideOut>
  );
};

export default ArchiveSection;
