import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ArrowSmallLeftIcon, Bars3Icon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Button } from '@/ui/Button';

import ActionModal from '../../../components/ActionModal';
import Text from '../../../components/Text';
import { TiptapEditor } from '../../../components/TiptapEditor';
import { PublicationProvider } from '../../../components/TiptapEditor/lib/context/PublicationContext';
import { GlobalStyles } from '../../../components/TiptapEditor/lib/GlobalStyles';
import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { useSettings } from '../../../context/settings-context';
import { useCurrentPublication } from '../../../hooks';
import { useNavGroups } from '../../../hooks/useNavGroups';
import { CustomPage, CustomPagePayload } from '../../../interfaces/custom_page';

import PageForm from './PageForm';

interface Props {
  isSubmitting: boolean;
  onSave: (payload: any) => void;
  customPage?: CustomPage | null;
  navLink?: any;
  isEditing?: boolean;
}

const PageLayout = ({ isSubmitting, onSave, customPage, navLink, isEditing = false }: Props) => {
  const navigate = useNavigate();
  const navGroupsQuery = useNavGroups();

  const [currentPublicationId] = useCurrentPublicationState();

  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [initializeSave, setInitializeSave] = useState<boolean>(false);
  const { data: currentPublication } = useCurrentPublication();
  const [showSidebar, setShowSidebar] = useState(true);

  const [content, setContent] = useState<any>(customPage?.content || {});
  const [thumbnail, setThumbnail] = useState<string | File | any>(null);
  const [pagePayload, setPagePayload] = useState<CustomPagePayload>({
    meta_title: customPage?.meta_title || '',
    meta_description: customPage?.meta_description || '',
    slug: customPage?.slug || '',
    noindexing_enabled: customPage?.noindexing_enabled || false,
    status: customPage?.status || 'draft',
    cta_text: navLink?.cta_text || '',
    nav_group_id: navLink?.nav_group_id || '',
  });

  const { settings } = useSettings();

  const handleUpdate = useCallback(({ editor }: any) => {
    if (!changesMade) setChangesMade(true);
    setContent(editor.getJSON());
  }, []);

  useEffect(() => {
    if (customPage) {
      setThumbnail(customPage.thumbnail);
      setContent(customPage.content);
      setPagePayload({
        meta_title: customPage.meta_title,
        meta_description: customPage.meta_description,
        slug: customPage.slug,
        noindexing_enabled: customPage.noindexing_enabled,
        status: customPage.status,
        cta_text: navLink.cta_text,
        nav_group_id: navLink.nav_group_id,
        premium: customPage.premium,
      });
    }
  }, [customPage]);

  const buildFinalPayload = () => {
    const formData = new FormData();

    if (typeof thumbnail !== 'string') {
      formData.append('custom_page[thumbnail]', thumbnail);
    }

    formData.append('publication_id', currentPublication?.id || '');
    formData.append('custom_page[meta_title]', pagePayload?.meta_title || '');
    formData.append('custom_page[meta_description]', pagePayload?.meta_description || '');
    formData.append('custom_page[slug]', pagePayload.slug || '');
    formData.append('custom_page[noindexing_enabled]', pagePayload?.noindexing_enabled?.toString() || 'false');
    formData.append('custom_page[status]', pagePayload.status || 'draft');
    formData.append('custom_page[content]', JSON.stringify(content));
    formData.append('custom_page[premium]', pagePayload?.premium?.toString() || 'false');

    formData.append('nav_link[nav_group_id]', pagePayload.nav_group_id || '');
    formData.append('nav_link[cta_text]', pagePayload.cta_text || '');
    formData.append('nav_link[href]', pagePayload.slug || ''); // we use the slug as the href for internal, page links

    return formData;
  };

  const handleSave = async () => {
    if (!content || Object.keys(content).length === 0) {
      toast.error('You have not added anything to the page!');
      return;
    }

    if (!pagePayload.slug) {
      toast.error('Please enter a slug.');
      return;
    }

    if (!pagePayload.cta_text) {
      toast.error('Please enter a cta text');
      return;
    }

    if (pagePayload?.meta_title && pagePayload?.meta_title?.length > 70) {
      toast.error('Please enter a meta title with less than 70 characters.');
      return;
    }

    if (pagePayload?.meta_description && pagePayload?.meta_description?.length > 160) {
      toast.error('Please enter a meta title with less than 160 characters.');
      return;
    }

    await onSave(buildFinalPayload());
    setIsSaving(false);
    setInitializeSave(false);
    setChangesMade(false);
  };

  const handleSetPagePayload = (payload: any) => {
    setChangesMade(true);
    setPagePayload(payload);
  };

  const handleImageChange = (file: File) => {
    setChangesMade(true);
    setThumbnail(file);
  };

  const handleImageClear = () => {
    setChangesMade(true);
    setThumbnail(null);
  };

  const handleGoBack = () => {
    navigate('/settings/website/pages');
  };

  const handlePromptLeave = () => {
    if (changesMade) {
      setIsLeaving(true);
    } else {
      handleGoBack();
    }
  };

  const initialContent = useMemo(() => content, []);

  return (
    <>
      <ActionModal
        isOpen={isLeaving}
        onClose={() => setIsLeaving(false)}
        onProceed={handleGoBack}
        resourceId="leave page"
        isWorking={isSaving}
        headerText="Have you saved?"
        actionText="Back to Settings"
      >
        Please make sure to save any progress before leaving.
      </ActionModal>
      <ActionModal
        isOpen={initializeSave}
        onClose={() => setInitializeSave(false)}
        onProceed={handleSave}
        resourceId="custom_page"
        isWorking={isSaving}
        headerText={isEditing ? 'Save Changes' : 'Create New Page'}
        actionText={isEditing ? 'Save' : 'Create'}
      >
        {isEditing
          ? 'Are you sure you want to save these changes? If this page is currently live, users will be able to immediately see any updates.'
          : 'You are about to create this page, it will not be live unless you have added it to one of your navigation dropdowns or as a Standalone Page. Confirm to create.'}
      </ActionModal>
      <div className="relative justify-end w-full h-screen bg-white md:fixed md:flex">
        <div className={cx('w-full absolute inset-0', showSidebar && 'xl:relative xl:w-2/3')}>
          {/* Header */}
          <div className="absolute top-0 right-0 flex justify-start w-full px-8 py-3 bg-white">
            <div className="flex justify-between w-full">
              <div className="flex items-center space-x-4">
                <button
                  className="text-gray-400 transition-all border border-gray-300 rounded-sm hover:bg-gray-200 hover:text-gray-600"
                  type="button"
                  onClick={handlePromptLeave}
                >
                  <ArrowSmallLeftIcon className="w-5 h-5" />
                </button>
                <Text type="bold" size="sm">
                  {pagePayload?.cta_text ? pagePayload?.cta_text : 'New Page'}
                </Text>
              </div>

              <div className="z-10 flex items-center space-x-2">
                <Button variant="primary-inverse" onClick={handlePromptLeave}>
                  Cancel
                </Button>
                <Button loading={isSubmitting} onClick={() => setInitializeSave(true)} disabled={!changesMade}>
                  {isEditing ? 'Save Changes' : 'Create Page'}
                </Button>
                {!showSidebar && (
                  <button
                    className="flex items-center justify-center w-9 h-9 text-gray-400 transition-all border border-gray-300 rounded-sm hover:bg-gray-200 hover:text-gray-600"
                    type="button"
                    onClick={() => setShowSidebar(true)}
                    data-testid="show-sidebar-button"
                  >
                    <Bars3Icon className="w-5 h-5" />
                  </button>
                )}
                {/* </div> */}
              </div>
            </div>
          </div>
          {/* Editor */}
          <div className="h-screen px-8 pb-8 overflow-y-auto pt-14">
            <div>
              <PublicationProvider id={currentPublicationId}>
                <GlobalStyles colors={currentPublication?.color_palette || []} />
                {settings && (
                  <TiptapEditor
                    publicationId={currentPublicationId}
                    settings={settings}
                    onUpdate={handleUpdate}
                    content={initialContent}
                  />
                )}
              </PublicationProvider>
            </div>
          </div>
        </div>

        {showSidebar && (
          <button
            onClick={() => setShowSidebar(false)}
            type="button"
            tabIndex={0}
            aria-label="overlay-background"
            className="absolute inset-0 z-10 hidden bg-gray-500 bg-opacity-75 md:block xl:hidden"
            data-testid="close-sidebar-overlay-button"
          />
        )}

        {/* Sidebar */}
        {showSidebar && (
          <div
            className={cx('relative bg-white z-20 h-full sm:border-l sm:border-gray-200 md:w-2/3 lg:w-1/2 xl:w-1/3')}
          >
            <div className="flex w-full justify-end px-8 pt-3 mt-px">
              <button
                className="flex items-center justify-center w-9 h-9 text-gray-400 transition-all border border-gray-300 rounded-sm hover:bg-gray-200 hover:text-gray-600"
                type="button"
                onClick={() => setShowSidebar(false)}
                data-testid="close-sidebar-button"
              >
                <Bars3Icon className="w-5 h-5" />
              </button>
            </div>

            <div className={cx('h-full overflow-x-hidden overflow-y-auto', !showSidebar && 'md:hidden p-8')}>
              {navGroupsQuery.data && (
                <PageForm
                  navGroups={navGroupsQuery.data}
                  publication={currentPublication}
                  pagePayload={pagePayload}
                  setPagePayload={handleSetPagePayload}
                  image={thumbnail}
                  handleImageChange={handleImageChange}
                  handleImageClear={handleImageClear}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PageLayout;
