import { useCallback, useEffect } from 'react';
import { useQueryParam } from 'use-query-params';

import { useCurrentPublicationState } from '../../context/current-publication-context';

export const SWITCH_PUBLICATION_QUERY_PARAM = 'spid';

export default function SwitchPublicationViaQuery() {
  const [spid, setSpid] = useQueryParam<string | undefined>(SWITCH_PUBLICATION_QUERY_PARAM);
  const [currentPublicationId, setCurrentPublicationId] = useCurrentPublicationState(false);

  const switchPublicationIfQueryPresent = useCallback(() => {
    if (spid && currentPublicationId) {
      if (currentPublicationId !== spid) {
        setCurrentPublicationId(spid);
      }

      // For some reason if we don't delay this,
      // it doesn't actually happen. My guess is due to updating
      // the same state via a React hook that is triggered off it
      setTimeout(() => {
        setSpid(undefined);
      }, 1000);
    }
  }, [currentPublicationId, spid, setCurrentPublicationId, setSpid]);

  useEffect(() => {
    switchPublicationIfQueryPresent();
  }, [switchPublicationIfQueryPresent]);

  return null;
}
