import LoadingList from '../LoadingList/LoadingList';
import { PageHeading } from '../PageHeading';

import { LoadingListPageProps } from './types';

const LoadingListPage = ({ isLoading, isError }: LoadingListPageProps) => {
  if (isError)
    return (
      <div className="bg-white rounded p-4 w-full text-sm text-gray-700">
        Something went wrong, please try again later.
      </div>
    );

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb>
              <div className="h-4 w-24 bg-gray-100 rounded animate-pulse" />
            </PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb>
              <div className="h-4 w-32 bg-gray-100 rounded animate-pulse" />
            </PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      {isLoading && <LoadingList />}
    </>
  );
};

export default LoadingListPage;
