import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { AdNetworkSimilarBrand } from '@/interfaces/ad_network/internal/similar_brand';
import api from '@/services/swarm';

export default function useAdvertiserSimilarBrands({ id }: { id: string }) {
  return useQuery<AdNetworkSimilarBrand[], AxiosError>(
    ['ad_network', 'internal', 'advertisers', id, 'similar_brands'],
    () => api.get(`/ad_network/internal/advertisers/${id}/similar_brands`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
