import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import analytics from '@/utils/analytics';

import api from '../../services/swarm';

const useDeleteOrganization = (organizationId: string | undefined, publicationId?: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      api.delete(`/organizations/${organizationId}`, {
        data: { publication_id: publicationId },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        localStorage.clear();
        analytics.track('Account Deleted');
        navigate('/login');
        queryClient.invalidateQueries(['organizations', organizationId]);
      },
    }
  );
};

export default useDeleteOrganization;
