import { useSearchParams } from 'react-router-dom';
import { BellSnoozeIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import Banner from '@/components/Banner';
import Card from '@/components/Card';
import IconButton from '@/components/IconHelpers/IconButton';
import Tooltip from '@/components/Tooltip';
import useBoostOfferCard from '@/hooks/boosts/monetize/useBoostOfferCard';
import useCreateSnoozedBoostOffer from '@/hooks/boosts/monetize/useCreateSnoozedBoostOffer';
import useBoostOfferEligibleCountries from '@/hooks/boosts/useBoostOfferEligibleCountries';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';
import { Button } from '@/ui/Button';

import BoostOfferInformationBlock from './BoostOfferInformationBlock';
import PublicationInformationBlock from './PublicationInformationBlock';
import RegionalRestrictionsBlock from './RegionalRestrictionsBlock';

interface BoostOfferCardParams {
  boostOffer: BoostOffer;
  onApply?: () => void;
  onSnooze?: (boostOfferId: string) => void;
  onExpand?: () => void;
  isSnoozing?: boolean;
  isPreview?: boolean;
}

const BoostOfferCard = ({
  boostOffer,
  onSnooze,
  onApply,
  onExpand,
  isSnoozing = false,
  isPreview = false,
}: BoostOfferCardParams) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { alreadyApplied, ctaIcon, ctaText, tooltipText, shouldDisableApply } = useBoostOfferCard(boostOffer);
  const createSnoozedBoostOffer = useCreateSnoozedBoostOffer({
    onSuccess: () => {},
  });

  const handleExpand = () => {
    searchParams.set('boost_offer_id', boostOffer.id);
    setSearchParams(searchParams);
    if (onExpand) {
      onExpand();
    }
  };

  const handleClickSnooze = () => {
    createSnoozedBoostOffer.mutate(boostOffer.id);
    onSnooze?.(boostOffer.id);
  };

  const snoozeButton = onSnooze ? (
    <Tooltip
      tooltipClass="text-center"
      id={`snooze-boost-offer-${boostOffer.id}`}
      text="Snooze offer"
      showIcon={false}
      autoWidth
    >
      <IconButton onClick={handleClickSnooze} disabled={isSnoozing}>
        <BellSnoozeIcon />
      </IconButton>
    </Tooltip>
  ) : null;

  const handleClickApply = () => {
    onApply?.();
  };

  const { hasRegionalRestrictions } = useBoostOfferEligibleCountries(boostOffer.eligible_countries);

  return (
    <Card>
      {isPreview && (
        <div className="mb-5">
          <Banner isScreenWide={false} title="Preview of your offer in the marketplace" bodyText="" />
        </div>
      )}
      <div className="space-y-4 flex flex-col h-full justify-between">
        <div className="space-y-4">
          <PublicationInformationBlock boostOffer={boostOffer} onExpand={handleExpand} isPreview={isPreview} />
          <BoostOfferInformationBlock boostOffer={boostOffer} />
        </div>

        <div className={cx('w-full flex items-center', hasRegionalRestrictions ? 'justify-between' : 'justify-end')}>
          <RegionalRestrictionsBlock boostOffer={boostOffer} />
          <div className="flex flex-col sm:flex-row sm:gap-x-2 justify-end items-center">
            {snoozeButton}
            <Tooltip
              tooltipClass="text-center"
              id={`boost-offer-cta-${boostOffer.id}`}
              text={tooltipText}
              showIcon={false}
              autoWidth
              isEnabled={!!tooltipText}
            >
              <Button
                disabled={shouldDisableApply || isPreview}
                onClick={handleClickApply}
                variant="primary"
                size="xs"
                block
                type="button"
                Icon={ctaIcon}
              >
                {alreadyApplied ? ctaText : 'Apply'}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BoostOfferCard;
