export const FORM_STEP_CLASSES = {
  details: 'premium-tier-step-one',
  pricing: 'premium-tier-step-two',
  benefits: 'premium-tier-step-three',
  advanced: 'premium-tier-step-four',
};

const useTierFormStep = (step: keyof typeof FORM_STEP_CLASSES) => {
  const className = FORM_STEP_CLASSES[step];

  const onFocus = () =>
    document
      .querySelectorAll(`.${className}, .${className} *`)
      .forEach((e) => e.classList.add('bg-surface-100', 'text-surface-900', 'border-surface-700'));

  const onBlur = () =>
    document
      .querySelectorAll(`.${className}, .${className} *`)
      .forEach((e) => e.classList.remove('bg-surface-100', 'text-surface-900', 'border-surface-700'));

  return {
    onFocus,
    onBlur,
  };
};

export default useTierFormStep;
