import { memo } from 'react';

import { IconName } from '../../lib/types';
import { Button } from '../ui/Button';
import { Icon } from '../ui/Icon';
import { Tooltip } from '../ui/Tooltip';

export const ToolbarButton = memo(
  ({
    title,
    icon,
    iconRight,
    is,
    can,
    muted,
    tooltipShortcut,
    onClick,
  }: {
    is: boolean;
    can: boolean;
    onClick?: () => void;
    tooltipShortcut?: string[];
    title: string;
    icon: IconName;
    iconRight?: IconName;
    muted?: boolean;
  }) => {
    return (
      <Tooltip enabled={(title && title.length > 0) || false} shortcut={tooltipShortcut} title={title}>
        <Button
          $active={is}
          $isIconButton
          $leftSlot={<Icon name={icon} />}
          $muted={muted}
          $rightSlot={iconRight ? <Icon name={iconRight} $size="0.66rem" /> : undefined}
          $showTooltip
          $size="small"
          $variant="quaternary"
          disabled={!can}
          onClick={onClick}
        />
      </Tooltip>
    );
  }
);

ToolbarButton.displayName = 'ToolbarButton';
