import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { SkeletonLoader } from '@/components/SkeletonLoader';
import { useUpdateWebTemplate } from '@/hooks';
import { Publication } from '@/interfaces/publication';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  attributeName: string;
  webTemplate: any;
  publications: Publication[];
  isLoadingPublications: boolean;
  showLabelText?: boolean;
}

const AddPublication = ({
  webTemplate,
  publications,
  isLoadingPublications,
  attributeName,
  showLabelText = true,
}: Props) => {
  const webTemplateMutation = useUpdateWebTemplate();

  const [selectedPubId, setSelectedPubId] = useState<string | null>(null);

  const handleAddPublicationIds = () => {
    if (selectedPubId) {
      const payload = {
        [attributeName]: [...(webTemplate?.[attributeName] || []), selectedPubId],
      };
      webTemplateMutation.mutate(payload);
      setSelectedPubId(null);
      toast.success('Saved!');
    }
  };

  const setPublicationOptions = () => {
    return publications?.map((publication: Publication) => {
      return {
        label: publication.name,
        value: publication.id,
      };
    });
  };

  if (!publications || publications.length === 0) {
    return null;
  }

  return (
    <SkeletonLoader
      isLoading={isLoadingPublications}
      skeletons={
        <div className="flex items-center justify-end">
          <div className="bg-gray-200 h-8 w-full rounded" />
        </div>
      }
      useFragment
    >
      <div className="space-y-2">
        <Dropdown
          name={`multi-pubs-${attributeName}`}
          labelText={showLabelText ? 'Select a publication' : undefined}
          value={selectedPubId || ''}
          options={setPublicationOptions()}
          onSelect={(_name: string, value: string) => {
            setSelectedPubId(value);
          }}
        />
        {selectedPubId && (
          <div className="space-x-2">
            <Button variant="primary-inverse" onClick={() => setSelectedPubId(null)}>
              Remove
            </Button>
            <Button onClick={handleAddPublicationIds}>Add</Button>
          </div>
        )}
      </div>
    </SkeletonLoader>
  );
};

export default AddPublication;
