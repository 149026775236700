interface Props {
  className?: string;
}

const Trigger = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4256_46292)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5735 0.0472134C10.9883 0.179381 11.2699 0.564689 11.2699 1.00001V6.15386H14.7143C15.0883 6.15386 15.4311 6.36255 15.6028 6.69481C15.7744 7.02707 15.7464 7.42737 15.53 7.73241L8.54586 17.5786C8.294 17.9336 7.84138 18.085 7.42661 17.9528C7.01183 17.8206 6.73021 17.4353 6.73021 17V11.8462H3.28577C2.91177 11.8462 2.56904 11.6375 2.39736 11.3052C2.22568 10.9729 2.25375 10.5727 2.47013 10.2676L9.45425 0.421454C9.70611 0.0663878 10.1587 -0.0849541 10.5735 0.0472134ZM5.22112 9.84616H7.73021C8.2825 9.84616 8.73021 10.2939 8.73021 10.8462V13.8618L12.779 8.15386H10.2699C9.71761 8.15386 9.2699 7.70614 9.2699 7.15386V4.13824L5.22112 9.84616Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4256_46292">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Trigger;
