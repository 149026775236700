import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';
import { TimePeriod } from '@/utils';

import useCurrentTimeZone from '../useCurrentTimeZone';

interface ApiResponse {
  milestones: {
    id: string;
    name: string;
    total_achieved: number;
    trimmed_name: string;
  }[];
}

interface Props {
  period: TimePeriod;
  additionalParams?: any;
  allResults?: boolean;
}

const useMilestoneBreakdown = ({ period }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const currentTimeZone = useCurrentTimeZone();

  const fetchBreakdown = () => {
    return api
      .get('/referral_program/metrics/milestone_breakdown', {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
          period,
        },
      })
      .then((res) => res.data);
  };

  return useQuery<ApiResponse>(['milestone_breakdown', currentPublicationId, period, currentTimeZone], fetchBreakdown, {
    keepPreviousData: true,
  });
};

export default useMilestoneBreakdown;
