import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import { Card } from '@/ui/Card';

import PageContainer from '../../_components/PageContainer';
import { usePublicationSettings } from '../context';

import FooterDetailsForm from './FooterDetailsForm';
import PresetForm from './PresetForm';
import SendingDetailsForm from './SendingDetailsForm';

const PublicationEmailSettings: React.FC = () => {
  const { currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);
  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId}>
          <Card id="sending-details" showCopyIdButton>
            <SendingDetailsForm publication={publication} />
          </Card>
          <Card id="footer-details" showCopyIdButton>
            <FooterDetailsForm publication={publication} />
          </Card>
          <Card id="preset" showCopyIdButton>
            <PresetForm publication={publication} />
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default PublicationEmailSettings;
