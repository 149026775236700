import { BanknotesIcon } from '@heroicons/react/20/solid';
import { Grid } from '@tremor/react';

import { EmptyCard } from '@/components/ResourceList';
import useEmailApplications from '@/hooks/boosts/grow/useEmailApplications';
import { Button } from '@/ui/Button';

import EmailApplicationItem from './EmailApplicationItem';

interface Props {
  boostOfferId?: string;
  maxSpendCents?: number;
}

const EmailApplications = ({ boostOfferId, maxSpendCents }: Props) => {
  const { noResults, boostSends, hasNextPage, isFetchingNextPage, fetchNextPage } = useEmailApplications(boostOfferId, {
    maxSpendCents,
    perPage: 9,
  });
  return (
    <>
      {noResults || !boostSends.length ? (
        <div className="h-96">
          <EmptyCard
            title="No Applications"
            description="Your offer has no applications yet"
            primaryIcon={BanknotesIcon}
          />
        </div>
      ) : null}

      {boostSends.length > 0 ? (
        <Grid numColsSm={3} numCols={1} className="gap-4">
          {boostSends.map((boostSend) => (
            <EmailApplicationItem key={`email-application-item-${boostSend.id}`} item={boostSend} />
          ))}
        </Grid>
      ) : null}
      {hasNextPage && (
        <div>
          <Button
            variant="primary-inverse"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? 'Loading more...' : 'Load more'}
          </Button>
        </div>
      )}
    </>
  );
};

export default EmailApplications;
