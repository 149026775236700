import { useQuery } from 'react-query';

import { PermissionLevel, PermissionResource, Permissions } from '@/interfaces/permissions';
import api from '@/services/swarm';

export const checkPermissions = (
  permissions: Permissions,
  resource: PermissionResource,
  permission: PermissionLevel
) => {
  const keys = resource.split('/');

  // Walk down permissions tree and return permissions array (or empty if not found)
  const permissionValues = keys.reduce((obj: any, key: string) => {
    return obj && obj[key] !== undefined ? obj[key] : undefined;
  }, permissions);

  return Array.isArray(permissionValues) ? permissionValues.includes(permission) : false;
};

const usePublicationPermissions = (publicationId: string) =>
  useQuery<Permissions>(['publications', publicationId, 'permissions'], () =>
    api
      .get('/permissions', {
        params: {
          publication_id: publicationId,
        },
      })
      .then((res) => {
        const dataCopy = { ...res.data };
        delete dataCopy.admin;
        return dataCopy;
      })
  );

export default usePublicationPermissions;
