import { forwardRef, HTMLProps, useEffect, useRef, useState } from 'react';

import { Label } from '../Label';

export type RangeProps = {
  label: string;
} & Omit<HTMLProps<HTMLInputElement>, 'type'>;

export const Range = forwardRef<HTMLInputElement, RangeProps>(
  ({ label: labelText, name, value, min = 0, max = 1, ...rest }, ref) => {
    const field = useRef<HTMLLabelElement>(null);
    const [initialPercentage, setInitialPercentage] = useState<number | null>(null);
    const [currentPercentage, setCurrentPercentage] = useState<number | null>(null);

    useEffect(() => {
      const currentMin = Number(min);
      const currentMax = Number(max);
      const currentValue = Number(value);

      if (!initialPercentage) {
        setInitialPercentage(((currentValue - currentMin) / (currentMax - currentMin)) * 100);
      }

      setCurrentPercentage(((currentValue - currentMin) / (currentMax - currentMin)) * 100);
    }, [value, min, max, initialPercentage, currentPercentage]);

    useEffect(() => {
      let from = 0;
      let to = 0;

      if (!initialPercentage || !currentPercentage) {
        return;
      }

      if (initialPercentage < currentPercentage) {
        from = initialPercentage;
        to = currentPercentage;
      } else {
        from = currentPercentage;
        to = initialPercentage;
      }

      const el = field.current?.querySelector('input[type="range"]') as HTMLInputElement;
      el.style.setProperty('--from', `${from}`);
      el.style.setProperty('--to', `${to}`);
    }, [currentPercentage, initialPercentage]);

    return (
      <label ref={field} className="flex flex-col gap-2" htmlFor={name}>
        <Label text={labelText} value={value?.toString()} />
        <input
          className="w-full h-1 bg-gray-200 rounded outline-none appearance-none range-input ring-0 focus:outline-none focus:ring-0"
          name={name}
          min={min}
          max={max}
          type="range"
          ref={ref}
          value={value}
          {...rest}
        />
      </label>
    );
  }
);

Range.displayName = 'Range';
