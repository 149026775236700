export const CURRENCY_OPTIONS = [
  {
    label: '🇺🇸 USD',
    value: 'usd',
  },
  {
    label: '🇦🇺 AUD',
    value: 'aud',
  },
  {
    label: '🇧🇷 BRL',
    value: 'brl',
  },
  {
    label: '🇨🇦 CAD',
    value: 'cad',
  },
  {
    label: '🇪🇺 EUR',
    value: 'eur',
  },
  {
    label: '🇬🇧 GBP',
    value: 'gbp',
  },
  {
    label: '🇮🇳 INR',
    value: 'inr',
  },
  {
    label: '🇲🇽 MXN',
    value: 'mxn',
  }
];
