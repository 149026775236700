import { useEffect, useState } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useLocation } from 'react-router-dom';

import { useAuth } from './context/auth-context';
import useReleaseRefresh from './hooks/useReleaseRefresh';
import analytics from './utils/analytics';
import { getAttributionCookies } from './utils/marketingCookies';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';

export default function App() {
  const [referrerPathname, setReferrerPathname] = useState<string | null>(null);
  const { isLoggedIn } = useAuth();
  const attribution = getAttributionCookies();
  const location = useLocation();
  useReleaseRefresh();

  useEffect(() => {
    analytics.page({
      page: location.pathname,
      referrer: referrerPathname,
      attribution: {
        cookies: {
          ...(attribution?.fbc && { fbc: attribution.fbc }),
          ...(attribution?.fbp && { fbp: attribution.fbp }),
          ...(attribution?.ga?.slice(6) && { ga: attribution.ga.slice(6) }),
          ...(attribution?.gclid?.split('.')[2] && { gclid: attribution?.gclid?.split('.')[2] }),
          ...(attribution?.msclkid && { msclkid: attribution.msclkid }),
          ...(attribution?.li_fat_id && { li_fat_id: attribution.li_fat_id }),
          ...(attribution?.ttclid && { ttclid: attribution.ttclid }),
        },
      },
    });

    setReferrerPathname(location.pathname);
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </>
  );
}
