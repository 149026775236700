import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { StripeMigration, StripeMigrationStatus } from '@/interfaces/stripe';
import api from '@/services/swarm';

interface Props {
  id?: string;
  status: StripeMigrationStatus;
  onSuccess: (data: StripeMigration) => void;
}

const useTransitionStripeMigration = ({ id, status, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<StripeMigration>(
    () =>
      api
        .patch(`/stripe_migrations/${id}/transition`, { publication_id: currentPublicationId, status })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useTransitionStripeMigration;
