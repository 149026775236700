import { TiptapTransformer } from '@hocuspocus/transformer';
import { JSONContent } from '@tiptap/core';

import { ExtensionKit } from '../../extensions/extension-kit';

interface GenerateYdocProps {
  json: JSONContent;
  extensionKitConfig: {
    allowPolls: boolean;
    allowAds: boolean;
  };
}

export const generateYdoc = ({ json, extensionKitConfig }: GenerateYdocProps) => {
  const extensions: any = ExtensionKit(extensionKitConfig);

  return TiptapTransformer.toYdoc(json, 'default', extensions);
};
