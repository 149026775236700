import { ProgressBar } from '@tremor/react';

import { PartnerCurrentTier, PartnerTierSetting } from '@/interfaces/partner_tier';
import { capitalize } from '@/utils';

interface Params {
  tierSetting: PartnerTierSetting;
  tierData: PartnerCurrentTier;
  nextGoal: number | false;
}

const CurrentBox = ({ tierSetting, tierData, nextGoal }: Params) => {
  return (
    <div className="w-[360px] h-[294px] grow shrink basis-0 p-5 bg-gray-50 rounded-lg border border-gray-200 flex-col justify-start items-end gap-6 inline-flex">
      <div className="self-stretch pr-2 rounded-lg justify-start items-center gap-3 inline-flex">
        <div className="grow shrink basis-0 h-[33.53px] justify-start items-center gap-3 flex">
          <img
            alt={`${capitalize(tierSetting.name)} Tier`}
            className="w-[34px] h-[38px]"
            src={`/images/partner-tier-${tierSetting.name}.svg`}
          />
          <div className="w-[121px] text-gray-900 text-base font-semibold font-['SF Pro'] leading-normal">
            {capitalize(tierSetting.name)} Partner
          </div>
        </div>
      </div>
      <div className="self-stretch h-10 flex-col justify-start items-start gap-3 flex">
        <div className="self-stretch pb-2 justify-between items-start inline-flex">
          <div className="grow shrink basis-0 text-gray-600 text-sm font-['SF Pro'] leading-tight">
            Total conversions
          </div>
          <div className="grow shrink basis-0 text-right text-gray-600 text-sm font-['SF Pro'] leading-tight">
            {tierData.current_count}
            {nextGoal && `/${nextGoal - 1}`}
          </div>
        </div>
        <div className="self-stretch h-[0px] relative">
          <ProgressBar
            percentageValue={
              (nextGoal &&
                100.0 * (tierData.current_count / nextGoal)) ||
              100
            }
            color="violet"
          />
        </div>
      </div>
      <div className="self-stretch h-[0px] border border-gray-200" />
      <div className="self-stretch h-[108px] flex-col justify-start items-start gap-5 flex">
        <div className="self-stretch">
          <div className="text-gray-600 text-sm font-normal leading-tight">
            <span className="font-bold">{tierSetting.commission}%</span> commissions for 12-months
          </div>
        </div>
        {tierSetting.bonuses && (
          <div className="self-stretch h-12 flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch items-center gap-2.5 inline-flex text-gray-600 text-sm font-normal leading-tight">
              <ul className="list-disc list-outside ml-4">{tierSetting.bonuses.map((bonus) => <li>{bonus}</li>)}</ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentBox;