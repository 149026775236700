import { useInfiniteQuery } from 'react-query';

import { Pagination } from '../../interfaces/general';
import { Poll } from '../../interfaces/options';
import api from '../../services/swarm';

interface ApiResponse {
  options: Poll[];
  pagination: Pagination;
}

export default function usePollOptions({ publicationId, search, enabled = true }: { publicationId: string; search?: string; enabled?: boolean }) {
  const fetchPosts = ({ pageParam = 1 }) =>
    api
      .get(`/options/polls`, {
        params: {
          page: pageParam,
          per_page: 10,
          publication_id: publicationId,
          q: search,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([publicationId, 'poll_options', search], fetchPosts, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
    enabled,
  });
}
