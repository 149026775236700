import { useState } from 'react';
import moment from 'moment-mini';

import MagicInput from '@/components/Form/MagicInput';
import { CustomField } from '@/interfaces/custom_field';
import { Dropdown } from '@/ui/Dropdown';

interface AttributeDateInputProps {
  customField: CustomField;
  value: string;
  onChange: (newValue: string) => void;
}

const AttributeDateInput = ({ customField, value, onChange }: AttributeDateInputProps) => {
  const [inputType, setInputType] = useState(value === 'now' ? 'now' : 'custom');
  const { kind } = customField;

  const formattedKind = (customFieldKind: CustomField['kind']) => {
    switch (customFieldKind) {
      case 'date':
        return 'date';
      case 'datetime':
        return 'date & time';
      default:
        return customFieldKind;
    }
  };

  return (
    <>
      <Dropdown
        className="w-full"
        name="useCurrentDate"
        value={inputType}
        onSelect={(name: string, newValue: string) => {
          setInputType(newValue);

          if (newValue === 'now') {
            onChange(newValue);
          }
        }}
        options={[
          { label: `Current ${formattedKind(kind)}`, value: 'now' },
          { label: `A specific ${formattedKind(kind)}`, value: 'custom' },
        ]}
      />
      {inputType === 'now' ? (
        <div className="text-xs text-gray-500 text-right mt-2">
          Use the date/time at which a subscriber flows through this node.
        </div>
      ) : (
        <MagicInput
          kind={kind}
          className="w-56 mt-2"
          name="value"
          value={value}
          onBlur={(newValue) => {
            onChange(moment(newValue)?.toISOString(true));
          }}
        />
      )}
    </>
  );
};

export default AttributeDateInput;
