import styled from 'styled-components';

import { Textarea } from '../../../components/ui/Textarea';
import { colors } from '../../../lib/colors';

export const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.25rem;

    ${Textarea} {
      margin-bottom: 0.5rem;
    }
  `,
  Header: styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
  `,
  HorizontalButtonWrapper: styled.div<{ isConsumptionExceeded?: boolean }>`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    width: ${({ isConsumptionExceeded }) => (isConsumptionExceeded ? '100%' : 'auto')};
  `,
  PromptFooter: styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    justify-content: space-between;
  `,
  PreviewFooter: styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  `,
  PreviewContainer: styled.div`
    background: #161a1f;
    border: 1px solid #000000;
    border-radius: 4px;
    color: ${colors.white[1]};
    font-size: 0.9375rem;
    height: 14rem;
    margin-bottom: 0.5rem;
    overflow-y: auto;
    padding: 1rem;
  `,
  ConsumptionWrapper: styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  `,
  ConsumptionHint: styled.div`
    color: ${colors.white[2]};
    font-size: 0.75rem;
    font-weight: 500;
  `,
};

export default Styled;
