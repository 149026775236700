import toast from 'react-hot-toast';
import { Editor } from '@tiptap/core';
import { mergeAttributes, ReactNodeViewRenderer } from '@tiptap/react';

import { API } from '../../lib/api';
import { Figure } from '../Figure';

import { ImageBlockView } from './views/ImageBlockView';
import { ImageBlockPlugin } from './ImageBlockPlugin';

const removeImageOnError = (editor: Editor, from: number, to: number, error: string) => {
  const {
    state: { tr },
  } = editor;

  toast.error(error);
  tr.delete(from, to);

  editor.view.dispatch(tr);
};

const replaceExternalImages = (
  editor: Editor,
  nodeName: string,
  publicationId: string,
  storage: { pendingUploads: Array<{ src: string }> },
  clientId?: number
) => {
  const { state } = editor;

  state.doc.descendants((node, pos) => {
    const { id, src, clientId: nodeClientId } = node.attrs as { id: string; src: string; clientId?: string };

    const isSameNodeType = node.type.name === nodeName;
    const isExisiting = id && id.length > 0;
    const clientHasId = !!clientId;
    const nodeHasClientId = !!nodeClientId;
    const isSameClient = !clientHasId || (clientHasId && nodeHasClientId && clientId === parseInt(nodeClientId, 10));

    if (!isSameNodeType) {
      return;
    }

    // If there are more than one user connected, do not proceed if image insertion is not initiated by the same user.
    // This helps deleting "old" images (client id has changed meanwhile).
    const hasActiveCollaborators = editor?.storage.collaborationCursor?.users?.length > 1;

    if (hasActiveCollaborators && !isSameClient) {
      return;
    }

    if (isExisiting) {
      return;
    }

    // If there is already a pending upload for this src, don't upload it again.
    if (storage.pendingUploads.find((item) => item.src === src)) {
      return;
    }

    storage.pendingUploads.push({ src });

    // Upload in case it is a Base64 image.
    if (src.startsWith('data:image')) {
      const fileExt = src?.match(/[^:/]\w+(?=;|,)/)?.[0];
      const mimeType = src?.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)?.[0];

      fetch(src)
        .then((res1) => res1.arrayBuffer())
        .then((buf) => new File([buf], `image.${fileExt}`, { type: mimeType }))
        .then((file) => {
          API.uploadPublicationAsset({
            publicationId,
            file,
          })
            .then((res2) => {
              const { id: newId, url: newSrc } = res2.data;

              if (newId && newSrc) {
                const {
                  state: { tr },
                } = editor;

                tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  src: newSrc,
                  id: newId,
                });

                editor.view.dispatch(tr);
              }
            })
            .catch(() => {
              removeImageOnError(editor, pos, pos + node.nodeSize, 'Uploading of Base64 image failed …');
            })
            .finally(() => {
              // Remove from storage.
              const index = storage.pendingUploads.findIndex((item) => item.src === src);

              if (index > -1) {
                storage.pendingUploads.splice(index, 1);
              }
            });
        });

      return;
    }

    if (!src) {
      return;
    }

    API.uploadPublicationAssetFromUrl({
      publicationId,
      url: src,
    })
      .then((res) => {
        const { id: newId, url: newSrc } = res.data;

        if (newId && newSrc) {
          const { state: currentState } = editor;
          const { tr } = currentState;

          tr.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            src: newSrc,
            id: newId,
          });

          editor.view.dispatch(tr);
        }
      })
      .catch(() => {
        removeImageOnError(editor, pos, pos + node.nodeSize, 'Uploading from external URL failed …');
      })
      .finally(() => {
        // Remove from storage.
        const index = storage.pendingUploads.findIndex((item) => item.src === src);

        if (index > -1) {
          storage.pendingUploads.splice(index, 1);
        }
      });
  });
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageBlock: {
      setImageBlock: (attributes?: { id: string; caption?: string }) => ReturnType;
      setImageBlockAlign: (align: 'left' | 'center' | 'right') => ReturnType;
      setImageBlockCaptionAlign: (align: 'left' | 'center' | 'right') => ReturnType;
      setImageBlockUrl: (url: string) => ReturnType;
      setImageBlockTarget: (target: string) => ReturnType;
      setImageBlockCaptionUrl: (url: string) => ReturnType;
      setImageBlockCaptionTarget: (target: string) => ReturnType;
      setImageBlockWidth: (width: number) => ReturnType;

      // Border width
      setIndividualImageBlockBorderWidth: (value: boolean) => ReturnType;
      toggleIndividualImageBlockBorderWidth: () => ReturnType;
      setImageBlockBorderWidth: (value: number) => ReturnType;
      setImageBlockTopBorderWidth: (value: number) => ReturnType;
      setImageBlockRightBorderWidth: (value: number) => ReturnType;
      setImageBlockBottomBorderWidth: (value: number) => ReturnType;
      setImageBlockLeftBorderWidth: (value: number) => ReturnType;

      // Border radius
      setIndividualImageBlockBorderRadius: (value: boolean) => ReturnType;
      toggleIndividualImageBlockBorderRadius: () => ReturnType;
      setImageBlockBorderRadius: (value: number) => ReturnType;
      setImageBlockTopLeftBorderRadius: (value: number) => ReturnType;
      setImageBlockTopRightBorderRadius: (value: number) => ReturnType;
      setImageBlockBottomRightBorderRadius: (value: number) => ReturnType;
      setImageBlockBottomLeftBorderRadius: (value: number) => ReturnType;

      // Border color
      setImageBlockBorderColor: (color: string | null) => ReturnType;

      // Border style
      setImageBlockBorderStyle: (style: 'solid' | 'dotted' | 'dashed') => ReturnType;
    };
  }
}

export const ImageBlock = Figure.extend<
  { publicationId: string; clientId?: number },
  {
    pendingUploads: Array<{ src: string }>;
  }
>({
  name: 'imageBlock',

  content: 'figcaption',

  defining: true,

  isolating: true,

  addOptions() {
    return {
      publicationId: '',
      clientId: undefined,
    };
  },

  addStorage() {
    return {
      pendingUploads: [],
    };
  },

  onCreate() {
    replaceExternalImages(this.editor, this.name, this.options.publicationId, this.storage, this.options.clientId);
  },

  onUpdate() {
    replaceExternalImages(this.editor, this.name, this.options.publicationId, this.storage, this.options.clientId);
  },

  addAttributes() {
    return {
      id: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({
          'data-id': attributes.id,
        }),
      },
      url: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-url'),
        renderHTML: (attributes) => ({
          'data-url': attributes.url,
        }),
      },
      target: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-target'),
        renderHTML: (attributes) => ({
          'data-target': attributes.target,
        }),
      },
      captionUrl: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-caption-url'),
        renderHTML: (attributes) => ({
          'data-caption-url': attributes.captionUrl,
        }),
      },
      captionTarget: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-caption-target'),
        renderHTML: (attributes) => ({
          'data-caption-target': attributes.captionTarget,
        }),
      },
      src: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-src'),
        renderHTML: (attributes) => ({
          'data-src': attributes.src,
        }),
      },
      title: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-title'),
        renderHTML: (attributes) => ({
          'data-title': attributes.title,
        }),
      },
      alt: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-alt'),
        renderHTML: (attributes) => ({
          'data-alt': attributes.alt,
        }),
      },
      width: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
        }),
      },
      align: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-align'),
        renderHTML: (attributes) => ({
          'data-align': attributes.align,
        }),
      },
      captionAlign: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-caption-align'),
        renderHTML: (attributes) => ({
          'data-caption-align': attributes.captionAlign,
        }),
      },
      clientId: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-client-id'),
        renderHTML: (attributes) => ({
          'data-client-id': attributes.clientId,
        }),
      },

      // Border width
      borderWidthTop: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-border-width-top'),
        renderHTML: (attributes) => ({
          'data-border-width-top': attributes.borderWidthTop,
        }),
      },
      borderWidthRight: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-border-width-right'),
        renderHTML: (attributes) => ({
          'data-border-width-right': attributes.borderWidthRight,
        }),
      },
      borderWidthBottom: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-border-width-bottom'),
        renderHTML: (attributes) => ({
          'data-border-width-bottom': attributes.borderWidthBottom,
        }),
      },
      borderWidthLeft: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-border-width-left'),
        renderHTML: (attributes) => ({
          'data-border-width-left': attributes.borderWidthLeft,
        }),
      },
      useIndividualBorderWidth: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-use-individual-border-width'),
        renderHTML: (attributes) => ({
          'data-use-individual-border-width': attributes.useIndividualBorderWidth,
        }),
      },

      // Border radius
      borderTopLeftRadius: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-border-top-left-radius'),
        renderHTML: (attributes) => ({
          'data-border-top-left-radius': attributes.borderTopLeftRadius,
        }),
      },
      borderTopRightRadius: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-border-top-right-radius'),
        renderHTML: (attributes) => ({
          'data-border-top-right-radius': attributes.borderTopRightRadius,
        }),
      },
      borderBottomRightRadius: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-border-bottom-right-radius'),
        renderHTML: (attributes) => ({
          'data-border-bottom-right-radius': attributes.borderBottomRightRadius,
        }),
      },
      borderBottomLeftRadius: {
        default: 0,
        parseHTML: (element) => element.getAttribute('data-border-bottom-left-radius'),
        renderHTML: (attributes) => ({
          'data-border-bottom-left-radius': attributes.borderBottomLeftRadius,
        }),
      },
      useIndividualBorderRadius: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-use-individual-border-radius'),
        renderHTML: (attributes) => ({
          'data-use-individual-border-radius': attributes.useIndividualBorderRadius,
        }),
      },

      // Border color
      borderColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({
          'data-border-color': attributes.borderColor,
        }),
      },

      // Border style
      borderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({
          'data-border-style': attributes.borderStyle,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `figure[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['figure', mergeAttributes(HTMLAttributes, { 'data-type': this.name }), 0];
  },

  addCommands() {
    return {
      setImageBlock:
        (attrs) =>
        ({ commands }) =>
          commands.insertContent(
            `<figure data-id="${attrs?.id}" data-type="${this.name}"><figcaption>${attrs?.caption}</figcaption></figure>`
          ),

      setImageBlockAlign:
        (align) =>
        ({ commands }) =>
          commands.updateAttributes('imageBlock', { align }),

      setImageBlockCaptionAlign:
        (align) =>
        ({ commands }) =>
          commands.updateAttributes('imageBlock', { captionAlign: align }),

      setImageBlockUrl:
        (url) =>
        ({ commands }) =>
          commands.updateAttributes('imageBlock', { url }),

      setImageBlockTarget:
        (target) =>
        ({ commands }) =>
          commands.updateAttributes('imageBlock', { target }),

      setImageBlockCaptionUrl:
        (url) =>
        ({ commands }) =>
          commands.updateAttributes('imageBlock', { captionUrl: url }),

      setImageBlockCaptionTarget:
        (target) =>
        ({ commands }) =>
          commands.updateAttributes('imageBlock', { captionTarget: target }),

      setImageBlockWidth:
        (width) =>
        ({ commands }) =>
          commands.updateAttributes('imageBlock', { width: `${Math.max(0, Math.min(100, width))}%` }),

      // Border width
      setIndividualImageBlockBorderWidth:
        (value: boolean) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { useIndividualBorderWidth: value }),
      toggleIndividualImageBlockBorderWidth:
        () =>
        ({ editor, chain }: any) => {
          const { useIndividualBorderWidth, borderWidthTop } = editor.getAttributes(this.name);
          const newUseIndividualBorderWidth = !useIndividualBorderWidth;

          if (newUseIndividualBorderWidth === false) {
            chain().updateAttributes(this.name, {
              borderWidthTop,
              borderWidthRight: borderWidthTop,
              borderWidthBottom: borderWidthTop,
              borderWidthLeft: borderWidthTop,
            });
          }

          return chain().updateAttributes(this.name, { useIndividualBorderWidth: newUseIndividualBorderWidth }).run;
        },
      setImageBlockBorderWidth:
        (value: number) =>
        ({ chain }: any) =>
          chain()
            .setImageBlockTopBorderWidth(value)
            .setImageBlockRightBorderWidth(value)
            .setImageBlockBottomBorderWidth(value)
            .setImageBlockLeftBorderWidth(value)
            .run(),
      setImageBlockTopBorderWidth:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderWidthTop: value }),
      setImageBlockRightBorderWidth:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderWidthRight: value }),
      setImageBlockBottomBorderWidth:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderWidthBottom: value }),
      setImageBlockLeftBorderWidth:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderWidthLeft: value }),

      // Border radius
      setIndividualImageBlockBorderRadius:
        (value: boolean) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { useIndividualBorderRadius: value }),
      toggleIndividualImageBlockBorderRadius:
        () =>
        ({ editor, chain }: any) => {
          const { useIndividualBorderRadius, borderTopLeftRadius } = editor.getAttributes(this.name);
          const newUseIndividualBorderRadius = !useIndividualBorderRadius;

          if (newUseIndividualBorderRadius === false) {
            chain().updateAttributes(this.name, {
              borderTopLeftRadius,
              borderTopRightRadius: borderTopLeftRadius,
              borderBottomRightRadius: borderTopLeftRadius,
              borderBottomLeftRadius: borderTopLeftRadius,
            });
          }

          return chain().updateAttributes(this.name, { useIndividualBorderRadius: newUseIndividualBorderRadius }).run;
        },
      setImageBlockBorderRadius:
        (value: number) =>
        ({ chain }: any) =>
          chain()
            .setImageBlockTopLeftBorderRadius(value)
            .setImageBlockTopRightBorderRadius(value)
            .setImageBlockBottomRightBorderRadius(value)
            .setImageBlockBottomLeftBorderRadius(value)
            .run(),
      setImageBlockTopLeftBorderRadius:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderTopLeftRadius: value }),
      setImageBlockTopRightBorderRadius:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderTopRightRadius: value }),
      setImageBlockBottomRightBorderRadius:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderBottomRightRadius: value }),
      setImageBlockBottomLeftBorderRadius:
        (value: number) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderBottomLeftRadius: value }),

      // Border color
      setImageBlockBorderColor:
        (color: string | null) =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderColor: color }),

      // Border style
      setImageBlockBorderStyle:
        (style: 'solid' | 'dotted' | 'dashed') =>
        ({ commands }: any) =>
          commands.updateAttributes(this.name, { borderStyle: style }),
    };
  },

  addProseMirrorPlugins() {
    return [ImageBlockPlugin({ publicationId: this.options.publicationId, clientId: this.options.clientId })];
  },

  // TODO: Use the following approach once Tiptap core supports it
  // addNodeView() {
  //   return (props) => {
  //     const { node } = props;

  //     return ReactNodeViewRenderer(ImageBlockView, {
  //       attrs: {
  //         ...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {}),
  //       },
  //     })(props);
  //   };
  // },

  addNodeView() {
    return ReactNodeViewRenderer(ImageBlockView);
  },
});

export default ImageBlock;
