/* eslint-disable consistent-return */
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TCollabThread } from '@hocuspocus/provider';
import Tippy from '@tippyjs/react';
import { Editor, getMarkRange, getMarkType, posToDOMRect } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import { Transaction } from '@tiptap/pm/state';
import { Instance, Props, sticky } from 'tippy.js';
import { v4 } from 'uuid';

import useDebouncedValue from '@/hooks/useDebouncedValue';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';
import { cn } from '@/utils/cn';

import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { ThreadCard } from '../components';

import 'tippy.js/animations/shift-toward-subtle.css';

interface IThreadButton {
  id: string;
  node: Node;
  pos: number;
  threads: Array<TCollabThread['id']>;
}

interface IInlineCommentsProps {
  editor: Editor;
  threadsToShow: TCollabThread[];
  activeThreadId?: string | null;
}

export const InlineComments: FC<IInlineCommentsProps> = ({ editor, threadsToShow, activeThreadId }) => {
  const { highlightThread, removeHighlightThread } = useEditorContext();

  const threadButtonsPosMapRef = useRef<Record<string, number>>({});

  const blockThreadIdsRef = useRef<string[]>([]);

  const threadIdTextContentMap = editor.storage.commentsKit.threadIdTextContentMap || {};

  const calculatedInlineThreadButtons = useMemo(() => {
    threadButtonsPosMapRef.current = {};
    blockThreadIdsRef.current = [];

    const threadIdsToShow = threadsToShow.map((thread) => thread.id);

    const blockCommentButtons: Record<IThreadButton['id'], IThreadButton> = {};

    editor.state.doc.descendants((node, pos, parent, index) => {
      const threadButtons: IThreadButton = {
        id: node.attrs.id || v4(),
        node,
        pos,
        threads: [],
      };

      if (index === 0 && parent?.type.name === 'blockThread' && parent.attrs['data-thread-id']) {
        const parentThreadId = parent.attrs['data-thread-id'];

        if (parentThreadId && threadIdsToShow.includes(parentThreadId)) {
          blockThreadIdsRef.current.push(parentThreadId);

          threadButtons.threads.push(parentThreadId);
        }
      }

      if (node.isTextblock) {
        node.descendants((textNode) => {
          const commentMarks = textNode.marks.filter((mark) => mark.type.name === 'inlineThread');

          commentMarks.forEach((mark) => {
            if (threadButtons.threads[mark.attrs['data-thread-id']]) {
              return;
            }

            if (threadIdsToShow.includes(mark.attrs['data-thread-id'])) {
              threadButtons.threads.push(mark.attrs['data-thread-id']);
            }
          });
        });
      }

      if (Object.keys(threadButtons.threads).length > 0) {
        blockCommentButtons[threadButtons.id] = threadButtons;
      }

      if (node.isTextblock) {
        return false;
      }
    });

    threadButtonsPosMapRef.current = Object.keys(blockCommentButtons).reduce((acc, threadButton) => {
      return {
        ...acc,
        [threadButton]: blockCommentButtons[threadButton].pos,
      };
    }, {});

    return blockCommentButtons;
  }, [editor, threadsToShow]);

  useEffect(() => {
    const onTransaction = ({ transaction }: { transaction: Transaction }) => {
      threadButtonsPosMapRef.current = Object.keys(calculatedInlineThreadButtons).reduce((acc, threadButtonId) => {
        return {
          ...acc,
          [threadButtonId]: transaction.mapping.map(calculatedInlineThreadButtons[threadButtonId].pos),
        };
      }, {});
    };

    editor.on('transaction', onTransaction);

    return () => {
      editor.off('transaction', onTransaction);
    };
  }, [editor, calculatedInlineThreadButtons]);

  const getThreadButtonRect = useCallback(
    (threadId: string) => {
      const domNode = editor.view.dom.querySelector(`[data-id="${threadId}"]`);

      if (domNode) {
        return domNode.getBoundingClientRect();
      }

      const pos = threadButtonsPosMapRef.current[threadId];

      if (!pos) {
        return new DOMRect(0, 0, 0, 0);
      }

      const node = editor.view.domAtPos(pos + 1).node as HTMLElement;

      if (!node) {
        return new DOMRect(0, 0, 0, 0);
      }

      if (node.tagName && node.tagName.toLowerCase() !== 'text') {
        return node.getBoundingClientRect();
      }

      return new DOMRect(0, 0, 0, 0);
    },
    [editor]
  );

  const [openThreadButtonId, setOpenThreadButtonId] = useState<string | null>(null);

  useEffect(() => {
    const resetOpenThreadButtonId = () => setOpenThreadButtonId(null);

    editor.on('focus', resetOpenThreadButtonId);

    return () => {
      editor.off('focus', resetOpenThreadButtonId);
    };
  }, [editor]);

  const activeThreadTippyInstanceRef = useRef<Instance<Props>>();

  const activeThread = useMemo(() => {
    return threadsToShow.find((thread) => thread.id === activeThreadId);
  }, [activeThreadId, threadsToShow]);

  const debouncedActiveThread = useDebouncedValue(activeThread, 100);

  const getThreadRect = useCallback(
    (threadId: string) => {
      const isBlockThread = blockThreadIdsRef.current.includes(threadId);

      if (isBlockThread) {
        const blockThreadNode = editor.view.dom.querySelector(`[data-id="${threadId}"]`);

        if (blockThreadNode) {
          return blockThreadNode.getBoundingClientRect();
        }

        return null;
      }

      const { $from, $to } = editor.state.selection;

      const range =
        getMarkRange($to, getMarkType('inlineThread', editor.schema), {
          'data-thread-id': threadId,
        }) ||
        getMarkRange($from, getMarkType('inlineThread', editor.schema), {
          'data-thread-id': threadId,
        });

      if (range) {
        return posToDOMRect(editor.view, range.from + 1, range.to);
      }

      return null;
    },
    [editor]
  );

  useEffect(() => {
    const tippy = activeThreadTippyInstanceRef.current;

    if (!debouncedActiveThread || !tippy) return;

    const threadRect = getThreadRect(debouncedActiveThread.id);

    if (threadRect) {
      tippy?.setProps({
        getReferenceClientRect: () => threadRect,
      });
      tippy?.show();
    } else {
      tippy?.hide();
    }
  }, [debouncedActiveThread, getThreadRect]);

  return (
    <>
      {Object.entries(calculatedInlineThreadButtons).map(([threadButtonId, threadButton]) => (
        <Tippy
          key={threadButtonId}
          getReferenceClientRect={() => getThreadButtonRect(threadButtonId)}
          appendTo={editor.options.element}
          interactive
          placement="right-start"
          visible
          offset={[0, 40]}
          plugins={[sticky]}
          content={
            <Button
              $leftSlot={
                <div className="flex gap-1 items-center justify-center">
                  <div className="relative w-4 h-4">
                    <Icon name="Comment" />
                  </div>
                  {threadButton.threads.length}
                </div>
              }
              $isIconButton
              $isToggleButton
              $variant="tertiary"
              $size="small"
              $active={openThreadButtonId === threadButtonId}
              onClick={() => setOpenThreadButtonId(openThreadButtonId === threadButtonId ? null : threadButtonId)}
            />
          }
        />
      ))}

      {openThreadButtonId && calculatedInlineThreadButtons[openThreadButtonId] && (
        <Tippy
          getReferenceClientRect={() => getThreadButtonRect(openThreadButtonId)}
          appendTo={document.body}
          interactive
          placement="bottom"
          visible
          offset={[0, 4]}
          plugins={[sticky]}
          animation="shift-toward-subtle"
          maxWidth="none"
          content={
            <div className="flex flex-col bg-white shadow w-110 rounded-lg max-h-[50vh] overflow-y-auto">
              {calculatedInlineThreadButtons[openThreadButtonId].threads.map((threadId) => {
                const thread = threadsToShow.find((t) => t.id === threadId);

                if (!thread) {
                  return null;
                }

                return (
                  <div className="w-full border-b border-b-gray-300 last:border-none p-4">
                    <ThreadCard
                      key={threadId}
                      thread={thread}
                      onClick={() => {}}
                      onMouseEnter={() => {}}
                      onMouseLeave={() => {}}
                      inPopoverList
                      active
                      referenceText={threadIdTextContentMap[threadId]}
                    />
                  </div>
                );
              })}
            </div>
          }
        />
      )}

      <Tippy
        interactive
        appendTo={document.body}
        placement="bottom"
        trigger="manual"
        offset={[0, 8]}
        plugins={[sticky]}
        animation="shift-toward-subtle"
        onCreate={(instance) => {
          activeThreadTippyInstanceRef.current = instance;
        }}
        moveTransition="transform 0.1s ease-in-out"
        hideOnClick={false}
        content={
          <div className={cn('w-80 md:w-96', !!openThreadButtonId && 'hidden')}>
            {debouncedActiveThread?.id && (
              <ThreadCard
                thread={debouncedActiveThread}
                onClick={() => {}}
                onMouseEnter={highlightThread}
                onMouseLeave={removeHighlightThread}
                active
                referenceText={threadIdTextContentMap[debouncedActiveThread.id]}
              />
            )}
          </div>
        }
      />
    </>
  );
};
