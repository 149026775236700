import { LogicalOperators } from '@/interfaces/condition';
import { Option } from '@/interfaces/general';

export const andOperatorOption = {
  label: 'All (AND)',
  value: LogicalOperators.AND,
};

export const orOperatorOption = {
  label: 'At least one (OR)',
  value: LogicalOperators.OR,
};

const noneOperatorOption = {
  label: 'None',
  value: LogicalOperators.NONE,
};

export const logicalOperatorOptions: Option[] = [andOperatorOption, orOperatorOption, noneOperatorOption];

export const colorByLogicalOperator: {
  [key: string]: any;
} = {
  [LogicalOperators.AND]: {
    tree: 'bg-sky-200',
    treeHeader: 'border-sky-200',
    treeFooter: 'border-sky-200',
    focusRing: 'focus:ring-sky-100',
    focusBorder: 'focus:border-sky-100',
    background: 'bg-sky-50',
    hoverBackground: 'hover:bg-sky-100',
    border: 'border-sky-100',
  },
  [LogicalOperators.OR]: {
    tree: 'bg-purple-200',
    treeHeader: 'border-purple-200',
    treeFooter: 'border-purple-200',
    focusRing: 'focus:ring-purple-100',
    focusBorder: 'focus:border-purple-100',
    background: 'bg-purple-50',
    hoverBackground: 'hover:bg-purple-100',
    border: 'border-purple-100',
  },
  [LogicalOperators.NONE]: {
    tree: 'bg-rose-200',
    treeHeader: 'border-rose-200',
    treeFooter: 'border-rose-200',
    focusRing: 'focus:ring-rose-100',
    focusBorder: 'focus:border-rose-100',
    background: 'bg-rose-50',
    hoverBackground: 'hover:bg-rose-100',
    border: 'border-rose-100',
  },
};
