import { useEffect, useMemo, useState } from 'react';

import { useMailchimpFolder } from '@/hooks/useIntegrations';
import useIntegrations from '@/hooks/useIntegrations/useIntegrations';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { ContentImport } from '@/interfaces/content_import';
import { Option } from '@/interfaces/general';
import { IntegrationProvider } from '@/interfaces/integration';

import { MailchimpImportOptions } from '../new/types';

interface Props {
  contentImport: Partial<ContentImport>;
  updateContentImport: (newContentImport: Partial<ContentImport>) => void;
}

const useMailchimpConnection = ({ contentImport, updateContentImport }: Props) => {
  const { integration_id: integrationId, mailchimp_folders: mailchimpFolders } = contentImport;
  const [importTypeSelection, setImportTypeSelection] = useState<string>(MailchimpImportOptions[0].value);

  const publicationId = useCurrentPublicationId();
  const { data, refetch: refetchIntegrations } = useIntegrations({
    publicationId,
    provider: IntegrationProvider.MAILCHIMP,
  });
  const integrations = useMemo(() => data?.pages.flatMap((page) => page.integrations) || [], [data]);
  const { data: mailchimpFolder } = useMailchimpFolder(publicationId, integrationId);
  const folders = mailchimpFolder?.folders || [];

  const isConnected = integrations.length > 0;
  const hasFolders = folders.length > 0;
  const isCustomizeByFolderSelected = importTypeSelection === MailchimpImportOptions[1].value;

  const options: Option[] = integrations.map((integration) => ({
    label: `Account #${integration.uid}`,
    value: integration.id,
  }));

  const handleSelectIntegration = (value: string) => {
    updateContentImport({
      integration_id: value,
      integration_provider: IntegrationProvider.MAILCHIMP,
    });
  };

  const handleCheckedFolderId = (folderId: string, state: boolean) => {
    if (state) {
      updateContentImport({
        mailchimp_folders: [...(mailchimpFolders || []), folderId],
      });
    } else {
      updateContentImport({
        mailchimp_folders: (mailchimpFolders || []).filter((id) => id !== folderId),
      });
    }
  };

  useEffect(() => {
    if (integrations.length > 0 && !integrationId) {
      updateContentImport({
        integration_id: integrations[0].id,
        integration_provider: IntegrationProvider.MAILCHIMP,
      });
    }
  }, [integrations, integrationId, updateContentImport]);

  return {
    importTypeSelection,
    setImportTypeSelection,
    isConnected,
    integrations,
    refetchIntegrations,
    folders,
    hasFolders,
    options,
    isCustomizeByFolderSelected,
    handleSelectIntegration,
    handleCheckedFolderId,
  };
};

export default useMailchimpConnection;
