import { useParams } from 'react-router-dom';

import { useAdvertisement, useAdvertisementDestroy } from '@/hooks/useAdNetwork/internal';
import useAdvertisementPreview from '@/hooks/useAdNetwork/internal/useAdvertisementPreview';
import { AdNetworkAdvertisement, AdNetworkAdvertisementPreview } from '@/interfaces/ad_network/internal/advertisement';
import { Button } from '@/ui/Button';

import { PageHeading } from '../_components/PageHeading';
import useConfirmDelete from '../_hooks/useConfirmDelete';

interface Props {
  advertisement: AdNetworkAdvertisement;
  preview: AdNetworkAdvertisementPreview;
}

const AdvertisementShow = ({ advertisement, preview }: Props) => {
  const deleteMutation = useAdvertisementDestroy({ advertisement });
  const { ConfirmDeleteModal, modalProps, handleDelete } = useConfirmDelete({
    deleteMutation,
    warning: 'Once you delete an advertisement, there is no going back. Please be certain.',
    confirmWith: advertisement.name,
  });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to={`/ad_network/campaigns/${advertisement.campaign_id}/advertisements`}>
              Advertisements
            </PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">{advertisement.name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button onClick={() => handleDelete()} variant="primary-inverse" size="xs">
            Delete
          </Button>
          <Button to="edit" variant="primary-inverse" size="xs">
            Edit
          </Button>
        </PageHeading.Side>
      </PageHeading>
      <iframe
        title="preview"
        className="w-full h-full rounded overflow-hidden"
        srcDoc={preview.html}
        style={{
          minHeight: '2000px',
        }}
      />

      <ConfirmDeleteModal {...modalProps} />
    </>
  );
};

export default function Loader() {
  const { advertisement_id: id } = useParams<'advertisement_id'>() as { advertisement_id: string };
  const { data: advertisement, isSuccess: advertisementIsSuccess } = useAdvertisement({ id });
  const { data: preview, isSuccess: previewIsSuccess } = useAdvertisementPreview({ id });

  if (!advertisementIsSuccess || !previewIsSuccess) return null;

  return <AdvertisementShow advertisement={advertisement} preview={preview} />;
}
