import { FC, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';

// Components
import { Input } from '../../../../components/Form';
// Services
import api from '../../../../services/swarm';

interface Props {
  onClose: () => void;
  onSuccess: (promoCodeId: string) => void;
  publicationId: string;
  rewardId: string;
}

const NewPromoCode: FC<Props> = (props: Props) => {
  const { publicationId, rewardId, onClose, onSuccess } = props;
  const [promoCode, setPromoCode] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = e;

    setPromoCode(value);
  };

  const resetForm = () => {
    setPromoCode('');
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const payload = {
      code: promoCode,
      publication_id: publicationId,
    };

    setIsSaving(true);
    api
      .post(`/referral_program/rewards/${rewardId}/promo_codes`, payload)
      .then((res) => {
        const createdId: string = res.data?.id;
        toast.success('Promo Code created successfully');
        onSuccess(createdId);
        resetForm();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  const isDisabled = () => {
    if (promoCode !== '') {
      return false;
    }

    return true;
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      <Input name="code" labelText="Promo Code" type="text" value={promoCode} onChange={handleChange} required />
      <div className="flex justify-end">
        <Button type="button" className="mx-2" variant="primary-inverse" block={false} onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" block={false} loading={isSaving} disabled={isDisabled()}>
          Create
        </Button>
      </div>
    </form>
  );
};

export default NewPromoCode;
