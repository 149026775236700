import { useNavigate } from 'react-router-dom';
import {
  ChartBarIcon,
  ClipboardDocumentListIcon,
  PresentationChartBarIcon,
  UserGroupIcon,
} from '@heroicons/react/20/solid';

import {
  AccordionCard,
  AccordionIconWrapper,
  AccordionListItem,
} from '../../../components/Accordion/variants/AccordionCard';

const AnalyzeListActions = () => {
  const navigate = useNavigate();

  return (
    <AccordionCard
      title={
        <div className="flex items-center">
          <AccordionIconWrapper>
            <ChartBarIcon className="w-4 h-4 text-feedback-info-500" />
          </AccordionIconWrapper>
          Analyze your list
        </div>
      }
      subText="Get a better understanding of your audience with our powerful analytic tools."
    >
      <div className="space-y-1">
        <AccordionListItem
          title="Build a segment"
          icon={<UserGroupIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/segments')}
        />
        <AccordionListItem
          title="Collect data"
          icon={<ClipboardDocumentListIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/forms')}
        />
        <AccordionListItem
          title="Get advanced insights"
          icon={<PresentationChartBarIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/reports/subscriber')}
        />
      </div>
    </AccordionCard>
  );
};

export default AnalyzeListActions;
