import { memo } from 'react';
import { Editor } from '@tiptap/react';

import { EditLink } from '../menus/Link';

type EditLinkMenuProps = {
  editor: Editor;
  onSetLink: (url: string) => void;
  onUnsetLink: () => null;
  onSetTarget: (target: string) => void;
  onBack: () => void;
};

export const EditLinkMenu = memo(({ onBack, onSetLink, onUnsetLink, onSetTarget, editor }: EditLinkMenuProps) => {
  return (
    <EditLink
      editor={editor}
      onSetLink={onSetLink}
      onUnsetLink={onUnsetLink}
      onSetTarget={onSetTarget}
      onBack={onBack}
    />
  );
});

EditLinkMenu.displayName = 'EditLinkMenu';
