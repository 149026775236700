import { useQuery } from 'react-query';

import { EmailMessageStats } from '../interfaces/email_message';
import api from '../services/swarm';

const useEmailMessageStats = (publicationId: string, emailMessageId: string | undefined) => {
  return useQuery<EmailMessageStats, Error>(
    ['email_messages', 'stats', emailMessageId],
    () =>
      api
        .get(`email_messages/${emailMessageId}/stats`, { params: { publication_id: publicationId } })
        .then((res) => res.data),
    {
      enabled: Boolean(emailMessageId),
      staleTime: 30000,
      keepPreviousData: true,
    }
  );
};

export default useEmailMessageStats;
