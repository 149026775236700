import { ConditionLabel, ConditionOptions, needsConditionInput } from '@/components/ConditionsEditor/';

import { Condition, Filter, Refine } from '../../../interfaces/segment';

import AutomationActionSelect from './AutomationActionSelect';
import BehaviorTypeSelect from './BehaviorTypeSelect';
import ConditionInput from './ConditionInput';
import ConditionNameSelect from './ConditionNameSelect';
import ConditionOperatorSelect from './ConditionOperatorSelect';
import ConditionRefine from './ConditionRefine';
import CustomFieldInput from './CustomFieldInput';
import EmailTypeSelect from './EmailTypeSelect';
import {
  automationOption,
  customFieldAttributeOption,
  referralCountBehaviorOption,
  surveyResponseBehaviorOption,
} from './nameOptions';
import { isEmailClick } from './operatorOptions';
import { NameTypes } from './types';

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleSelectWhichName: (value: string, blockIndex: number, subIndex?: number) => void;
  handleFilterChange: (filters: Filter, blockIndex: number, subIndex?: number) => void;
  handleRefineChange: (refine: Refine, blockIndex: number, subIndex?: number) => void;
  handleDuplicate: (blockIndex: number, subIndex?: number) => void;
  handleDelete: (blockIndex: number, subIndex?: number) => void;
  handleDeleteRefine: (blockIndex: number, subIndex?: number) => void;
}

const isRefineable = (condition: Condition) =>
  condition.type === NameTypes.MEASURE ||
  (condition.type === NameTypes.BEHAVIOR_ACTION && condition.name !== surveyResponseBehaviorOption.value) ||
  condition.type === NameTypes.AUTOMATION_ACTION ||
  isEmailClick(condition);

const hasRefine = (condition: Condition) => {
  if (condition?.refine !== undefined) {
    return true;
  }

  return false;
};

const ConditionBlock = ({
  condition,
  index,
  subIndex,
  handleSelectWhichName,
  handleFilterChange,
  handleDuplicate,
  handleDelete,
  handleRefineChange,
  handleDeleteRefine,
}: Props) => {
  return (
    <div className="group">
      <div className="flex flex-col">
        <div className="flex flex-col flex-grow">
          <ConditionLabel conditionType={condition.type} conditionName={condition.name} />
          <div className="flex">
            <div className="flex flex-grow flex-row gap-x-2">
              {condition.type !== NameTypes.EMAIL_ACTION &&
                condition.type !== NameTypes.BEHAVIOR_ACTION &&
                condition.type !== NameTypes.AUTOMATION_ACTION &&
                condition.name !== referralCountBehaviorOption.value && (
                  <ConditionNameSelect
                    condition={condition}
                    index={index}
                    subIndex={subIndex}
                    handleSelectWhichName={handleSelectWhichName}
                  />
                )}
              {condition.type === NameTypes.EMAIL_ACTION && (
                <EmailTypeSelect
                  condition={condition}
                  index={index}
                  subIndex={subIndex}
                  handleFilterChange={handleFilterChange}
                />
              )}
              {condition.type === NameTypes.BEHAVIOR_ACTION && (
                <BehaviorTypeSelect
                  condition={condition}
                  index={index}
                  subIndex={subIndex}
                  handleFilterChange={handleFilterChange}
                />
              )}
              {condition.type === NameTypes.AUTOMATION_ACTION && condition.name === automationOption.value && (
                <AutomationActionSelect
                  condition={condition}
                  index={index}
                  subIndex={subIndex}
                  handleFilterChange={handleFilterChange}
                />
              )}
              {condition.type === NameTypes.ATTRIBUTE && condition.name === customFieldAttributeOption.value && (
                <CustomFieldInput
                  condition={condition}
                  index={index}
                  subIndex={subIndex}
                  handleFilterChange={handleFilterChange}
                />
              )}
              <ConditionOperatorSelect
                condition={condition}
                index={index}
                subIndex={subIndex}
                handleFilterChange={handleFilterChange}
              />
              {condition.name !== surveyResponseBehaviorOption.value &&
                needsConditionInput(condition?.filters?.operator) && (
                  <ConditionInput
                    condition={condition}
                    index={index}
                    subIndex={subIndex}
                    handleFilterChange={handleFilterChange}
                  />
                )}
            </div>
            <div className="flex opacity-0 group-hover:opacity-100 align-middle items-center space-x-2 px-1">
              <ConditionOptions
                canRefine={isRefineable(condition)}
                disableRefine={hasRefine(condition)}
                handleRefineChange={() => handleRefineChange({}, index, subIndex)}
                handleDelete={() => handleDelete(index, subIndex)}
                handleDuplicate={() => handleDuplicate(index, subIndex)}
              />
            </div>
          </div>
        </div>

        {isRefineable(condition) && hasRefine(condition) && (
          <div className="mt-2">
            <ConditionRefine
              condition={condition}
              index={index}
              subIndex={subIndex}
              handleRefineChange={handleRefineChange}
              handleDeleteRefine={handleDeleteRefine}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConditionBlock;
