import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFonts, getUsedFonts, getUsedFontsFromNode, getUsedFontsURLs } from '@shared/dream-components';
import { useEditorState } from '@tiptap/react';

import { useDreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext } from '@/context/website-context';

export const useEditorFontHrefs = () => {
  const { site, previewSiteVersion } = useWebsiteContext();
  const { editor, previewContent } = useDreamEditorContext();

  const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());

  const navbarContent = previewSiteVersion ? previewSiteVersion?.navbar : site?.draft_site_version?.navbar;
  const footerContent = previewSiteVersion ? previewSiteVersion?.footer : site?.draft_site_version?.footer;

  const [allFonts, setAllFonts] = useState<any>(null);

  useEffect(() => {
    if (!allFonts) {
      getFonts().then((f: any) => setAllFonts(f));
    }
  });

  const inPageFontFamilies = useEditorState({
    editor,
    selector: ({ editor: e }) => (e ? getUsedFontsFromNode(e?.getJSON()) : undefined),
  });

  const inPageFontURLs = useMemo(() => {
    if (!inPageFontFamilies || !allFonts) return undefined;
    return getUsedFontsURLs(inPageFontFamilies, allFonts);
  }, [inPageFontFamilies, allFonts]);

  useEffect(() => {
    if (inPageFontURLs) {
      setFontHrefs((fonts) => new Set(Array.from(fonts).concat(inPageFontURLs)));
    }
  }, [inPageFontURLs]);

  const getNavbarFontLinks = useCallback(async () => {
    if (!navbarContent) return;
    const renderableFonts = await getUsedFonts(navbarContent);
    setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
  }, [navbarContent]);

  const getFooterFontLinks = useCallback(async () => {
    if (!footerContent) return;
    const renderableFonts = await getUsedFonts(footerContent);
    setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
  }, [footerContent]);

  const getContentFontLinks = useCallback(async () => {
    if (!previewContent) return;
    const renderableFonts = await getUsedFonts(previewContent);
    setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
  }, [previewContent]);

  useEffect(() => {
    getNavbarFontLinks();
  }, [navbarContent, getNavbarFontLinks]);

  useEffect(() => {
    getFooterFontLinks();
  }, [footerContent, getFooterFontLinks]);

  useEffect(() => {
    if (!previewContent) return;
    getContentFontLinks();
  }, [previewContent, getContentFontLinks]);

  return fontHrefs;
};
