import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { IData } from '@/interfaces/general';
import { StripeMigration } from '@/interfaces/stripe';
import api from '@/services/swarm';

interface Props {
  id?: string;
  onSuccess: (data: StripeMigration) => void;
}

interface UpdateParams {
  api_key?: string;
  previous_account_id?: string;
  product_mapping?: IData;
}

const useUpdateStripeMigration = ({ id, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<StripeMigration, unknown, UpdateParams>(
    (params) =>
      api
        .patch(`/stripe_migrations/${id}`, { publication_id: currentPublicationId, stripe_migration: params })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useUpdateStripeMigration;
