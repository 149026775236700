import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

interface Props {
  commentBanId: string;
  onSuccess: (response: any) => void;
}

const useUpdateComment = ({ onSuccess, commentBanId }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (comment_ban: any) =>
      api.patch(`/comment_bans/${commentBanId}`, {
        publication_id: currentPublication?.id,
        comment_ban,
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Oops there was an issue updating this comment.');
      },
    }
  );
};

export default useUpdateComment;
