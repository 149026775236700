import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { RadioSelect } from '@/components/Form';
import SlideOver from '@/components/SlideOver';
import { useUser } from '@/hooks';
import { useDeleteAssignment, useUpdateAssignment } from '@/hooks/useAssignments';
import { RoleOption } from '@/interfaces/organization';
import { Button } from '@/ui/Button';
import { capitalize } from '@/utils';
import analytics from '@/utils/analytics';

import makeUniqueRoleValue from '../makeUniqueRoleValue';
import { CurrentRoleOption } from '../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  name?: string | null;
  email?: string | undefined;
  roleOptions: RoleOption[];
  currentRoleOption: CurrentRoleOption;
  imageUrl: string;
  publicationId: string;
  assignmentId: number;
  assignmentUserId: string | undefined;
}

// TODO: Remove this component in favor of EditModal
const EditMember = ({
  isOpen,
  onClose,
  name,
  email,
  roleOptions,
  currentRoleOption,
  imageUrl,
  publicationId,
  assignmentId,
  assignmentUserId,
}: Props) => {
  const queryClient = useQueryClient();
  const [isDeletingMember, setIsDeletingMember] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<CurrentRoleOption>(currentRoleOption);

  const id = isOpen ? assignmentUserId : null;
  const { data: user } = useUser(publicationId, id as string);

  const assignmentMutation = useUpdateAssignment(publicationId, assignmentId, user?.id || null);
  const assignmentDelete = useDeleteAssignment(publicationId, assignmentId, user?.id || null);

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await assignmentMutation.mutateAsync({
        assignment: {
          assignable_id: selectedOption.object_id,
          assignable_type: selectedOption.object_type,
        },
        role_id: selectedOption.role_id,
      });
      analytics.track('Edited Team Member Role');
      queryClient.invalidateQueries(['publication-team', publicationId]);
      onClose();
    } catch (err) {
      toast.error('There was an error updating the member role.');
    }
  };

  const handleSelectRoleOption = (value: string) => {
    const selectedRole = roleOptions.find(
      (option) => makeUniqueRoleValue(option.object_type, option.role_id) === value
    );

    if (selectedRole) {
      setSelectedOption({
        role_id: selectedRole.role_id,
        object_id: selectedRole.object_id,
        object_type: selectedRole.object_type,
        name: selectedRole.name,
      });
    }
  };

  return (
    <>
      <ActionModal
        isOpen={isDeletingMember}
        onClose={() => setIsDeletingMember(false)}
        onProceed={async () => {
          analytics.track('Account Removed User', {
            role_id: currentRoleOption.role_id || null,
          });
          await assignmentDelete.mutateAsync();
          queryClient.invalidateQueries(['publication-team', publicationId]);
          setIsDeletingMember(false);
          onClose();
        }}
        resourceId={`${currentRoleOption.role_id}-${currentRoleOption.object_id}-${currentRoleOption.object_type}`}
        isWorking={assignmentDelete.isLoading}
        headerText="Remove Member"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this team member. They will be entirely removed from your workspace. This action
        cannot be undone.
      </ActionModal>
      <SlideOver
        bodyId={(assignmentId || '').toString()}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerText="Edit Team Member"
      >
        <form onSubmit={handleUpdate}>
          <div className="flex h-full flex-col overflow-y-scroll bg-white">
            <div className="flex space-x-4">
              <div className="min-h-16 min-w-16 w-16 h-16 rounded-full overflow-hidden bg-gray-100 overflow-hidden">
                <img className="h-16 w-16 object-cover" src={imageUrl} alt="" />
              </div>
              <div className="max-w-md">
                <div className="flex space-x-3">
                  <div className="space-y-0.5">
                    <div className="flex space-x-3">
                      <h3 className="block text-xl font-semibold text-gray-700">{name}</h3>
                      <div className="pt-1">
                        {user?.twitter_handle && (
                          <a href={`https://twitter.com/${user.twitter_handle}`} className="pt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                            </svg>
                          </a>
                        )}
                      </div>
                    </div>
                    <p className="block text-sm font-medium text-gray-400">{email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-8">
              <div className="flex justify-between space-x-4">
                {roleOptions && (
                  <RadioSelect
                    labelText="Select Permission Level"
                    value={makeUniqueRoleValue(selectedOption.object_type, selectedOption.role_id)}
                    onSelect={handleSelectRoleOption}
                    options={roleOptions.map((option: RoleOption) => ({
                      name: capitalize(option.name),
                      value: makeUniqueRoleValue(option.object_type, option.role_id),
                      description: option.description,
                    }))}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end space-x-4 pt-4">
            <Button type="button" variant="danger" onClick={() => setIsDeletingMember(true)}>
              End Assignment
            </Button>
            <Button type="submit" variant="primary" onClick={() => {}} loading={assignmentMutation.isLoading}>
              {assignmentMutation.isLoading ? 'Updating...' : 'Update'}
            </Button>
          </div>
        </form>
      </SlideOver>
    </>
  );
};

export default EditMember;
