import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Pagination } from '../interfaces/general';
import { PostTemplate } from '../interfaces/post_template';
import api from '../services/swarm';

interface ApiResponse {
  post_templates: PostTemplate[];
  pagination: Pagination;
}

export default function usePostTemplates() {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostTemplates = ({ pageParam = 1 }) =>
    api
      .get(`/post_templates`, {
        params: {
          page: pageParam,
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['publications', currentPublicationId, 'post_templates'], fetchPostTemplates, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
