import { useQuery } from 'react-query';

import { WebhookMessageAttempt } from '@/interfaces/webhooks/message_attempt';
import api from '@/services/swarm';

export default function useSvixMessageAttempt(publicationId: string, endpointId: string, messageAttemptId: string, messageId: string) {
  return useQuery<WebhookMessageAttempt>(
    ['publications', publicationId, 'webhooks', 'message_attempts', messageAttemptId],
    () =>
      api
        .get(`/webhooks/endpoints/${endpointId}/svix_messages/${messageId}`, {
          params: { publication_id: publicationId, message_attempt_id: messageAttemptId },
        })
        .then((res) => res.data),
    {
      staleTime: 10000,
    }
  );
}
