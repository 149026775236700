import { HTMLProps, PropsWithChildren } from 'react';
import cx from 'classnames';

import Tooltip from '@/components/Tooltip';

type Props = PropsWithChildren<{
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  textColor?: string;
  tooltip?: string;
}> &
  Omit<HTMLProps<HTMLButtonElement>, 'type'>;

const IconButton = ({
  children,
  backgroundColor = 'bg-white',
  hoverBackgroundColor = 'hover:bg-gray-100',
  textColor = 'text-gray-700',
  className = '',
  tooltip,
  disabled = false,
  ...rest
}: Props) => {
  return (
    <Tooltip
      id={`icon-button-tooltip-${btoa(tooltip || '')}`}
      text={tooltip || ''}
      showIcon={false}
      isEnabled={disabled ? false : !!tooltip}
      autoWidth
    >
      <button
        type="button"
        disabled={disabled}
        className={cx(
          'flex items-center justify-center w-4 h-4 p-1 box-content rounded-lg',
          disabled && 'cursor-not-allowed',
          !disabled && hoverBackgroundColor,
          backgroundColor,
          disabled ? 'text-surface-400' : textColor,
          className
        )}
        {...rest}
      >
        {children}
      </button>
    </Tooltip>
  );
};

export default IconButton;
