import { ActiveNodeResult } from '../types';

export const useIsActiveNodeDeletable = (activeNode: ActiveNodeResult): boolean => {
  return ![
    'signupModal',
    'recommendationsModal',
    'loginModal',
    'login',
    'updatePassword',
    'resetPassword',
    'doc',
  ].includes(activeNode.activeNodeType);
};
