import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';

import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

type ApiResponse = {
  data: Array<string>;
};

export type Params = {
  [key: string]: string;
};

const buildURL = (token: string, url: string, additionalParams?: Params, hrefRedirect?: boolean) => {
  const builtUrl = new URL(url);
  builtUrl.searchParams.append('admin_token', token || '');

  if (additionalParams) {
    Object.keys(additionalParams).forEach((key) => {
      builtUrl.searchParams.append(key, additionalParams[key]);
    });
  }

  if (hrefRedirect) {
    window.location.href = builtUrl.toString();
  } else {
    window.open(builtUrl.toString(), '_blank');
  }
};

// A hook that allows us to generate an Orchid token for a user so they can login to Orchid conveniently across the application
const useGenerateOrchidAdminToken = () => {
  const [currentToken, setCurrentToken] = useState<string | null>(null);
  const { data: currentPublication } = useCurrentPublication();
  const { currentUser } = useCurrentUser();
  const id = currentUser?.id;

  useEffect(() => {
    const token = localStorage.getItem('orchid_login_token');
    setCurrentToken(token);
  }, []);

  const mutation = useMutation<ApiResponse, unknown, {}>(() =>
    api
      .post(`/users/${id}/orchid_tokens`, {
        publication_id: currentPublication?.id,
      })
      .then((res) => res.data)
  );

  const handleRedirectToOrchid = (path: string, additionalParams?: Params, hrefRedirect?: boolean) => {
    const publicationUrl = currentPublication?.url?.slice(0, -1);
    const urlToPassIn = `${publicationUrl}${path}`;

    if (currentToken) {
      buildURL(currentToken, urlToPassIn, additionalParams, hrefRedirect);
    } else {
      mutation.mutate(
        {},
        {
          onSuccess: async ({ data }: any) => {
            const token = data?.[0];
            setCurrentToken(token);
            localStorage.setItem('orchid_login_token', token);
            buildURL(token, urlToPassIn, additionalParams, hrefRedirect);
          },
          onError: (err: any) => {
            toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
          },
        }
      );
    }
  };

  return {
    currentToken,
    handleRedirectToOrchid: (path: string, additionalParams?: Params, hrefRedirect?: boolean) =>
      handleRedirectToOrchid(path, additionalParams, hrefRedirect), // Ex: handleRedirectToOrchid('/') or handleRedirectToOrchid('/upgrade')
  };
};

export default useGenerateOrchidAdminToken;
