import { useState } from 'react';

import { Checkbox } from '@/components/Form';
import { Button } from '@/ui/Button';

import useFilteringContext from '../filtering-context';
import { ConditionGroup, TierOperator, TierOperatorsList, TierOptions } from '../types';

const TierFilterDetails = ({ group }: { group: ConditionGroup }) => {
  const operators = TierOperatorsList;
  const tierOptions = TierOptions;

  const [selectedOperator, setSelectedOperator] = useState<TierOperator>(operators[0].value);
  const [selectedTiers, setSelectedTiers] = useState<Array<string>>([]);

  const { addCondition, handleClose } = useFilteringContext();

  const handleSelect = (value: string) => {
    setSelectedTiers([...selectedTiers, value]);
  };

  const handleDeselect = (value: string) => {
    setSelectedTiers(selectedTiers.filter((tier) => tier !== value));
  };

  const handleApply = () => {
    selectedTiers.forEach((tier) => {
      addCondition(
        {
          type: 'attribute',
          name: 'tier',
          filters: { operator: selectedOperator, value: tier },
        },
        group.uuid
      );
    });

    handleClose();
  };

  return (
    <form
      className="absolute z-10 mt-2 bg-white border border-gray-300 shadow rounded-md text-sm left-0 w-max text-gray-600 p-4 space-y-2"
      onSubmit={handleApply}
    >
      <div className="space-y-2">
        <div className="space-x-2">
          <p className="inline">Tier</p>
          <select
            className="appearance-none outline-none border rounded-md text-xs border-gray-300"
            onChange={(e) => setSelectedOperator(e.target.value as any)}
            value={selectedOperator}
            required
          >
            {operators.map((operator) => {
              return (
                <option key={operator.value} value={operator.value}>
                  {operator.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className="h-64 w-56 space-y-2">
          {tierOptions.map((option) => (
            <Checkbox
              name={option.id}
              labelText={option.name}
              checked={selectedTiers.includes(option.id)}
              onChange={(checked) => checked ? handleSelect(option.id) : handleDeselect(option.id) }
            />
          ))}
        </div>
      </div>
      <Button variant="primary-inverse" type="submit" size="xs">
        Apply
      </Button>
    </form>
  );
};

export default TierFilterDetails;
