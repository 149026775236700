import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

import { useCalendarContext } from './useCalendarContext';

const TopBar = () => {
  const { goToToday, goToPrev, previousMonth, previousYear, currentMonth, currentYear, goToNext, nextMonth, nextYear } =
    useCalendarContext();

  return (
    <div
      className="flex justify-between items-center col-span-8 px-6 py-3 space-x-6 border-b border-gray-100"
      data-testid="calendar-top-bar"
    >
      <div>
        <Button
          onClick={goToToday}
          variant="primary-inverse"
          size="xs"
          data-testid="go-to-today"
          aria-label="Go to today"
        >
          Today
        </Button>
      </div>
      <div className="flex flex-1 max-w-xs space-x-4 items-center justify-center">
        <div>
          <Button
            onClick={goToPrev}
            variant="primary-inverse"
            size="none"
            className="px-2 py-2"
            data-testid="go-to-prev-month"
            aria-label={`Go to ${previousMonth} ${previousYear}`}
          >
            <ArrowLeftIcon className="h-4 w-4" />
            <span className="sr-only">
              View {previousMonth} {previousYear}
            </span>
          </Button>
        </div>
        <div className="lg:flex-1 text-center">
          <Typography as="h1" token="font-medium/text/base" colorWeight="900">
            {currentMonth} {currentYear}
          </Typography>
        </div>
        <div>
          <Button
            onClick={goToNext}
            variant="primary-inverse"
            size="none"
            className="px-2 py-2"
            data-testid="go-to-next-month"
            aria-label={`Go to ${nextMonth} ${nextYear}`}
          >
            <ArrowRightIcon className="h-4 w-4" />
            <span className="sr-only">
              View {nextMonth} {nextYear}
            </span>
          </Button>
        </div>
      </div>
      <div>&nbsp;</div>
    </div>
  );
};

export default TopBar;
