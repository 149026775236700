import { useState } from 'react';
import { Tier } from '@shared/dream-components';

import { Input } from '../../../../UI/Input';
import { Switch } from '../../../../UI/Switch';
import { Textarea } from '../../../../UI/Textarea';

import FeaturesForm from './FeaturesForm';

interface Props {
  tier: Tier;
  onChange: (changedTier: Tier) => void;
}

const SelectedTierView = ({ tier, onChange }: Props) => {
  const [status, setStatus] = useState(tier.enabled || false);

  const handleToggleStatus = (value: boolean) => {
    setStatus(value);
    onChange({
      ...tier,
      enabled: value,
    });
  };

  const handleChangeInName = (newName: string) => {
    if (newName) {
      onChange({
        ...tier,
        name: newName,
      });
    }
  };

  const handleChangeInDescription = (newDescription: string) => {
    if (newDescription) {
      onChange({
        ...tier,
        description: newDescription,
      });
    }
  };

  return (
    <div className="flex w-full flex-col gap-y-6">
      <div className="flex w-full justify-end">
        <Switch
          id={`${tier.id}-enabled`}
          checked={status}
          onCheckedChange={() => handleToggleStatus(!status)}
          labelText="Visible"
          labelClassName="whitespace-nowrap"
        />
      </div>
      <Input
        labelText="Name"
        defaultValue={tier.name}
        onBlur={(e) => handleChangeInName(e.currentTarget.value.trim())}
        placeholder="Tier name"
      />
      <Textarea
        labelText="Description"
        defaultValue={tier.description}
        onBlur={(e) => handleChangeInDescription(e.currentTarget.value.trim())}
        placeholder="Tier description"
      />
      {tier.prices.map((price) => (
        <FeaturesForm tier={tier} price={price} onChange={onChange} />
      ))}
    </div>
  );
};

export default SelectedTierView;
