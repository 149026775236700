import { ArrowSquareOut } from '@phosphor-icons/react';

import { Text } from '../../_components/UI/Text';

interface Props {
  labelText: string;
  href: string;
}

const SettingsUrl = ({ labelText, href }: Props) => {
  return (
    <div className="flex flex-col gap-1.5 pt-4">
      <Text size="sm" variant="secondary">
        {labelText}
      </Text>
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="flex items-center gap-2 px-3 py-2 rounded-lg bg-wb-secondary w-fit borderr border-wb-primary"
      >
        <div className="w-2 h-2 rounded-full bg-wb-success" />
        <Text size="xs">{href}</Text>
        <ArrowSquareOut size={16} className="text-wb-secondary" />
      </a>
    </div>
  );
};

export default SettingsUrl;
