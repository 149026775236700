import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';

import ColorField from '../../../../components/Form/ColorField';
import { useUpdateHomePage } from '../../../../hooks/useHomePages';
import { HomePage } from '../../../../interfaces/home_page';
import ThemeEditorDropdown from '../../../ThemeEditor/components/ThemeEditorDropdown';
import ThemeFormDrawer from '../../../ThemeEditor/components/ThemeFormDrawer';
import { Divider, FormContainer, FormInput, FormSection } from '../../../ThemeEditor/helpers';
import { usePageContext } from '../../components/PageContext';

import Header from './DefaultHeader';
import useHomePageThemeUpdater, { Themes } from './theme';

const HEADER_OPTIONS = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'News',
    value: 'news',
  },
  {
    label: 'Headliner',
    value: 'headliner',
  },
];

const LAYOUT_OPTIONS = [
  {
    label: 'Grid',
    value: 'grid',
  },
  {
    label: 'List',
    value: 'list',
  },
];

const Theme = () => {
  const queryClient = useQueryClient();
  const { updateTheme, updateFonts, themeOptions } = useHomePageThemeUpdater();
  const { pageData, colorPalette, setIsSaving, handleSaved, currentPublication, fontOptions } =
    usePageContext<HomePage>();

  const [theme, setTheme] = useState<any>(pageData.general_section.theme);
  const [archive, setArchive] = useState<any>(pageData?.posts_section?.archive);

  // Action Confirmations
  const [themeToUpdate, setThemeToUpdate] = useState('');
  const [fontToUpdate, setFontToUpdate] = useState('');

  useEffect(() => {
    setTheme(pageData.general_section.theme);
    setArchive(pageData?.posts_section?.archive);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  const updateHomePage = useUpdateHomePage({
    pageId: pageData.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['home_page', currentPublication?.id, 'draft']);
      handleSaved();
    },
  });

  const handleSaveGeneralSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: { general_section: JSON.stringify(payload) } });
  };

  const handleSavePostsSection = (payload: any) => {
    setIsSaving(true);
    updateHomePage.mutate({ home_page: { posts_section: JSON.stringify(payload) } });
  };

  return (
    <ThemeFormDrawer active={false} title="Theme" onClickHandler={() => {}}>
      <FormContainer>
        <FormSection
          title="Set Your Theme"
          protip={
            <>
              <p>
                Start by following steps 1-4 below. These are global actions that will populate your theme across the
                page.
              </p>
              <p>
                If you want to get fancy, we offer a number of options below for more control over the look and feel of
                your page, including featured sections, widgets, and advanced post card customization.{' '}
              </p>
              <p>Have fun!</p>
            </>
          }
        >
          <FormInput
            title="1. Choose a theme"
            helperText="The Light & Dark themes will use your site's primary, secondary, and tertiary colors to set reasonable defaults. The other themes are there to help inspire some creativity if you're not sure where to start."
          >
            <ActionModal
              isOpen={Boolean(themeToUpdate)}
              onProceed={() => {
                updateTheme(themeToUpdate as Themes);
                setThemeToUpdate('');
              }}
              onClose={() => setThemeToUpdate('')}
              resourceId={themeToUpdate}
              isWorking={false}
              headerText="Update Theme"
              actionText="Continue"
            >
              <p className="text-sm">
                Are you sure you want to update the theme? This will override any color customizations you have already
                made across the page.
              </p>
            </ActionModal>
            <ThemeEditorDropdown
              currentValue={themeToUpdate || ''}
              onUpdate={(val: string) => {
                if (val) {
                  setThemeToUpdate(val as Themes);
                }
              }}
              options={themeOptions}
            />
          </FormInput>
          <FormInput
            title="2. Choose a header type"
            helperText="The Default header offers additional customizations which can be found below. News & Headliner headers can highlight featured posts above-the-fold. See the 'Featured Posts' section below to select those posts."
          >
            <ThemeEditorDropdown
              currentValue={theme?.header_type}
              onUpdate={(val: string) =>
                handleSaveGeneralSection({
                  ...pageData?.general_section,
                  theme: {
                    ...theme,
                    header_type: val,
                  },
                })
              }
              options={HEADER_OPTIONS}
            />
          </FormInput>
          <FormInput
            title="3. Choose a layout type"
            helperText="Choose between grid and list layouts for posts in the archive section of the page."
          >
            <ThemeEditorDropdown
              currentValue={archive?.layout || 'grid'}
              onUpdate={(val: string) =>
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  archive: {
                    ...archive,
                    layout: val,
                  },
                })
              }
              options={LAYOUT_OPTIONS}
            />
          </FormInput>
          <FormInput
            title="4. Choose a font family"
            helperText="The global font styles applied to the page. These can be overwritten as needed below."
          >
            <ActionModal
              isOpen={Boolean(fontToUpdate)}
              onProceed={() => {
                updateFonts(fontToUpdate);
                setFontToUpdate('');
              }}
              onClose={() => setFontToUpdate('')}
              resourceId={fontToUpdate}
              isWorking={false}
              headerText="Update Fonts"
              actionText="Continue"
            >
              <p className="text-sm">
                Are you sure you want to update the fonts? This will override any font customizations you have already
                made across the page.
              </p>
            </ActionModal>
            <ThemeEditorDropdown
              currentValue={fontToUpdate || theme?.font}
              onUpdate={(val: string) => {
                setFontToUpdate(val);
              }}
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Background color" helperText="Applied to the background of the page.">
            <ColorField
              placement="bottom"
              color={theme?.bg_color || '#ffffff'}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setTheme({ ...theme, bg_color: color?.hexString })}
              onBlur={() => {
                handleSaveGeneralSection({
                  ...pageData?.general_section,
                  theme,
                });
              }}
            />
          </FormInput>
          <FormInput
            title="Text on background color"
            helperText="Applied to the search bar, content tags, text in the widgets sections & News/Headliner headers."
            tooltipText="This text is intended to contrast with the background color so we apply it to text and elements laid directly on the background."
          >
            <ColorField
              placement="bottom"
              color={theme?.text_color || '#000000'}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setTheme({ ...theme, text_color: color?.hexString })}
              onBlur={() => {
                handleSaveGeneralSection({
                  ...pageData?.general_section,
                  theme,
                });
              }}
            />
          </FormInput>
          <FormInput
            title="Accent color"
            helperText="Applied to labels in the featured posts & widgets section of the page."
            tooltipText="This text is intended to grab a user's attention, which is why we default it to your website's primary color."
          >
            <ColorField
              placement="bottom"
              color={theme?.accent_color || '#000000'}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setTheme({ ...theme, accent_color: color?.hexString })}
              onBlur={() => {
                handleSaveGeneralSection({
                  ...pageData?.general_section,
                  theme,
                });
              }}
            />
          </FormInput>
          <FormInput
            title="Border color"
            helperText="Applied to borders in the widgets section & News/Headliner headers."
          >
            <ColorField
              placement="bottom"
              color={theme?.border_color || '#000000'}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setTheme({ ...theme, border_color: color?.hexString })}
              onBlur={() => {
                handleSaveGeneralSection({
                  ...pageData?.general_section,
                  theme,
                });
              }}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <Header />
      </FormContainer>
    </ThemeFormDrawer>
  );
};

export default Theme;
