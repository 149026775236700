import { ShieldCheckIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';

interface Props {
  avatarUrl: string;
  commenter: string;
  time: string;
  content: string;
  isModerator?: boolean;
}

// These two functions are pretty thoroughly tested in orchid.
// Check orchid utils for reference:
// https://github.com/beehiiv/orchid/tree/main/app/utils/renderTime
const pluralizeTime = (number: number) => {
  return number === 1 ? '' : 's';
};

const renderTime = (time: string) => {
  const now = new Date();
  const date = new Date(time);
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(weeks / 4);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return `${seconds} second${pluralizeTime(seconds)} ago`;
  }
  if (minutes < 60) {
    return `${minutes} minute${pluralizeTime(minutes)} ago`;
  }
  if (hours < 24) {
    return `${hours} hour${pluralizeTime(hours)} ago`;
  }
  if (days < 7) {
    return `${days} day${pluralizeTime(days)} ago`;
  }
  if (weeks < 4) {
    return `${weeks} week${pluralizeTime(weeks)} ago`;
  }
  if (months < 12) {
    return `${months} month${pluralizeTime(months)} ago`;
  }
  return `${years} year${pluralizeTime(years)} ago`;
};

const CommentDisplay = ({ avatarUrl, commenter, time, content, isModerator = false }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  if (isV2) {
    return (
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-4 items-start">
          <img className="h-5 w-5 rounded-full" src={avatarUrl} alt="The user that posted the comment" />
          <div className="flex gap-x-2 items-center">
            {isModerator && <ShieldCheckIcon className="h-4 w-4 text-blue-500" />}
            <Typography token="font-medium/text/sm">{commenter}</Typography>
            <Typography token="font-light/text/xs" colorWeight="600">
              {renderTime(time)}
            </Typography>
          </div>
        </div>
        <Typography token="font-normal/text/sm" colorWeight="600">
          {content}
        </Typography>
      </div>
    );
  }

  return (
    <div className="flex w-full rounded p-2">
      <img className="h-5 w-5 rounded-full" src={avatarUrl} alt="The user that posted the comment" />
      <div className="ml-3 space-y-3">
        <div className="space-y-1">
          <div className="flex items-center space-x-1">
            {isModerator && <ShieldCheckIcon className="h-4 w-4 text-blue-500" />}{' '}
            <p className="text-xs font-medium text-gray-900">
              {commenter}
              <span className="font-light opacity-70"> &#x2022; {renderTime(time)}</span>
            </p>
          </div>

          <p className="text-xs text-gray-500">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default CommentDisplay;
