import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { BLACK_COLOR, DEFAULT_BORDER_RADIUS, DEFAULT_PRIMARY_COLOR, WHITE_COLOR } from '../constants';

import { RecommendationsModalView } from './views/RecommendationsModalView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    recommendationsModal: {
      setRecommendationsModal: () => ReturnType;
    };
  }
}

export const RecommendationsModal = Node.create<{}, {}>({
  name: 'recommendationsModal',

  group: 'block',

  content: 'block*',

  draggable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      gap: {
        default: '8px',
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
        parseHTML: (element) => element.getAttribute('data-gap') || '8px',
      },
      backgroundColor: {
        default: WHITE_COLOR,
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
        parseHTML: (element) => element.getAttribute('data-background-color') || WHITE_COLOR,
      },
      modalOverlayBackgroundColor: {
        default: BLACK_COLOR,
        renderHTML: (attributes) => ({ 'data-modal-overlay-background-color': attributes.modalOverlayBackgroundColor }),
        parseHTML: (element) => element.getAttribute('data-modal-overlay-background-color') || BLACK_COLOR,
      },
      height: {
        default: '550px',
        renderHTML: (attributes) => ({ 'data-height': attributes.height }),
        parseHTML: (element) => element.getAttribute('data-height'),
      },
      width: {
        default: '640px',
        renderHTML: (attributes) => ({ 'data-width': attributes.width }),
        parseHTML: (element) => element.getAttribute('data-width'),
      },
      padding: {
        default: '32px',
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
        parseHTML: (element) => element.getAttribute('data-padding') || '32px',
      },
      borderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
        parseHTML: (element) => element.getAttribute('data-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      borderWidth: {
        default: '2px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '2px',
      },
      borderColor: {
        default: DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_PRIMARY_COLOR,
      },
      borderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'solid',
      },
    };
  },

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(RecommendationsModalView, {
      stopEvent: () => false,
      className: 'w-full h-full',
    });
  },
});

export default RecommendationsModal;
