import React, { createContext, useState } from 'react';

import SupportTicketModal from '@/components/SupportTicketModal';
import { useCurrentPublication, useOrganization, useSupportTicketModalParam } from '@/hooks';
import usePlanType from '@/hooks/useOrganization/usePlanType';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

export type ContextType = {
  isSupportModalOpen: boolean;
  setIsSupportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  planType: string | undefined;
  isFreePlan: boolean;
  isBanned: boolean;
  setBypassConfirm: React.Dispatch<React.SetStateAction<boolean>>;
};

const SupportModalContext = createContext<ContextType | undefined>(undefined);

SupportModalContext.displayName = 'SupportModalContext';

const SupportModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: currentPublication } = useCurrentPublication();
  const publicationId = useCurrentPublicationId();
  const { data: plan } = usePlanType({ organizationId: currentPublication?.organization_id });
  const { data: organization } = useOrganization(currentPublication?.organization_id, publicationId);

  const [isSupportModalOpen, setIsSupportModalOpen] = useState(useSupportTicketModalParam());
  const [bypassConfirm, setBypassConfirm] = useState(false);
  const planType = plan?.type || "free"; // default to free until we get the actual plan type back
  const isFreePlan = planType === "free";
  const isBanned = !organization?.organization.sending_enabled || organization?.organization.flagged_for_review;

  const contextValue: ContextType = React.useMemo(() => ({
    isSupportModalOpen,
    setIsSupportModalOpen,
    planType,
    isFreePlan,
    setBypassConfirm,
    isBanned,
  }), [
    isSupportModalOpen,
    setIsSupportModalOpen,
    planType,
    isFreePlan,
    setBypassConfirm,
    isBanned,
  ]);

  return (
    <SupportModalContext.Provider value={contextValue}>
      <SupportTicketModal
        open={isSupportModalOpen}
        onClose={() => {
          setIsSupportModalOpen(false);
          setBypassConfirm(false);
        }}
        isFreePlan={isFreePlan}
        isBanned={isBanned}
        bypassConfirm={bypassConfirm}
      />
      {children}
    </SupportModalContext.Provider>
  );
};

function useSupportModalContext() {
  const context = React.useContext(SupportModalContext);
  if (context === undefined) {
    throw new Error(`useSupportModalContext must be used within a SupportModalProvider`);
  }
  return context;
}

export { SupportModalContext, SupportModalProvider, useSupportModalContext };
