import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Pagination } from '../../interfaces/general';
import { Subscription } from '../../interfaces/subscription';
import api from '../../services/swarm';

interface ApiResponse {
  subscriptions: Subscription[];
  pagination: Pagination;
}

export default function useSegmentSubscribers({
  segmentId,
  enabled = true,
  search = '',
}: {
  segmentId: string;
  enabled: boolean;
  search: string;
}) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchSubscribers = ({ pageParam = 1 }) =>
    api
      .get(`/segments/${segmentId}/subscriptions`, {
        params: {
          q: search,
          page: pageParam,
          per_page: 10,
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([currentPublicationId, 'segment-subscribers', segmentId], fetchSubscribers, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
    enabled,
  });
}
