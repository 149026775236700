import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowCircleUp } from '@phosphor-icons/react';
import { Content, Editor } from '@tiptap/core';

import { useDreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext } from '@/context/website-context';
import { usePage } from '@/hooks/usePages';
import useSiteVersions from '@/hooks/useSiteVersion/useSiteVersions';
import { SiteVersion } from '@/interfaces/dream_builder/site_version';
import { Text } from '@/routes/website/_components/UI/Text';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';
import { cn } from '@/routes/website/_utils/cn';
import { dateToAgo } from '@/routes/website/_utils/dateToAgo';
import { dateToDateString } from '@/routes/website/_utils/dateToDateString';

import { Button } from '../../UI/Button';

import { RestoreMenuOption } from './RestoreMenuOption';

const VersionItem = ({
  version,
  publishedID,
  isCurrent,
}: {
  version: SiteVersion;
  publishedID?: string;
  isCurrent?: boolean;
}) => {
  const { previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const { save } = useDreamEditorContext();

  return (
    <div
      className={cn(
        'flex items-center px-3 gap-4 h-[54px] group hover:bg-wb-secondary rounded-lg cursor-pointer',
        previewSiteVersion?.id === version.id || (isCurrent && !previewSiteVersion) || isOptionsOpen
          ? 'bg-wb-secondary'
          : 'hover:bg-wb-secondary'
      )}
      onClick={() => {
        if (isCurrent) {
          setPreviewSiteVersion(undefined);
        } else {
          // save any changes before previewing other versions
          save();
          setPreviewSiteVersion(version);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          e.currentTarget.click();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="flex flex-col items-center h-full">
        <div className="flex-1 w-[1px] bg-wb-surface group-first:bg-transparent" />
        <div className="w-[7px] h-[7px] bg-wb-surface rounded-full" />
        <div className="flex-1 w-[1px] bg-wb-surface group-last:bg-transparent" />
      </div>
      <div className="flex items-center justify-start gap-2 flex-1 min-w-0">
        <Tooltip
          center={isCurrent ? 'Current Draft Version' : dateToDateString(version.created_at)}
          className="h-full flex items-center whitespace-nowrap"
        >
          <Text size="xs" weight="semibold">
            {isCurrent ? 'Current Version' : dateToAgo(version.created_at, true)}
          </Text>
        </Tooltip>
        {version.version && (
          <Tooltip center={version.version} className="flex-1 min-w-0">
            <Text size="xs" variant="secondary" as="p" className="truncate">
              {version.version}
            </Text>
          </Tooltip>
        )}
        {version.version_type === 'published' && version.published_at && (
          <Tooltip
            center={
              publishedID === version.id
                ? 'Current Published Version'
                : `Was published on ${dateToDateString(version.published_at)}`
            }
            className="h-full flex items-center justify-center"
          >
            <ArrowCircleUp
              size={16}
              className={cn(publishedID === version.id ? 'text-wb-accent' : 'text-wb-secondary')}
              weight="bold"
            />
          </Tooltip>
        )}
      </div>
      <RestoreMenuOption version={version} isOptionsOpen={isOptionsOpen} setIsOptionsOpen={setIsOptionsOpen} />
    </div>
  );
};

export const VersionHistoryPanel = ({ editor }: { editor: Editor }) => {
  const { data: siteVersionsData, hasNextPage, fetchNextPage, isFetchingNextPage } = useSiteVersions();

  const siteVersions = siteVersionsData?.pages.flatMap((page) => page.site_versions) || [];

  const { site, previewSiteVersion } = useWebsiteContext();

  const { previewContent } = useDreamEditorContext();
  const { pageId } = useParams();
  const { data: page } = usePage({ pageId: pageId as string });

  const onSetContent = useCallback(
    (content: Content, isEditable: boolean) => {
      editor.commands.setContent(
        content || {
          type: 'doc',
          content: [
            {
              type: 'section',
              attrs: {
                id: 'id',
              },
            },
          ],
        },
        false
      );
      editor.setEditable(isEditable, false);
    },
    [editor]
  );
  const [isPreviewing, setIsPreviewing] = useState(false);

  useEffect(() => {
    const emptyContent = {
      type: 'doc',
      content: [
        {
          type: 'section',
          attrs: {
            id: 'id',
          },
        },
      ],
    };
    const hasActivePreview = !!(previewSiteVersion?.id && previewContent);
    if (hasActivePreview) {
      onSetContent(previewContent || emptyContent, false);
      setIsPreviewing(true);
    } else if (isPreviewing && !hasActivePreview) {
      const pageContent = page?.draft_page_version?.content;
      const useEmptyContent = !pageContent || Object.keys(pageContent).length === 0;
      onSetContent(useEmptyContent ? emptyContent : pageContent, true);
      setIsPreviewing(false);
    }
  }, [onSetContent, page?.draft_page_version?.content, previewContent, previewSiteVersion?.id, isPreviewing]);

  return (
    <div className="flex flex-col gap-2 max-w-full">
      <div className="flex items-center justify-between gap-4 mb-4 relative w-full">
        <Text size="xs" weight="semibold">
          Version History
        </Text>
      </div>
      <div className="flex flex-col">
        {site?.draft_site_version && (
          <VersionItem version={site?.draft_site_version} publishedID={site?.published_site_version?.id} isCurrent />
        )}
        {siteVersions?.map((version) => (
          <VersionItem key={version.id} version={version} publishedID={site?.published_site_version?.id} />
        ))}
      </div>
      {hasNextPage && (
        <Button
          variant="outlined"
          onClick={() => fetchNextPage()}
          isLoading={isFetchingNextPage}
          isDisabled={isFetchingNextPage}
          size="sm"
          className="w-fit ml-[35px]"
        >
          Load more
        </Button>
      )}
    </div>
  );
};
