// Define the extension for Tiptap

import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { DEFAULT_BORDER_COLOR } from '../constants';

import { DividerView } from './views/DividerView';

export const Divider = Node.create({
  name: 'divider',
  group: 'block',
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      backgroundColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
      },
      width: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({ 'data-width': attributes.width }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': this.name })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(DividerView, {
      attrs: (props) => {
        return {
          ...props.HTMLAttributes,
          style: `width:${props.node.attrs.width};`,
        };
      },
      stopEvent: () => false,
    });
  },
});
