import { useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import Text from '@/components/Text';
import { useCurrentPublication } from '@/hooks';
import { useUpdatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Section, SwitchForm } from '@/pages/Settings/Components';
import NotEditing from '@/pages/Settings/Components/NotEditing';
import { Button } from '@/ui/Button';

const GDPRSection = () => {
  const { data: currentPublication } = useCurrentPublication();
  const currentPublicationId = useCurrentPublicationId();
  const publicationMutation = useUpdatePublication(currentPublicationId);

  const [customTosOpen, setCustomTosOpen] = useState<boolean>(false);
  const [customTosText, setCustomTosText] = useState<string>(currentPublication?.terms_of_service || '');

  const onSave = async () => {
    try {
      publicationMutation.mutateAsync({ terms_of_service: customTosText });
      setCustomTosOpen(false);
      toast.success('Saved!');
    } catch (e) {
      toast.error('Error updating custom ToS');
    }
  };

  return (
    <>
      <Section
        title="GDPR, CCPA & Compliance"
        description="Both of these must be toggled on for your publication to be GDPR & CCPA compliant."
        scrollToId="compliance"
        isLoading={!currentPublication}
        hasDivider
      >
        <p className="text-sm font-regular text-gray-600">
          Deletions and exports are handled at the platform level, make sure users email:{' '}
          <a
            href="mailto:privacy@beehiiv.com"
            target="_blank"
            rel="noreferrer"
            className="font-semibold hover:opacity-70 text-gray-900"
          >
            privacy@beehiiv.com
          </a>
          .
        </p>
        <div className="flex w-full justify-between pt-4">
          <NotEditing
            label="Signup Terms of Service"
            value="Add signup terms of service for the readers of your newsletter."
          />
          <Button Icon={PencilIcon} size="xs" variant="primary-inverse" onClick={() => setCustomTosOpen(true)}>
            Signup ToS
          </Button>
        </div>
        <SwitchForm
          name="cookie_banner_enabled"
          label="Cookie Banner"
          description="Enable this to show a cookie banner when someone first visits your publication site to allow them to opt out of certain cookies."
          value={currentPublication?.enable_cookie_banner || false}
          onSave={(val: boolean) => publicationMutation.mutateAsync({ enable_cookie_banner: val })}
          toastSuccessMessage="Saved!"
        />
        <SwitchForm
          name="signup_confirmation_enabled"
          label="Sign Up ToS & Privacy Policy"
          description="Enable this to display your terms of service & privacy policy upon signup. Subscribers must check this box to signup."
          value={currentPublication?.enable_signup_confirmation || false}
          onSave={(val: boolean) => publicationMutation.mutateAsync({ enable_signup_confirmation: val })}
          toastSuccessMessage="Saved!"
        />
      </Section>
      <ActionModal
        isOpen={customTosOpen}
        onClose={() => setCustomTosOpen(false)}
        isWorking={publicationMutation.isLoading}
        onProceed={onSave}
        headerText="Custom ToS"
        resourceId={customTosText}
      >
        <div className="flex flex-col">
          <p className="block text-sm font-medium text-gray-700 mb-2">Terms of Service</p>
          <div className="flex">
            <textarea
              value={customTosText}
              onChange={(e) => setCustomTosText(e.target.value)}
              placeholder="User content submitted..."
              className="appearance-none block w-full px-3 py-2 border rounded h-[200px] border-gray-200 placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed"
            />
          </div>
          <Text size="xs" className="mt-2 text-gray-500">
            This is accessible via the ToS page on your publication site if the Cookie banner is enabled.
          </Text>
        </div>
      </ActionModal>
    </>
  );
};

export default GDPRSection;
