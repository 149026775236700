import { ArrowUpRightIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { useCreateStandardAccount } from '@/hooks/stripe';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

interface Props {
  children: React.ReactNode;
  mutationProps?: {
    country: string;
  };
  hideButton?: boolean;
}
const StripeWarning: React.FC<Props> = ({ children, mutationProps = {}, hideButton }) => {
  const createStripeAccountMutation = useCreateStandardAccount({
    onSuccess: (res) => {
      analytics.track('connected to stripe for premium subs');
      window.open(res.url, '_blank');
    },
  });

  return (
    <div className="mt-12 bg-feedback-warning-50 rounded-md border border-feedback-warning-200 p-6">
      <TypographyStack gap="2">
        <TypographyRow gap="2">
          <ExclamationTriangleIcon className="w-6 h-6 text-feedback-warning-500" />
          <Typography token="font-semibold/text/lg" color="warning" colorWeight="900">
            Stripe Account
          </Typography>
        </TypographyRow>
        <Typography token="font-normal/text/base" color="warning" colorWeight="700">
          {children}
        </Typography>
      </TypographyStack>
      {!hideButton && (
        <div className="pt-4 flex justify-end">
          <Button
            variant="primary"
            type="button"
            loading={createStripeAccountMutation.isLoading}
            onClick={() => createStripeAccountMutation.mutate(mutationProps)}
            Icon={ArrowUpRightIcon}
            iconRight
          >
            Connect to Stripe
          </Button>
        </div>
      )}
    </div>
  );
};

export default StripeWarning;
