import { useQuery } from 'react-query';

import { PostPoll } from '../../interfaces/post_poll';
import api from '../../services/swarm';

export default function usePostPoll(publicationId: string, { id }: { id: string }) {
  return useQuery<PostPoll>(
    ['post_polls', id],
    () =>
      api
        .get(`/post_polls/${id}`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
