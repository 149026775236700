import styled from 'styled-components';

import { PanelDivider } from '../ui/Panel';

const Styled = {
  DragHandleButtonWrapper: styled.div`
    display: flex;
  `,
  MenuButtonWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  `,
  ContentWrapper: styled.div.attrs({
    draggable: false,
  })`
    pointer-events: all;
    user-select: none;
  `,
  PanelSectionInner: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    ${PanelDivider} {
      margin-bottom: 0.25rem;
      margin-top: 0.25rem;
    }
  `,
  Label: styled.span`
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.6);
  `,
  LabelWrapper: styled.div`
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 0.5rem;
    padding-left: 0.25rem;
  `,
};

export default Styled;
