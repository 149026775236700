import styled from 'styled-components';

export const Styled = {
  Button: styled.a`
    margin-bottom: 1.5rem;
    // … see editor styles
  `,
};

export default Styled;
