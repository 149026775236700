import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';
import api from '@/services/swarm';

interface ApiResponse {
  boost_offers: BoostOffer[];
}

interface Variables {
  isEnabled: boolean;
}

const useRecommendedBoostOffers = ({ isEnabled = true }: Variables) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostOffers = (): Promise<ApiResponse> =>
    api
      .get('/boosts/monetize/recommended_boost_offers', {
        params: { publication_id: currentPublicationId },
      })
      .then((res: any) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'monetize', 'recommended_boost_offers'], fetchBoostOffers, {
    enabled: isEnabled,
  });
};

export default useRecommendedBoostOffers;
