import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { Input } from '@/components/Form';
import { AdNetworkContact } from '@/interfaces/ad_network/internal/contact';

interface ContactFieldsProps {
  contact: AdNetworkContact;
  onChange: (data: AdNetworkContact) => void;
}

const ContactFields = ({ contact, onChange }: ContactFieldsProps) => {
  const { id } = contact;
  const [firstName, setFirstName] = useState(contact?.first_name);
  const [lastName, setLastName] = useState(contact?.last_name);
  const [email, setEmail] = useState(contact?.email);

  const [debouncedFirstName] = useDebounce(firstName, 800);
  const [debouncedLastName] = useDebounce(lastName, 800);
  const [debouncedEmail] = useDebounce(email, 800);

  useEffect(() => {
    onChange({
      id,
      first_name: debouncedFirstName,
      last_name: debouncedLastName,
      email: debouncedEmail,
    } as AdNetworkContact);
  }, [debouncedFirstName, debouncedLastName, debouncedEmail]);

  return (
    <div className="space-y-6 py-6">
      <div className="flex flex-col space-y-6 space-x-0 items-start sm:flex-row sm:items-center sm:space-y-0 sm:space-x-2">
        <Input
          className="w-full"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          name="advertiser[primary_contact][first_name]"
          labelText="First Name"
          helperText="The first name of the contact (private)."
          required
        />
        <Input
          className="w-full"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          name="advertiser[primary_contact][last_name]"
          labelText="Last Name"
          helperText="The last name of the contact (private)."
          required
        />
      </div>
      <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        name="advertiser[primary_contact][email]"
        labelText="Email"
        helperText="The email of the contact (private)."
        required
      />
    </div>
  );
};

export default ContactFields;
