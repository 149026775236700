export interface Comment {
  id: string;
  content: string;
  created_at: string;
  deleted_by_commenter_at: string;
  removed_at: string;
  subcomments_count: number;
  commenter: string;
  commenter_id: string;
  avatar_url: string;
  is_moderator_comment: boolean;
  reported_count: number;
}

export enum CommentSectionState {
  DEFAULT = 'default',
  PREMIUM = 'premium',
  DISABLED = 'disabled',
  LOCKED = 'locked',
}

export type CommentSectionStateType =
  | CommentSectionState.DEFAULT
  | CommentSectionState.PREMIUM
  | CommentSectionState.DISABLED
  | CommentSectionState.LOCKED;
