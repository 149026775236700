import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import { useCopyToClipboard } from '@/hooks';
import { Filters } from '@/hooks/usePolls/usePostPollFeedback';
import { PollChoice } from '@/interfaces/poll';
import { ExtendedFeedback } from '@/interfaces/post_poll';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  feedback: ExtendedFeedback[];
  pollChoices: PollChoice[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  setFilters: (filters: Filters) => void;
  filters: Filters;
}

const Feedback = ({ feedback, fetchNextPage, hasNextPage, setFilters, filters, pollChoices }: Props) => {
  const copy = useCopyToClipboard();

  const truncatedStr = (str: string) => {
    if (str.length > 65) {
      return `${str.substring(0, 65)}...`;
    }
    return str;
  };

  const filterOptions = [
    {
      label: 'All responses',
      value: 'all',
    },
    ...pollChoices.map((choice) => ({
      label: choice.label,
      value: choice.id,
    })),
  ];

  const handleSelect = (_name: string, value: string) => {
    if (value === 'all') {
      setFilters({});
    } else {
      setFilters({ poll_choice_id: value });
    }
  };

  return (
    <div className="col-span-3 lg:col-span-1">
      <div className="mb-4 flex justify-start">
        <Dropdown
          className="w-full h-fit md:w-40"
          name="poll_choices"
          value={filterOptions.find((option) => option.value === filters.poll_choice_id)?.value || 'all'}
          onSelect={handleSelect}
          options={filterOptions}
        />
      </div>
      <div className="flow-root max-h-96 h-full overflow-y-scroll">
        {feedback.length !== 0 ? (
          <ul>
            {feedback.map((item, feedbackIdx: number) => (
              <li className="relative group">
                <div className="relative pb-8">
                  {feedbackIdx !== feedback.length - 1 ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    <div className="relative">
                      <img
                        className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                        src={item.gravatar_url}
                        alt=""
                      />

                      <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                        <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 space-y-2">
                      <div>
                        <div className="text-sm">
                          <a href={`/subscribers/${item.subscriber_id}`} className="font-medium text-gray-900">
                            {item.email}
                          </a>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">
                          Selected{' '}
                          <button type="button" onClick={() => setFilters({ poll_choice_id: item.poll_choice.id })}>
                            <Badge>{item.poll_choice.label}</Badge>
                          </button>{' '}
                          and wrote
                        </p>
                      </div>
                      <div className="text-sm text-gray-700">
                        <p>&ldquo;{item.extended_feedback}&rdquo;</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute top-0 right-0 opacity-0 group-hover:opacity-100 duration-200">
                  <EllipsisDropdown
                    options={[
                      {
                        label: 'Copy',
                        onClick: () => {
                          copy({ text: item.extended_feedback });
                        },
                      },
                      {
                        label: 'Reply',
                        onClick: () => {
                          window.location.href = `mailto:${item.email}?subject=Re:%20${truncatedStr(
                            item.extended_feedback
                          )}`;
                        },
                      },
                    ]}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 text-xs">No responses yet.</p>
        )}
      </div>
      <div className="flex">
        {hasNextPage && (
          <Button variant="primary-inverse" onClick={fetchNextPage} block>
            Load more
          </Button>
        )}
      </div>
    </div>
  );
};

export default Feedback;
