import { useQuery } from 'react-query';

import api from '@/services/swarm';

const usePausePlan = (organizationId: string) => {
  interface PausePlan {
    resumes_at: string;
  }

  const fetchPausePlan = () =>
    api.get(`/organizations/${organizationId}/pause_plan`).then((res) => res.data);

  return useQuery<PausePlan>(['pause_plan', organizationId], fetchPausePlan, {
    keepPreviousData: true,
  });
};

export default usePausePlan;
