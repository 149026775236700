import { useMemo, useState } from 'react';

import { TypeaheadMultiSelect } from '@/components/Form';
import SelectedOptionBadges from '@/components/Form/SelectedOptionBadges';
import useOptions from '@/hooks/useOptions';
import { Option } from '@/interfaces/general';
import { Publication } from '@/interfaces/publication';
import { Tag } from '@/interfaces/tag';
import { normalizeString } from '@/utils';

interface Props {
  publicationId: Publication['id'];
  publicationTags: Publication['tags'];
  onChange: (tagIds: string[]) => void;
  isDisabled: boolean;
}

const SelectTags = ({ publicationId, publicationTags, onChange, isDisabled }: Props) => {
  const { data: tagsData, isSuccess } = useOptions(publicationId, 'tags');
  const tagOptions: Tag[] = useMemo(
    () => (!isSuccess ? [] : tagsData?.options.map((tag: [number, string]) => ({ id: tag[0], name: tag[1] }))),
    [tagsData, isSuccess]
  );

  const [query, setQuery] = useState('');
  const [currentSelection, setCurrentSelection] = useState(publicationTags.map((tag) => String(tag.id)) || []);

  const handleOnClear = () => {
    setQuery('');
  };

  const handleDeselect = (_: string, value: string) => {
    const newCurrentSelection = currentSelection.filter((item) => String(item) !== value);
    setCurrentSelection(newCurrentSelection);

    onChange(newCurrentSelection);
  };

  const handleSearch = (): Promise<Option<string>[]> => {
    const options =
      tagOptions
        ?.filter((tag: Tag) => normalizeString(tag.name).includes(query))
        .map((tag: Tag) => ({ label: tag.name, value: String(tag.id) })) || [];

    return new Promise((resolve) => {
      resolve(options);
    });
  };

  const handleSearchQueryChange = (newQuery: string) => setQuery(normalizeString(newQuery));

  const handleSelect = (name: string, value: string) => {
    const newCurrentSelection = [...currentSelection, value];

    setCurrentSelection(newCurrentSelection);
    onChange(newCurrentSelection);
  };

  const handleDeselectViaBadge = (value: string) => {
    handleDeselect('', value);
  };

  const tagNameById = useMemo(() => {
    const mappedTags: {
      [key: string]: string;
    } = {};
    tagOptions?.forEach((tag: Tag) => {
      mappedTags[tag.id] = tag.name;
    });

    return mappedTags;
  }, [tagOptions]);

  const selectedOptions: Option<string>[] = useMemo(
    () =>
      currentSelection.map((tagId: string) => ({
        label: tagNameById[tagId],
        value: tagId,
      })),
    [currentSelection, tagNameById]
  );

  if (!isSuccess) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-y-2">
        <TypeaheadMultiSelect
          disabled={isDisabled}
          emptyLabel="No tags found"
          helperText="Choose up to 3 tags to describe your publication"
          labelText="Publication Tags"
          name="publication-tags-multiselect"
          onClear={handleOnClear}
          onDeselect={handleDeselect}
          onSearch={handleSearch}
          onSearchQueryChange={handleSearchQueryChange}
          onSelect={handleSelect}
          placeholderText="Select Tag(s)"
          values={currentSelection}
          showClearAll={false}
          shouldCloseOnSelection={false}
        />

        <SelectedOptionBadges options={selectedOptions} onDeselect={handleDeselectViaBadge} />
      </div>
    </div>
  );
};

export default SelectTags;
