import { PartnerCurrentTier, PartnerNextTier, PartnerTierSetting } from '@/interfaces/partner_tier';
import { CurrentBox, FinishedBox, UpcomingBox } from '@/pages/PartnerProgram/PartnerTiers';

interface Params {
  settings?: PartnerTierSetting[],
  tiers?: {
    current: PartnerCurrentTier;
    next?: PartnerNextTier;
  };
}

const PartnerProgramTiers = ({ tiers, settings }: Params) => {
  if (!tiers || !settings) return null;

  const finishedBoxesSettings = settings.filter((setting) => setting.threshold !== 0 && setting.threshold < tiers.current.current_count && setting.name !== tiers.current.name);
  const currentBoxSettings = settings.find((setting) => setting.threshold !== 0 && setting.name === tiers.current.name);
  const upcomingBoxesSettings = settings.filter((setting) => setting.threshold !== 0 && setting.name !== tiers.current.name && setting.threshold > tiers.current.current_count);

  return (
    <div className="h-[341.53px] p-6 bg-white rounded-md border border-gray-200 flex-col justify-start items-start gap-6 inline-flex">
      <div className="self-stretch justify-start items-start gap-6 inline-flex">
        {finishedBoxesSettings.map((setting) => (
          <FinishedBox tierSetting={setting} />
        ))}
        {currentBoxSettings && (
          <CurrentBox
            tierSetting={currentBoxSettings}
            tierData={tiers.current}
            nextGoal={upcomingBoxesSettings.length > 0 && upcomingBoxesSettings[0].threshold}
          />
        )}
        {upcomingBoxesSettings.map((setting) => (
          <UpcomingBox tierSetting={setting} tierData={tiers.next} />
        ))}
      </div>
    </div>
  );
};

export default PartnerProgramTiers;
