import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import styled from 'styled-components';

import { Button } from '../../../../../ui/Button';
import { Icon } from '../../../../../ui/Icon';
import { InputField } from '../../../../../ui/Input';
import { PanelHeadline, PanelSection } from '../../../../../ui/Panel';
import { convertToSection } from '../../../../utils';
import useVisualSettingsPanel from '../VisualSettingsPanel.hooks';

const Styled = {
  Grid: styled.div`
    display: flex;
    gap: 0.25rem;
    padding-inline: 0.375rem;
  `,
  IndividualPaddingSwitch: styled.div`
    flex: 0 0 auto;
  `,
  InputGroup: styled.div`
    display: grid;
    flex: 1 1 auto;
    gap: 0.25rem;
    grid-template-columns: repeat(2, 1fr);
  `,
  InputWrapper: styled.div<{ half?: boolean }>`
    flex: 1 1 auto;
  `,
};

export const Padding = ({
  editor,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  currentNode: Node | null;
  currentNodePos: number;
}) => {
  const [padding, setPadding] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  const { onSetPadding } = useVisualSettingsPanel(editor, currentNode, currentNodePos, () => {});

  useEffect(() => {
    setPadding({
      top: currentNode?.attrs.paddingTop || 0,
      bottom: currentNode?.attrs.paddingBottom || 0,
      right: currentNode?.attrs.paddingRight || 0,
      left: currentNode?.attrs.paddingLeft || 0,
    });
  }, [
    currentNode?.attrs.paddingTop,
    currentNode?.attrs.paddingBottom,
    currentNode?.attrs.paddingRight,
    currentNode?.attrs.paddingLeft,
  ]);

  const handlePaddingChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPadding({
      top: Number(e.target.value) || 0,
      bottom: Number(e.target.value) || 0,
      right: Number(e.target.value) || 0,
      left: Number(e.target.value) || 0,
    });
  }, []);

  const toggleIndividualPadding = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);

    editor.chain().toggleIndividualSectionPadding().run();
  }, [editor, currentNode, currentNodePos]);

  const updatePadding = useCallback(() => {
    onSetPadding(padding);
  }, [padding]);

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, newPadding: any) => {
      event.stopPropagation();

      if (event.key === 'Enter') {
        onSetPadding({ ...padding, ...newPadding });
      }

      return false;
    },
    [padding]
  );

  return (
    <PanelSection>
      <PanelHeadline>Inner spacing</PanelHeadline>
      <Styled.Grid>
        <Styled.IndividualPaddingSwitch>
          <Button
            $isIconButton
            $active={currentNode?.attrs.useIndividualPadding}
            $muted
            type="button"
            onClick={toggleIndividualPadding}
            $size="small"
            $variant="quaternary"
            $leftSlot={<Icon name="Padding" />}
          />
        </Styled.IndividualPaddingSwitch>
        {currentNode?.attrs.useIndividualPadding ? (
          <Styled.InputGroup>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setPadding({ ...padding, left: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { left: Number(e.currentTarget.value) })}
                onBlur={updatePadding}
                type="number"
                icon="PaddingLeft"
                suffix="px"
                value={padding.left.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setPadding({ ...padding, right: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { right: Number(e.currentTarget.value) })}
                onBlur={updatePadding}
                type="number"
                icon="PaddingRight"
                suffix="px"
                value={padding.right.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setPadding({ ...padding, top: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { top: Number(e.currentTarget.value) })}
                onBlur={updatePadding}
                type="number"
                icon="PaddingTop"
                suffix="px"
                value={padding.top.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setPadding({ ...padding, bottom: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { bottom: Number(e.currentTarget.value) })}
                onBlur={updatePadding}
                type="number"
                icon="PaddingBottom"
                suffix="px"
                value={padding.bottom.toString()}
              />
            </Styled.InputWrapper>
          </Styled.InputGroup>
        ) : (
          <Styled.InputWrapper>
            <InputField
              min="0"
              onChange={handlePaddingChange}
              onKeyUp={(e) =>
                handleKeyUp(e, {
                  top: Number(e.currentTarget.value) || 0,
                  bottom: Number(e.currentTarget.value) || 0,
                  right: Number(e.currentTarget.value) || 0,
                  left: Number(e.currentTarget.value) || 0,
                })
              }
              onBlur={updatePadding}
              type="number"
              suffix="px"
              value={padding.top.toString()}
            />
          </Styled.InputWrapper>
        )}
      </Styled.Grid>
    </PanelSection>
  );
};

export default Padding;
