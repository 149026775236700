import { useNavigate } from 'react-router-dom';

import BoostsFrozenBanner from '@/components/Boosts/BooostsFrozenBanner';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import LoadingBox from '@/components/LoadingBox';
import { useRequireSetting } from '@/hooks';
import useBoostOffers from '@/hooks/boosts/grow/useBoostOffers';
import useStripeCustomer from '@/hooks/boosts/grow/useStripeCustomer';
import useDismissable, { DismissableKey } from '@/hooks/useDismissable';
import { useTutorial } from '@/hooks/useTutorials';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';

import ExplainMetrics from './components/ExplainMetrics';
import IntroModal from './components/IntroModal/IntroModal';
import Tabs from './components/Tabs';
import TopLineMetrics from './components/TopLineMetrics';

const BoostsOverview = () => {
  const tutorial = useTutorial(TutorialType.BOOSTS_GROW);
  const navigate = useNavigate();
  const { data: boostOffers, isLoading, isError } = useBoostOffers({ search: '' });
  const { wasDismissed, onDismiss } = useDismissable(DismissableKey.INTRODUCTION_BLOCK_GROW_VIA_BOOSTS);

  const { data: stripeCustomer, isLoading: isStripeCustomerLoading } = useStripeCustomer();

  useRequireSetting({
    setting: Setting.BOOSTS,
    message: 'Upgrade to use boosts.',
    redirectTo: '/grow/boosts/overview',
  });

  // Prevent page flashes when checking if stripe customer is connected
  if (isStripeCustomerLoading) return null;
  if (!isStripeCustomerLoading && !stripeCustomer?.connectedToStripeBoosts) {
    navigate('introduction');
  }

  const hasBoostOffers = (boostOffers?.pages.flatMap((page) => page.boost_offers) || []).length > 0;
  const shouldShowIntroModal = !wasDismissed && !hasBoostOffers && !isLoading;

  return (
    <>
      <IntroModal isOpen={shouldShowIntroModal} onClose={onDismiss} />
      <LoadingBox isLoading={isLoading} isError={isError}>
        <div className="flex flex-col gap-y-8">
          <BoostsFrozenBanner />
          <PageHeading
            title="Grow with Boosts"
            description="Leverage the beehiiv network to grow your audience."
            tutorial={tutorial}
            cta={<ExplainMetrics />}
          />
          <TopLineMetrics />
          <Tabs />
        </div>
      </LoadingBox>
    </>
  );
};

export default BoostsOverview;
