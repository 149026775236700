import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

import { SortableListItem } from '@/components/SortableList';
import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useUpdateNavGroup } from '@/hooks/useNavGroups';
import { NavGroup } from '@/interfaces/nav_group';
import { LayoutPageData } from '@/routes/website_builder';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import OptionsDropdown from '@/routes/website_builder/_components/Helpers/OptionsDropdown';
import CategoriesGroup from '@/routes/website_builder/custom_pages/_components/MenuBuilder/CustomMenu/MenuGroups/CategoriesGroup';

import NavGroupForm from './NavGroupForm';
import SortableNavLinks from './SortableNavLinks';

interface Props {
  navGroup: NavGroup;
  setNavGroupToDelete: (navGroup: NavGroup) => void;
}

const SortableNavGroup = ({ navGroup, setNavGroupToDelete }: Props) => {
  const { handleSaved } = usePageContext<LayoutPageData>();
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const [title, setTitle] = useState(navGroup.title);
  const [isEditing, setIsEditing] = useState(false);
  const isCategoryGroup = navGroup?.is_category_group;
  const hasOneItem = !isCategoryGroup && navGroup?.nav_links?.length === 1;

  const navLinks = navGroup?.nav_links || [];
  const hasNoNavLinks = navLinks.length === 0 && !isCategoryGroup;

  const updateForm = useUpdateNavGroup({
    navGroupId: navGroup?.id || '',
    onSuccess: () => {
      toast.success('Updated!');
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      setIsEditing(false);
      handleSaved();
    },
  });

  return (
    <>
      <SortableListItem
        listItem={navGroup}
        text={title}
        onRemoveItem={() => {}}
        actionChild={
          <OptionsDropdown
            options={[
              {
                Icon: PencilIcon,
                label: 'Edit',
                onClick: () => {
                  setIsEditing(true);
                },
              },
              {
                Icon: TrashIcon,
                label: 'Delete',
                onClick: () => {
                  setNavGroupToDelete(navGroup);
                },
              },
            ]}
          />
        }
      >
        {hasNoNavLinks && (
          <Typography token="font-normal/text/xs" colorWeight="500" as="p">
            *Add one or more links for this dropdown to be visible in navigation bar
          </Typography>
        )}

        <div>{isCategoryGroup ? <CategoriesGroup /> : <SortableNavLinks navLinks={navLinks} />}</div>
        {hasOneItem && (
          <div className="mt-2">
            <Typography token="font-normal/text/xs" colorWeight="500" as="p">
              * When only one item is set in the group, it will be displayed as a single link in the navigation bar and
              not as a dropdown
            </Typography>
          </div>
        )}
      </SortableListItem>

      <NavGroupForm
        bodyId={navGroup.id || ''}
        title={title}
        isOpen={isEditing}
        headerText="Edit Dropdown"
        ctaText="Update"
        loadingText="Updating..."
        handleTitleChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
        isLoading={updateForm.isLoading}
        handleSubmit={() => {
          updateForm.mutate({ title });
          handleSaved();
        }}
        handleClose={() => {
          setTitle(navGroup.title);
          setIsEditing(false);
        }}
      />
    </>
  );
};

export default SortableNavGroup;
