import { useMemo } from 'react';
import { Selection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';

import { useThemeData } from '../../../lib/hooks/useThemeData';

export const useSelection = (editor: Editor, selection: Selection, themeColors: any[]) => {
  const fontFamilies = useThemeData('font-families');

  const { isDefaultFontFamily, isDefaultFontSize, isDefaultColor, isCustomColor, isDisabled, hasOverrides } =
    useMemo(() => {
      const textStyleAttributes = editor.getAttributes('textStyle');

      const currentFontFamily = fontFamilies.find((fontFamily) => {
        return editor.isActive('textStyle', { fontFamily: fontFamily.value });
      });

      const newIsDefaultFontFamily = !textStyleAttributes.fontFamily || !currentFontFamily;

      const newIsDefaultFontSize = !textStyleAttributes.fontSize;
      const newIsDefaultColor = !textStyleAttributes.color;

      const newIsCustomColor = newIsDefaultColor
        ? false
        : !themeColors.find((themeColor) => {
            return themeColor.value === textStyleAttributes.color;
          });

      const newIsDisabled = !(editor.can().setMark('textStyle') && selection);
      const newHasOverrides = !newIsDefaultFontFamily || !newIsDefaultFontSize || !newIsDefaultColor;

      return {
        isDefaultFontFamily: newIsDefaultFontFamily,
        isDefaultFontSize: newIsDefaultFontSize,
        isDefaultColor: newIsDefaultColor,
        isCustomColor: newIsCustomColor,
        isDisabled: newIsDisabled,
        hasOverrides: newHasOverrides,
      };
    }, [editor, selection, themeColors, fontFamilies]);

  return {
    isDefaultFontFamily,
    isDefaultFontSize,
    isDefaultColor,
    isCustomColor,
    isDisabled,
    hasOverrides,
  };
};

export default useSelection;
