import moment from 'moment-mini';

import { BadgeProps } from '@/ui/Badge';

const useBoostSendStatus = (status: string, type: BadgeProps['type'], label: string, sendBy?: string | Date) => {
  const expired = moment(sendBy).isBefore();

  if (expired && status !== 'canceled') {
    return {
      type: 'information' as BadgeProps['type'],
      label: 'Ended',
    };
  }

  return {
    type,
    label,
  };
};

export default useBoostSendStatus;
