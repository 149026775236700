import { FC, useEffect, useState } from 'react';
import { TestimonialsElement, TTestimonialAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import NotConnected from '../../components/NotConnected';
import { getDataAttributes } from '../../utils';

export const TestimonialsView: FC<NodeViewProps> = (nodeViewProps) => {
  const { HTMLAttributes, node, editor, getPos } = nodeViewProps;
  const { onDragStart } = useReactNodeView();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { insertedFromSidebar, hasFakeData } = node.attrs;

  useEffect(() => {
    if (insertedFromSidebar && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [insertedFromSidebar, isModalOpen]);

  const handleOpenSelectionModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
    setIsModalOpen(true);
  };

  return (
    <TestimonialsElement
      element={{
        type: 'testimonials',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        } as TTestimonialAttributes,
        content: [{ id: 'text', text: '' }],
      }}
      onDragStart={onDragStart}
      attributes={{
        ...getDataAttributes(HTMLAttributes),
        'data-node-view-wrapper': '',
      }}
    >
      {null}
      {hasFakeData && <NotConnected text="Not connected to real testimonials" onClick={handleOpenSelectionModal} />}
    </TestimonialsElement>
  );
};
