/* eslint-disable no-console */

import { useState } from 'react';

export default function useLocalStorage<T>(initialValue: T, key?: string) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (!key) return initialValue;

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T | ((prevValue: T) => T)) => {
    if (!key) {
      setStoredValue(value);
      return;
    }

    try {
      setStoredValue((prevValue) => {
        const valueToStore = value instanceof Function ? value(prevValue) : value;
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        return valueToStore;
      });
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue] as const;
}
