import { memo, useCallback } from 'react';

import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Label } from '@/components/UI/Label';
import { Button } from '@/ui/Button';

export type OrientationInputProps = {
  valueX: number;
  valueY: number;
  onChangeX: (value: number) => void;
  onChangeY: (value: number) => void;
};

const FlipHorizontalIcon = () => <Icon $size=".875rem" name="FlipHorizontal" />;
const FlipVerticalIcon = () => <Icon $size=".875rem" name="FlipVertical" />;

export const OrientationInput = memo(({ valueX, valueY, onChangeX, onChangeY }: OrientationInputProps) => {
  const handleHorizontalFlip = useCallback(() => {
    onChangeX(valueX * -1);
  }, [onChangeX, valueX]);

  const handleVerticalFlip = useCallback(() => {
    onChangeY(valueY * -1);
  }, [onChangeY, valueY]);

  return (
    <div className="flex flex-col gap-y-1.5">
      <Label text="Orientation" />
      <div className="flex gap-x-1 items-start">
        <Button
          type="button"
          onClick={handleHorizontalFlip}
          variant="primary-inverse"
          Icon={FlipHorizontalIcon}
          size="xxs"
        >
          Flip horizontal
        </Button>
        <Button type="button" onClick={handleVerticalFlip} variant="primary-inverse" Icon={FlipVerticalIcon} size="xxs">
          Flip vertical
        </Button>
      </div>
    </div>
  );
});

OrientationInput.displayName = 'OrientationInput';
