import { HardDrive } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';

type Props = AttributeSettingProps & {};

export const ButtonSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Button" text="Button" popoverTitle="Button" Icon={HardDrive}>
      <>
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          property="buttonTextFontFamily"
          title="Font"
        />
        <ColorSettings title="Background" editor={editor} activeNodeResult={activeNodeResult} property="buttonColor" />
        <ColorSettings title="Text" editor={editor} activeNodeResult={activeNodeResult} property="buttonTextColor" />
      </>
    </PopoverHelper>
  );
};

export default ButtonSettings;
