import AppliedFilter from '../AppliedFilter';
import { AttributeCondition, TagOperatorsList } from '../types';

const TagFilterApplied = ({ condition, onRemove }: { condition: AttributeCondition; onRemove: () => void }) => {
  const operator = TagOperatorsList.find((o) => o.value === condition.filters.operator);
  if (!operator) return null;

  const operatorLabel = operator.label;

  return <AppliedFilter name="Tags" label={operatorLabel} onRemove={onRemove} value={condition.filters.value} />;
};

export default TagFilterApplied;
