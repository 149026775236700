import { useCallback, useState } from 'react';
import { TagIcon, XMarkIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Input } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import Badge from '@/ui/Badge';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import useApplyPromotionCode, { StripePromotionCode } from '../../../_hooks/useApplyPromotionCode';

interface Props {
  organizationId: string;
  publicationId: string;
  planPriceId: string;
  appliedDiscount?: StripePromotionCode;
  onApply: (promotionCode?: StripePromotionCode) => void;
}

const PromotionCodeField: React.FC<Props> = ({
  organizationId,
  publicationId,
  planPriceId,
  appliedDiscount,
  onApply,
}) => {
  const applyCode = useApplyPromotionCode({ organizationId, publicationId, planPriceId });
  const { currentUser } = useCurrentUser();

  const [showField, setShowField] = useState(false);
  const [errMessage, setErrMessage] = useState<string>();
  const [promoCode, setPromoCode] = useState<string>();

  const apply = useCallback(() => {
    applyCode.mutate(
      { promoCode },
      {
        onSuccess: (promotionCode) => {
          if (currentUser) {
            analytics.identify(currentUser.id);
            analytics.track('Added Promo Code', {
              coupon: promotionCode.code,
              amount_off: promotionCode.amount_off,
              percent_off: promotionCode.percent_off,
              duration: promotionCode.duration,
              duration_in_months: promotionCode.duration_in_months,
            });
          }

          setShowField(false);
          setErrMessage(undefined);
          setPromoCode(undefined);
          onApply(promotionCode);
        },
        onError: (err: any) => setErrMessage(err?.response?.data?.error || 'This code is invalid'),
      }
    );
  }, [applyCode, currentUser, onApply, promoCode]);

  if (appliedDiscount) {
    return (
      <div className="flex justify-end space-x-1 items-start">
        <TypographyStack className="items-end">
          <Badge Icon={TagIcon} type="information" className="!bg-surface-200 w-fit">
            {appliedDiscount.code}
          </Badge>
          <Typography token="font-light/text/xs" colorWeight="500">
            {appliedDiscount.description}
          </Typography>
        </TypographyStack>
        <Button
          size="xs"
          variant="flush"
          type="button"
          Icon={XMarkIcon}
          className="!p-1 !rounded-full"
          onClick={() => onApply(undefined)}
        />
      </div>
    );
  }

  return (
    <div className={cx('flex justify-end', !showField && 'py-1')}>
      {showField ? (
        <Input
          name="promotion_code"
          className="max-w-[16rem] -mr-1"
          inputClassName="text-xs"
          placeholderText="Promotion Code"
          value={promoCode}
          onChange={(e) => {
            setPromoCode(e.target.value);
            setErrMessage(undefined);
          }}
          onBlur={() => (!promoCode || promoCode === '') && setShowField(false)}
          errorText={errMessage}
          trailingIcon={
            <Button
              size="xxs"
              variant="flush"
              type="button"
              className={cx(!promoCode ? 'opacity-0' : 'opacity-100', 'transition-opacity duration-300')}
              onClick={apply}
              loading={applyCode.isLoading}
            >
              Apply
            </Button>
          }
        />
      ) : (
        <Button size="xxs" variant="flush" type="button" className="-mr-3" onClick={() => setShowField(true)}>
          Add Promotion Code
        </Button>
      )}
    </div>
  );
};

export default PromotionCodeField;
