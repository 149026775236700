import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useDisconnectStripe = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(() => api.patch(`/publications/${currentPublicationId}/disconnect_stripe`, {}), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      toast.success('We are processing your request!');
      return queryClient.invalidateQueries(['premium_settings', 'publications', currentPublicationId]);
    },
  });
};

export default useDisconnectStripe;
