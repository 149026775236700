import { RecommendationsElement, TRecommendationAttributes } from '@shared/dream-components';

import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { GapSettings } from '../GapSettings';
import { GridColumnSetting } from '../GridColumnSetting';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';
import { SimpleNumberSettings } from '../SimpleNumberSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';
import { StructureSettings } from '../StructureSettings';

export const RecommendationsSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNode } = activeNodeResult;

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['recommendations']}>
      <SectionRenderer title="Layout">
        <GridColumnSetting editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>
      {activeNodeResult.activeNodeAttributes.isFullHeight !== true && (
        <SectionRenderer title="List">
          <SimpleNumberSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="listHeight"
            title="Height"
            unit="px"
            max={1200}
          />
        </SectionRenderer>
      )}
      <SectionRenderer title="Structure">
        <StructureSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          allowedProperties={['div', 'image', 'name', 'description', 'checkbox', 'sponsored']}
          predefinedPatterns={[]}
        >
          {(structure: any) => (
            <RecommendationsElement
              element={{
                type: 'recommendations',
                attrs: {
                  ...(activeNode?.attrs as TRecommendationAttributes),
                  cardStructure: structure as any,
                  columns: '1',
                  // We only need one testimonial to display the structure
                  data: activeNode?.attrs?.data?.slice(0, 1),
                },
                content: [{ id: 'text', text: '' }],
              }}
            >
              {null}
            </RecommendationsElement>
          )}
        </StructureSettings>
      </SectionRenderer>
      <SectionRenderer title="Typography">
        <FontSettings editor={editor} activeNodeResult={activeNodeResult} isMarkStyle={false} />
      </SectionRenderer>
      <SectionRenderer title="Cards">
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings
          title="Background"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="backgroundColor"
        />
        <ColorSettings title="Name" editor={editor} activeNodeResult={activeNodeResult} property="nameColor" />
        <ColorSettings
          title="Description"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="descriptionColor"
        />
      </SectionRenderer>
      <SectionRenderer title="Form">
        {activeNodeResult.activeNodeAttributes.disablePopupFormSetting !== true && (
          <>
            <BooleanSettings editor={editor} activeNodeResult={activeNodeResult} property="isPopupForm" title="Popup" />
            <ColorSettings
              title="Popup Background"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="formBackgroundColor"
            />
            <ShadowSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="formShadow"
              title="Popup shadow"
            />
            <PaddingSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="formPadding"
              title="Popup padding"
            />
            <BorderSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              title="Popup Border"
              properties={{
                color: 'formBorderColor',
                style: 'formBorderStyle',
                width: 'formBorderWidth',
                radius: 'formBorderRadius',
              }}
            />
          </>
        )}
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Input Border"
          properties={{
            color: 'inputBorderColor',
            style: 'inputBorderStyle',
            width: 'inputBorderWidth',
            radius: 'inputBorderRadius',
          }}
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Input Text"
          property="inputTextColor"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Input Background"
          property="inputBackgroundColor"
        />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="buttonText"
          title="Button Text"
        />
        <BorderSettings
          title="Button Border"
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'buttonBorderColor',
            style: 'buttonBorderStyle',
            width: 'buttonBorderWidth',
            radius: 'buttonBorderRadius',
          }}
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Button Text"
          property="buttonCustomTextColor"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Button Background"
          property="buttonCustomBackgroundColor"
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
