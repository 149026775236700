import { Typography } from '@/components/Typography';
import { useSetting } from '@/hooks';
import { Setting } from '@/interfaces/setting';
import { capitalize } from '@/utils';

import MaxPlan from './Plans/Max';
import ScalePlan from './Plans/Scale';

interface Props {
  trial?: any;
}

const planFeatures = ({ trial, tieredPricing }: { trial: any; tieredPricing: boolean }) => {
  switch (trial?.selected_plan) {
    case 'scale':
      return <ScalePlan hasPrioritySupport={!tieredPricing} />;
    case 'max':
      return <MaxPlan hasPrioritySupport={!tieredPricing} />;
    default:
      return <ScalePlan hasPrioritySupport={!tieredPricing} />;
  }
};

const TrialScreen2 = ({ trial }: Props) => {
  const tieredPricing = useSetting({ setting: Setting.TIERED_PRICING, defaultValue: false });

  const PlanSettings: JSX.Element = planFeatures({ trial, tieredPricing: !!tieredPricing });

  return (
    <div className="border-t border-surface-200 pt-4 space-y-4">
      <Typography size="lg" weight="bold">
        {capitalize(trial?.selected_plan || 'scale')} Plan Feature List
      </Typography>
      {PlanSettings}
    </div>
  );
};

export default TrialScreen2;
