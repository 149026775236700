import PageContainer from '../../_components/PageContainer';
import Billing from '../../billing';

const WorkspaceBillingAndPlan = () => {
  return (
    <PageContainer>
      <Billing />
    </PageContainer>
  );
};

export default WorkspaceBillingAndPlan;
