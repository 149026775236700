import { AutomationStep, AutomationStepStepType } from '@/interfaces/automations/automation_step';

const isDelayStep = (automationStep?: AutomationStep) => {
  if (!automationStep) {
    return false;
  }

  return [AutomationStepStepType.WAIT_FOR, AutomationStepStepType.WAIT_UNTIL].includes(automationStep.step_type);
};

export default isDelayStep;
