import { Edge, Node } from 'reactflow';

import { Automation } from '@/interfaces/automations/automation';
import { AutomationStep, AutomationStepStepType } from '@/interfaces/automations/automation_step';

import { makeNewAddAutomationStepNode } from '../hooks/makeNewAddAutomationStepNode';
import { CustomNodeDataType } from '../types';

import { translateAutomationStepsToNodes } from './translateAutomationStepToNode';

export const ROOT_NODE_TYPE = 'root';
export const ROOT_NODE_ID = 'root-node';
export const TRIGGERS_NODE_TYPE = 'triggers';
export const TRIGGERS_NODE_ID = 'triggers-node';
export const REFERRING_AUTOMATIONS_NODE_TYPE = 'referringAutomations';
export const REFERRING_AUTOMATIONS_NODE_ID = 'referring-automations-node';
export const CUSTOM_NODE_TYPE = 'custom';

const makeEdges = (nodes: Node[]) => {
  const edges: Edge[] = [];
  const triggersNode = nodes.find((node) => node.type === TRIGGERS_NODE_TYPE);
  const referringAutomationsNode = nodes.find((node) => node.type === REFERRING_AUTOMATIONS_NODE_TYPE);
  const stepNodes = nodes.filter((node) => node.type === AutomationStepStepType.AUTOMATION_STEP) || [];
  const addStepNodes = nodes.filter((node) => node.type === AutomationStepStepType.ADD_AUTOMATION_STEP) || [];
  const rootStepNode =
    nodes.find((node) => node.type === AutomationStepStepType.AUTOMATION_STEP && !node.parentNode) || addStepNodes[0];

  if (!rootStepNode) {
    throw new Error('rootStepNode node must exist!');
  }

  if (!triggersNode) {
    throw new Error('triggersNode node must exist!');
  }

  // Root Node -> Triggers Node
  edges.push({
    id: `${ROOT_NODE_ID}=>${TRIGGERS_NODE_ID}`,
    source: ROOT_NODE_ID,
    target: TRIGGERS_NODE_ID,
    hidden: true,
  });

  if (referringAutomationsNode) {
    // Root Node -> Referring Automations Node
    edges.push({
      id: `${ROOT_NODE_ID}=>${REFERRING_AUTOMATIONS_NODE_ID}`,
      source: ROOT_NODE_ID,
      target: REFERRING_AUTOMATIONS_NODE_ID,
      hidden: true,
    });

    // Referring Automations Node -> Trigger Node
    edges.push({
      id: `${REFERRING_AUTOMATIONS_NODE_ID}=>${triggersNode.id}`,
      source: REFERRING_AUTOMATIONS_NODE_ID,
      target: triggersNode.id,
    });

    // Referring Automations Node -> Root Step Node
    edges.push({
      id: `${REFERRING_AUTOMATIONS_NODE_ID}=>${rootStepNode.id}`,
      source: REFERRING_AUTOMATIONS_NODE_ID,
      target: rootStepNode.id,
      type: CUSTOM_NODE_TYPE,
    });
  }

  // Trigger Node -> Root Step Node
  edges.push({
    id: `${triggersNode.id}=>${rootStepNode.id}`,
    source: triggersNode.id,
    target: rootStepNode.id,
    type: CUSTOM_NODE_TYPE,
  });

  // Step Group
  const branchNodes = stepNodes.filter((node) => node.data.stepType === AutomationStepStepType.BRANCH);
  const branchlessNodes = nodes.filter((node) => !branchNodes.find((branchNode) => branchNode.id === node.parentNode));

  // Add edges between each branch node and its children
  branchNodes.forEach((branchNode) => {
    nodes
      .filter((node) => node.parentNode === branchNode.id)
      .forEach((node) => {
        edges.push({
          id: `${branchNode.id}=>${node.id}`,
          source: branchNode.id || '',
          target: node.id,
          data: {
            label: node.data.branchArm,
          },
          type: CUSTOM_NODE_TYPE,
        });
      });
  });

  // Add edges between each step node that isn't a child of a branch node
  branchlessNodes
    .filter((node) => node.type === AutomationStepStepType.AUTOMATION_STEP && !!node.parentNode)
    .forEach((node: Node) => {
      edges.push({
        id: `${node.parentNode}=>${node.id}`,
        source: node.parentNode || '',
        target: node.data.id,
        type: CUSTOM_NODE_TYPE,
      });
    });

  branchlessNodes
    .filter((node) => node.type === AutomationStepStepType.ADD_AUTOMATION_STEP)
    .forEach((node) => {
      edges.push({
        id: node.parentNode ? `${node.parentNode}=>${node.id}` : `${triggersNode.id}=>${node.id}`,
        source: node.parentNode || '',
        target: node.id,
        type: CUSTOM_NODE_TYPE,
      });
    });

  return edges;
};

const getNodesAndEdges = (
  automation: Automation,
  automationSteps: AutomationStep[] = []
): {
  nodes: Node<CustomNodeDataType>[];
  edges: Edge[];
} => {
  const nodes: Node[] = [];

  // Root Node
  nodes.push({
    id: ROOT_NODE_ID,
    data: { automationId: automation.id },
    position: { x: 0, y: 0 },
    hidden: true,
  });

  // Triggers Node
  nodes.push({
    id: TRIGGERS_NODE_ID,
    type: TRIGGERS_NODE_TYPE,
    parentNode: ROOT_NODE_ID,
    data: { automationId: automation.id },
    position: { x: 0, y: 0 },
    hidden: false,
  });

  // Referring Automations Node
  if (automation.referring_automation_steps.length > 0) {
    nodes.push({
      id: REFERRING_AUTOMATIONS_NODE_ID,
      type: REFERRING_AUTOMATIONS_NODE_TYPE,
      parentNode: ROOT_NODE_ID,
      data: {},
      position: { x: 0, y: 0 },
      hidden: false,
    });
  }

  // Step Group
  // Add the automation step nodes
  if (automationSteps.length > 0) {
    const stepNodes = translateAutomationStepsToNodes(automationSteps);

    stepNodes.forEach((node) => {
      nodes.push({
        hidden: false,
        ...node,
      });

      // Add the addStep node if there are no children and it's not a branch node (branches are handled below)
      const hasChildNodes = stepNodes.find((stepNode) => stepNode.parentNode === node.id);
      if (!hasChildNodes && node.data.stepType !== AutomationStepStepType.BRANCH) {
        const newNode = makeNewAddAutomationStepNode({
          parentNode: node.id,
          branchId: node.data.branchId,
          branchArm: node.data.branchArm,
        });
        nodes.push({
          hidden: false,
          ...newNode,
        });
      }
    });

    // For each arm of each branch node that has no nodes, add an addStep node.
    const branchNodes = nodes.filter((node) => node.data.stepType === AutomationStepStepType.BRANCH);

    branchNodes.forEach((branchNode) => {
      ['yes', 'no'].forEach((branchArm) => {
        const branchArmNode = stepNodes.find(
          (stepNode) => stepNode.parentNode === branchNode.id && stepNode.data.branchArm === branchArm
        );

        if (!branchArmNode) {
          const newNode = makeNewAddAutomationStepNode({
            parentNode: branchNode.id,
            branchId: branchNode.data.branchId,
            branchArm,
          });
          nodes.push({
            hidden: false,
            ...newNode,
          });
        }
      });
    });
  } else {
    // If there's no root automation step yet, add a single addAutomationStep node
    const newNode = makeNewAddAutomationStepNode({});
    nodes.push({
      hidden: false,
      ...newNode,
    });
  }

  return {
    nodes,
    edges: makeEdges(nodes),
  };
};

export default getNodesAndEdges;
