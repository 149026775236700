import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { BackgroundSettings } from '../BackgroundSettings';
import { BooleanSettings } from '../BooleanSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SizingSettings } from '../SizingSettings';

import { ColumnOrderSettings } from './ColumnOrderSettings';
import { SimpleColumnCountInput } from './SimpleColumnCountInput';
import SliderGroupSettings from './SliderGroupSettings';

export function ColumnsSettings({ editor, activeNodeResult }: AttributeSettingProps) {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['columns']}>
      <SectionRenderer title="Background">
        <BackgroundSettings editor={editor} activeNodeResult={activeNodeResult} propertyPrefix="background" />
      </SectionRenderer>
      <SectionRenderer title="Layout">
        <SizingSettings editor={editor} />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="isFullHeight"
          title="Full Height"
        />
        <SimpleColumnCountInput editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
        <SliderGroupSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" title="Padding" />
        <ColumnOrderSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
}
