import ActionModal from '@/components/ActionModal';
import pluralize from '@/utils/pluralize';

export interface Props {
  applicationCount: number;
  isOpen: boolean;
  isWorking: boolean;
  onProceed: () => void;
  onClose: () => void;
}

const ConfirmBoostAgreementsBulkAcceptModal = ({ applicationCount, isOpen, isWorking, onProceed, onClose }: Props) => {
  return (
    <ActionModal
      resourceId="confirm-boost-agreemeents-bulk-accept"
      isOpen={isOpen}
      isWorking={isWorking}
      onClose={onClose}
      onProceed={onProceed}
      modalMessageType="info"
      headerText={`Accept ${pluralize('application', applicationCount)}`}
      descriptionText={`Are you sure you want to accept ${pluralize('this application', applicationCount, 'these applications', true)}?`}
      actionText="Yes, accept"
    />
  );
};

export default ConfirmBoostAgreementsBulkAcceptModal;
