import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from '@/services/swarm';

interface Variables {
  allSelected: boolean;
  selectedPublicationIds: string[];
  excludePublicationIds: string[];
  conditions: string;
}

interface ApiResponse {
  size: number;
  async: boolean;
}

export default function useCampaignOpportunityGroupOpportunitiesCreate({ id }: { id: string }) {
  const stagePublications = ({
    allSelected,
    selectedPublicationIds,
    excludePublicationIds,
    conditions,
  }: Variables): Promise<ApiResponse> =>
    api
      .post(`/ad_network/internal/campaign_opportunity_groups/${id}/opportunities`, {
        all_selected: allSelected,
        selected_publication_ids: selectedPublicationIds,
        exclude_publication_ids: excludePublicationIds,
        conditions,
      })
      .then((res) => res.data);

  return useMutation<ApiResponse, MutationErrorResponse, Variables>(stagePublications, {
    onSuccess: ({ size, async }) => {
      if (async) {
        toast.success(
          `${size} opportunities are being staged in the background. Please allow a few minutes before adding any additional opportunities.`
        );
      } else {
        toast.success(`${size} opportunities staged successfully`);
      }
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
