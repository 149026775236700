import { ReactNode } from 'react';

import { ColorPalette } from '../../interfaces/color_palette';

export interface ThemeFormProps {
  theme: PostTheme;
  onUpdate: (obj: PostTheme) => void;
  fonts?: [{ label: string; value: string }] | undefined;
  colorPalette?: ColorPalette | undefined;
  currentPublicationId?: string;
  children?: ReactNode;
}

export enum LineHeights {
  Less = 'Less',
  Normal = 'Normal',
  More = 'More',
}

export type LineHeight = keyof typeof LineHeights;

export interface PostTheme {
  padding?: string;
  margin_left?: string;
  margin_right?: string;
  margin_top?: string;
  margin_bottom?: string;
  border_width?: string;
  border_radius?: number;
  border_color?: string;
  background_color?: string;
  content_background_color?: string;
  max_width?: string;
  paragraph_font_size?: string;
  paragraph_font_family?: string;
  paragraph_padding_top?: string;
  paragraph_padding_bottom?: string;
  paragraph_color?: string;
  paragraph_line_height_size?: LineHeight;
  inline_link_color?: string;
  inline_link_italic?: string;
  inline_link_bold?: string;
  inline_link_underline?: string;
  inline_link_underline_color?: string;
  h1_font_size?: string;
  h1_font_family?: string;
  h1_font_color?: string;
  h1_font_weight?: string;
  h1_padding_top?: string;
  h1_padding_bottom?: string;
  h1_line_height_size?: LineHeight;
  h2_font_size?: string;
  h2_font_family?: string;
  h2_font_color?: string;
  h2_font_weight?: string;
  h2_padding_top?: string;
  h2_padding_bottom?: string;
  h2_line_height_size?: LineHeight;
  h3_font_size?: string;
  h3_font_family?: string;
  h3_font_color?: string;
  h3_font_weight?: string;
  h3_padding_top?: string;
  h3_padding_bottom?: string;
  h3_line_height_size?: LineHeight;
  h4_font_size?: string;
  h4_font_family?: string;
  h4_font_color?: string;
  h4_font_weight?: string;
  h4_padding_top?: string;
  h4_padding_bottom?: string;
  h4_line_height_size?: LineHeight;
  h5_font_size?: string;
  h5_font_family?: string;
  h5_font_color?: string;
  h5_font_weight?: string;
  h5_padding_top?: string;
  h5_padding_bottom?: string;
  h5_line_height_size?: LineHeight;
  h6_font_size?: string;
  h6_font_family?: string;
  h6_font_color?: string;
  h6_font_weight?: string;
  h6_padding_top?: string;
  h6_padding_bottom?: string;
  h6_line_height_size?: LineHeight;
  list_font_size?: string;
  list_font_family?: string;
  list_line_height_size?: LineHeight;
  list_padding_left?: string;
  list_padding_right?: string;
  list_padding_top?: string;
  list_padding_bottom?: string;
  unordered_list_style_type?: string;
  ordered_list_style_type?: string;
  list_item_spacing?: string;
  list_font_color?: string;
  quote_font_size?: string;
  quote_font_family?: string;
  quote_line_height_size?: LineHeight;
  quote_background_color?: string;
  quote_font_color?: string;
  quote_alignment?: string;
  quote_border_color?: string;
  quote_border_width?: string;
  quote_border_radius?: string;
  quote_byline_color?: string;
  quote_byline_alignment?: string;
  quote_byline_spacing?: string;
  quote_byline_font_size?: string;
  quote_byline_font_family?: string;
  quote_padding_left?: string;
  quote_padding_right?: string;
  quote_padding_top?: string;
  quote_padding_bottom?: string;
  quote_margin_left?: string;
  quote_margin_right?: string;
  quote_margin_top?: string;
  quote_margin_bottom?: string;
  quote_2_font_size?: string;
  quote_2_font_family?: string;
  quote_2_line_height_size?: LineHeight;
  quote_2_background_color?: string;
  quote_2_font_color?: string;
  quote_2_alignment?: string;
  quote_2_border_color?: string;
  quote_2_border_left_width?: string;
  quote_2_border_radius?: string;
  quote_2_byline_color?: string;
  quote_2_byline_alignment?: string;
  quote_2_byline_spacing?: string;
  quote_2_byline_font_size?: string;
  quote_2_byline_font_family?: string;
  quote_2_padding_left?: string;
  quote_2_padding_right?: string;
  quote_2_padding_top?: string;
  quote_2_padding_bottom?: string;
  quote_2_margin_left?: string;
  quote_2_margin_right?: string;
  quote_2_margin_top?: string;
  quote_2_margin_bottom?: string;
  quote_3_font_size?: string;
  quote_3_font_family?: string;
  quote_3_line_height_size?: LineHeight;
  quote_3_background_color?: string;
  quote_3_font_color?: string;
  quote_3_alignment?: string;
  quote_3_mark_color: string;
  quote_3_mark_alignment: string;
  quote_3_mark_spacing: string;
  quote_3_mark_font_size: string;
  quote_3_mark_font_family: string;
  quote_3_byline_color?: string;
  quote_3_byline_alignment?: string;
  quote_3_byline_spacing?: string;
  quote_3_byline_font_size?: string;
  quote_3_byline_font_family?: string;
  quote_3_padding_left?: string;
  quote_3_padding_right?: string;
  quote_3_padding_top?: string;
  quote_3_padding_bottom?: string;
  quote_3_margin_left?: string;
  quote_3_margin_right?: string;
  quote_3_margin_top?: string;
  quote_3_margin_bottom?: string;
  image_padding_top?: string;
  image_padding_bottom?: string;
  image_source_y_spacing?: string;
  image_source_x_spacing?: string;
  image_source_alignment?: string;
  image_source_font_family?: string;
  image_source_style?: string;
  image_source_color?: string;
  image_source_font_size?: string;
  hyperlink_font_family?: string;
  hyperlink_background_color?: string;
  hyperlink_border_color?: string;
  hyperlink_border_width?: string;
  hyperlink_border_radius?: string;
  hyperlink_image_placement?: string;
  hyperlink_title_color?: string;
  hyperlink_title_size?: string;
  hyperlink_hide_title?: string;
  hyperlink_description_color?: string;
  hyperlink_description_size?: string;
  hyperlink_hide_description?: string;
  hyperlink_link_color?: string;
  hyperlink_link_size?: string;
  hyperlink_hide_link?: string;
  hyperlink_padding?: string;
  hyperlink_x_spacing?: string;
  hyperlink_y_spacing?: string;
  button_background_color?: string;
  button_text_color?: string;
  button_border_width?: string;
  button_border_radius?: string;
  button_border_color?: string;
  button_padding_top?: string;
  button_padding_bottom?: string;
  button_font_family?: string;
  header_top_bar_alignment?: string;
  header_top_date_format?: string;
  header_asset_id?: any;
  header_asset_integer_id?: any;
  header_asset_width?: string;
  header_asset_url?: any;
  header_title_font_family?: string;
  header_title_font_size?: string;
  header_title_alignment?: string;
  header_title_font_weight?: string;
  header_title_font_color?: string;
  header_title_vertical_padding?: string;
  header_subtitle_font_family?: string;
  header_subtitle_font_size?: string;
  header_subtitle_alignment?: string;
  header_subtitle_font_weight?: string;
  header_subtitle_font_color?: string;
  header_subtitle_vertical_padding?: string;
  header_byline_font_family?: string;
  header_byline_font_size?: string;
  header_byline_font_weight?: string;
  header_byline_font_color?: string;
  header_byline_accent_color?: string;
  header_byline_vertical_padding?: string;
  header_byline_avatar_size?: string;
  header_byline_date_format?: string;
  header_byline_underline_author?: string;
  header_byline_reading_time_enabled?: string;
  header_byline_reading_time_font_color?: string;
  header_byline_reading_time_label?: string;
  header_custom_css?: string;
  header_padding?: string;
  footer_padding?: string;
  footer_margin?: string;
  footer_background_color?: string;
  footer_font_family?: string;
  footer_font_color?: string;
  footer_top_border_width?: string;
  footer_top_border_color?: string;
  footer_top_border_size?: string;
  footer_social_background_color?: string;
  footer_social_icon_color?: string;
  content_break_y_margin?: string;
  content_break_width?: string;
  content_break_style?: string;
  content_break_color?: string;
  content_break_thickness?: string;
  table_border_width?: string;
  table_border_color?: string;
  table_padding_top?: string;
  table_padding_bottom?: string;
  table_header_font_family?: string;
  table_header_font_color?: string;
  table_header_background_color?: string;
  table_header_padding?: string;
  table_cell_font_family?: string;
  table_cell_font_color?: string;
  table_cell_background_color?: string;
  table_cell_padding?: string;
  name?: any;
  purpose?: string;
  owner_id?: string;
  header_asset?: any;
}
