import { useCallback, useState } from 'react';

import API from '@/components/TiptapEditor/lib/api';
import { Asset } from '@/interfaces/asset';

export const useAssetEditorModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((prev) => !prev), []);

  return { isOpen, open, close, toggle };
};

export const useAssetEditor = ({ onAssetUpdate }: { onAssetUpdate?: (asset: Asset) => void }) => {
  const [currentAsset, setCurrentAsset] = useState<Asset | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(
    (asset?: Asset) => {
      if (onAssetUpdate && asset) {
        onAssetUpdate(asset);
      }
      setIsOpen(false);
      setCurrentAsset(null);
    },
    [onAssetUpdate]
  );

  const open = useCallback((newAsset: Asset | null) => {
    setCurrentAsset(newAsset);
    setIsOpen(true);
  }, []);

  const handleEdit = useCallback((asset: Asset) => open(asset), [open]);

  const handleDelete = useCallback(async (asset: Asset, publicationId: string) => {
    const deletedId = await API.deleteAsset({ assetId: asset.id, publicationId });
    return deletedId.data.id;
  }, []);

  const id = currentAsset?.id || null;

  return { id, asset: currentAsset, open, isOpen, close, handleEdit, handleDelete };
};
