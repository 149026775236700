import { useCallback, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash.debounce';
import styled from 'styled-components';

import { usePermissions } from '@/context/permissions-context';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';

import Modal from '../../../../components/Modal';
import PreviewModal from '../../../../components/PreviewModal';
import { useCurrentPublication, usePostInformation } from '../../../../hooks';
import { IData } from '../../../../interfaces/general';
import { Post, PostStatus } from '../../../../interfaces/post';
import api from '../../../../services/swarm';
import TabNavigation from '../../../../ui/TabNavigation';
import SaveAsTemplateModal from '../Editor/SaveAsTemplateModal';
import ScheduleModal from '../Editor/ScheduleModal';
import UnscheduleModal from '../Editor/UnscheduleModal';
import { useEditorContext } from '../EditorContext';

import Configure from './Configure';
import SidebarToggleButton from './SidebarToggleButton';
import TestSend from './TestSend';
import { Threads } from './Threads';
import { SidebarTab } from './types';

interface Props {
  post: Post;
  onChange: (data: Post) => void;
  errors: { [key: string]: string };
  refetch: () => Promise<UseQueryResult>;
}

const StyledContainer = styled.div`
  background-color: #f7f7f7;
  @media (min-width: 1280px) {
    min-width: 450px;
  }
`;

const Sidebar = ({ post, onChange, errors, refetch }: Props) => {
  const [activeTab, setActiveTab] = useState<SidebarTab>('post');
  const [previewActive, setPreviewActive] = useState(false);
  const [testActive, setTestActive] = useState(false);
  const { data: currentPublication } = useCurrentPublication();
  const postInfoQuery = usePostInformation({ id: post.id });
  const navigate = useNavigate();
  const { checkPermissions } = usePermissions();
  const canPublish = checkPermissions('posts/schedule', 'update');

  const { setEditorIsLoading, setShowSidebar, selectThread, showThreadsSidebar, setShowThreadsSidebar } =
    useEditorContext();

  const hasErrors = Object.keys(errors).length > 0;

  const isPublished = post.status === PostStatus.PUBLISHED;
  const isScheduled = post.status === PostStatus.SCHEDULED;
  const canBeScheduledOrPublished = !isPublished && !isScheduled;

  const refetchInformation = useMemo(
    () =>
      debounce(() => {
        postInfoQuery.refetch();
      }, 1_000),
    [postInfoQuery]
  );

  const onRefresh = () => {
    refetch()
      .then(() => {
        refetchInformation();
      })
      .then(() => {
        setEditorIsLoading(true);
      })
      .finally(() => {
        setEditorIsLoading(false);
      });
  };

  const fetchPreview = useCallback(
    (platform: string, advancedParams: IData, onFetch: (html: string) => void) => {
      if (!currentPublication?.id) {
        return;
      }

      const params = {
        publication_id: currentPublication?.id,
        platform,
        ...advancedParams,
      };
      api.get(`/posts/${post.id}/preview`, { params }).then((resp) => onFetch(resp.data.html));
    },
    [post.id, currentPublication]
  );

  if (!currentPublication) {
    return null;
  }

  const sideTabs = [
    {
      name: 'post',
      label: 'Post',
      selected: activeTab === 'post',
      onSelect: () => setActiveTab('post'),
    },
    {
      name: 'email',
      label: 'Email',
      selected: activeTab === 'email',
      onSelect: () => setActiveTab('email'),
    },
    {
      name: 'website',
      label: 'Website',
      selected: activeTab === 'website',
      onSelect: () => setActiveTab('website'),
    },
    {
      name: 'seo',
      label: 'SEO',
      selected: activeTab === 'seo',
      onSelect: () => setActiveTab('seo'),
    },
    {
      name: 'delivery',
      label: 'Delivery',
      selected: activeTab === 'delivery',
      onSelect: () => setActiveTab('delivery'),
    },
  ];

  return (
    <>
      {showThreadsSidebar ? (
        <Threads onClose={() => setShowThreadsSidebar(false)} onSelectThread={selectThread} />
      ) : (
        <div className="overflow-y-auto relative mb-16 md:mb-0" style={{ height: 'calc(100% - 4rem)' }}>
          <div className="z-20 h-12 flex items-center sticky top-0" style={{ backgroundColor: '#f7f7f7' }}>
            <TabNavigation variant="secondary" className="px-2 w-full h-full" tabs={sideTabs}>
              <SidebarToggleButton
                onClick={() => setShowSidebar(false)}
                className="mx-4 cursor-pointer hover:bg-gray-200 hidden md:block"
              />
            </TabNavigation>
          </div>
          <div>
            <Configure activeSection={activeTab} errors={errors} post={post} onChange={onChange} />
          </div>
          <StyledContainer className="z-20 fixed bottom-0 h-20 sm:h-16 text-sm w-full md:w-2/3 lg:w-1/2 xl:w-1/3 border-t border-gray-300">
            <div className="grid grid-cols-2 sm:flex h-full items-center justify-start gap-x-2 w-full px-2 sm:px-4">
              <button
                className="justify-center duration-200 py-1 px-3 border font-medium focus:outline-none focus:ring-1 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap text-sm hover:bg-gray-200 text-gray-800 disabled:text-gray-600 disabled:bg-gray-100 rounded-md shadow-sm border-gray-300"
                type="button"
                onClick={() => setPreviewActive(true)}
              >
                <EyeIcon width="1rem" className="mr-1" />
                <span>Preview</span>
              </button>
              <button
                className="justify-center duration-200 py-1 px-3 border font-medium focus:outline-none focus:ring-1 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap text-sm hover:bg-gray-200 text-gray-800 disabled:text-gray-600 disabled:bg-gray-100 rounded-md shadow-sm border-gray-300"
                type="button"
                onClick={() => setTestActive(true)}
              >
                <span>Send Test</span>
              </button>
              <SaveAsTemplateModal postId={post.id} />
              {canBeScheduledOrPublished ? (
                <ScheduleModal
                  disabled={hasErrors || !canPublish}
                  disabledTooltip={!canPublish ? NO_PERMISSION_MESSAGE : undefined}
                  postData={post}
                  onConfirm={() => navigate(`/posts/${post.id}`)}
                />
              ) : (
                isScheduled && <UnscheduleModal postData={post} onUnconfirm={() => onRefresh()} />
              )}
            </div>
          </StyledContainer>
        </div>
      )}

      <PreviewModal
        fetchPreview={fetchPreview}
        active={previewActive}
        tabs={['Email', 'Web']}
        onClose={() => setPreviewActive(false)}
        showSubscriberSelect
      />

      <Modal isOpen={testActive} onClose={() => setTestActive(false)} includeCloseButton>
        <TestSend post={post} onSubmit={() => setTestActive(false)} />
      </Modal>
    </>
  );
};

export default Sidebar;
