import { AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Button } from '@/ui/Button';

type FilterButtonTypes = {
  handleClick: () => void;
  on: boolean;
};

const FilterButton = ({ handleClick, on }: FilterButtonTypes) => {
  return (
    <Button variant="primary-inverse" onClick={handleClick}>
      <AdjustmentsVerticalIcon className={cx('w-4 h-4', on ? 'text-primary-600' : 'text-grey-600')} />
    </Button>
  );
};

export default FilterButton;
