import { RefObject, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountUp from 'react-countup';
import cx from 'classnames';

import { PublicationRewind } from '@/interfaces/publication_rewind';

import { getDecimal, getSuffix } from '../helpers';

type Props = {
  currentViewIndex: number;
  data?: PublicationRewind;
  index?: number;
  setSlideRef: (ref: RefObject<HTMLDivElement>) => void;
};

const View4 = ({ setSlideRef, data, currentViewIndex, index }: Props) => {
  const [startedCount, setStartedCount] = useState(0);
  const [endedCount, setEndedCount] = useState(0);
  const [increaseCount, setIncreaseCount] = useState(0);
  const [playAnimation, setPlayAnimation] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const showPercentIncrease = !!data?.subscription_count.beginning && data?.subscription_count.beginning > 0;
  const increase = showPercentIncrease
    ? (((data?.subscription_count.end || 0) - (data?.subscription_count.beginning || 0)) /
        (data?.subscription_count.beginning || 0)) *
      100
    : data?.subscription_count.end || 0;

  useEffect(() => {
    const isCurrentView = currentViewIndex === index;

    if (isCurrentView) {
      setSlideRef(contentRef);
      setStartedCount(getDecimal(data?.subscription_count.beginning || 0));
      setEndedCount(getDecimal(data?.subscription_count.end || 0));
      setIncreaseCount(getDecimal(increase));
      setTimeout(() => {
        setPlayAnimation(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  return (
    <div ref={contentRef} className="w-full h-full relative overflow-hidden rewind-bg-pink">
      <div className="rewind-bg-burst top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] rewind-bg-blue rounded-[2rem] m-[5%_0_0_5%] flex flex-col rewind-border-cyber-ink border-2" />
      <div className="absolute top-0 left-0 w-[82%] h-[82%] rewind-bg-sapphire-haze rounded-[1.8rem] m-[9%_0_0_9%] flex flex-col rewind-border-cyber-ink border-2" />
      <div className="absolute top-0 left-0 w-[74%] h-[74%] rewind-bg-cosmic-lavender rounded-[1.6rem] m-[13%_0_0_13%] flex flex-col rewind-border-cyber-ink border-2" />
      <div className="rewind-bg-texture top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[74%] h-[74%] m-[13%_0_0_13%] flex flex-col p-[0_35px]">
        <div className="flex justify-center items-center w-full mt-12 mb-10">
          <img className="h-6 w-auto" src="/rewind-2024/images/beehiiv-logo-wordmark.png" alt="beehiiv logo" />
        </div>
        <h1
          className={cx(
            'font-clash-grotesk-v rewind-text-cyber-ink uppercase font-bold w-full text-[4rem] leading-[3.2rem] text-center transition-all duration-300',
            !playAnimation && 'translate-x-20 opacity-0',
            playAnimation && 'translate-x-0 opacity-1'
          )}
        >
          IT’S NO WONDER YOUR AUDIENCE IS GROWING FAST…
        </h1>
        <ul className="mt-14 p-0 w-full">
          <li className="flex w-full justify-between items-center bg-white rounded-[132px] px-16 py-11 mb-2">
            <div className="font-satoshi-v rewind-text-cyber-ink text-3xl font-bold">Started the year at</div>
            <div className="font-bold text-[4rem] leading-[3.5rem] font-clash-grotesk-v uppercase">
              <CountUp
                start={0}
                end={startedCount}
                duration={3}
                separator=" "
                decimals={(data?.subscription_count.beginning || 0) > 1000 ? 1 : 0}
                decimal="."
              >
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                    <span>{getSuffix(data?.subscription_count.beginning || 0)}</span>
                  </div>
                )}
              </CountUp>
            </div>
          </li>
          <li className="flex w-full justify-between items-center bg-white rounded-[132px] px-16 py-11 mb-2">
            <div className="font-satoshi-v rewind-text-cyber-ink text-3xl font-bold">Ended the year with</div>
            <div className="font-bold text-[4rem] leading-[3.5rem] font-clash-grotesk-v uppercase">
              <CountUp
                start={0}
                end={endedCount}
                duration={3}
                separator=" "
                decimals={(data?.subscription_count.end || 0) > 1000 ? 1 : 0}
                decimal="."
              >
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                    <span>{getSuffix(data?.subscription_count.end || 0)}</span>
                  </div>
                )}
              </CountUp>
            </div>
          </li>
          <li className="flex w-full justify-between items-center bg-white rounded-[132px] px-16 py-11">
            <div className="font-satoshi-v rewind-text-cyber-ink text-3xl font-bold">That&apos;s an increase of</div>
            <div className="font-bold text-[4rem] leading-[3.5rem] font-clash-grotesk-v uppercase">
              <CountUp
                start={0}
                end={increaseCount}
                duration={3}
                separator=" "
                decimals={increase >= 1000 ? 1 : 0}
                decimal="."
              >
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                    <span>
                      {getSuffix(increase)}
                      {showPercentIncrease && '%'}
                    </span>
                  </div>
                )}
              </CountUp>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default View4;
