import { css } from 'styled-components';

import { colors } from '../../../lib/colors';
import { Styled as StyledIcon } from '../Icon/styled';

import { StyledButtonProps } from './types';

export const quaternaryButtonStyles = css<StyledButtonProps>`
  color: ${colors.white[2]};

  &:not(:disabled) {
    &:hover {
      background-color: ${colors.white[7]};
      color: ${colors.white[1]};
    }

    &.is-active {
      background-color: ${colors.white[8]};
      color: ${({ $muted }) => ($muted ? colors.white[2] : colors.pink[6])};

      &:hover {
        background-color: ${colors.white[7]};
      }
    }

    &:hover,
    &.is-active {
      ${StyledIcon.Wrapper} svg,
      ${StyledIcon.Wrapper} svg path {
        fill: ${({ $isToggleButton, $active }) => ($isToggleButton && !$active ? colors.white[6] : 'currentColor')};
      }
    }

    & > span:not(${StyledIcon.Wrapper}) {
      color: ${({ $isToggleButton, $active }) => ($isToggleButton && !$active ? colors.white[5] : 'currentColor')};
    }
  }

  &:disabled {
    color: ${colors.white[6]};

    ${StyledIcon.Wrapper} svg,
    ${StyledIcon.Wrapper} svg path {
      fill: currentColor;
    }
  }

  ${StyledIcon.Wrapper} svg,
  ${StyledIcon.Wrapper} svg path {
    fill: ${({ $isToggleButton, $active }) => ($isToggleButton && !$active ? colors.white[6] : colors.white[3])};
  }
`;

export default quaternaryButtonStyles;
