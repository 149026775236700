import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChartBarIcon, ClockIcon, ReceiptPercentIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import { Grid } from '@tremor/react';

import Banner from '@/components/Banner';
import Card from '@/components/Card';
import { EmptyCard } from '@/components/ResourceList';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { MetricCard } from '@/ui/MetricCard';

import LoadingBox from '../../../../components/LoadingBox';
import { useSplitTest } from '../../../../hooks';
import { SplitTestStatus } from '../../../../interfaces/split_test';
import { SplitTestOption } from '../../../../interfaces/split_test_option';

import OptionTable from './OptionTable';

interface Props {
  shouldUpgrade: boolean;
  postHasSplitTest: boolean;
  isPostLive: boolean;
  postId: string;
}

const SplitTestAnalytics = ({ postId, shouldUpgrade, postHasSplitTest, isPostLive }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { data: splitTest, isLoading, isError } = useSplitTest(postId);
  const splitTestOptions = splitTest?.split_test_options || [];

  // SplitTest duration is given in seconds. Convert to hours and minutes for readability.
  const readableDuration = (duration: number) => {
    if (duration === 0) {
      return null;
    }

    const hours = Math.floor((duration % 86400) / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    let message = '';
    if (hours > 0) {
      message += `${hours} hours`;
    }
    if (hours > 0 && minutes > 0) {
      message += ' and ';
    }
    if (minutes > 0) {
      message += `${minutes} minutes`;
    }

    return message;
  };

  const significanceMessage = (significant: boolean | undefined) => {
    if (isLoading || significant === undefined || splitTest?.status !== SplitTestStatus.COMPLETED) {
      return null;
    }

    if (significant) {
      return (
        <Banner
          isScreenWide={false}
          variant="success"
          title="Great News! We can say with 90% statistical confidence that the winning version produces a higher open rate than the other options."
        />
      );
    }

    const sampleSizeTooSmall = splitTest?.population_size / splitTestOptions.length < 200;

    return (
      <Banner
        isScreenWide={false}
        variant="warning"
        title={
          sampleSizeTooSmall
            ? 'Sample size was too small to yield statistically significant results, so we sent the variant with the higher open rate.'
            : 'Results were too close to have statistically significant results, so we sent the variant with the higher open rate.'
        }
      />
    );
  };

  const showEmptyState = shouldUpgrade || !postHasSplitTest;
  const isPending = splitTest?.status === SplitTestStatus.PENDING;

  if (isV2) {
    return (
      <Card>
        <div className="flex flex-col gap-y-6">
          {!showEmptyState ? significanceMessage(splitTest?.statistically_significant) : null}
          <div className="flex items-center h-9">
            <Typography token="font-medium/text/lg">A/B Test</Typography>
          </div>

          {showEmptyState && shouldUpgrade ? (
            <EmptyCard
              title="A/B Tests"
              description="A/B Testing is only available on premium beehiiv plans"
              primaryIcon={ChartBarIcon}
              primaryActionLabel="Upgrade"
              primaryActionIcon={null}
              onPrimaryActionClick={() => {
                searchParams.set('upgrade', 'true');
                setSearchParams(searchParams);
              }}
            />
          ) : null}
          {showEmptyState && !postHasSplitTest && isPostLive ? (
            <EmptyCard
              title="No A/B Test Created"
              description="You can create an A/B test in a future post"
              primaryIcon={ChartBarIcon}
            />
          ) : null}
          {showEmptyState && !postHasSplitTest && !isPostLive ? (
            <EmptyCard
              title="No A/B Test Created"
              description="You can add an A/B test in your post in Email settings"
              primaryIcon={ChartBarIcon}
              primaryActionIcon={null}
              primaryActionLabel="Edit post"
              onPrimaryActionClick={() => navigate(`/posts/${postId}/edit`)}
            />
          ) : null}
          {!showEmptyState ? (
            <div className="flex flex-col gap-y-8">
              <Grid numCols={1} numColsSm={3} className="gap-4">
                <MetricCard
                  variant="secondary"
                  label="Duration"
                  value={readableDuration(splitTest?.duration || 0) || 0}
                  isLoading={isLoading}
                />
                <MetricCard
                  variant="secondary"
                  label="Sample Percentage"
                  value={`${splitTest?.sample_pct || 0}%`}
                  isLoading={isLoading}
                />
                <MetricCard
                  variant="secondary"
                  label="Sample Size"
                  value={`${splitTest?.sample_size.toLocaleString('en-US') || 0} total`}
                  isLoading={isLoading}
                />
              </Grid>

              {isPending ? (
                <EmptyCard
                  title="Variant Analytics"
                  description="This A/B Test has not run yet. Schedule the post to start testing."
                  primaryIcon={ChartBarIcon}
                />
              ) : (
                <div className="flex flex-col gap-y-4">
                  <LoadingBox isLoading={isLoading} isError={isError}>
                    {splitTestOptions.map((option: SplitTestOption) => (
                      <OptionTable
                        key={option.id}
                        splitTestOption={option}
                        winner={(splitTest?.winning_option_id || '') === option.id}
                        inProgress={splitTest?.status === SplitTestStatus.IN_PROGRESS}
                      />
                    ))}
                  </LoadingBox>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </Card>
    );
  }

  const statusMessage = (status: SplitTestStatus | undefined) => {
    switch (status) {
      case SplitTestStatus.PENDING:
        return 'This A/B Test has not run yet. Schedule the post to start testing.';
      case SplitTestStatus.IN_PROGRESS:
        return 'The A/B Test is currently running. Check back later for results.';
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      <Card>
        <LoadingBox isLoading={isLoading} isError={isError}>
          <div className="space-y-4">
            {significanceMessage(splitTest?.statistically_significant)}
            <div className="mb-2 font-bold text-lg">A/B Test</div>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-3">
              <div className="flex md:w-auto w-full flex-col">
                <ClockIcon className="h-6 w-6 text-gray-300 mb-4" />
                <div className="mb-2">
                  <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">DURATION</p>
                  <p className="leading-none">
                    <span className="text-md font-black text-gray-400 mt-2">
                      {readableDuration(splitTest?.duration || 0)}
                    </span>
                    <span className="text-gray-400 tracking-tighter text-sm"> </span>
                  </p>
                </div>
              </div>
              <div className="flex md:w-auto w-full flex-col">
                <ReceiptPercentIcon className="h-6 w-6 text-gray-300 mb-4" />
                <div className="mb-2">
                  <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">SAMPLE PERCENTAGE</p>
                  <p className="leading-none">
                    <span className="text-3xl font-black text-gray-400">{splitTest?.sample_pct || 0}</span>
                    <span className="text-gray-400 tracking-tighter text-sm"> %</span>
                  </p>
                </div>
              </div>
              <div className="flex md:w-auto w-full flex-col">
                <UserGroupIcon className="h-6 w-6 text-gray-300 mb-4" />
                <div className="mb-2">
                  <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">SAMPLE SIZE</p>
                  <p className="leading-none">
                    <span className="text-3xl font-black text-gray-400">
                      {splitTest?.sample_size.toLocaleString('en-US') || 0}
                    </span>
                    <span className="text-gray-400 tracking-tighter text-sm"> total</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LoadingBox>
      </Card>
      <Card>
        <LoadingBox isLoading={isLoading} isError={isError}>
          {splitTest?.status === SplitTestStatus.COMPLETED ? (
            <>
              <div className="mb-2 font-bold text-lg">Results</div>
              <div className="space-y-4">
                {splitTestOptions.map((option: SplitTestOption) => (
                  <OptionTable
                    key={option.id}
                    splitTestOption={option}
                    winner={(splitTest?.winning_option_id || '') === option.id}
                    inProgress={false}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="mt-6 pt-4 border-t border-gray-200">
              <p className="text-gray-400">{statusMessage(splitTest?.status)}</p>
            </div>
          )}
        </LoadingBox>
      </Card>
    </div>
  );
};

export default SplitTestAnalytics;
