import React from 'react';

import { Knob, Switch, SwitchInput } from './styled';
import { ToggleSwitchProps } from './types';

export const ToggleSwitch = ({ isChecked, onChange, isDisabled = false }: ToggleSwitchProps) => {
  return (
    <Switch>
      <SwitchInput type="checkbox" checked={isChecked} onChange={onChange} disabled={isDisabled} />
      <Knob isDisabled={isDisabled} />
    </Switch>
  );
};

export default ToggleSwitch;
