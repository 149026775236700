import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Card } from '@/ui/Card';

import PageContainer from '../../settings/_components/PageContainer';

import Form from './Form';

const CurrentUserNotificationPreferences: React.FC = () => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);
  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId}>
          <Card>
            <Form />
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default CurrentUserNotificationPreferences;
