import { useEffect, useRef, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useDebounce } from 'use-debounce';

import { useInfiniteScroll } from '@/hooks';
import useWorkspaceViews from '@/hooks/useAdNetwork/internal/useWorkspaceViews';
import { AdNetworkWorkspaceView } from '@/interfaces/ad_network/internal/workspace_view';
import { Pagination } from '@/interfaces/general';
import { Button } from '@/ui/Button';

import { Input } from '../_components/Input';
import { List } from '../_components/List';
import { LoadingListPage } from '../_components/LoadingListPage';
import { PageHeading } from '../_components/PageHeading';
import { Shortcut } from '../_components/Shortcut';

interface Props {
  query: string;
  setQuery: (query: string) => void;
  workspaceViews: AdNetworkWorkspaceView[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetching: boolean;
  isLoading: boolean;
  pagination: Pagination;
}

const WorkspaceViews = ({
  workspaceViews,
  fetchNextPage,
  hasNextPage,
  query,
  setQuery,
  isLoading,
  isFetching,
  pagination,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '/') {
        if (document.activeElement !== inputRef.current) {
          event.preventDefault();

          inputRef.current?.focus();
        }
      }

      if (event.key === 'Escape') {
        inputRef.current?.blur();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { ref: lastElementRef } = useInfiniteScroll({ fetchNextPage, hasNextPage, isFetching, isLoading });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="">Views</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <div className="relative">
            <Input
              placeholder="Search..."
              sizeVariant="sm"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              type="search"
              ref={inputRef}
            />
            {!query && (
              <div className="absolute right-1.5 top-1/2 transform -translate-y-1/2">
                <div className="flex">
                  <Shortcut>/</Shortcut>
                </div>
              </div>
            )}
          </div>
          <Button to="new" size="xs" variant="primary-inverse" Icon={PlusIcon}>
            New view
          </Button>
        </PageHeading.Side>
      </PageHeading>

      <List>
        {workspaceViews.map((view) => {
          const isLastRow = workspaceViews.length - 1 === workspaceViews.indexOf(view);

          return (
            <List.Item key={view.id} to={view.id} ref={isLastRow ? lastElementRef : null}>
              <List.Item.Text variant="primary">{view.name}</List.Item.Text>
              <List.Item.Text variant="secondary">{view.description}</List.Item.Text>
            </List.Item>
          );
        })}
      </List>

      <div className="sticky bottom-0">
        <div className="px-4 py-2 border-t border-gray-100 bg-white">
          <p className="text-sm text-gray-500">
            Showing {workspaceViews.length} of {pagination.total} views
          </p>
        </div>
      </div>
    </>
  );
};

export default function Loader() {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 800);
  const { data, isSuccess, hasNextPage, fetchNextPage, isLoading, isError, isFetching } = useWorkspaceViews({
    query: debouncedQuery,
  });

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const workspaceViews = data?.pages.flatMap((page) => page.workspace_views) || [];

  return (
    <WorkspaceViews
      query={query}
      setQuery={setQuery}
      workspaceViews={workspaceViews}
      fetchNextPage={fetchNextPage}
      hasNextPage={!!hasNextPage}
      isFetching={isFetching}
      isLoading={isLoading}
      pagination={data?.pages[0].pagination}
    />
  );
}
