import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

export default function useFormInsights({
  search,
  perPage = 10,
  all = false,
  formId,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
  formId: string;
}) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchForms = ({ pageParam = 1 }) =>
    api
      .get(`/forms/${formId}/insights`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery<any>([currentPublicationId, 'form_responses', formId, search], fetchForms, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    retry: false,
    keepPreviousData: true,
    onError: (errPayload: any) => errPayload?.response?.data?.message || 'Something went wrong',
  });
}
