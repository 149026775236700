import { Grid } from '@tremor/react';

import useFormattedSendingMetrics from '@/hooks/boosts/grow/useFormattedSendingMetrics';
import useFormattedTopLineMetrics from '@/hooks/boosts/grow/useFormattedTopLineMetrics';
import useSendingMetrics from '@/hooks/boosts/grow/useSendingMetrics';
import useTopLineMetrics from '@/hooks/boosts/grow/useTopLineMetrics';
import { MetricCard } from '@/ui/MetricCard';

interface Props {
  recommendingPublicationId: string;
}

const TopLineMetrics = ({ recommendingPublicationId }: Props) => {
  const { data: topLineMetricsData } = useTopLineMetrics({ recommendingPublicationId });
  const { data: sendingMetricsData } = useSendingMetrics({ recommendingPublicationId });
  const { openRate, unsubscribeRate, clickThroughRate } = useFormattedSendingMetrics(sendingMetricsData);

  const totalSubscribers = topLineMetricsData?.total_subscribers.toLocaleString() || '0';
  const verifiedSubscribers = topLineMetricsData?.total_accepted_subscribers.toLocaleString() || '0';
  const pendingSubscribers = topLineMetricsData?.total_pending_subscribers.toLocaleString() || '0';

  const { acceptanceRate, pendingSpend, totalSpend, averageCPA } = useFormattedTopLineMetrics(topLineMetricsData);

  return (
    <div>
      <Grid numCols={1} numColsSm={4} className="gap-4">
        <MetricCard
          variant="primary"
          label="Total Subscribers"
          value={totalSubscribers}
          complements={[
            { label: 'Pending subscribers', value: pendingSubscribers },
            { label: 'Verified subscribers', value: verifiedSubscribers },
          ]}
        />
        <MetricCard
          variant="primary"
          label="Average Open Rate"
          value={openRate}
          complements={[
            { label: 'Avg. click through rate', value: clickThroughRate },
            { label: 'Avg. unsubscribe rate', value: unsubscribeRate },
          ]}
        />
        <MetricCard
          variant="primary"
          label="Average CPA"
          value={averageCPA}
          complements={[{ label: 'Avg. acceptance rate', value: acceptanceRate }]}
        />
        <MetricCard
          variant="primary"
          label="Total Spend"
          value={totalSpend}
          complements={[{ label: 'Pending spend', value: pendingSpend }]}
        />
      </Grid>
    </div>
  );
};

export default TopLineMetrics;
