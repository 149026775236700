import cx from 'classnames';

interface Props {
  active: boolean;
}

const ActiveIndicator = ({ active }: Props) => {
  const color = active ? 'bg-green-500' : 'bg-gray-300';

  return <div className={cx('h-2 w-2 rounded-full', color)}>&nbsp;</div>;
};

export default ActiveIndicator;
