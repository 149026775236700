import { Editor, findChildren, findParentNode } from '@tiptap/core';

export const findNextColumnNodeEndPosition = (editor: Editor) => {
  const {
    state: {
      selection: { $anchor },
    },
  } = editor;

  const columns = findParentNode((node) => node.type.name === 'columns')(editor.state.selection);

  if (!columns) {
    return null;
  }

  const columnsNode = columns.node;
  const children = findChildren(columnsNode, (node) => node.type.name === 'column');

  const columnIndex = $anchor.index(columns.depth);

  if (columnIndex + 1 < children.length) {
    const nextColumn = children[columnIndex + 1];
    const nextColumnEndPos = columns.pos + nextColumn.pos + nextColumn.node.nodeSize - 1;

    return nextColumnEndPos;
  }

  return null;
};
