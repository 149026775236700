import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface ApiResponse {
  data: any;
}

const useTotalBalanceSnapshots = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchSnapshots = (): Promise<ApiResponse> =>
    api
      .get(`/wallet/total_balance_snapshots`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'wallet', 'total_balance_snapshots'], fetchSnapshots, {
    keepPreviousData: true,
  });
};

export default useTotalBalanceSnapshots;
