import React, { FC, useState } from 'react';
import cx from 'classnames';

import Card from '@/components/Card';
import { Input } from '@/components/Form';
import { CustomFieldKind } from '@/interfaces/custom_field';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import { selectOptions } from '@/utils/dataTypes';

interface Props {
  onSubmit: (display: string, kind: string) => Promise<void>;
  onCancel: () => void;
  header: string;
  customField?: {
    display?: string;
    kind?: string;
  };
  footerClassName?: string;
  allowedKindOptions?: CustomFieldKind[];
}

const CustomFieldForm: FC<Props> = ({
  onSubmit,
  header,
  customField,
  footerClassName,
  onCancel,
  allowedKindOptions,
}) => {
  const [customFieldName, setCustomFieldName] = useState(customField?.display || '');
  const [kind, setKind] = useState(customField?.kind || '');
  const [saving, setSaving] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSaving(true);

    onSubmit(customFieldName, kind).finally(() => {
      setSaving(false);
    });
  };

  const handleSelectKind = (_name: string, value: string) => {
    setKind(value);
  };

  const isDisabled = () => !customFieldName && !kind;

  return (
    <Card className="max-w-2xl mx-auto p-6">
      <div className="flex flex-col gap-1 w-full mb-4">
        <div className="flex items-center">
          <h1 className="text-lg pb-0.5 leading-6 font-medium text-gray-900 mr-2 flex items-center">{header}</h1>
        </div>
      </div>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="">
          <div className="space-y-6">
            <Input
              name="name"
              labelText="Name"
              type="text"
              value={customFieldName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomFieldName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="">
          <div className="space-y-6">
            <Dropdown
              name="kind"
              labelText="Data Type"
              value={kind}
              helperText="Choose the data type for this field"
              onSelect={handleSelectKind}
              options={selectOptions.filter(({ value }) =>
                allowedKindOptions ? allowedKindOptions.includes(value as CustomFieldKind) : true
              )}
            />
          </div>
        </div>

        <div className={cx('space-x-2', footerClassName)}>
          <Button type="button" variant="primary-inverse" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={isDisabled()} loading={saving}>
            Save
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default CustomFieldForm;
