import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

const ENTERPRISE_CONTACT_URL = 'https://www.beehiiv.com/enterprise';

const CustomButton = () => {
  return (
    <div className="h-full flex flex-col justify-end py-6">
      <Button
        type="button"
        variant="primary-inverse"
        Icon={ArrowTopRightOnSquareIcon}
        iconRight
        onClick={() => window.open(ENTERPRISE_CONTACT_URL, '_blank')}
      >
        Contact Sales
      </Button>
    </div>
  );
};

export default CustomButton;
