import { useLocation, useNavigate } from 'react-router-dom';
import { BanknotesIcon } from '@heroicons/react/20/solid';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import LoadingBox from '@/components/LoadingBox';
import { EmptyCard } from '@/components/ResourceList';
import { useCurrentUser } from '@/context/current-user-context';
import { useRequireSetting } from '@/hooks';
import { usePremiumSettings } from '@/hooks/usePublications';
import { useTutorial } from '@/hooks/useTutorials';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';

import { PremiumDataProvider } from '../../_context/premiumDataContext';
import TopLevelMetrics from '../TopLevelMetrics';

import Nav from './Nav';

const PageWrapper = ({ children }: { children: React.ReactNode }) => {
  useRequireSetting({
    setting: Setting.PREMIUM_SUBSCRIPTIONS,
    message: '',
    redirectTo: '/monetize/subscriptions/overview',
  });
  const navigate = useNavigate();
  const location = useLocation();

  const { data: premiumSettings, isLoading, isError } = usePremiumSettings();
  const isPremiumActive = premiumSettings?.premium_tier_active;
  const isPremiumEnabled = premiumSettings?.premium_enabled;
  const isPaidTiersTab = location.pathname.startsWith('/monetize/subscriptions/paid_tiers');
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;

  const tutorial = useTutorial(TutorialType.PREMIUM_SUBSCRIPTIONS);

  return (
    <PremiumDataProvider>
      <div className="space-y-5">
        <PageHeading
          title="Paid Subscriptions"
          description="Insights and data from your paying subscribers."
          tutorial={tutorial}
        />
        <TopLevelMetrics />
        <Nav />
        <LoadingBox backgroundClassName="bg-transparent" isLoading={isLoading} isError={isError}>
          {isPaidTiersTab ? children : null}
          {!isPaidTiersTab && isPremiumActive && children}
          {!isPaidTiersTab && !isPremiumActive && (
            <EmptyCard
              title="No Data Available"
              description="Configure your paid subscription offerings to get started"
              primaryActionLabel="Launch Paid Subscriptions"
              primaryIcon={BanknotesIcon}
              primaryIconTransparentBackground
              primaryIconSize="xl"
              onPrimaryActionClick={() => {
                if (isPremiumEnabled) {
                  navigate(
                    hasSettingsV2 ? '/monetize/subscriptions/paid_tiers/new' : '/settings/publication/premium/tiers/new'
                  );
                } else {
                  navigate(hasSettingsV2 ? '/monetize/subscriptions/paid_tiers' : '/settings/publication/premium');
                }
              }}
            />
          )}
        </LoadingBox>
      </div>
    </PremiumDataProvider>
  );
};

export default PageWrapper;
