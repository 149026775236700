import { PropsWithChildren } from 'react';

import Divider from '../../_components/UI/Divider';

type Props = PropsWithChildren<{
  hasDivider?: boolean;
}>;

const SettingsSection = ({ children, hasDivider = true }: Props) => {
  return (
    <div className="flex flex-col group">
      {children}
      {hasDivider && <Divider className="group-last:hidden" />}
    </div>
  );
};

export default SettingsSection;
