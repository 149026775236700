import { useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/24/outline';

import { Input } from '../../../components/Form';

import NotEditing from './NotEditing';
import SaveButton from './SaveButton';

type Name = {
  first_name: string;
  last_name: string;
};

interface Props {
  firstName: string | undefined;
  lastName: string | undefined;
  onSave: (val: Name) => Promise<any>;
  isSaving: boolean;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
}

const NameForm = ({ firstName, lastName, onSave, isSaving, toastSuccessMessage, toastErrorMessage }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [firstNameInput, setFirstNameInput] = useState<string>(firstName || '');
  const [lastNameInput, setLastNameInput] = useState<string>(lastName || '');
  const showSaving = isSaving && isEditing;

  const handleSave = async () => {
    if (firstNameInput !== firstName || lastNameInput !== lastName) {
      await onSave({
        first_name: firstNameInput,
        last_name: lastNameInput,
      })
        .then(() => {
          setIsEditing(false);
          if (toastSuccessMessage) {
            toast.success(toastSuccessMessage);
          }
        })
        .catch(() => {
          if (toastErrorMessage) {
            toast.error(toastErrorMessage);
          }
        });
    } else {
      setIsEditing(false);
    }
  };

  if (isEditing) {
    return (
      <div className="flex justify-between pb-4 space-x-6 border-b border-gray-100 last:border-b-0">
        <div className="flex md:space-x-4 space-x-0 md:space-y-0 space-y-4 md:flex-row flex-col">
          <Input
            className="w-full"
            name="firstName"
            labelText="First Name"
            value={firstNameInput}
            onChange={(e) => setFirstNameInput(e.target.value)}
          />
          <Input
            className="w-full"
            name="lastName"
            labelText="Last Name"
            value={lastNameInput}
            onChange={(e) => setLastNameInput(e.target.value)}
          />
        </div>

        <SaveButton
          isSaving={showSaving}
          closeEditing={() => setIsEditing(false)}
          isEditing={isEditing}
          changesDetected={Boolean(firstNameInput !== firstName || lastNameInput !== lastName)}
          type="button"
          onClick={handleSave}
        />
      </div>
    );
  }

  return (
    <div className="flex justify-between pb-4 border-b border-gray-100 last:border-b-0">
      <NotEditing label="Full Name" value={`${firstNameInput} ${lastNameInput}`} />

      <div className="pt-4">
        <button
          className="h-6 w-6 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
          type="button"
          onClick={() => setIsEditing(true)}
        >
          <PencilIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};

export default NameForm;
