const StripeLogo = () => {
  return (
    <svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8981 7.80849C19.5065 7.77489 20 8.27399 20 8.88649V20.1551C20 20.7683 19.5023 21.2555 18.9016 21.3549C17.5218 21.584 16.2479 22.2415 15.2585 23.2354C14.2691 24.2292 13.6138 25.5095 13.3844 26.8968C13.2849 27.5002 12.8004 28 12.192 28H2.09746C0.879319 28 -0.121646 27.0032 0.0120014 25.7852C0.533765 21.0253 2.71824 16.6061 6.17657 13.3141C9.6349 10.0222 14.1433 8.07122 18.8981 7.80849Z"
        fill="#9966FF"
      />
      <path
        d="M18.8981 20.1922C19.5065 20.2251 20 19.726 20 19.1142V7.8449C20 7.2317 19.5023 6.7445 18.9016 6.6451C17.5217 6.41632 16.2477 5.75886 15.2582 4.76496C14.2687 3.77106 13.6135 2.49059 13.3844 1.1032C13.2849 0.4998 12.8004 0 12.1921 0H2.09539C0.877942 0 -0.121632 0.9954 0.0120161 2.212C0.532856 6.97264 2.71693 11.3929 6.17534 14.6857C9.63376 17.9784 14.142 19.93 18.8974 20.1929L18.8981 20.1922Z"
        fill="url(#paint0_linear_1487_115276)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8332 20.7431C19.7327 20.5826 19.5953 20.4488 19.4326 20.353C19.2699 20.2571 19.0867 20.202 18.8984 20.1922C13.8101 19.9112 9.01766 17.698 5.48975 14C9.01791 10.302 13.8106 8.0891 18.8991 7.8085C19.0873 7.7985 19.2704 7.74318 19.433 7.64719C19.5955 7.5512 19.7328 7.41735 19.8332 7.2569C19.939 7.4298 20.0003 7.6293 20.0003 7.8449V20.1551C20.0003 20.3707 19.939 20.5702 19.8332 20.7431Z"
        fill="url(#paint1_linear_1487_115276)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1487_115276"
          x1="17.5637"
          y1="2.8"
          x2="0.509715"
          y2="2.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21CFE0" />
          <stop offset="1" stopColor="#11EFE3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1487_115276"
          x1="5.74173"
          y1="14.2625"
          x2="21.0555"
          y2="14.2625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0048E5" />
          <stop offset="1" stopColor="#9B66FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StripeLogo;
