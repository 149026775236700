import {
  AdNetworkDisbursementPayoutPlatform,
  AdNetworkDisbursementStatusLabel,
} from '@/interfaces/ad_network/internal/disbursement/types';
import { BadgeColor } from '@/routes/ad_network/_components/Badge/types';

export const LabelColorHash = {
  [AdNetworkDisbursementStatusLabel.NOT_STARTED]: BadgeColor.GRAY,
  [AdNetworkDisbursementStatusLabel.SCHEDULED]: BadgeColor.GRAY,
  [AdNetworkDisbursementStatusLabel.RUNNING]: BadgeColor.BLUE,
  [AdNetworkDisbursementStatusLabel.AWAITING_APPROVAL]: BadgeColor.YELLOW,
  [AdNetworkDisbursementStatusLabel.APPROVED]: BadgeColor.GREEN,
  [AdNetworkDisbursementStatusLabel.PAID]: BadgeColor.GREEN,
  [AdNetworkDisbursementStatusLabel.MANUAL]: BadgeColor.GRAY,
};

export interface StatusBadgeProps {
  status: AdNetworkDisbursementStatusLabel;
  payoutPlatform?: AdNetworkDisbursementPayoutPlatform | null;
}
