import { useCallback, useEffect, useMemo, useState } from 'react';
import Editor from '@monaco-editor/react';
import { BracketsCurly, CaretRight } from '@phosphor-icons/react';
import Ajv from 'ajv';

import { Button } from '../../../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../UI/Dialog';
import InputWrapper from '../../../UI/InputWrapper';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../../UI/Select';
import { Text } from '../../../UI/Text';
import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';

type Props = Omit<AttributeSettingProps, 'children'> & {
  allowedProperties: string[];
  predefinedPatterns: { name: string; value: any }[];
  onUpdate?: (pattern?: string) => void;
  children: (structure: any) => JSX.Element;
};

const getCardStructure = (initialStructure: any, incomingStructure: string): any => {
  if (!incomingStructure) return initialStructure;

  try {
    const parsedStructure = JSON.parse(incomingStructure);
    return parsedStructure as any;
  } catch (error) {
    console.error('Error parsing JSON string:', error);
    return {};
  }
};

const ajv = new Ajv();

export const StructureSettings = ({
  editor,
  activeNodeResult,
  allowedProperties,
  children,
  predefinedPatterns,
  onUpdate,
}: Props) => {
  const { activeNodePos, activeNode } = activeNodeResult;
  const [isOpen, setIsOpen] = useState(false);
  const hasPredefinedPatterns = predefinedPatterns && predefinedPatterns.length > 0;

  const [structure, setStructure] = useState(`${JSON.stringify(activeNode?.attrs?.cardStructure)}`);
  const [errors, setErrors] = useState<string[]>();
  const hasErrors = errors && errors.length > 0;
  const [editorInstance, setEditorInstance] = useState<any>();

  const onClose = () => {
    setIsOpen(false);
  };

  // We only want this to ensure the data is fresh when the dialog state is changed
  useEffect(() => {
    setStructure(JSON.stringify(activeNode?.attrs?.cardStructure));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const allowedSchema = useMemo(() => {
    return {
      type: 'object',
      properties: {
        node: {
          type: 'string',
          enum: allowedProperties,
        },
        className: { type: 'string', default: '' },
        children: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              node: {
                type: 'string',
                enum: allowedProperties,
              },
              className: { type: 'string', default: '' },
              children: { type: 'array', items: { $ref: '#' } }, // Recursive reference
            },
            required: ['node', 'children'],
            additionalProperties: false,
          },
        },
      },
      required: ['node', 'children'],
      additionalProperties: false,
    };
  }, [allowedProperties]);

  const validateJSON = (jsonString: string) => {
    try {
      const parsed = JSON.parse(jsonString);
      const valid = ajv.validate(allowedSchema, parsed);

      if (!valid) {
        const ajvErrors = ajv.errors?.map((error) => `${error.message}`);
        setErrors(ajvErrors || []);
        return false;
      }
      setErrors([]);
      return true;
    } catch (e) {
      setErrors(['Invalid JSON format']);
      return false;
    }
  };

  const handleFormat = () => {
    editorInstance?.getAction('editor.action.formatDocument').run();
  };

  const handleReset = () => {
    editorInstance?.getModel()?.setValue(JSON.stringify(activeNode?.attrs?.cardStructure));
    setStructure(JSON.stringify(activeNode?.attrs?.cardStructure));
    handleFormat();
  };

  const handleUpdate = useCallback(() => {
    if (!activeNodeResult || hasErrors) return;

    try {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(
          activeNodePos,
          'cardStructure',
          getCardStructure(activeNode?.attrs?.cardStructure, structure)
        );
        return true;
      });
      onClose();
    } catch (error) {
      console.error('Error parsing JSON string:', error);
    }
  }, [editor, activeNodePos, structure, activeNodeResult, activeNode?.attrs?.cardStructure, hasErrors]);

  return (
    <>
      <div className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Structure
        </Text>

        <button
          type="button"
          className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <div className="w-full justify-between flex items-center gap-2 p-2 ">
            <div className="flex items-center gap-1">
              <BracketsCurly className="text-wb-secondary" weight="bold" />
              <Text
                size="2xs"
                weight="medium"
                className="whitespace-nowrap overflow-hidden overflow-ellipsis pr-2 max-w-[80px]"
              >
                Structure
              </Text>
            </div>

            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </button>
      </div>

      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="w-[100vw] max-w-none h-[100vh] flex flex-col overflow-hidden">
          <DialogHeader>
            <DialogTitle>
              <div className="flex items-end justify-between ">
                <div className="flex flex-col gap-2">
                  <Text size="xl" weight="semibold" variant="primary" as="h4">
                    Structure
                  </Text>
                  <Tooltip placement="top" center="Allowed nodes" className="flex-1  flex items-center">
                    <div className="flex flex-wrap gap-2 w-fit">
                      {allowedProperties.map((property) => (
                        <span className="text-xs px-2 py-1 rounded-md bg-gray-200 text-gray-900">{property}</span>
                      ))}
                    </div>
                  </Tooltip>
                </div>
                {hasPredefinedPatterns && (
                  <InputWrapper name="predefinedPattern" labelText="Patterns" className="w-80">
                    <Select
                      defaultValue=""
                      onValueChange={(value: any) => {
                        if (value) {
                          const foundPattern = predefinedPatterns.find((pattern) => pattern.name === value);
                          onUpdate?.(value);
                          setStructure(JSON.stringify(foundPattern?.value));
                        }
                      }}
                    >
                      <SelectTrigger className="w-full" id="by_pattern">
                        <SelectValue placeholder="Select a pattern" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {predefinedPatterns.map((pattern) => (
                            <SelectItem key={pattern.name} value={pattern.name}>
                              {pattern.name}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </InputWrapper>
                )}
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className="flex gap-8">
            <div className="flex flex-col gap-2 w-1/2">
              <div className="rounded-lg overflow-hidden w-full">
                <Editor
                  height="90vh"
                  width="100%"
                  defaultLanguage="json"
                  defaultValue={structure}
                  theme="vs-dark"
                  options={{
                    formatOnType: true,
                    formatOnPaste: true,
                    tabSize: 2,
                  }}
                  onChange={(value) => {
                    if (value && validateJSON(value)) {
                      setStructure(`${value}`);
                    }
                  }}
                  onMount={(e: any) => {
                    setEditorInstance(e);
                    e.getAction('editor.action.formatDocument').run();
                  }}
                  onValidate={(markers) => {
                    if (markers.length > 0) {
                      setErrors(markers.map((marker) => marker.message));
                    } else {
                      setErrors([]);
                    }
                  }}
                />
              </div>

              <div>
                {errors && errors.length > 0 ? (
                  <div className="text-red-500">
                    <Text size="sm" weight="semibold">
                      Validation Errors:
                    </Text>
                    <div className="flex flex-col gap-1">
                      {errors.map((error) => (
                        <Text size="xs" weight="medium" className="text-red-500" key={error}>
                          {error}
                        </Text>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="transform p-4 rounded-lg w-1/2 h-full flex flex-col items-center">
              {children(getCardStructure(activeNode?.attrs?.cardStructure, structure))}
            </div>
          </div>

          <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
            <div className="flex gap-2 justify-between w-full">
              <Button variant="outlined" onClick={handleReset} isDisabled={hasErrors}>
                Reset
              </Button>
              <div className="flex gap-2">
                <Button variant="outlined" onClick={handleFormat} isDisabled={hasErrors}>
                  Format JSON
                </Button>
                <Button variant="primary" onClick={handleUpdate} isDisabled={hasErrors}>
                  Apply
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
