import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { UpgradeIntentPlaceholder } from '../../components/ui/UpgradeIntentPlaceholder';

import { PaywallBreakView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    paywallBreak: {
      setPaywallBreak: () => ReturnType;
    };
  }
}

export const PaywallBreak = Node.create({
  name: 'paywallBreak',

  draggable: true,

  addAttributes() {
    return {
      id: {
        default: '',
        renderHTML: (attributes) => ({ 'data-id': attributes.id }),
        parseHTML: (element) => element.getAttribute('data-id') || '',
      },
      data: {
        default: {
          isDefault: true,
          name: 'Default',
          title: '',
          description: '',
          image_url: '',
          subheader: '',
          cta_text: '',
          benefits: [],
          offer_id: '',
          style: {
            show_image: true,
            background_color: '#F9FAFB',
            text_color: '#F9FAFB',
            button_background_color: '#F9FAFB',
            button_text_color: '#F9FAFB',
            border_radius: 0,
            container_shadow: '0 0 #00000000',
          },
          publication_id: '',
        },
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
        parseHTML: (element) => JSON.parse(element.getAttribute('data-data') || '{}') || {},
      },
    };
  },

  addOptions() {
    return {
      enabled: false,
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setPaywallBreak:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
            })
            .run(),
    };
  },

  addNodeView() {
    if (!this.options.enabled) {
      return ReactNodeViewRenderer(
        () => (
          <UpgradeIntentPlaceholder
            description="You need to upgrade to access paid subscription features."
            url="https://www.beehiiv.com/newsletter-solutions/monetize"
            iconName="PaywallBreak"
          />
        ),
        { className: 'upgrade-intent' }
      );
    }

    return ReactNodeViewRenderer(PaywallBreakView);
  },
});

export default PaywallBreak;
