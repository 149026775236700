import { useState } from 'react';
import toast from 'react-hot-toast';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { PlusIcon } from '@heroicons/react/20/solid';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import { SortableList, SortableListItem } from '@/components/SortableList';
import { FAQ } from '@/interfaces/faq_section';
import { LandingPage } from '@/interfaces/landing_page';
import { UpgradePage } from '@/interfaces/upgrade_page';
import { Button } from '@/ui/Button';

import OptionsDropdown from '../../../Helpers/OptionsDropdown';

import QuestionForm from './QuestionForm';

interface Props {
  page: LandingPage | UpgradePage;
  handleSave: (payload: any) => void;
  isProcessing: boolean;
}

const FAQQuestionsForm = ({ page, handleSave, isProcessing = false }: Props) => {
  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<FAQ | null>(null);
  const [questionToDelete, setQuestionToDelete] = useState<FAQ | null>(null);

  // Grab the FAQ list from the landing page and add an id to help with sorting.
  // The Sortable Library requires an id for drag and drop.
  const [FAQList, setFAQList] = useState<any[]>(
    page?.faq_section?.faqs.map((item: any, index: number) => ({ ...item, id: index.toString() })) || []
  );
  const hasListItems = FAQList.length > 0;

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const activePostitionId = FAQList.find((position: any) => position.id === active.id);
      const overPostitionId = FAQList.find((position: any) => position.id === over?.id);
      const oldIndex = FAQList.indexOf(activePostitionId as any);
      const newIndex = FAQList.indexOf(overPostitionId as any);
      const newList = arrayMove(FAQList, oldIndex, newIndex);
      setFAQList(newList);
      const sanitizedList = newList.map((item: any) => {
        return { question: item.question, answer: item.answer };
      });
      handleSave({
        ...page?.faq_section,
        faqs: [...sanitizedList],
      });
    }
  };

  return (
    <div className="space-y-2">
      {/* Sortable FAQS */}
      {hasListItems ? (
        <SortableList listItems={FAQList} onDragEndEvent={onDragEnd}>
          {(list) => {
            return list.map((item: FAQ) => {
              return (
                <SortableListItem
                  className="!py-1"
                  key={item.question}
                  listItem={item}
                  onRemoveItem={() => {}}
                  text={item.question}
                  actionChild={
                    <div className="pr-1">
                      <OptionsDropdown
                        options={[
                          {
                            label: 'Edit',
                            Icon: PencilIcon,
                            onClick: () => setQuestionToEdit(item),
                          },
                          { label: 'Delete', Icon: TrashIcon, onClick: () => setQuestionToDelete(item) },
                        ]}
                      />
                    </div>
                  }
                />
              );
            });
          }}
        </SortableList>
      ) : null}

      {/** Delete a Question */}
      <ActionModal
        isOpen={Boolean(questionToDelete)}
        onClose={() => setQuestionToDelete(null)}
        onProceed={() => {
          const newList = FAQList.filter((item: any) => {
            return item.question !== questionToDelete?.question;
          });
          setFAQList(newList);
          handleSave({
            ...page?.faq_section,
            faqs: [...newList],
          });
          setQuestionToDelete(null);
        }}
        resourceId=""
        isWorking={isProcessing}
        headerText="Are you sure?"
        actionText="Delete"
        buttonType="danger"
      >
        <div className="space-y-4 text-sm text-gray-800">
          Are you sure you want to delete this question? You can always add it back later.
        </div>
      </ActionModal>

      {/* Edit a Question */}
      <QuestionForm
        isOpen={Boolean(questionToEdit)}
        isLoading={isProcessing}
        questionToEdit={questionToEdit}
        onSubmit={(payload) => {
          if (!payload.question || !payload.answer) {
            toast.error('Please fill out all fields');
            return;
          }
          const indexMatch = FAQList.findIndex((item: any) => {
            return item.question === questionToEdit?.question;
          });
          const newList = [...FAQList];
          setFAQList(newList);
          newList[indexMatch] = payload;
          const parsedList = newList.map((item: any) => {
            return { question: item.question, answer: item.answer };
          });
          handleSave({
            ...page?.faq_section,
            faqs: [...parsedList],
          });

          const listWithIds = parsedList.map((item: any, index: number) => {
            return { ...item, id: index.toString() };
          });
          setFAQList(listWithIds);
          setQuestionToEdit(null);
        }}
        onClose={() => {
          setQuestionToEdit(null);
        }}
      />

      {/* Add a New Question */}
      <Button variant="primary-inverse" size="xs" Icon={PlusIcon} onClick={() => setIsAddQuestionOpen(true)}>
        Add
      </Button>
      <QuestionForm
        isOpen={isAddQuestionOpen}
        isLoading={isProcessing}
        onSubmit={(payload) => {
          if (!payload.question || !payload.answer) {
            toast.error('Please fill out all fields');
            return;
          }

          const currentFAQs = page?.faq_section?.faqs || [];
          setFAQList([...FAQList, { ...payload, id: (FAQList.length + 1).toString() }]);
          handleSave({
            ...page?.faq_section,
            faqs: [...currentFAQs, payload],
          });
          setIsAddQuestionOpen(false);
        }}
        onClose={() => {
          setIsAddQuestionOpen(false);
        }}
      />
    </div>
  );
};

export default FAQQuestionsForm;
