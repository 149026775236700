const DESTINATION_TAG = {
  id: 'destination',
  display: 'Destination',
  description: 'Explore travel destinations around the globe and discover hidden gems.',
};

const HIKING_TAG = {
  id: 'hiking',
  display: 'Hiking',
  description: 'Find scenic trails, hiking tips, and adventure guides for outdoor enthusiasts.',
};

const FOOD_AND_DRINK_TAG = {
  id: 'food-and-drink',
  display: 'Food and Drink',
  description: 'Savor culinary delights and explore the best food and drink experiences.',
};

const HISTORY_AND_CULTURE_TAG = {
  id: 'history-and-culture',
  display: 'History & Culture',
  description: 'Dive into the rich history and cultural heritage of people and places.',
};

// 10 New Tags
const WELLNESS_TAG = {
  id: 'wellness',
  display: 'Wellness',
  description: 'Embrace a healthy lifestyle with tips on mindfulness, fitness, and self-care.',
};

const ADVENTURE_TAG = {
  id: 'adventure',
  display: 'Adventure',
  description: 'Fuel your adrenaline with activities like skydiving, rafting, and zip-lining.',
};

const LUXURY_TAG = {
  id: 'luxury',
  display: 'Luxury',
  description: 'Indulge in luxurious travel experiences, fine dining, and premium stays.',
};

const NATURE_TAG = {
  id: 'nature',
  display: 'Nature',
  description: 'Reconnect with the natural world through scenic landscapes and wildlife encounters.',
};

const FAMILY_TAG = {
  id: 'family',
  display: 'Family',
  description: 'Discover family-friendly destinations and activities for all ages.',
};

const CITY_GUIDE_TAG = {
  id: 'city-guide',
  display: 'City Guide',
  description: 'Navigate bustling urban environments with comprehensive city travel tips.',
};

const BEACH_TAG = {
  id: 'beach',
  display: 'Beach',
  description: 'Relax by the waves with guides to the best beaches and coastal experiences.',
};

const EVENTS_AND_FESTIVALS_TAG = {
  id: 'events-and-festivals',
  display: 'Events & Festivals',
  description: 'Celebrate the vibrant festivals and events happening worldwide.',
};

const PHOTOGRAPHY_TAG = {
  id: 'photography',
  display: 'Photography',
  description: 'Capture and share your travel stories through stunning photography tips.',
};

const SUSTAINABLE_TRAVEL_TAG = {
  id: 'sustainable-travel',
  display: 'Sustainable Travel',
  description: 'Travel responsibly with tips for eco-friendly and sustainable adventures.',
};

const TAGS = [
  DESTINATION_TAG,
  FOOD_AND_DRINK_TAG,
  HIKING_TAG,
  HISTORY_AND_CULTURE_TAG,
  WELLNESS_TAG,
  ADVENTURE_TAG,
  LUXURY_TAG,
  NATURE_TAG,
  FAMILY_TAG,
  CITY_GUIDE_TAG,
  BEACH_TAG,
  EVENTS_AND_FESTIVALS_TAG,
  PHOTOGRAPHY_TAG,
  SUSTAINABLE_TRAVEL_TAG,
];

export {
  ADVENTURE_TAG,
  BEACH_TAG,
  CITY_GUIDE_TAG,
  DESTINATION_TAG,
  EVENTS_AND_FESTIVALS_TAG,
  FAMILY_TAG,
  FOOD_AND_DRINK_TAG,
  HIKING_TAG,
  HISTORY_AND_CULTURE_TAG,
  LUXURY_TAG,
  NATURE_TAG,
  PHOTOGRAPHY_TAG,
  SUSTAINABLE_TRAVEL_TAG,
  TAGS,
  WELLNESS_TAG,
};
