import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  className?: string;
  paddingClassName?: string;
  heightClassName?: string;
}>;

const EmptyBlock = ({ children, className, paddingClassName = 'p-8', heightClassName = 'h-32' }: Props) => (
  <div
    className={cx(
      'flex justify-center items-center bg-gray-100 text-gray-400 text-sm rounded-md',
      className,
      paddingClassName,
      heightClassName
    )}
  >
    {children}
  </div>
);

export default EmptyBlock;
