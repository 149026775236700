import { useCallback, useEffect, useRef } from 'react';

import { GiphyGif } from '../../../../utils/giphy.types';

export type ImageItemProps = {
  image: GiphyGif;
  onSelect: (image: GiphyGif) => void;
};

const ImageItem = ({ image, onSelect }: ImageItemProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!videoRef.current || !buttonRef.current) {
      return undefined;
    }

    const video = videoRef.current;
    const button = buttonRef.current;

    const enterHandler = () => {
      video.play();
    };

    const leaveHandler = () => {
      video.pause();
      video.currentTime = 0;
    };

    video.addEventListener('mouseenter', enterHandler);
    button.addEventListener('focus', enterHandler);
    video.addEventListener('mouseleave', leaveHandler);
    button.addEventListener('blur', leaveHandler);

    return () => {
      video.removeEventListener('mouseenter', enterHandler);
      button.removeEventListener('focus', enterHandler);
      video.removeEventListener('mouseleave', leaveHandler);
      button.removeEventListener('blur', leaveHandler);
    };
  }, []);

  const handleClick = useCallback(() => {
    onSelect(image);
  }, [onSelect, image]);

  return (
    <button
      className="relative block bg-gray-100 border border-gray-200 rounded aspect-w-1 aspect-h-1"
      ref={buttonRef}
      onClick={handleClick}
      type="button"
    >
      <video
        className="absolute block object-contain w-full h-full"
        ref={videoRef}
        loop
        muted
        src={image.images.preview.mp4}
      />
    </button>
  );
};

export default ImageItem;
