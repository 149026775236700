import { SparklesIcon } from '@heroicons/react/20/solid';

import UpgradeButton from './UpgradeButton';

interface WaitingForProps {
  plan: string;
  onUpgradeClick: () => void;
}

const WhatAreYouWaitingFor: React.FC<WaitingForProps> = ({ plan, onUpgradeClick }) => (
  <div className="relative">
    <div className="bg-white rounded-xl px-8 py-28 justify-center flex">
      <div className="justify-center items-center flex space-y-8 flex-col">
        <h2 className="text-3xl font-bold mb-2">What are you waiting for?</h2>
        <UpgradeButton plan={plan} onClick={onUpgradeClick} />
      </div>
    </div>
    <SparklesIcon className="h-9 w-9 absolute top-12 left-12 sm:left-36 text-primary-500" />
    <SparklesIcon className="h-7 w-7 absolute bottom-12 right-12 sm:right-36 text-primary-400" />
  </div>
);

export default WhatAreYouWaitingFor;
