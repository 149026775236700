import { Card } from '@tremor/react';

import { BarChart } from '@/components/Chartsv2';
import { AggregateAnswer } from '@/interfaces/form';

interface Props {
  answers: AggregateAnswer[];
}

const Dropdown = ({ answers }: Props) => {
  const transformedAnswers: any = answers.map((answer) => ({
    name: answer.value,
    '# of responses': answer.count,
  }));

  return (
    <Card className="w-full">
      <BarChart data={transformedAnswers} categories={['# of responses']} index="name" showLegend={false} />
    </Card>
  );
};

export default Dropdown;
