import { Asset } from '@/interfaces/asset';

export type OnMediaPayload = {
  media: Asset;
  label?: string;
};

export enum MediaLibraryTabState {
  GIPHY = 'giphy',
  UNSPLASH = 'unsplash',
  GETTY = 'getty',
  BEEHIIV = 'beehiiv',
}

export enum MediaLibraryId {
  CURRENTPOST = 'currentPost',
  ALL = 'all',
  GLOBAL = 'global',
}

export type MediaLibraryProps = {
  onMediaSelect: (media: Asset) => {};
  onTabChange: (tab: MediaLibraryTabState) => {};
  openTab?: MediaLibraryTabState;
  openLibraryId?: MediaLibraryId;
  onLibraryChange?: (libraryId: MediaLibraryId) => {};
};

export type MediaLibraryComponentProps = {
  isOpen?: boolean;
  onClose: () => void;
  onMediaSelect: (payload: OnMediaPayload) => void;
  publicationId: string;
};
