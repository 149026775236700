import cx from 'classnames';

interface StatusIndicatorProps {
  color: 'green' | 'red' | 'yellow' | 'gray' | 'blue';
  label: string;
}

const StatusIndicator = ({ color, label }: StatusIndicatorProps) => {
  const colorClasses = {
    green: 'fill-green-500',
    red: 'fill-red-500',
    yellow: 'fill-yellow-500',
    gray: 'fill-gray-500',
    blue: 'fill-blue-500',
  }[color];

  return (
    <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
      <svg className={cx('h-1.5 w-1.5', colorClasses)} viewBox="0 0 6 6" aria-hidden="true">
        <circle cx={3} cy={3} r={3} />
      </svg>
      {label && <span className="capitalize">{label}</span>}
    </span>
  );
};

export default StatusIndicator;
