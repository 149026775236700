import { useUpdateOrganization } from '@/hooks';
import { Address } from '@/interfaces/general';
import { Organization } from '@/interfaces/organization';

import BodyContainer from '../../_components/BodyContainer';
import EditAddress from '../../_components/EditAddress';

interface Props {
  organization: Organization;
  publicationId: string;
}

const AddressForm = ({ organization, publicationId }: Props) => {
  const { mutate: updateOrganization, isLoading } = useUpdateOrganization(organization.id, publicationId);

  const handleSaveAddress = (address: Address) => {
    updateOrganization({
      street_address: address.street_address,
      city: address.city,
      state: address.state,
      zip_code: address.zip_code,
      country: address.country,
    });
  };

  const handleDeleteAddress = () => {
    updateOrganization({
      street_address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
    });
  };

  return (
    <BodyContainer>
      <EditAddress
        cardTitle="Global Email Footer Address"
        cardDescription="You can override this in your specific publications by going to the publication emails tab"
        id={undefined}
        streetAddress={organization.street_address}
        city={organization.city}
        state={organization.state}
        country={organization.country}
        zipCode={organization.zip_code || ''}
        onSave={handleSaveAddress}
        onDelete={handleDeleteAddress}
        isSaving={isLoading}
        isWhiteLabeled={false}
        isEmpty={!organization.is_address_valid}
      />
    </BodyContainer>
  );
};

export default AddressForm;
