import {
  ArrowsOutLineVertical,
  ClipboardText,
  CurrencyCircleDollar,
  File,
  HardDrive,
  HardDrives,
  Image,
  ListBullets,
  ListChecks,
  ListNumbers,
  Quotes,
  RectangleDashed,
  Slideshow,
  Square,
  SquareSplitHorizontal,
  Tag,
  Textbox,
  TextHFive,
  TextHFour,
  TextHOne,
  TextHSix,
  TextHThree,
  TextHTwo,
  TextT,
  Users,
  XLogo,
} from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { Text } from '../../../../UI/Text';
import { Tooltip } from '../../../../UI/Tooltip';
import { useActiveNode } from '../../../extensions/ActiveNode/hooks/useActiveNode';

const ICON_OPTIONS = {
  doc: File,
  text: TextT,
  paragraph: TextT,
  bulletList: ListBullets,
  listItem: ListBullets,
  accordion: HardDrives,
  orderedList: ListNumbers,
  image: Image,
  section: RectangleDashed,
  container: Square,
  columns: SquareSplitHorizontal,
  divider: ArrowsOutLineVertical,
  pricing: CurrencyCircleDollar,
  testimonials: Quotes,
  signup: Textbox,
  recommendations: ListChecks,
  post: Slideshow,
  survey: ClipboardText,
  tags: Tag,
  authors: Users,
  socials: XLogo,
  button: HardDrive,
};

const HEADING_OPTIONS = {
  1: TextHOne,
  2: TextHTwo,
  3: TextHThree,
  4: TextHFour,
  5: TextHFive,
  6: TextHSix,
};

const formatActiveNodeType = (type?: string): string => {
  if (!type) return 'unknown';

  // split camel case and capitalize each word
  const formattedType = type
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return formattedType;
};

export const BlockTypeSettings = ({ editor }: { editor: Editor }) => {
  const { activeNodeType, activeNodeAttributes } = useActiveNode(editor);
  let Icon;

  if (activeNodeType === 'heading') {
    Icon = HEADING_OPTIONS[activeNodeAttributes.level as keyof typeof HEADING_OPTIONS] || TextT;
  } else {
    Icon = ICON_OPTIONS[activeNodeType as keyof typeof ICON_OPTIONS] || TextT;
  }

  return (
    <Tooltip placement="bottom-end" center="No action available" delay={400}>
      <div className="flex items-center justify-stretch gap-2">
        <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
          <Text variant="secondary" size="2xs" weight="medium">
            Type
          </Text>
        </div>

        <div className="grow bg-wb-secondary rounded-lg shadow-sm">
          <div className="w-full justify-between flex items-center gap-2 p-2 ">
            <div className="flex items-center gap-1">
              <Icon className="text-wb-secondary" weight="bold" />
              <Text size="2xs" weight="medium" className="truncate w-[80px]" variant="primary">
                {formatActiveNodeType(
                  activeNodeType && activeNodeType.charAt(0).toUpperCase() + activeNodeType.slice(1)
                )}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
