import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';

interface Props {
  url: string;
  service: 'youtube';
  data: any;
  anchorEnabled: boolean;
  anchorId: string;
}

export const YoutubeEmbed = ({ url, service, data, anchorEnabled = false, anchorId }: Props) => {
  return (
    <NodeViewWrapper
      data-url={url}
      data-service={service}
      data-drag-handle
      {...(anchorEnabled ? { 'data-anchor': '', id: anchorId } : {})}
    >
      <iframe
        src={`https://www.youtube.com/embed/${data.id}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video"
      />
    </NodeViewWrapper>
  );
};

export default YoutubeEmbed;
