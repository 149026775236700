import { TextIndent } from '@phosphor-icons/react';

import DisabledSetting from '../../helpers/DisabledSetting';
import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';

type Props = AttributeSettingProps & {};

export const DescriptionSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;
  const isEnabled = activeNodeAttributes?.descriptionEnabled;

  return (
    <PopoverHelper title="Description" text="Description" popoverTitle="Description" Icon={TextIndent} align="end">
      <>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="descriptionEnabled"
          title="Enabled"
        />
        <DisabledSetting disabled={!isEnabled} tooltip="Description is disabled">
          <FontSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            isMarkStyle={false}
            property="descriptionFontFamily"
            title="Font"
          />
        </DisabledSetting>
        <DisabledSetting disabled={!isEnabled} tooltip="Description is disabled">
          <SimpleFontSizeSettings
            title="Size"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="descriptionFontSize"
          />
        </DisabledSetting>
        <DisabledSetting disabled={!isEnabled} tooltip="Description is disabled">
          <ColorSettings
            title="Color"
            property="descriptionColor"
            editor={editor}
            activeNodeResult={activeNodeResult}
          />
        </DisabledSetting>
      </>
    </PopoverHelper>
  );
};

export default DescriptionSettings;
