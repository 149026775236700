import { Grid } from '@tremor/react';

import { useSettings } from '@/context/settings-context';
import { PostStats } from '@/interfaces/post';
import { MetricCard } from '@/ui/MetricCard';
import { VERIFIED_CLICKS_EXPAND_METRIC_LABEL, VERIFIED_CLICKS_TOOLTIP } from '@/utils/verifiedClicksConstants';

interface Props {
  stats: PostStats;
}

const formatter = new Intl.NumberFormat('en');

const WebAndEmailStats = ({ stats }: Props) => {
  const { settings } = useSettings();
  const isVerifiedClicksV2 = settings?.verified_clicks_v2 === true;

  const totalImpressions = formatter.format(stats.total_web_viewed + stats.total_unique_opened);
  const totalClicks = formatter.format(stats.total_web_clicked + stats.total_email_clicked);
  const totalVerifiedClicks = formatter.format(stats.total_verified_email_clicked);

  return (
    <div className="flex flex-col gap-y-3">
      <Grid numCols={1} numColsSm={2} className="gap-4">
        <MetricCard
          variant="primary"
          label="Total Clicks"
          expandComplementsLabel={VERIFIED_CLICKS_EXPAND_METRIC_LABEL}
          expandComplementsTooltip={VERIFIED_CLICKS_TOOLTIP}
          initialVisibleComplements={0}
          complements={[
            ...(isVerifiedClicksV2 ? [{ label: 'Verified Total Clicks', value: totalVerifiedClicks }] : []),
          ]}
          value={totalClicks}
        />
        <MetricCard variant="primary" label="Total Impressions" value={totalImpressions} />
      </Grid>
    </div>
  );
};

export default WebAndEmailStats;
