import { useNavigate } from 'react-router-dom';

import ActionModal from '../../../../components/ActionModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const EnablePlanModal = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      onProceed={() => navigate('/monetize/subscriptions/paid_tiers')}
      resourceId=""
      isWorking={false}
      headerText="Paid subscriptions are not enabled"
      actionText="Enable in Settings"
    >
      <div className="text-sm">
        You must enable your paid subscriptions in settings before you can publish any updates or view the live page.
      </div>
    </ActionModal>
  );
};

export default EnablePlanModal;
