import { useSplitTest } from '../../../../hooks';

interface Props {
  postId: string;
}

const Summary = ({ postId }: Props) => {
  const { data: splitTest, isLoading } = useSplitTest(postId);

  if (!splitTest?.id || isLoading) return null;

  // SplitTest duration is given in seconds. Convert to hours and minutes for readability.
  const readableDuration = (duration: number) => {
    const hours = Math.floor((duration % 86400) / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    let message = '';
    if (hours > 0) {
      message += `${hours} hours`;
    }
    if (hours > 0 && minutes > 0) {
      message += ' and ';
    }
    if (minutes > 0) {
      message += `${minutes} minutes`;
    }

    return message;
  };

  const sampleSize = Math.ceil(splitTest.population_size * (splitTest.sample_pct / 100));
  const winningPct = 100 - splitTest.sample_pct;
  const winningSampleSize = splitTest.population_size - sampleSize;

  return (
    <ul className="bg-gray-100 p-4 rounded mt-4">
      <li className="text-xs font-semibold">
        A/B Test Duration:
        <span className="ml-2 text-gray-700 font-normal">{readableDuration(splitTest.duration)}</span>
      </li>
      <li className="text-xs font-semibold max-w-xl">
        A/B Test Sample Size:
        <span className="ml-2 text-gray-700 font-normal">
          {splitTest.sample_pct}% ({sampleSize.toLocaleString('en-US')} subscribers)
        </span>
      </li>
      {splitTest.sample_pct < 100 && (
        <li className="text-xs font-semibold max-w-xl">
          Winning Sample Size:
          <span className="ml-2 text-gray-700 font-normal">
            {winningPct}% ({winningSampleSize.toLocaleString('en-US')} subscribers)
          </span>
        </li>
      )}
      <li className="text-xs font-semibold max-w-xl mt-2">
        {splitTest.sample_pct < 100
          ? `*This means ${sampleSize.toLocaleString(
              'en-US'
            )} subscribers will receive one of the subject line variations and the remaining ${winningSampleSize.toLocaleString(
              'en-US'
            )} subscribers will receive the winning variation ${readableDuration(splitTest.duration)} later.`
          : `*This means ${sampleSize.toLocaleString(
              'en-US'
            )} subscribers will receive one of the subject line variations and a winner will be declared ${readableDuration(
              splitTest.duration
            )} later.`}
      </li>
    </ul>
  );
};

export default Summary;
