// import { useState } from "react"

import ActionModal from '@/components/ActionModal';
import { Publication } from '@/interfaces/publication';

interface ModalProps {
  publication: Publication;
  isOpen: boolean;
  handleOnClose: () => void;
  handleOnProceed: () => void;
}

const RequireApprovalModal = ({ publication, isOpen = false, handleOnClose, handleOnProceed }: ModalProps) => {
  return (
    <ActionModal
      isOpen={isOpen}
      onProceed={handleOnProceed}
      onClose={handleOnClose}
      resourceId={publication?.id}
      isWorking={false}
      modalMessageType="info"
      headerText="Disable Require Subscriber Approval?"
      descriptionText="Disabling this feature will auto-approve any subscribers awaiting approval."
      actionText="Disable"
    />
  );
};

export default RequireApprovalModal;
