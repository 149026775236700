import { useCallback, useEffect, useState } from 'react';

import { SiteTheme, WebTheme } from '@/interfaces/web_theme';

import { Input } from '../../../_components/UI/Input';
import InputWrapper from '../../../_components/UI/InputWrapper';
import Modal from '../../../_components/UI/Modal';

import { ColorSelection } from './ColorSelection';
import { FontSelection } from './FontSelection';
import { RadiusSelection } from './RadiusSelection';

interface Props {
  siteTheme?: SiteTheme | null;
  isSubmitting: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (payload: { name: string; data: any }) => void;
}

const DEFAULT_STYLE = {
  header_font: 'Inter',
  body_font: 'Inter',
  button_font: 'Inter',
  primary_color: '#000000',
  text_on_primary_color: '#FFFFFF',
  background_color: '#FFFFFF',
  text_on_background_color: '#000000',
  border_color: '#E5E7EB',
  border_radius: '6px',
};

const SiteThemeModal = ({ siteTheme, onClose, onSubmit, isSubmitting, isOpen }: Props) => {
  const [name, setName] = useState(siteTheme?.name || 'New Theme');
  const [style, setStyle] = useState<Partial<WebTheme> | undefined>(siteTheme?.data || DEFAULT_STYLE);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (siteTheme && !mounted) {
      setName(siteTheme.name);
      setStyle(siteTheme.data);
      setMounted(true);
    }
  }, [siteTheme, mounted]);

  const title = siteTheme ? 'Edit Theme' : 'Create Theme';
  const ctaText = siteTheme ? 'Save' : 'Create';
  const isLoadingText = siteTheme ? 'Saving...' : 'Creating...';

  const handleClose = () => {
    onClose();
    setStyle(DEFAULT_STYLE);
    setName('New Theme');
    setMounted(false);
  };

  const handleSubmit = () => {
    onSubmit({ name, data: style });
    handleClose();
  };

  const handleSetFontFamily = useCallback(
    (key: string, value: string) => {
      setStyle({ ...style, [key]: value } as WebTheme);
    },
    [style]
  );

  const handleSetColor = useCallback(
    (key: string, value: string) => {
      setStyle({ ...style, [key]: value } as WebTheme);
    },
    [style]
  );

  const handleSetRadius = useCallback(
    (value: number) => {
      setStyle({ ...style, border_radius: `${value}px` } as WebTheme);
    },
    [style]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      onConfirm={handleSubmit}
      isLoading={isSubmitting}
      ctaText={ctaText}
      isLoadingText={isLoadingText}
    >
      <div className="flex flex-col space-y-4">
        <Input
          name="theme_name"
          labelText="Name"
          placeholder="New theme"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <InputWrapper labelText="Theme">
          <div className="flex flex-col gap-4">
            <FontSelection
              title="Heading Font"
              onSetFontFamily={(value) => handleSetFontFamily('header_font', value)}
              value={style?.header_font}
            />
            <FontSelection
              title="Body Font"
              onSetFontFamily={(value) => handleSetFontFamily('body_font', value)}
              value={style?.body_font}
            />
            <FontSelection
              title="Button Font"
              onSetFontFamily={(value) => handleSetFontFamily('button_font', value)}
              value={style?.button_font}
            />
            <ColorSelection
              title="Primary Color"
              initialColor={style?.primary_color || ''}
              onSetColor={(value) => handleSetColor('primary_color', value || '')}
            />
            <ColorSelection
              title="Text on Primary Color"
              initialColor={style?.text_on_primary_color || ''}
              onSetColor={(value) => handleSetColor('text_on_primary_color', value || '')}
            />
            <ColorSelection
              title="Background Color"
              initialColor={style?.background_color || ''}
              onSetColor={(value) => handleSetColor('background_color', value || '')}
            />
            <ColorSelection
              title="Text on Background Color"
              initialColor={style?.text_on_background_color || ''}
              onSetColor={(value) => handleSetColor('text_on_background_color', value || '')}
            />
            <ColorSelection
              title="Border Color"
              initialColor={style?.border_color || ''}
              onSetColor={(value) => handleSetColor('border_color', value || '')}
            />
            <RadiusSelection title="Border Radius" min={0} max={100} unit="px" step={1} onSetRadius={handleSetRadius} />
          </div>
        </InputWrapper>
      </div>
    </Modal>
  );
};

export default SiteThemeModal;
