import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import DnsRecordDisplay from '../../../../../../components/_domain/CustomDomain/DnsRecordDisplay';
import Text from '../../../../../../components/Text';
import { useCurrentPublicationState } from '../../../../../../context/current-publication-context';
import { CustomDomain } from '../../../../../../interfaces/custom_domain';
import api from '../../../../../../services/swarm';

interface Props {
  customDomain: CustomDomain;
  onClose: () => void;
}

const CustomDomainVerify = ({ customDomain, onClose }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsLoading(true);

    api
      .post(`/custom_domains/${customDomain.id}/ownership_verifications`, {
        publication_id: currentPublicationId,
      })
      .then(() => {
        analytics.track('Configured web domain');
        toast.success('Domain has been successfully verified');
        queryClient.invalidateQueries(['custom_domains', currentPublicationId]);
        onClose();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="mb-8">
        <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0">Custom Domain</h2>
        <Text className="mt-1 text-gray-500" size="sm">
          Follow the instructions below, in order to prove ownership of{' '}
          <code className="bg-gray-100 px-2 py-1 rounded-sm">{customDomain.root_domain}</code>
        </Text>
      </div>
      <div className="pb-8 w-10/12 mt-10">
        <Text className="font-medium">Create a DNS record</Text>
        <Text size="sm" className="mt-2 text-gray-500">
          Go to your DNS provider and create a record using these values:
        </Text>
        <DnsRecordDisplay record={customDomain.verification_record} className="mt-6" />
      </div>
      <div className="mt-10 flex justify-end space-x-2">
        {!customDomain.verified && (
          <>
            <Button variant="primary-inverse" onClick={() => onClose()}>
              Back
            </Button>
            <Button onClick={() => handleSubmit()} loading={isLoading}>
              {isLoading ? 'Verifying...' : 'Verify'}
            </Button>
          </>
        )}
      </div>
      <div className="flex justify-end space-x-2">
        {isLoading && (
          <Text className="mt-4 text-gray-500" size="sm">
            This might take a little...
          </Text>
        )}
      </div>
    </>
  );
};

export default CustomDomainVerify;
