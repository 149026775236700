import React, { useCallback } from 'react';

import { Range } from '@/components/UI/Range';

export type ContrastControlProps = {
  contrast: number;
  setContrast: (contrast: number) => void;
};

export const ContrastControl = React.memo(({ contrast, setContrast }: ContrastControlProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setContrast(parseFloat(e.target.value));
    },
    [setContrast]
  );

  return <Range label="Contrast" min={-100} max={100} step="5" value={contrast} onChange={handleChange} />;
});

ContrastControl.displayName = 'ContrastControl';
