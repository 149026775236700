/* eslint-disable no-underscore-dangle */

import React, { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

interface Props {
  title: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  active?: boolean;
  buttonClassName?: string;
  chevronRight?: boolean;
  onClickHandler?: (activeValue: boolean) => void;
}

const BUTTON_BASE_CLASS = 'flex p-4 justify-start items-center w-full';

export const Drawer = (props: Props) => {
  const {
    className,
    title,
    content,
    active,
    buttonClassName = BUTTON_BASE_CLASS,
    chevronRight = false,
    onClickHandler,
  } = props;
  const [isActive, setIsActive] = useState(active || false);

  const clickHandler = (activeValue: boolean) => {
    setIsActive(activeValue);
    if (onClickHandler) {
      onClickHandler(activeValue);
    }
  };

  return (
    <div className={cx(className)}>
      <button type="button" className={buttonClassName} onClick={() => clickHandler(!isActive)}>
        {chevronRight && title}
        <ChevronDownIcon
          className={cx('h-5 w-5 transform transition-transform duration-200', isActive ? 'rotate-180' : '')}
        />
        {!chevronRight && title}
      </button>
      <Transition.Root as={Fragment} show={isActive}>
        <Transition.Child
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {content}
        </Transition.Child>
      </Transition.Root>
    </div>
  );
};

interface ChildProps {
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE?: string;
}

export const DrawerTitle: React.FunctionComponent<ChildProps> = (props: ChildProps) => {
  const { children, className } = props;
  return <div className={cx('text-md text-gray-700 font-medium', className)}>{children}</div>;
};

export const DrawerContent: React.FunctionComponent<ChildProps> = (props: ChildProps) => {
  const { children, className } = props;
  return <div className={className}>{children}</div>;
};
