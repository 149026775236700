import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  className?: string;
}>;

const FeatureList = ({ children, className }: Props) => {
  return <ul className={cx('flex flex-col space-y-6', className)}>{children}</ul>;
};

export default FeatureList;
