import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';
import { CursorArrowRaysIcon, EnvelopeIcon, EnvelopeOpenIcon, NoSymbolIcon, UserIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import useAutomationStepMetrics from '@/hooks/useAutomations/useAutomationStepMetrics';
import { AutomationStep, AutomationStepState, AutomationStepStepType } from '@/interfaces/automations/automation_step';
import getLocaleString from '@/utils/getLocaleString';

import { AutomationStepExpandedMetric } from '../components/ExpandedMetricsModal';
import {
  ACTION_STEP_TYPE_LABELS,
  AUTOMATION_NODE_WIDTH_CLASSNAME,
  COLORS_BY_AUTOMATION_STEP_TYPE,
  DEFAULT_HANDLE_COLORS,
} from '../constants';
import { useNewAutoLayoutContext } from '../context/new-layout-context';
import { CustomNodeDataType } from '../types';

import EnrollInAutomationStepNode from './AutomationStepNodes/EnrollInAutomationStepNode';
import NewBranchAutomationStepNode from './AutomationStepNodes/NewBranchAutomationStepNode';
import NewSendEmailAutomationStepNode from './AutomationStepNodes/NewSendEmailAutomationStepNode';
import NewUpdateSubscriptionAutomationStepNode from './AutomationStepNodes/NewUpdateSubscriptionAutomationStepNode';
import NewWaitUntilAutomationStepNode from './AutomationStepNodes/NewWaitUntilAutomationStepNode';
import WaitForStepNode from './AutomationStepNodes/WaitForNode';
import WebhookStepNode from './AutomationStepNodes/WebhookStepNode';
import AutomationStepMetrics, { AutomationStepMetric } from './AutomationStepMetrics';
import ContainerNode from './ContainerNode';
import ContainerNodeBody from './ContainerNodeBody';
import StatisticTags from './StatisticsTags';

interface SendEmailStepMetrics {
  click_rate: number;
  open_rate: number;
  spam_rate: number;
  total_sent: number;
  total_spam_reported: number;
  total_unique_clicked: number;
  total_unique_opened: number;
}

interface BranchStepMetrics {
  no_rate: number;
  total_yes: number;
  total_no: number;
  yes_rate: number;
}

const NewAutomationStepNode = ({ id, data, sourcePosition, targetPosition }: NodeProps<CustomNodeDataType>) => {
  const { automationSteps, automation, setIsSorting, setSortingStepId, isDrawerOpen, pushToChangeLog } =
    useNewAutoLayoutContext();
  const automationStep = automationSteps.find((step) => step.id === data.id) as AutomationStep;
  const [showMetrics, setShowMetrics] = useState(false);

  const {
    data: metrics,
    refetch: refetchMetrics,
    isFetching: isFetchingMetrics,
    isSuccess: wereMetricsFetchedSuccessfully,
  } = useAutomationStepMetrics({
    automationId: automationStep?.automation_id,
    automationStepId: automationStep?.id,
    isEnabled: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  if (!automationStep) {
    return null;
  }

  const handleDragStart = () => {
    setIsSorting(true);
    setSortingStepId(automationStep.id);
  };

  const handleDragEnd = () => {
    setIsSorting(false);
    setSortingStepId(null);
  };

  const handleConfigureClick = () => {
    navigate(`/automations/${automation.id}/workflow/steps/${automationStep.id}/configure`);
  };

  const handleShowMetricsClick = async () => {
    const newValue = !showMetrics;
    if (newValue) {
      await refetchMetrics();
    }

    setShowMetrics(newValue);
    pushToChangeLog({
      automationStepId: automationStep.id,
      action: 'toggle automation step',
      shouldShow: newValue,
    });
  };

  const isAutomationStepPublished = automationStep.state === AutomationStepState.PUBLISHED;
  const isAutomationStepBeingConfigured = !!location.pathname.match(
    `/automations/${automation.id}/workflow/steps/${id}/configure`
  );

  const handleBorderColor = isAutomationStepPublished
    ? COLORS_BY_AUTOMATION_STEP_TYPE[automationStep.step_type].border
    : DEFAULT_HANDLE_COLORS.border;

  const canShowMetrics = [AutomationStepStepType.SEND_EMAIL, AutomationStepStepType.BRANCH].includes(
    automationStep.step_type
  );
  const mappedMetrics: AutomationStepMetric[] = [];
  if (typeof metrics === 'object') {
    if (automationStep.step_type === AutomationStepStepType.SEND_EMAIL) {
      const typedMetrics = metrics as SendEmailStepMetrics;
      mappedMetrics.push({ label: 'Recipients', value: getLocaleString(typedMetrics.total_sent), icon: EnvelopeIcon });
      mappedMetrics.push({
        label: 'Open rate',
        value: `${typedMetrics.open_rate.toFixed(2)}%`,
        icon: EnvelopeOpenIcon,
      });
      mappedMetrics.push({ label: 'CTR', value: `${typedMetrics.click_rate.toFixed(2)}%`, icon: CursorArrowRaysIcon });
      mappedMetrics.push({
        label: 'Spam rate',
        value: `${typedMetrics.spam_rate.toFixed(2)}%`,
        icon: NoSymbolIcon,
      });
    } else {
      const typedMetrics = metrics as BranchStepMetrics;
      mappedMetrics.push({ label: 'True', value: `${typedMetrics.yes_rate.toFixed(2)}%`, icon: UserIcon });
      mappedMetrics.push({ label: 'False', value: `${typedMetrics.no_rate.toFixed(2)}%`, icon: UserIcon });
    }
  }
  const expandedMetrics: AutomationStepExpandedMetric[] = [];
  if (automationStep.step_type === AutomationStepStepType.SEND_EMAIL && automationStep.email_message_id) {
    expandedMetrics.push({
      type: 'send_analytics',
      resourceId: automationStep.email_message_id,
      automationStepId: automationStep.id,
    });
    expandedMetrics.push({
      type: 'polls',
      resourceId: automationStep.email_message_id,
      automationStepId: automationStep.id,
    });
    expandedMetrics.push({
      type: 'premium_data',
      resourceId: automationStep.email_message_id,
      automationStepId: automationStep.id,
    });
  }

  const canDrag =
    !isAutomationStepPublished &&
    automationStep.journey_step_counts.fresh_in_progress === 0 &&
    automationStep.step_type !== AutomationStepStepType.BRANCH;

  return (
    <div
      className={cx(
        'flex flex-col items-center rounded-md nopan',
        AUTOMATION_NODE_WIDTH_CLASSNAME,
        { 'cursor-default': !canDrag },
        { 'cursor-move': canDrag }
      )}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      draggable={canDrag}
    >
      <Handle className="opacity-0" type="target" position={targetPosition || Position.Top} />
      <ContainerNode
        uniqueIdentifier={automationStep.id}
        isActive={isAutomationStepPublished}
        isBeingConfigured={isAutomationStepBeingConfigured}
        shouldDisable={!isAutomationStepBeingConfigured && isDrawerOpen}
        stepType={automationStep.step_type}
        title={ACTION_STEP_TYPE_LABELS[automationStep.step_type]}
        onClickConfigure={handleConfigureClick}
        onClickShowMetrics={canShowMetrics ? handleShowMetricsClick : undefined}
      >
        <ContainerNodeBody className="flex flex-col gap-y-2">
          {automationStep.step_type === AutomationStepStepType.BRANCH && (
            <NewBranchAutomationStepNode automationConditions={automationStep.conditions} />
          )}

          {automationStep.step_type === AutomationStepStepType.ENROLL_IN_AUTOMATION && (
            <EnrollInAutomationStepNode
              automationIds={automationStep.enroll_in_automation_params.automation_ids || []}
            />
          )}

          {automationStep.step_type === AutomationStepStepType.SEND_EMAIL && (
            <NewSendEmailAutomationStepNode emailMessageId={automationStep.email_message_id} />
          )}

          {automationStep.step_type === AutomationStepStepType.UPDATE_SUBSCRIPTION && (
            <NewUpdateSubscriptionAutomationStepNode updateSubscriptionData={automationStep.update_subscription} />
          )}

          {automationStep.step_type === AutomationStepStepType.WAIT_FOR && (
            <WaitForStepNode seconds={automationStep.wait_for_params.seconds || 0} />
          )}

          {automationStep.step_type === AutomationStepStepType.WAIT_UNTIL && (
            <NewWaitUntilAutomationStepNode waitUntilParams={automationStep.wait_until_params} />
          )}

          {automationStep.step_type === AutomationStepStepType.WEBHOOK && automationStep.webhook_destination && (
            <WebhookStepNode webhookDestination={automationStep.webhook_destination} />
          )}

          <StatisticTags automationStep={automationStep} />
        </ContainerNodeBody>
        {showMetrics && !isFetchingMetrics && wereMetricsFetchedSuccessfully && mappedMetrics.length && (
          <ContainerNodeBody>
            <AutomationStepMetrics
              stepType={automationStep.step_type}
              metrics={mappedMetrics}
              expandedMetrics={expandedMetrics}
            />
          </ContainerNodeBody>
        )}
      </ContainerNode>

      <Handle
        className={cx('w-3.5 h-3.5 z-10 bg-gray-50 border-4', handleBorderColor)}
        style={{
          boxShadow: '0px 0px 0px 3px #F3F4F6',
        }}
        type="source"
        position={sourcePosition || Position.Bottom}
      />
    </div>
  );
};

export default NewAutomationStepNode;
