import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

const useCreateBugReport = () =>
  useMutation(
    (formData: FormData) =>
      api.post(`/bug_reports`, formData),
    {
      onSuccess: () => {
        toast.success('Bug report filed successfully');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong with your bug report request.');
      },
    }
  );

export default useCreateBugReport;
