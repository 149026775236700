import { useInfiniteQuery } from 'react-query';

import { AdNetworkCampaignPerformance } from '@/interfaces/ad_network/internal/campaign_performance';
import { Order, Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

import { AdNetworkCampaignStatus } from '../../../interfaces/ad_network/internal/campaign';

interface ApiResponse {
  campaigns: AdNetworkCampaignPerformance[];
  pagination: Pagination;
}

export enum OrderBy {
  CAMPAIGN_NAME = 'campaign_name',
  ADVERTISER_NAME = 'advertiser_name',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  CONTRACTED_CLICKS = 'contracted_clicks',
  COMPLETED_CLICKS = 'completed_clicks',
  REMAINING_CLICKS = 'remaining_clicks',
  PERCENT_DELIVERED = 'percent_delivered',
  FORECASTED = 'forecasted',
  PACING = 'pacing',
  SPEND = 'spend',
  BUDGET = 'budget',
  GAP = 'gap',
}

interface Props {
  status?: AdNetworkCampaignStatus;
  orderBy?: OrderBy;
  direction?: Order;
}

const useCampaignsPerformance = ({ status, orderBy, direction = Order.DESC }: Props) => {
  const fetchCampaigns = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/internal/campaigns/performance`, {
        params: {
          page: pageParam,
          per_page: 25,
          status,
          order_by: orderBy,
          direction,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_network', 'internal', 'campaigns', 'performance', status, orderBy, direction],
    fetchCampaigns,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
};

export default useCampaignsPerformance;
