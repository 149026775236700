import { Handle, NodeProps, Position } from 'reactflow';
import cx from 'classnames';

import { FormStatus } from '@/interfaces/form';

import NewQuestionForm from '../QuestionForm/NewQuestionForm';
import { useEditFormViewContent } from '..';

import { FORM_BUILDER_NODE_WIDTH_CLASSNAME } from './helpers';

const CustomQuestionNode = ({ id, sourcePosition, targetPosition }: NodeProps) => {
  const { form, setIsSorting, setSortingQuestionId } = useEditFormViewContent();
  const formQuestion = form.form_questions.find((question) => question.id === id);

  if (!formQuestion) {
    return null;
  }

  const isLastQuestion = form.form_questions[form.form_questions.length - 1].id === formQuestion.id;
  const canAddNewQuestion = form.status === FormStatus.DRAFT;

  const handleDragStart = () => {
    setIsSorting(true);
    setSortingQuestionId(formQuestion.id);
  };

  const handleDragEnd = () => {
    setIsSorting(false);
    setSortingQuestionId(null);
  };

  const canDrag = true;

  return (
    <div
      className={cx(
        'flex flex-col items-center rounded-md nopan',
        FORM_BUILDER_NODE_WIDTH_CLASSNAME,
        { 'cursor-default': !canDrag },
        { 'cursor-move': canDrag }
      )}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      draggable={canDrag}
    >
      <Handle className="opacity-0" type="target" position={targetPosition || Position.Top} />
      <NewQuestionForm question={formQuestion} questionType={formQuestion.question_type} />
      {isLastQuestion && !canAddNewQuestion ? null : (
        <Handle
          className={cx('w-3.5 h-3.5 z-10 bg-gray-50 border-4')}
          style={{
            boxShadow: '0px 0px 0px 3px #F3F4F6',
          }}
          type="source"
          position={sourcePosition || Position.Bottom}
        />
      )}
    </div>
  );
};

export default CustomQuestionNode;
