import { useSettings } from '@/context/settings-context';

import { useOrganization } from '../useOrganization';
import { usePublication } from '../usePublications';
import useCurrentPublicationId from '../usePublications/useCurrentPublicationId';

const useRequireVerifyIdentity = () => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: currentPublication } = usePublication(currentPublicationId);
  const { data: organizationData } = useOrganization(currentPublication?.organization_id, currentPublicationId);
  const { settings } = useSettings();
  const { organization } = organizationData || {};
  return (
    organization?.requires_stripe_identity_verification === true && settings?.boosts_identity_verification === true
  );
};

export default useRequireVerifyIdentity;
