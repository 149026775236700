import { AreaChart as TremorAreaChart, AreaChartProps } from '@tremor/react';

// https://www.tremor.so/docs/components/area-chart
// Ideally we pull from this base chart with our foundational customizations
// But you can always use the Tremor chart directly if needed
const AreaChart = (props: AreaChartProps) => {
  const { colors, ...rest } = props;
  return <TremorAreaChart colors={colors || ['pink']} {...rest} />;
};

export default AreaChart;
