import { TextAa } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';

type Props = AttributeSettingProps & {};

export const LabelSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Label" text="Label" popoverTitle="Label" Icon={TextAa}>
      <>
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          property="labelFontFamily"
          title="Font"
        />
        <ColorSettings title="Color" property="labelColor" editor={editor} activeNodeResult={activeNodeResult} />
      </>
    </PopoverHelper>
  );
};

export default LabelSettings;
