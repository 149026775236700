import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  borderClassName?: string;
}>;

const TreeHeader = ({ children, borderClassName = 'border-gray-200' }: Props) => (
  <div className="flex flex-row mb-2">
    <div className={cx('relative w-4 h-3/6 bg-white border-b-2 -left-0.5', borderClassName)}>&nbsp;</div>
    {children}
  </div>
);

export default TreeHeader;
