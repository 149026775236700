import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Tier } from '@shared/dream-components';

import { SortableList } from '@/components/SortableList';
import SimpleSortableItem from '@/routes/website/_components/UI/SimpleSortableItem';

interface Props {
  tiersInState: Tier[];
  setTiersInState: (changedTiers: Tier[]) => void;
  onClick: (tier: Tier) => void;
  selectedTier: Tier | null;
}

const SortableTiersList = ({ tiersInState, setTiersInState, onClick, selectedTier }: Props) => {
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const activePositionId = tiersInState.find((position: any) => position.id === active.id);
      const overPositionId = tiersInState.find((position: any) => position.id === over?.id);
      const oldIndex = tiersInState.indexOf(activePositionId as any);
      const newIndex = tiersInState.indexOf(overPositionId as any);
      const newList = arrayMove(tiersInState, oldIndex, newIndex);
      setTiersInState(newList);
    }
  };

  return (
    <SortableList listItems={tiersInState} onDragEndEvent={onDragEnd}>
      {(list) => {
        return list.map((item: Tier) => {
          return (
            <SimpleSortableItem
              id={item.id}
              key={item.id}
              label={item.name}
              onClick={() => onClick(item)}
              isSelected={selectedTier?.id === item.id}
            />
          );
        });
      }}
    </SortableList>
  );
};

export default SortableTiersList;
