import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { DonutChart, Legend } from '@tremor/react';

import Card from '@/components/Card';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentTimeZone } from '@/hooks';
import api from '@/services/swarm';
import { TimePeriod, valueFormatter } from '@/utils';

type Datum = {
  name: string;
  value: number;
};

type ResponseType = {
  months: number;
  years: number;
  discountedMonths: number;
  discountedYears: number;
};

const defaultStats = [
  {
    name: 'Monthly',
    value: 0,
  },
  {
    name: 'Yearly',
    value: 0,
  },
  {
    name: 'Monthly with Discount',
    value: 0,
  },
  {
    name: 'Yearly with Discount',
    value: 0,
  },
];

const formatResponse = (data: ResponseType): Datum[] => {
  const newData = [];

  newData.push(
    {
      name: 'Monthly',
      value: data.months,
    },
    {
      name: 'Yearly',
      value: data.years,
    },
    {
      name: 'Monthly with Discount',
      value: data.discountedMonths,
    },
    {
      name: 'Yearly with Discount',
      value: data.discountedYears,
    }
  );

  return newData;
};

const SubscriptionsByPlanInterval = ({ period }: { period?: TimePeriod }) => {
  const currentTimeZone = useCurrentTimeZone();
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState<Datum[]>(defaultStats);
  const [categories, setCategories] = useState<string[]>([]);
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchCounts = useCallback(() => {
    const params = {
      period,
      time_zone: currentTimeZone,
    };

    if (currentPublicationId) {
      setIsLoading(true);
      api
        .get(`/publications/${currentPublicationId}/subscriptions_payment_counts`, { params })
        .then((data) => formatResponse(data.data))
        .then((newCount) => {
          setCount(newCount);

          const newCategories = newCount.filter((item) => item.value > 0).map((item) => `${item.name} (${item.value})`);
          setCategories(newCategories);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPublicationId, currentTimeZone, period]);

  useEffect(() => {
    fetchCounts();
  }, [fetchCounts]);

  return (
    <Card title="Paid Subscribers By Pricing Option" isLoading={isLoading} className="relative w-full h-full">
      <DonutChart
        data={count}
        category="value"
        index="name"
        colors={['pink', 'blue', 'indigo', 'amber']}
        valueFormatter={valueFormatter}
      />
      <Legend categories={categories} colors={['pink', 'blue', 'indigo', 'yellow']} className="w-full justify-center" />
    </Card>
  );
};

export default SubscriptionsByPlanInterval;
