import { Plus } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';

export const NavbarDropdownColumnEmptyState = () => {
  return (
    <div className="w-full h-[180px] border border-wb-primary border-dashed p-2 flex items-center justify-center rounded-xl gap-2 navbar-column-empty-state">
      <Plus size={16} className="text-wb-secondary" />
      <Text size="sm" variant="secondary">
        Add Item
      </Text>
    </div>
  );
};
