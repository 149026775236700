import styled from 'styled-components';

import { colors } from '../../../lib/colors';
import { Divider } from '../../ui/Toolbar';

export const Styled = {
  Container: styled.div`
    color: ${colors.white[1]};
    display: flex;
    flex: 1;
    gap: 0.125rem;
    min-width: 18rem;

    ${Divider} {
      margin-left: 0.125rem;
      margin-right: 0.125rem;
    }
  `,
  ScrollContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-height: 12rem;
    overflow-y: auto;
    padding-right: 0.25rem;
    position: relative;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ::-webkit-scrollbar {
      height: 50px;
      width: 6px;
    }

    ::-webkit-scrollbar-button {
      height: 0;
      width: 0;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border: 0;
      border-radius: 70px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.3);
    }

    ::-webkit-scrollbar-thumb:active {
      background: rgba(255, 255, 255, 0.3);
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border: 0;
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  `,
  Input: styled.input`
    background: transparent;
    border: 0;
    caret-color: ${colors.pink[5]};
    color: currentColor;
    flex: 1;
    font-size: 0.875rem;
    font-weight: 500;
    height: 100%;
    line-height: 1;
    outline: 0;
    max-width: 25rem;
    padding: 0 0.5rem;

    &:focus {
      border: 0;
      box-shadow: none;
    }
  `,
  IconWrapper: styled.span`
    line-height: 0;
    margin: 0.3125rem 0 0.3125rem 0.375rem;

    svg path {
      fill: ${colors.white[3]};
    }
  `,
  EmptyState: styled.span`
    color: ${colors.white[3]};
    font-size: 0.75rem;
    line-height: 1.2;
    margin: 0.25rem 0.5rem 0.5rem;
    text-align: center;

    && {
      white-space: normal;
    }
  `,
};

export default Styled;
