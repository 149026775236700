import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { AutomationTrigger } from '../../interfaces/automations/automation_trigger';
import api from '../../services/swarm';

export default function useAutomationTriggers(automationId: string | undefined) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<[AutomationTrigger]>(
    ['automations', 'automation_triggers', automationId],
    () =>
      api
        .get(`/automation_triggers`, {
          params: { publication_id: currentPublicationId, automation_id: automationId },
        })
        .then((res) => res.data.automation_triggers),
    {
      keepPreviousData: true,
      enabled: !!automationId,
    }
  );
}
