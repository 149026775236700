import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { AxiosError } from 'axios';
import debounce from 'lodash.debounce';

import { LoadingSpinner } from '../../../../LoadingSpinner';
import { API } from '../../../lib/api';
import { EmbedService } from '../../../lib/constants';
import { usePublicationContext } from '../../../lib/context/PublicationContext';

import { BlueskyEmbed, InstagramEmbed, InstagramReelEmbed, TiktokEmbed, TwitterEmbed, YoutubeEmbed } from './embeds';

export const ServiceEmbedView = ({ updateAttributes, node }: NodeViewProps) => {
  const { publicationId } = usePublicationContext();

  const [isLoading, setIsLoading] = useState(false);

  const { url, service, error, hasFetched, data, anchorEnabled, anchorId } = useMemo(
    () => node.attrs || {},
    [node.attrs]
  );

  const componentProps = useMemo(() => {
    return { url, data, anchorEnabled, anchorId };
  }, [anchorEnabled, anchorId, data, url]);

  const updateAttrs = useMemo(() => {
    const updateAttrsInner = async (embedUrl: string, embedService: EmbedService) => {
      setIsLoading(true);

      try {
        const res = await API.getEmbedData({
          service: embedService,
          url: embedUrl,
          publicationId,
        });

        if (embedService === EmbedService.TWITTER && !res.data?.embed?.attributes?.data?.text) {
          const tweetEmbedError = new Error('[editor] Invalid data for tweet embed');

          Sentry.captureException(tweetEmbedError, {
            extra: {
              res,
              embedUrl,
              embedService,
            },
          });

          throw tweetEmbedError;
        }

        updateAttributes({ url, service, data: res.data?.embed.attributes, hasFetched: true });
      } catch (errPayload) {
        const errorMsg = (errPayload as AxiosError)?.response?.data?.error || 'Something went wrong';
        updateAttributes({ url, service, error: errorMsg, hasFetched: true });
        toast.error(`Error generating ${embedService} embed`);
      } finally {
        setIsLoading(false);
      }
    };

    return debounce(updateAttrsInner, 200);
  }, [publicationId, service, updateAttributes, url]);

  useEffect(() => {
    if (url && service && !hasFetched) {
      updateAttrs(url, service);
    }
  }, [url, service, updateAttrs, hasFetched]);

  if (isLoading) {
    return (
      <NodeViewWrapper>
        <div className="flex items-center justify-center border border-gray-300 rounded-lg h-40 w-96">
          <div className="flex space-x-2">
            <p className="text-sm text-gray-500">Loading...</p>
            <LoadingSpinner />
          </div>
        </div>
      </NodeViewWrapper>
    );
  }

  if (error) {
    return (
      <NodeViewWrapper>
        <div className="bg-red-50 p-4 text-red-800 rounded">{error}</div>
      </NodeViewWrapper>
    );
  }

  if (node.attrs.data) {
    switch (service) {
      case EmbedService.YOUTUBE:
        return <YoutubeEmbed service={EmbedService.YOUTUBE} {...componentProps} />;
      case EmbedService.TWITTER:
        return <TwitterEmbed service={EmbedService.TWITTER} {...componentProps} />;
      case EmbedService.INSTAGRAM:
        return /\/reel(s?)\//.test(url) ? (
          <InstagramReelEmbed service={EmbedService.INSTAGRAM} {...componentProps} />
        ) : (
          <InstagramEmbed service={EmbedService.INSTAGRAM} {...componentProps} />
        );
      case EmbedService.TIKTOK:
        return <TiktokEmbed service={EmbedService.TIKTOK} {...componentProps} />;
      case EmbedService.BLUESKY:
        return <BlueskyEmbed service={EmbedService.BLUESKY} {...componentProps} />;
      default:
        return null;
    }
  }

  return null;
};

export default ServiceEmbedView;
