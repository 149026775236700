import { Icon } from '../Icon';

import { Styled } from './styled';
import { BreakWrapperProps } from './types';

export const BreakWrapper = ({ icon, title, description }: BreakWrapperProps) => {
  return (
    <Styled.Container contentEditable={false}>
      <Icon name={icon} $size="1.125rem" />
      <Styled.Title>{title}</Styled.Title>
      {description && <Styled.Description>{description}</Styled.Description>}
    </Styled.Container>
  );
};

export default BreakWrapper;
