import { useEffect, useState } from 'react';

import { Typography } from '@/components/Typography';
import useAddBoostInvite from '@/hooks/boosts/grow/useAddBoostInvite';
import useCreateBoostInvitationsRestrictions from '@/hooks/boosts/grow/useCreateBoostInvitationsRestrictions';
import useDeleteBoostInvite from '@/hooks/boosts/grow/useDeleteBoostInvite';
import { BoostInvite } from '@/interfaces/boosts/grow/boost_invite';
import { Button } from '@/ui/Button';
import { Textarea } from '@/ui/Textarea';
import analytics from '@/utils/analytics';

interface Props {
  publicationId: string;
  boostOfferId: string;
  boostInvite?: BoostInvite;
  onSubmit: () => void;
  onDelete: () => void;
}

export default function CreateBoostInviteForm({ publicationId, boostOfferId, boostInvite, onSubmit, onDelete }: Props) {
  const { hasReachedInviteLimit } = useCreateBoostInvitationsRestrictions();
  const [boostInviteId, setBoostInviteId] = useState<string | undefined>(boostInvite?.id);
  const [optionWithReason, setOptionWithReason] = useState<string | undefined>();
  const [reason, setReason] = useState('');

  const { mutateAsync: addBoostInvite, isLoading: isAddingBoostInvite } = useAddBoostInvite({
    onSuccess: onSubmit,
  });

  const { mutateAsync: deleteBoostInvite, isLoading: isDeletingBoostInvite } = useDeleteBoostInvite({
    onSuccess: onDelete,
  });

  const sendInvitation = async () => {
    analytics.track('Added Boost Invite');
    try {
      const response = await addBoostInvite({
        boostOfferId,
        recommendingPublicationId: publicationId,
        inviteReason: reason,
      });
      if (response.boost_invite) {
        setBoostInviteId(response.boost_invite.id);
      }
    } catch (error) {
      // do something
    }
  };

  const cancelInvitation = async (id?: string) => {
    analytics.track('Deleted Boost Invite');
    try {
      await deleteBoostInvite({
        id,
        boostOfferId,
      });
      setBoostInviteId(undefined);
    } catch (error) {
      // do something
    }
  };

  const handleSubmit = () => {
    if (boostInviteId) {
      cancelInvitation(boostInviteId);
    } else {
      sendInvitation();
    }
  };

  useEffect(() => {
    if (!boostInvite) {
      setBoostInviteId(undefined);
      setOptionWithReason(undefined);
    }
  }, [boostInvite, publicationId]);

  return (
    <div className="flex flex-auto flex-col justify-between p-6 space-y-4">
      <div>
        {boostInviteId && (reason || boostInvite?.invite_reason) ? (
          <Typography token="font-normal/text/sm" colorWeight="500">
            {reason || boostInvite?.invite_reason}
          </Typography>
        ) : null}
        {!boostInviteId ? (
          <Textarea
            name="reason"
            value={optionWithReason === publicationId ? reason : ''}
            onChange={(e) => {
              setOptionWithReason(publicationId);
              setReason(e.target.value);
            }}
            labelText="Reason for inviting this publication"
            placeholderText="We could be a good fit as partners because..."
          />
        ) : null}
      </div>
      <Button
        disabled={isAddingBoostInvite || isDeletingBoostInvite || (!boostInviteId && hasReachedInviteLimit)}
        variant={boostInviteId ? 'primary-inverse' : 'primary'}
        onClick={handleSubmit}
      >
        {boostInviteId ? 'Cancel Invitation' : 'Send Boost Invite'}
      </Button>
      <Typography token="font-light/text/xs">
        Your invite will expire after 7 days if this publication does not accept your Boost invite.
      </Typography>
    </div>
  );
}
