import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

type SubscriptionTierBreakdown = {
  free: number;
  premium: number;
};

type RevenueMetrics = {
  revenue_ytd: number;
  current_mrr: number;
  current_arr: number;
};

type LifetimeValueMetrics = {
  ltv?: number;
  avg_time_to_upgrade?: number;
  avg_subscription_duration?: number;
};

type PlanBreakdown = {
  monthly_plans: number;
  annual_plans: number;
  upgrades_over_time: number;
  upgrades_on_signup: number;
  total_upgrades: number;
};

export interface ApiResponse {
  subscription_tier_breakdown: SubscriptionTierBreakdown;
  revenue_metrics: RevenueMetrics;
  lifetime_value_metrics: LifetimeValueMetrics;
  plan_breakdown: PlanBreakdown;
}

const useTopLevelMetrics = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<ApiResponse>(['metrics', 'premium_data', currentPublicationId], () =>
    api
      .get(`/premium_data/publication_top_level_metrics`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data)
  );
};

export default useTopLevelMetrics;
