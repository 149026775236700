import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { ExternalRssFeed, ExternalRssFeedApiPayload } from '@/interfaces/external_rss_feed';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

interface Props {
  publicationId: string;
  externalRssFeedId: string;
  onSuccess?: () => void;
}

const transformExternalRssFeed: (externalRssFeed: ExternalRssFeed) => ExternalRssFeedApiPayload = (externalRssFeed) => {
  return {
    url: externalRssFeed.url,
    name: externalRssFeed.name,
    entries_to_show: externalRssFeed.entriesToShow,
    cta_text: externalRssFeed.ctaText,
    display_title: externalRssFeed.displayTitle,
    display_cta: externalRssFeed.displayCta,
    display_author: externalRssFeed.displayAuthor,
    display_content: externalRssFeed.displayContent,
    display_description: externalRssFeed.displayDescription,
    display_pub_date: externalRssFeed.displayPubDate,
    display_thumbnail: externalRssFeed.displayThumbnail,
    display_title_above_thumbnail: externalRssFeed.displayTitleAboveThumbnail,
    display_categories: externalRssFeed.displayCategories,
    cta_style: externalRssFeed.ctaStyle,
    thumbnail_position: externalRssFeed.thumbnailPosition,
    cta_alignment: externalRssFeed.ctaAlignment,
  };
};

export default function useExternalRssFeedUpdate({ publicationId, externalRssFeedId, onSuccess }: Props) {
  return useMutation<ExternalRssFeed, ErrorResponse, ExternalRssFeed>(
    (externalRssFeed: ExternalRssFeed) =>
      api
        .put(`/publications/${publicationId}/external_rss_feeds/${externalRssFeedId}`, {
          external_rss_feed: transformExternalRssFeed(externalRssFeed),
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        analytics.track('Generated an RSS Feed');
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (error) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      },
    }
  );
}
