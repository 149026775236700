/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading  */

import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

import ActionModal from '../../../../components/ActionModal';
import { Input } from '../../../../components/Form';
import { useUpdateFormQuestionOption } from '../../../../hooks/useForms';
import useDeleteFormQuestionOption from '../../../../hooks/useForms/useDeleteFormQuestionOption';
import { FormQuestion, FormQuestionOption } from '../../../../interfaces/form';
import { SaveButton } from '../../../Settings/Components';

interface Props {
  question: FormQuestion;
  option: FormQuestionOption;
  hasOnlyOneOption: boolean;
  isEditInProgress: boolean;
  idsWithEditsInProgress: string[];
  setIdsWithEditsInProgress: (ids: string[]) => void;
}

const QuestionOption = ({
  question,
  option,
  hasOnlyOneOption,
  isEditInProgress,
  idsWithEditsInProgress,
  setIdsWithEditsInProgress,
}: Props) => {
  const queryClient = useQueryClient();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(isEditInProgress);
  const [optionValue, setOptionValue] = useState(option.value);
  const changesDetected = optionValue !== option.value;

  const { attributes, listeners, setNodeRef, transform } = useSortable({ id: option.id });

  useEffect(() => {
    setOptionValue(option.value);
  }, [option]);

  const handleAddToGlobalInProgress = () => {
    setIsEditing(true);
    setIdsWithEditsInProgress([...idsWithEditsInProgress, option.id]);
  };

  const handleRemoveFromGlobalInProgress = () => {
    setIsEditing(false);
    setIdsWithEditsInProgress(idsWithEditsInProgress.filter((id) => id !== option.id));
  };

  useEffect(() => {
    if (isEditInProgress && !isEditing) {
      setIsEditing(true);
    } else if (!isEditInProgress && isEditing) {
      setIsEditing(false);
    }
  }, [isEditInProgress]);

  const { formId } = useParams();
  const updateOption = useUpdateFormQuestionOption({
    formQuestionId: question.id,
    formId: formId || '',
    optionId: option.id,
    onSuccess: () => {
      toast.success('Saved!');
      queryClient.invalidateQueries(['forms', formId]);
      handleRemoveFromGlobalInProgress();
    },
  });
  const handleSave = () => {
    if (changesDetected) {
      updateOption.mutate({ value: optionValue });
    } else {
      setIsEditing(false);
    }
  };

  const deleteOption = useDeleteFormQuestionOption({
    formId: formId || '',
    formQuestionId: question.id,
    optionId: option.id,
  });
  const handleDelete = () => {
    deleteOption.mutate();
    setIsDeleting(false);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div className="w-full" ref={!hasOnlyOneOption ? setNodeRef : null} style={style}>
      <ActionModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onProceed={handleDelete}
        resourceId={question.id}
        isWorking={deleteOption.isLoading}
        headerText="Delete Option"
        actionText="Delete"
      >
        Are you sure you want to delete this option?
      </ActionModal>
      <div className="flex">
        {!hasOnlyOneOption && (
          <button
            type="button"
            className="bg-gray-100 hover:bg-gray-200 transition-all px-1 rounded mr-2"
            {...attributes}
            {...listeners}
          >
            <div className="flex space-x-1 -ml-2">
              <div className="w-4 h-4">
                <svg
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="opacity-20"
                >
                  <path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                  <path d="m20 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                </svg>
              </div>
            </div>
          </button>
        )}

        <div className="flex space-x-2 items-center justify-between w-full">
          {!isEditing ? (
            <p className="block text-sm font-semibold text-gray-700 break-words w-[400px]">{optionValue}</p>
          ) : (
            <Input
              className="w-full max-w-md"
              name={option.value.split(' ').join('-')}
              placeholder="Option"
              value={optionValue}
              onChange={(e) => setOptionValue(e.target.value)}
            />
          )}

          {!isEditing ? (
            <div className="flex space-x-1">
              <button
                className="h-6 w-6 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                type="button"
                onClick={handleAddToGlobalInProgress}
              >
                <PencilIcon className="h-3 w-3" />
              </button>
              <button
                className="h-6 w-6 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                type="button"
                onClick={() => setIsDeleting(true)}
              >
                <TrashIcon className="h-3 w-3" />
              </button>
            </div>
          ) : (
            <div>
              <SaveButton
                isSaving={updateOption.isLoading}
                closeEditing={handleRemoveFromGlobalInProgress}
                isEditing={isEditing}
                changesDetected={changesDetected}
                type="button"
                onClick={handleSave}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionOption;
