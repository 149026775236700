import { useNavigate } from 'react-router-dom';
import { AdjustmentsHorizontalIcon, ArrowPathIcon, PlusIcon } from '@heroicons/react/20/solid';

import Card from '@/components/Card';
import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { OrderBy } from '@/interfaces/subscribers_preferences';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import { usePreferencesContext } from '../context';

import PreferencesListItem from './PreferencesListItem';

const PreferencesList = () => {
  const {
    preferencesList,
    isListEmpty,
    isListLoading,
    handleClickOrderButton,
    orderBy,
    direction,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    totalPreferencesCount,
    searchQuery,
    setSearchQuery,
  } = usePreferencesContext();

  const navigate = useNavigate();

  if (isListEmpty && !isListLoading) {
    const title = 'No Preferences Created';
    const description = searchQuery
      ? `No results found for search, '${searchQuery}'`
      : 'Create a preference center for your subscriber management page';
    const primaryActionIcon = searchQuery ? ArrowPathIcon : PlusIcon;
    const primaryActionLabel = searchQuery ? 'Reset Search' : 'New Preference';
    const onPrimaryActionClick = () => (searchQuery ? setSearchQuery('') : navigate('/subscribers/preferences/new'));

    return (
      <EmptyCard
        primaryIcon={AdjustmentsHorizontalIcon}
        primaryIconTransparentBackground
        primaryIconSize="lg"
        title={title}
        description={description}
        primaryActionIcon={primaryActionIcon}
        primaryActionLabel={primaryActionLabel}
        onPrimaryActionClick={onPrimaryActionClick}
      />
    );
  }

  return (
    <Card>
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-2">
          <div className="flex items-center p-2 bg-surface-50 rounded-md">
            <Typography token="font-semibold/text/base" colorWeight="700">
              Subscriber Management Preferences
            </Typography>
          </div>
          <Typography token="font-normal/text/sm" colorWeight="500">
            Configure the preferences you want available for your subscribers on your website
          </Typography>
        </div>
        <div className="flex flex-col gap-y-2">
          <ResourceListSearchText showingCount={preferencesList.length} totalCount={totalPreferencesCount} />
          <Items>
            <ItemHeaders>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={OrderBy.NAME}
                isSorting={orderBy === OrderBy.NAME}
                currentDirection={direction}
                align={AlignType.LEFT}
              >
                Name
              </ItemHeader>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={OrderBy.DESCRIPTION}
                isSorting={orderBy === OrderBy.DESCRIPTION}
                currentDirection={direction}
                align={AlignType.LEFT}
              >
                Description
              </ItemHeader>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={OrderBy.FORMAT_TYPE}
                isSorting={orderBy === OrderBy.FORMAT_TYPE}
                currentDirection={direction}
                align={AlignType.LEFT}
              >
                Format Type
              </ItemHeader>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={OrderBy.CUSTOM_FIELD}
                isSorting={orderBy === OrderBy.CUSTOM_FIELD}
                currentDirection={direction}
                align={AlignType.LEFT}
              >
                Custom Field
              </ItemHeader>
              <ItemHeader
                onClick={handleClickOrderButton}
                orderBy={OrderBy.SETTINGS}
                isSorting={orderBy === OrderBy.SETTINGS}
                currentDirection={direction}
                align={AlignType.LEFT}
              >
                Settings
              </ItemHeader>
              <ItemHeader isSticky>&nbsp;</ItemHeader>
            </ItemHeaders>

            <ItemsBody>
              {preferencesList.map((item) => (
                <PreferencesListItem item={item} key={item.id} />
              ))}
            </ItemsBody>
          </Items>
        </div>
        {hasNextPage && (
          <div className="flex justify-start mt-8 w-fit">
            <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default PreferencesList;
