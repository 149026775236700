export const DropdownCardPreview = () => {
  return <svg width="120" height="56" viewBox="0 0 120 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2028_44298)">
      <g clipPath="url(#clip0_2028_44298)">
        <path d="M2 6C2 3.79086 3.79086 2 6 2H114C116.209 2 118 3.79086 118 6V11C118 13.2091 116.209 15 114 15H6C3.79086 15 2 13.2091 2 11V6Z" fill="white" />
        <rect width="116" height="13" transform="translate(2 2)" fill="#F2F4F7" />
        <path d="M7.88281 8.5C7.88281 7.11929 9.0021 6 10.3828 6H17.3828C18.7635 6 19.8828 7.11929 19.8828 8.5C19.8828 9.88071 18.7635 11 17.3828 11H10.3828C9.0021 11 7.88281 9.88071 7.88281 8.5Z" fill="#5E5DEF" />
        <path d="M49.7051 8.5C49.7051 7.11929 50.8244 6 52.2051 6H69.2051C70.5858 6 71.7051 7.11929 71.7051 8.5C71.7051 9.88071 70.5858 11 69.2051 11H52.2051C50.8244 11 49.7051 9.88071 49.7051 8.5Z" fill="#D0D5DD" />
        <path d="M77.933 8.04162L76.3705 9.60412C76.3487 9.62597 76.3229 9.6433 76.2944 9.65513C76.2659 9.66696 76.2354 9.67305 76.2045 9.67305C76.1737 9.67305 76.1431 9.66696 76.1146 9.65513C76.0861 9.6433 76.0603 9.62597 76.0385 9.60412L74.476 8.04162C74.432 7.99759 74.4072 7.93787 74.4072 7.8756C74.4072 7.81333 74.432 7.75362 74.476 7.70959C74.52 7.66556 74.5797 7.64082 74.642 7.64082C74.7043 7.64082 74.764 7.66556 74.808 7.70959L76.2047 9.10627L77.6014 7.70939C77.6454 7.66536 77.7051 7.64062 77.7674 7.64062C77.8297 7.64062 77.8894 7.66536 77.9334 7.70939C77.9774 7.75342 78.0022 7.81314 78.0022 7.87541C78.0022 7.93767 77.9774 7.99739 77.9334 8.04142L77.933 8.04162Z" fill="#98A2B3" />
        <path d="M83.4121 8.5C83.4121 7.11929 84.5314 6 85.9121 6H92.9121C94.2928 6 95.4121 7.11929 95.4121 8.5C95.4121 9.88071 94.2928 11 92.9121 11H85.9121C84.5314 11 83.4121 9.88071 83.4121 8.5Z" fill="#D0D5DD" />
        <path d="M100.117 8.5C100.117 7.11929 101.236 6 102.617 6H109.617C110.998 6 112.117 7.11929 112.117 8.5C112.117 9.88071 110.998 11 109.617 11H102.617C101.236 11 100.117 9.88071 100.117 8.5Z" fill="#D0D5DD" />
      </g>
      <path d="M2.16667 6C2.16667 3.88291 3.88291 2.16667 6 2.16667H114C116.117 2.16667 117.833 3.88291 117.833 6V11C117.833 13.1171 116.117 14.8333 114 14.8333H6C3.88291 14.8333 2.16667 13.1171 2.16667 11V6Z" stroke="#E4E7EC" strokeWidth="0.333333" />
    </g>
    <g filter="url(#filter1_dd_2028_44298)">
      <rect x="36" y="17" width="48" height="35.2673" rx="3.80198" fill="white" shapeRendering="crispEdges" />
      <rect x="36.1188" y="17.1188" width="47.7624" height="35.0297" rx="3.68317" stroke="#E4E7EC" strokeWidth="0.237624" shapeRendering="crispEdges" />
      <path d="M39.8018 21.8027C39.8018 21.2504 40.2495 20.8027 40.8018 20.8027H55.8018C56.354 20.8027 56.8018 21.2504 56.8018 21.8027C56.8018 22.355 56.354 22.8027 55.8018 22.8027H40.8018C40.2495 22.8027 39.8018 22.355 39.8018 21.8027Z" fill="#D0D5DD" />
      <rect x="39.8018" y="26.6055" width="9.50495" height="9.50495" rx="1.90099" fill="#F2F4F7" />
      <path d="M46.2174 30.9716C46.2174 32.0112 44.6759 32.8527 44.6103 32.8874C44.593 32.8967 44.5736 32.9016 44.554 32.9016C44.5343 32.9016 44.515 32.8967 44.4977 32.8874C44.4321 32.8527 42.8906 32.0112 42.8906 30.9716C42.8909 30.7274 42.988 30.4934 43.1606 30.3208C43.3332 30.1482 43.5673 30.0511 43.8114 30.0508C44.1181 30.0508 44.3866 30.1827 44.554 30.4056C44.7214 30.1827 44.9899 30.0508 45.2966 30.0508C45.5407 30.0511 45.7747 30.1482 45.9474 30.3208C46.12 30.4934 46.2171 30.7274 46.2174 30.9716Z" fill="#98A2B3" />
      <path d="M51.6826 29.3574C51.6826 28.8051 52.1303 28.3574 52.6826 28.3574H79.1975C79.7498 28.3574 80.1975 28.8051 80.1975 29.3574C80.1975 29.9097 79.7498 30.3574 79.1975 30.3574H52.6826C52.1303 30.3574 51.6826 29.9097 51.6826 29.3574Z" fill="#E4E7EC" />
      <path d="M51.6826 33.3574C51.6826 32.8051 52.1303 32.3574 52.6826 32.3574H62.6826C63.2349 32.3574 63.6826 32.8051 63.6826 33.3574C63.6826 33.9097 63.2349 34.3574 62.6826 34.3574H52.6826C52.1303 34.3574 51.6826 33.9097 51.6826 33.3574Z" fill="#F2F4F7" />
      <rect x="39.8018" y="38.9609" width="9.50495" height="9.50495" rx="1.90099" fill="#F2F4F7" />
      <path d="M46.2174 43.327C46.2174 44.3666 44.6759 45.2081 44.6103 45.2429C44.593 45.2522 44.5736 45.2571 44.554 45.2571C44.5343 45.2571 44.515 45.2522 44.4977 45.2429C44.4321 45.2081 42.8906 44.3666 42.8906 43.327C42.8909 43.0829 42.988 42.8489 43.1606 42.6762C43.3332 42.5036 43.5673 42.4065 43.8114 42.4062C44.1181 42.4062 44.3866 42.5381 44.554 42.7611C44.7214 42.5381 44.9899 42.4062 45.2966 42.4062C45.5407 42.4065 45.7747 42.5036 45.9474 42.6762C46.12 42.8489 46.2171 43.0829 46.2174 43.327Z" fill="#98A2B3" />
      <path d="M51.6826 41.7129C51.6826 41.1606 52.1303 40.7129 52.6826 40.7129H79.1975C79.7498 40.7129 80.1975 41.1606 80.1975 41.7129C80.1975 42.2652 79.7498 42.7129 79.1975 42.7129H52.6826C52.1303 42.7129 51.6826 42.2652 51.6826 41.7129Z" fill="#E4E7EC" />
      <path d="M51.6826 45.7129C51.6826 45.1606 52.1303 44.7129 52.6826 44.7129H62.6826C63.2349 44.7129 63.6826 45.1606 63.6826 45.7129C63.6826 46.2652 63.2349 46.7129 62.6826 46.7129H52.6826C52.1303 46.7129 51.6826 46.2652 51.6826 45.7129Z" fill="#F2F4F7" />
    </g>
    <defs>
      <filter id="filter0_d_2028_44298" x="-10" y="-2" width="140" height="37" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_2028_44298" />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.180392 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2028_44298" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2028_44298" result="shape" />
      </filter>
      <filter id="filter1_dd_2028_44298" x="33.6238" y="15.5743" width="52.7525" height="40.0201" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="0.475248" operator="erode" in="SourceAlpha" result="effect1_dropShadow_2028_44298" />
        <feOffset dy="0.950495" />
        <feGaussianBlur stdDeviation="1.42574" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2028_44298" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="0.475248" operator="erode" in="SourceAlpha" result="effect2_dropShadow_2028_44298" />
        <feOffset dy="0.950495" />
        <feGaussianBlur stdDeviation="1.42574" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_2028_44298" result="effect2_dropShadow_2028_44298" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2028_44298" result="shape" />
      </filter>
      <clipPath id="clip0_2028_44298">
        <path d="M2 6C2 3.79086 3.79086 2 6 2H114C116.209 2 118 3.79086 118 6V11C118 13.2091 116.209 15 114 15H6C3.79086 15 2 13.2091 2 11V6Z" fill="white" />
      </clipPath>
    </defs>
  </svg>

}
