import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import VerifyIdentity from '@/components/VerifyIdentity';
import { useCurrentPublication, useOrganization } from '@/hooks';
import useRequireVerifyIdentity from '@/hooks/boosts/useRequireVerifyIdentity';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useStripeIdentityVerificationSession from '@/hooks/useUsers/useStripeIdentityVerificationSession';

const MAX_POLL_COUNT = 20;
const POLL_INTERVAL = 5000;

type Props = PropsWithChildren<{
  titleText: string;
  className?: string;
}>;

const VerifyStripeIdentityForm = ({ children, titleText, className = 'bg-surface-200/60' }: Props) => {
  const refetchOrganizationCount = useRef(0);
  const [isPollingOrganization, setIsPollingOrganization] = useState(false);
  const currentPublicationId = useCurrentPublicationId();
  const { data: currentPublication } = useCurrentPublication();
  const {
    refetch: refetchOrganization,
    isRefetching: isRefetchingOrganization,
    isRefetchError: hasRefetchingOrganizationError,
  } = useOrganization(currentPublication?.organization_id, currentPublicationId);
  const requiresIdentityVerification = useRequireVerifyIdentity();

  const handleVerificationComplete = async () => {
    if (hasRefetchingOrganizationError || isRefetchingOrganization) {
      return;
    }

    if (refetchOrganizationCount.current < MAX_POLL_COUNT) {
      if (!isPollingOrganization) {
        setIsPollingOrganization(true);
      }

      await refetchOrganization();
      setTimeout(handleVerificationComplete, POLL_INTERVAL);

      refetchOrganizationCount.current += 1;
    } else {
      setIsPollingOrganization(false);
      refetchOrganizationCount.current = 0;
    }
  };

  const {
    mutation: { mutateAsync, isLoading },
    stripe,
    isSubmitting,
  } = useStripeIdentityVerificationSession({
    organizationId: currentPublication?.organization_id,
    onVerificationComplete: handleVerificationComplete,
  });

  const handleClickVerify = async () => {
    await mutateAsync({});
  };

  useEffect(() => {
    if (!requiresIdentityVerification) {
      setIsPollingOrganization(false);
      refetchOrganizationCount.current = 0;
    }
  }, [requiresIdentityVerification]);

  if (!requiresIdentityVerification) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <>
      <div
        className={cx(
          'absolute z-10 top-0 left-0 w-full h-full flex flex-col gap-y-3 items-center justify-center rounded-md',
          className
        )}
      >
        <Typography size="xs" weight="semibold">
          {isPollingOrganization ? 'Verifying your identity...' : titleText}
        </Typography>
        <VerifyIdentity
          isDisabled={!stripe}
          isBusy={isLoading || isSubmitting || isPollingOrganization}
          onClickVerify={handleClickVerify}
          hasSubmitted={false}
          verificationCompleteMessage="We're processing the results, expect an email with the outcome shortly. If your verification is successful we'll remove the restriction immediately."
        />
        <Typography size="xs" colorWeight="500" className="italic">
          This should only take a minute
        </Typography>
      </div>
      <div className="blur-sm p-6">{children}</div>
    </>
  );
};

export default VerifyStripeIdentityForm;
