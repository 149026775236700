import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { Input } from '@/components/Form';
import { useCurrentUser } from '@/context/current-user-context';
import useUpdatePublicationWithFormData from '@/hooks/usePublications/useUpdatePublicationWithFormData';
import { Publication } from '@/interfaces/publication';
import FileUploader from '@/ui/FileUploader';
import { Textarea } from '@/ui/Textarea';
import analytics from '@/utils/analytics';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import FormRow from '../../_components/FormRow';
import { usePublicationSettings } from '../context';

import SelectLanguage from './SelectLanguage';
import SelectTags from './SelectTags';
import SelectTimezone from './SelectTimezone';

interface Props {
  publication: Publication;
}

const GeneralInfoForm = ({ publication }: Props) => {
  const queryClient = useQueryClient();
  const { settings, handleChangeInAttribute, savingAttribute, setSavingAttribute } = usePublicationSettings();
  const { mutateAsync: updatePublicationWithFormData } = useUpdatePublicationWithFormData(publication?.id || '');
  const { currentUser } = useCurrentUser();

  const canUpdateLanguage = settings?.localization;

  const handleUpdateLogo = (publicationId: string) => async (logo: any) => {
    const formData = new FormData();
    formData.append('publication_id', publicationId);
    formData.append('publication[logo]', logo);

    try {
      setSavingAttribute('logo');
      await updatePublicationWithFormData(formData);
      analytics.identify(currentUser?.id, {
        email: currentUser?.email,
      });
      analytics.group(publicationId, {
        logo,
        object_type_id: 1,
      });
      analytics.track('Added a Logo');
      toast.success(`Logo saved!`);
      // Invalidate so publication name in the sidebar updates
      queryClient.invalidateQueries([currentUser?.id, 'organizations']);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }

    setSavingAttribute(null);
  };

  const handleUpdateThumbnail = (publicationId: string) => async (thumbnail: any) => {
    const formData = new FormData();
    formData.append('publication_id', publicationId);
    formData.append('publication[thumbnail]', thumbnail);

    try {
      setSavingAttribute('thumbnail');
      await updatePublicationWithFormData(formData);
      analytics.identify(currentUser?.id, {
        email: currentUser?.email,
      });
      analytics.group(publicationId, {
        thumbnail: `https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,format=auto,onerror=redirect,quality=80/uploads/publication/thumbnail/${publicationId}/${thumbnail.name}`,
        object_type_id: 1,
      });
      analytics.track('Added a Thumbnail');
      toast.success(`Thumbnail saved!`);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }

    setSavingAttribute(null);
  };

  const handleBlur = async (attributeName: keyof Publication, value: any) => {
    const excludedAttributes = ['tag_ids'];
    if (publication[attributeName] === value && !excludedAttributes.includes(attributeName)) {
      // Don't save if the value hasn't changed
      return;
    }

    try {
      setSavingAttribute(attributeName);
      await handleChangeInAttribute(attributeName)(value);

      if (attributeName === 'name') {
        // Invalidate so publication name in the sidebar updates
        queryClient.invalidateQueries([currentUser?.id, 'organizations']);
      }
    } catch (error: any) {
      setSavingAttribute(null);
    }
  };

  return (
    <BodyContainer>
      <CardHeader
        title="General Info"
        description="Manage the global information for your publication such as logo, name, and description"
      />
      <Input
        labelText="Publication Name"
        name="name"
        defaultValue={publication.name}
        onBlur={(e) => handleBlur('name', e.target.value)}
        disabled={savingAttribute === 'name'}
      />
      <Textarea
        labelText="Publication One-Line Description"
        name="description"
        defaultValue={publication.description}
        onBlur={(e) => handleBlur('description', e.target.value)}
        disabled={savingAttribute === 'description'}
      />
      <FormRow
        label="Publication Logo"
        helperText="This will be the logo that appears across your publication. It will also be your default favicon and mobile app logo."
        tip="Recommended: 800x800 px"
      >
        <FileUploader onSave={handleUpdateLogo(publication.id)} isBusy={savingAttribute === 'logo'}>
          {!publication.logo?.is_default && publication.logo?.url ? (
            <div className="w-20 h-20">
              <img src={publication.logo.url} alt="" className="w-full h-full object-cover" />
            </div>
          ) : null}
        </FileUploader>
      </FormRow>
      <FormRow
        label="Default Thumbnail Preview"
        helperText="This will be the thumbnail that appears in various places across your publication posts and previews in web browsers. "
        tip="Recommended: 1200x630 px"
      >
        <FileUploader
          onSave={handleUpdateThumbnail(publication.id)}
          isBusy={savingAttribute === 'thumbnail'}
          emptyStateType="rectangle"
        >
          {!publication.thumbnail?.is_default && publication.thumbnail?.url ? (
            <div className="w-36 h-16">
              <img src={publication.thumbnail.url} alt="" className="object-cover w-full h-full" />
            </div>
          ) : null}
        </FileUploader>
      </FormRow>
      <SelectTags
        publicationId={publication.id}
        publicationTags={publication.tags || []}
        onChange={(tagIds: string[]) => handleBlur('tag_ids', tagIds)}
        isDisabled={savingAttribute === 'tag_ids' || (publication.tags && publication.tags.length >= 3)}
      />
      {canUpdateLanguage ? (
        <SelectLanguage
          currentPublicationId={publication.id}
          onChange={(value: string) => handleBlur('language', value)}
          publicationLanguage={publication.language}
          isDisabled={savingAttribute === 'language'}
        />
      ) : null}
      <SelectTimezone
        currentPublicationId={publication.id}
        onChange={(value: string) => handleBlur('time_zone', value)}
        publicationTimezone={publication.time_zone}
        isDisabled={savingAttribute === 'time_zone'}
      />
      <Input
        labelText="Subscribe Button Text"
        name="cta-text"
        defaultValue={publication.cta_text}
        onBlur={(e) => handleBlur('cta_text', e.target.value)}
        disabled={savingAttribute === 'cta_text'}
      />
    </BodyContainer>
  );
};

export default GeneralInfoForm;
