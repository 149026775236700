import EarningsPerPeriodChart from './EarningsPerPeriodChart';
import ReferralsPerPeriodChart from './ReferralsPerPeriodChart';

const Insights = () => {
  return (
    <>
      <EarningsPerPeriodChart />
      <ReferralsPerPeriodChart />
    </>
  );
};

export default Insights;
