import { FC } from 'react';
import { Grid } from '@tremor/react';

import { useSettings } from '@/context/settings-context';
import { MetricCard } from '@/ui/MetricCard';
import { VERIFIED_CLICKS_EXPAND_METRIC_LABEL, VERIFIED_CLICKS_TOOLTIP } from '@/utils/verifiedClicksConstants';

import { useSegmentKpis } from '../../../../hooks/useSegmentDetail';

interface Props {
  segmentId: string;
}

const KPIs: FC<Props> = (props: Props) => {
  const { segmentId } = props;

  const { settings } = useSettings();
  const isVerifiedClicksV2 = settings?.verified_clicks_v2 === true;

  const kpisQuery = useSegmentKpis({ segmentId });
  const { data: kpiData, isLoading } = kpisQuery || {};

  // Send Kpis
  const showVerifiedClicks = isVerifiedClicksV2 && kpiData?.total_clicked_raw;
  const openRate = `${kpiData?.open_rate?.toFixed(1) || 0.0}%`;
  const clickThroughRateMetric = showVerifiedClicks ? kpiData?.click_through_rate_raw : kpiData?.click_through_rate;
  const clickThroughRate = `${clickThroughRateMetric?.toFixed(1) || 0.0}%`;
  const clickThroughRateVerified = `${kpiData?.click_through_rate?.toFixed(1) || 0.0}%`;

  // Subscriber Kpis
  const totalUnsubscribed = kpiData?.total_unsubscribed?.toLocaleString('en-US');
  const pctUnsubscribed = `${kpiData?.pct_unsubscribed || 0.0}%`;

  const totalPremium = kpiData?.total_premium_subscribers?.toLocaleString('en-US');
  const pctPremium = `${kpiData?.pct_premium || 0.0}%`;

  // Referral kpis
  const totalReferrals = kpiData?.total_referrals?.toLocaleString('en-US');
  const avgReferralsPerReferrer = kpiData?.average_referrals_per_referrer?.toFixed(1) || 0.0;
  const pctReferring = `${kpiData?.pct_referring || 0.0}%`;

  return (
    <Grid numCols={1} className="gap-4">
      <Grid numColsSm={4} numCols={1} className="gap-4">
        <MetricCard label="Open Rate" value={openRate} isLoading={isLoading} variant="primary" />
        <MetricCard
          label="Click Through Rate"
          value={clickThroughRate}
          isLoading={isLoading}
          variant="primary"
          initialVisibleComplements={0}
          expandComplementsLabel={VERIFIED_CLICKS_EXPAND_METRIC_LABEL}
          expandComplementsTooltip={VERIFIED_CLICKS_TOOLTIP}
          complements={showVerifiedClicks ? [{ label: 'Verified Click Rate', value: clickThroughRateVerified }] : []}
        />
        <MetricCard label="Unsubscribes" value={totalUnsubscribed || 0} isLoading={isLoading} variant="primary" />
        <MetricCard label="Unsubscribe Rate" value={pctUnsubscribed || 0} isLoading={isLoading} variant="primary" />
      </Grid>
      <Grid numColsSm={5} numCols={1} className="gap-4">
        <MetricCard label="Premium Rate" value={pctPremium} isLoading={isLoading} variant="primary" />
        <MetricCard label="Premium" value={totalPremium || 0} isLoading={isLoading} variant="primary" />
        <MetricCard label="Referral Rate" value={pctReferring || 0} isLoading={isLoading} variant="primary" />
        <MetricCard label="Referrals" value={totalReferrals} isLoading={isLoading} variant="primary" />
        <MetricCard
          label="Referrals per Referrer"
          value={avgReferralsPerReferrer}
          isLoading={isLoading}
          variant="primary"
        />
      </Grid>
    </Grid>
  );
};

export default KPIs;
