import { PropsWithChildren } from "react";
import { ArrowLeft } from "@phosphor-icons/react";

import { cn } from "../../_utils/cn";
import { Button } from "../UI/Button";
import { Text } from "../UI/Text";

export const SectionTitle = ({ title, onBack, className }: { title: string, onBack?: () => void, className?: string }) => (
  <div className={cn(`px-2 py-1.5 gap-2 flex items-center`, className)}>
    {onBack && <Button
      variant="ghost"
      size="sm"
      className="text-wb-primary"
      onClick={onBack}
      Icon={ArrowLeft}
    />}
    <Text size='xs' weight='medium' variant="secondary">{title}</Text>
  </div>
)
export const Section = ({ children }: PropsWithChildren<{}>) => (
  <div className="flex flex-col gap-1 p-2">
    {children}
  </div>
)
