import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AutomationJourney, AutomationJourneyStatus } from '@/interfaces/automations/automation_journey';
import api from '@/services/swarm';

import { useSubscriberContext } from '../../../_context/subscriberContext';

interface ApiResponse {
  automation_journeys: AutomationJourney[];
}

const useAutomationJourneys = ({ limit, statuses }: { limit?: number; statuses?: AutomationJourneyStatus[] }) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { subscription } = useSubscriberContext();

  return useQuery<ApiResponse>(['automation_journeys', subscription.id, limit, statuses], () =>
    api
      .get(`/subscriptions/${subscription.id}/automation_journeys`, {
        params: {
          publication_id: currentPublicationId,
          status: statuses,
          limit,
        },
      })
      .then((res) => res.data)
  );
};

export default useAutomationJourneys;
