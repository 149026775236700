import { FC, useEffect, useRef } from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  name?: string;
  type?: string;
  value?: string;
  labelText: string;
  errorText?: string;
  helperText?: string | React.ReactNode;
  onFileSelect: (file: File) => void;
  onFileRemoved?: (file: null) => void;
  required?: boolean;
  autoComplete?: string;
  readOnly?: boolean;
  disabled?: boolean;
  file?: File | null;
  accept?: string;
}

const FileInput: FC<Props> = (props: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const {
    className,
    errorText,
    type = 'file',
    name,
    labelText,
    helperText,
    value,
    onFileSelect,
    onFileRemoved = () => {},
    required,
    readOnly,
    disabled,
    autoComplete,
    file,
    accept = '*',
  } = props;

  const hasError = !!errorText;

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files && e.target.files.length > 0) {
      onFileSelect(e.target.files[0]);
    } else if (typeof onFileRemoved === 'function') {
      // If a onFileRemoved function was passed in, call it with null (to signify no file)
      onFileRemoved(null);
    }
  };

  // Reset the file name when the file is removed
  useEffect(() => {
    if (!file && ref.current) {
      ref.current.value = '';
    }
  }, [file, ref]);

  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
        {required ? ' *' : ''}
      </label>
      <div className="mt-1">
        <input
          ref={ref}
          name={name}
          type={type}
          value={value}
          onChange={handleFileInput}
          accept={accept}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete={autoComplete}
          className={cx(
            'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed',
            hasError ? 'border-red-300' : 'border-gray-300',
            disabled ? 'bg-gray-200 opacity-60' : 'opacity-100'
          )}
        />
        {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
        {errorText && <p className="mt-2 text-xs text-red-500">{errorText}</p>}
      </div>
    </div>
  );
};

export default FileInput;
