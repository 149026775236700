import { useMemo } from 'react';

import Banner from '@/components/Banner';
import { ContentImport, ContentImportPlatform } from '@/interfaces/content_import';
import { Dropdown } from '@/ui/Dropdown';

import BodyContainer from '../../../_components/BodyContainer';
import { StepErrors } from '../new/types';

const options = [
  { value: 'substack', label: 'Substack' },
  { value: 'wordpress', label: 'WordPress' },
  { value: 'mailchimp', label: 'Mailchimp' },
  { value: 'ghost', label: 'Ghost' },
  { value: 'other', label: 'Other' },
];

interface Props {
  contentImport: Partial<ContentImport>;
  handleChangePlatform: (newPlatform: ContentImportPlatform) => void;
  errors: StepErrors;
  showMailchimp: boolean;
}

const PlatformSelection: React.FunctionComponent<Props> = ({
  contentImport,
  errors,
  handleChangePlatform,
  showMailchimp,
}: Props) => {
  const filteredOptions = useMemo(
    () => options.filter((option) => (option.value === 'mailchimp' ? showMailchimp : true)),
    [showMailchimp]
  );

  return (
    <BodyContainer>
      <Banner
        isScreenWide={false}
        variant="info"
        title="By importing content, you agree to the following:"
        bodyText={
          <ul className="space-y-1 list-disc">
            <li>You verify that you are the rightful owner of this content.</li>
            <li>
              You acknowledge that by falsely claiming other&apos;s content as your own, you will be in direct violation
              of beehiiv&apos;s terms of service which can lead to immediate termination of service.
            </li>
          </ul>
        }
      />

      <Dropdown
        name="platform"
        labelText="Import from"
        className="space-y-2 max-w-xs"
        value={contentImport.platform}
        options={filteredOptions}
        onSelect={(_name, value: ContentImportPlatform) => {
          handleChangePlatform(value);
        }}
        errorText={errors?.platform}
        helperText="Choose the location where your content currently lives"
      />
    </BodyContainer>
  );
};

export default PlatformSelection;
