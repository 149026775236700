import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ArrowPathIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import moment from 'moment-mini';
import { StringParam, useQueryParam } from 'use-query-params';

import { Button } from '@/ui/Button';

import Card from '../../../components/Card';
import Tooltip from '../../../components/Tooltip';
import { Badge } from '../../../ui/Badge';
import NavContainer from '../NavContainer';

import QuestionResponses from './QuestionResponses';

const TABLE_HEADERS = ['Email', 'Subscription Status', 'Questions Answered', 'Responded At', 'Details'];
const BADGE_COLORS: any = { active: 'success', inactive: 'alert', pending: undefined };

const Responses = ({ formId, paginatedFormResponsesQuery, questionCount, form, isNewLayout }: any) => {
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = paginatedFormResponsesQuery;

  const formResponses = data?.pages.flatMap((page: any) => page.form_responses) || [];
  const isNoResults = !isLoading && formResponses.length === 0;

  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  // Reset search when no results
  const handleResetSearch = () => {
    setSearch(undefined);
    setShouldResetSearch(true);
  };

  const renderNotResults = () => {
    const noSearchResults = isNoResults && search;

    const text = noSearchResults
      ? `No results found for search, '${search}'`
      : `Once you receive your first response, it will show up here.`;

    return (
      <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500 text-center">{text}</p>
          {noSearchResults ? (
            <Button variant="primary-inverse" onClick={handleResetSearch}>
              <div className="flex">
                <ArrowPathIcon className="h-5 w-5 mr-2" />
                Reset Search
              </div>
            </Button>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <Card noPadding>
      {data && formResponses?.length === 0 ? (
        renderNotResults()
      ) : (
        <div className="bg-white rounded overflow-x-auto">
          {formId && <NavContainer formId={formId} isNewLayout={isNewLayout} />}
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {TABLE_HEADERS.map((header) => {
                  const isSubscriptionStatus = header === 'Subscription Status';

                  return (
                    <th
                      key={header}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5"
                    >
                      <span className="flex items-center">
                        {header}
                        {isSubscriptionStatus && (
                          <Tooltip
                            id="tooltip"
                            text="Data for subscriptions that are not yet active will be available via Custom Fields once the subscriber has finished confirming their subscription"
                            iconClass="ml-2"
                          />
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 transitiona-all">
              {formResponses?.map((response: any) => {
                const percentComplete = Math.round((response.question_response_count / questionCount) * 100);
                const isNotActive = response.status !== 'active';

                return (
                  <Disclosure key={response.id}>
                    {({ open }) => (
                      <>
                        <tr key={response.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="text-sm font-medium text-gray-900">
                                {isNotActive ? (
                                  <div className="text-sm text-gray-500"> {response.email}</div>
                                ) : (
                                  <Link
                                    className="text-primary-600 hover:text-primary-900"
                                    to={`/subscribers/${response.subscriber_id}`}
                                  >
                                    {response.email}
                                  </Link>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              <Badge className="capitalize" type={BADGE_COLORS[response.status]}>
                                {response.status}
                              </Badge>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900 flex space-x-2 items-center">
                              <span>{response.question_response_count}</span>
                              <span className="ml-2 text-xs text-gray-400">({percentComplete}%)</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">{moment(response.created).format('LLL')}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <Disclosure.Button className="flex items-center text-xs text-primary-500 space-x-1">
                              See more
                              <ChevronRightIcon
                                className={cx(
                                  'ml-1 w-3 h-3 transform transition-all',
                                  open ? '-rotate-90 transform' : 'rotate-90'
                                )}
                              />
                            </Disclosure.Button>
                          </td>
                        </tr>
                        <QuestionResponses
                          formId={formId}
                          formResponseId={response.id}
                          shouldFetch={open}
                          formQuestions={form?.form_questions}
                        />
                      </>
                    )}
                  </Disclosure>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {hasNextPage && (
        <div className="text-center mt-6 mb-8">
          <div>
            <Button
              variant="primary-inverse"
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load more'}
            </Button>
          </div>
        </div>
      )}
    </Card>
  );
};

export default Responses;
