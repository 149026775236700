import { PlusIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import { AdNetworkIndustryGroup } from '@/interfaces/ad_network/internal/industry_group';
import { Button } from '@/ui/Button';

import { useIndustries, useIndustryGroups } from '../../../hooks/useAdNetwork/internal';
import { AdNetworkIndustry } from '../../../interfaces/ad_network/internal/industry';
import { List } from '../_components/List';
import { LoadingListPage } from '../_components/LoadingListPage';
import { PageHeading } from '../_components/PageHeading';

interface Props {
  industries: AdNetworkIndustry[];
  industryGroups: AdNetworkIndustryGroup[];
}

const Index = ({ industries, industryGroups }: Props) => {
  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="">Industries</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button to="new" size="xs" variant="primary-inverse" Icon={PlusIcon}>
            Add industry
          </Button>
        </PageHeading.Side>
      </PageHeading>

      <List>
        {industries.map((industry) => (
          <List.Item key={industry.id} to={industry.id}>
            <List.Item.Text variant="primary">
              {industry.industry_group_id && (
                <>{industryGroups.find((group) => group.id === industry.industry_group_id)?.name} &rarr; </>
              )}
              {industry.name}
            </List.Item.Text>
            <List.Item.Text variant="secondary">
              <time dateTime={industry.created_at}>Created {moment(industry.created_at).fromNow()}</time>
              <span>&middot;</span>
              <time dateTime={industry.updated_at}>Updated {moment(industry.updated_at).fromNow()}</time>
            </List.Item.Text>
          </List.Item>
        ))}
      </List>
    </>
  );
};

export default function Loader() {
  const { data, isSuccess, isLoading, isError } = useIndustries();
  const {
    data: industryGroups,
    isSuccess: groupsSuccess,
    isLoading: groupsLoading,
    isError: groupsError,
  } = useIndustryGroups();

  if (!isSuccess || !groupsSuccess)
    return <LoadingListPage isLoading={isLoading || groupsLoading} isError={isError || groupsError} />;

  const industries = data.pages.flatMap((page) => page.industries) || [];

  return <Index industries={industries} industryGroups={industryGroups.industry_groups} />;
}
