import { XMarkIcon } from '@heroicons/react/20/solid';

interface SubscriberTagItemProps {
  name?: string;
  color?: string;
  deletableId?: string;
  onDelete?: (tag: any) => void;
}

const SubscriberTagItem: React.FunctionComponent<SubscriberTagItemProps> = ({ name, color, deletableId, onDelete }) => {
  const defaultClass = `bg-${color}-100 text-${color}-800 border-${color}-200 text-xs rounded-md border shadow-xs inline-flex gap-x-1 mr-2 mb-2`;

  return (
    <span className={deletableId ? `${defaultClass} pl-2 pt-1` : `${defaultClass} px-2 py-1`}>
      {name}
      {deletableId && onDelete && (
        <XMarkIcon
          onClick={() => {
            onDelete?.(deletableId);
          }}
          className="h-5 w-5 inline pb-1 cursor-pointer"
        />
      )}
    </span>
  );
};

export default SubscriberTagItem;
