import { MapPinIcon } from '@heroicons/react/20/solid';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import useBoostOfferEligibleCountries from '@/hooks/boosts/useBoostOfferEligibleCountries';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';

interface Props {
  boostOffer: BoostOffer;
}

const RegionalRestrictionsBlock = ({ boostOffer }: Props) => {
  const { hasRegionalRestrictions, regionalRestrictionsTooltip } = useBoostOfferEligibleCountries(
    boostOffer.eligible_countries
  );

  if (!hasRegionalRestrictions) {
    return null;
  }

  return (
    <Tooltip id={`regional-restrictions-tooltip-${boostOffer.id}`} text={regionalRestrictionsTooltip} showIcon={false}>
      <div className="flex gap-x-1 items-center w-fit">
        <MapPinIcon className="w-4 h-4 text-surface-300" />
        <Typography token="font-light/text/xs" colorWeight="500">
          Regional restrictions applied
        </Typography>
      </div>
    </Tooltip>
  );
};

export default RegionalRestrictionsBlock;
