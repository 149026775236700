import { CaretRight, Heart } from "@phosphor-icons/react";
import { NavbarDropdownItemStyleType } from "@shared/dream-components";

import { Popover, PopoverContent, PopoverTrigger } from "@/routes/website/_components/UI/Popover";
import { Text } from "@/routes/website/_components/UI/Text";
import { cn } from "@/utils/cn";

import { useNavbarContext } from "../../../NavbarContext";
import { formatCamelCase } from "../../../utils";
import { OptionsWithPreview } from "../../ui/OptionsWithPreview";

export function StyleSettings() {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_dropdown_item") return null;


  const contentValue = selectedContent?.attrs && 'style' in selectedContent.attrs && selectedContent.attrs.style || '';


  const handleStyleChange = (value: NavbarDropdownItemStyleType) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { style: value });
  }

  return <Popover>
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Style
      </Text>

      <PopoverTrigger asChild>
        <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer w-[120px]">
          <div className="w-full justify-between flex items-center gap-2 p-2 ">
            <div className="flex items-center gap-1">
              <Text size="2xs" weight="medium">
                {formatCamelCase(contentValue)}
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>
      </PopoverTrigger>
    </div>
    <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
      <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-6">
        <Text size="sm" weight="semibold">
          Style
        </Text>
        <div className="flex flex-col gap-2">
          {
            (['link', 'description', 'icon', 'featured'] as NavbarDropdownItemStyleType[]).map(style => (
              <OptionsWithPreview key={style} label={formatCamelCase(style)} selected={style === contentValue} onClick={() => handleStyleChange(style)}>
                {/* preview */}
                {style === 'link' &&
                  <div className="flex items-center justify-between bg-wb-primary rounded-md w-full gap-1 shadow-wb-md h-[40px] overflow-hidden">
                    <div className={cn("flex items-start justify-between bg-wb-primary p-2 w-full gap-1 h-fit")}>
                      <Text size="2xs" weight="medium" className="text-[10px]">
                        Product
                      </Text>
                    </div>
                  </div>
                }
                {style === 'description' &&
                  <div className="flex items-center justify-between bg-wb-primary rounded-md w-full gap-1 shadow-wb-md h-[40px] overflow-hidden">
                    <div className={cn("flex flex-col items-start justify-center bg-wb-primary p-2 w-full gap-[2px] h-fit")}>
                      <Text size="2xs" weight="medium" className="text-[10px]">
                        Product
                      </Text>
                      <Text size="2xs" weight="medium" variant="secondary" className="text-[8px]">
                        Description
                      </Text>
                    </div>
                  </div>
                }
                {style === 'icon' &&
                  <div className="flex items-center justify-between bg-wb-primary rounded-md w-full gap-1 shadow-wb-md h-[40px] overflow-hidden">
                    <div className={cn("flex items-center justify-start bg-wb-primary p-2 w-full gap-1 h-fit")}>
                      <div className="flex items-center justify-center bg-wb-secondary rounded-[4px] w-6 h-6">
                        <Heart size={10} weight="fill" className="text-wb-secondary" />
                      </div>
                      <div className={cn("flex flex-col items-start justify-center flex-1 gap-0 h-fit")}>
                        <Text size="2xs" weight="medium" className="text-[10px]">
                          Product
                        </Text>
                        <Text size="2xs" weight="medium" variant="secondary" className="text-[8px]">
                          Description
                        </Text>
                      </div>
                    </div>
                  </div>
                }
                {style === 'featured' &&
                  <div className="flex items-center justify-between bg-wb-primary rounded-md w-full gap-1 shadow-wb-md h-full mt-1 pt-3 overflow-hidden">
                    <div className={cn("flex flex-col items-start justify-center bg-wb-primary p-2 w-full gap-1 h-fit")}>
                      <div className="flex items-center justify-center bg-wb-secondary rounded-[4px] w-6 h-6">
                        <Heart size={10} weight="fill" className="text-wb-secondary" />
                      </div>
                      <div className={cn("flex flex-col items-start justify-center flex-1 gap-0 h-fit")}>
                        <Text size="2xs" weight="medium" className="text-[10px]">
                          Product
                        </Text>
                        <Text size="2xs" weight="medium" variant="secondary" className="text-[8px]">
                          Description
                        </Text>
                      </div>
                    </div>
                  </div>
                }
              </OptionsWithPreview>
            ))
          }
        </div>
      </div>
    </PopoverContent>
  </Popover>
}
