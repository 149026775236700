/* eslint-disable react-hooks/rules-of-hooks */

import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

const useUnsubscribeAction = ({ subscriberId, status }: { subscriberId: string; status?: string }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isUnsubscribing, setIsUnsubscribing] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const unsubscribeMutation = useMutation(
    () => {
      return api
        .patch(`/subscriptions/${subscriberId}/unsubscribe`, {
          publication_id: currentPublicationId,
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['subscriber', subscriberId]);
        toast.success('Subscriber successfully unsubscribed!');
      },
      onError: () => {
        toast.error('Something went wrong');
      },
      onSettled: () => {
        setConfirmOpen(false);
        setIsUnsubscribing(false);
      },
    }
  );

  const onUnsubscribe = () => {
    setIsUnsubscribing(true);
    analytics.track('Unsubscribe Subscriber');
    unsubscribeMutation.mutate();
  };

  if (status === 'inactive') {
    return [];
  }

  return [
    {
      label: 'Unsubscribe',
      helperText: 'Unsubscribe this subscriber',
      onClick: () => setConfirmOpen(true),
      children: (
        <ActionModal
          isOpen={confirmOpen}
          onProceed={onUnsubscribe}
          onClose={() => setConfirmOpen(false)}
          resourceId={subscriberId}
          isWorking={isUnsubscribing}
          headerText="Unsubscribe Subscriber"
          actionText="Unsubscribe"
        >
          Are you sure you want to unsubscribe this subscriber from receiving your newsletters?
        </ActionModal>
      ),
    },
  ];
};

export default useUnsubscribeAction;
