import { Bar } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import Card from '@/components/Card';
import Text from '@/components/Text';
import Colors from '@/interfaces/colors';
import { PollMetrics } from '@/interfaces/poll';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface HorizontalMetricsChartProps {
  title: string;
  metrics: PollMetrics;
}

const HorizontalMetricsChart = ({ metrics, title }: HorizontalMetricsChartProps) => {
  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          display: true,
          padding: 8,
          precision: 0,
        },
      },
    },
  } as any;

  const labels = Object.keys(metrics);

  const data = {
    labels,
    datasets: [
      {
        label: 'Votes',
        data: Object.values(metrics),
        borderColor: Colors.primary,
        backgroundColor: Colors.primary,
        scaleShowLabels: false,
      },
    ],
  };

  const totalVotes = Object.values(metrics).reduce((a: any, b: any) => a + b) as any;
  const winner = Object.keys(metrics).reduce((a, b) => (metrics[a] > metrics[b] ? a : b));
  const winnerPercentage = (metrics[winner] / totalVotes) * 100;
  const winnerMetric = metrics[winner];
  const hasWinner = Object.values(metrics).some((result: any) => result !== 0);
  const tie = Object.values(metrics).filter((value) => value === winnerMetric).length > 1;

  return (
    <Card className="border">
      <div className="border-b border-gray-200 mb-8 flex flex-col">
        <Text type="subtitle">{title}</Text>
        {hasWinner ? (
          <Text type="body" size="sm" className="text-gray-900 mb-2">
            {tie ? (
              <span>
                <strong>Tie</strong> with <strong>{winnerMetric}</strong> out of
                <strong> {totalVotes}</strong> {totalVotes === 1 ? 'vote' : 'votes'}
              </span>
            ) : (
              <span>
                <strong>{winner}</strong> leading with <strong>{winnerMetric}</strong> out of
                <strong> {totalVotes}</strong> {totalVotes === 1 ? 'vote' : 'votes'}
              </span>
            )}
            <span className="text-green-500 font-bold"> ({winnerPercentage.toFixed(2)}%)</span>
          </Text>
        ) : (
          <Text type="body" size="sm" className="text-gray-900 mb-2">
            No metrics, check back later.
          </Text>
        )}
      </div>

      <div className="h-72">
        <Bar data={data} options={options} />
      </div>
    </Card>
  );
};

export default HorizontalMetricsChart;
