import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

import * as CardUI from '@/components/Card/CardUI';
import { TitleSm, Typography, TypographyStack } from '@/components/Typography';
import UrlCopy from '@/components/UrlCopy';
import { useWalkthroughContext } from '@/context/walkthrough-context';
import { useCurrentPublication } from '@/hooks';
import { Button } from '@/ui/Button';

interface Props {
  isOnboardingCompleted: boolean;
}

const Resources = ({ isOnboardingCompleted }: Props) => {
  const { showWalkthrough, handleStartWalkthrough } = useWalkthroughContext();
  const { data: currentPublication } = useCurrentPublication();

  return (
    <div className="space-y-2">
      <Typography weight="semibold" size="base">
        Resources
      </Typography>

      <div className="space-y-4">
        {/* Quick Links */}
        <CardUI.Card>
          <div className="flex flex-col space-y-4">
            <TypographyStack gap="1">
              <TitleSm>Home page</TitleSm>
              <UrlCopy url={`${currentPublication?.url}`} />
            </TypographyStack>

            <TypographyStack gap="1">
              <TitleSm>Signup page</TitleSm>
              <UrlCopy url={`${currentPublication?.url}subscribe`} />
            </TypographyStack>
          </div>
        </CardUI.Card>

        {/* Tutorials */}
        <CardUI.Card>
          <div className="flex flex-col space-y-4">
            <TypographyStack gap="1">
              <TitleSm>Tutorials</TitleSm>
              <Typography size="xs" weight="medium" colorWeight="500">
                Learn how to get started on beehiiv and utilize all our features, directly from the beehiiv team.
              </Typography>
            </TypographyStack>

            <div className="flex flex-col space-y-2 w-fit">
              <div>
                <Button
                  type="button"
                  size="xs"
                  variant="primary-inverse"
                  Icon={ArrowTopRightOnSquareIcon}
                  iconRight
                  onClick={() => window.open('https://www.youtube.com/channel/UCI80tsW3wYNGEJLxwJo42vw', '_blank')}
                >
                  Tutorials
                </Button>
              </div>
              {!showWalkthrough && !isOnboardingCompleted && (
                <Button type="button" size="xs" variant="primary-inverse" onClick={handleStartWalkthrough}>
                  New User Walkthrough
                </Button>
              )}
            </div>
          </div>
        </CardUI.Card>

        {/* Need Help? */}
        <CardUI.Card>
          <div className="flex flex-col space-y-2">
            <Typography weight="semibold" size="sm">
              Need help?
            </Typography>

            <div className="flex flex-col space-y-2">
              <div>
                <Button
                  type="button"
                  size="xs"
                  variant="primary-inverse"
                  Icon={ArrowTopRightOnSquareIcon}
                  iconRight
                  onClick={() => window.open('https://support.beehiiv.com/hc/en-us', '_blank')}
                >
                  Knowledge base
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  size="xs"
                  variant="primary-inverse"
                  Icon={ArrowTopRightOnSquareIcon}
                  iconRight
                  onClick={() => window.open('https://developers.beehiiv.com/docs/v2', '_blank')}
                >
                  API Documentation
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  size="xs"
                  variant="primary-inverse"
                  Icon={ArrowTopRightOnSquareIcon}
                  iconRight
                  onClick={() => window.open('https://blog.beehiiv.com', '_blank')}
                >
                  Blog
                </Button>
              </div>
            </div>
          </div>
        </CardUI.Card>
      </div>
    </div>
  );
};

export default Resources;
