import { useState } from 'react';

import VideoModal from '../../../components/Modal/VideoModal';
import { useTutorial } from '../../../hooks/useTutorials';
import { TutorialType } from '../../../interfaces/tutorial';

interface Props {
  type: TutorialType;
  classNames?: string;
}

const VideoTutorial = ({ type, classNames = '' }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const tutorial = useTutorial(type);

  return (
    <>
      <VideoModal title={type} src={tutorial?.url || ''} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <button
        type="button"
        className={classNames}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <span className="text-primary-500">{tutorial?.cta}</span>
      </button>
    </>
  );
};

export default VideoTutorial;
