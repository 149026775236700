import { FONT_SIZE_OPTIONS } from '@/interfaces/font';
import { Option } from '@/interfaces/general';
import FontPicker from '@/ui/Settings/FontPicker';

import ColorInput from '../../ColorInput';
import SelectInput from '../../SelectInput';
import SwitchInput from '../../SwitchInput';

import { FormControlType } from './types';

interface Props {
  label: string;
  items: {
    [key: string]: {
      type: FormControlType;
      value: any;
      label?: string;
      resetCheckValue?: any;
      resetLabel?: string;
    };
  };
  nodeId?: string;
  onSave: (prop: string, newValue: any) => void;
  fontOptions: Option[];
}
const EditableProps = ({ items, onSave, nodeId, fontOptions, label }: Props) => {
  return (
    <div className="flex flex-col space-y-3">
      {Object.keys(items).map((prop: string, index: number) => {
        const resetCheckValue = items[prop]?.resetCheckValue;
        const resetLabel = items[prop]?.resetLabel;

        if (items[prop].type === 'colorInput') {
          return (
            <ColorInput
              key={`color-input-${nodeId}-${prop}`}
              label={items[prop].label || 'Color'}
              resettable={
                resetCheckValue
                  ? {
                      label: resetLabel || `Global ${label} Color`,
                      resetValue: null,
                      check: resetCheckValue !== null,
                    }
                  : undefined
              }
              color={items[prop].value}
              onSave={(color: string) => onSave(prop, color)}
            />
          );
        }
        if (items[prop].type === 'switch') {
          return (
            <SwitchInput
              name={`switch-input-${index}-${prop}`}
              key={`switch-input-${nodeId}-${prop}`}
              label={items[prop].label || 'Enabled'}
              enabled={items[prop].value}
              onSave={(value: boolean) => onSave(prop, value)}
            />
          );
        }
        if (items[prop].type === 'fontSizeSelect') {
          return (
            <SelectInput
              key={`font-size-select-${nodeId}-${prop}`}
              label={items[prop].label || 'Font Size'}
              value={items[prop].value}
              options={FONT_SIZE_OPTIONS}
              onSave={(value: string) => onSave(prop, value)}
            />
          );
        }
        if (items[prop].type === 'fontFamilySelect') {
          return (
            <FontPicker
              key={`font-family-select-${nodeId}-${prop}`}
              label={items[prop].label || 'Font Family'}
              searchPlaceholder="Search Font"
              value={items[prop].value}
              noOptionsText="No Matching Fonts"
              options={fontOptions}
              onSave={(value: string) => onSave(prop, value)}
              resettable={
                resetCheckValue
                  ? {
                      label: resetLabel || `Global ${label} Font Family`,
                      resetValue: null,
                      check: resetCheckValue !== null,
                    }
                  : undefined
              }
            />
          );
        }

        return null;
      })}
    </div>
  );
};

export default EditableProps;
