import { useEffect, useState } from 'react';

const FakeProgress = () => {
  const [timer, setTimer] = useState(1);

  const progress = 100 - 100 / timer;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((t) => t + 1);
    }, 100);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative h-1 w-full bg-gray-100 rounded overflow-hidden">
      <div
        className="absolute inset-0 bg-primary-500 transition-all duration-100"
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
};

export default FakeProgress;
