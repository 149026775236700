import moment from 'moment-mini';

import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { usePostTargetsDisplay } from '@/hooks/usePostTargets';
import { Post, PostNodeContent, PostScheduleOptions } from '@/interfaces/post';
import { PostTargetPlatform } from '@/interfaces/post_target';
import pluralize from '@/utils/pluralize';

import { SplitTestSummary } from '../../SplitTest';

interface PrePublishStatsProps {
  post: Post;
  selectedScheduleOption: PostScheduleOptions;
  formattedSelectedTime: string | null;
  postNodeContent?: PostNodeContent;
}

const PrePublishStats: React.FC<PrePublishStatsProps> = ({
  post,
  selectedScheduleOption,
  formattedSelectedTime,
  postNodeContent,
}: PrePublishStatsProps) => {
  const { data: postDeliveryData } = usePostDeliveryData(post.id);
  const emailAudiences = usePostTargetsDisplay(post.id, PostTargetPlatform.EMAIL);
  const webAudiences = usePostTargetsDisplay(post.id, PostTargetPlatform.WEB);

  const uniqBoosts = postNodeContent?.boost?.filter((value: any, index: any, self: any) => {
    return self.findIndex((v: any) => v.id === value.id) === index;
  });

  let formattedScheduledAt = 'Now';
  if (selectedScheduleOption === PostScheduleOptions.SCHEDULE) {
    formattedScheduledAt = formattedSelectedTime || 'Now';
  }

  const emailMessaging = () => {
    if (!postDeliveryData) {
      return <p>-</p>;
    }

    if (postDeliveryData.for_email) {
      return (
        <>
          <p>{pluralize('subscriber', postDeliveryData?.target_subscriptions)}</p>
          <p>
            <span className="ml-1 mr-2">&#8627;</span>
            {emailAudiences}
          </p>
        </>
      );
    }

    return <p>Not published via email</p>;
  };

  const webMessaging = () => {
    if (!postDeliveryData) {
      return <p>-</p>;
    }

    if (postDeliveryData.for_web) {
      return <p>{webAudiences}</p>;
    }

    return <p>Not published to the web</p>;
  };

  return (
    <>
      <ul className="bg-gray-100 p-4 rounded mt-4 space-y-2">
        <li className="text-xs font-semibold">
          Publish Time:
          <span className="ml-2 text-gray-700 font-normal capitalize">{formattedScheduledAt}</span>
        </li>
        {uniqBoosts && uniqBoosts?.length > 0 && (
          <li className="text-xs font-semibold">
            Boosts:
            {uniqBoosts.map((boost) => (
              <span key={boost.id} className="ml-2 text-gray-700 font-normal capitalize">
                Earnings window: {moment(boost.send_by).format('LLL')}
              </span>
            ))}
          </li>
        )}
        <li className="text-xs font-semibold max-w-xl">
          <div className="flex">
            <div>Web:</div>
            <div className="ml-2 text-gray-700 font-normal">{webMessaging()}</div>
          </div>
        </li>
        <li className="text-xs font-semibold max-w-xl">
          <div className="flex">
            <div>Email:</div>
            <div className="ml-2 text-gray-700 font-normal">{emailMessaging()}</div>
          </div>
        </li>
      </ul>
      <SplitTestSummary postId={post.id} />
    </>
  );
};

export default PrePublishStats;
