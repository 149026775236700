import { useCallback } from 'react';

import { Button } from '../../ui/Button';

const ThemeColorButton = (props: {
  themeColor: {
    value: string;
    label: string;
    key: string;
  };
  active?: boolean;
  onClick: (color: string) => void;
}) => {
  const { active, themeColor, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(themeColor.value);
  }, [themeColor.value, onClick]);

  return (
    <Button
      $variant="quaternary"
      $isColorTileButton
      $active={active}
      onClick={handleClick}
      style={{
        '--background': themeColor.value,
      }}
    />
  );
};

export default ThemeColorButton;
