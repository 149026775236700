import { useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/24/outline';

import { Textarea } from '@/ui/Textarea';

import NotEditing from './NotEditing';
import SaveButton from './SaveButton';

interface Props {
  name?: string;
  label: string;
  value: string;
  helperText?: string;
  placeholder?: string;
  onSave: (val: string) => Promise<any>;
  isSaving: boolean;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
}

const TextareaForm = ({
  name,
  label,
  value,
  onSave,
  isSaving,
  helperText,
  placeholder,
  toastSuccessMessage,
  toastErrorMessage,
}: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const showSaving = isSaving && isEditing;

  const handleSave = async () => {
    if (inputValue !== value) {
      await onSave(inputValue)
        .then(() => {
          if (toastSuccessMessage) {
            toast.success(toastSuccessMessage);
          }
          setIsEditing(false);
        })
        .catch(() => {
          if (toastErrorMessage) {
            toast.error(toastErrorMessage);
          }

          setIsEditing(false);
        });
    }

    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className="flex justify-between pb-4 space-x-6 border-b border-gray-100 last:border-b-0">
        <Textarea
          className="w-full max-w-xl"
          name={name || label.split(' ').join('-')}
          labelText={label}
          helperText={helperText}
          placeholderText={placeholder}
          value={inputValue}
          rows={4}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <SaveButton
          isSaving={showSaving}
          isEditing={isEditing}
          closeEditing={() => setIsEditing(false)}
          changesDetected={Boolean(inputValue !== value)}
          type="button"
          onClick={handleSave}
        />
      </div>
    );
  }

  return (
    <div className="flex justify-between items-center border-b border-gray-100 pb-4 last:border-b-0">
      <div className="w-full max-w-md">
        <NotEditing label={label} value={inputValue} helperText={helperText} />
      </div>

      <div className="pt-4">
        <button
          className="h-6 w-6 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
          type="button"
          onClick={() => setIsEditing(true)}
        >
          <PencilIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};

export default TextareaForm;
