import cx from 'classnames';

interface Props {
  publication: {
    logo_url: string;
    name: string;
  };
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  format?: 'circle' | 'square';
  className?: string;
  edge?: 'border' | 'shadow';
}

const PublicationLogo = ({ publication, size, format = 'square', className, edge = 'border' }: Props) => {
  const sizeClass = {
    xs: 'w-6 h-6',
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-12 h-12',
    xl: 'w-16 h-16',
    '2xl': 'w-24 h-24',
  }[size];

  const formatClass = {
    circle: 'rounded-full',
    square: 'rounded',
  }[format];

  return (
    <div
      className={cx(
        sizeClass,
        formatClass,
        className,
        'overflow-hidden flex-shrink-0',
        edge === 'border' ? 'border' : 'shadow-xl'
      )}
    >
      <figure className="aspect-w-1 aspect-h-1 relative h-full w-full">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={publication.logo_url}
          alt={publication.name}
        />
      </figure>
    </div>
  );
};

export default PublicationLogo;
