import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { NavGroupResponse } from '../../interfaces/nav_group';
import api from '../../services/swarm';

interface ApiResponse {
  nav_group: NavGroupResponse;
}

interface Props {
  navGroupId: string;
  onSuccess: (result: ApiResponse) => void;
}

const useUpdateNavGroup = ({ onSuccess, navGroupId }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, { title: string }>(
    ({ title }) =>
      api
        .patch(`/nav_groups/${navGroupId}`, {
          publication_id: currentPublicationId,
          nav_group: {
            title,
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useUpdateNavGroup;
