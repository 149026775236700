import { Input } from '@/components/Form';
import { ExternalRssFeed } from '@/interfaces/external_rss_feed';

import BodyContainer from '../../../_components/BodyContainer';

import { StepErrors } from './StepErrors';

interface Props {
  externalRssFeed: ExternalRssFeed;
  errors: StepErrors;
  onChange: (newExternalRssFeed: ExternalRssFeed) => void;
}

const DetailsForm = ({ externalRssFeed, errors, onChange }: Props) => {
  const { url, name } = externalRssFeed;

  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...externalRssFeed,
        [field]: value,
      });
    }
  };

  return (
    <BodyContainer>
      <Input
        type="text"
        name="name"
        label="Feed Name"
        id="create-external-rss-feed-name"
        labelText="RSS Feed Name"
        value={name}
        placeholder="Enter name"
        helperText='Choose a name for the feed (e.g., "beehiiv Times Sports").'
        onChange={(e) => handleChange('name', e.target.value)}
        required
        errorText={errors?.name}
      />
      <Input
        type="text"
        name="url"
        label="URL"
        id="create-external-rss-feed-url"
        labelText="RSS Feed URL"
        placeholder="Enter URL"
        value={url}
        helperText="Specify a URL to fetch the RSS feed from."
        onChange={(e) => handleChange('url', e.target.value)}
        required
        errorText={errors?.url}
      />
    </BodyContainer>
  );
};

export default DetailsForm;
