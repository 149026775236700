import { Popover } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';

export interface TestEmailModalProps {
  onClose: ({ showAgain }: { showAgain: boolean }) => void;
  show: boolean;
}

const TestEmailModal = ({ onClose, show }: TestEmailModalProps) => {
  if (show) {
    return (
      <Popover className="fixed left-6 sm:left-auto sm:right-[328px] bottom-[474px]">
        <div className="absolute z-[60] w-full">
          <Popover.Panel static>
            <div className="relative rounded-lg p-5 flex flex-col space-y-6 w-80 bg-white shadow-2xl max-w-[304px]">
              <div className="flex space-x-2">
                <TypographyStack>
                  <Typography weight="medium" size="base">
                    🔎 Want to test your post first?
                  </Typography>
                  <Typography weight="normal" colorWeight="700" size="sm" className="mt-2">
                    Before publishing, you can send a test email or share a draft link using the options under{' '}
                    <strong>Preview</strong>.
                  </Typography>
                  <img
                    src="/images/preview-email-thumbnail.jpg"
                    alt="Preview email"
                    className="w-[calc(100%+24px)] max-w-[calc(100%+24px)] h-auto pt-6 pb-2"
                  />
                </TypographyStack>

                <div>
                  <button
                    type="button"
                    onClick={() => {
                      onClose({ showAgain: false });
                    }}
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
              <div className="flex space-x-2 w-full justify-between items-center">
                <div className="flex space-x-2 w-full justify-end">
                  <button
                    className={cx(
                      'text-sm font-semibold px-4 py-2 rounded-md border border-surface-300 text-action-primary-900'
                    )}
                    onClick={() => {
                      onClose({ showAgain: true });
                    }}
                    type="button"
                    aria-label="Close"
                  >
                    Remind me later
                  </button>
                  <button
                    className="text-sm font-semibold px-4 py-2 rounded-md text-white bg-surface-900"
                    onClick={() => {
                      onClose({ showAgain: false });
                    }}
                    type="button"
                    aria-label="Close"
                  >
                    Got It!
                  </button>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </div>
      </Popover>
    );
  }

  return null;
};

export default TestEmailModal;
