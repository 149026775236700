import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserPlusIcon } from '@heroicons/react/20/solid';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

import SelectedOptionBadges from '@/components/Form/SelectedOptionBadges';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import { normalizeString } from '@/utils';

import { TypeaheadMultiSelect } from '../../../../../components/Form';
import useGuestAuthors from '../../../../../hooks/useGuestAuthors';
import { Post } from '../../../../../interfaces/post';

import AddSlideOver from './AddSlideOver';

interface Props {
  onChange: (values: Partial<Post>) => void;
  post: Post;
}

const GuestAuthors = ({ post, onChange }: Props) => {
  const { data, refetch } = useGuestAuthors();
  const guestAuthors = data?.guest_authors;

  const [isAdding, setIsAdding] = useState(false);
  const [query, setQuery] = useState('');
  const [currentSelection, setCurrentSelection] = useState<string[]>(post.guest_author_ids || []);

  const handleOpenAdding = () => setIsAdding(true);
  const handleCloseAdding = () => {
    setIsAdding(false);
    refetch();
  };

  const handleOnClear = () => {
    setQuery('');
  };

  const handleDeselect = (name: string, value: string) => {
    const newCurrentSelection = currentSelection.filter((item) => item !== value);
    setCurrentSelection(newCurrentSelection);

    onChange({ guest_author_ids: newCurrentSelection });
  };

  const handleDeselectAll = () => {};

  const handleSearch = (): Promise<Option[]> => {
    const options =
      guestAuthors
        ?.filter((author) => normalizeString(author.name).includes(query))
        .map((author) => ({ label: author.name, value: author.id })) || [];

    return new Promise((resolve) => {
      resolve(options);
    });
  };

  const handleSearchQueryChange = (newQuery: string) => setQuery(normalizeString(newQuery));

  const handleSelect = (name: string, value: string) => {
    const newCurrentSelection = [...currentSelection, value];

    setCurrentSelection(newCurrentSelection);
    onChange({ guest_author_ids: newCurrentSelection });
  };

  const handleDeselectViaBadge = (value: string) => {
    handleDeselect('', value);
  };

  const authorNameByUserId = useMemo(() => {
    const mappedAuthors: {
      [key: string]: string;
    } = {};
    guestAuthors?.forEach((author) => {
      mappedAuthors[author.id] = author.name;
    });

    return mappedAuthors;
  }, [guestAuthors]);

  const selectedOptions: Option[] = useMemo(
    () =>
      currentSelection.map((authorId: string) => ({
        label: authorNameByUserId[authorId],
        value: authorId,
      })),
    [currentSelection, authorNameByUserId]
  );

  if (!guestAuthors) return null;

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-y-2">
        <TypeaheadMultiSelect
          emptyLabel="No guest authors found"
          helperText={
            currentSelection.length === 0 ? 'Use guests to credit writers who are not part of your team.' : undefined
          }
          labelText="Guest Authors"
          name="new-post-guest-authors-multiselect"
          onClear={handleOnClear}
          onDeselect={handleDeselect}
          onDeselectAll={handleDeselectAll}
          onSearch={handleSearch}
          onSearchQueryChange={handleSearchQueryChange}
          onSelect={handleSelect}
          placeholderText="Select Guest Author(s)"
          values={currentSelection}
          showClearAll={false}
          shouldCloseOnSelection={false}
        />

        <SelectedOptionBadges options={selectedOptions} onDeselect={handleDeselectViaBadge} />
      </div>

      <div className="flex items-center space-x-2">
        <Button variant="primary-inverse" Icon={UserPlusIcon} onClick={handleOpenAdding}>
          New guest author
        </Button>

        <Link to="/guest_authors" target="_blank">
          <Button type="button" className="text-sm text-gray-600" variant="flush" Icon={ArrowTopRightOnSquareIcon}>
            Manage
          </Button>
        </Link>
      </div>
      <AddSlideOver isOpen={isAdding} onClose={handleCloseAdding} />
    </div>
  );
};

export default GuestAuthors;
