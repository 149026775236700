import { FC } from 'react';
import { ArrowDownTrayIcon, CakeIcon, Cog8ToothIcon, GiftIcon } from '@heroicons/react/24/outline';

import SettingsContainer from '../../../../components/SideTabContainer';
// Interfaces
import { SideTab } from '../../../../interfaces/general';
// Components
import NavContainer from '../../NavContainer';

const tabs: SideTab[] = [
  {
    label: 'Milestones',
    name: 'milestones',
    targetRoute: '/referral_program/configure/milestones',
    icon: CakeIcon,
  },
  {
    label: 'Rewards',
    name: 'rewards',
    targetRoute: '/referral_program/configure/rewards',
    icon: GiftIcon,
  },
  {
    label: 'Export Data',
    name: 'export_data',
    targetRoute: '/referral_program/configure/export',
    icon: ArrowDownTrayIcon,
  },
  {
    label: 'Settings',
    name: 'settings',
    targetRoute: '/referral_program/configure/settings',
    icon: Cog8ToothIcon,
  },
];

interface Props {
  selectedTab: string;
  children: React.ReactNode;
}

const ConfigureContainer: FC<Props> = (props: Props) => {
  const { selectedTab, children } = props;

  return (
    <NavContainer>
      <SettingsContainer tabs={tabs} selectedTab={selectedTab}>
        {children}
      </SettingsContainer>
    </NavContainer>
  );
};

export default ConfigureContainer;
