import { createContext, useContext } from 'react';

export interface RewindContextInterface {
  rewindModalOpen: boolean;
  setRewindModalOpen: (open: boolean) => void;
  showRewind2024Button: boolean;
}

const RewindContext = createContext<RewindContextInterface>({
  rewindModalOpen: false,
  setRewindModalOpen: () => {},
  showRewind2024Button: false,
});

export const useRewindContext = () => useContext(RewindContext);
export const RewindContextProvider = RewindContext.Provider;
