const NoResults = () => {
  return (
    <tr key="no-results">
      <td colSpan={5} className="h-48 items-center text-center mx-auto">
        No Message Attempts
      </td>
    </tr>
  );
};

export default NoResults;
