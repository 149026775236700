import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowPathIcon, CheckIcon } from '@heroicons/react/24/outline';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import Text from '@/components/Text';
import { useCustomDomains, useDeleteCustomDomain } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationPermissions, { checkPermissions } from '@/hooks/usePublications/usePublicationPermissions';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { CustomDomain } from '@/interfaces/custom_domain';
import { TutorialType } from '@/interfaces/tutorial';
import ConfirmEmailVerification from '@/pages/Post/ConfirmEmailVerification';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';

import { Section } from '../../../Components';
import NotEditing from '../../../Components/NotEditing';
import VideoTutorial from '../../../Components/VideoTutorial';

import EmailDomainSection from './CustomDomainView/EmailDomainSection';
import NewCustomDomain from './CustomDomainView/New';
import RedirectDomainSection from './CustomDomainView/RedirectDomainSection';
import WebDomainSection from './CustomDomainView/WebDomainSection';

const DomainSettings: React.FunctionComponent = () => {
  const publicationId = useCurrentPublicationId();
  const { data: permissions } = usePublicationPermissions(publicationId);
  const { data: settings } = usePublicationSettings(publicationId);
  const { data: currentPublication } = usePublication(publicationId);
  const { data: customDomains } = useCustomDomains(publicationId);
  const webDomain = customDomains?.find((d: CustomDomain) => d.web_enabled);
  const redirectDomain = customDomains?.find((d: CustomDomain) => d.web_redirect_enabled);
  const emailDomain = customDomains?.find((d: CustomDomain) => d.email_enabled);
  const canCustomDomain = checkPermissions(permissions || {}, 'domain_setting', 'update');
  const deleteCustomDomain = useDeleteCustomDomain();

  const [isNewPanelOpen, setIsNewPanelOpen] = useState<boolean>(false);
  const [customDomainToVerify, setCustomDomainToVerify] = useState<CustomDomain | undefined>(undefined);

  const customDomainSupportArticles = [
    // {
    //   name: 'Bluehost',
    // },
    {
      name: 'Cloudflare',
      url: 'https://support.beehiiv.com/hc/en-us/articles/13847455944087-How-do-I-set-up-a-custom-domain-with-Cloudflare-',
    },
    {
      name: 'Google Domains',
      url: 'https://support.beehiiv.com/hc/en-us/articles/4413246905111-How-do-I-set-up-a-custom-domain-with-Google-domains-',
    },
    {
      name: 'Godaddy',
      url: 'https://support.beehiiv.com/hc/en-us/articles/13808744641431-How-do-I-set-up-a-custom-domain-with-GoDaddy-',
    },
    {
      name: 'Hover',
      url: 'https://support.beehiiv.com/hc/en-us/articles/13847244887319-How-do-I-set-up-a-custom-domain-with-Hover-',
    },
    // {
    //   name: 'Hostgator',
    // },
    {
      name: 'Namecheap',
      url: 'https://support.beehiiv.com/hc/en-us/articles/13811949326487-How-do-I-set-up-a-custom-domain-with-Namecheap-',
    },
  ];

  const hasCustomDomainAndNoRedirectDomain = webDomain && !redirectDomain;
  const webDomainIsWWW = webDomain && webDomain.domain.startsWith('www.');
  const webDomainIsRoot = webDomain && webDomain.root_domain === webDomain.domain;
  const showRedirectWarningMessage = hasCustomDomainAndNoRedirectDomain && (webDomainIsWWW || webDomainIsRoot);
  const redirectWarningMessage = webDomainIsWWW
    ? `Since your web domain starts with www, we recommend also adding ${webDomain.root_domain} as a custom domain, then selecting it as the Redirect Domain below.`
    : `Since your web domain is the root domain, we recommend also adding www.${webDomain?.domain} as a custom domain, then selecting it as the Redirect Domain below.`;
  if (!currentPublication) return null;

  return (
    <>
      <div className="px-6">
        <ConfirmEmailVerification />
      </div>
      <NewCustomDomain
        isOpen={isNewPanelOpen}
        onClose={() => {
          setIsNewPanelOpen(false);
          setTimeout(() => setCustomDomainToVerify(undefined), 500);
        }}
        customDomainToVerify={customDomainToVerify}
      />
      <Section
        title="Verified Domains"
        description={
          <div>
            Update your publication&apos;s domain on the web and the address used for sending your newsletters.
            <span className="ml-1">
              <VideoTutorial type={TutorialType.CUSTOM_DOMAINS} />
            </span>
          </div>
        }
        scrollToId="verified-domains"
      >
        {!settings?.custom_domains && (
          <div className="flex-grow space-y-6">
            <div className="col-span-12 sm:col-span-6">
              <div className="my-3 p-3 bg-primary-300 bg-opacity-50 rounded-md border border-primary-400 text-gray-800 flex flex-col space-y-4">
                <Text size="sm">
                  Custom domains are only supported on the <b>Grow</b> plan or higher.
                  {checkPermissions(permissions || {}, 'organization', 'update') && (
                    <>
                      <Link to="/settings/billing" className="underline mx-1">
                        Click here
                      </Link>
                      <span>to upgrade</span>
                    </>
                  )}
                </Text>
              </div>
            </div>
          </div>
        )}
        <div className="mb-8 flex flex-col items-start">
          {canCustomDomain && (
            <>
              <div className="mt-1 w-full">
                {showRedirectWarningMessage ? (
                  <div className="my-3 p-3 bg-primary-300 bg-opacity-50 rounded-md border border-primary-400 text-gray-800 flex flex-col space-y-4">
                    <Text size="sm">{redirectWarningMessage}</Text>
                  </div>
                ) : (
                  ''
                )}
                {customDomains?.map((customDomain: CustomDomain) => (
                  <div className="flex justify-between items-center w-full border-b border-gray-100 mb-4 pb-2">
                    <div className="py-1 space-y-2">
                      <NotEditing
                        label={customDomain.domain}
                        value={
                          <div>
                            {customDomain.verified ? (
                              <Badge type="success">
                                <CheckIcon className="w-4 h-4 text-green-800 mr-2" />
                                Verified
                              </Badge>
                            ) : (
                              <Badge type="information">
                                <ArrowPathIcon className="w-4 h-4 text-gray-800 mr-2" />
                                Verifying
                              </Badge>
                            )}
                          </div>
                        }
                      />
                    </div>
                    <div className="flex items-center space-x-4">
                      {customDomain.verified && !customDomain.unused && (
                        <span className="font-light text-xs italic">In use</span>
                      )}
                      <EllipsisDropdown
                        portalMountedId={customDomain.id}
                        options={[
                          {
                            label: customDomain.verified ? 'View Verification Record' : 'Verify',
                            onClick: () => {
                              setCustomDomainToVerify(customDomain);
                              setIsNewPanelOpen(true);
                            },
                          },
                          ...(customDomain.unused
                            ? [
                                {
                                  label: 'Remove',
                                  onClick: () => deleteCustomDomain.mutate(customDomain.id),
                                },
                              ]
                            : []),
                        ]}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <Button
                variant="primary-inverse"
                className="mt-4"
                disabled={!canCustomDomain}
                onClick={() => setIsNewPanelOpen(true)}
              >
                Add Custom Domain
              </Button>
            </>
          )}
        </div>
      </Section>
      <WebDomainSection domain={webDomain} publication={currentPublication} />
      <RedirectDomainSection domain={redirectDomain} />
      <EmailDomainSection domain={emailDomain} publication={currentPublication} />
      <Section
        title="Help"
        scrollToId="domain-help"
        description="We created support articles specific to popular DNS providers to assist you with configuring your custom domain. This is the quickest way to resolve any issues."
        hasDivider
      >
        {customDomainSupportArticles.map((article) => {
          return (
            <>
              <a href={article.url} target="_blank" rel="noreferrer" className="text-primary-500">
                {article.name}
              </a>
              <br />
            </>
          );
        })}{' '}
      </Section>
    </>
  );
};

export default DomainSettings;
