import { FC } from 'react';

import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Dropdown } from '@/ui/Dropdown';
import { optionDataToOptions } from '@/utils';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const ExternalEmbedInput: FC<Props> = ({ value, onChange }) => {
  const publicationId = useCurrentPublicationId();
  const externalEmbedsData = useOptions(publicationId, 'external_embeds', Infinity);
  const externalEmbedOptions = optionDataToOptions(externalEmbedsData);

  return (
    <Dropdown
      className="w-full"
      name="value"
      placeholderText="Select Embed"
      value={value || ''}
      onSelect={(name: string, selectedValue: string) => onChange(selectedValue)}
      options={externalEmbedOptions}
      emptyLabel="No embeds found"
    />
  );
};

export default ExternalEmbedInput;
