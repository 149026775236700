import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Price, Tier } from '@/interfaces/tier';
import api from '@/services/swarm';

interface Props {
  id: string;
  onSuccess?: () => void;
  onError?: (err: any) => void;
}

type PriceUpdateProps = Partial<Price>;
type TierUpdateProps = Partial<Omit<Tier, 'id' | 'status'>> & {
  prices_attributes: PriceUpdateProps[];
};

const useUpdateTier = ({ id, onSuccess, onError }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const updateTier = (variables: TierUpdateProps) =>
    api
      .patch(`/tiers/${id}`, {
        publication_id: currentPublicationId,
        tier: variables,
      })
      .then((res) => res.data);

  return useMutation<Tier, unknown, TierUpdateProps>(updateTier, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['publication_settings', 'premium', 'tiers', currentPublicationId]);
      toast.success('Tier Updated!');
      onSuccess?.();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.errors?.base || 'Something went wrong, please try again.');
      onError?.(err?.response?.data?.errors);
    },
  });
};

export default useUpdateTier;
