interface Props {
  className: string;
  width?: number;
  height?: number;
  onClick: () => void;
}

const SidebarToggleButton: React.FC<Props> = ({ className, width = 16, height = 16, onClick }) => (
  <button type="button" className={className} onClick={onClick}>
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_989_3134)">
        <path
          d="M4.70479 9.14922C4.35766 9.49636 4.35766 10.0592 4.70479 10.4063C5.05193 10.7534 5.61474 10.7534 5.96187 10.4063L7.73965 8.62853C8.08678 8.28139 8.08678 7.71858 7.73965 7.37145L5.96187 5.59367C5.61474 5.24654 5.05193 5.24653 4.70479 5.59367C4.35766 5.9408 4.35766 6.50361 4.70479 6.85075L5.85403 7.99999L4.70479 9.14922Z"
          fill="#272A2F"
          fillOpacity="0.6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2.66665C0 1.19389 1.19391 -1.52588e-05 2.66667 -1.52588e-05H13.3333C14.8061 -1.52588e-05 16 1.19389 16 2.66665V13.3333C16 14.8061 14.8061 16 13.3333 16H2.66667C1.19391 16 0 14.8061 0 13.3333V2.66665ZM2.66667 1.77776C2.17575 1.77776 1.77778 2.17573 1.77778 2.66665V13.3333C1.77778 13.8242 2.17575 14.2222 2.66667 14.2222H10.6667L10.6667 1.77776H2.66667ZM13.3333 14.2222H12.4444L12.4444 1.77776L13.3333 1.77776C13.8243 1.77776 14.2222 2.17573 14.2222 2.66665V13.3333C14.2222 13.8242 13.8243 14.2222 13.3333 14.2222Z"
          fill="#272A2F"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_989_3134">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </button>
);

export default SidebarToggleButton;
