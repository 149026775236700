import { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { Checkbox, FileSelect, Input } from '@/components/Form';
import Modal from '@/components/Modal';
import { useCurrentPublication } from '@/hooks';
import useCreateBugReport from '@/hooks/useCreateBugReport';
import { BugReport } from '@/interfaces/bug_report';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import { Textarea } from '@/ui/Textarea';

type LoaderProps = {
  open: boolean;
  onClose: () => void;
};

type Props = LoaderProps & {
  publicationId: string;
};

const DEVICE_OTHERS = ['desktop_other', 'tablet_other', 'mobile_other'];

const RoundedIcon = ({ bgColor, Icon }: { bgColor: string; Icon: React.ReactNode }) => (
  <div className={`rounded-full ${bgColor} relative flex items-center justify-center p-[10px]`}>{Icon}</div>
);

const InfoIcon = () => (
  <div className="w-10 h-10 p-2.5 bg-indigo-50 rounded-3xl justify-center items-center flex">
    <RoundedIcon
      bgColor="bg-indigo-50"
      Icon={<InformationCircleIcon color="#4746E5" className="h-6 w-6 rounded-full" />}
    />
  </div>
);

const BugReportModal = ({ open, onClose, publicationId }: Props) => {
  const createBugReport = useCreateBugReport();

  const [saving, setSaving] = useState<boolean>(false);
  const [bugDescription, setBugDescription] = useState<string>('');
  const [bugSeverity, setBugSeverity] = useState<string>('');
  const [deviceType, setDeviceType] = useState<string>('');
  const [deviceTypeOther, setDeviceTypeOther] = useState<string | undefined>(undefined);
  const [browserType, setBrowserType] = useState<string>('');
  const [browserTypeOther, setBrowserTypeOther] = useState<string | undefined>(undefined);
  const [featureType, setFeatureType] = useState<string>('');
  const [featureTypeOther, setFeatureTypeOther] = useState<string | undefined>(undefined);
  const [stepsToReproduce, setStepsToReproduce] = useState<string>('');
  const [screenshot, setScreenshot] = useState<File | null>(null);
  const [agree, setAgree] = useState<boolean>(false);

  const bugSeverityOptions: Option[] = [
    { label: 'Urgent', value: 'urgent' },
    { label: 'High', value: 'high' },
    { label: 'Medium', value: 'medium' },
    { label: 'Low', value: 'low' },
  ];

  const deviceTypeOptions: Option[] = [
    { label: 'Desktop', value: '', isOptGroup: true },
    { label: 'Apple iMac', value: 'desktop_apple_imac' },
    { label: 'Apple MacBook', value: 'desktop_apple_macbook' },
    { label: 'Dell XPS Desktop', value: 'desktop_dell_xpg' },
    { label: 'HP Envy Desktop', value: 'desktop_hp_envy' },
    { label: 'Microsoft Surface Studio', value: 'desktop_ms_surface' },
    { label: 'Lenovo ThinkCentre', value: 'desktop_lenovo' },
    { label: 'Other Desktop (please specify)', value: 'desktop_other' },
    { label: 'Tablet', value: '', isOptGroup: true },
    { label: 'Apple iPad', value: 'tablet_apple_ipad' },
    { label: 'Samsung Galaxy Tab', value: 'tablet_samsung_galaxy' },
    { label: 'Microsoft Surface Pro', value: 'tablet_ms_surface' },
    { label: 'Amazon Fire HD', value: 'tablet_amazon_fire' },
    { label: 'Lenovo Tab', value: 'tablet_lenovo' },
    { label: 'Other Tablet (please specify)', value: 'tablet_other' },
    { label: 'Mobile', value: '', isOptGroup: true },
    { label: 'Apple iPhone', value: 'mobile_apple_iphone' },
    { label: 'Samsung Galaxy', value: 'mobile_samsung_galaxy' },
    { label: 'Google Pixel', value: 'mobile_google_pixel' },
    { label: 'OnePlus', value: 'mobile_oneplus' },
    { label: 'Xiaomi Redmi Note', value: 'mobile_xiaomi_redmi_note' },
    { label: 'Other Mobile (please specify)', value: 'mobile_other' },
  ];

  const browserTypeOptions: Option[] = [
    { label: 'Chrome', value: 'chrome' },
    { label: 'Firefox', value: 'firefox' },
    { label: 'Arc', value: 'arc' },
    { label: 'Safari', value: 'safari' },
    { label: 'Edge', value: 'edge' },
    { label: 'Opera', value: 'opera' },
    { label: 'Other (please specify)', value: 'other' },
  ];

  const featureTypeOptions: Option[] = [
    { label: 'Editor', value: 'editor' },
    { label: 'Automations', value: 'automations' },
    { label: 'Website Builder', value: 'website_builder' },
    { label: 'Newsletter Builder', value: 'newsletter_builder' },
    { label: 'Boosts', value: 'boosts' },
    { label: 'Referral Program', value: 'referral_program' },
    { label: 'Polls', value: 'polls' },
    { label: 'Surveys', value: 'surveys' },
    { label: 'Paid Subscriptions', value: 'paid_subscriptions' },
    { label: 'Segments', value: 'segments' },
    { label: 'Data & Analytics', value: 'data_analytics' },
    { label: 'Ads', value: 'ads' },
    { label: 'Login/Authentication', value: 'authentication' },
    { label: 'Search', value: 'search' },
    { label: 'Navigation', value: 'navigation' },
    { label: 'Payments', value: 'payments' },
    { label: 'Dev Tools (API, Webhooks)', value: 'dev_tools' },
    { label: 'Settings', value: 'settings' },
    { label: 'Notifications', value: 'notifications' },
    { label: 'Other (please specify)', value: 'other' },
  ];

  const handleSelectBugSeverity = (_name: string, value: string) => {
    setBugSeverity(value);
  };

  const handleSelectDeviceType = (_name: string, value: string) => {
    if (!DEVICE_OTHERS.some((item) => value === item)) setDeviceTypeOther(undefined);
    setDeviceType(value);
  };

  const handleSelectBrowserType = (_name: string, value: string) => {
    if (value !== 'other') setBrowserTypeOther(undefined);
    setBrowserType(value);
  };

  const handleSelectFeatureType = (_name: string, value: string) => {
    if (value !== 'other') setFeatureTypeOther(undefined);
    setFeatureType(value);
  };

  const handleScreenshotChange = (selectedFile: File | null) => {
    setScreenshot(selectedFile);
  };

  const clearAll = () => {
    setBugDescription('');
    setBugSeverity('');
    setDeviceType('');
    setBrowserType('');
    setFeatureType('');
    setStepsToReproduce('');
    setScreenshot(null);
    setAgree(false);
  };

  const handleClose = () => {
    onClose();
    clearAll();
  };

  const onBugReportSubmit = async (data: BugReport) => {
    /*
      Due to needing to upload an image
      we have to use multipart form instead of JSON
    */
    const formData = new FormData();
    formData.append('publication_id', publicationId);

    formData.append('bug_report[description]', data.description.toString());
    formData.append('bug_report[severity]', data.severity.toString());
    formData.append('bug_report[device_type]', data.device_type.toString());
    if (deviceTypeOther) formData.append('bug_report[device_type_other]', deviceTypeOther);
    formData.append('bug_report[browser_type]', data.browser_type.toString());
    if (browserTypeOther) formData.append('bug_report[browser_type_other]', browserTypeOther);
    formData.append('bug_report[feature_type]', data.feature_type.toString());
    if (featureTypeOther) formData.append('bug_report[feature_type_other]', featureTypeOther);
    formData.append('bug_report[steps_to_reproduce]', data.steps_to_reproduce.toString());
    if (data.screenshot) formData.append('bug_report[screenshot]', data.screenshot);

    try {
      await createBugReport.mutateAsync(formData);
      handleClose();
    } catch {
      setSaving(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSaving(true);

    onBugReportSubmit({
      description: bugDescription,
      severity: bugSeverity,
      device_type: deviceType,
      browser_type: browserType,
      feature_type: featureType,
      steps_to_reproduce: stepsToReproduce,
      screenshot,
    }).then(() => {
      setSaving(false);
    });
  };

  const isDisabled = () =>
    bugDescription.length === 0 ||
    bugSeverity.length === 0 ||
    deviceType.length === 0 ||
    (DEVICE_OTHERS.some((item) => deviceType === item) && (!deviceTypeOther || deviceTypeOther.length === 0)) ||
    browserType.length === 0 ||
    (browserType === 'other' && (!browserTypeOther || browserTypeOther.length === 0)) ||
    featureType.length === 0 ||
    (featureType === 'other' && (!featureTypeOther || featureTypeOther.length === 0)) ||
    !agree;

  return (
    <Modal
      isOpen={open}
      includeCloseButton={false}
      onClose={handleClose}
      className="bg-white rounded-lg shadow flex-col justify-start items-start inline-flex max-w-lg w-full z-50"
    >
      <div className="self-stretch rounded-lg px-6 pt-6 pb-4 bg-white justify-start items-start gap-4 inline-flex">
        <InfoIcon />
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
          <div className="self-stretch text-gray-900 text-lg font-['SF Pro'] leading-7">Report a Bug</div>
          <div className="self-stretch text-gray-700 text-sm font-normal font-['SF Pro'] leading-tight">
            We value your feedback. Please fill out this form to help us improve our product.
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="space-y-4 px-6">
          <div>
            <Textarea
              name="bug_description"
              placeholderText="When I try to do this..."
              rows={4}
              labelText="Bug Description"
              helperText="Describe what happened and what you expected to happen."
              value={bugDescription}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setBugDescription(e.target.value)}
              required
            />
          </div>
          <div>
            <Dropdown
              name="severity"
              labelText="Bug Severity"
              value={bugSeverity}
              onSelect={handleSelectBugSeverity}
              options={bugSeverityOptions}
              required
            />
          </div>
          <div>
            <Dropdown
              name="device_type"
              labelText="Device Type"
              value={deviceType}
              onSelect={handleSelectDeviceType}
              options={deviceTypeOptions}
              required
            />
          </div>
          {DEVICE_OTHERS.some((item) => deviceType === item) && (
            <div>
              <Input
                name="device_type_other"
                labelText="Other Device Type (please specify)"
                type="text"
                value={deviceTypeOther}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeviceTypeOther(e.target.value)}
                required
              />
            </div>
          )}
          <div>
            <Dropdown
              name="browser_type"
              labelText="Browser Type"
              value={browserType}
              onSelect={handleSelectBrowserType}
              options={browserTypeOptions}
              required
            />
          </div>
          {browserType === 'other' && (
            <div>
              <Input
                name="browser_type_other"
                labelText="Other Browser Type (please specify)"
                type="text"
                value={browserTypeOther}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBrowserTypeOther(e.target.value)}
                required
              />
            </div>
          )}
          <div>
            <Dropdown
              name="feature_type"
              labelText="Feature or Function Type"
              value={featureType}
              onSelect={handleSelectFeatureType}
              options={featureTypeOptions}
              required
            />
          </div>
          {featureType === 'other' && (
            <div>
              <Input
                name="feature_type_other"
                labelText="Other Feature or Function Type (please specify)"
                type="text"
                value={featureTypeOther}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFeatureTypeOther(e.target.value)}
                required
              />
            </div>
          )}
          <div>
            <Textarea
              name="steps_to_reproduce"
              placeholderText="When I try to do this..."
              rows={4}
              labelText="Steps to Reproduce"
              helperText="List the steps we can follow to see the bug ourselves."
              value={stepsToReproduce}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setStepsToReproduce(e.target.value)}
            />
          </div>
          <div>
            <FileSelect
              name="file"
              labelText="Screenshots"
              onFileSelect={handleScreenshotChange}
              helperText="Provide any visuals such as screenshots or recordings."
              file={screenshot}
              accept=".jpg,.jpeg,.gif,.png,.mov,.mp4,.webp,.heic"
            />
          </div>
          <div>
            <Checkbox
              name="agree"
              labelText="I understand that I will not receive a direct response from this form submission. To receive customer support, I need to submit a separate support ticket."
              checked={agree || false}
              onChange={(checked) => setAgree(checked)}
            />
          </div>
        </div>
        <div className="flex flex-row w-full justify-end space-x-3 mt-6 px-6 py-3 rounded-b-lg bg-gray-50">
          <Button type="button" variant="primary-inverse" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={isDisabled()} loading={saving}>
            Submit Bug
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const Loader = ({ open, onClose }: LoaderProps) => {
  const { data: currentPublication, isSuccess } = useCurrentPublication();

  if (!isSuccess) return null;

  return <BugReportModal open={open} onClose={onClose} publicationId={currentPublication.id} />;
};

export default Loader;
