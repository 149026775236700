import React from 'react';
import toast from 'react-hot-toast';

import { Input } from '@/components/Form';
import { useFormContext } from '@/pages/ReferralProgram/Configure/FormContext';

const SinglePromoCodeForm = () => {
  const { setReward, reward } = useFormContext();

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.trim();
    if (!rawValue) {
      return;
    }

    try {
      setReward({ ...reward, static_promo_code: rawValue });
    } catch (error: any) {
      // do nothing
      toast.error('There was a problem parsing your promo code!');
    }
  };

  return (
    <Input
      placeholderText="CODE"
      labelText="Promo code"
      helperText="This code will be assigned to all subscribers who achieve this reward"
      name="promo-code"
      onBlur={handleBlur}
      defaultValue={reward.static_promo_code}
    />
  );
};

export default SinglePromoCodeForm;
