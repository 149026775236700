import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

import ActionModal from '../../../../components/ActionModal';
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { useTestimonials } from '../../../../hooks/useTestimonials';
import { useCreateUpgradePageTestimonial } from '../../../../hooks/useUpgradePages';
import { Button } from '../../../../ui/Button';

const TestimonialView = ({ testimonial }: any) => {
  return (
    <div className="space-y-2">
      <div className="flex space-x-2">
        <img
          className="h-9 w-9 flex-none rounded-full bg-gray-50"
          src={testimonial?.picture_url || ''}
          alt="author of the testimonial"
        />
        <div>
          <p className="text-gray-800 font-semibold text-sm text-left">
            <div className="hover:text-gray-900">{testimonial?.name}</div>
          </p>
          <p className="flex text-gray-500 text-sm">{testimonial?.description}</p>
        </div>
      </div>

      <p className="flex text-gray-500 text-sm truncate italic">&quot;{testimonial?.message}&quot;</p>
    </div>
  );
};

const TestimonialOption = ({ testimonial, handleSelectTestimonial }: any) => {
  return (
    <li className="rounded hover:bg-gray-100">
      <button
        type="button"
        className="flex justify-between items-start py-2.5 px-2 hover:bg-gray-100 w-full"
        onClick={() => handleSelectTestimonial(testimonial)}
      >
        <div className="w-full">
          <TestimonialView testimonial={testimonial} />
        </div>
      </button>
    </li>
  );
};

interface Props {
  upgradePageId: string;
  upgradePageTestimonialIds?: string[];
  onSave: () => void;
}

const SelectTestimonial = ({ upgradePageId, upgradePageTestimonialIds, onSave }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState<any>(null);

  const testimonialsQuery = useTestimonials({
    search: '',
    perPage: 4,
    exludedTestimonialIds: upgradePageTestimonialIds,
  });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = testimonialsQuery;
  const testimonials = data?.pages.flatMap((page) => page.testimonials) || [];
  const totalTestimonialsCount = data?.pages[0]?.pagination?.total;
  const showingTestimonialsCount = testimonials?.length || 0;

  const handleSelectTestimonial = (testimonial: any) => {
    setSelectedTestimonial(testimonial);
  };

  const upgradePageTestimonial = useCreateUpgradePageTestimonial({
    onSuccess: () => {
      toast.success('Created!');
      queryClient.invalidateQueries(['upgrade_page', currentPublicationId, 'draft']);
      queryClient.invalidateQueries(['testimonials', currentPublicationId, '']);
      setIsModalOpen(false);
      setSelectedTestimonial(null);
      onSave();
    },
  });
  const handleAddTestimonial = () => {
    upgradePageTestimonial.mutate({
      upgrade_page_id: upgradePageId,
      testimonial_id: selectedTestimonial?.id,
    });
  };

  return (
    <div>
      <ActionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        resourceId=""
        headerText={!selectedTestimonial ? 'Select a Testimonial' : 'Add Testimonial'}
        onProceed={handleAddTestimonial}
        isWorking={upgradePageTestimonial.isLoading}
        actionText="Add"
        disabled={!selectedTestimonial}
      >
        {selectedTestimonial ? (
          <div className="space-y-4">
            <button
              type="button"
              className="text-gray-500 flex items-center text-sm font-regular"
              onClick={() => setSelectedTestimonial(null)}
            >
              <ChevronLeftIcon className="w-4 h-4 mr-1" /> <span>Select a diffent option</span>
            </button>
            <div className="shadow p-2">
              <TestimonialView testimonial={selectedTestimonial} />
            </div>
          </div>
        ) : (
          <div>
            <ul className="h-80 shadow-inner overflow-auto divide-y">
              {testimonials?.length > 0 ? (
                testimonials.map((testimonial) => {
                  return (
                    <TestimonialOption
                      key={testimonial.id}
                      testimonial={testimonial}
                      handleSelectTestimonial={handleSelectTestimonial}
                    />
                  );
                })
              ) : (
                <div className="border border-gray-100 h-full flex items-center justify-center px-8">
                  <span className="text-gray-500 font-medimum text-center">
                    No testimonials found, or all of them are already being used on this Upgrade Page. To create a new
                    testimonial close this modal and select the &lsquo;Add Testimonial&rsquo; button.
                  </span>
                </div>
              )}
            </ul>
            <div className="flex justify-between items-center pt-4">
              <span className="text-xs font-semibold text-gray-600">{`Showing ${showingTestimonialsCount} of ${totalTestimonialsCount} results`}</span>
              {hasNextPage && (
                <Button
                  variant="primary-inverse"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              )}
            </div>
          </div>
        )}
      </ActionModal>
      <Button variant="primary-inverse" size="xs" onClick={() => setIsModalOpen(true)}>
        Select
      </Button>
    </div>
  );
};

export default SelectTestimonial;
