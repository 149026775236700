import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentTimeZone } from '@/hooks';
import { Order } from '@/interfaces/general';
import api from '@/services/swarm';
import { TimePeriod } from '@/utils';

interface ChartData {
  purchaser_email: string;
  recipient_email: string;
  tier_name: string;
  interval: string;
  start_at: string;
  created_at: string;
}

interface ApiResponse {
  chart_data?: ChartData[];
  period: TimePeriod;
  time_zone: string;
}

interface UseGiftsPerPeriodProps {
  period: TimePeriod;
  orderBy: string;
  direction: Order;
}

const useGiftsPerPeriod = ({ period, orderBy, direction }: UseGiftsPerPeriodProps) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const currentTimeZone = useCurrentTimeZone();

  const fetchTierChangeData = () => {
    return api
      .get(`/premium_data/gifts_per_period/${period}`, {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
          order_by: orderBy,
          direction,
        },
      })
      .then((res) => res.data);
  };

  return useQuery<ApiResponse>(
    ['premium_data', 'donationsPerPeriod', currentPublicationId, period, currentTimeZone, orderBy, direction],
    fetchTierChangeData,
    {
      keepPreviousData: true,
    }
  );
};

export default useGiftsPerPeriod;
