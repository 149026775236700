import { FC } from 'react';

import { Condition } from '@/interfaces/segment';
import { Dropdown } from '@/ui/Dropdown';

import { attributeOptions, emailActionOptions, measureOptions } from './nameOptions';
import { NameTypes } from './types';

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleSelectWhichName: (value: string, blockIndex: number, subIndex?: number) => void;
}

const ConditionNameSelect: FC<Props> = ({ condition, handleSelectWhichName, index, subIndex }) => {
  if (condition.type === NameTypes.MEASURE) {
    return (
      <Dropdown
        className="w-full"
        name="measure"
        placeholderText="Select Engagement"
        value={condition.name || ''}
        onSelect={(name: string, value: string) => handleSelectWhichName(value, index, subIndex)}
        options={measureOptions}
      />
    );
  }

  if (condition.type === NameTypes.EMAIL_ACTION) {
    return (
      <Dropdown
        className="w-full"
        name="email_type"
        placeholderText="Select Email Type"
        value={condition.name || ''}
        onSelect={(name: string, value: string) => handleSelectWhichName(value, index, subIndex)}
        options={emailActionOptions}
      />
    );
  }

  return (
    <Dropdown
      className="w-full"
      name="attribute"
      placeholderText="Select Attribute"
      value={condition.name || ''}
      onSelect={(name: string, value: string) => handleSelectWhichName(value, index, subIndex)}
      options={attributeOptions}
    />
  );
};

export default ConditionNameSelect;
