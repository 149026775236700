import SearchInput from '@/ui/SearchInput';

import { Dropdown } from '../Dropdown';
import { FiltersPopover } from '../FiltersPopover';

import { ResourceListFiltersProps } from './ResourceListFilters.types';

const ResourceListFilters = ({
  search,
  setSearch,
  searchPlaceholder = 'Search',
  setFilter,
  filter,
  filterOptions,
  filterLabel = '',
  allowMultipleFilters = false,
  filterSections,
  onSaveFilters,
  onClearFilters,
  orderBy,
  setOrderBy,
  sortOrderOptions,
  orderByComponent,
}: ResourceListFiltersProps) => {
  const handleChangeInFilter = (_name: string, value: string | any) => setFilter?.(value);
  const handleChangeInSortOrder = (_name: string, value: string) => setOrderBy?.(value);

  return (
    <div className="flex gap-x-4 w-full justify-end">
      {setSearch && (
        <div className="w-full sm:w-fit">
          <SearchInput
            defaultValue={search}
            shouldDebounce
            debounceMilliseconds={800}
            onClearSearch={() => setSearch('')}
            onSearch={(val) => setSearch(val)}
            placeholder={searchPlaceholder}
          />
        </div>
      )}
      {filterOptions && (
        <Dropdown
          name="resource-list-filter-select"
          placeholderText={filterLabel}
          value={filter}
          onSelect={handleChangeInFilter}
          options={filterOptions}
          className="w-48"
          optionsContainerClassNames={{
            width: 'w-64',
          }}
          multiple={allowMultipleFilters}
        />
      )}
      {filterSections && (
        <FiltersPopover
          sections={filterSections}
          savedFilters={[]}
          onSaveFilters={onSaveFilters}
          onClearAll={onClearFilters || (() => { })}
          popoverWidthClassName="w-80"
        />
      )}
      {setOrderBy && sortOrderOptions && (
        <Dropdown
          name="resource-list-sort-order-select"
          value={orderBy}
          onSelect={handleChangeInSortOrder}
          options={sortOrderOptions}
          className="w-48"
          optionsContainerClassNames={{
            width: 'w-64',
          }}
        />
      )}
      {orderByComponent && <div>{orderByComponent}</div>}
    </div>
  );
};

export default ResourceListFilters;
