import { PaywallElement } from '@shared/dream-components';

import { PaywallStyle } from '@/interfaces/paywall';
import { PostTheme } from '@/pages/ThemeEditor/types';

import '@shared/dream-components/style.css';

interface Props {
  title: string;
  description: string;
  cta_text: string;
  benefits: string[];
  subheader: string;
  style: PaywallStyle;
  theme: PostTheme;
  image_url: string;
}

const PreviewPanel = ({ title, description, cta_text, benefits, subheader, style, image_url, theme }: Props) => {
  return (
    <div className="p-4 w-full flex flex-col items-center py-8">
      <div className="w-full max-w-2xl">
        <PaywallElement
          theme={theme}
          element={{
            type: 'paywall_break',
            attrs: {
              id: '1',
              data: {
                title,
                description,
                cta_text,
                benefits,
                subheader,
                style,
                image_url,
              },
            },
            content: [{ id: 'text', text: '' }],
          }}
        >
          {null}
        </PaywallElement>
      </div>
    </div>
  );
};

export default PreviewPanel;
