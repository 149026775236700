import { Typography, TypographyStack } from '@/components/Typography';
import UrlCopy from '@/components/UrlCopy';

interface Props {
  publicationId: string;
  automationId: string;
}

export const ApiTrigger = ({ publicationId, automationId }: Props) => (
  <>
    <TypographyStack gap="1" className="text-gray-500">
      <Typography token="font-normal/text/sm" colorWeight="700">
        Allow subscribers to be added via the API. The conditions you set here will still apply. Read the{' '}
        <a target="_blank" href="https://developers.beehiiv.com/docs/v2/na2fbzu7ltfw7-create" rel="noopener noreferrer">
          <Typography token="font-medium/text/sm" colorWeight="900" className="inline-flex items-baseline">
            API Documentation
          </Typography>
        </a>{' '}
        for more information.
      </Typography>
    </TypographyStack>
    <div className="flex flex-col space-y-4 mb-2">
      <UrlCopy labelText="publicationId" url={`pub_${publicationId}`} />
      <UrlCopy labelText="automationId" url={`aut_${automationId}`} />
    </div>
  </>
);
