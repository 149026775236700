/**
 * Pluralize the word given a count. By default it just appends an 's' to the
 * word but an override can be passed in if that is not grammatically correct
 * for the word
 *
 * @example pluralize('test', 3) => 'Tests'
 * @param str
 * @returns {string}
 */
const pluralize = (
  str: string,
  count: number | string,
  pluralOverride: string | undefined = undefined,
  hideCount?: boolean
) => {
  const castedCount: number = +count;
  const pluralizedStr = castedCount === 1 ? str : pluralOverride ?? `${str}s`;

  if (hideCount) {
    return pluralizedStr;
  }

  return `${count} ${pluralizedStr}`;
};

export default pluralize;
