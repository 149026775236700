import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

type SecondFactorPayload = {
  otp: string;
  enabled: boolean;
};

export default function useUpdateSecondFactor() {
  const queryClient = useQueryClient();

  return useMutation(
    (secondFactorPayload: SecondFactorPayload) =>
      api.patch('current_user/second_factor', {
        ...secondFactorPayload,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['second_factor']);
        toast.success('Two factor auth successfully added!');
      },
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
    }
  );
}
