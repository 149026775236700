import { SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';

import { LoadingSpinner } from '../../../UI/LoadingSpinner';

export const ShowOnPageSettings = ({
  value,
  onChange,
  isLoading,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
  isLoading: boolean;
}) => {
  return (
    <div className="flex items-center justify-stretch gap-2">
      <div className="w-[80px] flex items-center gap-2">
        <Text variant="secondary" size="2xs" weight="medium">
          Show
        </Text>
        {isLoading && <LoadingSpinner className="w-4 h-4" />}
      </div>
      <div className="grow flex items-center">
        <SimpleInputWrapper className="h-[38px] px-1">
          <ToggleGroup
            className="py-[1px] w-full"
            type="single"
            defaultValue={value ? 'true' : 'false'}
            onValueChange={(val) => onChange(val === 'true')}
          >
            <ToggleGroupItem asChild value="true">
              <div className="grow">
                <Text variant="inherit" size="2xs" weight="medium">
                  YES
                </Text>
              </div>
            </ToggleGroupItem>
            <ToggleGroupItem asChild value="false">
              <div className="grow">
                <Text variant="inherit" size="2xs" weight="medium">
                  NO
                </Text>
              </div>
            </ToggleGroupItem>
          </ToggleGroup>
        </SimpleInputWrapper>
      </div>
    </div>
  );
};
