import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks/usePublications';

const useCreateBoostAgreementsRestrictions = () => {
  const { data: currentPublication } = useCurrentPublication();
  const { settings } = useSettings();

  if (typeof settings?.max_monthly_boost_agreements === 'undefined' || !currentPublication) {
    return {
      hasReachedApplyLimit: false,
      remainingApplications: 0,
    };
  }

  const hasReachedApplyLimit =
    currentPublication.current_month_boost_agreements_count >= settings.max_monthly_boost_agreements;
  const remainingApplications = hasReachedApplyLimit
    ? 0
    : settings.max_monthly_boost_agreements - currentPublication.current_month_boost_agreements_count;

  return {
    hasReachedApplyLimit,
    remainingApplications,
  };
};

export default useCreateBoostAgreementsRestrictions;
