import styled from 'styled-components';

import Text from '../../../components/Text';
import { Tab } from '../../../interfaces/general';
import TabNavigation from '../../../ui/TabNavigation';

const StickyNavs = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`;

interface Props {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  sideTabs?: Tab[];
}

const SettingsPageLayout = ({ title, children, sideTabs }: Props) => {
  return (
    <div className="w-full relative">
      <StickyNavs className="w-full bg-white">
        <nav className="px-6 w-full h-12 border-b border-gray-200 items-center flex">
          <Text type="bold" size="sm" className="text-gray-600">
            {title}
          </Text>
        </nav>
        {sideTabs && (
          <nav className="border-b border-gray-200 mb-4 px-6 overflow-x-auto overflow-y-hidden">
            <TabNavigation className="w-full h-full" tabs={sideTabs} variant="tertiary" />
          </nav>
        )}
      </StickyNavs>
      <div>{children}</div>
    </div>
  );
};

export default SettingsPageLayout;
