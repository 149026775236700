import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { AdvertisementOpportunityLogoView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    advertisementOpportunityLogo: {
      setAdvertisementOpportunityLogo: () => ReturnType;
    };
  }
}

export const AdvertisementOpportunityLogo = Node.create({
  name: 'advertisementOpportunityLogo',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      publicationId: '',
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({
          'data-id': attributes.id,
        }),
      },
      showOptionsInitially: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-show-options-initially'),
        renderHTML: (attributes) => ({
          'data-show-options-initially': attributes.showOptionsInitially,
        }),
      },
      overrideSponsorText: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-override-sponsor-text'),
        renderHTML: (attributes) => ({
          'data-override-sponsor-text': attributes.overrideSponsorText,
        }),
      },
      overrideWidth: {
        default: 100,
        parseHTML: (element) => element.getAttribute('data-override-width'),
        renderHTML: (attributes) => ({
          'data-override-width': attributes.overrideWidth,
        }),
      },
      overrideAlign: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-override-align'),
        renderHTML: (attributes) => ({
          'data-override-align': attributes.overrideAlign,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setAdvertisementOpportunityLogo:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
            })
            .run(),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(AdvertisementOpportunityLogoView);
  },
});

export default AdvertisementOpportunityLogo;
