import { useSearchParams } from 'react-router-dom';

import { useSettings } from '@/context/settings-context';
import { Tab } from '@/interfaces/general';
import TabNavigation from '@/ui/TabNavigation';

import EmailTab from './EmailTab';
import WebTab from './WebTab';

interface Props {
  recommendingPublicationId: string;
}

const Tabs = ({ recommendingPublicationId }: Props) => {
  const { settings } = useSettings();
  const [searchParams] = useSearchParams();
  const activeTabParam = searchParams.get('tab');

  const canViewEmailApplications = settings?.boosts_plus === true;

  const tabs = [
    {
      name: 'web',
      label: 'Web',
      targetRoute: `/grow/boosts/recommending_publication/${recommendingPublicationId}?tab=web`,
      selected: !activeTabParam || activeTabParam === 'web',
    },
    {
      name: 'email',
      label: 'Email',
      targetRoute: `/grow/boosts/recommending_publication/${recommendingPublicationId}?tab=email`,
      selected: activeTabParam === 'email',
    },
  ] as Tab[];

  return (
    <>
      {canViewEmailApplications && <TabNavigation tabs={tabs} variant="tertiary" />}
      {!canViewEmailApplications && <WebTab recommendingPublicationId={recommendingPublicationId} />}
      {(!activeTabParam || activeTabParam === 'web') && (
        <WebTab recommendingPublicationId={recommendingPublicationId} />
      )}
      {activeTabParam === 'email' && <EmailTab recommendingPublicationId={recommendingPublicationId} />}
    </>
  );
};

export default Tabs;
