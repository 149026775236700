import { HomeModernIcon, UserIcon } from '@heroicons/react/24/solid';

import { LargeIconRadioOption } from '@/components/Form/LargeIconRadioGroup';
import { LargeItemRadioOption } from '@/components/Form/LargeItemRadioGroup';
import {
  OnboardingBusinessType,
  OnboardingOrganizationSize,
  OnboardingPrimaryGoal,
  OnboardingPublicationRate,
} from '@/interfaces/onboarding';

interface OnboardingQuestion {
  title: string;
  subtitle: string;
  onboarding_status: string;
  next_question: number | { [key: string]: number };
  previous_question?: number | string;
  options: LargeIconRadioOption[] | LargeItemRadioOption[];
}

export const BUSINESS_TYPE_OPTIONS: LargeIconRadioOption[] = [
  {
    name: 'Solo Creator',
    value: OnboardingBusinessType.SOLO_CREATORS,
    Icon: UserIcon,
    variant: 'tertiaryV2',
    size: 'md',
  },
  {
    name: 'Part of an Organization',
    value: OnboardingBusinessType.PART_OF_AN_ORGANIZATION,
    Icon: HomeModernIcon,
    variant: 'warningV2',
    size: 'md',
  },
];

export const ORGANIZATION_SIZE_OPTIONS: LargeItemRadioOption[] = [
  {
    name: '1-10 people',
    value: OnboardingOrganizationSize.SMALL,
  },
  {
    name: '11-50 people',
    value: OnboardingOrganizationSize.MEDIUM,
  },
  {
    name: '51-200 people',
    value: OnboardingOrganizationSize.LARGE,
  },
  {
    name: '201-1000 people',
    value: OnboardingOrganizationSize.XLARGE,
  },
  {
    name: '1,001+ people',
    value: OnboardingOrganizationSize.XXLARGE,
  },
];

export const PUBLICATION_RATE_OPTIONS: LargeItemRadioOption[] = [
  {
    name: 'Monthly',
    value: OnboardingPublicationRate.MONTHLY,
  },
  {
    name: 'Bi-weekly',
    value: OnboardingPublicationRate.BIWEEKLY,
  },
  {
    name: 'Weekly',
    value: OnboardingPublicationRate.WEEKLY,
  },
  {
    name: 'Daily',
    value: OnboardingPublicationRate.DAILY,
  },
  {
    name: 'More than once per day',
    value: OnboardingPublicationRate.MORE_THAN_ONCE_PER_DAY,
  },
  {
    name: 'Less than once per month',
    value: OnboardingPublicationRate.LESS_THAN_ONCE_PER_MONTH,
  },
];

export const PRIMARY_GOAL_OPTIONS: LargeItemRadioOption[] = [
  {
    name: 'Become a full-time creator',
    value: OnboardingPrimaryGoal.BECOME_FULL_TIME_CREATOR,
  },
  {
    name: 'Explore a hobby or an area of personal interest',
    value: OnboardingPrimaryGoal.EXPLORE_HOBBY_OR_INTEREST,
  },
  {
    name: 'Earn supplemental income or build a side hustle',
    value: OnboardingPrimaryGoal.SIDE_HUSTLE,
  },
  {
    name: 'Build a personal brand and establish domain expertise',
    value: OnboardingPrimaryGoal.BUILD_PERSONAL_BRAND,
  },
];

export const ONBOARDING_QUESTIONS: OnboardingQuestion[] = [
  {
    title: 'Are you publishing a newsletter on your own, or are you publishing on behalf of an organization?',
    subtitle: 'Select one option*',
    onboarding_status: 'business_type',
    next_question: {
      [OnboardingBusinessType.SOLO_CREATORS]: 2,
      [OnboardingBusinessType.PART_OF_AN_ORGANIZATION]: 1,
    },
    previous_question: '/onboarding/how_did_you_hear_about_us',
    options: BUSINESS_TYPE_OPTIONS,
  },
  {
    title: 'How large is your organization?',
    subtitle: 'Select one option*',
    onboarding_status: 'organization_size',
    next_question: 3,
    previous_question: 0,
    options: ORGANIZATION_SIZE_OPTIONS,
  },
  {
    title: 'What is the primary goal beehiiv can help you achieve?',
    subtitle: 'Select one option*',
    onboarding_status: 'primary_goal',
    next_question: 4,
    previous_question: 1,
    options: PRIMARY_GOAL_OPTIONS,
  },
  {
    title: 'How often do you plan to publish your newsletter?',
    subtitle: 'Select one option*',
    onboarding_status: 'publication_rate',
    next_question: -1,
    previous_question: 1,
    options: PUBLICATION_RATE_OPTIONS,
  },
  {
    title: 'How often do you plan to publish your newsletter?',
    subtitle: 'Select one option*',
    onboarding_status: 'publication_rate',
    next_question: -1,
    previous_question: 2,
    options: PUBLICATION_RATE_OPTIONS,
  },
];
