import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from './auth-context';
import { ReleaseVersionProvider } from './release-version-context';

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider: React.FunctionComponent<AppProviderProps> = ({ children }) => {
  const client = new QueryClient();

  return (
    <ReleaseVersionProvider>
      <QueryClientProvider client={client} contextSharing>
        <Router>
          <AuthProvider>{children}</AuthProvider>
        </Router>
      </QueryClientProvider>
    </ReleaseVersionProvider>
  );
};

export { AppProvider };
