/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import type { BreadcrumbProps, BreadcrumbsProps, PageHeadingProps, SideProps } from './types';

const PageHeading = ({ children }: PageHeadingProps) => (
  <div className="flex justify-between items-center p-4 border-b border-gray-100 h-16 relative md:sticky top-0 bg-white z-10">
    {children}
  </div>
);

PageHeading.Side = ({ children }: SideProps) => <div className="flex items-center space-x-2">{children}</div>;

PageHeading.Breadcrumbs = ({ children }: BreadcrumbsProps) => {
  const breadcrumbs = React.Children.toArray(children).reduce((acc: Array<React.ReactNode>, child, index) => {
    if (index === 0) {
      return [child];
    }

    return [
      ...acc,
      <li key={index} className="mx-2">
        <span>
          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
        </span>
      </li>,
      child,
    ];
  }, []);

  return <ul className="flex items-center text-sm text-gray-500">{breadcrumbs}</ul>;
};

PageHeading.Breadcrumb = ({ children, to }: BreadcrumbProps) => (
  <li className="last:text-gray-900 last:font-medium">
    {to ? <Link to={to}>{children}</Link> : <span className="line-clamp-1">{children}</span>}
  </li>
);

export default PageHeading;
