import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { StripeMigration, StripeMigrationStatus } from '@/interfaces/stripe';
import api from '@/services/swarm';

interface ApiResponse {
  stripe_migrations: StripeMigration[];
  pagination: Pagination;
}

const useStripeMigrations = (statuses: StripeMigrationStatus[] = []) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchMigrations = ({ pageParam = 1 }) =>
    api
      .get('/stripe_migrations', {
        params: {
          page: pageParam,
          publication_id: currentPublicationId,
          status: statuses,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([currentPublicationId, 'stripe_migrations', statuses], fetchMigrations, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};
      return page < totalPages ? page + 1 : false;
    },
    enabled: !!currentPublicationId,
    keepPreviousData: true,
  });
};

export default useStripeMigrations;
