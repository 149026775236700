import { Breadcrumbs } from '@/components/Breadcrumbs';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

interface Props {
  currentPublicationId: string;
  showEdit: boolean;
}

const Header = ({ currentPublicationId, showEdit }: Props) => (
  <Breadcrumbs className="">
    <Breadcrumbs.Home />
    <Breadcrumbs.Item to={appendSettingsPublicationId('/settings/publication/general', currentPublicationId)}>
      Publication Settings
    </Breadcrumbs.Item>
    <Breadcrumbs.Item to={appendSettingsPublicationId('/settings/publication/emails', currentPublicationId)}>
      Emails
    </Breadcrumbs.Item>
    <Breadcrumbs.Item
      to={appendSettingsPublicationId('/settings/publication/emails/opt_in_email', currentPublicationId)}
    >
      Opt in Email
    </Breadcrumbs.Item>
    {showEdit ? (
      <Breadcrumbs.Item
        to={appendSettingsPublicationId('/settings/publication/emails/opt_in_email/edit', currentPublicationId)}
      >
        Edit
      </Breadcrumbs.Item>
    ) : null}
  </Breadcrumbs>
);

export default Header;
