import { useSettings } from '@/context/settings-context';
import { Section } from '@/pages/Settings/Components';

import MailchimpSection from './MailchimpSection';

const Imports = () => {
  const { settings } = useSettings();

  return (
    <div>
      {settings?.mailchimp_integration && (
        <Section
          title="Mailchimp"
          description="Connect with Mailchimp for a one-time content import into beehiiv"
          scrollToId="mailchimp"
        >
          <MailchimpSection />
        </Section>
      )}
    </div>
  );
};

export default Imports;
