import { PropsWithChildren } from 'react';
import classNames from 'classnames';

type Props = PropsWithChildren<{
  isLight?: boolean;
  className?: string;
}>;

const FormBlock = ({ children, className, isLight = false }: Props) => {
  return (
    <div
      className={classNames(
        'p-6 flex flex-col gap-y-6 rounded-lg border border-surface-200',
        !isLight && !className && 'bg-surface-50',
        className
      )}
    >
      {children}
    </div>
  );
};

export default FormBlock;
