import { useMemo } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { EditorColor } from '@/components/TiptapEditor/lib/types';
import { getBorderColors } from '@/utils/documentColors';

import { useThemeData } from '../../../../../../lib/hooks/useThemeData';
import styles from '../../../../../../styled';
import { Button } from '../../../../../ui/Button';
import { Icon } from '../../../../../ui/Icon';
import { PanelHeadline, PanelSection } from '../../../../../ui/Panel';
import { useCurrentNode } from '../../../../hooks';
import ThemeColorButton from '../../../ThemeColorButton';
import useVisualSettingsPanel from '../VisualSettingsPanel.hooks';

export const BorderColor = ({
  editor,
  onShowCustomBorderColorPanel,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  onShowCustomBorderColorPanel: Function;
  currentNode: Node | null;
  currentNodePos: number;
}) => {
  const themeColors = useThemeData('colors');
  const { isCustomBorderColor } = useCurrentNode(editor, currentNode, themeColors);

  const { isSection, onSetBorderColor, onResetBorderColor } = useVisualSettingsPanel(
    editor,
    currentNode,
    currentNodePos,
    () => {}
  );

  const documentColors = editor.storage.documentColors?.colors as Array<EditorColor> | undefined;

  const borderColors = useMemo(() => {
    return getBorderColors(
      documentColors,
      themeColors.map((color) => color.value)
    );
  }, [documentColors, themeColors]);

  return (
    <PanelSection>
      <PanelHeadline>Color</PanelHeadline>
      <div className="flex items-center gap-[1px]">
        {themeColors.map((themeColor) => {
          return (
            <ThemeColorButton
              key={themeColor.key}
              onClick={onSetBorderColor}
              active={currentNode?.attrs.borderColor === themeColor.value}
              themeColor={themeColor}
            />
          );
        })}
      </div>
      <div className="flex items-center gap-[1px] pt-0.5 border-t border-white border-opacity-10 mt-0.5">
        <Button
          $variant="quaternary"
          $isColorTileButton
          $active={isCustomBorderColor}
          onClick={onShowCustomBorderColorPanel}
          style={styles.customColorCSSVar}
        />
        <Button
          $variant="quaternary"
          $size="small"
          $isIconButton
          $leftSlot={<Icon name="Cancel" />}
          disabled={!isSection || !currentNode?.attrs.borderColor}
          onClick={onResetBorderColor}
        />
        {borderColors.map((color) => (
          <ThemeColorButton
            key={color}
            onClick={onSetBorderColor}
            active={currentNode?.attrs.borderColor === color}
            themeColor={{ key: color, value: color, label: color }}
          />
        ))}
      </div>
    </PanelSection>
  );
};

export default BorderColor;
