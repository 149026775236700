import moment from "moment-mini";

import { Typography, TypographyStack } from "@/components/Typography";
import { Integration } from "@/interfaces/integration";

import { ReactComponent as AvatarPlaceholder } from './AvatarPlaceholder.svg';

interface MailchimpAccountProps {
  integration: Integration;
  onDisconnect: (integrationId: string) => void;
}

const MailchimpAccount: React.FunctionComponent<MailchimpAccountProps> = ({ integration, onDisconnect }) => {
  const { id, uid, provider, info, created_at: createdAt } = integration;
  const { image_url: imageUrl } = info;

  return (
    <li>
      <div className="flex space-x-4">
        <div className="flex-shrink-0">
          {imageUrl ? (
            <div className="w-12 rounded overflow-hidden">
              <div className="aspect-w-12 aspect-h-12">
                <img src={imageUrl} alt={provider} />
              </div>
            </div>
          ) : (
            <AvatarPlaceholder />
          )}
        </div>
        <TypographyStack gap="1">
          <Typography token="font-medium/text/sm">Account #{uid}</Typography>
          <Typography token="font-normal/text/sm" as="time" colorWeight="600">{moment(createdAt).format('LLL')}</Typography>
          <Typography token="font-normal/text/sm" color="danger">
            <button onClick={() => onDisconnect(id)} type="button" className="hover:opacity-80">
              Disconnect
            </button>
          </Typography>
        </TypographyStack>
      </div>
    </li>
  )
};

export default MailchimpAccount;
