import { useState } from 'react';

import { useGoToBilling } from '@/hooks';
import usePlan from '@/hooks/usePlan';
import { PlanType } from '@/interfaces/publication';

import TrialModal from './Trial/TrialModal';
import TrialPlan from './Trial/TrialPlan';
import GrowPlan from './GrowPlan';
import LaunchPlan from './LaunchPlan';
import ScalePlan from './ScalePlan';

interface Props {
  name?: PlanType;
}

const SelectedPlan = ({ name }: Props) => {
  switch (name) {
    case 'trial':
      return <TrialPlan />;
    case 'launch':
      return <LaunchPlan />;
    case 'grow':
      return <GrowPlan />;
    case 'scale':
      return <ScalePlan />;
    default:
      return null;
  }
};

const PlanCard = () => {
  const { plan, trial } = usePlan();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenShowing, setScreenShowing] = useState(1);

  const goToBilling = useGoToBilling();

  const isTrial = plan === 'trial';
  const isCustom = plan === 'custom';
  const isScale = plan === 'scale';

  const handleClick = () => {
    if (isTrial) {
      setIsModalOpen(true);
    } else {
      goToBilling();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (isCustom || isScale) {
    return null;
  }

  return (
    <>
      <button onClick={handleClick} type="button" className="w-full hover:shadow-lg transition-all rounded-lg relative">
        <SelectedPlan name={plan} />
      </button>
      {isTrial && trial && (
        <TrialModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          screenShowing={screenShowing}
          trial={trial}
          onBackward={() => setScreenShowing(1)}
          onForward={() => setScreenShowing(2)}
          onClick={() => {
            setIsModalOpen(false);

            goToBilling();
          }}
        />
      )}
    </>
  );
};

export default PlanCard;
