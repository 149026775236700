import { Extension } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    dreamSection: {
      setAllTextAlign: (pos: number, textAlign: string) => ReturnType;
    };
  }
}

export const DreamSection = Extension.create({
  name: 'dreamSection',

  addOptions() {
    return {
      textAlignPropertyName: 'textAlign',
    };
  },

  addCommands() {
    const { editor } = this;
    // get all nodes that have the textAlign attribute
    const textAlignableNodes = Object.entries(editor.extensionManager.schema.nodes).filter(
      ([, node]) => node.name !== 'section' && node.spec?.attrs?.[this.options.textAlignPropertyName]
    );

    return {
      setAllTextAlign:
        (pos: number, textAlign: string) =>
        ({ chain }) => {
          let currentChain = chain().command(({ tr }) => {
            // set the section node's attribute
            tr.setNodeAttribute(pos, this.options.textAlignPropertyName, textAlign);
            return true;
          });

          // for all set-able nodes, update the attribute
          textAlignableNodes.forEach(([node]) => {
            currentChain = currentChain.updateAttributes(node, { [this.options.textAlignPropertyName]: textAlign });
          });

          currentChain.run();

          return true;
        },
    };
  },
});
