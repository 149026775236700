interface FormSearchProps {
  search?: string;
  order?: string;
  dir?: string;
  filterByDateCreated?: string;
}

export default class FormSearch implements FormSearchProps {
  search: FormSearchProps['search'];

  order: FormSearchProps['order'];

  dir: FormSearchProps['dir'];

  filterByDateCreated: FormSearchProps['filterByDateCreated'];

  params(): object {
    return {
      ...(this.search && { q: this.search }),
      ...(this.order && this.dir && { sortOrder: this.order, sortDir: this.dir }),
      ...(this.filterByDateCreated && { filterByDateCreated: this.filterByDateCreated }),
    };
  }

  constructor(props: FormSearchProps) {
    this.search = props.search;
    this.order = props.order;
    this.dir = props.dir;
    this.filterByDateCreated = props.filterByDateCreated;
  }
}
