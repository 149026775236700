import React, { useEffect } from 'react';
import { Transition } from '@headlessui/react';

import { List } from '../List';

const LoadingListItem = ({ delay }: { delay: number }) => {
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    <Transition
      show={show}
      enter="transition duration-500 transform"
      enterFrom="opacity-0 translate-y-4"
      enterTo="opacity-100 translate-y-0"
      leave="transition duration-500 transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-4"
    >
      <List.Item>
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-4 flex space-x-2 items-center">
            <div className="space-y-2">
              <div className="text-sm font-medium truncate bg-gray-200 rounded animate-pulse text-transparent w-36 h-4" />
              <div className="text-sm text-gray-500 truncate bg-gray-100 rounded text-transparent w-12 h-4" />
            </div>
          </div>
          <div className="col-span-2">
            <div className="space-y-1 flex flex-col items-end">
              <div className="text-sm font-medium truncate bg-gray-200 rounded animate-pulse text-transparent w-36 h-4" />
              <div className="text-sm text-gray-500 truncate bg-gray-100 rounded text-transparent w-12 h-4" />
            </div>
          </div>
        </div>
      </List.Item>
    </Transition>
  );
};

export default LoadingListItem;
