import { useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { SearchList, SearchListItemProps } from '@/components/TiptapEditor/components/ui/SearchList';

export const CodeBlockLowLightView = ({
  node: {
    attrs: { language },
  },
  updateAttributes,
  extension,
}: NodeViewProps) => {
  const allowedLangs = useMemo<string[]>(
    () => extension.options.lowlight.listLanguages(),
    [extension.options.lowlight]
  );

  const [query, setQuery] = useState('');

  const [isSelectionMenuOpen, setIsSelectionMenuOpen] = useState(false);

  const filteredLangs = useMemo(() => {
    return allowedLangs.filter((lang) => lang.toLowerCase().includes(query.toLowerCase()));
  }, [allowedLangs, query]);

  return (
    <NodeViewWrapper className="code-block relative">
      <Tippy
        offset={[0, 8]}
        placement="bottom-start"
        interactive
        onShow={() => setIsSelectionMenuOpen(true)}
        onHidden={() => setIsSelectionMenuOpen(false)}
        onClickOutside={() => setIsSelectionMenuOpen(false)}
        render={(attrs) =>
          isSelectionMenuOpen && (
            <SearchList
              tabIndex={-1}
              {...attrs}
              items={filteredLangs.map((option: string) => {
                return {
                  value: option,
                  label: option,
                };
              })}
              hasMore={false}
              searchable
              searchPlaceholder="ex. html, css, js"
              $width="10rem"
              onSearch={setQuery}
              onSelect={(item: SearchListItemProps) => {
                updateAttributes({ language: item.value });
              }}
            />
          )
        }
        trigger="click"
      >
        <button
          type="button"
          className="absolute right-3 -top-[28px] !rounded-b-none flex items-center gap-2 bg-[#272A2F] text-gray-200 py-1 px-2 rounded-t-md"
          contentEditable={false}
        >
          <Icon name="Code" className="fill-current text-gray-200" /> {language}
        </button>
      </Tippy>
      <pre>
        <NodeViewContent as="code" />
      </pre>
    </NodeViewWrapper>
  );
};
