import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

interface Props {
  onSuccess?: (response: any) => void;
}

interface ApiResponse {
  connectedToStripeBoosts: boolean;
}

const useCreateStripeCustomer = ({ onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const createStripeCustomer = (): Promise<ApiResponse> =>
    api
      .post('/boosts/grow/stripe_customer', {
        publication_id: currentPublicationId,
      })
      .then((res) => res.data);

  return useMutation(createStripeCustomer, {
    onSuccess: (result) => {
      if (onSuccess) onSuccess(result);
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'stripe_customer'], { exact: true });
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
  });
};

export default useCreateStripeCustomer;
