import { FC } from 'react';
import cx from 'classnames';

import { Publication } from '../../../../../interfaces/publication';

import PublicationOption from './PublicationOption';

interface Props {
  isOpen: boolean;
  largeNavOpen: boolean;
  currentPublicationId: string;
  publications: Publication[];
  onSelectOption: (publication: Publication) => void;
}

const PublicationOptions: FC<Props> = (props) => {
  const { isOpen, currentPublicationId, publications, onSelectOption, largeNavOpen } = props;

  const className = isOpen
    ? 'min-w-[200px] absolute z-50 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm opacity-100 scale-100'
    : 'absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm opacity-0 scale-95 pointer-events-none';

  return (
    <ul
      aria-labelledby="location"
      aria-orientation="vertical"
      className={cx(className, largeNavOpen ? 'mt-1' : 'mt-2.5')}
      role="menu"
      tabIndex={-1}
    >
      {publications.map((publication) => (
        <PublicationOption
          key={publication.id}
          currentPublicationId={currentPublicationId}
          publication={publication}
          onSelectOption={onSelectOption}
        />
      ))}
    </ul>
  );
};

export default PublicationOptions;
