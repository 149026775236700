export enum DataTypes {
  STRING = 'string',
  DATETIME = 'datetime',
  INTEGER = 'integer',
  PERCENTAGE = 'percentage',
  EMAIL_ACTION = 'email_action',
  BEHAVIOR_ACTION = 'behavior_action',
  EXTERNAL_EMBED = 'external_embed',
  SUBSCRIBER_TAG = 'subscriber_tag',
  AUTOMATION_ACTION = 'automation_action',
  TIER = 'tier',
}

export enum NameTypes {
  ATTRIBUTE = 'attribute',
  GROUP = 'group',
  MEASURE = 'measure',
  EMAIL_ACTION = 'email_action',
  BEHAVIOR_ACTION = 'behavior_action',
  AUTOMATION_ACTION = 'automation_action',
}

export type SortableConditionBlockData = {
  isInGroup?: boolean;
  groupIndex?: number | undefined;
  index?: number;
  isSubGroup?: boolean;
  isRootGroup?: boolean;
};
