import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { attachInstruction, extractInstruction, Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';

import { useWebsiteContext } from '@/context/website-context';

import { cn } from '../../_utils/cn';

import { useContentTreeContext } from './context';
import { DROP } from './types';
import { movePath } from './util';

type Props = PropsWithChildren<{
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  path: string[];
  id: string;
}>;

export function ContentTreeItemDndWrapper({ setIsOpen, path, id, children }: Props) {
  const { pagesRoutes, updatePagesRoutes } = useWebsiteContext();
  const { isAllowDnD, dndDisabledErrorMessage } = useContentTreeContext();
  const [dropDirection, setdropDirection] = useState<DROP | null>(null);
  const [isOver, setIsOver] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const isHoveringOver = useRef<NodeJS.Timeout | undefined>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      console.error('element not found');
      return () => {};
    }
    const data = { id, path };

    return combine(
      draggable({
        element: ref.current,
        canDrag: () => {
          if (!isAllowDnD && dndDisabledErrorMessage) toast.error(dndDisabledErrorMessage);
          return isAllowDnD;
        },
        getInitialData: () => {
          return data;
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            render({ container }) {
              const clonedNode = element.cloneNode(true);
              container.appendChild(clonedNode);
            },
            nativeSetDragImage,
          });
        },
        onDragStart() {
          setIsDragging(true);
          // When dragging -> close this page if it has children
          setIsOpen(false);
        },
        onDrop() {
          setIsDragging(false);
        },
      }),
      dropTargetForElements({
        element: ref.current,
        getData({ input }) {
          return attachInstruction(data, {
            input,
            element,
            currentLevel: 2,
            indentPerLevel: 20,
            mode: 'standard',
          });
        },
        onDrag({ self, source }) {
          if (self.data.id === source.data.id) return;

          const instruction: Instruction | null = extractInstruction(self.data);

          switch (instruction?.type) {
            case 'make-child':
              setdropDirection(DROP.INSIDE);
              break;
            case 'reorder-below':
              setdropDirection(DROP.BOTTOM);
              break;
            case 'reorder-above':
              setdropDirection(DROP.TOP);
              break;
            default:
              break;
          }
        },
        onDragEnter: () => {
          // When long hovering over a page with sub-pages -> it should open the page
          isHoveringOver.current = setTimeout(() => {
            setIsOpen(true);
          }, 1000);
          setIsOver(true);
        },
        onDragLeave: () => {
          if (isHoveringOver.current) clearTimeout(isHoveringOver.current);
          setIsOver(false);
        },
        onDrop: async ({ self, source }) => {
          if (isHoveringOver.current) clearTimeout(isHoveringOver.current);
          if (!pagesRoutes) return;

          // handle dropping
          const instruction: Instruction | null = extractInstruction(self.data);

          let direction: DROP | null;

          switch (instruction?.type) {
            case 'make-child':
              direction = DROP.INSIDE;
              break;
            case 'reorder-below':
              direction = DROP.BOTTOM;
              break;
            case 'reorder-above':
              direction = DROP.TOP;
              break;
            default:
              direction = null;
              break;
          }

          if (!direction) return;
          const sourcePath = source.data.path;
          if (!(typeof sourcePath === 'object') || typeof sourcePath === 'undefined') {
            console.error('source data invalid');
            return;
          }

          const selfPath = self.data.path;
          if (!(typeof selfPath === 'object') || typeof self === 'undefined') {
            console.error('self path data invalid');
            return;
          }

          try {
            // Can only move pages that are non default pages (custom pages under routes)
            const newRoute = movePath(pagesRoutes, sourcePath as string[], selfPath as string[], direction);
            await updatePagesRoutes(newRoute);

            if (direction === DROP.INSIDE) {
              setIsOpen(true);
            }
          } catch (e) {
            toast.error('Failed to move page');
          }

          setIsOver(false);
        },
      })
    );
  }, [id, path, pagesRoutes, updatePagesRoutes, isAllowDnD, ref, isHoveringOver, setIsOpen]);

  // when not hovering over, clear direction
  useEffect(() => {
    if (!isOver) {
      setdropDirection(null);
    }
  }, [isOver]);

  return (
    <div
      ref={ref}
      className={cn(
        'relative rounded-lg outline outline-1 flex items-center',
        dropDirection === DROP.INSIDE ? 'outline-wb-accent' : 'outline-transparent'
      )}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {children}
      {isOver && (dropDirection === DROP.TOP || dropDirection === DROP.BOTTOM) && (
        <div
          className={cn(
            'absolute left-[30px] w-[calc(100%_-_30px)] h-[2px] bg-wb-accent',
            dropDirection === DROP.TOP ? 'top-0 -translate-y-[2px]' : '',
            dropDirection === DROP.BOTTOM ? 'top-full -translate-y-[2px]' : ''
          )}
        />
      )}
    </div>
  );
}
