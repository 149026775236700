export const THEME_ATTRS = [
  'header_font',
  'body_font',
  'button_font',

  'border_radius',

  'border_color',
  'background_color',
  'text_on_background_color',

  'primary_color',
  'text_on_primary_color',
] as const;
