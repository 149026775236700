/* eslint-disable */

import { useSettings } from '@/context/settings-context';
import { Posts as PostsV1 } from './Posts';
import { Posts as PostsV2 } from './v2/Posts';

const Posts: React.FunctionComponent = () => {
  const { settings } = useSettings();

  if (settings?.posts_index_v2) {
    return <PostsV2 />;
  }

  return <PostsV1 />;
};

export default Posts;
