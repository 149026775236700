import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import NewWebDomainsForm from './NewWebDomainsForm';

const NewPublicationDomain = () => {
  const publicationId = useCurrentPublicationId();
  return <NewWebDomainsForm key={publicationId} publicationId={publicationId} />;
};

export default NewPublicationDomain;
