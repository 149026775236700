import ReactMarkdown from 'react-markdown';
import { Link, useNavigate } from 'react-router-dom';
import {
  BanknotesIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckIcon,
  ClockIcon,
  DocumentChartBarIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { BellIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import * as Popover from '@radix-ui/react-popover';
import moment from 'moment-mini';

import UnreadNotifications from '@/components/Layout/AppLayout/TopBar/UnreadNotifications';
import LoadingBox from '@/components/LoadingBox';
import { useCurrentPublication } from '@/hooks';
import useNotifications from '@/hooks/useNotifications';
import useNotificationsMarkAllAsRead from '@/hooks/useNotificationsMarkAllAsRead';
import useNotificationsUnreadCount from '@/hooks/useNotificationsUnreadCount';
import useGenerateOrchidAdminToken, { Params } from '@/hooks/useUsers/useGenerateOrchidAdminToken';
import {
  isAdNetworkNewOpportunitiesNotification,
  isAdNetworkOpportunityAcceptedNotification,
  isAdNetworkOpportunityCanceledNotification,
  isAdNetworkOpportunityExpiredNotification,
  isAdNetworkOpportunityPendingNotification,
  isAdNetworkOpportunityRejectedNotification,
  isAdNetworkReportCreatedNotification,
  isPaymentRequiresActionNotification,
  Notification,
} from '@/interfaces/notification';
import { Button } from '@/ui/Button';

const NotificationIcon = ({ notification }: { notification: Notification }) => {
  if (isPaymentRequiresActionNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-primary-50 rounded-full relative">
        <ExclamationCircleIcon className="w-5 h-5 text-red-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkNewOpportunitiesNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-feedback-info-50 rounded-full relative">
        <BanknotesIcon className="w-5 h-5 text-feedback-info-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityPendingNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-primary-50 rounded-full relative">
        <BanknotesIcon className="w-5 h-5 text-primary-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityAcceptedNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-green-50 rounded-full relative">
        <CheckIcon className="w-5 h-5 text-green-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityRejectedNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-gray-50 rounded-full relative">
        <XMarkIcon className="w-5 h-5 text-gray-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityExpiredNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-gray-100 rounded-full relative">
        <ClockIcon className="w-5 h-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityCanceledNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-gray-100 rounded-full relative">
        <InformationCircleIcon className="w-5 h-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkReportCreatedNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-feedback-info-50 rounded-full relative">
        <DocumentChartBarIcon className="w-5 h-5 text-feedback-info-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  return (
    <div className="w-8 h-8 bg-gray-100 rounded-full relative">
      <ChatBubbleBottomCenterTextIcon className="w-5 h-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
    </div>
  );
};

const NotificationItem = ({ notification }: { notification: Notification }) => {
  const navigate = useNavigate();

  const { data: currentPublication } = useCurrentPublication();
  const { handleRedirectToOrchid } = useGenerateOrchidAdminToken();

  const handleClick = async () => {
    if (notification.url.includes('https') || notification.url.includes('http')) {
      const pubHostname = currentPublication?.hostname;
      if (pubHostname && notification.url.includes(pubHostname)) {
        try {
          const url = new URL(notification.url);
          const path = url.pathname;
          const params = url.searchParams;
          const paramsObject: Params = {};
          params.forEach((value: string, key: string) => {
            paramsObject[key] = value;
          });
          handleRedirectToOrchid(path, paramsObject);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      } else if (!notification.url.includes(window.location.hostname)) {
        window.open(notification.url, '_blank');
      } else {
        window.location.href = notification.url;
      }
    } else {
      navigate(notification.url);
    }
  };

  return (
    <li className="hover:bg-gray-50 relative">
      <button type="button" onClick={handleClick} className="flex flex-row space-x-3 py-3 px-3 w-full">
        <div className="w-8 h-8">
          <NotificationIcon notification={notification} />
        </div>
        <div className="flex flex-col space-y-2 w-full">
          <p className="text-left pt-[1px]">
            <ReactMarkdown className="text-gray-600 text-base">{notification.message}</ReactMarkdown>
          </p>
          <div className="flex flex-row space-x-2">
            {!notification.read_at && notification.created_at && (
              <div className="my-auto w-2 h-2 bg-[#be185d] rounded-full" />
            )}
            <p className="text-xs my-auto font-regular text-gray-600">{moment(notification.created_at).fromNow()}</p>
          </div>
        </div>
      </button>
    </li>
  );
};

const NotificationItems = () => {
  const { data: unreadCountData } = useNotificationsUnreadCount();
  const { mutateAsync: markAllAsRead } = useNotificationsMarkAllAsRead();
  const { data, isLoading, isError, hasNextPage, fetchNextPage } = useNotifications({
    onSuccess: () => {
      const unreadCount = unreadCountData?.count || 0;

      if (unreadCount > 0) {
        markAllAsRead();
      }
    },
  });

  const notifications = data?.pages?.flatMap((page) => page.notifications) || [];
  const noNotifications = notifications.length === 0 && !isLoading;

  return (
    <div className="max-h-144 overflow-auto w-full">
      <LoadingBox isLoading={isLoading} isError={isError} className="w-sm" width="384px">
        {noNotifications ? (
          <div className="mt-3 h-full flex flex-col justify-center items-center rounded-md text-center space-y-2.5 p-12">
            <BellIcon className="w-6 h-6 mx-auto text-gray-400" />
            <p className="text-center mt-1.5 text-sm text-gray-500">No activity yet, check back later.</p>

            <Button to="/current_user/notification_preferences" size="xs" variant="primary-inverse">
              Configure notifications
            </Button>
          </div>
        ) : (
          <>
            <ul className="flex flex-col w-full">
              {notifications.map((notification: any, index: number) => (
                <NotificationItem key={notification?.id || index} notification={notification} />
              ))}
            </ul>
            {hasNextPage && (
              <div className="flex text-center p-4">
                <Button
                  type="button"
                  onClick={() => fetchNextPage()}
                  className="w-full text-sm text-gray-500"
                  variant="primary-inverse"
                  block
                >
                  View More
                </Button>
              </div>
            )}
          </>
        )}
      </LoadingBox>
    </div>
  );
};

const NotificationsModal = () => {
  return (
    <div className="w-full h-full flex flex-col divide-y divide-gray-100">
      <div className="w-full flex flex-col px-4 py-4">
        <div className="w-full flex flex-row justify-between items-between">
          <p className="my-auto font-medium text-gray-900 text-base inline-flex items-center">Notifications</p>
          <div className="flex my-auto flex-row space-x-3">
            <div className="my-auto mt-0.5">
              <Link className="my-auto" to="/current_user/notification_preferences">
                <Cog6ToothIcon className="w-5 h-5 text-surface-400 my-auto hover:text-surface-600 duration-200" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <NotificationItems />
    </div>
  );
};

export default function NotificationsDropdown() {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div className="relative">
          <Button
            variant="flush"
            type="button"
            paddingStyle="icon"
            size="sm"
            Icon={BellIcon}
            tooltip="View notifications"
          />
          <UnreadNotifications />
        </div>
      </Popover.Trigger>
      <Popover.Content
        side="bottom"
        align="end"
        sideOffset={5}
        className="z-50 max-w-sm rounded-md shadow-lg ring-1 ring-black ring-opacity-5 bg-white origin-top-right data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
      >
        <NotificationsModal />
      </Popover.Content>
    </Popover.Root>
  );
}
