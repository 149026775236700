import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import analytics from '@/utils/analytics';

import Card from '../../../components/Card';
import { Input } from '../../../components/Form';
// Components
import { Button } from '../../../ui/Button';

interface Props {
  onSubmit: (display: string, description: string) => Promise<void>;
  header: string;
  content_tag?: {
    display?: string;
    description?: string;
  };
}

const ContentTagForm: FC<Props> = ({ onSubmit, header, content_tag: contentTag }) => {
  const [display, setDisplay] = useState(contentTag?.display || '');
  const [description, setDescription] = useState(contentTag?.description || '');
  const [saving, setSaving] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSaving(true);

    onSubmit(display, description).finally(() => {
      analytics.track('Created Content Tag');
      setSaving(false);
    });
  };

  const isDisabled = () => !display;

  return (
    <div>
      <div className="flex justify-between items-center pt-2 pb-6">
        <Link className="text-sm text-gray-500 hover:text-primary-500" to="/content_tags">
          <div className="flex items-center">
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            <span> Back to all Content Tags</span>
          </div>
        </Link>
      </div>
      <Card>
        <div className="flex flex-col gap-1 w-full mb-4">
          <div className="flex items-center">
            <h1 className="text-lg pb-0.5 leading-6 font-medium text-gray-900 mr-2 flex items-center">{header}</h1>
          </div>
        </div>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="lg:w-1/2">
            <div className="space-y-6">
              <Input
                name="display"
                labelText="Name"
                type="text"
                value={display}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDisplay(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="space-y-6">
              <Input
                name="description"
                labelText="Description"
                type="text"
                value={description}
                helperText="Recommended length: between 50 and 150 characters"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div className="pt-8">
            <Button type="submit" disabled={isDisabled()} loading={saving}>
              Save
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default ContentTagForm;
