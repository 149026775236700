import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { CancelInvite } from '@/interfaces/invites';
import api from '@/services/swarm';

const useCancelInvite = (organizationId: string, publicationId: string, inviteId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (invite: CancelInvite) =>
      api.patch(`/invites/${inviteId}`, {
        publication_id: publicationId,
        invite,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['invites', organizationId]);
        toast.success('Invite successfully cancelled!');
      },
    }
  );
};

export default useCancelInvite;
