import { useMemo, useState } from 'react';

import { TypeaheadMultiSelect } from '@/components/Form';
import SelectedOptionBadges from '@/components/Form/SelectedOptionBadges';
import { Option } from '@/interfaces/general';
import { normalizeString } from '@/utils';
import analytics from '@/utils/analytics';

type ContentTagsProps = {
  handleChange: (data: any) => void;
  selectedTags: string[];
  tags: {
    id: string;
    name: string;
  }[];
};

const ContentTags = ({ tags, selectedTags, handleChange }: ContentTagsProps) => {
  const [query, setQuery] = useState('');
  const [currentSelection, setCurrentSelection] = useState<string[]>(selectedTags || []);

  const handleSearch = (): Promise<Option[]> => {
    const options = tags
      .filter((tag) => normalizeString(tag.name).includes(query))
      .map((tag) => ({ value: tag.id, label: tag.name }));

    return new Promise((resolve) => {
      resolve(options);
    });
  };

  const handleOnClear = () => setQuery('');

  const handleSearchQueryChange = (newQuery: string) => {
    setQuery(newQuery.trim().toLowerCase());
  };

  const handleDeselectAll = () => {
    setCurrentSelection([]);
  };

  const handleSelect = (name: string, value: string) => {
    const newCurrentSelection = [...currentSelection, value];

    analytics.track('Added Content Tag to Post');

    setCurrentSelection(newCurrentSelection);
    handleChange({ content_tag_ids: newCurrentSelection });
  };

  const handleDeselect = (name: string, value: string) => {
    const newCurrentSelection = currentSelection.filter((item) => item !== value);

    setCurrentSelection(newCurrentSelection);
    handleChange({ content_tag_ids: newCurrentSelection });
  };

  const handleDeselectViaBadge = (value: string) => {
    handleDeselect('', value);
  };

  const tagNameByTagId = useMemo(() => {
    const mappedTags: {
      [key: string]: string;
    } = {};
    tags.forEach((tag) => {
      mappedTags[tag.id] = tag.name;
    });

    return mappedTags;
  }, [tags]);

  const selectedOptions: Option[] = useMemo(
    () =>
      currentSelection.map((tagId: string) => ({
        label: tagNameByTagId[tagId],
        value: tagId,
      })),
    [currentSelection, tagNameByTagId]
  );

  return (
    <div className="flex flex-col gap-y-2">
      <TypeaheadMultiSelect
        emptyLabel="No content tags found"
        labelText="Content Tags"
        name="new-post-content-tags-multiselect"
        onClear={handleOnClear}
        onDeselect={handleDeselect}
        onDeselectAll={handleDeselectAll}
        onSearch={handleSearch}
        onSearchQueryChange={handleSearchQueryChange}
        onSelect={handleSelect}
        placeholderText="Select Content Tag(s)"
        values={currentSelection}
        showClearAll={false}
        shouldCloseOnSelection={false}
      />
      <SelectedOptionBadges options={selectedOptions} onDeselect={handleDeselectViaBadge} />
    </div>
  );
};

export default ContentTags;
