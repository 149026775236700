import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { TCollabThread, TiptapCollabProvider } from '@hocuspocus/provider';
import { Editor, JSONContent } from '@tiptap/react';

import { EditorUser } from '@/components/TiptapEditor/lib/types';

export type EditorContextContent = {
  activeThreadId: string | null;
  collaborationEnabled: boolean;
  editor: Editor | null;
  editorIsLoading: boolean;
  isSaving: boolean;
  loadingNodes: Record<string, boolean>;
  openSidebar?: 'threads' | 'backlinks' | null;
  provider: TiptapCollabProvider | undefined;
  showSearchAndReplaceMenu: boolean;
  showSidebar: boolean;
  showThreadsSidebar: boolean;
  threads: TCollabThread[] | null;
  unresolvedThreadsCount: number;
  unsavedChanges: boolean;
  users: EditorUser[];
  wordCount: number;

  createThread: (content: JSONContent) => void;
  highlightThread: (threadId: string) => void;
  removeHighlightThread: (threadId: string) => void;
  selectThread: (threadId: string) => void;
  setActiveThreadId: (threadId: string | null) => void;
  setEditor: (editor: Editor) => void;
  setEditorIsLoading: (isLoading: boolean) => void;
  setIsSaving: (isSaving: boolean) => void;
  setLoadingNodes: Dispatch<SetStateAction<Record<string, boolean>>>;
  setOpenSidebar?: (openSidebar: 'threads' | 'backlinks' | null) => void;
  setProvider: (provider: TiptapCollabProvider | undefined) => void;
  setShowSearchAndReplaceMenu: (showSearchAndReplaceMenu: boolean) => void;
  setShowSidebar: (showSidebar: boolean) => void;
  setShowThreadsSidebar: (showThreads: boolean) => void;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  setUsers: (users: EditorUser[]) => void;
  setWordCount: (wordCount: number) => void;
  unselectThread: () => void;
};

export const EditorContext = createContext<EditorContextContent>({
  activeThreadId: null,
  collaborationEnabled: true,
  editor: null,
  editorIsLoading: false,
  isSaving: false,
  loadingNodes: {},
  openSidebar: null,
  provider: undefined,
  showSearchAndReplaceMenu: false,
  showSidebar: true,
  showThreadsSidebar: false,
  threads: [],
  unresolvedThreadsCount: 0,
  unsavedChanges: false,
  users: [],
  wordCount: 0,

  createThread: () => {},
  highlightThread: () => {},
  removeHighlightThread: () => {},
  selectThread: () => {},
  setActiveThreadId: () => {},
  setEditor: () => {},
  setEditorIsLoading: () => {},
  setIsSaving: () => {},
  setLoadingNodes: () => {},
  setOpenSidebar: () => {},
  setProvider: () => {},
  setShowSearchAndReplaceMenu: () => {},
  setShowSidebar: () => {},
  setShowThreadsSidebar: () => {},
  setUnsavedChanges: () => {},
  setUsers: () => {},
  setWordCount: () => {},
  unselectThread: () => {},
});

export const useEditorContext = () => useContext(EditorContext);
