/* eslint-disable react/jsx-props-no-spreading */

interface Props {
  title: string;
  description: string;
  url: string;
  imageUrl?: string;
  variant?: 'default' | 'facebook' | 'twitter';
}

const DefaultPreview = ({ title, description, url }: Omit<Props, 'variant'>) => {
  return (
    <div style={{ fontFamily: 'arial, sans-serif' }} className="flex flex-col">
      <h1 style={{ color: 'rgb(26, 13, 171)' }} className="leading-tight text-lg max-w-full truncate">
        {title}
      </h1>
      <p style={{ color: 'rgb(0, 102, 33)', fontSize: 14 }} className="truncate">
        {url}
      </p>
      <p style={{ color: 'rgb(84, 84, 84)', fontSize: 13 }} className="w-full whitespace-normal break-words">
        {description}
      </p>
    </div>
  );
};

const FacebookPreview = ({ title, description, url, imageUrl }: Omit<Props, 'variant'>) => {
  const urlHost = new URL(url).host;

  if (!imageUrl) return null;

  return (
    <div>
      <div className="aspect-w-16 aspect-h-9">
        <img src={imageUrl} alt="" className="object-cover" />
      </div>
      <div
        style={{
          fontFamily: 'Helvetica, arial, sans-serif',
          border: '1px solid #dadde1',
          borderTop: 'none',
          padding: '10px 12px',
          background: '#f2f3f5',
        }}
      >
        <p style={{ color: '#606770', fontSize: 12, height: 11, lineHeight: '11px' }} className="truncate uppercase">
          {urlHost}
        </p>
        <p style={{ color: '#1d2129' }} className="font-bold max-w-full truncate">
          {title}
        </p>
        <p style={{ color: '#606770', fontSize: 14 }} className="w-full truncate">
          {description}
        </p>
      </div>
    </div>
  );
};

const TwitterPreview = ({ title, description, url, imageUrl }: Omit<Props, 'variant'>) => {
  const urlHost = new URL(url).host;

  if (!imageUrl) return null;

  return (
    <div
      style={{
        borderRadius: '0.42857em',
        border: '1px solid #E1E8ED',
        overflow: 'hidden',
      }}
    >
      <div className="aspect-w-16 aspect-h-9">
        <img src={imageUrl} alt="" className="object-cover" />
      </div>
      <div
        className="space-y-0.5"
        style={{
          borderTop: '1px solid #dadde1',
          padding: '10px 12px',
          background: '#ffffff',
        }}
      >
        <p style={{ color: '#18283E', fontSize: 14 }} className="font-bold max-w-full truncate">
          {title}
        </p>
        <p style={{ color: '#18283E', fontSize: 14 }} className="w-full truncate">
          {description}
        </p>
        <p style={{ color: '#8899A6', fontSize: 12 }} className="truncate">
          {urlHost}
        </p>
      </div>
    </div>
  );
};

const SEOPreview = (props: Props) => {
  const { variant = 'default', ...rest } = props;

  switch (variant) {
    case 'facebook':
      return <FacebookPreview {...rest} />;
    case 'twitter':
      return <TwitterPreview {...rest} />;
    default:
      return <DefaultPreview {...rest} />;
  }
};

export default SEOPreview;
