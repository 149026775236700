export const imageStyles = [
  { name: 'photorealistic', label: 'Photorealistic', value: 'PHOTOREALISTIC' },
  { name: 'digital-art', label: 'Digital art', value: 'DIGITAL_ART' },
  { name: 'comic-book', label: 'Comic book', value: 'COMIC_BOOK' },
  { name: 'neon-punk', label: 'Neon punk', value: 'NEON_PUNK' },
  { name: 'isometric', label: 'Isometric', value: 'ISOMETRIC' },
  { name: 'line-art', label: 'Line art', value: 'LINE_ART' },
  { name: '3d-model', label: '3D model', value: '3D_MODEL' },
];

export default imageStyles;
