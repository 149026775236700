import { AutomationStepUpdateSubscriptionCustomField } from '@/interfaces/automations/automation_step';
import { CustomField } from '@/interfaces/custom_field';
import { PublicationSubscriberTagReference } from '@/interfaces/subscriber_tag';
import { SubscriptionStatuses } from '@/interfaces/subscription';

import EditAttributesForm from '../components/EditAttributesForm';

interface Props {
  customFields: CustomField[];
  currentSubscriptionStatusValue: typeof SubscriptionStatuses;
  onChangeCurrentSubscriptionStatusValue: (value: typeof SubscriptionStatuses) => void;
  currentCustomFieldsValue: AutomationStepUpdateSubscriptionCustomField[];
  onChangeCurrentCustomFieldsValue: (value: AutomationStepUpdateSubscriptionCustomField[]) => void;
  subscriberTagsValue: PublicationSubscriberTagReference[];
  onChangeSubscriberTags: (value: PublicationSubscriberTagReference[]) => void;
}

const ConfigureUpdateSubscription = ({
  customFields,
  currentSubscriptionStatusValue,
  onChangeCurrentSubscriptionStatusValue,
  currentCustomFieldsValue,
  onChangeCurrentCustomFieldsValue,
  subscriberTagsValue,
  onChangeSubscriberTags,
}: Props) => {
  return (
    <EditAttributesForm
      customFieldsValue={currentCustomFieldsValue}
      subscriptionStatusValue={currentSubscriptionStatusValue}
      customFields={customFields}
      onSubscriptionStatusValueChange={onChangeCurrentSubscriptionStatusValue}
      onCustomFieldsValueChange={onChangeCurrentCustomFieldsValue}
      subscriberTags={subscriberTagsValue}
      onSubscriberTagsChange={onChangeSubscriberTags}
    />
  );
};

export default ConfigureUpdateSubscription;
