import ColorPopover from '../../../../../../components/ColorPopover';
import { useControlledInput } from '../../../../../../hooks';

interface ColorInputProps {
  label: string;
  description: string;
  color: ReturnType<typeof useControlledInput>;
}

const ColorInput: React.FC<ColorInputProps> = ({ label, description, color }) => (
  <div className="pb-8 rounded">
    <div className="flex flex-col lg:flex-row">
      <div className="flex-grow space-y-6">
        <div className="col-span-12 sm:col-span-6">
          <div className="mb-1 font-normal text-left text-sm">{label}</div>
          <div className="mb-2 text-xs text-gray-500 text-left">{description}</div>
          <div className="w-20 h-6">
            {color.state && (
              <ColorPopover
                placement="bottom"
                color={color.state}
                onColorChange={(newColor: any) => color.setState(newColor?.hexString)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ColorInput;
