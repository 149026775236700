import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Order } from '@/interfaces/general';

interface SortableTableHeaderProps {
  orderBy: string;
  currentOrderBy: string;
  direction: Order;
  onClick: (orderBy: string) => void;
  children: React.ReactNode;
}

const SortableTableHeader = ({ orderBy, currentOrderBy, direction, onClick, children }: SortableTableHeaderProps) => {
  const active = currentOrderBy === orderBy;
  const iconColor = active ? 'text-primary-500' : 'text-gray-400';
  return (
    <th align="left" className="px-4 py-2 font-normal cursor-pointer" onClick={() => onClick(orderBy)}>
      <span className="inline-flex items-center">
        {children}

        {direction === Order.ASC && <ArrowUpIcon className={cx('w-4 h-4 ml-1', iconColor)} />}
        {direction === Order.DESC && <ArrowDownIcon className={cx('w-4 h-4 ml-1', iconColor)} />}
      </span>
    </th>
  );
};

export default SortableTableHeader;
