import { ReactNode, useRef } from 'react';

import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import { DreamEditor } from '@/routes/website/_components/DreamEditor';
import IframeWrapper from '@/routes/website/page/_components/Iframe';
import { useEditorFontHrefs } from '@/routes/website/page/_hooks/useEditorFontHrefs';

export const FooterEditor = ({ editorRect, children }: { editorRect: Partial<DOMRect>; children: ReactNode }) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const { settings } = useSettings();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const fontHrefs = useEditorFontHrefs();

  return (
    <>
      {children}
      <IframeWrapper
        width={editorRect.width || 0}
        height={editorRect.height || 0}
        contentWidth={1024}
        ref={iframeRef}
        fontHrefs={fontHrefs}
      >
        <div className="grow relative overflow-y-scroll min-h-[100px] h-fit">
          <PublicationProvider id={currentPublicationId}>
            {settings && (
              <DreamEditor
                publicationId={currentPublicationId}
                settings={settings}
                className="typedream content footer"
                isFooter
              />
            )}
          </PublicationProvider>
        </div>
      </IframeWrapper>
    </>
  );
};
