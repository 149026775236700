import cx from 'classnames';

import Card from '@/components/Card';

import { WizardSteps } from './types';

interface Props {
  step: number;
  onClick: (step: number) => void;
}

const StepsOverview = ({ step, onClick }: Props) => {
  return (
    <Card>
      <div className="space-y-4">
        <button
          type="button"
          onClick={() => onClick(WizardSteps.DETAILS)}
          className={cx(
            'flex flex-row w-full space-x-2 rounded-lg p-2',
            step === WizardSteps.DETAILS ? 'text-primary-500 bg-primary-50' : 'text-gray-400'
          )}
        >
          <div
            className={cx(
              'flex flex-row border-2 w-6 h-6 rounded-full justify-center items-center',
              step === WizardSteps.DETAILS ? 'border-primary-500' : 'border-gray-40'
            )}
          >
            1
          </div>
          <span>Details</span>
        </button>
        <button
          type="button"
          onClick={() => onClick(WizardSteps.EVENTS)}
          className={cx(
            'flex flex-row w-full space-x-2 rounded-lg p-2',
            step === WizardSteps.EVENTS ? 'text-primary-500 bg-primary-50' : 'text-gray-400'
          )}
        >
          <div
            className={cx(
              'flex flex-row border-2 w-6 h-6 rounded-full justify-center items-center',
              step === WizardSteps.EVENTS ? 'border-primary-500' : 'border-gray-40'
            )}
          >
            2
          </div>
          <span>Events</span>
        </button>
        <button
          type="button"
          onClick={() => onClick(WizardSteps.REVIEW)}
          className={cx(
            'flex flex-row w-full space-x-2 rounded-lg p-2',
            step === WizardSteps.REVIEW ? 'text-primary-500 bg-primary-50' : 'text-gray-400'
          )}
        >
          <div
            className={cx(
              'flex flex-row border-2 w-6 h-6 rounded-full justify-center items-center',
              step === WizardSteps.REVIEW ? 'border-primary-500' : 'border-gray-40'
            )}
          >
            3
          </div>
          <span>Review</span>
        </button>
      </div>
    </Card>
  );
};

export default StepsOverview;
