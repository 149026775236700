import { FC } from 'react';

import Text from '@/components/Text';
import VerifyIdentity from '@/components/VerifyIdentity';
import { useOrganization } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useStripeIdentityVerificationSession from '@/hooks/useUsers/useStripeIdentityVerificationSession';

interface Props {
  onSupport: () => void;
}

const IdentityVerification: FC<Props> = (props: Props) => {
  const { onSupport } = props;

  const publicationId = useCurrentPublicationId();
  const currentPublication = usePublication(publicationId)?.data;
  const organizationQuery = useOrganization(currentPublication?.organization_id, publicationId);
  const { organization } = organizationQuery?.data || {};
  const maxImportedSubscriptions = organization?.max_imported_subscriptions;
  const organizationId = currentPublication?.organization_id;
  const formatter = new Intl.NumberFormat('en-US');
  const importLimitAfterVerification = parseInt(process.env.REACT_APP_VETTED_MAX_IMPORTED_SUBSCRIPTIONS || '0', 10);

  const {
    mutation: { mutateAsync, isLoading },
    stripe,
    submitted,
    isSubmitting,
  } = useStripeIdentityVerificationSession({
    organizationId,
  });

  const handleClickVerify = async () => {
    await mutateAsync({});
  };

  const automaticLimitIncrease = importLimitAfterVerification > maxImportedSubscriptions;

  return (
    <div className="text-gray-800">
      <div className="space-y-6">
        {automaticLimitIncrease ? (
          <Text type="body" size="sm">
            Do you need to import more subscribers? Verify your identity and we&apos;ll instantly raise your import
            limit to <b>{formatter.format(importLimitAfterVerification)}</b>. We&apos;ve partnered with Stripe to make
            this as quick and easy as possible and it shouldn&apos;t take more than 5 minutes.
          </Text>
        ) : (
          <Text type="body" size="sm">
            Verify your identity to raise your import limit. We&apos;ve partnered with Stripe to make this as quick and
            easy as possible and it shouldn&apos;t take more than 5 minutes.
          </Text>
        )}

        <VerifyIdentity
          isDisabled={!stripe}
          isBusy={isLoading || isSubmitting}
          onClickVerify={handleClickVerify}
          hasSubmitted={submitted}
          verificationCompleteMessage="We're processing the results, expect an email with the outcome shortly. If your verification is successful we'll raise your import limit immediately."
        />

        {!submitted && (
          <Text type="body" size="xs">
            Alternatively,{' '}
            <button type="button" className="text-primary-500" onClick={onSupport}>
              complete this form
            </button>{' '}
            to verify your identity. With this method you have to wait <b>48 - 72 hours</b> for your verification to
            complete and before we can raise your import limit.
          </Text>
        )}
      </div>
    </div>
  );
};

export default IdentityVerification;
