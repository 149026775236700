import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

export interface Props {
  onSuccess?: () => void;
}

const useDeletePublication = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();

  return useMutation((publicationId: string) => api.delete(`/publications/${publicationId}`), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: (result: any) => {
      onSuccess?.();
      toast.success(result?.data?.message || 'Publication deleted');
      // The invalid query is ['organization', organizationId, 'publications']
      return queryClient.invalidateQueries(['organization']);
    },
  });
};

export default useDeletePublication;
