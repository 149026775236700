import Text from '@/components/Text';
import UrlCopy from '@/components/UrlCopy';

import { useSubscriberContext } from '../_context/subscriberContext';

const QuickLinks = () => {
  const { subscription } = useSubscriberContext();

  return (
    <div className="px-4">
      <div className="mt-6 space-y-2">
        <Text size="sm" type="bold" className="text-gray-500">
          Unique Referral Link
        </Text>
        <UrlCopy url={subscription.referral_url} />
      </div>
      <div className="mt-6 space-y-2">
        <Text size="sm" type="bold" className="text-gray-500">
          Stripe Link
        </Text>
        {subscription.stripe_customer_id ? (
          <UrlCopy url={`https://dashboard.stripe.com/customers/${subscription.stripe_customer_id}`} />
        ) : (
          <Text size="sm" type="body">
            &ndash;
          </Text>
        )}
      </div>
      <div className="mt-6 space-y-2">
        <Text size="sm" type="bold" className="text-gray-500">
          Subscriber Management Link
        </Text>
        <UrlCopy url={subscription.manage_url} />
      </div>
    </div>
  );
};

export default QuickLinks;
