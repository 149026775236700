import { OrderBy, SpendOrderBy } from '@/interfaces/boosts/order';

export const orderByOptions = [
  { label: 'Pending subscribers', value: OrderBy.PENDING_SUBSCRIBER_COUNT },
  { label: 'Verified subscribers', value: OrderBy.ACCEPTED_SUBSCRIBER_COUNT },
  { label: 'Total subscribers', value: OrderBy.TOTAL_SUBSCRIBER_COUNT },
];

export const spendOrderByOptions = [
  { label: 'Pending spend', value: SpendOrderBy.PENDING_SPEND },
  { label: 'Total spend', value: SpendOrderBy.TOTAL_SPEND },
];

export const SCORE_SIZE_LABELS = {
  1: 'New <5K',
  2: 'Small 5-25K',
  3: 'Medium 25-100K',
  4: 'Large 100-250K',
  5: 'Very Large >250K',
};

export const SCORE_GROWTH_LABELS = {
  1: 'Very Slow',
  2: 'Slow',
  3: 'Average',
  4: 'Fast',
  5: 'Very Fast',
};

export const SCORE_ENGAGEMENT_LABELS = {
  1: 'Poor <5%',
  2: 'Low 5-10%',
  3: 'Average 10-25%',
  4: 'Good 25-40%',
  5: 'Excellent >40%',
};

export const SCORE_QUALITY_LABELS = {
  1: 'Poor',
  2: 'Low',
  3: 'Average',
  4: 'Good',
  5: 'Excellent',
};
