import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Author } from '../interfaces/author';
import api from '../services/swarm';

interface ApiResponse {
  guest_authors: Author[];
}

const useGuestAuthors = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<ApiResponse>(['publications', currentPublicationId, 'guest_authors'], () =>
    api
      .get(`/guest_authors`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((response) => response.data)
  );
};

export default useGuestAuthors;
