import { Option } from '@/interfaces/general';

export enum CooldownType {
  NONE = 'none',
  SECONDS_SINCE_ENTERED = 'seconds_since_entered',
  SECONDS_SINCE_COMPLETED = 'seconds_since_completed',
}

export const cooldownTypeOptions: Option[] = [
  {
    label: 'No cooldown',
    value: CooldownType.NONE,
  },
  {
    label: 'Time since last entered',
    value: CooldownType.SECONDS_SINCE_ENTERED,
  },
  {
    label: 'Time since last completed',
    value: CooldownType.SECONDS_SINCE_COMPLETED,
  },
];
