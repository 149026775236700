import ActionModal from '@/components/ActionModal';
import { Typography, TypographyStack } from '@/components/Typography';
import {
  AutomationStep,
  AutomationStepActivateDescriptions,
  AutomationStepDeactivateDescriptions,
  AutomationStepDeletionDescriptions,
} from '@/interfaces/automations/automation_step';
import { AutomationTrigger, AutomationTriggerEvent } from '@/interfaces/automations/automation_trigger';
import { capitalize } from '@/utils';
import pluralize from '@/utils/pluralize';

import isDelayStep from '../utils/isDelayStep';

export type ConfirmResourceMutation = {
  active: boolean;
  loading?: boolean;
  automationStep?: AutomationStep;
  automationTrigger?: AutomationTrigger;
  mutationType?: 'activate' | 'deactivate' | 'delete' | 'update';
};

interface Props {
  mutation: ConfirmResourceMutation;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmResourceMutationModal = ({ mutation, onCancel, onConfirm }: Props) => {
  const { active, loading = false, automationStep, automationTrigger, mutationType } = mutation;
  const resource = automationTrigger || automationStep;
  const isTrigger = !!automationTrigger && !automationStep;
  const isStep = !isTrigger && !!automationStep;
  const affectsWaitingSubscribers = automationStep && isDelayStep(automationStep);
  const resourceName = isTrigger ? 'Automation Trigger' : 'Automation Step';

  return (
    <ActionModal
      isOpen={active}
      onClose={onCancel}
      resourceId={resource?.id || ''}
      headerText={(mutationType && `${capitalize(mutationType)} ${resourceName}`) || ''}
      onProceed={onConfirm || (() => {})}
      isWorking={loading}
    >
      {isTrigger && mutationType === 'delete' && (
        <TypographyStack gap="4" className="text-gray-800">
          <Typography>
            This will permanently remove this trigger from this automation flow and subscribers will stop entering this
            flow automatically through this trigger. Subscribers currently in the flow will continue to move through the
            flow.
          </Typography>
          <Typography>Are you sure you want to delete this trigger?</Typography>
        </TypographyStack>
      )}
      {isTrigger && mutationType === 'activate' && (
        <TypographyStack gap="4" className="text-gray-800">
          <Typography>
            Activating the trigger for this automation flow means that subscribers who meet the trigger conditions will
            start to move through the flow. Please ensure that your automation steps are correct and active.
          </Typography>
          {automationTrigger.event === AutomationTriggerEvent.UNENGAGED && (
            <Typography>
              <b>Double check</b> that your flow has the correct wait times and conditions if you&apos;re transitioning
              subscription statuses to Inactive.
            </Typography>
          )}
          <Typography>Are you sure you want to delete this trigger?</Typography>
        </TypographyStack>
      )}
      {isTrigger && mutationType === 'deactivate' && (
        <TypographyStack gap="4" className="text-gray-800">
          <Typography>
            Subscribers will stop entering this flow automatically through this trigger. Subscribers currently in the
            flow will continue to move through the flow. You can reactivate this trigger at any time.
          </Typography>
          <Typography>Are you sure you want to delete this trigger?</Typography>
        </TypographyStack>
      )}
      {isStep && mutationType === 'activate' && (
        <TypographyStack gap="4" className="text-gray-800">
          <Typography>
            The subscribers in this automation will start flowing through this step.{' '}
            {AutomationStepActivateDescriptions[automationStep.step_type]}
          </Typography>
          <Typography>Are you sure you want to activate this step?</Typography>
        </TypographyStack>
      )}
      {isStep && mutationType === 'deactivate' && (
        <TypographyStack gap="4" className="text-gray-800">
          <Typography>
            This will permanently deactivate this step but won&apos;t remove it from this automation flow.
            {automationStep.journey_step_counts.fresh_in_progress > 0 ? (
              <>
                {' '}
                The{' '}
                <b className="font-semibold">
                  {pluralize('subscriber', automationStep.journey_step_counts.fresh_in_progress)}
                </b>{' '}
                currently in-flight will be impacted. {AutomationStepDeactivateDescriptions[automationStep.step_type]}
              </>
            ) : (
              ' Any future subscribers entering this flow will bypass this step.'
            )}{' '}
            You can reactivate this step at any time.
          </Typography>
          <Typography>Are you sure you want to deactivate this step?</Typography>
        </TypographyStack>
      )}
      {isStep && mutationType === 'delete' && (
        <TypographyStack gap="4" className="text-gray-800">
          <Typography>
            This will permanently remove this step from this automation flow.
            {mutation.automationStep?.step_type === 'branch' &&
              ' All of the steps in the True and False branches will be permanently removed as well.'}{' '}
            {automationStep.journey_step_counts.fresh_in_progress > 0 && (
              <>
                {' '}
                The{' '}
                <b className="font-semibold">
                  {pluralize('subscriber', automationStep.journey_step_counts.fresh_in_progress)}
                </b>{' '}
                currently in-flight will be impacted. {AutomationStepDeletionDescriptions[automationStep.step_type]}
              </>
            )}
          </Typography>
          <Typography>Are you sure you want to delete this step?</Typography>
        </TypographyStack>
      )}
      {isStep && mutationType === 'update' && (
        <TypographyStack gap="4" className="text-gray-800">
          {affectsWaitingSubscribers && automationStep.journey_step_counts.fresh_in_progress > 0 && (
            <Typography>
              You&apos;re changing the timing for this step. The{' '}
              <b className="font-semibold">
                {pluralize('subscriber', automationStep.journey_step_counts.fresh_in_progress)}
              </b>{' '}
              currently in-flight and waiting on this step will be affected. This change might immediately move them to
              the next step, or it might increase their wait time.{' '}
            </Typography>
          )}
          <Typography>Are you sure you want to update this step?</Typography>
        </TypographyStack>
      )}
    </ActionModal>
  );
};

export default ConfirmResourceMutationModal;
