import BoostAgreementsList from '../Shared/BoostAgreementsList';

interface Props {
  recommendingPublicationId: string;
}

const WebTab = ({ recommendingPublicationId }: Props) => {
  return (
    <BoostAgreementsList
      recommendingPublicationId={recommendingPublicationId}
      showCPA
      showSendingMetrics
      showSpendMetrics={false}
      canReportPublication={false}
      showSearch={false}
    />
  );
};

export default WebTab;
