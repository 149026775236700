import { useEditorState } from '@tiptap/react';

import { Columns } from '../../../extensions';
import { AttributeSettingProps } from '../../types';
import { SliderSettings } from '../SliderSettings';

export const FlexBasisSettings = ({ activeNodeResult, editor }: AttributeSettingProps) => {
  const isChildOfColumns = useEditorState({
    editor,
    selector: ({ editor: e }) => {
      const { selection } = e.state;
      const parentNode = selection.$anchor.parent;

      return parentNode.type.name === Columns.name;
    },
  });

  if (!isChildOfColumns) {
    return null;
  }

  return <SliderSettings editor={editor} activeNodeResult={activeNodeResult} property="flexBasis" title="Width" />;
};
