import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useInvites = (organizationId: string | undefined, hasPermissions: boolean) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<any>(
    ['invites', organizationId],
    () =>
      api
        .get(`/invites`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: 10000,
      enabled: !!organizationId && hasPermissions,
    }
  );
};

export default useInvites;
