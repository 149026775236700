import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { BoostOffer } from '../../../interfaces/boosts/monetize/boost_offer';
import api from '../../../services/swarm';

const useBoostOffer = (boostOfferId: string) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchBoostOffer = (): Promise<BoostOffer> =>
    api
      .get(`/boosts/monetize/boost_offers/${boostOfferId}`, { params: { publication_id: currentPublicationId } })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'monetize', 'boost_offer', boostOfferId], fetchBoostOffer);
};

export default useBoostOffer;
