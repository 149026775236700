import React from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';
import moment from 'moment-mini';

import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyStack } from '@/components/Typography';
import UrlCopy from '@/components/UrlCopy';
import { useRssFeeds, useRssFeedsCreate, useRssFeedsDestroy } from '@/hooks/useRssFeeds';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';

interface Props {
  publicationId: string;
}

const Feeds: React.FC<Props> = ({ publicationId }) => {
  const { data: feeds, isLoading, isError, refetch } = useRssFeeds(publicationId);

  const onCreateMutation = useRssFeedsCreate({ publicationId, onSuccess: refetch });
  const { mutate: createFeed, isLoading: isCreating } = onCreateMutation;

  const onDestroyMutation = useRssFeedsDestroy({ publicationId, onSuccess: refetch });
  const { mutate: destroyFeed, isLoading: isDeleting } = onDestroyMutation;

  const feed = feeds?.[0];
  const hasFeeds = !!feed;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <BodyContainer>
        <Card>
          <CardHeader title="RSS Feed" />

          <TypographyStack gap="4" className="mt-4 mb-6">
            <Typography token="font-normal/text/sm" colorWeight="500">
              Generate an RSS Feed which allows you to sync posts made on beehiiv with a different website.
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="500">
              For example, if you are using WordPress or similar you can use a plugin to pull in posts to display them
              on your website.
            </Typography>
          </TypographyStack>

          {hasFeeds ? (
            <div className="space-y-4">
              <UrlCopy
                labelText="RSS Feed URL"
                url={feed.url}
                helperText={`Last updated ${moment(feed.updated_at).fromNow()}`}
              />

              <Button
                variant="danger"
                shade="dark"
                onClick={() => destroyFeed({ id: feed.id })}
                loading={isDeleting}
                disabled={isDeleting}
                disableWith="Deleting…"
              >
                Delete RSS Feed
              </Button>
            </div>
          ) : (
            <Button
              variant="primary"
              type="submit"
              loading={isCreating}
              Icon={PlusIcon}
              onClick={() => createFeed()}
              disabled={isCreating}
              disableWith="Creating…"
            >
              New RSS Feed
            </Button>
          )}
        </Card>
      </BodyContainer>
    </LoadingBox>
  );
};

export default Feeds;
