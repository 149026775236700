import { useInfiniteQuery } from 'react-query';

import { AdNetworkDisbursement } from '@/interfaces/ad_network/internal/disbursement';
import { Pagination } from '@/interfaces/general';
import { DisbursementStatusFilter } from '@/routes/ad_network/disbursements/_components/DisbursementSearch/types';
import api from '@/services/swarm';

interface ApiResponse {
  disbursements: AdNetworkDisbursement[];
  pagination: Pagination;
}

interface FetchDisbursementsParams {
  date?: string;
  payoutDate?: string;
  query?: string;
  status?: DisbursementStatusFilter;
}

export default function useDisbursements({ date, payoutDate, query, status = 'all' }: FetchDisbursementsParams) {
  const fetchDisbursements = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/internal/disbursements`, {
        params: {
          page: pageParam,
          per_page: 25,
          date,
          query,
          status,
          payout_date: payoutDate,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_network', 'internal', 'disbursements', query, status, date, payoutDate],
    fetchDisbursements,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
