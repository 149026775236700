import { useEffect, useRef, useState } from 'react';
import { UseMutationResult } from 'react-query';
import { useParams } from 'react-router-dom';

import { DatePicker, MultiSelect } from '@/components/Form';
import { useAdvertiser } from '@/hooks/useAdNetwork/internal';
import {
  AdNetworkAdvertiserPublisherListItemReasonOptions,
  AdNetworkAdvertiserPublisherListItemTypeOptions,
} from '@/interfaces/ad_network/internal/advertiser_publisher_list_item';
import { Publication } from '@/interfaces/publication';
import PublisherSelect from '@/routes/ad_network/_components/PublisherSelect';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

interface FormProps {
  publisherIdsWithRules: string[];
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  defaultPublisher?: Publication;
  defaultListType?: string;
  defaultReasons?: string[];
  defaultExpiresOn?: Date;
}

interface Props {
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  defaultPublisher?: Publication;
  defaultListType?: string;
  defaultReasons?: string[];
  defaultExpiresOn?: Date;
}

const Form = ({
  publisherIdsWithRules,
  onSubmitMutation,
  defaultPublisher,
  defaultListType,
  defaultReasons,
  defaultExpiresOn,
}: FormProps) => {
  const { mutateAsync, isLoading } = onSubmitMutation;
  const [publisherId, setPublisherId] = useState(defaultPublisher?.id || '');
  const [listType, setListType] = useState(defaultListType || '');
  const [reasons, setReasons] = useState(defaultReasons || []);
  const [expiresOn, setExpiresOn] = useState(defaultExpiresOn || undefined);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mutateAsync({ publisherId, listType, reasons, expiresOn });
  };

  const inputRef = useRef<HTMLInputElement>(null);

  if (publisherId) {
    const publisherIndex = publisherIdsWithRules.indexOf(publisherId);
    if (publisherIndex > -1) {
      publisherIdsWithRules.splice(publisherIndex, 1);
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="p-4">
      <div className="max-w-lg mx-auto w-full space-y-4">
        <form className="space-y-4" onSubmit={onSubmit}>
          <PublisherSelect
            labelText="Publisher"
            placeholderText="Select a publisher"
            helperText="Select the publisher to add to the list"
            onSelectPublication={(pubId: string) => {
              setPublisherId(pubId);
            }}
            onClearPublication={() => setPublisherId('')}
            value={publisherId}
            filterIds={publisherIdsWithRules}
          />
          <Dropdown
            required
            name="list_item[list_type]"
            labelText="Type"
            value={listType}
            onSelect={(_name, value) => {
              setListType(value as string);
              setReasons([]);
            }}
            options={AdNetworkAdvertiserPublisherListItemTypeOptions}
            helperText="Select the type of entry"
            topRightLinkText={listType && 'Clear'}
            onTopRightLinkClick={() => setListType('')}
          />
          {listType && (
            <MultiSelect
              label="Reasons"
              helperText="Select reasons for this entry "
              options={AdNetworkAdvertiserPublisherListItemReasonOptions.filter(
                (option) => option.associatedWith === listType
              ).map((option) => [option.value, option.label])}
              currentSelections={reasons}
              inputName="list_item[reasons]"
              onChange={(selected: any) => {
                const newSelections = selected.map((s: string) => String(s));
                setReasons(newSelections);
              }}
            />
          )}
          <DatePicker
            displayClearButton
            label="Expires on"
            enableTime={false}
            inputName="list_item[expires_on]"
            displayTimezone={false}
            value={expiresOn}
            helperText="Set a date for this list item to expire"
            onChange={(date) => {
              if (!date) {
                setExpiresOn(undefined);
                return;
              }

              setExpiresOn(date);
            }}
          />
          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default function Loader({
  onSubmitMutation,
  defaultPublisher,
  defaultListType,
  defaultReasons,
  defaultExpiresOn,
}: Props) {
  const { advertiser_id: id } = useParams<'advertiser_id'>() as { advertiser_id: string };
  const { data: advertiserData, isLoading, isError, isSuccess } = useAdvertiser({ id });

  if (!isSuccess || isLoading || isError) return null;

  const publisherIdsWithRules =
    advertiserData.publisher_list_items
      .filter((item) => item.removed_by_type === null)
      .map((item) => item.publisher_id) || [];

  return (
    <Form
      publisherIdsWithRules={publisherIdsWithRules}
      onSubmitMutation={onSubmitMutation}
      defaultPublisher={defaultPublisher}
      defaultListType={defaultListType}
      defaultReasons={defaultReasons}
      defaultExpiresOn={defaultExpiresOn}
    />
  );
}
