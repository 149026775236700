import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import SlideOver from '@/components/SlideOver';
import { Typography } from '@/components/Typography';
import useRewardPromoCodes from '@/hooks/useRewardPromoCodes';
import { Reward } from '@/interfaces/reward';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import BulkImportPromoCodes from './BulkImportPromoCodes';
import NewPromoCode from './NewPromoCode';
import Table from './Table';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  publicationId: string;
  rewardId?: string | null;
}

const PromoCodesSlideOver: FC<Props> = ({ rewardId, publicationId, isOpen, onClose }: Props) => {
  const [isLoadingReward, setIsLoadingReward] = useState(false);
  const [showAddPromoCode, setShowAddPromoCode] = useState(false);
  const [showBulkImportPromoCodes, setShowBulkImportPromoCodes] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletePromoCodeId, setDeletePromoCodeId] = useState('');
  const [reward, setReward] = useState<Reward>();
  const slideOverBodyId = 'manage-promo-codes';

  const {
    data: rewardPromoCodesData,
    refetch: refetchPromoCodes,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useRewardPromoCodes({ rewardId, isEnabled: isOpen, params: {} });
  const promoCodes = rewardPromoCodesData?.pages.flatMap((page) => page.promo_codes) || [];

  const fetchReward = useCallback(() => {
    const params = {
      publication_id: publicationId,
    };

    setIsLoadingReward(true);
    api
      .get(`/referral_program/rewards/${rewardId}`, { params })
      .then((res) => {
        setReward(res.data);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingReward(false);
      });
  }, [publicationId, rewardId]);

  useEffect(() => {
    if (isOpen && rewardId) {
      fetchReward();
    }
  }, [isOpen, rewardId, fetchReward]);

  const onResetAndClose = () => {
    onClose();
    setShowAddPromoCode(false);
    setShowBulkImportPromoCodes(false);
  };

  const onRefreshPromoCodes = () => {
    refetchPromoCodes();
  };

  const onAddPromoCode = () => {
    setShowAddPromoCode(true);
  };

  const onCancelAddPromoCode = () => {
    setShowAddPromoCode(false);
  };

  const onSuccessAddPromoCode = () => {
    setShowAddPromoCode(false);
    refetchPromoCodes();
  };

  const onBulkUploadPromoCodes = () => {
    setShowBulkImportPromoCodes(true);
  };

  const onCancelBulkImportPromoCodes = () => {
    setShowBulkImportPromoCodes(false);
  };

  const onSuccessBulkImportPromoCodes = () => {
    setShowBulkImportPromoCodes(false);
    onRefreshPromoCodes();
  };

  const onDeletePromoCodeRequested = (promoCodeId: string) => {
    setDeletePromoCodeId(promoCodeId);
  };

  const onDeletePromoCodeModalClose = () => {
    setDeletePromoCodeId('');
  };

  const onDeletePromoCode = (promoCodeId: string) => {
    const params = {
      publication_id: publicationId,
    };

    setIsDeleting(true);
    api
      .delete(`/referral_program/rewards/${rewardId}/promo_codes/${promoCodeId}`, { params })
      .then(() => {
        setDeletePromoCodeId('');
        refetchPromoCodes();
        toast.success('Promo Code deleted successfully');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const renderAddNewPromoCode = rewardId ? (
    <NewPromoCode
      rewardId={rewardId}
      publicationId={publicationId}
      onClose={onCancelAddPromoCode}
      onSuccess={onSuccessAddPromoCode}
    />
  ) : null;

  const renderBulkImportPromoCodes = rewardId ? (
    <BulkImportPromoCodes
      rewardId={rewardId}
      rewardName={reward?.name || ''}
      publicationId={publicationId}
      onClose={onCancelBulkImportPromoCodes}
      onSuccess={onSuccessBulkImportPromoCodes}
      slideOverBodyId={slideOverBodyId}
    />
  ) : null;

  const renderIndex = (
    <div>
      <ActionModal
        isOpen={deletePromoCodeId !== ''}
        onClose={onDeletePromoCodeModalClose}
        onProceed={onDeletePromoCode}
        resourceId={deletePromoCodeId}
        isWorking={isDeleting}
        headerText="Delete Promo Code"
        actionText="Delete"
      >
        <Typography token="font-normal/text/base">Are you sure you want to delete this promo code?</Typography>
      </ActionModal>
      <div>
        <Typography token="font-normal/text/sm" colorWeight="500">
          These promo codes are assigned when a milestone is achieved and can be used within your milestone email.
        </Typography>

        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="flex-1 flex justify-end">
            <Button
              variant="primary-inverse"
              block={false}
              onClick={() => onRefreshPromoCodes()}
              loading={isLoading || isLoadingReward}
            >
              <ArrowPathIcon className="w-4" />
            </Button>
            <Button variant="primary-inverse" block={false} className="ml-1" onClick={() => onBulkUploadPromoCodes()}>
              Bulk Upload
            </Button>
            <Button className="ml-1" block={false} onClick={() => onAddPromoCode()}>
              Add Code
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <Table
            promoCodes={promoCodes}
            hasNextPage={hasNextPage || false}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            onDeleteSelected={onDeletePromoCodeRequested}
            slideOverBodyId={slideOverBodyId}
          />
        </div>
      </div>
    </div>
  );

  const renderBody = () => {
    if (showAddPromoCode) {
      return renderAddNewPromoCode;
    }

    if (showBulkImportPromoCodes) {
      return renderBulkImportPromoCodes;
    }

    return renderIndex;
  };

  return (
    <SlideOver headerText="Manage Promo Codes" isOpen={isOpen} onClose={onResetAndClose} bodyId={slideOverBodyId}>
      {renderBody()}
    </SlideOver>
  );
};

export default PromoCodesSlideOver;
