import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ContentImport } from '@/interfaces/content_import';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

interface ErrorResponse {
  response: {
    data: {
      error: string
    };
  };
}

export default function useContentImportsCreate({ publicationId }: { publicationId: string }) {
  const navigate = useNavigate();

  return useMutation<ContentImport, ErrorResponse, FormData>(
    (formData) => api.post('/content_imports', formData).then((res) => res.data),
    {
      onSuccess: () => {
        analytics.track('Imported Content');
        toast.success('Content imported and will be processed shortly');

        navigate(appendSettingsPublicationId('/settings/publication/content_import', publicationId));
      },
      onError: (error) => {
        const { error: errorMessage } = error.response.data;

        toast.error(errorMessage);
      },
    }
  );
}
