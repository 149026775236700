export const DropdownFullPreview = () => {
  return <svg width="120" height="53" viewBox="0 0 120 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 4C2 1.79086 3.79086 0 6 0H114C116.209 0 118 1.79086 118 4V13H2V4Z" fill="#F2F4F7" />
    <path d="M7.88281 6.5C7.88281 5.11929 9.0021 4 10.3828 4H17.3828C18.7635 4 19.8828 5.11929 19.8828 6.5C19.8828 7.88071 18.7635 9 17.3828 9H10.3828C9.0021 9 7.88281 7.88071 7.88281 6.5Z" fill="#5E5DEF" />
    <path d="M49.7051 6.5C49.7051 5.11929 50.8244 4 52.2051 4H69.2051C70.5858 4 71.7051 5.11929 71.7051 6.5C71.7051 7.88071 70.5858 9 69.2051 9H52.2051C50.8244 9 49.7051 7.88071 49.7051 6.5Z" fill="#D0D5DD" />
    <path d="M77.933 6.04162L76.3705 7.60412C76.3487 7.62597 76.3229 7.6433 76.2944 7.65513C76.2659 7.66696 76.2354 7.67305 76.2045 7.67305C76.1737 7.67305 76.1431 7.66696 76.1146 7.65513C76.0861 7.6433 76.0603 7.62597 76.0385 7.60412L74.476 6.04162C74.432 5.99759 74.4072 5.93787 74.4072 5.8756C74.4072 5.81333 74.432 5.75362 74.476 5.70959C74.52 5.66556 74.5797 5.64082 74.642 5.64082C74.7043 5.64082 74.764 5.66556 74.808 5.70959L76.2047 7.10627L77.6014 5.70939C77.6454 5.66536 77.7051 5.64062 77.7674 5.64062C77.8297 5.64062 77.8894 5.66536 77.9334 5.70939C77.9774 5.75342 78.0022 5.81314 78.0022 5.87541C78.0022 5.93767 77.9774 5.99739 77.9334 6.04142L77.933 6.04162Z" fill="#98A2B3" />
    <path d="M83.4121 6.5C83.4121 5.11929 84.5314 4 85.9121 4H92.9121C94.2928 4 95.4121 5.11929 95.4121 6.5C95.4121 7.88071 94.2928 9 92.9121 9H85.9121C84.5314 9 83.4121 7.88071 83.4121 6.5Z" fill="#D0D5DD" />
    <path d="M100.117 6.5C100.117 5.11929 101.236 4 102.617 4H109.617C110.998 4 112.117 5.11929 112.117 6.5C112.117 7.88071 110.998 9 109.617 9H102.617C101.236 9 100.117 7.88071 100.117 6.5Z" fill="#D0D5DD" />
    <g filter="url(#filter0_dd_2028_44168)">
      <rect x="2" y="13" width="116" height="35.8614" fill="white" shapeRendering="crispEdges" />
      <rect x="2.11881" y="13.1188" width="115.762" height="35.6238" stroke="#E4E7EC" strokeWidth="0.237624" shapeRendering="crispEdges" />
      <rect x="18" y="20" width="9.50495" height="9.50495" rx="1.90099" fill="#F2F4F7" />
      <path d="M24.4156 24.3661C24.4156 25.4057 22.8742 26.2472 22.8085 26.2819C22.7912 26.2913 22.7719 26.2961 22.7522 26.2961C22.7326 26.2961 22.7132 26.2913 22.6959 26.2819C22.6303 26.2472 21.0889 25.4057 21.0889 24.3661C21.0891 24.122 21.1862 23.8879 21.3589 23.7153C21.5315 23.5427 21.7655 23.4456 22.0097 23.4453C22.3163 23.4453 22.5849 23.5772 22.7522 23.8001C22.9196 23.5772 23.1881 23.4453 23.4948 23.4453C23.7389 23.4456 23.973 23.5427 24.1456 23.7153C24.3182 23.8879 24.4153 24.122 24.4156 24.3661Z" fill="#98A2B3" />
      <path d="M29.8809 22.752C29.8809 22.1997 30.3286 21.752 30.8809 21.752H48.9997C49.552 21.752 49.9997 22.1997 49.9997 22.752C49.9997 23.3042 49.552 23.752 48.9997 23.752H30.8809C30.3286 23.752 29.8809 23.3042 29.8809 22.752Z" fill="#E4E7EC" />
      <path d="M29.8809 26.752C29.8809 26.1997 30.3286 25.752 30.8809 25.752H40.8809C41.4331 25.752 41.8809 26.1997 41.8809 26.752C41.8809 27.3042 41.4331 27.752 40.8809 27.752H30.8809C30.3286 27.752 29.8809 27.3042 29.8809 26.752Z" fill="#F2F4F7" />
      <rect x="18" y="32.3555" width="9.50495" height="9.50495" rx="1.90099" fill="#F2F4F7" />
      <path d="M24.4156 36.7216C24.4156 37.7612 22.8742 38.6027 22.8085 38.6374C22.7912 38.6467 22.7719 38.6516 22.7522 38.6516C22.7326 38.6516 22.7132 38.6467 22.6959 38.6374C22.6303 38.6027 21.0889 37.7612 21.0889 36.7216C21.0891 36.4774 21.1862 36.2434 21.3589 36.0708C21.5315 35.8982 21.7655 35.8011 22.0097 35.8008C22.3163 35.8008 22.5849 35.9327 22.7522 36.1556C22.9196 35.9327 23.1881 35.8008 23.4948 35.8008C23.7389 35.8011 23.973 35.8982 24.1456 36.0708C24.3182 36.2434 24.4153 36.4774 24.4156 36.7216Z" fill="#98A2B3" />
      <path d="M29.8809 35.1074C29.8809 34.5551 30.3286 34.1074 30.8809 34.1074H48.9997C49.552 34.1074 49.9997 34.5551 49.9997 35.1074C49.9997 35.6597 49.552 36.1074 48.9997 36.1074H30.8809C30.3286 36.1074 29.8809 35.6597 29.8809 35.1074Z" fill="#E4E7EC" />
      <path d="M29.8809 39.1074C29.8809 38.5551 30.3286 38.1074 30.8809 38.1074H40.8809C41.4331 38.1074 41.8809 38.5551 41.8809 39.1074C41.8809 39.6597 41.4331 40.1074 40.8809 40.1074H30.8809C30.3286 40.1074 29.8809 39.6597 29.8809 39.1074Z" fill="#F2F4F7" />
      <rect x="70" y="20" width="9.50495" height="9.50495" rx="1.90099" fill="#F2F4F7" />
      <path d="M76.4156 24.3661C76.4156 25.4057 74.8742 26.2472 74.8085 26.2819C74.7912 26.2913 74.7719 26.2961 74.7522 26.2961C74.7326 26.2961 74.7132 26.2913 74.6959 26.2819C74.6303 26.2472 73.0889 25.4057 73.0889 24.3661C73.0891 24.122 73.1862 23.8879 73.3589 23.7153C73.5315 23.5427 73.7655 23.4456 74.0097 23.4453C74.3163 23.4453 74.5849 23.5772 74.7522 23.8001C74.9196 23.5772 75.1881 23.4453 75.4948 23.4453C75.7389 23.4456 75.973 23.5427 76.1456 23.7153C76.3182 23.8879 76.4153 24.122 76.4156 24.3661Z" fill="#98A2B3" />
      <path d="M81.8809 22.752C81.8809 22.1997 82.3286 21.752 82.8809 21.752H101C101.552 21.752 102 22.1997 102 22.752C102 23.3042 101.552 23.752 101 23.752H82.8809C82.3286 23.752 81.8809 23.3042 81.8809 22.752Z" fill="#E4E7EC" />
      <path d="M81.8809 26.752C81.8809 26.1997 82.3286 25.752 82.8809 25.752H92.8809C93.4331 25.752 93.8809 26.1997 93.8809 26.752C93.8809 27.3042 93.4331 27.752 92.8809 27.752H82.8809C82.3286 27.752 81.8809 27.3042 81.8809 26.752Z" fill="#F2F4F7" />
      <rect x="70" y="32.3555" width="9.50495" height="9.50495" rx="1.90099" fill="#F2F4F7" />
      <path d="M76.4156 36.7216C76.4156 37.7612 74.8742 38.6027 74.8085 38.6374C74.7912 38.6467 74.7719 38.6516 74.7522 38.6516C74.7326 38.6516 74.7132 38.6467 74.6959 38.6374C74.6303 38.6027 73.0889 37.7612 73.0889 36.7216C73.0891 36.4774 73.1862 36.2434 73.3589 36.0708C73.5315 35.8982 73.7655 35.8011 74.0097 35.8008C74.3163 35.8008 74.5849 35.9327 74.7522 36.1556C74.9196 35.9327 75.1881 35.8008 75.4948 35.8008C75.7389 35.8011 75.973 35.8982 76.1456 36.0708C76.3182 36.2434 76.4153 36.4774 76.4156 36.7216Z" fill="#98A2B3" />
      <path d="M81.8809 35.1074C81.8809 34.5551 82.3286 34.1074 82.8809 34.1074H101C101.552 34.1074 102 34.5551 102 35.1074C102 35.6597 101.552 36.1074 101 36.1074H82.8809C82.3286 36.1074 81.8809 35.6597 81.8809 35.1074Z" fill="#E4E7EC" />
      <path d="M81.8809 39.1074C81.8809 38.5551 82.3286 38.1074 82.8809 38.1074H92.8809C93.4331 38.1074 93.8809 38.5551 93.8809 39.1074C93.8809 39.6597 93.4331 40.1074 92.8809 40.1074H82.8809C82.3286 40.1074 81.8809 39.6597 81.8809 39.1074Z" fill="#F2F4F7" />
    </g>
    <defs>
      <filter id="filter0_dd_2028_44168" x="-0.376237" y="11.5743" width="120.752" height="40.6138" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="0.475248" operator="erode" in="SourceAlpha" result="effect1_dropShadow_2028_44168" />
        <feOffset dy="0.950495" />
        <feGaussianBlur stdDeviation="1.42574" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2028_44168" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="0.475248" operator="erode" in="SourceAlpha" result="effect2_dropShadow_2028_44168" />
        <feOffset dy="0.950495" />
        <feGaussianBlur stdDeviation="1.42574" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_2028_44168" result="effect2_dropShadow_2028_44168" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2028_44168" result="shape" />
      </filter>
    </defs>
  </svg>

}
