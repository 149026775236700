/* eslint-disable react/no-array-index-key */
import { createRef, useCallback, useEffect, useState } from 'react';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

const MIN_CHOICE_COUNT = 2;
const MAX_CHOICE_COUNT = 10;
const TEXT_MAX_LENGTH = 255;

interface Props {
  question: string;
  setQuestion: (question: string) => void;

  description: string;
  setDescription: (description: string) => void;

  choices: string[];
  setChoices: (choices: string[]) => void;

  labelText: string;
}

const PollOptionsForm: React.FC<Props> = ({
  question,
  setQuestion,
  description,
  setDescription,
  choices,
  setChoices,
  labelText,
}) => {
  const [shouldFocusLast, setShouldFocusLast] = useState(false);

  const questionRef = createRef<HTMLInputElement>();
  const descriptionRef = createRef<HTMLInputElement>();
  const inputRefs = choices.map(() => createRef<HTMLInputElement>());

  const focusDescription = () => {
    descriptionRef.current?.focus();
  };

  const focusQuestion = () => {
    questionRef.current?.focus();
  };

  const focusFirstChoice = () => {
    inputRefs[0].current?.focus();
  };

  const focusNextChoice = (index: number) => {
    inputRefs[index + 1]?.current?.focus();
  };

  const focusPrevChoice = (index: number) => {
    inputRefs[index - 1]?.current?.focus();
  };

  const focusLastChoice = useCallback(() => {
    inputRefs[inputRefs.length - 1].current?.focus();
    setShouldFocusLast(false);
  }, [inputRefs]);

  useEffect(() => {
    if (shouldFocusLast) {
      focusLastChoice();
      setShouldFocusLast(false);
    }
  }, [shouldFocusLast, inputRefs, focusLastChoice]);

  const handleChoiceChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setChoices(choices.map((c, i) => (i === index ? e.target.value : c)));
  };

  const showHelper = choices.filter((c) => c).length >= MIN_CHOICE_COUNT && inputRefs.length < MAX_CHOICE_COUNT;

  const duplicates = choices
    .map((c) => c.toLowerCase())
    .filter((c) => c)
    .filter((c, i, a) => a.indexOf(c) !== i);
  const hasDuplicates = duplicates.length > 0;

  return (
    <>
      {labelText && <div className="block text-sm font-medium text-gray-700">{labelText}</div>}
      <div className="border rounded p-6 relative bg-white overflow-y-auto">
        <input
          type="text"
          ref={questionRef}
          className="w-full appearance-none border-0 focus:ring-0 focus:outline-none p-0 font-bold placeholder-gray-400"
          value={question}
          placeholder="Type a question..."
          required
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault();

              focusDescription();
            }

            if (e.key === 'ArrowDown') {
              focusDescription();
            }
          }}
          maxLength={TEXT_MAX_LENGTH}
        />
        <input
          type="text"
          ref={descriptionRef}
          className="w-full appearance-none border-0 focus:ring-0 focus:outline-none p-0 placeholder-gray-400"
          value={description}
          placeholder="Type a description (optional)..."
          onChange={(e) => setDescription(e.target.value)}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault();

              focusFirstChoice();
            }

            if (e.key === 'ArrowDown') {
              focusFirstChoice();
            }

            if (e.key === 'ArrowUp') {
              focusQuestion();
            }
          }}
          maxLength={TEXT_MAX_LENGTH}
        />

        <ul className="list-disc list-outside mt-4 ml-4">
          {choices.map((choice, index) => (
            <li key={index} className="relative">
              <input
                type="text"
                ref={inputRefs[index]}
                name={`choice-${index}`}
                className={cx(
                  'w-full appearance-none border-0 focus:ring-0 focus:outline-none p-0 placeholder-gray-400',
                  hasDuplicates && duplicates.includes(choice.toLowerCase()) && 'text-red-500'
                )}
                value={choice}
                placeholder="Choice..."
                onChange={(e) => handleChoiceChange(index)(e)}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                maxLength={TEXT_MAX_LENGTH}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    e.preventDefault();

                    if (choices[index + 1] !== '' && index + 1 < MAX_CHOICE_COUNT) {
                      setChoices([...choices.slice(0, index + 1), '', ...choices.slice(index + 1)]);
                    }

                    if (inputRefs[index + 1]) {
                      inputRefs[index + 1]?.current?.focus();
                    } else {
                      setShouldFocusLast(true);
                    }
                  }

                  if (e.key === 'Backspace' && choice === '' && index > 0) {
                    e.preventDefault();

                    if (choices.length > MIN_CHOICE_COUNT) {
                      setChoices(choices.filter((c, i) => i !== index));
                    }

                    focusPrevChoice(index);
                  }

                  if (e.key === 'ArrowDown') {
                    focusNextChoice(index);
                  }

                  if (e.key === 'ArrowUp') {
                    if (index === 0) {
                      focusDescription();
                    } else {
                      focusPrevChoice(index);
                    }
                  }
                }}
              />
            </li>
          ))}
        </ul>
        <p className="absolute top-2 right-3 text-xs text-gray-400">Editing</p>
        {hasDuplicates && <p className="text-xs text-red-400">Choices must be unique.</p>}
        <p
          className={cx(
            'text-gray-300 text-xs mt-2 -mb-2 text-right duration-700',
            showHelper ? 'opacity-100' : 'opacity-0'
          )}
        >
          Press{' '}
          <span>
            <ArrowUturnLeftIcon
              style={{
                transform: 'scale(1, -1)',
              }}
              className="inline w-4 h-4"
            />
          </span>{' '}
          to add another choice.
        </p>
      </div>
    </>
  );
};

export default PollOptionsForm;
