import moment from 'moment-mini';

import { Typography } from '@/components/Typography';
import { PostScheduledEvent } from '@/hooks/useCalendar/types';
import { Badge, BadgeSize, BadgeType } from '@/ui/Badge';
import { Button } from '@/ui/Button';

import Indicator from './Indicator';

interface Props {
  event: PostScheduledEvent;
}

const PostScheduledEventDetails = ({ event }: Props) => {
  const {
    post_id: postId,
    title,
    subtitle,
    scheduled_at: scheduledAt,
    subject,
    preview_text: previewText,
    content_tags: contentTags,
    platform,
    author_profile_picture_urls: authorProfilePictureUrls,
  } = event.metadata;

  return (
    <div className="max-w-lg">
      <div className="bg-white rounded shadow-xl border border-gray-200">
        <div className="p-6 space-y-6">
          <div className="flex items-start justify-between space-x-6">
            <div className="space-x-2 flex items-start">
              <Indicator calendar={event.calendar} />
              <div>
                <Typography as="p" token="font-medium/text/base" className="line-clamp-2">
                  {title}
                </Typography>
                {subtitle && (
                  <Typography as="p" token="font-normal/text/sm" colorWeight="700" className="line-clamp-1">
                    {subtitle}
                  </Typography>
                )}
              </div>
            </div>
            <Badge type={BadgeType.WARNING} text="Scheduled" size={BadgeSize.SM} />
          </div>
          <div className="flex">
            <dl className="grid grid-cols-2 gap-6">
              <div className="space-y-1">
                <dt>
                  <Typography as="p" token="font-normal/text/xs" colorWeight="500">
                    Scheduled for
                  </Typography>
                </dt>
                <dd>
                  <Typography as="p" token="font-normal/text/sm" colorWeight="900">
                    {moment(scheduledAt).format('MMM Do, YYYY h:mm A')}
                  </Typography>
                </dd>
              </div>
              <div className="space-y-1">
                <dt>
                  <Typography as="p" token="font-normal/text/xs" colorWeight="500">
                    Platform
                  </Typography>
                </dt>
                <dd>
                  <Typography as="p" token="font-normal/text/sm" colorWeight="900">
                    {platform}
                  </Typography>
                </dd>
              </div>
              <div className="space-y-1">
                <dt>
                  <Typography as="p" token="font-normal/text/xs" colorWeight="500">
                    Authors
                  </Typography>
                </dt>
                <dd>
                  <div className="flex -space-x-1 overflow-hidden">
                    {authorProfilePictureUrls.map((url) => (
                      <img
                        key={url}
                        src={url}
                        alt="Author"
                        className="inline-block size-6 rounded-full ring-2 ring-white"
                      />
                    ))}
                  </div>
                </dd>
              </div>
              {contentTags && (
                <div className="space-y-1">
                  <dt>
                    <Typography as="p" token="font-normal/text/xs" colorWeight="500">
                      Content tags
                    </Typography>
                  </dt>
                  <dd>
                    <Typography as="p" token="font-normal/text/sm" colorWeight="900">
                      {contentTags}
                    </Typography>
                  </dd>
                </div>
              )}
              {subject && (
                <div className="space-y-1">
                  <dt>
                    <Typography as="p" token="font-normal/text/xs" colorWeight="500">
                      Subject
                    </Typography>
                  </dt>
                  <dd>
                    <Typography as="p" token="font-normal/text/sm" colorWeight="900">
                      {subject}
                    </Typography>
                  </dd>
                </div>
              )}
              {previewText && (
                <div className="space-y-1">
                  <dt>
                    <Typography as="p" token="font-normal/text/xs" colorWeight="500">
                      Preview text
                    </Typography>
                  </dt>
                  <dd>
                    <Typography as="p" token="font-normal/text/sm" colorWeight="900">
                      {previewText}
                    </Typography>
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
        <div className="bg-gray-100 px-6 py-3 space-x-3 flex items-center justify-end">
          <Button type="button" variant="primary-inverse" size="sm" to={`/posts/${postId}`}>
            View details
          </Button>
          <Button type="button" variant="primary-inverse" size="sm" to={`/posts/${postId}/edit`}>
            Edit post
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PostScheduledEventDetails;
