import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PostDeliveryData } from '@/interfaces/post_delivery_data';
import api from '@/services/swarm';

const usePostDeliveryData = (postId?: string) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostDeliveryData = () => {
    return api
      .get(`/posts/${postId}/delivery`, { params: { publication_id: currentPublicationId } })
      .then((res) => res.data);
  };

  return useQuery<PostDeliveryData>(['post', postId, 'deliveryData'], fetchPostDeliveryData, { enabled: !!postId });
};

export default usePostDeliveryData;
