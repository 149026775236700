/* eslint-disable react/no-danger */

import { useEffect, useState } from 'react';

import { Button } from '@/ui/Button';

import ActionModal from '../../../../../../components/ActionModal';
import SlideOver from '../../../../../../components/SlideOver';
import { useDestroyExternalEmbed, useExternalEmbed } from '../../../../../../hooks/useExternalEmbeds';
import { ExternalEmbed, ExternalEmbedPreview } from '../../../../../../interfaces/external_embed';
import Section from '../Section';

const ExternalEmbedCode = ({ text }: { text: String }) => (
  <pre className="h-full bg-gray-100 p-4 rounded mt-4 mb-4 overflow-x-scroll">{text}</pre>
);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  externalEmbedId: string | undefined | null;
}

const ExternalEmbedShow = ({ isOpen, onClose, externalEmbedId }: Props) => {
  const [externalEmbed, setExternalEmbed] = useState<ExternalEmbed | undefined>();
  const [preview, setPreview] = useState<ExternalEmbedPreview | undefined>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { data } = useExternalEmbed(externalEmbedId);

  const onSuccess = () => {
    setDeleteModalOpen(false);
    onClose();
  };

  const { mutateAsync: doDelete } = useDestroyExternalEmbed({ onSuccess });

  useEffect(() => {
    setExternalEmbed(data?.external_embed);
    setPreview(data?.preview);
  }, [data]);

  return (
    <>
      <SlideOver
        bodyId={externalEmbed?.id}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerText={externalEmbed?.name || ''}
      >
        {externalEmbed && (
          <>
            <div className="flex" />
            <Section
              title="Embed Options"
              description="Below are various ways you can embed your subscribe form that offer various levels of control. Make sure to choose the best one for your use case."
            >
              <Section
                title="Full Width"
                description="Embed this code directly in-line within your website, and it will expand to the full width of its surrounding container."
                divider
              >
                <ExternalEmbedCode text={preview?.full_width || ''} />
                <div dangerouslySetInnerHTML={{ __html: preview?.full_width || '' }} />
              </Section>
              <Section
                title="Fixed Width"
                description="Embed this code directly in-line with your website, and it will expand to the specified maximum width."
                divider
              >
                <ExternalEmbedCode text={preview?.fixed_width || ''} />
                <div dangerouslySetInnerHTML={{ __html: preview?.fixed_width || '' }} />
              </Section>
              <Section
                title="Slim Embed"
                description="This offers the most control with placement without providing a background, header, or description."
                divider
              >
                <ExternalEmbedCode text={preview?.slim || ''} />
                <div dangerouslySetInnerHTML={{ __html: preview?.slim || '' }} />
              </Section>
            </Section>
            <Section
              title="Advanced Configuration"
              description="In addition to embedding the subscribe forms, there are more advanced customizations that can be added."
            >
              <Section
                title="Attribution Tracking"
                description="If you would like to forward UTM parameters to beehiiv from the page this is embedded on, add this script right before the closing body tag."
                divider
              >
                <ExternalEmbedCode text={preview?.attribution_script || ''} />
              </Section>
              <div className="flex w-full justify-end">
                <Button
                  variant="danger"
                  onClick={() => {
                    onClose();
                    setDeleteModalOpen(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            </Section>
          </>
        )}
      </SlideOver>
      {externalEmbed && (
        <ActionModal
          isWorking={false}
          headerText="Are you sure?"
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onProceed={() => doDelete(externalEmbed)}
          resourceId={externalEmbed.id}
        >
          Deleting this form will cause it to immediately stop working everywhere it&apos;s currently embedded. Are you
          sure you want to delete it?
        </ActionModal>
      )}
    </>
  );
};

export default ExternalEmbedShow;
