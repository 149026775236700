import { Extension } from '@tiptap/core';

import '@tiptap/extension-text-style';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontGradient: {
      setFontGradient: (gradient: string) => ReturnType;
      unsetFontGradient: () => ReturnType;
    };
  }
}

export const FontGradient = Extension.create({
  name: 'fontGradient',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontGradient: {
            parseHTML: (element) => element.style.background.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.fontGradient) {
                return {};
              }

              return {
                class: 'bg-clip-text !text-transparent',
                style: `background-image: ${attributes.fontGradient}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontGradient:
        (fontGradient: string) =>
        ({ chain }) =>
          chain().setMark('textStyle', { fontGradient }).run(),
      unsetFontGradient:
        () =>
        ({ chain }) =>
          chain().setMark('textStyle', { fontGradient: null }).removeEmptyTextStyle().run(),
    };
  },
});

export default FontGradient;
