import { useMemo, useState } from 'react';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Input } from '@/components/Form';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';
import { EllipsisDropdownOption } from '@/interfaces/general';
import { Badge } from '@/ui/Badge';

import useVerificationModals from '../hooks/useVerificationModals';
import { isDomainVerified } from '../utils';

import DisconnectDomainForm from './DisconnectDomainForm';
import StatusBadge from './StatusBadge';

interface Props {
  customDomain: CustomDomain;
  disabled: boolean;
}

const RedirectDomain: React.FC<Props> = ({ customDomain, disabled }) => {
  const { handleOpenDnsRecordsModal } = useVerificationModals();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const type = CustomDomainTypes.REDIRECT;
  const { domain, entri_configuration: entriConfiguration } = customDomain;
  const entriVerificationPending = entriConfiguration?.web_redirect_pending;
  const isVerified = isDomainVerified(customDomain, CustomDomainTypes.REDIRECT);

  const dropdownOptions = useMemo(() => {
    const options: EllipsisDropdownOption[] = [];

    // TODO: [BEE-7083] Allow viewing Entri records when verified
    if (!entriVerificationPending) {
      options.push({
        label: isVerified ? 'View DNS Records' : 'Verify',
        onClick: () => handleOpenDnsRecordsModal(type),
      });
    }

    return [
      ...options,
      {
        label: 'Disconnect Domain',
        onClick: () => setDeleteModalOpen(true),
        isDestructive: true,
      },
    ];
  }, [entriVerificationPending, isVerified, handleOpenDnsRecordsModal, type]);

  return (
    <>
      <DisconnectDomainForm
        customDomain={customDomain}
        type={type}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />

      <div className="p-4 bg-gray-50 border border-surface-200 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-start space-x-2">
            <Badge size="sm">Web</Badge>
            <StatusBadge domain={customDomain} type={type} />
          </div>
          {!disabled && <EllipsisDropdown options={dropdownOptions} />}
        </div>

        <Input name="domain" labelText="Domain" value={domain} className="flex-grow" readOnly />
      </div>
    </>
  );
};

export default RedirectDomain;
