import { useQuery } from 'react-query';

import { AdNetworkCampaign } from '../../../interfaces/ad_network/internal/campaign';
import api from '../../../services/swarm';

export default function useCampaign({ id }: { id: string }) {
  return useQuery<AdNetworkCampaign>(
    ['ad_network', 'internal', 'campaigns', id],
    () => api.get(`/ad_network/internal/campaigns/${id}`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
