import { useState } from 'react';
import { v4 as generateUuid } from 'uuid';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { LogicalOperator, LogicalOperators } from '@/interfaces/condition';
import { Condition, Filter, Group, Refine, Segment, SegmentType } from '@/interfaces/segment';
import { deepClonePrimitiveValues } from '@/utils/deepClone';

import { subscriberStatusAttributeOption } from '../ConditionsEditor/nameOptions';
import { defaultOperatorByConditionName, equalOperator } from '../ConditionsEditor/operatorOptions';
import { NameTypes, SortableConditionBlockData } from '../ConditionsEditor/types';
import { isCondition } from '../modules/isCondition';

const extractConditions = (conditions: Condition[] | Group[], extractedConditions: Condition[] = []): Condition[] => {
  if (!conditions) {
    return extractedConditions;
  }
  conditions.forEach((condition) => {
    if ('filters' in condition) {
      extractedConditions.push(condition);
    }
    if ('conditions' in condition) {
      // eslint-disable-next-line no-param-reassign
      extractedConditions = extractConditions(condition.conditions, extractedConditions);
    }
  });
  return extractedConditions;
};

const isActiveSubscriberCondition = (condition: Condition) => {
  return (
    condition?.filters?.operator === 'equal' &&
    condition?.filters?.value === 'active' &&
    condition?.type === 'attribute' &&
    condition?.name === 'status'
  );
};

// See: https://linear.app/beehiiv/issue/BEE-2384/show-warning-when-not-filtering-by-active-on-segmentation
// We want to warn users if they're including inactive or pending subscribers in their segment
const missingActiveSubscriberCondition = (conditions: Condition[] | Group[]) => {
  const conditionsArray = extractConditions(conditions);
  return conditionsArray.length > 0 && !conditionsArray.some(isActiveSubscriberCondition);
};

const hydrateSegmentWithUUIDs: any = (conditions: Condition[]) =>
  conditions.map((conditionOrGroup: Condition | Group) => {
    if (conditionOrGroup?.type === 'group') {
      return {
        ...conditionOrGroup,
        uuid: generateUuid(),
        conditions: hydrateSegmentWithUUIDs((conditionOrGroup as Group).conditions),
      };
    }

    return {
      ...conditionOrGroup,
      uuid: generateUuid(),
    };
  });

export default function useSegmentForm(segment?: Segment) {
  const isPendingOrProcessing = segment
    ? segment.status === 'pending' || segment.status === 'processing' || segment.is_locked
    : false;

  const [name, setName] = useState(segment?.name || '');
  const [description, setDescription] = useState(segment?.description || '');
  const [segmentType, setSegmentType] = useState(segment?.segment_type || 'static');
  const [blocks, setBlocks] = useState<(Condition | Group)[]>(
    hydrateSegmentWithUUIDs(segment?.conditions.conditions || [])
  );
  const [mainLogicalOperator, setMainLogicalOperator] = useState<LogicalOperator>(
    segment?.conditions.logical_operator || LogicalOperators.AND
  );

  const [isSaving, setIsSaving] = useState(false);
  const [showSecondStep, setShowSecondStep] = useState(false);

  const [hasConditionsChanged, setHasConditionsChanged] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();

  const isNextStepButtonDisabled = !name || !segmentType;
  const nextStepLabel = segmentType !== SegmentType.MANUAL ? 'Define conditions' : 'Add subscribers';
  const canAddConditions = segmentType !== SegmentType.MANUAL;
  const canImportSubscribers = segmentType === SegmentType.MANUAL;
  const canSave = !(!name || !segmentType || (segmentType !== SegmentType.MANUAL && !blocks.length));
  const isMissingActiveSubscriberCondition = missingActiveSubscriberCondition(blocks);

  const addBlock = (newBlock: Condition | Group) => {
    setHasConditionsChanged(true);
    setBlocks([...blocks, { ...newBlock }]);
  };

  const addBlocks = (newBlocks: Condition[] | Group[]) => {
    setHasConditionsChanged(true);
    setBlocks(newBlocks);
  };

  const handleAddActiveSubscribersCondition = () => {
    if (!isMissingActiveSubscriberCondition) {
      return;
    }

    addBlock({
      uuid: generateUuid(),
      type: subscriberStatusAttributeOption.type,
      name: subscriberStatusAttributeOption.value,
      filters: {
        operator: equalOperator.value,
        value: 'active',
      },
    });
  };

  const addConditionBlock = (type: NameTypes, groupIndex?: number) => {
    const newCondition: Condition = {
      uuid: generateUuid(),
      type,
    };

    if (groupIndex === undefined) {
      // Add in main group
      addBlock(newCondition);
    } else {
      // Add in sub-group
      const newBlocks = [...blocks];
      const group = blocks[groupIndex] as Group;

      const newConditions: Condition[] = [...group.conditions, newCondition];
      group.conditions = newConditions;

      newBlocks[groupIndex] = group;

      addBlocks(newBlocks);
    }
  };

  const addGroupBlock = (type: LogicalOperator) => {
    addBlock({
      uuid: generateUuid(),
      conditions: [],
      logical_operator: type,
      type: NameTypes.GROUP,
    } as Group);
  };

  const cloneCondition = (condition: Condition) => {
    const clonedCondition: Condition = deepClonePrimitiveValues(condition);
    clonedCondition.uuid = generateUuid();
    return clonedCondition;
  };

  const duplicateConditionBlock = (blockIndex: number, groupIndex?: number) => {
    const newBlocks = [...blocks];

    if (groupIndex !== undefined) {
      const group = newBlocks[blockIndex] as Group;
      group.conditions.splice(groupIndex + 1, 0, cloneCondition(group.conditions[groupIndex]));
    } else {
      newBlocks.splice(blockIndex + 1, 0, cloneCondition(newBlocks[blockIndex]));
    }

    addBlocks(newBlocks);
  };

  const handleSelectWhichName = (value: string, blockIndex: number, subIndex?: number) => {
    const newBlocks = [...blocks];

    if (subIndex !== undefined) {
      const group = newBlocks[blockIndex] as Group;
      const condition = group.conditions[subIndex] as Condition;

      condition.name = value;

      if (condition.filters) {
        const { value: oldFilterValue, ...rest } = condition.filters;
        condition.filters = rest;
      }

      group.conditions[subIndex] = condition;

      newBlocks[blockIndex] = group;
    } else {
      const condition = newBlocks[blockIndex] as Condition;

      condition.name = value;

      if (condition.filters) {
        const { value: oldFilterValue, ...rest } = condition.filters;
        condition.filters = rest;
      }

      newBlocks[blockIndex] = condition;
    }

    addBlocks(newBlocks);
  };

  const handleFilterChange = (filters: Filter, blockIndex: number, subIndex?: number) => {
    const newBlocks = [...blocks];

    if (subIndex !== undefined) {
      const group = newBlocks[blockIndex] as Group;
      const condition = group.conditions[subIndex] as Condition;

      if (condition.filters) {
        condition.filters = filters;
      } else {
        condition.filters = {
          operator: defaultOperatorByConditionName(condition?.name),
        };
        condition.filters = {
          ...filters,
        };
      }

      group.conditions[subIndex] = condition;
      newBlocks[blockIndex] = group;
    } else {
      const condition = newBlocks[blockIndex] as Condition;

      if (condition.filters) {
        condition.filters = filters;
      } else {
        condition.filters = {
          operator: defaultOperatorByConditionName(condition?.name),
        };
        condition.filters = {
          ...filters,
        };
      }

      newBlocks[blockIndex] = condition;
    }

    addBlocks(newBlocks);
  };

  const handleRefineChange = (refine: Refine, blockIndex: number, subIndex?: number) => {
    const newBlocks = [...blocks];

    if (subIndex !== undefined) {
      const group = newBlocks[blockIndex] as Group;
      const condition = group.conditions[subIndex] as Condition;

      condition.refine = refine;

      group.conditions[subIndex] = condition;
      newBlocks[blockIndex] = group;
    } else {
      const condition = newBlocks[blockIndex] as Condition;

      condition.refine = refine;

      newBlocks[blockIndex] = condition;
    }

    addBlocks(newBlocks);
  };

  const handleGroupLogicalOperatorChange = (value: LogicalOperator, blockIndex?: number) => {
    if (blockIndex === undefined) {
      return;
    }

    const newBlocks = [...blocks];
    const group = newBlocks[blockIndex] as Group;

    if (value !== LogicalOperators.AND && value !== LogicalOperators.OR && value !== LogicalOperators.NONE) {
      return;
    }

    group.logical_operator = value;

    newBlocks[blockIndex] = group;

    addBlocks(newBlocks);
  };

  const handleDeleteCondition = (blockIndex: number, subIndex?: number) => {
    const newBlocks = [...blocks];

    if (subIndex !== undefined) {
      const group = newBlocks[blockIndex] as Group;
      group.conditions.splice(subIndex, 1);
    } else {
      newBlocks.splice(blockIndex, 1);
    }

    addBlocks(newBlocks);
  };

  const handleDeleteRefine = (blockIndex: number, subIndex?: number) => {
    const newBlocks = [...blocks];

    if (subIndex !== undefined) {
      const group = newBlocks[blockIndex] as Group;
      delete group.conditions[subIndex].refine;
      newBlocks[blockIndex] = group;
    } else {
      const block = newBlocks[blockIndex];
      if (isCondition(block)) {
        const condition = block as Condition;
        delete condition.refine;
        newBlocks[blockIndex] = condition;
      }
    }

    addBlocks(newBlocks);
  };

  const handleSelectConditionName = (conditionOption: any) => {
    addBlock({
      uuid: generateUuid(),
      type: conditionOption.dataType,
      name: conditionOption.value,
    });
  };

  const handleSelectConditionNameInGroup = (conditionOption: any, blockIndex?: number) => {
    if (blockIndex === undefined) {
      return;
    }

    const newBlocks = [...blocks];
    const group = newBlocks[blockIndex] as Group;
    group.conditions.push({
      uuid: generateUuid(),
      type: conditionOption.dataType,
      name: conditionOption.value,
    });

    addBlocks(newBlocks);
  };

  const handleMainLogicalOperatorChange = (logicalOperator: LogicalOperator) => {
    setHasConditionsChanged(true);
    setMainLogicalOperator(logicalOperator);
  };

  const handleSortConditionBlocks = (
    activeBlockData?: SortableConditionBlockData,
    overBlockData?: SortableConditionBlockData
  ) => {
    if (!activeBlockData || !overBlockData) {
      return;
    }

    const newBlocks = [...blocks];

    // Dropping a condition block from a group
    // to empty root group
    if (overBlockData.isRootGroup && activeBlockData.index !== undefined && activeBlockData.groupIndex !== undefined) {
      const group = newBlocks[activeBlockData.groupIndex] as Group;
      const deletedBlock = group.conditions.splice(activeBlockData.index, 1)[0];
      newBlocks.splice(0, 0, deletedBlock);
      addBlocks(newBlocks);
      return;
    }

    // Dropping a condition block from root group
    // to empty sub-group
    if (
      overBlockData.isSubGroup &&
      activeBlockData.index !== undefined &&
      activeBlockData.groupIndex === undefined &&
      overBlockData.groupIndex !== undefined
    ) {
      const group = newBlocks[overBlockData.groupIndex] as Group;
      const deletedBlock = newBlocks.splice(activeBlockData.index, 1)[0];
      group.conditions.splice(0, 0, deletedBlock);
      addBlocks(newBlocks);
      return;
    }

    // Dropping a condition block from a sub-group
    // to an empty sub-group
    if (
      overBlockData.isSubGroup &&
      activeBlockData.index !== undefined &&
      activeBlockData.groupIndex !== undefined &&
      overBlockData.groupIndex !== undefined
    ) {
      const activeBlockGroup = newBlocks[activeBlockData.groupIndex] as Group;
      const deletedBlock = activeBlockGroup.conditions.splice(activeBlockData.index, 1)[0];
      const overBlockGroup = newBlocks[overBlockData.groupIndex] as Group;
      overBlockGroup.conditions.splice(0, 0, deletedBlock);
      addBlocks(newBlocks);
      return;
    }

    // Sorting a condition in root group below the sub-group block
    if (activeBlockData.index !== undefined && overBlockData.isRootGroup) {
      const deletedBlock = newBlocks.splice(activeBlockData.index, 1)[0];
      newBlocks.splice(0, 0, deletedBlock);

      addBlocks(newBlocks);
      return;
    }

    if (activeBlockData.index === undefined || overBlockData.index === undefined) {
      return;
    }

    // Sorting a condition either withing the root or sub-group
    if (activeBlockData.groupIndex === overBlockData.groupIndex) {
      if (activeBlockData.groupIndex === undefined) {
        // Within Main group
        const deletedBlock = newBlocks.splice(activeBlockData.index, 1)[0];
        newBlocks.splice(overBlockData.index, 0, deletedBlock);
      } else {
        // Within Sub group
        const group = newBlocks[activeBlockData.groupIndex] as Group;
        const deletedBlock = group.conditions.splice(activeBlockData.index, 1)[0];
        group.conditions.splice(overBlockData.index, 0, deletedBlock);
      }

      addBlocks(newBlocks);
      return;
    }

    // Dropping a condition from root group to a sub-group
    if (activeBlockData.groupIndex === undefined && overBlockData.groupIndex !== undefined) {
      // Moving from outside a group to a group
      const group = newBlocks[overBlockData.groupIndex] as Group;
      const deletedBlock = newBlocks.splice(activeBlockData.index, 1)[0];
      group.conditions.splice(overBlockData.index + 1, 0, deletedBlock);

      addBlocks(newBlocks);
      return;
    }

    // Dropping a condition from sub-group to root group
    if (activeBlockData.groupIndex !== undefined && overBlockData.groupIndex === undefined) {
      const group = newBlocks[activeBlockData.groupIndex] as Group;
      const deletedBlock = group.conditions.splice(activeBlockData.index, 1)[0];
      newBlocks.splice(overBlockData.index + 1, 0, deletedBlock);

      addBlocks(newBlocks);
      return;
    }

    // Dropping a condition from sub-group to sub-group
    if (
      activeBlockData.groupIndex !== undefined &&
      overBlockData.groupIndex !== undefined &&
      activeBlockData.groupIndex !== overBlockData.groupIndex
    ) {
      const activeBlockGroup = newBlocks[activeBlockData.groupIndex] as Group;
      const deletedBlock = activeBlockGroup.conditions.splice(activeBlockData.index, 1)[0];
      const overBlockGroup = newBlocks[overBlockData.groupIndex] as Group;
      overBlockGroup.conditions.splice(overBlockData.index + 1, 0, deletedBlock);

      addBlocks(newBlocks);
    }
  };

  return {
    isPendingOrProcessing,
    handleAddActiveSubscribersCondition,
    handleSelectWhichName,
    handleFilterChange,
    handleRefineChange,
    handleGroupLogicalOperatorChange,
    handleDeleteCondition,
    handleDeleteRefine,
    handleSelectConditionName,
    handleSelectConditionNameInGroup,
    handleMainLogicalOperatorChange,
    handleSortConditionBlocks,
    isMissingActiveSubscriberCondition,
    isNextStepButtonDisabled,
    nextStepLabel,
    canAddConditions,
    canImportSubscribers,
    isSaving,
    setIsSaving,
    name,
    setName,
    description,
    setDescription,
    segmentType,
    setSegmentType,
    showSecondStep,
    setShowSecondStep,
    mainLogicalOperator,
    setMainLogicalOperator,
    hasConditionsChanged,
    setHasConditionsChanged,
    blocks,
    setBlocks,
    addConditionBlock,
    addGroupBlock,
    duplicateConditionBlock,
    currentPublicationId,
    canSave,
  };
}
