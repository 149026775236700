import React from 'react';
import { SerializableNode, Serializer } from '@shared/dream-components';

export const SimpleFooterPreviewer = ({ content }: { content?: SerializableNode }) => {
  return (
    <div className="h-fit w-full flex flex-col justify-start items-center">
      {content && (
        <Serializer node={content as SerializableNode} rootClassName="footer" rootStyle={{ minHeight: 'unset' }} />
      )}
    </div>
  );
};
