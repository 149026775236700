import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

const useApproveAllSubscribers = (publicationId: string, onSuccess?: () => void) => {
  return useMutation(() => api.patch(`/publications/${publicationId}/approve_all_subscriptions`), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      toast.success('All subscribers have been approved!');
      if (onSuccess) onSuccess();
    },
  });
};

export default useApproveAllSubscribers;
