import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { SpacerView } from './views/SpacerView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    spacer: {
      setSpacer: () => ReturnType;
    };
  }
}

export const Spacer = Node.create<{}, {}>({
  name: 'spacer',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setSpacer:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: { height: 100 },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SpacerView);
  },
});

export default Spacer;
