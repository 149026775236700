import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DotsThree, House, Plus } from '@phosphor-icons/react';
import cx from 'classnames';

import { useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';
import { useCreatePage } from '@/hooks/usePages';
import usePageVersion from '@/hooks/usePageVersion/usePageVersion';
import { PageRoute } from '@/interfaces/dream_builder/page_route';

import { cn } from '../../_utils/cn';
import { PageOption } from '../Main/PageOption';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

import { useContentTreeContext } from './context';

interface Props {
  route: PageRoute;
  parentPath: string[];
}

export const ContentTreeItemHome = ({ route, parentPath }: Props) => {
  const { pageLinkPrefix, isShowAddPage, isShowOptions, isLinkToPage, rightComponent } = useContentTreeContext();
  const { pageId } = useParams() as unknown as { pageId: string };

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const pageRouteGetter = useWebsitePageRouteGetter();
  const page = pageRouteGetter?.getPageFromID(route.page_id);
  const isCurrentPath = pageId === route.page_id;

  const { previewSiteVersion } = useWebsiteContext();
  const { data: previewPageVersion } = usePageVersion({
    pageId: route.page_id as string,
    pageVersionId: route.page_version_id as string,
    enabled: !!route.page_version_id,
  });
  const pageVersion = previewSiteVersion?.id ? previewPageVersion : page?.draft_page_version;

  const createPage = useCreatePage({
    routesType: 'custom',
  });

  const createPageMutation = async () => {
    await createPage.mutateAsync({ parentPath: [] });
  };
  const Wrapper = isLinkToPage ? Link : 'div';

  return (
    <div className={cx('flex flex-col', parentPath?.length <= 0 ? '' : 'ml-4')}>
      <Wrapper
        to={`${pageLinkPrefix}/${route.page_id}`}
        className={cx(
          'h-8 px-1.5 py-2 flex rounded-lg flex-row w-full items-center gap-2 group',
          isCurrentPath || isSettingsOpen ? 'bg-wb-secondary' : 'hover:bg-wb-secondary'
        )}
      >
        <House weight="bold" size={16} className="ml-6 my-auto text-wb-secondary" />

        <Tooltip center={pageVersion?.name} className="flex-1 flex items-center text-ellipsis overflow-hidden">
          <Text as="p" size="2xs" weight="medium" className="truncate">
            {pageVersion?.name}
          </Text>
        </Tooltip>

        <div
          className={cn(
            'group-hover:opacity-100 flex items-center gap-1',
            isSettingsOpen ? 'opacity-100' : 'opacity-0'
          )}
        >
          {isShowOptions && (
            <Tooltip center="Page Options" className="flex-1  flex items-center">
              <PageOption
                pageId={route.page_id}
                align="start"
                open={isSettingsOpen}
                onOpenChange={setIsSettingsOpen}
                isDefaultPage={false}
                isHome
              >
                <div className="hover:bg-wb-highlight rounded-full p-0.5">
                  <DotsThree weight="bold" className="my-auto text-wb-secondary h-4 w-4" />
                </div>
              </PageOption>
            </Tooltip>
          )}
          {isShowAddPage && (
            <Tooltip center="Add Page Inside" className="flex-1 flex items-center">
              <button type="button" className="hover:bg-wb-highlight rounded-full p-0.5" onClick={createPageMutation}>
                <Plus weight="bold" className="my-auto text-wb-secondary h-4 w-4" />
              </button>
            </Tooltip>
          )}
        </div>
        {rightComponent && rightComponent(route.page_id)}
      </Wrapper>
    </div>
  );
};
