import cx from 'classnames';

type NoticeMessageProps = React.PropsWithChildren<{
  className?: string;
}>;

const NoticeMessage = ({ className, children }: NoticeMessageProps) => {
  const classNames = cx(
    'mb-4 flex flex-row space-x-4 w-full items-center justify-center',
    'py-2 rounded-r bg-blue-50 text-blue-700 border-l-4 border-blue-400',
    className
  );

  return <div className={classNames}>{children}</div>;
};

export default NoticeMessage;
