import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import useDeleteMilestone from '@/hooks/useMilestones/useDeleteMilestone';
import { EllipsisDropdownOption } from '@/interfaces/general';
import { Milestone } from '@/interfaces/milestone';
import RewardImage from '@/pages/ReferralProgram/Configure/RewardImage';
import pluralize from '@/utils/pluralize';
import rewardTypeLabels, { SINGLE_PROMO_CODE_LABEL } from '@/values/rewardType';

interface Props {
  milestone: Milestone;
}

const ReferralRow = ({ milestone }: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const { mutateAsync: deleteMilestone, isLoading } = useDeleteMilestone(milestone.id);

  const handleDelete = () => {
    deleteMilestone();
    setShowConfirmDelete(false);
  };

  const handleClickManagePromoCodes = () => {
    searchParams.set('manage_promo_codes', milestone.reward_id);
    setSearchParams(searchParams);
  };

  const menuOptions: EllipsisDropdownOption[] = [
    { label: 'Edit', onClick: () => navigate(`/referral_program/milestones/${milestone.id}/edit`) },
    { label: 'Delete', onClick: () => setShowConfirmDelete(true) },
  ];

  if (milestone.reward.reward_type === 'promo_code' && !milestone.reward.use_static_promo_code) {
    menuOptions.push({ label: 'Manage Promo Codes', onClick: handleClickManagePromoCodes });
  }

  return (
    <>
      <ActionModal
        isOpen={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onProceed={handleDelete}
        resourceId={milestone.id}
        isWorking={isLoading}
        headerText="Delete milestone"
        actionText="Delete"
      >
        Are you sure you want to delete this milestone?
      </ActionModal>
      <ItemRow>
        <ItemColumn align={AlignType.TOP}>
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <RewardImage image={milestone.reward.image} />
            </div>
            <div className="ml-4">
              <Typography token="font-normal/text/sm">{milestone.reward.name}</Typography>
            </div>
          </div>
        </ItemColumn>
        <ItemColumn align={AlignType.MIDDLE}>
          <Typography token="font-normal/text/sm">{milestone.auto_fulfill ? 'Yes' : 'No'}</Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.MIDDLE}>
          <Typography token="font-normal/text/sm">
            {milestone.reward.use_static_promo_code
              ? SINGLE_PROMO_CODE_LABEL
              : rewardTypeLabels[milestone.reward.reward_type]}
          </Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.MIDDLE}>
          <Typography token="font-normal/text/sm">{pluralize('referral', milestone.num_referrals)}</Typography>
        </ItemColumn>
        <ItemColumn>
          <EllipsisDropdown options={menuOptions} />
        </ItemColumn>
      </ItemRow>
    </>
  );
};

export default ReferralRow;
