import { useMemo } from 'react';
import { BanknotesIcon, CurrencyDollarIcon } from '@heroicons/react/20/solid';
import { Color } from '@tremor/react';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import ChartHeader from '@/components/Chartsv2/ChartHeader';
import { Color as DesignTokenColor } from '@/components/DesignTokens';
import usePublicationInsightsPerPeriod from '@/hooks/boosts/grow/usePublicationInsightsPerPeriod';
import useSpendPerPeriod from '@/hooks/boosts/grow/useSpendPerPeriod';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Option } from '@/interfaces/general';
import { currencyFormatter } from '@/utils';
import pluralize from '@/utils/pluralize';
import { timePeriodLabels } from '@/utils/timePeriods';

import KPI from './KPI';

const SPEND_CATEGORY: Option = {
  value: 'spend',
  label: 'Spend',
};
const PREVIOUS_SPEND_CATEGORY: Option = {
  value: 'previous_spend',
  label: 'Previous Spend',
};

const CHART_CATEGORIES = ['Spend', 'Previous Spend'];
const CHART_COLORS: Color[] = ['emerald', 'gray'];

const SpendPerPeriodChart = () => {
  const { period, periodLabel, TimePeriodSelect } = useTimePeriodSelect();
  const { data: spendData, isLoading: isSpendDataLoading } = useSpendPerPeriod(period as keyof typeof timePeriodLabels);
  const { data: publicationInsightsData } = usePublicationInsightsPerPeriod(period as keyof typeof timePeriodLabels);

  const totalSpend =
    useMemo(() => {
      return spendData?.metrics.reduce((pv, cv) => pv + cv.spend, 0);
    }, [spendData]) || 0;
  // remove $ because we show the currency sign as an icon
  const formattedTotalSpend = currencyFormatter(totalSpend, 'USD').replace('$', '');

  const mappedChartData = useMemo(() => {
    if (isSpendDataLoading || typeof spendData !== 'object') {
      return [];
    }

    return spendData?.metrics.map((row) => {
      return {
        date: row.name,
        [SPEND_CATEGORY.label]: row.spend,
        [PREVIOUS_SPEND_CATEGORY.label]: row.previous_spend,
      };
    });
  }, [spendData, isSpendDataLoading]);

  return (
    <ChartCard
      noResultsText={isSpendDataLoading ? 'Fetching...' : 'No growth data available within the chosen time range.'}
      noResults={mappedChartData.length === 0}
    >
      <div className="flex flex-col gap-y-5">
        <ChartHeader
          title="Total spend on Boosts"
          description="Break down over time of how much you have spent via Boosts."
          kpiLabel={pluralize('total spend', totalSpend, 'total spend', true)}
          kpiValue={formattedTotalSpend}
          timePeriodSelector={<TimePeriodSelect />}
          Icon={CurrencyDollarIcon}
          textColor={DesignTokenColor.SUCCESS}
          iconColor="text-feedback-success-500"
        />
        <AreaChart
          index="date"
          data={mappedChartData}
          categories={CHART_CATEGORIES}
          colors={CHART_COLORS}
          showLegend={false}
          showXAxis
          showYAxis
          showGridLines
          valueFormatter={(value) => currencyFormatter(value, 'USD')}
        />
        {publicationInsightsData?.highest_paid_partner && (
          <KPI
            Icon={BanknotesIcon}
            title="Highest paid partner"
            publicationName={publicationInsightsData.highest_paid_partner.name}
            periodLabel={periodLabel}
            iconColor="text-feedback-success-900"
          />
        )}
      </div>
    </ChartCard>
  );
};

export default SpendPerPeriodChart;
