import React from 'react';

import CurrencyInput from '../../../../components/Form/CurrencyInput';

interface Props {
  lowerBound?: number;
  upperBound?: number;
  setLowerBound: (value: number | undefined) => void;
  setUpperBound: (value: number | undefined) => void;
  name?: string;
  id?: string;
}

const CurrencyRange = ({ lowerBound, upperBound, setLowerBound, setUpperBound, name, id }: Props) => {
  return (
    <div className="flex items-center space-x-2">
      <CurrencyInput
        name={`${name}_lower_bound`}
        id={`${id}_lower_bound`}
        placeholder="No minimum"
        onChange={(val) => setLowerBound(val)}
        value={lowerBound}
      />
      <span>—</span>
      <CurrencyInput
        name={`${name}_upper_bound`}
        id={`${id}_upper_bound`}
        placeholder="No maximum"
        onChange={(val) => setUpperBound(val)}
        value={upperBound}
      />
    </div>
  );
};

export default CurrencyRange;
