import { useParams } from 'react-router-dom';

import { useAdvertiserCampaignCreate } from '../../../../../hooks/useAdNetwork/internal';
import { PageHeading } from '../../../_components/PageHeading';
import { Form } from '../../../campaigns/_components/Form';

interface PageProps {
  advertiser_id: string;
}

const Page = ({ advertiser_id }: PageProps) => {
  const onSubmitMutation = useAdvertiserCampaignCreate({ id: advertiser_id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Campaigns</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">New</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <Form onSubmitMutation={onSubmitMutation} />
    </>
  );
};

export default function Loader() {
  const { advertiser_id: id } = useParams<'advertiser_id'>() as { advertiser_id: string };

  return <Page advertiser_id={id} />;
}
