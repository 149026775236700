import { XMarkIcon } from '@heroicons/react/20/solid';

interface AppliedFilterProps {
  name: string;
  label: string;
  value?: string | number;
  onRemove: () => void;
}

const AppliedFilter = ({ name, label, value, onRemove }: AppliedFilterProps) => {
  return (
    <div className="text-sm rounded-lg border bg-gray-50 border-gray-300 inline-flex max-w-xs overflow-auto space-x-2">
      <div className="flex items-center text-gray-700 font-medium pl-2">
        <div className="pr-0.5 py-1">{name}</div>
        <div className="px-0.5 py-1">{label}</div>
        {value && <div className="pl-0.5 py-1 truncate">{value}</div>}
      </div>
      <button type="button" onClick={onRemove} className="pr-2 py-1 inline-flex items-center">
        <XMarkIcon className="w-4 h-4 text-gray-500" />
      </button>
    </div>
  );
};

export default AppliedFilter;
