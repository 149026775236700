import moment from 'moment-mini';

import { useCurrentPublication } from '@/hooks';
import api from '@/services/swarm';

import { DataExport, DataExportStatus, DataExportType } from '../../../../../interfaces/data_export';

const dataExportTypeLabel: { [K in DataExportType]: string } = {
  post_poll_responses: 'Post Poll Responses',
  poll_responses: 'Poll Responses',
  posts: 'Posts',
  referral_program_subscriber: 'Referrals',
  segment_subscriber: 'Subscriber Segment',
  subscribers: 'Subscribers',
  basic_subscriber: 'Basic Subscribers',
  basic_segment_subscriber: 'Basic Subscriber Segment',
  accounting_ledger: 'Ledgers',
};

const dataExportStatusClassNames: { [K in DataExportStatus]: string } = {
  completed: 'bg-green-100 text-green-800 border-green-200',
  failed: 'bg-red-100 text-red-800 border-red-200',
  pending: 'bg-gray-100 text-gray-800 border-gray-200',
  processing: 'bg-gray-100 text-gray-800 border-gray-200',
};

interface CellProps {
  width: number;
  children: React.ReactNode;
}

const Cell: React.FunctionComponent<CellProps> = ({ width, children }) => (
  <div className={`px-6 py-3 text-left text-sm text-gray-900 col-span-${width}`}>{children}</div>
);

interface DataExportRowProps {
  dataExport: DataExport;
}

const DataExportRow: React.FunctionComponent<DataExportRowProps> = ({ dataExport }) => {
  const { id, exportType, status, failureReason, createdAt, downloadUrl } = dataExport;
  const { data: currentPublication } = useCurrentPublication();

  const classNames = dataExportStatusClassNames[status];
  const label = dataExportTypeLabel[exportType];
  const date = moment(createdAt).format('ll LT');

  const handleClick = async () => {
    await api.post(`/publications/${currentPublication?.id}/log_export`, { export_id: id });
  };

  return (
    <div className="grid grid-cols-12 border-b border-gray-200">
      <Cell width={3}>
        <time dateTime={createdAt} aria-label={date}>
          {date}
        </time>
      </Cell>
      <Cell width={2}>{label}</Cell>
      <Cell width={4}>
        <div className={`border shadow-sm inline-flex items-center rounded-md text-xs ${classNames} px-2.5 py-0.5`}>
          {status}
        </div>
        {failureReason && <span>{failureReason}</span>}
      </Cell>
      <Cell width={3}>
        {downloadUrl && (
          <a
            onClick={handleClick}
            href={downloadUrl}
            className="px-2.5 py-0.5 text-primary-600 hover:text-primary-900 underline"
          >
            Download
          </a>
        )}
      </Cell>
    </div>
  );
};

export default DataExportRow;
