import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AdNetworkCampaignPayoutMethod } from '@/interfaces/ad_network/internal/campaign';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  opportunities: AdNetworkOpportunity[];
  pagination: Pagination;
}

interface Props {
  filter?: 'pending' | 'upcoming' | 'past';
  startDate?: string;
  endDate?: string;
  payoutMethod?: AdNetworkCampaignPayoutMethod;
}

export default function useOpportunities({ filter, startDate, endDate, payoutMethod }: Props) {
  const [publicationId] = useCurrentPublicationState(false);

  const fetchOpportunities = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/publisher/opportunities`, {
        params: {
          page: pageParam,
          per_page: 25,
          publication_id: publicationId,
          filter,
          start_date: startDate,
          end_date: endDate,
          payout_method: payoutMethod,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    [publicationId, 'ad_network', 'opportunities', filter, startDate, endDate, payoutMethod],
    fetchOpportunities,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
