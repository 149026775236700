import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

import ActivityFeed from '../_components/ActivityFeed';
import { useSearchContext } from '../_context/searchContext';
import { useSubscriberContext } from '../_context/subscriberContext';
import { useAutomationActivityFeedEvents } from '../_hooks/useActivityFeedEvents';

const SubscriberAutomationActivity = () => {
  const { search, eventType } = useSearchContext();
  const { subscription } = useSubscriberContext();
  const query = useAutomationActivityFeedEvents({ search: search || undefined, subscription, eventType });

  return (
    <ActivityFeed
      title="Automations Activity Feed"
      emptyText="No automation events for the subscriber have been recorded yet."
      query={query}
      eventTypeOptions={[
        ActivityFeedEventType.AUTOMATION_ENROLLED,
        ActivityFeedEventType.AUTOMATION_COMPLETED,
        ActivityFeedEventType.SENT,
        ActivityFeedEventType.DELIVERED,
        ActivityFeedEventType.OPEN,
        ActivityFeedEventType.CLICK,
        ActivityFeedEventType.UNSUBSCRIBE,
        ActivityFeedEventType.SPAM_REPORT,
        ActivityFeedEventType.DEFERRAL,
        ActivityFeedEventType.DELIVERY_ERROR,
      ]}
    />
  );
};

export default SubscriberAutomationActivity;
