import { ChangeEvent } from 'react';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';

interface ModalProps {
  postIds: string[];
  isOpen: boolean;
  disabled: boolean;
  deleteConfirmationValue: string;
  setDeleteConfirmationValue: (value: string) => void;
  setConfirmedDelete: (value: boolean) => void;
  handleOnClose: () => void;
  handleOnProceed: () => void;
  headerText: string;
}

const DeleteModal = ({
  postIds,
  headerText,
  disabled,
  deleteConfirmationValue,
  setDeleteConfirmationValue,
  setConfirmedDelete,
  isOpen = false,
  handleOnClose,
  handleOnProceed,
}: ModalProps) => {
  const modalId = postIds.filter((id) => id !== null).join('-');
  const CONFIRM_TEXT = 'delete';

  const handleDeleteConfirmChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setDeleteConfirmationValue(newValue);
    setConfirmedDelete(newValue === CONFIRM_TEXT);
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onProceed={handleOnProceed}
      onClose={handleOnClose}
      resourceId={modalId}
      isWorking={false}
      headerText={headerText}
      actionText="Delete"
      disabled={disabled}
      buttonType="danger"
      buttonShade="dark"
      modalMessageType="danger"
      descriptionText="This is a permanent action that cannot be undone. Are you sure you want to delete these posts?"
    >
      <Input
        labelText={`Type '${CONFIRM_TEXT}' below`}
        helperText="To confirm deletion, please enter the word ‘delete’"
        placeholderText="Enter delete"
        type="text"
        name="delete-confirm"
        className="pt-6"
        onChange={handleDeleteConfirmChange}
        value={deleteConfirmationValue}
      />
    </ActionModal>
  );
};

export default DeleteModal;
