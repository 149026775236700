import { useCallback } from 'react';
import styled from 'styled-components';

import { useCurrentUser } from '@/context/current-user-context';

import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Spinner } from '../../../components/ui/Spinner';
import { colors } from '../../../lib/colors';
import { usePublicationContext } from '../../../lib/context/PublicationContext';
import { useFileUpload } from '../../ImageUpload/view/hooks';

import { useAudioUploader, useDropZone } from './hooks';
import { LoadingWrapper } from './shared';

const UploadWrapper = styled.div<{
  isDraggedIn?: boolean;
}>`
  align-items: center;
  background-color: ${(p) => (p.isDraggedIn ? '#f0f0f0' : 'white')};
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
  border-radius: 0.25rem;

  [data-type='column'] & {
    padding: 1rem 0.5rem;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${colors.black[4]};
`;

const UploadContent = styled.div`
  margin-top: 1rem;
`;

const UploadLabel = styled.div`
  color: ${colors.black[3]};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
`;

const UploadButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  margin-top: 0.75rem;

  // if this component is used inside a
  // [data-type="column"], change flex layout
  [data-type='column'] & {
    flex-direction: column;
  }
`;

const FileInput = styled.input`
  height: 0;
  opacity: 0;
  overflow: hidden;
  width: 0;
`;

export const AudioFileUploader = ({
  onUpload,
}: {
  onUpload: ({ file, assetId }: { assetId: string; file: File }) => void;
}) => {
  const { publicationId } = usePublicationContext();
  const { currentUser } = useCurrentUser();

  const { handleUploadClick, ref } = useFileUpload();

  const { loading, uploadAudioFile } = useAudioUploader({ publicationId, userId: currentUser?.id });

  const { draggedInside, onDrop, onDragEnter, onDragLeave } = useDropZone({ uploader: uploadAudioFile, onUpload });

  const handleUploadAudioFile = useCallback(
    async (file: File) => {
      const assetId = await uploadAudioFile(file);

      onUpload({ file, assetId });
    },
    [onUpload, uploadAudioFile]
  );

  if (loading) {
    return (
      <LoadingWrapper>
        <Spinner size={1.5} />
      </LoadingWrapper>
    );
  }

  return (
    <UploadWrapper
      isDraggedIn={draggedInside}
      onDrop={onDrop}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
      contentEditable={false}
      className="relative"
    >
      <StyledIcon name="FileUpload" $size="" />

      <UploadContent>
        <UploadLabel>{draggedInside ? 'Drop audio file here' : 'Drag and drop or'}</UploadLabel>

        <UploadButtons>
          <Button
            disabled={draggedInside}
            onClick={handleUploadClick}
            $variant="secondary"
            $size="small"
            $leftSlot={<Icon name="Upload" />}
          >
            Upload audio file
          </Button>
        </UploadButtons>
      </UploadContent>

      <FileInput
        ref={ref}
        type="file"
        accept="audio/mpeg, audio/wav, audio/ogg, audio/flac, audio/aac, audio/webm"
        onChange={(e) => (e.target.files ? handleUploadAudioFile(e.target.files[0]) : null)}
      />

      <div className="absolute right-1 bottom-0 text-xs text-gray-500">
        Allowed types: mp3, wav, ogg, flac, aac, webm
      </div>
    </UploadWrapper>
  );
};

export default AudioFileUploader;
