import { useParams } from 'react-router-dom';

import Card from '@/components/Card';
import { Button } from '@/ui/Button';

import PublicationDetails from './_components/PublicationDetails';
import useConfirmSubscriptionChange from './_hooks/useConfirmSubscriptionChange';
import useDeleteSubscriptionChange from './_hooks/useDeleteSubscriptionChange';
import useSubscriptionChange from './_hooks/useSubscriptionChange';

const ConfirmSubscriptionChanges: React.FunctionComponent = () => {
  const { token } = useParams<'token'>() as { token: string };

  const { data, isLoading, isError } = useSubscriptionChange(token);
  const subscriptionChange = data;
  const publication = subscriptionChange?.publication;

  const { mutateAsync: deleteSubscriptionChange } = useDeleteSubscriptionChange(token || '');
  const { mutateAsync: confirmSubscriptionChange } = useConfirmSubscriptionChange(token || '');

  const handleCancelSubscriptionChange = () => {
    deleteSubscriptionChange();
  };

  const handleConfirmSubscriptionChange = () => {
    confirmSubscriptionChange();
  };

  return (
    <div className="h-4/5 flex-grow flex w-full space-y-4 py-8 mx-auto max-w-xl items-center justify-center">
      <Card className="w-full" title="Review Subscription Change" isLoading={isLoading}>
        <div className="flex flex-col space-y-8 md:p-12 py-12 p-6 w-full mx-auto text-center">
          {isError ? (
            <div className="text-red-600 space-y-2">
              <p>There was an error looking up your subscription change.</p>
              <p>The changes may have been deleted already.</p>
            </div>
          ) : (
            <>
              {publication && <PublicationDetails publication={publication} />}
              <p className="text-gray-700">View and confirm your {subscriptionChange?.field} change below.</p>
              <div className="grid grid-cols-1 md:grid-cols-2 text-gray-700 text-start space-y-2 md:space-y-0">
                <p>From:</p>
                <p>{subscriptionChange?.old_value}</p>
                <p>To:</p>
                <p>{subscriptionChange?.new_value}</p>
              </div>

              {!subscriptionChange?.confirmed_at ? (
                <div className="pt-8 grid grid-cols-1 md:grid-cols-2 space-y-2 space-x-0 md:space-y-0 md:space-x-2">
                  <Button
                    onClick={handleCancelSubscriptionChange}
                    variant="primary-inverse"
                    size="base"
                    className="w-full"
                  >
                    Cancel Change
                  </Button>
                  <Button onClick={handleConfirmSubscriptionChange} variant="primary" size="base" className="w-full">
                    Confirm Change
                  </Button>
                </div>
              ) : (
                <div className="pt-8 text-gray-700">
                  <p>Your changes have been confirmed.</p>
                  {publication && (
                    <p>
                      Return to{' '}
                      <a href={publication.url} className="underline">
                        {publication?.name || 'publication'}
                      </a>
                    </p>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ConfirmSubscriptionChanges;
