import { OptionsDropdownProps } from '@/components/OptionsDropdown';
import { useCurrentPublication } from '@/hooks';
import { PostPreview } from '@/interfaces/post';

import ListPost from './Post';

type PostProps = {
  posts: PostPreview[];
  featuredPostsIds: string[];
  postRoute: (post: PostPreview) => string;
  postOptions: (post: PostPreview) => OptionsDropdownProps['options'];
  selectedPosts: string[];
  handlePostSelect: (post_id: string) => (checked: boolean) => void;
};

const List = (props: PostProps) => {
  const { posts, featuredPostsIds, postRoute, postOptions, selectedPosts, handlePostSelect } = props;
  const { data: currentPublication } = useCurrentPublication();

  return (
    <ul className="divide-y rounded">
      {currentPublication &&
        posts.map((post) => (
          <ListPost
            key={post.id}
            post={post}
            publication={currentPublication}
            postRoute={postRoute}
            featured={featuredPostsIds?.includes(post.id)}
            selectedPosts={selectedPosts}
            handlePostSelect={handlePostSelect}
            postOptions={postOptions}
          />
        ))}
    </ul>
  );
};

export default List;
