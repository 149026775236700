import cx from "classnames";

interface Props {
  className?: string;
}

const FacebookIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={cx(className)}
    >
      <path
        d="M14.125 2.5H11.875C10.8804 2.5 9.92658 2.89508 9.22338 3.59835C8.52009 4.30161 8.125 5.25544 8.125 6.25V8.5H5.875V11.5H8.125V17.5H11.125V11.5H13.375L14.125 8.5H11.125V6.25C11.125 6.05109 11.2041 5.86032 11.3447 5.71967C11.4853 5.57902 11.6761 5.5 11.875 5.5H14.125V2.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FacebookIcon;
