import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostOffer } from '@/interfaces/boosts/grow/boost_offer';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  boost_offers: BoostOffer[];
  pagination: Pagination;
}

interface Props {
  search?: string;
  perPage?: number;
  status?: string | string[];
  isEnabled?: boolean;
}

const useBoostOffers = ({ search, perPage = 10, status, isEnabled = true }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostOffers = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/boosts/grow/boost_offers`, {
        params: {
          page: pageParam,
          per_page: perPage,
          publication_id: currentPublicationId,
          q: search,
          status,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery([currentPublicationId, 'boosts', 'grow', 'boost_offers', status, search], fetchBoostOffers, {
    enabled: isEnabled,
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
};

export default useBoostOffers;
