import { useState } from 'react';

import LoadingBox from '@/components/LoadingBox';
import { usePostPollFeedback } from '@/hooks';
import { PostPoll } from '@/interfaces/post_poll';

import Feedback from './Feedback';

interface Props {
  publicationId: string;
  postPoll: PostPoll;
}

export interface Filters {
  [key: string]: string;
}

const FeedbackLoader = ({ publicationId, postPoll }: Props) => {
  const [filters, setFilters] = useState<Filters>({});

  const feedbackQuery = usePostPollFeedback(publicationId, {
    id: postPoll.id,
    filters,
  });

  const { data, isLoading, isError, fetchNextPage, hasNextPage } = feedbackQuery;

  const handleSetFilters = (newFilters: Filters) => {
    setFilters(newFilters);
  };

  const extendedFeedback = data?.pages.flatMap((page) => page.extended_feedback) || [];

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      {data && (
        <Feedback
          feedback={extendedFeedback}
          pollChoices={postPoll.poll_choices}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          setFilters={handleSetFilters}
          filters={filters}
        />
      )}
    </LoadingBox>
  );
};

export default FeedbackLoader;
