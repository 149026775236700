import { FC } from 'react';
import cx from 'classnames';

import { Publication } from '../../../../../interfaces/publication';

interface Props {
  currentPublicationId: string;
  publication: Publication;
  onSelectOption: (publication: Publication) => void;
}

const PublicationOption: FC<Props> = (props) => {
  const { currentPublicationId, publication, onSelectOption } = props;
  const isCurrent = currentPublicationId === publication.id;

  return (
    <li
      className={cx(
        'hover:bg-primary-50 hover:text-primary-600 select-none relative py-2 pl-3 pr-2 cursor-pointer',
        isCurrent ? 'bg-primary-50 text-primary-600' : ''
      )}
      role="option"
      aria-selected="true"
      onClick={() => onSelectOption(publication)}
      onKeyDown={() => onSelectOption(publication)}
      key={publication.id}
    >
      <div className="flex items-center">
        <div className="w-4 h-4 min-w-4 min-h-4 rounded overflow-hidden">
          {publication.logo.thumb.url ? (
            <img className="h-4 w-4" src={publication.logo.thumb.url} alt="" />
          ) : (
            <div className="bg-gray-200 h-4 w-4 rounded" />
          )}
        </div>
        <div className="mr-2 flex-1">
          <span className="ml-2 block">{publication.name}</span>
        </div>
      </div>
    </li>
  );
};

export default PublicationOption;
