import { useMemo } from 'react';
import { NavbarSerializableNode } from '@shared/dream-components';

import BoxModelToggle from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/BoxModelToggle';
import { parseCssValue } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/parseCssValue';

import { useNavbarContext } from '../../../NavbarContext';

type PaddingValue = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type PaddingSettingsProps = {
  selectedContent: NavbarSerializableNode;
  attribute: string;
  defaultValue: string;
};

const getPaddingFromValue = (value: string, unit: string, defaultPadding: string) => {
  let nodePadding = value;

  if (!nodePadding) {
    nodePadding = defaultPadding;
  }

  // padding is always 4 numbers top, right, bottom, left
  let paddingArray = nodePadding.split(' ');
  if (paddingArray.length === 2) {
    paddingArray = [paddingArray[0], paddingArray[1], paddingArray[0], paddingArray[1]];
  } else if (paddingArray.length === 3) {
    paddingArray = [paddingArray[0], paddingArray[1], paddingArray[2], paddingArray[1]];
  } else if (paddingArray.length === 1) {
    paddingArray = [paddingArray[0], paddingArray[0], paddingArray[0], paddingArray[0]];
  }

  const top = paddingArray[0];
  const right = paddingArray[1];
  const bottom = paddingArray[2];
  const left = paddingArray[3];

  return {
    top: parseCssValue(top, unit).value,
    right: parseCssValue(right, unit).value,
    bottom: parseCssValue(bottom, unit).value,
    left: parseCssValue(left, unit).value,
  };
};

export const PaddingSettings = ({ selectedContent, attribute, defaultValue }: PaddingSettingsProps) => {
  const { onUpdateNodeAttributes } = useNavbarContext();
  const unit = 'px';

  const contentValue =
    selectedContent?.attrs && attribute in selectedContent.attrs
      ? (selectedContent.attrs[attribute as keyof typeof selectedContent.attrs] as string) || defaultValue
      : defaultValue;

  const padding = useMemo(
    () => getPaddingFromValue(contentValue, unit, defaultValue),
    [contentValue, unit, defaultValue]
  );

  if (!selectedContent || !selectedContent.attrs?.id) return null;

  const updatePadding = (side: 'top' | 'right' | 'bottom' | 'left' | 'all', value: number) => {
    if (!selectedContent.attrs?.id) return;
    const p = { ...padding, [side]: value } as PaddingValue;

    if (side === 'all') {
      onUpdateNodeAttributes(selectedContent.attrs.id, {
        [attribute]: `${value}${unit} ${value}${unit} ${value}${unit} ${value}${unit}`,
      });
    } else {
      onUpdateNodeAttributes(selectedContent.attrs.id, {
        [attribute]: `${p.top}${unit} ${p.right}${unit} ${p.bottom}${unit} ${p.left}${unit}`,
      });
    }
  };

  return (
    <BoxModelToggle
      title="Padding"
      defaultValues={{
        top: padding?.top || 0,
        right: padding?.right || 0,
        bottom: padding?.bottom || 0,
        left: padding?.left || 0,
      }}
      suffixes={{
        top: 't',
        right: 'r',
        bottom: 'b',
        left: 'l',
      }}
      onUpdate={updatePadding}
    />
  );
};
