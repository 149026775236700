import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

interface CreateAffiliateParams {
  partnerType: string;
}

const useCreateAffiliate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ partnerType }: CreateAffiliateParams) => api.post('/partner_details', { partner_type: partnerType }),
    {
      onSuccess: () => {
        toast.success('Successfully created affiliate');
        queryClient.invalidateQueries('partner_details');
      },
      onMutate: () => {
        toast.loading('Creating partner link...');
      },
      onError: (error: Error) => {
        if (error instanceof Error) {
          toast.error('Failed to create affiliate');
        } else {
          toast.error('An unknown error occurred');
        }
      },
    }
  );
};

export default useCreateAffiliate;
