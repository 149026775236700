import {
  AdNetworkCampaignOpportunityGroup,
  AdNetworkCampaignOpportunityGroupStatus,
} from '@/interfaces/ad_network/internal/campaign_opportunity_group';

import StatusIndicator from './StatusIndicator';

const CampaignOpportunityGroupStatusIndicator = ({
  campaignOpportunityGroup,
}: {
  campaignOpportunityGroup: AdNetworkCampaignOpportunityGroup;
}) => {
  const color = {
    [AdNetworkCampaignOpportunityGroupStatus.DRAFT]: 'gray',
    [AdNetworkCampaignOpportunityGroupStatus.STAGING]: 'yellow',
    [AdNetworkCampaignOpportunityGroupStatus.WORKING]: 'blue',
    [AdNetworkCampaignOpportunityGroupStatus.PROVISIONED]: 'green',
  }[campaignOpportunityGroup.status] as 'blue' | 'gray' | 'green' | 'yellow';

  return <StatusIndicator color={color} label={campaignOpportunityGroup.status} />;
};

export default CampaignOpportunityGroupStatusIndicator;
