import { IconComponent } from '@heroicons/react';
import { CheckCircleIcon, QuestionMarkCircleIcon, UsersIcon } from '@heroicons/react/24/solid';

import LifetimeMetricCard from '@/components/KPI/LifetimeMetricCard';

const Summary = ({ form }: any) => {
  const metrics: {
    label: string;
    valueSuffix?: string;
    icon: IconComponent;
    value: number | string;
  }[] = [
    {
      label: 'Total Questions',
      value: form?.question_count,
      icon: QuestionMarkCircleIcon,
    },
    {
      label: 'Total Responses',
      value: form?.total_response_count,
      icon: UsersIcon,
    },
    {
      label: 'Completion Rate',
      valueSuffix: '%',
      value: form?.completion_rate,
      icon: CheckCircleIcon,
    },
  ];

  return (
    <div className="flex flex-col md:flex-row justify-evenly gap-x-6 gap-y-2">
      {metrics.map((metric) => (
        <LifetimeMetricCard
          key={`lifetime-metric-${metric.label}`}
          label={metric.label}
          value={metric.value}
          icon={metric.icon}
          valueSuffix={metric.valueSuffix}
        />
      ))}
    </div>
  );
};

export default Summary;
