import {
  AutomationConditionName,
  AutomationConditionOperator,
  AutomationConditions,
} from '@/interfaces/automations/automation_condition';
import { AutomationTriggerEvent } from '@/interfaces/automations/automation_trigger';
import { LogicalOperators } from '@/interfaces/condition';
import { Option } from '@/interfaces/general';

export const LABEL_BY_EVENTS_TYPE: { [key in AutomationTriggerEvent]: string } = {
  [AutomationTriggerEvent.API]: 'Add by API',
  [AutomationTriggerEvent.DOWNGRADE]: 'Downgraded',
  [AutomationTriggerEvent.EMAIL_SUBMISSION]: 'Email Submitted',
  [AutomationTriggerEvent.FORM_SUBMISSION]: 'Survey Submitted',
  [AutomationTriggerEvent.MANUAL]: 'Manual',
  [AutomationTriggerEvent.POLL_SUBMISSION]: 'Poll Response',
  [AutomationTriggerEvent.REFERRAL_ACTION]: 'Referral Action',
  [AutomationTriggerEvent.SEGMENT_ACTION]: 'Segment Action',
  [AutomationTriggerEvent.SIGNUP]: 'Signed Up',
  [AutomationTriggerEvent.UNENGAGED]: 'Unengaged',
  [AutomationTriggerEvent.UPGRADE]: 'Upgraded',
};

export const AutomationTriggerOptions: Option[] = [
  { label: 'Subscription Events', value: '', isOptGroup: true },
  { label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.SIGNUP], value: AutomationTriggerEvent.SIGNUP },
  {
    label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.EMAIL_SUBMISSION],
    value: AutomationTriggerEvent.EMAIL_SUBMISSION,
  },
  {
    label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.FORM_SUBMISSION],
    value: AutomationTriggerEvent.FORM_SUBMISSION,
  },
  {
    label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.POLL_SUBMISSION],
    value: AutomationTriggerEvent.POLL_SUBMISSION,
  },
  {
    label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.REFERRAL_ACTION],
    value: AutomationTriggerEvent.REFERRAL_ACTION,
  },
  {
    label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.SEGMENT_ACTION],
    value: AutomationTriggerEvent.SEGMENT_ACTION,
  },
  { label: 'Paid Subscriptions', value: '', isOptGroup: true },
  { label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.UPGRADE], value: AutomationTriggerEvent.UPGRADE },
  { label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.DOWNGRADE], value: AutomationTriggerEvent.DOWNGRADE },
  { label: 'Other', value: '', isOptGroup: true },
  { label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.API], value: AutomationTriggerEvent.API },
  { label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.MANUAL], value: AutomationTriggerEvent.MANUAL },
  { label: LABEL_BY_EVENTS_TYPE[AutomationTriggerEvent.UNENGAGED], value: AutomationTriggerEvent.UNENGAGED },
];

export const AutomationTriggerDescriptions: { [key in AutomationTriggerEvent]: string } = {
  [AutomationTriggerEvent.API]: 'Triggered when calling the API to add a subscriber to this automation.',
  [AutomationTriggerEvent.DOWNGRADE]:
    'Triggered when a subscriber downgrades from the paid tier to the free tier of your newsletter.',
  [AutomationTriggerEvent.EMAIL_SUBMISSION]:
    'Triggered when a subscriber submits their email address in a subscribe form. Existing subscribers qualify.',
  [AutomationTriggerEvent.FORM_SUBMISSION]: 'Triggered when a subscriber submits a Survey.',
  [AutomationTriggerEvent.POLL_SUBMISSION]: 'Triggered when a subscriber submits a Poll Response.',
  [AutomationTriggerEvent.REFERRAL_ACTION]: 'Triggered when a subscriber reaches a number of referrals.',
  [AutomationTriggerEvent.SEGMENT_ACTION]: 'Triggered when a subscriber enters or exits a Segment.',
  [AutomationTriggerEvent.SIGNUP]: 'Triggered when a new subscriber signs up for your newsletter.',
  [AutomationTriggerEvent.UPGRADE]: 'Triggered when a subscriber upgrades to the paid tier of your newsletter.',
  [AutomationTriggerEvent.MANUAL]:
    'This placeholder trigger allows you to add subscribers to this automation manually. The trigger must be activated in order to add subscribers.',
  [AutomationTriggerEvent.UNENGAGED]:
    "This trigger runs daily and adds subscribers to this automation that haven't engaged with your newsletter according to the specified conditions. Excludes subscribers that signed up within the conditions window.",
};

export const AutomationTriggerMainConditionsDefaults: { [key in AutomationTriggerEvent]?: AutomationConditions } = {
  downgrade: {
    logical_operator: LogicalOperators.AND,
    conditions: [
      {
        name: AutomationConditionName.TIER_ID,
        operator: AutomationConditionOperator.EQUAL,
        value: 'any',
        hasErrors: false,
      },
    ],
  },
  email_submission: {
    logical_operator: LogicalOperators.AND,
    conditions: [
      {
        name: AutomationConditionName.EMAIL_SUBMISSION_SOURCE,
        operator: AutomationConditionOperator.EQUAL,
        value: 'any',
        hasErrors: false,
      },
    ],
  },
  form_submission: {
    logical_operator: LogicalOperators.AND,
    conditions: [
      {
        name: AutomationConditionName.FORM_ID,
        operator: AutomationConditionOperator.EQUAL,
        value: 'any',
        hasErrors: false,
      },
    ],
  },
  poll_submission: {
    logical_operator: LogicalOperators.AND,
    conditions: [
      {
        name: AutomationConditionName.POLL_ID,
        operator: AutomationConditionOperator.EQUAL,
        value: 'any',
        hasErrors: false,
      },
      {
        name: AutomationConditionName.POLL_CHOICE_ID,
        operator: AutomationConditionOperator.EQUAL,
        value: 'any',
        hasErrors: false,
      },
    ],
  },
  referral_action: {
    logical_operator: LogicalOperators.AND,
    conditions: [
      {
        name: AutomationConditionName.REFERRAL_ACTION,
        operator: AutomationConditionOperator.EQUAL,
        value: '10',
        hasErrors: false,
      },
    ],
  },
  segment_action: {
    logical_operator: LogicalOperators.AND,
    conditions: [
      {
        name: AutomationConditionName.SEGMENT_ID,
        operator: AutomationConditionOperator.EXISTS,
        value: '',
        hasErrors: false,
      },
    ],
  },
  unengaged: {
    logical_operator: LogicalOperators.AND,
    conditions: [
      {
        name: AutomationConditionName.LAST_OPENED_OR_CLICKED_DAYS_AGO,
        operator: AutomationConditionOperator.GREATER_THAN_EQUAL_TO,
        value: '21',
        hasErrors: false,
      },
    ],
  },
  upgrade: {
    logical_operator: LogicalOperators.AND,
    conditions: [
      {
        name: AutomationConditionName.TIER_ID,
        operator: AutomationConditionOperator.EQUAL,
        value: 'any',
        hasErrors: false,
      },
    ],
  },
};
