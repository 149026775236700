import Tippy from '@tippyjs/react';

import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';

import { usePostContext } from '../../PostContext';
import ThumbnailActionsMenu from '../ThumbnailActionsMenu';

interface Props {
  fileRef: React.RefObject<HTMLInputElement>;
  setShowImageLibrary: (value: boolean) => void;
}

const ThumbnailTooltipMenu = ({ fileRef, setShowImageLibrary }: Props) => {
  const { onChange } = usePostContext();
  const deleteThumbnail = () => {
    onChange({ thumbnail_id: 'delete', thumbnail: null });
  };

  return (
    <div className="flex h-[3rem] bg-gray-800 rounded-lg p-2">
      <Tippy
        offset={[0, 8]}
        placement="bottom-start"
        popperOptions={{
          modifiers: [{ name: 'flip' }],
        }}
        trigger="click"
        interactive
        content={
          <div className="absolute">
            <ThumbnailActionsMenu fileRef={fileRef} setShowImageLibrary={setShowImageLibrary} />
          </div>
        }
        zIndex={EDITOR_MENUS_Z_INDEX}
      >
        <Button $variant="quaternary" $leftSlot={<Icon name="Refresh" className="mr-2" />} />
      </Tippy>
      <Button $variant="quaternary" $leftSlot={<Icon name="Trash" />} onClick={deleteThumbnail} />
    </div>
  );
};

export default ThumbnailTooltipMenu;
