import ProtectedAction from '@/components/ProtectedAction';
import { useCancelAllPremiumSubscribers, useDisconnectStripe, usePremiumSettings } from '@/hooks/usePublications';
import Section from '@/routes/settings/_components/Section';

const CancellationSection = () => {
  const { data: premiumSettings } = usePremiumSettings();
  const { premium_subscription_count: premiumSubCount = 0 } = premiumSettings || {};

  const { mutate: cancelAllSubscriptions, isLoading: isCancelling } = useCancelAllPremiumSubscribers();
  const { mutate: disconnectStripe, isLoading: isDisconnecting } = useDisconnectStripe();

  return (
    <Section
      title="Cancellation Actions"
      description="Please note, these actions are irreversible and may take a few minutes to complete."
      maxWidthClassName="max-w-4xl"
      scrollToId="cancellation-actions"
    >
      <div className="flex flex-col space-y-4 max-w-xl">
        {premiumSubCount > 0 && (
          <ProtectedAction
            buttonText="Cancel Paid Subscriptions"
            isSubmitting={isCancelling}
            onProceed={cancelAllSubscriptions}
            resourceId="cancel_stripe_subscriptions"
            modalDescription="You are about to cancel all premium stripe subscriptions for this publication. Any paid subscriptions will be immediately downgraded to the free tier. (Please note: this action cannot be undone and may take a few minutes to complete.)"
          />
        )}
        <ProtectedAction
          buttonText="Disconnect Stripe Account"
          isSubmitting={isDisconnecting}
          onProceed={disconnectStripe}
          resourceId="disconnect_stripe_account"
          modalDescription="You are about to disconnect your stripe account for this publication. All of your tiers and prices will be deleted. Any paid subscriptions will be immediately downgraded to the free tier. (Please note: this action cannot be undone and may take a few minutes to complete.)"
        />
      </div>
    </Section>
  );
};

export default CancellationSection;
