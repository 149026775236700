import { useEffect, useState } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

import { SortableList, SortableListItem } from '@/components/SortableList';
import { LandingPage } from '@/interfaces/landing_page';
import { Testimonial } from '@/interfaces/testimonial';
import { UpgradePage } from '@/interfaces/upgrade_page';
import OptionsDropdown from '@/routes/website_builder/_components/Helpers/OptionsDropdown';

import orderTestimonials from '../../../../../../pages/DesignLab/utils/orderTestimonials';

interface Props {
  testimonialsOrder: string[];
  testimonials: Testimonial[];
  page: LandingPage | UpgradePage;
  handleSave: (payload: any) => void;
  setTestimonialToRemove: (id: string) => void;
  setTestimonialToEdit: (testimonial: any) => void;
}

const Testimonials = ({
  testimonials,
  page,
  testimonialsOrder,
  handleSave,
  setTestimonialToRemove,
  setTestimonialToEdit,
}: Props) => {
  const [list, setList] = useState<any>(orderTestimonials(testimonialsOrder, testimonials));
  const hasTestimonials = list.length > 0;
  const listsLengthMismatch = testimonials.length !== list.length;

  useEffect(() => {
    if (listsLengthMismatch) {
      setList(orderTestimonials(testimonialsOrder, testimonials));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listsLengthMismatch]);

  const onDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activePostitionId = list.find((position: any) => position.id === active.id);
      const overPostitionId = list.find((position: any) => position.id === over.id);
      const oldIndex = list.indexOf(activePostitionId);
      const newIndex = list.indexOf(overPostitionId);
      const newList = arrayMove(list, oldIndex, newIndex);
      setList(newList);
      handleSave({
        ...page?.testimonials_section,
        testimonials: newList.map((testimonial: any) => testimonial.testimonial_id),
      });
    }
  };

  return hasTestimonials ? (
    <SortableList listItems={list} onDragEndEvent={onDragEnd}>
      {(listItems: any) => {
        return listItems.map((item: any) => {
          return (
            <SortableListItem
              className="!py-1"
              key={item.question}
              listItem={item}
              onRemoveItem={(itemToRemove: any) => {
                setTestimonialToRemove(itemToRemove.id);
              }}
              text={item.name}
              actionChild={
                <div className="pr-1">
                  <OptionsDropdown
                    options={[
                      {
                        label: 'Edit',
                        Icon: PencilIcon,
                        onClick: () => setTestimonialToEdit(item),
                      },
                      { label: 'Delete', Icon: TrashIcon, onClick: () => setTestimonialToRemove(item.id) },
                    ]}
                  />
                </div>
              }
            />
          );
        });
      }}
    </SortableList>
  ) : null;
};

export default Testimonials;
