import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { ContentTag } from '../interfaces/content_tag';
import api from '../services/swarm';

export default function useContentTag(id: string) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchContentTag = () =>
    api
      .get(`/content_tags/${id}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<ContentTag>(['content_tags', id], fetchContentTag, {
    keepPreviousData: true,
  });
}
