import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

import ActivityFeed from './_components/ActivityFeed';
import { useSearchContext } from './_context/searchContext';
import { useSubscriberContext } from './_context/subscriberContext';
import { usePostActivityFeedEvents } from './_hooks/useActivityFeedEvents';

const SubscriberPostActivity = () => {
  const { search, eventType } = useSearchContext();
  const { subscription } = useSubscriberContext();
  const query = usePostActivityFeedEvents({ search, subscription, eventType });

  return (
    <ActivityFeed
      title="Posts Activity Feed"
      emptyText="No post events for this subscriber have been recorded yet."
      query={query}
      eventTypeOptions={[
        ActivityFeedEventType.SENT,
        ActivityFeedEventType.DELIVERED,
        ActivityFeedEventType.OPEN,
        ActivityFeedEventType.CLICK,
        ActivityFeedEventType.UNSUBSCRIBE,
        ActivityFeedEventType.SPAM_REPORT,
        ActivityFeedEventType.DEFERRAL,
        ActivityFeedEventType.DELIVERY_ERROR,
        ActivityFeedEventType.COMMENT,
      ]}
    />
  );
};

export default SubscriberPostActivity;
