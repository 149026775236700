import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { useAsyncExport } from '../../../hooks';
import { useForm, useFormInsights } from '../../../hooks/useForms';
import FormsHeader from '../Header';

import Responses from './Responses';

interface Props {
  isNewLayout?: boolean;
}

const Show = ({ isNewLayout }: Props) => {
  const { formId } = useParams();
  const { data: form } = useForm(formId || '');

  const paginatedFormResponsesQuery = useFormInsights({ search: '', formId: formId || '' });
  const { data, isLoading } = paginatedFormResponsesQuery;
  const formResponses = data?.pages.flatMap((page: any) => page.form_responses) || [];
  const isNoResults = !isLoading && formResponses.length === 0;

  const currentPublicationId = useCurrentPublicationId();
  const { startExport } = useAsyncExport(currentPublicationId, {
    exportType: 'form_responses',
    resourceType: 'form',
    resourceId: formId || '',
    fileName: `${form?.title}-${new Date().toISOString().split('T')[0]}.csv`,
  });

  return (
    <div className={isNewLayout ? 'mt-40 py-5 px-4 md:px-10 md:mt-28 md:mb-36' : ''}>
      {form && data && <FormsHeader form={form} formData={data?.pages?.[0]?.form} isNewLayout={isNewLayout} />}
      <div className="space-y-8">
        <div>
          {!isNoResults && (
            <div className="flex justify-end mb-2">
              <div className="text-sm text-primary-500 underline underline-primary-500 flex items-center">
                <button
                  type="button"
                  onClick={() => {
                    startExport();
                    toast.success('Downloading results, please wait...');
                  }}
                  className="text-sm text-primary-500 underline underline-primary-500 flex items-center"
                >
                  <ArrowDownTrayIcon className="w-4 h-4 mr-1 underline underline-primary-500" />
                  <span>Download results</span>
                </button>
              </div>
            </div>
          )}
          <Responses
            formId={formId || ''}
            form={form}
            paginatedFormResponsesQuery={paginatedFormResponsesQuery}
            questionCount={data?.pages?.[0]?.form?.question_count}
            isNewLayout={isNewLayout}
          />
        </div>
      </div>
    </div>
  );
};

export default Show;
