import { useEffect, useState } from 'react';
import ReactFlow, { Background, Controls, EdgeTypes, MiniMap, NodeTypes, useReactFlow } from 'reactflow';

import IntersectionPanels from '@/components/IntersectionPanels';
import { FormStatus } from '@/interfaces/form';
import { zoomToReactFlowNode } from '@/utils/zoomToReactFlowNode';

import { useEditFormViewContent } from '..';

import CustomAddQuestionNode from './CustomAddQuestionNode';
import CustomEdge from './CustomEdge';
import CustomQuestionNode from './CustomQuestionNode';
import {
  ADD_QUESTION_NODE_ID,
  ADD_QUESTION_NODE_TYPE,
  assignPositionToNodes,
  CUSTOM_EDGE_TYPE,
  CUSTOM_NODE_TYPE,
  getLaidOutElements,
  getNodesAndEdges,
  RE_LAY_TIMEOUT,
} from './helpers';

const nodeTypes: NodeTypes = {
  [CUSTOM_NODE_TYPE]: CustomQuestionNode,
  [ADD_QUESTION_NODE_TYPE]: CustomAddQuestionNode,
};

const edgeTypes: EdgeTypes = {
  [CUSTOM_EDGE_TYPE]: CustomEdge,
};

const FormBuilder = () => {
  const { fitView } = useReactFlow();
  const [hasInitialised, setHasInitialised] = useState(false);
  const { form, isSorting, isFormLoading, isFormError } = useEditFormViewContent();
  const isSelectingAutomationStep = false;
  const handleInitialise = () => setHasInitialised(true);

  const { setNodes, setEdges, getZoom } = useReactFlow();

  const canAddNewQuestion = form.status === FormStatus.DRAFT;

  // Re-paint layout when Form Questions are added/deleted
  // and/or React Flow has initialised
  useEffect(() => {
    if (hasInitialised && !isFormLoading && !isFormError && form.form_questions) {
      const { nodes: translatedNodes, edges: translatedEdges } = getNodesAndEdges(
        form.form_questions,
        canAddNewQuestion
      );

      setNodes(translatedNodes);
      setEdges(translatedEdges);

      window.requestAnimationFrame(() => {
        setTimeout(() => {
          const { nodes: laidOutNodes, edges: laidOutEdges } = getLaidOutElements(
            translatedNodes,
            translatedEdges,
            getZoom()
          );

          setNodes(assignPositionToNodes(laidOutNodes));
          setEdges(laidOutEdges);

          if (form.form_questions.length === 0) {
            zoomToReactFlowNode(ADD_QUESTION_NODE_ID, fitView);
          }
        }, RE_LAY_TIMEOUT);
      });
    }
  }, [hasInitialised, form.form_questions, isFormLoading, isFormError]);

  return (
    <div className="fixed w-full h-full">
      <IntersectionPanels isActive={isSorting}>
        <ReactFlow
          defaultNodes={[]}
          defaultEdges={[]}
          onInit={handleInitialise}
          proOptions={{
            account: 'paid-pro',
            hideAttribution: true,
          }}
          panOnDrag={!isSelectingAutomationStep && !isSorting}
          panOnScroll={!isSelectingAutomationStep}
          zoomOnScroll={!isSelectingAutomationStep}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
          fitViewOptions={{ padding: 1.1 }}
          minZoom={0.35}
          maxZoom={1}
          nodesDraggable={false}
          nodesConnectable={false}
          zoomOnDoubleClick={false}
        >
          <Controls position="bottom-left" />
          <MiniMap className="hidden sm:block" />
          <Background />
        </ReactFlow>
      </IntersectionPanels>
    </div>
  );
};

export default FormBuilder;
