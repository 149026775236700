import Banner from '@/components/Banner';
import { RewardType } from '@/interfaces/reward';
import rewardTypeLabels from '@/values/rewardType';

interface Props {
  rewardType: RewardType;
}

const EmailContentBanner = ({ rewardType }: Props) => {
  switch (rewardType) {
    case 'physical':
      return null;
    case 'digital':
      return (
        <Banner
          isScreenWide={false}
          title={`${rewardTypeLabels[rewardType]} Reward`}
          bodyText="A file attachment was added to the email content automatically"
        />
      );
    case 'promo_code':
      return (
        <Banner
          isScreenWide={false}
          title={`${rewardTypeLabels[rewardType]} Reward`}
          bodyText="The merge tag {{reward_promo_code}} was added to the email content automatically"
        />
      );
    default:
      return null;
  }
};

export default EmailContentBanner;
