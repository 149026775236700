import toast from "react-hot-toast";
import { useMutation,useQueryClient } from "react-query";

import api from "@/services/swarm";
import analytics from "@/utils/analytics";

import { InviteFormRowData } from "../_interfaces/invite";

interface MutationProps {
  invites: InviteFormRowData[];
  publicationId: string;
}

const useCreateBulkInvite = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({invites, publicationId}: MutationProps) =>
      api.post(`/invites/bulk_creates`, {
        publication_id: publicationId,
        invites: invites.map((invite) => ({
          email: invite.email,
          role_name: invite.role,
          permission_level: invite.permissions,
          publication_ids: invite.publications
        })),
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (res) => {
        try {
          const { invite } = res.data;
          analytics.track('Account Added User', { role_id: invite?.role_id });
          queryClient.invalidateQueries(['invites', organizationId]);
          queryClient.invalidateQueries([organizationId, 'team_members']);
          toast.success('Invites were sent successfully!');
        } catch {
          toast.error('Please try again');
        }
      },
    }
  );
}

export default useCreateBulkInvite;
