import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import styled from 'styled-components';

import { Button } from '../../../../../ui/Button';
import { Icon } from '../../../../../ui/Icon';
import { InputField } from '../../../../../ui/Input';
import { PanelHeadline, PanelSection } from '../../../../../ui/Panel';
import { convertToSection } from '../../../../utils';
import useVisualSettingsPanel from '../VisualSettingsPanel.hooks';

const Styled = {
  Grid: styled.div`
    display: flex;
    gap: 0.25rem;
    padding-inline: 0.375rem;
  `,
  IndividualMarginSwitch: styled.div`
    flex: 0 0 auto;
  `,
  InputGroup: styled.div`
    display: grid;
    flex: 1 1 auto;
    gap: 0.25rem;
    grid-template-columns: repeat(2, 1fr);
  `,
  InputWrapper: styled.div<{ half?: boolean }>`
    flex: 1 1 auto;
  `,
};

export const Margin = ({
  editor,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  currentNode: Node | null;
  currentNodePos: number;
}) => {
  const [margin, setMargin] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  const { onSetMargin } = useVisualSettingsPanel(editor, currentNode, currentNodePos, () => {});

  useEffect(() => {
    setMargin({
      top: currentNode?.attrs.marginTop || 0,
      bottom: currentNode?.attrs.marginBottom || 0,
      left: currentNode?.attrs.marginLeft || 0,
      right: currentNode?.attrs.marginRight || 0,
    });
  }, [
    currentNode?.attrs.marginTop,
    currentNode?.attrs.marginBottom,
    currentNode?.attrs.marginLeft,
    currentNode?.attrs.marginRight,
  ]);

  const handleMarginChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMargin({
      top: Number(e.target.value) || 0,
      bottom: Number(e.target.value) || 0,
      left: Number(e.target.value) || 0,
      right: Number(e.target.value) || 0,
    });
  }, []);

  const toggleIndividualMargin = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);

    editor.chain().toggleIndividualSectionMargin().run();
  }, [editor, currentNode, currentNodePos]);

  const updateMargin = useCallback(() => {
    onSetMargin(margin);
  }, [margin]);

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, newMargin: any) => {
      event.stopPropagation();

      if (event.key === 'Enter') {
        onSetMargin({ ...margin, ...newMargin });
      }

      return false;
    },
    [margin]
  );

  return (
    <PanelSection>
      <PanelHeadline>Outer spacing</PanelHeadline>
      <Styled.Grid>
        <Styled.IndividualMarginSwitch>
          <Button
            $isIconButton
            $active={currentNode?.attrs.useIndividualMargin}
            $muted
            type="button"
            onClick={toggleIndividualMargin}
            $size="small"
            $variant="quaternary"
            $leftSlot={<Icon name="Margin" />}
          />
        </Styled.IndividualMarginSwitch>
        {currentNode?.attrs.useIndividualMargin ? (
          <Styled.InputGroup>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setMargin({ ...margin, top: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { top: Number(e.currentTarget.value) })}
                onBlur={updateMargin}
                type="number"
                icon="MarginTop"
                suffix="px"
                value={margin.top.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setMargin({ ...margin, bottom: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { bottom: Number(e.currentTarget.value) })}
                onBlur={updateMargin}
                type="number"
                icon="MarginBottom"
                suffix="px"
                value={margin.bottom.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setMargin({ ...margin, left: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { left: Number(e.currentTarget.value) })}
                onBlur={updateMargin}
                type="number"
                icon="MarginLeft"
                suffix="px"
                value={margin.left.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setMargin({ ...margin, right: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { right: Number(e.currentTarget.value) })}
                onBlur={updateMargin}
                type="number"
                icon="MarginRight"
                suffix="px"
                value={margin.right.toString()}
              />
            </Styled.InputWrapper>
          </Styled.InputGroup>
        ) : (
          <Styled.InputWrapper>
            <InputField
              min="0"
              onChange={handleMarginChange}
              onKeyUp={(e) =>
                handleKeyUp(e, {
                  top: Number(e.currentTarget.value) || 0,
                  bottom: Number(e.currentTarget.value) || 0,
                })
              }
              onBlur={updateMargin}
              type="number"
              suffix="px"
              value={margin.top.toString()}
            />
          </Styled.InputWrapper>
        )}
      </Styled.Grid>
    </PanelSection>
  );
};

export default Margin;
