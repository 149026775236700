const AnswerLinks = () => (
  <>
    Want answers sooner? We just launched an expansive{' '}
    <a
      className="text-primary-600 underline"
      href="https://support.beehiiv.com/hc/en-us"
      target="_blank"
      rel="noopener noreferrer"
    >
      Knowledge Base
    </a>{' '}
    that should provide answers to 95% of all questions. We recommend checking{' '}
    <a
      className="text-primary-600 underline"
      href="https://support.beehiiv.com/hc/en-us"
      target="_blank"
      rel="noopener noreferrer"
    >
      there
    </a>{' '}
    first.
  </>
);

export default AnswerLinks;
