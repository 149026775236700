import { css } from 'styled-components';

import { colors } from '../../../lib/colors';
import { Styled as StyledIcon } from '../Icon/styled';

import { StyledButtonProps } from './types';

export const primaryButtonStyles = css<StyledButtonProps>`
  background-color: ${colors.black[1]};
  color: ${colors.white[1]};

  &:not(:disabled) {
    &:hover {
      background-color: ${colors.pink[5]};

      ${StyledIcon.Wrapper} svg,
      ${StyledIcon.Wrapper} svg path {
        fill: currentColor;
      }
    }

    &.is-active {
      background-color: ${colors.black[2]};

      ${StyledIcon.Wrapper} svg,
      ${StyledIcon.Wrapper} svg path {
        fill: ${colors.pink[6]};
      }
    }
  }

  &:disabled {
    background-color: ${colors.black[8]};
    color: ${colors.black[6]};

    ${StyledIcon.Wrapper} svg,
    ${StyledIcon.Wrapper} svg path {
      fill: currentColor;
    }
  }

  ${StyledIcon.Wrapper} svg,
  ${StyledIcon.Wrapper} svg path {
    fill: ${colors.pink[6]};
  }
`;

export default primaryButtonStyles;
