import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HexColorInput, RgbaColorPicker } from 'react-colorful';
import { getUsedColors } from '@shared/dream-components';

import { DreamEditorContext } from '@/context/dream-editor-context';

import { NavbarDataContext } from '../../navbar/_components/NavbarEditor/NavbarDataContext';

import { SimpleInput, SimpleInputWrapper } from './Input';
import { Text } from './Text';

import './ColorPicker.css';

export interface ColorPickerProps {
  selectedColor?: string;
  onSetColor: (color: string | null) => void;
  onReset?: () => void;
}

const convertHexToRgba = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  const a = hex.length === 9 ? parseInt(hex.slice(7, 9), 16) / 255 : 1;
  return { r, g, b, a };
};

const convertRgbaToHex = (rgba: { r: number; g: number; b: number; a?: number }, options?: { alpha?: boolean }) => {
  const { r, g, b, a = 1 } = rgba;
  const alpha = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0');

  if (options?.alpha) {
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
      .toString(16)
      .padStart(2, '0')}${alpha}`.toUpperCase();
  }
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`.toUpperCase();
};

export const ColorPicker = ({
  selectedColor = '#939393FF',
  onSetColor = () => null,
  onReset = () => null,
}: ColorPickerProps): JSX.Element => {
  const dreamEditorContext = useContext(DreamEditorContext);
  const navbarDataContext = useContext(NavbarDataContext);

  const rgbaSelectedColor = convertHexToRgba(selectedColor ?? '#939393FF');
  const [newColor, setNewColor] = useState(rgbaSelectedColor);
  const colorInputValue = useRef<string | undefined>(undefined);
  const [alpha, setAlpha] = useState(
    Number.isNaN(Math.round(rgbaSelectedColor.a * 100)) ? 100 : Math.round(rgbaSelectedColor.a * 100)
  );
  const [inPageColors, setInPageColors] = useState<string[]>([]);

  const dreamEditorColors = dreamEditorContext?.editor?.storage.colorStorage.usedColors;

  const lastSetColor = useRef(selectedColor);

  const handleSetColor = useCallback(
    (color: string, prevColor?: string) => {
      const rgbaColor = convertHexToRgba(color);

      setNewColor(rgbaColor);

      setAlpha(Math.round(rgbaColor.a * 100));

      if (dreamEditorContext) {
        const { editor } = dreamEditorContext;
        editor?.commands.setUsedColor(color, prevColor);
      }

      onSetColor(color);
    },
    [dreamEditorContext, onSetColor]
  );

  useEffect(() => {
    if (dreamEditorColors) {
      setInPageColors(
        Object.entries(dreamEditorColors || {})
          .filter(([, count]) => Number(count) > 0)
          .sort(([, a], [, b]) => Number(b) - Number(a))
          .map(([color]) => color)
      );
    }
  }, [dreamEditorColors]);

  useEffect(() => {
    if (navbarDataContext) {
      const { content } = navbarDataContext;
      const colors = content ? getUsedColors(content) : [];
      setInPageColors(colors);
    }
  }, [navbarDataContext]);

  useEffect(() => {
    const hexNewColor = convertRgbaToHex(newColor, { alpha: true });
    if (lastSetColor.current !== hexNewColor) {
      const prevColor = lastSetColor.current;
      lastSetColor.current = hexNewColor;
      setAlpha(Math.round(newColor.a * 100));
      handleSetColor(hexNewColor, prevColor);
    }
  }, [newColor, handleSetColor]);

  const handleAlphaChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      setAlpha(Number.isNaN(value) ? 0 : value);
    },
    [setAlpha]
  );

  const handleAlphaKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && alpha) {
        setNewColor((prev) => ({ ...prev, a: alpha / 100 }));
      }
    },
    [alpha, setNewColor]
  );

  const setNewHexColor = (hex: string) => {
    let h = hex;
    if (h.length === 4) {
      h = h[0] + h[1] + h[1] + h[2] + h[2] + h[3] + h[3];
    }
    colorInputValue.current = hex;
  };

  const handleHexKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const cInput = colorInputValue.current;
      if (e.key === 'Enter' && cInput) {
        setNewColor((prev) => {
          return { ...convertHexToRgba(cInput), a: prev.a };
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNewHexColor]
  );

  return (
    <div className="wb-react-colorful">
      <RgbaColorPicker color={newColor} onChange={setNewColor} />
      <div className="flex items-center justify-between gap-2 max-w-full mt-4">
        <div className="flex items-center p-2 bg-wb-secondary rounded-md">
          <Text size="2xs" weight="medium" className="leading-[18px]">
            Hex
          </Text>
        </div>
        <SimpleInputWrapper className="h-[34px] rounded-md">
          <div
            className="h-4 w-4 rounded-md"
            style={{ backgroundColor: convertRgbaToHex(newColor, { alpha: true }) }}
          />
          <div className="wb-react-colorful-input-wrapper">
            <HexColorInput
              prefixed
              color={convertRgbaToHex(newColor) || '#000000'}
              onChange={setNewHexColor}
              onKeyDown={handleHexKeyDown}
            />
          </div>
        </SimpleInputWrapper>
        <SimpleInputWrapper className="gap-0 h-[34px] rounded-md">
          <SimpleInput value={alpha} type="number" onChange={handleAlphaChange} onKeyDown={handleAlphaKeyDown} />
          <Text size="2xs" weight="medium">
            %
          </Text>
        </SimpleInputWrapper>
        <Text size="2xs" weight="medium" className="hover:underline cursor-pointer" onClick={onReset}>
          Reset
        </Text>
      </div>
      {inPageColors?.length > 0 && (
        <div className="flex flex-col gap-2 mt-4">
          <Text size="xs" weight="medium" variant="secondary">
            Recently Used
          </Text>
          <div className="grid grid-cols-[repeat(8,_1fr)] gap-2">
            {inPageColors?.map((color) => (
              <button
                type="button"
                key={`${color}`}
                className="w-full h-auto aspect-1 rounded-md cursor-pointer hover:opacity-80 border border-wb-primary"
                style={{ backgroundColor: color }}
                onClick={() => handleSetColor(color)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
