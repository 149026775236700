import { PropsWithChildren } from 'react';

import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

export const Disabled = ({ children, tooltipText }: PropsWithChildren<{ tooltipText?: string }>) => {
  if (tooltipText) {
    return (
      <Tooltip center={tooltipText}>
        <div className="opacity-50 pointer-events-none flex-1">{children}</div>
      </Tooltip>
    );
  }

  return <div className="opacity-50 pointer-events-none flex-1">{children}</div>;
};
