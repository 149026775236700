import cx from 'classnames';
import styled from 'styled-components';

interface TextProps {
  children: React.ReactNode;
  className?: string;
  type?: 'body' | 'caption' | 'overline' | 'subtitle' | 'bold' | 'label';
  as?: 'p' | 'span' | 'li';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
}

const Base = `text-md font-regular`;
const TEXT_TYPES = {
  body: `${Base}`,
  subtitle: `${Base} font-semibold`,
  bold: `${Base} font-bold`,
  caption: `${Base}`,
  overline: `${Base}`,
  label: `${Base} font-medium text-gray-700`,
};
const StyledText = styled.p``;

const Text: React.FC<TextProps> = ({ className = '', as = 'p', children, type = 'body', size = 'md' }: TextProps) => (
  <StyledText as={as} className={cx(`${TEXT_TYPES[type]}`, className, `text-${size}`)}>
    {children}
  </StyledText>
);

export default Text;
