import { FC, useCallback, useState } from 'react';
import toast from 'react-hot-toast';

import { SearchDropdown } from '@/components/Form';
import { useCurrentPublication } from '@/hooks';
import { useTiers } from '@/hooks/useTiers';
import { Option } from '@/interfaces/general';
import { Subscriber } from '@/interfaces/options';
import api from '@/services/swarm';

interface Props {
  labelText?: string;
  helperText?: string;
  placeholderText?: string;
  className?: string;
  labelClassName?: string;
  onSelectTier: (tierId: string, tierName: string) => void;
  onClearTier: () => void;
  onSelectSubscriber: (subscriberId: string, subscriberEmail: string) => void;
  onClearSubscriber: () => void;
}

const SubscriberAndTierSelect: FC<Props> = (props: Props) => {
  const {
    labelText,
    helperText,
    placeholderText = 'Select a subscriber...',
    className,
    labelClassName,
    onSelectTier,
    onClearTier,
    onSelectSubscriber,
    onClearSubscriber,
  } = props;
  const [subscriberId, setSubscriberId] = useState('');
  const [tierId, setTierId] = useState('free');
  const { data: currentPublication } = useCurrentPublication();
  const { data: tiers = [] } = useTiers(currentPublication?.id || '', { includeActiveSubscriptionsCount: false });

  const mappedTiers = [
    {
      label: `Free subscriber`,
      value: 'free',
    },
  ];

  const mappedPaidTiers: Option[] = tiers.map((tier) => {
    const val: Option = {
      label: `${tier.name} subscriber`,
      value: tier.id,
    };
    return val;
  });

  mappedTiers.push(...mappedPaidTiers);

  mappedTiers.push({
    label: `Non-subscriber`,
    value: 'none',
  });

  const fetchSubscribers = useCallback(
    (inputValue?: string) => {
      if (!currentPublication) {
        return;
      }

      const params = {
        publication_id: currentPublication.id,
        q: inputValue,
      };

      // eslint-disable-next-line
      return api
        .get(`/options/subscribers/`, { params })
        .then((res) => {
          const subscribers = res.data?.options || [];

          const options: Option[] = subscribers.map((subscriber: Subscriber) => {
            const val: Option = {
              label: subscriber.email,
              value: subscriber.id,
            };
            return val;
          });

          return options;
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [currentPublication]
  );

  const handleSelectSubscriber = (name: string, value: string, selectedOption?: Option) => {
    setTierId('');
    setSubscriberId(value);
    onSelectSubscriber(value, selectedOption?.label || '');
  };

  const handleSelectTier = (name: string, value: string, selectedOption?: Option) => {
    setSubscriberId('');
    setTierId(value);
    onSelectTier(value, selectedOption?.label || '');
  };

  const handleClear = () => {
    setSubscriberId('');
    onClearSubscriber();
  };

  const handleClearTier = () => {
    setTierId('');
    onClearTier();
  };

  if (!currentPublication) {
    return null;
  }

  return (
    <SearchDropdown
      name="subscriber-and-tiers"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText={placeholderText}
      value={subscriberId}
      staticValue={tierId}
      onSelect={handleSelectSubscriber}
      onSelectStatic={handleSelectTier}
      onClear={handleClear}
      onClearStatic={handleClearTier}
      staticOptions={mappedTiers}
      staticOptionsLabel="Subscribers"
      loadOptions={fetchSubscribers}
      labelClassName={labelClassName}
      className={className}
    />
  );
};

export default SubscriberAndTierSelect;
