import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PermissionLevel, PermissionResourceType } from '@/interfaces/permissions';
import api from '@/services/swarm';

type Props = {
  resourceType: PermissionResourceType
  resourceId: string
  policyClass?: string
};

const useResourcePermissions = ({resourceType, resourceId, policyClass}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<PermissionLevel[]>(
    [currentPublicationId, 'permissions', resourceType, resourceId, policyClass],
    () =>
      api
        .get(`/permissions/${resourceId}`, {
          params: {
            publication_id: currentPublicationId,
            resource_type: resourceType,
            policy_class: policyClass,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!resourceId && !!resourceType,
    }
  );
};

export default useResourcePermissions;
