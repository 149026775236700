import * as Popover from '@radix-ui/react-popover';
import { cx } from 'class-variance-authority';
import moment from 'moment-mini';

import { useCalendar } from '@/hooks/useCalendar';
import { Calendar } from '@/hooks/useCalendar/types';

import { CALENDAR_POPOVER_Z_INDEX } from '../zIndexes';

import EventDetails from './EventDetails/EventDetails';
import { useCalendarContext } from './useCalendarContext';

interface GridEventProps {
  event: ReturnType<typeof useCalendar>['days'][0]['events'][0];
}

const GridEvent = ({ event }: GridEventProps) => {
  const { calendars } = useCalendarContext();
  const calendar = calendars.find((c) => c.id === event.calendar) as Calendar;
  const category = calendar?.categories.find((c) => c.id === event.category) as Calendar['categories'][0];

  const variantClasses = {
    pink: {
      filled: {
        container:
          'transition duration-100 rounded bg-pink-100 text-pink-700 pl-2 border border-transparent data-[state=open]:border-pink-500 data-[state=open]:shadow-md',
        time: 'text-pink-900',
        indicator: 'absolute rounded h-full w-1 bg-gray-500 left-0 top-0 bg-pink-500',
      },
      flush: {
        container:
          'transition duration-100 rounded text-pink-700 pl-2 border border-transparent data-[state=open]:border-pink-500 data-[state=open]:shadow-md',
        time: 'text-pink-900',
        indicator: 'absolute rounded h-full w-1 bg-gray-500 left-0 top-0 bg-pink-500',
      },
      outline: {
        container: 'rounded border border-pink-300 data-[state=open]:border-pink-500 text-pink-600 pl-2',
        time: 'transition duration-100 text-pink-900',
        indicator: 'hidden',
      },
    },
    violet: {
      filled: {
        container:
          'transition duration-100 rounded bg-violet-100 text-violet-700 pl-2 border border-transparent data-[state=open]:border-violet-500 data-[state=open]:shadow-md',
        time: 'text-violet-900',
        indicator: 'absolute rounded h-full w-1 bg-gray-500 left-0 top-0 bg-violet-500',
      },
      flush: {
        container:
          'transition duration-100 rounded text-violet-700 pl-2 border border-transparent data-[state=open]:border-violet-500 data-[state=open]:shadow-md',
        time: 'text-violet-900',
        indicator: 'absolute rounded h-full w-1 bg-gray-500 left-0 top-0 bg-violet-500',
      },
      outline: {
        container:
          'transition duration-100 rounded border border-violet-300 data-[state=open]:border-violet-500 text-violet-600 pl-2 data-[state=open]:shadow-md',
        time: 'text-violet-900',
        indicator: 'hidden',
      },
    },
  }[calendar.color][category.style];

  return (
    <Popover.Root>
      <Popover.Trigger
        className={cx(
          'max-w-full font-medium p-1 text-xs w-full text-left relative outline-none focus:ring ring-gray-300',
          variantClasses.container
        )}
      >
        <div>
          <div className={cx(variantClasses.indicator)}>&nbsp;</div>
        </div>
        <div className={cx(category.time_relevant ? 'line-clamp-1' : 'line-clamp-2')}>
          <span>{event.title}</span>
        </div>
        {category.time_relevant && (
          <div>
            <p className={cx('font-light text-xs', variantClasses.time)}>
              <time dateTime={event.time}>{moment(event.time).format('LT')}</time>
            </p>
          </div>
        )}
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content side="left" sideOffset={5} className={CALENDAR_POPOVER_Z_INDEX}>
          <EventDetails event={event} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default GridEvent;
