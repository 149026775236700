import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/react';

import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { FieldSet, InputField } from '../../../components/ui/Input';
import { Panel } from '../../../components/ui/Panel';
import { Tooltip } from '../../../components/ui/Tooltip';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const ImageBlockDataPanel = ({
  parentRef,
  editor,
}: {
  parentRef: React.RefObject<HTMLElement>;
  editor: Editor;
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const { selection } = editor.state;

  const hasChanges = useMemo((): any => {
    const attrs = editor.getAttributes('imageBlock');

    return (attrs.title || attrs.alt) && selection;
  }, [editor, selection]);

  useEffect(() => {
    if (isPanelOpen) {
      const outsideClickHandler = (e: Event) => {
        if (
          !buttonRef.current?.contains(e.target as HTMLElement) &&
          !panelRef.current?.contains(e.target as HTMLElement)
        ) {
          setIsPanelOpen(false);
        }
      };

      document.addEventListener('click', outsideClickHandler);
      return () => {
        document.removeEventListener('click', outsideClickHandler);
      };
    }

    return () => {};
  }, [isPanelOpen]);

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      editor.commands.updateAttributes('imageBlock', { title: e.currentTarget.value });
    },
    [editor]
  );

  const handleAltChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      editor.commands.updateAttributes('imageBlock', { alt: e.currentTarget.value });
    },
    [editor]
  );

  return (
    <Tippy
      placement="bottom-start"
      reference={parentRef.current}
      offset={[0, 4]}
      render={(attrs) =>
        isPanelOpen && (
          <Panel
            ref={panelRef}
            tabIndex={-1}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attrs}
          >
            <FieldSet>
              <InputField
                label="Image title"
                value={editor.getAttributes('imageBlock').title}
                onChange={handleTitleChange}
                placeholder="Title …"
              />
              <InputField
                label="Alt text"
                value={editor.getAttributes('imageBlock').alt}
                onChange={handleAltChange}
                placeholder="Alt text …"
              />
            </FieldSet>
          </Panel>
        )
      }
      interactive
      visible={isPanelOpen}
    >
      <div>
        <Tooltip title="Edit image information">
          <Button
            ref={buttonRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $active={hasChanges || isPanelOpen}
            $muted={isPanelOpen && !hasChanges}
            $leftSlot={<Icon name="Edit" />}
            onClick={() => setIsPanelOpen(!isPanelOpen)}
          />
        </Tooltip>
      </div>
    </Tippy>
  );
};

export default ImageBlockDataPanel;
