import { useCallback, useMemo } from 'react';

import { BubbleMenu as BaseBubbleMenu } from '@/components/TiptapEditor/components/menus/BubbleMenu';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelHeader } from '@/components/TiptapEditor/components/ui/Panel';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';

import { AddRowAfterButton, AddRowBeforeButton, DeleteRowButton } from '../../../../components/buttons';
import { MenuProps, ShouldShowProps } from '../../../../components/menus/types';
import { Styled } from '../styled';

import { isRowGripSelected } from './utils';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $fullWidth: true,
};

export const TableRowMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const shouldShow = useCallback(
    ({ view, state, from }: ShouldShowProps) => {
      if (state && from) {
        return isRowGripSelected({ editor, view, state, from });
      }

      return false;
    },
    [editor]
  );

  const { from, $from } = editor.state.selection;

  const isHeaderRow = useMemo(() => {
    if (from === 0) {
      return false;
    }

    if ($from.depth === 0) {
      return false;
    }

    const rowPos = $from.before($from.depth);
    const $row = editor.state.doc.resolve(rowPos);

    if ($row.node().type.name !== 'tableRow') {
      return false;
    }

    let isHeader = true;

    $row.node().descendants((descendant) => {
      if (descendant.type.name === 'tableCell') {
        isHeader = false;
      }
    });

    return isHeader;
  }, [from, $from, editor]);

  const isFirstRow = useMemo(() => {
    if (from === 0) {
      return false;
    }

    if ($from.depth === 0) {
      return false;
    }

    const rowPos = $from.before($from.depth);
    const $row = editor.state.doc.resolve(rowPos);

    if ($row.node().type.name !== 'tableRow') {
      return false;
    }

    return $row.index($row.depth - 1) === 0;
  }, [from, $from, editor]);

  const onToggleHeaderRow = useCallback(() => {
    return editor.chain().toggleHeaderRow().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableRowMenu"
      updateDelay={0}
      tippyOptions={{
        placement: 'left',
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        appendTo: () => {
          return appendTo?.current;
        },
      }}
      shouldShow={shouldShow}
    >
      <Panel $width="auto">
        {isFirstRow && (
          <PanelHeader>
            <ToggleWrapper
              icon={<Icon name="TableRowHeader" />}
              switchEl={<ToggleSwitch isChecked={isHeaderRow} onChange={onToggleHeaderRow} />}
            >
              Set as row head
            </ToggleWrapper>
          </PanelHeader>
        )}
        <Styled.ButtonWrapper>
          {!isHeaderRow ? (
            <AddRowBeforeButton
              editor={editor}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonProps}
            />
          ) : null}
          <AddRowAfterButton
            editor={editor}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
          />
          <DeleteRowButton
            editor={editor}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
          />
        </Styled.ButtonWrapper>
      </Panel>
    </BaseBubbleMenu>
  );
};

export default TableRowMenu;
