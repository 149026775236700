import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

// Contexts
import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { Pagination } from '../../../interfaces/general';
// Interfaces
import { SubscriptionMilestone } from '../../../interfaces/milestone';
// API
import api from '../../../services/swarm';
import NavContainer from '../NavContainer';

import ActionRequired from './ActionRequired';
import ActionSlideOver from './ActionSlideOver';
import Historical from './Historical';

const Fulfillment: FC = () => {
  const [actionRequiredPage, setActionRequiredPage] = useQueryParam<number>(
    'actionRequiredPage',
    withDefault(NumberParam, 1)
  );
  const [historicalPage, setHistoricalPage] = useQueryParam<number>('histocalPage', withDefault(NumberParam, 1));
  const [isLoadingActionRequred, setIsLoadingActionRequred] = useState(false);
  const [isLoadingHistorical, setIsLoadingHistorical] = useState(false);
  const [actionRequiredSubscriptionMilestones, setActionRequiredSubscriptionMilestones] =
    useState<SubscriptionMilestone[]>();
  const [actionRequiredPagination, setActionRequiredPagination] = useState<Pagination>();
  const [historicalSubscriptionMilestones, setHistoricalSubscriptionMilestones] = useState<SubscriptionMilestone[]>();
  const [historicalPagination, setHistoricalPagination] = useState<Pagination>();
  const [focusSubscriptionMilestone, setFocusSubscriptionMilestone] = useState<SubscriptionMilestone>();
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchActionRequired = useCallback(() => {
    if (currentPublicationId) {
      const params = {
        publication_id: currentPublicationId,
        action_required: true,
        page: Number.isNaN(actionRequiredPage) ? 1 : actionRequiredPage,
      };

      setIsLoadingActionRequred(true);
      api
        .get('/referral_program/subscription_milestones', { params })
        .then((res) => {
          setActionRequiredSubscriptionMilestones(res.data?.subscription_milestones || []);
          setActionRequiredPagination(res.data?.pagination || {});
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoadingActionRequred(false);
        });
    }
  }, [currentPublicationId, actionRequiredPage]);

  const fetchHistorical = useCallback(() => {
    if (currentPublicationId) {
      const params = {
        publication_id: currentPublicationId,
        action_required: false,
        page: Number.isNaN(historicalPage) ? 1 : historicalPage,
      };

      setIsLoadingHistorical(true);
      api
        .get('/referral_program/subscription_milestones', { params })
        .then((res) => {
          setHistoricalSubscriptionMilestones(res.data?.subscription_milestones || []);
          setHistoricalPagination(res.data?.pagination || {});
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoadingHistorical(false);
        });
    }
  }, [currentPublicationId, historicalPage]);

  useEffect(() => {
    fetchActionRequired();
  }, [fetchActionRequired]);

  useEffect(() => {
    fetchHistorical();
  }, [fetchHistorical]);

  const onPageSelectedActionRequired = (targetPage: number) => {
    setActionRequiredPage(targetPage);
  };

  const onPageSelectedHistorical = (targetPage: number) => {
    setHistoricalPage(targetPage);
  };

  const onManageSelected = (subscriptionMilestone: SubscriptionMilestone) => {
    setFocusSubscriptionMilestone(subscriptionMilestone);
  };

  const onCloseManage = () => {
    setFocusSubscriptionMilestone(undefined);
  };

  const onSuccessManage = () => {
    setFocusSubscriptionMilestone(undefined);

    // Refetch All Records
    setActionRequiredPage(1);
    setHistoricalPage(1);
    fetchActionRequired();
    fetchHistorical();

    // Refetch the navigation
    queryClient.invalidateQueries('referral_program_nav_kpis');
  };

  const hasActionRequired = actionRequiredSubscriptionMilestones && actionRequiredSubscriptionMilestones?.length > 0;

  return (
    <NavContainer>
      <ActionSlideOver
        isOpen={!!focusSubscriptionMilestone}
        subscriptionMilestoneId={focusSubscriptionMilestone?.id}
        publicationId={currentPublicationId}
        onClose={onCloseManage}
        onSuccess={onSuccessManage}
      />
      {hasActionRequired && (
        <ActionRequired
          className="mb-4"
          isLoading={isLoadingActionRequred}
          subscriptionMilestones={actionRequiredSubscriptionMilestones}
          pagination={actionRequiredPagination}
          onPageSelected={onPageSelectedActionRequired}
          onManageSelected={onManageSelected}
        />
      )}
      <Historical
        isLoading={isLoadingHistorical}
        subscriptionMilestones={historicalSubscriptionMilestones}
        pagination={historicalPagination}
        onPageSelected={onPageSelectedHistorical}
        onManageSelected={onManageSelected}
      />
    </NavContainer>
  );
};

export default Fulfillment;
