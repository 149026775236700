import { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { useDebounce } from '@/components/TiptapEditor/lib/hooks/useDebounce';

import { DEFAULT_TOC_TITLE } from './constants';

const useToC = (
  editor: Editor,
  attrs: Record<string, string>,
  updateAttributes: (attributes: Record<string, any>) => void
) => {
  // ToC items
  const [items, setItems] = useState<any | null>([]);
  const itemsRefs = useRef<Array<HTMLButtonElement | null>>([]);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);

  useEffect(() => {
    const handler = () => {
      const { anchors } = editor.storage.anchor;

      setItems(anchors);
    };

    editor.on('update', handler);
    editor.on('create', handler);
    // TODO: On collab update! Check unique id ext

    return () => {
      editor.off('update', handler);
      editor.off('create', handler);
    };
  }, [editor]);

  const onToggleItemVisibility = useCallback(
    (node: Node, nodePos: number) => {
      const nodeAttrs = node.attrs;
      const includeInToc = node.attrs.anchorIncludeInToc;

      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeMarkup(nodePos, undefined, {
            ...nodeAttrs,
            anchorIncludeInToc: !includeInToc,
          });

          return true;
        })
        .run();
    },
    [editor]
  );

  // ToC heading title
  const [isEditHeadingPanelOpen, setIsEditHeadingPanelOpen] = useState<boolean>(false);
  const [headingTitle, setHeadingTitle] = useState<string | null>(attrs.title === DEFAULT_TOC_TITLE ? '' : attrs.title);
  const debouncedHeadingTitle = useDebounce(headingTitle, 250);

  const onToggleHeadingVisibility = useCallback(() => {
    updateAttributes({ showTitle: !attrs.showTitle });
  }, [attrs.showTitle, updateAttributes]);

  // Heading title: On input changes, save to state
  const onChangeHeadingTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setHeadingTitle(e.target.value);
  }, []);

  // On debounced input changes, save to node
  useEffect(() => {
    const title = debouncedHeadingTitle || DEFAULT_TOC_TITLE;

    if (title !== attrs.title) {
      updateAttributes({ title });
    }
  }, [debouncedHeadingTitle, updateAttributes, attrs]);

  return {
    headingTitle,
    isEditHeadingPanelOpen,
    setIsEditHeadingPanelOpen,
    onChangeHeadingTitle,
    items,
    itemsRefs,
    selectedItem,
    setSelectedItem,
    onToggleItemVisibility,
    onToggleHeadingVisibility,
  };
};

export default useToC;
