import Grid from '@/components/Grid';

import useTopLevelMetrics from '../../_hooks/useTopLevelMetrics';

import LifetimeValueCard from './LifetimeValueCard';
import PaidSubscribersCard from './PaidSubscribersCard';
import PlanBreakdownCard from './PlanBreakdownCard';
import RevenueCard from './RevenueCard';

const TopLevelMetrics = () => {
  const metricsQuery = useTopLevelMetrics();

  return (
    <Grid gap={2} className="grid-cols-4">
      <PaidSubscribersCard query={metricsQuery} />
      <RevenueCard query={metricsQuery} />
      <LifetimeValueCard query={metricsQuery} />
      <PlanBreakdownCard query={metricsQuery} />
    </Grid>
  );
};

export default TopLevelMetrics;
