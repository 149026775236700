import LoadingBox from '@/components/LoadingBox';

import useOpensByHour from '../../../hooks/useInsights/useOpensByHour';

import Chart from './Chart';
import RecommendedTime from './RecommendedTime';

interface Props {
  onClickRecommended: (val: string) => void;
}

const OpensByHourChart = ({ onClickRecommended }: Props) => {
  const { data, isLoading, isError } = useOpensByHour();

  const { opens_by_hour: opensByHour } = data || {};

  const noData = !opensByHour;
  const insufficientData = Object.values(opensByHour || {}).every((value) => value === 0);
  const isHidden = !noData && !insufficientData;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <>
        {isHidden && (
          <div className="space-y-4">
            <RecommendedTime data={opensByHour} onClickRecommended={onClickRecommended} />
            <Chart data={opensByHour} />
          </div>
        )}
      </>
    </LoadingBox>
  );
};

export default OpensByHourChart;
