import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cx from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCustomFields } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import {
  AutomationStep,
  AutomationStepState,
  AutomationStepStepType,
  AutomationStepUpdateSubscriptionCustomField,
} from '@/interfaces/automations/automation_step';
import { PublicationSubscriberTagReference } from '@/interfaces/subscriber_tag';
import { SubscriptionStatuses } from '@/interfaces/subscription';
import { WebhookDestination } from '@/interfaces/webhook_destination';
import analytics from '@/utils/analytics';

import { normalizeCustomFieldsValue } from '../components/normalizeCustomFieldsValue';
import { ACTION_STEP_TYPE_LABELS, CONDITIONS_DESCRIPTIONS_BY_AUTOMATION_STEP_TYPE } from '../constants';
import { useNewAutoLayoutContext } from '../context/new-layout-context';
import useAutomationStep from '../hooks/useAutomationStep';
import isDelayStep from '../utils/isDelayStep';

import ConfigureBody from './ConfigureBody';
import ConfigureConditions from './ConfigureConditions';
import ConfigureConditionsForm from './ConfigureConditionsForm';
import ConfigureEnrollInAutomation from './ConfigureEnrollInAutomation';
import ConfigureFooter from './ConfigureFooter';
import ConfigureHeader from './ConfigureHeader';
import ConfigureSendEmail from './ConfigureSendEmail';
import ConfigureUpdateSubscription from './ConfigureUpdateSubscription';
import ConfigureWaitForForm from './ConfigureWaitForForm';
import ConfigureWaitUntil from './ConfigureWaitUntil';
import ConfigureWebhook from './ConfigureWebhook';
import ConfirmModal from './ConfirmModal';
import ConfirmResourceMutationModal, { ConfirmResourceMutation } from './ConfirmResourceMutationModal';
import { hasConditionChanges } from './hasConditionChanges';

interface Props {
  automationStep: AutomationStep;
  canEdit: boolean;
}

const ConfigureAutomationStep = ({ automationStep, canEdit }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { automation, pushToChangeLog, zoomToNode } = useNewAutoLayoutContext();
  const { updateAutomationStep, deleteAutomationStep } = useAutomationStep();
  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;
  const customFieldsQuery = useCustomFields(publicationId, { search: '', all: true });
  const { data: customFieldsData } = customFieldsQuery;
  const customFields = useMemo(
    () => customFieldsData?.pages.flatMap((page) => page.customFields) || [],
    [customFieldsData]
  );

  const automationStepType = automationStep.step_type;
  const webhookDestination = automationStep.webhook_destination;
  const isConfiguringDelayStep = isDelayStep(automationStep);

  const canConfigureConditions = canEdit && !isConfiguringDelayStep;
  const isConfiguringConditions = canEdit && searchParams.get('mode') === 'conditions';

  const [hasWaitUntilChanges, setHasWaitUntilChanges] = useState(false);
  const [hasUpdateSubscriptionChanges, setHasUpdateSubscriptionChanges] = useState(false);
  const [hasWebhookDestinationChanges, setHasWebhookDestinationChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [stepCurrentState, setStepCurrentState] = useState<AutomationStepState | null>(automationStep.state);
  const [confirmResourceMutation, setConfirmResourceMutation] = useState<ConfirmResourceMutation>({ active: false });
  const [currentConditions, setCurrentConditions] = useState<AutomationConditions>(automationStep.conditions);
  const [currentAutomationIds, setCurrentAutomationIds] = useState<string[]>(
    automationStep.enroll_in_automation_params?.automation_ids || []
  );
  const [currentTimeDelay, setCurrentTimeDelay] = useState<number>(automationStep.wait_for_params?.seconds || 0);
  const [currentWeekdays, setCurrentWeekdays] = useState(automationStep.wait_until_params?.weekdays || []);
  const [currentTimeOfDay, setCurrentTimeOfDay] = useState(automationStep.wait_until_params?.time_of_day || '720');
  const [currentTimeZone, setCurrentTimeZone] = useState(
    automationStep.wait_until_params?.time_zone || currentPublication?.time_zone
  );
  const {
    status,
    custom_fields: updateSubscriptionCustomFields,
    publication_subscriber_tags: updateSubscriptionPublicationSubscriberTags,
  } = automationStep.update_subscription || {};
  const [currentSubscriptionStatusValue, setCurrentSubscriptionStatusValue] = useState<typeof SubscriptionStatuses>(
    status || SubscriptionStatuses.active
  );
  const [currentCustomFieldsValue, setCurrentCustomFieldsValue] = useState<
    AutomationStepUpdateSubscriptionCustomField[]
  >(updateSubscriptionCustomFields || []);
  const [currentPublicationSubscriberTags, setCurrentPublicationSubscriberTags] = useState<
    PublicationSubscriberTagReference[]
  >(updateSubscriptionPublicationSubscriberTags || []);
  const [currentWebhookDestination, setCurrentWebhookDestination] = useState<WebhookDestination | undefined>(
    webhookDestination
  );

  const handleChangeInConditions = (conditions?: AutomationConditions) => {
    setCurrentConditions(conditions || {});
  };

  const handleClickEditConditions = () =>
    navigate(
      `/automations/${automationStep.automation_id}/workflow/steps/${automationStep.id}/configure?mode=conditions`
    );

  const invalidateAutomation = (automationId: string) => {
    queryClient.invalidateQueries(['automations', 'automation_steps', automationId]);
  };

  const confirmDelete = async () => {
    setConfirmResourceMutation({ ...confirmResourceMutation, loading: true });
    await deleteAutomationStep(automationStep.id);
    invalidateAutomation(automation.id);
    pushToChangeLog({
      action: 'automation step deleted',
    });
    setConfirmResourceMutation({ active: false, loading: false });
    navigate(`/automations/${automationStep.automation_id}/workflow`);
  };

  const handleDelete = () => {
    setConfirmResourceMutation({
      active: true,
      automationStep,
      mutationType: 'delete',
    });
  };

  const handleCancel = () => {
    const hasUnsavedChanges =
      hasConditionChanges(automationStep.conditions, currentConditions) ||
      hasWaitUntilChanges ||
      hasUpdateSubscriptionChanges ||
      hasWebhookDestinationChanges;

    if (hasUnsavedChanges) {
      setShowConfirmModal(true);
      return;
    }

    navigate(`/automations/${automationStep.automation_id}/workflow`);
  };

  const confirmSave = async () => {
    try {
      await updateAutomationStep(automationStep.id, {
        conditions: currentConditions,
        state: stepCurrentState,
        ...(automationStepType === AutomationStepStepType.ENROLL_IN_AUTOMATION && {
          enroll_in_automation_params: {
            automation_ids: currentAutomationIds,
          },
        }),
        ...(automationStepType === AutomationStepStepType.UPDATE_SUBSCRIPTION && {
          update_subscription: {
            status: currentSubscriptionStatusValue,
            custom_fields: normalizeCustomFieldsValue(currentCustomFieldsValue, customFields),
            publication_subscriber_tags: currentPublicationSubscriberTags,
          },
        }),
        ...(automationStepType === AutomationStepStepType.WAIT_FOR && {
          wait_for_params: {
            seconds: currentTimeDelay,
          },
        }),
        ...(automationStepType === AutomationStepStepType.WAIT_UNTIL && {
          wait_until_params: {
            weekdays: currentWeekdays,
            time_of_day: currentTimeOfDay,
            time_zone: currentTimeZone,
          },
        }),
        ...(automationStepType === AutomationStepStepType.WEBHOOK && {
          webhook_destination_attributes: currentWebhookDestination,
        }),
      });

      pushToChangeLog({
        automationStepId: automationStep.id,
        action: 'automation step updated',
      });

      // Invalidate data in store
      if (automationStep.email_message_id) {
        queryClient.invalidateQueries(['email_messages', 'preview', automationStep.email_message_id], { exact: true });
        queryClient.invalidateQueries(['email_messages', automationStep.email_message_id], { exact: true });
      }

      invalidateAutomation(automation.id);
      navigate(`/automations/${automationStep.automation_id}/workflow`);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const handleSave = () => {
    if (isConfiguringDelayStep && automationStep.journey_step_counts.fresh_in_progress > 0) {
      return setConfirmResourceMutation({
        active: true,
        automationStep,
        mutationType: 'update',
      });
    }

    return confirmSave();
  };

  const confirmToggleStatus = async () => {
    try {
      await updateAutomationStep(automationStep.id, {
        conditions: currentConditions,
        state: stepCurrentState,
        ...(automationStepType === AutomationStepStepType.ENROLL_IN_AUTOMATION && {
          enroll_in_automation_params: {
            automation_ids: currentAutomationIds,
          },
        }),
        ...(automationStepType === AutomationStepStepType.UPDATE_SUBSCRIPTION && {
          update_subscription: {
            status: currentSubscriptionStatusValue,
            custom_fields: normalizeCustomFieldsValue(currentCustomFieldsValue, customFields),
          },
        }),
        ...(automationStepType === AutomationStepStepType.WAIT_FOR && {
          wait_for_params: {
            seconds: currentTimeDelay,
          },
        }),
        ...(automationStepType === AutomationStepStepType.WAIT_UNTIL && {
          wait_until_params: {
            weekdays: currentWeekdays,
            time_of_day: currentTimeOfDay,
            time_zone: currentTimeZone,
          },
        }),
        ...(automationStepType === AutomationStepStepType.WEBHOOK && {
          webhook_destination_attributes: currentWebhookDestination,
        }),
      });

      setHasUpdateSubscriptionChanges(false);
      setHasWaitUntilChanges(false);

      pushToChangeLog({
        automationStepId: automationStep.id,
        action: 'automation step updated',
      });

      invalidateAutomation(automation.id);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
      setStepCurrentState(automationStep.state);
    }

    // Close Confirm Dialog
    setConfirmResourceMutation({ ...confirmResourceMutation, active: false });
  };

  const handleToggleStatus = (newValue: boolean) => {
    setStepCurrentState(newValue ? AutomationStepState.PUBLISHED : AutomationStepState.DRAFT);

    if (newValue) {
      analytics.track('Activated');
    } else {
      analytics.track('De-activated');
    }

    return setConfirmResourceMutation({
      active: true,
      automationStep,
      mutationType: newValue ? 'activate' : 'deactivate',
    });
  };

  /* Confirm Modal callbacks */
  const handleClickCancelUnsavedChanges = () => setShowConfirmModal(false);

  const handleClickConfirmUnsavedChanges = () => {
    setShowConfirmModal(false);
    navigate(`/automations/${automationStep.automation_id}/workflow`);
  };

  /* Enroll In Automation callbacks */
  const handleChangeInAutomationIds = (value: string[]) => {
    setCurrentAutomationIds(value);
  };

  /* Wait Until configuration callbacks */
  const handleChangeInTimeOfDay = (value: string) => {
    setHasWaitUntilChanges(true);
    setCurrentTimeOfDay(value);
  };
  const handleChangeInTimeZone = (value: string) => {
    setHasWaitUntilChanges(true);
    setCurrentTimeZone(value);
  };
  const handleChangeInWeekdays = (value: string[]) => {
    setHasWaitUntilChanges(true);
    setCurrentWeekdays(value);
  };

  /* Update Subscription configuration callbacks */
  const handleChangeInCustomFieldsValue = (value: AutomationStepUpdateSubscriptionCustomField[]) => {
    setHasUpdateSubscriptionChanges(true);
    setCurrentCustomFieldsValue(value);
  };
  const handleChangeInSubscriptionStatusValue = (value: string) => {
    setHasUpdateSubscriptionChanges(true);
    setCurrentSubscriptionStatusValue(value);
  };

  /* Webhook configuration callbacks */
  const handleChangeInWebhookDestination = (value: WebhookDestination) => {
    setHasWebhookDestinationChanges(true);
    setCurrentWebhookDestination(value);
  };

  /* Confirm Resource Mutation callbacks */
  const handleCancelResourceMutation = () => {
    setStepCurrentState(automationStep.state);
    setConfirmResourceMutation({
      ...confirmResourceMutation,
      active: false,
    });
  };

  const handleConfirmResourceMutation = () => {
    switch (confirmResourceMutation.mutationType) {
      case 'delete':
        return confirmDelete();
      case 'activate':
      case 'deactivate':
        return confirmToggleStatus();
      case 'update':
        return confirmSave();
      default:
        return () => {};
    }
  };

  const conditionsDescription = CONDITIONS_DESCRIPTIONS_BY_AUTOMATION_STEP_TYPE[automationStep.step_type];

  const handleSubscriberTagsChange = (value: PublicationSubscriberTagReference[]) => {
    setCurrentPublicationSubscriberTags(value);
  };

  // On mount
  useEffect(() => {
    zoomToNode(automationStep.id);
  }, [zoomToNode, automationStep]);

  return (
    <>
      <ConfirmResourceMutationModal
        mutation={confirmResourceMutation}
        onConfirm={handleConfirmResourceMutation}
        onCancel={handleCancelResourceMutation}
      />
      <ConfirmModal
        isSaving={false}
        isOpen={showConfirmModal}
        onCancel={handleClickCancelUnsavedChanges}
        onConfirm={handleClickConfirmUnsavedChanges}
        headerText="You have unsaved changes"
      >
        <TypographyStack gap="4" className="text-gray-800">
          <Typography>Are you sure you want to discard the changes?</Typography>
        </TypographyStack>
      </ConfirmModal>

      <div className={cx('fixed top-0 left-0 z-20 w-screen h-screen bg-gray-100/30')}>
        <div className={cx('h-full shadow-md bg-white', isConfiguringConditions ? 'w-3/5' : 'w-128')}>
          {!isConfiguringConditions && automationStepType && (
            <div className="p-5">
              <ConfigureHeader
                title={ACTION_STEP_TYPE_LABELS[automationStep.step_type]}
                onClose={handleCancel}
                canActivate
                isStepActive={stepCurrentState === AutomationStepState.PUBLISHED}
                onActivate={handleToggleStatus}
              />
            </div>
          )}

          <ConfigureBody>
            {isConfiguringConditions && (
              <ConfigureConditions conditions={currentConditions} onChange={handleChangeInConditions} />
            )}
            {!isConfiguringConditions && !canEdit && <ConfigureSendEmail automationStep={automationStep} />}
            {!isConfiguringConditions && automationStep.step_type === AutomationStepStepType.ENROLL_IN_AUTOMATION && (
              <ConfigureEnrollInAutomation
                automationId={automation.id}
                selectedAutomationIds={currentAutomationIds}
                onChange={handleChangeInAutomationIds}
              />
            )}
            {!isConfiguringConditions && automationStep.step_type === AutomationStepStepType.SEND_EMAIL && (
              <ConfigureSendEmail automationStep={automationStep} />
            )}
            {!isConfiguringConditions && automationStep.step_type === AutomationStepStepType.UPDATE_SUBSCRIPTION && (
              <ConfigureUpdateSubscription
                currentCustomFieldsValue={currentCustomFieldsValue}
                currentSubscriptionStatusValue={currentSubscriptionStatusValue}
                onChangeCurrentCustomFieldsValue={handleChangeInCustomFieldsValue}
                onChangeCurrentSubscriptionStatusValue={handleChangeInSubscriptionStatusValue}
                customFields={customFields}
                subscriberTagsValue={currentPublicationSubscriberTags}
                onChangeSubscriberTags={handleSubscriberTagsChange}
              />
            )}
            {!isConfiguringConditions && automationStep.step_type === AutomationStepStepType.WAIT_FOR && (
              <ConfigureWaitForForm seconds={currentTimeDelay} onChange={setCurrentTimeDelay} />
            )}
            {!isConfiguringConditions && automationStep.step_type === AutomationStepStepType.WAIT_UNTIL && (
              <ConfigureWaitUntil
                currentTimeOfDay={currentTimeOfDay}
                currentTimeZone={currentTimeZone}
                currentWeekdays={currentWeekdays}
                onChangeTimeOfDay={handleChangeInTimeOfDay}
                onChangeTimeZone={handleChangeInTimeZone}
                onChangeWeekdays={handleChangeInWeekdays}
              />
            )}
            {!isConfiguringConditions &&
              automationStep.step_type === AutomationStepStepType.WEBHOOK &&
              currentWebhookDestination && (
                <ConfigureWebhook
                  automationId={automation.id}
                  webhookDestination={currentWebhookDestination}
                  onChange={handleChangeInWebhookDestination}
                />
              )}
            {!isConfiguringConditions && canConfigureConditions && (
              <ConfigureConditionsForm
                label={conditionsDescription.label}
                description={conditionsDescription.description}
                conditions={currentConditions}
                onClickEditConditions={handleClickEditConditions}
              />
            )}
          </ConfigureBody>
          {!isConfiguringConditions && (
            <ConfigureFooter onCancel={handleCancel} onSave={handleSave} onDelete={handleDelete} />
          )}
        </div>
      </div>
    </>
  );
};

export default ConfigureAutomationStep;
