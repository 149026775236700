import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Content, Editor } from '@tiptap/react';

import { DreamEditorContext } from '@/context/dream-editor-context';
import { useBeforeNavigate } from '@/hooks/useBeforeNavigate';
import { useUpdateSitePackage } from '@/hooks/useSitePackages';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';

export const FooterTemplateEditorProvider = ({ children }: { children: React.ReactNode }) => {
  const [initialContent, setInitialContent] = useState<Content | null>(null);
  const [changesMade, setChangesMade] = useState(false);
  const [editor, setEditor] = useState<Editor | null>(null);
  const { sitePackageId } = useParams();

  const { data: sitePackage } = useSitePackage({ sitePackageId: sitePackageId || '' });

  useEffect(() => {
    if (!initialContent && sitePackage) {
      setInitialContent(sitePackage.footer as Content);
    }
  }, [sitePackage, initialContent]);

  const { mutate: updateSitePackage, isLoading: isSaveLoading } = useUpdateSitePackage({
    id: sitePackageId || '',
  });

  const isSaveVersionLoading = false;

  const save = useCallback(() => {
    if (!editor) return;

    updateSitePackage({ footer: JSON.stringify(editor?.getJSON()) });
    setChangesMade(false);
  }, [updateSitePackage, editor]);

  const onSaveToVersionHistory = useCallback(async (): Promise<void> => {
    toast.error('Version history is not implemented for footer template!');
  }, []);

  // Auto save every 10 seconds
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        save();
      }
    }, 10000); // 10 seconds

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, save]);

  // Save on navigate away & prompt on reload or leaving page
  useBeforeNavigate(save, !!changesMade);

  const value = useMemo(
    () => ({
      initialContent,
      setEditor,
      changesMade,
      setChangesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory,
      previewContent: null,
      isSaveVersionLoading,
      editor,
    }),
    [initialContent, setEditor, changesMade, save, isSaveLoading, onSaveToVersionHistory, isSaveVersionLoading, editor]
  );

  return <DreamEditorContext.Provider value={value}>{children}</DreamEditorContext.Provider>;
};

export const useFooterDataContext = () => {
  const context = React.useContext(DreamEditorContext);
  if (context === undefined) {
    throw new Error('useFooterDataContext must be used within a FooterDataProvider');
  }
  return context;
};
