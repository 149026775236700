import { Quotes } from '@phosphor-icons/react';
import { TestimonialsElement, TTestimonialAttributes } from '@shared/dream-components';

import TestimonialsSelectionModal from '../../../../extensions/Testimonials/modals/TestimonialsSelectionModal';
import { AttributeSettingProps } from '../../../types';
import { DataModalSettings } from '../../DataModalSettings';
import { StructureSettings } from '../../StructureSettings';
import { MessageBottom, MessageTop, Stacked } from '../../TestimonialsSettings/testimonialsStructurePatterns';
import ActionRenderer from '../ActionRenderer';

const TestimonialsActions = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNode, activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const { insertedFromSidebar } = activeNodeAttributes;

  const handleCloseModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', false);
      return true;
    });
  };

  return (
    <ActionRenderer nodeType="testimonials" activeNodeType={activeNodeType}>
      <StructureSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        allowedProperties={['div', 'image', 'name', 'description', 'message']}
        predefinedPatterns={[MessageTop, MessageBottom, Stacked]}
      >
        {(structure: any) => (
          <TestimonialsElement
            element={{
              type: 'testimonials',
              attrs: {
                ...(activeNode?.attrs as TTestimonialAttributes),
                cardStructure: structure as any,
                columns: '1',
                // We only need one testimonial to display the structure
                data: activeNode?.attrs?.data?.slice(0, 1),
              },
              content: [{ id: 'text', text: '' }],
            }}
          >
            {null}
          </TestimonialsElement>
        )}
      </StructureSettings>
      <DataModalSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        handleOpenModal={() => {}}
        text="Testimonials"
        Icon={Quotes}
      />
      {insertedFromSidebar && activeNode && (
        <TestimonialsSelectionModal
          pos={activeNodePos}
          editor={editor}
          node={activeNode}
          isOpen={Boolean(insertedFromSidebar)}
          onClose={handleCloseModal}
        />
      )}
    </ActionRenderer>
  );
};

export default TestimonialsActions;
