import { useState } from 'react';

import { Button } from '@/ui/Button';

import useFilteringContext from '../filtering-context';
import { ConditionGroup, PlanNameOperator, PlanNameOperatorsList } from '../types';

const PlanNameFilterDetails = ({ group }: { group: ConditionGroup }) => {
  const operators = PlanNameOperatorsList;

  const [selectedOperator, setSelectedOperator] = useState<PlanNameOperator>(operators[0].value);
  const [value, setValue] = useState('launch');

  const { addCondition, handleClose } = useFilteringContext();

  const handleApply = () => {
    addCondition(
      {
        type: 'attribute',
        name: 'plan_name',
        filters: { operator: selectedOperator, value },
      },
      group.uuid
    );

    handleClose();
  };

  return (
    <form
      className="absolute z-10 mt-2 bg-white border border-gray-300 shadow rounded-md text-sm left-0 w-max text-gray-600 p-4 space-y-2"
      onSubmit={handleApply}
    >
      <div className="space-x-2">
        <p className="inline">Plan</p>
        <select
          className="appearance-none outline-none border rounded-md text-xs border-gray-300"
          onChange={(e) => setSelectedOperator(e.target.value as any)}
          value={selectedOperator}
          required
        >
          {operators.map((operator) => {
            return (
              <option key={operator.value} value={operator.value}>
                {operator.label}
              </option>
            );
          })}
        </select>
        <select
          className="appearance-none outline-none border rounded-md text-xs border-gray-300"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          required
        >
          <option value="launch">Launch</option>
          <option value="grow">Grow</option>
          <option value="scale">Scale</option>
          <option value="max">MAX</option>
          <option value="custom">Enterprise</option>
        </select>
      </div>
      <Button variant="primary-inverse" type="submit" size="xs">
        Apply
      </Button>
    </form>
  );
};

export default PlanNameFilterDetails;
