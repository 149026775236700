import React from 'react';

interface ImageCardProps {
  imageUrl: string;
  title: string;
  href: string;
  description: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ imageUrl, title, description, href }) => {
  return (
    <a href={href} target='_blank' className="bg-white rounded-lg border cursor-pointer border-surface-200 overflow-hidden shadow-sm hover:shadow-md transition-shadow" rel="noreferrer">
      <img src={imageUrl} alt={title} className="w-full h-32 object-cover" />
      <div className="p-4 text-sm">
        <h2 className="font-semibold mb-2 text-gray-900">{title}</h2>
        <p className="text-gray-700">{description}</p>
      </div>
    </a>
  );
};

export default ImageCard;