import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  routesType: 'default' | 'custom';
}

export default function useCreatePage({ routesType }: Props) {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();

  return useMutation(
    async ({ parentPath, slug, name = 'Untitled' }: { parentPath: string[]; slug?: string; name?: string }) => {
      const { data } = await api.post(`/pages`, {
        publication_id: currentPublicationId,
        parent_path: parentPath,
        routes_type: routesType,
        slug,
        name,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        toast.success('Page created successfully');
        queryClient.invalidateQueries(['publications', currentPublicationId, 'pages']);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'sites', 'null']);

        navigate(`/website_builder_v2/page/${data.id}`);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
}
