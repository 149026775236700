/**
 * What's your favorite color?
 *
 * 🟩🟩🟩🟩⬜️⬜️⬜️⬜️⬜️ Red (1000)
 * 🟨🟨🟨⬜️⬜️⬜️⬜️⬜️⬜️ Green (800)
 * 🟨🟨⬜️⬜️⬜️⬜️⬜️⬜️⬜️ Blue (234)
 * 2034 Votes
 *
 * via @beehiiv polls
 */

type Node = { label: string; value: number };
interface EmojiChartProps {
  data: Node[];
  title: string;
  describes: string;
  signature?: string;
  length?: number;
}

const useEmojiChart = ({ data, title, describes, signature, length = 6 }: EmojiChartProps) => {
  const total = data.reduce((acc, node) => acc + node.value, 0);

  const hitMarker = '🟨';
  const missMarker = '⬜️';
  const winningHitMarker = '🟩';
  const winner = data.reduce((acc, node) => (node.value > acc.value ? node : acc));

  return `${title}
 
${data
  .map((node) => {
    const percent = (node.value / winner.value) * 100;
    const roundedPercent = Math.round(percent / 10) * length;
    const hits = Math.floor(roundedPercent / 10);
    const misses = length - hits;
    const marker = node.value === winner.value ? winningHitMarker : hitMarker;

    return `${marker.repeat(hits)}${missMarker.repeat(misses)} ${node.label} (${node.value})`;
  })
  .join('\n')}
${total} ${describes}
${signature ? `\n${signature}` : ''}`;
};

export default useEmojiChart;
