import { useState } from 'react';
import { useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { SortableList, useSortableList } from '@/components/SortableList';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useDeleteNavGroup, useOrderNavGroups } from '@/hooks/useNavGroups';
import { NavGroup } from '@/interfaces/nav_group';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';

import SortableNavGroup from './SortableNavGroup';

interface Props {
  navGroups: NavGroup[];
}

const SortableNavGroups = ({ navGroups }: Props) => {
  const queryClient = useQueryClient();
  const { currentPublication, handleSaved } = usePageContext();

  const [navGroupToDelete, setNavGroupToDelete] = useState<NavGroup | null>(null);

  const deleteNavGroup = useDeleteNavGroup({
    navGroupId: navGroupToDelete?.id || '',
    onSuccess: handleSaved,
  });
  const handleDelete = async () => {
    await deleteNavGroup.mutateAsync('');
    queryClient.invalidateQueries(['nav_links', currentPublication?.id]);
    setNavGroupToDelete(null);
  };

  const [currentPublicationId] = useCurrentPublicationState();

  // The navGroup item is passed to `SortableNavGroup` component via this list
  // as sortableList does not deeply compare navGroup.nav_links for performance reasons
  const navGroupsById = navGroups.reduce((acc: { [key: string]: NavGroup }, group: NavGroup) => {
    acc[group.id] = group;
    return acc;
  }, {});
  const { sortableList } = useSortableList<NavGroup>({
    list: navGroups || [],
  });

  const orderGroups = useOrderNavGroups({
    onSuccess: () => {
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      handleSaved();
    },
  });

  const handleItemsSorted = (sortedList: any[]) => {
    const newList = sortedList as NavGroup[];
    orderGroups.mutate(
      newList.map((item: any, index: number) => {
        return { id: item.id, order: index + 1 };
      })
    );
  };

  return (
    <>
      <SortableList listItems={sortableList} onItemsSorted={handleItemsSorted} className="!space-y-2">
        {(list) => {
          return list?.map((item: NavGroup) => {
            return (
              <SortableNavGroup
                key={item?.id}
                navGroup={navGroupsById[item?.id] || ''}
                setNavGroupToDelete={setNavGroupToDelete}
              />
            );
          });
        }}
      </SortableList>

      <ActionModal
        isOpen={Boolean(navGroupToDelete)}
        onClose={() => setNavGroupToDelete(null)}
        onProceed={handleDelete}
        resourceId={navGroupToDelete?.id || ''}
        isWorking={deleteNavGroup.isLoading}
        headerText="Delete Dropdown"
        actionText="Delete"
        buttonType="danger"
      >
        <div className="space-y-2 text-sm">
          <p>
            Are you sure you want to delete this dropdown? If it is currently present on your site, it will be removed.
          </p>
          <p>
            <span className="font-bold">Not to worry:</span> None of your connected internal or external pages will be
            deleted. Internal pages will remain live on your site as Standalone Pages, while any external pages will be
            set to draft until they are reassigned to another Navbar Item.
          </p>
          <p>Would you like to continue?</p>
        </div>
      </ActionModal>
    </>
  );
};

export default SortableNavGroups;
