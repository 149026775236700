import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { PencilSquareIcon, RectangleStackIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { useIndustry, useIndustryDestroy, useIndustryGroup } from '@/hooks/useAdNetwork/internal';
import { AdNetworkIndustry } from '@/interfaces/ad_network/internal/industry';
import { AdNetworkIndustryGroup } from '@/interfaces/ad_network/internal/industry_group';
import { Button } from '@/ui/Button';

import { DescriptionList } from '../_components/DescriptionList';
import { MultiActionCard } from '../_components/MultiActionCard';
import { PageHeading } from '../_components/PageHeading';
import useConfirmDelete from '../_hooks/useConfirmDelete';

interface Props {
  industry: AdNetworkIndustry;
  industryGroup?: AdNetworkIndustryGroup;
}

const Industry = ({ industry, industryGroup }: Props) => {
  const deleteMutation = useIndustryDestroy({ id: industry.id });
  const warning = 'Once you delete an industry, there is no going back. Please be certain.';
  const { ConfirmDeleteModal, modalProps, handleDelete, isDeleting } = useConfirmDelete({
    deleteMutation,
    warning,
    confirmWith: industry.name,
  });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Industries</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">{industry.name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <ReactTooltip id="advertisers-tip" delayShow={1000} />
          <Button
            to="advertisers"
            data-tip="Advertisers"
            data-for="advertisers-tip"
            size="xs"
            variant="primary-inverse"
            Icon={RectangleStackIcon}
          />
          <Button to="edit" size="xs" variant="primary-inverse" Icon={PencilSquareIcon}>
            Edit
          </Button>
        </PageHeading.Side>
      </PageHeading>

      <div className="divide-y divide-gray-100">
        <DescriptionList>
          <DescriptionList.Item term="ID" copyValue={industry.id}>
            {industry.id}
          </DescriptionList.Item>
          <DescriptionList.Item term="Name">{industry.name}</DescriptionList.Item>
          <DescriptionList.Item term="Industry Group">{industryGroup?.name}</DescriptionList.Item>
          <DescriptionList.Item term="Created">
            <time>{moment(industry.created_at).fromNow()}</time>
          </DescriptionList.Item>
          <DescriptionList.Item term="Updated">
            <time>{moment(industry.updated_at).fromNow()}</time>
          </DescriptionList.Item>
        </DescriptionList>
        <MultiActionCard>
          <MultiActionCard.Item
            title="Delete this industry"
            description={warning}
            loading={isDeleting}
            onClick={() => handleDelete()}
            danger
          />
        </MultiActionCard>
      </div>
      <ConfirmDeleteModal {...modalProps} />
    </>
  );
};

export default function Loader() {
  const { industry_id: id } = useParams<'industry_id'>() as { industry_id: string };
  const { data: industry, isSuccess, isLoading, isError } = useIndustry({ id });
  const industryGroupId: string | undefined = industry?.industry_group_id || '';
  const {
    data: industryGroup,
    isSuccess: groupSuccess,
    isLoading: groupLoading,
    isError: groupError,
  } = useIndustryGroup({ id: industryGroupId });

  if (!isSuccess || isLoading || isError || !groupSuccess || groupLoading || groupError) return null;

  return <Industry industry={industry} industryGroup={industryGroup} />;
}
