import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link as LinkIcon } from '@phosphor-icons/react';
import { useEditorState } from '@tiptap/react';

import { useCurrentPublication } from '@/hooks';
import useResolvedRoutes from '@/routes/website/_hooks/useResolvedRoutes';

import { Button } from '../../../UI/Button';
import { SimpleInput, SimpleInputWrapper } from '../../../UI/Input';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { PopoverHelper, PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';
import { isInternalLink } from '../utils/isInternalLink';

export interface ComboboxOption {
  id: number | string;
  name: string;
  LeftIcon?: React.ReactNode;
}

const validateUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);

    if (process.env.NODE_ENV !== 'development') {
      return parsedUrl.protocol === 'https:';
    }

    return true;
  } catch (error) {
    toast.error('Invalid URL');
    return false;
  }
};

export const LinkToSettings = ({ editor }: AttributeSettingProps) => {
  const routes = useResolvedRoutes();
  const { data: currentPublication } = useCurrentPublication();

  const [currentURL, setCurrentURL] = useState('');
  const [userFriendlyName, setUserFriendlyName] = useState('');
  const [selectedLinkType, setSelectedLinkType] = useState<'routes' | 'custom'>('routes');

  const href = useEditorState<string>({
    editor,
    selector: ({ editor: e }) => e.getAttributes('link')?.href,
  });

  useEffect(() => {
    if (isInternalLink(currentPublication?.url || '', href)) {
      setSelectedLinkType('routes');

      try {
        const url = new URL(href);
        const slug = url.pathname;
        const route = routes.find((r: any) => `/${r.slug}` === slug);
        setUserFriendlyName(route?.name || '');
      } catch (error) {
        setUserFriendlyName('');
      }
    } else {
      setSelectedLinkType('custom');
    }

    setCurrentURL(href || '');
  }, [currentPublication?.url, href, routes]);

  const handleSetLink = useCallback(
    (url: string, isInternal?: boolean) => {
      if (!validateUrl(url)) return;

      if (isInternal || isInternalLink(currentPublication?.url || '', url)) {
        editor.chain().extendMarkRange('link').setLink({ href: url, target: '_self' }).focus().run();
      } else {
        editor
          .chain()
          .extendMarkRange('link')
          .setLink({ href: url, target: '_blank', rel: 'noopener noreferrer' })
          .focus()
          .run();
      }
    },
    [editor, currentPublication?.url]
  );

  return (
    <PopoverHelper title="Link" text={userFriendlyName || href} align="end" popoverTitle="Link" Icon={LinkIcon}>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="routes"
          value={selectedLinkType}
          onValueChange={(value: 'routes' | 'custom') => setSelectedLinkType(value)}
        >
          <ToggleGroupItem value="routes" className="grow">
            Pages
          </ToggleGroupItem>
          <ToggleGroupItem value="custom" className="grow">
            Custom
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
      <div className="flex flex-col gap-2 max-h-[200px] mt-2 pb-4">
        {selectedLinkType === 'custom' && (
          <div className="flex flex-col gap-2">
            <SimpleInputWrapper>
              <SimpleInput
                type="text"
                value={currentURL}
                onChange={(e) => setCurrentURL(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSetLink(currentURL);
                  }
                }}
              />
            </SimpleInputWrapper>
            <Button variant="outlined" size="sm" onClick={() => handleSetLink(currentURL)}>
              Apply
            </Button>
          </div>
        )}
        {selectedLinkType === 'routes' && (
          <>
            {routes.map((route: any) => {
              return (
                <PopoverItem
                  key={route.id}
                  isSelected={false}
                  value={route.name}
                  onSelect={() => {
                    const settableUrl = `${currentPublication?.url}${route.slug}`;
                    setUserFriendlyName(route.name);
                    handleSetLink(settableUrl, true);
                  }}
                />
              );
            })}
          </>
        )}
      </div>
    </PopoverHelper>
  );
};
