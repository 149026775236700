import { Link } from 'react-router-dom';
import { LinkIcon } from '@heroicons/react/20/solid';

import Text from '@/components/Text';
import { useCopyToClipboard } from '@/hooks';
import { ActivityFeedEvent, ActivityFeedEventType } from '@/interfaces/activity_feed_event';

const EventMetadata = ({ event }: { event: ActivityFeedEvent }) => {
  const copy = useCopyToClipboard();

  switch (event.type) {
    case ActivityFeedEventType.CLICK:
      return (
        <button
          type="button"
          className="group text-left relative max-w-[80%] overflow-hidden hover:overflow-visible hidden sm:block"
          onClick={() => copy({ text: event.metadata?.url })}
        >
          <Text
            size="sm"
            className="text-gray-700 bg-white text-ellipsis overflow-hidden group-hover:overflow-visible group-hover:break-all group-hover:whitespace-normal group-hover:absolute group-hover:p-2 group-hover:shadow-sm group-hover:border border-gray-200 rounded group-hover:-mt-4"
          >
            {event.metadata?.url}
            <div className="hidden group-hover:block">
              <div className="flex items-center justify-end mt-1 text-gray-300 ">
                <LinkIcon className="w-4 h-4 mr-1" />
                Copy
              </div>
            </div>
          </Text>
        </button>
      );
    case ActivityFeedEventType.POLL_EXTENDED_FEEDBACK:
      return (
        <div className="group relative max-w-[80%] overflow-hidden hover:overflow-visible hidden sm:block">
          <Text
            size="sm"
            className="text-gray-700 bg-white truncate group-hover:overflow-visible group-hover:break-all group-hover:whitespace-normal group-hover:absolute group-hover:p-2 group-hover:shadow-sm group-hover:border border-gray-200 rounded group-hover:-mt-2"
          >
            {event.metadata?.extended_feedback}
          </Text>
        </div>
      );
    case ActivityFeedEventType.COMMENT:
      return (
        <div className="max-w-4xl hidden sm:block">
          <Text size="sm" className="text-gray-700">
            {event.metadata?.content}
          </Text>
          <Link
            to={`/posts/${event.resource_id}/comments`}
            className="text-sm mt-3 font-semibold text-action-tertiary-600"
          >
            View Comment
          </Link>
        </div>
      );
    case ActivityFeedEventType.AUTOMATION_EXITED_EARLY:
      return (
        <div className="group relative max-w-[80%] overflow-hidden hover:overflow-visible hidden sm:block">
          <Text size="sm" className="text-gray-700">
            {event.metadata?.reason}
          </Text>
        </div>
      );
    default:
      return <div />;
  }
};

export default EventMetadata;
