import { Editor, Extension } from '@tiptap/core';

type ColorStorageType = {
  usedColors: Record<string, any>;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    colorStorage: {
      setUsedColor: (color: string, prevColor?: string) => ReturnType;
    };
  }
}

export const ColorStorage = Extension.create<{}, ColorStorageType>({
  name: 'colorStorage',
  addStorage() {
    return {
      usedColors: [],
    };
  },

  // @ts-ignore
  onCreate({ editor }: { editor: Editor }) {
    const allColors: Record<string, number> = {};

    editor.state.doc.descendants((node) => {
      if (node.type.name === 'text') {
        const textStyleMark = node.marks.find((m) => m.type.name === 'textStyle');

        const { color } = textStyleMark?.attrs || {};
        if (textStyleMark?.attrs.color) {
          if (color in allColors) {
            allColors[color] += 1;
          } else {
            allColors[color] = 1;
          }
        }
      } else {
        const { color, backgroundColor, borderColor } = node.attrs || {};
        if (color) {
          if (color in allColors) {
            allColors[color] += 1;
          } else {
            allColors[color] = 1;
          }
        }
        if (backgroundColor) {
          if (backgroundColor in allColors) {
            allColors[backgroundColor] += 1;
          } else {
            allColors[backgroundColor] = 1;
          }
        }
        if (borderColor) {
          if (borderColor in allColors) {
            allColors[borderColor] += 1;
          } else {
            allColors[borderColor] = 1;
          }
        }
      }
    });

    this.storage.usedColors = allColors;
  },

  addCommands() {
    return {
      setUsedColor: (color: string, prevColor?: string) => () => {
        if (prevColor && prevColor in this.storage.usedColors) {
          this.storage.usedColors[prevColor] -= 1;
          if (this.storage.usedColors[prevColor] === 0) {
            delete this.storage.usedColors[prevColor];
          }
        }

        if (color in this.storage.usedColors) {
          this.storage.usedColors[color] += 1;
        } else {
          this.storage.usedColors[color] = 1;
        }
        return true;
      },
    };
  },
});
