import { useState } from 'react';

import useCreateStripeConnectAccount from '@/hooks/boosts/monetize/useCreateStripeConnectAccount';
import { StripeAccountStatus, StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { Button } from '@/ui/Button';

interface Props {
  boostsAccountStatus: StripeAccountStatus | undefined;
  boostsAccountType: StripeAccountType | undefined | null;
  publicationId: string;
}

const ContinueOnboarding = ({ publicationId, boostsAccountStatus, boostsAccountType }: Props) => {
  const { data: settings } = usePublicationSettings(publicationId);
  const shouldRender =
    settings?.wallet &&
    boostsAccountType === StripeAccountType.EXPRESS &&
    boostsAccountStatus !== StripeAccountStatus.ACTIVE;

  const [isManaging, setIsManaging] = useState<boolean>(false);
  const { mutateAsync: createStripeConnectAccount, isLoading: isCreating } = useCreateStripeConnectAccount(
    publicationId,
    {
      onSuccess: (res) => {
        window.open(`${res.url}`, '_blank');
      },
    }
  );

  const continueOnboardingExpressAccount = () => {
    setIsManaging(true);
    createStripeConnectAccount({});
    setIsManaging(false);
  };

  if (!shouldRender) return null;

  return (
    <Button
      variant="primary-inverse"
      type="submit"
      size="xs"
      className="w-fit"
      loading={isManaging || isCreating}
      disabled={isManaging || isCreating}
      onClick={() => continueOnboardingExpressAccount()}
    >
      Continue Onboarding
    </Button>
  );
};

export default ContinueOnboarding;
