import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

import { useSubscriberContext } from '../../../_context/subscriberContext';

interface UpdateProps {
  customFieldId: string;
  customFieldValueId?: string;
}

const useDestroyCustomFieldValue = ({ customFieldValueId, customFieldId }: UpdateProps) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { subscription } = useSubscriberContext();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      api.patch(`/subscriptions/${subscription.id}/custom_field_values/bulk_update`, {
        publication_id: currentPublicationId,
        subscription: {
          custom_field_values: [
            {
              id: customFieldValueId,
              custom_field_id: customFieldId,
              _destroy: true,
            },
          ],
        },
      }),
    {
      onSuccess: async () => {
        return queryClient.invalidateQueries(['custom_field_values', subscription.id]);
      },
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
    }
  );
};

export default useDestroyCustomFieldValue;
