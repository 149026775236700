import { ArrowUpRightIcon, TicketIcon } from '@heroicons/react/24/outline';

import { useSettings } from '@/context/settings-context';
import { useSupportModalContext } from "@/context/support-modal-context";
import { Button } from '@/ui/Button';

import SupportCard from '../SupportCard';

const PricingButton = () => (
  <Button
    onClick={() => window.open("https://www.beehiiv.com/pricing", "_blank")}
    target="_blank"
    className="inline-flex ml-auto hover:shadow-md transition-shadow gap-2"
    rel="noreferrer"
    iconRight
    size='xs'
    Icon={ArrowUpRightIcon}
  >
    Pricing Plans
  </Button>
)

const TicketButton = () => {
  const { setIsSupportModalOpen, setBypassConfirm } = useSupportModalContext();

  return (
    <Button
      onClick={() => {
        setIsSupportModalOpen(true);
        setBypassConfirm(true);
      }}
      className="inline-flex ml-auto hover:shadow-md transition-shadow gap-2"
      size='xs'
      Icon={TicketIcon}
    >
      Create Ticket
    </Button>
  );
};

interface SupportCardVariableProps {
  isFreePlan: boolean;
  isBanned: boolean;
}

const SupportCardVariable: React.FC<SupportCardVariableProps> = ({ isFreePlan, isBanned }) => {
  const { settings } = useSettings();

  const cardProps = (isFreePlan && !settings?.free_support && !isBanned)
    ? {
      title: "Need hands-on support?",
      description: "Join 1,000s of customers paying for support from the beehiiv customer support team to help unblock you where you need it",
      button: <PricingButton />
    }
    : {
      title: "Support Squad",
      description: "Submit a ticket for our team of experts. Response times vary from 12-36 hours, plan pending.",
      button: <TicketButton />
    };

  return <SupportCard {...cardProps} />;
};

export default SupportCardVariable;