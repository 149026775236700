import { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';

import SelectCheckbox from '@/components/SelectCheckbox';
import { SelectingState } from '@/components/SelectCheckbox/SelectCheckbox';
import SelectCheckboxItem from '@/components/SelectCheckboxItem';
import { OptionProps } from '@/components/SelectCheckboxItem/SelectCheckboxItem';
import { usePermissions } from '@/context/permissions-context';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import { Post, PostBulkActions, PostPreview } from '@/interfaces/post';
import { Button } from '@/ui/Button';

export enum SelectOptions {
  ALL = 'All',
  FREE = 'Free',
  PREMIUM = 'Paid',
  BOTH_AUDIENCE = 'Both (Free & Paid)',
  WEB = 'Web',
  EMAIL = 'Email',
  BOTH_PLATFORM = 'Both (Web & Email)',
  NONE = 'None',
}

enum SelectOptionLabels {
  ALL = 'All',
  FREE = 'Only Free',
  PREMIUM = 'Only Paid',
  BOTH_AUDIENCE = 'Both (Free & Paid)',
  WEB = 'Only Web',
  EMAIL = 'Only Email',
  BOTH_PLATFORM = 'Both (Web & Email)',
  NONE = 'None',
}

type SelectAllCheckboxProps = {
  alignMenu?: 'left' | 'right';
  isLoading: boolean;
  selecting: SelectingState;
  totalPostCount: number;
  handleDeleteClick: (type: PostBulkActions) => void;
  handleSelectionChange: (currentSelectOptions: SelectOptions[]) => void;
  selectCheckOptions: {
    currentSelectOptions: SelectOptions[];
    posts: (Post | PostPreview)[];
    selectedPosts: string[];
    setSelectedPosts: (value: string[]) => void;
    totalPostCount?: number;
  };
};

const SelectAllCheckbox = (props: SelectAllCheckboxProps) => {
  const {
    alignMenu = 'left',
    isLoading,
    selecting,
    totalPostCount,
    handleSelectionChange,
    selectCheckOptions,
    handleDeleteClick,
  } = props;

  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);

  const { currentSelectOptions, selectedPosts, setSelectedPosts } = selectCheckOptions;

  const selectedPostsCount = selectedPosts.filter((postId) => postId !== null).length;
  const { checkPermissions } = usePermissions();
  const canDelete = checkPermissions('views/posts/bulk_actions', 'delete');

  useEffect(() => {
    const newCheck = selectedPostsCount > 0;
    const newIndeterminate = newCheck && selectedPosts.length < totalPostCount;

    setIndeterminate(newIndeterminate);
    setChecked(newCheck);
  }, [selectedPosts, setIndeterminate, selectedPostsCount, totalPostCount]);

  const handleOptionClick = (event: React.MouseEvent<HTMLButtonElement>, option: OptionProps) => {
    event.preventDefault();
    event.stopPropagation();

    let newOptions = [...currentSelectOptions, option.value as SelectOptions];

    if (currentSelectOptions.includes(option.value as SelectOptions)) {
      newOptions = [...currentSelectOptions.filter((currentOption) => currentOption !== option.value)];
    } else {
      if (option.value !== SelectOptionLabels.ALL) {
        newOptions = [
          ...currentSelectOptions.filter((currentOption) => currentOption !== SelectOptions.ALL),
          option.value as SelectOptions,
        ];
      }

      if (option.value === SelectOptions.ALL) {
        newOptions = [SelectOptions.ALL];
      }

      if (option.value === SelectOptions.NONE) {
        newOptions = [];
        setSelectedPosts([]);
      }
    }

    handleSelectionChange(newOptions);
  };

  const selectOptions = [
    <SelectCheckboxItem
      key={SelectOptions.ALL}
      option={{
        label: SelectOptionLabels.ALL,
        value: SelectOptions.ALL,
        selected: currentSelectOptions.includes(SelectOptions.ALL),
        showCheckbox: true,
      }}
      handleClick={handleOptionClick}
    />,
    <SelectCheckboxItem
      key={SelectOptions.NONE}
      option={{
        label: SelectOptionLabels.NONE,
        value: SelectOptions.NONE,
        selected: currentSelectOptions.includes(SelectOptions.NONE),
        showCheckbox: true,
      }}
      handleClick={handleOptionClick}
    />,
    <SelectCheckboxItem
      key="audience-label"
      option={{
        label: 'Web Audience',
        disabled: true,
        showCheckbox: false,
      }}
      handleClick={() => {}}
      type="label"
    />,
    <SelectCheckboxItem
      key={SelectOptions.FREE}
      option={{
        label: SelectOptionLabels.FREE,
        value: SelectOptions.FREE,
        selected: currentSelectOptions.includes(SelectOptions.FREE),
        showCheckbox: true,
      }}
      handleClick={handleOptionClick}
    />,
    <SelectCheckboxItem
      key={SelectOptions.PREMIUM}
      option={{
        label: SelectOptionLabels.PREMIUM,
        value: SelectOptions.PREMIUM,
        selected: currentSelectOptions.includes(SelectOptions.PREMIUM),
        showCheckbox: true,
      }}
      handleClick={handleOptionClick}
    />,
    <SelectCheckboxItem
      key={SelectOptions.BOTH_AUDIENCE}
      option={{
        label: SelectOptionLabels.BOTH_AUDIENCE,
        value: SelectOptions.BOTH_AUDIENCE,
        selected: currentSelectOptions.includes(SelectOptions.BOTH_AUDIENCE),
        showCheckbox: true,
      }}
      handleClick={handleOptionClick}
    />,
    <SelectCheckboxItem
      key="platform-label"
      option={{
        label: 'Platform',
        disabled: true,
        showCheckbox: false,
      }}
      handleClick={() => {}}
      type="label"
    />,
    <SelectCheckboxItem
      key={SelectOptions.WEB}
      option={{
        label: SelectOptionLabels.WEB,
        value: SelectOptions.WEB,
        selected: currentSelectOptions.includes(SelectOptions.WEB),
        showCheckbox: true,
      }}
      handleClick={handleOptionClick}
    />,
    <SelectCheckboxItem
      key={SelectOptions.EMAIL}
      option={{
        label: SelectOptionLabels.EMAIL,
        value: SelectOptions.EMAIL,
        selected: currentSelectOptions.includes(SelectOptions.EMAIL),
        showCheckbox: true,
      }}
      handleClick={handleOptionClick}
    />,
    <SelectCheckboxItem
      key={SelectOptions.BOTH_PLATFORM}
      option={{
        label: SelectOptionLabels.BOTH_PLATFORM,
        value: SelectOptions.BOTH_PLATFORM,
        selected: currentSelectOptions.includes(SelectOptions.BOTH_PLATFORM),
        showCheckbox: true,
      }}
      handleClick={handleOptionClick}
    />,
  ];

  const handleSelectCheck = (selectChecked: boolean) => {
    const newSelectOptions = selectChecked ? [SelectOptions.ALL] : [];

    setIndeterminate(false);
    handleSelectionChange(newSelectOptions);
  };

  const handleLocalDeleteClick = (action: PostBulkActions) => {
    if (selecting === SelectingState.NONE) {
      return;
    }

    handleDeleteClick(action);
  };

  return (
    <div className="space-x-2 w-full flex flex-row">
      <SelectCheckbox
        checked={checked}
        options={selectOptions}
        className="rounded"
        indeterminate={indeterminate}
        handleCheck={handleSelectCheck}
        alignMenu={alignMenu}
        disabled={isLoading || !canDelete}
        disabledTooltip={NO_PERMISSION_MESSAGE}
      />
      <Button
        className={`transition-opacity ease-in-out duration-50 ${
          isLoading || selecting === SelectingState.NONE ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
        }`}
        type="button"
        size="xs"
        variant="primary-inverse"
        onClick={() => handleLocalDeleteClick(PostBulkActions.DELETE)}
        disabled={isLoading || !canDelete}
        tooltip={canDelete ? undefined : NO_PERMISSION_MESSAGE}
      >
        <TrashIcon className="h-4 w-4 m-1" />
      </Button>
    </div>
  );
};

export default SelectAllCheckbox;
