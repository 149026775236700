interface Props {
  isEditing: boolean;
  changesDetected: boolean;
  isSaving: boolean;
  type?: 'button' | 'submit';
  onClick?: () => void;
  closeEditing?: () => void;
}

const SaveButton = ({ isEditing, changesDetected, isSaving, type = 'button', onClick, closeEditing }: Props) => {
  const isShowing = isEditing || changesDetected;
  const closeEditingFunc = closeEditing || (() => {});

  return (
    <div>
      {isShowing && (
        <div>
          {isSaving ? (
            <div>
              <div className="text-primary-500 flex items-center space-x-1 py-1 px-2 rounded hover:bg-gray-100">
                <span className="font-semibold text-sm">Saving...</span>
              </div>
            </div>
          ) : (
            <div>
              <button
                // eslint-disable-next-line react/button-has-type
                type={type}
                onClick={changesDetected ? onClick : closeEditingFunc}
                className="text-primary-500 flex items-center space-x-1 py-1 px-2 rounded hover:bg-gray-100"
              >
                <span className="font-semibold text-sm">{changesDetected ? 'Save' : 'Done'}</span>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SaveButton;
