import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostAgreement, BoostAgreementStatus } from '@/interfaces/boosts/grow/boost_agreement';
import { OrderBy } from '@/interfaces/boosts/order';
import { Order } from '@/interfaces/general';
import api from '@/services/swarm';

interface Props {
  boostOfferId: string;
  onSuccess: (result: BoostAgreement) => void;
  shouldInvalidate?: boolean;
}

interface Variables {
  boostAgreementId: string;
  transition_to?: BoostAgreementStatus;
  affiliate_enabled?: boolean;
  rejected_reason?: string;
}

const useUpdateBoostAgreement = ({ boostOfferId, onSuccess, shouldInvalidate = true }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const updateBoostAgreement = ({
    boostAgreementId,
    transition_to,
    affiliate_enabled,
    rejected_reason,
  }: Variables): Promise<BoostAgreement> =>
    api
      .patch(`/boosts/grow/boost_agreements/${boostAgreementId}`, {
        publication_id: currentPublicationId,
        boost_offer_id: boostOfferId,
        boost_agreement: {
          transition_to,
          affiliate_enabled,
          rejected_reason,
        },
      })
      .then((res) => res.data);

  return useMutation(updateBoostAgreement, {
    onSuccess: (result) => {
      onSuccess(result);

      if (!shouldInvalidate) {
        return;
      }

      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_agreements']);
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_sends', result.id]);
      // Live boost agreement
      queryClient.invalidateQueries([
        currentPublicationId,
        'boosts',
        'grow',
        'boosts_agreements',
        undefined,
        BoostAgreementStatus.ACTIVE,
        undefined,
        undefined,
        'all',
        OrderBy.TOTAL_SUBSCRIBER_COUNT,
        Order.DESC,
      ]);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
    },
  });
};

export default useUpdateBoostAgreement;
