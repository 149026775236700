import { useMemo } from 'react';

import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

import { eventTypeDropdownOptions } from '../_constants';

const useEventTypeDropdownOptions = (selectedTypes: ActivityFeedEventType[]) => {
  return useMemo(() => {
    if (selectedTypes.length === 0) return [];

    return [
      { label: 'All Events', value: '' },
      ...eventTypeDropdownOptions.filter((option) => selectedTypes.includes(option.value)),
    ];
  }, [selectedTypes]);
};

export default useEventTypeDropdownOptions;
