import cx from 'classnames';

type ColProps = React.PropsWithChildren<{
  className?: string;
}>;

const Col = ({ className, children }: ColProps) => {
  const classNames = cx('px-6 py-4 whitespace-nowrap', className);

  return <td className={classNames}>{children}</td>;
};

export default Col;
