import * as React from 'react';
import toast from 'react-hot-toast';
import { useInView } from 'react-intersection-observer';

import { Input } from '@/components/Form';
import Header from '@/components/Header';
import RichContent from '@/components/RichContent';
import Stack from '@/components/Stack';
import Text from '@/components/Text';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TiptapState } from '@/interfaces/editor_versions';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

interface FormProps {
  name?: string;
  tiptapState?: TiptapState;
  onSubmit: (name: string, templateContent: TiptapState) => Promise<void>;
  header: string;
  description?: string;
}

const Form: React.FC<FormProps> = ({ name: originalName, tiptapState, onSubmit, header, description }) => {
  const [name, setName] = React.useState(originalName || '');
  const [templateContent, setTemplateContent] = React.useState(tiptapState || {});
  const [loading, setLoading] = React.useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const platforms = ['Email', 'Web'];

  const onFetchPreview = (platform: string, onFetch: Function) => {
    const postTemplate = {
      tiptap_state: templateContent,
    };

    api
      .post(`/post_templates/preview`, {
        publication_id: currentPublicationId,
        post_template: postTemplate,
        platform,
      })
      .then((resp) => {
        onFetch(resp.data.html);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  };

  const handleSubmit = () => {
    setLoading(true);

    onSubmit(name, templateContent).finally(() => {
      setLoading(false);
    });
  };

  const { ref, inView: bottomButtonInView } = useInView({
    threshold: 0,
  });

  return (
    <div id="post-template-form">
      <div className="flex justify-between items-end">
        <div>
          <Header>{header}</Header>
          {description && <Text>{description}</Text>}
        </div>
        <div className={`opacity-0 duration-100 ${!bottomButtonInView && 'opacity-100'}`}>
          <Button onClick={handleSubmit} loading={loading}>
            Save
          </Button>
        </div>
      </div>

      <div className="my-4">
        <hr />
      </div>

      <Stack gap={4} direction="col">
        <div className="w-full md:w-1/3">
          <Input
            name="name"
            labelText="Name"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            required
          />
        </div>
        <RichContent
          name="templateContent"
          labelText="Content"
          publicationId={currentPublicationId}
          initialValue={templateContent}
          onChange={(value) => setTemplateContent(value)}
          onFetchPreview={onFetchPreview}
          required
          platforms={platforms}
          allowPolls
          allowAds
        />
        <div className="flex justify-end">
          <Button onClick={handleSubmit} loading={loading} ref={ref}>
            Save
          </Button>
        </div>
      </Stack>
    </div>
  );
};

export default Form;
