import LoadingBox from '@/components/LoadingBox';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import { Card } from '@/ui/Card';

import { useBillingSummary } from '../../_hooks';

import InvoiceCard from './InvoiceCard';

const BillingSummary: React.FC<{ organizationId: string }> = ({ organizationId }) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data, isLoading, isError } = useBillingSummary({ organizationId, publicationId: currentPublicationId });

  const { last_invoice: lastInvoice, next_invoice: nextInvoice } = data || {};

  return (
    <Card>
      <BodyContainer>
        <CardHeader title="Billing Summary" />
        <LoadingBox
          isLoading={isLoading}
          isError={isError}
          backgroundClassName="bg-transparent"
          errorText="Something went wrong."
          height={150}
        >
          {data && (
            <div className="gap-6 flex flex-col md:flex-row">
              <InvoiceCard
                title="Last Payment"
                timestamp={lastInvoice?.timestamp}
                amount={lastInvoice?.amount}
                interval={lastInvoice?.cadence}
              />
              <InvoiceCard
                title="Next Payment Due"
                timestamp={nextInvoice?.timestamp}
                amount={nextInvoice?.amount}
                interval={nextInvoice?.cadence}
              />
            </div>
          )}
        </LoadingBox>
      </BodyContainer>
    </Card>
  );
};

export default BillingSummary;
