import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';

import { findNextColumnNodeEndPosition } from './utils';

export const Column = Node.create({
  name: 'column',

  content: '(block|serviceEmbed|codeBlockGroup)+',

  isolating: true,

  addAttributes() {
    return {
      // Deprecated but used in old documents
      position: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-position'),
        renderHTML: (attributes) => ({ 'data-position': attributes.position }),
      },
      width: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-has-width': attributes.width ? true : undefined,
          style: attributes.width ? `width: ${attributes.width}%` : undefined,
        }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'column' }), 0];
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="column"]',
      },
    ];
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      ...(this.parent?.() || []),
      new Plugin({
        props: {
          handleKeyDown: (view: EditorView, event: KeyboardEvent) => {
            if (event.key === 'Tab') {
              const isColumns = editor.isActive('columns');

              if (isColumns) {
                const nextColumnPos = findNextColumnNodeEndPosition(editor);

                if (nextColumnPos) {
                  editor.commands.focus(nextColumnPos, { scrollIntoView: false });
                }
              }
            }

            return false;
          },
        },
      }),
    ];
  },
});

export default Column;
