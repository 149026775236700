import { useCallback } from 'react';
import { CaretDown, Lightning, Question } from '@phosphor-icons/react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/routes/website/_components/UI/DropdownMenu';
import { Text } from '@/routes/website/_components/UI/Text';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

import { AttributeSettingProps } from '../types';

export const ActionSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const currentValue = activeNodeAttributes?.action || 'none';
  const handleActionChange = useCallback(
    (value: string) => {
      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        if (value === 'none') {
          tr.setNodeAttribute(activeNodePos, 'action', null);
        } else {
          tr.setNodeAttribute(activeNodePos, 'action', value);
        }
        return true;
      });
    },
    [activeNodePos, activeNodeType, editor]
  );

  const options: Record<
    string,
    {
      title: string;
      description: string;
    }
  > = {
    none: {
      title: 'None',
      description: 'No action will be performed when the button is clicked.',
    },
    sign_up: {
      title: 'Sign Up',
      description: 'Opens the sign up modal',
    },
    login: {
      title: 'Login',
      description: 'Opens the login modal',
    },
    profile: {
      title: 'Profile',
      description: "Opens the current user's profile page",
    },
    search: {
      title: 'Search',
      description: 'Opens the search modal',
    },
  };

  return (
    <div className="flex items-center justify-stretch gap-2">
      <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
        <Text variant="secondary" size="2xs" weight="medium">
          Action
        </Text>
        <Tooltip placement="bottom-end" center={options[currentValue]?.description}>
          <Question size={16} weight="bold" className="text-wb-secondary" />
        </Tooltip>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="cursor-pointer">
          <div className="grow w-full bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-1">
            <div className="flex items-center gap-2">
              <div className="flex items-center justify-center w-6 h-6 bg-wb-accent rounded-md">
                <Lightning size={12} className="text-wb-on-accent" weight="fill" />
              </div>
              <Text size="2xs" weight="medium" variant={currentValue === 'none' ? 'secondary' : 'primary'}>
                {options[currentValue]?.title}
              </Text>
            </div>
            <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer" align="end">
          {Object.keys(options).map((optionKey) => (
            <DropdownMenuItem
              key={optionKey}
              onSelect={() => {
                handleActionChange(optionKey);
              }}
            >
              {options[optionKey]?.title}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
