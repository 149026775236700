import { MultiSelect } from '@/components/Form';
import { WebhooksEventType } from '@/interfaces/webhooks/endpoint';

import { StepProps } from './types';

const eventTypeOptions = Object.values(WebhooksEventType).map((eventType) => [eventType, eventType]);

const EventsStep = ({ endpoint, errors, onChange }: StepProps) => {
  const { event_types: eventTypes } = endpoint;

  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...endpoint,
        [field]: value,
      });
    }
  };

  return (
    <div className="flex flex-col">
      <MultiSelect
        options={eventTypeOptions}
        currentSelections={eventTypes || []}
        inputName="event_types"
        onChange={(selected) => handleChange('event_types', selected as WebhooksEventType[])}
        label="Event Types *" // Indicate this is a required field
      />
      {errors?.event_types && <span className="text-red-500 text-xs">{errors.event_types}</span>}
    </div>
  );
};

export default EventsStep;
