import { toSentence } from '@/utils';
import mappedCountries from '@/utils/mappedCountries';
import pluralize from '@/utils/pluralize';

const MAX_COUNTRIES_IN_TOOLTIP = 2;

const useBoostOfferEligibleCountries = (eligibleCountries: string[] | null) => {
  if (!eligibleCountries || eligibleCountries.length === 0) {
    return {
      hasRegionalRestrictions: false,
      regionalRestrictionsTooltip: '',
      regionalRestrictionsCountriesLabel: 'All countries',
      allMappedEligibleCountries: [],
    };
  }

  const allMappedEligibleCountries = eligibleCountries.map((countryCode) => mappedCountries[countryCode]);
  const countriesForTooltip = allMappedEligibleCountries.slice(0, MAX_COUNTRIES_IN_TOOLTIP);
  const regionalRestrictionsCountriesLabel = pluralize('country', eligibleCountries.length, 'countries');

  if (eligibleCountries.length <= MAX_COUNTRIES_IN_TOOLTIP) {
    return {
      hasRegionalRestrictions: true,
      regionalRestrictionsTooltip: `${toSentence(
        'Only subscribers from',
        countriesForTooltip
      )} are eligible for Boost payouts from this offer.`,
      regionalRestrictionsCountriesLabel,
      allMappedEligibleCountries,
    };
  }

  const remainingCountries = eligibleCountries.length - MAX_COUNTRIES_IN_TOOLTIP;
  const countriesStringWithTruncation = toSentence('Only subscribers from', [
    ...countriesForTooltip,
    `${remainingCountries} more`,
  ]);

  return {
    hasRegionalRestrictions: true,
    regionalRestrictionsTooltip: `${countriesStringWithTruncation} are eligible for Boost payouts from this offer.`,
    regionalRestrictionsCountriesLabel,
    allMappedEligibleCountries,
  };
};

export default useBoostOfferEligibleCountries;
