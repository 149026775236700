import { EditorState } from '@tiptap/pm/state';
import { TableMap } from '@tiptap/pm/tables';
import { EditorView } from '@tiptap/pm/view';
import { Editor } from '@tiptap/react';

import { Table } from '../..';
import { isCellSelection, isTableSelected } from '../../utils';

export const isColumnGripSelected = ({
  editor,
  view,
  state,
  from,
}: {
  editor: Editor;
  view: EditorView;
  state: EditorState;
  from: number;
}) => {
  const domAtPos = view.domAtPos(from).node as HTMLElement;
  const nodeDOM = view.nodeDOM(from) as HTMLElement;
  const node = nodeDOM || domAtPos;
  let isSingleColumn = false;

  if (isCellSelection(state.selection)) {
    const map = TableMap.get((state.selection as any).$anchorCell.node(-1));
    isSingleColumn = map.width === 1;
  }

  if (!editor.isActive(Table.name) || !node || (isTableSelected(state.selection) && !isSingleColumn)) {
    return false;
  }

  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement!;
  }

  const gripColumn = container && container.querySelector && container.querySelector('a.grip-column.selected');

  return !!gripColumn;
};

export default isColumnGripSelected;
