import { useQuery } from 'react-query';

import { EmailMessage } from '../interfaces/email_message';
import api from '../services/swarm';

export default function useEmailMessage(publicationId: string, { emailMessageId }: { emailMessageId: string | undefined }) {
  const fetchEmailMessage = () =>
    api
      .get(`/email_messages/${emailMessageId}`, {
        params: {
          publication_id: publicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<EmailMessage>(['email_messages', emailMessageId], fetchEmailMessage, {
    keepPreviousData: true,
    staleTime: 30000,
    enabled: Boolean(emailMessageId),
  });
}
