import { useState } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react/headless';

import { Button } from '../../ui/Button';
import { ButtonProps } from '../../ui/Button/types';
import { Icon } from '../../ui/Icon';
import { Divider, Toolbar } from '../../ui/Toolbar';

import { tones } from './constants';

export const AiChangeTextTonePanel = ({
  callback,
  tippyOptions,
  buttonProps,
  defaultValue = undefined,
  disabled = false,
}: {
  callback: Function;
  tippyOptions?: TippyProps;
  buttonProps?: ButtonProps;
  defaultValue?: string;
  disabled?: boolean;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [tone, setTone] = useState<string | undefined>(defaultValue);

  const currentTone = tones.find((t) => t.value === tone);
  const currentToneName = currentTone?.label;
  const hasToneChanges = !!currentToneName;

  return (
    <Tippy
      render={(attrs) => (
        <Toolbar
          tabIndex={-1}
          $isVertical
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attrs}
        >
          {tones.map((t) => (
            <Button
              key={t.name}
              $variant="quaternary"
              $size="small"
              onClick={() => {
                setMenuOpen(!menuOpen);
                setTone(t.value);
                callback(t.value);
              }}
              $fullWidth
              $active={t.value === tone}
            >
              {t.label}
            </Button>
          ))}
          {hasToneChanges && (
            <>
              <Divider />
              <Button
                $variant="quaternary"
                $size="small"
                $rightSlot={<Icon name="Reset" />}
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  setTone(undefined);
                  callback(undefined);
                }}
                $fullWidth
              >
                Reset
              </Button>
            </>
          )}
        </Toolbar>
      )}
      offset={[0, 8]}
      interactive
      visible={menuOpen}
      onShow={() => {
        setMenuOpen(true);
      }}
      onHidden={() => {
        setMenuOpen(false);
      }}
      onClickOutside={() => {
        setMenuOpen(false);
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...tippyOptions}
    >
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        $variant="quaternary"
        $size="small"
        $leftSlot={<Icon name="AIAdjustTone" />}
        $rightSlot={<Icon name="ChevronDown" />}
        $active={menuOpen || hasToneChanges}
        $muted={menuOpen && !hasToneChanges}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
      >
        {currentToneName || 'Tone of voice'}
      </Button>
    </Tippy>
  );
};

export default AiChangeTextTonePanel;
