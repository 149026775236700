import { AutomationStepUpdateSubscriptionCustomField } from '@/interfaces/automations/automation_step';
import { CustomField } from '@/interfaces/custom_field';

const findCustomField = (customFieldId: string, customFields: CustomField[]) =>
  customFields.find((customField) => customField.id === customFieldId);

export const normalizeCustomFieldsValue = (
  previousValues: Array<AutomationStepUpdateSubscriptionCustomField>,
  customFields: CustomField[]
) => {
  return previousValues.map((previousValue) => {
    const { id, value } = previousValue;
    const customField = findCustomField(id, customFields);
    let newValue = value;

    if (customField?.kind === 'boolean') {
      if (!['true', 'false'].includes(value.toLowerCase().replace(/\s/g, ''))) {
        newValue = 'false';
      }
    }

    return { ...previousValue, value: newValue };
  });
};
