import { RefObject, useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

type Props = {
  currentViewIndex: number;
  index?: number;
  setSlideRef: (ref: RefObject<HTMLDivElement>) => void;
};

const Landing = ({ currentViewIndex, index, setSlideRef }: Props) => {
  const bgLines = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const contentRef = useRef<HTMLDivElement>(null);
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    const isCurrentView = currentViewIndex === index;

    if (isCurrentView) {
      setSlideRef(contentRef);
      setTimeout(() => {
        setPlayAnimation(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  return (
    <div ref={contentRef} className="w-full h-full relative overflow-hidden rewind-bg-pink">
      <div>
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
          {bgLines.map((line) => {
            return (
              <div
                key={`bg-line-${line}`}
                className="font-clash-grotesk-v font-bold text-[150px] leading-[100px] absolute whitespace-nowrap rewind-text-pink"
                style={{ marginTop: -30 + line * 100 }}
              >
                {line % 2 === 0 && (
                  <>
                    <div className="inline-block ticker">BEEHIIV REWIND BEEHIIV REWIND</div>
                    <div className="inline-block">BEEHIIV REWIND BEEHIIV REWIND</div>
                  </>
                )}
                {line % 2 !== 0 && (
                  <>
                    <div className="inline-block -ml-[1200.5px] ticker-backwards">BEEHIIV REWIND BEEHIIV REWIND</div>
                    <div className="inline-block">BEEHIIV REWIND BEEHIIV REWIND</div>
                  </>
                )}
              </div>
            );
          })}
        </div>
        <div className="absolute top-0 left-0 w-full h-full rewind-bg-texture" />
      </div>
      <Transition
        show={playAnimation}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <div className="absolute top-0 left-0 w-[70%] h-[70%] bg-black rounded-[2rem] m-[15%_0_0_15%] p-[35px_55px] flex flex-col">
          <h2 className="font-satoshi-v rewind-text-pink uppercase font-bold text-xl w-full text-center">
            LOOKING BACK ON YOUR YEAR
          </h2>
          <h1 className="font-clash-grotesk-v text-white uppercase font-bold text-xl w-full text-center mt-auto">
            <p className="text-[14.93125rem] leading-[10.5rem] mb-2">2024</p>
            <p className="text-[10rem] leading-[7.3125rem] mb-2">beehiiv</p>
            <p className="text-[9.906875rem] leading-[7rem]">rewind</p>
          </h1>
        </div>
      </Transition>
    </div>
  );
};

export default Landing;
