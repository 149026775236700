import { FC } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

import ColorField from '../../components/Form/ColorField';

import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { ThemeFormProps } from './types';

const FooterSectionForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette, fonts }) => {
  return (
    <FormContainer>
      <FormSection title="Styles">
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.footer_font_family}
              onUpdate={(val: string) => onUpdate({ ...theme, footer_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.footer_font_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, footer_font_color: color?.hexString })}
          />
        </FormInput>
        <FormInput title="Background color">
          <ColorField
            placement="bottom"
            color={theme.footer_background_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, footer_background_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Spacing">
        <FormInput>
          <Slider
            name="footer_margin"
            label="Outer spacing"
            onChange={(val: number) => onUpdate({ ...theme, footer_margin: val.toString() })}
            initialValue={theme.footer_margin}
            min={0}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="footer_padding"
            label="Inner spacing"
            onChange={(val: number) => onUpdate({ ...theme, footer_padding: val.toString() })}
            initialValue={theme.footer_padding}
            min={0}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Social Links">
        <FormInput title="Background color">
          <ColorField
            placement="bottom"
            color={theme.footer_social_background_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, footer_social_background_color: color?.hexString })}
          />
        </FormInput>
        <FormInput title="Icon color">
          <ThemeEditorDropdown
            currentValue={theme.footer_social_icon_color}
            onUpdate={(val: string) => onUpdate({ ...theme, footer_social_icon_color: val })}
            options={[
              { label: 'Light', value: 'Light' },
              { label: 'Dark', value: 'Dark' },
            ]}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Top Border">
        <FormInput>
          <Slider
            name="footer_top_border_width"
            label="Border thickness"
            onChange={(val: number) => onUpdate({ ...theme, footer_top_border_width: val.toString() })}
            initialValue={theme.footer_top_border_width}
            min={0}
            max={10}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Border color">
          <ColorField
            placement="bottom"
            color={theme.footer_top_border_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, footer_top_border_color: color?.hexString })}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="footer_top_border_size"
            label="Border width"
            onChange={(val: number) => onUpdate({ ...theme, footer_top_border_size: val.toString() })}
            initialValue={theme.footer_top_border_size}
            min={10}
            max={100}
            valueSuffix="%"
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Custom Content">
        <FormInput>
          <a href="/custom_footer/edit" target="_blank">
            <button type="button" className="text-sm text-gray-500 hover:text-primary-500">
              <div className="flex items-center">
                <span className="flex items-start">
                  <span className="px-1">Add Custom Content To Footer</span>
                  <ArrowTopRightOnSquareIcon width={15} height={15} />
                </span>
              </div>
            </button>
          </a>
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default FooterSectionForm;
