import { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { icons as phosphorIcons, PhosphorIcon } from '@phosphor-icons/core';
import { MagnifyingGlass } from '@phosphor-icons/react';
import * as Icons from '@phosphor-icons/react';

import { SimpleInput, SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Text } from '@/routes/website/_components/UI/Text';
import { cn } from '@/utils/cn';

type IconSelectorProps = {
  onChange: (value: { type: string; iconType: string; iconName: string }) => void;
};

const ITEMS_PER_PAGE = 30;

const allIcons = phosphorIcons;

const DynamicIcon = ({ icon, onClick, isActive }: { icon: PhosphorIcon; onClick: () => void; isActive: boolean }) => {
  const IconComponent = Icons[icon.pascal_name] as Icons.Icon;

  return (
    <div
      key={icon.name}
      className={cn(
        'flex flex-col items-center gap-1 cursor-pointer rounded-md py-4 px-2 grow-0 border',
        isActive ? 'bg-wb-button-accent-soft border-wb-accent-soft' : 'bg-wb-secondary border-transparent'
      )}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      data-active={isActive}
    >
      <IconComponent className="w-6 h-6" />
      <Text
        size="3xs"
        weight="medium"
        className="capitalize text-ellipsis overflow-hidden whitespace-nowrap max-w-full"
      >
        {icon.pascal_name}
      </Text>
    </div>
  );
};

const iconsFilter = (icon: PhosphorIcon, searchTerm: string) => {
  if (!searchTerm) return true;

  return (
    icon.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase())) ||
    icon.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    icon.pascal_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

export const IconSelector = ({ onChange }: IconSelectorProps) => {
  const [selectedIcon, setSelectedIcon] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleIcons, setVisibleIcons] = useState<Array<PhosphorIcon>>([]);
  const loadingRef = useRef(false);

  const observerTarget = useRef(null);

  const loadMoreIcons = useCallback(
    (reset = false) => {
      if (loadingRef.current) return;
      loadingRef.current = true;

      const startIndex = reset ? 0 : visibleIcons.length;
      const newIcons = allIcons
        .filter((icon) => iconsFilter(icon, searchTerm))
        .slice(startIndex, startIndex + ITEMS_PER_PAGE);

      // @ts-ignore
      setVisibleIcons((prev) => (reset ? newIcons : [...prev, ...newIcons]));
      loadingRef.current = false;
    },
    [visibleIcons, searchTerm]
  );

  const handleSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setSearchTerm(e.target.value);
      if (!e.target.value) {
        return;
      }

      // filter icons
      loadMoreIcons(true);
    },
    [loadMoreIcons]
  );

  const handleSelectIcon = useCallback(
    (iconName: string) => () => {
      setSelectedIcon(iconName);
      onChange({ type: 'icon', iconType: 'phosphor', iconName });
    },
    [onChange]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loadingRef.current && !searchTerm) {
          loadMoreIcons();
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [loadingRef, loadMoreIcons, visibleIcons, searchTerm]);

  return (
    <div className="grow pt-3 flex flex-col gap-4 max-h-full min-h-0">
      <SimpleInputWrapper className="grow-0">
        <MagnifyingGlass />
        <SimpleInput placeholder="Search icons" onChange={handleSearchChange} />
      </SimpleInputWrapper>
      <div className="grid overflow-y-auto grid-cols-3 gap-2">
        {visibleIcons.map((icon) => (
          <DynamicIcon
            icon={icon}
            isActive={selectedIcon === icon.pascal_name}
            onClick={handleSelectIcon(icon.pascal_name)}
          />
        ))}
        <div ref={observerTarget} className="h-4" />
      </div>
    </div>
  );
};
