import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  onSuccess?: () => void;
}

interface SiteThemePayload {
  name: string;
  data: any;
}

export default function useCreateSiteTheme({ onSuccess }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    ({ name, data }: SiteThemePayload) =>
      api.post(`/site_themes`, {
        publication_id: currentPublicationId,
        site_theme: {
          name,
          data,
        },
      }),
    {
      onSuccess: () => {
        onSuccess?.();
        toast.success('Created!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
}
