import SelectAllCheckbox, { SelectOptions } from '@/components/SelectAllCheckbox';
import { SelectingState } from '@/components/SelectCheckbox/SelectCheckbox';
import Text from '@/components/Text';
import { Order } from '@/interfaces/general';
import { Post, PostBulkActions, PostPreview } from '@/interfaces/post';

import Sort from '../Sort';

import { DeletingMessage, SelectedMessage } from './messages';

type HeaderProps = {
  currentSelectOptions: SelectOptions[];
  handleDeleteClick: (type: PostBulkActions) => void;
  handleOrderClick: () => void;
  handleSelectionChange: (currentSelectOptions: SelectOptions[]) => void;
  isLoading: boolean;
  messageType: MessageTypes;
  orderDir: Order;
  selectCheckOptions: {
    currentSelectOptions: SelectOptions[];
    posts: (Post | PostPreview)[];
    selectedPosts: string[];
    setSelectedPosts: (value: string[]) => void;
    showingPostsCount?: number;
    totalPostCount?: number;
  };
  selectedPostCount: number;
  selectedPosts: string[];
  selecting: SelectingState;
  showingPosts: string[];
  totalPostCount: number;
};

export enum MessageTypes {
  NONE = '',
  SELECTING = 'selecting',
  DELETING = 'deleting',
}

const Header = (props: HeaderProps) => {
  const {
    currentSelectOptions,
    handleDeleteClick,
    handleOrderClick,
    handleSelectionChange,
    isLoading,
    messageType = MessageTypes.NONE,
    orderDir,
    selectCheckOptions,
    selectedPostCount,
    selectedPosts,
    selecting,
    showingPosts,
    totalPostCount,
  } = props;

  const selectedShowingPosts = showingPosts.filter((id) => selectedPosts.includes(id));

  return (
    <>
      <div className="flex justify-end mb-4">
        <SelectAllCheckbox
          isLoading={isLoading}
          selecting={selecting}
          selectCheckOptions={selectCheckOptions}
          totalPostCount={totalPostCount}
          handleSelectionChange={handleSelectionChange}
          handleDeleteClick={handleDeleteClick}
        />
      </div>

      <div className="mb-4 flex flex-row justify-between items-center">
        <div className="flex flex-row space-x-4 items-center">
          <Text size="sm" className="font-semibold text-gray-600 py-2">
            Showing {showingPosts.length} of {totalPostCount} results
          </Text>
        </div>

        <div className="flex space-x-1 items-center whitespace-nowrap">
          <Text size="sm" className="font-semibold text-gray-600 py-2">
            Sort by:
          </Text>

          <Sort orderDir={orderDir} handleClick={handleOrderClick} />
        </div>
      </div>

      {messageType === MessageTypes.SELECTING && (
        <SelectedMessage
          currentSelectOptions={currentSelectOptions}
          selectedShowingPosts={selectedShowingPosts}
          selectedPostCount={selectedPostCount}
          showingPosts={showingPosts}
          isLoading={isLoading}
        />
      )}

      {messageType === MessageTypes.DELETING && <DeletingMessage />}
    </>
  );
};

export default Header;
