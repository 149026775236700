import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { Status } from '../../interfaces/custom_page';
import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

interface Payload {
  overrideCustomPageId?: string;
  custom_page: {
    status?: Status;
  };
  nav_link: {
    nav_group_id?: string | null;
  };
}

interface Props {
  customPageId: string;
  onSuccess: (response: any) => void;
}

// See custom_pages_controller.rb for the API endpoint
// The endpoint is expecting a custom_page object and a nav_link object
const useUpdateCustomPage = ({ onSuccess, customPageId }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (payload: Payload) => {
      const pageId = payload?.overrideCustomPageId || customPageId;
      return api.patch(`/custom_pages/${pageId}`, {
        publication_id: currentPublication?.id,
        ...payload,
      });
    },
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'There was an issue updating this Custom Page.');
      },
    }
  );
};

export default useUpdateCustomPage;
