import { Transition } from '@headlessui/react';

type SlideDownProps = {
  show: boolean;
  height?: string;
  duration?: number;
  children: React.ReactNode;
};

const SlideDown = ({ show, height = 'full', duration = 150, children }: SlideDownProps) => {
  if (duration === 0) {
    return <div className={show ? 'block' : 'hidden'}>{children}</div>;
  }

  return (
    <Transition
      show={show}
      className={`transition-all duration-${duration} overflow-visible`}
      enterFrom="transform scale-95 opacity-0 max-h-0"
      enterTo={`transform scale-100 opacity-100 max-h-${height} h-${height}`}
      leaveFrom={`transform scale-100 opacity-100 max-h-${height} h-${height}`}
      leaveTo="transform scale-95 opacity-0 max-h-0"
    >
      {children}
    </Transition>
  );
};

export default SlideDown;
