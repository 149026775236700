import Text from '@/components/Text';
import { ActivityFeedEvent, ActivityFeedEventType } from '@/interfaces/activity_feed_event';

import ResourceLink from './ResourceLink';

const EventTitle = ({ event }: { event: ActivityFeedEvent }) => {
  switch (event.type) {
    case ActivityFeedEventType.SENT:
      return (
        <Text size="sm">
          Sent <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.DELIVERED:
      return (
        <Text size="sm">
          Delivered <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.OPEN:
      return (
        <Text size="sm">
          Opened <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.CLICK:
      return (
        <Text size="sm">
          Clicked <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.UNSUBSCRIBE:
      if (event.resource_type === 'subscription') {
        // This is an unsubscribe event coming from Views::SubscriptionProfileEvent
        // The resource data is pointing to the subscription that was unsubscribed so we shouldn't try to link to it
        return (
          <Text size="sm" className="font-semibold text-gray-900">
            Unsubscribed
          </Text>
        );
      }

      return (
        <Text size="sm">
          Unsubscribed via <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.SPAM_REPORT:
      return (
        <Text size="sm">
          <ResourceLink event={event} /> reported as spam
        </Text>
      );
    case ActivityFeedEventType.DEFERRAL:
      return (
        <Text size="sm">
          <ResourceLink event={event} /> delivery deferred
        </Text>
      );
    case ActivityFeedEventType.DELIVERY_ERROR:
      return (
        <Text size="sm">
          <ResourceLink event={event} /> delivery error -{' '}
          <span className="capitalize font-semibold">{event.metadata?.code}</span>
        </Text>
      );
    case ActivityFeedEventType.COMMENT:
      return (
        <Text size="sm">
          {event.metadata?.comment_id ? 'Replied to comment on ' : 'Commented on '}
          <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.AUTOMATION_ENROLLED:
      return (
        <Text size="sm">
          Enrolled in <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.AUTOMATION_COMPLETED:
      return (
        <Text size="sm">
          Completed <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.AUTOMATION_EXITED_EARLY:
      return (
        <Text size="sm">
          Exited <ResourceLink event={event} /> early
        </Text>
      );
    case ActivityFeedEventType.AUTOMATION_UNSUBSCRIBE:
      return (
        <Text size="sm">
          Unsubscribed via <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.POLL_RESPONSE:
      return (
        <Text size="sm">
          Responded{' '}
          <Text as="span" type="bold">
            {event.metadata?.choice}
          </Text>{' '}
          to <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.POLL_EXTENDED_FEEDBACK:
      return (
        <Text size="sm">
          Commented on <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.REFERRAL_MILESTONE_ACHIEVED:
      return (
        <Text size="sm">
          Achieved <ResourceLink event={event} />
        </Text>
      );
    case ActivityFeedEventType.REFERRAL_MILESTONE_EMAIL_SENT:
      return (
        <Text size="sm">
          Sent <ResourceLink event={event} /> milestone email
        </Text>
      );
    case ActivityFeedEventType.REFERRAL:
      return (
        <Text size="sm">
          <ResourceLink event={event} /> referred to{' '}
          <Text as="span" type="bold" className="capitalize">
            {event.metadata?.tier}
          </Text>{' '}
          tier
        </Text>
      );
    case ActivityFeedEventType.SUBSCRIBE:
      return (
        <Text size="sm" className="font-semibold text-gray-900">
          Subscribed
        </Text>
      );
    case ActivityFeedEventType.UPGRADE:
      return (
        <Text size="sm" className="font-semibold text-gray-900">
          Upgraded <Text as="span" size="sm">{`to ${event.resource_title}`}</Text>
        </Text>
      );
    case ActivityFeedEventType.DOWNGRADE:
      return (
        <Text size="sm" className="font-semibold text-gray-900">
          Downgraded <Text as="span" size="sm">{`from ${event.resource_title}`}</Text>
        </Text>
      );
    case ActivityFeedEventType.PAYMENT_SUCCEEDED:
      return (
        <Text size="sm">
          Paid{' '}
          <Text as="span" size="sm" className="font-semibold text-gray-900">
            {event.metadata.formatted_amount}
          </Text>{' '}
          for {event.resource_title}
        </Text>
      );
    case ActivityFeedEventType.PAYMENT_FAILED:
      return (
        <Text size="sm">
          Payment{' '}
          <Text as="span" size="sm" className="font-semibold text-gray-900">
            {event.metadata.formatted_amount}
          </Text>{' '}
          failed for {event.resource_title}
        </Text>
      );
    default:
      return (
        <Text size="sm">
          Unknown Event for <ResourceLink event={event} />
        </Text>
      );
  }
};

export default EventTitle;
