import { FC, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

export type ActionOption = {
  name: string;
  onClick: (node: any) => void;
  isVisible: (node: any) => boolean;
  infoText?: string | string[];
  isCurrent?: boolean;
};

export interface ActionsDropdownProps {
  size?: 'sm' | 'md';
  actions: ActionOption[][]; // Nested array of actions. Each nested array is a section of actions.
  buttonText?: string;
  node: any; // Some record/object the actions are being taken on. Passed into the isVisible and action functions.
  ref?: any;
  dropdownClass?: string;
  buttonClass?: string;
  optionClass?: string;
  disabled?: boolean;
}

const ActionsDropdown: FC<ActionsDropdownProps> = ({
  actions,
  buttonText = 'Actions',
  node,
  size = 'md',
  ref,
  dropdownClass = 'right-0',
  buttonClass = '',
  optionClass = '',
  disabled,
}: ActionsDropdownProps) => {
  const isSmall = size === 'sm';

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          disabled={disabled}
          className={cx(
            'bg-white inline-flex justify-center w-full text-sm font-medium text-gray-800 border border-surface-200 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 z-0 whitespace-nowrap',
            isSmall ? 'px-2 py-1' : 'px-4 py-2',
            buttonClass
          )}
        >
          {buttonText}
          <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1 text-gray-800" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cx(
            'z-10 absolute w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            dropdownClass
          )}
        >
          {actions.map((section) => (
            <div className="px-1 py-1" key={JSON.stringify(section)}>
              {section.map((action) => {
                if (!action.isVisible(node)) {
                  return null;
                }

                return (
                  <div key={action.name}>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          data-tip
                          data-for={action.name}
                          className={cx(
                            active ? 'bg-primary-100 text-primary-800' : 'text-gray-900',
                            action.isCurrent ? 'text-primary-800' : 'text-gray-900',
                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                            optionClass
                          )}
                          type="button"
                          onClick={() => action.onClick(node)}
                        >
                          {action.name}
                        </button>
                      )}
                    </Menu.Item>
                    {action.infoText && (
                      <ReactTooltip
                        id={action.name}
                        ref={ref}
                        className="overflow-visible"
                        place="bottom"
                        type="dark"
                        effect="solid"
                      >
                        <span className="text-sm text-gray-300">
                          {Array.isArray(action.infoText)
                            ? action.infoText.map((string: string) => <p>{string}</p>)
                            : action.infoText}
                        </span>
                      </ReactTooltip>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ActionsDropdown;
