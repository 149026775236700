import cx from 'classnames';

import { Calendar } from '@/hooks/useCalendar/types';

import { useCalendarContext } from '../useCalendarContext';

const Indicator = ({ calendar }: { calendar: string }) => {
  const { calendars } = useCalendarContext();

  const colorClass = {
    pink: 'bg-pink-500',
    violet: 'bg-violet-500',
  }[(calendars.find((c) => c.id === calendar) as Calendar).color];

  return <div className={cx('flex-shrink-0 h-5 w-5 rounded-sm mt-0.5', colorClass)}>&nbsp;</div>;
};

export default Indicator;
