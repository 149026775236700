import { useState } from 'react';

import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import { Input } from '../../../../../components/Form';
import { NavGroup } from '../../../../../interfaces/nav_group';
import { capitalize } from '../../../../../utils';

interface Props {
  navLink: any;
  navGroups: NavGroup[];
  href?: string;
  onSubmit: (navLink: any) => void;
  onClose: () => void;
  isLoading: boolean;
}

const ExternalLinkForm = ({ navLink, navGroups, onSubmit, isLoading, onClose }: Props) => {
  const [cta, setCta] = useState(navLink?.cta_text || '');
  const [link, setLink] = useState(navLink?.href || '');
  const [navId, setNavId] = useState(navLink?.nav_group?.id || '');
  const btnText = navLink ? 'Save' : 'Create';
  const btnLoadingText = navLink ? 'Saving...' : 'Creating...';

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit({
      href: link,
      managed: false,
      cta_text: cta,
      external_link: true,
      nav_group_id: navId,
    });
  };

  const navGroupOptions = navGroups
    .filter((navGroup: NavGroup) => !navGroup.is_category_group)
    .map((navGroup: NavGroup) => ({
      value: navGroup.id,
      label: `Navbar: ${capitalize(navGroup.title)}`,
    }));

  navGroupOptions.unshift({
    value: '',
    label: 'Draft',
  });

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Input
        name="cta_text"
        labelText="Title"
        type="text"
        value={cta}
        placeholder="About Us"
        helperText="This is the text that users will see on the site to navigate to this page."
        required
        onChange={(e: any) => setCta(e.target.value)}
      />
      <Input
        name="href"
        labelText="Link"
        type="text"
        value={link}
        placeholder="https://example.com"
        helperText="The URL to link to"
        required
        onChange={(e: any) => setLink(e.target.value)}
      />
      <div>
        <Dropdown
          name="nav_group_id"
          labelText="Add to Menu Item?"
          value={navId}
          onSelect={(_name: string, value: string) => setNavId(value)}
          helperText="By setting a Menu Item, this page will be live on your site within the Menu Item's dropdown."
          options={navGroupOptions}
        />
        {navGroupOptions.length === 1 && (
          <p className="mt-2 text-xs text-blue-400">
            *There are currently no navigation groups available, please create one in the Navigation Section above
            before you can set this live.
          </p>
        )}
      </div>
      <div className="flex justify-end space-x-2 pt-4">
        <Button variant="primary-inverse" type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={isLoading} type="submit">
          {isLoading ? btnLoadingText : btnText}
        </Button>
      </div>
    </form>
  );
};

export default ExternalLinkForm;
