import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import useFunds from '@/hooks/boosts/grow/useFunds';
import { Button } from '@/ui/Button';

const AddFundsButton = () => {
  const { canAddFunds, isProcessingCheckout, handleClickAddFunds, addFundsButtonlabel } =
    useFunds();

  if (!canAddFunds) {
    return null;
  }

  return (
    <Button
      disabled={isProcessingCheckout}
      type="button"
      variant="primary"
      onClick={handleClickAddFunds}
      iconRight
      Icon={ArrowUpRightIcon}
      size="xs"
    >
      {addFundsButtonlabel}
    </Button>
  );
};

export default AddFundsButton;
