import { Extension, KeyboardShortcutCommand } from '@tiptap/core';

interface ShortcutsOptions {
  additionalShortcuts: Record<string, KeyboardShortcutCommand>;
}

const extensionName = 'shortcuts';

const defaultShortcuts: ShortcutsOptions['additionalShortcuts'] = {
  'Mod-a': ({ editor }) => editor.commands.selectParentNode(),
};

export const Shortcuts = Extension.create<ShortcutsOptions>({
  name: extensionName,

  addOptions() {
    return {
      additionalShortcuts: {},
    };
  },

  addKeyboardShortcuts() {
    return {
      ...defaultShortcuts,
      ...(this.options.additionalShortcuts || {}),
    };
  },
});

export default Shortcuts;
