import { AdNetworkDisbursementStatus } from '@/interfaces/ad_network/internal/disbursement/types';

export type DisbursementStatusFilter = AdNetworkDisbursementStatus | 'all';

export interface DisbursementSearchParams {
  query: string;
  status: DisbursementStatusFilter;
  date: string;
  payoutDate: string;
}

export interface DisbursementSearchProps {
  onChange: (search: DisbursementSearchParams) => void;
}

export const statusFilterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Awaiting Approval', value: 'awaiting_approval' },
  { label: 'Manual', value: 'manual' },
  { label: 'Approved', value: 'approved' },
  { label: 'Paid', value: 'paid' },
];
