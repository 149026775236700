import { Info } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';

type Props = AttributeSettingProps & {};

export const HelperSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Helper" text="Helper" popoverTitle="Helper" Icon={Info}>
      <>
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          property="helperTextFontFamily"
          title="Font"
        />
        <ColorSettings title="Color" editor={editor} activeNodeResult={activeNodeResult} property="helperTextColor" />
      </>
    </PopoverHelper>
  );
};

export default HelperSettings;
