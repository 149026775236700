import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FileInput } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';
import { Button } from '@/ui/Button';

import Wizard from '../_components/Wizard';

import useCreateStripeMigration from './_hooks/useCreateStripeMigration';

const STRIPE_GUIDE_URL = 'https://stripe.com/docs/payments/account/data-migrations/pan-copy-self-serve';
const STRIPE_DOCUMENTS_URL = 'https://dashboard.stripe.com/settings/documents';

const StripeMigrationWizardStepOne = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState<File>();

  const { mutate, isLoading } = useCreateStripeMigration({
    onSuccess: ({ id }) => navigate(`/stripe_migrations/${id}/account_details`),
  });

  return (
    <Wizard step={1}>
      <TypographyStack className="mt-8 py-4 px-4 sm:px-6" gap="4">
        <Typography>
          First you will need to follow the steps laid out in{' '}
          <a className="underline text-action-tertiary-600" href={STRIPE_GUIDE_URL} target="_blank" rel="noreferrer">
            this
          </a>{' '}
          guide to do a <b>full copy</b> of your customer&apos;s PAN data into the stripe account that is connected to
          your beehiiv account. This will ensure that we are able to link the subscriptions created during the migration
          process to the proper customers. Once the copy is initiated in Stripe, it can take up to 72 hours to complete
          depending on the number of customers you have, though it generally does not take this long.
        </Typography>
        <Typography>
          Once the PAN data has been successfully copied between Stripe accounts, you should now see all of your
          customers reflected in your beehiiv stripe account. You will now also have access to a file that maps the
          payment method IDs used by each customer in your previous stripe acccount to the new payment method IDs that
          are in your beehiiv stripe account. This file can be found under{' '}
          <a
            className="underline text-action-tertiary-600"
            href={STRIPE_DOCUMENTS_URL}
            target="_blank"
            rel="noreferrer"
          >
            Settings &gt; Documents
          </a>
          .
        </Typography>
        <Typography>
          Please upload the file below. This will allow us to ensure that we are using the correct payment method for
          each customer when migrating over your subscriptions later in this process. Once the file is uploaded, you may
          continue.
        </Typography>
        <FileInput
          name="pan_mapping"
          labelText="PAN Mapping File"
          onFileSelect={(f) => setFile(f)}
          onFileRemoved={() => setFile(undefined)}
          file={file}
          accept=".csv"
        />
      </TypographyStack>
      <div className="mt-4 flex justify-end">
        <Button variant="primary" type="button" loading={isLoading} onClick={() => mutate({ pan_mapping_file: file })}>
          Continue
        </Button>
      </div>
    </Wizard>
  );
};

export default StripeMigrationWizardStepOne;
