import { useLayoutEffect, useRef, useState } from 'react';

import { GiphyGif } from '../../../utils/giphy.types';
import { LoadingDots } from '../../TiptapEditor/components/ui/Loader/LoadingDots';
import GiphyImageGrid from '../components/GiphyImageGrid/GiphyImageGrid';
import SearchBar from '../components/SearchBar';
import Tags from '../components/Tags';
import useGiphySearch from '../hooks/giphy/useGiphySearch';

const TAGS = [
  'Reactions',
  'Entertainment',
  'Sports',
  'Stickers',
  'Artists',
  'Animals',
  'Actions',
  'Anime',
  'Cartoons',
  'Emotions',
  'Food / Drink',
  'Gaming',
  'Holidays / Greetings',
  'Memes',
  'Clips',
  'plant',
  'portrait',
  'space',
  'technology',
  'texture',
  'wallpaper',
];

export type GiphyViewProps = {
  onMediaSelect: (gif: GiphyGif) => void;
};

const GiphyView = ({ onMediaSelect }: GiphyViewProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { gifs, loading, fetchNextPage, hasMore } = useGiphySearch({ query: searchQuery });

  useLayoutEffect(() => {
    if (!wrapperRef.current || loading) return undefined;

    const wrapper = wrapperRef.current;

    const handleScroll = () => {
      if (wrapper.scrollTop + wrapper.clientHeight >= wrapper.scrollHeight && hasMore) {
        fetchNextPage();
      }
    };

    wrapper.addEventListener('scroll', handleScroll);

    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, [fetchNextPage, hasMore, loading]);

  return (
    <div ref={wrapperRef} className="h-full w-full overflow-x-hidden overflow-y-auto">
      <div className="sticky top-0 z-10">
        <SearchBar value={searchQuery} onChange={setSearchQuery} placeholder="Search Giphy..." />
      </div>
      <div className="max-w-full px-6">
        {!gifs ||
          (gifs.length === 0 && searchQuery.length === 0 && (
            <Tags tags={TAGS} onTagSelect={(tag) => setSearchQuery(tag)} />
          ))}
        {gifs && gifs.length > 0 && <GiphyImageGrid images={gifs} onSelect={onMediaSelect} />}
        {loading && (
          <div className="flex items-center justify-center py-8">
            <LoadingDots />
          </div>
        )}
      </div>
    </div>
  );
};

export default GiphyView;
