import toast from "react-hot-toast";
import { useMutation } from "react-query";

import { AdNetworkCampaignCsv } from "@/interfaces/ad_network/advertiser_access/campaign_csv";
import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from "@/services/swarm";

export interface Props {
  advertiserId: string;
  campaignId: string;
}

export default function useDownloadCsv({ advertiserId, campaignId }: Props) {
  const fetchCsv = (): Promise<AdNetworkCampaignCsv> =>
    api
      .get(`ad_network/advertiser_access/${advertiserId}/campaign_reports/${campaignId}/opportunities/export`)
      .then((res) => res.data)

  return useMutation<AdNetworkCampaignCsv, MutationErrorResponse, unknown>(fetchCsv, {
    onSuccess: ({csv}) => {
      const url = window.URL.createObjectURL(new Blob([csv]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();    
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
