import toast from 'react-hot-toast';

import { useWebsiteContext } from '@/context/website-context';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';
import { CommentsOrdering } from '@/interfaces/dream_builder/site_version';

import InputWrapper from '../../_components/UI/InputWrapper';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../_components/UI/Select';
import { Switch } from '../../_components/UI/Switch';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';

import CommentBans from './_components/CommentsBans';

const WebsiteSettingsComments = () => {
  const { site } = useWebsiteContext();
  const updateSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  const commentEnabled = site?.draft_site_version?.settings?.comments_enabled || false;
  const commentsOrdering = site?.draft_site_version?.settings?.comments_ordering || 'desc';

  return (
    <div>
      <SettingsSection>
        <SettingsItem
          title="Post Comments Section"
          description="Allow users to engage in a comments section for a post. You can disable this feature on a post-by-post basis."
        >
          <Switch
            id="comments_enabled"
            labelText="Enable comments"
            checked={site?.draft_site_version?.settings?.comments_enabled || false}
            onCheckedChange={(value: boolean) =>
              updateSiteVersion.mutate({
                settings: {
                  comments_enabled: value,
                },
              })
            }
          />
        </SettingsItem>
      </SettingsSection>

      {commentEnabled && (
        <>
          <SettingsSection>
            <SettingsItem title="Comments Ordering" description="Order comments by newest, oldest, or most popular.">
              <InputWrapper name="comment_ordering" labelText="Select Order">
                <Select
                  defaultValue={commentsOrdering}
                  onValueChange={(value: CommentsOrdering) => {
                    updateSiteVersion.mutate({
                      settings: {
                        comments_ordering: value,
                      },
                    });
                  }}
                >
                  <SelectTrigger className="w-[180px]" id="comment_ordering">
                    <SelectValue placeholder="Select an order" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="desc">Newest First</SelectItem>
                      <SelectItem value="asc">Oldest First</SelectItem>
                      <SelectItem value="popular">Most Popular First</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </InputWrapper>
            </SettingsItem>
          </SettingsSection>
          <SettingsSection>
            <SettingsItem title="Comment Bans" description="Ban subscriber emails from commenting on posts below.">
              <CommentBans />
            </SettingsItem>
          </SettingsSection>
        </>
      )}
    </div>
  );
};

export default WebsiteSettingsComments;
