function getCurrentTimezone(dateString: string) {
  try {
    const date = new Date(dateString);
    const timeZoneString = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date);
    const abbreviation = timeZoneString.split(' ').pop();
    return abbreviation;
  } catch (error) {
    console.error("Error getting timezone abbreviation: ", error);
    return null;
  }
}

export default getCurrentTimezone;
