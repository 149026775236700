import { FC, useCallback, useState } from 'react';
import toast from 'react-hot-toast';

import { useCurrentPublication } from '../../hooks';
import { Option } from '../../interfaces/general';
import { Subscriber } from '../../interfaces/options';
import api from '../../services/swarm';
import { SearchDropdown } from '../Form';

interface Props {
  labelText?: string;
  helperText?: string;
  placeholderText?: string;
  className?: string;
  labelClassName?: string;
  onSelectSubscriber: (subscriberId: string, subscriberEmail: string) => void;
  onClearSubscriber: () => void;
}

const SubscriberSelect: FC<Props> = (props: Props) => {
  const {
    labelText,
    helperText,
    placeholderText = 'Select a subscriber...',
    className,
    labelClassName,
    onSelectSubscriber,
    onClearSubscriber,
  } = props;
  const [subscriberId, setSubscriberId] = useState('');
  const { data: currentPublication } = useCurrentPublication();

  const fetchSubscribers = useCallback(
    (inputValue?: string) => {
      if (!currentPublication) {
        return;
      }

      const params = {
        publication_id: currentPublication.id,
        q: inputValue,
      };

      // eslint-disable-next-line
      return api
        .get(`/options/subscribers/`, { params })
        .then((res) => {
          const subscribers = res.data?.options || [];

          const options: Option[] = subscribers.map((subscriber: Subscriber) => {
            const val: Option = {
              label: subscriber.email,
              value: subscriber.id,
            };
            return val;
          });
          return options;
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [currentPublication]
  );

  const handleSelect = (name: string, value: string, selectedOption?: Option) => {
    setSubscriberId(value);
    onSelectSubscriber(value, selectedOption?.label || '');
  };

  const handleClear = () => {
    setSubscriberId('');
    onClearSubscriber();
  };

  if (!currentPublication) {
    return null;
  }

  return (
    <SearchDropdown
      name="subscriber"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText={placeholderText}
      value={subscriberId}
      onSelect={handleSelect}
      onClear={handleClear}
      loadOptions={fetchSubscribers}
      labelClassName={labelClassName}
      className={className}
    />
  );
};

export default SubscriberSelect;
