import { useState } from 'react';
import { ComputerDesktopIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import SwitchSlider from '../../../components/Form/SwitchSlider';

export enum ScreenSizes {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  FULL_SCREEN = 'full_screen',
}
export type ScreenSizeType = ScreenSizes.DESKTOP | ScreenSizes.MOBILE | ScreenSizes.FULL_SCREEN;

interface Props {
  screenSize: ScreenSizeType;
  setScreenSize: (screenSize: ScreenSizeType) => void;
}

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSizeType>(ScreenSizes.DESKTOP);

  return {
    setScreenSize,
    screenSize,
  };
};

const ScreenSize = ({ screenSize, setScreenSize }: Props) => {
  return (
    <SwitchSlider
      name="desktopMobile"
      size="sm"
      checked={screenSize === ScreenSizes.MOBILE}
      onChange={(name: string, checked: boolean) => {
        setScreenSize(checked ? ScreenSizes.MOBILE : ScreenSizes.DESKTOP);
      }}
      child1={
        <div className="w-12 flex justify-center items-center">
          <ComputerDesktopIcon
            className={cx('h-4 w-4', screenSize === ScreenSizes.DESKTOP ? 'text-primary-600' : 'text-black')}
          />
        </div>
      }
      child2={
        <div className="w-12 flex justify-center items-center">
          <DevicePhoneMobileIcon
            className={cx('h-4 w-4', screenSize === ScreenSizes.MOBILE ? 'text-primary-600' : 'text-black')}
          />
        </div>
      }
    />
  );
};

export { ScreenSize, useScreenSize };
