import { useCallback, useMemo } from 'react';
import { Star } from '@phosphor-icons/react';

import usePricingTiers from '@/hooks/useDreamBuilder/usePricingTiers';
import { Option } from '@/interfaces/general';

import { PopoverHelper, PopoverItem } from '../../helpers/PopoverHelper';
import type { AttributeSettingProps } from '../../types';

type Props = AttributeSettingProps & {};

const normalizePriceName = (name: string) => {
  return name
    ?.split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const RecommendedSetting = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const { data: pricingTiers } = usePricingTiers({ enabled: true });

  const tierOptions = useMemo((): Option[] => {
    const tiers = pricingTiers?.tiers || [];
    return [
      { label: 'None', value: '' },
      ...tiers.map((tier) => ({
        label: tier.name,
        value: tier.id,
      })),
    ];
  }, [pricingTiers]);
  const tierCount = pricingTiers?.tiers.length || 0;
  const hasMultipleTiers = tierCount > 1;

  const priceOptions = useMemo((): Option[] => {
    const prices = pricingTiers?.tiers[0]?.prices || [];
    return [
      { label: 'None', value: '' },
      ...prices.map((price) => ({
        label: normalizePriceName(price.interval),
        value: price.id,
      })),
    ];
  }, [pricingTiers]);

  const options = hasMultipleTiers ? tierOptions : priceOptions;
  const title = hasMultipleTiers ? 'Tier' : 'Price';
  const propertyToUpdate = hasMultipleTiers ? 'recommendedTierId' : 'recommendedPriceId';

  const textValue =
    options.find((option) => option.value === activeNodeAttributes?.[propertyToUpdate])?.label || 'None';

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, propertyToUpdate, value);
        return true;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, propertyToUpdate]
  );

  return (
    <PopoverHelper title={title} text={textValue} popoverTitle={title} Icon={Star}>
      <div className="flex flex-col gap-2">
        {options.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={false}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};

export default RecommendedSetting;
