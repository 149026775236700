import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import useSvixEndpoint from '@/hooks/webhooks/useSvixEndpoint';
import useUpdateSvixEndpoint from '@/hooks/webhooks/useUpdateSvixEndpoint';
import { WebhookEndpoint, WebhookEndpointUpdatePayload } from '@/interfaces/webhooks/endpoint';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import { usePublicationSettings } from '../../context';
import EndpointForm from '../components/EndpointForm';

const EditWebhookEndpoint = () => {
  const { endpointId } = useParams<{ endpointId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentPublicationId } = usePublicationSettings();
  const { data: endpointData, isLoading, isError } = useSvixEndpoint(currentPublicationId, endpointId || '');

  const { mutateAsync: updateEndpoint, isLoading: isSaving } = useUpdateSvixEndpoint(currentPublicationId, () => {
    toast.success('Webhook endpoint updated successfully');
    queryClient.invalidateQueries(['publications', currentPublicationId, 'webhooks', 'endpoints']);
    navigate(appendSettingsPublicationId('/settings/publication/webhooks', currentPublicationId));
  });

  const handleSave = (payload: Partial<WebhookEndpoint>) => updateEndpoint(payload as WebhookEndpointUpdatePayload);

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <EndpointForm endpoint={endpointData} onSave={handleSave} isSaving={isSaving} />
    </LoadingBox>
  );
};

export default EditWebhookEndpoint;
