import { Link } from 'react-router-dom';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

const NoResults = () => (
  <div className="max-w-2xl p-8 my-8 mx-auto bg-gray-50 rounded-lg border">
    <div className="text-center space-y-4">
      <div className="flex justify-center">
        <ArrowUturnLeftIcon className="h-5 w-5 text-gray-400" />
      </div>
      <p className="text-gray-700 text-sm">We looked, but couldn&apos;t find anything.</p>
      <div>
        <Link to="..">
          <Button variant="primary-inverse" size="xs" type="button">
            Go back
          </Button>
        </Link>
      </div>
    </div>
  </div>
);

export default NoResults;
