import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SimpleNumberSettings } from '../SimpleNumberSettings';

export const RecommendationsModalSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['recommendationsModal']}>
      <SectionRenderer title="Modal">
        <ColorSettings
          title="Overlay Background"
          property="modalOverlayBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
      </SectionRenderer>
      <SectionRenderer title="Content">
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />

        <ColorSettings
          title="Background"
          property="backgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <SimpleNumberSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="width"
          title="Width"
          unit="px"
          max={1200}
        />
        <SimpleNumberSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="height"
          title="Height"
          unit="px"
          max={1200}
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
