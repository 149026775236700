import { ElementType } from 'react';
import cx from 'classnames';

import * as CardUI from '@/components/Card/CardUI';
import { BodySm, TitleBase } from '@/components/Typography';
import { Button } from '@/ui/Button';

interface Props {
  title: string;
  description: string;
  Icon: ElementType;
  ButtonIcon: ElementType;
  ctaText: string;
  onActionClick: () => void;
  variant?: 'surface' | 'primary' | 'secondary' | 'warning' | 'danger' | 'info' | 'success';
}

const ICON_COLOR_MAP = Object.freeze({
  surface: 'text-surface-500',
  primary: 'text-action-primary-900',
  secondary: 'text-action-secondary-900',
  warning: 'text-feedback-warning-900',
  danger: 'text-feedback-danger-900',
  info: 'text-feedback-info-900',
  success: 'text-feedback-success-900',
});

const BUTTON_VARIANT_MAP: any = Object.freeze({
  secondary: 'secondary',
  success: 'success',
});

const CTACard = ({ title, description, Icon, variant = 'surface', ButtonIcon, ctaText, onActionClick }: Props) => {
  const btnVariant = BUTTON_VARIANT_MAP[variant] as any;

  return (
    <CardUI.Card color={variant}>
      <div className="flex flex-col items-center space-y-6 py-4">
        <div className="flex flex-col items-center space-y-2">
          <Icon className={cx('w-12 h-12', ICON_COLOR_MAP[variant])} />
          <TitleBase color={variant}>{title}</TitleBase>
          <BodySm color={variant} colorWeight="900">
            {description}
          </BodySm>
        </div>

        <Button type="button" onClick={onActionClick} Icon={ButtonIcon} variant={btnVariant}>
          {ctaText}
        </Button>
      </div>
    </CardUI.Card>
  );
};

export default CTACard;
