import { NAVBAR_MENU_DROPDOWN_CARD_DEFAULTS, NAVBAR_MENU_DROPDOWN_FULL_DEFAULTS } from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { ColorSettings } from '../general/ColorSettings';

export function NavDropdownStyleSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown') return null;
  return (
    <SectionRenderer title="Style">
      <ColorSettings
        title="Background"
        property="background"
        selectedContent={selectedContent}
        defaultValue={
          selectedContent.attrs?.style === 'card'
            ? NAVBAR_MENU_DROPDOWN_CARD_DEFAULTS.background
            : NAVBAR_MENU_DROPDOWN_FULL_DEFAULTS.background
        }
      />
    </SectionRenderer>
  );
}
