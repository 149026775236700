import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import api from '../services/swarm';

export default function useSubscriber({ id }: { id: string }) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery(
    ['subscriber', id, currentPublicationId],
    () =>
      api
        .get(`/subscribers/${id}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      retry: false,
    }
  );
}
