import { SelectOptions } from '@/components/SelectAllCheckbox';
import pluralize from '@/utils/pluralize';

type AllSelectedWithFilterProps = {
  selectedPostCount: number;
  currentSelectOptions: SelectOptions[];
  showingPosts: string[];
  selectedShowingPosts: string[];
};

const AllSelectedWithFilter = ({
  selectedPostCount,
  currentSelectOptions,
  showingPosts,
  selectedShowingPosts,
}: AllSelectedWithFilterProps) => {
  const selectedOffScreen = showingPosts.filter((id) => selectedShowingPosts.includes(id)).length < selectedPostCount;
  const filters = currentSelectOptions.map((option) => option.toLowerCase()).join(', ');

  return (
    <p>
      You have selected {pluralize('post', selectedPostCount)} that meet this criteria: {filters}.
      {selectedOffScreen && <> (Note: only {selectedShowingPosts.length} are visible on this page).</>}
    </p>
  );
};

export default AllSelectedWithFilter;
