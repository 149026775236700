import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import { useDeleteForm } from '@/hooks/useForms';
import { Form } from '@/interfaces/form';
import { Button } from '@/ui/Button';

interface Props {
  form: Form;
}

const DeleteFormButton = ({ form }: Props) => {
  const navigate = useNavigate();

  // Handle Deleting form
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteForm = useDeleteForm({
    formId: form?.id || '',
  });
  const handleDelete = async () => {
    try {
      await deleteForm.mutateAsync();
      setIsDeleting(false);
      navigate('/forms');
    } catch (error) {
      toast.error('There was an error deleting this survey');
      setIsDeleting(false);
    }
  };

  return (
    <>
      <ActionModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onProceed={handleDelete}
        resourceId={form.id}
        isWorking={deleteForm.isLoading}
        headerText="Delete Survey"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this survey?
      </ActionModal>
      <Button size="xs" onClick={() => setIsDeleting(true)} variant="danger">
        Delete Survey
      </Button>
    </>
  );
};

export default DeleteFormButton;
