import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { CreatePublicationSubscriberPreferenceParams } from '@/interfaces/subscribers_preferences';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useCreateSubscriberPreference = (onSuccess?: (response: any) => void) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    (params: CreatePublicationSubscriberPreferenceParams) =>
      api.post(`/publication_subscriber_preferences`, { publication_id: currentPublicationId, subscriber_preference: params }),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        queryClient.invalidateQueries([currentPublicationId, 'subscriber_preferences']);
        toast.success('Preference created successfully!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Could not create preference.');
      },
    }
  );
};

export default useCreateSubscriberPreference;
