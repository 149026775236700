import { HandRaisedIcon } from '@heroicons/react/24/outline';

import { useUpdateWebTemplate, useWebTemplate } from '../../../../../../hooks';
import { Section, SwitchForm } from '../../../../Components';

const CommentSection = () => {
  const webTemplateMutation = useUpdateWebTemplate();
  const { data } = useWebTemplate();
  const webTemplate = data;

  return (
    <Section
      title="Comments"
      description="Manage your website's community engagement features below."
      scrollToId="menu-items"
      isLoading={!webTemplate}
    >
      <SwitchForm
        name="enable_comments"
        label="Enable comments"
        description="By enabling, posts will have a comments section. You can turn this feature off on a post-by-post basis."
        value={webTemplate?.enable_comments || false}
        onSave={(val: boolean) => webTemplateMutation.mutateAsync({ enable_comments: val })}
        toastSuccessMessage="Saved!"
      />
      <div className="bg-blue-50 p-4 rounded space-y-2">
        <div className="flex space-x-1 items-center">
          <HandRaisedIcon className="h-3 w-3 font-bold text-xs text-blue-500" />
          <span className="font-bold text-xs text-blue-500">Helping Hand:</span>
        </div>
        <p className="text-xs text-blue-600">
          We are working to embed comment section buttons directly into the post editor experience. In the meantime, a
          simple yet effective way to direct users to comment is to include a button that links directly to your
          post&#39;s comment section.
        </p>
        <p className="text-xs text-blue-600">For example:</p>
        <div className="pl-4">
          <div className="space-x-1">
            <span className="font-bold text-xs text-blue-600">Step 1:</span>
            <span className="text-xs text-blue-600">Add a new button in the editor</span>
          </div>
          <div className="space-x-1">
            <span className="font-bold text-xs text-blue-600">Step 2:</span>
            <span className="text-xs text-blue-600">Include a call to action like &ldquo;Add a comment&rdquo;</span>
          </div>
          <div className="space-x-1">
            <span className="font-bold text-xs text-blue-600">Step 3:</span>
            <span className="text-xs text-blue-600">
              Link to your post with the search params included:
              https://publication.beehiiv.com/p/my-new-post?comments=true
            </span>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default CommentSection;
