import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import useCheckPublicationPermissions from '@/hooks/useCheckPublicationPermissions/useCheckPublicationPermissions';
import { CurrentUserOrganization, CurrentUserPublication } from '@/hooks/useCurrentUserOrganizations';
import useIsWorkspaceSettingPage from '@/hooks/useIsWorkspaceSettingPage';
import { SHOW_CREATE_PUBLICATION_GET_PARAM } from '@/routes/settings/_layout';
import { Button } from '@/ui/Button';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';
import appendSettingsWorkspaceId from '@/utils/appendSettingsWorkspaceId';

import GroupBody from './GroupBody';
import GroupLink from './GroupLink';
import NavLink from './NavLink';
import PublicationNav from './PublicationNav';
import { ViewMode } from './types';

interface Props {
  organization: CurrentUserOrganization;
  currentPublicationId: string | null;
  isOpen: boolean;
  onLinkClick: (options: { shouldScrollToTop: boolean }) => void;
  hasOnlyOneWorkspace: boolean;
  viewMode: ViewMode;
}

const WorkspaceNav = ({
  organization,
  currentPublicationId,
  isOpen,
  onLinkClick,
  hasOnlyOneWorkspace,
  viewMode,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, publications } = organization;
  const currentPublication = publications.find((pub) => pub.id === currentPublicationId);
  const permissions = currentPublication?.permissions || {};
  const isWorkspaceSettingPage = useIsWorkspaceSettingPage();

  const [isFolded, setIsFolded] = useState(!isOpen);

  const navLinks = [
    {
      label: 'Overview',
      to: '/settings/workspace/overview',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/company', 'read'),
    },
    {
      label: 'Team',
      to: '/settings/workspace/team',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/company', 'read'),
    },
    {
      label: 'Billing & Plan',
      to: '/settings/workspace/billing_and_plan',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/billing', 'read'),
    },
    {
      label: 'API',
      to: '/settings/workspace/api',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/integrations', 'read'),
    },
  ];

  const handleClickGroup = () => {
    if (isOpen) {
      setIsFolded(!isFolded);
    }

    onLinkClick({ shouldScrollToTop: true });
  };

  const groupLinkElement = hasOnlyOneWorkspace ? (
    <GroupBody label={name} />
  ) : (
    <GroupLink
      to={appendSettingsPublicationId(`/settings/publication/general`, organization.publications[0].id)}
      label={name}
      isSelected={isOpen}
      isFolded={isFolded}
      onClick={handleClickGroup}
    />
  );

  return (
    <div className="flex flex-col gap-y-2">
      {viewMode === 'list' ? groupLinkElement : null}
      {!isFolded || viewMode === 'dropdown' ? (
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            {publications.map((publication: CurrentUserPublication) => {
              if (viewMode === 'dropdown' && publication.id !== currentPublicationId) {
                return null;
              }

              return (
                <PublicationNav
                  viewMode={viewMode}
                  key={publication.id}
                  publication={publication}
                  isOpen={
                    viewMode === 'dropdown' || (currentPublicationId === publication.id && !isWorkspaceSettingPage)
                  }
                  onLinkClick={() => onLinkClick({ shouldScrollToTop: false })}
                />
              );
            })}
          </div>
          <div className="flex flex-col gap-y-2">
            <Typography token="font-normal/text/xs" colorWeight="400">
              Workspace Settings
            </Typography>
            <nav className="w-full flex flex-col gap-y-1">
              {navLinks.map((link) => (
                <NavLink
                  key={link.to}
                  to={appendSettingsWorkspaceId(
                    link.to,
                    organization.id,
                    currentPublicationId || organization.publications[0].id
                  )}
                  label={link.label}
                  isSelected={location.pathname === link.to}
                  isDisabled={link.isDisabled}
                  onClick={() => onLinkClick({ shouldScrollToTop: false })}
                />
              ))}
            </nav>
          </div>
          <Button
            type="button"
            size="xs"
            Icon={PlusIcon}
            className="w-full"
            onClick={() =>
              navigate(
                `${appendSettingsWorkspaceId(
                  location.pathname,
                  organization.id,
                  currentPublicationId || organization.publications[0].id
                )}&${SHOW_CREATE_PUBLICATION_GET_PARAM}=true`
              )
            }
          >
            New Publication
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default WorkspaceNav;
