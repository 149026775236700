import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { v4 as generateUuid } from 'uuid';

import ButtonWithOptions from '@/components/Form/ButtonWithOptions';
import { Typography, TypographyStack } from '@/components/Typography';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import {
  AutomationTrigger,
  AutomationTriggerEvent,
  AutomationTriggerState,
} from '@/interfaces/automations/automation_trigger';
import analytics from '@/utils/analytics';

import { TRIGGERS_LABEL } from '../constants';
import { useNewAutoLayoutContext } from '../context/new-layout-context';
import useMaxAllowedTriggers from '../hooks/useMaxAllowedTriggers';
import useTrigger from '../hooks/useTrigger';
import { AutomationTriggerMainConditionsDefaults, AutomationTriggerOptions } from '../Nodes/Triggers/constants';

import ConfigureBody from './ConfigureBody';
import ConfigureConditions from './ConfigureConditions';
import ConfigureFooter from './ConfigureFooter';
import ConfigureHeader from './ConfigureHeader';
import ConfigureTrigger from './ConfigureTrigger';
import ConfirmModal from './ConfirmModal';

interface Props {
  automationId: string;
  currentTrigger?: AutomationTrigger;
}

const ConfigureTriggers = ({ automationId, currentTrigger }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { automationTriggers, pushToChangeLog } = useNewAutoLayoutContext();
  const triggers = [...automationTriggers].reverse();
  const hasMaxTriggers = useMaxAllowedTriggers(triggers);
  const { createAutomationTrigger, updateAutomationTrigger } = useTrigger();

  const [updatedTriggers, setUpdatedTriggers] = useState<{ [key: string]: AutomationTrigger }>({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleAddNewTrigger = async (name: string, value: any) => {
    const mainConditions = AutomationTriggerMainConditionsDefaults[value.value as AutomationTriggerEvent]
      ? { ...AutomationTriggerMainConditionsDefaults[value.value as AutomationTriggerEvent] }
      : {};

    const newTrigger = {
      id: generateUuid(),
      state: AutomationTriggerState.DRAFT,
      event: value.value,
      automation_id: automationId,
      conditions: {},
      main_conditions: mainConditions,
    };

    try {
      analytics.track('Trigger Added');
      await createAutomationTrigger(automationId, newTrigger.event, newTrigger.main_conditions, newTrigger.state);
      queryClient.invalidateQueries(['automations', 'automation_triggers', automationId]);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'There was an error adding the trigger, please try again');
    }
  };

  const handleUpdateTrigger = (updatedTrigger: AutomationTrigger) => {
    const currentTriggerResource = triggers.find((trigger) => trigger.id === updatedTrigger.id);

    if (!currentTriggerResource) {
      return;
    }

    setUpdatedTriggers({
      ...updatedTriggers,
      [updatedTrigger.id]: updatedTrigger,
    });
  };

  /* Confirm Modal callbacks */
  const handleClickCancelUnsavedChanges = () => setShowConfirmModal(false);

  const handleClickConfirmUnsavedChanges = () => {
    setShowConfirmModal(false);
    navigate(`/automations/${automationId}/workflow`);
  };

  const handleCancel = () => {
    const hasTriggersChanges = Object.keys(updatedTriggers).length > 0;

    if (hasTriggersChanges) {
      setShowConfirmModal(true);
      return;
    }

    navigate(`/automations/${automationId}/workflow`);
  };

  const invalidateQueries = () => {
    queryClient.invalidateQueries(['automations', 'automation', automationId]);
    queryClient.invalidateQueries(['automations', 'automation_triggers', automationId]);
  };

  const handleSave = async () => {
    const promises: Promise<any>[] = [];
    // Update existing ones
    Object.keys(updatedTriggers).forEach((triggerId: string) => {
      promises.push(updateAutomationTrigger(triggerId, updatedTriggers[triggerId]));
    });

    try {
      await Promise.all(promises);
      pushToChangeLog({
        action: 'triggers updated',
      });

      invalidateQueries();

      navigate(`/automations/${automationId}/workflow`);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const handleChangeInConditions = (conditions?: AutomationConditions) => {
    if (!currentTrigger) {
      return;
    }

    const updatedTrigger = { ...currentTrigger };
    updatedTrigger.conditions = conditions || {};
    handleUpdateTrigger(updatedTrigger);
  };

  const isConfiguringConditions = !!currentTrigger;

  const headerTitle = TRIGGERS_LABEL;

  return (
    <>
      <ConfirmModal
        isSaving={false}
        isOpen={showConfirmModal}
        onCancel={handleClickCancelUnsavedChanges}
        onConfirm={handleClickConfirmUnsavedChanges}
        headerText="You have unsaved changes"
      >
        <TypographyStack gap="4" className="text-gray-800">
          <Typography>Are you sure you want to discard the changes?</Typography>
        </TypographyStack>
      </ConfirmModal>
      <div className={cx('fixed top-0 left-0 z-20 w-screen h-screen bg-gray-100/30')}>
        <div className={cx('h-full shadow-md bg-white', isConfiguringConditions ? 'w-3/5' : 'w-128')}>
          {!isConfiguringConditions && (
            <div className="p-5">
              <ConfigureHeader title={headerTitle} onClose={handleCancel} canActivate={false} />
            </div>
          )}
          <ConfigureBody>
            {isConfiguringConditions && (
              <ConfigureConditions
                mainConditions={updatedTriggers[currentTrigger.id]?.main_conditions || currentTrigger.main_conditions}
                conditions={updatedTriggers[currentTrigger.id]?.conditions || currentTrigger.conditions}
                triggerEvent={currentTrigger.event}
                onChange={handleChangeInConditions}
              />
            )}
            {!isConfiguringConditions && (
              <div className="flex flex-col">
                <ul className="flex flex-col w-full">
                  {triggers.map((trigger, index) => (
                    <li
                      className="border-b border-gray-200 py-4 last-of-type:mb-2"
                      key={`configure-trigger-${trigger.id}`}
                    >
                      <ConfigureTrigger
                        index={index}
                        trigger={updatedTriggers[trigger.id] || trigger}
                        onChange={handleUpdateTrigger}
                      />
                    </li>
                  ))}
                </ul>
                {!hasMaxTriggers && (
                  <div className="w-max">
                    <ButtonWithOptions
                      name="add_trigger"
                      options={AutomationTriggerOptions}
                      optionsWidth="w-60"
                      placeholderText="Add Trigger"
                      onSelect={handleAddNewTrigger}
                      disabled={hasMaxTriggers}
                    />
                  </div>
                )}
              </div>
            )}
          </ConfigureBody>
          {!isConfiguringConditions && <ConfigureFooter onCancel={handleCancel} onSave={handleSave} />}
        </div>
      </div>
    </>
  );
};

export default ConfigureTriggers;
