import { GiftTopIcon } from '@heroicons/react/20/solid';

import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import Section from '@/routes/settings/_components/Section';
import Switch from '@/ui/Switch';
import analytics from '@/utils/analytics';
import isValidUrlWithProtocol from '@/utils/isValidUrlWithProtocol';

import { useTierConfigurationContext } from '../../_context/tier_configuration_context';
import useTierFormStep from '../../_hooks/useTierFormStep';

const AdvancedSection = () => {
  const { redirectUrl, setRedirectUrl, errors, setErrors, giftingEnabled, setGiftingEnabled } =
    useTierConfigurationContext();
  const { onFocus, onBlur } = useTierFormStep('advanced');
  const currentPublicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(currentPublicationId);

  const handleRedirectUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trim();
    setRedirectUrl(newValue);

    if (isValidUrlWithProtocol(newValue) || newValue === '') {
      setErrors((prevErrors) => Object.fromEntries(Object.entries(prevErrors).filter(([k]) => k !== 'redirect_url')));
      analytics.track('set redirect url', { url: newValue });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        redirect_url: 'Redirect URL must be a valid URL and begin with http:// or https://',
      }));
    }
  };

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      <Section
        title="Advanced Options"
        description="Optimize the subscriber checkout experience"
        maxWidthClassName="max-w-4xl"
        titleAction={
          <Typography token="font-normal/text/sm" colorWeight="500">
            Optional
          </Typography>
        }
      >
        <div className="space-y-6">
          <div>
            <Input
              labelText="Redirect URL after purchase"
              name="redirect_url"
              placeholder="https://example.com"
              className="mb-2"
              value={redirectUrl}
              onChange={handleRedirectUrlChange}
              errorText={errors.redirect_url || ''}
              helperText="Once a subscriber completes the subscribe flow, they will land on this URL associated with the tier they
              selected rather than your homepage."
            />
          </div>
          {settings?.purchase_gift_subscriptions && (
            <div className="flex flex-col space-y-1">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row space-x-1">
                  <GiftTopIcon className="w-5 h-5" />
                  <Typography token="font-normal/text/base" colorWeight="900">
                    Gift
                  </Typography>
                </div>
                <Switch
                  name="gifting_enabled"
                  size="large"
                  variant="primary"
                  checked={giftingEnabled}
                  onChange={(_name, checked) => {
                    setGiftingEnabled(checked);
                  }}
                />
              </div>
              <Typography token="font-light/text/xs" colorWeight="900">
                Allow anyone to purchase a subscription to gift to someone else
              </Typography>
            </div>
          )}
        </div>
      </Section>
    </div>
  );
};

export default AdvancedSection;
