import { useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { ProgressBar } from '@tremor/react';

import Card from '@/components/Card';
import Grid from '@/components/Grid';
import { HelperXs, Typography, TypographyStack } from '@/components/Typography';
import TypographyRow from '@/components/Typography/TypographyRow';

import { ApiResponse } from '../../_hooks/useTopLevelMetrics';

const PlanBreakdownCard = ({ query }: { query: UseQueryResult<ApiResponse> }) => {
  const { plan_breakdown: planBreakdown } = query.data || {};

  const {
    annual_plans: annualPlans = 0,
    monthly_plans: monthlyPlans = 0,
    upgrades_over_time: upgradesOverTime = 0,
    upgrades_on_signup: upgradesOnSignup = 0,
  } = planBreakdown || {};

  const totalPlans = monthlyPlans + annualPlans;
  const annualPlanPercent = useMemo(
    () => (totalPlans > 0 ? Math.round((annualPlans / totalPlans) * 100) : 0),
    [annualPlans, totalPlans]
  );

  const totalUpgrades = upgradesOverTime + upgradesOnSignup;
  const upgradeOverTimePercent = useMemo(
    () => (totalUpgrades > 0 ? Math.round((upgradesOverTime / totalUpgrades) * 100) : 0),
    [totalUpgrades, upgradesOverTime]
  );

  return (
    <Card isLoading={query.isLoading} className="py-2 col-span-4 md:col-span-2 lg:col-span-1 flex flex-col">
      {planBreakdown ? (
        <>
          <Typography className="mb-2" token="font-medium/text/sm" colorWeight="700">
            Plan breakdown
          </Typography>
          <Grid gap={0} columns={0} rows={0} className="grid-cols-[auto_minmax(0,1fr)]">
            <TypographyStack>
              <TypographyRow>
                <div className="w-2 h-2 rounded-full bg-violet-200" />
                <HelperXs>Monthly</HelperXs>
              </TypographyRow>
              <TypographyRow>
                <div className="w-2 h-2 rounded-full bg-violet-500" />
                <HelperXs>Annual</HelperXs>
              </TypographyRow>
            </TypographyStack>
            <ProgressBar
              className="pr-5 rotate-180"
              percentageValue={annualPlanPercent}
              color="violet"
              tooltip={`Monthly: ${100 - annualPlanPercent}% — Annual: ${annualPlanPercent}%`}
            />
            <hr className="my-3 col-span-2" />
            <TypographyStack>
              <TypographyRow>
                <div className="w-2 h-2 rounded-full bg-violet-200" />
                <HelperXs>On signup</HelperXs>
              </TypographyRow>
              <TypographyRow>
                <div className="w-2 h-2 rounded-full bg-violet-500" />
                <HelperXs>Over time</HelperXs>
              </TypographyRow>
            </TypographyStack>
            <ProgressBar
              className="pr-5 rotate-180"
              percentageValue={upgradeOverTimePercent}
              color="violet"
              tooltip={`Signup: ${100 - upgradeOverTimePercent}% — Over time: ${upgradeOverTimePercent}%`}
            />
          </Grid>
        </>
      ) : (
        <TypographyRow className="h-full items-center justify-center text-center min-h-[8rem]">
          <Typography token="font-medium/text/xs" colorWeight="700">
            Error pulling plan breakdown
          </Typography>
        </TypographyRow>
      )}
    </Card>
  );
};

export default PlanBreakdownCard;
