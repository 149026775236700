import { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { StringParam, useQueryParam } from 'use-query-params';

import { useCurrentUser } from '../../../../context/current-user-context';
import useSendEmailConfirmation from '../../../../hooks/useSendEmailConfirmation';
import api from '../../../../services/swarm';
import Banner from '../../../Banner';

const EmailConfirmationBanner = () => {
  const { currentUser, reloadUser } = useCurrentUser();

  /**
   * Check if token and confirmation token exist and send request to confirm email
   */
  const [tokenId, setTokenId] = useQueryParam('token_id', StringParam);
  const [confirmationToken, setConfirmationToken] = useQueryParam('confirmation_token', StringParam);

  const confirmEmail = useCallback(() => {
    api
      .patch(`/email_confirmations/${currentUser?.id}`, {
        token_id: tokenId,
        confirmation_token: confirmationToken,
      })
      .then(() => {
        reloadUser();
      })
      .then(() => {
        toast.success('Your account has successfully been confirmed!');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setTokenId(undefined);
        setConfirmationToken(undefined);
      });
  }, [tokenId, confirmationToken, currentUser, reloadUser, setConfirmationToken, setTokenId]);

  useEffect(() => {
    if (tokenId && confirmationToken && currentUser) {
      confirmEmail();
    }
  }, [tokenId, confirmationToken, confirmEmail, currentUser]);

  const confirmationMutation = useSendEmailConfirmation();
  const { mutateAsync: sendConfirmationEmail } = confirmationMutation;

  if (currentUser?.is_confirmed) return null;

  return (
    <Banner
      title="Please confirm your email"
      bodyText="Check your inbox and confirm your email. This is a required step to publishing content on beehiiv. If you can't find the email—check spam or click the button below to resend."
      ctaText="Resend confirmation email"
      onClick={sendConfirmationEmail}
    />
  );
};

export default EmailConfirmationBanner;
