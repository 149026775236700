import { AttributeSettingProps } from '../types';

import { SliderSettings } from './SliderSettings';

type Props = AttributeSettingProps & {
  property: string;
  title: string;
  max?: number;
};

export const GapSettings = ({ editor, activeNodeResult, property, title = 'Gap', max = 64 }: Props) => {
  return (
    <SliderSettings
      editor={editor}
      activeNodeResult={activeNodeResult}
      property={property}
      title={title}
      unit="px"
      min={0}
      max={max}
      step={2}
    />
  );
};
