import { DragEvent, DragEventHandler, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Node } from 'reactflow';
import { PlusIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import BusySkeletonNode from '@/components/BusySkeletonNode';
import { AutomationStepStepType } from '@/interfaces/automations/automation_step';
import { Dropdown } from '@/ui/Dropdown';

import { useNewAutoLayoutContext } from '../context/new-layout-context';
import useAutomationStep from '../hooks/useAutomationStep';
import { TRIGGERS_NODE_ID } from '../utils/getNodesAndEdges';

interface Props {
  sourceNode?: Node;
  targetNode?: Node;
  branchArm?: string;
}

const SelectNodeTypeButton = ({ sourceNode, targetNode, branchArm }: Props) => {
  const [isDropzoneActive, setDropzoneActive] = useState<boolean>(false);
  const [isCreatingOrUpdating, setIsCreatingOrUpdating] = useState(false);
  const queryClient = useQueryClient();
  const {
    draggingAction,
    automation,
    setIsSelectingAutomationStep,
    sortingStepId,
    zoomToNode,
    automationStepTypeOptions,
  } = useNewAutoLayoutContext();
  const { createAutomationStep, reorderAutomationStep } = useAutomationStep();

  const hideBranchOption = sourceNode && targetNode;
  const filteredOptions = hideBranchOption
    ? automationStepTypeOptions.filter(
        ({ value, label }) => value !== AutomationStepStepType.BRANCH && label !== 'Flow control'
      )
    : automationStepTypeOptions;

  const canDrag = draggingAction && !(hideBranchOption && draggingAction === AutomationStepStepType.BRANCH);
  const canReorder = sortingStepId && ![sourceNode?.data?.id, targetNode?.data?.id].includes(sortingStepId);

  const onAddStep = async (stepType: AutomationStepStepType) => {
    setIsCreatingOrUpdating(true);

    try {
      const response = await createAutomationStep(automation.id, stepType, branchArm, sourceNode?.id);
      await queryClient.invalidateQueries(['automations', 'automation_steps', automation.id]);
      zoomToNode(response.data.id);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }

    setIsCreatingOrUpdating(false);
  };

  const onReorderStep = async (nodeId: string) => {
    if (!sourceNode) {
      return;
    }

    setIsCreatingOrUpdating(true);

    try {
      await reorderAutomationStep(
        automation.id,
        nodeId,
        sourceNode.id === TRIGGERS_NODE_ID ? 'root' : sourceNode.id,
        branchArm || null
      );
      await queryClient.invalidateQueries(['automations', 'automation_steps', automation.id]);
      zoomToNode(nodeId);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }

    setIsCreatingOrUpdating(false);
  };

  const handleSelect = (name: string, value: string) => {
    onAddStep(value as AutomationStepStepType);
    setIsSelectingAutomationStep(false);
  };

  const onClick = () => setIsSelectingAutomationStep(true);
  const onClose = () => setIsSelectingAutomationStep(false);

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setDropzoneActive(false);

    if (canDrag) {
      onAddStep(draggingAction);
    } else if (sortingStepId && canReorder) {
      onReorderStep(sortingStepId);
    }
  };

  const handleDragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
  };

  const handleDragEnter = () => {
    if (canDrag || canReorder) {
      setDropzoneActive(true);
    }
  };

  const handleDragLeave = () => {
    setDropzoneActive(false);
  };

  return (
    <div
      className="relative w-full h-24 -top-12 flex justify-center items-center"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {isCreatingOrUpdating && <BusySkeletonNode />}
      {isDropzoneActive && (
        <div className="absolute flex justify-center items-center inset-0 pointer-events-none">
          <div className="w-full h-0.5 bg-primary-500 absolute" />
          <div
            className={cx(
              'w-4 h-4 mb-0.5 z-0 rounded-full shadow-md flex items-center justify-center bg-primary-500 text-white'
            )}
          >
            <PlusIcon />
          </div>
        </div>
      )}
      {!isDropzoneActive && !isCreatingOrUpdating && (
        <Dropdown
          name="select-example"
          onSelect={handleSelect}
          onClose={onClose}
          options={filteredOptions}
          value="foo"
          optionsContainerClassNames={{
            width: 'w-64',
            height: 'h-64',
            position: 'bottom-10 -left-28',
          }}
          button={
            <button
              onClick={onClick}
              type="button"
              className={cx(
                'w-4 h-4 rounded-full shadow-md flex items-center justify-center bg-white text-primary-500 hover:text-white hover:bg-primary-500'
              )}
            >
              <PlusIcon />
            </button>
          }
        />
      )}
    </div>
  );
};

export default SelectNodeTypeButton;
