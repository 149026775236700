import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { arrayMove } from '@dnd-kit/sortable';

import { SortableList } from '@/components/SortableList';
import UnstyledSortableListItem from '@/components/SortableList/UnstyledSortableListItem';
import { useUpdateWebTemplate } from '@/hooks';
import { Publication } from '@/interfaces/publication';

interface Props {
  publications: Publication[];
  attributeName: string;
}

const Publications = ({ publications, attributeName }: Props) => {
  const webTemplateMutation = useUpdateWebTemplate();
  const [listInState, setListInState] = useState<any>(null);
  const lengthCheck = listInState?.length !== publications?.length;

  useEffect(() => {
    if (publications && lengthCheck) {
      setListInState(publications);
    }
  }, [publications, lengthCheck]);

  const onDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activePostitionId = listInState.find((position: any) => position.id === active.id);
      const overPostitionId = listInState.find((position: any) => position.id === over.id);
      const oldIndex = listInState.indexOf(activePostitionId);
      const newIndex = listInState.indexOf(overPostitionId);
      const newList = arrayMove(listInState, oldIndex, newIndex);
      setListInState(newList);
      const updatableList = newList.map((item: any) => item.id);
      webTemplateMutation.mutate({ [attributeName]: updatableList as string[] });
      toast.success('Saved!');
    }
  };

  const handleRemovePublication = (id: string) => {
    const newList = listInState.filter((item: any) => item.id !== id);
    setListInState(newList);

    const updatableList = newList.map((item: any) => item.id);
    const payload: any = {
      [attributeName]: updatableList as string[],
    };

    if (updatableList.length === 0) {
      if (attributeName === 'multi_pub_ids') {
        payload.enable_multi_pub_post_signup = false;
      }

      webTemplateMutation.mutate(payload);
    } else {
      webTemplateMutation.mutate(payload);
    }

    toast.success('Saved!');
  };

  if (!listInState || listInState.length === 0) {
    return null;
  }

  return (
    <SortableList listItems={listInState} onDragEndEvent={onDragEnd}>
      {(list) => {
        return list.map((item: any) => {
          return (
            <UnstyledSortableListItem
              className="bg-white rounded hover:bg-gray-100 px-4 py-4 border rounded-md"
              key={item.id}
              listItem={item}
              text={item.name}
              onRemoveItem={() => handleRemovePublication(item.id)}
            />
          );
        });
      }}
    </SortableList>
  );
};

export default Publications;
