import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

// @ts-ignore
function nodeEqualsType({ types, node }) {
  return (Array.isArray(types) && types.includes(node.type)) || node.type === types;
}

/**
 * Extension based on:
 * - https://github.com/ueberdosis/tiptap/blob/v1/packages/tiptap-extensions/src/extensions/TrailingNode.js
 * - https://github.com/remirror/remirror/blob/e0f1bec4a1e8073ce8f5500d62193e52321155b9/packages/prosemirror-trailing-node/src/trailing-node-plugin.ts
 */

export interface TrailingNodeOptions {
  node: string;
  notAfter: string[];
}

export const TrailingNode = Extension.create<TrailingNodeOptions>({
  name: 'trailingNode',

  addOptions() {
    return {
      node: 'paragraph',
      notAfter: ['paragraph'],
    };
  },

  addProseMirrorPlugins() {
    const pluginKey = new PluginKey(this.name);

    const disabledNodes = Object.entries(this.editor.schema.nodes)
      .map(([, value]) => value)
      .filter((node) => this.options.notAfter.includes(node.name));

    return [
      new Plugin({
        key: pluginKey,

        appendTransaction: (_, __, state) => {
          const { doc, tr, schema } = state;
          const shouldInsertNodeAtEnd = pluginKey.getState(state);
          const type = schema.nodes[this.options.node];
          let endPosition = doc.content.size;

          if (!shouldInsertNodeAtEnd) {
            return;
          }

          if (doc.lastChild?.type.name === 'footnotesNode') {
            endPosition -= doc.lastChild.nodeSize - 1;
          }

          // eslint-disable-next-line consistent-return
          return tr.insert(endPosition, type.create());
        },

        state: {
          init: (_, { tr: { doc } }) => {
            let lastNode = doc.lastChild;

            if (lastNode?.type.name === 'footnotesNode' && doc.childCount >= 2) {
              lastNode = doc.child(doc.childCount - 2);
            }

            return !nodeEqualsType({ node: lastNode, types: disabledNodes });
          },
          apply: ({ doc, docChanged }, value) => {
            if (!docChanged) {
              return value;
            }

            let lastNode = doc.lastChild;

            if (lastNode?.type.name === 'footnotesNode' && doc.childCount >= 2) {
              lastNode = doc.child(doc.childCount - 2);
            }

            return !nodeEqualsType({ node: lastNode, types: disabledNodes });
          },
        },
      }),
    ];
  },
});
