import { useState } from 'react';
import { v4 as generateUuid } from 'uuid';

import useLocalStorage from '@/hooks/useLocalStorage';
import { LogicalOperators } from '@/interfaces/condition';

import { Condition, ConditionGroup, ConditionOmitUuid, Filter } from './types';

interface UseConditionManagerProps {
  initialState: ConditionGroup;
  localStorageKey?: string;
  readonly?: boolean;
  disableShortcuts?: boolean;
}

function useConditionManager({ initialState, localStorageKey, readonly, disableShortcuts }: UseConditionManagerProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [showViewSelector, setShowViewSelector] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<Filter | undefined>();
  const [currentGroupUuid, setCurrentGroupUuid] = useState<string>(initialState.uuid);
  const [state, setState] = useLocalStorage<ConditionGroup>(initialState, localStorageKey);

  const addCondition = (condition: ConditionOmitUuid, groupUuid?: string): void => {
    if (readonly) return;

    const newCondition = ({ ...condition }.uuid ? condition : { ...condition, uuid: generateUuid() }) as Condition;

    setState((prevState) => {
      if (!groupUuid || prevState.uuid === groupUuid) {
        return { ...prevState, conditions: [...prevState.conditions, newCondition] };
      }

      const updateConditionsRecursively = (conditions: Array<Condition>): Array<Condition> => {
        return conditions.map((conditionOrGroup) => {
          if (conditionOrGroup.uuid === groupUuid && conditionOrGroup.type === 'group') {
            return { ...conditionOrGroup, conditions: [...conditionOrGroup.conditions, newCondition] };
          }
          if (conditionOrGroup.type === 'group') {
            return { ...conditionOrGroup, conditions: updateConditionsRecursively(conditionOrGroup.conditions) };
          }

          return conditionOrGroup; // If it's just a condition and not the one we are looking for, return as is
        });
      };

      return { ...prevState, conditions: updateConditionsRecursively(prevState.conditions) };
    });
  };

  // Removes a condition or a group anywhere in the tree
  const removeConditionFromList = (conditions: Array<Condition>, conditionUuid: string): Array<Condition> => {
    if (readonly) return conditions;

    const indexToRemove = conditions.findIndex((conditionOrGroup) => conditionOrGroup.uuid === conditionUuid);

    if (indexToRemove !== -1) {
      // Remove the condition
      const updatedConditions = [...conditions];
      updatedConditions.splice(indexToRemove, 1);
      return updatedConditions;
    }

    // If not found at this level, dive deeper
    for (let i = 0; i < conditions.length; i += 1) {
      const conditionOrGroup = conditions[i];
      if (conditionOrGroup.type === 'group') {
        const updatedGroupConditions = removeConditionFromList(conditionOrGroup.conditions, conditionUuid);
        if (updatedGroupConditions !== conditionOrGroup.conditions) {
          const updatedConditions = [...conditions];
          if (updatedGroupConditions.length === 0) {
            // Remove the group if it's left with no conditions
            updatedConditions.splice(i, 1);
          } else {
            // Otherwise, update the group's conditions
            (updatedConditions[i] as ConditionGroup).conditions = updatedGroupConditions;
          }
          return updatedConditions;
        }
      }
    }

    return conditions; // No change
  };

  const removeCondition = (conditionUuid: string): void => {
    if (readonly) return;

    setState((prevState) => {
      const updatedConditions = removeConditionFromList(prevState.conditions, conditionUuid);
      return {
        ...prevState,
        conditions: updatedConditions,
        logical_operator: updatedConditions.length === 0 ? LogicalOperators.AND : prevState.logical_operator,
      };
    });
  };

  // Replaces a condition or a group anywhere in the tree by UUID, including the group itself
  const replaceCondition = (conditionUuid: string, newCondition: Condition) => {
    if (readonly) return;

    if (state.uuid === conditionUuid) {
      setState(newCondition as ConditionGroup);
      return;
    }

    setState((prevState) => {
      const updateConditionsRecursively = (conditions: Array<Condition>): Array<Condition> => {
        return conditions.map((conditionOrGroup) => {
          if (conditionOrGroup.uuid === conditionUuid) {
            return newCondition;
          }
          if (conditionOrGroup.type === 'group') {
            return { ...conditionOrGroup, conditions: updateConditionsRecursively(conditionOrGroup.conditions) };
          }

          return conditionOrGroup; // If it's just a condition and not the one we are looking for, return as is
        });
      };

      return { ...prevState, conditions: updateConditionsRecursively(prevState.conditions) };
    });
  };

  const clearConditions = (): void => {
    setState(initialState);
  };

  const handleClose = () => {
    setShowMenu(false);
    setSelectedFilter(undefined);
  };

  const handleOpen = (groupUuid: string, filter?: Filter) => {
    setCurrentGroupUuid(groupUuid);
    setSelectedFilter(filter);
    setShowMenu(true);
  };

  return {
    state,
    setState,
    addCondition,
    removeCondition,
    replaceCondition,
    clearConditions,
    showMenu,
    selectedFilter,
    setSelectedFilter,
    handleClose,
    handleOpen,
    currentGroupUuid,
    readonly: Boolean(readonly),
    disableShortcuts: Boolean(disableShortcuts),
    localStorageKey,
    showViewSelector,
    setShowViewSelector,
  };
}

export default useConditionManager;
