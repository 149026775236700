import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { Disabled } from '../general/Disabled';
import { WidthHeightSettings } from '../general/WidthHeightSettings';

export function NavMenuContentSettings() {
  const { selectedContent, isMobile } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const contentWidthSettings = selectedContent.type === 'navbar_menu_list' && (
    <WidthHeightSettings selectedContent={selectedContent} attribute="contentWidth" defaultFixedValue={1280} />
  );

  return (
    <SectionRenderer title="Content">
      {isMobile ? <Disabled>{contentWidthSettings}</Disabled> : contentWidthSettings}
    </SectionRenderer>
  );
}
