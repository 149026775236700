import { useMemo } from 'react';
import { Color } from '@tremor/react';

import { ChartCard, Legend, PieChart } from '@/components/Chartsv2';
import { useSubscriberBreakdown } from '@/hooks/useSubscriberBreakdown';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { timePeriodLabels } from '@/utils/timePeriods';

const CHART_COLORS: Color[] = ['violet', 'gray'];

const SubscriberBreakdownChart = () => {
  const { period } = useTimePeriodSelect();
  const { data, isLoading } = useSubscriberBreakdown({ period: period as keyof typeof timePeriodLabels });

  const mappedChartData = useMemo(() => {
    if (isLoading || typeof data !== 'object') {
      return [];
    }

    return [
      {
        label: 'With Referrals',
        value: data.num_subscribers_with_referrals,
      },
      {
        label: 'Without Referrals',
        value: data.num_subscribers_without_referrals,
      },
    ];
  }, [data, isLoading]);

  return (
    <ChartCard
      noResultsText={isLoading ? 'Fetching...' : 'No data available within the chosen time range.'}
      noResults={mappedChartData.length === 0}
    >
      <div className="space-y-5">
        <PieChart index="label" data={mappedChartData} colors={CHART_COLORS} showLabel />
        <div className="flex justify-end w-full">
          <Legend categories={['With Referrals', 'Without Referrals']} colors={CHART_COLORS} />
        </div>
      </div>
    </ChartCard>
  );
};

export default SubscriberBreakdownChart;
