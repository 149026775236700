import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { StripeSubscriptionPreview } from '@/interfaces/stripe';
import api from '@/services/swarm';

interface ApiResponse {
  records: StripeSubscriptionPreview[];
  pagination: Pagination;
}
const useStripeMigrationPreview = (stripeMigrationId?: string, page: number = 1) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPreview = () =>
    api
      .get(`/stripe_migrations/${stripeMigrationId}/preview`, {
        params: {
          publication_id: currentPublicationId,
          page,
        },
      })
      .then((res) => res.data);

  return useQuery<ApiResponse>(['stripe_migrations', stripeMigrationId, 'preview', page], fetchPreview, {
    enabled: !!stripeMigrationId,
    keepPreviousData: true,
  });
};

export default useStripeMigrationPreview;
