import { AttributeSettingProps } from '../../../types';
import { SignupFlowSettings } from '../../SignupFlowSettings';
import ActionRenderer from '../ActionRenderer';

const SignupActions = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeType } = activeNodeResult;

  return (
    <ActionRenderer nodeType="signup" activeNodeType={activeNodeType}>
      <SignupFlowSettings editor={editor} activeNodeResult={activeNodeResult} />
    </ActionRenderer>
  );
};

export default SignupActions;
