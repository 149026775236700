import React, { memo, useCallback, useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import { Level } from '@tiptap/extension-heading';

import { IconName } from '../../lib/types';
import { ToolbarButton } from '../buttons/ToolbarButton';
import { Toolbar } from '../ui/Toolbar';

import { ToolbarProps } from './types';

export const HeadingsToolbar = memo(
  ({
    canHeadline,
    level,
    onHeadline,
    onReset,
  }: ToolbarProps & {
    level: number | null;
    canHeadline: boolean;
    onHeadline: (level: Level) => boolean;
    onReset: () => void;
  }): JSX.Element => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const onH1 = useCallback(() => {
      onHeadline(1);
    }, [onHeadline]);

    const onH2 = useCallback(() => {
      onHeadline(2);
    }, [onHeadline]);

    const onH3 = useCallback(() => {
      onHeadline(3);
    }, [onHeadline]);

    const onH4 = useCallback(() => {
      onHeadline(4);
    }, [onHeadline]);

    const onH5 = useCallback(() => {
      onHeadline(5);
    }, [onHeadline]);

    const onH6 = useCallback(() => {
      onHeadline(6);
    }, [onHeadline]);

    const activeIcon = (level ? `Heading${level}` : 'Heading') as IconName;

    return (
      <Tippy
        render={(attrs) => (
          <Toolbar
            tabIndex={-1}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attrs}
          >
            <ToolbarButton can={canHeadline} is={level === 1} icon="Heading1" onClick={onH1} title="Heading 1" />
            <ToolbarButton can={canHeadline} is={level === 2} icon="Heading2" onClick={onH2} title="Heading 2" />
            <ToolbarButton can={canHeadline} is={level === 3} icon="Heading3" onClick={onH3} title="Heading 3" />
            <ToolbarButton can={canHeadline} is={level === 4} icon="Heading4" onClick={onH4} title="Heading 4" />
            <ToolbarButton can={canHeadline} is={level === 5} icon="Heading5" onClick={onH5} title="Heading 5" />
            <ToolbarButton can={canHeadline} is={level === 6} icon="Heading6" onClick={onH6} title="Heading 6" />
            <ToolbarButton can={level !== null} icon="Cancel" onClick={onReset} is={false} title="Paragraph" />
          </Toolbar>
        )}
        offset={[0, 8]}
        trigger="click"
        interactive
        onShow={() => {
          setIsPanelOpen(true);
        }}
        onHidden={() => {
          setIsPanelOpen(false);
        }}
      >
        <div>
          <ToolbarButton
            can
            icon={activeIcon}
            iconRight="ChevronDown"
            is={isPanelOpen || !!level}
            title={!isPanelOpen ? 'Headings' : ''}
            muted={isPanelOpen && !level}
          />
        </div>
      </Tippy>
    );
  }
);

HeadingsToolbar.displayName = 'HeadingsToolbar';

export default HeadingsToolbar;
