import React, { useCallback } from 'react';

import { Button } from '../ui/Button';
import { Icon } from '../ui/Icon';
import { Tooltip } from '../ui/Tooltip';

import { ButtonProps } from './types';

const icon = 'Trash';
const title = 'Delete row';
const shortcut = undefined;

export const DeleteRowButton = ({ editor, $showTooltip = false, ...rest }: ButtonProps): JSX.Element => {
  const deleteRow = useCallback(() => {
    editor.chain().focus().deleteRow().run();
  }, [editor]);

  return (
    <Tooltip enabled={$showTooltip} title={title} shortcut={shortcut}>
      <Button
        $leftSlot={<Icon name={icon} />}
        onClick={deleteRow}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export default DeleteRowButton;
