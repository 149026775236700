import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import { AdNetworkPaymentRequest } from '@/interfaces/ad_network/internal/payment_request';
import api from '@/services/swarm';

export default function useCampaignPaymentRequestUpdate({ campaignId, id }: { campaignId: string; id: string }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateCampaignPaymentRequest = (formData: FormData): Promise<AdNetworkPaymentRequest> =>
    api.patch(`/ad_network/internal/campaigns/${campaignId}/payment_requests/${id}`, formData).then((res) => res.data);

  return useMutation<AdNetworkPaymentRequest, MutationErrorResponse, FormData>(updateCampaignPaymentRequest, {
    onSuccess: () => {
      toast.success('Payment Request updated successfully');
      queryClient.invalidateQueries(['ad_network', 'internal', 'campaigns', campaignId, 'payment_requests']);
      navigate(`/ad_network/campaigns/${campaignId}/payment_requests`);
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
