import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { PlusCircle } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { useTestimonials } from '@/hooks/useTestimonials';
import { Testimonial } from '@/interfaces/testimonial';
import api from '@/services/swarm';

import { Button } from '../../_components/UI/Button';
import {
  Table,
  TableBody,
  TableCaption,
  TableCard,
  TableHead,
  TableHeader,
  TablePaginationWrapper,
  TableRow,
  TableSimplePagination,
} from '../../_components/UI/Table';
import { Text } from '../../_components/UI/Text';

import TestimonialModal from './_components/TestimonialModal';
import TestimonialRow from './_components/TestimonialRow';

const WebsiteSettingsTestimonials = () => {
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching, isLoading } = useTestimonials({
    search: '',
  });

  const queryKey = ['testimonials', currentPublication?.id];

  const testimonials = data?.pages.flatMap((page) => page.testimonials) || [];
  const totalCount = data?.pages[0]?.pagination?.total || 0;
  const showingCount = testimonials?.length || 0;
  const noResults = !isRefetching && !isLoading && testimonials.length === 0;

  const [testimonialToEdit, setTestimonialToEdit] = useState<Testimonial | null>(null);
  const [isNewTestimonialOpen, setIsNewTestimonialOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleClose = () => {
    setIsSaving(false);
    setTestimonialToEdit(null);
    setIsNewTestimonialOpen(false);
  };

  const handleAddTestimonial = (payload: any): void => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append('publication_id', currentPublication?.id || '');
    formData.append('testimonial[name]', payload.name);
    formData.append('testimonial[description]', payload.description);
    formData.append('testimonial[message]', payload.message);

    if (typeof payload.picture !== 'string') {
      formData.append('testimonial[picture]', payload.picture[0]);
    }

    api
      .post('testimonials', formData)
      .then(() => {
        queryClient.invalidateQueries(queryKey);
        toast.success(`Created!`);
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast(error);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleEditTestimonial = (payload: any): void => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append('publication_id', currentPublication?.id || '');
    formData.append('testimonial[name]', payload.name);
    formData.append('testimonial[description]', payload.description);
    formData.append('testimonial[message]', payload.message);

    if (typeof payload.picture !== 'string') {
      formData.append('testimonial[picture]', payload.picture[0]);
    }

    api
      .patch(`testimonials/${testimonialToEdit?.id}`, formData)
      .then(() => {
        queryClient.invalidateQueries(queryKey);
        toast.success(`Updated!`);
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast(error);
      })
      .finally(() => {
        handleClose();
      });
  };

  const isOpen = isNewTestimonialOpen || Boolean(testimonialToEdit);

  return (
    <>
      <TestimonialModal
        isSubmitting={isSaving}
        isOpen={isOpen}
        testimonial={testimonialToEdit}
        onClose={handleClose}
        onSubmit={isNewTestimonialOpen ? handleAddTestimonial : handleEditTestimonial}
      />

      <div className="flex flex-col space-y-2">
        <div className="flex justify-end">
          <div>
            <Button variant="primary" onClick={() => setIsNewTestimonialOpen(true)} LeftIcon={PlusCircle}>
              Add testimonial
            </Button>
          </div>
        </div>

        <TablePaginationWrapper>
          <TableCard>
            <Table>
              {noResults && (
                <TableCaption>
                  <div className="pb-4">
                    <Text size="sm" weight="medium" variant="secondary" as="span">
                      No results found
                    </Text>
                  </div>
                </TableCaption>
              )}
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[200px] truncate whitespace-nowrap">Name</TableHead>
                  <TableHead className="whitespace-nowrap">Message</TableHead>
                  <TableHead className="text-right">Image</TableHead>
                  <TableHead className="text-right w-4" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {testimonials.map((testimonial) => {
                  return (
                    <TestimonialRow
                      key={testimonial.id}
                      testimonial={testimonial}
                      onEdit={() => setTestimonialToEdit(testimonial)}
                      queryKey={queryKey as string[]}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableCard>
          <TableSimplePagination
            showingCount={showingCount}
            totalCount={totalCount}
            hasNextPage={Boolean(hasNextPage)}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
          />
        </TablePaginationWrapper>
      </div>
    </>
  );
};

export default WebsiteSettingsTestimonials;
