const Countries: any = {
  AL: 'Albania',
  DZ: 'Algeria',
  AO: 'Angola',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BJ: 'Benin',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BN: 'Brunei',
  BG: 'Bulgaria',
  KH: 'Cambodia',
  CA: 'Canada',
  CL: 'Chile',
  CO: 'Colombia',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FI: 'Finland',
  FR: 'France',
  GA: 'Gabon',
  GM: 'Gambia',
  DE: 'Germany',
  GH: 'Ghana',
  GR: 'Greece',
  GT: 'Guatemala',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  CI: 'Ivory Coast',
  JM: 'Jamaica',
  JP: 'Japan',
  JO: 'Jordan',
  KE: 'Kenya',
  KW: 'Kuwait',
  LA: 'Laos',
  LV: 'Latvia',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macau',
  MG: 'Madagascar',
  MY: 'Malaysia',
  MT: 'Malta',
  MU: 'Mauritius',
  MX: 'Mexico',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  MA: 'Morocco',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NE: 'Niger',
  NG: 'Nigeria',
  MK: 'North Macedonia',
  NO: 'Norway',
  OM: 'Oman',
  PA: 'Panama',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PL: 'Poland',
  PT: 'Portugal',
  QA: 'Qatar',
  RO: 'Romania',
  RW: 'Rwanda',
  LC: 'Saint Lucia',
  SM: 'San Marino',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ZA: 'South Africa',
  KR: 'South Korea',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SE: 'Sweden',
  CH: 'Switzerland',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VN: 'Vietnam',
};

export default Countries;
