import { Outlet } from 'react-router-dom';
import { ArrowLeftIcon, ExclamationTriangleIcon, RectangleStackIcon } from '@heroicons/react/20/solid';

import useAdBlockDetect from '@/hooks/useAdBlockDetect';
import { Button } from '@/ui/Button';

import { Navigation } from './_components/Navigation';

export default function Layout() {
  const { adBlockDetected, ignoreAdBlocker } = useAdBlockDetect();

  return (
    <div className="divide-x divide-gray-100 lg:flex">
      <Navigation>
        <Navigation.Group>
          <Navigation.Item item={{ name: 'Advertisers', href: '/ad_network/advertisers' }} />
          <Navigation.Item item={{ name: 'Disbursements', href: '/ad_network/disbursements' }} />
          <Navigation.Item item={{ name: 'Industries', href: '/ad_network/industries' }} />

          <Navigation.Item item={{ name: 'Publications', href: '/ad_network/publications' }} />
          <Navigation.Item item={{ name: 'Campaigns', href: '/ad_network/campaigns' }} />
        </Navigation.Group>
        <Navigation.Group>
          <Navigation.Item item={{ name: 'Views', href: '/ad_network/workspace_views', icon: RectangleStackIcon }} />
        </Navigation.Group>
        <Navigation.Group>
          <Navigation.Item item={{ name: 'Exit', href: '/', icon: ArrowLeftIcon }} />
        </Navigation.Group>
      </Navigation>

      <main className="flex-1 bg-white">
        {adBlockDetected ? (
          <div className="h-screen p-4">
            <div className="flex flex-wrap mx-auto max-w-2xl p-8 border rounded bg-white">
              <div className="rounded-full bg-gray-200 w-10 h-10 flex items-center justify-center mr-4 mb-4">
                <ExclamationTriangleIcon className="h-6 w-6 text-gray-500" />
              </div>
              <div className="sm:flex-1">
                <h1 className="text-lg sm:text-base text-gray-800 font-bold">Ad blocker detected</h1>
                <p className="text-lg sm:text-base text-gray-800">
                  It appears that you are using an ad blocker — some elements of the site may not work properly while an
                  ad blocker is enabled. Please consider disabling your ad blocker for this site.
                </p>
                <Button onClick={ignoreAdBlocker} className="mt-4" variant="primary-inverse">
                  I understand, continue anyway
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Outlet />
        )}
      </main>
    </div>
  );
}
