import { UpgradePage } from '@/interfaces/upgrade_page';
import ColorInput from '@/routes/website_builder/_components/Form/ColorInput';
import ImageForm from '@/routes/website_builder/_components/Form/PageSpecific/ImageForm';
import {
  NavSectionIcon,
  SectionGroupingSlideOut,
} from '@/routes/website_builder/_components/Helpers/SectionGroupingSlideOut';

import FontForm from '../../Form/PageSpecific/FontForm';
import SwitchInput from '../../Form/SwitchInput';
import PropertiesGrouping from '../../Helpers/PropertiesGrouping';

interface Props {
  upgradePage: UpgradePage;
  handleSave: (payload: any) => void;
  handleUploadImage: (type: string, file: File | null) => void;
}

const HeroSection = ({ upgradePage, handleSave, handleUploadImage }: Props) => {
  const mainSectionWithDefaults = upgradePage.main_section_with_defaults;

  return (
    <SectionGroupingSlideOut
      title="Hero Copy Section"
      showPlanBadge
      sectionIcon={<NavSectionIcon />}
      highlightProps={{
        sectionId: 'upgrade-hero-section',
      }}
    >
      <PropertiesGrouping label="General">
        <SwitchInput
          label="Show Logo"
          name="main-section-logo-enabled"
          enabled={mainSectionWithDefaults.enable_logo}
          onSave={(value: boolean) => {
            handleSave({
              ...upgradePage?.main_section,
              enable_logo: value,
            });
          }}
        />
        <ImageForm
          label="Background"
          dimensions="Upload a high-quality 1920px x 1080px PNG for best results."
          saveKey="main_bg_image"
          alwaysShowingChildren
          handleUploadImage={handleUploadImage}
          imageValue={upgradePage?.main_bg_image_url || ''}
        >
          <ColorInput
            label="Color"
            color={mainSectionWithDefaults.bg_color}
            resettable={{
              label: 'Global Background Color',
              resetValue: null,
              check: upgradePage?.main_section.bg_color !== null,
            }}
            onSave={(color: string) => {
              handleSave({
                ...upgradePage?.main_section,
                bg_color: color,
              });
            }}
          />
        </ImageForm>
      </PropertiesGrouping>

      <FontForm
        nodeId="upgrade-page-overline-font-form"
        label="Overline"
        supportsMarkdown
        onSave={(key, value) => {
          handleSave({
            ...upgradePage?.main_section,
            [key]: value,
          });
        }}
        textValue={mainSectionWithDefaults.overline}
        onSaveText={(value: string) => {
          handleSave({
            ...upgradePage?.main_section,
            overline: value,
          });
        }}
        editableProps={{
          overline_color: {
            type: 'colorInput',
            value: mainSectionWithDefaults.overline_color,
            resetCheckValue: upgradePage?.main_section.overline_color,
            resetLabel: 'Global Overline Color',
          },
          overline_font_family: {
            type: 'fontFamilySelect',
            value: mainSectionWithDefaults.overline_font_family,
            resetCheckValue: upgradePage.main_section.overline_font_family,
            resetLabel: 'Global Heading Font',
          },
          overline_font_size: { type: 'fontSizeSelect', value: mainSectionWithDefaults.overline_font_size },
        }}
      />

      <FontForm
        nodeId="upgrade-page-headline-font-form"
        label="Headline"
        supportsMarkdown
        onSave={(key, value) => {
          handleSave({
            ...upgradePage?.main_section,
            [key]: value,
          });
        }}
        textValue={mainSectionWithDefaults.headline}
        onSaveText={(value: string) => {
          handleSave({
            ...upgradePage?.main_section,
            headline: value,
          });
        }}
        editableProps={{
          headline_color: {
            type: 'colorInput',
            value: mainSectionWithDefaults.headline_color,
            resetCheckValue: upgradePage?.main_section.headline_color,
            resetLabel: 'Global Headline Color',
          },
          headline_font_family: {
            type: 'fontFamilySelect',
            value: mainSectionWithDefaults.headline_font_family,
            resetCheckValue: upgradePage.main_section.headline_font_family,
            resetLabel: 'Global Heading Font',
          },
          headline_font_size: { type: 'fontSizeSelect', value: mainSectionWithDefaults.headline_font_size },
        }}
      />

      <FontForm
        nodeId="upgrade-page-description-font-form"
        label="Description"
        supportsMarkdown
        onSave={(key, value) => {
          handleSave({
            ...upgradePage?.main_section,
            [key]: value,
          });
        }}
        textValue={mainSectionWithDefaults.description}
        onSaveText={(value: string) => {
          handleSave({
            ...upgradePage?.main_section,
            description: value,
          });
        }}
        editableProps={{
          description_color: {
            type: 'colorInput',
            value: mainSectionWithDefaults.description_color,
            resetCheckValue: upgradePage?.main_section.description_color,
            resetLabel: 'Global Body Color',
          },
          description_font_family: {
            type: 'fontFamilySelect',
            value: mainSectionWithDefaults.description_font_family,
            resetCheckValue: upgradePage.main_section.description_font_family,
            resetLabel: 'Global Body Font',
          },
          description_font_size: { type: 'fontSizeSelect', value: mainSectionWithDefaults.description_font_size },
        }}
      />

      <FontForm
        nodeId="upgrade-page-redirect-link-cta-font-form"
        label="Redirect Link"
        tooltip={{
          id: 'upgrade-page-redirect-link-cta-font-form-tooltip',
          text: 'This text will only be displayed during the post-signup flow and will not always be present on the page.',
        }}
        onSave={(key, value) => {
          handleSave({
            ...upgradePage?.main_section,
            [key]: value,
          });
        }}
        textValue={mainSectionWithDefaults.redirect_text}
        onSaveText={(value: string) => {
          handleSave({
            ...upgradePage?.main_section,
            redirect_text: value,
          });
        }}
        editableProps={{
          redirect_text_color: {
            type: 'colorInput',
            value: mainSectionWithDefaults.redirect_text_color,
            resetCheckValue: upgradePage?.main_section.redirect_text_color,
            resetLabel: 'Global Body Color',
          },
          redirect_text_font_family: {
            type: 'fontFamilySelect',
            value: mainSectionWithDefaults.redirect_text_font_family,
            resetCheckValue: upgradePage.main_section.redirect_text_font_family,
            resetLabel: 'Global Body Font',
          },
          redirect_text_font_size: { type: 'fontSizeSelect', value: mainSectionWithDefaults.redirect_text_font_size },
        }}
      />
    </SectionGroupingSlideOut>
  );
};

export default HeroSection;
