import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

import SubscriberTagSelectInput from '@/components/SubscriberTags/SubscriberTagSelectInput';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSubscriber } from '@/hooks';
import {
  PublicationSubscriberTagReference,
  PublicationSubscriberTagSelectItem,
  SubscriptionSubscriberTag,
} from '@/interfaces/subscriber_tag';
import api from '@/services/swarm';

const SubscriberTags = () => {
  const { subscriberId } = useParams();
  const subscriberQuery = useSubscriber({ id: subscriberId || '' });
  const { data, refetch } = subscriberQuery;
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const subscriberTags: SubscriptionSubscriberTag[] = data?.subscription?.subscription_subscriber_tags || [];
  const transformedSelectedTags: PublicationSubscriberTagReference[] = subscriberTags.map((tag) => ({
    id: tag.id,
    publication_subscriber_tag_id: tag.publication_subscriber_tag_id,
  }));
  const subscriptionId = data?.subscription?.id;

  const handleDelete = (tagId: string) => {
    const params = {
      publication_id: currentPublicationId,
    };
    api
      .delete(`/subscription_subscriber_tags/${tagId}`, { params })
      .then(() => {
        refetch();
        setIsDeleting(false);
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        toast.success('Tag deleted successfully');
        setIsDeleting(false);
      });
  };

  const onDeleteSubscriptionSubscriberTag = (subscriberTagId: string) => {
    setIsDeleting(true);
    handleDelete(subscriberTagId);
  };

  const onCreateSubscriptionSubscriberTag = (tag: PublicationSubscriberTagSelectItem) => {
    const params = {
      publication_subscriber_tag_id: tag?.value,
      subscription_id: subscriptionId,
    };
    api
      .post(`/subscription_subscriber_tags/`, { publication_id: currentPublicationId, subscriber_tag: params })
      .then(() => {
        refetch();
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        toast.success('Tag added successfully');
      });
  };

  return (
    <>
      <div className="pt-3">
        {subscriberTags && (
          <SubscriberTagSelectInput
            selectValue="publication_subscriber_tag_id"
            selectedTags={transformedSelectedTags}
            handleDeselect={onDeleteSubscriptionSubscriberTag}
            handleSelect={onCreateSubscriptionSubscriberTag}
            isDeleting={isDeleting}
          />
        )}
      </div>
      <div className="mt-2 relative text-gray-700 font-semibold p-2">
        <Link to="/subscribers/tags" target="_blank">
          View All
          <ArrowRightIcon className="h-5 w-5 inline pb-1 ml-2" />
        </Link>
      </div>
    </>
  );
};

export default SubscriberTags;
