import { useState } from 'react';
import moment from 'moment-mini';

import { DatePicker } from '@/components/Form';
import CurrencyInput from '@/components/Form/CurrencyInput';
import LoadingBox from '@/components/LoadingBox';
import { MultiActionModal } from '@/components/MultiActionModal';
import useDisbursementApproveAll from '@/hooks/useAdNetwork/internal/useDisbursementApproveAll';
import useDisbursementApproveAllPreview from '@/hooks/useAdNetwork/internal/useDisbursementApproveAllPreview';
import { Button } from '@/ui/Button';

const formatter = new Intl.NumberFormat('en-US');

const Preview = ({
  maxApproveAmountCents = 0,
  startDate = null,
  endDate = null,
}: {
  maxApproveAmountCents: number;
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { data, isLoading, isError } = useDisbursementApproveAllPreview({
    maximum_amount_cents: maxApproveAmountCents,
    start_date: startDate,
    end_date: endDate,
  });

  const range =
    data?.start_date && data.end_date
      ? `disbursements that ran on or between ${moment(data.start_date).format('LLL')} and ${moment(
          data.end_date
        ).format('LLL')}`
      : '';

  const after =
    data?.start_date && !data.end_date
      ? `disbursements that ran on or after ${moment(data.start_date).format('LLL')}`
      : '';

  const before =
    !data?.start_date && data?.end_date
      ? `disbursements that ran on or before ${moment(data.end_date).format('LLL')}`
      : '';

  const all = !data?.start_date && !data?.end_date ? 'all disbursements' : '';

  const rangeText = range || after || before || all;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      {data && (
        <div className="space-y-2">
          {data.limited && (
            <div className="bg-yellow-100 text-yellow-800 p-4 rounded">
              <p>
                Please note, a safety limit of {data.max_approve_amount} per disbursement has been set. If you need to
                approve more than this amount, please reach out to the dev team.
              </p>
            </div>
          )}
          <p>
            Are you sure you want to approve {rangeText} under <strong>{data.max_approve_amount}</strong>?
          </p>

          <p>
            This will auto approve <strong>{formatter.format(data.total_count)}</strong> disbursements, totaling{' '}
            <strong>{data.total_amount}</strong>.
          </p>
        </div>
      )}
    </LoadingBox>
  );
};

const BulkApproveAction = ({ refetch }: { refetch: () => void }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [maxApproveAmountCents, setMaxApproveAmountCents] = useState<number | undefined>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleClose = () => {
    setIsOpen(false);
    setMaxApproveAmountCents(undefined);
  };

  const handleApprove = useDisbursementApproveAll({
    maximum_amount_cents: maxApproveAmountCents || 0,
    start_date: startDate,
    end_date: endDate,
  });

  return (
    <>
      <Button variant="primary-inverse" size="xs" type="button" onClick={() => setIsOpen(true)}>
        Bulk Approve
      </Button>
      <MultiActionModal
        isOpen={isOpen}
        onClose={handleClose}
        resourceId="disbursements"
        modalSize="lg"
        steps={[
          {
            title: 'Bulk Approve',
            children: (
              <>
                <p>
                  Set a maximum amount to auto-approve disbursements. Any disbursements that exceed this amount will
                  require manual approval.
                </p>
                <p>
                  {/* See MAX_APPROVE_AMOUNT in app/services/ad_network/disbursements/approve_all.rb */}
                  <i>
                    Please note, there is safety limit of $500. If you need to approve more than this amount, please
                    reach out to the dev team.
                  </i>
                </p>
                <CurrencyInput
                  name="max_approve_amount"
                  onChange={(value) => setMaxApproveAmountCents(value)}
                  value={maxApproveAmountCents}
                  helperText="Auto-approve disbursements up to this amount (inclusive)."
                  labelText="Max Amount"
                />
                <div>
                  <div className="text-sm font-medium text-gray-800 pb-2">Start Date</div>
                  <DatePicker
                    helperText="Start date for disbursements to approve"
                    onChange={(date) => setStartDate(date || null)}
                    value={startDate || undefined}
                  />
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-800 pb-2">End Date</div>
                  <DatePicker
                    helperText="End date for disbursements to approve"
                    onChange={(date) => setEndDate(date || null)}
                    value={endDate || undefined}
                  />
                </div>
              </>
            ),
            actions: [
              {
                actionText: 'Cancel',
                onClick: () => handleClose(),
              },
              {
                actionText: 'Review',
                nextStep: 1,
                disabled: maxApproveAmountCents === undefined || maxApproveAmountCents <= 0,
                isWorking: false,
              },
            ],
          },
          {
            title: 'Review',
            children: (
              <Preview
                maxApproveAmountCents={maxApproveAmountCents || 0}
                startDate={startDate || null}
                endDate={endDate || null}
              />
            ),
            actions: [
              {
                actionText: 'Go back',
                nextStep: 0,
              },
              {
                actionText: 'Yes, approve disbursements',
                variant: 'primary',
                onClick: () =>
                  handleApprove
                    .mutateAsync()
                    .then(() => handleClose())
                    .then(() => refetch()),
                isWorking: handleApprove.isLoading,
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default BulkApproveAction;
