import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

export type SplitTestPayload = {
  duration?: number;
  sample_pct?: number;
};

const useUpdateSplitTest = (postId: String, onError: Function, onSuccess: Function, onSettled: Function) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (split_test: SplitTestPayload) =>
      api
        .patch(`/posts/${postId}/split_test`, {
          publication_id: currentPublicationId,
          split_test,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        return queryClient.invalidateQueries(['splitTest', postId]);
      },
      onError: (errPayload: any) => {
        if (onError) {
          onError();
        }
        toast.error(errPayload?.response?.data?.error || "Couldn't update A/B Test");
      },
      onSettled: () => {
        if (onSettled) {
          onSettled();
        }
      },
    }
  );
};

export default useUpdateSplitTest;
