import { useEffect, useRef, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useDebounce } from 'use-debounce';

import { useAdvertisers } from '@/hooks/useAdNetwork/internal';
import { AdNetworkAdvertiser } from '@/interfaces/ad_network/internal/advertiser';
import { Button } from '@/ui/Button';

import { Input } from '../_components/Input';
import { List } from '../_components/List';
import { LoadingListPage } from '../_components/LoadingListPage';
import { PageHeading } from '../_components/PageHeading';
import { Shortcut } from '../_components/Shortcut';

interface Props {
  query: string;
  setQuery: (query: string) => void;
  advertisers: AdNetworkAdvertiser[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
}

const Advertisers = ({ advertisers, fetchNextPage, hasNextPage, query, setQuery }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '/') {
        if (document.activeElement !== inputRef.current) {
          event.preventDefault();

          inputRef.current?.focus();
        }
      }

      if (event.key === 'Escape') {
        inputRef.current?.blur();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="">Advertisers</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <div className="relative">
            <Input
              placeholder="Search..."
              sizeVariant="sm"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              type="search"
              ref={inputRef}
            />
            <div className="absolute right-1.5 top-1/2 transform -translate-y-1/2">
              <Shortcut>/</Shortcut>
            </div>
          </div>
          <Button to="new" size="xs" variant="primary-inverse" Icon={PlusIcon}>
            Add advertiser
          </Button>
        </PageHeading.Side>
      </PageHeading>

      <List>
        {advertisers.map((advertiser) => (
          <List.Item key={advertiser.id} to={advertiser.id}>
            <List.Item.Text variant="primary">{advertiser.name}</List.Item.Text>
            <List.Item.Text variant="secondary">{advertiser.hostname}</List.Item.Text>
          </List.Item>
        ))}
      </List>

      {hasNextPage && (
        <div className="p-4 border-t border-gray-100">
          <Button onClick={() => fetchNextPage()} variant="primary-inverse">
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

export default function Loader() {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 800);
  const { data, isSuccess, hasNextPage, fetchNextPage, isLoading, isError } = useAdvertisers({ query: debouncedQuery });

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const advertisers = data?.pages.flatMap((page) => page.advertisers) || [];

  return (
    <Advertisers
      query={query}
      setQuery={setQuery}
      advertisers={advertisers}
      fetchNextPage={fetchNextPage}
      hasNextPage={!!hasNextPage}
    />
  );
}
