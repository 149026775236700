import moment from 'moment-mini';

import { LineChart } from '@/components/charts';

const Chart = ({ data }: any) => {
  const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: 'Total unique opens',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Hours after publishing',
        },
        ticks: {
          callback: (_value: any, index: number) => index,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (val: any) => {
            return moment(val[0].label).format('MMM Do, h:mm a');
          },
        },
      },
    },
  };

  return (
    <LineChart
      data={data}
      height={400}
      options={options}
      missingDataText="We're crunching the numbers, check back later."
    />
  );
};

export default Chart;
