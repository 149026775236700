import { IconComponent } from '@heroicons/react';
import { CheckBadgeIcon, InformationCircleIcon, LightBulbIcon, RocketLaunchIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import Card from '@/components/Card';
import { getColor } from '@/components/DesignTokens';
import IconButton from '@/components/IconHelpers/IconButton';
import { LineDivider } from '@/components/LineDivider';
import { Typography } from '@/components/Typography';

interface TipBlockProps {
  Icon: IconComponent;
  title: string;
  description: string;
}

const TipBlock = ({ Icon, title, description }: TipBlockProps) => (
  <div className="flex sm:w-1/3 gap-x-2">
    <div className={cx('w-fit h-fit p-1.5 rounded-full', getColor('info/200', 'background'))}>
      <Icon className={cx('w-3.5 h-3.5', getColor('surface/900', 'text'))} />
    </div>
    <div className="flex flex-col gap-y-2 justify-start">
      <Typography size="sm" weight="medium">
        {title}
      </Typography>
      <Typography size="xs">{description}</Typography>
    </div>
  </div>
);

interface Props {
  onDismiss: () => void;
}

const ApplicationsHelperBlock = ({ onDismiss }: Props) => {
  return (
    <Card className="relative" background={cx(getColor('info/50', 'background'))}>
      <div className="absolute right-4 top-4">
        <IconButton onClick={onDismiss} backgroundColor="transparent">
          <XMarkIcon />
        </IconButton>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-2 items-center">
          <InformationCircleIcon className={cx('w-5 h-5', getColor('info/500', 'text'))} />
          <Typography weight="semibold">How it works</Typography>
        </div>
        <Typography size="xs">
          Publications can apply to Boost your offer in our two-way marketplace. Review their application to accept or
          deny their request.{' '}
        </Typography>
      </div>
      <LineDivider className="my-4" />
      <div className="flex flex-col gap-y-4 sm:flex-row sm:justify-between sm:gap-x-4">
        <TipBlock
          Icon={LightBulbIcon}
          title="A publication sees your offer in our Boost Marketplace"
          description="Once you have created your offer and deposited funds, your offer will be live for other publications to
              view."
        />

        <TipBlock
          Icon={CheckBadgeIcon}
          title="They send you a Web Boost application to review"
          description="Publishers browse and apply to offers that are most enticing. They can apply to boost your publication via
          our Web channel first."
        />

        <TipBlock
          Icon={RocketLaunchIcon}
          title="If you’ve enabled the Email Boost channel in your offer, they can request to add your boost to a post
          additionally"
          description="To ensure that only publications you trust can send your boost in a post, they must first get approval for
          the web boost application before they can send an email boost request for you to review."
        />
      </div>
    </Card>
  );
};

export default ApplicationsHelperBlock;
