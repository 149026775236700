import { Link } from 'react-router-dom';

import Text from '@/components/Text';

const AcquisitionAttribute = ({ label, value, href }: { label: string; value: string; href?: string }) => {
  return (
    <div>
      <Text size="sm" className="text-gray-500 mb-2">
        {label}
      </Text>
      {href ? (
        <Link to={href} target="_blank">
          <Text className="text-action-tertiary-600 break-all">{value}</Text>
        </Link>
      ) : (
        <div className="w-full break-all">
          <Text className="text-gray-600 break-all">{value}</Text>
        </div>
      )}
    </div>
  );
};

export default AcquisitionAttribute;
