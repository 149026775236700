import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import { Input } from '@/components/Form';
import { useUpdateEmailMessage, useWelcomeEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';
import { Button } from '@/ui/Button';
import Switch from '@/ui/Switch';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';
import ensureUrlProtocol from '@/utils/ensureUrlProtocol';
import stripUrlProtocol from '@/utils/stripUrlProtocol';
import validateUrl from '@/utils/validateUrl';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import FormRow from '../../_components/FormRow';
import { usePublicationSettings } from '../context';

interface Props {
  publication: Publication;
}

const PresetForm = ({ publication }: Props) => {
  const navigate = useNavigate();

  const { handleChangeInAttribute, settings, savingAttribute, setSavingAttribute } = usePublicationSettings();
  const { data: welcomeEmail, isSuccess: isSuccessWelcomeEmail } = useWelcomeEmail(publication.id);
  const queryClient = useQueryClient();

  const { mutateAsync: updateEmailMessage } = useUpdateEmailMessage(publication.id, {
    emailMessageId: welcomeEmail?.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['welcome_email', publication.id], { exact: true });
      queryClient.invalidateQueries(['email_messages', 'preview', welcomeEmail?.id], { exact: true });
      queryClient.invalidateQueries(['publications', publication.id]);
      toast.success('Welcome Email saved!');
    },
    onError: (err: any) => toast.error(err?.response?.data?.error || 'Something went wrong, please try again'),
  });

  const isContentSet = !!(welcomeEmail?.preview_text && welcomeEmail?.subject_line);

  const [isWelcomeEmailActive, setIsWelcomeEmailActive] = useState(welcomeEmail?.active || false);
  const [isDoubleOptInRequired, setIsDoubleOptInRequired] = useState(publication.double_opt_required);
  const [shouldAutoResendOptIn, setShouldAutoResendOptIn] = useState(publication.auto_resend_opt_in);

  useEffect(() => {
    if (isSuccessWelcomeEmail) {
      setIsWelcomeEmailActive(welcomeEmail?.active);
    }
  }, [isSuccessWelcomeEmail, welcomeEmail?.active]);

  return (
    <BodyContainer>
      <CardHeader title="Preset Emails" description="Configure new subscriber triggered emails" />
      <FormRow
        label="Welcome Email"
        helperText="Enable a welcome email that is sent to new subscribers"
        cta={
          <Button
            size="xs"
            type="button"
            variant="primary-inverse"
            Icon={ArrowUpRightIcon}
            iconRight
            onClick={() =>
              navigate(
                appendSettingsPublicationId(
                  isContentSet
                    ? '/settings/publication/emails/welcome_email'
                    : '/settings/publication/emails/welcome_email/edit',
                  publication.id
                )
              )
            }
          >
            {isContentSet ? 'View Welcome Email' : 'Configure Welcome Email'}
          </Button>
        }
      >
        <Switch
          variant="primary"
          checked={isWelcomeEmailActive}
          name="active"
          onChange={async (_name: string, value: boolean) => {
            try {
              setIsWelcomeEmailActive(value);
              setSavingAttribute('email_message.active');
              await updateEmailMessage({ active: value });
            } catch (error: any) {
              setIsWelcomeEmailActive(!value);
            }

            setSavingAttribute(null);
          }}
          disabledText={!isContentSet ? 'Please configure Welcome Email first' : undefined}
          disabled={savingAttribute === 'email_message.active' || !isContentSet}
        />
      </FormRow>
      <FormRow
        label="Double Opt in Email"
        helperText="Require new subscribers to confirm their email address before adding them to your list. This will not impact your existing subscribers or subscriber imports"
        cta={
          <Button
            size="xs"
            type="button"
            variant="primary-inverse"
            Icon={ArrowUpRightIcon}
            iconRight
            onClick={() =>
              navigate(appendSettingsPublicationId('/settings/publication/emails/opt_in_email', publication.id))
            }
          >
            View Opt In Email
          </Button>
        }
      >
        <Switch
          variant="primary"
          checked={isDoubleOptInRequired}
          name="double_opt_required"
          onChange={async (_name: string, value: boolean) => {
            try {
              setIsDoubleOptInRequired(value);
              await handleChangeInAttribute('double_opt_required')(value);
            } catch (error: any) {
              setIsDoubleOptInRequired(!value);
            }
          }}
          disabled={savingAttribute === 'double_opt_required'}
        />
      </FormRow>
      {settings?.opt_in_email_editor && isDoubleOptInRequired && (
        <Input
          leadingText="https://"
          labelText="Opt in Redirect URL"
          helperText="Set the URL you want a subscriber to be redirected to after confirming their subscription (if not set, they will be redirected to your beehiiv homepage by default)"
          placeholder="johnsnewsletter.beehiiv.com"
          name="opt_in_redirect_url"
          defaultValue={stripUrlProtocol(publication.opt_in_redirect_url)}
          onBlur={(e) => {
            const newValue = ensureUrlProtocol(e.target.value);

            if (!newValue || validateUrl(newValue)) {
              handleChangeInAttribute('opt_in_redirect_url')(newValue);
            } else {
              toast.error('Please enter a valid URL');
            }
          }}
          disabled={savingAttribute === 'opt_in_redirect_url'}
        />
      )}
      <FormRow
        label="Smart Nudge"
        helperText="Enable to send a reminder email after 48 hours if a subscriber has not confirmed their email address. "
      >
        <Switch
          variant="primary"
          checked={shouldAutoResendOptIn}
          name="auto_resend_opt_in"
          onChange={async (_name: string, value: boolean) => {
            try {
              setShouldAutoResendOptIn(value);
              await handleChangeInAttribute('auto_resend_opt_in')(value);
            } catch (error: any) {
              setShouldAutoResendOptIn(!value);
            }
          }}
          disabled={savingAttribute === 'auto_resend_opt_in'}
        />
      </FormRow>
    </BodyContainer>
  );
};

export default PresetForm;
