import { useCallback } from 'react';
import { StackSimple } from '@phosphor-icons/react';

import { PopoverHelper, PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';

type Props = AttributeSettingProps & {
  property: string;
  title: string;
};

// https://tailwindcss.com/docs/box-shadow
const SHADOW_OPTIONS: Record<string, string> = Object.freeze({
  none: '0 0 #0000',
  sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
  md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
  '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
});

const SHADOW_OPTIONS_ARRAY = Object.entries(SHADOW_OPTIONS).map(([key, value]) => ({
  label: key,
  value,
}));

const ShadowSettings = ({ editor, activeNodeResult, property, title = 'Shadow' }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const textValue =
    SHADOW_OPTIONS_ARRAY.find((option) => option.value === activeNodeAttributes?.[property])?.label || 'None';

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property]
  );

  return (
    <PopoverHelper title={title} text={textValue} popoverTitle={title} Icon={StackSimple}>
      <div className="flex flex-col gap-2">
        {SHADOW_OPTIONS_ARRAY.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={false}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};

export default ShadowSettings;
