'use client';

import * as React from 'react';
import { Check, Minus } from '@phosphor-icons/react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '../../_utils/cn';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {}
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-4 w-4 shrink-0 rounded border border-wb-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
      'border-wb-primary data-[state=checked]:bg-wb-accent data-[state=checked]:border-none data-[state=checked]:text-wb-on-accent',
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
      {props.checked === 'indeterminate' && <Minus size={12} weight="bold" className="h-3 w-3" />}
      {props.checked === true && <Check size={12} weight="bold" className="h-3 w-3 text-white" />}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
