/* eslint-disable react-hooks/rules-of-hooks */

import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

const useResubscribeAction = ({ subscriberId, status }: { subscriberId: string; status?: string }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isResubscribing, setIsResubscribing] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const resubscribeMutation = useMutation(
    () => {
      setIsResubscribing(true);
      return api
        .patch(`/subscriptions/${subscriberId}/resubscribe`, {
          publication_id: currentPublicationId,
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['subscriber', subscriberId]);
        toast.success('Subscriber successfully resubscribed!');
      },
      onError: () => {
        toast.error('Something went wrong');
      },
      onSettled: () => {
        setIsResubscribing(false);
      },
    }
  );

  const onResubscribe = () => {
    setIsResubscribing(true);
    resubscribeMutation.mutate();
  };

  if (status !== 'inactive') {
    return [];
  }

  return [
    {
      label: 'Resubscribe',
      helperText: 'Resubscribe this subscriber',
      onClick: () => setConfirmOpen(true),
      children: (
        <ActionModal
          isOpen={confirmOpen}
          onProceed={onResubscribe}
          onClose={() => setConfirmOpen(false)}
          resourceId={subscriberId}
          isWorking={isResubscribing}
          headerText="Resubscribe Subscriber"
          actionText="Resubscribe"
        >
          Warning — please ensure this subscriber has granted you permission to resubscribe, otherwise your newsletter
          inbox rate will likely be penalized. Do you wish to continue?
        </ActionModal>
      ),
    },
  ];
};

export default useResubscribeAction;
