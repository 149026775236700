import { FC } from 'react'; // Hooks
import { BackwardIcon as BackwardIconSolid } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { REWIND_2024_MODAL_KEY } from '@/constants';
import { useRewindContext } from '@/pages/Rewind2024/context';
import { Button } from '@/ui/Button';

import { SvgLogoWithName } from '../../../SvgLogoWithName';
import PublicationDropdown from '../DesktopNavbar/PublicationDropdown';

import Menu from './Menu';
import UserInfo from './UserInfo';

interface NavbarProps {
  className?: string;
}

const Navbar: FC<NavbarProps> = ({ className }) => {
  const { setRewindModalOpen, showRewind2024Button } = useRewindContext();

  const openRewindModal = async () => {
    await window.localStorage.removeItem(REWIND_2024_MODAL_KEY);
    setRewindModalOpen(true);
  };

  return (
    <nav className={cx('border-b mb-6 z-10 h-screen', className)}>
      <div className="flex flex-col h-full divide-y divide-gray-100">
        <div className="w-full flex flex-col flex-1 overflow-auto">
          <div className="w-full p-2">
            <PublicationDropdown largeNavOpen />
          </div>
          <div className="w-full flex-1 pb-6">
            <Menu />
          </div>

          <div>
            {showRewind2024Button && (
              <div className="w-full justify-center flex p-4">
                <Button type="button" variant="rewind" Icon={BackwardIconSolid} onClick={openRewindModal}>
                  Rewind 2024
                </Button>
              </div>
            )}
            <div className="w-full justify-center flex p-4">
              <SvgLogoWithName className="h-5 text-gray-700" />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col px-4 w-full py-4">
                <UserInfo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
