import { useCallback, useEffect, useMemo } from 'react';
import { TCollabThread } from '@hocuspocus/provider';
import Bold from '@tiptap/extension-bold';
import Document from '@tiptap/extension-document';
import Italic from '@tiptap/extension-italic';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { JSONContent, useEditor } from '@tiptap/react';

import { CommentEditor } from '@/components/CommentEditor';
import { suggestions } from '@/components/CommentEditor/suggestions';
import { useCurrentUser } from '@/context/current-user-context';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';
import { cn } from '@/utils/cn';

import { Comment } from './Comment';

import './ThreadCard.css';

const CommentDocument = Document.extend({
  content: 'paragraph+',
});

export type ThreadCardProps = {
  thread: TCollabThread;
  onClick: (id: string) => void;
  onMouseEnter: (id: string) => void;
  onMouseLeave: (id: string) => void;
  active?: boolean;
  referenceText?: string;
  inFloatingList?: boolean;
  inPopoverList?: boolean;
  inSidebar?: boolean;
  refCallback?: (ref: HTMLDivElement | null) => void;
};

export const ThreadCard = ({
  thread,
  onClick,
  onMouseEnter,
  onMouseLeave,
  active,
  referenceText,
  inFloatingList = false,
  inPopoverList = false,
  inSidebar = false,
  refCallback,
}: ThreadCardProps) => {
  const { editor } = useEditorContext();

  const { currentUser } = useCurrentUser();

  const { id, comments } = useMemo(() => thread || {}, [thread]);

  const handleMouseEnter = useCallback(() => {
    onMouseEnter(id);
  }, [onMouseEnter, id]);

  const handleMouseLeave = useCallback(() => {
    onMouseLeave(id);
  }, [onMouseLeave, id]);

  const previewEditor = useEditor({
    content: comments?.[0]?.content || '',
    extensions: [
      CommentDocument,
      Text,
      Paragraph,
      Bold,
      Italic,
      Mention.configure({
        renderText({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
        },
        HTMLAttributes: {
          class: 'mention-suggestion',
        },
        suggestion: suggestions(() => document.body),
      }),
    ],
    editable: false,
    editorProps: {
      attributes: {
        class: 'comment-preview',
      },
    },
  });

  useEffect(() => {
    previewEditor?.commands.setContent(comments?.[0]?.content || '');
  }, [comments, previewEditor]);

  const handleClick = useCallback(() => {
    if (!active) {
      onClick(id);
    }
  }, [id, onClick, active]);

  const handleCommentDelete = useCallback(
    (commentId: string) => {
      editor?.commands.removeComment({ threadId: id, id: commentId });
    },
    [id, editor]
  );

  const handleThreadDelete = useCallback(() => {
    editor?.commands.removeThread({ id, deleteThread: true });
  }, [id, editor]);

  const handleResolveThread = useCallback(() => {
    if (!editor || !thread) return;

    if (thread.resolvedAt) {
      editor.commands.unresolveCollabThread({ id });
    } else {
      editor.commands.resolveCollabThread({ id, resolvedBy: currentUser?.id });
    }
  }, [id, editor, thread, currentUser?.id]);

  const addComment = useCallback(
    (content: JSONContent) => {
      if (!editor || !thread || !currentUser) return;

      editor.commands.createComment({ threadId: id, content, data: { authorId: currentUser.id } });
    },
    [id, currentUser, editor, thread]
  );

  const onSubmitComment = useCallback(({ json }: { json: JSONContent }) => addComment(json), [addComment]);

  if (!comments || !id) return null;

  return (
    <div
      data-thread-id={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn(
        'p-3 text-left bg-white transition flex flex-col gap-4 w-full scroll-m-8',
        { 'shadow border-transparent': active },
        { 'bg-pink-50': active && inFloatingList },
        { '-translate-x-6': active && inFloatingList && !inSidebar },
        { 'hover:-translate-x-2': !active && !inSidebar && inFloatingList },
        { 'border-b border-surface-100 p-6': inSidebar },
        { 'rounded-lg border border-surface-100': !inSidebar },
        { 'border-transparent shadow-none p-0': inPopoverList }
      )}
      ref={(r) => refCallback?.(r)}
      {...(active ? { onClick: () => null } : { onClick: handleClick })}
    >
      <div className={cn('flex flex-col gap-4 w-full')}>
        {comments?.map((comment, index) => {
          const isFirst = index === 0;
          const isLast = index === comments.length - 1;

          return (
            <Comment
              key={comment.id}
              comment={comment}
              isFirst={isFirst}
              isLast={isLast}
              isResolved={!!thread.resolvedAt}
              referenceText={isFirst ? referenceText : undefined}
              onDelete={handleCommentDelete}
              onDeleteThread={handleThreadDelete}
              onResolveThread={handleResolveThread}
            />
          );
        })}
      </div>

      <div
        className={cn(
          'bg-white w-full rounded-md border border-gray-300 hover:border-gray-500 shadow-sm focus-within:border-primary-500 hover:focus-within:border-primary-500',
          { hidden: !active }
        )}
      >
        <CommentEditor onSubmit={onSubmitComment} />
      </div>
    </div>
  );
};
