import pluralize from '@/utils/pluralize';

const formatDuration = (seconds?: number) => {
  if (!seconds) {
    return '-';
  }

  // More than a year
  if (seconds / 31_104_000 > 1) {
    return pluralize('year', Math.round(seconds / 31_104_000));
  }

  // More than a month
  if (seconds / 2_592_000 > 1) {
    return pluralize('month', Math.round(seconds / 2_592_000));
  }

  // More than a day
  if (seconds / 86400 > 1) {
    return pluralize('day', Math.round(seconds / 86400));
  }

  // More than a hour
  if (seconds / 3600 > 1) {
    return pluralize('hour', Math.round(seconds / 3600));
  }

  return pluralize('minute', Math.round(seconds / 60));
};

export default formatDuration;
