import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface CreatedForm {
  id: string;
  title: string;
  description: string;
  status: string;
  publication_id: string;
  created_at: string;
  updated_at: string;
  created_by_user_id: string;
  updated_by_user_id: string;
}

interface ApiResponse {
  form: CreatedForm;
}

const useCreateForm = ({ onSuccess }: { onSuccess: (result: ApiResponse) => void }) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, { title?: string; description?: string }>(
    ({ title, description }) =>
      api
        .post('/forms', {
          publication_id: currentPublicationId,
          form: {
            title: title || '',
            description: description || '',
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useCreateForm;
