/**
 * Ensure that a URL has a protocol.
 *
 * @example ensureUrlProtocol('foo.com') => 'https://foo.com'
 * @example ensureUrlProtocol('https://bar.com') => 'https://bar.com'
 * @param url The URL to ensure protocol for
 * @param prefferedProtocol Protocol to prepend (defaults to 'https')
 * @returns {string}
 */
const ensureUrlProtocol = (url: string, prefferedProtocol: string = 'https'): string => {
  if (!url) return url;

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `${prefferedProtocol}://${url}`;
  }

  return url;
};

export default ensureUrlProtocol;
