import LoadingBox from '@/components/LoadingBox';
import { useEmailMessageStats } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import ClicksTable from '../../../EmailMessage/ClicksTable';
import MetricsTable from '../../../EmailMessage/MetricsTable';

const SendAnalytics = ({ emailMessageId }: { emailMessageId: string }) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data, isLoading, isError } = useEmailMessageStats(currentPublicationId, emailMessageId);

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <div className="px-8 py-12">
        <MetricsTable
          totalSent={data?.total_sent || 0}
          totalUniqueOpened={data?.total_unique_opened || 0}
          totalClicked={data?.total_clicked || 0}
          totalUniqueClicked={data?.total_unique_clicked || 0}
          openRate={data?.open_rate || 0}
          clickRate={data?.click_rate || 0}
          totalSpamReported={data?.total_spam_reported || 0}
          spamRate={data?.spam_rate || 0}
        />
        <div className="max-h-80 overflow-y-auto mt-16">
          <ClicksTable publicationId={currentPublicationId} emailMessageId={emailMessageId} />
        </div>
      </div>
    </LoadingBox>
  );
};

export default SendAnalytics;
