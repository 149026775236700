import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { useCurrentUser } from '@/context/current-user-context';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import Loading from '@/pages/Loading';
import { ApiResponse } from '@/routes/monetize/subscriptions/_hooks/useTopLevelMetrics';
import api from '@/services/swarm';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

export default function AuthMailchimpCallback() {
  const currentPublicationId = useCurrentPublicationId();

  const [params] = useQueryParams<{ code: QueryParamConfig<string>; state: QueryParamConfig<string> }>({
    code: withDefault(StringParam, ''),
    state: withDefault(StringParam, ''),
  });

  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;
  const redirectUrl = hasSettingsV2 ? appendSettingsPublicationId('/settings/publication/content_import/new', currentPublicationId) : '/settings/integrations/imports';

  const { isSuccess } = useQuery({
    queryFn: (): Promise<ApiResponse> =>
      api.post('/oauth2/callbacks/mailchimp', {
        code: params.code,
        state: params.state,
        publication_id: currentPublicationId,
      }),
    retry: false,
    onSuccess: () => {
      navigate(redirectUrl);
      toast.success('Mailchimp connected');
    },
    onError: () => {
      navigate(redirectUrl);
      toast.error('Mailchimp connection failed');
    },
  });

  if (!isSuccess) return <Loading text="Connecting to Mailchimp…" />;

  return <Loading text="Done." />;
}
