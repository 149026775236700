import { useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../UI/DropdownMenu';
import { Text } from '../../../UI/Text';

type Options = {
  label: string;
  onSelect: () => void;
};

interface Props {
  title: string;
  defaultValue: string;
  options: Options[];
}

export const AttributeDropdown = ({ title, defaultValue, options }: Props) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 p-2">
              <div className="flex items-center gap-1">
                <Text size="2xs" weight="medium">
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </Text>
              </div>
              <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer">
            {options.map((option) => (
              <DropdownMenuItem
                key={option.label}
                onSelect={() => {
                  setValue(option.label);
                  option.onSelect();
                }}
              >
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
