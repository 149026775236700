import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { Typography, TypographyStack } from '@/components/Typography';
import { useStripeApi } from '@/hooks/useBilling';
import { BillingActions } from '@/interfaces/billing';
import { PlanPrice } from '@/interfaces/plan_price';
import { Button } from '@/ui/Button';
import { capitalize } from '@/utils';

interface Props {
  organizationId: string;
  publicationId: string;
  currentPlanPrice: PlanPrice;
  upcomingPlanPrice: PlanPrice;
}

const CancelDowngradeModal: React.FC<Props> = ({
  organizationId,
  publicationId,
  currentPlanPrice,
  upcomingPlanPrice,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const cancelDowngrade = useStripeApi({
    organizationId,
    publicationId,
    action: BillingActions.CANCEL_DOWNGRADE,
  });

  // "monthly" or "yearly"
  const intervalText = (interval: string) => `${interval}ly`;

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onProceed={() => cancelDowngrade.mutate({})}
        resourceId={organizationId}
        isWorking={cancelDowngrade.isLoading}
        headerText="Cancel your downgrade"
        actionText="Proceed"
        buttonType="primary"
      >
        <TypographyStack gap="4">
          <Typography colorWeight="700">
            Click proceed to cancel your downgrade to{' '}
            <b>
              {capitalize(intervalText(upcomingPlanPrice.interval))} {capitalize(upcomingPlanPrice.plan_name)} @{' '}
              {upcomingPlanPrice.max_subscriptions.toLocaleString()} Subscriptions
            </b>
            . You will resume being billed for{' '}
            <b>
              {capitalize(intervalText(currentPlanPrice.interval))} {capitalize(currentPlanPrice.plan_name)} @{' '}
              {currentPlanPrice.max_subscriptions.toLocaleString()} Subscriptions
            </b>{' '}
            at the end of your current billing period.
          </Typography>
        </TypographyStack>
      </ActionModal>
      <Button size="xs" type="button" onClick={() => setIsOpen(true)} variant="danger">
        Cancel Downgrade
      </Button>
    </>
  );
};

export default CancelDowngradeModal;
