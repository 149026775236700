import { useQuery } from 'react-query';

import useCurrentTimeZone from '@/hooks/useCurrentTimeZone';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { TimePeriod } from '../../../interfaces/time_period';
import api from '../../../services/swarm';

interface ApiResponse {
  metrics: { name: string; referrals: number; previous_referrals: number }[];
  period: TimePeriod;
  time_zone: string;
  total: number;
}

interface Props {
  period: TimePeriod;
  boost_agreement_id?: string;
}

const useGrowthPerPeriod = ({ period, boost_agreement_id }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const currentTimeZone = useCurrentTimeZone();

  const fetchReferrals = (): Promise<ApiResponse> =>
    api
      .get(`/boosts/monetize/referrals_per_period/${period}`, {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
          boost_agreement_id,
        },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, 'boosts', 'monetize', 'referrals_per_period', boost_agreement_id, period, currentTimeZone],
    fetchReferrals,
    {
      keepPreviousData: true,
    }
  );
};

export default useGrowthPerPeriod;
