import React from 'react';

import { Input } from '../../../../../../components/Form';

interface Props {
  url: string;
  onUrlChange: (str: string) => void;
  apiKey: string;
  onApiKeyChange: (str: string) => void;
}

const Ghost: React.FunctionComponent<Props> = ({ url, onUrlChange, apiKey, onApiKeyChange }: Props) => (
  <div className="space-y-6">
    <p className="mt-2 mb-4 text-sm text-gray-500">
      Input your Ghost Content API key and API URL below. This information can be found in Ghost by going to{' '}
      <b>Settings</b> &gt; <b>Integrations</b> and selecting or creating a custom integration.
    </p>
    <Input name="apiKey" labelText="Content API key" value={apiKey} onChange={(e) => onApiKeyChange(e.target.value)} />

    <Input
      name="url"
      labelText="API URL"
      value={url}
      placeholderText="https://demo.ghost.io/"
      onChange={(e) => onUrlChange(e.target.value)}
    />
  </div>
);

export default Ghost;
