import { Typography } from '@/components/Typography';

const AttributeRow = ({ label, value }: { label: string; value: JSX.Element }) => {
  return (
    <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-6">
      <Typography token="font-normal/text/sm" colorWeight="500" className="w-full sm:w-32">
        {label}
      </Typography>
      {value}
    </div>
  );
};

export default AttributeRow;
