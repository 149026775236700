import { TimePeriod } from '@/interfaces/time_period';

const getNormalizedTimePeriod = (timePeriod: TimePeriod) => {
  if (timePeriod === TimePeriod.ALL_TIME) {
    return '';
  }

  return `(${timePeriod.split('_').join(' ')}) `;
};

export default getNormalizedTimePeriod;
