/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useMemo, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import debounce from 'lodash.debounce';

import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { FieldSet, Input } from '../../../components/ui/Input';
import { Panel } from '../../../components/ui/Panel';
import { Tooltip } from '../../../components/ui/Tooltip';
import { IAudioAttrs } from '../types';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const AudioDataPanel = ({
  parentRef,
  attrs,
  updateAttributes,
}: {
  parentRef: React.RefObject<HTMLElement>;
  updateAttributes: (attrs: Partial<IAudioAttrs>) => void;
  attrs: IAudioAttrs;
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const hasChanges = !!attrs.title;

  useEffect(() => {
    if (isPanelOpen) {
      const outsideClickHandler = (e: Event) => {
        if (
          !buttonRef.current?.contains(e.target as HTMLElement) &&
          !panelRef.current?.contains(e.target as HTMLElement)
        ) {
          setIsPanelOpen(false);
        }
      };

      document.addEventListener('click', outsideClickHandler);

      return () => {
        document.removeEventListener('click', outsideClickHandler);
      };
    }

    return () => {};
  }, [isPanelOpen]);

  const [title, setTitle] = useState(attrs.title);

  const handleTitleChange = useMemo(() => {
    return debounce((updatedTitle: string) => updateAttributes({ title: updatedTitle }), 500);
  }, [updateAttributes]);

  useEffect(() => {
    handleTitleChange(title);
  }, [title, handleTitleChange]);

  return (
    <Tippy
      placement="top-start"
      reference={parentRef.current}
      offset={[0, 4]}
      render={(tippyRenderAttrs) =>
        isPanelOpen && (
          <Panel ref={panelRef} tabIndex={-1} {...tippyRenderAttrs}>
            <FieldSet>
              <label htmlFor="file-title" className="text-xs font-medium leading-5">
                Title
                <Input
                  id="file-title"
                  value={title}
                  onChange={(e) => setTitle(e.currentTarget.value)}
                  placeholder="Title …"
                />
              </label>
            </FieldSet>
          </Panel>
        )
      }
      interactive
      visible={isPanelOpen}
      onHide={() => setIsPanelOpen(false)}
    >
      <div>
        <Tooltip title="Edit title">
          <Button
            ref={buttonRef}
            {...buttonProps}
            $active={hasChanges || isPanelOpen}
            $muted={isPanelOpen && !hasChanges}
            $leftSlot={<Icon name="Edit" />}
            onClick={() => setIsPanelOpen(!isPanelOpen)}
          />
        </Tooltip>
      </div>
    </Tippy>
  );
};

export default AudioDataPanel;
