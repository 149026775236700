import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

interface BackLinkProps {
  to: string;
  linkText: string;
}

const BackLink = ({ to, linkText }: BackLinkProps) => {
  return (
    <Link className="text-sm text-gray-500 hover:text-primary-500" to={to}>
      <div className="flex items-center">
        <ArrowLeftIcon className="w-4 h-4 mr-2" />
        <span>{linkText}</span>
      </div>
    </Link>
  );
};

export default BackLink;
