import { ReactChild, useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import cx from 'classnames';

interface Props {
  listItems: any;
  children: (list: any, onRemoveItem: (indexToRemove: any) => any) => ReactChild;
  onItemRemoved?: (newList: any[] | string[]) => void;
  onItemsOrdered?: (newList: any[] | string[]) => void;
  onItemsSorted?: (newList: any[] | string[]) => void;
  onDragEndEvent?: (event: DragEndEvent) => void;
  itemToAppend?: any;
  onItemAppended?: (newList: any[] | string[]) => void;
  className?: string;
}

const SortableList = ({
  listItems,
  onItemRemoved,
  onItemsOrdered,
  onItemsSorted,
  children,
  itemToAppend,
  onItemAppended,
  onDragEndEvent,
  className,
}: Props) => {
  const [list, setList] = useState<any>(listItems);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (itemToAppend) {
      const newList = [...list, itemToAppend];
      setList(newList);

      if (onItemAppended) {
        // This gives you a callback to access in parent to remove itemToAppend from state
        // This prevents potential recursive loops of trying to add items
        onItemAppended(newList);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToAppend, onItemAppended]);

  useEffect(() => {
    if (!itemToAppend) {
      setList(listItems);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItems]);

  const onDragEnd = (event: DragEndEvent) => {
    // A way to override the default behavior of onDragEnd
    if (onDragEndEvent) {
      return onDragEndEvent(event);
    }

    const { active, over } = event;

    if (active.id !== over?.id) {
      const activePostitionId = list.find((position: any) => position.id === active.id);
      const overPostitionId = list.find((position: any) => position.id === over?.id);
      const oldIndex = list.indexOf(activePostitionId);
      const newIndex = list.indexOf(overPostitionId);
      const newList = arrayMove(list, oldIndex, newIndex);
      setList(newList);

      if (onItemsOrdered) {
        onItemsOrdered(newList.map((item: any) => item.id));
      }

      if (onItemsSorted) {
        onItemsSorted(newList);
      }
    }

    return null;
  };

  const onRemoveItem = (indexToRemove: any) => {
    const newList = list.filter((item: any, index: any) => index !== indexToRemove);
    setList(newList);

    if (onItemRemoved) {
      onItemRemoved(newList.map((item: any) => item.id));
    }
  };

  return (
    <div className="relative curser-pointer">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
        <SortableContext items={list} strategy={verticalListSortingStrategy}>
          <div className={cx('flex flex-col space-y-1', className)}>{children(list, onRemoveItem)}</div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default SortableList;
