import { Grid } from '@tremor/react';

import useFunds from '@/hooks/boosts/grow/useFunds';
import useSendingMetrics from '@/hooks/boosts/grow/useSendingMetrics';
import useTopLineMetrics from '@/hooks/boosts/grow/useTopLineMetrics';
import { MetricCard } from '@/ui/MetricCard';

import AddFundsButton from '../../Shared/AddFundsButton';

const TopLineMetrics = () => {
  const { availableBalanceFormatted, escrowBalanceFormatted } = useFunds();
  const { data: topLineMetricsData } = useTopLineMetrics({});
  const { data: sendingMetricsData } = useSendingMetrics({});

  const totalSubscribers = topLineMetricsData?.total_subscribers.toLocaleString() || 0;
  const verifiedSubscribers = topLineMetricsData?.total_accepted_subscribers.toLocaleString() || 0;
  const pendingSubscribers = topLineMetricsData?.total_pending_subscribers.toLocaleString() || 0;

  const openRateValue = sendingMetricsData?.open_rate ? `${sendingMetricsData.open_rate}%` : '-';
  const clickThroughRateValue = sendingMetricsData?.click_through_rate
    ? `${sendingMetricsData.click_through_rate}%`
    : '-';
  const unsubscribeRateValue = sendingMetricsData?.unsubscribe_rate ? `${sendingMetricsData.unsubscribe_rate}%` : '-';
  const pendingSpend = topLineMetricsData?.total_pending_payments || '-';
  const totalSpend = topLineMetricsData?.total_payments || '-';

  return (
    <Grid numCols={1} numColsSm={4} className="gap-4">
      <MetricCard
        variant="primary"
        label="Verified Subscribers"
        tooltip="Verified subscribers delivered via Boosts across all offers. Pending subscribers are currently in the verification process. Verified subscribers have successfully completed the process."
        value={verifiedSubscribers}
        complements={[
          { label: 'Total subscribers', value: totalSubscribers },
          { label: 'Pending subscribers', value: pendingSubscribers },
        ]}
      />
      <MetricCard
        variant="primary"
        label="Open Rate"
        tooltip="Overall stats across all offers and partners. Based on active, verified subscribers only."
        value={openRateValue}
        complements={[
          { label: 'Click through rate', value: clickThroughRateValue },
          { label: 'Unsubscribe rate', value: unsubscribeRateValue },
        ]}
      />
      <MetricCard
        variant="primary"
        label="Total Spend"
        tooltip="The total amount spent on verified subscribers across all offers. Pending spend applies to subscribers in the verification process. Pending funds are moved to “Total Spend” for verified subscribers and returned to your wallet for unverified subscribers."
        value={totalSpend}
        complements={[{ label: 'Pending spend', value: pendingSpend }]}
      />
      <MetricCard
        variant="secondary"
        label="My available balance"
        tooltip="Funds available for Boosts. All Boosts will pause if your available balance is below 10x your Live Offer's CPA. Escrow balance refers to funds set aside for approved Email Boosts."
        value={availableBalanceFormatted}
        complements={[{ label: 'Escrow balance', value: escrowBalanceFormatted }]}
        cta={<AddFundsButton />}
      />
    </Grid>
  );
};

export default TopLineMetrics;
