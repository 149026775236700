import { Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';

type KeyDownHandlerOptions = {
  editor: Editor;
};

export function keyDownHandler(options: KeyDownHandlerOptions): Plugin {
  return new Plugin({
    key: new PluginKey('handleKeyDownLink'),
    props: {
      handleKeyDown: (view: EditorView, event: KeyboardEvent) => {
        const { editor } = options;
        const { selection } = editor.state;

        if (event.key === 'Escape' && selection.empty !== true) {
          editor.commands.focus(selection.to, { scrollIntoView: false });
        }

        return false;
      },
    },
  });
}
