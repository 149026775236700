import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  email?: string;
}

const useRerequestConfirmationEmail = () =>
  useMutation(({ email }: Props) =>
    api
      .post(`/signups/rerequest_confirmation_email`, {
        email,
      })
      .then((res) => res.data)
  );

export default useRerequestConfirmationEmail;
