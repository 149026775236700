import cx from 'classnames';

import { ScreenSize } from "./types";

const BrowserBar = ({screenSize}: {screenSize: ScreenSize}) => {
  return (
    <div className='flex flex-row justify-between px-6 py-4 bg-surface-100 border-b-surface-200 border-solid rounded-t-md'>
      <div className={cx('flex flex-row gap-3', screenSize === 'mobile' && 'hidden')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#F3605C"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#F8BE39"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="6" fill="#50C845"/>
        </svg>
      </div>
    </div>
  )
}

export default BrowserBar;
