interface Props {
  children: React.ReactNode;
  variant?: string;
}

const Divider = ({ children, variant }: Props) => {
  const borderClass = variant === 'light' ? 'border-gray-200' : 'border-gray-300';
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className={`w-full border-t ${borderClass}`} />
      </div>
      <div className="relative flex justify-center">
        <span className="px-2 bg-white text-sm text-gray-500">{children}</span>
      </div>
    </div>
  );
};

export default Divider;
