import { Fragment, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Dialog, Transition } from '@headlessui/react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostInvite } from '@/interfaces/boosts/monetize/boost_invite';
import { PublicationSearchWithBoostInvite } from '@/interfaces/publication_search';
import { SearchPublication } from '@/ui/SearchPublication';

import VerifyStripeIdentityForm from '../../../Shared/VerifyStripeIdentityForm';

import ActiveBoostInviteItem from './ActiveBoostInviteItem';
import ReviewBoostInviteForm from './ReviewBoostInviteForm';
import ReviewBoostInvitesHeader from './ReviewBoostInvitesHeader';

interface Props {
  boostInvites: BoostInvite[];
  isFetchingBoostInvites: boolean;
  isOpen: boolean;
  onClose: () => void;
  onAcceptInvitation: () => void;
  onDeclineInvitation: () => void;
  selectedBoostInviteId?: string | null;
}

const castToPublicationSearch = (invite: BoostInvite): PublicationSearchWithBoostInvite<BoostInvite> => {
  return {
    id: invite.boost_offer.boosted_publication.id,
    name: invite.boost_offer.boosted_publication.name,
    description: invite.boost_offer.boosted_publication.description || '',
    logo_url: invite.boost_offer.boosted_publication.logo_url,
    hostname: invite.boost_offer.boosted_publication.hostname,
    tags: invite.boost_offer.boosted_publication.tags.map((tag) => tag.name),
    boost_invite: invite,
  };
};

const ReviewBoostInvites = ({
  boostInvites,
  isFetchingBoostInvites,
  isOpen,
  onClose,
  onAcceptInvitation,
  onDeclineInvitation,
  selectedBoostInviteId,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const [query, setQuery] = useState('');

  const publications = boostInvites.map(castToPublicationSearch);

  const selectedInvite = boostInvites.find((invite) => invite.id === selectedBoostInviteId);

  const handleAcceptInvitation = () => {
    queryClient.invalidateQueries([currentPublicationId, 'boosts', 'monetize', 'boost_invites']);
    queryClient.invalidateQueries([currentPublicationId, 'boosts', 'monetize', 'boosted_publications']);
    onAcceptInvitation();
  };

  const handleDeclineInvitation = () => {
    queryClient.invalidateQueries([currentPublicationId, 'boosts', 'monetize', 'boost_invites']);
    onDeclineInvitation();
  };

  const handleRenderActiveOption = (publication: PublicationSearchWithBoostInvite<BoostInvite>) => {
    const boostInvite = boostInvites.find((invite) => invite.boost_offer.boosted_publication.id === publication.id);

    if (!boostInvite) return null;

    return (
      <ReviewBoostInviteForm
        key={`create-boost-invite-form-${publication.id}-${boostInvite?.id}`}
        boostInvite={boostInvite}
        onAccept={handleAcceptInvitation}
        onDecline={handleDeclineInvitation}
      />
    );
  };

  return (
    <Transition.Root show={isOpen} as={Fragment} appear>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <VerifyStripeIdentityForm
                className="bg-surface-200/90"
                titleText="Before you can accept invitations, we need to verify your identity"
              >
                <SearchPublication
                  header={<ReviewBoostInvitesHeader />}
                  showFilters={false}
                  publications={publications}
                  isFetchingPublications={isFetchingBoostInvites}
                  onSetQuery={setQuery}
                  query={query}
                  onRenderActiveOption={handleRenderActiveOption}
                  ActiveListItemComponent={ActiveBoostInviteItem}
                  activePublicationId={selectedInvite?.boost_offer.boosted_publication.id}
                />
              </VerifyStripeIdentityForm>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ReviewBoostInvites;
