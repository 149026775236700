import { TierChangesGraph, TierChangeStatsByResource } from '@/components/_domain/PremiumData';
import { useWelcomeEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';
import { TierChangeEventResourceType } from '@/interfaces/tier_change_event';

interface Props {
  publication: Publication;
}

const PremiumData = ({ publication }: Props) => {
  const { data: welcomeEmail } = useWelcomeEmail(publication.id);

  return welcomeEmail ? (
    <>
      <TierChangeStatsByResource
        publicationId={publication.id}
        resourceType={TierChangeEventResourceType.EMAIL_MESSAGE}
        resourceId={welcomeEmail.id}
      />
      <TierChangesGraph
        publicationId={publication.id}
        resourceType={TierChangeEventResourceType.EMAIL_MESSAGE}
        resourceId={welcomeEmail.id}
      />
    </>
  ) : null;
};

export default PremiumData;
