import { ChangeEvent, useRef } from 'react';
import Tippy from '@tippyjs/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip';
import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';

import { usePostContext } from '../../PostContext';
import VisibilitySettings from '../SubtitleActionsMenu/VisibilitySettings';

const Subtitle = () => {
  const { formData, onChange } = usePostContext();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const updateRef = useRef<number>();

  const updatePostSubTitle = (value: string | undefined) => {
    onChange({ web_subtitle: value || '' });
  };

  const handlePostSubTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    if (updateRef.current) {
      clearTimeout(updateRef.current);
    }

    updateRef.current = window.setTimeout(() => {
      updatePostSubTitle(value);
    }, 300);
  };

  return (
    <div className="relative flex">
      <div className="absolute top-0 left-0 w-full h-full -ml-10 z-0" />

      <Tippy
        offset={[0, 8]}
        placement="bottom-start"
        popperOptions={{
          modifiers: [{ name: 'flip' }],
        }}
        trigger="click"
        interactive
        content={
          <div className="absolute">
            <VisibilitySettings />
          </div>
        }
        zIndex={EDITOR_MENUS_Z_INDEX}
      >
        <div className="absolute -ml-10 mt-1 z-50">
          <div>
            <Tooltip title={formData?.display_subtitle_in_email ? 'Visibility' : 'Hidden in email'}>
              <Button
                $leftSlot={
                  formData?.display_subtitle_in_email ? (
                    <div className="relative w-4 h-4 mt-[.1rem]">
                      <img src="/images/eye-icon.svg" alt="Eye icon" />
                    </div>
                  ) : (
                    <div className="relative w-4 h-4">
                      <img src="/images/eye-off-icon.svg" alt="Eye icon" />
                    </div>
                  )
                }
                $variant="tertiary"
                $size="small"
                $isIconButton
              />
            </Tooltip>
          </div>
        </div>
      </Tippy>
      <div className="relative w-full">
        <textarea
          ref={textAreaRef}
          data-testid="post-subtitle"
          className="h-8 editor-subtitle-textarea selection:bg-pink-200 placeholder-gray-300 focus:border-transparent focus:ring-0 box-0 p-0 m-0 border-0 outline-0 focus:outline-none focus:border-none active:outline-none active:border-none mt-1 text-gray-600 resize-none w-full"
          onChange={handlePostSubTitleChange}
          name="post-subtitle"
          placeholder="Add a subtitle"
          defaultValue={formData?.web_subtitle}
        />
      </div>
    </div>
  );
};

export default Subtitle;
