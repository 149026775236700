/* eslint-disable no-empty */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { SetupMethod } from '@/hooks/useCustomDomains/types';
import useCheckExistingRecords from '@/hooks/useCustomDomains/useCheckExistingRecords';
import useCreateCustomDomainsCollection from '@/hooks/useCustomDomains/useCreateCustomDomainsCollection';
import useCustomDomainsCollection from '@/hooks/useCustomDomains/useCustomDomainsCollection';
import { CustomDomainTypes } from '@/interfaces/custom_domain';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';
import isCommonEmailProvider from '@/utils/isCommonEmailProvider';
import isValidHostname from '@/utils/isValidHostname';
import pluralize from '@/utils/pluralize';

import { FormErrors, StepType } from '../components/form/types';
import getRedirectDomainValue from '../getRedirectDomainValue';
import getUsernameAndDomainFromEmail from '../getUsernameAndDomainFromEmail';

interface Arguments {
  isWebOnly?: boolean;
  isEmailOnly?: boolean;
  publicationId: string;
  initialStep: StepType;
}

const useForm = ({ publicationId, isEmailOnly, isWebOnly, initialStep }: Arguments) => {
  const navigate = useNavigate();

  const { data: collectionData, isLoading: isLoadingCollection } = useCustomDomainsCollection(publicationId);
  const hasExistingWebDomain = Boolean(collectionData?.web_domain);
  const hasExistingWebRedirectDomain = Boolean(collectionData?.web_redirect_domain);

  const [currentStep, setCurrentStep] = useState<StepType>(initialStep);

  // Web
  const [shouldUseDefaultWebDomain, setShouldUseDefaultWebDomain] = useState(true);
  const [webDomain, setWebDomain] = useState<string | null>(null);
  const [redirectToWWW, setRedirectToWWW] = useState<boolean>(false);
  const [shouldRedirectToDomain, setShouldRedirectToDomain] = useState(false);
  const [redirectToDomain, setRedirectToDomain] = useState<string | null>(null);
  const [overwritingExistingWebDomain, setOverwritingExistingWebDomain] = useState<boolean>(false);

  // Email
  const [shouldUseCustomEmailDomain, setShouldUseCustomEmailDomain] = useState(true);
  const [emailDomain, setEmailDomain] = useState<string | null>(null);
  const [shouldUseWebDomainAsEmailDomain, setShouldUseWebDomainAsEmailDomain] = useState(false);
  const [shouldUseDefaultEmailDomain, setShouldUseDefaultEmailDomain] = useState(false);

  // Setup
  const [setupMethod, setSetupMethod] = useState<SetupMethod | undefined>();
  const [isEntriAvailable, setisEntriAvailable] = useState(false);
  const [isSetupMethodModalOpen, setIsSetupMethodModalOpen] = useState(false);
  const [dnsRecordsModalOpen, setDnsRecordsModalOpen] = useState(false);
  const [customDomainIds, setCustomDomainIds] = useState<string[]>([]);
  const [verifiableDomainTypes, setVerifiableDomainTypes] = useState<CustomDomainTypes[]>([]);

  // Form Errors
  const [errors, setErrors] = useState<FormErrors>({});

  const exitWizard = () => {
    navigate(appendSettingsPublicationId('/settings/publication/domain', publicationId));
  };

  const startManualVerification = (customDomainType?: CustomDomainTypes) => {
    const domainType = customDomainType || verifiableDomainTypes[0];

    navigate(`${appendSettingsPublicationId('/settings/publication/domain', publicationId)}&verify=${domainType}`);
  };

  const { mutateAsync: createCollection, isLoading: isCreating } = useCreateCustomDomainsCollection(publicationId, {
    onSuccess: (domainIds, domainTypes, entriCompatible) => {
      const domainsText = pluralize('domain', domainIds.length);
      toast.success(`Successfully set up ${domainsText}!`);

      if (domainTypes.length === 0) {
        return exitWizard();
      }

      // If not compatible with Entri, start the manual verification at the first verifiable domain type
      if (!entriCompatible) {
        return startManualVerification(domainTypes[0]);
      }

      // Open the setup modal with Entri as the default method
      setisEntriAvailable(true);
      setSetupMethod(SetupMethod.ENTRI);
      setCustomDomainIds(domainIds);
      setVerifiableDomainTypes(domainTypes);
      return setIsSetupMethodModalOpen(true);
    },
  });

  const { mutateAsync: checkWebDomainRecords } = useCheckExistingRecords(publicationId, webDomain as string, false);

  const { mutateAsync: checkRedirectDomainRecords } = useCheckExistingRecords(
    publicationId,
    redirectToDomain as string,
    false
  );

  const handleSubmit = () => {
    if (isWebOnly) {
      try {
        createCollection({
          web_domain: hasExistingWebDomain || shouldUseDefaultWebDomain ? null : (webDomain as string),
          web_redirect_domain: hasExistingWebRedirectDomain
            ? null
            : shouldRedirectToDomain
            ? (redirectToDomain as string)
            : redirectToWWW
            ? getRedirectDomainValue(webDomain as string)
            : null,
        });
      } catch {}
    } else if (isEmailOnly) {
      try {
        createCollection({
          email_domain:
            shouldUseCustomEmailDomain && emailDomain
              ? getUsernameAndDomainFromEmail(emailDomain).domain
              : shouldUseWebDomainAsEmailDomain
              ? (webDomain as string)
              : null,
          email_username:
            shouldUseCustomEmailDomain && emailDomain ? getUsernameAndDomainFromEmail(emailDomain).username : null,
        });
      } catch {}
    } else {
      try {
        createCollection({
          web_domain: shouldUseDefaultWebDomain ? null : (webDomain as string),
          web_redirect_domain: shouldRedirectToDomain
            ? (redirectToDomain as string)
            : redirectToWWW
            ? getRedirectDomainValue(webDomain as string)
            : null,
          email_domain:
            shouldUseCustomEmailDomain && emailDomain
              ? getUsernameAndDomainFromEmail(emailDomain).domain
              : shouldUseWebDomainAsEmailDomain
              ? (webDomain as string)
              : null,
          email_username:
            shouldUseCustomEmailDomain && emailDomain ? getUsernameAndDomainFromEmail(emailDomain).username : null,
        });
      } catch {}
    }
  };

  const validateStep = () => {
    const localErrors: FormErrors = {};
    switch (currentStep) {
      case StepType.WEB:
        localErrors.webDomain = !shouldUseDefaultWebDomain && (!webDomain || !isValidHostname(webDomain));
        localErrors.redirectToDomain = shouldRedirectToDomain
          ? !redirectToDomain || !isValidHostname(redirectToDomain)
          : false;
        break;
      case StepType.EMAIL:
        if (shouldUseCustomEmailDomain && emailDomain) {
          const { domain } = getUsernameAndDomainFromEmail(emailDomain);
          localErrors.emailDomain = !isValidHostname(domain) || isCommonEmailProvider(domain);

          if (isCommonEmailProvider(domain)) {
            toast.error(
              `Your desired email domain, ${domain}, is a common email provider domain and cannot be used as a custom domain.`
            );
          }
        }
        break;
      default:
    }

    if (Object.values(localErrors).some(Boolean)) {
      setErrors(localErrors);
      return false;
    }

    return true;
  };

  const handleCheckWebDomain = async () => {
    try {
      let isOverwriting = false;

      if (webDomain) {
        const { result } = await checkWebDomainRecords({
          publication_id: publicationId,
          domain: webDomain as string,
          dmarc_check: false,
        });

        if (result) {
          isOverwriting = true;
        }
      }

      if (redirectToDomain) {
        const { result } = await checkRedirectDomainRecords({
          publication_id: publicationId,
          domain: redirectToDomain as string,
          dmarc_check: false,
        });

        if (result) {
          isOverwriting = true;
        }
      }

      setOverwritingExistingWebDomain(isOverwriting);
    } catch (error) {
      setOverwritingExistingWebDomain(false);
    }
  };

  const handleClickNextStep = () => {
    setErrors({});

    if (!validateStep()) {
      return;
    }

    switch (currentStep) {
      case StepType.WEB:
        handleCheckWebDomain();

        if (isWebOnly) {
          setCurrentStep(StepType.REVIEW);
        } else {
          setCurrentStep(StepType.EMAIL);
        }
        break;
      case StepType.EMAIL:
        setCurrentStep(StepType.REVIEW);
        break;
      case StepType.REVIEW:
        handleSubmit();
        break;
      default:
    }
  };

  const handleClickEditWebConfiguration = () => {
    setCurrentStep(StepType.WEB);
  };

  const handleClickPreviousStep = () => {
    switch (currentStep) {
      case StepType.REVIEW:
        if (isWebOnly) {
          setCurrentStep(StepType.WEB);
        } else {
          setCurrentStep(StepType.EMAIL);
        }
        break;
      case StepType.EMAIL:
        setCurrentStep(StepType.WEB);
        break;
      default:
    }
  };

  const dnsRecordsModalStepType = (() => {
    if (isWebOnly) {
      return redirectToDomain ? CustomDomainTypes.REDIRECT : CustomDomainTypes.WEB;
    }

    if (isEmailOnly) {
      return CustomDomainTypes.EMAIL;
    }

    return webDomain ? CustomDomainTypes.WEB : redirectToDomain ? CustomDomainTypes.REDIRECT : CustomDomainTypes.EMAIL;
  })();

  useEffect(() => {
    setWebDomain(collectionData?.web_domain || null);
    setRedirectToDomain(collectionData?.web_redirect_domain || null);
  }, [collectionData]);

  return {
    isLoadingCollection,
    hasExistingWebDomain,
    shouldUseDefaultWebDomain,
    setShouldUseDefaultWebDomain,
    webDomain,
    setWebDomain,
    hasExistingWebRedirectDomain,
    redirectToWWW,
    setRedirectToWWW,
    shouldRedirectToDomain,
    setShouldRedirectToDomain,
    redirectToDomain,
    setRedirectToDomain,
    shouldUseCustomEmailDomain,
    setShouldUseCustomEmailDomain,
    emailDomain,
    setEmailDomain,
    shouldUseWebDomainAsEmailDomain,
    setShouldUseWebDomainAsEmailDomain,
    shouldUseDefaultEmailDomain,
    setShouldUseDefaultEmailDomain,
    setupMethod,
    setSetupMethod,
    isEntriAvailable,
    isSetupMethodModalOpen,
    setIsSetupMethodModalOpen,
    customDomainIds,
    dnsRecordsModalOpen,
    setDnsRecordsModalOpen,
    currentStep,
    errors,
    validateStep,
    handleClickNextStep,
    handleClickPreviousStep,
    handleSubmit,
    isCreating,
    dnsRecordsModalStepType,
    exitWizard,
    startManualVerification,
    overwritingExistingWebDomain,
    handleClickEditWebConfiguration,
  };
};

export default useForm;
