import { useInfiniteQuery } from 'react-query';

import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import api from '@/services/swarm';

interface ApiResponse {
  endpoints: WebhookEndpoint[];
  has_more: boolean;
  iterator: string;
}

export default function useSvixEndpoints(publicationId: string, enabled: boolean = true) {
  const fetchEndpoints = ({ pageParam = '' }) =>
    api
      .get('/webhooks/svix_endpoints', {
        params: {
          publication_id: publicationId,
          iterator: pageParam,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['publications', publicationId, 'webhooks', 'endpoints'],
    fetchEndpoints,
    {
      getNextPageParam: (lastPage) => {
        const { iterator, has_more: hasMore } = lastPage || {};

        if (hasMore) return iterator;

        return false;
      },
      keepPreviousData: true,
      enabled,
    }
  );
}
