import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';
import useSiteTemplate from '@/hooks/useSiteTemplates/useSiteTemplate';
import { DreamEditor } from '@/routes/website/_components/DreamEditor';
import { EditorHeader, EditorHeaderBreadcrumb } from '@/routes/website/_components/EditorHeader/EditorHeader';
import { FooterTemplatePreview } from '@/routes/website/footer/_components/FooterEditor/FooterTemplatePreview';
import { NavbarTemplatePreview } from '@/routes/website/navbar/_components/NavbarEditor/NavbarTemplatePreview';
import IframeWrapper from '@/routes/website/page/_components/Iframe';
import { useEditorFontHrefs } from '@/routes/website/page/_hooks/useEditorFontHrefs';

export const TemplatesEditor = ({ editorRect }: { editorRect: Partial<DOMRect> }) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { templateId } = useParams();
  const { data: template } = useSiteTemplate({ siteTemplateId: templateId as string });
  const { data: sitePackage } = useSitePackage({ sitePackageId: template?.site_package_id as string });
  const isPageLevel = template?.level === 'page';
  const isPartOfASitePackage = template?.site_package_id !== null;
  const showNavAndFooter = isPartOfASitePackage && isPageLevel;

  const { settings } = useSettings();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);

  const fontHrefs = useEditorFontHrefs();

  return (
    <>
      <EditorHeader type="template" name={template?.name} iconType="layout">
        {template?.site_package_id && (
          <EditorHeaderBreadcrumb text="Projects" linkTo="/website_builder_v2/projects" iconType="folder" />
        )}
        <EditorHeaderBreadcrumb
          text={template?.site_package_id ? `${sitePackage?.name}` : 'Templates'}
          iconType="folder"
          linkTo={
            template?.site_package_id
              ? `/website_builder_v2/projects/${template.site_package_id}`
              : '/website_builder_v2/templates'
          }
        />
      </EditorHeader>
      <IframeWrapper
        width={editorRect.width || 0}
        height={editorRect.height || 0}
        contentWidth={1024}
        ref={iframeRef}
        fontHrefs={fontHrefs}
      >
        {showNavAndFooter && <NavbarTemplatePreview portalRef={portalRef} iframeRef={iframeRef} />}
        <div className="overflow-y-scroll min-h-[100px] h-fit">
          <PublicationProvider id={currentPublicationId}>
            {settings && (
              <div style={{ backgroundColor: 'white' }}>
                <DreamEditor
                  publicationId={currentPublicationId}
                  settings={settings}
                  className="typedream content template"
                  isFullPageHeight={false}
                  type={isPageLevel ? 'page' : 'template'}
                />
              </div>
            )}
          </PublicationProvider>
        </div>
        {showNavAndFooter && <FooterTemplatePreview iframeRef={iframeRef} />}
        <div ref={portalRef} id="portal-ref" className="z-50" />
      </IframeWrapper>
    </>
  );
};
