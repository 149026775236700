import { MapPinIcon } from '@heroicons/react/20/solid';

import Tooltip from './Tooltip';
import { Typography } from './Typography';

interface Props {
  id: string;
  text: string;
}

export default function RegionalRestrictionsTooltip({ id, text }: Props) {
  return (
    <Tooltip id={id} text={text} showIcon={false}>
      <div className="flex gap-x-1 items-center w-fit">
        <MapPinIcon className="w-4 h-4 text-surface-300" />
        <Typography token="font-light/text/xs" colorWeight="500">
          Regional restrictions applied
        </Typography>
      </div>
    </Tooltip>
  );
}
