import { useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import cx from 'classnames';

import { EmptyConditionsBlock } from '@/components/ConditionsEditor';
import { colorByLogicalOperator, logicalOperatorOptions } from '@/components/ConditionsEditor/constants';
import Text from '@/components/Text';
import Tree from '@/components/Tree';
import SortableTreeItem from '@/components/Tree/SortableTreeItem';
import SortableTreeItems from '@/components/Tree/SortableTreeItems';
import { LogicalOperator } from '@/interfaces/condition';
import { Condition, Filter, Group, Refine } from '@/interfaces/segment';

import { isGroup } from '../modules/isGroup';

import ConditionBlock from './ConditionBlock';
import { conditionOptions } from './constants';
import GroupFooter from './GroupFooter';
import GroupHeader from './GroupHeader';
import SortableGroupBlock from './SortableGroupBlock';
import { NameTypes, SortableConditionBlockData } from './types';

interface Props {
  className?: string;
  mainLogicalOperator: LogicalOperator;
  blocks: Condition[] | Group[];
  onAddConditionBlock: (type: NameTypes, groupIndex?: number) => void;
  onAddGroupBlock: (type: LogicalOperator) => void;
  onDuplicateCondition: (blockIndex: number, groupIndex?: number) => void;
  onChangeMainLogicalOperator: (logicalOperator: LogicalOperator) => void;
  onSelectWhichName: (value: string, blockIndex: number, subIndex?: number) => void;
  onFilterChange: (filters: Filter, blockIndex: number, subIndex?: number) => void;
  onRefineChange: (refine: Refine, blockIndex: number, subIndex?: number) => void;
  onGroupLogicalOperatorChange: (value: LogicalOperator, blockIndex?: number) => void;
  onDeleteCondition: (blockIndex: number, subIndex?: number) => void;
  onDeleteRefine: (blockIndex: number, subIndex?: number) => void;
  onSelectConditionName: (logicalOperator: LogicalOperator) => void;
  onSelectConditionNameInGroup: (conditionOption: any) => void;
  onSortConditionBlocks: (
    activeBlockData?: SortableConditionBlockData,
    overBlockData?: SortableConditionBlockData
  ) => void;
}

const ConditionsEditor = ({
  className,
  mainLogicalOperator,
  blocks,
  onSortConditionBlocks,
  onAddConditionBlock,
  onAddGroupBlock,
  onDuplicateCondition,
  onChangeMainLogicalOperator,
  onSelectWhichName,
  onFilterChange,
  onRefineChange,
  onGroupLogicalOperatorChange,
  onDeleteCondition,
  onDeleteRefine,
  onSelectConditionName,
  onSelectConditionNameInGroup,
}: Props) => {
  const [activeItemData, setActiveItemData] = useState<SortableConditionBlockData | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleItemsOrdered = (
    activeBlockData?: SortableConditionBlockData,
    overBlockData?: SortableConditionBlockData
  ) => {
    onSortConditionBlocks(activeBlockData, overBlockData);
  };

  const onDragStart = (event: DragEndEvent) => {
    const { active } = event;

    if (active.data.current) {
      setActiveItemData(active.data.current as SortableConditionBlockData);
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      handleItemsOrdered(
        active.data.current as SortableConditionBlockData,
        over.data.current as SortableConditionBlockData
      );
    }
  };

  const renderActiveSortableTreeItem = () => {
    if (!activeItemData || activeItemData.index === undefined) {
      return null;
    }

    let block;
    if (activeItemData.groupIndex === undefined) {
      block = blocks[activeItemData.index];
    } else if ((blocks[activeItemData.groupIndex] as Group)?.conditions) {
      block = (blocks[activeItemData.groupIndex] as Group)?.conditions[activeItemData.index];
    }

    if (!block) {
      return null;
    }

    return (
      <SortableTreeItem
        isPlaceholder
        isDisabled
        uniqueIdentifier={block.uuid}
        data={{
          isInGroup: activeItemData.groupIndex !== undefined,
          index: activeItemData.index,
          groupIndex: activeItemData.groupIndex,
        }}
      >
        <ConditionBlock
          condition={block as Condition}
          index={activeItemData.index}
          handleSelectWhichName={onSelectWhichName}
          handleFilterChange={onFilterChange}
          handleRefineChange={onRefineChange}
          handleDuplicate={onDuplicateCondition}
          handleDelete={onDeleteCondition}
          handleDeleteRefine={onDeleteRefine}
        />
      </SortableTreeItem>
    );
  };

  return (
    <>
      <div className={cx('w-full hidden md:block', className)}>
        <Tree backgroundClassName={colorByLogicalOperator[mainLogicalOperator].tree}>
          <GroupHeader
            selectedLogicalOperator={mainLogicalOperator}
            onLogicalOperatorSelect={onChangeMainLogicalOperator}
          />
          {blocks.length === 0 && <EmptyConditionsBlock />}
          {blocks.length > 0 && (
            <DndContext
              sensors={sensors}
              collisionDetection={pointerWithin}
              onDragEnd={onDragEnd}
              onDragStart={onDragStart}
            >
              <SortableTreeItems
                uniqueIdentifier="sortable-tree-items-root"
                listItems={blocks.map(({ uuid }) => uuid)}
                isFirstItemAGroup={(blocks.length > 0 && blocks[0].type === 'group') || false}
                key="sortable-tree-item-root"
                data={{
                  isRootGroup: true,
                }}
              >
                {blocks.map((block: Condition | Group, index: number) => {
                  return isGroup(block) ? (
                    <SortableGroupBlock
                      key={`tree-${block.uuid}}`}
                      uniqueIdentifier={block.uuid}
                      isDisabled
                      block={block}
                      blockIndex={index}
                      handleGroupLogicalOperatorChange={onGroupLogicalOperatorChange}
                      handleAddConditionBlock={onAddConditionBlock}
                      handleSelectConditionNameInGroup={onSelectConditionNameInGroup}
                      handleDeleteCondition={onDeleteCondition}
                      data={{
                        isSubGroup: true,
                        groupIndex: index,
                      }}
                    >
                      {block.conditions.length === 0 && <EmptyConditionsBlock />}
                      {block.conditions.length > 0 && (
                        <SortableTreeItems
                          uniqueIdentifier={`sortable-tree-items-${block.uuid}`}
                          listItems={block.conditions.map(({ uuid }) => uuid)}
                        >
                          {block.conditions.map((condition, conditionIndex) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <SortableTreeItem
                              key={`sortable-tree-item-${condition.uuid}`}
                              uniqueIdentifier={condition.uuid}
                              data={{
                                isInGroup: true,
                                groupIndex: index,
                                index: conditionIndex,
                              }}
                            >
                              <ConditionBlock
                                condition={condition}
                                index={index}
                                subIndex={conditionIndex}
                                handleSelectWhichName={onSelectWhichName}
                                handleFilterChange={onFilterChange}
                                handleRefineChange={onRefineChange}
                                handleDuplicate={onDuplicateCondition}
                                handleDelete={onDeleteCondition}
                                handleDeleteRefine={onDeleteRefine}
                              />
                            </SortableTreeItem>
                          ))}
                        </SortableTreeItems>
                      )}
                    </SortableGroupBlock>
                  ) : (
                    <SortableTreeItem
                      uniqueIdentifier={block.uuid}
                      key={`sortable-tree-item-${block.uuid}`}
                      data={{
                        isInGroup: false,
                        index,
                        groupIndex: undefined,
                      }}
                    >
                      <ConditionBlock
                        condition={block as Condition}
                        index={index}
                        handleSelectWhichName={onSelectWhichName}
                        handleFilterChange={onFilterChange}
                        handleRefineChange={onRefineChange}
                        handleDuplicate={onDuplicateCondition}
                        handleDelete={onDeleteCondition}
                        handleDeleteRefine={onDeleteRefine}
                      />
                    </SortableTreeItem>
                  );
                })}
                <DragOverlay>{renderActiveSortableTreeItem()}</DragOverlay>
              </SortableTreeItems>
            </DndContext>
          )}
          <GroupFooter
            showDelete={false}
            selectedLogicalOperator={mainLogicalOperator}
            onAddGroup={onAddGroupBlock}
            onAddConditionType={onAddConditionBlock}
            onSelectConditionName={onSelectConditionName}
            conditionSelectOptions={conditionOptions}
            conditionGroupSelectOptions={logicalOperatorOptions}
          />
        </Tree>
      </div>
      <div className={cx('w-full bg-gray-100 p-4 block md:hidden text-center', className)}>
        <Text size="sm" className="p-4">
          Conditions cannot be modified on mobile
        </Text>
      </div>
    </>
  );
};

export default ConditionsEditor;
