import * as React from 'react';
import cx from 'classnames';

import type { BaseButtonProps } from '../Button';

type ButtonGroupProps = {
  variant?: BaseButtonProps['variant'];
  size?: BaseButtonProps['size'];
  disabled?: BaseButtonProps['disabled'];
  children?: React.ReactNode;
  className?: string;
};

export const ButtonGroupContext = React.createContext<ButtonGroupProps>({});

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  variant = 'primary',
  disabled = false,
  children,
  className,
  size = 'sm',
}) => {
  const context = React.useMemo(
    () => ({
      variant,
      size,
      disabled,
    }),
    [variant, disabled, size]
  );

  return (
    <div role="group" className={cx('relative z-0 inline-flex shadow-sm rounded-md', className)}>
      <ButtonGroupContext.Provider value={context}>{children}</ButtonGroupContext.Provider>
    </div>
  );
};
