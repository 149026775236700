import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { ATTRIBUTES_PANEL_ID } from '@/routes/website/_components/DreamEditor/constants';
import IframeWrapper from '@/routes/website/page/_components/Iframe';
import { useNavbarFontHrefs } from '@/routes/website/page/_hooks/useNavbarFontHrefs';

import { AttributesPanel } from './AttributesPanel/AttributesPanel';
import { NavbarEmptyState } from './NavbarComponents/NavbarEmptyState';
import { DragHandle } from './DragHandle';
import { useNavbarContext } from './NavbarContext';
import { SelectionBox } from './SelectionBox';
import { NavbarSerializer } from './serializer';
import { useIsMobile } from './utils';

function generateTextSkeleton(length: number) {
  return Array.from({ length }).map(() => (
    <div
      key={`skeleton-${Math.random().toString(36).substr(2, 9)}`}
      className="flex flex-col w-full p-12 justify-center items-center gap-8"
    >
      <div className="h-8 w-[30%] bg-gray-900/5 rounded-full" />
      <div className="h-4 w-[70%] bg-gray-900/5 rounded-full" />
      <div className="h-4 w-[50%] bg-gray-900/5 rounded-full" />
    </div>
  ));
}

const NavbarContent = ({ portalRef }: { portalRef: React.RefObject<HTMLDivElement> }) => {
  const { content } = useNavbarContext();
  if (!content || !('content' in content) || content.content?.length === 0) return <NavbarEmptyState />;
  return <NavbarSerializer node={content} portalRef={portalRef} />;
};

const NavbarContentPreview = ({
  iframeRef,
  portalRef,
}: {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  portalRef: React.RefObject<HTMLDivElement>;
}) => {
  const { content } = useNavbarContext();
  const isMobile = useIsMobile(iframeRef);
  if (!content || !('content' in content) || content.content?.length === 0) return <NavbarEmptyState />;
  return <NavbarSerializer node={content} portalRef={portalRef} screen={isMobile ? 'mobile' : 'desktop'} />;
};

export const NavbarEditor = ({
  iframeRef,
  editorRect,
  contentWidth = 1024,
  isPreview = false,
}: {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  editorRect: Partial<DOMRect>;
  contentWidth: number;
  isPreview?: boolean;
}) => {
  const portalRef = useRef<HTMLDivElement>(null);
  const attributesPanelEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    attributesPanelEl.current = document.getElementById(ATTRIBUTES_PANEL_ID) as HTMLDivElement;
  }, []);

  const fontHrefs = useNavbarFontHrefs();

  const { undo, redo } = useNavbarContext();

  useEffect(() => {
    const onUndoOrRedo = (e: KeyboardEvent) => {
      if (e.key === 'z' && e.metaKey) {
        e.preventDefault();
        if (e.shiftKey) {
          redo();
        } else {
          undo();
        }
      }
    };

    // listen to undo & redo events
    window.addEventListener('keydown', onUndoOrRedo);
    const iframeWindow = iframeRef.current?.contentWindow;
    iframeWindow?.addEventListener('keydown', onUndoOrRedo);

    return () => {
      window.removeEventListener('keydown', onUndoOrRedo);
      iframeWindow?.removeEventListener('keydown', onUndoOrRedo);
    };
  }, [undo, redo, iframeRef]);

  return (
    <>
      <IframeWrapper
        width={editorRect.width || 0}
        height={editorRect.height || 0}
        ref={iframeRef}
        fontHrefs={fontHrefs}
        contentWidth={contentWidth}
      >
        <div
          className="dream-navbar-editor grow relative overflow-y-auto min-h-0 h-screen max-h-[1000px] w-full flex flex-col justify-start items-center cursor-auto"
          id="scrollable-container"
          style={{
            backgroundColor: 'white',
            display: isPreview ? 'none' : 'flex',
          }}
        >
          <NavbarContent portalRef={portalRef} />
          <div className="flex flex-col w-full flex-1">{generateTextSkeleton(10)}</div>
          <div ref={portalRef} id="portal-ref" className="z-50" />
        </div>

        <div
          className="grow relative overflow-y-auto min-h-0 h-screen max-h-[1000px] w-full flex flex-col justify-start items-center cursor-auto"
          id="scrollable-container"
          style={{
            backgroundColor: 'white',
            display: isPreview ? 'flex' : 'none',
          }}
        >
          <NavbarContentPreview iframeRef={iframeRef} portalRef={portalRef} />
          <div className="flex flex-col w-full flex-1">{generateTextSkeleton(10)}</div>
        </div>
      </IframeWrapper>
      <SelectionBox />
      <DragHandle />

      <div>{attributesPanelEl.current && createPortal(<AttributesPanel />, attributesPanelEl.current)}</div>
    </>
  );
};
