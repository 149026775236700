import { Link, useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';

import IconButton from '@/components/IconHelpers/IconButton';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { ExternalEmbed } from '@/interfaces/external_embed';

interface Props {
  items: ExternalEmbed[];
  onDeselect?: (externalEmbedId: string) => void;
}

const SelectedExternalEmbeds = ({ items, onDeselect }: Props) => {
  const navigate = useNavigate();
  if (items.length === 0) {
    return null;
  }

  return (
    <ul className="min-w-64 bg-gray-50 px-4 py-2 rounded-md">
      {items.map((externalEmbed: ExternalEmbed) => {
        const showRedirectWarning = !!externalEmbed.success_redirect_url;

        return (
          <li
            key={`selected-external-embed-${externalEmbed.id}`}
            className="flex items-center gap-x-2 py-2 justify-between border-b border-b-gray-200 last:border-b-0 first:pt-0 last:pb-0"
          >
            <div className="flex gap-x-2 overflow-hidden items-center">
              {showRedirectWarning && (
                <Tooltip
                  text="Please turn off redirection to show the widget on your external site"
                  id={`tooltip-${externalEmbed.id}`}
                  Icon={ExclamationTriangleIcon}
                  iconClass="mt-1"
                />
              )}

              <Typography token="font-medium/text/sm">
                <Link to={`/settings/publication/subscribe_forms?id=${externalEmbed.id}`}>{externalEmbed.name}</Link>
              </Typography>
            </div>
            <div className="flex gap-x-1 items-center">
              <IconButton onClick={() => navigate(`/settings/publication/subscribe_forms?id=${externalEmbed.id}`)}>
                <PencilSquareIcon width={16} height={16} />
              </IconButton>
              {onDeselect && (
                <IconButton onClick={() => onDeselect(externalEmbed.id)}>
                  <TrashIcon width={16} height={16} />
                </IconButton>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default SelectedExternalEmbeds;
