import {
  Heading1Element,
  Heading2Element,
  Heading3Element,
  Heading4Element,
  Heading5Element,
  Heading6Element,
} from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, useReactNodeView } from '@tiptap/react';

import MergeTag from '../../components/MergeTag';
import { getDataAttributes } from '../../utils';

export const HeadingView: React.FC<NodeViewProps> = ({ HTMLAttributes, node, editor, getPos }) => {
  const { onDragStart } = useReactNodeView();
  const { level, mergeTag } = node.attrs;

  let Cmp = Heading1Element;
  switch (level) {
    case 1:
      Cmp = Heading1Element;
      break;
    case 2:
      Cmp = Heading2Element;
      break;
    case 3:
      Cmp = Heading3Element;
      break;
    case 4:
      Cmp = Heading4Element;
      break;
    case 5:
      Cmp = Heading5Element;
      break;
    case 6:
      Cmp = Heading6Element;
      break;
    default:
      Cmp = Heading3Element;
  }

  const onClose = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'mergeTag', null);
      return true;
    });
  };

  return (
    <Cmp
      element={{
        type: 'heading',
        attrs: node.attrs as any,
        content: [],
      }}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
      onDragStart={onDragStart}
    >
      {mergeTag && <MergeTag onClose={onClose} />}
      <NodeViewContent />
    </Cmp>
  );
};
