import { useState } from 'react';

import { Input, Label } from '@/components/Form';
import { Duration } from '@/interfaces/general';
import { DURATION_OPTIONS } from '@/pages/AutomationView/constants';
import { Dropdown } from '@/ui/Dropdown';
import { durationFromSeconds, secondsForDuration, secondsToDuration } from '@/utils/durationDisplay';

interface Props {
  seconds: number;
  onChange: (value: number) => void;
}

const ConfigureWaitForForm = ({ seconds, onChange }: Props) => {
  const [delayUnit, setDelayUnit] = useState(durationFromSeconds(seconds || 0));
  const delayDisplay = secondsToDuration(seconds, delayUnit);

  const handleChangeInDelayValue = ({ target: { value } }: any) => {
    onChange(secondsForDuration(parseInt(value, 10), delayUnit));
  };

  const handleChangeInDelayUnit = (_name: string, value: Duration) => {
    setDelayUnit(value);
    onChange(secondsForDuration(delayDisplay, value));
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Label htmlFor="" value="Wait for…" />
      <div className="flex gap-x-2">
        <Input value={delayDisplay.toString()} type="number" name="delayValue" onChange={handleChangeInDelayValue} />
        <Dropdown
          name="delayUnit"
          value={delayUnit}
          options={DURATION_OPTIONS}
          onSelect={handleChangeInDelayUnit}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default ConfigureWaitForForm;
