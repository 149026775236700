import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { FormQuestion, FormQuestionTypes } from '../../interfaces/form';
import api from '../../services/swarm';

export interface ApiResponse {
  form_question: FormQuestion;
}

interface Props {
  form_id: string;
  onSuccess: (result: ApiResponse) => void;
}

const useCreateFormQuestion = ({ form_id, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<
    ApiResponse,
    unknown,
    { order: number; prompt: string; required?: boolean; custom_field_id: string; question_type: FormQuestionTypes }
  >(
    ({ order, prompt, required, custom_field_id, question_type }) =>
      api
        .post(`/form_questions`, {
          publication_id: currentPublicationId,
          form_id,
          form_question: {
            order,
            prompt,
            required,
            custom_field_id,
            question_type,
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useCreateFormQuestion;
