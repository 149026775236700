import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { PermissionLevel, PermissionResource, Permissions } from '../interfaces/permissions';
import api from '../services/swarm';

import { useCurrentPublicationState } from './current-publication-context';

interface IPermissionsContext {
  permissions?: Permissions;
  isLoading: boolean;
  reloadPermissions: () => void;
  checkPermissions: (resource: PermissionResource, permission: PermissionLevel) => boolean | undefined;
  hasAnyReadPermissions: (permissionsHash: Record<string, string[]>) => boolean;
}

const PermissionsContext = createContext<IPermissionsContext | undefined>(undefined);

PermissionsContext.displayName = 'PermissionsContext';

const PermissionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState<Permissions>();
  const [publicationId] = useCurrentPublicationState(false);

  const reloadPermissions = useCallback(() => {
    if (!publicationId || publicationId === '') {
      return;
    }

    const params = {
      publication_id: publicationId,
    };

    setIsLoading(true);

    // TODO: use hook usePublicationPermissions
    api
      .get('/permissions', { params })
      .then((res) => {
        const dataCopy = { ...res.data };
        delete dataCopy.admin;
        setPermissions(dataCopy);
      })
      .catch((e) => {
        console.log('Error fetching permissions: ', e);
      })
      .finally(() => setIsLoading(false));
  }, [publicationId]);

  // TODO: use checkPermissions exported from hook usePublicationPermissions
  const checkPermissions = useCallback(
    (resource: PermissionResource, permission: PermissionLevel) => {
      const keys = resource.split('/');

      // Walk down permissions tree and return permissions array (or empty if not found)
      const permissionValues = keys.reduce((obj: any, key: string) => {
        return obj && obj[key] !== undefined ? obj[key] : undefined;
      }, permissions);

      return Array.isArray(permissionValues) ? permissionValues.includes(permission) : false;
    },
    [permissions]
  );

  // Pass in a hash of permissions. (ex: permissions.views.nav.audience)
  // this will return true if any of the sub-permissions have read access.
  const hasAnyReadPermissions = (permissionsHash: Record<string, string[]>) => {
    return Object.values(permissionsHash).some((value) => Array.isArray(value) && value.includes('read'));
  };

  useEffect(() => {
    reloadPermissions();
  }, [reloadPermissions]);

  return useMemo(
    () => (
      <PermissionsContext.Provider
        value={{
          reloadPermissions,
          checkPermissions,
          hasAnyReadPermissions,
          permissions,
          isLoading,
        }}
      >
        {children}
      </PermissionsContext.Provider>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    [reloadPermissions, checkPermissions, hasAnyReadPermissions, permissions, isLoading]
  );
};

function usePermissions() {
  const context = React.useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error(`usePermissions must be used within a PermissionsProvider`);
  }
  return context;
}

export { PermissionsContext, PermissionsProvider, usePermissions };
