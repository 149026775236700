export enum IntegrationProvider {
  MAILCHIMP = 'mailchimp',
}

export interface Integration {
  id: string;
  expires_at: string | null;
  provider: IntegrationProvider;
  uid: string;
  created_at: string;
  updated_at: string;
  info: {
    name: string;
    email: string | null;
    image_url: string | null;
  };
}
