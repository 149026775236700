import cx from 'classnames';

import SubscriberTagItem from '@/components/SubscriberTags/SubscriberTagItem';
import { Typography } from '@/components/Typography';
import { useCustomField } from '@/hooks';
import { useForm } from '@/hooks/useForms';
import { useSubscriberTag } from '@/hooks/useSubscriberTags';
import useTierLabel from '@/hooks/useTierLabel';
import { AutomationCondition, AutomationConditions } from '@/interfaces/automations/automation_condition';
import { Badge } from '@/ui/Badge';

import { CONDITION_OPTIONS, OPERATOR_OPTIONS_BY_CONDITION_NAME } from '../../components/ConditionsModal/constants';

interface Props {
  automationConditions: AutomationConditions;
}

export const CustomFieldName = (id: string) => {
  const { data } = useCustomField(id);
  return data?.display;
};

export const FormIdName = (id: string) => {
  const { data } = useForm(id);
  return data?.title;
};

export const SubscriberTag = (id: string) => {
  const { data } = useSubscriberTag(id);
  return data?.subscriber_tag;
};

export const TierName = (value: string) => {
  return useTierLabel(value);
};

export const conditionPrefix = (condition: AutomationCondition) => {
  if (condition.name === 'custom_field_id') {
    return 'Custom Field';
  }

  if (condition.name === 'form_id') {
    return 'Survey';
  }

  return null;
};

export const displayConditionName = (condition: AutomationCondition) => {
  if (condition.name === 'custom_field_id' && condition.resource_id) {
    return CustomFieldName(condition.resource_id);
  }

  if (condition.name === 'form_id' && condition.resource_id) {
    return FormIdName(condition.resource_id);
  }

  const conditionOption = CONDITION_OPTIONS.find((option) => option.value === condition.name);
  return conditionOption?.label || condition.name;
};

export const displayConditionValue = (condition: AutomationCondition) => {
  if (condition.name === 'subscriber_tag_id' && condition.value) {
    const tag = SubscriberTag(condition.value);
    return <SubscriberTagItem name={tag?.name ? tag.name : 'Not Found'} color={tag?.color ? tag.color : 'white'} />;
  }

  if (condition.name === 'tier_id' && condition.value) {
    return TierName(condition.value);
  }

  return condition.value;
};

const ConditionsList = ({ automationConditions }: Props) => {
  const conditionCount = automationConditions?.conditions?.length || 0;
  const ifBadge = conditionCount > 1 && (
    <Badge size="sm" className="mr-2 uppercase">
      If
    </Badge>
  );
  const operatorBadge = ifBadge && (
    <Badge size="sm" className="mr-2 uppercase">
      {automationConditions.logical_operator}
    </Badge>
  );

  return (
    <ul className="flex flex-col gap-y-1">
      {automationConditions.conditions?.map((condition: AutomationCondition, index: number) => {
        const operatorName = OPERATOR_OPTIONS_BY_CONDITION_NAME[condition.name]?.find(
          (option) => option.value === condition.operator
        )?.label;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`condition-item-${index}`} className="flex">
            <div className={cx(conditionCount > 1 && 'flex-none w-12 text-right')}>
              {index === 0 && ifBadge}
              {index > 0 && operatorBadge}
            </div>
            <div className="flex-1 leading-none pt-1">
              <Typography size="xs" className="leading-none">
                {conditionPrefix(condition)}{' '}
                <Typography size="xs" weight="semibold" className="leading-none">
                  {displayConditionName(condition)}
                </Typography>{' '}
                {operatorName}{' '}
                <Typography size="xs" weight="semibold" className="leading-none">
                  {displayConditionValue(condition)}
                </Typography>
              </Typography>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default ConditionsList;
