import { PostClick } from "@/interfaces/post";

export const getClicksPercentage = (click: PostClick, totalClicks: number) => {
  if (!totalClicks) {
    return 0;
  }

  return Math.min(
    100,
    (click.total_unique_email_clicked / totalClicks) * 100
  );
};