import { useInfiniteQuery } from 'react-query';

import { Ledger } from '../../../interfaces/admin_reports/ledger';
import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  ledgers: Ledger[];
  pagination: Pagination;
}

interface UseLedgersProps {
  search: string;
  startDate?: Date | null;
  endDate?: Date | null;
}

export default function useLedgers({ search, startDate, endDate }: UseLedgersProps) {
  const fetchLedgers = ({ pageParam = 1 }) =>
    api
      .get(`/admin_reports/ledgers`, {
        params: {
          page: pageParam,
          per_page: 30,
          search,
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['admin_reports', 'ledgers', search, startDate, endDate], fetchLedgers, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
