import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
} from '../constants';

import { PostView } from './views/PostView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    post: {
      setPost: () => ReturnType;
      setFontFamily: (fontFamily: string, property: string, pos?: number) => ReturnType;
    };
  }
}

type CardSchema = {
  node: string;
  className: string;
  children: CardSchema[];
};

const cardStructure: CardSchema = {
  node: 'div',
  className: 'flex flex-col gap-3 w-full w-full',
  children: [
    {
      node: 'image',
      className: '',
      children: [],
    },
    {
      node: 'div',
      className: 'flex flex-col gap-2',
      children: [
        {
          node: 'tags',
          className: '',
          children: [],
        },
        {
          node: 'div',
          className: 'flex flex-col gap-1',
          children: [
            {
              node: 'title',
              className: '',
              children: [],
            },
            {
              node: 'subtitle',
              className: '',
              children: [],
            },
            {
              node: 'authors',
              className: 'text-xs',
              children: [],
            },
            {
              node: 'div',
              className: 'flex flex-row gap-1 items-center flex-wrap',
              children: [
                {
                  node: 'timestamp',
                  className: '',
                  children: [],
                },
                {
                  node: 'premium',
                  className: '',
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const Post = Node.create<{}, {}>({
  name: 'post',

  group: 'block',

  draggable: true,

  atom: true,

  defining: true,

  selectable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },

      insertedFromSidebar: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-inserted-from-sidebar'),
        renderHTML: (attributes) => ({ 'data-inserted-from-sidebar': attributes.insertedFromSidebar }),
      },
      hasFakeData: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-has-fake-data'),
        renderHTML: (attributes) => ({ 'data-has-fake-data': attributes.hasFakeData }),
      },

      postGroupType: {
        default: 'free_selection',
        parseHTML: (element) => element.getAttribute('data-post-group-type'),
        renderHTML: (attributes) => ({ 'data-post-group-type': attributes.postGroupType }),
      },
      gap: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      columns: {
        default: '3',
        parseHTML: (element) => element.getAttribute('data-columns'),
        renderHTML: (attributes) => ({ 'data-columns': attributes.columns }),
      },
      scrollOnMobile: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-scroll-on-mobile'),
        renderHTML: (attributes) => ({ 'data-scroll-on-mobile': attributes.scrollOnMobile }),
      },
      textAlign: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-text-align'),
        renderHTML: (attributes) => ({ 'data-text-align': attributes.textAlign }),
      },
      background: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-background'),
        renderHTML: (attributes) => ({ 'data-background': attributes.background }),
      },
      color: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-color'),
        renderHTML: (attributes) => ({ 'data-color': attributes.color }),
      },
      dividerColor: {
        default: '#000000FF',
        parseHTML: (element) => element.getAttribute('data-divider-color'),
        renderHTML: (attributes) => ({ 'data-divider-color': attributes.dividerColor }),
      },
      dividerStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-divider-style'),
        renderHTML: (attributes) => ({ 'data-divider-style': attributes.dividerStyle }),
      },
      shadow: {
        default: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      borderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
        parseHTML: (element) => element.getAttribute('data-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      cardPadding: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-card-padding') || '0px',
        renderHTML: (attributes) => ({ 'data-card-padding': attributes.cardPadding }),
      },
      borderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '1px',
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_BORDER_COLOR,
      },
      borderStyle: {
        default: 'none',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },

      // Image
      imageEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-image-enabled'),
        renderHTML: (attributes) => ({ 'data-image-enabled': attributes.imageEnabled }),
      },
      imageSizeFixed: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-image-size-fixed'),
        renderHTML: (attributes) => ({ 'data-image-size-fixed': attributes.imageSizeFixed }),
      },
      imageWidth: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-image-width'),
        renderHTML: (attributes) => ({ 'data-image-width': attributes.imageWidth }),
      },
      imageHeight: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('data-image-height'),
        renderHTML: (attributes) => ({ 'data-image-height': attributes.imageHeight }),
      },
      imageBorderRadius: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-image-border-radius'),
        renderHTML: (attributes) => ({ 'data-image-border-radius': attributes.imageBorderRadius }),
      },

      // Tags
      tagTextColor: {
        default: '#111827',
        parseHTML: (element) => element.getAttribute('data-tag-text-color'),
        renderHTML: (attributes) => ({ 'data-tag-text-color': attributes.tagTextColor }),
      },
      tagTransform: {
        default: 'capitalize',
        parseHTML: (element) => element.getAttribute('data-tag-transform'),
        renderHTML: (attributes) => ({ 'data-tag-transform': attributes.tagTransform }),
      },
      tagBadgeEnabled: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-tag-badge-enabled'),
        renderHTML: (attributes) => ({ 'data-tag-badge-enabled': attributes.tagBadgeEnabled }),
      },
      tagBackgroundColor: {
        default: '#f3f4f6',
        parseHTML: (element) => element.getAttribute('data-tag-background'),
        renderHTML: (attributes) => ({ 'data-tag-background': attributes.tagBackground }),
      },
      tagBorderRadius: {
        default: '9999px',
        parseHTML: (element) => element.getAttribute('data-tag-border-radius'),
        renderHTML: (attributes) => ({ 'data-tag-border-radius': attributes.tagBorderRadius }),
      },
      tagFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-tag-font-family'),
        renderHTML: (attributes) => ({ 'data-tag-font-family': attributes.tagFontFamily }),
      },

      // Titles
      titleTextColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-title-text-color'),
        renderHTML: (attributes) => ({ 'data-title-text-color': attributes.titleTextColor }),
      },
      titleFontSize: {
        default: 'text-lg',
        parseHTML: (element) => element.getAttribute('data-title-font-size'),
        renderHTML: (attributes) => ({ 'data-title-font-size': attributes.titleFontSize }),
      },
      titleLineClamp: {
        default: 'line-clamp-none',
        parseHTML: (element) => element.getAttribute('data-title-line-clamp'),
        renderHTML: (attributes) => ({ 'data-title-line-clamp': attributes.titleLineClamp }),
      },
      titleFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-title-font-family'),
        renderHTML: (attributes) => ({ 'data-title-font-family': attributes.titleFontFamily }),
      },
      subtitleTextColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-subtitle-text-color'),
        renderHTML: (attributes) => ({ 'data-subtitle-text-color': attributes.subtitleTextColor }),
      },
      subtitleFontSize: {
        default: '14px',
        parseHTML: (element) => element.getAttribute('data-subtitle-font-size'),
        renderHTML: (attributes) => ({ 'data-subtitle-font-size': attributes.subtitleFontSize }),
      },
      subtitleLineClamp: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-subtitle-line-clamp'),
        renderHTML: (attributes) => ({ 'data-subtitle-line-clamp': attributes.subtitleLineClamp }),
      },
      subtitleShowing: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-subtitle-showing'),
        renderHTML: (attributes) => ({ 'data-subtitle-showing': attributes.subtitleShowing }),
      },
      subtitleFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-subtitle-font-family'),
        renderHTML: (attributes) => ({ 'data-subtitle-font-family': attributes.subtitleFontFamily }),
      },

      // Authors
      authorsEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-author-enabled'),
        renderHTML: (attributes) => ({ 'data-author-enabled': attributes.authorEnabled }),
      },
      authorsImagesEnabled: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-author-images-enabled'),
        renderHTML: (attributes) => ({ 'data-author-images-enabled': attributes.authorImagesEnabled }),
      },
      authorsTextEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-author-text-enabled'),
        renderHTML: (attributes) => ({ 'data-author-text-enabled': attributes.authorTextEnabled }),
      },
      authorsTruncateListEnabled: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-author-truncate-list-enabled'),
        renderHTML: (attributes) => ({ 'data-author-truncate-list-enabled': attributes.authorTruncateListEnabled }),
      },
      authorsTextColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-author-text-color'),
        renderHTML: (attributes) => ({ 'data-author-text-color': attributes.authorTextColor }),
      },
      authorsTextTransform: {
        default: 'capitalize',
        parseHTML: (element) => element.getAttribute('data-author-text-transform'),
        renderHTML: (attributes) => ({ 'data-author-text-transform': attributes.authorTextTransform }),
      },
      authorsFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-author-font-family'),
        renderHTML: (attributes) => ({ 'data-author-font-family': attributes.authorsFontFamily }),
      },

      // Timestamps
      timestampsEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-timestamp-enabled'),
        renderHTML: (attributes) => ({ 'data-timestamp-enabled': attributes.timestampEnabled }),
      },
      timestampsTextColor: {
        default: '#000000',
        parseHTML: (element) => element.getAttribute('data-timestamp-text-color'),
        renderHTML: (attributes) => ({ 'data-timestamp-text-color': attributes.timestampTextColor }),
      },
      timestampsDisplay: {
        default: 'short_date',
        parseHTML: (element) => element.getAttribute('data-timestamp-line-clamp'),
        renderHTML: (attributes) => ({ 'data-timestamp-line-clamp': attributes.timestampLineClamp }),
      },

      // Premium
      premiumIconEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-premium-icon-enabled'),
        renderHTML: (attributes) => ({ 'data-premium-icon-enabled': attributes.premiumIconEnabled }),
      },
      premiumTextEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-premium-text-enabled'),
        renderHTML: (attributes) => ({ 'data-premium-text-enabled': attributes.premiumTextEnabled }),
      },
      premiumBadgeEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-premium-badge-enabled'),
        renderHTML: (attributes) => ({ 'data-premium-badge-enabled': attributes.premiumBadgeEnabled }),
      },
      premiumTextColor: {
        default: '#111827',
        parseHTML: (element) => element.getAttribute('data-premium-text-color'),
        renderHTML: (attributes) => ({ 'data-premium-text-color': attributes.premiumTextColor }),
      },
      premiumBackgroundColor: {
        default: '#f3f4f6',
        parseHTML: (element) => element.getAttribute('data-premium-background'),
        renderHTML: (attributes) => ({ 'data-premium-background': attributes.premiumBackground }),
      },
      premiumTextTransform: {
        default: 'capitalize',
        parseHTML: (element) => element.getAttribute('data-premium-text-transform'),
        renderHTML: (attributes) => ({ 'data-premium-text-transform': attributes.premiumTextTransform }),
      },

      cardStructure: {
        default: cardStructure,
        parseHTML: (element) => JSON.parse(element.getAttribute('data-card-structure') || '{}'),
        renderHTML: (attributes) => ({ 'data-card-structure': JSON.stringify(attributes.cardStructure) }),
      },

      // Pagination
      paginateButtonBorderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-paginate-button-border-radius'),
        renderHTML: (attributes) => ({ 'data-paginate-button-border-radius': attributes.paginateButtonBorderRadius }),
      },
      paginateButtonBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-paginate-button-border-width'),
        renderHTML: (attributes) => ({ 'data-paginate-button-border-width': attributes.paginateButtonBorderWidth }),
      },
      paginateButtonBorderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-paginate-button-border-style'),
        renderHTML: (attributes) => ({ 'data-paginate-button-border-style': attributes.paginateButtonBorderStyle }),
      },
      paginateButtonBorderColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-paginate-button-border-color'),
        renderHTML: (attributes) => ({ 'data-paginate-button-border-color': attributes.paginateButtonBorderColor }),
      },
      paginateButtonPadding: {
        default: '12px 16px 12px 16px',
        parseHTML: (element) => element.getAttribute('data-paginate-button-padding'),
        renderHTML: (attributes) => ({ 'data-paginate-button-padding': attributes.paginateButtonPadding }),
      },
      paginateButtonBackgroundColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-paginate-button-background-color'),
        renderHTML: (attributes) => ({
          'data-paginate-button-background-color': attributes.paginateButtonBackgroundColor,
        }),
      },
      paginateButtonTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-paginate-button-text-color'),
        renderHTML: (attributes) => ({
          'data-paginate-button-text-color': attributes.paginateButtonTextColor,
        }),
      },
      paginateButtonShadow: {
        default: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
        parseHTML: (element) => element.getAttribute('data-paginate-button-shadow'),
        renderHTML: (attributes) => ({ 'data-paginate-button-shadow': attributes.paginateButtonShadow }),
      },
      paginateButtonIconColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-paginate-button-icon-color'),
        renderHTML: (attributes) => ({ 'data-paginate-button-icon-color': attributes.paginateButtonIconColor }),
      },
      paginateButtonIconPlacement: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-paginate-button-icon-placement'),
        renderHTML: (attributes) => ({
          'data-paginate-button-icon-placement': attributes.paginateButtonIconPlacement,
        }),
      },
      paginateButtonIcon: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-paginate-button-icon'),
        renderHTML: (attributes) => ({
          'data-paginate-button-icon': attributes.paginateButtonIcon,
        }),
      },

      // Search Input
      inputAlignment: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-input-alignment'),
        renderHTML: (attributes) => ({ 'data-input-alignment': attributes.inputAlignment }),
      },
      inputMaxWidth: {
        default: '600px',
        parseHTML: (element) => element.getAttribute('data-input-max-width'),
        renderHTML: (attributes) => ({ 'data-input-max-width': attributes.inputMaxWidth }),
      },
      inputEnabled: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-search-input-enabled'),
        renderHTML: (attributes) => ({ 'data-search-input-enabled': attributes.searchInputEnabled }),
      },
      inputIconFill: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-icon-fill'),
        renderHTML: (attributes) => ({ 'data-input-icon-fill': attributes.inputIconFill }),
      },
      inputBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-input-border-width'),
        renderHTML: (attributes) => ({ 'data-input-border-width': attributes.inputBorderWidth }),
      },
      inputBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-border-color'),
        renderHTML: (attributes) => ({ 'data-input-border-color': attributes.inputBorderColor }),
      },
      inputBorderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-input-border-style'),
        renderHTML: (attributes) => ({ 'data-input-border-style': attributes.inputBorderStyle }),
      },
      inputBorderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-input-border-radius'),
        renderHTML: (attributes) => ({ 'data-input-border-radius': attributes.inputBorderRadius }),
      },
      inputBackgroundColor: {
        default: '#ffffff',
        parseHTML: (element) => element.getAttribute('data-input-background-color'),
        renderHTML: (attributes) => ({ 'data-input-background-color': attributes.inputBackgroundColor }),
      },
      inputTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-text-color'),
        renderHTML: (attributes) => ({ 'data-input-text-color': attributes.inputTextColor }),
      },
      inputPlaceholderColor: {
        default: '#6b7280',
        parseHTML: (element) => element.getAttribute('data-input-placeholder-color'),
        renderHTML: (attributes) => ({ 'data-input-placeholder-color': attributes.inputPlaceholderColor }),
      },

      data: {
        default: {
          posts: [],
        },
        parseHTML: (element) => JSON.parse(element.getAttribute('data-data') || '{}'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
    };
  },

  addOptions() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setPost:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(PostView, { className: 'relative' });
  },
});

export default Post;

// const cardStructure2 = {
//   node: 'div',
//   className: 'flex gap-2',
//   children: [
//     {
//       node: 'image',
//       className: '',
//       children: [],
//     },
//     {
//       node: 'div',
//       className: 'flex flex-col',
//       children: [
//         {
//           node: 'tags',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'titles',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'authors',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'timestamp',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'premium',
//           className: '',
//           children: [],
//         },
//       ],
//     },
//   ],
// };
