import { useInfiniteQuery, useQueryClient } from 'react-query';

import { TimePeriod } from '@/utils';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Form } from '../../interfaces/form';
import { Pagination } from '../../interfaces/general';
import api from '../../services/swarm';

interface ApiResponse {
  forms: Form[];
  pagination: Pagination;
}

export default function useCustomFields({
  search,
  dir = 'asc',
  order = 'form.title',
  perPage = 10,
  all = false,
  filterByDateCreated = TimePeriod.ALL_TIME,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
  dir?: string;
  order?: string;
  filterByDateCreated?: string;
}) {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchForms = ({ pageParam = 1 }) =>
    api
      .get(`/forms`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
          order,
          dir,
          filter_by_date_created: filterByDateCreated,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery<ApiResponse>(
    [currentPublicationId, 'forms', search, order, dir, filterByDateCreated],
    fetchForms,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      retry: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        const forms = data.pages.flatMap((page) => page.forms);

        forms.forEach((form: any) => {
          queryClient.setQueryData(['forms', form?.id], {
            id: form?.id,
            title: form?.title,
            description: form?.description,
          });
        });
      },
      onError: (errPayload: any) => errPayload?.response?.data?.message || 'Something went wrong',
    }
  );
}
