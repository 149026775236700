import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import { TooltipProps } from '@/routes/website_builder/_types/misc';

import OverrideGlobalSettingNote from '../../Helpers/OverrideGlobalSettingNote';
import TextInput from '../TextInput';

import { FormControlType } from './EditableProps/types';
import { EditableProps } from './EditableProps';
import FormHelper from './FormHelper';

interface Props {
  textValue: string;
  placeholderTextValue: string;
  label: string;
  tooltip?: TooltipProps;
  onSaveText: (value: string) => void;
  onSavePlaceholderText: (value: string) => void;
  onSave: (key: string, value: string) => void;
  editableProps: {
    [key: string]: {
      type: FormControlType;
      value: any;
      label?: string;
      resetCheckValue?: any;
      resetLabel?: string;
    };
  };
}

const SignupForm = ({
  textValue,
  placeholderTextValue,
  label,
  tooltip,
  editableProps,
  onSave,
  onSaveText,
  onSavePlaceholderText,
}: Props) => {
  const { fontOptions } = usePageContext();

  return (
    <FormHelper
      label={label}
      actionLabel="Styles"
      description={<OverrideGlobalSettingNote to="/website_builder?sectionId=subscribe-widget" />}
      tooltip={tooltip}
      alwaysShowingChildren={<div />}
    >
      <div className="space-y-3">
        <TextInput label="Button Text" value={textValue} onSave={(value: string) => onSaveText(value)} />
        <TextInput
          label="Placeholder Text"
          value={placeholderTextValue}
          onSave={(value: string) => onSavePlaceholderText(value)}
        />
        <EditableProps items={editableProps} onSave={onSave} fontOptions={fontOptions} label={label} />
      </div>
    </FormHelper>
  );
};

export default SignupForm;
