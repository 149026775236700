import { Input } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import { useOrganization } from '@/hooks';
import { Address } from '@/interfaces/general';
import { Publication } from '@/interfaces/publication';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import EditAddress from '../../_components/EditAddress';
import FormContentPreviewContainer from '../../_components/FormContentPreviewContainer';
import { usePublicationSettings } from '../context';

interface Props {
  publication: Publication;
}

const FooterDetailsForm = ({ publication }: Props) => {
  const {
    data: organizationData,
    isSuccess: isOrganizationSuccess,
    isError: isOrganizationError,
  } = useOrganization(publication.organization_id, publication.id);
  const { organization } = organizationData;

  const { handleChangeInAttribute, savingAttribute } = usePublicationSettings();

  const copyrightTextValue = publication.copyright_text || publication.name;
  const copyrightLeadingText = `© ${new Date().getFullYear()}`;

  const { address: publicationAddress } = publication;
  const isUsingOrganizationAddress = !publication?.address?.id && organization?.is_address_valid;
  const address = Object.values(publicationAddress || {}).every((value) => value === null)
    ? organization
    : publicationAddress;

  const handleSaveAddress = (newAddress: Address) => {
    handleChangeInAttribute('address_attributes')(newAddress);
  };

  const handleDeleteAddress = () => {
    handleChangeInAttribute('address_attributes')({
      id: publication?.address?.id,
      _destroy: true,
    });
  };

  const handleBlur = async (attributeName: keyof Publication, value: any) => {
    if (publication[attributeName] === value) {
      return;
    }

    handleChangeInAttribute(attributeName)(value);
  };

  return (
    <LoadingBox isLoading={!isOrganizationSuccess} isError={isOrganizationError}>
      {organization ? (
        <BodyContainer>
          <CardHeader title="Email Footer Details" description="Configure your email footer information" />
          <Input
            leadingText={copyrightLeadingText}
            labelText="Copyright Text"
            name="copyright_text"
            placeholder={publication.name}
            defaultValue={publication.copyright_text}
            onBlur={(e) => handleBlur('copyright_text', e.target.value.trim())}
            disabled={savingAttribute === 'copyright_text'}
          />
          <FormContentPreviewContainer className="text-center">
            <Typography token="font-normal/text/sm" colorWeight="500">
              {copyrightLeadingText}{' '}
            </Typography>
            <Typography token="font-medium/text/sm">{copyrightTextValue}</Typography>
          </FormContentPreviewContainer>
          <EditAddress
            id={publication?.address?.id}
            streetAddress={address?.street_address ?? ''}
            city={address.city ?? ''}
            state={address.state ?? ''}
            country={address.country ?? ''}
            countryName={address.country_name ?? ''}
            zipCode={address.zip_code ?? ''}
            onSave={handleSaveAddress}
            onDelete={handleDeleteAddress}
            isSaving={savingAttribute === 'address_attributes'}
            isWhiteLabeled={!!publication.white_labeled}
            isEmpty={!publication?.address?.id && !organization.is_address_valid}
            isUsingOrganizationAddress={isUsingOrganizationAddress}
          />
        </BodyContainer>
      ) : null}
    </LoadingBox>
  );
};

export default FooterDetailsForm;
