import { useState } from 'react';

import { Organization, RoleOption } from '@/interfaces/organization';
import { TeamAssignment } from '@/interfaces/team_assignment';

import NotEditing from '../NotEditing';

import EditPanel from './EditPanel';

interface Props {
  name: string;
  email: string;
  role: string;
  imageUrl: string;
  bio: string;
  currentRoleOption: RoleOption;
  organization: Organization;
  assignment: TeamAssignment;
  publicationId: string;
  isInitiallyEditing: boolean;
}

const MemberForm = ({
  organization,
  name,
  email,
  imageUrl,
  bio,
  currentRoleOption,
  assignment,
  publicationId,
  isInitiallyEditing,
}: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(isInitiallyEditing);
  // TODO: organization.role_options filters the options by the current_user's access level
  // TODO:       We should pull this list from it's own endpoint and ensure it returns all role options.
  const roleOptions = organization?.role_options || [];
  const match = roleOptions.find((option: RoleOption) => {
    return (
      assignment?.assignable_type.toUpperCase() === option.object_type.toUpperCase() &&
      assignment?.role.name.toUpperCase() === option.level.toUpperCase()
    );
  });

  return (
    <>
      {currentRoleOption && (
        <EditPanel
          isOpen={isEditing}
          close={() => setIsEditing(false)}
          name={name}
          email={email}
          bio={bio}
          assignment={assignment}
          imageUrl={imageUrl}
          currentRoleOption={currentRoleOption}
          roleOptions={roleOptions}
          publicationId={publicationId}
        />
      )}
      <button
        type="button"
        onClick={() => setIsEditing(true)}
        className="flex w-full text-left rounded justify-between items-center border-b border-gray-100 hover:bg-gray-100 px-2 py-2"
      >
        <div className="flex space-x-2">
          <img alt="profile" className="h-10 w-10 rounded-full object-cover" src={imageUrl} />
          <NotEditing label={name} value={email} />
        </div>

        <div className="pt-4">
          <div className="py-1 px-2 bg-blue-100 text-blue-600 rounded border text-xs font-medimum capitlaize">
            {match?.name}
          </div>
        </div>
      </button>
    </>
  );
};

export default MemberForm;
