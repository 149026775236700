import { FC } from 'react';

import { Dropdown } from '@/ui/Dropdown';

import countryData from '../../utils/countries.json';

interface Props {
  labelText?: string;
  name?: string;
  placeholderText?: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

const CountryInput: FC<Props> = ({
  value,
  placeholderText = 'Select Country',
  labelText,
  name = 'value',
  onChange,
  isDisabled = false,
}) => {
  const options = countryData.map((country: any) => {
    return {
      label: country.name,
      value: country.code,
    };
  });

  return (
    <div className="flex flex-col w-full">
      {labelText && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {labelText}
        </label>
      )}

      <Dropdown
        className={`w-full ${labelText ? 'mt-1' : ''}`}
        name={name}
        placeholderText={placeholderText}
        value={value || ''}
        onSelect={(_name: string, selectedValue: string) => onChange(selectedValue)}
        options={options}
        disabled={isDisabled}
      />
    </div>
  );
};

export default CountryInput;
