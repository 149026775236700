import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import Tab from './Tab';
import { TabContext } from './TabContext';

interface TabsProps {
  children: ReactElement[];
}

const Tabs = ({ children }: TabsProps) => {
  const location = useLocation();

  const activeTabIndex = children.findIndex((child) => location.pathname.endsWith(child.props.to));

  const modifiedChildren = React.Children.map(children, (child, index) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    const isActive = index === activeTabIndex;
    const isAfterActive = index === activeTabIndex + 1;

    return <TabContext.Provider value={{ isActive, isAfterActive }}>{child}</TabContext.Provider>;
  });

  return (
    <nav className="flex" aria-label="Tabs">
      <div className="rounded-lg flex bg-gray-50 divide-x">{modifiedChildren}</div>
    </nav>
  );
};

Tabs.Tab = Tab;

export default Tabs;
