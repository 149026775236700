import moment from 'moment-mini';

import SlideOver from '@/components/SlideOver';
import { PremiumOffer } from '@/interfaces/premium_offer';

import RedemptionsTable from './RedemptionsTable';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  offer?: PremiumOffer | null;
  publicationId: string;
}

const RedemptionsSlideOver = ({ isOpen, onClose, offer, publicationId }: Props) => {
  return (
    <SlideOver
      bodyId={offer?.id}
      headerText={offer?.name || ''}
      headerDescription={offer?.description || ''}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex justify-between flex-wrap">
        <div>
          <h3 className="text-md font-medium text-gray-900">Terms</h3>
          <p className="text-sm text-gray-900">{offer?.terms}</p>
        </div>
        <div>
          <h3 className="text-md font-medium text-gray-900">Expires</h3>
          <p className="text-sm text-gray-900">{offer?.expires_at ? moment(offer?.expires_at).format('lll') : '-'}</p>
        </div>
        <div>
          <h3 className="text-md font-medium text-gray-900">Product Exclusive</h3>
          <p className="text-sm text-gray-900">{offer?.product_exclusive_type ? offer?.product_exclusive_type : '-'}</p>
        </div>
      </div>
      <RedemptionsTable className="mt-4" publicationId={publicationId} premiumOfferId={offer?.id || ''} />
    </SlideOver>
  );
};

export default RedemptionsSlideOver;
