import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Checkbox, PhoneInput } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';
import { isPhoneValid } from '@/utils/phoneUtils';

import { Layout, LogoWrapper } from '../_components/Layout';
import useCreatePhoneNumber from '../_hooks/useCreatePhoneNumber';

const PhoneCollection = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('us');
  const [messageConsent, setMessageConsent] = useState(true);

  const signupToken = localStorage.getItem('signupToken');
  const createPhoneNumber = useCreatePhoneNumber(signupToken);

  const handleSubmit = () => {
    createPhoneNumber.mutate(
      { number: phone, messageConsent },
      {
        onSuccess: () => {
          localStorage.setItem('signupPhone', phone);
          localStorage.setItem('phoneMessageConsent', messageConsent.toString());

          analytics.track('Phone Verification', {
            phoneVerified: false,
            phone,
            country,
          });

          navigate('/signup/phone_verification');
        },
        onError: (error: any) => {
          if (error?.response?.data?.status === 404) {
            toast.error("Please make sure you've confirmed your email.");
            navigate('/signup/email_confirmation');
          } else {
            toast.error(error?.response?.data?.error || 'Something went wrong');
          }
        },
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>Registration - beehiiv</title>
      </Helmet>

      <Layout>
        <LogoWrapper
          title="Verify your phone number"
          subtitle={
            <Typography token="font-normal/text/base" colorWeight="700">
              Please note, we will send you a one-time phone verification via SMS for spam and security purposes. Msg
              and data rates may apply.
            </Typography>
          }
        >
          <div className="space-y-5 w-full">
            <PhoneInput value={phone} onChange={setPhone} defaultCountry="us" onCountryChange={setCountry} />
            <Button
              type="button"
              variant="primary"
              block
              onClick={handleSubmit}
              loading={createPhoneNumber.isLoading}
              disabled={!isPhoneValid(phone, country)}
            >
              Send Code
            </Button>
          </div>

          <div className="pt-10 flex space-x-2 items-center justify-center">
            <Checkbox name="receive_updates" checked={messageConsent} onChange={setMessageConsent} />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="receive_updates">
              <Typography token="font-medium/text/sm" colorWeight="500">
                I want to receive updates about beehiiv via SMS
              </Typography>
            </label>
          </div>
        </LogoWrapper>
      </Layout>
    </>
  );
};

export default PhoneCollection;
