import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useCurrentUser } from '@/context/current-user-context';
import TabNavigation from '@/ui/TabNavigation';

const Nav = () => {
  const { pathname } = useLocation();

  const growthPath = '/monetize/subscriptions/growth';
  const attributionPath = '/monetize/subscriptions/attribution';
  const breakdownPath = '/monetize/subscriptions/breakdown';
  const paidTiersPath = '/monetize/subscriptions/paid_tiers';

  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;

  const tabs = useMemo(() => {
    const list = [
      {
        name: 'growth',
        label: 'Growth',
        selected: pathname === growthPath,
        targetRoute: growthPath,
      },
      {
        name: 'attribution',
        label: 'Attribution',
        selected: pathname === attributionPath,
        targetRoute: attributionPath,
      },
      {
        name: 'breakdown',
        label: 'Breakdown',
        selected: pathname === breakdownPath,
        targetRoute: breakdownPath,
      },
    ];

    if (hasSettingsV2) {
      list.push({
        name: 'paid_tiers',
        label: 'Paid Tiers',
        selected: pathname === paidTiersPath,
        targetRoute: paidTiersPath,
      });
    }

    return list;
  }, [pathname, hasSettingsV2]);

  return <TabNavigation variant="tertiary" tabs={tabs} />;
};

export default Nav;
