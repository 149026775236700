import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import SearchInput from '@/ui/SearchInput';
import { TimePeriod } from '@/utils';

interface Props {
  onChangeInPeriod: (timePeriod: TimePeriod) => void;
  showSubjectFilter?: boolean;
  subjectQuery?: string;
  onChangeInSubject?: (value: string) => void;
}

const Filters = ({ onChangeInPeriod, subjectQuery = '', showSubjectFilter = false, onChangeInSubject }: Props) => {
  const { TimePeriodSelect } = useTimePeriodSelect({
    defaultValue: TimePeriod.LAST_7_DAYS,
    size: 'md',
  });

  return (
    <div className="flex justify-end items-center gap-x-2">
      {showSubjectFilter && onChangeInSubject && (
        <div className="w-fit">
          <SearchInput defaultValue={subjectQuery} onSearch={onChangeInSubject} placeholder="Subject..." />
        </div>
      )}
      <TimePeriodSelect handleChange={onChangeInPeriod} />
    </div>
  );
};

export default Filters;
