import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';

import { LogicalOperator, LogicalOperators } from '@/interfaces/condition';
import { Button } from '@/ui/Button';

import useFilteringContext from './filtering-context';

interface Props {
  onSelect: (operator: LogicalOperator) => void;
  logicalOperator: LogicalOperator;
}

const OperatorSelect = ({ logicalOperator, onSelect }: Props) => {
  const operatorButtonLabel = {
    [LogicalOperators.AND]: 'Match all conditions',
    [LogicalOperators.OR]: 'Match any condition',
    [LogicalOperators.NONE]: 'Match no condition',
  }[logicalOperator];

  const { readonly } = useFilteringContext();

  return (
    <div className="inline-flex relative">
      <Popover className="relative">
        {({ close }) => (
          <>
            <Popover.Button as={Button} size="xs" variant="flush" disabled={readonly}>
              {operatorButtonLabel}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute p-2 shadow-lg rounded-md z-10 bg-white border right-0">
                <button
                  type="button"
                  className="text-left text-sm hover:bg-gray-50 rounded-md p-2 w-full whitespace-nowrap"
                  onClick={() => {
                    onSelect(LogicalOperators.AND);
                    close();
                  }}
                >
                  All conditions must match
                </button>
                <button
                  type="button"
                  className="text-left text-sm hover:bg-gray-50 rounded-md p-2 w-full whitespace-nowrap"
                  onClick={() => {
                    onSelect(LogicalOperators.OR);
                    close();
                  }}
                >
                  Any condition
                </button>
                <button
                  type="button"
                  className="text-left text-sm hover:bg-gray-50 rounded-md p-2 w-full whitespace-nowrap"
                  onClick={() => {
                    onSelect(LogicalOperators.NONE);
                    close();
                  }}
                >
                  No condition
                </button>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default OperatorSelect;
