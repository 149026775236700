import cx from 'classnames';

import Card from '@/components/Card';
import { getColor } from '@/components/DesignTokens';
import { Typography } from '@/components/Typography';
import { TypographyProps } from '@/components/Typography/Typography';

type Step = {
  label: string;
  wasCompleted: boolean;
  isCurrent: boolean;
};

interface Props {
  steps: Step[];
}

const FormStepsWidget = ({ steps }: Props) => {
  return (
    <Card className="flex flex-col gap-y-2">
      {steps.map((step, index) => {
        const textColor = step.wasCompleted ? 'success' : 'surface';
        // eslint-disable-next-line no-nested-ternary
        const textColorWeight: TypographyProps['colorWeight'] = step.wasCompleted
          ? '600'
          : step.isCurrent
          ? '900'
          : '400';
        // eslint-disable-next-line no-nested-ternary
        const stepNumberBorderColor = step.isCurrent
          ? getColor('surface/500', 'border')
          : step.wasCompleted
          ? getColor('success/600', 'border')
          : getColor('surface/200', 'border');
        const stepNumberBackgroundColor = step.wasCompleted ? getColor('success/600', 'background') : undefined;
        const stepNumberTextColor = step.wasCompleted ? 'white' : textColor;
        return (
          <div
            key={`form-steps-${step.label}`}
            className={cx(
              'flex gap-x-2 w-full p-2 rounded-md',
              step.isCurrent && getColor('surface/100', 'background')
            )}
          >
            <Typography
              size="sm"
              color={stepNumberTextColor}
              colorWeight={!step.isCurrent && !step.wasCompleted ? '400' : undefined}
              className={cx(
                'flex items-center justify-center w-6 h-6 rounded-full border-2',
                stepNumberBorderColor,
                stepNumberBackgroundColor
              )}
            >
              {index + 1}
            </Typography>
            <Typography color={textColor} colorWeight={textColorWeight}>
              {step.label}{' '}
            </Typography>
          </div>
        );
      })}
    </Card>
  );
};

export default FormStepsWidget;
