import { PropsWithChildren } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import cx from 'classnames';

import { SortableConditionBlockData } from '@/pages/Segments/ConditionsEditor/types';

import DragIcon from '../SortableList/DragIcon';

import TreeItem from './TreeItem';

type Props = PropsWithChildren<{
  uniqueIdentifier: string;
  data?: SortableConditionBlockData;
  isDisabled?: boolean;
  isPlaceholder?: boolean;
}>;

const SortableTreeItem = ({ children, uniqueIdentifier, data, isDisabled = false, isPlaceholder = false }: Props) => {
  const { attributes, listeners, setNodeRef, isDragging, isOver, activeIndex, overIndex } = useSortable({
    id: uniqueIdentifier,
    disabled: isDisabled,
    data,
  });

  let borderClassName = '';
  if (activeIndex < overIndex) {
    borderClassName = 'border-b-2';
  } else {
    borderClassName = 'border-t-2';
  }

  return (
    <div ref={setNodeRef} className={cx(isPlaceholder && 'opacity-80')}>
      <TreeItem className={cx(isOver && borderClassName)}>
        <div className="flex flex-row items-center">
          <button
            {...attributes}
            {...listeners}
            className={cx('w-4 h-16 mr-4', isDragging ? 'cursor-grabbing' : 'cursor-grab')}
            type="button"
          >
            <div className="w-4 h-4">
              <DragIcon />
            </div>
          </button>
          <div className="w-full">{children}</div>
        </div>
      </TreeItem>
    </div>
  );
};

export default SortableTreeItem;
