import { Fragment, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { ArrowLeftIcon, BoltIcon, ChevronRightIcon, SparklesIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { PlanTag } from '@/components/Plan';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';

import useBuilderHighlighter, { BuilderHighlighterProps } from '../../_hooks/useBuilderHighlighter';
import { usePageContext } from '../Context/PageContext';

import { useReturnToSetting } from './SettingsNote';

// ------ SECTION HELPER ICONS ----- //
interface IconProps {
  isHighlighted?: boolean;
}

const VSpacer = () => <div className="h-[2px] transparent" />;
const HSpacer = () => <div className="w-[2px] transparent" />;
const XSmallSection = ({ isHighlighted = false }: IconProps) => (
  <div className={cx(isHighlighted ? 'bg-action-secondary-700' : 'bg-surface-300', 'h-[2px] w-full rounded')} />
);
const SmallSection = ({ isHighlighted = false }: IconProps) => (
  <div className={cx(isHighlighted ? 'bg-action-secondary-700' : 'bg-surface-300', 'h-1 w-full rounded')} />
);
const MediumSection = ({ isHighlighted = false }: IconProps) => (
  <div className={cx(isHighlighted ? 'bg-action-secondary-700' : 'bg-surface-300', 'h-1 rounded-sm ')} />
);
const LargeSection = ({ isHighlighted = false }: IconProps) => (
  <div className={cx(isHighlighted ? 'bg-action-secondary-700' : 'bg-surface-300', 'h-[9px] rounded-sm ')} />
);
const Quotes = () => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.625 0.859375V3.4375C3.62459 3.81034 3.46642 4.1678 3.1852 4.43144C2.90399 4.69508 2.5227 4.84336 2.125 4.84375C2.0587 4.84375 1.99511 4.81906 1.94822 4.7751C1.90134 4.73115 1.875 4.67154 1.875 4.60938C1.875 4.54721 1.90134 4.4876 1.94822 4.44365C1.99511 4.39969 2.0587 4.375 2.125 4.375C2.39022 4.375 2.64457 4.27623 2.83211 4.10041C3.01964 3.9246 3.125 3.68614 3.125 3.4375V3.20312H1.25C1.11739 3.20312 0.990215 3.15374 0.896447 3.06583C0.802678 2.97792 0.75 2.8587 0.75 2.73438V0.859375C0.75 0.735055 0.802678 0.615826 0.896447 0.527919C0.990215 0.440011 1.11739 0.390625 1.25 0.390625H3.125C3.25761 0.390625 3.38479 0.440011 3.47855 0.527919C3.57232 0.615826 3.625 0.735055 3.625 0.859375ZM6.75 0.390625H4.875C4.74239 0.390625 4.61521 0.440011 4.52145 0.527919C4.42768 0.615826 4.375 0.735055 4.375 0.859375V2.73438C4.375 2.8587 4.42768 2.97792 4.52145 3.06583C4.61521 3.15374 4.74239 3.20312 4.875 3.20312H6.75V3.4375C6.75 3.68614 6.64464 3.9246 6.45711 4.10041C6.26957 4.27623 6.01522 4.375 5.75 4.375C5.6837 4.375 5.62011 4.39969 5.57322 4.44365C5.52634 4.4876 5.5 4.54721 5.5 4.60938C5.5 4.67154 5.52634 4.73115 5.57322 4.7751C5.62011 4.81906 5.6837 4.84375 5.75 4.84375C6.1477 4.84336 6.52899 4.69508 6.8102 4.43144C7.09142 4.1678 7.24959 3.81034 7.25 3.4375V0.859375C7.25 0.735055 7.19732 0.615826 7.10355 0.527919C7.00979 0.440011 6.88261 0.390625 6.75 0.390625Z"
      fill="#BE185D"
    />
  </svg>
);

const NavSectionIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <MediumSection isHighlighted />
      <VSpacer />
      <LargeSection />
    </div>
  );
};

const FooterSectionIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <LargeSection />
      <VSpacer />
      <MediumSection isHighlighted />
    </div>
  );
};

const HeroSectionIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <MediumSection />
      <VSpacer />
      <LargeSection isHighlighted />
    </div>
  );
};

const TestimonialSectionIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <div className="flex w-full">
        <Quotes />
        <HSpacer />
        <div className="w-1/3">
          <SmallSection />
        </div>
      </div>
      <VSpacer />
      <MediumSection />
    </div>
  );
};

const FAQSectionIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <XSmallSection />
      <VSpacer />
      <XSmallSection />
      <VSpacer />
      <XSmallSection isHighlighted />
      <VSpacer />
      <XSmallSection />
    </div>
  );
};

const PostsSectionIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <LargeSection />
      <VSpacer />
      <div className="flex w-full">
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
      </div>
    </div>
  );
};

const SideWidgetSectionIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <LargeSection />
      <VSpacer />
      <div className="flex w-full">
        <div className="w-2/3">
          <SmallSection />
        </div>
        <HSpacer />
        <div className="w-1/3">
          <SmallSection isHighlighted />
        </div>
      </div>
    </div>
  );
};

const CustomPagesIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <div className="flex w-full">
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
      </div>
      <VSpacer />
      <div className="flex w-full">
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
      </div>
    </div>
  );
};

const ArchiveSectionIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <SmallSection />
      <VSpacer />
      <div className="flex w-full">
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
      </div>
      <VSpacer />
      <div className="flex w-full">
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
      </div>
    </div>
  );
};

const FeaturedPostsSectionIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <div className="flex w-full">
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
        <HSpacer />
        <SmallSection isHighlighted />
      </div>
      <VSpacer />
      <LargeSection />
    </div>
  );
};

const SubscribeWidgetIcon = () => {
  return (
    <div className="flex flex-col w-4">
      <LargeSection />
      <VSpacer />
      <div className="relative">
        <SmallSection isHighlighted />
        <div className="absolute top-1/2 transform -translate-y-1/2 right-1 rounded-full w-1 h-[1px] bg-action-secondary-100" />
      </div>
    </div>
  );
};

// ------ SECTION HELPER ICONS ----- //

type Props = PropsWithChildren<{
  title: string;
  sectionIcon?: React.ReactNode;
  highlightProps?: BuilderHighlighterProps;
  sectionGroupChildren?: React.ReactNode;
  showPlanBadge?: boolean;
  tooltipText?: string;
  alwaysOpen?: boolean;
  onClose?: () => void;
}>;

const SectionGroupingSlideOut = ({
  title,
  children,
  sectionIcon,
  highlightProps = {},
  sectionGroupChildren,
  showPlanBadge = false,
  tooltipText,
  alwaysOpen = false,
  onClose,
}: Props) => {
  const { plan } = usePageContext();
  const { settings } = useSettings();

  const badgeDetails = useMemo(() => {
    const isTrial = plan === 'trial';
    const isUpgrade = plan === 'launch';
    const hidePill = ['grow', 'scale', 'custom', 'creator'].includes(plan);

    return { isTrial, isUpgrade, hidePill };
  }, [plan]);

  const { isSectionHighlighted, handleStartHighlight, handleEndHighlight } = useBuilderHighlighter({
    sectionId: highlightProps?.sectionId,
    nodeId: highlightProps?.nodeId,
  });

  const [searchParams] = useSearchParams();
  const sectionIds = searchParams.getAll('sectionId');
  const lastSectionId = sectionIds[sectionIds.length - 1];
  const isTopLevel = highlightProps?.sectionId === lastSectionId;

  const { returnToTitle, returnToPath } = useReturnToSetting();

  const [open, setOpen] = useState(isSectionHighlighted);

  useEffect(() => {
    if (isSectionHighlighted && !open) {
      setOpen(true);
    }
  }, [isSectionHighlighted, open]);

  const handleOpen = () => {
    setOpen(true);
    handleStartHighlight();
  };

  const handleClose = () => {
    setOpen(false);
    handleEndHighlight();
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Transition
        as={Fragment}
        show={open || alwaysOpen}
        enter="transform transition ease-in-out duration-300"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-300"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className={cx('absolute top-0 left-0 bg-white z-10 h-screen w-full p-6 pt-0')}>
          <div className="space-y-6">
            <div
              className={cx(
                'flex space-x-3 justify-between items-center bg-white w-full py-6 z-20 border-b border-surface-200',
                isTopLevel && 'sticky top-0'
              )}
            >
              <div className="flex space-x-3 items-center">
                <Button
                  type="button"
                  variant="flush"
                  className="!p-1 h-8 w-8 rounded-md hover:!bg-surface-100"
                  onClick={handleClose}
                >
                  <ArrowLeftIcon className="w-4 h-4" />
                </Button>
                <div className="flex space-x-2 items-center">
                  {sectionIcon && sectionIcon}
                  <Typography token="font-semibold/text/sm">{title}</Typography>
                  {tooltipText && (
                    <Tooltip tooltipClass="text-center" id="tooltip" text={tooltipText} showIcon autoWidth={false} />
                  )}
                </div>
              </div>
              {!badgeDetails.hidePill && showPlanBadge && !settings?.landing_pages && (
                <>
                  {badgeDetails.isUpgrade && <PlanTag text="Upgrade" variant="secondary" Icon={BoltIcon} />}
                  {badgeDetails.isTrial && <PlanTag text="Trial" variant="tertiary" Icon={SparklesIcon} />}
                </>
              )}
            </div>

            {returnToPath && (
              <div className="flex space-x-3 items-center">
                <Link to={returnToPath} className="!p-1 h-5 w-5 rounded-md hover:!bg-surface-100">
                  <ArrowLeftIcon className="w-4 h-4" />
                </Link>
                <div className="flex space-x-2 items-center">
                  <Typography token="font-medium/text/xs">{returnToTitle}</Typography>
                </div>
              </div>
            )}

            <div
              className={cx(
                'bg-white space-y-6 pb-6',
                plan === 'launch' && showPlanBadge && !settings?.landing_pages && 'opacity-40 pointer-events-none'
              )}
            >
              {children}
            </div>
          </div>
        </div>
      </Transition>

      <div>
        {sectionGroupChildren ? (
          <button type="button" onClick={handleOpen}>
            <div className="z-30">{sectionGroupChildren}</div>
          </button>
        ) : (
          <div className="group transition-all">
            <div className="px-3 py-2 border border-surface-200 mb-4 bg-surface-50 rounded flex items-center justify-between">
              <div className="flex space-x-2 items-center">
                {sectionIcon && <div className="pb-0.5">{sectionIcon}</div>}
                <Typography token="font-semibold/text/sm">{title}</Typography>
              </div>
              <Button
                type="button"
                variant="flush"
                className="!p-1 !bg-surface-100 h-8 w-8 rounded-md hover:!bg-surface-200"
                onClick={handleOpen}
              >
                <ChevronRightIcon className="w-4 h-4" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export {
  ArchiveSectionIcon,
  CustomPagesIcon,
  FAQSectionIcon,
  FeaturedPostsSectionIcon,
  FooterSectionIcon,
  HeroSectionIcon,
  NavSectionIcon,
  PostsSectionIcon,
  SectionGroupingSlideOut,
  SideWidgetSectionIcon,
  SubscribeWidgetIcon,
  TestimonialSectionIcon,
};
