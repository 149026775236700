import { Link } from 'react-router-dom';

import { Typography, TypographyStack } from '@/components/Typography';
import { Button } from '@/ui/Button';

import Wizard from '../../_components/Wizard';

const StripeMigrationWizardStepFive = () => {
  return (
    <Wizard step={5}>
      <div className="mt-4 py-4 px-4 sm:px-6 space-y-4">
        <TypographyStack>
          <Typography>
            That wasn&apos;t so bad! Your subscribers should be showing up over the next few minutes. Once you verify
            all is correct, you&apos;ll want to ensure that you pause paid subscriptions on your previous platform so
            that your existing paid subscribers don&apos;t get billed twice next period. If your platform does not have
            the capability to bulk cancel your subscriptions, you may need to reach out to their support.
          </Typography>
          <Typography>
            Once this migration is complete and all of your subscribers are accessible in beehiiv, we highly recommend
            that you remove the restricted API key that you created at the beginning of th migration process.
          </Typography>
        </TypographyStack>
        <div className="flex justify-end">
          <Link target="_blank" to="/subscribers">
            <Button variant="primary-inverse" className="ml-2" type="button">
              Check Subscribers
            </Button>
          </Link>
          <Link to="/stripe_migrations">
            <Button variant="primary" className="ml-2" type="button">
              View All Migrations
            </Button>
          </Link>
        </div>
      </div>
    </Wizard>
  );
};

export default StripeMigrationWizardStepFive;
