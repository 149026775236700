import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';

import Banner from '@/components/Banner';
import { Input } from '@/components/Form';
import { Typography, TypographyRow } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import useResendPublicationChangeConfirmation from '@/hooks/useResendPublicationChangeConfirmation';
import { Publication } from '@/interfaces/publication';
import Switch from '@/ui/Switch';
import analytics from '@/utils/analytics';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import FormContentPreviewContainer from '../../_components/FormContentPreviewContainer';
import FormRow from '../../_components/FormRow';
import { usePublicationSettings } from '../context';

interface Props {
  publication: Publication;
}

const SendingDetailsForm = ({ publication }: Props) => {
  const queryClient = useQueryClient();

  const { handleChangeInAttribute, savingAttribute } = usePublicationSettings();
  const { currentUser } = useCurrentUser();

  const [usePostAuthorNameAsSender, setUsePostAuthorNameAsSender] = useState(
    publication.display_authors_as_post_email_sender_name
  );

  const handleBlur = async (attributeName: keyof Publication, value: any) => {
    if (publication[attributeName] === value) {
      return;
    }

    // Prevent backend updates for the "email_reply_to_address" field when
    // the value of the reply to address field is the same as the unverified value.
    // This field behaves uniquely: after a user updates this address, it must be verified via email.
    // Until verification, the system considers this new address as "pending" and displays it in the input field,
    // while the currently verified address remains displayed as a label below it.
    // This check avoids triggering an update if the user exits the input field without making changes,
    // which could happen as the input field value (potentially the pending address) is compared against
    // the verified address (publication.email_reply_to_address), not against the actual field value sent to the backend.
    if (attributeName === 'email_reply_to_address' && publication.email_reply_to_address_unverified === value) {
      return;
    }

    try {
      await handleChangeInAttribute(attributeName)(value);
      // We invalidate the data because when updating the reply to address
      // a confirmation is required.
      // By invalidating the backend will send a value for `email_reply_to_address_unverified`
      // and the UI will show the desired state with a disabled input for reply to address
      if (attributeName === 'email_reply_to_address') {
        queryClient.invalidateQueries(['publications', publication.id]);
      }
    } catch (error: any) {
      // do nothing
    }
  };

  const { mutateAsync: resendConfirmationEmail } = useResendPublicationChangeConfirmation(publication.id);

  const hasUnverifiedReplyToAddress = !!publication?.email_reply_to_address_unverified;

  return (
    <BodyContainer>
      <CardHeader title="Email Sending Details" description="Configure your email sending information" />
      <FormRow label="Use Post's Author Name as Sender Name">
        <Switch
          variant="primary"
          checked={usePostAuthorNameAsSender}
          name="display_authors_as_post_email_sender_name"
          onChange={async (_name: string, value: boolean) => {
            try {
              setUsePostAuthorNameAsSender(value);
              analytics.track('Use author as sender name for posts');
              await handleChangeInAttribute('display_authors_as_post_email_sender_name')(value);
            } catch (error: any) {
              setUsePostAuthorNameAsSender(!value);
            }
          }}
          disabled={savingAttribute === 'display_authors_as_post_email_sender_name'}
        />
      </FormRow>
      <Input
        labelText="Sender Name"
        helperText="This is who subscribers will see the email is from in their inbox. Typically the name of your newsletter or the author. This should NOT be an email address."
        placeholderText="Newsletter or author name"
        name="email_sender_name"
        disabled={publication.display_authors_as_post_email_sender_name || savingAttribute === 'email_sender_name'}
        defaultValue={publication.email_sender_name}
        onBlur={(e) => handleBlur('email_sender_name', e.target.value.trim())}
      />
      {!publication.display_authors_as_post_email_sender_name ? (
        <FormContentPreviewContainer className="flex flex-row gap-x-6 items-center">
          <Typography token="font-normal/text/sm" colorWeight="500">
            From:{' '}
          </Typography>
          <TypographyRow>
            {publication.email_sender_name ? (
              <Typography token="font-medium/text/sm">{publication.email_sender_name} </Typography>
            ) : null}
            <Typography token="font-normal/text/sm" colorWeight="500">
              {`<${publication.email_from_address}>`}
            </Typography>
          </TypographyRow>
        </FormContentPreviewContainer>
      ) : null}
      {hasUnverifiedReplyToAddress ? (
        <Banner
          variant="warning"
          title="Please verify the Reply To email address"
          bodyText="We have sent you an email to the address provided below, please verify the email to apply the change."
          isScreenWide={false}
          ctaText="Resend Email"
          onClick={() => resendConfirmationEmail({ field: 'email_reply_to_address' })}
        />
      ) : null}
      <Input
        labelText="Reply To Address"
        helperText="Replies to all emails will be sent to this address."
        name="email_reply_to_address"
        placeholder={publication.email_from_address}
        defaultValue={
          hasUnverifiedReplyToAddress
            ? publication.email_reply_to_address_unverified
            : publication.email_reply_to_address
        }
        onBlur={(e) => {
          const newValue = e.target.value.trim();

          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
          });
          analytics.group(publication.id, {
            email_reply_to_address: newValue,
            object_type_id: 1,
          });
          analytics.track('Set up Reply to Address');
          handleBlur('email_reply_to_address', newValue);
        }}
      />
      <FormContentPreviewContainer className="flex flex-row gap-x-6 items-center">
        <span className="inline-flex gap-x-2 items-center">
          <ArrowUturnLeftIcon className="w-4 h-4 text-surface-500" />
          <Typography token="font-normal/text/sm" colorWeight="500">
            Reply-to:{' '}
          </Typography>
        </span>
        <TypographyRow>
          <Typography token="font-medium/text/sm">{publication.email_sender_name} </Typography>
          <Typography token="font-normal/text/sm" colorWeight="500">
            {`<${publication.email_reply_to_address || publication.email_from_address}>`}
          </Typography>
        </TypographyRow>
      </FormContentPreviewContainer>
    </BodyContainer>
  );
};

export default SendingDetailsForm;
