import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Tippy from '@tippyjs/react/headless';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment-mini';
import { Placement } from 'tippy.js';

import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { SearchList, SearchListItemProps } from '../../../components/ui/SearchList';
import { API } from '../../../lib/api';
import { debounce } from '../../../lib/utils/debounce';

import { Styled } from './BoostView.styled';

export interface TippyProps {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

export const BoostView = ({
  editor,
  node,
  updateAttributes,
  extension: {
    options: { publicationId },
  },
}: NodeViewProps) => {
  const boostedRecommendationId = useMemo(() => node.attrs.id, [node.attrs.id]);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState<any>(undefined);
  const [selectionMenuOpen, setSelectionMenuOpen] = useState(node.attrs.showOptionsInitially ?? false);
  const [boostSendId, setBoostSendId] = useState(node.attrs.boostSendId ?? null);
  const [searchQuery, setSearchQuery] = useState('');
  const hasNoResults = options.length === 0 && searchQuery === '';

  const formatOptions = (newOptions: any) => {
    const mappedOptions = newOptions.map((option: any) => {
      return {
        id: option.id,
        name: `${option.name} - Send by ${moment(option.send_by).format('L h:mm:ss a')}`,
      };
    });

    return mappedOptions;
  };

  useEffect(() => {
    updateAttributes({ ...node.attrs, boostSendId });
  }, [boostSendId, node.attrs, updateAttributes]);

  const hasMore = useRef(false);
  const page = useRef(1);

  useEffect(() => {
    if (node.attrs.showOptionsInitially) {
      updateAttributes({
        ...node.attrs,
        showOptionsInitially: false,
      });
    }
  }, [updateAttributes, node.attrs]);

  const onSearch = useCallback(
    (q: string) => {
      setSearchQuery(q);

      API.getBoostedRecommendationOptions({
        publicationId,
        page: 1,
        q,
      })
        .then((res: AxiosResponse) => {
          page.current = res.data.pagination.page;
          hasMore.current = page.current < res.data.pagination.total_pages;

          setOptions(formatOptions(res.data.options));
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId]
  );
  const debounceOnSearch = debounce(onSearch, 200);

  const loadNextPage = useCallback(
    (q: string) => {
      API.getBoostedRecommendationOptions({
        publicationId,
        page: page.current + 1,
        q,
      })
        .then((res: AxiosResponse) => {
          page.current = res.data.pagination.page;
          hasMore.current = page.current < res.data.pagination.total_pages;

          setOptions([...options, ...formatOptions(res.data.options)] as any);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId, options]
  );

  useEffect(() => {
    if (boostedRecommendationId) {
      API.getBoostedRecommendation({
        publicationId,
        recommendationId: boostedRecommendationId,
      })
        .then((res: AxiosResponse) => {
          setBoostSendId(res.data.boost_send_id);
          setData(res.data.recommended_publication);
        })
        .catch((errPayload: AxiosError) => {
          if (errPayload.code === '404') {
            toast.error('Could not find Recommendation. Please add another one using the "Select a Boost" button');
          } else {
            const error = errPayload?.response?.data?.error || 'Something went wrong';
            toast.error(error);
          }
        });
    } else {
      API.getBoostedRecommendationOptions({
        publicationId,
        page: page.current,
      })
        .then((res: AxiosResponse) => {
          page.current = res.data.pagination.page;
          hasMore.current = page.current < res.data.pagination.total_pages;

          setOptions(formatOptions(res.data.options));
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    }
  }, [publicationId, boostedRecommendationId]);

  return (
    <NodeViewWrapper
      data-drag-handle
      data-id={boostedRecommendationId}
      data-boost-send-id={boostSendId}
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <div {...{ inert: editor.isEditable ? undefined : '' }}>
        {data ? (
          <Styled.DataContainer>
            <img src={data.logo_url} alt="" />
            <Styled.TextContainer>
              <Styled.SponsoredBanner>Sponsored</Styled.SponsoredBanner>
              <Styled.Headline>{data.name}</Styled.Headline>
              <Styled.Paragraph>{data.description}</Styled.Paragraph>
              <Styled.Button href={data.hostname} target="_blank" className="content-element-button">
                Subscribe
              </Styled.Button>
            </Styled.TextContainer>
          </Styled.DataContainer>
        ) : (
          <Styled.SelectionContainer>
            <Tippy
              offset={[0, 8]}
              placement="bottom"
              interactive
              visible={selectionMenuOpen}
              onShow={() => {
                setSelectionMenuOpen(true);
              }}
              onHidden={() => {
                setSelectionMenuOpen(false);
              }}
              onClickOutside={() => {
                setSelectionMenuOpen(false);
              }}
              render={(attrs: TippyProps) =>
                selectionMenuOpen ? (
                  <SearchList
                    tabIndex={-1}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...attrs}
                    items={options.map((option: any) => {
                      return {
                        value: option.id,
                        label: option.name,
                      };
                    })}
                    hasMore={hasMore.current}
                    onLoadMore={(q: string) => {
                      loadNextPage(q);
                    }}
                    searchable
                    searchPlaceholder="Search a Boost"
                    onSearch={(q: string) => {
                      debounceOnSearch(q);
                    }}
                    onSelect={(item: SearchListItemProps) => {
                      editor.chain().focus().updateAttributes('boost', { id: item.value }).run();
                    }}
                    onBack={() => {
                      setSelectionMenuOpen(false);
                    }}
                    footer={
                      <div className="space-y-4">
                        {hasNoResults && (
                          <div className="flex items-center justify-center text-center">
                            <span className="text-xs italic">
                              It looks like you don&apos;t have any Email Boosts to add to your posts right now
                            </span>
                          </div>
                        )}
                        <Button
                          $variant="quaternary"
                          $size="small"
                          $rightSlot={<Icon name="Plus" />}
                          $fullWidth
                          as="a"
                          href="/monetize/boosts"
                          target="_blank"
                        >
                          Add Email Boost
                        </Button>
                      </div>
                    }
                  />
                ) : null
              }
            >
              <Button
                $variant="secondary"
                $size="small"
                $active={selectionMenuOpen}
                $leftSlot={<Icon name="Boost" />}
                onClick={() => {
                  setSelectionMenuOpen(!selectionMenuOpen);
                }}
              >
                Select a Boost
              </Button>
            </Tippy>
          </Styled.SelectionContainer>
        )}
      </div>
    </NodeViewWrapper>
  );
};
