import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useWebTemplate } from '@/hooks';
import { useNavGroups } from '@/hooks/useNavGroups';
import { NavGroup } from '@/interfaces/nav_group';
import { WebTemplate } from '@/interfaces/web_template';
import TopNavActions from '@/routes/website_builder/custom_pages/_components/TopNavActions';

import { PageProvider } from '../../_components/Context/PageContext';
import { CustomPagesIcon, SectionGroupingSlideOut } from '../../_components/Helpers/SectionGroupingSlideOut';
import { SettingsNote, SettingsNoteDescription } from '../../_components/Helpers/SettingsNote';
import Layout from '../../_components/Layout';

import CustomPagesSection from './CustomPagesSection';

export interface CustomPageData {
  navGroups: NavGroup[] | undefined;
  webTemplate: WebTemplate | undefined;
}

const WebBuilderCustomPagesView = () => {
  const navigate = useNavigate();

  const navGroupsQuery = useNavGroups();
  const { data: navGroups } = navGroupsQuery;

  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: UseQueryResult<WebTemplate, Error> = webTemplateRequest;

  return (
    <PageProvider<CustomPageData>
      pageData={{
        navGroups,
        webTemplate,
      }}
    >
      <Layout
        removePadding
        onPublish={() => {}}
        sidePanelChildren={
          <div className="pt-6">
            <SectionGroupingSlideOut
              title="Custom Pages"
              showPlanBadge
              sectionIcon={<CustomPagesIcon />}
              alwaysOpen
              onClose={() => navigate('/website_builder?tab=layout&page=home_page&sectionId=global-navigation')}
            >
              <SettingsNote label="Custom Pages">
                <SettingsNoteDescription>
                  See an overview of all your custom pages and manage their settings. Add an internal page, or an
                  external link, and then return back to the Home Page view to better visualize adding these pages into
                  your navigation menu.
                </SettingsNoteDescription>
              </SettingsNote>
            </SectionGroupingSlideOut>
          </div>
        }
        topNavChildren={<TopNavActions showBackButton={false} showSaveActions={false} />}
      >
        <div className="mx-auto max-w-5xl pt-2 p-8">
          <CustomPagesSection navGroups={navGroups} />
        </div>
      </Layout>
    </PageProvider>
  );
};

export default WebBuilderCustomPagesView;
