import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

interface Props {
  pageId: string;
  onSuccess: (response: any) => void;
}

const usePublishLandingPage = ({ onSuccess, pageId }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    () =>
      api.post(`/landing_pages/${pageId}/publish`, {
        publication_id: currentPublication?.id,
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'There was an issue publishing this Landing Page.');
      },
    }
  );
};

export default usePublishLandingPage;
