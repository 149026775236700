import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const Textarea = styled.textarea`
  background-color: ${colors.white[8]};
  border: 0;
  border-radius: 0.5rem;
  caret-color: ${colors.pink[6]};
  color: ${colors.white[1]};
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  height: 4.5rem;
  padding: 0.375rem 0.5rem;
  width: 100%;

  .tiptap & {
    line-height: 1.4;
  }

  &:hover {
    background-color: ${colors.white[7]};
  }

  &:focus,
  &:active {
    background-color: transparent;
    outline: 1px solid #ff67b7;
    outline-offset: 0;
  }
`;

export default Textarea;
