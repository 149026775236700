import { SendingMetrics } from '@/interfaces/boosts/sending_metrics';

const useFormattedSendingMetrics = (sendingMetrics?: SendingMetrics) => {
  const openRate = sendingMetrics?.open_rate ? `${sendingMetrics.open_rate}%` : '-';
  const clickThroughRate = sendingMetrics?.click_through_rate ? `${sendingMetrics.click_through_rate}%` : '-';
  const unsubscribeRate = sendingMetrics?.unsubscribe_rate ? `${sendingMetrics.unsubscribe_rate}%` : '-';

  return {
    openRate,
    clickThroughRate,
    unsubscribeRate,
  };
};

export default useFormattedSendingMetrics;
