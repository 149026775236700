import { DragEvent, DragEventHandler, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import BusySkeletonNode from '@/components/BusySkeletonNode';

import { FORM_BUILDER_EDGE_WIDTH_CLASSNAME } from './helpers';

interface Props {
  sortingQuestionId?: string | null;
  labelX: number;
  labelY: number;
  onReorder: (questionId: string) => Promise<void>;
}

const Dropzone = ({ labelX, labelY, sortingQuestionId, onReorder }: Props) => {
  const [isDropzoneActive, setDropzoneActive] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState(false);

  const handleReorder = async (questionId: string) => {
    setIsBusy(true);

    await onReorder(questionId);

    setIsBusy(false);
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setDropzoneActive(false);
    if (sortingQuestionId) {
      handleReorder(sortingQuestionId);
    }
  };

  const handleDragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
  };

  const handleDragEnter = () => {
    setDropzoneActive(true);
  };

  const handleDragLeave = () => {
    setDropzoneActive(false);
  };

  return (
    <div
      style={{ transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)` }}
      className={cx(
        'absolute pointer-events-auto',
        'flex justify-center items-center',
        FORM_BUILDER_EDGE_WIDTH_CLASSNAME
      )}
    >
      <div
        className="relative w-full h-24 flex justify-center items-center"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {isBusy && <BusySkeletonNode />}
        {isDropzoneActive && (
          <div className="absolute flex justify-center items-center inset-0 pointer-events-none">
            <div className="w-full h-0.5 bg-primary-500 absolute" />
            <div
              className={cx(
                'w-4 h-4 mb-0.5 z-0 rounded-full shadow-md flex items-center justify-center bg-primary-500 text-white'
              )}
            >
              <PlusIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
