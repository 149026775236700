import { useInfiniteQuery } from 'react-query';

import { BoostPublicationWithBoostAgreement } from '@/interfaces/boosts/monetize/boost_publication_with_boost_agreement';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  publications: BoostPublicationWithBoostAgreement[];
  pagination: Pagination;
}

interface Props {
  publicationId?: string;
  search?: string;
  statusFilter?: string;
  perPage?: number;
  isEnabled?: boolean;
  orderBy?: string;
  direction?: string;
}

const useBoostedPublications = ({
  publicationId,
  perPage = 10,
  search,
  statusFilter,
  orderBy,
  direction,
  isEnabled = true,
}: Props) => {
  const fetch = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/boosts/monetize/boosted_publications`, {
        params: {
          page: pageParam,
          per_page: perPage,
          publication_id: publicationId,
          q: !search ? null : search,
          status: !statusFilter ? null : statusFilter,
          order_by: orderBy,
          direction,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(
    [publicationId, 'boosts', 'monetize', 'boosted_publications', search, statusFilter, orderBy, direction],
    fetch,
    {
      enabled: isEnabled,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
};

export default useBoostedPublications;
