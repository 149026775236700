import React from 'react';

import { cn } from '../../../../_utils/cn';
import { Text } from '../../../UI/Text';

interface Props {
  title: string;
  value: number | string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rightElement?: React.ReactNode;
  rightElementClassName?: string;
}

const AttributeInput: React.FC<Props> = ({
  title,
  value,
  onChange,
  rightElement,
  rightElementClassName = 'absolute top-1/2 right-3 -translate-y-1/2',
}) => {
  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm w-[120px]">
        <div className="relative h-full">
          <input
            className="bg-wb-secondary rounded-lg border-none p-2 text-xs w-full"
            type={typeof value === 'number' ? 'number' : 'text'}
            value={value}
            min={0}
            onChange={onChange}
          />
          {rightElement && <div className={cn(rightElementClassName)}>{rightElement}</div>}
        </div>
      </div>
    </div>
  );
};

export default AttributeInput;
