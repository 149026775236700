import { useCallback, useState } from 'react';

import { Slider } from '../../../UI/Slider';
import { Text } from '../../../UI/Text';
import { AttributeSettingProps } from '../types';

type SliderSettingsProps = AttributeSettingProps<number> & {
  property: string;
  title: string;
  min?: number;
  max?: number;
  unit?: string;
  step?: number;
  type?: 'number' | 'string';
};

const parseWidth = (width?: string | number, unit?: string) => {
  if (!width) return 0;
  if (typeof width === 'string') {
    // remove % and parse to number
    return parseInt(width.replace(unit ?? '%', ''), 10);
  }
  return width;
};

export const SliderSettings = ({
  editor,
  activeNodeResult,
  property,
  title,
  min,
  max,
  unit,
  step = 1,
  type = 'string',
  onChange,
}: SliderSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const [inputValue, setInputValue] = useState<string>(
    activeNodeAttributes?.[property] !== undefined ? String(parseWidth(activeNodeAttributes?.[property], unit)) : ''
  );

  const handleSliderChange = useCallback(
    (value: number) => {
      if (activeNodePos === undefined || !activeNodeType) return;

      if (type === 'number') {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, property, value);
          return true;
        });
      } else {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, property, `${value}${unit ?? '%'}`);
          return true;
        });
      }

      setInputValue(String(value));
      onChange?.(value);
    },
    [activeNodePos, activeNodeType, property, editor, unit, onChange, type]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);

    const numValue = Number(value);
    if (!Number.isNaN(numValue) && value.trim() !== '') {
      handleSliderChange(numValue);
    }
  };

  const handleInputBlur = () => {
    const numValue = Number(inputValue);

    if (inputValue.trim() === '') {
      if (min !== undefined) {
        handleSliderChange(min);
      } else {
        handleSliderChange(0);
      }
    } else if (!Number.isNaN(numValue)) {
      let finalValue = numValue;

      if (max !== undefined) {
        finalValue = Math.min(finalValue, max);
      }

      if (min !== undefined) {
        finalValue = Math.max(finalValue, min);
      }

      handleSliderChange(finalValue);
      setInputValue(String(finalValue));
    }
  };

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px] min-w-[80px] flex items-center gap-1" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow flex items-center h-[32px] gap-2">
        <div className="relative h-full">
          <input
            className="bg-wb-secondary rounded-lg border-none p-2 text-xs w-full"
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            min={0}
          />
          {unit && (
            <Text size="2xs" weight="medium" className="absolute top-1/2 right-3 -translate-y-1/2" variant="secondary">
              {unit}
            </Text>
          )}
        </div>
        <Slider
          defaultValue={[parseWidth(inputValue, unit)]}
          onValueChange={(value) => {
            handleSliderChange(value[0]);
          }}
          min={min}
          max={max}
          step={step}
        />
      </div>
    </div>
  );
};
