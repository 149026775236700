const isValidUrlWithProtocol = (url: string) => {
  /* eslint-disable no-new */
  if (!url.startsWith('http://') && !url.startsWith('https://')) return false;

  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export default isValidUrlWithProtocol;
