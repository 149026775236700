import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface BoostsFrozenResponse {
  boosts_frozen: boolean;
}

const useBoostsFrozen = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchBoostsFrozen = (): Promise<BoostsFrozenResponse> =>
    api
      .get(`/boosts/frozen`, {
        params: { publication_id: currentPublicationId },
      })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'frozen'], fetchBoostsFrozen);
};

export default useBoostsFrozen;
