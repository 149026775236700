import { useEffect, useState } from 'react';
import { Info } from '@phosphor-icons/react';

import { useUpdatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { Switch } from '../UI/Switch';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

interface Props {
  title: string;
  usesDreamBuilderSite?: boolean;
}

const SiteToggle = ({ title, usesDreamBuilderSite = false }: Props) => {
  const [isActive, setIsActive] = useState(usesDreamBuilderSite);

  useEffect(() => {
    setIsActive(usesDreamBuilderSite);
  }, [usesDreamBuilderSite]);

  const currentPublicationId = useCurrentPublicationId();
  const publicationMutation = useUpdatePublication(currentPublicationId);

  const handleToggle = () => {
    publicationMutation.mutate({ uses_dream_builder_site: !isActive });
    setIsActive(!isActive);
  };

  return (
    <div className="flex gap-2 border-b border-wb-primary p-4 w-full">
      <div className="flex w-full justify-between gap-2">
        <div>
          <div className="flex items-center space-x-2">
            <div
              className={`h-1.5 w-1.5 text-xs font-medium rounded-full mt-0.5 ${
                isActive ? 'bg-wb-success animate-pulse' : 'bg-gray-400'
              }`}
            />
            <div className="flex items-center gap-1">
              <Text size="xs" weight="medium" variant="secondary">
                {title}
              </Text>
              <Tooltip center="Enable to use this site. Disbale to revert to the old site." delay={300}>
                <Info className="text-wb-secondary w-4 h-4" />
              </Tooltip>
            </div>
          </div>
        </div>

        <Switch checked={isActive} onCheckedChange={handleToggle} />
      </div>
    </div>
  );
};

export default SiteToggle;
