import { PriceInterval, Tier, TierStatus } from '@shared/dream-components';

const tiers: Tier[] = [
  {
    id: 'silver-tier',
    description: 'Access to basic features and exclusive updates.',
    status: TierStatus.ACTIVE,
    name: 'Silver Tier',
    publication_id: 'pub-001',
    is_default: true,
    redirect_url: 'https://example.com/silver-tier',
    enabled: true,
    prices: [
      {
        id: 'silver-monthly',
        cta: 'Subscribe Now',
        currency: 'usd',
        amount_cents: 5000,
        original_amount_cents: 5500,
        interval: PriceInterval.MONTH,
        interval_display: 'monthly',
        enabled: true,
        denominator: 'month',
        features: [
          'Access to monthly content',
          'Exclusive newsletter',
          'Priority email support',
          'Monthly insights report',
        ],
      },
      {
        id: 'silver-yearly',
        cta: 'Save with Annual Plan',
        currency: 'usd',
        amount_cents: 54000,
        original_amount_cents: 60000,
        interval: PriceInterval.YEAR,
        interval_display: 'yearly',
        enabled: true,
        denominator: 'year',
        features: [
          'Everything in monthly plan',
          '10% off yearly price',
          'Annual subscriber badge',
          'Early access to new features',
        ],
      },
    ],
  },
  {
    id: 'gold-tier',
    description: 'Enhanced features for premium users.',
    status: TierStatus.ACTIVE,
    name: 'Gold Tier',
    publication_id: 'pub-002',
    is_default: false,
    redirect_url: 'https://example.com/gold-tier',
    enabled: true,
    prices: [
      {
        id: 'gold-monthly',
        cta: 'Upgrade to Gold',
        currency: 'usd',
        amount_cents: 10000,
        original_amount_cents: 12000,
        interval: PriceInterval.MONTH,
        interval_display: 'monthly',
        enabled: true,
        denominator: 'month',
        features: ['Priority support', 'Exclusive resources', 'Invitations to webinars', 'Advanced analytics access'],
      },
      {
        id: 'gold-yearly',
        cta: 'Best Value: Annual Plan',
        currency: 'usd',
        amount_cents: 108000,
        original_amount_cents: 120000,
        interval: PriceInterval.YEAR,
        interval_display: 'yearly',
        enabled: true,
        denominator: 'year',
        features: [
          'All monthly benefits',
          'Free gift with subscription',
          'Dedicated onboarding assistance',
          'Annual performance review session',
        ],
      },
    ],
  },
  {
    id: 'platinum-tier',
    description: 'Top-tier access with maximum benefits.',
    status: TierStatus.ARCHIVED,
    name: 'Platinum Tier',
    publication_id: 'pub-003',
    is_default: false,
    redirect_url: 'https://example.com/platinum-tier',
    enabled: false,
    prices: [
      {
        id: 'platinum-monthly',
        cta: 'Join Platinum',
        currency: 'usd',
        amount_cents: 20000,
        original_amount_cents: 25000,
        interval: PriceInterval.MONTH,
        interval_display: 'monthly',
        enabled: true,
        denominator: 'month',
        features: ['Dedicated account manager', 'Exclusive events', '24/7 support hotline', 'VIP community access'],
      },
      {
        id: 'platinum-yearly',
        cta: 'Annual Platinum Membership',
        currency: 'usd',
        amount_cents: 216000,
        original_amount_cents: 240000,
        interval: PriceInterval.YEAR,
        interval_display: 'yearly',
        enabled: true,
        denominator: 'year',
        features: [
          'All monthly perks',
          'One-on-one consultation',
          'Custom performance insights',
          'Invitation to executive meetups',
        ],
      },
    ],
  },
];

export { tiers };
