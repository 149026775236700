import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';

export type StepErrors = {
  url?: string;
  event_types?: string;
};

export type StepProps = {
  endpoint: Partial<WebhookEndpoint>;
  errors: StepErrors | null;
  onChange?: (args: Partial<WebhookEndpoint>) => void;
};

export enum WizardSteps {
  DETAILS = 1,
  EVENTS = 2,
  REVIEW = 3,
}
