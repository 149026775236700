interface Props {
  className?: string;
}

const EnrollInAutomation = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_4187)">
      <mask
        id="mask0_1_4187"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <path d="M14 0H0V14H14V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1_4187)">
        <path
          d="M5.05556 3.49999C5.05556 4.01568 5.26042 4.51027 5.62507 4.87492C5.98972 5.23957 6.4843 5.44443 7 5.44443C7.5157 5.44443 8.01026 5.23957 8.37496 4.87492C8.73958 4.51027 8.94445 4.01568 8.94445 3.49999C8.94445 2.98429 8.73958 2.48971 8.37496 2.12505C8.01026 1.7604 7.5157 1.55554 7 1.55554C6.4843 1.55554 5.98972 1.7604 5.62507 2.12505C5.26042 2.48971 5.05556 2.98429 5.05556 3.49999Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.38889 12.4444H3.11111V11.6667C3.11111 9.5189 4.85223 7.77777 7 7.77777C7.58293 7.77777 8.13587 7.90603 8.63225 8.13586"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.889 8.68903V13.0888"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.68903 10.8889H13.0888"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_4187">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EnrollInAutomation;
