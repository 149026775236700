import cx from 'classnames';

import { SvgLogo } from '@/components/SvgLogo';

interface Props {
  size?: 'sm' | 'md' | 'lg';
}

const ICON_SIZE_MAP = Object.freeze({
  sm: 'w-12 h-12',
  md: 'w-16 h-16',
  lg: 'w-20 h-20',
});

const BlackAndWhiteLogo = ({ size }: Props) => {
  return (
    <div className={cx('bg-black rounded p-3', ICON_SIZE_MAP[size || 'sm'])}>
      <SvgLogo stopColorOverride="#fff" />
    </div>
  );
};

export default BlackAndWhiteLogo;
