import React from 'react';
import { NavbarSerializableNode, NavbarSerializer } from '@shared/dream-components';

import { useIsMobile } from './utils';

export const SimpleNavPreviewer = ({
  portalRef,
  iframeRef,
  content,
}: {
  portalRef: React.RefObject<HTMLDivElement>;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  content?: NavbarSerializableNode;
}) => {
  const isMobile = useIsMobile(iframeRef);

  return (
    <div className="h-fit w-full flex flex-col justify-start items-center">
      {content && (
        <NavbarSerializer
          node={content as NavbarSerializableNode}
          portalRef={portalRef}
          screen={isMobile ? 'mobile' : 'desktop'}
        />
      )}
    </div>
  );
};
