export interface BadgeProps {
  text: string;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  size?: BadgeSize;
  type?: BadgeType;
}

export enum BadgeType {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
  DEFAULT_LINED = 'default_lined',
  DEFAULT_FILLED = 'default_filled',
  TERTIARY_LINED = 'tertiary_lined',
}

export enum BadgeSize {
  SM = 'sm',
  BASE = 'base',
}
