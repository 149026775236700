import { FC } from 'react';

import ColorField from '../../components/Form/ColorField';

import ExpandableSlider from './components/ExpandableSlider';
import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { LineHeight, LineHeights, ThemeFormProps } from './types';

const BlockquoteForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette, fonts }) => {
  return (
    <FormContainer>
      <FormSection title="Font Settings">
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.quote_font_family}
              onUpdate={(val) => onUpdate({ ...theme, quote_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Text alignment">
          <ThemeEditorDropdown
            currentValue={theme.quote_alignment}
            onUpdate={(val: string) => onUpdate({ ...theme, quote_alignment: val })}
            options={[
              { label: 'Left', value: 'left' },
              { label: 'Center', value: 'center' },
              { label: 'Right', value: 'right' },
            ]}
          />
        </FormInput>
        <FormInput title="Line height">
          <ThemeEditorDropdown
            currentValue={theme.quote_line_height_size}
            onUpdate={(val) => onUpdate({ ...theme, quote_line_height_size: val as LineHeight })}
            options={[
              { label: LineHeights.Less, value: LineHeights.Less },
              { label: LineHeights.Normal, value: LineHeights.Normal },
              { label: LineHeights.More, value: LineHeights.More },
            ]}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="quote_font_size"
            label="Font size"
            onChange={(val: number) => onUpdate({ ...theme, quote_font_size: val.toString() })}
            initialValue={theme.quote_font_size}
            min={12}
            max={32}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.quote_font_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, quote_font_color: color?.hexString })}
          />
        </FormInput>
        <FormInput title="Background color">
          <ColorField
            placement="bottom"
            color={theme.quote_background_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, quote_background_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Border">
        <FormInput>
          <Slider
            name="quote_border_radius"
            label="Curve radius"
            onChange={(val: number) => onUpdate({ ...theme, quote_border_radius: val.toString() })}
            initialValue={theme.quote_border_radius}
            min={0}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="quote_border_width"
            label="Border thickness"
            onChange={(val: number) => onUpdate({ ...theme, quote_border_width: val.toString() })}
            initialValue={theme.quote_border_width}
            min={0}
            max={5}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Border color">
          <ColorField
            placement="bottom"
            color={theme.quote_border_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, quote_border_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Spacing">
        <FormInput>
          <ExpandableSlider
            fields={{
              quote_margin_top: 'Top',
              quote_margin_right: 'Right',
              quote_margin_bottom: 'Bottom',
              quote_margin_left: 'Left',
            }}
            theme={theme}
            onUpdate={onUpdate}
            min={0}
            max={80}
            title="Outer"
          />
        </FormInput>
        <FormInput>
          <ExpandableSlider
            fields={{
              quote_padding_top: 'Top',
              quote_padding_right: 'Right',
              quote_padding_bottom: 'Bottom',
              quote_padding_left: 'Left',
            }}
            theme={theme}
            onUpdate={onUpdate}
            min={0}
            max={80}
            title="Inner"
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Quote Byline">
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.quote_byline_font_family}
              onUpdate={(val) => onUpdate({ ...theme, quote_byline_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Text alignment">
          <ThemeEditorDropdown
            currentValue={theme.quote_byline_alignment}
            onUpdate={(val: string) => onUpdate({ ...theme, quote_byline_alignment: val })}
            options={[
              { label: 'Left', value: 'left' },
              { label: 'Center', value: 'center' },
              { label: 'Right', value: 'right' },
            ]}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="quote_byline_spacing"
            label="Spacing"
            onChange={(val: number) => onUpdate({ ...theme, quote_byline_spacing: val.toString() })}
            initialValue={theme.quote_byline_spacing}
            min={0}
            max={36}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="quote_byline_font_size"
            label="Font size"
            onChange={(val: number) => onUpdate({ ...theme, quote_byline_font_size: val.toString() })}
            initialValue={theme.quote_byline_font_size}
            min={12}
            max={32}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.quote_byline_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, quote_byline_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default BlockquoteForm;
