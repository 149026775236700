import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

import { Button } from '@/ui/Button';

import ActionModal from '../../../../components/ActionModal';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
// Contexts
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { Pagination } from '../../../../interfaces/general';
// Interfaces
import { Reward } from '../../../../interfaces/reward';
// Services
import api from '../../../../services/swarm';
// Components
import ConfigureContainer from '../ConfigureContainer';
import { FormContextProvider } from '../FormContext';
import PromoCodesSlideOver from '../PromoCodesSlideOver';
import RewardSlideOver from '../RewardSlideOver';

import Table from './Table';

export default function ConfigureRewardsIndex() {
  const [page, setPage] = useQueryParam<number>('page', withDefault(NumberParam, 1));
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRewardsFormOpen, setIsRewardsFormOpen] = useState(false);
  const [editRewardId, setEditRewardId] = useState('');
  const [deleteRewardId, setDeleteRewardId] = useState('');
  const [promoCodeRewardId, setPromoCodeRewardId] = useState('');
  const [rewards, setRewards] = useState<Reward[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchRewards = (pageNum: number) => {
    const params = {
      publication_id: currentPublicationId,
      page: Number.isNaN(pageNum) ? 1 : pageNum,
    };

    setIsLoading(true);
    api
      .get('/referral_program/rewards', { params })
      .then((res) => {
        setRewards(res.data?.rewards || []);
        setPagination(res.data?.pagination || {});
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (currentPublicationId) {
      fetchRewards(page);
    }
  }, [currentPublicationId, page]);
  /* eslint-enable */

  const onAddReward = () => {
    setIsRewardsFormOpen(true);
  };

  const onNewModalClose = () => {
    setEditRewardId('');
    setIsRewardsFormOpen(false);
  };

  const onNewModalSuccess = () => {
    setEditRewardId('');
    setIsRewardsFormOpen(false);
    fetchRewards(1);
  };

  const onEditReward = (rewardId: string) => {
    setEditRewardId(rewardId);
  };

  const onDeleteRewardRequested = (rewardId: string) => {
    setDeleteRewardId(rewardId);
  };

  const onActionModalClose = () => {
    setDeleteRewardId('');
  };

  const onDeleteReward = (rewardId: string) => {
    const params = {
      publication_id: currentPublicationId,
    };

    setIsDeleting(true);
    api
      .delete(`/referral_program/rewards/${rewardId}`, { params })
      .then(() => {
        setDeleteRewardId('');
        fetchRewards(1);
        toast.success('Reward deleted successfully');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const onPromoCodesSelected = (rewardId: string) => {
    setPromoCodeRewardId(rewardId);
  };

  const onPromoCodeModalClose = () => {
    setPromoCodeRewardId('');
  };

  const onPageSelected = (targetPage: number) => {
    setPage(targetPage);
  };

  return (
    <ConfigureContainer selectedTab="rewards">
      <div>
        <FormContextProvider rewardId={editRewardId}>
          <RewardSlideOver
            isOpen={isRewardsFormOpen || editRewardId !== ''}
            onClose={onNewModalClose}
            onSuccess={onNewModalSuccess}
            publicationId={currentPublicationId}
          />
        </FormContextProvider>
        <PromoCodesSlideOver
          isOpen={promoCodeRewardId !== ''}
          onClose={onPromoCodeModalClose}
          publicationId={currentPublicationId}
          rewardId={promoCodeRewardId}
        />
        <ActionModal
          isOpen={deleteRewardId !== ''}
          onClose={onActionModalClose}
          onProceed={onDeleteReward}
          resourceId={deleteRewardId}
          isWorking={isDeleting}
          headerText="Delete reward"
          actionText="Delete"
        >
          Are you sure you want to delete this reward? (A reward can only be deleted if its not associated with any
          active milestones)
        </ActionModal>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 inline-flex">
                  Rewards {isLoading && <LoadingSpinner className="ml-2" />}
                </h3>
                <p className="mt-1 text-sm text-gray-500">All of your rewards that are usable by milestones</p>
              </div>
              <div>
                <Button onClick={() => onAddReward()}>Add Reward</Button>
              </div>
            </div>
            <div>
              <Table
                rewards={rewards}
                pagination={pagination}
                onEditSelected={onEditReward}
                onPageSelected={onPageSelected}
                onDeleteSelected={onDeleteRewardRequested}
                onPromoCodesSelected={onPromoCodesSelected}
              />
            </div>
          </div>
        </div>
      </div>
    </ConfigureContainer>
  );
}
