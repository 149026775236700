import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Tippy from '@tippyjs/react/headless';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { AxiosError, AxiosResponse } from 'axios';
import { Placement } from 'tippy.js';

import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { SearchList, SearchListItemProps } from '../../../components/ui/SearchList';
import { API } from '../../../lib/api';
import { debounce } from '../../../lib/utils/debounce';

import { Styled } from './RecommendationView.styled';

export interface TippyProps {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

export const RecommendationView = ({
  editor,
  node,
  updateAttributes,
  extension: {
    options: { publicationId },
  },
}: NodeViewProps) => {
  const recommendationId = useMemo(() => node.attrs.id, [node.attrs.id]);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState<any>(undefined);
  const [selectionMenuOpen, setSelectionMenuOpen] = useState(node.attrs.showOptionsInitially ?? false);

  const hasMore = useRef(false);
  const page = useRef(1);

  useEffect(() => {
    if (node.attrs.showOptionsInitially) {
      updateAttributes({
        showOptionsInitially: false,
      });
    }
  }, [updateAttributes, node.attrs.showOptionsInitially]);

  const onSearch = useCallback(
    (q: string) => {
      API.getRecommendationsOptions({
        publicationId,
        page: 1,
        q,
      })
        .then((res: AxiosResponse) => {
          page.current = res.data.pagination.page;
          hasMore.current = page.current < res.data.pagination.total_pages;

          setOptions(res.data.options);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId]
  );
  // eslint-disable-next-line
  const debounceOnSearch = debounce(onSearch, 200);

  const loadNextPage = useCallback(
    (q: string) => {
      API.getRecommendationsOptions({
        publicationId,
        page: page.current + 1,
        q,
      })
        .then((res: AxiosResponse) => {
          page.current = res.data.pagination.page;
          hasMore.current = page.current < res.data.pagination.total_pages;

          setOptions([...options, ...res.data.options] as any);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId, options]
  );

  useEffect(() => {
    if (recommendationId) {
      API.getRecommendation({
        publicationId,
        recommendationId,
      })
        .then((res: AxiosResponse) => {
          setData(res.data.recommended_publication);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    } else {
      API.getRecommendationsOptions({
        publicationId,
        page: page.current,
      })
        .then((res: AxiosResponse) => {
          page.current = res.data.pagination.page;
          hasMore.current = page.current < res.data.pagination.total_pages;

          setOptions(res.data.options);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    }
  }, [publicationId, recommendationId]);

  return (
    <NodeViewWrapper
      data-drag-handle
      data-id={recommendationId}
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <div {...{ inert: editor.isEditable ? undefined : '' }}>
        {data ? (
          <Styled.DataContainer>
            <img src={data.logo_url} alt="" />
            <Styled.TextContainer>
              <Styled.Headline>{data.name}</Styled.Headline>
              <Styled.Paragraph>{data.description}</Styled.Paragraph>
              <Styled.Button href={data.hostname} target="_blank" className="content-element-button">
                Subscribe
              </Styled.Button>
            </Styled.TextContainer>
          </Styled.DataContainer>
        ) : (
          <Styled.SelectionContainer>
            <Tippy
              offset={[0, 8]}
              placement="bottom"
              interactive
              visible={selectionMenuOpen}
              onShow={() => {
                setSelectionMenuOpen(true);
              }}
              onHidden={() => {
                setSelectionMenuOpen(false);
              }}
              onClickOutside={() => {
                setSelectionMenuOpen(false);
              }}
              render={(attrs: TippyProps) =>
                selectionMenuOpen ? (
                  <SearchList
                    tabIndex={-1}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...attrs}
                    items={options.map((option: any) => {
                      return {
                        value: option.id,
                        label: option.name,
                      };
                    })}
                    hasMore={hasMore.current}
                    onLoadMore={(q: string) => {
                      loadNextPage(q);
                    }}
                    searchable
                    searchPlaceholder="Search a recommendation"
                    onSearch={(q: string) => {
                      debounceOnSearch(q);
                    }}
                    onSelect={(item: SearchListItemProps) => {
                      editor.chain().focus().updateAttributes('recommendation', { id: item.value }).run();
                    }}
                    onBack={() => {
                      setSelectionMenuOpen(false);
                    }}
                    footer={
                      <Button
                        $variant="quaternary"
                        $size="small"
                        $rightSlot={<Icon name="Plus" />}
                        $fullWidth
                        as="a"
                        href="/recommendations"
                        target="_blank"
                      >
                        Add a recommendation
                      </Button>
                    }
                  />
                ) : null
              }
            >
              <Button
                $variant="secondary"
                $size="small"
                $active={selectionMenuOpen}
                $leftSlot={<Icon name="Recommendation" />}
                onClick={() => {
                  setSelectionMenuOpen(!selectionMenuOpen);
                }}
              >
                Select a recommendation
              </Button>
            </Tippy>
          </Styled.SelectionContainer>
        )}
      </div>
    </NodeViewWrapper>
  );
};
