import React from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  as?: keyof JSX.IntrinsicElements;
}

const Skeleton: React.FC<Props> = ({ className, as: Component = 'div' }) => (
  <Component className={cx(
    'w-full h-2 bg-gray-200 rounded-sm',
    className,
  )} />
)

export default Skeleton;
