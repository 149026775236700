import { useEffect, useState } from 'react';

import { Input } from '../../_components/UI/Input';
import { Textarea } from '../../_components/UI/Textarea';

interface Props {
  className?: string;
  name: string;
  placeholder?: string;
  value: string;
  onChangeMutateValue?: (val: string) => string;
  onSave: (val: string) => void;
  type?: 'input' | 'textarea';
  helperText?: string | React.ReactNode;
  labelText?: string;
  disabled?: boolean;
  labelInfo?: string;
}

// TODO REPLACE WITH TYPDREAM INPUT WHICH SUPPORTS THE DARK MODE
// OR We Can just add the classnames needed here...
const SettingInput = ({ name, value, onSave, onChangeMutateValue, placeholder, className, type, helperText, labelText, disabled, labelInfo }: Props) => {
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleOnSave = async () => {
    if (disabled) return;
    try {
      onSave(inputValue);
    } catch (e) {
      setInputValue(value);
    }
  }

  if (type === 'textarea') {
    return (
      <Textarea
        placeholder={placeholder}
        className={className}
        name={name}
        value={inputValue}
        labelText={labelText}
        onChange={(e) => {
          let val = e.target.value;
          if (onChangeMutateValue) {
            val = onChangeMutateValue(val);
          }
          setInputValue(val)
        }}
        onBlur={() => handleOnSave()}
        helperText={helperText}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleOnSave();
          }
        }}
        disabled={disabled}
        labelInfo={labelInfo}
      />
    );
  }

  return (
    <Input
      className={className}
      name={name}
      labelText={labelText}
      placeholder={placeholder}
      value={inputValue}
      helperText={helperText}
      onChange={(e) => {
        let val = e.target.value;
        if (onChangeMutateValue) {
          val = onChangeMutateValue(val);
        }
        setInputValue(val)
      }}
      onBlur={() => handleOnSave()}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleOnSave();
        }
      }}
      disabled={disabled}
      labelInfo={labelInfo}
    />
  );
};

export default SettingInput;
