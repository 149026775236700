import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { Automation } from '@/interfaces/automations/automation';
import { MutationErrorResponse, MutationErrorResponseErrors } from '@/interfaces/automations/mutation_error_response';
import api from '@/services/swarm';

interface Props {
  id: string;
  showErrors?: boolean;
  onSuccess?: (response: Automation) => void;
  onError?: (errors: MutationErrorResponseErrors) => void;
}

export default function useUpdateAutomation({ id, showErrors = true, onSuccess, onError }: Props) {
  const queryClient = useQueryClient();

  const updateAutomation = (formData: FormData): Promise<Automation> =>
    api.patch(`/automations/${id}`, formData).then((res) => res.data);

  return useMutation<Automation, MutationErrorResponse, FormData>(updateAutomation, {
    onSuccess: (response) => {
      toast.success('Automation updated successfully');
      queryClient.invalidateQueries(['automations', 'automation', id]);

      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (error) => {
      const { errors } = error.response.data;

      if (showErrors) {
        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      }

      if (onError) {
        onError(errors);
      }
    },
  });
}
