import { useEffect, useState } from 'react';
import cx from 'classnames';

import { SortableList, SortableListItem, useSortableList } from '../SortableList';

type BenefitItem = {
  id: string;
  value: string;
};

interface Props {
  id: string;
  stringList: string[];
  onListChange: (list: string[]) => void;
  variant?: 'primary' | 'secondary';
}

const VARIANT_CLASSES = {
  primary: 'bg-white shadow sm:rounded-md',
  secondary: '',
};

const SortableStringList = ({ id, stringList, onListChange, variant = 'primary' }: Props) => {
  const [localList, setLocalList] = useState<string[]>([]);
  const lengthCheck = localList?.length !== stringList?.length;

  const { sortableList, setSortableList, reStringifyList } = useSortableList<BenefitItem>({
    list: localList,
    addIdToListOfStrings: true,
    zeroBasedIndex: false,
  });

  useEffect(() => {
    if (stringList && lengthCheck) {
      setLocalList(stringList);
    }
  }, [stringList, lengthCheck]);

  if (!localList || localList.length === 0) {
    return null;
  }

  return (
    <div className={cx('relative curser-pointer mt-2', VARIANT_CLASSES[variant])}>
      <SortableList
        listItems={sortableList}
        onItemsSorted={(sortedList: any[]) => {
          setSortableList(sortedList);
          const reStringifiedList = reStringifyList(sortedList);
          setLocalList(reStringifiedList);
          onListChange(reStringifiedList);
        }}
      >
        {(list) => {
          return list.map((item: BenefitItem) => {
            return (
              <SortableListItem
                key={`${id}-${item.id}`}
                listItem={item}
                onRemoveItem={(removedBenefit: BenefitItem) => {
                  const newList = list.filter((benefit: BenefitItem) => benefit.id !== removedBenefit.id);
                  setSortableList(newList);
                  const reStringifiedList = reStringifyList(newList);
                  setLocalList(reStringifiedList);
                  onListChange(reStringifiedList);
                }}
                text={item.value}
              />
            );
          });
        }}
      </SortableList>
    </div>
  );
};

export default SortableStringList;
