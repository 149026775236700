import { useEffect, useState } from 'react';

import { Input } from '@/components/Form';
import SlideOver from '@/components/SlideOver';
import { FAQ } from '@/interfaces/faq_section';
import { Button } from '@/ui/Button';
import { Textarea } from '@/ui/Textarea';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (payload: FAQ) => void;
  isLoading: boolean;
  questionToEdit?: FAQ | null;
}

const QuestionForm = ({ isOpen, onClose, onSubmit, isLoading, questionToEdit }: Props) => {
  const [question, setQuestion] = useState(questionToEdit?.question || '');
  const [answer, setAnswer] = useState(questionToEdit?.answer || '');

  useEffect(() => {
    if (questionToEdit) {
      setQuestion(questionToEdit.question);
      setAnswer(questionToEdit.answer);
    }
  }, [questionToEdit]);

  const title = questionToEdit ? 'Edit Question' : 'Add Question';
  const ctaText = questionToEdit ? 'Save' : 'Create';

  const clearForm = () => {
    setQuestion('');
    setAnswer('');
  };

  const handleClickSave = () => {
    onSubmit({ question, answer });
    clearForm();
  };

  return (
    <SlideOver
      isOpen={Boolean(isOpen)}
      onClose={() => {
        onClose();
        clearForm();
      }}
      bodyId=""
      headerText={title}
    >
      <div className="space-y-4">
        <Input
          name="question"
          labelText="Question"
          supportsMarkdown
          value={question}
          onChange={(event) => setQuestion(event.target.value)}
          placeholder="What can you expect with this newsletter?"
          required
        />
        <Textarea
          rows={3}
          name="answer"
          labelText="Answer"
          supportsMarkdown
          value={answer}
          onChange={(event) => setAnswer(event.target.value)}
          required
          placeholderText="You should expect a newsletter every Friday morning."
        />
      </div>

      <div className="pt-8 w-full">
        <Button variant="primary" onClick={handleClickSave} loading={isLoading}>
          {ctaText}
        </Button>
      </div>
    </SlideOver>
  );
};

export default QuestionForm;
