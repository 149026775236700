import { useQuery } from 'react-query';

import { AdNetworkAdvertisementPreview } from '@/interfaces/ad_network/internal/advertisement';
import api from '@/services/swarm';

export default function useAdvertisementPreview({ id }: { id: string }) {
  return useQuery<AdNetworkAdvertisementPreview>(
    ['ad_network', 'internal', 'advertisements', id, 'preview'],
    () => api.get(`/ad_network/internal/advertisements/${id}/preview`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
