import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Dropdown } from '@/ui/Dropdown';
import { optionDataToOptions } from '@/utils';

interface ExternalEmbedSelectProps {
  value: string;
  onChange: (name: string, value: string) => void;
}

const ExternalEmbedSelect = ({ value, onChange }: ExternalEmbedSelectProps) => {
  const publicationId = useCurrentPublicationId();
  const externalEmbedsData = useOptions(publicationId, 'external_embeds', Infinity);
  const options = optionDataToOptions(externalEmbedsData);

  return (
    <Dropdown name="value" placeholderText="Select Embed" value={value || ''} onSelect={onChange} options={options} />
  );
};

export default ExternalEmbedSelect;
