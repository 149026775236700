import { useCallback, useRef } from 'react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import UpdateFilePanel from '@/components/TiptapEditor/components/panels/UpdateFile/UpdateFilePanel';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { getFileSizeStringWithUnit } from '@/components/TiptapEditor/lib/utils/getFileSizeStringWithUnit';
import { useCurrentUser } from '@/context/current-user-context';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';
import { FILE_ATTACHMENT_TYPE } from '../constants';
import { FileAttachmentDataPanel } from '../panels/FileAttachmentDataPanel';
import { IFileAttrs } from '../types';
import { useFileUploader } from '../views/hooks';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const FileAttachmentMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const { publicationId } = usePublicationContext();
  const { currentUser } = useCurrentUser();

  const menuRef = useRef<HTMLDivElement>(null);

  const tippyInstance = useRef<Instance | null>(null);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, `node-${FILE_ATTACHMENT_TYPE}`);
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive(FILE_ATTACHMENT_TYPE);

    return isActive;
  }, [editor]);

  const removeNode = useCallback(() => {
    const { from, to } = editor.state.selection;
    editor.commands.deleteRange({ from, to });
  }, [editor]);

  const fileAttachmentAttrs = editor.getAttributes(FILE_ATTACHMENT_TYPE) as IFileAttrs;

  const updateFileAttachmentAttributes = useCallback(
    (attrs: Partial<IFileAttrs>) => {
      editor.commands.updateAttributes(FILE_ATTACHMENT_TYPE, attrs);
    },
    [editor]
  );

  const { uploadFile } = useFileUploader({ publicationId, userId: currentUser?.id });

  const onUpload = useCallback(
    ({ file, fileAttachmentId }: { fileAttachmentId: string; file: File }) => {
      const fileAttrs = editor.getAttributes(FILE_ATTACHMENT_TYPE);

      editor
        .chain()
        .insertContent({
          type: FILE_ATTACHMENT_TYPE,
          attrs: {
            id: fileAttachmentId,
            src: '',
            size: getFileSizeStringWithUnit(file.size),
            type: file.type,
            title: fileAttrs.title || file.name,
            name: '',
          },
        })
        .focus()
        .run();
    },
    [editor]
  );

  const handleFileChanged = useCallback(
    async (file: File) => {
      const fileAttachmentId = await uploadFile(file);

      onUpload({ file, fileAttachmentId });
    },
    [uploadFile, onUpload]
  );

  const handleRemoveFile = useCallback(() => {
    const fileAttrs: Partial<IFileAttrs> = {
      id: '',
      src: '',
      size: '',
      type: '',
      name: '',
    };

    updateFileAttachmentAttributes(fileAttrs);
  }, [updateFileAttachmentAttributes]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`fileAttachmentMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()} ref={menuRef}>
        <FileAttachmentDataPanel
          updateAttributes={updateFileAttachmentAttributes}
          parentRef={menuRef}
          attrs={fileAttachmentAttrs}
        />
        <UpdateFilePanel
          tooltip="Upload or pick a new file"
          parentRef={menuRef}
          onFileChange={handleFileChanged}
          onRemove={handleRemoveFile}
          showRemoveOption
          accept=".epub,.pdf"
        />
        <Divider />
        <Tooltip title="Remove file attachment">
          <Button {...buttonProps} $leftSlot={<Icon name="Trash" />} onClick={removeNode} />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default FileAttachmentMenu;
