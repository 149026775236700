import { useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

import useEmailApplication from '@/hooks/boosts/grow/useEmailApplication';
import { BoostSend } from '@/interfaces/boosts/grow/boost_send';

import ApplicationCard from './ApplicationCard';
import ConfirmBoostSendRejectModal from './ConfirmBoostSendRejectModal';
import ReviewBoostSendModal from './ReviewBoostSendModal';

interface Props {
  item: BoostSend;
}

const EmailApplicationItem = ({ item }: Props) => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const { handleAccept, isAccepting, isRejecting } = useEmailApplication(item, {
    onSuccess: () => {
      toast.success('Email Boost Accepted!');
      setIsAccepted(true);
    },
  });
  const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isReviewModalOpen = searchParams.get('boost_send_id') === item.id;

  const handleClickReview = () => {
    searchParams.set('boost_send_id', item.id);
    setSearchParams(searchParams);
  };

  const closeReviewModal = () => {
    searchParams.delete('boost_send_id');
    setSearchParams(searchParams);
  };

  const closeConfirmRejectModal = () => setShowConfirmRejectModal(false);

  const handleClickReject = () => {
    closeReviewModal();
    setShowConfirmRejectModal(true);
  };

  const handleClickAccept = () => {
    closeReviewModal();
    handleAccept();
  };

  return (
    <>
      <ReviewBoostSendModal
        isOpen={isReviewModalOpen && !showConfirmRejectModal}
        boostSend={item}
        onClose={closeReviewModal}
        onClickAccept={handleClickAccept}
        onClickReject={handleClickReject}
      />
      <ConfirmBoostSendRejectModal
        isOpen={showConfirmRejectModal}
        boostSend={item}
        onClickCancel={closeConfirmRejectModal}
        onSuccess={() => {
          setIsRejected(true);
          closeConfirmRejectModal();
        }}
      />
      <ApplicationCard
        publication={item.recommending_publication}
        createdAt={item.created_at}
        onClickReview={handleClickReview}
        onAccept={handleClickAccept}
        onReject={handleClickReject}
        isAccepting={isAccepting}
        isRejecting={isRejecting}
        isAccepted={isAccepted}
        isRejected={isRejected}
      />
    </>
  );
};

export default EmailApplicationItem;
