// @ts-nocheck

import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */

const RouteAdapter: FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(loc: any) {
        navigate(loc, { replace: true, state: loc.state });
      },
      push(loc: any) {
        navigate(loc, { replace: false, state: loc.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

export default RouteAdapter;
