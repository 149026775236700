import { useState } from 'react';
import toast from 'react-hot-toast';

import { Input } from '@/components/Form';
import useUpdateUserPassword from '@/hooks/useUsers/useUpdateUserPassword';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import { Button } from '@/ui/Button';

const PasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const updatePasswordMutation = useUpdateUserPassword(() => {
    toast.success('Password updated!');
  });

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error('New and Confirm Passwords must match!');
      return;
    }

    await updatePasswordMutation.mutateAsync({
      old_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    });
  };

  return (
    <BodyContainer>
      <CardHeader title="Password" />
      <form onSubmit={handleUpdate}>
        <div className="flex flex-col gap-y-2">
          <Input
            className="w-full"
            name="current_password"
            labelText="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            type="password"
            required
          />
          <Input
            className="w-full"
            name="new_password"
            labelText="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            required
          />
          <Input
            className="w-full"
            name="confirm_password"
            labelText="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            required
          />
          <Button
            type="submit"
            className="w-fit"
            size="xs"
            variant="primary-inverse"
            loading={updatePasswordMutation.isLoading}
          >
            {updatePasswordMutation.isLoading ? 'Resetting...' : 'Reset Password'}
          </Button>
        </div>
      </form>
    </BodyContainer>
  );
};

export default PasswordForm;
