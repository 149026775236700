interface Props {
  className?: string;
}

const ReferringAutomation = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_4196)">
      <path
        d="M11.2778 12.4445H9.72222C9.07789 12.4445 8.55556 11.9221 8.55556 11.2778V9.72224C8.55556 9.0779 9.07789 8.55557 9.72222 8.55557H11.2778C11.9221 8.55557 12.4444 9.0779 12.4444 9.72224V11.2778C12.4444 11.9221 11.9221 12.4445 11.2778 12.4445Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.27778 7H2.72222C2.07789 7 1.55556 6.47767 1.55556 5.83333V4.27778C1.55556 3.63345 2.07789 3.11111 2.72222 3.11111H4.27778C4.92211 3.11111 5.44445 3.63345 5.44445 4.27778V5.83333C5.44445 6.47767 4.92211 7 4.27778 7Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 0.777771V3.1111"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.5 7V12.4444" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.83333 5.05554H9.33333C9.97764 5.05554 10.5 5.57787 10.5 6.22221V8.16665"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83333 5.05554H9.33333C9.97764 5.05554 10.5 5.57787 10.5 6.22221V8.16665"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_4196">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ReferringAutomation;
