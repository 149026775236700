import { DonutChart as TremorDonutChart, DonutChartProps } from '@tremor/react';

// https://www.tremor.so/docs/components/donut-chart

const PieChart = (props: DonutChartProps) => {
  const { colors, ...rest } = props;
  return <TremorDonutChart variant="pie" colors={colors || ['pink']} {...rest} />;
};

export default PieChart;
