import styled from 'styled-components';

export const Styled = {
  InstagramMedia: styled.blockquote.attrs({
    className: 'mx-auto p-0 border rounded-lg border-gray-300 bg-white',
  })`
    max-width: 420px;
    min-width: 326px;
    width: 99.375%;
    width: -webkit-calc(100% - 2px);
    width: calc(100% - 2px);

    .ig-text {
      color: rgb(0, 149, 246);
    }
  `,
  Paragraph: styled.p`
    margin: 0 !important;
  `,
  ProfileImage: styled.img.attrs({
    className: 'block rounded-full border-0',
  })`
    width: 40px;
    height: 40px;
  `,
  HeartLeft: styled.div`
    background-color: #d1d5db;
    border-radius: 50%;
    flex-grow: 0;
    height: 14px;
    width: 14px;
    transform: translateX(0px) translateY(7px);
  `,
  HeartRight: styled.div`
    background-color: #d1d5db;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    transform: translateX(10px) translateY(-21px);
  `,
  HeartTip: styled.div`
    background-color: #d1d5db;
    flex-grow: 0;
    height: 14px;
    transform: rotate(-45deg) translateX(3px) translateY(1px);
    width: 14px;
    margin-right: 14px;
    margin-left: 2px;
  `,
  CommentBubble: styled.div`
    background-color: #d1d5db;
    flex-grow: 0;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  `,
  CommentBubbleTip: styled.div`
    width: 0;
    height: 0;
    border-top: 2px solid transparent;
    border-left: 6px solid #d1d5db;
    border-bottom: 2px solid transparent;
    transform: translateX(18px) translateY(-4px) rotate(30deg);
  `,
  BookmarkLeft: styled.div`
    width: 0px;
    border-top: 10px solid #d1d5db;
    border-right: 10px solid transparent;
    transform: translateY(16px);
  `,
  BookmarkTop: styled.div`
    flex-grow: 0;
    background-color: #d1d5db;
    height: 14px;
    width: 18px;
    transform: translateY(-8px);
  `,
  BookmarkRight: styled.div`
    width: 0;
    height: 0;
    border-top: 10px solid #d1d5db;
    border-left: 10px solid transparent;
    transform: translateY(-8px) translateX(8px);
  `,
  LogoImage: styled.img`
    width: 20px;
    height: 20px;
  `,
  ReelsLogoImage: styled.img`
    width: 22px;
    height: 22px;
  `,
};

export default Styled;
