import { useMemo } from 'react';

import { useEditorContext } from '../../../EditorContext';
import { EmptyState } from '../components/EmptyState';
import { ThreadCard } from '../components/ThreadCard';

export const ResolvedThreadsView = ({ onClickThread }: { onClickThread: (id: string) => void }) => {
  const { threads, highlightThread, removeHighlightThread, editor } = useEditorContext();

  const focusedThreads = editor?.storage.comments.focusedThreads || [];

  const filtered = useMemo(() => {
    return threads?.filter((t) => !!t.resolvedAt);
  }, [threads]);

  if (!filtered || filtered.length === 0) return <EmptyState message="No resolved threads found" />;

  return (
    <div className="grid gap-2">
      {filtered?.map((thread) => (
        <ThreadCard
          key={thread.id}
          id={thread.id}
          authorId={thread.data.authorId}
          content={thread.comments[0]?.content}
          participantIds={thread.comments.map((c) => c.data.authorId)}
          createdAt={thread.createdAt}
          replies={thread.comments.length - 1}
          onClick={onClickThread}
          onMouseEnter={highlightThread}
          onMouseLeave={removeHighlightThread}
          focused={focusedThreads.includes(thread.id)}
          resolved
        />
      ))}
    </div>
  );
};
