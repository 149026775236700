import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { BoostSend, BoostSendStatus } from '../../../../interfaces/boosts/grow/boost_send';
import api from '../../../../services/swarm';

interface Props {
  id: string;
  onSuccess?: (result: BoostSend) => void;
  shouldInvalidate?: boolean;
}

interface Variables {
  status: BoostSendStatus;
}

const useUpdateBoostSend = ({ id, onSuccess, shouldInvalidate = true }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const updateBoostSend = ({ status }: Variables): Promise<BoostSend> =>
    api
      .patch(`/boosts/grow/boost_sends/${id}`, {
        publication_id: currentPublicationId,
        boost_send: { transition_to: status },
      })
      .then((res) => res.data);

  return useMutation(updateBoostSend, {
    onSuccess: (result) => {
      onSuccess?.(result);

      if (!shouldInvalidate) {
        return;
      }

      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_sends']);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
  });
};

export default useUpdateBoostSend;
