import { InformationCircleIcon } from '@heroicons/react/20/solid';

import Table, { TableBody, TableCol, TableRow } from '@/components/Table';
import Tooltip from '@/components/Tooltip';
import { LeaderboardRow } from '@/interfaces/partner_program/leaderboard_row';

interface Props {
  leaderboardRows: LeaderboardRow[];
}

const TableColInHeader = ({ children }: any) => {
  return (
    <TableCol className="!px-3 !py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      {children}
    </TableCol>
  );
};

const PartnerProgramLeaderboard = ({ leaderboardRows }: Props) => {
  return (
    <div className="p-6 w-full flex flex-col space-y-6 bg-white border rounded-lg">
      <div className="p-2 w-full flex flex-row space-x-1.5 items-start self-stretch overflow-auto bg-surface-50 rounded-md">
        <p className="font-semibold text-gray-700">Beach Club Leaderboard</p>
        <div className="my-auto">
          <Tooltip
            tooltipClass="text-center"
            id="beach-club-leaderboard-tooltip"
            text="A quarterly leaderboard where the highest performing partner will be sent to an all-expenses-paid trip."
            showIcon={false}
            autoWidth={false}
          >
            <InformationCircleIcon className="my-auto h-4 w-4 text-surface-200" />
          </Tooltip>
        </div>
      </div>
      <Table>
        <TableRow className="bg-gray-50 border-b border-surface-100 rounded-t-lg rounded-xl">
          <TableColInHeader>Position</TableColInHeader>
          <TableColInHeader>Partner Name</TableColInHeader>
          <TableColInHeader>Sales</TableColInHeader>
        </TableRow>
        <TableBody>
          {leaderboardRows?.map((row: any) => (
            <TableRow key={row.position}>
              <TableCol className="!px-3 text-sm font-medium text-gray-900 text-left">{row.position}</TableCol>
              <TableCol className="!px-3 text-sm font-medium text-gray-900 text-left">{row.name}</TableCol>
              <TableCol className="!px-3 text-sm text-gray-700 text-left">{row.total_sales}</TableCol>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PartnerProgramLeaderboard;
