import { Link } from 'react-router-dom';
import moment from 'moment-mini';

import { Typography } from '@/components/Typography';
import { AdNetworkPublishedEvent } from '@/hooks/useCalendar/types';
import { Badge, BadgeSize, BadgeType } from '@/ui/Badge';
import { Button } from '@/ui/Button';

import Indicator from './Indicator';

interface Props {
  event: AdNetworkPublishedEvent;
}

const PublishedAdNetworkOpportunityEventDetails = ({ event }: Props) => {
  const {
    title,
    post_title: postTitle,
    payout_rate: payoutRate,
    opportunity_id: opportunityId,
    report_id: reportId,
    date,
    post_id: postId,
  } = event.metadata;
  return (
    <div className="max-w-lg">
      <div className="bg-white rounded shadow-xl border border-gray-200">
        <div className="p-6 space-y-6">
          <div className="flex items-start justify-between space-x-6">
            <div className="space-x-2 flex items-start">
              <Indicator calendar={event.calendar} />
              <div>
                <Typography as="p" token="font-medium/text/base" className="line-clamp-2">
                  {title}
                </Typography>
              </div>
            </div>
            <Badge type={BadgeType.SUCCESS} text="Published" size={BadgeSize.SM} />
          </div>
          <div className="flex">
            <dl className="space-y-6">
              <div className="space-y-1">
                <dt>
                  <Typography as="p" token="font-normal/text/xs" colorWeight="500">
                    Date
                  </Typography>
                </dt>
                <dd>
                  <Typography as="p" token="font-normal/text/sm" colorWeight="900">
                    Published on {moment(date).format('MMM Do, YYYY')}
                  </Typography>
                </dd>
              </div>
              <div className="space-y-1">
                <dt>
                  <Typography as="p" token="font-normal/text/xs" colorWeight="500">
                    Payout rate
                  </Typography>
                </dt>
                <dd>
                  <Typography as="p" token="font-normal/text/sm" colorWeight="900">
                    {payoutRate}
                  </Typography>
                </dd>
              </div>
              <div className="space-y-1">
                <dt>
                  <Typography as="p" token="font-normal/text/xs" colorWeight="500">
                    Post
                  </Typography>
                </dt>
                <dd>
                  <Link to={`/posts/${postId}`}>
                    <Typography
                      as="p"
                      token="font-normal/text/sm"
                      colorWeight="700"
                      color="primary"
                      className="hover:underline"
                    >
                      {postTitle}
                    </Typography>
                  </Link>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-gray-100 px-6 py-3 space-x-3 flex items-center justify-end">
          <Button type="button" variant="primary-inverse" size="sm" to={`/monetize/ads/opportunities/${opportunityId}`}>
            View details
          </Button>
          {reportId && (
            <Button type="button" variant="primary" size="sm" to={`/monetize/ads/reports/${reportId}`}>
              View report
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublishedAdNetworkOpportunityEventDetails;
