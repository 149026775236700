/**
 * Capitalize the first letter in a string
 *
 * @example capitalize('test') => 'Test
 * @param str
 * @returns {string}
 */
const capitalize = (str: string) => (str[0] || '').toUpperCase() + str.substring(1);

export default capitalize;
