import { useQuery } from 'react-query';

import { usePublication } from '@/hooks/usePublications';
import { Publication } from '@/interfaces/publication';
import api from '@/services/swarm';

interface NotificationSetting {
  code: string;
  description: string;
  id: string | null;
  name: string;
  setting_type: string;
  value: string | boolean | number;
  value_origin: string;
  roles: string[];
  plans: string[];
}

interface ReturnProps {
  isPublicationSuccess: boolean;
  isSuccess: boolean;
  publication?: Publication;
  publicationId: string;
  settings?: NotificationSetting[];
  refetch: () => void;
}

const useNotificationSettings = (publicationId: string): ReturnProps => {
  const { data: publication, isSuccess: isPublicationSuccess } = usePublication(publicationId);
  const {
    data: settings,
    isSuccess,
    refetch,
  } = useQuery<NotificationSetting[]>(
    ['publications', publicationId, 'notification_settings'],
    () =>
      api
        .get('/notification_settings', {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );

  return { isPublicationSuccess, isSuccess, publication, publicationId, refetch, settings };
};

export default useNotificationSettings;
