/* eslint-disable import/prefer-default-export */

export enum TimePeriod {
  LAST_24_HOURS = 'last_24_hours',
  LAST_7_DAYS = 'last_7_days',
  LAST_4_WEEKS = 'last_4_weeks',
  LAST_12_MONTHS = 'last_12_months',
  MONTH_TO_DATE = 'month_to_date',
  QUARTER_TO_DATE = 'quarter_to_date',
  YEAR_TO_DATE = 'year_to_date',
  ALL_TIME = 'all_time',
}
