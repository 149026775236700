import { useMemo } from 'react';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import LoadingBox from '@/components/LoadingBox';
import { useSettings } from '@/context/settings-context';
import useEarningsPerPeriod from '@/hooks/boosts/monetize/useEarningsPerPeriod';
import {
  CHART_CATEGORIES,
  CHART_COLORS,
  EARNINGS_CATEGORY,
  PREVIOUS_EARNINGS_CATEGORY,
} from '@/pages/Monetize/Boosts/Dashboard/components/EarningsPerPeriodChart';

import currencyFormatter from '../../Monetize/Boosts/utils/currencyFormatter';
import { PageProps } from '../types';

const ChartOptions = () => {
  const { period } = usePageContext<PageProps>();
  const { data: earningsData, isLoading: isEarningsDataLoading, isError } = useEarningsPerPeriod({ period });
  const { settings } = useSettings();

  const totalEarnings =
    useMemo(() => {
      return earningsData?.metrics.reduce((pv, cv) => pv + cv.earnings, 0);
    }, [earningsData]) || 0;

  const mappedChartData = useMemo(() => {
    if (isEarningsDataLoading || typeof earningsData !== 'object') {
      return [];
    }

    return earningsData?.metrics.map((row) => {
      return {
        date: row.name,
        [EARNINGS_CATEGORY.label]: row.earnings,
        [PREVIOUS_EARNINGS_CATEGORY.label]: row.previous_earnings,
      };
    });
  }, [earningsData, isEarningsDataLoading]);

  if (settings && !settings.boosts) return null;

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <ChartCard
          title="Boost Earnings"
          description="Money you have earned from Boosts in the selected time range"
          isLoading={isEarningsDataLoading}
          noResults={false}
          noResultsText="No results found for this time range."
        >
          <LoadingBox isLoading={isEarningsDataLoading} isError={isError} height={250}>
            {earningsData && (
              <>
                <div className="flex justify-between items-end mb-1">
                  <div>
                    <p className="text-gray-700">
                      <span className="text-2xl mr-1 inline-flex items-center">
                        <span className="font-black text-feedback-success-600">
                          {currencyFormatter.format(totalEarnings)}
                        </span>
                      </span>
                      <span className="text-sm font-normal text-gray-600">total earned</span>
                    </p>
                  </div>
                </div>
                <AreaChart
                  index="date"
                  className="mt-8 w-full h-60"
                  data={mappedChartData}
                  categories={CHART_CATEGORIES}
                  colors={CHART_COLORS}
                  showLegend={false}
                  showXAxis={false}
                  showYAxis
                  showGridLines={false}
                />
              </>
            )}
          </LoadingBox>
        </ChartCard>
      </div>
    </div>
  );
};

export default ChartOptions;
