import { FC, useEffect, useState } from 'react';
import { RecommendationsElement, TRecommendationAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import NotConnected from '../../components/NotConnected';
import RecommendationsSelectionModal from '../modals/RecommendationsSelectionModal';

export const RecommendationsView: FC<NodeViewProps> = (nodeViewProps) => {
  const { node, editor, getPos } = nodeViewProps;
  const { onDragStart } = useReactNodeView();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { insertedFromSidebar, hasFakeData } = node.attrs;

  useEffect(() => {
    if (insertedFromSidebar && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [insertedFromSidebar, isModalOpen]);

  const handleCloseModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', false);
      return true;
    });
    setIsModalOpen(false);
  };

  const handleOpenSelectionModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
    setIsModalOpen(true);
  };

  return (
    <RecommendationsElement
      className="relative"
      element={{
        type: 'recommendations',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        } as TRecommendationAttributes,
        content: [{ id: 'text', text: '' }],
      }}
      onDragStart={onDragStart}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      isPreviewing
    >
      {hasFakeData && <NotConnected text="Not connected to real recommendations" onClick={handleOpenSelectionModal} />}
      {!hasFakeData && <NotConnected text="Change recommendations" onClick={handleOpenSelectionModal} />}
      {insertedFromSidebar && (
        <RecommendationsSelectionModal
          {...nodeViewProps}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </RecommendationsElement>
  );
};
