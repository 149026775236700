import { useEffect, useRef, useState } from 'react';
import { UseMutationResult } from 'react-query';

import { Input } from '@/components/Form';
import { useIndustryGroups } from '@/hooks/useAdNetwork/internal';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

interface FormProps {
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  defaultName?: string;
  defaultIndustryGroupId?: string | null;
}

const Form = ({ onSubmitMutation, defaultName, defaultIndustryGroupId }: FormProps) => {
  const { mutateAsync, isLoading } = onSubmitMutation;
  const [name, setName] = useState(defaultName || '');
  const [industryGroupId, setIndustryGroupId] = useState(defaultIndustryGroupId || '');

  const { data: industryGroupsData } = useIndustryGroups();
  const allIndustryGroups = industryGroupsData?.industry_groups.map((industryGroup) => ({
    value: industryGroup.id,
    label: industryGroup.name,
  }));

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mutateAsync({ name, industryGroupId });
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="p-4">
      <div className="max-w-lg mx-auto w-full space-y-4">
        <form className="space-y-4" onSubmit={onSubmit}>
          <Input
            ref={inputRef}
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="industry[name]"
            labelText="Name"
          />
          {allIndustryGroups && (
            <Dropdown
              name="industry[industry_group_id]"
              labelText="Industry group"
              value={industryGroupId}
              onSelect={(_name, value) => {
                setIndustryGroupId(value as string);
              }}
              options={allIndustryGroups}
              helperText="Set this value to categorize this industry underneath a group"
              topRightLinkText={industryGroupId && 'Clear'}
              onTopRightLinkClick={() => setIndustryGroupId('')}
            />
          )}
          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Form;
