import { FC } from 'react';

import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';

export interface SampleRecord {
  promo_code: string;
}

interface Props {
  sampleRecords: SampleRecord[];
}

const SampleTable: FC<Props> = ({ sampleRecords }: Props) => {
  return (
    <Items>
      <ItemHeaders>
        <ItemHeader align={AlignType.LEFT}>Code</ItemHeader>
      </ItemHeaders>

      <ItemsBody>
        {sampleRecords.map((record: SampleRecord) => (
          <ItemRow>
            <ItemColumn align={AlignType.TOP}>
              <Typography token="font-normal/text/sm">{record.promo_code}</Typography>
            </ItemColumn>
          </ItemRow>
        ))}
      </ItemsBody>
    </Items>
  );
};

export default SampleTable;
