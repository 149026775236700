import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Subscriber } from '@/interfaces/subscriber';
import { Subscription, SubscriptionSendStats } from '@/interfaces/subscription';
import api from '@/services/swarm';

export interface SubscriberApiResponse {
  subscription: Subscription;
  subscriber: Subscriber;
  send_stats: SubscriptionSendStats;
  total_spend: string;
  gravatar: string;
}

const useSubscriber = ({ id }: { id: string | undefined }) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<SubscriberApiResponse>(
    ['subscriber', id, currentPublicationId],
    () =>
      api
        .get(`/subscribers/${id}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      retry: false,
      enabled: !!id,
    }
  );
};

export default useSubscriber;
