import { useQueryClient } from 'react-query';

import { SortableList, useSortableList } from '@/components/SortableList';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useOrderNavLinks } from '@/hooks/useNavlinks';
import { NavLink } from '@/interfaces/nav_link';

import SortableNavLink from './SortableNavLink';

interface Props {
  navLinks: NavLink[];
}

const SortableNavLinks = ({ navLinks }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const { sortableList } = useSortableList<NavLink>({
    list: navLinks,
  });

  const orderLinks = useOrderNavLinks({
    onSuccess: () => {
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
    },
  });

  const handleItemsSorted = (sortedList: any[]) => {
    const newList = sortedList as NavLink[];
    orderLinks.mutate(
      newList.map((item: any, index: number) => {
        return { id: item.id, order: index + 1 };
      })
    );
  };

  return (
    <SortableList listItems={sortableList} onItemsSorted={handleItemsSorted}>
      {(list) => {
        return list.map((item: NavLink) => {
          return <SortableNavLink key={item.id} navLink={item} />;
        });
      }}
    </SortableList>
  );
};

export default SortableNavLinks;
