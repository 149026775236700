import { DateFilterTypes } from '../interfaces/dates';
import { Sort } from '../interfaces/general';

interface SubscriberSearchProps {
  advanced: boolean;
  afterDate?: Date;
  beforeDate?: Date;
  dateField?: string;
  dateFilterType: DateFilterTypes;
  datesEnabled: boolean;
  dir?: Sort | string;
  endDate?: Date;
  order?: string;
  search?: string;
  startDate?: Date;
  status?: string;
  tierId?: string;
}

export default class SubscriberSearch implements SubscriberSearchProps {
  advanced: SubscriberSearchProps['advanced'];

  afterDate: SubscriberSearchProps['afterDate'];

  beforeDate: SubscriberSearchProps['beforeDate'];

  dateField: SubscriberSearchProps['dateField'];

  dateFilterType: SubscriberSearchProps['dateFilterType'];

  datesEnabled: SubscriberSearchProps['datesEnabled'];

  dir: SubscriberSearchProps['dir'];

  endDate: SubscriberSearchProps['endDate'];

  order: SubscriberSearchProps['order'];

  search: SubscriberSearchProps['search'];

  startDate: SubscriberSearchProps['startDate'];

  status: SubscriberSearchProps['status'];

  tierId: SubscriberSearchProps['tierId'];

  params(): object {
    const datesEnabled = this.advanced && this.datesEnabled;

    return {
      ...(this.advanced && { advanced: this.advanced }),
      ...(this.datesEnabled && { datesEnabled: this.datesEnabled }),
      ...(this.dir && { dir: this.dir }),
      ...(this.order && { order: this.order }),
      ...(this.search && { q: this.search }),
      ...(this.status && { status: this.status }),
      ...(this.tierId && { tier_id: this.tierId }),
      ...(datesEnabled && this.dateField && { date_field: this.dateField }),
      ...(datesEnabled &&
        this.dateFilterType === DateFilterTypes.BETWEEN &&
        this.startDate && { start_date: this.startDate }),
      ...(datesEnabled &&
        this.dateFilterType === DateFilterTypes.BETWEEN &&
        this.endDate && { end_date: this.endDate }),
      ...(datesEnabled &&
        this.dateFilterType === DateFilterTypes.BEFORE &&
        this.beforeDate && { before_date: this.beforeDate }),
      ...(datesEnabled &&
        this.dateFilterType === DateFilterTypes.AFTER &&
        this.afterDate && { after_date: this.afterDate }),
    };
  }

  constructor(props: SubscriberSearchProps) {
    this.advanced = props.advanced;
    this.afterDate = props.afterDate;
    this.beforeDate = props.beforeDate;
    this.dateField = props.dateField;
    this.dateFilterType = props.dateFilterType;
    this.datesEnabled = props.datesEnabled;
    this.dir = props.dir;
    this.endDate = props.endDate;
    this.order = props.order;
    this.search = props.search;
    this.startDate = props.startDate;
    this.status = props.status;
    this.tierId = props.tierId;
  }
}
