import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import { Typography, TypographyStack } from '../Typography';

const UnderConstruction = () => {
  return (
    <div className="flex flex-col px-4 py-16 space-y-10 items-center">
      <TypographyStack gap="4">
        <WrenchScrewdriverIcon className="w-16 h-16 text-surface-300 mx-auto" />
        <Typography token="font-bold/text/5xl">Under Construction</Typography>
      </TypographyStack>
      <Typography token="font-light/text/3xl" className="max-w-2xl text-center">
        This page is temporarily unavailable. We&apos;re probably building something cool that&apos;s worth the wait.{' '}
        <br /> Please check back later.
      </Typography>
      <Link to="/">
        <Button type="button" iconRight Icon={ArrowRightIcon}>
          Go Home
        </Button>
      </Link>
    </div>
  );
};

export default UnderConstruction;
