import { createContext, useContext, useMemo } from 'react';

import { useCurrentPublication, useWebTemplate } from '@/hooks';
import useFonts from '@/hooks/useFonts';
import useAllFonts from '@/hooks/useFonts/useAllFonts';
import usePlan from '@/hooks/usePlan';
import { Publication } from '@/interfaces/publication';
import { WebTemplate, WebTheme } from '@/interfaces/web_template';
import FontStyles from '@/pages/DesignLab/components/FontStyles';
import { ScreenSizes, ScreenSizeType, useScreenSize } from '@/pages/DesignLab/components/ScreenSize';

import { StatusType, useSavingIndicator } from '../Helpers/SavingIndicator';

interface ContextProps<T> {
  pageData: T;
  currentPublication?: Publication;
  webTemplate?: WebTemplate;
  isLoading?: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  screenSize: ScreenSizeType;
  setScreenSize: (screenSize: ScreenSizeType) => void;
  setIsSaving: (isSaving: boolean) => void;
  handleSaved: () => void;
  isPreviewMode: boolean;
  plan: string;
  defaultColors: string[];
  status: StatusType;
  fontOptions: {
    label: string;
    value: string;
  }[];
}

const PageContext = createContext<ContextProps<unknown>>({
  pageData: {},
  currentPublication: undefined,
  webTemplate: undefined,
  isDesktop: false,
  isMobile: false,
  screenSize: ScreenSizes.DESKTOP,
  setScreenSize: () => {},
  isLoading: true,
  setIsSaving: () => {},
  handleSaved: () => {},
  isPreviewMode: false,
  defaultColors: [],
  status: 'idle',
  fontOptions: [],
  plan: '',
});
PageContext.displayName = 'PageContext';

interface Props<T> {
  children: React.ReactNode;
  pageData?: T;
  isLoading?: boolean;
}

const THEME_COLORS = [
  { label: 'Primary Color', key: 'color_primary' },
  { label: 'Secondary Color', key: 'color_secondary' },
  { label: 'Tertiary Color', key: 'color_tertiary' },
  { label: 'Background Color', key: 'color_background' },
  { label: 'Border Color', key: 'color_border' },
  { label: 'Link Color', key: 'color_link' },
  { label: 'Underline Color', key: 'color_underline_text' },
  { label: 'Hover Color', key: 'color_hover' },
];

export const PageProvider = <T extends unknown>({ children, pageData, isLoading }: Props<T>) => {
  const { data: currentPublication } = useCurrentPublication();
  const { data: webTemplate } = useWebTemplate();
  const { plan } = usePlan();

  const { setIsSaving, handleSaved, status } = useSavingIndicator();

  const webTheme: WebTheme | undefined = webTemplate?.web_theme_preview;

  const { screenSize, setScreenSize } = useScreenSize();
  const fontOptions = useFonts();

  const allFontsQuery = useAllFonts({
    webSafe: false,
  });
  const { data: fonts } = allFontsQuery;

  const defaultColors = useMemo(() => {
    if (typeof webTheme !== 'undefined') {
      const theme: any = webTheme;
      const themeColors = THEME_COLORS.map((value) => theme[value.key] || '#000000').slice(0, -2);
      const blackCount = themeColors.filter((color) => color === '#000000').length;
      if (blackCount < 2) {
        return themeColors;
      }
    }

    return [];
  }, [webTheme]);

  const value = useMemo(() => {
    return {
      fontOptions: fontOptions || [],
      pageData,
      currentPublication,
      webTemplate,
      isPreviewMode: screenSize === ScreenSizes.FULL_SCREEN,
      setIsSaving,
      handleSaved,
      screenSize,
      isDesktop: screenSize === ScreenSizes.DESKTOP,
      isMobile: screenSize === ScreenSizes.MOBILE,
      setScreenSize,
      defaultColors,
      status,
      plan,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData, currentPublication, status, fontOptions, screenSize, defaultColors, webTemplate, plan]);

  return (
    <PageContext.Provider value={value}>
      {fonts && <FontStyles fonts={fonts} />}
      {isLoading ? null : children}
    </PageContext.Provider>
  );
};

export const usePageContext = <T extends unknown>() => useContext(PageContext) as ContextProps<T>;
