import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Icon } from '../../TiptapEditor/components/ui/Icon';

export type SearchBarProps = {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  suffix?: JSX.Element;
};

const SearchBar = React.forwardRef(
  ({ onChange, value, placeholder, suffix }: SearchBarProps, ref: React.Ref<HTMLLabelElement>) => {
    const [currentValue, setCurrentValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    const valueChanged = useMemo(() => currentValue !== value, [currentValue, value]);

    const handleSubmit = useCallback(
      (e: React.FormEvent) => {
        e.preventDefault();
        onChange(currentValue);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueChanged, currentValue, onChange]
    );

    return (
      <label ref={ref} htmlFor="searchInput" className="relative flex items-center w-full p-6 bg-white cursor-text">
        <form className="flex items-center w-full h-9" onSubmit={handleSubmit}>
          <div className="relative w-full">
            <input
              ref={inputRef}
              id="searchInput"
              className="relative border border-surface-200 rounded-md inset-0 z-0 block w-full py-2 pl-10 pr-3 text-sm bg-transparent outline-none focus:ring-0"
              type="text"
              value={currentValue}
              onChange={(e) => setCurrentValue(e.target.value)}
              placeholder={placeholder}
            />
            <div className="flex-none absolute top-2.5 left-3 text-gray-600 pointer-events-none">
              <Icon name="Search" $display="block" $size="1.125rem" />
            </div>
          </div>
          {valueChanged && (
            <button
              className="flex-none px-2 py-1.5 text-xs font-semibold text-white bg-black rounded-lg"
              type="submit"
            >
              Search
            </button>
          )}
        </form>
        {suffix && <div className="flex-none ml-2">{suffix}</div>}
      </label>
    );
  }
);

export default SearchBar;
