import { ChangeEvent, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/core';
import throttle from 'lodash.throttle';
import { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';

import IconButton from '@/components/IconHelpers/IconButton';
import { SearchAndReplaceStorage } from '@/components/TiptapEditor/extensions/SearchAndReplace';
import { Typography } from '@/components/Typography';
import { Input } from '@/components/UI/Input';
import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';
import { Button } from '@/ui/Button';

import { SearchButton } from '../../buttons';

interface SearchAndReplaceMenuProps {
  editor: Editor;
}

export const SearchAndReplaceMenu: FC<SearchAndReplaceMenuProps> = memo(({ editor }) => {
  const { showSearchAndReplaceMenu, setShowSearchAndReplaceMenu } = useEditorContext();

  const tippyInstance = useRef<TippyInstance<TippyProps> | null>(null);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const [replaceTerm, setReplaceTerm] = useState<string>('');

  const [searchAndReplaceData, setSearchAndReplaceData] = useState<Pick<
    SearchAndReplaceStorage,
    'results' | 'resultIndex'
  > | null>(null);

  const setTippyInstance = useCallback((instance: TippyInstance<TippyProps>) => {
    tippyInstance.current = instance;
  }, []);

  const onTippyHide = useCallback(() => {
    setShowSearchAndReplaceMenu(false);
  }, [setShowSearchAndReplaceMenu]);

  const resetTerms = useCallback(() => {
    setSearchTerm('');
    setReplaceTerm('');
  }, []);

  const triggerClose = useCallback(() => {
    if (searchTerm.trim()) return;

    setShowSearchAndReplaceMenu(false);
  }, [searchTerm, setShowSearchAndReplaceMenu]);

  const forceClose = useCallback(() => {
    resetTerms();

    setTimeout(() => setShowSearchAndReplaceMenu(false));
  }, [resetTerms, setShowSearchAndReplaceMenu]);

  const onSearchInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const chain = editor.chain();

        if (e.shiftKey) chain.focusPreviousSearchResult().run();
        else chain.focusNextSearchResult().run();
      }

      if (e.key === 'Escape') {
        triggerClose();
        editor.commands.focus();
      }
    },
    [editor, triggerClose]
  );

  const searchStatusString = useMemo(() => {
    if (!searchAndReplaceData) return '';
    const { resultIndex, results } = searchAndReplaceData;

    return results.length ? `${resultIndex + 1} of ${results.length}` : '0 of 0';
  }, [searchAndReplaceData]);

  useEffect(() => {
    if (editor) {
      editor.commands.setSearchTerm(searchTerm);
      editor.commands.setReplaceTerm(replaceTerm);
    }
  }, [editor, searchTerm, replaceTerm]);

  useEffect(() => {
    if (showSearchAndReplaceMenu) {
      const { from, to } = editor.state.selection;
      const selectionText = editor.state.doc.textBetween(from, to);

      if (selectionText.trim() && !editor.storage.searchAndReplace.searchTerm) {
        editor.commands.setSearchTerm(selectionText);
        setSearchTerm(selectionText);
      }

      setTimeout(() => searchInputRef.current?.focus());
    }
  }, [showSearchAndReplaceMenu, editor]);

  useEffect(() => {
    const extractSearchAndReplaceData = throttle(() => {
      const { results, resultIndex } = editor.storage.searchAndReplace as SearchAndReplaceStorage;

      setSearchAndReplaceData({ results, resultIndex });
    }, 200);

    editor.on('transaction', extractSearchAndReplaceData);

    return () => {
      editor.off('transaction', extractSearchAndReplaceData);
    };
  }, [editor]);

  const tippyContent = useMemo(
    () => (
      <div className="flex flex-col gap-2 bg-white p-2 rounded-md shadow-md z-50 w-80 relative">
        <Input.Field
          value={searchTerm}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
          placeholder="Search"
          className="flex-1 rounded-md px-3 py-1.5"
          ref={searchInputRef}
          id="search-input"
          onKeyDown={onSearchInputKeyDown}
        />

        <div className="flex gap-2 items-center justify-between">
          <Typography token="font-normal/text/xs" className="ml-1">
            {searchStatusString}
          </Typography>

          <div className="flex gap-2 items-center">
            <Button
              type="button"
              variant="flush"
              size="xxs"
              onClick={() => editor.chain().focusPreviousSearchResult().run()}
              className="flex-1 px-2 py-1"
            >
              <ChevronLeftIcon className="w-4 h-4" aria-hidden="true" />
              Prev
            </Button>

            <Button
              type="button"
              variant="flush"
              size="xxs"
              onClick={() => editor.chain().focusNextSearchResult().run()}
              className="flex-1 px-2 py-1"
            >
              Next
              <ChevronRightIcon className="w-4 h-4" aria-hidden="true" />
            </Button>
          </div>
        </div>

        <hr className="border-t-0 border-b border-b-gray-300 mx-1" />

        <Typography token="font-bold/text/xs" className="text-gray-600">
          Replace search text
        </Typography>

        <Input.Field
          value={replaceTerm}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setReplaceTerm(e.target.value)}
          placeholder="Replace"
          className="flex-1 rounded-md"
        />

        <div className="flex gap-2 justify-end">
          <Button
            type="button"
            variant="primary"
            className="px-2 py-1"
            size="xxs"
            onClick={() => editor.chain().replaceAll().run()}
          >
            Replace All
          </Button>

          <Button
            type="button"
            size="xxs"
            variant="primary"
            className="px-2 py-1"
            onClick={() => editor.chain().replace().run()}
          >
            Replace
          </Button>
        </div>

        <IconButton className="absolute px-0 py-0 -right-3 -top-3 rounded-full" onClick={forceClose}>
          <XCircleIcon className="w-6 h-6" aria-hidden="true" />
        </IconButton>
      </div>
    ),
    [searchTerm, onSearchInputKeyDown, searchStatusString, replaceTerm, forceClose, editor]
  );

  return (
    <Tippy
      content={tippyContent}
      interactive
      onClickOutside={triggerClose}
      onCreate={setTippyInstance}
      onHide={onTippyHide}
      placement="bottom-end"
      maxWidth="none"
      zIndex={EDITOR_MENUS_Z_INDEX}
      visible={showSearchAndReplaceMenu}
    >
      <SearchButton
        $variant="tertiary"
        $size="small"
        editor={editor}
        $isIconButton
        $showTooltip
        onClick={() => setShowSearchAndReplaceMenu(true)}
        className={showSearchAndReplaceMenu ? 'is-active' : ''}
      />
    </Tippy>
  );
});
