import { ReactNode } from 'react';

import { Typography, TypographyStack } from '@/components/Typography';

interface Props {
  title: string;
  description?: string | ReactNode;
  cta?: ReactNode;
}

const CardHeader = ({ title, description, cta }: Props) => (
  <div className="flex flex-row justify-between">
    <TypographyStack>
      <Typography token="font-medium/text/base">{title}</Typography>
      {typeof description === 'string' ? (
        <Typography token="font-normal/text/sm" colorWeight="500">
          {description}
        </Typography>
      ) : (
        description
      )}
    </TypographyStack>
    {cta ? <div>{cta}</div> : null}
  </div>
);

export default CardHeader;
