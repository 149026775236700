import { ArrowPathIcon } from '@heroicons/react/20/solid';

import OptionsDropdown from '../Helpers/OptionsDropdown';

interface Props {
  resettableValue: any;
  onSave: (newValue: any) => void;
  resetValueLabel?: string;
}

const ResetMenu = ({ resettableValue, resetValueLabel, onSave }: Props) => (
  <OptionsDropdown
    dropdownClassname="!-top-12 !right-0"
    title="Reset to..."
    options={[
      {
        label: resetValueLabel || 'Factory default',
        Icon: ArrowPathIcon,
        onClick: () => onSave(resettableValue),
      },
    ]}
  >
    <button type="button" className="cursor-pointer focus:none outline-none focus:outline-none" onClick={() => {}}>
      <ArrowPathIcon className="w-3 h-3 text-surface-500 hover:text-surface-600" />
    </button>
  </OptionsDropdown>
);

export default ResetMenu;
