import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { AdNetworkAdvertiserPublisherListItem } from '@/interfaces/ad_network/internal/advertiser_publisher_list_item';
import api from '@/services/swarm';

export default function usePublisherListItem({
  advertiserId,
  publisherListItemId,
}: {
  advertiserId: string;
  publisherListItemId: string;
}) {
  return useQuery<AdNetworkAdvertiserPublisherListItem, AxiosError>(
    ['ad_network', 'internal', 'advertisers', advertiserId, 'publisher_list_items', publisherListItemId],
    () =>
      api
        .get(`/ad_network/internal/advertisers/${advertiserId}/publisher_list_items/${publisherListItemId}`)
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
