/* eslint-disable react/no-danger */
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyStack } from '@/components/Typography';
import { useStripeBillingSession } from '@/hooks/useBilling';
import { BillingActions } from '@/interfaces/billing';
import { PlanPrice } from '@/interfaces/plan_price';
import { capitalize, currencyFormatter } from '@/utils';

import { useBillingSummary, useCreatePlanPriceChange, usePlanPriceChange } from '../../../_hooks';
import { StripePromotionCode } from '../../../_hooks/useApplyPromotionCode';

import DisclaimerText from './DisclaimerText';
import PriceBreakdown from './PriceBreakdown';
import PromotionCodeField from './PromotionCode';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
  publicationId: string;
  currentPlanPrice: PlanPrice;
  newPlanPriceId: string;
  onCancel?: () => void;
  beforeConfirm?: () => Promise<any>;
}

const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onCancel,
  organizationId,
  publicationId,
  currentPlanPrice,
  newPlanPriceId,
  beforeConfirm,
}) => {
  const [appliedDiscount, setAppliedDiscount] = useState<StripePromotionCode>();

  const {
    data: changeData,
    isLoading: changeDataLoading,
    isError: changeDataError,
  } = usePlanPriceChange({ organizationId, newPlanPriceId, promotionCode: appliedDiscount?.code });

  const {
    data: summaryData,
    isLoading: summaryDataLoading,
    isError: summaryDataError,
  } = useBillingSummary({ organizationId, publicationId });

  const stripeCheckoutSession = useStripeBillingSession({
    publicationId,
    organizationId,
    action: BillingActions.UPGRADE_CONFIRM,
    trackingEvent: 'Started Checkout',
  });

  const mutation = useCreatePlanPriceChange({
    publicationId,
    organizationId,
    onSuccess: () => {
      toast.success('Plan tier changed');
      setTimeout(() => window.location.reload(), 500);
    },
  });

  const isLoading = changeDataLoading || summaryDataLoading;
  const isError = changeDataError || summaryDataError;
  const missingPaymentMethod = !summaryData?.billing_details?.last4;

  // If we don't have a default payment method on file, we want to send them
  // through the stripe subscription change confirmation flow b/c it will handle
  // gathering that information.
  const onConfirm = useCallback(
    () =>
      missingPaymentMethod
        ? stripeCheckoutSession.mutate({ plan_price_id: newPlanPriceId, promotion_code: appliedDiscount?.code })
        : mutation.mutate({ newPlanPriceId, promotionCode: appliedDiscount?.code }),
    [appliedDiscount?.code, missingPaymentMethod, mutation, newPlanPriceId, stripeCheckoutSession]
  );

  const onProceed = beforeConfirm ? () => beforeConfirm().finally(onConfirm) : onConfirm;

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      resourceId={organizationId}
      headerText="Confirm your updates"
      onProceed={onProceed}
      isWorking={mutation.isLoading || stripeCheckoutSession.isLoading}
      disabled={isLoading}
      actionText={missingPaymentMethod ? 'Go to Checkout' : 'Confirm'}
    >
      <LoadingBox isLoading={isLoading} isError={isError} height={320} backgroundClassName="bg-transparent">
        {changeData && (
          <TypographyStack gap="2">
            <Typography as="div" className="pb-4">
              You are currently on the {capitalize(currentPlanPrice.plan_name)} plan @{' '}
              {currentPlanPrice.max_subscriptions.toLocaleString()} subscriptions, billed {currentPlanPrice.interval}ly.{' '}
              This change will update you to the{' '}
              <b>
                {capitalize(changeData?.new_plan_name)} plan @ {changeData?.new_max_subscriptions.toLocaleString()}{' '}
                subscriptions, billed {changeData?.new_interval}ly
              </b>{' '}
              {changeData.is_upgrade ? 'starting immediately' : 'starting on your next billing cycle'}.
            </Typography>

            <div className="bg-surface-50 border border-surface-200 rounded p-4 space-y-6">
              <div className="flex justify-between">
                <Typography as="div" token="font-normal/text/sm">
                  What you&apos;ll pay {changeData.new_interval}ly starting
                  <br />
                  {changeData.next_billing_date
                    ? moment(changeData.next_billing_date).format('LL')
                    : 'on your next billing cycle'}
                </Typography>
                <Typography as="div" token="font-normal/text/sm">
                  {currencyFormatter(changeData.new_unit_amount / 100, 'USD', 2, 2)}
                </Typography>
              </div>
              <hr />
              <div className="flex justify-between">
                <Typography as="div" token="font-normal/text/sm">
                  Amount due today
                  <br />
                  Prorated for this billing cycle
                </Typography>
                <Typography as="div" token="font-bold/text/sm">
                  {changeData.is_upgrade
                    ? currencyFormatter(changeData.amount_due / 100, 'USD', 2, 2)
                    : currencyFormatter(0, 'USD', 2, 2)}
                </Typography>
              </div>
              {changeData.is_upgrade && (
                <PromotionCodeField
                  organizationId={organizationId}
                  publicationId={publicationId}
                  planPriceId={newPlanPriceId}
                  appliedDiscount={appliedDiscount}
                  onApply={setAppliedDiscount}
                />
              )}
              <PriceBreakdown
                visible={changeData.is_upgrade}
                amountDue={changeData.amount_due}
                lines={changeData.lines}
              />
            </div>

            {!missingPaymentMethod && (
              <DisclaimerText
                organizationId={organizationId}
                publicationId={publicationId}
                isUpgrade={changeData.is_upgrade}
                amountDue={changeData.amount_due}
                nextBillingDate={changeData.next_billing_date}
              />
            )}
          </TypographyStack>
        )}
      </LoadingBox>
    </ActionModal>
  );
};

export default ConfirmationModal;
