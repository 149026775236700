interface Props {
  newUntil?: Date;
  isPingShowing?: boolean;
  size?: 'small' | 'medium';
}

const NewBadge: React.FC<Props> = ({ newUntil, isPingShowing = true }) => {
  if (newUntil && newUntil < new Date()) {
    return null;
  }

  return (
    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-blue-100 text-blue-500 mr-2">
      {isPingShowing && (
        <>
          <svg
            className="-ml-0.5 mr-1.5 h-2 w-2 text-blue-400 animate-ping absolute"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx={4} cy={4} r={3} />
          </svg>
          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-blue-400 relative" fill="currentColor" viewBox="0 0 8 8">
            <circle cx={4} cy={4} r={3} />
          </svg>
        </>
      )}
      New
    </span>
  );
};

export default NewBadge;
