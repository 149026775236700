import React, { useCallback } from 'react';

import { Range } from '@/components/UI/Range';

export type SaturationControlProps = {
  saturation: number;
  setSaturation: (saturation: number) => void;
};

export const SaturationControl = React.memo(({ saturation, setSaturation }: SaturationControlProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSaturation(parseFloat(e.target.value));
    },
    [setSaturation]
  );

  return <Range label="Saturation" min={-100} max={100} step="5" value={saturation} onChange={handleChange} />;
});

SaturationControl.displayName = 'SaturationControl';
