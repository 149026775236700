import { useEffect, useState } from 'react';
import { CaretRight, Check, TextT, X } from '@phosphor-icons/react';
import { getUsedFonts } from '@shared/dream-components';

import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Search } from '@/routes/website/_components/UI/Search';
import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';
import { getFontFamilies, getFontPreviewUrl } from '@/routes/website/_utils/getFonts';

import { useNavbarContext } from '../../../../NavbarContext';

const DEFAULT_FONT_FAMILY = 'Inter';

export const FontSettings = () => {
  const { content, selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  const [fonts, setFonts] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [navbarFonts, setNavbarFonts] = useState<string[]>([]);
  const [group, setGroup] = useState<'all' | 'in-page'>('all');
  useEffect(() => {
    getFontFamilies().then((w) => setFonts(w));
  }, []);

  const filteredFonts = search ? fonts.filter((font) => font.toLowerCase().includes(search.toLowerCase())) : fonts;

  const handleSetFontFamily = (fontFamily: string) => {
    if (!selectedContent || !selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { fontFamily });
    setIsOpen(false);
  };

  useEffect(() => {
    if (!content) return;
    getUsedFonts(content, ['Inter'], 'families').then((f) => setNavbarFonts(Array.from(new Set(f))));
  }, [content]);

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  const contentValue: string =
    (selectedContent?.attrs && 'fontFamily' in selectedContent.attrs && (selectedContent.attrs.fontFamily as string)) ||
    '';

  const currentFont = contentValue || DEFAULT_FONT_FAMILY;
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <div className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Font
        </Text>

        <PopoverTrigger asChild>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 p-2">
              <div className="flex items-center gap-1">
                <TextT className="text-wb-secondary" weight="bold" />
                <Text size="2xs" weight="medium">
                  {currentFont}
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </PopoverTrigger>
      </div>

      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="bg-wb-primary w-full max-h-[400px] min-h-0 p-3 rounded-lg shadow-xl flex flex-col gap-2">
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Fonts
            </Text>

            <div
              className="p-1 text-wb-secondary bg-wb-secondary rounded-full"
              onClick={() => setIsOpen(false)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsOpen(false);
                }
              }}
            >
              <X className="cursor-pointer" />
            </div>
          </div>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm mb-3">
            <ToggleGroup
              className="p-[2px] grid grid-cols-2"
              type="single"
              defaultValue="left"
              value={group}
              onValueChange={(s) => setGroup(s as 'all' | 'in-page')}
            >
              <ToggleGroupItem value="all">All</ToggleGroupItem>
              <ToggleGroupItem value="in-page">In this page</ToggleGroupItem>
            </ToggleGroup>
          </div>

          {group === 'all' ? (
            <>
              <Search placeholder="Search font..." value={search} onChange={(e) => setSearch(e.target.value)} />

              <div className="grow min-h-0 overflow-y-scroll flex flex-col gap-2">
                {filteredFonts.map((font) => (
                  <div
                    key={font}
                    className={`flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer border hover:bg-wb-secondary ${
                      font === currentFont
                        ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                        : 'bg-wb-primary border-transparent'
                    }`}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleSetFontFamily(font);
                      }
                    }}
                    onClick={() => {
                      handleSetFontFamily(font);
                    }}
                  >
                    <img src={getFontPreviewUrl(font)} alt={font} height={12} className="h-[12px] w-auto" />
                    {font === currentFont && <Check weight="bold" className="text-wb-accent" />}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="grow min-h-0 overflow-y-scroll flex flex-col gap-2">
              {navbarFonts.map((font) => (
                <div
                  key={font}
                  className={`flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer border hover:bg-wb-secondary ${
                    font === currentFont
                      ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                      : 'bg-wb-primary border-transparent'
                  }`}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSetFontFamily(font);
                    }
                  }}
                  onClick={() => {
                    handleSetFontFamily(font);
                  }}
                >
                  <img src={getFontPreviewUrl(font)} alt={font} height={12} className="h-[12px] w-auto" />
                  {font === currentFont && <Check weight="bold" className="text-wb-accent" />}
                </div>
              ))}
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
