import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { WebhookDestinationTestResponse } from '@/interfaces/webhook_destination';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

interface Props {
  webhookDestinationId: string;
  onSuccess?: (response: WebhookDestinationTestResponse) => void;
}

interface Variables {
  automationId: string;
  subscriberId?: string;
  webhookDestinationTestParams: {
    url: string;
    request_method: string;
    content_type: string;
    headers: Array<{ key: string; value: string }>;
  };
}

export default function useTestCreate({ webhookDestinationId, onSuccess }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  const createTest = (variables: Variables) =>
    api
      .post(`/webhook_destinations/${webhookDestinationId}/tests`, {
        publication_id: currentPublicationId,
        subscriber_id: variables.subscriberId,
        automation_id: variables.automationId,
        webhook_destination_test_params: {
          url: variables.webhookDestinationTestParams.url,
          request_method: variables.webhookDestinationTestParams.request_method,
          content_type: variables.webhookDestinationTestParams.content_type,
          headers: variables.webhookDestinationTestParams.headers,
        },
      })
      .then((res) => res.data);

  return useMutation<WebhookDestinationTestResponse, unknown, Variables>(createTest, {
    onSuccess: async (response) => {
      toast.success('Webhook test request sent!');
      analytics.track('tested a webhook send');
      onSuccess?.(response);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.errors?.base || 'Something went wrong, please try again.');
    },
  });
}
