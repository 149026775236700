import { useParams } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import { useCampaign } from '@/hooks/useAdNetwork/internal';
import useCampaignPaymentRequest from '@/hooks/useAdNetwork/internal/useCampaignPaymentRequest';
import useCampaignPaymentRequestUpdate from '@/hooks/useAdNetwork/internal/useCampaignPaymentRequestUpdate';
import { PageHeading } from '@/routes/ad_network/_components/PageHeading';

import { Form } from './_components/Form';

export default function Edit() {
  const { campaign_id: campaignId, payment_request_id: id } = useParams() as {
    campaign_id: string;
    payment_request_id: string;
  };
  const { data: campaign } = useCampaign({ id: campaignId });
  const { data, isLoading, isError } = useCampaignPaymentRequest({ campaignId, id });
  const onSubmitMutation = useCampaignPaymentRequestUpdate({ campaignId, id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="../..">{campaign?.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="..">Payment Requests</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Edit</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <LoadingBox isLoading={isLoading} isError={isError} height="100vh">
        {data && <Form onSubmitMutation={onSubmitMutation} paymentRequest={data} />}
      </LoadingBox>
    </>
  );
}
