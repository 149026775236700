import styled from 'styled-components';

const LargeSubtitle = styled.h3.attrs({
  className: 'mt-6 text-3xl font-bold text-gray-900',
})`
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  @media (max-width: 500px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export default LargeSubtitle;
