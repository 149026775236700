import { Link, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

interface Props {
  Icon: React.ElementType;
  BoldIcon: React.ElementType;
  title: string;
  to: string;
  largeNavOpen: boolean;
}

export const NavParentItem = ({ Icon, BoldIcon, title, to, largeNavOpen }: Props) => {
  const location = useLocation();
  const isCurrentPath = location.pathname === to;

  return (
    <Link
      to={to}
      data-tip
      data-for={`nav-tooltip-${title}`}
      className={cx(
        'p-2 flex rounded-lg flex-row space-x-2',
        isCurrentPath ? 'bg-surface-100' : 'hover:bg-surface-100',
        largeNavOpen ? '' : 'aspect-1 flex flex-col justify-center items-center',
        !largeNavOpen && isCurrentPath && 'border border-surface-200',
        largeNavOpen ? 'w-full' : 'w-fit mx-auto'
      )}
    >
      {isCurrentPath ? (
        <BoldIcon className={cx('my-auto text-surface-700 h-4 w-4')} />
      ) : (
        <Icon className={cx('my-auto text-surface-700 h-4 w-4')} />
      )}
      {largeNavOpen && (
        <Typography token="font-medium/text/sm" className="my-auto text-gray-700">
          {title}
        </Typography>
      )}
      {!largeNavOpen && (
        <ReactTooltip id={`nav-tooltip-${title}`} effect="solid" place="right">
          {title}
        </ReactTooltip>
      )}
    </Link>
  );
};
