import { useSearchParams } from 'react-router-dom';
import { CalendarIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { Typography } from '@/components/Typography';
import { useCurrentPublication } from '@/hooks';
import useApplicationScores from '@/hooks/boosts/grow/useApplicationScores';
import useBoostsFrozen from '@/hooks/boosts/useBoostsFrozen';
import { ApplicationDemographics } from '@/interfaces/boosts/grow/application_demographics';
import { ApplicationScores } from '@/interfaces/boosts/grow/application_scores';
import { BoostPublication } from '@/interfaces/boosts/grow/boost_publication';
import Badge, { BadgeSize, BadgeType } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { Score } from '@/ui/Score';
import { ScoreValue } from '@/ui/Score/Score.types';
import pluralize from '@/utils/pluralize';

import { applicationScoresInsufficientData } from '../../../utils/applicationScoresInsufficientData';
import { SCORE_QUALITY_LABELS } from '../../constants';
import ApplicationDemographicBadges from '../ApplicationDemographicBadges';

import DiscordIcon from './icons/DiscordIcon';
import FacebookIcon from './icons/FacebookIcon';
import InstagramIcon from './icons/InstagramIcon';
import LinkedinIcon from './icons/LinkedInIcon';
import ThreadsIcon from './icons/ThreadsIcon';
import TiktokIcon from './icons/TiktokIcon';
import TwitterIcon from './icons/TwitterIcon';
import YoutubeIcon from './icons/YoutubeIcon';
import PublicationScores from './PublicationScores';

const SOCIAL_SORT_ORDER = ['facebook', 'instagram', 'threads', 'youtube', 'twitter', 'linkedin', 'tiktok', 'discord'];

interface Props {
  boostAgreementId: string;
  publication: BoostPublication;
  publicationMessage: string;
  applicationScores?: ApplicationScores;
  applicationScoresIsLoading: boolean;
  applicationDemographics?: ApplicationDemographics;
  applicationDemographicsIsLoading: boolean;
  isOpen: boolean;
  isAccepting: boolean;
  isRejecting: boolean;
  isReporting: boolean;
  onClickReject: () => void;
  onClickAccept: () => void;
  onClickReport: () => void;
}

const ReviewBoostModalV3 = ({
  boostAgreementId,
  publication,
  publicationMessage,
  applicationScores,
  applicationScoresIsLoading,
  applicationDemographics = [],
  applicationDemographicsIsLoading,
  isOpen,
  isAccepting,
  isRejecting,
  isReporting,
  onClickReject,
  onClickAccept,
  onClickReport,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen;
  const {
    id: publicationId,
    logo_url: logoUrl,
    created_at: joinDate,
    social_links: socialLinks,
    name,
    description,
    tags,
    url
  } = publication;
  const { post_count: postCount } = publication;
  const insufficientData = !applicationScores || applicationScores && applicationScoresInsufficientData(applicationScores);
  const sortedSocialLinks = Object.fromEntries(
    Object.entries(socialLinks).sort(([a], [b]) => {
      return SOCIAL_SORT_ORDER.indexOf(a) - SOCIAL_SORT_ORDER.indexOf(b);
    })
  );

  const { data: currentPublication } = useCurrentPublication();
  const currentPublicationId = currentPublication?.id;
  const {
    data: currentApplicationScores,
    isLoading: currentPublicationScoresIsLoading
  } = useApplicationScores(currentPublicationId, !!currentPublication);

  const portalMountedId = `${boostAgreementId}-review-modal`;
  const isWorking = isAccepting || isRejecting || isReporting;

  const handleClose = () => {
    searchParams.delete('boost_agreement_id');
    setSearchParams(searchParams);
  };

  const handleAccept = () => {
    onClickAccept();
    handleClose();
  };

  const handleReject = () => {
    onClickReject();
    handleClose();
  };

  const handleReport = () => {
    onClickReport();
    handleClose();
  };

  const modalFooterContent = (
    <div className="w-full flex flex-wrap-reverse gap-8 justify-between">
      <div>
        <Button
          variant="primary-danger"
          type="button"
          size="sm"
          disabled={isWorking || boostsFrozen}
          loading={isReporting}
          onClick={handleReport}
        >
          Report Publication
        </Button>
      </div>

      <div className="flex space-x-3">
        <Button
          variant="primary-inverse"
          type="button"
          size="sm"
          disabled={isWorking || boostsFrozen}
          loading={isRejecting}
          onClick={handleReject}
        >
          Reject
        </Button>

        <Button
          variant="primary"
          type="button"
          size="sm"
          disabled={isWorking || boostsFrozen}
          loading={isAccepting}
          onClick={handleAccept}
        >
          Accept
        </Button>
      </div>
    </div>
  )

  return (
    <ActionModal
      resourceId="review-boost"
      modalSize="lg"
      bodyId={portalMountedId}
      disabled={boostsFrozen}
      isOpen={isOpen}
      isWorking={isWorking}
      onClose={handleClose}
      footer={modalFooterContent}
      hideCancelButton
    >
      <>
        <div className="flex items-start space-x-3 mb-8">
          {logoUrl && (
            <img
              src={logoUrl}
              alt={`${name} logo`}
              className="w-12 h-12 rounded-md"
            />
          )}
          <div className="flex flex-col space-y-2">
            <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
              <Typography token="font-medium/text/sm" colorWeight="900" as="h3">
                <a href={url} target="_blank" rel="noreferrer" className="hover:underline">
                  {name}
                </a>
              </Typography>
            </div>
            <Typography
              token="font-normal/text/sm"
              colorWeight="700"
              as="p"
            >
              {description}
            </Typography>
          </div>
        </div>

        <div className="space-y-6">
          <div className="w-full grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-10">
            <div className="space-y-2">
              <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
                Join date
              </Typography>
              <div className="flex items-center space-x-1">
                <CalendarIcon className="w-4 h-4 text-surface-500" />
                <Typography token="font-normal/text/sm" colorWeight="900" as="span">
                  Joined {moment(joinDate).format('MMM YYYY')}
                </Typography>
              </div>
            </div>

            <div className="space-y-2">
              <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
                Total posts
              </Typography>
              <SkeletonLoader
                isLoading={applicationScoresIsLoading}
                skeletons={
                  <div className="flex items-center space-x-1">
                    <div className="w-16"><Skeleton className="h-5 py-0.5" /></div>
                  </div>
                }
              >
                <div className="flex items-center space-x-1">
                  <DocumentTextIcon className="w-4 h-4 text-surface-500" />
                  <Typography token="font-normal/text/sm" colorWeight="900" as="span">
                    {pluralize('post', postCount || 0)}
                  </Typography>
                </div>
              </SkeletonLoader>
            </div>
          </div>

          <div className="w-full grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-10">
            <div className="space-y-2">
              <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
                Website URL
              </Typography>
              <Typography token="font-semibold/text/xs" colorWeight="900" as="div">
                <a href={url} target="_blank" rel="noreferrer" className="block truncate hover:underline">
                  {url}
                </a>
              </Typography>
            </div>

            <div className="space-y-2">
              <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
                Social links
              </Typography>
              {Object.keys(sortedSocialLinks).length === 0 && <Typography token="font-semibold/text/xs" colorWeight="900" as="div">-</Typography>}
              <div className="flex space-x-2">
                {Object.entries(sortedSocialLinks).map(([key, value]) => (
                  <a
                    key={key}
                    href={value}
                    target="_blank"
                    rel="noreferrer"
                    className="text-surface-500 hover:text-surface-700 active:text-surface-400"
                  >
                    {key === 'facebook' && <FacebookIcon className="w-5 h-5 transition-colors" />}
                    {key === 'instagram' && <InstagramIcon className="w-5 h-5 transition-colors" />}
                    {key === 'threads' && <ThreadsIcon className="w-5 h-5 transition-colors" />}
                    {key === 'youtube' && <YoutubeIcon className="w-5 h-5 transition-colors" />}
                    {key === 'twitter' && <TwitterIcon className="w-5 h-5 transition-colors" />}
                    {key === 'linkedin' && <LinkedinIcon className="w-5 h-5 transition-colors" />}
                    {key === 'tiktok' && <TiktokIcon className="w-5 h-5 transition-colors" />}
                    {key === 'discord' && <DiscordIcon className="w-5 h-5 transition-colors" />}
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="w-full grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-10">
            <div className="space-y-2">
              <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
                Categories
              </Typography>
              <div className="flex flex-wrap gap-2">
                {tags.map((tag) => (
                  <Badge
                    key={tag.id}
                    text={tag.name}
                    size={BadgeSize.SM}
                    type={BadgeType.DEFAULT_FILLED}
                  />
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
                Boost Quality
              </Typography>
              <Score
                publicationId={publicationId}
                portalMountedId={portalMountedId}
                labels={SCORE_QUALITY_LABELS}
                score={!insufficientData ? applicationScores?.referral || 0 : 0 as ScoreValue}
              />
            </div>
          </div>

          <div className="space-y-2">
            <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
              Message from the publication
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="900" as="p">
              {publicationMessage}
            </Typography>
          </div>

          <div className="space-y-2">
            <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
              Top audience demographics
            </Typography>
            <div>
              <ApplicationDemographicBadges
                publicationId={publication.id}
                portalMountedId={portalMountedId}
                applicationDemographics={applicationDemographics}
                maxVisible={10}
                isLoading={applicationDemographicsIsLoading}
              />
              {!applicationDemographics.length && !applicationDemographicsIsLoading && (
                <Typography token="font-normal/text/sm" colorWeight="900" as="p">
                  Unknown
                </Typography>
              )}
            </div>
          </div>

          <div className="space-y-2">
            <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
              Compare metrics
            </Typography>
            <div className="space-y-2">
              <PublicationScores
                publicationId={publicationId}
                portalMountedId={portalMountedId}
                isLoading={applicationScoresIsLoading}
                publicationName={name}
                scores={applicationScores}
              />
              <PublicationScores
                publicationId={currentPublicationId || ""}
                portalMountedId={portalMountedId}
                isLoading={currentPublicationScoresIsLoading}
                publicationName={currentPublication?.name || ""}
                scores={currentApplicationScores}
              />
            </div>
          </div>
        </div>
      </>
    </ActionModal>
  );
};

export default ReviewBoostModalV3;
