import { Editor } from '@tiptap/core';
import { NodeSelection, Plugin, PluginKey, Selection, TextSelection } from '@tiptap/pm/state';

import { modKey } from '../../utils';

import { drawMultinodeSelectionDecorations, MultiNodeSelection } from './MultiNodeSelection';

export const MultiNodeSelectionPluginKey = new PluginKey('multiNodeSelection');

export const getMultiNodeSelectionPlugin = (editor: Editor) => {
  return new Plugin({
    key: MultiNodeSelectionPluginKey,

    props: {
      decorations: drawMultinodeSelectionDecorations,

      handleDOMEvents: {
        mousedown: (view, event) => {
          if (!event[modKey]) {
            return;
          }

          const eventPos = view.posAtCoords({ left: event.clientX, top: event.clientY });

          if (!eventPos) {
            return;
          }

          const { inside: clickedNodePos } = eventPos;

          const { doc, selection } = view.state;

          if (clickedNodePos === -1) {
            return;
          }

          const $clickedNodePos = doc.resolve(clickedNodePos);

          let newSelection: Selection | null = null;

          if (selection instanceof NodeSelection && clickedNodePos !== selection.from) {
            const { node } = selection;

            const newNode = view.state.doc.nodeAt(clickedNodePos);

            let isDescendant = false;

            newNode?.descendants((desc) => {
              if (desc.eq(node)) {
                isDescendant = true;
              }
            });

            if (isDescendant) {
              return;
            }

            newSelection = new MultiNodeSelection([selection.$from, $clickedNodePos]);
          }

          if (selection instanceof MultiNodeSelection) {
            if (selection.posList.includes(clickedNodePos)) {
              const $newPosList = selection.$posList.filter((val) => val.pos !== clickedNodePos);

              if ($newPosList.length === 0) {
                newSelection = new NodeSelection(selection.$posList[0]);
              } else {
                newSelection = new MultiNodeSelection($newPosList);
              }
            } else {
              const node = doc.nodeAt(clickedNodePos);

              const $newPosList = selection.$posList.slice();

              node?.descendants((_desc, pos) => {
                const adjustedPos = pos + clickedNodePos + 1;

                const inSelectedNodes = $newPosList.findIndex((val) => val.pos === adjustedPos);

                if (inSelectedNodes !== -1) {
                  $newPosList.splice(inSelectedNodes, 1);
                }
              });

              const $finalPosList = [...$newPosList, $clickedNodePos];

              if ($finalPosList.length === 1) {
                newSelection = new NodeSelection($finalPosList[0]);
              } else if ($finalPosList.length > 1) {
                newSelection = new MultiNodeSelection($finalPosList);
              }
            }
          }

          if (selection instanceof TextSelection) {
            const $currentNodePos = doc.resolve(selection.$from.start() - 1);

            if ($currentNodePos.pos === $clickedNodePos.pos) {
              newSelection = new NodeSelection($clickedNodePos);
            } else {
              newSelection = new MultiNodeSelection([$currentNodePos, $clickedNodePos]);
            }
          }

          if (!newSelection) {
            return;
          }

          event.preventDefault();

          editor
            .chain()
            .command(({ tr }) => {
              if (newSelection) {
                tr.setSelection(newSelection);
              }

              return true;
            })
            .focus()
            .run();
        },
      },
    },
  });
};
