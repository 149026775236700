import { useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { MultiSelect } from '../../../components/Form';

import NotEditing from './NotEditing';
import SaveButton from './SaveButton';

interface Props {
  name?: string;
  label: string;
  description: string;
  options: any[];
  currentSelections: string[];
  maxSelections?: number;
  minSelections?: number;
  isSaving: boolean;
  onSave: (val: string[]) => Promise<any>;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
  noBorder?: boolean;
}

const SwitchForm = ({
  name,
  label,
  description,
  onSave,
  options,
  maxSelections = 3,
  minSelections = 0,
  toastSuccessMessage,
  toastErrorMessage,
  currentSelections,
  isSaving,
  noBorder = false,
}: Props) => {
  const [selections, setSelections] = useState<string[]>(currentSelections);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const showSaving = isSaving && isEditing;

  const handleSave = async () => {
    if (selections.length < minSelections) {
      toast.error('Please select at least one item');
      return;
    }

    await onSave(selections)
      .then(() => {
        if (toastSuccessMessage) {
          toast.success(toastSuccessMessage);
          setIsEditing(false);
        }
      })
      .catch(() => {
        if (toastErrorMessage) {
          toast.error(toastErrorMessage);
        }
      });
  };

  if (isEditing) {
    return (
      <div className="flex justify-between pb-4 space-x-6">
        <div className="pt-3">
          <MultiSelect
            label={label}
            isMoreShowing={false}
            options={options}
            currentSelections={selections}
            inputName={name || label.split(' ').join('-')}
            helperText={description}
            maxSelections={maxSelections}
            onChange={(selected: any) => {
              setHasChanged(true);
              const newSelections = selected.map((s: string) => String(s));
              setSelections(newSelections);
            }}
          />
        </div>
        <SaveButton
          isSaving={showSaving}
          isEditing={isEditing}
          closeEditing={() => setIsEditing(false)}
          changesDetected={Boolean(hasChanged)}
          type="button"
          onClick={handleSave}
        />
      </div>
    );
  }

  return (
    <div className={cx('flex justify-between border-gray-100 pb-4', noBorder ? '' : 'border-b')}>
      <div className="space-y-3">
        <NotEditing label={label} value={description} />
        {!selections.length ? (
          <p className="block text-xs font-regular italic text-gray-400">No Items Selected</p>
        ) : (
          <ul className="flex mt-4 flex-wrap transition-all">
            {options.map(([id, optionValue]) => {
              if (selections.includes(String(id))) {
                return (
                  <li key={id} className="mr-2 mb-2">
                    <div className="flex items-center border rounded text-xs py-1 px-2 relative group disabled:cursor-not-allowed disabled:opacity-50 border-primary-50 text-primary-800  bg-primary-50">
                      {optionValue}
                    </div>
                  </li>
                );
              }

              return null;
            })}
          </ul>
        )}
      </div>

      <div className="pt-4">
        <button
          className="h-6 w-6 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
          type="button"
          onClick={() => setIsEditing(true)}
        >
          <PencilIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};

export default SwitchForm;
