import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import api from '../services/swarm';

export default function useClickmapPostPreview(postId: string) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery(
    ['posts', postId, 'clickmap_preview'],
    () =>
      api
        .get(`/posts/${postId}/clickmap_preview`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: 30000,
    }
  );
}
