import { ChevronDoubleRightIcon, DocumentMinusIcon } from '@heroicons/react/20/solid';

import { useCurrentPublication } from '@/hooks';
import { useUpdatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { SettingsContainer, SettingsGroup } from '../../../../ui/Settings/SettingsContainer';
import SwitchDescriptionInput from '../../../../ui/Settings/SwitchDescriptionInput';

const WebBuilderSeo = () => {
  const { data: currentPublication }: any = useCurrentPublication();
  const currentPublicationId = useCurrentPublicationId();
  const publicationMutation = useUpdatePublication(currentPublicationId);

  const seoSettings = [
    {
      name: 'breadcrumbs',
      title: 'Breadcrumbs',
      description:
        'Breadcrumbs are a navigational aid that allows users to keep track of their current location on your website. Enable to show them on your web pages.',
      icon: ChevronDoubleRightIcon,
      onSave: (val: boolean) => publicationMutation.mutateAsync({ render_breadcrumbs: val }),
      value: currentPublication?.render_breadcrumbs || false,
      toastSuccessMessage: 'Saved!',
    },
    {
      name: 'allow_indexing',
      title: 'Remove Indexing',
      description:
        'Enable noindex meta tag in the header of your publication to prevent your posts from showing up in Google Search. ',
      icon: DocumentMinusIcon,
      onSave: (val: boolean) => publicationMutation.mutateAsync({ noindex_enabled: val }),
      value: currentPublication?.noindex_enabled || false,
      toastSuccessMessage: 'Saved!',
    },
  ];

  return (
    <SettingsContainer>
      <SettingsGroup title="SEO" description="Configure search engine optimization details below.">
        <div className="flex flex-col space-y-6">
          {currentPublication &&
            seoSettings.map((setting) => {
              return (
                <SwitchDescriptionInput
                  key={setting.name}
                  name={setting.name}
                  title={setting.title}
                  Icon={setting.icon}
                  value={setting.value}
                  onSave={setting.onSave}
                  description={setting.description}
                  toastSuccessMessage={setting.toastSuccessMessage}
                />
              );
            })}
        </div>
      </SettingsGroup>
    </SettingsContainer>
  );
};

export default WebBuilderSeo;
