import { useMemo, useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { SetReferralsConditionsButton } from '../../../../buttons/SetReferralsConditions';
import { Button } from '../../../../ui/Button';
import { Icon } from '../../../../ui/Icon';
import { Toolbar } from '../../../../ui/Toolbar';

const actions = [
  { label: 'Lower than', value: 'lt', iconName: 'LowerThan', active: false },
  { label: 'Equal to', value: 'eq', iconName: 'EqualTo', active: true },
  { label: 'Greater than', value: 'gt', iconName: 'GreaterThan', active: true },
];

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: false,
};

export const ReferralsConditionsSelect = ({
  editor,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  currentNode: Node | null;
  currentNodePos: number;
}): JSX.Element => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const activeAction = useMemo((): any => {
    return actions.find((action) => {
      return action.value === currentNode?.attrs.referralCondition;
    });
  }, [editor, currentNode]);

  return (
    <Tippy
      render={(attrs) => (
        <Toolbar
          tabIndex={-1}
          $isVertical
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attrs}
        >
          {actions.map((action) => (
            <SetReferralsConditionsButton
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonProps}
              key={action.label}
              editor={editor as any}
              currentNode={currentNode}
              currentNodePos={currentNodePos}
              name={action.label}
              icon={action.iconName}
              title={action.label}
              condition={action.value}
              $muted
              $active={activeAction?.value === action.value}
              $showTooltip={false}
              onClick={() => {
                setIsPanelOpen(false);
              }}
            />
          ))}
        </Toolbar>
      )}
      offset={[0, 12]}
      placement="bottom-start"
      interactive
      visible={isPanelOpen}
      onShow={() => {
        setIsPanelOpen(true);
      }}
      onHidden={() => {
        setIsPanelOpen(false);
      }}
      onClickOutside={() => {
        setIsPanelOpen(false);
      }}
    >
      <div>
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          editor={editor}
          $leftSlot={<Icon name={activeAction?.iconName ?? 'EqualTo'} />}
          $rightSlot={<Icon name="ChevronDown" $size="0.66rem" />}
          $active
          $muted
          onClick={() => {
            setIsPanelOpen(!isPanelOpen);
          }}
          disabled={!activeAction}
          style={{ height: '100%' }}
        />
      </div>
    </Tippy>
  );
};

export default ReferralsConditionsSelect;
