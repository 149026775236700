import { EmptyCard, ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { useLeaderboardMetrics } from '@/hooks/useLeaderboardMetrics';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import pluralize from '@/utils/pluralize';

const LeadersTable = () => {
  const { data, isRefetching, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useLeaderboardMetrics();
  const leaders = data?.pages.flatMap((page) => page.leaders) || [];
  const noResults = !isRefetching && !isLoading && leaders.length === 0;

  if (noResults) {
    return <EmptyCard title="No Leaders yet" description="" />;
  }

  return (
    <>
      <Items>
        <ItemHeaders>
          <ItemHeader align={AlignType.LEFT}>Email</ItemHeader>
          <ItemHeader align={AlignType.LEFT}>Total Referrals</ItemHeader>
        </ItemHeaders>

        <ItemsBody>
          {leaders.map((leader) => (
            <ItemRow>
              <ItemColumn align={AlignType.TOP}>
                <Typography token="font-normal/text/sm">{leader.email}</Typography>
              </ItemColumn>
              <ItemColumn align={AlignType.TOP}>
                <Typography token="font-normal/text/sm">{pluralize('referral', leader.total_referrals)}</Typography>
              </ItemColumn>
            </ItemRow>
          ))}
        </ItemsBody>
      </Items>
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </>
  );
};

export default LeadersTable;
