import { AutomationEnrolmentSettings, AutomationEnrolmentType } from '@/interfaces/automations/automation';
import { Duration, Option } from '@/interfaces/general';

export const DURATION_OPTIONS: Option[] = [
  {
    label: 'Minutes',
    value: Duration.MINUTES,
  },
  {
    label: 'Hours',
    value: Duration.HOURS,
  },
  {
    label: 'Days',
    value: Duration.DAYS,
  },
];

export const ENROLMENT_TYPE_OPTIONS: Option[] = [
  {
    label: 'No re-entry',
    value: AutomationEnrolmentType.LIMITED,
    description: 'Subscribers can only enter the automation once.',
  },
  {
    label: 'Unlimited re-entry',
    value: AutomationEnrolmentType.UNLIMITED,
    description: 'Subscribers can enter the automation multiple times when they meet the acceptance criteria.',
  },
  {
    label: 'Conditional re-entry',
    value: AutomationEnrolmentType.CONDITIONAL,
    description:
      'Subscribers can enter the automation multiple times, providing that they meet the acceptance criteria and additional conditions outlined below.',
  },
];

export const DEFAULT_ENROLMENT_SETTINGS: AutomationEnrolmentSettings = {
  max_enrolments: 1,
  seconds_since_completed: 0,
  seconds_since_entered: 0,
};
