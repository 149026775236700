import { usePageContext } from './PageContext';
import { SavingIndicator } from './SavingIndicator';

const SavingIndicatorHelper = () => {
  const { status } = usePageContext();

  return (
    <div>
      <SavingIndicator status={status} />
    </div>
  );
};

export default SavingIndicatorHelper;
