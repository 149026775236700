import { useParams } from 'react-router-dom';

import Comments from '../Comments';
import NavContainer from '../NavContainer';

interface RouteParams {
  postId: string;
}

const CommentsContainer = () => {
  const { postId } = useParams() as unknown as RouteParams;

  return <NavContainer postId={postId}>{() => <Comments postId={postId} />}</NavContainer>;
};

export default CommentsContainer;
