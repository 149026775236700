import { useCallback } from 'react';
import { Clock } from '@phosphor-icons/react';

import { PopoverHelper, PopoverItem } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';

const TIMESTAMP_DISPLAY_OPTIONS = [
  {
    label: 'Mar 7, 2024',
    value: 'short_date',
  },
  {
    label: '03/7/2024',
    value: 'numeric_date',
  },
  {
    label: '2024-03-07',
    value: 'iso_date',
  },
  {
    label: '7 March 2024',
    value: 'day_first',
  },
  {
    label: 'March 2024',
    value: 'month_year',
  },
];

type Props = AttributeSettingProps & {
  property: string;
};

export const TimestampSettings = ({ editor, activeNodeResult, property }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const textValue =
    TIMESTAMP_DISPLAY_OPTIONS.find((option) => option.value === activeNodeAttributes?.[property])?.label ||
    'March 7, 2024';

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodePos) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [editor, activeNodePos, property]
  );

  return (
    <PopoverHelper title="Display" text={textValue} popoverTitle="Display" Icon={Clock}>
      <div className="flex flex-col gap-2">
        {TIMESTAMP_DISPLAY_OPTIONS.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={false}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};
