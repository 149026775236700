import { useInfiniteQuery } from 'react-query';

import { AdNetworkCampaignOpportunityGroupDraftOpportunity } from '@/interfaces/ad_network/internal/campaign_opportunity_group';

import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  opportunities: AdNetworkCampaignOpportunityGroupDraftOpportunity[];
  pagination: Pagination;
}

export default function useCampaignOpportunityGroupOpportunities({ id }: { id: string }) {
  const fetchOpportunities = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/internal/campaign_opportunity_groups/${id}/opportunities`, {
        params: {
          page: pageParam,
          per_page: 25,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_network', 'internal', 'campaign_opportunity_groups', id, 'opportunities'],
    fetchOpportunities,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
