import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';

interface Props {
  title: string | React.ReactNode;
  icon: React.ReactNode;
  ctaText?: string | React.ReactNode;
  onClick: () => void;
}

const AccordionListItem = ({ title, icon, onClick, ctaText }: Props) => {
  return (
    <li className="group flex justify-between items-center bg-gray-50 hover:bg-feedback-info-100 transition-all p-2 rounded cursor-pointer">
      <button type="button" onClick={onClick} className="flex justify-between items-center w-full">
        <div className="flex space-x-2 items-center text-gray-600">
          {icon}
          <Typography className="w-full" colorWeight="900" weight="normal" size="sm">
            {title}
          </Typography>
        </div>

        {ctaText || <ChevronRightIcon className="w-5 h-5 text-surface-500 group-hover:text-feedback-info-500" />}
      </button>
    </li>
  );
};

export default AccordionListItem;
