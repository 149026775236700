/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { useReorderQuestionOptions } from '../../../../hooks/useForms';
import useCreateFormQuestionOption from '../../../../hooks/useForms/useCreateFormQuestionOption';
import { FormQuestion, FormQuestionOption } from '../../../../interfaces/form';
import SimpleInputForm from '../../../Settings/Components/SimpleInputForm';

import QuestionOption from './QuestionOption';

interface Props {
  question: FormQuestion;
}

const QuestionOptions = ({ question }: Props) => {
  const queryClient = useQueryClient();
  const [addingNewOption, setAddingNewOption] = useState(false);

  const { formId } = useParams();
  const createNewOption = useCreateFormQuestionOption({
    formQuestionId: question.id,
    formId: formId || '',
    onSuccess: () => {
      toast.success('Option added!');
      queryClient.invalidateQueries(['forms', formId]);
      setAddingNewOption(false);
    },
  });

  const [questionOptions, setQuestionOptions] = useState(question.form_question_options || []);
  const hasOnlyOneOption = questionOptions && questionOptions.length === 1;

  const [idsWithEditsInProgress, setIdsWithEditsInProgress] = useState<string[]>([]);

  useEffect(() => {
    if (question?.form_question_options) {
      setQuestionOptions(question.form_question_options);
    }
  }, [question]);

  const reorderFormQuestions = useReorderQuestionOptions({
    formQuestionId: question.id || '',
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activePostitionId: any = questionOptions.find((position: any) => position.id === active.id);
      const overPostitionId: any = questionOptions.find((position: any) => position.id === over.id);
      const oldIndex = questionOptions.indexOf(activePostitionId);
      const newIndex = questionOptions.indexOf(overPostitionId);
      const newList = arrayMove(questionOptions, oldIndex, newIndex);
      setQuestionOptions(newList);
      reorderFormQuestions.mutate({
        optionsOrder: newList.map((item: any, index: any) => {
          return { id: item.id, order: index + 1 };
        }),
      });
    }
  };

  return (
    <div className="flex flex-col space-y-2.5 p-4 py-2 bg-gray-100 border border-gray-200 rounded">
      <span className="text-gray-400 text-xs font-semibold">Options</span>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
      >
        <SortableContext items={questionOptions} strategy={verticalListSortingStrategy}>
          {questionOptions.map((option: FormQuestionOption) => {
            return (
              <QuestionOption
                key={option.id}
                isEditInProgress={idsWithEditsInProgress.includes(option.id)}
                question={question}
                option={option}
                hasOnlyOneOption={hasOnlyOneOption}
                idsWithEditsInProgress={idsWithEditsInProgress}
                setIdsWithEditsInProgress={setIdsWithEditsInProgress}
              />
            );
          })}
        </SortableContext>
      </DndContext>

      <div className="pb-2">
        {addingNewOption ? (
          <SimpleInputForm
            label="New"
            placeholder="New option"
            value=""
            onSave={(val) => createNewOption.mutateAsync({ value: val })}
            isSaving={false}
            isInitiallyEditing
          />
        ) : (
          <button
            type="button"
            onClick={() => setAddingNewOption(true)}
            className="text-blue-500 font-medium text-xs hover:text-blue-600 w-min whitespace-nowrap transition-all"
          >
            Add option
          </button>
        )}
      </div>
    </div>
  );
};

export default QuestionOptions;
