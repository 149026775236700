import { Link } from 'react-router-dom';
import { ChevronRightIcon, CloudArrowDownIcon } from '@heroicons/react/20/solid';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import Tooltip from '@/components/Tooltip';
import { useSettings } from '@/context/settings-context';
import { usePostClicksExport } from '@/hooks';
import { PostClick } from '@/interfaces/post';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';

import LinkPopover from './LinkPopover';
import PostClickContainer from './PostClickContainer';
import PostClicksContainer from './PostClicksContainer';

interface ClicksTableProps {
  showEmail: boolean;
  showWeb: boolean;
  postId: string;
}

const ClicksTable = ({ showEmail, showWeb, postId }: ClicksTableProps) => {
  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;
  const { mutateAsync, isLoading: isExporting } = usePostClicksExport({ useApiary: canUseApiary });

  return (
    <PostClicksContainer postId={postId}>
      {(clicks, totalClicks, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage) => {
        if (isLoading) {
          return (
            <div className="flex items-center">
              <LoadingSpinner className="mr-2" />
              <p className="text-gray-400 text-sm">Loading clicks...</p>
            </div>
          );
        }

        if (clicks.length === 0) return null;

        return (
          <div className="bg-white rounded">
            <div className="pt-6">
              <div className="px-6">
                <div className="flex items-center">
                  <div className="flex items-center mr-2 space-x-2">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Clicks</h3>
                    <Badge>
                      Showing {clicks.length} of {totalClicks} results
                    </Badge>
                  </div>
                  <div className="flex items-center">
                    <button
                      className="relative inline-flex flex-col items-center group"
                      type="button"
                      onClick={() => mutateAsync()}
                    >
                      <CloudArrowDownIcon className="h-4 w-4 text-gray-400" />
                      <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:opacity-100 opacity-0">
                        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg rounded-sm w-max">
                          {isExporting ? 'Exporting...' : 'Export CSV'}
                        </span>
                        <div className="w-3 h-3 -mt-2 rotate-45 bg-black transform" />
                      </div>
                    </button>
                  </div>
                </div>
                <p className="text-xs text-gray-500 pt-1">Showing most popular links, export CSV for all links.</p>
              </div>
              <div className="flex flex-col mt-5 overflow-x-scroll">
                <table className="divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        URL
                      </th>
                      {showEmail && (
                        <>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                          >
                            Email (Total)
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                          >
                            Email (Unique)
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                          >
                            <div className="flex items-center space-x-1">
                              <div>Email (CTR)</div>
                              <div>
                                <Tooltip id="ctr" text="Unique Email Clicks / Unique Opens" />
                              </div>
                            </div>
                          </th>
                        </>
                      )}
                      {showWeb && (
                        <>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                          >
                            Web (Total)
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                          >
                            Web (Unique)
                          </th>
                        </>
                      )}
                      {showEmail && showWeb && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                        >
                          Email + Web (Total)
                        </th>
                      )}
                      {showEmail && (
                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                        />
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 relative">
                    {clicks.map((click: PostClick) => {
                      const formatter = new Intl.NumberFormat('en');
                      const decimals = new Intl.NumberFormat('en', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });

                      return (
                        <PostClickContainer postId={postId} postClick={click}>
                          {(stats) => (
                            <tr key={click.id}>
                              <LinkPopover>
                                <a
                                  href={click.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-primary-600 hover:text-primary-500 hover:underline"
                                >
                                  {click.url}
                                </a>
                              </LinkPopover>
                              {showEmail && (
                                <>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                    {formatter.format(stats.total_email_clicked)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                    {formatter.format(stats.total_unique_email_clicked)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                    {decimals.format(stats.email_click_through_rate)}%
                                  </td>
                                </>
                              )}
                              {showWeb && (
                                <>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                    {formatter.format(stats.total_web_clicked)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                    {formatter.format(stats.total_unique_web_clicked)}
                                  </td>
                                </>
                              )}
                              {showEmail && showWeb && (
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                  {formatter.format(stats.total_clicked)}
                                </td>
                              )}
                              {showEmail && stats.total_email_clicked > 0 && (
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                  <div className="relative inline-flex flex-col align-middle items-center group">
                                    <Link to={`/posts/${postId}/analytics/${click.id}`}>
                                      <ChevronRightIcon className="ml-1 text-gray-500 h-4 w-4 group-hover:text-gray-700" />
                                    </Link>
                                    <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:opacity-100 opacity-0 z-100">
                                      <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg rounded-sm w-max">
                                        View Details
                                      </span>
                                      <div className="w-3 h-3 -mt-2 rotate-45 bg-black transform" />
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tr>
                          )}
                        </PostClickContainer>
                      );
                    })}
                  </tbody>
                </table>
                {hasNextPage && fetchNextPage && typeof isFetchingNextPage !== 'undefined' && (
                  <div className="flex justify-center align-middle p-6">
                    <Button
                      variant="primary-inverse"
                      onClick={() => fetchNextPage()}
                      loading={isFetchingNextPage}
                      disabled={!hasNextPage || isFetchingNextPage}
                    >
                      {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </PostClicksContainer>
  );
};

export default ClicksTable;
