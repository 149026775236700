import { useCallback } from "react";

import useBlocker from "./useBlocker";

export function useBeforeNavigate(callback: () => void | Promise<void>, when: boolean = true) {
  const blocker = useCallback(
    (tx: { retry: () => void }) => {
      const result = callback();
      if (result instanceof Promise) {
        result.then(() => tx.retry());
      } else {
        tx.retry();
      }
    },
    [callback]
  );

  useBlocker(blocker, when);
}

