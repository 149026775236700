import React, { ErrorInfo, useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import styled from 'styled-components';

import { Subtitle, Title } from '@/components/Text';
import LargeSubtitle from '@/components/Text/LargeSubtitle';
import { Button } from '@/ui/Button';

const NotFoundAnimation = React.lazy(() => import('./animation/NotFound'));

interface Props {
  statusCode: number;
  errorState?: ErrorState;
}

export interface ErrorState {
  error: Error | AxiosError | null;
  errorInfo: ErrorInfo | null;
}

const errorCodeConfigs: {
  [key: number | string]: {
    title?: string;
    subtitle: string;
    buttonText: string;
    secondaryButtonText?: string;
    image?: string;
    animation?: JSX.Element;
  };
} = {
  404: {
    subtitle: 'We can’t find the page you’re looking for.',
    buttonText: 'Back to home',
    secondaryButtonText: 'Get a Demo',
    animation: <NotFoundAnimation />,
  },
  500: {
    title: '500',
    subtitle: 'We aren’t exactly sure what happened, but our servers are telling us something is wrong.',
    buttonText: 'Go to home',
    image: '500.svg',
  },
  fallback: {
    title: 'Oops, that’s our bad',
    subtitle: 'We aren’t exactly sure what happened, but our servers are telling us something is wrong.',
    buttonText: 'Go to home',
    image: '500.svg',
  },
};

const Image = styled.img.attrs({
  className: 'm-auto',
})`
  width: 248px;
  height: 240px;
`;

const defaultErrorState: ErrorState = {
  error: null,
  errorInfo: null,
};

const goTo = (path: string) => {
  window.location.href = window.location.origin + path;
};

const ButtonWithShadow = styled(Button)`
  background-color: #209bf1;
  width: 315px;
  height: 58px;
  box-shadow: 6px 6px 0px rgba(32, 155, 241, 0.25);
  font-size: 22px;
  line-height: 41px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 400px) {
    width: unset;
    height: unset;
    font-size: 18px;
    line-height: 30px;
  }
`;

const ErrorPage: React.FC<Props> = ({ statusCode = 404, errorState = { ...defaultErrorState } }) => {
  const errorConfig = errorCodeConfigs[statusCode] || errorCodeConfigs.fallback;
  const { error, errorInfo } = errorState;

  useEffect(() => {
    Sentry.captureException(error || new Error(`${statusCode}: ${window.location.href}`), {
      tags: {
        status_code: `${statusCode}`,
      },
    });
  }, [error, statusCode]);

  return (
    <div className="flex flex-col">
      <div className="h-full flex flex-col min-h-screen">
        <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 p-5">
          {statusCode === 404 ? (
            <div className="flex flex-col space-y-10">
              <div className="flex flex-col items-center justify-center gap-y-[56px]">
                <div className="w-[393px] h-[266px]">{errorConfig.animation}</div>
                <div className="max-w-2xl text-center">
                  <LargeSubtitle>{errorConfig.subtitle}</LargeSubtitle>
                </div>
              </div>
              <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-3 items-center justify-center">
                <button
                  className="text-[18px] py-[15px] px-6 bg-[#3843D0] text-white rounded-lg leading-[27px] font-bold border-2 border-[#3843D0]"
                  type="button"
                  onClick={() => goTo('/')}
                >
                  {errorConfig.buttonText}
                </button>
                <button
                  className="text-[18px] py-[15px] px-6 text-[#3843D0] rounded-lg leading-[27px] font-bold border-2 border-[#3843D0]"
                  type="button"
                  onClick={() => window.open('https://www.beehiiv.com/demo')}
                >
                  {errorConfig.secondaryButtonText}
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="text-center sm:mx-auto sm:w-full sm:max-w-3xl">
                {errorConfig.animation ? (
                  errorConfig.animation
                ) : (
                  <Image src={`/images/${errorConfig.image}`} alt="not_found" />
                )}
                {errorConfig.title ? <Title>{errorConfig.title}</Title> : null}
                <Subtitle>{errorConfig.subtitle}</Subtitle>
              </div>
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex flex-col justify-center">
                <ButtonWithShadow
                  className="m-auto justify-evenly"
                  Icon={ArrowRightIcon}
                  iconRight
                  onClick={() => goTo('/')}
                >
                  {errorConfig.buttonText}
                </ButtonWithShadow>
              </div>
            </>
          )}
          {process.env.NODE_ENV === 'development' && (
            <div className="mt-10">
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {error && error.toString()}
                <br />
                {errorInfo?.componentStack}
              </details>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
