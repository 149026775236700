import { Link } from "react-router-dom";
import moment from "moment-mini";

import { ItemColumn, ItemRow } from "@/components/ResourceList";
import { AlignType } from "@/components/ResourceList/types";
import { Typography } from '@/components/Typography';
import { PostSubscriberEngagement } from "@/interfaces/post";
import { Badge } from '@/ui/Badge';

import { engagementBadgeTypeMap, engagementTypeLabels } from "./utils";

interface Props {
  engagement: PostSubscriberEngagement;
}

const EngagementItem = ({ engagement }: Props) => {
  const {
    email,
    status,
    timestamp,
    totalOpened,
    totalClicked,
    subscriberId,
  } = engagement;

  return (
    <ItemRow key={subscriberId}>
      <ItemColumn align={AlignType.LEFT}>
        {email === null && <Typography token="font-normal/text/sm">Deleted subscriber</Typography>}

        {email && (
          <Link to={`/subscribers/${subscriberId}`}>
            <Typography token="font-normal/text/sm" color="secondary" colorWeight="600">
              {email}
            </Typography>
          </Link>
        )}
      </ItemColumn>
      <ItemColumn align={AlignType.LEFT}>
        <Badge
          text={engagementTypeLabels[status]}
          type={engagementBadgeTypeMap[status]}
        />
      </ItemColumn>
      <ItemColumn align={AlignType.LEFT}>
        <Typography token="font-normal/text/sm" colorWeight="700">
          {moment(timestamp).format('LLL')}
        </Typography>
      </ItemColumn>
      <ItemColumn align={AlignType.LEFT}>
        <Typography token="font-normal/text/sm" colorWeight="700">
          {totalOpened}
        </Typography>
      </ItemColumn>
      <ItemColumn align={AlignType.LEFT}>
        <Typography token="font-normal/text/sm" colorWeight="700">
          {totalClicked}
        </Typography>
      </ItemColumn>
    </ItemRow>
  )
};

export default EngagementItem;
