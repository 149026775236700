import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus } from '@phosphor-icons/react';
import { SerializableNode, Serializer } from '@shared/dream-components';

import { useWebsitePageRouteGetter } from '@/context/website-context';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';
import { useSiteTemplate } from '@/hooks/useSiteTemplates';
import { useAttributesPanelNavbarFooterContext } from '@/routes/website/_components/DreamEditor/AttributesPanel/navbarFooterContextProvider';
import { Text } from '@/routes/website/_components/UI/Text';
import { useClickOutside } from '@/routes/website/_utils/useClickOutside';
import { cn } from '@/utils/cn';

export const FooterTemplatePreview = ({ iframeRef }: { iframeRef: React.RefObject<HTMLIFrameElement> }) => {
  const { pageId } = useParams();
  const [selected, setSelected] = useState(false);
  const pageRouteGetter = useWebsitePageRouteGetter();
  const page = pageId ? pageRouteGetter?.getPageFromID(pageId) : undefined;
  const navigate = useNavigate();

  const { templateId } = useParams();
  const { data: siteTemplate } = useSiteTemplate({ siteTemplateId: templateId });

  const { data: sitePackage } = useSitePackage({ sitePackageId: siteTemplate?.site_package_id });

  const content = sitePackage?.footer as SerializableNode;

  const isFooterVisible =
    'show_footer' in (page?.draft_page_version?.customization || {})
      ? page?.draft_page_version?.customization?.show_footer
      : true;

  const { setIsFooterSelected } = useAttributesPanelNavbarFooterContext() || {};

  useEffect(() => {
    setIsFooterSelected?.(selected);
  }, [selected, setIsFooterSelected]);

  const onDoubleClick = () => {
    navigate(`/website_builder_v2/footer_template/${sitePackage?.id}`);
  };

  const onClickOutside = (): void => {
    setSelected(false);
  };
  const ref = useClickOutside(iframeRef, onClickOutside, ['footer-settings']);

  if (!content || !('content' in content) || !content.content)
    return (
      <div
        className={cn(
          'group w-full h-[60px] flex items-center justify-center gap-2 border border-solid border-inset-1',
          selected ? 'border-[#7C3AED]' : 'hover:border-neutral-200 border-transparent',
          !isFooterVisible ? 'opacity-50' : 'opacity-100'
        )}
        onClick={() => setSelected(true)}
        role="button"
        tabIndex={0}
        onDoubleClick={onDoubleClick}
        ref={ref}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setSelected(true);
          }
        }}
      >
        <Plus size={16} className="text-neutral-400 group-hover:text-[#7C3AED]" />
        <Text size="sm" variant="secondary" className="text-neutral-400 group-hover:text-[#7C3AED]">
          Edit Footer
        </Text>
      </div>
    );

  return (
    <div className="h-fit w-full flex flex-col justify-start items-center" ref={ref}>
      <div
        className={cn(
          ' border w-full border-solid border-inset-1',
          selected ? 'border-[#7C3AED]' : 'border-transparent hover:border-neutral-200',
          !isFooterVisible ? 'opacity-50' : 'opacity-100'
        )}
        onClick={() => setSelected(true)}
        role="button"
        tabIndex={0}
        onDoubleClick={onDoubleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setSelected(true);
          }
        }}
      >
        <Serializer node={content} rootClassName="footer" rootStyle={{ minHeight: 'unset' }} />
      </div>
    </div>
  );
};
