import { useParams } from 'react-router-dom';

import { useIndustry, useIndustryAdvertisers } from '../../../../hooks/useAdNetwork/internal';
import { List } from '../../_components/List';
import { NoResults } from '../../_components/NoResults';
import { PageHeading } from '../../_components/PageHeading';

export default function Edit() {
  const { industry_id: id } = useParams<'industry_id'>() as { industry_id: string };
  const { data } = useIndustry({ id });
  const { data: advertisersData, hasNextPage, fetchNextPage, isLoading } = useIndustryAdvertisers({ id });

  const advertisers = advertisersData?.pages.flatMap((page) => page.advertisers) ?? [];

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="../..">Industries</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="..">{data?.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Advertisers</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>

      {advertisers.length === 0 && !isLoading ? (
        <NoResults />
      ) : (
        <List hasNextPage={hasNextPage} fetchNextPage={fetchNextPage}>
          {advertisers.map((advertiser) => (
            <List.Item key={advertiser.id} to={`/ad_network/advertisers/${advertiser.id}`}>
              <List.Item.Text variant="primary">{advertiser.name}</List.Item.Text>
              <List.Item.Text variant="secondary">{advertiser.hostname}</List.Item.Text>
            </List.Item>
          ))}
        </List>
      )}
    </>
  );
}
