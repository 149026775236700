import { useQuery } from 'react-query';

import { BillingDetails } from '../../interfaces/billing';
import api from '../../services/swarm';

const useBilling = (organizationId: string | undefined, publicationId: string) => {
  return useQuery<BillingDetails>(
    ['billing', 'organizations', organizationId],
    () =>
      api
        .get(`/organizations/${organizationId}/billing`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: 10000,
      enabled: !!organizationId,
    }
  );
};

export default useBilling;
