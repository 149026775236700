import { getDefaultBorderRadius } from '../components/SignupWidgetSettings/SWBorderSettings';

// TRBL is top, right, bottom, left
type TRBL = {
  top: string;
  right: string;
  bottom: string;
  left: string;
};

// getTRBLValue is a function that takes a string value and returns an object with top, right, bottom, and left values
export const getTRBLValue = (value: string): TRBL => {
  let br = value.split(' ');
  if (br.length < 4) {
    let v = value;
    if (br.length === 2) {
      v = `${br[0]} ${br[1]} ${br[0]} ${br[1]}`;
    } else if (br.length === 1) {
      v = `${br[0]} ${br[0]} ${br[0]} ${br[0]}`;
    } else {
      v = getDefaultBorderRadius();
    }
    br = v.split(' ');
  }

  return { top: br[0], right: br[1], bottom: br[2], left: br[3] };
};
