import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import { PlanPrice } from '@/interfaces/plan_price';
import { PLAN } from '@/utils/plans';

interface Props {
  planPrice: PlanPrice;
}

const UpgradeCta: React.FC<Props> = ({ planPrice }) => {
  const {
    plan_name: planName,
    has_next_tier: hasNextTier,
    active_subscriptions: activeSubscriptions,
    organization_max_subscriptions: maxSubscriptions,
  } = planPrice;

  const usagePercentage = Math.min(100, (activeSubscriptions / maxSubscriptions) * 100);

  if (usagePercentage < 80) {
    return null;
  }

  const usageMessage =
    usagePercentage < 100
      ? 'You are approaching the usage limit for your plan.'
      : 'You have maxed out your usage limit for your plan.';

  const shouldContactSupport =
    planName === PLAN.CUSTOM || ((planName === PLAN.MAX || planName === PLAN.SCALE) && !hasNextTier);

  const actionMessage = () => {
    if (planName === PLAN.CUSTOM)
      return 'Since you are on a custom billed plan, please reach out to support to make adjustments.';

    if ((planName === PLAN.LAUNCH || planName === PLAN.GROW) && !hasNextTier)
      return 'In order to add more subscribers, you must upgrade to a higher plan.';

    if ((planName === PLAN.MAX || planName === PLAN.SCALE) && !hasNextTier)
      return 'Please reach out to support to inquire about transitioning to an enterprise plan.';

    if (usagePercentage < 100) return 'Upgrade to the next tier to add more subscribers and avoid disruptions.';

    return 'Upgrade to the next tier to add more subscribers.';
  };

  return (
    <div className="p-4 rounded bg-feedback-info-50 border border-feedback-info-200">
      <div className="flex space-x-3">
        <InformationCircleIcon className="w-5 h-5 text-feedback-info-500" />
        <Typography as="div" className="grow" token="font-medium/text/sm" color="info">
          {usageMessage} {actionMessage()}
        </Typography>
        {shouldContactSupport && (
          <Typography as="div" className="flex-none my-auto" token="font-medium/text/sm" color="info">
            {shouldContactSupport && (
              <a href="/?new_support_ticket=true" className="underline" target="_blank">
                Contact Support &rarr;
              </a>
            )}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default UpgradeCta;
