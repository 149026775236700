interface CookieType {
  [key: string]: string;
}
const parseCookie = (str: string): CookieType =>
  str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      if (typeof v[0] !== 'undefined' && typeof v[1] !== 'undefined') {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      }
      return acc;
    }, {} as CookieType);

const safelyParseJSONCookie = (str?: string) => {
  if (!str) return {};
  try {
    return JSON.parse(str);
  } catch (err) {
    return {};
  }
};
// On the marketing site we use attribution.js to store first and last touch attribution information
// in cookies. This function will parse those cookies and return the values as an object.
// see: https://github.com/derekcavaliero/attributor/blob/master/src/attributor.js#L5
//  https://github.com/derekcavaliero/attributor/blob/master/src/attributor.js#L37C16-L45C8
export const getAttributionCookies = () => {
  const cookies = parseCookie(document.cookie);
  const first = safelyParseJSONCookie(cookies.attr_first);
  const last = safelyParseJSONCookie(cookies.attr_last);
  // we use rewardful for affiliate tracking
  const rewardful = safelyParseJSONCookie(cookies['rewardful.referral']);
  const {
    _fbc: fbc, // Facebook Ads Click ID
    _fbp: fbp, // Facebook Ads Browser ID
    _ga: ga, // Google Analytics Client ID
    _gcl_aw: gclid, // Google Ads Click ID
    _uetmsclkid: msclkid, // Bing/Microsoft Ads Click ID
    // eslint-disable-next-line @typescript-eslint/naming-convention
    li_fat_id, // LinkedIn Click ID
    ttclid, // TikTok Ads Click ID
  } = cookies;
  return { first, last, fbc, fbp, ga, gclid, msclkid, li_fat_id, ttclid, rewardful };
};
