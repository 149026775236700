import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { PlusIcon, RectangleStackIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { LogicalOperators } from '@/interfaces/condition';
import { Button } from '@/ui/Button';

import { Shortcut } from '../Shortcut';

import AppliedCondition from './AppliedCondition';
import FilterDetails from './FilterDetails';
import useFilteringContext from './filtering-context';
import FilterSelector from './FilterSelector';
import OperatorSelect from './OperatorSelect';
import { ConditionGroup } from './types';
import ViewSelector from './ViewSelector';

interface Props {
  group: ConditionGroup;
  mainGroup?: boolean;
}

const FilterGroup = ({ group, mainGroup = true }: Props) => {
  const {
    selectedFilter,
    handleOpen,
    handleClose,
    showMenu,
    currentGroupUuid,
    replaceCondition,
    addCondition,
    removeCondition,
    clearConditions,
    readonly,
    disableShortcuts,
    localStorageKey,
    showViewSelector,
    setShowViewSelector,
  } = useFilteringContext();

  const navigate = useNavigate();

  const isMenuOpen = showMenu && currentGroupUuid === group.uuid;

  const rows: Array<any> = [];
  let currentRow: Array<any> = [];

  group.conditions.forEach((condition) => {
    if (condition.type === 'group') {
      if (currentRow.length > 0) {
        rows.push(currentRow);
        currentRow = [];
      }
      rows.push(condition);
    } else {
      currentRow.push(condition);
    }
  });

  if (currentRow.length > 0) {
    rows.push(currentRow);
  }

  const closeAllMenus = () => {
    handleClose();
    setShowViewSelector(false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (readonly || disableShortcuts) return;

    if (!showMenu && !showViewSelector && mainGroup) {
      event.preventDefault();

      if (event.key === 'v') {
        setShowViewSelector(true);
      }

      if (event.key === 'f') {
        handleOpen(group.uuid);
      }

      if (event.key === 'F') {
        clearConditions();
      }

      if (event.key === 'g') {
        addCondition(
          {
            type: 'group',
            logical_operator: LogicalOperators.AND,
            conditions: [],
          },
          group.uuid
        );
      }

      if (event.key === '/') {
        handleOpen(group.uuid, { id: 'search', label: 'Simple search' });
      }

      if (event.key === 'v' && event.ctrlKey) {
        navigate(`/ad_network/workspace_views/new?conditions_key=${localStorageKey}`);
      }
    }

    if (showMenu) {
      if (event.key === 'Escape') {
        closeAllMenus();
      }
    }

    if (showViewSelector) {
      if (event.key === 'Escape') {
        closeAllMenus();
      }
    }
  };

  // shortcuts
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const operators = [LogicalOperators.AND, LogicalOperators.OR, LogicalOperators.NONE];
  const nextOperator = operators[(operators.indexOf(group.logical_operator) + 1) % operators.length];
  const shuffleOperator = () => {
    replaceCondition(group.uuid, { ...group, logical_operator: nextOperator });
  };
  const operatorLabels = {
    [LogicalOperators.AND]: 'and',
    [LogicalOperators.OR]: 'or',
    [LogicalOperators.NONE]: 'and not',
  };
  const operatorLabel = operatorLabels[group.logical_operator];

  if (mainGroup && rows.length === 0) {
    return (
      <div className="relative flex space-x-2">
        <div>
          <Button
            variant="primary-inverse"
            size="xs"
            type="button"
            Icon={PlusIcon}
            onClick={() => {
              handleOpen(group.uuid);
            }}
          >
            Filter
          </Button>
          <Transition
            show={isMenuOpen}
            enter="transition duration-200 transform ease-out"
            enterFrom="opacity-0 translate-y-2"
            enterTo="opacity-100 translate-y-0"
            leave="transition duration-200 transform ease-out"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-2"
            className="absolute top-full z-10"
          >
            {!selectedFilter && <FilterSelector />}
            {selectedFilter && <FilterDetails group={group} />}
          </Transition>
        </div>
        <div>
          <Button
            variant="primary-inverse"
            size="xs"
            type="button"
            Icon={RectangleStackIcon}
            onClick={() => {
              setShowViewSelector(true);
            }}
          >
            Apply a view
          </Button>
          <Transition
            show={showViewSelector}
            enter="transition duration-200 transform ease-out"
            enterFrom="opacity-0 translate-y-2"
            enterTo="opacity-100 translate-y-0"
            leave="transition duration-200 transform ease-out"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-2"
            className="absolute top-full z-10"
          >
            <ViewSelector />
          </Transition>
        </div>
      </div>
    );
  }

  return (
    <div className="flex space-x-2 w-full relative">
      <div className="w-full border rounded-xl p-2 space-y-2 relative">
        {rows.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {rows.map((row, rowIx) => {
              if (Array.isArray(row)) {
                return (
                  <>
                    {row.map((condition, ix) => (
                      <div className="inline-flex items-center space-x-2" key={condition.uuid}>
                        {(rowIx !== 0 || ix !== 0) && (
                          <p className="text-sm text-gray-500 flex-shrink-0">{operatorLabel}</p>
                        )}
                        {group.logical_operator === LogicalOperators.NONE && ix === 0 && rowIx === 0 && (
                          <p className="text-sm text-gray-500 flex-shrink-0">Not</p>
                        )}

                        <AppliedCondition condition={condition} />
                      </div>
                    ))}
                  </>
                );
              }

              return (
                <div className="w-full block" key={row.uuid}>
                  <div className="flex space-x-2">
                    {rowIx !== 0 && <p className="text-sm text-gray-500 flex-shrink-0">{operatorLabel}</p>}
                    {group.logical_operator === LogicalOperators.NONE && rowIx === 0 && (
                      <button type="button" className="text-sm text-gray-500 flex-shrink-0" onClick={shuffleOperator}>
                        Not
                      </button>
                    )}
                    <FilterGroup group={row} mainGroup={false} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div>
          <div className="flex items-center space-x-2">
            <Button
              variant="primary-inverse"
              size="xs"
              type="button"
              onClick={() => {
                handleOpen(group.uuid);
              }}
              disabled={readonly}
            >
              {mainGroup ? (
                <span className="flex space-x-1">
                  <span>Add filter</span>
                  <Shortcut>F</Shortcut>
                </span>
              ) : (
                'Add filter'
              )}
            </Button>
            {mainGroup ? (
              <Button
                variant="primary-inverse"
                size="xs"
                type="button"
                onClick={() => {
                  addCondition(
                    {
                      type: 'group',
                      logical_operator: LogicalOperators.AND,
                      conditions: [],
                    },
                    group.uuid
                  );
                }}
                disabled={readonly}
              >
                <span className="flex space-x-1">
                  <span>Add group</span>
                  <Shortcut>G</Shortcut>
                </span>
              </Button>
            ) : (
              <Button
                variant="primary-inverse"
                size="xs"
                type="button"
                Icon={XMarkIcon}
                onClick={() => {
                  removeCondition(group.uuid);
                }}
                disabled={readonly}
              >
                Remove group
              </Button>
            )}
            {group.conditions.length > 1 && (
              <OperatorSelect
                logicalOperator={group.logical_operator}
                onSelect={(operator) => {
                  replaceCondition(group.uuid, { ...group, logical_operator: operator });
                }}
              />
            )}
          </div>
        </div>
      </div>
      {!mainGroup && (
        <div>
          <button type="button" onClick={() => removeCondition(group.uuid)}>
            <XMarkIcon className="w-5 h-5 text-gray-600" />
          </button>
        </div>
      )}
      <Transition
        show={isMenuOpen}
        enter="transition duration-200 transform ease-out"
        enterFrom="opacity-0 translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition duration-200 transform ease-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-2"
        className="absolute top-full z-10"
      >
        {!selectedFilter && <FilterSelector />}
        {selectedFilter && <FilterDetails group={group} />}
      </Transition>
    </div>
  );
};

export default FilterGroup;
