import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PublicationSubscriberTagParams } from '@/interfaces/subscriber_tag';
import api from '@/services/swarm';

const useUpdatePublicationSubscriberTag = (onSuccess?: (response: any) => void) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    (params: PublicationSubscriberTagParams) =>
      api.patch(`/publication_subscriber_tags/${params.id}`, {
        publication_id: currentPublicationId,
        subscriber_tag: params,
      }),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        queryClient.invalidateQueries(['subscriber_tags', currentPublicationId]);
        toast.success('Tag updated successfully!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Could not create tag.');
      },
    }
  );
};

export default useUpdatePublicationSubscriberTag;
