import { CustomDomain } from '../../interfaces/custom_domain';
import useCurrentPublicationId from '../usePublications/useCurrentPublicationId';

import useCustomDomains from './useCustomDomains';

const useVerifiedCustomDomains = () => {
  const publicationId = useCurrentPublicationId();
  const { data: customDomains } = useCustomDomains(publicationId);
  return {
    data: customDomains?.filter((customDomain: CustomDomain) => customDomain.verified),
  };
};

export default useVerifiedCustomDomains;
