import { forwardRef, HTMLProps } from 'react';

import { cn } from '@/utils/cn';

export type AvatarWrapperProps = HTMLProps<HTMLDivElement> & {
  fallback?: string;
  isLoading?: boolean;
};

export const AvatarWrapper = forwardRef<HTMLDivElement, AvatarWrapperProps>(
  ({ fallback, children, className, isLoading, ...props }, ref) => {
    const wrapperClassName = cn(
      'w-5 h-5 rounded-full relative flex items-center justify-center overflow-hidden bg-neutral-100 text-neutral-700 text-sm',
      className
    );

    const fallbackInitials = fallback
      ?.split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase();

    return (
      <div {...props} ref={ref} className={wrapperClassName}>
        {!isLoading ? children : null}
        {!isLoading ? <div>{fallbackInitials}</div> : null}
        {isLoading ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4 h-4 text-neutral-700 animate-spin"
          >
            <path d="M21 12a9 9 0 1 1-6.219-8.56" />
          </svg>
        ) : null}
      </div>
    );
  }
);

AvatarWrapper.displayName = 'Avatar.Wrapper';

export const AvatarImage = forwardRef<HTMLImageElement, HTMLProps<HTMLImageElement>>(
  ({ className, alt, ...props }, ref) => {
    const imageClassName = cn('absolute bg-neutral-100 inset-0 w-full h-full object-cover', className);

    return <img {...props} alt={alt} ref={ref} className={imageClassName} />;
  }
);

AvatarImage.displayName = 'Avatar.Image';

export const Avatar = {
  Wrapper: AvatarWrapper,
  Image: AvatarImage,
};
