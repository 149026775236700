/* eslint-disable jsx-a11y/media-has-caption */

import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import cx from 'classnames';

import { Spinner } from '@/components/TiptapEditor/components/ui/Spinner';
import API from '@/components/TiptapEditor/lib/api';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { getFileSizeStringWithUnit } from '@/components/TiptapEditor/lib/utils/getFileSizeStringWithUnit';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';
import { generateJWT } from '@/utils';

import { IAudioAttrs } from '../types';
import { AUDIO_EMBED_APP_URL } from '../utils';

import { AudioFileUploader } from './AudioFileUploader';
import { LoadingWrapper } from './shared';

export const AudioView: FC<NodeViewProps> = ({ node, editor, getPos, updateAttributes, selected }) => {
  const { publicationId } = usePublicationContext();
  const { loadingNodes } = useEditorContext();

  const { backgroundColor, id, src, type, title } = node.attrs as IAudioAttrs;

  const onUpload = ({ file, assetId }: { assetId: string; file: File }) => {
    const attrs: Partial<IAudioAttrs> = {
      id: assetId,
      src: '',
      size: getFileSizeStringWithUnit(file.size),
      type: file.type,
      title: !title ? file.name.split('.').slice(0, -1).join('.') : title,
    };

    updateAttributes(attrs);
  };

  useEffect(() => {
    const checkIfFileUrlExpired = async () => {
      const res = await fetch(src);

      if (!res.status.toString().startsWith('2')) {
        return true;
      }

      return false;
    };

    const fetchAudioSrc = async () => {
      try {
        const response = await API.getDownloadableData({
          downloadableId: id,
          publicationId,
        });

        const { url, name: fileName } = response.data;

        updateAttributes({ src: url, name: fileName });
      } catch (error) {
        toast.error('Failed to fetch audio file');
      }
    };

    if (src) {
      checkIfFileUrlExpired().then((fileUrlExpired) => {
        if (fileUrlExpired && id) {
          fetchAudioSrc();
        }
      });
    } else if (id && publicationId) {
      fetchAudioSrc();
    }
  }, [src, id, updateAttributes, publicationId]);

  const token = useMemo(() => generateJWT(JSON.stringify({ ...node.attrs }), ''), [node.attrs]);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const selectCurrentNode = useCallback(() => {
    editor.commands.setNodeSelection(getPos());
  }, [getPos, editor]);

  return (
    <NodeViewWrapper>
      <div className={cx('p-0 rounded-md relative')} style={{ backgroundColor }}>
        {loadingNodes[id] ? (
          <LoadingWrapper>
            <Spinner size={1.5} />
          </LoadingWrapper>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {id && src && type ? (
              <>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <iframe
                  onMouseDown={selectCurrentNode}
                  src={`${AUDIO_EMBED_APP_URL}?token=${token}`}
                  title="Audio Player"
                  height="162"
                  width="100%"
                  ref={iframeRef}
                  className="z-10 relative"
                />

                <div
                  className={cx(
                    'absolute top-0 left-0 bg-gray-100 bg-opacity-20 w-full h-full',
                    selected ? 'z-0' : 'z-20'
                  )}
                />
              </>
            ) : (
              <AudioFileUploader onUpload={onUpload} />
            )}
          </>
        )}
      </div>
    </NodeViewWrapper>
  );
};
