import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { Typography } from '@/components/Typography';
import { ApplicationScores } from '@/interfaces/boosts/grow/application_scores';
import { Score } from '@/ui/Score';
import { SCORE_HEIGHT_CLASSNAME, SCORE_WIDTH_CLASSNAME, ScoreValue } from '@/ui/Score/Score.types';

import { applicationScoresInsufficientData } from '../../../utils/applicationScoresInsufficientData';
import { SCORE_ENGAGEMENT_LABELS, SCORE_GROWTH_LABELS, SCORE_SIZE_LABELS } from '../../constants';

interface BoostApplicationScoresProps {
  publicationName: string;
  publicationId: string;
  scores?: ApplicationScores;
  portalMountedId?: string;
  isLoading: boolean;
}

const BoostApplicationScores = ({ publicationName, publicationId, scores, portalMountedId, isLoading }: BoostApplicationScoresProps) => {
  const insufficientData = !scores || scores && applicationScoresInsufficientData(scores);

  const items = [
    {
      type: 'Size',
      labels: SCORE_SIZE_LABELS,
      score: !insufficientData ? scores?.size || 0 : 0 as ScoreValue
    },
    {
      type: 'Growth',
      labels: SCORE_GROWTH_LABELS,
      score: !insufficientData ? scores?.growth || 0 : 0 as ScoreValue
    },
    {
      type: 'Engagement',
      labels: SCORE_ENGAGEMENT_LABELS,
      score: !insufficientData ? scores?.engagement || 0 : 0 as ScoreValue
    },
  ];

  return (
    <div className="border rounded-lg border-surface-200 p-4 space-y-4">
      <Typography token="font-medium/text/sm" colorWeight="900">
        {publicationName}
      </Typography>

      <SkeletonLoader
        isLoading={isLoading}
        skeletons={
          <div className="w-fit grid grid-cols-2 items-start gap-4 md:grid-cols-3">
            <div className={SCORE_WIDTH_CLASSNAME}>
              <Skeleton className={SCORE_HEIGHT_CLASSNAME} />
            </div>
            <div className={SCORE_WIDTH_CLASSNAME}>
              <Skeleton className={SCORE_HEIGHT_CLASSNAME} />
            </div>
            <div className={SCORE_WIDTH_CLASSNAME}>
              <Skeleton className={SCORE_HEIGHT_CLASSNAME} />
            </div>
          </div>
        }
      >
        <div className="w-fit grid grid-cols-2 items-start gap-4 md:grid-cols-3">
          {items.map((item) => (
            <Score
              key={`${publicationId}-score-${item.type}`}
              publicationId={publicationId}
              portalMountedId={portalMountedId}
              type={item.type}
              labels={item.labels}
              score={item.score}
            />
          ))}
        </div>
      </SkeletonLoader>
    </div>
  );
};

export default BoostApplicationScores;
