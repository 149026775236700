import { useQuery } from 'react-query';

import api from '@/services/swarm';

import { CreateCustomDomainsCollectionPayload } from './types';

const useCustomDomainsCollection = (publicationId: string) => {
  return useQuery<
    Pick<CreateCustomDomainsCollectionPayload, 'web_domain' | 'web_redirect_domain' | 'email_domain' | 'email_username'>
  >(
    ['custom_domains_collection', publicationId],
    () =>
      api
        .get(`/custom_domains/collection`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
};

export default useCustomDomainsCollection;
