import {
  BanknotesIcon,
  CursorArrowRaysIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

import { Badge } from '../../../ui/Badge';

export interface MetricsBadgeProps {
  totalSent?: number;
  openRate?: number;
  clickRate?: number;
  totalUpgrades?: number;
  totalWebViewed?: number;
  totalWebClicked?: number;
}

const MetricsBadge = ({
  totalSent,
  openRate,
  clickRate,
  totalUpgrades,
  totalWebViewed,
  totalWebClicked,
}: MetricsBadgeProps) => {
  const formatter = Intl.NumberFormat('en');

  return (
    <Badge type="information">
      <div className="flex space-x-2">
        {typeof totalSent === 'number' && (
          <>
            <span className="flex items-center">
              <EnvelopeIcon className="h-4 w-4 mr-1 text-gray-400" />
              <span>{formatter.format(totalSent)}</span>
            </span>
            <span className="text-gray-400">&rarr;</span>
          </>
        )}
        {typeof totalWebViewed === 'number' && (
          <>
            <span className="flex items-center">
              <EyeIcon className="h-4 w-4 mr-1 text-gray-400" />
              <span>{formatter.format(totalWebViewed)}</span>
            </span>
            <span className="text-gray-400">&rarr;</span>
          </>
        )}
        {typeof openRate === 'number' && (
          <>
            <span className="flex items-center">
              <EnvelopeOpenIcon className="h-4 w-4 mr-1 text-gray-400" />
              <span>{openRate}%</span>
            </span>
            <span className="text-gray-400">&rarr;</span>
          </>
        )}
        {typeof clickRate === 'number' && (
          <span className="flex items-center">
            <CursorArrowRaysIcon className="h-4 w-4 mr-1 text-gray-400" />
            <span>{clickRate}%</span>
          </span>
        )}
        {typeof totalWebClicked === 'number' && (
          <span className="flex items-center">
            <CursorArrowRaysIcon className="h-4 w-4 mr-1 text-gray-400" />
            <span>{totalWebClicked}</span>
          </span>
        )}
        {typeof totalUpgrades === 'number' && (
          <>
            <span className="text-gray-400">&rarr;</span>
            <span className="flex items-center">
              <BanknotesIcon className="h-4 w-4 mr-1 text-gray-400" />
              <span>{formatter.format(totalUpgrades)}</span>
            </span>
          </>
        )}
      </div>
    </Badge>
  );
};

export default MetricsBadge;
