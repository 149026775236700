import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { usePublication } from '@/hooks/useAdNetwork/internal';
import usePublicationProfile from '@/hooks/useAdNetwork/internal/usePublicationProfile';
import { AdNetworkPublication } from '@/interfaces/ad_network/internal/publication';
import { AdNetworkPublicationProfile } from '@/interfaces/ad_network/internal/publication_profile';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import { LoadingListPage } from '../../../_components/LoadingListPage';
import { PageHeading } from '../../../_components/PageHeading';

import { Form } from './_components/Form';

interface Props {
  publication: AdNetworkPublication;
  publicationProfile: AdNetworkPublicationProfile | null;
}

const PublicationProfile = ({ publication, publicationProfile }: Props) => {
  const navigate = useNavigate();

  const upsertProfile = useMutation(
    (formData: FormData) => {
      return api.put(`/ad_network/internal/publications/${publication.id}/profile`, formData);
    },
    {
      onSuccess: () => {
        toast.success('Profile updated successfully');
        navigate('..');
      },
    }
  );

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{publication.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Manage profile</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button
            type="submit"
            size="xs"
            variant="primary-inverse"
            form="publication-profile"
            loading={upsertProfile.isLoading}
          >
            Save
          </Button>
        </PageHeading.Side>
      </PageHeading>
      {!publicationProfile && (
        <div className="bg-feedback-warning-50 text-sm p-4 font-bold text-feedback-warning-600">
          <p>
            Notice: No profile has been created for this publication. Values shown are set to defaults and not persisted
            (i.e. treated as unknown in filtering). All fields are required.
          </p>
        </div>
      )}
      <Form onSubmitMutation={upsertProfile} publication={publication} publicationProfile={publicationProfile} />
    </>
  );
};

export default function Loader() {
  const { publication_id: id } = useParams<'publication_id'>() as { publication_id: string };

  const publicationQuery = usePublication({ id });
  const publicationProfileQuery = usePublicationProfile({ publicationId: id });

  const isSuccess = publicationQuery.isSuccess && publicationProfileQuery.isSuccess;
  const isError = publicationQuery.isError || publicationProfileQuery.isError;
  const isLoading = !isError && (publicationQuery.isLoading || publicationProfileQuery.isLoading);

  if (!isSuccess || isLoading || isError) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const publication = publicationQuery.data;
  const publicationProfile = publicationProfileQuery.data;

  return <PublicationProfile publication={publication} publicationProfile={publicationProfile} />;
}
