import { useMemo } from 'react';
import cx from 'classnames';

import { Color } from '@/components/DesignTokens';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { ColorWeight } from '@/components/Typography/Typography';

import {
  SCORE_HEIGHT_CLASSNAME,
  SCORE_LINE_HEIGHT_CLASSNAME,
  SCORE_TOOLTIP_WIDTH_CLASSNAME,
  SCORE_WIDTH_CLASSNAME,
  ScoreLabelMapping,
  ScoreValue
} from './Score.types';

interface ScoreMappingEntry {
  color: Color;
  colorWeight: ColorWeight;
  barWidth: number;
  textColor?: string
}

const SCORE_MAPPING: { [key: number]: ScoreMappingEntry } = {
  0: {
    color: Color.SURFACE,
    colorWeight: '500',
    barWidth: 0,
  },
  1: {
    color: Color.TERTIARY,
    colorWeight: '900',
    barWidth: 20,
  },
  2: {
    color: Color.TERTIARY,
    colorWeight: '900',
    barWidth: 40,
  },
  3: {
    color: Color.TERTIARY,
    colorWeight: '900',
    barWidth: 60,
  },
  4: {
    color: Color.TERTIARY,
    colorWeight: '900',
    barWidth: 80,
  },
  5: {
    color: Color.TERTIARY,
    colorWeight: '900',
    barWidth: 100,
  },
}

interface ScoreProps {
  publicationId: string;
  type?: string;
  labels: ScoreLabelMapping;
  score: ScoreValue;
  portalMountedId?: string;
  className?: string;
}

const Score = ({ publicationId, type, labels, score, portalMountedId, className }: ScoreProps) => {
  const scoreNotAvailable = score === 0;
  const label = scoreNotAvailable ? 'Insufficient Data' : labels[score];
  const isTooltipEnabled = !scoreNotAvailable;
  const tooltipId = `${portalMountedId || publicationId}-score-${type}`;

  const tooltipContent = useMemo(() => (
    <ol className="space-y-1">
      {Object.entries(labels).map(([labelScore, labelText]) => {
        const isCurrentScore = Number(labelScore) === score;

        return (
          <Typography
            key={labelScore}
            token={isCurrentScore ? 'font-semibold/text/xs' : 'font-normal/text/xs'}
            color="surface"
            colorWeight={isCurrentScore ? '50' : '300'}
            as="li"
          >
            {labelText}
          </Typography>
        )
      })}
    </ol>
  ), [labels, score])

  return (
    <div className={cx(
      "space-y-1",
      SCORE_WIDTH_CLASSNAME,
      className
    )}>
      {type && (
        <Typography token="font-normal/text/xs" colorWeight="700" as="div">
          {type}
        </Typography>
      )}

      <Tooltip
        id={tooltipId}
        portalMountedId={portalMountedId}
        text=""
        content={tooltipContent}
        showIcon={false}
        tooltipClass={SCORE_TOOLTIP_WIDTH_CLASSNAME}
        padding="8px"
        isEnabled={isTooltipEnabled}
      >
        <div className={cx(
          "flex relative overflow-hidden bg-surface-100 rounded-[4px] group cursor-default",
          SCORE_WIDTH_CLASSNAME,
          SCORE_HEIGHT_CLASSNAME
        )}>
          <div
            className="bg-action-tertiary-200 h-full"
            style={{ width: `${SCORE_MAPPING[score].barWidth}%` }}
          />
          <div
            className={cx(
              "absolute top-0 right-0 bottom-0 left-0 rounded-[4px] border border-transparent",
              isTooltipEnabled && 'group-hover:border-action-tertiary-300 transition-colors'
            )}
          />
          <Typography
            token="font-medium/text/xs"
            color={SCORE_MAPPING[score].color}
            colorWeight={SCORE_MAPPING[score].colorWeight}
            className={cx(
              "absolute top-0 left-0 px-[6px]",
              SCORE_WIDTH_CLASSNAME,
              SCORE_HEIGHT_CLASSNAME,
              SCORE_LINE_HEIGHT_CLASSNAME
            )}
          >
            {label}
          </Typography>
        </div>
      </Tooltip>
    </div>
  );
};

export default Score;
