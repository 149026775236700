import { useEffect } from 'react';

export const useClickOutside = (callback: () => void, ref?: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!ref) {
        return;
      }

      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [callback, ref]);
};
