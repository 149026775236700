import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Typography } from '@/components/Typography';
import { useExternalRssFeedDestroy } from '@/hooks/useExternalRssFeeds';
import { ExternalRssFeed } from '@/interfaces/external_rss_feed';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

interface Props {
  externalRssFeed: ExternalRssFeed;
  publicationId: string;
}

const ExternalFeedListItem = ({ externalRssFeed, publicationId }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { mutate: deleteEndpoint, isLoading } = useExternalRssFeedDestroy({ publicationId });

  const handleClickDelete = () => {
    queryClient.invalidateQueries([publicationId, 'external_rss_feeds']);
    if (externalRssFeed?.id) {
      deleteEndpoint({ id: externalRssFeed.id });
    }
  };

  return (
    <>
      <ActionModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        headerText="Delete Endpoint"
        descriptionText="Deleting this endpoint will permanently remove it and stop all webhook deliveries. This action cannot be undone."
        actionText="Delete"
        buttonType="danger"
        buttonShade="dark"
        onProceed={handleClickDelete}
        resourceId={externalRssFeed.id || ''}
        isWorking={isLoading}
        modalMessageType="danger"
      />
      <div className="flex flex-row justify-between p-3 border border-surface-200 bg-surface-50 rounded-md">
        <div className="flex flex-col gap-y-2 flex-grow">
          <Typography token="font-medium/text/sm">{externalRssFeed.name}</Typography>
          <Typography token="font-medium/text/sm" colorWeight="500">
            {externalRssFeed.url}
          </Typography>
          <div className="flex flex-row space-x-1 items-center">
            {/* <Badge type="info_blue" className="flex flex-row gap-x-1 items-center" tooltip={eventTypesTooltip}>
              {pluralize('Event type', endpoint.event_types.length)}
            </Badge>
            <ErrorRate endpointId={endpoint.id} messageText="Errors" /> */}
          </div>
        </div>
        <div className="flex flex-row gap-x-6">
          <EllipsisDropdown
            options={[
              {
                label: 'Edit',
                onClick: () =>
                  navigate(
                    appendSettingsPublicationId(`/settings/publication/rss/${externalRssFeed.id}/edit`, publicationId)
                  ),
              },
              {
                label: 'Delete',
                onClick: () => setShowDeleteModal(true),
                labelClass: 'text-red-600 border-t border-surface-200',
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default ExternalFeedListItem;
