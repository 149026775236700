export const debounce = (func: Function, wait: number) => {
  let timeout: ReturnType<typeof setTimeout> | null;

  return (...args: any) => {
    const context = this;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export default debounce;
