import { toast } from 'react-hot-toast';
import { TCollabThread, TiptapCollabProvider } from '@hocuspocus/provider';
import { Extension } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { BlockThread, Comments, InlineThread } from '@tiptap-pro/extension-comments';
import throttle from 'lodash.throttle';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    commentsKit: {
      scrollToThread: (threadId: string) => ReturnType;

      /**
       * creates an unreferenced thread and adds a comment to it
       * @param content
       * @param data
       * @param commentData
       * @returns
       */
      createUnreferencedThread: ({
        content,
        data,
        commentData,
      }: {
        content: any;
        data?: Record<string, any>;
        commentData?: Record<string, any>;
      }) => ReturnType;

      /**
       * resolves a thread
       * this is to make sure that resolver Id gets passed with the data when resolving a thread
       * @param threadId
       * @returns
       */
      resolveCollabThread: (options: { id: string; resolvedBy?: string }) => ReturnType;

      /**
       * unresolves a thread
       * this is to make sure that only resolver Id gets removed when unresolving
       * @param threadId
       * @returns
       */
      unresolveCollabThread: (options: { id: string }) => ReturnType;
    };
  }
}

interface ICommentsKitOptions {
  provider: TiptapCollabProvider | null;
  isV2?: boolean;
  openThreadsSidebar: () => void;
}

interface ICommentsKitStorage {
  threadIds: string[];
  threadIdTextContentMap: Record<TCollabThread['id'], string>;
}

export const CommentsKit = Extension.create<ICommentsKitOptions, ICommentsKitStorage>({
  name: 'commentsKit',

  addOptions() {
    return {
      provider: null,
      isV2: false,
      openThreadsSidebar: () => {},
    };
  },

  addStorage() {
    return {
      threadIds: [],
      threadIdTextContentMap: {},
    };
  },

  onCreate() {
    const { editor } = this;

    const resolveUnreferencedThreads = () => {
      const oldThreadIds = this.storage.threadIds;

      let newThreadIds: string[] = [];

      editor.state.doc.descendants((node) => {
        if (node.type.name === 'blockThread' && node.attrs['data-thread-id']) {
          newThreadIds.push(node.attrs['data-thread-id']);

          return;
        }

        node.marks.forEach((mark) => {
          if (mark.type.name === 'inlineThread' && mark.attrs['data-thread-id']) {
            newThreadIds.push(mark.attrs['data-thread-id']);
          }
        });
      });

      newThreadIds = Array.from(new Set(newThreadIds));

      const deletedThreadIds = oldThreadIds.filter((id) => !newThreadIds.includes(id));

      if (deletedThreadIds.length) {
        deletedThreadIds.forEach((id) => editor.commands.resolveThread({ id }));

        toast('Threads referenced by deleted content have been resolved.');
      }

      this.storage.threadIds = newThreadIds;
    };

    const throttledRemoveUnreferencedThreads = throttle(resolveUnreferencedThreads, 3000);

    resolveUnreferencedThreads();

    editor.on('update', throttledRemoveUnreferencedThreads);
    editor.on('destroy', () => editor.off('update', throttledRemoveUnreferencedThreads));

    const extractThreadIdTextContentMap = () => {
      const threadIdTextContentMap: ICommentsKitStorage['threadIdTextContentMap'] = {};

      editor.state.doc.descendants((node) => {
        if (node.type.name === 'blockThread' && node.attrs['data-thread-id']) {
          const threadId = node.attrs['data-thread-id'];
          threadIdTextContentMap[threadId] = node.textContent;

          return;
        }

        node.marks.forEach((mark) => {
          const threadId = mark.attrs['data-thread-id'];

          if (mark.type.name !== 'inlineThread' || !threadId) return;

          threadIdTextContentMap[threadId] = `${threadIdTextContentMap[threadId] || ''} ${node.textContent}`;
        });
      });

      Object.entries(threadIdTextContentMap).forEach(([threadId, textContent]) => {
        if (textContent.length <= 30) return;

        threadIdTextContentMap[threadId] = `${textContent.slice(0, 15)}…${textContent.slice(-15)}`;
      });

      this.storage.threadIdTextContentMap = threadIdTextContentMap;
    };

    const throttledExtractThreadIdTextContentMap = throttle(extractThreadIdTextContentMap, 300);

    extractThreadIdTextContentMap();

    editor.on('update', throttledExtractThreadIdTextContentMap);
    editor.on('destroy', () => editor.off('update', throttledExtractThreadIdTextContentMap));
  },

  addExtensions() {
    const { provider } = this.options;

    return [
      InlineThread.configure({
        provider,
      }),
      BlockThread.extend({
        content: '(block|columns|section|tableBlock|blockThread)+',
      }).configure({
        provider,
      }),
      Comments.configure({
        provider,
      }),
    ];
  },

  addCommands() {
    return {
      createUnreferencedThread:
        ({ content, data, commentData }) =>
        () => {
          if (!this.options.provider) return false;

          const thread = this.options.provider.createThread({ data });

          if (!thread) return false;

          this.options.provider.addComment(thread.id, { content, data: commentData });

          return true;
        },
      resolveCollabThread:
        ({ id: threadId, resolvedBy }) =>
        ({ tr }) => {
          if (!this.options.provider || !threadId) return false;

          const resolvedAt = new Date().toISOString();

          const thread = this.options.provider.getThread(threadId);

          if (!thread) return false;

          this.options.provider.updateThread(threadId, { resolvedAt, data: { ...(thread.data || {}), resolvedBy } });

          tr.setMeta('threadUpdate', threadId);

          return true;
        },
      unresolveCollabThread:
        ({ id: threadId }) =>
        ({ tr }) => {
          if (!this.options.provider || !threadId) return false;

          const thread = this.options.provider.getThread(threadId);

          if (!thread) return false;

          this.options.provider.updateThread(threadId, {
            resolvedAt: null,
            data: { ...(thread.data || {}), resolvedBy: null },
          });

          tr.setMeta('threadUpdate', threadId);

          return true;
        },
      scrollToThread:
        (id) =>
        ({ tr, view }) => {
          interface INodeData {
            node: PMNode | null;
            from: number;
            to: number;
          }

          let inlineThreadNodeData: INodeData = { node: null, from: 0, to: 0 };
          let blockThreadNodeData: INodeData = { node: null, from: 0, to: 0 };

          tr.doc.descendants((node, pos) => {
            if (blockThreadNodeData.node || inlineThreadNodeData.node) return;

            const foundBlockThreadNode = node.type.name === 'blockThread' && node.attrs['data-thread-id'] === id;

            if (foundBlockThreadNode) {
              blockThreadNodeData = { node, from: pos, to: pos + node.nodeSize };
            }

            const inlineThreadFoundInNode = node.marks.some(
              (t) => t.type.name === 'inlineThread' && t.attrs['data-thread-id'] === id
            );

            if (inlineThreadFoundInNode) {
              inlineThreadNodeData = { node, from: pos, to: pos + node.nodeSize };
            }
          });

          if (blockThreadNodeData.node) {
            const domAtPos = view.nodeDOM(blockThreadNodeData.from);

            (domAtPos as HTMLDivElement)?.scrollIntoView({ block: 'start' });

            return true;
          }

          if (inlineThreadNodeData.node) {
            const domAtPos = view.domAtPos(inlineThreadNodeData.from).node;

            (domAtPos as HTMLDivElement)?.scrollIntoView({ block: 'start' });

            return true;
          }

          return false;
        },
    };
  },

  addProseMirrorPlugins() {
    if (this.options.isV2) return [];

    const {
      editor,
      options: { openThreadsSidebar },
    } = this;

    const threadIdTimeoutMap = new Map<string, NodeJS.Timeout>();

    const mouseLastLocation = { x: 0, y: 0 };

    const delayedFocusThreadInSidebar = (threadId: string) => {
      if (threadIdTimeoutMap.get(threadId)) return;

      const timeoutId = setTimeout(() => {
        openThreadsSidebar?.();

        const { x: left, y: top } = mouseLastLocation;
        const posAtCoords = editor.view.posAtCoords({ left, top })?.pos;

        if (posAtCoords && !editor.storage.comments.focusedThreads.includes(threadId)) {
          editor.commands.focus(posAtCoords);
        }
      }, 3000);

      threadIdTimeoutMap.set(threadId, timeoutId);
    };

    return [
      new Plugin({
        key: new PluginKey('commentsKit'),
        props: {
          handleDOMEvents: {
            mouseover(_view, event) {
              if (!event.target) {
                return;
              }

              const threadId = (event.target as HTMLDivElement)
                .closest('[data-thread-id]')
                ?.getAttribute('data-thread-id');

              if (threadId) {
                mouseLastLocation.x = event.clientX;
                mouseLastLocation.y = event.clientY;

                delayedFocusThreadInSidebar(threadId);
              }
            },
            mouseout(_view, event) {
              if (!event.target) {
                return;
              }

              const threadId = (event.target as HTMLDivElement)
                .closest('[data-thread-id]')
                ?.getAttribute('data-thread-id');

              if (threadId) {
                const timeoutId = threadIdTimeoutMap.get(threadId);

                if (timeoutId) {
                  clearTimeout(timeoutId);
                  threadIdTimeoutMap.delete(threadId);
                }
              }
            },
          },
        },
      }),
    ];
  },
});
