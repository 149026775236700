import toast from 'react-hot-toast';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';

import { API } from '../../lib/api';
import { audioTypeRegex } from '../../lib/constants';
import { getFileSizeStringWithUnit } from '../../lib/utils/getFileSizeStringWithUnit';

import { IAudioAttrs } from './types';

const processAudioFile = async ({
  file,
  publicationId,
  userId,
  view,
}: {
  file: File;
  publicationId: string;
  userId: string;
  view: EditorView;
}) => {
  const $pos = view.state.selection.$from;

  try {
    const res = await API.getUploadPublicationDownloadablePresignedUrl({
      file,
      userId,
      publicationId,
    });

    const { file_upload_url: presignedUrl, downloadable_id: audioId } = res.data;

    await API.uploadFileToPresignedUrl({ file, presignedUrl });

    await API.markDownloadableAsUploaded({ downloadableId: audioId, publicationId });

    const audioAttrs: Partial<IAudioAttrs> = {
      id: audioId,
      size: getFileSizeStringWithUnit(file.size),
      type: file.type,
    };

    const node = view.state.schema.nodes.audio.create(audioAttrs);

    const { tr } = view.state;

    tr.replaceRangeWith($pos.pos, $pos.pos, node);

    view.dispatch(tr);
  } catch {
    toast.error('There was a problem uploading this audio file.');
  }
};

export const getAudioDropPlugin = ({ publicationId, userId }: { publicationId: string; userId: string }): Plugin => {
  return new Plugin({
    key: new PluginKey('audioPasteDropHandlerPlugin'),

    props: {
      handleDOMEvents: {
        paste(view, event) {
          if (!event.clipboardData || event.clipboardData.files.length === 0) {
            return false;
          }

          const { types } = event.clipboardData;

          const containsRichText = ['text/plain', 'text/html', 'text/rtf', 'Files'].every((type) =>
            types.includes(type)
          );

          if (containsRichText) {
            return false;
          }

          const file = event.clipboardData.files[0];
          const isAllowedAudioFile = audioTypeRegex.test(file.type);

          if (!isAllowedAudioFile) {
            return false;
          }

          event.preventDefault();

          processAudioFile({ file, publicationId, view, userId });

          return true;
        },
        drop: (view, event) => {
          if (!event.dataTransfer || event.dataTransfer.files.length === 0) {
            return false;
          }

          const file = event.dataTransfer.files[0];
          const isAllowedAudioFile = audioTypeRegex.test(file.type);

          if (!isAllowedAudioFile) {
            return false;
          }

          event.preventDefault();

          processAudioFile({ file, publicationId, view, userId });

          return true;
        },
      },
    },
  });
};
export default getAudioDropPlugin;
