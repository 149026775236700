import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useQueryParam } from 'use-query-params';

export default function ShowToastViaQuery() {
  const [toastSuccess,] = useQueryParam<string | undefined>('toast_success');
  const [toastError,] = useQueryParam<string | undefined>('toast_error');

  const toastShown = useRef(false);


  useEffect(() => {
    if (toastShown.current) {
      return;
    }

    if (toastSuccess) {
      toast.success(decodeURIComponent(toastSuccess));
    }
    
    if (toastError) {
      toast.error(decodeURIComponent(toastError));
    }
    
    toastShown.current = true;
  }, [toastSuccess, toastError, toastShown]);

  return null;
}
