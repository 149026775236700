import NoticeMessage from '@/components/NoticeMessage';
import Text from '@/components/Text';

const DeletingMessage = () => {
  return (
    <NoticeMessage>
      <Text size="sm" className="flex flex-row gap-2 p-2">
        Your request for deletion is being processed.
      </Text>
    </NoticeMessage>
  );
};

export default DeletingMessage;
