import { useInfiniteQuery } from 'react-query';

import { AdNetworkAdvertiser } from '../../../interfaces/ad_network/internal/advertiser';
import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  advertisers: AdNetworkAdvertiser[];
  pagination: Pagination;
}

export default function useIndustryAdvertisers({ id }: { id: string }) {
  const fetchAdvertisers = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/internal/industries/${id}/advertisers`, {
        params: {
          page: pageParam,
          per_page: 25,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['ad_network', 'internal', 'industries', id, 'advertisers'], fetchAdvertisers, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
