import { CheckCircleIcon, ClockIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { Post, PostStatus } from '@/interfaces/post';

interface Props {
  post: Post;
}

const Header = ({ post }: Props) => {
  const { status } = post;
  let title;
  let iconBgColor;
  let icon;
  if (status === PostStatus.DRAFT) {
    title = 'Review and Publish';
    iconBgColor = 'bg-action-secondary-50';
    icon = <PaperAirplaneIcon className="h-6 w-6 text-action-secondary-600 flex-grow" />;
  } else if (status === PostStatus.PUBLISHED || status === PostStatus.ARCHIVED) {
    title = 'Your post is published';
    iconBgColor = 'bg-feedback-success-100';
    icon = <CheckCircleIcon className="h-6 w-6 text-feedback-success-500 flex-grow" />;
  } else {
    title = 'Your post is scheduled';
    iconBgColor = 'bg-feedback-info-50';
    icon = <ClockIcon className="h-6 w-6 text-feedback-info-500 flex-grow" />;
  }

  return (
    <div className="flex flex-col gap-5 items-center">
      <div className={cx('rounded w-16 h-16 flex flex-row items-center', iconBgColor)}>{icon}</div>
      <Typography token="font-semibold/text/4xl">{title}</Typography>
    </div>
  );
};

export default Header;
