import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { CheckIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Input } from '@/components/Form';
import Text from '@/components/Text';
import { useCurrentPublication, useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { useAuthors, useRemoveAuthorFromPosts } from '@/hooks/useAuthors';

import { Section, SwitchForm } from '../../../../Components';

const DELETE_CONFIRMATION = 'DELETE';

const AuthorsSection = () => {
  const { data: currentPublication } = useCurrentPublication();
  const authorsQuery = useAuthors();
  const { data: authors } = authorsQuery;

  const webTemplateMutation = useUpdateWebTemplate(() => {
    toast.success('Saved!');
  });
  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: any = webTemplateRequest;
  const authorIds = webTemplate?.author_ids;
  const authorsEnabled = authorIds?.length > 0;

  const [authorToRemove, setAuthorToRemove] = useState<string>('');
  const [confirmDelete, setConfirmDelete] = useState<string>('');
  const removeAuthoredPosts = useRemoveAuthorFromPosts({
    authorId: authorToRemove,
    onSuccess: () => {
      setAuthorToRemove('');
      setConfirmDelete('');
      webTemplateMutation.mutate({ author_ids: authorIds }); // This will update the list of author ids for the site.
    },
  });

  const handleToggleAuthorsPage = async () => {
    if (authorsEnabled) {
      await webTemplateMutation.mutateAsync({ author_ids: [] });
    } else {
      await webTemplateMutation.mutateAsync({ author_ids: authors?.map((author: any) => author.id) });
    }
  };

  return (
    <>
      <ActionModal
        isOpen={Boolean(authorToRemove)}
        onClose={() => {
          setAuthorToRemove('');
          setConfirmDelete('');
        }}
        resourceId={authorToRemove}
        headerText="Remove author from posts"
        onProceed={() => {
          if (confirmDelete !== DELETE_CONFIRMATION) {
            toast.error('Please type DELETE to confirm');
          } else {
            removeAuthoredPosts.mutate();
          }
        }}
        actionText="Delete Author"
        isWorking={removeAuthoredPosts.isLoading}
        buttonType="danger"
      >
        <div className="text-sm space-y-2">
          <p>Taking this action will remove this user as an author from all posts they are associated with.</p>
          <p>This action CANNOT be undone.</p>
          <Input
            name="confirm-delete"
            placeholderText="Type DELETE"
            onChange={(e) => setConfirmDelete(e.target.value)}
            value={confirmDelete}
            helperText="Type DELETE and click 'Delete Author' to confirm."
          />
        </div>
      </ActionModal>
      <Section
        title="Authors"
        description="Customize the authors that appear on your site's Author Page."
        scrollToId="authors"
        isLoading={!authors || !webTemplate}
        tooltipText="Authors are users who have published at least 1 post on your site."
        customCopyLink={authorsEnabled ? `${currentPublication?.url}authors` : undefined}
      >
        <SwitchForm
          name="enable_disable_authors_page"
          label="Authors Page"
          description={authorsEnabled ? 'Toggle to disable' : 'Toggle to enable'}
          value={authorsEnabled}
          onSave={handleToggleAuthorsPage}
        />
        {authors?.map((author: any, index: number) => {
          const isLast = index === authors.length - 1;
          const isLive = authorIds?.includes(author.id);
          const options = [];

          if (!author.is_team_member) {
            options.push({
              label: 'Delete author',
              onClick: () => setAuthorToRemove(author.id),
            });
          }

          if (isLive) {
            options.push({
              label: 'Remove from authors page',
              onClick: () => {
                const newAuthorIds = authorIds.filter((id: string) => id !== author.id);
                webTemplateMutation.mutate({ author_ids: newAuthorIds });
              },
            });
          } else {
            options.push({
              label: 'Add to authors page',
              onClick: () => {
                const newAuthorIds = [...authorIds, author.id];
                webTemplateMutation.mutate({ author_ids: newAuthorIds });
              },
            });
          }

          return (
            <div
              key={author.id}
              className={cx('pb-4 flex justify-between items-center', !isLast && 'border-b border-gray-100')}
            >
              <div className="space-y-1">
                <div className="flex space-x-1 items-center">
                  <Text size="sm" className="font-semibold text-gray-700">
                    {author.name}
                  </Text>
                  {isLive && <CheckIcon className="w-4 h-4 text-green-500" />}
                </div>
                {!author.is_team_member && (
                  <div className="w-full max-w-sm">
                    <div className="bg-red-100  px-2 py-1 rounded">
                      <Text as="p" size="xs" className="text-red-500">
                        It looks like this user is no longer on your team but is still tagged as an author for posts on
                        your site. Select &lsquo;Delete author&rsquo; in the dropdown to remove them from all posts on
                        your site.
                      </Text>
                    </div>
                  </div>
                )}
              </div>
              {options.length > 0 ? <EllipsisDropdown options={options} /> : null}
            </div>
          );
        })}
      </Section>
    </>
  );
};

export default AuthorsSection;
