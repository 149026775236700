import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { RecommendationWidget, RecommendationWidgetMutatorParams } from '@/interfaces/recommendation_widget';
import api from '@/services/swarm';

interface UpdateParams {
  recommendationWidget: RecommendationWidget;
  externalEmbedIds: string[];
}

const useUpdateRecommendationWidget = ({ onSuccess, onError }: RecommendationWidgetMutatorParams) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    ({ recommendationWidget, externalEmbedIds }: UpdateParams) =>
      api.put(`/publications/${currentPublicationId}/recommendation_widgets/${recommendationWidget.id}`, {
        recommendation_widget: recommendationWidget,
        external_embed_ids: externalEmbedIds,
      }),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response.data.recommendation_widget);
        queryClient.invalidateQueries(['recommendationWidget', currentPublicationId]);

        toast.success('Widget settings updated successfully!');
      },
      onError: (err: any) => {
        if (onError) {
          onError(err?.response?.data);
        } else {
          toast.error(err?.response?.data?.error || 'Could not update widget settings.');
        }
      },
    }
  );
};

export default useUpdateRecommendationWidget;
