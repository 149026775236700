import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

interface Props {
  to: string;
  children: React.ReactNode;
}

export default function BackLink({ to, children }: Props) {
  return (
    <div className="my-4">
      <Link className="text-sm text-gray-500 hover:text-primary-500" to={to}>
        <div className="flex items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-2" />
          <span>{children}</span>
        </div>
      </Link>
    </div>
  );
}
