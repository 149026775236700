import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

export default function usePollResponseCount() {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<{ count: number }>(
    'poll_response_count',
    () =>
      api
        .get(`/polls/response_count`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: Infinity, // No need to refetch this ever again
    }
  );
}
