import OrderedListExtension from '@tiptap/extension-ordered-list';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { OrderedListView } from './views/OrderedListView';

export const OrderedList = OrderedListExtension.extend({
  addNodeView() {
    return ReactNodeViewRenderer(OrderedListView);
  },
});

export default OrderedList;
