import { IconComponent } from '@heroicons/react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

interface Props {
  title: string;
  publicationName: string;
  periodLabel: string;
  Icon: IconComponent;
  iconColor: string;
}

const KPI = ({ title, publicationName, periodLabel, Icon, iconColor }: Props) => (
  <div className="flex p-4 border border-surface-200 rounded-md gap-x-6">
    <div className="flex w-fit h-fit p-3 bg-tertiary-50 rounded-full">
      <Icon className={cx('w-5 h-5', iconColor)} />
    </div>
    <div className="flex flex-col gap-y-2">
      <Typography token="font-medium/text/sm" colorWeight="700">
        {title}
      </Typography>
      <Typography token="font-semibold/text/2xl" colorWeight="700">
        {publicationName}
      </Typography>
      <Typography token="font-medium/text/sm" colorWeight="700">
        {periodLabel}
      </Typography>
    </div>
  </div>
);

export default KPI;
