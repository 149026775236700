import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

type DeliveryContextContent = {
  isChanging: boolean;
  setIsChanging: (val: boolean) => void;
};

const DeliveryContext = createContext<DeliveryContextContent>({ isChanging: false, setIsChanging: () => {} });

const DeliveryContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isChanging, setIsChanging] = useState(false);
  const contextValue = useMemo(() => ({ isChanging, setIsChanging }), [isChanging]);

  return <DeliveryContext.Provider value={contextValue}>{children}</DeliveryContext.Provider>;
};

const useDeliveryContext = () => useContext(DeliveryContext);

export { DeliveryContextProvider, useDeliveryContext };
