import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkCampaign } from '../../../interfaces/ad_network/internal/campaign';
import api from '../../../services/swarm';

interface SuccessResponse {}
interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}
interface Variables {}

export default function useCampaignDestroy({ campaign }: { campaign: AdNetworkCampaign }) {
  const navigate = useNavigate();

  return useMutation<SuccessResponse, ErrorResponse, Variables>(
    () => api.delete(`/ad_network/internal/campaigns/${campaign.id}`).then((res) => res.data),
    {
      onSuccess: () => {
        toast.success('Campaign deleted successfully');

        navigate(`/ad_network/advertisers/${campaign.advertiser.id}/campaigns`);
      },
      onError: (error) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      },
    }
  );
}
