import { useMemo } from 'react';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import useActiveSubscriptionsByPeriod from '../_hooks/useActiveSubscriptionsByPeriod';

const ActiveSubscriptionsChart = () => {
  // Revenue can only be broken down montly at the moment so filter out the unneeded options
  const excludeOptions = [TimePeriod.LAST_24_HOURS, TimePeriod.LAST_4_WEEKS, TimePeriod.LAST_7_DAYS];
  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: TimePeriod.ALL_TIME, excludeOptions });
  const { data, isLoading, isError } = useActiveSubscriptionsByPeriod(period as keyof typeof timePeriodLabels);

  const valueFormatter = (num: number) => new Intl.NumberFormat('en').format(num);

  const parsedData = useMemo(
    () => (data?.chart_data ? data.chart_data.map((o) => ({ ...o, Subscriptions: parseFloat(o.Subscriptions) })) : []),
    [data?.chart_data]
  );

  const hasNoData = !data?.chart_data || data?.chart_data.length === 0;

  return (
    <ChartCard
      title="Active Paying Subscribers"
      description="Shows all active paid subscribers over time"
      isLoading={isLoading}
      noResultsText="No active paid subscribers within the chosen time range."
      noResults={isError || (!isLoading && hasNoData)}
      actionChildren={<TimePeriodSelect />}
    >
      {data && (
        <AreaChart
          data={parsedData}
          index="name"
          categories={data.categories}
          valueFormatter={valueFormatter}
          allowDecimals={false}
          colors={['pink']}
        />
      )}
    </ChartCard>
  );
};

export default ActiveSubscriptionsChart;
