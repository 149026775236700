import { AutomationConditionName, AutomationConditionOperator } from '@/interfaces/automations/automation_condition';
import { Option } from '@/interfaces/general';

export interface ConditionOption extends Option {
  value: string | AutomationConditionName;
  dataType?: 'text' | 'number';
}

export const CONDITION_OPTIONS: ConditionOption[] = [
  {
    label: 'Subscription',
    value: '',
    isOptGroup: true,
  },
  {
    label: 'Email',
    value: AutomationConditionName.EMAIL,
    dataType: 'text',
  },
  {
    label: 'Tier',
    value: AutomationConditionName.TIER_ID,
    dataType: 'text',
  },
  {
    label: 'Custom Field',
    value: AutomationConditionName.CUSTOM_FIELD_ID,
    dataType: 'text',
  },
  {
    label: 'Subscriber Tags',
    value: AutomationConditionName.SEGMENT_TAG_ID,
    dataType: 'text',
  },
  {
    label: 'Survey',
    value: AutomationConditionName.FORM_ID,
    dataType: 'text',
  },
  {
    label: 'Acquisition',
    value: '',
    isOptGroup: true,
  },
  {
    label: 'URL',
    value: AutomationConditionName.LANDING_PAGE,
    dataType: 'text',
  },
  {
    label: 'Channel',
    value: AutomationConditionName.CHANNEL,
    dataType: 'text',
  },
  {
    label: 'UTM Source',
    value: AutomationConditionName.SOURCE,
    dataType: 'text',
  },
  {
    label: 'UTM Medium',
    value: AutomationConditionName.MEDIUM,
    dataType: 'text',
  },
  {
    label: 'UTM Campaign',
    value: AutomationConditionName.CAMPAIGN,
    dataType: 'text',
  },
  {
    label: 'Referring URL',
    value: AutomationConditionName.REFERRING_URL,
    dataType: 'text',
  },
  {
    label: 'Embed Source',
    value: AutomationConditionName.EXTERNAL_EMBED_ID,
    dataType: 'text',
  },
  {
    label: 'Engagement',
    value: '',
    isOptGroup: true,
  },
  {
    label: 'Days without opens',
    value: AutomationConditionName.LAST_OPENED_DAYS_AGO,
    dataType: 'number',
  },
  {
    label: 'Days without clicks',
    value: AutomationConditionName.LAST_CLICKED_DAYS_AGO,
    dataType: 'number',
  },
  {
    label: 'Days without opens or clicks',
    value: AutomationConditionName.LAST_OPENED_OR_CLICKED_DAYS_AGO,
    dataType: 'number',
  },
];

interface OperatorOption extends Option {
  label: string;
  value: AutomationConditionOperator;
}

const DEFAULT_OPERATOR_OPTIONS: OperatorOption[] = [
  { label: 'equals', value: AutomationConditionOperator.EQUAL },
  { label: 'does not equal', value: AutomationConditionOperator.NOT_EQUAL },
  { label: 'contains', value: AutomationConditionOperator.CONTAIN },
  { label: 'does not contain', value: AutomationConditionOperator.NOT_CONTAIN },
  { label: 'begins with', value: AutomationConditionOperator.START_WITH },
  { label: 'ends with', value: AutomationConditionOperator.END_WITH },
];

export const ENGAGEMENT_FIELD_OPERATOR_OPTIONS: OperatorOption[] = [
  { label: 'is exactly', value: AutomationConditionOperator.EQUAL },
  { label: 'is not exactly', value: AutomationConditionOperator.NOT_EQUAL },
  { label: 'is greater than', value: AutomationConditionOperator.GREATER_THAN },
  { label: 'is greater than or equal to', value: AutomationConditionOperator.GREATER_THAN_EQUAL_TO },
  { label: 'is less than', value: AutomationConditionOperator.LESS_THAN },
  { label: 'is less than or equal to', value: AutomationConditionOperator.LESS_THAN_EQUAL_TO },
];

export const OPERATOR_OPTIONS_BY_CONDITION_NAME: { [key in AutomationConditionName]: OperatorOption[] } = {
  [AutomationConditionName.CAMPAIGN]: DEFAULT_OPERATOR_OPTIONS,
  [AutomationConditionName.CHANNEL]: DEFAULT_OPERATOR_OPTIONS,
  [AutomationConditionName.CUSTOM_FIELD_ID]: DEFAULT_OPERATOR_OPTIONS.concat([
    { label: 'exists', value: AutomationConditionOperator.EXISTS },
    { label: 'does not exist', value: AutomationConditionOperator.DOES_NOT_EXIST },
  ]),
  [AutomationConditionName.SEGMENT_TAG_ID]: [
    { label: 'includes', value: AutomationConditionOperator.CONTAIN },
    { label: 'does not include', value: AutomationConditionOperator.NOT_CONTAIN },
  ],
  [AutomationConditionName.EMAIL]: DEFAULT_OPERATOR_OPTIONS,
  [AutomationConditionName.EMAIL_SUBMISSION_SOURCE]: DEFAULT_OPERATOR_OPTIONS,
  [AutomationConditionName.EXTERNAL_EMBED_ID]: [
    { label: 'was', value: AutomationConditionOperator.EQUAL },
    { label: 'was not', value: AutomationConditionOperator.NOT_EQUAL },
  ],
  [AutomationConditionName.FORM_ID]: [
    { label: 'was submitted', value: AutomationConditionOperator.EXISTS },
    { label: 'was not submitted', value: AutomationConditionOperator.DOES_NOT_EXIST },
  ],
  [AutomationConditionName.LANDING_PAGE]: DEFAULT_OPERATOR_OPTIONS,
  [AutomationConditionName.LAST_CLICKED_DAYS_AGO]: ENGAGEMENT_FIELD_OPERATOR_OPTIONS,
  [AutomationConditionName.LAST_OPENED_DAYS_AGO]: ENGAGEMENT_FIELD_OPERATOR_OPTIONS,
  [AutomationConditionName.LAST_OPENED_OR_CLICKED_DAYS_AGO]: ENGAGEMENT_FIELD_OPERATOR_OPTIONS,
  [AutomationConditionName.MEDIUM]: DEFAULT_OPERATOR_OPTIONS,
  [AutomationConditionName.POLL_ID]: [{ label: 'was submitted', value: AutomationConditionOperator.EXISTS }],
  [AutomationConditionName.POLL_CHOICE_ID]: [{ label: 'was submitted', value: AutomationConditionOperator.EXISTS }],
  [AutomationConditionName.REFERRING_URL]: DEFAULT_OPERATOR_OPTIONS,
  [AutomationConditionName.REFERRAL_ACTION]: [{ label: 'equals', value: AutomationConditionOperator.EQUAL }],
  [AutomationConditionName.SEGMENT_ID]: [
    { label: 'entered', value: AutomationConditionOperator.EXISTS },
    { label: 'exited', value: AutomationConditionOperator.DOES_NOT_EXIST },
  ],
  [AutomationConditionName.SOURCE]: DEFAULT_OPERATOR_OPTIONS,
  [AutomationConditionName.TIER_ID]: [
    { label: 'equals', value: AutomationConditionOperator.EQUAL },
    { label: 'does not equal', value: AutomationConditionOperator.NOT_EQUAL },
  ],
};
