import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { useThemeData } from '../../../../../../lib/hooks/useThemeData';
import CustomColorPanel from '../../../../../panels/CustomColor';
import { Button } from '../../../../../ui/Button';
import { Icon } from '../../../../../ui/Icon';
import { Panel, PanelFooter, PanelHeader } from '../../../../../ui/Panel';
import { PanelSection } from '../../../../../ui/Panel/styled';
import { useCurrentNode } from '../../../../hooks';
import Styled from '../../../../styled';
import useVisualSettingsPanel from '../VisualSettingsPanel.hooks';

import { BorderColor } from './BorderColor';
import { BorderRadius } from './BorderRadius';
import { BorderStyle } from './BorderStyle';
import { BorderWidth } from './BorderWidth';

export const BordersPanel = ({
  editor,
  onBack,
  navigateToPanel,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  onBack: Function;
  currentNode: Node | null;
  currentNodePos: number;
  navigateToPanel: Function;
}) => {
  const {
    isCustomBorderColorPanelOpen,
    onShowCustomBorderColorPanel,
    onCloseCustomBorderColorPanel,
    onSetCustomBorderColor,
    onResetBorders,
  } = useVisualSettingsPanel(editor, currentNode, currentNodePos, navigateToPanel);
  const themeColors = useThemeData('colors');
  const { hasBorderOverrides } = useCurrentNode(editor, currentNode, themeColors);

  const renderDefaultPanel = () => (
    <Styled.ContentWrapper>
      <Panel>
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            Borders
          </Button>
        </PanelHeader>
        <PanelSection>
          <BorderRadius editor={editor} currentNode={currentNode} currentNodePos={currentNodePos} />
          <BorderWidth editor={editor} currentNode={currentNode} currentNodePos={currentNodePos} />
          <BorderStyle editor={editor} currentNode={currentNode} currentNodePos={currentNodePos} />
          <BorderColor
            editor={editor}
            onShowCustomBorderColorPanel={onShowCustomBorderColorPanel}
            currentNode={currentNode}
            currentNodePos={currentNodePos}
          />
        </PanelSection>
        {hasBorderOverrides && (
          <PanelFooter>
            <Button
              $variant="quaternary"
              $size="small"
              $rightSlot={<Icon name="Reset" />}
              onClick={onResetBorders}
              $fullWidth
              $active
              $muted
            >
              Reset all
            </Button>
          </PanelFooter>
        )}
      </Panel>
    </Styled.ContentWrapper>
  );

  const renderCustomBorderColorPanel = () => (
    <Styled.ContentWrapper>
      <CustomColorPanel
        selectedColor={currentNode?.attrs.borderColor}
        onBack={onCloseCustomBorderColorPanel}
        onSelect={onSetCustomBorderColor}
      />
    </Styled.ContentWrapper>
  );

  if (isCustomBorderColorPanelOpen) {
    return renderCustomBorderColorPanel();
  }

  return renderDefaultPanel();
};

export default BordersPanel;
