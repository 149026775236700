import { Editor } from '@tiptap/core';
import TiptapFontFamily from '@tiptap/extension-font-family';

type FontFamilyStorage = {
  usedFonts: Record<string, any>;
};

export const FontFamily = TiptapFontFamily.extend<{}, FontFamilyStorage>({
  addStorage() {
    return {
      usedFonts: [],
    };
  },

  // @ts-ignore
  onCreate({ editor }: { editor: Editor }) {
    const allFontFamilies: Record<string, number> = {};

    editor.state.doc.descendants((node) => {
      if (node.type.name === 'text') {
        const textStyleMark = node.marks.find((m) => m.type.name === 'textStyle');

        const { fontFamily } = textStyleMark?.attrs || {};
        if (textStyleMark?.attrs.fontFamily) {
          if (fontFamily in allFontFamilies) {
            allFontFamilies[fontFamily] += 1;
          } else {
            allFontFamilies[fontFamily] = 1;
          }
        }
      }
    });

    this.storage.usedFonts = allFontFamilies;
  },

  addCommands() {
    return {
      setFontFamily:
        (fontFamily) =>
        ({ chain }) => {
          if (fontFamily in this.storage.usedFonts) {
            this.storage.usedFonts[fontFamily] += 1;
          } else {
            this.storage.usedFonts[fontFamily] = 1;
          }
          return chain().setMark('textStyle', { fontFamily }).run();
        },
      unsetFontFamily:
        () =>
        ({ chain, editor }) => {
          const { fontFamily } = editor.getAttributes('textStyle') || {};
          if (fontFamily in this.storage.usedFonts) {
            this.storage.usedFonts[fontFamily] -= 1;
            if (this.storage.usedFonts[fontFamily] === 0) {
              delete this.storage.usedFonts[fontFamily];
            }
          }

          return chain().setMark('textStyle', { fontFamily: null }).removeEmptyTextStyle().run();
        },
    };
  },
});
