import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

const StripeLink = () => {
  return (
    <Button
      type="button"
      variant="flush"
      className="flex flex-row space-x-2 align-middle"
      onClick={() => window.open('https://dashboard.stripe.com/billing')}
      Icon={ArrowTopRightOnSquareIcon}
      iconRight
    >
      Stripe Account
    </Button>
  );
};

export default StripeLink;
