import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDebounce } from 'use-debounce';

import usePublicationSearch from '@/hooks/usePublications/usePublicationSearch';
import { PublicationSearch } from '@/interfaces/publication_search';
import { SearchPublication } from '@/ui/SearchPublication';

import AddRecommendationForm from './AddRecommendationForm';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSuccess: () => void;
}

export default function PublicationTransition({ isOpen, setIsOpen, onSuccess }: Props) {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 450);
  const { data, isFetching } = usePublicationSearch({ query: debouncedQuery });
  const publications = data?.pages.flatMap((page) => page.publications) || [];

  const handleRenderActiveOption = (publication: PublicationSearch) => (
    <AddRecommendationForm publicationId={publication.id} publicationName={publication.name} onSubmit={onSuccess} />
  );

  return (
    <Transition.Root show={isOpen} as={Fragment} appear>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <SearchPublication
                publications={publications}
                isFetchingPublications={isFetching}
                onSetQuery={setQuery}
                query={query}
                onRenderActiveOption={handleRenderActiveOption}
              />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
