import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

const useDeleteSvixEndpoint = (publicationId: string) => {
  const queryClient = useQueryClient();

  const params = {
    publication_id: publicationId,
  };

  return useMutation((endpointId: string) => api.delete(`/webhooks/svix_endpoints/${endpointId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.message || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['publications', publicationId, 'webhooks', 'endpoints']);
      toast.success('Endpoint Removed!');
    },
  });
};

export default useDeleteSvixEndpoint;
