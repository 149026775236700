import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import useDeleteSubscriberPreference from '@/hooks/useSubscriberPreferences/useDeleteSubscriberPreference';
import { PublicationSubscriberPreference, SubscriberPreferenceVisibility } from '@/interfaces/subscribers_preferences';

import { ICON_BY_SUBSCRIBER_PREFERENCE_TYPE, LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE } from '../constants';

import CreateSegmentModal from './CreateSegmenModal';
import SettingsBadge from './SettingsBadge';

interface Props {
  item: PublicationSubscriberPreference;
}

const PreferencesListItem = ({ item }: Props) => {
  const navigate = useNavigate();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showCreateSegmentModal, setShowCreateSegmentModal] = useState(false);

  const { mutateAsync: deleteSubscriberPreference, isLoading } = useDeleteSubscriberPreference();

  const handleClickDelete = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleClickConfirmDelete = () => {
    deleteSubscriberPreference({ id: item.id });
  };

  const Icon = ICON_BY_SUBSCRIBER_PREFERENCE_TYPE[item.preference_type];

  return (
    <>
      <ActionModal
        modalMessageType="danger"
        buttonType="danger"
        buttonShade="dark"
        resourceId="confirm-delete-subscriber-preference"
        isOpen={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        headerText="Delete Preference"
        descriptionText="By deleting this preference, the custom field updated by the subscriber will not be deleted in your account. However, the option to update this preference by the subscriber will no longer be present in their account."
        onProceed={handleClickConfirmDelete}
        isWorking={isLoading}
      />
      <CreateSegmentModal
        isOpen={showCreateSegmentModal}
        onClose={() => setShowCreateSegmentModal(false)}
        subscriberPreference={item}
      />
      <ItemRow>
        <ItemColumn align={AlignType.LEFT}>
          <Typography token="font-medium/text/sm" colorWeight="700">
            {item.name}
          </Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <div className="w-56 sm:w-80">
            <Typography token="font-normal/text/sm" colorWeight="700">
              {item.description}
            </Typography>
          </div>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <span className="inline-flex flex-row gap-x-2 items-center">
            <Icon className="w-5 h-5 text-surface-500" />
            <Typography token="font-normal/text/sm" colorWeight="700">
              {LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE[item.preference_type]}
            </Typography>
          </span>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Typography token="font-normal/text/sm" colorWeight="700" className="font-mono">
            {`{{${item.custom_field?.name}}}`}
          </Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <SettingsBadge isVisible={item.visibility === SubscriberPreferenceVisibility.VISIBLE} />
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <EllipsisDropdown
            options={[
              { label: 'Edit', onClick: () => navigate(`/subscribers/preferences/${item.id}/edit`) },
              { label: 'Create Segment', onClick: () => setShowCreateSegmentModal(true) },
              { label: 'Delete', onClick: handleClickDelete },
            ]}
          />
        </ItemColumn>
      </ItemRow>
    </>
  );
};

export default PreferencesListItem;
