import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { NavGroupResponse } from '../../interfaces/nav_group';
import api from '../../services/swarm';

interface ApiResponse {
  nav_group: NavGroupResponse;
}

interface Props {
  onSuccess: (result: ApiResponse) => void;
  onError: () => void;
}

const useCreateNavGroup = ({ onSuccess, onError }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, { title: string; group_type: 'nav' | 'managed' | 'category' }>(
    ({ title, group_type }) =>
      api
        .post('/nav_groups', {
          publication_id: currentPublicationId,
          nav_group: {
            title,
            group_type,
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
        onError();
      },
    }
  );
};

export default useCreateNavGroup;
