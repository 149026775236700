/* eslint-disable */
import Tooltip from '../../components/Tooltip';
import { useEffect, useRef, useState } from 'react';
import Card from '../Card';
import Header from '../Header';
import Text from '../../components/Text/Text';
import { GlobeAmericasIcon } from '@heroicons/react/24/outline';

// @ts-ignore
mapboxgl.accessToken = window.env.REACT_APP_MAPBOX_API_KEY || '';

const Mapbox = ({
  longitude,
  latitude,
  container,
  style,
  zoom,
  showPin = true,
  height = 200,
}: {
  longitude: number;
  latitude: number;
  container?: string;
  style?: string;
  zoom?: number;
  showPin?: boolean;
  height?: number;
}) => {
  const map = useRef(null as any);
  const containerId = container || 'location-map';

  useEffect(() => {
    if (!map.current && (latitude !== undefined || longitude !== undefined) && latitude !== 0 && longitude !== 0) {
      // @ts-ignore
      map.current = new mapboxgl.Map({
        container: containerId,
        style: style || 'mapbox://styles/mapbox/outdoors-v12',
        // style: 'mapbox://styles/beehiiv/clbmdlouc000114s6j968vu8w',
        // style: 'mapbox://styles/mapbox/outdoors-v12'
        // style: 'mapbox://styles/mapbox/streets-v12',
        // style: 'mapbox://styles/mapbox/light-v11',
        // style: 'mapbox://styles/mapbox/dark-v11',
        // style: 'mapbox://styles/mapbox/satellite-v9',
        // style: 'mapbox://styles/mapbox/satellite-streets-v12',
        // style: 'mapbox://styles/mapbox/navigation-day-v1',
        // style: 'mapbox://styles/mapbox/navigation-night-v1',
        center: [longitude, latitude],
        zoom: zoom || 9,
        interactive: false,
        projection: 'mercator',
        attributionControl: false,
      });

      if (showPin) {
        // @ts-ignore
        new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(map.current);
      }

      map.current.resize();
    }
  }, [longitude, latitude, style, zoom]);

  return (
    <div className="mapbox-container">
      <div id={containerId} className="map-container rounded-md bg-gray-100" style={{ height: height }}>
        <div className="flex flex-col justify-center items-center h-full w-full">
          <GlobeAmericasIcon className="text-gray-400 h-10 w-10" />
          <Text type="subtitle" size="sm" className="text-gray-400">
            No map data
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Mapbox;
