import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { AdvertisementOpportunityView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    advertisementOpportunity: {
      setAdvertisementOpportunity: (attrs?: Record<string, any>) => ReturnType;
    };
  }
}

export const AdvertisementOpportunity = Node.create({
  name: 'advertisementOpportunity',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      publicationId: '',
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({
          'data-id': attributes.id,
        }),
      },
      showOptionsInitially: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-show-options-initially'),
        renderHTML: (attributes) => ({
          'data-show-options-initially': attributes.showOptionsInitially,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setAdvertisementOpportunity:
        (attrs = {}) =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs,
            })
            .run(),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(AdvertisementOpportunityView);
  },

  addProseMirrorPlugins() {
    const { editor, name } = this;

    return [
      new Plugin({
        key: new PluginKey('advertisementDropPlugin'),
        props: {
          handleDrop(view, event) {
            const jsonString = event.dataTransfer?.getData('text/plain');

            try {
              const json = JSON.parse(jsonString || '');

              const pos = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              })?.pos;

              if (typeof pos === 'number' && pos >= 0 && json.adDrag && json.id) {
                editor.commands.insertContentAt(pos, {
                  type: name,
                  attrs: {
                    id: json.id,
                  },
                });

                return true;
              }
            } catch {
              // no op
            }

            return false;
          },
        },
      }),
    ];
  },
});

export default AdvertisementOpportunity;
