import { PublicationSubscriberTagReference } from '../subscriber_tag';
import { SubscriptionStatuses } from '../subscription';
import { WebhookDestination } from '../webhook_destination';

import { AutomationConditions } from './automation_condition';

export enum AutomationStepStepType {
  BRANCH = 'branch',
  ENROLL_IN_AUTOMATION = 'enroll_in_automation',
  SEND_EMAIL = 'send_email',
  UPDATE_SUBSCRIPTION = 'update_subscription',
  WAIT_UNTIL = 'wait_until',
  WAIT_FOR = 'wait_for',
  WEBHOOK = 'webhook',
  ADD_AUTOMATION_STEP = 'addAutomationStep',
  AUTOMATION_STEP = 'automationStep',
}

export const AutomationStepDeletionDescriptions: { [key in AutomationStepStepType]: string } = {
  branch: 'They will not follow subsequent steps and will finish the automation flow.',
  enroll_in_automation: 'They will not be enrolled in the selected automation and will finish the automation flow.',
  send_email: 'They will not receive the email message and will finish the automation flow.',
  update_subscription:
    'Their subscriptions will not be updated and they will move on to the next step once their wait time is up.',
  wait_until: 'They will not wait until the specified date and time and will immediately move on to the next step.',
  wait_for: 'They will not wait for the specified amount of time and will immediately move on to the next step.',
  webhook: 'No webhooks will be sent and they will finish the automation flow.',
  addAutomationStep: '',
  automationStep: '',
};

export const AutomationStepDeactivateDescriptions: { [key in AutomationStepStepType]: string } = {
  branch: 'They will not follow subsequent steps and will finish the automation flow.',
  enroll_in_automation: 'They will not be enrolled in the selected automation and will move on to the next step.',
  send_email: 'They will not receive the email message and will move on to the next step.',
  update_subscription: 'Their subscriptions will not be updated and they will move on to the next step.',
  wait_until:
    'They will continue to wait until the specified date and time and will then move on to the next step without adding to the total count of completed subscribers for this step.',
  wait_for:
    'They will continue to wait for the specified amount of time and will then move on to the next step without adding to the total count of completed subscribers for this step.',
  webhook: 'No webhooks will be sent and they will move on to the next step.',
  addAutomationStep: '',
  automationStep: '',
};

export const AutomationStepActivateDescriptions: { [key in AutomationStepStepType]: string } = {
  branch: 'Please ensure that both branches are set up correctly.',
  enroll_in_automation: 'Please ensure that the automation you have selected is set up correctly.',
  send_email: 'Please ensure that that your email message is correct.',
  update_subscription: 'Their subscriptions will be updated according to the conditions you have set.',
  wait_until: 'Please ensure that the date and time conditions that you have set are correct.',
  wait_for: '',
  webhook:
    'Please ensure that you have configured the webhook correctly. Use the Test Webhook functionality to test the webhook.',
  addAutomationStep: '',
  automationStep: '',
};

export enum AutomationStepState {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export interface AutomationStepEnrollInAutomationParams {
  automation_ids?: string[];
}

export interface AutomationStepUpdateSubscriptionCustomField {
  id: string;
  value: string;
}

export interface AutomationStepUpdateSubscription {
  status?: typeof SubscriptionStatuses;
  custom_fields?: AutomationStepUpdateSubscriptionCustomField[];
  publication_subscriber_tags?: PublicationSubscriberTagReference[];
}

export interface AutomationStepWaitForParams {
  seconds: number;
}

export interface AutomationStepWaitUntilParams {
  weekdays: string[];
  time_of_day: string;
  time_zone: string;
}

export interface AutomationStep {
  id: string;
  automation_id: string;
  parent_id?: string;
  branch_id?: string;
  branch_arm?: 'yes' | 'no';
  step_type: AutomationStepStepType;
  state: AutomationStepState;
  delay_seconds?: number;
  relative_delay_seconds?: number;
  journey_step_counts: {
    fresh_in_progress: number;
    fresh_completed: number;
    stale_completed: number;
  };
  conditions: AutomationConditions;
  email_message_id?: string;
  enroll_in_automation_params: AutomationStepEnrollInAutomationParams;
  update_subscription?: AutomationStepUpdateSubscription;
  wait_for_params: AutomationStepWaitForParams;
  wait_until_params: AutomationStepWaitUntilParams;
  webhook_destination?: WebhookDestination;
  published_at: string;
  description?: string;
}
