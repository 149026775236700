import { useMutation } from 'react-query';

import api from '@/services/swarm';

const useLogExport = () => {
  return useMutation(({ currentPublicationId, id }: { currentPublicationId: string; id: string }) => {
    return api.post(`/publications/${currentPublicationId}/log_export`, { export_id: id });
  });
};

export default useLogExport;
