import { FC } from 'react';
import { AccordionTitleElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, useReactNodeView } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

import { getAccordionTitleElement } from './utils';

export const AccordionTitleView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <AccordionTitleElement
      element={getAccordionTitleElement(node)}
      attributes={{
        ...getDataAttributes(HTMLAttributes),
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      <NodeViewContent data-node-view-display-contents />
    </AccordionTitleElement>
  );
};
