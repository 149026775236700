import { NavGroup } from '../../../../../../interfaces/nav_group';
import { Publication } from '../../../../../../interfaces/publication';
import { TutorialType } from '../../../../../../interfaces/tutorial';
import { Section } from '../../../../Components';
import VideoTutorial from '../../../../Components/VideoTutorial';

import CreateNavGroup from './CreateNavGroup';
import NavGroups from './NavGroups';

interface Props {
  publication: Publication | undefined;
  navGroups: NavGroup[] | undefined;
}

const NavigationSection = ({ publication, navGroups }: Props) => {
  return (
    <Section
      title="Menu Items"
      description={
        <div>
          <span className="mr-2">
            Manage your website&apos;s navigation below by adding customizable Menu Items to your site&apos;s navbar.
          </span>
          <VideoTutorial type={TutorialType.CUSTOM_NAVBAR} />
        </div>
      }
      scrollToId="menu-items"
      isLoading={!publication || !navGroups}
      hasDivider
    >
      <div>
        {navGroups && <NavGroups navGroups={navGroups} />}
        <p className="mt-2 text-xs text-gray-500">
          *You may add up to 15 Menu Items, however for best results in the UI we recommend no more than 5 or 6. When
          navbars get too wide, we will proactively move your Menu Items to a side panel.
        </p>
      </div>
      <CreateNavGroup navGroups={navGroups} />
    </Section>
  );
};

export default NavigationSection;
