import { MouseEvent } from 'react';
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Button, ButtonProps } from '@/ui/Button';

import { Order } from '../../interfaces/general';

type SortProps = {
  className?: string;
  handleClick: (_event: MouseEvent<HTMLElement>) => void;
  orderDir: Order;
  variant?: ButtonProps['variant'];
};

const Sort = ({ orderDir, handleClick, className, variant = 'primary-inverse' }: SortProps) => {
  const classes = cx(
    className,
    'transition-opacity duration-100 hover:opacity-100 h-full max-w-sm text-sm font-semibold text-gray-600 align-middle'
  );

  const currentOrderDir =
    (orderDir !== Order.ASC && orderDir !== Order.DESC) || orderDir === Order.DESC ? Order.DESC : Order.ASC;

  return (
    <span className={classes}>
      <Button
        variant={variant}
        size="xxs"
        onClick={handleClick}
        className="px-1"
        Icon={
          // eslint-disable-next-line no-nested-ternary
          currentOrderDir === Order.DESC
            ? BarsArrowDownIcon
            : currentOrderDir === Order.ASC
            ? BarsArrowUpIcon
            : undefined
        }
      />
    </span>
  );
};

export default Sort;
