import { Typography, TypographyStack } from '@/components/Typography';

const ReviewBoostInvitesHeader = () => (
  <div className="pt-6 pb-4 px-6">
    <TypographyStack>
      <Typography token="font-semibold/text/lg">Review Boost Invites </Typography>
      <Typography token="font-normal/text/sm" colorWeight="500">
        Review pending Boost Invites from publications that want to partner with you.
      </Typography>
    </TypographyStack>
  </div>
);

export default ReviewBoostInvitesHeader;
