import { AutomationCondition, AutomationConditions } from '@/interfaces/automations/automation_condition';
import { LogicalOperators } from '@/interfaces/condition';

import PollChoiceSelect from '../../components/PollChoiceSelect';
import PollSelect from '../../components/PollSelect';

interface Props {
  mainConditions: AutomationConditions;
  onChange: (mainConditions: AutomationConditions) => void;
}

export const PollSubmissionTrigger = ({ mainConditions, onChange }: Props) => {
  const pollIdCondition = mainConditions.conditions?.find((condition) => condition.name === 'poll_id');
  const pollId = pollIdCondition?.value;
  const pollChoiceIdCondition = mainConditions.conditions?.find((condition) => condition.name === 'poll_choice_id');
  const pollChoiceId = pollChoiceIdCondition?.value;
  const shouldShowPollChoiceSelect = pollId && pollId !== 'any';

  const handlePollChange = (_name: string, selectedValue: string) => {
    const pollCondition = mainConditions.conditions?.find((c) => c.name === 'poll_id');

    onChange({
      logical_operator: LogicalOperators.AND,
      conditions: [
        { ...pollCondition, value: selectedValue } as AutomationCondition,
        { name: 'poll_choice_id', operator: 'equal', value: 'any' } as AutomationCondition,
      ],
    });
  };

  const handlePollChoiceChange = (_name: string, selectedValue: string) => {
    const pollCondition = mainConditions.conditions?.find((c) => c.name === 'poll_id');

    onChange({
      logical_operator: LogicalOperators.AND,
      conditions: [
        pollCondition as AutomationCondition,
        { name: 'poll_choice_id', operator: 'equal', value: selectedValue } as AutomationCondition,
      ],
    });
  };

  return (
    <div className="space-y-2">
      <PollSelect id={pollId || ''} onChange={handlePollChange} />
      {shouldShowPollChoiceSelect && (
        <PollChoiceSelect pollId={pollId} id={pollChoiceId || ''} onChange={handlePollChoiceChange} />
      )}
    </div>
  );
};
