import { Link } from 'react-router-dom';
import { ArrowLeft } from '@phosphor-icons/react';

import { WebsiteProvider } from '@/context/website-context';
import useSitePackages from '@/hooks/useSitePackages/useSitePackages';

import { Layout } from '../_components/Layout';
import { Main } from '../_components/Main';
import { Breadcrumbs } from '../_components/Main/Breadcrumbs';
import { Button } from '../_components/UI/Button';
import { Text } from '../_components/UI/Text';

import { ProjectCard } from './_components/ProjectCard';
import { TopBar } from './_components/TopBar';

const WebsiteProjectsPage = () => {
  const { data: packages, isLoading } = useSitePackages({ enabled: true });
  const projects = packages?.pages.flatMap((pkg) => pkg.site_packages) || [];
  const hasNoProjects = projects.length === 0 && !isLoading;

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Back to Main App',
        backPath: '/',
      }}
      sidenavChildren={
        <Link to="/website_builder_v2">
          <Button variant="secondary" LeftIcon={ArrowLeft} className="w-full">
            Editor
          </Button>
        </Link>
      }
    >
      <Main>
        <Breadcrumbs />
        <TopBar />
        {hasNoProjects ? (
          <div className="flex items-center justify-center h-full py-20">
            <Text size="xl" weight="semibold" variant="secondary" as="h4">
              No projects found
            </Text>
          </div>
        ) : (
          <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
            {projects?.map((project) => {
              return <ProjectCard key={project.id} project={project} onDeleteSuccess={() => {}} />;
            })}
          </div>
        )}
      </Main>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <WebsiteProjectsPage />
  </WebsiteProvider>
);
