import React from 'react';

import { Button } from '@/ui/Button';

import ListItem from './ListItem';

interface Props {
  children: React.ReactElement[] | React.ReactElement;
  hasNextPage?: boolean;
  fetchNextPage?: () => Promise<any>;
}

const List = ({ children, hasNextPage, fetchNextPage }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleFetchNextPage = () => {
    if (!fetchNextPage) return;

    const timeout = setTimeout(() => {
      setIsLoading(true);
    }, 500);

    fetchNextPage().then(() => {
      setIsLoading(false);

      clearTimeout(timeout);
    });
  };

  return (
    <ul className="divide-y divide-gray-100">
      {children}
      {hasNextPage && fetchNextPage && (
        <li>
          <div className="px-4 pt-2">
            <Button onClick={handleFetchNextPage} variant="primary-inverse" loading={isLoading}>
              {isLoading ? 'Loading...' : 'Load more'}
            </Button>
          </div>
        </li>
      )}
    </ul>
  );
};

List.Item = ListItem;

export default List;
