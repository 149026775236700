import { EnvelopeIcon, GlobeAltIcon } from '@heroicons/react/20/solid';

import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import useBoostOfferCard from '@/hooks/boosts/monetize/useBoostOfferCard';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';

interface Props {
  boostOffer: BoostOffer;
}

const BoostOfferInformationBlock = ({ boostOffer }: Props) => {
  const { boostsPlusEnabled } = useBoostOfferCard(boostOffer);

  const typesTooltipText = boostsPlusEnabled
    ? 'This offer can be shared via web and sent via email'
    : 'This offer can be shared via web only';

  return (
    <div className="space-y-1">
      <span className="inline-flex gap-x-1 items-center">
        <Typography token="font-medium/text/sm">Boost Types Available</Typography>
        <Tooltip text={typesTooltipText} id={`boost-offer-types-tooltip-${boostOffer.id}`} />
      </span>
      {boostsPlusEnabled && (
        <div className="flex space-x-1 items-center">
          <EnvelopeIcon className="w-4 h-4 text-surface-700" />
          <Typography token="font-normal/text/xs" colorWeight="700">
            Earn up to <span className="font-bold text-feedback-success-500"> {boostOffer.max_payout} per</span> send
          </Typography>
        </div>
      )}
      <div className="flex space-x-1 items-center">
        <GlobeAltIcon className="w-4 h-4 text-surface-700" />
        <Typography token="font-normal/text/xs" colorWeight="700">
          Earn <span className="font-bold text-feedback-success-500"> {boostOffer.payout_per_lead}</span> per subscriber
        </Typography>
      </div>
    </div>
  );
};

export default BoostOfferInformationBlock;
