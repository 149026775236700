import { useSearchParams } from 'react-router-dom';
import { ArrowsPointingOutIcon } from '@heroicons/react/20/solid';

import IconButton from '@/components/IconHelpers/IconButton';
import { PublicationLogo } from '@/components/PublicationLogo';
import { Typography, TypographyStack } from '@/components/Typography';
import useBoostOfferCard from '@/hooks/boosts/monetize/useBoostOfferCard';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';

interface BoostOfferCardParams {
  boostOffer: BoostOffer;
  onExpand?: () => void;
  isPreview?: boolean;
}

const PublicationInformationBlock = ({ boostOffer, onExpand, isPreview = false }: BoostOfferCardParams) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { boostedPublication } = useBoostOfferCard(boostOffer);

  const handleExpand = () => {
    searchParams.set('boost_offer_id', boostOffer.id);
    setSearchParams(searchParams);
    if (onExpand) {
      onExpand();
    }
  };

  return (
    <div className="space-y-4">
      <div className="space-y-4">
        <div className="w-full flex justify-between items-start">
          {boostedPublication && <PublicationLogo publication={boostedPublication} size="lg" />}
          {!isPreview && (
            <IconButton onClick={handleExpand}>
              <ArrowsPointingOutIcon />
            </IconButton>
          )}
        </div>

        <TypographyStack>
          <a
            target="_blank"
            href={boostOffer.boosted_publication.url}
            className="text-sm opacity-80 line-clamp-1"
            rel="noreferrer"
          >
            <Typography token="font-medium/text/lg">{boostedPublication?.name}</Typography>
          </a>
          {boostOffer?.boosted_publication?.description && (
            <Typography className="line-clamp-2" token="font-normal/text/sm" colorWeight="500">
              {boostOffer?.boosted_publication?.description}
            </Typography>
          )}
        </TypographyStack>
        <hr />
      </div>

      {boostOffer.description && (
        <div className="flex flex-col gap-y-1">
          <Typography token="font-medium/text/sm" colorWeight="900">
            Target Audience
          </Typography>
          <Typography token="font-normal/text/xs" colorWeight="500" className="line-clamp-2">
            {boostOffer.description}
          </Typography>
        </div>
      )}
      {boostOffer.boosted_publication.tags.map((tag) => (
        <button
          className="inline-block bg-action-tertiary-50 rounded px-3 py-1 text-xs font-medium text-action-tertiary-700 mr-2 mt-2"
          key={tag.id}
          type="button"
        >
          #{tag.name}
        </button>
      ))}
    </div>
  );
};

export default PublicationInformationBlock;
