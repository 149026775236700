import { useMemo } from 'react';

import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Dropdown } from '@/ui/Dropdown';
import { optionDataToOptions } from '@/utils';

interface ChannelSelectProps {
  value: string;
  onChange: (name: string, value: string) => void;
}

const ChannelSelect = ({ value, onChange }: ChannelSelectProps) => {
  const publicationId = useCurrentPublicationId();
  const channelsData = useOptions(publicationId, 'subscription_channels', Infinity);
  const options = optionDataToOptions(channelsData);

  // Legacy support
  // This input used to be a text input, so we need to add any missing values as an option
  const hasValue = useMemo(() => options.find((option) => option.value === value), [options, value]);
  if (value && !hasValue) {
    options.push({ label: value, value });
  }

  return (
    <Dropdown name="value" placeholderText="Select Channel" value={value || ''} onSelect={onChange} options={options} />
  );
};

export default ChannelSelect;
