import { NodeViewWrapper } from '@tiptap/react';

import pluralize from '../../../../../../utils/pluralize';

import { Styled } from './Instagram.styled';

interface Props {
  url: string;
  service: 'instagram';
  data: any;
  anchorEnabled: boolean;
  anchorId: string;
}

export const InstagramReelEmbed = ({ url, service, data: attributes, anchorEnabled = false, anchorId }: Props) => {
  return (
    <NodeViewWrapper
      data-url={url}
      data-service={service}
      data-drag-handle
      {...(anchorEnabled ? { 'data-anchor': '', id: anchorId } : {})}
    >
      <Styled.InstagramMedia>
        <div>
          <a href={url} className="p-0 text-center w-full no-underline" target="_blank" rel="noreferrer">
            <div className="flex items-center p-3 justify-between">
              <div>
                <Styled.Paragraph className="text-left text-gray-800">
                  <strong>{attributes.author_name}</strong>
                </Styled.Paragraph>
                {attributes.author_follower_count && (
                  <Styled.Paragraph className="m-0 text-left text-gray-500 text-sm">
                    {pluralize('follower', attributes.author_follower_count)}
                  </Styled.Paragraph>
                )}
              </div>
              <div>
                <Styled.ReelsLogoImage
                  className="block border-0"
                  src="https://media.beehiiv.com/static_assets/instagram_reel_embed/logo.png?fit=scale-down&format=auto"
                  alt=""
                />
              </div>
            </div>
            <div className="image-wrapper relative border-t border-b border-gray-300">
              <img
                className="border-0 h-auto max-w-full w-full block align-top"
                alt=""
                src={attributes.thumbnail_url}
              />
            </div>
            <div className="p-3">
              <Styled.Paragraph className="text-center ig-text">
                <strong>View more on Instagram</strong>
              </Styled.Paragraph>
            </div>
          </a>
        </div>
      </Styled.InstagramMedia>
    </NodeViewWrapper>
  );
};

export default InstagramReelEmbed;
