import cx from 'classnames';

import { getColor } from '@/components/DesignTokens';
import { Checkbox } from '@/components/Form';
import { Option } from '@/interfaces/general';

interface Props {
  filters: Option[];
  colorByFilter: any;
  onChange: (changedFilter: string, checked: boolean) => void;
}

const ChartFilters = ({ filters, colorByFilter, onChange }: Props) => {
  const handleChangeInCheckbox = (filterLabel: string) => (checked: boolean) => {
    onChange(filterLabel, checked);
  };
  return (
    <ul className="flex flex-col">
      {filters.map((filter) => (
        <li
          key={`filter-option-${filter.value}`}
          className={cx('py-4 pr-3 border-b last-of-type:border-b-0', getColor('surface/200', 'border'))}
        >
          <Checkbox
            name={`${filter.value}_filter_checkbox`}
            checked
            onChange={handleChangeInCheckbox(filter.label)}
            labelText={filter.label}
            helperText={filter.description}
            overrideColor={colorByFilter[filter.label]}
          />
        </li>
      ))}
    </ul>
  );
};

export default ChartFilters;
