import SlideOver from '@/components/SlideOver';
import { CustomDomainTypes } from '@/interfaces/custom_domain';
import { Button } from '@/ui/Button';

import EmailConfig from '../CustomDomainView/EmailConfig';
import RedirectConfig from '../CustomDomainView/RedirectConfig';
import WebConfig from '../CustomDomainView/WebConfig';

interface Props {
  type: CustomDomainTypes;
  isPanelOpen: boolean;
  setIsPanelOpen: (isOpen: boolean) => void;
}

const DomainSelectionPanel = ({ type, isPanelOpen, setIsPanelOpen }: Props) => {
  return (
    <>
      <SlideOver
        bodyId={type}
        isOpen={isPanelOpen}
        onClose={() => setIsPanelOpen(false)}
        headerText="Select Custom Domain"
      >
        {type === CustomDomainTypes.WEB && <WebConfig />}
        {type === CustomDomainTypes.REDIRECT && <RedirectConfig />}
        {type === CustomDomainTypes.EMAIL && <EmailConfig />}
      </SlideOver>
      <div className="space-y-2 my-4">
        <Button
          variant="primary-inverse"
          onClick={(e) => {
            e.stopPropagation();
            setIsPanelOpen(true);
          }}
          className="capitalize"
        >
          {`Select Custom ${type} Domain`}
        </Button>
      </div>
    </>
  );
};

export default DomainSelectionPanel;
