import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { RecommendationWidgetIndexResponse } from '@/interfaces/recommendation_widget';
import api from '@/services/swarm';

const useRecommendationWidget = () => {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<RecommendationWidgetIndexResponse>(
    ['recommendationWidget', publicationId],
    () =>
      api
        .get<RecommendationWidgetIndexResponse>(`/publications/${publicationId}/recommendation_widget`)
        .then((res) => res.data),
    {
      retry: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};

export default useRecommendationWidget;
