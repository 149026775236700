import React, { useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';

import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Panel, PanelFooter, PanelHeader } from '../../ui/Panel';

import { Styled } from './styled';

export interface CustomColorPanelProps {
  selectedColor?: string;
  onSelect?: Function;
  onBack?: Function;
}

export const CustomColorPanel = ({
  selectedColor = '#939393',
  onSelect = () => null,
  onBack = () => null,
}: CustomColorPanelProps): JSX.Element => {
  const [newColor, setNewColor] = useState(selectedColor ?? undefined);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSelect(newColor);
      onBack();

      return true;
    }

    return false;
  };

  return (
    <Panel>
      <PanelHeader>
        <Button $variant="quaternary" $size="small" $leftSlot={<Icon name="ChevronLeft" />} onClick={onBack} $fullWidth>
          Color picker
        </Button>
      </PanelHeader>
      <HexColorPicker color={newColor} onChange={setNewColor} />
      <Styled.InputWrapper>
        <HexColorInput color={newColor} onChange={setNewColor} onKeyDown={handleKeyDown} />
      </Styled.InputWrapper>

      {newColor !== (selectedColor ?? undefined) && (
        <PanelFooter>
          <Button
            $variant="quaternary"
            $size="small"
            $rightSlot={<Icon name="ChevronRight" />}
            onClick={() => {
              onSelect(newColor);
              onBack();
            }}
            $fullWidth
          >
            Set color
          </Button>
        </PanelFooter>
      )}
    </Panel>
  );
};
