import { TiptapState } from "@/interfaces/editor_versions";

const hasPollInTipTapState = (tiptapState: TiptapState | undefined) => {
  if (!tiptapState) {
    return false;
  }

  try {
    if (JSON.stringify(tiptapState).match('"type":"poll"') === null) {
      return false;
    }

    return true;
  } catch(error: any) {
    // do nothing
  }

  return false;
};

export default hasPollInTipTapState;