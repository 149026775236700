import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

// Components
import { LoadingSpinner } from '../../components/LoadingSpinner';
// Contexts
import { useAuth } from '../../context/auth-context';
// Services
import api from '../../services/swarm';

import AuthenticatedUser from './AuthenticatedUser';
import ExistingUser from './ExistingUser';
import NewUser from './NewUser';

export default function InviteAccept() {
  const { inviteId } = useParams();
  const [inviteToken] = useQueryParam<string | undefined>('invite_token');
  const [tokenId] = useQueryParam<string | undefined>('token_id');
  const [inviteableName, setInviteableName] = useState('');
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { isLoggedIn } = useAuth();

  const fetchInvite = useCallback(() => {
    if (inviteId) {
      setIsLoading(true);
      api
        .get(`/invites/${inviteId}`)
        .then((res) => {
          setEmail(res?.data?.email);
          setInviteableName(res?.data?.inviteable_name);
          setIsExistingUser(res?.data?.is_existing_user);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          setErrorMessage(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [inviteId]);

  useEffect(() => {
    fetchInvite();
  }, [fetchInvite]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col justify-start py-12 sm:px-6 lg:px-8 p-5">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="min-h-screen flex flex-col justify-start py-12 sm:px-6 lg:px-8 p-5">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-medium text-gray-900">{errorMessage}</h2>
        </div>
      </div>
    );
  }

  if (!inviteableName || !tokenId || !inviteToken || !inviteId || !email) {
    return (
      <div className="min-h-screen flex flex-col justify-start py-12 sm:px-6 lg:px-8 p-5">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-medium text-gray-900">Missing required information</h2>
        </div>
      </div>
    );
  }

  if (isLoggedIn) {
    return (
      <AuthenticatedUser
        inviteId={inviteId}
        inviteableName={inviteableName}
        tokenId={tokenId}
        inviteToken={inviteToken}
      />
    );
  }

  if (isExistingUser) {
    return (
      <ExistingUser
        email={email}
        inviteId={inviteId}
        inviteableName={inviteableName}
        tokenId={tokenId}
        inviteToken={inviteToken}
      />
    );
  }

  return (
    <NewUser
      email={email}
      inviteId={inviteId}
      inviteableName={inviteableName}
      tokenId={tokenId}
      inviteToken={inviteToken}
    />
  );
}
