import MobileNavbar from '../MobileNavbar';

import Navigation from './Navigation';

interface Props {
  largeNavOpen: boolean;
}

const Navbar = ({ largeNavOpen }: Props) => {
  return (
    <>
      <MobileNavbar className="sm:hidden" />
      <Navigation largeNavOpen={largeNavOpen} className="hidden sm:flex" />
    </>
  );
};

export default Navbar;
