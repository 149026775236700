import { createContext, useContext } from 'react';

import LoadingBox from '@/components/LoadingBox';
import { usePremiumSettings } from '@/hooks/usePublications';
import { PremiumSettings } from '@/interfaces/premium_settings';

const PremiumDataContext = createContext<PremiumSettings | undefined>(undefined);
PremiumDataContext.displayName = 'PremiumDataContext';

const PremiumDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading, isError } = usePremiumSettings();

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <PremiumDataContext.Provider value={data}>{children}</PremiumDataContext.Provider>
    </LoadingBox>
  );
};

const usePremiumDataContext = () => {
  const context = useContext(PremiumDataContext);

  if (context === undefined) {
    throw new Error('usePremiumDataContext must be used within a PremiumDataProvider');
  }

  return context;
};

export { PremiumDataContext, PremiumDataProvider, usePremiumDataContext };
