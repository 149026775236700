import { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { FieldSet, InputField } from '@/components/TiptapEditor/components/ui/Input';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';
import { Typography } from '@/components/Typography';
import { RSSCTAAlignment, RSSCTAStyle } from '@/interfaces/external_rss_feed';

import { Panel, PanelHeader, PanelSection } from '../../ui/Panel/styled';

import Dropdown from './Dropdown';

export const CTA = ({
  editor,
  onBack,
  compact = false,
  ctaText,
  setCtaText,
}: {
  editor: Editor;
  onBack: Function;
  ctaText: string;
  setCtaText: Function;
  compact?: boolean;
}) => {
  const { settings } = editor.getAttributes('rss');
  const [ctaStyle, setCtaStyle] = useState(settings?.ctaStyle || RSSCTAStyle.BUTTON);
  const [showCtaThumbnailOption, setShowCtaThumbnailOption] = useState(settings?.displayThumbnail);
  const [ctaAlignment, setCtaAlignment] = useState(settings?.ctaAlignment || RSSCTAAlignment.LEFT);

  const [showCtaTitleOption, setShowCtaTitleOption] = useState(settings?.displayTitle);

  useEffect(() => {
    if (settings) {
      setCtaAlignment(settings.ctaAlignment);
      setCtaText(settings.ctaText);
      setShowCtaThumbnailOption(settings.displayThumbnail);
      setShowCtaTitleOption(settings.displayTitle);
      if (!settings.displayTitle && settings.ctaStyle === RSSCTAStyle.TITLE) {
        editor
          .chain()
          .updateAttributes('rss', { settings: { ...settings, ctaStyle: RSSCTAStyle.BUTTON } })
          .run();
        setCtaStyle(RSSCTAStyle.BUTTON);
      }
      if (!settings.displayThumbnail && settings.ctaStyle === RSSCTAStyle.THUMBNAIL) {
        editor
          .chain()
          .updateAttributes('rss', { settings: { ...settings, ctaStyle: RSSCTAStyle.BUTTON } })
          .run();
        setCtaStyle(RSSCTAStyle.BUTTON);
      }
    }
  }, [settings, showCtaTitleOption, showCtaThumbnailOption, editor, setCtaText]);

  const onChangeCtaText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedValue = e.target.value;
    if (updatedValue === '') {
      updatedValue = 'Read More';
    }
    setCtaText(updatedValue);
  }, []);

  const onChangeToggleLinkToArticle = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayCta: !currentSettings.displayCta };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onUpdateCtaText = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, ctaText };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor, ctaText]);

  return (
    <Panel>
      {!compact && (
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            CTA
          </Button>
        </PanelHeader>
      )}
      <PanelSection>
        <FieldSet>
          <ToggleWrapper
            icon={<Icon name="Button" />}
            switchEl={
              <ToggleSwitch
                isChecked={editor.getAttributes('rss').settings.displayCta}
                onChange={onChangeToggleLinkToArticle}
              />
            }
          >
            Show CTA
          </ToggleWrapper>
          {editor.getAttributes('rss').settings.displayCta && (
            <div className="flex flex-col gap-2">
              <Typography token="font-medium/text/xs" color="white" colorWeight="900">
                CTA Style
              </Typography>
              <Dropdown
                actions={[
                  { label: RSSCTAStyle.BUTTON, value: RSSCTAStyle.BUTTON },
                  { label: RSSCTAStyle.LINK, value: RSSCTAStyle.LINK },
                  { label: RSSCTAStyle.TITLE, value: RSSCTAStyle.TITLE, disabled: !showCtaTitleOption },
                  {
                    label: RSSCTAStyle.THUMBNAIL,
                    value: RSSCTAStyle.THUMBNAIL,
                    disabled: !showCtaThumbnailOption,
                  },
                  {
                    label: RSSCTAStyle.TITLE_AND_THUMBNAIL,
                    value: RSSCTAStyle.TITLE_AND_THUMBNAIL,
                    disabled: !showCtaThumbnailOption || !showCtaTitleOption,
                  },
                ]}
                value={ctaStyle}
                onSelect={(name: string, value: RSSCTAStyle) => {
                  const currentSettings = editor.getAttributes('rss').settings;
                  const newSettings = { ...currentSettings, ctaStyle: value };
                  editor.chain().updateAttributes('rss', { settings: newSettings }).run();
                  setCtaStyle(value);
                }}
              />
            </div>
          )}
          {editor.getAttributes('rss').settings.displayCta &&
            (ctaStyle === RSSCTAStyle.BUTTON || ctaStyle === RSSCTAStyle.LINK) && (
              <div className="flex flex-col gap-2">
                <Typography token="font-medium/text/xs" color="white" colorWeight="900">
                  CTA Text
                </Typography>
                <InputField
                  value={ctaText}
                  defaultValue="View Article"
                  min={1}
                  onChange={onChangeCtaText}
                  className="w-full"
                  onBlur={onUpdateCtaText}
                />
              </div>
            )}
          {editor.getAttributes('rss').settings.displayCta &&
            (ctaStyle === RSSCTAStyle.BUTTON || ctaStyle === RSSCTAStyle.LINK) && (
              <div className="flex flex-col gap-2">
                <Typography token="font-medium/text/xs" color="white" colorWeight="900">
                  CTA Alignment
                </Typography>
                <Dropdown
                  actions={[
                    { label: RSSCTAAlignment.LEFT, value: RSSCTAAlignment.LEFT },
                    { label: RSSCTAAlignment.CENTER, value: RSSCTAAlignment.CENTER },
                    { label: RSSCTAAlignment.RIGHT, value: RSSCTAAlignment.RIGHT },
                  ]}
                  value={ctaAlignment}
                  onSelect={(name: string, value: RSSCTAAlignment) => {
                    const currentSettings = editor.getAttributes('rss').settings;
                    const newSettings = { ...currentSettings, ctaAlignment: value };
                    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
                    setCtaAlignment(value);
                  }}
                />
              </div>
            )}
        </FieldSet>
      </PanelSection>
    </Panel>
  );
};
