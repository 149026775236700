import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  navLinkId: string;
  onSuccess: () => void;
}

const useDeleteNavLink = ({ navLinkId, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation(() => api.delete(`/nav_links/${navLinkId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.message || 'Something went wrong');
    },
    onSuccess: () => {
      toast.success('Deleted!');
      onSuccess();
    },
  });
};

export default useDeleteNavLink;
