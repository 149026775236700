import { Option } from '@/interfaces/general';
import { BadgeProps } from '@/ui/Badge';

export enum FiltersPopopoverOperator {
  EQUAL = 'equal',
  NOT_EQUAL = 'not_equal',
  GREATER_THAN = 'greater_than',
  LESS_THAN = 'less_than',
}

export enum FiltersPopoverSectionType {
  HEADER = 'header',
  NUMBER = 'number',
  SELECT = 'select',
}

export interface FiltersPopoverSectionHeader {
  type: FiltersPopoverSectionType.HEADER;
  name: string;
  title: string;
}

interface FiltersPopoverSectionBase {
  name: string;
  title?: string;
  labelText?: string;
  placeholderText?: string;
  badgeType?: BadgeProps['type'];
  operatorValue?: FiltersPopopoverOperator;
  operators?: FiltersPopopoverOperator[];
  onOperatorChange?: (value: FiltersPopopoverOperator) => void;
}

export interface FiltersPopoverNumberInputSection extends FiltersPopoverSectionBase {
  type: FiltersPopoverSectionType.NUMBER;
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}

export interface FiltersPopoverSelectSection extends FiltersPopoverSectionBase {
  type: FiltersPopoverSectionType.SELECT;
  options: Option[];
  value: string[];
  onSelect: (values: string[]) => void;
}

export type FiltersPopoverSection =
  | FiltersPopoverSectionHeader
  | FiltersPopoverNumberInputSection
  | FiltersPopoverSelectSection;

export interface FiltersPopover {
  sections: FiltersPopoverSection[];
  savedFilters: [];
  onClearAll: () => void;
  onSaveFilters?: () => void;
  popoverWidthClassName?: string;
}
