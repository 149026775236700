import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { SettingsPageLayout } from '../../Components';

const Integrations = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const sideTabs = [
    {
      name: 'api',
      label: 'API',
      selected: location.pathname === '/settings/integrations/api',
      onSelect: () => navigate('/settings/integrations/api'),
    },
    ...(String(window.env.REACT_APP_WEBHOOKS_DEFAULT_ENABLED) === 'true'
      ? [
          {
            name: 'webhooks',
            label: 'Webhooks',
            selected: location.pathname.startsWith('/settings/integrations/webhooks'),
            onSelect: () => navigate('/settings/integrations/webhooks'),
          },
        ]
      : []),
    {
      name: 'imports',
      label: 'Imports',
      selected: location.pathname === '/settings/integrations/imports',
      onSelect: () => navigate('/settings/integrations/imports'),
    },
  ];

  return (
    <SettingsPageLayout title="Integrations" sideTabs={sideTabs}>
      <Outlet />
    </SettingsPageLayout>
  );
};

export default Integrations;
