export interface RoleAssignment {
  id: string;
  name: string;
  type: string;
  assignment_id: string;
  assignable_id: string;
  assignable_type: string;
}

export type AssignableType = 'organization' | 'publication';

export type Levels =
  | 'security_admin'
  | 'system_admin'
  | 'ad_network_admin'
  | 'system_accountant'
  | 'admin'
  | 'member'
  | 'contributor';

export type AssignableLevels = 'admin' | 'member' | 'contributor';

export const assignableLevelLabels: Record<AssignableLevels, string> = {
  admin: 'Admin',
  member: 'Member',
  contributor: 'Contributor',
};

export const assignableLevelDescriptions: Record<AssignableLevels, string> = {
  admin:
    'Full access plus billing, exporting data, integrations, payments, adding teammates, creating publications & domains',
  member: 'Access to all functionality except for the admin permissions above',
  contributor: 'Access limited to writing/designing draft posts, & automations.',
};

export type AssignedRole = Levels | `${AssignableType}_${AssignableLevels}`;
