// This function is used to organize the list of testimonials based on the order of the testimonialsOrder array
const orderTestimonials = (listOfIds: string[], listOfTestimonials: any[]) => {
  const list1 = listOfTestimonials.filter((obj) => listOfIds.includes(obj.testimonial_id));
  const list2 = listOfTestimonials.filter((obj) => !listOfIds.includes(obj.testimonial_id));

  const sortedList1 = list1.sort((a, b) => listOfIds.indexOf(a.testimonial_id) - listOfIds.indexOf(b.testimonial_id));

  return [...sortedList1, ...list2];
};

export default orderTestimonials;
