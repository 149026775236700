import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { WebhookEndpointUpdatePayload } from '@/interfaces/webhooks/endpoint';
import api from '@/services/swarm';

const useUpdateSvixEndpoint = (publicationId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    (endpoint: WebhookEndpointUpdatePayload) =>
      api.patch(`/webhooks/svix_endpoints/${endpoint.id}`, {
        publication_id: publicationId,
        endpoint,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.message || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['publications', publicationId, 'webhooks', 'endpoints']);
        onSuccess?.();
      },
    }
  );
};

export default useUpdateSvixEndpoint;
