import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { Button } from '@/ui/Button';
import { Textarea } from '@/ui/Textarea';

import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { Recommendation } from '../../../../interfaces/recommendation';
import api from '../../../../services/swarm';

interface Props {
  recommendation: Recommendation;
  onCancel: () => void;
  onSave: () => void;
}

const Form = ({ recommendation, onCancel, onSave }: Props) => {
  const [reason, setReason] = useState(recommendation.reason);
  const [currentPublicationId] = useCurrentPublicationState();

  const { isLoading, mutateAsync: handleSave } = useMutation(
    () =>
      api.patch(`/recommendations/${recommendation.id}`, {
        recommendation: { reason },
        publication_id: currentPublicationId,
      }),
    {
      onSuccess: () => {
        toast.success('Recommendation saved');
        onSave();
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );

  return (
    <div>
      <div className="bg-white rounded-t-lg py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Editing recommendation for {recommendation.recommended_publication.name}
          </h3>
        </div>
        <div className="space-y-6">
          <Textarea
            placeholderText={`Describe why you recommend ${recommendation.recommended_publication.name}...`}
            labelText="Reason"
            helperText="This will be visible to your audience on your website."
            name="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            rows={5}
          />
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 rounded-b-lg text-right sm:px-6 flex align-items-center justify-end">
        <Button type="button" className="mx-2" variant="primary-inverse" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" type="button" onClick={() => handleSave()} loading={isLoading}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Form;
