import { LinkIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon, ArrowUturnRightIcon, EnvelopeIcon, GlobeAltIcon, SunIcon } from '@heroicons/react/24/solid';

import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';
import { Badge } from '@/ui/Badge';

import { brandedLinkVerificationBadgeText } from '../utils';

interface Props {
  domain: CustomDomain;
  type: CustomDomainTypes;
}

export const EmailBadge = () => (
  <Badge size="sm" type="success" Icon={EnvelopeIcon}>
    Live
  </Badge>
);

export const WebBadge = () => (
  <Badge size="sm" type="success" Icon={GlobeAltIcon}>
    Live
  </Badge>
);

export const RedirectBadge = () => (
  <Badge size="sm" type="info_blue" Icon={ArrowUturnRightIcon}>
    Redirect
  </Badge>
);

export const SmartWarmingBadge = () => (
  <Badge size="sm" type="warning" Icon={SunIcon}>
    Smart Warming In Progress
  </Badge>
);

export const VerifyingBadge = ({ entri = false }: { entri: boolean }) => (
  <Badge size="sm" type="warning" Icon={ArrowPathIcon}>
    {entri ? 'Entri Verification in Progress' : 'Verifying'}
  </Badge>
);

export const BrandedLinkBadge = ({ text }: { text: string }) => (
  <Badge size="sm" type="warning" Icon={LinkIcon}>
    {text}
  </Badge>
);

const StatusBadge = ({ domain, type }: Props) => {
  const {
    branded_link_cloudflare_list_verified: brandedLinkCloudflareListVerified,
    email_verification_pending: emailVerificationPending,
    entri_configuration: entriConfiguration,
    web_redirect_verification_pending: webRedirectVerificationPending,
    web_verification_pending: webVerificationPending,
  } = domain;
  const {
    email_pending: entriEmailPending,
    web_pending: entriWebPending,
  } = entriConfiguration || {};

  const isVerifying =
    (type === CustomDomainTypes.WEB && webVerificationPending) ||
    (type === CustomDomainTypes.REDIRECT && webRedirectVerificationPending) ||
    (type === CustomDomainTypes.EMAIL && emailVerificationPending);

  const entri = entriConfiguration ? (
    type === CustomDomainTypes.WEB && entriWebPending ||
    type === CustomDomainTypes.REDIRECT && webRedirectVerificationPending ||
    type === CustomDomainTypes.EMAIL && entriEmailPending ||
    false
  ) : false;

  if (isVerifying) {
    return <VerifyingBadge entri={entri} />;
  }

  if (type === CustomDomainTypes.EMAIL) {
    return <EmailBadge />;
  }

  if (type === CustomDomainTypes.WEB) {
    return <WebBadge />;
  }

  if (type === CustomDomainTypes.REDIRECT) {
    return <RedirectBadge />;
  }

  if (type === CustomDomainTypes.BRANDED_LINK && !emailVerificationPending && !brandedLinkCloudflareListVerified) {
    const text = brandedLinkVerificationBadgeText(domain);
    if (!text) return null;

    return <BrandedLinkBadge text={text} />;
  }

  return null;
};

export default StatusBadge;
