import AppliedFilter from '../AppliedFilter';
import { AttributeCondition, StringOperatorsList } from '../types';

const OpportunityGroupFilerApplied = ({
  condition,
  onRemove,
}: {
  condition: AttributeCondition;
  onRemove: () => void;
}) => {
  const operator = StringOperatorsList.find((o) => o.value === condition.filters.operator);
  if (!operator) return null;

  const operatorLabel = operator.shorthand;

  return (
    <AppliedFilter
      name="Opportunity Group Id"
      label={operatorLabel}
      value={condition.filters.value}
      onRemove={onRemove}
    />
  );
};

export default OpportunityGroupFilerApplied;
