import { memo, useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import { IconName } from '../../lib/types';
import { ToolbarButton } from '../buttons/ToolbarButton';
import { Toolbar } from '../ui/Toolbar';

const icons: Record<'left' | 'center' | 'right' | 'justify', IconName> = {
  center: 'TextAlignCenter',
  justify: 'TextAlignJustify',
  left: 'TextAlignLeft',
  right: 'TextAlignRight',
};

export const TextAlignmentsToolbar = memo(
  ({
    alignment,
    canAlign,
    onAlignCenter,
    onAlignJustify,
    onAlignLeft,
    onAlignRight,
  }: {
    alignment: 'left' | 'center' | 'right' | 'justify' | null;
    canAlign?: boolean;
    onAlignCenter: () => void;
    onAlignJustify: () => void;
    onAlignLeft: () => void;
    onAlignRight: () => void;
  }): JSX.Element => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const hasAlignment = !!alignment && alignment !== 'left';

    return (
      <Tippy
        render={(attrs) => (
          <Toolbar
            tabIndex={-1}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attrs}
          >
            <ToolbarButton
              can
              icon="TextAlignLeft"
              is={alignment === 'left'}
              muted={alignment === 'left'}
              onClick={onAlignLeft}
              title="Align left"
            />
            <ToolbarButton
              can
              icon="TextAlignCenter"
              is={alignment === 'center'}
              onClick={onAlignCenter}
              title="Align center"
            />
            <ToolbarButton
              can
              icon="TextAlignRight"
              is={alignment === 'right'}
              onClick={onAlignRight}
              title="Align right"
            />
            <ToolbarButton
              can
              icon="TextAlignJustify"
              is={alignment === 'justify'}
              onClick={onAlignJustify}
              title="Align justify"
            />
          </Toolbar>
        )}
        offset={[0, 8]}
        trigger="click"
        interactive
        onShow={() => {
          setIsPanelOpen(true);
        }}
        onHidden={() => {
          setIsPanelOpen(false);
        }}
      >
        <div>
          <ToolbarButton
            title={isPanelOpen ? '' : 'Text alignment'}
            can={canAlign || false}
            is={hasAlignment}
            muted={isPanelOpen && !hasAlignment}
            icon={hasAlignment && alignment ? icons[alignment] : 'TextAlignLeft'}
            iconRight="ChevronDown"
          />
        </div>
      </Tippy>
    );
  }
);

TextAlignmentsToolbar.displayName = 'TextAlignmentsToolbar';

export default TextAlignmentsToolbar;
