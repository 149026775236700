import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import NewEmailDomainForm from './NewEmailDomainForm';

const NewPublicationDomain = () => {
  const publicationId = useCurrentPublicationId();
  return <NewEmailDomainForm key={publicationId} publicationId={publicationId} />;
};

export default NewPublicationDomain;
