type ColorValues = {
  [key: number]: string;
};

type Colors = {
  white: ColorValues;
  black: ColorValues;
  pink: ColorValues;
  blue: ColorValues;
  gradient: ColorValues;
  comment: string;
};

export const colors: Colors = {
  white: {
    1: '#FFFFFF',
    2: 'rgba(255, 255, 255, 0.8)',
    3: 'rgba(255, 255, 255, 0.6)',
    4: 'rgba(255, 255, 255, 0.4)',
    5: 'rgba(255, 255, 255, 0.3)',
    6: 'rgba(255, 255, 255, 0.2)',
    7: 'rgba(255, 255, 255, 0.1)',
    8: 'rgba(255, 255, 255, 0.05)',
  },
  black: {
    1: '#272A2F',
    2: 'rgba(39, 42, 47, 0.8)',
    3: 'rgba(39, 42, 47, 0.6)',
    4: 'rgba(39, 42, 47, 0.4)',
    5: 'rgba(39, 42, 47, 0.4)',
    6: 'rgba(39, 42, 47, 0.2)',
    7: 'rgba(39, 42, 47, 0.1)',
    8: 'rgba(39, 42, 47, 0.06)',
    9: 'rgba(39, 42, 47, 0.04)',
  },
  pink: {
    1: '#8A0F4C',
    2: '#A2125A',
    3: '#B81466',
    4: '#E51A7F',
    5: '#EC4899',
    6: '#FF67B7',
    7: '#F5A3CC',
    8: '#FAD0E5',
    9: '#FCE7F1',
    10: '#FDF2F7',
  },
  blue: {
    1: '#073A92',
    2: '#0944AA',
    3: '#0A4EC2',
    4: '#0C61F3',
    5: '#3C80F5',
    6: '#6EA0F7',
    7: '#80AEFF',
    8: '#CEDFFD',
    9: '#E7EFFE',
    10: '#F0F6FE',
  },
  gradient: {
    1: '#EB4799',
    2: '#E5499C',
    3: '#E04B9E',
    4: '#DB4CA1',
    5: '#D64EA4',
    6: '#D150A7',
    7: '#CC51A9',
    8: '#C753AC',
    9: '#C255AF',
    10: '#BD57B1',
    11: '#B858B4',
    12: '#B35AB6',
    13: '#AE5BB9',
    14: '#A95DBC',
    15: '#A35FBF',
    16: '#9E61C1',
    17: '#9962C4',
    18: '#9464C7',
    19: '#8F65C9',
    20: '#8A67CC',
    21: '#8569CF',
    22: '#806AD1',
    23: '#7B6CD4',
    24: '#766ED7',
    25: '#716FD9',
    26: '#6C71DC',
    27: '#6773DF',
    28: '#6274E1',
    29: '#5D76E4',
    30: '#5878E6',
    31: '#5479E9',
    32: '#4E7BEC',
    33: '#497DEE',
    34: '#447EF1',
    35: '#3D81F5',
  },
  comment: '#FBCF51',
};

export default colors;
