import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

const useConfirmSubscriptionChange = (token: string | null) => {
  return useMutation(() => api.delete(`/mailer_actions/subscription_change_confirmations/${token}`, {}), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: (resp) => {
      const url = resp?.data?.redirect_url;
      window.location.href = url;
    },
  });
};

export default useConfirmSubscriptionChange;
