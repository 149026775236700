import { XMarkIcon } from '@heroicons/react/24/solid';

import Banner from '@/components/Banner';
import { Checkbox } from '@/components/Form';
import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { BillingDetails } from '@/interfaces/billing';
import { usePlanFeatures } from '@/routes/settings/billing/_hooks';

type Plan = {
  name: string;
  max_subscriptions: number;
  max_publications: number;
};

type Props = {
  billingDetails: BillingDetails;
  currentPlan: Plan | undefined;
  confirmationStep?: boolean;
  acknowledgeChanges?: boolean;
  onToggleAcknowledgeChanges?: () => void;
};

const Body = (props: Props) => {
  const { billingDetails, currentPlan, confirmationStep, acknowledgeChanges, onToggleAcknowledgeChanges } = props;
  const { features } = usePlanFeatures({ planName: currentPlan?.name });

  const currentPlanFeatures = Object.entries(features)
    .map(([, planFeatures]) => planFeatures)
    .flat();

  return (
    <div>
      {!confirmationStep && (
        <div className="border border-surface-200 rounded">
          <div className="px-6 pt-6 pb-4 bg-surface-50 border-b border-surface-200">
            <TypographyStack>
              <TypographyRow className="flex flex-row justify-start">
                <Typography size="base" weight="semibold" className="w-1/3">
                  You&apos;ll lose access to:
                </Typography>
              </TypographyRow>
            </TypographyStack>
          </div>
          <div className="px-6 pt-4 pb-6 space-y-4">
            <div className="flex flex-col gap-5">
              {Object.entries(features).map(([heading, planFeatures]) => (
                <div key={heading}>
                  <Typography token="font-semibold/text/base">{heading}</Typography>
                  <div className="flex flex-col gap-2 mt-2">
                    {planFeatures.map((feature) => (
                      <div key={feature} className="flex flex-row items-center">
                        <XMarkIcon className="w-4 h-4 mx-2 text-feedback-danger-600" />
                        <Typography token="font-normal/text/sm" colorWeight="700">
                          {feature}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {confirmationStep && (
        <div className="flex flex-col gap-10">
          <div className="border border-surface-200 rounded">
            <div className="px-6 pt-6 pb-4 bg-surface-50 border-b border-surface-200">
              <TypographyStack>
                <TypographyRow className="flex flex-row justify-start">
                  <Typography size="base" weight="semibold" className="w-1/3">
                    Confirm your changes
                  </Typography>
                </TypographyRow>
              </TypographyStack>
            </div>
            <div className="px-6 pt-4 pb-6 space-y-4 flex flex-col gap-6">
              <div className="flex flex-row">
                {onToggleAcknowledgeChanges && (
                  <Checkbox
                    name="acknowledgeChanges"
                    checked={acknowledgeChanges || false}
                    onChange={onToggleAcknowledgeChanges}
                    labelText={`I understand that my plan will change on ${billingDetails.next_billing_date}. From then on:`}
                  />
                )}
              </div>
              <div>
                <TypographyStack>
                  <Typography token="font-semibold/text/base">Usage limits</Typography>
                  <Typography token="font-normal/text/sm">
                    My account will be limited to <Typography token="font-semibold/text/sm">2,500</Typography>{' '}
                    subscribers, <Typography token="font-semibold/text/sm">3</Typography> publications and{' '}
                    <Typography token="font-semibold/text/sm">1</Typography> team member.
                  </Typography>
                </TypographyStack>
              </div>
              <div>
                <Typography token="font-semibold/text/base">Tools I will not have access to:</Typography>
                <div className="grid grid-cols-2 gap-2 pt-4">
                  {currentPlanFeatures.map((feature) => (
                    <div key={feature} className="flex flex-row gap-2 items-center">
                      <span>&bull;</span>
                      <Typography token="font-normal/text/sm">{feature}</Typography>
                    </div>
                  ))}
                </div>
              </div>
              <div className="space-y-4">
                <TypographyStack>
                  <Typography token="font-semibold/text/base">Posts</Typography>
                  <Typography token="font-normal/text/sm">
                    My posts and post archive will remain on my site. I&apos;ll also retain access to post and
                    subscriber-level data.
                  </Typography>
                </TypographyStack>
              </div>
            </div>
          </div>
          <Banner
            title={`You can cancel the downgrade anytime before ${billingDetails.next_billing_date}.`}
            isScreenWide={false}
          />
        </div>
      )}
    </div>
  );
};

export default Body;
