import { LayoutPageData } from '@/routes/website_builder';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';

import AddCategoriesGroup from './AddCategoriesGroup';
import AddMenuGroup from './AddMenuGroup';
import SortableNavGroups from './SortableNavGroups';

const NavigationMenuGroup = () => {
  const { pageData } = usePageContext<LayoutPageData>();
  const { navGroups } = pageData;
  const hasCategoryGroup = Boolean(navGroups?.find((group) => group.is_category_group));

  return (
    <PropertiesGrouping
      label="Navigation Menu Groups"
      description="You can customize your menu groups, menu group pages, and connect them to internal or external pages."
    >
      <div className="space-y-5">
        <AddMenuGroup />
        <div>
          {navGroups && <SortableNavGroups navGroups={navGroups} />}
          {!hasCategoryGroup && <AddCategoriesGroup />}
        </div>
      </div>
    </PropertiesGrouping>
  );
};

export default NavigationMenuGroup;
