import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { arrayMove } from '@dnd-kit/sortable';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import { SortableList } from '@/components/SortableList';
import { Button } from '@/ui/Button';

import { SortableFeatureItem } from '../HeroSection/types';

import FeaturesItem from './FeaturesItem';

interface Props {
  features: string[];
  onUpdate(newFeaturesList: string[]): void;
}

// This is a somewhat arbitrary number. We want to allow for flexibility in the number of features but want to have some sane limit on it.
const MAX_FEATURES = 20;

const Features = ({ features, onUpdate }: Props) => {
  const featuresCount = features.length;
  const canAddMoreFeatures = featuresCount < MAX_FEATURES;

  const [featureToEdit, setFeatureToEdit] = useState<SortableFeatureItem>();
  const [possibleEdits, setPossibleEdits] = useState<SortableFeatureItem>();
  const [featureToDelete, setFeatureToDelete] = useState<SortableFeatureItem>();
  const [newFeature, setNewFeature] = useState<SortableFeatureItem>();

  // Grab the Features list from the page and add an id to help with sorting.
  // The Sortable Library requires an id for drag and drop.
  const [sortableFeaturesList, setSortableFeaturesList] = useState<SortableFeatureItem[]>([]);

  useEffect(() => {
    if (features) {
      setSortableFeaturesList(
        features.map((item: string, index: number) => ({
          text: item,
          id: index.toString(),
        }))
      );
    }
  }, []);

  const handleDelete = () => {
    const newList = sortableFeaturesList.filter((item: SortableFeatureItem) => item.id !== featureToDelete?.id) || [];
    setSortableFeaturesList(newList);
    onUpdate(newList.map((item) => item.text));
    setFeatureToDelete(undefined);
  };

  const handleAddFeature = () => {
    // Prevent empty strings from being added
    if (!newFeature?.text.trim()) {
      return;
    }

    const newList = [...sortableFeaturesList, newFeature];
    setSortableFeaturesList(newList);
    onUpdate(newList.map((item) => item.text));
    setNewFeature({ id: newFeature.id, text: '' });
  };

  const handleEditFeature = () => {
    if (!possibleEdits?.text?.trim()) {
      toast.error('Please enter a valid feature');
      return;
    }

    if (possibleEdits.text) {
      const newSortableList = sortableFeaturesList.map((item: SortableFeatureItem) => {
        if (item.id === featureToEdit?.id) {
          return {
            ...item,
            text: possibleEdits.text,
          };
        }
        return item;
      });
      setSortableFeaturesList(newSortableList);
      onUpdate(newSortableList.map((item) => item.text));
      setFeatureToEdit(undefined);
      setPossibleEdits(undefined);
    }
  };

  const onDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = sortableFeaturesList.findIndex((item: SortableFeatureItem) => item.id === active.id);
      const newIndex = sortableFeaturesList.findIndex((item: SortableFeatureItem) => item.id === over.id);
      const newList = arrayMove(sortableFeaturesList, oldIndex, newIndex);
      const filteredList = newList.filter((item: any) => Boolean(item.text.trim())); // Remove empty strings
      setSortableFeaturesList(filteredList);
      onUpdate(filteredList.map((item) => item.text));
    }
  };

  const renderAddFeatureForm = () => {
    if (canAddMoreFeatures) {
      return (
        <div className="pt-4 space-y-2">
          <Input
            name="new_feature"
            variant="primary"
            value={newFeature?.text}
            onChange={(e) => {
              const newId = sortableFeaturesList.length.toString();
              setNewFeature({
                id: newId,
                text: e.target.value,
              });
            }}
            placeholder="Add a new benefit"
          />
          <Button variant="primary-inverse" size="xs" onClick={handleAddFeature}>
            Add
          </Button>
        </div>
      );
    }

    return <span className="mt-2 text-xs text-red-500">Please remove at least 1 feature before adding another.</span>;
  };

  if (!sortableFeaturesList || sortableFeaturesList.length === 0) {
    return renderAddFeatureForm();
  }

  return (
    <>
      <ActionModal
        isOpen={Boolean(featureToDelete)}
        onClose={() => setFeatureToDelete(undefined)}
        onProceed={handleDelete}
        resourceId=""
        isWorking={false}
        headerText="Delete Feature"
        actionText="Delete"
        buttonType="danger"
      >
        <div className="space-y-2 text-sm">
          Are you sure you want to delete this feature? You can always add it back.
        </div>
      </ActionModal>
      <ActionModal
        isOpen={Boolean(featureToEdit)}
        onClose={() => setFeatureToEdit(undefined)}
        onProceed={handleEditFeature}
        resourceId=""
        isWorking={false}
        headerText="Edit Feature"
        actionText="Update"
      >
        <div className="space-y-2 text-sm">
          <Input
            name="new_feature"
            variant="primary"
            value={possibleEdits?.text}
            onChange={(e) => {
              if (!possibleEdits) {
                return;
              }

              setPossibleEdits({
                ...possibleEdits,
                text: e.target.value,
              });
            }}
            placeholder="Edit Feature"
          />
        </div>
      </ActionModal>

      <div className="space-y-2">
        {sortableFeaturesList.length > 0 ? (
          <SortableList listItems={sortableFeaturesList} onDragEndEvent={onDragEnd}>
            {(sortableList) => {
              return sortableList.map((item: SortableFeatureItem) => {
                return (
                  <FeaturesItem
                    key={item.id}
                    listItem={item}
                    onRemoveItem={() => setFeatureToDelete(item)}
                    setFeatureToEdit={() => {
                      setFeatureToEdit(item);
                      setPossibleEdits(item);
                    }}
                  />
                );
              });
            }}
          </SortableList>
        ) : null}
      </div>

      {renderAddFeatureForm()}
    </>
  );
};

export default Features;
