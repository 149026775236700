import { CheckIcon } from '@heroicons/react/20/solid';

import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { PlanPrice } from '@/interfaces/plan_price';

import { usePlanInfo } from '../../_hooks';

const PlanCard: React.FC<{ planPrice: PlanPrice }> = ({ planPrice }) => {
  const { description, features } = usePlanInfo({ planName: planPrice?.plan_name });

  return (
    <div className="border border-surface-200 rounded">
      <div className="px-6 pt-6 pb-4 bg-surface-50 border-b border-surface-200">
        <TypographyStack>
          <div className="flex justify-between">
            <TypographyRow>
              <Typography className="capitalize" token="font-semibold/text/base">
                {planPrice?.plan_name} Plan
              </Typography>
            </TypographyRow>
          </div>
          <Typography colorWeight="700" token="font-light/text/sm">
            {description}
          </Typography>
        </TypographyStack>
      </div>
      <div className="px-6 pt-4 pb-6 space-y-4">
        {features.map((feature) => (
          <TypographyRow gap="2">
            <CheckIcon className="w-5 h-5 text-surface-700" />
            <Typography colorWeight="700" token="font-normal/text/sm">
              {feature}
            </Typography>
          </TypographyRow>
        ))}
      </div>
    </div>
  );
};

export default PlanCard;
