import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

import API from '@/components/TiptapEditor/lib/api';
import { Input } from '@/components/UI/Input';
import { Asset } from '@/interfaces/asset';

import { Button } from '../../../ui/Button';

export type MetaDataModalContentProps = {
  assetId: string;
  publicationId: string;
  onClose: () => void;
  onChange: (asset: Asset) => void;
};

const useFormState = ({
  assetId,
  publicationId,
  onClose,
  onChange,
}: {
  assetId: string;
  publicationId: string;
  onClose: () => void;
  onChange: (asset: Asset) => void;
}) => {
  const [asset, setAsset] = useState<Asset | null>(null);
  const [assetFetched, setAssetFetched] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [alt, setAlt] = useState('');
  const [caption, setCaption] = useState('');
  const [captionUrl, setCaptionUrl] = useState('');

  useEffect(() => {
    setAssetFetched(false);

    API.getAsset({ assetId, publicationId }).then((newAsset) => {
      if (!newAsset.data) {
        return;
      }

      setAsset(newAsset.data);

      setTitle(newAsset.data.title || '');
      setAlt(newAsset.data.alt || '');
      setCaption(newAsset.data.source_display || '');
      setCaptionUrl(newAsset.data.source_link || '');
      setAssetFetched(true);
    });
  }, [assetId, publicationId]);

  const handleSave = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();

      setIsSaving(true);

      try {
        const response = await API.updateAsset(assetId, publicationId, {
          alt,
          sourceDisplay: caption,
          sourceLink: captionUrl,
          title,
        });
        onChange(response.data);
        toast.success('Asset saved.');
      } catch {
        toast.error('Something went wrong while saving the asset.');
      } finally {
        setIsSaving(false);
        onClose();
      }
    },
    [alt, caption, captionUrl, title, assetId, publicationId, onChange, onClose]
  );

  return {
    asset,
    handleSave,
    isSaving,
    assetFetched,
    title,
    setTitle,
    alt,
    setAlt,
    caption,
    setCaption,
    captionUrl,
    setCaptionUrl,
  };
};

export const MetaDataModalContent = ({ onClose, assetId, publicationId, onChange }: MetaDataModalContentProps) => {
  const form = useFormState({ assetId, publicationId, onChange, onClose });

  if (!form.asset) {
    return null;
  }

  return (
    <>
      <div className="flex-1 overflow-auto">
        <div className="flex h-full gap-8">
          <div className="w-sidebar">
            <div className="flex flex-col gap-4">
              <Input.Wrapper>
                <Input.Label htmlFor="input__asset_title">Title</Input.Label>
                <Input.Field
                  placeholder="Default title"
                  id="input__asset_title"
                  type="text"
                  value={form.title}
                  onChange={(e) => form.setTitle(e.currentTarget.value)}
                />
              </Input.Wrapper>
              <Input.Wrapper>
                <Input.Label htmlFor="input__alt_text">Alt text</Input.Label>
                <Input.Field
                  placeholder="Default alt text"
                  id="input__alt_text"
                  type="text"
                  value={form.alt}
                  onChange={(e) => form.setAlt(e.currentTarget.value)}
                />
                <Input.DescriptionText>
                  The alternative text is displayed if your image cannot be displayed correctly.
                </Input.DescriptionText>
              </Input.Wrapper>
              <Input.Wrapper>
                <Input.Label htmlFor="input__caption">Caption</Input.Label>
                <Input.Field
                  placeholder="Default caption"
                  id="input__caption"
                  type="text"
                  value={form.caption}
                  onChange={(e) => form.setCaption(e.currentTarget.value)}
                />
              </Input.Wrapper>
              <Input.Wrapper>
                <Input.Label htmlFor="input__caption_url">Caption link</Input.Label>
                <Input.Field
                  placeholder="Default caption link"
                  id="input__caption_url"
                  type="url"
                  value={form.captionUrl}
                  onChange={(e) => form.setCaptionUrl(e.currentTarget.value)}
                />
              </Input.Wrapper>
            </div>
          </div>
          <div className="flex items-center justify-center flex-1 h-full col-span-7 overflow-hidden bg-gray-100 border border-gray-200 rounded-xl">
            <img alt={form.asset.alt} src={form.asset.url} className="object-contain w-full h-full" />
          </div>
        </div>
      </div>
      <div className="flex-none mt-6">
        <div className="flex items-center justify-end gap-2">
          <Button disabled={form.isSaving} onClick={form.handleSave} type="button" variant="primary">
            Save
            <ArrowRightIcon className="w-5 h-5 ml-1" />
          </Button>
        </div>
      </div>
    </>
  );
};
