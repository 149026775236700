import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { OnMediaPayload } from '@/components/MediaLibrary/MediaLibrary.types';
import { Asset } from '@/interfaces/asset';

import MediaLibrary from '../../../../MediaLibrary/MediaLibrary';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Spinner } from '../../../components/ui/Spinner';
import { colors } from '../../../lib/colors';
import { usePublicationContext } from '../../../lib/context/PublicationContext';

import { useDropZone, useFileUpload, useUploader } from './hooks';

const UploadWrapper = styled.div<{
  isDraggedIn?: boolean;
}>`
  align-items: center;
  background-color: ${(p) => (p.isDraggedIn ? '#f0f0f0' : 'white')};
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
  border-radius: 0.25rem;

  [data-type='column'] & {
    padding: 1rem 0.5rem;
  }
`;

const LoadingWrapper = styled.div`
  align-items: center;
  background: #f4f4f4;
  border-radius: 4px;
  color: #888;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
`;

const StyledIcon = styled(Icon)`
  color: ${colors.black[4]};
`;

const UploadContent = styled.div`
  margin-top: 1rem;
`;

const UploadLabel = styled.div`
  color: ${colors.black[3]};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
`;

const UploadButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  margin-top: 0.75rem;

  // if this component is used inside a
  // [data-type="column"], change flex layout
  [data-type='column'] & {
    flex-direction: column;
  }
`;

const FileInput = styled.input`
  height: 0;
  opacity: 0;
  overflow: hidden;
  width: 0;
`;

export const ImageUploader = ({ onUpload }: { onUpload: (payload: OnMediaPayload, pos?: number) => void }) => {
  const { publicationId } = usePublicationContext();

  const handleUpload = useCallback(
    (asset: Asset) => {
      onUpload({ media: asset });
    },
    [onUpload]
  );

  const { loading, uploadFile } = useUploader({ publicationId, onUpload: handleUpload });
  const { handleUploadClick, ref } = useFileUpload();
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const { draggedInside, onDrop, onDragEnter, onDragLeave } = useDropZone({ uploader: uploadFile });

  if (loading) {
    return (
      <LoadingWrapper>
        <Spinner size={1.5} />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <MediaLibrary
        isOpen={showImageLibrary}
        onClose={() => setShowImageLibrary(false)}
        publicationId={publicationId}
        onMediaSelect={onUpload}
      />
      <UploadWrapper
        isDraggedIn={draggedInside}
        onDrop={onDrop}
        onDragOver={onDragEnter}
        onDragLeave={onDragLeave}
        contentEditable={false}
      >
        <StyledIcon name="Image" $size="" />
        <UploadContent>
          <UploadLabel>{draggedInside ? 'Drop image here' : 'Drag and drop or'}</UploadLabel>
          <UploadButtons>
            <Button
              disabled={draggedInside}
              onClick={handleUploadClick}
              $variant="secondary"
              $size="small"
              $leftSlot={<Icon name="Upload" />}
            >
              Upload an image
            </Button>
            <Button
              onClick={() => setShowImageLibrary(true)}
              disabled={draggedInside}
              $variant="secondary"
              $size="small"
              $leftSlot={<Icon name="Library" />}
            >
              Use from library
            </Button>
          </UploadButtons>
        </UploadContent>
        <FileInput
          ref={ref}
          type="file"
          accept=".jpg,.jpeg,.png,.webp,.gif"
          onChange={(e) => (e.target.files ? uploadFile(e.target.files[0]) : null)}
        />
      </UploadWrapper>
    </>
  );
};

export default ImageUploader;
