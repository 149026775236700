import React, { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { EnvelopeIcon, TagIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import { StringParam, useQueryParam } from 'use-query-params';

import ActionModal from '../../components/ActionModal';
import Card from '../../components/Card';
import EllipsisDropdown from '../../components/EllipsisDropdown';
import PageHeading from '../../components/Layout/PageLayout/PageHeading';
// Contexts
import { useCurrentPublicationState } from '../../context/current-publication-context';
// Hooks
import { useContentTags } from '../../hooks';
import { useTutorial } from '../../hooks/useTutorials';
import { TutorialType } from '../../interfaces/tutorial';
// Services
import api from '../../services/swarm';
import { Badge } from '../../ui/Badge';
// Components
import { Button } from '../../ui/Button';
import SearchInput from '../../ui/SearchInput';

const ContentTags: FC = () => {
  const tutorial = useTutorial(TutorialType.CONTENT_TAGS);

  const [search, setSearch] = useQueryParam('search', StringParam);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);
  const [deleteContentTagId, setDeleteContentTagId] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();

  const contentTagsQuery = useContentTags({ search: search || '' });

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, error } = contentTagsQuery;

  const contentTags = data?.pages.flatMap((page) => page.contentTags) || [];
  const totalContentTagCount = data?.pages[0]?.pagination?.total;
  const showingContentTagsCount = contentTags?.length || 0;

  const isNoResults = !isLoading && contentTags.length === 0;
  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
    }
  }, [error]);

  // Reset search when no results
  const handleResetSearch = () => {
    setSearch(undefined);
    setShouldResetSearch(true);
  };

  const onDeleteContentTagRequested = (contentTagId: string) => {
    setDeleteContentTagId(contentTagId);
  };

  const onDeleteContentTagModalClose = () => {
    setDeleteContentTagId('');
  };

  const onDeleteContentTag = (contentTagId: string) => {
    const params = {
      publication_id: currentPublicationId,
    };

    setIsDeleting(true);
    api
      .delete(`/content_tags/${contentTagId}`, { params })
      .then(() => {
        setDeleteContentTagId('');
        refetch();
        toast.success('Content Tag deleted successfully');
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const NoResults = () => {
    const noSearchResults = isNoResults && search;

    const text = noSearchResults
      ? `No results found for search, '${search}'`
      : `Once you create your first content tag, it will show up here.`;

    return (
      <div className={`bg-gray-50 p-8 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500 text-center">{text}</p>
          {noSearchResults ? (
            <Button variant="primary-inverse" onClick={handleResetSearch}>
              <div className="flex">
                <ArrowPathIcon className="h-5 w-5 mr-2" />
                Reset Search
              </div>
            </Button>
          ) : (
            <Button variant="primary" onClick={() => navigate('/content_tags/new')}>
              <div className="flex">
                <TagIcon className="h-5 w-5 mr-2" />
                Create Content Tag
              </div>
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <ActionModal
        isOpen={deleteContentTagId !== ''}
        onClose={onDeleteContentTagModalClose}
        onProceed={onDeleteContentTag}
        resourceId={deleteContentTagId}
        isWorking={isDeleting}
        headerText="Delete ContentTag"
        actionText="Delete"
      >
        Are you sure you want to delete this content tag?
      </ActionModal>
      <PageHeading title="Content Tags" description="Create and edit content tags" tutorial={tutorial}>
        <Button variant="primary" onClick={() => navigate('/content_tags/new')}>
          <div className="flex">
            <TagIcon className="h-5 w-5 mr-2" />
            Create Content Tag
          </div>
        </Button>
      </PageHeading>
      <div className="mb-8 pb-8 pt-4 border-b border-gray-200">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-full relative">
            <div className="w-full sm:w-fit">
              <SearchInput
                defaultValue={search || ''}
                shouldDebounce={false}
                shouldReset={shouldResetSearch}
                onClearSearch={handleResetSearch}
                onSearch={setSearch}
                placeholder="Search Content Tags"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-1">
        <span className="text-xs font-semibold text-gray-600">{`Showing ${showingContentTagsCount} of ${totalContentTagCount} results`}</span>
      </div>
      <Card noPadding>
        {data && contentTags.length === 0 ? (
          <NoResults />
        ) : (
          <ul className="divide-y rounded">
            {contentTags.map((content_tag) => {
              const options = [
                {
                  label: 'Edit',
                  onClick: () => navigate(`/content_tags/${content_tag.id}/edit`),
                },
                {
                  label: 'Delete',
                  onClick: () => onDeleteContentTagRequested(content_tag.id),
                },
              ];
              return (
                <li className="p-4" key={content_tag.id}>
                  <div className="flex justify-between items-start mb-2">
                    <div className="w-2/3">
                      <p className="text-gray-800 font-semibold truncate">
                        <div className="hover:text-gray-900">{content_tag.display}</div>
                      </p>
                      <p className="text-gray-500 text-sm truncate mb-2">{content_tag.description}</p>
                      <p>
                        <Badge type="information">
                          <EnvelopeIcon className="h-5 w-5 mr-2" /> {content_tag.post_count}
                        </Badge>
                      </p>
                    </div>
                    <div>
                      <EllipsisDropdown options={options} />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
        <div className={cx('text-center', hasNextPage ? 'my-6' : '')}>
          {hasNextPage && (
            <div>
              <Button
                variant="primary-inverse"
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
              >
                {isFetchingNextPage ? 'Loading more...' : 'Load more'}
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ContentTags;
