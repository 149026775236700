import { useQuery } from 'react-query';

import { SubscriptionChange } from '@/interfaces/mailer_actions/subscription_change';
import api from '@/services/swarm';

const useSubscriptionChange = (token?: String) => {
  return useQuery<SubscriptionChange>(
    ['mailer_actions', 'subscription_change', token],
    () => api.get(`/mailer_actions/subscription_change_confirmations/${token}`, {}).then((res) => res.data),
    {
      staleTime: 10000,
      enabled: !!token,
    }
  );
};

export default useSubscriptionChange;
