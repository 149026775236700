import { useQueryClient } from 'react-query';

import { EmailMessage } from '../../../../interfaces/email_message';
import Modal from '../../../Modal';
import Editor from '../../EmailMessage/Editor';

const EditMessageModal = ({
  isOpen,
  onClose,
  emailMessage,
}: {
  isOpen: boolean;
  onClose: () => void;
  emailMessage: EmailMessage;
}) => {
  const queryClient = useQueryClient();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        queryClient.invalidateQueries(['email_messages', emailMessage.id]);
        onClose();
      }}
      fullScreen
      includeCloseButton={false}
    >
      <Editor
        emailMessage={emailMessage}
        onExit={() => {
          queryClient.invalidateQueries(['email_messages', emailMessage.id]);
          onClose();
        }}
      />
    </Modal>
  );
};

export default EditMessageModal;
