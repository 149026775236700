import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import styled from 'styled-components';

import { Spinner } from '../../../components/ui/Spinner';
import { API } from '../../../lib/api';
import { usePublicationContext } from '../../../lib/context/PublicationContext';

import { ImageBlockContext } from './ImageBlockContext';

const LoaderWrapper = styled.div`
  align-items: center;
  aspect-ratio: 2/1;
  background-color: #f4f4f4;
  border-radius: 1rem;
  color: #888;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ImageBlockWrapper = styled.div<{
  align: 'left' | 'center' | 'right';
}>`
  margin-left: ${(p) => (p.align === 'left' ? '0' : 'auto')};
  margin-right: ${(p) => (p.align === 'right' ? '0' : 'auto')};
`;

const ImageWrapper = styled.div``;

const Image = styled.img`
  display: block;
`;

const CaptionWrapper = styled.div<{
  align: 'left' | 'center' | 'right';
}>`
  display: block;
  text-align: ${(p) => p.align};
`;

export const ImageBlockView = (props: NodeViewProps) => {
  const { publicationId } = usePublicationContext();
  const { editor, getPos, node, updateAttributes } = props;
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const captionWrapperRef = useRef<HTMLDivElement>(null);

  const {
    id,
    src,
    alt,
    align = 'center',
    captionAlign = 'center',
    captionUrl,
    width,
    borderWidthTop = '0',
    borderWidthRight = '0',
    borderWidthBottom = '0',
    borderWidthLeft = '0',
    borderTopLeftRadius = '0',
    borderTopRightRadius = '0',
    borderBottomRightRadius = '0',
    borderBottomLeftRadius = '0',
    borderColor = undefined,
    borderStyle = undefined,
  } = node.attrs;

  useEffect(() => {
    if (id && !src) {
      setLoading(true);

      API.getAsset({
        assetId: id,
        publicationId,
      })
        .then((res) => {
          updateAttributes({
            src: res.data.url,
            title: res.data.title || '',
            alt: res.data.alt || '',
          });
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [id, src, publicationId, updateAttributes]);

  useEffect(() => {
    if (src) {
      setImage(src);
    }
  }, [src]);

  return (
    <NodeViewWrapper
      data-drag-handle
      as="figure"
      data-type="imageBlock"
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <ImageBlockContext.Provider value={{ loading, setLoading, image, setImage, updateAttributes }}>
        <ImageBlockWrapper align={align} style={{ width }}>
          <ImageWrapper contentEditable={false} ref={imageWrapperRef}>
            {image && (
              <Image
                src={image}
                alt={alt}
                onClick={() => {
                  editor.commands.focus(getPos() + 2);
                }}
                style={{
                  borderTopWidth: borderWidthTop ? `${borderWidthTop}px` : undefined,
                  borderRightWidth: borderWidthRight ? `${borderWidthRight}px` : undefined,
                  borderBottomWidth: borderWidthBottom ? `${borderWidthBottom}px` : undefined,
                  borderLeftWidth: borderWidthLeft ? `${borderWidthLeft}px` : undefined,
                  borderTopLeftRadius: borderTopLeftRadius ? `${borderTopLeftRadius}px` : undefined,
                  borderTopRightRadius: borderTopRightRadius ? `${borderTopRightRadius}px` : undefined,
                  borderBottomRightRadius: borderBottomRightRadius ? `${borderBottomRightRadius}px` : undefined,
                  borderBottomLeftRadius: borderBottomLeftRadius ? `${borderBottomLeftRadius}px` : undefined,
                  borderColor,
                  borderStyle,
                }}
              />
            )}
            {loading && !image && (
              <LoaderWrapper>
                <Spinner size={1.5} />
              </LoaderWrapper>
            )}
          </ImageWrapper>
          <CaptionWrapper align={captionAlign} ref={captionWrapperRef}>
            {captionUrl && captionUrl.length > 0 ? (
              <a href={captionUrl} target="_blank" rel="noreferrer">
                <NodeViewContent className="figcaption" />
              </a>
            ) : (
              <NodeViewContent className="figcaption" />
            )}
          </CaptionWrapper>
        </ImageBlockWrapper>
      </ImageBlockContext.Provider>
    </NodeViewWrapper>
  );
};

export default ImageBlockView;
