import { useParams } from 'react-router-dom';

import useAdvertiserPublisherListItemCreate from '@/hooks/useAdNetwork/internal/useAdvertiserPublisherListItemCreate';
import { PageHeading } from '@/routes/ad_network/_components/PageHeading';

import Form from '../_components/Form';

interface Props {
  advertiserId: string;
}

const AddEntry = ({ advertiserId }: Props) => {
  const onSubmitMutation = useAdvertiserPublisherListItemCreate({ advertiserId });
  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Publisher Preferred/Excluded List</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">New</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="mx-auto max-w-lg pt-4">
        <h1 className="text-xl">New Publisher List Item</h1>
      </div>
      <Form onSubmitMutation={onSubmitMutation} />
    </>
  );
};

export default function Loader() {
  const { advertiser_id: advertiserId } = useParams<'advertiser_id'>() as { advertiser_id: string };
  return <AddEntry advertiserId={advertiserId} />;
}
