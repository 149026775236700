import { useEffect } from 'react';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';

import { Checkbox } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { usePermissions } from '@/context/permissions-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import { WebTemplatePayload } from '@/hooks/useWebTemplates/useUpdateWebTemplate';
import { PostBulkActions, PostPreview } from '@/interfaces/post';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import { CardDensity } from '@/ui/Card/Card.types';

import PostContainer from './PostContainer';

interface PostsContainerProps {
  selectCheckOptions: {
    posts: PostPreview[];
    selectedPosts: string[];
    setSelectedPosts: (value: string[]) => void;
    totalPostCount?: number;
  };
  featuredPostIds?: string[];
  allPostIds?: string[];
  selectedCheckbox: boolean;
  setSelectedCheckbox: (value: boolean) => void;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
  refetch: () => void;
  handleCreateTemplateFromPost: (post: PostPreview) => void;
  handleDeletePost: (post: PostPreview) => void;
  handleGiftLinkForPost: (post: PostPreview) => void;
  webTemplateMutation: UseMutationResult<AxiosResponse<any, any>, any, WebTemplatePayload, unknown>;
  handleDeleteClick: (type: PostBulkActions) => void;
  queryParams?: any;
}

const PostsContainer = ({
  selectCheckOptions,
  featuredPostIds,
  allPostIds,
  selectedCheckbox,
  setSelectedCheckbox,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  refetch,
  handleCreateTemplateFromPost,
  handleDeletePost,
  handleGiftLinkForPost,
  webTemplateMutation,
  handleDeleteClick,
  queryParams,
}: PostsContainerProps) => {
  const { data: currentPublication } = useCurrentPublication();
  const premiumEnabled = currentPublication?.is_premium_enabled;
  const { posts, totalPostCount, selectedPosts, setSelectedPosts } = selectCheckOptions;
  const { settings } = useSettings();
  const { checkPermissions } = usePermissions();
  const giftLinkEnabled = settings?.post_gift_links;

  useEffect(() => {
    if (selectCheckOptions.selectedPosts.length === totalPostCount) {
      setSelectedCheckbox(true);
    }
  }, [selectedCheckbox, totalPostCount, selectCheckOptions.selectedPosts, setSelectedCheckbox]);

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      selectCheckOptions.setSelectedPosts(allPostIds || []);
      setSelectedCheckbox(true);
    } else {
      selectCheckOptions.setSelectedPosts([]);
      setSelectedCheckbox(false);
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Checkbox
          name="select-all"
          checked={selectedCheckbox}
          onChange={handleSelectAll}
          labelText={`Showing ${posts.length} of ${totalPostCount} posts`}
          labelClassNames="text-xs !text-gray-500"
        />
      </div>
      {(selectedCheckbox || selectedPosts.length > 0) && (
        <div>
          <Card density={CardDensity.narrow}>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row gap-2 items-center">
                <Typography token="font-normal/text/xs" colorWeight="500">
                  {selectedCheckbox ? totalPostCount : selectedPosts.length} selected
                </Typography>
                <Button
                  onClick={() => {
                    setSelectedPosts([]);
                    setSelectedCheckbox(false);
                  }}
                  variant="primary-inverse"
                  size="xs"
                >
                  Deselect
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => handleDeleteClick(PostBulkActions.DELETE)}
                  variant="danger"
                  size="xs"
                  shade="default"
                >
                  {selectedPosts.length > 1 ? 'Delete all' : 'Delete'}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      )}
      <div className="flex flex-col items-start self-stretch border border-surface-200 rounded-lg divide-y">
        {posts.map((post, index) => (
          <PostContainer
            key={post.id}
            post={post}
            publication={currentPublication}
            firstItem={index === 0}
            lastItem={index === (totalPostCount || 1) - 1}
            featured={featuredPostIds?.includes(post.id)}
            featuredPostsIds={featuredPostIds || []}
            selected={selectedPosts.includes(post.id)}
            onSelect={() => {
              if (selectedCheckbox) {
                setSelectedCheckbox(false);
              }
              const updatedSelectedPosts = selectedPosts.includes(post.id)
                ? selectedPosts.filter((id) => id !== post.id)
                : [...selectedPosts, post.id];
              selectCheckOptions.setSelectedPosts(updatedSelectedPosts);
            }}
            refetch={refetch}
            handleCreateTemplateFromPost={handleCreateTemplateFromPost}
            handleDeletePost={handleDeletePost}
            handleGiftLinkForPost={handleGiftLinkForPost}
            webTemplateMutation={webTemplateMutation}
            giftLinkEnabled={giftLinkEnabled}
            checkPermissions={checkPermissions}
            premiumEnabled={premiumEnabled}
            queryParams={queryParams}
          />
        ))}
        {hasNextPage && fetchNextPage && (
          <div className="w-full flex flex-row justify-center py-4">
            <Button
              type="button"
              variant="primary-inverse"
              onClick={fetchNextPage}
              disabled={isFetchingNextPage}
              size="xs"
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load more'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostsContainer;
