import { FC, useRef } from 'react';

import { Button } from '@/ui/Button';

interface Props {
  className?: string;
  name: string;
  labelText: string;
  helperText?: string;
  required?: boolean;
  onFileSelect: (file: File) => void;
  file?: File | null;
  accept?: string;
}

const FileSelect: FC<Props> = (props: Props) => {
  const { className, name, labelText, helperText, required, onFileSelect, file, accept = '*' } = props;
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files && e.target.files.length > 0) {
      onFileSelect(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  let fileName = null;
  if (file) {
    fileName = typeof file === 'string' ? file : file?.name;
  }

  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {labelText}
        {required ? ' *' : ''}
      </label>
      <div className="mt-1 flex items-center">
        <input name={name} ref={fileInput} type="file" onChange={handleFileInput} className="hidden" accept={accept} />
        <Button variant="primary-inverse" type="button" onClick={onButtonClick}>
          {file ? 'Change File' : 'Select File'}
        </Button>
        <p className="text-sm font-medium text-gray-700 ml-2">{fileName}</p>
      </div>
      {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
    </div>
  );
};

export default FileSelect;
