import { ElementType, PropsWithChildren, useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

import { TooltipProps } from '../../../_types/misc';
import CustomizeMoreButton from '../../Helpers/CustomizeMoreButton';
import PropertiesGrouping from '../../Helpers/PropertiesGrouping';

export type Props = PropsWithChildren<{
  label: string;
  tooltip?: TooltipProps;
  Icon?: ElementType;
  actionLabel?: string;
  description?: string | React.ReactNode;
  alwaysShowingChildren: React.ReactNode;
}>;

const FormHelper = ({
  label,
  tooltip,
  children,
  alwaysShowingChildren,
  actionLabel = 'Customize',
  description,
  Icon,
}: Props) => {
  const [isCustomizeShowing, setIsCustomizeShowing] = useState(false);

  return (
    <PropertiesGrouping
      className="!space-y-2"
      description={description}
      indent
      label={label}
      tooltip={tooltip}
      actionChild={
        <CustomizeMoreButton
          Icon={Icon || PencilSquareIcon}
          isOpen={isCustomizeShowing}
          text={actionLabel}
          onClose={() => setIsCustomizeShowing(!isCustomizeShowing)}
        />
      }
    >
      {alwaysShowingChildren}
      {isCustomizeShowing && children}
    </PropertiesGrouping>
  );
};

export default FormHelper;
