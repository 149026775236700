import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface MutationParams {
  subscriptionId: string;
}

const useDeleteSubscription = (mutationParams: MutationParams) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const { subscriptionId } = mutationParams;

  return useMutation(
    (params: any) =>
      api
        .delete(`/subscriptions/${subscriptionId}`, {
          data: { publication_id: currentPublicationId, ...params },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([currentPublicationId, 'subscribers']);
        queryClient.invalidateQueries(['subscriber', subscriptionId, currentPublicationId], { exact: true });
      },
    }
  );
};

export default useDeleteSubscription;
