import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { SubscriptionMilestone } from '@/interfaces/milestone';
import api from '@/services/swarm';

const useFulfillment = (subscriptionMilestone?: SubscriptionMilestone, onActionSuccess?: Function) => {
  const [publicationId] = useCurrentPublicationState();

  const [isMarkingAsFulfilled, setIsMarkingAsFulfilled] = useState(false);
  const [isMarkingAsIgnored, setIsMarkingAsIgnored] = useState(false);
  const [isMarkingAsPending, setIsMarkingAsPending] = useState(false);

  const onMarkAsFulfilled = () => {
    if (!subscriptionMilestone) {
      return;
    }

    const payload = {
      publication_id: publicationId,
    };

    setIsMarkingAsFulfilled(true);
    api
      .post(`/referral_program/subscription_milestones/${subscriptionMilestone.id}/update_status/fulfilled`, payload)
      .then(() => {
        onActionSuccess?.();
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsMarkingAsFulfilled(false);
      });
  };

  const onMarkAsIgnored = () => {
    if (!subscriptionMilestone) {
      return;
    }

    const payload = {
      publication_id: publicationId,
    };

    setIsMarkingAsIgnored(true);
    api
      .post(`/referral_program/subscription_milestones/${subscriptionMilestone.id}/update_status/ignored`, payload)
      .then(() => {
        onActionSuccess?.();
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsMarkingAsIgnored(false);
      });
  };

  const onMarkAsPending = () => {
    if (!subscriptionMilestone) {
      return;
    }

    const payload = {
      publication_id: publicationId,
    };

    setIsMarkingAsPending(true);
    api
      .post(`/referral_program/subscription_milestones/${subscriptionMilestone.id}/update_status/pending`, payload)
      .then(() => {
        onActionSuccess?.();
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsMarkingAsPending(false);
      });
  };

  return {
    onMarkAsFulfilled,
    onMarkAsIgnored,
    onMarkAsPending,
    isMarkingAsFulfilled,
    isMarkingAsIgnored,
    isMarkingAsPending,
    shouldAssignPromoCode: !!(
      subscriptionMilestone?.is_fulfilled_via_promo_code &&
      !subscriptionMilestone.reward_use_static_promo_code &&
      !subscriptionMilestone?.reward_promo_code_id
    ),
    canPerformActions: !(!subscriptionMilestone || subscriptionMilestone.status === 'awaiting_promo_code'),
    canMarkAsPending: subscriptionMilestone ? subscriptionMilestone.status !== 'pending' : false,
    canMarkAsIgnored: subscriptionMilestone ? subscriptionMilestone.status !== 'ignored' : false,
    canMarkAsFulfilled: subscriptionMilestone ? subscriptionMilestone.status !== 'fulfilled' : false,
  };
};

export default useFulfillment;
