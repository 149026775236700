import { BarList as BarListChart, BarListProps } from '@tremor/react';
import styled from 'styled-components';
import tw from 'twin.macro';

// If the items are linked, we want the link color to remain the same gray color
// instead of a generic blue. We can't customize the component directly so have
// to reach in via CSS.
const StyledWrapper = styled.div`
  .tremor-BarList-barLink {
    ${tw`text-gray-700`}
  }
`;

// https://www.tremor.so/docs/components/barlist
// Ideally we pull from this base chart with our foundational customizations
// But you can always use the Tremor chart directly if needed
const BarList = (props: BarListProps) => {
  const { color, ...rest } = props;
  return (
    <StyledWrapper>
      <BarListChart color={color || 'pink'} {...rest} />
    </StyledWrapper>
  );
};

export default BarList;
