import { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { PencilSimple } from '@phosphor-icons/react';
import { Checkbox } from '@radix-ui/react-checkbox';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCreateSite } from '@/hooks/useSite';
import useSitePackages from '@/hooks/useSitePackages/useSitePackages';
import { useSiteThemes, useUpdateSiteTheme } from '@/hooks/useSiteThemes';
import { SitePackage } from '@/interfaces/site_package';
import { SiteTemplate } from '@/interfaces/site_template';
import { WebTheme } from '@/interfaces/web_theme';
import { cn } from '@/utils/cn';

import { previewThemeApplier } from '../_components/DreamEditor/utils/previewThemeApplier';
import TemplatePreviewer from '../_components/Templates/TemplatePreviewer';
import { Button } from '../_components/UI/Button';
import { Label } from '../_components/UI/Label';
import { Text } from '../_components/UI/Text';
import { getFontFamilies } from '../_utils/getFonts';
import { ColorSelection } from '../settings/themes/_components/ColorSelection';

import FontsPreviewer from './_components/FontsPreviewer';

const TITLES = {
  '1': 'Select a template',
  '2': 'Choose a primary color',
  '3': 'Pick your fonts',
};

type FontPairing = {
  title: string;
  header: string;
  body: string;
};

const DEFAULT_FONT_PAIRINGS: FontPairing[] = [
  {
    title: 'Modern & Clean',
    header: 'Montserrat',
    body: 'Open Sans',
  },
  {
    title: 'Elegant & Traditional',
    header: 'Abril Fatface',
    body: 'Georgia',
  },
  {
    title: 'Minimal & Professional',
    header: 'Josefin Sans',
    body: 'Lato',
  },
  {
    title: 'Classic & Sophisticated',
    header: 'Playfair Display',
    body: 'Merriweather',
  },
  {
    title: 'Bold & Attention-Grabbing',
    header: 'Bebas Neue',
    body: 'Roboto',
  },
  {
    title: 'Quirky & Fun',
    header: 'Amatic SC',
    body: 'Poppins',
  },
  {
    title: 'Vintage & Refined',
    header: 'Cinzel',
    body: 'Crimson Text',
  },
  {
    title: 'Artistic & Creative',
    header: 'Pacifico',
    body: 'Nunito',
  },
  {
    title: 'Geometric & Versatile',
    header: 'Futura',
    body: 'Work Sans',
  },
];

const WebsiteOnboardingPage = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const { data: packages } = useSitePackages({ enabled: true, allPublic: true });
  const projects = packages?.pages.flatMap((pkg) => pkg.site_packages) || [];
  const [selectedProject, setSelectedProject] = useState<SitePackage | null>(null);
  const isDisabled = selectedProject === null;
  const ref = useRef<HTMLDivElement>(null);

  const queryKey = ['publications', currentPublicationId, 'site_themes'];

  const [color, setColor] = useState<string | null>('#e6e6e6');
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [selectedFontPairing, setSelectedFontPairing] = useState<FontPairing | null>(null);
  const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());

  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get('step') || '1';
  const isStepOne = step === '1';
  const isStepTwo = step === '2';
  const isStepThree = step === '3';

  useEffect(() => {
    getFontFamilies().then(() => {
      const usableFonts = DEFAULT_FONT_PAIRINGS.reduce((acc: string[], font) => {
        acc.push(font.header);
        acc.push(font.body);
        return acc;
      }, []);

      setFontHrefs(
        new Set(usableFonts.map((font) => `https://fonts.googleapis.com/css2?family=${encodeURI(font)}&display=swap`))
      );
    });
  }, []);

  const { data, isRefetching } = useSiteThemes();

  const siteThemes = data?.pages.flatMap((page) => page.site_themes) || [];
  const siteThemesLength = siteThemes.length;
  const themeRules = data?.pages?.[0]?.theme_rules;

  const { primaryTheme } = useMemo(() => {
    const theme = siteThemes.find((thme) => thme.is_primary);
    setColor(theme?.data?.primary_color || '#e6e6e6');
    return { primaryTheme: theme };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteThemesLength, isRefetching]);

  const updateSiteTheme = useUpdateSiteTheme({
    siteThemeId: primaryTheme?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const { previewNavbar, previewContent, previewFooter } = useMemo(() => {
    const project = projects.find((pkg) => pkg.id === selectedProject?.id);
    const homePage = project?.templates.find((template: SiteTemplate) => template.name === 'Home');
    const homPageCopy = { ...homePage };

    return {
      previewNavbar: previewThemeApplier({
        content: homePage?.content,
        themeRules: themeRules || {},
        theme: primaryTheme?.data as WebTheme,
      }),
      previewContent: previewThemeApplier({
        content: homPageCopy?.content,
        themeRules: themeRules || {},
        theme: primaryTheme?.data as WebTheme,
      }),
      previewFooter: previewThemeApplier({
        content: homPageCopy?.content,
        themeRules: themeRules || {},
        theme: primaryTheme?.data as WebTheme,
      }),
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, primaryTheme, themeRules]);

  const handleUpdateThemeColor = (val?: string) => {
    updateSiteTheme.mutate({
      name: 'Primary Theme',
      data: {
        ...primaryTheme?.data,
        primary_color: val || selectedColor,
      } as any,
    });
  };

  const handleUpdateThemeFonts = (font: FontPairing) => {
    updateSiteTheme.mutate({
      name: 'Primary Theme',
      data: {
        ...primaryTheme?.data,
        header_font: font.header,
        body_font: font.body,
      } as any,
    });
  };

  const createSite = useCreateSite({ sitePackageId: selectedProject?.id || '' });

  const createSiteMutation = () => {
    createSite.mutate();
  };

  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowing(true);
    }, 500);
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col overflow-scroll no-scrollbar bg-white py-16 pb-32 gap-8 px-8">
      <div className="flex flex-col items-center justify-center">
        <Text size="2xl" className="font-bold !text-6xl">
          {TITLES[step as keyof typeof TITLES]}
        </Text>
      </div>

      {isStepOne && (
        <Transition
          className="w-full flex flex-col gap-8"
          show={isShowing}
          enter="transition-all ease-in-out duration-500 delay-[200ms]"
          enterFrom="opacity-0 translate-y-8"
          enterTo="opacity-100 translate-y-0"
          leave="transition-all ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="grid grid-cols-3 gap-4 mx-auto">
            {projects?.map((project) => {
              const isSelected = selectedProject?.id === project.id;
              const homePage = project.templates.find((template: SiteTemplate) => template.name === 'Home');

              return (
                <div className="relative">
                  <Label
                    key={project.id}
                    htmlFor={project.id}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-2xl transition-shadow duration-200 p-0 rounded-xl overflow-hidden focus:outline-none relative justify-center items-center',
                      isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    {homePage ? (
                      <TemplatePreviewer
                        navbarContent={project.navbar}
                        content={homePage?.content}
                        hasBrowserBar={false}
                        containerHeightClass="h-[500px]"
                        previewHeight="1600px"
                      />
                    ) : null}
                    <Checkbox
                      id={project.id}
                      className={cn('absolute -top-2 -right-2', isSelected ? 'opacity-100' : 'opacity-0')}
                      checked={Boolean(isSelected)}
                      onCheckedChange={() => setSelectedProject(project)}
                    />
                  </Label>

                  <div className="flex justify-center items-center pt-2">
                    <Text weight="bold" variant="primary" size="xl" as="span" className="">
                      {project.name}
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        </Transition>
      )}

      {isStepTwo && (
        <Transition
          className="w-full flex flex-col gap-8"
          show={isShowing}
          enter="transition-all ease-in-out duration-500 delay-[200ms]"
          enterFrom="opacity-0 translate-y-8"
          enterTo="opacity-100 translate-y-0"
          leave="transition-all ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="flex gap-4">
            <div ref={ref} onBlur={() => handleUpdateThemeColor()} className="w-1/6">
              <ColorSelection
                initialColor={color}
                offset={20}
                onSetColor={(value) => {
                  setColor(value || '');
                  setSelectedColor(value || '');
                }}
              >
                <div className="flex flex-col items-center relative">
                  <div
                    className={cn(
                      'rounded-xl w-[100px] h-[100px] cursor-pointer',
                      selectedColor === color ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                    style={{ backgroundColor: color || '' }}
                  />

                  <div className="flex justify-center items-center pt-2 gap-1 absolute -bottom-8">
                    <PencilSimple size={20} />
                    <Text weight="bold" variant="primary" size="md" as="span" className="">
                      {color}
                    </Text>
                  </div>
                </div>
              </ColorSelection>
            </div>
            <div className="w-full flex justify-center mx-auto items-center max-w-[500px]">
              <div className="w-full flex mx-auto">
                <TemplatePreviewer
                  navbarContent={previewNavbar as any}
                  content={previewContent as any}
                  footerContent={previewFooter as any}
                  hasBrowserBar={false}
                  containerHeightClass="h-[500px]"
                  previewHeight="1600px"
                />
              </div>
            </div>
            <div className="w-1/6" />
          </div>
        </Transition>
      )}

      {isStepThree && (
        <Transition
          className="w-full flex flex-col gap-8"
          show={isShowing}
          enter="transition-all ease-in-out duration-500 delay-[200ms]"
          enterFrom="opacity-0 translate-y-8"
          enterTo="opacity-100 translate-y-0"
          leave="transition-all ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="grid grid-cols-3 gap-8 mx-auto">
            {DEFAULT_FONT_PAIRINGS?.map((fontPairing: FontPairing) => {
              const isSelected = selectedFontPairing?.title === fontPairing.title;

              return (
                <div className="relative">
                  <Label
                    key={fontPairing.title}
                    htmlFor={fontPairing.title}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-2xl transition-shadow duration-200 p-4 border overflow-hidden rounded-xl focus:outline-none relative justify-center items-center',
                      isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    <FontsPreviewer fontHrefs={fontHrefs}>
                      <div className="flex flex-col gap-2 items-center scale-2">
                        <Text weight="bold" variant="primary" size="xl" as="span" className="">
                          <span
                            style={{
                              fontFamily: fontPairing.header,
                            }}
                          >
                            {fontPairing.header}
                          </span>
                        </Text>
                        <Text weight="medium" variant="primary" size="md" as="span" className="">
                          <span
                            style={{
                              fontFamily: fontPairing.body,
                            }}
                          >
                            {fontPairing.body}
                          </span>
                        </Text>
                      </div>
                    </FontsPreviewer>
                    <Checkbox
                      id={fontPairing.title}
                      className={cn('absolute -top-2 -right-2', isSelected ? 'opacity-100' : 'opacity-0')}
                      checked={Boolean(isSelected)}
                      onCheckedChange={() => {
                        setSelectedFontPairing(fontPairing);
                        handleUpdateThemeFonts(fontPairing);
                      }}
                    />
                  </Label>

                  <div className="flex justify-center items-center pt-2">
                    <Text weight="semibold" variant="secondary" size="md" as="span" className="">
                      {fontPairing.title}
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        </Transition>
      )}

      <div className="absolute bottom-0 left-0 w-full h-16 bg-white flex justify-center items-center border-t border-wb-primary">
        {isStepOne && (
          <Button
            variant="primary"
            onClick={() => setSearchParams({ step: '2' })}
            className="w-full max-w-sm"
            isDisabled={isDisabled}
            isLoading={false}
          >
            Next
          </Button>
        )}

        {isStepTwo && (
          <div className="flex gap-4 items-center w-full justify-center">
            <Button
              variant="outlined"
              onClick={() => setSearchParams({ step: '1' })}
              className="w-full max-w-[100px]"
              isDisabled={false}
              isLoading={false}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => setSearchParams({ step: '3' })}
              className="w-full max-w-sm"
              isDisabled={!selectedColor}
              isLoading={false}
            >
              Next
            </Button>
          </div>
        )}

        {isStepThree && (
          <div className="flex gap-4 items-center w-full justify-center">
            <Button
              variant="outlined"
              onClick={() => setSearchParams({ step: '2' })}
              className="w-full max-w-[100px]"
              isDisabled={false}
              isLoading={false}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={createSiteMutation}
              className="w-full max-w-sm"
              isDisabled={!selectedFontPairing}
              isLoading={createSite.isLoading}
            >
              {createSite.isLoading ? 'Creating Site...' : 'Start Building'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WebsiteOnboardingPage;
