import { Users } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { TransformSettings } from '../TransformSettings';

type Props = AttributeSettingProps & {};

export const AuthorsPostSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <PopoverHelper title="Authors" text="Authors" popoverTitle="Authors" Icon={Users}>
      <>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsEnabled"
          title="Enabled"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsImagesEnabled"
          title="Images"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsTextEnabled"
          title="Text"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsTruncateListEnabled"
          title="Truncate List"
        />
        {activeNodeAttributes?.authorsEnabled && (
          <>
            <ColorSettings
              title="Color"
              property="authorsTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
            />
            <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="authorsTextTransform" />
            <FontSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              isMarkStyle={false}
              property="authorsFontFamily"
            />
          </>
        )}
      </>
    </PopoverHelper>
  );
};

export default AuthorsPostSettings;
