import { useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { WebTheme } from '@/interfaces/web_template';

import { usePageContext } from '../Context/PageContext';
import ColorInput from '../Form/ColorInput';
import PropertiesGrouping from '../Helpers/PropertiesGrouping';
import SectionDivider from '../Helpers/SectionDivider';
import { FooterSectionIcon, SectionGroupingSlideOut } from '../Helpers/SectionGroupingSlideOut';
import { SettingsNote, SettingsNoteDescription, SettingsNoteLink } from '../Helpers/SettingsNote';

const GlobalFooterSection = () => {
  const { handleSaved, defaultColors } = usePageContext();

  const webTemplateMutation = useUpdateWebTemplate(() => handleSaved());
  const { data: webTemplate } = useWebTemplate();
  const webTheme: any = webTemplate?.web_theme_preview;
  const webThemWithDefaults: any = webTemplate?.web_theme_preview_with_defaults;

  const handleUpdateWebTheme = (key: string, value: string) => {
    webTemplateMutation.mutate({
      web_theme_preview: {
        ...(webTheme as WebTheme),
        [key]: value,
      },
    });
  };

  return (
    <SectionGroupingSlideOut
      title="Global Footer"
      sectionIcon={<FooterSectionIcon />}
      highlightProps={{
        sectionId: 'global-footer',
      }}
    >
      <PropertiesGrouping label="Bottom Footer Bar" indent>
        <ColorInput
          label="Background Color"
          color={webThemWithDefaults?.footer_background_color}
          resettable={{
            label: 'Global Primary Color',
            resetValue: null,
            check: webTheme?.footer_background_color !== null,
          }}
          onSave={(value: string) => handleUpdateWebTheme('footer_background_color', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Text Color"
          color={webThemWithDefaults?.footer_text_color}
          resettable={{
            label: 'Global Text on Primary Color',
            resetValue: null,
            check: webTheme?.footer_background_color !== null,
          }}
          onSave={(value: string) => handleUpdateWebTheme('footer_text_color', value)}
          defaultColors={defaultColors}
        />
      </PropertiesGrouping>

      <PropertiesGrouping label="External Pages" indent>
        <ColorInput
          label="Font Color"
          color={webThemWithDefaults?.footer_external_link_color}
          onSave={(value: string) => handleUpdateWebTheme('footer_external_link_color', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Underline Color"
          color={webThemWithDefaults?.footer_external_link_underline_color}
          onSave={(value: string) => handleUpdateWebTheme('footer_external_link_underline_color', value)}
          defaultColors={defaultColors}
        />
      </PropertiesGrouping>

      <SectionDivider />

      <SettingsNote label="Social Icons">
        <SettingsNoteDescription>
          Connected social account links are pulled in from your{' '}
          <SettingsNoteLink to="/website_builder/settings/social_links">settings tab</SettingsNoteLink> section.
        </SettingsNoteDescription>
      </SettingsNote>

      <SectionDivider />

      <SettingsNote label="ToS & Privacy">
        <SettingsNoteDescription>
          Terms of Service & Privacy are pulled in from your{' '}
          <SettingsNoteLink to="/website_builder/settings/pixels">settings tab</SettingsNoteLink> section.
        </SettingsNoteDescription>
      </SettingsNote>
    </SectionGroupingSlideOut>
  );
};

export default GlobalFooterSection;
