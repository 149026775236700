import { InformationCircleIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import { Typography, TypographyStack } from '@/components/Typography';
import useBoostsFrozen from '@/hooks/boosts/useBoostsFrozen';
import { BoostSend } from '@/interfaces/boosts/grow/boost_send';
import { Button } from '@/ui/Button';
import { IconContainer } from '@/ui/IconContainer';

export interface Props {
  isOpen: boolean;
  onClickAccept: () => void;
  onClickReject: () => void;
  onClose: () => void;
  boostSend: BoostSend;
}

const ReviewBoostSendModal = ({ isOpen, boostSend, onClickReject, onClickAccept, onClose }: Props) => {
  const { max_spend: maxSpend } = boostSend;
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen;

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      onProceed={onClickAccept}
      disabled={Boolean(boostsFrozen)}
      resourceId={boostSend.id}
      isWorking={false}
      actionText="I understand"
      footer={
        <Button type="button" variant="danger" shade="dark" onClick={onClickReject} disabled={Boolean(boostsFrozen)}>
          Reject
        </Button>
      }
    >
      <div className="flex gap-x-4">
        <IconContainer backgroundColor="bg-feedback-info-50" iconColor="text-feedback-info-400">
          <InformationCircleIcon />
        </IconContainer>
        <TypographyStack gap="2">
          <Typography token="font-medium/text/lg">
            Accept Email Boost from {boostSend.recommending_publication.name}
          </Typography>
          <Typography token="font-normal/text/sm" colorWeight="700">
            When you accept this application{' '}
            <Typography as="span" token="font-normal/text/sm" colorWeight="700" color="info">
              {maxSpend} will move to into escrow for the next 72 hours
            </Typography>{' '}
            while {boostSend.recommending_publication.name} includes your Boost in an upcoming post.
          </Typography>
          <Typography token="font-normal/text/sm" colorWeight="700">
            After 72 hours any unused funds will be immediately returned to your wallet.
          </Typography>
        </TypographyStack>
      </div>
    </ActionModal>
  );
};

export default ReviewBoostSendModal;
