import { ElementType } from 'react';
import cx from 'classnames';

interface Props {
  type?: 'solid' | 'ghost';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'warning' | 'danger' | 'success' | 'info';
  Icon: ElementType;
  size?: 'sm' | 'md' | 'lg';
}

const COLOR_MAP: any = Object.freeze({
  ghost: {
    primary: {
      iconColor: 'text-action-primary-900',
      bgColor: 'bg-action-primary-50',
    },
    secondary: {
      iconColor: 'text-action-secondary-900 ',
      bgColor: 'bg-action-secondary-50',
    },
    tertiary: {
      iconColor: 'text-action-tertiary-900 ',
      bgColor: 'bg-action-tertiary-50',
    },
    warning: {
      iconColor: 'text-action-warning-900 ',
      bgColor: 'bg-action-warning-50',
    },
    danger: {
      iconColor: 'text-action-danger-900 ',
      bgColor: 'bg-action-danger-50',
    },
    success: {
      iconColor: 'text-action-success-900 ',
      bgColor: 'bg-action-success-50',
    },
    info: {
      iconColor: 'text-action-info-900 ',
      bgColor: 'bg-action-info-50',
    },
  },
  solid: {
    primary: {
      iconColor: 'text-white',
      bgColor: 'bg-action-primary-600',
    },
    secondary: {
      iconColor: 'text-white',
      bgColor: 'bg-action-secondary-600',
    },
    tertiary: {
      iconColor: 'text-white',
      bgColor: 'bg-action-tertiary-600',
    },
    warning: {
      iconColor: 'text-white',
      bgColor: 'bg-action-warning-600',
    },
    danger: {
      iconColor: 'text-white',
      bgColor: 'bg-action-danger-600',
    },
    success: {
      iconColor: 'text-white',
      bgColor: 'bg-action-success-600',
    },
    info: {
      iconColor: 'text-white',
      bgColor: 'bg-action-info-600',
    },
  },
});

const SIZE_MAP: any = Object.freeze({
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
  lg: 'w-6 h-6',
  xl: 'w-10 h-10',
});

const IconWrapper = ({ variant = 'secondary', size = 'md', Icon, type = 'ghost' }: Props) => {
  return (
    <div>
      <div className={cx('rounded-full p-2', COLOR_MAP[type][variant].bgColor)}>
        <Icon className={cx(SIZE_MAP[size], COLOR_MAP[type][variant].iconColor)} />
      </div>
    </div>
  );
};

export default IconWrapper;
