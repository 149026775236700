import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkIndustry } from '../../../interfaces/ad_network/internal/industry';
import api from '../../../services/swarm';

interface SuccessResponse extends AdNetworkIndustry {}
interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

export default function useAdvertiserCreate() {
  const navigate = useNavigate();

  return useMutation<SuccessResponse, ErrorResponse, FormData>(
    (formData) => api.post('/ad_network/internal/advertisers', formData).then((res) => res.data),
    {
      onSuccess: () => {
        toast.success('Advertiser created successfully');

        navigate('/ad_network/advertisers');
      },
      onError: (error) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      },
    }
  );
}
