import { useCallback, useState } from 'react';
import { Editor } from '@tiptap/core';

import CustomColorPanel from '@/components/TiptapEditor/components/panels/CustomColor';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelFooter, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { useThemeData } from '@/components/TiptapEditor/lib/hooks/useThemeData';

import { useImageBlockOptions } from '../hooks';

import { BorderColor } from './BorderColor';
import { BorderRadius } from './BorderRadius';
import { BorderStyle } from './BorderStyle';
import { BorderWidth } from './BorderWidth';

export const BordersPanel = ({ editor, onBack }: { editor: Editor; onBack: Function }) => {
  const [isCustomBorderColorPanelOpen, setIsCustomBorderColorPanelOpen] = useState<boolean>(false);

  const attrs = editor.getAttributes('imageBlock');

  const onSetCustomBorderColor = useCallback(
    (color: string) => {
      editor.commands.setImageBlockBorderColor(color);
    },
    [editor]
  );

  const themeColors = useThemeData('colors');
  const { hasBorderOverrides } = useImageBlockOptions(attrs, themeColors);

  const onResetBorders = useCallback(() => {
    editor
      .chain()
      .setIndividualImageBlockBorderRadius(false)
      .setImageBlockBorderRadius(0)
      .setIndividualImageBlockBorderWidth(false)
      .setImageBlockBorderWidth(0)
      .setImageBlockBorderColor(null)
      .setImageBlockBorderStyle('solid')
      .run();
  }, [editor]);

  const renderDefaultPanel = () => (
    <Panel>
      <PanelHeader>
        <Button $variant="quaternary" $size="small" $leftSlot={<Icon name="ChevronLeft" />} onClick={onBack} $fullWidth>
          Borders
        </Button>
      </PanelHeader>
      <PanelSection>
        <BorderRadius editor={editor} />
        <BorderWidth editor={editor} />
        <BorderStyle editor={editor} />
        <BorderColor editor={editor} onShowCustomBorderColorPanel={() => setIsCustomBorderColorPanelOpen(true)} />
      </PanelSection>
      {hasBorderOverrides && (
        <PanelFooter>
          <Button
            $variant="quaternary"
            $size="small"
            $rightSlot={<Icon name="Reset" />}
            onClick={onResetBorders}
            $fullWidth
            $active
            $muted
          >
            Reset all
          </Button>
        </PanelFooter>
      )}
    </Panel>
  );

  const renderCustomBorderColorPanel = () => (
    <CustomColorPanel
      selectedColor={attrs.borderColor}
      onBack={() => setIsCustomBorderColorPanelOpen(false)}
      onSelect={onSetCustomBorderColor}
    />
  );

  if (isCustomBorderColorPanelOpen) {
    return renderCustomBorderColorPanel();
  }

  return renderDefaultPanel();
};

export default BordersPanel;
