import { Outlet } from 'react-router-dom';

const CurrentUserSettingsLayout: React.FC = () => {
  return (
    <div className="flex flex-row w-full justify-center">
      <Outlet />
    </div>
  );
};

export default CurrentUserSettingsLayout;
