import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import { TableCol, TableRow } from '@/components/Table';
import { Typography } from '@/components/Typography';
import { useCopyToClipboard } from '@/hooks';
import { PartnerLink } from '@/interfaces/partner_program/partner_link';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

const LinkRow = ({ link, updateLink }: { link: PartnerLink; updateLink: (link: PartnerLink) => void }) => {
  const { id, url, token, visitors, leads, conversions } = link;
  const copy = useCopyToClipboard();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(token);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleUpdateLink = async () => {
    setIsSaving(true);

    api
      .patch(`/partner_link/${id}`, {
        affiliate_link: {
          url,
          token: inputValue,
        },
      })
      .then((resp) => {
        const updatedLink = resp.data.resource;
        updateLink(updatedLink);
        toast.success('Link updated!');
        setIsEditing(false);
      })
      .catch((errPayload) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setIsSaving(false);
      });

    setIsSaving(false);
  };

  const handleClose = () => {
    setIsEditing(false);
    setInputValue(token);
  };

  return (
    <>
      <ActionModal
        isOpen={isEditing}
        onClose={handleClose}
        onProceed={handleUpdateLink}
        resourceId={id}
        isWorking={isSaving}
        headerText="Careful!"
        descriptionText="If you change your link, your old link will no longer work. Be sure to replace any use of your old link (blog posts, articles, etc.) with your new link."
        actionText="Update"
        modalMessageType="warning"
      >
        <div className="flex flex-col space-y-2 mt-6 mb-4 w-full">
          <Typography token="font-medium/text/sm">
            <span className="text-gray-900">Edit Link</span>
          </Typography>
          <Input
            name={`link-${id}`}
            placeholder={token}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            readOnlyText={`${url.split('?via=')[0]}?via=`}
            className="w-full"
          />
          <Typography token="font-light/text/xs">
            <span className="text-gray-900">Please note that the old link will immediately stop tracking visits.</span>
          </Typography>
        </div>
      </ActionModal>
      <TableRow key={url}>
        <TableCol>
          <div className="flex flex-col space-y-2">
            <p className="text-sm text-[#DB2777] font-medium">{url}</p>
            <p className="text-gray-500 text-xs">
              Link to any page on www.beehiiv.com by adding ?via={token} to the end of the URL
            </p>
          </div>
        </TableCol>
        <TableCol className="text-sm text-gray-700">{visitors}</TableCol>
        <TableCol className="text-sm text-gray-700">{leads}</TableCol>
        <TableCol className="text-sm text-gray-700">{conversions}</TableCol>
        <TableCol className="text-sm text-gray-700">
          <div className="flex flex-row space-x-2 w-full justify-end">
            <Button
              onClick={() => {
                setIsEditing(true);
              }}
              variant="primary-inverse"
              size="xs"
              type="button"
            >
              Edit Link
            </Button>
            <button
              className="py-1 px-3 bg-[#EEF2FF] hover:bg-[#E0E7FF] flex flex-row justify-center items-center space-x-1 rounded-md border border-[#C7D2FE]"
              type="button"
              onClick={() => {
                copy({ text: url, onSuccessText: 'Copied to clipboard!' });
              }}
            >
              <p className="text-xs lg:text-sm text-[#4338CA] font-medium my-auto">Copy Link</p>
            </button>
          </div>
        </TableCol>
      </TableRow>
    </>
  );
};

export default LinkRow;
