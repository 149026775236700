import { ArrowPathIcon } from '@heroicons/react/20/solid';

import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { useBoostSends } from '@/hooks/boosts/grow/useBoostSends';
import { BoostSend, BoostSendStatus } from '@/interfaces/boosts/grow/boost_send';
import { OrderBy, SpendOrderBy } from '@/interfaces/boosts/order';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListFilters, useResourceListFilters } from '@/ui/ResourceListFilters';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import { orderByOptions, spendOrderByOptions } from '../Dashboard/constants';

import BoostSendListItem from './BoostSendListItem';

interface Props {
  recommendingPublicationId?: string;
  boostOfferId?: string;
  isSecondary?: boolean;
  showFilters?: boolean;
  showCPA?: boolean;
  showSendingMetrics?: boolean;
  showSpendMetrics?: boolean;
  showSearch?: boolean;
}

const filterOptions = [
  { label: 'All', value: '' },
  { label: 'Live', value: BoostSendStatus.ACCEPTED },
  { label: 'Pending', value: BoostSendStatus.PENDING },
  { label: 'Cancelled', value: BoostSendStatus.CANCELED },
  { label: 'Rejected', value: BoostSendStatus.REJECTED },
];

const BoostSendsList = ({
  boostOfferId,
  recommendingPublicationId,
  showFilters = true,
  isSecondary = false,
  showCPA = false,
  showSendingMetrics = false,
  showSpendMetrics = true,
  showSearch = true,
}: Props) => {
  const {
    hasFilter,
    resetAllFilters,
    search,
    setSearch,
    filter,
    setFilter,
    orderBy,
    setOrderBy,
    direction,
    handleClickOrderButton,
  } = useResourceListFilters<string, string>({
    defaultFilter: '',
    defaultSortOrder: SpendOrderBy.TOTAL_SPEND
  });

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } = useBoostSends({
    boostOfferId,
    recommendingPublicationId,
    status: filter,
    search,
    direction,
    orderBy,
  });
  const boostSends = (data?.pages.flatMap((page) => page.boost_sends) as BoostSend[]) || [];
  const hasNoResults = boostSends.length === 0;
  const totalCount = data?.pages[0]?.pagination?.total || 0;
  const showingCount = boostSends?.length || 0;

  const title = hasFilter
    ? `No results found for current filters`
    : 'There are no Publications Boosting the Offer at the moment';
  const primaryActionLabel = hasFilter ? 'Reset Filters' : 'New Offer';
  const onPrimaryActionClick = hasFilter ? resetAllFilters : undefined;
  const primaryActionIcon = hasFilter ? ArrowPathIcon : undefined;

  return (
    <div className="flex flex-col gap-y-4">
      {showFilters && (
        <div className="flex justify-end w-full">
          <ResourceListFilters
            search={showSearch ? search : undefined}
            setSearch={showSearch ? setSearch : undefined}
            searchPlaceholder="Search Publications"
            filter={filter}
            setFilter={setFilter}
            filterOptions={filterOptions}
            sortOrderOptions={showSpendMetrics ? [...orderByOptions, ...spendOrderByOptions] : orderByOptions}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
        </div>
      )}
      <div className="flex justify-start">
        <ResourceListSearchText showingCount={showingCount} totalCount={totalCount} />
      </div>
      {hasNoResults && !isLoading && (
        <EmptyCard
          title={title}
          primaryActionIcon={primaryActionIcon}
          primaryActionLabel={primaryActionLabel}
          onPrimaryActionClick={onPrimaryActionClick}
        />
      )}
      {!hasNoResults && (
        <Items isSecondary={isSecondary}>
          <ItemHeaders isSecondary={isSecondary}>
            <ItemHeader>{showCPA ? 'CPA' : 'Publication Name'}</ItemHeader>
            <ItemHeader>Status</ItemHeader>
            {showSendingMetrics && (
              <>
                <ItemHeader>Open rate %</ItemHeader>
                <ItemHeader>Click through rate %</ItemHeader>
                <ItemHeader>Unsubscribe rate %</ItemHeader>
                <ItemHeader>Acceptance rate %</ItemHeader>
              </>
            )}
            <ItemHeader
              onClick={handleClickOrderButton}
              orderBy={OrderBy.PENDING_SUBSCRIBER_COUNT}
              isSorting={orderBy === OrderBy.PENDING_SUBSCRIBER_COUNT}
              currentDirection={direction}
            >
              Pending subscribers
            </ItemHeader>
            <ItemHeader
              onClick={handleClickOrderButton}
              orderBy={OrderBy.ACCEPTED_SUBSCRIBER_COUNT}
              isSorting={orderBy === OrderBy.ACCEPTED_SUBSCRIBER_COUNT}
              currentDirection={direction}
            >
              Verified subscribers
            </ItemHeader>
            <ItemHeader
              onClick={handleClickOrderButton}
              orderBy={OrderBy.TOTAL_SUBSCRIBER_COUNT}
              isSorting={orderBy === OrderBy.TOTAL_SUBSCRIBER_COUNT}
              currentDirection={direction}
            >
              Total subscribers
            </ItemHeader>
            {showSpendMetrics && (
              <>
                <ItemHeader
                  onClick={handleClickOrderButton}
                  orderBy={SpendOrderBy.PENDING_SPEND}
                  isSorting={orderBy === SpendOrderBy.PENDING_SPEND}
                  currentDirection={direction}
                >
                  Pending spend
                </ItemHeader>
                <ItemHeader
                  onClick={handleClickOrderButton}
                  orderBy={SpendOrderBy.TOTAL_SPEND}
                  isSorting={orderBy === SpendOrderBy.TOTAL_SPEND}
                  currentDirection={direction}
                >
                  Total spend
                </ItemHeader>
              </>
            )}
            <ItemHeader isSticky>&nbsp;</ItemHeader>
          </ItemHeaders>

          <ItemsBody>
            {boostSends.map((send) => (
              <BoostSendListItem
                key={send.id}
                boostSend={send}
                showCPA={showCPA}
                showSendingMetrics={showSendingMetrics}
                showSpendMetrics={showSpendMetrics}
              />
            ))}
          </ItemsBody>
        </Items>
      )}
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </div>
  );
};

export default BoostSendsList;
