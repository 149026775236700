import { TopLineMetrics } from '../top_line_metrics';

export enum BoostOfferStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DRAFT = 'draft',
  INACTIVE = 'inactive',
  LIVE = 'live',
}

export enum VerificationMode {
  STANDARD = 'standard',
  RELAXED = 'relaxed',
}

// Defines possible user actions on a BoostOffer for each status.
export const BoostOfferStatusTransitions: any = {
  [BoostOfferStatus.DRAFT]: [BoostOfferStatus.LIVE, BoostOfferStatus.ARCHIVED],
  [BoostOfferStatus.LIVE]: [BoostOfferStatus.INACTIVE, BoostOfferStatus.ARCHIVED],
  [BoostOfferStatus.ACTIVE]: [BoostOfferStatus.LIVE, BoostOfferStatus.INACTIVE, BoostOfferStatus.ARCHIVED],
  [BoostOfferStatus.INACTIVE]: [BoostOfferStatus.LIVE, BoostOfferStatus.ARCHIVED],
  [BoostOfferStatus.ARCHIVED]: [],
};

// Defines the labels for each user action on a BoostOffer for each status.
export const BoostOfferTransitionLabels: any = {
  [BoostOfferStatus.LIVE]: 'Publish',
  [BoostOfferStatus.ACTIVE]: 'Activate',
  [BoostOfferStatus.INACTIVE]: 'Disable',
  [BoostOfferStatus.ARCHIVED]: 'Archive',
};

/**
 * @see `app/views/api/v2/boosts/grow/_boost_offer.json.jbuilder`
 */
export interface BoostOffer {
  id: string;
  name: string;
  updated_at: string;
  created_at: string;
  status: BoostOfferStatus;
  all_time_growth_count: number;
  top_line_metrics: TopLineMetrics;
  pending_application_count: number;
  description: string | null;
  cost_per_lead_cents: number;
  max_spend_cents: number;
  over_budget_at: Date | null;
  verification_mode: VerificationMode;
  remove_rejected: boolean;
  application_url: string | null;
  eligible_countries: string[] | null;
}
