import { useCallback, useEffect, useState } from 'react';
import {
  NAVBAR_MENU_ITEM_BUTTON_DEFAULTS,
  NAVBAR_MENU_ITEM_LINK_DEFAULTS,
  NAVBAR_MENU_ITEM_LOGO_DEFAULTS,
  NAVBAR_MENU_ITEM_SEARCH_DEFAULTS,
} from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { BorderRadiusSettings } from '../general/BorderRadiusSettings';
import BorderSettings from '../general/BorderSettings';
import { ColorSettings } from '../general/ColorSettings';
import { PaddingSettings } from '../general/PaddingSettings';

import { FontSettings } from './FontSettings';
import { LogoSrcSettings } from './LogoSrcSettings';

export function NavItemStylingSettings() {
  const { selectedContent } = useNavbarContext();
  const [defaults, setDefaults] = useState<Record<string, string>>(NAVBAR_MENU_ITEM_LINK_DEFAULTS);

  const getDefaults = useCallback((): Record<string, any> => {
    if (!selectedContent || !selectedContent.attrs?.id) return NAVBAR_MENU_ITEM_LINK_DEFAULTS;
    if (selectedContent.type !== 'navbar_item') return NAVBAR_MENU_ITEM_LINK_DEFAULTS;
    if (selectedContent.attrs?.type === 'button') return NAVBAR_MENU_ITEM_BUTTON_DEFAULTS;
    if (selectedContent.attrs?.type === 'link') return NAVBAR_MENU_ITEM_LINK_DEFAULTS;
    if (selectedContent.attrs?.type === 'search') return NAVBAR_MENU_ITEM_SEARCH_DEFAULTS;
    if (selectedContent.attrs?.type === 'logo') return NAVBAR_MENU_ITEM_LOGO_DEFAULTS;
    if (selectedContent.attrs?.type === 'user_button') return NAVBAR_MENU_ITEM_BUTTON_DEFAULTS;
    return NAVBAR_MENU_ITEM_LINK_DEFAULTS;
  }, [selectedContent]);

  useEffect(() => {
    setDefaults(getDefaults());
  }, [selectedContent, getDefaults]);

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  return (
    <SectionRenderer title="Styling">
      {selectedContent.attrs?.type !== 'logo' && <FontSettings />}
      {selectedContent.attrs?.type !== 'logo' && (
        <ColorSettings title="Text" property="color" selectedContent={selectedContent} defaultValue={defaults.color} />
      )}
      {(selectedContent.attrs?.type === 'button' || selectedContent.attrs?.type === 'user_button') && (
        <ColorSettings
          title="Fill"
          property="background"
          selectedContent={selectedContent}
          defaultValue={defaults.background}
        />
      )}
      {selectedContent.attrs?.type === 'logo' && <LogoSrcSettings />}
      {selectedContent.attrs?.type !== 'logo' && (
        <PaddingSettings selectedContent={selectedContent} attribute="padding" defaultValue={defaults?.padding} />
      )}
      {(selectedContent.attrs?.type === 'button' || selectedContent.attrs?.type === 'user_button') && (
        <BorderSettings
          defaults={{
            borderRadius: NAVBAR_MENU_ITEM_BUTTON_DEFAULTS.borderRadius,
            borderWidth: NAVBAR_MENU_ITEM_BUTTON_DEFAULTS.borderWidth,
            borderStyle: NAVBAR_MENU_ITEM_BUTTON_DEFAULTS.borderStyle,
            borderColor: NAVBAR_MENU_ITEM_BUTTON_DEFAULTS.borderColor,
          }}
        />
      )}
      {!['logo', 'button', 'user_button'].includes(selectedContent.attrs?.type || '') && (
        <BorderRadiusSettings
          defaults={{
            borderRadius: defaults.borderRadius,
          }}
        />
      )}
    </SectionRenderer>
  );
}
