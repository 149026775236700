import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  testimonialId: string;
  onSuccess: () => void;
}

interface TestimonialPayload {
  name: string;
  description?: string;
  message: string;
  picture_url?: string;
}

const useUpdateTestimonial = ({ testimonialId, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    ({ name, description, message }: TestimonialPayload) =>
      api
        .patch(`/testimonials/${testimonialId}`, {
          publication_id: currentPublicationId,
          testimonial: {
            name,
            description,
            message,
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useUpdateTestimonial;
