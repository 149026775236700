import { useEffect, useMemo, useState } from 'react';
import { ArrowLeft, MagnifyingGlass, X } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { useDebounce } from 'use-debounce';

import useInfinitePosts from '@/hooks/useDreamBuilder/useInfinitePosts';
import { PostPreview } from '@/interfaces/post';
import Callout from '@/routes/website/_components/UI/Callout';

import { cn } from '../../../../../_utils/cn';
import { Button } from '../../../../UI/Button';
import { Checkbox } from '../../../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Input } from '../../../../UI/Input';
import { Label } from '../../../../UI/Label';
import { Switch } from '../../../../UI/Switch';
import { Text } from '../../../../UI/Text';
import { selectRandomPosts } from '../../../dummyData/posts';

import PostTypeSelect from './PostTypeSelect';

const fakePosts = selectRandomPosts({
  count: 9,
});

const title = 'Select posts to display';

const callout: Record<string, { title: string; description: string }> = {
  latest: {
    title: 'Previewing Latest Posts',
    description: `We'll display the latest posts that you have published.`,
  },
  archive: {
    title: 'Previewing Archive Posts',
    description: `We'll display all of your posts that you have published.`,
  },
  categories: {
    title: 'Previewing Posts by Category',
    description: `We'll display all of your posts that match the selected category.`,
  },
};

const PostsSelectionModal = ({
  editor,
  node,
  isOpen,
  onClose,
  pos,
}: {
  editor: Editor;
  node: ProseMirrorNode;
  isOpen: boolean;
  onClose: () => void;
  pos: number;
}) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [selectedPosts, setSelectedPosts] = useState<PostPreview[] | any[]>([]);
  const [isLayoutOpen, setIsLayoutOpen] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [postType, setPostType] = useState<'free_selection' | 'latest' | 'archive' | any>('free_selection');
  const [count, setCount] = useState(5);
  const [showFakeData, setShowFakeData] = useState(false);

  const reset = () => {
    setSelectedPosts([]);
    setCount(5);
  };

  useEffect(() => {
    reset();
  }, [isOpen, postType]);

  const { data } = useInfinitePosts({
    enabled: isOpen,
    tags: isCategorySelected ? [postType] : undefined,
    q: debouncedSearch,
  });
  const posts = showFakeData ? fakePosts : data?.pages.flatMap((page) => page.posts) || [];

  const selectedPostsIds = useMemo(() => selectedPosts.map((post) => post.id), [selectedPosts]);

  const handleSubmit = () => {
    const handlePostsSelection = () => {
      const isArchive = postType === 'archive';
      const isLatest = postType === 'latest';

      if (isArchive) {
        return posts;
      }

      let selectedPostsToSave = selectedPosts;

      if (isLatest) {
        // We bypasse selection in this case
        selectedPostsToSave = posts.slice(0, count);
      }

      if (!isCategorySelected) {
        return selectedPostsToSave;
      }

      return selectedPostsToSave.map((post, index) => ({
        ...post,
        content_tags: [{ id: `${postType}-${index}`, display: postType }],
      }));
    };

    const newPostData = handlePostsSelection();

    const updatedAttributes = {
      ...node?.attrs,
      insertedFromSidebar: false,
      data: {
        posts: newPostData,
      },
    };

    editor?.commands.command(({ tr }) => {
      tr.setNodeAttribute(pos, 'insertedFromSidebar', false);
      tr.setNodeAttribute(pos, 'hasFakeData', false);
      tr.setNodeAttribute(pos, 'data', updatedAttributes.data);
      return true;
    });

    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) {
          onClose();
        }
      }}
    >
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col overflow-hidden p-0 gap-0">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2 p-4">
              {isLayoutOpen && <Button variant="ghost" onClick={() => setIsLayoutOpen(false)} LeftIcon={ArrowLeft} />}
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                {title}
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        {/** PREFERENCES SECTION */}

        <div className="flex flex-col gap-4 px-4 py-2">
          {/** avoids fetching options when modal is closed */}
          {isOpen && (
            <PostTypeSelect
              setIsCategorySelected={setIsCategorySelected}
              setPostType={setPostType}
              node={node}
              editor={editor}
              pos={pos}
              postType={postType}
              count={count}
              setCount={setCount}
              showFakeData={showFakeData}
            />
          )}
        </div>

        <div className="flex flex-col gap-2 w-full px-4 py-2">
          <div className="flex gap-2 flex-wrap">
            {selectedPosts.map((post) => (
              <button
                type="button"
                key={post.id}
                className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1"
                onClick={() => setSelectedPosts(selectedPosts.filter((p) => p.id !== post.id))}
              >
                <Text
                  weight="regular"
                  variant="accent"
                  size="3xs"
                  as="span"
                  className="line-clamp-1 max-w-[150px] truncate"
                >
                  {post.web_title}
                </Text>
                <X className="w-3 h-3" />
              </button>
            ))}
          </div>
        </div>
        {postType !== 'free_selection' && (
          <div className="flex px-4 py-2 w-full">
            <Callout
              title={isCategorySelected ? 'Category preview' : callout[postType]?.title}
              className="w-full p-4 max-w-full"
            >
              {isCategorySelected
                ? 'We will display all posts that match the selected category.'
                : callout[postType]?.description}
            </Callout>
          </div>
        )}

        <div className="flex flex-1 flex-col gap-4 w-full min-h-0 px-4 py-2">
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold" variant="secondary" as="p">
              {postType === 'free_selection' ? 'Posts to show' : 'Posts preview'}
            </Text>

            <Input
              name="name"
              className="max-w-[250px]"
              LeftIcon={MagnifyingGlass}
              type="text"
              placeholder="Search posts"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="flex-1 overflow-y-auto no-scrollbar">
            {posts.length === 0 ? (
              <div className="flex items-center justify-center h-full py-20">
                <Text size="xl" weight="semibold" variant="secondary" as="h4">
                  No posts found
                </Text>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-32">
                {posts.map((post, index) => {
                  const isSelected = selectedPostsIds.includes(post.id);

                  return (
                    <Label
                      key={post.id}
                      htmlFor={post.id}
                      className={cn(
                        'flex flex-col cursor-pointer border shadow p-0 rounded-md focus:outline-none relative overflow-hidden',
                        isSelected ? 'border-wb-accent border-2' : 'border-wb-primary',
                        postType === 'latest' && index >= count ? 'opacity-50' : '',
                        postType !== 'free_selection' && !isCategorySelected
                          ? 'cursor-auto'
                          : 'cursor-pointer hover:shadow-md'
                      )}
                    >
                      <div className="w-full h-36 overflow-hidden">
                        <img
                          src={post.image_url}
                          alt={post.web_title}
                          className="w-full h-full object-cover aspect-video"
                        />
                      </div>
                      <div className="flex justify-between items-center gap-2 p-3">
                        <div className="flex flex-col">
                          <Text weight="semibold" variant="primary" size="xs" as="span" className="line-clamp-1">
                            {post.web_title}
                          </Text>
                          <Text weight="regular" variant="secondary" size="2xs" as="span" className="line-clamp-1">
                            {post.web_subtitle}
                          </Text>
                        </div>
                        {(postType === 'free_selection' || isCategorySelected) && (
                          <Checkbox
                            id={post.id}
                            checked={Boolean(isSelected)}
                            onCheckedChange={() => {
                              if (isSelected) {
                                setSelectedPosts(selectedPosts.filter((p) => p.id !== post.id));
                              } else {
                                setSelectedPosts([...selectedPosts, post]);
                              }
                            }}
                          />
                        )}
                      </div>
                    </Label>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center p-4 bg-white border-t border-wb-primary">
          <div className="flex items-center gap-2 justify-between w-full">
            <Switch
              id="show-fake-data"
              checked={showFakeData}
              onCheckedChange={() => setShowFakeData(!showFakeData)}
              labelText="Dummy data"
              labelClassName="whitespace-nowrap"
            />
            <div className="flex gap-2 w-full justify-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                {postType === 'free_selection' ? 'Select posts' : 'Save'}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PostsSelectionModal;
