import { useMemo } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import Text from '@/components/Text';
import { useForm } from '@/hooks/useForms';
import { AutomationCondition, AutomationConditionName } from '@/interfaces/automations/automation_condition';
import { AutomationTriggerEvent } from '@/interfaces/automations/automation_trigger';
import { FormStatus } from '@/interfaces/form';

const WARNINGS = {
  SIGNUP: {
    CUSTOM_FIELDS_PRESENT: () =>
      `Subscribers might signup prior to having Custom Field values. You might want to use the&nbsp;<strong>Survey Submitted</strong>&nbsp;Trigger instead.`,
  },
  FORM_SUBMISSION: {
    FORM_NOT_LIVE: (formTitle: string) =>
      `The <strong>${formTitle}</strong> Survey is not live. Make sure that the Survey is live before activating the trigger.`,
    UNMATCHED_CUSTOM_FIELDS: (formTitle: string) =>
      `You&apos;ve selected Custom Fields that are not present as a question in the <strong>${formTitle}</strong> Survey. Make sure to include all the Custom Fields as survey questions.`,
  },
};

interface TriggerWarningsProps {
  event: AutomationTriggerEvent;
  mainConditions: AutomationCondition[];
  conditions: AutomationCondition[];
}

const TriggerWarnings = ({ event, mainConditions, conditions }: TriggerWarningsProps) => {
  const formCondition = mainConditions?.find((condition) => condition.name === AutomationConditionName.FORM_ID);
  const { data: form } = useForm(formCondition?.value || '');
  const isFormLive = form?.status === FormStatus.LIVE;

  const warning = useMemo(() => {
    switch (event) {
      case AutomationTriggerEvent.FORM_SUBMISSION: {
        // Don't show any warnings if the form condition is set to 'any', which includes the publication websites signup form
        if (formCondition?.value === 'any') return null;

        if (!isFormLive) return WARNINGS.FORM_SUBMISSION.FORM_NOT_LIVE(form?.title || '');

        // Check if the form has all the custom fields that are used in the conditions
        const questions = form?.form_questions || [];
        const unmatchedCustomFields = conditions
          .filter((condition) => condition.name === AutomationConditionName.CUSTOM_FIELD_ID)
          .filter((condition) => condition.resource_id !== undefined && condition.resource_id !== '')
          .filter((condition) => !questions.some((question) => question.custom_field.id === condition.resource_id));
        if (unmatchedCustomFields.length > 0)
          return WARNINGS.FORM_SUBMISSION.UNMATCHED_CUSTOM_FIELDS(form?.title || '');

        return null;
      }
      case AutomationTriggerEvent.SIGNUP:
        if (conditions.some((condition) => condition.name === 'custom_field_id')) {
          return WARNINGS.SIGNUP.CUSTOM_FIELDS_PRESENT();
        }
        return null;
      default:
        return null;
    }
  }, [event, conditions, formCondition, isFormLive, form?.title, form?.form_questions]);

  if (warning === null) {
    return null;
  }

  return (
    <Text className="text-xs text-gray-500">
      <ExclamationCircleIcon className="w-4 h-4 mr-0.5 text-yellow-500 font-medium inline -mt-0.5" />
      <strong className="text-yellow-500 font-medium">Warning:</strong>
      &nbsp;
      {/* eslint-disable react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: warning }} />
    </Text>
  );
};

export default TriggerWarnings;
