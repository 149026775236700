import React, { FC, useState } from 'react';

import SubscriberTagSelectInput from '@/components/SubscriberTags/SubscriberTagSelectInput';
import { PublicationSubscriberTagReference, PublicationSubscriberTagSelectItem } from '@/interfaces/subscriber_tag';

interface Props {
  selectedTagId?: string;
  onChange: (value: string) => void;
}

const SubscriberTagSelect: FC<Props> = ({ selectedTagId, onChange }: Props) => {
  const [selectedTags, setSelectedTags] = selectedTagId
    ? useState<PublicationSubscriberTagReference[]>([{ id: selectedTagId }])
    : useState<PublicationSubscriberTagReference[]>([]);

  const handleDeselect = () => {
    setSelectedTags([]);
  };

  const handleSelect = (tag: PublicationSubscriberTagSelectItem) => {
    const selected: PublicationSubscriberTagReference = { id: tag.value };
    setSelectedTags([selected]);
    onChange(tag.value);
  };

  return (
    <SubscriberTagSelectInput
      selectValue="id"
      selectedTags={selectedTags}
      handleDeselect={handleDeselect}
      handleSelect={handleSelect}
    />
  );
};

export default SubscriberTagSelect;
