import Card from '@/components/Card';

type Props = {
  payload: any;
};

const Payload = ({ payload }: Props) => {
  return (
    <Card>
      <div className="space-y-2">
        <div className="text-gray-400">Payload</div>
        <div className="bg-gray-100 rounded-md p-6">
          <pre>
            <code className="language-json whitespace-pre-wrap">{JSON.stringify(payload, null, 2)}</code>
          </pre>
        </div>
      </div>
    </Card>
  );
};

export default Payload;
