import { useCallback, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import { LineDivider } from '@/components/LineDivider';
import { Attributes } from '@/components/TiptapEditor/components/panels/RSS/Attributes';
import { CTA } from '@/components/TiptapEditor/components/panels/RSS/CTA';
import { Layout } from '@/components/TiptapEditor/components/panels/RSS/Layout';
import { FieldSet } from '@/components/TiptapEditor/components/ui/Input';
import { Panel } from '@/components/TiptapEditor/components/ui/Panel/styled';
import API from '@/components/TiptapEditor/lib/api';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';

import { RSSEntryOrganizerPanel } from './RSSEntryOrganizerPanel';
import { PANEL } from './types';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const RSSBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const tippyInstance = useRef<Instance | null>(null);
  const [currentPanel, setCurrentPanel] = useState<PANEL>(PANEL.main);
  const { publicationId } = usePublicationContext();
  const { id: externalRssFeedId, data, settings } = editor.getAttributes('rss');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [organizeContentModalOpen, setOrganizeContentModalOpen] = useState(false);
  const [entriesToShow, setEntriesToShow] = useState(settings?.entriesToShow || 6);
  const [ctaText, setCtaText] = useState(settings?.ctaText || 'Read More');

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-rss');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('rss');

    return isActive;
  }, [editor]);

  const removeNode = useCallback(() => {
    editor.chain().focus().deleteSelection().run();
  }, [editor]);

  const clearSelection = useCallback(() => {
    editor.chain().updateAttributes('rss', { id: undefined, data: {} }).run();
  }, [editor]);

  const onUpdateEntriesToShow = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, entriesToShow: entriesToShow || 1 };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor, entriesToShow]);

  const onUpdateCtaText = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, ctaText };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor, ctaText]);

  const navigateToLayout = useCallback(() => {
    setCurrentPanel(PANEL.layout);
  }, []);

  const navigateToAttributes = useCallback(() => {
    setCurrentPanel(PANEL.attributes);
  }, []);

  const navigateToCTA = useCallback(() => {
    setCurrentPanel(PANEL.cta);
  }, []);

  let CurrentPanel = (
    <Panel style={{ width: '250px' }}>
      <FieldSet>
        <Button
          $variant="quaternary"
          $size="small"
          onClick={navigateToLayout}
          $fullWidth
          $leftSlot={<Icon name="TwoCol" />}
          $rightSlot={<Icon name="ChevronRight" />}
        >
          Layout
        </Button>
        <Button
          $variant="quaternary"
          $size="small"
          onClick={navigateToAttributes}
          $fullWidth
          $leftSlot={<Icon name="Browse" />}
          $rightSlot={<Icon name="ChevronRight" />}
        >
          Attributes
        </Button>
        <Button
          $variant="quaternary"
          $size="small"
          onClick={navigateToCTA}
          $fullWidth
          $leftSlot={<Icon name="CTA" />}
          $rightSlot={<Icon name="ChevronRight" />}
        >
          CTA
        </Button>
        <LineDivider className="opacity-10" />
        <RSSEntryOrganizerPanel
          editor={editor}
          data={data}
          settings={settings}
          setOrganizeContentModalOpen={setOrganizeContentModalOpen}
          organizeContentModalOpen={organizeContentModalOpen}
        />
      </FieldSet>
    </Panel>
  );

  if (currentPanel === PANEL.layout) {
    CurrentPanel = (
      <Layout
        editor={editor}
        onBack={() => setCurrentPanel(PANEL.main)}
        entriesToShow={entriesToShow}
        setEntriesToShow={setEntriesToShow}
        onUpdateEntriesToShow={onUpdateEntriesToShow}
      />
    );
  }

  if (currentPanel === PANEL.attributes) {
    CurrentPanel = <Attributes editor={editor} onBack={() => setCurrentPanel(PANEL.main)} />;
  }

  if (currentPanel === PANEL.cta) {
    CurrentPanel = (
      <CTA editor={editor} onBack={() => setCurrentPanel(PANEL.main)} ctaText={ctaText} setCtaText={setCtaText} />
    );
  }

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`rssBlock-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        plugins: [sticky],
        sticky: 'reference',
      }}
    >
      <Toolbar ref={menuRef} shouldShowContent={shouldShow()}>
        {externalRssFeedId && (
          <>
            <Tippy
              offset={[0, 8]}
              placement="bottom-start"
              reference={menuRef.current}
              visible={isSettingsOpen && menuRef.current !== null}
              interactive
              onClickOutside={() => {
                if (!organizeContentModalOpen) {
                  onUpdateEntriesToShow();
                  onUpdateCtaText();
                  setCurrentPanel(PANEL.main);
                  setIsSettingsOpen(false);
                }
              }}
              render={() => isSettingsOpen && CurrentPanel}
            >
              <Tooltip title="Settings">
                <Button
                  $leftSlot={<Icon name="Settings" />}
                  onClick={() => {
                    setIsSettingsOpen(!isSettingsOpen);
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...buttonProps}
                />
              </Tooltip>
            </Tippy>
            <Tooltip title="Refresh Content">
              <Button
                $leftSlot={<Icon name="Refresh" />}
                onClick={() => {
                  API.getExternalRssFeed({ publicationId, externalRssFeedId }).then((response) => {
                    editor.chain().updateAttributes('rss', { data: response.data }).run();
                  });
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Select New RSS Feed">
              <Button
                $leftSlot={<Icon name="Return" />}
                onClick={() => {
                  clearSelection();
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Divider />
          </>
        )}
        <Tooltip title="Remove Content">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $leftSlot={<Icon name="Trash" />}
            onClick={removeNode}
          />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default RSSBlockMenu;
