import { PropsWithChildren } from 'react';
import cx from 'classnames';

import { TOP_BAR_FROM_TOP_CLASS_NAME } from '../Layout/AppLayout/constants';

type Props = PropsWithChildren<{
  isSecondary?: boolean;
  isSticky?: boolean;
  canIntersectWithTopBar?: boolean;
}>;

export const ItemHeaders = ({
  children,
  isSecondary = false,
  isSticky = false,
  canIntersectWithTopBar = true,
}: Props) => {
  const topPositionClassName = canIntersectWithTopBar ? TOP_BAR_FROM_TOP_CLASS_NAME : 'top-0';

  return (
    <thead
      className={cx(
        isSecondary && 'bg-surface-50',
        isSecondary && 'border-b',
        isSecondary && 'border-surface-300',
        !isSecondary && 'bg-surface-100',
        isSticky && 'sticky z-10',
        isSticky && topPositionClassName
      )}
    >
      <tr>{children}</tr>
    </thead>
  );
};
