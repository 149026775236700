import { AdNetworkAdvertiser } from '@/interfaces/ad_network/advertiser_access/ad_network_advertiser';

import {
  AdNetworkAdvertiserPublisherListItemActor,
  AdNetworkAdvertiserPublisherListItemReason,
} from './advertiser_publisher_list_item';

export enum AdNetworkAdvertiserPublisherListType {
  PREFERRED = 'preferred',
  EXCLUDED = 'excluded',
}

export const AdNetworkAdvertiserPublisherListTypeOptions = [
  { value: AdNetworkAdvertiserPublisherListType.PREFERRED, label: 'Preferred' },
  { value: AdNetworkAdvertiserPublisherListType.EXCLUDED, label: 'Excluded' },
];

export interface AdNetworkAdvertiserPublisherListItem {
  id: string;
  expires_on: Date;
  reasons: AdNetworkAdvertiserPublisherListItemReason[];
  added_by: AdNetworkAdvertiserPublisherListItemActor;
  removed_by: AdNetworkAdvertiserPublisherListItemActor;
  publisher_name: string;
}

export interface AdNetworkAdvertiserPublisherList {
  id: string;
  type: AdNetworkAdvertiserPublisherListType;
  advertiser: AdNetworkAdvertiser;
  advertiser_id: string;
  created_at: Date;
  updated_at: Date;
  list_items: AdNetworkAdvertiserPublisherListItem[];
}
