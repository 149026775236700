import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

import Banner from '@/components/Banner';
import { Typography } from '@/components/Typography';
import VerifyIdentity from '@/components/VerifyIdentity';
import useStripeIdentityVerificationSession from '@/hooks/useUsers/useStripeIdentityVerificationSession';
import { Organization } from '@/interfaces/organization';
import { Button } from '@/ui/Button';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';

import KeyForm from './KeyForm';
import List from './List';

interface Props {
  organization: Organization;
  publicationId: string;
}

const CreateCard = ({ organization, publicationId }: Props) => {
  const [isNewApiKeyFormOpen, setIsNewApiKeyFormOpen] = useState(false);

  const requiresIdentityVerification = Boolean(organization?.requires_stripe_identity_verification);
  const {
    mutation: { mutateAsync, isLoading },
    stripe,
    submitted,
    isSubmitting,
  } = useStripeIdentityVerificationSession({
    organizationId: organization.id,
  });

  const handleClickVerify = async () => {
    await mutateAsync({});
  };

  if (requiresIdentityVerification) {
    return (
      <BodyContainer>
        <CardHeader
          title="API Keys"
          description="API keys are unique tokens generated for authenticating your requests and secure your account access. Its important to keep them confidential to protect your data and usage."
        />

        <div className="flex flex-col gap-y-3 bg-surface-50 border border-surface-200 p-4 rounded-md">
          <Typography token="font-normal/text/sm">
            Verify your identity to create an API key. We&apos;ve partnered with Stripe to make this as easy as
            possible. Verification takes less than 5 minutes.
          </Typography>
          <VerifyIdentity
            isDisabled={!stripe}
            isBusy={isLoading || isSubmitting}
            onClickVerify={handleClickVerify}
            hasSubmitted={submitted}
            verificationCompleteMessage="We're processing the results, expect an email with the outcome shortly. If your verification is successful you'll get access to the API immediately."
          />
          <Typography token="font-normal/text/sm">
            Alternatively, you may contact support to get API access. With this method you have to wait{' '}
            <b>48 - 72 hours</b> for your verification
          </Typography>
        </div>
      </BodyContainer>
    );
  }

  return (
    <BodyContainer>
      <Banner
        isScreenWide={false}
        variant="info"
        title={
          <>
            Make sure all subscribers added by API have given consent to be signed up via beehiiv&apos;s{' '}
            <a href="https://www.beehiiv.com/tou" target="_blank" rel="noreferrer" className="underline">
              Terms of Use
            </a>{' '}
            &{' '}
            <a href="https://www.beehiiv.com/privacy" target="_blank" rel="noreferrer" className="underline">
              Privacy Policy
            </a>
          </>
        }
      />
      <CardHeader
        title="API Keys"
        description="API keys are unique tokens generated for authenticating your requests and secure your account access. Its important to keep them confidential to protect your data and usage."
      />
      <List organization={organization} publicationId={publicationId} />
      <KeyForm
        publicationId={publicationId}
        organizationId={organization.id}
        isOpen={isNewApiKeyFormOpen}
        onClose={() => setIsNewApiKeyFormOpen(false)}
      />
      <Button size="xs" onClick={() => setIsNewApiKeyFormOpen(true)} Icon={PlusIcon} className="w-fit">
        Create New API Key
      </Button>
    </BodyContainer>
  );
};

export default CreateCard;
