import { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-mini';

import { SortableTableHeader } from '@/components/SortableTableHeader';
import useCampaignsPerformance, { OrderBy } from '@/hooks/useAdNetwork/internal/useCampaignsPerformance';
import { AdNetworkCampaignStatus } from '@/interfaces/ad_network/internal/campaign';
import { Order } from '@/interfaces/general';
import { Button } from '@/ui/Button';

import LoadingBox from '../../../../components/LoadingBox';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface Props {
  status?: AdNetworkCampaignStatus;
}

const CampaignsTable = ({ status }: Props) => {
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.CAMPAIGN_NAME);
  const [direction, setDirection] = useState<Order>(Order.DESC);
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = useCampaignsPerformance({
    status,
    orderBy,
    direction,
  });
  const campaigns = data?.pages.flatMap((page) => page.campaigns) || [];
  const pagination = data?.pages[0].pagination;

  const handleSort = (column: string) => {
    if (orderBy === column) {
      setDirection(direction === Order.ASC ? Order.DESC : Order.ASC);
    } else {
      setOrderBy(column as OrderBy);
      setDirection(Order.DESC);
    }
  };

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height={500}>
      {data && (
        <table className="w-full text-sm divide-y divide-gray-100">
          <thead className="bg-gray-50">
            <tr>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.CAMPAIGN_NAME}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Campaign</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.ADVERTISER_NAME}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Advertiser</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.START_DATE}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Start</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.END_DATE}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">End</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.SPEND}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Spend</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.BUDGET}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Budget</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.CONTRACTED_CLICKS}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Contracted</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.COMPLETED_CLICKS}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Completed</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.REMAINING_CLICKS}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Remaining</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.PERCENT_DELIVERED}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Delivered</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.FORECASTED}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Forecasted</div>
              </SortableTableHeader>
              <SortableTableHeader
                currentOrderBy={orderBy}
                orderBy={OrderBy.GAP}
                direction={direction}
                onClick={handleSort}
              >
                <div className="!font-normal px-4 py-2">Gap to Goal</div>
              </SortableTableHeader>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {campaigns.map((campaign) => {
              return (
                <tr key={campaign.id}>
                  <td className="px-4 py-2">
                    <p>
                      <Link
                        to={`/ad_network/campaigns/${campaign.id}/performance`}
                        className="font-semibold text-gray-800 "
                      >
                        {campaign.name}
                      </Link>
                    </p>
                  </td>
                  <td className="px-4 py-2">{campaign.advertiser.name}</td>
                  <td className="px-4 py-2">{moment(campaign.start_date).format('L')}</td>
                  <td className="px-4 py-2">
                    {campaign.end_date ? moment(campaign.end_date).format('L') : 'indefinitely'}
                  </td>
                  <td className="px-4 py-2">{currencyFormatter.format(campaign.spend_cents / 100)}</td>
                  <td className="px-4 py-2">{currencyFormatter.format(campaign.budget_cents / 100)}</td>
                  <td className="px-4 py-2">{campaign.contracted_clicks}</td>
                  <td className="px-4 py-2">{campaign.completed_clicks}</td>
                  <td className="px-4 py-2">{campaign.contracted_clicks - campaign.completed_clicks}</td>
                  <td className="px-4 py-2">
                    {((100 * campaign.completed_clicks) / campaign.contracted_clicks).toFixed(2)}%
                  </td>
                  <td className="px-4 py-2">{campaign.forecasted_clicks}</td>
                  <td className="px-4 py-2">{campaign.contracted_clicks - campaign.forecasted_clicks}</td>
                </tr>
              );
            })}

            {hasNextPage && (
              <div className="flex justify-center align-middle p-6">
                <Button
                  onClick={() => fetchNextPage()}
                  loading={isFetchingNextPage}
                  variant="primary-inverse"
                  size="xs"
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            )}

            <tr className="sticky bottom-0 bg-white">
              <td colSpan={5} className="px-4 py-2">
                <p>
                  Showing {campaigns.length} of {pagination?.total || 0} campaigns
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </LoadingBox>
  );
};

export default CampaignsTable;
