import { FC, useState } from 'react';
import toast from 'react-hot-toast';

import { FileInput } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { Option } from '@/interfaces/general';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import SampleTable from './SampleTable';

export interface SampleRecord {
  promo_code: string;
}

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  publicationId: string;
  rewardId: string;
  rewardName: string;
  slideOverBodyId: string;
}

const BulkImportPromoCodes: FC<Props> = (props: Props) => {
  const { publicationId, rewardId, rewardName, onClose, onSuccess, slideOverBodyId } = props;
  const [file, setFile] = useState<File>();
  const [columns, setColumns] = useState<Option[]>();
  const [numRecords, setNumRecords] = useState(0);
  const [sampleRecords, setSampleRecords] = useState<SampleRecord[]>();
  const [importId, setImportId] = useState<string>();
  const [selectedColumn, setSelectedColumn] = useState('');
  const [showColumnSelect, setShowColumnSelect] = useState(false);
  const [showVerificationStep, setShowVerificationStep] = useState(false);
  const [isLoadingColumns, setIsLoadingColumns] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleFileChange = (selectedFile: File) => {
    setFile(selectedFile);
  };

  const getColumns = (id: string) => {
    const params = {
      publication_id: publicationId,
    };

    setIsLoadingColumns(true);
    api
      .get(`/imports/${id}/columns`, { params })
      .then((res) => {
        const fileColumns: string[] = res.data?.columns || [];
        const options: Option[] = fileColumns.map((column) => ({
          label: column,
          value: column,
        }));
        setColumns(options);
        setShowColumnSelect(true);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingColumns(false);
      });
  };

  const handleSelect = (name: string, value: string) => {
    if (name === 'column') {
      setSelectedColumn(value);
    }
  };

  const getPreview = () => {
    const params = {
      publication_id: publicationId,
    };

    setIsLoadingPreview(true);
    api
      .get(`/imports/${importId}/preview`, { params })
      .then((res) => {
        const num: number = res.data?.num_records || 0;
        const records: SampleRecord[] = res.data?.sample_records || [];
        setNumRecords(num);
        setSampleRecords(records);
        setShowVerificationStep(true);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingPreview(false);
      });
  };

  const createImport = (e: any) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('publication_id', publicationId);
    formData.append('resource_id', rewardId);
    formData.append('resource_type', 'reward');
    formData.append('import_type', 'reward_promo_code');
    if (file instanceof File) {
      formData.append('file', file);
    }

    setIsCreating(true);
    api
      .post('/imports', formData)
      .then((res) => {
        const id = res?.data?.id;
        setImportId(id);
        getColumns(id);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const processCsv = (e: any) => {
    e.preventDefault();

    setIsSaving(true);
    api
      .post(`/imports/${importId}/process`, {
        publication_id: publicationId,
      })
      .then(() => {
        toast.success('Import has started. Please give it a few minutes for all codes to show up.');
        onSuccess();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const updateConfiguration = (e: any) => {
    e.preventDefault();

    const payload = {
      publication_id: publicationId,
      configuration: {
        column_mapping: {
          promo_code: selectedColumn,
        },
      },
    };

    setIsSaving(true);
    api
      .patch(`/imports/${importId}`, payload)
      .then(() => {
        getPreview();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const resetForm = () => {
    setFile(undefined);
  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  const renderUploadStep = (
    <>
      <FileInput
        name="file"
        labelText="Upload Promo Codes CSV"
        helperText="The entire CSV will be scanned for promo codes. Please only include codes that can be assigned. (Max size 5MB)"
        onFileSelect={handleFileChange}
        file={file}
        accept=".csv"
      />
      <div className="flex justify-end">
        <Button type="button" className="mx-2" variant="primary-inverse" block={false} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type="button"
          block={false}
          loading={isCreating || isLoadingColumns}
          disabled={!file}
          onClick={createImport}
        >
          Next
        </Button>
      </div>
    </>
  );

  const renderColumnSelectStep = (
    <>
      <Dropdown
        name="column"
        labelText="Promo Code Column"
        value={selectedColumn}
        onSelect={handleSelect}
        options={columns || []}
        helperText="Select the column from the CSV that holds the unique promo codes"
        portalMountedId={slideOverBodyId}
        required
      />
      <div className="flex justify-end">
        <Button
          type="button"
          className="mx-2"
          variant="primary-inverse"
          block={false}
          onClick={() => {
            setShowColumnSelect(false);
            setImportId('');
            setSelectedColumn('');
          }}
        >
          Back
        </Button>
        <Button
          variant="primary"
          type="button"
          block={false}
          loading={isLoadingPreview}
          disabled={!selectedColumn}
          onClick={updateConfiguration}
        >
          Next
        </Button>
      </div>
    </>
  );

  const renderVerificationStep = (
    <>
      <div>
        <Typography token="font-normal/text/sm" colorWeight="500">
          Preview the codes below to confirm, then upload to add <strong>{numRecords.toLocaleString()}</strong> promo
          codes to the <strong>{rewardName}</strong> reward.
        </Typography>
      </div>
      <SampleTable sampleRecords={sampleRecords || []} />
      <div className="flex justify-end">
        <Button
          type="button"
          className="mx-2"
          variant="primary-inverse"
          block={false}
          onClick={() => {
            setSampleRecords([]);
            setShowVerificationStep(false);
          }}
        >
          Back
        </Button>
        <Button
          variant="primary"
          type="button"
          block={false}
          loading={isSaving}
          disabled={!importId}
          onClick={processCsv}
        >
          Upload
        </Button>
      </div>
    </>
  );

  const renderWizard = () => {
    if (showVerificationStep) {
      return renderVerificationStep;
    }

    if (showColumnSelect) {
      return renderColumnSelectStep;
    }

    return renderUploadStep;
  };

  return <div className="space-y-4">{renderWizard()}</div>;
};

export default BulkImportPromoCodes;
