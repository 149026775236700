import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import { Typography } from '@/components/Typography';
import useEmailApplication from '@/hooks/boosts/grow/useEmailApplication';
import { BoostSend } from '@/interfaces/boosts/grow/boost_send';

export interface Props {
  isOpen: boolean;
  onSuccess?: () => void;
  onClickCancel: () => void;
  boostSend: BoostSend;
}

const ConfirmBoostSendRejectModal = ({ isOpen, boostSend, onClickCancel, onSuccess }: Props) => {
  const { handleReject, isRejecting } = useEmailApplication(boostSend, {
    onSuccess: () => {
      toast.success('Email Boost Rejected!');
      onSuccess?.();
    },
  });

  return (
    <ActionModal
      isOpen={isOpen}
      isWorking={isRejecting}
      onClose={onClickCancel}
      onProceed={() => {
        handleReject();
      }}
      resourceId={boostSend.id}
      actionText="Confirm"
    >
      <div className="flex gap-x-4">
        <Typography token="font-normal/text/sm">
          Are you sure you want to reject Email Boost from <strong>{boostSend.recommending_publication.name}</strong>?
        </Typography>
      </div>
    </ActionModal>
  );
};

export default ConfirmBoostSendRejectModal;
