import React, { useEffect, useRef } from 'react';
import { NodeViewWrapper } from '@tiptap/react';

interface Props {
  url: string;
  service: 'twitter';
  data: any;
  anchorEnabled: boolean;
  anchorId: string;
}

export const TwitterEmbed = ({ url, service, data: attributes, anchorEnabled = false, anchorId }: Props) => {
  const twitterEmbedRef = useRef(null);

  useEffect(() => {
    (window as any)?.twttr?.widgets?.load(twitterEmbedRef.current);
  }, []);

  return (
    <NodeViewWrapper
      data-url={url}
      data-service={service}
      data-drag-handle
      style={{ width: '100%', maxWidth: '520px' }}
      {...(anchorEnabled ? { 'data-anchor': '', id: anchorId } : {})}
    >
      <blockquote className="twitter-tweet" ref={twitterEmbedRef}>
        {attributes?.data?.text && <p>{attributes.data.text}</p>}

        <a className="underline text-blue-500" href={url}>
          View Tweet
        </a>
      </blockquote>
    </NodeViewWrapper>
  );
};

export default TwitterEmbed;
