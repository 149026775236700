import React, { FC } from 'react';

import ColorField from '../../components/Form/ColorField';

import ExpandableSlider from './components/ExpandableSlider';
import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { LineHeights, ThemeFormProps } from './types';

const HeaderTextForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette, fonts }) => {
  return (
    <FormContainer>
      {[1, 2, 3, 4, 5, 6].map((htag: any) => (
        <React.Fragment key={htag}>
          <FormSection title={`Heading ${htag}`}>
            <FormInput title="Font family">
              {fonts && (
                <ThemeEditorDropdown
                  currentValue={(theme as any)[`h${htag}_font_family`]}
                  onUpdate={(val: string) => onUpdate({ ...theme, [`h${htag}_font_family`]: val })}
                  options={fonts}
                />
              )}
            </FormInput>
            <FormInput title="Font weight">
              <ThemeEditorDropdown
                currentValue={(theme as any)[`h${htag}_font_weight`]}
                onUpdate={(val: string) => onUpdate({ ...theme, [`h${htag}_font_weight`]: val })}
                options={[
                  { label: 'Normal', value: 'Normal' },
                  { label: 'Bold', value: 'Bold' },
                ]}
              />
            </FormInput>
            <FormInput title="Line height">
              <ThemeEditorDropdown
                currentValue={(theme as any)[`h${htag}_line_height_size`]}
                onUpdate={(val: string) => onUpdate({ ...theme, [`h${htag}_line_height_size`]: val })}
                options={[
                  { label: LineHeights.Less, value: LineHeights.Less },
                  { label: LineHeights.Normal, value: LineHeights.Normal },
                  { label: LineHeights.More, value: LineHeights.More },
                ]}
              />
            </FormInput>
            <FormInput>
              <Slider
                name={`h${htag}_font_size`}
                label="Font size"
                onChange={(val: number) => onUpdate({ ...theme, [`h${htag}_font_size`]: val.toString() })}
                initialValue={(theme as any)[`h${htag}_font_size`]}
                min={12}
                max={60}
                valueSuffix="px"
              />
            </FormInput>
            <FormInput>
              <ExpandableSlider
                fields={{
                  [`h${htag}_padding_top`]: 'Top',
                  [`h${htag}_padding_bottom`]: 'Bottom',
                }}
                theme={theme}
                onUpdate={onUpdate}
                max={80}
                min={0}
                title="Space between elements"
              />
            </FormInput>
            <FormInput title="Header color">
              <ColorField
                placement="bottom"
                color={(theme as any)[`h${htag}_font_color`]}
                colorPalette={colorPalette}
                onColorChange={(color: any) => onUpdate({ ...theme, [`h${htag}_font_color`]: color?.hexString })}
              />
            </FormInput>
          </FormSection>
          {htag !== 6 && <Divider />}
        </React.Fragment>
      ))}
    </FormContainer>
  );
};

export default HeaderTextForm;
