import React from 'react';
import { Link } from 'react-router-dom';

import { Checkbox, FileInput } from '@/components/Form';
import SelectAutomation from '@/components/SelectAutomation/SelectAutomation';
import SubscriberTagSelectInput from '@/components/SubscriberTags/SubscriberTagSelectInput';
import { PublicationSubscriberTagReference } from '@/interfaces/subscriber_tag';
import { SubscriberImportType } from '@/pages/Segments/types';
import { Dropdown } from '@/ui/Dropdown';
import { Textarea } from '@/ui/Textarea';

interface Props {
  onFileChange: (file: File | null) => void;
  selectedFile: File | null;
  commaSeparatedEmails: string;
  onCommaSeparatedEmailsChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  shouldSendWelcomeEmail: boolean;
  onSendWelcomeEmailChange: (value: boolean) => void;
  shouldDisableWelcomeEmail: boolean;
  canSkipEmailValidation: boolean;
  shouldSkipEmailValidation: boolean;
  onSkipEmailValidationChange: (value: boolean) => void;
  csvUploadElementLabel?: string;
  csvTextInputElementLabel?: string;
  isAddingToAutomations?: boolean;
  onAddToAutomationsChange?: (value: boolean) => void;
  selectedAutomations?: string[];
  onSelectAutomation?: (automationId: string) => void;
  onDeselectAutomation?: (automationId: string) => void;
  onDeselectAllAutomations?: () => void;
  canEnrollToAutomations?: boolean;
  selectedImportType: SubscriberImportType;
  onImportTypeChange: (name: string, value: SubscriberImportType) => void;
  taggingEnabled?: boolean;
  handleSubscriberTagSelect?: (tag: any) => void;
  handleSubscriberTagDeselect?: (subscriberTagId: string) => void;
  onAddSubscriberTagsChange?: (value: boolean) => void;
  isAddingSubscriberTags?: boolean;
  selectedSubscriberTags?: PublicationSubscriberTagReference[];
}

const ImportSubscriberListForm = ({
  onFileChange,
  selectedFile,
  commaSeparatedEmails,
  onCommaSeparatedEmailsChange,
  shouldSendWelcomeEmail,
  shouldDisableWelcomeEmail,
  onSendWelcomeEmailChange,
  canSkipEmailValidation,
  shouldSkipEmailValidation,
  onSkipEmailValidationChange,
  onSelectAutomation,
  onDeselectAutomation,
  onDeselectAllAutomations,
  isAddingToAutomations,
  onAddToAutomationsChange,
  selectedAutomations = [],
  canEnrollToAutomations = false,
  selectedImportType,
  onImportTypeChange,
  taggingEnabled,
  handleSubscriberTagSelect,
  handleSubscriberTagDeselect,
  onAddSubscriberTagsChange,
  isAddingSubscriberTags,
  selectedSubscriberTags,
  csvTextInputElementLabel = 'Input a list of emails, separated by commas',
  csvUploadElementLabel = 'Upload Email List CSV',
}: Props) => {
  return (
    <>
      <div className="space-y-6">
        <Dropdown
          className="w-full"
          name="measure"
          labelText="Import method"
          value={selectedImportType}
          onSelect={onImportTypeChange}
          options={[
            {
              label: 'Upload a CSV file',
              value: SubscriberImportType.CSV,
            },
            {
              label: 'Copy and paste a list of email addresses',
              value: SubscriberImportType.Manual,
            },
          ]}
        />

        {selectedImportType === SubscriberImportType.CSV && (
          <FileInput
            name="file"
            labelText={csvUploadElementLabel}
            helperText="The entire CSV will be scanned for emails. Please only include emails that should be subscribed to your newsletter."
            onFileSelect={onFileChange}
            onFileRemoved={onFileChange}
            file={selectedFile}
            accept=".csv"
          />
        )}

        {selectedImportType === SubscriberImportType.Manual && (
          <Textarea
            name="comma_separated_emails"
            value={commaSeparatedEmails}
            onChange={onCommaSeparatedEmailsChange}
            labelText={csvTextInputElementLabel}
            placeholderText="email1@beehiiv.com, email2@beehiiv.com, email3@beehiiv.com, etc."
          />
        )}

        <div className="space-y-2">
          <Checkbox
            checked={shouldSendWelcomeEmail}
            onChange={onSendWelcomeEmailChange}
            name="send_welcome_email"
            labelText="Send welcome email to newly imported subscribers"
            disabled={shouldDisableWelcomeEmail}
          />
          {!shouldDisableWelcomeEmail ? (
            <p className="text-xs text-gray-500">
              If checked, each imported email will automatically receive your welcome email
            </p>
          ) : (
            <p className="text-xs text-gray-800">
              {'You need to first setup a '}
              <Link to="/settings/publication/emails#preset" className="underline hover:text-primary-600">
                welcome email
              </Link>
              {' to enable this option'}
            </p>
          )}
        </div>

        {canSkipEmailValidation && (
          <Checkbox
            checked={shouldSkipEmailValidation}
            onChange={onSkipEmailValidationChange}
            name="skip_email_validation"
            labelText="Skip email validation"
          />
        )}

        <div className="space-y-2">
          {canEnrollToAutomations &&
            onAddToAutomationsChange &&
            onSelectAutomation &&
            onDeselectAutomation &&
            onDeselectAllAutomations && (
              <>
                <Checkbox
                  checked={false}
                  onChange={onAddToAutomationsChange}
                  name="add_to_automations"
                  labelText="Add to Automation(s)"
                />
                {isAddingToAutomations && (
                  <SelectAutomation
                    onSelect={onSelectAutomation}
                    onDeselect={onDeselectAutomation}
                    onDeselectAll={onDeselectAllAutomations}
                    selectedAutomations={selectedAutomations}
                    isEnrollingMultipleSubscribers
                  />
                )}
              </>
            )}
        </div>
        {taggingEnabled &&
          selectedSubscriberTags &&
          onAddSubscriberTagsChange &&
          handleSubscriberTagSelect &&
          handleSubscriberTagDeselect && (
            <div className="space-y-2">
              <Checkbox
                checked={false}
                onChange={onAddSubscriberTagsChange}
                name="add_subscriber_tags"
                labelText="Apply Subscriber Tags"
              />
              {isAddingSubscriberTags && (
                <SubscriberTagSelectInput
                  selectValue="id"
                  selectedTags={selectedSubscriberTags}
                  handleDeselect={handleSubscriberTagDeselect}
                  handleSelect={handleSubscriberTagSelect}
                />
              )}
            </div>
          )}
      </div>

      <div>
        <div className="flex mb-4">
          <p className="mt-1 text-xs text-gray-500 pr-3">
            <b className="text-red-500">Important: </b>
            All subscribers being imported must have knowingly consented and opted into your newsletter, otherwise you
            are at risk of violating our{' '}
            <a
              href="https://www.beehiiv.com/tou"
              target="_blank"
              className="underline underline-offset-1 hover:opacity-80"
              rel="noreferrer noopener"
            >
              terms of use
            </a>{' '}
            &{' '}
            <a
              href="https://www.beehiiv.com/privacy"
              target="_blank"
              className="underline underline-offset-1 hover:opacity-80"
              rel="noreferrer noopener"
            >
              privacy policy
            </a>
            . You will not be billed if your list exceeds limit of your plan.
          </p>
        </div>
      </div>
    </>
  );
};
export default ImportSubscriberListForm;
