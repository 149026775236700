import { FC } from 'react';

import ColorField from '../../components/Form/ColorField';

import ExpandableSlider from './components/ExpandableSlider';
import Slider from './components/Slider';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { ThemeFormProps } from './types';

const BackgroundForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette }) => {
  return (
    <FormContainer>
      <FormSection title="Colors">
        <FormInput title="Outside background">
          <ColorField
            placement="bottom"
            color={theme.background_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, background_color: color?.hexString })}
          />
        </FormInput>
        <FormInput title="Post background">
          <ColorField
            placement="bottom"
            color={theme.content_background_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, content_background_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Post Border">
        <FormInput>
          <Slider
            name="border_radius"
            label="Corner radius"
            onChange={(val: number) => onUpdate({ ...theme, border_radius: val })}
            initialValue={theme.border_radius}
            max={50}
            min={0}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="border_width"
            label="Border thickness"
            onChange={(val: number) => onUpdate({ ...theme, border_width: val.toString() })}
            initialValue={theme.border_width}
            max={5}
            min={0}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Border color">
          <ColorField
            placement="bottom"
            color={theme.border_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, border_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection
        title="Spacing"
        protip="Giving your post a border makes it easier to see the effects of these changes."
      >
        <FormInput>
          <Slider
            name="padding"
            label="Inner margin"
            onChange={(val: number) => onUpdate({ ...theme, padding: val.toString() })}
            initialValue={theme.padding}
            max={80}
            min={0}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <ExpandableSlider
            fields={{
              margin_top: 'Top',
              margin_right: 'Right',
              margin_bottom: 'Bottom',
              margin_left: 'Left',
            }}
            theme={theme}
            onUpdate={onUpdate}
            max={80}
            min={0}
            title="Outer margin"
          />
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default BackgroundForm;
