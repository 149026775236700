import AttributeInput from "@/routes/website/_components/DreamEditor/AttributesPanel/helpers/AttributeInput";

import { useNavbarContext } from "../../../NavbarContext";

export function TitleSettings() {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_dropdown_item") return null;


  const contentValue = selectedContent?.attrs && 'title' in selectedContent.attrs && selectedContent.attrs.title || '';


  const handleTitleChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { title: value });
  }

  return <AttributeInput
    title="Title"
    value={contentValue}
    onChange={e => handleTitleChange(e.target.value)}
  />
}
