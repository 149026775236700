import { useApiKeys } from '@/hooks/useIntegrations';
import { ApiKey } from '@/interfaces/api_key';
import { Organization } from '@/interfaces/organization';

import ApiKeyItem from './ApiKeyItem';

interface Props {
  organization: Organization;
  publicationId: string;
}

const List = ({ organization, publicationId }: Props) => {
  const apiKeysRequest = useApiKeys(publicationId);
  const { data: apiKeys } = apiKeysRequest || {};

  return (
    <div className="flex flex-col gap-y-3">
      {apiKeys?.length
        ? apiKeys?.map((apiKey: ApiKey) => {
            return (
              <ApiKeyItem
                key={apiKey.id}
                organizationId={organization.id}
                publicationId={publicationId}
                apiKey={apiKey}
              />
            );
          })
        : null}
    </div>
  );
};

export default List;
