import { AuthorsElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { useReactNodeView } from '@tiptap/react';

import NotConnected from '../../components/NotConnected';
import { getDataAttributes } from '../../utils';

export const AuthorsView = ({ HTMLAttributes, node, editor, getPos }: NodeViewProps) => {
  const { onDragStart } = useReactNodeView();
  const { hasFakeData } = node.attrs;

  const handleOpenSelectionModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
  };

  return (
    <AuthorsElement
      element={{
        type: 'authors',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [{ id: 'text', text: '' }],
      }}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
      onDragStart={onDragStart}
    >
      {hasFakeData && <NotConnected text="Not connected to real authors" onClick={handleOpenSelectionModal} />}
      {null}
    </AuthorsElement>
  );
};
