import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface ApiResponse {
  total_count: number;
  success_count: number;
  failed_count: number;
  total_amount: string;
  max_approve_amount: string;
  start_date: string | null;
  end_date: string | null;
}

export default function useDisbursementApproveAll({
  maximum_amount_cents,
  start_date,
  end_date,
}: {
  maximum_amount_cents: number;
  start_date: Date | null;
  end_date: Date | null;
}) {
  const approveDisbursement = (): Promise<ApiResponse> =>
    api
      .post(`/ad_network/internal/disbursements/approve_all`, {
        maximum_amount_cents,
        start_date,
        end_date,
      })
      .then((res) => res.data);

  return useMutation(approveDisbursement, {
    onSuccess: () => {
      toast.success('Disbursements approved');
    },
    onError: (error: any) => {
      toast.error(error.response.data.error || 'Something went wrong');
    },
  });
}
