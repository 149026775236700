import React from 'react';

import { Tooltip } from '../Tooltip';

import { Styled } from './styled';
import { AvatarProps } from './types';

export const Avatar = ({ children, tooltipTitle = undefined, $color = undefined }: AvatarProps) => {
  return (
    <Tooltip title={tooltipTitle} tippyOptions={{ touch: true }}>
      <Styled.Container $color={$color}>
        {typeof children === 'string' ? (
          <Styled.TextWrapper>{children}</Styled.TextWrapper>
        ) : (
          <Styled.ImageWrapper>{children}</Styled.ImageWrapper>
        )}
      </Styled.Container>
    </Tooltip>
  );
};

export default Avatar;
