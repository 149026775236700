import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { RadioSelect } from '../../../../../../components/Form';
import { useCurrentPublicationState } from '../../../../../../context/current-publication-context';
import { useSettings } from '../../../../../../context/settings-context';
import api from '../../../../../../services/swarm';

import Ghost from './Ghost';
import Mailchimp from './Mailchimp';
import MailchimpImportOptions from './MailchimpImportOptions';
import Other from './Other';
import Substack from './Substack';
import Wordpress from './Wordpress';

type PlatformOption = 'substack' | 'wordpress' | 'mailchimp' | 'ghost' | 'other';

interface Props {
  onClose: () => void;
}

const ContentImportCreate = ({ onClose }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [url, setUrl] = useState('');
  const [integrationId, setIntegrationId] = useState<string | undefined>();
  const [integrationProvider, setIntegrationProvider] = useState<string | undefined>();
  const { settings } = useSettings();
  const [platform, setPlatform] = useState<PlatformOption>('substack');
  const [file, setFile] = useState<File>();
  const [importTypeSelection, setImportTypeSelection] = useState<string>('1');
  const [selectedFolderIds, setSelectedFolderIds] = useState<string[]>([]);
  const isCustomizeByFolderSelected = importTypeSelection === MailchimpImportOptions[1].value;
  const [apiKey, setApiKey] = useState('');

  const submitImport = () => {
    setIsSubmitting(true);

    if (!currentPublicationId) {
      toast.error('Something went wrong, please try again.');
      return;
    }

    const payload = new FormData();

    payload.append('publication_id', currentPublicationId);
    payload.append('content_import[platform]', platform);

    if (url && url.length > 0) {
      payload.append('content_import[url]', url);
    }

    if (apiKey && apiKey.length > 0) {
      payload.append('content_import[api_key]', apiKey);
    }

    if (file instanceof File) {
      payload.append('content_import[file]', file);
    }

    if (integrationId && integrationProvider) {
      payload.append('content_import[integration_id]', integrationId);
      payload.append('content_import[integration_provider]', integrationProvider);
    }

    if (isCustomizeByFolderSelected && selectedFolderIds.length > 0) {
      selectedFolderIds.forEach((val) => {
        payload.append('content_import[mailchimp_folders][]', val);
      });
    }

    api
      .post('/content_imports', payload)
      .then(() => {
        analytics.track('Imported Content');
        toast.success('Content import will start shortly');
        onClose();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  const selections = {
    substack: <Substack url={url} file={file} onUrlChange={setUrl} onFileChange={setFile} />,
    wordpress: <Wordpress url={url} onUrlChange={setUrl} />,
    ...(settings?.mailchimp_integration
      ? {
          mailchimp: (
            <Mailchimp
              setSelectedFolderIds={setSelectedFolderIds}
              selectedFolderIds={selectedFolderIds}
              importTypeSelection={importTypeSelection}
              setImportTypeSelection={setImportTypeSelection}
              integrationId={integrationId}
              setIntegrationId={setIntegrationId}
              setIntegrationProvider={setIntegrationProvider}
            />
          ),
        }
      : {}),
    ghost: <Ghost url={url} onUrlChange={setUrl} apiKey={apiKey} onApiKeyChange={setApiKey} />,
    other: <Other />,
  };

  const options = [
    { name: 'Substack', value: 'substack' },
    { name: 'WordPress', value: 'wordpress' },
    ...(settings?.mailchimp_integration ? [{ name: 'Mailchimp', value: 'mailchimp' }] : []),
    { name: 'Ghost', value: 'ghost' },
    { name: 'Other', value: 'other' },
  ];

  const isImportReady = Boolean(url || file || (integrationId && integrationProvider));

  return (
    <div className="divide-y divide-gray-200">
      <div>
        <div className="mt-4 flex flex-col lg:flex-row">
          <div className="w-full space-y-8">
            <div className="p-3 bg-primary-50 rounded-md border border-primary-100 text-gray-800">
              <p className="text-sm">
                To help make moving to beehiiv as seamless as possible, we&apos;ve built a content import tool to help
                move your existing content on to your site on beehiiv. Any posts that you import will not be sent to
                your subscribers, they will only be published to your website.
              </p>
              <p className="text-sm font-bold mt-8">By importing content, you are agreeing to the following:</p>
              <ul className="text-sm list-disc list-outside ml-6">
                <li className="py-2 pl-1">
                  You can verify that you are the rightful owner of this content and can definitively claim it as your
                  own.
                </li>
                <li className="py-2 pl-1">
                  You acknowledge that by falsely claiming other&apos;s content as your own, you will be in direct
                  violation of beehiiv&apos;s terms of service which can lead to immediate termination of service.
                </li>
              </ul>
            </div>
            <RadioSelect
              labelText="Where does your content live now?"
              className="space-y-2 max-w-xs"
              value={platform}
              options={options}
              onSelect={(selected) => {
                setPlatform(selected as PlatformOption);
                setUrl('');
                setFile(undefined);
              }}
            />
            {selections[platform]}
          </div>
        </div>
      </div>
      <div className="mt-4 py-4 flex space-x-2 justify-end">
        <Button variant="primary-inverse" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={isSubmitting} disabled={!isImportReady} onClick={submitImport}>
          Import
        </Button>
      </div>
    </div>
  );
};

export default ContentImportCreate;
