import { useState } from 'react';

import { Button } from '@/ui/Button';

import useFilteringContext from '../filtering-context';
import { ConditionGroup } from '../types';

const SearchFilterDetails = ({ group }: { group: ConditionGroup }) => {
  const [value, setValue] = useState('');
  const { addCondition, handleClose } = useFilteringContext();

  const handleApply = () => {
    addCondition(
      {
        type: 'attribute',
        name: 'search',
        filters: { operator: 'equal', value },
      },
      group.uuid
    );

    handleClose();
  };

  return (
    <form
      className="absolute z-10 mt-2 bg-white border border-gray-300 shadow rounded-md text-sm left-0 w-max text-gray-600 p-4 space-y-2"
      onSubmit={handleApply}
    >
      <div className="flex items-center space-x-2">
        <p className="inline">Search</p>
        <input
          type="text"
          className="appearance-none border rounded-md text-xs border-gray-300 focus:border-gray-300 focus:outline-none focus:ring-0"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          required
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </div>
      <Button variant="primary-inverse" type="submit" size="xs">
        Apply
      </Button>
    </form>
  );
};

export default SearchFilterDetails;
