import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const Switch = styled.label`
  display: inline-block;
  height: 16px;
  position: relative;
  width: 28px;
`;

export const Knob = styled.span<{
  isDisabled?: boolean;
}>`
  position: absolute;
  cursor: ${(p) => (p.isDisabled ? 'auto' : 'pointer')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.white[6]};
  border-radius: 16px;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: ${colors.white[1]};
    border-radius: 50%;
    transition: 0.2s;
  }
`;

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Knob} {
    background-color: ${colors.blue[5]};
  }

  &:checked + ${Knob}:before {
    transform: translateX(12px);
  }

  &:focus + ${Knob} {
    box-shadow: 0 0 1px ${colors.blue[5]};
  }
`;
