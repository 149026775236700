import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useDreamEditorContext } from '@/context/dream-editor-context';
import { useSettings } from '@/context/settings-context';
import { DreamEditor } from '@/routes/website/_components/DreamEditor';
import { EditorDocumentType } from '@/routes/website/_components/DreamEditor/extensions/Document';
import { InsertPanel } from '@/routes/website/_components/DreamEditor/InsertPanel';
import { EditorHeader } from '@/routes/website/_components/EditorHeader/EditorHeader';
import { HeaderType } from '@/routes/website/_components/EditorHeader/types';
import IframeWrapper from '@/routes/website/page/_components/Iframe';
import { useEditorFontHrefs } from '@/routes/website/page/_hooks/useEditorFontHrefs';

export const Editor = ({ editorRect }: { editorRect: Partial<DOMRect> }) => {
  const { elementType } = useParams<{ elementType: EditorDocumentType }>();
  const [currentPublicationId] = useCurrentPublicationState();
  const { editor } = useDreamEditorContext();

  const { settings } = useSettings();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const fontHrefs = useEditorFontHrefs();

  const editorHeaderType: Record<EditorDocumentType, HeaderType> = {
    signup_modal: 'signup_modal',
    recommendations_modal: 'recommendations_modal',
    login_modal: 'login_modal',
    template: 'template',
    page: 'template',
  };

  const insertPanelComponent = useMemo(
    () => (editor ? <InsertPanel allowedCategories={['layout', 'text']} editor={editor} /> : <></>),
    [editor]
  );

  const iconTypes: Record<EditorDocumentType, 'component' | 'layout'> = {
    signup_modal: 'component',
    recommendations_modal: 'component',
    login_modal: 'component',
    template: 'layout',
    page: 'layout',
  };
  return (
    <>
      {elementType && <EditorHeader type={editorHeaderType[elementType]} iconType={iconTypes[elementType]} />}
      <IframeWrapper
        width={editorRect.width || 0}
        height={editorRect.height || 0}
        contentWidth={1024}
        ref={iframeRef}
        fontHrefs={fontHrefs}
      >
        <div className="grow relative overflow-y-scroll min-h-[400px] h-fit">
          <PublicationProvider id={currentPublicationId}>
            {settings && (
              <DreamEditor
                type={elementType}
                publicationId={currentPublicationId}
                settings={settings}
                className="typedream content global-elements"
                insertPanelComponent={insertPanelComponent}
              />
            )}
          </PublicationProvider>
        </div>
      </IframeWrapper>
    </>
  );
};
