import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import { v4 as generateUuid } from 'uuid';

import { useSearchSubscriberTags } from '@/hooks/useSubscriberTags';
import {
  PublicationSubscriberTag,
  PublicationSubscriberTagReference,
  PublicationSubscriberTagSelectItem,
  SubscriberTagsIndexResponse,
  SubscriptionSubscriberTag,
} from '@/interfaces/subscriber_tag';

import EmptyDropdownOption from '../Form/EmptyDropdownOption';

import SubscriberTagItem from './SubscriberTagItem';

interface SubscriberTagSelectInputProps {
  selectValue: string;
  selectedTags: PublicationSubscriberTagReference[];
  handleDeselect: (tagId: string) => void;
  handleSelect: (tag: PublicationSubscriberTagSelectItem) => void;
  isDeleting?: boolean;
}

const SubscriberTagSelectInput: React.FunctionComponent<SubscriberTagSelectInputProps> = ({
  selectValue,
  selectedTags,
  handleDeselect,
  handleSelect,
  isDeleting,
}) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [showAllSelected, setShowAllSelected] = useState(false);
  const subscriberTagsQuery = useSearchSubscriberTags({ search: '' });
  const { data, error } = subscriberTagsQuery;

  const unhideAllTags = () => {
    setShowAllSelected(true);
  };

  const selectedTag = (
    tagOption: PublicationSubscriberTag
  ): SubscriptionSubscriberTag | PublicationSubscriberTag | PublicationSubscriberTagReference | undefined => {
    return selectedTags.find(
      (
        tag: SubscriptionSubscriberTag | PublicationSubscriberTag | PublicationSubscriberTagReference
      ): tag is PublicationSubscriberTag => {
        if (selectValue === 'publication_subscriber_tag_id' && 'publication_subscriber_tag_id' in tag) {
          return tag.publication_subscriber_tag_id === tagOption.id;
        }
        if (selectValue === 'id' && 'id' in tag) {
          return tag.id === tagOption.id;
        }
        return false;
      }
    );
  };

  const subscriberTags: PublicationSubscriberTagSelectItem[] = useMemo(() => {
    return (data?.pages.flatMap((page: SubscriberTagsIndexResponse) => page.subscriber_tags) || []).map(
      (tag: PublicationSubscriberTag): PublicationSubscriberTagSelectItem => ({
        label: tag.name,
        value: tag.id,
        color: tag.color,
        deletableId: selectedTag(tag)?.id,
      })
    );
  }, [data, selectedTags]);

  useEffect(() => {
    if (shouldFetch) {
      setShouldFetch(false);
    }
  }, [shouldFetch, setShouldFetch]);

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
    }
  }, [error]);

  const selectedItems = subscriberTags.filter((tag: PublicationSubscriberTagSelectItem) => !!tag.deletableId);

  const filteredSelections = useMemo(
    () =>
      selectedItems.length > 0 ? (
        subscriberTags
          .filter((tag: PublicationSubscriberTagSelectItem) => !!tag.deletableId)
          .map((option: PublicationSubscriberTagSelectItem, index: number) => (
            <>
              {((!showAllSelected && index < 3) || !!showAllSelected) && (
                <SubscriberTagItem
                  key={`select-group-label-${option.label}-${generateUuid()}`}
                  name={option.label}
                  color={option.color}
                  deletableId={option.deletableId}
                  onDelete={() => {
                    handleDeselect(option.deletableId || '');
                  }}
                />
              )}
              {!showAllSelected && index === 2 && (
                <button
                  type="button"
                  onClick={unhideAllTags}
                  className="inline cursor-pointer bg-white-100 text-gray-600 text-xs rounded-md border shadow-xs inline-flex gap-x-1 mr-2 px-2 py-1"
                >
                  + {selectedItems.length - 3} More
                </button>
              )}
            </>
          ))
      ) : (
        <span className="w-full inline-flex">
          <p>Select tags</p>
        </span>
      ),
    [subscriberTags, showAllSelected, handleDeselect, unhideAllTags]
  );

  const filteredOptions = useMemo(
    () =>
      subscriberTags
        .filter((tag: PublicationSubscriberTagSelectItem) => tag.deletableId === undefined)
        .map((option: PublicationSubscriberTagSelectItem) => (
          <Listbox.Option
            key={option.value}
            className={({ active }) =>
              cx(
                active ? 'text-gray-900 bg-gray-100' : 'text-gray-800',
                'cursor-pointer select-none relative py-2 pl-3 pr-9 rounded-lg'
              )
            }
            value={option}
          >
            {({ selected, active }) => (
              <>
                <SubscriberTagItem name={option.label} color={option.color} />
                {selected || option.deletableId !== undefined ? (
                  <span
                    className={cx(
                      active ? 'text-gray-900' : 'text-gray-800',
                      'absolute inset-y-0 right-0 flex items-center pr-4'
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                ) : null}
              </>
            )}
          </Listbox.Option>
        )),
    [subscriberTags]
  );

  return (
    <div className="w-full items-center">
      <Listbox onChange={handleSelect}>
        {({ open }) => (
          <div className="relative">
            <div>
              <Listbox.Button className="relative w-full rounded-md focus:outline-none focus:ring-0 text-left sm:text-sm cursor-default shadow-sm py-2 pl-3 pr-10 bg-white border border-gray-300">
                {filteredSelections}
                <span
                  key="select-btn"
                  className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 bg-white shadow-lg max-h-60 rounded-md py-2 px-2 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm max-h-60 origin-top-right right-0 w-full mt-1 shadow-lg">
                  {filteredOptions.length > 0 && !isDeleting && filteredOptions}
                  {filteredOptions.length === 0 ? <EmptyDropdownOption label="No tag found" /> : null}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default SubscriberTagSelectInput;
