import { IdentificationBadge } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';

type Props = AttributeSettingProps & {};

export const NameSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Name" text="Name" popoverTitle="Name" Icon={IdentificationBadge}>
      <>
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          property="nameFontFamily"
          title="Font"
        />
        <SimpleFontSizeSettings
          title="Size"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="nameFontSize"
        />
        <ColorSettings title="Color" property="nameColor" editor={editor} activeNodeResult={activeNodeResult} />
      </>
    </PopoverHelper>
  );
};

export default NameSettings;
