import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { HomePage } from '../../interfaces/home_page';
import api from '../../services/swarm';

interface Props {
  homePageId?: string;
  status: string;
}

const useHomePage = ({ status, homePageId }: Props) => {
  const [publicationId] = useCurrentPublicationState();
  const enabled = !!status && !!publicationId;

  return useQuery<HomePage>(
    ['home_page', publicationId, status],
    () =>
      api
        .get<any>(`/home_pages/${homePageId}`, {
          params: {
            status,
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled,
    }
  );
};

export default useHomePage;
