import { useNavigate } from 'react-router-dom';
import { SparklesIcon } from '@heroicons/react/24/outline';

import { UpgradeIntent } from '@/components/UpgradeIntent';
import { useHomePage } from '@/hooks/useHomePages';
import { useUpdateWebTemplatePages } from '@/hooks/useWebTemplates';
import { Endpoints } from '@/hooks/useWebTemplates/useUpdateWebTemplatePages';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { HelperTextWrapper, Section } from '../../../../Components';
import InputForm from '../../../../Components/InputForm';
import useUpgradeForPages from '../hooks/useUpgradeForPages';

const HomePageSection = () => {
  const navigate = useNavigate();
  const { isOpen, handleClose, intentAction, preselectedPlan, isPagesEnabled, handleOpen } = useUpgradeForPages();

  const homePageQuery = useHomePage({ status: 'draft' });
  const { data: homePage } = homePageQuery;
  const homePagesMutation = useUpdateWebTemplatePages({
    endpoint: Endpoints.UPDATE_HOME_PAGES,
  });

  const updateHomePages = async (updateValue: any) => {
    homePagesMutation.mutateAsync({
      home_page: {
        seo_section: {
          ...homePage?.seo_section,
          ...updateValue,
        },
      },
    });
  };

  const handleOnDesignClick = () => {
    if (isPagesEnabled) {
      analytics.track('Published Custom Home Page');
      navigate('/design_lab/home_page');
    } else {
      handleOpen();
    }
  };

  return (
    <Section
      title="Home Page"
      description="Customize your home page."
      scrollToId="home-page"
      isLoading={!homePage}
      hasDivider
    >
      <UpgradeIntent
        isOpen={isOpen}
        onClose={handleClose}
        intentAction={intentAction}
        preselectedPlan={preselectedPlan}
      />
      <InputForm
        label="Meta Title"
        placeholder="Meta Title"
        value={homePage?.seo_section?.title || ''}
        onSave={(updateTitle: string) => updateHomePages({ title: updateTitle })}
        isSaving={homePagesMutation.isLoading}
        toastSuccessMessage="Saved!"
      />
      <InputForm
        label="Meta Description"
        placeholder="Meta Description"
        value={homePage?.seo_section?.description || ''}
        onSave={(updateDescription: string) => updateHomePages({ description: updateDescription })}
        isSaving={homePagesMutation.isLoading}
        toastSuccessMessage="Saved!"
      />
      <HelperTextWrapper
        helperText="*Basic design features are avilable in general, this feature is only available on scale plans."
        isTextRendered={!isPagesEnabled}
      >
        <div>
          <Button Icon={SparklesIcon} onClick={handleOnDesignClick}>
            Design Home Page
          </Button>
        </div>
      </HelperTextWrapper>
    </Section>
  );
};

export default HomePageSection;
