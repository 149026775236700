// eslint-disable-next-line
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import { useCurrentPublication } from '@/hooks';
import analytics from '@/utils/analytics';
import { Layout, LeftPane, RightPane } from '../_components/Layout';
import UpgradeOption from './_components/UpgradeOption';
import { capitalize } from '@/utils';
import TrialOption from './_components/TrialOption';
import OnboardingStepForm from '../_components/OnboardingStepForm';
import useUpdateOnboarding from '../_hooks/useUpdateOnboarding';
import useCreateTrial from '../_hooks/useCreateTrial';

const OnboardingTrialFeatures = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { data: currentPublication } = useCurrentPublication();
  const updateOnboarding = useUpdateOnboarding();
  const createTrial = useCreateTrial();

  const [isRequesting, setIsRequesting] = useState(false);
  const selectedPlan: 'scale' | 'max' | 'launch' =
    (localStorage.getItem('selectedPlan') as 'scale' | 'max' | 'launch') || 'scale';
  const planOptions = {
    scale: ['Ad Network', 'Paid Subscriptions', 'Boosts'],
    max: ['NewsletterXP Course', 'Up to 10 Publications', 'Priority Support'],
    launch: [],
  };

  const handleTrial = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsRequesting(true);

    updateOnboarding
      .mutateAsync({ status: 'complete' })
      .then(() => {
        if (currentUser?.id) {
          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
            state: 'trialing',
            currentPlan: 'launch',
          });
        }
        analytics.track('Completed Onboarding', { email: currentUser?.email });
        analytics.track('Trial Started', {
          state: 'trialing',
          currentPlan: 'launch',
          trialPlan: selectedPlan,
        });
      })
      .then(() => createTrial.mutateAsync({ selectedPlan }))
      .finally(() => queryClient.invalidateQueries(['publications', currentPublication?.id]));
  };

  return (
    <>
      <Helmet>
        <title>Start Free Trial - beehiiv</title>
      </Helmet>

      <Layout>
        <LeftPane>
          <OnboardingStepForm
            title="You're almost in, just one more step to go:"
            subtitle="How would you like to get started?"
            className="max-w-5xl"
            onBack={() => navigate('/onboarding/business_questionnaire')}
            centered
          >
            <div className="w-full items-start space-y-6 flex flex-col md:flex-row md:space-x-10 md:space-y-0">
              <UpgradeOption planName={capitalize(selectedPlan)} upgradeOptions={planOptions[selectedPlan]} />
              <TrialOption
                planName={capitalize(selectedPlan)}
                onClick={handleTrial}
                excludedOptions={planOptions[selectedPlan]}
                isProcessing={isRequesting}
              />
            </div>
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingTrialFeatures;
