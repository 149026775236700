export const zoomToReactFlowNode = (nodeId: string, fitView: any) => {
  setTimeout(
    () =>
      fitView({
        nodes: [
          {
            id: nodeId,
          },
        ],
        duration: 800,
      }),
    300
  );
};
