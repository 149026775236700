import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import analytics from '@/utils/analytics';

import { BillingActions } from '../../interfaces/billing';
import api from '../../services/swarm';

interface ApiResponse {
  stripe_session_url: string;
}

interface Props {
  organizationId?: string;
  publicationId: string;
  action: BillingActions;
  trackingEvent?: string;
}

const useStripeBillingSession = ({ organizationId, publicationId, action, trackingEvent }: Props) => {
  return useMutation<ApiResponse, unknown, { plan?: string; plan_price_id?: string; promotion_code?: string }>(
    ({ plan, plan_price_id, promotion_code }) =>
      api
        .post(`/organizations/${organizationId}/stripe_sessions`, {
          publication_id: publicationId,
          stripe_session: {
            action,
            plan,
            plan_price_id,
            promotion_code,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        if (trackingEvent) analytics.track(trackingEvent);
        window.location.replace(data.stripe_session_url);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useStripeBillingSession;
