import { useState } from 'react';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import CustomFieldSelect from '@/components/CustomFieldSelect';
import Modal from '@/components/Modal';
import { Typography, TypographyStack } from '@/components/Typography';
import useCreateCustomField from '@/hooks/useCreateCustomField';
import { CustomField, CustomFieldKind } from '@/interfaces/custom_field';
import {
  PublicationSubscriberPreference,
  SubscriberPreferenceDisplayType,
  SubscriberPreferenceType,
  SubscriberPreferenceVisibility,
} from '@/interfaces/subscribers_preferences';
import { Dropdown } from '@/ui/Dropdown';
import HelperText from '@/ui/HelperText';
import Switch from '@/ui/Switch';
import { Textarea } from '@/ui/Textarea';
import Input from '@/ui/TextInput';
import analytics from '@/utils/analytics';

import { LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE } from '../../preferences/constants';
import { usePreferencesContext } from '../../preferences/context';
import CustomFieldForm from '../CustomFieldForm';

import OptionsForm from './OptionsForm';

const getAllowedCustomFieldType = (
  preferenceType: SubscriberPreferenceType,
  displayType: SubscriberPreferenceDisplayType
) => {
  switch (preferenceType) {
    case SubscriberPreferenceType.MULTIPLE_CHOICE:
      return displayType === SubscriberPreferenceDisplayType.RADIO_BUTTONS
        ? CustomFieldKind.STRING
        : CustomFieldKind.LIST;
    case SubscriberPreferenceType.BOOLEAN:
      return CustomFieldKind.BOOLEAN;
    case SubscriberPreferenceType.DROPDOWN:
      return CustomFieldKind.STRING;
    case SubscriberPreferenceType.TEXT:
      return CustomFieldKind.STRING;
    default:
      return CustomFieldKind.STRING;
  }
};

const Form = () => {
  const [showCustomFieldFormModal, setShowCustomFieldFormModal] = useState(false);

  const { editablePreference, setEditablePreference } = usePreferencesContext();

  const handleChangeInAttribute = (newEditablePreference: Partial<PublicationSubscriberPreference>) => {
    setEditablePreference({ ...editablePreference, ...newEditablePreference });
  };

  const handleChangeInPreferenceType = (newValue: SubscriberPreferenceType) => {
    // Set display type to be unstyled when type is text or boolean
    // and reset options
    if ([SubscriberPreferenceType.TEXT, SubscriberPreferenceType.BOOLEAN].includes(newValue)) {
      handleChangeInAttribute({
        preference_type: newValue,
        display_type: SubscriberPreferenceDisplayType.UNSTYLED,
        subscriber_preference_options: [],
      });
      return;
    }
    if (newValue === SubscriberPreferenceType.MULTIPLE_CHOICE) {
      handleChangeInAttribute({
        preference_type: newValue,
        display_type: SubscriberPreferenceDisplayType.RADIO_BUTTONS,
      });
      return;
    }

    handleChangeInAttribute({ preference_type: newValue });
  };

  const showDisplayType =
    editablePreference.preference_type === SubscriberPreferenceType.MULTIPLE_CHOICE &&
    editablePreference.display_type !== SubscriberPreferenceDisplayType.RADIO_BUTTONS;

  const showOptionsForm = [SubscriberPreferenceType.DROPDOWN, SubscriberPreferenceType.MULTIPLE_CHOICE].includes(
    editablePreference.preference_type
  );

  const { mutateAsync: createCustomField } = useCreateCustomField((newCustomField: CustomField) => {
    setShowCustomFieldFormModal(false);
    setEditablePreference({
      ...editablePreference,
      custom_field_id: newCustomField.id,
    });
  });

  const handleSubmitCustomFieldForm = async (display: any, kind: any) => {
    analytics.track('Created a custom field', {
      view: 'Subscriber Preferences Form',
    });
    await createCustomField({ display, kind });
  };

  const allowedCustomFieldKind = getAllowedCustomFieldType(
    editablePreference.preference_type,
    editablePreference.display_type
  );

  return (
    <>
      <Modal
        className="w-full sm:w-1/3"
        isOpen={showCustomFieldFormModal}
        onClose={() => setShowCustomFieldFormModal(false)}
      >
        <CustomFieldForm
          header="New Custom Field"
          footerClassName="flex justify-end"
          onSubmit={handleSubmitCustomFieldForm}
          onCancel={() => setShowCustomFieldFormModal(false)}
          allowedKindOptions={[allowedCustomFieldKind]}
          customField={{
            kind: allowedCustomFieldKind,
          }}
        />
      </Modal>
      <form>
        <div className="flex flex-col gap-y-5">
          <Input
            name="name"
            labelText="Name"
            placeholderText="Name of preference"
            type="text"
            value={editablePreference.name}
            onChange={(e) => handleChangeInAttribute({ name: e.currentTarget.value })}
            required
          />
          <Textarea
            name="description"
            labelText="Description"
            value={editablePreference.description}
            rows={2}
            onChange={(e) => handleChangeInAttribute({ description: e.currentTarget.value })}
            placeholderText="Short description of preference"
          />
          <Dropdown
            labelText="Type"
            required
            helperText="Choose a type of configuration format for the preference"
            options={[
              {
                label: LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE[SubscriberPreferenceType.MULTIPLE_CHOICE],
                value: SubscriberPreferenceType.MULTIPLE_CHOICE,
                icon: CheckCircleIcon,
              },
              {
                label: LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE[SubscriberPreferenceType.TEXT],
                value: SubscriberPreferenceType.TEXT,
              },
              {
                label: LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE[SubscriberPreferenceType.DROPDOWN],
                value: SubscriberPreferenceType.DROPDOWN,
                icon: ChevronDownIcon,
              },
              {
                label: LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE[SubscriberPreferenceType.BOOLEAN],
                value: SubscriberPreferenceType.BOOLEAN,
              },
            ]}
            name="preference_type"
            disabled={!!editablePreference.id}
            value={editablePreference.preference_type}
            onSelect={(_, value) => handleChangeInPreferenceType(value)}
          />
          {editablePreference.preference_type === SubscriberPreferenceType.MULTIPLE_CHOICE ? (
            <Switch
              name="allow-multiple-responses"
              checked={[
                SubscriberPreferenceDisplayType.CHECKBOXES,
                SubscriberPreferenceDisplayType.NUMERALS,
                SubscriberPreferenceDisplayType.UNSTYLED,
              ].includes(editablePreference.display_type)}
              labelText="Allow multiple responses"
              disabled={!!editablePreference.id}
              onChange={(_, checked) =>
                handleChangeInAttribute({
                  display_type: checked
                    ? SubscriberPreferenceDisplayType.CHECKBOXES
                    : SubscriberPreferenceDisplayType.RADIO_BUTTONS,
                })
              }
            />
          ) : null}
          {showDisplayType ? (
            <Dropdown
              labelText="Options Format"
              required
              helperText="Choose how you want your multiple choice options to be displayed"
              options={[
                { label: 'Checkbox', value: SubscriberPreferenceDisplayType.CHECKBOXES },
                { label: 'Numerals', value: SubscriberPreferenceDisplayType.NUMERALS },
                { label: 'Unstyled', value: SubscriberPreferenceDisplayType.UNSTYLED },
              ]}
              name="display_type"
              value={editablePreference.display_type}
              onSelect={(_, value) => handleChangeInAttribute({ display_type: value })}
            />
          ) : null}
          {showOptionsForm ? <OptionsForm /> : null}
          <div className="flex flex-col gap-y-2">
            <CustomFieldSelect
              disabled={!!editablePreference.id}
              customFieldId={editablePreference.custom_field_id}
              labelText="Custom Field *"
              helperText=""
              className="w-full"
              onSelectCustomField={(customFieldId) => handleChangeInAttribute({ custom_field_id: customFieldId })}
              onClearCustomField={() => handleChangeInAttribute({ custom_field_id: undefined })}
              refetchFetchOptions={false}
              filterByKind={getAllowedCustomFieldType(
                editablePreference.preference_type,
                editablePreference.display_type
              )}
            />
            <HelperText>
              You can only use data type custom fields you have created or create a{' '}
              <Typography token="font-semibold/text/xs" colorWeight="700" color="secondary">
                <button
                  type="button"
                  disabled={!!editablePreference.id}
                  onClick={() => setShowCustomFieldFormModal(true)}
                >
                  new custom field
                </button>
              </Typography>
              .
            </HelperText>
          </div>

          <div className="flex flex-col gap-y-4">
            <TypographyStack>
              <Typography token="font-medium/text/base">Preference Center Settings</Typography>
              <Typography token="font-normal/text/sm" colorWeight="700">
                Determine if you want this preference to be shown on your subscriber management preference center page
              </Typography>
            </TypographyStack>
            <Switch
              name="visibility"
              checked={editablePreference.visibility === SubscriberPreferenceVisibility.VISIBLE}
              labelText="Visible"
              onChange={(_, checked) =>
                handleChangeInAttribute({
                  visibility: checked ? SubscriberPreferenceVisibility.VISIBLE : SubscriberPreferenceVisibility.HIDDEN,
                })
              }
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default Form;
