import { LogicalOperators } from '../condition';

export enum AutomationConditionName {
  CAMPAIGN = 'campaign',
  CHANNEL = 'channel',
  CUSTOM_FIELD_ID = 'custom_field_id',
  SEGMENT_TAG_ID = 'subscriber_tag_id',
  EMAIL = 'email',
  EMAIL_SUBMISSION_SOURCE = 'email_submission_source',
  EXTERNAL_EMBED_ID = 'external_embed_id',
  FORM_ID = 'form_id',
  LANDING_PAGE = 'landing_page',
  LAST_CLICKED_DAYS_AGO = 'last_clicked_days_ago',
  LAST_OPENED_DAYS_AGO = 'last_opened_days_ago',
  LAST_OPENED_OR_CLICKED_DAYS_AGO = 'last_opened_or_clicked_days_ago',
  MEDIUM = 'medium',
  POLL_ID = 'poll_id',
  POLL_CHOICE_ID = 'poll_choice_id',
  REFERRING_URL = 'referring_url',
  REFERRAL_ACTION = 'referral_action',
  SEGMENT_ID = 'segment_id',
  SOURCE = 'source',
  TIER_ID = 'tier_id',
}

export enum AutomationConditionOperator {
  CONTAIN = 'contain',
  DOES_NOT_EXIST = 'does_not_exist',
  END_WITH = 'end_with',
  EQUAL = 'equal',
  EXISTS = 'exists',
  GREATER_THAN = 'greater_than',
  GREATER_THAN_EQUAL_TO = 'greater_than_equal_to',
  LESS_THAN = 'less_than',
  LESS_THAN_EQUAL_TO = 'less_than_equal_to',
  NOT_CONTAIN = 'not_contain',
  NOT_EQUAL = 'not_equal',
  START_WITH = 'start_with',
}

export interface AutomationCondition {
  name: AutomationConditionName;
  operator: AutomationConditionOperator;
  value: string;
  resource_id?: string;
  hasErrors?: boolean;
}

export interface AutomationConditions {
  logical_operator?: LogicalOperators;
  conditions?: AutomationCondition[];
}
