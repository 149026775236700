import styled, { css } from 'styled-components';

import { colors } from '../../../lib/colors';
import { Styled as StyledIcon } from '../Icon/styled';

import { primaryButtonStyles } from './primary.styled';
import { quaternaryButtonStyles } from './quaternary.styled';
import { secondaryButtonStyle } from './secondary.styled';
import { tertiaryButtonStyles } from './tertiary.styled';
import { StyledButtonProps } from './types';

const defaultSizeStyles = css<StyledButtonProps>`
  padding: ${({ $isIconButton }) => {
    const paddingTop = '0.625rem';
    let paddingRight = $isIconButton ? '0.625rem' : '0.875rem';
    const paddingBottom = '0.625rem';
    let paddingLeft = $isIconButton ? '0.625rem' : '0.875rem';

    if (!$isIconButton) {
      paddingRight = '0.375rem';
      paddingLeft = '0.375rem';
    }

    return `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`;
  }};
`;

const smallSizeStyles = css<StyledButtonProps>`
  font-size: 0.875rem;
  padding: ${({ $isIconButton }) => {
    const paddingTop = '0.3125rem';
    let paddingRight = $isIconButton ? '0.3125rem' : '0.5rem';
    const paddingBottom = '0.3125rem';
    let paddingLeft = $isIconButton ? '0.3125rem' : '0.5rem';

    if (!$isIconButton) {
      paddingRight = '0.375rem';
      paddingLeft = '0.375rem';
    }

    return `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`;
  }};
`;

const colorTileButtonStyles = css<StyledButtonProps>`
  align-items: center;
  align-self: center;
  justify-content: center;
  padding: 0.375rem;

  &:hover {
    background: ${colors.white[7]};
  }

  &::before {
    background: var(--background);
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-size: 120% 120%;
    background-position: center;
    border: 1px solid ${colors.white[7]};
    border-radius: 0.375rem;
    content: '';
    height: 1rem;
    width: 1rem;
  }

  &.is-active,
  &:hover {
    &::after {
      border: 1px solid ${colors.white[3]};
      border-radius: 0.5rem;
      content: '';
      height: calc(1rem + 0.375rem);
      position: absolute;
      width: calc(1rem + 0.375rem);
    }
  }

  &.is-active {
    &::after {
      border: 2px solid ${colors.pink[6]};
      height: calc(1rem + 0.3125rem);
      width: calc(1rem + 0.3125rem);
    }
  }
`;

export const Styled = {
  Button: styled.button<StyledButtonProps>`
    align-items: center;
    background: 0;
    border: 0;
    border-radius: 8px;
    display: inline-flex;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    text-decoration: none;

    &[disabled] {
      cursor: default;
    }

    ${({ $fullWidth, $rightSlot }) => {
      if ($rightSlot) {
        return (
          $fullWidth &&
          css`
            width: 100%;

            &&& ${StyledIcon.Wrapper}:last-child {
              margin-left: auto;
            }
          `
        );
      }

      return (
        $fullWidth &&
        css`
          width: 100%;
        `
      );
    }}

    ${({ $variant }) => $variant === 'primary' && primaryButtonStyles}
    ${({ $variant }) => $variant === 'secondary' && secondaryButtonStyle}
    ${({ $variant }) => $variant === 'tertiary' && tertiaryButtonStyles}
    ${({ $variant }) => $variant === 'quaternary' && quaternaryButtonStyles}

    ${({ $size }) => $size === 'default' && defaultSizeStyles}
    ${({ $size }) => $size === 'small' && smallSizeStyles}

    ${({ $isColorTileButton }) => $isColorTileButton && colorTileButtonStyles}

    ${StyledIcon.Wrapper} {
      margin-left: ${({ $isIconButton, $rightSlot }) => ($rightSlot && !$isIconButton ? '0.5rem' : '0')};
      margin-right: ${({ $isIconButton, $leftSlot }) => ($leftSlot && !$isIconButton ? '0.5rem' : '0')};

      &:first-child {
        margin-left: ${({ $isIconButton, $rightSlot }) => $rightSlot && !$isIconButton && '0'};
      }

      &:last-child {
        margin-right: ${({ $isIconButton, $leftSlot }) => $leftSlot && !$isIconButton && '0'};
      }

      svg,
      path {
        fill: currentColor;
      }
    }
  `,
  Text: styled.span``,
};

export default Styled;
