import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AdjustmentsVerticalIcon } from '@heroicons/react/20/solid';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import Card from '@/components/Card';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import MetadataForm from '@/pages/AutomationView/Settings/MetadataForm';
import FormTitle from '@/pages/Segments/FormTitle';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

const NewAutomation = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [automationName, setAutomationName] = useState('');
  const [automationDescription, setAutomationDescription] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    setCanSave(!!automationName);
  }, [automationName]);

  const handleSaveAutomation = async (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSaving(true);

    try {
      const response = await api.post(`/automations`, {
        name: automationName,
        description: automationDescription,
        publication_id: currentPublicationId,
      });

      queryClient.setQueriesData(['automations', 'automation', response.data.id], response.data);
      toast.success('Automation created successfully');
      navigate(`/automations/${response.data.id}/workflow`);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong, please try again');
    }

    setIsSaving(false);
  };

  const handleNameChange = (value: string) => setAutomationName(value);
  const handleDescriptionChange = (value: string) => setAutomationDescription(value);

  return (
    <div className="mb-24">
      <Helmet>
        <title>New Automation</title>
      </Helmet>

      <div className="flex flex-row justify-between items-center w-full mb-4">
        <Breadcrumbs>
          <Breadcrumbs.Item to="/automations">Automations</Breadcrumbs.Item>
          <Breadcrumbs.Item to="/automations/new">New Automation</Breadcrumbs.Item>
        </Breadcrumbs>

        <Button
          type="button"
          disabled={!canSave}
          loading={isSaving}
          onClick={handleSaveAutomation}
          iconRight
          Icon={AdjustmentsVerticalIcon}
        >
          Save Automation
        </Button>
      </div>

      <Card>
        <FormTitle>New Automation</FormTitle>
        <MetadataForm
          automationName={automationName || ''}
          automationDescription={automationDescription}
          onAutomationNameChange={handleNameChange}
          onAutomationDescriptionChange={handleDescriptionChange}
        />
      </Card>
    </div>
  );
};

export default NewAutomation;
