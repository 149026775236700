import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { EmojiItem } from '@tiptap-pro/extension-emoji';

import { Button } from '../../components/ui/Button';
import { Panel } from '../../components/ui/Panel';

import { EmojiListProps } from './types';

const EmojiList = forwardRef((props: EmojiListProps, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ name: item.name });
    }
  };

  const scrollIntoView = (index: number) => {
    const item = props.items[index];

    if (item) {
      const node = document.querySelector(`[data-emoji-name="${item.name}"]`);

      if (node) {
        node.scrollIntoView({ block: 'nearest' });
      }
    }
  };

  const upHandler = () => {
    const newIndex = (selectedIndex + props.items.length - 1) % props.items.length;
    setSelectedIndex(newIndex);
    scrollIntoView(newIndex);
  };

  const downHandler = () => {
    const newIndex = (selectedIndex + 1) % props.items.length;
    setSelectedIndex(newIndex);
    scrollIntoView(newIndex);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
          if (event.key === 'ArrowUp') {
            upHandler();
            return true;
          }

          if (event.key === 'ArrowDown') {
            downHandler();
            return true;
          }

          if (event.key === 'Enter') {
            enterHandler();
            return true;
          }

          return false;
        },
      };
    },
    [upHandler, downHandler, enterHandler]
  );

  if (!props.items || !props.items.length) {
    return null;
  }

  return (
    <Panel className="overflow-y-auto" style={{ maxHeight: '18rem' }}>
      {props.items.map((item: EmojiItem, index: number) => (
        <Button
          $active={index === selectedIndex}
          $variant="quaternary"
          $fullWidth
          $size="small"
          $leftSlot={item.fallbackImage ? <img src={item.fallbackImage} className="w-5 h-5" alt="emoji" /> : item.emoji}
          $rightSlot={<span className="ml-2 truncate text-ellipsis">:{item.name}:</span>}
          key={item.name}
          onClick={() => selectItem(index)}
          data-emoji-name={item.name}
        />
      ))}
    </Panel>
  );
});

export default EmojiList;
