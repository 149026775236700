import DnsRecordDisplay from '@/components/_domain/CustomDomain/DnsRecordDisplay';
import { Typography, TypographyStack } from '@/components/Typography';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';

import { customDomainRecords, isDomainVerified } from '../../utils';

interface Props {
  customDomain: CustomDomain;
}

const Web = ({ customDomain }: Props) => {
  const { domain } = customDomain;
  const records = customDomainRecords(customDomain, CustomDomainTypes.REDIRECT);
  const verified = isDomainVerified(customDomain, CustomDomainTypes.REDIRECT);

  return (
    <div className="space-y-4">
      <TypographyStack gap="4">
        {verified ? (
          <Typography token="font-normal/text/sm" colorWeight="500" as="p">
            This DNS record has been verified and your {domain} redirect is live. Please make sure to keep this record
            in place to ensure your redirect remains functional. For more information, visit the{' '}
            <Typography token="font-medium/text/sm" colorWeight="600" color="secondary" className="cursor-pointer">
              <a
                href="https://www.beehiiv.com/support/article/14492990172823-How-to-add-and-configure-custom-domains"
                target="_blank"
                rel="noopener noreferrer"
              >
                beehiiv knowledge base
              </a>
            </Typography>{' '}
            or check your DNS provider&quot;s documentation.
          </Typography>
        ) : (
          <>
            <Typography token="font-normal/text/sm" colorWeight="500" as="p">
              Go to your DNS provider and create the following DNS record. Once done, click “Verify Setup” below to
              verify your configuration. We additionally will check on your behalf over the next 24 hours.
            </Typography>

            <Typography token="font-normal/text/sm" colorWeight="500" as="p">
              This record will ensure that your redirect will work over your custom domain.
            </Typography>
          </>
        )}
      </TypographyStack>

      <div className="space-y-4">
        {records.map((record) => (
          <DnsRecordDisplay key={record.name} record={record} />
        ))}
      </div>
    </div>
  );
};

export default Web;
