interface Props {
  children: React.ReactNode;
  helperText: string;
  isTextRendered?: boolean;
}

const HelperTextWrapper = ({ children, helperText, isTextRendered }: Props) => {
  return (
    <div className="flex flex-col space-y-2">
      {children}
      {isTextRendered && <p className="text-xs font-light text-gray-700 w-full">{helperText}</p>}
    </div>
  );
};

export default HelperTextWrapper;
