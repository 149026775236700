export interface Font {
  id: string;
  name: string;
  normal_url: string;
  normal_italic_url: string;
  bold_url: string;
  bold_italic_url: string;
  fallback: string;
  format: string;
  web_safe: boolean;
}

export type FontSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';

export const FONT_SIZE_OPTIONS = [
  {
    label: 'XS',
    value: 'xs',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
  {
    label: 'XL',
    value: 'xl',
  },
  {
    label: '2XL',
    value: '2xl',
  },
  {
    label: '3XL',
    value: '3xl',
  },
  {
    label: '4XL',
    value: '4xl',
  },
  {
    label: '5XL',
    value: '5xl',
  },
  {
    label: '6XL',
    value: '6xl',
  },
];
