import { useEffect, useState } from 'react';
import { getUsedFonts, NavbarSerializableNode } from '@shared/dream-components';

import { useWebsiteContext } from '@/context/website-context';

import { useNavbarContext } from '../../navbar/_components/NavbarEditor/NavbarContext';

export const useNavbarFontHrefs = () => {
  const { previewSiteVersion } = useWebsiteContext();
  const { content: draftContent } = useNavbarContext();
  const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());

  const content = (previewSiteVersion ? previewSiteVersion?.navbar : draftContent) as NavbarSerializableNode;

  useEffect(() => {
    getUsedFonts(content, ['Inter']).then((f) => setFontHrefs(new Set(f)));
  }, [content]);

  return fontHrefs;
};
