import { TextT } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';

type Props = AttributeSettingProps & {};

export const TitleSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Title" text="Title" popoverTitle="Title" Icon={TextT}>
      <>
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          property="titleFontFamily"
          title="Font"
        />
        <SimpleFontSizeSettings
          title="Size"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="titleFontSize"
        />
        <ColorSettings title="Color" editor={editor} activeNodeResult={activeNodeResult} property="titleColor" />
      </>
    </PopoverHelper>
  );
};

export default TitleSettings;
