import { useMutation } from 'react-query';

import api from '../../services/swarm';

const useDisconnectIntegration = (publicationId: string) => {
  return useMutation<{ message: string }, { error: string }, { integrationId: string }>(({ integrationId }) =>
    api.delete(`/integrations/${integrationId}`, {
      params: {
        publication_id: publicationId,
      },
    })
  );
};

export default useDisconnectIntegration;
