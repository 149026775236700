import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostInvite } from '@/interfaces/boosts/grow/boost_invite';
import { Pagination } from '@/interfaces/general';
import { PublicationSearchWithBoostInvite } from '@/interfaces/publication_search';
import api from '@/services/swarm';

interface ApiResponse {
  publications: PublicationSearchWithBoostInvite<BoostInvite>[];
  pagination: Pagination;
}

interface Arguments {
  query: string;
  country?: string;
  language?: string;
}

export default function usePublicationSearch({ query = '*', country, language }: Arguments) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPublications = ({ pageParam = 1 }) =>
    api
      .get(`/boosts/grow/publication_search`, {
        params: {
          page: pageParam,
          per_page: 50,
          publication_id: currentPublicationId,
          query,
          country: country === 'not_specified' ? null : country,
          language: language === 'not_specified' ? null : language,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'boosts', 'grow', 'publication_search', query, language, country],
    fetchPublications,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
