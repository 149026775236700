import { createPortal } from 'react-dom';

import { LoadingDots } from './LoadingDots';
import { Styled } from './styled';
import { LoaderProps, LoadingWrapperProps } from './types';

const LoadingWrapper = ({ label }: LoadingWrapperProps) => {
  return (
    <Styled.Loader>
      <LoadingDots />
      {label && <Styled.Label>{label}</Styled.Label>}
    </Styled.Loader>
  );
};

export const Loader = ({ hasOverlay = true, label }: LoaderProps) => {
  return hasOverlay ? (
    createPortal(
      <Styled.Overlay>
        <LoadingWrapper label={label} />
      </Styled.Overlay>,
      document.body
    )
  ) : (
    <LoadingWrapper label={label} />
  );
};

export default Loader;
