import { useEffect, useState } from 'react';

import { useCurrentPublication } from '@/hooks';
import { useCreatePublicationSubscriberTag } from '@/hooks/useSubscriberTags';
import { PublicationSubscriberTagParams } from '@/interfaces/subscriber_tag';

import Form from './Form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const NewTag = ({ isOpen, onClose }: Props) => {
  const createPublicationSubscriberTag = useCreatePublicationSubscriberTag();
  const defaultNewParams: PublicationSubscriberTagParams = {
    name: '',
    color: 'gray',
    subscriber_emails: '',
  };
  const { data: currentPublication } = useCurrentPublication();
  const [newPublicationSubscriberTag, setNewPublicationSubscriberTag] =
    useState<PublicationSubscriberTagParams>(defaultNewParams);

  useEffect(() => {
    if (isOpen) {
      setNewPublicationSubscriberTag(defaultNewParams);
    }
  }, [currentPublication, isOpen]);

  const handleSubmit = async (params: PublicationSubscriberTagParams) => {
    return createPublicationSubscriberTag.mutateAsync(params);
  };

  return (
    <Form
      description="Fill in the information below to create your new tag."
      subscriberTag={newPublicationSubscriberTag}
      onSuccess={onClose}
      bodyId={currentPublication?.id || ''}
      isOpen={isOpen}
      onClose={() => {
        setNewPublicationSubscriberTag(defaultNewParams);
        onClose();
      }}
      headerText="New Tag"
      handleSubmit={handleSubmit}
    />
  );
};

export default NewTag;
