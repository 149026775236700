import { useQuery } from 'react-query';

import { EmailMessage } from '../interfaces/email_message';
import api from '../services/swarm';

const useWelcomeEmail = (publicationId: string) => {
  return useQuery<EmailMessage, Error>(
    ['welcome_email', publicationId],
    () => api.get(`/welcome_email`, { params: { publication_id: publicationId } }).then((res) => res.data),
    {
      staleTime: Infinity,
      cacheTime: 0,
      enabled: !!publicationId,
      keepPreviousData: true,
    }
  );
};

export default useWelcomeEmail;
