import { FC } from 'react';

import ColorField from '../../components/Form/ColorField';

import ExpandableSlider from './components/ExpandableSlider';
import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { ThemeFormProps } from './types';

const ImageForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette, fonts }) => {
  return (
    <FormContainer>
      <FormSection title="Image Source">
        <FormInput>
          <Slider
            name="image_source_y_spacing"
            label="Vertical spacing"
            onChange={(val: number) => onUpdate({ ...theme, image_source_y_spacing: val.toString() })}
            initialValue={theme.image_source_y_spacing}
            min={0}
            max={24}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="image_source_x_spacing"
            label="Horizontal spacing"
            onChange={(val: number) => onUpdate({ ...theme, image_source_x_spacing: val.toString() })}
            initialValue={theme.image_source_x_spacing}
            min={0}
            max={40}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Text alignment">
          <ThemeEditorDropdown
            currentValue={theme.image_source_alignment}
            onUpdate={(val: string) => onUpdate({ ...theme, image_source_alignment: val })}
            options={[
              { label: 'Left', value: 'left' },
              { label: 'Center', value: 'center' },
              { label: 'Right', value: 'right' },
            ]}
          />
        </FormInput>
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.image_source_font_family}
              onUpdate={(val: string) => onUpdate({ ...theme, image_source_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Text style">
          <ThemeEditorDropdown
            currentValue={theme.image_source_style}
            onUpdate={(val: string) => onUpdate({ ...theme, image_source_style: val })}
            options={[
              { label: 'Normal', value: 'normal' },
              { label: 'Italic', value: 'italic' },
            ]}
          />
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.image_source_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, image_source_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Spacing">
        <FormInput>
          <ExpandableSlider
            fields={{
              image_padding_top: 'Top',
              image_padding_bottom: 'Bottom',
            }}
            theme={theme}
            onUpdate={onUpdate}
            min={0}
            max={80}
            title="Space around image"
          />
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default ImageForm;
