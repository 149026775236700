import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface ApiResponse {
  id: string;
}

interface Props {
  formId: string;
  formQuestionId: string;
  optionId: string;
  onSuccess: (result: ApiResponse) => void;
}

const useUpdateFormQuestionOption = ({ formId, formQuestionId, optionId, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, { value: string }>(
    ({ value }) =>
      api
        .patch(`/form_question_options/${optionId}`, {
          publication_id: currentPublicationId,
          form_id: formId,
          form_question_id: formQuestionId,
          form_question_option: {
            value,
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useUpdateFormQuestionOption;
