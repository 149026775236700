import { createContext } from 'react';

export const AdvertisementOpportunityLogoContext = createContext<{
  sponsorText: string | null;
  setSponsorText: (sponsorText: string | null) => void;
  width: number | null;
  setWidth: (width: number | null) => void;
  align: string | null;
  setAlign: (align: string | null) => void;
}>({
  sponsorText: null,
  setSponsorText: () => {},
  width: null,
  setWidth: () => {},
  align: null,
  setAlign: () => {},
});

export default AdvertisementOpportunityLogoContext;
