import { PropsWithChildren } from 'react';

import { Typography, TypographyStack } from '@/components/Typography';

type Props = PropsWithChildren<{
  title: string;
  description: string;
  hasDivider?: boolean;
}>;

const GroupHeading = ({ title, description, children, hasDivider = true }: Props) => {
  return (
    <div className="flex flex-col space-y-4">
      <TypographyStack gap="1">
        <Typography token="font-medium/text/xs" className="text-400">
          {title}
        </Typography>
        <Typography token="font-normal/text/xs" className="text-400">
          {description}
        </Typography>
      </TypographyStack>
      {children}
      {hasDivider && <hr />}
    </div>
  );
};

export default GroupHeading;
