import { useCallback } from 'react';

import { Asset } from '@/interfaces/asset';

import ImageItem from './ImageItem';

export type AssetGridProps = {
  publicationId: string;
  images: Asset[];
  onSelect: (image: Asset) => void;
  onDelete: (image: Asset, publicationId: string) => void;
  onEditMeta: (image: Asset) => void;
  onEditCrop: (image: Asset) => void;
  onEditColor: (image: Asset) => void;
};

const AssetGrid = ({
  publicationId,
  images,
  onSelect,
  onDelete,
  onEditMeta,
  onEditColor,
  onEditCrop,
}: AssetGridProps) => {
  const handleDelete = useCallback(
    (image: Asset) => {
      onDelete(image, publicationId);
    },
    [publicationId, onDelete]
  );

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full">
      {images.map((image) => (
        <ImageItem
          key={image.id}
          image={image}
          onSelect={onSelect}
          onDelete={handleDelete}
          onEditMeta={onEditMeta}
          onEditCrop={onEditCrop}
          onEditColor={onEditColor}
        />
      ))}
    </div>
  );
};

export default AssetGrid;
