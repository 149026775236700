import { PopoverHelper } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';

import { ColorSettings } from './ColorSettings';

type Property = {
  property: string;
  title: string;
};

type Props = AttributeSettingProps & {
  properties: Property[];
  title: string;
};

export const ColorPopoverGroupSettings = ({ editor, activeNodeResult, properties, title = 'Colors' }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <PopoverHelper
      title={title}
      text=""
      textChild={
        <div className="flex items-center gap-1">
          {properties.map((option) => (
            <div style={{ backgroundColor: activeNodeAttributes[option.property] }} className="w-4 h-4 rounded-md" />
          ))}
        </div>
      }
      popoverTitle={title}
    >
      <div className="flex flex-col gap-4">
        {properties.map((option: Property) => (
          <ColorSettings
            key={option.property}
            title={option.title}
            editor={editor}
            activeNodeResult={activeNodeResult}
            property={option.property}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};
