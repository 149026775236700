import { useEffect } from 'react';

import { Input, RadioSelect } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { Card } from '@/ui/Card';

import BodyContainer from '../../../../_components/BodyContainer';
import CardHeader from '../../../../_components/CardHeader';

import CodeBlock from './CodeBlock';
import CustomOptionNode from './CustomOptionNode';
import RedirectForm from './RedirectForm';
import { FormErrors } from './types';

interface Props {
  errors: FormErrors;
  publicationSubdomain: string | undefined;
  shouldUseDefaultWebDomain: boolean;
  setShouldUseDefaultWebDomain: (value: boolean) => void;
  webDomain: string | null;
  setWebDomain: (value: string | null) => void;
  redirectToWWW: boolean;
  setRedirectToWWW: (value: boolean) => void;
  shouldRedirectToDomain: boolean;
  setShouldRedirectToDomain: (value: boolean) => void;
  redirectToDomain: string | null;
  setRedirectToDomain: (value: string | null) => void;
  hasExistingWebDomain?: boolean;
  hideWebRedirect?: boolean;
}

const WebForm = ({
  errors,
  publicationSubdomain,
  shouldUseDefaultWebDomain,
  setShouldUseDefaultWebDomain,
  webDomain,
  setWebDomain,
  redirectToWWW,
  setRedirectToWWW,
  shouldRedirectToDomain,
  setShouldRedirectToDomain,
  redirectToDomain,
  setRedirectToDomain,
  hasExistingWebDomain = false,
  hideWebRedirect = true,
}: Props) => {
  useEffect(() => {
    if (hasExistingWebDomain) {
      setRedirectToWWW(true);
    }
  }, [hasExistingWebDomain, setRedirectToWWW]);

  return (
    <>
      {!hasExistingWebDomain && (
        <Card>
          <BodyContainer>
            <CardHeader
              title="Web Domain"
              description="In order to link a custom domain to your beehiiv newsletter, we must verify that you own the domain. Add the domain below to start the process. We recommend that you use a subdomain, if possible, as this is generally considered a good practice for both web and email deliverability."
            />
            <RadioSelect
              value={shouldUseDefaultWebDomain ? 'should_use_default_web_domain' : 'should_use_custom_web_domain'}
              CustomOptionNode={CustomOptionNode}
              options={[
                {
                  name: (
                    <Typography token="font-medium/text/sm">
                      Use <CodeBlock>{`${publicationSubdomain}.beehiiv.com`}</CodeBlock>
                    </Typography>
                  ),
                  value: 'should_use_default_web_domain',
                },
                {
                  name: <Typography token="font-medium/text/sm">Enable a new domain or subdomain</Typography>,
                  value: 'should_use_custom_web_domain',
                  children: (
                    <Input
                      labelText="Custom Domain"
                      placeholder="www.yourdomain.com"
                      required
                      name="web_domain"
                      defaultValue={webDomain || undefined}
                      onChange={(e) => e.stopPropagation()}
                      onBlur={(e) => setWebDomain(e.target.value)}
                      errorText={errors.webDomain ? 'Please enter a valid domain' : undefined}
                    />
                  ),
                },
              ]}
              onSelect={(value: string) => {
                if (value === 'should_use_default_web_domain') {
                  setShouldUseDefaultWebDomain(true);
                } else if (value === 'should_use_custom_web_domain') {
                  setShouldUseDefaultWebDomain(false);
                }

                setWebDomain(null);
                setRedirectToDomain(null);
                setRedirectToWWW(false);
              }}
            />
          </BodyContainer>
        </Card>
      )}

      {!hideWebRedirect && (
        <Card>
          <RedirectForm
            hasErrors={errors.redirectToDomain || false}
            webDomain={webDomain}
            redirectToWWW={redirectToWWW}
            setRedirectToWWW={setRedirectToWWW}
            shouldRedirectToDomain={shouldRedirectToDomain}
            setShouldRedirectToDomain={setShouldRedirectToDomain}
            redirectToDomain={redirectToDomain}
            setRedirectToDomain={setRedirectToDomain}
          />
        </Card>
      )}
    </>
  );
};

export default WebForm;
