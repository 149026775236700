import { useCallback, useState } from 'react';

import { TypeaheadMultiSelect } from '@/components/Form';
import { useExternalEmbeds } from '@/hooks/useExternalEmbeds';
import { ExternalEmbed } from '@/interfaces/external_embed';
import { Option } from '@/interfaces/general';

import SelectedExternalEmbeds from './SelectedExternalEmbeds';

interface Props {
  selectedExternalEmbeds: ExternalEmbed[];
  onSelect: (externalEmbed: ExternalEmbed) => void;
  onDeselect: (externalEmbedId: string) => void;
  onDeselectAll: () => void;
}

const SelectExternalEmbed = ({ onSelect, onDeselect, onDeselectAll, selectedExternalEmbeds }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [externalEmbedsInFormState, setExternalEmbedsInFormState] = useState<ExternalEmbed[]>(selectedExternalEmbeds);

  const { refetch, data: externalEmbeds } = useExternalEmbeds({
    search: searchQuery,
  });

  const handleSelect = (name: string, value: string) => {
    const externalEmbedItem = externalEmbeds?.pages
      .flatMap((page) => page.external_embeds)
      ?.find((externalEmbed: ExternalEmbed) => externalEmbed.id === value);

    if (externalEmbedItem) {
      setExternalEmbedsInFormState([externalEmbedItem, ...externalEmbedsInFormState]);
      onSelect(externalEmbedItem);
    }
  };

  const handleDeselect = (name: string, value: string) => {
    onDeselect(value);
    const deselectedAutomationIndex = externalEmbedsInFormState.findIndex(({ id }) => id === value);
    if (deselectedAutomationIndex > -1) {
      const newExternalEmbedsInFormState = [...externalEmbedsInFormState];
      newExternalEmbedsInFormState.splice(deselectedAutomationIndex, 1);
      setExternalEmbedsInFormState(newExternalEmbedsInFormState);
    }
  };

  const handleDeselectAll = () => {
    onDeselectAll();
    setExternalEmbedsInFormState([]);
  };

  const handleRemoveExternalEmbed = (externalEmbedId: string) => handleDeselect('', externalEmbedId);

  const handleClear = () => {
    setSearchQuery('');
  };

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleSearch = useCallback(
    async (): Promise<Option[]> => {
      try {
        const response = await refetch();
        return (
          response?.data?.pages
            .flatMap((page) => page.external_embeds)
            .map((externalEmbed: ExternalEmbed) => ({ label: externalEmbed.name, value: externalEmbed.id })) || []
        );
      } catch (error) {
        // do something
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchQuery]
  );

  return (
    <>
      <input type="hidden" name="external_embed_ids" value={selectedExternalEmbeds.join(', ')} />
      <TypeaheadMultiSelect
        name="post"
        placeholderText="Type name"
        labelText="Subscribe Form"
        values={selectedExternalEmbeds.map(({ id }) => id)}
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        onClear={handleClear}
        onSearchQueryChange={handleSearchQueryChange}
        onDeselectAll={handleDeselectAll}
        onSearch={handleSearch}
        emptyLabel="No Subscribe Form found"
        showSelectedOption
        showClearAll={false}
      />

      <div className="w-full">
        <SelectedExternalEmbeds items={externalEmbedsInFormState} onDeselect={handleRemoveExternalEmbed} />
      </div>
    </>
  );
};

export default SelectExternalEmbed;
