import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const ButtonContainer = styled.div`
  background: ${colors.black[1]};
  border-radius: 0.5rem;
  display: none;
  padding: 0.25rem;
  position: absolute;

  &:has(.is-active) {
    display: inline-block;
  }
`;

export const ImageContainer = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  position: relative;

  img {
    background: ${colors.black[9]};
    height: auto;
    width: 10rem;
  }

  &:hover {
    ${ButtonContainer} {
      display: inline-block;
    }
  }
`;

export const LoaderWrapper = styled.div`
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 1rem;
  color: #888;
  display: flex;
  justify-content: center;
  height: 10rem;
  width: 10rem;
`;
