import { useWebsiteContext } from '@/context/website-context';

import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { OrientationSettings } from '../OrientationSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SliderSettings } from '../SliderSettings';

import { ButtonSettings } from './ButtonSettings';
import { InputSettings } from './InputSettings';

export const SignupWidgetSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { site } = useWebsiteContext();
  const isTermsOfServiceEnabled = site?.draft_site_version?.settings?.terms_of_service_enabled;

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['signup']}>
      {isTermsOfServiceEnabled && (
        <SectionRenderer title="Terms of Service">
          <ColorSettings
            title="Checkbox"
            property="gdprCheckboxColor"
            editor={editor}
            activeNodeResult={activeNodeResult}
          />
          <ColorSettings title="Text" property="gdprTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        </SectionRenderer>
      )}
      <SectionRenderer title="Styles">
        <OrientationSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ColorSettings
          title="Background"
          property="backgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <SliderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="width"
          title="Width"
          unit="px"
          min={300}
          max={1280}
        />
        <SliderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="height"
          title="Height"
          unit="px"
          min={40}
          max={80}
        />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" max={32} />
        <InputSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ButtonSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
