import cx from 'classnames';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography } from '@/components/Typography';

import { TypographyProps } from '../Typography/Typography';

export interface LoadingStateProps {
  className?: string;
  textProps?: TypographyProps;
  text?: string;
}

const LoadingState = ({ className, textProps, text }: LoadingStateProps) => {
  return (
    <div className={cx('flex items-center justify-center w-full h-full', className)}>
      <LoadingSpinner />
      {text && (
        <Typography className="ml-3" token="font-normal/text/sm" {...textProps}>
          {text}
        </Typography>
      )}
    </div>
  );
};

export default LoadingState;
