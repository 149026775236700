import { Link } from 'react-router-dom';

import Text from '@/components/Text';
import { useSettings } from '@/context/settings-context';
import { ActivityFeedEvent, ActivityFeedEventType } from '@/interfaces/activity_feed_event';

const ResourceLink = ({ event }: { event: ActivityFeedEvent }) => {
  const { settings } = useSettings();

  const resourceLink = () => {
    switch (event.resource_type) {
      case 'Post':
        return `/posts/${event.resource_id}`;
      case 'AutomationStepMessage':
        return event.metadata?.redirect_path;
      case 'WelcomeEmailMessage':
        return '/welcome_email';
      case 'EmailBlastMessage':
        return `/blasts/${event.resource_id}`;
      case 'Automation':
        return `/automations/${event.resource_id}`;
      case 'Poll':
        return `/polls/${event.resource_id}`;
      case 'Milestone':
        if (settings?.referral_program_v2 === true) {
          return event.type === ActivityFeedEventType.REFERRAL_MILESTONE_ACHIEVED
            ? '/referral_program/overview'
            : `/referral_program/milestones/${event.resource_id}/edit`;
        }

        return event.type === ActivityFeedEventType.REFERRAL_MILESTONE_ACHIEVED
          ? '/referral_program/configure/rewards'
          : `/referral_program/configure/milestones/${event.resource_id}/edit`;
      case 'Subscriber':
        return `/subscribers/${event.resource_id}`;
      default:
        return null;
    }
  };

  const url = resourceLink();

  return url ? (
    <Link to={url} target="_blank">
      <Text size="sm" as="span" className="font-semibold text-action-tertiary-600">
        {event.resource_title}
      </Text>
    </Link>
  ) : (
    <Text size="sm" as="span" className="font-semibold text-gray-900">
      {event.resource_title}
    </Text>
  );
};

export default ResourceLink;
