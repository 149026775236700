import { DonutChart, Grid, Legend, List, ListItem } from '@tremor/react';

import * as CardUI from '@/components/Card/CardUI';
import { ChartCard, ChartPill } from '@/components/Chartsv2';
import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import { BodyXs, TitleSm } from '@/components/Typography';
import { useOverview } from '@/hooks/useDashboard';
import { OverviewEndpoints } from '@/hooks/useDashboard/useOverview';

import getLocaleString from '../../../utils/getLocaleString';
import { PageProps } from '../types';

const BEEHIIV_CHANNELS = {
  // API_CHANNEL: 'api', --- leaving commentend out for context of what is not being included
  // DEFAULT_CHANNEL: 'website',
  EXTERNAL_EMBED_CHANNEL: 'embed',
  // IMPORT_CHANNEL: 'import',
  REFERRAL_CHANNEL: 'referral',
  RECOMMENDATION_CHANNEL: 'recommendation',
  MAGIC_LINK_CHANNEL: 'magic_link',
  BOOST_CHANNEL: 'boost',
  BOOST_SEND_CHANNEL: 'boost_send',
};

const CHANNELS_ARRAY = Object.values(BEEHIIV_CHANNELS);
const SEO_DISPLAY = 'website: google.com / organic';

const getParsedChartData = (initialData: any[]) => {
  const beehiivArray: any[] = [];
  let beehiivCount = 0;
  const otherArray: any[] = [];
  let otherCount = 0;

  initialData.forEach((item) => {
    const isImportDirect = item.display === 'import: direct / (none)';
    const isGoogleOrganic = item.display === SEO_DISPLAY;
    const channel = item.display?.split(':')[0];
    const isBeehiivPowered = CHANNELS_ARRAY.includes(channel) || isGoogleOrganic;
    if (isBeehiivPowered) {
      beehiivArray.push(item);
      beehiivCount += item.count;
    } else if (!isImportDirect) {
      otherArray.push(item);
      otherCount += item.count;
    }
  });

  return {
    beehiivData: beehiivArray,
    beehiivCount,
    otherData: otherArray,
    otherCount,
  };
};

const SOURCE_CUTOFF = 5;

type Source = {
  display: string;
  count: number;
};

interface AccordionProps {
  title: string;
  totalCount: number;
  data: Source[];
}

const SourcesAccordion = ({ data, title, totalCount }: AccordionProps) => {
  return (
    <>
      <ListItem className="px-4 py-3 bg-surface-50">
        <TitleSm>{title}</TitleSm>
        <TitleSm>{getLocaleString(totalCount)}</TitleSm>
      </ListItem>
      {data.map((item, index) => {
        const isLessThanOrEqualToCutoff = index + 1 <= SOURCE_CUTOFF;
        // This hack was requested specifically by Tyler + EJ to add clarity for users.
        const display = item.display === SEO_DISPLAY ? 'SEO' : item.display;

        if (!isLessThanOrEqualToCutoff) return null;

        return (
          <ListItem key={item.display} className="py-3 pr-4">
            <span className="pl-8">{display}</span>
            <span>{getLocaleString(item.count)}</span>
          </ListItem>
        );
      })}
    </>
  );
};

const BeehiivImpactChart = () => {
  const { period } = usePageContext<PageProps>();

  const { isLoading, data: { acquisition_sources: acquisitionSources } = { acquisition_sources: [] } } = useOverview({
    endpoint: OverviewEndpoints.ACQUISITION_SOURCES,
    timePeriod: period,
  });
  const { beehiivCount, beehiivData, otherData, otherCount } = getParsedChartData(acquisitionSources);
  const noResults = beehiivCount === 0 && otherCount === 0 && !isLoading;

  const chart = [
    {
      name: 'beehiiv',
      count: beehiivCount,
      bgColor: 'bg-action-secondary-500',
    },
    {
      name: 'other',
      count: otherCount,
      bgColor: 'bg-feedback-info-500',
    },
  ];

  return (
    <ChartCard
      title="beehiiv impact"
      description="Subscribers driven through the beehiiv network vs. alternative growth channels"
      noResults={noResults}
      noResultsText="No acquisition sources available within the chosen time range."
      isLoading={isLoading}
    >
      <div className="w-full flex flex-col">
        <Legend categories={['beehiiv', 'other']} className="mt-4" colors={['pink', 'indigo']} />
        <Grid className="grid grid-cols-1 lg:grid-cols-3">
          <div />
          <DonutChart
            className="mt-8 w-full h-60"
            data={chart}
            category="count"
            index="name"
            colors={['pink', 'indigo']}
            valueFormatter={(value) => getLocaleString(value)}
          />
          <div className="flex flex-col space-y-2 justify-center mt-6 pl-4">
            {chart.map((item) => (
              <div key={item.name} className="w-fit">
                <ChartPill bgColor={item.bgColor}>
                  <div className="flex items-center space-x-2 text-white">
                    <span>{item.name}</span>
                    <span>{getLocaleString(item.count)}</span>
                  </div>
                </ChartPill>
              </div>
            ))}
          </div>
        </Grid>

        <div className="flex flex-col items-end w-full mt-6 space-y-2">
          <BodyXs className="itali w-full" weight="normal">
            *Only showing up to the top {SOURCE_CUTOFF} results for each category
          </BodyXs>

          <CardUI.Card hasPadding={false} className="rounded-lg overflow-hidden w-full">
            <List>
              <SourcesAccordion title="beehiiv generated" totalCount={beehiivCount} data={beehiivData} />
              <SourcesAccordion title="other" totalCount={otherCount} data={otherData} />
            </List>
          </CardUI.Card>
        </div>
      </div>
    </ChartCard>
  );
};

export default BeehiivImpactChart;
