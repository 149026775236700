import emojiData from './emoji-data.json';

type EmojiItem = {
  name: string;
  emoji: string;
  shortcodes: string[];
  tags: string[];
  group: string;
  emoticons: string[];
  version: number;
  fallback_image: string | null;
};

export const emojis: EmojiItem[] = emojiData;
