export enum AdNetworkDisbursementStatus {
  NOT_STARTED = 'not_started',
  SCHEDULED = 'scheduled',
  RUNNING = 'running',
  AWAITING_APPROVAL = 'awaiting_approval',
  APPROVED = 'approved',
  PAID = 'paid',
  MANUAL = 'manual',
}

export enum AdNetworkDisbursementStatusLabel {
  NOT_STARTED = 'Not Started',
  SCHEDULED = 'Scheduled',
  RUNNING = 'Running',
  AWAITING_APPROVAL = 'Awaiting Approval',
  APPROVED = 'Approved',
  MANUAL = 'Manual Payout',
  PAID = 'Paid',
}

export enum AdNetworkDisbursementPayoutPlatform {
  BEEHIIV = 'beehiiv',
  MERCURY = 'mercury',
  PAYPAL = 'paypal',
  WISE = 'wise',
  OTHER = 'other',
}

// Clicks Adjustment Reasons
export enum AdNetworkDisbursementClicksAdjustmentReason {
  INFLATED_CLICKS = 'inflated_clicks',
  DUPLICATE_SEND = 'duplicate_send',
  CAPPED_CLICKS = 'capped_clicks',
}

export enum AdNetworkDisbursementClicksAdjustmentReasonLabel {
  INFLATED_CLICKS = 'Inflated clicks',
  DUPLICATE_SEND = 'Duplicate send',
  CAPPED_CLICKS = 'Capped clicks',
}

export const AdNetworkDisbursementClicksAdjustmentReasonLabelHash = {
  [AdNetworkDisbursementClicksAdjustmentReason.INFLATED_CLICKS]:
    AdNetworkDisbursementClicksAdjustmentReasonLabel.INFLATED_CLICKS,
  [AdNetworkDisbursementClicksAdjustmentReason.DUPLICATE_SEND]:
    AdNetworkDisbursementClicksAdjustmentReasonLabel.DUPLICATE_SEND,
  [AdNetworkDisbursementClicksAdjustmentReason.CAPPED_CLICKS]:
    AdNetworkDisbursementClicksAdjustmentReasonLabel.CAPPED_CLICKS,
};

// Payout Adjustment Reasons
export enum AdNetworkDisbursementPayoutAdjustmentReason {
  CHURNED = 'churned',
  BLOCKED = 'blocked',
  FLAT_FEE = 'flat_fee',
}

export enum AdNetworkDisbursementPayoutAdjustmentReasonLabel {
  CHURNED = 'Churned',
  BLOCKED = 'Blocked',
  FLAT_FEE = 'Flat Fee Program',
}

export const AdNetworkDisbursementPayoutAdjustmentReasonLabelHash = {
  [AdNetworkDisbursementPayoutAdjustmentReason.CHURNED]: AdNetworkDisbursementPayoutAdjustmentReasonLabel.CHURNED,
  [AdNetworkDisbursementPayoutAdjustmentReason.BLOCKED]: AdNetworkDisbursementPayoutAdjustmentReasonLabel.BLOCKED,
  [AdNetworkDisbursementPayoutAdjustmentReason.FLAT_FEE]: AdNetworkDisbursementPayoutAdjustmentReasonLabel.FLAT_FEE,
};

export const AdNetworkDisbursementStatusLabelHash = {
  [AdNetworkDisbursementStatus.NOT_STARTED]: AdNetworkDisbursementStatusLabel.NOT_STARTED,
  [AdNetworkDisbursementStatus.SCHEDULED]: AdNetworkDisbursementStatusLabel.SCHEDULED,
  [AdNetworkDisbursementStatus.RUNNING]: AdNetworkDisbursementStatusLabel.RUNNING,
  [AdNetworkDisbursementStatus.AWAITING_APPROVAL]: AdNetworkDisbursementStatusLabel.AWAITING_APPROVAL,
  [AdNetworkDisbursementStatus.APPROVED]: AdNetworkDisbursementStatusLabel.APPROVED,
  [AdNetworkDisbursementStatus.PAID]: AdNetworkDisbursementStatusLabel.PAID,
  [AdNetworkDisbursementStatus.MANUAL]: AdNetworkDisbursementStatusLabel.MANUAL,
};
