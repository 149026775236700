import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { CustomField } from '@/interfaces/custom_field';
import api from '@/services/swarm';


const useCreateCustomField = (onSuccess?: (newCustomField: CustomField) => void) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    ({ display, kind }: { display: string; kind: string }) =>
      api.post('/custom_fields', {
        publication_id: currentPublicationId,
        custom_field: {
          display,
          kind,
        },
      }).then((res) => res.data),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (data: CustomField) => {
        toast.success('Custom Field created successfully');
        onSuccess?.(data);
        queryClient.invalidateQueries([currentPublicationId, 'customFields']);
      },
    }
  );
};

export default useCreateCustomField;
