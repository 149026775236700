import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { PlusIcon } from '@heroicons/react/20/solid';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCreateNavGroup } from '@/hooks/useNavGroups';
import { Button } from '@/ui/Button';

import NavGroupForm from './NavGroupForm';

const AddMenuGroup = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');

  const createNavGroup = useCreateNavGroup({
    onSuccess: () => {
      toast.success('Created!');
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      setTitle('');
      setIsOpen(false);
    },
    onError: () => {},
  });

  const handleCreateNavGroup = () => {
    createNavGroup.mutate({ title, group_type: 'nav' });
  };

  const handleClose = () => {
    setTitle('');
    setIsOpen(false);
  };

  return (
    <>
      <div>
        <Button Icon={PlusIcon} type="button" variant="primary-inverse" size="xs" onClick={() => setIsOpen(true)}>
          Add Menu Item
        </Button>
      </div>
      <NavGroupForm
        bodyId=""
        title={title}
        isOpen={isOpen}
        headerText="New Dropdown"
        ctaText="Create"
        loadingText="Creating..."
        handleTitleChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
        isLoading={createNavGroup.isLoading}
        handleSubmit={handleCreateNavGroup}
        handleClose={handleClose}
      />
    </>
  );
};

export default AddMenuGroup;
