import { JSONContent } from '@tiptap/core';

import { NodeAttrsToThemePropertiesMap } from '@/interfaces/dream_builder/site';
import { WebTheme } from '@/interfaces/web_theme';

import { applyThemeToNode } from './applyThemeToNode';

function traverseJSON<T extends JSONContent>(json: T, callback: (node: T) => T): T {
  const transformedNode = callback({ ...json });

  if (transformedNode.content && Array.isArray(transformedNode.content)) {
    transformedNode.content = transformedNode.content.map((child) => traverseJSON(child as T, callback));
  }

  return transformedNode;
}

export const previewThemeApplier = ({
  content = {
    type: 'doc',
    content: [],
  },
  themeRules,
  theme,
}: {
  content: JSONContent;
  themeRules: NodeAttrsToThemePropertiesMap;
  theme: WebTheme;
}): JSONContent => {
  const transformedContent = traverseJSON(
    content,
    (node) =>
      applyThemeToNode(
        {
          content: node.content,
          type: node.type,
          attrs: node.attrs,
        },
        theme,
        themeRules
      ) || node
  );

  return transformedContent;
};
