import AcceptedOpportunityCountFilterApplied from './AcceptedOpportunityCountFilter/Applied';
import ActiveSubscriptionCountFilterApplied from './ActiveSubscriptionCountFilter/Applied';
import DescriptionFilterApplied from './DescriptionFilter/Applied';
import ExpiredOpportunityCountFilterApplied from './ExpiredOpportunityCountFilter/Applied';
import HostnameFilterApplied from './HostnameFilter/Applied';
import LanguageFilterApplied from './LanguageFilter/Applied';
import NameFilterApplied from './NameFilter/Applied';
import OpportunityGroupFilerApplied from './OpportunityGroupFilter/Applied';
import OwnerEmailFilterApplied from './OwnerEmailFilter/Applied';
import PendingOpportunityCountFilterApplied from './PendingOpportunityCountFilter/Applied';
import PlanNameFilterApplied from './PlanNameFilter/Applied';
import ReceivedOpportunityCountFilterApplied from './ReceivedOpportunityCountFilter/Applied';
import RejectedOpportunityCountFilterApplied from './RejectedOpportunityCountFilter/Applied';
import SearchFilterApplied from './SearchFilter/Applied';
import SentPostsCountFilterApplied from './SentPostsCountFilter/Applied';
import TagFilterApplied from './TagFilter/Applied';
import TierFilterApplied from './TierFilter/Applied';
import useFilteringContext from './filtering-context';
import { AttributeCondition, Condition } from './types';

const RenderCondition = ({ condition, onRemove }: { condition: Condition; onRemove: () => void }) => {
  if (condition.type === 'group') {
    return null;
  }

  switch (condition.name) {
    case 'name':
      return <NameFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'plan_name':
      return <PlanNameFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'description':
      return <DescriptionFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'hostname':
      return <HostnameFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'language':
      return <LanguageFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'owner_email':
      return <OwnerEmailFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'search':
      return <SearchFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'active_subscription_count':
      return <ActiveSubscriptionCountFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'accepted_opportunity_count':
      return <AcceptedOpportunityCountFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'received_opportunity_count':
      return <ReceivedOpportunityCountFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'rejected_opportunity_count':
      return <RejectedOpportunityCountFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'pending_opportunity_count':
      return <PendingOpportunityCountFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'expired_opportunity_count':
      return <ExpiredOpportunityCountFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'tag':
      return <TagFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'sent_posts_count':
      return <SentPostsCountFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'opportunity_group':
      return <OpportunityGroupFilerApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    case 'tier':
        return <TierFilterApplied condition={condition as AttributeCondition} onRemove={onRemove} />;
    default:
      return null;
  }
};

const AppliedCondition = ({ condition }: { condition: Condition }) => {
  const { removeCondition } = useFilteringContext();

  const onRemove = () => {
    removeCondition(condition.uuid);
  };

  return (
    <div>
      <RenderCondition condition={condition} onRemove={onRemove} />
    </div>
  );
};
export default AppliedCondition;
