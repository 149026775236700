import { Node } from '@tiptap/core';

export const GenericEmbedDescription = Node.create({
  name: 'genericEmbedDescription',

  content: 'inline*',

  group: 'block',

  defining: true,

  marks: '',

  parseHTML() {
    return [
      {
        tag: 'p',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', HTMLAttributes, 0];
  },
});

export default GenericEmbedDescription;
