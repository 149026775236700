import { useUpdateWebTemplate } from '@/hooks';
import { WebTheme } from '@/interfaces/web_template';

import { TEMPLATE_OPTIONS } from '../../_types/options';
import { usePageContext } from '../Context/PageContext';
import ColorInput from '../Form/ColorInput';
import SelectInput from '../Form/SelectInput';
import ImageSettingHelper from '../Helpers/ImageSettingHelper';
import PropertiesGrouping from '../Helpers/PropertiesGrouping';
import SectionDivider from '../Helpers/SectionDivider';
import { NavSectionIcon, SectionGroupingSlideOut } from '../Helpers/SectionGroupingSlideOut';

const GlobalNavigationSection = () => {
  const { handleSaved, webTemplate, defaultColors, currentPublication } = usePageContext();

  const webTemplateMutation = useUpdateWebTemplate(() => handleSaved());
  const webTheme: WebTheme | any | undefined = webTemplate?.web_theme_preview;
  const webThemWithDefaults: WebTheme | undefined = webTemplate?.web_theme_preview_with_defaults;

  const handleUpdateWebTheme = (key: string, value: string | boolean) => {
    webTemplateMutation.mutate({
      web_theme_preview: {
        ...(webTheme as WebTheme),
        [key]: value,
      },
    });
  };

  return (
    <SectionGroupingSlideOut
      title="Global Navigation"
      sectionIcon={<NavSectionIcon />}
      highlightProps={{
        sectionId: 'global-navigation',
      }}
    >
      <ImageSettingHelper url={currentPublication?.logo?.url || ''} alt="Logo" label="Logo" />
      <PropertiesGrouping label="Top Navigation Bar" indent>
        <SelectInput
          label="Template"
          value={webThemWithDefaults?.navbar_template || ''}
          options={TEMPLATE_OPTIONS}
          onSave={(value: string) => {
            handleUpdateWebTheme('navbar_template', value);
          }}
        />
        <ColorInput
          label="Background Color"
          resettable={{
            label: 'Factory Default',
            resetValue: '#FFFFFF',
            check: webTheme?.navbar_background_color !== '#FFFFFF',
          }}
          color={webThemWithDefaults?.navbar_background_color}
          onSave={(value: string) => handleUpdateWebTheme('navbar_background_color', value)}
          defaultColors={defaultColors}
        />
        <ColorInput
          label="Text Color"
          color={webThemWithDefaults?.navbar_text_color}
          resettable={{
            label: 'Global Value',
            resetValue: null,
            check: webTheme?.navbar_text_color !== null,
          }}
          onSave={(value: string) => handleUpdateWebTheme('navbar_text_color', value)}
          defaultColors={defaultColors}
        />
      </PropertiesGrouping>

      <SectionDivider />

      <PropertiesGrouping
        label="Navigation Buttons"
        description="You can customize below your primary and secondary buttons used in the navigation bar."
        className="space-y-3"
        faintHeading
      >
        <div className="space-y-6">
          <PropertiesGrouping label="Primary Button" indent>
            <ColorInput
              label="Background Color"
              color={webThemWithDefaults?.navbar_button_primary_background_color}
              resettable={{
                label: 'Global Primary Color',
                resetValue: null,
                check: webTheme?.navbar_button_primary_background_color !== null,
              }}
              onSave={(value: string) => handleUpdateWebTheme('navbar_button_primary_background_color', value)}
              defaultColors={defaultColors}
            />
            <ColorInput
              label="Text Color"
              color={webThemWithDefaults?.navbar_button_primary_text_color}
              resettable={{
                label: 'Global Text on Primary Color',
                resetValue: null,
                check: webTheme?.navbar_button_primary_text_color !== null,
              }}
              onSave={(value: string) => handleUpdateWebTheme('navbar_button_primary_text_color', value)}
              defaultColors={defaultColors}
            />
            <ColorInput
              label="Border Color"
              resettable={{
                label: 'Global Primary Color',
                resetValue: null,
                check: webTheme?.navbar_button_primary_border_color !== null,
              }}
              color={webThemWithDefaults?.navbar_button_primary_border_color}
              onSave={(value: string) => handleUpdateWebTheme('navbar_button_primary_border_color', value)}
              defaultColors={defaultColors}
            />
            {/* TODO: https://linear.app/beehiiv/issue/BEE-4777/add-shadow-option-in-global-navigation-buttons */}
            {/* <SelectInput
              label="Shadow"
              value={webThemWithDefaults?.navbar_button_primary_shadow || ''}
              options={SHADOW_OPTIONS}
              onSave={(value: string) => {
                handleUpdateWebTheme('navbar_button_primary_shadow', value);
              }}
            /> */}
          </PropertiesGrouping>

          <PropertiesGrouping label="Secondary Button" indent>
            <ColorInput
              label="Background Color"
              resettable={{
                label: 'Global Secondary Color',
                resetValue: null,
                check: webTheme?.navbar_button_secondary_background_color !== null,
              }}
              color={webThemWithDefaults?.navbar_button_secondary_background_color}
              onSave={(value: string) => handleUpdateWebTheme('navbar_button_secondary_background_color', value)}
              defaultColors={defaultColors}
            />
            <ColorInput
              label="Text Color"
              resettable={{
                label: 'Global Text on Secondary Color',
                resetValue: null,
                check: webTheme?.navbar_button_secondary_text_color !== null,
              }}
              color={webThemWithDefaults?.navbar_button_secondary_text_color}
              onSave={(value: string) => handleUpdateWebTheme('navbar_button_secondary_text_color', value)}
              defaultColors={defaultColors}
            />
            <ColorInput
              label="Border Color"
              resettable={{
                label: 'Global Border Color',
                resetValue: null,
                check: webTheme?.navbar_button_secondary_text_color !== null,
              }}
              color={webThemWithDefaults?.navbar_button_secondary_border_color}
              onSave={(value: string) => handleUpdateWebTheme('navbar_button_secondary_border_color', value)}
              defaultColors={defaultColors}
            />
            {/* TODO: https://linear.app/beehiiv/issue/BEE-4777/add-shadow-option-in-global-navigation-buttons */}
            {/* <SelectInput
              label="Shadow"
              value={webThemWithDefaults?.navbar_button_secondary_shadow || ''}
              options={SHADOW_OPTIONS}
              onSave={(value: string) => {
                handleUpdateWebTheme('navbar_button_secondary_shadow', value);
              }}
            /> */}
          </PropertiesGrouping>
        </div>
      </PropertiesGrouping>
    </SectionGroupingSlideOut>
  );
};

export default GlobalNavigationSection;
