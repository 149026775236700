import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import Card from '@/components/Card';
import Table, { TableBody, TableCol, TableRow } from '@/components/Table';
import { Button } from '@/ui/Button';

const TableColInHeader = ({ children }: any) => {
  return (
    <TableCol className="!px-3 !py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      {children}
    </TableCol>
  );
};

const PartnerProgramResources = () => {
  const data = [
    {
      name: 'Brand Guidelines',
      driveLink: 'https://drive.google.com/file/d/1eiaAzDlnb9NluUM1fobyCiQR9XYGTx20/view?usp=sharing',
    },
    {
      name: 'Logos',
      driveLink: 'https://drive.google.com/drive/folders/1oYAeEZhhX-YrGuc7ltJykg2kapOkkNlr',
    },
    {
      name: 'Product Images',
      driveLink: 'https://drive.google.com/drive/folders/1betaDCAJdp36qSbSYFawhPI8iYnpZhfL',
    },
    {
      name: 'Ad Creatives',
      driveLink: 'https://drive.google.com/drive/folders/1eh9M0JDDDMEah8sY568xocl5Ft2sXyvm',
    },
    {
      name: 'Compare',
      driveLink: 'https://drive.google.com/drive/folders/11R2uOuffHu_ic06Pl8lJnw3_Fd0vPiIf?usp=drive_link',
    },
  ];

  return (
    <Card noPadding>
      <div className="p-6 w-full flex flex-col space-y-6 bg-white rounded-lg">
        <Table>
          <TableRow className="bg-gray-50 border-b border-surface-100 rounded-t-lg rounded-xl">
            <TableColInHeader>File Name</TableColInHeader>
            <TableColInHeader />
          </TableRow>
          <TableBody>
            {data?.map((row: any) => (
              <TableRow key={row.name}>
                <TableCol className="text-sm font-medium text-[#DB2777]">{row.name}</TableCol>
                <TableCol className="text-right">
                  <Button
                    onClick={() => window.open(row.driveLink, '_blank')}
                    type="button"
                    Icon={ArrowUpRightIcon}
                    variant="primary-inverse"
                    size="xs"
                  >
                    View Folder
                  </Button>
                </TableCol>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};

export default PartnerProgramResources;
