import { CheckIcon } from '@heroicons/react/20/solid';
import { ProgressBar } from '@tremor/react';

import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { PlanPrice } from '@/interfaces/plan_price';
import { currencyFormatter } from '@/utils';

import { usePlanInfo } from '../../_hooks';

interface Props {
  planPrice: PlanPrice;
  showUsage?: boolean;
}

const PlanCard: React.FC<Props> = ({
  planPrice,
  showUsage = false
}) => {
  const { description, features } = usePlanInfo({ planName: planPrice?.plan_name });

  const { active_subscriptions: activeSubscriptions = 1, organization_max_subscriptions: maxSubscriptions = 1 } = planPrice;
  const usagePercentage = Math.min(100, (activeSubscriptions / maxSubscriptions) * 100);

  return (
    <div className="border border-surface-200 rounded">
      <div className="px-6 pt-6 pb-4 bg-surface-50 border-b border-surface-200">
        <TypographyStack gap='4'>
          <div className="flex justify-between">
            <TypographyRow>
              <Typography className="capitalize" token="font-semibold/text/base">
                {planPrice?.plan_name} Plan
              </Typography>
              {planPrice?.interval === 'year' && (
                <div className="p-1 bg-[#C4B5FD] flex rounded-sm">
                  <Typography token="font-normal/text/xs">Annually</Typography>
                </div>
              )}
            </TypographyRow>
            <Typography as="div" token="font-medium/text/sm" colorWeight="500">
              {planPrice.max_subscriptions.toLocaleString()} subs @{' '}
              {currencyFormatter(planPrice.unit_amount / 100, 'USD')}/{planPrice.interval}
            </Typography>
          </div>
          {showUsage && (
            <TypographyStack gap='1'>
              <ProgressBar percentageValue={usagePercentage} color="violet" />
              <Typography as="p" token="font-normal/text/sm" colorWeight='500'>
                {activeSubscriptions.toLocaleString()} out of {maxSubscriptions.toLocaleString()} subscribers
              </Typography>
            </TypographyStack>
          )}
        </TypographyStack>
      </div>
      <div className="px-6 pt-4 pb-6 space-y-4">
        <Typography colorWeight="700" token="font-medium/text/sm">
          {description}
        </Typography>
        {features.map((feature) => (
          <TypographyRow gap="2">
            <CheckIcon className="w-5 h-5 text-surface-700" />
            <Typography colorWeight="700" token="font-normal/text/sm">
              {feature}
            </Typography>
          </TypographyRow>
        ))}
      </div>
    </div>
  );
};

export default PlanCard;
