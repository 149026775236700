interface PollSearchProps {
  search?: string;
  order?: string;
  dir?: string;
  filteredPost?: string;
  filterByDateCreated?: string;
  filterByDatePublished?: string;
}

export default class PollSearch implements PollSearchProps {
  search: PollSearchProps['search'];

  order: PollSearchProps['order'];

  dir: PollSearchProps['dir'];

  filteredPost: PollSearchProps['filteredPost'];

  filterByDateCreated: PollSearchProps['filterByDateCreated'];

  filterByDatePublished: PollSearchProps['filterByDatePublished'];

  params(): object {
    return {
      ...(this.search && { q: this.search }),
      ...(this.order && this.dir && { sortOrder: this.order, sortDir: this.dir }),
      ...(this.filteredPost && { filteredPost: this.filteredPost }),
      ...(this.filterByDateCreated && { filterByDateCreated: this.filterByDateCreated }),
      ...(this.filterByDatePublished && { filterByDatePublished: this.filterByDatePublished }),
    };
  }

  constructor(props: PollSearchProps) {
    this.search = props.search;
    this.order = props.order;
    this.dir = props.dir;
    this.filteredPost = props.filteredPost;
    this.filterByDateCreated = props.filterByDateCreated;
    this.filterByDatePublished = props.filterByDatePublished;
  }
}
