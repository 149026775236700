import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Author } from '../interfaces/author';
import api from '../services/swarm';

interface Props {
  onSuccess?: () => void;
}

const useUpdateGuest = ({ onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<Author, unknown, { id: string; formData: FormData }>(
    ({ id, formData }) =>
      api
        .patch(`/guest_authors/${id}`, formData, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        if (typeof onSuccess === 'function') {
          onSuccess();
        }
        toast.success('Guest author updated');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};
export default useUpdateGuest;
