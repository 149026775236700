/**
 * Check if a URL is valid or not.
 * It must begin with http:// or https:// and be a valid URL.
 *
 * @example checkUrl('foobar.com') => 'false'
 * @param url
 * @returns {boolean}
 */
const validateUrl = (url: string) => {
  if (!url) return false;

  try {
    // eslint-disable-next-line no-new
    new URL(url);
  } catch (error) {
    return false;
  }
  return true;
};

export default validateUrl;
