import { PageRoute } from '@/interfaces/dream_builder/page_route';

import { DROP } from '../_components/ContentTree/types';

export function insertPageRouteToPath(
  route: PageRoute,
  parentPath: string[],
  slug: string,
  newRoute: PageRoute,
  direction: DROP | 'last',
  directionSlugReference: string | undefined
): PageRoute {
  if (parentPath.length === 0) {
    // insert page route here
    if (route.children?.[slug]) {
      throw new Error('This path already exists, each page must have a unique URL');
    }

    if (direction === DROP.INSIDE && directionSlugReference) {
      return insertPageRouteToPath(route, [...parentPath, directionSlugReference], slug, newRoute, 'last', undefined);
    }

    let newChildrenKeys = [...(route.children_keys || [])];

    if (direction === 'last') {
      newChildrenKeys = [...newChildrenKeys, slug];
    } else if (direction === DROP.TOP && directionSlugReference) {
      // insert before directionSlugReference
      const index = newChildrenKeys.indexOf(directionSlugReference);
      newChildrenKeys.splice(index, 0, slug);
    } else if (direction === DROP.BOTTOM && directionSlugReference) {
      // insert after directionSlugReference
      const index = newChildrenKeys.indexOf(directionSlugReference);
      newChildrenKeys.splice(index + 1, 0, slug);
    }

    return {
      ...route,
      children: {
        ...(route.children || {}),
        [slug]: newRoute,
      },
      children_keys: newChildrenKeys,
    };
  }

  const nextPath = parentPath[0];
  const nextRoute = route.children?.[nextPath];

  // can't find next path
  if (!nextRoute) {
    return route;
  }

  const updatedNextRoute = insertPageRouteToPath(
    nextRoute,
    parentPath.slice(1),
    slug,
    newRoute,
    direction,
    directionSlugReference
  );

  // Unable to update next route
  if (!updatedNextRoute) {
    return route;
  }
  return {
    ...route,
    children: {
      ...route.children,
      [nextPath]: updatedNextRoute,
    },
  };
}
