import { SocialsElement } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import NotConnected from '../../components/NotConnected';
import { getDataAttributes } from '../../utils';

export const SocialsView = ({ HTMLAttributes, node, editor, getPos }: NodeViewProps) => {
  const { onDragStart } = useReactNodeView();
  const { hasFakeData } = node.attrs;

  const handleOpenSelectionModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
  };

  return (
    <SocialsElement
      element={{
        type: 'socials',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [
          {
            text: '',
            id: 'text',
          },
        ],
      }}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
      onDragStart={onDragStart}
      className="relative"
    >
      {null}
      {hasFakeData && <NotConnected onClick={handleOpenSelectionModal} text="No socials connected" />}
    </SocialsElement>
  );
};
