import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { AdNetworkDisbursement } from '@/interfaces/ad_network/internal/disbursement';
import {
  AdNetworkDisbursementClicksAdjustmentReason,
  AdNetworkDisbursementPayoutAdjustmentReason,
} from '@/interfaces/ad_network/internal/disbursement/types';
import api from '@/services/swarm';

interface DisbursementUpdateProps {
  id: string;
  onSuccess?: () => void;
}
interface DisbursementData {
  approved_amount_cents?: number;
  approved_clicks?: number;
  payout_adjustment_reason?: AdNetworkDisbursementPayoutAdjustmentReason | null;
  clicks_adjustment_reason?: AdNetworkDisbursementClicksAdjustmentReason | null;
}

export default function useDisbursementUpdate({ id, onSuccess }: DisbursementUpdateProps) {
  const updateDisbursement = ({
    approved_amount_cents,
    approved_clicks,
    payout_adjustment_reason,
    clicks_adjustment_reason,
  }: DisbursementData): Promise<AdNetworkDisbursement> =>
    api
      .patch(`/ad_network/internal/disbursements/${id}`, {
        disbursement: {
          approved_amount_cents,
          approved_clicks,
          payout_adjustment_reason,
          clicks_adjustment_reason,
        },
      })
      .then((res) => res.data);

  return useMutation(updateDisbursement, {
    onSuccess: () => {
      onSuccess?.();
      toast.success('Disbursement updated successfully');
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Couldn't update disbursement");
    },
  });
}
