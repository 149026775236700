import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import CustomFieldSelect from '@/components/CustomFieldSelect';
import { Input, RadioSelect } from '@/components/Form';
import { useSettings } from '@/context/settings-context';
import { useCreateFormQuestion } from '@/hooks/useForms';
import { ApiResponse } from '@/hooks/useForms/useCreateFormQuestion';
import { QuestionTypes } from '@/interfaces/form';
import { CustomFieldOption } from '@/interfaces/general';
import { Button } from '@/ui/Button';

import { filterCustomFields } from './helpers';
import NewCustomField from './NewCustomField';

const options = [
  { name: 'Short response', value: QuestionTypes.FREE_FORM },
  { name: 'Multiple choice', value: QuestionTypes.MULTIPLE_CHOICE },
  { name: 'Dropdown', value: QuestionTypes.DROPDOWN },
  { name: 'Long response', value: QuestionTypes.LONG_TEXT },
];

interface Props {
  nextQuestionOrder: number;
  currentCustomFieldIds: string[];
  isInitialQuestion?: boolean;
  onAddQuestionClick: () => void;
  onAddQuestion?: (questionId: string) => void;
}

const AddNewQuestion = ({
  isInitialQuestion,
  nextQuestionOrder,
  currentCustomFieldIds,
  onAddQuestionClick,
  onAddQuestion,
}: Props) => {
  const queryClient = useQueryClient();
  const { settings } = useSettings();
  const hasSurveysV2Enabled = settings?.surveys_v2 === true;

  // New question form states
  const [showNewQuestionOptions, setShowNewQuestionOptions] = useState(false);
  const [selection, setSelection] = useState<any>(options[0].value);
  const [prompt, setPrompt] = useState<string>('');
  const [customFieldId, setCustomFieldId] = useState<string>('');
  const isMultipleChoiceSelected = selection === QuestionTypes.MULTIPLE_CHOICE;
  const isDropdownSelected = selection === QuestionTypes.DROPDOWN;
  const isLongTextSelected = selection === QuestionTypes.LONG_TEXT;

  // New Custom field states + logic
  const [showNewCustomField, setShowNewCustomField] = useState(false);
  const handleCloseNewCustomField = () => setShowNewCustomField(false);
  const handleOpenNewCustomField = () => {
    setShowNewCustomField(true);
    setCustomFieldId('');
  };

  // Clear form data and close
  const handleClearFormData = () => {
    setPrompt('');
    setSelection(options[0].value);
    setCustomFieldId('');
  };

  const handleCloseNewQuestionForm = () => {
    handleClearFormData();
    setShowNewQuestionOptions(false);
  };

  const { formId } = useParams();
  const createFormQuestion = useCreateFormQuestion({
    form_id: formId || '',
    onSuccess: (response: ApiResponse) => {
      toast.success('Question added!');
      queryClient.invalidateQueries(['forms', formId]);
      handleCloseNewQuestionForm();
      if (hasSurveysV2Enabled) {
        onAddQuestion?.(response.form_question.id);
      }
    },
  });

  const handleCreateFormQuestion = () => {
    createFormQuestion.mutate({
      prompt,
      question_type: selection,
      custom_field_id: customFieldId,
      order: nextQuestionOrder,
      required: false,
    });

    queryClient.invalidateQueries('formQuestions');
  };

  const handleOpenNewQuestionForm = () => {
    setShowNewQuestionOptions(true);
  };

  return (
    <>
      <ActionModal
        isOpen={showNewQuestionOptions}
        onClose={() => {
          handleClearFormData();
          setShowNewQuestionOptions(false);
        }}
        onProceed={handleCreateFormQuestion}
        resourceId=""
        isWorking={createFormQuestion.isLoading}
        headerText="Add New Question"
        actionText="Add Question"
      >
        <NewCustomField
          isMultipleChoiceSelected={isMultipleChoiceSelected}
          isDropdownSelected={isDropdownSelected}
          isLongTextSelected={isLongTextSelected}
          isOpen={showNewCustomField}
          onClose={handleCloseNewCustomField}
          onSuccess={(customField) => {
            setCustomFieldId(customField.id);
          }}
        />
        <div className="space-y-4">
          <Input
            name="prompt"
            value={prompt}
            labelText="Title"
            placeholder="What is your first name?"
            onChange={(e) => setPrompt(e.target.value)}
            required
          />
          <RadioSelect required labelText="Question Type" value={selection} options={options} onSelect={setSelection} />
          <div className="space-y-2 flex flex-col justify-center">
            <div>
              {!showNewCustomField && (
                <CustomFieldSelect
                  className="w-64"
                  dropdownClassnames="rounded-md overflow-auto max-h-[7.5rem]"
                  labelText="Custom Field Value*"
                  customFieldId={customFieldId}
                  refetchFetchOptions={selection}
                  onFilterOptions={(fieldOptions: CustomFieldOption[]) => {
                    const filteredOptions = filterCustomFields(
                      currentCustomFieldIds,
                      isMultipleChoiceSelected,
                      isDropdownSelected,
                      isLongTextSelected
                    )(fieldOptions);

                    return filteredOptions;
                  }}
                  onSelectCustomField={(idValue: string) => {
                    setCustomFieldId(idValue);
                  }}
                  onClearCustomField={() => {
                    setCustomFieldId('');
                  }}
                />
              )}
              {isMultipleChoiceSelected && (
                <p className="italic text-xs text-blue-500 mt-1">
                  *Please Note: You may not see some Custom Fields in the dropdown because we limit multiple choice
                  questions to Custom Fields of type string or list.
                </p>
              )}
              {isDropdownSelected && (
                <p className="italic text-xs text-blue-500 mt-1">
                  *Please Note: You may not see some Custom Fields in the dropdown because we limit dropdown questions
                  to Custom Fields of type string.
                </p>
              )}
            </div>
            <div>
              <span className="text-sm text-gray-500">
                Or,{' '}
                <button type="button" onClick={handleOpenNewCustomField} className="text-primary-500">
                  create a new one.
                </button>
              </span>
            </div>
          </div>
        </div>
      </ActionModal>

      {isInitialQuestion && (
        <Button
          type="button"
          onClick={() => {
            handleOpenNewQuestionForm();
            onAddQuestionClick();
          }}
          className="absolute -bottom-8"
        >
          Add First Question
        </Button>
      )}

      {!isInitialQuestion && hasSurveysV2Enabled && (
        <button
          type="button"
          onClick={() => {
            handleOpenNewQuestionForm();
            onAddQuestionClick();
          }}
          className="bg-gray-900 rounded-full p-1.5"
        >
          <PlusIcon className="w-4 h-4 text-white" />
        </button>
      )}

      {!isInitialQuestion && !hasSurveysV2Enabled && (
        <div className="absolute -bottom-14 left-1/2 transform -translate-x-1/2">
          <button
            type="button"
            onClick={() => {
              handleOpenNewQuestionForm();
              onAddQuestionClick();
            }}
            className="bg-gray-900 rounded-full p-1.5"
          >
            <PlusIcon className="w-4 h-4 text-white" />
          </button>
        </div>
      )}
    </>
  );
};

export default AddNewQuestion;
