import { useQuery } from 'react-query';

import { CustomFieldValue } from '@/interfaces/custom_field_value';

import { useCurrentPublicationState } from '../context/current-publication-context';
import api from '../services/swarm';

interface ApiResponse {
  custom_field_values: CustomFieldValue[];
}

const useCustomFieldValues = (subscriptionId: any) => {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<ApiResponse>(
    ['custom_field_values', subscriptionId],
    () =>
      api
        .get(`/subscriptions/${subscriptionId}/custom_field_values`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: 10000,
      enabled: Boolean(subscriptionId),
    }
  );
};

export default useCustomFieldValues;
