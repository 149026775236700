import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Page } from '@/interfaces/dream_builder/page';
import { Pagination } from '@/interfaces/general';

import api from '../../services/swarm';


interface ApiResponse {
  pages: Page[];
  pagination: Pagination;
}

export default function usePages() {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPages = ({ pageParam = 1 }) =>
    api
      .get(`/pages`, {
        params: {
          publication_id: currentPublicationId,
          page: pageParam,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'pages']
    , fetchPages, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    enabled: Boolean(currentPublicationId),
    staleTime: 30000,
    keepPreviousData: true,
  });
}
