import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { AccordionCard } from '@/components/Accordion/variants/AccordionCard';
import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import { Typography } from '@/components/Typography';
import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { usePostTargets } from '@/hooks/usePostTargets';
import { Post, PostStatus } from '@/interfaces/post';
import { PostTargetAction, PostTargetPlatform } from '@/interfaces/post_target';
import AudienceBadges from '@/pages/Post/Overview/AudienceBadges';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';

interface Props {
  post: Post;
  setPostEditorStep: Dispatch<SetStateAction<PostEditorSteps>>;
}

const PublishSettings = ({ post, setPostEditorStep }: Props) => {
  const { data: postDeliveryData } = usePostDeliveryData(post.id);
  const { data: emailPostTargetsData } = usePostTargets(post.id, PostTargetPlatform.EMAIL);
  const { data: webPostTargetsData } = usePostTargets(post.id, PostTargetPlatform.WEB);
  const [postSent, setPostSent] = useState(false);
  const [iconColor, setIconColor] = useState('text-action-secondary-600');

  const emailPostTargets = useMemo(() => emailPostTargetsData?.post_targets || [], [emailPostTargetsData]);
  const webPostTargets = useMemo(() => webPostTargetsData?.post_targets || [], [webPostTargetsData]);

  let deliveryOption = 'None';
  if (postDeliveryData?.for_email && postDeliveryData?.for_web) {
    deliveryOption = 'Email and Web';
  } else if (postDeliveryData?.for_email) {
    deliveryOption = 'Email Only';
  } else if (postDeliveryData?.for_web) {
    deliveryOption = 'Web Only';
  }

  useEffect(() => {
    if (post.status === PostStatus.PUBLISHED || post.status === PostStatus.ARCHIVED) {
      setIconColor('text-feedback-success-500');
      setPostSent(true);
    } else if (post.status === PostStatus.DRAFT) {
      setIconColor('text-action-secondary-600');
    } else {
      setIconColor('text-feedback-info-500');
    }
  }, [post.status]);

  return (
    <div className="w-2/3 mx-auto max-w-[40rem]">
      <AccordionCard
        cardPaddingClass="p-6"
        marginTop="mt-0"
        padding="p-6"
        titleGap="0"
        title={
          <span className="flex flex-row gap-3">
            <PaperAirplaneIcon className={cx('h-6 w-6 flex-grow', iconColor)} />
            <Typography token="font-normal/text/base" colorWeight="500">
              Publish to{' '}
              <Typography token="font-medium/text/base" colorWeight="900">
                {deliveryOption}
              </Typography>
            </Typography>
          </span>
        }
        subText=""
        topBorder={false}
        footer={
          <div
            className={cx(
              'flex px-6 py-3 justify-end items-center gap-3 self-stretch bg-surface-50',
              postSent ? 'hidden' : ''
            )}
          >
            <ButtonGroup>
              <Button
                onClick={() => setPostEditorStep(PostEditorSteps.AUDIENCE)}
                type="button"
                variant="primary-inverse"
                size="sm"
              >
                Edit
              </Button>
            </ButtonGroup>
          </div>
        }
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <div className="flex flex-row gap-2">
              <Typography token="font-medium/text/sm" colorWeight="900">
                Email audience
              </Typography>
              <Typography token="font-medium/text/sm" colorWeight="500">
                {emailPostTargets.length === 0 && (
                  <Typography token="font-medium/text/sm" colorWeight="500">
                    Not published via email
                  </Typography>
                )}
                {emailPostTargets.length > 0 &&
                  `${(postDeliveryData?.target_subscriptions || 0).toLocaleString()} recipients`}
              </Typography>
            </div>
            <AudienceBadges
              includedAudiences={emailPostTargets.filter((target) => target.action === PostTargetAction.INCLUDE)}
              excludedAudiences={emailPostTargets.filter((target) => target.action === PostTargetAction.EXCLUDE)}
            />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-row gap-2">
              <Typography token="font-medium/text/sm" colorWeight="900">
                Web audience
              </Typography>
              {webPostTargets.length === 0 && (
                <Typography token="font-medium/text/sm" colorWeight="500">
                  Not published to the web
                </Typography>
              )}
            </div>
            <AudienceBadges
              includedAudiences={webPostTargets.filter((target) => target.action === PostTargetAction.INCLUDE)}
              excludedAudiences={webPostTargets.filter((target) => target.action === PostTargetAction.EXCLUDE)}
            />
          </div>
        </div>
      </AccordionCard>
    </div>
  );
};

export default PublishSettings;
