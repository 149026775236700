import { Typography, TypographyStack } from '@/components/Typography';

const BoostInvitesHeader = () => (
  <div className="pt-6 pb-4 px-6">
    <TypographyStack>
      <Typography token="font-semibold/text/lg">Boost Invites </Typography>
      <Typography token="font-normal/text/sm" colorWeight="500">
        Simply search and filter to find publications you would like to invite to boost your publication.
      </Typography>
    </TypographyStack>
  </div>
);

export default BoostInvitesHeader;
