import { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

import { LandingPage } from '@/interfaces/landing_page';
import { UpgradePage } from '@/interfaces/upgrade_page';
import FontPicker from '@/ui/Settings/FontPicker';

import { usePageContext } from '../../../Context/PageContext';
import CustomizeMoreButton from '../../../Helpers/CustomizeMoreButton';
import PropertiesGrouping from '../../../Helpers/PropertiesGrouping';
import ColorInput from '../../ColorInput';

import FAQQuestionsForm from './FAQQuestionsForm';

interface Props {
  handleSave: (payload: any) => void;
  page: LandingPage | UpgradePage;
  isProcessing: boolean;
}

const FAQExtendedForm = ({ page, handleSave, isProcessing }: Props) => {
  const [isCustomizeShowing, setIsCustomizeShowing] = useState(false);
  const { fontOptions } = usePageContext();

  const faqSectionWithDefaults = page.faq_section_with_defaults;

  return (
    <PropertiesGrouping
      className="!space-y-2"
      label="FAQs"
      actionChild={
        <CustomizeMoreButton
          Icon={PencilSquareIcon}
          isOpen={isCustomizeShowing}
          text="Styles"
          onClose={() => setIsCustomizeShowing(!isCustomizeShowing)}
        />
      }
    >
      <FAQQuestionsForm page={page} handleSave={handleSave} isProcessing={isProcessing} />
      {isCustomizeShowing && (
        <>
          <ColorInput
            label="Question Color"
            color={faqSectionWithDefaults.faq_question_color}
            onSave={(color: string) => {
              handleSave({
                ...page?.faq_section,
                faq_question_color: color,
              });
            }}
          />
          <ColorInput
            label="Answer Color"
            color={faqSectionWithDefaults.faq_answer_color}
            onSave={(color: string) => {
              handleSave({
                ...page?.faq_section,
                faq_answer_color: color,
              });
            }}
          />
          <ColorInput
            label="Border Color"
            color={faqSectionWithDefaults.faq_border_color}
            onSave={(color: string) => {
              handleSave({
                ...page?.faq_section,
                faq_border_color: color,
              });
            }}
          />
          <ColorInput
            label="Button Color"
            color={faqSectionWithDefaults.faq_button_color}
            onSave={(color: string) => {
              handleSave({
                ...page?.faq_section,
                faq_button_color: color,
              });
            }}
          />
          <FontPicker
            label="Font Family"
            searchPlaceholder="Search Font"
            value={faqSectionWithDefaults.faq_font_family}
            noOptionsText="No Matching Fonts"
            options={fontOptions}
            onSave={(value: string) => {
              handleSave({
                ...page?.faq_section,
                faq_font_family: value,
              });
            }}
            resettable={{
              check: page?.faq_section?.faq_font_family !== null,
              resetValue: null,
              label: 'Global Heading Font',
            }}
          />
        </>
      )}
    </PropertiesGrouping>
  );
};

export default FAQExtendedForm;
