import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface PartnerProgramDetailInput {
  affiliate_id?: string;
  rewardful_email?: string;
  status?: string;
  paypal_email?: string;
  affiliate_link?: string;
  partner_type?: string;
  app_celebration_due?: boolean;
}

export default function useUpdatePartnerProgramDetails(silent = false) {
  return useMutation<any, unknown, PartnerProgramDetailInput>(
    (detail) =>
      api
        .patch('/partner_program/partner_program_details', {detail})
        .then((res) => res.data),
    {
      onSuccess: () => {
        if (!silent) {
          toast.success('Partner Program Detail Updated');
        }
      },
      onError: (err: any) => {
        if (!silent) {
          toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
        }
      },
    }
  );
};
