import { useQuery } from 'react-query';

import { AdNetworkOpportunityStats } from '@/interfaces/ad_network/internal/opportunity_stats';
import api from '@/services/swarm';

export default function useOpportunityStats({ id }: { id: string }) {
  return useQuery<AdNetworkOpportunityStats>(['ad_network', 'internal', 'opportunities', id, 'stats'], () =>
    api.get(`/ad_network/internal/opportunities/${id}/stats`).then((res) => res.data)
  );
}
