import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus } from '@phosphor-icons/react';
import { NavbarSerializableNode, NavbarSerializer } from '@shared/dream-components';

import { useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';
import { useAttributesPanelNavbarFooterContext } from '@/routes/website/_components/DreamEditor/AttributesPanel/navbarFooterContextProvider';
import { Text } from '@/routes/website/_components/UI/Text';
import { useClickOutside } from '@/routes/website/_utils/useClickOutside';
import { cn } from '@/utils/cn';

import { useIsMobile } from './utils';

export const NavbarPreview = ({
  portalRef,
  iframeRef,
  selectable = true,
  onFocus,
  overrideContent,
}: {
  portalRef: React.RefObject<HTMLDivElement>;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  selectable?: boolean;
  onFocus?: () => void;
  overrideContent?: NavbarSerializableNode;
}) => {
  const { pageId } = useParams();
  const [selected, setSelected] = useState(false);
  const { site, previewSiteVersion } = useWebsiteContext();
  const pageRouteGetter = useWebsitePageRouteGetter();
  const page = pageId ? pageRouteGetter?.getPageFromID(pageId) : undefined;
  const navigate = useNavigate();
  const isMobile = useIsMobile(iframeRef);
  const content =
    overrideContent ||
    ((previewSiteVersion ? previewSiteVersion?.navbar : site?.draft_site_version?.navbar) as NavbarSerializableNode);

  const isNavbarVisible =
    'show_navbar' in (page?.draft_page_version?.customization || {})
      ? page?.draft_page_version?.customization?.show_navbar
      : true;
  const { setIsNavbarSelected } = useAttributesPanelNavbarFooterContext() || {};

  useEffect(() => {
    setIsNavbarSelected?.(selected);
  }, [selected, setIsNavbarSelected]);

  const onDoubleClick = () => {
    navigate(`/website_builder_v2/navbar?previousPageID=${pageId}`);
  };

  const onClickOutside = (): void => {
    setSelected(false);
  };

  const ref = useClickOutside(iframeRef, onClickOutside, ['navbar-settings']);

  if (!content || !('content' in content) || content.content?.length === 0)
    return (
      <div
        className={cn(
          'group w-full h-[60px] flex items-center justify-center gap-2 border border-solid border-inset-1',
          selected && selectable ? 'border-[#7C3AED]' : 'border-transparent',
          selectable ? 'hover:border-neutral-200' : '',
          !isNavbarVisible ? 'opacity-50' : 'opacity-100'
        )}
        onClick={() => setSelected(true)}
        role="button"
        tabIndex={0}
        onDoubleClick={selectable ? onDoubleClick : undefined}
        ref={ref}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setSelected(true);
          }
        }}
        onFocus={onFocus}
      >
        <Plus size={16} className="text-neutral-400 group-hover:text-[#7C3AED]" />
        <Text size="sm" variant="secondary" className="text-neutral-400 group-hover:text-[#7C3AED]">
          Edit Navbar
        </Text>
      </div>
    );

  return (
    <div className="h-fit w-full flex flex-col justify-start items-center" ref={ref}>
      <div
        className={cn(
          'w-full flex justify-center items-center border border-solid border-inset-1',
          selected && selectable ? 'border-[#7C3AED]' : 'border-transparent',
          selectable ? 'hover:border-neutral-200' : '',
          !isNavbarVisible ? 'opacity-50' : 'opacity-100'
        )}
        onClick={() => {
          setSelected(true);
        }}
        role="button"
        tabIndex={0}
        onDoubleClick={selectable ? onDoubleClick : undefined}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setSelected(true);
          }
        }}
        onFocus={onFocus}
      >
        <NavbarSerializer node={content} portalRef={portalRef} screen={isMobile ? 'mobile' : 'desktop'} />
      </div>
    </div>
  );
};
