import { useCallback, useRef, useState } from 'react';
import { Editor } from '@tiptap/react';
import { Instance, sticky } from 'tippy.js';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { EditLink } from '../../../components/menus/Link';
import { MenuProps } from '../../../components/menus/types';
import { forceUpdateTippy } from '../../../components/menus/utils/forceUpdateTippy';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const GenericEmbedMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const tippyInstance = useRef<Instance | null>(null);
  const [showLinkEdit, setShowLinkEdit] = useState(false);
  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-genericEmbed');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('genericEmbed');

    return isActive;
  }, [editor]);

  const genericEmbedUrl = editor.getAttributes('genericEmbed').url ?? '';
  const genericEmbedTarget = editor.getAttributes('genericEmbed').target;

  return (
    <BaseBubbleMenu
      editor={editor as Editor}
      pluginKey="genericEmbedMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        onHidden: () => {
          setShowLinkEdit(false);
        },
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()}>
        {(editor.isActive('button') && !genericEmbedUrl) || showLinkEdit ? (
          <EditLink
            link={genericEmbedUrl}
            target={genericEmbedTarget}
            onSetLink={(newUrl: string) => {
              editor.chain().focus().updateAttributes('genericEmbed', { url: newUrl }).run();
              setShowLinkEdit(false);
            }}
            onUnsetLink={() => {
              editor.chain().focus().updateAttributes('genericEmbed', { url: undefined }).run();
              setShowLinkEdit(false);
              return null;
            }}
            onSetTarget={(target: string) => {
              editor.chain().focus().updateAttributes('genericEmbed', { target }).run();
            }}
            onBack={() => {
              setShowLinkEdit(false);
              if (tippyInstance.current) {
                forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
              }
            }}
            autoFocus={showLinkEdit || false}
            editor={editor}
          />
        ) : (
          <>
            <Tooltip title="Text left / image right">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $active={editor.isActive('genericEmbed', { variant: 'text-left-image-right' })}
                $leftSlot={<Icon name="TextLeftImageRight" />}
                onClick={() => {
                  editor
                    .chain()
                    .focus(undefined, { scrollIntoView: false })
                    .setGenericEmbedVariant('text-left-image-right')
                    .run();
                }}
              />
            </Tooltip>
            <Tooltip title="Image left / text right">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $active={editor.isActive('genericEmbed', { variant: 'image-left-text-right' })}
                $leftSlot={<Icon name="ImageLeftTextRight" />}
                onClick={() => {
                  editor
                    .chain()
                    .focus(undefined, { scrollIntoView: false })
                    .setGenericEmbedVariant('image-left-text-right')
                    .run();
                }}
              />
            </Tooltip>
            <Tooltip title="Image top / text bottom">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $active={editor.isActive('genericEmbed', { variant: 'image-top-text-bottom' })}
                $leftSlot={<Icon name="ImageTopTextBottom" />}
                onClick={() => {
                  editor
                    .chain()
                    .focus(undefined, { scrollIntoView: false })
                    .setGenericEmbedVariant('image-top-text-bottom')
                    .run();
                }}
              />
            </Tooltip>
            <Tooltip title="Text only">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $active={editor.isActive('genericEmbed', { variant: 'text-only' })}
                $leftSlot={<Icon name="TextOnly" />}
                onClick={() => {
                  editor.chain().focus(undefined, { scrollIntoView: false }).setGenericEmbedVariant('text-only').run();
                }}
              />
            </Tooltip>
            <Divider />
            <Tooltip title="Edit link">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $active={!!genericEmbedUrl}
                $leftSlot={<Icon name="Link" />}
                onClick={() => {
                  setShowLinkEdit(true);
                  if (tippyInstance.current) {
                    forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Open link">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $leftSlot={<Icon name="External" />}
                as="a"
                href={genericEmbedUrl}
                target="_blank"
              />
            </Tooltip>
            <Divider />
            <Tooltip title="Delete embed">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $leftSlot={<Icon name="Trash" />}
                onClick={() => {
                  editor.chain().focus().deleteNode('genericEmbed').run();
                }}
              />
            </Tooltip>
          </>
        )}
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default GenericEmbedMenu;
