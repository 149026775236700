import styled from 'styled-components';

export const Styled = {
  MilestonesContainer: styled.div`
    margin-bottom: 1rem;
  `,
  Headline: styled.h3`
    &&& {
      margin: 0;
    }
  `,
  Description: styled.p`
    &&& {
      font-size: 0.9rem;
      margin: 0;
    }
  `,
  ImageContainer: styled.div`
    width: 181px;
    height: 150px;
    overflow: hidden;
    border: solid 2px #e5e7eb;
    border-radius: 6px;
    background-image: url('https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/placeholder-checkers.jpg');
    background-size: cover, 50%;

    img {
      width: 100%;
      height: 100%;
      background-color: #fff;
      object-fit: cover;
    }
  `,
  Paragraph: styled.div`
    margin-bottom: 0;
    font-size: 0.9rem;
  `,
};

export default Styled;
