import cx from 'classnames';

import { Accordion } from '@/components/Accordion';
import * as CardUI from '@/components/Card/CardUI';
import { Title2xl, TitleBase, TitleSm, Typography, TypographyStack } from '@/components/Typography';

interface Props {
  title: string | React.ReactNode;
  subText: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  defaultOpen?: boolean;
  topBorder?: boolean;
  titleSize?: 'sm' | 'base' | 'lg' | '2xl';
  titleWeight?: 'bold' | 'semibold' | 'medium';
  isControlledState?: boolean;
  isControlledStateOpen?: boolean;
  onClick?: () => void;
  titleGap?: '0' | '1' | '2' | '3' | '4' | '5' | '6';
  marginTop?: string;
  padding?: string;
  cardPaddingClass?: string;
}

const AccordionCard = ({
  children,
  footer,
  title,
  subText,
  defaultOpen = false,
  topBorder = true,
  titleSize = 'sm',
  isControlledState,
  isControlledStateOpen,
  onClick,
  titleGap = '1',
  marginTop = 'mt-4',
  padding = 'p-4',
  titleWeight = 'semibold',
  cardPaddingClass,
}: Props) => {
  const titles = {
    sm: <TitleSm>{title}</TitleSm>,
    base: <TitleBase weight={titleWeight}>{title}</TitleBase>,
    lg: <Typography token="font-medium/text/lg">{title}</Typography>,
    '2xl': <Title2xl>{title}</Title2xl>,
  };
  return (
    <CardUI.Card hasPadding={false}>
      <Accordion
        defaultOpen={defaultOpen}
        className={cardPaddingClass || 'p-4'}
        isControlledState={isControlledState}
        isControlledStateOpen={isControlledStateOpen}
        onClick={onClick}
        titleChildren={
          <TypographyStack gap={titleGap}>
            {titles[titleSize]}
            <Typography className="w-full" colorWeight="500" weight="normal" size="sm">
              {subText}
            </Typography>
          </TypographyStack>
        }
      >
        <div className={cx(marginTop, padding, topBorder ? 'border-t border-t-surface-200' : '')}>{children}</div>
        {footer}
      </Accordion>
    </CardUI.Card>
  );
};

export default AccordionCard;
