import { PropsWithChildren } from 'react';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

type Props = PropsWithChildren<{
  noSearchResults: boolean;
  searchQuery?: string | null;
  isLoading: boolean;
  handleResetSearch: () => void;
}>;

const EmptyState = ({ noSearchResults, searchQuery, isLoading, handleResetSearch, children }: Props) => {
  const text = noSearchResults
    ? `No results found for search, '${searchQuery}'`
    : `Once you create your first segment, it will show up here.`;

  return (
    <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
      <div className="flex flex-col items-center gap-4">
        <p className="text-gray-500 text-center">{text}</p>
        {noSearchResults ? (
          <Button variant="primary-inverse" onClick={handleResetSearch}>
            <div className="flex">
              <ArrowPathIcon className="h-5 w-5 mr-2" />
              Reset Search
            </div>
          </Button>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default EmptyState;
