import { IconComponent } from '@heroicons/react';
import { BoltIcon, CheckCircleIcon, ClockIcon, SparklesIcon, UsersIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { formatMinutes } from '@/components/KPI/formatMinutes';
import LifetimeMetricCard from '@/components/KPI/LifetimeMetricCard';
import useMetrics, { AutomationMetricsEndpoints } from '@/hooks/useAutomations/useMetrics';
import getLocaleString from '@/utils/getLocaleString';

interface Props {
  automationId: string;
}

interface TopLineMetricsData {
  average_duration_minutes: null | number;
  completion_rate: null | number;
  last_enrollment_at: null | number;
  total_steps: number;
  total_subscribers_completed: number;
  total_subscribers_enrolled: number;
}

const LifetimeMetrics = ({ automationId }: Props) => {
  const { data, isLoading } = useMetrics({ automationId, endpoint: AutomationMetricsEndpoints.TOP_LINE_METRICS });

  const mappedData: TopLineMetricsData =
    !isLoading && typeof data === 'object'
      ? data
      : {
          average_duration_minutes: null,
          completion_rate: null,
          last_enrollment_at: null,
          total_steps: 0,
          total_subscribers_completed: 0,
          total_subscribers_enrolled: 0,
        };

  const averageDurationMinutes =
    typeof mappedData.average_duration_minutes === 'string'
      ? parseFloat(mappedData.average_duration_minutes)
      : mappedData.average_duration_minutes;
  const averageCompletionTime = formatMinutes(averageDurationMinutes);

  const metrics: {
    label: string;
    valueSuffix?: string;
    icon: IconComponent;
    value: number | string;
  }[] = [
    {
      label: 'Steps in workflow',
      valueSuffix: '',
      value: getLocaleString(mappedData.total_steps || 0),
      icon: SparklesIcon,
    },
    {
      label: 'In Progress in workflow',
      valueSuffix: 'subscribers',
      value: getLocaleString(mappedData.total_subscribers_enrolled || 0),
      icon: UsersIcon,
    },
    {
      label: 'Completed workflow',
      valueSuffix: 'subscribers',
      value: getLocaleString(mappedData.total_subscribers_completed || 0),
      icon: CheckCircleIcon,
    },
    {
      label: 'Last enrollment',
      valueSuffix: 'days ago',
      value: mappedData.last_enrollment_at ? moment().diff(data.last_enrollment_at, 'days') : '-',
      icon: ClockIcon,
    },
    {
      label: 'Completion rate',
      valueSuffix: '%',
      value: mappedData.completion_rate ? parseFloat(data.completion_rate).toFixed(2) : '-',
      icon: BoltIcon,
    },
    {
      label: 'Avg. completion time',
      valueSuffix: averageCompletionTime.label,
      value: averageCompletionTime.value,
      icon: ClockIcon,
    },
  ];
  return (
    <div className="flex flex-col md:flex-row justify-evenly gap-x-6 gap-y-2">
      {metrics.map((metric) => (
        <LifetimeMetricCard
          key={`lifetime-metric-${metric.label}`}
          label={metric.label}
          value={metric.value}
          icon={metric.icon}
          valueSuffix={metric.valueSuffix}
        />
      ))}
    </div>
  );
};

export default LifetimeMetrics;
