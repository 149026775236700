import { useQuery } from 'react-query';

import { AdNetworkCampaignOpportunityGroup } from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import api from '@/services/swarm';

export default function useCampaignOpportunityGroup({ id }: { id: string }) {
  return useQuery<AdNetworkCampaignOpportunityGroup>(
    ['ad_network', 'internal', 'campaign_opportunity_group', id],
    () => api.get(`/ad_network/internal/campaign_opportunity_groups/${id}`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
