import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Tippy from '@tippyjs/react/headless';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment-mini';
import { Placement } from 'tippy.js';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { SearchList, SearchListItemProps } from '@/components/TiptapEditor/components/ui/SearchList';
import { API } from '@/components/TiptapEditor/lib/api';
import { debounce } from '@/components/TiptapEditor/lib/utils/debounce';
import { usePermissions } from '@/context/permissions-context';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';

import { Styled } from './AdvertisementOpportunityView.styled';

export interface TippyProps {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

export const AdvertisementOpportunityView = ({
  editor,
  node,
  updateAttributes,
  extension: {
    options: { publicationId },
  },
}: NodeViewProps) => {
  const opportunityId = useMemo(() => node.attrs.id, [node.attrs.id]);
  const [options, setOptions] = useState<Array<{ id: string; advertiser_name: string; selected_date: string }>>([]);
  const [data, setData] = useState<AdNetworkOpportunity | undefined>(undefined);
  const [selectionMenuOpen, setSelectionMenuOpen] = useState(node.attrs.showOptionsInitially ?? false);
  const { checkPermissions } = usePermissions();
  const canUpdateAdvertisement = checkPermissions('views/tiptap/advertisement', 'update');
  const hasMore = useRef(false);
  const page = useRef(1);

  useEffect(() => {
    if (node.attrs.showOptionsInitially) {
      updateAttributes({
        showOptionsInitially: false,
      });
    }
  }, [updateAttributes, node.attrs.showOptionsInitially]);

  const onSearch = useCallback(
    (q: string) => {
      API.getAdvertisementOpportunitiesOptions({
        publicationId,
        page: 1,
        q,
      })
        .then((res: AxiosResponse) => {
          page.current = res.data.pagination.page;
          hasMore.current = page.current < res.data.pagination.total_pages;

          setOptions(res.data.options);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId]
  );
  // eslint-disable-next-line
  const debounceOnSearch = useCallback(debounce(onSearch, 200) as any, []);

  const loadNextPage = useCallback(
    (q: string) => {
      API.getAdvertisementOpportunitiesOptions({
        publicationId,
        page: page.current + 1,
        q,
      })
        .then((res: AxiosResponse) => {
          page.current = res.data.pagination.page;
          hasMore.current = page.current < res.data.pagination.total_pages;

          setOptions([...options, ...res.data.options] as any);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId, options]
  );

  useEffect(() => {
    if (opportunityId) {
      API.getAdvertisementOpportunity({
        publicationId,
        opportunityId,
      })
        .then((res) => {
          setData(res.data);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    } else {
      API.getAdvertisementOpportunitiesOptions({
        publicationId,
        page: page.current,
      })
        .then((res) => {
          page.current = res.data.pagination.page;
          hasMore.current = page.current < res.data.pagination.total_pages;

          setOptions(res.data.options);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    }
  }, [publicationId, opportunityId]);

  return (
    <NodeViewWrapper
      data-drag-handle
      data-id={opportunityId}
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <div {...{ inert: editor.isEditable ? undefined : '' }}>
        {data ? (
          <Styled.DataContainer>
            <div className="flex items-center space-x-2 text-sm font-bold text-primary-600">
              <Icon name="Banknotes" />
              <span>Advertisement</span>
            </div>
            <Styled.TextContainer>
              <Styled.TextContainer>
                <b>
                  {data.advertiser.name} / {data.campaign.name}
                </b>
              </Styled.TextContainer>
              {data.selected_date && (
                <Styled.TextContainer>
                  This advertisement must be sent on {moment(data.selected_date).format('MMMM Do, YYYY.')}
                </Styled.TextContainer>
              )}
            </Styled.TextContainer>
{ canUpdateAdvertisement ? (
  <Button
    $variant="secondary"
    $size="small"
    $leftSlot={<Icon name="External" />}
    href={`/monetize/ads/opportunities/${data.id}`}
    as="a"
    target="_blank"
  >
    View details
  </Button>
) : (
  <Button
    $variant="secondary"
    $size="small"
    $leftSlot={<Icon name="External" />}
    $isDisabled
    $tooltip={NO_PERMISSION_MESSAGE}
  >
    View details
  </Button>
)}

          </Styled.DataContainer>
        ) : (
          <Styled.SelectionContainer>
            <Tippy
              offset={[0, 8]}
              placement="bottom"
              interactive
              visible={canUpdateAdvertisement && selectionMenuOpen}
              onShow={() => {
                setSelectionMenuOpen(true);
              }}
              onHidden={() => {
                setSelectionMenuOpen(false);
              }}
              onClickOutside={() => {
                setSelectionMenuOpen(false);
              }}
              render={(attrs: TippyProps) =>
                selectionMenuOpen ? (
                  <SearchList
                    tabIndex={-1}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...attrs}
                    items={options.map((option) => {
                      return {
                        value: option.id,
                        label: `${option.advertiser_name} / ${moment(option.selected_date).format('MMM Do')}`,
                      };
                    })}
                    hasMore={hasMore.current}
                    onLoadMore={(q: any) => {
                      loadNextPage(q);
                    }}
                    searchable
                    searchPlaceholder="Search ads"
                    onSearch={(q: any) => {
                      debounceOnSearch(q);
                    }}
                    onSelect={(item: SearchListItemProps) => {
                      editor.chain().focus().updateAttributes('advertisementOpportunity', { id: item.value }).run();
                    }}
                    onBack={() => {
                      setSelectionMenuOpen(false);
                    }}
                    footer={
                      <Button
                        $variant="quaternary"
                        $size="small"
                        $rightSlot={<Icon name="ArrowSmallRight" />}
                        $fullWidth
                        as="a"
                        href="/monetize/ads"
                        target="_blank"
                      >
                        Monetize with ads
                      </Button>
                    }
                  />
                ) : null
              }
            >
              <Button
                $variant="secondary"
                $size="small"
                $active={selectionMenuOpen}
                $leftSlot={<Icon name="Banknotes" />}
                onClick={() => {
                  setSelectionMenuOpen(!selectionMenuOpen);
                }}
                $isDisabled={!canUpdateAdvertisement}
                $tooltip={!canUpdateAdvertisement ? NO_PERMISSION_MESSAGE : undefined}
              >
                Select advertisement
              </Button>
            </Tippy>
          </Styled.SelectionContainer>
        )}
      </div>
    </NodeViewWrapper>
  );
};
