import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BarList, Bold, Flex, Text } from '@tremor/react';

import Card from '@/components/Card';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useCurrentTimeZone } from '@/hooks';
import { TimePeriod } from '@/utils';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

type Source = {
  name: string;
  value: number;
};

type SourceProps = {
  period?: TimePeriod;
};

type ResponseType = {
  name: string;
  count: number;
};

const transformData = (sources: ResponseType[]) => {
  return sources.map((source) => ({ name: source.name, value: source.count }));
};

const AcquisitionSource = ({ period }: SourceProps) => {
  const currentTimeZone = useCurrentTimeZone();
  const [isLoading, setIsLoading] = useState(false);
  const [, setResponseData] = useState<ResponseType[]>([]);
  const [sources, setSources] = useState<Source[]>([]);
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBreakdown = useCallback(() => {
    if (currentPublicationId) {
      setIsLoading(true);

      const params = {
        group_cols: ['channel', 'source', 'medium'],
        group_by_period: false,
        period,
        time_zone: currentTimeZone,
        tier: 'premium',
      };

      api
        .get(`/publications/${currentPublicationId}/acquisition_sources_stats`, { params })
        .then((res) => {
          const transformedData = transformData(res.data);

          setSources(transformedData);
          setResponseData(res.data);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPublicationId, period, currentTimeZone]);

  useEffect(() => {
    fetchBreakdown();
  }, [fetchBreakdown]);

  const renderEmptyState = () => <p className="mt-4">No data</p>;

  return (
    <Card title="Paid Subscribers by Acquisition Source" className="w-full h-full pb-20">
      {isLoading && <LoadingSpinner className="ml-2" />}
      {!isLoading && sources && sources.length === 0 && renderEmptyState()}

      <Flex className="mt-4">
        <Text>
          <Bold>Source</Bold>
        </Text>
        <Text>
          <Bold>Subscriptions</Bold>
        </Text>
      </Flex>
      <BarList data={sources} className="mt-2" />
    </Card>
  );
};

export default AcquisitionSource;
