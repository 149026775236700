import { useParams } from 'react-router-dom';

import { useAdvertisement, useAdvertisementUpdate } from '@/hooks/useAdNetwork/internal';
import { AdNetworkAdvertisement } from '@/interfaces/ad_network/internal/advertisement';

import { PageHeading } from '../../_components/PageHeading';

import { Form } from './_components/Form';

interface Props {
  advertisement: AdNetworkAdvertisement;
}

const AdvertisementShow = ({ advertisement }: Props) => {
  const onSubmitMutation = useAdvertisementUpdate({ id: advertisement.id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{advertisement.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Edit</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <Form advertisement={advertisement} onSubmitMutation={onSubmitMutation} />
    </>
  );
};

export default function Loader() {
  const { advertisement_id: id } = useParams<'advertisement_id'>() as { advertisement_id: string };
  const { data, isLoading, isError } = useAdvertisement({ id });

  if (isLoading || isError) return null;

  return <AdvertisementShow advertisement={data as AdNetworkAdvertisement} />;
}
