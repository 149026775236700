import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TopLineMetrics } from '@/interfaces/boosts/top_line_metrics';
import api from '@/services/swarm';

interface Variables {
  recommendingPublicationId?: string;
}

const useTopLineMetrics = ({ recommendingPublicationId }: Variables) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchMetrics = (): Promise<TopLineMetrics> =>
    api
      .get(`/boosts/grow/top_line_metrics`, {
        params: {
          publication_id: currentPublicationId,
          recommending_publication_id: recommendingPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'grow', 'top_line_metrics'], fetchMetrics, {
    keepPreviousData: true,
  });
};

export default useTopLineMetrics;
