import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import { useExternalRssFeed, useExternalRssFeedUpdate } from '@/hooks/useExternalRssFeeds';
import { ExternalRssFeed } from '@/interfaces/external_rss_feed';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import { usePublicationSettings } from '../../context';
import ExternalRssFeedForm from '../components/ExternalRssFeedForm';

const EditExternalRssFeed = () => {
  const { externalRssFeedId } = useParams<{ externalRssFeedId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentPublicationId } = usePublicationSettings();
  const {
    data: externalRssFeedData,
    isLoading: isLoadingData,
    isError: isErrorData,
  } = useExternalRssFeed(currentPublicationId, externalRssFeedId || '');

  const { mutateAsync: updateExternalRssFeed, isLoading } = useExternalRssFeedUpdate({
    publicationId: currentPublicationId,
    externalRssFeedId: externalRssFeedId || '',
    onSuccess: () => {
      toast.success('RSS feed updated successfully');
      queryClient.invalidateQueries(['publications', currentPublicationId, 'external_rss_feeds', externalRssFeedId]);
      navigate(appendSettingsPublicationId('/settings/publication/rss', currentPublicationId));
    },
  });

  const handleSave = (payload: ExternalRssFeed) => updateExternalRssFeed(payload);

  return (
    <LoadingBox isLoading={isLoadingData} isError={isErrorData}>
      <ExternalRssFeedForm externalRssFeed={externalRssFeedData} onSave={handleSave} isSaving={isLoading} />
    </LoadingBox>
  );
};

export default EditExternalRssFeed;
