import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Publication } from '@/interfaces/publication';

import { Section } from '../../../../Components';

import AddPublication from './AddPublication';
import Publications from './Publications';

interface Props {
  sectionTitle: string;
  sectionDescription: string;
  webTemplate: any;
  publications: Publication[];
  isLoading: boolean;
  isV2?: boolean;
  attributeName: string;
  showLabelText?: boolean;
}

const MultiPubSection = ({
  webTemplate,
  publications,
  isLoading,
  isV2,
  sectionTitle,
  sectionDescription,
  attributeName,
  showLabelText = true,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const multiPubIds = webTemplate?.[attributeName] || [];
  const selectedPubs = multiPubIds
    .map((id: string) => publications?.find((pub: Publication) => pub.id === id))
    .filter(Boolean);
  const unselectedPubs = publications?.filter((pub: Publication) => {
    return pub.id !== currentPublicationId && !multiPubIds.includes(pub.id);
  });

  if (isV2) {
    return (
      <div className="space-y-6">
        <Publications attributeName={attributeName} publications={selectedPubs} />
        <AddPublication
          attributeName={attributeName}
          webTemplate={webTemplate}
          publications={unselectedPubs}
          isLoadingPublications={isLoading}
          showLabelText={showLabelText}
        />
      </div>
    );
  }

  return (
    <Section
      title={sectionTitle}
      description={sectionDescription}
      scrollToId={`multi-pubs-${attributeName}`}
      isLoading={isLoading}
    >
      <div className="space-y-6">
        <Publications attributeName={attributeName} publications={selectedPubs} />
        <AddPublication
          attributeName={attributeName}
          webTemplate={webTemplate}
          publications={unselectedPubs}
          isLoadingPublications={isLoading}
          showLabelText={showLabelText}
        />
      </div>
    </Section>
  );
};

export default MultiPubSection;
