import { useState } from 'react';
import { useQuery } from 'react-query';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentTimeZone } from '@/hooks';
import { AdNetworkOpportunityEvent } from '@/interfaces/ad_network/publisher/opportunity_event';
import api from '@/services/swarm';

import CalendarButton from './CalendarButton';
import generateCalendar from './generateCalendar';
import { Event } from './types';

const useEvents = ({ monthNumber, year }: { monthNumber: number; year: number }) => {
  const [publicationId] = useCurrentPublicationState();
  const timeZone = useCurrentTimeZone();
  const { data, isSuccess } = useQuery<Event[]>(
    [publicationId, 'opportunity_calendar', timeZone, monthNumber, year],
    () => {
      return api
        .get('/ad_network/publisher/calendar', {
          params: {
            publication_id: publicationId,
            time_zone: timeZone,
            month_number: monthNumber,
            year,
          },
        })
        .then((res) =>
          res.data.map((event: AdNetworkOpportunityEvent) => ({
            ...event,
            date: moment(event.date),
          }))
        );
    }
  );

  return isSuccess ? data : [];
};

const Calendar = () => {
  const [monthOffset, setMonthOffset] = useState(0);
  const activeMonth = moment().add(monthOffset, 'months');
  const events = useEvents({ monthNumber: activeMonth.month() + 1, year: activeMonth.year() });
  const month = generateCalendar(activeMonth.year(), activeMonth.month(), events);

  const handlePreviousMonth = () => {
    setMonthOffset(monthOffset - 1);
  };

  const handleNextMonth = () => {
    setMonthOffset(monthOffset + 1);
  };

  return (
    <div>
      <div className="relative grid gap-6">
        <button
          type="button"
          className="absolute -left-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={handlePreviousMonth}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="absolute -right-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={handleNextMonth}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>

        <section className="text-center">
          <h2 className="text-sm font-semibold text-gray-900">{month.name}</h2>
          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm ring-1 ring-gray-200">
            {month.days.map((day) => (
              <CalendarButton key={day.index} day={day} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Calendar;
