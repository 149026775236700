import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface ApiResponse {
  id: string;
}

interface Props {
  formId: string;
  onSuccess?: (result: ApiResponse) => void;
}

const useReorderFormQuestions = ({ formId, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, { questionOrder: any }>(
    ({ questionOrder }) =>
      api
        .patch(`/reorder_questions/${formId}`, {
          publication_id: currentPublicationId,
          form_id: formId,
          questions: questionOrder,
        })
        .then((res) => res.data),
    {
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
      onSuccess,
    }
  );
};

export default useReorderFormQuestions;
