import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { PostTheme } from '../pages/ThemeEditor/types';
import api from '../services/swarm';

const useUpdatePostTheme = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    (params: PostTheme) =>
      api
        .patch(`/post_theme`, {
          publication_id: currentPublicationId,
          post_theme: { ...params },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['postTheme', currentPublicationId], { exact: true });
        queryClient.invalidateQueries(['postTheme', currentPublicationId, 'preview'], { exact: true });
      },
    }
  );
};

export default useUpdatePostTheme;
