import moment from 'moment-mini';

import Card from '@/components/Card';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';

interface Props {
  name: string;
  reason: string;
  time: string;
}

const CommentReportDisplay = ({ name, reason, time }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  if (isV2) {
    return (
      <Card background="bg-surface-50">
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-4 items-center">
            <Typography token="font-medium/text/sm">{name}</Typography>
            <Typography token="font-normal/text/xs">{moment(time).format('LLL')}</Typography>
          </div>
          <Typography token="font-normal/text/xs">&quot;{reason}&quot;</Typography>
        </div>
      </Card>
    );
  }

  return (
    <div className="space-y-1">
      <p className="text-sm text-gray-700 font-regular">
        {' '}
        &quot;{reason}&quot; - <span className="text-primary-500">{name}</span>
      </p>
      <p className="text-xs text-gray-500 font-light">{moment(time).format('LLL')}</p>
    </div>
  );
};

export default CommentReportDisplay;
