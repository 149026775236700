import { useParams } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon, ClipboardIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useCopyToClipboard, useInfiniteScroll } from '@/hooks';
import useCampaignOpportunities from '@/hooks/useAdNetwork/advertiser_access/useCampaignOpportunities';
import {
  AdNetworkCampaignPerformanceFilter,
  AdNetworkCampaignPerformanceOpportunity,
} from '@/interfaces/ad_network/internal/campaign_performance_opportunity';
import { InfiniteScrollOptions } from '@/interfaces/infinite_scroll_options';

interface Props {
  opportunities: AdNetworkCampaignPerformanceOpportunity[];
  infiniteScrollOptions: InfiniteScrollOptions;
  isFetchingNextPage: boolean;
}

const formatter = new Intl.NumberFormat('en-US');

const CampaignPerformanceUpcoming = ({ opportunities, infiniteScrollOptions, isFetchingNextPage }: Props) => {
  const { ref: lastElementRef } = useInfiniteScroll(infiniteScrollOptions);

  const copy = useCopyToClipboard();

  return (
    <div className="flex flex-col overflow-x-auto">
      <div className="overflow-x-auto w-full">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="text-lg font-bold whitespace-nowrap pr-6" align="left">
                Publication
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="left">
                Version
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="left">
                Date
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                Est. Clicks
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                Est. Opens
              </th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {opportunities.map((opportunity) => (
              <tr
                key={opportunity.ad_network_opportunity_id}
                ref={
                  opportunities[opportunities.length - 1].ad_network_opportunity_id ===
                  opportunity.ad_network_opportunity_id
                    ? lastElementRef
                    : null
                }
              >
                <td
                  className="py-6 pr-6 truncate group cursor-pointer"
                  align="left"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  onClick={() =>
                    copy({
                      text: opportunity.publication_alphanumeric_id,
                      onSuccessText: `Copied ${opportunity.publication_alphanumeric_id} to clipboard`,
                      onErrorText: 'Failed to copy to clipboard',
                    })
                  }
                >
                  <p className="inline-flex space-x-2 items-center">
                    <span className="font-mono">{opportunity.publication_alphanumeric_id}</span>
                    <span>
                      <ClipboardIcon className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 duration-300" />
                    </span>
                  </p>
                </td>
                <td className="py-6 pl-6 truncate" align="left">
                  <a
                    href={opportunity.advertisement_option_url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-feedback-info-500 hover:underline inline-flex items-center space-x-1"
                  >
                    <span>{opportunity.advertisement_option_name}</span>
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 inline" />
                  </a>
                </td>
                <td className="py-6 pl-6 truncate" align="left">
                  {moment(opportunity.selected_date).format('MMM D, YYYY')}
                </td>
                <td className="py-6 pl-6 truncate" align="right">
                  {formatter.format(opportunity.estimated_clicks)}
                </td>
                <td className="py-6 pl-6 truncate" align="right">
                  {formatter.format(opportunity.estimated_opens || 0)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center text-gray-400 text-sm h-12">
        {infiniteScrollOptions.isFetching || infiniteScrollOptions.isLoading || isFetchingNextPage ? (
          <>
            <LoadingSpinner className="mr-2" />
            <p>Loading reports...</p>
          </>
        ) : (
          infiniteScrollOptions.hasNextPage && <p>Scroll down to load more reports</p>
        )}
      </div>
    </div>
  );
};

export default function Loader() {
  const { advertiser_id: advertiserId, campaign_id: campaignId } = useParams<{
    advertiser_id: string;
    campaign_id: string;
  }>() as { advertiser_id: string; campaign_id: string };

  const { data, isSuccess, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isLoading } =
    useCampaignOpportunities({
      campaignId,
      advertiserId,
      filter: AdNetworkCampaignPerformanceFilter.UPCOMING,
    });

  if (!isSuccess) return null;

  const opportunities = data?.pages.map((page) => page.opportunities).flat();

  return (
    <CampaignPerformanceUpcoming
      opportunities={opportunities}
      infiniteScrollOptions={{
        fetchNextPage,
        hasNextPage: !!hasNextPage,
        isFetching,
        isLoading,
      }}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}
