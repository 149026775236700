import { DragEvent, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { audioTypeRegex } from '@/components/TiptapEditor/lib/constants';

import { API } from '../../../lib/api';
import { IAudioAttrs } from '../types';

export const useAudioUploader = ({ publicationId, userId }: { publicationId: string; userId?: string }) => {
  const [loading, setLoading] = useState(false);

  const uploadAudioFile = useCallback(
    async (file: File) => {
      if (!userId) {
        toast.error('Please log in');
        return null;
      }

      setLoading(true);

      try {
        const res = await API.getUploadPublicationDownloadablePresignedUrl({
          file,
          userId,
          publicationId,
        });

        const { file_upload_url: presignedUrl, downloadable_id: audioId } = res.data;

        await API.uploadFileToPresignedUrl({ file, presignedUrl });

        await API.markDownloadableAsUploaded({ downloadableId: audioId, publicationId });

        return audioId;
      } catch (errPayload: any) {
        const error = errPayload?.response?.data?.error || 'Something went wrong';

        toast.error(error);

        return null;
      } finally {
        setLoading(false);
      }
    },
    [publicationId, userId]
  );

  return { loading, uploadAudioFile };
};

export const useDropZone = ({
  uploader,
  onUpload,
}: {
  uploader: (file: File) => Promise<IAudioAttrs['id']>;
  onUpload: ({ file, assetId }: { assetId: string; file: File }) => void;
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [draggedInside, setDraggedInside] = useState<boolean>(false);

  useEffect(() => {
    const dragStartHandler = () => {
      setIsDragging(true);
    };

    const dragEndHandler = () => {
      setIsDragging(false);
    };

    document.body.addEventListener('dragstart', dragStartHandler);
    document.body.addEventListener('dragend', dragEndHandler);

    return () => {
      document.body.removeEventListener('dragstart', dragStartHandler);
      document.body.removeEventListener('dragend', dragEndHandler);
    };
  }, []);

  const onDrop = useCallback(
    async (e: DragEvent<HTMLDivElement>) => {
      setDraggedInside(false);

      if (e.dataTransfer.files.length === 0) {
        return;
      }

      const fileList = e.dataTransfer.files;

      const files: File[] = [];

      for (let i = 0; i < fileList.length; i += 1) {
        const item = fileList.item(i);
        if (item) {
          files.push(item);
        }
      }

      const filteredFiles = files.filter((file) => audioTypeRegex.exec(file.type));

      if (!filteredFiles.length) {
        return;
      }

      e.preventDefault();

      const file = filteredFiles.length > 0 ? filteredFiles[0] : undefined;

      if (file) {
        const assetId = await uploader(file);

        if (assetId) {
          onUpload({ assetId, file });
        }
      }
    },
    [uploader, onUpload]
  );

  const onDragEnter = () => {
    setDraggedInside(true);
  };

  const onDragLeave = () => {
    setDraggedInside(false);
  };

  return { isDragging, draggedInside, onDragEnter, onDragLeave, onDrop };
};
