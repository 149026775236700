import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { EmptyCard } from '@/components/ResourceList';
import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import { useComments } from '../../../../hooks/useComments';
import { Comment } from '../../../../interfaces/comment';
import ReportActionModal from '../ReportActionModal';

import ReportsSlideOver from './ReportsSlideOver';
import CommentReportSections from '.';

const ActionNeededSection = () => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const { postId } = useParams();

  const paginatedCommentsQuery = useComments({
    postId: postId || '',
    commentId: '',
    perPage: 5,
    resolved_reports: false,
  });
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = paginatedCommentsQuery;
  const comments = data?.pages.flatMap((page) => page.comments) || [];

  const [currentReport, setCurrentReport] = useState<any>(null);
  const [comment, setComment] = useState<Comment | null>(null);
  const [pendingReports, setPendingReports] = useState<any>([]);

  const handleOpenActionModal = (report: any) => {
    setCurrentReport(report);
  };

  const handleViewReports = (currentComment: any) => {
    setComment(currentComment);
  };

  if (!comments || comments.length === 0) {
    return isV2 ? (
      <EmptyCard title="No Reported Comments" description="You have no reported comments that require an action" />
    ) : null;
  }

  if (isV2) {
    return (
      <>
        <ReportActionModal
          isOpen={Boolean(currentReport)}
          onClose={() => setCurrentReport(null)}
          onSuccess={() => {
            setPendingReports([...pendingReports, currentReport.id]);
          }}
          comment={currentReport}
          postId={postId}
        />
        <ReportsSlideOver
          isOpen={Boolean(comment)}
          onClose={() => setComment(null)}
          bodyId={comment?.id || ''}
          comment={comment}
          resolvedReports={false}
        />
        <CommentReportSections
          onTakeAction={handleOpenActionModal}
          onViewReports={handleViewReports}
          pendingReports={pendingReports}
          comments={comments}
        />
        {hasNextPage && (
          <div className="flex justify-start mt-8 w-fit">
            <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <ReportActionModal
        isOpen={Boolean(currentReport)}
        onClose={() => setCurrentReport(null)}
        onSuccess={() => {
          setPendingReports([...pendingReports, currentReport.id]);
        }}
        comment={currentReport}
        postId={postId}
      />
      <ReportsSlideOver
        isOpen={Boolean(comment)}
        onClose={() => setComment(null)}
        bodyId={comment?.id || ''}
        comment={comment}
        resolvedReports={false}
      />
      <CommentReportSections
        title="Action Required"
        onTakeAction={handleOpenActionModal}
        onViewReports={handleViewReports}
        pendingReports={pendingReports}
        comments={comments}
      />
      {hasNextPage && (
        <div className="w-full flex justify-center py-4">
          <Button
            variant="primary-inverse"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? 'Loading more...' : 'Load more'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActionNeededSection;
