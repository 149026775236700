import { useCallback, useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../UI/DropdownMenu';
import { SimpleInput, SimpleInputWrapper } from '../../../UI/Input';
import { Text } from '../../../UI/Text';

type SimpleLengthSettingsProps = {
  defaultValue: Length;
  onChange?: (length: Length) => void;
  title: string;
  units?: Array<string>;
};

const DEFAULT_UNITS = ['em', 'px'];

// https://developer.mozilla.org/en-US/docs/Web/CSS/length
export type Length = {
  value: number;
  unit: string;
};

export const parseLength = (length: string): Length => {
  // grab the numbers from length string
  const value = length.match(/[\d.]+/)?.[0] || '0';
  const unit = length.match(/[a-z%]+$/i)?.[0] || '';
  return {
    value: parseFloat(value),
    unit,
  };
};

export const SimpleLengthSettings = ({
  defaultValue,
  onChange,
  title,
  units = DEFAULT_UNITS,
}: SimpleLengthSettingsProps) => {
  const [defaultLength, setDefaultLength] = useState(defaultValue.value);
  const [defaultUnit, setDefaultUnit] = useState(defaultValue.unit);

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return;
      const { value } = e.target;
      setDefaultLength(parseFloat(value));
      onChange?.({ value: parseFloat(value), unit: defaultUnit });
    },
    [defaultUnit, onChange]
  );

  const handleUnitChange = useCallback(
    (unit: string) => {
      setDefaultUnit(unit);
      onChange?.({ value: defaultLength, unit });
    },
    [defaultLength, onChange]
  );

  return (
    <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
      <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <SimpleInputWrapper className="h-[32px] relative">
        <SimpleInput type="number" className="text-xs" defaultValue={defaultLength} onChange={handleValueChange} />
      </SimpleInputWrapper>
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="cursor-pointer">
          <div className="w-full h-[32px] bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-2">
            <div className="flex items-center gap-1">
              <Text size="2xs" weight="medium">
                {defaultUnit}
              </Text>
            </div>
            <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer">
          {units.map((unit) => (
            <DropdownMenuItem
              key={unit}
              onSelect={() => {
                handleUnitChange(unit);
              }}
            >
              {unit}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
