import React from 'react';

import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import UpgradeIntent from '@/components/UpgradeIntent/TieredUpgradeIntent';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';

const ExternalRssFeedsTeaser = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <BodyContainer>
      <UpgradeIntent isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Card>
        <CardHeader title="External RSS Feeds" />

        <TypographyStack gap="4" className="mt-4 mb-6">
          <TypographyRow>
            <Typography token="font-normal/text/sm" colorWeight="500">
              Add an external RSS feed to your publication. This will allow you to display posts from other sources on
              your website.
            </Typography>
          </TypographyRow>

          <Typography token="font-normal/text/sm" colorWeight="500">
            For example, if you are using WordPress or another 3rd party tool to publish your content via an RSS Feed,
            you can pull that content into your posts by adding your feed here.
          </Typography>
        </TypographyStack>

        <div className="flex flex-col gap-4" />

        <Button
          variant="primary"
          type="submit"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <span>Upgrade to </span>&nbsp;<span className="font-bold">MAX</span>
        </Button>
      </Card>
    </BodyContainer>
  );
};

export default ExternalRssFeedsTeaser;
