import { Input } from '@/components/Form';
import { ExternalRssFeed, RSSCTAAlignment, RSSCTAStyle, RSSThumbnailPosition } from '@/interfaces/external_rss_feed';
import { Dropdown } from '@/ui/Dropdown';
import Switch from '@/ui/Switch';

import BodyContainer from '../../../_components/BodyContainer';
import FormRow from '../../../_components/FormRow';

interface Props {
  externalRssFeed: ExternalRssFeed;
  onChange: (newExternalRssFeed: ExternalRssFeed) => void;
}

const DefaultsForm = ({ externalRssFeed, onChange }: Props) => {
  const {
    entriesToShow,
    ctaStyle,
    ctaText,
    displayAuthor,
    displayContent,
    displayCta,
    displayDescription,
    displayCategories,
    displayThumbnail,
    displayPubDate,
    displayTitle,
    ctaAlignment,
    thumbnailPosition,
  } = externalRssFeed;

  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...externalRssFeed,
        [field]: value,
      });
    }
  };

  const handleThumbnailChange = (field: string, checked: any) => {
    onChange({
      ...externalRssFeed,
      displayThumbnail: checked,
      ctaStyle: !checked && ctaStyle === RSSCTAStyle.THUMBNAIL ? RSSCTAStyle.BUTTON : ctaStyle,
    });
  };

  const handleTitleChange = (field: string, checked: any) => {
    onChange({
      ...externalRssFeed,
      displayTitle: checked,
      ctaStyle: !checked && ctaStyle === RSSCTAStyle.TITLE ? RSSCTAStyle.BUTTON : ctaStyle,
    });
  };

  return (
    <BodyContainer>
      <Input
        type="number"
        name="entriesToShow"
        labelText="Articles to show"
        helperText="Specify the number of articles to show"
        id="create-external-rss-feed-entries-to-show"
        min={1}
        value={entriesToShow?.toString() || '1'}
        onChange={(e) => handleChange('entriesToShow', Number(e.target.value))}
      />
      <FormRow label="Display Article Title" helperText="By default show the article title">
        <Switch name="enabled" checked={displayTitle} variant="primary" size="large" onChange={handleTitleChange} />
      </FormRow>
      <FormRow label="Display Publication Date" helperText="By default show the date the article was published">
        <Switch
          name="enabled"
          checked={displayPubDate}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayPubDate', checked)}
        />
      </FormRow>
      <FormRow label="Display Thumbnail" helperText="Show the thumbnail image for the article">
        <Switch
          name="enabled"
          checked={displayThumbnail}
          variant="primary"
          size="large"
          onChange={handleThumbnailChange}
        />
      </FormRow>
      {displayThumbnail && (
        <FormRow label="Thumbnail Position" helperText="Where to Place the Thumbnail realtive to the content">
          <Dropdown
            name="thumbnailPosition"
            value={thumbnailPosition}
            options={[
              { label: RSSThumbnailPosition.TOP, value: RSSThumbnailPosition.TOP },
              { label: RSSThumbnailPosition.BOTTOM, value: RSSThumbnailPosition.BOTTOM },
              { label: RSSThumbnailPosition.LEFT, value: RSSThumbnailPosition.LEFT },
              { label: RSSThumbnailPosition.RIGHT, value: RSSThumbnailPosition.RIGHT },
              {
                label: RSSThumbnailPosition.ALTERNATING_HORIZONTAL,
                value: RSSThumbnailPosition.ALTERNATING_HORIZONTAL,
              },
            ]}
            onSelect={(name, value) => handleChange('thumbnailPosition', value)}
          />
        </FormRow>
      )}
      <FormRow label="Display Categories" helperText="Show the article categories">
        <Switch
          name="enabled"
          checked={displayCategories}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayCategories', checked)}
        />
      </FormRow>
      <FormRow label="Display Author" helperText="Show the byline for the article">
        <Switch
          name="enabled"
          checked={displayAuthor}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayAuthor', checked)}
        />
      </FormRow>
      <FormRow label="Display Description" helperText="Show the article description">
        <Switch
          name="enabled"
          checked={displayDescription}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayDescription', checked)}
        />
      </FormRow>
      <FormRow label="Display Full Article" helperText="Show the full article contents">
        <Switch
          name="enabled"
          checked={displayContent}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayContent', checked)}
        />
      </FormRow>
      <FormRow label="Display CTA" helperText="Show a link to the full article">
        <Switch
          name="enabled"
          checked={displayCta}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayCta', checked)}
        />
      </FormRow>
      {displayCta && (
        <Dropdown
          name="ctaStyle"
          labelText="CTA Style"
          value={ctaStyle}
          options={[
            { label: RSSCTAStyle.BUTTON, value: RSSCTAStyle.BUTTON },
            { label: RSSCTAStyle.LINK, value: RSSCTAStyle.LINK },
            { label: RSSCTAStyle.TITLE, value: RSSCTAStyle.TITLE, disabled: !displayTitle },
            {
              label: RSSCTAStyle.THUMBNAIL,
              value: RSSCTAStyle.THUMBNAIL,
              disabled: !displayThumbnail,
            },
            {
              label: RSSCTAStyle.TITLE_AND_THUMBNAIL,
              value: RSSCTAStyle.TITLE_AND_THUMBNAIL,
              disabled: !displayThumbnail || !displayTitle,
            },
          ]}
          onSelect={(name, value) => handleChange('ctaStyle', value)}
        />
      )}
      {displayCta && (ctaStyle === RSSCTAStyle.BUTTON || ctaStyle === RSSCTAStyle.LINK) && (
        <Input
          type="text"
          name="ctaText"
          label="CTA Text"
          id="create-external-rss-feed-cta-text"
          labelText="CTA Text"
          helperText="Customize the call to action text"
          value={ctaText}
          placeholder="View Article"
          onChange={(e) => handleChange('ctaText', e.target.value)}
        />
      )}
      {displayCta && (
        <Dropdown
          name="ctaAlignment"
          labelText="CTA Alignment"
          value={ctaAlignment}
          options={[
            { label: RSSCTAAlignment.LEFT, value: RSSCTAAlignment.LEFT },
            { label: RSSCTAAlignment.CENTER, value: RSSCTAAlignment.CENTER },
            { label: RSSCTAAlignment.RIGHT, value: RSSCTAAlignment.RIGHT },
          ]}
          onSelect={(name, value) => handleChange('ctaAlignment', value)}
        />
      )}
    </BodyContainer>
  );
};

export default DefaultsForm;
