export enum StepType {
  WEB = 'web',
  EMAIL = 'email',
  REVIEW = 'review',
}

export type FormErrors = {
  webDomain?: boolean;
  redirectToDomain?: boolean;
  emailDomain?: boolean;
};
