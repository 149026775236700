import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { LockOpenIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

import Card from '../../../components/Card';
import PageHeading from '../../../components/Layout/PageLayout/PageHeading';
import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { useSettings } from '../../../context/settings-context';
import { useRequireSetting } from '../../../hooks';
import { useTutorial } from '../../../hooks/useTutorials';
import { ReportType } from '../../../interfaces/report';
import { Setting } from '../../../interfaces/setting';
import { TutorialType } from '../../../interfaces/tutorial';
import api from '../../../services/swarm';

interface RouteParams {
  reportType: ReportType;
}

export default function ReportsShow() {
  const subscribersTutorial = useTutorial(TutorialType.SUBSCRIBERS_REPORT);
  const postsTutorial = useTutorial(TutorialType.POSTS_REPORT);

  const { settings } = useSettings();
  const navigate = useNavigate();
  const [reportURL, setReportURL] = useState<string>('');
  const [error, setError] = useState();
  const [currentPublicationId] = useCurrentPublicationState();
  const { reportType } = useParams() as unknown as RouteParams;

  useRequireSetting({
    setting: Setting.REPORTS,
    message: '',
    redirectTo: `/reports/${reportType}/overview`,
  });

  useEffect(() => {
    if (
      !Object.keys(ReportType)
        .map((t) => t.toLowerCase())
        .includes(reportType)
    ) {
      toast.error("Couldn't find that report");
      navigate('/');
    }
  }, [reportType, navigate]);

  const fetchReportURL = () => {
    if (settings?.reports) {
      api
        .get(`/publications/${currentPublicationId}/reports/${reportType}.json`)
        .then((res) => setReportURL(res.data.url))
        .catch((err) => setError(err.message));
    } else {
      setReportURL(`/teasers/reports/${reportType}.html`);
    }
  };

  useEffect(fetchReportURL, [reportType, currentPublicationId, settings]);

  const title = `${String(reportType)[0].toUpperCase()}${String(reportType).slice(1)}s Report`;
  const subtitle = {
    post: "Track your post's performance",
    subscriber: 'Learn about your audience',
    click: 'Track clicks across posts',
  }[String(reportType)];
  const isSubscriberReport = reportType === ReportType.SUBSCRIBER;

  if (reportURL)
    return (
      <>
        <PageHeading
          title={title}
          description={subtitle}
          tutorial={isSubscriberReport ? subscribersTutorial : postsTutorial}
        />
        <Card>
          <div style={{ minHeight: '2700px' }}>
            {settings?.reports ? (
              ''
            ) : (
              <div>
                <Button variant="primary" onClick={() => navigate(`/settings/billing`)} Icon={LockOpenIcon}>
                  Unlock
                </Button>
              </div>
            )}
            <iframe
              title={`report${reportType}`}
              src={reportURL}
              className="rounded pb-12"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ minHeight: '2600px' }}
              data-pid={currentPublicationId}
            />
          </div>
        </Card>
      </>
    );

  if (error)
    return (
      <Card>
        <p>{error}</p>
      </Card>
    );

  return (
    <Card>
      <p>Loading...</p>
    </Card>
  );
}
