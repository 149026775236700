import { FC } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import ErrorText from '@/components/Form/ErrorText';
import MarkDownHelperText from '@/pages/DesignLab/components/MardownHelperText';

import { Typography } from '../../components/Typography';
import HelperText from '../HelperText';

interface Props {
  className?: string;
  name: string;
  defaultValue?: string;
  value?: string;
  labelText?: string;
  inputClassOverride?: string;
  errorText?: string;
  error?: boolean;
  helperText?: string | React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  id?: string;
  placeholderText?: string;
  readOnly?: boolean;
  maxLength?: number;
  cols?: number;
  rows?: number;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  showOptional?: boolean;
  supportsMarkdown?: boolean;
  disabled?: boolean;
  nonResizable?: boolean;
}

const Textarea: FC<Props> = (props: Props) => {
  const {
    className,
    error,
    errorText,
    id,
    name,
    labelText,
    defaultValue,
    value,
    onChange = () => {},
    required,
    readOnly,
    placeholderText,
    helperText,
    maxLength,
    cols,
    rows,
    onBlur,
    showOptional = false,
    inputClassOverride,
    supportsMarkdown = false,
    disabled = false,
    nonResizable = false,
  } = props;
  const hasError = error || !!errorText;

  return (
    <div className={className}>
      <div className="flex justify-between">
        <label htmlFor={`textarea-${name}`} className="flex items-center gap-1 text-sm font-medium text-gray-700">
          {labelText}
          {required ? ' *' : ''}
          {supportsMarkdown && (
            <Typography token="font-light/text/xs" colorWeight="400" as="span">
              <MarkDownHelperText />
            </Typography>
          )}
        </label>
        {showOptional && (
          <Typography token="font-normal/text/sm" colorWeight="500">
            Optional
          </Typography>
        )}
      </div>
      <div className="mt-2 relative">
        <textarea
          id={`textarea-${id || name}`}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholderText}
          required={required}
          readOnly={readOnly}
          onBlur={onBlur}
          className={cx(
            !hasError && !disabled && 'border-surface-200',
            disabled && 'bg-surface-100 border-surface-200 text-gray-400 cursor-not-allowed',
            inputClassOverride ||
              'min-h-24 appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:shadow-[0px_0px_0px_4px_#E5E7EB,0px_1px_2px_0px_rgba(0,0,0,0.05)] focus:border-surface-300 focus:ring-0 text-sm text-gray-900 placeholder-gray-400',
            hasError &&
              'focus:border-surface-300 !border-feedback-danger-600 !focus:border-feedback-danger-600 focus:shadow-[0px_0px_0px_4px_#FECACA,_0px_1px_2px_0px_rgba(0,0,0,0.05)] pr-10 z-30',
            nonResizable && 'resize-none'
          )}
          maxLength={maxLength}
          cols={cols}
          rows={rows}
          disabled={disabled}
        >
          {defaultValue}
        </textarea>
        {helperText && (
          <HelperText className={cx('mt-2', hasError && '!text-feedback-danger-600')}>{helperText}</HelperText>
        )}
        {errorText && <ErrorText className="mt-2">{errorText}</ErrorText>}
        {hasError && (
          <div className={cx('absolute right-0 top-0 mt-2 mr-3 text-feedback-danger-600 z-50')}>
            <ExclamationCircleIcon width={20} height="auto" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Textarea;
