import { useMemo, useState } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { PANEL } from './types';

export const useDragHandleData = () => {
  const [currentNode, setCurrentNode] = useState<Node | null>(null);
  const [currentNodePos, setCurrentNodePos] = useState<number>(-1);
  const [currentPanel, setCurrentPanel] = useState<PANEL>(PANEL.main);

  return {
    currentNode,
    currentNodePos,
    currentPanel,
    setCurrentNode,
    setCurrentNodePos,
    setCurrentPanel,
  };
};

export const useCurrentNode = (editor: Editor, currentNode: Node | null, themeColors: any[]) => {
  const {
    isDefaultTextColor,
    isDefaultPadding,
    isDefaultMargin,
    isDefaultBackgroundColor,
    isCustomTextColor,
    isCustomBackgroundColor,
    isCustomBorderColor,
    isDefaultBorderRadius,
    isDefaultBorderWidth,
    isDefaultBorderStyle,
    isDefaultBorderColor,
    hasVisualSettingsOverrides,
    hasVisibilitySettingsOverrides,
    hasAnchorSettingsOverrides,
    hasBorderOverrides,
    hasSpacingOverrides,
  } = useMemo(() => {
    const defaults = {
      isDefaultBackgroundColor: true,
      isDefaultTextColor: true,
      isDefaultPadding: true,
      isDefaultMargin: true,
      isCustomTextColor: false,
      isCustomBackgroundColor: false,
      isCustomBorderColor: false,
      isDefaultBorderRadius: true,
      isDefaultBorderWidth: true,
      isDefaultBorderStyle: true,
      isDefaultBorderColor: true,
      hasVisualSettingsOverrides: false,
      hasVisibilitySettingsOverrides: false,
      hasAnchorSettingsOverrides: false,
      hasBorderOverrides: false,
      hasSpacingOverrides: false,
    };

    if (!currentNode) {
      return defaults;
    }

    const nodeAttributes = currentNode?.attrs;

    const newHasAnchorSettingsOverrides = nodeAttributes.anchorEnabled;

    // Everything except anchors is bind to a section
    if (currentNode.type.name !== 'section') {
      return {
        ...defaults,
        hasAnchorSettingsOverrides: newHasAnchorSettingsOverrides,
      };
    }

    const newIsDefaultTextColor = !nodeAttributes.color;
    const newIsDefaultBackgroundColor = !nodeAttributes.backgroundColor;
    const newIsDefaultBorderColor = !nodeAttributes.borderColor;

    // TODO: Default === 0? Theme values?
    const newIsDefaultPadding =
      !nodeAttributes.paddingTop &&
      !nodeAttributes.paddingBottom &&
      !nodeAttributes.paddingLeft &&
      !nodeAttributes.paddingRight;

    const newIsDefaultMargin =
      !nodeAttributes.marginTop &&
      !nodeAttributes.marginBottom &&
      !nodeAttributes.marginLeft &&
      !nodeAttributes.marginRight;

    const newIsCustomTextColor = newIsDefaultTextColor
      ? false
      : !themeColors.find((themeColor) => {
          return themeColor.value === nodeAttributes.color;
        });

    const newIsCustomBackgroundColor = newIsDefaultBackgroundColor
      ? false
      : !themeColors.find((themeColor) => {
          return themeColor.value === nodeAttributes.backgroundColor;
        });

    const newIsCustomBorderColor = newIsDefaultBorderColor
      ? false
      : !themeColors.find((themeColor) => {
          return themeColor.value === nodeAttributes.borderColor;
        });

    const newIsDefaultBorderRadius =
      !nodeAttributes.borderTopLeftRadius &&
      !nodeAttributes.borderTopRightRadius &&
      !nodeAttributes.borderBottomLeftRadius &&
      !nodeAttributes.borderBottomRightRadius;

    const newIsDefaultBorderWidth =
      !nodeAttributes.borderWidthTop &&
      !nodeAttributes.borderWidthBottom &&
      !nodeAttributes.borderWidthLeft &&
      !nodeAttributes.borderWidthRight;

    const newIsDefaultBorderStyle = nodeAttributes.borderStyle === 'solid' || !nodeAttributes.borderStyle;

    const newHasBorderOverrides =
      !newIsDefaultBorderRadius || !newIsDefaultBorderWidth || !newIsDefaultBorderStyle || !newIsDefaultBorderColor;

    const newHasSpacingOverrides = !newIsDefaultPadding || !newIsDefaultMargin;

    const newHasVisualSettingsOverrides =
      !newIsDefaultTextColor || !newIsDefaultBackgroundColor || newHasBorderOverrides || newHasSpacingOverrides;

    const newIsDefaultWebsiteVisibility = nodeAttributes.showOnWebsite || nodeAttributes.showOnWebsite === undefined;
    const newIsDefaultEmailVisibility = nodeAttributes.showInEmail || nodeAttributes.showInEmail === undefined;
    const newIsDefaultShowToNonSubscribers =
      nodeAttributes.showToNonSubscribers || nodeAttributes.showToNonSubscribers === undefined;
    const newIsDefaultShowToFreeSubscribers =
      nodeAttributes.showToFreeSubscribers || nodeAttributes.showToFreeSubscribers === undefined;
    const newIsDefaultShowToPaidSubscribers =
      nodeAttributes.showToPaidSubscribers || nodeAttributes.showToPaidSubscribers === undefined;
    const newIsDefaultShowWithReferralCount =
      !nodeAttributes.showWithReferralCount || nodeAttributes.showWithReferralCount === undefined;

    const newHasVisibilitySettingsOverrides =
      !newIsDefaultWebsiteVisibility ||
      !newIsDefaultEmailVisibility ||
      !newIsDefaultShowToNonSubscribers ||
      !newIsDefaultShowToFreeSubscribers ||
      !newIsDefaultShowToPaidSubscribers ||
      !newIsDefaultShowWithReferralCount;

    return {
      isDefaultBackgroundColor: newIsDefaultBackgroundColor,
      isDefaultTextColor: newIsDefaultTextColor,
      isDefaultPadding: newIsDefaultPadding,
      isDefaultMargin: newIsDefaultMargin,
      isCustomTextColor: newIsCustomTextColor,
      isCustomBackgroundColor: newIsCustomBackgroundColor,
      isCustomBorderColor: newIsCustomBorderColor,
      isDefaultBorderRadius: newIsDefaultBorderRadius,
      isDefaultBorderWidth: newIsDefaultBorderWidth,
      isDefaultBorderStyle: newIsDefaultBorderStyle,
      isDefaultBorderColor: newIsDefaultBorderColor,
      hasVisualSettingsOverrides: newHasVisualSettingsOverrides,
      hasVisibilitySettingsOverrides: newHasVisibilitySettingsOverrides,
      hasAnchorSettingsOverrides: newHasAnchorSettingsOverrides,
      hasBorderOverrides: newHasBorderOverrides,
      hasSpacingOverrides: newHasSpacingOverrides,
    };
  }, [editor, currentNode, themeColors]);

  return {
    isDefaultBackgroundColor,
    isDefaultTextColor,
    isDefaultPadding,
    isDefaultMargin,
    isCustomTextColor,
    isCustomBackgroundColor,
    isCustomBorderColor,
    isDefaultBorderRadius,
    isDefaultBorderWidth,
    isDefaultBorderStyle,
    isDefaultBorderColor,
    hasVisualSettingsOverrides,
    hasVisibilitySettingsOverrides,
    hasAnchorSettingsOverrides,
    hasBorderOverrides,
    hasSpacingOverrides,
  };
};

export default useCurrentNode;
