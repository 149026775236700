import moment from 'moment-mini';

import { CustomField, CustomFieldKind } from '@/interfaces/custom_field';

export const formatCustomFieldValue = (customField: CustomField, value: any) => {
  switch (customField.kind) {
    case CustomFieldKind.DATE:
      if (value === 'now') {
        return 'Current date';
      }

      return moment(value).format('LL');
    case CustomFieldKind.DATETIME:
      if (value === 'now') {
        return 'Current date & time';
      }

      return moment(value).format('LL - LT');
    default:
      return value;
  }
};
