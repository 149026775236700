import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Publication } from '@/interfaces/publication';
import { TimePeriod } from '@/interfaces/time_period';
import api from '@/services/swarm';

interface ApiResponse {
  highest_growth_driver: Publication;
  highest_paid_partner: Publication;
}

const usePublicationInsightsPerPeriod = (period: TimePeriod) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchInsights = (): Promise<ApiResponse> =>
    api
      .get(`/boosts/grow/publication_insights/${period}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'grow', 'publication_insights', period], fetchInsights, {
    keepPreviousData: true,
  });
};

export default usePublicationInsightsPerPeriod;
