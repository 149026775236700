import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import { usePublishForm } from '@/hooks/useForms';
import { Form } from '@/interfaces/form';
import { Button } from '@/ui/Button';

interface Props {
  form: Form;
}

const PublishFormButton = ({ form }: Props) => {
  const [isPublishing, setIsPublishing] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const publishForm = usePublishForm({
    formId: form.id || '',
    onSuccess: () => {
      toast.success('Survey published!');
      queryClient.invalidateQueries(['forms', form.id]);
      navigate('/forms');
    },
  });

  const handlePublish = async () => {
    try {
      await publishForm.mutateAsync({
        title: form.title,
        description: form.description,
        ctaText: form.cta_text,
        thankYouMessage: form.thank_you_message,
      });
    } catch (error) {
      toast.error('There was an error publishing this survey');
    }
  };

  const handlePublishForm = () => {
    setIsPublishing(true);
  };

  return (
    <>
      <ActionModal
        isOpen={isPublishing}
        onClose={() => setIsPublishing(false)}
        onProceed={handlePublish}
        resourceId={form.id || ''}
        isWorking={publishForm.isLoading}
        headerText="Publish Survey"
        actionText="Publish"
      >
        <div className="space-y-2">
          <p>Are you sure you want to publish this survey?</p>
          <p>
            To protect the intregrity of the data you receive from subscribers,{' '}
            <span className="font-bold text-gray-900">
              you will NOT be able to edit any of the survey details once it is live.
            </span>
          </p>
          <p>
            Maybe you would like to preview this survey before turning it on?{' '}
            {form?.url && form?.form_questions?.length ? (
              <div className="text-sm text-primary-500 underline underline-primary-500 flex items-center">
                <a
                  href={`${form.url}?preview=true`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm text-primary-500 underline underline-primary-500 flex items-center"
                >
                  <span>Click to Preview</span>.
                </a>
              </div>
            ) : null}
          </p>
        </div>
      </ActionModal>
      <Button size="xs" onClick={handlePublishForm}>
        Publish
      </Button>
    </>
  );
};

export default PublishFormButton;
