import React, { useCallback, useState } from 'react';
import { cx } from 'class-variance-authority';

import { PlanPriceOption, PlanPriceTierSizes } from '@/interfaces/plan_price';
import usePlanPriceOptions from '@/routes/settings/billing/_hooks/usePlanPriceOptions';
import { PLAN } from '@/utils/plans';
import { getLowestTier, plans } from '@/utils/tiered_plans';

export interface ActionProps {
  disabled: boolean;
  newPlanPriceOption: PlanPriceOption | undefined;
  price?: number;
}

interface Props {
  defaultMaxSubscribers?: number;
  defaultBillingInterval?: 'month' | 'year';
  allowDisablingLaunch?: boolean;
  actions?: { [Key in PLAN]?: (props: ActionProps) => React.ReactNode };
}

const PricingPanel: React.FC<Props> = ({
  actions,
  defaultMaxSubscribers = 100,
  defaultBillingInterval = 'month',
  allowDisablingLaunch = true,
}) => {
  const [maxSubscribers, setMaxSubscribers] = useState(defaultMaxSubscribers);
  const [billingInterval, setBillingInterval] = useState<'month' | 'year'>(defaultBillingInterval);

  const { data: planPriceOptions } = usePlanPriceOptions({ enabled: !!actions });

  const findPlanPriceOption = useCallback(
    (planName: string, interval: string, subscriptions: number) =>
      planPriceOptions?.find((option) =>
        planName === PLAN.LAUNCH
          ? option.plan_name === planName // We only have one launch price so only consider the name when comparing
          : option.plan_name === planName && option.interval === interval && option.max_subscriptions === subscriptions
      ),
    [planPriceOptions]
  );

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <label className="flex flex-col justify-end space-y-2" htmlFor="subscribers">
          <span className="text-lg">How many subscribers?</span>
          <select
            className="border-gray-900 border-2 rounded p-2"
            onChange={(e) => setMaxSubscribers(Number(e.target.value))}
            value={maxSubscribers}
          >
            {PlanPriceTierSizes.map((size) => (
              <option value={size}>less than {size.toLocaleString()}</option>
            ))}
            <option value={100001}>more than 100,000</option>
          </select>
        </label>
        <label className="flex flex-col justify-end space-y-2" htmlFor="subscribers">
          <span className="text-lg">How would you like to pay?</span>
          <select
            className="border-gray-900 border-2 rounded p-2"
            onChange={(e) => setBillingInterval(e.target.value as 'month' | 'year')}
            value={billingInterval}
          >
            <option value="month">Monthly</option>
            <option value="year">Yearly</option>
          </select>
        </label>
      </div>

      <div className="border-gray-900 border-2 rounded grid divide-x-2 divide-y-2 lg:divide-y-0 divide-gray-300 grid-cols-1 lg:grid-cols-4">
        {plans.map((plan) => {
          const tier = getLowestTier(plan.id, maxSubscribers);
          const disabled =
            plan.id === PLAN.LAUNCH ? allowDisablingLaunch && tier.disabled : tier.disabled && plan.id !== PLAN.CUSTOM;
          const newPlanPriceOption = findPlanPriceOption(plan.id, billingInterval, maxSubscribers);
          const price = billingInterval === 'month' ? tier.priceMonthly : tier.priceAnnually;

          return (
            <div
              className={cx('p-4 space-y-4 flex flex-col', !!disabled && 'opacity-25 cursor-not-allowed')}
              key={plan.id}
            >
              <p className="uppercase font-black space-x-2 flex items-center text-xl">
                {plan.logo}
                <span>{plan.name}</span>
              </p>
              {billingInterval === 'month' ? (
                <div>
                  <p className="font-bold space-x-2 text-2xl">{tier.pricePerMonthBilledMonthly}</p>
                  <p className="text-gray-600">
                    {plan.id === 'launch' ? (
                      <span>No commitment</span>
                    ) : (
                      <span>{plan.id !== PLAN.CUSTOM ? <>billed monthly</> : <>&nbsp;</>}</span>
                    )}
                  </p>
                </div>
              ) : (
                <div>
                  <p className="font-bold space-x-2 text-2xl">{tier.pricePerMonthBilledAnnually}</p>
                  <p className="text-gray-600">
                    {tier.pricePerYearBilledAnnually}
                    {plan.id === PLAN.CUSTOM && <span>&nbsp;</span>}
                  </p>
                </div>
              )}
              <p className="font-bold">{plan.inclusionDescription}</p>
              <ul className="list-disc">
                {plan.features.map((f) => (
                  <li className="flex items-center space-x-2" key={f}>
                    <div className="w-2 h-2 rounded-full bg-primary-500" />
                    <span className="line-clamp-1">{f}</span>
                  </li>
                ))}
              </ul>
              {actions && actions[plan.id]?.({ disabled, price, newPlanPriceOption })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PricingPanel;
