import CustomFieldSelectInternal from '@/components/CustomFieldSelect';

interface CustomFieldSelectProps {
  resourceId?: string;
  onChange: (resourceId?: string) => void;
  className?: string;
}

const CustomFieldSelect = ({ resourceId, onChange, className }: CustomFieldSelectProps) => (
  <CustomFieldSelectInternal
    className={className}
    customFieldId={resourceId}
    onSelectCustomField={onChange}
    onClearCustomField={() => onChange(undefined)}
  />
);

export default CustomFieldSelect;
