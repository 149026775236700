import { useMutation } from 'react-query';

import api from '../services/swarm';

interface PrevalidateSignupProps {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}

const usePrevalidateSignup = () =>
  useMutation(({ email, firstName, lastName, password }: PrevalidateSignupProps) =>
    api
      .post(`/signups/prevalidate`, {
        email,
        first_name: firstName,
        last_name: lastName,
        password,
      })
      .then((res) => res.data)
  );

export default usePrevalidateSignup;
