import { useQuery } from 'react-query';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { PlanPrice } from '@/interfaces/plan_price';
import api from '@/services/swarm';

interface Props {
  organizationId: string;
  newPlanPriceId: string;
  promotionCode?: string;
}

interface ApiResponse {
  amount_due: number;
  new_unit_amount: number;
  new_max_subscriptions: number;
  new_interval: PlanPrice['interval'];
  new_plan_name: PlanPrice['plan_name'];
  next_billing_date: string | null;
  is_upgrade: boolean;
  lines: {
    amount: number;
    description: string;
    discount_amounts: { amount: number }[];
  }[];
}

const usePlanPriceChange = ({ organizationId, newPlanPriceId, promotionCode }: Props) => {
  const publicationId = useCurrentPublicationId();

  return useQuery<ApiResponse>(
    ['organization', organizationId, 'plan_price_change', newPlanPriceId, promotionCode],
    () =>
      api
        .get(`/organizations/${organizationId}/plan_price_change`, {
          params: {
            publication_id: publicationId,
            new_plan_price_id: newPlanPriceId,
            promotion_code: promotionCode,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!organizationId,
      staleTime: 60_000, // 1 minute
    }
  );
};

export default usePlanPriceChange;
