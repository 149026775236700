import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { NavLinkPayload } from '../../interfaces/nav_link';
import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

interface Props {
  navLinkId: string;
  onSuccess: (response: any) => void;
}

const useUpdateNavLink = ({ onSuccess, navLinkId }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (navLinkPayload: NavLinkPayload) => {
      const id = navLinkPayload?.overrideNavLinkId || navLinkId;
      return api.patch(`/nav_links/${id}`, {
        publication_id: currentPublication?.id,
        nav_link: { ...navLinkPayload, web_template_id: currentPublication?.web_template_id },
      });
    },
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Oops there was an issue updating this Nav Link.');
      },
    }
  );
};

export default useUpdateNavLink;
