import { useQuery } from 'react-query';

import { ElevenlabsVoice } from '@/interfaces/post';
import api from '@/services/swarm';

import { useCurrentPublication } from '../usePublications';

export function useElevenlabsVoices() {
  const { data: currentPublication } = useCurrentPublication();

  return useQuery<ElevenlabsVoice[]>(
    ['elevenlabsVoices', currentPublication?.id],
    () =>
      api
        .get<ElevenlabsVoice[]>(`/elevenlabs/voices`, {
          params: {
            publication_id: currentPublication?.id,
          },
        })
        .then((res) => res.data),
    { keepPreviousData: true }
  );
}
