import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

export const useRedirectAfterEntriSetup = (publicationId: string, customDomainIds: string[]) => {
  const navigate = useNavigate();

  useEffect(() => {
    const completeSetup = () => {
      navigate(appendSettingsPublicationId('/settings/publication/domain', publicationId));
    };

    window.addEventListener('onEntriClose', completeSetup);

    return () => {
      window.removeEventListener('onEntriClose', completeSetup);
    };
  }, [navigate, publicationId, customDomainIds]);
};
