import { FC } from 'react';

import ColorField from '../../components/Form/ColorField';

import ExpandableSlider from './components/ExpandableSlider';
import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { LineHeight, LineHeights, ThemeFormProps } from './types';

const ListForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette, fonts }) => {
  return (
    <FormContainer>
      <FormSection title="Font Settings">
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.list_font_family}
              onUpdate={(val) => onUpdate({ ...theme, list_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Line height">
          <ThemeEditorDropdown
            currentValue={theme.list_line_height_size}
            onUpdate={(val) => onUpdate({ ...theme, list_line_height_size: val as LineHeight })}
            options={[
              { label: LineHeights.Less, value: LineHeights.Less },
              { label: LineHeights.Normal, value: LineHeights.Normal },
              { label: LineHeights.More, value: LineHeights.More },
            ]}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="list_font_size"
            label="Font size"
            onChange={(val: number) => onUpdate({ ...theme, list_font_size: val.toString() })}
            initialValue={theme.list_font_size}
            min={12}
            max={32}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.list_font_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, list_font_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Bulleted Lists">
        <FormInput title="Bullet style">
          <ThemeEditorDropdown
            currentValue={theme.unordered_list_style_type}
            onUpdate={(val) => onUpdate({ ...theme, unordered_list_style_type: val })}
            options={[
              { label: 'Disc', value: 'disc' },
              { label: 'Circle', value: 'circle' },
              { label: 'Square', value: 'square' },
            ]}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Numbered Lists">
        <FormInput title="Number style">
          <ThemeEditorDropdown
            currentValue={theme.ordered_list_style_type}
            onUpdate={(val) => onUpdate({ ...theme, ordered_list_style_type: val })}
            options={[
              { label: 'Decimal', value: 'decimal' },
              { label: 'Upper-Roman', value: 'upper-roman' },
              { label: 'Lower-Roman', value: 'lower-roman' },
            ]}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Spacing">
        <FormInput>
          <Slider
            name="list_item_spacing"
            label="Spacing between items"
            onChange={(val: number) => onUpdate({ ...theme, list_item_spacing: val.toString() })}
            initialValue={theme.list_item_spacing}
            min={0}
            max={40}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <ExpandableSlider
            fields={{
              list_padding_top: 'Top',
              list_padding_right: 'Right',
              list_padding_bottom: 'Bottom',
              list_padding_left: 'Left',
            }}
            theme={theme}
            onUpdate={onUpdate}
            min={0}
            max={80}
            title="Space around list"
          />
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default ListForm;
