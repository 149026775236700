import { Trash } from '@phosphor-icons/react';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import { Button } from '@/routes/website/_components/UI/Button';

import { useNavbarContext } from '../../../NavbarContext';

import { LabelSettings } from './LabelSettings';
import { TypeSettings } from './TypeSettings';

export function NavItemContentSettings() {
  const { selectedContent, onDeleteNode } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;
  const itemType = (selectedContent?.attrs && 'type' in selectedContent.attrs && selectedContent.attrs.type) || '';

  return (
    <SectionRenderer
      title="Navigation Item"
      actions={
        <Button
          size="sm"
          variant="secondary"
          iconClassName="text-wb-secondary"
          Icon={Trash}
          isDisabled={itemType === 'user_button'}
          onClick={() => onDeleteNode(selectedContent.attrs?.id || '')}
        />
      }
    >
      <LabelSettings />
      <TypeSettings />
    </SectionRenderer>
  );
}
