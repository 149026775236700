import cx from "classnames";

interface Props {
  className?: string;
}

const LinkedinIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={cx(className)}
    >
      <path
        d="M17.5 6.66667V13.3333C17.5 15.6345 15.6345 17.5 13.3333 17.5H6.66667C4.36548 17.5 2.5 15.6345 2.5 13.3333V6.66667C2.5 4.36548 4.36548 2.5 6.66667 2.5H13.3333C15.6345 2.5 17.5 4.36548 17.5 6.66667Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 14.1673V11.2507V8.33398"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16675 14.1673V11.459M9.16675 11.459V8.33398M9.16675 11.459C9.16675 8.33398 14.1667 8.33398 14.1667 11.459V14.1673" stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 5.84129L5.84159 5.83203"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkedinIcon;
