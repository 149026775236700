import { ChangeEvent, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Panel } from '../../ui/Panel';
import { Tooltip } from '../../ui/Tooltip';

const Styled = {
  FileInput: styled.input`
    height: 0;
    overflow: hidden;
    width: 0;
  `,
};

export interface UpdateImagePanelProps {
  tooltip: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  onFileChange: (file: File) => void;
  onRemove?: () => void;
  showRemoveOption?: boolean;
  accept?: string;
}

type PanelContentProps = {
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onRemove?: () => void;
  showRemoveOption?: boolean;
  accept?: string;
};

const PanelContent = forwardRef<HTMLDivElement, PanelContentProps>(
  ({ onFileChange, onRemove, showRemoveOption = false, accept }, ref) => {
    const fileRef = useRef<HTMLInputElement>(null);

    const handleFileClick = useCallback(() => {
      if (fileRef.current) {
        fileRef.current.click();
      }
    }, []);

    return (
      <Panel ref={ref}>
        <Styled.FileInput onChange={onFileChange} type="file" accept={accept} ref={fileRef} />
        <Button
          $size="small"
          $variant="quaternary"
          $fullWidth
          $leftSlot={<Icon name="Upload" />}
          onClick={handleFileClick}
        >
          Upload a file
        </Button>
        {showRemoveOption && (
          <Button
            onClick={() => onRemove?.()}
            $size="small"
            $variant="quaternary"
            $fullWidth
            $leftSlot={<Icon name="Cancel" />}
          >
            Remove file
          </Button>
        )}
      </Panel>
    );
  }
);

export const UpdateFilePanel = ({
  tooltip,
  parentRef,
  onFileChange,
  showRemoveOption,
  onRemove,
  accept,
}: UpdateImagePanelProps) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const togglePanel = useCallback(() => {
    setPanelOpen(!panelOpen);
  }, [panelOpen]);

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPanelOpen(false);

      if (e.target.files) {
        onFileChange(e.target.files[0]);
      }
    },
    [onFileChange]
  );

  useEffect(() => {
    if (panelOpen) {
      const outsideClickHandler = (e: Event) => {
        if (
          buttonRef.current &&
          panelRef.current &&
          !buttonRef.current.contains(e.target as HTMLElement) &&
          !panelRef.current.contains(e.target as HTMLElement)
        ) {
          setPanelOpen(false);
        }
      };

      document.addEventListener('click', outsideClickHandler);

      return () => {
        document.removeEventListener('click', outsideClickHandler);
      };
    }

    return () => {};
  }, [panelOpen]);

  return (
    <Tippy
      render={() =>
        panelOpen && (
          <PanelContent
            ref={panelRef}
            onFileChange={handleFileChange}
            showRemoveOption={showRemoveOption}
            onRemove={onRemove}
            accept={accept}
          />
        )
      }
      reference={parentRef?.current}
      visible={panelOpen}
      offset={[0, 4]}
      placement="bottom-start"
      interactive
    >
      <div>
        <Tooltip title={tooltip}>
          <Button
            ref={buttonRef}
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="Refresh" />}
            onClick={togglePanel}
            $active={panelOpen}
            $muted={panelOpen}
            $isIconButton
          />
        </Tooltip>
      </div>
    </Tippy>
  );
};

export default UpdateFilePanel;
