import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

export enum StripeStandardAccountStatus {
  MISSING = 'missing',
  ACTIVE = 'active',
  ONBOARDING = 'onboarding',
  MISSING_PRODUCTS = 'missing_products',
}

interface ApiResponse {
  account_status: StripeStandardAccountStatus;
}

const useStandardAccount = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchStandardAccount = (): Promise<ApiResponse> =>
    api
      .get('/stripe_account/connect_accounts/standard_account', { params: { publication_id: currentPublicationId } })
      .then((res) => res.data);

  return useQuery<ApiResponse>([currentPublicationId, 'stripe_standard_account'], fetchStandardAccount);
};

export default useStandardAccount;
