import { useSettings } from '@/context/settings-context';

import Subscribers from './Subscribers';
import SubscribersV1 from './SubscribersV1';

interface Props {
  postId: string;
}

const Engagement = ({ postId }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.post_top_subscribers_table === true;

  if (isV2) {
    return (
      <Subscribers postId={postId} />
    )
  }

  return (
    <SubscribersV1 postId={postId} />
  );
}

export default Engagement;
