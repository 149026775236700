import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { SettingsPageLayout } from '../../Components';

const Company = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const sideTabs = [
    {
      name: 'general',
      label: 'General',
      selected: location.pathname === '/settings/company',
      onSelect: () => navigate('/settings/company'),
    },
    {
      name: 'publications',
      label: 'Publications',
      selected: location.pathname === '/settings/company/publications',
      onSelect: () => navigate('/settings/company/publications'),
    },
    {
      name: 'team',
      label: 'Team',
      selected: location.pathname === '/settings/company/team',
      onSelect: () => navigate('/settings/company/team'),
    },
  ];

  return (
    <SettingsPageLayout title="Workspace" sideTabs={sideTabs}>
      <Outlet />
    </SettingsPageLayout>
  );
};

export default Company;
