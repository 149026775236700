import { useEffect } from 'react';
import qs from 'query-string';

export default function OauthCallback() {
  useEffect(() => {
    const queryParams = qs.parse(window.location.search);
    const { token } = queryParams;

    if (typeof token === 'string') {
      localStorage.setItem('token', token);

      window.location.href = window.location.origin;
    }
  }, []);

  return <></>;
}
