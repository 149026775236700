import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  onSuccess: () => void;
}

const useReconcile = ({ onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const reconcile = () =>
    api.patch('/stripe_account/reconcile', {
      publication_id: currentPublicationId,
    });

  return useMutation(reconcile, {
    onSuccess,
    onError: (err: any) => {
      toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
    },
  });
};

export default useReconcile;
