import { useNavigate } from 'react-router-dom';
import { CameraIcon } from '@heroicons/react/20/solid';

import TextIcon from '@/components/Icons/TextIcon';
import { Typography } from '@/components/Typography';
import { useCurrentPublication, useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { WebTheme } from '@/interfaces/web_template';

import { usePageContext } from '../Context/PageContext';
import GlobalTypographyForm from '../Form/GlobalTypographyForm';
import ImageSettingHelper from '../Helpers/ImageSettingHelper';
import { SectionGroupingSlideOut } from '../Helpers/SectionGroupingSlideOut';
import SidePanelSectionGroup from '../Helpers/SidePanelSectionGroup';

import GlobalColorsSection from './GlobalColorsSection';
import GlobalFooterSection from './GlobalFooterSection';
import GlobalNavigationSection from './GlobalNavigationSection';
import PostsSection from './PostsSection';
import RadiusSection from './RadiusSection';
import SubscribeWidgetSection from './SubscribeWidgetSection';

const GeneralSettings = () => {
  const { data: currentPublication } = useCurrentPublication();
  const { handleSaved } = usePageContext();
  const navigate = useNavigate();

  const webTemplateMutation = useUpdateWebTemplate(() => handleSaved());
  const { data: webTemplate } = useWebTemplate();
  const webTheme: any = webTemplate?.web_theme_preview;
  const webThemWithDefaults: any = webTemplate?.web_theme_preview_with_defaults;

  const handleUpdateWebTheme = (key: string, value: string) => {
    webTemplateMutation.mutate({
      web_theme_preview: {
        ...(webTheme as WebTheme),
        [key]: value,
      },
    });
  };

  const fontTypes = [
    {
      title: 'Global Headings',
      description: 'Typography headings provide clear hierarchy and consistent styling for titles and sections.',
      subTitle: 'Headings',
      label: 'Font Family',
      saveKey: 'font_header',
    },
    {
      title: 'Global Body',
      description:
        'Your body styles ensure all body content has a clean, readable appearance for paragraphs or smaller text.',
      subTitle: 'Body',
      label: 'Font Family',
      saveKey: 'font_body',
    },
    {
      title: 'Global Button',
      description:
        'Button styles are used for primary and secondary actions, such as submitting a form or canceling an action.',
      subTitle: 'Button',
      label: 'Font Family',
      saveKey: 'font_button',
    },
  ];

  return (
    <SidePanelSectionGroup
      title="Global Styles"
      description="Customization provided below will cascade across your website as the default values used. You can change these individually in the Layout tab."
      isLoading={!webTemplate}
    >
      <SectionGroupingSlideOut
        title="Logo & Identity"
        sectionIcon={<CameraIcon className="w-4 h-4" />}
        highlightProps={{
          sectionId: 'logo-identity',
        }}
      >
        <ImageSettingHelper
          url={currentPublication?.logo?.url || ''}
          alt="Logo"
          label="Logo"
          description="This logo will show up in your navigation bar, favicon, footer and various places across your website."
        />
      </SectionGroupingSlideOut>

      <GlobalColorsSection />
      <SectionGroupingSlideOut
        title="Typography"
        sectionIcon={
          <div className="pr-1">
            <TextIcon className="w-3 h-3" />
          </div>
        }
      >
        <div className="flex flex-col space-y-4">
          {fontTypes.map(({ title, description, subTitle, label, saveKey }) => (
            <GlobalTypographyForm
              key={saveKey}
              title={title}
              description={description}
              subTitle={subTitle}
              label={label}
              onSave={(value: string) => handleUpdateWebTheme(saveKey, value)}
              value={webThemWithDefaults?.[`${saveKey}`]}
            />
          ))}
          <div className="flex flex-col space-y-1">
            <Typography token="font-normal/text/xs" className="text-400">
              Typography Styling
            </Typography>
            <Typography token="font-light/text/xs" className="text-900">
              Right now, you can change the font family style for headings and body text for your global website
              experience. For more font options like size, weight, and color, head over to the{' '}
              <button
                onClick={() => navigate('/website_builder?tab=layout')}
                type="button"
                className="text-[#DB2777] underline"
              >
                Layout
              </button>{' '}
              tab.{' '}
            </Typography>
          </div>
        </div>
      </SectionGroupingSlideOut>
      <RadiusSection />
      <GlobalNavigationSection />
      <SubscribeWidgetSection />
      <PostsSection />
      <GlobalFooterSection />
    </SidePanelSectionGroup>
  );
};

export default GeneralSettings;
