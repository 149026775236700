import { FC, useCallback, useState } from 'react';
import { CaretRight } from '@phosphor-icons/react';
import { AccordionElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, useReactNodeView } from '@tiptap/react';

import { cn } from '@/utils/cn';

import { getDataAttributes } from '../../utils';

import { getAccordionElement } from './utils';

export const AccordionView: FC<NodeViewProps> = (props) => {
  const { node, HTMLAttributes } = props;

  const { onDragStart } = useReactNodeView();

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <AccordionElement
      element={getAccordionElement(node)}
      attributes={{
        ...getDataAttributes(HTMLAttributes),
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
      isOpen={isOpen}
      className="relative"
    >
      <NodeViewContent data-node-view-display-contents />

      <button
        className={cn(
          'text-gray-400 absolute z-10 right-2 top-8 -translate-y-1/2 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-500 transition p-2'
        )}
        onClick={toggleOpen}
        type="button"
      >
        <CaretRight className={cn('h-4 w-4', { 'rotate-90': isOpen })} />
      </button>
    </AccordionElement>
  );
};
