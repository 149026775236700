/* eslint-disable */
import { useSortable } from '@dnd-kit/sortable';
import { CheckIcon, TrashIcon, XMarkIcon, PencilIcon, LinkIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { CSS } from '@dnd-kit/utilities';

import cx from 'classnames';
import ActionModal from '../../../../../../components/ActionModal';
import toast from 'react-hot-toast';
import { useDeleteNavGroup, useUpdateNavGroup } from '../../../../../../hooks/useNavGroups';
import { useQueryClient } from 'react-query';
import { useCurrentPublicationState } from '../../../../../../context/current-publication-context';
import NavGroupForm from './NavGroupForm';
import useOptions from '../../../../../../hooks/useOptions';
import { MultiSelect } from '../../../../../../components/Form';
import CategoriesGroup from '@/routes/website_builder/custom_pages/_components/MenuBuilder/CustomMenu/MenuGroups/CategoriesGroup';

interface Props {
  listItem: any;
  children: React.ReactChild;
  removeItemRequired: boolean;
  onRemoveItem: () => void;
}

const NavGroupItem = ({ listItem, children, removeItemRequired = false, onRemoveItem }: Props) => {
  const isCategoryGroup = listItem?.is_category_group;

  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(listItem.title);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: listItem.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const deleteNavGroup = useDeleteNavGroup({
    navGroupId: listItem?.id || '',
  });
  const handleDelete = async () => {
    await deleteNavGroup.mutateAsync('');
    queryClient.invalidateQueries(['nav_links', currentPublicationId]);
  };

  const updateForm = useUpdateNavGroup({
    navGroupId: listItem?.id || '',
    onSuccess: () => {
      toast.success('Updated!');
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      setIsEditing(false);
    },
  });

  return (
    <>
      <NavGroupForm
        bodyId={listItem.id || ''}
        title={title}
        isOpen={isEditing}
        headerText="Edit Dropdown"
        ctaText="Update"
        loadingText="Updating..."
        handleTitleChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
        isLoading={updateForm.isLoading}
        handleSubmit={() => {
          updateForm.mutate({ title });
        }}
        handleClose={() => {
          setTitle(listItem.title);
          setIsEditing(false);
        }}
      />
      <ActionModal
        isOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onProceed={handleDelete}
        resourceId={listItem.id}
        isWorking={deleteNavGroup.isLoading}
        headerText="Delete Dropdown"
        actionText="Delete"
        buttonType="danger"
      >
        <div className="space-y-2 text-sm">
          <p>
            Are you sure you want to delete this dropdown? If it is currently present on your site, it will be removed.
          </p>
          <p>
            <span className="font-bold">Not to worry:</span> None of your connected external or internal pages will be
            deleted. Internal pages will remain live on your site as Standalone Pages, while any external pages will be
            set to draft until they are reassigned to another Navbar Item.
          </p>
          <p>Would you like to continue?</p>
        </div>
      </ActionModal>

      <div
        className={cx('z-10 bg-white rounded', !removeItemRequired && !confirmDelete ? 'hover:bg-gray-100' : '')}
        ref={!removeItemRequired ? setNodeRef : null}
        style={!removeItemRequired ? style : {}}
      >
        <div className="flex">
          <div className=" w-full">
            <div className="flex items-center justify-between">
              <div className="pl-4 pr-6 py-3 w-full" {...attributes} {...listeners}>
                {children}
              </div>

              <div className="pl-4 pr-6 py-3 transition transition-none">
                <div className="flex">
                  {!isCategoryGroup && (
                    <button
                      type="button"
                      onClick={() => setIsEditing(true)}
                      className={cx(
                        'ml-2 flex-shrink-0 flex  rounded-full p-0.5',
                        removeItemRequired ? 'text-gray-500 hover:text-gray-600' : '',
                        !removeItemRequired ? 'text-gray-300 hover:text-gray-500' : ''
                      )}
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => setConfirmDelete(true)}
                    className={cx(
                      'ml-2 flex-shrink-0 flex  rounded-full p-0.5',
                      removeItemRequired ? 'text-red-500 hover:text-red-600' : '',
                      !removeItemRequired ? 'text-gray-300 hover:text-red-500' : ''
                    )}
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {listItem?.nav_links?.length > 0 ? (
          <div className="pl-12 pb-2 w-full space-y-2 flex flex-col">
            {listItem?.nav_links?.map((link: any) => {
              return link.external_link ? (
                <div key={link.id} className="flex space-x-2 items-center">
                  <LinkIcon className="h-3 w-3 text-purple-400" />
                  <span className="text-xs font-medium text-purple-400">{link.cta_text}</span>
                </div>
              ) : (
                <div key={link.id} className="flex space-x-2 items-center">
                  <DocumentIcon className="h-3 w-3 text-blue-400" />
                  <span className="text-xs font-medium text-blue-400">{link.cta_text}</span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="pl-12 pb-2">
            {isCategoryGroup ? (
              <CategoriesGroup />
            ) : (
              <p className="mt-2 text-xs text-red-500">
                *This dropdown will not appear in your navbar until at least one link or page is added from the section
                below.
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default NavGroupItem;
