/* eslint-disable jsx-a11y/label-has-associated-control */

import { FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

import { getColor } from '../../components/DesignTokens';
import NewBadge from '../../components/NewBadge';
import { Tab } from '../../interfaces/general';

interface Props {
  tabs: Tab[];
  className?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'sm';
  children?: React.ReactNode;
}

const countBadge = (current: boolean, count: number | string) => (
  <span
    className={cx(
      current ? 'bg-violet-50 text-pink-600' : 'bg-surface-200 text-gray-600',
      'py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
    )}
  >
    {count}
  </span>
);

const notificationBadge = (notificationVariant: 'solid' | 'pulse' | 'ping') => {
  const animationClass = {
    solid: '',
    pulse: 'animate-pulse',
    ping: 'animate-ping opacity-75',
  }[notificationVariant];

  return (
    <span className="relative transform -translate-y-1/2 -translate-x-1/2 flex h-3 w-3">
      <span className={cx(animationClass, 'absolute inline-flex h-full w-full rounded-full bg-primary-400')} />
      {notificationVariant === 'ping' && <span className="relative inline-flex rounded-full h-3 w-3 bg-primary-500" />}
    </span>
  );
};

const TabNavigation: FC<Props> = (props: Props) => {
  const { tabs: allTabs, className = '', variant = 'primary', size, children } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const tabs = allTabs.filter((tab) => tab.visible !== false);
  const selectedTab = tabs.find((tab) => tab.selected);

  let divClassName: string;
  let navClassName: string;
  let tabClassName: string;
  let tabSelectClassName: string;
  let tabDeselectClassName: string;
  if (variant === 'secondary') {
    divClassName = 'h-full flex items-center justify-between';
    navClassName = 'flex space-x-4 bg-white p-1 rounded-md';
    tabClassName = cx('border whitespace-nowrap rounded-md py-1 px-2 font-medium text-sm');
    tabSelectClassName = cx('cursor-default', getColor('surface/50', 'background'), getColor('surface/200', 'border'));
    tabDeselectClassName = 'border-white text-gray-500 hover:text-gray-700 hover:border-gray-300';
  } else if (variant === 'tertiary') {
    divClassName = 'h-full';
    navClassName = cx(
      'flex space-x-1 bg-surface-100 rounded-lg border border-surface-gray-200',
      size === 'sm' ? 'p-0.5' : 'p-2'
    );
    tabClassName = cx(
      'w-full rounded-md text-xs font-medium ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none text-gray-700 leading-4',
      size === 'sm' ? 'py-1 px-2' : 'py-2 px-3'
    );
    tabSelectClassName = 'bg-white shadow text-gray-900 cursor-default';
    tabDeselectClassName = 'hover:bg-surface-200 hover:text-gray-600';
  } else {
    divClassName = 'h-full';
    navClassName = 'h-full -mb-px flex space-x-8 border-b';
    tabClassName = 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm';
    tabSelectClassName = 'border-pink-400 text-pink-400 cursor-default';
    tabDeselectClassName = 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300';
  }

  const onSelectOption: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const foundTab = tabs.find((tab) => tab.name === e.currentTarget.value);

    if (foundTab) {
      if (foundTab.targetRoute) {
        navigate(foundTab.targetRoute);
      } else if (foundTab.onSelect) {
        foundTab.onSelect();
      }
    }
  };

  return (
    <div className={className}>
      <div className="h-full sm:hidden flex flex-col items-center">
        <div className="w-full">
          <label className="sr-only">Select a tab</label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-50 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
            value={selectedTab?.name}
            onChange={onSelectOption}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.name} disabled={tab.disabled}>
                {tab.label}{' '}
                {((tab.count && typeof tab.count === 'number' && tab.count > 0) || typeof tab.count === 'string') &&
                  `(${tab.count})`}{' '}
                {tab.disabled && `(${tab.disableWith || 'Not available'})`}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">{children}</div>
      </div>
      <div className="h-full hidden sm:block">
        <div className={divClassName}>
          <nav className={cx(navClassName, getColor('surface/200', 'border'))} aria-label="Tabs">
            {tabs.map((tab) => {
              const { notificationVariant = '' } = tab;
              const tabClass = cx(tabClassName, tab.selected ? tabSelectClassName : tabDeselectClassName);

              const hasCount = Boolean(
                (tab.count && typeof tab.count === 'number' && tab.count > 0) || typeof tab.count === 'string'
              );

              const isNew = tab.newUntil ? new Date() < new Date(tab.newUntil) : false;

              const tabContent = (
                <div className="flex space-x-2 items-center">
                  <span>{tab.label}</span>
                  {tab.Icon && <tab.Icon className="h-4 w-4" />}
                  {notificationVariant && notificationBadge(notificationVariant)}
                  {hasCount && <span>{countBadge(tab.selected, tab.count || 0)}</span>}
                  {isNew && <NewBadge />}
                  {tab.upgradeIntent}
                </div>
              );

              if (tab.url) {
                return (
                  <Link
                    className={tabClass}
                    to={tab.url}
                    target={tab.target || '_blank'}
                    rel={tab.rel || 'noreferrer'}
                    key={`tab-nav-link-${tab.url}`}
                  >
                    {tabContent}
                  </Link>
                );
              }

              if (tab.targetRoute) {
                return (
                  <div className="flex" key={`tab-nav-link-${tab.targetRoute}`}>
                    <div className="flex" data-tip data-for={`tooltip-${tab.label}`}>
                      <Link
                        to={tab.disabled ? pathname : tab.targetRoute}
                        aria-current={tab.selected}
                        aria-disabled={tab.disabled}
                        className={cx(
                          tabClass,
                          tab.disabled && '!text-gray-400 cursor-not-allowed pointer-events-none !hover:bg-surface-100'
                        )}
                      >
                        {tabContent}
                      </Link>
                    </div>
                    {tab.disabled && (
                      <ReactTooltip place="top" effect="solid" id={`tooltip-${tab.label}`}>
                        {tab.disableWith || 'Unavailable'}
                      </ReactTooltip>
                    )}
                  </div>
                );
              }

              return (
                <div data-tip data-for={`tooltip-${tab.label}`} key={`tab-nav-button-${tab.name}`}>
                  <button
                    type="button"
                    aria-current={tab.selected}
                    onClick={() => {
                      if (tab.onSelect) {
                        tab.onSelect();
                      }
                    }}
                    className={cx(
                      tabClass,
                      tab.disabled && '!text-gray-400 cursor-not-allowed pointer-events-none !hover:bg-surface-100'
                    )}
                    disabled={tab.disabled}
                  >
                    {tabContent}
                  </button>
                  {tab.disabled && (
                    <ReactTooltip place="top" effect="solid" id={`tooltip-${tab.label}`}>
                      {tab.disableWith || 'Unavailable'}
                    </ReactTooltip>
                  )}
                </div>
              );
            })}
          </nav>
          {children}
        </div>
      </div>
    </div>
  );
};

export default TabNavigation;
