import { Outlet, useLocation, useOutletContext, useParams } from 'react-router-dom';

import { Breadcrumbs } from '../../components/Breadcrumbs';
import LoadingBox from '../../components/LoadingBox';
import { useSegment } from '../../hooks';
import { Segment } from '../../interfaces/segment';
import TabNavigation from '../../ui/TabNavigation';

import { getTabs } from './constants';
import SegmentHeader from './SegmentHeader';

interface RouteParams {
  segmentId: string;
}

const SegmentView = () => {
  const { segmentId } = useParams() as unknown as RouteParams;
  const { data: segment, isLoading, isError } = useSegment({ segmentId });
  const { pathname } = useLocation();

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/segments" backLanguage="Back to all segments">
            Segments
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to=".">{segment?.name}</Breadcrumbs.Item>
        </Breadcrumbs>
        {segment && <SegmentHeader segment={segment} />}
        {segment && <TabNavigation tabs={getTabs(segment?.id, pathname)} className="pt-4 md:mb-4" variant="tertiary" />}
        <Outlet context={{ segment }} />
      </>
    </LoadingBox>
  );
};

type ContextType = { segment: Segment };

export const useSegmentViewContext = () => useOutletContext<ContextType>();

export default SegmentView;
