import { useQuery } from 'react-query';

import { AdNetworkPaymentRequest } from '@/interfaces/ad_network/internal/payment_request';
import api from '@/services/swarm';

export default function useCampaignPaymentRequest({ campaignId, id }: { campaignId: string; id: string }) {
  return useQuery<AdNetworkPaymentRequest>(
    ['ad_network', 'internal', 'campaigns', campaignId, 'payment_requests', id],
    () => api.get(`/ad_network/internal/campaigns/${campaignId}/payment_requests/${id}`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
