import { SubscriptionChangePublication } from '@/interfaces/mailer_actions/subscription_change';

interface Props {
  publication: SubscriptionChangePublication;
}

const PublicationDetails = ({ publication }: Props) => {
  const { logo_url: logoUrl, name, description } = publication;

  return (
    <>
      <div className="rounded-lg shadow-lg overflow-hidden h-24 w-24 md:mx-auto ">
        <figure className="aspect-square relative h-full overflow-hidden w-full">
          <img
            width="128"
            height="128"
            src={logoUrl}
            alt={name}
            className="absolute inset-0 h-full w-full object-cover rounded-lg"
          />
        </figure>
      </div>
      <div className="space-y-2 md:mx-auto text-left md:text-center">
        <h1 className="text-2xl font-bold wt-header-font">{name}</h1>
        {description && <p className="text-gray-700">{description}</p>}
      </div>
    </>
  );
};

export default PublicationDetails;
