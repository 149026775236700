import { useState } from 'react';

interface ControlledInput {
  initialState: any;
}

const useControlledInput = ({ initialState }: ControlledInput) => {
  const [state, setState] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const resetState = (): void => {
    setState(initialState);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  return {
    state,
    setState,
    resetState,
    handleChange,
    errorMessage,
    setErrorMessage,
  };
};

export default useControlledInput;
