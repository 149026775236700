import { CaretRight } from "@phosphor-icons/react"
import { NavbarDropdownStyleType } from "@shared/dream-components";

import { Popover, PopoverContent, PopoverTrigger } from "@/routes/website/_components/UI/Popover"
import { Text } from "@/routes/website/_components/UI/Text"

import { useNavbarContext } from "../../../NavbarContext";
import { formatCamelCase } from "../../../utils";
import { OptionsWithPreview } from "../../ui/OptionsWithPreview";

import { DropdownCardPreview } from "./DropdownCardPreview";
import { DropdownFullPreview } from "./DropdownFullPreview";


export const StyleSettings = () => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_dropdown") return null;

  const contentValue = selectedContent.attrs?.style || 'full';

  const handleStyleChange = (style: "full" | "card") => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { style });
  }

  return <Popover>
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Style
      </Text>

      <PopoverTrigger asChild>
        <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer w-[120px]">
          <div className="w-full justify-between flex items-center gap-2 p-2 ">
            <div className="flex items-center gap-1">
              <Text size="2xs" weight="medium">
                {formatCamelCase(contentValue)}
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>
      </PopoverTrigger>
    </div>
    <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
      <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-6">
        <Text size="sm" weight="semibold">
          Style
        </Text>
        <div className="flex flex-col gap-2">
          {
            (['card', 'full'] as NavbarDropdownStyleType[]).map(style => (
              <OptionsWithPreview key={style} label={formatCamelCase(style)} selected={style === contentValue} onClick={() => handleStyleChange(style)}>
                {/* preview */}
                {style === 'full' ? <DropdownFullPreview /> : <DropdownCardPreview />}
              </OptionsWithPreview>
            ))
          }
        </div>
      </div>
    </PopoverContent>
  </Popover >
}
