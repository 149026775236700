import { Navigate, useMatch, useParams } from 'react-router-dom';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import useBoostOffer from '@/hooks/boosts/grow/useBoostOffer';

import BoostOfferForm from '../../Shared/BoostOfferForm';
import BoostOfferFormHeader from '../../Shared/BoostOfferFormHeader';

const BoostOfferEdit = () => {
  const { boostOfferId } = useParams() as unknown as { boostOfferId: string };
  const { data: boostOffer, isLoading } = useBoostOffer(boostOfferId);

  const shouldRedirect = useMatch(`/boost_offers/${boostOfferId}/edit`);

  if (shouldRedirect) {
    return <Navigate to={`/grow/boosts/offers/${boostOfferId}/edit`} replace />;
  }

  return (
    <>
      <BoostOfferFormHeader boostOffer={boostOffer} />
      {isLoading ? <LoadingSpinner /> : <BoostOfferForm boostOffer={boostOffer} />}
    </>
  );
};

export default BoostOfferEdit;
