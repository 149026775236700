import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const Styled = {
  Overlay: styled.div`
    align-items: center;
    background-color: ${colors.white[3]};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    user-select: none;
    width: 100%;
    z-index: 9999;
  `,
  Loader: styled.div`
    align-items: center;
    background-color: ${colors.black[1]};
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    padding: 1rem;
  `,
  Label: styled.span`
    color: ${colors.white[3]};
    font-size: 0.875rem;
    line-height: 1.15;
  `,
  Dots: styled.div`
    align-items: center;
    display: flex;
    gap: 0.3rem;
    justify-content: center;

    & :nth-child(1) {
      animation-delay: 0s;
      background-color: ${colors.gradient[33]};
    }

    & :nth-child(2) {
      animation-delay: 0.4s;
      background-color: ${colors.gradient[19]};
    }

    & :nth-child(3) {
      animation-delay: 0.6s;
      background-color: ${colors.gradient[1]};
    }
  `,

  Dot: styled.div`
    --scale-default: 0.5;
    --dot-size: 0.8rem;

    @keyframes scaling {
      0%,
      100% {
        transform: scale(var(--scale-default));
      }

      40% {
        transform: scale(calc(var(--scale-default) * 2));
      }

      60% {
        transform: scale(calc(var(--scale-default) * 2));
      }
    }

    animation: scaling 2s ease-in-out infinite;
    border-radius: 50%;
    display: inline-block;
    height: var(--dot-size);
    transform: scale(var(--scale-default));
    width: var(--dot-size);
  `,
};

export default Styled;
