import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useUpdateWebTheme = (webThemeId: string | null | undefined) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (webTheme: any) =>
      api.patch(`/web_theme/${webThemeId}`, {
        publication_id: currentPublicationId,
        ...webTheme,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['publications', currentPublicationId]);
      },
    }
  );
};

export default useUpdateWebTheme;
