import { PropsWithChildren } from 'react';
import { Grid } from '@tremor/react';

type Props = PropsWithChildren<{}>;

const Main = ({ children }: Props) => {
  return <Grid className="gap-6 grid-cols-1 lg:grid-cols-3 w-full">{children}</Grid>;
};

const LeftPane = ({ children }: Props) => {
  return <div className="flex flex-col space-y-4 w-full col-span-2">{children}</div>;
};

const RightPane = ({ children }: Props) => {
  return <div className="flex flex-col space-y-4 col-span-1 h-full">{children}</div>;
};

export { LeftPane, Main, RightPane };
