import { FC } from 'react';
import { AccordionContentElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, useReactNodeView } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

import { getAccordionContentElement } from './utils';

export const AccordionContentView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <AccordionContentElement
      element={getAccordionContentElement(node)}
      attributes={{
        ...getDataAttributes(HTMLAttributes),
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      <NodeViewContent data-node-view-display-contents />
    </AccordionContentElement>
  );
};
