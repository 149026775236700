import toast from 'react-hot-toast';

import ActionsDropdown, { ActionOption } from '@/components/ActionsDropdown/ActionsDropdown';
import Tooltip from '@/components/Tooltip';
import useUpdateBoostAgreement from '@/hooks/boosts/monetize/useUpdateBoostAgreement';
import useBoostAgreementCanReactivate from '@/hooks/boosts/useBoostAgreementCanReactivate';
import {
  BoostAgreement,
  BoostAgreementStatus,
  BoostAgreementStatusTransitions,
  BoostAgreementTransitionLabels,
} from '@/interfaces/boosts/monetize/boost_agreement';

interface Props {
  boostAgreement: BoostAgreement;
  onSuccess: () => void;
}
const AgreementActions = ({ boostAgreement, onSuccess }: Props) => {
  const canReactivateQuery = useBoostAgreementCanReactivate(boostAgreement?.id);
  const canReactivate = canReactivateQuery.data?.can_reactivate ?? false;
  const handleSuccess = () => {
    onSuccess();
    toast.success('Boost agreement updated.');
  };
  const { mutateAsync: updateBoostAgreement } = useUpdateBoostAgreement({ onSuccess: handleSuccess });
  const availableTransitions = BoostAgreementStatusTransitions[boostAgreement?.status || ''] || [];
  const options: ActionOption[] = availableTransitions.map((transition: BoostAgreementStatus) => ({
    name: BoostAgreementTransitionLabels[transition],
    isVisible: () =>
      transition === BoostAgreementStatus.ACTIVE && boostAgreement?.status === BoostAgreementStatus.INACTIVE
        ? canReactivate
        : true,
    onClick: (boost: any) => updateBoostAgreement({ boostAgreementId: boost.id, transition_to: transition }),
  }));

  if (!boostAgreement) return null;

  if (options.length === 0) {
    return null;
  }

  const areActionsDisabled = boostAgreement.is_reported === true;

  return (
    <Tooltip
      tooltipClass="text-center"
      id={`disabled-boost-actions-tooltip-${boostAgreement.id}`}
      text="You have been reported by the Publication"
      showIcon={false}
      autoWidth={false}
      isEnabled={areActionsDisabled}
    >
      <ActionsDropdown
        disabled={areActionsDisabled}
        actions={[areActionsDisabled ? [] : options]}
        node={boostAgreement}
      />
    </Tooltip>
  );
};

export default AgreementActions;
