import { useMemo, useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import Toolbar from '@/components/TiptapEditor/components/ui/Toolbar';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: false,
  $muted: true,
  $fullWidth: true,
  actions: [],
};

const Dropdown = ({
  value,
  onSelect,
  actions,
}: {
  value?: string;
  onSelect?: (name: string, value: any) => any;
  actions: { label: string; value: string; disabled?: boolean }[];
}): JSX.Element => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const activeAction = useMemo((): any => {
    return actions.find((action) => {
      return action.label === value;
    });
  }, [value]);

  return (
    <Tippy
      render={(attrs) => (
        <Toolbar
          tabIndex={-1}
          $isVertical
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attrs}
        >
          {actions.map((action) => (
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonProps}
              key={action.label}
              $active={activeAction?.label === action.label}
              disabled={action.disabled}
              onClick={() => {
                if (onSelect) {
                  onSelect(action.label, action.value);
                }

                setIsPanelOpen(false);
              }}
              $fullWidth
            >
              {action.label}
            </Button>
          ))}
        </Toolbar>
      )}
      offset={[0, 8]}
      placement="bottom-start"
      interactive
      visible={isPanelOpen}
      onShow={() => {
        setIsPanelOpen(true);
      }}
      onHidden={() => {
        setIsPanelOpen(false);
      }}
    >
      <div>
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          $rightSlot={<Icon name="ChevronDown" />}
          $active
          onClick={() => {
            setIsPanelOpen(!isPanelOpen);
          }}
          style={{ height: '100%' }}
        >
          {value}
        </Button>
      </div>
    </Tippy>
  );
};

export default Dropdown;
