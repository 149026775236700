import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import SlideOver from '@/components/SlideOver';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCreateNavLink } from '@/hooks/useNavlinks';
import { NavGroup } from '@/interfaces/nav_group';
import { isValidUrl } from '@/utils';

import ExternalLinkForm from './ExternalLinkForm';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  navGroups: NavGroup[];
}

const NewLinkPanel = ({ navGroups, isOpen, handleClose }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const createNavLink = useCreateNavLink({
    onSuccess: () => {
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      queryClient.invalidateQueries(['nav_links', currentPublicationId]);
      handleClose();
    },
  });

  const handleOnSubmit = (navLink: any) => {
    if (!isValidUrl(navLink.href)) {
      toast.error('Please include a valid URL');
      return;
    }

    createNavLink.mutate(navLink);
  };

  return (
    <SlideOver bodyId="new-external-page" isOpen={isOpen} onClose={handleClose} headerText="New External Page">
      <ExternalLinkForm
        navLink={null}
        navGroups={navGroups}
        onSubmit={handleOnSubmit}
        onClose={handleClose}
        isLoading={createNavLink.isLoading}
      />
    </SlideOver>
  );
};

export default NewLinkPanel;
