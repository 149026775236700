import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

import useGenerateOrchidAdminToken from '@/hooks/useUsers/useGenerateOrchidAdminToken';

interface Props {
  postUrl: string;
}

const useRedirectUserToOrchid = ({ postUrl }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isRedirectToOrchid = searchParams.get('redirect_to_orchid') === 'true';
  const [hasSetToast, setHasSetToast] = useState(false);

  const { handleRedirectToOrchid } = useGenerateOrchidAdminToken();

  const handleLogUserIntoOrchid = useCallback(() => {
    try {
      const url = new URL(postUrl);
      const pathname = url?.pathname;
      const redirectUrl = `${pathname}`;
      handleRedirectToOrchid(redirectUrl, { comments: 'true' }, true);
      searchParams.delete('redirect_to_orchid');
      setSearchParams(searchParams);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [handleRedirectToOrchid, postUrl, searchParams, setSearchParams]);

  useEffect(() => {
    if (isRedirectToOrchid && postUrl) {
      handleLogUserIntoOrchid();

      if (!hasSetToast) {
        setHasSetToast(true);
        toast.success('Authenticating and redirecting to site as admin...');
      }
    }
  }, [isRedirectToOrchid, postUrl, handleLogUserIntoOrchid, hasSetToast]);

  return {
    handleManuallyLogInUserToOrchid: handleLogUserIntoOrchid,
  };
};

export default useRedirectUserToOrchid;
