import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Tab } from '../../../interfaces/general';
import TabNavigation from '../../../ui/TabNavigation';

interface Props {
  formId: string;
  isNewLayout?: boolean;
}

const constructTabs: (currentPath: string, formId: string, routes: any) => Tab[] = (
  currentPath: string,
  formId: string,
  routes: any
): Tab[] => [
  {
    name: 'individual',
    label: 'Individual',
    targetRoute: routes.individual,
    selected: currentPath === routes.individual,
  },
  {
    name: 'summary',
    label: 'Summary',
    targetRoute: routes.summary,
    selected: currentPath === routes.summary,
  },
];

const NavContainer: FC<Props> = (props: Props) => {
  const { pathname } = useLocation();

  const { formId, isNewLayout } = props;

  const routes = {
    individual: isNewLayout ? `/forms/${formId}/edit/responses` : `/forms/${formId}`,
    summary: isNewLayout ? `/forms/${formId}/edit/summary` : `/forms/${formId}/summary`,
  };

  const constructedTabs = constructTabs(pathname, formId, routes);

  return (
    <div className="p-4">
      <div className="font-bold">Responses</div>
      <main>
        <div>
          <TabNavigation tabs={constructedTabs} className="sm:border-b mb-4 px-4 lg:px-0" variant="tertiary" />
        </div>
      </main>
    </div>
  );
};

export default NavContainer;
