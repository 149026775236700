import { useSearchParams } from 'react-router-dom';

export interface BuilderHighlighterProps {
  sectionId?: string;
  nodeId?: string;
}

interface ReturnProps {
  isSectionHighlighted: boolean;
  isNodeHighlighted: boolean;
  highlightedSectionId: string | null;
  highlightedNodeId: string | null;
  handleStartHighlight: (overrideIds?: BuilderHighlighterProps) => void;
  handleEndHighlight: () => void;
}

const useBuilderHighlighter = ({ sectionId, nodeId }: BuilderHighlighterProps): ReturnProps => {
  const [searchParams, setSearchParams] = useSearchParams();
  const highlightedSectionId = searchParams.get('sectionId');
  const highlightedNodeId = searchParams.get('nodeId');

  const handleStartHighlight = (overrideIds?: BuilderHighlighterProps) => {
    if (overrideIds) {
      if (overrideIds.sectionId) searchParams.set('sectionId', overrideIds.sectionId);
      if (overrideIds.nodeId) searchParams.set('nodeId', overrideIds.nodeId);
    } else {
      if (sectionId) searchParams.append('sectionId', sectionId);
      if (nodeId) searchParams.append('nodeId', nodeId);
    }

    const updateParams = sectionId || nodeId || overrideIds;

    if (updateParams) {
      setSearchParams(searchParams);
    }
  };

  const handleEndHighlight = () => {
    searchParams.delete('sectionId');
    searchParams.delete('nodeId');
    setSearchParams(searchParams);
  };

  return {
    highlightedSectionId,
    highlightedNodeId,
    isSectionHighlighted: highlightedSectionId === sectionId,
    isNodeHighlighted: highlightedNodeId === nodeId,
    handleStartHighlight,
    handleEndHighlight,
  };
};

export default useBuilderHighlighter;
