import { QueryParamConfig } from 'use-query-params';

export enum QuestionTypes {
  FREE_FORM = 'free_form',
  MULTIPLE_CHOICE = 'multiple_choice',
  DROPDOWN = 'dropdown',
  LONG_TEXT = 'long_text',
}
export type FormQuestionTypes =
  | QuestionTypes.FREE_FORM
  | QuestionTypes.MULTIPLE_CHOICE
  | QuestionTypes.DROPDOWN
  | QuestionTypes.LONG_TEXT;

export enum FormStatus {
  DRAFT = 'draft',
  LIVE = 'live',
  ARCHIVED = 'archived',
}
export type FormStatusTypes = FormStatus.DRAFT | FormStatus.LIVE | FormStatus.ARCHIVED;

export interface FormQuestionOption {
  id: string;
  value: string;
}

export interface CustomField {
  id: string;
  kind: string;
}

export interface FormQuestion {
  id: string;
  order: number;
  prompt: string;
  question_type: FormQuestionTypes;
  required: boolean;
  show_max_characters: boolean;
  max_character_limit: number;
  min_character_limit: number;
  custom_field: CustomField;
  form_question_options: FormQuestionOption[];
  multi_select: boolean;
}

export interface Form {
  id: string;
  title: string;
  description: string;
  cta_text: string;
  thank_you_message: string;
  url: string;
  form_response_count: number;
  most_recent_response: string;
  status: FormStatusTypes;
  form_questions: FormQuestion[];
  created_at: string;
  aggregate_details: AggregateDetail[];
}

export type FormQueryParams = {
  search: QueryParamConfig<string>;
  order: QueryParamConfig<string>;
  dir: QueryParamConfig<string>;
  filterByDateCreated: QueryParamConfig<string>;
};

export interface QuestionResponse {
  id: string;
  answer: string;
  form_question_id: string;
  question_data: {
    type: string;
    prompt: string;
    answer: string;
  };
}

export interface AggregateDetail {
  id: string;
  response_count: number;
  multi_select_count: number;
  answers: AggregateAnswer[];
}

export interface AggregateAnswer {
  id?: string;
  value: string;
  count: number;
}
