import { Tooltip } from '../Tooltip';

import { Styled } from './styled';

export const ConsumptionProgress = ({
  used,
  available,
  label,
  $showTooltip = false,
  $isInline = false,
  tooltipTitle,
  shortcut,
}: {
  used: number;
  available: number;
  label?: string;
  $showTooltip?: boolean;
  $isInline?: boolean;
  tooltipTitle?: string;
  shortcut?: string[];
}) => {
  return (
    <Tooltip enabled={$showTooltip} title={tooltipTitle} shortcut={shortcut}>
      <Styled.Wrapper $isInline={$isInline}>
        <Styled.Header label={label} $isInline={$isInline}>
          <Styled.ProgressNumeric>
            {used} / {available}
          </Styled.ProgressNumeric>
          {label && <Styled.Label>{label}</Styled.Label>}
        </Styled.Header>
        <Styled.ProgressBar used={used} available={available} />
      </Styled.Wrapper>
    </Tooltip>
  );
};

export default ConsumptionProgress;
