import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Response {
  can_reactivate: boolean;
}

const useBoostAgreementCanReactivate = (boostAgreementId: string) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchBoostAgreementCanReactivate = (): Promise<Response> =>
    api
      .get(`/boosts/boost_agreements/can_reactivate/${boostAgreementId}`, {
        params: { publication_id: currentPublicationId },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, 'boosts', 'boost_agreement', boostAgreementId, 'can_reactivate'],
    fetchBoostAgreementCanReactivate
  );
};

export default useBoostAgreementCanReactivate;
