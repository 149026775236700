import styled from 'styled-components';

export const Styled = {
  TextLengthWrapper: styled.div`
    display: flex;
    gap: 0.25rem;
  `,
  TextLengthInputWrapper: styled.div<{ half?: boolean }>`
    flex: 1 1 auto;
  `,
};

export default Styled;
