import { CaretRight } from "@phosphor-icons/react"

import { Popover, PopoverContent, PopoverTrigger } from "@/routes/website/_components/UI/Popover"
import { Text } from "@/routes/website/_components/UI/Text"
import { cn } from "@/routes/website/_utils/cn";

import { useNavbarContext } from "../../../NavbarContext";
import { formatCamelCase, getParent } from "../../../utils";
import { OptionsWithPreview } from "../../ui/OptionsWithPreview";

export const StyleOptions = () => {
  const { content, selectedContent: rawSelectedContent, onUpdateNodeAttributes } = useNavbarContext();

  const selectedContent = rawSelectedContent?.type === 'navbar_menu_list' && content ? getParent(content, rawSelectedContent) : rawSelectedContent;

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_menu" && selectedContent.type !== "navbar_menu_list") return null;

  const contentValue = selectedContent?.attrs && 'style' in selectedContent.attrs && selectedContent.attrs.style || 'default';


  const handleStyleChange = (style: 'default' | 'border' | 'shadow') => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { style });
  }

  return <Popover>
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Style
      </Text>

      <PopoverTrigger asChild>
        <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer w-[120px]">
          <div className="w-full justify-between flex items-center gap-2 p-2 ">
            <div className="flex items-center gap-1">
              <Text size="2xs" weight="medium">
                {formatCamelCase(contentValue)}
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>
      </PopoverTrigger>
    </div>
    <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
      <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-6">
        <Text size="sm" weight="semibold">
          Style
        </Text>
        <div className="flex flex-col gap-2">

          {
            (['default', 'border', 'shadow'] as ('default' | 'border' | 'shadow')[]).map(style => (
              <OptionsWithPreview key={style} label={formatCamelCase(style)} selected={style === contentValue} onClick={() => handleStyleChange(style)}>
                {/* preview */}
                <div className="flex items-start justify-between bg-wb-primary rounded-md w-full gap-1 shadow-wb-md h-[40px] overflow-hidden">
                  <div className={cn("flex items-start justify-between bg-wb-primary p-2 w-full gap-1 h-fit",
                    style === 'border' ? 'border-b border-solid border-wb-border' : '',
                    style === 'shadow' ? 'shadow-wb-md' : '',
                  )}>
                    <div className="h-2 w-3 rounded-full bg-wb-accent" />
                    <div className="flex items-center gap-1">
                      <div className="h-2 w-3 rounded-full bg-wb-surface" />
                      <div className="h-2 w-3 rounded-full bg-wb-surface" />
                    </div>
                  </div>
                </div>
              </OptionsWithPreview>
            ))
          }
        </div>
      </div>
    </PopoverContent>
  </Popover >
}
