import styled from 'styled-components';

import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { colors } from '@/components/TiptapEditor/lib/colors';

const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 0.5rem;
`;

const Styled = {
  Container: styled.div`
    cursor: default;

    span {
      align-items: center;
      display: inline-flex;
      justify-content: flex-end;
      margin-right: 0.75rem;
      min-width: 1rem;
    }

    a {
      color: ${colors.blue[5]};
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  `,
  InputWrapper: styled.div`
    label {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      * {
        width: 100%;
      }
    }
  `,
  Title: styled.h2`
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
  `,
  ActionLine: styled.div<{ $locked?: boolean }>`
    align-items: center;
    background: ${({ $locked }) => ($locked ? colors.black[9] : undefined)};
    border-radius: 0.25rem;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0.125rem;
    padding-top: 0.125rem;

    &:hover {
      background: ${colors.black[9]};
    }

    ${ButtonGroup} {
      opacity: ${({ $locked }) => ($locked ? 1 : 0)};
    }

    &:hover ${ButtonGroup} {
      opacity: 1;
    }
  `,
  ItemIndex: styled.span``,
  UnorderedIndicator: styled(Icon)`
    height: 1rem;
    margin-right: -0.35rem;
    width: 1rem;
  `,
};

export default {
  ButtonGroup,
  ...Styled,
};
