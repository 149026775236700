import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import useCreateSegment from '@/hooks/useCreateSegment';
import { LogicalOperators } from '@/interfaces/condition';
import { PublicationSubscriberPreference, SubscriberPreferenceType } from '@/interfaces/subscribers_preferences';
import { NameTypes } from '@/pages/Segments/ConditionsEditor/types';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  subscriberPreference: PublicationSubscriberPreference;
  isOpen: boolean;
  onClose: () => void;
}

const CreateSegmentModal = ({ isOpen, onClose, subscriberPreference }: Props) => {
  const [segmentFilterValue, setSegmentFilterValue] = useState<string | null>(null);
  const navigate = useNavigate();

  const { mutate: createSegment, isLoading } = useCreateSegment({
    onSuccess: (segment) => {
      onClose();
      navigate(`/segments/${segment.id}/edit`);
    },
  });

  const handleClickCreateSegment = () => {
    if (!segmentFilterValue) {
      return;
    }

    createSegment({
      name: `Subscriber Preference Segment (${subscriberPreference.name})`,
      description: `This segment has been created using subscriber preference ${subscriberPreference.name} with custom field containing value ${segmentFilterValue}.`,
      conditions: {
        logical_operator: LogicalOperators.AND,
        conditions: [
          {
            uuid: '',
            filters: {
              operator:
                subscriberPreference.preference_type === SubscriberPreferenceType.MULTIPLE_CHOICE ? 'contain' : 'equal',
              value: segmentFilterValue,
              resource_id: subscriberPreference.custom_field_id,
            },
            name: 'custom_field',
            type: NameTypes.ATTRIBUTE,
          },
        ],
      },
      segment_type: 'dynamic',
    });
  };

  return (
    <ActionModal
      modalMessageType="info"
      buttonType="info"
      resourceId="create-segment-via-subscriber-preference"
      isOpen={isOpen}
      onClose={onClose}
      disabled={!segmentFilterValue}
      headerText="Create a new segment for a preference option"
      descriptionText="In order to create a segment with a custom field value condition, you need to choose which option you created within the preference."
      onProceed={handleClickCreateSegment}
      isWorking={isLoading}
    >
      <div className="my-8">
        {[SubscriberPreferenceType.MULTIPLE_CHOICE, SubscriberPreferenceType.DROPDOWN].includes(
          subscriberPreference.preference_type
        ) ? (
          <Dropdown
            labelText={`${subscriberPreference.name} Options`}
            helperText="Choose one option to link to a new segment"
            required
            name="create-segment-via-subscriber-preference-option-select"
            onSelect={(_, value: string) => setSegmentFilterValue(value)}
            options={subscriberPreference.subscriber_preference_options.map((option) => ({
              value: option.value,
              label: option.value,
            }))}
            value={segmentFilterValue || undefined}
          />
        ) : null}
        {subscriberPreference.preference_type === SubscriberPreferenceType.BOOLEAN ? (
          <Dropdown
            labelText={`${subscriberPreference.name} Options`}
            helperText="Choose one option to link to a new segment"
            required
            name="create-segment-via-subscriber-preference-option-select"
            onSelect={(_, value: string) => setSegmentFilterValue(value)}
            options={[
              { value: 'True', label: 'True' },
              { value: 'False', label: 'False' },
            ]}
            value={segmentFilterValue || undefined}
          />
        ) : null}
        {subscriberPreference.preference_type === SubscriberPreferenceType.TEXT ? (
          <Input
            labelText={`${subscriberPreference.name} Value`}
            helperText="Specify a value to link to a new segment"
            required
            name="create-segment-via-subscriber-preference-text-input"
            onChange={(e) => setSegmentFilterValue(e.currentTarget.value)}
          />
        ) : null}
      </div>
    </ActionModal>
  );
};

export default CreateSegmentModal;
