export enum StripeMigrationStatus {
  PENDING = 'pending',
  PROCESSING_PREVIEW = 'processing_preview',
  PREVIEW_PROCESSED = 'preview_processed',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export interface StripeMigration {
  id: string;
  product_mapping: object;
  customers: object;
  status: StripeMigrationStatus;
  started_at: Date;
  finished_at: Date;
  num_subscriptions_created: number;
  created_at: Date;
}

export interface StripeMigrationPrice {
  id: string;
  product_id: string;
  product_name: string;
  display_label: string;
  unit_amount_cents: number;
  unit_amount_currency: string;
  interval: string;
  interval_count: number;
}

export interface StripeSubscriptionPreview {
  id: string;
  customer_id: string;
  customer_email: string;
  cancel_at: number;
  current_period_end: number;
  current_period_start: number;
  billing_cycle_anchor: number;
  source_product_label: string;
  target_product_label: string;
  coupon_id?: string;
  coupon_amount_off?: number;
  coupon_percent_off?: number;
  is_new: boolean;
  is_canceled: boolean;
  tier_id: string;
  tier_name: string;
}
