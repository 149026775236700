import { Helmet } from 'react-helmet';
import moment from 'moment-mini';

import Header from '@/components/Header';
import { SegmentBadge } from '@/components/SegmentBadge';
import { Skeleton } from '@/components/SkeletonLoader';
import Text from '@/components/Text';
import { Segment } from '@/interfaces/segment';

interface Props {
  segment: Segment;
}

const SegmentHeader = ({ segment }: Props) =>
  segment ? (
    <div>
      <Helmet>
        <title>{segment.name} Segment</title>
      </Helmet>
      <div className="flex items-center space-x-4">
        <Header>{segment.name}</Header>
        <SegmentBadge segment={segment} />
      </div>
      <Text type="body" size="sm" className="text-gray-500">
        Last processed {segment.last_processed_at ? moment(segment.last_processed_at).fromNow() : 'never'}
      </Text>
    </div>
  ) : (
    <Skeleton />
  );

export default SegmentHeader;
