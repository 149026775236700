import cx from 'classnames';

import { Button } from '@/ui/Button';

import type { ItemProps, MultiActionCardProps } from './types';

const MultiActionCard = ({ children }: MultiActionCardProps) => {
  return (
    <div className="p-4 space-y-2">
      <div className="rounded border border-gray-200 duration-200 text-sm divide-y divide-gray-200">{children}</div>
    </div>
  );
};

MultiActionCard.Item = ({ title, description, onClick, loading = false, danger }: ItemProps) => {
  const dangerClass = 'hover:bg-red-50 hover:text-red-700';

  return (
    <div className={cx('p-4 flex justify-between flex-wrap duration-200', danger && dangerClass)}>
      <div>
        <p className="font-bold">{title}</p>
        <p>{description}</p>
      </div>
      <Button
        variant="primary-inverse"
        onClick={onClick}
        loading={loading}
        className="w-full lg:w-auto block lg:flex mt-2 lg:mt-0"
      >
        {title}
      </Button>
    </div>
  );
};

export default MultiActionCard;
