export type Option = {
  label: string;
  value: string;
};

export const FONT_WEIGHT_OPTIONS: Option[] = [
  { label: 'Light', value: 'light' },
  { label: 'Normal', value: 'normal' },
  { label: 'Medium', value: 'medium' },
  { label: 'Semi-bold', value: 'semi-bold' },
  { label: 'Bold', value: 'bold' },
];

export const FONT_SIZE_OPTIONS: Option[] = [
  {
    label: 'XS',
    value: 'xs',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
  {
    label: 'XL',
    value: 'xl',
  },
  {
    label: '2XL',
    value: '2xl',
  },
  {
    label: '3XL',
    value: '3xl',
  },
  {
    label: '4XL',
    value: '4xl',
  },
  {
    label: '5XL',
    value: '5xl',
  },
  {
    label: '6XL',
    value: '6xl',
  },
];

export const ALIGNMENT_OPTIONS: Option[] = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' },
];

export const TEMPLATE_OPTIONS: Option[] = [
  { label: 'Template 1', value: 'template-1' },
  { label: 'Template 2', value: 'template-2' },
  { label: 'Template 3', value: 'template-3' },
  { label: 'Template 4', value: 'template-4' },
  { label: 'Template 5', value: 'template-5' },
  { label: 'Template 6', value: 'template-6' },
];

// BUTTON

export const BUTTON_FONT_SIZE_OPTIONS: Option[] = [
  { label: 'SM', value: 'sm' },
  { label: 'MD', value: 'md' },
  { label: 'LG', value: 'lg' },
];

export const BUTTON_RADIUS_OPTIONS: Option[] = [
  { label: 'None', value: 'none' },
  { label: 'SM', value: 'sm' },
  { label: 'MD', value: 'md' },
  { label: 'LG', value: 'lg' },
];

export const BUTTON_SHADOW_OPTIONS: Option[] = [
  { label: 'None', value: 'none' },
  { label: 'SM', value: 'sm' },
  { label: 'MD', value: 'md' },
  { label: 'LG', value: 'lg' },
];

export const LOGO_SIZE_OPTIONS: Option[] = [
  { label: 'SM', value: 'sm' },
  { label: 'MD', value: 'md' },
  { label: 'LG', value: 'lg' },
];

export const SHADOW_OPTIONS: Option[] = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
  {
    label: 'XL',
    value: 'xl',
  },
];

export const RADIUS_OPTIONS: Option[] = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
  {
    label: 'XL',
    value: 'xl',
  },
  {
    label: '2XL',
    value: '2xl',
  },
  {
    label: '3XL',
    value: '3xl',
  },
];

// Opacity

export const OPACITY_OPTIONS: Option[] = [
  { label: '0%', value: '0' },
  { label: '10%', value: '10' },
  { label: '20%', value: '20' },
  { label: '30%', value: '30' },
  { label: '40%', value: '40' },
  { label: '50%', value: '50' },
  { label: '60%', value: '60' },
  { label: '70%', value: '70' },
  { label: '80%', value: '80' },
  { label: '90%', value: '90' },
  { label: '100%', value: '100' },
];

// HOME PAGE

export const BACKGROUND_SIZE_OPTIONS = [
  {
    label: 'Auto',
    value: 'auto',
  },
  {
    label: 'Auto Width',
    value: 'auto-width',
  },
  {
    label: 'Auto Height',
    value: 'auto-height',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

export const ARCHIVE_LAYOUT_OPTIONS = [
  {
    label: 'Grid',
    value: 'grid',
  },
  {
    label: 'List',
    value: 'list',
  },
];

export const HOME_PAGE_HEADER_OPTIONS: Option[] = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'News',
    value: 'news',
  },
  {
    label: 'Headliner',
    value: 'headliner',
  },
];

export const HEADER_WIDTH_OPTIONS: Option[] = [
  {
    label: 'Full',
    value: 'full',
  },
  {
    label: 'Contained',
    value: 'contained',
  },
];

export const BORDER_WIDTH_OPTIONS = [
  {
    label: 'XS',
    value: 'xs',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
];

export const PADDING_OPTIONS = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
];

export const BORDER_SIDE_OPTIONS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Right',
    value: 'right',
  },
  {
    label: 'Top',
    value: 'top',
  },
  {
    label: 'Bottom',
    value: 'bottom',
  },
  {
    label: 'X-Axis',
    value: 'x-axis',
  },
  {
    label: 'Y-Axis',
    value: 'y-axis',
  },
];
