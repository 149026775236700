import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { CaretRight, CoinVertical, Image, Trash } from '@phosphor-icons/react';
import { StyleSheetManager } from 'styled-components';

import { MediaLibrary } from '@/components/MediaLibrary';
import { OnMediaPayload } from '@/components/MediaLibrary/MediaLibrary.types';
import { useFileUpload } from '@/components/TiptapEditor/extensions/ImageUpload/view/hooks';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Asset } from '@/interfaces/asset';

import { Button } from '../../../UI/Button';
import { ColorPicker } from '../../../UI/ColorPicker';
import { SimpleInputWrapper } from '../../../UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { useUploader } from '../../hooks/useUploader';
import { AttributeSettingProps } from '../types';

import GradientSettings from './GradientSettings';
import { SliderSettings } from './SliderSettings';
import { TokenizeColorSettings } from './TokenizeColorSettings';

const DEFAULT_COLOR = '#000000FF';

export const BackgroundSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;
  const hasTokenAttribute = activeNodeAttributes?.tokens;
  const isTokenApplied = activeNodeAttributes?.tokens?.backgroundColor;

  const { backgroundColor, backgroundVideo, backgroundImage } = useMemo(
    () => activeNodeAttributes,
    [activeNodeAttributes]
  );

  const defaultTab = useMemo(() => {
    if (backgroundImage) return 'media';
    if (backgroundColor?.includes('linear-gradient')) return 'gradient';
    if (backgroundColor) return 'color';
    return 'media';
  }, [backgroundImage, backgroundColor]);

  const [currentPublicationId] = useCurrentPublicationState();
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const [viewType, setViewType] = useState<'gradient' | 'color' | 'media'>(defaultTab);

  const onUpload = useCallback(
    (asset: Asset) => {
      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'backgroundImage', asset.url);
          return true;
        })
        .run();
    },
    [activeNodePos, editor]
  );

  const { uploadFile, loading } = useUploader({ publicationId: currentPublicationId, onUpload });
  const { handleUploadClick, ref: fileInputRef } = useFileUpload();

  const handleUpload = useCallback(
    (payload: OnMediaPayload) => {
      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'backgroundImage', payload.media.url);
          return true;
        })
        .run();
    },
    [activeNodePos, editor]
  );

  const handleDelete = useCallback(() => {
    editor
      .chain()
      .command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'backgroundImage', null);
        return true;
      })
      .run();
  }, [activeNodePos, editor]);

  const handleMediaURLChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'backgroundImage', event.target.value);
          return true;
        })
        .run();
    },
    [activeNodePos, editor]
  );

  const [color, setColor] = useState<string>(backgroundColor || DEFAULT_COLOR);

  const onSetColor = useCallback(
    (value: string | null) => {
      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'backgroundColor', value || DEFAULT_COLOR);

          return true;
        })
        .run();
    },
    [activeNodePos, editor]
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Background
          </Text>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer relative">
            {isTokenApplied && (
              <div className="p-0.5 border rounded-full absolute -left-3 -top-2 bg-wb-accent-soft text-wb-accent border-wb-accent">
                <CoinVertical className="w-2 h-2" />
              </div>
            )}
            <div className="w-full justify-between flex items-center gap-2 p-2">
              <div className="flex items-center gap-1">
                {backgroundImage ? (
                  <Image className="text-wb-secondary" weight="bold" />
                ) : (
                  <div style={{ background: backgroundColor }} className="w-4 h-4 rounded-md" />
                )}
                <Text size="2xs" weight="medium">
                  Background
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[290px] p-4 overflow-y-auto no-scrollbar" align="center" side="left" sideOffset={20}>
        <div className="max-h-[700px]  flex flex-col gap-4">
          <div className="flex items-center">
            <Text size="sm" weight="semibold">
              Background
            </Text>
          </div>

          <div className="grow flex items-center">
            <SimpleInputWrapper className="h-[38px] px-1 relative">
              <ToggleGroup
                className="py-[1px] w-full"
                type="single"
                defaultValue={viewType}
                onValueChange={(type) => setViewType(type as 'gradient' | 'color' | 'media')}
              >
                <ToggleGroupItem asChild value="media">
                  <Text variant="secondary" size="2xs" weight="medium" className="grow">
                    Media
                  </Text>
                </ToggleGroupItem>
                <ToggleGroupItem asChild value="color">
                  <Text variant="secondary" size="2xs" weight="medium" className="grow">
                    Color
                  </Text>
                </ToggleGroupItem>
                <ToggleGroupItem asChild value="gradient">
                  <Text variant="secondary" size="2xs" weight="medium" className="grow">
                    Gradient
                  </Text>
                </ToggleGroupItem>
              </ToggleGroup>
            </SimpleInputWrapper>
          </div>

          {viewType === 'color' && (
            <div className="max-h-full flex flex-col gap-4">
              <ColorPicker
                selectedColor={color}
                onSetColor={(value: string | null) => {
                  if (!value) {
                    setColor(DEFAULT_COLOR);
                    onSetColor(null);
                    return;
                  }

                  setColor(value);
                  onSetColor(value);
                }}
              />

              {hasTokenAttribute && (
                <TokenizeColorSettings
                  editor={editor}
                  activeNodeResult={activeNodeResult}
                  property="backgroundColor"
                  title="Tokens"
                />
              )}
            </div>
          )}

          {viewType === 'gradient' && (
            <div className="h-full flex flex-col gap-4">
              <GradientSettings editor={editor} activeNodeResult={activeNodeResult} property="backgroundColor" />
            </div>
          )}

          {viewType === 'media' && (
            <div className="flex flex-col gap-4">
              <div className="w-full min-w-0 flex justify-center items-center relative ">
                {/*
                  This is an escape hatch to allow styled components to work outside
                  of the editor's iframe.
                 */}
                <StyleSheetManager target={document.head}>
                  <MediaLibrary
                    isOpen={showImageLibrary}
                    onClose={() => setShowImageLibrary(false)}
                    publicationId={currentPublicationId}
                    onMediaSelect={handleUpload}
                  />
                </StyleSheetManager>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="h-0 opacity-0 overflow-hidden w-0"
                  accept=".jpg,.jpeg,.png,.webp,.gif"
                  onChange={(e) => (e.target.files ? uploadFile(e.target.files[0]) : null)}
                />
                <div className="w-full h-[150px] bg-wb-secondary rounded-lg shadow-sm flex flex-col gap-2 items-center justify-center">
                  {backgroundImage && (
                    <img src={backgroundImage} alt="background" className="w-full h-full object-contain" />
                  )}
                  {!(backgroundImage || backgroundVideo) && (
                    <>
                      <Button size="sm" variant="tertiary" onClick={handleUploadClick} isLoading={loading}>
                        Upload an Image
                      </Button>

                      <Text size="2xs" weight="medium" variant="secondary">
                        OR
                      </Text>

                      <Button
                        size="sm"
                        variant="tertiary"
                        onClick={() => setShowImageLibrary(true)}
                        isLoading={loading}
                      >
                        Use from library
                      </Button>
                    </>
                  )}
                </div>

                <div className="absolute top-3 right-3 cursor-pointer hover:bg-wb-secondary/50 rounded-lg p-1">
                  <Trash className="text-wb-secondary hover:text-wb-danger" weight="bold" onClick={handleDelete} />
                </div>
              </div>
              <div className="w-full flex items-center justify-center">
                <Text size="xs" weight="medium" variant="secondary">
                  OR
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <Text size="2xs" variant="secondary" weight="medium" className="w-[100px]">
                    Media URL
                  </Text>
                  <input
                    className="outline-none bg-wb-secondary rounded-lg shadow-sm p-2 border-transparent border focus:outline-none w-full text-xs"
                    placeholder="https://google.com"
                    value={backgroundImage}
                    onChange={handleMediaURLChange}
                  />
                </div>
              </div>
              <hr />
              <SliderSettings
                editor={editor}
                activeNodeResult={activeNodeResult}
                property="backgroundDarken"
                title="Darkened"
                unit=""
                min={0}
                max={1}
                step={0.1}
                type="number"
              />
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
