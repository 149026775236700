import { TrashIcon } from '@heroicons/react/20/solid';

import CustomFieldSelect from '@/components/CustomFieldSelect';
import MagicInput from '@/components/Form/MagicInput';
import { AutomationStepUpdateSubscriptionCustomField } from '@/interfaces/automations/automation_step';
import { CustomField } from '@/interfaces/custom_field';
import { CustomFieldOption } from '@/interfaces/general';

import { findCustomField } from '../Nodes/AutomationStepNodes/findCustomField';

import { AttributeDateInput } from './EditAttributesModalInputs';

interface Props {
  customFields: CustomField[];
  currentCustomFields: AutomationStepUpdateSubscriptionCustomField[];
  onUpdate: (index: number, value: AutomationStepUpdateSubscriptionCustomField) => void;
  onDelete: (index: number) => void;
  shouldRefetchCustomFields: boolean;
}

const CustomFields = ({ currentCustomFields, customFields, onUpdate, onDelete, shouldRefetchCustomFields }: Props) => {
  const renderCustomFieldInput = (id: string, customField: CustomField, customFieldData: any, index: number) => {
    if (['date', 'datetime'].includes(customField?.kind || '')) {
      return (
        <AttributeDateInput
          customField={customField}
          value={customFieldData.value}
          onChange={(newValue) => onUpdate(index, { id, value: newValue?.toString() })}
        />
      );
    }

    return (
      <MagicInput
        name={id}
        kind={customField?.kind || 'string'}
        value={customFieldData.value}
        onBlur={(newValue) => onUpdate(index, { id, value: newValue?.toString() })}
      />
    );
  };

  return (
    <div className="flex flex-col gap-y-2">
      {currentCustomFields.map((customFieldData, index) => {
        const { id, value } = customFieldData;
        const customField = findCustomField(customFields, id);

        return (
          <div
            key={`custom-field-${id}`}
            className="group flex flex-grow gap-x-2 py-2 px-2 rounded-lg bg-gray-50 hover:bg-gray-100"
          >
            <CustomFieldSelect
              className="w-full"
              customFieldId={id}
              onFilterOptions={(fieldOptions: CustomFieldOption[]) => {
                return fieldOptions.filter((option) => {
                  if (option.value === id) return true;

                  let isOptionAvailable = true;

                  currentCustomFields.forEach((cf) => {
                    if (cf.id === option.value) {
                      isOptionAvailable = false;
                    }
                  });

                  return isOptionAvailable;
                });
              }}
              onSelectCustomField={(customFieldId) => onUpdate(index, { id: customFieldId, value })}
              onClearCustomField={() => onUpdate(index, { id: '', value })}
              refetchFetchOptions={shouldRefetchCustomFields}
            />
            <div className="w-full">
              {customField && renderCustomFieldInput(id, customField, customFieldData, index)}
            </div>
            <button
              type="button"
              onClick={() => onDelete(index)}
              title="Delete"
              className="opacity-0 group-hover:opacity-100"
            >
              <TrashIcon className="text-gray-400 hover:text-gray-800" width="15" height="15" />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default CustomFields;
