import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  isNested?: boolean;
  backgroundClassName?: string;
}>;

const Tree = ({ children, isNested = false, backgroundClassName = 'bg-gray-200' }: Props) => (
  <div className={cx('flex flex-row', isNested && 'ml-4 mt-4')}>
    <div className={cx('w-0.5', backgroundClassName)}>&nbsp;</div>
    <div className="flex flex-col w-full">{children}</div>
  </div>
);

export default Tree;
