import { useState } from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

import ActionModal from "@/components/ActionModal";
import { Typography } from "@/components/Typography";
import useCurrentPublicationId from "@/hooks/usePublications/useCurrentPublicationId";
import { Organization } from "@/interfaces/organization";

import useCreateBulkInvite from "./_hooks/useCreateBulkInvite";
import { InviteFormRowData, InviteFormRowErrors } from "./_interfaces/invite";
import InviteForm from "./InviteForm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
  contributorEnabled?: boolean;
}

const InviteModal = ({isOpen, onClose, organization, contributorEnabled}: Props) => {
  const [hasErrors, setHasErrors] = useState(false);
  const [invites, setInvites] = useState<InviteFormRowData[]>([]);
  const createBulkInvite = useCreateBulkInvite(organization.id);
  const currentPublicationId = useCurrentPublicationId();
  const actionText = invites.length > 1 ? 'Send Email Invites' : 'Send Email Invite';

  const handleSendInvite = () => {
    createBulkInvite.mutate({
      invites,
      publicationId: currentPublicationId
    });
    onClose();
  };

  const hasUnfinishedRows = invites.some((invite) => invite.email === '' || (invite.permissions === 'publication' && invite.publications.length === 0));

  const handleInviteChange = (newInvites: InviteFormRowData[]) => {
    setInvites(newInvites);
  }

  const handleErrorChange = (errors: InviteFormRowErrors[]) => {
    setHasErrors(errors.length > 0);
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      isWorking={false}
      onProceed={handleSendInvite}
      resourceId={organization.id}
      headerText={`Invite New Team User to ${organization.name}`}
      actionText={actionText}
      actionIcon={EnvelopeIcon}
      modalSize="lg"
      disabled={hasErrors || hasUnfinishedRows}
    >
      <Typography className="mb-4">
        Send an email invite for new users to create their beehiiv profile for your account. Select a permission level and user role you would like them to have when they join your team.{' '}
        <a
          href="https://www.beehiiv.com/support/article/27753463738263-Workspace-user-roles-and-permissions-overview"
          target="_blank"
          rel="noreferrer"
          className="text-primary-600 underline"
        >
          Learn more about beehiiv team permissions
        </a>
      </Typography>
      <div className="my-6">
        <InviteForm
          onErrorChange={handleErrorChange}
          onInviteChange={handleInviteChange}
          contributorEnabled={contributorEnabled}
          organizationId={organization.id}
        />
      </div>
    </ActionModal>
  )
};

export default InviteModal;
