import { FC } from 'react';
import { SerializableNode, SignupModalElement } from '@shared/dream-components';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

export const SignupModalView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  return (
    <NodeViewWrapper data-drag-handle data-id={node.attrs.id}>
      <SignupModalElement
        isPreviewing
        element={{
          type: 'signupModal',
          attrs: {
            id: node.attrs.id,
            orientation: node.attrs.orientation,
            gap: node.attrs.gap,
            width: node.attrs.width,
            height: node.attrs.height,
            backgroundColor: node.attrs.backgroundColor,
            padding: node.attrs.padding,
            borderRadius: node.attrs.borderRadius,
            borderWidth: node.attrs.borderWidth,
            borderColor: node.attrs.borderColor,
            borderStyle: node.attrs.borderStyle,
            modalOverlayBackgroundColor: node.attrs.modalOverlayBackgroundColor,
          },
          content: node.content as unknown as SerializableNode,
        }}
        attributes={{
          'data-node-view-wrapper': '',
          ...getDataAttributes(HTMLAttributes),
        }}
        className="relative"
      >
        <NodeViewContent data-node-view-display-contents />
      </SignupModalElement>
    </NodeViewWrapper>
  );
};
