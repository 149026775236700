import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import api from '../../../services/swarm';
import PageLayout from '../_components/PageLayout';

const NewCustomPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCreateCustomPage = (formData: FormData) => {
    setIsLoading(true);
    api
      .post(`/custom_pages`, formData)
      .then(() => {
        toast.success('Page created successfully!');
        navigate('/settings/website/pages');
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message || 'Could not create page.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return <PageLayout isSubmitting={isLoading} onSave={handleCreateCustomPage} />;
};

export default NewCustomPage;
