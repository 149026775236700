import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useCreateAbuseReport = ({ onSuccess }: { onSuccess?: (result: any) => void }) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<any, unknown, { publication_id: string; reason?: string }>(
    ({ publication_id, reason }) =>
      api
        .post('/abuse_reports', {
          publication_id: currentPublicationId,
          abuse_report: {
            publication_id,
            reason: reason || '',
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useCreateAbuseReport;
