import { useGoToBilling } from '@/hooks';
import { useTrialStatus } from '@/hooks/usePublicationDashboard';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useCreateTrial from '@/routes/onboarding/_hooks/useCreateTrial';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import Modal from '../Modal';
import PricingPanel from '../PricingPanel';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const UpgradeIntent: React.FC<Props> = ({ isOpen, onClose }) => {
  const currentPublicationId = useCurrentPublicationId();
  const trialStatusQuery = useTrialStatus(currentPublicationId);
  const { data: trialStatus } = trialStatusQuery || {};
  const trialEligible = trialStatus?.trial_eligible;
  const createTrial = useCreateTrial();
  const goToBilling = useGoToBilling();

  const startTrial = () => {
    createTrial.mutate(
      { selectedPlan: 'scale' },
      {
        onSuccess: () => {
          analytics.track('Trial Started', {
            state: 'trialing',
            currentPlan: 'launch',
            trialPlan: 'scale',
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="max-w-5xl w-full">
      <div className="p-8">
        <div className="mb-4">
          <h1 className="text-2xl leading-6 font-bold text-gray-900 mb-1">The newsletter suite of your dreams ✨</h1>
          <p className="text-base text-gray-500">Simple pricing. Predictable. Built for you.</p>
        </div>
        <div className="mb-8">
          <PricingPanel />
        </div>
        <div className="flex space-x-2 justify-end">
          {trialEligible ? (
            <Button block onClick={startTrial} loading={createTrial.isLoading || createTrial.isSuccess}>
              <span className="text-lg">Start a Scale Trial</span>
            </Button>
          ) : (
            <Button onClick={goToBilling} block>
              <span className="text-lg">Go to Billing</span>
            </Button>
          )}
        </div>
        <p className="text-sm text-gray-500 text-center mt-4">
          <span>Learn more about </span>
          <a
            className="underline hover:text-primary-500"
            href="https://www.beehiiv.com/enterprise"
            target="_blank"
            rel="noopener noreferrer"
          >
            enterprise plans
          </a>
        </p>
      </div>
    </Modal>
  );
};

export default UpgradeIntent;
