import { AssignableLevels } from "@/interfaces/role";
import { TeamMemberPermission } from "@/interfaces/team_member";

export const ASSIGNABLE_LEVEL_NAMES: Record<AssignableLevels, string> = {
  'admin': 'Admin',
  'member': 'Member',
  'contributor': 'Contributor',
};

export const TEAM_MEMBER_PERMISSION_NAMES: Record<TeamMemberPermission, string> = {
  'Organization': 'Workspace',
  'Publication': 'Publication',
};
