import { Tutorial, TutorialType } from '../../interfaces/tutorial';
import tutorials from '../../services/tutorials/index.json';

const useTutorial = (tutorialType: TutorialType): Tutorial | undefined => {
  const foundTutorial: any = tutorials.tutorials.find((tutorial) => tutorial.title === tutorialType);

  return foundTutorial;
};

export default useTutorial;
