import cx from 'classnames';

import { gapClasses } from './Grid';

type GenerateProps = {
  span: number | SpanString | SpanString[];
  spanType: 'col' | 'row';
  currentSpanClass?: string[];
  responsive?: boolean;
};

type RowProps = {
  children: React.ReactNode;
  className?: string;
  colSpan?: number | SpanString | SpanString[];
  rowSpan?: number | SpanString | SpanString[];
  gap?: number;
  responsive?: boolean;
};

type SpanString =
  | `xs:${number}`
  | `sm:${number}`
  | `md:${number}`
  | `lg:${number}`
  | `xl:${number}`
  | `2xl:${number}`
  | `3xl:${number}`
  | `4xl:${number}`
  | `5xl:${number}`;

const colSpanClasses: { [key: number]: string } = {
  0: '',
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
  7: 'col-span-7',
  8: 'col-span-8',
  9: 'col-span-9',
  10: 'col-span-10',
  11: 'col-span-11',
  12: 'col-span-12',
};

const mdColSpanClasses: { [key: number]: string } = {
  0: '',
  1: 'md:col-span-1',
  2: 'md:col-span-2',
  3: 'md:col-span-3',
  4: 'md:col-span-4',
  5: 'md:col-span-5',
  6: 'md:col-span-6',
  7: 'md:col-span-7',
  8: 'md:col-span-8',
  9: 'md:col-span-9',
  10: 'md:col-span-10',
  11: 'md:col-span-11',
  12: 'md:col-span-12',
};

const rowSpanClasses: { [key: number]: string } = {
  0: '',
  1: 'row-span-1',
  2: 'row-span-2',
  3: 'row-span-3',
  4: 'row-span-4',
  5: 'row-span-5',
  6: 'row-span-6',
  7: 'row-span-7',
  8: 'row-span-8',
  9: 'row-span-9',
  10: 'row-span-10',
  11: 'row-span-11',
  12: 'row-span-12',
};

const mdRowSpanClasses: { [key: number]: string } = {
  0: '',
  1: 'md:row-span-1',
  2: 'md:row-span-2',
  3: 'md:row-span-3',
  4: 'md:row-span-4',
  5: 'md:row-span-5',
  6: 'md:row-span-6',
  7: 'md:row-span-7',
  8: 'md:row-span-8',
  9: 'md:row-span-9',
  10: 'md:row-span-10',
  11: 'md:row-span-11',
  12: 'md:row-span-12',
};

const transformColSpan = (colSpan: number, size: string = '') => {
  switch (size) {
    case 'md':
      return mdColSpanClasses[colSpan];
    default:
      return colSpanClasses[colSpan];
  }
};

const transformRowSpan = (rowSpan: number, size: string = '') => {
  switch (size) {
    case 'md':
      return mdRowSpanClasses[rowSpan];
    default:
      return rowSpanClasses[rowSpan];
  }
};

const splitColSpan = (colSpan: SpanString) => {
  const colSpanParts = colSpan.split(':');
  let size = '';
  let defaultSpan = '';

  if (colSpanParts.length === 2) {
    size = `${colSpanParts[0]}:`;
    defaultSpan = 'col-span-12';
  }

  return `${size}${transformColSpan(Number(colSpanParts[1]))} ${defaultSpan}`;
};

const splitRowSpan = (rowSpan: SpanString) => {
  const rowSpanParts = rowSpan.split(':');
  let size = '';
  let defaultSpan = '';

  if (rowSpanParts.length === 2) {
    size = `${rowSpanParts[0]}:`;
    defaultSpan = 'row-span-12';
  }

  return `${size}${transformRowSpan(Number(rowSpanParts[1]))} ${defaultSpan}`;
};

const generateClass = ({ span, spanType, currentSpanClass = [], responsive }: GenerateProps): string[] => {
  switch (typeof span) {
    case 'number': {
      if (responsive) {
        const newClass = spanType === 'col' ? `${transformColSpan(span)}` : `${transformRowSpan(span)}`;
        currentSpanClass.push(newClass);
      } else {
        const newClass = spanType === 'col' ? transformColSpan(span) : transformRowSpan(span);
        currentSpanClass.push(newClass);
      }
      break;
    }
    case 'object': {
      // array
      span.forEach((item) => {
        const newClass = spanType === 'col' ? splitColSpan(item as SpanString) : splitRowSpan(item as SpanString);
        currentSpanClass.push(newClass);
      });
      break;
    }
    case 'string': {
      const newClass = spanType === 'col' ? splitColSpan(span as SpanString) : splitRowSpan(span as SpanString);
      currentSpanClass.push(newClass);
      break;
    }
    default:
      break;
  }

  return currentSpanClass;
};

const Row = ({ className = '', colSpan = 12, rowSpan = 0, gap = 0, children, responsive = true }: RowProps) => {
  const hasColSpan = className?.indexOf('col-span') >= 0;
  const hasRowSpan = className?.indexOf('row-span') >= 0;
  let colSpanClass = [''];
  let rowSpanClass = [''];

  if (!hasColSpan) {
    colSpanClass = generateClass({ span: colSpan, spanType: 'col', responsive });
  }

  if (!hasRowSpan) {
    rowSpanClass = generateClass({ span: rowSpan, spanType: 'row', responsive });
  }

  const classNames = cx(colSpanClass.join(' '), rowSpanClass.join(' '), gapClasses[gap], className);

  return <div className={classNames}>{children}</div>;
};

export default Row;
