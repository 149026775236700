import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TimePeriod } from '@/interfaces/time_period';
import api from '@/services/swarm';

export enum EngagementEndpoints {
  IMPRESSIONS = 'impressions',
  TOP_POSTS = 'top_posts',
}

interface Props {
  endpoint: EngagementEndpoints;
  timePeriod: TimePeriod;
  filter_params?: {
    metric?: string;
  };
}

const useEngagement = ({ endpoint, timePeriod, filter_params }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchInsights = () =>
    api
      .get(`/dashboard/engagement/${endpoint}/${timePeriod}`, {
        params: {
          publication_id: currentPublicationId,
          ...filter_params,
        },
      })
      .then((res) => res.data);

  return useQuery(['engagement', currentPublicationId, endpoint, timePeriod, filter_params?.metric], fetchInsights, {
    refetchOnWindowFocus: false,
    retryOnMount: false,
    enabled: Boolean(currentPublicationId),
  });
};

export default useEngagement;
