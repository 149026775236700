import { Textbox } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';

type Props = AttributeSettingProps & {};

export const InputSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Input" text="Input" popoverTitle="Input" Icon={Textbox}>
      <>
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          property="inputTextFontFamily"
          title="Font"
        />
        <ColorSettings
          title="Background"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="inputBackgroundColor"
        />
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'inputBorderColor',
            style: 'inputBorderStyle',
            width: 'inputBorderWidth',
            radius: 'inputBorderRadius',
          }}
        />
        <ColorSettings title="Input" property="inputTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings
          title="Placeholder"
          property="inputPlaceholderColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <ColorSettings title="Required" property="requiredColor" editor={editor} activeNodeResult={activeNodeResult} />
      </>
    </PopoverHelper>
  );
};

export default InputSettings;
