import { FC, useCallback } from 'react';
import toast from 'react-hot-toast';

import { useCurrentPublication } from '../../hooks';
import { Option } from '../../interfaces/general';
import { Segment } from '../../interfaces/segment';
import api from '../../services/swarm';
import { SearchDropdown } from '../Form';

interface Props {
  labelText?: string;
  helperText?: string;
  className?: string;
  labelClassName?: string;
  segmentId?: string;
  onSelect: (id: string) => void;
  onClear: () => void;
  dropdownClassnames?: string;
}

const SegmentSelect: FC<Props> = (props: Props) => {
  const {
    labelText = 'Segment',
    helperText,
    className,
    labelClassName,
    segmentId,
    onSelect,
    onClear,
    dropdownClassnames,
  } = props;
  const { data: currentPublication } = useCurrentPublication();

  const fetchSegments = useCallback(
    (inputValue?: string) => {
      const params = {
        publication_id: currentPublication?.id,
        q: inputValue,
        per_page: 20,
      };

      return api
        .get(`/segments`, { params })
        .then((res) => {
          const segments = res.data?.segments || [];

          const options: Option[] = segments.map((segment: Segment) => {
            const val: Option = {
              label: segment.name,
              value: segment.id,
            };
            return val;
          });
          return options;
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },

    [currentPublication]
  );

  const loadInitialValue = useCallback(() => {
    if (!segmentId) {
      return undefined;
    }

    const params = {
      publication_id: currentPublication?.id,
    };

    return api
      .get(`/segments/${segmentId}`, { params })
      .then((res) => {
        const segment = res.data;
        const option = segment && {
          label: segment.name,
          value: segment.id,
        };

        return option;
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [currentPublication, segmentId]);

  const handleSelect = (_name: string, value: string) => {
    onSelect(value);
  };

  const handleClear = () => {
    onClear();
  };

  if (!currentPublication) {
    return null;
  }

  return (
    <SearchDropdown
      name="segment"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText="Select a Segment"
      value={segmentId || ''}
      onSelect={handleSelect}
      onClear={handleClear}
      loadOptions={fetchSegments}
      loadInitialValue={loadInitialValue}
      labelClassName={labelClassName}
      className={className}
      dropdownClassnames={dropdownClassnames}
    />
  );
};

export default SegmentSelect;
