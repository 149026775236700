import { ArrowLeft } from '@phosphor-icons/react';

import { Button } from '../UI/Button';

interface Props {
  onClick: () => void;
}

export const BackButton = ({ onClick }: Props) => (
  <Button variant="outlined" Icon={ArrowLeft} onClick={onClick} className="h-full" />
);
