import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  paywallId: string;
  onSuccess: () => void;
}

const useUpdatePaywall = ({ paywallId, onSuccess }: Props) => {
  return useMutation((formData: FormData) => api.patch(`/paywalls/${paywallId}`, formData).then((res) => res.data), {
    onSuccess: () => {
      onSuccess?.();
      toast.success('Saved!');
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
  });
};

export default useUpdatePaywall;
