import { useQuery } from 'react-query';

import { AdNetworkReport } from '@/interfaces/ad_network/publisher/report';
import api from '@/services/swarm';

export default function useReport(publicationId: string, { id }: { id: string }) {
  return useQuery<AdNetworkReport>(
    [publicationId, 'ad_network', 'publisher', 'reports', id],
    () =>
      api
        .get(`/ad_network/publisher/reports/${id}`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
