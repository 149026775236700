export enum TierChangeEventType {
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
}

export enum TierChangeEventResourceType {
  POST = 'Post',
  EMAIL_MESSAGE = 'EmailMessage',
  MILESTONE = 'Milestone',
}

export interface TierChangeEvent {
  change_type: TierChangeEventType;
  from: string;
  to: string;
  resource_id: string;
  resource_type: TierChangeEventResourceType;
  subscription_id: number;
}
