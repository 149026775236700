import { useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import MagicInput from '@/components/Form/MagicInput';
import Text from '@/components/Text';
import { CustomField } from '@/interfaces/custom_field';
import { CustomFieldValue } from '@/interfaces/custom_field_value';
import { Button } from '@/ui/Button';

import useDestroyCustomFieldValue from './_hooks/useDestroyCustomFieldValue';
import { useDisplayCustomFieldKind } from './_hooks/useDisplayCustomFieldKind';
import { useDisplayCustomFieldValue } from './_hooks/useDisplayCustomFieldValue';
import useUpdateCustomFieldValue from './_hooks/useUpdateCustomFieldValue';

interface CustomFieldItemProps {
  customField: CustomField;
  customFieldValue?: CustomFieldValue;
}

const CustomFieldItem: React.FC<CustomFieldItemProps> = ({ customField, customFieldValue }) => {
  const displayValue = useDisplayCustomFieldValue();
  const displayKind = useDisplayCustomFieldKind();
  const updateMutation = useUpdateCustomFieldValue({
    customFieldId: customField.id,
    customFieldValueId: customFieldValue?.id,
  });
  const deleteMutation = useDestroyCustomFieldValue({
    customFieldId: customField.id,
    customFieldValueId: customFieldValue?.id,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(customFieldValue?.value);

  const handleSave = () => updateMutation.mutate({ value: updatedValue }, { onSuccess: () => setIsEditing(false) });

  const handleDelete = () => deleteMutation.mutate(undefined, { onSuccess: () => setIsDeleting(false) });

  return (
    <>
      <ActionModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        resourceId={`${customField.id}/${customFieldValue?.id}`}
        headerText="Delete Custom Field Value"
        onProceed={handleDelete}
        isWorking={deleteMutation.isLoading}
      >
        This will permanently delete the value for this custom field for this subscriber. Are you sure you want to
        proceed?
      </ActionModal>
      <div className="px-3 py-4 border-t border-gray-200 first:border-t-0 space-y-2">
        <div className="flex justify-between">
          <Text size="sm" className="font-semibold text-gray-400">
            {displayKind(customField.kind)}
          </Text>
          <EllipsisDropdown
            options={[
              {
                label: 'Edit',
                onClick: () => setIsEditing(true),
              },
              ...(customFieldValue
                ? [
                    {
                      label: 'Delete',
                      onClick: () => setIsDeleting(true),
                      labelClass: 'text-red-600',
                    },
                  ]
                : []),
            ]}
          >
            <div className="bg-white hover:bg-gray-100 rounded-full p-0.5">
              <EllipsisVerticalIcon className="w-4 h-4 text-gray-900" />
            </div>
          </EllipsisDropdown>
        </div>
        <Text className="font-medium">{customField.display}</Text>
        {isEditing ? (
          <>
            <MagicInput
              name={customField.name}
              kind={customField.kind}
              value={customFieldValue?.value}
              onChange={(val) => setUpdatedValue(val)}
            />
            <div className="flex justify-end pt-2 space-x-2">
              <Button variant="flush" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
              <Button
                variant="secondary"
                onClick={handleSave}
                loading={updateMutation.isLoading}
                disabled={updateMutation.isLoading}
              >
                Save
              </Button>
            </div>
          </>
        ) : (
          <Text className="break-all">{displayValue(customField.kind, customFieldValue?.value)}</Text>
        )}
      </div>
    </>
  );
};

export default CustomFieldItem;
