import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { Post } from '@/interfaces/post';
import api from '@/services/swarm';

import { useCurrentPublication } from '../usePublications';

interface Props {
  postId: Post['id'];
  onError?: (err: any) => void;
}

export const useUpdatePostTextToSpeechConfig = ({ postId, onError }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (payload: any) =>
      api.patch(`/posts/${postId}/text_to_speech_config`, payload, {
        params: { publication_id: currentPublication?.id },
      }),
    {
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Issue updating text to speech config');

        onError?.(err);
      },
    }
  );
};
