import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import { Button } from '@/ui/Button';

import { BackgroundGradient } from '../../components/BackgroundGradient';
import { Input } from '../../components/Form';
import Header from '../../components/Header';
import Stack from '../../components/Stack';
import { SvgLogo } from '../../components/SvgLogo';
import Text from '../../components/Text';
import { useAuth } from '../../context/auth-context';

const H2Styled = styled.h2`
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  @media (min-width: 400px) {
    white-space: nowrap;
  }
`;

const ButtonStyled = styled(Button)`
  background-color: #209bf1;
  box-shadow: 6px 6px 0px rgba(32, 155, 241, 0.25);
  padding: 1rem;
`;

const RequestPassword = () => {
  const [emailQueryParam, setEmailQueryParam] = useQueryParam('email', StringParam);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isDisabled = () => !email?.match(/^.+@.+$/);

  const { requestPassword } = useAuth();

  useEffect(() => {
    if (emailQueryParam) {
      if (email) {
        setEmailQueryParam(undefined);
      } else {
        setEmail(emailQueryParam);
      }
    }
  }, [email, emailQueryParam, setEmailQueryParam]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');

    if (e.target.name === 'email') {
      setEmail(e.target.value);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setErrorMessage('This field is required');
    }

    if (email && !isDisabled()) {
      requestPassword.mutate({ email });
    }
  };

  useEffect(() => {
    const startAdaEmbed = async () => {
      try {
        await window.adaEmbed.start({
          handle: 'beehiiv',
          metaFields: {
            channel: 'ada-resetpw-page',
          },
        });
      } catch {
        // console.error('Error starting Ada Embed:', error);
        try {
          await window.adaEmbed.setMetaFields({ channel: 'ada-resetpw-page' });
        } catch {
          // console.error('Error resetting Ada Embed:', error);
        }
      }
    };

    startAdaEmbed();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-10 sm:px-6 lg:px-8 xs:p-5">
      <BackgroundGradient className="z-0 fixed inset-0 h-full w-full object-cover" />
      <div className="z-10 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-12 h-auto m-auto">
          <a href="https://www.beehiiv.com" aria-label="beehiiv" target="_blank" rel="noreferrer">
            <SvgLogo stopColorOverride="#fff" />
          </a>
        </div>
      </div>
      <div className="z-10 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white shadow rounded-lg">
          <div className="py-8 px-4 sm:px-6">
            <div className="text-center mb-10">
              <H2Styled>Reset&nbsp;your password</H2Styled>
            </div>
            <Stack direction="col" gap={4}>
              {requestPassword.isSuccess ? (
                <Header as="h5" className="text-center">
                  If this email address is found, be on the lookout for an email with a link to reset your password!
                </Header>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Stack direction="col" gap={4}>
                    <Text size="sm" className="text-gray-600">
                      Enter your user account&apos;s verified email address and we will send you a password reset link.
                    </Text>
                    <Stack direction="col" gap={4}>
                      <Input
                        type="email"
                        name="email"
                        labelText="Email address"
                        errorText={errorMessage}
                        autoComplete="email"
                        value={email}
                        onChange={handleChange}
                        placeholder="your@email.com"
                        inputClassOverride="mt-1 appearance-none block w-full p-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 text-base"
                      />
                    </Stack>
                    <div>
                      <ButtonStyled
                        Icon={ArrowRightIcon}
                        iconRight
                        disabled={requestPassword.isLoading}
                        loading={requestPassword.isLoading}
                        type="submit"
                        variant="primary"
                        className="w-full flex justify-center text-sm xs:text-base sm:text-xl"
                      >
                        {requestPassword.isLoading ? 'Sending email...' : 'Send password reset email'}
                      </ButtonStyled>
                      {requestPassword.isError && (
                        <p className="text-red-500 text-xs italic mt-2">Email is incorrect</p>
                      )}
                    </div>
                  </Stack>
                </form>
              )}
              <div className="flex flex-col items-center gap-2 text-center">
                <Link className="text-sm font-medium text-gray-600 hover:text-gray-500" to="/login">
                  Back to login
                </Link>
                <span className="text-sm text-center">
                  Trouble resetting your password? {` `}
                  <button
                    type="button"
                    onClick={window.adaEmbed.toggle}
                    className="text-gray-600 font-medium underline hover:text-gray-500"
                  >
                    Talk with our Chatbot Assistant
                  </button>
                </span>
              </div>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPassword;
