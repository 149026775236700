import SectionDivider from '../../_components/Helpers/SectionDivider';
import { NavSectionIcon, SectionGroupingSlideOut } from '../../_components/Helpers/SectionGroupingSlideOut';

import GlobalNavigationOptions from './MenuBuilder/CustomMenu/MenuGroups/GlobalNavigationOptions';
import NavigationMenuGroup from './MenuBuilder/CustomMenu/MenuGroups/NavigationMenuGroup';
import StandalonePages from './MenuBuilder/CustomMenu/StandalonePages';
import UnlinkedPages from './MenuBuilder/CustomMenu/UnlinkedPages';

const NavigationLayoutSettings = () => {
  return (
    <div className="flex flex-col">
      <SectionGroupingSlideOut
        title="Global Navigation Layout"
        showPlanBadge
        sectionIcon={<NavSectionIcon />}
        highlightProps={{
          sectionId: 'global-navigation',
        }}
      >
        <GlobalNavigationOptions />
        <SectionDivider />
        <NavigationMenuGroup />
        <SectionDivider />

        <StandalonePages />
        <SectionDivider />

        <UnlinkedPages />
      </SectionGroupingSlideOut>
    </div>
  );
};

export default NavigationLayoutSettings;
