import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostSend } from '@/interfaces/boosts/monetize/boost_send';
import { Order, Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  boost_sends: BoostSend[];
  pagination: Pagination;
}

interface Props {
  allResults?: boolean;
  boostAgreementId?: string;
  perPage?: number;
  search?: string | null;
  status?: string | null;
  isEnabled?: boolean;
  sendable?: boolean;
  boostedPublicationId?: string;
  orderBy?: string;
  direction?: Order;
}

const useBoostSends = ({
  boostAgreementId,
  search,
  status,
  boostedPublicationId,
  orderBy,
  allResults = false,
  sendable = false,
  perPage = 10,
  isEnabled = true,
  direction = Order.DESC,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostSends = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/boosts/monetize/boost_sends`, {
        params: {
          publication_id: currentPublicationId,
          boosted_publication_id: boostedPublicationId,
          all_results: allResults,
          boost_agreement_id: boostAgreementId,
          page: pageParam,
          per_page: perPage,
          order_by: orderBy,
          search,
          status,
          sendable,
          direction,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(
    [
      currentPublicationId,
      boostedPublicationId,
      'boosts',
      'monetize',
      'boost_sends',
      boostAgreementId,
      status,
      search,
      orderBy,
      direction,
    ],
    fetchBoostSends,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
      enabled: isEnabled,
    }
  );
};

export default useBoostSends;
