import { useCallback, useMemo, useState } from 'react';
import { Level } from '@tiptap/extension-heading';
import { Editor } from '@tiptap/react';

export const useFormattingOptions = (editor: Editor) => {
  const onBold = useCallback(() => (editor.chain().focus() as any).toggleBold().run(), [editor]);
  const onItalic = useCallback(() => (editor.chain().focus() as any).toggleItalic().run(), [editor]);
  const onUnderline = useCallback(() => editor.chain().focus().toggleUnderline().run(), [editor]);
  const onStrike = useCallback(() => (editor.chain().focus() as any).toggleStrike().run(), [editor]);
  const onCode = useCallback(() => (editor.chain().focus() as any).toggleCode().run(), [editor]);
  const onSuperScript = useCallback(() => editor.chain().focus().toggleSuperscript().run(), [editor]);
  const onSubScript = useCallback(() => editor.chain().focus().toggleSubscript().run(), [editor]);
  const onCodeBlock = useCallback(() => editor.chain().focus().toggleCodeBlock().run(), [editor]);
  const onBlockquote = useCallback(() => editor.chain().focus().setBlockquote().run(), [editor]);

  const isBold = editor.isActive('bold');
  const isItalic = editor.isActive('italic');
  const isUnderline = editor.isActive('underline');
  const isStrike = editor.isActive('strike');
  const isCode = editor.isActive('code');
  const isSuperScript = editor.isActive('superscript');
  const isSubScript = editor.isActive('subscript');
  const isCodeBlock = editor.isActive('codeBlock');
  const isBlockquote = editor.isActive('blockquoteFigure');
  const isParagraph = editor.isActive('paragraph');

  const canCommands = editor.can();
  const canBold = canCommands.toggleBold();
  const canItalic = canCommands.toggleItalic();
  const canUnderline = canCommands.toggleUnderline();
  const canStrike = canCommands.toggleStrike();
  const canCode = canCommands.toggleCode();
  const canSuperScript = canCommands.toggleSuperscript();
  const canSubScript = canCommands.toggleSubscript();
  const canCodeBlock = canCommands.toggleCodeBlock();
  const canBlockquote = canCommands.setBlockquote() && isParagraph;

  const value = {
    canBold,
    canCode,
    canCodeBlock,
    canItalic,
    canStrike,
    canSubScript,
    canSuperScript,
    canUnderline,
    canBlockquote,
    isBold,
    isCode,
    isCodeBlock,
    isItalic,
    isStrike,
    isSubScript,
    isSuperScript,
    isUnderline,
    isBlockquote,
    onBold,
    onCode,
    onCodeBlock,
    onItalic,
    onStrike,
    onSubScript,
    onSuperScript,
    onUnderline,
    onBlockquote,
  };

  return value;
};

export const useHeadingOptions = (editor: Editor) => {
  const onHeading = useCallback((level: Level) => editor.chain().focus().toggleHeading({ level }).run(), [editor]);
  const onReset = useCallback(() => editor.chain().focus().setParagraph().run(), [editor]);
  const canHeading = editor.can().toggleHeading({ level: 1 });

  const currentLevel = editor.isActive('heading') ? (editor.getAttributes('heading').level as Level) : null;

  const value = {
    currentLevel,
    canHeading,
    onHeading,
    onReset,
  };

  return value;
};

export const useLinkHandlers = (editor: Editor, onReposition: () => void) => {
  const [showEdit, setShowEdit] = useState(false);

  const canLink = editor.can().setLink({ href: '' });

  const onEditLinkClick = useCallback(() => {
    setShowEdit(true);
    onReposition();
  }, [onReposition]);

  const onSetLink = useCallback(
    (url: string, target: string = '_blank') => {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url, target }).run();
      setShowEdit(false);
    },
    [editor]
  );

  const onUnsetLink = useCallback(() => {
    setShowEdit(false);
    return null;
  }, []);

  const onBack = useCallback(() => {
    setShowEdit(false);
    onReposition();
  }, [onReposition]);

  const onSetTarget = useCallback(
    (target: string) => {
      const url = editor.getAttributes('link').href;
      if (url) editor.chain().focus().extendMarkRange('link').setLink({ href: url, target }).run();
    },
    [editor]
  );

  return {
    canLink,
    onBack,
    onEditLinkClick,
    onSetLink,
    onUnsetLink,
    onSetTarget,
    showEdit,
    setShowEdit,
  };
};

export const useHighlighting = (editor: Editor) => {
  const onHighlightSelect = useCallback(
    (color: string) => {
      if (color) {
        editor.chain().setHighlight({ color }).focus().run();
      }
    },
    [editor]
  );

  const onHighlightClear = useCallback(() => editor.chain().unsetHighlight().focus().run(), [editor]);

  const isHighlightActive = useCallback((color: string) => editor.isActive('highlight', { color }), [editor]);

  return { onHighlightSelect, onHighlightClear, isHighlightActive };
};

export const useListOptions = (editor: Editor) => {
  const isBulletListActive = editor.isActive('bulletList');
  const isOrderedListActive = editor.isActive('orderedList');

  const isList = isBulletListActive || isOrderedListActive;

  const canBulletList = editor.can().toggleBulletList();
  const canOrderedList = editor.can().toggleOrderedList();

  const canList = canBulletList || canOrderedList;

  const onBulletList = useCallback(() => editor.chain().focus().toggleBulletList().run(), [editor]);
  const onOrderedList = useCallback(() => editor.chain().focus().toggleOrderedList().run(), [editor]);
  const onReset = useCallback(() => editor.chain().focus().liftListItem('listItem').run(), [editor]);

  return {
    canBulletList,
    canList,
    canOrderedList,
    isBulletListActive,
    isList,
    isOrderedListActive,
    onBulletList,
    onOrderedList,
    onReset,
  };
};

export const useAlignmentOptions = (editor: Editor) => {
  const onAlignLeft = useCallback(() => editor.chain().focus().setTextAlign('left').run(), [editor]);
  const onAlignCenter = useCallback(() => editor.chain().focus().setTextAlign('center').run(), [editor]);
  const onAlignRight = useCallback(() => editor.chain().focus().setTextAlign('right').run(), [editor]);
  const onAlignJustify = useCallback(() => editor.chain().focus().setTextAlign('justify').run(), [editor]);

  const canAlign = editor.can().setTextAlign('left') && !editor.isActive('codeBlock');

  const isAlignLeft = editor.isActive({ textAlign: 'left' });
  const isAlignCenter = editor.isActive({ textAlign: 'center' });
  const isAlignRight = editor.isActive({ textAlign: 'right' });
  const isAlignJustify = editor.isActive({ textAlign: 'justify' });

  const alignment = useMemo<'left' | 'center' | 'right' | 'justify' | null>(() => {
    if (isAlignLeft) return 'left';
    if (isAlignCenter) return 'center';
    if (isAlignRight) return 'right';
    if (isAlignJustify) return 'justify';

    return null;
  }, [isAlignCenter, isAlignLeft, isAlignRight, isAlignJustify]);

  return {
    alignment,
    canAlign,
    isAlignCenter,
    isAlignJustify,
    isAlignLeft,
    isAlignRight,
    onAlignCenter,
    onAlignJustify,
    onAlignLeft,
    onAlignRight,
  };
};

export const useTextStyleOptions = (editor: Editor) => {
  const onSetColor = useCallback(
    (color: string | null) => {
      const isLinkActive = editor.isActive('link');

      if (isLinkActive) {
        editor.chain().focus().extendMarkRange('link').unsetColor().updateAttributes('link', { color }).run();
        return;
      }

      if (color) {
        editor?.chain().focus().setColor(color).run();
      } else {
        editor?.chain().focus().unsetColor().run();
      }
    },
    [editor]
  );

  const onSetFontSize = useCallback(
    (size: string | null) => {
      if (size) {
        editor?.chain().focus().setFontSize(size).run();
      } else {
        editor?.chain().focus().unsetFontSize().run();
      }
    },
    [editor]
  );

  const onSetFontFamily = useCallback(
    (fontFamily: string | null) => {
      if (fontFamily) {
        editor?.chain().focus().setFontFamily(fontFamily).run();
      } else {
        editor?.chain().focus().unsetFontFamily().run();
      }
    },
    [editor]
  );

  const { color, fontSize, fontFamily } = editor.getAttributes('textStyle');

  const { color: linkColor } = editor.getAttributes('link');

  return {
    color: color || linkColor,
    fontFamily,
    fontSize,
    onSetColor,
    onSetFontFamily,
    onSetFontSize,
  };
};
