import UpgradeButton from './UpgradeButton';

interface StatProps {
  plan: string;
  statHeading?: string;
  statNumber?: number | string;
  statDescription?: string;
  onUpgradeClick: () => void;
}

const Stat: React.FC<StatProps> = ({ plan, statHeading, onUpgradeClick, statNumber, statDescription }) => (
  <div className="relative">
    <div className="bg-white rounded-xl px-8 py-28 w-4/5">
      <div className="w-7/12 space-y-8">
        <div>
          <h2 className="text-3xl font-bold mb-2">{statHeading}</h2>
          <p className="text-lg">What are you waiting for?</p>
        </div>
        <UpgradeButton plan={plan} onClick={onUpgradeClick} />
      </div>
    </div>
    <div
      className="px-8 py-16 absolute bg-primary-500 w-1/2 top-1/2 right-0 transform -translate-y-1/2 rounded-xl"
      style={{
        backgroundImage: `url(https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/gradient_v2.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="space-y-6 text-white">
        <p className="text-7xl font-bold text-center">{statNumber}</p>
        <p className="text-xl text-center ">{statDescription}</p>
      </div>
    </div>
  </div>
);

export default Stat;
