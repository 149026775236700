import { useCallback } from 'react';
import { Editor } from '@tiptap/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { PanelHeadline, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';
import Styled from '@/components/TiptapEditor/extensions/ImageBlock/panels/Options/styled';
import { IconName } from '@/components/TiptapEditor/lib/types';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: true,
};

type Action = {
  align: 'left' | 'center' | 'right';
  iconName: IconName;
};

const actions: Array<Action> = [
  {
    align: 'left',
    iconName: 'AlignImageLeft',
  },
  {
    align: 'center',
    iconName: 'AlignImageCenter',
  },
  {
    align: 'right',
    iconName: 'AlignImageRight',
  },
];

const AlignmentButton = ({
  editor,
  action,
  setAlignContext,
}: {
  editor: Editor;
  action: Action;
  setAlignContext: (align: string) => void;
}) => {
  const { align, iconName } = action;
  const isActive = editor.isActive('advertisementOpportunityLogo', { overrideAlign: align });

  const onClick = useCallback(() => {
    editor.commands.updateAttributes('advertisementOpportunityLogo', {
      overrideAlign: align,
    });
    setAlignContext(align);
  }, [align, editor, setAlignContext]);

  return (
    <Tooltip title={`Align ${align}`}>
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
        $active={isActive}
        $muted={!isActive}
        onClick={onClick}
        $leftSlot={<Icon name={iconName} />}
      />
    </Tooltip>
  );
};

export const ImageAlignment = ({
  editor,
  setAlignContext,
}: {
  editor: Editor;
  setAlignContext: (align: string) => void;
}) => {
  if (!editor) {
    return null;
  }

  return (
    <PanelSection>
      <PanelHeadline>Image alignment</PanelHeadline>
      <Styled.ButtonWrapper>
        {actions.map((action) => (
          <AlignmentButton key={action.align} editor={editor} action={action} setAlignContext={setAlignContext} />
        ))}
      </Styled.ButtonWrapper>
    </PanelSection>
  );
};

export default ImageAlignment;
