import { useQuery } from 'react-query';

import api from '../services/swarm';

const useEmailMessagePreview = (publicationId: string, emailMessageId: string | undefined) => {
  return useQuery(
    ['email_messages', 'preview', emailMessageId],
    () =>
      api
        .get(`/email_messages/${emailMessageId}/preview`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: 30000,
      enabled: Boolean(emailMessageId),
    }
  );
};

export default useEmailMessagePreview;
