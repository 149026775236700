import { LeftPane, Main, RightPane } from '../components/Layout';
import { SectionTabPanel } from '../components/SectionTabs';

import AnalyzeActions from './AnalyzeActions';
import KPIs from './KPIs';
import TopPosts from './TopPosts';
import WebsiteActions from './WebsiteActions';
import WriteActions from './WriteActions';

const Engagement = () => {
  return (
    <SectionTabPanel>
      <Main>
        <LeftPane>
          <KPIs />
          <TopPosts />
        </LeftPane>

        <RightPane>
          <WriteActions />
          <WebsiteActions />
          <AnalyzeActions />
        </RightPane>
      </Main>
    </SectionTabPanel>
  );
};

export default Engagement;
