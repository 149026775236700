import { useRef } from 'react';
import Tippy from '@tippyjs/react';
import { Instance } from 'tippy.js';

import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip/index';
import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';
import { Author } from '@/interfaces/author';
import AuthorsActionsMenu from '@/pages/Post/Edit/v2/Compose/AuthorsMenu/AuthorsActionsMenu';

import { usePostContext } from '../../PostContext';
import AddContentTagMenu from '../ContentTags/AddContentTagMenu';
import ThumbnailActionsMenu from '../ThumbnailActionsMenu';

interface Props {
  guestAuthors: Author[];
  users: Author[];
  showAuthorsButton?: boolean;
  setShowCreateGuestAuthorModal: (value: boolean) => void;
  setShowImageLibrary: (value: boolean) => void;
  fileRef: React.RefObject<HTMLInputElement>;
  setAuthorTippyMenusShown: (value: boolean) => void;
}

const TitleContentMenu = ({
  guestAuthors,
  users,
  showAuthorsButton,
  setShowCreateGuestAuthorModal,
  fileRef,
  setShowImageLibrary,
  setAuthorTippyMenusShown,
}: Props) => {
  const tippyInstance = useRef<Instance | null>(null);
  const { formData } = usePostContext();
  const onAuthorsTippyMenuShow = () => {
    setAuthorTippyMenusShown(true);
  };

  const onAuthorsTippyMenuHide = () => {
    setAuthorTippyMenusShown(false);
  };

  const extendedShowImageLibrary = (show: boolean) => {
    setShowImageLibrary(show);

    tippyInstance.current?.hide();
  };

  const showThumbnailActionsMenu = () => {
    tippyInstance.current?.show();
  };

  const formatContentTags = () => {
    const formattedTags = formData?.content_tag_ids
      .slice(0, 2)
      .map((tagId) => {
        if (!tagId) {
          return null;
        }

        const tagOption = formData?.content_tag_options.find((tag) => tag && tag[0] === tagId);

        if (!tagOption || !tagOption[1]) {
          return null;
        }

        return tagOption[1];
      })
      .filter(Boolean);

    if (formattedTags && formData && formData?.content_tag_ids.length > 2) {
      const remainingCount = formData.content_tag_ids.length - 2;
      formattedTags.push(`+ ${remainingCount} more`);
    }

    return formattedTags?.join(', ');
  };

  return (
    <div className="flex flex-wrap lg:flex-nowrap mb-5 -ml-8 min-w-[calc(100%+2rem)] relative rounded-lg">
      {!formData?.thumbnail?.id && (
        <Tippy
          onCreate={(instance: Instance) => {
            tippyInstance.current = instance;
          }}
          offset={[0, 8]}
          placement="bottom-start"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          trigger="manual"
          interactive
          content={
            <div className="absolute">
              <ThumbnailActionsMenu fileRef={fileRef} setShowImageLibrary={extendedShowImageLibrary} />
            </div>
          }
          zIndex={EDITOR_MENUS_Z_INDEX}
        >
          <button
            onClick={showThumbnailActionsMenu}
            type="button"
            className="flex min-w-[140px] flex-nowrap mb-3 md:mb-0 ml-6 text-sm text-gray-400 hover:text-black transition-colors hover:bg-gray-100 rounded-lg px-2 pt-[.4rem]"
          >
            <span className="block mt-[.1rem]">
              <Icon name="Image" />
            </span>
            <span className="block ml-2 font-medium">Add thumbnail</span>
          </button>
        </Tippy>
      )}
      {showAuthorsButton && (
        <Tippy
          offset={[0, 8]}
          placement="bottom-start"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          onShow={onAuthorsTippyMenuShow}
          onHide={onAuthorsTippyMenuHide}
          trigger="click"
          interactive
          content={
            <div className="absolute">
              <AuthorsActionsMenu
                guestAuthors={guestAuthors}
                users={users}
                smallVersion
                setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
              />
            </div>
          }
          zIndex={EDITOR_MENUS_Z_INDEX}
        >
          <button
            data-testid="authors-button"
            type="button"
            className="flex min-w-[125px] flex-nowrap mb-3 md:mb-0 ml-6 text-sm text-gray-400 hover:text-black transition-colors hover:bg-gray-100 rounded-lg px-2 py-[.4rem]"
          >
            <span className="block mt-[.1rem]">
              <Icon name="Person" />
            </span>
            <span className="block ml-2 font-medium">Add authors</span>
          </button>
        </Tippy>
      )}
      <Tippy
        offset={[0, 8]}
        placement="bottom-start"
        popperOptions={{
          modifiers: [{ name: 'flip' }],
        }}
        trigger="click"
        interactive
        content={
          <div className="absolute">
            <AddContentTagMenu />
          </div>
        }
        zIndex={EDITOR_MENUS_Z_INDEX}
      >
        <button
          data-testid="content-tags-button"
          type="button"
          className="flex flex-nowrap mb-3 md:mb-0 ml-6 text-sm text-gray-400 hover:text-black transition-colors hover:bg-gray-100 rounded-lg pl-2 pr-3 pt-[.4rem]"
        >
          <Tooltip
            title="Displayed on a post card on the web, when toggled on in the Website Builder > Layout > Global Navigation Layout > Categories."
            maxWidth={224}
          >
            <div className="flex">
              <span className="block mt-[.2rem]">
                <Icon name="Tag" />
              </span>
              <span className="block ml-2 font-medium">
                {formData && formData?.content_tag_ids.length > 0 ? formatContentTags() : 'Add content tags'}
              </span>
            </div>
          </Tooltip>
        </button>
      </Tippy>
    </div>
  );
};

export default TitleContentMenu;
