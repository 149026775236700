import { useState } from 'react';

import { Input } from '@/components/Form';
import RichContent from '@/components/RichContent';
import { useDisableRefetchOnFocus } from '@/hooks';
import { TiptapState } from '@/interfaces/editor_versions';
import { EmailMessage } from '@/interfaces/email_message';
import { Button } from '@/ui/Button';

import useFetchPreview from '../hooks/useFetchPreview';

interface Props {
  emailMessage: EmailMessage | undefined;
  currentPublicationId: string;
  onUpdate: ({
    active,
    subject_line,
    preview_text,
    tiptap_state,
  }: {
    active?: boolean;
    subject_line?: string;
    preview_text?: string;
    tiptap_state: TiptapState;
  }) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const EditEmailForm = ({ currentPublicationId, emailMessage, onUpdate, onCancel, isLoading }: Props) => {
  useDisableRefetchOnFocus();

  const [subjectLine, setSubjectLine] = useState(emailMessage?.subject_line || undefined);
  const [previewText, setPreviewText] = useState(emailMessage?.preview_text || undefined);
  const [tiptapState, setTiptapState] = useState(emailMessage?.tiptap_state || {});

  const onFetchPreview = useFetchPreview(currentPublicationId, emailMessage?.id || '', tiptapState);

  return (
    <>
      <Input
        name="subject_line"
        labelText="Subject Line"
        value={subjectLine}
        onChange={(e) => setSubjectLine(e.target.value)}
      />
      <Input
        name="preview_text"
        labelText="Preview Text"
        value={previewText}
        onChange={(e) => setPreviewText(e.target.value)}
      />
      <RichContent
        name="content"
        labelText="Content"
        publicationId={currentPublicationId}
        initialValue={emailMessage?.tiptap_state}
        onChange={setTiptapState}
        onFetchPreview={onFetchPreview}
        allowPolls
      />
      <div className="flex flex-row gap-x-2 justify-end">
        <Button size="xs" variant="primary-inverse" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          size="xs"
          loading={isLoading}
          onClick={() => {
            onUpdate({
              active: emailMessage?.active,
              subject_line: subjectLine,
              preview_text: previewText,
              tiptap_state: tiptapState,
            });
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default EditEmailForm;
