import { useEffect, useRef, useState } from 'react';

interface Props {
  html: string;
  renderHeight: number;
  renderWidth: number;
}

const ScaledHTMLPreview = ({ html, renderHeight, renderWidth }: Props) => {
  const [scale, setScale] = useState(1);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (!iframeRef.current) return;

      const parentWidth = iframeRef.current.parentElement?.clientWidth || 1;
      const newScale = parentWidth / renderWidth;
      setScale(newScale);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [renderWidth]);

  return (
    <iframe
      ref={iframeRef}
      className="flex-shrink-0 pointer-events-none"
      srcDoc={html}
      title="preview"
      style={{
        transformOrigin: 'top left',
        transform: `scale(${scale})`,
      }}
      width={renderHeight}
      height={renderWidth}
      scrolling="no"
      tabIndex={-1}
    />
  );
};

export default ScaledHTMLPreview;
