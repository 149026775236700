import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { ShareIcon } from '@heroicons/react/24/outline';

import { useAsyncExport, useCopyToClipboard, useEmojiChart } from '@/hooks';
import { PostPoll } from '@/interfaces/post_poll';
import { Button } from '@/ui/Button';

interface NavProps {
  publicationId: string;
  postPoll: PostPoll;
}

const Nav = ({ publicationId, postPoll }: NavProps) => {
  const chartData = Object.keys(postPoll.metrics).map((metricKey) => ({
    label: metricKey,
    value: postPoll.metrics[metricKey],
  }));

  const emojiChart = useEmojiChart({
    data: chartData,
    title: postPoll.poll_question,
    describes: 'Votes',
    signature: 'via @beehiiv polls',
  });
  const copy = useCopyToClipboard();

  const handleCopyEmojiChart = () => {
    copy({ text: emojiChart, onSuccessText: 'Copied results to clipboard' });
  };

  const { isExporting, startExport } = useAsyncExport(publicationId, {
    exportType: 'post_poll_responses',
    resourceType: 'post_poll',
    resourceId: postPoll.id || '',
  });

  return (
    <div className="pb-4 border-b border-gray-200">
      <div className="flex items-end justify-between flex-wrap sm:flex-nowrap">
        <h1 className="text-xl font-bold">{postPoll.poll_name}</h1>

        <div className="flex-shrink-0 flex space-x-2 items-end">
          <Button variant="primary-inverse" onClick={startExport} loading={isExporting} Icon={ArrowDownTrayIcon}>
            Export Results
          </Button>
          <Button variant="primary-inverse" onClick={handleCopyEmojiChart} Icon={ShareIcon}>
            Share Results
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Nav;
