export const isInternalLink = (publicationUrl: string, url: string) => {
  try {
    const pubUrl = new URL(publicationUrl);
    const parsedUrl = new URL(url);
    const hostNamesMatch = pubUrl.hostname === parsedUrl.hostname;
    return hostNamesMatch;
  } catch (error) {
    return false;
  }
};
