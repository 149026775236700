import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import SlideOver from '../../../../components/SlideOver';

import type { GroupProps, ItemProps, NavigationProps } from './types';

const Navigation = ({ children }: NavigationProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  // close menu on route change
  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <>
      {/* MOBILE */}
      <nav className="lg:hidden border-b border-gray-100 w-full p-4 flex items-center">
        <button type="button" onClick={() => setMenuOpen(true)}>
          <Bars3Icon className="w-6 h-6 text-gray-700" />
        </button>
        <div className="px-4 flex items-center space-x-2">
          <h1 className="text-lg font-bold">beehiiv</h1>
        </div>

        <SlideOver isOpen={menuOpen} onClose={() => setMenuOpen(false)} headerText="Menu" position="left">
          <ul className="divide-y divide-gray-100 -mx-4">{children}</ul>
        </SlideOver>
      </nav>

      {/* DESKTOP */}
      <nav className="w-64 hidden lg:block max-h-screen min-h-screen sticky top-0 space-y-6 bg-white justify-between flex-col basis-56 flex-shrink-0">
        <div>
          <div className="px-4 pt-2 flex items-center space-x-2">
            <h1 className="font-bold">beehiiv</h1>
          </div>
          <ul className="divide-y divide-gray-100">{children}</ul>
        </div>
      </nav>
    </>
  );
};

Navigation.Group = ({ children }: GroupProps) => {
  return <div className="p-2">{children}</div>;
};

Navigation.Item = ({ item }: ItemProps) => {
  const location = useLocation();
  const current = location.pathname.startsWith(item.href) && item.href !== '/';

  return (
    <li>
      <Link
        to={item.href}
        type="button"
        className={cx(
          current ? 'bg-primary-50 text-primary-700' : 'text-gray-500 hover:bg-gray-50',
          'w-full group flex items-center px-2 py-1 text-lg sm:text-sm font-medium rounded duration-100 space-x-2'
        )}
      >
        {item.icon && (
          <item.icon className={cx('w-5 h-5', current ? 'text-primary-600' : 'text-gray-400')} aria-hidden="true" />
        )}
        <span>{item.name}</span>
      </Link>
    </li>
  );
};

export default Navigation;
