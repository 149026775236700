interface HeaderProps {
  title: string;
  description?: React.ReactNode | string;
  divider?: boolean;
  children?: React.ReactNode;
}

const Section: React.FC<HeaderProps> = ({ title, description, divider = true, children }) => (
  <section>
    <div className="relative my-6">
      {divider && (
        <div className="absolute inset-0 flex items-center" aria-hidden>
          <div className="w-full border-t border-gray-300" />
        </div>
      )}
      <div className="relative flex justify-start">
        <h2 className="pr-3 bg-white text-lg font-medium text-gray-900">{title}</h2>
      </div>
    </div>
    {description && <p className="text-sm text-gray-500">{description}</p>}
    {children}
  </section>
);

export default Section;
