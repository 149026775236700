import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowSquareOut, X } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

import { useCurrentPublication } from '@/hooks';
import { Publication } from '@/interfaces/publication';
import DiscordIcon from '@/pages/Grow/Boosts/Dashboard/components/ReviewBoostModalV3/icons/DiscordIcon';
import FacebookIcon from '@/pages/Grow/Boosts/Dashboard/components/ReviewBoostModalV3/icons/FacebookIcon';
import InstagramIcon from '@/pages/Grow/Boosts/Dashboard/components/ReviewBoostModalV3/icons/InstagramIcon';
import LinkedInIcon from '@/pages/Grow/Boosts/Dashboard/components/ReviewBoostModalV3/icons/LinkedInIcon';
import TiktokIcon from '@/pages/Grow/Boosts/Dashboard/components/ReviewBoostModalV3/icons/TiktokIcon';
import TwitterIcon from '@/pages/Grow/Boosts/Dashboard/components/ReviewBoostModalV3/icons/TwitterIcon';
import YoutubeIcon from '@/pages/Grow/Boosts/Dashboard/components/ReviewBoostModalV3/icons/YoutubeIcon';

import { cn } from '../../../../../_utils/cn';
import { Button } from '../../../../UI/Button';
import { Checkbox } from '../../../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Label } from '../../../../UI/Label';
import { Switch } from '../../../../UI/Switch';
import { Text } from '../../../../UI/Text';
import { SOCIALS } from '../../../dummyData/socials';

type Social = {
  id: string;
  display: string;
  key: string;
  icon: React.ReactNode;
  url: string;
};

const getSocialsArray = (publication: Publication | undefined, isFakeData?: boolean) => {
  if (!publication) return {};

  const socials: Social[] = [
    {
      id: 'twitter',
      key: 'twitter_url',
      display: 'Twitter',
      icon: <TwitterIcon className="w-10 h-10" />,
      url: isFakeData ? SOCIALS.twitter_url : publication.twitter_url,
    },
    {
      id: 'facebook',
      key: 'facebook_url',
      display: 'Facebook',
      icon: <FacebookIcon className="w-10 h-10" />,
      url: isFakeData ? SOCIALS.facebook_url : publication.facebook_url,
    },
    {
      id: 'instagram',
      key: 'instagram_url',
      display: 'Instagram',
      icon: <InstagramIcon className="w-10 h-10" />,
      url: isFakeData ? SOCIALS.instagram_url : publication.instagram_url,
    },
    {
      id: 'tiktok',
      key: 'tiktok_url',
      display: 'TikTok',
      icon: <TiktokIcon className="w-10 h-10" />,
      url: isFakeData ? SOCIALS.tiktok_url : publication.tiktok_url,
    },
    {
      id: 'youtube',
      key: 'youtube_url',
      display: 'Youtube',
      icon: <YoutubeIcon className="w-10 h-10" />,
      url: isFakeData ? SOCIALS.youtube_url : publication.youtube_url,
    },
    {
      id: 'linkedin',
      key: 'linkedin_url',
      display: 'LinkedIn',
      icon: <LinkedInIcon className="w-10 h-10" />,
      url: isFakeData ? SOCIALS.linkedin_url : publication.linkedin_url,
    },
    {
      id: 'discord',
      key: 'discord_url',
      display: 'Discord',
      icon: <DiscordIcon className="w-10 h-10" />,
      url: isFakeData ? SOCIALS.discord_url : publication.discord_url,
    },
  ];

  return socials
    .filter((social) => social.url)
    .map((social) => ({
      id: social.id,
      display: social.display,
      url: social.url,
      icon: social.icon,
    })) as Social[];
};

const SocialsSelectionModal = ({
  editor,
  node,
  isOpen,
  onClose,
  pos,
}: {
  editor: Editor;
  node: ProseMirrorNode;
  pos: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [selectedSocials, setSelectedSocials] = useState<Social[]>([]);
  const [showFakeData, setShowFakeData] = useState(false);

  const isSubmitDisabled = selectedSocials.length === 0;
  const selectionCount = selectedSocials.length;
  const selectionLabel = selectionCount <= 1 ? 'social' : 'socials';

  const { data: currentPublication } = useCurrentPublication();
  const pubSocials = useMemo(
    () => getSocialsArray(currentPublication, showFakeData) as Social[],
    [currentPublication, showFakeData]
  );

  const socials = pubSocials;

  const selectedSocialsUrls = useMemo(() => {
    return selectedSocials.reduce((acc, social) => {
      if (social.url) {
        acc[`${social.id}_url`] = social.url;
      }
      return acc;
    }, {} as Record<string, string>);
  }, [selectedSocials]);

  const handleSubmit = useCallback(() => {
    const updatedAttributes = {
      ...node?.attrs,
      insertedFromSidebar: false,
      data: selectedSocialsUrls,
    };

    editor?.commands.command(({ tr }) => {
      tr.setNodeAttribute(pos, 'insertedFromSidebar', false);
      tr.setNodeAttribute(pos, 'hasFakeData', false);
      tr.setNodeAttribute(pos, 'data', updatedAttributes.data);
      return true;
    });

    onClose();
  }, [selectedSocialsUrls, editor, node, pos, onClose]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                Select socials
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-2">
            {selectedSocials.map((social) => (
              <button
                type="button"
                key={social.id}
                className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1"
                onClick={() => setSelectedSocials(selectedSocials.filter((p) => p.id !== social.id))}
              >
                <Text
                  weight="regular"
                  variant="accent"
                  size="3xs"
                  as="span"
                  className="line-clamp-1 max-w-[150px] truncate"
                >
                  {social.display}
                </Text>
                <X className="w-3 h-3" />
              </button>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full overflow-y-auto no-scrollbar">
          <div className="mb-3">
            <Link to="/website_builder_v2/settings/socials" className="flex items-center gap-1 hover:underline">
              Manage socials
              <ArrowSquareOut className="inline-block" />
            </Link>
          </div>
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-32">
              {socials.map((social: any) => {
                const isSelected = selectedSocials.includes(social.url);

                return (
                  <Label
                    key={social.id}
                    htmlFor={social.id}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md focus:outline-none relative overflow-hidden',
                      isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    <div className="w-full h-36 overflow-hidden flex items-center justify-center">{social.icon}</div>
                    <div className="flex justify-between items-center gap-2 p-3">
                      <div className="flex gap-2 items-center">
                        <div className="flex flex-col">
                          <Text weight="semibold" variant="primary" size="xs" as="span" className="line-clamp-1">
                            {social.display}
                          </Text>
                        </div>
                      </div>
                      <Checkbox
                        id={social.id}
                        className={cn(isSelected ? 'opacity-100' : 'opacity-0')}
                        checked={Boolean(isSelected)}
                        onCheckedChange={() => {
                          if (isSelected) {
                            setSelectedSocials(selectedSocials.filter((p) => p.id !== social.id));
                          } else {
                            setSelectedSocials([...selectedSocials, social]);
                          }
                        }}
                      />
                    </div>
                  </Label>
                );
              })}
            </div>
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
          <div className="flex justify-between items-center w-full">
            <div className="flex gap-2 items-center">
              <Switch
                id="show-fake-data"
                checked={showFakeData}
                onCheckedChange={() => setShowFakeData(!showFakeData)}
                labelText="Dummy data"
                labelClassName="whitespace-nowrap"
              />
            </div>
            <div className="flex gap-2 w-full justify-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit} isDisabled={isSubmitDisabled}>
                Select {selectionLabel}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SocialsSelectionModal;
