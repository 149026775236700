import { useQuery } from 'react-query';

import api from '../../services/swarm';

type PublicationSubscriberCounts = {
  num_active_free_subscribers: number;
  num_active_paid_subscribers: number;
};

const usePublicationSubscriberCounts = (publicationId: string) =>
  useQuery<PublicationSubscriberCounts>(
    ['publications-subscriber-counts', publicationId],
    () => api.get(`/publications/${publicationId}/subscriber_counts`).then((res) => res.data),
    {
      enabled: !!(publicationId && publicationId.length),
    }
  );

export default usePublicationSubscriberCounts;
