import { useEffect, useState } from 'react';

import { MultiSelect } from '@/components/Form';
import { useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { LayoutPageData } from '@/routes/website_builder';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import Switch from '@/ui/Switch';

interface Props {
  isv2?: boolean;
}

const CategoriesGroup = ({ isv2 = false }: Props) => {
  const { handleSaved } = usePageContext<LayoutPageData>();
  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: any = webTemplateRequest;

  const webTemplateMutation = useUpdateWebTemplate();

  const optionsEndpoints = webTemplate?.enable_customize_category_ids ? 'content_tags' : '';
  const publicationId = useCurrentPublicationId();
  const contentTags = useOptions(publicationId, optionsEndpoints);

  const currentTags = webTemplate?.category_ids ? webTemplate?.category_ids : [];
  const [selectedTags, setSelectedTags] = useState<string[]>(currentTags);
  const options = contentTags?.data?.options.map((option: any) => [option.id, option.display]);

  useEffect(() => {
    setSelectedTags(webTemplate?.category_ids);
  }, [webTemplate?.category_ids]);

  const handleToggleCustomizeCategories = async (_: string, value: boolean) => {
    await webTemplateMutation.mutateAsync({ enable_customize_category_ids: value });
    handleSaved();
  };

  const handleChangeInSelectedTags = (selected: string[]) => {
    setSelectedTags(selected);
    webTemplateMutation.mutateAsync({ category_ids: selected });
  };

  return (
    <div className="flex flex-col gap-y-2">
      {!isv2 && (
        <div className="flex flex-col">
          <Switch
            size="small"
            name="categories-group-enable-customize-category-ids"
            checked={webTemplate?.enable_customize_category_ids || false}
            onChange={handleToggleCustomizeCategories}
            variant="primary"
          />
          <p className="mt-2 text-xs text-gray-500">
            By default this group will be populated with all the categories on your site. Select the toggle to
            customize.
          </p>
        </div>
      )}

      <div>
        {options?.length > 0 && selectedTags ? (
          <MultiSelect
            buttonClassName="!py-1 px-1"
            options={options}
            currentSelections={selectedTags}
            inputName="content_tags"
            onChange={handleChangeInSelectedTags}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CategoriesGroup;
