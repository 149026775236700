import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Tier } from '@/interfaces/tier';
import api from '@/services/swarm';

interface Props {
  tierId?: string;
  onSuccess?: (data: Tier) => void;
}

const useTier = ({ tierId, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<Tier>(
    ['publication_settings', 'premium', 'tiers', currentPublicationId, tierId],
    () =>
      api
        .get(`/tiers/${tierId}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!currentPublicationId && !!tierId,
      onSuccess: (data) => onSuccess?.(data),
    }
  );
};

export default useTier;
