import { Button } from '../../../ui/Button';
import { Icon } from '../../../ui/Icon';
import { Panel, PanelFooter, PanelHeader, PanelSection } from '../../../ui/Panel';
import { Divider } from '../../../ui/Toolbar';
import { tones } from '../../AiChangeTextTone/constants';
import Styled from '../styled';

export const ChangeTextTonePanel = ({
  attrs,
  setIsPanelOpen,
  valueCallback,
  setCurrentToneName,
  setHasTextToneChanges,
  tone,
  setTone,
  resetTone,
}: {
  attrs: any;
  valueCallback: Function;
  setIsPanelOpen: Function;
  setCurrentToneName: Function;
  setHasTextToneChanges: Function;
  tone: string | undefined;
  setTone: Function;
  resetTone: Function;
}) => {
  const currentTone = tones.find((t) => t.value === tone);
  const currentToneName = currentTone?.label;
  const hasChanges = !!currentToneName;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Panel tabIndex={-1} {...attrs}>
      <PanelHeader>
        <Button
          $variant="quaternary"
          $size="small"
          onClick={() => setIsPanelOpen(false)}
          $leftSlot={<Icon name="ChevronLeft" />}
          $fullWidth
        >
          Tone of voice
        </Button>
      </PanelHeader>
      <PanelSection>
        <Styled.ButtonWrapper>
          {tones.map((t) => (
            <Button
              key={t.name}
              $variant="quaternary"
              $size="small"
              onClick={() => {
                valueCallback(t.value);
                setTone(t.value);
                setCurrentToneName(t.label);
                setIsPanelOpen(false);
                setHasTextToneChanges(true);
              }}
              $fullWidth
              $active={t.value === tone}
            >
              {t.label}
            </Button>
          ))}
        </Styled.ButtonWrapper>
      </PanelSection>
      {hasChanges && (
        <PanelFooter>
          <Divider />
          <Button
            $variant="quaternary"
            $size="small"
            $rightSlot={<Icon name="Reset" />}
            onClick={() => {
              resetTone();
            }}
            $fullWidth
          >
            Reset
          </Button>
        </PanelFooter>
      )}
    </Panel>
  );
};

export default ChangeTextTonePanel;
