export function dateToDateString(dateString: string, options?: Intl.DateTimeFormatOptions): string {
  try {
    const date = new Date(dateString);
    const intlOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short',
      ...(options || {}),
    };

    const formatter = new Intl.DateTimeFormat(undefined, intlOptions);
    const formattedDate = formatter.format(date);

    // Convert the first letter of the month to uppercase
    return formattedDate.replace(/^\w/, (c) => c.toUpperCase());
  } catch (e) {
    return 'Invalid Date';
  }
}
