import { useState } from 'react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useUpdateAutomation } from '@/hooks/useAutomations';
import useResourcePermissions from '@/hooks/useResourcePermissions/useResourcePermissions';
import { Automation, AutomationEnrolmentSettings, AutomationEnrolmentType } from '@/interfaces/automations/automation';

import { DEFAULT_ENROLMENT_SETTINGS } from '../constants';

export default function useUpdateAutomationForm(automation: Automation) {
  const [currentPublicationId] = useCurrentPublicationState();
  const automationMutation = useUpdateAutomation({ id: automation.id });

  const hasEnrolmentSettings = automation?.enrolment_settings && Object.keys(automation?.enrolment_settings).length > 0;

  const [name, setName] = useState(automation?.name || '');
  const [description, setDescription] = useState(automation?.description || '');
  const [enrolmentType, setEnrolmentType] = useState<AutomationEnrolmentType>(
    automation?.enrolment_type || AutomationEnrolmentType.LIMITED
  );
  const [enrolmentSettings, setEnrolmentSettings] = useState<AutomationEnrolmentSettings>(
    hasEnrolmentSettings ? automation.enrolment_settings : DEFAULT_ENROLMENT_SETTINGS
  );
  const [isSaving, setIsSaving] = useState(false);
  const canSave = !!name;
  const {data: recordPermissions, isSuccess: recordPermissionSuccess} = useResourcePermissions({
    resourceType: 'Automation',
    resourceId: automation.id,
    policyClass: "Api::V2::AutomationPolicy",
  });

  const canEdit = recordPermissionSuccess && recordPermissions.includes('update');

  const handleSave = (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSaving(true);

    const formData = new FormData();
    formData.set('publication_id', currentPublicationId);
    formData.set('automation[name]', name);
    formData.set('automation[description]', description);
    formData.set('automation[enrolment_type]', enrolmentType);

    if (enrolmentSettings.max_enrolments !== undefined) {
      formData.set('automation[enrolment_settings][max_enrolments]', enrolmentSettings.max_enrolments.toString());
    }

    if (enrolmentSettings.seconds_since_completed !== undefined) {
      formData.set(
        'automation[enrolment_settings][seconds_since_completed]',
        enrolmentSettings.seconds_since_completed.toString()
      );
    }

    if (enrolmentSettings.seconds_since_entered !== undefined) {
      formData.set(
        'automation[enrolment_settings][seconds_since_entered]',
        enrolmentSettings.seconds_since_entered.toString()
      );
    }

    automationMutation.mutateAsync(formData).then(() => setIsSaving(false));
  };

  return {
    currentPublicationId,
    name,
    setName,
    description,
    setDescription,
    enrolmentType,
    setEnrolmentType,
    enrolmentSettings,
    setEnrolmentSettings,
    isSaving,
    setIsSaving,
    canSave,
    canEdit,
    handleSave,
  };
}
