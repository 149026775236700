import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { useCreateDraft } from '../../hooks';

const TEMPLATE_RESOURCES = ['posts'];
type TemplateResource = 'posts';

const Container = () => {
  const navigate = useNavigate();
  const { resourceType } = useParams();

  const createPostDraftMutation = useCreateDraft();
  const [isCreatingDraft, setIsCreatingDraft] = useState(false);

  const runMutation = (postTemplateId: string) => {
    return createPostDraftMutation.mutateAsync({ postTemplateId });
  };

  const onSelect = (postTemplateId: string) => {
    setIsCreatingDraft(true);

    runMutation(postTemplateId).then(() => setIsCreatingDraft(false));
  };

  // Ensure that they get redirected to a valid spot
  useEffect(() => {
    if (resourceType && !TEMPLATE_RESOURCES.includes(resourceType)) navigate('/templates/posts');
  }, [navigate, resourceType]);

  return <Outlet context={{ resourceType, onSelect, isCreatingDraft }} />;
};

type ContextType = {
  resourceType: TemplateResource;
  isCreatingDraft: boolean;
  onSelect: (postTemplateId: string) => void;
};

export const useTemplateViewContext = () => useOutletContext<ContextType>();

export default Container;
