import { ReactNode } from 'react';
import { ChevronDoubleRightIcon, InformationCircleIcon, PlayCircleIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { getColor } from '@/components/DesignTokens';
import { AutomationState } from '@/interfaces/automations/automation';
import { type BadgeProps } from '@/ui/Badge';

export const badgeAttributesByType: {
  [key in AutomationState]: {
    type: BadgeProps['type'];
    icon: ReactNode;
  };
} = {
  [AutomationState.RUNNING]: {
    type: 'success',
    icon: <PlayCircleIcon className={cx('w-3 h-3', getColor('success/500', 'text'))} />,
  },
  [AutomationState.FINISHING]: {
    type: 'warning',
    icon: <ChevronDoubleRightIcon className={cx('w-3 h-3', getColor('warning/500', 'text'))} />,
  },
  [AutomationState.INACTIVE]: {
    type: 'information',
    icon: <InformationCircleIcon className={cx('w-3 h-3', getColor('information/500', 'text'))} />,
  },
};
