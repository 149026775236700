import cx from 'classnames';
import styled from 'styled-components';

interface StackProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  gap: 0 | 1 | 2 | 4 | 8 | 12 | 16 | 24 | 32;
  direction?: 'row' | 'col';
}

const StyledStack = styled.div``;

const Stack: React.FC<StackProps> = ({ className = 'flex', children, gap, direction = 'row' }: StackProps) => {
  const dir = direction === 'row' ? 'x' : 'y';
  const flex = direction === 'row' ? 'flex-row' : 'flex-col';
  const classes = `flex ${flex} space-${dir}-${gap}`;

  return <StyledStack className={cx(classes, className)}>{children}</StyledStack>;
};

export default Stack;
