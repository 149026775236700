import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { DeletePublicationSubscriberPreferenceParams } from '@/interfaces/subscribers_preferences';
import api from '@/services/swarm';

const useDeleteSubscriberPreference = (onSuccess?: (response: any) => void) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    (params: DeletePublicationSubscriberPreferenceParams) =>
      api.delete(`/publication_subscriber_preferences/${params.id}`, {
        params: {
          publication_id: currentPublicationId,
        }
      }),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        queryClient.invalidateQueries([currentPublicationId, 'subscriber_preferences']);
        toast.success('Preference deleted successfully!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Could not delete preference.');
      },
    }
  );
};

export default useDeleteSubscriberPreference;
