import LoadingBox from '@/components/LoadingBox';
import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import useSvixMessageAttempts from '@/hooks/webhooks/useSvixMessageAttempts';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import BodyContainer from '../../../_components/BodyContainer';
import CardHeader from '../../../_components/CardHeader';

import MessageListItem from './MessageListItem';

type Props = {
  endpointId: string;
  publicationId: string;
};

const Messages = ({ endpointId, publicationId }: Props) => {
  const messageAttemptsQuery = useSvixMessageAttempts(publicationId, { endpointId });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = messageAttemptsQuery;
  const messageAttempts = data?.pages.flatMap((page) => page.message_attempts) || [];
  const noResults = !isLoading && !isError && messageAttempts.length === 0;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <BodyContainer>
        <CardHeader title="Message Attempts" />
        {noResults ? (
          <EmptyCard title="No Message Attempts" />
        ) : (
          <Items>
            <ItemHeaders>
              <ItemHeader>Status</ItemHeader>
              <ItemHeader>Event Type</ItemHeader>
              <ItemHeader>Message ID</ItemHeader>
              <ItemHeader>Timestamp</ItemHeader>
              <ItemHeader>&nbsp;</ItemHeader>
            </ItemHeaders>
            <ItemsBody>
              {messageAttempts.map((messageAttempt) => (
                <MessageListItem
                  key={messageAttempt.id}
                  messageAttempt={messageAttempt}
                  publicationId={publicationId}
                />
              ))}
            </ItemsBody>
          </Items>
        )}
        {hasNextPage && (
          <div className="flex justify-start mt-8 w-fit">
            <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
          </div>
        )}
      </BodyContainer>
    </LoadingBox>
  );
};

export default Messages;
