import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { AssignedRole } from '@/interfaces/role';
import api from '@/services/swarm';

interface IRolesContext {
  roles?: AssignedRole[];
  isLoading: boolean;
  reloadRoles: () => void;
}

const RolesContext = createContext<IRolesContext | undefined>(undefined);

RolesContext.displayName = 'RolesContext';

const RolesProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState<AssignedRole[]>();
  const publicationId = useCurrentPublicationId();

  const reloadRoles = useCallback(() => {
    if (!publicationId || publicationId === '') {
      return;
    }

    const params = {
      publication_id: publicationId,
    };

    setIsLoading(true);

    api
      .get('/assigned_roles', { params })
      .then((res) => {
        const dataCopy = { ...res.data };
        setRoles(dataCopy.assigned_roles);
      })
      .finally(() => setIsLoading(false));
  }, [publicationId]);

  useEffect(() => {
    reloadRoles();
  }, [reloadRoles]);

  return useMemo(() => (
    <RolesContext.Provider
      value={{
        reloadRoles,
        roles,
        isLoading,
      }}
    >
      {children}
    </RolesContext.Provider>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [reloadRoles, roles, isLoading]);
};

function useRoles() {
  const context = React.useContext(RolesContext);
  if (context === undefined) {
    throw new Error(`useRoles must be used within a RolesProvider`);
  }
  return context;
}

export { RolesContext, RolesProvider, useRoles };
