import { Tag } from '@phosphor-icons/react';
import { TagsElement, TTagsAttributes } from '@shared/dream-components';

import TagsSelectionModal from '../../../../extensions/Tags/modals/TagsSelectionModal';
import { AttributeSettingProps } from '../../../types';
import { DataModalSettings } from '../../DataModalSettings';
import { StructureSettings } from '../../StructureSettings';
import ActionRenderer from '../ActionRenderer';

const TagsActions = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNode, activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const { insertedFromSidebar } = activeNodeAttributes;

  const handleCloseModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', false);
      return true;
    });
  };

  const handleOpenModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', true);
      return true;
    });
  };

  return (
    <ActionRenderer nodeType="tags" activeNodeType={activeNodeType}>
      <StructureSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        allowedProperties={['div', 'name']}
        predefinedPatterns={[]}
      >
        {(structure: any) => (
          <TagsElement
            element={{
              type: 'tags',
              attrs: {
                ...(activeNode?.attrs as TTagsAttributes),
                cardStructure: structure as any,
                columns: '1',
                // We only need one tag to display the structure
                data: activeNode?.attrs?.data?.slice(0, 1),
              },
              content: [{ id: 'text', text: '' }],
            }}
          >
            {null}
          </TagsElement>
        )}
      </StructureSettings>
      <DataModalSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        handleOpenModal={handleOpenModal}
        text="Tags"
        Icon={Tag}
      />
      {insertedFromSidebar && activeNode && (
        <TagsSelectionModal
          pos={activeNodePos}
          editor={editor}
          node={activeNode}
          isOpen={Boolean(insertedFromSidebar)}
          onClose={handleCloseModal}
        />
      )}
    </ActionRenderer>
  );
};

export default TagsActions;
