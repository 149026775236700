import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import moment from 'moment-mini';

import { Post, PostErrors, PostStatus, PostWarnings } from '../../../../interfaces/post';
import { useEditorContext } from '../EditorContext';
import { usePostContext } from '../v2/PostContext';

interface Props {
  errors: PostErrors;
  warnings: PostWarnings;
  post: Post;
  isV2?: boolean;
}

const StatusBadge: React.FunctionComponent<Props> = ({ errors, isV2, post, warnings = {} }: Props) => {
  const {
    provider,
    unsavedChanges: editorContextUnsavedChanges,
    isSaving: editorContextIsSaving,
    collaborationEnabled,
  } = useEditorContext();

  const { unsavedChanges: postContextUnsavedChanges, isSaving: postContextIsSaving } = usePostContext();

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const unsavedChanges = useMemo(() => {
    return (isV2 ? postContextUnsavedChanges : editorContextUnsavedChanges) || editorContextUnsavedChanges;
  }, [isV2, postContextUnsavedChanges, editorContextUnsavedChanges]);

  const isSaving = useMemo(() => {
    return (isV2 ? postContextIsSaving : editorContextIsSaving) || editorContextIsSaving;
  }, [isV2, postContextIsSaving, editorContextIsSaving]);

  useEffect(() => {
    provider?.on('unsyncedChanges', (unsyncedChanges: number) => {
      const hasNewChanges = unsyncedChanges > 0;

      if (hasNewChanges) {
        setHasChanges(true);
      } else {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }

        // Fake some saving delay to prevent flickering
        timerRef.current = setTimeout(() => {
          setHasChanges(false);
        }, 500);
      }
    });
  }, [provider]);

  const hasWarnings = Object.keys(warnings).length > 0;

  const shouldWarn = unsavedChanges || isSaving;

  let statusText = shouldWarn ? 'Unsaved' : `Saved${hasWarnings ? ' with warnings' : ''}`;
  let statusClasses = shouldWarn
    ? 'animate-pulse bg-yellow-500'
    : `bg-green-500 ${hasWarnings ? 'duration-1000 animate-pulse' : ''}`;
  let lastChangeString = 'unknown';

  if (collaborationEnabled) {
    const lastChangeTimestamp = provider?.document.getMap('meta')?.toJSON()?.lastChange;
    const isSynced = provider?.isSynced && !hasChanges;

    if (lastChangeTimestamp) {
      lastChangeString = moment(lastChangeTimestamp).fromNow();
    }

    if (!provider) {
      statusText = 'Initializing';
      statusClasses = 'animate-pulse bg-yellow-500';
    } else if (provider?.status === 'connected') {
      statusText = isSynced ? `Synced${hasWarnings ? ' with warnings' : ''}` : 'Syncing';
      statusClasses = isSynced
        ? `bg-green-500 ${hasWarnings ? 'animate-pulse duration-1000' : ''}`
        : 'animate-pulse bg-yellow-500';
    } else {
      statusText = `${provider.status.charAt(0).toUpperCase()}${provider.status.slice(1)}`;
      statusClasses = 'animate-pulse bg-red-500';
    }
  }

  const isPublished = post.status === PostStatus.PUBLISHED;
  const isScheduled = post.status === PostStatus.SCHEDULED;
  const hasErrors = Object.keys(errors).length > 0;

  // Show error.base message if present (i.e. post stale) otherwise show generic
  // message b/c the error is also shown next to the necessary form field
  const error = errors.base || 'Post cannot save';

  return (
    <div className="flex relative rounded-md bg-gray-100 items-center py-1 px-2 space-x-2 text-xs text-center text-gray-500">
      <p data-tip data-for="status-indicator" className="capitalize font-medium">
        {post.status}
      </p>
      <p className="text-gray-300">|</p>
      <div data-tip data-for="saving-indicator" className="flex items-center space-x-2">
        {hasErrors ? (
          <>
            <p>Error</p>
            <span className="relative flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
              <span className="relative inline-flex rounded-full h-2 w-2 bg-red-400" />
            </span>
          </>
        ) : (
          <>
            <p>{statusText}</p>
            <div className={cx('w-2 h-2 rounded-full transition duration-200', statusClasses)} />
          </>
        )}
      </div>
      <ReactTooltip offset={{ right: 30 }} id="saving-indicator" place="bottom" type="dark" effect="solid">
        {hasErrors ? (
          <div>{error}</div>
        ) : (
          <>
            {hasWarnings && (
              <div className="space-y-2 mb-2">
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-500 font-semibold">Warnings</span>
                </div>
                <hr className="border-gray-700" />
                <div className="flex items-center space-x-2">
                  <div className={cx('w-2 h-2 rounded-full bg-yellow-500')} />
                  <span className="text-sm text-gray-500">{warnings.base}</span>
                </div>
                <hr className="border-gray-700" />
              </div>
            )}
            <div className="space-y-2 relative">
              {collaborationEnabled ? (
                <>
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-500 font-semibold">Last change {lastChangeString}</span>
                  </div>
                  <hr className="border-gray-700" />
                  <div className="flex items-center space-x-2">
                    <div className={cx('w-2 h-2 rounded-full bg-green-500')} />
                    <span className="text-sm text-gray-500">Changes are up to date</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className={cx('w-2 h-2 rounded-full bg-green-500 duration-1000 animate-pulse')} />
                    <span className="text-sm text-gray-500">Changes are up to date with warnings</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className={cx('w-2 h-2 rounded-full transition duration-200 animate-pulse bg-yellow-500')} />
                    <span className="text-sm text-gray-500">Changes not synchronized</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className={cx('w-2 h-2 rounded-full transition duration-200 animate-pulse bg-red-500')} />
                    <span className="text-sm text-gray-500">Server disconnected</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center space-x-2">
                    <div className={cx('w-2 h-2 rounded-full bg-green-500')} />
                    <span className="text-sm text-gray-500">Changes are up to date</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className={cx('w-2 h-2 rounded-full bg-green-500 duration-1000 animate-pulse')} />
                    <span className="text-sm text-gray-500">Changes are up to date with warnings</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className={cx('w-2 h-2 rounded-full transition duration-200 animate-pulse bg-yellow-500')} />
                    <span className="text-sm text-gray-500">Saving / unsaved changes</span>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </ReactTooltip>
      {(isScheduled || isPublished) && (
        <ReactTooltip id="status-indicator" place="bottom" type="dark" effect="solid">
          <div className="space-y-2">
            <p className="text-sm text-gray-500">{moment(post.scheduled_at).format('LLL')}</p>
          </div>
        </ReactTooltip>
      )}
    </div>
  );
};

export default StatusBadge;
