import { BoostOfferStatus } from '@/interfaces/boosts/grow/boost_offer';
import Badge, { BadgeProps } from '@/ui/Badge';

const badgeAttributesByType: {
  [key in BoostOfferStatus]: {
    type: BadgeProps['type'];
    label: string;
  };
} = {
  [BoostOfferStatus.LIVE]: {
    type: 'success',
    label: 'Live',
  },
  [BoostOfferStatus.INACTIVE]: {
    type: 'warning',
    label: 'Paused',
  },
  [BoostOfferStatus.ACTIVE]: {
    type: 'info_blue',
    label: 'Active',
  },
  [BoostOfferStatus.ARCHIVED]: {
    type: 'information',
    label: 'Archived',
  },
  [BoostOfferStatus.DRAFT]: {
    type: 'information',
    label: 'Draft',
  },
};

interface Props {
  status: BoostOfferStatus;
}

const BoostOfferStatusBadge = ({ status }: Props) => (
  <Badge className="capitalize" type={badgeAttributesByType[status].type}>
    {badgeAttributesByType[status].label}
  </Badge>
);

export default BoostOfferStatusBadge;
