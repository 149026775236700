import { Extension } from '@tiptap/core';
import { AllSelection, NodeSelection, TextSelection } from '@tiptap/pm/state';

import { getIgnoreTextInputOnNodes, getNonDeletableNodes } from './plugins';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customBehaviors: {
      /**
       * Select the parent text block when selection is a cursor
       */
      selectTextBlock: () => ReturnType;
      /**
       * Focus doc
       */
      focusDoc: () => ReturnType;
    };
  }
}

interface CustomBehaviorsOptions {
  nonDeletableNodes: string[];
}

export const CustomBehaviors = Extension.create<CustomBehaviorsOptions>({
  name: 'customBehaviors',

  addOptions() {
    return {
      nonDeletableNodes: [],
    };
  },

  addCommands() {
    return {
      selectTextBlock:
        () =>
        ({ tr, dispatch }) => {
          if (!dispatch || !(tr.selection instanceof TextSelection) || !tr.selection.$cursor) {
            return false;
          }

          const { $cursor } = tr.selection;

          if (!$cursor.parent.isTextblock) {
            return false;
          }

          tr.setSelection(NodeSelection.create(tr.doc, $cursor.before()));

          dispatch(tr);

          return true;
        },
      focusDoc:
        () =>
        ({ chain }) => {
          return chain()
            .command(({ tr, dispatch }) => {
              tr.setSelection(new AllSelection(tr.doc));

              dispatch?.(tr);

              return true;
            })
            .focus(undefined, { scrollIntoView: false })
            .run();
        },
    };
  },

  addProseMirrorPlugins() {
    const plugins = [getIgnoreTextInputOnNodes()];

    if (this.options.nonDeletableNodes.length) {
      const nonDeletableNodesInSchema = this.options.nonDeletableNodes.filter(
        (nodeTypeName) => !!this.editor.schema.nodes[nodeTypeName]
      );

      if (nonDeletableNodesInSchema.length) {
        plugins.push(getNonDeletableNodes(nonDeletableNodesInSchema));
      }
    }

    return plugins;
  },
});
