import { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';

import { useSubscriberContext } from '../../_context/subscriberContext';
import useUpdateSubscriptionEmail from '../../_hooks/useUpdateSubscriptionEmail';

const EditableEmail = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');

  const { subscriber, subscription } = useSubscriberContext();
  const mutation = useUpdateSubscriptionEmail({ id: subscription.id });

  const clearModal = () => {
    setIsOpen(false);
    setEmail('');
  };

  const handleSubmit = () => mutation.mutate({ email }, { onSettled: clearModal });

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        onClose={clearModal}
        onProceed={handleSubmit}
        resourceId={subscriber.id}
        isWorking={mutation.isLoading}
        headerText="Update Subscriber Email"
        actionText="Update"
      >
        <Input
          className="w-full"
          name="email"
          labelText="New Email"
          value={email}
          helperText="Enter the new email address for this subscriber"
          placeholderText={subscriber.email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </ActionModal>
      <div className="flex space-x-1 items-center justify-between group w-full">
        <p className="mt-1 text-lg lg:text-xl font-bold">{subscriber.email}</p>
        <button
          onClick={() => setIsOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsOpen(true);
            }
          }}
          tabIndex={0}
          type="button"
        >
          <PencilSquareIcon className="group-hover:block hidden h-5 w-5 text-gray-400" />
        </button>
      </div>
    </>
  );
};

export default EditableEmail;
