import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface ApiResponse {
  id: string;
}

interface CreateParams {
  pan_mapping_file?: File;
}

interface Props {
  onSuccess: (data: ApiResponse) => void;
}

const useCreateStripeMigration = ({ onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, CreateParams>(
    ({ pan_mapping_file }) => {
      const formData = new FormData();

      formData.append('publication_id', currentPublicationId);

      if (pan_mapping_file) {
        formData.append('stripe_migration[pan_mapping_file]', pan_mapping_file);
      }

      return api.post('/stripe_migrations', formData).then((res) => res.data);
    },
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useCreateStripeMigration;
