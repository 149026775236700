import { useState } from 'react';
import { usePopper } from 'react-popper';

interface Props {
  align: 'start' | 'end';
}

const useWebBuilderOverlay = ({ align }: Props) => {
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: align === 'start' ? 'bottom-start' : 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          altBoundary: true,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: 'clippingParents',
          altBoundary: true,
        },
      },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['right-start'],
          fallbackPlacements: ['right-start'],
          altBoundary: true,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  return {
    styles,
    attributes,
    setReferenceElement,
    setPopperElement,
  };
};

export default useWebBuilderOverlay;
