import AdNetworkEventDetails from './AdNetworkEventDetails';
import PostEventDetails from './PostEventDetails';
import { EventDetailsProps } from './types';

const EventDetails = ({ event }: EventDetailsProps) => {
  switch (event.calendar) {
    case 'posts':
      return <PostEventDetails event={event} />;
    case 'ad_network':
      return <AdNetworkEventDetails event={event} />;
    default:
      return null;
  }
};

export default EventDetails;
