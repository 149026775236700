import { Breadcrumbs } from '@/components/Breadcrumbs';
import Grid from '@/components/Grid';
import { useSettings } from '@/context/settings-context';

import AcquisitionDetails from './_components/AcquisitionDetails';
import ActionsButton from './_components/ActionsButton';
import AutomationHistory from './_components/AutomationHistory';
import CustomFields from './_components/CustomFields';
import DropDown from './_components/Dropdown';
import GeneralDetails from './_components/GeneralDetails';
import Header from './_components/Header';
import Metrics from './_components/Metrics';
import SubscriberOverview from './_components/SubscriberOverview';
import SubscriberTags from './_components/SubscriberTags';
import { SubscriberProvider } from './_context/subscriberContext';

const Subscriber = () => {
  const { settings } = useSettings();
  return (
    <SubscriberProvider>
      <div className="flex flex-col xs:flex-row justify-between xs:items-center space-y-2 xs:space-y-0">
        <Breadcrumbs>
          <Breadcrumbs.Home />
          <Breadcrumbs.Item to="/subscribers">Subscribers</Breadcrumbs.Item>
          <Breadcrumbs.Item to=".">Subscriber Overview</Breadcrumbs.Item>
        </Breadcrumbs>
        <ActionsButton />
      </div>
      <Grid gap={4} className="grid-cols-12 mt-4">
        <div className="col-span-12 lg:col-span-6 xl:col-span-4">
          <div className="bg-white rounded-lg pb-8">
            <Header />
            <GeneralDetails />
            <DropDown title="Acquisition Details">
              <AcquisitionDetails />
            </DropDown>
            <DropDown title="Custom Fields">
              <CustomFields />
            </DropDown>
            {settings?.subscriber_tagging && (
              <DropDown title="Tags">
                <SubscriberTags />
              </DropDown>
            )}
            <DropDown title="Automation History">
              <AutomationHistory />
            </DropDown>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6 xl:col-span-8">
          <Metrics />
          <SubscriberOverview />
        </div>
      </Grid>
    </SubscriberProvider>
  );
};

export default Subscriber;
