import cx from 'classnames';

const MarkDownHelperText = () => {
  return (
    <div className="space-y-2 cursor-pointer">
      <span className="flex space-x-2">
        <div className="relative group">
          <svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.414 11H1.58597C1.37769 11 1.17146 10.9667 0.979043 10.9019C0.786625 10.8372 0.611789 10.7423 0.464519 10.6226C0.317248 10.503 0.200427 10.361 0.120724 10.2046C0.0410222 10.0483 0 9.88078 0 9.71158V1.28842C0 1.11922 0.0410222 0.951683 0.120724 0.795365C0.200427 0.639046 0.317248 0.497011 0.464519 0.37737C0.761945 0.135744 1.16534 0 1.58597 0H20.414C20.6223 0 20.8285 0.0333262 21.021 0.0980755C21.2134 0.162825 21.3882 0.257729 21.5355 0.37737C21.6828 0.497011 21.7996 0.639046 21.8793 0.795365C21.959 0.951683 22 1.11922 22 1.28842V9.71009C22.0001 9.87935 21.9592 10.047 21.8795 10.2034C21.7999 10.3598 21.6831 10.5019 21.5358 10.6216C21.3885 10.7413 21.2136 10.8363 21.0212 10.9011C20.8287 10.9659 20.6224 11 20.414 11ZM5.28777 8.42167V5.07028L7.40362 7.21889L9.51854 5.07028V8.42167H11.6344V2.57908H9.51854L7.40362 4.72769L5.28777 2.57908H3.17193V8.42316L5.28777 8.42167ZM19.4625 5.5H17.3466V2.57833H15.2317V5.5H13.1158L16.2887 8.50806L19.4625 5.5Z"
              fill="#D1D5DB"
            />
          </svg>

          <div
            className={cx(
              'p-3 bg-white shadow-2xl group-hover:absolute group-hover:top-4 group-hover:left-0 hidden group-hover:block z-10 rounded-md'
            )}
          >
            <div className="space-y-2 text-surface-900">
              <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.markdownguide.org/basic-syntax/">
                <span>Simple markdown supported:</span>
              </a>

              <div className="flex flex-col w-48">
                <span>*italics*</span>
                <span>**bold**</span>
                <span>***bold italics***</span>
                <span>~~strike through~~</span>
                <span>==highlight==</span>
                <span>1. numbered list</span>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};

export default MarkDownHelperText;
