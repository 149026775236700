import React from 'react';
import toast from 'react-hot-toast';

import { useWebsiteContext } from '@/context/website-context';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';

import { Switch } from '../../_components/UI/Switch';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';

const WebsiteSettingsSecurity = () => {
  const { site } = useWebsiteContext();
  const updateSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  return (
    <SettingsSection>
      <SettingsItem
        title="Cloudflare Captcha"
        description="Enable to prevent spam submission via Turnstile, Cloudfare’s CAPTCHA solution, on your website and embed forms. It will be invisible on the page so user experience will not be impacted."
      >
        <Switch
          id="captcha_enabled"
          labelText="Enable Stricter Site Security"
          checked={site?.draft_site_version?.settings?.captcha_enabled || false}
          onCheckedChange={(value: boolean) =>
            updateSiteVersion.mutate({
              settings: {
                captcha_enabled: value,
              },
            })
          }
        />
      </SettingsItem>
    </SettingsSection>
  );
};

export default WebsiteSettingsSecurity;
