import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const Styled = {
  Container: styled.div`
    display: flex;
    min-width: 18rem;
  `,
  Link: styled.a`
    align-self: center;
    color: ${colors.pink[6]};
    flex: 1;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    max-width: 25rem;
    overflow: hidden;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  IconWrapper: styled.span`
    align-self: center;
    color: ${colors.pink[6]};
    line-height: 0;
    margin-left: 0.375rem;

    svg path {
      fill: currentColor;
    }
  `,
};

export default Styled;
