import { SelectOptions } from '@/components/SelectAllCheckbox';

type NoneSelectedWithFilterProps = {
  currentSelectOptions: SelectOptions[];
};

const NoneSelectedWithFilter = ({ currentSelectOptions }: NoneSelectedWithFilterProps) => {
  const filters = currentSelectOptions.map((option) => option.toLowerCase()).join(', ');

  return <p>No posts match the selection filters: {filters}</p>;
};

export default NoneSelectedWithFilter;
