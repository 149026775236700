import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import ImportLimit from '../ImportLimit';
import ImportSubscriberList from '../ImportSubscriberList';

const StartImport = () => {
  const [startedNewImport, setStartedNewImport] = useState<boolean>(false);

  const handleCloseImportSteps = () => {
    setStartedNewImport(false);
  };

  const handleImportSuccess = useCallback(() => {
    toast.success('Email import has begun. Please check back soon.');
  }, []);

  const handleImportFailure = useCallback((errorMessage?: string) => {
    toast.error(errorMessage || 'Something went wrong');
  }, []);

  if (startedNewImport) {
    return (
      <ImportSubscriberList
        onClose={handleCloseImportSteps}
        onSuccess={handleImportSuccess}
        onFailure={handleImportFailure}
      />
    );
  }

  return (
    <div>
      <Button variant="primary" type="button" onClick={() => setStartedNewImport(true)}>
        <DocumentPlusIcon className="w-4 h-4 mr-2" />
        <span>Start New Import</span>
      </Button>

      <div className="mt-12">
        <ImportLimit />
      </div>
    </div>
  );
};

export default StartImport;
