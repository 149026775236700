import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from '@/services/swarm';

interface Variables {
  removeAll?: boolean;
  opportunityId?: string;
}

export default function useCampaignOpportunityGroupOpportunitiesDestroy({ id }: { id: string }) {
  const stagePublications = ({ removeAll, opportunityId }: Variables): Promise<void> =>
    api
      .delete(`/ad_network/internal/campaign_opportunity_groups/${id}/opportunities`, {
        data: {
          remove_all: removeAll,
          opportunity_id: opportunityId,
        },
      })
      .then((res) => res.data);

  return useMutation<void, MutationErrorResponse, Variables>(stagePublications, {
    onSuccess: () => {
      toast.success('Removed');
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
