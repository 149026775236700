import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';
import { Textarea } from '@/ui/Textarea';

import { ImageSelect, Input } from '../../../../../../components/Form';
import SlideOver from '../../../../../../components/SlideOver';
import { Testimonial } from '../../../../../../interfaces/testimonial';

interface Payload {
  name: string;
  description: string;
  message: string;
  picture: File | null;
  addToLandingPage: boolean;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isProcessing: boolean;
  onProceed: (payload: Payload) => void;
  testimonial?: Testimonial | null;
  ctaText: string;
  headerText: string;
}

const TestimonialForm = ({ isOpen, onClose, isProcessing, onProceed, testimonial, ctaText, headerText }: Props) => {
  const [nameValue, setNameValue] = useState(testimonial?.name || '');
  const [descriptionValue, setDescriptionValue] = useState(testimonial?.description || '');
  const [messageValue, setMessageValue] = useState(testimonial?.message || '');
  const [picture, setPicture] = useState<string | File | any>(testimonial?.picture_url || null);

  const handleFileChange = (file: File) => {
    setPicture(file);
  };

  const handleFileClear = () => {
    setPicture(null);
  };

  const handleClearForm = () => {
    setNameValue('');
    setDescriptionValue('');
    setMessageValue('');
    setPicture(null);
  };

  useEffect(() => {
    if (testimonial) {
      setNameValue(testimonial.name);
      setDescriptionValue(testimonial.description);
      setMessageValue(testimonial.message);
      setPicture(testimonial.picture_url);
    }
  }, [testimonial]);

  const handleSave = () => {
    if (!nameValue || !messageValue) {
      return toast.error('Name and message are required.');
    }

    onProceed({
      name: nameValue,
      description: descriptionValue,
      message: messageValue,
      picture,
      addToLandingPage: true,
    });

    onClose();
    return handleClearForm();
  };

  return (
    <div>
      <SlideOver
        isOpen={isOpen}
        onClose={() => {
          onClose();
          handleClearForm();
        }}
        bodyId={testimonial?.id}
        headerText={headerText}
      >
        <div className="space-y-4">
          <ImageSelect
            name="picture"
            dimensionSuggestion="800px x 800px recommended"
            onFileSelect={handleFileChange}
            onFileClear={handleFileClear}
            file={picture}
          />
          <Input
            required
            name="name"
            labelText="Name"
            placeholderText="John Doe"
            onChange={(e) => setNameValue(e.target.value)}
            value={nameValue}
            supportsMarkdown
          />
          <Input
            name="description"
            labelText="Description"
            placeholderText="Ex: CEO of Company X"
            helperText="This can be a job title, description, or even a social media handle that gives credibility and context to the testimonial."
            onChange={(e) => setDescriptionValue(e.target.value)}
            value={descriptionValue}
            supportsMarkdown
          />
          <Textarea
            required
            name="message"
            labelText="Message"
            placeholderText="'This is the best newsletter I've ever subscribed to!'"
            rows={4}
            onChange={(e) => setMessageValue(e.target.value)}
            value={messageValue}
            supportsMarkdown
          />
        </div>

        <div className="pt-8 w-full">
          <Button variant="primary" onClick={handleSave} loading={isProcessing}>
            {isProcessing ? 'Saving...' : ctaText}
          </Button>
        </div>
      </SlideOver>
    </div>
  );
};

export default TestimonialForm;
