import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { Downgrade } from '../../interfaces/downgrade';
import api from '../../services/swarm';

interface Props {
  organizationId: string | undefined;
  onSuccess?: (res: any) => void;
}

const useCreateDowngrade = ({ organizationId, onSuccess }: Props) =>
  useMutation(
    (downgrade: Downgrade) =>
      api.post(`/organizations/${organizationId}/downgrades`, {
        organization_id: organizationId,
        downgrade: { ...downgrade },
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong downgrading your plan.');
      },
    }
  );

export default useCreateDowngrade;
