import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import * as CardUI from '@/components/Card/CardUI';
import { Checkbox } from '@/components/Form';
import ProgressIndicator from '@/components/ProgressIndicator';
import { BodySm, TitleBase, TitleSm, Typography, TypographyStack } from '@/components/Typography';
import { useWalkthroughContext } from '@/context/walkthrough-context';
import { useCreateDraft } from '@/hooks';
import { useExtendedOnboarding } from '@/hooks/usePublicationDashboard';
import { ExtendedOnboardingStep } from '@/interfaces/onboarding';

const getOnboardingProgress = (steps: ExtendedOnboardingStep[]) => {
  const stepCount = steps?.length || 0;
  const completedSteps = steps?.filter((step) => step.completed === true).length || 0;
  const percentComplete = Math.round((completedSteps / stepCount) * 100);

  return {
    allStepsCompleted: percentComplete === 100 || stepCount === 0,
    percentComplete,
    stepsCount: stepCount,
    currentStepsCompletedCount: completedSteps,
  };
};

interface Props {
  isOnboardingCompleted: boolean;
  setIsOnboardingCompleted: (isOnboardingCompleted: boolean) => void;
}

const Onboarding = ({ isOnboardingCompleted, setIsOnboardingCompleted }: Props) => {
  const navigate = useNavigate();

  const { handleStartWalkthrough } = useWalkthroughContext();

  const createDraftMutation = useCreateDraft();
  const handleStartWriting = () => {
    createDraftMutation.mutateAsync({});
  };

  const extendOnboardingQuery = useExtendedOnboarding();
  const { data: steps } = extendOnboardingQuery || {};
  const onboardingSteps: ExtendedOnboardingStep[] = steps || [];
  const { allStepsCompleted, percentComplete, currentStepsCompletedCount, stepsCount } =
    getOnboardingProgress(onboardingSteps);

  useEffect(() => {
    if (allStepsCompleted && !isOnboardingCompleted) {
      setIsOnboardingCompleted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStepsCompleted]);

  if (allStepsCompleted) {
    return null;
  }

  return (
    <CardUI.Card hasPadding={false} className="overflow-hidden">
      <div className="bg-action-tertiary-50 p-6">
        <div className="space-y-4">
          <div className="flex justify-between space-x-4">
            <TypographyStack gap="0">
              <TitleBase>Get Ready to Publish 🚀</TitleBase>
              <BodySm>Let&#39;s get you all set up</BodySm>
            </TypographyStack>
          </div>

          <div>
            <ProgressIndicator value={percentComplete} showIconTracker={false} color="bg-action-tertiary-900" />
            <Typography weight="light" size="xs">
              {currentStepsCompletedCount}/{stepsCount} steps completed
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-2">
        {onboardingSteps?.length
          ? steps.map((step: ExtendedOnboardingStep) => {
              const isWalkthroughStep = step.id === 'walkthrough';
              const isFirstPostStep = step.id === 'first_post';

              const handleClick = () => {
                if (isWalkthroughStep) {
                  handleStartWalkthrough();
                } else if (isFirstPostStep) {
                  handleStartWriting();
                } else {
                  navigate(step?.redirect_to || '/');
                }
              };

              return (
                <button
                  key={step.id}
                  type="button"
                  className="px-6 py-3 flex justify-between items-center hover:bg-gray-50 transition-all"
                  onClick={handleClick}
                >
                  <div className="space-x-3 flex">
                    <div className="mt-1 pointer-events-none">
                      <Checkbox checked={step.completed} name={step.title} onChange={() => {}} color="tertiary" />
                    </div>
                    <TypographyStack gap="0">
                      <TitleSm>{step.title}</TitleSm>
                      <BodySm colorWeight="400" weight="normal">
                        {step.subtext}
                      </BodySm>
                    </TypographyStack>
                  </div>

                  <ChevronRightIcon className="h-3 w-3" />
                </button>
              );
            })
          : null}
      </div>
    </CardUI.Card>
  );
};

export default Onboarding;
