import Card from '@/components/Card';
import { Typography } from '@/components/Typography';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import { Button } from '@/ui/Button';

import { ENROLMENT_TYPE_OPTIONS } from '../constants';
import useUpdateAutomationForm from '../hooks/useUpdateAutomationForm';
import { useAutomationViewContent } from '..';

import MetadataForm from './MetadataForm';
import ReenrolmentForm from './ReenrolmentForm';

const AutomationViewSettings = () => {
  const { automation } = useAutomationViewContent();
  const {
    name,
    setName,
    description,
    setDescription,
    enrolmentType,
    setEnrolmentType,
    enrolmentSettings,
    setEnrolmentSettings,
    isSaving,
    canSave,
    canEdit,
    handleSave,
  } = useUpdateAutomationForm(automation);


  return (
    <div className="mt-40 py-5 px-4 md:px-10 md:mt-28 md:mb-36 lg:w-1/2 lg:px-0 lg:mx-auto">
      <form onSubmit={handleSave} className="space-y-6">
        <Card>
          <Typography token="font-medium/text/lg" className="block mb-4 leading-6 text-gray-900">
            Edit Automation
          </Typography>
          <MetadataForm
            automationName={name}
            automationDescription={description}
            onAutomationNameChange={setName}
            onAutomationDescriptionChange={setDescription}
            disabled={!canEdit}
          />

          <Button
            className="mt-6"
            type="button"
            disabled={!canSave || !canEdit}
            tooltip={!canEdit ? NO_PERMISSION_MESSAGE : undefined}
            loading={isSaving}
            onClick={handleSave}
          >
            Save Automation
          </Button>
        </Card>

        <Card>
          <ReenrolmentForm
            automationEnrolmentType={enrolmentType}
            automationEnrolmentTypeOptions={ENROLMENT_TYPE_OPTIONS}
            onAutomationEnrolmentTypeChange={setEnrolmentType}
            automationEnrolmentSettings={enrolmentSettings}
            onAutomationEnrolmentSettingsChange={setEnrolmentSettings}
            disabled={!canEdit}
          />

          <Button
            className="mt-6"
            type="button" disabled={!canSave || !canEdit}
            tooltip={!canEdit ? NO_PERMISSION_MESSAGE : undefined}
            loading={isSaving}
            onClick={handleSave}
          >
            Save Automation
          </Button>
        </Card>
      </form>
    </div>
  );
};

export default AutomationViewSettings;
