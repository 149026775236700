import { Badge } from '@/ui/Badge';

interface Props {
  isVisible: boolean;
}

const SettingsBadge = ({ isVisible }: Props) => {
  if (isVisible) {
    return <Badge type="success">Visible</Badge>;
  }

  return <Badge>Hidden</Badge>;
};

export default SettingsBadge;
