import toast from 'react-hot-toast';

type CopyFn = (props: { text: string; onSuccessText?: string; onErrorText?: string }) => void;

function useCopyToClipboard(): CopyFn {
  const copy: CopyFn = ({ text, onSuccessText = 'Copied!', onErrorText = 'Failed to copy' }) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    if (document.execCommand('copy')) {
      if (onSuccessText) {
        toast.success(onSuccessText);
      }
    } else {
      toast.error(onErrorText);
    }

    textArea.remove();
  };

  return copy;
}

export default useCopyToClipboard;
