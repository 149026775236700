import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import { Label } from '@/components/Form';
import SubscriberTagSelectInput from '@/components/SubscriberTags/SubscriberTagSelectInput';
import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import { AutomationStepUpdateSubscriptionCustomField } from '@/interfaces/automations/automation_step';
import { CustomField } from '@/interfaces/custom_field';
import { PublicationSubscriberTagReference, PublicationSubscriberTagSelectItem } from '@/interfaces/subscriber_tag';
import { SubscriptionStatuses } from '@/interfaces/subscription';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import CustomFields from './CustomFields';

interface EditAttributesFormProps {
  customFields: CustomField[];
  subscriptionStatusValue: typeof SubscriptionStatuses;
  customFieldsValue: AutomationStepUpdateSubscriptionCustomField[];
  onCustomFieldsValueChange: (value: AutomationStepUpdateSubscriptionCustomField[]) => void;
  onSubscriptionStatusValueChange: (value: typeof SubscriptionStatuses) => void;
  subscriberTags: PublicationSubscriberTagReference[];
  onSubscriberTagsChange: (value: PublicationSubscriberTagReference[]) => void;
}

const EditAttributesForm = ({
  customFields,
  customFieldsValue,
  subscriptionStatusValue,
  onCustomFieldsValueChange,
  onSubscriptionStatusValueChange,
  subscriberTags,
  onSubscriberTagsChange,
}: EditAttributesFormProps) => {
  const [refetchCustomFields, setRefetchCustomFields] = useState(false);
  const hasCustomFields = customFields.length > 0;
  const canAddCustomField = customFields.length > customFieldsValue.length;
  const hasUnspecifiedCustomFields = !!customFieldsValue.find((customField) => !customField.id);

  const [selectedTags, setSelectedTags] = subscriberTags
    ? useState<PublicationSubscriberTagReference[]>(subscriberTags)
    : useState<PublicationSubscriberTagReference[]>([]);

  const handleSubscriberTagDeselect = (tagId: string) => {
    const updatedTags = selectedTags.filter((t) => t.id !== tagId);

    setSelectedTags(updatedTags);
    onSubscriberTagsChange(updatedTags);
  };

  const handleSubscriberTagSelect = (tag: PublicationSubscriberTagSelectItem) => {
    const selected: PublicationSubscriberTagReference = { id: tag.value };
    const updatedTags = [...subscriberTags, selected];

    setSelectedTags(updatedTags);
    onSubscriberTagsChange(updatedTags);
  };

  const forceCustomFieldSelectOptionsRefresh = () => setRefetchCustomFields(!refetchCustomFields);

  const handleAddCustomField = () => {
    const firstCustomFieldWithoutValue = customFields.find(
      (customField) => !customFieldsValue.find((cf) => cf.id === customField.id)
    );
    onCustomFieldsValueChange(customFieldsValue.concat({ id: firstCustomFieldWithoutValue?.id || '', value: '' }));
    forceCustomFieldSelectOptionsRefresh();
  };

  const handleUpdateCustomField = (index: number, value: AutomationStepUpdateSubscriptionCustomField) => {
    const newCustomFieldsValue = customFieldsValue.map((customField, idx) => {
      if (idx === index) {
        return { ...customField, ...value };
      }
      return customField;
    });

    onCustomFieldsValueChange(newCustomFieldsValue);

    if (value.id !== customFieldsValue[index].id) {
      forceCustomFieldSelectOptionsRefresh();
    }
  };

  const handleDeleteCustomField = (index: number) => {
    const newCustomFields = customFieldsValue.filter((_customField, idx) => idx !== index);
    onCustomFieldsValueChange(newCustomFields);
    forceCustomFieldSelectOptionsRefresh();
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-2">
        <Label htmlFor="subscription_status" value="Keep or transition subscription status to..." />
        <Dropdown
          name="subscription_status"
          value={subscriptionStatusValue}
          options={[
            { label: 'Active', value: SubscriptionStatuses.active },
            { label: 'Inactive', value: SubscriptionStatuses.inactive },
          ]}
          onSelect={(_name: string, value: string) => {
            onSubscriptionStatusValueChange(value);
          }}
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <Label htmlFor="" value="Keep or update custom field(s) to..." />
        <div className="flex flex-col gap-y-2">
          <CustomFields
            onDelete={handleDeleteCustomField}
            onUpdate={handleUpdateCustomField}
            shouldRefetchCustomFields={refetchCustomFields}
            currentCustomFields={customFieldsValue}
            customFields={customFields}
          />
          <div className="flex items-center space-x-2">
            <Tooltip
              tooltipClass="text-center"
              id="no-custom-fields-tooltip"
              text="You don't have any custom fields yet"
              showIcon={false}
              autoWidth={false}
              isEnabled={!hasCustomFields}
            >
              <Button
                className="w-fit"
                type="button"
                variant="primary-inverse"
                size="xs"
                onClick={handleAddCustomField}
                Icon={PlusIcon}
                disabled={!canAddCustomField || hasUnspecifiedCustomFields || !hasCustomFields}
              >
                Add Custom Field
              </Button>
            </Tooltip>
            <Link to="/subscribers/custom_fields" target="_blank" className="text-sm text-blue-400 hover:text-blue-500">
              Manage Custom Fields
            </Link>
          </div>
        </div>
      </div>

      {hasCustomFields && (
        <Text size="xs" className="text-gray-500 mt-1">
          Hint: You can remove a custom field from a subscriber by specifying an empty value.
        </Text>
      )}

      <div className="flex flex-col gap-y-2">
        <Label htmlFor="" value="Apply the following subscriber tag(s)..." />
        <div className="flex flex-col gap-y-2">
          <div className="flex items-center space-x-2">
            <SubscriberTagSelectInput
              selectValue="id"
              selectedTags={selectedTags}
              handleDeselect={handleSubscriberTagDeselect}
              handleSelect={handleSubscriberTagSelect}
            />
          </div>
          <Link to="/subscribers/tags" target="_blank" className="text-sm text-blue-400 hover:text-blue-500">
            Manage Subscriber Tags
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EditAttributesForm;
