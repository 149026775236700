import { EarningsOrderBy, OrderBy } from '@/interfaces/boosts/order';

export const orderByOptions = [
  { label: 'Pending subscribers', value: OrderBy.PENDING_SUBSCRIBER_COUNT },
  { label: 'Verified subscribers', value: OrderBy.ACCEPTED_SUBSCRIBER_COUNT },
  { label: 'Total subscribers', value: OrderBy.TOTAL_SUBSCRIBER_COUNT },
];

export const earningsOrderByOptions = [
  { label: 'Pending revenue', value: EarningsOrderBy.PENDING_EARNINGS },
  { label: 'Total revenue', value: EarningsOrderBy.TOTAL_EARNINGS },
];

export const dateOrderByOptions = [{ label: 'Created at', value: 'created_at' }];
