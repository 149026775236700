const DisbursementHeader = () => {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th className="!font-normal px-4 py-2" align="left">
          Publication
        </th>
        <th className="!font-normal px-4 py-2" align="left">
          Advertiser
        </th>
        <th className="!font-normal px-4 py-2" align="left">
          Status
        </th>
        <th className="!font-normal px-4 py-2" align="left">
          Rates
        </th>
        <th className="!font-normal px-4 py-2" align="left">
          Clicks
        </th>
        <th className="!font-normal px-4 py-2" align="left">
          Opens
        </th>
        <th className="!font-normal px-4 py-2" align="left">
          Payout
        </th>
        <th className="!font-normal px-4 py-2" align="left" />
      </tr>
    </thead>
  );
};

export default DisbursementHeader;
