const OptionsIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.7998 5.99961C1.7998 5.50255 2.20275 5.09961 2.6998 5.09961C3.19686 5.09961 3.5998 5.50255 3.5998 5.99961C3.5998 6.49667 3.19686 6.89961 2.6998 6.89961C2.20275 6.89961 1.7998 6.49667 1.7998 5.99961Z"
        fill="#6B7280"
      />
      <path
        d="M5.0998 5.99961C5.0998 5.50255 5.50275 5.09961 5.9998 5.09961C6.49686 5.09961 6.8998 5.50255 6.8998 5.99961C6.8998 6.49667 6.49686 6.89961 5.9998 6.89961C5.50275 6.89961 5.0998 6.49667 5.0998 5.99961Z"
        fill="#6B7280"
      />
      <path
        d="M9.2998 5.09961C8.80275 5.09961 8.3998 5.50255 8.3998 5.99961C8.3998 6.49667 8.80275 6.89961 9.2998 6.89961C9.79686 6.89961 10.1998 6.49667 10.1998 5.99961C10.1998 5.50255 9.79686 5.09961 9.2998 5.09961Z"
        fill="#6B7280"
      />
    </svg>
  );
};

export default OptionsIcon;
