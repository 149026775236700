import React, { createContext, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';

import useIsSettingPage from '@/hooks/useIsSettingPage';

import api from '../services/swarm';

import { useCurrentUser } from './current-user-context';

type ContextValue = [string, (publicationId: string) => void];

const CurrentPublicationContext = createContext<ContextValue | undefined>(undefined);

CurrentPublicationContext.displayName = 'CurrentPublicationContext';

const CurrentPublicationProvider = ({
  fallbackPublicationId,
  children,
}: {
  fallbackPublicationId: string;
  children: React.ReactNode;
}) => {
  const defaultToken = localStorage.getItem('currentPublicationId');

  const [currentPublicationId, setCurrentPublicationId] = useState(defaultToken || fallbackPublicationId);

  const handleSetCurrentPublicationId = React.useCallback(
    (id: string) => {
      localStorage.setItem('currentPublicationId', id);

      // So that the Rails side is kept informed
      api
        .post('/update_current_publication', { id })
        .then(() => {
          setCurrentPublicationId(id);
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);
        });
    },
    [setCurrentPublicationId]
  );

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'currentPublicationId' && e.newValue) {
        setCurrentPublicationId(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const contextValue: ContextValue = React.useMemo(() => {
    return [currentPublicationId, handleSetCurrentPublicationId];
  }, [currentPublicationId, handleSetCurrentPublicationId]);

  return <CurrentPublicationContext.Provider value={contextValue}>{children}</CurrentPublicationContext.Provider>;
};

function useCurrentPublicationState(reportIllegalUse: boolean = true) {
  const context = React.useContext(CurrentPublicationContext);
  if (context === undefined) {
    throw new Error(`useCurrentPublicationState must be used within a CurrentPublicationProvider`);
  }

  const isASettingPage = useIsSettingPage();
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;

  // Within settings pages (v2) we always extract the publication and organization id
  // using the following hooks
  // 1. const currentPublicationId = useCurrentPublicationId();
  // 2. const currentOrganizationId = useCurrentOrganizationId();
  // The hooks look up the GET params in the URL for `s_publication_id` and `s_organization_id`
  // We report an error on Sentry (production) if that is not the case and throw an error
  // locally
  if (isASettingPage && hasSettingsV2 && reportIllegalUse) {
    const error = new Error(
      `ILLEGAL_USE_OF_HOOK: useCurrentPublicationState is being used on page ${window.location.href}, please use useCurrentPublicationId hook instead`
    );

    if (process.env.NODE_ENV === 'development') {
      throw error;
    } else {
      captureException(error);
    }
  }

  return context;
}

export { CurrentPublicationContext, CurrentPublicationProvider, useCurrentPublicationState };
