import { useCallback, useState } from 'react';
import { Editor } from '@tiptap/core';

import { Input } from '@/components/Form';
import { LineDivider } from '@/components/LineDivider';
import Modal from '@/components/Modal';
import { SortableList, SortableListItem } from '@/components/SortableList';
import { Typography } from '@/components/Typography';
import { RSSEntry } from '@/interfaces/external_rss_feed';

interface Props {
  editor: Editor;
  data: any;
  settings: any;
  organizeContentModalOpen: boolean;
  setOrganizeContentModalOpen: (open: boolean) => void;
}

const DividerItem = {
  id: 'divider',
  value: 'divider',
};

export const RSSEntryOrganizerModal = ({
  editor,
  data,
  settings,
  organizeContentModalOpen,
  setOrganizeContentModalOpen,
}: Props) => {
  const [articleSearch, setArticleSearch] = useState('');

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setArticleSearch(e.target.value);
  }, []);

  const updateData = (updatedEntry: RSSEntry) => {
    const newData = {
      ...data,
      content: data.content.map((entry: RSSEntry) => {
        return entry.link === updatedEntry.link ? updatedEntry : entry;
      }),
    };
    editor.commands.setRSSData(newData);
  };

  if (!data || !editor) {
    return null;
  }

  return (
    <Modal
      isOpen={organizeContentModalOpen}
      onClose={() => setOrganizeContentModalOpen(false)}
      className="w-full max-w-3xl p-6"
    >
      <div className="flex flex-col gap-8">
        <div>Organize Articles</div>
        <SortableList
          listItems={data.content}
          onItemsSorted={(sortedList: any[]) => {
            const newData = {
              ...data,
              content: sortedList,
            };
            editor.commands.setRSSData(newData);
          }}
        >
          {(list) => {
            const listWithDivider =
              list.length <= settings.entriesToShow
                ? list
                : list
                    .slice(0, settings.entriesToShow)
                    .concat([DividerItem])
                    .concat(list.slice(settings.entriesToShow));
            return listWithDivider.map((item: RSSEntry, index: number) => {
              if (item.id === 'divider') {
                return (
                  <div className="pt-8 pb-4">
                    <LineDivider key="divider" />
                    <Input
                      name="articleSearch"
                      labelText="Search Articles"
                      type="text"
                      value={articleSearch}
                      onChange={handleSearch}
                      placeholder="Search articles by title"
                    />
                    <Typography key="divider-1" token="font-bold/text/xs">
                      Articles Hidden from View
                    </Typography>
                  </div>
                );
              }
              const sortableItem = item;
              sortableItem.id = item.link;
              return sortableItem.title.toLowerCase().includes(articleSearch.toLowerCase()) ||
                index <= settings.entriesToShow ? (
                <SortableListItem
                  key={sortableItem.link}
                  listItem={sortableItem}
                  text={sortableItem.title}
                  editableText
                  onTextChange={(e) => {
                    const updatedItem = {
                      ...sortableItem,
                      title: e.target.value,
                    };
                    updateData(updatedItem);
                  }}
                  onReset={() => {
                    const updatedItem = {
                      ...sortableItem,
                      title: sortableItem.original_title || sortableItem.title,
                    };
                    updateData(updatedItem);
                  }}
                  onRemoveItem={(itemToRemove) => {
                    const newData = {
                      ...data,
                      content: data.content.filter((entry: RSSEntry) => entry.link !== itemToRemove.link),
                    };
                    editor.commands.setRSSData(newData);
                  }}
                />
              ) : null;
            });
          }}
        </SortableList>
      </div>
    </Modal>
  );
};
