import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const Styled = {
  InputWrapper: styled.div`
    align-items: center;
    background: ${colors.white[8]};
    border-radius: 0.5rem;
    color: ${colors.white[4]};
    display: flex;
    margin-top: 0.5rem;
    padding: 0 0.5rem;

    &::before {
      content: '#';
    }

    & > input {
      background: none;
      border: none;
      color: currentColor;
      flex: 1;
      outline: none;
      padding: 0.375rem;
    }
  `,
};

export default Styled;
