/* eslint-disable react-hooks/rules-of-hooks */

import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import SelectAutomationModal from '@/components/SelectAutomationModal/SelectAutomationModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import api from '@/services/swarm';

const useEnrollInAutomationAction = ({ subscriptionId, status }: { subscriptionId: string; status?: string }) => {
  const { settings } = useSettings();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const automationsEnabled = settings?.automations;

  const enrollMutation = useMutation(
    async (automationIds: string[]) => {
      const res = await api.post(`/subscriptions/${subscriptionId}/automation_journeys`, {
        publication_id: currentPublicationId,
        automation_ids: automationIds,
      });
      return res.data;
    },
    {
      onSuccess: (res: any) => {
        toast.success(res.message || 'Automation enrollment process started.');
        setIsOpen(false);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
      onSettled: () => {
        setIsOpen(false);
      },
    }
  );

  const onSubmit = async (automationIds: string[]) => {
    enrollMutation.mutateAsync(automationIds);
  };

  if (status !== 'active' || !automationsEnabled) {
    return [];
  }

  return [
    {
      label: 'Add to Automations',
      helperText: 'Enroll subscriber in automation flows',
      onClick: () => setIsOpen(true),
      children: <SelectAutomationModal isOpen={isOpen} onClose={() => setIsOpen(false)} onSubmit={onSubmit} />,
    },
  ];
};

export default useEnrollInAutomationAction;
