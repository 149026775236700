import React from 'react';

let doc;
let win;
if (typeof document !== 'undefined') {
  doc = document;
}
if (typeof window !== 'undefined') {
  win = window;
}

export const IFrameContext = React.createContext({ document: doc, window: win });

export const IFrameContextProvider = IFrameContext.Provider;

export const useIFrame = () => React.useContext(IFrameContext);
