import { AdNetworkPayoutModel } from '../shared/payout_model';

import {
  AdNetworkDisbursementClicksAdjustmentReason,
  AdNetworkDisbursementPayoutAdjustmentReason,
  AdNetworkDisbursementStatus,
} from './disbursement/types';
import { AdNetworkOpportunityStatus } from './opportunity';
import { AdNetworkOpportunityPixelEvents } from './pixel_events';

export interface PublisherListItem {
  id: string;
  type: string;
}

export interface AdNetworkCampaignPerformanceOpportunity {
  ad_network_opportunity_id: string;
  opportunity_status: AdNetworkOpportunityStatus;
  rejection_reason: string;
  deadline: string;
  banned: boolean;
  post_id: string;
  post_title: string;
  advertiser_id: string;
  publisher_list_item: PublisherListItem;
  publication_id: string;
  publication_alphanumeric_id: string;
  publication_name: string;
  publication_tier_display_name: string;
  publication_owner_email: string;
  payout_model: AdNetworkPayoutModel;
  payout_per_click_cents: number;
  payout_per_click: string;
  payout_per_mille_cents: number;
  payout_per_mille: string;
  date: string;
  total_unique_opened: number;
  total_unique_eligible_opened: number;
  total_eligible_clicked: number;
  total_unique_eligible_clicked: number;
  total_clicked: number;
  total_unique_clicked: number;
  total_impressions: number;
  click_rate: number;
  disbursement: AdNetworkCampaignPerformanceOpportunityDisbursement;
  estimated_clicks: number;
  estimated_payout: string;

  /**
   * Only included when filter is `upcoming` due to performance concerns. See comment
   * in `app/models/views/ad_network/campaign_performance_opportunities.rb` for more.
   */
  estimated_opens?: number;

  selected_date: string;
  advertisement_option_name: string;
  advertisement_option_url: string;
  is_finalized: boolean;
  pixel_events: AdNetworkOpportunityPixelEvents;
}

export enum AdNetworkCampaignPerformanceFilter {
  ALL = 'all',
  DELIVERED = 'delivered',
  PENDING = 'pending',
  UPCOMING = 'upcoming',
  DECLINED_OR_EXPIRED = 'declined_or_expired',
}

export interface AdNetworkCampaignPerformanceOpportunityDisbursement {
  id: string;
  status: AdNetworkDisbursementStatus;
  approved_at: string;
  approved_by: string;
  amount_cents: number;
  amount: string;
  approved_amount_cents: number;
  approved_amount: string;
  actual_clicks: number;
  approved_clicks: number;
  actual_opens: number;
  approved_opens: number;
  payout_adjustment_reason: AdNetworkDisbursementPayoutAdjustmentReason;
  clicks_adjustment_reason: AdNetworkDisbursementClicksAdjustmentReason;
}
