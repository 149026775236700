export enum PostTargetAction {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

export enum PostTargetTier {
  NONE,
  FREE = 'free',
  PREMIUM = 'premium',
}

export enum PostTargetPlatform {
  EMAIL = 'email',
  WEB = 'web',
}

export enum PostTargetReceiverType {
  PUBLICATION = 'Publication',
  SEGMENT = 'Segment',
}

export interface PostTarget {
  id: string;
  action: PostTargetAction;
  receiver_type: PostTargetReceiverType;
  receiver_id: string;
  tier: PostTargetTier;
  tier_id: string;
  post_id: string;
  publication_id: string;
  num_active_subscribers: number;
  receiver_display_name: string;
  platform: string;
}
