import { useParams } from 'react-router-dom';

import { useCampaignAdvertisementCreate } from '../../../../../hooks/useAdNetwork/internal';
import { PageHeading } from '../../../_components/PageHeading';
import Form from '../../../advertisements/$advertisement_id/_components/Form/Form';

const CampaignAdvertisementsNew = () => {
  const { campaign_id: campaignId } = useParams<'campaign_id'>() as { campaign_id: string };
  const onSubmitMutation = useCampaignAdvertisementCreate({ campaignId });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Advertisements</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb>New</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <Form onSubmitMutation={onSubmitMutation} />
    </>
  );
};

export default CampaignAdvertisementsNew;
