/* eslint-disable consistent-return */
import { createContext, MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { attachInstruction, extractInstruction, Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import {
  ArrowsOutLineVertical,
  CaretDown,
  CaretRight,
  ClipboardText,
  CurrencyCircleDollar,
  File,
  HardDrive,
  HardDrives,
  Icon,
  Image,
  ListBullets,
  ListChecks,
  ListNumbers,
  Quotes,
  RectangleDashed,
  Slideshow,
  Square,
  SquareSplitHorizontal,
  Stack,
  Tag,
  Textbox,
  TextH,
  TextHFive,
  TextHFour,
  TextHOne,
  TextHSix,
  TextHThree,
  TextHTwo,
  TextT,
  Users,
  XLogo,
} from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { Fragment, Node, Slice } from '@tiptap/pm/model';
import { useEditorState } from '@tiptap/react';

import { cn } from '@/utils/cn';

import { Text } from '../../UI/Text';
import { Heading, Paragraph, Section } from '../extensions';
import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';
import { MultiNodeSelection } from '../extensions/CustomSelections/selections';

type TreeContextType = {
  editor: Editor;
};

const TreeContext = createContext<TreeContextType>({
  editor: {} as unknown as Editor,
});

type TNodeItemProps = {
  node: Node;
  nodePos: number;
  defaultIsOpen?: boolean;
};

const Icons: Record<string, Icon> = {
  paragraph: TextT,
  heading: TextH,
  imageBlock: Image,
  doc: File,
  text: TextT,
  bulletList: ListBullets,
  listItem: ListBullets,
  accordion: HardDrives,
  orderedList: ListNumbers,
  image: Image,
  section: RectangleDashed,
  container: Square,
  columns: SquareSplitHorizontal,
  divider: ArrowsOutLineVertical,
  pricing: CurrencyCircleDollar,
  testimonials: Quotes,
  signup: Textbox,
  recommendations: ListChecks,
  post: Slideshow,
  survey: ClipboardText,
  tags: Tag,
  authors: Users,
  socials: XLogo,
  button: HardDrive,
};

const HEADING_OPTIONS = {
  1: TextHOne,
  2: TextHTwo,
  3: TextHThree,
  4: TextHFour,
  5: TextHFive,
  6: TextHSix,
};

const formatActiveNodeType = (type?: string): string => {
  if (!type) return 'unknown';

  // split camel case and capitalize each word
  const formattedType = type
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return formattedType;
};

const TNodeItem = ({ node, nodePos, defaultIsOpen = false }: TNodeItemProps) => {
  const { editor } = useContext(TreeContext);

  const {
    activeNodeAttributes: { id },
    activeNode,
    selection,
  } = useActiveNode(editor);

  const multiNodeSelected = useMemo(() => {
    if (selection instanceof MultiNodeSelection) return selection.posList.includes(nodePos);
    return false;
  }, [selection, nodePos]);

  const childNodes = useMemo(() => {
    if (node.type.name === Heading.name || node.type.name === Paragraph.name) return [];

    const newChildNodes: TNodeItemProps[] = [];

    node.forEach((n, pos) => {
      newChildNodes.push({
        node: n,
        nodePos: pos + nodePos + 1,
      });
    });

    return newChildNodes;
  }, [node, nodePos]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!editor) return;

      if (e.metaKey || e.ctrlKey) {
        editor.chain().toggleNodeInMultinodeSelection(nodePos).focus().scrollIntoView().run();
        return;
      }

      editor.chain().setNodeSelection(nodePos).focus().scrollIntoView().run();
    },
    [editor, nodePos]
  );

  const handleHover = useCallback(() => {
    if (!editor) return;

    editor.chain().setHoveredNode(nodePos).focus().run();
  }, [editor, nodePos]);

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const [isDirtyOpen, setIsDirtyOpen] = useState<boolean>(false);

  useEffect(() => {
    // user already touched this, do not mess with it.
    if (isDirtyOpen || !node) return;

    if (!activeNode) return;

    let isDescendant = false;

    node.descendants((desc) => {
      if (isDescendant) return false;

      if (desc.eq(activeNode)) {
        isDescendant = true;
      }
    });

    setIsOpen(isDescendant);
  }, [nodePos, node, isDirtyOpen, activeNode]);

  const isNodeActive = id === node.attrs.id || multiNodeSelected;

  const { text } = node;

  const dragItem = useRef<HTMLDivElement>(null);

  const [isOver, setIsOver] = useState(false);
  const [instruction, setInstruction] = useState<Instruction | null>(null);

  useEffect(() => {
    if (!dragItem.current) return () => {};

    const slice = new Slice(Fragment.from(node), 0, 0);

    const data = {
      node,
      nodePos,
    };

    return combine(
      draggable({
        element: dragItem.current,
        getInitialData() {
          editor.view.dragging = {
            slice,
            move: true,
          };
          return data;
        },
      }),
      dropTargetForElements({
        element: dragItem.current,
        getData: ({ input, element, source }) => {
          const block: ('reorder-above' | 'reorder-below' | 'make-child')[] = [];
          const sourceNode = source.data.node as Node;
          if (node.type.name === Section.name && sourceNode && sourceNode.type.name !== Section.name) {
            block.push('reorder-above');
            block.push('reorder-below');
          }

          const contentFragment = Fragment.from(sourceNode);
          if (!node.type.validContent(contentFragment)) {
            block.push('make-child');
          }

          // this will 'attach' the instruction to your `data` object
          return attachInstruction(data, {
            input,
            element,
            currentLevel: 2,
            indentPerLevel: 20,
            mode: 'standard',
            block,
          });
        },
        onDragEnter() {
          setIsOver(true);
        },
        onDragLeave() {
          setIsOver(false);
        },
        onDrag({ self, source }) {
          const sourceNode = source.data.node as Node;
          const selfNode = self.data.node as Node;
          if (selfNode.attrs.id === sourceNode.attrs.id) return;

          setInstruction(extractInstruction(self.data));
        },
        onDrop({ self, source }) {
          // handle dropping
          const inst: Instruction | null = extractInstruction(self.data);

          const sourceNode = source.data.node as Node;
          const sourceNodePos = source.data.nodePos as number;

          let isDescendant = false;

          // check if the source node is a descendant of the self node
          sourceNode.descendants((desc) => {
            if (isDescendant) return false;

            if (node.eq(desc)) isDescendant = true;
          });

          setIsOver(false);

          if (isDescendant) return;

          // 'reorder-above' as default case;
          let insertPos: number = nodePos;

          if (inst?.type === 'reorder-below') {
            insertPos = nodePos + node.nodeSize;
          } else if (inst?.type === 'make-child') {
            insertPos = nodePos + node.nodeSize - 1;
          }

          if (insertPos < 0) return;

          // the general idea is to copy dragged node to new position and delete from old position.
          editor
            .chain()
            .command(({ tr }) => {
              tr.insert(insertPos, sourceNode);

              const delPos = tr.mapping.map(sourceNodePos);

              tr.deleteRange(delPos, delPos + sourceNode.nodeSize);

              return true;
            })
            .focus()
            .run();
        },
      })
    );
  }, [editor, nodePos, node]);

  let IconComp;

  if (node.type.name === 'heading') {
    IconComp = HEADING_OPTIONS[node.attrs.level as keyof typeof HEADING_OPTIONS] || TextT;
  } else {
    IconComp = Icons[node.type.name] || Stack;
  }

  return (
    <div className="select-none">
      <div
        className={cn('w-full bg-wb-accent h-[1px]', { 'opacity-0': !isOver || instruction?.type !== 'reorder-above' })}
      />
      <div
        ref={dragItem}
        className={cn('flex items-center gap-1 cursor-pointer border rounded-md p-1.5', {
          'bg-wb-button-accent-soft border-wb-accent-soft': isNodeActive,
          'hover:bg-wb-secondary border-transparent': !isNodeActive,
          'border-wb-accent': isOver && instruction?.type === 'make-child',
        })}
        onClick={handleClick}
        onMouseEnter={handleHover}
        role="none"
      >
        {childNodes.length > 0 && (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(!isOpen);
              setIsDirtyOpen(!isOpen);
            }}
            className="text-wb-primary-soft"
          >
            {isOpen ? <CaretDown /> : <CaretRight />}
          </button>
        )}
        <IconComp className="text-wb-accent" weight="bold" />
        <Text size="xs" className="text-inherit">
          {formatActiveNodeType(node.type.name)}
        </Text>{' '}
        {childNodes.length === 0 && text && <Text>{`(${text})`}</Text>}
      </div>

      <div style={{ height: isOpen ? undefined : '0px', overflow: 'hidden' }}>
        {childNodes.map(({ node: childNode, nodePos: np }) => (
          <div key={childNode.attrs.id} style={{ marginLeft: '20px' }}>
            <TNodeItem node={childNode} nodePos={np} />
          </div>
        ))}
      </div>

      <div
        className={cn('w-full bg-wb-accent h-[1px]', { 'opacity-0': !isOver || instruction?.type !== 'reorder-below' })}
      />
    </div>
  );
};

export const LayersPanel = ({ editor }: { editor: Editor }) => {
  const contextValue = useMemo(() => ({ editor }), [editor]);

  const directRootChildren = useEditorState({
    editor,
    selector: ({ editor: e }) => {
      const childNodes: TNodeItemProps[] = [];

      e.state.doc.descendants((node, pos) => {
        if (!node.type.isBlock) return false;

        childNodes.push({
          node,
          nodePos: pos,
        });

        return false; // do not dive into the children of these nodes
      });

      return childNodes;
    },
  });

  return (
    <TreeContext.Provider value={contextValue}>
      <div className="flex flex-col gap-2">
        <Text size="xs" variant="secondary" weight="semibold" className="p-2">
          Layers
        </Text>
        <div>
          {directRootChildren.map(({ node: chNode, nodePos: np }) => (
            <TNodeItem key={`${np}-${chNode.attrs.id}`} node={chNode} nodePos={np} />
          ))}
        </div>
      </div>
    </TreeContext.Provider>
  );
};
