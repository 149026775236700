import Widgets from '@/pages/Settings/Pages/Publication/Widgets';
import { SettingsContainer } from '@/ui/Settings/SettingsContainer';

const WebBuilderEmbedRecommendationsWidget = () => {
  return (
    <SettingsContainer>
      <Widgets />
    </SettingsContainer>
  );
};

export default WebBuilderEmbedRecommendationsWidget;
