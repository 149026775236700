import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { WidthHeightSettings } from '../general/WidthHeightSettings';

export function NavItemLogoSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item' || selectedContent.attrs?.type !== 'logo') return null;

  return (
    <SectionRenderer title="Logo">
      <WidthHeightSettings selectedContent={selectedContent} attribute="width" defaultFixedValue={100} />
      <WidthHeightSettings selectedContent={selectedContent} attribute="height" defaultFixedValue={36} />
    </SectionRenderer>
  );
}
