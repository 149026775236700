import { EditorColor } from '@/components/TiptapEditor/lib/types';

export const getTextDocumentColors = (colors?: EditorColor[], themeColors?: string[]) => {
  if (!colors) {
    return [];
  }

  return colors
    .filter((color) => !themeColors?.includes(color.color.toUpperCase()))
    .map((currentColor) => {
      return {
        ...currentColor,
        usage: colors.filter((c) => c.color === currentColor.color).length,
      };
    })
    .sort((a, b) => b.usage - a.usage)
    .map((color) => color.color)
    .filter((color, index, self) => !self.includes(color, index + 1))
    .slice(0, 4);
};

export const getBackgroundColors = (colors?: EditorColor[], themeColors?: string[]) => {
  if (!colors) {
    return [];
  }

  return colors
    .filter((color) => !themeColors?.includes(color.color.toUpperCase()))
    .map((currentColor) => {
      return {
        ...currentColor,
        usage: colors.filter((c) => c.color === currentColor.color).length,
      };
    })
    .sort((a, b) => b.usage - a.usage)
    .map((color) => color.color)
    .filter((color, index, self) => !self.includes(color, index + 1))
    .slice(0, 4);
};
export const getBorderColors = (colors?: EditorColor[], themeColors?: string[]) => {
  if (!colors) {
    return [];
  }

  return colors
    .filter((color) => !themeColors?.includes(color.color.toUpperCase()))
    .map((currentColor) => {
      return {
        ...currentColor,
        usage: colors.filter((c) => c.color === currentColor.color).length,
      };
    })
    .sort((a, b) => b.usage - a.usage)
    .map((color) => color.color)
    .filter((color, index, self) => !self.includes(color, index + 1))
    .slice(0, 4);
};
