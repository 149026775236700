import { useQuery } from 'react-query';

import { UserWithRoles } from '@/interfaces/user';

import api from '../../services/swarm';

const useUser = (publicationId: string, userId: string | undefined) => {
  return useQuery<UserWithRoles, Error>(
    ['user', userId],
    () =>
      api
        .get(`/users/${userId}`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!userId,
    }
  );
};

export default useUser;
