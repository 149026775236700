import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

const FreeTrialAlert: React.FC = () => {
  return (
    <div className="bg-[#EEF2FF] border border-[#C7D2FE] rounded-lg p-4 flex items-start mb-6">
      <div className="flex-shrink-0">
        <InformationCircleIcon className='w-5 h-5 text-[#6366F1]' />
      </div>
      <div className="ml-3 text-sm text-[#312E81]">
        <p className="font-medium mb-2">You are on a free trial</p>
        <p>You will no longer have access to submit tickets to the customer support team once your free trial is over. Upgrade to maintain access to this paid feature.</p>
        <a href="https://www.beehiiv.com/pricing" className="inline-block mt-[10px] font-medium underline underline-offset-3">
          View Pricing Plans
        </a>
      </div>
    </div>
  );
};

export default FreeTrialAlert;
