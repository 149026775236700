import { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import styled from 'styled-components';

import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { InputField } from '@/components/TiptapEditor/components/ui/Input';
import { Panel, PanelDivider, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { useTiers } from '@/hooks/useTiers';

import Styled from '../../../styled';

import { ReferralsConditionsSelect } from './ReferralsConditions';
import useVisibilitySettingsPanel from './VisibilitySettingsPanel.hooks';

const StyledLocal = {
  Grid: styled.div`
    display: flex;
    gap: 0.25rem;
  `,
  InputWrapper: styled.div<{ half?: boolean }>`
    flex: 1 1 auto;
  `,
};

export const VisibilitySettingsPanel = ({
  editor,
  onBack,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  onBack: Function;
  currentNode: Node | null;
  currentNodePos: number;
}) => {
  const {
    isSection,
    onToggleShowOnWebsite,
    onToggleShowOnEmail,
    onToggleShowToNonSubscribers,
    onToggleShowToFreeSubscribers,
    onToggleShowToPaidSubscribers,
    onToggleShowWithReferralCount,
    onSetShowWithReferralCount,
    onToggleShowToTieredSubscribers,
  } = useVisibilitySettingsPanel(editor, currentNode, currentNodePos);
  const { publicationId } = usePublicationContext();
  const { data: settings } = usePublicationSettings(publicationId);
  const referralProgramEnabled = settings?.referral_program;

  const [referralCount, setReferralCount] = useState({
    value: 0,
  });

  useEffect(() => {
    setReferralCount({ value: currentNode?.attrs.referralCountValue || 0 });
  }, [currentNode?.attrs.referralCountValue]);

  const handleReferralCountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setReferralCount({
      value: Number(e.target.value) || 0,
    });
  }, []);

  const tiersQuery = useTiers(publicationId);
  const tiers = tiersQuery.data || [];
  const showTierOptions = tiers && tiers.length > 1;
  // Nodes could have been created before this attribute existed, so we add a default value
  const showToTieredSubscribers = currentNode?.attrs.showToTieredSubscribers || [];

  const updateReferralCount = useCallback(() => {
    onSetShowWithReferralCount(referralCount);
  }, [referralCount]);

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, newReferralCount: any) => {
      event.stopPropagation();

      if (event.key === 'Enter') {
        onSetShowWithReferralCount({ ...referralCount, ...newReferralCount });
      }

      return false;
    },
    [referralCount]
  );

  const webOnlyNodes: string[] = [];
  const emailOnlyNodes: string[] = [];

  const forceHideOnWeb = emailOnlyNodes.includes(currentNode?.type.name || '');
  const forceHideOnEmail = webOnlyNodes.includes(currentNode?.type.name || '');

  const isForcedVisbility = forceHideOnWeb || forceHideOnEmail;

  return (
    <Styled.ContentWrapper>
      <Panel>
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            Visibility
          </Button>
        </PanelHeader>
        <PanelSection>
          <Styled.PanelSectionInner>
            <ToggleWrapper
              icon={<Icon name="Website" />}
              isDisabled={isForcedVisbility}
              switchEl={
                <ToggleSwitch
                  isChecked={!isSection ? true : currentNode?.attrs.showOnWebsite}
                  isDisabled={isForcedVisbility}
                  onChange={onToggleShowOnWebsite}
                />
              }
            >
              Show on website
            </ToggleWrapper>
            <ToggleWrapper
              icon={<Icon name="Mail" />}
              isDisabled={isForcedVisbility}
              switchEl={
                <ToggleSwitch
                  isChecked={!forceHideOnEmail && !isSection ? true : currentNode?.attrs.showInEmail}
                  isDisabled={isForcedVisbility}
                  onChange={onToggleShowOnEmail}
                />
              }
            >
              Show in email
            </ToggleWrapper>
            <PanelDivider />
            {currentNode?.attrs.showOnWebsite && (
              <ToggleWrapper
                icon={<Icon name="NonSubscriber" />}
                switchEl={
                  <ToggleSwitch
                    isChecked={!isSection ? true : currentNode?.attrs.showToNonSubscribers}
                    onChange={onToggleShowToNonSubscribers}
                  />
                }
              >
                Show to anonymous viewers on web
              </ToggleWrapper>
            )}
            <ToggleWrapper
              icon={<Icon name="FreeSubscriber" />}
              switchEl={
                <ToggleSwitch
                  isChecked={!isSection ? true : currentNode?.attrs.showToFreeSubscribers}
                  onChange={onToggleShowToFreeSubscribers}
                />
              }
            >
              Show to free subscribers
            </ToggleWrapper>
            {showTierOptions && <PanelDivider />}
            {/*
                ! IMPORTANT NOTE:
                ! For backwards compatibility the showToPaidSubscribers attribute is being repurposed as an "all" toggle.
                ! When this is enabled, the section will be shown to all paid subscribers, regardless of tier.
            */}
            <ToggleWrapper
              icon={<Icon name="PaidSubscriber" />}
              switchEl={
                <ToggleSwitch
                  isChecked={!isSection ? true : currentNode?.attrs.showToPaidSubscribers}
                  onChange={onToggleShowToPaidSubscribers}
                />
              }
            >
              Show to all paid subscribers
            </ToggleWrapper>
            {showTierOptions &&
              tiers.map((tier: any) => (
                <ToggleWrapper
                  key={tier.id}
                  icon={<Icon name="PaidSubscriber" />}
                  switchEl={
                    <ToggleSwitch
                      isChecked={
                        !isSection
                          ? true
                          : currentNode?.attrs.showToPaidSubscribers || showToTieredSubscribers.includes(tier.id)
                      }
                      onChange={onToggleShowToTieredSubscribers(tier.id, tiers)}
                    />
                  }
                >
                  All {tier.name} subscribers
                </ToggleWrapper>
              ))}
            {referralProgramEnabled &&
              (currentNode?.attrs.showToFreeSubscribers ||
                currentNode?.attrs.showToPaidSubscribers ||
                showToTieredSubscribers.length > 0) && (
                <>
                  <PanelDivider />
                  <ToggleWrapper
                    icon={<Icon name="ReferralCount" />}
                    switchEl={
                      <ToggleSwitch
                        isChecked={currentNode?.attrs.showWithReferralCount}
                        onChange={onToggleShowWithReferralCount}
                      />
                    }
                  >
                    Show to subscribers with a certain referral count
                  </ToggleWrapper>
                  {currentNode?.attrs.showWithReferralCount && (
                    <StyledLocal.Grid>
                      <ReferralsConditionsSelect
                        editor={editor}
                        currentNode={currentNode}
                        currentNodePos={currentNodePos}
                      />
                      <StyledLocal.InputWrapper>
                        <InputField
                          min="0"
                          onChange={handleReferralCountChange}
                          onKeyUp={(e) =>
                            handleKeyUp(e, {
                              value: Number(e.currentTarget.value) || 0,
                            })
                          }
                          onBlur={updateReferralCount}
                          type="number"
                          suffix="Referrals"
                          value={referralCount.value.toString()}
                        />
                      </StyledLocal.InputWrapper>
                    </StyledLocal.Grid>
                  )}
                </>
              )}
          </Styled.PanelSectionInner>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default VisibilitySettingsPanel;
