import { PageRoute } from '@/interfaces/dream_builder/page_route';

export function getPageRouteFromPath(route: PageRoute, path: string[]): PageRoute | undefined {
  if (path.length === 0) {
    return route;
  }
  const nextPath = path[0];
  const nextRoute = route.children?.[nextPath];
  if (!nextRoute) {
    return undefined;
  }
  return getPageRouteFromPath(nextRoute, path.slice(1));
}
