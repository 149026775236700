import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkAdvertiser } from '../../../interfaces/ad_network/internal/advertiser';
import api from '../../../services/swarm';

interface SuccessResponse extends AdNetworkAdvertiser {}
interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

export default function useAdvertiserUpdate({ id }: { id: string }) {
  const navigate = useNavigate();

  return useMutation<SuccessResponse, ErrorResponse, FormData>(
    (formData) => api.patch(`/ad_network/internal/advertisers/${id}`, formData).then((res) => res.data),
    {
      onSuccess: () => {
        toast.success('Advertiser updated successfully');

        navigate(`/ad_network/advertisers/${id}`);
      },
      onError: (error) => {
        const { errors } = error.response.data;

        if (errors) {
          Object.keys(errors).forEach((key) => {
            toast.error(`${key} ${errors[key][0]}`);
          });
        } else {
          toast.error('Something went wrong');
        }
      },
    }
  );
}
