import { useInfiniteQuery, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { PostSubscriberEngagement } from '@/interfaces/post';

import api from '../services/swarm';

interface ApiResponse {
  subscribers: PostSubscriberEngagement[];
  pagination: Pagination;
}

interface Props {
  postId: string;
  search?: string;
  statuses: string[];
  order?: string;
  dir?: string;
}

const usePostEngagements = ({ postId, search, statuses, order, dir }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchPostEngagements = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/posts/${postId}/engagements`, {
        params: {
          publication_id: currentPublicationId,
          page: pageParam,
          email: search,
          statuses,
          order,
          dir,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery(['posts', postId, 'engagements', search, order, dir, ...statuses], fetchPostEngagements, {
    getNextPageParam: (lastPage) => {
      const { page, total, per_page: responsePerPage } = lastPage.pagination;

      if (total >= responsePerPage) return page + 1;

      return false;
    },
    onSuccess: (data) => {
      const subscribers = data.pages.flatMap((page) => page.subscribers);

      subscribers.forEach((subscriberEngagement) => {
        queryClient.setQueryData(
          ['posts', postId, 'engagements', subscriberEngagement.subscriberId],
          subscriberEngagement
        );
      });
    },
  });
};

export default usePostEngagements;
