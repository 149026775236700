import { LogicalOperator } from '@/interfaces/condition';

export const StringOperatorsList = [
  { value: 'equal', label: 'equals', shorthand: '=' },
  { value: 'not_equal', label: 'does not equal', shorthand: '!=' },
  { value: 'contain', label: 'contains', shorthand: 'contains' },
  { value: 'start_with', label: 'starts with', shorthand: 'starts with' },
  { value: 'end_with', label: 'ends with', shorthand: 'ends with' },
  { value: 'exists', label: 'exists', shorthand: 'exists' },
  { value: 'does_not_exist', label: 'does not exist', shorthand: 'does not exist' },
] as const;

export const NumberOperatorsList = [
  { value: 'equal', label: 'equals', shorthand: '=' },
  { value: 'not_equal', label: 'not equal', shorthand: '!=' },
  { value: 'greater_than', label: 'greater than', shorthand: '>' },
  { value: 'less_than', label: 'less than', shorthand: '<' },
  { value: 'greater_than_equal_to', label: 'greater than or equal to', shorthand: '>=' },
  { value: 'less_than_equal_to', label: 'less than or equal to', shorthand: '<=' },
] as const;

export const LanguageOperatorsList = [
  { value: 'is', label: 'is' },
  { value: 'is_not', label: 'is not' },
] as const;

export const TagOperatorsList = [
  { value: 'include', label: 'includes' },
  { value: 'exclude', label: 'excludes' },
] as const;

export const TierOperatorsList = [
  { value: 'is', label: 'is' },
  { value: 'is_not', label: 'is not' },
] as const;

export const PlanNameOperatorsList = [
  { value: 'is', label: 'is' },
  { value: 'is_not', label: 'is not' },
] as const;

export type LanguageOperator = (typeof LanguageOperatorsList)[number]['value'];

export type PlanNameOperator = (typeof PlanNameOperatorsList)[number]['value'];

export type TagOperator = (typeof TagOperatorsList)[number]['value'];

export type TierOperator = (typeof TierOperatorsList)[number]['value'];

export const TierOptions = [
  {id: 'one', name: 'One'},
  {id: 'two', name: 'Two'},
  {id: 'three', name: 'Three'},
  {id: 'four', name: 'Four'},
  {id: 'unknown', name: 'Unknown'},
];

export type LanguageOperators = {
  operator: (typeof LanguageOperatorsList)[number]['value'];
  value: string;
};

export type PlanNameOperators = {
  operator: (typeof PlanNameOperatorsList)[number]['value'];
  value: string;
};

export type TagOperators = {
  operator: (typeof TagOperatorsList)[number]['value'];
  value: string;
};

export type StringOperator = (typeof StringOperatorsList)[number]['value'];

export type StringOperators = {
  operator: StringOperator;
  value?: string;
};

export type NumberOperator = (typeof NumberOperatorsList)[number]['value'];

export type NumberOperators = {
  operator: (typeof NumberOperatorsList)[number]['value'];
  value?: number;
};

export interface AttributeCondition {
  type: 'attribute';
  name: string;
  filters: StringOperators | NumberOperators | LanguageOperators | TagOperators | PlanNameOperators;
  uuid: string;
}

export interface MeasureCondition {
  type: 'measure';
  name: string;
  filters: NumberOperators;
  uuid: string;
  refine?: { within: number };
}

export interface ConditionGroup {
  conditions: Array<Condition>;
  logical_operator: LogicalOperator;
  type: 'group';
  uuid: string;
}

export type Condition = AttributeCondition | MeasureCondition | ConditionGroup;
export type ConditionOmitUuid =
  | (Omit<ConditionGroup, 'uuid'> & { uuid?: string })
  | (Omit<AttributeCondition, 'uuid'> & { uuid?: string })
  | (Omit<MeasureCondition, 'uuid'> & { uuid?: string });

export interface Filter {
  label: string;
  id: string;
}

export const filters: Array<Array<Filter>> = [
  [
    {
      label: 'Publication name',
      id: 'name',
    },
    {
      label: 'Publication description',
      id: 'description',
    },
    {
      label: 'Publication hostname',
      id: 'hostname',
    },
    {
      label: 'Simple search',
      id: 'search',
    },
    {
      label: 'Owner email',
      id: 'owner_email',
    },
    {
      label: 'Plan',
      id: 'plan_name',
    },
    {
      label: 'Tags',
      id: 'tag',
    },
    {
      label: 'Sent posts count',
      id: 'sent_posts_count',
    },
    {
      label: 'Tier',
      id: 'tier',
    },
  ],
  [
    {
      label: 'Opportunity Group',
      id: 'opportunity_group',
    },
  ],
  [
    {
      label: 'Active subscriber count',
      id: 'active_subscription_count',
    },
  ],
  [
    {
      label: 'Accepted opportunities',
      id: 'accepted_opportunity_count',
    },
    {
      label: 'Rejected opportunities',
      id: 'rejected_opportunity_count',
    },
    {
      label: 'Pending opportunities',
      id: 'pending_opportunity_count',
    },
    {
      label: 'Expired opportunities',
      id: 'expired_opportunity_count',
    },
    {
      label: 'Received opportunities',
      id: 'received_opportunity_count',
    },
  ],
  [
    {
      label: 'Language',
      id: 'language',
    },
  ],
];
