import { memo } from 'react';

import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import * as DropdownMenu from '@/components/UI/Dropdown';

export type CropOption = {
  label: string;
  value: string;
  scale?: number;
};

export const cropOptions: CropOption[] = [
  {
    label: 'Original',
    value: 'original',
  },
  {
    label: '1 : 1',
    value: '1:1',
    scale: 1,
  },
  {
    label: '1 : 2',
    value: '1:2',
    scale: 1 / 2,
  },
  {
    label: '2 : 1',
    value: '2:1',
    scale: 2 / 1,
  },
  {
    label: '2 : 3',
    value: '2:3',
    scale: 2 / 3,
  },
  {
    label: '3 : 2',
    value: '3:2',
    scale: 3 / 2,
  },
  {
    label: '3 : 4',
    value: '3:4',
    scale: 3 / 4,
  },
  {
    label: '4 : 3',
    value: '4:3',
    scale: 4 / 3,
  },
  {
    label: '4 : 5',
    value: '4:5',
    scale: 4 / 5,
  },
  {
    label: '5 : 7',
    value: '5:7',
    scale: 5 / 7,
  },
  {
    label: '16 : 9',
    value: '16:9',
    scale: 16 / 9,
  },
  {
    label: '16 : 10',
    value: '16:10',
    scale: 16 / 10,
  },
];

export type CropRatioDropdownProps = {
  currentOption: CropOption | null;
  onUnset: () => void;
  onSet: (option: CropOption) => void;
};

export const CropRatioDropdown = memo(({ currentOption, onSet, onUnset }: CropRatioDropdownProps) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Button>
        <Button
          $variant="tertiary"
          $size="small"
          $leftSlot={<Icon name="AspectRatio" />}
          $rightSlot={<Icon name="ChevronDown" />}
        >
          {!currentOption ? 'Custom' : currentOption.label}
        </Button>
      </DropdownMenu.Button>
      <DropdownMenu.Content style={{ maxWidth: '12rem' }}>
        <DropdownMenu.Item active={currentOption === null} onClick={onUnset}>
          Custom
        </DropdownMenu.Item>
        <DropdownMenu.Separator />
        {cropOptions.map((option) => (
          <DropdownMenu.Item
            key={option.value}
            active={currentOption?.value === option.value}
            onClick={() => onSet(option)}
          >
            {option.label}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
});

CropRatioDropdown.displayName = 'CropRatioDropdown';
