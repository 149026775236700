import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import useCampaignPaymentRequestDestroy from '@/hooks/useAdNetwork/internal/useCampaignPaymentRequestDestroy';
import { AdNetworkCampaign } from '@/interfaces/ad_network/internal/campaign';
import {
  AdNetworkPaymentRequest,
  AdNetworkPaymentRequestStatus,
} from '@/interfaces/ad_network/internal/payment_request';

export interface PaymentRequestActionProps {
  paymentRequest: AdNetworkPaymentRequest;
  campaign: AdNetworkCampaign;
}

const PaymentRequestAction = ({ paymentRequest, campaign }: PaymentRequestActionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const destroy = useCampaignPaymentRequestDestroy({ campaignId: campaign.id });

  const handleDelete = () => {
    destroy.mutateAsync({ paymentRequestId: paymentRequest.id }).then(() => {
      queryClient.invalidateQueries(['ad_network', 'internal', 'campaigns', campaign.id, 'payment_requests']);
    });
    setIsModalOpen(false);
  };
  const modalActionText = paymentRequest.status === AdNetworkPaymentRequestStatus.SCHEDULED ? 'Delete' : 'Void';
  const modalBodyText =
    paymentRequest.status === AdNetworkPaymentRequestStatus.SCHEDULED
      ? 'Are you sure you want to delete this payment request?'
      : 'Are you sure you want to void this payment request?';

  const showDelete =
    paymentRequest.status !== AdNetworkPaymentRequestStatus.PAID &&
    paymentRequest.status !== AdNetworkPaymentRequestStatus.ARCHIVED;

  return (
    <div className="flex flex-row space-x-2">
      {showDelete && (
        <>
          <ActionModal
            isOpen={isModalOpen}
            isWorking={false}
            onClose={() => setIsModalOpen(false)}
            onProceed={handleDelete}
            resourceId={paymentRequest.id}
            headerText={`${modalActionText} Payment Request`}
            actionText={modalActionText}
          >
            <p>{modalBodyText}</p>
          </ActionModal>
          <button type="button" onClick={() => setIsModalOpen(true)} title={modalActionText} className="">
            <TrashIcon className="inline-block text-red-500 hover:text-red-800 w-5 h-5" />
          </button>
        </>
      )}

      {paymentRequest.status === AdNetworkPaymentRequestStatus.SCHEDULED && (
        <p>
          <Link
            to={`/ad_network/campaigns/${campaign.id}/payment_requests/${paymentRequest.id}/edit`}
            className="bg-white hover:bg-gray-100 text-gray-800 disabled:text-gray-600 disabled:bg-gray-100 rounded-md shadow-sm border-gray-300 py-1 px-3 text-sm justify-center duration-200 border font-medium focus:outline-none focus:ring-2 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap space-x-1"
          >
            Edit
            <PencilSquareIcon className="inline-block w-4 h-4 ml-1" />
          </Link>
        </p>
      )}
      {paymentRequest.hosted_invoice_url.length > 0 && (
        <p>
          <a
            href={paymentRequest.hosted_invoice_url}
            className="bg-white hover:bg-gray-100 text-gray-800 disabled:text-gray-600 disabled:bg-gray-100 rounded-md shadow-sm border-gray-300 py-1 px-3 text-sm justify-center duration-200 border font-medium focus:outline-none focus:ring-2 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap space-x-1"
          >
            View Invoice
            <ArrowTopRightOnSquareIcon className="inline-block w-4 h-4 ml-1" />
          </a>
        </p>
      )}
    </div>
  );
};

export default PaymentRequestAction;
