import { Option } from '../../interfaces/general';

export const SEGMENT_TYPE_OPTIONS: Option[] = [
  {
    label: 'Static',
    value: 'static',
  },
  {
    label: 'Dynamic',
    value: 'dynamic',
  },
];

export const MANUAL_SEGMENT_OPTION = {
  label: 'Manual',
  value: 'manual',
};

export const SEGMENT_TYPE_DESCRIPTION = {
  static:
    'Set conditions to create a segmented group of subscribers that will NOT be automatically updated after creating.',
  dynamic: 'Set conditions to create a segmented group of subscribers that ARE automatically updated daily.',
  manual: 'Upload a list of subscribers that are NOT automatically updated.',
};

export const MISSING_SUBSCRIBER_STATUS_WARNING = 'This segment currently includes inactive and/or pending subscribers.';
