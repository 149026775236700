import { ElementType, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';

import ActionModal from '../ActionModal';
import { Input } from '../Form';

interface Props {
  actionWord?: string;
  buttonText: string;
  isSubmitting: boolean;
  onProceed: () => void;
  modalDescription: string;
  resourceId: string;
  Icon?: ElementType;
}

const ProtectedAction = ({
  buttonText,
  isSubmitting,
  onProceed,
  resourceId,
  modalDescription,
  actionWord = 'DELETE',
  Icon,
}: Props) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [confirmationText, setConfirmationText] = useState<string>('');

  const handleClear = () => {
    setIsConfirming(false);
    setIsConfirmed(false);
    setConfirmationText('');
  };

  const handleConfirm = () => {
    if (confirmationText === actionWord) {
      setIsConfirmed(true);
    } else {
      toast.error('Please type the confirmation text to continue!');
    }
  };

  const handleProceed = async () => {
    await onProceed();
    handleClear();
  };

  if (isConfirming) {
    return (
      <div className="space-y-2">
        <ActionModal
          isOpen={isConfirmed}
          onClose={handleClear}
          isWorking={isSubmitting}
          onProceed={handleProceed}
          resourceId={resourceId}
          headerText={buttonText}
          buttonType="danger"
        >
          <p>{modalDescription}</p>
        </ActionModal>
        <Input
          name={buttonText.split(' ').join('_')}
          labelText={`Please type "${actionWord}" in all caps to proceed.`}
          type="text"
          value={confirmationText}
          placeholder={actionWord}
          onChange={(e) => setConfirmationText(e.target.value)}
        />
        <div className="flex space-x-2">
          <Button variant="primary-inverse" onClick={handleClear}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Continue
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Button variant="danger" onClick={() => setIsConfirming(true)} Icon={Icon}>
        {buttonText}
      </Button>
    </div>
  );
};

export default ProtectedAction;
