import { useQuery } from 'react-query';

import { ApplicationSettings } from '../interfaces/application_settings';
import api from '../services/swarm';

const useApplicationSettings = () => {
  const fetchApplicationSettings = () => api.get(`/application_settings`).then((res) => res.data);

  return useQuery<ApplicationSettings>(['application_settings'], fetchApplicationSettings, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 10,
  });
};

export default useApplicationSettings;
