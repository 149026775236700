import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { Leader } from '@/pages/ReferralProgram/Overview/LeaderboardContainer';
import api from '@/services/swarm';

interface ApiResponse {
  leaders: Leader[];
  pagination: Pagination;
}

interface Props {
  enabled?: boolean;
  allResults?: boolean;
  perPage?: number;
}

const useLeaderboardMetrics = ({ enabled = true, allResults = false, perPage = 10 }: Props = {}) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    [currentPublicationId, 'leaderboard_metrics'],
    ({ pageParam = 1 }) =>
      api
        .get('referral_program/metrics/leaderboard', {
          params: {
            publication_id: currentPublicationId,
            all_results: allResults,
            page: pageParam,
            per_page: perPage,
          },
        })
        .then((res) => res.data),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
    }
  );
};

export default useLeaderboardMetrics;
