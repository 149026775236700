import { useCallback } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { convertToSection, nodeIsSection } from '../../utils';

const useMainPanel = (editor: Editor, navigateToPanel: Function, currentNode: Node | null, currentNodePos: number) => {
  const isSection = nodeIsSection(currentNode);

  const isGroupingPossible = !isSection;
  const isUngroupingPossible = isSection;

  const navigateToVisualSettings = useCallback(() => {
    navigateToPanel('VisualSettings');
  }, [navigateToPanel]);

  const navigateToVisibilitySettings = useCallback(() => {
    navigateToPanel('VisibilitySettings');
  }, [navigateToPanel]);

  const navigateToAnchorSettings = useCallback(() => {
    navigateToPanel('AnchorSettings');
  }, [navigateToPanel]);

  const onSetSection = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);
  }, [editor, currentNode, currentNodePos]);

  const onUnsetSection = useCallback(() => {
    editor.chain().setNodeSelection(currentNodePos).unsetSection().run();
  }, [editor, currentNodePos]);

  const resetTextFormatting = useCallback(() => {
    editor.chain().setNodeSelection(currentNodePos).unsetAllMarks().run();
  }, [editor, currentNodePos]);

  const duplicateNode = useCallback(() => {
    editor.commands.setNodeSelection(currentNodePos);

    const selectedNode = (editor.state.selection as any).node;
    const insertPos = currentNodePos + (currentNode?.nodeSize || 0);

    editor.chain().setMeta('hideDragHandle', true).insertContentAt(insertPos, selectedNode.toJSON()).run();
  }, [editor, currentNode?.nodeSize, currentNodePos]);

  const copyNodeToClipboard = useCallback(() => {
    editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).run();

    document.execCommand('copy');
  }, [editor, currentNodePos]);

  const deleteNode = useCallback(() => {
    editor.chain().setMeta('hideDragHandle', true).setNodeSelection(currentNodePos).deleteSelection().run();
  }, [editor, currentNodePos]);

  return {
    isSection,
    isGroupingPossible,
    isUngroupingPossible,
    navigateToVisualSettings,
    navigateToVisibilitySettings,
    navigateToAnchorSettings,
    onSetSection,
    onUnsetSection,
    resetTextFormatting,
    duplicateNode,
    copyNodeToClipboard,
    deleteNode,
  };
};

export default useMainPanel;
