/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useParams } from 'react-router-dom';
import ReactFlow, { Background, Controls, EdgeTypes, MiniMap, NodeTypes, Panel } from 'reactflow';

import IntersectionPanels from '@/components/IntersectionPanels';
import LoadingState from '@/components/LoadingState';
import useResourcePermissions from '@/hooks/useResourcePermissions/useResourcePermissions';
import NoPermission from '@/pages/NoPermission';

import ConfigureAutomationStep from '../ConfigureDrawer/ConfigureAutomationStep';
import ConfigureTriggers from '../ConfigureDrawer/ConfigureTriggers';
import { useNewAutoLayoutContext } from '../context/new-layout-context';
import CustomEdge from '../Edges/CustomEdge';
import useNewLayout from '../hooks/useNewLayout';
import NewAddAutomationStepNode from '../Nodes/NewAddAutomationStepNode';
import NewAutomationStepNode from '../Nodes/NewAutomationStepNode';
import ReferringAutomationsNode from '../Nodes/Triggers/ReferringAutomationsNode';
import TriggersNode from '../Nodes/Triggers/TriggersNode';
import { Sidebar } from '../Sidebar';
import { TRIGGERS_NODE_ID } from '../utils/getNodesAndEdges';

import 'reactflow/dist/style.css';

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true,
};

const nodeTypes: NodeTypes = {
  triggers: TriggersNode,
  referringAutomations: ReferringAutomationsNode,
  addAutomationStep: NewAddAutomationStepNode,
  automationStep: NewAutomationStepNode,
};

const edgeTypes: EdgeTypes = {
  custom: CustomEdge,
};

const FlowWithSidebar = () => {
  const {
    automation,
    automationSteps,
    automationTriggers,
    isLoading,
    isSelectingAutomationStep,
    changeLog,
    isDrawerOpen,
    isSorting,
    zoomToNode,
  } = useNewAutoLayoutContext();
  const { automationId, automationStepId, triggerId } = useParams() as unknown as {
    automationId: string;
    automationStepId: string;
    triggerId: string;
  };
  const location = useLocation();
  const isConfigureTriggersPath =
    location.pathname.match(`/automations/${automation.id}/workflow/triggers/configure`) || triggerId;
  const {data: recordPermissions, isSuccess: recordPermissionSuccess} = useResourcePermissions({
    resourceType: 'Automation',
    resourceId: automation.id,
    policyClass: "Api::V2::AutomationPolicy",
  });

  const canEdit = recordPermissionSuccess && recordPermissions.includes('update');

  const [hasInitialised, setHasInitialised] = useState(false);

  const handleInitialise = () => {
    setHasInitialised(true);
  };

  const automationStep = automationSteps.find(({ id }) => id === automationStepId);
  const currentTrigger = automationTriggers.find(({ id }) => id === triggerId);

  useNewLayout(hasInitialised, automation, automationSteps, automationTriggers, changeLog);

  // Zoom to triggers node on mount or
  // the page loads with configuring triggers path
  useEffect(() => {
    if (!hasInitialised) {
      return;
    }

    if (!isDrawerOpen || isConfigureTriggersPath) {
      zoomToNode(TRIGGERS_NODE_ID);
    }
  }, [hasInitialised]);

  if (!canEdit && isConfigureTriggersPath) {
    return <NoPermission redirectPath={`/automations/${automation.id}/workflow`} />;
  }

  if (isLoading) {
    return <LoadingState textProps={{ colorWeight: '700' }} text="Fetching your Automation data…" />;
  }

  return (
    <>
      {!isConfigureTriggersPath &&
        automationStep &&
        createPortal(<ConfigureAutomationStep automationStep={automationStep} canEdit={canEdit} />, document.body)}
      {isConfigureTriggersPath &&
        automationId &&
        createPortal(<ConfigureTriggers automationId={automationId} currentTrigger={currentTrigger} />, document.body)}
      <IntersectionPanels isActive={isSorting}>
        <ReactFlow
          defaultNodes={[]}
          defaultEdges={[]}
          onInit={handleInitialise}
          proOptions={proOptions}
          panOnDrag={!isSelectingAutomationStep && !isSorting}
          panOnScroll={!isSelectingAutomationStep}
          zoomOnScroll={!isSelectingAutomationStep}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
          fitViewOptions={{ padding: 1.1 }}
          minZoom={0.35}
          maxZoom={1.2}
          nodesDraggable={false}
          nodesConnectable={false}
          zoomOnDoubleClick={false}
        >
          <Panel position="top-left" className="hidden md:block">
            <div className="translate-y-2/4">
              <Sidebar disabled={!canEdit}/>
            </div>
          </Panel>
          <Controls position="bottom-left" />
          <MiniMap className="hidden sm:block" />
          <Background />
        </ReactFlow>
      </IntersectionPanels>
    </>
  );
};

export default FlowWithSidebar;
