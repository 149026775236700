import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import {
  ExternalRssFeed,
  ExternalRssFeedApiResponse,
  RSSCTAAlignment,
  RSSCTAStyle,
  RSSEntry,
  RSSThumbnailPosition,
} from '@/interfaces/external_rss_feed';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { FormStep } from '@/ui/FormStep';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';
import validateUrl from '@/utils/validateUrl';

import TwoColumnPageContainer from '../../../_components/TwoColumnPageContainer';
import { usePublicationSettings } from '../../context';

import DefaultsForm from './DefaultsForm';
import DetailsForm from './DetailsForm';
import Preview from './Preview';
import { StepErrors } from './StepErrors';

enum FormSteps {
  DETAILS = 1,
  DEFAULTS = 2,
}

interface Props {
  externalRssFeed?: ExternalRssFeedApiResponse;
  onSave: (externalRssFeed: ExternalRssFeed) => Promise<ExternalRssFeed>;
  isSaving: boolean;
}

const ExternalRssFeedForm = ({ externalRssFeed, onSave, isSaving }: Props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [stepErrors, setStepErrors] = useState<StepErrors>({});
  const [externalRssFeedInState, setExternalRssFeedInState] = useState<ExternalRssFeed>({
    id: externalRssFeed?.id || '',
    name: externalRssFeed?.name || '',
    url: externalRssFeed?.url || '',
    entriesToShow: externalRssFeed?.entries_to_show || 5,
    ctaAlignment: externalRssFeed?.cta_alignment || RSSCTAAlignment.LEFT,
    ctaStyle: externalRssFeed?.cta_style || RSSCTAStyle.BUTTON,
    ctaText: externalRssFeed?.cta_text || '',
    displayTitle: externalRssFeed?.display_title || true,
    displayCta: externalRssFeed?.display_cta || true,
    displayAuthor: externalRssFeed?.display_author || false,
    displayContent: externalRssFeed?.display_content || false,
    displayDescription: externalRssFeed?.display_description || false,
    displayPubDate: externalRssFeed?.display_pub_date || false,
    displayThumbnail: externalRssFeed?.display_thumbnail || false,
    displayTitleAboveThumbnail: externalRssFeed?.display_title_above_thumbnail || false,
    displayCategories: externalRssFeed?.display_categories || false,
    thumbnailPosition: externalRssFeed?.thumbnail_position || RSSThumbnailPosition.BOTTOM,
  });
  const [previewContent, setPreviewContent] = useState<RSSEntry[]>([]);
  const navigate = useNavigate();
  const { currentPublicationId } = usePublicationSettings();

  const fetchPreview = () => {
    api
      .get(`/publications/${currentPublicationId}/external_rss_feeds/preview`, {
        params: { url: externalRssFeedInState.url },
      })
      .then((res) => {
        setPreviewContent(res.data.content);
      });
  };

  useMemo(() => {
    if (externalRssFeed?.id) {
      fetchPreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalRssFeed?.id]);

  const getStepErrors = (step: number): StepErrors | null => {
    switch (step) {
      case 1:
        if (!validateUrl(externalRssFeedInState?.url || '')) {
          return { url: 'Please enter a valid URL' };
        }
        if (externalRssFeedInState?.name === null || externalRssFeedInState?.name?.trim() === '') {
          return { name: 'Please enter a name' };
        }
        break;
      case 2:
        break;
      default:
        return null;
    }

    return null;
  };

  const handleSave = () => {
    const errors = getStepErrors(currentStep);
    if (errors) {
      setStepErrors(errors);
    } else {
      onSave(externalRssFeedInState);
    }
  };

  const handleCancel = () => navigate(appendSettingsPublicationId('/settings/publication/rss', currentPublicationId));

  const handleClickPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleClickNextStep = () => {
    const errors = getStepErrors(currentStep);
    if (errors) {
      setStepErrors(errors);
    } else {
      setStepErrors({});
      if (currentStep === FormSteps.DETAILS) {
        fetchPreview();
      }
      setCurrentStep(currentStep + 1);
    }
  };

  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);

  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <TwoColumnPageContainer
          key={currentPublicationId}
          useAlternateWidth
          rhsColumn={<Preview externalRssFeed={externalRssFeedInState} content={previewContent} />}
        >
          <FormStep
            isOpen={currentStep === FormSteps.DETAILS}
            stepTitle="External RSS Feed Details"
            stepDescription="An External RSS Feed allows you to send data from an external source to your publication."
            footer={
              <>
                <Button type="button" variant="primary-inverse" onClick={handleCancel} size="sm">
                  Cancel
                </Button>
                <Button type="button" variant="primary" onClick={handleClickNextStep} size="sm">
                  Continue
                </Button>
              </>
            }
          >
            <DetailsForm
              externalRssFeed={externalRssFeedInState}
              errors={stepErrors}
              onChange={(payload) => setExternalRssFeedInState(payload)}
            />
          </FormStep>
          <FormStep
            isOpen={currentStep === FormSteps.DEFAULTS}
            stepTitle="Feed Defaults"
            stepDescription="When you use your external RSS feed in a post, you can set defaults for how the feed is displayed."
            footer={
              <>
                <Button
                  type="button"
                  variant="primary-inverse"
                  onClick={handleClickPreviousStep}
                  size="sm"
                  disabled={isSaving}
                >
                  Back
                </Button>
                <Button type="button" variant="primary" onClick={handleSave} disabled={isSaving} size="sm">
                  {externalRssFeedInState.id ? 'Update' : 'Create'} RSS Feed
                </Button>
              </>
            }
          >
            <DefaultsForm
              externalRssFeed={externalRssFeedInState}
              onChange={(payload: ExternalRssFeed) => setExternalRssFeedInState(payload)}
            />
          </FormStep>
        </TwoColumnPageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default ExternalRssFeedForm;
