import { useCallback, useMemo } from 'react';

import { TiptapEditor } from '@/components/TiptapEditor';
import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { GlobalStyles } from '@/components/TiptapEditor/lib/GlobalStyles';
import { useSettings } from '@/context/settings-context';
import { useCustomPageContext } from '@/routes/website_builder/_components/Context/CustomPageContext';

const PageLayout = () => {
  const { currentPublication, currentPublicationId, changesMade, setChangesMade, content, setContent } =
    useCustomPageContext();

  const { settings } = useSettings();

  const handleUpdate = useCallback(({ editor }: any) => {
    if (!changesMade) {
      setChangesMade(true);
    }
    setContent(editor.getJSON());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialContent = useMemo(() => content, [content]);

  return (
    <div className="h-full">
      {/* Editor */}
      <div className="flex flex-col w-full space-y-4">
        <PublicationProvider id={currentPublicationId}>
          <GlobalStyles colors={currentPublication?.color_palette || []} />
          {settings && (
            <TiptapEditor
              publicationId={currentPublicationId}
              settings={settings}
              onUpdate={handleUpdate}
              content={initialContent}
            />
          )}
        </PublicationProvider>
      </div>
    </div>
  );
};

export default PageLayout;
