import toast from 'react-hot-toast';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath, MarkerType, useReactFlow } from 'reactflow';

import { zoomToReactFlowNode } from '@/utils/zoomToReactFlowNode';

import { useEditFormViewContent } from '..';

import Dropzone from './Dropzone';

const CustomEdge = ({
  sourceX,
  sourceY,
  target,
  targetX,
  targetY,
  source,
  sourcePosition,
  targetPosition,
  style = {},
}: EdgeProps) => {
  const { getNode, fitView } = useReactFlow();
  const { sortingQuestionId, reorderQuestion } = useEditFormViewContent();

  const sourceNode = getNode(source);
  const targetNode = getNode(target);

  const handleReorder = async (questionId: string) => {
    if (!sourceNode || !targetNode) {
      return;
    }

    const canReorder = questionId && ![sourceNode.id, targetNode.id].includes(questionId);

    if (!canReorder) {
      return;
    }

    try {
      await reorderQuestion(questionId, targetNode.id);
      zoomToReactFlowNode(questionId, fitView);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={MarkerType.Arrow}
        style={{ ...style, pointerEvents: 'none' }}
        labelX={labelX}
        labelY={labelY}
        interactionWidth={0}
      />

      <EdgeLabelRenderer>
        <Dropzone sortingQuestionId={sortingQuestionId} labelX={labelX} labelY={labelY} onReorder={handleReorder} />
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
