import { useQuery } from 'react-query';

import { Publication } from '../../interfaces/publication';
import api from '../../services/swarm';

const usePublication = (publicationId: string, enabled: boolean = true) =>
  useQuery<Publication>(
    ['publications', publicationId],
    () => api.get(`/publications/${publicationId}`).then((res) => res.data),
    {
      staleTime: 10000,
      enabled,
    }
  );

export default usePublication;
