import { useQuery } from 'react-query';

import api from '../../services/swarm';

interface Props {
  downgradeId: string;
  organizationId: string | undefined;
}

export default function useEligibleDowngradeOffers({ organizationId, downgradeId }: Props) {
  interface EligibleOffers {
    pause_plan: boolean;
    claim_discount: boolean;
  }

  const fetchPausePlan = () =>
    api.get(`/organizations/${organizationId}/downgrades/${downgradeId}/eligible_offers`).then((res) => res.data);

  return useQuery<EligibleOffers>(
    ['organizations', organizationId, 'downgrades', downgradeId, 'eligible_offers'],
    fetchPausePlan,
    {
      keepPreviousData: true,
    }
  );
}
