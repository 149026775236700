import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

import Banner from '@/components/Banner';
import { CountryInput, Input } from '@/components/Form';
import IconButton from '@/components/IconHelpers/IconButton';
import { Typography } from '@/components/Typography';
import { Address } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import countryData from '@/utils/countries.json';

import CardHeader from './CardHeader';
import FormContentPreviewContainer from './FormContentPreviewContainer';
import PoweredByBeehiiv from './PoweredByBeehiiv';

interface Props {
  id: string | undefined;
  streetAddress: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  isSaving: boolean;
  onSave: (address: Address) => void;
  onDelete: () => void;
  isWhiteLabeled: boolean;
  countryName?: string;
  isEmpty: boolean;
  cardTitle?: string;
  cardDescription?: string;
  isUsingOrganizationAddress?: boolean;
}

const streetAddressPlaceholder = '228 Park Ave S, #29976';
const statePlaceholder = 'New York';
const cityPlaceholder = 'New York';
const zipCodePlaceholder = '10003';

const EditAddress = ({
  id,
  streetAddress,
  city,
  state,
  country,
  countryName,
  zipCode,
  isSaving,
  onSave,
  onDelete,
  cardTitle = 'Address',
  cardDescription,
  isWhiteLabeled,
  isEmpty,
  isUsingOrganizationAddress = false,
}: Props) => {
  const [isDeletingAddress, setIsDeletingAddress] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [address, setAddress] = useState<Address | undefined>(undefined);
  const [countryNameLocal, setCountryNameLocal] = useState<string | undefined>(countryName);

  const handleBlurInAddressField = (fieldName: string, value: string) => {
    setAddress({
      ...address,
      [fieldName]: value,
    } as Address);
  };

  const handleClickClearAddress = async () => {
    try {
      setIsDeletingAddress(true);
      await onDelete();
      setAddress(undefined);
    } catch (error) {
      // do nothing
    }

    setIsDeletingAddress(false);
  };

  const handleClickSaveAddress = async () => {
    if (!address?.street_address || !address?.city || !address?.state || !address?.zip_code || !address.country) {
      toast.error('Please fill the address to continue');
      return;
    }

    try {
      await onSave(address);
      setIsEditingAddress(false);
      setAddress(undefined);
    } catch (error) {
      // do nothing
    }
  };

  const handleClickEditAddress = () => {
    setIsEditingAddress(true);
    setAddress({
      id: id || '',
      street_address: streetAddress,
      street_level: '',
      city,
      state,
      country,
      zip_code: zipCode,
      country_name: '',
    });
  };

  const handleClickCancelEditAddress = () => {
    setIsEditingAddress(false);
    setAddress(undefined);
  };

  const updateCountryName = (value: string) => {
    const foundCountry = countryData.find((item) => item.code === value);
    if (foundCountry) {
      setCountryNameLocal(foundCountry.name);
    }
  };

  useEffect(() => {
    if (country) {
      updateCountryName(country);
    }
  }, [country]);

  return (
    <>
      <CardHeader
        title={cardTitle}
        description={cardDescription}
        cta={
          <div className="flex flex-row gap-x-2">
            {isEditingAddress ? (
              <>
                <Button size="xs" variant="primary-inverse" onClick={handleClickCancelEditAddress} disabled={isSaving}>
                  Cancel
                </Button>
                <Button size="xs" onClick={handleClickSaveAddress} disabled={isSaving}>
                  Save
                </Button>
              </>
            ) : (
              <>
                <IconButton onClick={handleClickEditAddress} tooltip="Edit Address">
                  <PencilIcon />
                </IconButton>
                {!isUsingOrganizationAddress && (
                  <IconButton
                    onClick={handleClickClearAddress}
                    disabled={isDeletingAddress || isEmpty}
                    tooltip="Clear Address"
                  >
                    <TrashIcon />
                  </IconButton>
                )}
              </>
            )}
          </div>
        }
      />
      {isEditingAddress ? (
        <>
          <Banner
            title="You need to input a value for each of these fields to have your address updated in your emails."
            isScreenWide={false}
            variant="info"
          />
          <Input
            labelText="Street Address"
            name="street_address"
            placeholder={streetAddressPlaceholder}
            defaultValue={streetAddress}
            onBlur={(e) => {
              handleBlurInAddressField('street_address', e.target.value);
            }}
            disabled={isSaving}
          />
          <div className="grid grid-rows-1 grid-cols-1 sm:grid-rows-2 sm:grid-cols-2 gap-y-6 gap-x-4">
            <Input
              labelText="City"
              name="city"
              placeholder={cityPlaceholder}
              defaultValue={city}
              onBlur={(e) => {
                handleBlurInAddressField('city', e.target.value);
              }}
              disabled={isSaving}
            />
            <Input
              labelText="State"
              name="state"
              placeholder={statePlaceholder}
              defaultValue={state}
              onBlur={(e) => {
                handleBlurInAddressField('state', e.target.value);
              }}
              disabled={isSaving}
            />
            <Input
              labelText="Postal Code"
              name="zip_code"
              placeholder={zipCodePlaceholder}
              defaultValue={zipCode}
              onBlur={(e) => {
                handleBlurInAddressField('zip_code', e.target.value);
              }}
              disabled={isSaving}
            />
            <CountryInput
              value={country || address?.country || ''}
              labelText="Country"
              name="country"
              onChange={(value) => {
                handleBlurInAddressField('country', value);
              }}
              isDisabled={isSaving}
            />
          </div>
        </>
      ) : null}
      {!isEditingAddress ? (
        <div className="space-y-2">
          <FormContentPreviewContainer className="flex flex-col gap-y-4 justify-center items-center">
            <div className="flex flex-col w-full text-center">
              {isEmpty ? (
                <Typography token="font-medium/text/sm" colorWeight="500">
                  No Address Set
                </Typography>
              ) : (
                <>
                  <Typography token="font-medium/text/sm">{streetAddress}</Typography>
                  <Typography token="font-medium/text/sm">
                    {city}, {state} {zipCode}, {countryNameLocal}
                  </Typography>
                </>
              )}
            </div>
            {isWhiteLabeled || isEmpty ? null : <PoweredByBeehiiv />}
          </FormContentPreviewContainer>
          {isUsingOrganizationAddress ? (
            <Typography token="font-light/text/xs">
              This publication is using the Workspace address. Override this by updating the address above.
            </Typography>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default EditAddress;
