import { useMutation } from 'react-query';

import { useCurrentPublication } from '@/hooks';
import api from '@/services/swarm';

const useUpdateOnboarding = () => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(({ status }: { status: string }) =>
    api
      .patch(`/onboarding/${currentPublication?.onboarding_id}`, {
        status,
        publication_id: currentPublication?.id,
      })
      .then((res) => res.data)
  );
};

export default useUpdateOnboarding;
