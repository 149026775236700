import toast from 'react-hot-toast';

import { useCurrentPublication, useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { TutorialType } from '@/interfaces/tutorial';
import { SignupFlowItem } from '@/interfaces/web_template';
import BoostDisplayOrder from '@/pages/Monetize/Boosts/Dashboard/components/Settings/Sections/BoostDisplayOrder';
import { ImageForm, Section } from '@/pages/Settings/Components';
import InputForm from '@/pages/Settings/Components/InputForm';
import VideoTutorial from '@/pages/Settings/Components/VideoTutorial';
import CustomStep from '@/pages/Settings/Pages/Website/Advanced/SignupFlowSection/CustomStep';
import PublicationStep from '@/pages/Settings/Pages/Website/Advanced/SignupFlowSection/PublicationsStep';
import RecommendationsStep from '@/pages/Settings/Pages/Website/Advanced/SignupFlowSection/RecommendationsStep';
import SubscribeSurveyStep from '@/pages/Settings/Pages/Website/Advanced/SignupFlowSection/SubscribeSurveyStep';
import UpgradeStep from '@/pages/Settings/Pages/Website/Advanced/SignupFlowSection/UpgradeStep';
import api from '@/services/swarm';

const WebBuilderSignupFlow = () => {
  const { data: currentPublication } = useCurrentPublication();

  const webTemplateMutation = useUpdateWebTemplate();
  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: any = webTemplateRequest;
  const signupFlow = webTemplate?.signup_flow;
  const signupMessaging = webTemplate?.signup_flow_messaging;

  const handleUpdateImg = (avatar: any): void => {
    const formData = new FormData();
    formData.append('publication_id', currentPublication?.id || '');
    formData.append('web_template[signup_flow_avatar]', avatar);

    api
      .patch(`/web_template/${currentPublication?.web_template_id}`, formData)
      .then(() => {
        toast.success('Avatar successfully updated!');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  };

  return (
    <div className="flex flex-col h-full w-full overflow-y-auto">
      <Section
        title="Signup Flow"
        description={
          <>
            <p className="mb-1">
              Customize the redirect journey new subscribers are taken through after signing up for your publication.
            </p>
            <VideoTutorial type={TutorialType.SUBSCRIBER_FLOW} />
          </>
        }
        scrollToId="subscribe-flow"
        isLoading={!currentPublication && !webTemplate}
      >
        <div className="space-y-4">
          <ImageForm
            label="Avatar"
            value={webTemplate?.signup_flow_avatar_url || null}
            onSave={handleUpdateImg}
            variant="circle"
            description="The avatar that appears at the top of your signup flow."
          />
          <InputForm
            name="messaging_title"
            label="Success Message"
            placeholder="Thanks for subscribing!"
            helperText="The success message that appears at the top of your signup flow."
            value={signupMessaging?.success_message || ''}
            onSave={(val) =>
              webTemplateMutation.mutateAsync({
                signup_flow_messaging: JSON.stringify({
                  ...signupMessaging,
                  success_message: val,
                }),
              })
            }
            isSaving={webTemplateMutation.isLoading}
            toastSuccessMessage="Saved!"
          />
          <BoostDisplayOrder />
          <div className="space-y-4">
            {signupFlow?.map((step: SignupFlowItem) => {
              if (step.managed_type === 'survey') {
                return <SubscribeSurveyStep key={step.id} step={step} webTemplate={webTemplate} />;
              }
              if (step.managed_type === 'recommendations') {
                return <RecommendationsStep key={step.id} step={step} webTemplate={webTemplate} />;
              }
              if (step.managed_type === 'upgrade') {
                return <UpgradeStep key={step.id} step={step} webTemplate={webTemplate} />;
              }
              if (step.managed_type === 'publications') {
                return <PublicationStep key={step.id} step={step} webTemplate={webTemplate} isWebBuilder />;
              }
              if (step.managed_type === 'custom') {
                return <CustomStep key={step.id} step={step} />;
              }

              return null;
            })}
          </div>
        </div>
      </Section>
    </div>
  );
};

export default WebBuilderSignupFlow;
