import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import useCheckPublicationPermissions from '@/hooks/useCheckPublicationPermissions/useCheckPublicationPermissions';
import { CurrentUserPublication } from '@/hooks/useCurrentUserOrganizations';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import GroupLink from './GroupLink';
import NavLink from './NavLink';
import { ViewMode } from './types';

interface Props {
  publication: CurrentUserPublication;
  isOpen: boolean;
  onLinkClick: () => void;
  viewMode: ViewMode;
}

const PublicationNav = ({ publication, isOpen, onLinkClick, viewMode }: Props) => {
  const location = useLocation();
  const { id, name, permissions } = publication;
  const [isFolded, setIsFolded] = useState(!isOpen);

  const navLinks = [
    {
      label: 'General Info',
      to: '/settings/publication/general',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/general', 'read'),
    },
    {
      label: 'Emails',
      to: '/settings/publication/emails',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/emails', 'read'),
    },
    {
      label: 'Content Import',
      to: '/settings/publication/content_import',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/content_import', 'read'),
    },
    {
      label: 'Subscribers Import',
      to: '/settings/publication/subscribers_import',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/subscribers_import', 'read'),
    },
    {
      label: 'Export Data',
      to: '/settings/publication/export_data',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/export_data', 'read'),
    },
    {
      label: 'Domains',
      to: '/settings/publication/domain',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/domain', 'read'),
    },
    {
      label: 'Webhooks',
      to: '/settings/publication/webhooks',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/webhooks', 'read'),
    },
    {
      label: 'Payment Accounts',
      to: '/settings/publication/payment_accounts',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/payment_accounts', 'read'),
    },
    {
      label: 'RSS',
      to: '/settings/publication/rss',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/rss', 'read'),
    },
  ];

  const handleClickGroup = () => {
    if (isOpen) {
      setIsFolded(!isFolded);
    }
  };

  return (
    <ul>
      <li className={classNames(isOpen && 'rounded-md bg-surface-50 border border-surface-100')}>
        {viewMode === 'list' ? (
          <GroupLink
            to={appendSettingsPublicationId(`/settings/publication/general`, id)}
            onClick={handleClickGroup}
            label={name}
            isSelected={isOpen}
            isFolded={isFolded}
            showPlaceholderIcon={publication.logo.is_default}
            placeholderImage={publication.logo.thumb.url}
            showBorder={false}
          />
        ) : null}
        {!isFolded || viewMode === 'dropdown' ? (
          <nav className={classNames('w-full flex flex-col gap-y-1 px-3 py-2')}>
            {navLinks.map((link) => (
              <NavLink
                to={appendSettingsPublicationId(link.to, id)}
                label={link.label}
                isSelected={location.pathname.startsWith(link.to)}
                isDisabled={link.isDisabled}
                key={link.to}
                onClick={onLinkClick}
              />
            ))}
          </nav>
        ) : null}
      </li>
    </ul>
  );
};

export default PublicationNav;
