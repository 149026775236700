import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import * as Sentry from '@sentry/react';
import { Editor as CoreEditor, JSONContent } from '@tiptap/core';
import { CollabHistoryVersion, CollabOnUpdateProps } from '@tiptap-pro/extension-collaboration-history';
import autosize from 'autosize';
import { AxiosError } from 'axios';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import LoadingBox from '@/components/LoadingBox';
import { TiptapEditor } from '@/components/TiptapEditor';
import { BacklinksSidebar } from '@/components/TiptapEditor/components/Backlinks';
import { FloatingComments } from '@/components/TiptapEditor/components/CommentsUI';
import { ThreadsSidebar } from '@/components/TiptapEditor/components/CommentsUI/Sidebar';
import {
  AUDIO_NEWSLETTER_CHARACTER_LIMIT,
  FALL_BACK_CHARACTER_LIMIT,
} from '@/components/TiptapEditor/extensions/extension-kit';
import API from '@/components/TiptapEditor/lib/api';
import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { GlobalStyles } from '@/components/TiptapEditor/lib/GlobalStyles';
import { useProvider } from '@/components/TiptapEditor/lib/hooks/useProvider';
import useUpdatePostContent from '@/components/TiptapEditor/lib/hooks/useUpdatePostContent';
import { EditorUser } from '@/components/TiptapEditor/lib/types';
import { generateYdoc } from '@/components/TiptapEditor/lib/utils/generateYdoc';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication, usePostInformation } from '@/hooks';
import { Post } from '@/interfaces/post';
import { cn } from '@/utils/cn';

import { useEditorContext } from '../EditorContext';
import AdsBanner from '../v2/AdsBanner';
import PostMeta from '../v2/Compose/PostMeta';
import SaveAsTemplateModal from '../v2/Compose/SaveAsTemplateModal';

import { EditorTopBar } from './EditorTopBar';
import { HistoryModal } from './HistoryModal';

import './index.css';

const isEmptyObject = (obj: Object | undefined | null) => (obj ? Object.keys(obj).length === 0 : true);

const MemoizedEditor = memo(TiptapEditor);

interface Props {
  post: Post;
  errors: { [key: string]: string };
}

const Editor = ({ post, errors }: Props) => {
  const queryClient = useQueryClient();

  const { data: currentPublication } = useCurrentPublication();

  const { refetch: postInfoRefetch, data: postInfoData } = usePostInformation({ id: post.id });

  const { settings } = useSettings();
  const { currentUser } = useCurrentUser();
  const { editor, setWordCount, setUsers, setUnsavedChanges, setIsSaving, openSidebar, collaborationEnabled } =
    useEditorContext();

  const [lostConnection, setLostConnection] = useState(false);
  const [hitSizeThreshold, setHitSizeThreshold] = useState(false);
  const [hitAudioNewsletterThreshold, setHitAudioNewsletterThreshold] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [versions, setVersions] = useState<Array<CollabHistoryVersion> | null>(null);
  const [currentVersion, setCurrentVersion] = useState<number | null>(null);
  const [showSaveAsTemplateModal, setShowSaveAsTemplateModal] = useState(false);
  const [showAdsBanner, setShowAdsBanner] = useState(false);
  const [postMetaLoading, setPostMetaLoading] = useState(false);
  const [adOpportunities, setAdOpportunities] = useState<
    Array<{ id: string; advertiser_name: string; selected_date: string }>
  >([]);
  const [adIdsInPost, setAdIdsInPost] = useState<Array<string> | undefined>();

  const refetchInformation = useMemo(() => debounce(() => postInfoRefetch(), 1_000), [postInfoRefetch]);

  const saveContentMutation = useUpdatePostContent({
    postId: post.id,
    saveDraft: true,
    onSuccess: () => {
      setIsSaving(false);
      setUnsavedChanges(false);
      queryClient.invalidateQueries(['post-v2', post.id]);
    },
    onError: () => {
      toast.error('Something went wrong');
    },
  });

  // Cache users profile data for collaboration cursor.
  const user = useMemo(() => {
    const userFirstName = currentUser?.first_name;
    const userLastName = currentUser?.last_name;
    const userName = userFirstName || userLastName ? `${userFirstName} ${userLastName}`.trim() : 'Unknown user';

    const colors = ['#958DF1', '#F98181', '#FBBC88', '#FAF594', '#70CFF8', '#94FADB', '#B9F18D'];
    const userColor = colors[Math.floor(Math.random() * colors.length)];

    return {
      id: currentUser?.id,
      name: userName,
      color: userColor,
    };
  }, [currentUser]);

  // Cache initial content and use it in case collaborative editing is not active.
  const initialContent = useMemo(() => {
    const tipTapContent = !isEmptyObject(post.draft_tiptap_state) ? post.draft_tiptap_state : post.tiptap_state;

    return !collaborationEnabled ? tipTapContent : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check to see if the post needs a ydoc generated. If it has JSON content but is
  // marked as not having a ydoc, we need to generate it to be loaded into the editor.
  // This would be the case for imported posts, or posts created in the old editor
  const initialYdoc = useMemo(() => {
    const { has_ydoc: hasYdoc, tiptap_state: publishedJson, draft_tiptap_state: draftJson } = post;

    const json = draftJson || publishedJson;

    const hasJsonContent = !!(json && Object.keys(json).length > 0);

    if (collaborationEnabled && hasJsonContent && !hasYdoc) {
      return generateYdoc({ json, extensionKitConfig: { allowAds: true, allowPolls: true } });
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialise collaboration functionality.
  const { provider } = useProvider({
    postId: post.id,
    publicationId: post.publication_id,
    initialDocument: initialYdoc,
  });

  const isDisconnected = (collaborationEnabled && !!provider && provider.status !== 'connected') || lostConnection;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveContent = useCallback(
    debounce(async (json: JSONContent) => {
      setIsSaving(true);
      saveContentMutation.mutate(json);
    }, 500),
    []
  );

  const shouldAutoFocus = useMemo(() => {
    return collaborationEnabled ? !!initialYdoc : true;
  }, [collaborationEnabled, initialYdoc]);

  const handleCharacterLimitWarnings = useCallback(
    ({ editor: editorInstance }: { editor: CoreEditor }) => {
      setWordCount(editorInstance.storage.characterCount.words());
      const characterCount = editorInstance.storage.characterCount.characters();
      const characterLimit = settings?.max_tip_tap_character_limit || FALL_BACK_CHARACTER_LIMIT;
      setHitSizeThreshold(characterCount >= characterLimit);

      const audioNewsletterCharacterLimit = AUDIO_NEWSLETTER_CHARACTER_LIMIT;
      setHitAudioNewsletterThreshold(characterCount >= audioNewsletterCharacterLimit);
    },
    [settings?.max_tip_tap_character_limit, setHitAudioNewsletterThreshold, setWordCount]
  );

  const invalidatePostV2Queries = useMemo(
    () => debounce(() => queryClient.invalidateQueries(['post-v2', post.id]), 3000),
    [post.id, queryClient]
  );

  const handleUpdate = useCallback(
    async ({ editor: editorInstance }: { editor: CoreEditor }) => {
      handleCharacterLimitWarnings({ editor: editorInstance });

      const editorJson = editorInstance.getJSON();

      const adIds = editorJson.content
        ?.filter((node) => node.type === 'advertisementOpportunity')
        .map((node) => node.attrs?.id);

      setAdIdsInPost(adIds || []);

      if (adIds?.length === adOpportunities.length) {
        setShowAdsBanner(false);
      }

      if (!collaborationEnabled) {
        setUnsavedChanges(true);
        saveContent(editorJson);
      } else {
        invalidatePostV2Queries();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collaborationEnabled, saveContent, setUnsavedChanges, handleCharacterLimitWarnings, queryClient, post.id]
  );

  const handleCreate = useCallback(
    ({ editor: editorInstance }: { editor: CoreEditor }) => {
      handleCharacterLimitWarnings({ editor: editorInstance });

      if (!shouldAutoFocus) {
        setTimeout(() => editorInstance.commands.focus('start'));
      }
    },
    [handleCharacterLimitWarnings, shouldAutoFocus]
  );

  // In case the state of collaborative users is changing …
  const handleUsersUpdate = useCallback(
    (updatedUsers: EditorUser[]) => {
      setUsers(updatedUsers);
    },
    [setUsers]
  );

  const handleVersionUpdate = useCallback((payload: CollabOnUpdateProps) => {
    setVersions(payload.versions);
    setCurrentVersion(payload.currentVersion);
  }, []);

  const handleRevert = useCallback(
    (version: number) => {
      editor?.commands.revertToVersion(version);
    },
    [editor]
  );

  const timeoutId = useRef<NodeJS.Timeout>();

  const onPostMetaReady = useCallback(() => {
    setPostMetaLoading(false);
  }, []);

  const allContentLoading = useMemo(
    () => postMetaLoading || (collaborationEnabled && !provider?.synced),
    [collaborationEnabled, postMetaLoading, provider?.synced]
  );

  // We have seen situations where the editor seems to be connected but changes don't actually get synced.
  // This works as a last resort to fight against people losing content. It's a fairly naive approach but
  // in practice - if you are connected to the internet - the editor should basically never be behind more
  // than a couple changes when syncing.
  useEffect(() => {
    provider?.on('unsyncedChanges', (changeCount: number) => {
      clearTimeout(timeoutId.current);

      if (changeCount === 0) {
        return;
      }

      timeoutId.current = setTimeout(() => {
        if (provider.unsyncedChanges > 0) {
          // eslint-disable-next-line no-console
          console.warn('Large number of unsynced editor changes');
          setLostConnection(true);
        }
      }, 5000);
    });
  }, [provider]);

  useEffect(() => {
    if (isDisconnected) {
      Sentry.captureMessage('Post editor disconnected');
    }
  }, [currentUser?.id, isDisconnected, post.id]);

  useEffect(() => {
    if (currentPublication && !adIdsInPost) {
      API.getAdvertisementOpportunitiesOptions({
        publicationId: currentPublication.id,
        page: 1,
      })
        .then((res) => {
          setAdOpportunities(res.data.options || []);

          const usedAdOpportunities = post.tiptap_state?.content?.filter(
            (node: { type: string }) => node.type === 'advertisementOpportunity'
          );

          const adIds = post.tiptap_state?.content
            ?.filter((node: { type: string }) => node.type === 'advertisementOpportunity')
            .map((node: { attrs: { id: string } }) => node.attrs.id);

          setAdIdsInPost(adIds);

          if (usedAdOpportunities?.length > 0) {
            setShowAdsBanner(false);
          } else {
            setShowAdsBanner(true);
          }
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPublication]);

  useEffect(() => {
    const titleEl = document.querySelector('.editor-title-textarea');
    const subTitleEl = document.querySelector('.editor-subtitle-textarea');
    if (!allContentLoading) {
      if (titleEl) {
        autosize.destroy(titleEl);
        autosize(titleEl);
        setTimeout(() => {
          autosize.update(titleEl);
        }, 10);
      }

      if (subTitleEl) {
        autosize.destroy(subTitleEl);
        autosize(subTitleEl);
        setTimeout(() => {
          autosize.update(subTitleEl);
        }, 10);
      }
    }

    return () => {
      if (titleEl) {
        autosize.destroy(titleEl);
      }

      if (subTitleEl) {
        autosize.destroy(subTitleEl);
      }
    };
  }, [allContentLoading]);

  if (!settings || !currentPublication) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`Editing "${post.web_title}"`}</title>
      </Helmet>

      <GlobalStyles colors={post.color_palette} />

      <div className={cn('flex w-full overflow-y-auto')}>
        <div className="flex flex-col h-full m-0 flex-1 overflow-y-auto">
          <EditorTopBar
            errors={errors}
            postInfo={postInfoData}
            hitSizeThreshold={hitSizeThreshold}
            hitAudioNewsletterThreshold={hitAudioNewsletterThreshold}
            showHistoryModal={showHistoryModal}
            setShowHistoryModal={setShowHistoryModal}
            setShowSaveAsTemplateModal={setShowSaveAsTemplateModal}
          />

          <div className="flex-1 relative layout px-8">
            <div className="layout-content mb-4 md:mb-10">
              <div className={cn('relative mt-6 md:mt-12', allContentLoading && 'hidden')}>
                <PostMeta onReady={onPostMetaReady} />
              </div>

              {showAdsBanner && adOpportunities.length > 0 && (
                <div className={cn('relative mt-12', allContentLoading && 'hidden')}>
                  <AdsBanner
                    excludeIds={adIdsInPost}
                    adOpportunities={adOpportunities}
                    setShowAdsBanner={setShowAdsBanner}
                  />
                </div>
              )}
            </div>

            <LoadingBox
              isLoading={(collaborationEnabled && !provider?.synced) || allContentLoading}
              height="50vh"
              isError={isDisconnected}
              backgroundClassName="bg-transparent"
              errorText="Connection was lost. Try refreshing the page to reconnect."
              className="layout-content"
            >
              <div className={cn('relative px-0 layout-content')}>
                <PublicationProvider id={post.publication_id}>
                  <MemoizedEditor
                    publicationId={post.publication_id}
                    settings={settings}
                    onUpdate={handleUpdate}
                    onCreate={handleCreate}
                    onBlur={refetchInformation}
                    provider={provider}
                    onUsersUpdate={handleUsersUpdate}
                    userId={user.id}
                    userName={user.name}
                    userColor={user.color}
                    className={cx('pb-[42rem]')}
                    content={initialContent}
                    usesCollaboration={collaborationEnabled}
                    allowPolls
                    allowAds
                    useCollabHistory={collaborationEnabled}
                    onVersionUpdate={handleVersionUpdate}
                    isV2
                    shouldAutoFocus={collaborationEnabled ? !!initialYdoc : true}
                  />
                </PublicationProvider>
              </div>
            </LoadingBox>

            {!openSidebar && (
              <div className="layout-comment ml-20">
                <FloatingComments />
              </div>
            )}
          </div>
        </div>
        {!!editor && (
          <>
            {openSidebar === 'threads' && <ThreadsSidebar />}
            {openSidebar === 'backlinks' && <BacklinksSidebar />}
          </>
        )}
      </div>
      <SaveAsTemplateModal
        isOpen={showSaveAsTemplateModal}
        onClose={() => setShowSaveAsTemplateModal(false)}
        postId={post.id}
      />
      {collaborationEnabled && provider && (
        <HistoryModal
          open={showHistoryModal}
          onClose={() => setShowHistoryModal(false)}
          provider={provider as TiptapCollabProvider}
          onRevert={handleRevert}
          publicationId={post.publication_id}
          versions={versions}
          currentVersion={currentVersion}
          settings={settings}
          isV2
        />
      )}
    </>
  );
};

export default Editor;
