import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useNewSplitTestOption = (postId: String, onSuccess: Function) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    () =>
      api
        .get(`/posts/${postId}/split_test_option/new`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
      },
    }
  );
};

export default useNewSplitTestOption;
