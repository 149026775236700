export interface TierData {
  name: string;
  id: string;
}

export enum TierStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export const DefaultTierName = 'Premium Non-Tier';

export enum PriceInterval {
  MONTH = 'month',
  YEAR = 'year',
  ONE_TIME = 'one_time',
  DONATION = 'donation',
}

export interface Price {
  id: string;
  amount_cents: number;
  currency: string;
  enabled: boolean;
  interval: PriceInterval;
  interval_display: string;
  cta: string;
  denominator?: string;
  features: string[];
}

export interface Tier {
  id: string;
  publication_id: string;
  name: string;
  prices: Price[];
  status: TierStatus;
  description?: string;
  is_default: boolean;
  redirect_url?: string;
  gifting_enabled?: boolean;
  active_subscriptions_count?: number;
}
