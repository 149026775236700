import { useCurrentPublicationState } from '@/context/current-publication-context';

import useCopyToClipboard from '../useCopyToClipboard';

const useAffiliateLink = (boostedPublicationUrl: string) => {
  const copy = useCopyToClipboard();
  const [currentPublicationId] = useCurrentPublicationState();

  // _bhba => beehiiv boost affiliate
  const affiliateLink = `${boostedPublicationUrl}subscribe?_bhba=${currentPublicationId}`;
  return {
    affiliateLink,
    handleCopyAffiliateLink: () => {
      copy({ text: affiliateLink, onSuccessText: 'Direct Link copied!' });
    },
  };
};

export default useAffiliateLink;
