import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import Card from '@/components/Card';
import Table, { TableBody, TableCol, TableRow } from '@/components/Table';
import { PartnerLink } from '@/interfaces/partner_program/partner_link';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import LinkRow from './LinkRow';

interface Props {
  links: PartnerLink[];
  refetch: any;
}

async function createLink() {
  toast.loading('Creating link...');
  await api.post('/partner_link');
  toast.remove();
  toast.success('Link created!');
}

const TableColInHeader = ({ children }: any) => {
  return (
    <TableCol className="!px-3 !py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      {children}
    </TableCol>
  );
};

const Links = ({ links, refetch }: Props) => {
  const [linkData, setLinkData] = useState(links);

  useEffect(() => {
    setLinkData(links);
  }, [links]);

  const handleNewLink = async () => {
    createLink().then(() => {
      refetch();
    });
  };

  const updateLink = (updatedLink: PartnerLink) => {
    setLinkData((prevLinks) => prevLinks.map((link) => (link.id === updatedLink.id ? updatedLink : link)));
  };

  return (
    <Card noPadding>
      <div className="p-6 w-full flex flex-col overflow-auto space-y-6 bg-white rounded-lg">
        <div className="flex flex-row space-x-2 w-full justify-end">
          <Button onClick={handleNewLink} variant="secondary" size="xs" type="button">
            New Link
          </Button>
        </div>
        <div className="w-full overflow-auto">
          <Table>
            <TableRow className="bg-gray-50 border-b border-surface-100 rounded-t-lg rounded-xl">
              <TableColInHeader>Link</TableColInHeader>
              <TableColInHeader>Visitors</TableColInHeader>
              <TableColInHeader>Leads</TableColInHeader>
              <TableColInHeader>Conversions</TableColInHeader>
              <TableColInHeader />
            </TableRow>
            <TableBody>
              {linkData?.map((link: PartnerLink) => {
                return <LinkRow key={link.id} link={link} updateLink={updateLink} />;
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </Card>
  );
};

export default Links;
