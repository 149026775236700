import { File, Plus } from '@phosphor-icons/react';

import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

interface Props {
  text: string;
  tooltipText: string;
  onClick: () => void;
}

const AddablePageHelper = ({ text, tooltipText, onClick }: Props) => {
  return (
    <div className="px-1.5 py-2 flex rounded-lg flex-row w-full items-center justify-between gap-2 group bg-white hover:bg-wb-secondary pl-[30px] opacity-30 hover:opacity-100 transition-all duration-200">
      <File weight="bold" size={16} className="my-auto text-wb-secondary" />
      <Text as="p" size="2xs" weight="medium" className="truncate">
        {text}
      </Text>

      <Tooltip center={tooltipText} className="flex-1 flex items-center cursor-pointer">
        <button type="button" className="hover:bg-wb-highlight rounded-full p-0.5" onClick={onClick}>
          <Plus weight="bold" className="my-auto text-wb-secondary h-4 w-4" />
        </button>
      </Tooltip>
    </div>
  );
};

export default AddablePageHelper;
