import moment from 'moment-mini';

import { PublicationLogo } from '@/components/PublicationLogo';
import RegionalRestrictionsTooltip from '@/components/RegionalRestrictionsTooltip';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import useFormattedTopLineMetrics from '@/hooks/boosts/grow/useFormattedTopLineMetrics';
import useBoostOfferEligibleCountries from '@/hooks/boosts/useBoostOfferEligibleCountries';
import { BoostSend } from '@/interfaces/boosts/monetize/boost_send';

import BoostSendActions from './Table/BoostSendActions';
import BoostSendStatusBadge from './BoostSendStatusBadge';

interface Props {
  boostSend: BoostSend;
  onActionSuccess: () => void;
  showCPA?: boolean;
}

const BoostSendListItem = ({ boostSend, onActionSuccess, showCPA = false }: Props) => {
  const { totalSpend, pendingSpend } = useFormattedTopLineMetrics(boostSend.top_line_metrics);
  const { hasRegionalRestrictions, regionalRestrictionsTooltip } = useBoostOfferEligibleCountries(
    boostSend.boost_agreement.boost_offer.eligible_countries
  );

  return (
    <ItemRow>
      <ItemColumn align={AlignType.TOP}>
        {showCPA ? (
          <TypographyStack>
            <Typography token="font-medium/text/base">
              {boostSend.boost_agreement.boost_offer.payout_per_lead}
            </Typography>
            {hasRegionalRestrictions && (
              <RegionalRestrictionsTooltip
                id={`regional-restrictions-tooltip-${boostSend.boost_agreement.boost_offer.id}`}
                text={regionalRestrictionsTooltip}
              />
            )}
            <Typography token="font-normal/text/xs" colorWeight="500">
              Created <time dateTime={boostSend.created_at as string}>{moment(boostSend.created_at).fromNow()}</time>{' '}
            </Typography>
          </TypographyStack>
        ) : (
          <div className="w-64 flex items-center gap-x-2">
            <PublicationLogo publication={boostSend.boosted_publication} size="sm" />
            <Typography weight="medium" colorWeight="900">
              {boostSend.boosted_publication.name}
            </Typography>
          </div>
        )}
      </ItemColumn>
      <ItemColumn>
        <BoostSendStatusBadge status={boostSend.status} sendBy={boostSend.send_by} />
      </ItemColumn>
      {!showCPA && (
        <ItemColumn>
          <Typography size="sm" colorWeight="700">
            {boostSend.boost_agreement.boost_offer.payout_per_lead}
          </Typography>
        </ItemColumn>
      )}
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostSend.max_payout}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostSend.top_line_metrics.total_pending_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostSend.top_line_metrics.total_accepted_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostSend.top_line_metrics.total_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {pendingSpend}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {totalSpend}
        </Typography>
      </ItemColumn>
      <ItemColumn isSticky className="w-full h-full flex items-center">
        <BoostSendActions boostSend={boostSend} onSuccess={onActionSuccess} />
      </ItemColumn>
    </ItemRow>
  );
};

export default BoostSendListItem;
