import api from '@/services/swarm';

export type SearchGiphyGifsOptions = {
  query: string;
  publication_id: string;
  page?: number;
};

export type UnsplashImage = {
  id: string;
  created_at: string;
  width: number;
  height: number;
  color: string;
  blur_hash: string;
  likes: number;
  liked_by_user: boolean;
  description: string;
  urls: {
    raw: string;
    full: string;
    regular: string;
    small: string;
    thumb: string;
  };
  links: {
    download_location: string;
  };
  user: {
    id: string;
    username: string;
    name: string;
    first_name: string;
    last_name: string;
    instagram_username: string;
    twitter_username: string;
    portfolio_url: string;
    profile_image: {
      small: string;
      medium: string;
      large: string;
    };
    links: {
      self: string;
      html: string;
      photos: string;
      likes: string;
    };
  };
};

const defaultSearchOptions: SearchGiphyGifsOptions = {
  query: '',
  publication_id: '',
  page: 1,
};

type PhotoSearchResponse = { results: UnsplashImage[]; total: number; total_pages: number };

export const searchUnsplashImages = async (rawOptions: SearchGiphyGifsOptions) => {
  const params = { ...defaultSearchOptions, ...rawOptions };

  try {
    const { data } = await api.get('/editor/unsplash/photos', { params });
    return data as PhotoSearchResponse;
  } catch (e: any) {
    throw new Error(e.message);
  }
};
