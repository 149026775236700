import useWorkspaceViewCreate from '@/hooks/useAdNetwork/internal/useWorkspaceViewCreate';

import { PageHeading } from '../_components/PageHeading';

import { Form } from './_components/Form';

export default function Edit() {
  const onSubmitMutation = useWorkspaceViewCreate();

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Views</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">New</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <Form onSubmitMutation={onSubmitMutation} />
    </>
  );
}
