import React, { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import { IData } from '@/interfaces/general';
import { StripeMigrationStatus } from '@/interfaces/stripe';
import { Button } from '@/ui/Button';

import Wizard from '../../_components/Wizard';
import useTransitionStripeMigration from '../../_hooks/useTransitionStripeMigration';
import useUpdateStripeMigration from '../../_hooks/useUpdateStripeMigration';

import MissingPricePointsSlideOver from './_components/MissingPricePointSlideOver';
import ProductMapSelections from './_components/ProductMapSelections';
import useSourcePrices from './_hooks/useSourcePrices';
import useTargetPrices from './_hooks/useTargetPrices';

const StripeMigrationWizardStepThree: FC = () => {
  const [selectedSourcePrices, setSelectedSourcePrices] = useState<string[]>([]);
  const [isMissingPricePointsOpen, setIsMissingPricePointsOpen] = useState(false);
  const [mapping, setMapping] = useState<IData>({});
  const { stripeMigrationId } = useParams();
  const navigate = useNavigate();

  const {
    data: sourcePriceData,
    isLoading: isLoadingSourcePrices,
    refetch: refetchSourcePrices,
  } = useSourcePrices(stripeMigrationId);
  const { prices: sourcePrices } = sourcePriceData || { prices: [] };

  const {
    data: targetPriceData,
    isLoading: isLoadingTargetPrices,
    refetch: refetchTargetPrices,
  } = useTargetPrices(stripeMigrationId);
  const { prices: targetPrices } = targetPriceData || { prices: [] };

  const { mutate: transition, isLoading: isTransitioning } = useTransitionStripeMigration({
    id: stripeMigrationId,
    status: StripeMigrationStatus.PROCESSING_PREVIEW,
    onSuccess: () => navigate(`/stripe_migrations/${stripeMigrationId}/preview_subscriptions`),
  });

  const { mutate: update, isLoading: isUpdating } = useUpdateStripeMigration({
    id: stripeMigrationId,
    onSuccess: () => transition(),
  });

  const addMorePricePoints = () => {
    setIsMissingPricePointsOpen(true);
  };

  const addMorePricePointsClose = () => {
    setIsMissingPricePointsOpen(false);
  };

  const onAddModePricePointsSuccess = () => {
    setIsMissingPricePointsOpen(false);
    refetchSourcePrices();
    refetchTargetPrices();
  };

  const mapProductsSmartly = () => {
    const smartMapping: IData = {};
    for (let i = 0; i < sourcePrices.length; i += 1) {
      const sourcePrice = sourcePrices[i];
      const match = targetPrices.find(
        (targetPrice) =>
          sourcePrice.interval === targetPrice.interval &&
          sourcePrice.interval_count === targetPrice.interval_count &&
          sourcePrice.unit_amount_cents === targetPrice.unit_amount_cents &&
          sourcePrice.unit_amount_currency === targetPrice.unit_amount_currency
      );

      if (match) {
        smartMapping[sourcePrice.id] = match.id;
      }
    }

    setMapping(smartMapping);
    setSelectedSourcePrices(Object.keys(smartMapping));
  };

  const isDisabled = useMemo(
    () => Object.keys(mapping).length === 0 || selectedSourcePrices.length !== Object.values(mapping).length,
    [mapping, selectedSourcePrices.length]
  );

  const isLoading = isLoadingSourcePrices || isLoadingTargetPrices;

  return (
    <Wizard step={3}>
      <div className="mt-4 py-4 px-4 sm:px-6">
        <MissingPricePointsSlideOver
          isOpen={isMissingPricePointsOpen}
          onClose={addMorePricePointsClose}
          onSuccess={onAddModePricePointsSuccess}
        />
        <div className="py-4">
          <Typography>
            Now you need to map the Stripe products from your existing newsletter to their corresponding equivalent in
            beehiiv. The next step will allow you to preview which subscriptions would be coming over in to what
            product. Use &quot;Smart Mapping&quot; option below to automatically map your products to beehiiv products
            at the same interval/price point.
          </Typography>
        </div>
        <LoadingBox isLoading={isLoading} isError={false}>
          <ProductMapSelections
            targetPrices={targetPrices}
            sourcePrices={sourcePrices}
            selectedSourcePrices={selectedSourcePrices}
            setSelectedSourcePrices={setSelectedSourcePrices}
            mapping={mapping}
            setMapping={setMapping}
          />
        </LoadingBox>
        <div className="mt-4 py-4 flex justify-end">
          <Button variant="primary-inverse" onClick={addMorePricePoints}>
            Add Beehiiv Price Points
          </Button>
          <Button variant="primary-inverse" className="ml-2" onClick={mapProductsSmartly}>
            Smart Mapping
          </Button>
          <Button
            variant="primary"
            className="ml-2"
            onClick={() => update({ product_mapping: mapping })}
            loading={isUpdating || isTransitioning}
            disabled={isDisabled}
          >
            Next
          </Button>
        </div>
      </div>
    </Wizard>
  );
};

export default StripeMigrationWizardStepThree;
