import { MISSING_SUBSCRIBER_STATUS_WARNING } from '../constants';

interface Props {
  handleAddActiveSubscribersCondition: () => void;
}

const FilterByActiveSubscribersWarning = ({ handleAddActiveSubscribersCondition }: Props) => (
  <div className="flex items-center mt-4 text-sm">
    <p className="text-gray-500">{MISSING_SUBSCRIBER_STATUS_WARNING}</p>
    <button type="button" className="ml-0.5 underline text-primary-500" onClick={handleAddActiveSubscribersCondition}>
      Filter by active subscribers
    </button>
  </div>
);

export default FilterByActiveSubscribersWarning;
