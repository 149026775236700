import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

export const focusThreadInEditor = (editor: Editor, threadId: string) => {
  if (!editor) return;

  let target: { node: Node | null; pos: number | null } = { node: null, pos: null };

  editor.state.doc.descendants((node, pos) => {
    const inlineThreadMark = node.marks.find((m) => m.type.name === 'inlineThread');

    if (inlineThreadMark && inlineThreadMark.attrs['data-thread-id'] === threadId) {
      target = { node, pos };
    }

    if (node.type.name === 'blockThread' && node.attrs['data-thread-id'] === threadId) {
      target = { node, pos };
    }
  });

  if (typeof target.pos === 'number' && target.pos >= 0) {
    editor
      .chain()
      .focus(target.pos + 1)
      .scrollIntoView()
      .run();
  }
};
