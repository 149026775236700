import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { QuestionResponse } from '../../interfaces/form';
import api from '../../services/swarm';

const useQuestionResponses = (formId: string | undefined, formResponseId: string | undefined, shouldFetch: boolean) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<QuestionResponse[]>(
    ['question_responses', formId, formResponseId],
    () =>
      api
        .get(`/forms/${formId}/question_responses`, {
          params: {
            form_response_id: formResponseId,
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: Boolean(formId && formResponseId && shouldFetch),
      refetchOnWindowFocus: false,
    }
  );
};

export default useQuestionResponses;
