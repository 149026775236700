import { FC } from 'react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePoll } from '@/hooks';
import { Filter } from '@/interfaces/segment';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  value: string;
  filter?: Filter;
  onChange: (value: string) => void;
}

const pollChoiceDataToOptions = (pollChoices: any[] = []) => {
  return pollChoices?.map((pollChoice: any) => ({ label: pollChoice?.label, value: pollChoice?.id })) || [];
};

const PollActionInput: FC<Props> = ({ value, filter, onChange }) => {
  const pollId = filter?.resource_id;
  const operator = filter?.operator;

  const [publicationId] = useCurrentPublicationState();
  const { data: poll } = usePoll(publicationId, pollId);

  if (pollId === 'any' || pollId === 'all' || operator === 'not_submitted') {
    return null;
  }

  const pollActionOptions = pollChoiceDataToOptions(poll?.poll_choices) || [];

  return (
    <Dropdown
      className="w-full"
      name="value"
      labelText="with the response"
      labelTextClassName="block -mt-[22px] text-[12px] text-gray-400 font-normal"
      placeholderText="Select Poll Choice"
      value={value || ''}
      onSelect={(name: string, selectedValue: string) => onChange(selectedValue)}
      options={pollActionOptions}
      emptyLabel="No poll choices found"
    />
  );
};

export default PollActionInput;
