import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Wrapped = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/?showWrapped=true');
  });
  return <div />;
};

export default Wrapped;
