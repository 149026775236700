import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { PencilIcon } from '@heroicons/react/24/outline';

import { BackLink } from '@/components/BackLink';
import LoadingBox from '@/components/LoadingBox';
import { Tab } from '@/interfaces/general';
import TabNavigation from '@/ui/TabNavigation';

// TODO: use this in Automations too

interface Props {
  tabs: Tab[];
  isLoading: boolean;
  isError: boolean;
  backPath: string;
  label?: string;
  onClickSettings?: () => void;
  statusBadge?: ReactNode;
  outletContext?: any;
  headerButtons?: ReactNode;
}

const WorkflowBuilderSkeletonView = ({
  label,
  tabs,
  isLoading,
  isError,
  backPath,
  onClickSettings,
  statusBadge,
  outletContext,
  headerButtons,
}: Props) => (
  <LoadingBox isLoading={isLoading} isError={isError}>
    <ReactFlowProvider>
      <div className="fixed top inset-x-0 bg-white z-10">
        <div className="md:flex md:justify-between items-center px-4 py-1 shadow-sm relative">
          <div className="md:w-full flex justify-between md:justify-start items-center pr-2 md:pr-0 space-x-4">
            <BackLink to={backPath}>Exit</BackLink>
            {statusBadge && <div>{statusBadge}</div>}
          </div>
          <div className="w-full flex justify-center py-2 group md:py-0">
            <div className="flex relative">
              <div className="max-w-lg truncate">
                <span className="text-sm font-gray-500">{label}</span>
              </div>
              {onClickSettings && (
                <button
                  className="absolute -right-8 h-6 w-6 flex justify-center items-center rounded border opacity-0 border-gray-200 text-gray-400 hover:bg-gray-200 hover:text-gray-600 group-hover:opacity-100 transition-all"
                  type="button"
                  onClick={onClickSettings}
                >
                  <PencilIcon className="h-3 w-3" />
                </button>
              )}
            </div>
          </div>
          <div className="flex gap-x-2 md:flex w-full py-2 md:py-0 justify-end">{headerButtons}</div>
        </div>
        <div className="py-2 md:py-0 bg-gray-50 border-b border-gray-200">
          <TabNavigation tabs={tabs} className="px-4 py-2" variant="tertiary" />
        </div>
      </div>
      {outletContext && <Outlet context={outletContext} />}
    </ReactFlowProvider>
  </LoadingBox>
);

export default WorkflowBuilderSkeletonView;
