import { useInfiniteQuery, useQueryClient } from 'react-query';

import { TimePeriod } from '@/utils';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Pagination } from '../../interfaces/general';
import { Poll } from '../../interfaces/poll';
import api from '../../services/swarm';

interface ApiResponse {
  polls: Poll[];
  pagination: Pagination;
}

export default function usePolls({
  search,
  perPage = 10,
  shouldFetchAll = false,
  dir = 'desc',
  order = 'poll.name',
  filteredPost = 'all',
  filterByDateCreated = TimePeriod.ALL_TIME,
  filterByDatePublished = TimePeriod.ALL_TIME,
}: {
  search?: string;
  perPage?: number;
  shouldFetchAll?: boolean;
  dir?: string;
  order?: string;
  filteredPost?: string;
  filterByDateCreated?: string;
  filterByDatePublished?: string;
}) {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchPolls = ({ pageParam = 1 }) =>
    api
      .get(`/polls`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: shouldFetchAll,
          publication_id: currentPublicationId,
          q: search,
          order,
          dir,
          filtered_post: filteredPost,
          filter_by_date_created: filterByDateCreated,
          filter_by_date_published: filterByDatePublished,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    [
      'publications',
      currentPublicationId,
      'polls',
      search,
      filteredPost,
      filterByDateCreated,
      filterByDatePublished,
      order,
      dir,
    ],
    fetchPolls,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      retry: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        const polls = data.pages.flatMap((page) => page.polls);

        polls.forEach((poll: any) => {
          queryClient.setQueryData(['polls', poll?.id], {
            id: poll?.id,
            title: poll?.title,
            description: poll?.description,
          });
        });
      },
      onError: (errPayload: any) => errPayload?.response?.data?.message || 'Something went wrong',
    }
  );
}
