import Card from '@/components/Card';
import { BarChart, BarChartDatum as Datum } from '@/components/charts';
import { LoadingSpinner } from '@/components/LoadingSpinner';

import MilestoneBreakdownChartContainer from './MilestoneBreakdownChartContainer';

export interface Milestone {
  id: string;
  name: string;
  trimmed_name: string;
  total_achieved: number;
}

const MilestoneBreakdownChart = () => {
  const renderEmptyState = () => <p className="mt-4">No data</p>;

  const renderBody = (milestones: Datum[] | undefined, isLoading: boolean) => {
    if (!isLoading && milestones && milestones.length === 0) {
      return renderEmptyState();
    }

    return milestones && <BarChart data={milestones} />;
  };

  return (
    <MilestoneBreakdownChartContainer>
      {(milestones, isLoading) => (
        <Card>
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4 flex">
            Breakdown by milestone {isLoading && <LoadingSpinner className="ml-2" />}
          </h3>
          {renderBody(milestones, isLoading)}
        </Card>
      )}
    </MilestoneBreakdownChartContainer>
  );
};

export default MilestoneBreakdownChart;
