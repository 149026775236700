import { Question } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

import { useNavbarContext } from '../../../NavbarContext';

const DEFAULT_ACTION = 'sign_in';
export const VisibilitySettingsReadOnly = () => {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item' || selectedContent.attrs?.type !== 'user_button') return null;

  const contentValue =
    selectedContent?.attrs && 'action' in selectedContent.attrs
      ? selectedContent.attrs.action || DEFAULT_ACTION
      : DEFAULT_ACTION;

  const options: Record<string, { title: string; tooltip: string }> = {
    sign_up: {
      title: 'Logged Out',
      tooltip: 'This button will be visible when the user is logged out',
    },
    sign_in: {
      title: 'Logged Out',
      tooltip: 'This button will be visible when the user is logged out',
    },
    profile: {
      title: 'Logged In',
      tooltip: 'This button will be visible when the user is logged in',
    },
  };

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px] min-w-[80px]" variant="secondary" size="2xs" weight="medium">
        Show when
      </Text>
      <div className="grow w-full bg-wb-primary border border-wb-soft rounded-lg flex items-center gap-2 p-2 cursor-default">
        <Text size="2xs" weight="medium" className="flex-1">
          {options[contentValue].title}
        </Text>
        <Tooltip center={options[contentValue].tooltip}>
          <Question size={16} className="text-wb-secondary" weight="bold" />
        </Tooltip>
      </div>
    </div>
  );
};
