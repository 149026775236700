import { AutomationCondition, AutomationConditions } from '@/interfaces/automations/automation_condition';
import { LogicalOperators } from '@/interfaces/condition';

import FormIdSelect from '../../components/FormIdSelect';

interface Props {
  mainConditions: AutomationConditions;
  onChange: (mainConditions: AutomationConditions) => void;
}

export const FormSubmissionTrigger = ({ mainConditions, onChange }: Props) => {
  const condition = mainConditions.conditions?.[0];

  const handleChange = (_name: string, selectedValue: string) => {
    onChange({
      logical_operator: LogicalOperators.AND,
      conditions: [{ ...condition, value: selectedValue } as AutomationCondition],
    });
  };

  return <FormIdSelect formId={condition?.value || ''} onChange={handleChange} />;
};
