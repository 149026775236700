import { useEffect, useState } from 'react';

import { useInfiniteScroll } from '@/hooks';
import useDisbursements from '@/hooks/useAdNetwork/internal/useDisbursements';

import { PageHeading } from '../_components/PageHeading';

import BulkApproveAction from './_components/BulkApproveAction/BulkApproveAction';
import { DisbursementRow } from './_components/DisbursementRow';
import DisbursementHeader from './_components/DisbursementRow/DisbursementHeader';
import { DisbursementSearch } from './_components/DisbursementSearch';
import { DisbursementSearchParams, DisbursementStatusFilter } from './_components/DisbursementSearch/types';

export default function Index() {
  const [queryParam, setQueryParam] = useState<string>('');
  const [statusFilterParam, setStatusFilterParam] = useState<DisbursementStatusFilter>('all');
  const [dateFilterParam, setDateFilterParam] = useState<string>('');
  const [payoutDateFilterParam, setPayoutDateFilterParam] = useState<string>('');

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, refetch, isLoading } = useDisbursements({
    query: queryParam,
    status: statusFilterParam,
    date: dateFilterParam,
    payoutDate: payoutDateFilterParam,
  });

  const disbursements = data?.pages.flatMap((page) => page.disbursements) || [];
  const pagination = data?.pages[0].pagination;

  const handleSearchChange = (search: DisbursementSearchParams) => {
    const { date, payoutDate, query, status } = search;
    setQueryParam(query);
    setStatusFilterParam(status);
    setDateFilterParam(date);
    setPayoutDateFilterParam(payoutDate);
  };

  useEffect(() => {
    refetch();
  }, [dateFilterParam, payoutDateFilterParam, queryParam, refetch, statusFilterParam]);

  const { ref: lastElementRef } = useInfiniteScroll({
    fetchNextPage,
    isLoading,
    hasNextPage: !!hasNextPage,
    isFetching: isFetchingNextPage,
  });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="">Disbursements</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <BulkApproveAction refetch={refetch} />
        </PageHeading.Side>
      </PageHeading>

      <DisbursementSearch onChange={handleSearchChange} />
      <table className="w-full text-sm divide-y divide-gray-100">
        <DisbursementHeader />
        <tbody className="divide-y divide-gray-100">
          {disbursements.map((disbursement, index) => (
            <DisbursementRow
              key={disbursement.id}
              disbursement={disbursement}
              ref={disbursements.length - 1 === index ? lastElementRef : null}
            />
          ))}

          <tr className="sticky bottom-0 bg-white">
            <td colSpan={5} className="px-4 py-2">
              <p>
                Showing {disbursements.length} of {pagination?.total || 0} disbursements
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
