import PageWrapper from '../_components/PageWrapper';

import ActiveSubscriptionsChart from './_components/ActiveSubscriptionsChart';
import RevenueByPeriodChart from './_components/RevenueByPeriodChart';

const MonetizeSubscriptionsGrowth = () => {
  return (
    <PageWrapper>
      <ActiveSubscriptionsChart />
      <RevenueByPeriodChart />
    </PageWrapper>
  );
};

export default MonetizeSubscriptionsGrowth;
