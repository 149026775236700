import { useState } from 'react';
import toast from 'react-hot-toast';
// import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import CustomFieldSelect from '@/components/CustomFieldSelect';
import { Input } from '@/components/Form';
import IconButton from '@/components/IconHelpers/IconButton';
import { Typography } from '@/components/Typography';
import { useDeleteFormQuestion, useUpdateFormQuestion } from '@/hooks/useForms';
import { CustomField } from '@/interfaces/custom_field';
import { FormQuestion, FormQuestionTypes, QuestionTypes } from '@/interfaces/form';
import { CustomFieldOption } from '@/interfaces/general';
import { Badge } from '@/ui/Badge';
import Switch from '@/ui/Switch';

import { useEditFormViewContent } from '..';

import { filterCustomFields } from './helpers';
import NewCustomField from './NewCustomField';
import Preview from './Preview';
import QuestionOptions from './QuestionOptions';

interface Props {
  questionType: FormQuestionTypes;
  question: FormQuestion;
}

const NewQuestionForm = ({ question, questionType }: Props) => {
  const { currentCustomFieldIds } = useEditFormViewContent();

  const [isCreatingCustomField, setIsCreatingCustomField] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRequired, setIsRequired] = useState(question.required || false);
  const [isMultiSelect, setIsMultiSelect] = useState(question.multi_select || false);
  const [isShowMaxCharacters, setShowMaxCharacters] = useState(question.show_max_characters || false);
  const [maxCharacterLimit, setMaxCharacterLimit] = useState(question.max_character_limit || 400);
  const [minCharacterLimit, setMinCharacterLimit] = useState(question.min_character_limit || 0);
  const [promptInput, setPromptInput] = useState<string>(question.prompt || '');
  const [fieldId, setFieldId] = useState<string>(question.custom_field.id || '');
  const [selectedCustomFieldOption, setSelectedCustomFieldOption] = useState<CustomFieldOption | null>(
    question
      ? {
          label: '',
          kind: question.custom_field.kind,
          value: question.custom_field.id,
        }
      : null
  );

  const { formId } = useParams();
  const deleteFormQuestion = useDeleteFormQuestion({
    formId: formId || '',
    formQuestionId: question.id,
  });
  const handleDelete = () => {
    deleteFormQuestion.mutate();
    setIsDeleting(false);
  };

  const updateFormQuestion = useUpdateFormQuestion({
    formQuestionId: question.id,
    formId: formId || '',
    onSuccess: () => {
      toast.success('Question saved!');
    },
  });

  const handleClickCreateNewCustomField = () => setIsCreatingCustomField(true);
  const handleCloseNewCustomFieldForm = () => setIsCreatingCustomField(false);
  const handleSuccessNewCustomField = (customField: CustomField) => {
    setFieldId(customField.id);
    setSelectedCustomFieldOption({
      label: customField.display,
      kind: customField.kind,
      value: customField.id,
    });

    updateFormQuestion.mutate({
      order: question.order,
      prompt: promptInput,
      custom_field_id: customField.id,
      question_type: questionType,
    });
  };

  const isMultipleChoiceQuestion = questionType === QuestionTypes.MULTIPLE_CHOICE;
  const isFreeFormQuestion = questionType === QuestionTypes.FREE_FORM;
  const isDropdownQuestion = questionType === QuestionTypes.DROPDOWN;
  const isLongTextQuestion = questionType === QuestionTypes.LONG_TEXT;

  return (
    <>
      <NewCustomField
        isOpen={isCreatingCustomField}
        isDropdownSelected={isDropdownQuestion}
        isMultipleChoiceSelected={isMultipleChoiceQuestion}
        isLongTextSelected={isLongTextQuestion}
        onClose={handleCloseNewCustomFieldForm}
        onSuccess={handleSuccessNewCustomField}
      />
      <ActionModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onProceed={handleDelete}
        resourceId={question.id}
        isWorking={deleteFormQuestion.isLoading}
        headerText="Delete Question"
        actionText="Delete"
      >
        Are you sure you want to delete this question?
      </ActionModal>
      <div className="flex flex-col gap-y-4 w-full pb-5 rounded-md overflow-hidden border border-surface-200 bg-white group">
        <div className="flex flex-col gap-y-3 bg-surface-50 px-6 py-5">
          <div className="flex justify-between items-center">
            <Badge type="information">
              {isFreeFormQuestion && `Short response`}
              {isMultipleChoiceQuestion && 'Multiple choice'}
              {isDropdownQuestion && 'Dropdown'}
              {isLongTextQuestion && 'Long response'}
            </Badge>
            <div className="invisible group-hover:visible">
              <IconButton onClick={() => setIsDeleting(true)}>
                <TrashIcon />
              </IconButton>
            </div>
          </div>

          <Input
            className="w-full"
            name="prompt"
            value={promptInput}
            labelText="Prompt"
            placeholder="Question prompt"
            helperText="This is the question that will be asked to the user"
            onBlur={() => {
              if (promptInput && promptInput !== question.prompt) {
                updateFormQuestion.mutate({
                  order: question.order,
                  prompt: promptInput,
                  custom_field_id: fieldId,
                  question_type: questionType,
                });
              }
            }}
            onChange={(e: any) => {
              setPromptInput(e.target.value);
            }}
          />

          <div className="flex flex-col gap-y-1">
            <CustomFieldSelect
              className=""
              labelText="Custom Field"
              customFieldId={fieldId}
              dropdownClassnames="rounded-md overflow-auto max-h-[10rem] nowheel"
              onSelectCustomField={(idVal: string, option?: CustomFieldOption) => {
                setFieldId(idVal);
                if (option) {
                  setSelectedCustomFieldOption(option);
                }

                const newIsMultiSelectValue =
                  option?.kind === 'list' && selectedCustomFieldOption?.kind === 'list' ? isMultiSelect : false;
                setIsMultiSelect(newIsMultiSelectValue);

                if (idVal && idVal !== question.custom_field.id) {
                  updateFormQuestion.mutate({
                    order: question.order,
                    prompt: promptInput,
                    custom_field_id: idVal,
                    question_type: questionType,
                    multi_select: newIsMultiSelectValue,
                  });
                }
              }}
              onFilterOptions={
                currentCustomFieldIds
                  ? filterCustomFields(
                      currentCustomFieldIds,
                      isMultipleChoiceQuestion,
                      isDropdownQuestion,
                      isLongTextQuestion,
                      fieldId
                    )
                  : undefined
              }
              onClearCustomField={() => {
                setFieldId('');
                setSelectedCustomFieldOption(null);
              }}
            />
            <Typography token="font-normal/text/xs" colorWeight="500">
              Select a custom field to associate with this question or{' '}
              <button type="button" onClick={handleClickCreateNewCustomField}>
                <Typography token="font-bold/text/xs" color="secondary" colorWeight="500">
                  create a new one
                </Typography>
              </button>
            </Typography>
          </div>

          {isMultipleChoiceQuestion && selectedCustomFieldOption && selectedCustomFieldOption.kind === 'list' && (
            <div className="space-y-2">
              <p className="block text-sm font-medium text-gray-700">Allow Multiple Responses?</p>
              <Switch
                variant="primary"
                name="multi_select"
                checked={isMultiSelect}
                onChange={(_name: string, value: boolean) => {
                  setIsMultiSelect(value);
                  updateFormQuestion.mutate({
                    order: question.order,
                    prompt: promptInput,
                    question_type: questionType,
                    custom_field_id: fieldId,
                    multi_select: value, // only updating this value
                  });
                }}
              />
              <p className="mt-2 text-xs text-gray-500">Allow subscribers to choose multiple options.</p>
            </div>
          )}

          {isLongTextQuestion && (
            <>
              <div className="space-y-2">
                <Input
                  name="max_character_limit"
                  value={maxCharacterLimit.toString()}
                  labelText="Maximum Character Limit"
                  helperText="The upper limit available is 400 characters."
                  onBlur={() => {
                    if (maxCharacterLimit && maxCharacterLimit !== question.max_character_limit) {
                      updateFormQuestion.mutate({
                        prompt: promptInput || '',
                        question_type: questionType,
                        custom_field_id: fieldId,
                        required: isRequired,
                        show_max_characters: isShowMaxCharacters,
                        max_character_limit: maxCharacterLimit,
                        order: question.order,
                      });
                    }
                  }}
                  onChange={(e: any) => {
                    setMaxCharacterLimit(e.target.value);
                  }}
                />
              </div>

              <Input
                name="min_character_limit"
                value={minCharacterLimit.toString()}
                labelText="Minimum Character Limit"
                helperText="Require a minimum number of characters in the answer before allowing the survey to be submitted (optional)"
                onBlur={() => {
                  if (minCharacterLimit && minCharacterLimit !== question.min_character_limit) {
                    updateFormQuestion.mutate({
                      order: question.order,
                      prompt: promptInput,
                      question_type: questionType,
                      custom_field_id: fieldId,
                      min_character_limit: minCharacterLimit,
                    });
                  }
                }}
                onChange={(e: any) => {
                  setMinCharacterLimit(e.target.value);
                }}
              />

              <div className="flex items-end">
                <Switch
                  variant="primary"
                  labelText="Show Max Characters"
                  helperText="Display the max character limit under the answer."
                  size="small"
                  name="show_max_characters"
                  checked={isShowMaxCharacters}
                  onChange={(_name: string, value: boolean) => {
                    setShowMaxCharacters(value);
                    updateFormQuestion.mutate({
                      order: question.order,
                      prompt: promptInput,
                      question_type: questionType,
                      custom_field_id: fieldId,
                      show_max_characters: value, // only updating this value
                    });
                  }}
                />
              </div>
            </>
          )}

          {(isMultipleChoiceQuestion || isDropdownQuestion) && <QuestionOptions question={question} />}
        </div>
        <div className="px-6">
          <Preview
            question={{
              ...question,
              custom_field: selectedCustomFieldOption?.kind
                ? { id: selectedCustomFieldOption.value, kind: selectedCustomFieldOption.kind }
                : question.custom_field,
              prompt: promptInput,
              required: isRequired,
              multi_select: isMultiSelect,
              show_max_characters: isShowMaxCharacters,
              max_character_limit: maxCharacterLimit,
              min_character_limit: minCharacterLimit,
            }}
          />
        </div>
        <div className="flex justify-end px-6">
          <Switch
            variant="primary"
            name="required"
            prefixLabelText="Required on form"
            size="small"
            checked={isRequired}
            onChange={(_name: string, value: boolean) => {
              setIsRequired(value);
              updateFormQuestion.mutate({
                prompt: promptInput || '',
                question_type: questionType,
                custom_field_id: fieldId,
                required: value, // only updating this value
                multi_select: question.multi_select,
                show_max_characters: question.show_max_characters,
                max_character_limit: question.max_character_limit,
                min_character_limit: question.min_character_limit,
                order: question.order,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default NewQuestionForm;
