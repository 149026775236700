import LoadingBox from '@/components/LoadingBox';
import Text from '@/components/Text';
import usePollEmailMessages from '@/hooks/usePolls/usePollEmailMessages';

import List from './List';

const EmailMessageList = ({ pollId }: { pollId: string }) => {
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = usePollEmailMessages({
    id: pollId,
  });
  const emailMessages = data?.pages.flatMap((page) => page.email_messages) || [];

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height="200px">
      {emailMessages?.length > 0 ? (
        <List
          resources={emailMessages}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      ) : (
        <Text size="sm">This poll has not appeared in any other emails</Text>
      )}
    </LoadingBox>
  );
};

export default EmailMessageList;
