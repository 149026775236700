import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { v4 as uuid } from 'uuid';

import { UpgradeIntentPlaceholder } from '../../components/ui/UpgradeIntentPlaceholder';

import { AiImageView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiImage: {
      setAiImage: () => ReturnType;
    };
  }
}

export const AiImage = Node.create({
  name: 'aiImage',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      authorId: undefined,
      authorName: undefined,
      enabled: false,
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({
          'data-id': attributes.id,
        }),
      },
      authorId: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-author-id'),
        renderHTML: (attributes) => ({
          'data-author-id': attributes.authorId,
        }),
      },
      authorName: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-author-name'),
        renderHTML: (attributes) => ({
          'data-author-name': attributes.authorName,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setAiImage:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                id: uuid(),
                authorId: this.options.authorId,
                authorName: this.options.authorName,
              },
            })
            .run(),
    };
  },

  addNodeView() {
    if (!this.options.enabled) {
      return ReactNodeViewRenderer(
        () => (
          <UpgradeIntentPlaceholder
            description="You need to upgrade to access AI writing features."
            url="https://www.beehiiv.com/create/ai"
            iconName="AIImage"
          />
        ),
        { className: 'upgrade-intent' }
      );
    }

    return ReactNodeViewRenderer(AiImageView);
  },
});

export default AiImage;
