import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import { useDeleteSubscription } from '@/hooks';

const useDeleteAction = ({ subscriberId }: { subscriberId: string }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteMutation = useDeleteSubscription({ subscriptionId: subscriberId });
  const navigate = useNavigate();

  const onDelete = () => {
    setIsDeleting(true);
    deleteMutation.mutate(
      {},
      {
        onSuccess: () => {
          toast.success('Subscriber successfully deleted');
          navigate('/subscribers');
        },
        onError: (errPayload: any) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        },
        onSettled: () => {
          setIsDeleting(false);
          setConfirmOpen(false);
        },
      }
    );
  };

  return [
    {
      label: 'Delete',
      helperText: 'Delete subscriber',
      onClick: () => setConfirmOpen(true),
      children: (
        <ActionModal
          isOpen={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onProceed={onDelete}
          isWorking={isDeleting}
          resourceId={subscriberId}
          headerText="Delete Subscriber"
          actionText="Delete"
        >
          Are you sure you want to permenantly delete this subscriber? All associated data will be lost.
        </ActionModal>
      ),
    },
  ];
};

export default useDeleteAction;
