import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import SlideOver from '@/components/SlideOver';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useTiers } from '@/hooks/useTiers';
import { IData } from '@/interfaces/general';
import { DefaultTierName } from '@/interfaces/tier';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import Switch from '@/ui/Switch';

import useCreateMissingTargetPrices from '../_hooks/useCreateMissingTargetPrices';
import useMissingTargetPrices from '../_hooks/useMissingTargetPrices';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface EnabledData {
  [key: string]: boolean;
}

const MissingPricePointsSlideOver: FC<Props> = (props: Props) => {
  const { stripeMigrationId } = useParams();

  const { isOpen, onSuccess, onClose } = props;
  const [pricePointsToEnable, setPricePointsToEnable] = useState<EnabledData>({});
  const [tierMapping, setTierMapping] = useState<IData>({});
  const slideOverBodyId = 'missing-price-points';

  const { data: targetMissingPrices, isLoading } = useMissingTargetPrices(stripeMigrationId, isOpen);

  const [publicationId] = useCurrentPublicationState();
  const tiersQuery = useTiers(publicationId);
  const tiers = tiersQuery.data || [];
  const tierOptions = tiers.map((t) => ({
    label: t.name,
    value: t.id,
  }));

  const defaultTierId = tierOptions.find((t) => t.label === DefaultTierName)?.value;

  const resetForm = () => {
    setPricePointsToEnable({});
  };

  const handleTierMappingSelect = (name: string, value: string) => {
    const priceId = name.replace('_tier', '');
    setTierMapping({ ...tierMapping, [priceId]: value });
  };

  const { mutate, isLoading: isSaving } = useCreateMissingTargetPrices({
    id: stripeMigrationId,
    onSuccess: () => {
      resetForm();
      onSuccess();
    },
  });

  const onCloseSlideOver = () => {
    resetForm();
    onClose();
  };

  const onSwitch = (name: string, value: boolean) => {
    setPricePointsToEnable({ ...pricePointsToEnable, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const existingPriceIds = Object.keys(pricePointsToEnable).filter((key) => pricePointsToEnable[key]);
    mutate({ existing_price_ids: existingPriceIds, tier_mapping: tierMapping });
  };

  const renderForm = (
    <div className="flex flex-col">
      {targetMissingPrices?.prices.map((price) => (
        <>
          <Switch
            name={price.id}
            labelText={price.display_label}
            checked={pricePointsToEnable[price.id] || false}
            className="mt-2"
            onChange={onSwitch}
          />
          {pricePointsToEnable[price.id] && (
            <div className="">
              <Dropdown
                key={price.id}
                name={`${price.id}_tier`}
                labelText="Select a Tier"
                helperText="Select a tier to map this price to. If you do not have a matching tier, please create one first."
                className="ml-14"
                options={tierOptions}
                value={tierMapping[price.id] || defaultTierId}
                onSelect={handleTierMappingSelect}
                required
              />
            </div>
          )}
        </>
      ))}
    </div>
  );

  const actions = (
    <div className="flex justify-end">
      <Button type="button" className="mx-2" variant="primary-inverse" block={false} onClick={onCloseSlideOver}>
        Cancel
      </Button>
      <Button variant="primary" type="button" block={false} loading={isSaving} onClick={handleSubmit}>
        Create Price Points
      </Button>
    </div>
  );

  const loadingSpinner = (
    <div className="flex py-6 space-x-2">
      <LoadingSpinner />
      <p>Loading Missing Prices</p>
    </div>
  );

  return (
    <SlideOver
      bodyId={slideOverBodyId}
      headerText="Create Beehiiv Price Points"
      isOpen={isOpen}
      onClose={onCloseSlideOver}
      actionsChildren={actions}
    >
      <div>
        <p className="mb-2">
          These prices exist in your existing Stripe account, but do not have a matching equivalent in beehiiv (matching
          is defined as same price, interval, and currency). <br />
          <br />
          Switch next to each price point below to create a beehiiv equivalent. We will create a new price point in
          beehiiv for each of them, and your existing publicly displayed prices on your publication will stay as-is.
        </p>
        {isLoading ? loadingSpinner : renderForm}
      </div>
    </SlideOver>
  );
};

export default MissingPricePointsSlideOver;
