import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import Text from '@/components/Text';
import { usePostTargets } from '@/hooks/usePostTargets';
import { PostTargetPlatform } from '@/interfaces/post_target';

import ManagedAudiences from '../_components/ManagedAudiences';

import Segments from './Segments';

interface EmailSectionProps {
  isLocked: boolean;
}

const EmailSection: React.FC<EmailSectionProps> = ({ isLocked }) => {
  const { postId } = useParams<'postId'>() as { postId: string };
  const { data, isLoading, isError } = usePostTargets(postId, PostTargetPlatform.EMAIL);

  const postSendTargets = useMemo(() => data?.post_targets || [], [data]);
  const excludedAudience = useMemo(() => !!postSendTargets.find((t) => t.action === 'exclude'), [postSendTargets]);

  return (
    <div className="space-y-1">
      <Text size="md" type="bold" className="text-gray-600">
        Email Recipients
      </Text>
      <div className="py-4 bg-white rounded-md border border-gray-200">
        <LoadingBox isLoading={isLoading} isError={isError}>
          <ManagedAudiences postSendTargets={postSendTargets} readOnly={isLocked} platform={PostTargetPlatform.EMAIL} />
          <Segments postSendTargets={postSendTargets} readOnly={isLocked} />
        </LoadingBox>
      </div>
      {isLocked && (
        <div className="pt-1">
          <Text size="xs" type="label">
            * You cannot edit the email audience of a confirmed post
          </Text>
        </div>
      )}
      {excludedAudience && !isLocked && (
        <div className="pt-1 flex space-x-1">
          <Text size="xs" type="label">
            *
          </Text>
          <Text size="xs" type="label">
            Excluding an audience will always take precedence when there is overlap in the selected audiences. If a
            subscriber is in both an included and excluded audience, the post will NOT be sent to them.
          </Text>
        </div>
      )}
    </div>
  );
};

export default EmailSection;
