import { useCallback } from 'react';

import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Asset } from '@/interfaces/asset';

export type ImageItemProps = {
  image: Asset;
  onSelect: (image: Asset) => void;
  onDelete: (image: Asset) => void;
  onEditMeta: (image: Asset) => void;
  onEditCrop: (image: Asset) => void;
  onEditColor: (image: Asset) => void;
};

const ImageItem = ({ image, onSelect, onDelete, onEditMeta, onEditCrop, onEditColor }: ImageItemProps) => {
  const handleClick = useCallback(() => {
    onSelect(image);
  }, [onSelect, image]);

  const handleDelete = useCallback(() => {
    onDelete(image);
  }, [onDelete, image]);

  const handleEditMeta = useCallback(() => {
    onEditMeta(image);
  }, [onEditMeta, image]);

  const handleEditCrop = useCallback(() => {
    onEditCrop(image);
  }, [onEditCrop, image]);

  const handleEditColor = useCallback(() => {
    onEditColor(image);
  }, [onEditColor, image]);

  const isGif = image?.url?.includes('.gif') || image?.url?.includes('.GIF');

  const buttonClass = 'w-8 h-8 flex items-center justify-center hover:bg-gray-50';

  return (
    <div className="relative overflow-hidden bg-gray-100 border border-gray-200 rounded aspect-w-1 aspect-h-1 group">
      <div className="absolute bottom-auto left-auto z-20 flex items-center w-auto h-auto overflow-hidden transition-opacity bg-white rounded shadow opacity-0 top-2 right-2 group-hover:opacity-100">
        <button onClick={handleEditMeta} className={buttonClass} type="button">
          <Icon name="Info" className="w-3.5 h-3.5" />
        </button>
        {!isGif ? (
          <>
            <button onClick={handleEditCrop} className={buttonClass} type="button">
              <Icon name="Crop" className="w-3.5 h-3.5" />
            </button>
            <button onClick={handleEditColor} className={buttonClass} type="button">
              <Icon name="Fill" className="w-3.5 h-3.5" />
            </button>
          </>
        ) : null}
        <button onClick={handleDelete} className={buttonClass} type="button">
          <Icon name="Trash" className="w-3.5 h-3.5" />
        </button>
      </div>
      <button className="absolute inset-0 z-10 block w-full h-full" onClick={handleClick} type="button">
        <img className="block object-contain w-full h-full" src={image.url} alt={image.alt || ''} />
      </button>
    </div>
  );
};

export default ImageItem;
