import Tippy from '@tippyjs/react';

import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';
import { Author } from '@/interfaces/author';

import { usePostContext } from '../../PostContext';
import AuthorsActionsMenu from '../AuthorsMenu/AuthorsActionsMenu';

interface Props {
  guestAuthors: Author[];
  guestAuthorId?: string;
  users: Author[];
  userId?: string;
  setShowCreateGuestAuthorModal: (value: boolean) => void;
}

const AuthorsTooltipMenu = ({ users, guestAuthors, guestAuthorId, setShowCreateGuestAuthorModal, userId }: Props) => {
  const { formData, onChange } = usePostContext();
  const deleteAuthor = (type: string, authorId: string) => {
    let newUserIDs = formData?.user_ids || [];
    let newGuestAuthorIDs = formData?.guest_author_ids || [];

    if (type === 'user') {
      newUserIDs = newUserIDs?.filter((id) => id !== authorId);
    }

    if (type === 'guest') {
      newGuestAuthorIDs = newGuestAuthorIDs?.filter((id) => id !== authorId);
    }

    onChange({ user_ids: newUserIDs, guest_author_ids: newGuestAuthorIDs });
  };

  return (
    <div className="flex h-[3rem] bg-gray-800 rounded-lg p-2">
      <Tippy
        offset={[0, 8]}
        placement="bottom-start"
        popperOptions={{
          modifiers: [{ name: 'flip' }],
        }}
        trigger="click"
        interactive
        content={
          <div className="absolute">
            <AuthorsActionsMenu
              guestAuthors={guestAuthors}
              users={users}
              setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
              smallVersion
            />
          </div>
        }
        zIndex={EDITOR_MENUS_Z_INDEX}
      >
        <Button $variant="quaternary" $leftSlot={<Icon name="Person" className="mr-2" />} />
      </Tippy>
      <Button
        $variant="quaternary"
        $leftSlot={<Icon name="Trash" />}
        onClick={() => {
          if (userId) {
            deleteAuthor('user', userId);
          } else if (guestAuthorId) {
            deleteAuthor('guest', guestAuthorId);
          }
        }}
      />
    </div>
  );
};

export default AuthorsTooltipMenu;
