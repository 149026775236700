import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

export interface OpensByHour {
  [key: number]: number;
}

interface Data {
  opens_by_hour: OpensByHour;
}

const useOpensByHour = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<Data>(
    ['publications', currentPublicationId, 'insights', 'opens-by-hour'],
    () =>
      api
        .get(`/insights/opens_by_hour`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );
};

export default useOpensByHour;
