import { PublicationSearch } from '@/interfaces/publication_search';

interface Props {
  publication: PublicationSearch;
  onTagClick?: (value: string) => void;
}

const ActiveItem = ({ publication, onTagClick }: Props) => (
  <div className="flex-none p-6">
    <div className="w-16 h-16 border rounded overflow-hidden">
      <figure className="aspect-w-1 aspect-h-1 relative h-full w-full">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={publication.logo_url}
          alt={publication.name}
        />
      </figure>
    </div>
    <h2 className="mt-3 font-semibold text-gray-900">{publication.name}</h2>
    <p className="text-sm leading-6 text-gray-500 text-left line-clamp-2">{publication.description}</p>
    <ul>
      {publication.tags.map((tag: string) => (
        <li key={tag} className="inline-block mr-2">
          <div>
            <button type="button" onClick={() => onTagClick?.(tag)}>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                #{tag}
              </span>
            </button>
          </div>
        </li>
      ))}
    </ul>
    <a
      href={`https://${publication.hostname}`}
      className="text-xs leading-6 text-gray-500 text-left line-clamp-1"
      target="_blank"
      rel="noreferrer"
    >
      {publication.hostname}
    </a>
  </div>
);

export default ActiveItem;
