import moment from 'moment-mini';

import LoadingBox from '@/components/LoadingBox';
import useDisconnectIntegration from '@/hooks/useIntegrations/useDisconnectIntegration';
import useIntegrations from '@/hooks/useIntegrations/useIntegrations';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { IntegrationProvider } from '@/interfaces/integration';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

const MailchimpSection = () => {
  const publicationId = useCurrentPublicationId();
  const integrationsQuery = useIntegrations({ publicationId, provider: IntegrationProvider.MAILCHIMP });
  const { data, isLoading, isError } = integrationsQuery;

  const integrations = data?.pages.flatMap((page) => page.integrations) || [];

  const disconnect = useDisconnectIntegration(publicationId);

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height={200}>
      {data && (
        <>
          <div>
            {integrations.length > 0 && (
              <ul className="divide-y">
                {integrations?.map((integration) => (
                  <li key={integration.id} className="py-4">
                    <div className="flex">
                      <div className="mr-4 flex-shrink-0">
                        {integration.info.image_url ? (
                          <div className="w-12 rounded overflow-hidden">
                            <div className="aspect-w-12 aspect-h-12">
                              <img src={integration.info.image_url} alt={integration.provider} />
                            </div>
                          </div>
                        ) : (
                          <svg
                            className="h-12 w-12 border border-gray-300 bg-white text-gray-300"
                            preserveAspectRatio="none"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 200 200"
                            aria-hidden="true"
                          >
                            <path vectorEffect="non-scaling-stroke" strokeWidth={1} d="M0 0l200 200M0 200L200 0" />
                          </svg>
                        )}
                      </div>
                      <div className="space-y-1 text-sm text-gray-500">
                        <p className="text-gray-900">Account #{integration.uid}</p>
                        <p>
                          <span>Connected on </span>
                          <time dateTime={integration.created_at}>{moment(integration.created_at).format('LLL')}</time>
                        </p>
                        <div className="hover:text-red-500 text-gray-400">
                          <button
                            onClick={() =>
                              disconnect.mutateAsync({ integrationId: integration.id }).then(() => {
                                integrationsQuery.refetch();
                              })
                            }
                            type="button"
                          >
                            Disconnect
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <a
              onClick={() => {
                analytics.track('Mailchimp Integration');
                return true;
              }}
              href="/auth/mailchimp"
            >
              <Button type="button" variant="primary-inverse">
                Add integration
              </Button>
            </a>
          </div>
        </>
      )}
    </LoadingBox>
  );
};

export default MailchimpSection;
