import React, { useState } from 'react';
import { useQuery } from 'react-query';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';

import { EmailMessage, EmailMessagePurpose, EmailMessageResourceType } from '../../../../../interfaces/email_message';
import api from '../../../../../services/swarm';
import ActionModal from '../../../../ActionModal';
import { DatePicker } from '../../../../Form';

interface ScheduleModalProps {
  emailMessage: EmailMessage;
  onSchedule: (emailMessage: EmailMessage, date: Date) => Promise<void>;
}

const ScheduleModal: React.FunctionComponent<ScheduleModalProps> = ({
  emailMessage,
  onSchedule,
}: ScheduleModalProps) => {
  const currentPublicationId = useCurrentPublicationId();
  const [modalOpen, setModalOpen] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(new Date());
  const minScheduleTime = new Date();
  minScheduleTime.setHours(0, 0, 0, 0);

  const futureTimeSelected = scheduleTime > new Date();
  const isBlast = emailMessage.message_purpose === EmailMessagePurpose.BLAST;
  const segmentResourcePresent =
    emailMessage.resource_type === EmailMessageResourceType.SEGMENT && !!emailMessage.resource_id;

  const {
    data: { subscription_data: subscriptionData, name: segmentName, type: segmentType },
  } = useQuery(
    ['segment', emailMessage.resource_id, 'subscription_data'],
    () =>
      api
        .get(`/segments/${emailMessage.resource_id}/metrics/subscriptions`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),

    {
      keepPreviousData: true,
      enabled: isBlast && segmentResourcePresent,
      initialData: {},
    }
  );

  const SegmentData = () => {
    return (
      <div className="py-4">
        <p className="text-sm text-gray-500 mt-2 max-w-xl">
          <span className="font-medium text-gray-900">Please note:</span> This email will only go out to active
          subscribers in this segment. Once this email is scheduled, you will no longer be able to make changes. Please
          ensure the following is correct.
        </p>
        <div className="bg-gray-100 p-4 rounded mt-2">
          <p className="text-xs">
            <span className="font-semibold">Segment Name:</span>
            <span className="ml-2 text-gray-700 font-normal">{segmentName || '-'}</span>
          </p>
          <p className="text-xs mt-1">
            <span className="font-semibold">Active Subscribers:</span>
            <span className="ml-2 text-gray-700 font-normal">
              {subscriptionData?.active_subscriptions?.toLocaleString('en-US') || '-'}
            </span>
          </p>
          {segmentType === 'dynamic' && (
            <p className="text-xs text-gray-500 mt-3">
              <span className="text-gray-900">*Dynamic Segment Note:</span>&nbsp; If scheduling for a future date, the
              number of active subscribers this blast is being sent to
              <span className="text-gray-900">&nbsp;will not&nbsp;</span>
              change when the segment recalculates. If you need the most up-to-date results of the segment, do not
              schedule this blast for a future date.
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <ActionModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        resourceId={emailMessage.id}
        isWorking={isScheduling}
        onProceed={() => {
          setIsScheduling(true);
          onSchedule(emailMessage, scheduleTime).finally(() => setIsScheduling(false));
        }}
        headerText="Schedule"
        actionText={futureTimeSelected ? 'Schedule' : 'Publish now'}
        modalSize="lg"
        overflow="visible"
      >
        <p className="text-sm text-gray-500 mt-2 max-w-xl">
          Pick a time for this message to go out and then click &quot;Publish&quot;. Selecting a time in the past will
          publish this message immediately. If you are not ready to publish this message, you can click
          &quot;Cancel&quot;.
        </p>
        {isBlast && <SegmentData />}
        <DatePicker
          inline
          className="mt-4"
          value={scheduleTime}
          minDate={minScheduleTime}
          onChange={(date) => date && setScheduleTime(date)}
        />
      </ActionModal>
      <Button onClick={() => setModalOpen(true)}>Schedule or Publish</Button>
    </>
  );
};

export default ScheduleModal;
