export const ImageRightCenter = {
  name: 'Image Right, Center',
  value: {
    node: 'div',
    children: [
      {
        node: 'div',
        className: 'flex flex-col col-span-12 sm:col-span-8 gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
      {
        node: 'image',
        children: [],
        className: 'col-span-12 sm:col-span-4',
      },
    ],
    className: 'grid grid-cols-1 sm:grid-cols-12 gap-4 w-full items-center',
  },
};

export const ImageRight = {
  name: 'Image Right',
  value: {
    node: 'div',
    children: [
      {
        node: 'div',
        className: 'flex flex-col col-span-12 sm:col-span-8 gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
      {
        node: 'image',
        children: [],
        className: 'col-span-12 sm:col-span-4',
      },
    ],
    className: 'grid grid-cols-1 sm:grid-cols-12 gap-4 w-full',
  },
};

export const ImageRightCenterFlex = {
  name: 'Image Right, Center (Flex)',
  value: {
    node: 'div',
    children: [
      {
        node: 'div',
        className: 'flex flex-col gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
      {
        node: 'image',
        children: [],
        className: '',
      },
    ],
    className: 'flex justify-between gap-4 w-full items-center',
  },
};

export const ImageRightFlexBetween = {
  name: 'Image Right (Flex, Space Between)',
  value: {
    node: 'div',
    children: [
      {
        node: 'div',
        className: 'flex flex-col gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
      {
        node: 'image',
        children: [],
        className: '',
      },
    ],
    className: 'flex justify-between gap-4 w-full',
  },
};

export const ImageRightFlex = {
  name: 'Image Right (Flex)',
  value: {
    node: 'div',
    children: [
      {
        node: 'div',
        className: 'flex flex-col gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
      {
        node: 'image',
        children: [],
        className: '',
      },
    ],
    className: 'flex gap-4 w-full',
  },
};

export const ImageLeftCenter = {
  name: 'Image Left, Center (Grid)',
  value: {
    node: 'div',
    children: [
      {
        node: 'image',
        children: [],
        className: 'col-span-12 sm:col-span-4',
      },
      {
        node: 'div',
        className: 'flex flex-col col-span-12 sm:col-span-8 gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
    ],
    className: 'grid grid-cols-1 sm:grid-cols-12 gap-4 w-full items-center',
  },
};

export const ImageLeft = {
  name: 'Image Left (Grid)',
  value: {
    node: 'div',
    children: [
      {
        node: 'image',
        children: [],
        className: 'col-span-12 sm:col-span-4',
      },
      {
        node: 'div',
        className: 'flex flex-col col-span-12 sm:col-span-8 gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
    ],
    className: 'grid grid-cols-1 sm:grid-cols-12 gap-4 w-full',
  },
};

export const ImageLeftCenterFlex = {
  name: 'Image Left, Center (Flex)',
  value: {
    node: 'div',
    children: [
      {
        node: 'image',
        children: [],
        className: '',
      },
      {
        node: 'div',
        className: 'flex flex-col gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
    ],
    className: 'flex justify-between gap-4 w-full items-center',
  },
};

export const ImageLeftFlexBetween = {
  name: 'Image Left (Flex, Space Between)',
  value: {
    node: 'div',
    children: [
      {
        node: 'image',
        children: [],
        className: '',
      },
      {
        node: 'div',
        className: 'flex flex-col gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
    ],
    className: 'flex justify-between gap-4 w-full',
  },
};

export const ImageLeftFlex = {
  name: 'Image Left (Flex)',
  value: {
    node: 'div',
    children: [
      {
        node: 'image',
        children: [],
        className: '',
      },
      {
        node: 'div',
        className: 'flex flex-col gap-2',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-1 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
      },
    ],
    className: 'flex gap-4 w-full',
  },
};

export const ImageTop = {
  name: 'Image Top',
  value: {
    node: 'div',
    children: [
      {
        node: 'image',
        children: [],
        className: '',
      },
      {
        node: 'div',
        children: [
          {
            node: 'tags',
            children: [],
            className: '',
          },
          {
            node: 'div',
            children: [
              {
                node: 'title',
                children: [],
                className: '',
              },
              {
                node: 'subtitle',
                children: [],
                className: '',
              },
              {
                node: 'authors',
                children: [],
                className: 'text-xs',
              },
              {
                node: 'div',
                children: [
                  {
                    node: 'timestamp',
                    children: [],
                    className: '',
                  },
                  {
                    node: 'premium',
                    children: [],
                    className: '',
                  },
                ],
                className: 'flex flex-row gap-2 items-center flex-wrap',
              },
            ],
            className: 'flex flex-col gap-1',
          },
        ],
        className: 'flex flex-col gap-2',
      },
    ],
    className: 'flex flex-col gap-4 w-full w-full',
  },
};
