import { useCallback, useMemo } from 'react';
import { CurrencyDollar } from '@phosphor-icons/react';

import type { PremiumOffer } from '@/interfaces/premium_offer';
import usePremiumOffers from '@/routes/settings/publication/premium/_hooks/usePremiumOffers';

import { PopoverHelper, PopoverItem } from '../../helpers/PopoverHelper';
import type { AttributeSettingProps } from '../../types';

type Props = AttributeSettingProps & {
  property: string;
  title: string;
};

const OfferSettings = ({ editor, activeNodeResult, property, title = 'Offer' }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const { data } = usePremiumOffers();

  const offerOptions = useMemo(() => {
    const offers = data?.pages[0]?.premium_offers || [];
    return [
      { label: 'None', value: '' },
      ...offers.map((offer: PremiumOffer) => ({
        label: offer.name,
        value: offer.id,
      })),
    ];
  }, [data]);

  const textValue = offerOptions.find((option) => option.value === activeNodeAttributes?.[property])?.label || 'None';

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property]
  );

  return (
    <PopoverHelper title={title} text={textValue} popoverTitle={title} Icon={CurrencyDollar}>
      <div className="flex flex-col gap-2">
        {offerOptions.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={false}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};

export default OfferSettings;
