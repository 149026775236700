import { memo, useCallback, useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';

import { IntentAction } from '../../../../../interfaces/upgrades';
import { PLAN } from '../../../../../utils/plans';
import { UpgradeIntent } from '../../../../UpgradeIntent';
import { useConsumption } from '../../../lib/hooks/useConsumption';
import { Button } from '../../ui/Button';
import { ConsumptionProgress } from '../../ui/ConsumptionProgress';
import { Icon } from '../../ui/Icon';
import { Loader } from '../../ui/Loader';
import { Panel, PanelDivider, PanelHeader, PanelSection } from '../../ui/Panel';
import { Tooltip } from '../../ui/Tooltip';

import { languages } from './constants';
import Styled from './styled';
import { LanguageType } from './types';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: true,
};

type AiToolsPanelProps = {
  onShowImproveWriting: () => void;
  isFetching?: boolean;
  consumption: ReturnType<typeof useConsumption>['consumption'];
  isConsumptionExceeded: ReturnType<typeof useConsumption>['isConsumptionExceeded'];
  refetchConsumption: ReturnType<typeof useConsumption>['refetchConsumption'];
  showConsumption: ReturnType<typeof useConsumption>['showConsumption'];
  onComplete: () => Promise<void>;
  onFixSpelling: () => Promise<void>;
  onTranslate: (language: string) => void;
};

export const AiToolsPanel = memo(
  ({
    isFetching,
    onShowImproveWriting,
    consumption,
    onComplete,
    isConsumptionExceeded,
    refetchConsumption,
    showConsumption,
    onFixSpelling,
    onTranslate,
  }: AiToolsPanelProps): JSX.Element => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [isTranslationPanelOpen, setIsTranslationPanelOpen] = useState(false);

    const currentPublicationId = useCurrentPublicationId();
    const { data: settings } = usePublicationSettings(currentPublicationId);
    const shouldUpsell = !settings || settings.ai_editor_requests < 1;
    const [upgradeIntentOpen, setUpgradeIntentOpen] = useState(false);

    const handleFixSpelling = useCallback(async () => {
      setIsPanelOpen(false);
      await onFixSpelling();
    }, [onFixSpelling]);

    const handleComplete = useCallback(async () => {
      setIsPanelOpen(false);
      await onComplete();
      onShowImproveWriting();
    }, [onComplete, onShowImproveWriting]);

    const renderDefaultPanel = (attrs: any) => {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Panel tabIndex={-1} {...attrs}>
          <Styled.ButtonWrapper>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={onShowImproveWriting}
              $leftSlot={<Icon name="AIImproveWriting" />}
              $rightSlot={<Icon name="ChevronRight" />}
              $fullWidth
              disabled={isConsumptionExceeded}
            >
              Improve writing
            </Button>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => setIsTranslationPanelOpen(true)}
              $leftSlot={<Icon name="Translation" />}
              $rightSlot={<Icon name="ChevronRight" />}
              $fullWidth
              disabled={isConsumptionExceeded}
            >
              Translate
            </Button>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={handleFixSpelling}
              $leftSlot={<Icon name="AISpellingGrammar" />}
              $fullWidth
              disabled={isConsumptionExceeded}
            >
              Fix spelling & grammar
            </Button>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={handleComplete}
              $leftSlot={<Icon name="AICompleteSentence" />}
              $fullWidth
              disabled={isConsumptionExceeded}
            >
              Complete sentence
            </Button>
            {showConsumption && (
              <>
                <PanelDivider />
                <Styled.ConsumptionWrapper>
                  <ConsumptionProgress
                    used={consumption.consumedRequests || 0}
                    available={consumption.monthlyAvailableRequests || 0}
                    label="Monthly AI requests"
                  />
                  {isConsumptionExceeded && (
                    <Styled.ConsumptionHint>
                      Monthly limit reached.
                      <br />
                      <a href="/?new_support_ticket=true" target="_blank">
                        Contact support
                      </a>
                    </Styled.ConsumptionHint>
                  )}
                </Styled.ConsumptionWrapper>
              </>
            )}
          </Styled.ButtonWrapper>
        </Panel>
      );
    };

    const renderTranslationPanel = () => {
      return (
        <Panel>
          <PanelHeader>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => setIsTranslationPanelOpen(false)}
              $leftSlot={<Icon name="ChevronLeft" />}
              $fullWidth
            >
              Translate to
            </Button>
          </PanelHeader>
          <PanelSection>
            <Styled.ButtonWrapper>
              {languages.map((language: LanguageType) => (
                <Button
                  key={language.name}
                  $variant="quaternary"
                  $size="small"
                  onClick={() => onTranslate(language.value)}
                  $fullWidth
                  disabled={isConsumptionExceeded}
                >
                  {language.label}
                </Button>
              ))}
            </Styled.ButtonWrapper>
          </PanelSection>
        </Panel>
      );
    };

    return (
      <>
        <UpgradeIntent
          isOpen={upgradeIntentOpen}
          intentAction={IntentAction.USE_AI_WRITING_TOOLS}
          onClose={() => setUpgradeIntentOpen(false)}
          preselectedPlan={PLAN.SCALE}
        />
        <Tippy
          render={(attrs) => {
            if (isTranslationPanelOpen) {
              return renderTranslationPanel();
            }

            return renderDefaultPanel(attrs);
          }}
          offset={[0, 8]}
          placement="bottom"
          interactive
          visible={isPanelOpen}
          onShow={() => {
            refetchConsumption();
            setIsPanelOpen(true);
          }}
          onHidden={() => {
            setIsPanelOpen(false);
            setIsTranslationPanelOpen(false);
          }}
          onClickOutside={() => {
            setIsPanelOpen(false);
          }}
        >
          <div>
            <Tooltip title="AI writing tools">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $leftSlot={<Icon name="AI" />}
                $rightSlot={<Icon name="ChevronDown" $size="0.66rem" />}
                $active={isPanelOpen}
                $muted
                onClick={() => {
                  if (shouldUpsell) {
                    setUpgradeIntentOpen(true);
                  } else {
                    setIsPanelOpen(!isPanelOpen);
                  }
                }}
              />
            </Tooltip>
          </div>
        </Tippy>
        {isFetching && <Loader label="Generating cool new stuff" />}
      </>
    );
  }
);

AiToolsPanel.displayName = 'AiToolsPanel';

export default AiToolsPanel;
