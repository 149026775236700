import { useEffect, useRef, useState } from 'react';

interface LinkPopoverProps {
  children: React.ReactNode;
}

const LinkPopover: React.FC<LinkPopoverProps> = ({ children }: LinkPopoverProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [allowHover, setAllowHover] = useState(false);
  const childRef = useRef<any>(null);

  // Only let hover affect happen if the width of the url is greater than the truncated constraint
  useEffect(() => {
    if (childRef.current?.scrollWidth >= childRef?.current?.offsetWidth) {
      setAllowHover(true);
    }
  }, [childRef, setAllowHover]);

  return (
    <td
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className="py-4 whitespace-nowrap text-sm font-medium"
    >
      {allowHover && isHovering && (
        <div className="transition-all ease-in-out absolute w-full overflow-x-auto px-6 py-4 -mt-4 max-w-full flex items-center z-10 whitespace-nowrap bg-white shadow">
          {children}
        </div>
      )}
      <div className="px-6 max-w-sm truncate" ref={childRef}>
        {children}
      </div>
    </td>
  );
};

export default LinkPopover;
