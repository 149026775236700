import { BodyBase, Title2xl, TypographyStack } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';

interface Props {
  timePeriodSelectChild: React.ReactNode;
}

const Header = ({ timePeriodSelectChild }: Props) => {
  const { currentUser } = useCurrentUser();

  return (
    <div className="flex items-end justify-between">
      <div className="flex items-center space-x-2">
        <TypographyStack gap="1">
          <Title2xl>Hi {currentUser?.first_name} 👋</Title2xl>
          <BodyBase>Here&#39;s how your publication is doing</BodyBase>
        </TypographyStack>
        {timePeriodSelectChild}
      </div>
    </div>
  );
};

export default Header;
