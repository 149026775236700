import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Publication } from '@/interfaces/publication';
import api from '@/services/swarm';
import Switch from '@/ui/Switch';

import { SettingsPageLayout } from '../../Components';

import useNotificationSettings from './useNotificationSettings';

export interface NotificationSetting {
  code: string;
  description: string;
  id: string | null;
  name: string;
  setting_type: string;
  value: string | boolean | number;
  value_origin: string;
  roles: string[];
  plans: string[];
}

interface Props {
  settings: NotificationSetting[];
  publication: Publication;
  refetch: () => void;
}

interface Variables {
  code: string;
  value: string;
}

const Notifications = ({ settings, refetch, publication }: Props) => {
  const updateSetting = ({ code, value }: Variables): Promise<any> => {
    return api
      .patch(`/notification_settings/${code}`, {
        notification_setting: {
          setting_code: code,
          value,
        },
        publication_id: publication.id,
      })
      .then((res) => res.data);
  };

  const { mutateAsync: update, isLoading: isSaving } = useMutation(updateSetting, {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSettled: () => {
      refetch();
    },
  });

  const { mutateAsync: resetAll, isLoading: isRestoring } = useMutation(
    () => {
      return api
        .delete(`/notification_settings/reset_all`, {
          params: { publication_id: publication.id },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        toast.success('Settings reset');
      },
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSettled: () => {
        refetch();
      },
    }
  );

  return (
    <SettingsPageLayout title={`${publication.name} / Notifications`}>
      <div className="max-w-xl mx-auto p-6 space-y-6">
        <div className="flex items-start justify-between">
          <div className="flex space-x-2 items-center">
            <div className="leading-tight">
              <h1 className="font-semibold text-black space-x-2 line-clamp-2">
                <span>Notifications</span>
              </h1>
            </div>
            {(isSaving || isRestoring) && <LoadingSpinner className="opacity-50" size="sm" delay={500} />}
          </div>
          {settings.some((setting) => setting.value_origin === 'override') && (
            <button className="text-xs text-gray-700 flex-shrink-0" type="button" onClick={() => resetAll()}>
              Restore defaults
            </button>
          )}
        </div>
        <ul className="space-y-6">
          {settings.map((setting) => {
            return (
              <li key={setting.code}>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold text-sm text-gray-700">{setting.name}</p>
                    <p className="text-sm text-gray-400">{setting.description}</p>
                  </div>
                  <div>
                    {setting.setting_type === 'on_off' && (
                      <Switch
                        variant="primary"
                        checked={setting.value === 'true' || setting.value === true}
                        onChange={(_name, checked) => update({ code: setting.code, value: checked ? 'true' : 'false' })}
                        name={`settings[${setting.code}]`}
                      />
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <hr />
        <p className="text-sm text-gray-400">
          Showing all notification preferences for <span className="font-semibold">{publication.name}</span>.
        </p>
      </div>
    </SettingsPageLayout>
  );
};

export default function Loader() {
  const currentPublicationId = useCurrentPublicationId();
  const { isSuccess, isPublicationSuccess, publication, refetch, settings } =
    useNotificationSettings(currentPublicationId);

  if (!isSuccess || !isPublicationSuccess || !settings || !publication) return null;

  return <Notifications settings={settings} publication={publication} refetch={refetch} />;
}
