import ReactTooltip from 'react-tooltip';
import moment from 'moment-mini';

import { OpensByHour } from '@/hooks/useInsights/useOpensByHour';

const RecommendedTime = ({
  data,
  onClickRecommended,
}: {
  data: OpensByHour;
  onClickRecommended: (val: string) => void;
}) => {
  const mostOpensHour = Object.keys(data).reduce((a, b) => (data[Number(a)] > data[Number(b)] ? a : b));
  const mostOpens = data[Number(mostOpensHour)];
  if (mostOpens === 0) return null;
  const recommendedHour = moment().utc().hour(Number(mostOpensHour)).minute(0).second(0).local();
  const nextRecommendedTime = recommendedHour.isAfter(moment()) ? recommendedHour : recommendedHour.add(1, 'day');

  return (
    <p className="text-sm">
      <ReactTooltip id="use-recommended-time" place="bottom" type="dark" effect="solid">
        Use recommended time
      </ReactTooltip>
      <button
        className="font-bold hover:underline text-primary-500"
        type="button"
        onClick={() => onClickRecommended(nextRecommendedTime.format('YYYY-MM-DDTHH:mm'))}
        data-tip
        data-for="use-recommended-time"
      >
        {recommendedHour.format('h:mm A')}
      </button>{' '}
      appears to be when your subscribers are most engaged.
    </p>
  );
};

export default RecommendedTime;
