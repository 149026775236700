import { MultiSelect } from '@/components/Form';
import ErrorText from '@/components/Form/ErrorText';
import { Typography } from '@/components/Typography';
import { WebhooksEventType } from '@/interfaces/webhooks/endpoint';
import { StepProps } from '@/pages/Settings/Pages/Integrations/Webhooks/Endpoints/Create/Steps/types';

const eventTypeOptions = Object.values(WebhooksEventType).map((eventType) => [eventType, eventType]);
const eventTypeTooltips = {
  'subscription.created': 'Fires when a subscription is created, regardless of status.',
  'subscription.confirmed':
    'Fires when a subscription becomes “active.” If double opt-in is used, this will occur after the subscriber has confirmed in the opt-in email. if double opt-in is off, this will occur on all new signups.',
  'subscription.deleted': 'Fires when an active subscription unsubscribes.',
  'subscription.upgraded': 'Fires when a free subscription upgrades to any premium tier.',
  'subscription.downgraded':
    'Fires when a premium subscription downgrades to free and no longer has any premium tier associated with it.',
  'subscription.tier.created': 'Fires when any subscription, free or premium, is added to a new premium tier.',
  'subscription.tier.deleted':
    'Fires when a subscription is removed from a premium tier, even if they keep premium access via another tier.',
  'post.sent': 'Fires when a new post is published, either on web, via email, or both.',
  'survey.response_submitted': 'Fires when any subscriber completes any survey form.',
};

const EventTypesForm = ({ endpoint, errors, onChange }: StepProps) => {
  const { event_types: eventTypes } = endpoint;

  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...endpoint,
        [field]: value,
      });
    }
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Typography token="font-medium/text/sm">
        Select all the event types you want to trigger your webhook below*
      </Typography>
      <div className="p-6 bg-surface-50 border border-surface-200 rounded-md">
        <MultiSelect
          options={eventTypeOptions}
          tooltips={eventTypeTooltips}
          currentSelections={eventTypes || []}
          inputName="event_types"
          onChange={(selected) => handleChange('event_types', selected as WebhooksEventType[])}
        />
      </div>
      {errors?.event_types && <ErrorText>{errors.event_types}</ErrorText>}
    </div>
  );
};

export default EventTypesForm;
