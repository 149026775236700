import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import Teaser from '@/pages/Settings/Pages/Integrations/Webhooks/Teaser';
import { Card } from '@/ui/Card';

import PageContainer from '../../_components/PageContainer';
import { usePublicationSettings } from '../context';

import Endpoints from './Endpoints';

const PublicationWebhookSettings: React.FC = () => {
  const { currentPublicationId, settings } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);

  if (!settings?.webhooks) {
    return <Teaser />;
  }

  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId}>
          <Card id="endpoints" showCopyIdButton>
            <Endpoints publication={publication} />
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default PublicationWebhookSettings;
