import { forwardRef } from 'react';

import { Button } from '../ui/Button';
import { ButtonProps } from '../ui/Button/types';
import { Icon } from '../ui/Icon';
import { Tooltip } from '../ui/Tooltip';

const icon = 'Search';
const title = 'Search';
const shortcut = ['Mod', 'F'];

interface SearchButtonProps extends ButtonProps {
  onClick: () => void;
}

export const SearchButton = forwardRef<HTMLButtonElement, SearchButtonProps>(
  ({ editor, $showTooltip = false, onClick, ...rest }, ref): JSX.Element => {
    const isDisabled = !editor.isEditable;

    return (
      <Tooltip enabled={$showTooltip && !isDisabled} title={title} shortcut={shortcut}>
        <Button
          $leftSlot={<Icon name={icon} />}
          onClick={() => onClick?.()}
          disabled={isDisabled}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          ref={ref}
        >
          {title}
        </Button>
      </Tooltip>
    );
  }
);

export default SearchButton;
