import { Link } from 'react-router-dom';

import { SitePackage } from '@/interfaces/site_package';

import { dateToAgo } from '../../_utils/dateToAgo';
import { Text } from '../UI/Text';

import TemplatePreviewer from './TemplatePreviewer';

type NavbarCardProps = {
  sitePackage: SitePackage;
};

const NavbarCard = ({ sitePackage }: NavbarCardProps) => {
  const { navbar, id: sitePackageId } = sitePackage;

  return (
    <div>
      <Link to={`/website_builder_v2/navbar_template/${sitePackageId}`} className="cursor-pointer">
        <div className="group cursor-pointer overflow-hidden bg-wb-primary border border-solid border-wb-primary rounded-xl h-[150px] relative flex justify-center items-center">
          <TemplatePreviewer navbarContent={navbar} hasBrowserBar={false} />
          <div className="opacity-0 group-hover:opacity-100 transition-opacity bg-wb-overlay duration-150 absolute top-0 left-0 w-full h-full cursor-pointer flex items-center justify-center" />
        </div>
      </Link>
      <div className="flex items-center justify-between py-1">
        <div className="flex flex-col">
          <Text size="sm" weight="semibold">
            Navbar
          </Text>
          <Text size="xs" weight="medium" variant="secondary">
            Edited {dateToAgo(sitePackage.updated_at, true)}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default NavbarCard;
