import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { RssFeed } from '@/interfaces/rss_feed';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

interface Props {
  publicationId: string;
  onSuccess?: () => void;
}

export default function useRssFeedsCreate({ publicationId, onSuccess }: Props) {
  return useMutation<RssFeed, ErrorResponse>(
    () => api.post('/rss_feeds', { publication_id: publicationId }).then((res) => res.data),
    {
      onSuccess: () => {
        analytics.track('Generated an RSS Feed');
        toast.success('RSS feed created');

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (error) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      },
    }
  );
}
