import { useEffect, useState } from 'react';

interface Props {
  updateIdCheck: boolean;
  idToPublish: string;
  setPublishId: (id: string) => void;
}

const useSetPublishId = ({ updateIdCheck, setPublishId, idToPublish }: Props) => {
  const [initialIdSet, setInitialIdSet] = useState(false);

  // Ensures an id is set when the page is loaded
  useEffect(() => {
    if (!initialIdSet && idToPublish) {
      setPublishId(idToPublish);
      setInitialIdSet(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToPublish]);

  useEffect(() => {
    if (updateIdCheck) {
      setPublishId(idToPublish);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIdCheck, idToPublish]);
};

export default useSetPublishId;
