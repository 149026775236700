import { useEffect, useState } from 'react';

// import { Skeleton } from '../../../../../../components/SkeletonLoader';
import SlideOver from '../../../../../../components/SlideOver';
import { useCurrentPublication } from '../../../../../../hooks';
import { useCreateExternalEmbed } from '../../../../../../hooks/useExternalEmbeds';
import { ExternalEmbed } from '../../../../../../interfaces/external_embed';
import ExternalEmbedForm from '../Form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ExternalEmbedNew = ({ isOpen, onClose }: Props) => {
  const { data: currentPublication } = useCurrentPublication();
  const [newExternalEmbed, setNewExternalEmbed] = useState<Partial<ExternalEmbed>>();

  useEffect(() => {
    if (isOpen) {
      setNewExternalEmbed({
        publication_id: currentPublication?.id,
        header: currentPublication?.name,
        description: currentPublication?.description,
        input_placeholder: 'Enter your email',
        button_text: 'Subscribe',
        config: {
          button_color: currentPublication?.web_theme?.primary_color || '',
          background_color: currentPublication?.web_theme?.secondary_color || '',
          text_color: currentPublication?.web_theme?.text_on_secondary_color || '',
          button_text_color: currentPublication?.web_theme?.text_on_primary_color || '',
          header_font: currentPublication?.web_theme?.header_font || '',
          body_font: currentPublication?.web_theme?.body_font || '',
          button_font: currentPublication?.web_theme?.button_font || '',
        },
      });
    }
  }, [currentPublication, isOpen]);

  return (
    <SlideOver
      bodyId={currentPublication?.id}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      headerText="New Subscribe Form"
    >
      {newExternalEmbed && (
        <ExternalEmbedForm
          title=""
          externalEmbed={newExternalEmbed}
          useMutation={useCreateExternalEmbed}
          onSuccess={onClose}
        />
      )}
    </SlideOver>
  );
};

export default ExternalEmbedNew;
