import { UseQueryResult } from 'react-query';
import { Outlet, useParams } from 'react-router-dom';

import { useWebTemplate } from '@/hooks';
import { useCustomPage } from '@/hooks/useCustomPages';
import { useNavGroups } from '@/hooks/useNavGroups';
import { NavGroup } from '@/interfaces/nav_group';
import { WebTemplate } from '@/interfaces/web_template';
import TopNavActions from '@/routes/website_builder/custom_pages/_components/TopNavActions';

import { CustomPageProvider } from '../_components/Context/CustomPageContext';
import { PageProvider } from '../_components/Context/PageContext';
import Layout from '../_components/Layout';

import CustomPageSettings from './_components/CustomPageSettings';

export interface CustomPageData {
  navGroups: NavGroup[] | undefined;
  webTemplate: WebTemplate | undefined;
}

const CustomPages = () => {
  const navGroupsQuery = useNavGroups();
  const { data: navGroups } = navGroupsQuery;

  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate, isLoading: isLoadingWebTemplate }: UseQueryResult<WebTemplate, Error> = webTemplateRequest;

  const { customPageId } = useParams();
  const pageQuery = useCustomPage(customPageId);
  const { data: customPage, isLoading: isLoadingCustomPage } = pageQuery;

  return (
    <PageProvider<CustomPageData>
      pageData={{
        navGroups,
        webTemplate,
      }}
    >
      <CustomPageProvider
        key={customPageId}
        customPage={customPage}
        isLoading={customPageId ? isLoadingCustomPage || isLoadingWebTemplate : isLoadingWebTemplate}
        isEditing={!!customPageId}
      >
        <Layout
          removePadding
          onPublish={() => {}}
          sidePanelChildren={
            <div className="pt-6">
              <CustomPageSettings />
            </div>
          }
          topNavChildren={<TopNavActions />}
        >
          <div className="mx-auto max-w-6xl pt-2 p-8">
            <Outlet />
          </div>
        </Layout>
      </CustomPageProvider>
    </PageProvider>
  );
};

export default CustomPages;
