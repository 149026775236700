/**
 * @see `app/views/api/v2/boosts/grow/_boost_send.json.jbuilder`
 */

import { TopLineMetrics } from '../top_line_metrics';

import { BoostAgreement } from './boost_agreement';
import { BoostPublication } from './boost_publication';

export enum BoostSendStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}

// Defines possible grow user actions on a BoostSend for each status.
export const BoostSendStatusTransitions: any = {
  [BoostSendStatus.PENDING]: [BoostSendStatus.ACCEPTED, BoostSendStatus.REJECTED],
  [BoostSendStatus.REJECTED]: [],
  [BoostSendStatus.ACCEPTED]: [],
  [BoostSendStatus.CANCELED]: [],
};

// Defines the labels for each user action on a BoostSend for each status.
export const BoostSendTransitionLabels: any = {
  [BoostSendStatus.ACCEPTED]: 'Accept',
  [BoostSendStatus.REJECTED]: 'Reject',
};

export interface BoostSend {
  id: string;
  status: BoostSendStatus;
  max_spend_cents: number;
  max_spend: string;
  top_line_metrics: TopLineMetrics;
  recommending_publication: BoostPublication;
  boost_agreement: BoostAgreement;
  send_by: Date | string;
  created_at: Date | string;
  accepted_at: Date | string | null;
  rejected_at: Date | string | null;
}
