import moment from 'moment-mini';

import { AdNetworkCampaign } from '@/interfaces/ad_network/internal/campaign';

import StatusIndicator from './StatusIndicator';

function getStatusColor(campaign: AdNetworkCampaign) {
  const currentDate = moment().toISOString();

  if (campaign.window_start_date > currentDate) {
    return 'blue';
  }

  if (campaign.window_end_date < currentDate) {
    return 'gray';
  }

  return 'green';
}

function getStatus(campaign: AdNetworkCampaign) {
  const currentDate = moment().toISOString();

  if (campaign.window_start_date > currentDate) {
    return 'Upcoming';
  }

  if (campaign.window_end_date < currentDate) {
    return 'Past';
  }

  return 'Active';
}

const CampaignStatusIndicator = ({ campaign }: { campaign: AdNetworkCampaign }) => {
  return <StatusIndicator color={getStatusColor(campaign)} label={getStatus(campaign)} />;
};

export default CampaignStatusIndicator;
