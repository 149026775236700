import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import BoostInviteStatus from '@/interfaces/boosts/boost_invite_status';
import api from '@/services/swarm';

interface ApiResponse {
  id: string;
}

interface Arguments {
  onSuccess?: () => void;
}

interface Payload {
  id: string;
  transition_to: BoostInviteStatus;
  reject_reason?: string;
}

const useUpdateBoostInvite = ({ onSuccess }: Arguments) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, Payload>(
    ({ id, transition_to, reject_reason }) =>
      api
        .patch(`/boosts/monetize/boost_invites/${id}`, {
          publication_id: currentPublicationId,
          boost_invite: {
            transition_to,
            reject_reason,
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useUpdateBoostInvite;
