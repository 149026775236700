import { Dispatch, SetStateAction, useState } from 'react';
import { BookmarkIcon, BookOpenIcon, BugAntIcon, DocumentIcon, ExclamationTriangleIcon, FireIcon, InformationCircleIcon, PlayIcon } from '@heroicons/react/24/solid';

import BugReportModal from '@/components/BugReportModal';
import { useSupportModalContext } from '@/context/support-modal-context';
import FreeTrialAlert from '@/routes/help/_components/FreeTrialBanner';
import ImageCard from "@/routes/help/_components/ImageCard";
import ResourceLink from "@/routes/help/_components/ResourceLink";
import SupportCard from '@/routes/help/_components/SupportCard';
import SupportCardVariable from '@/routes/help/_components/SupportCardVariable';
import { Button } from '@/ui/Button';

const resourceLinks = [
  { title: "beehiiv Blog", Icon: DocumentIcon, href: "https://blog.beehiiv.com/" },
  { title: "beehiiv Webinars", Icon: PlayIcon, href: "https://beehiiv.com/webinars" },
  { title: "Newsletter XP", Icon: BookOpenIcon, href: "https://www.beehiiv.com/courses/newsletter-xp" },
  { title: "beehiiv Glossary", Icon: InformationCircleIcon, href: "https://www.beehiiv.com/newsletter-glossary" },
];

const updateLinks = [
  { title: "Product Updates", Icon: FireIcon, href: "https://product.beehiiv.com/" },
  { title: "beehiiv Changelog", Icon: BookmarkIcon, href: "https://www.beehiiv.com/changelog" },
  { title: "beehiiv Status Page", Icon: ExclamationTriangleIcon, href: "https://beehiivstatus.com/", isLive: true },
];

const KBButton = () => (
  <Button
    onClick={() => window.open("https://www.beehiiv.com/support", "_blank")}
    className="ml-auto hover:shadow-md transition-shadow"
    rel="noreferrer"
    variant="primary-inverse"
    size='xs'
  >
    Start Reading
  </Button>
)

const BuzzButton = () => (
  <Button
    onClick={() => window.adaEmbed.toggle()}
    className="ml-auto hover:shadow-md transition-shadow"
    variant="primary-inverse"
    size='xs'
  >
    Open Chat
  </Button>
)

const BugReportButton = ({ handleOpen }: { handleOpen: Dispatch<SetStateAction<boolean>> }) => (
  <Button
    variant="primary-inverse"
    size="xs"
    onClick={() => {
      handleOpen(true);
    }}
    className="absolute right-6 top-6 ml-auto hover:shadow-md transition-shadow"
    Icon={BugAntIcon}
  >
    Report a Bug
  </Button>
)

const HelpPage = () => {
  const [bugModalIsOpen, setBugModalIsOpen] = useState<boolean>(false);
  const { planType, isFreePlan, isBanned } = useSupportModalContext();

  return (
    <div className="p-6 bg-white rounded-lg border border-surface-300 relative">
      {/* Header Section */}

      {planType === "trial" && <FreeTrialAlert />}
      <div className="text-left mb-4">
        <h1 className="font-medium text-gray-900">Need help?</h1>
        <p className="text-sm text-gray-500">
          Start here to learn more about how to use beehiiv and get help
        </p>
      </div>
      <BugReportButton handleOpen={setBugModalIsOpen} />
      <BugReportModal open={bugModalIsOpen} onClose={() => { setBugModalIsOpen(false) }} />

      {/* Main Help Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
        <SupportCard
          button={<KBButton />}
          title="Knowledge Base"
          description="Browse hundreds of up-to-date guides and articles." />
        <SupportCard
          button={<BuzzButton />}
          title="AI-Assistant"
          description="Buzz is a product specialist and can assist you right now." />
        <SupportCardVariable isFreePlan={isFreePlan} isBanned={isBanned} />
      </div>

      {/* Additional Resources Section */}
      <div className="mb-10">
        <div className="text-left mb-4">
          <h2 className="font-medium text-gray-900">Additional resources</h2>
          <p className="text-sm text-gray-500">
            Expand your knowledge by exploring all of our guides, tutorials, and product resources
          </p>
        </div>
        <div className="grid md:grid-cols-4 gap-6 mb-6 md:mb-4">
          {resourceLinks.map((link) => (
            <ResourceLink key={link.title} href={link.href} title={link.title} icon={link.Icon} />
          ))}
        </div>
        <div className="grid md:grid-cols-3 gap-6">
          {updateLinks.map((link) => (
            <ResourceLink key={link.title} href={link.href} title={link.title} icon={link.Icon} isLive={link.isLive || false} />
          ))}
        </div>
      </div>

      {/* Recommended for You Section */}
      <div>
        <h2 className="font-medium text-gray-900 mb-4">Recommended for you</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <ImageCard
            href="https://www.youtube.com/@beehiiv"
            imageUrl="https://beehiiv-images-production.s3.amazonaws.com/static_assets/help-page/youtube-tutorials.png"
            title="Video tutorials at your fingertips"
            description="Watch step by step how you can start making the most of your publication."
          />

          <ImageCard
            href="https://www.beehiiv.com/support/category/21554629356823-sending-best-practices"
            imageUrl="https://beehiiv-images-production.s3.amazonaws.com/static_assets/help-page/sending-best-practices.png"
            title="Sending & best practices"
            description="Maximize open rates and engagement with proven delivery strategies."
          />

          <ImageCard
            href="https://www.beehiiv.com/support/category/21554516324375-creating-customizing-posts#4412725593879-customizations-in-the-post-editor"
            imageUrl="https://beehiiv-images-production.s3.amazonaws.com/static_assets/help-page/editor.png"
            title="Using our powerful Editor"
            description="Create stunning, responsive emails with streamlined simplicity."
          />
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
