import { PropsWithChildren } from 'react';

import { Typography, TypographyStack } from '@/components/Typography';

type Props = PropsWithChildren<{
  title: string;
  description: string;
  isLoading?: boolean;
}>;

const SidePanelSectionGroup = ({ title, description, children, isLoading }: Props) => {
  return (
    <div className="flex flex-col space-y-2">
      <div className="border-b border-surface-200 py-6 mb-6 sticky top-0 bg-white">
        <TypographyStack gap="1">
          <Typography token="font-medium/text/xs" as="h5">
            {title}
          </Typography>
          <Typography token="font-normal/text/xs" as="p" className="text-surface-600">
            {description}
          </Typography>
        </TypographyStack>
      </div>

      <div className="flex flex-col">{!isLoading && children}</div>
    </div>
  );
};

export default SidePanelSectionGroup;
