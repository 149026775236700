import { useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import { Button } from '../ui/Button';
import { Icon } from '../ui/Icon';
import { ToggleSwitch } from '../ui/ToggleSwitch';
import { ToggleWrapper } from '../ui/ToggleWrapper';
import { Toolbar } from '../ui/Toolbar';
import { Tooltip } from '../ui/Tooltip';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: true,
};

export const LinkOptions = ({
  target,
  onUpdateTarget,
  isDisabled,
}: {
  target: string;
  onUpdateTarget: (newTarget: string) => void;
  isDisabled: boolean;
}): JSX.Element => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  return (
    <Tippy
      render={(attrs) => (
        <Toolbar
          tabIndex={-1}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attrs}
        >
          <ToggleWrapper
            switchEl={
              <ToggleSwitch
                isChecked={target === '_blank' || target === null}
                onChange={() => {
                  onUpdateTarget(target === '_blank' || target === null ? '_self' : '_blank');
                }}
              />
            }
          >
            Open in new tab
          </ToggleWrapper>
        </Toolbar>
      )}
      offset={[0, 8]}
      trigger="click"
      interactive
      onShow={() => {
        setIsPanelOpen(true);
      }}
      onHidden={() => {
        setIsPanelOpen(false);
      }}
    >
      <div>
        <Tooltip enabled={!isPanelOpen} title="Text alignment">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $leftSlot={<Icon name="Settings" />}
            $rightSlot={<Icon name="ChevronDown" $size="0.66rem" />}
            disabled={isDisabled}
            $active={isPanelOpen}
            $muted={isPanelOpen}
          />
        </Tooltip>
      </div>
    </Tippy>
  );
};

export default LinkOptions;
