import { AlignType } from './types';

export const ALIGN_CLASSNAME_MAP: {
  [key in AlignType]: string;
} = {
  top: 'align-top',
  middle: 'align-middle',
  center: 'text-center',
  bottom: 'align-bottom',
  left: 'text-left',
  right: 'text-right',
};
