import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/20/solid';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyStack } from '@/components/Typography';
import { useRequireSetting } from '@/hooks';
import useBoostAgreements from '@/hooks/boosts/grow/useBoostAgreements';
import useUpdateBoostAgreement from '@/hooks/boosts/grow/useUpdateBoostAgreement';
import { BoostAgreementCreateWithReferralCount, BoostAgreementStatus } from '@/interfaces/boosts/grow/boost_agreement';
import { OrderBy } from '@/interfaces/boosts/order';
import { Order } from '@/interfaces/general';
import { Setting } from '@/interfaces/setting';

import PublicationCard from './PublicationCard';
import Tabs from './Tabs';
import TopLineMetrics from './TopLineMetrics';

const RecommendingPublication = () => {
  const { recommendingPublicationId } = useParams() as unknown as { recommendingPublicationId: string };

  const {
    data,
    isLoading: isLoadingFirstBoostAgreement,
    isError: isErrorFirstBoostAgreement,
  } = useBoostAgreements({
    recommendingPublicationId,
    orderBy: OrderBy.CREATED_AT,
    direction: Order.ASC,
    perPage: 1,
  });

  const { data: liveBoostAgreementsData, isLoading: isLoadingLiveBoostAgreement } = useBoostAgreements({
    recommendingPublicationId,
    status: BoostAgreementStatus.ACTIVE,
    perPage: 1,
  });

  const boostAgreements =
    (data?.pages.flatMap((page) => page.boost_agreements) as BoostAgreementCreateWithReferralCount[]) || [];
  const firstBoostAgreement = boostAgreements[0];

  const liveBoostAgreements =
    (liveBoostAgreementsData?.pages.flatMap(
      (page) => page.boost_agreements
    ) as BoostAgreementCreateWithReferralCount[]) || [];
  const liveBoostAgreement = liveBoostAgreements[0];

  const { mutateAsync: updateBoostAgreement } = useUpdateBoostAgreement({
    boostOfferId: liveBoostAgreement?.boost_offer.id,
    onSuccess: () => {
      toast.success('Boost agreement updated.');
    },
  });

  useRequireSetting({
    setting: Setting.BOOSTS,
    message: 'Upgrade to use boosts.',
    redirectTo: '/grow/boosts/overview',
  });

  const isLoading = isLoadingFirstBoostAgreement;
  const isError = isErrorFirstBoostAgreement;

  const handleToggleBoostAffiliate = (newValue: boolean) => {
    return updateBoostAgreement({ boostAgreementId: liveBoostAgreement?.id, affiliate_enabled: newValue });
  };

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <div className="flex flex-col gap-y-8">
        <Breadcrumbs>
          <Breadcrumbs.Item to="/">
            <HomeIcon className="w-4 h-4" />
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to="/grow/boosts">Grow with Boosts</Breadcrumbs.Item>
          <Breadcrumbs.Item to={`/grow/boosts/recommending_publication/${recommendingPublicationId}`}>
            {firstBoostAgreement?.recommending_publication?.name}
          </Breadcrumbs.Item>
        </Breadcrumbs>
        {firstBoostAgreement && !isLoadingLiveBoostAgreement && (
          <PublicationCard
            firstBoostAgreement={firstBoostAgreement}
            liveBoostAgreement={liveBoostAgreement}
            publication={firstBoostAgreement.recommending_publication}
            onToggleBoostAffiliate={handleToggleBoostAffiliate}
          />
        )}

        <TypographyStack>
          <Typography token="font-bold/text/base">Publication Overview</Typography>
          <Typography token="font-normal/text/sm">
            High level glance at how this partner is driving growth towards your publication.
          </Typography>
        </TypographyStack>

        <TopLineMetrics recommendingPublicationId={recommendingPublicationId} />

        <TypographyStack>
          <Typography token="font-bold/text/base">Boost Channels</Typography>
          <Typography token="font-normal/text/sm">
            Here are the channels through which this partner is boosting your publication
          </Typography>
        </TypographyStack>

        <Tabs recommendingPublicationId={recommendingPublicationId} />
      </div>
    </LoadingBox>
  );
};

export default RecommendingPublication;
