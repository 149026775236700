import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UpgradeIntent } from '@/components/UpgradeIntent';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import { useNavGroups } from '@/hooks/useNavGroups';
import useUpgradeIntent from '@/hooks/useUpgradeIntent';
import { IntentAction } from '@/interfaces/upgrades';
import { PLAN } from '@/utils/plans';

import NavigationSection from './NavigationSection';

const Pages = () => {
  const navigate = useNavigate();
  const { data: currentPublication } = useCurrentPublication();
  const navGroupsQuery = useNavGroups();
  const { settings } = useSettings();
  const isPagesEnabled = settings?.custom_pages;

  const { isOpen, handleOpen, handleClose } = useUpgradeIntent({
    publicationId: currentPublication?.id || '',
    intentAction: IntentAction.USE_FORMS,
  });

  useEffect(() => {
    if (!isPagesEnabled && !isOpen) {
      handleOpen();
    }
  }, [isPagesEnabled]);

  if (isOpen) {
    return (
      <UpgradeIntent
        isOpen={isOpen}
        onClose={() => {
          handleClose();
          navigate('/settings/website');
        }}
        intentAction={IntentAction.USE_PAGES}
        preselectedPlan={PLAN.SCALE}
      />
    );
  }

  return <NavigationSection publication={currentPublication} navGroups={navGroupsQuery?.data} />;
};

export default Pages;
