import { Link } from 'react-router-dom';
import { DotsThree } from '@phosphor-icons/react';

import { SitePackage } from '@/interfaces/site_package';

import { Button } from '../../_components/UI/Button';
import { Text } from '../../_components/UI/Text';
import { dateToAgo } from '../../_utils/dateToAgo';

import { ProjectOptions } from './ProjectOptions';

interface Props {
  project: SitePackage;
  onDeleteSuccess?: () => void;
}

export const ProjectCard = ({ project, onDeleteSuccess }: Props) => {
  return (
    <div>
      <Link to={`/website_builder_v2/projects/${project.id}`} className="cursor-pointer">
        <div className="group cursor-pointer p-4 overflow-hidden bg-wb-primary border border-solid border-wb-primary shadow-wb-md aspect-video rounded-xl h-[150px] relative flex justify-center items-center">
          <img src="" alt={project.name} className="w-full object-cover" />
          <div className="opacity-0 group-hover:opacity-100 transition-opacity bg-wb-overlay duration-150 absolute top-0 left-0 w-full h-full cursor-pointer flex items-center justify-center" />
        </div>
      </Link>
      <div className="flex items-center justify-between py-1">
        <div className="flex flex-col">
          <Text size="sm" weight="semibold">
            {project.name}
          </Text>
          <Text size="xs" weight="medium" variant="secondary">
            Edited {dateToAgo(project.updated_at, true)}
          </Text>
        </div>

        <div className="flex items-center justify-end">
          <ProjectOptions align="end" project={project} onDeleteSuccess={onDeleteSuccess}>
            <Button
              variant="secondary"
              size="sm"
              Icon={DotsThree}
              iconClassName="h-5 w-5 text-wb-secondary"
              className="p-1.5 hover:opacity-100 data-[state=open]:opacity-100 transition-all"
            />
          </ProjectOptions>
        </div>
      </div>
    </div>
  );
};
