import analytics from '@/utils/analytics';
import { getAttributionCookies } from '@/utils/marketingCookies';

interface RegisterTempUserProps {
  email: string;
  firstName: string;
  lastName: string;
  emailVerified: boolean;
  selectedPlan?: string;
  isTrialPlan?: boolean;
}

const registerTempUser = async (props: RegisterTempUserProps) => {
  const attribution = getAttributionCookies();
  const { email, firstName, lastName, selectedPlan, isTrialPlan, emailVerified } = props;

  analytics.identify(email, {
    email,
    firstName,
    lastName,
    emailVerified,
    phoneVerified: false,
    trialIntent: isTrialPlan,
    attribution: {
      cookies: {
        ...(attribution?.fbc && { fbc: attribution.fbc }),
        ...(attribution?.fbp && { fbp: attribution.fbp }),
        ...(attribution?.ga?.slice(6) && { ga: attribution.ga.slice(6) }),
        ...(attribution?.gclid?.split('.')[2] && { gclid: attribution?.gclid?.split('.')[2] }),
        ...(attribution?.msclkid && { msclkid: attribution.msclkid }),
        ...(attribution?.li_fat_id && { li_fat_id: attribution.li_fat_id }),
        ...(attribution?.ttclid && { ttclid: attribution.ttclid }),
      },
      ...(attribution?.first && { first_touch: attribution.first }),
      ...(attribution?.last && { last_touch: attribution.last }),
    },
  });

  analytics.track('Completed Registration', {
    email,
    firstName,
    lastName,
    plan: selectedPlan,
    trialIntent: isTrialPlan,
    attribution: {
      cookies: {
        ...(attribution?.fbc && { fbc: attribution.fbc }),
        ...(attribution?.fbp && { fbp: attribution.fbp }),
        ...(attribution?.ga?.slice(6) && { ga: attribution.ga.slice(6) }),
        ...(attribution?.gclid?.split('.')[2] && { gclid: attribution?.gclid?.split('.')[2] }),
        ...(attribution?.msclkid && { msclkid: attribution.msclkid }),
        ...(attribution?.li_fat_id && { li_fat_id: attribution.li_fat_id }),
        ...(attribution?.ttclid && { ttclid: attribution.ttclid }),
      },
      ...(attribution?.first && { first_touch: attribution.first }),
      ...(attribution?.last && { last_touch: attribution.last }),
    },
  });
};

export { registerTempUser };
