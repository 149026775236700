export type SHADOWSIZES = 'none' | 'sm' | 'md' | 'lg' | 'xl';

export const SHADOW_OPTIONS = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
  {
    label: 'XL',
    value: 'xl',
  },
];

export type GAPSIZES = 'none' | 'sm' | 'md' | 'lg';
export const GAP_OPTIONS = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
];

export const RADIUS_OPTIONS = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
  {
    label: 'XL',
    value: 'xl',
  },
  {
    label: '2XL',
    value: '2xl',
  },
  {
    label: '3XL',
    value: '3xl',
  },
];

export const BORDER_WIDTH_OPTIONS = [
  {
    label: 'XS',
    value: 'xs',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
];

export const PADDING_OPTIONS = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'SM',
    value: 'sm',
  },
  {
    label: 'MD',
    value: 'md',
  },
  {
    label: 'LG',
    value: 'lg',
  },
];

export const BORDER_SIDE_OPTIONS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Right',
    value: 'right',
  },
  {
    label: 'Top',
    value: 'top',
  },
  {
    label: 'Bottom',
    value: 'bottom',
  },
  {
    label: 'X-Axis',
    value: 'x-axis',
  },
  {
    label: 'Y-Axis',
    value: 'y-axis',
  },
];

export const OPACITY_OPTIONS = ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];
