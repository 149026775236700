import { BanknotesIcon } from '@heroicons/react/20/solid';
import { Grid } from '@tremor/react';

import { EmptyCard } from '@/components/ResourceList';
import useWebApplications from '@/hooks/boosts/grow/useWebApplications';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import WebApplicationItem from './WebApplicationItem';

interface Props {
  boostOfferId?: string;
}

const WebApplications = ({ boostOfferId }: Props) => {
  const { boostAgreements, noResults, hasNextPage, fetchNextPage, isFetchingNextPage } = useWebApplications(
    boostOfferId || '',
    { perPage: 9 }
  );

  return (
    <>
      {noResults || !boostAgreements.length ? (
        <div className="h-96">
          <EmptyCard
            title="No Applications"
            description="Your offer has no applications yet"
            primaryIcon={BanknotesIcon}
          />
        </div>
      ) : null}
      {boostAgreements.length > 0 ? (
        <Grid numColsSm={3} numCols={1} className="gap-4">
          {boostAgreements.map((boostAgreement) => (
            <WebApplicationItem key={`web-application-item-${boostAgreement.id}`} item={boostAgreement} />
          ))}
        </Grid>
      ) : null}
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </>
  );
};

export default WebApplications;
