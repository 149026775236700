import { useLocation } from 'react-router-dom';
import { LinkIcon } from '@heroicons/react/20/solid';

import IconButton from '@/components/IconHelpers/IconButton';
import { useCopyToClipboard } from '@/hooks';
import { cn } from '@/utils/cn';

import { CardDensity, CardProps } from './Card.types';

const BORDER_CLASSNAMES = {
  primary: 'border-surface-200',
  secondary: 'border-surface-200',
  success: 'border-feedback-success-200',
};

const Card = ({
  variant = 'primary',
  density = CardDensity.default,
  children,
  className,
  id,
  backgroundColor = 'bg-white',
  showCopyIdButton = false,
}: CardProps) => {
  const densityClass = {
    default: 'p-6',
    compact: 'p-4',
    wide: 'p-10',
    narrow: 'px-4 py-2',
    none: '',
  }[density];

  const copy = useCopyToClipboard();
  const location = useLocation();
  const handleClickCopyId = () => {
    copy({
      text: `${window.location.origin}${location.pathname}${location.search}#${id}`,
      onSuccessText: 'Section link copied!',
    });
  };

  return (
    <div
      id={id}
      className={cn(
        'border rounded-md group/ui-card relative',
        BORDER_CLASSNAMES[variant],
        backgroundColor,
        densityClass,
        className
      )}
    >
      {children}
      {showCopyIdButton ? (
        <div className="absolute top-0.5 right-0.5 invisible group-hover/ui-card:visible">
          <IconButton onClick={handleClickCopyId} tooltip="Copy section link">
            <LinkIcon />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
};

export default Card;
