/* eslint-disable consistent-return */
import { useCallback, useMemo } from 'react';
import { SuggestionProps } from '@tiptap/suggestion';

import { Spinner } from '@/components/TiptapEditor/components/ui/Spinner';
import * as Sheet from '@/components/UI/Sheet';
import { usePosts } from '@/hooks';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { PostStatus } from '@/interfaces/post';
import PostSearch from '@/models/postSearch';
import { Badge, BadgeSize, BadgeType } from '@/ui/Badge';

export const BacklinkSuggestionsModal = (props: SuggestionProps) => {
  const { query, command } = props;

  const debouncedQuery = useDebouncedValue<string>(query, 400);

  const postSearchQuery = useMemo(
    () =>
      new PostSearch({
        search: debouncedQuery,
      }),
    [debouncedQuery]
  );

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = usePosts(postSearchQuery, false, 20);

  const posts = useMemo(() => data?.pages.flatMap((page) => page.posts) || [], [data]);

  const handleSelect = useCallback(
    (id: string) => {
      const option = posts?.find((p) => p.id === id);

      if (option?.id) {
        command({ title: option.title, target: option.id });
      }
    },
    [command, posts]
  );

  const loadingSpinner = isLoading ? (
    <div className="flex items-center justify-center h-8">
      <Spinner />
    </div>
  ) : null;

  const noResults =
    !isLoading && !posts?.length ? (
      <div className="flex items-center justify-center h-8 text-sm font-medium leading-none">No results</div>
    ) : null;

  const results =
    !isLoading && posts?.length ? (
      <Sheet.ScrollList className="max-h-60">
        {posts.map((p) => (
          <Sheet.Item className="flex items-center gap-2" type="button" key={p.id} onClick={() => handleSelect(p.id)}>
            <span className="flex-1 leading-5">{p.title}</span>
            {p.status === PostStatus.DRAFT && <Badge text="Draft" size={BadgeSize.SM} type={BadgeType.WARNING} />}
          </Sheet.Item>
        ))}

        {hasNextPage && (
          <div className="flex justify-center">
            <button
              type="button"
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={() => fetchNextPage()}
              className="flex justify-center items-center gap-2 text-sm hover:bg-gray-600 bg-gray-700 hover:text-white px-2 py-1 rounded-md bg-opacity-50"
            >
              <span>Load more</span>
              {isFetchingNextPage && <Spinner />}
            </button>
          </div>
        )}
      </Sheet.ScrollList>
    ) : null;

  return (
    <Sheet.Surface className="w-64 md:w-96">
      {loadingSpinner}
      {noResults}
      {results}
    </Sheet.Surface>
  );
};
