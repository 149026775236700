import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

import { Slider } from '../../../../UI/Slider';
import { Text } from '../../../../UI/Text';
import { AttributeSettingProps } from '../../types';

const SliderGroupSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeAttributes, activeNode, activeNodePos } = activeNodeResult;
  const columnCount = activeNode?.content.childCount ?? 0;

  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const renderableColumnWidths = useMemo(() => {
    return columnWidths.map((width, index) => {
      return {
        key: index,
        value: width,
      };
    });
  }, [columnWidths]);

  useEffect(() => {
    if (!activeNodeAttributes) return;

    if (!activeNodeAttributes.gridTemplateColumns) {
      // initialize column widths with equal width
      if (columnCount > 0) {
        const baseWidth = Math.floor(100 / columnCount);
        const remainder = 100 % columnCount;

        const initialWidths = Array(columnCount)
          .fill(baseWidth)
          .map((width, index) => (index < remainder ? width + 1 : width));

        setColumnWidths(initialWidths);
      }
    }

    if (columnWidths.length > 0 && columnCount === columnWidths.length) return;

    const gridTemplateColumns = (activeNodeAttributes.gridTemplateColumns as string).split(' ');

    const widths = gridTemplateColumns.map((width) => Math.round(Number(width.replace('fr', ''))));

    const totalFrs = widths.reduce((sum, width) => sum + width, 0);

    const updatedWidths = widths.map((width) => Math.round((width / totalFrs) * 100));

    setColumnWidths(updatedWidths);
  }, [activeNodeAttributes, columnWidths, columnCount]);

  const updateColumnWidths = useMemo(
    () =>
      debounce((updatedWidths: number[]) => {
        const totalFrs = updatedWidths.reduce((sum, width) => sum + width, 0);

        const updateWidthsNumbers = updatedWidths.map((width) => (width / totalFrs) * 100);

        const gridTemplateColumns = updateWidthsNumbers.map((width) => `${width}fr`).join(' ');

        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'gridTemplateColumns', gridTemplateColumns);
          return true;
        });
      }, 5),
    [editor, activeNodePos]
  );

  const handleSliderChange = useCallback(
    (index: number, value: number) => {
      const sanitizedValue = Math.max(0, Math.min(100, value));
      const remainingWidth = 100 - sanitizedValue;

      const otherColumns = columnWidths.filter((_, i) => i !== index);
      const totalOtherColumns = otherColumns.reduce((sum, width) => sum + (width || 0), 0);

      let updatedWidths = columnWidths.map((width, i) => {
        if (i === index) {
          return Math.round(sanitizedValue);
        }

        return totalOtherColumns > 0
          ? Math.round(((width || 0) / totalOtherColumns) * remainingWidth)
          : Math.round(remainingWidth / (columnWidths.length - 1 || 1));
      });

      const totalWidth = updatedWidths.reduce((sum, width) => sum + (width || 0), 0);
      const difference = 100 - totalWidth;

      if (difference !== 0) {
        updatedWidths = updatedWidths.map((width, i) => (i === 0 ? Math.max(0, width + difference) : width));
      }

      updatedWidths = updatedWidths.map((width) => Math.max(0, Math.min(100, width || 0)));

      setColumnWidths(updatedWidths);
      updateColumnWidths(updatedWidths);
    },
    [columnWidths, setColumnWidths, updateColumnWidths]
  );

  return (
    <div className="flex flex-col gap-4">
      {renderableColumnWidths.map(({ key, value }) => (
        <div className="flex items-center justify-stretch gap-2" key={key}>
          <Text className="w-[80px] min-w-[80px]" variant="secondary" size="2xs" weight="medium">
            Column {key + 1}
          </Text>
          <div className="grow flex items-center h-[32px] gap-2">
            <div className="relative h-full">
              <input
                className="bg-wb-secondary rounded-lg border-none p-2 text-xs w-[75px]"
                type="number"
                min={0}
                max={100}
                value={value}
                onChange={(e) => handleSliderChange(key, Number(e.target.value))}
              />
              <Text
                size="2xs"
                weight="medium"
                className="absolute top-1/2 right-3 -translate-y-1/2"
                variant="secondary"
              >
                %
              </Text>
            </div>
            <Slider value={[value]} onValueChange={(val) => handleSliderChange(key, val[0])} min={0} max={100} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SliderGroupSettings;
