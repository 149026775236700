import React from 'react';

import { Button } from '../ui/Button';
import { Icon } from '../ui/Icon';
import { Tooltip } from '../ui/Tooltip';

import { ButtonProps } from './types';

const icon = 'Undo';
const title = 'Undo';
const shortcut = ['Mod', 'Z'];

export const UndoButton = ({ editor, $showTooltip = false, ...rest }: ButtonProps): JSX.Element => {
  const isDisabled = !editor.can().undo();

  return (
    <Tooltip enabled={$showTooltip && !isDisabled} title={title} shortcut={shortcut}>
      <Button
        $leftSlot={<Icon name={icon} />}
        onClick={() => {
          editor.chain().focus().undo().run();
        }}
        disabled={isDisabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export default UndoButton;
