import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { AccordionContentView } from './view/AccordionContentView';

export const AccordionContent = Node.create({
  name: 'accordionContent',

  group: 'block',

  content: 'block+',

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      width: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
          style: `width: ${attributes.width}`,
        }),
      },
      height: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({
          'data-height': attributes.height,
          style: `height: ${attributes.height}`,
        }),
      },
      width_max: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
        }),
      },
      padding: {
        default: '16px 0px 16px 0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AccordionContentView, {
      stopEvent: () => false,
    });
  },
});

export default AccordionContent;
