import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Node } from '@tiptap/pm/model';
import { Editor, NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import { AxiosError, AxiosResponse } from 'axios';

import { Asset } from '@/interfaces/asset';

import { useEditorContext } from '../../../../../pages/Post/Edit/EditorContext';
import { Skeleton } from '../../../../SkeletonLoader';
import { UpdateImagePanel } from '../../../components/panels/UpdateImage';
import { Spinner } from '../../../components/ui/Spinner';
import { API } from '../../../lib/api';
import { usePublicationContext } from '../../../lib/context/PublicationContext';

import { ButtonContainer, ImageContainer, LoaderWrapper } from './GenericEmbedView.styled';

export const GenericEmbedView = ({
  editor,
  node,
  getPos,
  updateAttributes,
}: {
  editor: Editor;
  node: Node;
  getPos: () => number;
  updateAttributes: (attributes: any) => void;
  extension: any;
}) => {
  const { provider } = useEditorContext();
  const { publicationId } = usePublicationContext();
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const url = useMemo(() => node.attrs?.url, [node.attrs?.url]);
  const variant = useMemo(() => node.attrs?.variant, [node.attrs?.variant]);
  const imageUrl = useMemo(() => node.attrs?.imageUrl, [node.attrs?.imageUrl]);
  const hasFetched = useMemo(() => node.attrs?.hasFetched, [node.attrs?.hasFetched]);
  const [isLoading, setIsLoading] = useState(hasFetched !== 'true');
  const [isUploading, setIsUploading] = useState(false);
  const clientId = useMemo(() => provider?.document.clientID, [provider?.document.clientID]);
  const createdByClientId = useMemo(() => node.attrs?.clientId, [node.attrs?.clientId]);
  const isOwner = useMemo(() => {
    if (!createdByClientId) {
      return undefined;
    }

    return clientId === createdByClientId;
  }, [clientId, createdByClientId]);

  useEffect(() => {
    const insertEmbed = (embed: { title: string | null; description: string | null; image: string | null }) => {
      const pos = getPos();

      const title = embed.title ?? '';
      const description = embed.description ?? '';

      const posTitle = pos + 2;
      const posDescription = posTitle + title.length + 2;
      const posUrl = posDescription + description.length + 2;

      editor
        .chain()
        .focus()
        .updateAttributes('genericEmbed', { imageUrl: embed.image, hasFetched: true })
        .insertContentAt({ from: posTitle, to: posTitle }, title)
        .insertContentAt({ from: posDescription, to: posDescription }, description)
        .insertContentAt({ from: posUrl, to: posUrl }, url.replace(/^https?:\/\//, '').replace(/\/+$/, ''))
        .run();
    };

    const fetchData = () => {
      API.getEmbedData({
        service: 'generic',
        url,
        publicationId,
      })
        .then((res: AxiosResponse) => {
          insertEmbed(res.data.embed.attributes);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';

          insertEmbed({ title: null, description: null, image: null });

          toast.error(error);
        });
    };

    if (hasFetched) {
      setIsLoading(false);
    } else if (typeof isOwner === 'undefined' || isOwner) {
      fetchData();
    }
  }, [editor, getPos, hasFetched, isOwner, url, publicationId]);

  const onUpload = useCallback(
    (file: File) => {
      setIsUploading(true);

      API.uploadPublicationAsset({
        file,
        publicationId,
      })
        .then((res) => {
          updateAttributes({ imageUrl: String(res.data.url) });
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => setIsUploading(false));
    },
    [publicationId, updateAttributes]
  );

  const onReplace = useCallback(
    (asset: Asset) => {
      updateAttributes({ imageUrl: asset.url });
    },
    [updateAttributes]
  );

  return (
    <NodeViewWrapper
      data-drag-handle
      data-url={url}
      data-variant={variant}
      data-image-url={imageUrl}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(url ? { as: 'a', href: url } : {})}
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <ImageContainer>
        {isLoading || isUploading ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          <>
            {imageUrl ? (
              <button
                onClick={() => {
                  editor.commands.focus(getPos() + 2);
                }}
                type="button"
                aria-label="Click to focus embed"
              >
                <img src={imageUrl} alt="" contentEditable={false} />
              </button>
            ) : (
              <div className="w-40">
                <p className="text-sm font-medium text-center">&lt; No Image &gt;</p>
              </div>
            )}
            <ButtonContainer ref={buttonContainerRef}>
              <UpdateImagePanel
                tooltip="Upload or pick new image"
                parentRef={buttonContainerRef}
                onUpload={onUpload}
                onReplace={onReplace}
              />
            </ButtonContainer>
          </>
        )}
      </ImageContainer>

      {isLoading ? (
        <div className="w-full animate-pulse">
          <Skeleton className="h-8 mb-8 rounded" />
          <Skeleton className="h-4 mb-2 rounded" />
          <Skeleton className="h-4 mb-2 rounded" />
          <Skeleton className="h-4 rounded" />
        </div>
      ) : (
        <NodeViewContent />
      )}
    </NodeViewWrapper>
  );
};

export default GenericEmbedView;
