import { useState } from 'react';

import { Button } from '@/ui/Button';

import useFilteringContext from '../filtering-context';
import { ConditionGroup, NumberOperator, NumberOperatorsList } from '../types';

const PendingOpportunityCountFilterDetails = ({ group }: { group: ConditionGroup }) => {
  const operators = NumberOperatorsList;

  const [selectedOperator, setSelectedOperator] = useState<NumberOperator>(operators[0].value);
  const [value, setValue] = useState('');

  const { addCondition, handleClose } = useFilteringContext();

  const handleApply = () => {
    addCondition(
      {
        type: 'measure',
        name: 'pending_opportunity_count',
        filters: { operator: selectedOperator, value: Number(value) },
      },
      group.uuid
    );

    handleClose();
  };

  return (
    <form
      className="absolute z-10 mt-2 bg-white border border-gray-300 shadow rounded-md text-sm left-0 w-max text-gray-600 p-4 space-y-2"
      onSubmit={handleApply}
    >
      <div className="space-x-2">
        <p className="inline">Pending opportunity count</p>
        <select
          className="appearance-none outline-none border rounded-md text-xs border-gray-300"
          onChange={(e) => setSelectedOperator(e.target.value as any)}
          value={selectedOperator}
          required
        >
          {operators.map((operator) => {
            return (
              <option key={operator.value} value={operator.value}>
                {operator.label}
              </option>
            );
          })}
        </select>

        <input
          type="number"
          className="appearance-none outline-none border rounded-md text-xs border-gray-300 placeholder:text-gray-400"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          required
        />
      </div>
      <Button variant="primary-inverse" type="submit" size="xs">
        Apply
      </Button>
    </form>
  );
};

export default PendingOpportunityCountFilterDetails;
