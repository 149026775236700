import { useMemo } from 'react';

export const useImageBlockOptions = (attrs: any, themeColors: any[]) => {
  const { isCustomBorderColor, hasOptionsOverrides, hasBorderOverrides } = useMemo(() => {
    if (!attrs) {
      return {
        isCustomBorderColor: false,
        hasOptionsOverrides: false,
        hasBorderOverrides: false,
      };
    }

    const newIsDefaultAlignment = !attrs.align || attrs.align === 'center';

    const newIsDefaultWidth = !attrs.width || attrs.width === '100%';

    const newIsDefaultBorderColor = !attrs.borderColor;

    const newIsCustomBorderColor = newIsDefaultBorderColor
      ? false
      : !themeColors.find((themeColor) => {
          return themeColor.value === attrs.borderColor;
        });

    const newIsDefaultBorderRadius =
      !attrs.borderTopLeftRadius &&
      !attrs.borderTopRightRadius &&
      !attrs.borderBottomLeftRadius &&
      !attrs.borderBottomRightRadius;

    const newIsDefaultBorderWidth =
      !attrs.borderWidthTop && !attrs.borderWidthBottom && !attrs.borderWidthLeft && !attrs.borderWidthRight;

    const newIsDefaultBorderStyle = attrs.borderStyle === 'solid' || !attrs.borderStyle;

    const newHasBorderOverrides =
      !newIsDefaultBorderRadius || !newIsDefaultBorderWidth || !newIsDefaultBorderStyle || !newIsDefaultBorderColor;

    const newHasOptionsOverrides = !newIsDefaultWidth || !newIsDefaultAlignment || newHasBorderOverrides;

    return {
      isCustomBorderColor: newIsCustomBorderColor,
      hasOptionsOverrides: newHasOptionsOverrides,
      hasBorderOverrides: newHasBorderOverrides,
    };
  }, [attrs, themeColors]);

  return {
    isCustomBorderColor,
    hasOptionsOverrides,
    hasBorderOverrides,
  };
};

export default useImageBlockOptions;
