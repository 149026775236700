import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useIsSettingPage from '@/hooks/useIsSettingPage';
import { usePublication } from '@/hooks/usePublications';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';

import { useCurrentPublicationState } from './current-publication-context';

interface IAppLayoutContext {
  isSettingsPages: boolean;
  hasFullHeight: boolean;
  hasTopPadding: boolean;
  disableView: boolean;
  largeNavOpen: boolean;
  setLargeNavOpen: (value: boolean) => void;
}

const AppLayoutContext = createContext<IAppLayoutContext | undefined>(undefined);

const AppLayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const isPageWithSubLayout = location.pathname.includes('analytics/click_map');
  const isCurrentUserSettingPage = location.pathname.startsWith('/current_user');
  const isSettingsPages = useIsSettingPage();
  const [largeNavOpen, setLargeNavOpen] = useState<boolean>(localStorage.getItem('largeNavOpen') === 'true');

  const [publicationId] = useCurrentPublicationState(false);
  const currentPublication = usePublication(publicationId)?.data;
  const { data: settings } = usePublicationSettings(publicationId);
  const isSysAdmin = settings?.system_admin;
  const disableView = (currentPublication?.is_sending_disabled && !isSettingsPages && !isSysAdmin) || false;

  useEffect(() => {
    localStorage.setItem('largeNavOpen', largeNavOpen.toString());
  }, [largeNavOpen]);

  const contextProviderProps = useMemo(
    () => ({
      isSettingsPages,
      hasFullHeight: isPageWithSubLayout || disableView,
      hasTopPadding: !isSettingsPages && !isCurrentUserSettingPage,
      disableView,
      largeNavOpen,
      setLargeNavOpen,
    }),
    [disableView, isPageWithSubLayout, isSettingsPages, largeNavOpen, isCurrentUserSettingPage]
  );

  return <AppLayoutContext.Provider value={contextProviderProps}>{children}</AppLayoutContext.Provider>;
};

function useAppLayout() {
  const context = React.useContext(AppLayoutContext);
  if (context === undefined) {
    throw new Error(`useAppLayout must be used within a AppLayoutProvider`);
  }
  return context;
}

export { AppLayoutContext, AppLayoutProvider, useAppLayout };
