import cx from 'classnames';

type TableProps = React.PropsWithChildren<{
  className?: string;
}>;

const Table = ({ className, children }: TableProps) => {
  const classNames = cx('min-w-full divide-y divide-gray-200 border-t rounded-md', className);

  return <table className={classNames}>{children}</table>;
};

export default Table;
