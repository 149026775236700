import { useCallback } from 'react';
import { Editor } from '@tiptap/core';

export const useHighlighting = (editor: Editor) => {
  const onHighlightSelect = useCallback(
    (color: string) => {
      if (color) {
        editor.chain().selectTextBlock().setHighlight({ color }).focus().run();
      }
    },
    [editor]
  );

  const onHighlightClear = useCallback(() => editor.chain().selectTextBlock().unsetHighlight().focus().run(), [editor]);

  const isHighlightActive = useCallback((color: string) => editor.isActive('highlight', { color }), [editor]);

  return { onHighlightSelect, onHighlightClear, isHighlightActive };
};
