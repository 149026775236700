import { PropsWithChildren } from 'react';
import cx from 'classnames';
import styled from 'styled-components';

import { Color, TEXT_COLOR_MAP } from '../DesignTokens';

export enum FontSize {
  xs = 'xs',
  sm = 'sm',
  base = 'base',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
  '3xl' = '3xl',
  '4xl' = '4xl',
  '5xl' = '5xl',
  '6xl' = '6xl',
  '7xl' = '7xl',
  '8xl' = '8xl',
  '9xl' = '9xl',
}

export enum FontWeight {
  light = 'light',
  normal = 'normal',
  medium = 'medium',
  semibold = 'semibold',
  bold = 'bold',
}

export type ColorWeight = '50' | '300' | '400' | '500' | '600' | '700' | '800' | '900';

export type Token = `font-${FontWeight}/text/${FontSize}`;

export type TypographyProps = PropsWithChildren<{
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'li' | 'span' | 'div' | 'time' | 'code';
  size?: FontSize | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl';
  color?:
  | Color
  | 'surface'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'danger'
  | 'info'
  | 'success'
  | 'white'
  | 'gray';
  colorWeight?: ColorWeight;
  weight?: FontWeight | 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  token?: Token;
}>;

const StyledText = styled.span``;

const FONT_SIZE_MAP = Object.freeze({
  [FontSize.xs]: 'text-xs',
  [FontSize.sm]: 'text-sm',
  [FontSize.base]: 'text-base',
  [FontSize.lg]: 'text-lg',
  [FontSize.xl]: 'text-xl',
  [FontSize['2xl']]: 'text-2xl',
  [FontSize['3xl']]: 'text-3xl',
  [FontSize['4xl']]: 'text-4xl',
  [FontSize['5xl']]: 'text-5xl',
  [FontSize['6xl']]: 'text-6xl',
  [FontSize['7xl']]: 'text-7xl',
  [FontSize['8xl']]: 'text-8xl',
  [FontSize['9xl']]: 'text-9xl',
});

const WEIGHT_MAP = Object.freeze({
  [FontWeight.light]: 'font-light',
  [FontWeight.normal]: 'font-normal',
  [FontWeight.medium]: 'font-medium',
  [FontWeight.semibold]: 'font-semibold',
  [FontWeight.bold]: 'font-bold',
});

const propsByToken = (token: Token) => {
  const arr = token.split('/');
  const weight = arr[0].split('-').pop() as FontWeight;
  const size = arr.pop() as FontSize;

  return {
    weight,
    size,
  };
};

const Typography = ({
  className = '',
  children,
  as,
  size = FontSize.base,
  color = Color.SURFACE,
  colorWeight = '900',
  weight = FontWeight.normal,
  token,
}: TypographyProps) => {
  const tokenProps = token ? propsByToken(token) : { size, weight };

  return (
    <StyledText
      as={as}
      className={cx(
        className,
        FONT_SIZE_MAP[tokenProps.size],
        TEXT_COLOR_MAP[color][colorWeight],
        WEIGHT_MAP[tokenProps.weight]
      )}
    >
      {children}
    </StyledText>
  );
};

export default Typography;
