import { APP_LAYOUT_BODY_CONTAINER_ID } from '@/components/Layout/AppLayout/constants';
import TierConfigurationNew from '@/routes/settings/publication/premium/tiers/new';
import { ScrollOnMount } from '@/ui/ScrollOnMount';

const MonetizeSubscriptionsPaidTiersNew = () => {
  return (
    <ScrollOnMount elementId={APP_LAYOUT_BODY_CONTAINER_ID}>
      <TierConfigurationNew />
    </ScrollOnMount>
  );
};

export default MonetizeSubscriptionsPaidTiersNew;
