import { useState } from 'react';
import toast from 'react-hot-toast';
import { PencilSimple } from '@phosphor-icons/react';

import useUpdateSitePackage from '@/hooks/useSitePackages/useUpdateSitePackage';
import { SitePackage } from '@/interfaces/site_package';

import { Button } from '../../../_components/UI/Button';
import { Input } from '../../../_components/UI/Input';
import { Switch } from '../../../_components/UI/Switch';
import { Text } from '../../../_components/UI/Text';

interface Props {
  project?: SitePackage;
}

export const TemplatesTopBar = ({ project }: Props) => {
  const [newProjectName, setNewProjectName] = useState<string>(project?.name || '');
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const updateSitePackage = useUpdateSitePackage({
    id: project?.id || '',
    onSuccess: () => {
      toast.success('Success');
    },
  });

  const handleNameChange = () => {
    updateSitePackage.mutate({
      name: newProjectName,
    });
    setIsEditing(false);
  };

  const handleToggle = () => {
    updateSitePackage.mutate({
      available_to_public: !project?.available_to_public,
    });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col space-y-2 max-w-[620px]">
        <div className="flex items-center gap-3">
          {!isEditing && (
            <>
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                {project?.name}
              </Text>
              <Button size="sm" Icon={PencilSimple} variant="secondary" onClick={() => setIsEditing(true)} />
            </>
          )}
          {isEditing && (
            <>
              <Input
                placeholder="Project Name"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
              />
              <Button variant="secondary" onClick={handleNameChange}>
                Save
              </Button>
            </>
          )}
        </div>
      </div>
      <Switch id="publish" checked={project?.available_to_public} onCheckedChange={handleToggle} labelText="Publish" />
    </div>
  );
};
