import { ChartCard } from '@/components/Chartsv2';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { TitleSm, Typography } from '@/components/Typography';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Tab } from '@/interfaces/general';
import TabNavigation from '@/ui/TabNavigation';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import useOfferOverviewByPeriod from '../_hooks/useOffersOverviewByPeriod';

const OverviewGraph = ({ tabs }: { tabs: Tab[] }) => {
  // Overview can only be broken down monthly at the moment so filter out the unneeded options
  const excludeOptions = [TimePeriod.LAST_24_HOURS, TimePeriod.LAST_4_WEEKS, TimePeriod.LAST_7_DAYS];
  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: TimePeriod.ALL_TIME, excludeOptions });
  const { data, isLoading, isError } = useOfferOverviewByPeriod(period as keyof typeof timePeriodLabels);

  const formatValue = (num: number) => new Intl.NumberFormat('en').format(num);

  return (
    <ChartCard
      title="Offer Performance"
      isLoading={isLoading}
      noResults={isError}
      noResultsText=""
      actionChildren={<TimePeriodSelect />}
    >
      <TabNavigation tabs={tabs} variant="tertiary" />
      <div className="mt-6">
        {data?.chart_data ? (
          <Items className="!shadow-none">
            <ItemHeaders>
              <ItemHeader>Plan Type</ItemHeader>
              <ItemHeader>Monthly Subscriptions</ItemHeader>
              <ItemHeader>Annual Subscriptions</ItemHeader>
              <ItemHeader>Total Paid Subscriptions</ItemHeader>
            </ItemHeaders>
            <ItemsBody>
              {data.chart_data.map((row) => (
                <ItemRow className="last:border-b-0" key={row.name}>
                  <ItemColumn>
                    <Typography token="font-medium/text/base">{row.name}</Typography>
                  </ItemColumn>
                  <ItemColumn>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {formatValue(row.month)}
                    </Typography>
                  </ItemColumn>
                  <ItemColumn>
                    <Typography token="font-medium/text/sm" colorWeight="700">
                      {formatValue(row.annual)}
                    </Typography>
                  </ItemColumn>
                  <ItemColumn>
                    <Typography token="font-bold/text/base" color="tertiary" colorWeight="600">
                      {formatValue(row.total)}
                    </Typography>
                  </ItemColumn>
                </ItemRow>
              ))}
            </ItemsBody>
          </Items>
        ) : (
          <div className="h-40 px-4 flex items-center justify-center">
            <TitleSm className="text-sm">No data for the chosen time range</TitleSm>
          </div>
        )}
      </div>
    </ChartCard>
  );
};

export default OverviewGraph;
