import { ArrowRightIcon } from '@heroicons/react/20/solid';

import {
  automationActionOptions,
  behaviorActionOptions,
  emailActionOptions,
} from '@/pages/Segments/ConditionsEditor/nameOptions';
import { NameTypes } from '@/pages/Segments/ConditionsEditor/types';

import ConditionLabelContainer from './ConditionLabelContainer';

interface Props {
  conditionType: string;
  conditionName?: string;
  label?: string;
}

const ConditionLabel = ({ conditionType, conditionName, label }: Props) => {
  if (conditionType === NameTypes.ATTRIBUTE) {
    return (
      <ConditionLabelContainer>
        <span>Subscriber data</span>
        <ArrowRightIcon width="14" height="14" />
        <span>Attribute</span>
      </ConditionLabelContainer>
    );
  }

  if (conditionType === NameTypes.MEASURE) {
    return (
      <ConditionLabelContainer>
        <span>Subscriber data</span>
        <ArrowRightIcon width="14" height="14" />
        <span>Engagement</span>
      </ConditionLabelContainer>
    );
  }

  if (conditionType === NameTypes.EMAIL_ACTION) {
    const conditionLabel = conditionName && emailActionOptions.find((option) => option.value === conditionName)?.label;
    return (
      <ConditionLabelContainer>
        <span>Email data</span>
        {conditionLabel && (
          <>
            <ArrowRightIcon width="14" height="14" />
            <span>{conditionLabel}</span>
          </>
        )}
      </ConditionLabelContainer>
    );
  }

  if (conditionType === NameTypes.BEHAVIOR_ACTION || conditionName === 'referral_count') {
    const conditionLabel =
      conditionName && behaviorActionOptions.find((option) => option.value === conditionName)?.label;
    return (
      <ConditionLabelContainer>
        <span>Behavior data</span>
        {conditionLabel && (
          <>
            <ArrowRightIcon width="14" height="14" />
            <span>{conditionLabel}</span>
          </>
        )}
      </ConditionLabelContainer>
    );
  }

  if (conditionType === NameTypes.AUTOMATION_ACTION) {
    const conditionLabel =
      conditionName && automationActionOptions.find((option) => option.value === conditionName)?.label;

    return (
      <ConditionLabelContainer>
        <span>Automation data</span>
        <ArrowRightIcon width="14" height="14" />
        <span>{conditionLabel}</span>
      </ConditionLabelContainer>
    );
  }

  if (label) {
    return <ConditionLabelContainer>{label}</ConditionLabelContainer>;
  }

  return null;
};

export default ConditionLabel;
