import { PropsWithChildren } from 'react';
import cx from 'classnames';

import { Tooltip } from '../../../UI/Tooltip';

type Props = PropsWithChildren<{
  disabled: boolean;
  tooltip?: string;
}>;

const DisabledSetting = ({ disabled, tooltip, children }: Props) => {
  if (tooltip && disabled) {
    return (
      <Tooltip center={tooltip} delay={300}>
        <div className={cx(disabled && 'opacity-50 pointer-events-none')}>{children}</div>
      </Tooltip>
    );
  }

  return <div className={cx(disabled && 'opacity-50 pointer-events-none')}>{children}</div>;
};

export default DisabledSetting;
