import { useState } from 'react';
import toast from 'react-hot-toast';

import { useGoToBilling } from '@/hooks';
import { useTrialStatus } from '@/hooks/usePublicationDashboard';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useCreateTrial from '@/routes/onboarding/_hooks/useCreateTrial';
import { capitalize } from '@/utils';
import analytics from '@/utils/analytics';

import { PLAN } from '../../utils/plans';

import Card, { ICard } from './Card';
import Container from './Container';
import Hero from './Hero';
import Stat from './Stat';
import VideoModal from './VideoModal';
import WhatAreYouWaitingFor from './WhatAreYouWaitingFor';

interface Props {
  plan: PLAN;
  title: string;
  description: string;
  youtubeUrl?: string;
  cards: ICard[];
  statNumber?: number | string;
  statDescription?: string;
  learnMoreUrl: string;
  statHeading?: string;
  onUpsell: () => void;
  disclaimer?: string;
}

const UpsellPage: React.FC<Props> = (props) => {
  const {
    plan,
    title,
    description,
    youtubeUrl,
    cards,
    statNumber,
    statDescription,
    learnMoreUrl,
    onUpsell,
    statHeading = 'Used by all the top newsletters',
    disclaimer,
  } = props;
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const currentPublicationId = useCurrentPublicationId();
  const trialStatusQuery = useTrialStatus(currentPublicationId);
  const { data: trialStatus } = trialStatusQuery || {};
  const trialEligible = trialStatus?.trial_eligible;
  const createTrial = useCreateTrial();
  const goToBilling = useGoToBilling();

  const startTrial = () => {
    createTrial.mutate(
      { selectedPlan: plan },
      {
        onSuccess: () => {
          analytics.track('Trial Started', {
            state: 'trialing',
            currentPlan: 'launch',
            trialPlan: plan,
          });

          toast.loading(`Starting your ${capitalize(plan)} trial`);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        },
      }
    );
  };

  const handleUpsell = () => {
    if (trialEligible) {
      startTrial();
    } else {
      goToBilling();
    }

    onUpsell();
  };

  return (
    <>
      <Container>
        <Hero
          plan={plan}
          pillUrl={learnMoreUrl}
          title={title}
          description={description}
          onUpgradeClick={handleUpsell}
          videoUrl={youtubeUrl}
          onVideoClick={() => setIsVideoModalOpen(true)}
          disclaimer={disclaimer}
        />
        {cards.map((card, index) => (
          <Card key={card.heading} index={index} card={card} />
        ))}
        {statNumber && statDescription ? (
          <Stat
            plan={plan}
            statHeading={statHeading}
            statDescription={statDescription}
            statNumber={statNumber}
            onUpgradeClick={handleUpsell}
          />
        ) : (
          <WhatAreYouWaitingFor plan={plan} onUpgradeClick={handleUpsell} />
        )}
        {disclaimer && (
          <div className="text-center text-gray-500 text-sm mt-8">
            <p>{disclaimer}</p>
          </div>
        )}
      </Container>
      <VideoModal videoUrl={youtubeUrl} isOpen={isVideoModalOpen} onClose={() => setIsVideoModalOpen(false)} />
    </>
  );
};

export default UpsellPage;
