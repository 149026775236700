import TwoColumnPageContainer from '@/routes/settings/_components/TwoColumnPageContainer';
import { Card } from '@/ui/Card';

import PersonalInfoForm from './PersonalInfoForm';
import SocialLinksForm from './SocialLinksForm';

const PersonalInfoSettings: React.FC = () => {
  return (
    <TwoColumnPageContainer
      useAlternateWidth
      rhsColumn={
        <Card>
          <SocialLinksForm />
        </Card>
      }
    >
      <Card>
        <PersonalInfoForm />
      </Card>
    </TwoColumnPageContainer>
  );
};

export default PersonalInfoSettings;
