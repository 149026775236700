// import { LineDivider } from '@/components/LineDivider';
import { PostStats } from '@/interfaces/post';

// import AdStatsContainer from '../AdStatsContainer';
// import BoostStatsContainer from '../BoostStatsContainer';
import ClickMetric from './ClickMetric';

interface Props {
  postId: string;
  postStats: PostStats;
}

const ClicksSummary = ({ postId, postStats }: Props) => {
  return (
    <div className="flex flex-col gap-y-4" key={`clicks-summary-post-${postId}`}>
      <ClickMetric
        label="Click per Unique Open"
        description="Recipient who clicked / unique opens (%)"
        value={`${postStats.click_rate}%`}
      />
      <ClickMetric
        label="Click per Total Opens"
        description="Recipients who clicked / total opens (%)"
        value={`${postStats.click_rate_by_total_opened}%`}
      />
      {/* <LineDivider className="border-dashed" />
      <AdStatsContainer postId={postId}>
        {(stats, isLoading) => {
          return (
            <ClickMetric
              label="beehiiv Ad"
              description="Unique clicks on ad block / unique opens (%)"
              value={isLoading ? '-' : `${stats.click_rate}%`}
            />
          );
        }}
      </AdStatsContainer>
      <BoostStatsContainer postId={postId}>
        {(stats, isLoading) => {
          return (
            <ClickMetric
              label="beehiiv Boost"
              description="Recipients clicks on email boost block / unique opens (%)"
              value={isLoading ? '-' : `${stats.click_rate}%`}
            />
          );
        }}
      </BoostStatsContainer> */}
    </div>
  );
};

export default ClicksSummary;
