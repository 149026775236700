import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SizingSettings } from '../SizingSettings';

const AccordionSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['accordion']}>
      <SectionRenderer title="Layout">
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <SizingSettings editor={editor} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>
    </RenderCondition>
  );
};

export { AccordionSettings };
