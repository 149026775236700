import React from 'react';

import LoadingBox from '@/components/LoadingBox';
import { useOrganization } from '@/hooks';
import { useCurrentOrganizationId } from '@/hooks/useOrganization';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Card } from '@/ui/Card';

import PageContainer from '../../_components/PageContainer';

import CreateCard from './CreateCard';
import PublicationIdCard from './PublicationIdCard';

const WorkspaceApi: React.FC = () => {
  const currentPublicationId = useCurrentPublicationId();
  const currentOrganizationId = useCurrentOrganizationId();
  const {
    data: organizationData,
    isLoading: isLoadingOrgnanization,
    isError: isErrorOrgnanization,
  } = useOrganization(currentOrganizationId, currentPublicationId);
  const { organization } = organizationData || {};

  return (
    <LoadingBox isLoading={isLoadingOrgnanization} isError={isErrorOrgnanization}>
      {organization ? (
        <PageContainer key={`${currentOrganizationId}-${currentPublicationId}`}>
          <Card>
            <CreateCard publicationId={currentPublicationId} organization={organization} />
          </Card>
          <Card>
            <PublicationIdCard organizationId={currentOrganizationId} />
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default WorkspaceApi;
