import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

import ActivityFeed from '../_components/ActivityFeed';
import { useSearchContext } from '../_context/searchContext';
import { useSubscriberContext } from '../_context/subscriberContext';
import { usePollActivityFeedEvents } from '../_hooks/useActivityFeedEvents';

const SubscriberPollActivity = () => {
  const { search, eventType } = useSearchContext();
  const { subscription } = useSubscriberContext();
  const query = usePollActivityFeedEvents({ search, subscription, eventType });

  return (
    <ActivityFeed
      title="Polls Activity Feed"
      emptyText="No poll events for this subscriber have been recorded yet"
      query={query}
      eventTypeOptions={[ActivityFeedEventType.POLL_RESPONSE, ActivityFeedEventType.POLL_EXTENDED_FEEDBACK]}
    />
  );
};

export default SubscriberPollActivity;
