import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { UpgradePage } from '../../interfaces/upgrade_page';
import api from '../../services/swarm';

interface Props {
  upgradePageId?: string;
  status: string;
}

const useUpgradePage = ({ status, upgradePageId }: Props) => {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<UpgradePage>(
    ['upgrade_page', publicationId, status],
    () =>
      api
        .get<any>(`/upgrade_pages/${upgradePageId}`, {
          params: {
            status,
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!status && !!publicationId,
    }
  );
};

export default useUpgradePage;
