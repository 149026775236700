import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { UpsellPage } from '@/components/UpsellPage';
import { useSettings } from '@/context/settings-context';
import { useSetting } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useUpgradeIntent from '@/hooks/useUpgradeIntent';
import { Setting } from '@/interfaces/setting';
import { IntentAction } from '@/interfaces/upgrades';
import { PLAN } from '@/utils/plans';

const Teaser = () => {
  const publicationId = useCurrentPublicationId();
  const { pushDataLayerEvent } = useUpgradeIntent({ publicationId, intentAction: IntentAction.USE_FORMS });
  const { settings } = useSettings();
  const tieredPricing = useSetting({ setting: Setting.TIERED_PRICING, defaultValue: false });

  const navigate = useNavigate();

  useEffect(() => {
    if (settings?.forms) {
      toast.success('Surveys are now enabled.');
      navigate('/forms');
    }
  }, [settings, navigate]);

  return (
    <UpsellPage
      plan={tieredPricing ? PLAN.SCALE : PLAN.GROW}
      title="Surveys"
      youtubeUrl="https://www.youtube.com/embed/CGEfrVPkDGA"
      description="Gather data in seconds and grow with your audience with surveys."
      cards={[
        {
          heading: 'Gather First-Party Data.',
          description:
            'Understand your audience better and get more accurate, relevant, and actionable data by engaging users directly.',
          imageUrl:
            'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,width=1080,quality=100/www/survey-forms/benefits/section-1.png',
        },
        {
          heading: 'Eliminate 3rd-Party Tools.',
          description:
            'Everything you need to create, distribute, and analyze surveys is right here, simplifying your workflow and reducing costs.',
          imageUrl:
            'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,width=1080,quality=100/www/survey-forms/benefits/section-2.png',
        },
        {
          heading: 'Personalize Reader Experiences.',
          description:
            'Create a more personalized and engaging reader experience by collecting information about your readers preferences and needs.',
          imageUrl:
            'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,width=1080,quality=100/www/survey-forms/benefits/section-3.png',
        },
      ]}
      statNumber="500k+"
      statDescription="Surveys created."
      learnMoreUrl="https://www.beehiiv.com/features/survey-forms"
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default Teaser;
