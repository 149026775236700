import { Fragment, ReactNode } from 'react';

import { useSettings } from '@/context/settings-context';
import { usePostClickMap, usePostClicks } from '@/hooks';
import { Order } from '@/interfaces/general';
import { DeviceType, GroupBy, PostClick } from '@/interfaces/post';

interface Props {
  postId: string;
  children: (
    clicks: PostClick[],
    totalClicks: number | undefined,
    isLoading: boolean,
    fetchNextPage?: () => void,
    hasNextPage?: boolean | undefined,
    isFetchingNextPage?: boolean
  ) => ReactNode;
  direction?: Order;
  platform?: 'web' | 'email';
  deviceType?: DeviceType;
  allResults?: boolean;
  groupBy?: GroupBy;
  useClickMap?: boolean;
  orderBy?: string;
}

const PostClicksContainer = ({
  postId,
  children,
  platform,
  direction = Order.DESC,
  deviceType,
  groupBy,
  allResults = false,
  useClickMap = false,
  orderBy,
}: Props) => {
  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;

  const {
    data: postClicksData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = usePostClicks({
    postId,
    useApiary: canUseApiary,
    platform,
    direction,
    deviceType,
    allResults,
    groupBy,
    staleTime: undefined,
    enabled: !useClickMap,
  });

  const { data: postClickMapData, isLoading: isLoadingPostClickMapData } = usePostClickMap({
    postId,
    direction,
    staleTime: undefined,
    enabled: useClickMap,
    orderBy,
  });

  if (useClickMap && groupBy === 'link_id') {
    const clicks = postClickMapData?.pages?.flatMap((page) => page.clicks) || [];
    const totalClicks = clicks.length;
    return <>{children(clicks, totalClicks, isLoadingPostClickMapData)}</>;
  }

  const clicks = postClicksData?.pages?.flatMap((page) => page.clicks) || [];
  const totalClicks = postClicksData?.pages[0]?.pagination?.total;
  return <>{children(clicks, totalClicks, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage)}</>;
};

export default PostClicksContainer;
