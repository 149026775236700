import Banner from '@/components/Banner';
import { BannerProps } from '@/components/Banner/Banner';
import { Segment, SegmentType } from '@/interfaces/segment';

interface SegmentWarningProps {
  segment?: Segment;
}

const segmentBannerAttributes = (segment: Segment): { variant: BannerProps['variant']; title: string } | null => {
  if (segment.paused) {
    return {
      variant: 'danger',
      title: 'This segment is inactive. Recalculate this Segment manually to start enrolling subscribers.',
    };
  }

  if (segment.segment_type === SegmentType.DYNAMIC) {
    return {
      variant: 'info',
      title:
        'Subscribers will enroll when this Dynamic Segment is recalculated daily or when you manually recalculate.',
    };
  }
  if (segment.segment_type === SegmentType.STATIC) {
    return {
      variant: 'warning',
      title: 'Subscribers will enroll when you manually recalculate this Static Segment.',
    };
  }

  return null;
};

const SegmentWarning = ({ segment }: SegmentWarningProps) => {
  if (!segment) return null;
  const bannerAttributes = segmentBannerAttributes(segment);
  if (!bannerAttributes) return null;

  const { variant, title } = bannerAttributes;

  return <Banner isScreenWide={false} variant={variant} title={title} />;
};

export default SegmentWarning;
