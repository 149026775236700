import { FC } from 'react';

import ColorField from '../../components/Form/ColorField';

import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { ThemeFormProps } from './types';

const TableForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette, fonts }) => {
  return (
    <FormContainer>
      <FormSection title="Styles">
        <FormInput>
          <Slider
            name="table_border_width"
            label="Thickness"
            onChange={(val: number) => onUpdate({ ...theme, table_border_width: val.toString() })}
            initialValue={theme.table_border_width}
            min={0}
            max={10}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput title="Color">
          <ColorField
            placement="bottom"
            color={theme.table_border_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, table_border_color: color?.hexString })}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="table_padding"
            label="Spacing between elements"
            onChange={(val: number) =>
              onUpdate({ ...theme, table_padding_top: val.toString(), table_padding_bottom: val.toString() })
            }
            initialValue={theme.table_padding_top}
            min={0}
            max={80}
            valueSuffix="px"
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Table Headers">
        <FormInput title="Font Family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.table_header_font_family}
              onUpdate={(val) => onUpdate({ ...theme, table_header_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Font Color">
          <ColorField
            placement="bottom"
            color={theme.table_header_font_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, table_header_font_color: color?.hexString })}
          />
        </FormInput>
        <FormInput title="Background Color">
          <ColorField
            placement="bottom"
            color={theme.table_header_background_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, table_header_background_color: color?.hexString })}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="table_header_padding"
            label="Inner Spacing"
            onChange={(val: number) => onUpdate({ ...theme, table_header_padding: val.toString() })}
            initialValue={theme.table_header_padding}
            min={0}
            max={25}
            valueSuffix="px"
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Table Cells">
        <FormInput title="Font Family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.table_cell_font_family}
              onUpdate={(val) => onUpdate({ ...theme, table_cell_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Font Color">
          <ColorField
            placement="bottom"
            color={theme.table_cell_font_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, table_cell_font_color: color?.hexString })}
          />
        </FormInput>
        <FormInput title="Background Color">
          <ColorField
            placement="bottom"
            color={theme.table_cell_background_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, table_cell_background_color: color?.hexString })}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="table_cell_padding"
            label="Inner Spacing"
            onChange={(val: number) => onUpdate({ ...theme, table_cell_padding: val.toString() })}
            initialValue={theme.table_cell_padding}
            min={0}
            max={25}
            valueSuffix="px"
          />
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default TableForm;
