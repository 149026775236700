import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type Props = PropsWithChildren<{}>;

const Layout = ({ children }: Props) => {
  return (
    <div className="min-h-screen w-full flex overflow-hidden flex-col-reverse justify-end md:justify-start md:flex-col">
      {children}
    </div>
  );
};

const RightPane = styled.div.attrs({
  className: 'w-full block h-32 md:h-4 md:-mt-[50vh] -z-1 md:h-[50vh] bg-cover bg-center',
})`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255)),
    url('https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=90/static_assets/onboarding/background.png');

  @media (min-width: 768px) {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.1), rgba(255, 255, 255)),
      url('https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=90/static_assets/onboarding/background.png');
  }
`;

const LeftPane = ({ children }: Props) => {
  return (
    <div className="w-full md:min-h-screen overflow-y-auto flex justify-center md:py-8 px-8 md:pt-[20vh]">
      {children}
    </div>
  );
};

export { Layout, LeftPane, RightPane };
