import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkCampaignOpportunityGroup } from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from '@/services/swarm';

export default function useCampaignOpportunityGroupsCreate({ campaignId }: { campaignId: string }) {
  const navigate = useNavigate();

  const createGroup = (formData: FormData): Promise<AdNetworkCampaignOpportunityGroup> =>
    api
      .post(`/ad_network/internal/campaigns/${campaignId}/campaign_opportunity_groups`, formData)
      .then((res) => res.data);

  return useMutation<AdNetworkCampaignOpportunityGroup, MutationErrorResponse, FormData>(createGroup, {
    onSuccess: ({ id }) => {
      toast.success('Group created successfully');
      navigate(`/ad_network/campaign_opportunity_groups/${id}`);
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
