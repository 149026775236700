import cx from "classnames";

interface Props {
  children: string;
  as: 'h1' | 'h2' | 'h3' | 'h4';
  lowercase?: boolean;
  className?: string;
  id?: string;
}

const Heading = ({ children, as, lowercase = false, className, id }: Props) => {
  const Component = as;

  const headingSizeClass = () => {
    switch (as) {
      case 'h1':
        return 'text-4xl';
      case 'h2':
        return 'text-3xl';
      case 'h3':
        return 'text-2xl';
      default:
        return 'text-xl';
    }
  }

  return <Component id={id} className={cx("font-black", headingSizeClass(), !lowercase && "uppercase", className)}>{children}</Component>;
};

export default Heading;