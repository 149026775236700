import { TierChangesGraph } from '@/components/_domain/PremiumData';
import Grid from '@/components/Grid';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { TimePeriod } from '@/utils';

import PageWrapper from '../_components/PageWrapper';

import TopAttributionSourcesCard from './_components/TopAttributionSourcesCard';
import TopPostsCard from './_components/TopPostsCard';

const MonetizeSubscriptionsAttribution = () => {
  const currentPublicationId = useCurrentPublicationId();
  return (
    <PageWrapper>
      <TierChangesGraph publicationId={currentPublicationId} defaultTimePeriod={TimePeriod.LAST_4_WEEKS} />
      <Grid gap={7} className="grid-cols-2">
        <TopAttributionSourcesCard />
        <TopPostsCard />
      </Grid>
    </PageWrapper>
  );
};

export default MonetizeSubscriptionsAttribution;
