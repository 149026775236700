/**
 * Removes the protocol from a URL if it exists
 *
 * @example stripUrlProtocol('foo.com') => 'foo.com'
 * @example stripUrlProtocol('https://bar.com') => 'bar.com'
 * @param url The URL to strip protocol from
 * @returns {string}
 */
const stripUrlProtocol = (url: string): string => {
  if (!url) return url;

  return url.replace(/^(https?:)?\/\//, '');
};

export default stripUrlProtocol;
