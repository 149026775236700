export enum AutomationJourneyStatus {
  INITIATED = 'initiated',
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface AutomationJourney {
  id: string;
  status: AutomationJourneyStatus;
  automation_name: string;
}
