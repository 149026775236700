import { useParams } from 'react-router-dom';

import { FormContextProvider } from '../../FormContext';
import MilestoneForm from '../MilestoneForm';

export default function ConfigureNewMilestone() {
  const { milestoneId } = useParams();

  return (
    <FormContextProvider milestoneId={milestoneId}>
      <MilestoneForm milestoneId={milestoneId} />
    </FormContextProvider>
  );
}
