import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';

const Help = () => {
  return (
    <div className="flex flex-row space-x-2">
      <span className="text-gray-500">Need help?</span>
      <div className="flex flex-col space-y-2 items-end">
        <a
          href="https://developers.beehiiv.com/docs/webhooks/84qbkdalj3bo2-overview"
          target="_blank"
          rel="noreferrer"
          className="text-primary-500 hover:text-primary-700"
        >
          <div className="flex flex-row items-center space-x-1">
            <span>Technical Docs</span>
            <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          </div>
        </a>
        <a
          href="https://support.beehiiv.com/hc/en-us/articles/18034325358743"
          target="_blank"
          rel="noreferrer"
          className="text-primary-500 hover:text-primary-700"
        >
          <div className="flex flex-row items-center space-x-1">
            <span>Support Docs</span>
            <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Help;
