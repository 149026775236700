import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type Props = PropsWithChildren<{
  elementId?: string;
  scrollIntoView?: boolean;
}>;

export default function ScrollOnMount({ children, elementId, scrollIntoView = false }: Props) {
  const location = useLocation();

  useEffect(() => {
    if (elementId) {
      const element = window?.document?.getElementById(elementId);

      if (scrollIntoView) {
        setTimeout(() => element?.scrollIntoView({ behavior: 'smooth', inline: 'start' }), 100);
        return;
      }

      setTimeout(() => element?.scrollTo({ left: 0, top: 0, behavior: 'smooth' }), 1);
    }
  }, [elementId, scrollIntoView, location.pathname]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
