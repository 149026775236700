import toast from 'react-hot-toast';

import api from '@/services/swarm';

import { useCurrentPublication, useUpdateWebTemplate, useWebTemplate } from '../../../../../../hooks';
import { SignupFlowItem } from '../../../../../../interfaces/web_template';
import { ImageForm, Section } from '../../../../Components';
import InputForm from '../../../../Components/InputForm';

import CustomStep from './CustomStep';
import PublicationStep from './PublicationsStep';
import RecommendationsStep from './RecommendationsStep';
import SubscribeSurveyStep from './SubscribeSurveyStep';
import UpgradeStep from './UpgradeStep';

const SignupFlowSection = () => {
  const { data: currentPublication } = useCurrentPublication();

  const webTemplateMutation = useUpdateWebTemplate();
  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: any = webTemplateRequest;
  const signupFlow = webTemplate?.signup_flow;
  const signupMessaging = webTemplate?.signup_flow_messaging;

  const handleUpdateImg = (avatar: any): void => {
    const formData = new FormData();
    formData.append('publication_id', currentPublication?.id || '');
    formData.append('web_template[signup_flow_avatar]', avatar);

    api
      .patch(`/web_template/${currentPublication?.web_template_id}`, formData)
      .then(() => {
        toast.success('Avatar successfully updated!');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  };

  return (
    <Section
      title="Subscribe Flow"
      description="Customize the redirect journey new subscribers are taken through after signing up for your publication."
      scrollToId="subscribe-flow"
      isLoading={!currentPublication && !webTemplate}
    >
      <div className="space-y-4">
        <ImageForm
          label="Avatar"
          value={webTemplate?.signup_flow_avatar_url || null}
          onSave={handleUpdateImg}
          variant="circle"
          description="The avatar that appears at the top of your signup flow."
        />
        <InputForm
          name="messaging_title"
          label="Success Message"
          placeholder="Thanks for subscribing!"
          helperText="The success message that appears at the top of your signup flow."
          value={signupMessaging?.success_message || ''}
          onSave={(val) =>
            webTemplateMutation.mutateAsync({
              signup_flow_messaging: JSON.stringify({
                ...signupMessaging,
                success_message: val,
              }),
            })
          }
          isSaving={webTemplateMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
        <div className="space-y-4">
          {signupFlow?.map((step: SignupFlowItem) => {
            if (step.managed_type === 'survey') {
              return <SubscribeSurveyStep key={step.id} step={step} webTemplate={webTemplate} />;
            }
            if (step.managed_type === 'recommendations') {
              return <RecommendationsStep key={step.id} step={step} webTemplate={webTemplate} />;
            }
            if (step.managed_type === 'upgrade') {
              return <UpgradeStep key={step.id} step={step} webTemplate={webTemplate} />;
            }
            if (step.managed_type === 'publications') {
              return <PublicationStep key={step.id} step={step} webTemplate={webTemplate} />;
            }
            if (step.managed_type === 'custom') {
              return <CustomStep key={step.id} step={step} />;
            }

            return null;
          })}
        </div>
      </div>
    </Section>
  );
};

export default SignupFlowSection;
