import { useNavigate } from 'react-router-dom';

import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import { useAudience } from '@/hooks/useDashboard';
import { AudienceEndpoints } from '@/hooks/useDashboard/useAudience';
import { Referrer } from '@/interfaces/dashboard';

import getLocaleString from '../../../utils/getLocaleString';
import Sources from '../components/Sources';
import { PageProps } from '../types';

const TopReferrers = () => {
  const { period } = usePageContext<PageProps>();
  const navigate = useNavigate();

  const { data } = useAudience({
    endpoint: AudienceEndpoints.TOP_REFERRERS,
    timePeriod: period,
  });

  const topReferrers = data
    ? data?.top_referrers?.[0]?.map((referrer: Referrer) => {
        return {
          name: referrer.subscription_email,
          value: getLocaleString(referrer.total_referrals),
        };
      })
    : [];

  return (
    <Sources
      title="Top Referrers"
      listData={topReferrers}
      cta={{
        text: 'View referral dashboard',
        onClick: () => navigate('/referral_program/overview'),
      }}
    />
  );
};

export default TopReferrers;
