import { useQueryClient } from 'react-query';

import { useLandingPage, useUpdateLandingPage } from '@/hooks/useLandingPages';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import ChildLoader from '@/routes/website_builder/_components/Form/PageSpecific/ChildLoader';

import usePageNavigator from '../../_hooks/usePageNavigator';
import useSetPublishId from '../../_hooks/useSetPublishId';
import useTabNavigator from '../../_hooks/useTabNavigator';
import FAQSection from '../Form/FaqSection';
import TestimonialSection from '../Form/TestimonialSection';

import FeaturedPostsSection from './FeaturedPostSection';
import HeroSection from './HeroSection';

interface Props {
  setPublishId: (id: string) => void;
}

const SignupPageSettings = ({ setPublishId }: Props) => {
  const queryClient = useQueryClient();
  const { handleSaved, setIsSaving } = usePageContext();
  const landingPageQuery = useLandingPage({ slug: '/subscribe', status: 'draft' });
  const { data: landingPage } = landingPageQuery;
  const { isSignupPage } = usePageNavigator();
  const { isStylesTab } = useTabNavigator();

  useSetPublishId({
    updateIdCheck: isSignupPage && !isStylesTab,
    setPublishId,
    idToPublish: landingPage?.id || '',
  });

  const updateLandingPage = useUpdateLandingPage({
    pageId: landingPage?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['landing_page', '/subscribe', 'draft']);
      handleSaved();
    },
  });

  const handleSaveMainSection = (payload: any) => {
    setIsSaving(true);
    updateLandingPage.mutate({ landing_page: { main_section: JSON.stringify(payload) } });
  };

  const handleSaveTestimonialsSection = (payload: any) => {
    setIsSaving(true);
    updateLandingPage.mutate({ landing_page: { testimonials_section: JSON.stringify(payload) } });
  };

  const handleSaveFeaturedPostsSection = (payload: any) => {
    setIsSaving(true);
    updateLandingPage.mutate({ landing_page: { featured_posts_section: JSON.stringify(payload) } });
  };

  const handleSaveFAQSection = (payload: any) => {
    setIsSaving(true);
    updateLandingPage.mutate({ landing_page: { faq_section: JSON.stringify(payload) } });
  };

  return (
    <ChildLoader isLoading={!landingPage}>
      {landingPage && (
        <>
          <HeroSection landingPage={landingPage} handleSaveMainSection={handleSaveMainSection} />
          <TestimonialSection page={landingPage} handleSave={handleSaveTestimonialsSection} type="landing_page" />
          <FeaturedPostsSection page={landingPage} handleSave={handleSaveFeaturedPostsSection} />
          <FAQSection page={landingPage} handleSave={handleSaveFAQSection} />
        </>
      )}
    </ChildLoader>
  );
};

export default SignupPageSettings;
