import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

import { Divider } from '@/components/Divider';
import { Checkbox, Input } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';
import { usePrevalidateSignup } from '@/hooks';
import { useCreateTempUser, useCreateTempUserFromGoogle } from '@/hooks/useTempUser';
import { Button } from '@/ui/Button';
import { capitalize, toSentence } from '@/utils';
import { getAttributionCookies } from '@/utils/marketingCookies';
import { PLAN } from '@/utils/plans';

import { Layout, LogoWrapper } from './_components/Layout';

interface ApiError {
  field: string;
  message: string;
}

const getLocalEmail = (params: URLSearchParams) => params.get('email') || localStorage.getItem('email') || '';
const getLocalFirstName = (params: URLSearchParams) =>
  params.get('firstName') || localStorage.getItem('firstName') || '';
const getLocalLastName = (params: URLSearchParams) => params.get('lastName') || localStorage.getItem('lastName') || '';

const Signup = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const passwordHelper = useRef<HTMLDivElement>(null);

  const [email, setEmail] = useState(() => getLocalEmail(searchParams));
  const [firstName, setFirstName] = useState(() => getLocalFirstName(searchParams));
  const [lastName, setLastName] = useState(() => getLocalLastName(searchParams));
  const [password, setPassword] = useState('');
  const [receiveUpdates, setReceiveUpdates] = useState(true);
  const [errors, setErrors] = useState<ApiError[]>([]);

  const planParam = searchParams.get('plan') || '';
  const isEnterprisePlan = searchParams.get('enterprise') === 'true';
  const isTrialPlan = searchParams.get('trial') === 'true';
  const validPlanNames = Object.values(PLAN) as string[];

  const trialText = isEnterprisePlan ? `${capitalize(planParam || '')} trial` : '30-day free trial';

  if (!validPlanNames.includes(planParam) || planParam === PLAN.CUSTOM) {
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    localStorage.setItem('email', email);
    window.location.replace('https://www.beehiiv.com/pricing');
  }

  /**
   * Set any errors returned from the server.
   */
  const firstNameError = toSentence(
    'First name',
    errors.filter((error) => error.field === 'first_name').map((error) => error.message)
  );

  const lastNameError = toSentence(
    'Last name',
    errors.filter((error) => error.field === 'last_name').map((error) => error.message)
  );

  const emailError = toSentence(
    'Email is not valid. Code:',
    errors.filter((error) => error.field === 'email').map((error) => error.message)
  );
  const showVpnMessage = emailError && emailError.includes('[461]');

  const passwordError = toSentence(
    'Password',
    errors.filter((error) => error.field === 'password').map((error) => error.message)
  );

  const passIsValidLength = password.length >= 8 && password.length <= 20;
  const passHasCapital = /[A-Z]+/.test(password);
  const passHasNumber = /[0-9]+/.test(password);
  const passHasSpecial = /\W/.test(password);

  const validPassword = passIsValidLength && passHasCapital && passHasNumber && passHasSpecial;
  const isSubmitBtnDisabled = !email || !firstName || !lastName || !password || !validPassword;

  /**
   * Generic change handler to update the state of the form. This is used for all
   * fields and is triggered by the onChange event of the input.
   *
   * @param {event} event The onChange event.
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'email') {
      setEmail(value);
    } else if (name === 'firstName') {
      setFirstName(value);
    } else if (name === 'lastName') {
      setLastName(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  /**
   * Mutation to pre-validate the signup with each step. This is to ensure that the
   * server will accept a submission before moving on to the next step, such as validating
   * email uniqueness.
   */
  const preValidateSignup = usePrevalidateSignup();
  const createTempUser = useCreateTempUser();
  const createTempUserFromGoogle = useCreateTempUserFromGoogle();
  const isLoading = preValidateSignup.isLoading || createTempUser.isLoading;

  const handleSubmitErrors = (serverErrors: ApiError[] | string) => {
    if (typeof serverErrors === 'string') {
      toast.error(serverErrors);
      return serverErrors;
    }

    const formErrors = serverErrors.filter(
      (error) =>
        error.field === 'password' ||
        error.field === 'first_name' ||
        error.field === 'last_name' ||
        error.field === 'email'
    );

    if (formErrors.length) {
      setErrors(formErrors);
    }

    return formErrors;
  };

  const handleGoogeLogin = async (credentialResponse: any) => {
    // @ts-ignore
    await window.Talon?.eHawkTalon();
    // @ts-ignore
    const ehawkTal = document.getElementById('talon6').value;
    const marketingAttribution = getAttributionCookies();

    createTempUserFromGoogle
      .mutateAsync(
        {
          credential: credentialResponse.credential,
          marketingAttribution,
          ehawkTal,
          selectedPlan: planParam,
          isEnterprisePlan,
          isTrialPlan,
        },
        {
          onSuccess: (data) => {
            localStorage.setItem('selectedPlan', planParam);
            localStorage.setItem('selectedTrial', isTrialPlan.toString());
            localStorage.setItem('isEnterprisePlan', isEnterprisePlan.toString());
            localStorage.setItem('signupToken', data.token);

            navigate('/signup/phone_collection');
          },
        }
      )
      .catch((errPayload) => {
        handleSubmitErrors(errPayload?.response?.data?.error || []);
      });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // beehiiv ad network pixel conversion
    if (typeof bhpx === 'function') {
      bhpx('track', 'conversion', { host: 'www' });
    }
    localStorage.setItem('largeNavOpen', 'true');

    // @ts-ignore
    await window.Talon?.eHawkTalon();
    // @ts-ignore
    const ehawkTal = document.getElementById('talon6').value;

    preValidateSignup
      .mutateAsync({ firstName, lastName, email, password })
      .then(({ error: serverErrors }: { error: ApiError[] | string }) => {
        const prevalidateErrors = handleSubmitErrors(serverErrors);

        if (!prevalidateErrors.length) {
          const marketingAttribution = getAttributionCookies();

          localStorage.setItem('firstName', firstName);
          localStorage.setItem('lastName', lastName);
          localStorage.setItem('email', email);
          localStorage.setItem('selectedPlan', planParam);
          localStorage.setItem('selectedTrial', isTrialPlan.toString());
          localStorage.setItem('isEnterprisePlan', isEnterprisePlan.toString());

          createTempUser
            .mutateAsync({
              email,
              firstName,
              lastName,
              password,
              marketingAttribution,
              ehawkTal,
              selectedPlan: planParam,
              isEnterprisePlan,
              isTrialPlan,
            })
            .then(() => {
              navigate(`/signup/email_confirmation?email=${email}`);
            })
            .catch((errPayload) => {
              handleSubmitErrors(errPayload?.response?.data?.error || []);
            });
        }
      });
  };

  useEffect(() => {
    const startAdaEmbed = async () => {
      try {
        await window.adaEmbed.start({
          handle: 'beehiiv',
          metaFields: {
            channel: 'ada-signup-page',
          },
        });
      } catch {
        // console.error('Error starting Ada Embed:', error);
        try {
          await window.adaEmbed.setMetaFields({ channel: 'ada-signup-page' });
        } catch {
          // console.error('Error resetting Ada Embed:', error);
        }
      }
    };

    startAdaEmbed();
  }, []);

  return (
    <>
      <Helmet>
        <title>Registration - beehiiv</title>
      </Helmet>

      <Layout>
        <LogoWrapper
          title={`Let's get started with ${(isEnterprisePlan && 'a ') || (isTrialPlan && 'your ') || ''} ${
            isTrialPlan || isEnterprisePlan ? trialText : capitalize(planParam || '')
          } ✨`}
          subtitle={
            <>
              <Typography token="font-normal/text/base" colorWeight="700">
                One-time phone verification via SMS is required for signup. Msg and data rates may apply.
              </Typography>

              <Typography token="font-medium/text/base" colorWeight="700">
                No credit card details required.
              </Typography>
            </>
          }
        >
          <form className="flex flex-col w-full space-y-5" onSubmit={handleSubmit}>
            <div className="flex flex-col w-full space-x-0 space-y-4 md:space-y-0 md:space-x-4 md:flex-row">
              <Input
                required
                className="w-full"
                variant="primary"
                labelText="First Name"
                name="firstName"
                placeholderText="John"
                value={firstName}
                onChange={handleChange}
                errorText={firstNameError}
                autoFocus
              />
              <Input
                required
                className="w-full"
                variant="primary"
                labelText="Last Name"
                name="lastName"
                placeholderText="Doe"
                value={lastName}
                onChange={handleChange}
                errorText={lastNameError}
              />
            </div>
            <div>
              <Input
                required
                type="email"
                variant="primary"
                className="w-full"
                labelText="Email"
                name="email"
                placeholderText="name@email.com"
                value={email}
                onChange={handleChange}
                errorText={emailError}
              />
              {showVpnMessage && (
                <p className="mt-2 text-xs text-feedback-danger-500">
                  Please disable any VPN or Proxies and check the email spelling before trying again.
                </p>
              )}
            </div>
            <div>
              <Input
                required
                type="password"
                variant="primary"
                className="w-full"
                labelText="Password"
                name="password"
                placeholderText="********"
                value={password}
                onChange={handleChange}
                errorText={passwordError}
                onFocus={() => passwordHelper.current?.classList.remove('hidden')}
              />
              <div ref={passwordHelper} className="hidden">
                <TypographyStack gap="0" className="pt-2">
                  <Typography token="font-light/text/xs">Your Password must contain:</Typography>
                  <Typography
                    token="font-light/text/xs"
                    color={passIsValidLength ? 'success' : 'surface'}
                    colorWeight={passIsValidLength ? '600' : '900'}
                  >
                    &nbsp;&nbsp;{passIsValidLength ? <>&#10003;</> : <>&bull;</>} Between 8 and 20 characters
                  </Typography>
                  <Typography
                    token="font-light/text/xs"
                    color={passHasCapital ? 'success' : 'surface'}
                    colorWeight={passHasCapital ? '600' : '900'}
                  >
                    &nbsp;&nbsp;{passHasCapital ? <>&#10003;</> : <>&bull;</>} 1 or more upper case letter
                  </Typography>
                  <Typography
                    token="font-light/text/xs"
                    color={passHasNumber ? 'success' : 'surface'}
                    colorWeight={passHasNumber ? '600' : '900'}
                  >
                    &nbsp;&nbsp;{passHasNumber ? <>&#10003;</> : <>&bull;</>} 1 or more numbers
                  </Typography>
                  <Typography
                    token="font-light/text/xs"
                    color={passHasSpecial ? 'success' : 'surface'}
                    colorWeight={passHasSpecial ? '600' : '900'}
                  >
                    &nbsp;&nbsp;{passHasSpecial ? <>&#10003;</> : <>&bull;</>} 1 or more special characters
                  </Typography>
                </TypographyStack>
              </div>
            </div>
            <input type="hidden" name="talon6" value='{"version": 6, "status": -1}' id="talon6" />

            <Button type="submit" disabled={isSubmitBtnDisabled} loading={isLoading} className="w-full">
              {isLoading ? 'Creating Account' : 'Get Started'}
            </Button>
            {window.env.REACT_APP_ALLOW_GOOGLE_LOGIN && (
              <>
                <div className="pt-4">
                  <Divider>
                    <Typography token="font-normal/text/base">Or continue with</Typography>
                  </Divider>
                </div>
                <div className="flex flex-row justify-center pt-4">
                  <GoogleLogin
                    size="large"
                    onSuccess={async (credentialResponse) => {
                      await handleGoogeLogin(credentialResponse);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                    useOneTap
                    auto_select
                  />
                </div>
              </>
            )}
            <Typography token="font-medium/text/sm" className="text-center" colorWeight="500">
              Already have an account?{' '}
              <Link to="/login" className="font-medium underline text-action-secondary-600">
                Log In
              </Link>
            </Typography>

            <div className="flex items-center justify-center space-x-2">
              <Checkbox
                name="receive_updates"
                checked={receiveUpdates}
                onChange={(checked) => {
                  setReceiveUpdates(checked);
                }}
              />
              <Typography token="font-medium/text/sm" colorWeight="500">
                I want to receive updates about beehiiv
              </Typography>
            </div>
          </form>

          <TypographyStack gap="5" className="pt-5">
            <Typography token="font-medium/text/sm" className="text-center" colorWeight="500">
              By signing up you consent to our{' '}
              <a
                className="font-medium text-action-secondary-600"
                href="https://www.beehiiv.com/tou"
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                className="font-medium text-action-secondary-600"
                href="https://www.beehiiv.com/privacy"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
            </Typography>
          </TypographyStack>
        </LogoWrapper>
      </Layout>
    </>
  );
};

export default Signup;
