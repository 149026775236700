import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostOffer } from '@/interfaces/boosts/grow/boost_offer';
import api from '@/services/swarm';

const usePrimaryOffer = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchBoostOffer = (): Promise<BoostOffer> =>
    api.get(`/boosts/grow/primary_offer`, { params: { publication_id: currentPublicationId } }).then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'grow', 'primary_offer'], fetchBoostOffer, {
    retry: false,
  });
};

export default usePrimaryOffer;
