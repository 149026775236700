import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { ArrowPathIcon, BanknotesIcon } from '@heroicons/react/20/solid';
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/solid';

import LoadingBox from '@/components/LoadingBox';
import { EmptyCard, Items, ItemsBody } from '@/components/ResourceList';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import useUpdateBoostAgreements from '@/hooks/boosts/grow/useUpdateBoostAgreements';
import useWebApplications from '@/hooks/boosts/grow/useWebApplications';
import useOptions from '@/hooks/useOptions';
import BoostAgreementStatus from '@/interfaces/boosts/monetize/boost_agreement_status';
import { Option, Order } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import { FiltersPopopoverOperator, FiltersPopoverNumberInputSection, FiltersPopoverSection, FiltersPopoverSectionType } from '@/ui/FiltersPopover/FiltersPopover.types';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListBulkActions, useResourceListBulkActions } from '@/ui/ResourceListBulkActions';
import { ResourceListFilters, useResourceListFilters } from '@/ui/ResourceListFilters';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import { SCORE_ENGAGEMENT_LABELS, SCORE_GROWTH_LABELS, SCORE_QUALITY_LABELS, SCORE_SIZE_LABELS } from '../constants';

import ConfirmBoostAgreementsBulkAcceptModal from './ConfirmBoostAgreementsBulkAcceptModal';
import ConfirmBoostAgreementsBulkRejectModal from './ConfirmBoostAgreementsBulkRejectModal';
import WebApplicationItemV3 from './WebApplicationItemV3';

interface Props {
  boostOfferId?: string;
  narrowLayout: boolean;
}

const WebApplicationsV3 = ({ boostOfferId, narrowLayout }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const [tagsOperator, setTagsOperator] = useState<FiltersPopopoverOperator>(FiltersPopopoverOperator.EQUAL);
  const [tagsValue, setTagsValue] = useState<string[]>([]);

  const [languagesOperator, setLanguagesOperator] = useState<FiltersPopopoverOperator>(FiltersPopopoverOperator.EQUAL);
  const [languagesValue, setLanguagesValue] = useState<string[]>([]);

  const [countriesValue, setCountriesValue] = useState<string[]>([]);

  const [postCountOperatorValue, setPostCountOperatorValue] = useState<FiltersPopopoverOperator>(FiltersPopopoverOperator.GREATER_THAN);
  const [postCountValue, setPostCountValue] = useState<number>();

  const [sizeValue, setSizeValue] = useState<string[]>([]);
  const [growthValue, setGrowthValue] = useState<string[]>([]);
  const [engagementValue, setEngagementValue] = useState<string[]>([]);
  const [boostQualityValue, setBoostQualityValue] = useState<string[]>([]);

  const handleClearFilters = useCallback(() => {
    setTagsValue([]);
    setLanguagesValue([]);
    setCountriesValue([]);
    setPostCountValue(undefined);
    setSizeValue([]);
    setGrowthValue([]);
    setEngagementValue([]);
    setBoostQualityValue([]);
  }, []);

  // Tag options
  const tags = useOptions(currentPublicationId, 'tags');
  const { data: tagsData } = tags;
  const loadedTagOptions = tagsData?.options;
  const tagOptions = useMemo(() => {
    if (loadedTagOptions?.length > 0) {
      return loadedTagOptions.map((option: [number, string]): Option => {
        const [id, value] = option;
        return {
          label: value,
          value: id.toString(),
        };
      });
    }

    return [];
  }, [loadedTagOptions]);

  // Language options
  const languages = useOptions(currentPublicationId, 'languages');
  const { data: languagesData } = languages;
  const loadedLanguageOptions = languagesData?.options;
  const languageOptions = useMemo(() => {
    if (loadedLanguageOptions?.length > 0) {
      return loadedLanguageOptions.map((option: [number, string]): Option => {
        const [id, value] = option;
        return {
          label: value,
          value: id.toString(),
        };
      });
    }

    return [];
  }, [loadedLanguageOptions]);

  // Demographic options
  const countries = useOptions(currentPublicationId, 'demographic_countries');
  const { data: countriesData } = countries;
  const loadedCountryOptions = countriesData?.options;
  const countryOptions = useMemo(() => {
    if (loadedCountryOptions?.length > 0) {
      return loadedCountryOptions.map((option: [string, string]): Option => {
        const [value, label] = option;
        return { label, value };
      });
    }

    return [];
  }, [loadedCountryOptions]);

  const filterSections: FiltersPopoverSection[] = useMemo(() => [
    {
      type: FiltersPopoverSectionType.SELECT,
      name: 'categories',
      title: 'Category',
      placeholderText: 'Categories',
      options: tagOptions,
      value: tagsValue,
      onSelect: setTagsValue,
      operatorValue: tagsOperator,
      operators: [
        FiltersPopopoverOperator.EQUAL,
        FiltersPopopoverOperator.NOT_EQUAL,
      ],
      onOperatorChange: setTagsOperator
    },
    {
      type: FiltersPopoverSectionType.SELECT,
      name: 'language',
      title: 'Publication language',
      placeholderText: 'Languages',
      options: languageOptions,
      value: languagesValue,
      onSelect: setLanguagesValue,
      operatorValue: languagesOperator,
      operators: [
        FiltersPopopoverOperator.EQUAL,
        FiltersPopopoverOperator.NOT_EQUAL,
      ],
      onOperatorChange: setLanguagesOperator
    },
    {
      type: FiltersPopoverSectionType.SELECT,
      name: 'demographic',
      title: 'Audience demographic',
      labelText: 'Majority from',
      placeholderText: 'Countries',
      options: countryOptions,
      value: countriesValue,
      onSelect: setCountriesValue
    },
    {
      type: FiltersPopoverSectionType.NUMBER,
      name: 'posts',
      title: 'Number of posts',
      value: postCountValue,
      min: 0,
      max: 1000,
      onChange: setPostCountValue,
      operatorValue: postCountOperatorValue,
      operators: [
        FiltersPopopoverOperator.GREATER_THAN,
        FiltersPopopoverOperator.LESS_THAN,
        FiltersPopopoverOperator.EQUAL,
      ],
      onOperatorChange: setPostCountOperatorValue
    } as FiltersPopoverNumberInputSection,
    {
      type: FiltersPopoverSectionType.HEADER,
      name: 'metrics',
      title: 'Metrics',
    },
    {
      type: FiltersPopoverSectionType.SELECT,
      name: 'size',
      placeholderText: 'Size',
      options: Object.entries(SCORE_SIZE_LABELS).map(([value, label]) => ({
        label,
        value
      })),
      value: sizeValue,
      onSelect: setSizeValue,
    },
    {
      type: FiltersPopoverSectionType.SELECT,
      name: 'growth',
      placeholderText: 'Growth',
      options: Object.entries(SCORE_GROWTH_LABELS).map(([value, label]) => ({
        label,
        value
      })),
      value: growthValue,
      onSelect: setGrowthValue,
    },
    {
      type: FiltersPopoverSectionType.SELECT,
      name: 'engagement',
      placeholderText: 'Engagement',
      options: Object.entries(SCORE_ENGAGEMENT_LABELS).map(([value, label]) => ({
        label,
        value
      })),
      value: engagementValue,
      onSelect: setEngagementValue,
    },
    {
      type: FiltersPopoverSectionType.SELECT,
      name: 'boostQuality',
      placeholderText: 'Boost Quality',
      options: Object.entries(SCORE_QUALITY_LABELS).map(([value, label]) => ({
        label,
        value
      })),
      value: boostQualityValue,
      onSelect: setBoostQualityValue,
    },
  ], [
    tagOptions,
    tagsValue,
    tagsOperator,
    languageOptions,
    languagesValue,
    languagesOperator,
    countryOptions,
    countriesValue,
    postCountValue,
    postCountOperatorValue,
    sizeValue,
    growthValue,
    engagementValue,
    boostQualityValue
  ]);

  const {
    search: filterSearch,
    setSearch: setFilterSearch,
    direction,
    setDirection,
    orderBy,
    setOrderBy,
    hasFilter,
    resetAllFilters
  } = useResourceListFilters<string, string>({
    defaultFilter: '',
    defaultSortOrder: Order.DESC,
    popoverSections: filterSections,
    onClearFilters: handleClearFilters,
  });

  const {
    boostAgreements,
    allBoostAgreementIds,
    count,
    noResults,
    hasNextPage,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    setSearch,
  } = useWebApplications(
    boostOfferId || '',
    {
      tagsOperator,
      tags: tagsValue,
      languagesOperator,
      languages: languagesValue,
      countries: countriesValue,
      postCountOperator: postCountOperatorValue,
      postCount: postCountValue,
      sizeScores: sizeValue.map(value => parseInt(value, 10)),
      growthScores: growthValue.map(value => parseInt(value, 10)),
      engagementScores: engagementValue.map(value => parseInt(value, 10)),
      boostQualityScores: boostQualityValue.map(value => parseInt(value, 10)),
      returnAllIds: true,
      perPage: 10,
      direction,
    }
  );
  const showingCount = boostAgreements.length;
  const emptyResult = boostAgreements.length === 0;
  const emptyCardDescription = hasFilter ? 'No results found for current filters' : 'Your offer has no applications yet';
  const emptyCardPrimaryIcon = hasFilter ? undefined : BanknotesIcon;
  const emptyCardPrimaryActionLabel = hasFilter ? 'Reset Filters' : undefined;
  const onPrimaryActionClick = hasFilter ? resetAllFilters : undefined;
  const emptyCardPrimaryActionIcon = hasFilter ? ArrowPathIcon : undefined;

  // Bulk actions
  const {
    selectedIds,
    selectedCount,
    isSelectAllActive,
    toggleSelection,
    selectAll,
    deselectAll,
    addNewIds,
    selectedIdsArray,
    isSelected
  } = useResourceListBulkActions({
    totalCount: count,
    allAvailableIds: allBoostAgreementIds
  });

  const [isConfirmingAcceptAll, setIsConfirmingAcceptAll] = useState(false);
  const [isConfirmingRejectAll, setIsConfirmingRejectAll] = useState(false);

  const { mutateAsync: updateBoostAgreements, isLoading: isBulkUpdating } = useUpdateBoostAgreements({
    boostOfferId: boostOfferId || '',
    onSuccess: () => {
      if (isConfirmingRejectAll) {
        toast.success('Boosts rejected!');
      } else {
        toast.success('Boosts accepted!');
      }

      deselectAll();
    },
    shouldInvalidate: true,
  });

  const handleAcceptAll = () => {
    try {
      updateBoostAgreements({
        boostAgreementIds: selectedIdsArray,
        transitionTo: BoostAgreementStatus.ACTIVE
      });
    } catch (error) {
      toast.error('Something went wrong accepting the applications.');
    } finally {
      setIsConfirmingAcceptAll(false);
      deselectAll();
    }
  };

  const handleRejectAll = (rejectedReason?: string) => {
    try {
      updateBoostAgreements({
        boostAgreementIds: selectedIdsArray,
        transitionTo: BoostAgreementStatus.REJECTED,
        rejectedReason
      });
    } catch (error) {
      toast.error('Something went wrong rejecting the applications.');
    } finally {
      setIsConfirmingRejectAll(false);
      deselectAll();
    }
  };

  const handleSelectAll = () => {
    if (isSelectAllActive) {
      deselectAll();
    } else {
      selectAll(boostAgreements.map(item => item.id));
    }
  };

  // When new applications are loaded, and we're in "Select All" mode, add the new IDs to the selection
  useEffect(() => {
    if (isSelectAllActive) {
      // Get just the new items that were loaded
      const currentIds = new Set(Array.from(selectedIds));
      const newIds = boostAgreements
        .map(item => item.id)
        .filter(id => !currentIds.has(id));

      if (newIds.length > 0) {
        addNewIds(newIds);
      }
    }
  }, [boostAgreements, isSelectAllActive, selectedIds, addNewIds]);

  // Filtering and sorting
  useEffect(() => {
    setSearch(filterSearch);
  }, [filterSearch, setSearch]);

  const handleSetOrderBy = useCallback((newOrderBy: string) => {
    setOrderBy(newOrderBy);
    setDirection(newOrderBy as Order);
  }, [setOrderBy, setDirection]);

  const handleSaveFilters = useCallback(() => {
    // Implementation for saving active filters to the backend
    console.log('Saving filters...');
  }, []);

  const orderByComponent = (
    <Button
      variant="primary-inverse"
      onClick={() => handleSetOrderBy(orderBy === Order.DESC ? Order.ASC : Order.DESC)}
      Icon={orderBy === Order.DESC ? BarsArrowDownIcon : BarsArrowUpIcon}
    >
      {orderBy === Order.DESC ? 'Newest' : 'Oldest'} first
    </Button>
  );

  return (
    <div className="flex flex-col gap-y-6">
      <ResourceListFilters
        search={filterSearch}
        setSearch={setFilterSearch}
        searchPlaceholder="Search applications…"
        orderByComponent={orderByComponent}
        filterSections={filterSections}
        onSaveFilters={handleSaveFilters}
        onClearFilters={handleClearFilters}
      />

      <div className="space-y-3">
        <ResourceListSearchText
          showingCount={showingCount}
          totalCount={count}
          resourceName="application"
          isSelectAllActive={isSelectAllActive}
          onSelectAll={handleSelectAll}
        />

        <ResourceListBulkActions
          selectedCount={selectedCount}
          onDeselect={deselectAll}
        >
          <Button
            size="xs"
            variant="primary-inverse"
            onClick={() => setIsConfirmingRejectAll(true)}
          >
            Reject all
          </Button>

          <Button
            size="xs"
            variant="primary-inverse"
            onClick={() => setIsConfirmingAcceptAll(true)}
          >
            Accept all
          </Button>
        </ResourceListBulkActions>

        <ConfirmBoostAgreementsBulkAcceptModal
          applicationCount={selectedCount}
          isOpen={isConfirmingAcceptAll}
          isWorking={isBulkUpdating}
          onProceed={handleAcceptAll}
          onClose={() => setIsConfirmingAcceptAll(false)}
        />

        <ConfirmBoostAgreementsBulkRejectModal
          applicationCount={selectedCount}
          isOpen={isConfirmingRejectAll}
          isWorking={isBulkUpdating}
          onProceed={handleRejectAll}
          onClose={() => setIsConfirmingRejectAll(false)}
        />

        <LoadingBox isLoading={isLoading} isError={isError}>
          {noResults || emptyResult ? (
            <EmptyCard
              title="No applications"
              description={emptyCardDescription}
              primaryIcon={emptyCardPrimaryIcon}
              primaryActionIcon={emptyCardPrimaryActionIcon}
              primaryActionLabel={emptyCardPrimaryActionLabel}
              onPrimaryActionClick={onPrimaryActionClick}
              isFiltering={hasFilter}
            />
          ) : (
            <>
              <Items className="border border-surface-200">
                <ItemsBody>
                  {boostAgreements.map((boostAgreement) => (
                    <WebApplicationItemV3
                      key={`web-application-item-${boostAgreement.id}`}
                      item={boostAgreement}
                      narrowLayout={narrowLayout}
                      isSelected={isSelected(boostAgreement.id)}
                      onSelect={() => toggleSelection(boostAgreement.id)}
                    />
                  ))}
                </ItemsBody>
              </Items>
              {hasNextPage && (
                <div className="flex justify-start mt-8 w-fit">
                  <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
                </div>
              )}
            </>
          )}
        </LoadingBox>
      </div>
    </div>
  );
};

export default WebApplicationsV3;
