import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

const useUpdateSubscriptionEmail = ({ id }: { id: string | undefined }) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(({ email }: { email: string }) =>
    api
      .patch(`/subscriptions/${id}/email`, {
        publication_id: currentPublicationId,
        subscription: {
          email,
        },
      })
      .then((resp) => {
        queryClient.invalidateQueries(['subscriber', resp.data.subscriber_id, currentPublicationId]);
        toast.success('Email successfully updated');
      })
      .catch((errPayload) => {
        const errorMsg = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(errorMsg);
      })
  );
};

export default useUpdateSubscriptionEmail;
