import { TrashIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Button } from '@/ui/Button';

interface Props {
  onCancel: () => void;
  onSave: () => void;
  onDelete?: () => void;
}

const ConfigureFooter = ({ onCancel, onSave, onDelete }: Props) => {
  return (
    <div
      className={cx(
        'absolute bottom-0 left-0 w-128 flex border-t p-4 bg-white',
        onDelete ? 'justify-between' : 'justify-end'
      )}
    >
      <div>
        {onDelete && (
          <Button variant="flush" size="xs" onClick={onDelete} Icon={TrashIcon}>
            Delete
          </Button>
        )}
      </div>
      <div className="flex gap-x-2">
        <Button variant="primary-inverse" size="xs" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" size="xs" onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ConfigureFooter;
