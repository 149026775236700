import { forwardRef } from 'react';

import { cn } from '@/utils/cn';

import { SIDE_INSERT_PANEL_ID } from '../DreamEditor/constants';

interface Props {
  isInsertPanelOpen: boolean;
}

const InsertPanelPortal = forwardRef<HTMLDivElement, Props>(({ isInsertPanelOpen }: Props, ref) => {
  return (
    <div
      id={SIDE_INSERT_PANEL_ID}
      className={cn(
        'absolute top-0 left-0 bottom-0 min-w-[200px] max-w-[250px] transition-transform duration-250 bg-wb-primary border-r border-solid overflow-y-auto',
        isInsertPanelOpen ? 'translate-x-0' : `-translate-x-[500px]`
      )}
      ref={ref}
    />
  );
});

export default InsertPanelPortal;
