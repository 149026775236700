import { useQuery } from 'react-query';

import { AdNetworkPublicationProfile } from '@/interfaces/ad_network/internal/publication_profile';

import api from '../../../services/swarm';

export default function usePublicationProfile({ publicationId }: { publicationId: string }) {
  return useQuery<AdNetworkPublicationProfile | null>(
    ['ad_network', 'internal', 'publications', publicationId, 'profile'],
    () => api.get(`/ad_network/internal/publications/${publicationId}/profile`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
