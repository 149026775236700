import useCurrentPublicationId from './useCurrentPublicationId';
import usePublication from './usePublication';

const useCurrentPublication = () => {
  const publicationId = useCurrentPublicationId();
  
  return usePublication(publicationId);
};

export default useCurrentPublication;
