import { PropsWithChildren } from 'react';
import { AdjustmentsVerticalIcon, ChartBarIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import IconButton from '@/components/IconHelpers/IconButton';
import Tag from '@/components/Tag';
import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import useResourcePermissions from '@/hooks/useResourcePermissions/useResourcePermissions';
import { AutomationStepStepType } from '@/interfaces/automations/automation_step';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';

import {
  AUTOMATION_NODE_WIDTH_CLASSNAME,
  COLORS_BY_AUTOMATION_STEP_TYPE,
  ICONS_BY_AUTOMATION_STEP_TYPE,
  REFERRING_AUTOMATIONS_COLORS,
  REFERRING_AUTOMATIONS_ICON,
  TRIGGERS_COLORS,
  TRIGGERS_ICON,
} from '../constants';
import { useNewAutoLayoutContext } from '../context/new-layout-context';
import { REFERRING_AUTOMATIONS_NODE_TYPE, TRIGGERS_NODE_TYPE } from '../utils/getNodesAndEdges';

const getColorMapAndIcon = (
  stepType: AutomationStepStepType | typeof TRIGGERS_NODE_TYPE | typeof REFERRING_AUTOMATIONS_NODE_TYPE
) => {
  switch (stepType) {
    case TRIGGERS_NODE_TYPE:
      return { colorMap: TRIGGERS_COLORS, Icon: TRIGGERS_ICON };
    case REFERRING_AUTOMATIONS_NODE_TYPE:
      return { colorMap: REFERRING_AUTOMATIONS_COLORS, Icon: REFERRING_AUTOMATIONS_ICON };
    default:
      return { colorMap: COLORS_BY_AUTOMATION_STEP_TYPE[stepType], Icon: ICONS_BY_AUTOMATION_STEP_TYPE[stepType] };
  }
};

type Props = PropsWithChildren<{
  uniqueIdentifier: string;
  stepType: AutomationStepStepType | typeof TRIGGERS_NODE_TYPE | typeof REFERRING_AUTOMATIONS_NODE_TYPE;
  isBeingConfigured: boolean;
  isActive: boolean;
  shouldDisable: boolean;
  title?: string;
  onClickConfigure?: () => void;
  onClickShowMetrics?: () => void;
}>;

const ContainerNode = ({
  uniqueIdentifier,
  stepType,
  children,
  title,
  onClickConfigure,
  onClickShowMetrics,
  isActive,
  isBeingConfigured,
  shouldDisable,
}: Props) => {
  const { colorMap, Icon } = getColorMapAndIcon(stepType);

  const backgroundColor = stepType && isActive ? colorMap.background : 'bg-gray-300/50';
  const borderColorWhileBeingConfigured = stepType && isBeingConfigured ? colorMap.border : undefined;
  const defaultBorderColor = isActive ? 'border-transparent' : 'border-gray-300';
  const textColor = stepType && isActive ? colorMap.text : 'text-gray-800';
  const iconColor = stepType && isActive ? colorMap.icon : 'text-gray-600';
  const { automation } = useNewAutoLayoutContext();
  const { data: recordPermissions, isSuccess: recordPermissionSuccess } = useResourcePermissions({
    resourceType: 'Automation',
    resourceId: automation.id,
    policyClass: 'Api::V2::AutomationPolicy',
  });

  const canEdit = recordPermissionSuccess && recordPermissions.includes('update');

  return (
    <div
      className={cx(
        'flex flex-col rounded-md relative px-1 pb-1 border transition-all',
        AUTOMATION_NODE_WIDTH_CLASSNAME,
        backgroundColor,
        isActive ? borderColorWhileBeingConfigured : defaultBorderColor,
        shouldDisable && 'opacity-20',
        !isActive && !shouldDisable && 'opacity-70'
      )}
    >
      <div className="flex justify-between items-center h-9 py-3 text-gray-800">
        {(title || Icon) && (
          <div className="flex gap-x-1 items-center">
            {Icon && (
              <div className={cx('w-3 h-3 mt-px', iconColor)}>
                <Icon />
              </div>
            )}
            {title && (
              <Text type="subtitle" size="xs" className={cx(textColor)}>
                {title}
              </Text>
            )}
          </div>
        )}
        <div className="flex gap-x-1">
          {!isActive && <Tag label="Inactive" backgroundColor="bg-gray-100" />}
          {onClickShowMetrics && (
            <Tooltip
              tooltipClass="text-center"
              id={`open-automation-step-${uniqueIdentifier}-configuration`}
              text={canEdit ? 'Toggle metrics' : NO_PERMISSION_MESSAGE}
              showIcon={false}
              autoWidth
            >
              <IconButton onClick={canEdit ? onClickShowMetrics : () => {}}>
                <ChartBarIcon className={canEdit ? 'w-full' : 'cursor-not-allowed text-surface-400 w-full'} />
              </IconButton>
            </Tooltip>
          )}
          {onClickConfigure && (
            <Tooltip
              tooltipClass="text-center"
              id={`view-automation-step-${uniqueIdentifier}-metrics`}
              text={canEdit ? 'Configure step' : NO_PERMISSION_MESSAGE}
              showIcon={false}
              autoWidth
            >
              <IconButton onClick={canEdit ? onClickConfigure : () => {}}>
                <AdjustmentsVerticalIcon
                  className={canEdit ? 'w-full' : 'cursor-not-allowed text-surface-400 w-full'}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-1 w-full">{children}</div>
    </div>
  );
};

export default ContainerNode;
