import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useWorkspaceView from '@/hooks/useAdNetwork/internal/useWorkspaceView';
import useWorkspaceViewDestroy from '@/hooks/useAdNetwork/internal/useWorkspaceViewDestroy';
import useWorkspaceViewDuplicate from '@/hooks/useAdNetwork/internal/useWorkspaceViewDuplicate';
import { AdNetworkWorkspaceView } from '@/interfaces/ad_network/internal/workspace_view';
import { Button } from '@/ui/Button';

import { DescriptionList } from '../../_components/DescriptionList';
import { MultiActionCard } from '../../_components/MultiActionCard';
import { PageHeading } from '../../_components/PageHeading';
import { FilteringContext } from '../../_components/PublicationFilters/filtering-context';
import Filters from '../../_components/PublicationFilters/Filters';
import useConditionManager from '../../_components/PublicationFilters/useConditionManager';
import useConfirmDelete from '../../_hooks/useConfirmDelete';

interface Props {
  workspaceView: AdNetworkWorkspaceView;
}

const WorkspaceView = ({ workspaceView }: Props) => {
  const deleteMutation = useWorkspaceViewDestroy({ id: workspaceView.id });
  const warning = 'Once you delete a view, there is no going back. Please be certain.';
  const { ConfirmDeleteModal, modalProps, handleDelete, isDeleting } = useConfirmDelete({
    deleteMutation,
    warning,
    confirmWith: workspaceView.name,
  });

  const { mutateAsync: handleDuplicate } = useWorkspaceViewDuplicate({ id: workspaceView.id });

  const navigate = useNavigate();

  const willOverwrite = !!localStorage.getItem('ad_network_publication_filters');

  const handleUseView = () => {
    localStorage.setItem('ad_network_publication_filters', JSON.stringify(workspaceView.conditions));

    navigate('../../publications');
  };

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Views</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">{workspaceView.name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button
            onClick={handleUseView}
            size="xs"
            variant="primary-inverse"
            confirm={willOverwrite ? 'Are you sure? This will overwrite your current filters.' : undefined}
          >
            Use this view
          </Button>
        </PageHeading.Side>
      </PageHeading>

      <div className="divide-y divide-gray-100">
        <DescriptionList>
          <DescriptionList.Item term="Name">{workspaceView.name}</DescriptionList.Item>
          <DescriptionList.Item term="Description">{workspaceView.description}</DescriptionList.Item>
        </DescriptionList>
        <div className="p-4">
          <Filters />
        </div>
        <MultiActionCard>
          <MultiActionCard.Item
            title="Edit this view"
            description="Add or remove conditions from this view."
            onClick={() => navigate('edit')}
          />
          <MultiActionCard.Item
            title="Duplicate this view"
            description="Create a new view with the same conditions."
            onClick={handleDuplicate}
          />
          <MultiActionCard.Item
            title="Delete this view"
            description={warning}
            loading={isDeleting}
            onClick={() => handleDelete()}
            danger
          />
        </MultiActionCard>
      </div>
      <ConfirmDeleteModal {...modalProps} />
    </>
  );
};

const ContextLoader = ({ workspaceView }: { workspaceView: AdNetworkWorkspaceView }) => {
  const conditionsManager = useConditionManager({ initialState: workspaceView.conditions, readonly: true });

  useEffect(() => {
    conditionsManager.clearConditions();
  }, [conditionsManager, workspaceView.conditions]);

  return (
    <FilteringContext.Provider value={conditionsManager}>
      <WorkspaceView workspaceView={workspaceView} />
    </FilteringContext.Provider>
  );
};

export default function Loader() {
  const { workspace_view_id: id } = useParams<'workspace_view_id'>() as { workspace_view_id: string };
  const { isSuccess, isError, isLoading, data: workspaceView } = useWorkspaceView({ id });

  if (!isSuccess || isLoading || isError) return null;

  return <ContextLoader workspaceView={workspaceView} />;
}
