import { ElementType } from 'react';

import { Typography, TypographyStack } from '@/components/Typography';

import IconWrapper from '../IconHelpers/IconWrapper';

interface Props {
  title: string;
  description: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'warning' | 'danger' | 'success' | 'info';
  Icon: ElementType;
}

const FeatureItem = ({ title, description, Icon, variant = 'secondary' }: Props) => {
  return (
    <li className="flex space-x-4">
      <div>
        <IconWrapper Icon={Icon} variant={variant} />
      </div>

      <TypographyStack gap="1">
        <Typography weight="semibold" size="base" as="h4">
          {title}
        </Typography>
        <Typography weight="normal" size="sm" as="p" colorWeight="700">
          {description}
        </Typography>
      </TypographyStack>
    </li>
  );
};

export default FeatureItem;
