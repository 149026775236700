import LoadingBox from '@/components/LoadingBox';
import { useCurrentUser } from '@/context/current-user-context';
import { useOrganization, useUser } from '@/hooks';
import { useCurrentOrganizationId } from '@/hooks/useOrganization';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { Card } from '@/ui/Card';

import PageContainer from '../../_components/PageContainer';

import AddressForm from './AddressForm';
import DeleteWorkspaceForm from './DeleteWorkspaceForm';
import OverviewForm from './OverviewForm';
import PublicationsList from './PublicationsList';

const WorkspaceOverview: React.FC = () => {
  const currentOrganizationId = useCurrentOrganizationId();
  const currentPublicationId = useCurrentPublicationId();
  const {
    data: organizationData,
    isLoading: isLoadingOrgnanization,
    isError: isErrorOrgnanization,
  } = useOrganization(currentOrganizationId, currentPublicationId);
  const { organization } = organizationData || {};
  const { currentUser } = useCurrentUser();
  const { data: user } = useUser(currentPublicationId, currentUser?.id);
  const { data: settings } = usePublicationSettings(currentPublicationId);

  return (
    <LoadingBox isLoading={isLoadingOrgnanization} isError={isErrorOrgnanization}>
      {organization ? (
        <PageContainer key={`${organization.id}-${currentPublicationId}`}>
          <Card id="overview" showCopyIdButton>
            <OverviewForm organization={organization} publicationId={currentPublicationId} />
          </Card>
          <Card id="address" showCopyIdButton>
            <AddressForm organization={organization} publicationId={currentPublicationId} />
          </Card>
          <Card id="publications" showCopyIdButton>
            <PublicationsList organization={organization} publicationId={currentPublicationId} />
          </Card>
          {(settings?.system_admin || user?.owns_organization) && (
            <Card id="delete" showCopyIdButton>
              <DeleteWorkspaceForm organization={organization} publicationId={currentPublicationId} />
            </Card>
          )}
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default WorkspaceOverview;
