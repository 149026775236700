/**
 * Parses a CSS value into its numeric value and unit.
 *
 * @param value - The CSS value to parse. Can be a string, number, or any other type.
 * @param unit - An optional unit to use if not present in the value.
 * @returns An object containing the parsed numeric value and unit.
 *
 * This function handles various input types:
 * - For strings, it attempts to infer the unit if not provided.
 * - For numbers, it uses the provided unit or defaults to 'px'.
 * - For other types, it converts to string and attempts to parse.
 *
 * The function uses regex to extract units from string values,
 * and falls back to 'px' if no unit is found or provided.
 */
export const parseCssValue = (value: unknown, unit?: string): { value: number; unit: string } => {
  let valArr: string[];
  let inferedUnit: string | undefined = unit;

  if (!inferedUnit && typeof value === 'string') {
    // then we infer the unit from value.
    // match regex for any numbers followed by any non-alphanumeric characters
    const regex = /^\d*\.?\d+([a-zA-Z%]*)$/;
    const match = value.toString().match(regex);
    if (match) {
      [, inferedUnit] = match;
    }
  }

  if (typeof value === 'string') {
    valArr = value.split(inferedUnit || '');
  } else if (typeof value === 'number') {
    valArr = value.toString().split(inferedUnit || '');
  } else {
    valArr = String(value).split(inferedUnit || '');
  }

  return {
    value: parseFloat(valArr[0]) || 0,
    unit: valArr[1] || inferedUnit || 'px',
  };
};
