import { NavLink } from '@/interfaces/nav_link';

import LinkActions from '../Helpers/LinkActions';
import NonSortablePageWrapper from '../Helpers/NonSortablePageWrapper';
import PageHelperTitle from '../Helpers/PageTitleHelper';

interface Props {
  link: NavLink;
}

const UnlinkedPages = ({ link }: Props) => {
  return (
    <NonSortablePageWrapper>
      <PageHelperTitle
        text={link.cta_text}
        isExternalLink={link.external_link}
        colorWeight="400"
        iconClass="!text-surface-400"
      />
      <LinkActions navLink={link} iconOrientation="vertical" />
    </NonSortablePageWrapper>
  );
};

export default UnlinkedPages;
