import { forwardRef } from 'react';
import { Icon as PhosphorIcon } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';

type Props = {
  Icon: PhosphorIcon;
  text: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isActive?: boolean;
};

export const ActionMenu = forwardRef<HTMLButtonElement, Props>(({ Icon, text, onClick, isActive = false }, ref) => (
  <button
    type="button"
    className={`group flex items-center gap-1.5 cursor-pointer hover:text-wb-primary ${
      isActive ? 'text-wb-primary' : 'text-wb-secondary'
    }`}
    onClick={onClick}
    ref={ref}
  >
    <div className="flex items-center justify-center w-5 h-5 bg-wb-highlight rounded-md">
      <Icon size={16} weight="bold" />
    </div>
    <Text variant="secondary" size="2xs" weight="semibold" className="text-inherit">
      {text}
    </Text>
  </button>
));
