import { useOutletContext } from 'react-router-dom';

import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

type ContextType = {
  search: string | undefined;
  setSearch: (val: string | undefined) => void;
  eventType: ActivityFeedEventType | undefined;
  setEventType: (val: ActivityFeedEventType | undefined) => void;
};

export const useSearchContext = () => useOutletContext<ContextType>();
