import { memo, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CaretRight, DotsThree, File, House, Icon as PhosphorIcon, Plus } from '@phosphor-icons/react';
import cx from 'classnames';

import { useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';
import { useCreatePage } from '@/hooks/usePages';
import usePageVersion from '@/hooks/usePageVersion/usePageVersion';
import { PageRoute } from '@/interfaces/dream_builder/page_route';

import { cn } from '../../_utils/cn';
import { PageOption } from '../Main/PageOption';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

import { ContentTreeItemDndWrapper } from './ContentTreeItemDndWrapper';
import { useContentTreeContext } from './context';

interface Props {
  route: PageRoute;
  slug: string;
  parentPath: string[];
  asListItem?: boolean;
}

export const ContentTreeItem = memo(({ route, parentPath, slug, asListItem = false }: Props) => {
  const { isAllowDnD, pageLinkPrefix, isShowOptions, isShowAddPage, isLinkToPage, rightComponent, onClick } =
    useContentTreeContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { pageId } = useParams() as unknown as { pageId: string };

  const createPage = useCreatePage({
    routesType: 'custom',
  });
  const createPageMutation = async () => {
    await createPage.mutateAsync({ parentPath: [...parentPath, slug] });
  };

  const { previewSiteVersion } = useWebsiteContext();
  const pageRouteGetter = useWebsitePageRouteGetter();
  const isHome = parentPath?.length === 0 && !slug;
  const isCurrentPath = pageId === route.page_id;
  const isDefaultPage = pageRouteGetter?.isDefaultPage(route.page_id);
  const page = pageRouteGetter?.getPageFromID(route.page_id);
  const { data: previewPageVersion } = usePageVersion({
    pageId: route.page_id as string,
    pageVersionId: route.page_version_id as string,
    enabled: !!route.page_version_id,
  });

  const hasChildren = (route?.children_keys?.length || 0) > 0;
  // Auto-open the tree if the current page is inside this page item
  const openedPageIDPath = pageRouteGetter?.getPageRouteFromID(pageId)?.path?.join('/');
  const currentPath = [...parentPath, slug]?.join('/');
  const isOpenedPageInsideThisPageItem =
    (openedPageIDPath?.length || 0) > currentPath?.length && openedPageIDPath?.startsWith(currentPath);
  useEffect(() => {
    if (isOpenedPageInsideThisPageItem) {
      setIsOpen(true);
    }
  }, [isOpenedPageInsideThisPageItem]);

  const pageVersion = previewSiteVersion?.id ? previewPageVersion : page?.draft_page_version;

  const Icon = useMemo((): PhosphorIcon => (isHome ? House : File), [isHome]);
  const Wrapper = isLinkToPage ? Link : 'div';
  const content = (
    <Wrapper
      to={`${pageLinkPrefix}/${route.page_id}`}
      draggable={false}
      className={cx(
        'px-1.5 py-2 flex rounded-lg flex-row w-full items-center gap-2 group',
        isCurrentPath || isSettingsOpen ? 'bg-wb-secondary' : 'hover:bg-wb-secondary',
        isAllowDnD ? 'cursor-grab' : 'cursor-pointer'
      )}
      onClick={onClick ? () => onClick(route.page_id) : undefined}
    >
      <div
        className="h-4 w-4 flex items-center justify-center cursor-pointer"
        role="none"
        onClick={
          !asListItem && hasChildren && !isHome
            ? (e) => {
                e.preventDefault(); // this line prevents changing to the URL of the link href
                e.stopPropagation(); // this line prevents the link click from bubbling
                setIsOpen((o) => !o);
              }
            : undefined
        }
      >
        {!asListItem && hasChildren && !isHome && (
          <CaretRight
            size={10}
            weight="fill"
            className={cx('my-auto text-wb-secondary transition-all', isOpen ? 'rotate-90' : '')}
          />
        )}
      </div>
      <Icon weight="bold" size={16} className="my-auto text-wb-secondary" />

      <Tooltip center={pageVersion?.name} className="flex-1 flex items-center truncate gap-2 min-w-[0px]">
        <Text as="p" size="2xs" weight="medium" className="truncate">
          {pageVersion?.name}
        </Text>
        {asListItem && (
          <Text as="p" size="xs" weight="medium" variant="secondary">
            /{currentPath}
          </Text>
        )}
      </Tooltip>
      <div
        className={cn('group-hover:opacity-100 flex items-center gap-1', isSettingsOpen ? 'opacity-100' : 'opacity-0')}
      >
        {isShowOptions && (
          <Tooltip center="Page Options" className="flex-1  flex items-center cursor-pointer">
            <PageOption
              pageId={route.page_id}
              align="start"
              open={isSettingsOpen}
              onOpenChange={setIsSettingsOpen}
              isDefaultPage={isDefaultPage || false}
              isHome={isHome}
            >
              <div className="hover:bg-wb-highlight rounded-full p-0.5 cursor-pointer">
                <DotsThree weight="bold" className="my-auto text-wb-secondary h-4 w-4" />
              </div>
            </PageOption>
          </Tooltip>
        )}

        {isShowAddPage && (
          <Tooltip center="Add Page Inside" className="flex-1 flex items-center cursor-pointer">
            <button type="button" className="hover:bg-wb-highlight rounded-full p-0.5" onClick={createPageMutation}>
              <Plus weight="bold" className="my-auto text-wb-secondary h-4 w-4" />
            </button>
          </Tooltip>
        )}
      </div>
      {rightComponent && rightComponent(route.page_id)}
    </Wrapper>
  );

  return (
    <div className={cx('flex flex-col', asListItem || parentPath?.length <= 0 ? '' : 'ml-4')}>
      <ContentTreeItemDndWrapper path={[...parentPath, slug]} id={route.page_id} setIsOpen={setIsOpen}>
        {content}
      </ContentTreeItemDndWrapper>

      {(isHome || isOpen) && !asListItem && route?.children && route?.children_keys && (
        <div className="flex flex-col">
          {route.children_keys?.map(
            (childSlug) =>
              route.children?.[childSlug] && (
                <ContentTreeItem
                  key={route.children[childSlug]?.page_id}
                  route={route.children[childSlug]}
                  parentPath={slug ? [...parentPath, slug] : [...parentPath]}
                  slug={childSlug}
                />
              )
          )}
        </div>
      )}
    </div>
  );
});
