import cx from 'classnames';

import { BadgeColor, BadgeProps } from './types';

const Badge = ({ children, color }: BadgeProps) => {
  const colorClass = {
    [BadgeColor.GRAY]: 'bg-gray-100 text-gray-600',
    [BadgeColor.GREEN]: 'bg-green-100 text-green-700',
    [BadgeColor.RED]: 'bg-red-100 text-red-700',
    [BadgeColor.YELLOW]: 'bg-yellow-100 text-yellow-800',
    [BadgeColor.BLUE]: 'bg-blue-100 text-blue-700',
  }[color || BadgeColor.GRAY];

  const svgColorClass = {
    [BadgeColor.GRAY]: 'fill-gray-400',
    [BadgeColor.GREEN]: 'fill-green-500',
    [BadgeColor.RED]: 'fill-red-500',
    [BadgeColor.YELLOW]: 'fill-yellow-500',
    [BadgeColor.BLUE]: 'fill-blue-500',
  }[color || BadgeColor.GRAY];

  return (
    <span
      className={cx(
        'capitalize inline-flex items-center gap-x-1.5 rounded-full px-1.5 py-0.5 text-xs font-medium',
        colorClass
      )}
    >
      <svg className={cx('h-1.5 w-1.5', svgColorClass)} viewBox="0 0 6 6" aria-hidden="true">
        <circle cx={3} cy={3} r={3} />
      </svg>
      {children}
    </span>
  );
};

export default Badge;
