import { FC, useMemo, useState } from 'react';
import { ContainerElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, useReactNodeView } from '@tiptap/react';

import { cn } from '@/utils/cn';

import { Text } from '../../../../UI/Text';
import { getDataAttributes } from '../../utils';

export const ContainerView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  const { onDragStart } = useReactNodeView();

  const [isDragOver, setIsDragOver] = useState(false);

  const hasContent = useMemo(() => node.content.childCount > 0, [node.content]);

  return (
    <ContainerElement
      element={{
        type: 'container',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [],
      }}
      attributes={{
        ...getDataAttributes(HTMLAttributes),
        'data-type': node.type.name,
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      <NodeViewContent data-node-view-display-contents />

      {!hasContent && (
        <div
          className={cn(
            'w-full h-full flex justify-center items-center cursor-pointer select-none relative p-12 bg-opacity-50',
            {
              'bg-blue-50': isDragOver,
            }
          )}
          contentEditable={false}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragOver(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setIsDragOver(false);
          }}
        >
          <div className="flex items-center justify-center z-[2]">
            <div className="flex flex-col items-center justify-center gap-2">
              <Text size="md" weight="semibold">
                Drag and drop elements to start
              </Text>
            </div>
          </div>
        </div>
      )}
    </ContainerElement>
  );
};
