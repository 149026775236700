export enum ReferralProgramLayout {
  DEFAULT = 'default',
  UPCOMING_MILESTONE_ONLY = 'upcoming_milestone_only',
  REWARDS_ONLY = 'rewards_only',
  UPCOMING_MILESTONES_AND_REWARDS = 'upcoming_milestones_and_rewards',
}
export interface ReferralProgram {
  id: string;
  publication_id: string;
  email_description: string;
  disabled_at: Date | null;
  deleted_at: Date;
  created_at: Date;
  block_layout: ReferralProgramLayout | null;
}
