import styled from 'styled-components';

import { LoadingSpinner } from '../../../components/LoadingSpinner';

interface Props {
  sidePanelChildren: React.ReactNode;
  children: React.ReactNode;
  isLoading?: boolean;
  actionChildren?: React.ReactNode;
  bgColor?: string;
}

const StyledWrapper = styled.div<any>`
  background-color: ${(props) => props?.bgColor};
`;

const PageLayout = ({ sidePanelChildren, children, isLoading, actionChildren, bgColor = '#ffffff' }: Props) => {
  const renderLoader = () => {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <LoadingSpinner />
      </div>
    );
  };

  return (
    <div className="flex flex-row w-full h-full">
      {/* Side Panel */}
      <div className="w-1/4 bg-white min-h-screen border-r border-1 border-gray-200">
        <div
          className="text-xl font-light text-center"
          style={{
            backgroundImage: 'linear-gradient(rgba(255,255,255,1) 75%, rgba(255,255,255,0))',
          }}
        >
          {!isLoading && sidePanelChildren}
        </div>
      </div>

      {/* Main Panel */}
      <StyledWrapper bgColor={bgColor} className="fixed top-0 right-0 h-full bg-white w-3/4 overflow-auto">
        <div className="w-full h-full flex flex-col overflow-auto">
          <div className="flex-grow bg-white">{isLoading ? renderLoader() : children}</div>
        </div>

        {/* Action Panel */}
        <div className="absolute bottom-0 left-0 h-20 w-full bg-white border border-t border-gray-200 flex justify-between p-5 items-center">
          {!isLoading && actionChildren}
        </div>
      </StyledWrapper>
    </div>
  );
};

export default PageLayout;
