import { FC, ReactNode } from 'react';
import cx from 'classnames';

import Tooltip from '@/components/Tooltip';

interface Props {
  name: string;
  checked: boolean;
  onChange: (name: string, checked: boolean) => void;
  className?: string;
  prefixLabelText?: string | ReactNode;
  labelText?: string;
  helperText?: string | ReactNode;
  disabled?: boolean;
  disabledText?: string;
  size?: 'large' | 'small';
  fullWidth?: boolean;
  showIcons?: boolean;
  variant?: 'primary' | 'secondary' | 'notification';
}

const Switch: FC<Props> = (props: Props) => {
  const {
    className,
    prefixLabelText,
    labelText,
    name,
    checked,
    onChange,
    helperText,
    disabled = false,
    size = 'large',
    fullWidth = false,
    disabledText = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    variant = 'primary', // keeping this in for backwards compatibility
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showIcons = false, // keeping this in for backwards compatibility
  } = props;
  const tooltipId = `switch-${name}-tooltip`;

  const handleChange = () => {
    if (onChange) {
      onChange(name, !checked);
    }
  };

  return (
    <div className={cx('inline-flex flex-col justify-center', className, fullWidth && 'w-full')}>
      <div className={cx('flex', fullWidth && 'w-full justify-between')}>
        {(!!prefixLabelText || !!labelText) && (
          <div className="mr-3">
            <label
              htmlFor={name}
              className={cx(
                'block font-medium',
                (!checked || disabled) && 'text-gray-500',
                checked && !disabled && 'text-gray-700',
                size === 'small' && 'text-sm',
                size === 'large' && 'text-md'
              )}
            >
              {prefixLabelText || labelText}
            </label>
            {helperText && (
              <div>
                <p className={cx(size === 'small' && 'text-sm', size === 'large' && 'text-md', 'text-gray-500')}>
                  {helperText}
                </p>
              </div>
            )}
          </div>
        )}
        <Tooltip
          tooltipClass="text-center"
          id={tooltipId}
          text={disabledText}
          showIcon={false}
          autoWidth
          isEnabled={disabled}
        >
          <div className="flex items-center">
            <button
              id={name}
              type="button"
              onClick={handleChange}
              disabled={disabled}
              className={cx(
                'relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full ease-in-out duration-200 focus:outline-none focus:ring-0',
                !disabled &&
                  'focus:shadow-[0px_0px_0px_2px_#FBCFE8] cursor-pointer transition-colors bg-gray-200 hover:bg-gray-300',
                checked && !disabled && `bg-pink-700 hover:bg-pink-600`,
                disabled && 'bg-gray-100 cursor-not-allowed ',
                size === 'large' ? 'h-6 w-11' : 'h-5 w-9'
              )}
            >
              <span
                className={cx(
                  'pointer-events-none relative inline-block rounded-full bg-white shadow-sm transform ring-0 transition ease-in-out duration-200',
                  // eslint-disable-next-line no-nested-ternary
                  checked ? (size === 'large' ? 'translate-x-5' : 'translate-x-4') : 'translate-x-0',
                  size === 'large' ? 'h-5 w-5' : 'h-4 w-4'
                )}
              >
                <span
                  className={cx(
                    'span ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                    checked ? 'opacity-0' : 'opacity-100'
                  )}
                />
                <span
                  className={cx(
                    'ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                    checked ? 'opacity-100' : 'opacity-0'
                  )}
                />
              </span>
              {name && <input type="hidden" name={name} value={`${checked}`} />}
            </button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default Switch;
