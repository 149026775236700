import React, { useMemo } from 'react';
import Tippy from '@tippyjs/react/headless';
import { findParentNodeClosestToPos } from '@tiptap/react';
import { Placement } from 'tippy.js';

import { MenuProps } from '../../../components/menus/types';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';

export interface TippyProps {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
}

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
};

interface HtmlSnippetMenuProps extends MenuProps {
  children?: React.ReactElement;
  getPos: Function;
  showHtmlPreview: Function;
  isHtmlPreviewActive: boolean;
}

export const HtmlSnippetMenu = ({
  children,
  editor,
  getPos,
  showHtmlPreview,
  isHtmlPreviewActive,
}: HtmlSnippetMenuProps): JSX.Element => {
  const { selection } = editor.state;

  const shouldShow = useMemo(() => {
    const parentNodeDOM = editor.view.nodeDOM(getPos());

    const selectedParentNode = findParentNodeClosestToPos(editor.state.selection.$head, (node) => {
      return node.type.name === 'htmlSnippet';
    });

    if (!selectedParentNode) {
      return false;
    }

    const selectedParentNodeDOM = editor.view.nodeDOM(selectedParentNode.pos);

    return parentNodeDOM === selectedParentNodeDOM && !!selection;
  }, [editor, selection, getPos]);

  return (
    <Tippy
      offset={[0, 8]}
      interactive
      visible={shouldShow}
      popperOptions={{
        modifiers: [{ name: 'flip', enabled: false }],
      }}
      render={(attrs: TippyProps) => (
        <div
          tabIndex={-1}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attrs}
          contentEditable={false}
        >
          <Toolbar shouldShowContent={shouldShow}>
            <Tooltip title="Show HTML code">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $isToggleButton
                $active={!isHtmlPreviewActive}
                $muted
                onClick={() => {
                  showHtmlPreview(false);
                }}
              >
                HTML code
              </Button>
            </Tooltip>
            <Tooltip title="Show preview of HTML">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $isToggleButton
                $active={isHtmlPreviewActive}
                $muted
                onClick={() => {
                  showHtmlPreview(true);
                }}
              >
                Preview
              </Button>
            </Tooltip>
            <Divider />
            <Tooltip title="Delete HTML snippet">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $isIconButton
                $leftSlot={<Icon name="Trash" />}
                onClick={() => {
                  editor.chain().focus().deleteNode('htmlSnippet').run();
                }}
              />
            </Tooltip>
          </Toolbar>
        </div>
      )}
    >
      {children}
    </Tippy>
  );
};
