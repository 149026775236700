import { AxiosResponse } from 'axios';

import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { Asset, AssetSource } from '@/interfaces/asset';
import { ExternalRssFeedApiResponse } from '@/interfaces/external_rss_feed';
import { Pagination } from '@/interfaces/general';

import api from '../../../services/swarm';

export class API {
  public static uploadPublicationAsset = async ({
    publicationId,
    file,
    title,
    alt,
    sourceDisplay,
    sourceLink,
  }: {
    publicationId: string;
    file: File;
    title?: string;
    alt?: string;
    sourceDisplay?: string;
    sourceLink?: string;
  }) => {
    const formData = new FormData();
    formData.append('asset[file]', file);
    formData.append('asset[title]', title ?? '');
    formData.append('asset[alt]', alt ?? '');
    formData.append('asset[source_display]', sourceDisplay ?? '');
    formData.append('asset[source_link]', sourceLink ?? '');

    const res = await api.post<Asset>(`/publications/${publicationId}/assets`, formData, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });

    if (res.status > 299) {
      throw new Error(`Error uploading file: ${res.statusText}`);
    }

    return res;
  };

  public static uploadPublicationAssetFromUrl = async ({
    publicationId,
    url,
    title,
    alt,
    sourceDisplay,
    sourceLink,
  }: {
    publicationId: string;
    url: string;
    title?: string;
    alt?: string;
    sourceDisplay?: string;
    sourceLink?: string;
  }) => {
    const res = await api.post<Asset>(`/publications/${publicationId}/assets`, {
      asset: {
        remote_url: url,
        title: title ?? '',
        alt: alt ?? '',
        source_display: sourceDisplay ?? '',
        source_link: sourceLink ?? '',
      },
      headers: {
        accept: 'application/json',
      },
    });

    if (res.status > 299) {
      throw new Error(`Error uploading file: ${res.statusText}`);
    }

    return res;
  };

  /**
   * Update an asset
   * @param assetId The asset id
   * @param publicationId The publication id
   * @param options The asset options
   * @returns The updated asset
   */
  public static updateAsset = async (
    assetId: string,
    publicationId: string,
    {
      title,
      alt,
      sourceDisplay,
      sourceLink,
      file,
    }: {
      title?: string;
      alt?: string;
      sourceDisplay?: string;
      sourceLink?: string;
      file?: File;
    }
  ) => {
    const formData = new FormData();
    if (file) {
      formData.append('asset[file]', file);
      formData.append('asset[source]', 'file');
    }
    formData.append('asset[title]', title ?? '');
    formData.append('asset[alt]', alt ?? '');
    formData.append('asset[source_display]', sourceDisplay ?? '');
    formData.append('asset[source_link]', sourceLink ?? '');

    const res = await api.patch<Asset>(`/publications/${publicationId}/assets/${assetId}`, formData, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });

    if (res.status > 299) {
      throw new Error(`Error updating asset: ${res.statusText}`);
    }

    return res;
  };

  public static uploadPublicationExternalAsset = async ({
    publicationId,
    external_url,
    external_id,
    source,
    title,
    alt,
    sourceDisplay,
    sourceLink,
  }: {
    publicationId: string;
    external_url: string;
    external_id: string;
    source: AssetSource;
    title?: string;
    alt?: string;
    sourceDisplay?: string;
    sourceLink?: string;
  }) => {
    const res = await api.post<Asset>(`/publications/${publicationId}/assets`, {
      asset: {
        external_url,
        external_id,
        source,
        title: title ?? '',
        alt: alt ?? '',
        source_display: sourceDisplay ?? '',
        source_link: sourceLink ?? '',
      },
      headers: {
        accept: 'application/json',
      },
    });

    if (res.status > 299) {
      throw new Error(`Error creating asset: ${res.statusText}`);
    }

    return res;
  };

  public static getUploadPublicationDownloadablePresignedUrl = ({
    publicationId,
    userId,
    file,
  }: {
    publicationId: string;
    userId: string;
    file: File;
  }) => {
    const params = {
      file_name: file.name,
      publication_id: publicationId,
      user_id: userId,
    };

    return api.post('editor/downloadables', params, {
      headers: {
        accept: 'application/json',
      },
    });
  };

  public static markDownloadableAsUploaded = ({
    downloadableId,
    publicationId,
  }: {
    downloadableId: string;
    publicationId: string;
  }) => {
    return api.post(
      `editor/downloadables/${downloadableId}/mark_as_uploaded`,
      {
        downloadable_id: downloadableId,
        publication_id: publicationId,
      },
      {
        headers: {
          accept: 'application/json',
        },
      }
    );
  };

  public static getDownloadableData = ({
    downloadableId,
    publicationId,
  }: {
    downloadableId: string;
    publicationId: string;
  }) => {
    return api.get(`editor/downloadables/${downloadableId}`, {
      params: {
        publication_id: publicationId,
      },
    });
  };

  public static uploadFileToPresignedUrl = ({ file, presignedUrl }: { file: File; presignedUrl: string }) => {
    const headers = {
      'Content-Type': file.type,
    };

    return fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers,
    });
  };

  public static getAsset = async ({ assetId, publicationId }: { assetId: string; publicationId: string }) => {
    const res = await api.get<Asset>(`/publications/${publicationId}/assets/${assetId}`);

    if (res.status > 299) {
      throw new Error(`Error getting asset: ${res.statusText}`);
    }

    return res;
  };

  public static deleteAsset = async ({ assetId, publicationId }: { assetId: string; publicationId: string }) => {
    const res = await api.delete<{ id: string }>(`/publications/${publicationId}/assets/${assetId}`);

    if (res.status > 299) {
      throw new Error(`Error deleting asset: ${res.statusText}`);
    }

    return res;
  };

  public static getEmbedData = async ({
    service,
    url,
    publicationId,
  }: {
    service: 'tiktok' | 'twitter' | 'youtube' | 'instagram' | 'generic' | 'bluesky';
    url: string;
    publicationId: string;
  }) => {
    const res = await api.get(`/editor/fetch_embed_data`, {
      params: {
        service,
        url,
        publication_id: publicationId,
      },
    });

    return res;
  };

  public static getPublication = async ({ publicationId }: { publicationId: string }) => {
    const res = await api.get(`/publications/${publicationId}`);

    return res;
  };

  public static getPresetButtons = async ({ publicationId, postId }: { publicationId: string; postId: string }) => {
    const res = await api.get(`/options/preset_buttons`, {
      params: {
        publication_id: publicationId,
        post_id: postId,
      },
    });

    return res;
  };

  public static getRecommendationsOptions = async ({
    publicationId,
    q = '',
    page = 1,
  }: {
    publicationId: string;
    q?: string;
    page?: number;
  }) => {
    const res = await api.get(`/options/recommendations`, {
      params: {
        publication_id: publicationId,
        q,
        page,
        per_page: 10,
      },
    });

    return res;
  };

  public static getRecommendation = async ({
    publicationId,
    recommendationId,
  }: {
    publicationId: string;
    recommendationId: string;
  }) => {
    const res = await api.get(`/recommendations/${recommendationId}`, {
      params: {
        publication_id: publicationId,
      },
    });

    return res;
  };

  public static getReferralProgram = async ({ publicationId }: { publicationId: string }) => {
    const res = await api.get('/referral_program', {
      params: {
        publication_id: publicationId,
      },
    });

    return res;
  };

  public static getReferralProgramMilestones = async ({
    publicationId,
    perPage = 3,
  }: {
    publicationId: string;
    perPage?: number;
  }) => {
    const res = await api.get('/referral_program/milestones', {
      params: {
        publication_id: publicationId,
        per_page: perPage,
      },
    });

    return res;
  };

  public static getAdvertisementOpportunitiesOptions = async ({
    publicationId,
    q = '',
    page = 1,
  }: {
    publicationId: string;
    q?: string;
    page?: number;
  }) => {
    const res = await api.get<{
      options: Array<{
        id: string;
        advertiser_name: string;
        selected_date: string;
      }>;
      pagination: Pagination;
    }>(`/options/advertisement_opportunities`, {
      params: {
        publication_id: publicationId,
        q,
        page,
        per_page: 10,
      },
    });

    return res;
  };

  public static getAdvertisementOpportunity = async ({
    publicationId,
    opportunityId,
  }: {
    publicationId: string;
    opportunityId: string;
  }) => {
    const res = await api.get<AdNetworkOpportunity>(`/ad_network/publisher/opportunities/${opportunityId}`, {
      params: {
        publication_id: publicationId,
      },
    });

    return res;
  };

  public static getTiptapCollabToken = async ({
    publicationId,
    resourceType,
    resourceId,
  }: {
    publicationId: string;
    resourceType: string;
    resourceId: string;
  }) => {
    const res = await api.post(`/tiptap_collab/tokens`, {
      publication_id: publicationId,
      resource_type: resourceType,
      resource_id: resourceId,
    });

    return res;
  };

  public static getBoostedRecommendation = async ({
    publicationId,
    recommendationId,
  }: {
    publicationId: string;
    recommendationId: string;
  }) => {
    const res = await api.get(`boosts/monetize/recommendations/${recommendationId}`, {
      params: {
        publication_id: publicationId,
      },
    });

    return res;
  };

  public static getBoostedRecommendationOptions = async ({
    publicationId,
    q = '',
    page = 1,
  }: {
    publicationId: string;
    q?: string;
    page?: number;
  }) => {
    const res = await api.get(`/options/boosted_recommendations`, {
      params: {
        publication_id: publicationId,
        q,
        page,
        per_page: 10,
      },
    });

    return res;
  };

  public static getExternalRssFeeds = async ({publicationId}: {publicationId: string}) => {
    const res = await api.get(`/publications/${publicationId}/external_rss_feeds`);

    return res;
  }

  public static getExternalRssFeed = async ({publicationId, externalRssFeedId}: {publicationId: string, externalRssFeedId: string}) => {
    const res: AxiosResponse<ExternalRssFeedApiResponse,any> = await api.get(`/publications/${publicationId}/external_rss_feeds/${externalRssFeedId}`);

    return res;
  }
}

export default API;
