import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { SliderSettings } from '../SliderSettings';

const ColumnSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['column']}>
      <SectionRenderer title="Layout">
        <SliderSettings editor={editor} activeNodeResult={activeNodeResult} property="width" title="Width" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};

export { ColumnSettings };
