import { useQuery } from 'react-query';

import { AdNetworkIndustryGroup } from '@/interfaces/ad_network/internal/industry_group';
import api from '@/services/swarm';

interface ApiResponse {
  industry_groups: AdNetworkIndustryGroup[];
}

export default function useIndustryGroups() {
  const fetchIndustryGroups = () => api.get(`/ad_network/internal/industry_groups`).then((res) => res.data);

  return useQuery<ApiResponse>(['ad_network', 'internal', 'industry_groups'], fetchIndustryGroups, {
    keepPreviousData: true,
  });
}
