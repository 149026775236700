import { createContext, useContext } from 'react';

import { Order } from '@/interfaces/general';
import {
  OrderBy,
  PublicationSubscriberPreference,
  SubscriberPreferenceDisplayType,
  SubscriberPreferenceType,
  SubscriberPreferenceVisibility,
} from '@/interfaces/subscribers_preferences';

import { ContextValueType } from './types';

export const DEFAULT_SUBSCRIBER_PREFERENCE: PublicationSubscriberPreference = {
  id: '',
  name: '',
  description: '',
  created_at: '',
  preference_type: SubscriberPreferenceType.TEXT,
  display_type: SubscriberPreferenceDisplayType.UNSTYLED,
  visibility: SubscriberPreferenceVisibility.VISIBLE,
  required: true,
  custom_field_id: '',
  sort_order: 0,
  subscriber_preference_options: [],
};

const PreferencesContext = createContext<ContextValueType>({
  editablePreference: { ...DEFAULT_SUBSCRIBER_PREFERENCE },
  setEditablePreference: () => {},
  preferencesList: [],
  isListEmpty: true,
  isListLoading: false,
  searchQuery: '',
  setSearchQuery: () => {},
  orderBy: OrderBy.NAME,
  handleClickOrderButton: () => {},
  direction: Order.DESC,
  fetchNextPage: () => {},
  isFetchingNextPage: false,
  hasNextPage: false,
  totalPreferencesCount: 0,
});

export const usePreferencesContext = () => useContext(PreferencesContext);
export const PreferencesProvider = PreferencesContext.Provider;
