import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { Recommendation } from '../../../../interfaces/recommendation';
import api from '../../../../services/swarm';

import Item from './Item';

interface Props {
  recommendations: Recommendation[];
}

const Sort = ({ recommendations }: Props) => {
  const [items, setItems] = useState(recommendations);

  useEffect(() => {
    if (items.length !== recommendations.length) {
      setItems(recommendations);
    }
  }, [items, recommendations]);

  const [currentPublicationId] = useCurrentPublicationState();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      setItems((prev) => arrayMove(prev, oldIndex, newIndex));

      api
        .patch(`/recommendations/${active.id}/position`, {
          publication_id: currentPublicationId,
          recommendation: {
            position: recommendations.findIndex((recommendation) => recommendation.id === over.id) + 1,
          },
        })
        .catch(() => {
          toast.error('Something went wrong, please try again.');

          setItems((prev) => arrayMove(prev, newIndex, oldIndex));
        });
    }
  };

  return (
    <ul className="space-y-2 py-2">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((recommendation) => (
            <Item key={recommendation.id} recommendation={recommendation} />
          ))}
        </SortableContext>
      </DndContext>
    </ul>
  );
};

export default Sort;
