import { css } from 'styled-components';

import { colors } from '../../../lib/colors';
import { Styled as StyledIcon } from '../Icon/styled';

import { StyledButtonProps } from './types';

export const secondaryButtonStyle = css<StyledButtonProps>`
  background-color: ${colors.white[1]};
  box-shadow: 0 0 0 1px ${colors.black[7]};
  color: ${colors.black[1]};

  &:not(:disabled) {
    &:hover {
      ${StyledIcon.Wrapper} svg,
      ${StyledIcon.Wrapper} svg path {
        fill: ${colors.black[1]};
      }
    }

    &.is-active {
      ${StyledIcon.Wrapper} svg,
      ${StyledIcon.Wrapper} svg path {
        fill: ${colors.black[3]};
      }
    }

    &:hover,
    &.is-active {
      background-color: rgb(242, 242, 242);
      box-shadow: 0 0 0 1px ${colors.black[8]};
    }
  }

  &:disabled {
    background-color: ${colors.white[1]};
    box-shadow: 0 0 0 1px ${colors.black[7]};
    color: ${colors.black[6]};

    ${StyledIcon.Wrapper} svg,
    ${StyledIcon.Wrapper} svg path {
      fill: currentColor;
    }
  }

  ${StyledIcon.Wrapper} svg,
  ${StyledIcon.Wrapper} svg path {
    fill: ${colors.black[3]};
  }
`;

export default secondaryButtonStyle;
