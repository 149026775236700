import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

const useDeletePremiumGift = (giftId?: string) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation(() => api.delete(`/premium_gifts/${giftId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries([currentPublicationId, 'premiumGifts']);
      toast.success('Paid Subscription Gift Deleted');
    },
  });
};

export default useDeletePremiumGift;
