import { useEffect, useState } from 'react';

import useRecommendedBoostOffers from '@/hooks/useRecommendedBoostOffers';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';

import useCreateSnoozedBoostOffer from './useCreateSnoozedBoostOffer';

const useSwapRecommendedBoostOffer = () => {
  const [recommendedOffers, setRecommendedOffers] = useState<BoostOffer[]>([]);
  const [prefetchedOffers, setPrefetchedOffers] = useState<BoostOffer[]>([]);

  const createSnoozedBoostOffer = useCreateSnoozedBoostOffer({});

  const { refetch, isError, isLoading } = useRecommendedBoostOffers({ isEnabled: false });
  const hasNoResults = recommendedOffers.length === 0 && !isLoading;

  const swapOffer = async (boostOfferId: string) => {
    let newPrefetchedOffers = [...prefetchedOffers];
    if (newPrefetchedOffers.length <= 3) {
      const response = await refetch();
      const currentPrefetchedOfferIds = newPrefetchedOffers.map(({ id }) => id);
      const recommendedOfferIds = recommendedOffers.map(({ id }) => id);
      // Filter offers that might already have been rendered
      const newOffers = (
        response.data?.boost_offers && response.data?.boost_offers.length > 0 ? response.data.boost_offers : []
      ).filter(
        (newOffer) => !currentPrefetchedOfferIds.includes(newOffer.id) && !recommendedOfferIds.includes(newOffer.id)
      );

      newPrefetchedOffers = newPrefetchedOffers.concat(newOffers);
    }

    const newRenderedOffers = [...recommendedOffers];
    const indexOfSnoozedOffer = recommendedOffers.findIndex(({ id }) => boostOfferId === id);

    if (newPrefetchedOffers.length > 0) {
      // Replace snoozed offer
      newRenderedOffers.splice(indexOfSnoozedOffer, 1, newPrefetchedOffers[0]);
      newPrefetchedOffers.splice(0, 1);
    } else {
      // Only remove snoozed offer
      newRenderedOffers.splice(indexOfSnoozedOffer, 1);
    }

    setRecommendedOffers(newRenderedOffers);
    setPrefetchedOffers(newPrefetchedOffers);
  };

  const snoozeOffer = (boostOfferId: string) => {
    return createSnoozedBoostOffer.mutate(boostOfferId);
  };

  // Fetch offers on mount
  useEffect(() => {
    refetch().then((response) => {
      if (response.data?.boost_offers && response.data?.boost_offers.length > 0) {
        setRecommendedOffers(response.data.boost_offers);
      }
    });
  }, [refetch, setRecommendedOffers]);

  return {
    recommendedOffers,
    swapOffer,
    snoozeOffer,
    isLoading,
    hasNoResults,
    hasError: isError,
  };
};

export default useSwapRecommendedBoostOffer;
