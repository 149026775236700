import { CursorArrowRaysIcon, EnvelopeIcon, EnvelopeOpenIcon, NoSymbolIcon } from '@heroicons/react/24/outline';

import ProgressIndicator from '@/components/ProgressIndicator';

interface Props {
  totalSent: number;
  totalUniqueOpened: number;
  totalClicked: number;
  totalUniqueClicked: number;
  totalSpamReported: number;
  openRate: number;
  clickRate: number;
  spamRate: number;
}

const Metrics = ({
  totalSent,
  totalUniqueOpened,
  totalClicked,
  totalUniqueClicked,
  totalSpamReported,
  openRate,
  clickRate,
  spamRate,
}: Props) => {
  const formatter = new Intl.NumberFormat('en');

  return (
    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
      <div className="flex md:w-auto w-full flex-col">
        <EnvelopeIcon className="h-6 w-6 text-gray-300 mb-4" />
        <div className="mb-8">
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Recipients</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-primary-500">{formatter.format(totalSent)}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> </span>
          </p>
        </div>
      </div>

      <div className="flex md:w-auto w-full flex-col justify-between">
        <EnvelopeOpenIcon className="h-6 w-6 text-gray-300 mb-4" />
        <div className="mb-8">
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Opens</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-primary-500">{formatter.format(totalUniqueOpened)}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> unique</span>
          </p>
          <p className="leading-none invisible">
            <span className="text-gray-400 tracking-tighter text-sm"> . </span>
          </p>
        </div>

        <ProgressIndicator value={openRate} label="Open Rate" />
      </div>

      <div className="flex md:w-auto w-full flex-col justify-between">
        <CursorArrowRaysIcon className="h-6 w-6 text-gray-300 mb-4" />
        <div className="mb-8">
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Clicks</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-primary-500">{formatter.format(totalUniqueClicked)}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> unique</span>
          </p>
          <p className="leading-none">
            <span className="text-gray-400 tracking-tighter text-sm">
              vs. <strong className="text-gray-600 text-base">{formatter.format(totalClicked)}</strong> total
            </span>
          </p>
        </div>
        <ProgressIndicator value={clickRate} label="CTR" />
      </div>

      <div className="flex md:w-auto w-full flex-col justify-between">
        <NoSymbolIcon className="h-6 w-6 text-gray-300 mb-4" />
        <div className="mb-8">
          <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">Spam reports</p>
          <p className="leading-none">
            <span className="text-5xl font-black text-primary-500">{formatter.format(totalSpamReported)}</span>
            <span className="text-gray-400 tracking-tighter text-sm"> unique</span>
          </p>
          <p className="leading-none invisible">
            <span className="text-gray-400 tracking-tighter text-sm"> . </span>
          </p>
        </div>
        <ProgressIndicator value={spamRate} label="Spam rate" />
      </div>
    </div>
  );
};

export default Metrics;
