import React, { createContext, useMemo } from 'react';
import { useMutation } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';

interface IOAuthContext {
  loginWithGoogleCredential: any;
}

const OAuthContext = createContext<IOAuthContext | undefined>(undefined);

OAuthContext.displayName = 'OAuthContext';

const OAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const loginWithGoogleCredential = useMutation(
    async ({ idToken }: { idToken: string }) => {
      const baseUrl = window?.env?.REACT_APP_API_BASE_URL;
      const requestUrl = `${baseUrl}/oauth2/google`;

      const response = await axios.post(requestUrl, {
        credential: idToken,
      });

      return response.data;
    },
    {
      onSuccess: (data) => {
        return data;
      },
    }
  );

  const contextValue = useMemo(() => {
    return {
      loginWithGoogleCredential,
    };
  }, [loginWithGoogleCredential]);

  return (
    <GoogleOAuthProvider clientId={window.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}>
      <OAuthContext.Provider value={contextValue}>{children}</OAuthContext.Provider>
    </GoogleOAuthProvider>
  );
};

function useOAuth() {
  const context = React.useContext(OAuthContext);
  if (context === undefined) {
    throw new Error(`useOAuth must be used within a AuthProvider`);
  }
  return context;
}

export { OAuthContext, OAuthProvider, useOAuth };
