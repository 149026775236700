import { useCallback, useEffect, useMemo } from 'react';
import { JSONContent } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import Document from '@tiptap/extension-document';
import Italic from '@tiptap/extension-italic';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/react';
import moment from 'moment-mini';

import { suggestions } from '@/components/CommentEditor/suggestions';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Avatar } from '@/components/UI/Avatar';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useUser } from '@/hooks';
import { cn } from '@/utils/cn';

import './ThreadCard.css';

const CommentDocument = Document.extend({
  content: 'paragraph+',
});

const ParticipantAvatar = ({ id, publicationId }: { id: string; publicationId: string }) => {
  const { data: participant, isLoading } = useUser(publicationId, id);

  if (!participant) {
    return null;
  }

  return (
    <Avatar.Wrapper isLoading={isLoading} fallback={participant.name}>
      {participant.profile_picture?.url ? (
        <Avatar.Image src={participant.profile_picture.url} alt={participant.name} />
      ) : null}
    </Avatar.Wrapper>
  );
};

export type ThreadCardProps = {
  id: string;
  authorId: string;
  participantIds: string[];
  content: JSONContent;
  createdAt: number;
  replies: number;
  onClick: (id: string) => void;
  onMouseEnter: (id: string) => void;
  onMouseLeave: (id: string) => void;
  focused?: boolean;
  resolved?: boolean;
};

export const ThreadCard = ({
  id,
  createdAt,
  replies,
  authorId,
  participantIds,
  content = {},
  onClick,
  onMouseEnter,
  onMouseLeave,
  focused,
  resolved,
}: ThreadCardProps) => {
  const [currentPublicationId] = useCurrentPublicationState(false);
  const { data: author, isLoading: isAuthorLoading } = useUser(currentPublicationId, authorId);

  const uniqueParticipantIds = useMemo(() => Array.from(new Set(participantIds)), [participantIds]);

  const handleMouseEnter = useCallback(() => {
    onMouseEnter(id);
  }, [onMouseEnter, id]);

  const handleMouseLeave = useCallback(() => {
    onMouseLeave(id);
  }, [onMouseLeave, id]);

  const wrapperClassName = cn(
    'p-3 border border-transparent text-left bg-white rounded appearance-none',
    focused ? 'border-primary-500' : ''
  );

  const previewEditor = useEditor({
    content,
    extensions: [
      CommentDocument,
      Text,
      Paragraph,
      Bold,
      Italic,
      Mention.configure({
        renderText({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
        },
        HTMLAttributes: {
          class: 'mention-suggestion',
        },
        suggestion: suggestions(() => document.body),
      }),
    ],
    editable: false,
    editorProps: {
      attributes: {
        class: 'comment-preview',
      },
    },
  });

  useEffect(() => {
    previewEditor?.commands.setContent(content);
  }, [content, previewEditor]);

  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <button
      data-thread-id={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={wrapperClassName}
      type="button"
      onClick={handleClick}
    >
      <div className="flex items-center gap-1 justify-between">
        <span>
          {uniqueParticipantIds.slice(0, 5).map((participantId) => (
            <ParticipantAvatar key={participantId} id={participantId} publicationId={currentPublicationId} />
          ))}
        </span>
        {resolved && <Icon name="Check" className="w-8 h-8" />}
      </div>
      <div className="flex items-center justify-start gap-1 mt-3">
        {isAuthorLoading && !author ? (
          <div className="h-[1em] text-xs w-12 animate-pulse bg-neutral-200 rounded-md" />
        ) : null}
        {author ? <div className="text-xs leading-[1.1] font-semibold text-black">{author.name}</div> : null}
        <div className="text-xs leading-[1.1] font-semibold text-neutral-400">{moment(createdAt).fromNow()}</div>
      </div>
      <div className="mt-1 text-sm">
        <EditorContent editor={previewEditor} />
      </div>
      {replies > 0 ? (
        <div className="mt-2 text-xs font-medium text-neutral-400">
          {replies} {replies === 1 ? 'reply' : 'replies'}
        </div>
      ) : null}
    </button>
  );
};
