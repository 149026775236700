import cx from 'classnames';

type BodyProps = React.PropsWithChildren<{
  className?: string;
}>;

const Body = ({ className, children }: BodyProps) => {
  const classNames = cx('bg-white divide-y divide-gray-200', className);

  return <tbody className={classNames}>{children}</tbody>;
};

export default Body;
