import { currencyFormatter, valueFormatter } from '@/utils';

export enum StatTypes {
  DEFAULT = 'default',
  MONEY = 'money',
  TIME = 'time',
}

type StatBodyProps = {
  currency?: string;
  isLoading: boolean;
  type?: StatTypes;
  value: number;
};

type RenderForTimeProps = {
  value: string | number;
};

const RenderForTime = ({ value }: RenderForTimeProps) => {
  return (
    <div className="flex flex-col">
      <span className="flex text-2xl font-bold justify-center">{value}</span>
    </div>
  );
};

const RenderForCurrency = ({ value, currency }: { value: number; currency: string }) => {
  const newValue = currencyFormatter(value, currency);

  return <span className="flex text-2xl font-bold">{newValue}</span>;
};

const RenderForDefault = ({ value }: { value: string | number }) => {
  let newValue = value;

  if (typeof value === 'number') {
    newValue = valueFormatter(value);
  }

  return <span className="flex text-2xl font-bold">{newValue}</span>;
};

const StatBody = ({ value, isLoading, currency = 'USD', type = StatTypes.TIME }: StatBodyProps) => {
  if (isLoading) return null;

  const components = {
    [StatTypes.DEFAULT]: RenderForDefault,
    [StatTypes.MONEY]: RenderForCurrency,
    [StatTypes.TIME]: RenderForTime,
  };

  const NewComponent = components[type];

  return <NewComponent value={value} currency={currency} />;
};

export default StatBody;
