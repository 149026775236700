import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import SlideOver from '@/components/SlideOver';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PremiumGift } from '@/interfaces/premium_gift';
import api from '@/services/swarm';

import PremiumGiftForm from './PremiumGiftForm';

interface Props {
  premiumGift?: PremiumGift;
  isOpen: boolean;
  onClose: () => void;
}
const EditPremiumGift = ({ premiumGift, isOpen, onClose }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const updatePremiumGiftMutation = useMutation(
    ({ time_unit, duration, tier_id }: { time_unit: string; duration: string; tier_id: string }) =>
      api.patch(`/premium_gifts/${premiumGift?.id || ''}`, {
        publication_id: currentPublicationId,
        premium_gift: {
          time_unit,
          duration,
          tier_id,
        },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        toast.success('Paid Subscription Gift updated successfully');
        queryClient.invalidateQueries([currentPublicationId, 'premiumGifts', '']);
        onClose();
      },
    }
  );

  const onSubmit = async (time_unit: string, duration: string, tier_id: string) => {
    await updatePremiumGiftMutation.mutateAsync({ time_unit, duration, tier_id });
  };

  return (
    <SlideOver bodyId={currentPublicationId} headerText="Edit Paid Subscription Gift" isOpen={isOpen} onClose={onClose}>
      <PremiumGiftForm
        onSubmit={onSubmit}
        premium_gift={{
          time_unit: premiumGift?.time_unit,
          duration: premiumGift?.duration,
          tier_id: premiumGift?.tier_id,
        }}
      />
    </SlideOver>
  );
};

export default EditPremiumGift;
