import React, { FC, forwardRef, PropsWithChildren } from 'react';
import cx from 'classnames';

interface TableComponent extends FC<PropsWithChildren<{}>> {
  Head: typeof TableHead;
  Row: typeof TableRow;
  Cell: typeof TableCell;
  Header: typeof TableHeader;
  Body: typeof TableBody;
}

const Table: TableComponent = ({ children }) => {
  return <table className="divide-y divide-gray-100 w-full">{children}</table>;
};

const TableHead: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return <thead className="bg-white">{children}</thead>;
};

const TableRow = forwardRef<HTMLTableRowElement, PropsWithChildren<{}>>((props, ref) => {
  const { children } = props;
  return (
    <tr ref={ref} className="even:bg-gray-50 w-full">
      {children}
    </tr>
  );
});

const TableCell: React.FC<
  PropsWithChildren<{
    align?: 'left' | 'right';
    muted?: boolean;
  }>
> = ({ children, align = 'left', muted = false }) => {
  const alignClass = align === 'right' ? 'text-right' : 'text-left';
  const textColor = muted ? 'text-gray-500' : 'text-gray-900';

  return (
    <td className={cx('py-2 pl-4 pr-3 text-sm font-medium truncate', alignClass, textColor)} style={{ maxWidth: 1 }}>
      {children}
    </td>
  );
};

const TableHeader: React.FC<
  PropsWithChildren<{
    align?: 'left' | 'right';
  }>
> = ({ children, align = 'left' }) => {
  const alignClass = align === 'right' ? 'text-right' : 'text-left';

  return (
    <th
      scope="col"
      className={cx('whitespace-nowrap py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900', alignClass)}
      align={align}
    >
      {children}
    </th>
  );
};

const TableBody: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return <tbody className="w-full bg-white divide-y divide-gray-100 ">{children}</tbody>;
};

Table.Head = TableHead;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.Header = TableHeader;
Table.Body = TableBody;

export default Table;
