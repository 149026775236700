import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  organizationId: string | undefined;
  downgradeId: string;
}

const useClaimOffer = () => {
  return useMutation(({ organizationId, downgradeId }: Props) =>
    api.post(`/organizations/${organizationId}/downgrades/${downgradeId}/claim_offer`, {
      organization_id: organizationId,
      downgrade_id: downgradeId,
    })
  );
};

export default useClaimOffer;
