import { useNavGroups } from '../../../../../hooks/useNavGroups';
import CustomPagesSection from '../../../../../routes/website_builder/custom_pages/view/CustomPagesSection';

import HomePageSection from './HomePageSection';
import LandingPageSection from './LandingPageSection';
import UpgradePageSection from './UpgradePageSection';

const Pages = () => {
  const navGroupsQuery = useNavGroups();

  return (
    <>
      <LandingPageSection />
      <HomePageSection />
      <UpgradePageSection />
      <CustomPagesSection navGroups={navGroupsQuery?.data} />
    </>
  );
};

export default Pages;
