import { useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';

import { Input } from '../../../../components/Form';
import { useSettings } from '../../../../context/settings-context';
import useUpdateUserPassword from '../../../../hooks/useUsers/useUpdateUserPassword';
import { Section, SettingsPageLayout } from '../../Components';

import TwoFactorSection from './TwoFactorSection';

const Password = () => {
  const { settings } = useSettings();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const updatePasswordMutation = useUpdateUserPassword();

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error('New and Confirm Passwords must match!');
      return;
    }

    updatePasswordMutation.mutate({
      old_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    });
  };

  return (
    <SettingsPageLayout title="Password">
      <Section
        title="Password Reset"
        description="Fill out the form to reset your password."
        scrollToId="password"
        hasDivider={settings?.two_factor_auth}
      >
        <form onSubmit={handleUpdate}>
          <div className="flex flex-col space-y-4">
            <Input
              className="w-full"
              name="current_password"
              labelText="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              type="password"
              required
            />
            <Input
              className="w-full"
              name="new_password"
              labelText="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              required
            />
            <Input
              className="w-full"
              name="confirm_password"
              labelText="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              required
            />
          </div>

          <div className="mt-4 py-4 flex justify-end">
            <Button type="submit" loading={updatePasswordMutation.isLoading}>
              {updatePasswordMutation.isLoading ? 'Resetting...' : 'Reset Password'}
            </Button>
          </div>
        </form>
      </Section>
      {settings?.two_factor_auth && (
        <Section
          title="Two-factor Authentication"
          description="Adding two-factor authentication makes your account more secure by requiring a one-time code along with your password to sign in."
          scrollToId="password"
          hasDivider={false}
        >
          <TwoFactorSection />
        </Section>
      )}
    </SettingsPageLayout>
  );
};

export default Password;
