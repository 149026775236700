import { useCallback, useRef } from 'react';
import Tippy from '@tippyjs/react';
import { Editor, JSONContent } from '@tiptap/react';

import { CommentEditor } from '@/components/CommentEditor';
import { useClickOutside } from '@/hooks/useUsers/useClickOutside';
import { useEsc } from '@/hooks/useUsers/useEsc';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';

import { useThreadComposer } from './Context';

export type ThreadComposerProps = {
  editor: Editor;
};

export const ThreadComposer = ({ editor }: ThreadComposerProps) => {
  const { isComposing, setComposing } = useThreadComposer();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { createThread } = useEditorContext();

  useClickOutside(() => setComposing(false), wrapperRef);
  useEsc(() => setComposing(false), wrapperRef);

  const handleSubmit = useCallback(
    (values: { html: string; json: JSONContent }) => {
      createThread(values.json);
      setComposing(false);

      const { from, to } = editor.state.selection;
      setTimeout(() => {
        editor
          .chain()
          .setTextSelection({ from: from + 1, to })
          .run();

        editor.commands.setTextSelection({ from, to });
      });
    },
    [createThread, setComposing, editor]
  );

  const getRect = useCallback(() => {
    const { from, to } = editor.state.selection;

    const startCoords = editor.view.coordsAtPos(from);
    const endCoords = editor.view.coordsAtPos(to);
    let { node: nodeAtStart } = editor.view.domAtPos(from);
    let { node: nodeAtEnd } = editor.view.domAtPos(to);

    if (nodeAtStart.nodeType === 3) {
      nodeAtStart = nodeAtStart.parentNode as HTMLElement;
    }

    if (nodeAtEnd?.nodeType === 3) {
      nodeAtEnd = nodeAtEnd.parentNode as HTMLElement;
    }

    return new DOMRect(
      startCoords.left,
      startCoords.top,
      endCoords.left - startCoords.left,
      endCoords.bottom - startCoords.top
    );
  }, [editor]);

  return (
    <Tippy
      content={
        isComposing ? (
          <div ref={wrapperRef} className="bg-white w-96 border border-primary-500 rounded-md shadow">
            <CommentEditor className="max-h-[8rem]" autoFocus onSubmit={handleSubmit} />
          </div>
        ) : null
      }
      appendTo={document.body}
      visible={isComposing}
      interactive
      placement="bottom"
      getReferenceClientRect={getRect}
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: document.body,
              mainAxis: true,
              altAxis: true,
              padding: 4,
            },
          },
          {
            name: 'flip',
            options: {
              allowedAutoPlacements: ['top', 'bottom'],
            },
          },
        ],
      }}
    />
  );
};
