import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { ExternalEmbed } from '@/interfaces/external_embed';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  external_embeds: ExternalEmbed[];
  pagination: Pagination;
}

export default function useExternalEmbeds({ search }: { search?: string }) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchExternalEmbeds = ({ pageParam = 1 }) =>
    api
      .get(`/publications/${currentPublicationId}/external_embeds`, {
        params: {
          page: pageParam,
          publication_id: currentPublicationId,
          q: search,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['externalEmbeds', currentPublicationId, search], fetchExternalEmbeds, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
