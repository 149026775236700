import cx from 'classnames';
import styled from 'styled-components';

interface HeaderProps {
  children: React.ReactNode;
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Base = `font-bold`;
const HEADER_TYPES = {
  h1: `${Base} text-3xl`,
  h2: `${Base} text-2xl`,
  h3: `${Base} text-xl`,
  h4: `${Base} text-lg`,
  h5: `${Base} text-md`,
  h6: `${Base} text-base`,
};
const StyledHeader = styled.h1``;

const Header: React.FC<HeaderProps> = ({ className = '', as = 'h1', children }: HeaderProps) => (
  <StyledHeader as={as} className={cx(`${HEADER_TYPES[as]}`, className)}>
    {children}
  </StyledHeader>
);

export default Header;
