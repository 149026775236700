import { useParams } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import Text from '@/components/Text';
import { useDeletePostTarget } from '@/hooks/usePostTargets';
import { PostTarget } from '@/interfaces/post_target';
import pluralize from '@/utils/pluralize';

import { useDeliveryContext } from '../context/DeliveryContext';

const SegmentListOption = ({ postTarget, readOnly }: { postTarget: PostTarget; readOnly: boolean }) => {
  const { postId } = useParams<'postId'>() as { postId: string };
  const deleteMutation = useDeletePostTarget(postId);
  const { isChanging, setIsChanging } = useDeliveryContext();

  const handleDelete = () => {
    setIsChanging(true);
    deleteMutation.mutateAsync(postTarget.id).finally(() => setIsChanging(false));
  };

  return (
    <li
      key={postTarget.id}
      className={cx(
        'flex justify-between py-2 items-center border-b border-b-gray-200 last:border-b-0 first:pt-0 last:pb-0',
        deleteMutation.isLoading && 'hidden'
      )}
    >
      <div className="space-y-1">
        <Text type="label" size="sm">
          {postTarget.receiver_display_name}
        </Text>
        <Text type="body" size="sm" className="text-gray-700">
          {pluralize('Subscriber', postTarget.num_active_subscribers)}
        </Text>
      </div>
      {!readOnly && (
        <button
          type="button"
          onClick={handleDelete}
          className="text-gray-400 hover:text-gray-600 p-1 disabled:cursor-not-allowed"
          disabled={isChanging}
        >
          <TrashIcon width={16} height={16} />
        </button>
      )}
    </li>
  );
};

export default SegmentListOption;
