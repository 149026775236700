import { useEditorContext } from '../../../EditorContext';
import { EmptyState } from '../components/EmptyState';
import { ThreadCard } from '../components/ThreadCard';

export const AllThreadsView = ({ onClickThread }: { onClickThread: (id: string) => void }) => {
  const { threads, highlightThread, removeHighlightThread, editor } = useEditorContext();

  const focusedThreads = editor?.storage.comments.focusedThreads || [];

  if (!threads) return <EmptyState message="No threads found" />;

  return (
    <div className="grid gap-2">
      {threads?.map((thread) => (
        <ThreadCard
          key={thread.id}
          id={thread.id}
          authorId={thread.data.authorId}
          content={thread.comments[0]?.content}
          participantIds={thread.comments.map((c) => c.data.authorId)}
          createdAt={thread.createdAt}
          replies={thread.comments.length - 1}
          onClick={onClickThread}
          onMouseEnter={highlightThread}
          onMouseLeave={removeHighlightThread}
          focused={focusedThreads.includes(thread.id)}
          resolved={!!thread.resolvedAt}
        />
      ))}
    </div>
  );
};
