import styled from 'styled-components';

import { colors } from '../../../lib/colors';

const Styled = {
  ButtonWrapper: styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    & > button {
      flex: 1;
    }
  `,
  InputFieldsWrapper: styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  `,
  InputFieldWrapper: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
  `,
  Label: styled.span`
    color: ${colors.white[4]};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.1;
    padding-left: 0.25rem;
  `,
};

export default Styled;
