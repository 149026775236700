import moment from 'moment-mini';

import { CustomFieldKind } from '@/interfaces/custom_field';

export const useDisplayCustomFieldValue = () => (kind: CustomFieldKind, value?: string) => {
  switch (kind) {
    case CustomFieldKind.BOOLEAN:
      if (value === undefined) return '-';
      return value === 'true' ? 'True' : 'False';
    case CustomFieldKind.DATE:
      return value ? moment(value).format('ll') : '-';
    case CustomFieldKind.DATETIME:
      return value ? moment(value).format('lll') : '-';
    default:
      return value || '-';
  }
};
