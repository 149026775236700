interface Props {
  className?: string;
}

const SubscriberUpdate = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4383_43006)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C6.07174 0 5.1815 0.368749 4.52513 1.02513C3.86875 1.6815 3.5 2.57174 3.5 3.5C3.5 4.42826 3.86875 5.3185 4.52513 5.97487C5.1815 6.63125 6.07174 7 7 7C7.92826 7 8.8185 6.63125 9.47487 5.97487C10.1313 5.3185 10.5 4.42826 10.5 3.5C10.5 2.57174 10.1313 1.6815 9.47487 1.02513C8.8185 0.368749 7.92826 0 7 0ZM5.93934 2.43934C6.22064 2.15804 6.60218 2 7 2C7.39782 2 7.77936 2.15804 8.06066 2.43934C8.34196 2.72064 8.5 3.10218 8.5 3.5C8.5 3.89782 8.34197 4.27936 8.06066 4.56066C7.77936 4.84197 7.39782 5 7 5C6.60218 5 6.22064 4.84197 5.93934 4.56066C5.65804 4.27936 5.5 3.89782 5.5 3.5C5.5 3.10218 5.65804 2.72064 5.93934 2.43934Z"
        fill="currentColor"
      />
      <path
        d="M7 8C3.68629 8 1 10.6863 1 14V15C1 15.5523 1.44772 16 2 16H6.5C7.05228 16 7.5 15.5523 7.5 15C7.5 14.4477 7.05228 14 6.5 14H3C3 11.7909 4.79086 10 7 10C7.30418 10 7.59926 10.0338 7.88216 10.0974C8.42099 10.2185 8.95601 9.87992 9.07716 9.34108C9.1983 8.80225 8.8597 8.26723 8.32087 8.14608C7.89495 8.05033 7.45275 8 7 8Z"
        fill="currentColor"
      />
      <path
        d="M17.13 11.3631C17.6485 11.5532 17.9147 12.1277 17.7246 12.6462L17.146 14.2241C16.9797 14.6776 16.5132 14.9474 16.0372 14.8653L14.5119 14.6023C13.9676 14.5085 13.6025 13.9912 13.6963 13.447C13.7674 13.0346 14.0816 12.725 14.4665 12.6401C14.3909 12.5585 14.3083 12.4831 14.2194 12.4148C13.9236 12.1872 13.5699 12.0469 13.1984 12.0099C12.827 11.9728 12.4526 12.0405 12.1176 12.2052C11.7826 12.3699 11.5004 12.6251 11.3029 12.9418C11.1054 13.2586 11.0005 13.6243 11 13.9976C10.9996 14.3709 11.1036 14.7369 11.3003 15.0541C11.4971 15.3713 11.7787 15.6272 12.1133 15.7927C12.4479 15.9582 12.8221 16.0267 13.1936 15.9906C13.7433 15.9371 14.2323 16.3394 14.2858 16.8891C14.3392 17.4388 13.937 17.9277 13.3873 17.9812C12.6442 18.0535 11.8957 17.9164 11.2265 17.5854C10.5573 17.2543 9.99414 16.7427 9.60065 16.1082C9.20717 15.4737 8.9991 14.7418 9 13.9952C9.0009 13.2486 9.21073 12.5172 9.60574 11.8836C10.0007 11.2501 10.5652 10.7398 11.2352 10.4104C11.9052 10.081 12.654 9.94567 13.3969 10.0197C14.1398 10.0938 14.8471 10.3743 15.4389 10.8295C15.7048 11.0341 15.9426 11.2702 16.1476 11.5317C16.4127 11.3126 16.7838 11.2361 17.13 11.3631Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4383_43006">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SubscriberUpdate;
