import { useState } from 'react';
import { DotsThree, Pencil, Trash } from '@phosphor-icons/react';

import { useDeleteSignupFlow } from '@/hooks/useSignupFlows';
import { SignupFlow } from '@/interfaces/signup_flow';
import { Badge } from '@/ui/Badge';

import { Button } from '../../../_components/UI/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../_components/UI/DropdownMenu';
import Modal from '../../../_components/UI/Modal';
import { TableCell, TableRow } from '../../../_components/UI/Table';
import { Text } from '../../../_components/UI/Text';

interface Props {
  signupFlow: SignupFlow;
  onEdit: (signupFlow: SignupFlow) => void;
}

const SignupFlowRow = ({ signupFlow, onEdit }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const deleteSignupFlow = useDeleteSignupFlow({
    signupFlowId: signupFlow?.id || '',
  });

  const handleDelete = () => {
    deleteSignupFlow.mutate();
  };

  return (
    <>
      <TableRow key={signupFlow.id}>
        <TableCell className="font-medium">{signupFlow.name}</TableCell>
        <TableCell>{signupFlow.steps.length}</TableCell>
        <TableCell className="text-right">
          {signupFlow.default ? (
            <Badge type="information">
              <span className="capitalize mr-1">Default</span>
            </Badge>
          ) : null}
        </TableCell>
        <TableCell className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <DotsThree className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={2} align="end" className="flex w-[150px] flex-col bg-wb-primary p-1">
              <DropdownMenuItem LeftIcon={Pencil} onSelect={() => onEdit(signupFlow)}>
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem LeftIcon={Trash} onSelect={() => setIsOpen(true)}>
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Delete Signup Flow"
        onConfirm={handleDelete}
        isLoading={deleteSignupFlow.isLoading}
        ctaVariant="danger"
        ctaText="Delete"
        isLoadingText="Deleting..."
      >
        <Text size="sm" weight="medium" variant="secondary" as="p" className="mb-4">
          Are you sure you want to delete this signup flow? This action cannot be undone.
        </Text>
      </Modal>
    </>
  );
};

export default SignupFlowRow;
