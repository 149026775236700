import BoostAgreementsList from '../Dashboard/components/BoostAgreementsList';

interface Props {
  boostedPublicationId: string;
}

const AffiliateTab = ({ boostedPublicationId }: Props) => {
  return (
    <BoostAgreementsList boostedPublicationId={boostedPublicationId} showCPA showSearch={false} isBoostAffiliate />
  );
};

export default AffiliateTab;
