import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import api from '@/services/swarm';

import { useCurrentPublicationState } from '../../../context/current-publication-context';

export interface KPIData {
  total_subscribers_with_referrals: number;
  lag_total_subscribers_with_referrals: number;
  total_subscribers_with_referrals_delta: number;
  total_referrals: number;
  lag_total_referrals: number;
  total_referrals_delta: number;
  avg_referrals_per_subscriber: number;
  lag_avg_referrals_per_subscriber: number;
  avg_referrals_per_subscriber_delta: number;
}

interface Props {
  children: (kpiData: KPIData | undefined, isLoading: boolean) => React.ReactNode;
}

const KpisContainer = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [kpiData, setKpiData] = useState<KPIData>();
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchKPIS = useCallback(() => {
    if (currentPublicationId) {
      const params = {
        publication_id: currentPublicationId,
      };

      setIsLoading(true);
      api
        .get('/referral_program/metrics/kpis', { params })
        .then((res) => {
          setKpiData(res.data);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPublicationId]);

  useEffect(() => {
    fetchKPIS();
  }, [fetchKPIS]);

  return <>{children(kpiData, isLoading)}</>;
};

export default KpisContainer;
