import { PlusIcon } from '@heroicons/react/20/solid';

import { Description } from '@/components/Form';
import Label from '@/components/Form/Label';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import { Button } from '@/ui/Button';

import ConditionsList from '../Nodes/AutomationStepNodes/ConditionsList';

interface Props {
  label: string;
  description: string;
  conditions: AutomationConditions;
  onClickEditConditions: () => void;
}

const ConfigureConditionsForm = ({
  label,
  description,
  conditions: currentConditions,
  onClickEditConditions,
}: Props) => {
  const hasConditions = currentConditions.conditions ? currentConditions.conditions.length > 0 : false;

  return (
    <div className="flex flex-col gap-y-2">
      <Label htmlFor="" value={label} />
      <Description value={description} />
      <div className="flex flex-col gap-y-2">
        <ConditionsList automationConditions={currentConditions} />
        <Button
          className="w-fit"
          type="button"
          variant="primary-inverse"
          size="xs"
          onClick={onClickEditConditions}
          Icon={PlusIcon}
        >
          {hasConditions ? 'Edit Conditions' : 'Add Conditions'}
        </Button>
      </div>
    </div>
  );
};

export default ConfigureConditionsForm;
