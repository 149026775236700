import { ReactNode } from 'react';
import cx from 'classnames';

import Text from '@/components/Text';

interface Props {
  icon?: ReactNode;
  label: string | number;
  id?: string;
  backgroundColor?: string;
}

const Tag = ({ label, icon, id = '', backgroundColor = 'bg-white' }: Props) => {
  return (
    <div
      id={id}
      className={cx(
        'flex border items-center justify-center border-gray-300 py-0.5 px-2 gap-x-1 rounded-lg w-fit',
        backgroundColor
      )}
    >
      {icon && <span className="text-gray-400 w-3.5 h-3.5">{icon}</span>}
      <Text size="xs" type="subtitle" className="text-gray-700">
        {label}
      </Text>
    </div>
  );
};

export default Tag;
