import { RefObject, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountUp from 'react-countup';
import cx from 'classnames';

import { PublicationRewind } from '@/interfaces/publication_rewind';

import countryData from '../../../utils/countries.json';

type Props = {
  currentViewIndex: number;
  data?: PublicationRewind;
  index?: number;
  setSlideRef: (ref: RefObject<HTMLDivElement>) => void;
};

const View8 = ({ setSlideRef, data, currentViewIndex, index }: Props) => {
  const [country1Count, setCountry1Count] = useState({ code: '', name: '', count: 0 });
  const [country2Count, setCountry2Count] = useState({ code: '', name: '', count: 0 });
  const [country3Count, setCountry3Count] = useState({ code: '', name: '', count: 0 });
  const [playAnimation, setPlayAnimation] = useState(false);
  const leftOver =
    100 -
    (Math.round(data?.top_countries[0]?.percentage_of_total_subscribers || 0) +
      Math.round(data?.top_countries[1]?.percentage_of_total_subscribers || 0) +
      Math.round(data?.top_countries[2]?.percentage_of_total_subscribers || 0));
  const contentRef = useRef<HTMLDivElement>(null);

  const getCountryNameFromCode = (code: string) => {
    const country = countryData.find((c) => c.code === code);
    return country?.name || '';
  };

  useEffect(() => {
    const isCurrentView = currentViewIndex === index;

    if (isCurrentView) {
      setSlideRef(contentRef);
      setCountry1Count({
        code: data?.top_countries[0]?.country_iso.toLowerCase() || '',
        name: getCountryNameFromCode(data?.top_countries[0]?.country_iso || ''),
        count: Math.round(data?.top_countries[0]?.percentage_of_total_subscribers || 0),
      });
      setCountry2Count({
        code: data?.top_countries[1]?.country_iso.toLowerCase() || '',
        name: getCountryNameFromCode(data?.top_countries[1]?.country_iso || ''),
        count: Math.round(data?.top_countries[1]?.percentage_of_total_subscribers || 0),
      });
      setCountry3Count({
        code: data?.top_countries[2]?.country_iso.toLowerCase() || '',
        name: getCountryNameFromCode(data?.top_countries[2]?.country_iso || ''),
        count: Math.round(data?.top_countries[2]?.percentage_of_total_subscribers || 0),
      });
      setTimeout(() => {
        setPlayAnimation(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  return (
    <div ref={contentRef} className="w-full h-full relative overflow-hidden rewind-bg-pink">
      <div className="rewind-bg-burst top-0 left-0 absolute w-full h-full" />
      <div className="rewind-bg-texture top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] bg-white rounded-[2rem] m-[5%_0_0_5%] p-[35px_55px] flex flex-col border-2 rewind-border-cyber-ink" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] m-[5%_0_0_5%] p-[35px] flex flex-col">
        <div className="flex justify-center items-center w-full mt-6 mb-10">
          <img className="h-6 w-auto" src="/rewind-2024/images/beehiiv-logo-wordmark.png" alt="beehiiv logo" />
        </div>
        <h1
          className={cx(
            'font-clash-grotesk-v rewind-text-cyber-ink uppercase font-bold w-full text-[4rem] leading-[3.2rem] text-center mb-6 transition-all duration-300',
            !playAnimation && 'translate-x-20 opacity-0',
            playAnimation && 'translate-x-0 opacity-1'
          )}
        >
          MOST OF YOUR READERS ARE TUNING IN FROM…
        </h1>
        <ul className="mt-14 p-0 w-full">
          <li className="flex w-full justify-between items-center rewind-bg-ethereal-white rounded-xl p-6 mb-9">
            <span className={`w-[133.3px] h-[100px] fi fi-${country1Count.code}`} />
            <div className="flex justify-between items-center w-full pl-6">
              <div className="font-satoshi-v rewind-text-cyber-ink text-2xl font-bold pr-6">{country1Count.name}</div>
              <div className="flex font-bold text-[5.125rem] leading-[3.5rem] font-clash-grotesk-v uppercase items-baseline">
                <CountUp start={0} end={country1Count.count} duration={3} separator=" " decimals={0} decimal=".">
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <span>%</span>
                    </div>
                  )}
                </CountUp>
              </div>
            </div>
          </li>
          <li className="flex w-full justify-between items-center rewind-bg-ethereal-white rounded-xl p-6 mb-9">
            <span className={`w-[133.3px] h-[100px] fi fi-${country2Count.code}`} />
            <div className="flex justify-between items-center w-full pl-6">
              <div className="font-satoshi-v rewind-text-cyber-ink text-2xl font-bold pr-6">{country2Count.name}</div>
              <div className="flex font-bold text-[5.125rem] leading-[3.5rem] font-clash-grotesk-v uppercase items-baseline">
                <CountUp start={0} end={country2Count.count} duration={3} separator=" " decimals={0} decimal=".">
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <span>%</span>
                    </div>
                  )}
                </CountUp>
              </div>
            </div>
          </li>
          <li className="flex w-full justify-between items-center rewind-bg-ethereal-white rounded-xl p-6">
            <span className={`w-[133.3px] h-[100px] fi fi-${country3Count.code}`} />
            <div className="flex justify-between items-center w-full pl-6">
              <div className="font-satoshi-v rewind-text-cyber-ink text-2xl font-bold pr-6">{country3Count.name}</div>
              <div className="flex font-bold text-[5.125rem] leading-[3.5rem] font-clash-grotesk-v uppercase items-baseline">
                <CountUp start={0} end={country3Count.count} duration={3} separator=" " decimals={0} decimal=".">
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <span>%</span>
                    </div>
                  )}
                </CountUp>
              </div>
            </div>
          </li>
        </ul>
        {leftOver > 0 && (
          <p className="font-satoshi-v rewind-text-cyber-ink text-3xl mt-14 font-medium text-center">
            And the other {leftOver}% are from around the world.
          </p>
        )}
      </div>
    </div>
  );
};

export default View8;
