import { FC, ReactElement } from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  labelText?: String;
  name: string;
  checked: boolean;
  onChange: (name: string, checked: boolean) => void;
  helperText?: string;
  child1: ReactElement;
  child2: ReactElement;
  size?: 'sm' | 'md' | 'lg';
  type?: 'raised' | 'recessed';
}

const SwitchSlider: FC<Props> = (props: Props) => {
  const {
    className,
    labelText,
    name,
    checked,
    onChange,
    helperText,
    child1,
    child2,
    size = 'md',
    type = 'raised',
  } = props;

  const handleChange = () => {
    if (onChange) {
      onChange(name, !checked);
    }
  };

  const hasLabelOrHelperText: boolean = !!labelText || !!helperText;

  return (
    <div className={cx(type === 'recessed' && 'p-1 bg-gray-100 rounded-md', className)}>
      <button
        id={name}
        type="button"
        onClick={handleChange}
        className={cx({
          'focus:ring-transparent flex items-center w-full border-1 border-transparent rounded-md cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none bg-gray-100 relative':
            true,
          'h-10': size === 'sm',
          'h-14': size === 'md',
          'h-18': size === 'lg',
        })}
      >
        <div className="pointer-events-none w-1/2 h-full rounded-md flex items-center flex justify-center z-10">
          {child1}
        </div>
        <div className="pointer-events-none w-1/2 h-full rounded-md flex items-center flex justify-center left-1/2 z-10">
          {child2}
        </div>
        <span
          className={cx(
            'pointer-events-none w-1/2 h-full rounded-md bg-white transform ring-0 transition ease-in-out duration-200 inset-0 flex items-center justify-center absolute border border-solid border-gray-100',
            checked ? 'translate-x-full' : 'translate-x-0',
            type === 'raised' && 'shadow'
          )}
        />
        {name && <input type="hidden" name={name} value={`${checked}`} />}
      </button>
      {hasLabelOrHelperText && (
        <div className="ml-3">
          {labelText && (
            <label htmlFor={name} className="block text-sm font-medium text-gray-700 mt-1">
              {labelText}
            </label>
          )}
          {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
        </div>
      )}
    </div>
  );
};

export default SwitchSlider;
