import { Card, Metric } from '@tremor/react';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import Text from '@/components/Text';

import StatBody, { StatTypes } from './StatBody';

type DefaultProps = {
  isLoading: boolean;
  title: string;
  type?: StatTypes;
  value: number;
};

type TimeStatProps = {
  isLoading: boolean;
  title: string;
  value: number;
};

type MoneyStatProps = {
  currency?: string;
  isLoading: boolean;
  title: string;
  value: number;
};

type StatProps = {
  type: StatTypes;
  currency?: string;
  isLoading: boolean;
  title: string;
  value: number;
};

const Loading = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) return null;

  return <LoadingSpinner className="ml-2" />;
};

const BaseStat = ({ title, value, isLoading, currency, type = StatTypes.TIME }: StatProps) => {
  return (
    <Card decoration="top" decorationColor="pink" className="flex flex-col h-full">
      <Text size="sm" className="text-center">
        {title}
      </Text>

      <Loading isLoading={isLoading} />

      <Metric className="text-center flex w-full justify-center">
        <StatBody isLoading={isLoading} value={value} currency={currency} type={type} />
      </Metric>
    </Card>
  );
};

const Stat = ({ title, value, isLoading, type = StatTypes.DEFAULT }: DefaultProps) => {
  return <BaseStat title={title} value={value} isLoading={isLoading} type={type} />;
};

const TimeStat = ({ title, value, isLoading }: TimeStatProps) => {
  return <BaseStat title={title} value={value} isLoading={isLoading} type={StatTypes.TIME} />;
};

const MoneyStat = ({ title, value, isLoading, currency }: MoneyStatProps) => {
  return <BaseStat title={title} value={value} isLoading={isLoading} currency={currency} type={StatTypes.MONEY} />;
};

export default Stat;
export { MoneyStat, TimeStat };
