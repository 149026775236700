import { useQuery } from 'react-query';

import { AdNetworkIndustry } from '../../../interfaces/ad_network/internal/industry';
import api from '../../../services/swarm';

export default function useIndustry({ id }: { id: string }) {
  return useQuery<AdNetworkIndustry>(
    ['ad_network', 'internal', 'industries', id],
    () => api.get(`/ad_network/internal/industries/${id}`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
