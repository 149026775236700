import useReferrals from '@/hooks/useSubscriptionMilestones/useReferrals';
import { SubscriptionMilestone } from '@/interfaces/milestone';
import ReferralsTable from '@/pages/ReferralProgram/Fulfillment/ReferralTable';

interface Props {
  subscriptionMilestone: SubscriptionMilestone;
}

const ManageReferrals = ({ subscriptionMilestone }: Props) => {
  const {
    data: referralsData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useReferrals(subscriptionMilestone?.subscription_id);
  const referrals = referralsData?.pages.flatMap((page) => page.referrals) || [];

  return (
    <ReferralsTable
      referrals={referrals}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      onDelete={refetch}
      subscriptionMilestoneId={subscriptionMilestone.id}
    />
  );
};

export default ManageReferrals;
