interface Props {
  className?: string;
}

const WaitUntil = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4362_42884)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99996 0C9.55224 0 9.99996 0.447715 9.99996 1V4.07689C9.99996 4.62918 9.55224 5.07689 8.99996 5.07689C8.44767 5.07689 7.99996 4.62918 7.99996 4.07689V1C7.99996 0.447715 8.44767 0 8.99996 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99996 12.9229C9.55224 12.9229 9.99996 13.3707 9.99996 13.9229V16.9998C9.99996 17.5521 9.55224 17.9998 8.99996 17.9998C8.44767 17.9998 7.99996 17.5521 7.99996 16.9998V13.9229C7.99996 13.3707 8.44767 12.9229 8.99996 12.9229Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63605 2.63602C3.02658 2.24549 3.65974 2.2455 4.05026 2.63602L6.22595 4.81172C6.61647 5.20224 6.61647 5.83541 6.22594 6.22593C5.83542 6.61645 5.20225 6.61645 4.81173 6.22592L2.63605 4.05023C2.24552 3.6597 2.24553 3.02654 2.63605 2.63602Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.774 11.7739C12.1645 11.3833 12.7976 11.3833 13.1882 11.7738L15.3639 13.9495C15.7544 14.34 15.7544 14.9732 15.3639 15.3637C14.9734 15.7542 14.3402 15.7543 13.9497 15.3637L11.774 13.1881C11.3835 12.7976 11.3834 12.1644 11.774 11.7739Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.99993C0 8.44764 0.447715 7.99993 1 7.99993H4.07689C4.62918 7.99993 5.07689 8.44764 5.07689 8.99993C5.07689 9.55221 4.62918 9.99993 4.07689 9.99993H1C0.447715 9.99993 0 9.55221 0 8.99993Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9232 9.00006C12.9232 8.44778 13.3709 8.00006 13.9232 8.00006H17.0001C17.5523 8.00006 18.0001 8.44778 18.0001 9.00006C18.0001 9.55235 17.5523 10.0001 17.0001 10.0001H13.9232C13.3709 10.0001 12.9232 9.55235 12.9232 9.00006Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22594 11.7739C6.61647 12.1644 6.61647 12.7976 6.22595 13.1881L4.05027 15.3638C3.65975 15.7544 3.02658 15.7544 2.63605 15.3638C2.24553 14.9733 2.24552 14.3402 2.63605 13.9496L4.81173 11.7739C5.20225 11.3834 5.83541 11.3834 6.22594 11.7739Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3641 2.63595C15.7546 3.02648 15.7546 3.65964 15.364 4.05016L13.1883 6.22586C12.7978 6.61638 12.1646 6.61637 11.7741 6.22584C11.3836 5.83531 11.3836 5.20215 11.7741 4.81163L13.9498 2.63593C14.3404 2.24541 14.9735 2.24542 15.3641 2.63595Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4362_42884">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WaitUntil;
