import { NewAutoLayoutProvider } from '@/components/_domain/AutomationWorkflowBuilder/context/new-layout-context';
import FlowWithSidebar from '@/components/_domain/AutomationWorkflowBuilder/Flow/FlowWithSidebar';

const AutomationViewEdit = () => (
  <div className="fixed w-full h-full">
    <NewAutoLayoutProvider>
      <FlowWithSidebar />
    </NewAutoLayoutProvider>
  </div>
);

export default AutomationViewEdit;
