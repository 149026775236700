import { Link } from 'react-router-dom';

import Card from "@/components/Card";
import { Typography } from "@/components/Typography";
import { useSupportModalContext } from '@/context/support-modal-context';

const BoostsDisabled = () => {
  const { setIsSupportModalOpen } = useSupportModalContext();

  return (
    <Card id="boosts-disabled-card" className="">
      <div className="space-y-4">
        <Typography colorWeight="700" weight="medium" as="div">
          Access to Boosts has been disabled for this account. This is a system-generated event and could be due to:
        </Typography>
        <Typography colorWeight="600" as="div">
          <ul className="list-disc list-inside">
            <li>
              A regularly scheduled review determined a violation of the{' '}
              <Link
                to="https://www.beehiiv.com/tou"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="text-primary-500 hover:underline hover:text-primary-600"
              >
                beehiiv ToU
              </Link>
            </li>
            <li>Substantiated reports from other users on the platform</li>
            <li>Problematic behaviors from subscribers you have driven to other publications</li>
          </ul>
        </Typography>
        <Typography colorWeight="600" as="p">
          For further details, please{' '}
          <button
            type="button"
            onClick={() => setIsSupportModalOpen(true)}
            className="text-primary-500 hover:underline hover:text-primary-600"
          >
            submit a ticket
          </button>
          {' '}to beehiiv customer support. Our team will review the situation
          to determine whether or not we can reinstate your access to the Boosts Marketplace.
        </Typography>
        <Typography colorWeight="600" as="p">
          We understand that this situation may have arisen unintentionally or without malicious intent. However,
          because the costs associated with such incidents directly impact other beehiiv users, we have removed
          access at this time.
        </Typography>

        <Typography colorWeight="600" as="p">
          We appreciate your understanding.
        </Typography>
      </div>
    </Card>
  );
}

export default BoostsDisabled;
