import { useEmailMessagePreview, useWelcomeEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';

import EmailOverview from '../components/EmailOverview';

interface Props {
  publication: Publication;
}

const Overview = ({ publication }: Props) => {
  const { data: welcomeEmail } = useWelcomeEmail(publication.id);
  const { data: emailMessagePreview, isLoading, isError } = useEmailMessagePreview(publication.id, welcomeEmail?.id);
  const { html } = emailMessagePreview || {};

  return (
    <EmailOverview
      isLoadingPreview={isLoading}
      isErrorPreview={isError}
      subjectLine={welcomeEmail?.subject_line || ''}
      previewText={welcomeEmail?.preview_text || ''}
      html={html}
    />
  );
};

export default Overview;
