import { Editor, Extension, JSONContent } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet, EditorView } from '@tiptap/pm/view';

import { Site } from '@/interfaces/dream_builder/site';
import { WebTheme } from '@/interfaces/web_theme';

import { applyThemeToNode } from '../../utils/applyThemeToNode';

export interface HoverOptions {
  /**
   * The class name that should be added to the focused node.
   * @default 'has-focus'
   * @example 'is-focused'
   */
  className: string;

  /**
   * The mode by which the focused node is determined.
   * - All: All nodes are marked as focused.
   * - Deepest: Only the deepest node is marked as focused.
   * - Shallowest: Only the shallowest node is marked as focused.
   *
   * @default 'all'
   * @example 'deepest'
   * @example 'shallowest'
   */
  mode: 'all' | 'deepest' | 'shallowest';
}

type HoverStorage = {
  hoverTimeout: NodeJS.Timeout | null;
  rect: DOMRect | null;
  node: Element | null;
  pos: number | null;

  primaryTheme: WebTheme | null;
  themeRules: Site['theme_rules'] | null;
};

const handleDropFromInsertPanel = (editor: Editor, view: EditorView, event: DragEvent) => {
  const jsonString = event.dataTransfer?.getData('text/plain');

  try {
    const json = JSON.parse(jsonString || '');

    const pos = view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    })?.pos;

    if (typeof pos === 'number' && pos >= 0 && json.type === 'block' && json.block) {
      const { primaryTheme, themeRules: themeMapping } = editor.storage.hover as HoverStorage;

      if (primaryTheme && themeMapping) {
        applyThemeToNode(json.block as JSONContent, primaryTheme, themeMapping);
      }

      editor.chain().insertContentAt(pos, json.block).focus().run();

      return true;
    }
  } catch {
    // no op
  }

  return false;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    hover: {
      setHoveredNode: (nodePos: number) => ReturnType;
    };
  }
}

export const HoverPluginKey = new PluginKey('hover');

/**
 * This extension allows you to add a class to the focused node.
 * @see https://www.tiptap.dev/api/extensions/focus
 */
export const Hover = Extension.create<HoverOptions, HoverStorage>({
  name: 'hover',

  addOptions() {
    return {
      className: 'dream-hovering',
      mode: 'all',
    };
  },

  addStorage() {
    return {
      hoverTimeout: null,
      rect: null,
      node: null,
      pos: null,
      primaryTheme: null,
      themeRules: null,
    };
  },

  addCommands() {
    return {
      setHoveredNode:
        (nodePos) =>
        ({ commands, view }) => {
          const maybeNodeDom = view.nodeDOM(nodePos) as Element | null;

          if (!maybeNodeDom) return false;

          const nodeDom = maybeNodeDom.firstElementChild;

          if (!nodeDom) return false;

          this.storage.node = nodeDom;
          this.storage.rect = nodeDom.getBoundingClientRect();
          this.storage.pos = nodePos;

          return commands.setMeta(HoverPluginKey, nodePos);
        },
    };
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      new Plugin({
        key: HoverPluginKey,
        state: {
          init() {
            return DecorationSet.empty;
          },
          apply(tr, decorationSet) {
            const pos = tr.getMeta(HoverPluginKey);

            if (pos === undefined) {
              return decorationSet.map(tr.mapping, tr.doc);
            }

            const node = tr.doc.nodeAt(pos)!;

            return DecorationSet.create(tr.doc, [
              Decoration.node(pos, pos + node.nodeSize, {
                class: 'dream-hovered-widget',
                'data-position': pos.toString(),
              }),
            ]);
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
          handleDrop(view, event) {
            return handleDropFromInsertPanel(editor, view, event);
          },
          handleDOMEvents: {
            mouseover: (view, event) => {
              const posDetails = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              });

              if (!posDetails) return;

              editor.commands.setHoveredNode(posDetails.inside);
            },
          },
        },
      }),
    ];
  },
});
