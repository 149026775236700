import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { useDebouncedValue } from '../../../../hooks/useDebouncedValue';
import { searchGiphyGifs } from '../../../../utils/giphy';
import { GiphyGif } from '../../../../utils/giphy.types';

export type GiphySearchHookOptions = {
  query: string;
  offset?: number;
};

const useGiphySearch = ({ query }: GiphySearchHookOptions) => {
  const [offset, setOffset] = useState(0);
  const [gifs, setGifs] = useState<GiphyGif[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const currentPublicationId = useCurrentPublicationId();

  const debouncedSearchValue = useDebouncedValue<string>(query, 750);

  useEffect(() => {
    if (query) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [query]);

  const searchGifs = useCallback(async (searchQuery: string, searchOffset: number) => {
    if (!searchQuery || searchQuery.length < 3) {
      return;
    }

    setLoading(true);
    setError(false);

    try {
      const response = await searchGiphyGifs({
        q: searchQuery,
        offset: searchOffset,
        publication_id: currentPublicationId,
      });
      setGifs((oldGifs) => [...oldGifs, ...response.data]);
      const newOffset = searchOffset + 33;
      setOffset(newOffset);
      setHasMore(response.pagination.total_count > newOffset);
    } catch (e: any) {
      setError(true);
      toast.error(e.message);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    setGifs([]);
    searchGifs(debouncedSearchValue, 0);
  }, [debouncedSearchValue]);

  const fetchNextPage = useCallback(() => {
    if (!hasMore) return;
    searchGifs(debouncedSearchValue, offset);
  }, [hasMore, offset]);

  return {
    gifs,
    offset,
    loading,
    error,
    searchGifs,
    fetchNextPage,
    hasMore,
  };
};

export default useGiphySearch;
