import { CheckCircleIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Option } from '@/interfaces/general';

import DynamicSegmentIcon from './DynamicSegmentIcon';
import ManualSegmentIcon from './ManualSegmentIcon';
import StaticSegmentIcon from './StaticSegmentIcon';

const iconBySegmentType: any = {
  dynamic: DynamicSegmentIcon,
  static: StaticSegmentIcon,
  manual: ManualSegmentIcon,
};

interface Props {
  segmentType: string;
  onSegmentTypeChange: (value: string) => void;
  segmentOptions: Option[];
  segmentDescription: any;
}

const SelectSegment = ({
  segmentType,
  segmentOptions,
  segmentDescription,
  onSegmentTypeChange,
}: React.PropsWithChildren<Props>) => {
  const onSelect: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onSegmentTypeChange((event.currentTarget as HTMLButtonElement).value);
  };

  return (
    <div className="flex flex-col">
      <label htmlFor="segment_type" className="text-sm font-medium text-gray-700 mb-2">
        Segment type *
        <input name="segment_type" type="hidden" value={segmentType} />
      </label>
      <div className="flex flex-col justify-between gap-x-4 gap-y-4 xl:flex-row">
        {segmentOptions.map((segment: Option) => {
          const isSelected = segmentType === segment.value;
          const IconComponent = iconBySegmentType[segment.value];
          return (
            <button
              key={`select-segment-button-${segment.value}`}
              type="button"
              className={cx(
                'relative flex flex-col text-gray-900 text-sm text-center py-6 px-6 justify-items-center items-center rounded-lg w-full border hover:border-primary-500 focus:ring-1 focus:ring-primary-500 focus:border-primary-500',
                isSelected ? 'border-primary-500' : 'border-gray-300'
              )}
              value={segment.value}
              onClick={onSelect}
            >
              {isSelected && (
                <CheckCircleIcon
                  width="26"
                  height="26"
                  className={cx('absolute -right-2 -top-2 bg-white', isSelected && 'text-primary-500')}
                />
              )}
              <IconComponent />
              <p className="capitalize font-semibold mt-4">{segment.value}</p>
              <p className="font-normal leading-5 w-full mt-2 md:w-64">{segmentDescription[segment.value]}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SelectSegment;
