import { SelectOptions } from '@/components/SelectAllCheckbox';
import { Pagination } from '@/interfaces/general';
import { PostPreview } from '@/interfaces/post';
import PostSearch from '@/models/postSearch';
import api from '@/services/swarm';

interface ApiResponse {
  posts: PostPreview[];
  pagination: Pagination;
}

interface ComposedQueryProps {
  audience: string[];
  platform: string[];
}

const composeNewQuery = (selectOptions: SelectOptions[]): ComposedQueryProps => {
  const audience: string[] = [];
  const platform: string[] = [];

  const checked = {
    audience: {
      free: selectOptions.includes(SelectOptions.FREE),
      premium: selectOptions.includes(SelectOptions.PREMIUM),
      both: selectOptions.includes(SelectOptions.BOTH_AUDIENCE),
    },
    platform: {
      web: selectOptions.includes(SelectOptions.WEB),
      email: selectOptions.includes(SelectOptions.EMAIL),
      both: selectOptions.includes(SelectOptions.BOTH_PLATFORM),
    },
  };

  if (checked.audience.free) {
    audience.push(SelectOptions.FREE.toLowerCase());
  }

  if (checked.audience.premium) {
    audience.push(SelectOptions.PREMIUM.toLowerCase());
  }

  if (checked.audience.both) {
    audience.push('both');
  }

  if (checked.platform.web) {
    platform.push(SelectOptions.WEB.toLowerCase());
  }

  if (checked.platform.email) {
    platform.push(SelectOptions.EMAIL.toLowerCase());
  }

  if (checked.platform.both) {
    platform.push('both');
  }

  return {
    audience,
    platform,
  };
};

type UsePostsSelectionProps = {
  selectOptions: SelectOptions[];
  currentPublicationId: string;
  selectAll?: boolean;
  query: PostSearch;
};

type SelectionPosts = {
  audience: string;
  id: string;
  platform: string;
};

export default function usePostsSelection(props: UsePostsSelectionProps): Promise<SelectionPosts[]> {
  const { selectOptions, currentPublicationId, query, selectAll = true } = props;

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newQuery = composeNewQuery(selectOptions);

  let posts = new Promise<PostPreview[]>((resolve) => {
    resolve([]);
  });

  const queryIncludesSelection = (post: SelectionPosts) => {
    if (selectOptions.includes(SelectOptions.ALL)) {
      return true;
    }

    const selectedPlatform = newQuery.platform.length === 0 || newQuery.platform.includes(post.platform);
    const selectedAudience = newQuery.audience.length === 0 || newQuery.audience.includes(post.audience);

    if (selectedPlatform && selectedAudience) {
      return true;
    }

    return false;
  };

  if (selectOptions.includes(SelectOptions.ALL) || selectOptions.length > 0) {
    posts = api
      .get('/posts/selected', {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
          all_results: selectAll,
          ...query.params(),
        },
      })
      .then((res) => res.data)
      .then((data: ApiResponse) => data.posts)
      .then((newPosts) => newPosts.filter(queryIncludesSelection));
  }

  return posts;
}
