import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const useDeleteSplitTest = (postId: String, onError: Function, onSuccess: Function) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    () =>
      api
        .delete(`/posts/${postId}/split_test`, {
          data: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        return queryClient.invalidateQueries(['splitTest', postId]);
      },
      onError: (errPayload: any) => {
        if (onError) {
          onError();
        }
        toast.error(errPayload?.response?.data?.error || "Couldn't create A/B Test");
      },
    }
  );
};

export default useDeleteSplitTest;
