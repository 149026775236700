const getUsernameAndDomainFromEmail = (emailDomain: string) => {
  const splitValues = emailDomain.split('@');
  const isOnlyDomain = splitValues.length === 1;

  if (isOnlyDomain) {
    return {
      username: '',
      domain: splitValues[0],
    };
  }

  return {
    username: splitValues[0],
    domain: splitValues[1],
  };
};

export default getUsernameAndDomainFromEmail;
