export enum PANEL {
  main = 'Main',
  visualSettings = 'VisualSettings',
  visibilitySettings = 'VisibilitySettings',
  anchorSettings = 'AnchorSettings',
  spacings = 'Spacings',
  borders = 'Borders',
}

export default {
  PANEL,
};
