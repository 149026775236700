import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { useOpportunities } from '@/hooks/useAdNetwork/publisher';
import pluralize from '@/utils/pluralize';

const useDismiss = (): [boolean, () => void] => {
  const [dismissed, setDismissed] = useState(true);

  useEffect(() => {
    if (!document.cookie.includes('dismissPendingOpportunitiesBanner=true')) {
      setDismissed(false);
    }
  }, []);

  const handleDismiss = () => {
    const date = new Date();

    // expire in 1 day
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

    document.cookie = `dismissPendingOpportunitiesBanner=true;expires=${date.toUTCString()};path=/`;

    setDismissed(true);
  };

  return [dismissed, handleDismiss];
};

const Banner = () => {
  const { data, isSuccess } = useOpportunities({ filter: 'pending' });

  const [dismissed, handleDismiss] = useDismiss();

  const numOpportunities = data?.pages[0].pagination.total ?? 0;

  if (dismissed || !isSuccess || numOpportunities === 0) return null;

  return (
    <div className="fixed z-20 w-full bottom-0 bg-gray-900">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center">
          <p className="font-medium text-white">
            <span className="inline">
              You have {numOpportunities} pending advertisement{' '}
              {pluralize('opportunity', numOpportunities, 'opportunities', true)} that{' '}
              {pluralize('expires', numOpportunities, 'expire', true)} soon.
            </span>
            <span className="block sm:ml-2 sm:inline-block">
              <Link to="/monetize/ads/pending" className="font-bold text-primary-400 underline" onClick={handleDismiss}>
                View {pluralize('opportunity', numOpportunities, 'opportunities', true)}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </span>
          </p>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
          <button
            type="button"
            className="flex rounded-md p-2 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white"
            onClick={handleDismiss}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
