import { AggregateAnswer } from '@/interfaces/form';

interface Props {
  answers: AggregateAnswer[];
}

const LongText = ({ answers }: Props) => {
  return (
    <div className="max-h-48 overflow-scroll">
      {answers.map((answer) => (
        <div key={answer.id} className="bg-gray-100 text-sm px-3 py-2 mb-2 rounded">
          <p className="truncate">{answer.value}</p>
          {answer.count > 1 && <span className="ml-1">({answer.count} responses)</span>}
        </div>
      ))}
    </div>
  );
};

export default LongText;
