import { useParams } from 'react-router-dom';
import moment from 'moment-mini';

import { Button } from '@/ui/Button';

import { useCampaign, useCampaignAdvertisements } from '../../../../../hooks/useAdNetwork/internal';
import { AdNetworkAdvertisement } from '../../../../../interfaces/ad_network/internal/advertisement';
import { AdNetworkCampaign } from '../../../../../interfaces/ad_network/internal/campaign';
import { List } from '../../../_components/List';
import { LoadingListPage } from '../../../_components/LoadingListPage';
import { PageHeading } from '../../../_components/PageHeading';

interface Props {
  campaign: AdNetworkCampaign;
  advertisements: AdNetworkAdvertisement[];
  hasNextPage: boolean;
  fetchNextPage: () => Promise<any>;
}

const CampaignAdvertisements = ({ campaign, advertisements, hasNextPage, fetchNextPage }: Props) => {
  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{campaign.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Advertisements</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button to="new" variant="primary-inverse" size="xs">
            New advertisement
          </Button>
        </PageHeading.Side>
      </PageHeading>
      <List hasNextPage={hasNextPage} fetchNextPage={fetchNextPage}>
        {advertisements.map((advertisement) => (
          <List.Item key={advertisement.id} to={`/ad_network/advertisements/${advertisement.id}`}>
            <List.Item.Text variant="primary">{advertisement.name}</List.Item.Text>
            <List.Item.Text variant="secondary">
              <time dateTime={advertisement.created_at}>Created {moment(advertisement.created_at).fromNow()}</time>
              <span>&middot;</span>
              <time dateTime={advertisement.updated_at}>Updated {moment(advertisement.updated_at).fromNow()}</time>
            </List.Item.Text>
          </List.Item>
        ))}
      </List>
    </>
  );
};

export default function Loader() {
  const { campaign_id: id } = useParams<'campaign_id'>() as { campaign_id: string };

  const campaignQuery = useCampaign({ id });
  const advertisementsQuery = useCampaignAdvertisements({ campaignId: id });
  const isSuccess = campaignQuery.isSuccess && advertisementsQuery.isSuccess;
  const isError = campaignQuery.isError || advertisementsQuery.isError;
  const isLoading = !isError && (campaignQuery.isLoading || advertisementsQuery.isLoading);

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const { data: campaign } = campaignQuery;
  const advertisements = advertisementsQuery.data.pages.flatMap((page) => page.advertisements) ?? [];
  const { hasNextPage, fetchNextPage } = advertisementsQuery;

  return (
    <CampaignAdvertisements
      campaign={campaign}
      advertisements={advertisements}
      hasNextPage={!!hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
}
