import cx from 'classnames';

import { Filter } from './types';

interface Props {
  filter: Filter;
  onSelectFilter: (filter: Filter) => void;
  highlighted: boolean;
  onHighlight: () => void;
}

const FilterSelectButton = ({ filter, onSelectFilter, highlighted, onHighlight }: Props) => {
  return (
    <button
      type="button"
      className={cx(
        'focus:outline-none focus:bg-gray-100 rounded-md p-2 block w-full text-left',
        highlighted ? 'bg-gray-100' : 'bg-white'
      )}
      key={filter.label}
      onClick={() => {
        onSelectFilter(filter);
      }}
      onMouseEnter={onHighlight}
    >
      {filter.label}
    </button>
  );
};

export default FilterSelectButton;
