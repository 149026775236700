import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { usePublication } from '@/hooks/usePublications';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import TwoColumnPageContainer from '@/routes/settings/_components/TwoColumnPageContainer';
import { Card } from '@/ui/Card';

import DeleteAccountForm from './DeleteAccountForm';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';
import TwoFactorSection from './TwoFactorSection';

const AccountSecuritySettings = () => {
  const { settings } = useSettings();
  const { currentUser } = useCurrentUser();
  const { data: primaryPublication, isLoading: isLoadingPrimaryPublication } = usePublication(
    currentUser?.primary_publication_id || '',
    !!currentUser?.primary_publication_id
  );

  return (
    <TwoColumnPageContainer
      rhsColumn={
        <SkeletonLoader
          isLoading={isLoadingPrimaryPublication}
          skeletons={
            <div className="space-y-6 w-full">
              <div>
                <Skeleton className="h-8 rounded" />
              </div>
              <div>
                <Skeleton className="h-8 rounded" />
              </div>
              <div>
                <Skeleton className="h-8 rounded" />
              </div>
            </div>
          }
        >
          <Card>
            {primaryPublication && (
              <DeleteAccountForm
                publicationId={primaryPublication.id}
                organizationId={primaryPublication.organization_id}
              />
            )}
          </Card>
        </SkeletonLoader>
      }
    >
      <Card>
        <BodyContainer>
          <CardHeader title="Account Security" description="Protect and manage your account with these key settings" />
          {settings?.two_factor_auth ? <TwoFactorSection /> : null}
          {currentUser ? <EmailForm value={currentUser.email} userId={currentUser.id} /> : null}
        </BodyContainer>
      </Card>
      <Card>
        <PasswordForm />
      </Card>
    </TwoColumnPageContainer>
  );
};

export default AccountSecuritySettings;
