import { useCallback } from 'react';
import { Editor } from '@tiptap/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { PanelHeadline, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ToolbarProps } from '@/components/TiptapEditor/components/ui/Toolbar/types';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';
import { IconName } from '@/components/TiptapEditor/lib/types';

import Styled from './styled';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: true,
};

type Action = {
  align: 'left' | 'center' | 'right';
  iconName: IconName;
};

const actions: Array<Action> = [
  {
    align: 'left',
    iconName: 'AlignImageLeft',
  },
  {
    align: 'center',
    iconName: 'AlignImageCenter',
  },
  {
    align: 'right',
    iconName: 'AlignImageRight',
  },
];

const AlignmentButton = ({ editor, action }: { editor: Editor; action: Action }) => {
  const { align, iconName } = action;
  const isActive = editor.isActive('imageBlock', { align });

  const onClick = useCallback(() => {
    editor.commands.setImageBlockAlign(align);
  }, [align, editor]);

  return (
    <Tooltip title={`Align ${align}`}>
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
        $active={isActive}
        $muted={align === 'center'}
        onClick={onClick}
        $leftSlot={<Icon name={iconName} />}
      />
    </Tooltip>
  );
};

export const ImageAlignment = ({ editor }: ToolbarProps) => {
  if (!editor) {
    return null;
  }

  return (
    <PanelSection>
      <PanelHeadline>Image alignment</PanelHeadline>
      <Styled.ButtonWrapper>
        {actions.map((action) => (
          <AlignmentButton key={action.align} editor={editor} action={action} />
        ))}
      </Styled.ButtonWrapper>
    </PanelSection>
  );
};

export default ImageAlignment;
