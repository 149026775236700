import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { AdNetworkAdvertiserPublisherListItem, AdNetworkAdvertiserPublisherListItemReason } from '@/interfaces/ad_network/advertiser_access/advertiser_publisher_list_item';
import { MutationErrorResponse } from '@/interfaces/ad_network/advertiser_access/mutation_error_response';
import api from '@/services/swarm';

interface Variables {
  advertiserId: string;
  publisherId: string;
  publisherListItemId: string;
  listType: string;
}

export default function usePublisherListItemUpdate() {
  const updateAdvertiserPublisherListItem = ({
    advertiserId,
    publisherId,
    publisherListItemId,
    listType,
  }: Variables): Promise<AdNetworkAdvertiserPublisherListItem> =>
    api
      .patch(`/ad_network/advertiser_access/${advertiserId}/publisher_list_items/${publisherListItemId}`, {
        list_type: listType,
        update_type: "update",
        publisher_list_item: {
          reasons: listType === "preferred" ? [AdNetworkAdvertiserPublisherListItemReason.ADVERTISER_FAVORITE] : [AdNetworkAdvertiserPublisherListItemReason.ADVERTISER_EXCLUSION],
          publisher_id: publisherId,
        },
      })
      .then((res: { data: AdNetworkAdvertiserPublisherListItem }) => res.data);

  return useMutation<AdNetworkAdvertiserPublisherListItem, MutationErrorResponse, Variables>(
    updateAdvertiserPublisherListItem,
    {
      onError: (error) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      },
    }
  );
}
