import useStripeConnectAccount from '@/hooks/boosts/monetize/useStripeConnectAccount';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import PageContainer from '@/routes/settings/_components/PageContainer';

import { SettingsPageLayout } from '../../Components';

import Disconnect from './components/Disconnect';
import GeneralInfo from './components/GeneralInfo';

interface Props {
  isV2?: boolean;
}

const PaymentAccounts = ({ isV2 = false }: Props) => {
  const currentPublicationId = useCurrentPublicationId();
  const {
    data: stripeConnectAccount,
    isLoading: isStripeConnectAccountLoading,
    isError: isStripeConnectAccountError,
  } = useStripeConnectAccount(currentPublicationId);
  const { boosts_account_status: boostsAccountStatus, boosts_account_type: boostsAccountType } =
    stripeConnectAccount || {};

  if (isV2) {
    return (
      <PageContainer>
        <GeneralInfo
          publicationId={currentPublicationId}
          boostsAccountStatus={boostsAccountStatus}
          boostsAccountType={boostsAccountType}
          isLoading={isStripeConnectAccountLoading}
          isError={isStripeConnectAccountError}
        />
        <Disconnect boostsAccountType={boostsAccountType} isLoading={isStripeConnectAccountLoading} />
      </PageContainer>
    );
  }

  // Each component controls if it should be rendered.
  return (
    <SettingsPageLayout title="Payment Accounts">
      <div className="flex flex-row w-full justify-center">
        <PageContainer>
          <GeneralInfo
            publicationId={currentPublicationId}
            boostsAccountStatus={boostsAccountStatus}
            boostsAccountType={boostsAccountType}
            isLoading={isStripeConnectAccountLoading}
            isError={isStripeConnectAccountError}
          />
          <Disconnect boostsAccountType={boostsAccountType} isLoading={isStripeConnectAccountLoading} />
        </PageContainer>
      </div>
    </SettingsPageLayout>
  );
};

export default PaymentAccounts;
