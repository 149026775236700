import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Switch from '@/ui/Switch';

import ActionModal from '../../../../../../components/ActionModal';
import { useCurrentPublication, useUpdateWebTemplate } from '../../../../../../hooks';
import { SignupFlowItem, WebTemplate } from '../../../../../../interfaces/web_template';

import FlowStep from './FlowStep';
import { UpdateMessage, useUpdateMessage } from './UpdateMessage';

interface Props {
  step: SignupFlowItem;
  webTemplate: WebTemplate;
  isWebBuilder?: boolean;
}

const PublicationStep = ({ step, webTemplate, isWebBuilder }: Props) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const webTemplateMutation = useUpdateWebTemplate();
  const hasPubIds = webTemplate?.multi_pub_ids?.length > 0;
  const signupMessaging = webTemplate?.signup_flow_messaging;

  const {
    isOpen: isMessageModalOpen,
    message,
    handleClose: handleCloseMessageModal,
    handleOpen: handleOpenMessageModal,
    onChange,
  } = useUpdateMessage({ message: signupMessaging?.multi_pubs_message || '' });

  const handleUpdateMessage = async () => {
    await webTemplateMutation.mutateAsync({
      signup_flow_messaging: JSON.stringify({
        ...signupMessaging,
        multi_pubs_message: message,
      }),
    });

    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
    handleCloseMessageModal();
  };

  const handleEnableStep = async (val: boolean) => {
    await webTemplateMutation.mutateAsync({ enable_multi_pub_post_signup: val });
    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
  };

  const removeRecommendations = () => {
    navigate('/recommendations');
  };

  return (
    <>
      {/* Disable Action Modal */}
      <ActionModal
        isOpen={isDisableModalOpen}
        onClose={() => setIsDisableModalOpen(false)}
        resourceId=""
        headerText={step.modal_header}
        onProceed={removeRecommendations}
        isWorking={false}
        actionText={step.action_text}
      >
        <div className="text-sm space-y-4">{step.modal_body}</div>
      </ActionModal>

      {/* Enable Action Modal */}
      <ActionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        resourceId=""
        headerText="Add some publications first!"
        onProceed={() => {
          if (isWebBuilder) {
            navigate('/website_builder/settings/static_pages');
          } else {
            setIsModalOpen(false);
          }
        }}
        isWorking={false}
        actionText="Let's do it"
      >
        <div className="text-sm space-y-4">
          {isWebBuilder ? (
            <p>Please see the Multi-publications section under static pages</p>
          ) : (
            <p>To add publications to this page, please see the multi-publications section below.</p>
          )}
        </div>
      </ActionModal>

      {/** Custom Page Message Modal */}
      <UpdateMessage
        isOpen={isMessageModalOpen}
        onClose={handleCloseMessageModal}
        isLoading={false}
        message={message}
        onProceed={handleUpdateMessage}
        name={step?.title?.split(' ')?.join('_')?.toLowerCase()}
        onChange={onChange}
      />

      <FlowStep title={step.title} url={step.full_url} enabled={step.enabled} onUpdateMessage={handleOpenMessageModal}>
        <Switch
          name="publications"
          checked={step.enabled}
          onChange={() => {
            if (!hasPubIds) return setIsModalOpen(true);
            if (step.enabled) return handleEnableStep(false);

            return handleEnableStep(true);
          }}
          variant="primary"
        />
      </FlowStep>
    </>
  );
};

export default PublicationStep;
