import { useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Input } from '../../../components/Form';

import NotEditing from './NotEditing';
import SaveButton from './SaveButton';

interface Props {
  name?: string;
  label: string;
  value: string;
  helperText?: string;
  placeholder?: string;
  onSave: (val: string) => Promise<any>;
  validationCheck?: (val: string) => boolean;
  isSaving: boolean;
  validationFailMessage?: string;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
  noBorder?: boolean;
}

const InputForm = ({
  name,
  label,
  value,
  onSave,
  isSaving,
  helperText,
  placeholder,
  validationFailMessage,
  toastSuccessMessage,
  toastErrorMessage,
  validationCheck,
  noBorder = false,
}: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const showSaving = isSaving && isEditing;

  const handleSave = async () => {
    // Check for validation if one is provided
    if (validationCheck) {
      if (!validationCheck(inputValue)) {
        toast.error(validationFailMessage || 'Something went wrong');
        return;
      }
    }

    if (inputValue !== value) {
      await onSave(inputValue)
        .then(() => {
          if (toastSuccessMessage) {
            toast.success(toastSuccessMessage);
          }
          setIsEditing(false);
        })
        .catch(() => {
          if (toastErrorMessage) {
            toast.error(toastErrorMessage);
          }
        });
    } else {
      setIsEditing(false);
    }
  };

  if (isEditing) {
    return (
      <div
        className={cx(
          'flex justify-between border-gray-100 pb-4 space-x-6',
          noBorder ? '' : 'border-b last:border-b-0'
        )}
      >
        <Input
          className="w-full max-w-md"
          name={name || label.split(' ').join('-')}
          labelText={label}
          helperText={helperText}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <SaveButton
          isSaving={showSaving}
          closeEditing={() => setIsEditing(false)}
          isEditing={isEditing}
          changesDetected={Boolean(inputValue !== value)}
          type="button"
          onClick={handleSave}
        />
      </div>
    );
  }

  return (
    <div className={cx('flex justify-between border-gray-100 pb-4', noBorder ? '' : 'border-b last:border-b-0')}>
      <NotEditing label={label} value={inputValue} helperText={helperText} />

      <div className="pt-4">
        <button
          className="h-6 w-6 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
          type="button"
          onClick={() => setIsEditing(true)}
        >
          <PencilIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};

export default InputForm;
