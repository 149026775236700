import { useState } from 'react';
import toast from 'react-hot-toast';

import Modal from '@/components/Modal';
import useTransitionRecommendation from '@/hooks/useRecommendations/useTransitionRecommendation';
import { Recommendation, RecommendationStatus } from '@/interfaces/recommendation';
import analytics from '@/utils/analytics';

import Row from '../../components/Row';

import Form from './Form';

interface Props {
  recommendation: Recommendation;
  refetch: () => void;
}

export default function FormRow({ recommendation, refetch }: Props) {
  const { id: recommendationId, status } = recommendation;
  const [isEditing, setIsEditing] = useState(false);

  const transitionMutation = useTransitionRecommendation({
    onSuccess: (successData: any) => {
      toast.success(`Recommendation is now ${successData.status === 'blocked' ? 'blocked' : 'unblocked'}`);
      refetch();
    },
  });

  const handleTransition = (newStatus: string) => {
    analytics.track('Removed Recommendation');
    transitionMutation.mutateAsync({ recommendationId, newStatus });
  };

  const options = [
    [
      {
        label: 'Edit reason',
        onClick: () => setIsEditing(true),
      },
      ...(status === RecommendationStatus.ACTIVE
        ? [{ label: 'Make inactive', onClick: () => handleTransition('inactive') }]
        : [{ label: 'Make active', onClick: () => handleTransition('active') }]),
    ],
    [
      {
        label: 'Remove',
        onClick: () => handleTransition('archived'),
      },
    ],
  ];

  const onSave = () => {
    setIsEditing(false);
    refetch();
  };

  return (
    <>
      <Row
        recommendation={recommendation}
        publicationType="recommended_publication"
        options={options}
        verb="Referred"
      />
      <Modal isOpen={isEditing} onClose={() => setIsEditing(false)} className="w-full max-w-xl">
        <Form recommendation={recommendation} onCancel={() => setIsEditing(false)} onSave={onSave} />
      </Modal>
    </>
  );
}
