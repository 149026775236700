import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import Text from '@/components/Text';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSegment } from '@/hooks';
import api from '@/services/swarm';

interface DeleteSegmentModalProps {
  isOpen: boolean;
  segmentId: string;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteSegmentModal = ({ isOpen, segmentId, onClose, onDelete }: DeleteSegmentModalProps) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const [isDeleting, setIsDeleting] = useState(false);
  const { data: segment } = useSegment({ segmentId });

  const onDeleteSegment = () => {
    setIsDeleting(true);
    api
      .delete(`/segments/${segmentId}`, { params: { publication_id: currentPublicationId } })
      .then(() => {
        toast.success('Segment deleted successfully');
        onDelete();
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      onProceed={onDeleteSegment}
      resourceId={segmentId || ''}
      isWorking={isDeleting}
      headerText="Delete Segment"
      actionText="Delete"
    >
      <Text>Are you sure you want to delete this segment?</Text>

      {segment?.has_post_send_targets && (
        <Text>
          This segment has been used in at least one post send. Deleting it will not effect your ability to manage those
          posts.
        </Text>
      )}
    </ActionModal>
  );
};

export default DeleteSegmentModal;
