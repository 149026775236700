import DmarcWarningBanner from '@/components/DmarcWarningBanner';
import { useCustomDomains } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { CustomDomain } from '@/interfaces/custom_domain';

const ConfirmEmailVerification = () => {
  const publicationId = useCurrentPublicationId();
  const { data: customDomains } = useCustomDomains(publicationId);
  const emailDomain = customDomains?.find((d: CustomDomain) => d.email_enabled);
  const { data: currentPublication } = usePublication(publicationId);

  if (!emailDomain || !currentPublication) return null;

  return <DmarcWarningBanner publicationId={publicationId} domain={emailDomain.domain} variant="full" />;
};

export default ConfirmEmailVerification;
