import { FC } from 'react';

import Card from '@/components/Card';
import KPI from '@/components/KPI';
import KPIGroup from '@/components/KPIGroup';

import KpisContainer from './KpisContainer';

const KPIS: FC = () => {
  return (
    <KpisContainer>
      {(kpiData, isLoading) => (
        <Card className="p-0">
          <KPIGroup>
            <KPI
              title="Subscribers with referrals"
              value={kpiData?.total_subscribers_with_referrals || 0}
              previousValue={kpiData?.lag_total_subscribers_with_referrals || 0}
              pctDelta={kpiData?.total_subscribers_with_referrals_delta || 0}
              isLoading={isLoading}
            />
            <KPI
              title="Total readers referred"
              value={kpiData?.total_referrals || 0}
              previousValue={kpiData?.lag_total_referrals || 0}
              pctDelta={kpiData?.total_referrals_delta || 0}
              isLoading={isLoading}
            />
            <KPI
              title="Referrals per subscriber"
              tooltipText="Only includes subscribers with at least one referral"
              value={kpiData?.avg_referrals_per_subscriber || 0}
              previousValue={kpiData?.lag_avg_referrals_per_subscriber || 0}
              pctDelta={kpiData?.avg_referrals_per_subscriber_delta || 0}
              isLoading={isLoading}
            />
          </KPIGroup>
        </Card>
      )}
    </KpisContainer>
  );
};

export default KPIS;
