import { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

export type StatusType = 'saving' | 'saved' | 'idle';

interface Props {
  status: StatusType;
}

interface SavingIndicatorReturn {
  status: StatusType;
  setIsSaving: (isSaving: boolean) => void;
  handleSaved: () => void;
}

const useSavingIndicator = (): SavingIndicatorReturn => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const handleSaved = () => {
    setIsSaved(true);
    setTimeout(() => {
      setIsSaving(false);
      setIsSaved(false);
    }, 1000);
  };

  const setStatus = () => {
    if (isSaved) return 'saved';
    if (isSaving) return 'saving';
    return 'idle';
  };

  return {
    status: setStatus(),
    setIsSaving,
    handleSaved,
  };
};

const SavingIndicator = ({ status }: Props) => {
  switch (status) {
    case 'saving':
      return (
        <div className="flex items-center space-x-1.5">
          <div className="h-2 w-2 rounded-full bg-yellow-500 animate-pulse" />
          <span className="text-xs text-gray-600">Saving...</span>
        </div>
      );

    case 'saved' || 'idle':
      return (
        <div className="flex items-center space-x-1.5">
          <CheckIcon className="w-4 h-4 text-green-600" />
          <span className="text-xs text-gray-600">Saved!</span>
        </div>
      );
    default:
      return null;
  }
};

export { SavingIndicator, useSavingIndicator };
