import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import Card from '@/components/Card';
import { useSettings } from '@/context/settings-context';
import { Badge } from '@/ui/Badge';
import { Textarea } from '@/ui/Textarea';

import ActionModal from '../../../../components/ActionModal';
import { RadioSelect } from '../../../../components/Form';
import { useResolveCommentReports } from '../../../../hooks/useComments';
import { Comment } from '../../../../interfaces/comment';
import CommentDisplay from '../../Comments/Comment/CommentDisplay';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  comment: Comment;
  postId?: string;
}

const options = [
  { name: 'Ignore report(s)', value: 'ignore' },
  { name: 'Remove comment', value: 'remove' },
  { name: 'Ban subscriber from commenting + remove comment', value: 'ban' },
];

const banLevels = [
  { name: 'Just this post', value: 'post' },
  { name: 'All posts', value: 'publication' },
];

const ReportActionModal = ({ isOpen, onClose, comment, postId, onSuccess }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const queryClient = useQueryClient();
  const [selection, setSelection] = useState<any>(options[0]);
  const [banLevelSelection, setBanLevelSelection] = useState<any>(banLevels[0]);
  const [note, setNote] = useState('');
  const isBanSelected = selection === 'ban';

  const handleSelection = (val: any) => {
    setSelection(val);
  };

  const handleBanLevelSelection = (val: any) => {
    setBanLevelSelection(val);
  };

  const resolveCommentReports = useResolveCommentReports({
    config: {
      postId: postId || '',
      commentReportId: comment?.id,
    },
    onSuccess: () => {
      toast.success('Removing all associated reports with this comment, try refreshing in a minute.');
      queryClient.invalidateQueries(['comment_reports', comment.id]);
      onSuccess();
      onClose();
    },
  });

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      resourceId=""
      headerText="Resolve Comment Report"
      descriptionText={isV2 ? 'Select an action to take and leave a note for other admins.' : undefined}
      modalMessageType={isV2 ? 'warning' : undefined}
      onProceed={() =>
        resolveCommentReports.mutate({
          resolution_note: note,
          resolution_type: selection,
          ban_level: isBanSelected ? banLevelSelection : null,
        })
      }
      isWorking={resolveCommentReports.isLoading}
    >
      <div className="space-y-4">
        <div className="space-y-2">
          {comment && (
            <div className="space-y-1">
              {isV2 ? (
                <Card background="bg-surface-50" className="mt-10">
                  <div className="flex flex-col gap-y-2">
                    <Badge type="warning" className="w-fit">
                      Reported Comment
                    </Badge>
                    <CommentDisplay
                      avatarUrl={comment.avatar_url}
                      commenter={comment.commenter}
                      time={comment.created_at}
                      content={comment.content}
                    />
                  </div>
                </Card>
              ) : (
                <CommentDisplay
                  avatarUrl={comment.avatar_url}
                  commenter={comment.commenter}
                  time={comment.created_at}
                  content={comment.content}
                />
              )}
            </div>
          )}
        </div>
        <RadioSelect
          labelText="Select action"
          size="md"
          value={selection}
          options={options}
          onSelect={handleSelection}
        />
        {isBanSelected && (
          <RadioSelect
            labelText="Select ban level"
            size="md"
            value={banLevelSelection}
            options={banLevels}
            onSelect={handleBanLevelSelection}
          />
        )}
        <Textarea
          name="additional_emails"
          labelText="Leave a note"
          helperText="This can be helpful for other admins to understand why you took this action."
          placeholderText='e.g. "This comment was reported by multiple users.'
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
    </ActionModal>
  );
};

export default ReportActionModal;
