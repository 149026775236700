import { useCallback, useState } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { convertToSection, nodeIsSection } from '../../../utils';

const useVisualSettingsPanel = (
  editor: Editor,
  currentNode: Node | null,
  currentNodePos: number,
  navigateToPanel: Function
) => {
  const isSection = nodeIsSection(currentNode);

  const [isCustomBackgroundColorPanelOpen, setIsCustomBackgroundColorPanelOpen] = useState<boolean>(false);
  const [isCustomBorderColorPanelOpen, setIsCustomBorderColorPanelOpen] = useState<boolean>(false);
  const [isCustomTextColorPanelOpen, setIsCustomTextColorPanelOpen] = useState<boolean>(false);

  const navigateToSpacings = useCallback(() => {
    navigateToPanel('Spacings');
  }, [navigateToPanel]);

  const navigateToBorders = useCallback(() => {
    navigateToPanel('Borders');
  }, [navigateToPanel]);

  // Show panels
  // Custom background color
  const onShowCustomBackgroundColorPanel = useCallback(() => {
    setIsCustomBackgroundColorPanelOpen(true);
  }, []);

  const onCloseCustomBackgroundColorPanel = useCallback(() => {
    setIsCustomBackgroundColorPanelOpen(false);
  }, []);

  // Custom border color
  const onShowCustomBorderColorPanel = useCallback(() => {
    setIsCustomBorderColorPanelOpen(true);
  }, []);

  const onCloseCustomBorderColorPanel = useCallback(() => {
    setIsCustomBorderColorPanelOpen(false);
  }, []);

  // Custom text color
  const onShowCustomTextColorPanel = useCallback(() => {
    setIsCustomTextColorPanelOpen(true);
  }, []);

  const onCloseCustomTextColorPanel = useCallback(() => {
    setIsCustomTextColorPanelOpen(false);
  }, []);

  // Set commands
  // Background color
  const onSetBackgroundColor = useCallback(
    (color: string) => {
      convertToSection(editor, currentNode, currentNodePos);

      editor.commands.setSectionBackgroundColor(color);
    },
    [editor, currentNode, currentNodePos]
  );

  // Custom background color
  const onSetCustomBackgroundColor = useCallback(
    (color: string) => {
      convertToSection(editor, currentNode, currentNodePos);

      editor.commands.setSectionBackgroundColor(color);
    },
    [editor, currentNode, currentNodePos]
  );

  // Border color
  const onSetBorderColor = useCallback(
    (color: string) => {
      convertToSection(editor, currentNode, currentNodePos);

      editor.commands.setSectionBorderColor(color);
    },
    [editor, currentNode, currentNodePos]
  );

  // Custom background color
  const onSetCustomBorderColor = useCallback(
    (color: string) => {
      convertToSection(editor, currentNode, currentNodePos);

      editor.commands.setSectionBorderColor(color);
    },
    [editor, currentNode, currentNodePos]
  );

  // Text color
  const onSetTextColor = useCallback(
    (color: string) => {
      convertToSection(editor, currentNode, currentNodePos);

      editor.commands.setSectionTextColor(color);
    },
    [editor, currentNode, currentNodePos]
  );

  // Custom text color
  const onSetCustomTextColor = useCallback(
    (color: string) => {
      convertToSection(editor, currentNode, currentNodePos);

      editor.commands.setSectionTextColor(color);
    },
    [editor, currentNode, currentNodePos]
  );

  // Padding
  const onSetPadding = useCallback(
    (padding: { top: number; bottom: number; right: number; left: number }) => {
      convertToSection(editor, currentNode, currentNodePos);

      const useIndividualPadding = currentNode?.attrs.useIndividualPadding;

      if (!useIndividualPadding) {
        editor.commands.setSectionPadding(padding.top);
      } else {
        editor
          .chain()
          .setSectionTopPadding(padding.top)
          .setSectionBottomPadding(padding.bottom)
          .setSectionRightPadding(padding.right)
          .setSectionLeftPadding(padding.left)
          .run();
      }
    },
    [editor, currentNode, currentNodePos]
  );

  // Margin
  const onSetMargin = useCallback(
    (margin: { top: number; bottom: number; left: number; right: number }) => {
      convertToSection(editor, currentNode, currentNodePos);

      const useIndividualMargin = currentNode?.attrs.useIndividualMargin;

      if (!useIndividualMargin) {
        editor.commands.setSectionMargin(margin.top);
      } else {
        editor
          .chain()
          .setSectionTopMargin(margin.top)
          .setSectionBottomMargin(margin.bottom)
          .setSectionLeftMargin(margin.left)
          .setSectionRightMargin(margin.right)
          .run();
      }
    },
    [editor, currentNode, currentNodePos]
  );

  // Border width
  const onSetBorderWidth = useCallback(
    (borderWidth: { top: number; bottom: number; right: number; left: number }) => {
      convertToSection(editor, currentNode, currentNodePos);

      const useIndividualBorderWidth = currentNode?.attrs.useIndividualBorderWidth;

      if (!useIndividualBorderWidth) {
        editor.commands.setSectionBorderWidth(borderWidth.top);
      } else {
        editor
          .chain()
          .setSectionTopBorderWidth(borderWidth.top)
          .setSectionBottomBorderWidth(borderWidth.bottom)
          .setSectionRightBorderWidth(borderWidth.right)
          .setSectionLeftBorderWidth(borderWidth.left)
          .run();
      }
    },
    [editor, currentNode, currentNodePos]
  );

  // Border radius
  const onSetBorderRadius = useCallback(
    (borderRadius: { topLeft: number; bottomRight: number; topRight: number; bottomLeft: number }) => {
      convertToSection(editor, currentNode, currentNodePos);

      const useIndividualBorderRadius = currentNode?.attrs.useIndividualBorderRadius;

      if (!useIndividualBorderRadius) {
        editor.commands.setSectionBorderRadius(borderRadius.topLeft);
      } else {
        editor
          .chain()
          .setSectionTopLeftBorderRadius(borderRadius.topLeft)
          .setSectionBottomRightBorderRadius(borderRadius.bottomRight)
          .setSectionTopRightBorderRadius(borderRadius.topRight)
          .setSectionBottomLeftBorderRadius(borderRadius.bottomLeft)
          .run();
      }
    },
    [editor, currentNode, currentNodePos]
  );

  // Reset commands
  // Background color
  const onResetBackgroundColor = useCallback(() => {
    editor.chain().setNodeSelection(currentNodePos).setSectionBackgroundColor(null).run();
  }, [editor, currentNodePos]);

  // Border color
  const onResetBorderColor = useCallback(() => {
    editor.chain().setNodeSelection(currentNodePos).setSectionBorderColor(null).run();
  }, [editor, currentNodePos]);

  // Text color
  const onResetTextColor = useCallback(() => {
    editor.chain().setNodeSelection(currentNodePos).setSectionTextColor(null).run();
  }, [editor, currentNodePos]);

  // Borders
  const onResetBorders = useCallback(() => {
    editor
      .chain()
      .setNodeSelection(currentNodePos)
      .setIndividualSectionBorderRadius(false)
      .setSectionBorderRadius(0)
      .setIndividualSectionBorderWidth(false)
      .setSectionBorderWidth(0)
      .setSectionBorderColor(null)
      .setSectionBorderStyle('solid')
      .run();
  }, [editor, currentNodePos]);

  // Spacings
  const onResetSpacings = useCallback(() => {
    editor
      .chain()
      .setNodeSelection(currentNodePos)
      .setIndividualSectionPadding(false)
      .setSectionPadding(0)
      .setIndividualSectionMargin(false)
      .setSectionMargin(0)
      .run();
  }, [editor, currentNodePos]);

  // All
  const onResetAllVisualSettings = useCallback(() => {
    editor
      .chain()
      .setNodeSelection(currentNodePos)
      .setSectionBackgroundColor(null)
      .setSectionTextColor(null)
      .setIndividualSectionPadding(false)
      .setSectionPadding(0) // TODO: Check default (theme?)
      .setIndividualSectionMargin(false)
      .setSectionMargin(0)
      .setIndividualSectionBorderRadius(false)
      .setSectionBorderWidth(0)
      .setIndividualSectionBorderWidth(false)
      .setSectionBorderRadius(0)
      .setSectionBorderColor(null)
      .setSectionBorderStyle('solid')
      .run();
  }, [editor, currentNodePos]);

  return {
    isSection,
    isCustomBackgroundColorPanelOpen,
    isCustomBorderColorPanelOpen,
    isCustomTextColorPanelOpen,
    navigateToSpacings,
    navigateToBorders,
    onShowCustomBackgroundColorPanel,
    onCloseCustomBackgroundColorPanel,
    onShowCustomBorderColorPanel,
    onCloseCustomBorderColorPanel,
    onShowCustomTextColorPanel,
    onCloseCustomTextColorPanel,
    onSetBackgroundColor,
    onSetBorderColor,
    onSetCustomBackgroundColor,
    onSetCustomBorderColor,
    onSetTextColor,
    onSetCustomTextColor,
    onSetPadding,
    onSetMargin,
    onSetBorderWidth,
    onSetBorderRadius,
    onResetBackgroundColor,
    onResetBorderColor,
    onResetTextColor,
    onResetBorders,
    onResetSpacings,
    onResetAllVisualSettings,
  };
};

export default useVisualSettingsPanel;
