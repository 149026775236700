import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { UseMutationResult } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { AxiosResponse } from 'axios';
import cx from 'classnames';
import moment from 'moment-mini';

import { Checkbox } from '@/components/Form';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { Avatar } from '@/components/UI/Avatar';
import { useCopyToClipboard } from '@/hooks';
import useResourcePermissions from '@/hooks/useResourcePermissions/useResourcePermissions';
import { WebTemplatePayload } from '@/hooks/useWebTemplates/useUpdateWebTemplate';
import { NO_PERMISSION_MESSAGE, PermissionLevel, PermissionResource } from '@/interfaces/permissions';
import { PostPlatform, PostPreview, PostStatus } from '@/interfaces/post';
import { Publication } from '@/interfaces/publication';
import api from '@/services/swarm';
import { Badge, BadgeType } from '@/ui/Badge';
import { Dropdown } from '@/ui/Dropdown';

import PostStatsOverviewContainer from '../PostStatsOverviewContainer';

import MetricsBadge from './MetricsBadge';

const FEATURED_POST_LIMIT = 6;

interface PostProps {
  post: PostPreview;
  featuredPostsIds: string[];
  publication?: Publication;
  firstItem?: boolean;
  lastItem?: boolean;
  featured?: boolean;
  selected: boolean;
  onSelect: (checked: boolean) => void;
  refetch: () => void;
  handleCreateTemplateFromPost: (post: PostPreview) => void;
  handleGiftLinkForPost: (post: PostPreview) => void;
  handleDeletePost: (post: PostPreview) => void;
  webTemplateMutation: UseMutationResult<AxiosResponse<any, any>, any, WebTemplatePayload, unknown>;
  checkPermissions: (resource: PermissionResource, action: PermissionLevel) => boolean | undefined;
  giftLinkEnabled?: boolean;
  premiumEnabled?: boolean;
  queryParams?: any;
}

const PostContainer = ({
  post,
  featuredPostsIds,
  publication,
  firstItem,
  lastItem,
  featured,
  selected,
  onSelect,
  refetch,
  handleCreateTemplateFromPost,
  handleGiftLinkForPost,
  handleDeletePost,
  webTemplateMutation,
  checkPermissions,
  giftLinkEnabled,
  premiumEnabled,
  queryParams,
}: PostProps) => {
  const { data: recordPermissions, isSuccess: recordPermissionSuccess } = useResourcePermissions({
    resourceType: 'Post',
    resourceId: post.id,
    policyClass: 'Api::V2::PostPolicy',
  });
  const canUpdate = recordPermissionSuccess && recordPermissions.includes('update');
  const canArchive = checkPermissions && checkPermissions('views/posts/archive', 'update');
  const canFeature = checkPermissions && checkPermissions('views/posts/feature', 'update');
  const canGiftLink = checkPermissions && checkPermissions('views/posts/gift_link', 'read');
  const canDelete = recordPermissionSuccess && recordPermissions.includes('delete');
  const copy = useCopyToClipboard();
  const navigate = useNavigate();
  const canShowMetrics = useCallback((newPost: PostPreview) => newPost.status === PostStatus.PUBLISHED, []);

  let postStatus;
  let postDisplayTime;
  let postBadgeType;
  let postBadgeIcon;
  let postBadgeText;

  if (post.status === PostStatus.DRAFT) {
    postStatus = 'Last edited on';
    postDisplayTime = post.updated_at;
    postBadgeType = BadgeType.DEFAULT_LINED;
    postBadgeText = 'Draft';
  } else if (post.status === PostStatus.SCHEDULED) {
    postStatus = 'Scheduled for';
    postDisplayTime = post.scheduled_at;
    postBadgeType = BadgeType.WARNING;
    postBadgeText = 'Scheduled';
  } else if (post.status === PostStatus.PUBLISHED) {
    postStatus = 'Published on';
    postDisplayTime = post.scheduled_at;
    postBadgeType = BadgeType.SUCCESS;
    postBadgeText = 'Published';
  } else if (post.status === PostStatus.ARCHIVED) {
    postStatus = 'archived';
    postDisplayTime = '';
    postBadgeText = 'Archived';
    postBadgeType = BadgeType.DEFAULT_LINED;
    postBadgeIcon = <ArchiveBoxIcon className="w-3.5 h-3.5" />;
  } else {
    postStatus = '';
    postDisplayTime = '';
    postBadgeText = '';
  }

  function toQueryString(params: Record<string, any>): string {
    const queryString = Object.entries(params)
      .flatMap(([key, value]) => {
        if (value === null || value === undefined) {
          // Skip null or undefined values
          return [];
        }
        if (Array.isArray(value)) {
          // Handle arrays
          return value
            .filter((item) => item !== null && item !== undefined) // Remove null or undefined within arrays
            .map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
        }
        if (value instanceof Date) {
          // Handle Date objects
          return `${encodeURIComponent(key)}=${encodeURIComponent(value.toISOString())}`;
        }
        // Handle other types
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');

    return queryString;
  }

  const viewPostDetails = `/posts/${post.id}${queryParams ? `?${toQueryString(queryParams)}` : ''}`;

  const handlePostAction = async (name: string, value: string) => {
    switch (value) {
      case 'view':
        navigate(viewPostDetails);
        break;
      case 'edit':
        navigate(`/posts/${post.id}/edit`);
        break;
      case 'duplicate':
        api
          .post(`/posts/${post.id}/duplicates`)
          .then(() => {
            toast.success('Post duplicated');
            refetch();
          })
          .catch(() => {
            toast.error('Could not duplicate post');
          });
        break;
      case 'save_template':
        handleCreateTemplateFromPost(post);
        break;
      case 'feature_post':
        if (featured) {
          const newFeaturedPosts = featuredPostsIds?.filter((id: any) => id !== post.id);
          await webTemplateMutation.mutate({
            feature_post_ids: newFeaturedPosts,
          });
        } else {
          await webTemplateMutation.mutate({
            feature_post_ids: [...featuredPostsIds, post.id],
          });
        }
        toast.success(featured ? 'Post unfeatured' : 'Post featured');
        break;
      case 'view_post':
        window.open(post.url, '_blank');
        break;
      case 'copy_url':
        copy({ text: post.url, onSuccessText: 'Copied post URL' });
        break;
      case 'archive':
        api
          .patch(`/posts/${post.id}/transition`, { status: 'archived' })
          .then(() => {
            toast.success('Post archived');
            refetch();
          })
          .catch(() => {
            toast.error('Could not archive post');
          });
        break;
      case 'unarchive':
        api
          .patch(`/posts/${post.id}/transition`, { status: 'confirmed' })
          .then(() => {
            toast.success('Post unarchived');
            refetch();
          })
          .catch(() => {
            toast.error('Could not unarchive post');
          });
        break;
      case 'delete':
        handleDeletePost(post);
        break;
      case 'gift_link':
        handleGiftLinkForPost(post);
        break;
      default:
        break;
    }
  };

  const publishedOnWeb = useMemo(
    () =>
      post.status === PostStatus.PUBLISHED &&
      (post.platform === PostPlatform.WEB || post.platform === PostPlatform.BOTH),
    [post.platform, post.status]
  );

  const formattedContentTags =
    post.content_tags.length <= 2
      ? post.content_tags.map((tag) => tag.display).join(', ')
      : `${post.content_tags
          .slice(0, 2)
          .map((tag) => tag.display)
          .join(', ')}, +${post.content_tags.length - 2}`;

  const formattedContentTagsMobile =
    post.content_tags.length <= 1
      ? post.content_tags.map((tag) => tag.display).join(', ')
      : `${post.content_tags
          .slice(0, 1)
          .map((tag) => tag.display)
          .join(', ')}, +${post.content_tags.length - 1}`;

  const featuredPostLimitReached = useMemo(
    () => featuredPostsIds.length >= FEATURED_POST_LIMIT,
    [featuredPostsIds.length]
  );

  const featuredOptionLabel = useMemo(() => {
    const featurePostText = featuredPostLimitReached
      ? `Feature limit reached (${FEATURED_POST_LIMIT})`
      : 'Feature post';
    return featured ? 'Unfeature post' : featurePostText;
  }, [featured, featuredPostLimitReached]);

  return (
    <div
      className={cx(
        'flex flex-row p-4 gap-x-2 w-full h-auto first:rounded-t-lg last:rounded-b-lg',
        selected ? 'bg-surface-100' : 'bg-white hover:bg-surface-50 cursor-pointer'
      )}
    >
      <div className="flex items-start pt-1 grow">
        <Checkbox name="select-post" checked={selected} className="cursor-pointer" onChange={onSelect} />
      </div>
      <Link to={viewPostDetails} className="w-full flex h-full grow">
        <div
          className={cx(
            'hidden w-full sm:flex items-center justify-between post-container-web',
            firstItem && 'rounded-t-lg',
            lastItem && 'rounded-b-lg'
          )}
        >
          <div className="flex flex-col gap-4 w-[600px]">
            <div className="flex flex-row gap-2">
              <div className="flex flex-row gap-4">
                <div className="flex flex-col">
                  <Typography className="max-w-144" token="font-medium/text/sm">
                    <Tooltip
                      id="post-title"
                      text={post.title}
                      showIcon={false}
                      tooltipClass="max-w-[200px] w-fit"
                      childrenContainerClass="whitespace-nowrap text-ellipsis overflow-hidden"
                    >
                      {post.title}
                    </Tooltip>
                  </Typography>
                  <Typography className="max-w-144" token="font-normal/text/sm" colorWeight="700">
                    <Tooltip
                      id="post-subtitle"
                      text={post.subtitle}
                      showIcon={false}
                      tooltipClass="max-w-[200px] w-fit"
                      childrenContainerClass="whitespace-nowrap text-ellipsis overflow-hidden"
                    >
                      {post.subtitle}
                    </Tooltip>
                  </Typography>
                </div>
              </div>
            </div>
            {postStatus.length > 0 && (
              <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-row gap-2">
                  {featured && (
                    <Badge
                      leadingIcon={<SparklesIcon className="w-3.5 h-3.5" />}
                      type={BadgeType.INFO}
                      text="Featured"
                    />
                  )}
                  <Badge leadingIcon={postBadgeIcon} type={postBadgeType} text={postBadgeText} />
                </div>
                <span className="text-gray-500 text-xs">•</span>
                <div className="flex flex-row">
                  {post.authors.map((author, index) => (
                    <Avatar.Wrapper className={cx(index > 0 && '-mx-1')} key={author.id}>
                      {author.profile_picture ? (
                        <Tooltip
                          id={`author-${author.id}`}
                          text={author.name}
                          showIcon={false}
                          tooltipClass="max-w-[200px] w-fit"
                        >
                          <Avatar.Image
                            src={author.profile_picture.thumb.url}
                            alt={author.name}
                            className="border rounded-full border-neutrals-200 w-5 h-5"
                          />
                        </Tooltip>
                      ) : null}
                    </Avatar.Wrapper>
                  ))}
                </div>
                {postStatus !== 'archived' && (
                  <Typography token="font-normal/text/xs" colorWeight="500">
                    {postStatus} {postDisplayTime && moment(postDisplayTime).format('MMM D, YYYY h:mm A')}
                  </Typography>
                )}
                {formattedContentTags && <span className="text-gray-500 text-xs">•</span>}
                {formattedContentTags && (
                  <Typography token="font-normal/text/xs" colorWeight="500">
                    <Tooltip
                      id="post-content-tags"
                      text={post.content_tags.map((tag) => tag.display).join(', ')}
                      showIcon={false}
                      tooltipClass="max-w-[200px] w-fit"
                    >
                      {formattedContentTags}
                    </Tooltip>
                  </Typography>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-row mr-14 items-center">
            {canShowMetrics(post) && (
              <div className="flex items-center">
                <PostStatsOverviewContainer
                  postId={post.id}
                  isPremiumEnabled={publication?.is_premium_enabled || false}
                  staleTime={Infinity}
                >
                  {(stats) =>
                    post.platform === PostPlatform.WEB ? (
                      <MetricsBadge
                        totalWebViewed={stats.total_web_viewed || 0}
                        totalWebClicked={stats.total_unique_web_clicked || 0}
                        totalUpgrades={stats.total_upgrades}
                      />
                    ) : (
                      <MetricsBadge
                        totalSent={stats.total_sent || 0}
                        openRate={stats.open_rate || 0}
                        clickRate={stats.click_rate || 0}
                        totalUpgrades={stats.total_upgrades}
                      />
                    )
                  }
                </PostStatsOverviewContainer>
              </div>
            )}
          </div>
        </div>
        <div
          className={cx(
            'w-full flex flex-col gap-5 sm:hidden items-center justify-between post-container-mobile',
            firstItem && 'rounded-t-lg',
            lastItem && 'rounded-b-lg'
          )}
        >
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-row gap-2">
              <div className="flex flex-col">
                <Typography className="max-w-80" token="font-medium/text/sm">
                  <Tooltip
                    id="post-title"
                    text={post.title}
                    showIcon={false}
                    tooltipClass="max-w-[200px] w-fit"
                    childrenContainerClass="whitespace-nowrap text-ellipsis overflow-hidden"
                  >
                    {post.title}
                  </Tooltip>
                </Typography>
                <Typography className="max-w-80" token="font-normal/text/sm" colorWeight="700">
                  <Tooltip
                    id="post-subtitle"
                    text={post.subtitle}
                    showIcon={false}
                    tooltipClass="max-w-[200px] w-fit"
                    childrenContainerClass="whitespace-nowrap text-ellipsis overflow-hidden"
                  >
                    {post.subtitle}
                  </Tooltip>
                </Typography>
              </div>
            </div>
          </div>
          {canShowMetrics(post) && (
            <div className="w-full">
              <div className="flex items-center">
                <PostStatsOverviewContainer
                  postId={post.id}
                  isPremiumEnabled={publication?.is_premium_enabled || false}
                  staleTime={Infinity}
                >
                  {(stats) =>
                    post.platform === PostPlatform.WEB ? (
                      <MetricsBadge
                        totalWebViewed={stats.total_web_viewed || 0}
                        totalWebClicked={stats.total_unique_web_clicked || 0}
                        totalUpgrades={stats.total_upgrades}
                      />
                    ) : (
                      <MetricsBadge
                        totalSent={stats.total_sent || 0}
                        openRate={stats.open_rate || 0}
                        clickRate={stats.click_rate || 0}
                        totalUpgrades={stats.total_upgrades}
                      />
                    )
                  }
                </PostStatsOverviewContainer>
              </div>
            </div>
          )}
          <div className="flex flex-row gap-10 items-end w-full justify-between">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2">
                {featured && (
                  <Badge leadingIcon={<SparklesIcon className="w-3.5 h-3.5" />} type={BadgeType.INFO} text="Featured" />
                )}
                <Badge leadingIcon={postBadgeIcon} type={postBadgeType} text={postBadgeText} />
              </div>
              <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-row">
                  {post.authors.map((author, index) => (
                    <Avatar.Wrapper className={cx(index > 0 && '-mx-1')} key={author.id}>
                      {author.profile_picture ? (
                        <Tooltip
                          id={`author-${author.id}`}
                          text={author.name}
                          showIcon={false}
                          tooltipClass="max-w-[200px] w-fit"
                        >
                          <Avatar.Image
                            src={author.profile_picture.thumb.url}
                            alt={author.name}
                            className="border rounded-full border-neutrals-200 w-5 h-5"
                          />
                        </Tooltip>
                      ) : null}
                    </Avatar.Wrapper>
                  ))}
                </div>
                <span className="text-gray-500 text-xs">•</span>
                {postStatus !== 'archived' && (
                  <Typography token="font-normal/text/xs" colorWeight="500">
                    {postDisplayTime && moment(postDisplayTime).format('MMM D, YYYY h:mm A')}
                  </Typography>
                )}
                {formattedContentTagsMobile && <span className="text-gray-500 text-xs">•</span>}
                {formattedContentTagsMobile && (
                  <Typography token="font-normal/text/xs" colorWeight="500">
                    <Tooltip
                      id="post-content-tags"
                      text={post.content_tags.map((tag) => tag.display).join(', ')}
                      showIcon={false}
                      tooltipClass="max-w-[200px] w-fit"
                    >
                      {formattedContentTagsMobile}
                    </Tooltip>
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
      <Dropdown
        name="bulk-actions"
        variant="ellipsis"
        ellipsisClassNames="w-4 h-4"
        ellipsisBordered
        className="flex h-auto items-center"
        optionsContainerClassNames={{
          width: 'w-fit',
          height: 'max-h-max',
        }}
        options={[
          { label: 'View post details', value: 'view' },
          {
            label: 'Edit',
            value: 'edit',
            disabled: !canUpdate,
            disabledTooltip: !canUpdate ? NO_PERMISSION_MESSAGE : undefined,
          },
          { label: 'Duplicate post', value: 'duplicate' },
          { label: 'Save post as template', value: 'save_template' },
          ...(publishedOnWeb
            ? [
                {
                  label: featuredOptionLabel,
                  value: 'feature_post',
                  topBorder: true,
                  disabled: (featuredPostLimitReached && !featured) || !canFeature,
                  disabledTooltip: !canFeature ? NO_PERMISSION_MESSAGE : undefined,
                },
              ]
            : []),
          ...(publishedOnWeb ? [{ label: 'View published post', value: 'view_post', topBorder: true }] : []),
          ...(publishedOnWeb ? [{ label: 'Copy post URL', value: 'copy_url' }] : []),
          ...(publishedOnWeb && giftLinkEnabled
            ? [
                {
                  label: 'Gift link to paid content',
                  value: 'gift_link',
                  disabled: !canGiftLink || !premiumEnabled,
                  disabledTooltip: !canGiftLink ? NO_PERMISSION_MESSAGE : undefined,
                },
              ]
            : []),
          ...(post.archivable || post.status === PostStatus.ARCHIVED
            ? [
                {
                  label: post.status === PostStatus.ARCHIVED ? 'Unarchive' : 'Archive',
                  value: post.status === PostStatus.ARCHIVED ? 'unarchive' : 'archive',
                  danger: true,
                  topBorder: true,
                  disabled: !canArchive,
                  disabledTooltip: !canArchive ? NO_PERMISSION_MESSAGE : undefined,
                },
              ]
            : []),
          {
            label: 'Delete',
            value: 'delete',
            danger: true,
            disabled: !canDelete,
            disabledTooltip: !canDelete ? NO_PERMISSION_MESSAGE : undefined,
          },
        ]}
        value=""
        onSelect={handlePostAction}
      />
    </div>
  );
};

export default PostContainer;
