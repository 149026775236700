import { useQuery } from 'react-query';

import { Post, TextToSpeechConfig } from '@/interfaces/post';
import api from '@/services/swarm';

import { useCurrentPublication } from '../usePublications';

export function usePostTextToSpeechConfig(postId: Post['id']) {
  const { data: currentPublication } = useCurrentPublication();

  return useQuery<TextToSpeechConfig>(
    ['postTextToSpeechConfig', postId, currentPublication?.id],
    () =>
      api
        .get<TextToSpeechConfig>(`/posts/${postId}/text_to_speech_config`, {
          params: { publication_id: currentPublication?.id },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
      enabled: !!postId && !!currentPublication?.id,
    }
  );
}
