import { MultiSelect } from '../../components/Form';
import { CheckboxOptionsProps, OptionTypes } from '../../interfaces/post';

const CheckboxOptions = ({ options, authors, contentTags, handleOptionsChange }: CheckboxOptionsProps) => {
  const handleOptionChange =
    (type: OptionTypes) =>
    (values: string[]): void => {
      const newOptions = {
        ...options,
        [type]: values,
      };

      handleOptionsChange(newOptions);
    };

  return (
    <div className="flex flex-col h-full w-full justify-between">
      <div className="flex flex-col lg:space-x-10 space-x-0 lg:flex-row">
        <div className="pb-4">
          <p className="block text-sm font-medium text-gray-700 mb-1">Audience</p>
          <MultiSelect
            options={[
              ['free', 'free'],
              ['premium', 'premium'],
              ['both', 'both'],
            ]}
            currentSelections={options[OptionTypes.AUDIENCE].map((value) => String(value))}
            inputName="audience"
            onChange={handleOptionChange(OptionTypes.AUDIENCE)}
          />
        </div>

        <div className="pb-4">
          <p className="block text-sm font-medium text-gray-700 mb-1">Platforms</p>
          <MultiSelect
            options={[
              ['web', 'web'],
              ['email', 'email'],
              ['both', 'both'],
            ]}
            currentSelections={options[OptionTypes.PLATFORMS].map((value) => String(value))}
            inputName="platforms"
            onChange={handleOptionChange(OptionTypes.PLATFORMS)}
          />
        </div>

        {contentTags?.length > 0 && (
          <div className="pb-4">
            <p className="block text-sm font-medium text-gray-700 mb-1">Content Tags</p>
            <MultiSelect
              options={contentTags}
              currentSelections={options[OptionTypes.CONTENT_TAGS].map((value) => String(value))}
              inputName="content_tags"
              onChange={handleOptionChange(OptionTypes.CONTENT_TAGS)}
            />
          </div>
        )}

        {authors.length > 0 && (
          <div className="pb-4">
            <p className="block text-sm font-medium text-gray-700 mb-1">Authors</p>
            <MultiSelect
              options={authors}
              currentSelections={options[OptionTypes.AUTHOR_IDS].map((value) => String(value))}
              inputName="authors"
              onChange={handleOptionChange(OptionTypes.AUTHOR_IDS)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckboxOptions;
