import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Plus } from '@phosphor-icons/react';

import { useWebsiteContext, WebsiteProvider } from '@/context/website-context';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';
import { cn } from '@/utils/cn';

import { ATTRIBUTES_PANEL_ID, LAYERS_PANEL_ID, SIDE_INSERT_PANEL_ID } from '../../_components/DreamEditor/constants';
import { EditorHeader, EditorHeaderBreadcrumb } from '../../_components/EditorHeader/EditorHeader';
import { Layout } from '../../_components/Layout';
import { ActionMenu } from '../../_components/Layout/ActionMenu';
import { NavSection } from '../../_components/SideNav/NavSection';
import { SaveFooterIndicator } from '../../_components/SideNav/SaveIndicator';
import { Button } from '../../_components/UI/Button';
import VersionHistoryNotice from '../../_components/VersionHistoryNotice';
import { FooterEditor } from '../../footer/_components/FooterEditor';
import { useContainerRef } from '../../page/_hooks';

import { FooterTemplateEditorProvider } from './context';

const FooterEditorPage = () => {
  const navigate = useNavigate();
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const { previewSiteVersion } = useWebsiteContext();

  const { sitePackageId } = useParams();
  const { data: sitePackage } = useSitePackage({ sitePackageId: sitePackageId as string });

  const { rect: editorRect, containerRef: editorContainerRef } = useContainerRef();

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary pb-0 pt-0"
      isSidebarResizable
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
        />
      }
      sidenavChildren={
        <>
          <NavSection>
            <Button
              variant="secondary"
              size="sm"
              LeftIcon={ArrowLeft}
              className="w-full py-3"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </NavSection>
          <div className="flex-1">
            <NavSection>
              <div id={LAYERS_PANEL_ID} />
            </NavSection>
          </div>
          <NavSection>
            <SaveFooterIndicator />
          </NavSection>
          <div
            id={SIDE_INSERT_PANEL_ID}
            className={cn(
              'absolute top-0 left-0 bottom-0 min-w-[200px] max-w-[250px] transition-transform duration-250 bg-wb-primary border-r border-solid overflow-y-auto',
              isInsertPanelOpen ? 'translate-x-0' : `-translate-x-[500px]`
            )}
          />
        </>
      }
      rightSideChildren={
        <div
          className="w-[266px] min-w-[266px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-auto"
          id={ATTRIBUTES_PANEL_ID}
          style={{
            scrollbarGutter: 'stable',
          }}
        />
      }
      titleType="template_footer"
    >
      <div ref={editorContainerRef} className="h-full bg-transparent w-full relative">
        <FooterEditor editorRect={editorRect}>
          <EditorHeader type="footer-template" iconType="component">
            {sitePackage?.id && (
              <EditorHeaderBreadcrumb text="Projects" linkTo="/website_builder_v2/projects" iconType="folder" />
            )}
            <EditorHeaderBreadcrumb
              text={sitePackage?.name || 'Templates'}
              iconType="folder"
              linkTo={`/website_builder_v2/projects/${sitePackage?.id}`}
            />
          </EditorHeader>
        </FooterEditor>
      </div>
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <FooterTemplateEditorProvider>
      <FooterEditorPage />
    </FooterTemplateEditorProvider>
  </WebsiteProvider>
);
