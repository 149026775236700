import { useCallback, useMemo, useState } from 'react';
import { ArrowLeft, X } from '@phosphor-icons/react';
import { Author } from '@shared/dream-components';
import { Editor } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

import useAuthors from '@/hooks/useDreamBuilder/useAuthors';

import { cn } from '../../../../../_utils/cn';
import { Button } from '../../../../UI/Button';
import { Checkbox } from '../../../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Label } from '../../../../UI/Label';
import { Switch } from '../../../../UI/Switch';
import { Text } from '../../../../UI/Text';
import { authors as dummyAuthors } from '../../../dummyData/authors';

const AuthorsSelectionModal = ({
  editor,
  node,
  isOpen,
  onClose,
  pos,
}: {
  editor: Editor;
  node: ProseMirrorNode;
  pos: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [selectedAuthors, setSelectedAuthors] = useState<Author[]>([]);
  const [isLayoutOpen, setIsLayoutOpen] = useState(false);
  const [showFakeData, setShowFakeData] = useState(false);

  const isSubmitDisabled = selectedAuthors.length === 0;
  const selectionCount = selectedAuthors.length;
  const selectionLabel = selectionCount <= 1 ? 'author' : 'authors';

  const { data } = useAuthors({ enabled: isOpen });
  const authors = showFakeData ? dummyAuthors.map((author) => ({ ...author, id: author.name })) : data?.authors || [];

  const selectedAuthorsIds = useMemo(() => selectedAuthors.map((author) => author.name), [selectedAuthors]);

  const handleSubmit = useCallback(() => {
    const updatedAttributes = {
      ...node?.attrs,
      insertedFromSidebar: false,
      data: {
        authors: selectedAuthors,
      },
    };

    editor?.commands.command(({ tr }) => {
      tr.setNodeAttribute(pos, 'insertedFromSidebar', false);
      tr.setNodeAttribute(pos, 'hasFakeData', false);
      tr.setNodeAttribute(pos, 'data', updatedAttributes.data);
      return true;
    });

    onClose();
  }, [selectedAuthors, editor, node, pos, onClose]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              {isLayoutOpen && <Button variant="ghost" onClick={() => setIsLayoutOpen(false)} LeftIcon={ArrowLeft} />}
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                Select authors
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-2">
            {selectedAuthors.map((author) => (
              <button
                type="button"
                key={author.name}
                className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1"
                onClick={() => setSelectedAuthors(selectedAuthors.filter((p) => p.name !== author.name))}
              >
                <Text
                  weight="regular"
                  variant="accent"
                  size="3xs"
                  as="span"
                  className="line-clamp-1 max-w-[150px] truncate"
                >
                  {author.name}
                </Text>
                <X className="w-3 h-3" />
              </button>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full overflow-y-auto no-scrollbar">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-32">
              {authors.map((author) => {
                const isSelected = selectedAuthorsIds.includes(author.name);

                return (
                  <Label
                    key={author.name}
                    htmlFor={author.name}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md focus:outline-none relative overflow-hidden',
                      isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    <div className="w-full h-36 overflow-hidden flex items-center justify-center">
                      <img
                        src={author.profile_picture.url}
                        alt={author.name}
                        className="w-full h-full object-cover rounded-md"
                      />
                    </div>
                    <div className="flex justify-between items-center gap-2 p-3">
                      <div className="flex gap-2 items-center">
                        <div className="flex flex-col">
                          <Text weight="semibold" variant="primary" size="xs" as="span" className="line-clamp-1">
                            {author.name}
                          </Text>
                        </div>
                      </div>
                      <Checkbox
                        id={author.name}
                        className={cn(isSelected ? 'opacity-100' : 'opacity-0')}
                        checked={Boolean(isSelected)}
                        onCheckedChange={() => {
                          if (isSelected) {
                            setSelectedAuthors(selectedAuthors.filter((p) => p.name !== author.name));
                          } else {
                            setSelectedAuthors([...selectedAuthors, author] as Author[]);
                          }
                        }}
                      />
                    </div>
                  </Label>
                );
              })}
            </div>
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
          <div className="flex justify-between items-center w-full">
            <div className="flex gap-2 items-center">
              <Switch
                id="show-fake-data"
                checked={showFakeData}
                onCheckedChange={() => setShowFakeData(!showFakeData)}
                labelText="Dummy data"
                labelClassName="whitespace-nowrap"
              />
            </div>
            <div className="flex gap-2 w-full justify-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit} isDisabled={isSubmitDisabled}>
                Select {selectionLabel}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AuthorsSelectionModal;
