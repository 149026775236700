import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useCustomPage } from '../../../hooks/useCustomPages';
import api from '../../../services/swarm';
import PageLayout from '../_components/PageLayout';

const EditCustomPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pageId } = useParams();
  const pageQuery = useCustomPage(pageId);
  const { data: page } = pageQuery;

  const handleEditCustomPage = (formData: FormData) => {
    setIsLoading(true);
    api
      .patch(`/custom_pages/${page?.id}`, formData)
      .then(() => {
        toast.success('Page saved!');
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message || 'Could not save page.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {page && (
        <PageLayout
          isEditing
          isSubmitting={isLoading}
          customPage={page}
          navLink={page.nav_link}
          onSave={handleEditCustomPage}
        />
      )}
    </>
  );
};

export default EditCustomPage;
