import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cx from 'classnames';

import { WalkthroughCard } from '@/components/Walkthrough';
import useUpdateOnboarding from '@/hooks/useOnboarding/useUpdateOnboarding';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { useOnboarding } from '../hooks';
import usePublication from '../hooks/usePublications/usePublication';
import { IOnboarding } from '../interfaces/onboarding';

interface IWalkthroughContext {
  onboarding?: IOnboarding;
  currentStep?: number;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
  navigationStep: any;
  showWalkthrough: boolean;
  handleStartWalkthrough: () => void;
  handleFinishWalkthrough: () => void;
}

const WalkthroughContext = createContext<IWalkthroughContext | any>({});

WalkthroughContext.displayName = 'WalkthroughContext';

// These are just the steps on the NAV, that is why it is missing step 1 + 6
const NAV_BAR_STEPS: any = Object.freeze({
  2: {
    title: 'Customize publication in Settings 🛠️',
    description:
      'Manage your website, publication, invite team members, & import content. Also, use the Design Lab to design your email newsletter and website.',
    currentStep: 2,
    totalSteps: 7,
    positionClassNameOverride: 'h-screen pb-2 flex items-end absolute',
    arrowPositionOverride: 'absolute -left-2 top-[214px]',
    backPath: '/',
    nextPath: '/subscribers',
  },
  3: {
    title: 'Import subscribers 🚀',
    description: 'Seamlessly import existing subscribers to your publication list.',
    currentStep: 3,
    totalSteps: 7,
    positionClassNameOverride: 'top-[190px]',
    backPath: '/',
    nextPath: '/posts',
  },
  4: {
    title: 'Write your posts ✍️',
    description:
      'Explore our Editor to write and manage your publication’s posts, add content tags, and send to audience segments.',
    currentStep: 4,
    totalSteps: 7,
    positionClassNameOverride: 'top-[98px]',
    backPath: '/subscribers',
    nextPath: '/automations',
  },
  5: {
    title: 'Automate emails 🤖',
    description: 'Create welcome series, re-engagement campaigns, upsell sequences, and more.',
    currentStep: 5,
    totalSteps: 7,
    positionClassNameOverride: 'top-[249px]',
    backPath: '/posts',
    nextPath: '/',
  },
  7: {
    title: 'Build your website 🌐',
    description: 'Easily customize the look and feel of your publication’s website.',
    currentStep: 7,
    totalSteps: 7,
    positionClassNameOverride: 'top-[320px]',
    backPath: '/',
    nextPath: '',
  },
});

const STEPS_IN_FLOW = ['1', '2', '3', '4', '5', '6', '7'];

const WalkthroughProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const isInFlow = Boolean(searchParams.get('walkthrough_step'));
  const currentStep = Number(searchParams.get('walkthrough_step')) || 0;
  const [hasFlowStarted, setHasFlowStarted] = React.useState<boolean>(false);
  const [showWalkthrough, setShowWalkthrough] = React.useState<boolean>(false);

  const publicationId = useCurrentPublicationId();
  const currentPublication = usePublication(publicationId);
  const { data: onboarding } = useOnboarding(publicationId, currentPublication?.data?.onboarding_id);
  const [isDelayed, setIsDelayed] = useState<boolean>(false);
  const delayedUntil = onboarding?.walkthrough_delayed_until;
  const delayFinished = delayedUntil && !isDelayed;

  useEffect(() => {
    if (onboarding && delayedUntil) {
      const now = new Date();
      const delayedUntilDate = new Date(delayedUntil);
      if (now < delayedUntilDate) {
        setIsDelayed(true);
      } else {
        setIsDelayed(false);
      }
    } else if (onboarding && !delayedUntil && isDelayed) {
      setIsDelayed(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboarding, delayedUntil]);

  const updateOnboarding = useUpdateOnboarding({
    onSuccess: () => {
      queryClient.invalidateQueries(['onboarding', currentPublication?.data?.id]);
      queryClient.invalidateQueries(['publication-extended-onboarding', currentPublication?.data?.id]);
    },
  });

  useEffect(() => {
    if (isInFlow && !hasFlowStarted && !showWalkthrough) {
      setShowWalkthrough(true);
      setHasFlowStarted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInFlow]);

  useEffect(() => {
    if (onboarding && !showWalkthrough) {
      const walkthroughCompletedAt = Boolean(onboarding.walkthrough_completed_at);
      if (!walkthroughCompletedAt && !isDelayed) {
        setShowWalkthrough(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboarding]);

  const handleCloseWalkthrough = () => {
    setShowWalkthrough(false);
    setHasFlowStarted(false);
    navigate(`/`);
  };

  // Remove user from flow if the step is not between 1-7
  useEffect(() => {
    if (isInFlow && !STEPS_IN_FLOW.includes(searchParams.get('walkthrough_step') || '')) {
      handleCloseWalkthrough();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInFlow, currentStep]);

  const handleDelayWalkthrough = () => {
    // Delay walkthrough for 24 hours
    updateOnboarding.mutate({
      walkthrough_delayed_until: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
    });
    handleCloseWalkthrough();
    setIsDelayed(true);
  };

  const handleStartWalkthrough = () => {
    setHasFlowStarted(true);
    setShowWalkthrough(true);
    navigate('/?walkthrough_step=1');
  };

  const handleFinishWalkthrough = () => {
    handleCloseWalkthrough();
    updateOnboarding.mutate({
      walkthrough_completed_at: new Date().toISOString(),
    });
  };

  const handlePreviousStep = (optionPath?: string) => {
    const previousStep = currentStep - 1;

    if (optionPath) {
      navigate(`${optionPath}?walkthrough_step=${previousStep}`);
      return;
    }

    navigate(`?walkthrough_step=${previousStep}`);
  };

  const handleNextStep = (optionPath?: string) => {
    if (currentStep === 7) {
      handleFinishWalkthrough();
      return;
    }

    const nextStep = currentStep + 1;
    if (optionPath) {
      navigate(`${optionPath}?walkthrough_step=${nextStep}`);
      return;
    }

    navigate(`?walkthrough_step=${nextStep}`);
  };

  const providerValue = useMemo(() => {
    return {
      onboarding,
      currentStep,
      handlePreviousStep,
      handleNextStep,
      navigationStep: NAV_BAR_STEPS[currentStep],
      showWalkthrough,
      handleStartWalkthrough,
      handleFinishWalkthrough,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboarding, currentStep]);

  return (
    <WalkthroughContext.Provider value={providerValue}>
      <div className="relative">
        {children}
        {hasFlowStarted && showWalkthrough && (
          <div className="absolute top-0 left-0 inset-0 bg-black opacity-30 z-10" />
        )}
        {showWalkthrough && !isDelayed && (
          <div className={cx('fixed bottom-4 right-4', !hasFlowStarted && 'z-20')}>
            <WalkthroughCard
              title="Welcome to beehiiv 🐝"
              description="Kickstart your newsletter with a quick tour."
              continueButton={{
                text: "Let's go",
                onClick: () => handleStartWalkthrough(),
              }}
              backButton={{
                text: delayFinished ? 'Skip' : 'Remind Me Later',
                onClick: () => {
                  if (delayFinished) {
                    handleFinishWalkthrough();
                    return;
                  }

                  handleDelayWalkthrough();
                },
              }}
              onClose={handleFinishWalkthrough}
            />
          </div>
        )}
      </div>
    </WalkthroughContext.Provider>
  );
};

function useWalkthroughContext() {
  const context = React.useContext(WalkthroughContext);
  if (context === undefined) {
    throw new Error(`useWalkthroughContext must be used within a WalkthroughProvider`);
  }
  return context;
}

export { useWalkthroughContext, WalkthroughContext, WalkthroughProvider };
