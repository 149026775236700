import { useQuery } from 'react-query';

import { ApiKey } from '@/interfaces/api_key';
import api from '@/services/swarm';

const useApiKeys = (publicationId: string) => {
  return useQuery<ApiKey[]>(
    ['api_keys', publicationId],
    () =>
      api
        .get(`/api_keys`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!publicationId,
    }
  );
};

export default useApiKeys;
