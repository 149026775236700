import { ClipboardIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import IconButton from '@/components/IconHelpers/IconButton';
import { Typography } from '@/components/Typography';
import { Token } from '@/components/Typography/Typography';
import { useCopyToClipboard } from '@/hooks';

interface Props {
  code: string;
  labelText?: string;
  helperText?: string;
  canCopy?: boolean;
  shouldOverflow?: boolean;
  codeTypographyToken?: Token;
}

const CodeBlock = ({ code, labelText, helperText, canCopy = false, shouldOverflow = true, codeTypographyToken = 'font-light/text/sm' }: Props) => {
  const copy = useCopyToClipboard();
  const handleClickCopy = () =>
    copy({
      text: code,
    });

  return (
    <div className="flex flex-col gap-y-2 ">
      {labelText && <Typography token="font-medium/text/base">{labelText}</Typography>}
      <div className="bg-gray-100 p-4 rounded h-full relative group">
        {canCopy && (
          <div className="absolute right-2 top-2 hidden group-hover:block">
            <IconButton
              onClick={handleClickCopy}
              backgroundColor="bg-action-primary-700"
              hoverBackgroundColor="hover:bg-action-primary-800"
              textColor="text-action-primary-100"
            >
              <ClipboardIcon />
            </IconButton>
          </div>
        )}
        <pre className={cx('h-full', shouldOverflow ? 'overflow-x-scroll' : 'whitespace-normal')}>
          <Typography token={codeTypographyToken}>{code}</Typography>
        </pre>
      </div>
      {helperText && <Typography token="font-light/text/sm">{helperText}</Typography>}
    </div>
  );
};

export default CodeBlock;
