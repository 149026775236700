import { Image } from '@/interfaces/image';
import { Milestone } from '@/interfaces/milestone';
import pluralize from '@/utils/pluralize';

import { Styled } from './UpcomingMilestones.styles';

interface Props {
  milestones: Milestone[];
}

export const UpcomingMilestones = ({ milestones }: Props) => {
  if (milestones.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-2">
        <Styled.Headline>Upcoming Rewards</Styled.Headline>
        <Styled.Description>
          For each referral you drive, you will unlock rewards when you hit milestones.
        </Styled.Description>
      </div>
      <Styled.MilestonesContainer className="flex flex-row gap-x-2">
        {milestones.map((milestone) => {
          const rewardImage = milestone.reward?.image as Image;
          return (
            <div className="flex flex-col grow-1 text-center gap-y-1">
              <Styled.ImageContainer>
                {rewardImage?.thumb?.url ? <img src={rewardImage.thumb.url} alt={milestone.reward.name} /> : null}
              </Styled.ImageContainer>
              <Styled.Paragraph>{pluralize('referral', milestone.num_referrals)}</Styled.Paragraph>
              <Styled.Paragraph>{milestone.reward.name}</Styled.Paragraph>
            </div>
          );
        })}
      </Styled.MilestonesContainer>
    </div>
  );
};
