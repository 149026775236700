import { forwardRef, ReactElement, ReactNode, Ref } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

interface ListItemProps {
  children: ReactElement[] | ReactElement;
  to?: string;
}

interface ListItemTextProps {
  children: ReactNode;
  variant: 'primary' | 'secondary';
}

const ListItem = forwardRef<HTMLLIElement, ListItemProps>(({ children, to }, ref: Ref<HTMLLIElement>) => {
  return (
    <li ref={ref} className="whitespace-nowrap text-gray-700 hover:bg-gray-50">
      <div className="px-4 py-2">{to ? <Link to={to}>{children}</Link> : children}</div>
    </li>
  );
});

const ListItemText = ({ children, variant }: ListItemTextProps) => {
  const className = {
    primary: 'font-medium text-gray-900',
    secondary: 'text-gray-500',
  }[variant];

  return <p className={cx('text-sm truncate space-x-2 flex items-center', className)}>{children}</p>;
};

type ListItemComponent = typeof ListItem & {
  Text: typeof ListItemText;
};

const ListItemExtended = ListItem as ListItemComponent;
ListItemExtended.Text = ListItemText;

export default ListItemExtended;
