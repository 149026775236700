import { Button } from '@/ui/Button';

import { LoadMoreButtonProps } from './LoadMoreButton.types';

const LoadMoreButton = ({ isFetchingNextPage, fetchNextPage, size }: LoadMoreButtonProps) => {
  return (
    <Button
      variant="primary-inverse"
      block
      onClick={() => fetchNextPage()}
      loading={isFetchingNextPage}
      disableWith="Loading..."
      size={size}
    >
      Load more
    </Button>
  );
};

export default LoadMoreButton;
