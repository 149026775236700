import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface ApiResponse {
  csv: string;
}

interface Arguments {
  postId: string;
  factPostClickId: string;
  useApiary?: boolean;
}

export default function usePostsFactPostClickExport({ postId, factPostClickId, useApiary = false }: Arguments) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostFactPostClick = () =>
    api
      .get(`/posts/${postId}/fact_post_clicks/${factPostClickId}/export`, {
        params: {
          publication_id: currentPublicationId,
          use_apiary: useApiary === true ? '1' : undefined,
        },
      })
      .then((res) => res.data);

  return useMutation<ApiResponse>(fetchPostFactPostClick, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.csv]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `clicks-${postId}-${factPostClickId}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onError: () => {
      toast.error('Something went wrong. Please try again.');
    },
  });
}
