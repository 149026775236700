import React from 'react';

import { Typography } from '@/components/Typography';

import BodyContainer from '../../../_components/BodyContainer';
import { StepProps } from '../new/types';

const OtherPlatform: React.FunctionComponent<StepProps> = () => (
  <BodyContainer>
    <Typography token="font-normal/text/sm" colorWeight="500">
      Uh-oh, it looks like we don&apos;t yet support importing content from your current platform. Please{' '}
      <a
        href="https://support.beehiiv.com/hc/en-us/requests/new"
        target="_blank"
        rel="noreferrer"
        className="text-primary-700"
      >
        contact support
      </a>{' '}
      and let us know what platform you are using. We are working hard to expand what our content import tool can
      support.
    </Typography>
  </BodyContainer>
);

export default OtherPlatform;
