import { useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon, LinkIcon } from '@heroicons/react/20/solid';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { Checkbox } from '@/components/Form';
import Modal from '@/components/Modal';
import ScaledHTMLPreview from '@/components/ScaledHTMLPreview';
import { Typography } from '@/components/Typography';
import { AdNetworkAdvertisementKind } from '@/interfaces/ad_network/internal/advertisement';
import { AdNetworkOpportunityAdvertisement } from '@/interfaces/ad_network/publisher/opportunity';
import { Badge, BadgeSize, BadgeType } from '@/ui/Badge';
import { Button } from '@/ui/Button';

interface Props {
  advertisements: AdNetworkOpportunityAdvertisement[];
  onSelect?: (advertisement: AdNetworkOpportunityAdvertisement | null) => void;
  selectedAdvertisement?: AdNetworkOpportunityAdvertisement;
}

const duration = 'duration-300';

const ScaledPreviews = ({ advertisements, onSelect, selectedAdvertisement }: Props) => {
  const readOnly = !onSelect;
  const [previewAd, setPreviewAd] = useState<AdNetworkOpportunityAdvertisement | undefined>(undefined);
  const handleSelect = (advertisement: AdNetworkOpportunityAdvertisement | null) => {
    if (readOnly) return;

    onSelect(advertisement);
    setPreviewAd(undefined);
  };
  const handlePreview = (advertisement: AdNetworkOpportunityAdvertisement) => {
    setPreviewAd(advertisement);
  };
  const handleClosePreview = () => {
    setPreviewAd(undefined);
  };
  const previewIdx = previewAd && advertisements.findIndex((advertisement) => advertisement.id === previewAd?.id);
  const prevAd = previewIdx !== undefined && advertisements[previewIdx - 1];
  const nextAd = previewIdx !== undefined && advertisements[previewIdx + 1];
  const firstAd = advertisements[0];
  const lastAd = advertisements[advertisements.length - 1];

  return (
    <>
      {advertisements.map((advertisement) => {
        const selected = advertisement.id === selectedAdvertisement?.id;

        return (
          <div className="w-full space-y-3" key={advertisement.id}>
            <div
              className={cx(
                'rounded-lg overflow-hidden border',
                duration,
                selected && 'ring ring-action-tertiary-600 ring-offset-2'
              )}
            >
              <div className="w-full relative" style={{ paddingTop: '100%' }}>
                <div className="absolute inset-0 w-full h-full ">
                  <div className="flex-shrink-0 w-full h-full relative overflow-hidden group ">
                    <ScaledHTMLPreview html={advertisement.html} renderHeight={1000} renderWidth={1000} />
                    <button
                      className={cx(
                        'cursor-pointer absolute bg-gray-900 inset-0 opacity-0 group-hover:opacity-20',
                        duration
                      )}
                      type="button"
                      onClick={() => handleSelect(advertisement)}
                    >
                      <span className="sr-only">Select</span>
                    </button>
                    {!readOnly && (
                      <div className="absolute top-4 right-4 z-10">
                        <Checkbox
                          checked={!!selected}
                          onChange={(checked) => {
                            if (checked) {
                              handleSelect(advertisement);
                            } else if (!readOnly) {
                              onSelect(null);
                            }
                          }}
                          className="text-white"
                          name={`ad-${advertisement.id}-select`}
                          color="tertiary"
                        />
                      </div>
                    )}
                    <div
                      className={cx(
                        'absolute bottom-0 right-0 group-hover:opacity-100 opacity-0 transform translate-y-4 group-hover:translate-y-0',
                        duration
                      )}
                    >
                      <div className="flex justify-end w-full h-full items-end p-4 text-white relative">
                        <div className="flex space-x-2">
                          <a href={advertisement.url} target="_blank" rel="noopener noreferrer">
                            <Button size="sm" variant="primary" type="button" Icon={LinkIcon} spacing="p-2" />
                          </a>
                          <Button
                            size="sm"
                            variant="primary"
                            type="button"
                            Icon={ArrowsPointingOutIcon}
                            onClick={() => handlePreview(advertisement)}
                            spacing="p-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!readOnly && (
              <div className="flex flex-col space-y-1">
                <div className="space-x-2 flex items-center">
                  <Typography token="font-medium/text/xs" colorWeight="900">
                    {advertisement.name}
                  </Typography>
                  <Badge
                    text={advertisement.kind}
                    size={BadgeSize.SM}
                    type={
                      advertisement.kind === AdNetworkAdvertisementKind.PRIMARY
                        ? BadgeType.TERTIARY_LINED
                        : BadgeType.DEFAULT_LINED
                    }
                  />
                </div>
                {advertisement.description && (
                  <Typography token="font-normal/text/xs" colorWeight="500">
                    {advertisement.description}
                  </Typography>
                )}
              </div>
            )}
          </div>
        );
      })}
      <Modal isOpen={previewAd !== undefined} onClose={handleClosePreview} fullScreen>
        {previewAd && (
          <div className="flex flex-col h-full w-full">
            <nav className="p-4 grid grid-cols-3">
              <div className="space-x-3">
                {!readOnly && (
                  <Button type="button" variant="primary" Icon={CheckIcon} onClick={() => handleSelect(previewAd)}>
                    Select version
                  </Button>
                )}
                <a href={previewAd.url} target="_blank" rel="noopener noreferrer">
                  <Button variant="primary-inverse" onClick={() => {}} Icon={LinkIcon}>
                    Test Ad Link
                  </Button>
                </a>
              </div>
              {!readOnly && (
                <div className="flex-grow flex justify-center items-center space-x-3">
                  <Button
                    size="sm"
                    type="button"
                    variant="primary-inverse"
                    Icon={ArrowLeftIcon}
                    onClick={() => handlePreview(prevAd || lastAd)}
                    spacing="p-2"
                  />
                  <p className="text-sm text-gray-500">
                    {(previewIdx || 0) + 1} of {advertisements.length}
                  </p>
                  <Button
                    size="sm"
                    type="button"
                    variant="primary-inverse"
                    Icon={ArrowRightIcon}
                    onClick={() => handlePreview(nextAd || firstAd)}
                    spacing="p-2"
                  />
                </div>
              )}
              <div />
            </nav>
            <div className="flex flex-col space-y-2 text-center py-10">
              <div className="space-x-2 flex justify-center">
                <Typography token="font-medium/text/base" colorWeight="900">
                  {previewAd.name}
                </Typography>
                <Badge
                  text={previewAd.kind}
                  size={BadgeSize.SM}
                  type={
                    previewAd.kind === AdNetworkAdvertisementKind.PRIMARY
                      ? BadgeType.TERTIARY_LINED
                      : BadgeType.DEFAULT_LINED
                  }
                />
              </div>
              {previewAd.description && (
                <Typography token="font-normal/text/sm" colorWeight="500">
                  {previewAd.description}
                </Typography>
              )}
            </div>
            <div className="mx-auto max-w-4xl w-full border rounded-b-none rounded-t-xl overflow-clip h-full">
              <iframe className="w-full h-full" srcDoc={previewAd?.html} title="preview" />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ScaledPreviews;
