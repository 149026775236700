import { useInfiniteQuery, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Pagination } from '../interfaces/general';
import { PremiumGift } from '../interfaces/premium_gift';
import api from '../services/swarm';

interface ApiResponse {
  premiumGifts: PremiumGift[];
  pagination: Pagination;
}

export default function usePremiumGifts({
  search,
  perPage = 10,
  all = false,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
}) {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchPremiumGifts = ({ pageParam = 1 }) =>
    api
      .get(`/premium_gifts`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([currentPublicationId, 'premiumGifts', search], fetchPremiumGifts, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    retry: false,
    keepPreviousData: true,
    onSuccess: (data) => {
      const premiumGifts = data.pages.flatMap((page) => page.premiumGifts);

      premiumGifts.forEach((premiumGiftPreview: any) => {
        queryClient.setQueryData(['premiumGifts', premiumGiftPreview?.id], {
          id: premiumGiftPreview?.id,
          name: premiumGiftPreview?.name,
          description: premiumGiftPreview?.description,
        });
      });
    },
    onError: (errPayload: any) => errPayload?.response?.data?.error || 'Something went wrong',
  });
}
