import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Content, Editor } from '@tiptap/react';

import { DreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext } from '@/context/website-context';
import { useBeforeNavigate } from '@/hooks/useBeforeNavigate';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';
import useCreateSiteVersion from '@/hooks/useSiteVersion/useCreateSiteVersion';
import { EditorDocumentType } from '@/routes/website/_components/DreamEditor/extensions/Document';

export const EditorDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { elementType } = useParams<{ elementType: EditorDocumentType }>();
  const { site, previewSiteVersion } = useWebsiteContext();
  const [initialContent, setInitialContent] = useState<Content | null>(null);
  const [changesMade, setChangesMade] = useState(false);
  const [editor, setEditor] = useState<Editor | null>(null);

  useEffect(() => {
    if (site?.draft_site_version && elementType) {
      if (site?.draft_site_version) {
        switch (elementType) {
          case 'recommendations_modal':
          case 'signup_modal':
          case 'login_modal':
            if (site.draft_site_version[elementType]) {
              setInitialContent(site.draft_site_version[elementType] as Content);
            }
            break;
          default:
        }
      }
    }
    return () => {
      editor?.commands.blur();
      setInitialContent(null);
    };
  }, [site?.draft_site_version, elementType]);

  const { mutate: updateSiteVersion, isLoading: isSaveLoading } = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
  });

  const { isLoading: isSaveVersionLoading, mutate: createSiteVersion } = useCreateSiteVersion({});

  const save = useCallback(() => {
    if (previewSiteVersion) return; // don't save if previewing other versions
    if (!elementType) return;
    updateSiteVersion({ [elementType]: editor?.getJSON() as Content });
    setChangesMade(false);
  }, [previewSiteVersion, updateSiteVersion, elementType, editor]);

  const onSaveToVersionHistory = useCallback(
    (autoSave: boolean = false, versionName?: string): void => {
      if (previewSiteVersion) return; // don't save if previewing other versions
      if (!elementType) return;

      updateSiteVersion({ [elementType]: editor?.getJSON() as Content });
      setChangesMade(false);
      createSiteVersion({
        version_name: versionName,
        version_type: autoSave ? 'autosave' : 'usersave',
      });
    },
    [updateSiteVersion, elementType, createSiteVersion, editor, previewSiteVersion]
  );

  // Auto save every 10 seconds
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        save();
      }
    }, 10000); // 10 seconds

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, save]);

  // Save on navigate away & prompt on reload or leaving page
  useBeforeNavigate(save, !!changesMade);

  const previewContent = (() => {
    switch (elementType) {
      case 'recommendations_modal':
      case 'signup_modal':
      case 'login_modal':
        if (elementType && previewSiteVersion && previewSiteVersion[elementType]) {
          return previewSiteVersion[elementType];
        }
        break;
      default:
    }

    return undefined;
  })();

  const value = useMemo(
    () => ({
      initialContent,
      setEditor,
      changesMade,
      setChangesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory,
      previewContent,
      isSaveVersionLoading,
      editor,
    }),
    [
      initialContent,
      changesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory,
      previewContent,
      isSaveVersionLoading,
      editor,
    ]
  );

  return <DreamEditorContext.Provider value={value}>{children}</DreamEditorContext.Provider>;
};

export const useEditorDataContext = () => {
  const context = React.useContext(DreamEditorContext);
  if (context === undefined) {
    throw new Error('useEditorDataContext must be used within a EditorDataProvider');
  }
  return context;
};
