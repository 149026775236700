interface Props {
  className?: string;
}

const TextTypeIcon = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" className={className}>
    <path stroke="currentColor" d="M4 12h6M4 8h12" />
  </svg>
);

export default TextTypeIcon;
