import { ActionProps } from '@/components/PricingPanel';
import { useCurrentUser } from '@/context/current-user-context';
import { PlanPrice } from '@/interfaces/plan_price';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

interface ButtonProps {
  planPrice: PlanPrice;
  onSelect: (newPlanPriceId?: string) => void;
}

const ScaleButton: React.FC<ActionProps & ButtonProps> = ({
  disabled,
  price,
  newPlanPriceOption,
  planPrice,
  onSelect,
}) => {
  const { currentUser } = useCurrentUser();

  const isCurrentPlanPrice = planPrice?.id === newPlanPriceOption?.id;
  const onScale = planPrice.plan_name === 'scale';
  const onCustom = planPrice.plan_name === 'custom';
  const onLaunch = planPrice.plan_name === 'launch';
  const higherTier = (newPlanPriceOption?.max_subscriptions || 0) > planPrice.max_subscriptions;
  const higherCadence = newPlanPriceOption?.interval === 'year' && planPrice.interval === 'month';
  const lowerCadence = newPlanPriceOption?.interval === 'month' && planPrice.interval === 'year';

  const buttonText = () => {
    if (isCurrentPlanPrice) return 'Your Current Plan';

    if (!onLaunch && !onCustom && higherCadence) return 'Upgrade to Annual';

    if (onScale) {
      if (lowerCadence) return 'Downgrade to Monthly';

      return higherTier ? 'Increase Subscriber Count' : 'Decrease Subscriber Count';
    }

    return onLaunch ? 'Upgrade To Scale' : 'Downgrade to Scale';
  };

  const onClick = () => {
    if (newPlanPriceOption && currentUser) {
      analytics.identify(currentUser.id);
      analytics.track('Selected Plan', {
        subscriber_limit: newPlanPriceOption.max_subscriptions,
        frequency: newPlanPriceOption.interval === 'month' ? 'monthly' : 'annual',
        stripe_price_id: newPlanPriceOption.stripe_price_id,
        price,
      });
    }

    onSelect(newPlanPriceOption?.id);
  };

  return (
    <div className="h-full flex flex-col justify-end py-6">
      <Button type="button" disabled={disabled || isCurrentPlanPrice} variant="primary-inverse" onClick={onClick}>
        {buttonText()}
      </Button>
    </div>
  );
};

export default ScaleButton;
