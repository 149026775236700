import { useState } from 'react';

import SlideOver from '@/components/SlideOver';
import { Button } from '@/ui/Button';

const ExplainMetrics = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} variant="primary-inverse" size="xs">
        Explain metrics
      </Button>
      <SlideOver isOpen={isOpen} onClose={() => setIsOpen(false)} headerText="Explain Boost Metrics">
        <div>
          <div className="relative mt-6 border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Total subscribers</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  The total number of subscribers that other publications have sent you via Boosts.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Verified subscribers</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Subscribers who have been accepted by our verification process. You only ever pay for verified
                  subscribers, which prevents you from paying for low quality and/or fake subscribers.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Pending verification</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Subscribers who you haven&apos;t yet paid for, currently going through our verification process. This
                  process takes between 10-17 days for our normal verification process (recommended), or as little as 3
                  days for our relaxed verification process.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Pending spend</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  The amount in escrow tied to those subscribers currently going through the verification process. Funds
                  associated with subscribers who fail verification will be returned to your beehiiv wallet and made
                  available to spend again.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Total spend</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  This is the amount you have already paid for verified subscribers.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Total Emails Sent</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  This is the number of emails that have been sent to your verified subscribers.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Total Unique Opens</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  This is the total unique number of emails that have been opened by your verified subscribers.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Total Clicks</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  This is the total number of email links clicked by your verified subscribers.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Open Rate</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  This is the open rate of your verified subscribers. It is calculated by dividing the number of unique
                  opens by the number of emails sent.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Click Through Rate</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  This is the click through rate of your verified subscribers. It is calculated by dividing the number
                  of clicks by the number of emails sent.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </SlideOver>
    </>
  );
};

export default ExplainMetrics;
