export enum PANEL {
  main = 'Main',
  layout = 'Layout',
  attributes = 'Attributes',
  cta = 'CTA',
}

export default {
  PANEL,
};
