import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

interface Props {
  value: string;
  userId: string;
}

const emailValidation = (value: string) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value);
};

const EmailForm = ({ value, userId }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!inputValue || inputValue === value) {
      toast.error('Please enter a new value to save!');
      return;
    }

    if (inputValue !== value && !emailValidation(inputValue)) {
      toast.error('Please enter a valid email address');
      return;
    }

    setIsSaving(true);
    api
      .post(`/users/${userId}/user_changes`, {
        field: 'email',
        new_value: inputValue,
      })
      .then((resp) => {
        toast.success(resp.data.message);
        setIsEditing(false);
      })
      .catch((errPayload) => {
        toast.error(errPayload?.response?.data?.message || 'Something went wrong');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <BodyContainer>
      <ActionModal
        isOpen={isEditing}
        disabled={!inputValue || isSaving}
        onClose={() => setIsEditing(false)}
        onProceed={handleSubmit}
        resourceId={value}
        isWorking={isSaving}
        headerText="Send New Email Confirmation"
        descriptionText="Your email will not be changed until you confirm it."
        actionText="Send Request"
        modalMessageType="info"
      >
        <div className="p-4">
          <Input
            name="email"
            labelText="Enter new email"
            placeholder={value}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
          />
        </div>
      </ActionModal>
      <div className="flex flex-col gap-y-4">
        <Input name="email" labelText="Email" value={value} disabled />

        <Button className="w-fit" variant="primary-inverse" size="xs" onClick={() => setIsEditing(true)}>
          Update Email
        </Button>
      </div>
    </BodyContainer>
  );
};

export default EmailForm;
