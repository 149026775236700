import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { PostTemplate } from '../interfaces/post_template';
import api from '../services/swarm';

export default function usePostTemplate(id: string) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostTemplate = () =>
    api
      .get(`/post_templates/${id}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<PostTemplate>(['post_templates', id], fetchPostTemplate, {
    keepPreviousData: true,
  });
}
