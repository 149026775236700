import { FC, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Checkbox, DatePicker, Input } from '@/components/Form';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import SlideOver from '@/components/SlideOver';
import { PercentSliderField } from '@/components/SliderField';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { useTiers } from '@/hooks/useTiers';
import { PremiumOfferType } from '@/interfaces/premium_offer';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import { Textarea } from '@/ui/Textarea';

const offerTypeOptions = [
  {
    label: 'Percent Discount',
    value: 'percent_discount',
  },
  {
    label: 'Free Trial',
    value: 'trial_period',
  },
];

const productExclusiveTypeOptions = [
  {
    label: 'Monthly Only',
    value: PremiumOfferType.MONTHLY,
  },
  {
    label: 'Annual Only',
    value: PremiumOfferType.ANNUAL,
  },
];

const durationOptions = [
  {
    label: 'Forever',
    value: 'forever',
  },
  {
    label: 'Once',
    value: 'once',
  },
  {
    label: 'Repeating',
    value: 'repeating',
  },
];

const trialPeriodOptions = [
  {
    label: '1 day',
    value: '1',
  },
  {
    label: '2 days',
    value: '2',
  },
  {
    label: '7 days',
    value: '7',
  },
  {
    label: '14 days',
    value: '14',
  },
  {
    label: '30 days',
    value: '30',
  },
  {
    label: '60 days',
    value: '60',
  },
  {
    label: '90 days',
    value: '90',
  },
];

const generateDurationInMonthsOptions = () => {
  const options = [];
  for (let step = 1; step <= 12; step += 1) {
    const option = {
      label: `${step} ${step === 1 ? 'month' : 'months'}`,
      value: `${step}`,
    };
    options.push(option);
  }
  return options;
};

const durationInMonthsOptions = generateDurationInMonthsOptions();

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (rewardId: string) => void;
  publicationId: string;
  offerId?: string;
}

interface IData {
  [key: string]: any;
}

const FormSlideOver: FC<Props> = (props: Props) => {
  const { isOpen, onSuccess, onClose, publicationId, offerId } = props;
  const [offer, setOffer] = useState<IData>({});
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const slideOverBodyId = 'manage-premium-offer';
  const currentPublicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(currentPublicationId);
  const { data: tiers = [] } = useTiers(currentPublicationId, { onlyActive: true, excludeDonation: true });
  const tierOptions = tiers.map((t) => ({
    label: t.name,
    value: t.id,
  }));
  tierOptions.unshift({
    label: 'All Tiers',
    value: 'all',
  });

  // Initially set a new offer with default values
  useEffect(() => {
    api
      .get('/premium_offers/new', { params: { publication_id: publicationId } })
      .then((r) => {
        setOffer(r?.data);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [publicationId]);

  const loadOffer = useCallback(() => {
    const params = {
      publication_id: publicationId,
    };

    setIsLoading(true);
    api
      .get(`/premium_offers/${offerId}`, { params })
      .then((r) => {
        setOffer(r?.data);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsLoading(false));
  }, [publicationId, offerId]);

  useEffect(() => {
    if (offerId) {
      loadOffer();
    }
  }, [offerId, loadOffer]);

  const resetForm = () => {
    setOffer({});
  };

  const onCloseSlideOver = () => {
    resetForm();
    onClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value, name },
    } = e;

    setOffer({ ...offer, [name]: value });
  };

  const handleSelect = (name: string, value: string) => {
    setOffer({ ...offer, [name]: value });
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setOffer({ ...offer, [name]: checked });
  };

  const handleDateChange = (name: string, date: Date) => {
    setOffer({ ...offer, [name]: date });
  };

  const handleCreate = (payload: any) => {
    setIsSaving(true);
    api
      .post('/premium_offers', payload)
      .then((res) => {
        const createdId: string = res.data?.id;
        toast.success('Paid subscription offer created successfully');
        onSuccess(createdId);
        resetForm();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleUpdate = (payload: any) => {
    setIsSaving(true);
    api
      .patch(`/premium_offers/${offerId}`, payload)
      .then((res) => {
        const updatedId: string = res.data?.id;
        toast.success('Paid subscription offer updated successfully');
        onSuccess(updatedId);
        resetForm();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const payload = {
      publication_id: publicationId,
      premium_offer: {
        name: offer?.name,
        description: offer?.description,
        offer_type: offer?.offer_type,
        product_exclusive_type: offer?.product_exclusive_type ? offer?.product_exclusive_type : null,
        expires_at: offer?.expires_at_set ? offer?.expires_at : null,
        trial_period_days: offer?.offer_type === 'trial_period' ? offer?.trial_period_days : null,
        pct_discount: offer?.offer_type === 'percent_discount' ? offer?.pct_discount : null,
        duration: offer?.offer_type === 'percent_discount' ? offer?.duration : null,
        duration_in_months:
          offer?.offer_type === 'percent_discount' && offer?.duration === 'repeating'
            ? offer?.duration_in_months
            : null,
        default_annual:
          offer?.default_annual &&
          (!offer?.product_exclusive_type ||
            (offer?.product_exclusive_type && offer?.product_exclusive_type !== PremiumOfferType.MONTHLY)),
        default_monthly:
          offer?.default_monthly &&
          (!offer?.product_exclusive_type ||
            (offer?.product_exclusive_type && offer?.product_exclusive_type !== PremiumOfferType.ANNUAL)),
        default_one_time:
          offer?.default_one_time &&
          (!offer?.product_exclusive_type ||
            (offer?.product_exclusive_type && offer?.product_exclusive_type !== PremiumOfferType.ONE_TIME)),
        card_required: offer?.card_required,
        upsell_email_enabled: offer?.upsell_email_enabled,
        upsell_email_period_days: offer?.upsell_email_period_days,
        upsell_email_send_before_end: offer?.upsell_email_send_before_end,
        tier_id: offer?.tier_id,
      },
    };

    if (offerId) {
      handleUpdate(payload);
      return;
    }

    handleCreate(payload);
  };

  useEffect(() => {
    if (offer?.offer_type === 'percent_discount' && !offer?.card_required) {
      setOffer({ ...offer, card_required: true });
    }
  }, [offer]);

  // If the offer is not a percent discount, then we don't allow it to be active for one-time payments
  useEffect(() => {
    if (offer?.offer_type !== 'percent_discount' && offer?.default_one_time) {
      setOffer({ ...offer, default_one_time: false });
    }
  }, [offer]);

  const upsellSpecificFields = (
    <>
      <Checkbox
        labelText="Send an email to remind readers their trial is going to expire"
        name="upsell_email_enabled"
        checked={offer?.upsell_email_enabled}
        onChange={(checked) => handleCheckboxChange('upsell_email_enabled', checked)}
      />
      {offer?.upsell_email_enabled && (
        <div className="space-y-4">
          <div className="flex flex-row items-baseline space-x-2">
            <span>Send an email </span>
            <Input
              className="w-24"
              name="upsell_email_period_days"
              type="number"
              value={offer.upsell_email_period_days}
              onChange={handleChange}
            />
            <span> days </span>
            <Dropdown
              name="upsell_email_send_before_end"
              value={offer?.upsell_email_send_before_end?.toString()}
              options={[
                { label: 'before', value: 'true' },
                { label: 'after', value: 'false' },
              ]}
              onSelect={handleSelect}
            />
            <span> the trial ends.</span>
          </div>
          <Button variant="primary-inverse" type="button" onClick={() => window.open('/upsell_email/edit')}>
            Edit Reminder Email
          </Button>
        </div>
      )}
    </>
  );

  const renderPercentDiscountSpecificFields = (
    <>
      <PercentSliderField
        label="Percent Discount"
        name="pct_discount"
        value={offer?.pct_discount}
        min={0}
        max={100}
        step={1}
        onChange={(val) => handleSelect('pct_discount', val.toString())}
      />
      <Dropdown
        name="duration"
        labelText="For how long"
        value={offer?.duration}
        onSelect={handleSelect}
        options={durationOptions}
        required
      />
      {offer?.duration === 'repeating' && (
        <Dropdown
          name="duration_in_months"
          labelText="Repeats for how many months"
          value={offer?.duration_in_months}
          onSelect={handleSelect}
          options={durationInMonthsOptions}
          required
        />
      )}
    </>
  );

  const renderTrialPeriodSpecificFields = (
    <Dropdown
      name="trial_period_days"
      labelText="Length of Trial"
      value={offer?.trial_period_days}
      onSelect={handleSelect}
      options={trialPeriodOptions}
      required
    />
  );

  const isActionDisabled = () => {
    if (!offer?.name || !offer?.offer_type) {
      return true;
    }

    if (offer?.offer_type === 'percent_discount') {
      if (!offer?.pct_discount) {
        return true;
      }

      if (!offer?.duration) {
        return true;
      }

      if (offer?.duration === 'repeating' && !offer?.duration_in_months) {
        return true;
      }
    }

    if (offer?.offer_type === 'trial_period') {
      if (!offer?.trial_period_days) {
        return true;
      }
    }

    return false;
  };

  const renderForm = (
    <div className="space-y-6">
      <Input
        name="name"
        labelText="Name"
        helperText="This is displayed to the subscriber as the name"
        type="text"
        value={offer?.name || ''}
        onChange={handleChange}
        required
      />
      <Textarea
        name="description"
        labelText="Description"
        helperText="This is displayed to the subscriber to add more context about the offer"
        value={offer?.description || ''}
        onChange={handleChange}
      />
      <Dropdown
        name="tier_id"
        labelText="Tier"
        value={offer?.tier_id || 'all'}
        onSelect={handleSelect}
        options={tierOptions}
      />
      <Dropdown
        name="offer_type"
        labelText="Terms"
        value={offer?.offer_type}
        onSelect={handleSelect}
        options={offerTypeOptions}
        required
      />
      {offer?.offer_type === 'percent_discount' && renderPercentDiscountSpecificFields}
      {offer?.offer_type === 'trial_period' && renderTrialPeriodSpecificFields}
      <h3>Advanced Options</h3>
      {settings?.cardless_trials && offer?.offer_type === 'trial_period' && (
        <Checkbox
          labelText="Allow trial redemption without a credit card"
          name="card_required"
          checked={Object.prototype.hasOwnProperty.call(offer, 'card_required') && !offer?.card_required}
          onChange={(checked) => handleCheckboxChange('card_required', !checked)}
        />
      )}
      {settings?.cardless_trials && !offer?.card_required && upsellSpecificFields}
      <Checkbox
        labelText="Set when subscribers must redeem this offer by"
        name="expires_at_set"
        checked={offer?.expires_at_set}
        onChange={(checked) => handleCheckboxChange('expires_at_set', checked)}
      />
      {offer?.expires_at_set && (
        <DatePicker
          inline
          className="mt-8"
          value={offer?.expires_at}
          minDate={new Date()}
          onChange={(date) => date && handleDateChange('expires_at', date)}
        />
      )}
      <Checkbox
        labelText="Limit this offer to specific plans"
        name="product_exclusive_type"
        checked={offer?.product_exclusive_type}
        onChange={(checked) =>
          setOffer({ ...offer, product_exclusive_type: checked, default_monthly: false, default_annual: false })
        }
      />
      {offer?.product_exclusive_type && (
        <Dropdown
          name="product_exclusive_type"
          value={offer?.product_exclusive_type}
          onSelect={handleSelect}
          options={productExclusiveTypeOptions}
        />
      )}
      <h3>Set this offering as the default offer on the upgrade page</h3>
      {offer &&
        (offer.product_exclusive_type === PremiumOfferType.MONTHLY ||
          !offer.product_exclusive_type ||
          offer.product_exclusive_type === null) && (
          <Checkbox
            labelText="Default Monthly Subscriptions Offer"
            name="default_monthly"
            checked={offer.default_monthly}
            onChange={(checked) => handleCheckboxChange('default_monthly', checked)}
          />
        )}
      {offer &&
        (offer.product_exclusive_type === PremiumOfferType.ANNUAL ||
          !offer.product_exclusive_type ||
          offer.product_exclusive_type === null) && (
          <Checkbox
            labelText="Default Annual Subscriptions Offer"
            name="default_annual"
            checked={offer.default_annual}
            onChange={(checked) => handleCheckboxChange('default_annual', checked)}
          />
        )}
      {settings?.one_time_payments &&
        offer?.offer_type === 'percent_discount' &&
        offer &&
        (offer.product_exclusive_type === PremiumOfferType.ONE_TIME ||
          !offer.product_exclusive_type ||
          offer.product_exclusive_type === null) && (
          <Checkbox
            labelText="Default One Time Payments Offer"
            name="default_one_time"
            checked={offer.default_one_time}
            onChange={(checked) => handleCheckboxChange('default_one_time', checked)}
          />
        )}
    </div>
  );

  const actions = (
    <div className="flex justify-end">
      <Button type="button" className="mx-2" variant="primary-inverse" block={false} onClick={onCloseSlideOver}>
        Cancel
      </Button>
      <Button
        variant="primary"
        type="button"
        block={false}
        loading={isSaving}
        onClick={handleSubmit}
        disabled={isActionDisabled()}
      >
        {offerId ? 'Update' : 'Create'}
      </Button>
    </div>
  );

  const renderBody = <div>{renderForm}</div>;

  return (
    <SlideOver
      bodyId={slideOverBodyId}
      headerText={offerId ? 'Update Offer' : 'Create Offer'}
      isOpen={isOpen}
      onClose={onCloseSlideOver}
      actionsChildren={actions}
    >
      {isLoading ? (
        <div className="text-center py-6">
          <LoadingSpinner />
        </div>
      ) : (
        renderBody
      )}
    </SlideOver>
  );
};

export default FormSlideOver;
