import { InputRule } from '@tiptap/core';

import { Figure } from '../Figure';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    blockquoteFigure: {
      setBlockquote: () => ReturnType;
      setBlockquoteVariant: (variant?: string) => ReturnType;
    };
  }
}

export const blockquoteInputRegex = /^\s*>\s$/;

export const BlockquoteFigure = Figure.extend({
  name: 'blockquoteFigure',

  group: 'block',

  content: 'quote quoteCaption',

  addAttributes() {
    return {
      ...this.parent?.(),
      variant: {
        default: '1',
        parseHTML: (element) => element.getAttribute('data-variant'),
        renderHTML: (attributes) => ({
          'data-variant': attributes.variant,
        }),
      },
    };
  },

  addCommands() {
    return {
      setBlockquote:
        () =>
        ({ chain, state, dispatch }) => {
          const parentJSON = state.selection.$from.parent.toJSON();
          const range = {
            from: state.selection.$from.start() - 1,
            to: state.selection.$from.end(),
          };

          const finalJson = {
            type: this.name,
            content: [
              {
                type: 'quote',
                content: [parentJSON],
              },
              {
                type: 'quoteCaption',
                content: [],
              },
            ],
          };

          const blockquoteChain = chain().insertContentAt(range, finalJson);

          if (dispatch) {
            return blockquoteChain.focus(range.from).run();
          }

          return blockquoteChain.run();
        },
      setBlockquoteVariant:
        (variant) =>
        ({ commands }) =>
          commands.updateAttributes('blockquoteFigure', { variant }),
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => false,
      'Mod-Shift-b': () => this.editor.commands.setBlockquote(),
    };
  },

  addInputRules() {
    return [
      new InputRule({
        find: blockquoteInputRegex,
        handler: ({ chain, range }) => {
          chain()
            .command(({ tr, dispatch }) => {
              return dispatch?.(tr.delete(range.from, range.to));
            })
            .setBlockquote()
            .run();
        },
      }),
    ];
  },
});

export default BlockquoteFigure;
