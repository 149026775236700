import React, { createContext, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';

// Define the context type with a generic parameter
interface ContextProps<T> {
  data: T;
}

const PageContext = createContext<ContextProps<unknown>>({ data: {} });
PageContext.displayName = 'PageContext';

type Props<T> = React.PropsWithChildren<{ value: T; title: string }>;

export const PageProvider = <T extends unknown>({ children, value, title }: Props<T>) => {
  // Create the context value
  const contextValue: ContextProps<T> = useMemo(
    () => ({
      data: value,
    }),
    [value]
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
    </>
  );
};

export const usePageContext = <T extends unknown>() => {
  const context = useContext(PageContext) as ContextProps<T>;

  if (!context) {
    throw new Error('usePageContext must be used within a PageProvider');
  }

  return context.data;
};
