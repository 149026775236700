import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { UpsellPage } from '@/components/UpsellPage';
import { useSettings } from '@/context/settings-context';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useUpgradeIntent from '@/hooks/useUpgradeIntent';
import { ReportType } from '@/interfaces/report';
import { IntentAction } from '@/interfaces/upgrades';
import { PLAN } from '@/utils/plans';

interface RouteParams {
  reportType: ReportType;
}

const Teaser = () => {
  const { settings } = useSettings();
  const navigate = useNavigate();
  const { reportType } = useParams() as unknown as RouteParams;
  const intentAction = {
    post: IntentAction.USE_POSTS_REPORT,
    subscriber: IntentAction.USE_SUBSCRIBERS_REPORT,
    click: IntentAction.USE_POSTS_REPORT, // mapping to the same intent action as post because its basically an extension of the functionality
  }[reportType];
  const publicationId = useCurrentPublicationId();
  const { pushDataLayerEvent } = useUpgradeIntent({ publicationId, intentAction });

  useEffect(() => {
    if (
      !Object.keys(ReportType)
        .map((t) => t.toLowerCase())
        .includes(reportType)
    ) {
      toast.error("Couldn't find that report");
      navigate('/');
    }
  }, [reportType, navigate]);

  useEffect(() => {
    if (settings?.reports) {
      toast.success('Reports are now enabled.');
      navigate(`/reports/${reportType}`);
    }
  }, [settings, navigate, reportType]);

  const title = {
    post: '3D Post Analytics',
    subscriber: '3D Subscriber Analytics',
    click: '3D Click Analytics',
  }[reportType];

  const youtubeUrl = {
    subscriber: 'https://www.youtube.com/embed/S6jb2PJrcOU',
    post: 'https://www.youtube.com/embed/gqhnetg6xAw',
    click: undefined,
  }[reportType];

  const cards = {
    subscriber: [
      {
        heading: 'Advanced Subscriber Analytics',
        description:
          "Optimize for growth and retention by understanding your subscribers' behavior and their activity.",
        imageUrl: 'https://media.beehiiv.net/static_assets/reports/subscribers.png',
      },
      {
        heading: 'Actionable Growth Insights',
        description:
          "We asked the best of the best in the industry to pitch us their dream growth dashboard. Now it's yours.",
        imageUrl: 'https://media.beehiiv.net/static_assets/reports/subscriber_example.png',
      },
    ],
    post: [
      {
        heading: 'Advanced Post Analytics',
        description: "Optimize for growth and retention by understanding your posts' performance and engagement.",
        imageUrl: 'https://media.beehiiv.net/static_assets/reports/posts.png',
      },
      {
        heading: 'Actionable Growth Insights',
        description:
          "We asked the best of the best in the industry to pitch us their dream growth dashboard. Now it's yours.",
        imageUrl: 'https://media.beehiiv.net/static_assets/reports/post_example.png',
      },
    ],
    click: [
      {
        heading: 'Advanced click analytics',
        description: 'See how links perform across all your posts.',
        imageUrl: 'https://media.beehiiv.net/static_assets/reports/posts.png',
      },
    ],
  }[reportType];

  return (
    <UpsellPage
      plan={PLAN.SCALE}
      title={title}
      description="The toolkit used by the world's top newsletters, now at your disposal."
      youtubeUrl={youtubeUrl}
      cards={cards || []}
      learnMoreUrl="https://www.beehiiv.com/newsletter-solutions/analyze"
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default Teaser;
