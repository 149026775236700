import { useMemo, useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import { Button } from '../../../ui/Button';
import { Icon } from '../../../ui/Icon';
import { Toolbar } from '../../../ui/Toolbar';

import { actions } from './constants';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: false,
  $muted: true,
};

export const TextUnitsSelect = ({
  defaultUnit,
  setTextUnit,
}: {
  defaultUnit?: string;
  setTextUnit?: Function;
}): JSX.Element => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const activeAction = useMemo((): any => {
    return actions.find((action) => {
      return action.label === defaultUnit;
    });
  }, [defaultUnit]);

  return (
    <Tippy
      render={(attrs) => (
        <Toolbar
          tabIndex={-1}
          $isVertical
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attrs}
        >
          {actions.map((action) => (
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonProps}
              key={action.label}
              $active={activeAction?.label === action.label}
              onClick={() => {
                if (setTextUnit) {
                  setTextUnit(action.label);
                }

                setIsPanelOpen(false);
              }}
              $fullWidth
            >
              {action.label}
            </Button>
          ))}
        </Toolbar>
      )}
      offset={[0, 8]}
      placement="bottom-start"
      interactive
      visible={isPanelOpen}
      onShow={() => {
        setIsPanelOpen(true);
      }}
      onHidden={() => {
        setIsPanelOpen(false);
      }}
    >
      <div>
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          $rightSlot={<Icon name="ChevronDown" />}
          $active
          onClick={() => {
            setIsPanelOpen(!isPanelOpen);
          }}
          style={{ height: '100%' }}
        >
          {defaultUnit}
        </Button>
      </div>
    </Tippy>
  );
};

export default TextUnitsSelect;
