import React, { createElement, CSSProperties, PropsWithChildren } from 'react';

import { cn } from '../../_utils/cn';

type Variants =
  | 'primary'
  | 'primary-soft'
  | 'invert-primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'on-accent'
  | 'success'
  | 'danger'
  | 'inherit'
  | 'invert';
type Sizes = '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'display-sm' | 'display-md' | 'display-lg';
type Weights = 'regular' | 'medium' | 'semibold' | 'bold' | 'black';

const variantClasses: Record<Variants, string> = {
  primary: 'text-wb-primary',
  'primary-soft': 'text-wb-primary-soft',
  'invert-primary': 'text-wb-invert-primary',
  secondary: 'text-wb-secondary',
  tertiary: 'text-wb-tertiary',
  accent: 'text-wb-accent',
  'on-accent': 'text-wb-on-accent',
  success: 'text-wb-success',
  danger: 'text-wb-danger',
  inherit: 'text-wb-inherit',
  invert: 'text-wb-invert-primary',
};

const sizeClasses: Record<Sizes, string> = {
  '3xs': 'text-[11px] leading-[18px] tracking-tight',
  '2xs': 'text-xs leading-[18px]',
  xs: 'text-[13px] leading-5',
  sm: 'text-sm leading-5',
  md: 'text-base leading-6',
  lg: 'text-lg leading-7',
  xl: 'text-xl leading-7 tracking-tight',
  '2xl': 'text-2xl leading-9 tracking-tight',
  'display-sm': 'text-[28px] tracking-tight',
  'display-md': 'text-3xl tracking-tight',
  'display-lg': 'text-4xl tracking-tight',
};

const weightClasses: Record<Weights, string> = {
  regular: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  black: 'font-black',
};

type TextProps = PropsWithChildren<{
  id?: string;
  as?: HTMLParagraphElement['tagName'] | HTMLSpanElement['tagName'];
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  variant?: Variants;
  size?: Sizes;
  weight?: Weights;
}>;

type TextType = HTMLParagraphElement | HTMLHeadingElement | HTMLSpanElement;

/**
 * @example
 * <Text variant={'error'} size={'lg'} weight={'medium'} />
 */
const Text = React.forwardRef<TextType, TextProps>(
  ({ as, className, style, variant = 'primary', size = 'md', weight = 'regular', ...props }, ref) => {
    return createElement(
      as ?? 'span',
      {
        ref,
        className: cn(variantClasses[variant], sizeClasses[size], weightClasses[weight], className),
        style,
        ...props,
      },
      props.children
    );
  }
);

export { Text };
