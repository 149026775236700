import { useQuery } from 'react-query';

import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import api from '@/services/swarm';

export default function useSvixEndpoint(publicationId: string, endpointId: string) {
  return useQuery<WebhookEndpoint>(
    ['publications', publicationId, 'webhooks', 'endpoints', endpointId],
    () =>
      api
        .get(`/webhooks/svix_endpoints/${endpointId}`, { params: { publication_id: publicationId } })
        .then((res) => res.data),
    {
      staleTime: 10000,
    }
  );
}
