import { Button } from '@/ui/Button';

import { Input } from '../../../../../../../components/Form';
import SlideOver from '../../../../../../../components/SlideOver';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  headerText: string;
  ctaText: string;
  loadingText: string;
  title: string;
  bodyId?: string;
}

const NavGroupForm = ({
  bodyId,
  ctaText,
  loadingText,
  headerText,
  isOpen,
  handleClose,
  handleSubmit,
  handleTitleChange,
  title,
  isLoading,
}: Props) => {
  return (
    <SlideOver bodyId={bodyId} headerText={headerText} isOpen={isOpen} onClose={handleClose}>
      <div>
        <Input
          name="title"
          labelText="Title"
          placeholder="Resources"
          helperText="This is the text your readers will see in the navbar."
          type="text"
          value={title}
          onChange={handleTitleChange}
          required
        />
        <div className="flex space-x-2 mt-4">
          <Button type="button" variant="primary-inverse" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={handleSubmit} loading={isLoading}>
            {isLoading ? loadingText : ctaText}
          </Button>
        </div>
      </div>
    </SlideOver>
  );
};

export default NavGroupForm;
