import Text from '@/components/Text';

const MetricCard = ({ label, value }: { label: string; value: string }) => (
  <div className="bg-white p-6 rounded-lg space-y-2 border border-gray-200 col-span-4 lg:col-span-2 xl:col-span-1 flex flex-col justify-between">
    <Text className="font-medium text-gray-700">{label}</Text>
    <Text size="3xl" className="font-semibold text-gray-900">
      {value}
    </Text>
  </div>
);

export default MetricCard;
