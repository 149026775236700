import { FC } from 'react';

import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { Referral } from '@/interfaces/referral';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import ReferralTableRow from './ReferralTableRow';

interface Props {
  referrals: Referral[] | undefined;
  subscriptionMilestoneId?: string;
  onDelete: Function;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
}

const ReferralTable: FC<Props> = ({
  referrals,
  subscriptionMilestoneId,
  onDelete,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: Props) => {
  if (!referrals) {
    return null;
  }

  return (
    <>
      <Items>
        <ItemHeaders>
          <ItemHeader align={AlignType.LEFT}>Email</ItemHeader>
          <ItemHeader align={AlignType.LEFT}>Date</ItemHeader>
          <ItemHeader align={AlignType.LEFT}>Status</ItemHeader>
          <ItemHeader align={AlignType.LEFT} />
        </ItemHeaders>

        <ItemsBody>
          {referrals.map((referral) => (
            <ReferralTableRow
              key={referral.id}
              referral={referral}
              onDelete={onDelete}
              subscriptionMilestoneId={subscriptionMilestoneId}
            />
          ))}
          {referrals.length === 0 ? (
            <ItemRow>
              <ItemColumn colSpan={4} align={AlignType.CENTER}>
                <Typography token="font-normal/text/sm" colorWeight="500">
                  No referrals yet
                </Typography>
              </ItemColumn>
            </ItemRow>
          ) : null}
        </ItemsBody>
      </Items>
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </>
  );
};

export default ReferralTable;
