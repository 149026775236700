import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

const PartnerProgramFAQ = () => {
  const [selectedFAQ, setSelectedFAQ] = useState<number>(0);

  const faqs = [
    {
      id: 1,
      question: "How do Tiers work?",
      answer: "Tiers are based on the lifetime conversions (new paying customers) you refer to beehiiv. As your conversions grow, you'll unlock higher commissions and bonus rewards. Check your current status in the Tiers tab. Everyone starts on our Launch tier with the ability to unlock 3 additional tiers based on performance.",
    },
    {
      id: 2,
      question: "How does the first conversion bonus work?",
      answer: "Once you achieve your first conversion you will unlock our Bronze tier and receive a $100 beehiiv Swag coupon, your very own custom co-branded landing page, access to content collaborations with the beehiiv partner team, and an exclusive Bronze partner badge.",
    },
    {
      id: 3,
      question: "How do payouts work?",
      answer: "Payments are made to all partners on the 15th of each month, which covers the commissionable earnings from the prior month. These payouts are made exclusively via Paypal.",
    },
    {
      id: 4,
      question: "What do I do if my PayPal payout fails?",
      answer: "If your PayPal payout fails when we make payouts you will need to simply update your PayPal address and or fix any pending issues with your PayPal account.",
    },
    {
      id: 5,
      question: "Can I run paid traffic to my partner link?",
      answer: "You cannot run search ads on Bing or Google, especially on branded terms or domain names. Doing so will result in a permanent ban. However, ads on Meta, LinkedIn, and other social platforms are allowed. Unsure? Review our TOS here.",
    },
    {
      id: 6,
      question: "What if a user upgrades or downgrades?",
      answer: "Your commission will simply be tracked and fluctuate up or down if a user you referred upgrades or downgrades their plan.",
    },
    {
      id: 7,
      question: "What is the cookie duration?",
      answer: "Cookies expire after 60-days. If a user for example signs up after clicking your link within that 60-day window and becomes a paying beehiiv customer, that qualifies for a commission!",
    },
    {
      id: 8,
      question: "Can I sign up using my own affiliate link?",
      answer: "Signing up with your own link is NOT allowed. If you do happen to sign up using your own link the commission will be removed.",
    },
    {
      id: 9,
      question: "What is the difference between Visitors, Leads, & Conversions?",
      answer: "Visitors would be the unique traffic you send to one of our pages. Leads would be the amount of that traffic that actually signs up for an account. Conversions are the amount of those leads that end up paying for any beehiiv plan.",
    },
  ];

  return (
    <div className="p-6 w-full flex flex-col space-y-6 bg-white border rounded-lg">
      <div className="flex flex-col space-y-3">
        {faqs.map((faq) => {
          const anchor = faq.question.replace(/\s+/g, '-').replace(/[.,/#!$%^&*;:{}=_`~()?]/g,"").toLowerCase();

          return (
            <div key={faq.id} id={anchor} className="flex flex-col space-y-3 w-full">
              <div className="flex flex-col w-full space-y-4">
                <button
                  onClick={() => {
                    if (faq.id === selectedFAQ) {
                      setSelectedFAQ(0);
                    } else {
                      setSelectedFAQ(faq.id);
                    }
                  }}
                  type="button"
                  className="flex flex-row justify-between items-between w-full"
                >
                  <p className="text-gray-900 font-medium">{faq.question}</p>
                  <ChevronDownIcon
                    className={cx('h-5 w-5 text-surface-500', faq.id === selectedFAQ && 'rotate-180')}
                  />
                </button>
                {faq.id === selectedFAQ && <p className="text-gray-700 text-sm">{faq.answer}</p>}
              </div>
              <hr className="w-full" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PartnerProgramFAQ;
