import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloudArrowUpIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import VideoModal from '@/components/Modal/VideoModal';
import { Typography, TypographyStack } from '@/components/Typography';
import { usePublication } from '@/hooks/usePublications';
import { useTutorial } from '@/hooks/useTutorials';
import { TutorialType } from '@/interfaces/tutorial';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import PageContainer from '../../_components/PageContainer';
import { usePublicationSettings } from '../context';

import HistoricalImportsCard from './HistoricalImportsCard';
import ImportLimit from './ImportLimit';
import RaiseImportLimitCard from './RaiseImportLimitCard';

const PublicationSubscribersImportSettings: React.FC = () => {
  const navigate = useNavigate();
  const { currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);
  const tutorial = useTutorial(TutorialType.IMPORT_SUBSCRIBERS);
  const [showVideoTutorialModal, setShowVideoTutorialModal] = useState(false);

  return (
    <>
      {tutorial ? (
        <VideoModal
          title=""
          src={tutorial.url}
          isOpen={showVideoTutorialModal}
          onClose={() => setShowVideoTutorialModal(false)}
        />
      ) : null}
      <LoadingBox isLoading={!isSuccess} isError={isError}>
        {publication ? (
          <PageContainer key={currentPublicationId}>
            <Card id="general" showCopyIdButton>
              <BodyContainer>
                <CardHeader
                  title="Subscribers Import"
                  description={
                    <TypographyStack>
                      <Typography token="font-normal/text/sm" colorWeight="500">
                        Import already-existing subscribers to your beehiiv publication’s subscriber list.
                      </Typography>
                      <button type="button" onClick={() => setShowVideoTutorialModal(true)} className="w-fit">
                        <Typography token="font-medium/text/sm" colorWeight="500" color="secondary">
                          Learn more about subscribers imports
                        </Typography>
                      </button>
                    </TypographyStack>
                  }
                />
                <ImportLimit publication={publication} />
                <Button
                  onClick={() =>
                    navigate(
                      appendSettingsPublicationId('/settings/publication/subscribers_import/new', publication.id)
                    )
                  }
                  Icon={CloudArrowUpIcon}
                  size="xs"
                  className="w-fit"
                >
                  New Subscribers Import
                </Button>
              </BodyContainer>
            </Card>
            <Card id="raise-limit" showCopyIdButton>
              <RaiseImportLimitCard publication={publication} />
            </Card>
            <Card id="history" showCopyIdButton>
              <HistoricalImportsCard publication={publication} />
            </Card>
          </PageContainer>
        ) : null}
      </LoadingBox>
    </>
  );
};

export default PublicationSubscribersImportSettings;
