export enum ContentImportStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  FAILED = 'failed',
}

export enum ContentImportPlatform {
  SUBSTACK = 'substack',
  WORDPRESS = 'wordpress',
  MAILCHIMP = 'mailchimp',
  GHOST = 'ghost',
  OTHER = 'other',
}

export interface ContentImport {
  id: string;
  publication_id: string;
  integration_id: string;
  integration_provider: string;
  status: ContentImportStatus;
  api_key: string;
  mailchimp_folders: string[];
  platform: string;
  url: string;
  file: File;
  num_posts_created: number;
  num_posts_failed: number;
  created_at: string;
  updated_at: string;
  started_at: string;
  finished_at: string;
  created_by_user: {
    id: string;
    name: string;
  };
}
