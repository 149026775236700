import { useNavigate } from 'react-router-dom';
import {
  ChartBarIcon,
  ChatBubbleBottomCenterTextIcon,
  PresentationChartBarIcon,
  UserGroupIcon,
} from '@heroicons/react/20/solid';

import {
  AccordionCard,
  AccordionIconWrapper,
  AccordionListItem,
} from '../../../components/Accordion/variants/AccordionCard';

const AnalyzeActions = () => {
  const navigate = useNavigate();

  return (
    <AccordionCard
      title={
        <div className="flex items-center">
          <AccordionIconWrapper>
            <ChartBarIcon className="w-4 h-4 text-feedback-info-500" />
          </AccordionIconWrapper>
          Analyze your posts
        </div>
      }
      subText="Get better insights on how your posts are performing."
    >
      <div className="space-y-1">
        <AccordionListItem
          title="Build a segment"
          icon={<UserGroupIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/segments')}
        />
        <AccordionListItem
          title="Start a poll"
          icon={<ChatBubbleBottomCenterTextIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/polls')}
        />
        <AccordionListItem
          title="Get advanced insights"
          icon={<PresentationChartBarIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/reports/post')}
        />
      </div>
    </AccordionCard>
  );
};

export default AnalyzeActions;
