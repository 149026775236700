import moment from 'moment-mini';

import { useCalendar } from '@/hooks/useCalendar';

import Modal from '../Modal';

import Grid from './Grid';
import SidePanel from './SidePanel';
import TopBar from './TopBar';
import { CalendarContext } from './useCalendarContext';

const FullCalendar = () => {
  const calendar = useCalendar({
    year: moment().year(),
    month: moment().month(),
  });

  return (
    <CalendarContext.Provider value={calendar}>
      <div className="grid grid-cols-8 lg:h-full" data-testid="calendar">
        <TopBar />
        <Grid />
        <SidePanel />
      </div>
    </CalendarContext.Provider>
  );
};

export default function FullCalendarModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} fullScreen>
      <FullCalendar />
    </Modal>
  );
}
