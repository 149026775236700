import { useQuery } from 'react-query';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { PlanPriceOption } from '@/interfaces/plan_price';
import api from '@/services/swarm';
import { PLAN } from '@/utils/plans';

interface Props {
  planName?: PLAN;
  interval?: PlanPriceOption['interval'];
  enabled?: boolean;
}

const usePlanPriceOptions = ({ interval, planName, enabled = true }: Props) => {
  const publicationId = useCurrentPublicationId();

  return useQuery<PlanPriceOption[]>(
    ['plan_price_options', planName, interval],
    () =>
      api
        .get(`/plan_price_options`, {
          params: {
            publication_id: publicationId,
            interval,
            plan_name: planName,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!enabled
    },
  );
};

export default usePlanPriceOptions;
