import common from 'email-providers/common.json';

const isCommonEmailProvider = (domain: string): boolean => {
  try {
    let url = domain;

    if (!domain.startsWith('https://') && !domain.startsWith('http://')) {
      url = `https://${domain}`;
    }

    const urlObject = new URL(url);
    const { hostname } = urlObject;
    const domainParts = hostname.split('.');

    let rootDomain = '';

    if (domainParts.length > 2) {
      rootDomain = domainParts.slice(-2).join('.');
    } else {
      rootDomain = hostname;
    }

    return common.includes(rootDomain);
  } catch (error) {
    return false;
  }
};

export default isCommonEmailProvider;
