import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

import { Button } from '@/ui/Button';

import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { ContentImport } from '../../../../../interfaces/content_import';
import { Pagination } from '../../../../../interfaces/general';
import api from '../../../../../services/swarm';
import { Section } from '../../../Components';

import ContentImportTable from './ContentImportTable';
import Create from './Create';

const ContentImports: React.FunctionComponent = () => {
  const [page, setPage] = useQueryParam<number>('page', withDefault(NumberParam, 1));
  const [pagination, setPagination] = useState<Pagination>();
  const [contentImports, setContentImports] = useState<ContentImport[]>([]);
  const [isFetchingImports, setIsFetchingImports] = useState(true);
  const [currentPublicationId] = useCurrentPublicationState();
  const [creatingNewImport, setCreatingNewImport] = useState<boolean>(false);

  const fetchImports = useCallback(() => {
    if (currentPublicationId) {
      const params = {
        publication_id: currentPublicationId,
        page: Number.isNaN(page) ? 1 : page,
      };

      api
        .get('/content_imports', { params })
        .then((res: any) => {
          setContentImports(res.data?.content_imports || []);
          setPagination(res.data?.pagination || {});
        })
        .catch((errPayload: any) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => setIsFetchingImports(false));
    }
  }, [currentPublicationId, page]);

  useEffect(() => {
    if (currentPublicationId) {
      fetchImports();
    }
  }, [currentPublicationId, fetchImports]);

  if (!currentPublicationId) {
    return null;
  }

  const onPageSelected = (targetPage: number) => {
    setPage(targetPage);
  };

  const renderImports = () => {
    if (isFetchingImports) {
      return <LoadingSpinner />;
    }

    return !isFetchingImports && pagination && pagination.total > 0 ? (
      <div className="mt-4 flex flex-col lg:flex-row">
        <div className="flex-grow space-y-6">
          <h2 className="font-medium">Historical Imports</h2>
          <ContentImportTable contentImports={contentImports} onPageSelected={onPageSelected} pagination={pagination} />
        </div>
      </div>
    ) : (
      <h1 className="mt-4 text-2xl leading-6 font-light text-gray-500 mx-auto">No Historical Imports</h1>
    );
  };

  if (creatingNewImport) {
    return (
      <Section
        title="New Import"
        description="Follow the instructions below to import your content."
        scrollToId="import_content"
        hasDivider={false}
      >
        <div className="relative">
          <button
            className="absolute -left-14 h-7 w-7 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
            type="button"
            onClick={() => setCreatingNewImport(false)}
          >
            <ArrowSmallLeftIcon className="h-4 w-4" />
          </button>
          <Create onClose={() => setCreatingNewImport(false)} />
        </div>
      </Section>
    );
  }

  return (
    <Section
      title="Import Content"
      description="Import already-existing content from wherever your publication is currently hosted."
      scrollToId="import_content"
      hasDivider={false}
    >
      <div className="py-4">{renderImports()}</div>
      <div className="py-4">
        <Button onClick={() => setCreatingNewImport(true)}>Get Started</Button>
      </div>
    </Section>
  );
};

export default ContentImports;
