import { Typography, TypographyStack } from '@/components/Typography';

interface Props {
  label: string;
  description: string;
  value: string;
}

const ClickMetric = ({ label, description, value }: Props) => {
  return (
    <div className="flex gap-x-6 justify-between">
      <TypographyStack>
        <Typography token="font-medium/text/sm" colorWeight="700">
          {label}
        </Typography>
        <Typography token="font-medium/text/xs" colorWeight="500">
          {description}
        </Typography>
      </TypographyStack>
      <Typography token="font-medium/text/lg">{value}</Typography>
    </div>
  );
};

export default ClickMetric;
