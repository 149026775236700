import React, { FC } from 'react';

import AutomationSelect from '@/components/AutomationSelect';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Condition, Filter } from '@/interfaces/segment';

import { defaultOperatorByConditionName } from './operatorOptions';

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleFilterChange: (filters: Filter, blockIndex: number, subIndex?: number) => void;
}

const AutomationActionSelect: FC<Props> = ({ condition, handleFilterChange, index, subIndex }) => {
  const defaultOperator = defaultOperatorByConditionName(condition?.name);
  const [currentPublicationId] = useCurrentPublicationState();

  const handleChange = (resourceId?: string) => {
    const updatedFilters = {
      resource_id: resourceId,
      operator: condition?.filters?.operator || defaultOperator,
    };

    const newFilters = { ...condition.filters, ...updatedFilters };

    handleFilterChange(newFilters, index, subIndex);
  };

  return (
    <AutomationSelect
      className="w-full"
      labelText=""
      automationId={condition.filters?.resource_id || ''}
      onSelectAutomation={(formId: string) => handleChange(formId)}
      onClearAutomation={() => handleChange(undefined)}
      publicationId={currentPublicationId}
      allowAnyAutomation
    />
  );
};

export default AutomationActionSelect;
