import KPI from '@/components/KPI';
import KPIGroup from '@/components/KPIGroup';
import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import { useSettings } from '@/context/settings-context';
import useEarningsPerPeriod from '@/hooks/boosts/monetize/useEarningsPerPeriod';
import { useTotalSubscriptionRevenuePerPeriod } from '@/hooks/usePremiumData';

import getLocaleString from '../../../utils/getLocaleString';
import { PageProps } from '../types';

const KPIs = () => {
  const { period } = usePageContext<PageProps>();
  const { data: boostsData, isLoading: isLoadingBoosts } = useEarningsPerPeriod({ period, boost_agreement_id: '' });
  const { data: subscriptionRevenue, isLoading: isLoadingSubscriptions } = useTotalSubscriptionRevenuePerPeriod(period);
  const { settings } = useSettings();

  const isBoostsEnabled = Boolean(settings?.boosts);
  const showBoostLoader = isLoadingBoosts && isBoostsEnabled;

  const boostValue = boostsData?.total || 0;
  const boostKpiValue = isBoostsEnabled ? `$${getLocaleString(boostValue)}` : '-';

  return (
    <KPIGroup>
      <KPI title="Ads" isLoading={false} pctDelta={undefined} value="Coming Soon" previousValue={undefined} />
      <KPI
        title="Paid Subscriptions"
        isLoading={isLoadingSubscriptions}
        pctDelta={undefined}
        value={subscriptionRevenue}
        previousValue={undefined}
        tooltipText="Data is only available down to a monthly level"
      />
      <KPI
        title="Boosts"
        isLoading={showBoostLoader}
        pctDelta={undefined}
        value={boostKpiValue}
        previousValue={undefined}
      />
    </KPIGroup>
  );
};

export default KPIs;
