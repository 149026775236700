import { RefObject, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountUp from 'react-countup';
import cx from 'classnames';

import { Publication } from '@/interfaces/publication';
import { PublicationRewind } from '@/interfaces/publication_rewind';

import { getDecimal, getImageUrl, getSuffix } from '../helpers';

type Props = {
  currentViewIndex: number;
  data?: PublicationRewind;
  index?: number;
  setSlideRef: (ref: RefObject<HTMLDivElement>) => void;
  currentPublication?: Publication;
};

const View7 = ({ currentPublication, setSlideRef, data, currentViewIndex, index }: Props) => {
  const [impressionsCount, setImpressionsCount] = useState(0);
  const [playAnimation, setPlayAnimation] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isCurrentView = currentViewIndex === index;

    if (isCurrentView) {
      setSlideRef(contentRef);
      setImpressionsCount(getDecimal(data?.most_clicked_post?.click_count || 0));
      setTimeout(() => {
        setPlayAnimation(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const postImageUrl = getImageUrl(data?.most_clicked_post?.thumbnail?.url || currentPublication?.thumbnail?.url);

  return (
    <div ref={contentRef} className="w-full h-full relative overflow-hidden rewind-bg-blue">
      <div className="rewind-bg-burst top-0 left-0 absolute w-full h-full" />
      <div className="rewind-bg-texture top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] bg-white rounded-[2rem] m-[5%_0_0_5%] p-[35px_55px] flex flex-col border-2 rewind-border-cyber-ink" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] m-[5%_0_0_5%] p-[35px_15px] flex flex-col">
        <div className="flex justify-center items-center w-full mt-6 mb-10">
          <img className="h-6 w-auto" src="/rewind-2024/images/beehiiv-logo-wordmark.png" alt="beehiiv logo" />
        </div>
        <h1
          className={cx(
            'font-clash-grotesk-v rewind-text-cyber-ink uppercase font-bold w-full text-[4rem] leading-[3.2rem] text-center mb-6 px-[13%] transition-all duration-300',
            !playAnimation && 'translate-x-20 opacity-0',
            playAnimation && 'translate-x-0 opacity-1'
          )}
        >
          AND THIS ONE SNAGGED THE MOST CLICKS.
        </h1>
        <div className="m-0 w-full p-[5%_13%_0_13%]">
          <div className="flex flex-col w-full justify-between items-center rewind-bg-ethereal-white rounded-xl p-6 mb-2">
            {postImageUrl && (
              <div className="w-full h-96 flex justify-center align-center mb-6">
                <img
                  src={postImageUrl}
                  alt={data?.most_clicked_post?.web_title}
                  className="max-w-full max-h-full self-center"
                />
              </div>
            )}
            <div className="w-full">
              {data?.most_clicked_post?.scheduled_at && (
                <div className="rounded-[0.875rem] bg-white rewind-text-blue text-sm px-2 inline-block">
                  {new Date(data.most_clicked_post?.scheduled_at).toLocaleDateString('en-US', dateOptions)}
                </div>
              )}
              <div className="font-satoshi-v rewind-text-cyber-ink text-2xl font-bold mt-2 mb-4 w-full truncate">
                {data?.most_clicked_post?.web_title}
              </div>
              <div className="flex font-bold text-[5.5875rem] leading-[3.5rem] font-clash-grotesk-v uppercase items-baseline mt-2">
                <div>
                  <CountUp
                    start={0}
                    end={impressionsCount}
                    duration={3}
                    separator=" "
                    decimals={(data?.most_clicked_post?.click_count || 0) > 1000 ? 1 : 0}
                    decimal="."
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                        <span>{getSuffix(data?.most_clicked_post?.click_count || 0)}</span>
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className="font-satoshi-v rewind-text-cyber-ink text-2xl font-bold ml-2">Clicks</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View7;
