import { useCurrentPublication } from '@/hooks';
import { useUpdatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import InputWithDescription from '../../../../ui/Settings/InputWithDescription';
import { SettingsContainer, SettingsGroup } from '../../../../ui/Settings/SettingsContainer';

const WebBuilderSocialLinks = () => {
  const { data: currentPublication } = useCurrentPublication();
  const currentPublicationId = useCurrentPublicationId();
  const publicationMutation = useUpdatePublication(currentPublicationId);

  const isValidUrl = (url: string): boolean => {
    /* eslint-disable no-new */

    try {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        new URL(url);
      } else {
        new URL(`http://${url}`);
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const socialLinks: any = [
    {
      name: 'facebook',
      title: 'Facebook',
      description: 'www.facebook.com/trybeehiiv',
      placeholder: 'Link',
      image: '',
      onSave: (val: boolean) => publicationMutation.mutateAsync({ facebook_url: val }),
      value: currentPublication?.facebook_url || '',
    },
    {
      name: 'twitter',
      title: 'X (Twitter)',
      description: 'www.twitter.com/beehiiv',
      placeholder: 'Link',
      image: '',
      onSave: (val: boolean) => publicationMutation.mutateAsync({ twitter_url: val }),
      value: currentPublication?.twitter_url || '',
    },
    {
      name: 'instagram',
      title: 'Instagram',
      description: 'www.instagram.com/beehiiv',
      placeholder: 'Link',
      image: '',
      onSave: (val: boolean) => publicationMutation.mutateAsync({ instagram_url: val }),
      value: currentPublication?.instagram_url || '',
    },
    {
      name: 'linkedin',
      title: 'LinkedIn',
      description: 'www.linkedin.com/in/mynewsletter',
      placeholder: 'Link',
      image: '',
      onSave: (val: boolean) => publicationMutation.mutateAsync({ linkedin_url: val }),
      value: currentPublication?.linkedin_url || '',
    },
    {
      name: 'youtube',
      title: 'YouTube',
      description: 'www.youtube.com/channel/mynewsletter',
      placeholder: 'Link',
      image: '',
      onSave: (val: boolean) => publicationMutation.mutateAsync({ youtube_url: val }),
      value: currentPublication?.youtube_url || '',
    },
    {
      name: 'tiktok',
      title: 'TikTok',
      description: 'www.tiktok.com/@beehiiv',
      placeholder: 'Link',
      image: '',
      onSave: (val: boolean) => publicationMutation.mutateAsync({ tiktok_url: val }),
      value: currentPublication?.tiktok_url || '',
    },
    {
      name: 'threads',
      title: 'Threads',
      description: 'www.threads.net/@beehiiv',
      placeholder: 'Link',
      image: '',
      onSave: (val: boolean) => publicationMutation.mutateAsync({ threads_url: val }),
      value: currentPublication?.threads_url || '',
    },
    {
      name: 'discord',
      title: 'Discord',
      description: 'www.discord.gg/mynewsletter',
      placeholder: 'Link',
      image: '',
      onSave: (val: boolean) => publicationMutation.mutateAsync({ discord_url: val }),
      value: currentPublication?.discord_url || '',
    },
    {
      name: 'bluesky',
      title: 'Bluesky',
      description: 'bsky.app/profile/beehiiv.com',
      placeholder: 'Link',
      image: '',
      onSave: (val: boolean) => publicationMutation.mutateAsync({ bluesky_url: val }),
      value: currentPublication?.bluesky_url || '',
    },
  ];

  return (
    <SettingsContainer>
      <SettingsGroup
        title="Social Links"
        description="Add links to your socials to redirect site users to your related social media profiles."
      >
        <div className="flex flex-col w-full space-y-6">
          {currentPublication &&
            socialLinks.map((social: any) => {
              return (
                <InputWithDescription
                  key={social.name}
                  title={social.title}
                  description={social.description}
                  placeholder={social.placeholder}
                  value={social.value}
                  onSave={social.onSave}
                  validationCheck={isValidUrl}
                  validationFailMessage="Please enter a valid URL"
                  toastSuccessMessage="Saved!"
                />
              );
            })}
        </div>
      </SettingsGroup>
    </SettingsContainer>
  );
};

export default WebBuilderSocialLinks;
