import { Label } from '@/components/Form';
import { AutomationStep } from '@/interfaces/automations/automation_step';

import EmailMessagePreview from '../components/EmailMessagePreview';

interface Props {
  automationStep: AutomationStep;
}

const ConfigureSendEmail = ({ automationStep }: Props) => {
  return (
    <div className="flex flex-col gap-y-2">
      <Label htmlFor="" value="Email template" />
      <EmailMessagePreview id={automationStep.email_message_id} />
    </div>
  );
};

export default ConfigureSendEmail;
