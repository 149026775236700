import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

interface Labels {
  [key: number]: string;
  default: string;
}

const GROWTH_LABELS: Labels = {
  1: 'Slow',
  2: 'Moderate',
  3: 'Fast',
  4: 'Very fast',
  5: 'Rocketship',
  default: 'N/A',
};

const ENGAGEMENT_LABELS: Labels = {
  1: 'Low',
  2: 'Fair',
  3: 'Good',
  4: 'Great',
  5: 'Excellent',
  default: 'N/A',
};

const SIZE_LABELS: Labels = {
  1: 'Small',
  2: 'Sizeable',
  3: 'Large',
  4: 'Very large',
  5: 'Massive',
  default: 'N/A',
};

const getLabel = (value: number, label: 'Engagement' | 'Size' | 'Growth'): string => {
  switch (label) {
    case 'Size':
      return SIZE_LABELS[value] ?? SIZE_LABELS.default;
    case 'Growth':
      return GROWTH_LABELS[value] ?? GROWTH_LABELS.default;
    case 'Engagement':
      return ENGAGEMENT_LABELS[value] ?? ENGAGEMENT_LABELS.default;
    default:
      return value.toString();
  }
};

const QualityRadar = ({ data }: { data: any }) => {
  return (
    <div className="h-72">
      <Radar
        className="rounded border p-4 h-32"
        data={data}
        options={{
          scales: {
            r: {
              angleLines: {
                display: false,
              },
              ticks: {
                display: false,
              },
              suggestedMin: 0,
              suggestedMax: 5,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  return `${context.dataset.label}: ${getLabel(context.raw as number, context.label as any)}`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default QualityRadar;
