import { useEffect, useMemo } from 'react';
import { Link, Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/20/solid';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { useCurrentPublication, useWelcomeEmail } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { EmailMessage } from '@/interfaces/email_message';
import { Tab } from '@/interfaces/general';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import TabNavigation from '@/ui/TabNavigation';

const WelcomeEmailView = () => {
  const { pathname } = useLocation();
  const currentPublicationId = useCurrentPublicationId();
  const { data: currentPublication } = useCurrentPublication();
  const { data: welcomeEmail } = useWelcomeEmail(currentPublicationId);
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/welcome_email') {
      navigate('/welcome_email/analytics');
    }
  }, [navigate, pathname]);

  const constructedTabs = useMemo(
    (): Tab[] => [
      {
        name: 'overview',
        label: 'Overview',
        targetRoute: '/welcome_email/overview',
        selected: pathname === '/welcome_email/overview',
      },
      {
        name: 'analytics',
        label: 'Analytics',
        targetRoute: '/welcome_email/analytics',
        selected: pathname === '/welcome_email/analytics',
      },
      {
        name: 'polls',
        label: 'Polls',
        targetRoute: '/welcome_email/polls',
        selected: pathname === '/welcome_email/polls',
      },
      {
        name: 'premium_data',
        label: 'Premium Data',
        targetRoute: '/welcome_email/premium_data',
        selected: pathname === '/welcome_email/premium_data',
        disabled: !currentPublication?.is_premium_enabled,
        disableWith: 'You must have a premium tier active',
      },
    ],
    [pathname, currentPublication]
  );

  const badgeType = welcomeEmail?.active ? 'success' : 'alert';
  const isEditing = pathname === '/welcome_email/edit';

  return (
    <div className="mx-auto sm:px-6 px-4 lg:px-8 mb-6">
      <div className="flex justify-between items-center pb-4">
        <div className="flex">
          <Breadcrumbs>
            <Breadcrumbs.Item to="/settings/publication">Publication Settings</Breadcrumbs.Item>
            <Breadcrumbs.Item to="/welcome_email/analytics">Welcome Email</Breadcrumbs.Item>
            {isEditing && <Breadcrumbs.Item to="/welcome_email/edit">Edit</Breadcrumbs.Item>}
          </Breadcrumbs>
        </div>
        {!isEditing && (
          <div className="flex">
            <Link to="/welcome_email/edit">
              <Button type="button">
                <div className="flex">
                  <PencilIcon className="w-5 h-5 mr-2" />
                  <span>Edit</span>
                </div>
              </Button>
            </Link>
          </div>
        )}
      </div>

      {welcomeEmail && (
        <>
          <div className="flex flex-col">
            <div className="mb-2">
              <Badge type={badgeType}>{welcomeEmail.status[0].toUpperCase() + welcomeEmail.status.slice(1)}</Badge>
            </div>
            <div className="flex items-center">
              <h1 className="text-2xl font-bold mr-2 max-w-4xl">Welcome Email</h1>
            </div>
          </div>
          <p className="text-gray-600 max-w-3xl">
            The email that is sent out to new readers right after they signup for your newsletter
          </p>

          {!isEditing && (
            <TabNavigation tabs={constructedTabs} className="sm:border-b pt-4 mb-4 px-4 lg:px-0" variant="tertiary" />
          )}

          <Outlet context={{ welcomeEmail }} />
        </>
      )}
    </div>
  );
};

type ContextType = { welcomeEmail: EmailMessage };

export const useWelcomeEmailContext = () => useOutletContext<ContextType>();

export default WelcomeEmailView;
