import useMediaQuery from '../../hooks/useMediaQuery';

import Modal from './index';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  src: string;
}

const VideoModal = ({ title, src, isOpen, onClose }: Props) => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const height = isMobile ? 315 / 1.7 : 315 * 1.5;
  const width = isMobile ? 560 / 1.7 : 560 * 1.5;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <iframe
        width={width}
        height={height}
        src={src}
        title={`${title} video player`}
        frameBorder={0}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Modal>
  );
};

export default VideoModal;
