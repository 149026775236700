import { useParams } from 'react-router-dom';

import LoadingBox from '../../../../components/LoadingBox';
import { useIndustry, useIndustryUpdate } from '../../../../hooks/useAdNetwork/internal';
import { PageHeading } from '../../_components/PageHeading';
import { Form } from '../_components/Form';

export default function Edit() {
  const { industry_id: id } = useParams<'industry_id'>() as { industry_id: string };
  const { data, isLoading, isError } = useIndustry({ id });
  const onSubmitMutation = useIndustryUpdate({ id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="../..">Industries</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="..">{data?.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Edit</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="mx-auto max-w-lg pt-4">
        <h1 className="text-xl">Edit industry</h1>
      </div>
      <LoadingBox isLoading={isLoading} isError={isError} height="100vh">
        {data && (
          <Form
            onSubmitMutation={onSubmitMutation}
            defaultName={data.name}
            defaultIndustryGroupId={data.industry_group_id}
          />
        )}
      </LoadingBox>
    </>
  );
}
