import { useMutation } from 'react-query';

import { AdNetworkCampaignOpportunityGroup } from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from '@/services/swarm';

export default function useCampaignOpportunityGroupsUpdate({ id }: { id: string }) {
  const updateGroup = (formData: FormData): Promise<AdNetworkCampaignOpportunityGroup> =>
    api.patch(`/ad_network/internal/campaign_opportunity_groups/${id}`, formData).then((res) => res.data);

  return useMutation<AdNetworkCampaignOpportunityGroup, MutationErrorResponse, FormData>(updateGroup, {});
}
