import { useParams } from 'react-router-dom';
import cx from 'classnames';

import Card from '@/components/Card';
import { useSettings } from '@/context/settings-context';
import { Badge } from '@/ui/Badge';

import SlideOver from '../../../../components/SlideOver';
import { useCommentReports } from '../../../../hooks/useComments';
import { Comment } from '../../../../interfaces/comment';
import { CommentReport } from '../../../../interfaces/comment_report';
import CommentDisplay from '../../Comments/Comment/CommentDisplay';
import CommentReportDisplay from '../CommentReport/CommentReportDisplay';

interface Props {
  bodyId: string;
  isOpen: boolean;
  onClose: () => void;
  comment?: Comment | null;
  resolvedReports?: boolean;
}

const ReportsSlideOver = ({ bodyId, isOpen, onClose, comment, resolvedReports }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const { postId } = useParams();
  const paginatedCommentReportsQuery = useCommentReports({
    postId: postId || '',
    resolved_reports: resolvedReports,
    enabled: Boolean(postId) && Boolean(comment?.id),
    commentId: comment?.id || '',
  });
  const { data } = paginatedCommentReportsQuery;
  const commentReports = data?.pages.flatMap((page) => page.comment_reports) || [];

  if (isV2) {
    return (
      <SlideOver bodyId={bodyId} isOpen={isOpen} onClose={onClose} headerText={isV2 ? 'Comment Reports' : 'Reports'}>
        <div className="flex flex-col gap-y-4">
          {comment ? (
            <Card>
              <div className="flex flex-col gap-y-2">
                <Badge type="warning" className="w-fit">
                  Reported Comment
                </Badge>
                <CommentDisplay
                  avatarUrl={comment.avatar_url}
                  commenter={comment.commenter}
                  time={comment.created_at}
                  content={comment.content}
                />
              </div>
            </Card>
          ) : null}
          <div className="pl-4 ml-4 flex flex-col gap-y-4 border-l border-dashed border-surface-200">
            {commentReports?.map((report: CommentReport) => {
              return (
                <CommentReportDisplay
                  key={report.id}
                  name={report.subscription.name}
                  reason={report.reason}
                  time={report.created_at}
                />
              );
            })}
          </div>
        </div>
      </SlideOver>
    );
  }

  return (
    <SlideOver bodyId={bodyId} isOpen={isOpen} onClose={onClose} headerText="Reports">
      <div className="space-y-4">
        {commentReports?.map((report: CommentReport, index: number) => {
          const isLast = index === commentReports.length - 1;

          return (
            <div key={report.id} className={cx('relative pb-4', !isLast && 'border-b border-gray-200')}>
              <CommentReportDisplay name={report.subscription.name} reason={report.reason} time={report.created_at} />
            </div>
          );
        })}
      </div>
    </SlideOver>
  );
};

export default ReportsSlideOver;
