import { Typography } from '../Typography';

interface Props {
  value: number;
  total: number;
  showLabel?: boolean;
  helperText?: string;
}

const ProgressBar = ({ value, total, showLabel = true, helperText }: Props) => {
  const usableValue = Math.min(value, total);
  const percentageCompletion = (usableValue / total) * 100;

  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex border border-gray-100 bg-white rounded-lg h-6 justify-between items-center p-1 gap-x-2">
        <div className="bg-surface-50 rounded-lg w-3/4 h-2/3">
          <div
            className="rounded-lg bg-surface-200 h-full"
            style={{
              width: `${percentageCompletion}%`,
            }}
          >
            &nbsp;
          </div>
        </div>
        {showLabel && (
          <Typography size="xs" colorWeight="700" weight="semibold">
            {usableValue}/{total}
          </Typography>
        )}
      </div>
      {helperText && (
        <Typography size="xs" colorWeight="500" weight="semibold">
          {helperText}
        </Typography>
      )}
    </div>
  );
};

export default ProgressBar;
