import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useUpdateEmailMessage } from '@/hooks';

const useUpdateOptInEmail = (publicationId: string, optInEmailId: string) => {
  const queryClient = useQueryClient();

  return useUpdateEmailMessage(publicationId, {
    emailMessageId: optInEmailId,
    onSuccess: () => {
      queryClient.invalidateQueries(['opt_in_email', publicationId], { exact: true });
      queryClient.invalidateQueries(['email_messages', 'preview', optInEmailId], { exact: true });
      toast.success('Opt In saved');
    },
    onError: (err: any) => toast.error(err?.response?.data?.error || 'Something went wrong, please try again'),
  });
};

export default useUpdateOptInEmail;
