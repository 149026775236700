import { Node } from '@tiptap/core';

export const GenericEmbedTitle = Node.create({
  name: 'genericEmbedTitle',

  content: 'inline*',

  group: 'block',

  defining: true,

  marks: '',

  parseHTML() {
    return [
      {
        tag: 'h3',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['h3', HTMLAttributes, 0];
  },
});

export default GenericEmbedTitle;
