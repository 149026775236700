import React, { FC } from 'react';

import getLocaleString from '@/utils/getLocaleString';

interface Props {
  pctDelta: number;
}

const DeltaBadge: FC<Props> = (props: Props) => {
  const { pctDelta } = props;

  if (pctDelta === 0) {
    return (
      <div className="inline-flex items-baseline rounded-full text-sm font-medium bg-gray-100 text-gray-800 md:mt-2 lg:mt-0 px-2.5 py-0.5">
        <svg
          className="-ml-1 flex-shrink-0 self-center h-5 w-5 text-gray-500 mr-0.5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 12H6" />
        </svg>
        {getLocaleString(pctDelta)}%
      </div>
    );
  }

  if (pctDelta >= 0) {
    return (
      <div className="inline-flex items-baseline rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0 px-2.5 py-0.5">
        <svg
          className="-ml-1 flex-shrink-0 self-center h-5 w-5 text-green-500 mr-0.5"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            clipRule="evenodd"
            d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
            fillRule="evenodd"
          />
        </svg>
        {getLocaleString(pctDelta)}%
      </div>
    );
  }

  return (
    <div className="inline-flex items-baseline rounded-full text-sm font-medium bg-red-100 text-red-800 md:mt-2 lg:mt-0 px-2.5 py-0.5">
      <svg
        className="-ml-1 flex-shrink-0 self-center h-5 w-5 text-red-500 mr-0.5"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          clipRule="evenodd"
          d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
          fillRule="evenodd"
        />
      </svg>
      {getLocaleString(pctDelta)}%
    </div>
  );
};

export default DeltaBadge;
