import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Pagination } from '../../interfaces/general';
import { Testimonial } from '../../interfaces/testimonial';
import api from '../../services/swarm';

interface ApiResponse {
  testimonials: Testimonial[];
  pagination: Pagination;
}

export default function useTestimonials({
  search,
  perPage = 10,
  all = false,
  exludedTestimonialIds,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
  exludedTestimonialIds?: string[];
}) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchTestimonials = ({ pageParam = 1 }) =>
    api
      .get(`/testimonials`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
          excluded_testimonial_ids: exludedTestimonialIds,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['testimonials', currentPublicationId, exludedTestimonialIds, search],
    fetchTestimonials,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      retry: false,
      keepPreviousData: true,
      onSuccess: (data) => data.pages.flatMap((page) => page.testimonials),
      onError: (errPayload: any) => errPayload?.response?.data?.message || 'Something went wrong',
    }
  );
}
