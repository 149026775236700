import { AlignCenterHorizontalSimple, AlignLeftSimple, AlignRightSimple } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';

import { useNavbarContext } from '../../../NavbarContext';

export const JustifySettings = () => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item' || !selectedContent.attrs?.showOnMobile) return null;

  const contentValue =
    selectedContent?.attrs && 'mobileJustify' in selectedContent.attrs ? selectedContent.attrs.mobileJustify : 'end';

  const handleMobileJustifyChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { mobileJustify: value });
  };

  const value = contentValue?.toString() || 'true';

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px] min-w-[80px]" variant="secondary" size="2xs" weight="medium">
        Align
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="true"
          value={value}
          onValueChange={handleMobileJustifyChange}
        >
          <ToggleGroupItem value="start" className="grow px-2">
            <AlignLeftSimple weight="fill" size={16} />
          </ToggleGroupItem>
          <ToggleGroupItem value="center" className="grow px-2">
            <AlignCenterHorizontalSimple weight="fill" size={16} />
          </ToggleGroupItem>
          <ToggleGroupItem value="end" className="grow px-2">
            <AlignRightSimple weight="fill" size={16} />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
