import { useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import DeleteSegmentModal from '@/pages/Segments/DeleteSegmentModal';
import { Button } from '@/ui/Button';

import Card from '../../../components/Card';
import { SegmentType } from '../../../interfaces/segment';
import SegmentForm from '../../Segments/SegmentForm';
import ImportSubscriberList from '../../Settings/Pages/Publication/ImportSubscribers/ImportSubscriberList';
import { useSegmentViewContext } from '..';

const EditSegment = () => {
  const { segment } = useSegmentViewContext();
  const [isDeleteRequested, setIsDeleteRequested] = useState(false);

  const navigate = useNavigate();

  const isPendingOrProcessing = segment.status === 'pending' || segment.status === 'processing';

  const handleImportSuccess = () => {
    navigate(`/segments/${segment.id}`);
    toast.success('Email import has begun. Please check back soon.');
  };

  const handleImportFailure = (errorMessage?: string) => {
    navigate(`/segments/${segment.id}`);
    toast.error(errorMessage || 'Something went wrong');
  };

  return (
    <>
      <Helmet>
        <title>Editing Segment {segment.name}</title>
      </Helmet>
      <div className="space-y-8">
        <SegmentForm segment={segment} />
        <hr />
        {segment.segment_type === SegmentType.MANUAL && (
          <>
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <h1 className="text-lg pb-0.5 leading-6 font-medium text-gray-900 mx-6 mt-6 flex items-center">
                Add subscribers
              </h1>
              <div className="p-6">
                <ImportSubscriberList
                  segmentId={segment.id}
                  onSuccess={handleImportSuccess}
                  onFailure={handleImportFailure}
                />
              </div>
            </div>
            <hr />
          </>
        )}
        <div className="group">
          <Card
            className={cx(
              'bg-white border-2 border-transparent rounded  duration-200 flex justify-between items-center',
              segment.deletable ? 'group-hover:border-red-500 group-hover:bg-red-50' : 'cursor-not-allowed'
            )}
          >
            <div>
              <p className="font-bold">Delete this segment</p>
              <p className="max-w-4xl">
                {segment.deletable
                  ? 'Once you delete this segment, it will be gone forever. Please be certain.'
                  : 'You cannot delete a segment that is used in the audience of a draft or scheduled post send. Unattach the segment from those posts or wait for the posts to be sent in order to delete this segment.'}{' '}
                {isPendingOrProcessing ? 'Deleting is disabled while the segment is processing' : ''}{' '}
              </p>
            </div>
            <div>
              <Button
                disabled={!segment.deletable || isPendingOrProcessing}
                variant="primary-inverse"
                onClick={() => setIsDeleteRequested(true)}
              >
                Delete
              </Button>
            </div>
          </Card>
        </div>
      </div>
      <DeleteSegmentModal
        isOpen={isDeleteRequested}
        segmentId={segment.id}
        onClose={() => setIsDeleteRequested(false)}
        onDelete={() => navigate('/segments')}
      />
    </>
  );
};

export default EditSegment;
