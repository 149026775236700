import cx from 'classnames';

import { colorByLogicalOperator, logicalOperatorOptions } from '@/components/ConditionsEditor/constants';
import GroupSelect from '@/components/Form/GroupSelect';
import { TreeHeader } from '@/components/Tree';
import { LogicalOperator } from '@/interfaces/condition';

interface Props {
  selectedLogicalOperator: LogicalOperator;
  onLogicalOperatorSelect: (value: LogicalOperator, index?: number) => void;
  index?: number;
}

const GroupHeader = ({ selectedLogicalOperator, onLogicalOperatorSelect, index }: Props) => (
  <TreeHeader borderClassName={colorByLogicalOperator[selectedLogicalOperator].treeHeader}>
    <div className="flex items-center">
      <GroupSelect
        name="logical_operator"
        value={selectedLogicalOperator}
        onSelect={onLogicalOperatorSelect}
        index={index}
        options={logicalOperatorOptions}
        buttonClassNames={{
          hover: colorByLogicalOperator[selectedLogicalOperator].hoverBackground,
          focus: cx(
            colorByLogicalOperator[selectedLogicalOperator].focusRing,
            colorByLogicalOperator[selectedLogicalOperator].focusBorder
          ),
          cursor: 'cursor-pointer',
          text: 'text-left text-sm text-gray-700 font-bold',
          padding: 'py-1 pl-3 pr-10',
          background: colorByLogicalOperator[selectedLogicalOperator].background,
          border: cx('border', colorByLogicalOperator[selectedLogicalOperator].border),
        }}
      />
    </div>
  </TreeHeader>
);

export default GroupHeader;
