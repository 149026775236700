import { useCallback, useEffect, useState } from 'react';
import { Palette } from '@phosphor-icons/react';
import { NavbarSerializableNode } from '@shared/dream-components';

import { useCurrentPublication } from '@/hooks';
import { useSite } from '@/hooks/useSite';
import { useSiteThemes } from '@/hooks/useSiteThemes';
import { SiteTheme, WebTheme } from '@/interfaces/web_theme';

import { Button } from '../../../../../../_components/UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../../_components/UI/Popover';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../../../../_components/UI/Select';
import { Text } from '../../../../../../_components/UI/Text';
import { Tooltip } from '../../../../../../_components/UI/Tooltip';
import { useNavbarContext } from '../../../NavbarContext';
import { applyThemeToNode, getParent } from '../../../utils';

export const THEME_ATTRS = [
  'header_font',
  'body_font',
  'button_font',

  'border_radius',

  'background_color',
  'text_on_background_color',

  'primary_color',
  'text_on_primary_color',
] as const;

const ThemeSettings = () => {
  const { data: currentPublication } = useCurrentPublication();
  const { data: siteThemesData } = useSiteThemes();
  const { data: site } = useSite();
  const { content, selectedContent, onUpdateNodeAttributes, onUpdateNodeContent } = useNavbarContext();

  const [selectedTheme, setSelectedTheme] = useState<SiteTheme | null>(null);

  const siteThemes = siteThemesData?.pages.flatMap((page) => page.site_themes) || [];
  const hasSiteThemes = siteThemes.length > 0;

  useEffect(() => {
    if (currentPublication?.web_theme && hasSiteThemes && !selectedTheme) {
      const theme = siteThemes.find((siteTheme) => siteTheme.is_primary);
      if (theme) {
        setSelectedTheme(theme);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedTheme, selectedTheme, hasSiteThemes]);

  const onApplyTheme = useCallback(() => {
    if (!site?.theme_rules) return;
    if (!selectedTheme) return;
    if (!selectedContent) return;
    if (!content) return;
    let contentToUpdate = selectedContent as NavbarSerializableNode;
    if (selectedContent.type === 'navbar_menu_list') {
      contentToUpdate = getParent(content, selectedContent) as NavbarSerializableNode;
    }

    const updatedNode = applyThemeToNode(contentToUpdate, selectedTheme.data, site?.theme_rules);

    onUpdateNodeAttributes(contentToUpdate.attrs?.id || '', updatedNode.attrs || {});
    if ('content' in updatedNode) {
      onUpdateNodeContent(contentToUpdate.attrs?.id || '', updatedNode.content || []);
    }
  }, [site?.theme_rules, selectedTheme, selectedContent, content, onUpdateNodeAttributes, onUpdateNodeContent]);

  return (
    <Popover>
      <Tooltip center="Apply Theme" delay={300}>
        <PopoverTrigger asChild>
          <Button variant="secondary" size="sm" LeftIcon={Palette} className="text-wb-secondary" onClick={() => {}} />
        </PopoverTrigger>
      </Tooltip>

      <PopoverContent className="w-[325px] p-0" align="start" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Theme Navbar
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Select
              defaultValue={selectedTheme?.name || 'Theme'}
              onValueChange={(value: string) => {
                const theme = siteThemes.find((siteTheme) => siteTheme.name === value);
                if (theme) {
                  setSelectedTheme(theme);
                }
              }}
            >
              <SelectTrigger className="w-full" id="comment_ordering">
                <SelectValue placeholder="Select an order" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {siteThemes.map((theme) => (
                    <SelectItem key={theme.id} value={theme.name}>
                      {theme.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>

            {THEME_ATTRS.map((attr) => {
              const normalizedAttr = attr.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
              const value = selectedTheme?.data?.[attr as keyof WebTheme];

              return (
                <div key={attr} className="flex items-center justify-between gap-2">
                  <Text size="xs" weight="medium" variant="secondary">
                    {normalizedAttr}
                  </Text>
                  <div className="flex items-center gap-2">
                    <Text size="xs" weight="medium">
                      {value}
                    </Text>
                    {value?.includes('#') && (
                      <div
                        className="w-4 h-4 rounded-md shadow-sm border border-wb-secondary"
                        style={{ backgroundColor: value }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <Button variant="primary" onClick={onApplyTheme}>
            Apply Theme
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ThemeSettings;
