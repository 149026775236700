import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import AttributeRow from '@/pages/Post/Overview/AttributeRow';
import { Card } from '@/ui/Card';
import { Iframe } from '@/ui/Iframe';

interface Props {
  subjectLine: string;
  previewText: string;
  html: string;
  isLoadingPreview: boolean;
  isErrorPreview: boolean;
}

const EmailOverview = ({ subjectLine, previewText, html, isLoadingPreview, isErrorPreview }: Props) => {
  return (
    <Card density="none" className="overflow-hidden">
      <div className="flex flex-col gap-y-4 p-6">
        <AttributeRow
          label="Subject"
          value={
            <Typography token="font-medium/text/sm" colorWeight="700">
              {subjectLine}
            </Typography>
          }
        />
        <AttributeRow
          label="Preview Text"
          value={
            <Typography token="font-medium/text/sm" colorWeight="700">
              {previewText}
            </Typography>
          }
        />
      </div>
      <div className="border-t pb-6">
        <LoadingBox isLoading={isLoadingPreview} isError={isErrorPreview}>
          {html ? (
            <Iframe title="preview" className="w-full overflow-hidden" html={html} shouldResizeHeight minHeight={750} />
          ) : null}
        </LoadingBox>
      </div>
    </Card>
  );
};

export default EmailOverview;
