import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { CustomPage } from '../../interfaces/custom_page';
import api from '../../services/swarm';

const useCustomPage = (pageId: string | undefined) => {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<CustomPage>(
    ['custom_page', publicationId, pageId],
    () =>
      api
        .get<any>(`/custom_pages/${pageId}`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!pageId && !!publicationId,
    }
  );
};

export default useCustomPage;
