import { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";

import Modal from "@/components/Modal";
import Text from '@/components/Text';
import { useAuth } from "@/context/auth-context";
import { useCurrentUser } from "@/context/current-user-context";
import { Button } from '@/ui/Button';

const goTo = (path: string) => {
  window.location.href = window.location.origin + path;
};

const Unmasquerade: React.FC = () => {
  const { isMasquerading, endMasquerade } = useAuth();
  const { currentUser, reloadUser } = useCurrentUser();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const endMasqueradeHandler = (path: string) => {
    endMasquerade
      .mutateAsync()
      .then(() => {
        reloadUser();
      })
      .then(() => {
        goTo(path);
      });
  };

  const handleNavBack = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      window.close();
    }
  };

  useEffect(() => {
    if (isMasquerading) {
      setModalOpen(true);
    }  
  }, [isMasquerading]);

  const requestedEmail = new URLSearchParams(location.search).get('email');

  let prompt = <div>Are you sure you want to end your masquerade<br />as {currentUser?.email}?</div>;

  let path = `${location.pathname}${location.search}`;

  if (location.pathname === '/system_admin/users/masquerade') {
    if (requestedEmail) {
      path = `/system_admin/users/masquerade?email=${requestedEmail}`;
      prompt = <div>Are you sure you want to change your masq?<br />From: {currentUser?.email}<br />To: {requestedEmail}</div>;
    } else {
      path = `/system_admin/organizations?query=${currentUser?.id}`;
    }
  }
 
  return (
    <Modal
      className="w-1/4"
      isOpen={modalOpen}
      onClose={() => {
        setModalOpen(false);
        handleNavBack();
      }}
    >
      <div className="p-8">
        <Text size="lg" type="bold" className="text-gray-800 w-fit pb-4">
          {prompt}
        </Text>
        <Button
          className="m-2"
          type="button"
          variant="primary"
          size="lg"
          onClick={() => endMasqueradeHandler(path)}
        >
          Yes
        </Button>
        <Button
          className="m-2"
          type="button"
          variant="secondary"
          size="lg"
          onClick={() => handleNavBack()}
        >
          No
        </Button>
      </div>
    </Modal>
  );
};

export default Unmasquerade;
