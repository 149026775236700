import { AutomationConditionName } from '@/interfaces/automations/automation_condition';
import { AutomationTriggerEvent } from '@/interfaces/automations/automation_trigger';

import { CONDITION_OPTIONS, ConditionOption } from '../components/ConditionsModal/constants';
import { CONDITIONS_BY_AUTOMATION_TRIGGER } from '../constants';

const triggerEventConditionOptions = (triggerEvent?: AutomationTriggerEvent) => {
  if (!triggerEvent) {
    return CONDITION_OPTIONS;
  }

  return CONDITION_OPTIONS.filter(
    (option: ConditionOption) =>
      option.isOptGroup ||
      CONDITIONS_BY_AUTOMATION_TRIGGER[triggerEvent].includes(option.value as AutomationConditionName)
  );
};

export default triggerEventConditionOptions;
