import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import NewCollectionForm from './NewCollectionForm';

const NewPublicationDomain = () => {
  const publicationId = useCurrentPublicationId();
  return <NewCollectionForm key={publicationId} publicationId={publicationId} />;
};

export default NewPublicationDomain;
