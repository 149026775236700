import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { TitleBase, Typography, TypographyStack } from '@/components/Typography';
import useStreakBadges from '@/hooks/useStreakBadges';

interface StreakComponentProps {
  type: string;
  streak_value: number;
  days_left?: number;
}

const NoStreakComponent = () => {
  return (
    <div className="w-full p-2.5 rounded-lg border bg-surface-50 flex flex-row space-x-2 border-surface-300">
      <img className="my-auto" alt="Inactive streak" src="/images/inactive-streak.svg" />
      <TypographyStack className="text-gray-800">
        <Typography className="font-semibold text-sm">🐣 Build your streak</Typography>
        <Typography className="font-medium text-xs">Publish a post to begin a new streak.</Typography>
      </TypographyStack>
    </div>
  );
};

const StreakComponent = ({ type, streak_value, days_left }: StreakComponentProps) => {
  return (
    <div
      className={`w-full px-3 py-2.5 border rounded-lg flex flex-row space-x-2 ${
        type === 'active' ? 'border-[#A78BFA] bg-[#F5F3FF]' : 'border-[#F87171] bg-[#FDF2F8]'
      }`}
    >
      <div className="relative text-center mb-auto h-fit">
        <span
          className={clsx(
            `absolute h-fit pb-0.5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-sm font-bold`,
            type === 'active' ? 'text-[#A78BFA]' : 'text-[#F77171]'
          )}
        >
          {streak_value}
        </span>
        <img
          height={42}
          width={42}
          className="min-h-[42px] min-w-[42px] my-auto"
          src={type === 'active' ? '/images/active-streak.svg' : '/images/hot-streak.svg'}
          alt={type === 'active' ? 'Active Streak' : 'Hot Streak'}
        />
      </div>
      <div className="flex flex-col my-auto space-y-0.5">
        <p className="text-gray-900 font-semibold text-sm">
          {type === 'active' ? `🔥 ${streak_value} week streak` : '⏳ Save your weekly streak!'}
        </p>
        <p className="font-medium text-xs text-gray-900">
          {type === 'active'
            ? "Congrats, you're on a roll. Keep it going!"
            : `Your streak is going to expire in ${days_left} days.`}
        </p>
      </div>
    </div>
  );
};

const Streak = () => {
  const [streakType, setStreakType] = useState<string | null>(null);
  const streakData = useStreakBadges();

  useEffect(() => {
    if (streakData.data) {
      if (streakData.data.week_streak === 0) {
        setStreakType('noStreak');
      } else if (!streakData.data.sent_this_week) {
        setStreakType('inactiveStreak');
      } else {
        setStreakType('activeStreak');
      }
    }
  }, [streakData]);

  if (!streakData?.data) {
    return <></>;
  }

  return (
    <div className="space-y-2">
      <TitleBase>Streak</TitleBase>
      {streakType === 'noStreak' && <NoStreakComponent />}
      {streakType === 'activeStreak' && <StreakComponent type="active" streak_value={streakData.data.week_streak} />}
      {streakType === 'inactiveStreak' && (
        <StreakComponent
          type="inactive"
          streak_value={streakData.data.week_streak}
          days_left={streakData.data.days_left}
        />
      )}
    </div>
  );
};

export default Streak;
