import { Plus } from "@phosphor-icons/react"

import { Text } from "@/routes/website/_components/UI/Text";

import { useNavbarContext } from "../NavbarContext";

export const NavbarEmptyState = () => {
  const { onCreateNavbar } = useNavbarContext();
  return <div
    className="w-full p-2 flex items-center justify-center rounded-xl hover:shadow-lg transition-shadow duration-200 cursor-pointer box-border"
    onClick={onCreateNavbar}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onCreateNavbar();
      }
    }}
    role="button"
    tabIndex={0}
  ><div
    className="w-full h-[60px] border border-wb-accent border-dashed p-2 flex items-center justify-center rounded-xl box-border gap-2"
  >
      <Plus size={16} className="text-wb-secondary" />
      <Text size="sm" variant="secondary">Create Navbar</Text>
    </div>
  </div>
}
