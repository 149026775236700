import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

import ActivityFeed from '../_components/ActivityFeed';
import { useSearchContext } from '../_context/searchContext';
import { useSubscriberContext } from '../_context/subscriberContext';
import { useOtherEmailActivityFeedEvents } from '../_hooks/useActivityFeedEvents';

const SubscriberOtherEmailActivity = () => {
  const { search, eventType } = useSearchContext();
  const { subscription } = useSubscriberContext();
  const query = useOtherEmailActivityFeedEvents({ search, subscription, eventType });

  return (
    <ActivityFeed
      title="Other Emails Activity Feed"
      emptyText="No other email events for this subscriber have been recorded yet."
      query={query}
      eventTypeOptions={[
        ActivityFeedEventType.SENT,
        ActivityFeedEventType.DELIVERED,
        ActivityFeedEventType.OPEN,
        ActivityFeedEventType.CLICK,
        ActivityFeedEventType.UNSUBSCRIBE,
        ActivityFeedEventType.SPAM_REPORT,
        ActivityFeedEventType.DEFERRAL,
        ActivityFeedEventType.DELIVERY_ERROR,
      ]}
    />
  );
};

export default SubscriberOtherEmailActivity;
