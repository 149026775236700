import { PartnerTierSetting } from "@/interfaces/partner_tier";
import { capitalize } from "@/utils";

interface Params {
  tierSetting: PartnerTierSetting;
}

const FinishedBox = ({ tierSetting }: Params) => {
  return (
    <div
      className="w-[360px] h-[294px] p-5 bg-gray-50 rounded-lg border border-gray-200 flex-col justify-center items-center gap-10 inline-flex">
      <div className="self-stretch h-[102px] flex-col justify-start items-center gap-6 flex">
        <div className="w-[34px] h-[38px] relative">
          <img alt={`${tierSetting.name} Tier`} className="w-[34px] h-[38px]"
            src={`/images/partner-tier-${tierSetting.name}.svg`} />
        </div>
        <div className="text-center text-gray-600 text-sm font-['SF Pro'] leading-tight">
          You already completed the {capitalize(tierSetting.name)} level
          <br />
          Great Job!
        </div>
      </div>
    </div>
  );
};

export default FinishedBox;