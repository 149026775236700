import { Extension } from '@tiptap/core';
import { NodeSelection, TextSelection } from '@tiptap/pm/state';

import { MultiNodeSelection } from '../CustomSelections/selections';

type ActiveNodeOptions = {};
type ActiveNodeStorage = {
  activeDOMNode?: Element;
  activeNodeRect?: DOMRect;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    activeNode: {
      updateActiveNode: () => ReturnType;
    };
  }
}

export const ActiveNode = Extension.create<ActiveNodeOptions, ActiveNodeStorage>({
  name: 'activeNode',

  addStorage() {
    return {
      activeDOMNode: undefined,
      activeNodeRect: undefined,
    };
  },

  addCommands() {
    return {
      updateActiveNode:
        () =>
        ({ editor }) => {
          const {
            state: { selection },
            view,
          } = editor;
          const { $from } = selection;

          if (!editor.isFocused) return true;

          if (selection instanceof NodeSelection || selection instanceof MultiNodeSelection) {
            const nodeDOM = view.nodeDOM($from.pos)?.firstChild as Element | null;

            if (nodeDOM) {
              this.storage.activeDOMNode = nodeDOM;
              this.storage.activeNodeRect = nodeDOM.getBoundingClientRect();
            }
          } else if (selection instanceof TextSelection) {
            // leaf node, select the parent
            const nodeDOM = view.nodeDOM($from.before())?.firstChild as Element | null;

            if (nodeDOM) {
              this.storage.activeDOMNode = nodeDOM;
              this.storage.activeNodeRect = nodeDOM.getBoundingClientRect();
            }
          }

          return true;
        },
    };
  },

  onSelectionUpdate() {
    requestAnimationFrame(() => {
      this.editor.commands.updateActiveNode();
    });
  },

  onUpdate() {
    // We want to wait for the next frame to update the active node to ensure the DOM is updated
    requestAnimationFrame(() => {
      this.editor.commands.updateActiveNode();
    });
  },

  onBlur() {
    this.storage.activeDOMNode = undefined;
    this.storage.activeNodeRect = undefined;
  },
});
