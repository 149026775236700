import styled from 'styled-components';

export const Styled = {
  Wrapper: styled.span<{ $size?: string; $display?: string }>`
    display: ${({ $display }) => $display || 'inline-block'};
    height: ${({ $size }) => $size || '1rem'};
    width: ${({ $size }) => $size || '1rem'};

    svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  `,
};

export default Styled;
