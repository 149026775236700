import { useState } from 'react';
import { UseMutationResult } from 'react-query';

import ActionModal from '../../../components/ActionModal';
import { Input } from '../../../components/Form';

interface ConfirmDeleteProps {
  deleteMutation: UseMutationResult<any, unknown, any, unknown>;
  warning: string;
  confirmWith: string;
}

interface ConfirmDeleteModalProps {
  mutateAsync: UseMutationResult<any, unknown, any, unknown>['mutateAsync'];
  isDeleting: boolean;
  warning: string;
  confirmWith: string;
  requestedDelete: boolean;
  setRequestedDelete: (value: boolean) => void;
  confirmText: string;
  setConfirmText: (value: string) => void;
}

const ConfirmDeleteModal = ({
  mutateAsync,
  warning,
  isDeleting,
  confirmWith,
  requestedDelete,
  setRequestedDelete,
  confirmText,
  setConfirmText,
}: ConfirmDeleteModalProps) => {
  return (
    <ActionModal
      isOpen={requestedDelete}
      isWorking={isDeleting}
      onClose={() => setRequestedDelete(false)}
      onProceed={() => mutateAsync({})}
      resourceId="resourceId" // not needed
      headerText="Are you sure?"
      disabled={confirmText !== confirmWith}
      modalSize="md"
    >
      <div className="space-y-2">
        <p>{warning}</p>
        <Input
          type="text"
          className="mt-2 form-input block w-full"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
          name="confirm"
          placeholder={confirmWith}
          labelText={`To confirm, enter "${confirmWith}" in the field below.`}
        />
      </div>
    </ActionModal>
  );
};

const useConfirmDelete = ({ deleteMutation, warning, confirmWith }: ConfirmDeleteProps) => {
  const { mutateAsync, isLoading: isDeleting } = deleteMutation;
  const [requestedDelete, setRequestedDelete] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const handleDelete = () => {
    setRequestedDelete(true);
  };

  const modalProps = {
    mutateAsync,
    isDeleting,
    warning,
    confirmWith,
    requestedDelete,
    setRequestedDelete,
    confirmText,
    setConfirmText,
  };

  return { ConfirmDeleteModal, modalProps, isDeleting, handleDelete };
};

export default useConfirmDelete;
