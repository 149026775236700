import { useQuery } from 'react-query';

import type { SmartWarmingProgressData } from '@/interfaces/smart_warming_progress';
import api from '@/services/swarm';

export const useSmartWarmingProgress = (publicationId: string, customDomainId: string, enabled: boolean) => {
  return useQuery<SmartWarmingProgressData>(
    ['smartWarmingProgress', customDomainId],
    () =>
      api
        .get(`/custom_domains/${customDomainId}/smart_warming_progress`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled,
      refetchInterval: 150000,
    }
  );
};

export default useSmartWarmingProgress;
