import { useQuery } from 'react-query';

import { AdNetworkCampaignReport } from '@/interfaces/ad_network/advertiser_access/campaign_report';
import api from '@/services/swarm';

export default function useCampaignReport({ advertiserId, campaignId }: { advertiserId: string; campaignId: string }) {
  return useQuery<AdNetworkCampaignReport>(
    ['ad_network', 'advertiser_access', advertiserId, 'campaign_reports', campaignId],
    () =>
      api
        .get(`/ad_network/advertiser_access/${advertiserId}/campaign_reports/${campaignId}`, {})
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
