import { useCallback, useEffect } from 'react';
import { Code, TextBolder, TextItalic, TextStrikethrough, TextUnderline } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { cn } from '../../../../_utils/cn';
import { Text } from '../../../UI/Text';
import { Toggle } from '../../../UI/Toggle';
import { useActiveNode } from '../../extensions/ActiveNode/hooks/useActiveNode';

export const getDefaultAlignment = () => {
  return 'left';
};

export const FormatSettings = ({ editor }: { editor: Editor }) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = useActiveNode(editor);

  const isBold = editor.isActive('bold');
  const isItalic = editor.isActive('italic');
  const isStrikethrough = editor.isActive('strike');
  const isUnderline = editor.isActive('underline');
  const isCode = editor.isActive('code');

  const onBold = useCallback(() => editor.chain().selectTextBlock().toggleBold().focus().run(), [editor]);
  const onItalic = useCallback(() => editor.chain().selectTextBlock().toggleItalic().focus().run(), [editor]);
  const onUnderline = useCallback(() => editor.chain().selectTextBlock().toggleUnderline().focus().run(), [editor]);
  const onStrike = useCallback(() => editor.chain().selectTextBlock().toggleStrike().focus().run(), [editor]);
  const onCode = useCallback(() => editor.chain().selectTextBlock().toggleCode().focus().run(), [editor]);

  useEffect(() => {}, [activeNodeAttributes, activeNodePos, activeNodeType, editor]);

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Format
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <div className="flex items-center justify-center gap-1 p-[2px]">
          <Toggle pressed={isBold} className="grow p-1" onPressedChange={onBold}>
            <TextBolder
              weight="bold"
              format="outline"
              className={cn('h-4 w-4', isBold ? 'text-wb-accent' : 'text-wb-secondary')}
            />
          </Toggle>
          <Toggle pressed={isUnderline} className="grow p-1" onPressedChange={onUnderline}>
            <TextUnderline
              weight="bold"
              format="outline"
              className={cn('h-4 w-4', isUnderline ? 'text-wb-accent' : 'text-wb-secondary')}
            />
          </Toggle>
          <Toggle pressed={isItalic} className="grow p-1" onPressedChange={onItalic}>
            <TextItalic
              weight="bold"
              format="outline"
              className={cn('h-4 w-4', isItalic ? 'text-wb-accent' : 'text-wb-secondary')}
            />
          </Toggle>
          <Toggle pressed={isStrikethrough} className="grow p-1" onPressedChange={onStrike}>
            <TextStrikethrough
              weight="bold"
              format="outline"
              className={cn('h-4 w-4', isStrikethrough ? 'text-wb-accent' : 'text-wb-secondary')}
            />
          </Toggle>
          <Toggle pressed={isCode} className="grow p-1" onPressedChange={onCode}>
            <Code
              weight="bold"
              format="outline"
              className={cn('h-4 w-4', isCode ? 'text-wb-accent' : 'text-wb-secondary')}
            />
          </Toggle>
        </div>
      </div>
    </div>
  );
};
