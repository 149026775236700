import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { BarList, Flex } from '@tremor/react';

import * as CardUI from '@/components/Card/CardUI';
import { TitleSm } from '@/components/Typography';
import { Button } from '@/ui/Button';

interface ListData {
  name: string;
  value: number;
}

interface Props {
  title: string;
  listData: ListData[];
  cta: {
    text: string;
    onClick: () => void;
  };
}

const Sources = ({ title, listData, cta }: Props) => {
  const slicedData = listData?.slice(0, 8) || [];
  const noResults = slicedData.length === 0;

  return (
    <CardUI.Card className="flex flex-col justify-between">
      <div>
        <div className="flex justify-between">
          <TitleSm>{title}</TitleSm>
          <TitleSm>Count</TitleSm>
        </div>

        {noResults ? (
          <div className="relative w-full h-60">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-2">
              <div className="px-4 text-center">
                <TitleSm className="text-sm">No Results</TitleSm>
              </div>
            </div>
          </div>
        ) : (
          <BarList data={slicedData} showAnimation={false} className="mt-4" color="pink" />
        )}
      </div>

      <Flex className="mt-6 pt-4 border-t">
        <Button size="xs" variant="flush" Icon={ArrowRightIcon} iconRight onClick={cta.onClick}>
          {cta.text}
        </Button>
      </Flex>
    </CardUI.Card>
  );
};

export default Sources;
