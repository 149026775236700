import moment from 'moment-mini';

import { useCurrentTimeZone } from '@/hooks';
import useOpportunities from '@/hooks/useAdNetwork/publisher/useOpportunities';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

type GroupedOpportunities = Array<{
  date: string;
  opportunities: AdNetworkOpportunity[];
}>;

const UpcomingOpportunities = () => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } = useOpportunities({
    filter: 'upcoming',
  });

  const opportunities = data?.pages.flatMap((page) => page.opportunities) || [];

  const groupedOpportunities = opportunities.reduce((group, opportunity) => {
    const date = moment(opportunity.selected_date as string).format('dddd, MMMM D');
    const existingGroup = group.find((g) => g.date === date);

    if (existingGroup) {
      existingGroup.opportunities.push(opportunity);
    } else {
      group.push({ date, opportunities: [opportunity] });
    }

    return group;
  }, [] as GroupedOpportunities);

  const currentTimeZone = useCurrentTimeZone();

  if (!isLoading && !opportunities?.length) return null;

  return (
    <div className="space-y-4">
      <p className="font-semibold text-gray-900">Upcoming</p>
      {groupedOpportunities.map((group) => (
        <>
          <div className="border-b border-gray-200 pb-2">
            <p className="font-bold text-sm text-feedback-info-600">{group.date}</p>
          </div>
          <div className="space-y-4">
            {group.opportunities.map((opportunity) => (
              <Card density="compact">
                <div className="space-y-2">
                  <div className="space-y-1">
                    <p className="font-bold">
                      {opportunity.advertiser.name} / {opportunity.campaign.name}
                    </p>
                    <p className="text-sm text-gray-400">
                      Send by {moment(opportunity.selected_date as string).format('h:mm A')} {currentTimeZone}
                    </p>
                  </div>
                  <div>
                    <Button variant="primary-inverse" size="xs" to={`/monetize/ads/opportunities/${opportunity.id}`}>
                      View details
                    </Button>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </>
      ))}
      {hasNextPage && (
        <Button
          variant="primary-inverse"
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
          loading={isFetchingNextPage}
        >
          {isFetchingNextPage ? 'Loading...' : 'Load more'}
        </Button>
      )}
    </div>
  );
};

export default UpcomingOpportunities;
