import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import { BarChartDatum as Datum } from '@/components/charts';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

export interface Milestone {
  id: string;
  name: string;
  trimmed_name: string;
  total_achieved: number;
}

const transformMilestonesToData = (milestones: Milestone[]): Datum[] =>
  milestones.map((milestone) => ({
    label: milestone.trimmed_name,
    value: milestone.total_achieved,
    tooltipTitle: milestone.name,
  }));

interface Props {
  children: (milestones: Datum[] | undefined, isLoading: boolean) => React.ReactNode;
}

const MilestoneBreakdownChartContainer = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [milestones, setMilestones] = useState<Datum[]>();
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBreakdown = useCallback(() => {
    if (currentPublicationId) {
      const params = {
        publication_id: currentPublicationId,
      };

      setIsLoading(true);
      api
        .get('/referral_program/metrics/milestone_breakdown', { params })
        .then((res) => {
          const data = transformMilestonesToData(res.data.milestones);
          setMilestones(data);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPublicationId]);

  useEffect(() => {
    fetchBreakdown();
  }, [fetchBreakdown]);

  return <>{children(milestones, isLoading)}</>;
};

export default MilestoneBreakdownChartContainer;
