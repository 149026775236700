import { memo } from 'react';
import ReactTooltip from 'react-tooltip';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import moment from 'moment-mini';

import { TiptapEditor } from '@/components/TiptapEditor';
import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { GlobalStyles } from '@/components/TiptapEditor/lib/GlobalStyles';
import { usePublicationColorPalette } from '@/hooks';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { EmailMessage } from '@/interfaces/email_message';

import ScheduleModal from './ScheduleModal';

const MemoizedTiptapEditor = memo(TiptapEditor);

interface LeftPaneProps {
  emailMessage: EmailMessage;
  schedulable: boolean;
  isSaving: boolean;
  lastUpdated: string;
  onExit: () => void;
  onChange: (params: any) => void;
  onSchedule?: (emailMessage: EmailMessage, date: Date) => Promise<void>;
  allowPolls: boolean;
}

const LeftPane: React.FunctionComponent<LeftPaneProps> = ({
  emailMessage,
  schedulable,
  isSaving,
  lastUpdated,
  onChange,
  onSchedule,
  onExit,
  allowPolls,
}: LeftPaneProps) => {
  const { publication_id: publicationId } = emailMessage;
  const { data: colorPalette } = usePublicationColorPalette(publicationId);
  const { data: settings } = usePublicationSettings(publicationId);

  return (
    <div className="h-full flex flex-col m-0">
      <div className="sticky top-0 md:block flex-none z-10">
        <div className="w-full bg-white h-12 border-b flex items-center justify-between px-6 z-10">
          <button
            type="button"
            className="text-sm text-gray-500 hover:text-gray-800 flex items-center cursor-pointer"
            onClick={onExit}
          >
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            Exit
          </button>
        </div>
      </div>
      <div className="overflow-auto flex-auto">
        <div className="pt-12 pb-20 px-2 z-0 overflow-">
          {settings && colorPalette && (
            <PublicationProvider id={publicationId}>
              <GlobalStyles colors={colorPalette} />
              <MemoizedTiptapEditor
                onUpdate={({ editor }: any) => onChange({ tiptap_state: editor.getJSON() })}
                publicationId={publicationId}
                settings={settings}
                content={emailMessage.tiptap_state}
                allowPolls={allowPolls}
              />
            </PublicationProvider>
          )}
        </div>
      </div>
      <div className="flex-none">
        <div className="w-full bg-white border-t border-b z-10 md:flex items-center justify-between px-6">
          <div className="text-gray-500 text-sm p-4 text-center space-x-2 flex items-center">
            <p>Saved {moment(lastUpdated).fromNow()}</p>
            <div
              data-tip
              data-for="saving-indicator"
              className={cx(
                'w-2 h-2 rounded-full transition duration-200',
                isSaving ? 'animate-pulse bg-yellow-500' : 'bg-green-500'
              )}
            />
            <ReactTooltip id="saving-indicator" place="top" type="dark" effect="solid">
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <div className={cx('w-2 h-2 rounded-full transition duration-200 animate-pulse bg-yellow-500')} />
                  <span className="text-sm text-gray-500">Saving / unsaved changes</span>
                </div>
                <div className="flex items-center space-x-2">
                  <div className={cx('w-2 h-2 rounded-full bg-green-500')} />
                  <span className="text-sm text-gray-500">Changes are up to date</span>
                </div>
              </div>
            </ReactTooltip>
          </div>
          <div className="flex flex-col sm:flex-row justify-center sm:items-center space-y-4 sm:space-y-0 space-x-0 sm:space-x-4 py-4">
            {schedulable && onSchedule && <ScheduleModal emailMessage={emailMessage} onSchedule={onSchedule} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPane;
