import cx from 'classnames';

import { AdNetworkWorkspaceView } from '@/interfaces/ad_network/internal/workspace_view';

interface Props {
  workspaceView: AdNetworkWorkspaceView;
  onSelectWorkspaceView: (view: AdNetworkWorkspaceView) => void;
  highlighted: boolean;
  onHighlight: () => void;
}

const ViewSelectButton = ({ workspaceView, onSelectWorkspaceView, highlighted, onHighlight }: Props) => {
  return (
    <button
      type="button"
      className={cx(
        'focus:outline-none focus:bg-gray-100 rounded-md p-2 block w-full text-left',
        highlighted ? 'bg-gray-100' : 'bg-white'
      )}
      key={workspaceView.id}
      onClick={() => {
        onSelectWorkspaceView(workspaceView);
      }}
      onMouseEnter={onHighlight}
    >
      <span className="line-clamp-1">{workspaceView.name}</span>
    </button>
  );
};

export default ViewSelectButton;
