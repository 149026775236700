import { UnsplashImage } from '../../../../utils/unsplash';

import ImageItem from './ImageItem';

export type UnsplashImageGridProps = {
  images: UnsplashImage[];
  onSelect: (image: UnsplashImage) => void;
};

const UnsplashImageGrid = ({ images, onSelect }: UnsplashImageGridProps) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {images.map((image) => (
        <ImageItem key={image.id} image={image} onSelect={onSelect} />
      ))}
    </div>
  );
};

export default UnsplashImageGrid;
