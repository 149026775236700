import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { ResolutionType } from '../../interfaces/comment_report';
import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

interface Props {
  config: {
    postId: string;
    commentReportId: string;
  };
  onSuccess: (response: any) => void;
}

const useResolveCommentReports = ({ onSuccess, config }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (comment_report: { resolution_type: ResolutionType; resolution_note?: string; ban_level?: string }) =>
      api.patch(`/posts/${config.postId}/comments/${config.commentReportId}/resolve_reports`, {
        publication_id: currentPublication?.id,
        level: comment_report.ban_level,
        comment_report,
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Oops there was an issue updating this Comment Report.');
      },
    }
  );
};

export default useResolveCommentReports;
