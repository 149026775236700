import { useEffect, useState } from 'react';

const useAdBlockDetect = () => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  useEffect(() => {
    if (!document.cookie.includes('ignoreAdBlocker=true')) {
      const url = 'https://www3.doubleclick.net';
      fetch(url, {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store',
      }).catch(() => {
        setAdBlockDetected(true);
      });
    }
  }, []);

  const ignoreAdBlocker = () => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm('Are you sure? Some features may not work properly.');

    if (confirm) {
      const date = new Date();

      // expire in 1 day
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

      document.cookie = `ignoreAdBlocker=true;expires=${date.toUTCString()};path=/`;

      setAdBlockDetected(false);
    }
  };

  return { adBlockDetected, ignoreAdBlocker };
};

export default useAdBlockDetect;
