import { useInfiniteQuery } from 'react-query';

import { AdNetworkAdvertisement } from '../../../interfaces/ad_network/internal/advertisement';
import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  advertisements: AdNetworkAdvertisement[];
  pagination: Pagination;
}

interface Props {
  campaignId: string;
}

const useCampaignAdvertisements = ({ campaignId }: Props) => {
  const fetchAdvertisements = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/ad_network/internal/campaigns/${campaignId}/advertisements`, {
        params: {
          page: pageParam,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_network', 'internal', 'campaigns', campaignId, 'advertisements'],
    fetchAdvertisements,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
};

export default useCampaignAdvertisements;
