export const ImageTop = {
  name: 'Image Top',
  value: {
    node: 'div',
    className: 'flex flex-col gap-4 w-full',
    children: [
      {
        node: 'image',
        className: 'object-cover h-16 w-16',
        children: [],
      },
      {
        node: 'div',
        className: 'flex flex-col gap-2',
        children: [
          {
            node: 'name',
            className: '',
            children: [],
          },
          {
            node: 'bio',
            className: '',
            children: [],
          },
        ],
      },
      {
        node: 'socials',
        className: 'flex flex-row gap-2',
        children: [],
      },
    ],
  },
};

export const ImageLeftFlex = {
  name: 'Image Left',
  value: {
    node: 'div',
    className: 'flex gap-4 w-full items-center',
    children: [
      {
        node: 'image',
        className: 'object-cover h-16 w-16',
        children: [],
      },
      {
        node: 'div',
        className: 'flex flex-col gap-4',
        children: [
          {
            node: 'div',
            className: 'flex flex-col gap-2',
            children: [
              {
                node: 'name',
                className: '',
                children: [],
              },
              {
                node: 'bio',
                className: '',
                children: [],
              },
            ],
          },
          {
            node: 'socials',
            className: 'flex flex-row gap-2',
            children: [],
          },
        ],
      },
    ],
  },
};

export const ImageRightFlex = {
  name: 'Image Right',
  value: {
    node: 'div',
    className: 'flex gap-4 w-full items-center',
    children: [
      {
        node: 'div',
        className: 'flex flex-col gap-4',
        children: [
          {
            node: 'div',
            className: 'flex flex-col gap-2',
            children: [
              {
                node: 'name',
                className: '',
                children: [],
              },
              {
                node: 'bio',
                className: '',
                children: [],
              },
            ],
          },
          {
            node: 'socials',
            className: 'flex flex-row gap-2',
            children: [],
          },
        ],
      },
      {
        node: 'image',
        className: 'object-cover h-16 w-16',
        children: [],
      },
    ],
  },
};
