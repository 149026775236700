import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';

export const IgnoreTextInputOnNodesPluginKey = new PluginKey('ignoreTextInputOnNodes');

export const getIgnoreTextInputOnNodes = (): Plugin => {
  return new Plugin({
    key: IgnoreTextInputOnNodesPluginKey,
    props: {
      handleTextInput(view) {
        if (!(view.state.selection instanceof TextSelection)) {
          return true;
        }

        return false;
      },
    },
  });
};
