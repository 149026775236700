import { Link, useParams } from 'react-router-dom';
import moment from 'moment-mini';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useInfiniteScroll } from '@/hooks';
import useCampaignOpportunities from '@/hooks/useAdNetwork/internal/useCampaignOpportunities';
import {
  AdNetworkCampaignPerformanceFilter,
  AdNetworkCampaignPerformanceOpportunity,
} from '@/interfaces/ad_network/internal/campaign_performance_opportunity';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';
import { Pagination } from '@/interfaces/general';
import { InfiniteScrollOptions } from '@/interfaces/infinite_scroll_options';
import { Badge } from '@/ui/Badge';

import { LoadingListPage } from '../../../_components/LoadingListPage';
import { Tabs } from '../../../_components/Tabs';

import { Table } from './_components/Table';

interface CampaignPerformanceUpcomingProps {
  opportunities: AdNetworkCampaignPerformanceOpportunity[];
  infiniteScrollOptions: InfiniteScrollOptions;
  isFetchingNextPage: boolean;
  pagination: Pagination;
}

const formatter = new Intl.NumberFormat('en-US');

const CampaignPerformanceUpcoming = ({
  opportunities,
  infiniteScrollOptions,
  isFetchingNextPage,
  pagination,
}: CampaignPerformanceUpcomingProps) => {
  const { ref: lastElementRef } = useInfiniteScroll(infiniteScrollOptions);

  return (
    <>
      <div className="border-b border-gray-100 p-4 flex space-x-2">
        <div>
          <Tabs>
            <Tabs.Tab to="../delivered">Delivered</Tabs.Tab>
            <Tabs.Tab to="">Upcoming</Tabs.Tab>
            <Tabs.Tab to="../pending">Pending</Tabs.Tab>
            <Tabs.Tab to="../declined_or_expired">Declined or Expired</Tabs.Tab>
          </Tabs>
        </div>
      </div>
      <div>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <Table.Head>
            <Table.Row>
              <Table.Header>Publication</Table.Header>
              <Table.Header>Version</Table.Header>
              <Table.Header>Date</Table.Header>
              <Table.Header align="right">Est. Clicks</Table.Header>
              <Table.Header align="right">Est. Opens</Table.Header>
              <Table.Header align="right">Payout Rate</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {opportunities.map((opportunity) => {
              const isLastRow = opportunities.length - 1 === opportunities.indexOf(opportunity);

              return (
                <Table.Row key={opportunity.ad_network_opportunity_id} ref={isLastRow ? lastElementRef : null}>
                  <Table.Cell>
                    <Link
                      to={`/ad_network/publications/${opportunity.publication_id}`}
                      className="hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {opportunity.publication_name}
                      {opportunity.banned && <span className="text-red-500"> (banned)</span>}
                    </Link>
                    <Badge size="sm" className="ml-2">
                      {opportunity.publication_tier_display_name}
                    </Badge>
                  </Table.Cell>
                  <Table.Cell>{opportunity.advertisement_option_name}</Table.Cell>
                  <Table.Cell>{moment(opportunity.selected_date).format('MMM D, YYYY')}</Table.Cell>
                  <Table.Cell align="right">{formatter.format(opportunity.estimated_clicks)}</Table.Cell>
                  <Table.Cell align="right">{formatter.format(opportunity.estimated_opens || 0)}</Table.Cell>
                  <Table.Cell align="right">
                    <div className="min-w-fit">
                      {opportunity.payout_model === AdNetworkPayoutModel.CPC
                        ? `${opportunity.payout_per_click} CPC`
                        : `${opportunity.payout_per_mille} CPM`}
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <div className="sticky bottom-0">
          <div className="w-full px-4 py-2 border-t border-gray-100 bg-white">
            <div className="text-sm text-gray-500 float-left">
              Showing {opportunities.length} of {pagination.total}
            </div>
            <div className="flex text-sm text-gray-400 float-right">
              {infiniteScrollOptions.isFetching || infiniteScrollOptions.isLoading || isFetchingNextPage ? (
                <>
                  <LoadingSpinner className="mr-2" />
                  <p>Loading reports...</p>
                </>
              ) : (
                infiniteScrollOptions.hasNextPage && <p>Scroll down to load more reports</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Loader() {
  const { campaign_id: id } = useParams<'campaign_id'>() as { campaign_id: string };

  const { data, isSuccess, isError, isLoading, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useCampaignOpportunities({
      campaignId: id,
      filter: AdNetworkCampaignPerformanceFilter.UPCOMING,
    });

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const opportunities = data.pages.flatMap((page) => page.opportunities);
  const { pagination } = data.pages[0];

  return (
    <CampaignPerformanceUpcoming
      opportunities={opportunities}
      infiniteScrollOptions={{
        fetchNextPage,
        hasNextPage: !!hasNextPage,
        isFetching,
        isLoading,
      }}
      pagination={pagination}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}
