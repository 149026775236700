import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/ui/Button';

import { Input } from '../../../../components/Form';
import Modal from '../../../../components/Modal';
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import api from '../../../../services/swarm';

const SaveAsTemplateModal: React.FC<{ postId: string }> = ({ postId }) => {
  const navigate = useNavigate();
  const [currentPublicationId] = useCurrentPublicationState();
  const [name, setName] = React.useState('');

  const [active, setActive] = useState(false);

  const featureIsNew = new Date() < new Date('2022-04-15');

  const saveAsTemplate = useMutation<[boolean, string], any, boolean>(
    (viewAfterSave) =>
      api
        .post('/post_templates', {
          publication_id: currentPublicationId,
          from_post_id: postId,
          post_template: {
            name: name || 'Untitled template',
          },
        })
        .then((res) => [viewAfterSave, res.data.id]),
    {
      onSuccess: (res) => {
        const [viewAfterSave, templateId] = res;
        toast.success('Template saved successfully');

        if (viewAfterSave) {
          navigate(`/templates/posts/${templateId}`);
        } else {
          setActive(false);
        }
      },
      onError: (err) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );

  return (
    <>
      <Modal isOpen={active} onClose={() => setActive(false)}>
        <div className="px-12 pt-16 pb-12 max-w-2xl">
          <p className="text-2xl text-gray-800 font-medium">New Template</p>
          <p className="text-sm text-gray-500 mt-2 max-w-xl">
            Templates are the easiest way to jump start your writing. Your post will be saved as a &apos;template,&apos;
            and you can use it as a starting point rather than starting from scratch.
          </p>

          <div className="my-8">
            <Input
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              labelText="Give your template a name"
              helperText="You can always change this later"
              placeholder="Untitled template"
            />
          </div>
          <div className="mt-12 flex justify-end">
            <Button variant="primary-inverse" className="mr-4" onClick={() => saveAsTemplate.mutateAsync(true)}>
              Save and view template
            </Button>
            <Button onClick={() => saveAsTemplate.mutateAsync(false)}>Save and continue writing</Button>
          </div>
        </div>
      </Modal>
      <button
        type="button"
        className="justify-center duration-200 py-1 px-3 border font-medium focus:outline-none focus:ring-1 inline-flex items-center disabled:cursor-not-allowed whitespace-wrap xs:whitespace-nowrap text-sm hover:bg-gray-200 text-gray-800 disabled:text-gray-600 disabled:bg-gray-100 rounded-md shadow-sm border-gray-300 overflow-hidden"
        onClick={() => {
          setActive(true);
        }}
      >
        {featureIsNew && (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mr-2">
            <svg
              className="-ml-0.5 mr-1.5 h-2 w-2 text-blue-400 animate-ping absolute"
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx={4} cy={4} r={3} />
            </svg>
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-blue-400 relative" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            New
          </span>
        )}
        <span>Save Template</span>
      </button>
    </>
  );
};

export default SaveAsTemplateModal;
