import { CheckBadgeIcon, EnvelopeIcon, GlobeAltIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { getColor } from '@/components/DesignTokens';
import IconWrapper from '@/components/IconHelpers/IconWrapper';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const IntroModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="rounded-lg overflow-hidden">
        <img
          src="https://beehiiv-images-production.s3.amazonaws.com/static_assets/budgetimage.jpg"
          width="556"
          height="191"
          alt="Grow with Boosts"
        />
      </div>
      <div className="flex flex-col py-5 px-6 gap-y-6">
        <div className="flex flex-col gap-y-2">
          <Typography token="font-semibold/text/2xl">Create your offer</Typography>
          <Typography token="font-normal/text/sm">
            Let’s create your offer, which is what other publishers will see in the beehiiv <br /> Boost Marketplace.
          </Typography>
        </div>

        <div className="flex flex-col gap-y-6">
          <div className="flex gap-x-2">
            <IconWrapper Icon={GlobeAltIcon} size="md" type="ghost" variant="secondary" />
            <div className="flex flex-col gap-y-2">
              <Typography token="font-medium/text/base">Set up your Web Boost channel</Typography>
              <Typography token="font-normal/text/sm">
                Fill in your details including CPA and target audience
              </Typography>
            </div>
          </div>

          <div className="flex gap-x-2">
            <IconWrapper Icon={CheckBadgeIcon} size="md" type="ghost" variant="secondary" />
            <div className="flex flex-col gap-y-2">
              <Typography token="font-medium/text/base">Determine your verification mode</Typography>
              <Typography token="font-normal/text/sm">
                Choose between our standard or relaxed verification process
              </Typography>
            </div>
          </div>

          <div className="flex gap-x-2">
            <IconWrapper Icon={EnvelopeIcon} size="md" type="ghost" variant="secondary" />
            <div className="flex flex-col gap-y-2">
              <Typography token="font-medium/text/base">Enable Email boost channel (Recommended)</Typography>
              <Typography token="font-normal/text/sm">Define your max limit spend</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('flex justify-end p-6 rounded-b-lg', getColor('primary/200', 'background'))}>
        <Button variant="primary" onClick={onClose}>
          Get Started
        </Button>
      </div>
    </Modal>
  );
};

export default IntroModal;
