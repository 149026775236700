import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkCampaign } from '../../../interfaces/ad_network/internal/campaign';
import { MutationErrorResponse } from '../../../interfaces/ad_network/internal/mutation_error_response';
import api from '../../../services/swarm';

export default function useAdvertiserCampaignCreate({ id }: { id: string }) {
  const navigate = useNavigate();

  const updateCampaign = (formData: FormData): Promise<AdNetworkCampaign> =>
    api.post(`/ad_network/internal/advertisers/${id}/campaigns`, formData).then((res) => res.data);

  return useMutation<AdNetworkCampaign, MutationErrorResponse, FormData>(updateCampaign, {
    onSuccess: ({ id: campaignId }) => {
      toast.success('Campaign created successfully');
      navigate(`/ad_network/campaigns/${campaignId}`);
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
