import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { useDebouncedValue } from '../../../../hooks/useDebouncedValue';
import { searchUnsplashImages, UnsplashImage } from '../../../../utils/unsplash';

export type UnsplashSearchHookOptions = {
  query: string;
  offset?: number;
};

const useUnsplashSearch = ({ query }: UnsplashSearchHookOptions) => {
  const [offset, setOffset] = useState(0);
  const [images, setImages] = useState<UnsplashImage[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const currentPublicationId = useCurrentPublicationId();

  const debouncedSearchValue = useDebouncedValue<string>(query, 750);

  useEffect(() => {
    if (query) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [query]);

  const searchGifs = useCallback(async (searchQuery: string, searchOffset: number) => {
    if (!searchQuery || searchQuery.length < 3) {
      return;
    }

    setLoading(true);
    setError(false);

    try {
      const response = await searchUnsplashImages({
        query: searchQuery,
        page: searchOffset,
        publication_id: currentPublicationId,
      });
      setImages((oldImages) => [...oldImages, ...response.results]);
      const newOffset = searchOffset + 1;
      setOffset(newOffset);
      setHasMore(response.total_pages > newOffset);
    } catch (e: any) {
      setError(true);
      toast.error(e.message);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    setImages([]);
    searchGifs(debouncedSearchValue, 0);
  }, [debouncedSearchValue]);

  const fetchNextPage = useCallback(() => {
    if (!hasMore) return;
    searchGifs(debouncedSearchValue, offset);
  }, [hasMore, offset]);

  return {
    images,
    offset,
    loading,
    error,
    searchGifs,
    fetchNextPage,
    hasMore,
  };
};

export default useUnsplashSearch;
