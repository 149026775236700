import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { AdNetworkDisbursement } from '@/interfaces/ad_network/internal/disbursement';
import api from '@/services/swarm';

interface DisbursementTransitionProps {
  id: string;
  onSuccess?: () => void;
}
interface DisbursementData {
  status: 'approved' | 'manual' | 'paid';
  manualPayoutReason?: string;
  payoutPlatform?: string;
}

export default function useDisbursementTransition({ id, onSuccess }: DisbursementTransitionProps) {
  const transitionDisbursement = ({
    status,
    manualPayoutReason,
    payoutPlatform,
  }: DisbursementData): Promise<AdNetworkDisbursement> =>
    api
      .patch(`/ad_network/internal/disbursements/${id}`, {
        disbursement: {
          status,
          ...(status === 'manual' && { manual_payout_reason: manualPayoutReason }),
          ...(status === 'paid' && { payout_platform: payoutPlatform }),
        },
      })
      .then((res) => res.data);

  return useMutation(transitionDisbursement, {
    onSuccess: () => {
      onSuccess?.();
      toast.success('Disbursement updated successfully');
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Couldn't update disbursement");
    },
  });
}
