import { ChatBubbleLeftRightIcon } from "@heroicons/react/20/solid";

import Banner from "@/components/Banner";
import { useSupportModalContext } from "@/context/support-modal-context";
import useBoostsFrozen from "@/hooks/boosts/useBoostsFrozen";

const BoostsFrozenBanner = () => {
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen || false;
  const { setIsSupportModalOpen } = useSupportModalContext();

  if(!boostsFrozen) return null;
  return(
    <Banner
      isScreenWide={false}
      variant="danger"
      title="Your access to the Boosts platform has been disabled. Please contact customer support by submitting a support ticket for assistance."
      ctaText="Contact Support"
      ctaIcon={<ChatBubbleLeftRightIcon className="h-5 w-5" />}
      onClick={() => setIsSupportModalOpen(true)}
    />
  )
};

export default BoostsFrozenBanner;
