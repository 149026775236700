import styled from 'styled-components';

export const Styled = {
  ConsumptionWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0.5rem;
  `,
};

export default Styled;
