import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { BoostOffer } from '../../../interfaces/boosts/monetize/boost_offer';
import api from '../../../services/swarm';

interface Props {
  onSuccess?: (response: any) => void;
  onSettled?: (response: any) => void;
}

const useCreateSnoozedBoostOffer = ({ onSuccess, onSettled }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const createSnoozedBoostOffer = (boostOfferId: string): Promise<BoostOffer> =>
    api
      .post('/boosts/monetize/snoozed_boost_offers', {
        publication_id: currentPublicationId,
        snoozed_boost_offer: {
          boost_offer_id: boostOfferId,
        },
      })
      .then((res) => res.data);

  return useMutation(createSnoozedBoostOffer, {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
    onSettled: (response) => {
      if (onSettled) onSettled(response);
    },
  });
};

export default useCreateSnoozedBoostOffer;
