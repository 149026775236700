import { FC, useState } from 'react';
import { ArrowRight, Plus } from '@phosphor-icons/react';
import { SectionElement } from '@shared/dream-components';
import { NodeViewContent, NodeViewProps, useReactNodeView } from '@tiptap/react';
import cx from 'classnames';

import { Button } from '../../../../UI/Button';
import { Text } from '../../../../UI/Text';
import { getDataAttributes } from '../../utils';
import AddSectionModal from '../modal';

import WorkCard from './WorkCard';

export const SectionView: FC<NodeViewProps> = ({ HTMLAttributes, node, editor, getPos }) => {
  const { onDragStart } = useReactNodeView();
  const [isDragOver, setIsDragOver] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const hasNoContent = node.content.size === 0;

  const onMouseEnter = () => {
    setIsOver(true);
  };

  const onMouseLeave = () => {
    setIsOver(false);
  };

  return (
    <SectionElement
      element={{
        type: 'section',
        content: node.attrs.content,
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
          padding: hasNoContent ? '0' : node.attrs.padding,
          innerWidthMax: hasNoContent ? '100dvw' : node.attrs.innerWidthMax,
        },
      }}
      onDragStart={onDragStart}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      attributes={{
        ...getDataAttributes(HTMLAttributes),
        'data-node-view-wrapper': '',
      }}
    >
      {hasNoContent ? (
        <div className="w-[98%] mx-auto h-full min-h-[300px] select-none">
          <div
            className={cx(
              'absolute w-full h-full min-h-[300px] top-0 left-0 transition-all duration-300 pointer-events-none',
              {
                'bg-blue-50': isDragOver,
              }
            )}
            style={{ zIndex: 1 }}
          />

          <div className="flex flex-col items-center gap-[10px] absolute w-full top-1/2 transform -translate-y-1/2">
            <div className="flex gap-4 self-stretch items-start">
              <WorkCard label="Hero Section" img="/images/hero.png" />
              <WorkCard label="Features Section" img="/images/features.png" />
              <WorkCard label="Testimonials Section" img="/images/testimonials.png" />
              <WorkCard label="Footer Section" img="/images/footer.png" />
            </div>
            <div className="flex gap-4 self-stretch items-center">
              <WorkCard label="Hero Section" img="/images/hero.png" />
              <WorkCard label="Features Section" img="/images/features.png" />
              <WorkCard label="Testimonials Section" img="/images/testimonials.png" />
              <WorkCard label="Footer Section" img="/images/footer.png" />
            </div>
          </div>

          <div
            className="relative w-full h-full min-h-[300px] flex items-center justify-center backdrop-blur-[1px] bg-gradient-to-b from-white/100 from-50% to-white/40 to-90%"
            style={{ zIndex: 2 }}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDragOver(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setIsDragOver(false);
            }}
          >
            <div className="flex flex-col items-center justify-center gap-2" contentEditable={false}>
              <Text size="xl" weight="semibold" variant="secondary">
                Add a Section
              </Text>
              <div className="flex flex-col">
                <AddSectionModal
                  actionButton={
                    <Button variant="outlined" RightIcon={ArrowRight} className="shadow-md">
                      Browse Templates
                    </Button>
                  }
                  editor={editor}
                  insertPos={{
                    from: getPos(),
                    to: getPos() + node.nodeSize,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isOver && (
            <div
              className="absolute w-full flex items-center justify-center bottom-0 left-1/2 transform -translate-x-1/2 duration-300"
              contentEditable={false}
            >
              <hr className="w-full absolute bottom-0 border-t-2 border-indigo-400" />
              <AddSectionModal
                actionButton={
                  <button
                    type="button"
                    className="text-xs flex gap-2 items-center rounded-t-md bg-indigo-400 text-white px-2 py-1 z-10 cursor-pointer"
                  >
                    <Plus /> Add section
                  </button>
                }
                insertPos={getPos() + node.nodeSize}
                editor={editor}
                multipleSelectionsAllowed
              />
            </div>
          )}
          <NodeViewContent data-node-view-display-contents />
        </>
      )}
    </SectionElement>
  );
};
