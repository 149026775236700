import { Legend as TremorLegend, LegendProps } from '@tremor/react';

// https://www.tremor.so/docs/ui/legend

const Legend = (props: LegendProps) => {
  const { colors, ...rest } = props;
  return <TremorLegend colors={colors || ['pink']} {...rest} />;
};

export default Legend;
