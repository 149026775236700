interface ShortcutProps {
  children: React.ReactNode;
}

const Shortcut = ({ children }: ShortcutProps) => {
  return (
    <div className="font-mono border rounded-md p-1 bg-white h-5 space-x-0.5 flex text-xs items-center justify-center text-gray-500 basis-5 flex-shrink-0">
      {children}
    </div>
  );
};

export default Shortcut;
