import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import { Button } from '@/ui/Button';

import { BackgroundGradient } from '../../components/BackgroundGradient';
import { Input } from '../../components/Form';
import Header from '../../components/Header';
import Stack from '../../components/Stack';
// Components
import { SvgLogo } from '../../components/SvgLogo';
import Text from '../../components/Text';
// Context
import { useAuth } from '../../context/auth-context';

const H2Styled = styled.h2`
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  @media (min-width: 400px) {
    white-space: nowrap;
  }
`;

const ButtonStyled = styled(Button)`
  background-color: #209bf1;
  box-shadow: 6px 6px 0px rgba(32, 155, 241, 0.25);
  padding: 1rem;
`;

const PasswordReset = () => {
  const [tokenId] = useQueryParam('token_id', StringParam);
  const [passwordToken] = useQueryParam('password_token', StringParam);
  const [userId] = useQueryParam('user_id', StringParam);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (!tokenId || !passwordToken || !userId) {
      toast.error('Password reset request invalid');
    }
  }, [tokenId, passwordToken, userId]);

  const { resetPassword, needsOtp } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');

    if (e.target.name === 'password') {
      setPassword(e.target.value);
    } else if (e.target.name === 'confirmPassword') {
      setConfirmPassword(e.target.value);
    } else if (e.target.name === 'otp') {
      setOtp(e.target.value);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      return setErrorMessage('All fields required!');
    }

    if (password !== confirmPassword) {
      return setErrorMessage('Passwords must match!');
    }

    if (password.length < 6) {
      return setErrorMessage('Passwords must be at least 6 characters!');
    }

    if (password && confirmPassword) {
      resetPassword.mutate(
        { password, confirmPassword, userId, tokenId, passwordToken, otp },
        {
          onSuccess: (data: any) => {
            if (data?.code === 'OTP_REQUIRED') {
              return;
            }
            navigate('/');
          },
        }
      );
    }

    return null;
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-10 sm:px-6 lg:px-8 xs:p-5">
      <BackgroundGradient className="z-0 fixed inset-0 h-full w-full object-cover" />
      <div className="z-10 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-12 h-auto m-auto">
          <a href="https://www.beehiiv.com" aria-label="beehiiv" target="_blank" rel="noreferrer">
            <SvgLogo stopColorOverride="#fff" />
          </a>
        </div>
      </div>
      <div className="z-10 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white shadow rounded-lg">
          <div className="py-8 px-4 sm:px-10">
            <div className="text-center mb-10">
              <H2Styled>Update your password</H2Styled>
            </div>
            {!tokenId || !passwordToken || !userId ? (
              <Stack direction="col" gap={4}>
                <Header as="h5" className="text-center">
                  You must request a password reset email before resetting your password!
                </Header>
                <div className="text-center">
                  <Link className="text-sm font-medium text-gray-600 hover:text-gray-500" to="/request_password_reset">
                    Request password reset email
                  </Link>
                </div>
              </Stack>
            ) : (
              <Stack direction="col" gap={4}>
                <Text size="sm" className="text-gray-600">
                  {needsOtp ? 'Enter a two-factor code' : 'Please enter a new password.'}
                </Text>
                <form onSubmit={handleSubmit}>
                  <Stack direction="col" gap={4}>
                    <Stack direction="col" gap={4}>
                      {!needsOtp && (
                        <Input
                          type="password"
                          name="password"
                          labelText="Password"
                          autoComplete="password"
                          value={password}
                          onChange={handleChange}
                          inputClassOverride="mt-1 appearance-none block w-full p-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 text-base"
                        />
                      )}
                      {!needsOtp && (
                        <Input
                          type="password"
                          name="confirmPassword"
                          labelText="Confirm Password"
                          autoComplete="password"
                          value={confirmPassword}
                          onChange={handleChange}
                          inputClassOverride="mt-1 appearance-none block w-full p-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 text-base"
                        />
                      )}
                      {needsOtp && (
                        <input
                          name="otp"
                          value={otp}
                          onChange={handleChange}
                          className="mt-1 appearance-none block w-full p-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 text-base"
                        />
                      )}
                    </Stack>
                    <div>
                      <ButtonStyled
                        disabled={resetPassword.isLoading}
                        loading={resetPassword.isLoading}
                        type="submit"
                        variant="primary"
                        className="w-full flex justify-center text-sm xs:text-base sm:text-xl"
                      >
                        {resetPassword.isLoading ? 'Password resetting...' : 'Reset Password'}
                      </ButtonStyled>
                      {(resetPassword.isError || errorMessage) && (
                        <p className="text-red-500 text-xs italic mt-2">
                          {resetPassword.isError ? 'We were unable to reset your password!' : errorMessage}
                        </p>
                      )}
                    </div>
                  </Stack>
                </form>
              </Stack>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
