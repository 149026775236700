import { PropsWithChildren } from 'react';

import SwitchInput from '../Form/SwitchInput';

type Props = PropsWithChildren<{
  name: string;
  label: string;
  onSave: (value: boolean) => void;
  value: boolean;
}>;

const SwitchHelper = ({ label, onSave, value, children, name }: Props) => {
  return (
    <>
      <SwitchInput name={name} label={label} enabled={value} onSave={(newValue: boolean) => onSave(newValue)} />
      {value && children}
    </>
  );
};

export default SwitchHelper;
