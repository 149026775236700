import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';
import Form from '../Form';
import { useTemplateViewContext } from '..';

const NewPostTemplate: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const [currentPublicationId] = useCurrentPublicationState();
  const { resourceType } = useTemplateViewContext();

  const createPostTemplateMutation = useMutation(
    ({ postTemplate }: { postTemplate: any }) =>
      api.post('/post_templates', {
        publication_id: currentPublicationId,
        post_template: postTemplate,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (res) => {
        toast.success('Post template saved');
        navigate(`/templates/${resourceType}/${res.data.id}`);
      },
    }
  );

  const onSubmit = async (name: string, templateContent: any) => {
    const postTemplate = {
      name,
      tiptap_state: templateContent,
    };

    await createPostTemplateMutation.mutateAsync({ postTemplate });
  };

  return (
    <div>
      <div className="flex mb-4">
        <Link className="text-sm text-gray-500 hover:text-primary-500" to={`/templates/${resourceType}`}>
          <div className="flex items-center">
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            <span>Back to all templates</span>
          </div>
        </Link>
      </div>
      <div className="my-4">
        <Form
          onSubmit={onSubmit}
          header="New Template"
          name=""
          description="Create a new template below, or navigate to a post and select 'Save as template.'"
          tiptapState={{}}
        />
      </div>
    </div>
  );
};

export default NewPostTemplate;
