import { ATTRIBUTES_PANEL_ID } from '../DreamEditor/constants';

const AttributesPanelPortal = () => {
  return (
    <div
      className="w-[266px] min-w-[266px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-auto"
      style={{
        scrollbarGutter: 'stable',
      }}
      id={ATTRIBUTES_PANEL_ID}
    />
  );
};

export default AttributesPanelPortal;
