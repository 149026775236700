import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { AdNetworkAdGroup } from '@/interfaces/ad_network/internal/ad_group';

import { MutationErrorResponse } from '../../../interfaces/ad_network/internal/mutation_error_response';
import api from '../../../services/swarm';

export default function useCampaignAdGroupCreate({ campaignId }: { campaignId: string }) {
  const createAdGroup = (formData: FormData): Promise<AdNetworkAdGroup> =>
    api.post(`/ad_network/internal/campaigns/${campaignId}/ad_groups`, formData).then((res) => res.data);

  return useMutation<AdNetworkAdGroup, MutationErrorResponse, FormData>(createAdGroup, {
    onSuccess: () => {
      toast.success('Group created successfully');
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
