import { AdjustmentsHorizontalIcon, DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/20/solid';

interface Props {
  canRefine?: boolean;
  canDuplicate?: boolean;
  disableRefine?: boolean;
  handleRefineChange?: () => void;
  handleDelete: () => void;
  handleDuplicate?: () => void;
}

const ConditionOptions = ({
  canRefine = false,
  canDuplicate = true,
  disableRefine = false,
  handleRefineChange,
  handleDelete,
  handleDuplicate,
}: Props) => (
  <>
    {canRefine && (
      <button type="button" onClick={handleRefineChange} disabled={disableRefine} title="Refine">
        <AdjustmentsHorizontalIcon className="text-gray-400 hover:text-gray-800" width="15" height="15" />
      </button>
    )}
    {canDuplicate && handleDuplicate && (
      <button type="button" onClick={handleDuplicate} title="Duplicate">
        <DocumentDuplicateIcon className="text-gray-400 hover:text-gray-800" width="15" height="15" />
      </button>
    )}
    <button type="button" onClick={handleDelete} title="Delete">
      <TrashIcon className="text-gray-400 hover:text-gray-800" width="15" height="15" />
    </button>
  </>
);

export default ConditionOptions;
