import React from 'react';

import { Input } from '../../../../../../components/Form';

interface Props {
  url: string;
  onUrlChange: (str: string) => void;
}

const Wordpress: React.FunctionComponent<Props> = ({ url, onUrlChange }: Props) => (
  <div>
    <p className="font-medium">Step 1</p>
    <p className="mt-2 mb-4 text-sm text-gray-500">
      Ensure that your RSS feed includes entire posts instead of just excerpts. This can be done in WordPress by going
      to <b>Settings</b> &gt; <b>Reading</b> &gt; <b>For each post in a feed, include</b> and selecting <b>Full Text</b>
      . Make sure to click Save.
    </p>
    <p className="mt-12 font-medium">Step 2</p>
    <p className="mt-2 mb-4 text-sm text-gray-500">
      Input your blog&apos;s URL below. This will be used to pull content and information about your blog during the
      import process.
    </p>
    <Input
      name="url"
      labelText="Blog URL"
      value={url}
      placeholderText="https://beehiiv.wordpress.com"
      onChange={(e) => onUrlChange(e.target.value)}
    />
  </div>
);

export default Wordpress;
