import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkWorkspaceView } from '../../../interfaces/ad_network/internal/workspace_view';
import api from '../../../services/swarm';

interface SuccessResponse extends AdNetworkWorkspaceView {}
interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

export default function useWorkspaceViewDuplicate({ id }: { id: string }) {
  const navigate = useNavigate();

  return useMutation<SuccessResponse, ErrorResponse>(
    () => api.post(`/ad_network/internal/workspace_views/${id}/duplicate`).then((res) => res.data),
    {
      onSuccess: ({ id: newId }) => {
        toast.success('View duplicated successfully');

        navigate(`/ad_network/workspace_views/${newId}`);
      },
      onError: (error) => {
        const { errors } = error.response.data;

        if (errors) {
          Object.keys(errors).forEach((key) => {
            toast.error(`${key} ${errors[key][0]}`);
          });
        } else {
          toast.error('Something went wrong');
        }
      },
    }
  );
}
