import React, { useEffect, useState } from 'react';

import { useCurrentPublication } from '@/hooks';
import { useUpdatePublicationSubscriberTag } from '@/hooks/useSubscriberTags';
import { PublicationSubscriberTag, PublicationSubscriberTagParams } from '@/interfaces/subscriber_tag';

import Form from './Form';

interface Props {
  tag: PublicationSubscriberTag | null;
  tagId: string;
  isOpen: boolean;
  onClose: () => void;
}

const EditTag = ({ tag, tagId, isOpen, onClose }: Props) => {
  const updatePublicationSubscriberTag = useUpdatePublicationSubscriberTag();
  const { data: currentPublication } = useCurrentPublication();
  const [isLoading, setIsLoading] = useState(false);

  const [publicationSubscriberTag, setPublicationSubscriberTag] = useState<PublicationSubscriberTagParams | null>(null);

  useEffect(() => {
    setIsLoading(true);
    if (isOpen && tag) {
      setPublicationSubscriberTag({
        name: tag.name || '',
        color: tag.color || '',
        subscriber_emails: '',
      });
      setIsLoading(false);
    } else {
      setPublicationSubscriberTag(null);
    }
  }, [tagId, tag, isOpen]);

  const handleSubmit = async (data: PublicationSubscriberTagParams) => {
    const params = { ...data, ...{ id: tagId } };
    return updatePublicationSubscriberTag.mutateAsync(params);
  };

  return (
    <Form
      description="Update your tag"
      subscriberTag={publicationSubscriberTag}
      onSuccess={onClose}
      bodyId={currentPublication?.id || ''}
      isOpen={!isLoading && !!publicationSubscriberTag}
      onClose={onClose}
      headerText="Edit Tag"
      handleSubmit={handleSubmit}
    />
  );
};

export default EditTag;
