import { PageHeading } from '../../_components/PageHeading';

import MonthlyReport from './MonthlyReport';

const CampaignsHeader = () => {
  return (
    <PageHeading>
      <PageHeading.Side>
        <PageHeading.Breadcrumbs>
          <PageHeading.Breadcrumb to="">Campaigns</PageHeading.Breadcrumb>
        </PageHeading.Breadcrumbs>
      </PageHeading.Side>
      <MonthlyReport />
    </PageHeading>
  );
};

export default CampaignsHeader;
