import pluralize from '@/utils/pluralize';

type AllSelectedNoFilterProps = {
  selectedPostCount: number;
  showingPosts: string[];
  selectedShowingPosts: string[];
};

const AllSelectedNoFilter = ({ selectedPostCount, showingPosts, selectedShowingPosts }: AllSelectedNoFilterProps) => {
  const selectedOffScreen = showingPosts.filter((id) => selectedShowingPosts.includes(id)).length < selectedPostCount;

  return (
    <p>
      You have selected {pluralize('post', selectedPostCount)}.
      {selectedOffScreen && <> (Note: only {selectedShowingPosts.length} are visible on this page).</>}
    </p>
  );
};

export default AllSelectedNoFilter;
