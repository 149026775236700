import { useState } from 'react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import { AutomationTriggerEvent, AutomationTriggerState } from '@/interfaces/automations/automation_trigger';
import api from '@/services/swarm';

const useTrigger = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();

  const updateAutomationTrigger = (id: string, params: any) => {
    return api.patch(`/automation_triggers/${id}`, {
      publication_id: currentPublicationId,
      automation_trigger: {
        ...params,
      },
    });
  };

  const deleteAutomationTrigger = (id: string) => {
    return api.delete(`/automation_triggers/${id}`, {
      params: {
        publication_id: currentPublicationId,
      },
    });
  };

  const createAutomationTrigger = (
    automationId: string,
    event: AutomationTriggerEvent = AutomationTriggerEvent.SIGNUP,
    mainConditions: AutomationConditions = {},
    state: AutomationTriggerState = AutomationTriggerState.DRAFT
  ) => {
    return api.post(`/automation_triggers`, {
      publication_id: currentPublicationId,
      automation_trigger: {
        event,
        automation_id: automationId,
        main_conditions: mainConditions,
        state,
      },
    });
  };

  return {
    deleteAutomationTrigger,
    createAutomationTrigger,
    updateAutomationTrigger,
    isSaving,
    setIsSaving,
    isCreating,
    setIsCreating,
    showConditionsModal,
    setShowConditionsModal,
  };
};

export default useTrigger;
