import { JSONContent } from '@tiptap/core';

import { FILE_ATTACHMENT_TYPE } from '@/components/TiptapEditor/extensions/FileAttachment/constants';
import { Milestone } from '@/interfaces/milestone';

export const matchRewardPromoCodeString = (str: string) => str.match(/\{\{reward_promo_code\}\}/g);

export const hasPromoCodeInEmail = (tiptapState: Milestone['tiptap_state']) => {
  try {
    if (!tiptapState) {
      return false;
    }

    if (matchRewardPromoCodeString(JSON.stringify(tiptapState)) !== null) {
      return true;
    }
  } catch (error: any) {
    // do nothing
  }

  return false;
};

export const hasFileAttachment = (tiptapState: JSONContent) => {
  if (!tiptapState || !tiptapState.content || tiptapState.content.length === 0) {
    return false;
  }

  return tiptapState.content.some((row: any) => row.type === FILE_ATTACHMENT_TYPE);
};

export const removeFileAttachment = (tiptapState: JSONContent) => {
  if (!tiptapState || !tiptapState.content || tiptapState.content.length === 0) {
    return tiptapState;
  }

  if (!hasFileAttachment(tiptapState)) {
    return tiptapState;
  }

  return {
    type: 'doc',
    content: tiptapState.content.filter((block: any) => block.type !== FILE_ATTACHMENT_TYPE),
  };
};

export const removeRewardPromoCodeString = (tiptapState: JSONContent) => {
  if (!tiptapState || !tiptapState.content || tiptapState.content.length === 0) {
    return tiptapState;
  }

  if (!hasPromoCodeInEmail(tiptapState)) {
    return tiptapState;
  }

  return {
    type: 'doc',
    content: tiptapState.content.filter(
      (block: any) => matchRewardPromoCodeString(JSON.stringify(block.content || [])) === null
    ),
  };
};

export const insertFileAttachment = (tiptapState: JSONContent) => ({
  type: 'doc',
  content: [
    {
      type: FILE_ATTACHMENT_TYPE,
    },
    ...(tiptapState?.content || []),
  ] as JSONContent[],
});

export const insertPromoCodeMergeTag = (tiptapState: JSONContent) => ({
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: '{{reward_promo_code}}',
        },
      ],
    },
    ...(tiptapState?.content || []),
  ] as JSONContent[],
});
