import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  className?: string;
}>;

const TreeItem = ({ children, className }: Props) => (
  <div className={cx('ml-4 mt-1 py-2 px-2 rounded-lg bg-gray-50 hover:bg-gray-100', className)}>{children}</div>
);

export default TreeItem;
