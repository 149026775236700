import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

import ActionModal from '@/components/ActionModal';
import { Typography, TypographyStack } from '@/components/Typography';
import { Tier } from '@/interfaces/tier';
import analytics from '@/utils/analytics';

import { useUpdateTier } from '../../_hooks';

interface Props {
  tier: Tier;
  isOpen: boolean;
  onClose: () => void;
}

const UnarchiveTierModal: React.FC<Props> = ({ tier, isOpen, onClose }) => {
  const { mutate, isLoading } = useUpdateTier({ id: tier.id });
  const { prices } = tier;
  const monthlyPrice = prices.find((price) => price.interval === 'month' && price.amount_cents > 0);
  const yearlyPrice = prices.find((price) => price.interval === 'year' && price.amount_cents > 0);
  const updatablePrices = [monthlyPrice, yearlyPrice].filter((price) => price) as Tier['prices'];
  const priceAttributes = updatablePrices.map((price) => ({ id: price.id, enabled: true }));

  const onProceed = () => {
    mutate({ prices_attributes: priceAttributes });
    analytics.track('unarchived a premium tier');
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      isWorking={isLoading}
      onProceed={onProceed}
      resourceId={tier.id}
      buttonType="primary"
      actionText="Unarchive Tier"
    >
      <div className="flex space-x-4">
        <div className="p-[10px] bg-feedback-warning-50 rounded-full w-fit h-fit">
          <ExclamationCircleIcon className="w-5 h-5 text-feedback-warning-600" />
        </div>
        <TypographyStack>
          <Typography token="font-medium/text/lg">Unarchive Tier</Typography>
          <Typography token="font-normal/text/sm">
            Are you sure you want to unarchive this tier? New subscribers will be shown this tier as an option to
            upgrade. Existing subscriptions on this tier will remain unchanged.
          </Typography>
        </TypographyStack>
      </div>
    </ActionModal>
  );
};

export default UnarchiveTierModal;
