import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Title2xl, TitleBase, Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

import MigrationsTable from './_components/MigrationsTable';
import useStripeMigrations from './_hooks/useStripeMigrations';

const StripeMigrations = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useStripeMigrations();
  const migrations = useMemo(() => data?.pages?.flatMap((page) => page.stripe_migrations) || [], [data]);

  return (
    <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <Title2xl>Stripe Migrations {isLoading && <LoadingSpinner className="ml-2" />}</Title2xl>
        <Typography token="font-normal/text/base" colorWeight="500">
          Bring your paid subscribers over from Stripe in a few minutes
        </Typography>

        <div className="mt-8 bg-white border-l-4 border-red-400 px-4 py-8 md:flex space-x-4 items-start max-w-2xl">
          <div className="flex-shrink-0">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
          <Typography className="max-w-5xl" token="font-normal/text/base" colorWeight="700">
            Please note that in order to migrate your paid subscriptions to beehiiv, we are forced by Stripe to create
            duplicates of your existing subscriptions. This is because Stripe does not allow for transferring ownership
            of a subscription.
            <br />
            <br />
            <span className="font-semibold">
              Please address cancelling the existing subscriptions with your previous provider after the migration is
              complete.
            </span>{' '}
            This will ensure your subscribers are not accidentally double-billed.
          </Typography>
        </div>

        <div className="mt-8">
          <Link to="/stripe_migrations/new">
            <Button variant="primary" type="button">
              Get Started
            </Button>
          </Link>
        </div>

        {migrations.length > 0 && (
          <div className="mt-12 space-y-4">
            <TitleBase>Historical Migrations</TitleBase>
            <MigrationsTable migrations={migrations} />
            {hasNextPage && (
              <Button variant="primary-inverse" onClick={() => fetchNextPage()} loading={isFetchingNextPage}>
                Load More
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StripeMigrations;
