import { GlobeAmericasIcon } from '@heroicons/react/20/solid';

interface Props {
  url: string;
}

const ViewSiteLink = ({ url }: Props) => {
  return (
    <div className="flex space-x-1 items-center ml-4">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-xs whitespace-nowrap font-medium text-gray-500 hover:text-gray-900 duration-200 whitespace-nowrap flex items-center space-x-2 cursor-pointer"
      >
        <span className="flex items-center">
          <GlobeAmericasIcon className="w-4 h-4 mr-1" />
          View Site
        </span>
      </a>
    </div>
  );
};

export default ViewSiteLink;
