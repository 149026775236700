import { useInfiniteQuery } from 'react-query';

import { AdNetworkOpportunity } from '../../../interfaces/ad_network/internal/opportunity';
import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  opportunities: AdNetworkOpportunity[];
  pagination: Pagination;
}

export default function usePublicationOpportunities({ publicationId }: { publicationId: string }) {
  const fetchPublications = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/internal/publications/${publicationId}/opportunities`, {
        params: {
          page: pageParam,
          per_page: 25,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_network', 'internal', 'publications', publicationId, 'opportunities'],
    fetchPublications,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
