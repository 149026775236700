import { Editor } from '@tiptap/core';
import { Transaction } from '@tiptap/pm/state';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';

import { FootnoteMarkerAttrs } from './types';

export const reorderFootnotes = ({ tr }: { tr: Transaction }) => {
  const { doc } = tr;

  const footnotesData: Record<string, { order: number; footnoteText: string }> = {};

  // eslint-disable-next-line consistent-return
  doc.descendants((node, pos) => {
    switch (node.type.name) {
      case 'section':
        return true;
      case 'footnotesNode':
        node.forEach((child, childPos, index) => {
          const order = index + 1;

          footnotesData[child.attrs.footnoteId] = {
            order,
            footnoteText: child.textContent,
          };

          if (child.type.name !== 'footnoteItem' || child.attrs.order === order) return;

          tr.setNodeMarkup(tr.mapping.map(childPos + pos + 1), undefined, {
            ...child.attrs,
            order,
          });
        });
        return false;
      default:
        return false;
    }
  });

  doc.descendants((node, pos) => {
    if (node.type.name !== 'footnoteMarker') return;

    const { footnoteId, order, footnoteText } = node.attrs as FootnoteMarkerAttrs;

    if (!footnotesData[footnoteId]) {
      tr.deleteRange(pos, pos + node.nodeSize);

      return;
    }

    if (isEqual(footnotesData[footnoteId], { order, footnoteText })) return;

    tr.setNodeMarkup(tr.mapping.map(pos), undefined, {
      ...node.attrs,
      ...footnotesData[footnoteId],
    });
  });

  return footnotesData;
};

export const processFootnotes = ({ editor }: { editor: Editor }) => {
  const {
    state: { tr },
    view: { dispatch },
    storage,
  } = editor;

  tr.setMeta('addToHistory', false);

  const footnotesData = reorderFootnotes({ tr });

  storage.footnotesKit.footnotesData = footnotesData;

  dispatch?.(tr);
};

export const debouncedProcessFootnotes = debounce(processFootnotes, 300);
