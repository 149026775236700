import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

type BoostPublicationQualityInsufficientData = {
  insufficient_data: boolean;
};

type BoostPublicationQualitySufficientData = {
  size_score: number;
  growth_score: number;
  engagement_score: number;
  explanation: string;
};

type BoostPublicationQuality = BoostPublicationQualityInsufficientData | BoostPublicationQualitySufficientData;

interface ApiResponse {
  quality: BoostPublicationQuality;
  radar: any;
}

export const isInsufficientData = (
  quality: BoostPublicationQualityInsufficientData | BoostPublicationQualitySufficientData
): quality is BoostPublicationQualityInsufficientData =>
  (quality as BoostPublicationQualityInsufficientData).insufficient_data !== undefined;

const useBoostAgreementComparison = (boostAgreementId: string) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchComparison = (): Promise<ApiResponse> =>
    api
      .get(`/boosts/grow/boost_agreements/${boostAgreementId}/comparison`, {
        params: { publication_id: currentPublicationId },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, 'boosts', 'grow', 'boost_agreement', boostAgreementId, 'comparison'],
    fetchComparison
  );
};

export default useBoostAgreementComparison;
