import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkAdvertiserPublisherListItem } from '@/interfaces/ad_network/internal/advertiser_publisher_list_item';
import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from '@/services/swarm';

interface Variables {
  publisherId: string;
  listType: string;
  reasons: string[];
  expiresOn?: Date | null;
}

export default function useAdvertiserPublisherListItemUpdate({
  advertiserId,
  publisherListItemId,
}: {
  advertiserId: string;
  publisherListItemId: string;
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateAdvertiserPublisherListItem = ({
    publisherId,
    listType,
    reasons,
    expiresOn = null,
  }: Variables): Promise<AdNetworkAdvertiserPublisherListItem> =>
    api
      .patch(`/ad_network/internal/advertisers/${advertiserId}/publisher_list_items/${publisherListItemId}`, {
        list_type: listType,
        publisher_list_item: {
          publisher_id: publisherId,
          reasons,
          expires_on: expiresOn,
        },
      })
      .then((res) => res.data);

  return useMutation<AdNetworkAdvertiserPublisherListItem, MutationErrorResponse, Variables>(
    updateAdvertiserPublisherListItem,
    {
      onSuccess: () => {
        toast.success('List item updated successfully');
        queryClient.invalidateQueries([
          'ad_network',
          'internal',
          'advertisers',
          advertiserId,
          'publisher_list_items',
          publisherListItemId,
        ]);
        navigate(`/ad_network/advertisers/${advertiserId}/publisher_list/${publisherListItemId}`);
      },
      onError: (error) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      },
    }
  );
}
