import { Checkbox, Input } from "@/components/Form";
import Dropdown from "@/components/Form/SimpleSelect";
import { usePublications } from "@/hooks/usePublications";
import { assignableLevelDescriptions,assignableLevelLabels, AssignableLevels } from "@/interfaces/role";

import { Assignment, AssignmentPermission } from "./_interfaces/assignment";

interface Props {
  assignment: Assignment;
  contributorEnabled?: boolean;
  onAssignmentChange: (assignment: Assignment) => void;
}

const EditForm = ({ assignment, contributorEnabled, onAssignmentChange }: Props) => {
  const { data: publications } = usePublications();
  const publicationOptions = publications?.map((publication: any) => ({
    label: publication.name,
    value: publication.id,
  }));
  const roleOptions = (Object.keys(assignableLevelLabels) as AssignableLevels[]).map((level) => ({
    label: assignableLevelLabels[level],
    value: level,
    description: assignableLevelDescriptions[level],
    wrapDescription: true,
  }));

  // Filter out the contributor role if the feature flag is disabled
  const filteredRoleOptions = roleOptions.filter((role) => (
    (!contributorEnabled && role.value !== 'contributor') || contributorEnabled
  ));

  const handlePermissionChange = (name: string, value: string) => {
    const newPublicationId = value === 'workspace' ? '' : assignment.publicationId;
    onAssignmentChange({
      ...assignment,
      permission: value as AssignmentPermission,
      publicationId: newPublicationId,
    });
  }

  return (
    <div className="flex flex-col py-6 space-y-4">
      <Input
        labelText="Email"
        name="email"
        type="email"
        value={assignment.email}
        disabled
      />
      <Dropdown
        required
        labelText="Permission Level"
        name="permission"
        value={assignment.permission}
        options={[
          { value: 'workspace', label: 'Workspace', description: 'User can access all publications under this account', wrapDescription: true },
          { value: 'publication', label: 'Publication', description: 'User can only access specific publication\'s content and info', wrapDescription: true },
        ]}
        onSelect={handlePermissionChange}
      />
      {assignment.permission === 'publication' && (
        <Dropdown
            required
            labelText='Publication'
            name="publication"
            placeholderText="Select a publication"
            options={publicationOptions}
            value={assignment.publicationId}
            onSelect={(name, value) => onAssignmentChange({ ...assignment, publicationId: value })}
          />
      )}
      <Dropdown
        required
        labelText="User Role"
        name="role_id"
        value={assignment.role}
        options={filteredRoleOptions}
        onSelect={(name: string, value: string) => onAssignmentChange({ ...assignment, role: value as AssignableLevels })}
      />
      <Checkbox
        labelText="I confirm that I want to update this team member account and acknowledge that beehiiv will let them know about this update. *"
        name="confirmation"
        checked={assignment.confirmation}
        onChange={(checked) => onAssignmentChange({ ...assignment, confirmation: checked })}
      />
    </div>
  )
}

export default EditForm;
