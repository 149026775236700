import styled from 'styled-components';

export const Styled = {
  Tooltip: styled.div<{ maxWidth?: number }>`
    align-items: center;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.07)) drop-shadow(0px -5.85px 24.1177px rgba(0, 0, 0, 0.0456112))
      drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035))
      drop-shadow(0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0243888));
    justify-content: center;
    padding: 0.375rem 0.75rem;
    position: relative;

    &::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='5' viewBox='0 0 12 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.657 0L7.41433 4.24264C6.63328 5.02369 5.36695 5.02369 4.5859 4.24264L0.343262 0H11.657Z' fill='white'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat no-repeat;
      background-position: top center;
      background-size: cover;
      height: 4.8px;
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translateX(-50%);
      width: 11.3px;
    }

    &[data-placement='bottom'] {
      &::after {
        bottom: 100%;
        margin-bottom: -1px;
        top: unset;
        transform: rotate(180deg);
      }
    }

    ${(props) =>
      props.maxWidth &&
      `
        max-width: ${props.maxWidth}px;
      `}
  `,
  Title: styled.span`
    color: rgba(31, 41, 55, 0.4);
    font-size: 0.75rem;
    font-weight: 500;
    margin-right: 0.25rem;

    &:last-child {
      margin-right: 0;
    }
  `,
  Key: styled.span`
    align-items: center;
    background: rgba(31, 41, 55, 0.04);
    border-radius: 4px;
    color: rgba(31, 41, 55, 0.8);
    display: flex;
    font-size: 0.625rem;
    font-weight: 600;
    height: 1rem;
    justify-content: center;
    margin-right: 0.125rem;
    min-width: 1rem;
    padding: 0.2rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  `,
};

export default Styled;
