import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft } from '@phosphor-icons/react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { WebsiteProvider } from '@/context/website-context';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';

import { Layout } from '../../_components/Layout';
import { Main } from '../../_components/Main';
import { Breadcrumbs } from '../../_components/Main/Breadcrumbs';
import FooterCard from '../../_components/Templates/FooterCard';
import NavbarCard from '../../_components/Templates/NavbarCard';
import TemplateCard from '../../_components/Templates/TemplateCard';
import { Button } from '../../_components/UI/Button';
import { Text } from '../../_components/UI/Text';

import { TemplatesTopBar } from './_components/TemplatesTopBar';

const WebsiteProjectPage = () => {
  const { projectId } = useParams();
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const { data: project, isLoading } = useSitePackage({ sitePackageId: projectId || '' });
  const templates = project?.templates;
  const hasNoTemplates = templates?.length === 0 && !isLoading;

  const onDeleteTemplateSuccess = () => {
    queryClient.invalidateQueries(['publications', currentPublicationId, 'site_packages', projectId]);
  };

  const { pages, sections, blocks } = useMemo(() => {
    const pageTemplates = templates?.filter((template) => template.level === 'page');
    const sectionTemplates = templates?.filter((template) => template.level === 'section');
    const blockTemplates = templates?.filter((template) => template.level === 'block');

    return {
      pages: pageTemplates,
      sections: sectionTemplates,
      blocks: blockTemplates,
    };
  }, [templates]);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Back to Main App',
        backPath: '/',
      }}
      sidenavChildren={
        <Link to="/website_builder_v2/projects">
          <Button variant="secondary" LeftIcon={ArrowLeft} className="w-full">
            All Projects
          </Button>
        </Link>
      }
    >
      <Main>
        <Breadcrumbs />
        <TemplatesTopBar project={project} />
        <div className="flex flex-col gap-3">
          <Text weight="semibold" variant="secondary" size="sm">
            Globals
          </Text>
          <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
            {project && <NavbarCard sitePackage={project} />}
            {project && <FooterCard sitePackage={project} />}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Text weight="semibold" variant="secondary" size="sm">
            Pages
          </Text>
          {hasNoTemplates ? (
            <div className="flex items-center justify-center h-full py-20">
              <Text size="xl" weight="semibold" variant="secondary" as="h4">
                No templates found
              </Text>
            </div>
          ) : (
            <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
              {pages?.map((template) => {
                return (
                  <TemplateCard key={template.id} template={template} onDeleteSuccess={() => {}} hasOptions={false} />
                );
              })}
            </div>
          )}
        </div>

        {hasNoTemplates ? null : (
          <div className="flex flex-col gap-3">
            <Text weight="semibold" variant="secondary" size="sm">
              Sections
            </Text>

            <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
              {sections?.map((template) => {
                return (
                  <TemplateCard
                    hasOptions
                    key={template.id}
                    template={template}
                    onDeleteSuccess={onDeleteTemplateSuccess}
                  />
                );
              })}
            </div>
          </div>
        )}

        {hasNoTemplates ? null : (
          <div className="flex flex-col gap-3">
            <Text weight="semibold" variant="secondary" size="sm">
              Blocks
            </Text>

            <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
              {blocks?.map((template) => {
                return (
                  <TemplateCard
                    hasOptions
                    key={template.id}
                    template={template}
                    onDeleteSuccess={onDeleteTemplateSuccess}
                  />
                );
              })}
            </div>
          </div>
        )}
      </Main>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <WebsiteProjectPage />
  </WebsiteProvider>
);
