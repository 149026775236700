import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import api from '@/services/swarm';

export const useConsumption = (currentPublicationId: string) => {
  const [consumption, setConsumption] = useState<{
    consumedRequests?: number;
    monthlyAvailableRequests?: number;
  }>({ consumedRequests: undefined, monthlyAvailableRequests: undefined });

  const refetchConsumption = useCallback(() => {
    api
      .get('/editor/ai/consumption', { params: { publication_id: currentPublicationId } })
      .then(({ data }) => {
        setConsumption({
          consumedRequests: data.requests_consumed,
          monthlyAvailableRequests: data.requests_allowed,
        });
      })
      .catch((errPayload) => {
        const message = errPayload?.response?.data?.error || 'An error occurred';
        toast.error(message);
      });
  }, [currentPublicationId]);

  useEffect(() => {
    refetchConsumption();
  }, [refetchConsumption]);

  const showConsumption =
    typeof consumption.consumedRequests !== 'undefined' &&
    consumption.consumedRequests >= 0 &&
    typeof consumption.monthlyAvailableRequests !== 'undefined' &&
    consumption.monthlyAvailableRequests >= 0;

  const isConsumptionExceeded =
    (typeof consumption.consumedRequests !== 'undefined' && consumption.consumedRequests) >=
    (typeof consumption.monthlyAvailableRequests !== 'undefined' && consumption.monthlyAvailableRequests);

  return { consumption, setConsumption, refetchConsumption, showConsumption, isConsumptionExceeded };
};

export default useConsumption;
