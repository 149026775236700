import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  align-items: center;
  background: #f4f4f4;
  border-radius: 4px;
  color: #888;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
`;
