import { useState } from "react";
import { useQueryClient } from "react-query";

import ActionModal from "@/components/ActionModal";
import { useDeleteAssignment } from "@/hooks/useAssignments";
import useCurrentPublicationId from "@/hooks/usePublications/useCurrentPublicationId";
import { Organization } from "@/interfaces/organization";
import { TeamMember } from "@/interfaces/team_member";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
  member: TeamMember;
}

const DeleteModal = ({isOpen, onClose, organization, member}: Props) => {
  const [isDeletingMember, setIsDeletingMember] = useState<boolean>(false);

  const publicationId = useCurrentPublicationId();
  const queryClient = useQueryClient();
  const assignableName = member.permission === 'Organization' ? organization.name : member.publication_name;
  const assignmentId = parseInt(member.id, 10)
  const userId = member.user_id;
  const assignmentDelete = useDeleteAssignment(publicationId, assignmentId, userId);

  const handleProceed = async () => {
    setIsDeletingMember(true);
    await assignmentDelete.mutateAsync();
    queryClient.invalidateQueries(['publication-team', publicationId]);
    queryClient.invalidateQueries([organization.id, 'team_members']);
    setIsDeletingMember(false);
    onClose();
  }

  return (
    <ActionModal
      isOpen={isOpen}
      resourceId={member.id}
      isWorking={isDeletingMember}
      onClose={onClose}
      headerText={`Remove Permissions for ${member.name} from ${assignableName}`}
      actionText="Remove Team Member"
      buttonType="danger"
      buttonShade="dark"
      modalMessageType="danger"
      onProceed={handleProceed}
    >
      This action is permanent. You&apos;ll need to send a new invitation if you want to add them again later.
    </ActionModal>
  );
}

export default DeleteModal;
