import React from 'react';
import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { UpgradeIntentPlaceholder } from '../../components/ui/UpgradeIntentPlaceholder';

import { PollView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    poll: {
      setPoll: () => ReturnType;
    };
  }
}

export const Poll = Node.create({
  name: 'poll',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      enabled: false,
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({
          'data-id': attributes.id,
        }),
      },
      showOptionsInitially: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-show-options-initially'),
        renderHTML: (attributes) => ({
          'data-show-options-initially': attributes.showOptionsInitially,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setPoll:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
            })
            .run(),
    };
  },

  addNodeView() {
    if (!this.options.enabled) {
      return ReactNodeViewRenderer(
        () => (
          <UpgradeIntentPlaceholder
            description="You need to upgrade to access polls."
            url="https://www.beehiiv.com/newsletter-solutions/analyze"
            iconName="Poll"
          />
        ),
        { className: 'upgrade-intent' }
      );
    }

    return ReactNodeViewRenderer(PollView);
  },
});

export default Poll;
