import { FC } from 'react';
import { BubbleMenu as BaseBubbleMenu, BubbleMenuProps } from '@tiptap/react';

import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';

export const BubbleMenu: FC<BubbleMenuProps> = ({ children, ...rest }) => {
  return (
    <BaseBubbleMenu
      {...rest}
      tippyOptions={{
        ...rest.tippyOptions,
        zIndex: EDITOR_MENUS_Z_INDEX,
      }}
    >
      {children}
    </BaseBubbleMenu>
  );
};
