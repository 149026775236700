import {
  ArrowPathRoundedSquareIcon,
  ArrowTrendingUpIcon,
  BeakerIcon,
  BookOpenIcon,
  ChartPieIcon,
  CheckBadgeIcon,
  InboxStackIcon,
  NoSymbolIcon,
  QueueListIcon,
  RocketLaunchIcon,
  SparklesIcon,
} from '@heroicons/react/20/solid';

import { FeatureItem, FeatureList } from '@/components/Features';

interface Props {
  hasPrioritySupport: boolean;
}

const MaxPlan = ({ hasPrioritySupport }: Props) => {
  return (
    <div className="flex space-x-4 pb-4">
      {/** LEFT SIDE */}
      <div className="h-96 overflow-auto w-full">
        <FeatureList>
          <FeatureItem
            Icon={BookOpenIcon}
            title="Create Up To 10 Publications"
            description="Create up to 10 publications to share your content with different audiences."
            variant="tertiary"
          />
          <FeatureItem
            Icon={NoSymbolIcon}
            title="Remove beehiiv Branding"
            description="Let your brand shine by removing the beehiiv branding from your newsletter."
            variant="tertiary"
          />
          <FeatureItem
            Icon={RocketLaunchIcon}
            title="Advanced Web Builder"
            description="The ultimate suite of content tools—built for ease, optimized for beautiful custom newsletters."
            variant="tertiary"
          />
          <FeatureItem
            Icon={QueueListIcon}
            title="Automations"
            description="Create multi-step email journeys based on different triggers such as signing up for your newsletter or upgrading to your premium offering."
            variant="tertiary"
          />
          <FeatureItem
            Icon={ArrowTrendingUpIcon}
            title="beehiiv Ad Network"
            description="Monetize your newsletter with premium sponsors with just a few simple clicks."
            variant="tertiary"
          />
          <FeatureItem
            Icon={SparklesIcon}
            title="beehiiv AI Tools"
            description="Our AI features are built directly into the text editor, supporting writing assistance, text, image, and translation."
            variant="tertiary"
          />
          <FeatureItem
            Icon={InboxStackIcon}
            title="Audience Segmentation"
            description="One of the most powerful and dynamic segmentation tools available in email with advanced filtering through static, dynamic, and manual segmentation workflows."
            variant="tertiary"
          />
          <FeatureItem
            Icon={ArrowPathRoundedSquareIcon}
            title="Referral Program"
            description="Turn your readers into a growth engine and unlock the true potential of your subscribers."
            variant="tertiary"
          />
          <FeatureItem
            Icon={ChartPieIcon}
            title="3D Analytics"
            description="Easily view your data through our dashboards, metrics, and views to further unlock your newsletter’s growth."
            variant="tertiary"
          />
          <FeatureItem
            Icon={BeakerIcon}
            title="A/B Testing Suite"
            description="Test multiple subject line variations (up to 4) and optimize the performance of your emails."
            variant="tertiary"
          />
          {hasPrioritySupport && (
            <FeatureItem
              Icon={CheckBadgeIcon}
              title="Priority Support"
              description="Get help quickly so you can keep creating for your audience."
              variant="tertiary"
            />
          )}
        </FeatureList>
      </div>

      {/** RIGHT SIDE */}
      <div className="rounded-lg overflow-hidden w-full">
        <img
          className="w-full h-96 object-cover rounded-lg"
          src="https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=80/static_assets/illustration3.png"
          alt="generic subscription"
        />
      </div>
    </div>
  );
};

export default MaxPlan;
