import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useReferralProgram, useUpdateReferralProgram } from '@/hooks/useReferralProgram';
import { ReferralProgram } from '@/interfaces/referral_program';

const useReferralProgramForm = () => {
  const { data: referralProgram } = useReferralProgram();
  const { mutateAsync: updateReferralProgram, isLoading: isUpdating } = useUpdateReferralProgram();
  const [referralProgramInState, setReferralProgramInState] = useState<ReferralProgram | undefined>(referralProgram);

  useEffect(() => {
    setReferralProgramInState(referralProgram);
  }, [referralProgram, isUpdating]);

  const onStatusChange = (_name: string, newValue: boolean) => {
    const newReferralProgram = { ...referralProgramInState } as ReferralProgram;
    newReferralProgram.disabled_at = newValue ? null : new Date();
    setReferralProgramInState(newReferralProgram);
    updateReferralProgram(
      {
        disabledAt: newReferralProgram.disabled_at,
      },
      {
        onSuccess: () => {
          if (newValue) {
            toast.success('Referral Program enabled!');
          } else {
            toast.success('Referral Program disabled!');
          }
        },
      }
    );
  };

  return {
    referralProgramInState,
    setReferralProgramInState,
    updateReferralProgram,
    isUpdating,
    onStatusChange,
  };
};

export default useReferralProgramForm;
