import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { DEFAULT_BORDER_COLOR, DEFAULT_BORDER_RADIUS, DEFAULT_PRIMARY_COLOR } from '../constants';

import { AuthorsView } from './views/AuthorsView';

const cardStructure = {
  node: 'div',
  className: 'flex flex-col gap-4',
  children: [
    {
      node: 'image',
      className: 'object-cover h-16 w-16',
      children: [],
    },
    {
      node: 'div',
      className: 'flex flex-col',
      children: [
        {
          node: 'name',
          className: 'flex flex-col',
          children: [],
        },
        {
          node: 'bio',
          className: 'flex flex-col',
          children: [],
        },
      ],
    },
    {
      node: 'socials',
      className: 'flex flex-row gap-2',
      children: [],
    },
  ],
};

export const Authors = Node.create<{}, {}>({
  name: 'authors',
  group: 'block',
  atom: true,
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({ 'data-id': attributes.id }),
      },
      insertedFromSidebar: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-inserted-from-sidebar'),
        renderHTML: (attributes) => ({ 'data-inserted-from-sidebar': attributes.insertedFromSidebar }),
      },
      hasFakeData: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-has-fake-data'),
        renderHTML: (attributes) => ({ 'data-has-fake-data': attributes.hasFakeData }),
      },
      gap: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      columns: {
        default: 3,
        parseHTML: (element) => element.getAttribute('data-columns'),
        renderHTML: (attributes) => ({ 'data-columns': attributes.columns }),
      },
      alignment: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({ 'data-alignment': attributes.alignment }),
      },
      shadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      nameFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-name-font-family'),
        renderHTML: (attributes) => ({ 'data-name-font-family': attributes.nameFontFamily }),
      },
      nameFontSize: {
        default: 'text-lg',
        parseHTML: (element) => element.getAttribute('data-name-font-size'),
        renderHTML: (attributes) => ({ 'data-name-font-size': attributes.nameFontSize }),
      },
      bioFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-bio-font-family'),
        renderHTML: (attributes) => ({ 'data-bio-font-family': attributes.bioFontFamily }),
      },
      bioFontSize: {
        default: 'text-sm',
        parseHTML: (element) => element.getAttribute('data-bio-font-size'),
        renderHTML: (attributes) => ({ 'data-bio-font-size': attributes.bioFontSize }),
      },
      cardBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-card-border-radius'),
        renderHTML: (attributes) => ({ 'data-card-border-radius': attributes.cardBorderRadius }),
      },
      cardBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-card-border-width'),
        renderHTML: (attributes) => ({ 'data-card-border-width': attributes.cardBorderWidth }),
      },
      cardBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-border-color'),
        renderHTML: (attributes) => ({ 'data-card-border-color': attributes.cardBorderColor }),
      },
      cardBorderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-card-border-style'),
        renderHTML: (attributes) => ({ 'data-card-border-style': attributes.cardBorderStyle }),
      },
      cardBackgroundColor: {
        default: '#FFFFFFFF',
        parseHTML: (element) => element.getAttribute('data-card-background-color'),
        renderHTML: (attributes) => ({ 'data-card-background-color': attributes.cardBackgroundColor }),
      },
      cardPadding: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      cardSocialFill: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-social-fill'),
        renderHTML: (attributes) => ({ 'data-card-social-fill': attributes.cardSocialFill }),
      },
      cardSocialSize: {
        default: '4',
        parseHTML: (element) => element.getAttribute('data-card-social-size'),
        renderHTML: (attributes) => ({ 'data-card-social-size': attributes.cardSocialSize }),
      },
      cardTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-text-color'),
        renderHTML: (attributes) => ({ 'data-card-text-color': attributes.cardTextColor }),
      },
      cardBioColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-bio-color'),
        renderHTML: (attributes) => ({ 'data-card-bio-color': attributes.cardBioColor }),
      },
      imageRadius: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-image-radius'),
        renderHTML: (attributes) => ({ 'data-image-radius': attributes.imageRadius }),
      },
      imageSize: {
        default: '80px',
        parseHTML: (element) => element.getAttribute('data-image-size'),
        renderHTML: (attributes) => ({ 'data-image-size': attributes.imageSize }),
      },
      cardStructure: {
        default: cardStructure,
        parseHTML: (element) => element.getAttribute('data-card-structure'),
        renderHTML: (attributes) => ({ 'data-card-structure': attributes.cardStructure }),
      },
      data: {
        default: {
          authors: [],
        },
        parseHTML: (element) => JSON.parse(element.getAttribute('data-data') || '{}'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AuthorsView, {
      className: 'w-full',
    });
  },
});
