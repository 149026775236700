import { TextAa } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { LineClampSettings } from '../LineClampSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';

type Props = AttributeSettingProps & {};

export const TitlesPostSetting = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <PopoverHelper title="Titles" text="Titles" popoverTitle="Titles" Icon={TextAa}>
      <>
        <ColorSettings title="Color" property="titleTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <LineClampSettings
          title="Lines"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="titleLineClamp"
        />
        <SimpleFontSizeSettings
          title="Size"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="titleFontSize"
        />
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          title="Title Font"
          property="titleFontFamily"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="subtitleShowing"
          title="Subtitle"
        />
        {activeNodeAttributes?.subtitleShowing && (
          <>
            <ColorSettings
              title="Color"
              property="subtitleTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
            />
            <LineClampSettings
              title="Lines"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="subtitleLineClamp"
            />
            <SimpleFontSizeSettings
              title="Size"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="subtitleFontSize"
            />
            <FontSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              isMarkStyle={false}
              title="Subtitle Font"
              property="subtitleFontFamily"
            />
          </>
        )}
      </>
    </PopoverHelper>
  );
};

export default TitlesPostSetting;
