import { InformationCircleIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import { Typography } from '@/components/Typography';
import { useAddOpportunityToPost } from '@/hooks';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { Badge, BadgeSize } from '@/ui/Badge';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  opportunity: AdNetworkOpportunity;
}

const OpportunityReady = ({ isOpen, onClose, opportunity }: Props) => {
  const handleAddToPost = useAddOpportunityToPost({ opportunityId: opportunity.id });

  return (
    <ActionModal
      isOpen={isOpen}
      isWorking={false}
      onClose={onClose}
      onProceed={() => handleAddToPost()}
      resourceId={opportunity.id}
      modalSize="sm"
      actionText="Add campaign to new post"
      cancelText="I'll add later"
    >
      <div className="space-y-6">
        <div className="flex space-x-4">
          <div className="w-10 h-10 bg-feedback-info-50 rounded-full relative flex flex-shrink-0">
            <InformationCircleIcon className="absolute top-1/2 right-1/2 transform -translate-y-1/2 translate-x-1/2 w-6 h-6 text-feedback-info-600" />
          </div>
          <div className="flex flex-col">
            <Typography token="font-medium/text/lg" colorWeight="900">
              Campaign is ready! Send on {moment(opportunity.selected_date || new Date()).format('MMM Do')}.
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="700">
              Add this campaign to a new post or simply type <Badge text="/ads" size={BadgeSize.SM} /> in any post to
              send.{' '}
              <a
                href="https://support.beehiiv.com/hc/en-us/articles/17507491038231-Ad-Network-FAQ"
                target="_blank"
                rel="noreferrer noopener"
                className="text-action-secondary-600 underline"
              >
                Learn More
              </a>
            </Typography>
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default OpportunityReady;
