import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';

interface Props {
  isLoading: boolean;
  onProceed: () => void;
  isOpen: boolean;
  onClose: () => void;
  message: string;
  name: string;
  onChange: (event: string) => void;
}

const UpdateMessage = ({ onClose, isOpen, isLoading, onProceed, message, name, onChange }: Props) => {
  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      resourceId=""
      headerText="Edit Message"
      onProceed={onProceed}
      isWorking={isLoading}
      actionText="Save"
    >
      <div className="text-sm space-y-4">
        <Input
          name={name}
          value={message}
          onChange={(event) => onChange(event.target.value)}
          placeholderText="Add a custom message"
          helperText="This message will be displayed on the top of the page to encourage users to take an action."
        />
      </div>
    </ActionModal>
  );
};

interface HookProps {
  message: string;
}

const useUpdateMessage = ({ message }: HookProps) => {
  const [value, setValue] = useState(message);
  const [isOpen, setIsOpen] = useState(false);

  return {
    isOpen,
    message: value,
    onChange: (event: string) => {
      setValue(event);
    },
    handleClose: () => {
      setIsOpen(false);
    },
    handleOpen: () => {
      setIsOpen(true);
    },
  };
};

export { UpdateMessage, useUpdateMessage };
