import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { TopLineMetrics } from '../../../interfaces/boosts/top_line_metrics';
import api from '../../../services/swarm';

interface Variables {
  boostedPublicationId?: string;
}

const useTopLineMetrics = ({ boostedPublicationId }: Variables) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchMetrics = (): Promise<TopLineMetrics> =>
    api
      .get(`/boosts/monetize/top_line_metrics`, {
        params: {
          publication_id: currentPublicationId,
          boosted_publication_id: boostedPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, boostedPublicationId, 'boosts', 'monetize', 'top_line_metrics'],
    fetchMetrics,
    {
      keepPreviousData: true,
    }
  );
};

export default useTopLineMetrics;
