import moment from 'moment-mini';

import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { PlanPrice } from '@/interfaces/plan_price';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

interface Props {
  title: string;
  timestamp?: string;
  amount?: number;
  interval?: PlanPrice['interval'];
}

const InvoiceCard: React.FC<Props> = ({ title, timestamp, amount, interval }) => {
  return (
    <div className="p-6 w-full rounded space-y-2 bg-surface-50 border border-surface-200">
      <TypographyStack gap="0">
        <Typography token="font-normal/text/sm">{title}</Typography>
        <Typography colorWeight="600" token="font-light/text/sm">
          {timestamp ? moment(timestamp).format('LL') : '-'}
        </Typography>
      </TypographyStack>
      <TypographyRow gap="2">
        <Typography className="w-full" token="font-semibold/text/3xl">
          {typeof amount === 'number' ? currencyFormatter.format(amount / 100) : 'N/A'}
        </Typography>
        {interval && (
          <Typography
            className="w-full mt-auto text-right whitespace-nowrap"
            colorWeight="600"
            token="font-light/text/sm"
          >
            /per {interval}
          </Typography>
        )}
      </TypographyRow>
    </div>
  );
};

export default InvoiceCard;
