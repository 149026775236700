import Text from '@/components/Text';
import { Post, PostStatus } from '@/interfaces/post';

import { DeliveryContextProvider } from './context/DeliveryContext';
import EmailSection from './EmailSection';
import SummarySection from './SummarySection';
import WebSection from './WebSection';

interface PostDeliveryProps {
  post: Post;
  onChange: (data: Post) => void;
}

const PostDelivery = ({ post, onChange }: PostDeliveryProps) => {
  const isEmailLocked = !!post.locked_fields.platform;
  const showEmailSection = post.status === PostStatus.DRAFT;
  const isWebLocked = !!post.locked_fields.audience;
  const showWebSection = !isWebLocked;

  return (
    <div className="p-4 space-y-6">
      <Text size="lg" type="bold">
        Delivery
      </Text>
      <div className="space-y-8">
        <DeliveryContextProvider>
          {showEmailSection && <EmailSection isLocked={isEmailLocked} />}
          {showWebSection && <WebSection post={post} onChange={onChange} isLocked={isWebLocked} />}
        </DeliveryContextProvider>
        <div className="space-y-1">
          <SummarySection />
        </div>
      </div>
    </div>
  );
};

export default PostDelivery;
