import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import styled from 'styled-components';

import { Button } from '../../../../../ui/Button';
import { Icon } from '../../../../../ui/Icon';
import { InputField } from '../../../../../ui/Input';
import { PanelHeadline, PanelSection } from '../../../../../ui/Panel';
import { convertToSection } from '../../../../utils';
import useVisualSettingsPanel from '../VisualSettingsPanel.hooks';

const Styled = {
  Grid: styled.div`
    display: flex;
    gap: 0.25rem;
    padding-inline: 0.375rem;
  `,
  IndividualBorderRadiusSwitch: styled.div`
    flex: 0 0 auto;
  `,
  InputGroup: styled.div`
    display: grid;
    flex: 1 1 auto;
    gap: 0.25rem;
    grid-template-columns: repeat(2, 1fr);
  `,
  InputWrapper: styled.div<{ half?: boolean }>`
    flex: 1 1 auto;
  `,
};

export const BorderRadius = ({
  editor,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  currentNode: Node | null;
  currentNodePos: number;
}) => {
  const [borderRadius, setBorderRadius] = useState({
    topLeft: 0,
    topRight: 0,
    bottomRight: 0,
    bottomLeft: 0,
  });

  const { onSetBorderRadius } = useVisualSettingsPanel(editor, currentNode, currentNodePos, () => {});

  useEffect(() => {
    setBorderRadius({
      topLeft: currentNode?.attrs.borderTopLeftRadius || 0,
      bottomRight: currentNode?.attrs.borderBottomRightRadius || 0,
      topRight: currentNode?.attrs.borderTopRightRadius || 0,
      bottomLeft: currentNode?.attrs.borderBottomLeftRadius || 0,
    });
  }, [
    currentNode?.attrs.borderTopLeftRadius,
    currentNode?.attrs.borderBottomRightRadius,
    currentNode?.attrs.borderTopRightRadius,
    currentNode?.attrs.borderBottomLeftRadius,
  ]);

  const handleBorderRadiusChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setBorderRadius({
      topLeft: Number(e.target.value) || 0,
      bottomRight: Number(e.target.value) || 0,
      topRight: Number(e.target.value) || 0,
      bottomLeft: Number(e.target.value) || 0,
    });
  }, []);

  const toggleIndividualBorderRadius = useCallback(() => {
    convertToSection(editor, currentNode, currentNodePos);

    editor.chain().toggleIndividualSectionBorderRadius().run();
  }, [editor, currentNode, currentNodePos]);

  const updateBorderRadius = useCallback(() => {
    onSetBorderRadius(borderRadius);
  }, [borderRadius]);

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, newBorderRadius: any) => {
      event.stopPropagation();

      if (event.key === 'Enter') {
        onSetBorderRadius({ ...borderRadius, ...newBorderRadius });
      }

      return false;
    },
    [borderRadius]
  );

  return (
    <PanelSection>
      <PanelHeadline>Radius</PanelHeadline>
      <Styled.Grid>
        <Styled.IndividualBorderRadiusSwitch>
          <Button
            $isIconButton
            $active={currentNode?.attrs.useIndividualBorderRadius}
            $muted
            type="button"
            onClick={toggleIndividualBorderRadius}
            $size="small"
            $variant="quaternary"
            $leftSlot={<Icon name="BorderRadius" />}
          />
        </Styled.IndividualBorderRadiusSwitch>
        {currentNode?.attrs.useIndividualBorderRadius ? (
          <Styled.InputGroup>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setBorderRadius({ ...borderRadius, topLeft: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { topLeft: Number(e.currentTarget.value) })}
                onBlur={updateBorderRadius}
                type="number"
                icon="BorderRadiusTopLeft"
                suffix="px"
                value={borderRadius.topLeft.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setBorderRadius({ ...borderRadius, topRight: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { topRight: Number(e.currentTarget.value) })}
                onBlur={updateBorderRadius}
                type="number"
                icon="BorderRadiusTopRight"
                suffix="px"
                value={borderRadius.topRight.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setBorderRadius({ ...borderRadius, bottomLeft: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { bottomLeft: Number(e.currentTarget.value) })}
                onBlur={updateBorderRadius}
                type="number"
                icon="BorderRadiusBottomLeft"
                suffix="px"
                value={borderRadius.bottomLeft.toString()}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper half>
              <InputField
                min="0"
                onChange={(e) => setBorderRadius({ ...borderRadius, bottomRight: Number(e.currentTarget.value) })}
                onKeyUp={(e) => handleKeyUp(e, { bottomRight: Number(e.currentTarget.value) })}
                onBlur={updateBorderRadius}
                type="number"
                icon="BorderRadiusBottomRight"
                suffix="px"
                value={borderRadius.bottomRight.toString()}
              />
            </Styled.InputWrapper>
          </Styled.InputGroup>
        ) : (
          <Styled.InputWrapper>
            <InputField
              min="0"
              onChange={handleBorderRadiusChange}
              onKeyUp={(e) =>
                handleKeyUp(e, {
                  topLeft: Number(e.currentTarget.value) || 0,
                  bottomRight: Number(e.currentTarget.value) || 0,
                  topRight: Number(e.currentTarget.value) || 0,
                  bottomLeft: Number(e.currentTarget.value) || 0,
                })
              }
              onBlur={updateBorderRadius}
              type="number"
              suffix="px"
              value={borderRadius.topLeft.toString()}
            />
          </Styled.InputWrapper>
        )}
      </Styled.Grid>
    </PanelSection>
  );
};

export default BorderRadius;
