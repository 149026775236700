import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TimePeriod } from '@/interfaces/time_period';
import api from '@/services/swarm';

export enum AudienceEndpoints {
  TODAYS_ACTIVE_SUBSCRIBER_COUNT = 'todays_active_subscriber_count',
  ALL_SUBCRIPTION_EVENTS = 'all_subscription_events',
  TOP_REFERRERS = 'top_referrers',
  SUBSCRIPTION_METRICS = 'subscription_metrics',
}

interface Props {
  endpoint: AudienceEndpoints;
  timePeriod: TimePeriod;
}

const useAudience = ({ endpoint, timePeriod }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchInsights = () =>
    api
      .get(`/dashboard/audience/${endpoint}/${timePeriod}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery(['audience', currentPublicationId, endpoint, timePeriod], fetchInsights, {
    refetchOnWindowFocus: false,
    retryOnMount: false,
    enabled: Boolean(currentPublicationId),
  });
};

export default useAudience;
