import { Link, useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import * as CardUI from '@/components/Card/CardUI';
import { BodySm, TitleBase, TitleSm, Typography } from '@/components/Typography';
import { useOpportunities } from '@/hooks/useAdNetwork/publisher';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { Button } from '@/ui/Button';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const PendingAdNetworkOpportunities = () => {
  const navigate = useNavigate();
  const { data } = useOpportunities({ filter: 'pending' });
  const recentAdNetworkOpportunities = data?.pages.flatMap((page) => page.opportunities) || [];
  const hasPendingAdNetworkOpportunities = Boolean(recentAdNetworkOpportunities?.length);
  if (!hasPendingAdNetworkOpportunities) {
    return null;
  }
  const totalAmount = recentAdNetworkOpportunities
    .map((opportunity) => opportunity.estimated_payout_cents)
    .reduce((a, b) => a + b, 0);
  const totalAmountFormat = formatter.format(totalAmount / 100);
  const title = totalAmount >= 1000 ? `Earn ${totalAmountFormat}+ from ads 💰` : 'Earn with ads 💰';

  return (
    <div className="space-y-2">
      <TitleBase>{title}</TitleBase>

      <CardUI.Card className="pt-3" color="info">
        <div className="flex flex-col divide-y divide-y-surface-200">
          {recentAdNetworkOpportunities?.map((opportunity: AdNetworkOpportunity, index: number) => {
            const isFirstItem = index === 0;

            return (
              <Link
                key={opportunity.id}
                to={`/monetize/ads/opportunities/${opportunity.id}`}
                className={cx(
                  'flex space-x-2 items-center justify-between py-3 hover:bg-surface-50',
                  isFirstItem && 'pt-3'
                )}
              >
                <TitleSm className="truncate">{opportunity.advertiser.name}</TitleSm>
                <BodySm className="truncate">Est. {opportunity.estimated_payout}</BodySm>
              </Link>
            );
          })}

          <div className="pt-4 flex justify-between items-center">
            <Button
              size="xs"
              variant="primary-inverse"
              onClick={() => navigate(`/monetize/ads`)}
              Icon={ArrowRightIcon}
              iconRight
            >
              View all
            </Button>
            <Typography size="xs" weight="medium" colorWeight="500">
              Offers expire soon.
            </Typography>
          </div>
        </div>
      </CardUI.Card>
    </div>
  );
};

export default PendingAdNetworkOpportunities;
