import { FC } from 'react';

import PremiumGiftModal from '@/components/PremiumGiftModal';
import { useCurrentPublication, usePremiumGifts } from '@/hooks';

interface Props {
  segmentId: string;
  isOpen: boolean;
  onClose: () => void;
}

const PremiumGift: FC<Props> = (props: Props) => {
  const { segmentId, isOpen, onClose } = props;
  const premiumGiftsQuery = usePremiumGifts({ all: true });
  const premiumGifts = premiumGiftsQuery.data?.pages.flatMap((page) => page.premiumGifts) || [];
  const { data: publication } = useCurrentPublication();

  return (
    <PremiumGiftModal
      isOpen={isOpen}
      onClose={onClose}
      premiumGifts={premiumGifts}
      publicationId={publication?.id || ''}
      segmentId={segmentId}
    />
  );
};
export default PremiumGift;
