import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import PostPoll from '@/components/_domain/PostPoll';
import Text from '@/components/Text';
import { UpgradeIntent } from '@/components/UpgradeIntent';
import { useSettings } from '@/context/settings-context';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { IntentAction } from '@/interfaces/upgrades';
import { Button } from '@/ui/Button';
import { PLAN } from '@/utils/plans';

import { useWelcomeEmailContext } from '../_layout';

const WelcomeEmailOverview = () => {
  const currentPublicationId = useCurrentPublicationId();
  const { welcomeEmail } = useWelcomeEmailContext();
  const { settings } = useSettings();
  const [upgradeIntentOpen, setUpgradeIntentOpen] = useState(false);

  if (!settings?.polls) {
    return (
      <>
        <p className="mb-64 text-gray-600 text-sm">
          Polls are only available on premium beehiiv plans.{' '}
          <span
            aria-hidden
            onClick={() => setUpgradeIntentOpen(true)}
            className="cursor-pointer text-primary-500 underline hover:text-grey-500"
          >
            Upgrade
          </span>{' '}
          to get access.
        </p>
        <UpgradeIntent
          isOpen={upgradeIntentOpen}
          intentAction={IntentAction.USE_POLLS}
          onClose={() => setUpgradeIntentOpen(false)}
          preselectedPlan={PLAN.GROW}
        />
      </>
    );
  }

  if (welcomeEmail.post_poll_ids.length === 0) {
    return (
      <div className="text-center mt-2 bg-white rounded py-8 px-2 flex flex-col space-y-4">
        <Text size="sm">Your welcome email does not currently include any polls.</Text>
        <Link to="/polls">
          <Button variant="primary-inverse" onClick={() => {}} Icon={PlusIcon}>
            Create one
          </Button>
        </Link>{' '}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {welcomeEmail.post_poll_ids.map((postPollId) => (
        <PostPoll publicationId={currentPublicationId} postPollId={postPollId} />
      ))}
    </div>
  );
};

export default WelcomeEmailOverview;
