import { useQuery } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import type { PartnerCurrentTier, PartnerNextTier } from '@/interfaces/partner_tier';
import api from '@/services/swarm';

interface ReturnData {
  tiers: {
    current: PartnerCurrentTier,
    next: PartnerNextTier,
  },
};

const usePartnerTier = () => {
  const { currentUser } = useCurrentUser();

  const { data, isLoading, refetch } = useQuery<ReturnData>(['partner_program', 'partner_tiers', currentUser], () =>
    api.get('/partner_program/partner_tiers').then((res) => res.data)
  );

  return { data, isLoading, refetch };
};

export default usePartnerTier;
