import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography, TypographyRow } from '@/components/Typography';
import { useStripeBillingSession } from '@/hooks/useBilling';
import { BillingActions } from '@/interfaces/billing';
import { currencyFormatter } from '@/utils';

interface Props {
  organizationId: string;
  publicationId: string;
  isUpgrade: boolean;
  amountDue: number;
  nextBillingDate: string | null;
}

const DisclaimerText: React.FC<Props> = ({ organizationId, publicationId, isUpgrade, amountDue, nextBillingDate }) => {
  const stripeInfoSession = useStripeBillingSession({
    organizationId,
    publicationId,
    action: BillingActions.INFO,
    trackingEvent: 'Started Billing Details Update',
  });
  return (
    <>
      {isUpgrade ? (
        <Typography token="font-normal/text/xs">
          Clicking Confirm will charge your card on file for the prorated amount of{' '}
          {currencyFormatter(amountDue / 100, 'USD', 2, 2)} and immediately update your plan.
        </Typography>
      ) : (
        <Typography token="font-normal/text/xs">
          Clicking Confirm will schedule your plan to change on{' '}
          {nextBillingDate ? moment(nextBillingDate).format('LL') : 'your next billing cycle'}. This <b>will not</b>{' '}
          charge your card until that point.
        </Typography>
      )}
      {stripeInfoSession.isLoading || stripeInfoSession.isSuccess ? (
        <LoadingSpinner size="sm" />
      ) : (
        <TypographyRow gap="1" className="items-center">
          <button type="button" className="underline" onClick={() => stripeInfoSession.mutate({})}>
            <Typography token="font-normal/text/xs">Manage your billing details in Stripe</Typography>
          </button>
          <ArrowTopRightOnSquareIcon className="h-3 w-3 mt-1" />
        </TypographyRow>
      )}
    </>
  );
};

export default DisclaimerText;
