import { useMemo } from 'react';

import { BarChart, ChartCard } from '@/components/Chartsv2';
import { TimePeriod } from '@/interfaces/time_period';

const STRIPE_EVENTS_LAUNCH_DATE = new Date(2023, 2, 30);

const getNegativeNumber = (number: number) => {
  return number < 0 ? number : -number;
};

interface ChartData {
  data: {
    labels: string[];
    datasets: Dataset[];
  };
  expires_at: string;
  period: TimePeriod;
  time_zone: string;
  total: number;
}

type Dataset = {
  identifier: string;
  data: {
    period: number[];
    previous_period: number[];
  };
};

interface Props {
  actionChildren?: React.ReactNode;
  data: ChartData;
  isLoading: boolean;
  isPremiumPublication?: boolean;
}

const dateFormatter = Intl.DateTimeFormat('default');
const formattedStripeEventsLaunchDate = dateFormatter.format(STRIPE_EVENTS_LAUNCH_DATE);

const SubscriptionStateEvents = ({ actionChildren, data, isLoading, isPremiumPublication = false }: Props) => {
  const tremorChartData = useMemo(() => {
    if (data) {
      if (!isPremiumPublication) {
        const { labels, datasets }: any = data.data;
        const subscribed = datasets?.find((dataset: Dataset) => dataset.identifier === 'subscribed');
        const unsubscribed = datasets?.find((dataset: Dataset) => dataset.identifier === 'unsubscribed');

        return labels?.map((label: string, index: number) => {
          return {
            name: label,
            subscribed: subscribed?.data?.period?.[index] || 0,
            unsubscribed: getNegativeNumber(unsubscribed?.data?.period?.[index] || 0),
          };
        });
      }

      const { labels, datasets } = data.data;
      const subscribed = datasets?.find((dataset) => dataset.identifier === 'subscribed');
      const upgraded = datasets?.find((dataset) => dataset.identifier === 'upgraded');
      const downgraded = datasets?.find((dataset) => dataset.identifier === 'downgraded');
      const unsubscribed = datasets?.find((dataset) => dataset.identifier === 'unsubscribed');
      const expired = datasets?.find((dataset) => dataset.identifier === 'expired');

      return labels?.map((label, index) => {
        return {
          name: label,
          subscribed: subscribed?.data?.period?.[index] || 0,
          unsubscribed: getNegativeNumber(unsubscribed?.data?.period?.[index] || 0),
          upgraded: upgraded?.data?.period?.[index] || 0,
          downgraded: getNegativeNumber(downgraded?.data?.period?.[index] || 0),
          expired: getNegativeNumber(expired?.data?.period?.[index] || 0),
        };
      });
    }

    return [];
  }, [data, isPremiumPublication]);

  const noResults = tremorChartData?.length === 0 && !isLoading;
  const categories = isPremiumPublication
    ? ['subscribed', 'unsubscribed', 'upgraded', 'downgraded', 'expired']
    : ['subscribed', 'unsubscribed'];

  return (
    <ChartCard
      title="Subscription Events"
      description="All subscription events for your publication including premium upgrades and downgrades."
      isLoading={isLoading}
      noResultsText="No active subscribers within the chosen time range."
      tooltipText={`Upgrade, Downgrade and Expire events are tracked as of ${formattedStripeEventsLaunchDate}.`}
      noResults={noResults}
      actionChildren={actionChildren}
    >
      <BarChart
        className="mt-6"
        data={tremorChartData}
        index="name"
        categories={categories}
        allowDecimals={false}
        colors={['violet', 'gray', 'green', 'red', 'amber']}
        yAxisWidth={48}
      />
    </ChartCard>
  );
};

export default SubscriptionStateEvents;
