import { useQuery } from 'react-query';

import { BoostInvite } from '@/interfaces/boosts/monetize/boost_invite';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

interface ApiResponse {
  boost_invite: BoostInvite;
}

const useBoostInvite = (boostInviteId: string | null) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchBoosInvite = (): Promise<ApiResponse> =>
    api
      .get(`/boosts/monetize/boost_invites/${boostInviteId}`, { params: { publication_id: currentPublicationId } })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'monetize', 'boost_invite', boostInviteId], fetchBoosInvite, {
    enabled: !!boostInviteId,
  });
};

export default useBoostInvite;
