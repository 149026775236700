import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useUpdateEmailMessage } from '@/hooks';
import { EmailMessage } from '@/interfaces/email_message';

const useUpdateUpsellEmail = (publicationId: string, upsellEmail: EmailMessage) => {
  const queryClient = useQueryClient();

  return useUpdateEmailMessage(publicationId, {
    emailMessageId: upsellEmail?.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['upsell_email', publicationId], { exact: true });
      queryClient.invalidateQueries(['email_messages', 'preview', upsellEmail?.id], { exact: true });
      toast.success('Reminder Email saved');
    },
    onError: (err: any) => toast.error(err?.response?.data?.error || 'Something went wrong, please try again'),
  });
};

export default useUpdateUpsellEmail;
