import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{}>;

const TopNav = ({ children }: Props) => {
  return (
    <nav className="w-full bg-surface-50 px-4 py-3 flex justify-between border-b border-b-surface-200">{children}</nav>
  );
};

export default TopNav;
