import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { SplitTest } from '../../interfaces/split_test';
import api from '../../services/swarm';

export default function useSplitTest(postId: string) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchSplitTest = () =>
    api
      .get(`/posts/${postId}/split_test`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<SplitTest>(['split_test', postId], fetchSplitTest, {
    keepPreviousData: true,
  });
}
