import React, { PropsWithChildren, useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';
import pluralize from '@/utils/pluralize';

import Modal from '../../../../components/Modal';
import { PostWarnings } from '../../../../interfaces/post';

interface Props {
  warnings?: PostWarnings;
}

const Notification: React.FC<PropsWithChildren<{title: string, type: 'warning' | 'danger'}>> = ({ title, type, children }) => {
  const borderClass = type === "warning" ? 'border-yellow-500' : 'border-red-500';
  const bgClass = type === "warning" ? 'bg-yellow-50' : 'bg-red-50';
  const iconClass = type === "warning" ? 'text-yellow-500' : 'text-red-500';
  const preventionVerb = type === "warning" ? "will not" : "will";

  return (
    <div className={cx("rounded-md border-2 text-gray-800", borderClass, bgClass)}>
      <div className="px-6 pt-6 pb-4 grid grid-flow-col grid-cols-[min-content_auto] grid-rows-[repeat(2,_minmax(0,_min-content))] gap-2">
        <ExclamationTriangleIcon className={cx("h-5 w-5 row-span-2", iconClass)} />
        <Typography token="font-medium/text/sm" as="div">
          {title}
        </Typography>
        <Typography token="font-normal/text/sm" as="div" colorWeight='800'>
          {children}
        </Typography>
      </div>
      <div className="pb-2 text-right px-2">
        <Typography token="font-normal/text/xs" colorWeight='500'>
          This <b>{preventionVerb}</b> prevent you from publishing your post
        </Typography>
      </div>
    </div>
  )
}

const WarningNotifier: React.FunctionComponent<Props> = (props: Props) => {
  const { warnings = {} } = props;
  const [active, setActive] = useState(false);

  const warningKeys = ['invalid_links', 'post_size']
  const warningCount = (Object.keys(warnings).filter((key) => warningKeys.includes(key))).length;

  const errorKeys = ['ad_opportunities']
  const errorCount = (Object.keys(warnings).filter((key) => errorKeys.includes(key))).length;

  if (warningCount === 0 && errorCount === 0) {
    return null;
  }

  const buttonClasses = errorCount > 0
    ? "bg-red-100 text-red-700 hover:bg-red-200 hover:text-red-800"
    : "bg-yellow-100 text-yellow-700 hover:bg-yellow-200 hover:text-yellow-800"

  const buttonText = [
    (warningCount > 0 ? pluralize('Warning', warningCount) : null),
    (errorCount > 0 ? pluralize('Error', errorCount) : null)
  ].filter((v) => !!v).join(' & ')

  return (
    <>
      <Modal isOpen={active} onClose={() => setActive(false)} className="w-full max-w-2xl">
        <div className="px-12 py-16">
          <TypographyStack gap='2' className='max-w-xl'>
            <Typography token="font-medium/text/2xl" colorWeight='800'>Warnings And Errors</Typography>
            <Typography token="font-normal/text/sm" colorWeight='500'>
              We auto-detect potential issues with your post. Errors will keep you from publishing your post while warnings will not.
            </Typography>
          </TypographyStack>
          <div className="mt-8 flex-col space-y-8">
            {warnings.ad_opportunities && (
              <Notification title="Problem with included ads" type="danger">
                {warnings.ad_opportunities}
              </Notification>
            )}
            {warnings.post_size && (
              <Notification title="Your post is large and may get clipped by Gmail" type="warning">
                <>
                  <b>Size Estimate</b>
                  <p>{warnings.post_size} KB</p>
                </>
              </Notification>
            )}
            {warnings.invalid_links && (
              <Notification title="Tracking Links" type="warning">
                You have pasted a tracking link from another beehiiv post into your content. We recommend that you
                use the tracking link&apos;s target url instead. To avoid this issue in the future, you can
                duplicate posts instead of copying and pasting from them.
              </Notification>
            )}
          </div>
        </div>
      </Modal>
      <span className="relative">
        <button
          type="button"
          className={cx("flex rounded-md items-center py-1 px-2 space-x-2 text-xs text-center", buttonClasses)}
          onClick={() => setActive(true)}
        >
          {buttonText}
        </button>
      </span>
    </>
  );
};

export default WarningNotifier;
