import { createContext,Dispatch, MutableRefObject, SetStateAction  } from "react";

export const CanvasPositionContext = createContext<{
    x: number;
    y: number;
    scale: number;
    canvasRef?: MutableRefObject<HTMLElement | null>;
  }>({
    x: 0,
    y: 0,
    scale: 1,
  });

  export const CanvasPositionDispatchContext = createContext<{
    setX: Dispatch<SetStateAction<number>>;
    setY: Dispatch<SetStateAction<number>>;
    setScale: Dispatch<SetStateAction<number>>;
  }>({
    setX: () => {},
    setY: () => {},
    setScale: () => {},
  });
