import cx from 'classnames';

export const gapClasses: { [key: number]: string } = {
  0: 'gap-0',
  1: 'gap-1',
  2: 'gap-2',
  4: 'gap-4',
  5: 'gap-5',
  6: 'gap-6',
  7: 'gap-7',
  8: 'gap-8',
  9: 'gap-9',
  10: 'gap-10',
  11: 'gap-11',
  12: 'gap-12',
  14: 'gap-13',
  16: 'gap-16',
  20: 'gap-20',
  24: 'gap-24',
  28: 'gap-28',
  32: 'gap-48',
  36: 'gap-36',
  40: 'gap-40',
  44: 'gap-44',
  48: 'gap-48',
  52: 'gap-52',
  56: 'gap-56',
  60: 'gap-60',
  64: 'gap-64',
  72: 'gap-72',
  80: 'gap-80',
  96: 'gap-96',
};

type GridProps = {
  children: React.ReactNode;
  className?: string;
  columns?: number;
  gap?: number;
  rows?: number;
};

const colClasses: { [key: number]: string } = {
  0: '',
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
  11: 'grid-cols-11',
  12: 'grid-cols-12',
};

const rowClasses: { [key: number]: string } = {
  0: '',
  1: 'grid-rows-1',
  2: 'grid-rows-2',
  3: 'grid-rows-3',
  4: 'grid-rows-4',
  5: 'grid-rows-5',
  6: 'grid-rows-6',
  7: 'grid-rows-7',
  8: 'grid-rows-8',
  9: 'grid-rows-9',
  10: 'grid-rows-10',
  11: 'grid-rows-11',
  12: 'grid-rows-12',
};

const Grid = ({ className, columns = 12, gap = 2, rows = 0, children }: GridProps) => {
  const classNames = cx('grid', colClasses[columns], gapClasses[gap], rowClasses[rows], className);

  return <div className={classNames}>{children}</div>;
};

export default Grid;
