import { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import { Button } from '@/ui/Button';

import { useSubscriberContext } from '../../_context/subscriberContext';
import useUpdateSubscriptionEmail from '../../_hooks/useUpdateSubscriptionEmail';

const EmailUpdateModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');

  const { subscriber, subscription } = useSubscriberContext();
  const mutation = useUpdateSubscriptionEmail({ id: subscription.id });

  const clearModal = () => {
    setIsOpen(false);
    setEmail('');
  };

  const handleSubmit = () => mutation.mutate({ email }, { onSettled: clearModal });

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        onClose={clearModal}
        onProceed={handleSubmit}
        resourceId={subscriber.id}
        isWorking={mutation.isLoading}
        headerText="Update Subscriber Email"
        actionText="Update"
      >
        <Input
          className="w-full"
          name="email"
          labelText="New Email"
          value={email}
          helperText="Enter the new email address for this subscriber"
          placeholderText={subscriber.email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </ActionModal>
      <Button type="button" onClick={() => setIsOpen(true)} Icon={PencilSquareIcon} variant="flush" size="xs" />
    </>
  );
};

export default EmailUpdateModal;
