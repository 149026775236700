import toast from 'react-hot-toast';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';

import { API } from '../../lib/api';
import { fileTypeRegex } from '../../lib/constants';
import { getFileSizeStringWithUnit } from '../../lib/utils/getFileSizeStringWithUnit';

import { IFileAttrs } from './types';

const processFile = async ({
  file,
  publicationId,
  userId,
  view,
}: {
  file: File;
  publicationId: string;
  userId: string;
  view: EditorView;
}) => {
  const $pos = view.state.selection.$from;

  try {
    const res = await API.getUploadPublicationDownloadablePresignedUrl({
      file,
      publicationId,
      userId,
    });

    const { file_upload_url: presignedUrl, downloadable_id: fileAttachmentId } = res.data;

    await API.uploadFileToPresignedUrl({ file, presignedUrl });

    await API.markDownloadableAsUploaded({ downloadableId: fileAttachmentId, publicationId });

    const fileAttrs: Partial<IFileAttrs> = {
      id: fileAttachmentId,
      size: getFileSizeStringWithUnit(file.size),
      description: '',
      title: file.name,
      type: file.type,
    };

    const node = view.state.schema.nodes.fileAttachment.create(fileAttrs);

    const { tr } = view.state;

    tr.replaceRangeWith($pos.pos, $pos.pos, node);

    view.dispatch(tr);
  } catch {
    toast.error('There was a problem uploading this file.');
  }
};

export const getFileDropPlugin = ({ publicationId, userId }: { publicationId: string; userId: string }): Plugin => {
  return new Plugin({
    key: new PluginKey('filePasteDropHandlerPlugin'),

    props: {
      handleDOMEvents: {
        paste(view, event) {
          if (!event.clipboardData || event.clipboardData.files.length === 0) {
            return false;
          }

          const { types } = event.clipboardData;

          const containsRichText = ['text/plain', 'text/html', 'text/rtf', 'Files'].every((type) =>
            types.includes(type)
          );

          if (containsRichText) {
            return false;
          }

          const file = event.clipboardData.files[0];
          const isFile = fileTypeRegex.test(file.type);

          if (!isFile) {
            return false;
          }

          event.preventDefault();

          processFile({ file, publicationId, userId, view });

          return true;
        },
        drop: (view, event) => {
          if (!event.dataTransfer || event.dataTransfer.files.length === 0) {
            return false;
          }

          const file = event.dataTransfer.files[0];
          const isAllowedFile = fileTypeRegex.test(file.type);

          if (!isAllowedFile) {
            return false;
          }

          event.preventDefault();

          processFile({ file, publicationId, userId, view });

          return true;
        },
      },
    },
  });
};
export default getFileDropPlugin;
