import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/24/outline';

import { Input } from '../../../components/Form';

import SaveButton from './SaveButton';

interface Props {
  name?: string;
  label: string;
  value: string;
  placeholder?: string;
  onSave: (val: string) => any;
  onEditingChange?: (val: boolean) => any;
  validationCheck?: (val: string) => boolean;
  isSaving: boolean;
  isInitiallyEditing?: boolean;
  validationFailMessage?: string;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
}

const SimpleInputForm = ({
  name,
  label,
  value,
  onSave,
  isSaving,
  isInitiallyEditing = false,
  placeholder,
  onEditingChange,
  validationFailMessage,
  toastSuccessMessage,
  toastErrorMessage,
  validationCheck,
}: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(isInitiallyEditing);
  const [inputValue, setInputValue] = useState<string>(value);
  const showSaving = isSaving && isEditing;

  useEffect(() => {
    if (onEditingChange) {
      onEditingChange(isEditing);
    }
  }, [isEditing]);

  const handleSave = async () => {
    // Check for validation if one is provided
    if (validationCheck) {
      if (!validationCheck(inputValue)) {
        toast.error(validationFailMessage || 'Something went wrong');
        return;
      }
    }

    if (inputValue !== value) {
      await onSave(inputValue)
        .then(() => {
          if (toastSuccessMessage) {
            toast.success(toastSuccessMessage);
          }
          setIsEditing(false);
        })
        .catch(() => {
          if (toastErrorMessage) {
            toast.error(toastErrorMessage);
          }
        });
    } else {
      setIsEditing(false);
    }
  };

  if (isEditing) {
    return (
      <div className="flex justify-between pb-4 space-x-6 w-full">
        <Input
          className="w-full max-w-md"
          name={name || label.split(' ').join('-')}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <SaveButton
          isSaving={showSaving}
          closeEditing={() => setIsEditing(false)}
          isEditing={isEditing}
          changesDetected={Boolean(inputValue !== value)}
          type="button"
          onClick={handleSave}
        />
      </div>
    );
  }

  return (
    <div className="flex justify-between items-center w-full">
      <p className="block text-sm font-semibold text-gray-700">{label}</p>

      <div className="">
        <button
          className="h-6 w-6 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
          type="button"
          onClick={() => setIsEditing(true)}
        >
          <PencilIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};

export default SimpleInputForm;
