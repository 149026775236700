import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  disableSidePanel?: boolean;
}>;

const SidePanel = ({ children, disableSidePanel = false }: Props) => {
  return (
    <nav
      className={cx(
        'h-full min-w-[400px] w-[400px] overflow-x-hidden overflow-y-auto bg-white shadow-lg px-6 relative border-r border-r-surface-200',
        disableSidePanel && 'opacity-50 pointer-events-none'
      )}
    >
      {children}
    </nav>
  );
};

export default SidePanel;
