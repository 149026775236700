import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { cx } from 'class-variance-authority';

import { Typography } from '@/components/Typography';
import { Calendar } from '@/hooks/useCalendar/types';

import CategoryLegend from './CategoryLegend';
import { useCalendarContext } from './useCalendarContext';

interface CalendarLegendProps {
  calendar: Calendar;
}

const CalendarLegend = ({ calendar }: CalendarLegendProps) => {
  const colorClass = {
    pink: 'bg-pink-500',
    violet: 'bg-violet-500',
  }[calendar.color];

  const { settings, toggleCalendar } = useCalendarContext();

  return (
    <Disclosure as="div" defaultOpen key={calendar.id} className="space-y-2">
      {({ open }) => (
        <>
          <div className="relative">
            <Disclosure.Button
              className="group flex space-x-2 items-center p-1 hover:bg-gray-100 rounded w-full outline-none focus:ring ring-gray-300"
              aria-label="Expand calendar legend"
            >
              <div className={cx('h-4 w-4 rounded', colorClass)}>&nbsp;</div>
              <div className="flex items-center justify-between cursor-pointer">
                <div className="flex items-center space-x-2">
                  <Typography as="h2" token="font-normal/text/sm" colorWeight="700">
                    {calendar.name}
                  </Typography>
                  <ChevronDownIcon
                    className={cx('h-4 w-4 transform transition duration-100 ease-in-out', open ? 'rotate-180' : '')}
                  />
                </div>
              </div>
            </Disclosure.Button>
            <div className="absolute top-1/2 transform -translate-y-1/2 right-1 flex items-center">
              <input
                type="checkbox"
                className="size-4 rounded border-gray-200 text-gray-600 outline-none focus:ring ring-gray-300 border-1"
                id={`calendar-${calendar.id}`}
                onChange={() => toggleCalendar(calendar.id)}
                checked={Object.values(settings?.[calendar.id] || {}).every((value) => value)}
                aria-label={`Toggle ${calendar.name}`}
              />
            </div>
          </div>
          <Disclosure.Panel className="space-y-2">
            {calendar.categories.map((category) => (
              <CategoryLegend key={category.id} category={category} calendarId={calendar.id} />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CalendarLegend;
