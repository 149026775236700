import { FormQuestion, FormQuestionOption } from '@/interfaces/form';

interface Props {
  question: FormQuestion;
}

const MultipleChoicePreview = ({ question }: Props) => (
  <div className="mt-2 space-y-2">
    {question.form_question_options.map((questionOption: FormQuestionOption) => {
      return (
        <div className="flex items-center" key={questionOption.id}>
          <input
            id={questionOption.id}
            name={question.id}
            value={questionOption.value}
            type={question.multi_select ? 'checkbox' : 'radio'}
            defaultChecked={false}
            className="h-5 w-5 cursor-pointer border-gray-300 text-wt-primary focus:ring-wt-primary"
          />
          <label
            htmlFor={questionOption.id}
            className="text-md wt-body-font ml-3 block cursor-pointer font-semibold text-gray-700"
          >
            {questionOption.value}
          </label>
        </div>
      );
    })}
  </div>
);

export default MultipleChoicePreview;
