import { ReactNode } from 'react';

import { Typography } from '@/components/Typography';

interface SettingsContainerProps {
  children: ReactNode;
}

interface SettingsGroupProps {
  title: string;
  description: string;
  children: JSX.Element;
}

interface SettingsSubheadingProps {
  title: string;
  description?: string;
}

interface SettingsSubContainerProps {
  children: ReactNode;
}

export const SettingsContainer = ({ children }: SettingsContainerProps) => {
  return (
    <div className="flex flex-col items-center h-full overflow-auto">
      <div className="flex flex-col space-y-6 w-3/5 pb-8 pt-4">{children}</div>
    </div>
  );
};

export const SettingsGroup = ({ title, description, children }: SettingsGroupProps) => {
  return (
    <div className="flex flex-col  space-y-6 p-8 border rounded-lg border-surface-200">
      <div className="flex flex-col space-y-1">
        <Typography token="font-medium/text/lg" className="text-gray-900">
          {title}
        </Typography>
        <Typography token="font-normal/text/sm" className="text-gray-700">
          {description}
        </Typography>
      </div>
      {children}
    </div>
  );
};

export const SettingsSubheading = ({ title, description }: SettingsSubheadingProps) => {
  return (
    <div className="flex flex-col space-y-1">
      <Typography token="font-medium/text/base" className="text-gray-900">
        {title}
      </Typography>
      <Typography token="font-light/text/xs" className="text-gray-900">
        {description}
      </Typography>
    </div>
  );
};

export const SettingsSubContainer = ({ children }: SettingsSubContainerProps) => {
  return <div className="p-6 border rounded-lg border-surface-200 flex flex-col space-y-6">{children}</div>;
};

const LoaderLine = () => {
  return <div className="h-4 bg-surface-200 rounded w-full" />;
};

export const SettingsLoader = () => {
  return (
    <div className="flex flex-col space-y-6">
      <div className="animate-pulse flex flex-col space-y-1">
        <LoaderLine />
        <LoaderLine />
      </div>
      <div className="animate-pulse flex flex-col space-y-1">
        <LoaderLine />
        <LoaderLine />
      </div>
      <div className="animate-pulse flex flex-col space-y-1">
        <LoaderLine />
        <LoaderLine />
      </div>
    </div>
  );
};
