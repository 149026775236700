const BackgroundGradient = ({ className }: { className: string }) => (
  <img
    src="https://media.beehiiv.com/cdn-cgi/image/format=auto,quality=85,sharpen=1/static_assets/gradient-background.png"
    alt="beehiiv gradient"
    className={`${className} select-none`}
    draggable="false"
    style={{ WebkitTouchCallout: 'none' }}
  />
);

export default BackgroundGradient;
