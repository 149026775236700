import { useNavigate } from 'react-router-dom';
import {
  ArrowPathIcon,
  ComputerDesktopIcon,
  DocumentDuplicateIcon,
  PaintBrushIcon,
  SparklesIcon,
  SwatchIcon,
} from '@heroicons/react/20/solid';

import {
  AccordionCard,
  AccordionIconWrapper,
  AccordionListItem,
} from '../../../components/Accordion/variants/AccordionCard';

const WebsiteActions = () => {
  const navigate = useNavigate();

  return (
    <AccordionCard
      title={
        <div className="flex items-center">
          <AccordionIconWrapper>
            <ComputerDesktopIcon className="w-4 h-4 text-feedback-info-500" />
          </AccordionIconWrapper>
          Build your website
        </div>
      }
      subText="Create a beautiful website that will excite your readers."
    >
      <div className="space-y-1">
        <AccordionListItem
          title="Design website"
          icon={<PaintBrushIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/settings/website/pages#landing-page')}
        />
        <AccordionListItem
          title="Edit theme"
          icon={<SwatchIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/settings/website#color-palette')}
        />
        <AccordionListItem
          title="Customize navbar"
          icon={<SparklesIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/settings/website/navbar')}
        />
        <AccordionListItem
          title="Build webpages"
          icon={<DocumentDuplicateIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/settings/website/pages#pages')}
        />
        <AccordionListItem
          title="Add a subscribe flow"
          icon={<ArrowPathIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/settings/website/advanced#subscribe-flow')}
        />
      </div>
    </AccordionCard>
  );
};

export default WebsiteActions;
