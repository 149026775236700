import { Fragment, useState } from 'react';
import { usePopper } from 'react-popper';
import { Combobox, Transition } from '@headlessui/react';
import { CaretUpDown, Check } from '@phosphor-icons/react';

import { cn } from '../../_utils/cn';

export interface ComboboxOption {
  id: number | string;
  name: string;
}

interface ComboboxInputProps {
  options: ComboboxOption[];
  selectedOption: ComboboxOption | null;
  onChange: (selected: ComboboxOption) => void;
  onInputChange?: (query: string) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const ComboboxInput: React.FC<ComboboxInputProps> = ({
  options,
  selectedOption,
  onChange,
  onInputChange,
  placeholder = 'Select an option...',
  onKeyPress,
}) => {
  const [query, setQuery] = useState('');
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start'],
        },
      },
    ],
  });

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  return (
    <div className="relative w-full max-w-sm">
      <Combobox value={selectedOption} onChange={onChange}>
        <div ref={setReferenceElement} className="relative">
          <div className="relative h-10 w-full cursor-default overflow-hidden rounded-lg border border-wb-primary bg-wb-primary text-left shadow-sm focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 sm:text-sm">
            <Combobox.Input
              className="flex h-10 w-full border-none bg-wb-primary px-3 py-2 text-sm placeholder:text-wb-tertiary focus:ring-0"
              displayValue={(option: ComboboxOption) => option?.name || ''}
              placeholder={placeholder}
              onChange={(event) => {
                setQuery(event.target.value);
                onInputChange?.(event.target.value);
              }}
              onKeyPress={onKeyPress}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <CaretUpDown className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-10 max-h-60 w-full overflow-auto rounded-md bg-wb-modal p-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              {filteredOptions.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700 'text-sm tracking-tight font-medium'">
                  Nothing found.
                </div>
              ) : (
                filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.id}
                    className={({ active }) =>
                      cn(
                        'cursor-pointer select-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                        'flex items-center justify-center rounded-lg transition-colors duration-200 focus:outline-none',
                        'bg-transparent hover:bg-wb-secondary active:bg-wb-secondary text-wb-secondary',
                        'text-wb-primary-soft py-1.5 pr-8 pl-2 justify-start gap-2 rounded-md w-full',
                        'text-sm tracking-tight font-medium',
                        active ? 'bg-wb-secondary text-wb-primary' : ''
                      )
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {option.name}
                        </span>
                        {selected && (
                          <span className="absolute right-4 flex h-3.5 w-3.5 items-center justify-center">
                            <Check className="h-4 w-4" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default ComboboxInput;
