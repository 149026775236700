import * as React from 'react';
import { Minus, Plus } from '@phosphor-icons/react';

import { cn } from '../../_utils/cn';

import InputWrapper from './InputWrapper';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  helperText?: string | React.ReactNode;
  inputClassName?: string;
  labelInfo?: string;
  LeftIcon?: React.ElementType;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, inputClassName, labelText, helperText, type, labelInfo, LeftIcon, ...props }, ref) => {
    return (
      <InputWrapper labelText={labelText} helperText={helperText} className={className} labelInfo={labelInfo}>
        <div className="relative">
          {LeftIcon && (
            <LeftIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-wb-secondary pointer-events-none" />
          )}
          <input
            type={type}
            className={cn(
              'shadow-sm flex h-10 w-full rounded-lg border border-wb-primary bg-wb-primary px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-wb-tertiary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
              inputClassName,
              LeftIcon && 'pl-10'
            )}
            ref={ref}
            {...props}
          />
        </div>
      </InputWrapper>
    );
  }
);
Input.displayName = 'Input';

const NumberInput = React.forwardRef<
  HTMLInputElement,
  InputProps & { onClickMinus: () => void; onClickPlus: () => void }
>(({ className, inputClassName, labelText, helperText, type, labelInfo, onClickMinus, onClickPlus, ...props }, ref) => {
  return (
    <InputWrapper labelText={labelText} helperText={helperText} className={className} labelInfo={labelInfo}>
      <div className="relative">
        <Minus
          size={16}
          weight="bold"
          className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-wb-secondary cursor-pointer"
          onClick={onClickMinus}
        />
        <input
          type={type}
          className={cn(
            'shadow-sm flex h-10 w-full rounded-lg border border-wb-primary bg-wb-primary py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-wb-tertiary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 px-10 text-center max-w-[150px]',
            inputClassName
          )}
          ref={ref}
          {...props}
        />
        <Plus
          size={16}
          weight="bold"
          className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-wb-secondary cursor-pointer"
          onClick={onClickPlus}
        />
      </div>
    </InputWrapper>
  );
});
NumberInput.displayName = 'NumberInput';

type SimpleInputWrapperProps = {
  children: React.ReactNode;
  className?: string;
  [key: string]: any;
};

const SimpleInputWrapper = React.forwardRef<HTMLDivElement, SimpleInputWrapperProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'grow w-full bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-2 cursor-pointer',
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
SimpleInputWrapper.displayName = 'SimpleInputWrapper';

export interface SimpleInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const SimpleInput = React.forwardRef<HTMLInputElement, SimpleInputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'bg-transparent border-none border-transparent p-0 text-sm placeholder:text-wb-tertiary disabled:cursor-not-allowed disabled:opacity-50 outline-none focus:ring-0 focus:ring-transparent w-full',
        className
      )}
      ref={ref}
      {...props}
    />
  );
});

SimpleInput.displayName = 'SimpleInput';

export { Input, NumberInput, SimpleInput, SimpleInputWrapper };
