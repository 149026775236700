import { ReactNode, useRef } from 'react';
import { Combobox } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { PublicationSearch } from '@/interfaces/publication_search';

import ActiveItem from './ActiveItem';
import SearchListItem from './SearchListItem';
import TagSelect from './TagSelect';

type Props = {
  publications: PublicationSearch[];
  isFetchingPublications: boolean;
  query: string;
  onSetQuery: (query: string) => void;
  onRenderActiveOption?: (publication: any) => React.ReactNode;
  showFilters?: boolean;
  ActiveListItemComponent?: (props: any) => JSX.Element;
  header?: ReactNode;
  footer?: ReactNode;
  activePublicationId?: string;
};

export default function SearchPublication({
  query,
  onSetQuery,
  publications,
  isFetchingPublications,
  onRenderActiveOption,
  header,
  footer,
  activePublicationId,
  showFilters = true,
  ActiveListItemComponent = ActiveItem,
}: Props) {
  const isNoResults = publications.length === 0;

  const inputRef = useRef<HTMLInputElement>(null);

  const changeQuery = (str: string) => {
    onSetQuery(str);

    if (inputRef.current) inputRef.current.value = str;
  };

  const selectedPublication = activePublicationId
    ? publications.find((publication) => publication.id === activePublicationId)
    : publications[0];

  return (
    <>
      {header}
      <Combobox value={selectedPublication} onChange={() => {}}>
        {({ activeOption }) => {
          return (
            <>
              {showFilters && (
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(e) => changeQuery(e.target.value)}
                    ref={inputRef}
                  />
                  <TagSelect query={query} onChangeQuery={changeQuery} />
                  <LoadingSpinner
                    className="pointer-events-none absolute top-3.5 right-4"
                    size="md"
                    delay={500}
                    visible={isFetchingPublications}
                  />
                </div>
              )}

              {!isNoResults && (
                <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
                  <div
                    className={classNames(
                      'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                      activeOption ? 'sm:h-96' : ''
                    )}
                  >
                    {showFilters && (
                      <div className="relative">
                        <h2 className="mt-2 mb-4 text-xs font-semibold text-gray-500">Publications</h2>
                        <button
                          className="text-xs font-semibold text-gray-500 absolute top-0 right-0 hover:text-primary-500"
                          onClick={() => changeQuery(`${query} #`)}
                          type="button"
                        >
                          Discover
                        </button>
                      </div>
                    )}
                    <div className="-mx-2 text-sm text-gray-700">
                      {publications.map((publication) => (
                        <Combobox.Option
                          as="div"
                          key={publication.id}
                          value={publication}
                          className={({ active }) =>
                            classNames(
                              'flex select-none items-center rounded-md p-2',
                              active && 'bg-gray-100 text-gray-900'
                            )
                          }
                        >
                          {({ active }) => <SearchListItem isActive={active} publication={publication} />}
                        </Combobox.Option>
                      ))}
                    </div>
                  </div>
                  {activeOption && (
                    <div className="hidden w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                      <ActiveListItemComponent
                        publication={activeOption}
                        onTagClick={(tag: string) => changeQuery(`${query} #${tag} `)}
                      />
                      {onRenderActiveOption?.(activeOption)}
                    </div>
                  )}
                </Combobox.Options>
              )}
              {isNoResults && query !== '' && (
                <div className="py-14 px-6 text-center text-sm sm:px-14">
                  <ChatBubbleBottomCenterTextIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                  <p className="mt-4 font-semibold text-gray-900">No publications found</p>
                  <p className="mt-2 text-gray-500">We couldn&apos;t find anything with that term.</p>
                </div>
              )}
            </>
          );
        }}
      </Combobox>
      {footer}
    </>
  );
}
