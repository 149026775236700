import { useState } from 'react';
import { useMutation } from 'react-query';

import { Input } from '@/components/Form';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Post } from '@/interfaces/post';
import api from '@/services/swarm';

interface Props {
  postScheduledAt: Post['scheduled_at'];
  postId: Post['id'];
  slug: string;
}

interface ApiSuccessResponse {
  slug: string;
}

const SlugInput = ({ postId, postScheduledAt, slug: givenSlug }: Props) => {
  const [slug, setSlug] = useState(givenSlug);
  const [publicationId] = useCurrentPublicationState();
  const [error, setError] = useState('');

  const updateSlugMutation = useMutation<ApiSuccessResponse>(
    () => {
      return api
        .patch(`/posts/${postId}/slug`, {
          post: { slug },
          publication_id: publicationId,
        })
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        setSlug(data.slug);
      },
      onError: (err: any) => {
        setError(err?.response.data.error || "Couldn't update slug");
      },
    }
  );

  const handleFormat = () =>
    updateSlugMutation.mutateAsync().then((res) => {
      if (slug !== res.slug) {
        setSlug(res.slug);
      }
    });

  const onBlur = () => {
    handleFormat();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setSlug(e.target.value);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} className="space-y-2">
      <Input
        labelText="Slug"
        onChange={handleChange}
        name="slug"
        required
        value={slug}
        errorText={error}
        onBlur={onBlur}
      />
      <div className="text-xs text-gray-500">
        <b>Post will appear at:</b> <code>/p/{slug}</code>
      </div>
      {postScheduledAt && (
        <div className="text-xs text-yellow-700">
          Changing the slug after a post is published will invalidate the previous URL.
        </div>
      )}
    </form>
  );
};

export default SlugInput;
