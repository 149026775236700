import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';
import HelperText from '@/ui/HelperText';

type Props = PropsWithChildren<{
  label: string | ReactNode;
  helperText?: string;
  tip?: string;
  cta?: ReactNode;
  className?: string;
}>;

const FormRow = ({ label, helperText, tip, children, cta, className }: Props) => (
  <div className={classNames('flex flex-row gap-x-2 justify-between', className)}>
    <div className="flex flex-col gap-y-3">
      <TypographyStack>
        {typeof label === 'string' ? <Typography token="font-medium/text/sm">{label}</Typography> : label}
        {helperText ? <HelperText className="sm:w-96">{helperText}</HelperText> : null}
        {tip ? <Typography token="font-light/text/xs">{tip}</Typography> : null}
      </TypographyStack>
      {cta ? <span>{cta}</span> : null}
    </div>
    {children}
  </div>
);

export default FormRow;
