import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { LandingPage } from '@/interfaces/landing_page';

import { useCurrentPublication } from '../../../hooks';
import { useLandingPage, usePublishLandingPage } from '../../../hooks/useLandingPages';
import { useRequirePermission } from '../../../hooks/useRequirePermission';
import { Button } from '../../../ui/Button';
import Navigator from '../components/Navigator';
import { PageProvider } from '../components/PageContext';
import PageIframe from '../components/PageIframe';
import SavingIndicatorHelper from '../components/SavingIndicatorHelper';
import ScreenSizeHelper from '../components/ScreenSizeHelper';
import ViewSiteLink from '../components/ViewSiteLink';

import FAQSection from './FAQSection';
import FeaturedPostsSection from './FeaturedPostsSection';
import MainSection from './MainSection';
import TestimonialsSection from './TestimonialsSection';

const LandingPageView = () => {
  useRequirePermission({
    resource: 'web_theme',
    permission: 'update',
    message: 'You do not have access to the Design Lab',
    redirectTo: '/',
  });

  const navigate = useNavigate();

  const { data: currentPublication } = useCurrentPublication();
  const landingPageQuery = useLandingPage({ slug: '/subscribe', status: 'draft' });
  const { data: landingPage } = landingPageQuery;
  const iframeUrl = `${currentPublication?.url}subscribe?version=draft`;

  const publishLandingPage = usePublishLandingPage({
    pageId: landingPage?.id || '',
    onSuccess: () => {
      toast.success('Published!');
    },
  });

  return (
    <PageProvider<LandingPage>
      bgColor="#ffffff"
      pageData={landingPage}
      actionChildren={
        <div className="flex items-center justify-between w-full">
          <div className="w-full flex justify-between items-center mr-4">
            <Navigator />
            <SavingIndicatorHelper />
          </div>

          <div className={cx('flex justify-center')}>
            <div className="w-20">
              <ScreenSizeHelper />
            </div>
          </div>

          <div className={cx('w-full flex between')}>
            <ViewSiteLink url={`${currentPublication?.url}subscribe`} />

            <div className="w-full flex justify-end">
              <div className="flex items-center space-x-2 pt-0.5">
                <Button variant="primary-inverse" type="button" onClick={() => navigate('/settings/website/pages')}>
                  Exit
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  Icon={CursorArrowRaysIcon}
                  disabled={publishLandingPage.isLoading}
                  loading={publishLandingPage.isLoading}
                  onClick={() => {
                    publishLandingPage.mutate();
                  }}
                >
                  {publishLandingPage.isLoading ? 'Publishing...' : 'Publish to site'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
      sidePanelChildren={
        <div>
          <MainSection />
          <TestimonialsSection />
          <FeaturedPostsSection />
          <FAQSection />
        </div>
      }
    >
      <PageIframe title="Landing Page" url={iframeUrl} />
    </PageProvider>
  );
};

export default LandingPageView;
