import { Segment, SegmentType } from '../../interfaces/segment';
import { Badge } from '../../ui/Badge';

const SegmentBadge = ({
  segment,
  className,
  hideSubscriberCount,
}: {
  segment: Segment;
  className?: string;
  hideSubscriberCount?: boolean;
}) => {
  // Treat a locked segment as if it is processing
  const status = segment.is_locked ? 'processing' : segment.status;

  const type = {
    completed: 'success',
    pending: 'warning',
    processing: 'warning',
    failed: 'alert',
  }[status] as 'success' | 'alert' | 'warning';

  const completedText = hideSubscriberCount ? 'completed' : `${segment.num_members} subscribers`;
  const badgeText = {
    completed: completedText,
    pending: 'processing',
    processing: 'processing',
    failed: segment.segment_type === SegmentType.MANUAL ? `failed - ${segment.failed_reason}` : 'failed',
  }[status];

  return (
    <Badge className={className} type={type}>
      {badgeText}
    </Badge>
  );
};

export default SegmentBadge;
