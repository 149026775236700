import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AdNetworkReport } from '@/interfaces/ad_network/publisher/report';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  reports: AdNetworkReport[];
  pagination: Pagination;
}

export default function useReports() {
  const [publicationId] = useCurrentPublicationState();

  const fetchReports = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/publisher/reports`, {
        params: {
          page: pageParam,
          per_page: 10,
          publication_id: publicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([publicationId, 'ad_network', 'reports'], fetchReports, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
