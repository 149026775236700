// this file is taken from @tiptap-pro/extension-drag-handle to modify it for our needs, mainly how cursor is handled
/* eslint-disable */
import { Extension as e } from '@tiptap/core';
import { isChangeOrigin as d } from '@tiptap/extension-collaboration';
import { Plugin as n, PluginKey as t } from '@tiptap/pm/state';
import { getSelectionRanges as l, NodeRangeSelection as a } from '@tiptap-pro/extension-node-range';
import o from 'tippy.js';
import {
  absolutePositionToRelativePosition as i,
  relativePositionToAbsolutePosition as s,
  ySyncPluginKey as r,
} from 'y-prosemirror';

function p(e) {
  const t = e.cloneNode(!0);
  const n = [e, ...Array.from(e.getElementsByTagName('*'))];
  const o = [t, ...Array.from(t.getElementsByTagName('*'))];
  return (
    n.forEach((e, t) => {
      o[t].style.cssText = (function (e) {
        let t = '';
        const n = getComputedStyle(e);
        for (let e = 0; e < n.length; e += 1) t += `${n[e]}:${n.getPropertyValue(n[e])};`;
        return t;
      })(e);
    }),
    t
  );
}
function c(e, t) {
  return window.getComputedStyle(e)[t];
}
function u(e = 0, t = 0, n = 0) {
  return Math.min(Math.max(e, t), n);
}
function m(e) {
  let t;
  (t = e.parentNode) === null || void 0 === t || t.removeChild(e);
}
const g = (e) => {
  const { x: t, y: n, direction: o, editor: r } = e;
  let i = null;
  let s = null;
  let d = null;
  let l = t;
  for (; s === null && l < window.innerWidth && l > 0; ) {
    const e = document.elementsFromPoint(l, n);
    const t = e.findIndex((e) => e.classList.contains('ProseMirror'));
    const a = e.slice(0, t);
    if (a.length > 0) {
      const e = a[0];
      if (((i = e), (d = r.view.posAtDOM(e, 0)), d >= 0)) {
        (s = r.state.doc.nodeAt(Math.max(d - 1, 0))),
          (s == null ? void 0 : s.isText) && (s = r.state.doc.nodeAt(Math.max(d - 1, 0))),
          s || (s = r.state.doc.nodeAt(Math.max(d, 0)));
        break;
      }
    }
    o === 'left' ? (l -= 1) : (l += 1);
  }
  return { resultElement: i, resultNode: s, pos: d != null ? d : null };
};
function f(e, t) {
  const { doc: n } = t.view.state;
  const o = g({ editor: t, x: e.clientX, y: e.clientY, direction: 'right' });
  if (!o.resultNode || o.pos === null) return [];
  const r = e.clientX;
  const i = (function (e, t, n) {
    const o = parseInt(c(e.dom, 'paddingLeft'), 10);
    const r = parseInt(c(e.dom, 'paddingRight'), 10);
    const i = parseInt(c(e.dom, 'borderLeftWidth'), 10);
    const s = parseInt(c(e.dom, 'borderLeftWidth'), 10);
    const d = e.dom.getBoundingClientRect();
    return { left: u(t, d.left + o + i, d.right - r - s), top: n };
  })(t.view, r, e.clientY);
  const s = t.view.posAtCoords(i);
  if (!s) return [];
  const { pos: d } = s;
  if (!n.resolve(d).parent) return [];
  const a = n.resolve(o.pos);
  const p = n.resolve(o.pos + 1);
  return l(a, p, 0);
}
const h = (e, t) => {
  const n = e.resolve(t);
  const { depth: o } = n;
  if (o === 0) return t;
  return n.pos - n.parentOffset - 1;
};
const y = (e, t) => {
  const n = e.nodeAt(t);
  const o = e.resolve(t);
  let { depth: r } = o;
  let i = n;
  for (; r > 0; ) {
    const e = o.node(r);
    (r -= 1), r === 0 && (i = e);
  }
  return i;
};
const v = (e, t) => {
  const n = r.getState(e);
  return n ? i(t, n.type, n.binding.mapping) : null;
};
const C = (e, t) => {
  let n = t;
  for (; n && n.parentNode && n.parentNode !== e.dom; ) n = n.parentNode;
  return n;
};
const E = new t('dragHandle');
const k = ({ pluginKey: e = E, element: i, editor: c, tippyOptions: u, onNodeChange: k }) => {
  const M = document.createElement('div');
  let w;
  let D = null;
  let x = !1;
  let O = null;
  let N = -1;

  const mouseMoveHandler = (event) => {
    const view = c.view;

    if (!i || !D || x) return !1;

    const editorRect = c?.options?.element?.getBoundingClientRect();
    let editorClientX;

    if (editorRect) {
      const [editorLeft, editorRight] = [editorRect.left - 100, editorRect.right + 100];

      if (event.clientX >= editorLeft && event.clientX <= editorRight) {
        editorClientX = editorRect.x + 10;
      } else {
        return D.hide();
      }
    }

    const n = g({ x: editorClientX || event.clientX, y: event.clientY, direction: 'right', editor: c });
    let o = n.resultElement;
    if (!o) return !1;
    if (((o = C(view, o)), o === view.dom)) return !1;
    if ((o == null ? void 0 : o.nodeType) !== 1) return !1;
    const r = view.posAtDOM(o, 0);
    const s = y(c.state.doc, r);
    if (s !== O) {
      const t = h(c.state.doc, r);
      (O = s),
        (N = t),
        (w = v(view.state, N)),
        k == null || k({ editor: c, node: O, pos: N }),
        D.setProps({ getReferenceClientRect: () => o.getBoundingClientRect() }),
        D.show();
    }
    return !1;
  };

  document.addEventListener('mousemove', mouseMoveHandler);

  c.on('destroy', () => {
    document.removeEventListener('mousemove', mouseMoveHandler);
  });

  return (
    i.addEventListener('dragstart', (e) => {
      !(function (e, t) {
        const { view: n } = t;
        if (!e.dataTransfer) return;
        const { empty: o, $from: r, $to: i } = n.state.selection;
        const s = f(e, t);
        const d = l(r, i, 0);
        const c = d.some((e) => s.find((t) => t.$from === e.$from && t.$to === e.$to));
        const u = o || !c ? s : d;
        if (!u.length) return;
        const { tr: g } = n.state;
        const h = document.createElement('div');
        const y = u[0].$from.pos;
        const v = u[u.length - 1].$to.pos;
        const C = a.create(n.state.doc, y, v);
        const E = C.content();
        u.forEach((e) => {
          const t = p(n.nodeDOM(e.$from.pos));
          h.append(t);
        }),
          (h.style.position = 'absolute'),
          (h.style.top = '-10000px'),
          document.body.append(h),
          e.dataTransfer.clearData(),
          e.dataTransfer.setDragImage(h, 0, 0),
          (n.dragging = { slice: E, move: !0 }),
          g.setSelection(C),
          n.dispatch(g),
          document.addEventListener('drop', () => m(h), { once: !0 });
      })(e, c),
        setTimeout(() => {
          i && (i.style.pointerEvents = 'none');
        }, 0);
    }),
    i.addEventListener('dragend', () => {
      i && (i.style.pointerEvents = 'auto');
    }),
    new n({
      key: typeof e === 'string' ? new t(e) : e,
      state: {
        init: () => ({ locked: !1 }),
        apply(e, t, n, o) {
          const l = e.getMeta('lockDragHandle');
          const a = e.getMeta('hideDragHandle');
          if ((void 0 !== l && (x = l), a && D))
            return D.hide(), (x = !1), (O = null), (N = -1), k == null || k({ editor: c, node: null, pos: -1 }), t;
          if (e.docChanged && N !== -1 && i && D)
            if (d(e)) {
              const e = ((e, t) => {
                const n = r.getState(e);
                return n ? s(n.doc, n.type, t, n.binding.mapping) || 0 : -1;
              })(o, w);
              e !== N && (N = e);
            } else {
              const t = e.mapping.map(N);
              t !== N && ((N = t), (w = v(o, N)));
            }
          return t;
        },
      },
      view: (e) => {
        let t;
        return (
          (i.draggable = !0),
          (i.style.pointerEvents = 'auto'),
          (t = c.view.dom.parentElement) === null || void 0 === t || t.appendChild(M),
          M.appendChild(i),
          (M.style.pointerEvents = 'none'),
          (M.style.position = 'absolute'),
          (M.style.top = '0'),
          (M.style.left = '0'),
          (D = o(e.dom, {
            getReferenceClientRect: null,
            interactive: !0,
            trigger: 'manual',
            placement: 'left-start',
            hideOnClick: !1,
            duration: 100,
            popperOptions: {
              modifiers: [
                { name: 'flip', enabled: !1 },
                { name: 'preventOverflow', options: { rootBoundary: 'document', mainAxis: !1 } },
              ],
            },
            ...u,
            appendTo: M,
            content: i,
          })),
          {
            update(t, n) {
              if (!i || !D) return;
              if (((i.draggable = !x), e.state.doc.eq(n.doc) || N === -1)) return;
              let o = e.nodeDOM(N);
              if (((o = C(e, o)), o === e.dom)) return;
              if ((o == null ? void 0 : o.nodeType) !== 1) return;
              const r = e.posAtDOM(o, 0);
              const s = y(c.state.doc, r);
              const d = h(c.state.doc, r);
              (O = s),
                (N = d),
                (w = v(e.state, N)),
                k == null || k({ editor: c, node: O, pos: N }),
                D.setProps({ getReferenceClientRect: () => o.getBoundingClientRect() });
            },
            destroy() {
              D == null || D.destroy(), i && m(M);
            },
          }
        );
      },
      props: {
        handleDOMEvents: {
          mouseleave: (e, t) => (
            x ||
              (t.target &&
                !M.contains(t.relatedTarget) &&
                (D == null || D.hide(), (O = null), (N = -1), k == null || k({ editor: c, node: null, pos: -1 }))),
            !1
          ),
        },
      },
    })
  );
};
const M = e.create({
  name: 'dragHandle',
  addOptions: () => ({
    render() {
      const e = document.createElement('div');
      return e.classList.add('drag-handle'), e;
    },
    tippyOptions: {},
    locked: !1,
    onNodeChange: () => null,
  }),
  addCommands() {
    return {
      lockDragHandle:
        () =>
        ({ editor: e }) => ((this.options.locked = !0), e.commands.setMeta('lockDragHandle', this.options.locked)),
      unlockDragHandle:
        () =>
        ({ editor: e }) => ((this.options.locked = !1), e.commands.setMeta('lockDragHandle', this.options.locked)),
      toggleDragHandle:
        () =>
        ({ editor: e }) => (
          (this.options.locked = !this.options.locked), e.commands.setMeta('lockDragHandle', this.options.locked)
        ),
    };
  },
  addProseMirrorPlugins() {
    const e = this.options.render();
    return [
      k({
        tippyOptions: this.options.tippyOptions,
        element: e,
        editor: this.editor,
        onNodeChange: this.options.onNodeChange,
      }),
    ];
  },
});
export { M as default, M as DragHandle, k as DragHandlePlugin, E as dragHandlePluginDefaultKey };
