import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

const useDeletePausePlan = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => api.delete(`/organizations/${organizationId}/pause_plan`), {
    onError: (err: any) => {
      toast.error(err?.response?.data?.error || 'Oops there was an issue unpausing your plan');
    },
    onSettled: async () => {
      return queryClient.invalidateQueries(['pause_plan', organizationId]);
    },
  });
};

export default useDeletePausePlan;
