import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import PaymentAccounts from '@/pages/Settings/Pages/PaymentAccounts';

import { usePublicationSettings } from '../context';

const PublicationPaymentAccountsSettings: React.FC = () => {
  const { currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);
  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? <PaymentAccounts isV2 key={publication.id} /> : null}
    </LoadingBox>
  );
};

export default PublicationPaymentAccountsSettings;
