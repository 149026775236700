import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import { OpensByHourChart } from '@/components/charts';

import { useSettings } from '../../../../context/settings-context';
import useSendEmailConfirmation from '../../../../hooks/useSendEmailConfirmation';
import { PostNodeContent, PostScheduleOptions } from '../../../../interfaces/post';

import PrePublishStats from './PrePublishStats';
import PrePublishWarnings from './PrePublishWarnings';

interface Step {
  headerText: string;
  actionText: string;
  onCancel: () => void;
  onProceed: (id: string) => void;
  cancelText?: string;
  hideCancelButton?: boolean;
  content: JSX.Element;
  isWorking: boolean;
  isDisabled?: boolean;
}
interface Props {
  selectedScheduleOption: PostScheduleOptions;
  setSelectedScheduleOption: (option: PostScheduleOptions) => void;
  selectedTime: string | null;
  setSelectedTime: (time: string | null) => void;
  isSelectedTimeInvalid: boolean;
  setIsSelectedTimeInvalid: (isInvalid: boolean) => void;
  isOpen: boolean;
  isWorking: boolean;
  onClose: () => void;
  postData: any;
  refetchPostNodeContent: () => void;
  postNodeContent: PostNodeContent;
  isEmailConfirmed: boolean;
  onConfirm: () => Promise<any>;
}

const ScheduleStep = ({
  postNodeContent,
  selectedScheduleOption,
  setSelectedScheduleOption,
  selectedTime,
  setSelectedTime,
  isSelectedTimeInvalid,
}: {
  postNodeContent: Props['postNodeContent'];
  selectedScheduleOption: Props['selectedScheduleOption'];
  setSelectedScheduleOption: Props['setSelectedScheduleOption'];
  selectedTime: Props['selectedTime'];
  setSelectedTime: Props['setSelectedTime'];
  isSelectedTimeInvalid: Props['isSelectedTimeInvalid'];
}) => {
  const tzAbbr = Intl.DateTimeFormat('default', { timeZoneName: 'long' })
    ?.formatToParts(new Date())
    .find((part) => part.type === 'timeZoneName')?.value;

  const onClickRecommended = (time: string) => {
    toast.success(`Date set to ${moment(time).calendar()}`);

    setSelectedTime(time);
  };
  const minBoostSendBy = postNodeContent?.boost?.map((boost) => boost?.send_by).sort()[0];

  const { settings } = useSettings();

  const options = [
    {
      id: PostScheduleOptions.SCHEDULE,
      title: 'Schedule',
      description: 'Schedule for a specific date and time in the future.',
      recommended: true,
    },
    {
      id: PostScheduleOptions.PUBLISH_NOW,
      title: 'Publish Now',
      description: 'Publish your post immediately.',
      recommended: false,
    },
  ];

  const handleSelectOption = (id: PostScheduleOptions) => {
    if (id === PostScheduleOptions.PUBLISH_NOW) {
      setSelectedTime(null);
    }

    setSelectedScheduleOption(id);
  };

  return (
    <div className="space-y-4">
      {settings?.recommended_sending_time && <OpensByHourChart onClickRecommended={onClickRecommended} />}

      <div className="">
        <div className="w-full">
          <RadioGroup value={selectedScheduleOption} onChange={handleSelectOption}>
            <RadioGroup.Label className="text-base font-medium text-gray-900">
              Choose a publishing option
            </RadioGroup.Label>

            <div className="mt-4 grid gap-6 grid-cols-2">
              {options.map((option) => {
                return (
                  <RadioGroup.Option
                    key={option.id}
                    value={option.id}
                    className={({ checked, active }) => {
                      return cx(
                        checked ? 'border-transparent' : 'border-gray-300',
                        active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                        'relative flex cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none duration-200'
                      );
                    }}
                  >
                    {({ checked, active }) => (
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                              {option.title}{' '}
                              {option.recommended && (
                                <div className="inline bg-gray-200 py-0.5 px-2 rounded-full">
                                  <span className="text-gray-600 text-xs">Recommended</span>
                                </div>
                              )}
                            </RadioGroup.Label>
                            <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                              {option.description}
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <CheckCircleIcon
                          className={cx(
                            !checked ? 'opacity-0' : 'opacity-100',
                            'h-5 w-5 text-primary-600 duration-200'
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={cx(
                            active ? 'border' : 'border-2',
                            checked ? 'border-primary-500' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                );
              })}
            </div>
          </RadioGroup>
          {selectedScheduleOption === PostScheduleOptions.SCHEDULE && (
            <div className="py-6">
              <label htmlFor="scheduled_at">
                <span
                  className={cx('block text-sm font-medium text-gray-900', { 'text-red-500': isSelectedTimeInvalid })}
                >
                  Publish Date
                </span>
                <input
                  id="scheduled_at"
                  type="datetime-local"
                  className={cx('rounded border-gray-200 text-gray-600 w-full mt-1', {
                    'border-red-500': isSelectedTimeInvalid,
                  })}
                  min={moment().format('YYYY-MM-DDThh:mm')}
                  max={minBoostSendBy ? moment(minBoostSendBy).format('YYYY-MM-DDThh:mm') : ''}
                  value={selectedTime || ''}
                  placeholder=""
                  onChange={(e) => {
                    setSelectedTime(e.target.value);
                  }}
                  required
                />
              </label>
              <p className="text-xs text-gray-500 text-right">{tzAbbr ? <>{tzAbbr}</> : <>&nbsp;</>}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ConfirmModal = ({
  postData,
  postNodeContent,
  refetchPostNodeContent,
  isOpen,
  isWorking,
  onClose,
  isEmailConfirmed,
  selectedScheduleOption,
  setSelectedScheduleOption,
  selectedTime,
  setSelectedTime,
  isSelectedTimeInvalid,
  setIsSelectedTimeInvalid,
  onConfirm,
}: Props) => {
  const [step, setStep] = useState(0);
  const { mutateAsync: resendConfirmation, isLoading: isResendingConfirmation } = useSendEmailConfirmation();

  const handleClose = () => {
    onClose();

    setTimeout(() => {
      setStep(0);
    }, 500);
  };

  const parsedSelectedTime = moment(selectedTime || undefined);
  const validSelectedTime = parsedSelectedTime.isValid() && parsedSelectedTime.isAfter(moment());
  const formattedSelectedTime = validSelectedTime ? parsedSelectedTime.format('LLL') : null;
  const publishNow = selectedScheduleOption === PostScheduleOptions.PUBLISH_NOW;

  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState(false);
  const embeddedBoosts = postNodeContent?.boost || [];
  const uniqueEmbeddedBoosts = embeddedBoosts?.reduce((acc, boost) => {
    if (!acc.includes(boost?.id)) {
      acc.push(boost?.id);
    }

    return acc;
  }, [] as string[]);
  const maxEmbeddedBoosts = 3;
  const overMaxEmbeddedBoosts = (uniqueEmbeddedBoosts?.length || 0) > maxEmbeddedBoosts;
  const adOpportunityInUse = postNodeContent?.advertisement_opportunity?.some((ad) => ad?.in_use);

  const hasErrors = overMaxEmbeddedBoosts || adOpportunityInUse;

  useEffect(() => {
    if (hasErrors) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  }, [hasErrors]);

  const handleChangeStep = (newStep: number) => {
    // Refetching here is a safeguard against content changes that may have taken
    // longer to save than the user waited before clicking the Schedule button
    refetchPostNodeContent();
    if (newStep === 1) {
      setIsDisabled(true);

      setTimeout(() => setIsDisabled(false), 500); // prevent double clicks
    }

    setStep(newStep);
  };

  const handleReview = () => {
    if (selectedScheduleOption === PostScheduleOptions.SCHEDULE && !validSelectedTime) {
      toast.error('Please specify the full date and time for publishing.', { duration: 8000 });
      setIsSelectedTimeInvalid(true);
      return;
    }

    setIsSelectedTimeInvalid(false);
    handleChangeStep(1);
  };

  const steps: Step[] = [
    {
      headerText: 'Nice work! Let’s schedule your post.',
      actionText: 'Review',
      onProceed: handleReview,
      onCancel: handleClose,
      cancelText: 'Cancel',
      content: (
        <ScheduleStep
          selectedScheduleOption={selectedScheduleOption}
          setSelectedScheduleOption={setSelectedScheduleOption}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          isSelectedTimeInvalid={isSelectedTimeInvalid}
          postNodeContent={postNodeContent}
        />
      ),
      isWorking,
    },
    {
      headerText: 'Review and publish',
      actionText: publishNow ? 'Publish now' : `Publish on ${formattedSelectedTime}`,
      onCancel: () => handleChangeStep(0),
      onProceed: () => {
        setIsDisabled(true);
        onConfirm().finally(() => setIsDisabled(false));
      },
      cancelText: 'Go back',
      content: (
        <div className="space-y-4">
          <p className="text-sm">Double check that everything looks good below:</p>
          <PrePublishStats
            selectedScheduleOption={selectedScheduleOption}
            formattedSelectedTime={formattedSelectedTime}
            post={postData}
            postNodeContent={postNodeContent}
          />
          <PrePublishWarnings post={postData} publishNow={publishNow} />
        </div>
      ),
      isDisabled,
      isWorking,
    },
  ];

  let currentStep = steps[step];

  if (errors) {
    currentStep = {
      headerText: 'Uh oh! Looks like there is an issue with your post.',
      actionText: 'Go back',
      onProceed: handleClose,
      onCancel: handleClose,
      hideCancelButton: true,
      content: (
        <div className="text-sm font-medium text-gray-700 my-8">
          {overMaxEmbeddedBoosts && (
            <div>
              <p>
                You have <span className="text-primary-500 font-bold">{postNodeContent?.boost?.length}</span> boosts
                embedded in your post.
              </p>
              <p>
                You can only have <span className="text-primary-500 font-bold">{maxEmbeddedBoosts}</span> embedded
                boosts.
              </p>
              <p className="mt-4">Please go back and edit your post to remove the extra boosts.</p>
            </div>
          )}
          {adOpportunityInUse && (
            <div>
              <p>You have included an advertisement opportunity that was scheduled in another post.</p>
              <p>You can only send an advertisement opportunity once.</p>
              <p className="mt-4">Please go back and edit your post to remove the used advertisement opportunity.</p>
            </div>
          )}
        </div>
      ),
      isWorking,
    };
  }

  if (!isEmailConfirmed) {
    currentStep = {
      headerText: 'Confirm your email',
      actionText: 'Resend',
      onProceed: () => resendConfirmation().finally(() => handleClose()),
      onCancel: () => onClose(),
      cancelText: 'Cancel',
      isWorking: isResendingConfirmation,
      content: (
        <p>
          To continue publishing your post, please confirm your email address. Check your inbox for a confirmation
          email, or click the button below to resend.
        </p>
      ),
    };
  }

  return (
    <ActionModal
      isOpen={isOpen}
      isWorking={currentStep.isWorking}
      disabled={currentStep.isDisabled || currentStep.isWorking}
      onProceed={currentStep.onProceed}
      headerText={currentStep.headerText}
      actionText={currentStep.actionText}
      cancelText={currentStep.cancelText}
      hideCancelButton={currentStep?.hideCancelButton}
      resourceId={postData.id}
      onClose={currentStep.onCancel}
      modalSize="lg"
      overflow="visible"
    >
      {currentStep.content}
    </ActionModal>
  );
};

export default ConfirmModal;
