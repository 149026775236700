import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const Styled = {
  SelectionContainer: styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem;
  `,
  Headline: styled.h3`
    &&& {
      margin: 0 0 0.5rem;
    }
  `,
  Paragraph: styled.div`
    margin-bottom: 1rem;
  `,
  List: styled.ul<{
    $orientation?: 'vertical' | 'horizontal';
    $listStyle?: 'disc' | 'decimal' | 'checkbox' | 'none';
  }>`
    display: ${(p) => (p.$orientation === 'vertical' ? 'grid' : 'flex')};
    grid-template-columns: ${(p) => (p.$orientation === 'vertical' ? 'auto' : 'auto auto auto')};
    list-style: none;

    &&& {
      margin: 1.5rem 0 0 0;
      padding: 0;
    }

    li {
      counter-increment: counter;
      display: flex;
      padding-right: 0.5rem;
      position: relative;

      span {
        color: ${colors.blue[5]};
        text-decoration: underline;
      }

      &&& {
        margin: 0;
        padding: 0;
      }

      ${(p) =>
        p.$listStyle === 'disc' &&
        p.$orientation === 'horizontal' &&
        `
          border-left: 1px solid currentColor;
          padding-left: calc(0.5rem - 1px);

          &:first-child {
            border-left: 0;
            padding-left: 0;
          }
      `}

      &::before {
        font-size: 1rem;

        ${(p) =>
          p.$listStyle === 'decimal' &&
          `
            content: counter(counter) '.';
            display: inline-block;
            font-weight: 700;
            min-width: 1.2rem;
          `}

        ${(p) =>
          p.$listStyle === 'disc' &&
          p.$orientation === 'vertical' &&
          `
            content: '\\00a0\\2022\\00a0\\00a0';
        `}

        ${(p) =>
          p.$listStyle === 'checkbox' &&
          `
            content: '';
            border: 1px solid ${colors.black[6]};
            border-radius: 100%;
            margin-right: 0.5rem;
            height: 1.4rem;
            width: 1.4rem;
          `}
      }
    }
  `,
  ListItem: styled.li``,
};

export default Styled;
