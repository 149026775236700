import { ReferralQuality } from '@/interfaces/boosts/grow/application_metrics';
import { LevelIndicator } from '@/ui/LevelIndicator';
import { Props as LevelIndicatorProps } from '@/ui/LevelIndicator/LevelIndicator';

interface Props {
  referralQuality: ReferralQuality;
  alignLabel?: LevelIndicatorProps['alignLabel'];
}

const LEVEL_BY_QUALITY: Record<'low' | 'poor' | 'average' | 'good' | 'excellent', number> = {
  poor: 1,
  low: 2,
  average: 3,
  good: 4,
  excellent: 5,
};

const BoostApplicationLevelIndicator = ({ referralQuality, alignLabel }: Props) => {
  const qualityLevel = referralQuality ? LEVEL_BY_QUALITY[referralQuality] : 0;
  const qualityLabel = referralQuality ? `${referralQuality} quality` : 'quality not available';

  return <LevelIndicator level={qualityLevel} label={qualityLabel} alignLabel={alignLabel} />;
};

export default BoostApplicationLevelIndicator;
