import { Link } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';
import cx from 'classnames';

import { badgeAttributesByType } from '@/components/_domain/Automations/utils';
import { Typography } from '@/components/Typography';
import { ReferringAutomationData } from '@/hooks/useAutomations/useReferringAutomations';
import { AutomationState } from '@/interfaces/automations/automation';
import { AutomationStepState } from '@/interfaces/automations/automation_step';
import { Badge } from '@/ui/Badge';

import {
  AUTOMATION_NODE_WIDTH_CLASSNAME,
  REFERRING_AUTOMATIONS_COLORS,
  REFERRING_AUTOMATIONS_LABEL,
} from '../../constants';
import { useNewAutoLayoutContext } from '../../context/new-layout-context';
import { CustomNodeDataType } from '../../types';
import { REFERRING_AUTOMATIONS_NODE_ID, REFERRING_AUTOMATIONS_NODE_TYPE } from '../../utils/getNodesAndEdges';
import ContainerNode from '../ContainerNode';
import ContainerNodeBody from '../ContainerNodeBody';

const ReferringAutomationsNode = ({ sourcePosition }: NodeProps<CustomNodeDataType>) => {
  const { referringAutomations } = useNewAutoLayoutContext();
  const isInActive = referringAutomations.every(
    (data) =>
      data.automation_state === AutomationState.INACTIVE || data.automation_step_state === AutomationStepState.DRAFT
  );

  const handleBorderColor = REFERRING_AUTOMATIONS_COLORS.border;

  return (
    <div className={cx('flex flex-col items-center rounded-md', AUTOMATION_NODE_WIDTH_CLASSNAME)}>
      <ContainerNode
        uniqueIdentifier={REFERRING_AUTOMATIONS_NODE_ID}
        isBeingConfigured={false}
        isActive={!isInActive}
        shouldDisable={false}
        stepType={REFERRING_AUTOMATIONS_NODE_TYPE}
        title={REFERRING_AUTOMATIONS_LABEL}
      >
        <ContainerNodeBody className="space-y-2">
          <ul>
            {referringAutomations.map((data: ReferringAutomationData) => {
              const isReferringAutomationActive =
                data.automation_state === AutomationState.RUNNING &&
                data.automation_step_state === AutomationStepState.PUBLISHED;

              return (
                <li
                  className="border-b border-gray-200 py-2 first-of-type:pt-0 last-of-type:border-0 last-of-type:pb-0"
                  key={`referring-automation-${data.automation_id}`}
                >
                  <Link
                    to={`/automations/${data.automation_id}/workflow/steps/${data.automation_step_id}/configure`}
                    target="_blank"
                    className={cx('flex space-x-3 group', !isReferringAutomationActive && 'opacity-50')}
                  >
                    <div className="w-20">
                      <Badge
                        className="capitalize"
                        type={badgeAttributesByType[data.automation_state].type}
                        leadingIcon={badgeAttributesByType[data.automation_state].icon}
                      >
                        {data.automation_state}
                      </Badge>
                    </div>
                    <div className="flex-1 -mt-[1px]">
                      <Typography
                        size="xs"
                        weight="medium"
                        className="text-gray-900 group-hover:text-gray-500 transition-colors"
                      >
                        {data.automation_name}
                      </Typography>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </ContainerNodeBody>
      </ContainerNode>
      <Handle
        className={cx('w-3.5 h-3.5 z-10 bg-gray-50 border-4', handleBorderColor)}
        style={{
          boxShadow: '0px 0px 0px 3px #F3F4F6',
        }}
        type="source"
        position={sourcePosition || Position.Bottom}
      />
    </div>
  );
};

export default ReferringAutomationsNode;
