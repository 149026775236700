import { InformationCircleIcon } from '@heroicons/react/24/solid';

import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';

interface Props {
  onDismiss: () => void;
}

const ProTip: React.FC<Props> = ({ onDismiss }) => {
  return (
    <div className="bg-feedback-info-50 rounded-md border border-feedback-info-200 p-4">
      <TypographyRow gap="3" align="start">
        <InformationCircleIcon className="w-5 h-5 text-feedback-info-500" />
        <TypographyStack>
          <Typography token="font-medium/text/sm" color="info" colorWeight="900">
            Pro tip - start with one tier
          </Typography>
          <Typography token="font-normal/text/sm" color="info" colorWeight="900">
            Consider launching your paid subscription with a single membership offering and building from there.
          </Typography>
          <TypographyRow gap="4">
            <a href="https://www.beehiiv.com" target="_blank" rel="noreferrer">
              <Typography className="underline" token="font-medium/text/sm" color="info" colorWeight="900">
                Learn More
              </Typography>
            </a>
            <button type="button" onClick={onDismiss}>
              <Typography className="underline" token="font-medium/text/sm" color="info" colorWeight="900">
                Dismiss
              </Typography>
            </button>
          </TypographyRow>
        </TypographyStack>
      </TypographyRow>
    </div>
  );
};

export default ProTip;
