import { FC } from 'react';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';

export const FootnotesNodeView: FC<NodeViewProps> = () => {
  return (
    <NodeViewWrapper className="pl-4 pt-2 border-t-2 border-t-gray-200">
      <NodeViewContent />
    </NodeViewWrapper>
  );
};
