import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import {
  DEFAULT_BACKGROUND_SECONDARY_COLOR,
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_SECONDARY_COLOR,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
  WHITE_COLOR,
} from '../constants';

import { PricingView } from './views/PricingView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    pricing: {
      setPricing: () => ReturnType;
    };
  }
}

export const Pricing = Node.create<{}, {}>({
  name: 'pricing',

  group: 'block',

  draggable: true,

  atom: true,

  defining: true,

  selectable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      hasFakeData: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-has-fake-data') === 'true',
        renderHTML: (attributes) => ({ 'data-has-fake-data': attributes.hasFakeData }),
      },
      insertedFromSidebar: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-inserted-from-sidebar') === 'true',
        renderHTML: (attributes) => ({ 'data-inserted-from-sidebar': attributes.insertedFromSidebar }),
      },
      gap: {
        default: '32px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      padding: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      borderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
      },
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
      },
      borderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
      },
      backgroundColor: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-card-background'),
        renderHTML: (attributes) => ({ 'data-card-background': attributes.cardBackground }),
      },
      cardShadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-card-shadow'),
        renderHTML: (attributes) => ({ 'data-card-shadow': attributes.cardShadow }),
      },
      cardBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-card-border-width'),
        renderHTML: (attributes) => ({ 'data-card-border-width': attributes.cardBorderWidth }),
      },
      cardBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-border-color'),
        renderHTML: (attributes) => ({ 'data-card-border-color': attributes.cardBorderColor }),
      },
      cardBorderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-card-border-style'),
        renderHTML: (attributes) => ({ 'data-card-border-style': attributes.cardBorderStyle }),
      },
      cardBorderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-card-border-radius'),
        renderHTML: (attributes) => ({ 'data-card-border-radius': attributes.cardBorderRadius }),
      },
      cardPadding: {
        default: '32px',
        parseHTML: (element) => element.getAttribute('data-card-padding'),
        renderHTML: (attributes) => ({ 'data-card-padding': attributes.cardPadding }),
      },
      cardSelectedBorderColor: {
        default: DEFAULT_BORDER_SECONDARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-selected-border-color'),
        renderHTML: (attributes) => ({ 'data-card-selected-border-color': attributes.cardSelectedBorderColor }),
      },
      cardSelectedBackgroundColor: {
        default: DEFAULT_BACKGROUND_SECONDARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-selected-background-color'),
        renderHTML: (attributes) => ({ 'data-card-selected-background-color': attributes.cardSelectedBackgroundColor }),
      },
      signupFlowId: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-signup-flow-id') || '',
        renderHTML: (attributes) => ({ 'data-signup-flow-id': attributes.signupFlowId }),
      },
      offerId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-offer-id'),
        renderHTML: (attributes) => ({ 'data-offer-id': attributes.offerId }),
      },
      recommendedTierId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-recommended-tier-id'),
        renderHTML: (attributes) => ({ 'data-recommended-tier-id': attributes.recommendedTierId }),
      },
      recommendedPriceId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-recommended-price-id'),
        renderHTML: (attributes) => ({ 'data-recommended-price-id': attributes.recommendedPriceId }),
      },
      internalCheckout: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-internal-checkout'),
        renderHTML: (attributes) => ({ 'data-internal-checkout': attributes.internalCheckout }),
      },
      internalCheckoutCta: {
        default: 'Upgrade',
        parseHTML: (element) => element.getAttribute('data-internal-checkout-cta'),
        renderHTML: (attributes) => ({ 'data-internal-checkout-cta': attributes.internalCheckoutCta }),
      },
      nameColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-name-color'),
        renderHTML: (attributes) => ({ 'data-name-color': attributes.nameColor }),
      },
      descriptionColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-description-color'),
        renderHTML: (attributes) => ({ 'data-description-color': attributes.descriptionColor }),
      },
      priceColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-price-color'),
        renderHTML: (attributes) => ({ 'data-price-color': attributes.priceColor }),
      },
      offerDetailsColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-offer-details-color'),
        renderHTML: (attributes) => ({ 'data-offer-details-color': attributes.offerDetailsColor }),
      },
      offerDetailsIconColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-offer-details-icon-color'),
        renderHTML: (attributes) => ({ 'data-offer-details-icon-color': attributes.offerDetailsIconColor }),
      },
      featureColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-feature-color'),
        renderHTML: (attributes) => ({ 'data-feature-color': attributes.featureColor }),
      },
      featureIconColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-feature-icon-color'),
        renderHTML: (attributes) => ({ 'data-feature-icon-color': attributes.featureIconColor }),
      },
      buttonBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-button-border-width'),
        renderHTML: (attributes) => ({ 'data-button-border-width': attributes.buttonBorderWidth }),
      },
      buttonBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-border-color'),
        renderHTML: (attributes) => ({ 'data-button-border-color': attributes.buttonBorderColor }),
      },
      buttonBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-button-border-style'),
        renderHTML: (attributes) => ({ 'data-button-border-style': attributes.buttonBorderStyle }),
      },
      buttonBorderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-button-border-radius'),
        renderHTML: (attributes) => ({ 'data-button-border-radius': attributes.buttonBorderRadius }),
      },
      buttonColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-color'),
        renderHTML: (attributes) => ({ 'data-button-color': attributes.buttonColor }),
      },
      buttonTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-text-color'),
        renderHTML: (attributes) => ({ 'data-button-text-color': attributes.buttonTextColor }),
      },
      tabBackgroundColor: {
        default: DEFAULT_BACKGROUND_SECONDARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-tab-background-color'),
        renderHTML: (attributes) => ({ 'data-tab-background-color': attributes.tabBackgroundColor }),
      },
      tabSelectedBackgroundColor: {
        default: WHITE_COLOR,
        parseHTML: (element) => element.getAttribute('data-tab-selected-background-color'),
        renderHTML: (attributes) => ({ 'data-tab-selected-background-color': attributes.tabSelectedBackgroundColor }),
      },
      tabTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-tab-text-color'),
        renderHTML: (attributes) => ({ 'data-tab-text-color': attributes.tabTextColor }),
      },
      tabSelectedTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-tab-selected-text-color'),
        renderHTML: (attributes) => ({ 'data-tab-selected-text-color': attributes.tabSelectedTextColor }),
      },
      recommendedBackgroundColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-recommended-background-color'),
        renderHTML: (attributes) => ({ 'data-recommended-background-color': attributes.recommendedBackgroundColor }),
      },
      recommendedTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-recommended-text-color'),
        renderHTML: (attributes) => ({ 'data-recommended-text-color': attributes.recommendedTextColor }),
      },
      inputBackgroundColor: {
        default: DEFAULT_BACKGROUND_SECONDARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-background-color'),
        renderHTML: (attributes) => ({ 'data-input-background-color': attributes.inputBackgroundColor }),
      },
      inputPlaceholderColor: {
        default: '#6B7280',
        parseHTML: (element) => element.getAttribute('data-input-placeholder-color') || '#6B7280',
        renderHTML: (attributes) => ({ 'data-input-placeholder-color': attributes.inputPlaceholderColor }),
      },
      inputTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-text-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-input-text-color': attributes.inputTextColor }),
      },
      data: {
        default: [],
        parseHTML: (element) => element.getAttribute('data-data'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
      fontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-font-family') || 'inherit',
        renderHTML: (attributes) => ({ 'data-font-family': attributes.fontFamily }),
      },
    };
  },

  addOptions() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(PricingView);
  },
});

export default Pricing;
