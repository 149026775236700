import { useQuery } from 'react-query';

import { PlanPrice } from '@/interfaces/plan_price';
import api from '@/services/swarm';

const usePlanPrice = ({ organizationId, publicationId, upcoming = false }: { organizationId: string; publicationId: string; upcoming?: boolean }) => {
  return useQuery<PlanPrice>(
    ['organization', organizationId, 'plan_price', `upcoming_${upcoming}`],
    () =>
      api
        .get(`/organizations/${organizationId}/plan_price`, {
          params: {
            publication_id: publicationId,
            upcoming,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!organizationId,
    }
  );
};

export default usePlanPrice;
