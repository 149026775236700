import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

import API from '@/components/TiptapEditor/lib/api';
import { Label } from '@/components/UI/Label';
import { Asset } from '@/interfaces/asset';

import { Button } from '../../../ui/Button';
import { createCanvasPreview } from '../utils/createCanvasPreview';
import { AlignmentInput } from '../views/CropEditor/AlignmentInput';
import { CropOption, CropRatioDropdown } from '../views/CropEditor/CropRatioDropdown';
import { OrientationInput } from '../views/CropEditor/OrientationInput';

export type CropModalContentProps = {
  assetId: string;
  fileName: string;
  publicationId: string;
  onClose: () => void;
  onChange: (asset: Asset) => void;
  onCreate: (asset: Asset) => void;
};

const useFormState = ({
  fileName,
  assetId,
  publicationId,
  onClose,
  onChange,
  onCreate,
}: {
  fileName: string;
  assetId: string;
  publicationId: string;
  onClose: () => void;
  onChange: (asset: Asset) => void;
  onCreate: (asset: Asset) => void;
}) => {
  const [asset, setAsset] = useState<Asset | null>(null);
  const [blob, setBlob] = useState<Blob | null>(null);
  const [assetFetched, setAssetFetched] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setAssetFetched(false);

    API.getAsset({ assetId, publicationId }).then((newAsset) => {
      if (!newAsset.data) {
        return;
      }

      setAsset(newAsset.data);
      setAssetFetched(true);
    });
  }, [assetId, publicationId]);

  const handleSave = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();

      setIsSaving(true);

      try {
        const file = new File(
          [blob as Blob],
          asset?.title
            ? `${asset?.title}_${publicationId}_${Date.now()}.png`
            : `cropped_${publicationId}_${Date.now()}.png`,
          {
            type: blob?.type,
          }
        );

        const response = await API.uploadPublicationAsset({ publicationId, file });
        onCreate(response.data);
        toast.success('Asset saved.');
      } catch {
        toast.error('Something went wrong while saving the asset.');
      } finally {
        setIsSaving(false);
        onClose();
      }
    },
    [onClose, asset, blob, onCreate, publicationId]
  );

  const handleOverwrite = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();

      setIsSaving(true);

      try {
        const file = new File([blob as Blob], fileName, {
          type: blob?.type,
        });

        const response = await API.updateAsset(assetId, publicationId, { file });
        onChange(response.data);
        toast.success('Asset overwritten.');
      } catch {
        toast.error('Something went wrong while overwriting the asset.');
      } finally {
        setIsSaving(false);
        onClose();
      }
    },
    [fileName, onClose, assetId, blob, onChange, publicationId]
  );

  return {
    blob,
    setBlob,
    asset,
    handleSave,
    handleOverwrite,
    isSaving,
    assetFetched,
  };
};

export const CropModalContent = ({
  fileName,
  onClose,
  onChange,
  onCreate,
  assetId,
  publicationId,
}: CropModalContentProps) => {
  const form = useFormState({ fileName, assetId, publicationId, onChange, onCreate, onClose });

  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const timeoutRef = useRef<number | null>(null);

  const [crop, setCrop] = useState<Crop | null>(null);
  const [pixelCrop, setPixelCrop] = useState<PixelCrop | null>(null);
  const [aspectRatio, setAspectRatio] = useState<CropOption | null>(null);
  const [aspect, setAspect] = useState<number | null>(null);

  const [rotation, setRotation] = useState(0);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  const [imageWidth, setImageWidth] = useState<number | undefined>(undefined);
  const [imageHeight, setImageHeight] = useState<number | undefined>(undefined);

  const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);
  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    let loopCount = 0;

    const checkImageSize = () => {
      window.requestAnimationFrame(() => {
        const newWidth = imageRef.current?.naturalWidth;
        const newHeight = imageRef.current?.naturalHeight;

        const newContainerWidth = containerRef.current?.clientWidth;
        const newContainerHeight = containerRef.current?.clientHeight;

        if (!newWidth && !newHeight && loopCount < 30) {
          checkImageSize();
          loopCount += 1;
        }

        if (newWidth && newHeight && newContainerWidth && newContainerHeight) {
          setImageWidth(newWidth);
          setImageHeight(newHeight);
          setContainerWidth(newContainerWidth);
          setContainerHeight(newContainerHeight);
        }
      });
    };

    checkImageSize();
  }, []);

  const isWide = useMemo(() => {
    const imageAspect = imageHeight && imageWidth && imageHeight / imageWidth;
    const containerAspect = containerHeight && containerWidth && containerHeight / containerWidth;

    if (!imageAspect || !containerAspect) {
      return false;
    }

    return containerAspect > imageAspect;
  }, [imageWidth, imageHeight, containerWidth, containerHeight]);

  const onSetAspectRatio = useCallback(
    (option: CropOption) => {
      setAspectRatio(option);

      const previewWidth = imageRef.current?.offsetWidth || 0;
      const previewHeight = imageRef.current?.offsetHeight || 0;

      if (option.scale) {
        setAspect(option.scale);

        let cropWidth = previewWidth;
        let cropHeight = cropWidth / option.scale;

        if (cropHeight > previewHeight) {
          cropHeight = previewHeight;
          cropWidth = cropHeight * option.scale;
        }

        const cropX = (previewWidth - cropWidth) / 2;
        const cropY = (previewHeight - cropHeight) / 2;

        setCrop({
          unit: 'px',
          width: cropWidth,
          height: cropHeight,
          x: cropX,
          y: cropY,
        });
      } else {
        setAspect(null);
        setCrop(null);
      }
    },
    [setAspect, setCrop]
  );

  useEffect(() => {
    if (!imageRef.current || !canvasRef.current) {
      return;
    }

    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    const currentCrop = (crop as PixelCrop) || {
      unit: 'px',
      width: imageRef.current.offsetWidth,
      height: imageRef.current.offsetHeight,
      x: 0,
      y: 0,
    };

    timeoutRef.current = window.setTimeout(() => {
      if (!imageRef.current || !canvasRef.current) {
        return;
      }

      createCanvasPreview(imageRef.current, canvasRef.current, currentCrop, 1, rotation, scaleX, scaleY)
        .then((blob) => {
          form.setBlob(blob);
        })
        .catch(() => null);
    }, 250);
  }, [pixelCrop, crop, rotation, scaleX, scaleY, form.setBlob]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCropComplete = useCallback(async (newCrop: PixelCrop) => {
    setPixelCrop(newCrop);
  }, []);

  const handleAlignmentChange = useCallback(async (newAngle: number) => {
    setRotation(newAngle);
  }, []);

  if (!form.asset) {
    return null;
  }

  let imageUrl = form.asset.url;
  try {
    const url = new URL(form.asset.url);
    url.searchParams.set('x-refetch', 'true');
    imageUrl = url.href;
  } catch {
    imageUrl = `${form.asset.url}?x-refetch=true`;
  }

  return (
    <>
      <div className="flex-1 overflow-auto">
        <div className="flex h-full gap-8">
          <div className="w-sidebar">
            <div className="flex flex-col gap-4">
              <div>
                <Label className="mb-1" text="Aspect ratio" />
                <CropRatioDropdown
                  currentOption={aspectRatio}
                  onSet={onSetAspectRatio}
                  onUnset={() => setAspectRatio(null)}
                />
              </div>
              <div>
                <AlignmentInput onChange={handleAlignmentChange} value={rotation} disabled={false} />
              </div>
              <div>
                <OrientationInput onChangeX={setScaleX} onChangeY={setScaleY} valueX={scaleX} valueY={scaleY} />
              </div>
            </div>
          </div>
          <div
            ref={containerRef}
            className="flex items-center justify-center flex-1 h-full col-span-7 overflow-hidden bg-gray-100 border border-gray-200 rounded-xl"
          >
            <ReactCrop
              className={isWide ? `is-wide` : `is-tall`}
              aspect={aspect || undefined}
              onChange={setCrop}
              crop={crop || undefined}
              onComplete={handleCropComplete}
            >
              <img
                ref={imageRef}
                alt={form.asset.alt}
                src={imageUrl}
                crossOrigin="anonymous"
                className="object-contain w-full h-full"
                style={{ transform: `rotate(${rotation}deg) scaleX(${scaleX}) scaleY(${scaleY})` }}
              />
            </ReactCrop>
            <canvas className="absolute w-0 h-0 opacity-0 pointer-events-none -top-96 -left-96" ref={canvasRef} />
          </div>
        </div>
      </div>
      <div className="flex-none mt-6">
        <div className="flex items-center justify-end gap-2">
          <Button
            disabled={form.isSaving || !form.blob}
            onClick={form.handleOverwrite}
            type="button"
            variant="primary-inverse"
          >
            Overwrite
          </Button>
          <Button disabled={form.isSaving || !form.blob} onClick={form.handleSave} type="button" variant="primary">
            Save as copy
            <ArrowRightIcon className="w-5 h-5 ml-1" />
          </Button>
        </div>
      </div>
    </>
  );
};
