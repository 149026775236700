import { useEffect, useState } from 'react';
import { getUsedFonts } from '@shared/dream-components';
import type { Content } from '@tiptap/core';

export const useFontHrefsFromContent = (content: Content) => {
  const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (!content) return;
    getUsedFonts(content).then((f) => setFontHrefs(new Set(f)));
  }, [content]);

  return fontHrefs;
};
