import { ChartData } from 'chart.js';
import moment from 'moment-mini';

import { LineChart } from '@/components/charts';

function offsetShift(map: Map<number, number>, offset: number) {
  const entries = Array.from(map.entries());
  const { length } = entries;
  const newOffset = ((offset % length) + length) % length;
  const newEntries = entries.slice(newOffset).concat(entries.slice(0, newOffset));
  const newMap = new Map(newEntries);
  return newMap;
}

interface Props {
  data: { [key: number]: number };
}

const OpensChart = ({ data: unsortedData }: Props) => {
  const offset = new Date().getTimezoneOffset() / 60;
  const data = offsetShift(
    new Map(Object.entries(unsortedData).map(([key, value]) => [parseInt(key, 10), value])),
    offset
  );

  const labels = Array.from(data.keys()).map((hour) =>
    moment().utc().hour(hour).minute(0).second(0).local().format('h:mm A')
  );

  const chartData: ChartData<'line'> = {
    labels,
    datasets: [
      {
        data: Array.from(data.values()),
        tension: 0.4,
        fill: 'start',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time of day',
        },
      },
      y: {
        title: {
          display: true,
          text: '% of unique opens',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx: any) => `${ctx.raw}%`,
        },
      },
    },
  };

  return <LineChart data={chartData} height={300} options={options} />;
};

export default OpensChart;
