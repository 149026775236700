import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { AutomationStep } from '../../interfaces/automations/automation_step';
import api from '../../services/swarm';

export default function useAutomationSteps(automationId: string | undefined) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<[AutomationStep]>(
    ['automations', 'automation_steps', automationId],
    () =>
      api
        .get(`/automations/${automationId}/automation_steps`, { params: { publication_id: currentPublicationId } })
        .then((res) => res.data.automation_steps),
    {
      keepPreviousData: true,
      enabled: !!automationId,
    }
  );
}
