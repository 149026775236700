import { Node } from 'reactflow';

import { AutomationStep, AutomationStepStepType } from '@/interfaces/automations/automation_step';

export const translateAutomationStepToNode = (automationStep: AutomationStep): Node => ({
  id: automationStep.id,
  parentNode: automationStep.parent_id,
  type: AutomationStepStepType.AUTOMATION_STEP,
  position: { x: 0, y: 0 },
  data: {
    id: automationStep.id,
    branchId: automationStep.branch_id,
    branchArm: automationStep.branch_arm,
    stepType: automationStep.step_type,
  },
});

export const translateAutomationStepsToNodes = (automationSteps: AutomationStep[]): Node[] =>
  automationSteps.map(translateAutomationStepToNode);
