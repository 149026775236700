import { FC } from 'react';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import SlideOver from '@/components/SlideOver';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { useFormContext } from '../FormContext';
import RewardForm from '../RewardForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (rewardId: string) => void;
  publicationId: string;
}

const RewardSlideOver: FC<Props> = ({ isOpen, onSuccess, onClose, publicationId }: Props) => {
  const { reward, resetReward, isLoadingReward, createReward, updateReward, isSavingReward } = useFormContext();

  const onCloseModal = () => {
    resetReward();
    onClose();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (reward === null) {
      return;
    }

    analytics.track('Added Reward');

    /*
      Due to needing to upload an image
      we have to use multipart form instead of JSON
    */
    const formData = new FormData();
    formData.append('name', reward?.name);
    formData.append('prefixed_article', reward?.prefixed_article || '');
    formData.append('description', reward?.description);
    formData.append('disclaimer', reward?.disclaimer || '');
    formData.append('reward_type', reward?.reward_type);
    formData.append('publication_id', publicationId);

    // Only pass image if it's a binary or null.
    // If it's a string, it means the image didn't change
    // and we can leave it alone.
    if (reward?.image instanceof File || reward?.image === null) {
      formData.append('image', reward.image || '');
    }

    if (reward?.id) {
      updateReward(reward.id, formData, onSuccess);
      return;
    }

    createReward(formData, onSuccess);
  };

  const isDisabled = !reward?.name || !reward?.description || !reward?.reward_type;

  const actions = (
    <div className="flex justify-end">
      <Button type="button" className="mx-2" variant="primary-inverse" block={false} onClick={onCloseModal}>
        Cancel
      </Button>
      <Button
        variant="primary"
        type="button"
        block={false}
        loading={isSavingReward}
        disabled={isDisabled}
        onClick={handleSubmit}
      >
        {reward?.id ? 'Update' : 'Create'}
      </Button>
    </div>
  );

  return (
    <SlideOver
      bodyId="manage-reward"
      headerText={reward?.id ? 'Update Reward' : 'Create Reward'}
      isOpen={isOpen}
      onClose={onClose}
      actionsChildren={actions}
    >
      {isLoadingReward ? (
        <div className="text-center py-6">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="space-y-6">
          <RewardForm />
        </div>
      )}
    </SlideOver>
  );
};

export default RewardSlideOver;
