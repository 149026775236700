import { FC } from 'react';
import { SurveyElement } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

export const SurveyView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <SurveyElement
      element={{
        type: 'survey',
        content: [{ text: '', id: 'text' }],
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
          data: node.attrs.data,
        },
      }}
      onDragStart={onDragStart}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
    >
      {null}
    </SurveyElement>
  );
};
