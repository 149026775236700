/* eslint-disable */

import { FC } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip);

export interface Datum {
  label: string;
  value: number;
}

interface Props {
  className?: string;
  data: Datum[];
  options?: any;
  rest?: any;
}

const PieChart: FC<Props> = (props: Props) => {
  const { className, data, options, rest } = props;

  const chartOptions = {
    ...options,
  };
  const labels = data.map((datum) => datum.label);
  const datasetData = data.map((datum) => datum.value);
  const datasets = [
    {
      id: 1,
      label: '',
      data: datasetData,
      backgroundColor: ['#EC4899', '#6B7280', '#8B5CF6', '#EF4444', '#10B981', '#F59E0B'],
    },
  ];

  const dataProp = {
    labels,
    datasets,
  };

  return <Pie datasetIdKey="id" data={dataProp} options={chartOptions} {...rest} />;
};

export default PieChart;
