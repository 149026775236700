import { PropsWithChildren, useState } from 'react';
import toast from 'react-hot-toast';
import { Pencil, Trash } from '@phosphor-icons/react';
import { DropdownMenuContentProps, DropdownMenuProps } from '@radix-ui/react-dropdown-menu';

import { useUpdateSitePackage } from '@/hooks/useSitePackages';
import useDeleteSitePackage from '@/hooks/useSitePackages/useDeleteSitePackage';
import { SitePackage } from '@/interfaces/site_package';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/routes/website/_components/UI/DropdownMenu';
import { Input } from '@/routes/website/_components/UI/Input';
import Modal from '@/routes/website/_components/UI/Modal';
import { Text } from '@/routes/website/_components/UI/Text';

type Props = {
  project: SitePackage;
  onDeleteSuccess?: () => void;
};

export const ProjectOptions = ({
  children,
  align,
  project,
  onDeleteSuccess,
  ...props
}: PropsWithChildren<{ align: DropdownMenuContentProps['align'] } & DropdownMenuProps & Props>) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [name, setName] = useState(project.name);

  const updateSitePackage = useUpdateSitePackage({
    id: project.id,
    onSuccess: () => {
      setIsEditModalOpen(false);
    },
  });

  const handleEdit = () => {
    if (!name.trim()) {
      toast.error('Name is required');
      return;
    }

    updateSitePackage.mutate({
      name,
    });
  };

  const deleteSitePackage = useDeleteSitePackage({
    id: project.id,
    onSuccess: () => {
      setIsDeleteModalOpen(false);
    },
  });

  const handleDelete = () => {
    deleteSitePackage.mutate();
  };

  return (
    <>
      <DropdownMenu {...props}>
        <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={2} align={align} className="w-[150px]">
          <DropdownMenuItem
            LeftIcon={Pencil}
            onSelect={() => {
              setIsEditModalOpen(true);
            }}
          >
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem
            LeftIcon={Trash}
            onSelect={() => {
              setIsDeleteModalOpen(true);
            }}
            disabled={false}
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Modal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Edit Project"
        onConfirm={handleEdit}
        ctaText="Save"
        isLoading={updateSitePackage.isLoading}
        isLoadingText="Saving..."
      >
        <Input
          name="name"
          labelText="Name"
          placeholder="New Project"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Project"
        onConfirm={handleDelete}
        ctaText="Delete"
        ctaVariant="danger"
        isLoading={deleteSitePackage.isLoading}
        isLoadingText="Deleting..."
      >
        <Text size="sm" weight="medium" variant="secondary" as="p" className="mb-4">
          Are you sure you want to delete this project?
        </Text>
      </Modal>
    </>
  );
};
