import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { DotsThree, Pencil, Trash } from '@phosphor-icons/react';

import useDeleteSiteTheme from '@/hooks/useSiteThemes/useDeleteSiteTheme';
import { SiteTheme } from '@/interfaces/web_theme';

import { Button } from '../../../_components/UI/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../_components/UI/DropdownMenu';
import Modal from '../../../_components/UI/Modal';
import { TableCell, TableRow } from '../../../_components/UI/Table';
import { Text } from '../../../_components/UI/Text';

interface Props {
  siteTheme: SiteTheme;
  queryKey: string[];
  onEdit: (siteTheme: SiteTheme) => void;
  hideDelete?: boolean;
}

const SiteThemeRow = ({ siteTheme, queryKey, onEdit, hideDelete = false }: Props) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);

  const deleteSiteTheme = useDeleteSiteTheme({
    siteThemeId: siteTheme?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      setIsOpen(false);
    },
  });

  const handleDelete = () => {
    deleteSiteTheme.mutate();
  };

  return (
    <>
      <TableRow key={siteTheme.id}>
        <TableCell className="font-medium">{siteTheme?.name || 'Theme'}</TableCell>
        <TableCell className="text-right">
          <div className="w-6 h-6 rounded-md" style={{ backgroundColor: siteTheme.data.primary_color }} />
        </TableCell>
        <TableCell className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <DotsThree className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={2} align="end" className="flex w-[150px] flex-col bg-wb-primary p-1">
              <DropdownMenuItem LeftIcon={Pencil} onSelect={() => onEdit(siteTheme)}>
                Edit
              </DropdownMenuItem>
              {!hideDelete && (
                <DropdownMenuItem LeftIcon={Trash} onSelect={() => setIsOpen(true)}>
                  Delete
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Delete Theme"
        onConfirm={handleDelete}
        isLoading={deleteSiteTheme.isLoading}
        ctaVariant="danger"
        ctaText="Delete"
        isLoadingText="Deleting..."
      >
        <Text size="sm" weight="medium" variant="secondary" as="p" className="mb-4">
          Would you like to delete this theme? It will not impact any content or current theming on your site but will
          no longer be useable within the editor. You can always re-create it later.
        </Text>
      </Modal>
    </>
  );
};

export default SiteThemeRow;
