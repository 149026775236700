import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { NavLinkPayload } from '../../interfaces/nav_link';
import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

interface Props {
  onSuccess: (response: any) => void;
}

const useCreateNavLink = ({ onSuccess }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (navLinkPayload: NavLinkPayload) =>
      api.post(`/nav_links`, {
        publication_id: currentPublication?.id,
        nav_link: { ...navLinkPayload, web_template_id: currentPublication?.web_template_id },
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Could not create page.');
      },
    }
  );
};

export default useCreateNavLink;
