import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkIndustry } from '../../../interfaces/ad_network/internal/industry';
import api from '../../../services/swarm';

interface SuccessResponse extends AdNetworkIndustry {}
interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}
interface Variables {
  name: string;
  industryGroupId: string;
}

export default function useIndustryUpdate({ id }: { id: string }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<SuccessResponse, ErrorResponse, Variables>(
    ({ name, industryGroupId }) =>
      api
        .patch(`/ad_network/internal/industries/${id}`, { industry: { name, industry_group_id: industryGroupId } })
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast.success('Industry updated successfully');
        queryClient.invalidateQueries(['ad_network', 'internal', 'industries', id]);
        navigate(`/ad_network/industries/${id}`);
      },
      onError: (error) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      },
    }
  );
}
