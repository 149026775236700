import { useCallback, useMemo } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { useThemeData } from '../../../../lib/hooks/useThemeData';
import { useThreadComposer } from '../../../panels/ThreadComposer/Context';
import { Button } from '../../../ui/Button';
import { Icon } from '../../../ui/Icon';
import { Panel, PanelDivider, PanelFooter, PanelHeader, PanelSection } from '../../../ui/Panel';
import { useCurrentNode } from '../../hooks';
import Styled from '../../styled';

import useMainPanel from './MainPanel.hooks';

export const MainPanel = ({
  editor,
  navigateToPanel,
  currentNode,
  currentNodePos,
  usesCollaboration,
}: {
  editor: Editor;
  navigateToPanel: Function;
  currentNode: Node | null;
  currentNodePos: number;
  usesCollaboration?: boolean;
}) => {
  const {
    onSetSection,
    onUnsetSection,
    navigateToVisualSettings,
    navigateToVisibilitySettings,
    navigateToAnchorSettings,
    resetTextFormatting,
    duplicateNode,
    copyNodeToClipboard,
    deleteNode,
    isGroupingPossible,
    isUngroupingPossible,
  } = useMainPanel(editor, navigateToPanel, currentNode, currentNodePos);

  const themeColors = useThemeData('colors');
  const { hasVisualSettingsOverrides, hasVisibilitySettingsOverrides, hasAnchorSettingsOverrides } = useCurrentNode(
    editor,
    currentNode,
    themeColors
  );

  const isSafari = !navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari');
  const { openComposer } = useThreadComposer();

  const handleOpenComposer = useCallback(() => {
    editor.commands.setNodeSelection(currentNodePos);
    openComposer();
  }, [openComposer, editor, currentNodePos]);

  const isPaywall = useMemo(() => currentNode?.type?.name === 'paywallBreak', [currentNode]);

  return (
    <Styled.ContentWrapper>
      <Panel tabIndex={-1}>
        <PanelHeader>
          {isGroupingPossible ? (
            <Button
              $variant="quaternary"
              $size="small"
              onClick={onSetSection}
              disabled={!isGroupingPossible}
              $fullWidth
              $leftSlot={<Icon name="GroupSection" />}
              $muted
            >
              Group to section
            </Button>
          ) : null}

          {isUngroupingPossible ? (
            <Button
              $variant="quaternary"
              $size="small"
              onClick={onUnsetSection}
              disabled={!isUngroupingPossible}
              $fullWidth
              $leftSlot={<Icon name="Ungroup" />}
              $muted
            >
              Ungroup
            </Button>
          ) : null}

          {usesCollaboration && (
            <Button
              $variant="quaternary"
              $size="small"
              onClick={handleOpenComposer}
              $fullWidth
              $leftSlot={<Icon name="Comment" />}
              $muted
            >
              Comment
            </Button>
          )}
        </PanelHeader>
        <PanelSection>
          <Styled.MenuButtonWrapper>
            {!isPaywall ? (
              <>
                <Button
                  $variant="quaternary"
                  $size="small"
                  $active={hasVisualSettingsOverrides}
                  onClick={navigateToVisualSettings}
                  $fullWidth
                  $leftSlot={<Icon name="Settings" />}
                  $rightSlot={<Icon name="ChevronRight" />}
                >
                  Visual settings
                </Button>
                <Button
                  $variant="quaternary"
                  $size="small"
                  $active={hasVisibilitySettingsOverrides}
                  onClick={navigateToVisibilitySettings}
                  $fullWidth
                  $leftSlot={<Icon name="Show" />}
                  $rightSlot={<Icon name="ChevronRight" />}
                >
                  Visibility
                </Button>
              </>
            ) : null}
            {currentNode && 'anchorTitle' in currentNode.attrs && (
              <Button
                $variant="quaternary"
                $size="small"
                $active={hasAnchorSettingsOverrides}
                onClick={navigateToAnchorSettings}
                $fullWidth
                $leftSlot={<Icon name="Anchor" />}
                $rightSlot={<Icon name="ChevronRight" />}
              >
                Anchor
              </Button>
            )}
          </Styled.MenuButtonWrapper>
        </PanelSection>
        <PanelDivider />
        <PanelSection>
          <Styled.MenuButtonWrapper>
            {!currentNode?.isAtom && (
              <Button
                $variant="quaternary"
                $size="small"
                onClick={resetTextFormatting}
                $fullWidth
                $leftSlot={<Icon name="ClearFormat" />}
                $muted
              >
                Reset text formatting
              </Button>
            )}

            <Button
              $variant="quaternary"
              $size="small"
              onClick={duplicateNode}
              $fullWidth
              $leftSlot={<Icon name="Duplicate" />}
              $muted
            >
              Duplicate
            </Button>
            {!isSafari && (
              <Button
                $variant="quaternary"
                $size="small"
                onClick={copyNodeToClipboard}
                $fullWidth
                $leftSlot={<Icon name="Copy" />}
                $muted
              >
                Copy to clipboard
              </Button>
            )}
          </Styled.MenuButtonWrapper>
        </PanelSection>
        <PanelFooter>
          <Button
            $variant="quaternary"
            $size="small"
            onClick={deleteNode}
            $fullWidth
            $leftSlot={<Icon name="Trash" />}
            $muted
          >
            Delete
          </Button>
        </PanelFooter>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default MainPanel;
