import { Extension } from '@tiptap/core';
import { NodeSelection } from '@tiptap/pm/state';

import { Columns } from '../Columns';
import { Container } from '../Container';

import { getMultiNodeSelectionPlugin, MultiNodeSelection } from './selections';
import { getDrilldownSelectorPlugin, getParentSelectorPlugin } from './selectors';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customSelections: {
      /**
       * Turns multinode selection to containers
       */
      multinodeSelectionToContainers: () => ReturnType;
      /**
       * Turns multinode selection to columns
       */
      multinodeSelectionToColumns: () => ReturnType;
      /**
       * Toggles a node in the multinode selection
       */
      toggleNodeInMultinodeSelection: (nodePos: number) => ReturnType;
    };
  }
}

export const CustomSelections = Extension.create({
  name: 'customSelections',

  addProseMirrorPlugins() {
    return [
      // selections
      getMultiNodeSelectionPlugin(this.editor),

      // selectors
      getDrilldownSelectorPlugin(this.editor),
      getParentSelectorPlugin(this.editor),
    ];
  },

  addCommands() {
    return {
      multinodeSelectionToContainers:
        () =>
        ({ chain, state: { selection, schema } }) => {
          if (!(selection instanceof MultiNodeSelection)) {
            return false;
          }

          const start = selection.$from.pos;

          const newNode = schema.node(Container.name, null, selection.nodes);

          return chain()
            .deleteSelection()
            .command(({ tr, dispatch }) => {
              tr.insert(tr.mapping.map(start), newNode);

              dispatch?.(tr);

              return true;
            })
            .run();
        },
      multinodeSelectionToColumns:
        () =>
        ({ chain, state: { selection } }) => {
          if (!(selection instanceof MultiNodeSelection)) {
            return false;
          }

          const start = selection.$from.pos;

          const columns = {
            type: Columns.name,
            content: [
              {
                type: Container.name,
                attrs: { flexBasis: '50%' },
                content: selection.nodes.map((node) => node.toJSON()),
              },
              {
                type: Container.name,
                attrs: { flexBasis: '50%' },
              },
            ],
          };

          return chain()
            .deleteSelection()
            .command(({ tr, commands }) => {
              return commands.insertContentAt(tr.mapping.map(start), columns);
            })
            .run();
        },
      toggleNodeInMultinodeSelection:
        (nodePos) =>
        ({ tr, state: { selection } }) => {
          let newSelection: NodeSelection | MultiNodeSelection | null = null;

          const $nodePos = tr.doc.resolve(nodePos);

          if (selection instanceof NodeSelection) {
            if (selection.from === nodePos) return false;

            newSelection = new MultiNodeSelection([selection.$from, $nodePos]);
          } else if (selection instanceof MultiNodeSelection) {
            if (selection.posList.includes(nodePos)) {
              const $newPosList = selection.$posList.filter((val) => val.pos !== nodePos);

              if ($newPosList.length > 1) {
                newSelection = new MultiNodeSelection($newPosList);
              } else {
                newSelection = new NodeSelection($newPosList[0]);
              }
            } else {
              newSelection = new MultiNodeSelection([...selection.$posList, $nodePos]);
            }
          }

          if (newSelection) {
            tr.setSelection(newSelection);
            return true;
          }

          return false;
        },
    };
  },
});
