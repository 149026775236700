import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TimePeriod } from '@/interfaces/time_period';
import api from '@/services/swarm';

import useCurrentTimeZone from '../useCurrentTimeZone';

export enum OverviewEndpoints {
  ENGAGEMENT_KPIS = 'engagement_kpis',
  ACQUISITION_SOURCES = 'acquisition_sources',
}

interface Props {
  endpoint: OverviewEndpoints;
  timePeriod: TimePeriod;
}

const useOverview = ({ endpoint, timePeriod }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const currentTimeZone = useCurrentTimeZone();

  const fetchInsights = () =>
    api
      .get(`/dashboard/overview/${endpoint}/${timePeriod}`, {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
        },
      })
      .then((res) => res.data);

  return useQuery(['overview', currentPublicationId, endpoint, timePeriod], fetchInsights, {
    refetchOnWindowFocus: false,
    retryOnMount: false,
    enabled: Boolean(currentPublicationId),
  });
};

export default useOverview;
