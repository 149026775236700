import { FC, useCallback, useMemo } from 'react';
import Tippy from '@tippyjs/react';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { FootnoteMarkerAttrs } from '../../types';

export const FootnoteMarkerView: FC<NodeViewProps> = ({ node, editor }) => {
  const attrs = useMemo(() => node.attrs as FootnoteMarkerAttrs, [node.attrs]);

  const focusFootNoteItem = useCallback(() => {
    editor.commands.focusFootnoteItem(attrs.footnoteId);
  }, [attrs.footnoteId, editor]);

  const footnoteItemContent = (
    <div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200">
      <span className="underline underline-offset-2 mr-1">{attrs.order}</span>
      {attrs.footnoteText}
    </div>
  );

  return (
    <NodeViewWrapper as="span">
      <Tippy content={footnoteItemContent} placement="top" trigger="mouseenter">
        <button type="button" onClick={focusFootNoteItem} contentEditable="false" suppressContentEditableWarning>
          <sup className="underline underline-offset-1">{attrs.order}</sup>
        </button>
      </Tippy>
    </NodeViewWrapper>
  );
};
