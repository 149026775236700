import { FC } from 'react';

// Components
import TablePagination from '../../../../components/TablePagination';
import { Pagination } from '../../../../interfaces/general';
// Interfaces
import { Milestone } from '../../../../interfaces/milestone';
import RewardImage from '../RewardImage';

interface Props {
  milestones: Milestone[] | undefined;
  pagination: Pagination | undefined;
  onPageSelected: (pageNum: number) => void;
  onEditSelected: (milestoneId: string) => void;
  onDeleteSelected: (milestoneId: string) => void;
}

const Table: FC<Props> = (props: Props) => {
  const { milestones, pagination, onPageSelected, onEditSelected, onDeleteSelected } = props;

  if (!milestones || !pagination) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Reward
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total Referrals
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {milestones.map((milestone: Milestone) => (
                  <tr key={milestone.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <RewardImage image={milestone.reward.image} />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{milestone.reward.name}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{milestone.num_referrals}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        type="button"
                        className="text-primary-600 hover:text-primary-900"
                        onClick={() => onEditSelected(milestone.id)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="text-primary-600 hover:text-primary-900 ml-2"
                        onClick={() => onDeleteSelected(milestone.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <TablePagination className="mt-2" pagination={pagination} onPageSelected={onPageSelected} />
    </div>
  );
};

export default Table;
