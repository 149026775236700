import { TutorialType } from '../../../../../interfaces/tutorial';
import { Section } from '../../../Components';
import VideoTutorial from '../../../Components/VideoTutorial';

import HistoricalImports from './HistoricalImports';
import RaiseImportLimit from './RaiseImportLimit';
import StartImport from './StartImport';

const ImportSubscribers = () => {
  return (
    <>
      <Section
        title="Import Subscribers"
        description={
          <div>
            <span>Seamlessly import existing subscribers to your publication&apos;s list.</span>
            <VideoTutorial type={TutorialType.IMPORT_SUBSCRIBERS} />
          </div>
        }
        scrollToId="import_subscribers"
      >
        <StartImport />
      </Section>
      <Section
        title="Raise your import limit"
        description="Options to raise your subscriber import limit."
        scrollToId="raise_import_limit"
      >
        <RaiseImportLimit />
      </Section>
      <Section
        title="Historical Imports"
        description="A list of your previous subscriber imports."
        scrollToId="historical_imports"
      >
        <HistoricalImports />
      </Section>
    </>
  );
};

export default ImportSubscribers;
