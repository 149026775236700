import { useEffect } from 'react';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { Typography } from '@/components/Typography';
import { useBoostSends } from '@/hooks/boosts/monetize/useBoostSends';
import { EarningsOrderBy, OrderBy } from '@/interfaces/boosts/order';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListFilters, useResourceListFilters } from '@/ui/ResourceListFilters';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import { boostSendsFilterOptions } from '../constants/filterOptions';
import { dateOrderByOptions, earningsOrderByOptions, orderByOptions } from '../constants/orderByOptions';

import BoostSendListItem from './BoostSendListItem';

interface Props {
  boostedPublicationId?: string;
  showCPA?: boolean;
  showSearch?: boolean;
}

const BoostSendsList = ({ boostedPublicationId, showCPA = false, showSearch = true }: Props) => {
  const {
    hasFilter,
    resetAllFilters,
    search,
    setSearch,
    filter,
    setFilter,
    orderBy,
    setOrderBy,
    handleClickOrderButton,
    direction,
  } = useResourceListFilters<string, string>({
    defaultFilter: '',
    defaultSortOrder: OrderBy.CREATED_AT
  });

  const { data, isLoading, isRefetching, isError, hasNextPage, refetch, fetchNextPage, isFetchingNextPage } =
    useBoostSends({
      search,
      status: filter,
      boostedPublicationId,
      orderBy,
      direction: orderBy === 'created_at' ? undefined : direction,
    });

  const boostSends = data?.pages.flatMap((page) => page.boost_sends) || [];

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  const hasNoResults = !isRefetching && !isLoading && boostSends.length === 0;
  const totalCount = data?.pages[0]?.pagination?.total || 0;
  const showingCount = boostSends?.length || 0;

  const title = hasFilter
    ? `No results found for current filters`
    : 'You are not recommending any Publications via Email at the moment';
  const primaryActionLabel = 'Reset Filters';
  const onPrimaryActionClick = hasFilter ? resetAllFilters : undefined;
  const primaryActionIcon = hasFilter ? ArrowPathIcon : undefined;

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height={500}>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-2">
          <Typography token="font-bold/text/sm">Overall Email Boost Performance</Typography>
          <Typography token="font-medium/text/sm">
            All time revenue you have received from boosting your partner publications via Email.
          </Typography>
        </div>
        <div className="flex justify-end w-full">
          <ResourceListFilters
            search={showSearch ? search : undefined}
            setSearch={showSearch ? setSearch : undefined}
            searchPlaceholder="Search Publications"
            filter={filter}
            setFilter={setFilter}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            sortOrderOptions={[...orderByOptions, ...earningsOrderByOptions, ...dateOrderByOptions]}
            filterOptions={boostSendsFilterOptions}
          />
        </div>
        <ResourceListSearchText showingCount={showingCount} totalCount={totalCount} />
        {hasNoResults && !isLoading && (
          <EmptyCard
            title={title}
            primaryActionIcon={primaryActionIcon}
            primaryActionLabel={primaryActionLabel}
            onPrimaryActionClick={onPrimaryActionClick}
          />
        )}
        {!hasNoResults && (
          <Items>
            {(isInViewport) => (
              <>
                <ItemHeaders isSticky={isInViewport}>
                  <ItemHeader>{showCPA ? 'CPA' : 'Publication Name'}</ItemHeader>
                  <ItemHeader>Status</ItemHeader>
                  {!showCPA && <ItemHeader>Offer CPA</ItemHeader>}
                  <ItemHeader>Email Max Earnings</ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={OrderBy.PENDING_SUBSCRIBER_COUNT}
                    isSorting={orderBy === OrderBy.PENDING_SUBSCRIBER_COUNT}
                    currentDirection={direction}
                  >
                    Pending subscribers
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={OrderBy.ACCEPTED_SUBSCRIBER_COUNT}
                    isSorting={orderBy === OrderBy.ACCEPTED_SUBSCRIBER_COUNT}
                    currentDirection={direction}
                  >
                    Verified subscribers
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={OrderBy.TOTAL_SUBSCRIBER_COUNT}
                    isSorting={orderBy === OrderBy.TOTAL_SUBSCRIBER_COUNT}
                    currentDirection={direction}
                  >
                    Total subscribers
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={EarningsOrderBy.PENDING_EARNINGS}
                    isSorting={orderBy === EarningsOrderBy.PENDING_EARNINGS}
                    currentDirection={direction}
                  >
                    Pending revenue
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={EarningsOrderBy.TOTAL_EARNINGS}
                    isSorting={orderBy === EarningsOrderBy.TOTAL_EARNINGS}
                    currentDirection={direction}
                  >
                    Total revenue
                  </ItemHeader>
                  <ItemHeader isSticky>&nbsp;</ItemHeader>
                </ItemHeaders>
                <ItemsBody>
                  {boostSends.map((boostSend) => (
                    <BoostSendListItem
                      boostSend={boostSend}
                      onActionSuccess={refetch}
                      showCPA={showCPA}
                      key={`boost-send-list-item-${boostSend.id}`}
                    />
                  ))}
                </ItemsBody>
              </>
            )}
          </Items>
        )}

        {hasNextPage && (
          <div className="flex justify-start mt-8 w-fit">
            <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
          </div>
        )}
      </div>
    </LoadingBox>
  );
};

export default BoostSendsList;
