import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import SubscriberSelect from '@/components/SubscriberSelect';
import { useCommentBans, useCreateCommentBan } from '@/hooks/useComments';
import { BanScopes, BanTypes, CommentBan, ScopeTypes } from '@/interfaces/comment_ban';

import { Button } from '../../../_components/UI/Button';
import InputWrapper from '../../../_components/UI/InputWrapper';
import Modal from '../../../_components/UI/Modal';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../_components/UI/Select';
import { Table, TableBody, TableCaption, TableHead, TableHeader, TableRow } from '../../../_components/UI/Table';
import { Text } from '../../../_components/UI/Text';
import { Textarea } from '../../../_components/UI/Textarea';

import CommentBanRow from './CommentBanRow';

const CommentBans = () => {
  const queryClient = useQueryClient();
  const [renderType, setRenderType] = useState<string>(BanScopes.ALL);
  const isAll = renderType === BanScopes.ALL;

  const [renderStatus, setRenderStatus] = useState<ScopeTypes>(BanScopes.ACTIVE);

  const paginatedCommentBansQuery = useCommentBans({
    level: isAll ? undefined : renderType,
    scope: renderStatus,
  });
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } = paginatedCommentBansQuery;
  const commentBans = data?.pages.flatMap((page: any) => page.comment_bans) || [];
  const totalCommentBansCount = data?.pages[0]?.pagination?.total;
  const showingCommentBansCount = commentBans?.length || 0;
  const isNoResults = !isLoading && commentBans.length === 0;

  const [reason, setReason] = useState('');
  const [currentSubscriberId, setCurrentSubscriberId] = useState<string>();
  const [commentBansOpen, setCommentBansOpen] = useState<boolean>(false);

  const createCommentBan = useCreateCommentBan({
    onSuccess: () => {
      toast.success('Saved!');
      queryClient.invalidateQueries(['comment_bans']);
      setReason('');
      setCurrentSubscriberId('');
      setCommentBansOpen(false);
    },
  });

  const handleCreateCommentBan = () => {
    createCommentBan.mutate({
      level: BanTypes.PUBLICATION,
      reason,
      subscriber_id: currentSubscriberId,
    });
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex space-x-2 justify-between w-full items-end">
        <div className="flex space-x-2">
          <InputWrapper name="by_level" labelText="Filter by level">
            <Select
              defaultValue={renderType}
              onValueChange={(value: BanScopes) => {
                setRenderType(value);
              }}
            >
              <SelectTrigger className="w-[180px]" id="by_level">
                <SelectValue placeholder="Select a level" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="all">All</SelectItem>
                  <SelectItem value="post">Post</SelectItem>
                  <SelectItem value="publication">Publication</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </InputWrapper>

          <InputWrapper name="by_status" labelText="Filter by status">
            <Select
              defaultValue={renderStatus}
              onValueChange={(value: BanScopes) => {
                setRenderStatus(value);
              }}
            >
              <SelectTrigger className="w-[180px]" id="by_status">
                <SelectValue placeholder="Select a status" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="all">All</SelectItem>
                  <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="inactive">Inactive</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </InputWrapper>
        </div>

        <Button onClick={() => setCommentBansOpen(true)}>Add Ban</Button>
        <Modal
          isOpen={commentBansOpen}
          onClose={() => setCommentBansOpen(false)}
          title="Add Comment Ban"
          onConfirm={handleCreateCommentBan}
          ctaText={createCommentBan.isLoading ? 'Adding...' : 'Add Ban'}
          isLoading={createCommentBan.isLoading}
        >
          <div className="flex flex-col py-4 space-y-2">
            <SubscriberSelect
              onSelectSubscriber={(subscriberId: string) => setCurrentSubscriberId(subscriberId)}
              onClearSubscriber={() => setCurrentSubscriberId('')}
            />
          </div>
          <div className="flex flex-col space-y-2 w-full">
            <Textarea
              name="reason"
              labelText="Reason for ban"
              placeholder="This user was reported by multiple other users because of..."
              helperText="Giving an explanation is helpful for other admins to understand why you took this action to ban the
                    subscriber(s) from commenting."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </Modal>
      </div>

      <div className="flex flex-col space-y-1">
        <div className="border rounded-md">
          <Table>
            {isNoResults && (
              <TableCaption>
                <div className="pb-4">
                  <Text size="sm" weight="medium" variant="secondary" as="span">
                    No results found
                  </Text>
                </div>
              </TableCaption>
            )}
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Subscriber</TableHead>
                <TableHead>Reason</TableHead>
                <TableHead className="text-right">Level</TableHead>
                <TableHead className="text-right w-4" />
              </TableRow>
            </TableHeader>
            <TableBody>
              {commentBans.map((commentBan: CommentBan) => {
                return <CommentBanRow key={commentBan.id} commentBan={commentBan} />;
              })}
            </TableBody>
          </Table>
        </div>
        <div className="flex justify-between items-center">
          <Text size="sm" weight="medium" variant="secondary" as="span">
            {`Showing ${showingCommentBansCount} of ${totalCommentBansCount} results`}
          </Text>
          {hasNextPage && (
            <Button variant="outlined" onClick={() => fetchNextPage()}>
              {isFetchingNextPage ? 'Loading more...' : 'Load more'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentBans;
