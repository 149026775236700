import React from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

interface ResourceLinkProps {
  title: string;
  href: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  isLive?: boolean; // New optional prop for live badge
}

const ResourceLink: React.FC<ResourceLinkProps> = ({ title, icon: Icon, href, isLive }) => (
  <a href={href} target='_blank' className="bg-white cursor-pointer rounded-lg border border-surface-200 overflow-hidden shadow-sm hover:shadow-md transition-shadow p-4 relative" rel="noreferrer">
    {Icon &&
      <div className='inline-block text-[#4746E5] bg-[#E0E7FF] rounded-full p-2 mb-2'>
        <Icon className='w-4 h-4 ' />
      </div>
    }
    <div className='flex justify-between items-center'>
      <p className="font-medium flex items-center gap-2">{title}
        {isLive && (
          <div className="bg-[#D1FAE5] border border-[#A7F3D0] text-[#064E3B] text-xs font-semibold px-[6px] py-[2px] rounded-[4px]">
            Live
          </div>
        )}
      </p>
      <ArrowUpRightIcon className='text-surface-500 w-4 h-4' />
    </div>
  </a>
);

export default ResourceLink;