import { RefObject, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountUp from 'react-countup';
import cx from 'classnames';

import { Publication } from '@/interfaces/publication';
// import moment from 'moment-mini';
import { PublicationRewind } from '@/interfaces/publication_rewind';

import { getDecimal, getImageUrl, getSuffix } from '../helpers';

type Props = {
  currentViewIndex: number;
  data?: PublicationRewind;
  index?: number;
  currentPublication?: Publication;
  setSlideRef: (ref: RefObject<HTMLDivElement>) => void;
};

const View6 = ({ currentPublication, setSlideRef, data, currentViewIndex, index }: Props) => {
  const [post1Count, setPost1Count] = useState(0);
  const [post2Count, setPost2Count] = useState(0);
  const [post3Count, setPost3Count] = useState(0);
  const [playAnimation, setPlayAnimation] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isCurrentView = currentViewIndex === index;

    if (isCurrentView) {
      setSlideRef(contentRef);
      setPost1Count(getDecimal(data?.top_posts[0]?.unique_subscriber_opens || 0));
      setPost2Count(getDecimal(data?.top_posts[1]?.unique_subscriber_opens || 0));
      setPost3Count(getDecimal(data?.top_posts[2]?.unique_subscriber_opens || 0));
      setTimeout(() => {
        setPlayAnimation(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const image1Url = getImageUrl(data?.top_posts[0]?.thumbnail?.url || currentPublication?.thumbnail?.url);
  const image2Url = getImageUrl(data?.top_posts[1]?.thumbnail?.url || currentPublication?.thumbnail?.url);
  const image3Url = getImageUrl(data?.top_posts[2]?.thumbnail?.url || currentPublication?.thumbnail?.url);

  return (
    <div ref={contentRef} className="w-full h-full relative overflow-hidden rewind-bg-blue">
      <div className="rewind-bg-burst top-0 left-0 absolute w-full h-full" />
      <div className="rewind-bg-texture top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] bg-white rounded-[2rem] m-[5%_0_0_5%] p-[35px_55px] flex flex-col border-2 rewind-border-cyber-ink" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] m-[5%_0_0_5%] p-[35px] flex flex-col">
        <div className="flex justify-center items-center w-full mt-6 mb-10">
          <img className="h-6 w-auto" src="/rewind-2024/images/beehiiv-logo-wordmark.png" alt="beehiiv logo" />
        </div>
        <h1
          className={cx(
            'font-clash-grotesk-v rewind-text-cyber-ink uppercase font-bold w-full text-[4rem] leading-[3.2rem] text-center mb-6 transition-all duration-300',
            !playAnimation && 'translate-x-20 opacity-0',
            playAnimation && 'translate-x-0 opacity-1'
          )}
        >
          THESE POSTS GRABBED THE MOST ATTENTION…
        </h1>
        <ul className="mt-4 p-0 w-full">
          <li className="flex w-full justify-between items-center rewind-bg-ethereal-white rounded-xl p-6 mb-4 relative h-52">
            {image1Url && (
              <div className="w-[38%] h-full flex justify-center align-center">
                <img
                  src={image1Url}
                  alt={data?.top_posts[0]?.web_title}
                  className="max-w-full max-h-full self-center"
                />
              </div>
            )}
            <div className="w-full pl-5">
              {data?.top_posts[0]?.scheduled_at && (
                <div className="rounded-[0.875rem] bg-white rewind-text-blue text-sm px-2 inline-block">
                  {new Date(data.top_posts[0].scheduled_at).toLocaleDateString('en-US', dateOptions)}
                </div>
              )}

              <div className="font-satoshi-v rewind-text-cyber-ink text-lg font-bold w-full truncate">
                {data?.top_posts[0]?.web_title}
              </div>
              <div className="flex font-bold text-[5.125rem] leading-[3.5rem] font-clash-grotesk-v uppercase items-baseline mt-6">
                <div>
                  <CountUp
                    start={0}
                    end={post1Count}
                    duration={3}
                    separator=" "
                    decimals={(data?.top_posts[0]?.unique_subscriber_opens || 0) > 1000 ? 1 : 0}
                    decimal="."
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                        <span>{getSuffix(data?.top_posts[0]?.unique_subscriber_opens || 0)}</span>
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className="font-satoshi-v rewind-text-cyber-ink text-2xl font-bold ml-2 capitalize">
                  Impressions
                </div>
              </div>
            </div>
          </li>
          <li className="flex w-full justify-between items-center rewind-bg-ethereal-white rounded-xl p-6 mb-4 relative h-52">
            {image2Url && (
              <div className="w-[38%] h-full flex justify-center align-center">
                <img
                  src={image2Url}
                  alt={data?.top_posts[1]?.web_title}
                  className="max-w-full max-h-full self-center"
                />
              </div>
            )}
            <div className="w-full pl-5">
              {data?.top_posts[1]?.scheduled_at && (
                <div className="rounded-[0.875rem] bg-white rewind-text-blue text-sm px-2 inline-block">
                  {new Date(data.top_posts[1].scheduled_at).toLocaleDateString('en-US', dateOptions)}
                </div>
              )}
              <div className="font-satoshi-v rewind-text-cyber-ink text-lg font-bold w-full truncate">
                {data?.top_posts[1]?.web_title}
              </div>
              <div className="flex font-bold text-[5.125rem] leading-[3.5rem] font-clash-grotesk-v uppercase items-baseline mt-6">
                <div>
                  <CountUp
                    start={0}
                    end={post2Count}
                    duration={3}
                    separator=" "
                    decimals={(data?.top_posts[1]?.unique_subscriber_opens || 0) > 1000 ? 1 : 0}
                    decimal="."
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                        <span>{getSuffix(data?.top_posts[1]?.unique_subscriber_opens || 0)}</span>
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className="font-satoshi-v rewind-text-cyber-ink text-2xl font-bold ml-2 capitalize">
                  Impressions
                </div>
              </div>
            </div>
          </li>
          <li className="flex w-full justify-between items-center rewind-bg-ethereal-white rounded-xl p-6 relative h-52">
            {image3Url && (
              <div className="w-[38%] h-full flex justify-center align-center">
                <img
                  src={image3Url}
                  alt={data?.top_posts[2]?.web_title}
                  className="max-w-full max-h-full self-center"
                />
              </div>
            )}
            <div className="w-full pl-5">
              {data?.top_posts[2]?.scheduled_at && (
                <div className="rounded-[0.875rem] bg-white rewind-text-blue text-sm px-2 inline-block">
                  {new Date(data.top_posts[2].scheduled_at).toLocaleDateString('en-US', dateOptions)}
                </div>
              )}
              <div className="font-satoshi-v rewind-text-cyber-ink text-lg font-bold w-full truncate">
                {data?.top_posts[2]?.web_title}
              </div>
              <div className="flex font-bold text-[5.125rem] leading-[3.5rem] font-clash-grotesk-v uppercase items-baseline mt-6">
                <div>
                  <CountUp
                    start={0}
                    end={post3Count}
                    duration={3}
                    separator=" "
                    decimals={(data?.top_posts[2]?.unique_subscriber_opens || 0) > 1000 ? 1 : 0}
                    decimal="."
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                        <span>{getSuffix(data?.top_posts[2]?.unique_subscriber_opens || 0)}</span>
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className="font-satoshi-v rewind-text-cyber-ink text-2xl font-bold ml-2 capitalize">
                  Impressions
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default View6;
