import { useQuery } from 'react-query';

import { Image } from '@/interfaces/image';
import { Permissions } from '@/interfaces/permissions';
import api from '@/services/swarm';

export interface CurrentUserPublication {
  id: string;
  name: string;
  logo: Image;
  permissions: Permissions;
}

export interface CurrentUserOrganization {
  id: string;
  name: string;
  publications: CurrentUserPublication[];
}

interface ApiResponse {
  total_publications: number;
  organizations: CurrentUserOrganization[];
}

const useCurrentUserOrganizations = (currentUserId: string, enabled: boolean) => {
  return useQuery<ApiResponse>(
    [currentUserId, 'organizations'],
    () => api.get(`/current_user/organizations`).then((res) => res.data),
    { enabled }
  );
};

export default useCurrentUserOrganizations;
