import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { StripeMigrationPrice } from '@/interfaces/stripe';
import api from '@/services/swarm';

interface ApiResponse {
  prices: StripeMigrationPrice[];
}

const useMissingTargetPrices = (stripeMigrationId?: string, enabled?: boolean) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<ApiResponse>(
    ['stripe_migrations', stripeMigrationId, 'missing_target_prices'],
    () =>
      api
        .get(`/stripe_migrations/${stripeMigrationId}/missing_target_prices`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!stripeMigrationId && enabled,
    }
  );
};

export default useMissingTargetPrices;
