import cx from "classnames";

interface Props {
  className?: string;
}

const ThreadsIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={cx(className)}
    >
      <path
        d="M8.12212 7.09602C10.0004 5.58578 12.7046 6.39069 13.024 8.875C13.3629 11.5105 12.6865 13.6 10.0615 13.6C7.62402 13.6 7.69902 11.5 7.69902 11.5C7.69902 9.25 11.5615 8.95 13.8115 10.075C17.9365 12.7 14.9365 17.5 10.4365 17.5C6.7086 17.5 3.68652 15.625 3.68652 10C3.68652 4.375 6.7086 2.5 10.4365 2.5C13.0675 2.5 15.4402 3.85521 16.3126 6.56564"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThreadsIcon;
