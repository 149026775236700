import { useInfiniteQuery } from 'react-query';

import { WebhookMessageAttempt } from '@/interfaces/webhooks/message_attempt';
import api from '@/services/swarm';

interface ApiResponse {
  message_attempts: WebhookMessageAttempt[];
  has_more: boolean;
  iterator: string;
}

export type MessageAttemptStatusStringified = '' | '0' | '1' | '2' | '3';
type Props = {
  endpointId: string;
  status?: MessageAttemptStatusStringified;
};

export default function useSvixMessageAttempts(publicationId: string, { endpointId, status }: Props) {

  const fetchMessageAttempts = ({ pageParam = '' }) =>
    api
      .get(`/webhooks/endpoints/${endpointId}/svix_messages`, {
        params: {
          publication_id: publicationId,
          iterator: pageParam,
          status,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['publications', publicationId, 'webhooks', 'endpoints', endpointId, 'message_attempts', 'status', status],
    fetchMessageAttempts,
    {
      getNextPageParam: (lastPage) => {
        const { iterator, has_more: hasMore } = lastPage || {};

        if (hasMore) return iterator;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
