import { Link, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';

import Header from '../../../components/Header';
import LoadingBox from '../../../components/LoadingBox';
import { Skeleton, SkeletonLoader } from '../../../components/SkeletonLoader';
import Stack from '../../../components/Stack';
import { usePostTemplate } from '../../../hooks';
import { useTemplateViewContext } from '..';

interface RouteParams {
  templateId: string;
}

const PostTemplate: React.FunctionComponent = () => {
  const { templateId: id } = useParams() as unknown as RouteParams;
  const { data, isLoading, isError } = usePostTemplate(id);

  const { resourceType, onSelect, isCreatingDraft } = useTemplateViewContext();

  return (
    <div>
      <div className="flex mb-4">
        <Link className="text-sm text-gray-500 hover:text-primary-500" to={`/templates/${resourceType}`}>
          <div className="flex items-center">
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            <span>Back to all templates</span>
          </div>
        </Link>
      </div>
      <div className="mb-4">
        <SkeletonLoader
          className="px-0 py-0"
          isLoading={isLoading}
          skeletons={
            <div className="flex justify-between">
              <div className="space-y-6 w-60">
                <div className="space-y-2">
                  <Skeleton className="h-10 rounded" />
                </div>
              </div>
              <div className="space-x-4 flex">
                <div className="space-y-2 w-20">
                  <Skeleton className="h-10 rounded" />
                </div>
                <div className="space-y-2 w-32">
                  <Skeleton className="h-10 rounded" />
                </div>
              </div>
            </div>
          }
        >
          {data && (
            <div className="flex justify-between w-full">
              <Header>{data.name}</Header>

              <Stack gap={2} direction="row">
                <ButtonGroup variant="primary-inverse">
                  <Link to={`/templates/${resourceType}/${id}/edit`}>
                    <Button variant="primary-inverse" type="button">
                      Edit
                    </Button>
                  </Link>
                </ButtonGroup>
                <Button loading={isCreatingDraft} onClick={() => onSelect(id)}>
                  Use this template
                </Button>
              </Stack>
            </div>
          )}
        </SkeletonLoader>
      </div>
      <hr />
      <div className="my-4">
        <LoadingBox isLoading={isLoading} isError={isError}>
          <iframe
            title="preview"
            className="w-full h-full rounded overflow-hidden"
            srcDoc={data?.html}
            style={{
              minHeight: '750px',
            }}
          />
        </LoadingBox>
      </div>
    </div>
  );
};

export default PostTemplate;
