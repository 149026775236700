import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { CustomDomainTypes } from '@/interfaces/custom_domain';
import api from '@/services/swarm';

import {
  CreateCustomDomainsCollectionErrorResponse,
  CreateCustomDomainsCollectionPayload,
  CreateCustomDomainsCollectionSuccessResponse,
} from './types';

interface Props {
  onSuccess?: (customDomainIds: string[], verifiableDomainTypes: CustomDomainTypes[], entriCompatible: boolean) => void;
}

const useCreateCustomDomainsCollection = (publicationId: string, { onSuccess }: Props) => {
  const queryClient = useQueryClient();

  return useMutation<
    CreateCustomDomainsCollectionSuccessResponse,
    CreateCustomDomainsCollectionErrorResponse,
    CreateCustomDomainsCollectionPayload
  >(
    (payload) =>
      api
        .post('/custom_domains/collection', {
          publication_id: publicationId,
          custom_domains: payload,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        const {
          custom_domain_ids: customDomainIds,
          verifiable_domain_types: verifiableDomainTypes,
          entri_compatible: entriCompatible,
        } = data;

        queryClient.invalidateQueries(['custom_domains', publicationId]);

        if (onSuccess) {
          onSuccess(customDomainIds, verifiableDomainTypes, entriCompatible || false);
        }
      },
      onError: (error) => {
        const { errors } = error.response.data;

        if (errors) {
          Object.keys(errors).forEach((key) => {
            toast.error(`${key} ${errors[key][0]}`);
          });
        } else {
          toast.error('Something went wrong. Please try again.');
        }
      },
    }
  );
};

export default useCreateCustomDomainsCollection;
