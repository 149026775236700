import { ArrowPathIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import useRecommendingPublications from '@/hooks/boosts/grow/useRecommendingPublications';
import { BoostPublicationWithBoostAgreement } from '@/interfaces/boosts/grow/boost_publication_with_boost_agreement';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListFilters, useResourceListFilters } from '@/ui/ResourceListFilters';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import RecommendingPublicationtListItem from './RecommendingPublicationtListItem';

interface Props {
  publicationId: string;
}

enum SortingOptions {
  TOTAL_SUBSCRIBER_COUNT = 'total_subscriber_count',
  ACCEPTED_SUBSCRIBER_COUNT = 'accepted_subscriber_count',
  PENDING_SUBSCRIBER_COUNT = 'pending_subscriber_count',
  PENDING_SPEND = 'pending_spend',
  TOTAL_SPEND = 'total_spend',
}

const orderByOptions = [
  { label: 'Total subscribers', value: SortingOptions.TOTAL_SUBSCRIBER_COUNT },
  { label: 'Verified subscribers', value: SortingOptions.ACCEPTED_SUBSCRIBER_COUNT },
  { label: 'Pending subscribers', value: SortingOptions.PENDING_SUBSCRIBER_COUNT },
  { label: 'Pending spend', value: SortingOptions.PENDING_SPEND },
  { label: 'Total spend', value: SortingOptions.TOTAL_SPEND },
];

const RecommendingPublicationsList = ({ publicationId }: Props) => {
  const {
    search,
    setSearch,
    hasFilter,
    resetAllFilters,
    orderBy,
    setOrderBy,
    direction,
    handleClickOrderButton
  } = useResourceListFilters<string, string>({
    defaultFilter: 'all',
    defaultSortOrder: SortingOptions.TOTAL_SUBSCRIBER_COUNT
  });

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, isError, refetch } =
    useRecommendingPublications({
      publicationId,
      search,
      orderBy,
      direction,
    });
  const publications = (data?.pages.flatMap((page) => page.publications) as BoostPublicationWithBoostAgreement[]) || [];
  const hasNoResults = publications.length === 0;
  const totalCount = data?.pages[0]?.pagination?.total || 0;
  const showingCount = publications?.length || 0;

  const title = hasFilter ? `No results found for current filters` : 'There are no Publications Boosting at the moment';
  const primaryActionLabel = hasFilter ? 'Reset Filters' : 'New Offer';
  const onPrimaryActionClick = hasFilter ? resetAllFilters : undefined;
  const primaryActionIcon = hasFilter ? ArrowPathIcon : undefined;

  const filteredPublications = publications.filter((publication) => typeof publication === 'object');

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex justify-end w-full">
        <ResourceListFilters
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          search={search}
          setSearch={setSearch}
          searchPlaceholder="Search Publications"
          sortOrderOptions={orderByOptions}
        />
      </div>
      <ResourceListSearchText showingCount={showingCount} totalCount={totalCount} />
      {hasNoResults && !isLoading && (
        <EmptyCard
          title={title}
          primaryActionIcon={primaryActionIcon}
          primaryActionLabel={primaryActionLabel}
          onPrimaryActionClick={onPrimaryActionClick}
        />
      )}
      {!hasNoResults && (
        <LoadingBox isLoading={isLoading} isError={isError}>
          <Items>
            {(isInViewport) => (
              <>
                <ItemHeaders isSticky={isInViewport}>
                  <ItemHeader>Publication Name</ItemHeader>
                  <ItemHeader>Status</ItemHeader>
                  <ItemHeader>CPA</ItemHeader>
                  <ItemHeader>Open rate %</ItemHeader>
                  <ItemHeader>Acceptance rate %</ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.PENDING_SUBSCRIBER_COUNT}
                    isSorting={orderBy === SortingOptions.PENDING_SUBSCRIBER_COUNT}
                    currentDirection={direction}
                    tooltip="Subscribers currently in the verification process."
                  >
                    Pending subscribers
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.ACCEPTED_SUBSCRIBER_COUNT}
                    isSorting={orderBy === SortingOptions.ACCEPTED_SUBSCRIBER_COUNT}
                    currentDirection={direction}
                    tooltip="Subscribers who successfully completed the verification process."
                  >
                    Verified subscribers
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.TOTAL_SUBSCRIBER_COUNT}
                    isSorting={orderBy === SortingOptions.TOTAL_SUBSCRIBER_COUNT}
                    currentDirection={direction}
                  >
                    Total subscribers
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.PENDING_SPEND}
                    isSorting={orderBy === SortingOptions.PENDING_SPEND}
                    currentDirection={direction}
                    tooltip="Funds tied to subscribers going through the verification process. Any excess funds for unverified subscribers will be returned to your account"
                  >
                    Pending spend
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.TOTAL_SPEND}
                    isSorting={orderBy === SortingOptions.TOTAL_SPEND}
                    currentDirection={direction}
                    tooltip="Funds paid out for verified subscribers"
                  >
                    Total spend
                  </ItemHeader>
                  <ItemHeader isSticky>&nbsp;</ItemHeader>
                </ItemHeaders>

                <ItemsBody>
                  {filteredPublications.map((publication) => (
                    <RecommendingPublicationtListItem
                      key={publication.id}
                      publication={publication}
                      onStatusChange={refetch}
                    />
                  ))}
                </ItemsBody>
              </>
            )}
          </Items>
        </LoadingBox>
      )}
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </div>
  );
};

export default RecommendingPublicationsList;
