import { FormContextProvider } from '../../FormContext';
import MilestoneForm from '../MilestoneForm';

export default function ConfigureNewMilestone() {
  return (
    <FormContextProvider>
      <MilestoneForm />
    </FormContextProvider>
  );
}
