import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import moment from 'moment-mini';

import Checkbox from '@/components/Form/Checkbox';
import DatePicker from '@/components/Form/DatePicker';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

const MonthlyReport = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [summary, setSummary] = useState(false);
  const fetchCsv = async () => {
    const response = await api.get(`ad_network/internal/campaign_performance_opportunities`, {
      params: {
        is_csv: true,
        date: selectedDate,
        is_summary: summary,
      },
    });
    return response.data;
  };

  const downloadCsvMutation = useMutation<any>(fetchCsv, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.csv]));
      const link = document.createElement('a');
      const filename = `export-${summary ? 'summary-' : ''}${moment(selectedDate).format('YYYY-MM')}.csv`;
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.error || 'Something went wrong, please try again');
    },
  });

  const datePickerOptions = {
    enableTime: false,
    altFormat: 'F j, Y',
  };

  return (
    <div className="flow-root">
      <DatePicker
        value={selectedDate}
        maxDate={new Date()}
        onChange={(date) => date && setSelectedDate(date)}
        pickerOptions={datePickerOptions}
        displayTimezone={false}
        className="float-left mr-2"
      />
      <Checkbox
        name="report-summary-on"
        checked={summary}
        onChange={setSummary}
        labelText="Summary"
        className="float-left mx-2 my-2"
      />
      <Button type="button" onClick={() => downloadCsvMutation.mutate()} className="ml-2 float-left">
        Download Monthly Report
      </Button>
    </div>
  );
};

export default MonthlyReport;
