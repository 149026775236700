import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';
import { usePublication } from '../usePublications';

interface Props {
  onSuccess?: () => void;
}

const useResetSiteTheme = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: currentPublication } = usePublication(currentPublicationId);

  return useMutation(
    () =>
      api.patch(`/web_templates/${currentPublication?.web_template_id}/reset_theme`, {
        publication_id: currentPublicationId,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['web_template', currentPublicationId]);
        if (onSuccess) onSuccess();
      },
    }
  );
};

export default useResetSiteTheme;
