import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface CreatedForm {
  id: string;
  title: string;
  description: string;
  status: string;
  publication_id: string;
  created_at: string;
  updated_at: string;
  created_by_user_id: string;
  updated_by_user_id: string;
}

interface ApiResponse {
  form: CreatedForm;
}

interface Props {
  formId: string;
  onSuccess: (result: ApiResponse) => void;
  muteError?: boolean;
}

const useUpdateForm = ({ formId, onSuccess, muteError = false }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<
    ApiResponse,
    unknown,
    { title?: string; description?: string; ctaText?: string; thankYouMessage?: string; status?: string }
  >(
    ({ title, description, ctaText, thankYouMessage, status }) =>
      api
        .patch(`/forms/${formId}`, {
          publication_id: currentPublicationId,
          form: {
            title: title || '',
            description: description || '',
            cta_text: ctaText || '',
            thank_you_message: thankYouMessage || '',
            status: status || '',
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        if (!muteError) {
          toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
        }
      },
    }
  );
};

export default useUpdateForm;
