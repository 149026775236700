import BoostSendsList from '../Shared/BoostSendsList';

interface Props {
  recommendingPublicationId: string;
}

const EmailTab = ({ recommendingPublicationId }: Props) => {
  return (
    <BoostSendsList
      recommendingPublicationId={recommendingPublicationId}
      showCPA
      showSendingMetrics
      showSpendMetrics={false}
      showSearch={false}
    />
  );
};

export default EmailTab;
