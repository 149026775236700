import { useQuery, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Automation } from '../../interfaces/automations/automation';
import api from '../../services/swarm';

export default function useAutomationStep(automationId: string) {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useQuery<Automation>(
    ['automations', 'automation', automationId],
    () =>
      api.get(`/automations/${automationId}`, { params: { publication_id: currentPublicationId } }).then((res) => {
        queryClient.setQueriesData(['automations', 'automation', 'stats', res.data.id], res.data.stats);
        return res.data;
      }),
    {
      keepPreviousData: true,
      staleTime: 30000,
    }
  );
}
