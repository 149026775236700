import SectionRenderer from '../../helpers/SectionRenderer';
import { AttributeSettingProps } from '../../types';

import { ActionSettings } from './ActionsSettings';
import AuthorsActions from './AuthorsActions';
import { BlockTypeSettings } from './BlockTypeSettings';
import ImageActions from './ImageActions';
import PostActions from './PostActions';
import SignupActions from './SignupActions';
import SocialsActions from './SocialsActions';
import TagsActions from './TagsActions';
import { TemplateSettings } from './TemplateSettings';
import TestimonialsActions from './TestimonialsActions';
import TextActions from './TextActions';

export function BlockSettings({
  editor,
  activeNodeResult,
  isDeletable,
  isFooter,
}: AttributeSettingProps & {
  isFooter?: boolean;
}) {
  return (
    <SectionRenderer title="Block" collapsible={false}>
      <ActionSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        isDeletable={isDeletable}
        isFooter={isFooter}
      />
      <BlockTypeSettings editor={editor} />
      <TemplateSettings editor={editor} activeNodeResult={activeNodeResult} />

      <PostActions editor={editor} activeNodeResult={activeNodeResult} />
      <ImageActions editor={editor} activeNodeResult={activeNodeResult} />
      <TestimonialsActions editor={editor} activeNodeResult={activeNodeResult} />
      <TagsActions editor={editor} activeNodeResult={activeNodeResult} />
      <TextActions editor={editor} activeNodeResult={activeNodeResult} />
      <AuthorsActions editor={editor} activeNodeResult={activeNodeResult} />
      <SocialsActions editor={editor} activeNodeResult={activeNodeResult} />
      <SignupActions editor={editor} activeNodeResult={activeNodeResult} />
    </SectionRenderer>
  );
}
