import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type TabType = 'styles' | 'layout';

interface ReturnProps {
  isStylesTab: boolean;
  isLayoutTab: boolean;
  handleTabChange: (tab: TabType) => void;
}

const useTabNavigator = (): ReturnProps => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentTab = searchParams.get('tab') || 'styles';

  const isWebBuilderPage = pathname === '/website_builder' || pathname === '/website_builder/';

  const handleTabChange = (tab: TabType) => {
    searchParams.set('tab', tab);
    const currentParams = searchParams.toString();
    navigate(`/website_builder?${currentParams}`);
  };

  return {
    isStylesTab: isWebBuilderPage && currentTab === 'styles',
    isLayoutTab: isWebBuilderPage && currentTab === 'layout',
    handleTabChange,
  };
};

export default useTabNavigator;
