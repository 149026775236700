export const tones = [
  { name: 'academic-tone', label: 'Academic tone', value: 'ACADEMIC' },
  { name: 'confident-tone', label: 'Confident tone', value: 'CONFIDENT' },
  { name: 'creative-tone', label: 'Creative tone', value: 'CREATIVE' },
  { name: 'excited-tone', label: 'Excited tone', value: 'EXCITED' },
  { name: 'formal-tone', label: 'Formal tone', value: 'FORMAL' },
  { name: 'friendly-tone', label: 'Friendly tone', value: 'FRIENDLY' },
  { name: 'funny-tone', label: 'Funny tone', value: 'FUNNY' },
];

export default tones;
