import { useSearchParams } from 'react-router-dom';

import { useWebsitePageRouteGetter } from '@/context/website-context';

import Callout from '../UI/Callout';
import Divider from '../UI/Divider';
import { Text } from '../UI/Text';

import { AddDropdown } from './AddDropdown';

interface Props {
  title?: string;
  description?: string;
  callout?: {
    title: string;
    description: string;
  };
  hasDivider?: boolean;
  showDropdown?: boolean;
}

export const TopBar = ({ title, description, hasDivider, showDropdown = false, callout }: Props) => {
  const [searchParams] = useSearchParams();
  const path = searchParams.get('path');
  const pageRouteGetter = useWebsitePageRouteGetter();
  const page = path ? pageRouteGetter?.getPageFromPath(path) : null;

  const pageName = path ? page?.draft_page_version?.name : 'Recents';
  const pageTitle = title || pageName;

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex flex-col space-y-2 max-w-[620px]">
          <div className="flex-1">
            <Text size="xl" weight="semibold" variant="primary" as="h4">
              {pageTitle}
            </Text>
          </div>
          {description && (
            <Text size="sm" weight="medium" variant="secondary" as="p">
              {description}
            </Text>
          )}
        </div>
        {showDropdown && (
          <div className="flex items-center gap-4">
            <AddDropdown />
          </div>
        )}
      </div>
      {callout && <Callout title={callout.title}>{callout.description}</Callout>}
      {hasDivider && <Divider className="!my-0 !mt-4" />}
    </>
  );
};
