import { useMemo } from 'react';

import { Setting, Settings } from '@/interfaces/setting';

import useCurrentPublicationId from './usePublications/useCurrentPublicationId';
import usePublicationSettings from './usePublications/usePublicationSettings';

const useSetting = <T extends Setting>({ setting, defaultValue }: { setting: T; defaultValue?: Settings[T] }) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(currentPublicationId);

  const value = useMemo(() => {
    return settings?.[setting] ?? defaultValue;
  }, [settings, setting, defaultValue]);

  return value;
};

export default useSetting;
