import { useParams } from 'react-router-dom';

import useWorkspaceView from '@/hooks/useAdNetwork/internal/useWorkspaceView';
import useWorkspaceViewUpdate from '@/hooks/useAdNetwork/internal/useWorkspaceViewUpdate';

import LoadingBox from '../../../../components/LoadingBox';
import { PageHeading } from '../../_components/PageHeading';
import { Form } from '../_components/Form';

export default function Edit() {
  const { workspace_view_id: id } = useParams<'workspace_view_id'>() as { workspace_view_id: string };
  const { data, isLoading, isError } = useWorkspaceView({ id });
  const onSubmitMutation = useWorkspaceViewUpdate({ id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{data?.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Edit</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <LoadingBox isLoading={isLoading} isError={isError} height="100vh">
        {data && <Form onSubmitMutation={onSubmitMutation} workspaceView={data} />}
      </LoadingBox>
    </>
  );
}
