import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useUpdateEmailMessage } from '@/hooks';

const useUpdateWelcomeEmail = (publicationId: string, emailMessageId: string) => {
  const queryClient = useQueryClient();

  return useUpdateEmailMessage(publicationId, {
    emailMessageId,
    onSuccess: () => {
      queryClient.invalidateQueries(['welcome_email', publicationId], { exact: true });
      queryClient.invalidateQueries(['email_messages', 'preview', emailMessageId], { exact: true });
      toast.success('Welcome Email saved');
    },
    onError: (err: any) => toast.error(err?.response?.data?.error || 'Something went wrong, please try again'),
  });
};

export default useUpdateWelcomeEmail;
