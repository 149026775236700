import { Extension } from '@tiptap/core';

export const Extras = Extension.create({
  name: 'extras',

  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const { $from, to } = editor.state.selection;

        if ($from.pos !== to) {
          return false;
        }

        const grandParent = $from.node($from.depth - 1);

        if (grandParent?.type.name !== 'listItem') {
          return false;
        }

        const grandParentStart = $from.start($from.depth - 1);
        const grandParentIndex = $from.index($from.depth - 2);

        if (grandParentIndex !== 0 || $from.pos - grandParentStart !== 1) {
          return false;
        }

        return editor.commands.liftListItem('listItem');
      },
    };
  },
});
