import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import { Card } from '@/ui/Card';

import PageContainer from '../../_components/PageContainer';
import { usePublicationSettings } from '../context';

import { ExportDataProvider } from './context';
import ExportAction from './ExportAction';
import HistoricalExports from './HistoricalExports';

const PublicationExportDataSettings: React.FC = () => {
  const { currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);

  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId}>
          <ExportDataProvider publicationId={currentPublicationId}>
            <Card id="quick-version" showCopyIdButton>
              <ExportAction
                title="Export Subscribers (Quick Version)"
                description="Send a list of your subscribers to your email that includes the subscribers email, status, and tier sorted by most recently subscribed"
                type="basic_subscriber"
              />
            </Card>

            <Card id="full-version" showCopyIdButton>
              <ExportAction
                title="Export Subscribers (Full Version)"
                description="Send a list of your subscribers to your email that includes the subscribers email, status, tier, custom fields, stats, and sorted by most recently subscribed. Please note this takes longer to export than the quick version"
                type="subscribers"
              />
            </Card>

            <Card id="posts" showCopyIdButton>
              <ExportAction
                title="Export Posts"
                description="Send a list of your posts to your email that includes your content broken out with metadata"
                type="posts"
              />
            </Card>

            <Card id="history" showCopyIdButton>
              <HistoricalExports />
            </Card>
          </ExportDataProvider>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default PublicationExportDataSettings;
