import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { ExternalEmbed, ExternalEmbedMutatorParams } from '../../interfaces/external_embed';
import api from '../../services/swarm';

const useUpdateExternalEmbed = ({ onSuccess }: ExternalEmbedMutatorParams) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    (externalEmbed: ExternalEmbed) =>
      api.put(`/publications/${currentPublicationId}/external_embeds/${externalEmbed.id}`, {
        external_embed: externalEmbed,
      }),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response.data.external_embed);
        queryClient.invalidateQueries(['externalEmbeds', currentPublicationId]);

        toast.success('Subscribe form updated successfully!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Could not update subscribe form.');
      },
    }
  );
};

export default useUpdateExternalEmbed;
