import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import useCampaignOpportunityGroup from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroup';
import useCampaignOpportunityGroupsUpdate from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroupsUpdate';
import {
  AdNetworkCampaignOpportunityGroup,
  CampaignOpportunityGroupAdGroupOption,
} from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import { Button } from '@/ui/Button';

import { LoadingListPage } from '../../_components/LoadingListPage';
import { PageHeading } from '../../_components/PageHeading';
import { Tabs } from '../../_components/Tabs';
import AdGroupSlideOverForm from '../_components/AdGroupSlideoverForm';

interface Props {
  campaignOpportunityGroup: AdNetworkCampaignOpportunityGroup;
  refetch: () => void;
}

const CampaignOpportunityGroupSelect = ({ campaignOpportunityGroup, refetch }: Props) => {
  const adGroups = campaignOpportunityGroup.ad_group_options;
  const [isAdGroupSlideOverOpen, setIsAdGroupSlideOverOpen] = useState(false);
  const { mutateAsync: handleChange } = useCampaignOpportunityGroupsUpdate({ id: campaignOpportunityGroup.id });

  const onSelect = (adGroup: CampaignOpportunityGroupAdGroupOption) => {
    const formData = new FormData();

    formData.append('campaign_opportunity_group[ad_group_kind]', adGroup.kind);
    if (!adGroup.managed) {
      formData.append('campaign_opportunity_group[ad_group_id]', adGroup.id);
    }

    toast.promise(
      handleChange(formData).then(() => refetch()),
      {
        loading: 'Saving...',
        success: 'Saved!',
        error: 'Failed to save.',
      }
    );
  };

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{campaignOpportunityGroup.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to=".">Select advertisements</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="border-b border-gray-100 p-4 flex space-x-2 items-center justify-between">
        <div>
          <Tabs>
            <Tabs.Tab to="../provision">Find publications</Tabs.Tab>
            <Tabs.Tab to="">Select advertisements</Tabs.Tab>
            <Tabs.Tab to="../review">Review &amp; provision</Tabs.Tab>
          </Tabs>
        </div>
      </div>
      <div>
        <div className="p-4">
          <div className="max-w-xl mx-auto w-full space-y-6">
            <form className="divide-y">
              <div className="space-y-6 pt-6">
                <RadioGroup onChange={onSelect} value={adGroups.find((adGroup) => adGroup.selected)}>
                  <RadioGroup.Label className="text-base font-semibold leading-7 text-gray-900">
                    Select advertisements
                  </RadioGroup.Label>

                  <div className="mt-4 grid grid-cols-1 gap-y-6">
                    {adGroups.map((adGroup) => (
                      <RadioGroup.Option
                        key={adGroup.id}
                        value={adGroup}
                        className="border-gray-300 relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                      >
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label as="span" className="block space-x-2 text-sm font-medium text-gray-900">
                                <span>{adGroup.name}</span>
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-sm text-gray-500"
                              >
                                {adGroup.description}
                              </RadioGroup.Description>
                              <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                {adGroup.units.primary} primary, {adGroup.units.secondary} secondary
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={cx(!adGroup.selected ? 'invisible' : '', 'h-5 w-5 text-primary-600')}
                            aria-hidden="true"
                          />
                          <span
                            className={cx(
                              adGroup.selected ? 'border-primary-600' : 'border-transparent',
                              'pointer-events-none absolute -inset-px rounded-lg border-2'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
            </form>
            <div className="relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-500">Or</span>
              </div>
            </div>
            <div className="flex">
              <AdGroupSlideOverForm
                isOpen={isAdGroupSlideOverOpen}
                onClose={() => setIsAdGroupSlideOverOpen(false)}
                campaignId={campaignOpportunityGroup.campaign_id}
                refetch={refetch}
              />
              <Button type="button" variant="primary-inverse" block onClick={() => setIsAdGroupSlideOverOpen(true)}>
                Create new group
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Loader() {
  const { campaign_opportunity_group_id: id } = useParams<'campaign_opportunity_group_id'>() as {
    campaign_opportunity_group_id: string;
  };

  const { data, isSuccess, isLoading, isError, refetch } = useCampaignOpportunityGroup({ id });

  if (!isSuccess || isLoading) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  return <CampaignOpportunityGroupSelect campaignOpportunityGroup={data} refetch={refetch} />;
}
