import { HeaderType } from './types';

export const EDITING_BREADCRUMB_TEXT_BY_TYPE: Record<HeaderType, string> = {
  template: "You're editing a template",
  navbar: "You're editing a component",
  footer: "You're editing a component",
  signup_modal: "You're editing a global element",
  recommendations_modal: "You're editing a global element",
  login_modal: "You're editing a global element",
  'navbar-template': "You're editing a component within a template project",
  'footer-template': "You're editing a component within a template project",
};

export const EDITING_TOOLTIP_BREADCRUMB_TEXT_BY_TYPE: Record<HeaderType, string> = {
  template: 'You can use the updated changes the next time you insert this template',
  navbar: 'Changes here will apply to all instances of this component',
  footer: 'Changes here will apply to all instances of this component',
  signup_modal: 'Changes here will apply to all pages',
  recommendations_modal: 'Changes here will apply to all pages',
  login_modal: 'Changes here will apply to all pages',
  'navbar-template': 'Changes here will apply only to navbar of this template project',
  'footer-template': 'Changes here will apply only to footer of this template project',
};

export const TYPE_NAMES: Record<HeaderType, string> = {
  navbar: 'Navigation',
  footer: 'Footer',
  signup_modal: 'Signup Modal',
  recommendations_modal: 'Recommendations Modal',
  login_modal: 'Login Modal',
  template: 'Template',
  'navbar-template': 'Navigation',
  'footer-template': 'Footer',
};
