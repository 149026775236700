import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { useImports } from '@/hooks';
import { Publication } from '@/interfaces/publication';

interface Props {
  publication: Publication;
}

const ImportLimit = ({ publication }: Props) => {
  const useImportsQuery = useImports(publication.id);
  const { data } = useImportsQuery;
  const formatter = new Intl.NumberFormat('en-US');
  const importLimit = data?.pages[0]?.import_limit || 0;
  const importLimitRemaining = data?.pages[0]?.import_limit_remaining || 0;
  const importsUtilized = importLimit - importLimitRemaining;
  const importLimitStat = `${formatter.format(importsUtilized)}/${formatter.format(importLimit)}`;

  return (
    <div className="flex flex-col gap-y-6 bg-surface-50 border border-surface-200 rounded-md p-4">
      <TypographyStack>
        <Typography token="font-medium/text/sm">Imported subscribers</Typography>
        <Typography token="font-normal/text/sm" colorWeight="600">
          This limit counts across all of your publications and is not the same as the subscriber limit associated with
          your plan.{' '}
          <a
            href="https://support.beehiiv.com/hc/en-us/articles/12231316510871-Why-Do-I-Have-An-Import-Limit-"
            rel="noopener noreferrer nofollow"
            target="blank"
            className="text-primary-500 font-medium hover:text-primary-600"
          >
            Read more about import limits in our Knowledge Base
          </a>
        </Typography>
      </TypographyStack>
      <TypographyRow gap="3" align="end">
        <Typography token="font-semibold/text/4xl">{importLimitStat}</Typography>
        <Typography token="font-normal/text/sm" colorWeight="600" className="mb-1">
          subscribers
        </Typography>
      </TypographyRow>
    </div>
  );
};

export default ImportLimit;
