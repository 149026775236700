import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const Styled = {
  SelectionContainer: styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem;
  `,
  DataContainer: styled.div`
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    gap: 2rem;
    background-color: white;
    color: #222222;

    img {
      align-self: flex-start;
      border: 1px solid ${colors.black[7]};
      border-radius: 4px;
      width: 7.5rem;
    }
  `,
  TextContainer: styled.div``,
  SponsoredBanner: styled.div`
    font-weight: 700;
    color: #bbbbbb;
  `,
  Headline: styled.h3`
    &&& {
      margin: 0 0 0.5rem;
    }
  `,
  Paragraph: styled.div`
    margin-bottom: 1rem;
  `,
  Button: styled.a`
    // … see editor styles
    // client/src/components/TiptapEditor/styled.tsx
  `,
};

export default Styled;
