import { useEffect, useState } from 'react';
import { UseMutationResult } from 'react-query';
import moment from 'moment-mini';

import { Checkbox, Input, MultiSelect } from '@/components/Form';
import CurrencyInput from '@/components/Form/CurrencyInput';
import { useCurrentTimeZone } from '@/hooks';
import useTags from '@/hooks/useTags';
import {
  AdNetworkCampaign,
  AdNetworkCampaignAge,
  AdNetworkCampaignAgeOptions,
  AdNetworkCampaignGender,
  AdNetworkCampaignGenderOptions,
  AdNetworkCampaignGeographicalRequirement,
  AdNetworkCampaignGeographicalRequirementOptions,
  AdNetworkCampaignGoal,
  AdNetworkCampaignGoalOptions,
  AdNetworkCampaignHouseholdIncome,
  AdNetworkCampaignHouseholdIncomeOptions,
  AdNetworkCampaignPayoutMethod,
  AdNetworkCampaignPayoutMethodOptions,
  AdNetworkCampaignPromotedItem,
  AdNetworkCampaignPromotedItemOptions,
} from '@/interfaces/ad_network/internal/campaign';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

interface FormProps {
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  campaign?: AdNetworkCampaign;
}

const numToFmt = (num: number) => (Number.isNaN(num) ? '' : num.toLocaleString());

const Form = ({ onSubmitMutation, campaign }: FormProps) => {
  const { mutateAsync, isLoading } = onSubmitMutation;
  const [name, setName] = useState(campaign?.name || '');
  const [internalName, setInternalName] = useState(campaign?.internal_name || '');
  const [defaultMessageToPublisher, setDefaultMessageToPublisher] = useState(
    campaign?.default_message_to_publisher || ''
  );
  const [payoutMethod, setPayoutMethod] = useState(campaign?.payout_method || AdNetworkCampaignPayoutMethod.AUTO);
  const [clicksGoal, setClicksGoal] = useState(campaign?.clicks_goal || 0);
  const [costPerClickCents, setCostPerClickCents] = useState(campaign?.cost_per_click_cents || 0);
  const [costPerAcquisitionCents, setCostPerAcquisitionCents] = useState(campaign?.cost_per_acquisition_cents || 0);
  const [totalSpendCents, setTotalSpendCents] = useState(campaign?.total_spend_cents || 0);
  const [windowStartDate, setWindowStartDate] = useState(
    campaign?.window_start_date ? moment.utc(campaign.window_start_date).format('YYYY-MM-DDTHH:mm') : ''
  );
  const [windowEndDate, setWindowEndDate] = useState(
    campaign?.window_end_date ? moment.utc(campaign.window_end_date).format('YYYY-MM-DDTHH:mm') : ''
  );
  const [windowAlwaysOn, setWindowAlwaysOn] = useState(campaign?.window_always_on || false);
  const [ages, setAges] = useState(campaign?.ages || []);
  const [gender, setGender] = useState(campaign?.gender);
  const [householdIncomes, setHouseholdIncomes] = useState(campaign?.household_incomes || []);
  const [otherNewsletters, setOtherNewsletters] = useState(campaign?.other_newsletters || '');
  const [promotedItem, setPromotedItem] = useState(campaign?.promoted_item || '');
  const [promotedItemOther, setPromotedItemOther] = useState(campaign?.promoted_item_other || '');
  const [goal, setGoal] = useState(campaign?.goal || '');
  const [goalOther, setGoalOther] = useState(campaign?.goal_other || '');
  const [tags, setTags] = useState(campaign?.tags || []);
  const [geographicalRequirements, setGeographicalRequirements] = useState(campaign?.geographical_requirements || []);
  const [geographicalRequirementsOther, setGeographicalRequirementsOther] = useState(
    campaign?.geographical_requirements_other || ''
  );
  const timeZone = useCurrentTimeZone();
  const { data: tagData, isFetched: tagsAreFetched } = useTags();

  useEffect(() => {
    const calcClicks = totalSpendCents && totalSpendCents > 0 ? Math.ceil(totalSpendCents / costPerClickCents) : 0;
    setClicksGoal(calcClicks);
  }, [totalSpendCents, costPerClickCents]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    formData.set('campaign[name]', name);
    formData.set('campaign[internal_name]', internalName);
    formData.set('campaign[default_message_to_publisher]', defaultMessageToPublisher);
    formData.set('campaign[clicks_goal]', String(clicksGoal));
    formData.set('campaign[cost_per_click_cents]', String(costPerClickCents));
    formData.set('campaign[total_spend_cents]', String(totalSpendCents));
    formData.set('campaign[window_start_date]', String(windowStartDate));
    formData.set('campaign[window_end_date]', String(windowEndDate));
    formData.set('campaign[payout_method]', String(payoutMethod));
    formData.set('campaign[window_always_on]', String(windowAlwaysOn));
    formData.set('campaign[cost_per_acquisition_cents]', String(costPerAcquisitionCents));
    formData.set('campaign[other_newsletters]', String(otherNewsletters));
    formData.set('campaign[promoted_item]', String(promotedItem));
    formData.set('campaign[promoted_item_other]', String(promotedItemOther));
    formData.set('campaign[goal]', String(goal));
    formData.set('campaign[goal_other]', String(goalOther));
    formData.set('campaign[geographical_requirements_other]', String(geographicalRequirementsOther));

    if (ages) {
      ages.forEach((age) => {
        formData.append('campaign[ages][]', age);
      });
    }

    if (gender) formData.set('campaign[gender]', String(gender));

    if (householdIncomes) {
      householdIncomes.forEach((householdIncome) => {
        formData.append('campaign[household_incomes][]', householdIncome);
      });
    }

    if (tags) {
      tags.forEach((tag) => {
        formData.append('campaign[tags][]', tag);
      });
    }

    if (geographicalRequirements) {
      geographicalRequirements.forEach((geographicalRequirement) => {
        formData.append('campaign[geographical_requirements][]', geographicalRequirement);
      });
    }

    mutateAsync(formData);
  };

  const handleSetStartDate = (date: string) => {
    setWindowStartDate(moment.utc(date).startOf('day').toISOString());
  };

  const handleSetEndDate = (date: string) => {
    setWindowEndDate(date ? moment.utc(date).endOf('day').toISOString() : '');
  };

  return (
    <div className="p-4">
      <div className="max-w-xl mx-auto w-full space-y-4">
        <form className="divide-y" onSubmit={onSubmit}>
          <div className="space-y-6 pb-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
              <p className="text-sm text-gray-500">
                Give your campaign a descriptive name to help you identify it later.
              </p>
            </div>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="campaign[name]"
              labelText="Name"
              type="text"
              helperText={`A public identifier for the campaign (e.g. "Got Milk? - Q${moment()
                .add(1, 'quarter')
                .quarter()}")`}
              required
            />
            <Input
              value={internalName}
              onChange={(e) => setInternalName(e.target.value)}
              name="campaign[internal_name]"
              labelText="Internal Name"
              type="text"
              helperText={`A private identifier for the campaign (e.g. "Got Milk? - Q${moment()
                .add(1, 'quarter')
                .quarter()}")`}
            />
            <Input
              value={defaultMessageToPublisher}
              onChange={(e) => setDefaultMessageToPublisher(e.target.value)}
              name="campaign[default_message_to_publisher]"
              labelText="Message to all publishers"
              type="text"
              helperText="A public message included with every ad opportunity for this campaign."
            />
            <Dropdown
              name="campaign[payout_method]"
              labelText="Payout Method"
              value={payoutMethod}
              onSelect={(_name, value) => {
                setPayoutMethod(value as AdNetworkCampaignPayoutMethod);
              }}
              options={AdNetworkCampaignPayoutMethodOptions}
              helperText="How payments will be made for this campaign. Manual payouts will not receive stripe payment requests or appear in the disbursements list"
              required
            />
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Timeframe</h2>
              <p className="text-sm text-gray-500">
                Specify the timeframe for this campaign. Advertisements will only be allowed to run within this window.
              </p>
            </div>
            <Input
              value={moment.utc(windowStartDate).format('YYYY-MM-DD')}
              onChange={(e) => handleSetStartDate(e.target.value)}
              name="campaign[window_start_date]"
              labelText="Start Date"
              type="date"
              required
              helperText={`The earliest point that an advertisement can be run as part of this campaign. (Start of day, ${timeZone})`}
            />
            <Input
              value={moment.utc(windowEndDate).format('YYYY-MM-DD')}
              onChange={(e) => handleSetEndDate(e.target.value)}
              name="campaign[window_end_date]"
              labelText="End Date"
              type="date"
              required={!windowAlwaysOn}
              disabled={windowAlwaysOn}
              helperText={`The latest point that an advertisement can be run as part of this campaign. (End of day, ${timeZone}))`}
            />
            <Checkbox
              name="campaign[window_always_on]"
              labelText="Always On (no end date)"
              checked={windowAlwaysOn}
              onChange={() => {
                setWindowAlwaysOn(!windowAlwaysOn);
                if (!windowAlwaysOn) handleSetEndDate('');
              }}
              helperText="Check this box to remove requirement for an end date, meaning that the campaign is always available."
            />
          </div>
          <div className="space-y-6 pt-6 pb-12">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Goals</h2>
              <p className="text-sm text-gray-500">Specify the goals for this campaign.</p>
            </div>
            <div>
              <h3 className="text-sm font-semibold leading-5 text-gray-900">Total Clicks:</h3>
              <p className="text-sm text-gray-700">{numToFmt(clicksGoal)}</p>
              <p className="text-xs text-gray-500 mt-1">Total clicks for this campaign based on CPC and Total Spend.</p>
            </div>
            <CurrencyInput
              value={totalSpendCents}
              onChange={(val) => setTotalSpendCents(val || 0)}
              name="campaign[total_spend_cents]"
              labelText="Total Spend (Budget)"
              helperText="The total budget for this campaign."
              required
            />
            <CurrencyInput
              value={costPerClickCents}
              onChange={(val) => setCostPerClickCents(val || 0)}
              name="campaign[cost_per_click_cents]"
              labelText="CPC"
              helperText="The amount we charge the advertiser per click."
              required
            />
            <CurrencyInput
              value={costPerAcquisitionCents}
              onChange={(val) => setCostPerAcquisitionCents(val || 0)}
              name="campaign[cost_per_acquisition_cents]"
              labelText="Target CPA"
              helperText="The amount we charge the advertiser per acquisition."
            />
          </div>
          <div className="space-y-6 pt-6 pb-12">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Campaign details</h2>
              <p className="text-sm text-gray-500">Specify details for this campaign.</p>
            </div>
            <Input
              value={otherNewsletters}
              onChange={(e) => setOtherNewsletters(e.target.value)}
              name="campaign[other_newsletters]"
              labelText="Newsletters that have worked well for this advertiser"
              type="text"
            />
            <Dropdown
              name="campaign[promoted_item]"
              labelText="What is the campaign promoting?"
              value={promotedItem}
              onSelect={(_name, value) => {
                setPromotedItem(value as AdNetworkCampaignPromotedItem);
              }}
              options={AdNetworkCampaignPromotedItemOptions}
            />
            <Input
              value={promotedItemOther}
              onChange={(e) => setPromotedItemOther(e.target.value)}
              name="campaign[promoted_item_other]"
              labelText="Promoted item details"
              helperText="Provide details if Other is selected, or add additional details about the promoted item"
              type="text"
            />
            <Dropdown
              name="campaign[goal]"
              labelText="What is the campaign goal?"
              value={goal}
              onSelect={(_name, value) => {
                setGoal(value as AdNetworkCampaignGoal);
              }}
              options={AdNetworkCampaignGoalOptions}
            />
            <Input
              value={goalOther}
              onChange={(e) => setGoalOther(e.target.value)}
              name="campaign[goal_other]"
              labelText="Campaign goal details"
              helperText="Provide details if Other is selected, or add additional details about the campaign goal"
              type="text"
            />
            {tagsAreFetched && tagData && (
              <MultiSelect
                inputName="tags"
                label="Category alignment (choose multiple)"
                currentSelections={tags}
                onChange={(values) => {
                  setTags(values as string[]);
                }}
                options={tagData.tags.map((tag) => [tag.id.toString(), tag.name])}
                isMoreShowing={false}
              />
            )}
          </div>
          <div className="space-y-6 pt-6 pb-12">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Target audience</h2>
              <p className="text-sm text-gray-500">Specify the target audience for this campaign.</p>
            </div>
            <MultiSelect
              inputName="ages"
              label="Age (choose multiple)"
              currentSelections={ages}
              onChange={(values) => {
                setAges(values as AdNetworkCampaignAge[]);
              }}
              options={AdNetworkCampaignAgeOptions.map((option) => [option.value, option.label])}
            />
            <Dropdown
              name="campaign[gender]"
              labelText="Gender"
              value={gender}
              onSelect={(_name, value) => {
                setGender(value as AdNetworkCampaignGender);
              }}
              options={AdNetworkCampaignGenderOptions}
            />
            <MultiSelect
              inputName="household_incomes"
              label="Household income (choose multiple)"
              currentSelections={householdIncomes}
              onChange={(values) => {
                setHouseholdIncomes(values as AdNetworkCampaignHouseholdIncome[]);
              }}
              options={AdNetworkCampaignHouseholdIncomeOptions.map((option) => [option.value, option.label])}
            />
            <MultiSelect
              inputName="geographical_requirements"
              label="Geographical Requirements (choose multiple)"
              currentSelections={geographicalRequirements}
              onChange={(values) => {
                setGeographicalRequirements(values as AdNetworkCampaignGeographicalRequirement[]);
              }}
              options={AdNetworkCampaignGeographicalRequirementOptions.map((option) => [option.value, option.label])}
            />
            <Input
              value={geographicalRequirementsOther}
              onChange={(e) => setGeographicalRequirementsOther(e.target.value)}
              name="campaign[geographical_requirements_other]"
              labelText="Geographical Requirement details"
              helperText="Provide details if Other is selected, or add additional details about the geographical requirements"
              type="text"
            />
          </div>
          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Form;
