import BoostAgreementsList from '../Dashboard/components/BoostAgreementsList';

interface Props {
  boostedPublicationId: string;
}

const WebTab = ({ boostedPublicationId }: Props) => {
  return (
    <BoostAgreementsList
      boostedPublicationId={boostedPublicationId}
      showCPA
      showSearch={false}
      isBoostAffiliate={false}
    />
  );
};

export default WebTab;
