import { useUpdateWebTemplate } from '@/hooks';
import { WebTheme } from '@/interfaces/web_template';
import FontPicker from '@/ui/Settings/FontPicker';

import { HOME_PAGE_HEADER_OPTIONS, RADIUS_OPTIONS } from '../../_types/options';
import { usePageContext } from '../Context/PageContext';
import ColorInput from '../Form/ColorInput';
import SelectInput from '../Form/SelectInput';
import SwitchInput from '../Form/SwitchInput';
import GroupHeading from '../Helpers/GroupHeading';
import PropertiesGrouping from '../Helpers/PropertiesGrouping';
import { PostsSectionIcon, SectionGroupingSlideOut } from '../Helpers/SectionGroupingSlideOut';
import SwitchHelper from '../Helpers/SwitchHelper';

const PostsSection = () => {
  const { handleSaved, defaultColors, fontOptions, webTemplate } = usePageContext();

  const webTemplateMutation = useUpdateWebTemplate(() => handleSaved());
  const webTheme: WebTheme | undefined = webTemplate?.web_theme_preview;
  const webThemeWithDefaults: WebTheme | undefined = webTemplate?.web_theme_preview_with_defaults;

  const handleUpdateWebTheme = (key: string, value: string | boolean) => {
    webTemplateMutation.mutate({
      web_theme_preview: {
        ...(webTheme as WebTheme),
        [key]: value,
      },
    });
  };

  return (
    <SectionGroupingSlideOut
      title="Post Styles"
      sectionIcon={<PostsSectionIcon />}
      highlightProps={{
        sectionId: 'post-styles',
      }}
    >
      <GroupHeading title="Post Page" description="Set the background and text color for your post pages.">
        <PropertiesGrouping
          label="Posts"
          indent
          exclamationNote="(1) We currently don't offer live previews for post page changes. (2) We cache posts heavily for performance benefits and it might take 30 minutes or more to see your theme updates reflected on your site. (3) Be sure to hit publish to promote your changes live."
        >
          <ColorInput
            label="Background Color"
            color={webThemeWithDefaults?.post_page_background_color}
            resettable={{
              label: 'Factory Default',
              resetValue: '#FFFFFF',
              check: webTheme?.post_page_background_color !== '#FFFFFF',
            }}
            onSave={(value: string) => handleUpdateWebTheme('post_page_background_color', value)}
            defaultColors={defaultColors}
          />

          <ColorInput
            label="Text Color"
            color={webThemeWithDefaults?.post_page_text_color}
            resettable={{
              label: 'Factory Default',
              resetValue: '#030712',
              check: webTheme?.post_page_text_color !== '#030712',
            }}
            onSave={(value: string) => handleUpdateWebTheme('post_page_text_color', value)}
            defaultColors={defaultColors}
          />
        </PropertiesGrouping>
      </GroupHeading>
      <GroupHeading
        title="Home Page Header Style"
        description="Customize the header style displayed on the home page of your site."
      >
        <SelectInput
          label="Header Type"
          value={webThemeWithDefaults?.posts_home_page_header_style || ''}
          options={HOME_PAGE_HEADER_OPTIONS}
          onSave={(value: string) => {
            handleUpdateWebTheme('posts_home_page_header_style', value);
          }}
        />
      </GroupHeading>

      <GroupHeading title="Post Cards" description="Customize the design of the cards users see to click into posts.">
        <PropertiesGrouping label="Card" indent>
          <SwitchHelper
            label="Background Fill Enabled"
            name="post-background-fill-enabled"
            value={webThemeWithDefaults?.post_background_fill_enabled || false}
            onSave={(value: boolean) => {
              handleUpdateWebTheme('post_background_fill_enabled', value);
            }}
          >
            <ColorInput
              label="Background Color"
              color={webThemeWithDefaults?.post_background_color}
              resettable={{
                label: 'Global Background Color',
                resetValue: null,
                check: webTheme?.post_background_color !== null,
              }}
              onSave={(value: string) => handleUpdateWebTheme('post_background_color', value)}
              defaultColors={defaultColors}
            />
            <ColorInput
              label="Border Color"
              color={webThemeWithDefaults?.post_border_color}
              resettable={{
                label: 'Global Border Color',
                resetValue: null,
                check: webTheme?.post_border_color !== null,
              }}
              onSave={(value: string) => handleUpdateWebTheme('post_border_color', value)}
              defaultColors={defaultColors}
            />
          </SwitchHelper>

          <SelectInput
            label="Radius"
            value={webThemeWithDefaults?.post_border_radius || ''}
            options={RADIUS_OPTIONS}
            onSave={(value: string) => {
              handleUpdateWebTheme('post_border_radius', value);
            }}
          />
        </PropertiesGrouping>

        <PropertiesGrouping label="Content Tags" indent>
          <SwitchHelper
            label="Enabled"
            name="post-content-tags-enabled"
            value={webThemeWithDefaults?.post_content_tags_enabled || false}
            onSave={(value: boolean) => {
              handleUpdateWebTheme('post_content_tags_enabled', value);
            }}
          >
            <ColorInput
              label="Background Color"
              color={webThemeWithDefaults?.post_content_tags_background_color}
              resettable={{
                label: 'Factory Default',
                resetValue: '#082F49',
                check: webTheme?.post_content_tags_background_color !== '#082F49',
              }}
              onSave={(value: string) => handleUpdateWebTheme('post_content_tags_background_color', value)}
              defaultColors={defaultColors}
            />
            <ColorInput
              label="Text Color"
              color={webThemeWithDefaults?.post_content_tags_text_color}
              resettable={{
                label: 'Factory Default',
                resetValue: '#F9FAFB',
                check: webTheme?.post_content_tags_text_color !== '#F9FAFB',
              }}
              onSave={(value: string) => handleUpdateWebTheme('post_content_tags_text_color', value)}
              defaultColors={defaultColors}
            />
          </SwitchHelper>
        </PropertiesGrouping>

        <PropertiesGrouping label="Insights" indent>
          <SwitchInput
            label="Likes & Comments Enabled"
            name="post-insights-likes-comments-enabled"
            enabled={webThemeWithDefaults?.post_insights_likes_comments_enabled || false}
            onSave={(value: boolean) => handleUpdateWebTheme('post_insights_likes_comments_enabled', value)}
          />
          <SwitchInput
            label="Time Fields Enabled"
            name="post-insights-time-fields-enabled"
            enabled={webThemeWithDefaults?.post_insights_time_fields_enabled || false}
            onSave={(value: boolean) => handleUpdateWebTheme('post_insights_time_fields_enabled', value)}
          />
          {(webThemeWithDefaults?.post_insights_time_fields_enabled ||
            webThemeWithDefaults?.post_insights_time_fields_enabled) && (
            <ColorInput
              label="Color"
              color={webThemeWithDefaults?.post_insights_color}
              resettable={{
                label: 'Global Overline Text Color',
                resetValue: null,
                check: webTheme?.post_insights_color !== null,
              }}
              onSave={(value: string) => handleUpdateWebTheme('post_insights_color', value)}
              defaultColors={defaultColors}
            />
          )}
        </PropertiesGrouping>

        <PropertiesGrouping label="Premium Badge" indent>
          <ColorInput
            label="Background Color"
            color={webThemeWithDefaults?.post_premium_badge_background_color}
            onSave={(value: string) => handleUpdateWebTheme('post_premium_badge_background_color', value)}
            resettable={{
              label: 'Factory Default',
              resetValue: '#D1D5DB',
              check: webTheme?.post_premium_badge_background_color !== '#D1D5DB',
            }}
            defaultColors={defaultColors}
          />
          <ColorInput
            label="Font Color"
            color={webThemeWithDefaults?.post_premium_badge_text_color}
            onSave={(value: string) => handleUpdateWebTheme('post_premium_badge_text_color', value)}
            resettable={{
              label: 'Factory Default',
              resetValue: '#030712',
              check: webTheme?.post_premium_badge_text_color !== '#030712',
            }}
            defaultColors={defaultColors}
          />
        </PropertiesGrouping>

        <PropertiesGrouping label="Title" indent>
          <FontPicker
            label="Font Family"
            searchPlaceholder="Search Font"
            value={webThemeWithDefaults?.post_heading_font_family || ''}
            noOptionsText="No Matching Fonts"
            options={fontOptions}
            onSave={(value: string) => handleUpdateWebTheme('post_heading_font_family', value)}
          />
          <ColorInput
            label="Font Color"
            color={webThemeWithDefaults?.post_heading_font_color}
            resettable={{
              label: 'Global Heading Color',
              resetValue: null,
              check: webTheme?.post_heading_font_color !== null,
            }}
            onSave={(value: string) => handleUpdateWebTheme('post_heading_font_color', value)}
            defaultColors={defaultColors}
          />
        </PropertiesGrouping>

        <PropertiesGrouping label="Subtitle" indent>
          <FontPicker
            label="Font Family"
            searchPlaceholder="Search Font"
            value={webThemeWithDefaults?.post_description_font_family || ''}
            noOptionsText="No Matching Fonts"
            options={fontOptions}
            onSave={(value: string) => handleUpdateWebTheme('post_description_font_family', value)}
          />
          <ColorInput
            label="Font Color"
            color={webThemeWithDefaults?.post_description_font_color}
            resettable={{
              label: 'Global Body Color',
              resetValue: null,
              check: webTheme?.post_description_font_color !== null,
            }}
            onSave={(value: string) => handleUpdateWebTheme('post_description_font_color', value)}
            defaultColors={defaultColors}
          />
        </PropertiesGrouping>

        <PropertiesGrouping label="Author(s)" indent>
          <ColorInput
            label="Font Color"
            color={webThemeWithDefaults?.post_authors_font_color}
            resettable={{
              label: 'Global Link Color',
              resetValue: null,
              check: webTheme?.post_authors_font_color !== null,
            }}
            onSave={(value: string) => handleUpdateWebTheme('post_authors_font_color', value)}
            defaultColors={defaultColors}
          />
          <SwitchInput
            label="Show Author Image"
            name="post-authors-image-enabled"
            enabled={webThemeWithDefaults?.post_authors_show_image || false}
            onSave={(value: boolean) => handleUpdateWebTheme('post_authors_show_image', value)}
          />
        </PropertiesGrouping>
      </GroupHeading>
    </SectionGroupingSlideOut>
  );
};

export default PostsSection;
