import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

export default function useSegmentKpis({ segmentId }: { segmentId: string }) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery(
    ['kpis', segmentId, currentPublicationId],
    () =>
      api
        .get(`/segments/${segmentId}/metrics/kpis`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
