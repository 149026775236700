const LS_KEY = 'wb-fonts';
const WEBFONTS_URL = 'https://media.beehiiv.com/dream/fonts/webfonts.json';
const WEBFONTS_PREVIEW_URL_PREFIX = 'https://media.beehiiv.com/cdn-cgi/image/format=auto/dream/fonts/';

export const getFontPreviewUrl = (fontName: string) => {
  return `${WEBFONTS_PREVIEW_URL_PREFIX}${fontName.replace(/\s+/g, '+')}.webp`;
};

// From client/src/components/TiptapEditor/lib/hooks/useThemeData.ts
const tiptapFonts = [
  { key: 'font-family-default', label: 'None' },
  { key: 'font-family-0', label: 'Helvetica' },
  { key: 'font-family-1', label: 'Arial' },
  { key: 'font-family-2', label: 'Courier' },
  { key: 'font-family-3', label: 'Didot' },
  { key: 'font-family-4', label: 'Garamond' },
  { key: 'font-family-5', label: 'Georgia' },
  { key: 'font-family-6', label: 'Lucida Console' },
  { key: 'font-family-7', label: 'Monaco' },
  { key: 'font-family-8', label: 'Palatino' },
  { key: 'font-family-9', label: 'Times New Roman' },
  { key: 'font-family-10', label: 'Trebuchet MS' },
  { key: 'font-family-11', label: 'Verdana' },
  { key: 'font-family-12', label: 'Alegreya Sans' },
  { key: 'font-family-13', label: 'Alegreya' },
  { key: 'font-family-14', label: 'Cormorant' },
  { key: 'font-family-15', label: 'DM Sans' },
  { key: 'font-family-16', label: 'Eczar' },
  { key: 'font-family-17', label: 'IBM Plex Sans' },
  { key: 'font-family-18', label: 'Inter' },
  { key: 'font-family-19', label: 'Karla' },
  { key: 'font-family-20', label: 'Lato' },
  { key: 'font-family-21', label: 'Libre Baskerville' },
  { key: 'font-family-22', label: 'Libre Franklin' },
  { key: 'font-family-23', label: 'Noto Sans' },
  { key: 'font-family-24', label: 'Oswald' },
  { key: 'font-family-25', label: 'PT Sans' },
  { key: 'font-family-26', label: 'PT Serif' },
  { key: 'font-family-27', label: 'Raleway' },
  { key: 'font-family-28', label: 'Source Sans 3' },
  { key: 'font-family-29', label: 'Space Grotesk' },
  { key: 'font-family-30', label: 'Space Mono' },
  { key: 'font-family-31', label: 'Syne' },
  { key: 'font-family-32', label: 'Work Sans' },
];

type Font = {
  category: string;
  family: string;
  files: Record<string, string>;
  // many other fields we don't care about at this moment
};

type WebFont = {
  items: Array<Font>;
};

export const getFonts = async () => {
  // check if fonts available in localStorage for now.
  if (typeof localStorage === 'undefined') {
    throw Error('localStorage not found. Did you try to run this on the server?');
  }
  const storedFonts = localStorage.getItem(LS_KEY);
  if (storedFonts) {
    return JSON.parse(storedFonts) as WebFont;
  }

  // fetch from server, we might want pagination on this.
  const fonts = await fetch(WEBFONTS_URL).then((d) => d.json());

  return fonts as WebFont;
};

export const getFontFamilies = async () => {
  const fonts = await getFonts();
  const fontFamilies = fonts.items.map((font) => font.family);
  return Array.from(new Set([...fontFamilies, ...tiptapFonts.map((font) => font.label)]));
};
