import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useCurrentPublication } from '@/hooks';

const OnboardingLayout = () => {
  const navigate = useNavigate();
  const { data: currentPublication } = useCurrentPublication();
  const isOnboared = currentPublication?.is_onboarded;

  useEffect(() => {
    if (currentPublication && isOnboared) {
      navigate('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnboared]);

  return (
    <div className="w-full min-h-screen">
      <Outlet />
    </div>
  );
};

export default OnboardingLayout;
