import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import LoadingBox from '@/components/LoadingBox';
import { useSettings } from '@/context/settings-context';
import { useDisableRefetchOnFocus, usePost } from '@/hooks';
import { PostStatus } from '@/interfaces/post';

import FormV2 from './v2/Form';
import Form from './Form';

const PostEdit: React.FunctionComponent = () => {
  useDisableRefetchOnFocus();

  const { postId } = useParams<'postId'>() as { postId: string };
  const navigate = useNavigate();

  const { data: post, isFetching, isError, refetch } = usePost({ id: postId });
  const { settings, isLoading: isLoadingSettings } = useSettings();

  useEffect(() => {
    if (post && post.editing_locked) {
      toast.error(post?.editing_locked_reason || 'There was a problem editing this post.');
      navigate(`/posts/${post.id}`);
    }

    if (post && post.status === PostStatus.ARCHIVED) {
      toast.error('Cannot edit an archived post');
      navigate(`/posts/${post.id}`);
    }
  }, [post, navigate]);

  useEffect(() => {
    Sentry.setTags({
      postEditorVersion: settings?.post_editor_v2 ? 'v2' : 'v1',
    });
  }, [settings, postId]);

  const formElement =
    post &&
    (settings?.post_editor_v2 ? (
      <FormV2 postId={postId} />
    ) : (
      <Form post={post} refetch={refetch} collaborationEnabled={!!settings?.collaborative_editing} />
    ));

  return (
    <LoadingBox isLoading={isFetching || isLoadingSettings} isError={isError} height="100vh">
      {formElement}
    </LoadingBox>
  );
};

export default PostEdit;
