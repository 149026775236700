import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { RectangleGroupIcon } from '@heroicons/react/20/solid';

import Header from '@/components/Header';
import LoadingBox from '@/components/LoadingBox';
import Text from '@/components/Text';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePostTemplates } from '@/hooks';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import { useTemplateViewContext } from '..';

import TemplateListItem from './TemplateListItem';

const Templates: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { resourceType } = useTemplateViewContext();
  const [publicationId] = useCurrentPublicationState();
  const postTemplatesQuery = usePostTemplates();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = postTemplatesQuery;
  const postTemplates = data?.pages.flatMap((page) => page.post_templates) || [];

  const isNoResults = !isLoading && postTemplates.length === 0;

  const handleDelete = (resourceId: string) => {
    api
      .delete(`/post_templates/${resourceId}`, {
        params: {
          publication_id: publicationId,
        },
      })
      .then(() => {
        toast.success('Post template deleted');
        postTemplatesQuery.refetch();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error || 'Something went wrong');
      });
  }

  return (
    <div>
      <div className="py-4 grid grid-cols-5">
        <div className="col-span-3">
          <Header>Templates</Header>
          <Text>Define custom templates to jump start your writing</Text>
        </div>

        {!isLoading && !isNoResults && (
          <div className="col-span-2 flex items-end justify-end">
            <Button onClick={() => navigate(`/templates/${resourceType}/new`)}>
              <div className="flex">
                <RectangleGroupIcon className="h-5 w-5 mr-2" />
                New template
              </div>
            </Button>
          </div>
        )}
      </div>
      <div className="mb-4">
        <hr />
      </div>
      <LoadingBox isLoading={isLoading} isError={isError}>
        <>
          {isNoResults ? (
            <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
              <div className="flex flex-col items-center gap-4">
                <p className="text-gray-500 text-center">Once you create your first template, it will show up here.</p>
                <Button variant="primary" onClick={() => navigate(`/templates/${resourceType}/new`)}>
                  <div className="flex">
                    <RectangleGroupIcon className="h-5 w-5 mr-2" />
                    New template
                  </div>
                </Button>
              </div>
            </div>
          ) : (
            <ul className="divide-y border rounded bg-white">
              {postTemplates.map((post_template) => (
                <TemplateListItem key={post_template.id} template={post_template} onDelete={handleDelete}/>
              ))}
            </ul>
          )}
          <div className="text-center mt-6">
            {hasNextPage && (
              <div>
                <Button
                  variant="primary-inverse"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            )}
          </div>
        </>
      </LoadingBox>
    </div>
  );
};

export default Templates;
