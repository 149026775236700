import BoostAgreementStatus from '@/interfaces/boosts/monetize/boost_agreement_status';
import { BoostSendStatus } from '@/interfaces/boosts/monetize/boost_send';
import { Option } from '@/interfaces/general';

export const boostAgreementsFilterOptions: Option[] = [
  { label: 'All', value: '' },
  { label: 'Pending', value: BoostAgreementStatus.PENDING },
  { label: 'Live', value: BoostAgreementStatus.ACTIVE },
  { label: 'Paused', value: BoostAgreementStatus.PAUSED },
  { label: 'Rejected', value: BoostAgreementStatus.REJECTED },
  { label: 'Archived', value: BoostAgreementStatus.ARCHIVED },
  { label: 'Inactive', value: BoostAgreementStatus.INACTIVE },
];

export const boostSendsFilterOptions: Option[] = [
  { label: 'All', value: '' },
  { label: 'Live', value: BoostSendStatus.ACCEPTED },
  { label: 'Pending', value: BoostSendStatus.PENDING },
  { label: 'Cancelled', value: BoostSendStatus.CANCELED },
  { label: 'Rejected', value: BoostSendStatus.REJECTED },
  { label: 'Archived', value: BoostSendStatus.ARCHIVED },
];
