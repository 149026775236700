import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import useDisbursementUpdate from '@/hooks/useAdNetwork/internal/useDisbursementUpdate';
import { AdNetworkCampaignPerformanceOpportunity } from '@/interfaces/ad_network/internal/campaign_performance_opportunity';
import {
  AdNetworkDisbursementClicksAdjustmentReason,
  AdNetworkDisbursementClicksAdjustmentReasonLabelHash,
} from '@/interfaces/ad_network/internal/disbursement/types';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';
import { Option } from '@/interfaces/general';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  opportunity: AdNetworkCampaignPerformanceOpportunity;
  modalOpen: boolean;
  onClose: () => void;
}

const EditClicksModal = ({ opportunity, modalOpen, onClose }: Props) => {
  const { campaign_id: campaignId } = useParams<'campaign_id'>() as { campaign_id: string };
  const { disbursement } = opportunity;
  const [clicksAdjustmentReasonError, setClicksAdjustmentReasonError] = useState<string | null>(null);
  const [approvedClicks, setApprovedClicks] = useState(disbursement.approved_clicks);
  const [approvedAmount, setApprovedAmount] = useState(disbursement.approved_amount_cents);
  const [clicksAdjustmentReason, setClicksAdjustmentReason] =
    useState<AdNetworkDisbursementClicksAdjustmentReason | null>(disbursement.clicks_adjustment_reason);
  const needsAdjustmentReason = approvedClicks !== opportunity.total_unique_eligible_clicked;
  const modalId = `approve-disbursement-${disbursement.id}`;
  const queryClient = useQueryClient();
  const numberFormatter = new Intl.NumberFormat();
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const clicksAdjustmentReasonOptions = [
    ...Object.keys(AdNetworkDisbursementClicksAdjustmentReasonLabelHash).map((key) => ({
      label: AdNetworkDisbursementClicksAdjustmentReasonLabelHash[key as AdNetworkDisbursementClicksAdjustmentReason],
      value: key,
    })),
  ] as Option[];

  const handleModalClose = () => {
    onClose();
    setApprovedClicks(disbursement.approved_clicks);
    setApprovedAmount(disbursement.approved_amount_cents);
    setClicksAdjustmentReason(disbursement.clicks_adjustment_reason);
    setClicksAdjustmentReasonError(null);
  };
  const updateDisbursement = useDisbursementUpdate({
    id: disbursement.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['ad_network', 'internal', 'disbursements'], { exact: true });
      queryClient.invalidateQueries(['ad_network', 'internal', 'campaign', campaignId, 'stats'], {
        exact: true,
      });
      handleModalClose();
    },
  });

  useEffect(() => {
    setApprovedClicks(disbursement.approved_clicks);
    setApprovedAmount(disbursement.approved_amount_cents);
    setClicksAdjustmentReason(disbursement.clicks_adjustment_reason);
    setClicksAdjustmentReasonError(null);
  }, [disbursement]);

  const handleApprovedClicksChange = (e: any) => {
    const { value } = e.target;

    if (value === opportunity.total_unique_eligible_clicked.toString()) {
      setClicksAdjustmentReason(null);
      setClicksAdjustmentReasonError(null);
    }

    setApprovedClicks(value);

    if (opportunity.payout_model === AdNetworkPayoutModel.CPC) {
      setApprovedAmount(value * opportunity.payout_per_click_cents);
    }
  };
  const handleProceed = () => {
    if (!clicksAdjustmentReason && needsAdjustmentReason) {
      setClicksAdjustmentReasonError('Please select a reason for the adjustment');
      return;
    }

    updateDisbursement.mutateAsync({
      approved_clicks: approvedClicks,
      approved_amount_cents: approvedAmount,
      clicks_adjustment_reason: clicksAdjustmentReason,
    });
  };
  const handleAdjustmentReasonSelect = (_name: string, value: string) => {
    setClicksAdjustmentReason(value as AdNetworkDisbursementClicksAdjustmentReason);
    setClicksAdjustmentReasonError(null);
  };

  return (
    <ActionModal
      resourceId={disbursement.id}
      isOpen={modalOpen}
      isWorking={updateDisbursement.isLoading}
      onClose={handleModalClose}
      onProceed={handleProceed}
      headerText="Adjust Payable Unique Clicks"
      actionText="Confirm"
      bodyId={modalId}
    >
      <div className="space-y-4">
        <div className="text-sm">
          <p className="text-xl mb-4">Publication</p>
          <div className="flex flex-row items-center">
            <div>
              <p>{opportunity.publication_name}</p>
              <p className="text-xs text-gray-500">{opportunity.publication_owner_email}</p>
            </div>
          </div>
        </div>
        <div className="text-sm">
          <div className="flex flex-row items-center justify-between">
            <p className="text-xl mb-4">Payout</p>
          </div>
          <div className="space-y-4">
            <div className="flex flex-row items-center justify-between">
              {opportunity.payout_model === AdNetworkPayoutModel.CPC ? (
                <>
                  <p>Publisher CPC</p>
                  <p>{opportunity.payout_per_click}</p>
                </>
              ) : (
                <>
                  <p>Publisher CPM</p>
                  <p>{opportunity.payout_per_mille}</p>
                </>
              )}
            </div>
            <div className="flex flex-row items-center justify-between">
              <p>Payout Amount</p>
              {approvedAmount !== disbursement.amount_cents ? (
                <div className="flex flex-row space-x-2">
                  <span className="line-through">{disbursement.amount}</span>
                  <span>{currencyFormatter.format(approvedAmount / 100)}</span>
                </div>
              ) : (
                <p>{disbursement.amount}</p>
              )}
            </div>
            <div className="flex flex-row items-center justify-between">
              <p>Payable Unique Clicks</p>
              <p>{numberFormatter.format(opportunity.total_unique_eligible_clicked || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p>Adjusted Clicks</p>

              <input
                type="number"
                name="approved_clicks"
                value={approvedClicks}
                onChange={handleApprovedClicksChange}
                required
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <p>Adjustment Reason</p>
              <Dropdown
                name="payout_adjustment_reason"
                value={clicksAdjustmentReason?.toString() || ''}
                onSelect={handleAdjustmentReasonSelect}
                options={clicksAdjustmentReasonOptions}
                placeholderText="Select a reason"
                className="w-1/2"
                errorText={clicksAdjustmentReasonError?.toString()}
                disabled={!needsAdjustmentReason}
              />
            </div>
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default EditClicksModal;
