/* eslint-disable consistent-return */
import { useCallback, useEffect, useRef, useState } from 'react';
import { TCollabThread } from '@hocuspocus/provider';
import throttle from 'lodash.throttle';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { focusThreadInEditor } from '@/components/TiptapEditor/lib/utils/focusThreadInEditor';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';
import { cn } from '@/utils/cn';

import { ThreadCard } from '../components';
import { useFocusedThreads } from '../hooks';
import { InlineComments } from '../Inline';

type CommentsQueryParams = {
  thread_id: QueryParamConfig<string>;
  comment_id: QueryParamConfig<string>;
};

export const FloatingComments = () => {
  const { threads, highlightThread, removeHighlightThread, editor, selectThread } = useEditorContext();

  const [params, setParams] = useQueryParams<CommentsQueryParams>({
    thread_id: withDefault(StringParam, ''),
    comment_id: withDefault(StringParam, ''),
  });

  const threadCardRefs = useRef<Record<string, HTMLDivElement>>({});

  const [threadRects, setThreadRects] = useState<Record<string, DOMRect>>({});

  const [threadsToShow, setThreadsToShow] = useState<TCollabThread[]>([]);

  const { focusedThreads, activeThreadId, activeThreadIdObj } = useFocusedThreads(editor);

  const isXl = useMediaQuery('(min-width: 1280px)');

  const threadIdTextContentMap = editor?.storage.commentsKit.threadIdTextContentMap || {};

  // force rerender of the floating comments when the window is resized
  const [, setRerenderTrigger] = useState(0);

  useEffect(() => {
    if (editor?.isEditable && params.thread_id) {
      setTimeout(() => {
        editor.view.dom.querySelector(`[data-thread-id="${params.thread_id}"]`)?.scrollIntoView({
          block: 'center',
        });

        focusThreadInEditor(editor, params.thread_id);

        setParams({ thread_id: undefined, comment_id: undefined });
      }, 300);
    }
  }, [editor, params.thread_id, setParams]);

  useEffect(() => {
    if (!editor) return () => {};

    const updateRerenderTrigger = throttle(() => setRerenderTrigger(Math.random()), 500);

    editor?.on('transaction', updateRerenderTrigger);
    window.addEventListener('resize', updateRerenderTrigger);

    return () => {
      editor?.off('transaction', updateRerenderTrigger);
      window.removeEventListener('resize', updateRerenderTrigger);
    };
  }, [editor]);

  const updateThreadRects = useCallback(() => {
    const openThreads = threads?.filter((t) => !t.resolvedAt);

    if (!editor || !openThreads?.length) {
      setThreadsToShow([]);
      setThreadRects({});

      return;
    }

    const editorRect = editor.options.element.getBoundingClientRect();

    const rects: Record<string, DOMRect> = {};

    openThreads.forEach((t) => {
      editor.options.element.querySelectorAll(`[data-thread-id="${t.id}"]`).forEach((el) => {
        const thredRect = el.getBoundingClientRect();

        rects[t.id] = new DOMRect(
          thredRect.left - editorRect.left,
          thredRect.top - editorRect.top,
          editorRect.width,
          editorRect.height
        );
      });
    });

    const sortedOpenThreads = Object.keys(rects)
      .sort((a, b) => rects[a].top - rects[b].top || rects[a].left - rects[b].left)
      .map((id) => threads?.find((t) => t.id === id)!)
      .filter(Boolean);

    setThreadsToShow(sortedOpenThreads);
    setThreadRects(rects);
  }, [threads, editor]);

  useEffect(() => {
    if (!editor) return () => {};

    editor.on('update', updateThreadRects);
    editor.on('selectionUpdate', updateThreadRects);
    editor.on('create', updateThreadRects);

    updateThreadRects();

    return () => {
      editor.off('update', updateThreadRects);
      editor.off('selectionUpdate', updateThreadRects);
      editor.off('create', updateThreadRects);
    };
  }, [editor, updateThreadRects]);

  const showFloatingComments = !!threadsToShow?.length;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const calculateTops = () => {
    const threadIdTopMap: Record<string, number> = {};

    if (!mounted || !editor || !Object.keys(threadRects).length) return threadIdTopMap;

    let threadBeforeDimensions = {
      top: 0,
      height: 0,
    };

    threadsToShow.forEach((thread, index) => {
      const threadRef = threadCardRefs.current[thread.id];

      let top = threadRects[thread.id]?.top || 0;

      if (index !== 0) {
        // do the calculation for non first threads
        const threadBeforeEnd = threadBeforeDimensions.top + threadBeforeDimensions.height;

        if (top - threadBeforeEnd <= 12) {
          top = threadBeforeEnd + 12;
        }
      }

      threadIdTopMap[thread.id] = top;

      threadBeforeDimensions = { top, height: threadRef?.getBoundingClientRect().height || 0 };
    });

    if (activeThreadId) {
      const indexOfActiveThread = threadsToShow.findIndex((t) => t.id === activeThreadId);
      const currentTop = threadIdTopMap[activeThreadId];
      const editorTopOfActiveThread = (threadRects[activeThreadId || focusedThreads[0]]?.top || 0) - 10;
      const distanceFromEditorTop = currentTop - editorTopOfActiveThread;

      if (indexOfActiveThread > -1) {
        for (let i = 0; i < indexOfActiveThread; i += 1) {
          const thread = threadsToShow[i];
          threadIdTopMap[thread.id] -= distanceFromEditorTop;
        }

        for (let i = indexOfActiveThread + 1; i < threadsToShow.length; i += 1) {
          const thread = threadsToShow[i];
          threadIdTopMap[thread.id] = threadIdTopMap[thread.id] - distanceFromEditorTop + 100;
        }

        threadIdTopMap[activeThreadId] = editorTopOfActiveThread;
      }
    }

    return threadIdTopMap;
  };

  const calculatedTops = isXl ? calculateTops() : {};

  return showFloatingComments ? (
    <>
      {isXl && (
        <div className={cn('hidden lg:flex lg:flex-col lg:w-80 lg:max-w-xs relative overflow-x-visible bg-white')}>
          {threadsToShow.map((thread) => (
            <div
              className="absolute transition-transform w-full"
              ref={(ref) => {
                if (ref) {
                  threadCardRefs.current[thread.id] = ref;
                }
              }}
              key={thread.id}
              {...(typeof calculatedTops[thread.id] === 'number'
                ? { style: { transform: `translateY(${calculatedTops[thread.id]}px)` } }
                : {})}
            >
              <ThreadCard
                thread={thread}
                onClick={selectThread}
                onMouseEnter={highlightThread}
                onMouseLeave={removeHighlightThread}
                active={activeThreadIdObj[thread.id]}
                inFloatingList
                referenceText={threadIdTextContentMap[thread.id]}
              />
            </div>
          ))}
        </div>
      )}

      {!isXl && editor && threadsToShow && (
        <InlineComments editor={editor} threadsToShow={threadsToShow} activeThreadId={activeThreadId} />
      )}
    </>
  ) : null;
};
