import { ArrowPathIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

interface Props {
  handleResetSearch: () => void;
  isLoading: boolean;
}

const NoResults = ({ handleResetSearch, isLoading }: Props) => {
  return (
    <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
      <div className="flex flex-col items-center gap-4">
        <p className="text-center text-gray-500">No applicable offers found</p>
        <Button variant="primary-inverse" onClick={handleResetSearch}>
          <div className="flex">
            <ArrowPathIcon className="w-5 h-5 mr-2" />
            Reset Search
          </div>
        </Button>
      </div>
    </div>
  );
};

export default NoResults;
