import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

export const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string, country: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phone, country));
  } catch (error) {
    return false;
  }
};

export const formatPhoneNumber = (phone: string, format = PhoneNumberFormat.INTERNATIONAL) => {
  try {
    return phoneUtil.format(phoneUtil.parse(`+${phone}`), format);
  } catch (error) {
    return phone;
  }
};
