import { useCallback, useMemo, useState } from 'react';

interface Props {
  totalCount: number;
  allAvailableIds: string[];
}

export function useResourceListBulkActions({ totalCount, allAvailableIds }: Props) {
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());
  const [isSelectAllActive, setIsSelectAllActive] = useState(false);

  const selectedCount = isSelectAllActive ? totalCount : selectedIds.size;

  // Convert selectedIds to an array so that we can pass it to the backend
  const selectedIdsArray = useMemo(() => {
    return isSelectAllActive ? allAvailableIds : Array.from(selectedIds);
  }, [isSelectAllActive, allAvailableIds, selectedIds]);

  // Toggle selection of a single item
  const toggleSelection = useCallback((id: string) => {
    setSelectedIds(currentIds => {
      const nextIds = new Set(currentIds);
      if (nextIds.has(id)) {
        nextIds.delete(id);
      } else {
        nextIds.add(id);
      }
      return nextIds;
    });
    setIsSelectAllActive(false);
  }, []);

  const selectAll = useCallback((visibleIds: string[]) => {
    setIsSelectAllActive(true);
    setSelectedIds(new Set(visibleIds));
  }, []);

  const deselectAll = useCallback(() => {
    setIsSelectAllActive(false);
    setSelectedIds(new Set());
  }, []);

  // Add new IDs to selection when in "select all" mode
  const addNewIds = useCallback((newIds: string[]) => {
    if (!isSelectAllActive) return;

    setSelectedIds(currentIds => {
      const nextIds = new Set(currentIds);
      newIds.forEach(id => nextIds.add(id));
      return nextIds;
    });
  }, [isSelectAllActive]);

  // Helper function to check if an ID is currently selected
  const isSelected = useCallback((id: string) =>
    isSelectAllActive || selectedIds.has(id),
    [isSelectAllActive, selectedIds]
  );

  return {
    selectedIds,
    selectedCount,
    isSelectAllActive,
    toggleSelection,
    selectAll,
    deselectAll,
    addNewIds,
    selectedIdsArray,
    isSelected
  };
}
