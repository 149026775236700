import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CalendarIcon } from '@heroicons/react/20/solid';
import { GlobeAltIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import FullCalendar from '@/components/FullCalendar';
import { NotificationsDropdown } from '@/components/Notifications/NotificationsDropdown';
import { useAppLayout } from '@/context/app-layout-context';
import { useCurrentUser } from '@/context/current-user-context';
import { usePermissions } from '@/context/permissions-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import { Button } from '@/ui/Button';

import { TOP_BAR_HEIGHT_CLASS_NAME } from '../constants';

import TopNavItem from './_components/TopNavItem';
import SizingConfiguration from './SizingConfiguration';
import { UserDropdown } from './UserDropdown';

interface Props {
  largeNavOpen: boolean;
  className?: string;
}

const TopBar = ({ largeNavOpen, className }: Props) => {
  const navigate = useNavigate();
  const { checkPermissions } = usePermissions();
  const { settings } = useSettings(false);

  const { currentUser } = useCurrentUser();
  const { data: currentPublication } = useCurrentPublication();
  const { isSettingsPages, setLargeNavOpen } = useAppLayout();
  const hasPublicationSettingsV2 = currentUser?.has_settings_v2;

  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div
      className={cx(
        'screen:flex flex-col justify-between items-center print:hidden',
        TOP_BAR_HEIGHT_CLASS_NAME,
        className
      )}
    >
      <div className="w-full h-full flex flex-row justify-center items-between">
        {hasPublicationSettingsV2 && isSettingsPages ? null : (
          <SizingConfiguration largeNavOpen={largeNavOpen} setLargeNavOpen={setLargeNavOpen} />
        )}
        <div className="w-full py-2 pr-6 flex flex-row space-x-2 justify-end items-end bg-white">
          <TopNavItem
            onClick={() => navigate('/partner_program')}
            title="Share & Earn"
            disabled={!checkPermissions('views/nav/share_and_earn', 'read')}
          />
          <TopNavItem
            onClick={() => navigate('/wallet')}
            title="Wallet"
            disabled={!checkPermissions('views/nav/wallet', 'read')}
          />
          <TopNavItem onClick={() => navigate('/help')} title="Help" />
          <a href={currentPublication?.url} target="_blank" rel="noreferrer">
            <Button
              href={currentPublication?.url}
              size="sm"
              variant="flush"
              type="button"
              paddingStyle="icon"
              Icon={GlobeAltIcon}
              tooltip="View site"
            />
          </a>
          {settings?.calendar && (
            <>
              <Button
                onClick={() => {
                  setIsOpen(true);
                }}
                size="sm"
                variant="flush"
                type="button"
                paddingStyle="icon"
                Icon={CalendarIcon}
                tooltip="View calendar"
              />
              <FullCalendar isOpen={isOpen} onClose={() => setIsOpen(false)} />
            </>
          )}

          <NotificationsDropdown />
          <UserDropdown />
        </div>
      </div>
      <hr className="w-full" />
    </div>
  );
};

export default TopBar;
