import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';

import LoadingBox from '@/components/LoadingBox';
import useAddRecommendation from '@/hooks/useAddRecommendation';
import useExternalRecommendations from '@/hooks/useExternalRecommendations';
import useTransitionRecommendation from '@/hooks/useRecommendations/useTransitionRecommendation';
import { Recommendation, RecommendationStatus } from '@/interfaces/recommendation';
import { Button } from '@/ui/Button';

import Row from '../components/Row';

import BlockedRecommendations from './BlockedRecommendations';

type NoResultsProps = {
  isLoading: boolean;
};

const NoResults = ({ isLoading }: NoResultsProps) => {
  const navigate = useNavigate();
  return (
    <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
      <div className="flex flex-col items-center space-y-4 max-w-xl">
        <ChatBubbleBottomCenterTextIcon className="h-6 w-6 text-gray-500" />
        <p className="text-gray-500 text-center">
          Looks like no one is recommending you yet. The best way to get started is by adding some recommendations of
          your own.
        </p>
        <Button onClick={() => navigate('/recommendations')}>Get started</Button>
      </div>
    </div>
  );
};

const Recommendations = () => {
  const { data, isLoading, isError, refetch, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useExternalRecommendations();
  const navigate = useNavigate();

  const recommendations = data?.pages.flatMap((page) => page.recommendations) || [];
  const blockedRecommendations = recommendations.filter(
    (recommendation) => recommendation.status === RecommendationStatus.BLOCKED
  );
  const unblockedRecommendations = recommendations.filter(
    (recommendation) => recommendation.status !== RecommendationStatus.BLOCKED
  );
  const isNoResults = !isLoading && recommendations.length === 0;

  const { mutateAsync: addRecommendation } = useAddRecommendation({
    onSuccess: () => {
      toast.success('Recommendation added');
      navigate('/recommendations');
    },
  });

  const transitionMutation = useTransitionRecommendation({
    onSuccess: (successData: any) => {
      toast.success(`Recommendation successfully ${successData.status === 'blocked' ? 'removed' : 'added'}`);
      refetch();
    },
  });

  const handleTransition = ({ recommendationId, newStatus }: { recommendationId: string; newStatus: string }) => {
    transitionMutation.mutateAsync({ recommendationId, newStatus });
  };

  const blockOption = (recommendation: Recommendation) => {
    if (recommendation.status === RecommendationStatus.ARCHIVED) return [];

    const isBlocked = recommendation.status === RecommendationStatus.BLOCKED;
    return [
      {
        label: isBlocked ? 'Unblock publication' : 'Block publication',
        onClick: () =>
          handleTransition({
            recommendationId: recommendation.id,
            newStatus: isBlocked ? 'inactive' : 'blocked',
          }),
      },
    ];
  };

  const options = (recommendation: Recommendation) => [
    [
      {
        label: 'Recommend back',
        onClick: () => addRecommendation({ publicationId: recommendation.publication.id }),
      },
      {
        label: 'View publication',
        onClick: () => window.open(`https://${recommendation.publication.hostname}`, '_blank'),
      },
    ],
    blockOption(recommendation),
  ];

  return (
    <>
      <div className="grid mb-4 gap-4 grid-cols-8">
        <p className="text-gray-600 lg:col-span-4 col-span-8">
          These publications recommended you to their audience. If you like their content, you can recommend them back.
        </p>
      </div>

      <LoadingBox isLoading={isLoading} isError={isError}>
        <>
          {isNoResults ? (
            <NoResults isLoading={isLoading} />
          ) : (
            <>
              <ul className="space-y-2">
                {unblockedRecommendations.map((recommendation) => (
                  <Row
                    key={recommendation.id}
                    recommendation={recommendation}
                    publicationType="publication"
                    options={options}
                  />
                ))}
              </ul>
              <BlockedRecommendations recommendations={blockedRecommendations} options={options} />
            </>
          )}
          <div className="text-center mt-6">
            {hasNextPage && (
              <div>
                <Button
                  variant="primary-inverse"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            )}
          </div>
        </>
      </LoadingBox>
    </>
  );
};

export default Recommendations;
