import { useQuery } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import api from '@/services/swarm';

const usePartnerLeaderboard = (staleTime = 10000) => {
  const { currentUser } = useCurrentUser();

  const { data, isLoading, error, refetch } = useQuery<any>(
    ['leaderboard', currentUser],
    () =>
      api
        .get(`/partner_program/leaderboard`, {
          params: {
            user_id: currentUser?.id,
          },
        })
        .then((res) => res.data),
    {
      staleTime,
    }
  );

  return { data, isLoading, error, refetch };
};

export default usePartnerLeaderboard;
