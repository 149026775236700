import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Post } from '@/interfaces/dream_builder/post';
import { Pagination } from '@/interfaces/general';

import api from '../../services/swarm';

interface ApiResponse {
  posts: Post[];
  pagination: Pagination;
}

interface Props {
  enabled?: boolean;
  tags?: string[];
  q?: string;
}

export default function useInfinitePosts({ enabled = true, tags, q }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'posts', 'infinite_scroll', tags, q],
    ({ pageParam = 1 }) =>
      api
        .get(`/dream_editor/posts`, {
          params: {
            publication_id: currentPublicationId,
            page: pageParam,
            tags,
            q,
          },
        })
        .then((res) => res.data),
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      enabled: !!currentPublicationId && enabled,
    }
  );
}
