import { useCurrentPublication, useUpdateWebTemplate, useWebTemplate } from '../../../../../../hooks';
import { Section, SwitchForm } from '../../../../Components';

const RecommendedReadingSection = () => {
  const { data: currentPublication } = useCurrentPublication();
  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: any = webTemplateRequest;

  const webTemplateMutation = useUpdateWebTemplate();

  return (
    <Section
      title="Recommended Reading"
      description="Offer your readers a short recommended list of articles to read next."
      scrollToId="recommended-reading"
      isLoading={!currentPublication || !webTemplate}
    >
      <SwitchForm
        name="enable_recommended_reading"
        label="Enable recommended reading"
        description="By enabling, users will see a list of recommended articles at the bottom of each post."
        value={webTemplate?.enable_recommended_reading || false}
        onSave={(val: boolean) => webTemplateMutation.mutateAsync({ enable_recommended_reading: val })}
        toastSuccessMessage="Saved!"
      />
    </Section>
  );
};

export default RecommendedReadingSection;
