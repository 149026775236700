import Text from '../Text';

interface Props {
  htmlFor: string;
  value: string;
}

const Label = ({ htmlFor, value }: Props) => {
  return (
    <label htmlFor={htmlFor}>
      <Text as="span" size="sm" type="subtitle" className="text-gray-800">
        {value}
      </Text>
    </label>
  );
};

export default Label;
