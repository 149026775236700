import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Form } from '../../interfaces/form';
import api from '../../services/swarm';

const useForm = (formId: string | undefined) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<Form>(
    ['forms', formId],
    () =>
      api
        .get(`/forms/${formId}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!formId,
    }
  );
};

export default useForm;
