import { useEffect, useState } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';

import Banner from '@/components/Banner';
import useCheckExistingRecords from '@/hooks/useCustomDomains/useCheckExistingRecords';

interface Props {
  publicationId: string;
  domain: string;
  variant?: 'compact' | 'full';
}

const DmarcWarningBanner = ({ publicationId, domain, variant = 'full' }: Props) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const dmarcCheckQuery = useCheckExistingRecords(publicationId, domain, true);
  const { mutateAsync: checkDmarc } = dmarcCheckQuery;

  useEffect(() => {
    if (domain && publicationId) {
      checkDmarc({
        publication_id: publicationId,
        domain,
        dmarc_check: true,
      }).then((response) => {
        setShowWarning(!response.result);
      });
    }
  }, [domain, publicationId, checkDmarc]);

  if (!showWarning) return null;

  return (
    <Banner
      variant="warning"
      title={variant === 'compact' ? undefined : 'Your email sending domain does not have a DMARC record.'}
      bodyText={
        variant === 'compact'
          ? 'Your domain does not have a DMARC record. This could impact your deliverability. Please visit our Knowledge Base for details on how to configure DMARC.'
          : 'This could impact your deliverability. Please visit our Knowledge Base for details on how to configure DMARC.'
      }
      ctaText={variant === 'compact' ? 'Learn more' : 'DMARC Setup Guide'}
      ctaIcon={variant === 'compact' ? undefined : <ArrowTopRightOnSquareIcon />}
      smallCTA={variant === 'compact'}
      isScreenWide={false}
      onClick={() =>
        window.open(
          'https://support.beehiiv.com/hc/en-us/articles/13650078276375-How-to-set-up-DMARC-authentication',
          '_blank'
        )
      }
    />
  );
};

export default DmarcWarningBanner;
