import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

import { ChartCard } from '@/components/Chartsv2';
import LoadingBox from '@/components/LoadingBox';
import Modal from '@/components/Modal';
import useStripeConnectAccount, { StripeAccountStatus } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import useSwapRecommendedBoostOffer from '@/hooks/boosts/monetize/useSwapRecommendedBoostOffer';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';
import ConnectToStripe from '@/pages/Monetize/Boosts/components/ConnectToStripe';
import ApplyModal from '@/pages/Monetize/Boosts/Marketplace/components/ApplyModal';
import { BoostOfferCard } from '@/pages/Monetize/Boosts/Shared/BoostOfferCard';
import Teaser from '@/pages/Monetize/Boosts/Teaser';
import { Button } from '@/ui/Button';

interface Props {
  showUpsell: boolean;
}

const RecommendedBoosts = ({ showUpsell }: Props) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: stripeConnectAccount, isLoading: isLoadingStripeAccount } =
    useStripeConnectAccount(currentPublicationId);
  const boostsAccountStatus = stripeConnectAccount?.boosts_account_status || StripeAccountStatus.MISSING;
  const [isConnectingToStripe, setIsConnectingToStripe] = useState(false);
  const [snoozingBoostOfferId, setSnoozingBoostOfferId] = useState<string | null>(null);
  const [selectedBoost, setSelectedBoost] = useState<BoostOffer | null>(null);
  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);
  const navigate = useNavigate();

  const { recommendedOffers, snoozeOffer, swapOffer, hasNoResults, isLoading, hasError } =
    useSwapRecommendedBoostOffer();

  const handleApplyProceed = () => {
    if (selectedBoost?.id) {
      swapOffer(selectedBoost.id);
    }

    setSelectedBoost(null);
  };

  const handleCloseApplyModal = () => setSelectedBoost(null);

  const handleApply = ({ boostOffer }: { boostOffer: BoostOffer }) => {
    if (showUpsell) {
      setIsUpsellModalOpen(true);
      return;
    }

    if (isLoadingStripeAccount) {
      toast.loading('One moment please...');
      return;
    }

    if (boostsAccountStatus !== StripeAccountStatus.ACTIVE) {
      setIsConnectingToStripe(true);
    } else {
      setSelectedBoost(boostOffer);
    }
  };

  const handleClickSnooze = async (boostOfferId: string) => {
    setSnoozingBoostOfferId(boostOfferId);

    try {
      await snoozeOffer(boostOfferId);
      swapOffer(boostOfferId);
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
      setSnoozingBoostOfferId(null);
    }
  };

  return (
    <ChartCard
      title="Recommended Boost Offers"
      description="Check publications to boost within the beehiiv network"
      isLoading={isLoading}
      noResults={hasNoResults}
      noResultsText="No recommendations at this moment, please try again later!"
      actionChildren={
        <Button
          Icon={ArrowUpRightIcon}
          iconRight
          variant="secondary"
          size="xs"
          onClick={() => navigate('/monetize/boosts/marketplace')}
        >
          Boosts Marketplace
        </Button>
      }
    >
      <div className="w-full pt-6">
        <ConnectToStripe
          boostsAccountStatus={boostsAccountStatus}
          isOpen={isConnectingToStripe}
          onClose={() => setIsConnectingToStripe(false)}
          publicationId={currentPublicationId}
        />
        <ApplyModal
          isOpen={!!selectedBoost}
          onClose={handleCloseApplyModal}
          boostOffer={selectedBoost}
          onProceed={handleApplyProceed}
        />
        <Modal isOpen={isUpsellModalOpen} onClose={() => setIsUpsellModalOpen(false)}>
          <div className="m-6">
            <Teaser />
          </div>
        </Modal>
        {hasNoResults && <div className="h-60" />}
        <LoadingBox isLoading={isLoading} isError={hasError} height="400px">
          <div className="grid grid-flow-row gap-4 lg:grid-cols-1 xl:grid-cols-2">
            {recommendedOffers.map((boostOffer) => (
              <BoostOfferCard
                boostOffer={boostOffer}
                onApply={() => handleApply({ boostOffer })}
                key={`recommended-boost-offer-${boostOffer.id}`}
                onSnooze={handleClickSnooze}
                isSnoozing={boostOffer.id === snoozingBoostOfferId}
              />
            ))}
          </div>
        </LoadingBox>
      </div>
    </ChartCard>
  );
};

export default RecommendedBoosts;
