import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  automationId: string;
  isEnabled: boolean;
}

interface ApiResponse {
  [key: string]: {
    fresh_in_progress: number;
    fresh_completed: number;
    stale_completed: number;
  };
}

const useJourneyStepCounts = ({ automationId, isEnabled }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const journeyStepCounts = () =>
    api
      .get(`/automations/${automationId}/journey_step_counts`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<ApiResponse>(['automations', automationId, 'journey_step_counts'], journeyStepCounts, {
    retryOnMount: false,
    enabled: isEnabled,
  });
};

export default useJourneyStepCounts;
