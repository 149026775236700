export default function getLinkHrefs(state: any) {
  const hrefs: string[] = [];

  if (!state) return [];

  function traverse(node: any) {
    if (node.marks) {
      node.marks.forEach((mark: any) => {
        if (mark.type === 'link' && mark.attrs && mark.attrs.href) {
          hrefs.push(mark.attrs.href);
        }
      });
    }

    if (node.attrs) {
      Object.entries(node.attrs).forEach(([key, val]) => {
        if (key === 'src') return;

        if (typeof val === 'string' && val.startsWith('http')) {
          hrefs.push(val);
        }
      });
    }

    if (node.content) {
      node.content.forEach(traverse);
    }
  }

  traverse(state);

  return hrefs;
}
