import { useInfiniteQuery } from 'react-query';

import {
  AdNetworkCampaignPerformanceFilter,
  AdNetworkCampaignPerformanceOpportunity,
} from '@/interfaces/ad_network/internal/campaign_performance_opportunity';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  opportunities: AdNetworkCampaignPerformanceOpportunity[];
  pagination: Pagination;
}

export default function useCampaignOpportunities({
  campaignId,
  filter,
}: {
  campaignId: string;
  filter: AdNetworkCampaignPerformanceFilter;
}) {
  const fetchOpportunities = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/ad_network/internal/campaigns/${campaignId}/opportunities`, {
        params: {
          filter,
          page: pageParam,
          per_page: 25,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(
    ['ad_network', 'internal', 'campaign', campaignId, 'opportunities', filter],
    fetchOpportunities,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
