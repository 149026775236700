import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { AdNetworkIndustry } from '../../../interfaces/ad_network/internal/industry';
import api from '../../../services/swarm';

export default function useAdvertiserIndustries({ id }: { id: string }) {
  return useQuery<AdNetworkIndustry[], AxiosError>(
    ['ad_network', 'internal', 'advertisers', id, 'industries'],
    () => api.get(`/ad_network/internal/advertisers/${id}/industries`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
