import { RadioGroup } from '@headlessui/react';
import { CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';
import { Dropdown } from '@/ui/Dropdown';

import Tooltip from '../Tooltip';

export type LargeItemRadioOption = {
  name: string;
  value: string;
  tooltip?: string;
  disabled?: boolean;
};

interface Props {
  srOnlyLabel?: string;
  options: LargeItemRadioOption[];
  dropdownOptions?: LargeItemRadioOption[];
  selected: LargeItemRadioOption | null;
  onSelected: (option: LargeItemRadioOption) => void;
  justifyItems?: 'start' | 'center' | 'end';
  dropdownPlaceholder?: string;
  dropdownName?: string;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
}

const LargeItemRadioGroup = ({
  options,
  srOnlyLabel,
  dropdownOptions,
  selected,
  justifyItems,
  dropdownPlaceholder,
  dropdownName,
  variant = 'primary',
  onSelected,
  disabled = false,
}: Props) => {
  let justifyClass = '';
  if (justifyItems) {
    justifyClass = `justify-${justifyItems}`;
  } else {
    justifyClass = 'justify-center';
  }

  const borderColor = variant === 'primary' ? 'border-surface-300' : 'border-surface-200';
  const bgColor = variant === 'primary' ? 'hover:bg-surface-100 bg-white' : 'bg-neutrals-200 hover:bg-surface-50';
  const selectedBorderColor = variant === 'primary' ? 'border-surface-600' : 'border-action-secondary-200';
  const selectedBgColor = variant === 'primary' ? 'bg-white' : 'bg-action-secondary-50 text-action-secondary-900';
  const disabledBgColor = 'bg-neutrals-200';
  const disabledBorderColor = 'border-surface-200';

  const renderClassName = (active: boolean, option: LargeItemRadioOption) => {
    const isSelected = active || option?.value === selected?.value;

    return cx(
      'relative block rounded-lg border px-3 py-2 shadow-sm focus:outline-none sm:flex sm:justify-between',
      isSelected && selectedBorderColor,
      isSelected && selectedBgColor,
      option.disabled && disabledBorderColor,
      option.disabled && disabledBgColor,
      !isSelected && !option.disabled && borderColor,
      !isSelected && !option.disabled && bgColor,
      option.disabled ? 'bg-neutrals-200 hover:bg-neutrals-200 border-surface-200 cursor-not-allowed' : 'cursor-pointer'
    );
  };
  return (
    <RadioGroup disabled={disabled} value={selected} onChange={onSelected} className="w-full">
      {srOnlyLabel && <RadioGroup.Label className="sr-only">{srOnlyLabel}</RadioGroup.Label>}
      <div className={cx('gap-4 w-full flex flex-wrap', justifyClass)}>
        {options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option}
            disabled={disabled || option.disabled || false}
            className={({ active }) => renderClassName(active, option)}
          >
            {({ checked }) => (
              <>
                <div className="flex items-center justify-between space-x-8 w-full">
                  <span className="flex flex-col text-sm">
                    <TypographyStack gap="1">
                      <RadioGroup.Label as="span">
                        <Typography
                          token="font-medium/text/sm"
                          colorWeight={option.disabled ? '300' : '900'}
                          color={selected?.value === option.value && variant === 'secondary' ? 'secondary' : 'surface'}
                        >
                          {option.tooltip ? (
                            <Tooltip id={option.name} text={option.tooltip} showIcon={false} tooltipClass="h-10">
                              <div className="flex flex-row items-center gap-2">
                                <span>{option.name}</span>
                                <div className="bg-action-tertiary-50 p-2 rounded-md">
                                  <CursorArrowRaysIcon className="text-action-tertiary-900 w-5 h-5" />
                                </div>
                              </div>
                            </Tooltip>
                          ) : (
                            <span>{option.name}</span>
                          )}
                        </Typography>
                      </RadioGroup.Label>
                    </TypographyStack>
                  </span>
                </div>

                <span
                  className={cx(
                    'border',
                    option.disabled && disabledBorderColor,
                    checked ? selectedBorderColor : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
        {dropdownOptions && (
          <Dropdown
            placeholderText={dropdownPlaceholder}
            name={dropdownName || 'dropdown_options'}
            options={dropdownOptions.map((option) => ({ label: option.name, value: option.value }))}
            value={selected?.value}
            buttonClassNames={{
              focus: 'focus:border focus:border-action-tertiary-600',
              border: dropdownOptions.find((option) => option.value === selected?.value)
                ? 'border border-action-tertiary-600'
                : 'border',
              text: 'text-sm text-surface-900 font-medium text-left',
              padding: 'py-4 px-5',
            }}
            className="text-surface-900 font-normal w-1/4"
            onSelect={(name: string, value: string) => {
              onSelected(dropdownOptions.find((option) => option.value === value) || { name, value });
            }}
          />
        )}
      </div>
    </RadioGroup>
  );
};

export default LargeItemRadioGroup;
