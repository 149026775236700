import { BoltIcon, ChevronLeftIcon, ChevronRightIcon, SparklesIcon } from '@heroicons/react/20/solid';

import Modal from '@/components/Modal';
import { Typography, TypographyStack } from '@/components/Typography';
import { Button } from '@/ui/Button';

import PlanPill from '../../PlanPill';

import TrialScreen1 from './TrialScreen1';
import TrialScreen2 from './TrialScreen2';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  screenShowing: number;
  onForward?: () => void;
  onBackward?: () => void;
  onClick?: () => void;
  isLoading?: boolean;
  trial?: any;
}

const TrialModal = ({
  isOpen,
  onClose,
  screenShowing,
  onBackward,
  onForward,
  onClick,
  isLoading = false,
  trial,
}: Props) => {
  const features = trial?.features_used || [];
  const filteredFeatures = features.filter((feature: any) => feature.used);
  const hasAtLeastOneFeature = filteredFeatures.length > 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-full max-w-3xl p-6">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <PlanPill variant="tertiary" text="Trial" Icon={SparklesIcon} />

            <TypographyStack>
              <Typography size="lg" weight="bold">
                Your free trial expires in {trial?.days_remaining || 0} days
              </Typography>
              <Typography weight="normal">
                Here&#39;s how you&#39;ve used premium features during your free trial.
              </Typography>
              <Typography weight="normal">Upgrade to continue using them.</Typography>
            </TypographyStack>
          </div>
          {hasAtLeastOneFeature && screenShowing === 1 ? (
            <TrialScreen1 features={filteredFeatures} />
          ) : (
            <TrialScreen2 trial={trial} />
          )}
        </div>
        {hasAtLeastOneFeature && (
          <div className="flex justify-center items-center w-full py-4 space-x-4">
            {screenShowing !== 1 && (
              <button type="button" onClick={onBackward} className="rounded-full bg-surface-200 p-3">
                <ChevronLeftIcon className="w-4 h-4" />
              </button>
            )}

            {screenShowing === 1 ? (
              <div className="flex justify-center items-center space-x-1">
                <div className="h-2.5 w-2.5 rounded-full bg-action-tertiary-600" />
                <div className="h-2.5 w-2.5 rounded-full bg-surface-300" />
              </div>
            ) : (
              <div className="flex justify-center items-center space-x-1">
                <div className="h-2.5 w-2.5 rounded-full bg-surface-300" />
                <div className="h-2.5 w-2.5 rounded-full bg-action-tertiary-600" />
              </div>
            )}

            {screenShowing === 1 && (
              <button type="button" onClick={onForward} className="rounded-full bg-surface-200 p-3">
                <ChevronRightIcon className="w-4 h-4" />
              </button>
            )}
          </div>
        )}
      </div>

      <div className="w-full p-y-4 flex justify-end">
        <Button type="button" size="base" Icon={BoltIcon} onClick={() => onClick?.()} loading={isLoading}>
          <span className="pl-2">Upgrade</span>
        </Button>
      </div>
    </Modal>
  );
};

export default TrialModal;
