import { useCallback } from 'react';
import { Editor } from '@tiptap/core';
import styled from 'styled-components';

import Button from '@/components/TiptapEditor/components/ui/Button';
import { Styled as StyledButton } from '@/components/TiptapEditor/components/ui/Button/styled';
import { PanelHeadline, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import colors from '@/components/TiptapEditor/lib/colors';
import { useThemeData } from '@/components/TiptapEditor/lib/hooks/useThemeData';

const Styled = {
  ButtonGroup: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    & > ${StyledButton.Button} {
      color: ${colors.white[5]};
      flex: 0 0 calc(33.333333% - 0.25rem);
      justify-content: center;
      text-align: center;
    }
  `,
};

export const BorderStyle = ({ editor }: { editor: Editor }) => {
  const attrs = editor.getAttributes('imageBlock');
  const borderStyles = useThemeData('border-styles');

  const setBorderStyle = useCallback(
    (style: string) => {
      editor.commands.setImageBlockBorderStyle(style as 'solid' | 'dashed' | 'dotted');
    },
    [editor]
  );

  return (
    <PanelSection>
      <PanelHeadline>Style</PanelHeadline>
      <Styled.ButtonGroup>
        {borderStyles.map((borderStyle) => {
          const isActive = attrs.borderStyle === borderStyle.key || (!attrs.borderStyle && borderStyle.key === 'solid');

          return (
            <Button
              key={borderStyle.key}
              onClick={() => setBorderStyle(borderStyle.key)}
              $active={isActive}
              $variant="quaternary"
              $size="small"
              $muted={borderStyle.key === 'solid'}
            >
              {borderStyle.label}
            </Button>
          );
        })}
      </Styled.ButtonGroup>
    </PanelSection>
  );
};

export default BorderStyle;
