import { GiphyGif } from '../../../../utils/giphy.types';

import ImageItem from './ImageItem';

export type GiphyImageGridProps = {
  images: GiphyGif[];
  onSelect: (image: GiphyGif) => void;
};

const GiphyImageGrid = ({ images, onSelect }: GiphyImageGridProps) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {images.map((image) => (
        <ImageItem key={image.id} image={image} onSelect={onSelect} />
      ))}
    </div>
  );
};

export default GiphyImageGrid;
