import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { StripeAccountStatus, StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import Badge, { BadgeProps } from '@/ui/Badge';
import { Card } from '@/ui/Card';

import Connect from './Connect';
import ContinueOnboarding from './ContinueOnboarding';
import Manage from './Manage';
import StripeLogo from './StripeLogo';

interface Props {
  publicationId: string;
  boostsAccountStatus: StripeAccountStatus | undefined;
  boostsAccountType: StripeAccountType | undefined | null;
  isLoading: boolean;
  isError: boolean;
}

const GeneralInfo = ({ publicationId, boostsAccountStatus, boostsAccountType, isLoading, isError }: Props) => {
  const accountTypeText = boostsAccountType
    ? boostsAccountType.charAt(0).toUpperCase() + boostsAccountType.slice(1)
    : 'Not Connected';
  const boostsAccountStatusText = boostsAccountStatus
    ? boostsAccountStatus.charAt(0).toUpperCase() + boostsAccountStatus.slice(1)
    : 'Not Connected';

  const statusBadgeType = {
    [StripeAccountStatus.MISSING]: 'information',
    [StripeAccountStatus.ACTIVE]: 'success',
    [StripeAccountStatus.INACTIVE]: 'alert',
    [StripeAccountStatus.PENDING]: 'warning',
  }[boostsAccountStatus || StripeAccountStatus.MISSING];

  const statusExplanation = {
    [StripeAccountStatus.MISSING]: 'You have not connected a Stripe account yet.',
    [StripeAccountStatus.ACTIVE]: 'Your Stripe account is connected and ready to accept payments.',
    [StripeAccountStatus.INACTIVE]: 'Your Stripe account is connected but not ready to accept payments.',
    [StripeAccountStatus.PENDING]: 'Your Stripe account is connected but is being processed by Stripe.',
  }[boostsAccountStatus || StripeAccountStatus.MISSING];

  const statusBadge = () => {
    return (
      <Badge type={statusBadgeType as BadgeProps['type']} tooltip={statusExplanation}>
        {boostsAccountStatusText}
      </Badge>
    );
  };

  const accountTypeBadgeType = {
    not_connected: 'information',
    [StripeAccountType.STANDARD]: 'success',
    [StripeAccountType.EXPRESS]: 'info_blue',
  }[boostsAccountType || 'not_connected'];

  const accountTypeExplanation = {
    not_connected: 'You have not connected a Stripe account yet.',
    [StripeAccountType.STANDARD]: 'Your connected Stripe account is a personal or business account.',
    [StripeAccountType.EXPRESS]: 'Your connected Stripe account is an express account created by beehiiv.',
  }[boostsAccountType || 'not_connected'];

  const accountTypeBadge = () => {
    return (
      <Badge type={accountTypeBadgeType as BadgeProps['type']} tooltip={accountTypeExplanation}>
        {accountTypeText}
      </Badge>
    );
  };

  return (
    <Card>
      <BodyContainer>
        <CardHeader
          title="Stripe Express Payment Account"
          description={
            <TypographyStack gap="4">
              <Typography token="font-normal/text/sm" colorWeight="500">
                A Stripe Express Account will allow you to monetize your publication through Boosts or Ads. You can view
                your balance, payouts, and manage account settings
              </Typography>
              <Typography token="font-normal/text/sm" colorWeight="500">
                Please note, this is publication specific and is separate from your Workspace Stripe Express Billing
                account
              </Typography>
            </TypographyStack>
          }
        />
        <LoadingBox isLoading={isLoading} isError={isError}>
          {boostsAccountStatus !== StripeAccountStatus.MISSING ? (
            <TypographyRow gap="2">
              <Typography token="font-medium/text/sm">Connected Account Type</Typography>
              {accountTypeBadge()}
            </TypographyRow>
          ) : null}
          <div className="flex flex-row gap-x-3 border-t border-surface-200 pt-6">
            <div className="flex px-3 py-2 h-12 w-12 rounded-md overflow-hidden border border-surface-100 items-center justify-center">
              <StripeLogo />
            </div>
            <TypographyStack>
              <TypographyRow gap="2">
                <Typography token="font-medium/text/sm">Account Transfer Status</Typography>
                {statusBadge()}
              </TypographyRow>
              <Typography token="font-normal/text/sm" colorWeight="500">
                To withdraw earnings, transfers must be active on your Stripe account
              </Typography>
            </TypographyStack>
          </div>
          <ContinueOnboarding
            boostsAccountStatus={boostsAccountStatus}
            boostsAccountType={boostsAccountType}
            publicationId={publicationId}
          />
          <Connect
            publicationId={publicationId}
            boostsAccountType={boostsAccountType}
            boostsAccountStatus={boostsAccountStatus}
          />
          <Manage
            publicationId={publicationId}
            boostsAccountStatus={boostsAccountStatus}
            boostsAccountType={boostsAccountType}
          />
        </LoadingBox>
      </BodyContainer>
    </Card>
  );
};

export default GeneralInfo;
