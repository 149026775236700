import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Node } from '@tiptap/pm/model';
import { NodeViewWrapper } from '@tiptap/react';
import { AxiosError, AxiosResponse } from 'axios';

import { Milestone } from '@/interfaces/milestone';
import { ReferralProgramLayout } from '@/interfaces/referral_program';

import { API } from '../../../lib/api';

import { Styled } from './ReferralProgramView.styled';
import { UpcomingMilestones } from './UpcomingMilestones';

interface Props {
  node: Node;
  extension: {
    options: {
      publicationId: string;
    };
  };
}

const shouldFetchMilestones = (layoutType: ReferralProgramLayout) =>
  [ReferralProgramLayout.UPCOMING_MILESTONES_AND_REWARDS, ReferralProgramLayout.REWARDS_ONLY].includes(layoutType);

export const ReferralProgramView = ({
  node,
  extension: {
    options: { publicationId },
  },
}: Props) => {
  const [data, setData] = useState({ name: '', url: '' });
  const [layout, setLayout] = useState<ReferralProgramLayout>(ReferralProgramLayout.DEFAULT);
  const [milestones, setMilestones] = useState<Milestone[]>([]);

  useEffect(() => {
    API.getPublication({
      publicationId,
    })
      .then((res: AxiosResponse) => {
        setData({ name: res.data.name, url: `${res.data.url}subscribe?ref=PLACEHOLDER` });
      })
      .catch((errPayload: AxiosError) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });

    API.getReferralProgram({
      publicationId,
    })
      .then((res: AxiosResponse) => {
        setLayout(res.data.block_layout);
      })
      .catch((errPayload: AxiosError) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [publicationId]);

  useEffect(() => {
    if (shouldFetchMilestones(layout)) {
      API.getReferralProgramMilestones({
        publicationId,
      })
        .then((res: AxiosResponse) => {
          setMilestones(res.data.milestones || []);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    }
  }, [layout, publicationId]);

  if (layout === ReferralProgramLayout.REWARDS_ONLY) {
    return (
      <NodeViewWrapper
        data-drag-handle
        {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
      >
        <UpcomingMilestones milestones={milestones} />
        <Styled.Footnote>
          Your unique referral link to share:{' '}
          <a href={data.url} rel="noreferrer" target="_blank">
            {data.url}
          </a>
        </Styled.Footnote>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper
      data-drag-handle
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <Styled.Headline>Share {data.name}</Styled.Headline>
      <Styled.Paragraph>
        You currently have <strong>X</strong> referrals.
      </Styled.Paragraph>
      <Styled.Button href={data.url} target="_blank" className="content-element-button">
        Click to Share
      </Styled.Button>
      <UpcomingMilestones milestones={milestones} />
      <Styled.Footnote>
        Or copy and paste this link to others:{' '}
        <a href={data.url} rel="noreferrer" target="_blank">
          {data.url}
        </a>
      </Styled.Footnote>
    </NodeViewWrapper>
  );
};

export default ReferralProgramView;
