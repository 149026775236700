import { useCallback } from 'react';

import { SimpleInput, SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Text } from '@/routes/website/_components/UI/Text';

import { AttributeSettingProps } from '../types';

function parsePxToNumber(value: string, unit: string) {
  try {
    // Ensure the value is a string and remove "px" if it exists
    const numericValue = parseFloat(value.replace(unit, '').trim());
    return numericValue;
  } catch (error) {
    return 0;
  }
}

type SimpleNumberSettingsProps = AttributeSettingProps & {
  title: string;
  property: string;
  unit: string;
  min?: number;
  max?: number;
};

export const SimpleNumberSettings = ({
  editor,
  activeNodeResult,
  property,
  title,
  unit,
  min = 0,
  max = 100,
}: SimpleNumberSettingsProps) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!activeNodeAttributes) return;

      const value = `${e.target.value}${unit}`;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [editor, activeNodePos, property, unit, activeNodeAttributes]
  );

  return (
    <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
      <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <SimpleInputWrapper className="h-[32px] relative">
        <SimpleInput
          key={`${activeNodePos}-button-text`}
          type="number"
          min={min}
          max={max}
          className="text-xs"
          defaultValue={parsePxToNumber(activeNodeAttributes[property], unit)}
          onChange={handleChange}
        />
        {unit && (
          <Text size="2xs" weight="medium" variant="secondary" className="absolute top-1/2 right-3 -translate-y-1/2">
            {unit}
          </Text>
        )}
      </SimpleInputWrapper>
    </div>
  );
};
