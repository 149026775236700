const EntriLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="24" viewBox="0 0 75 24" fill="none">
      <g clipPath="url(#clip0_1868_2904)">
        <path
          d="M17.1258 0C15.2513 0 13.6964 1.2496 13.4194 2.86448L12.9082 7.26696L17.4666 6.78632C19.3836 6.63252 20.896 5.17144 20.896 3.38355C20.8747 1.51875 19.1919 0 17.1258 0Z"
          fill="currentColor"
        />
        <path
          d="M0 13.4954C0 16.5137 2.7052 18.9744 6.07072 18.9744C9.2658 18.9744 11.8858 16.7251 12.1201 13.8991L12.9082 7.2666L5.4743 8.03558C2.38569 8.32393 0 10.6501 0 13.4954Z"
          fill="currentColor"
        />
        <path
          d="M31.5891 17.3796C31.7382 12.2658 28.6709 8.78613 23.7078 8.78613C18.9577 8.78613 15.6561 11.9967 15.6561 16.303C15.6561 20.4748 18.6595 23.9544 23.8356 23.9544C28.2236 23.9544 30.5454 21.5513 31.4187 19.3982H27.1585C26.3278 20.667 25.071 21.0707 23.793 21.0707C21.8334 21.0707 19.9802 19.8596 19.7459 17.3796H31.5891ZM23.6865 11.4968C25.4118 11.4968 27.3289 12.3619 27.4141 14.8035H19.8098C20.1293 12.3619 22.1316 11.4968 23.6865 11.4968Z"
          fill="currentColor"
        />
        <path
          d="M37.5746 9.11297H33.4635V23.5892H37.5746V16.0531C37.5746 13.7653 38.171 11.766 40.8762 11.766C43.198 11.766 43.6453 13.4001 43.6453 15.2649V23.5892H47.7563V14.4574C47.7563 10.8816 46.0523 8.78613 42.2181 8.78613C40.2158 8.78613 38.4905 9.53587 37.5746 11.0162V9.11297Z"
          fill="currentColor"
        />
        <path
          d="M51.0366 9.11295H48.7787V11.6698H51.0366V19.6865C51.0366 22.5702 51.7182 23.5891 55.4246 23.5891H58.428V20.9169H57.1712C55.7015 20.9169 55.1476 20.6285 55.1476 19.1482V11.6698H58.4919V9.11295H55.1476V5.17188H51.0366V9.11295Z"
          fill="currentColor"
        />
        <path
          d="M69.5469 9.18989C69.27 9.17065 68.9292 9.13223 68.6523 9.13223C66.7779 9.13223 65.3508 9.82432 64.4135 11.6315V9.09375H60.3663V23.5699H64.4774V15.9762C64.4774 13.2656 65.8194 12.5926 67.8429 12.5926C68.4393 12.5926 68.9505 12.5926 69.5469 12.6695V9.18989Z"
          fill="currentColor"
        />
        <path
          d="M70.889 9.11234V23.5885H75V9.11234H70.889ZM72.8913 4.45996C71.7837 4.45996 70.8677 5.2674 70.8677 6.28631C70.8677 7.28596 71.7624 8.11267 72.8913 8.11267H72.9765C74.0841 8.11267 75 7.30521 75 6.28631C75 5.28662 74.1054 4.45996 72.9765 4.45996H72.8913Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1868_2904">
          <rect width="75" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EntriLogo;
