import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '../ActionModal';
import SelectAutomation from '../SelectAutomation/SelectAutomation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (automationIds: string[]) => Promise<void>;
}

const SelectAutomationModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedAutomations, setSelectedAutomations] = useState<string[]>([]);

  const onSelectAutomation = (automationId: string) => setSelectedAutomations([...selectedAutomations, automationId]);

  const onDeselectAutomation = (automationId: string) => {
    const newAutomations = [...selectedAutomations];
    const indexOfDeselectedValue = newAutomations.indexOf(automationId);

    if (indexOfDeselectedValue !== -1) {
      newAutomations.splice(indexOfDeselectedValue, 1);
      setSelectedAutomations(newAutomations);
    }
  };

  const onDeselectAllAutomations = () => {
    setSelectedAutomations([]);
  };

  const handleSubmit = () => {
    setIsProcessing(true);

    if (selectedAutomations.length) {
      onSubmit(selectedAutomations).finally(() => {
        setIsProcessing(false);
        setSelectedAutomations([]);
      });
    } else {
      toast.error('Please select an automation');
    }
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onProceed={handleSubmit}
      onClose={onClose}
      resourceId=""
      modalHeightClassName="h-[74vh] md:h-128"
      isWorking={isProcessing}
      headerText="Choose Automation(s)"
      actionText="Enroll"
      disabled={!selectedAutomations.length}
    >
      <SelectAutomation
        onSelect={onSelectAutomation}
        onDeselect={onDeselectAutomation}
        onDeselectAll={onDeselectAllAutomations}
        selectedAutomations={selectedAutomations}
      />
    </ActionModal>
  );
};

export default SelectAutomationModal;
