import { useInfiniteQuery } from 'react-query';

import { AdNetworkCampaign, AdNetworkCampaignStatus } from '../../../interfaces/ad_network/internal/campaign';
import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  campaigns: AdNetworkCampaign[];
  pagination: Pagination;
}

interface Props {
  id: string;
  status?: AdNetworkCampaignStatus;
}

const useAdvertiserCampaigns = ({ id, status }: Props) => {
  const fetchCampaigns = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/ad_network/internal/advertisers/${id}/campaigns`, {
        params: {
          page: pageParam,
          per_page: 25,
          status,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_network', 'internal', 'advertisers', id, 'campaigns', status],
    fetchCampaigns,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
};

export default useAdvertiserCampaigns;
