import { createEnumParam, useQueryParams, withDefault } from 'use-query-params';

import Grid, { Row } from '@/components/Grid';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import { useRequireSetting } from '@/hooks';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { useTutorial } from '@/hooks/useTutorials';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import AcquisitionsByPeriod from './AcquisitionsByPeriod';
import AcquisitionSource from './AcquisitionSource';
import Stats from './Stats';
import StripeLink from './StripeLink';
import SubscriptionsByCountry from './SubscriptionsByCountry';
import SubscriptionsByPlanInterval from './SubscriptionsByPlanInterval';

const Subscriptions = () => {
  useRequireSetting({
    setting: Setting.PREMIUM_SUBSCRIPTIONS,
    message: 'Upgrade to use paid subscriptions.',
    redirectTo: '/monetize/subscriptions/overview',
  });

  const tutorial = useTutorial(TutorialType.PREMIUM_SUBSCRIPTIONS);

  const PeriodParam = withDefault(createEnumParam(Object.values(TimePeriod)), TimePeriod.ALL_TIME);
  const [queryParams, setQueryParams] = useQueryParams({
    period: PeriodParam,
  });

  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: queryParams.period });

  const handleTimePeriodSelect = (timePeriod: TimePeriod) => {
    setQueryParams({ ...queryParams, period: timePeriod });
  };

  return (
    <>
      <PageHeading title="Paid Subscriptions" description="Insight into your paid subscriptions." tutorial={tutorial} />

      <Grid gap={6}>
        <Row className="grid mb-2 w-full">
          <div className="w-full flex flex-row justify-center md:w-auto md:justify-self-end items-baseline">
            <StripeLink />
            <TimePeriodSelect handleChange={handleTimePeriodSelect} />
          </div>
        </Row>

        <Row gap={4}>
          <Stats />
        </Row>

        <Row className="flex flex-row flex-wrap w-full space-y-6 lg:space-y-0 lg:flex-nowrap lg:space-x-6">
          <div className="w-full lg:w-1/3">
            <SubscriptionsByPlanInterval period={period as keyof typeof timePeriodLabels} />
          </div>
          <div className="w-full lg:w-2/3">
            <AcquisitionSource period={period as keyof typeof timePeriodLabels} />
          </div>
        </Row>

        <Row className="flex flex-row flex-wrap w-full space-y-6 lg:space-y-0 lg:flex-nowrap lg:space-x-6">
          <div className="w-full lg:w-1/3">
            <SubscriptionsByCountry period={period as keyof typeof timePeriodLabels} />
          </div>
          <div className="w-full lg:w-2/3">
            <AcquisitionsByPeriod period={period as keyof typeof timePeriodLabels} />
          </div>
        </Row>
      </Grid>
    </>
  );
};

export default Subscriptions;
