import { AdNetworkCampaignPerformanceOpportunity } from '@/interfaces/ad_network/internal/campaign_performance_opportunity';
import { AdNetworkOpportunityStatus } from '@/interfaces/ad_network/internal/opportunity';

import StatusIndicator from './StatusIndicator';

const CampaignPerformanceOpportunityStatusIndicator = ({
  opportunity,
}: {
  opportunity: AdNetworkCampaignPerformanceOpportunity;
}) => {
  const color = {
    [AdNetworkOpportunityStatus.DRAFT]: 'gray',
    [AdNetworkOpportunityStatus.PENDING]: 'blue',
    [AdNetworkOpportunityStatus.ACCEPTED]: 'green',
    [AdNetworkOpportunityStatus.REJECTED]: 'red',
    [AdNetworkOpportunityStatus.CANCELED]: 'yellow',
    [AdNetworkOpportunityStatus.EXPIRED]: 'red',
  }[opportunity.opportunity_status] as 'blue' | 'gray' | 'green' | 'red' | 'yellow';

  return <StatusIndicator color={color} label={opportunity.opportunity_status} />;
};

export default CampaignPerformanceOpportunityStatusIndicator;
