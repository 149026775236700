import { useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

import { BillingDetails } from '@/interfaces/billing';
import { Button } from '@/ui/Button';
import { PLAN } from '@/utils/plans';

import Body from '../components/PlanCard/Body';
import Header from '../components/PlanCard/Header';

interface Props {
  billingDetails: BillingDetails | undefined;
  onCancel: () => void;
  onDowngrade: () => void;
  onPreviousStep: () => void;
  isLoading: boolean;
  isDowngrading: boolean;
}

const Confirmation = (props: Props) => {
  const { billingDetails, onCancel, onDowngrade, onPreviousStep, isLoading, isDowngrading } = props;
  const [acknowledgeChanges, setAcknowledgeChanges] = useState(false);

  const currentPlan = {
    name: billingDetails?.current_plan.name || '',
    max_subscriptions: billingDetails?.organization_max_subscriptions || 0,
    max_publications: billingDetails?.current_plan.name === PLAN.MAX ? 10 : 3,
  };

  const launchPlan = { name: 'Launch', max_subscriptions: 2500, max_publications: 3 };

  const onToggleAcknowledgeChanges = () => {
    setAcknowledgeChanges(!acknowledgeChanges);
  };

  return (
    <div className="flex flex-col gap-10">
      <div>
        <h1 className="text-xl font-semibold text-gray-900">Confirm your changes</h1>
      </div>

      <div className="lg:flex items-start justify-between space-y-8 lg:space-y-0">
        <div className="flex-initial lg:w-full flex flex-col gap-6">
          {billingDetails && (
            <Header billingDetails={billingDetails} currentPlan={currentPlan} newPlan={launchPlan} renderTopRowArrow />
          )}
          {billingDetails && (
            <Body
              billingDetails={billingDetails}
              currentPlan={currentPlan}
              onToggleAcknowledgeChanges={onToggleAcknowledgeChanges}
              confirmationStep
            />
          )}
        </div>
      </div>

      <div className="flex flex-col gap-y-2 gap-x-2 justify-end sm:flex-row sm:justify-between">
        <Button variant="flush" Icon={ArrowLeftIcon} onClick={onPreviousStep}>
          Back
        </Button>

        <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-2">
          <Button variant="primary-inverse" onClick={onCancel}>
            Nevermind, keep my plan
          </Button>
          <Button
            variant="danger"
            loading={isLoading}
            disabled={!acknowledgeChanges || isDowngrading}
            onClick={onDowngrade}
          >
            Cancel my plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
