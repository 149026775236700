import React from 'react';
import { CloudArrowDownIcon } from '@heroicons/react/24/solid';

import { Button } from '@/ui/Button';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';

import { useExportDataContext } from './context';

const buttonTitles = {
  basic_subscriber: 'Export All Basic Subscribers',
  subscribers: 'Export All Subscribers',
  posts: 'Export All Posts',
};

interface ExportActionProps {
  title: string;
  description: string;
  type: 'basic_subscriber' | 'subscribers' | 'posts';
}

const ExportAction: React.FC<ExportActionProps> = ({ title, description, type }) => {
  const { createExport, exportingType } = useExportDataContext();
  const isExporting = exportingType === type;
  const buttonTitle = buttonTitles[type];

  return (
    <BodyContainer>
      <CardHeader title={title} description={description} />

      <Button
        className="w-fit"
        size="xs"
        variant="primary"
        type="submit"
        disabled={isExporting}
        disableWith="Exporting..."
        Icon={CloudArrowDownIcon}
        onClick={() => createExport(type)}
      >
        {buttonTitle}
      </Button>
    </BodyContainer>
  );
};

export default ExportAction;
