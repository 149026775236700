import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import {
  DEFAULT_BACKGROUND_SECONDARY_COLOR,
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
  WHITE_COLOR,
} from '../constants';

import { UpdatePasswordView } from './views/UpdatePasswordView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    updatePassword: {
      setUpdatePassword: () => ReturnType;
    };
  }
}

export const UpdatePassword = Node.create<{}, {}>({
  name: 'updatePassword',

  group: 'block',

  draggable: true,

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      orientation: {
        default: 'vertical',
        renderHTML: (attributes) => ({ 'data-orientation': attributes.orientation }),
        parseHTML: (element) => element.getAttribute('data-orientation') || 'vertical',
      },
      gap: {
        default: '8px',
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
        parseHTML: (element) => element.getAttribute('data-gap') || '8px',
      },
      borderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
        parseHTML: (element) => element.getAttribute('data-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      borderWidth: {
        default: '0px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '0px',
      },
      borderColor: {
        default: DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_PRIMARY_COLOR,
      },
      borderStyle: {
        default: 'none',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },
      padding: {
        default: '4px',
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
        parseHTML: (element) => element.getAttribute('data-padding') || '4px',
      },
      backgroundColor: {
        default: WHITE_COLOR,
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
        parseHTML: (element) => element.getAttribute('data-background-color') || WHITE_COLOR,
      },
      height: {
        default: '50px',
        renderHTML: (attributes) => ({ 'data-height': attributes.height }),
        parseHTML: (element) => element.getAttribute('data-height'),
      },
      width: {
        default: '550px',
        renderHTML: (attributes) => ({ 'data-width': attributes.width }),
        parseHTML: (element) => element.getAttribute('data-width') || '550px',
      },
      passwordInputPlaceholder: {
        default: 'Enter your password',
        parseHTML: (element) => element.getAttribute('data-email-input-placeholder') || 'Enter your password',
        renderHTML: (attributes) => ({ 'data-email-input-placeholder': attributes.emailInputPlaceholder }),
      },
      inputBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-border-color') || DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-input-border-color': attributes.inputBorderColor }),
      },
      inputBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-input-border-width') || '1px',
        renderHTML: (attributes) => ({ 'data-input-border-width': attributes.inputBorderWidth }),
      },
      inputBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-input-border-radius') || DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-input-border-radius': attributes.inputBorderRadius }),
      },
      inputBorderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-input-border-style') || 'solid',
        renderHTML: (attributes) => ({ 'data-input-border-style': attributes.inputBorderStyle }),
      },
      inputPlaceholderColor: {
        default: '#6B7280',
        parseHTML: (element) => element.getAttribute('data-input-placeholder-color') || '#6B7280',
        renderHTML: (attributes) => ({ 'data-input-placeholder-color': attributes.inputPlaceholderColor }),
      },
      inputTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-text-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-input-text-color': attributes.inputTextColor }),
      },
      inputBackgroundColor: {
        default: DEFAULT_BACKGROUND_SECONDARY_COLOR,
        parseHTML: (element) =>
          element.getAttribute('data-input-background-color') || DEFAULT_BACKGROUND_SECONDARY_COLOR,
        renderHTML: (attributes) => ({ 'data-input-background-color': attributes.inputBackgroundColor }),
      },
      buttonBackgroundColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-background-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-button-background-color': attributes.buttonBackgroundColor }),
      },
      buttonBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-button-border-width') || '1px',
        renderHTML: (attributes) => ({ 'data-button-border-width': attributes.buttonBorderWidth }),
      },
      buttonBorderColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-border-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-button-border-color': attributes.buttonBorderColor }),
      },
      buttonBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-button-border-style') || 'none',
        renderHTML: (attributes) => ({ 'data-button-border-style': attributes.buttonBorderStyle }),
      },
      buttonBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-button-border-radius') || DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-button-border-radius': attributes.buttonBorderRadius }),
      },
      buttonTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-text-color') || DEFAULT_TEXT_ON_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-button-text-color': attributes.buttonTextColor }),
      },
      buttonText: {
        default: 'Update',
        parseHTML: (element) => element.getAttribute('data-button-text') || 'Update',
        renderHTML: (attributes) => ({ 'data-button-text': attributes.buttonText }),
      },
      buttonSubmittingText: {
        default: 'Updating...',
        parseHTML: (element) => element.getAttribute('data-button-submitting-text') || 'Updating...',
        renderHTML: (attributes) => ({ 'data-button-submitting-text': attributes.buttonSubmittingText }),
      },
      textColor: {
        default: DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-text-color': attributes.textColor }),
        parseHTML: (element) => element.getAttribute('data-text-color') || DEFAULT_PRIMARY_COLOR,
      },
      fontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-font-family') || 'inherit',
        renderHTML: (attributes) => ({ 'data-font-family': attributes.fontFamily }),
      },
    };
  },

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setResetPassword:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(UpdatePasswordView, { className: 'w-full' });
  },
});

export default UpdatePassword;
