import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  ArrowTopRightOnSquareIcon,
  ClipboardIcon,
  CursorArrowRaysIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCopyToClipboard, useCurrentPublication } from '@/hooks';
import { useUpdateCustomPage } from '@/hooks/useCustomPages';
import { useCreateNavGroup } from '@/hooks/useNavGroups';
import { useDeleteNavLink, useUpdateNavLink } from '@/hooks/useNavlinks';
import { NavGroup } from '@/interfaces/nav_group';
import { NavLink } from '@/interfaces/nav_link';
import { LayoutPageData } from '@/routes/website_builder';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import OptionsDropdown from '@/routes/website_builder/_components/Helpers/OptionsDropdown';
import { capitalize } from '@/utils';

import EditLinkPanel from '../AddPage/EditLinkPanel';

interface Props {
  navLink: NavLink;
  iconOrientation?: 'horizontal' | 'vertical';
}

const LinkActions = ({ navLink, iconOrientation = 'horizontal' }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: currentPublication } = useCurrentPublication();
  const { pageData, handleSaved } = usePageContext<LayoutPageData>();
  const copy = useCopyToClipboard();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const isPageLive = navLink.nav_group?.id || navLink?.custom_page?.status === 'live';
  const isExternalLink = navLink.external_link;
  const isStandAlonePage = !navLink?.nav_group?.id;
  const pageUrlText = `${currentPublication?.url}c/${navLink.href}`;

  const deleteNavLink = useDeleteNavLink({
    navLinkId: navLink?.id || '',
    onSuccess: () => {
      setConfirmDelete(false);
      queryClient.invalidateQueries(['nav_links', currentPublicationId]);
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      handleSaved();
    },
  });
  const handleDelete = async () => {
    deleteNavLink.mutate();
  };

  const updateCustomPage = useUpdateCustomPage({
    customPageId: navLink?.custom_page?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['nav_links', currentPublicationId]);
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      handleSaved();
    },
  });

  const updateNavLink = useUpdateNavLink({
    navLinkId: navLink?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['nav_links', currentPublicationId]);
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      handleSaved();
    },
  });

  const handleSetLive = (id: string) => {
    if (navLink?.external_link) {
      updateNavLink.mutate({
        nav_group_id: id,
      });
    } else {
      updateCustomPage.mutate({
        custom_page: {
          status: 'live',
        },
        nav_link: {
          nav_group_id: id,
        },
      });
    }
  };

  const handleSetToDraft = () => {
    if (navLink?.external_link) {
      updateNavLink.mutate({
        nav_group_id: null,
      });
    } else {
      updateCustomPage.mutate({
        custom_page: {
          status: 'draft',
        },
        nav_link: {
          nav_group_id: null,
        },
      });
    }
  };

  const createNavGroup = useCreateNavGroup({
    onSuccess: (data) => {
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      handleSetLive(data.nav_group.id);
      handleSaved();
    },
    onError: () => {},
  });

  const handleCreateNavGroup = () => {
    createNavGroup.mutate({ title: navLink.cta_text, group_type: 'nav' });
  };

  const handleMoveToStandalonePage = () => {
    updateCustomPage.mutate({
      custom_page: {
        status: 'live',
      },
      nav_link: {
        nav_group_id: null,
      },
    });
  };

  const filteredNavGroups: any = pageData?.navGroups?.filter?.(
    (group) => !group.is_category_group && navLink?.nav_group?.id !== group.id
  );
  const navGroupOptions =
    filteredNavGroups?.map((group: NavGroup) => {
      return {
        label: `Navbar: ${capitalize(group.title)}`,
        onClick: () => handleSetLive(group.id),
      };
    }) || [];

  if (!isPageLive || isStandAlonePage) {
    navGroupOptions.push({
      label: 'New Menu',
      onClick: () => handleCreateNavGroup(),
    });
  }

  if ((!isPageLive || !isStandAlonePage) && !isExternalLink) {
    navGroupOptions.push({
      label: 'Standalone page',
      onClick: handleMoveToStandalonePage,
    });
  }

  if (isPageLive) {
    navGroupOptions.push({
      label: 'Drafts',
      onClick: () => handleSetToDraft(),
    });
  }

  const handleClickEdit = () => {
    if (navLink.external_link === true) {
      setIsEditing(true);
    } else {
      navigate(`/website_builder/custom_pages/${navLink.custom_page_id || navLink.custom_page.id}`);
    }
  };

  return (
    <div>
      <OptionsDropdown
        iconOrientation={iconOrientation}
        options={[
          {
            label: 'Move to...',
            Icon: CursorArrowRaysIcon,
            onClick: () => {},
            chainedDropdown: {
              alignRight: true,
              options: [...navGroupOptions],
            },
          },
          ...(isPageLive
            ? [
                {
                  label: 'View',
                  Icon: ArrowTopRightOnSquareIcon,
                  onClick: () => {
                    window.open(`${pageUrlText}`, '_blank');
                  },
                },
                {
                  label: 'Copy',
                  Icon: ClipboardIcon,
                  onClick: () => {
                    copy({ text: pageUrlText });
                  },
                },
              ]
            : []),
          {
            label: 'Edit',
            Icon: PencilIcon,
            onClick: handleClickEdit,
          },
          { label: 'Delete', Icon: TrashIcon, onClick: () => setConfirmDelete(true) },
        ]}
      />
      <ActionModal
        isOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onProceed={handleDelete}
        resourceId={navLink.id}
        isWorking={deleteNavLink.isLoading}
        headerText="Delete"
        actionText="Delete"
        buttonType="danger"
      >
        <p className="text-sm">
          {navLink.external_link
            ? 'Are you sure you want to delete this link?'
            : 'Are you sure you want to delete this page? You will not be able to recover your work.'}
        </p>
      </ActionModal>
      {navLink.external_link && pageData?.navGroups && (
        <EditLinkPanel
          navLink={navLink}
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          navGroups={pageData?.navGroups}
        />
      )}
    </div>
  );
};

export default LinkActions;
