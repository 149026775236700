import { useNavigate } from 'react-router-dom';
import { ArrowUpRightIcon, PlusIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';
import SearchInput from '@/ui/SearchInput';

import { usePreferencesContext } from '../context';

const Header = () => {
  const { setSearchQuery } = usePreferencesContext();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-y-2 sm:flex-row sm:justify-end sm:gap-x-4">
      <div className="w-full sm:w-60">
        <SearchInput onSearch={setSearchQuery} placeholder="Search preferences" />
      </div>
      <Button
        variant="primary-inverse"
        type="button"
        Icon={ArrowUpRightIcon}
        iconRight
        size="sm"
        onClick={() => navigate('/website_builder/settings/static_pages')}
      >
        Configure Preference Center
      </Button>
      <Button
        variant="secondary"
        type="button"
        Icon={PlusIcon}
        size="sm"
        onClick={() => navigate('/subscribers/preferences/new')}
      >
        New Preference
      </Button>
    </div>
  );
};

export default Header;
