import { useInfiniteQuery } from 'react-query';

import { Pagination } from '@/interfaces/general';
import { SpamContentVectorPreview } from '@/interfaces/system_admin/spam_content_vector';
import api from '@/services/swarm';

interface ApiResponse {
  spam_content_vector_previews: SpamContentVectorPreview[];
  pagination: Pagination;
}

interface Props {
  status: 'confirmed' | 'unconfirmed';
  search?: string;
  perPage?: number;
  random?: boolean;
}

const useSpamContentVectors = ({ search, status = 'unconfirmed', perPage = 15, random = false }: Props) => {
  const fetchSpamContentVectors = ({ pageParam = 1 }) =>
    api
      .get(`/system_admin/spam_content_vectors`, {
        params: {
          page: pageParam,
          per_page: perPage,
          q: search,
          status,
          random,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['system_admin', 'spam_content_vectors', status, perPage, search],
    fetchSpamContentVectors,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
};

export default useSpamContentVectors;
