import KPI from '@/components/KPI';
import KPIGroup from '@/components/KPIGroup';
import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import { TimePeriod } from '@/interfaces/time_period';

import getLocaleString from '../../../utils/getLocaleString';
import { PageProps } from '../types';
import getNormalizedTimePeriod from '../utils/getNormalizedTimePeriod';

interface Props {
  subscriberKPIs?: any;
  isLoadingSubsKPI: boolean;
}

const KPIs = ({ isLoadingSubsKPI, subscriberKPIs }: Props) => {
  const { period } = usePageContext<PageProps>();
  const isAllTime = period === TimePeriod.ALL_TIME;

  const lagNetSubscribers = subscriberKPIs ? subscriberKPIs?.net_subscriptions?.previous_period : 0;
  const lagNewSubscribers = subscriberKPIs ? subscriberKPIs?.new_subscriptions?.previous_period : 0;
  const lagUnsubscribes = subscriberKPIs ? subscriberKPIs?.unsubscribed?.previous_period : 0;
  const unsubscribesDelta = subscriberKPIs ? subscriberKPIs?.unsubscribed?.delta : 0;
  const invertedUnsubscribesDelta = unsubscribesDelta ? unsubscribesDelta * -1 : 0;

  const normalizeTimePeriod = getNormalizedTimePeriod(period);

  return (
    <KPIGroup>
      <KPI
        title="New Subscribers"
        isLoading={isLoadingSubsKPI}
        pctDelta={subscriberKPIs ? subscriberKPIs?.new_subscriptions?.delta : 0}
        value={getLocaleString(subscriberKPIs ? subscriberKPIs?.new_subscriptions?.period : 0)}
        previousValue={`${getLocaleString(lagNewSubscribers)} ${normalizeTimePeriod}`}
        isAllTime={isAllTime}
        tooltipText="Subscribers who joined the list during the selected time period. This may include subscribers who joined the list and then unsubscribed during the same time period."
      />
      <KPI
        title="Unsubscribes"
        isLoading={isLoadingSubsKPI}
        pctDelta={invertedUnsubscribesDelta}
        value={getLocaleString(subscriberKPIs ? subscriberKPIs?.unsubscribed?.period : 0)}
        previousValue={`${getLocaleString(lagUnsubscribes)} ${normalizeTimePeriod}`}
        isAllTime={isAllTime}
        tooltipText="Subscribers who unsubscribed during the selected time period."
      />
      <KPI
        title="Net Subscribers"
        isLoading={isLoadingSubsKPI}
        value={getLocaleString(subscriberKPIs ? subscriberKPIs?.net_subscriptions?.period : 0)}
        pctDelta={subscriberKPIs ? subscriberKPIs?.net_subscriptions?.delta : 0}
        previousValue={`${getLocaleString(lagNetSubscribers)} ${normalizeTimePeriod}`}
        isAllTime={isAllTime}
        tooltipText="New Subscribers - Unsubscribes"
      />
    </KPIGroup>
  );
};

export default KPIs;
