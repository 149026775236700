import { useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeftIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import IconButton from '@/components/IconHelpers/IconButton';
import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { CurrentUserOrganization } from '@/hooks/useCurrentUserOrganizations';
import useIsWorkspaceSettingPage from '@/hooks/useIsWorkspaceSettingPage';
import useLocalStorage from '@/hooks/useLocalStorage';
import { SETTINGS_ORGANIZATION_ID_PARAM_NAME } from '@/hooks/useOrganization/useCurrentOrganizationId';
import { SETTINGS_PUBLICATION_ID_PARAM_NAME } from '@/hooks/usePublications/useCurrentPublicationId';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import { SETTINGS_SIDEBAR_WIDTH } from '../constants';

import { ViewMode } from './types';
import WorkspaceNav from './WorkspaceNav';

interface Props {
  organizations: CurrentUserOrganization[];
  onLinkClick: () => void;
  isLoading: boolean;
  totalPublications: number;
}

const MultiplePublicationSettings = ({ organizations, totalPublications, onLinkClick, isLoading }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPublicationId = searchParams.get(SETTINGS_PUBLICATION_ID_PARAM_NAME);
  const currentOrganizationId = searchParams.get(SETTINGS_ORGANIZATION_ID_PARAM_NAME);
  const isWorkspaceSettingPage = useIsWorkspaceSettingPage();
  const hasViewModeFeatureEnabled = totalPublications > 1;
  const [viewMode, setViewMode] = useLocalStorage<ViewMode>('dropdown', 'settingsSidebarViewMode');
  const inverseViewMode = viewMode === 'list' ? 'dropdown' : 'list';

  const listContainer = useRef<HTMLDivElement>(null);

  const publicationIdsByOrganizationId: Record<string, string[]> =
    useMemo(
      () =>
        organizations?.reduce((pv, cv) => {
          return {
            ...pv,
            [cv.id]: cv.publications.map((publication) => publication.id),
          };
        }, {}),
      [organizations]
    ) || {};

  const organizationIdsByPublicationId: Record<string, string> =
    useMemo(
      () =>
        organizations?.reduce((po, co) => {
          return co.publications.reduce((pv, cp) => {
            return {
              ...po,
              ...pv,
              [cp.id]: co.id,
            };
          }, {});
        }, {}),
      [organizations]
    ) || {};

  const handleClickLink = ({ shouldScrollToTop }: { shouldScrollToTop: boolean }) => {
    if (shouldScrollToTop) {
      listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    onLinkClick();
  };

  const handleSelectPublication = (_: string, value: string) => {
    // No point switching if the user clicked on the current publication
    if (currentPublicationId === value) {
      return;
    }

    navigate(appendSettingsPublicationId('/settings/publication/general', value));
  };

  const organizationOptions = useMemo(
    () =>
      organizations.reduce((pv, cv) => {
        return [
          ...pv,
          {
            label: cv.name,
            value: cv.id,
            isOptGroup: true,
          },
          ...cv.publications.map((publication) => ({
            label: publication.name,
            value: publication.id,
          })),
        ];
      }, [] as Option[]),
    [organizations]
  );

  if (isLoading) {
    return (
      <div className={classNames('flex flex-col border-x border-surface-100 bg-white', SETTINGS_SIDEBAR_WIDTH)}>
        <SkeletonLoader
          isLoading={isLoading}
          skeletons={
            <div className="p-4 mt-12 space-y-2">
              <Skeleton className="h-9" />
              <Skeleton className="h-9" />
              <Skeleton className="h-9" />
            </div>
          }
        >
          &nbsp;
        </SkeletonLoader>
      </div>
    );
  }

  return (
    <div className={classNames('flex flex-col border-x border-surface-200 bg-white', SETTINGS_SIDEBAR_WIDTH)}>
      <div className="px-4 pt-3.5 w-full flex flex-row justify-between items-center">
        <Button align="left" size="xs" variant="flush" Icon={ArrowLeftIcon} onClick={() => navigate('/')}>
          Settings
        </Button>
        {hasViewModeFeatureEnabled ? (
          <IconButton
            onClick={() => setViewMode(inverseViewMode)}
            tooltip={`Switch to ${inverseViewMode} view`}
            backgroundColor="bg-surface-700"
            hoverBackgroundColor="hover:bg-surface-800"
            textColor="text-white"
          >
            <ArrowsRightLeftIcon />
          </IconButton>
        ) : null}
      </div>
      {hasViewModeFeatureEnabled && viewMode === 'dropdown' ? (
        <Dropdown
          name="settings-sidebar-publication-dropdown"
          options={organizationOptions}
          onSelect={handleSelectPublication}
          value={currentPublicationId || undefined}
          className="px-4 pt-4"
        />
      ) : null}
      <div
        className={classNames('flex flex-col gap-y-2 px-4 pb-4 overflow-y-auto', viewMode === 'list' ? 'mt-4' : 'mt-2')}
        ref={listContainer}
      >
        {organizations?.map((organization) => {
          if (
            hasViewModeFeatureEnabled &&
            viewMode === 'dropdown' &&
            currentPublicationId &&
            organization.id !== organizationIdsByPublicationId[currentPublicationId]
          ) {
            return null;
          }

          return (
            <WorkspaceNav
              viewMode={hasViewModeFeatureEnabled ? viewMode : 'list'}
              key={`${organization.id}-${currentPublicationId}-${isWorkspaceSettingPage}`}
              organization={organization}
              currentPublicationId={currentPublicationId}
              isOpen={
                (currentPublicationId &&
                  publicationIdsByOrganizationId[organization.id].includes(currentPublicationId)) ||
                currentOrganizationId === organization.id
              }
              onLinkClick={handleClickLink}
              hasOnlyOneWorkspace={organizations.length === 1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MultiplePublicationSettings;
