import { useMemo } from 'react';

import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { ApplicationDemographics } from '@/interfaces/boosts/grow/application_demographics';
import Badge, { BadgeSize, BadgeType } from '@/ui/Badge';
import pluralize from '@/utils/pluralize';

interface Props {
  applicationDemographics: ApplicationDemographics;
  publicationId: string;
  maxVisible: number;
  portalMountedId?: string;
  isLoading: boolean;
}

const MAX_TOOLTIP_VISIBLE = 10;

const ApplicationDemographicBadges = ({ applicationDemographics, publicationId, maxVisible, portalMountedId, isLoading }: Props) => {
  const totalCountries = applicationDemographics.length;
  const demographics = applicationDemographics.slice(0, maxVisible);
  const restCountriesCount = totalCountries - maxVisible;

  const tooltipCountries = applicationDemographics.slice(maxVisible, (MAX_TOOLTIP_VISIBLE + maxVisible));
  const tooltipRestCountriesCount = restCountriesCount - tooltipCountries.length;

  const tooltipContent = useMemo(() => (
    <ol className="space-y-1">
      {tooltipCountries.map((demographic) => (
        <Typography
          key={demographic.name}
          token="font-normal/text/xs"
          color="surface"
          colorWeight="50"
          as="li"
        >
          {demographic.flag} {demographic.name} {demographic.percentage}%
        </Typography>
      ))}
      {tooltipRestCountriesCount > 0 && (
        <Typography token="font-normal/text/xs" colorWeight="400" as="li" className="italic">
          … and {pluralize('other', tooltipRestCountriesCount)}
        </Typography>
      )}
    </ol>
  ), [tooltipCountries, tooltipRestCountriesCount])

  if (totalCountries === 0 && !isLoading) {
    return null;
  }

  return (
    <SkeletonLoader
      isLoading={isLoading}
      skeletons={
        <div className="flex flex-row gap-2">
          <div className="w-32"><Skeleton className="h-5 py-0.5" /></div>
          <div className="w-32"><Skeleton className="h-5 py-0.5" /></div>
          <div className="w-32"><Skeleton className="h-5 py-0.5" /></div>
        </div>
      }
    >
      <div className="flex flex-wrap gap-2 items-center">
        {demographics?.map(({ name: countryName, flag, percentage }) => (
          <Badge
            key={countryName}
            text={`${flag} ${countryName} ${percentage}%`}
            size={BadgeSize.SM}
            type={BadgeType.DEFAULT_FILLED}
          />
        ))}
        {restCountriesCount > 0 && (
          <Tooltip
            id={`${publicationId}-rest-countries`}
            portalMountedId={portalMountedId}
            text=""
            content={tooltipContent}
            showIcon={false}
            childrenContainerClass="flex items-center"
            padding="8px"
            autoWidth
          >
            <Typography token="font-normal/text/xs" colorWeight="500" as="span">
              +{restCountriesCount}
            </Typography>
          </Tooltip>
        )}
      </div>
    </SkeletonLoader>
  );
};

export default ApplicationDemographicBadges;
