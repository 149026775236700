export enum BadgeColor {
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
  BLUE = 'blue',
  GRAY = 'gray',
}

export interface BadgeProps {
  children: React.ReactNode;
  color?: BadgeColor;
}
