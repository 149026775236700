import { memo, PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';
import { autoUpdate, offset, Placement, useFloating, useHover, useInteractions } from '@floating-ui/react';

export const Tooltip = memo(
  ({
    center,
    right,
    children,
    placement = 'bottom',
    className,
    delay = 0,
  }: PropsWithChildren<{
    center: any;
    right?: any;
    placement?: Placement;
    className?: HTMLElement['className'];
    delay?: number;
  }>) => {
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
      placement,
      middleware: [offset(5)],
      open: isOpen,
      onOpenChange: setIsOpen,
      whileElementsMounted: autoUpdate,
    });

    const hover = useHover(context, {
      delay,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    return (
      <>
        <div ref={refs.setReference} className={className} {...getReferenceProps()}>
          {children}
        </div>
        {isOpen &&
          createPortal(
            <div
              className="bg-wb-inverse shadow-wb-md border border-wb-button flex text-[11px] items-center justify-between w-max h-fit text-wb-invert-primary font-medium tracking-tight px-2.5 py-0.5 rounded-[5px] border-solid"
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <div className="w-max text-center">{center}</div>
              {right && (
                <>
                  <span className="text-wb-secondary ml-[5px]">•</span>
                  <div className="w-max ml-[5px]">{right}</div>
                </>
              )}
            </div>,
            document.body
          )}
      </>
    );
  }
);
