export enum AdNetworkAdvertisementSponsorText {
  SPONSORED_BY = 'sponsored_by',
  TOGETHER_WITH = 'together_with',
  IN_PARTNERSHIP_WITH = 'in_partnership_with',
  PRESENTED_BY = 'presented_by',
  TODAYS_SPONSOR = 'todays_sponsor',
}

export enum AdNetworkAdvertisementKind {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface AdNetworkAdvertisement {
  id: string;
  name: string;
  description: string | null;
  url: string;
  tiptap_state: any;
  sponsor_text: AdNetworkAdvertisementSponsorText;
  campaign_id: string;
  kind: AdNetworkAdvertisementKind;
  created_at: string;
  updated_at: string;
}

export interface AdNetworkAdvertisementPreview {
  html: string;
}
