import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentTimeZone } from '@/hooks';
import { Order } from '@/interfaces/general';
import api from '@/services/swarm';
import { TimePeriod } from '@/utils';

interface ChartData {
  email: string;
  formatted_amount: string;
  tier_name: string;
  created_at: string;
  subscriber_id: string;
}

interface ApiResponse {
  chart_data?: ChartData[];
  period: TimePeriod;
  time_zone: string;
}

interface UseDonationsByPeriodProps {
  period: TimePeriod;
  orderBy: string;
  direction: Order;
}

const useDonationsByPeriod = ({ period, orderBy, direction }: UseDonationsByPeriodProps) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const currentTimeZone = useCurrentTimeZone();

  const fetchTierChangeData = () => {
    return api
      .get(`/premium_data/donations_per_period/${period}`, {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
          order_by: orderBy,
          direction,
        },
      })
      .then((res) => res.data);
  };

  return useQuery<ApiResponse>(
    ['premium_data', 'donationsPerPeriod', currentPublicationId, period, currentTimeZone, orderBy, direction],
    fetchTierChangeData,
    {
      keepPreviousData: true,
    }
  );
};

export default useDonationsByPeriod;
