import { OptionsDropdownProps } from '@/components/OptionsDropdown';
import { Recommendation } from '@/interfaces/recommendation';

import Row from '../components/Row';

type Props = {
  recommendations: Recommendation[];
  options: ((recommendation: Recommendation) => OptionsDropdownProps['options']) | OptionsDropdownProps['options'];
};

const BlockedRecommendations = ({ recommendations, options }: Props) => {
  if (recommendations.length === 0) return null;

  return (
    <>
      <div className="text-gray-600 text-md font-medium mt-8 mb-4">Blocked</div>
      <ul className="space-y-2">
        {recommendations.map((recommendation) => (
          <Row
            key={recommendation.id}
            recommendation={recommendation}
            publicationType="publication"
            options={options}
          />
        ))}
      </ul>
    </>
  );
};

export default BlockedRecommendations;
