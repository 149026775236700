interface Props {
  className?: string;
}

const Mail = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2C2.34315 2 1 3.34315 1 5L1 13C1 14.6569 2.34315 16 4 16L14 16C15.6569 16 17 14.6569 17 13V5C17 3.34315 15.6569 2 14 2L4 2ZM3.50004 4.13376C3.64712 4.04869 3.81787 4 4 4L14 4C14.1821 4 14.3529 4.04869 14.5 4.13376C14.4396 4.1687 14.382 4.21053 14.3282 4.2593L9.18809 8.92142C9.12507 8.9786 9.05782 9.00001 9.00001 9.00001C8.94219 9.00001 8.87486 8.97852 8.81184 8.92134L3.67184 4.2593C3.61806 4.21053 3.56043 4.1687 3.50004 4.13376ZM3 6.35006L3 13C3 13.5523 3.44772 14 4 14L14 14C14.5523 14 15 13.5523 15 13L15 6.35008L10.5319 10.4027C10.1138 10.7819 9.57175 11 9.00001 11C8.42827 11 7.88626 10.782 7.46818 10.4028L3 6.35006Z"
      fill="currentColor"
    />
  </svg>
);

export default Mail;
