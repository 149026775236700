import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Pagination } from '../../interfaces/general';
import api from '../../services/swarm';

interface ApiResponse {
  nav_links: any[];
  pagination: Pagination;
}

const useNavLinks = ({
  search,
  perPage = 5,
  all = false,
  externalLinks = true,
  pages = true,
  disabled = true,
  live = true,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
  externalLinks?: boolean;
  pages?: boolean;
  disabled?: boolean;
  live?: boolean | null;
}) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchForms = ({ pageParam = 1 }) =>
    api
      .get(`/nav_links`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
          external_links: externalLinks,
          pages,
          disabled,
          live,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['nav_links', currentPublicationId, search, externalLinks, pages, disabled, live],
    fetchForms,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      retry: false,
      keepPreviousData: true,
      onError: (errPayload: any) => errPayload?.response?.data?.message || 'Something went wrong',
    }
  );
};

export default useNavLinks;
