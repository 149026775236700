export const languages = [
  { name: 'english', label: 'English', value: 'EN' },
  { name: 'spanish', label: 'Spanish', value: 'ES' },
  { name: 'french', label: 'French', value: 'FR' },
  { name: 'german', label: 'German', value: 'DE' },
  { name: 'italian', label: 'Italian', value: 'IT' },
  { name: 'portuguese', label: 'Portuguese', value: 'PT' },
  { name: 'portuguese-brazilian', label: 'Portuguese (Brazilian)', value: 'PT_BR' },
];

export default { languages };
