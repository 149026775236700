import { useRef } from 'react';
import { NavbarSerializableNode, SerializableNode, Serializer } from '@shared/dream-components';

import BrowserBar from '@/components/PreviewModalV2/BrowserBar';
import { cn } from '@/utils/cn';

import { SimpleFooterPreviewer } from '../../footer/_components/FooterEditor/SimpleFooterPreviewer';
import { SimpleNavPreviewer } from '../../navbar/_components/NavbarEditor/SimpleNavPreviewer';
import IframeWrapper from '../../page/_components/Iframe';
import { useContainerRef } from '../../page/_hooks';
import { useFontHrefsFromContent } from '../../page/_hooks/useFontHrefs';
import { VIEWPORTS } from '../../page/constants';

interface Props {
  navbarContent?: NavbarSerializableNode;
  content?: SerializableNode;
  footerContent?: SerializableNode;
  hasBrowserBar?: boolean;
  containerHeightClass?: string;
  previewHeight?: string;
  viewPortWidth?: number;
}

const TemplatePreviewer = ({
  content,
  hasBrowserBar = true,
  containerHeightClass = 'h-[200px]',
  previewHeight = '800px',
  navbarContent,
  footerContent,
  viewPortWidth = VIEWPORTS[0].width,
}: Props) => {
  const { rect: previewRect, containerRef: previewContainerRef } = useContainerRef({
    enabled: true,
  });

  const fontHrefs = useFontHrefsFromContent(content as any);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={cn(
        'w-full overflow-hidden rounded-xl shadow-xl border border-solid pointer-events-none',
        containerHeightClass
      )}
    >
      <div
        className={cn(
          VIEWPORTS[0].type === 'desktop' ? 'w-full' : 'max-w-md',
          'h-full m-auto flex flex-col border-b border-surface-200 overflow-hidden justify-center'
        )}
        ref={previewContainerRef}
        style={{
          height: previewHeight,
        }}
      >
        {hasBrowserBar && <BrowserBar screenSize="desktop" />}
        <IframeWrapper
          width={previewRect.width || 0}
          height={previewRect.height || 0}
          contentWidth={viewPortWidth}
          fontHrefs={fontHrefs}
          ref={iframeRef}
        >
          {navbarContent && <SimpleNavPreviewer portalRef={portalRef} iframeRef={iframeRef} content={navbarContent} />}
          {content && <Serializer node={content} />}
          {footerContent && <SimpleFooterPreviewer content={footerContent} />}
          <div ref={portalRef} id="portal-ref" className="z-50" />
        </IframeWrapper>
      </div>
    </div>
  );
};

export default TemplatePreviewer;
