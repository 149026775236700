import api from '@/services/swarm';

import {
  GetTrendingGiphyGifsOptions,
  GiphyGif,
  GiphyMeta,
  GiphyPagination,
  SearchGiphyGifsOptions,
} from './giphy.types';

export const GIPHY_API_URL = 'https://api.giphy.com/v1';
export const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY;

export const defaultGetTrendingGiphyGifsOptions: GetTrendingGiphyGifsOptions = {
  offset: 0,
  rating: 'g',
};

export const getTrendingGiphyGifs = async (rawOptions: GetTrendingGiphyGifsOptions) => {
  const options = { ...defaultGetTrendingGiphyGifsOptions, ...rawOptions };
  const url = `${GIPHY_API_URL}/gifs/trending`;
  const { offset, rating } = options;

  try {
    const res = await fetch(`${url}?api_key=${GIPHY_API_KEY}&limit=32&offset=${offset}&rating=${rating}`);
    const json = await (res.json() as Promise<{ data: GiphyGif[]; pagination: GiphyPagination; meta: GiphyMeta }>);
    return json;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const defaultSearchOptions: SearchGiphyGifsOptions = {
  q: '',
  publication_id: '',
  offset: 0,
  rating: 'g',
};

type GifSearchResponse = { data: GiphyGif[]; pagination: GiphyPagination; meta: GiphyMeta };

export const searchGiphyGifs = async (rawOptions: SearchGiphyGifsOptions) => {
  const params = { ...defaultSearchOptions, ...rawOptions };

  try {
    const { data } = await api.get('/editor/giphy/gifs', { params });
    return data as GifSearchResponse;
  } catch (e: any) {
    throw new Error(e.message);
  }
};
