import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';
import debounce from 'lodash.debounce';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip';
import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';
import { Author } from '@/interfaces/author';
import { Post } from '@/interfaces/post';
import { parameterize } from '@/utils/parameterize';

import { usePostContext } from '../../PostContext';
import VisibilitySettings from '../TitleActionsMenu/VisibilitySettings';
import TitleContentMenu from '../TitleContentMenu';

interface Props {
  guestAuthors?: {
    guest_authors: Author[];
  };
  setShowCreateGuestAuthorModal: (value: boolean) => void;
  setShowImageLibrary: (value: boolean) => void;
  thumbnailFileRef: React.RefObject<HTMLInputElement>;
  users?: Author[];
}

const Title = ({
  guestAuthors,
  setShowCreateGuestAuthorModal,
  setShowImageLibrary,
  thumbnailFileRef,
  users,
}: Props) => {
  const { formData, onChange } = usePostContext();
  const [authorTippyMenusShown, setAuthorTippyMenusShown] = useState(false);
  const canUpdateSlug = formData?.status === 'draft' || formData?.status === 'scheduled';
  const postAuthors = useMemo(() => users?.filter((user) => formData?.user_ids.includes(user.id)), [formData, users]);

  const postGuestAuthors = useMemo(
    () => guestAuthors?.guest_authors.filter((guestUser) => formData?.guest_author_ids.includes(guestUser.id)),
    [formData, guestAuthors]
  );

  const showAuthorsButton = useMemo(
    () => (postAuthors?.length === 0 && postGuestAuthors?.length === 0) || authorTippyMenusShown,
    [postAuthors, postGuestAuthors, authorTippyMenusShown]
  );

  const updatePostTitle = useCallback(
    (value: string) => {
      const updatedPostData: Partial<Post> = {
        web_title: value,
      };

      if (!formData?.email_subject_line || formData?.email_subject_line === formData?.web_title) {
        updatedPostData.email_subject_line = updatedPostData.web_title;
      }

      if (
        canUpdateSlug &&
        formData?.slug &&
        (formData.slug === parameterize(formData.web_title) ||
          (formData?.web_title === 'New Post' && formData.slug.startsWith(parameterize(formData.web_title))))
      ) {
        updatedPostData.slug = parameterize(value);
      }

      onChange(updatedPostData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, formData]
  );

  const handlePostTitleChange = useMemo(() => {
    return debounce((e: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;

      const derivedValue = !value.trim().length ? 'New Post' : value;

      updatePostTitle(derivedValue);
    }, 300);
  }, [updatePostTitle]);

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 w-full h-full -ml-10 z-0" />
      <TitleContentMenu
        users={users || []}
        guestAuthors={guestAuthors?.guest_authors || []}
        showAuthorsButton={showAuthorsButton}
        setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
        fileRef={thumbnailFileRef}
        setShowImageLibrary={setShowImageLibrary}
        setAuthorTippyMenusShown={setAuthorTippyMenusShown}
      />
      <div className="absolute -ml-10 z-50">
        <Tippy
          offset={[0, 8]}
          placement="bottom-start"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          trigger="click"
          interactive
          content={
            <div className="absolute">
              <VisibilitySettings />
            </div>
          }
          zIndex={EDITOR_MENUS_Z_INDEX}
        >
          <div className="text-gray-300 hover:text-gray-950">
            <Tooltip title={formData?.display_title_in_email ? 'Visibility' : 'Hidden in email'}>
              <Button
                $leftSlot={
                  formData?.display_title_in_email ? (
                    <div className="relative w-4 h-4 mt-[.1rem]">
                      <img src="/images/eye-icon.svg" alt="Eye icon" />
                    </div>
                  ) : (
                    <div className="relative w-4 h-4">
                      <img src="/images/eye-off-icon.svg" alt="Eye icon" />
                    </div>
                  )
                }
                $variant="tertiary"
                $size="small"
                $isIconButton
              />
            </Tooltip>
          </div>
        </Tippy>
      </div>
      <div className="relative flex">
        <textarea
          data-testid="post-title"
          className="h-[3rem] w-full editor-title-textarea selection:bg-pink-200 placeholder-gray-300 focus:border-transparent focus:ring-0 box-0 p-0 m-0 border-0 outline-0 focus:outline-none focus:border-none active:outline-none active:border-none font-semibold text-4xl resize-none"
          onChange={handlePostTitleChange}
          name="post-title"
          placeholder="Add a title"
          defaultValue={formData?.web_title}
        />
      </div>
    </div>
  );
};

export default Title;
