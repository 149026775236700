import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';

import { CustomDomain, CustomDomainTypes } from '../../../../../../interfaces/custom_domain';
import { Section } from '../../../../Components';
import SelectedDomainDetails from '../SelectedDomainDetails';

interface Props {
  domain?: CustomDomain;
}

const RedirectDomainSection = ({ domain }: Props) => {
  const publicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(publicationId);
  const description =
    'Add a domain that redirects to your primary domain. This is useful when you want to redirect the non-www version of your domain to the www version.';
  if (!settings?.apex_domains) {
    return null;
  }
  if (!domain?.verified) {
    return (
      <Section title="Redirect Domain" description={description} scrollToId="redirect-domain">
        <SelectedDomainDetails domain={domain} type={CustomDomainTypes.REDIRECT} />
      </Section>
    );
  }

  return (
    <Section title="Redirect Domain" description={description} scrollToId="web-domain">
      <div className="my-6">
        <SelectedDomainDetails domain={domain} type={CustomDomainTypes.REDIRECT} />
      </div>
    </Section>
  );
};

export default RedirectDomainSection;
