import CodeBlockLowlightTiptap from '@tiptap/extension-code-block-lowlight';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { lowlight } from 'lowlight/lib/common.js';

import { CodeBlockLowLightView } from './views';

import 'highlight.js/styles/github-dark.css';

export const CodeBlockLowlight = CodeBlockLowlightTiptap.extend({
  group: 'codeBlockGroup',

  addNodeView() {
    return ReactNodeViewRenderer(CodeBlockLowLightView);
  },

  addAttributes() {
    return {
      language: {
        default: 'html',
        parseHTML: (element) => {
          const { languageClassPrefix } = this.options;
          const classNames = Array.from(element.firstElementChild?.classList || []);
          const languages = classNames
            .filter((className) => className.startsWith(languageClassPrefix))
            .map((className) => className.replace(languageClassPrefix, ''));
          const language = languages[0];

          if (!language) {
            return null;
          }

          return language;
        },
        rendered: false,
      },
    };
  },
}).configure({ lowlight });
