import moment from 'moment-mini';

import { PublicationLogo } from '@/components/PublicationLogo';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import useFormattedSendingMetrics from '@/hooks/boosts/grow/useFormattedSendingMetrics';
import useFormattedTopLineMetrics from '@/hooks/boosts/grow/useFormattedTopLineMetrics';
import useOfferStatus from '@/hooks/boosts/grow/useOfferStatus';
import useSendingMetrics from '@/hooks/boosts/grow/useSendingMetrics';
import { BoostSend } from '@/interfaces/boosts/grow/boost_send';

import BoostSendStatusBadge from '../Dashboard/components/BoostSendStatusBadge';
import BoostSendActions from '../Dashboard/components/Table/BoostSendActions';

interface Props {
  boostSend: BoostSend;
  showCPA?: boolean;
  showSendingMetrics?: boolean;
  showSpendMetrics?: boolean;
}

const BoostSendListItem = ({ boostSend, showCPA, showSendingMetrics, showSpendMetrics }: Props) => {
  const { cost } = useOfferStatus(boostSend.boost_agreement.boost_offer);
  const { data: sendingMetricsData } = useSendingMetrics({
    boost_send_id: boostSend.id,
    isEnabled: showSendingMetrics,
  });
  const { openRate, unsubscribeRate, clickThroughRate } = useFormattedSendingMetrics(sendingMetricsData);
  const { acceptanceRate, totalSpend, pendingSpend } = useFormattedTopLineMetrics(boostSend.top_line_metrics);

  return (
    <ItemRow>
      <ItemColumn align={AlignType.TOP}>
        {showCPA ? (
          <TypographyStack>
            <Typography token="font-medium/text/base">{cost}</Typography>
            <Typography token="font-normal/text/xs" colorWeight="500">
              Created <time dateTime={boostSend.created_at as string}>{moment(boostSend.created_at).fromNow()}</time>{' '}
            </Typography>
          </TypographyStack>
        ) : (
          <div className="w-64 flex items-center gap-x-2">
            <PublicationLogo publication={boostSend.recommending_publication} size="sm" />
            <Typography weight="medium" colorWeight="900">
              {boostSend.recommending_publication.name}
            </Typography>
          </div>
        )}
      </ItemColumn>
      <ItemColumn>
        <BoostSendStatusBadge status={boostSend.status} sendBy={boostSend.send_by} />
      </ItemColumn>
      {showSendingMetrics && (
        <>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {openRate}
            </Typography>
          </ItemColumn>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {clickThroughRate}
            </Typography>
          </ItemColumn>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {unsubscribeRate}
            </Typography>
          </ItemColumn>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {acceptanceRate}
            </Typography>
          </ItemColumn>
        </>
      )}
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostSend.top_line_metrics.total_pending_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostSend.top_line_metrics.total_accepted_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {boostSend.top_line_metrics.total_subscribers}
        </Typography>
      </ItemColumn>
      {showSpendMetrics && (
        <>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {pendingSpend}
            </Typography>
          </ItemColumn>
          <ItemColumn>
            <Typography size="sm" colorWeight="700">
              {totalSpend}
            </Typography>
          </ItemColumn>
        </>
      )}
      <ItemColumn isSticky className="w-full h-full flex items-center">
        <BoostSendActions id={boostSend.id} />
      </ItemColumn>
    </ItemRow>
  );
};

export default BoostSendListItem;
