import { useMutation } from 'react-query';

import api from '@/services/swarm';
import { registerTempUser } from '@/utils/analytics/tempUser';

interface Props {
  credential: string;
  selectedPlan?: string;
  isEnterprisePlan?: boolean;
  isTrialPlan?: boolean;
  marketingAttribution?: any;
  ehawkTal?: any;
}

const useCreateTempUserFromGoogle = () => {
  return useMutation(
    ({ credential, marketingAttribution, ehawkTal, selectedPlan, isEnterprisePlan, isTrialPlan }: Props) =>
      api
        .post(`/signups/new_temp_user_from_google`, {
          credential,
          marketing_attribution: marketingAttribution,
          ehawk_tal: JSON.parse(ehawkTal) || {},
          selected_plan: selectedPlan,
          is_enterprise_plan: isEnterprisePlan,
          is_trial_plan: isTrialPlan,
        })
        .then((res) => {
          registerTempUser({
            email: res.data.email,
            firstName: res.data.first_name,
            lastName: res.data.last_name,
            emailVerified: true,
            selectedPlan,
            isTrialPlan,
          });

          return res.data;
        })
  );
};

export default useCreateTempUserFromGoogle;
