import { DocumentIcon, LinkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

interface Props {
  text: string;
  isExternalLink: boolean;
  colorWeight?: any;
  iconClass?: string;
}

const PageHelperTitle = ({ isExternalLink, text, colorWeight, iconClass }: Props) => {
  return (
    <div className="flex space-x-1 items-center overflow-hidden">
      <div className="flex">
        {isExternalLink ? (
          <LinkIcon className={cx('w-3 h-3 text-surface-400', iconClass)} />
        ) : (
          <DocumentIcon className={cx('w-3 h-3 text-surface-400', iconClass)} />
        )}
      </div>
      <Typography as="p" token="font-normal/text/xs" colorWeight={colorWeight || '900'} className="py-1">
        {text}
      </Typography>
    </div>
  );
};

export default PageHelperTitle;
