import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostOffer, VerificationMode } from '@/interfaces/boosts/grow/boost_offer';
import api from '@/services/swarm';

interface Props {
  id: string;
  onSuccess?: (result: BoostOffer) => void;
}

interface Variables {
  cost_per_lead_cents?: number;
  max_spend_cents?: number;
  description?: string;
  status?: string;
  name?: string;
  verification_mode?: VerificationMode;
  remove_rejected?: boolean;
  eligible_countries?: string[] | null;
}

const useUpdateBoostOffer = ({ id, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const updateBoostOffer = (boost_offer: Variables): Promise<BoostOffer> =>
    api
      .patch(`/boosts/grow/boost_offers/${id}`, {
        publication_id: currentPublicationId,
        boost_offer,
      })
      .then((res) => res.data);

  return useMutation(updateBoostOffer, {
    onSuccess: (result) => {
      onSuccess?.(result);
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_offers']);
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_sends']);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
  });
};

export default useUpdateBoostOffer;
