import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';

import useResourcePermissions from '@/hooks/useResourcePermissions/useResourcePermissions';

import SelectNodeTypeButton from '../components/SelectNodeTypeButton';
import { AUTOMATION_NODE_WIDTH_CLASSNAME } from '../constants';
import { useNewAutoLayoutContext } from '../context/new-layout-context';
import useMaxAllowedSteps from '../hooks/useMaxAllowedSteps';
import { CustomNodeDataType } from '../types';

const NewAddAutomationStepNode = ({ data, targetPosition }: NodeProps<CustomNodeDataType>) => {
  const { getNode } = useReactFlow();
  const { automation } = useNewAutoLayoutContext();
  const {data: recordPermissions, isSuccess: recordPermissionSuccess} = useResourcePermissions({
    resourceType: 'Automation',
    resourceId: automation.id,
    policyClass: "Api::V2::AutomationPolicy",
  });
  const canAddNew = recordPermissionSuccess && recordPermissions.includes('update');
  const hasMaxSteps = useMaxAllowedSteps();
  const shouldShowAddButton = !hasMaxSteps && canAddNew;
  const { parentId, branchArm, branchId } = data;
  const sourceNode = (parentId && getNode(parentId)) || (branchId && getNode(branchId)) || undefined;

  return (
    <div className={AUTOMATION_NODE_WIDTH_CLASSNAME}>
      <Handle className="opacity-0" type="target" position={targetPosition || Position.Top} />
      {shouldShowAddButton && <SelectNodeTypeButton sourceNode={sourceNode} branchArm={branchArm} />}
    </div>
  );
};

export default NewAddAutomationStepNode;
