/* eslint-disable consistent-return */
import { Extension } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';
import { AllSelection, NodeSelection } from '@tiptap/pm/state';

import { WebTheme } from '@/interfaces/web_theme';

import { THEME_ATTRS } from '../../AttributesPanel/components/BlockSettings/ActionsSettings/consts';
import transformTokenOverrides from '../../utils/transformThemeOverrides';

export enum ApplyThemeScope {
  DOC = 'doc',
  SECTION = 'section',
}

export const THEME_SCOPES = [ApplyThemeScope.DOC, ApplyThemeScope.SECTION];
const BOUNDARY_NODE_SIZE = 1;

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    theme: {
      applyTheme: ({
        theme,
        mapping,
        scope,
      }: {
        theme: WebTheme;
        mapping: Record<string, Partial<Record<(typeof THEME_ATTRS)[number], string[]>>>;
        scope?: ApplyThemeScope;
      }) => ReturnType;
    };
  }
}

export const ThemeCommands = Extension.create({
  name: 'themeCommands',

  addCommands() {
    return {
      applyTheme:
        ({ theme, mapping, scope = 'section' }) =>
        ({ state, dispatch }) => {
          const { selection, tr } = state;

          const { $from } = selection;

          const isSectionScope = scope === 'section';
          const isSectionSelection = selection instanceof NodeSelection && selection.node.type.name === 'section';
          const isDocSelection = selection instanceof AllSelection;

          let scopeTargetNode: PMNode = state.doc;
          let scopeTargetNodeStart = -1;

          if (isDocSelection) {
            if (isSectionScope) {
              let setScopeTargetNode = false;

              state.doc.descendants((node, pos) => {
                if (setScopeTargetNode) return false;

                if (node.type.name === 'section') {
                  scopeTargetNode = node;
                  scopeTargetNodeStart = pos;
                  setScopeTargetNode = true;
                }
              });
            } else {
              scopeTargetNode = state.doc;
              scopeTargetNodeStart = 0;
            }
          } else if (isSectionScope && isSectionSelection) {
            scopeTargetNode = selection.node;
            scopeTargetNodeStart = selection.from;
          } else {
            scopeTargetNode = isSectionScope ? $from.node(1) : $from.node(0);
            scopeTargetNodeStart = isSectionScope ? $from.start(1) : 0;
          }

          if (!THEME_SCOPES.includes(scopeTargetNode.type.name as ApplyThemeScope)) {
            return false;
          }

          // Update section attrs
          if (scopeTargetNode.type.name === 'section') {
            const sectionMapping = mapping[scopeTargetNode.type.name];

            const sectionAttrs = Object.keys(sectionMapping) as Array<keyof typeof sectionMapping>;

            const updatedSectionAttrs: Record<string, string> = {};

            sectionAttrs.forEach((attrKey) => {
              if (!Object.hasOwn(theme, attrKey)) {
                return;
              }

              const themeValue = theme[attrKey];

              const attrsToUpdate = sectionMapping[attrKey] as string[];

              attrsToUpdate.forEach((attr) => {
                updatedSectionAttrs[attr] = themeValue;
              });
            });

            tr.setNodeMarkup(scopeTargetNodeStart, null, updatedSectionAttrs);
          }

          // Update Doc/Section Node Children attrs based on scope
          scopeTargetNode.descendants((node, pos) => {
            let adjustedPos = scopeTargetNodeStart + pos;

            if (isSectionSelection) {
              adjustedPos += BOUNDARY_NODE_SIZE;
            }

            let nodeThemeMetaData = mapping[node.type.name];

            if (!nodeThemeMetaData) {
              return;
            }

            if (node.attrs?.tokens) {
              nodeThemeMetaData = transformTokenOverrides(node.attrs?.tokens || {}, nodeThemeMetaData);
            }

            const themeAttrs = Object.keys(nodeThemeMetaData) as Array<keyof typeof nodeThemeMetaData>;

            const updatedAttrs: Record<string, string> = {};

            themeAttrs.forEach((nodeThemeMetaDataKey) => {
              if (!Object.hasOwn(theme, nodeThemeMetaDataKey)) {
                return;
              }
              const themeValue = theme[nodeThemeMetaDataKey];

              const attrsToUpdate = nodeThemeMetaData[nodeThemeMetaDataKey] as string[];

              attrsToUpdate.forEach((attr) => {
                updatedAttrs[attr] = themeValue;
              });
            });

            // For nodes that have text nodes as children we want to return early here so it does not appply the color as a mark
            // We dont offere the ability for the users to update the mark on the button, just the button color attribute
            if (node.type.name === 'button') {
              const newAttrs = {
                ...node.attrs,
                ...updatedAttrs,
              };

              tr.setNodeMarkup(adjustedPos, null, newAttrs);

              // eslint-disable-next-line consistent-return
              return false;
            }

            if (node.type.name === 'text') {
              const textStyleMark = node.marks.find((mark) => mark.type.name === 'textStyle');
              const overrideColor = textStyleMark?.attrs?.tokens?.color;
              const overrides = overrideColor ? { color: theme[overrideColor as keyof WebTheme] } : {};

              const updatedTextStyleMark = state.schema.mark('textStyle', {
                ...(textStyleMark?.attrs || {}),
                ...updatedAttrs,
                ...overrides,
              });

              tr.addMark(adjustedPos, adjustedPos + node.nodeSize, updatedTextStyleMark);
            } else {
              const newAttrs = {
                ...node.attrs,
                ...updatedAttrs,
              };

              tr.setNodeMarkup(adjustedPos, null, newAttrs);
            }
          });

          if (tr.docChanged && dispatch) {
            dispatch(tr);
            return true;
          }

          return false;
        },
    };
  },
});
