import { Input } from '@/components/Form';
import Switch from '@/ui/Switch';

import { StepProps } from './types';

const DetailsStep = ({ endpoint, errors, onChange }: StepProps) => {
  const { url, description, disabled } = endpoint;

  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...endpoint,
        [field]: value,
      });
    }
  };

  return (
    <div className="flex justify-between">
      <div className="space-y-6">
        <Switch
          name="enabled"
          labelText="Enabled"
          helperText={
            disabled
              ? 'Toggle on to receive webhooks at this endpoint'
              : 'Toggle off to stop receiving webhooks at this endpoint'
          }
          checked={!disabled}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('disabled', !checked)}
        />
        <Input
          type="text"
          name="url"
          label="URL"
          id="create-endpoint-url"
          labelText="Endpoint URL"
          value={url}
          helperText="The URL to send the webhook to."
          placeholder="https://example.com/webhook"
          onChange={(e) => handleChange('url', e.target.value)}
          required
          errorText={errors?.url}
        />
        <Input
          type="text"
          name="description"
          label="Description"
          id="create-endpoint-description"
          labelText="Description"
          value={description}
          helperText="A description of the endpoint to help you identify it later."
          placeholder="Premium subscriber updates sent to Zapier"
          onChange={(e) => handleChange('description', e.target.value)}
        />
      </div>
    </div>
  );
};

export default DetailsStep;
