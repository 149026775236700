import React from 'react';

const Other: React.FunctionComponent = () => (
  <div>
    <p className="text-gray-500 text-sm mt-2">
      Uh-oh, it looks like we don&apos;t yet support importing content from your current platform. Please{' '}
      <a
        href="https://support.beehiiv.com/hc/en-us/requests/new"
        target="_blank"
        rel="noreferrer"
        className="text-primary-700"
      >
        contact support
      </a>{' '}
      and let us know what platform you are using. We are working hard to expand what our content import tool can
      support.
    </p>
  </div>
);

export default Other;
