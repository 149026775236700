import { BoltIcon, XMarkIcon } from '@heroicons/react/20/solid';

import Grid from '@/components/Grid';
import { Typography, TypographyRow } from '@/components/Typography';
import { Button } from '@/ui/Button';

interface TrialOptionProps {
  planName: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  excludedOptions: string[];
  isProcessing?: boolean;
}

const TrialOption: React.FC<TrialOptionProps> = ({ planName, onClick, excludedOptions, isProcessing }) => {
  return (
    <div className="w-full">
      <div className="border-surface-200 rounded border">
        <TypographyRow className="bg-surface-50 rounded-t py-4 px-6 border-b border-surface-200 justify-between">
          <Typography token="font-semibold/text/base">
            Start your 30-day free trial,
            <br />
            no credit card required
          </Typography>
          <div className="p-4 bg-action-secondary-100 rounded">
            <BoltIcon className="text-action-secondary-500 w-6 h-6" />
          </div>
        </TypographyRow>
        <div className="px-6 py-4">
          <TypographyRow className="pb-4">
            <Typography token="font-normal/text/sm" colorWeight="700">
              All {planName} features
            </Typography>
            <Typography token="font-bold/text/sm" colorWeight="700">
              EXCEPT:
            </Typography>
          </TypographyRow>
          <Grid gap={3} columns={2} rows={2}>
            {excludedOptions.map((option) => (
              <TypographyRow>
                <XMarkIcon className="text-surface-300 h-[16px] w-[16px]" />
                <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
                  {option}
                </Typography>
              </TypographyRow>
            ))}
            <TypographyRow>
              <XMarkIcon className="text-surface-300  h-[16px] w-[16px]" />
              <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
                Sending limited to 2,500 subscribers
              </Typography>
            </TypographyRow>
          </Grid>
        </div>
        <TypographyRow className="bg-surface-50 rounded-t py-4 px-6 border-b border-surface-200 justify-end">
          <Button onClick={onClick} variant="primary" size="sm" disabled={isProcessing}>
            Start Trial
          </Button>
        </TypographyRow>
      </div>
      <div className="text-left pt-3">
        <Typography token="font-normal/text/xs" colorWeight="700">
          <Typography token="font-semibold/text/xs" colorWeight="700" className="mr-1">
            We don&apos;t require a credit card to get started.
          </Typography>
          When your trial ends, your account will be downgraded to our Launch plan. To avoid losing access to {planName}{' '}
          features, upgrade your account before the trial period concludes.
        </Typography>
      </div>
    </div>
  );
};

export default TrialOption;
