import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';
import currencyFormatter from '@/pages/Grow/Boosts/utils/currencyFormatter';

const useOfferStatus = (offer: BoostOffer) => {
  const maxPayout = offer.max_payout_cents ? currencyFormatter.format(offer.max_payout_cents / 100) : 0;
  const payout = currencyFormatter.format(offer.payout_per_lead_cents / 100);

  return {
    maxPayout,
    payout,
  };
};

export default useOfferStatus;
