import { useNavigate } from 'react-router-dom';
import { EnvelopeIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import Entri from 'entrijs';

import ActionModal from '@/components/ActionModal';
import { RadioSelect } from '@/components/Form';
import { Typography, TypographyRow } from '@/components/Typography';
import { useCreateEntriModalRecords } from '@/hooks/useCustomDomains';
import { SetupMethod } from '@/hooks/useCustomDomains/types';
import { useRedirectAfterEntriSetup } from '@/hooks/useCustomDomains/useRedirectAfterEntriSetup';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import type { CustomDomainTypes } from '@/interfaces/custom_domain';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import BodyContainer from '../../../../_components/BodyContainer';
import getUsernameAndDomainFromEmail from '../../getUsernameAndDomainFromEmail';

import CodeBlock from './CodeBlock';
import { DEFAULT_EMAIL_DOMAIN } from './constants';
import CustomSetupMethodNode from './CustomSetupMethodNode';
import EntriLogo from './EntriLogo';
import FormBlock from './FormBlock';

interface Props {
  publicationSubdomain: string | undefined;
  shouldUseDefaultWebDomain: boolean;
  webDomain: string | null;
  redirectToWWW: boolean;
  redirectDomainValue?: string;
  redirectToDomain: string | null;
  shouldRedirectToDomain: boolean;
  shouldUseCustomEmailDomain: boolean;
  emailDomain: string | null;
  shouldUseWebDomainAsEmailDomain: boolean;
  shouldUseDefaultEmailDomain: boolean;
  setupMethod?: SetupMethod;
  setSetupMethod: (value: SetupMethod) => void;
  isEntriAvailable: boolean;
  isSetupMethodModalOpen: boolean;
  setIsSetupMethodModalOpen: (value: boolean) => void;
  customDomainIds: string[];
  hideWebSection?: boolean;
  hideEmailSection?: boolean;
  hideWeb?: boolean;
  hideWebRedirect?: boolean;
  isCreating: boolean;
  startManualVerification: (customDomainType?: CustomDomainTypes) => void;
}

const ReviewForm = ({
  publicationSubdomain,
  shouldUseDefaultWebDomain,
  webDomain,
  redirectToWWW,
  redirectToDomain,
  redirectDomainValue,
  shouldRedirectToDomain,
  shouldUseCustomEmailDomain,
  emailDomain,
  shouldUseWebDomainAsEmailDomain,
  shouldUseDefaultEmailDomain,
  setupMethod,
  setSetupMethod,
  isEntriAvailable,
  isSetupMethodModalOpen,
  setIsSetupMethodModalOpen,
  customDomainIds,
  hideWebSection = false,
  hideWeb = false,
  hideWebRedirect = false,
  hideEmailSection = false,
  isCreating,
  startManualVerification,
}: Props) => {
  const navigate = useNavigate();
  const publicationId = useCurrentPublicationId();
  const { mutate: createEntriModalRecords, isLoading: createEntriModalRecordsIsLoading } =
    useCreateEntriModalRecords({ publicationId, includeWeb: true, includeWebRedirect: true, includeEmail: true, includeBrandedLink: true });

  const { username: customEmailUsername, domain: customEmailDomain } = emailDomain
    ? getUsernameAndDomainFromEmail(emailDomain)
    : { username: '', domain: '' };

  const handleSetupModalClose = () => {
    navigate(appendSettingsPublicationId('/settings/publication/domain', publicationId));
  };

  useRedirectAfterEntriSetup(publicationId, customDomainIds);

  const handleSetupModalProceed = () => {
    if (setupMethod === SetupMethod.ENTRI) {
      try {
        createEntriModalRecords({}, {
          onSuccess: (data) => {
            setIsSetupMethodModalOpen(false);
            Entri.showEntri(data);
          },
        });
        // eslint-disable-next-line no-empty
      } catch (error) { }
    } else {
      startManualVerification();
    }
  };

  return (
    <>
      <ActionModal
        isOpen={isSetupMethodModalOpen}
        isWorking={isCreating || createEntriModalRecordsIsLoading}
        onClose={handleSetupModalClose}
        onProceed={handleSetupModalProceed}
        resourceId=""
        actionText="Proceed with Verification"
        modalSize="md"
      >
        <RadioSelect
          value={setupMethod}
          options={[
            {
              name: (
                <div className="flex flex-row items-center gap-x-1">
                  Set up with
                  <EntriLogo />
                </div>
              ),
              description:
                'beehiiv partner that can help link your domain to our service automatically. You will keep your domain registered with your current domain provider.',
              value: SetupMethod.ENTRI,
              isVisible: Boolean(isEntriAvailable),
            },
            {
              name: (
                <>
                  Set up <strong>Manually</strong>
                </>
              ),
              description:
                'We will provide you with the values you need to create records with your DNS provider and will need to verify ownership of the domain you want to connect.',
              value: SetupMethod.MANUAL,
            },
          ]}
          onSelect={(value: string) => setSetupMethod(value as SetupMethod)}
          CustomOptionNode={CustomSetupMethodNode}
        />
      </ActionModal>

      <BodyContainer>
        {!hideWebSection ? (
          <FormBlock isLight>
            <div className="flex flex-row gap-x-3">
              <GlobeAltIcon className="w-5 h-5 text-action-secondary-600 mt-0.5" />
              <div className="flex flex-col space-y-2">
                <Typography token="font-normal/text/base" colorWeight="500">
                  Web Domain
                </Typography>
                <div className="flex flex-col gap-y-1">
                  {!hideWeb && (
                    <TypographyRow>
                      <Typography token="font-semibold/text/sm" className="w-20" colorWeight="700">
                        Domain:
                      </Typography>
                      <Typography token="font-normal/text/sm">
                        <CodeBlock>
                          {shouldUseDefaultWebDomain ? `${publicationSubdomain}.beehiiv.com` : webDomain}
                        </CodeBlock>
                      </Typography>
                    </TypographyRow>
                  )}
                  {!hideWebRedirect && (
                    <TypographyRow>
                      <Typography token="font-semibold/text/sm" className="w-20" colorWeight="700">
                        Redirect:
                      </Typography>
                      <Typography token="font-normal/text/sm">
                        {redirectToWWW || shouldRedirectToDomain ? (
                          <CodeBlock>{shouldRedirectToDomain ? redirectToDomain : redirectDomainValue}</CodeBlock>
                        ) : (
                          <i>No redirect</i>
                        )}
                      </Typography>
                    </TypographyRow>
                  )}
                </div>
              </div>
            </div>
          </FormBlock>
        ) : null}

        {!hideEmailSection ? (
          <FormBlock isLight>
            <div className="flex flex-row gap-x-3">
              <EnvelopeIcon className="w-5 h-5 text-action-secondary-600 mt-0.5" />
              <div className="flex flex-col space-y-2">
                <Typography token="font-normal/text/base" colorWeight="500">
                  Email Domain
                </Typography>
                <div className="flex flex-col gap-y-1">
                  {(customEmailUsername || shouldUseDefaultEmailDomain) && (
                    <TypographyRow>
                      <Typography token="font-semibold/text/sm" className="w-20" colorWeight="700">
                        Username:
                      </Typography>
                      <Typography token="font-normal/text/sm">
                        <CodeBlock>
                          {shouldUseCustomEmailDomain ? customEmailUsername : null}
                          {shouldUseDefaultEmailDomain ? publicationSubdomain : null}
                        </CodeBlock>
                      </Typography>
                    </TypographyRow>
                  )}

                  <TypographyRow>
                    <Typography token="font-semibold/text/sm" className="w-20" colorWeight="700">
                      Domain:
                    </Typography>
                    <Typography token="font-normal/text/sm">
                      <CodeBlock>
                        {shouldUseCustomEmailDomain ? customEmailDomain : null}
                        {shouldUseWebDomainAsEmailDomain ? webDomain : null}
                        {shouldUseDefaultEmailDomain ? DEFAULT_EMAIL_DOMAIN : null}
                      </CodeBlock>
                    </Typography>
                  </TypographyRow>
                </div>
              </div>
            </div>
          </FormBlock>
        ) : null}
      </BodyContainer>
    </>
  );
};

export default ReviewForm;
