import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { TCollabThread, TiptapCollabProvider } from '@hocuspocus/provider';
import { Editor as TiptapEditor, JSONContent } from '@tiptap/react';
import debounce from 'lodash.debounce';
import styled from 'styled-components';

import { useCurrentUser } from '@/context/current-user-context';

import ActionModal from '../../../components/ActionModal';
import { FakeProgress } from '../../../components/FakeProgress';
import { EditorUser } from '../../../components/TiptapEditor/lib/types';
import { usePrompt, useReloadAlert } from '../../../hooks';
import { Post } from '../../../interfaces/post';
import api from '../../../services/swarm';

import SidebarToggleButton from './Sidebar/SidebarToggleButton';
import Editor from './Editor';
import { EditorContext, EditorContextContent } from './EditorContext';
import Sidebar from './Sidebar';

interface Props {
  post: Post;
  refetch: () => Promise<UseQueryResult>;
  collaborationEnabled: boolean;
}

const StyledDivGray = styled.div`
  background: #f7f7f7;
`;

const StyledSidebarDiv = styled.div`
  @media (min-width: 1280px) {
    min-width: 450px;
  }
`;

// We don't want to send along the tiptap_state on regular update requests
// since updating it is handled by the collaboration server.
const removeTiptapContent = (data: Post) => {
  const { tiptap_state: tiptapState, ...rest } = data;
  return rest as Post;
};

const Form = ({ post, refetch, collaborationEnabled }: Props) => {
  const [provider, setProvider] = useState<TiptapCollabProvider>();
  const [editor, setEditor] = useState<TiptapEditor | null>(null);
  const [editorUsers, setEditorUsers] = useState<EditorUser[]>([]);
  const [wordCount, setWordCount] = useState<number>(0);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showSearchAndReplaceMenu, setShowSearchAndReplaceMenu] = useState(false);
  const { currentUser } = useCurrentUser();
  const userId = currentUser?.id;

  const [formData, setFormData] = useState<Post>(post);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [editorIsLoading, setEditorIsLoading] = useState(false);

  const [loadingNodes, setLoadingNodes] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setFormData(post);
  }, [post]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const save = useMemo(
    () =>
      debounce(async (data: Partial<Post>) => {
        abortControllerRef.current = new AbortController();

        setIsSaving(true);

        return api
          .patch(
            `/posts/${post.id}`,
            { post: { ...data, id: post.id } },
            {
              signal: abortControllerRef.current.signal,
            }
          )
          .then((resp) => {
            if (resp.status >= 200 && resp.status < 300) {
              setUnsavedChanges(false);
              setErrors({});
            }

            if (resp.data.errors) {
              setErrors(resp.data.errors);
            }

            setIsSaving(false);
          })
          .catch((err) => {
            if (err.response?.data) {
              setErrors(err.response.data);
              setIsSaving(false);
            }
          })
          .finally(() => {
            abortControllerRef.current = null;
          });
      }, 450),
    []
  );

  const cancelSave = useCallback(() => {
    abortControllerRef.current?.abort();
  }, []);

  const onChange = useMemo(
    () =>
      (data: any, shouldRemoveContent = false) => {
        cancelSave();
        setUnsavedChanges(true);

        setFormData((prev) => {
          save(shouldRemoveContent ? removeTiptapContent(data) : data);

          return {
            ...prev,
            ...data,
          };
        });
      },
    [cancelSave, save]
  );

  const shouldPrompt = isSaving || unsavedChanges;
  const [isPrompting, confirmPrompt, cancelPrompt] = usePrompt(shouldPrompt);
  useReloadAlert(shouldPrompt, 'You have unsaved changes. Are you sure you want to leave this page?');

  const hasErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    if (isPrompting && !shouldPrompt) {
      confirmPrompt();
    }
  }, [isPrompting, shouldPrompt, confirmPrompt]);

  // threads
  const [showThreadsSidebar, setShowThreadsSidebar] = useState(false);

  const [activeThreadId, setActiveThreadId] = useState<string | null>(null);

  const selectThread = useCallback(
    (threadId: string) => {
      if (!editor) return;

      editor?.commands.scrollToThread(threadId);

      setTimeout(() => setActiveThreadId(threadId), 100);
    },
    [editor]
  );

  const unselectThread = useCallback(() => {
    setActiveThreadId(null);
  }, []);

  const [threads, setThreads] = useState<TCollabThread[] | null>(null);

  const unresolvedThreadsCount = useMemo(() => {
    return threads?.filter((t) => !t.resolvedAt)?.length || 0;
  }, [threads]);

  useEffect(() => {
    if (provider) {
      const updater = () => {
        const currentThreads = provider.getThreads();
        const sortedThreads = [...currentThreads].sort((a, b) => {
          const timeA = new Date(a.createdAt).getTime();
          const timeB = new Date(b.createdAt).getTime();
          return timeB - timeA;
        });
        setThreads(sortedThreads);
      };

      updater();
      provider.watchThreads(updater);

      return () => {
        provider.unwatchThreads(updater);
      };
    }

    return () => {};
  }, [provider]);

  const createThread = useCallback(
    (content: JSONContent) => {
      if (!editor || !userId) return null;

      const chain = editor.chain();

      return chain
        .setThread({
          content,
          data: {
            authorId: userId,
          },
          commentData: {
            authorId: userId,
          },
        })
        .focus()
        .run();
    },
    [editor, userId]
  );

  const highlightThread = useCallback(
    (id: string) => {
      if (!editor) return;

      const { tr } = editor.state;
      tr.setMeta('threadMouseOver', id);
      editor.view.dispatch(tr);
    },
    [editor]
  );

  const removeHighlightThread = useCallback(
    (id: string) => {
      if (!editor) return;

      const { tr } = editor.state;
      tr.setMeta('threadMouseOut', id);
      editor.view.dispatch(tr);
    },
    [editor]
  );

  const providerValue = useMemo<EditorContextContent>(
    () => ({
      editor,
      setEditor,
      editorIsLoading,
      setEditorIsLoading,
      isSaving,
      setIsSaving,
      unsavedChanges,
      setUnsavedChanges,
      wordCount,
      setWordCount,
      provider,
      setProvider,
      formData,
      users: editorUsers,
      setUsers: setEditorUsers,
      showSidebar,
      setShowSidebar,
      showSearchAndReplaceMenu,
      setShowSearchAndReplaceMenu,
      collaborationEnabled,
      loadingNodes,
      setLoadingNodes,

      // threads
      activeThreadId,
      createThread,
      highlightThread,
      removeHighlightThread,
      selectThread,
      setActiveThreadId,
      setShowThreadsSidebar,
      showThreadsSidebar,
      threads,
      unresolvedThreadsCount,
      unselectThread,
    }),
    [
      editor,
      editorIsLoading,
      editorUsers,
      isSaving,
      provider,
      formData,
      showSidebar,
      showSearchAndReplaceMenu,
      unsavedChanges,
      wordCount,
      collaborationEnabled,
      loadingNodes,

      // threads
      activeThreadId,
      createThread,
      highlightThread,
      removeHighlightThread,
      selectThread,
      showThreadsSidebar,
      threads,
      unresolvedThreadsCount,
      unselectThread,
    ]
  );

  return (
    <EditorContext.Provider value={providerValue}>
      <ActionModal
        isOpen={isPrompting}
        onClose={cancelPrompt}
        onProceed={confirmPrompt}
        headerText={hasErrors ? 'Are you sure?' : 'Hang tight...'}
        resourceId="post"
        actionText="Leave anyway"
        isWorking={false}
      >
        {hasErrors ? (
          <>
            Errors are preventing this post from being saved. Are you sure you want to leave? Any unsaved changes will
            be lost.
          </>
        ) : (
          <>
            <p className="mb-4">
              We&apos;re working on saving your latest changes. Once we&apos;re done, you&apos;ll be redirected
              automatically.
            </p>
            {isPrompting && <FakeProgress />}
          </>
        )}
      </ActionModal>
      <StyledDivGray className="relative justify-end w-full h-full md:fixed md:flex">
        <div
          className={`w-full bg-white ${showSidebar ? 'md:absolute inset-0 xl:relative xl:w-2/3' : ''}`}
          style={{ borderBottomRightRadius: '2rem', borderTopRightRadius: '2rem' }}
        >
          <Editor errors={errors} post={formData} />
        </div>
        {showSidebar && (
          <button
            onClick={() => setShowSidebar(false)}
            type="button"
            tabIndex={0}
            aria-label="overlay-background"
            className="absolute inset-0 z-10 hidden bg-gray-500 bg-opacity-75 md:block xl:hidden"
          />
        )}
        <StyledDivGray className={`relative z-20 h-full ${showSidebar ? 'md:w-2/3 lg:w-1/2 xl:w-1/3' : ''}`}>
          {!showSidebar && (
            <SidebarToggleButton
              onClick={() => setShowSidebar(true)}
              className="absolute z-20 transform rotate-180 -left-10 top-4 hover:bg-gray-200 xl:flex"
            />
          )}
          <StyledSidebarDiv className={`h-full ${showSidebar ? '' : 'md:hidden'}`}>
            <Sidebar errors={errors} post={formData} onChange={(data: any) => onChange(data, true)} refetch={refetch} />
          </StyledSidebarDiv>
        </StyledDivGray>
      </StyledDivGray>
    </EditorContext.Provider>
  );
};

export default Form;
