import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { ExternalEmbedDestructor } from '../../interfaces/external_embed';
import api from '../../services/swarm';

const useDestroyExternalEmbed: ExternalEmbedDestructor = ({ onSuccess }) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    (externalEmbed) => api.delete(`/publications/${externalEmbed.publication_id}/external_embeds/${externalEmbed.id}`),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['externalEmbeds', currentPublicationId]);
        toast.success('Subscribe form deleted.');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Could not delete subscribe form.');
      },
    }
  );
};

export default useDestroyExternalEmbed;
