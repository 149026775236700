import AppliedFilter from '../AppliedFilter';
import { AttributeCondition, PlanNameOperatorsList } from '../types';

const PlanNameFilterApplied = ({ condition, onRemove }: { condition: AttributeCondition; onRemove: () => void }) => {
  const operator = PlanNameOperatorsList.find((o) => o.value === condition.filters.operator);
  if (!operator) return null;

  const operatorLabel = operator.label;

  return <AppliedFilter name="Plan Name" label={operatorLabel} value={condition.filters.value} onRemove={onRemove} />;
};

export default PlanNameFilterApplied;
