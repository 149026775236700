import { useCallback, useRef } from 'react';

interface Props {
  onIntersect: (isInViewport: boolean) => void;
}

export default function useIntersectionObserver({ onIntersect }: Props) {
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: any) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onIntersect(entries[0].intersectionRatio * 100 > 0);
          }
        },
        {
          threshold: [0, 1], // observe when element enters/leaves viewport
        }
      );

      if (node) observer.current.observe(node);
    },
    [onIntersect]
  );

  return { ref: lastElementRef };
}
