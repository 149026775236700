import { Button } from '@/ui/Button';

import Pill from './Pill';
import UpgradeButton from './UpgradeButton';

interface HeroProps {
  plan: string;
  pillUrl: string;
  title: string;
  description: string;
  onUpgradeClick: () => void;
  videoUrl?: string;
  onVideoClick: () => void;
  disclaimer?: string;
}

const Hero: React.FC<HeroProps> = ({
  plan,
  pillUrl,
  title,
  description,
  onUpgradeClick,
  videoUrl,
  onVideoClick,
  disclaimer,
}) => (
  <div className="max-w-lg mx-auto">
    <div className="mb-8">
      <Pill plan={plan} url={pillUrl} />
    </div>
    <div className="space-y-8">
      <h1 className="text-4xl font-bold text-center">{title}</h1>
      <p className="text-xl text-center">{description}</p>

      <div className="flex justify-center space-x-2">
        <UpgradeButton plan={plan} onClick={onUpgradeClick} />
        {videoUrl && (
          <Button variant="primary-inverse" onClick={onVideoClick}>
            Watch the video
          </Button>
        )}
      </div>
      {disclaimer && (
        <div className="text-center text-gray-500 text-sm mt-8">
          <p>{disclaimer}</p>
        </div>
      )}
    </div>
  </div>
);

export default Hero;
