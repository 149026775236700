import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import { Shortcut } from '../Shortcut';

import FilterGroup from './FilterGroup';
import useFilteringContext from './filtering-context';

const Filters = () => {
  const {
    state,
    handleClose,
    showMenu,
    clearConditions,
    readonly,
    localStorageKey,
    showViewSelector,
    setShowViewSelector,
  } = useFilteringContext();

  const navigate = useNavigate();

  return (
    <div className="w-full flex space-x-2">
      <FilterGroup group={state} />
      {!readonly && (
        <>
          {state.conditions.length > 0 && (
            <div>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="flex items-center rounded-ful text-gray-400 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => clearConditions()}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              ' px-4 py-2 text-sm justify-between w-full flex space-x-2'
                            )}
                          >
                            <span>Reset filters</span>
                            <span>
                              <Shortcut>
                                <span className="font-serif">⇧</span>
                                <span>F</span>
                              </Shortcut>
                            </span>
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              ' px-4 py-2 text-sm justify-between w-full flex space-x-2'
                            )}
                            onClick={() => {
                              navigate(`/ad_network/workspace_views/new?conditions_key=${localStorageKey}`);
                            }}
                          >
                            <span>Save as...</span>
                            <span>
                              <Shortcut>
                                <span className="font-serif">⌥</span>
                                <span>V</span>
                              </Shortcut>
                            </span>
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          )}
          {(showMenu || showViewSelector) && (
            <button
              className="absolute inset-0 cursor-default "
              onClick={() => {
                handleClose();
                setShowViewSelector(false);
              }}
              type="button"
            />
          )}
        </>
      )}
    </div>
  );
};

export default Filters;
