import { LandingPage } from '@/interfaces/landing_page';
import { UpgradePage } from '@/interfaces/upgrade_page';
import ColorInput from '@/routes/website_builder/_components/Form/ColorInput';
import FAQExtendedForm from '@/routes/website_builder/_components/Form/PageSpecific/FAQs/FAQExtendedForm';
import FontForm from '@/routes/website_builder/_components/Form/PageSpecific/FontForm';
import SelectInput from '@/routes/website_builder/_components/Form/SelectInput';
import SwitchInput from '@/routes/website_builder/_components/Form/SwitchInput';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';
import { ALIGNMENT_OPTIONS } from '@/routes/website_builder/_types/options';

import { FAQSectionIcon, SectionGroupingSlideOut } from '../../Helpers/SectionGroupingSlideOut';

interface Props {
  handleSave: (payload: any) => void;
  page: LandingPage | UpgradePage | any;
}

const FAQSection = ({ handleSave, page }: Props) => {
  const faqSectionWithDefaults = page.faq_section_with_defaults;

  return (
    <SectionGroupingSlideOut
      title="FAQ Section"
      showPlanBadge
      sectionIcon={<FAQSectionIcon />}
      highlightProps={{
        sectionId: 'faq-section',
      }}
    >
      <PropertiesGrouping label="General">
        <SwitchInput
          label="Enabled"
          name="faqs_enabled"
          enabled={faqSectionWithDefaults.enabled}
          onSave={(value: boolean) => {
            handleSave({
              ...page?.faq_section,
              enabled: value,
            });
          }}
        />
        {faqSectionWithDefaults.enabled && (
          <>
            <ColorInput
              label="Background"
              color={faqSectionWithDefaults.bg_color}
              resettable={{
                label: 'Global Background Color',
                resetValue: null,
                check: page?.faq_section.bg_color !== null,
              }}
              onSave={(color: string) => {
                handleSave({
                  ...page?.faq_section,
                  bg_color: color,
                });
              }}
            />
            <SelectInput
              label="Text Alignment"
              value={faqSectionWithDefaults.text_align}
              options={ALIGNMENT_OPTIONS}
              onSave={(value: string) => {
                handleSave({
                  ...page?.faq_section,
                  text_align: value,
                });
              }}
            />
            <FontForm
              label="Overline"
              textValue={faqSectionWithDefaults.overline}
              supportsMarkdown
              onSaveText={(value) => {
                handleSave({
                  ...page?.faq_section,
                  overline: value,
                });
              }}
              onSave={(key, value) => {
                handleSave({
                  ...page?.faq_section,
                  [key]: value,
                });
              }}
              editableProps={{
                overline_color: {
                  type: 'colorInput',
                  value: faqSectionWithDefaults.overline_color,
                  resetCheckValue: page?.faq_section.overline_color,
                  resetLabel: 'Global Overline Color',
                },
                overline_font_family: {
                  type: 'fontFamilySelect',
                  value: faqSectionWithDefaults.overline_font_family,
                  resetCheckValue: page?.faq_section.overline_font_family,
                  resetLabel: 'Global Body Font',
                },
                overline_font_size: { type: 'fontSizeSelect', value: faqSectionWithDefaults.overline_font_size },
              }}
            />
            <FontForm
              label="Headline"
              textValue={faqSectionWithDefaults.headline}
              supportsMarkdown
              onSaveText={(value) => {
                handleSave({
                  ...page?.faq_section,
                  headline: value,
                });
              }}
              onSave={(key, value) => {
                handleSave({
                  ...page?.faq_section,
                  [key]: value,
                });
              }}
              editableProps={{
                headline_color: {
                  type: 'colorInput',
                  value: faqSectionWithDefaults.headline_color,
                  resetCheckValue: page?.faq_section.headline_color,
                  resetLabel: 'Global Headline Color',
                },
                headline_font_family: {
                  type: 'fontFamilySelect',
                  value: faqSectionWithDefaults.headline_font_family,
                  resetCheckValue: page?.faq_section.headline_font_family,
                  resetLabel: 'Global Heading Font',
                },
                headline_font_size: { type: 'fontSizeSelect', value: faqSectionWithDefaults.headline_font_size },
              }}
            />
          </>
        )}
      </PropertiesGrouping>
      {page && <FAQExtendedForm page={page} handleSave={handleSave} isProcessing={false} />}
    </SectionGroupingSlideOut>
  );
};

export default FAQSection;
