import { useParams, useSearchParams } from 'react-router-dom';

import { PostStatus } from '@/interfaces/post';

import { UpgradeIntent } from '../../../../components/UpgradeIntent';
import { useSettings } from '../../../../context/settings-context';
import { IntentAction } from '../../../../interfaces/upgrades';
import { PLAN } from '../../../../utils/plans';
import NavContainer from '../../NavContainer';
import SplitTestAnalytics from '../Analytics';

interface RouteParams {
  postId: string;
}

const SplitTest = () => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const { postId } = useParams() as unknown as RouteParams;

  const [searchParams, setSearchParams] = useSearchParams();

  const shouldUpgrade = (settings?.max_split_test_options || 0) < 2;

  return (
    <NavContainer postId={postId}>
      {({ post }) => {
        if (!isV2 && shouldUpgrade) {
          return (
            <>
              <div className="mb-64 text-gray-600 text-sm">
                A/B Testing is only available on premium beehiiv plans.{' '}
                <span
                  aria-hidden
                  onClick={() => {
                    searchParams.set('upgrade', 'true');
                    setSearchParams(searchParams);
                  }}
                  className="cursor-pointer text-primary-500 underline hover:text-grey-500"
                >
                  Upgrade
                </span>{' '}
                to get access.
              </div>
              <UpgradeIntent
                isOpen={!!searchParams.get('upgrade')}
                intentAction={IntentAction.USE_SPLIT_TEST}
                onClose={() => {
                  searchParams.delete('upgrade');
                  setSearchParams(searchParams);
                }}
                preselectedPlan={PLAN.GROW}
              />
            </>
          );
        }

        const postHasSplitTest = post.split_test;
        const isPostLive = post.status === PostStatus.PUBLISHED;
        if (!isV2 && !postHasSplitTest) {
          return <p className="mb-64 text-gray-600 text-sm">This post did not include an A/B Test.</p>;
        }

        return (
          <SplitTestAnalytics
            isPostLive={isPostLive}
            shouldUpgrade={shouldUpgrade}
            postHasSplitTest={postHasSplitTest}
            postId={postId}
          />
        );
      }}
    </NavContainer>
  );
};

export default SplitTest;
