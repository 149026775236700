import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { Author } from '../interfaces/author';
import api from '../services/swarm';

interface Props {
  onSuccess?: () => void;
}

const useCreateGuest = ({ onSuccess }: Props) =>
  useMutation<Author, unknown, { formData: FormData }>(
    ({ formData }) => api.post(`/guest_authors`, formData).then((res) => res.data),
    {
      onSuccess: () => {
        if (typeof onSuccess === 'function') {
          onSuccess();
        }
        toast.success('Guest author created');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );

export default useCreateGuest;
