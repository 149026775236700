import SlideOver from '../../../../../components/SlideOver';
import Form from '../../../../GuestAuthorsView/Form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddSlideOver = ({ isOpen, onClose }: Props) => {
  return (
    <SlideOver isOpen={isOpen} onClose={onClose} headerText="Add guest">
      <Form onSubmit={onClose} />
    </SlideOver>
  );
};

export default AddSlideOver;
