/**
 * Converts a field and array of messages into a single sentence.
 *
 * @example toSentence('FirstName', ['is required', 'is not valid', 'is too short']) => 'First name is required, is not valid, and is too short'
 * @param field The field name
 * @param parts The array of messages
 * @returns {string}
 */
const toSentence = (field: string, parts: string[]) => {
  if (parts.length === 0) {
    return '';
  }
  if (parts.length === 1) {
    return `${field} ${parts[0]}`;
  }
  return `${field} ${parts.slice(0, -1).join(', ')} and ${parts[parts.length - 1]}`;
};

export default toSentence;
