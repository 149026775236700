import { useCallback, useEffect, useState } from 'react';

import { ColorPicker } from '../../../_components/UI/ColorPicker';
import { SimpleInputWrapper } from '../../../_components/UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../_components/UI/Popover';
import { Text } from '../../../_components/UI/Text';

type Props = {
  title?: string;
  initialColor?: string | null;
  onSetColor: (value: string | null) => void;
  children?: React.ReactNode;
  offset?: number;
};

const DEFAULT_COLOR = '#000000FF';

const reformatColor = (color?: string) => {
  if (!color) return undefined;

  // if color is not 9 digits (including alpha & '#')
  if (color?.length === 4) {
    return `#${color.replace('#', '')}${color.replace('#', '')}FF`;
  }
  if (color?.length === 7) {
    return `${color}FF`;
  }
  if (color?.length === 9) {
    // do nothing
  }

  return color;
};

export const ColorSelection = ({ title, initialColor, onSetColor, children, offset = 110 }: Props) => {
  const [color, setColor] = useState<string | undefined>(initialColor || undefined);

  const onChange = useCallback(
    (value: string | null) => {
      const formattedColor = reformatColor(value || DEFAULT_COLOR) || null;
      setColor(formattedColor || DEFAULT_COLOR);
      onSetColor(formattedColor);
    },
    [onSetColor]
  );

  useEffect(() => {
    if (initialColor) {
      setColor(initialColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialColor]);

  const onReset = useCallback(() => {
    setColor(DEFAULT_COLOR);
    onChange(null);
  }, [onChange]);

  return (
    <Popover>
      <div className="flex items-center justify-between gap-2 select-none">
        {title && (
          <Text className="w-full max-w-[150px]" variant="secondary" size="2xs" weight="medium">
            {title}
          </Text>
        )}
        <PopoverTrigger asChild>
          {children || (
            <SimpleInputWrapper className="justify-start relative max-w-[150px]">
              <div style={{ backgroundColor: color }} className="h-4 w-4 rounded-md" />
              <Text size="2xs" weight="medium" className="">
                {color?.toUpperCase()}
              </Text>
            </SimpleInputWrapper>
          )}
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[280px] p-0" align="center" side="left" sideOffset={offset}>
        <div className="max-h-[700px] overflow-y-auto p-3 flex flex-col gap-4">
          <ColorPicker
            selectedColor={color}
            onSetColor={(value: string | null) => {
              setColor(value || DEFAULT_COLOR);
              onChange(value);
            }}
            onReset={onReset}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
