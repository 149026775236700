import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

import { SortableListItem } from '@/components/SortableList';

import OptionsDropdown from '../../Helpers/OptionsDropdown';
import { SortableFeatureItem } from '../HeroSection/types';

interface Props {
  listItem: SortableFeatureItem;
  onRemoveItem: () => void;
  setFeatureToEdit: (feature: any) => void;
}

const FeaturesItem = ({ listItem, onRemoveItem, setFeatureToEdit }: Props) => {
  return (
    <SortableListItem
      className="!py-1"
      key={listItem.id}
      listItem={listItem}
      onRemoveItem={() => {}}
      text={listItem.text}
      actionChild={
        <div className="pr-1">
          <OptionsDropdown
            options={[
              {
                label: 'Edit',
                Icon: PencilIcon,
                onClick: () => setFeatureToEdit(listItem),
              },
              { label: 'Delete', Icon: TrashIcon, onClick: onRemoveItem },
            ]}
          />
        </div>
      }
    />
  );
};

export default FeaturesItem;
