import { PartnerNextTier,PartnerTierSetting } from "@/interfaces/partner_tier";
import { capitalize } from "@/utils";
import pluralize from "@/utils/pluralize";

interface Params {
  tierSetting: PartnerTierSetting;
  tierData?: PartnerNextTier;
}

const UpcomingBox = ({ tierSetting, tierData }: Params) => {
  return (
    <div className="w-[360px] h-[294px] grow shrink basis-0 self-stretch p-5 bg-gray-50 rounded-lg border border-gray-200 flex-col gap-6 inline-flex">
      <div className="self-stretch h-[138px] flex-col justify-start gap-6 flex">
        <div className="w-[34px] h-[38px] relative">
          <img
            alt="Locked Tier"
            className="w-[34px] h-[38px]"
            src="/images/partner-tier-locked.svg"
          />
        </div>
        <div className="self-stretch h-[76px] flex-col justify-start gap-6 flex">
          <div className="flex flex-col gap-1 font-semibold">
            <div className="text-gray-600 text-sm font-['SF Pro'] leading-tight">
              {capitalize(tierSetting.name)}
            </div>
            <div className="text-gray-600 text-sm font-['SF Pro'] leading-tight">
              Unlock with {pluralize('Conversion', tierSetting.threshold)}
            </div>
          </div>
          <div className="self-stretch text-gray-600 text-xs font-normal font-['SF Pro'] leading-none">
            <div className="flex-col justify-start items-start gap-6 flex">
              <div className="">
                <div className="text-gray-600 font-normal leading-tight">
                  <span className="font-bold">{tierSetting.commission}%</span> commissions for 12-months
                </div>
              </div>
              {tierSetting.bonuses && (
                <div className="flex-col justify-start items-start gap-2 flex">
                  <div className="gap-2.5 inline-flex text-gray-600 font-normal leading-tight">
                    <ul className="list-disc list-outside ml-4">{tierSetting.bonuses.map((bonus) => <li key={bonus}>{bonus}</li>)}</ul>
                  </div>
                </div>
              )}
            </div>
            {tierData?.name.toLowerCase() === tierSetting.name.toLowerCase() && (
              <div className="flex-col justify-start pt-6">
                Conversions Remaining: <span className="font-bold">{tierData.remaining_count}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingBox;
