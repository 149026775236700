import { NAVBAR_MENU_DROPDOWN_ITEM_DEFAULTS } from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { ColorSettings } from '../general/ColorSettings';

import { StyleSettings } from './StyleSettings';

export function NavDropdownItemStylingSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown_item') return null;

  return (
    <SectionRenderer title="Styling">
      <StyleSettings />
      <ColorSettings
        title="Background"
        property="background"
        selectedContent={selectedContent}
        defaultValue={NAVBAR_MENU_DROPDOWN_ITEM_DEFAULTS.background}
      />
    </SectionRenderer>
  );
}
