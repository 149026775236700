import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { SubscriptionChange } from '@/interfaces/mailer_actions/subscription_change';
import api from '@/services/swarm';

const useConfirmSubscriptionChange = (token: String) => {
  const queryClient = useQueryClient();

  return useMutation<SubscriptionChange>(
    () =>
      api
        .patch(`/mailer_actions/subscription_change_confirmations/${token}`, {
          subscription_change: {
            confirm: true,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast.success('Subscription change confirmed');
        queryClient.invalidateQueries(['mailer_actions', 'subscription_change', token]);
      },
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong', { duration: 7000 });
      },
    }
  );
};

export default useConfirmSubscriptionChange;
