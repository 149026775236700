import styled from 'styled-components';

import { colors } from '../../../lib/colors';

const Styled = {
  ButtonWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  `,
  ConsumptionWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 0.5rem 0.25rem;
  `,
  ConsumptionHint: styled.div`
    color: ${colors.white[2]};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.2;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  `,
};

export default Styled;
