import CommentBansSection from './CommentBansSection';
import CommentSection from './CommentsSection';

const Comments = () => {
  return (
    <>
      <CommentSection />
      <CommentBansSection />
    </>
  );
};

export default Comments;
