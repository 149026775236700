import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { getText, NodeViewContent, NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import { AxiosError, AxiosResponse } from 'axios';

import { SearchList, SearchListItemProps } from '../../../components/ui/SearchList';
import { API } from '../../../lib/api';
import { usePublicationContext } from '../../../lib/context/PublicationContext';

interface Preset {
  text: string;
  url: string;
}

export const ButtonView = ({ editor, node, getPos }: NodeViewWrapperProps) => {
  const text = getText(node);
  const isNew = node.attrs.isCustom === undefined && !node.attrs.href?.length && !text?.length;

  const { publicationId } = usePublicationContext();
  const { postId } = useParams<'postId'>() as { postId: string };
  const [isLoading, setIsLoading] = useState(isNew);
  const [presets, setPresets] = useState<Preset[]>([]);

  useEffect(() => {
    if (isNew) {
      API.getPresetButtons({
        publicationId,
        postId,
      })
        .then((res: AxiosResponse<{ options: Preset[] }>) => {
          const customPreset = [
            {
              text: 'Custom',
              url: '',
            },
          ];

          const p = [...customPreset, ...(res.data?.options || [])];

          setPresets(p);
        })
        .catch((errPayload: AxiosError) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';

          toast.error(error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isNew, publicationId, postId]);

  const handleSelect = useCallback(
    (item: SearchListItemProps) => {
      const focusPos = getPos() + 1;

      const content = item.label === 'Custom' ? '' : item.label;
      const href = item.value;

      const chain = editor
        .chain()
        .focus(focusPos)
        .updateAttributes('button', { href, isCustom: item.label === 'Custom' });

      if (content) {
        chain.insertContent(content).run();
      } else {
        chain.run();
      }
    },
    [editor, getPos]
  );

  const styles = {
    style: {
      ...(node.attrs.customBackgroundColor && { backgroundColor: node.attrs.customBackgroundColor }),
      ...(node.attrs.customTextColor && { color: node.attrs.customTextColor }),
      ...(node.attrs.fullWidth && { width: '100%', textAlign: node.attrs.alignment }),
    },
  };

  return (
    <NodeViewWrapper
      data-drag-handle
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      {isNew ? (
        <SearchList
          tabIndex={-1}
          items={presets.map((preset) => {
            return {
              value: preset.url,
              label: preset.text,
            };
          })}
          hasMore={isLoading}
          searchable
          searchPlaceholder="Search a button preset"
          onSelect={(item: SearchListItemProps) => handleSelect(item)}
        />
      ) : (
        <NodeViewContent
          as="a"
          href={node.attrs.href}
          data-type={node.type.name}
          data-size={node.attrs.size}
          data-alignment={node.attrs.alignment}
          {...styles}
        />
      )}
    </NodeViewWrapper>
  );
};

export default ButtonView;
