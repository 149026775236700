import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import useConfirmPublicationChange from './_hooks/useConfirmPublicationChange';

const ConfirmPublicationChanges = () => {
  const { token } = useParams<'token'>() as { token: string };
  const navigate = useNavigate();

  const [pcpid] = useQueryParam<string | undefined>('pcpid');
  const redirectUrl = appendSettingsPublicationId('/settings/publication/emails', pcpid || '');
  const { mutate: confirmPublicationChange } = useConfirmPublicationChange(pcpid || '', token || '', {
    onSuccess: () => {
      // We set this so that next time useEffect is triggered, we can prevent it from running again
      localStorage.setItem('hasConfirmedPublicationChange', token);

      navigate(redirectUrl, {
        state: { message: 'Change confirmed!' },
      });
    },
    onError: () => {
      navigate(redirectUrl, { state: { message: 'Failed to confirm change.' } });
    },
  });

  // Hours have been spent trying to figure out why this useEffect is being triggered
  // a second time after navigating to the settings page in the onSuccess callback above.
  // This is only happening on the login redirect when confirming while logged out.
  // Even when trying to use a ref via useRef() to prevent the useEffect from being triggered again didn't work.
  // Hence, the hacky solution we went with for now to get this out was to remove the ref
  // and use localStorage to keep track of state to prevent the useEffect from being triggered again.
  const hasConfirmed = useRef(false);
  useEffect(() => {
    const cachedToken = localStorage.getItem('hasConfirmedPublicationChange');

    if (token && !hasConfirmed.current && cachedToken !== token) {
      hasConfirmed.current = true; // Set the ref to true to prevent future invocations on remounts
      confirmPublicationChange();
    } else {
      localStorage.removeItem('hasConfirmedPublicationChange');
      navigate(redirectUrl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ConfirmPublicationChanges;
