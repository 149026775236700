/**
 * This method takes a token overrides object: {backgroundColor: 'primary_color'}
 * And a theme metadata object: {primary_color: ['backgroundColor'}
 * The metadata object comes from theme_rules.rb.
 * It returns a new object with the same keys as the metadata object, but with the values replaced by the values from the token overrides object.
 */

function transformTokenOverrides(
  input: Record<string, string>,
  themeMetaData: Record<string, string[]>
): Record<string, string[]> {
  const result: Record<string, string[]> = {};

  Object.entries(input).forEach(([key, value]) => {
    if (!result[value]) {
      result[value] = [];
    }
    result[value].push(key);
  });

  const mergedThemeMetaData: Record<string, string[]> = {};
  const reassignedKeys = new Set<string>(Object.keys(input));

  Object.entries(themeMetaData).forEach(([key, values]) => {
    const filteredValues = values.filter((value) => !reassignedKeys.has(value));
    mergedThemeMetaData[key] = [...filteredValues, ...(result[key] || [])];
  });

  Object.entries(result).forEach(([key, values]) => {
    if (!mergedThemeMetaData[key]) {
      mergedThemeMetaData[key] = values;
    }
  });

  return mergedThemeMetaData;
}

export default transformTokenOverrides;
