import { useParams } from 'react-router-dom';

import { useCampaign } from '@/hooks/useAdNetwork/internal';
import useCampaignOpportunityGroups from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroups';
import { AdNetworkCampaign } from '@/interfaces/ad_network/internal/campaign';
import { AdNetworkCampaignOpportunityGroup } from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import { List } from '@/routes/ad_network/_components/List';
import { LoadingListPage } from '@/routes/ad_network/_components/LoadingListPage';
import CampaignOpportunityGroupStatusIndicator from '@/routes/ad_network/_components/StatusIndicator/CampaignOpportunityGroupStatusIndicator';
import { Button } from '@/ui/Button';

import { PageHeading } from '../../../_components/PageHeading';

interface Props {
  campaign: AdNetworkCampaign;
  campaignOpportunityGroups: AdNetworkCampaignOpportunityGroup[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

const CampaignProvision = ({ campaign, campaignOpportunityGroups, hasNextPage, fetchNextPage }: Props) => {
  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{campaign.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Opportunity groups</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button size="xs" variant="primary-inverse" type="button" to="new">
            New group
          </Button>
        </PageHeading.Side>
      </PageHeading>
      <List>
        {campaignOpportunityGroups.map((group) => {
          return (
            <List.Item key={group.id} to={`/ad_network/campaign_opportunity_groups/${group.id}`}>
              <List.Item.Text variant="primary">
                <span>{group.name}</span>
                <span>
                  <CampaignOpportunityGroupStatusIndicator campaignOpportunityGroup={group} />
                </span>
              </List.Item.Text>
            </List.Item>
          );
        })}
      </List>

      {hasNextPage && (
        <div className="p-4 border-t border-gray-100">
          <Button onClick={() => fetchNextPage()} variant="primary-inverse">
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

export default function Loader() {
  const { campaign_id: id } = useParams<'campaign_id'>() as { campaign_id: string };
  const campaignQuery = useCampaign({ id });
  const groupsQuery = useCampaignOpportunityGroups({ campaignId: id });

  const isSuccess = campaignQuery.isSuccess && groupsQuery.isSuccess;
  const isLoading = campaignQuery.isLoading || groupsQuery.isLoading;
  const isError = campaignQuery.isError || groupsQuery.isError;

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const { data: campaign } = campaignQuery;
  const { data: groupsData, hasNextPage, fetchNextPage } = groupsQuery;
  const groups = groupsData.pages?.flatMap((page) => page.campaign_opportunity_groups) || [];

  return (
    <CampaignProvision
      campaign={campaign}
      campaignOpportunityGroups={groups}
      hasNextPage={!!hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
}
