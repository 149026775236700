import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  ClipboardIcon,
  HandThumbDownIcon as SolidThumbsDown,
  HandThumbUpIcon as SolidThumbsUp,
} from '@heroicons/react/20/solid';
import {
  HandThumbDownIcon as OutlineThumbsDown,
  HandThumbUpIcon as OutlineThumbsUp,
} from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import IconButton from '@/components/IconHelpers/IconButton';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useCopyToClipboard, useInfiniteScroll } from '@/hooks';
import useCampaignOpportunities from '@/hooks/useAdNetwork/advertiser_access/useCampaignOpportunities';
import usePublisherListItemCreate from '@/hooks/useAdNetwork/advertiser_access/usePublisherListItemCreate';
import usePublisherListItemDestroy from '@/hooks/useAdNetwork/advertiser_access/usePublisherListItemDestroy';
import usePublisherListItemUpdate from '@/hooks/useAdNetwork/advertiser_access/usePublisherListItemUpdate';
import {
  AdNetworkCampaignPerformanceFilter,
  AdNetworkCampaignPerformanceOpportunity,
} from '@/interfaces/ad_network/internal/campaign_performance_opportunity';
import { InfiniteScrollOptions } from '@/interfaces/infinite_scroll_options';

interface Props {
  opportunities: AdNetworkCampaignPerformanceOpportunity[];
  infiniteScrollOptions: InfiniteScrollOptions;
  isFetchingNextPage: boolean;
  refetchData: any;
}

const formatter = new Intl.NumberFormat('en-US');

const CampaignPerformanceDelivered = ({
  opportunities,
  infiniteScrollOptions,
  refetchData,
  isFetchingNextPage,
}: Props) => {
  const { ref: lastElementRef } = useInfiniteScroll(infiniteScrollOptions);

  const { mutateAsync: createAsync } = usePublisherListItemCreate();
  const { mutateAsync: updateAsync } = usePublisherListItemUpdate();
  const { mutateAsync: destroyAsync } = usePublisherListItemDestroy();

  const generateToast = (
    type: 'create' | 'destroy' | 'update',
    opportunity: AdNetworkCampaignPerformanceOpportunity,
    listType: string
  ) => {
    let toastMessage =
      listType === 'preferred'
        ? 'This publisher will be included in more future opportunities'
        : 'This publisher will be excluded from future opportunities';
    if (type === 'destroy')
      toastMessage =
        listType === 'preferred'
          ? 'This publisher has been removed from the preferred list'
          : 'This publisher has been removed from the exclusion list';

    toast.success(toastMessage);
  };

  const createMutation = (opportunity: AdNetworkCampaignPerformanceOpportunity, listType: string) => {
    createAsync({ advertiserId: opportunity.advertiser_id, publisherId: opportunity.publication_id, listType })
      .then(() => {
        refetchData();
      })
      .then(() => {
        generateToast('create', opportunity, listType);
      });
  };

  const updateMutation = (opportunity: AdNetworkCampaignPerformanceOpportunity, listType: string) => {
    updateAsync({
      advertiserId: opportunity.advertiser_id,
      publisherId: opportunity.publication_id,
      publisherListItemId: opportunity.publisher_list_item.id,
      listType,
    })
      .then(() => {
        refetchData();
      })
      .then(() => {
        generateToast('update', opportunity, listType);
      });
  };

  const destroyMutation = (opportunity: AdNetworkCampaignPerformanceOpportunity, listType: string) => {
    destroyAsync({
      advertiserId: opportunity.advertiser_id,
      publisherId: opportunity.publication_id,
      publisherListItemId: opportunity.publisher_list_item.id,
      listType,
    })
      .then(() => {
        refetchData();
      })
      .then(() => {
        generateToast('destroy', opportunity, listType);
      });
  };

  const copy = useCopyToClipboard();

  const handleVote = (opportunity: AdNetworkCampaignPerformanceOpportunity, voteType: string) => {
    if (opportunity.publisher_list_item.id) {
      if (opportunity.publisher_list_item.type === voteType) {
        destroyMutation(opportunity, voteType);
      } else {
        updateMutation(opportunity, voteType);
      }
    } else {
      createMutation(opportunity, voteType);
    }
  };

  return (
    <div className="flex flex-col overflow-x-auto">
      <div className="overflow-x-auto w-full">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="text-lg font-bold whitespace-nowrap pr-6" align="left">
                Publication
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="left">
                Date
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="left">
                Advertisement
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                Impressions
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                Total Clicks
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                Unique Clicks
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                CTR
              </th>
              <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                Performance
              </th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {opportunities.map((opportunity) => (
              <tr
                key={opportunity.ad_network_opportunity_id}
                ref={
                  opportunities[opportunities.length - 1].ad_network_opportunity_id ===
                  opportunity.ad_network_opportunity_id
                    ? lastElementRef
                    : null
                }
              >
                <td
                  className="py-6 pr-6 truncate group cursor-pointer"
                  align="left"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  onClick={() =>
                    copy({
                      text: opportunity.publication_alphanumeric_id,
                      onSuccessText: `Copied ${opportunity.publication_alphanumeric_id} to clipboard`,
                      onErrorText: 'Failed to copy to clipboard',
                    })
                  }
                >
                  <p className="inline-flex space-x-2 items-center">
                    <span className="font-mono">{opportunity.publication_alphanumeric_id}</span>
                    <span>
                      <ClipboardIcon className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 duration-300" />
                    </span>
                  </p>
                </td>
                <td className="py-6 pl-6 truncate" align="left">
                  {moment(opportunity.date).format('LL')}
                </td>
                <td className="py-6 pl-6 truncate" align="left">
                  <span>{opportunity.advertisement_option_name}</span>
                </td>
                <td className="py-6 pl-6 truncate" align="right">
                  {formatter.format(opportunity.total_impressions)}
                </td>
                <td className="py-6 pl-6 truncate" align="right">
                  {formatter.format(opportunity.total_eligible_clicked)}
                </td>
                <td className="py-6 pl-6 truncate" align="right">
                  {formatter.format(opportunity.total_unique_eligible_clicked)}
                </td>
                <td className="py-6 pl-6 truncate" align="right">
                  {formatter.format(opportunity.click_rate)}%
                </td>
                <td className="py-6 pl-6 truncate" align="center">
                  <div className="flex w-12">
                    <IconButton className="!bg-transparent" onClick={() => handleVote(opportunity, 'preferred')}>
                      {opportunity.publisher_list_item.type === 'preferred' ? (
                        <SolidThumbsUp aria-hidden="true" className="text-green-500" />
                      ) : (
                        <OutlineThumbsUp aria-hidden="true" />
                      )}
                    </IconButton>
                    <IconButton className="!bg-transparent" onClick={() => handleVote(opportunity, 'excluded')}>
                      {opportunity.publisher_list_item.type === 'excluded' ? (
                        <SolidThumbsDown aria-hidden="true" className="text-red-500" />
                      ) : (
                        <OutlineThumbsDown aria-hidden="true" />
                      )}
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center text-gray-400 text-sm h-12">
        {infiniteScrollOptions.isFetching || infiniteScrollOptions.isLoading || isFetchingNextPage ? (
          <>
            <LoadingSpinner className="mr-2" />
            <p>Loading reports...</p>
          </>
        ) : (
          infiniteScrollOptions.hasNextPage && <p>Scroll down to load more reports</p>
        )}
      </div>
    </div>
  );
};

export default function Loader() {
  const { advertiser_id: advertiserId, campaign_id: campaignId } = useParams<{
    advertiser_id: string;
    campaign_id: string;
  }>() as { advertiser_id: string; campaign_id: string };

  const { data, isSuccess, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isLoading, refetch } =
    useCampaignOpportunities({ campaignId, advertiserId, filter: AdNetworkCampaignPerformanceFilter.DELIVERED });

  if (!isSuccess) return null;

  const opportunities = data?.pages.map((page) => page.opportunities).flat();

  return (
    <CampaignPerformanceDelivered
      opportunities={opportunities}
      infiniteScrollOptions={{
        fetchNextPage,
        hasNextPage: !!hasNextPage,
        isFetching,
        isLoading,
      }}
      isFetchingNextPage={isFetchingNextPage}
      refetchData={refetch}
    />
  );
}
