import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { PublicationSearch } from '@/interfaces/publication_search';

interface Props {
  isActive: boolean;
  publication: PublicationSearch;
}

const SearchListItem = ({ isActive, publication }: Props) => (
  <>
    <div className="w-6 h-6 border rounded overflow-hidden">
      <figure className="aspect-w-1 aspect-h-1 relative h-full w-full">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={publication.logo_url}
          alt={publication.name}
        />
      </figure>
    </div>
    <span className="ml-3 flex-auto truncate">{publication.name}</span>
    {isActive && <ChevronRightIcon className="ml-3 h-5 w-5 flex-none text-gray-400" aria-hidden="true" />}
  </>
);

export default SearchListItem;
