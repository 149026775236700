import { CsvColumn } from '@/interfaces/csv_column';

import PreImport from '../PreImport';

import { handleCsvMappingChange } from './modules/handleCsvMappingChange';

interface Props {
  publicationName?: string;
  showMappingModal: boolean;
  csvColumns: CsvColumn[];
  setColumnMappingErrors: (value: boolean) => void;
  setColumnMapping: (value: { [key: number]: any }) => void;
}

const ImportSubscriberModalContent = ({
  publicationName,
  showMappingModal,
  csvColumns,
  setColumnMappingErrors,
  setColumnMapping,
}: Props) => {
  return showMappingModal ? (
    <PreImport columns={csvColumns} onChange={handleCsvMappingChange(setColumnMappingErrors, setColumnMapping)} />
  ) : (
    <div>
      {`You are adding these subscribers to the ${publicationName} list. Are you sure this is the correct list and that these contacts have knowingly opted in to receiving email from you?`}
    </div>
  );
};

export default ImportSubscriberModalContent;
