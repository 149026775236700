import { PropsWithChildren, useRef } from 'react';
import { PencilIcon } from '@heroicons/react/20/solid';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { v4 as generateUuid } from 'uuid';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography } from '@/components/Typography';

type Props = PropsWithChildren<{
  onSave: Function;
  isBusy: boolean;
  emptyStateType?: 'square' | 'rectangle';
}>;

const FileUploader = ({ onSave, children, isBusy = false, emptyStateType = 'square' }: Props) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleImageClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files && e.target.files.length > 0) {
      // setImage(e.target.files[0]);

      if (onSave) {
        onSave(e.target.files[0]);
      }
    }
  };

  const inputId = generateUuid();

  const areChildrenEmpty = !children;

  return (
    <div
      className={classNames(
        'flex justify-between items-center border border-surface-200 rounded-md w-fit h-fit',
        areChildrenEmpty && 'border-dashed'
      )}
    >
      <input
        name={`file-uploader-file-input-${inputId}`}
        ref={fileInput}
        type="file"
        onChange={handleFileInput}
        className="hidden"
        disabled={isBusy}
      />

      <div className="inline-block rounded-sm relative">
        <button
          type="button"
          onClick={handleImageClick}
          className="flex relative w-auto h-auto bg-black bg-opacity-0 group rounded-md overflow-hidden border-0 hover:bg-surface-50"
          disabled={isBusy}
        >
          {areChildrenEmpty ? (
            <div
              className={classNames(
                'flex flex-col gap-y-[10px] items-center justify-center p-3 overflow-hidden',
                emptyStateType === 'square' && 'w-28',
                emptyStateType === 'rectangle' && 'w-36'
              )}
            >
              <div className="flex items-center justify-center h-6 w-6 rounded-full bg-surface-100">
                <ArrowUpTrayIcon className="w-3 h-3 text-surface-500" />
              </div>
              <Typography token="font-normal/text/xs" colorWeight="600">
                browse or upload
              </Typography>
            </div>
          ) : (
            children
          )}
          <span
            className={classNames(
              'flex items-center justify-center text-white absolute top-0 left-0 w-full h-full',
              isBusy && 'visible bg-opacity-50 bg-surface-100',
              !areChildrenEmpty &&
                !isBusy &&
                'invisible group-hover:visible group-hover:bg-black group-hover:bg-opacity-50'
            )}
          >
            {isBusy ? <LoadingSpinner /> : null}
            {!areChildrenEmpty ? <PencilIcon className="h-4 w-4" /> : null}
          </span>
        </button>
      </div>
    </div>
  );
};

export default FileUploader;
