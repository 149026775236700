import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import LoadingBox from '../../../components/LoadingBox';
import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { usePostTemplate } from '../../../hooks';
import api from '../../../services/swarm';
import Form from '../Form';
import { useTemplateViewContext } from '..';

interface RouteParams {
  templateId: string;
}

const EditPostTemplate: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const { templateId: id } = useParams() as unknown as RouteParams;
  const [currentPublicationId] = useCurrentPublicationState();
  const { resourceType } = useTemplateViewContext();

  const { data, isLoading, isError } = usePostTemplate(id);

  const savePostTemplateMutation = useMutation(
    ({ postTemplate }: { postTemplate: any }) =>
      api.patch(`/post_templates/${id}`, {
        publication_id: currentPublicationId,
        post_template: postTemplate,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (res) => {
        toast.success('Post template saved');
        navigate(`/templates/${resourceType}/${res.data.id}`);
      },
    }
  );

  const onSubmit = async (name: string, templateContent: any) => {
    const postTemplate = {
      name,
      tiptap_state: templateContent,
    };

    await savePostTemplateMutation.mutateAsync({ postTemplate });
  };

  return (
    <div>
      <div className="flex mb-4">
        {data && (
          <Link className="text-sm text-gray-500 hover:text-primary-500" to={`/templates/${resourceType}/${data.id}`}>
            <div className="flex items-center">
              <ArrowLeftIcon className="w-4 h-4 mr-2" />
              <span>Back to template</span>
            </div>
          </Link>
        )}
      </div>
      <div className="my-4">
        <LoadingBox isLoading={isLoading} isError={isError}>
          {data && (
            <Form onSubmit={onSubmit} header="Edit Template" name={data.name} tiptapState={data.tiptap_state || {}} />
          )}
        </LoadingBox>
      </div>
    </div>
  );
};

export default EditPostTemplate;
