import { useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

import useApplicationMetrics from '@/hooks/boosts/grow/useApplicationMetrics';
import useWebApplication from '@/hooks/boosts/grow/useWebApplication';
import { useCreateAbuseReport } from '@/hooks/useAbuseReports';
import { BoostAgreement } from '@/interfaces/boosts/grow/boost_agreement';

import ApplicationCard from './ApplicationCard';
import ConfirmBoostAgreementRejectModal from './ConfirmBoostAgreementRejectModal';
import ReviewBoostModal from './ReviewBoostModal';

interface Props {
  item: BoostAgreement;
}

const WebApplicationItem = ({ item }: Props) => {
  const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false);

  const { data } = useApplicationMetrics(item.id);
  const applicantMetrics = data?.metrics;
  const [searchParams, setSearchParams] = useSearchParams();
  const isReviewModalOpen = searchParams.get('boost_agreement_id') === item.id;

  const [isAccepted, setIsAccepted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const handleClickReview = () => {
    searchParams.set('boost_agreement_id', item.id);
    setSearchParams(searchParams);
  };

  const handleClickReject = () => setShowConfirmRejectModal(true);

  const { handleAccept, handleReject, isAccepting, isRejecting } = useWebApplication(item, {
    onAcceptSuccess: () => setIsAccepted(true),
    onRejectSuccess: () => setIsRejected(true),
  });

  const handleSuccessfulRejection = () => {
    searchParams.delete('boost_agreement_id');
    setSearchParams(searchParams);
    setIsRejected(true);
  };

  const onAbuseReportSuccess = () => {
    toast.success('Report submitted');
    handleReject('');
  };

  const { mutateAsync: createAbuseReport, isLoading: isReportLoading } = useCreateAbuseReport({
    onSuccess: onAbuseReportSuccess,
  });

  const handleReport = () => {
    createAbuseReport({
      publication_id: item.recommending_publication.id,
    });
  };

  return (
    <>
      <ConfirmBoostAgreementRejectModal
        isOpen={showConfirmRejectModal}
        boostAgreement={item}
        onClickCancel={() => setShowConfirmRejectModal(false)}
        onSuccess={handleSuccessfulRejection}
      />
      <ReviewBoostModal
        key={item.id}
        isOpen={isReviewModalOpen}
        boostAgreement={item}
        onClickReject={handleClickReject}
        onClickAccept={handleAccept}
        onClickReport={handleReport}
        isReporting={isReportLoading}
      />
      <ApplicationCard
        publication={item.recommending_publication}
        createdAt={item.created_at}
        onClickReview={handleClickReview}
        referralQuality={
          typeof applicantMetrics?.referral_quality === 'undefined' ? null : applicantMetrics?.referral_quality
        }
        showReferralQuality
        onAccept={handleAccept}
        onReject={handleClickReject}
        isAccepting={isAccepting}
        isRejecting={isRejecting}
        isAccepted={isAccepted}
        isRejected={isRejected}
      />
    </>
  );
};

export default WebApplicationItem;
