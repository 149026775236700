import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { PublicationLogo } from '@/components/PublicationLogo';
import { Typography, TypographyStack } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { BoostAgreement } from '@/interfaces/boosts/monetize/boost_agreement';
import { IconContainer } from '@/ui/IconContainer';

import ActionModal from '../../../../../components/ActionModal';
import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { useCreateBoostSend } from '../../../../../hooks/boosts/monetize/useBoostSends';

interface GridRowProps {
  label: string;
  value: any;
  children?: React.ReactNode;
}

const InfoGridRow = ({ label, value, children }: GridRowProps) => (
  <div className="py-4 grid grid-cols-3 sm:gap-4 sm:grid-cols-12 py-5">
    <dt className="text-lg font-medium text-gray-500 col-span-3">
      <div className="flex h-full items-center content-center">
        <span>{label}</span>
      </div>
    </dt>
    <dd className="mt-1 text-lg text-sm text-gray-900 col-span-3">
      <div className="flex h-full items-center content-center">
        <div className="bg-gray-100 w-28 text-gray-800 border-gray-200 text-center justify-center rounded-lg flex px-4 px-2">
          <span>{value}</span>
        </div>
      </div>
    </dd>
    <dd className="mt-1 text-sm text-gray-900 italic col-span-6 mt-0">{children}</dd>
  </div>
);
interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  boostAgreement: BoostAgreement | null;
}

const CreateBoostsPlusModal = ({ isOpen, onClose, boostAgreement, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const { settings } = useSettings();

  if (!boostAgreement) {
    return null;
  }

  const shouldShowNewUI = settings?.boosts_monetize_v2 === true;

  const handleClose = () => {
    onClose();
  };

  const createBoostSend = useCreateBoostSend({
    onSuccess: () => {
      toast.success('Request to send boost created successfully.');
      onSuccess();
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'monetize', 'boost_sends']);
      handleClose();
    },
  });

  const handleSubmit = () => {
    // Casting the arguments because we know it's not null
    createBoostSend.mutate({
      boost_agreement_id: boostAgreement.id,
    });
  };

  if (shouldShowNewUI) {
    return (
      <ActionModal
        isOpen={isOpen}
        isWorking={createBoostSend.isLoading}
        onClose={handleClose}
        onProceed={() => {
          handleSubmit();
        }}
        resourceId={boostAgreement.id}
        actionText="I understand"
      >
        <div className="flex gap-x-4">
          <IconContainer backgroundColor="bg-feedback-info-50" iconColor="text-feedback-info-400">
            <InformationCircleIcon />
          </IconContainer>
          <TypographyStack gap="2">
            <Typography token="font-medium/text/lg">
              Apply to Boost {boostAgreement.boost_offer.boosted_publication.name} via email
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="700">
              Once accepted you will have 72 hours to include this Boost in one of your newsletters.
            </Typography>
            <Typography token="font-normal/text/sm" color="info" colorWeight="700">
              Disclaimer: failure to include this offer in a future email may affect your eligibility to use this
              feature.
            </Typography>
          </TypographyStack>
        </div>
      </ActionModal>
    );
  }

  return (
    <ActionModal
      isOpen={isOpen}
      isWorking={createBoostSend.isLoading}
      onClose={handleClose}
      onProceed={() => {
        handleSubmit();
      }}
      resourceId={boostAgreement.id}
      headerText="Send a Boost"
      modalSize="lg"
      actionText="Request"
    >
      <div className="flex items-center space-x-2 text-wrap">
        <PublicationLogo publication={boostAgreement.boost_offer.boosted_publication} size="lg" />

        <span className="text-lg text-gray-500">{boostAgreement.boost_offer.boosted_publication.name}</span>
      </div>
      <div className="mt-6 space-y-6">
        <p className="text-gray-500">
          Add this Boost to an upcoming post and promote it to all of your subscribers at once.
        </p>
        <dl className="sm:divide-y sm:divide-gray-200">
          <InfoGridRow label="CPA" value={boostAgreement.boost_offer.payout_per_lead}>
            <span>This is how much you will earn per verified subscriber.</span>
          </InfoGridRow>
          <InfoGridRow label="Max Payout" value={boostAgreement.calc_max_payout}>
            <span>This is the maximum amount you can earn per send for this Boost.</span>
          </InfoGridRow>
          <InfoGridRow label="Send By" value="72 Hours">
            <span>
              Once your request is approved, you will have 72 hours to include this Boost in an upcoming newsletter
              send. The send by date will be viewable in your dashboard and within the editor.
            </span>
          </InfoGridRow>
          <div className="py-4 grid grid-cols-3 sm:gap-4 sm:grid-cols-12 py-5">
            <dt className="text-lg font-medium text-gray-500 col-span-4">
              <div className="flex h-full items-center content-center">
                <span>Adding to Post</span>
              </div>
            </dt>
            <dd className="mt-1 text-sm text-gray-900 col-span-8 mt-0">
              <span className="italic">
                Within the Post Editor you can access your available Email Boosts directly in the main menu by clicking
                &apos;+&apos; or typing &apos;/boost.&apos;
              </span>
            </dd>
          </div>
        </dl>

        <div className="flex items-center justify-center">
          <span className="text-gray-900 text-sm italic font-bold text-center">
            Remember to monitor your Email Boosts in your dashboard as they get approved.
          </span>
        </div>
      </div>
    </ActionModal>
  );
};

export default CreateBoostsPlusModal;
