import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '../services/swarm';

const useSendEmailConfirmation = () =>
  useMutation(() => api.post(`/email_confirmations`), {
    onSuccess: () => {
      toast.success('Email confirmation sent');
    },
    onError: (errPayload: any) => {
      const error = errPayload?.response?.data?.error || 'Something went wrong';
      toast.error(error);
    },
  });

export default useSendEmailConfirmation;
