import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useCurrentPublicationState } from '../../../context/current-publication-context';
// Services
import api from '../../../services/swarm';
// Components
import ContentTagForm from '../ContentTagForm';

const NewContentTag = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();

  const createContentTagMutation = useMutation(
    ({ display, description }: { display: string; description: string }) =>
      api.post('/content_tags', {
        publication_id: currentPublicationId,
        content_tag: {
          display,
          description,
        },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        toast.success('Content Tag created successfully');
        navigate(`/content_tags`);
      },
    }
  );

  const onSubmit = async (display: string, description: string) => {
    await createContentTagMutation.mutateAsync({ display, description });
  };

  return <ContentTagForm onSubmit={onSubmit} header="New Content Tag" />;
};

export default NewContentTag;
