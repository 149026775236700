import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import SettingsSectionHeader from './SettingsSectionHeader';

type Props = PropsWithChildren<{
  title: string;
  Icon?: React.ElementType;
  description?: string;
  className?: string;
}>;

const SettingsSection = ({ Icon, title, description, children, className }: Props) => {
  return (
    <div className={classNames('flex flex-col space-y-4', className)}>
      <SettingsSectionHeader title={title} description={description} Icon={Icon} />
      {children}
    </div>
  );
};

export default SettingsSection;
