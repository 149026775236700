import { FC } from 'react';
import { ResetPasswordElement } from '@shared/dream-components';
import { NodeViewProps, NodeViewWrapper, useReactNodeView } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

export const ResetPasswordView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <NodeViewWrapper data-drag-handle data-id={node.attrs.id}>
      <ResetPasswordElement
        element={{
          type: 'resetPassword',
          attrs: {
            id: node.attrs.id,
            ...node.attrs,
          },
          content: [{ id: 'text', text: '' }],
        }}
        onDragStart={onDragStart}
        attributes={{
          'data-node-view-wrapper': '',
          ...getDataAttributes(HTMLAttributes),
        }}
        className="relative"
      >
        {/* So signup can easily be selected, try removing and see what happens */}
        <div className="absolute top-0 left-0 w-full h-full bg-transparent" />
      </ResetPasswordElement>
    </NodeViewWrapper>
  );
};
