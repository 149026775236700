import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export const ThreadComposerContext = createContext<{
  isComposing: boolean;
  setComposing: (newState: boolean) => void;
  openComposer: () => void;
  closeComposer: () => void;
}>({
  isComposing: false,
  setComposing: () => {},
  openComposer: () => {},
  closeComposer: () => {},
});

export const useThreadComposer = () => useContext(ThreadComposerContext);

export const ThreadComposerProvider = ({ children }: { children: React.ReactNode }) => {
  const [isComposing, setComposing] = useState(false);

  const openComposer = useCallback(() => {
    setComposing(true);
  }, []);

  const closeComposer = useCallback(() => {
    setComposing(false);
  }, []);

  const providerValue = useMemo(
    () => ({
      isComposing,
      setComposing,
      openComposer,
      closeComposer,
    }),
    [isComposing, setComposing, openComposer, closeComposer]
  );

  return <ThreadComposerContext.Provider value={providerValue}>{children}</ThreadComposerContext.Provider>;
};

export const ThreadComposerConsumer = ThreadComposerContext.Consumer;
