import { Fragment, ReactNode } from 'react';

import { useSettings } from '@/context/settings-context';
import usePostsFactPostClick from '@/hooks/usePostsFactPostClick';
import { FactPostClick } from '@/interfaces/fact_post_click';

interface Props {
  postId: string;
  factPostClickId: string;
  children: (metrics: FactPostClick | undefined, isLoading: boolean, isError: boolean) => ReactNode;
}

const PostFactPostClicksContainer = ({ postId, factPostClickId, children }: Props) => {
  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;

  const { data, isLoading, isError } = usePostsFactPostClick({ postId, factPostClickId, useApiary: canUseApiary });

  const metrics = data?.fact_post_click;

  return <>{children(metrics, isLoading, isError)}</>;
};

export default PostFactPostClicksContainer;
