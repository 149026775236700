import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { PostGroupType } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';

import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { NumberInput } from '@/routes/website/_components/UI/Input';

import InputWrapper from '../../../../UI/InputWrapper';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '../../../../UI/Select';
import { DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG } from '../../../dummyData/tags';

type Props = Partial<NodeViewProps> & {
  pos: number;
  setIsCategorySelected: (value: boolean) => void;
  setPostType: (value: 'free_selection' | 'latest' | 'featured' | any) => void;
  postType: 'free_selection' | 'latest' | 'featured' | any;
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
  showFakeData: boolean;
};

const PostTypeSelect = ({
  setIsCategorySelected,
  setPostType,
  postType,
  count,
  setCount,
  node,
  editor,
  pos,
  showFakeData,
}: Props) => {
  const publicationId = useCurrentPublicationId();
  const tags = useOptions(publicationId, 'content_tags');
  const currentPostGroupType = node?.attrs.postGroupType;
  const tagOptions = useMemo(() => {
    if (showFakeData) {
      return [DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG].map((tag) => ({
        label: tag.display,
        value: tag.display,
      }));
    }

    return tags?.data?.options?.map((tag: any) => ({ label: tag?.display, value: tag?.display }));
  }, [tags, showFakeData]);

  const handleUpdatePostGroupType = useCallback(
    (value: PostGroupType) => {
      editor?.commands.command(({ tr }) => {
        tr.setNodeAttribute(pos ?? 0, 'postGroupType', value);
        return true;
      });
    },
    [editor, pos]
  );

  return (
    <div className="flex items-end gap-2">
      <InputWrapper name="post_type" labelText="Post Type" className="w-[250px]">
        <Select
          defaultValue={currentPostGroupType}
          value={postType}
          onValueChange={(value) => {
            // Let the parent know if a catgery has been selected to determine if we should insert the appropriate tags
            const isCategory = tagOptions?.find((option: any) => option.value === value);

            if (isCategory) {
              setIsCategorySelected(true);
            } else {
              setIsCategorySelected(false);
            }

            setPostType(value);
            handleUpdatePostGroupType(value as PostGroupType);
          }}
        >
          <SelectTrigger className="w-full" id="by_status">
            <SelectValue placeholder="Select a category" />
          </SelectTrigger>
          <SelectContent className="max-h-[280px] overflow-y-auto no-scrollbar">
            <SelectGroup>
              <SelectLabel>Basic</SelectLabel>
              <SelectItem value="free_selection">Free Selection</SelectItem>
              <SelectItem value="latest">Latest</SelectItem>
              <SelectItem value="archive">Archive</SelectItem>
            </SelectGroup>
            <SelectSeparator />
            <SelectGroup>
              <SelectLabel>Categories</SelectLabel>
              {tagOptions?.map((option: any) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </InputWrapper>
      {postType === 'latest' && (
        <NumberInput
          name="post_count"
          labelText="Post Count"
          type="number"
          value={count}
          onChange={(e) => setCount(parseInt(e.target.value, 10))}
          className="max-w-[120px]"
          onClickMinus={() => setCount((c) => c - 1)}
          onClickPlus={() => setCount((c) => c + 1)}
        />
      )}
    </div>
  );
};

export default PostTypeSelect;
