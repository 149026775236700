import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import api from '../services/swarm';

interface MutationParams {
  postId: string;
  onSuccess?: () => void;
}

const useUpdatePost = (mutationParams: MutationParams) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const { postId, onSuccess } = mutationParams;

  return useMutation(
    (params: any) =>
      api
        .patch(`/posts/${postId}`, {
          publication_id: currentPublicationId,
          ...params,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['posts', postId], { exact: true });
        queryClient.invalidateQueries(['posts', postId, 'preview'], { exact: true });
        onSuccess?.();
      },
    }
  );
};

export default useUpdatePost;
