import { PropsWithChildren } from 'react';
import cx from 'classnames';

import { Typography } from '../Typography';

type Props = PropsWithChildren<{
  className?: string;
}>;

const ErrorText = ({ children, className }: Props) => {
  return (
    <Typography
      token="font-light/text/xs"
      color="danger"
      className={cx(className, 'block leading-snug')}
      colorWeight="600"
    >
      {children}
    </Typography>
  );
};

export default ErrorText;
