import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { TiptapState } from '@/interfaces/editor_versions';
import api from '@/services/swarm';

const useFetchPreview = (publicationId: string, emailMessageId: string, tiptapState: TiptapState) => {
  return useCallback(
    (_platform: string, onFetch: Function) => {
      const payload = {
        publication_id: publicationId,
        tiptap_state: tiptapState,
      };

      api
        .post(`/email_messages/${emailMessageId}/preview`, payload)
        .then((resp) => {
          onFetch(resp.data.html);
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId, tiptapState, emailMessageId]
  );
};

export default useFetchPreview;
