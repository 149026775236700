import { QueryParamConfig } from 'use-query-params';

import { NameTypes } from '@/pages/Segments/ConditionsEditor/types';

import { LogicalOperator } from './condition';

export interface Segment {
  id: string;
  name: string;
  description?: string;
  segment_type: SegmentType;
  num_members: number;
  status: SegmentStatus;
  created_at: Date;
  last_processed_at: Date;
  paused: boolean;
  failed_reason: string;
  recalculatable: boolean;
  deletable: boolean;
  has_post_send_targets: boolean;
  is_locked: boolean;
  updated_at: Date;
  metrics: SegmentMetrics;
  taggable?: boolean;
  conditions: {
    conditions: any;
    logical_operator: LogicalOperator;
  };
}

export interface SegmentMetrics {
  open_rate: number;
  click_through_rate: number;
  pct_unsubscribed: number;
}

export interface Refine {
  within?: number;
  url?: string;
  fromPost?: string;
}

export interface Filter {
  operator: string;
  value?: string | number;
  relative?: string;
  resource_id?: string;
}

export interface Condition {
  uuid: string;
  filters?: Filter;
  refine?: Refine;
  name?: string;
  type: NameTypes;
}

export interface Group {
  uuid: string;
  conditions: Condition[];
  logical_operator: LogicalOperator;
  type: NameTypes;
}

export const enum SegmentType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
  MANUAL = 'manual',
}

export const enum SegmentStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export type SegmentFormQueryParams = {
  search: QueryParamConfig<string>;
  filterByDateCreated: QueryParamConfig<string>;
  filterByDateLastUpdated: QueryParamConfig<string>;
  order: QueryParamConfig<string>;
  dir: QueryParamConfig<string>;
};
