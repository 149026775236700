import { Disclosure } from '@headlessui/react';
import cx from 'classnames';

const DisclosurePanel = ({ className }: { className: string }) => {
  return (
    <Disclosure className={cx('space-y-2', className)} as="div">
      <Disclosure.Button className="text-sm text-left text-gray-500 hover:underline hover:text-primary-500">
        How should I use this chart?
      </Disclosure.Button>
      <Disclosure.Panel className="text-xs p-4 bg-gray-100 text-gray-800 rounded">
        The comparison chart illustrates some high-level metrics between your publication and the applicants to help you
        make a decision about accepting a boost. For example, if you have a high CPA, you might look for small niche
        audiences with high engagement. Keep in mind that since you only pay for engaged subscribers, similarity between
        your publication and the applicant is not necessarily an indication of how well the boost will perform.
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default DisclosurePanel;
