import { useSearchParams } from 'react-router-dom';

import { CustomDomainTypes } from '@/interfaces/custom_domain';

const useVerificationModals = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedDnsRecordType = searchParams.get('verify') as CustomDomainTypes;
  const dnsRecordsModalOpen = Boolean(selectedDnsRecordType);

  const handleOpenDnsRecordsModal = (selectedType: CustomDomainTypes) => {
    searchParams.set('verify', selectedType);
    setSearchParams(searchParams);
  };

  const handleCloseDnsRecordsModal = () => {
    searchParams.delete('verify');
    setSearchParams(searchParams);
  };

  return {
    dnsRecordsModalOpen,
    selectedDnsRecordType,
    handleOpenDnsRecordsModal,
    handleCloseDnsRecordsModal,
  };
};

export default useVerificationModals;
