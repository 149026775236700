import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import usePageNavigator from '@/routes/website_builder/_hooks/usePageNavigator';
import useTabNavigator from '@/routes/website_builder/_hooks/useTabNavigator';
import { Button } from '@/ui/Button';

import BackButton from '../BackButton';

const ADVANCED_TAB = '/website_builder/settings';

const SettingsTabs = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { pageType, handlePageChange } = usePageNavigator();
  const { isStylesTab, isLayoutTab, handleTabChange } = useTabNavigator();

  const isAdvanced = pathname.includes(ADVANCED_TAB);

  return (
    <div className="flex space-x-2 items-center">
      <BackButton
        onClick={() => {
          navigate('/');
        }}
      />
      <div className="flex bg-white shadow rounded-md p-1 space-x-1">
        <Button
          variant={isStylesTab ? 'primary' : 'flush'}
          onClick={() => {
            handleTabChange('styles');
          }}
          className={cx(isStylesTab, '!px-3')}
        >
          Styles
        </Button>
        <Button
          variant={isLayoutTab ? 'primary' : 'flush'}
          onClick={() => {
            handleTabChange('layout');
          }}
          className={cx(isLayoutTab, '!px-3')}
        >
          Layout
        </Button>
        <Button
          variant={isAdvanced ? 'primary' : 'flush'}
          onClick={() => {
            handlePageChange({ path: ADVANCED_TAB, pageType });
          }}
          className={cx(isAdvanced, '!px-3')}
        >
          Settings
        </Button>
      </div>
    </div>
  );
};

export default SettingsTabs;
