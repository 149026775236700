import { useEffect, useState } from 'react';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { useCopyToClipboard } from '../../../../hooks';

import type { DescriptionListProps, ItemProps } from './types';

const DescriptionList = ({ children }: DescriptionListProps) => {
  return <dl className="sm:divide-y sm:divide-gray-100">{children}</dl>;
};

DescriptionList.Item = ({ term, children, copyValue }: ItemProps) => {
  const copy = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    if (!copyValue) return;

    copy({ text: copyValue });
    setIsCopied(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsCopied(false), 2000);
    return () => clearTimeout(timeout);
  }, [isCopied]);

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 group p-4 sm:py-5">
      <dt className="text-sm font-medium text-gray-500">{term}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center">
        {children}
        {copyValue && (
          <button type="button" onClick={handleCopy}>
            <ClipboardDocumentCheckIcon
              className={cx(
                'w-4 h-4 ml-2 cursor-pointer duration-200 group-hover:opacity-100 opacity-0',
                isCopied ? 'text-green-500' : 'text-gray-300'
              )}
            />
          </button>
        )}
      </dd>
    </div>
  );
};

export default DescriptionList;
