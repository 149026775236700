/* eslint-disable jsx-a11y/media-has-caption */

import { FC } from 'react';
import { ParagraphElement } from '@shared/dream-components';
import { NodeViewContent, NodeViewProps, useReactNodeView } from '@tiptap/react';

import MergeTag from '../../components/MergeTag';
import { getDataAttributes } from '../../utils';

export const ParagraphView: FC<NodeViewProps> = ({ HTMLAttributes, node, editor, getPos }) => {
  const { onDragStart } = useReactNodeView();
  const { mergeTag } = node.attrs;

  const onClose = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'mergeTag', null);
      return true;
    });
  };

  return (
    <ParagraphElement
      element={{
        type: 'paragraph',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
      }}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
      onDragStart={onDragStart}
    >
      {mergeTag && <MergeTag onClose={onClose} />}
      <NodeViewContent as="span" />
    </ParagraphElement>
  );
};
