import { useEffect, useMemo, useState } from 'react';
import { Browser, Layout as LayoutIcon, SquaresFour, SquareSplitVertical } from '@phosphor-icons/react';

import { WebsiteProvider } from '@/context/website-context';
import { useSite } from '@/hooks/useSite';
import useInfiniteScrollSiteTemplates from '@/hooks/useSiteTemplates/useInfiniteScrollSiteTemplates';
import { cn } from '@/utils/cn';

import { Layout } from '../_components/Layout';
import { Main } from '../_components/Main';
import TemplateCard from '../_components/Templates/TemplateCard';
import { Button } from '../_components/UI/Button';
import { LoadingSpinner } from '../_components/UI/LoadingSpinner';
import { Text } from '../_components/UI/Text';

const TemplatesPage = () => {
  const { data: site } = useSite();
  const [type, setType] = useState<'block' | 'section' | 'page' | 'all'>('all');
  const categoryOptions = useMemo(() => (type !== 'all' ? site?.template_categories?.[type] || [] : []), [type, site]);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    type === 'all' ? undefined : categoryOptions?.[0]?.value
  );
  const { data, isFetching } = useInfiniteScrollSiteTemplates({});
  const siteTemplates =
    type === 'all'
      ? data?.pages.flatMap((page) => page.site_templates)
      : data?.pages
          .flatMap((page) => page.site_templates)
          ?.filter((template) => template.category === selectedCategory) || [];

  const iconMap = {
    page: Browser,
    section: SquareSplitVertical,
    block: SquaresFour,
    all: LayoutIcon,
  };

  useEffect(() => {
    setSelectedCategory(categoryOptions?.[0]?.value);
  }, [type, categoryOptions]);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      sidenavChildren={
        <div className="flex flex-col gap-2">
          <div className="my-5 h-[40px] flex flex-col justify-center">
            <Text size="xl" weight="semibold" variant="primary" as="h4">
              {type.charAt(0).toUpperCase() + type.slice(1)} Templates
            </Text>
          </div>
          <ul>
            {categoryOptions.map((category) => {
              const isSelected = category.value === selectedCategory;

              return (
                <li key={category.value}>
                  <Button
                    variant="ghost"
                    className={cn('w-full justify-start', isSelected ? 'bg-wb-secondary' : '')}
                    onClick={() => setSelectedCategory(category.value)}
                  >
                    <Text weight="medium" variant="primary" size="2xs" as="span">
                      {category.label}
                    </Text>
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      }
    >
      <Main>
        <div className="flex items-center gap-4">
          {['all', 'page', 'section', 'block'].map((t) => (
            <Button
              variant={type === t ? 'secondary' : 'ghost'}
              LeftIcon={iconMap[t as 'page' | 'section' | 'block' | 'all']}
              onClick={() => setType(t as 'page' | 'section' | 'block' | 'all')}
            >
              <Text size="sm" weight="medium" variant={type === t ? 'primary' : 'secondary'}>
                {t.charAt(0).toUpperCase() + t.slice(1)}
              </Text>
            </Button>
          ))}
          {isFetching && <LoadingSpinner />}
        </div>
        <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
          {siteTemplates?.map((template) => (
            <TemplateCard key={template.id} template={template} />
          ))}
        </div>
      </Main>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <TemplatesPage />
  </WebsiteProvider>
);
