import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { SubscriberBreakView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    subscriberBreak: {
      setSubscriberBreak: () => ReturnType;
    };
  }
}

export const SubscriberBreak = Node.create({
  name: 'subscriberBreak',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setSubscriberBreak:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
            })
            .run(),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(SubscriberBreakView);
  },
});

export default SubscriberBreak;
