import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';

import { Button } from '../Button';
import { Icon } from '../Icon';

import { Styled } from './styled';
import { UpgradeIntentPlaceholderProps } from './types';

export const UpgradeIntentPlaceholder = ({ description, url, iconName }: UpgradeIntentPlaceholderProps) => {
  return (
    <NodeViewWrapper data-drag-handle>
      <Styled.Container contentEditable={false}>
        <Icon name={iconName} />
        <Styled.Text>{description}</Styled.Text>
        <Button
          $size="small"
          $leftSlot={<Icon name="Upgrade" />}
          onClick={() => window.open('/settings/billing', '_blank')}
        >
          Upgrade
        </Button>
        <Styled.Link href={url} rel="noopener noreferrer" target="_blank">
          Learn more
        </Styled.Link>
      </Styled.Container>
    </NodeViewWrapper>
  );
};

export default UpgradeIntentPlaceholder;
