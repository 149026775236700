import { useCallback, useEffect, useMemo, useState } from 'react';

import { Asset } from '@/interfaces/asset';

import { useDebouncedValue } from '../../../../hooks/useDebouncedValue';
import api from '../../../../services/swarm';

export type UseAssetsHookOptions = {
  query: string;
  publicationId?: string;
};

export default function useAssets({ query, publicationId }: UseAssetsHookOptions) {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);

  const debouncedSearchValue = useDebouncedValue<string>(query, 750);

  const prependAsset = useCallback((asset: Asset) => {
    setAssets((oldAssets) => [asset, ...oldAssets]);
  }, []);

  const removeAsset = useCallback((assetId: string) => {
    setAssets((oldAssets) => oldAssets.filter((asset) => asset.id !== assetId));
  }, []);

  const prependAssets = useCallback((newAssets: Asset[]) => {
    setAssets((oldAssets) => [...newAssets, ...oldAssets]);
  }, []);

  const updateAsset = useCallback(
    (asset: Asset) => {
      const existingAsset = assets.find((currentAsset) => currentAsset.id === asset.id);

      if (!existingAsset) {
        prependAsset(asset);
        return;
      }

      setAssets(() => {
        return assets.map((currentAsset) => {
          if (currentAsset.id === asset.id) {
            return asset;
          }

          return currentAsset;
        });
      });
    },
    [assets, prependAsset]
  );

  const searchAssets = useCallback(
    async (searchQuery: string, currentPage: number) => {
      setLoading(true);
      setError(null);

      try {
        const params = {
          q: searchQuery,
          page: currentPage,
          limit: 32,
        };

        const newAssets = await api.get<{ assets: Asset[]; pages: number }>(`/publications/${publicationId}/assets`, {
          params,
        });

        setLoading(false);

        return newAssets;
      } catch (e: any) {
        setError(e.message);
        setLoading(false);
        return null;
      }
    },
    [publicationId]
  );

  const hasNextPage = useMemo(() => page < maxPages, [page, maxPages]);

  const fetchNextPage = useCallback(() => {
    if (loading || error || !hasNextPage) return;

    searchAssets(debouncedSearchValue, page + 1).then((newAssets) => {
      if (!newAssets?.data) return;

      setAssets((oldAssets) => [...oldAssets, ...newAssets.data.assets]);
      setPage((oldPage) => oldPage + 1);
    });
  }, [debouncedSearchValue, searchAssets, loading, page, error, hasNextPage]);

  useEffect(() => {
    searchAssets(debouncedSearchValue, 1).then((newAssets) => {
      if (!newAssets?.data) return;

      setMaxPages(newAssets.data.pages);
      setAssets(newAssets.data.assets);
      setPage(1);
    });
  }, [debouncedSearchValue, searchAssets]);

  useEffect(() => {
    setError(null);
    setLoading(true);
  }, [query]);

  return {
    assets,
    page,
    loading,
    error,
    fetchNextPage,
    updateAsset,
    searchAssets,
    prependAsset,
    prependAssets,
    removeAsset,
    hasNextPage,
  };
}
