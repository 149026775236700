import { MouseEventHandler } from 'react';
import { CaretRight } from '@phosphor-icons/react';

import { Text } from '../../../../UI/Text';

interface Props {
  label: string;
  icon: JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Button = ({ label, icon, onClick }: Props) => (
  <button type="button" className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer" onClick={onClick}>
    <div className="w-full justify-between flex items-center gap-2 p-2 ">
      <div className="flex items-center gap-1">
        {icon}
        <Text
          size="2xs"
          weight="medium"
          className="whitespace-nowrap overflow-hidden overflow-ellipsis pr-2 max-w-[80px]"
        >
          {label}
        </Text>
      </div>

      <CaretRight className="text-wb-secondary" weight="bold" />
    </div>
  </button>
);

export default Button;
