import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import BackButton from '@/routes/website_builder/_components/Layout/BackButton';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import { useCustomPageContext } from '../../_components/Context/CustomPageContext';
import PageSelect from '../../_components/Layout/PageSelect';

interface Props {
  showBackButton?: boolean;
  showSaveActions?: boolean;
}

const TopNavActions = ({ showBackButton = true, showSaveActions = true }: Props) => {
  const queryClient = useQueryClient();
  const { changesMade, setChangesMade, content, pagePayload, thumbnail, currentPublicationId } = useCustomPageContext();
  const navigate = useNavigate();
  const { customPageId } = useParams();
  const isEditing = !!customPageId;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [initializeSave, setInitializeSave] = useState<boolean>(false);

  const onGoBack = () => {
    queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
    navigate(-1);
  };

  const handleClickCancel = () => {
    if (changesMade) {
      setIsLeaving(true);
    } else {
      onGoBack();
    }
  };

  const buildFinalPayload = () => {
    const formData = new FormData();

    if (typeof thumbnail !== 'string') {
      formData.append('custom_page[thumbnail]', thumbnail);
    }

    formData.append('publication_id', currentPublicationId || '');
    formData.append('custom_page[meta_title]', pagePayload?.meta_title || '');
    formData.append('custom_page[meta_description]', pagePayload?.meta_description || '');
    formData.append('custom_page[slug]', pagePayload.slug || '');
    formData.append('custom_page[noindexing_enabled]', pagePayload?.noindexing_enabled?.toString() || 'false');
    formData.append('custom_page[status]', pagePayload.status || 'draft');
    formData.append('custom_page[content]', JSON.stringify(content));
    formData.append('custom_page[premium]', pagePayload?.premium?.toString() || 'false');

    formData.append('nav_link[nav_group_id]', pagePayload.nav_group_id || '');
    formData.append('nav_link[cta_text]', pagePayload.cta_text || '');
    formData.append('nav_link[href]', pagePayload.slug || ''); // we use the slug as the href for internal, page links

    return formData;
  };

  const handleCreateCustomPage = (formData: FormData) => {
    setIsLoading(true);
    api
      .post(`/custom_pages`, formData)
      .then(() => {
        toast.success('Page created successfully!');

        onGoBack();
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message || 'Could not create page.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditCustomPage = (formData: FormData) => {
    setIsLoading(true);
    api
      .patch(`/custom_pages/${customPageId}`, formData)
      .then(() => {
        toast.success('Page saved!');
        queryClient.invalidateQueries(['custom_page', currentPublicationId, customPageId]);
        onGoBack();
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message || 'Could not save page.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClickSave = async () => {
    if (!content || Object.keys(content).length === 0) {
      toast.error('You have not added anything to the page!');
      return;
    }

    if (!pagePayload.slug) {
      toast.error('Please enter a slug.');
      return;
    }

    if (!pagePayload.cta_text) {
      toast.error('Please enter a cta text');
      return;
    }

    if (pagePayload?.meta_title && pagePayload?.meta_title?.length > 70) {
      toast.error('Please enter a meta title with less than 70 characters.');
      return;
    }

    if (pagePayload?.meta_description && pagePayload?.meta_description?.length > 160) {
      toast.error('Please enter a meta title with less than 160 characters.');
      return;
    }

    if (isEditing) {
      await handleEditCustomPage(buildFinalPayload());
    } else {
      await handleCreateCustomPage(buildFinalPayload());
    }

    setIsSaving(false);
    setInitializeSave(false);
    setChangesMade(false);
  };

  return (
    <>
      <ActionModal
        isOpen={isLeaving}
        onClose={() => setIsLeaving(false)}
        onProceed={onGoBack}
        resourceId="leave page"
        isWorking={isSaving}
        headerText="Have you saved?"
        actionText="Back to Settings"
      >
        Please make sure to save any progress before leaving.
      </ActionModal>
      <ActionModal
        isOpen={initializeSave}
        onClose={() => setInitializeSave(false)}
        onProceed={handleClickSave}
        resourceId="custom_page"
        isWorking={isSaving}
        headerText={isEditing ? 'Save Changes' : 'Create New Page'}
        actionText={isEditing ? 'Save' : 'Create'}
      >
        {isEditing
          ? 'Are you sure you want to save these changes? If this page is currently live, users will be able to immediately see any updates.'
          : 'You are about to create this page, it will not be live unless you have added it to one of your navigation dropdowns or as a Standalone Page. Confirm to create.'}
      </ActionModal>

      <>
        <div className="w-1/3 flex justify-start">{showBackButton && <BackButton onClick={onGoBack} />}</div>
        <div className="w-1/3 flex justify-center">
          <PageSelect currentPath="/custom_pages" />
        </div>
        <div className="w-1/3 flex justify-end items-center gap-x-2">
          {showSaveActions && (
            <>
              <Button variant="primary-inverse" type="button" onClick={handleClickCancel}>
                Cancel
              </Button>
              <Button loading={isLoading} onClick={() => setInitializeSave(true)} disabled={!changesMade}>
                {isEditing ? 'Save Changes' : 'Create Page'}
              </Button>
            </>
          )}
        </div>
      </>
    </>
  );
};

export default TopNavActions;
