import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { NodeAttrsToThemePropertiesMap } from '@/interfaces/dream_builder/site';
import { Pagination } from '@/interfaces/general';
import { SiteTheme } from '@/interfaces/web_theme';

import api from '../../services/swarm';

interface ApiResponse {
  site_themes: SiteTheme[];
  theme_rules: NodeAttrsToThemePropertiesMap;
  pagination: Pagination;
}

export default function useSiteThemes() {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'site_themes'],
    ({ pageParam = 1 }) =>
      api
        .get(`/site_themes`, {
          params: {
            publication_id: currentPublicationId,
            page: pageParam,
          },
        })
        .then((res) => res.data),
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      refetchOnWindowFocus: false,
    }
  );
}
