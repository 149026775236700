import { useQuery } from 'react-query';

import { AdNetworkOpportunity } from '../../../interfaces/ad_network/internal/opportunity';
import api from '../../../services/swarm';

export default function useOpportunity({ id }: { id: string }) {
  return useQuery<AdNetworkOpportunity>(
    ['ad_network', 'internal', 'opportunities', id],
    () => api.get(`/ad_network/internal/opportunities/${id}`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
