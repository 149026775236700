import React, { memo, useMemo, useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import { getBackgroundColors } from '@/utils/documentColors';

import { useThemeData } from '../../lib/hooks/useThemeData';
import { EditorColor } from '../../lib/types';
import styles from '../../styled';
import CustomColorPanel from '../panels/CustomColor';
import { Button } from '../ui/Button';
import { Icon } from '../ui/Icon';
import { Toolbar } from '../ui/Toolbar';
import { Tooltip } from '../ui/Tooltip';

import { ToolbarProps } from './types';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const HighlightingToolbar = memo(
  ({
    currentColor,
    isActive,
    isColorActive,
    isDisabled,
    hasSelection,
    onSelect,
    onClear,
    documentColors = [],
  }: ToolbarProps & {
    currentColor: string | null;
    isColorActive: (color: string) => boolean;
    onSelect: (color: string) => void;
    onClear: () => void;
    documentColors: Array<EditorColor>;
  }): JSX.Element => {
    const [isMainPanelOpen, setIsMainPanelOpen] = useState(false);
    const [isColorPanelOpen, setIsColorPanelOpen] = useState(false);
    const themeColors = useThemeData('colors');

    const highlightColors = useMemo(() => {
      return getBackgroundColors(
        documentColors,
        themeColors.map((color) => color.value)
      );
    }, [documentColors, themeColors]);

    const isCustomColorActive = useMemo(() => {
      if (!currentColor) {
        return false;
      }

      return !themeColors.find((themeColor) => {
        return themeColor.value === currentColor && hasSelection;
      });
    }, [themeColors, hasSelection, currentColor]);

    return (
      <Tippy
        onHide={() => {
          setIsColorPanelOpen(false);
        }}
        render={(attrs) =>
          isColorPanelOpen ? (
            <CustomColorPanel
              selectedColor={currentColor || ''}
              onBack={() => {
                setIsColorPanelOpen(false);
              }}
              onSelect={onSelect}
            />
          ) : (
            <Toolbar
              tabIndex={-1}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...attrs}
            >
              <div>
                <div className="flex items-center gap-[1px]">
                  {themeColors.map((themeColor, index) => {
                    return (
                      <Button
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        $variant="quaternary"
                        $isColorTileButton
                        $active={isColorActive(themeColor.value)}
                        onClick={() => onSelect(themeColor.value)}
                        style={{
                          '--background': themeColor.value,
                        }}
                      />
                    );
                  })}
                </div>
                <div className="flex items-center gap-[1px] pt-0.5 border-t border-white border-opacity-10 mt-0.5">
                  <Button
                    $variant="quaternary"
                    $isColorTileButton
                    $active={isCustomColorActive}
                    onClick={() => {
                      setIsColorPanelOpen(true);
                    }}
                    style={styles.customColorCSSVar}
                  />
                  <Button
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...buttonProps}
                    $leftSlot={<Icon name="Cancel" />}
                    disabled={!isActive}
                    onClick={onClear}
                  />
                  {highlightColors.map((docColor, index) => {
                    return (
                      <Button
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        $variant="quaternary"
                        $isColorTileButton
                        $active={isColorActive(docColor)}
                        onClick={() => onSelect(docColor)}
                        style={{
                          '--background': docColor,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </Toolbar>
          )
        }
        offset={[0, 8]}
        trigger="click"
        interactive
        disabled={isDisabled}
        onShow={() => {
          setIsMainPanelOpen(true);
        }}
        onHidden={() => {
          setIsMainPanelOpen(false);
        }}
      >
        <div>
          <Tooltip enabled={!isDisabled} title="Highlight" shortcut={['Mod', 'Shift', 'H']}>
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonProps}
              $showTooltip
              $active={isActive || isMainPanelOpen}
              $muted={isMainPanelOpen && !isActive}
              $leftSlot={<Icon name="Highlight" />}
              disabled={isDisabled}
            />
          </Tooltip>
        </div>
      </Tippy>
    );
  }
);

HighlightingToolbar.displayName = 'HighlightingToolbar';

export default HighlightingToolbar;
