import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { rootStyles } from '@shared/dream-components';
import { Content } from '@tiptap/core';
import { EditorContent, useEditor } from '@tiptap/react';

import { UpgradeIntent as UpgradeIntentModal } from '@/components/UpgradeIntent';
import { useDreamEditorContext } from '@/context/dream-editor-context';
import { Settings } from '@/interfaces/setting';
import { IntentAction } from '@/interfaces/upgrades';
import { cn } from '@/utils/cn';
import { PLAN, PREMIUM_PLANS } from '@/utils/plans';

import { AttributesPanel } from './AttributesPanel/AttributesPanel';
import { EditorDocumentType } from './extensions/Document';
import { ExtensionKit } from './extensions/extension-kit';
import { DragHandle } from './extensions/Hover/views/DragHandle';
import { InsertPanel } from './InsertPanel/InsertPanel';
import { ATTRIBUTES_PANEL_ID, LAYERS_PANEL_ID, SIDE_INSERT_PANEL_ID, VERSION_HISTORY_PANEL_ID } from './constants';
import { LayersPanel } from './LayersPanel';
import { Styled } from './styled';
import { VersionHistoryPanel } from './VersionHistoryPanel';

interface DreamEditorProps {
  allowAds?: boolean;
  allowPolls?: boolean;
  className?: string;
  publicationId: string;
  settings: Settings;
  shouldAutoFocus?: boolean;
  isFullPageHeight?: boolean;
  type?: EditorDocumentType;
  nonDeletableNodes?: string[];
  isFooter?: boolean;
  insertPanelComponent?: JSX.Element;
}

interface UpgradeIntentModalState {
  isOpen: boolean;
  action?: IntentAction;
  plan?: keyof typeof PREMIUM_PLANS;
}

export const DreamEditor = ({
  publicationId,
  settings,
  className,
  allowPolls = false,
  allowAds = false,
  shouldAutoFocus = false,
  isFullPageHeight = true,
  type = 'page',
  nonDeletableNodes,
  isFooter,
  insertPanelComponent,
}: DreamEditorProps): JSX.Element | null => {
  const menuContainerRef = useRef(null);
  const { setEditor, save, initialContent, setChangesMade } = useDreamEditorContext();
  const hasContent = initialContent && Object.keys(initialContent).length > 0;

  const saveHandler = useRef(save);
  useEffect(() => {
    saveHandler.current = save;
  }, [save]);

  const [upgradeIntentModal, setUpgradeIntentModal] = useState<UpgradeIntentModalState>({
    isOpen: false,
    action: undefined,
  });

  const editor = useEditor(
    {
      extensions: [
        ...(ExtensionKit({
          publicationId,
          settings,
          allowPolls,
          allowAds,
          onToggleUpgradeIntentModal: (action: IntentAction, plan: keyof typeof PREMIUM_PLANS) => {
            setUpgradeIntentModal((prevState) => ({ isOpen: !prevState.isOpen, action, plan }));
          },
          additionalShortcuts: {
            'Mod-s': () => {
              if (saveHandler.current && typeof saveHandler.current === 'function') {
                saveHandler.current();
                return true;
              }
              return false;
            },
          },
          type,
          nonDeletableNodes,
        }) as any),
      ],
      editorProps: {
        attributes: {
          class: cn(
            rootStyles.rootElement,
            'relative flex flex-col items-center justify-start whitespace-prewrap overflow-break-wrap outline-none',
            isFullPageHeight ? 'min-h-dvh' : 'min-h-0',
            className || ''
          ),
        },
      },
      onCreate: ({ editor: tiptapEditor }) => {
        setEditor(tiptapEditor as any);
      },
      onUpdate: () => {
        setChangesMade(true);
      },
      autofocus: shouldAutoFocus,
      content: hasContent
        ? (initialContent as Content)
        : ({
            type: 'doc',
            content: [
              {
                type: 'section',
                attrs: {},
              },
            ],
          } as Content),
    },
    [hasContent, initialContent]
  );

  // useEffect(() => {
  //   return debugProseMirrorCopy(editor);
  // }, [editor]);

  const sideInsertPanelEl = useRef<HTMLDivElement | null>(null);
  const attributesPanelEl = useRef<HTMLDivElement | null>(null);
  const layersPanelEl = useRef<HTMLDivElement | null>(null);
  const versionHistoryPanelEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    sideInsertPanelEl.current = document.getElementById(SIDE_INSERT_PANEL_ID) as HTMLDivElement;
    attributesPanelEl.current = document.getElementById(ATTRIBUTES_PANEL_ID) as HTMLDivElement;
    layersPanelEl.current = document.getElementById(LAYERS_PANEL_ID) as HTMLDivElement;
    versionHistoryPanelEl.current = document.getElementById(VERSION_HISTORY_PANEL_ID) as HTMLDivElement;
  }, []);

  if (!editor) {
    return null;
  }

  return (
    <>
      <Styled.Container>
        {/* TODO(DOC): Add images & videos from Root/Doc element here */}
        <EditorContent editor={editor} suppressContentEditableWarning />
      </Styled.Container>

      <div ref={menuContainerRef}>
        {/* If we have a side insert panel, render it */}
        {sideInsertPanelEl.current &&
          createPortal(insertPanelComponent || <InsertPanel editor={editor} />, sideInsertPanelEl.current)}
        {attributesPanelEl.current &&
          createPortal(<AttributesPanel editor={editor} isFooter={isFooter} />, attributesPanelEl.current)}
        {layersPanelEl.current && createPortal(<LayersPanel editor={editor} />, layersPanelEl.current)}
        {versionHistoryPanelEl.current &&
          createPortal(<VersionHistoryPanel editor={editor} />, versionHistoryPanelEl.current)}
        <DragHandle editor={editor} />
      </div>

      <UpgradeIntentModal
        isOpen={upgradeIntentModal.isOpen}
        intentAction={upgradeIntentModal.action}
        preselectedPlan={upgradeIntentModal.plan || PLAN.SCALE}
        onClose={() => {
          setUpgradeIntentModal({ ...upgradeIntentModal, isOpen: false });
        }}
      />
    </>
  );
};

export default DreamEditor;
