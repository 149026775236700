import { FC } from 'react';
import { CameraIcon } from '@heroicons/react/24/outline';

import { Image } from '@/interfaces/image';
import { Reward } from '@/interfaces/reward';

interface Props {
  image: Reward['image'];
}

const RewardImage: FC<Props> = ({ image }: Props) => {
  const thumbnailUrl = image ? (image as Image).thumb.url : undefined;
  if (thumbnailUrl) {
    return <img className="h-10 w-10 rounded-lg" src={thumbnailUrl} alt="" />;
  }

  return (
    <div className="h-10 w-10 rounded-none border flex items-center justify-center">
      <CameraIcon className="h-4 w-4" />
    </div>
  );
};

export default RewardImage;
