import { useState } from 'react';
import toast from 'react-hot-toast';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

const downloadFile = (fileName: string, urlData: any) => {
  const aLink = document.createElement('a');
  aLink.download = fileName;
  aLink.href = `data:text/csv;charset=UTF-8, ${encodeURIComponent(urlData)}`;

  const event = new MouseEvent('click');
  aLink.dispatchEvent(event);
};

const useExportStripeMigrationPreview = (stripeMigrationId?: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();

  const downloadExport = () => {
    setIsLoading(true);

    const params = {
      publication_id: currentPublicationId,
    };

    api
      .get(`/stripe_migrations/${stripeMigrationId}/export_preview`, { params })
      .then((res: any) => {
        const resData = res?.data;
        downloadFile(`stripe-migration-${stripeMigrationId}.csv`, resData);
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    downloadExport,
    isDownloadingExport: isLoading,
  };
};

export default useExportStripeMigrationPreview;
