import { FC, useCallback } from 'react';
import { JSONContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { OnMediaPayload } from '@/components/MediaLibrary/MediaLibrary.types';

import { ImageUploader } from './ImageUploader';

const getImageDimensions = (url: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    let imgSrc = url;
    if (window?.env?.REACT_APP_HEROKU_ENV === 'development') {
      imgSrc = `${window?.env?.REACT_APP_API_BASE_URL}${url}`;
    }
    img.src = imgSrc;
  });
};

export const ImageUpload: FC<NodeViewProps> = ({ getPos, editor, node }) => {
  const onUpload = useCallback(
    async (payload: OnMediaPayload) => {
      const { media: asset } = payload;

      const from = getPos();
      const to = from + node.nodeSize;

      let width: number | undefined;
      let height: number | undefined;
      if (asset.url) {
        const dimensions = await getImageDimensions(asset.url);
        width = dimensions.width;
        height = dimensions.height;
      }

      const jsonContent: JSONContent = {
        type: 'image',
        attrs: {
          id: asset.id,
          src: asset.url,
          width,
          height,
          title: asset.title,
          captionUrl: asset.source_link || '',
          alt: asset.alt,
        },
      };

      editor.chain().insertContentAt({ from, to }, jsonContent).focus().run();
    },
    [editor, getPos, node]
  );

  return (
    <NodeViewWrapper>
      <div
        data-drag-handle
        {...{ inert: editor.isEditable ? undefined : '' }}
        className="m-0 p-0"
        contentEditable={false}
      >
        <ImageUploader onUpload={onUpload} />
      </div>
    </NodeViewWrapper>
  );
};

export default ImageUpload;
