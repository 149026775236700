import { RefObject, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

type Props = {
  currentViewIndex: number;
  index?: number;
  setSlideRef: (ref: RefObject<HTMLDivElement>) => void;
};

const End = ({ currentViewIndex, index, setSlideRef }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    const isCurrentView = currentViewIndex === index;

    if (isCurrentView) {
      setSlideRef(contentRef);
      setTimeout(() => {
        setPlayAnimation(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  return (
    <div ref={contentRef} className="w-full h-full relative overflow-hidden rewind-bg-cyber-ink">
      <div className="absolute top-0 left-0 w-[90%] h-[90%] m-[5%_0_0_5%] p-[35px_15px] flex border-2 border-l-0 border-r-0 border-t-[#ffc8eb] border-b-[#ffc8eb]">
        <img
          src="/rewind-2024/images/logo-trail.webp"
          alt="beehiiv logo"
          className="bg-blend-multiply rounded-[32px]"
        />
        <div className="flex flex-col justify-center pl-8">
          <div className="flex w-full mt-6 mb-12">
            <img className="h-6 w-auto" src="/rewind-2024/images/beehiiv-logo-wordmark-white.png" alt="beehiiv logo" />
          </div>
          <h1
            className={cx(
              'font-clash-grotesk-v text-white uppercase font-bold w-full text-[5.745rem] leading-[5.745rem] transition-all duration-300',
              !playAnimation && 'translate-x-20 opacity-0',
              playAnimation && 'translate-x-0 opacity-1'
            )}
          >
            It&apos;s been a pleasure watching you glow up in 2024
          </h1>
          <div className="rewind-bg-texture top-0 left-0 absolute w-full h-full" />
          <p
            className={cx(
              'font-satoshi-v text-4xl font-bold text-white uppercase mt-16 tracking-widest transition-all duration-300, delay-150',
              !playAnimation && 'translate-x-20 opacity-0',
              playAnimation && 'translate-x-0 opacity-1'
            )}
          >
            Here&apos;s to 2025 🚀
          </p>
        </div>
      </div>
    </div>
  );
};

export default End;
