import { FC } from 'react';

import Card from '../../../components/Card';
// Components
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Pagination } from '../../../interfaces/general';
// Interfaces
import { SubscriptionMilestone } from '../../../interfaces/milestone';

import Table from './FulfillmentTable';

interface Props {
  className?: string;
  isLoading: boolean;
  subscriptionMilestones?: SubscriptionMilestone[];
  pagination?: Pagination;
  onPageSelected: (pageNum: number) => void;
  onManageSelected: (subscriptionMilestone: SubscriptionMilestone) => void;
}

const Historical: FC<Props> = (props: Props) => {
  const {
    className,
    isLoading,
    subscriptionMilestones = [],
    pagination = {
      per_page: 10,
      page: 1,
      total: 0,
      total_pages: 0,
    },
    onPageSelected,
    onManageSelected,
  } = props;

  return (
    <Card className={className}>
      <div className="space-y-6">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 inline-flex">
              Milestone Achievements {isLoading && <LoadingSpinner className="ml-2" />}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              All of your acknowledged milestone achievements for your subscribers
            </p>
          </div>
        </div>
        <div>
          <Table
            subscriptionMilestones={subscriptionMilestones}
            pagination={pagination}
            onPageSelected={onPageSelected}
            onManageSelected={onManageSelected}
          />
        </div>
      </div>
    </Card>
  );
};

export default Historical;
