import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { UserPlusIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

import PageHeading from '../../components/Layout/PageLayout/PageHeading';
import LoadingBox from '../../components/LoadingBox';
import OptionsDropdown from '../../components/OptionsDropdown';
import SlideOver from '../../components/SlideOver';
import { useCurrentPublicationState } from '../../context/current-publication-context';
import useGuestAuthors from '../../hooks/useGuestAuthors';
import { Author } from '../../interfaces/author';
import api from '../../services/swarm';

import Form from './Form';

const GuestAuthorsView: React.FunctionComponent = () => {
  const guestAuthorsQuery = useGuestAuthors();
  const { data, isLoading, isError } = guestAuthorsQuery;
  const [publicationId] = useCurrentPublicationState();
  const [selectedGuestAuthor, setSelectedGuestAuthor] = useState<Author | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const guestAuthorOptions = useCallback(
    (guest_author: Author) => [
      [
        {
          label: 'Edit',
          onClick: () => {
            setSelectedGuestAuthor(guest_author);
            setIsOpen(true);
          },
        },
      ],
      [
        {
          label: 'Delete',
          color: 'red',
          onClick: () => {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(`Are you sure? This cannot be undone.`);

            if (confirm) {
              api
                .delete(`/guest_authors/${guest_author.id}`, {
                  params: {
                    publication_id: publicationId,
                  },
                })
                .then(() => {
                  toast.success('Guest author deleted');
                  guestAuthorsQuery.refetch();
                })
                .catch((err) => {
                  toast.error(err?.response?.data?.error || 'Something went wrong');
                });
            }
          },
        },
      ],
    ],
    [guestAuthorsQuery, publicationId]
  );

  const isNoResults = !isLoading && data?.guest_authors.length === 0;

  const NoResults = () => (
    <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
      <div className="flex flex-col items-center gap-4">
        <p className="text-gray-500 text-center">Once you create your first guest author, they will show up here.</p>
        <Button variant="primary" onClick={() => setIsOpen(true)} Icon={UserPlusIcon}>
          New guest author
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <PageHeading title="Guest Authors" description="Use guests to credit writers who are not part of your team.">
        {!isLoading && !isNoResults && (
          <div className="col-span-2 flex items-end justify-end">
            <Button onClick={() => setIsOpen(true)} Icon={UserPlusIcon}>
              New guest author
            </Button>
          </div>
        )}
      </PageHeading>

      <LoadingBox isLoading={isLoading} isError={isError}>
        {data && (
          <>
            {isNoResults ? (
              <NoResults />
            ) : (
              <ul className="divide-y border rounded bg-white">
                {data.guest_authors.map((guest_author) => (
                  <li className="p-4 " key={guest_author.id}>
                    <div className="flex justify-between items-center">
                      <div>
                        <img
                          className="h-8 w-8 rounded-full object-cover"
                          src={guest_author?.profile_picture.thumb.url}
                          alt=""
                        />
                      </div>
                      <div className="w-3/4">
                        <p className="text-gray-800 font-semibold truncate">{guest_author.name}</p>
                      </div>
                      <div>
                        <OptionsDropdown options={guestAuthorOptions(guest_author)} />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </LoadingBox>
      <SlideOver
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        headerText={selectedGuestAuthor ? 'Edit guest' : 'Add new guest'}
      >
        <Form
          guestAuthor={selectedGuestAuthor}
          onSubmit={() => {
            setIsOpen(false);
            setSelectedGuestAuthor(null);
            guestAuthorsQuery.refetch();
          }}
        />
      </SlideOver>
    </>
  );
};

export default GuestAuthorsView;
