import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { CustomField } from '../interfaces/custom_field';
import api from '../services/swarm';

export default function useCustomField(id: string) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchCustomField = () =>
    api
      .get(`/custom_fields/${id}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<CustomField>(['custom_fields', id], fetchCustomField, {
    keepPreviousData: true,
  });
}
