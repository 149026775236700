import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostAgreementStatus } from '@/interfaces/boosts/grow/boost_agreement';
import api from '@/services/swarm';

interface Props {
  boostOfferId?: string;
  onSuccess: () => void;
  shouldInvalidate?: boolean;
}

interface Variables {
  boostAgreementIds: string[];
  transitionTo?: BoostAgreementStatus;
  affiliateEnabled?: boolean;
  rejectedReason?: string;
}

const useUpdateBoostAgreements = ({ boostOfferId, onSuccess, shouldInvalidate = true }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const updateBoostAgreements = ({
    boostAgreementIds,
    transitionTo,
    affiliateEnabled,
    rejectedReason,
  }: Variables): Promise<void> =>
    api.patch(`/boosts/grow/boost_agreements/bulk_updates`, {
      publication_id: currentPublicationId,
      boost_offer_id: boostOfferId,
      boost_agreement_ids: boostAgreementIds,
      boost_agreement_params: {
        transition_to: transitionTo,
        affiliate_enabled: affiliateEnabled,
        rejected_reason: rejectedReason,
      },
    });

  return useMutation(updateBoostAgreements, {
    onSuccess: () => {
      onSuccess();

      if (!shouldInvalidate) {
        return;
      }

      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boosts_agreements', boostOfferId]);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
    },
  });
};

export default useUpdateBoostAgreements;
