import { TierChangesGraph, TierChangeStatsByResource } from '@/components/_domain/PremiumData';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { TierChangeEventResourceType } from '@/interfaces/tier_change_event';

const PremiumData = ({ emailMessageId }: { emailMessageId: string }) => {
  const currentPublicationId = useCurrentPublicationId();
  return (
    <div className="p-6">
      <TierChangeStatsByResource
        publicationId={currentPublicationId}
        resourceType={TierChangeEventResourceType.EMAIL_MESSAGE}
        resourceId={emailMessageId}
      />
      <TierChangesGraph
        publicationId={currentPublicationId}
        resourceType={TierChangeEventResourceType.EMAIL_MESSAGE}
        resourceId={emailMessageId}
      />
    </div>
  );
};

export default PremiumData;
