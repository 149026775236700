import { useMemo, useState } from 'react';
import cx from 'classnames';

import CountryMultiSelect from '@/components/Form/CountryMultiSelect';
import SelectedOptionBadges from '@/components/Form/SelectedOptionBadges';
import { LineDivider } from '@/components/LineDivider';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { BoostOfferSort } from '@/hooks/boosts/monetize/useBoostOffers';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import SearchInput from '@/ui/SearchInput';
import mappedCountries from '@/utils/mappedCountries';

import CurrencyRange from './CurrencyRange';
import FilterByTags from './FilterByTags';
import SortingOptions from './SortingOptions';

interface Props {
  minPayoutCents?: number;
  setMinPayoutCents: (cents?: number) => void;
  maxPayoutCents?: number;
  setMaxPayoutCents: (cents?: number) => void;
  minPerLeadCents?: number;
  setMinPerLeadCents: (cents?: number) => void;
  maxPerLeadCents?: number;
  setMaxPerLeadCents: (cents?: number) => void;
  tagIds: string[];
  setTagIds: (tags: string[]) => void;
  eligibleCountries: string[];
  setEligibleCountries: (tags: string[]) => void;
  search?: string | null;
  setSearch: (query: string) => void;
  isFetching: boolean;
  shouldResetSearch: boolean;
  onResetSearch: () => void;
  tags: Option[];
  sort: Option<BoostOfferSort>;
  setSort: (value: Option<BoostOfferSort>) => void;
  sortingOptions: Option<BoostOfferSort>[];
}

export default function Filters({
  minPayoutCents,
  setMinPayoutCents,
  maxPayoutCents,
  setMaxPayoutCents,
  minPerLeadCents,
  setMinPerLeadCents,
  maxPerLeadCents,
  setMaxPerLeadCents,
  tagIds,
  setTagIds,
  eligibleCountries,
  setEligibleCountries,
  search,
  setSearch,
  isFetching,
  shouldResetSearch,
  onResetSearch,
  tags,
  sort,
  setSort,
  sortingOptions,
}: Props) {
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const filterCount =
    tagIds.length +
    eligibleCountries.length +
    (minPayoutCents ? 1 : 0) +
    (maxPayoutCents ? 1 : 0) +
    (minPerLeadCents ? 1 : 0) +
    (maxPerLeadCents ? 1 : 0);

  const [rangeKey, setRangeKey] = useState<number>(0);
  const { settings } = useSettings();

  const handleClearAll = () => {
    setTagIds([]);
    setEligibleCountries([]);
    setEligibleCountries([]);
    setMinPayoutCents(undefined);
    setMaxPayoutCents(undefined);
    setMinPerLeadCents(undefined);
    setMaxPerLeadCents(undefined);
    setRangeKey(rangeKey + 1);
  };

  const selectedTagOptions = tags.filter((tag) => tagIds.includes(tag.value));
  const selectedEligibleCountryOptions: Option[] = useMemo(
    () =>
      eligibleCountries.map((countryCode: string) => ({
        label: mappedCountries[countryCode],
        value: countryCode,
      })),
    [eligibleCountries]
  );

  const handleDeselectTag = (value: string) => {
    const newTagIds = tagIds.filter((tagId) => tagId !== value);
    setTagIds(newTagIds);
  };

  const handleDeselectCountry = (value: string) => {
    const newEligibleCountries = eligibleCountries.filter((countryCode) => countryCode !== value);
    setTagIds(newEligibleCountries);
  };

  const handleToggleMoreFilters = () => setShowMoreFilters(!showMoreFilters);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-2 sm:justify-between">
          <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-2">
            <div className="w-full sm:w-fit">
              <SearchInput
                defaultValue={search || ''}
                shouldDebounce
                shouldReset={shouldResetSearch}
                onClearSearch={onResetSearch}
                onSearch={setSearch}
                placeholder="Search offers"
              />
            </div>
            <FilterByTags tags={tags} selectedTagIds={tagIds} setTagIds={setTagIds} />
            <CountryMultiSelect
              defaultSelection={eligibleCountries}
              onChange={setEligibleCountries}
              labelText=""
              showSelection={false}
            />
          </div>
          <div className="flex sm:items-center sm:gap-x-2">
            <div className="hidden sm:block">
              <LoadingSpinner className={cx(!isFetching && 'hidden')} />
            </div>
            <div className="flex gap-x-2">
              <Button onClick={handleClearAll} size="xs" variant="primary-inverse" disabled={filterCount === 0}>
                Clear filters
              </Button>
              <Button onClick={handleToggleMoreFilters} size="xs" variant="primary-inverse">
                {showMoreFilters ? 'Hide' : 'Show'} advance filters
              </Button>
            </div>
          </div>
        </div>
        {tagIds.length > 0 && <SelectedOptionBadges options={selectedTagOptions} onDeselect={handleDeselectTag} />}
        {eligibleCountries.length > 0 && (
          <SelectedOptionBadges options={selectedEligibleCountryOptions} onDeselect={handleDeselectCountry} />
        )}
        {showMoreFilters && (
          <div className={cx('bg-white p-4 rounded border')}>
            <fieldset className="flex flex-col gap-y-4 sm:flex-row sm:gap-x-4">
              <div className="flex flex-col gap-y-2">
                <Typography token="font-semibold/text/xs">Payout</Typography>
                <CurrencyRange
                  id="payout"
                  name="payout"
                  lowerBound={minPerLeadCents}
                  upperBound={maxPerLeadCents}
                  setLowerBound={setMinPerLeadCents}
                  setUpperBound={setMaxPerLeadCents}
                />
                <p className="text-xs text-gray-500">The payout per subscriber in USD (e.g. $2.00).</p>
              </div>

              {settings?.boosts_plus && (
                <LineDivider direction="vertical" heightClassName="h-auto" className="mx-2 hidden sm:block" />
              )}

              {settings?.boosts_plus && (
                <div className="flex flex-col gap-y-2">
                  <Typography token="font-semibold/text/xs">Payout per Send</Typography>
                  <CurrencyRange
                    id="payout_per_send"
                    name="payout_per_send"
                    lowerBound={minPayoutCents}
                    upperBound={maxPayoutCents}
                    setLowerBound={setMinPayoutCents}
                    setUpperBound={setMaxPayoutCents}
                  />
                  <p className="text-xs text-gray-500">The payout per send in USD (e.g. $200.00).</p>
                </div>
              )}
            </fieldset>
          </div>
        )}
      </div>
      <div className="flex justify-end mb-4">
        <SortingOptions selected={sort} setSelected={(option) => setSort(option)} options={sortingOptions} />
      </div>
    </div>
  );
}
