import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

interface ApiResponse {
  connectedToStripeBoosts: boolean;
}

const useStripeCustomer = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchStripeCustomer = (): Promise<ApiResponse> =>
    api
      .get(`/boosts/grow/stripe_customer`, { params: { publication_id: currentPublicationId } })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'grow', 'stripe_customer'], fetchStripeCustomer);
};

export default useStripeCustomer;
