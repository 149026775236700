import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import { TooltipProps } from '@/routes/website_builder/_types/misc';

import { FormControlType } from './EditableProps/types';
import { EditableProps } from './EditableProps';
import TextareaHelper from './TextAreaHelper';

interface Props {
  label: string;
  onSave: (key: string, value: any) => void;
  editableProps: {
    [key: string]: {
      type: FormControlType;
      value: any;
      label?: string;
      resetCheckValue?: any;
      resetLabel?: string;
    };
  };
  tooltip?: TooltipProps;
  supportsMarkdown?: boolean;
  textValue?: string;
  onSaveText?: (value: string) => void;
  nodeId?: string;
  rows?: number;
}

const FontForm = ({
  label,
  tooltip,
  editableProps,
  onSave,
  onSaveText,
  textValue,
  supportsMarkdown,
  nodeId,
  rows,
}: Props) => {
  const { fontOptions } = usePageContext();

  const formFields = (
    <EditableProps items={editableProps} label={label} fontOptions={fontOptions} nodeId={nodeId} onSave={onSave} />
  );

  if (!onSaveText) {
    return formFields;
  }

  return (
    <TextareaHelper
      label={label}
      tooltip={tooltip}
      value={textValue || ''}
      rows={rows}
      onSave={onSaveText}
      supportsMarkdown={supportsMarkdown}
      nodeId={nodeId}
    >
      <div className="flex flex-col space-y-3">{formFields}</div>
    </TextareaHelper>
  );
};

export default FontForm;
