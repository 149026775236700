import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useUpdateUpgradePage, useUpgradePage } from '@/hooks/useUpgradePages';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import FAQSection from '@/routes/website_builder/_components/Form/FaqSection';
import ChildLoader from '@/routes/website_builder/_components/Form/PageSpecific/ChildLoader';
import TestimonialSection from '@/routes/website_builder/_components/Form/TestimonialSection';
import api from '@/services/swarm';

import usePageNavigator from '../../_hooks/usePageNavigator';
import useSetPublishId from '../../_hooks/useSetPublishId';
import useTabNavigator from '../../_hooks/useTabNavigator';

import HeroSection from './HeroSection';
import TiersSection from './TiersSection';

interface Props {
  setPublishId: (id: string) => void;
}

const UpgradePageSettings = ({ setPublishId }: Props) => {
  const queryClient = useQueryClient();
  const { handleSaved, setIsSaving, currentPublication } = usePageContext<any>();
  const upgradePageQuery = useUpgradePage({ status: 'draft' });
  const { data: upgradePage } = upgradePageQuery;
  const { isUpgradePage } = usePageNavigator();
  const { isStylesTab } = useTabNavigator();

  const updateUpgradePage = useUpdateUpgradePage({
    pageId: upgradePage?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['upgrade_page', currentPublication?.id, 'draft']);
      handleSaved();
    },
  });

  useSetPublishId({
    updateIdCheck: isUpgradePage && !isStylesTab,
    setPublishId,
    idToPublish: upgradePage?.id || '',
  });

  const handleSaveMainSection = (payload: any) => {
    setIsSaving(true);
    updateUpgradePage.mutate({ upgrade_page: { main_section: JSON.stringify(payload) } });
  };

  const handleSaveTestimonialsSection = (payload: any) => {
    setIsSaving(true);
    updateUpgradePage.mutate({ upgrade_page: { testimonials_section: JSON.stringify(payload) } });
  };

  const handleSaveFAQSection = (payload: any) => {
    setIsSaving(true);
    updateUpgradePage.mutate({ upgrade_page: { faq_section: JSON.stringify(payload) } });
  };

  const handleUploadImage = (type: string, file: File | null) => {
    setIsSaving(true);
    const formData = new FormData();

    if (typeof file !== 'string') {
      formData.append(`upgrade_page[${type}]`, file || '');
    }

    formData.append('publication_id', currentPublication?.id || '');

    api
      .patch(`/upgrade_pages/${upgradePage?.id}`, formData)
      .then(() => {
        queryClient.invalidateQueries(['upgrade_page', currentPublication?.id, 'draft']);
        handleSaved();
      })
      .catch((err) => {
        setIsSaving(false);
        toast.error(err?.response?.data?.error || 'Something went wrong');
      });
  };

  return (
    <ChildLoader isLoading={!upgradePage}>
      {upgradePage && (
        <>
          <HeroSection
            upgradePage={upgradePage}
            handleSave={handleSaveMainSection}
            handleUploadImage={handleUploadImage}
          />
          <TiersSection upgradePage={upgradePage} handleSave={handleSaveMainSection} />
          <TestimonialSection page={upgradePage} handleSave={handleSaveTestimonialsSection} type="upgrade_page" />
          <FAQSection page={upgradePage} handleSave={handleSaveFAQSection} />
        </>
      )}
    </ChildLoader>
  );
};

export default UpgradePageSettings;
