import { FC } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Button } from '@/ui/Button';

import { Skeleton, SkeletonLoader } from '../SkeletonLoader';
import Text from '../Text';

interface CardProps {
  className?: string;
  errorText?: string;
  id?: string;
  isError?: boolean;
  isLoading?: boolean;
  noPadding?: boolean;
  onActionClick?: any;
  style?: React.CSSProperties;
  title?: string;
  background?: string;
  children: React.ReactNode;
}

const Card: FC<CardProps> = (props: CardProps) => {
  const {
    className = '',
    errorText = 'Something went wrong, try refreshing the page.',
    id,
    isError = false,
    isLoading = false,
    noPadding = false,
    background = 'bg-white',
    onActionClick,
    style,
    title,
    children,
  } = props;

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div
      id={id}
      className={cx(
        'transition-all border border-gray-200 rounded-md',
        className,
        background,
        noPadding ? 'p-0' : 'p-4'
      )}
      style={style}
    >
      {title && (
        <div className="flex justify-between border-b border-gray-200 items-center mb-6 pb-2">
          <Text type="subtitle" size="sm" className="text-gray-900">
            {title}
          </Text>
          {onActionClick && (
            <button
              type="button"
              onClick={onActionClick}
              className="h-7 w-7 bg-gray-100 text-gray-80 hover:text-white rounded-full flex justify-center items-center group hover:bg-gray-900 transition-all"
            >
              <PlusIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      )}
      {isLoading && !isError && (
        <SkeletonLoader
          isLoading={isLoading}
          skeletons={
            <div className="space-y-6 w-full">
              <div className="space-y-2">
                <Skeleton className="h-6 rounded" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-6 rounded" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-6 rounded" />
              </div>
            </div>
          }
        >
          {children}
        </SkeletonLoader>
      )}

      {isError && !isLoading && (
        <div className="absolute top-1/2 right-1/2 transform translate-x-1/2 ">
          <div className="flex flex-col items-center">
            <p className="text-sm text-gray-600">{errorText}</p>
            <Button variant="primary-inverse" className="mt-2" onClick={handleRefresh}>
              <span>Refresh</span>
            </Button>
          </div>
        </div>
      )}

      {!isLoading && !isError && children}
    </div>
  );
};

export default Card;
