import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { BoostSend } from '../../../../interfaces/boosts/monetize/boost_send';
import api from '../../../../services/swarm';

interface Props {
  onSuccess?: (response: any) => void;
  onSettled?: (response: any) => void;
}

interface Variables {
  boost_agreement_id: string;
}

const useCreateBoostSend = ({ onSuccess, onSettled }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const createBoostSend = ({ boost_agreement_id }: Variables): Promise<BoostSend> =>
    api
      .post('/boosts/monetize/boost_sends', {
        publication_id: currentPublicationId,
        boost_send: { boost_agreement_id },
      })
      .then((res) => res.data);

  return useMutation(createBoostSend, {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
    },
    onSettled: (response) => {
      if (onSettled) onSettled(response);
    },
  });
};

export default useCreateBoostSend;
