import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { LedgerEntry, LedgerEntryCategory, LedgerKind } from '../../interfaces/ledger_entry';
import api from '../../services/swarm';

interface Props {
  onSuccess?: (response: any) => void;
  onError?: (err: any) => void;
}

interface Variables {
  amount_cents: number;
  category: LedgerEntryCategory;
  description?: string;
  ledger_kind: LedgerKind;
  prevent_negative_ledger_balance?: boolean;
}

const useCreateLedgerEntry = ({ onSuccess, onError }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const createLedgerEntry = ({
    amount_cents,
    category,
    description,
    ledger_kind,
    prevent_negative_ledger_balance,
  }: Variables): Promise<LedgerEntry> =>
    api
      .post('/boosts/ledger_entries', {
        publication_id: currentPublicationId,
        ledger_kind,
        ledger_entry: {
          amount_cents,
          category,
          description,
          prevent_negative_ledger_balance,
        },
      })
      .then((res) => res.data);

  return useMutation(createLedgerEntry, {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
    },
    onError: (err: any) => {
      if (onError) {
        onError(err);
      } else {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      }
    },
  });
};

export default useCreateLedgerEntry;
