import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

export default function useDisbursementPayout({ id, onSuccess }: { id: string; onSuccess: () => void }) {
  const payoutDisbursement = (): Promise<any> =>
    api.patch(`/ad_network/internal/disbursements/${id}/payouts`).then((res) => res.data);

  return useMutation(payoutDisbursement, {
    onSuccess: () => {
      onSuccess?.();
      toast.success('Disbursement paid');
    },
    onError: (error: any) => {
      toast.error(error.response.data.error || 'Something went wrong');
    },
  });
}
