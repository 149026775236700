import { useCallback, useMemo } from 'react';
import { CaretRight, Question, X } from '@phosphor-icons/react';
import cx from 'classnames';

import { useWebsiteContext } from '@/context/website-context';
import { WebTheme } from '@/interfaces/web_theme';

import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { Tooltip } from '../../../UI/Tooltip';
import { PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';

// Pulled from TailwindCSS
// https://tailwindcss.com/docs/font-size
const TOKENIZE_COLOR_OPTIONS = [
  {
    label: 'primary_color',
    value: 'primary_color',
  },
  {
    label: 'text_on_primary_color',
    value: 'text_on_primary_color',
  },
  {
    label: 'background_color',
    value: 'background_color',
  },
  {
    label: 'text_on_background_color',
    value: 'text_on_background_color',
  },
  {
    label: 'border_color',
    value: 'border_color',
  },
];

type Props = AttributeSettingProps & {
  property: string;
  isMarkStyle?: boolean;
  color?: string;
};

export const TokenizeColorSettings = ({ editor, activeNodeResult, property, isMarkStyle, color }: Props) => {
  const { site } = useWebsiteContext();
  const { activeNodeAttributes, activeNodePos, activeNode } = activeNodeResult;
  const themeRules = site?.theme_rules;
  const primaryTheme = site?.primary_theme;

  const propertyRule = useMemo(() => {
    const isText = activeNode?.type?.name === 'heading' || activeNode?.type?.name === 'paragraph';
    const nodeKey = isText ? 'text' : activeNode?.type?.name;
    const nodeRule = themeRules?.[nodeKey || ''] as Record<string, string[]>;

    let ruleKey = null;

    Object.entries(nodeRule).forEach(([key, value]) => {
      if (value.includes(property)) {
        ruleKey = key;
      }

      return null;
    });

    return ruleKey;
  }, [themeRules, property, activeNode?.type?.name]);

  const textValue =
    TOKENIZE_COLOR_OPTIONS.find((option) => option.value === activeNodeAttributes?.[property])?.label || 'None';
  const tokenValue = activeNodeAttributes?.tokens?.[property] || editor.getAttributes('textStyle')?.tokens?.[property];
  const hasToken = Boolean(tokenValue);

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      if (isMarkStyle) {
        const currentTokens = editor.getAttributes('textStyle')?.tokens || {};

        const newToken = {
          [property]: `${value}`,
        };
        const newTokens = { ...currentTokens, ...newToken };
        editor.chain().selectTextBlock().updateAttributes('textStyle', { tokens: newTokens }).focus().run();
      } else {
        const currentTokens = activeNodeAttributes?.tokens || {};
        const newToken = {
          [property]: `${value}`,
        };
        const newTokens = { ...currentTokens, ...newToken };

        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'tokens', newTokens);
          return true;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property]
  );

  const handleRemove = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      if (isMarkStyle) {
        editor.chain().selectTextBlock().updateAttributes('textStyle', { tokens: {} }).focus().run();
      } else {
        const currentTokens = activeNodeAttributes?.tokens || {};
        const tokenExists = currentTokens[value];
        if (!tokenExists) return;

        const newTokens = { ...currentTokens };
        delete newTokens[value];

        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'tokens', newTokens);
          return true;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property]
  );

  return (
    <Popover>
      <div className="flex flex-col justify-stretch gap-2">
        <div className="flex items-center justify-between gap-2">
          <Tooltip center="The color applied when the apply theme action is taken" placement="bottom" className="z-10">
            <div className="flex items-center gap-1">
              <Text variant="secondary" size="2xs" weight="semibold">
                Theme Rule
              </Text>
              <Question className="text-wb-secondary" weight="bold" size={14} />
            </div>
          </Tooltip>

          {!hasToken && (
            <PopoverTrigger asChild>
              <div className="flex items-center cursor-pointer px-1 py-0.5 bg-transparent rounded-md hover:bg-wb-secondary">
                <Text className="text-wb-secondary" variant="secondary" size="2xs" weight="medium">
                  Override
                </Text>
                <CaretRight className="text-wb-secondary" weight="bold" size={10} />
              </div>
            </PopoverTrigger>
          )}
        </div>

        {hasToken && (
          <button
            type="button"
            className="flex w-fit gap-2 items-center justify-start px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px]"
            onClick={() => handleRemove(property)}
          >
            <div
              style={{ backgroundColor: primaryTheme?.[tokenValue as keyof WebTheme] }}
              className="w-4 h-4 rounded-md"
            />
            <Text
              weight="regular"
              variant="accent"
              size="3xs"
              as="span"
              className="line-clamp-1 max-w-[150px] truncate"
            >
              {tokenValue}
            </Text>
            <X className="w-3 h-3" />
          </button>
        )}
        <div className={cx('bg-wb-secondary rounded-lg shadow-sm w-fit', hasToken && 'opacity-70')}>
          <div className="w-fit flex items-center gap-2 px-2 py-1">
            <div style={{ backgroundColor: color }} className="w-4 h-4 rounded-md" />
            <Text
              size="3xs"
              weight="medium"
              as="span"
              className={cx(
                'whitespace-nowrap text-wb-secondary overflow-hidden overflow-ellipsis pr-2',
                hasToken && 'line-through'
              )}
            >
              {propertyRule}
            </Text>
          </div>
        </div>
      </div>
      {!hasToken && (
        <PopoverContent className="w-[255px] p-0" align="end" side="left" sideOffset={205}>
          <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
            <div className="flex items-center justify-between gap-2">
              <Text size="sm" weight="semibold">
                Theme Rule
              </Text>
            </div>

            <div className="flex flex-col gap-1.5">
              <div className="flex flex-col gap-2">
                {TOKENIZE_COLOR_OPTIONS.map((option) => (
                  <PopoverItem
                    key={option.value}
                    value={
                      <div className="flex items-center gap-2">
                        <div
                          style={{ backgroundColor: primaryTheme?.[option.value as keyof WebTheme] }}
                          className="w-4 h-4 rounded-md"
                        />
                        <Text size="2xs" weight="medium" as="span">
                          {option.label}
                        </Text>
                      </div>
                    }
                    onSelect={() => handleUpdate(option.value)}
                    isSelected={textValue === option.label}
                  />
                ))}
              </div>
            </div>
          </div>
        </PopoverContent>
      )}
    </Popover>
  );
};
