import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

import ActivityFeed from '../_components/ActivityFeed';
import { useSearchContext } from '../_context/searchContext';
import { useSubscriberContext } from '../_context/subscriberContext';
import { useSubscriberActivityFeedEvents } from '../_hooks/useActivityFeedEvents';

const SubscriberProfileEvents = () => {
  const { subscription } = useSubscriberContext();
  const { search, eventType } = useSearchContext();
  const query = useSubscriberActivityFeedEvents({ search: search || undefined, subscription, eventType });

  return (
    <ActivityFeed
      title="Subscriber Events Activity Feed"
      emptyText="No activity for this subscriber have been recorded yet."
      query={query}
      eventTypeOptions={[
        ActivityFeedEventType.SUBSCRIBE,
        ActivityFeedEventType.UNSUBSCRIBE,
        ActivityFeedEventType.UPGRADE,
        ActivityFeedEventType.DOWNGRADE,
      ]}
    />
  );
};
export default SubscriberProfileEvents;
