import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from './routes/advertiser_access/_layout';
import Campaign from './routes/advertiser_access/$advertiser_id/campaign_reports/$campaign_id';
import CampaignPerformanceDelivered from './routes/advertiser_access/$advertiser_id/campaign_reports/$campaign_id/delivered';
import CampaignPerformanceUpcoming from './routes/advertiser_access/$advertiser_id/campaign_reports/$campaign_id/upcoming';

const App = () => {
  return (
    <Routes>
      <Route path=":advertiser_id">
        <Route element={<Layout />}>
          <Route path="campaign_reports">
            <Route path=":campaign_id" element={<Campaign />}>
              <Route index element={<Navigate to="delivered" replace />} />
              <Route path="delivered" element={<CampaignPerformanceDelivered />} />
              <Route path="upcoming" element={<CampaignPerformanceUpcoming />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
