import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import useAdBlockDetect from '@/hooks/useAdBlockDetect';
import { Button } from '@/ui/Button';
import TabNavigation from '@/ui/TabNavigation';

import StripeConnectMessage from './_components/StripeConnectMessage';

const MonetizeAdsLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { adBlockDetected, ignoreAdBlocker } = useAdBlockDetect();

  const tabs = [
    {
      name: 'overview',
      label: 'Overview',
      selected: pathname === `/monetize/ads`,
      onSelect: () => navigate(`/monetize/ads`),
    },
    {
      name: 'reports',
      label: 'Reports',
      selected: pathname === `/monetize/ads/reports`,
      onSelect: () => navigate(`/monetize/ads/reports`),
    },
  ];
  return (
    <div>
      <PageHeading title="Advertisements" description="Seamlessly monetize your newsletter with premium sponsors." />
      <StripeConnectMessage />
      <TabNavigation tabs={tabs} className="mt-6" variant="tertiary" />
      {adBlockDetected ? (
        <div className="h-screen p-4">
          <div className="flex flex-wrap max-w-2xl p-8 mx-auto bg-white border rounded">
            <div className="flex items-center justify-center w-10 h-10 mb-4 mr-4 bg-gray-200 rounded-full">
              <ExclamationTriangleIcon className="w-6 h-6 text-gray-500" />
            </div>
            <div className="sm:flex-1">
              <h1 className="text-lg font-bold text-gray-800 sm:text-base">Ad blocker detected</h1>
              <p className="text-lg text-gray-800 sm:text-base">
                It appears that you are using an ad blocker — some elements of the site may not work properly while an
                ad blocker is enabled. Please consider disabling your ad blocker for this site.
              </p>
              <Button onClick={ignoreAdBlocker} className="mt-4" variant="primary-inverse">
                I understand, continue anyway
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default MonetizeAdsLayout;
