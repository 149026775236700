import { useContext } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { TabContext } from './TabContext';

interface TabProps {
  children: React.ReactNode;
  to: string;
}

const Tab = ({ children, to }: TabProps) => {
  const { isActive, isAfterActive } = useContext(TabContext);
  const tabClasses = cx(
    'px-4 py-2 text-sm font-medium duration-200 text-center',
    isActive && 'ring ring-gray-100 bg-white rounded-lg',
    isActive ? 'text-gray-900' : 'text-gray-600 hover:text-gray-900',
    (isActive || isAfterActive) && 'border-transparent'
  );

  return (
    <Link to={to} className={tabClasses} replace aria-current={isActive ? 'page' : undefined}>
      {children}
    </Link>
  );
};

export default Tab;
