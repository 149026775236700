import { Spinner } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { IconPlacementSettings } from '../IconPlacementSettings';
import { IconSettings } from '../IconSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

type Props = AttributeSettingProps & {};

export const PaginationPostSetting = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Pagination" text="Pagination" popoverTitle="Pagination" Icon={Spinner} align="end">
      <>
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'paginateButtonBorderColor',
            style: 'paginateButtonBorderStyle',
            width: 'paginateButtonBorderWidth',
            radius: 'paginateButtonBorderRadius',
          }}
        />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="paginateButtonPadding" />
        <ShadowSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Shadow"
          property="paginateButtonShadow"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Text"
          property="paginateButtonTextColor"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Background"
          property="paginateButtonBackgroundColor"
          hasGradientPicker
          gradientProperty="paginateButtonBackgroundColor"
        />
        <IconSettings editor={editor} activeNodeResult={activeNodeResult} property="paginateButtonIcon" />
        <IconPlacementSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Icon Placement"
          property="paginateButtonIconPlacement"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Icon Color"
          property="paginateButtonIconColor"
        />
      </>
    </PopoverHelper>
  );
};

export default PaginationPostSetting;
