/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import cx from 'classnames';

export interface Props extends React.HTMLProps<HTMLInputElement> {
  className?: string;
  name: string;
  value?: string;
  labelText?: string;
  errorText?: string;
  helperText?: string | React.ReactNode;
  placeholderText?: string;
  readOnly?: boolean;
  inputClassOverride?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const PercentageInput = (props: Props) => {
  const {
    className,
    errorText,
    name,
    labelText,
    helperText,
    value,
    placeholderText,
    inputClassOverride,
    required = false,
    disabled = false,
    readOnly = false,
    ...rest
  } = props;

  const hasError = !!errorText;

  return (
    <div className={className}>
      {labelText && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {labelText}
          {required ? ' *' : ''}
        </label>
      )}
      <div className={cx('flex flex-col', labelText && 'mt-1')}>
        <div className="flex items-center relative">
          <input
            name={name}
            type="number"
            min={0}
            max={100}
            step={1}
            value={value}
            placeholder={placeholderText}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            className={cx(
              inputClassOverride ||
                'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm h-full disabled:cursor-not-allowed',
              hasError ? 'border-red-300' : 'border-gray-300',
              disabled ? 'bg-gray-200 opacity-60' : 'opacity-100'
            )}
            style={{
              height: 'inherit',
            }}
            {...rest}
          />
          <span className="text-sm absolute top-0 inset-y-0 right-0 flex items-center pr-3.5 pointer-events-none text-gray-400 font-bold">
            %
          </span>
        </div>
        {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
        {errorText && <p className="mt-2 text-xs text-red-500">{errorText}</p>}
      </div>
    </div>
  );
};

export default PercentageInput;
