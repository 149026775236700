import cx from 'classnames';

import useMediaQuery from '@/hooks/useMediaQuery';

import WalkthroughCard, { WalkthroughCardProps } from './WalkthroughCard';

interface Props extends WalkthroughCardProps {
  children: any;
  isActive?: boolean;
  positionClassNameOverride?: string;
}

const CARD_LOCATION_MAP = Object.freeze({
  top: 'top-20',
  left: 'top-0 left-64',
  bottom: '-bottom-2 left-1/2 transform -translate-x-1/2',
  right: 'top-0 -left-80',
});

const WalkthroughPortal = ({
  children,
  isActive = false,
  title,
  description,
  positionClassNameOverride,
  arrowPositionOverride,
  totalSteps,
  currentStep,
  arrowDirection = 'left',
  hasOverlay = false,
  continueButton = {
    text: 'Continue',
    onClick: () => {},
  },
  backButton = {
    text: 'Back',
    onClick: () => {},
  },
  onClose,
  variant = 'tertiary',
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 640px)');

  if (!isActive) {
    return children;
  }

  return (
    <div className="relative z-20">
      {children}
      {!isMobile ? (
        <div className={cx(positionClassNameOverride, 'absolute', CARD_LOCATION_MAP[arrowDirection])}>
          <WalkthroughCard
            title={title}
            hasOverlay={hasOverlay}
            description={description}
            totalSteps={totalSteps}
            currentStep={currentStep}
            arrowDirection={arrowDirection}
            arrowPositionOverride={arrowPositionOverride}
            continueButton={continueButton}
            backButton={backButton}
            onClose={onClose}
            variant={variant}
          />
        </div>
      ) : (
        <div className={cx('fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2')}>
          <WalkthroughCard
            title={title}
            hasOverlay={hasOverlay}
            description={description}
            totalSteps={totalSteps}
            currentStep={currentStep}
            continueButton={continueButton}
            backButton={backButton}
            onClose={onClose}
            variant={variant}
          />
        </div>
      )}
    </div>
  );
};

export default WalkthroughPortal;
