import { useEffect, useState } from 'react';

import { SortableList, SortableListItem, useSortableList } from '@/components/SortableList';
import { Typography } from '@/components/Typography';
import { HomePage } from '@/interfaces/home_page';
import AddInput from '@/routes/website_builder/_components/Form/AddInput';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';
import {
  SectionGroupingSlideOut,
  SideWidgetSectionIcon,
} from '@/routes/website_builder/_components/Helpers/SectionGroupingSlideOut';

interface Props {
  homePage?: HomePage;
  handleSave: (payload: any) => void;
}

type WidgetItem = {
  id: string;
  value: string;
};

const SideWidgetsSection = ({ homePage, handleSave }: Props) => {
  const allowedOptions = homePage?.widget_options?.filter((option) => option.enabled).map((option) => option.label);
  const widgets = homePage?.widgets_section?.widgets_enabled || [];
  const widgetLength = widgets.length;

  const options = [
    { value: 'newsletters', label: 'Newsletters' },
    { value: 'authors', label: 'Authors' },
    { value: 'signup', label: 'Signup' },
    { value: 'categories', label: 'Categories' },
    { value: 'publications', label: 'Multi-Publications' },
    { value: 'recommendations', label: 'Recommendations' },
    { value: 'social', label: 'Social' },
  ];

  const [availableOptions, setAvailableOptions] = useState<any>(allowedOptions || []);

  useEffect(() => {
    setAvailableOptions(
      allowedOptions
        ?.filter((option) => !widgets.includes(option))
        .map((option) => {
          return { label: option, value: option };
        })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetLength]);

  const { sortableList, setSortableList, reStringifyList } = useSortableList<WidgetItem>({
    list: widgets,
    addIdToListOfStrings: true,
  });
  const hasWidgets = sortableList.length > 0;
  const availableArray = options.filter((option) =>
    availableOptions?.find((availableOption: any) => availableOption.value === option.value)
  );

  return (
    <SectionGroupingSlideOut
      title="Side Widget Section"
      showPlanBadge
      sectionIcon={<SideWidgetSectionIcon />}
      highlightProps={{
        sectionId: 'side_widget_section',
      }}
    >
      <PropertiesGrouping label="Order Widgets" indent>
        <div className="space-y-4 w-full">
          {hasWidgets ? (
            <SortableList
              listItems={sortableList}
              onItemsSorted={(sortedList: any[]) => {
                const stringifiedList = reStringifyList(sortedList);
                return handleSave({
                  ...homePage?.widgets_section,
                  widgets_enabled: stringifiedList,
                });
              }}
            >
              {(widgetsEnabledList: any) => {
                return widgetsEnabledList.map((item: WidgetItem) => {
                  return (
                    <SortableListItem
                      key={item.id}
                      listItem={item}
                      onRemoveItem={(widgetItem: WidgetItem) => {
                        const list = sortableList.filter((widget: WidgetItem) => widget.id !== widgetItem.id);
                        setSortableList(list);
                        const reStringifiedList = reStringifyList(list);
                        return handleSave({
                          ...homePage?.widgets_section,
                          widgets_enabled: [...reStringifiedList],
                        });
                      }}
                      text={item.value}
                    />
                  );
                });
              }}
            </SortableList>
          ) : (
            <div className="w-full flex flex-col justify-center items-center">
              <Typography token="font-normal/text/xs" className="text-center w-full mt-1">
                No widgets yet!
              </Typography>
            </div>
          )}
        </div>
      </PropertiesGrouping>
      <PropertiesGrouping label="Add Widgets" indent>
        {availableArray.length ? (
          availableArray.map((option) => {
            return (
              <AddInput
                key={option.label}
                label={option.value}
                onSave={() => {
                  const list = [...sortableList, { id: sortableList.length, value: option.value }];
                  setSortableList(sortableList);
                  const reStringifiedList = reStringifyList(list as WidgetItem[]);
                  return handleSave({
                    ...homePage?.widgets_section,
                    widgets_enabled: [...reStringifiedList],
                  });
                }}
              />
            );
          })
        ) : (
          <Typography token="font-normal/text/xs" className="text-center mt-1">
            All widgets used!
          </Typography>
        )}
      </PropertiesGrouping>
    </SectionGroupingSlideOut>
  );
};

export default SideWidgetsSection;
