import { FC, ReactNode, useMemo } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

export interface RadioOption {
  name: string | ReactNode;
  value: string;
  description?: string | ReactNode;
  correct?: boolean;
  children?: ReactNode;
  customProps?: any;
  isVisible?: boolean;
}

interface Props {
  hasBorders?: boolean;
  className?: string;
  labelText?: string;
  required?: boolean;
  disabled?: boolean;
  value: String | undefined;
  onSelect: (value: string) => void;
  options: RadioOption[];
  size?: 'md' | 'lg';
  orientation?: 'row' | 'col' | 'wrap';
  CustomOptionNode?: ({ checked, option }: { checked: boolean; option: RadioOption }) => JSX.Element;
}

const RadioSelect: FC<Props> = ({
  className,
  labelText,
  onSelect,
  value,
  options,
  required = false,
  disabled = false,
  hasBorders = true,
  size = 'md',
  orientation = 'col',
  CustomOptionNode,
}: Props) => {
  const isLarge = size === 'lg';
  const isHorizontal = orientation === 'row';
  const isWrapped = orientation === 'wrap';

  const handleSelect = (selectedValue: string) => {
    if (!disabled) {
      onSelect(selectedValue);
    }
  };

  const filteredOptions = useMemo(
    () => options.filter((option) => typeof option.isVisible === 'undefined' || option.isVisible === true),
    [options]
  );

  return (
    <RadioGroup className={`${className} space-y-2`} value={value} onChange={handleSelect}>
      {labelText && (
        <RadioGroup.Label className="text-sm font-medium text-gray-800 pb-2">
          {required ? `${labelText}*` : labelText}
        </RadioGroup.Label>
      )}
      <div
        className={cx(
          hasBorders && !isHorizontal && !isWrapped ? `space-y-2` : '',
          isHorizontal ? 'flex items-center space-x-4' : '',
          isWrapped ? 'flex flex-row items-center flex-wrap justify-center' : ''
        )}
      >
        {filteredOptions.map((option: RadioOption) => (
          <RadioGroup.Option
            className={isWrapped ? 'w-fit mr-4 mb-4' : 'w-full'}
            value={option.value}
            key={option.value}
          >
            {({ checked }) => {
              if (CustomOptionNode) {
                return <CustomOptionNode checked={checked} option={option} />;
              }

              return (
                <div
                  className={cx(
                    'rounded relative flex bg-white transition-all',
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    checked ? 'text-gray-900' : 'text-gray-900',
                    !disabled ? 'hover:text-gray-900 hover:bg-gray-50' : '',
                    hasBorders && checked ? 'border border-gray-300' : '',
                    hasBorders && !checked ? 'border border-gray-200' : '',
                    isLarge ? 'text-lg font-regular py-4 px-8' : 'text-sm font-medium py-2 px-4'
                  )}
                >
                  <div className="flex">
                    {checked ? (
                      <CheckIcon className="w-5 h-5 stroke-4 p-1 bg-primary-500 text-white rounded-full duration-200" />
                    ) : (
                      <div className="w-5 h-5 border border-gray-300 rounded-full" />
                    )}
                  </div>
                  <div className="ml-3">
                    {typeof option.name === 'string' ? <div>{option.name}</div> : option.name}
                    {typeof option.description === 'string' ? (
                      <div className="text-sm text-gray-500 font-normal pt-1">{option.description}</div>
                    ) : (
                      option.description
                    )}
                  </div>
                  {option.correct && (
                    <span className="absolute right-2 top-1/2 -translate-y-1/2 transform">
                      <CheckIcon className="h-4 w-4 text-green-500" />
                    </span>
                  )}
                </div>
              );
            }}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioSelect;
