import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { BoostOfferSort } from '../../../../hooks/boosts/monetize/useBoostOffers';

interface Option {
  label: string;
  value: BoostOfferSort;
}

interface Props {
  options: Array<Option>;
  selected: Option;
  setSelected: (option: Option) => void;
}

export default function SortingOptions({ options, selected, setSelected }: Props) {
  return (
    <div>
      <div>
        <section aria-labelledby="filter-heading">
          <div className="flex items-center justify-between">
            <Menu as="div" className="relative inline-block text-left">
              {({ open: isOpen }) => (
                <>
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      Sort by {selected.label}
                      <ChevronDownIcon
                        className={cx(
                          '-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500 duration-200',
                          isOpen ? 'transform rotate-180 ease-out' : 'ease-in'
                        )}
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-50 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {options.map((option) => (
                          <Menu.Item key={option.value}>
                            {({ active }) => (
                              <button
                                className={cx(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm font-medium text-gray-900 w-full text-left'
                                )}
                                type="button"
                                onClick={() => {
                                  setSelected(option);
                                }}
                              >
                                {option.label}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </section>
      </div>
    </div>
  );
}
