import React, { FC } from 'react';

import EmailMessageSelect from '../../../components/EmailMessageSelect';
import PostSelect from '../../../components/PostSelect';
import { EmailMessagePurpose } from '../../../interfaces/email_message';
import { Condition, Filter } from '../../../interfaces/segment';

import { defaultOperatorByConditionName } from './operatorOptions';

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleFilterChange: (filters: Filter, blockIndex: number, subIndex?: number) => void;
}

const EmailTypeSelect: FC<Props> = ({ condition, handleFilterChange, index, subIndex }) => {
  const defaultOperator = defaultOperatorByConditionName(condition?.name);

  const handleChange = (resourceId?: string) => {
    const updatedFilters = {
      resource_id: resourceId,
      operator: condition?.filters?.operator || defaultOperator,
    };

    const newFilters = { ...condition.filters, ...updatedFilters };

    handleFilterChange(newFilters, index, subIndex);
  };

  if (condition?.name === 'post') {
    return (
      <PostSelect
        className="w-full"
        labelText=""
        postId={condition.filters?.resource_id || ''}
        onSelectPost={(postId: string) => handleChange(postId)}
        onClearPost={() => handleChange(undefined)}
      />
    );
  }

  if (condition?.name === 'email_blast') {
    return (
      <EmailMessageSelect
        className="w-full"
        labelText=""
        emailMessageId={condition.filters?.resource_id || ''}
        emailMessagePurpose={EmailMessagePurpose.BLAST}
        onSelect={(emailMessageId: string) => handleChange(emailMessageId)}
        onClear={() => handleChange(undefined)}
      />
    );
  }

  if (condition?.name === 'automation_step_message') {
    return (
      <EmailMessageSelect
        className="w-full"
        labelText=""
        emailMessageId={condition.filters?.resource_id || ''}
        emailMessagePurpose={EmailMessagePurpose.AUTOMATION_STEP}
        onSelect={(emailMessageId: string) => handleChange(emailMessageId)}
        onClear={() => handleChange(undefined)}
      />
    );
  }

  return null;
};

export default EmailTypeSelect;
