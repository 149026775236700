import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { cn } from '@/utils/cn';

import 'tippy.js/animations/shift-toward-subtle.css';

export const BacklinkView = ({ node }: NodeViewProps) => {
  return (
    <NodeViewWrapper as="span" className="backlink" data-drag-handle>
      <span className={cn('cursor-pointer')}>{node.attrs.title}</span>
    </NodeViewWrapper>
  );
};
