import { useQuery } from 'react-query';

import { ColorPalette } from '../../interfaces/color_palette';
import api from '../../services/swarm';

const usePublicationColorPalette = (publicationId: string) =>
  useQuery<ColorPalette, Error>(['publications', publicationId, 'color_palette'], () =>
    api.get(`/publications/${publicationId}/color_palette`).then((res) => res.data.color_palette)
  );

export default usePublicationColorPalette;
