import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import SlideOver from '../../../../../components/SlideOver';
import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { useUpdateNavLink } from '../../../../../hooks/useNavlinks';
import { NavGroup } from '../../../../../interfaces/nav_group';
import { NavLink } from '../../../../../interfaces/nav_link';
import { isValidUrl } from '../../../../../utils';

import ExternalLinkForm from './ExternalLinkForm';

interface Props {
  navLink: NavLink | null;
  isOpen: boolean;
  onClose: () => void;
  navGroups: NavGroup[];
  refetch: () => void;
}

const EditLinkPanel = ({ navLink, isOpen, onClose, navGroups, refetch }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const updateNavLink = useUpdateNavLink({
    navLinkId: navLink?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      refetch();
      onClose();
    },
  });

  const handleOnSubmit = (navLinkPayload: any) => {
    if (!isValidUrl(navLinkPayload.href)) {
      toast.error('Please include a valid URL');
      return;
    }

    updateNavLink.mutate(navLinkPayload);
  };

  if (!navLink || !navGroups) return null;

  return (
    <SlideOver bodyId="new-external-link" isOpen={isOpen} onClose={onClose} headerText="Edit External Page">
      <ExternalLinkForm
        navGroups={navGroups}
        navLink={navLink}
        onSubmit={handleOnSubmit}
        onClose={onClose}
        isLoading={updateNavLink.isLoading}
      />
    </SlideOver>
  );
};

export default EditLinkPanel;
