import toast from 'react-hot-toast';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

import ProtectedAction from '@/components/ProtectedAction';
import { useResetSiteTheme } from '@/hooks/useWebTemplates';

import { SettingsContainer, SettingsGroup } from '../../../../ui/Settings/SettingsContainer';

const WebBuilderSiteReset = () => {
  const resetThemeMutation = useResetSiteTheme({
    onSuccess: () => {
      toast.success('Theme Reset!');
    },
  });

  const handleResetTheme = () => {
    resetThemeMutation.mutate();
  };

  return (
    <SettingsContainer>
      <SettingsGroup title="Theme Reset" description="Reset your theme customizations and start from scratch">
        <ProtectedAction
          buttonText="Reset Site Theme"
          isSubmitting={resetThemeMutation.isLoading}
          actionWord="RESET"
          Icon={ArrowPathIcon}
          onProceed={handleResetTheme}
          resourceId="reset-site-theme"
          modalDescription="Are you sure you want to take this action? Your site will be reset to its default theme."
        />
      </SettingsGroup>
    </SettingsContainer>
  );
};

export default WebBuilderSiteReset;
