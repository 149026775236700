import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

import useEventIcon from './_hooks/useEventIcon';

const EventIcon = ({ type }: { type: ActivityFeedEventType }) => {
  const Icon = useEventIcon(type);

  return (
    <div className="w-8 h-8 bg-surface-100 rounded-full flex items-center justify-center">
      {Icon && <Icon className="w-4 h-4 text-gray-900" />}
    </div>
  );
};

export default EventIcon;
