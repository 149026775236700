import { Label } from '@/components/Form';
import SelectAutomation from '@/components/SelectAutomation/SelectAutomation';

interface Props {
  automationId: string;
  selectedAutomationIds?: string[];
  onChange: (value: string[]) => void;
}

const ConfigureEnrollInAutomation = ({ automationId, selectedAutomationIds = [], onChange }: Props) => {
  const onSelectAutomation = (selectedAutomationId: string) =>
    onChange([...selectedAutomationIds, selectedAutomationId]);

  const onDeselectAutomation = (deselectedAutomationId: string) => {
    const newAutomations = [...selectedAutomationIds];
    const indexOfDeselectedValue = newAutomations.indexOf(deselectedAutomationId);

    if (indexOfDeselectedValue !== -1) {
      newAutomations.splice(indexOfDeselectedValue, 1);
      onChange(newAutomations);
    }
  };

  const onDeselectAllAutomations = () => {
    onChange([]);
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Label htmlFor="" value="Select Automations" />
      <SelectAutomation
        onSelect={onSelectAutomation}
        onDeselect={onDeselectAutomation}
        onDeselectAll={onDeselectAllAutomations}
        selectedAutomations={selectedAutomationIds}
        excludedAutomations={[automationId]}
      />
    </div>
  );
};

export default ConfigureEnrollInAutomation;
