import Avatar from '@/components/Avatar';
import { Mapbox } from '@/components/Mapbox';

import { useSubscriberContext } from '../_context/subscriberContext';

import UpdateEmailModal from './UpdateEmailModal';

const Header = () => {
  const { subscriber, subscription, gravatar } = useSubscriberContext();

  const { latitude, longitude, city, state, country } = subscription;

  const coordinatesPresent = !!latitude || !!longitude;

  let zoom;
  if (coordinatesPresent && !state && !city) zoom = 2;
  if (coordinatesPresent && state && !city) zoom = 5;

  const address = [city, state, country].filter(Boolean).join(', ') || 'No location data available';

  return (
    <>
      <div className="relative" style={{ minHeight: '200px' }}>
        <div className="absolute w-full">
          {coordinatesPresent ? (
            <Mapbox
              latitude={latitude}
              longitude={longitude}
              container={`${subscriber.id}-location`}
              zoom={zoom}
              showPin={coordinatesPresent}
            />
          ) : (
            <div className="w-full min-h-[200px] rounded-lg bg-center bg-cover bg-[url(https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,quality=90/static_assets/world_map.png)]" />
          )}
        </div>
        <div className="absolute bottom-0 h-20 bg-gradient-to-b from-transparent via-white to-white w-full z-10" />
        <div className="absolute bottom-0 z-10 px-4">
          <Avatar src={gravatar} />
        </div>
      </div>
      <div className="px-4 pt-3">
        <div className="flex space-x-1 items-center">
          <p className="text-lg lg:text-xl font-bold">{subscriber.email}</p>
          <UpdateEmailModal />
        </div>
        <p className="text-sm mt-1">
          {subscription.name && (
            <>
              <span className="font-semibold">{subscription.name}</span>
              <span className="mx-1">&bull;</span>
            </>
          )}
          <span>{address}</span>
        </p>
      </div>
    </>
  );
};

export default Header;
