import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import {
  BLACK_COLOR,
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
  WHITE_COLOR,
} from '../constants';

import { RecommendationsView } from './views/RecommendationsView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    recommendations: {
      setRecommendations: () => ReturnType;
    };
  }
}

type CardSchema = {
  node: string;
  className: string;
  children: CardSchema[];
};

const cardStructure: CardSchema = {
  node: 'div',
  className: 'flex flex-row gap-4',
  children: [
    {
      node: 'image',
      className: 'object-cover h-16 w-16 rounded-lg',
      children: [],
    },
    {
      node: 'div',
      className: 'flex flex-col gap-1',
      children: [
        {
          node: 'name',
          className: 'flex flex-col text-left',
          children: [],
        },
        {
          node: 'description',
          className: 'flex flex-col text-left',
          children: [],
        },
        {
          node: 'checkbox',
          className: 'flex flex-col absolute top-2 right-2',
          children: [],
        },
      ],
    },
  ],
};

export const Recommendations = Node.create<{}, {}>({
  name: 'recommendations',

  group: 'block',

  draggable: true,

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      id: {
        default: '',
        renderHTML: (attributes) => ({ 'data-id': attributes.id }),
        parseHTML: (element) => element.getAttribute('data-id') || '',
      },
      listHeight: {
        default: '200px',
        renderHTML: (attributes) => ({ 'data-list-height': attributes.listHeight }),
        parseHTML: (element) => element.getAttribute('data-list-height'),
      },
      insertedFromSidebar: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-inserted-from-sidebar') === 'true',
        renderHTML: (attributes) => ({ 'data-inserted-from-sidebar': attributes.insertedFromSidebar }),
      },
      disablePopupFormSetting: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-disable-popup-form-setting') === 'true',
        renderHTML: (attributes) => ({ 'data-disable-popup-form-setting': attributes.disablePopupFormSetting }),
      },
      isFullHeight: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-is-full-height') === 'true',
        renderHTML: (attributes) => ({ 'data-is-full-height': attributes.isFullHeight }),
      },
      hasFakeData: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-has-fake-data') === 'true',
        renderHTML: (attributes) => ({ 'data-has-fake-data': attributes.hasFakeData }),
      },
      isPopupForm: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-is-popup-form') === 'true',
        renderHTML: (attributes) => ({ 'data-is-popup-form': attributes.isPopupForm }),
      },
      formPadding: {
        default: '16px',
        renderHTML: (attributes) => ({ 'data-form-padding': attributes.formPadding }),
        parseHTML: (element) => JSON.parse(element.getAttribute('data-form-padding') || '4px'),
      },
      formBackgroundColor: {
        default: WHITE_COLOR,
        renderHTML: (attributes) => ({ 'data-form-background-color': attributes.formBackgroundColor }),
        parseHTML: (element) => element.getAttribute('data-form-background-color') || WHITE_COLOR,
      },
      formBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-form-border-radius': attributes.formBorderRadius }),
        parseHTML: (element) => element.getAttribute('data-form-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      formBorderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-form-border-width': attributes.formBorderWidth }),
        parseHTML: (element) => element.getAttribute('data-form-border-width') || '1px',
      },
      formBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-form-border-color': attributes.formBorderColor }),
        parseHTML: (element) => element.getAttribute('data-form-border-color') || DEFAULT_BORDER_COLOR,
      },
      formBorderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-form-border-style': attributes.formBorderStyle }),
        parseHTML: (element) => element.getAttribute('data-form-border-style') || 'none',
      },
      formShadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-form-shadow'),
        renderHTML: (attributes) => ({ 'data-form-shadow': attributes.formShadow }),
      },
      gap: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      shadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      columns: {
        default: '1',
        parseHTML: (element) => element.getAttribute('data-columns'),
        renderHTML: (attributes) => ({ 'data-columns': attributes.columns }),
      },
      borderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
        parseHTML: (element) => element.getAttribute('data-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      borderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '1px',
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_BORDER_COLOR,
      },
      borderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },
      padding: {
        default: '16px',
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
        parseHTML: (element) => JSON.parse(element.getAttribute('data-padding') || '4px'),
      },
      fontFamily: {
        default: 'Inter',
        renderHTML: (attributes) => ({ 'data-font-family': attributes.fontFamily }),
        parseHTML: (element) => element.getAttribute('data-font-family') || 'Inter',
      },
      backgroundColor: {
        default: WHITE_COLOR,
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
        parseHTML: (element) => element.getAttribute('data-background-color') || WHITE_COLOR,
      },
      messageColor: {
        default: BLACK_COLOR,
        renderHTML: (attributes) => ({ 'data-message-color': attributes.messageColor }),
        parseHTML: (element) => element.getAttribute('data-message-color') || BLACK_COLOR,
      },
      nameColor: {
        default: BLACK_COLOR,
        renderHTML: (attributes) => ({ 'data-name-color': attributes.nameColor }),
        parseHTML: (element) => element.getAttribute('data-name-color') || BLACK_COLOR,
      },
      descriptionColor: {
        default: BLACK_COLOR,
        renderHTML: (attributes) => ({ 'data-description-color': attributes.descriptionColor }),
        parseHTML: (element) => element.getAttribute('data-description-color') || BLACK_COLOR,
      },
      cardStructure: {
        default: cardStructure,
        parseHTML: (element) => element.getAttribute('data-card-structure'),
        renderHTML: (attributes) => ({ 'data-card-structure': attributes.cardStructure }),
      },
      data: {
        default: [],
        parseHTML: (element) => JSON.parse(element.getAttribute('data-data') || '[]'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
      buttonBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-button-border-radius': attributes.buttonBorderRadius }),
        parseHTML: (element) => element.getAttribute('data-button-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      buttonBorderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-button-border-width': attributes.buttonBorderWidth }),
        parseHTML: (element) => element.getAttribute('data-button-border-width') || '1px',
      },
      buttonBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-button-border-color': attributes.buttonBorderColor }),
        parseHTML: (element) => element.getAttribute('data-button-border-color') || DEFAULT_BORDER_COLOR,
      },
      buttonBorderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-button-border-style': attributes.buttonBorderStyle }),
        parseHTML: (element) => element.getAttribute('data-button-border-style') || 'none',
      },
      buttonCustomBackgroundColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-custom-background-color'),
        renderHTML: (attributes) => ({
          'data-button-custom-background-color': attributes.buttonCustomBackgroundColor,
        }),
      },
      buttonCustomTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-custom-text-color'),
        renderHTML: (attributes) => ({
          'data-button-custom-text-color': attributes.buttonCustomTextColor,
        }),
      },
      buttonText: {
        default: 'Submit',
        parseHTML: (element) => element.getAttribute('data-button-text'),
        renderHTML: (attributes) => ({
          'data-button-text': attributes.buttonText,
        }),
      },
      inputBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-input-border-radius': attributes.inputBorderRadius }),
        parseHTML: (element) => element.getAttribute('data-input-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      inputBorderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-input-border-width': attributes.inputBorderWidth }),
        parseHTML: (element) => element.getAttribute('data-input-border-width') || '1px',
      },
      inputBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-input-border-color': attributes.inputBorderColor }),
        parseHTML: (element) => element.getAttribute('data-input-border-color') || DEFAULT_BORDER_COLOR,
      },
      inputBorderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-input-border-style': attributes.inputBorderStyle }),
        parseHTML: (element) => element.getAttribute('data-input-border-style') || 'none',
      },
      inputBackgroundColor: {
        default: WHITE_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-background-color'),
        renderHTML: (attributes) => ({
          'data-input-background-color': attributes.inputBackgroundColor,
        }),
      },
      inputTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-text-color'),
        renderHTML: (attributes) => ({
          'data-input-text-color': attributes.inputTextColor,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setRecommendations:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(RecommendationsView);
  },
});

export default Recommendations;
