import { CheckedState } from '@radix-ui/react-checkbox';

import { cn } from '../../../../../_utils/cn';
import { Checkbox } from '../../../../UI/Checkbox';
import { Label } from '../../../../UI/Label';
import { Text } from '../../../../UI/Text';

interface Props {
  id: string;
  name: string;
  logoUrl: string;
  isSelected: boolean;
  onCheckedChange: (value: CheckedState) => void;
  description?: string | null;
}

const RecommendationItem = ({ id, name, logoUrl, description, isSelected, onCheckedChange }: Props) => {
  return (
    <Label
      key={id}
      htmlFor={id}
      className={cn(
        'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md focus:outline-none relative overflow-hidden',
        isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
      )}
    >
      <div className="w-full h-36 overflow-hidden flex items-center justify-center">
        <Text weight="regular" variant="secondary" size="2xs" as="span" className="line-clamp-3 max-w-[200px]">
          {name}
        </Text>
      </div>
      <div className="flex justify-between items-center gap-2 p-3">
        <div className="flex gap-2 items-center">
          <div className="w-8 h-8 rounded-full overflow-hidden">
            <img src={logoUrl} alt={name} className="w-full h-full object-cover" />
          </div>
          <div className="flex flex-col">
            <Text weight="semibold" variant="primary" size="xs" as="span" className="line-clamp-1">
              {name}
            </Text>
            <Text weight="regular" variant="secondary" size="2xs" as="span" className="line-clamp-1">
              {description}
            </Text>
          </div>
        </div>
        <Checkbox
          id={id}
          className={cn(isSelected ? 'opacity-100' : 'opacity-0')}
          checked={Boolean(isSelected)}
          onCheckedChange={onCheckedChange}
        />
      </div>
    </Label>
  );
};

export default RecommendationItem;
