import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AutomationState } from '@/interfaces/automations/automation';
import { AutomationStepState } from '@/interfaces/automations/automation_step';
import api from '@/services/swarm';

export interface ReferringAutomationData {
  automation_step_id: string;
  automation_step_state: AutomationStepState;
  automation_id: string;
  automation_name: string;
  automation_description: string;
  automation_state: AutomationState;
}

const useReferringAutomations = (automationId: string) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchAutomations = () =>
    api
      .get(`/automations/${automationId}/referring`, {
        params: { publication_id: currentPublicationId },
      })
      .then((res) => res.data.automations);

  return useQuery<[ReferringAutomationData]>(['automations', automationId, 'referring'], fetchAutomations, {
    retryOnMount: false,
  });
};

export default useReferringAutomations;
