import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Input } from '@/components/Form';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography, TypographyRow } from '@/components/Typography';
import { useAuth } from '@/context/auth-context';
import analytics from '@/utils/analytics';

import { Layout, LogoWrapper } from '../_components/Layout';
import useCreatePhoneNumber from '../_hooks/useCreatePhoneNumber';

const PhoneVerification = () => {
  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const signupToken = localStorage.getItem('signupToken');
  const number = localStorage.getItem('signupPhone') || '';
  const { signup } = useAuth();
  const resendCode = useCreatePhoneNumber(signupToken);

  useEffect(() => {
    if (code && code.length === 6 && !signup.isLoading) {
      signup.mutate(
        { code, signupToken, number },
        {
          onSuccess: () => {
            analytics.track('Phone Verification', {
              phoneVerified: true,
              phone: number,
            });

            localStorage.setItem('newUser', 'true');

            navigate('/onboarding/personas');
          },
          onError: (e: any) => {
            if (e.response?.data?.status === 404) {
              toast.error('Could not find user. Please retry, or attempt to signup again.');
            } else {
              const fallbackMessage = 'Please retry, or request a new SMS code.';
              toast.error(e.response?.data?.error || fallbackMessage);
            }
          },
          onSettled: () => {
            setCode('');
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const resendVerificationCode = () => {
    resendCode.mutate(
      { number },
      {
        onError: (errPayload: any) => {
          toast.error(errPayload?.response?.data?.error || 'Something went wrong');
        },
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>Registration - beehiiv</title>
      </Helmet>

      <Layout>
        <LogoWrapper
          title="Enter code"
          subtitle={
            <Typography colorWeight="700">
              Please enter the one-time code we sent you via SMS to finish verification.
            </Typography>
          }
        >
          {signup.isLoading ? (
            <LoadingSpinner />
          ) : (
            <Input
              variant="primary"
              className="w-full"
              placeholder="000 000"
              inputClassName="text-center"
              maxLength={6}
              name="verification_code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              autoComplete="one-time-code"
            />
          )}

          <TypographyRow gap="2" className="pt-10">
            <Typography token="font-medium/text/sm">Didn&apos;t receive a verification code? </Typography>
            <button type="button" onClick={resendVerificationCode}>
              <Typography token="font-medium/text/sm" color="secondary" colorWeight="600">
                Resend code
              </Typography>
            </button>
            {resendCode.isLoading && <LoadingSpinner color="action-secondary-600" size="sm" />}
          </TypographyRow>
        </LogoWrapper>
      </Layout>
    </>
  );
};

export default PhoneVerification;
