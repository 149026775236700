import { useInfiniteQuery } from 'react-query';

import { Pagination } from '../interfaces/general';
import { PostPreview } from '../interfaces/post';
import PostSearch from '../models/postSearch';
import api from '../services/swarm';

import useCurrentPublicationId from './usePublications/useCurrentPublicationId';
import useCurrentTimeZone from './useCurrentTimeZone';

interface ApiResponse {
  posts: PostPreview[];
  pagination: Pagination;
}

export default function usePosts(query: PostSearch, reRun: boolean = false, pageSize = 10, returnAllIds = false) {
  const currentPublicationId = useCurrentPublicationId();
  const currentTimeZone = useCurrentTimeZone();

  const fetchPosts = ({ pageParam = 1 }) =>
    api
      .get(`/posts`, {
        params: {
          page: pageParam,
          per_page: pageSize,
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
          return_all_ids: returnAllIds ? 'true' : undefined,
          ...query.params(),
        },
      })
      .then((res) => res.data);

  const queryKeys = [currentPublicationId, currentTimeZone, 'posts', reRun, Object.values(query.params())].flat();

  return useInfiniteQuery<ApiResponse>(queryKeys, fetchPosts, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
