import { useParams } from 'react-router-dom';

import Card from '@/components/Card';
import { AggregateDetail, QuestionTypes } from '@/interfaces/form';
import FormTitle from '@/pages/Segments/FormTitle';
import pluralize from '@/utils/pluralize';

import { useForm, useFormInsights } from '../../../hooks/useForms';
import FormsHeader from '../Header';
import NavContainer from '../NavContainer';

import Dropdown from './Dropdown';
import FreeForm from './FreeForm';
import LongText from './LongText';
import MultipleChoice from './MultipleChoice';

interface Props {
  isNewLayout?: boolean;
}

const FormSummary = ({ isNewLayout }: Props) => {
  const { formId } = useParams();
  const { data: form } = useForm(formId || '');

  const paginatedFormResponsesQuery = useFormInsights({ search: '' || '', formId: formId || '' });
  const { data } = paginatedFormResponsesQuery;

  return (
    <div className={isNewLayout ? 'mt-40 py-5 px-4 md:px-10 md:mt-28 md:mb-8' : ''}>
      {form && data && <FormsHeader form={form} formData={data?.pages?.[0]?.form} isNewLayout={isNewLayout} />}
      <div className="bg-white border border-gray-200 rounded-md mt-7">
        {formId && <NavContainer formId={formId} isNewLayout={isNewLayout} />}
        <div className="grid place-items-center">
          <div className="bg-gray-200 w-full max-w-4xl p-3">
            {form?.aggregate_details &&
              form.form_questions.map((question) => {
                const aggregateQuestionData: AggregateDetail | undefined = form.aggregate_details.find(
                  (aggregateItem) => aggregateItem.id === question.id
                );

                return (
                  <Card className="mb-5" key={question.id}>
                    <FormTitle>{question.prompt}</FormTitle>
                    {typeof aggregateQuestionData?.response_count !== 'undefined' ? (
                      <span className="flex mb-2 text-sm">
                        {pluralize('response', aggregateQuestionData?.response_count)}
                        {question.question_type === QuestionTypes.MULTIPLE_CHOICE && question.multi_select && (
                          <span className="ml-1">
                            (with {pluralize('choice', aggregateQuestionData?.multi_select_count)} selected)
                          </span>
                        )}
                      </span>
                    ) : (
                      <span className="flex mb-2 text-sm">No responses</span>
                    )}
                    {aggregateQuestionData?.answers && question.question_type === QuestionTypes.FREE_FORM && (
                      <FreeForm answers={aggregateQuestionData?.answers} />
                    )}
                    {aggregateQuestionData?.answers && question.question_type === QuestionTypes.LONG_TEXT && (
                      <LongText answers={aggregateQuestionData?.answers} />
                    )}
                    {aggregateQuestionData?.answers && question.question_type === QuestionTypes.DROPDOWN && (
                      <Dropdown answers={aggregateQuestionData?.answers} />
                    )}
                    {aggregateQuestionData?.answers && question.question_type === QuestionTypes.MULTIPLE_CHOICE && (
                      <MultipleChoice answers={aggregateQuestionData?.answers} />
                    )}
                  </Card>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSummary;
