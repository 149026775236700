import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { LandingPage } from '@/interfaces/landing_page';
import { usePageContext } from '@/routes/website_builder/_components/Context/PageContext';
import ColorInput from '@/routes/website_builder/_components/Form/ColorInput';
import FontForm from '@/routes/website_builder/_components/Form/PageSpecific/FontForm';
import ImageForm from '@/routes/website_builder/_components/Form/PageSpecific/ImageForm';
import SignupForm from '@/routes/website_builder/_components/Form/PageSpecific/SignupForm';
import SelectInput from '@/routes/website_builder/_components/Form/SelectInput';
import SwitchInput from '@/routes/website_builder/_components/Form/SwitchInput';
import {
  HeroSectionIcon,
  SectionGroupingSlideOut,
} from '@/routes/website_builder/_components/Helpers/SectionGroupingSlideOut';
import { OPACITY_OPTIONS } from '@/routes/website_builder/_types/options';
import api from '@/services/swarm';

import PropertiesGrouping from '../../Helpers/PropertiesGrouping';

interface Props {
  handleSaveMainSection: (payload: any) => void;
  landingPage: LandingPage;
}

const HeroSection = ({ handleSaveMainSection, landingPage }: Props) => {
  const queryClient = useQueryClient();
  const { handleSaved, currentPublication, setIsSaving } = usePageContext();

  const handleUploadImage = (type: string, file: File | null) => {
    setIsSaving(true);
    const formData = new FormData();

    if (typeof file !== 'string') {
      formData.append(`landing_page[${type}]`, file || '');
    }

    formData.append('publication_id', currentPublication?.id || '');

    api
      .patch(`/landing_pages/${landingPage?.id}`, formData)
      .then(() => {
        queryClient.invalidateQueries(['landing_page', '/subscribe', 'draft']);
        handleSaved();
      })
      .catch((err) => {
        setIsSaving(false);
        toast.error(err?.response?.data?.error || 'Something went wrong');
      });
  };

  const mainSectionWithDefaults = landingPage.main_section_with_defaults;

  return (
    <SectionGroupingSlideOut
      title="Hero Section"
      showPlanBadge
      sectionIcon={<HeroSectionIcon />}
      highlightProps={{
        sectionId: 'signup-hero-section',
      }}
    >
      <ImageForm
        label="Background"
        dimensions="Upload a high-quality 1920px x 1080px PNG for best results."
        saveKey="main_bg_image"
        handleUploadImage={handleUploadImage}
        imageValue={landingPage?.main_bg_image_url || ''}
      >
        <ColorInput
          label="Color"
          color={mainSectionWithDefaults.bg_color}
          resettable={{
            label: 'Global Background Color',
            resetValue: null,
            check: landingPage?.main_section.bg_color !== null,
          }}
          onSave={(color: string) => {
            handleSaveMainSection({
              ...landingPage?.main_section,
              bg_color: color,
            });
          }}
        />
        <SelectInput
          label="Gloss Opacity"
          options={OPACITY_OPTIONS}
          value={mainSectionWithDefaults.bg_gloss_opacity}
          onSave={(value: string) => {
            handleSaveMainSection({
              ...landingPage?.main_section,
              bg_gloss_opacity: value,
            });
          }}
        />
        <SwitchInput
          label="Full Height"
          name="main-full-height-disabled"
          enabled={mainSectionWithDefaults.disable_full_height}
          onSave={(value: boolean) => {
            handleSaveMainSection({
              ...landingPage?.main_section,
              disable_full_height: value,
            });
          }}
        />
      </ImageForm>
      <ImageForm
        label="Image"
        tooltip={{
          id: 'main_image',
          text: 'You can upload an additional image to be displayed on the right-hand side of the hero section.',
        }}
        saveKey="main_image"
        dimensions="Upload a high-quality PNG for best results."
        handleUploadImage={handleUploadImage}
        hasChildren={false}
        imageValue={landingPage?.main_image_url || ''}
      />
      <PropertiesGrouping label="Logo">
        <SwitchInput
          label="Logo Enabled"
          name="main-section-logo-enabled"
          enabled={mainSectionWithDefaults.logo_enabled}
          onSave={(value: boolean) => {
            handleSaveMainSection({
              ...landingPage?.main_section,
              logo_enabled: value,
            });
          }}
        />
      </PropertiesGrouping>
      <FontForm
        nodeId="overline-heading"
        label="Overline"
        supportsMarkdown
        textValue={mainSectionWithDefaults.overline}
        onSaveText={(value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            overline: value,
          });
        }}
        onSave={(key, value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            [key]: value,
          });
        }}
        editableProps={{
          overline_color: {
            type: 'colorInput',
            value: mainSectionWithDefaults.overline_color,
            resetCheckValue: landingPage.main_section.overline_color,
            resetLabel: 'Global Overline Color',
          },
          overline_font_family: {
            type: 'fontFamilySelect',
            value: mainSectionWithDefaults.overline_font_family,
            resetCheckValue: landingPage.main_section.overline_font_family,
            resetLabel: 'Global Heading Font',
          },
          overline_font_size: { type: 'fontSizeSelect', value: mainSectionWithDefaults.overline_font_size },
        }}
      />
      <FontForm
        nodeId="headline"
        label="Headline"
        supportsMarkdown
        textValue={mainSectionWithDefaults.headline}
        onSaveText={(value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            headline: value,
          });
        }}
        onSave={(key, value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            [key]: value,
          });
        }}
        editableProps={{
          headline_color: {
            type: 'colorInput',
            value: mainSectionWithDefaults.headline_color,
            resetCheckValue: landingPage.main_section.headline_color,
            resetLabel: 'Global Headline Color',
          },
          headline_font_family: {
            type: 'fontFamilySelect',
            value: mainSectionWithDefaults.headline_font_family,
            resetCheckValue: landingPage.main_section.headline_font_family,
            resetLabel: 'Global Heading Font',
          },
          headline_font_size: { type: 'fontSizeSelect', value: mainSectionWithDefaults.headline_font_size },
        }}
      />
      <FontForm
        nodeId="description"
        label="Description"
        rows={3}
        supportsMarkdown
        textValue={mainSectionWithDefaults.description}
        onSaveText={(value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            description: value,
          });
        }}
        onSave={(key, value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            [key]: value,
          });
        }}
        editableProps={{
          description_color: {
            type: 'colorInput',
            value: mainSectionWithDefaults.description_color,
            resetCheckValue: landingPage.main_section.description_color,
            resetLabel: 'Global Body Color',
          },
          description_font_family: {
            type: 'fontFamilySelect',
            value: mainSectionWithDefaults.description_font_family,
            resetCheckValue: landingPage.main_section.description_font_family,
            resetLabel: 'Global Body Font',
          },
          description_font_size: { type: 'fontSizeSelect', value: mainSectionWithDefaults.description_font_size },
        }}
      />
      <SignupForm
        label="Signup Widget"
        onSave={(key, value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            [key]: value,
          });
        }}
        textValue={mainSectionWithDefaults.signup_cta_text}
        onSaveText={(value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            signup_cta_text: value,
          });
        }}
        placeholderTextValue={mainSectionWithDefaults.signup_placeholder_text}
        onSavePlaceholderText={(value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            signup_placeholder_text: value,
          });
        }}
        editableProps={{
          signup_bg_color: {
            type: 'colorInput',
            label: 'Background',
            value: mainSectionWithDefaults.signup_bg_color,
            resetCheckValue: landingPage.main_section.signup_bg_color,
            resetLabel: 'Subscribe Widget Background Color',
          },
          signup_border_color: {
            type: 'colorInput',
            label: 'Border',
            value: mainSectionWithDefaults.signup_border_color,
            resetCheckValue: landingPage.main_section.signup_border_color,
            resetLabel: 'Subscribe Widget Border Color',
          },
          signup_cta_font_family: {
            type: 'fontFamilySelect',
            label: 'Font Family',
            value: mainSectionWithDefaults.signup_cta_font_family,
            resetCheckValue: landingPage.main_section.signup_cta_font_family,
            resetLabel: 'Subscribe Widget Font Family',
          },
          signup_text_color: {
            type: 'colorInput',
            label: 'Text',
            value: mainSectionWithDefaults.signup_text_color,
            resetCheckValue: landingPage.main_section.signup_text_color,
            resetLabel: 'Subscribe Widget Button Text Color',
          },
        }}
      />
      <FontForm
        nodeId="underline"
        label="Underline"
        supportsMarkdown
        textValue={mainSectionWithDefaults.underline}
        onSaveText={(value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            underline: value,
          });
        }}
        onSave={(key, value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            [key]: value,
          });
        }}
        editableProps={{
          underline_color: {
            type: 'colorInput',
            value: mainSectionWithDefaults.underline_color,
            resetCheckValue: landingPage.main_section.underline_color,
            resetLabel: 'Global Underline Color',
          },
          underline_font_family: {
            type: 'fontFamilySelect',
            value: mainSectionWithDefaults.underline_font_family,
            resetCheckValue: landingPage.main_section.underline_font_family,
            resetLabel: 'Global Body Font',
          },
          underline_font_size: {
            type: 'fontSizeSelect',
            value: mainSectionWithDefaults.underline_font_size,
          },
        }}
      />
      <FontForm
        nodeId="read-more-cta"
        label="Redirect Link"
        textValue={mainSectionWithDefaults.read_more_cta}
        onSaveText={(value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            read_more_cta: value,
          });
        }}
        onSave={(key, value) => {
          handleSaveMainSection({
            ...landingPage?.main_section,
            [key]: value,
          });
        }}
        editableProps={{
          read_more_cta_enabled: { type: 'switch', value: mainSectionWithDefaults.read_more_cta_enabled },
          read_more_cta_color: {
            type: 'colorInput',
            value: mainSectionWithDefaults.read_more_cta_color,
            resetCheckValue: landingPage.main_section.read_more_cta_color,
            resetLabel: 'Global Body Color',
          },
          read_more_cta_font_family: {
            type: 'fontFamilySelect',
            value: mainSectionWithDefaults.read_more_cta_font_family,
            resetCheckValue: landingPage.main_section.read_more_cta_font_family,
            label: 'Global Button Font',
          },
          read_more_cta_font_size: { type: 'fontSizeSelect', value: mainSectionWithDefaults.read_more_cta_font_size },
        }}
      />
    </SectionGroupingSlideOut>
  );
};

export default HeroSection;
