import { useMemo } from 'react';
import { FireIcon } from '@heroicons/react/20/solid';
import { Color } from '@tremor/react';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import ChartHeader from '@/components/Chartsv2/ChartHeader';
import { Color as DesignTokenColor } from '@/components/DesignTokens';
import useReferralsPerPeriod from '@/hooks/boosts/monetize/useReferralsPerPeriod';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Option } from '@/interfaces/general';
import { TimePeriod } from '@/interfaces/time_period';
import pluralize from '@/utils/pluralize';
import { timePeriodLabels } from '@/utils/timePeriods';

interface Props {
  boost_agreement_id?: string;
  alternatePeriod?: TimePeriod;
}

const REFERRALS_CATEGORY: Option = {
  value: 'referrals',
  label: 'Referrals',
};
const PREVIOUS_REFERRALS_CATEGORY: Option = {
  value: 'previous_referrals',
  label: 'Previous Referrals',
};

const CHART_CATEGORIES = [REFERRALS_CATEGORY.label, PREVIOUS_REFERRALS_CATEGORY.label];
const CHART_COLORS: Color[] = ['violet', 'gray'];

const ReferralsPerPeriodChart = ({ boost_agreement_id, alternatePeriod }: Props) => {
  const { period, TimePeriodSelect } = useTimePeriodSelect();
  const { data: referralsData, isLoading: isReferralsDataLoading } = useReferralsPerPeriod({
    period: (alternatePeriod || period) as keyof typeof timePeriodLabels,
    boost_agreement_id,
  });

  const totalReferrals =
    useMemo(() => {
      return referralsData?.metrics.reduce((pv, cv) => pv + cv.referrals, 0);
    }, [referralsData]) || 0;

  const mappedChartData = useMemo(() => {
    if (isReferralsDataLoading || typeof referralsData !== 'object') {
      return [];
    }

    return referralsData?.metrics.map((row) => {
      return {
        date: row.name,
        [REFERRALS_CATEGORY.label]: row.referrals,
        [PREVIOUS_REFERRALS_CATEGORY.label]: row.previous_referrals,
      };
    });
  }, [referralsData, isReferralsDataLoading]);

  return (
    <ChartCard
      noResultsText={
        isReferralsDataLoading ? 'Fetching...' : 'No referrals data available within the chosen time range.'
      }
      noResults={mappedChartData.length === 0}
    >
      <div className="flex flex-col gap-y-5">
        <ChartHeader
          title="New subscribers from Boosts"
          description="Breakdown over time of how many subscribers you have driven to other publishers."
          kpiLabel={pluralize('new subscriber', totalReferrals, undefined, true)}
          kpiValue={totalReferrals}
          timePeriodSelector={<TimePeriodSelect />}
          Icon={FireIcon}
          iconColor="text-action-tertiary-500"
          textColor={DesignTokenColor.TERTIARY}
        />
        <AreaChart
          index="date"
          data={mappedChartData}
          categories={CHART_CATEGORIES}
          colors={CHART_COLORS}
          showLegend={false}
          showXAxis
          showYAxis
          showGridLines
        />
      </div>
    </ChartCard>
  );
};

export default ReferralsPerPeriodChart;
