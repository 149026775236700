import { PieChart, PieChartDatum as Datum } from '@/components/charts';
import { AggregateAnswer } from '@/interfaces/form';

interface Props {
  answers: AggregateAnswer[];
}

const MultipleChoice = ({ answers }: Props) => {
  const transformedAnswers: Datum[] = answers.map((answer) => ({ label: answer.value, value: answer.count }));
  const totalCount = answers.reduce((sum, currentAnswer) => sum + currentAnswer.count, 0);

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    border: {
      display: false,
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `  ${((context.parsed / totalCount) * 100).toFixed(1)}%`;
          },
        },
      },
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          generateLabels: (chart: any) => {
            const { datasets } = chart.data;
            return datasets[0].data.map((data: any, i: number) => ({
              text: `${chart.data.labels[i]} (${data})`,
              fillStyle: datasets[0].backgroundColor[i],
              index: i,
            }));
          },
        },
      },
    },
  };

  return (
    <div className="grid place-items-center">
      <div className="w-2/3 h-[300px] pr-10">
        <PieChart data={transformedAnswers} options={pieChartOptions} />
      </div>
    </div>
  );
};

export default MultipleChoice;
