import { List } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';

type Props = AttributeSettingProps & {};

export const DescriptionSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Description" text="Description" popoverTitle="Description" Icon={List}>
      <>
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          property="descriptionFontFamily"
          title="Font"
        />
        <SimpleFontSizeSettings
          title="Size"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="descriptionFontSize"
        />
        <ColorSettings title="Color" editor={editor} activeNodeResult={activeNodeResult} property="descriptionColor" />
      </>
    </PopoverHelper>
  );
};

export default DescriptionSettings;
