import { useMutation } from 'react-query';

import api from '@/services/swarm';

import type {
  CheckExistingRecordsErrorResponse,
  CheckExistingRecordsPayload,
  CheckExistingRecordsSuccessResponse,
} from './types';

const useCheckExistingRecords = (publicationId: string, domain: string, dmarc_check: boolean) => {
  return useMutation<
    CheckExistingRecordsSuccessResponse,
    CheckExistingRecordsErrorResponse,
    CheckExistingRecordsPayload
  >(() =>
    api
      .post('/custom_domains/check_existing_records', {
        publication_id: publicationId,
        domain,
        dmarc_check,
      })
      .then((res) => res.data)
  );
};

export default useCheckExistingRecords;
