export const MessageTop = {
  name: 'Message Top',
  value: {
    node: 'div',
    className: 'flex flex-col gap-3 w-full w-full',
    children: [
      {
        node: 'div',
        className: 'flex gap-2',
        children: [
          {
            node: 'div',
            className: 'flex flex-col gap-4 justify-between',
            children: [
              {
                node: 'message',
                className: '',
                children: [],
              },
              {
                node: 'div',
                className: 'flex gap-2',
                children: [
                  {
                    node: 'div',
                    className: 'w-10 h-10 rounded-full overflow-hidden',
                    children: [
                      {
                        node: 'image',
                        className: 'w-full h-full object-cover',
                        children: [],
                      },
                    ],
                  },
                  {
                    node: 'div',
                    className: 'flex flex-col items-start gap-1',
                    children: [
                      {
                        node: 'name',
                        className: '',
                        children: [],
                      },
                      {
                        node: 'description',
                        className: '',
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export const MessageBottom = {
  name: 'Message Bottom',
  value: {
    node: 'div',
    className: 'flex flex-col gap-3 w-full w-full',
    children: [
      {
        node: 'div',
        className: 'flex gap-2',
        children: [
          {
            node: 'div',
            className: 'flex flex-col gap-4 justify-between',
            children: [
              {
                node: 'div',
                className: 'flex gap-2',
                children: [
                  {
                    node: 'div',
                    className: 'w-10 h-10 rounded-full overflow-hidden',
                    children: [
                      {
                        node: 'image',
                        className: 'w-full h-full object-cover',
                        children: [],
                      },
                    ],
                  },
                  {
                    node: 'div',
                    className: 'flex flex-col items-start gap-1',
                    children: [
                      {
                        node: 'name',
                        className: '',
                        children: [],
                      },
                      {
                        node: 'description',
                        className: '',
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                node: 'message',
                className: '',
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
};

export const Stacked = {
  name: 'Stacked',
  value: {
    node: 'div',
    className: 'flex flex-col gap-3 w-full w-full',
    children: [
      {
        node: 'div',
        className: 'flex gap-2',
        children: [
          {
            node: 'div',
            className: 'flex flex-col gap-4 justify-between',
            children: [
              {
                node: 'div',
                className: 'flex gap-2',
                children: [
                  {
                    node: 'div',
                    className: 'flex flex-col items-start gap-1',
                    children: [
                      {
                        node: 'image',
                        className: 'w-full h-full object-cover',
                        children: [],
                      },
                      {
                        node: 'name',
                        className: '',
                        children: [],
                      },
                      {
                        node: 'description',
                        className: '',
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                node: 'message',
                className: '',
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
};
