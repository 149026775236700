import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import {
  BanknotesIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckIcon,
  ClockIcon,
  DocumentChartBarIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import useNotificationReadStatusUpdate from '@/hooks/useNotificationReadStatusUpdate';
import {
  isAdNetworkNewOpportunitiesNotification,
  isAdNetworkOpportunityAcceptedNotification,
  isAdNetworkOpportunityCanceledNotification,
  isAdNetworkOpportunityExpiredNotification,
  isAdNetworkOpportunityPendingNotification,
  isAdNetworkOpportunityRejectedNotification,
  isAdNetworkReportCreatedNotification,
  Notification,
} from '@/interfaces/notification';

const NotificationIcon = ({ notification }: { notification: Notification }) => {
  if (isAdNetworkNewOpportunitiesNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-feedback-info-50 rounded-full relative">
        <BanknotesIcon className="w-5 h-5 text-feedback-info-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityPendingNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-primary-50 rounded-full relative">
        <BanknotesIcon className="w-5 h-5 text-primary-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityAcceptedNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-green-50 rounded-full relative">
        <CheckIcon className="w-5 h-5 text-green-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityRejectedNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-gray-50 rounded-full relative">
        <XMarkIcon className="w-5 h-5 text-gray-600 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityExpiredNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-gray-100 rounded-full relative">
        <ClockIcon className="w-5 h-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkOpportunityCanceledNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-gray-100 rounded-full relative">
        <InformationCircleIcon className="w-5 h-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  if (isAdNetworkReportCreatedNotification(notification)) {
    return (
      <div className="w-8 h-8 bg-feedback-info-50 rounded-full relative">
        <DocumentChartBarIcon className="w-5 h-5 text-feedback-info-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    );
  }

  return (
    <div className="w-8 h-8 bg-gray-100 rounded-full relative">
      <ChatBubbleBottomCenterTextIcon className="w-5 h-5 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
    </div>
  );
};

const NotificationItem = ({ notification }: { notification: Notification }) => {
  const { mutateAsync: markAsRead } = useNotificationReadStatusUpdate({ notification });
  const navigate = useNavigate();

  const handleClick = async () => {
    markAsRead({ read: true }).then(() => {
      navigate(notification.url);
    });
  };

  return (
    <li className="hover:bg-gray-50 first:rounded-t-md last:rounded-b-md relative">
      <button type="button" onClick={handleClick} className="flex items-start space-x-2 p-2 w-full">
        {notification.read_at ? (
          <div className="inline-block flex-shrink-0 w-2 h-2 rounded-full my-3" />
        ) : (
          <div className="inline-block flex-shrink-0 w-2 h-2 bg-feedback-info-500 rounded-full my-3" />
        )}
        <div className="space-y-1">
          <NotificationIcon notification={notification} />
          <p className="text-left">
            <ReactMarkdown>{notification.message}</ReactMarkdown>
          </p>
        </div>
        <p className="text-sm absolute top-2 right-4 text-gray-400">
          {moment(notification?.created_at).fromNow() || moment().fromNow()}
        </p>
      </button>
    </li>
  );
};

export default NotificationItem;
