import { Option } from '../interfaces/general';

/**
 * Converts a field and array of messages into a single sentence.
 *
 * @example optionDataToOptions(data: { options: [{id: 1, name: 'Example}]}) => [{label: "Example", value: 1}]
 * @param optionsData The response from the useOptions hook
 * @returns {Option[]}
 */
const optionDataToOptions = (optionsData: any): Option[] =>
  optionsData?.data?.options?.map((option: any) => ({ label: option?.name, value: option?.id })) || [];

export default optionDataToOptions;
