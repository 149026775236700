import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';

import AvailableEmailBoosts from './AvailableEmailBoosts';
import BoostedPublicationsList from './BoostedPublicationsList';
import BoostInvitationsCard from './BoostInvitationsCard';

const Overview = () => {
  const { data: currentPublication } = useCurrentPublication();
  const { settings } = useSettings();
  const [currentPublicationId] = useCurrentPublicationState();

  const hasBoostsPlus = settings?.boosts_plus === true;
  const hasBoostInvites = settings?.boosts_invites === true;

  return (
    <>
      {hasBoostInvites && currentPublication && <BoostInvitationsCard />}
      {hasBoostsPlus && <AvailableEmailBoosts />}
      <BoostedPublicationsList publicationId={currentPublicationId} />
    </>
  );
};

export default Overview;
