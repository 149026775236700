export const cities = [
  {
    maxSubs: 100,
    name: 'Monowi, Nebraska',
    image: '/rewind-2024/images/cities/Monowi, Nebraska.webp',
    description: 'the smallest town in the U.S.',
  },
  {
    maxSubs: 500,
    name: 'Lost Springs, Wyoming',
    image: '/rewind-2024/images/cities/Lost Springs, Wyoming.webp',
    description: 'a small community with room to grow!',
  },
  {
    maxSubs: 1000,
    name: 'Talkeetna, Alaska',
    image: '/rewind-2024/images/cities/Talkeetna, Alaska.webp',
    description: 'a picturesque mountain town.',
  },
  {
    maxSubs: 2000,
    name: 'Greensboro, Vermont',
    image: '/rewind-2024/images/cities/Greensboro, Vermont.webp',
  },
  {
    maxSubs: 3000,
    name: 'Hope, Arkansas',
    image: '/rewind-2024/images/cities/Hope, Arkansas.webp',
    description: 'a small town with big charm.',
  },
  {
    maxSubs: 4000,
    name: 'Springdale, Utah',
    image: '/rewind-2024/images/cities/Springdale, Utah.webp',
    description: 'the gateway to Zion National Park.',
  },
  {
    maxSubs: 5000,
    name: 'Fruita, Colorado',
    image: '/rewind-2024/images/cities/Fruita, Colorado.webp',
    description: 'known for mountain biking adventures.',
  },
  {
    maxSubs: 10_000,
    name: 'Crested Butte, Colorado',
    image: '/rewind-2024/images/cities/Crested Butte, Colorado.webp',
  },
  {
    maxSubs: 20_000,
    name: 'Sedona, Arizona',
    image: '/rewind-2024/images/cities/Sedona, Arizona.webp',
    description: 'famous for its red rock beauty.',
  },
  {
    maxSubs: 30_000,
    name: 'Bar Harbor, Maine',
    image: '/rewind-2024/images/cities/Bar Harbor, Maine.webp',
    description: 'the entrance to Acadia National Park.',
  },
  {
    maxSubs: 40_000,
    name: 'San Luis Obispo, California',
    image: '/rewind-2024/images/cities/San Luis Obispo, California.webp',
  },
  {
    maxSubs: 50_000,
    name: 'Flagstaff, Arizona',
    image: '/rewind-2024/images/cities/Flagstaff, Arizona.webp',
    description: 'a bustling mountain city.',
  },
  {
    maxSubs: 60_000,
    name: 'Manhattan Beach, California',
    image: '/rewind-2024/images/cities/Manhattan Beach, California.webp',
    description: 'a vibrant coastal town.',
  },
  {
    maxSubs: 70_000,
    name: 'Tigard, Oregon',
    image: '/rewind-2024/images/cities/Tigard, Orego.webp',
    description: 'a lively suburb of Portland.',
  },
  {
    maxSubs: 80_000,
    name: 'Boca Raton, Florida',
    image: '/rewind-2024/images/cities/Boca Raton, Florida.webp',
    description: 'a sun-soaked coastal city.',
  },
  {
    maxSubs: 90_000,
    name: 'Napa, California',
    image: '/rewind-2024/images/cities/Napa, California.webp',
    description: 'the heart of wine country.',
  },
  {
    maxSubs: 100_000,
    name: 'Santa Barbara, California',
    image: '/rewind-2024/images/cities/Santa Barbara, California.webp',
    description: 'a scenic oceanside city.',
  },
  {
    maxSubs: 150_000,
    name: 'Pasadena, California',
    image: '/rewind-2024/images/cities/Pasadena, California.webp',
    description: 'known for the Rose Parade.',
  },
  {
    maxSubs: 200_000,
    name: 'Reno, Nevada',
    image: '/rewind-2024/images/cities/reno.webp',
  },
  {
    maxSubs: 250_000,
    name: 'Baton Rouge, Louisiana',
    image: '/rewind-2024/images/cities/Baton Rouge, Louisiana.webp',
    description: 'the capital city.',
  },
  {
    maxSubs: 300_000,
    name: 'Pittsburgh, Pennsylvania',
    image: '/rewind-2024/images/cities/pittsburgh.webp',
    description: 'the steel city!',
  },
  {
    maxSubs: 350_000,
    name: 'Minneapolis, Minnesota.',
    image: '/rewind-2024/images/cities/Minneapolis, Minnesota.webp',
  },
  {
    maxSubs: 400_000,
    name: 'Tampa, Florida',
    image: '/rewind-2024/images/cities/Tampa, Florida.webp',
    description: 'a Gulf Coast metropolis.',
  },
  {
    maxSubs: 450_000,
    name: 'Miami, Florida',
    image: '/rewind-2024/images/cities/Miami, Florida.webp',
    description: 'known for its vibrant culture.',
  },
  {
    maxSubs: 500_000,
    name: 'Atlanta, Georgia',
    image: '/rewind-2024/images/cities/Atlanta, Georgia.webp',
    description: 'a major urban hub.',
  },
  {
    maxSubs: 750_000,
    name: 'Denver, Colorado',
    image: '/rewind-2024/images/cities/Denver, Colorado.webp',
    description: 'nestled in the Rocky Mountains.',
  },
  {
    maxSubs: 1_000_000,
    name: 'San Jose, California!',
    image: '/rewind-2024/images/cities/San Jose, California.webp',
  },
  {
    maxSubs: 1_500_000,
    name: 'Phoenix, Arizona',
    image: '/rewind-2024/images/cities/Phoenix, Arizona.webp',
    description: 'one of the fastest-growing cities.',
  },
  {
    maxSubs: 2_000_000,
    name: 'Houston, Texas',
    image: '/rewind-2024/images/cities/Houston, Texas.webp',
    description: 'a sprawling urban center.',
  },
  {
    maxSubs: 2_500_000,
    name: 'Chicago, Illinois',
    image: '/rewind-2024/images/cities/chicago.webp',
    description: 'the Windy City!',
  },
  {
    maxSubs: 2_500_000,
    name: 'Los Angeles, California!',
    image: '/rewind-2024/images/cities/LA.webp',
  },
];
