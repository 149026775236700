import { Input } from '@/components/Form';
import { FormQuestion } from '@/interfaces/form';

interface Props {
  question: FormQuestion;
}

const FreeFormPreview = ({ question }: Props) => {
  const placeholders: any = {
    date: 'Please select a date',
    datetime: 'Please select a time',
    default: 'Your answer goes here',
    integer: 'Please add a numeric answer',
    string: 'Please type your response here',
  };

  return <Input name={question.id} placeholder={placeholders[question.custom_field.kind]} />;
};

export default FreeFormPreview;
