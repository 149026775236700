import { useQuery } from 'react-query';

import api from '@/services/swarm';

interface ApiResponse {
  total_count: number;
  success_count: number;
  failed_count: number;
  total_amount: string;
  max_approve_amount: string;
  limited: boolean;
  start_date: string | null;
  end_date: string | null;
}

export default function useDisbursementApproveAllPreview({
  maximum_amount_cents,
  start_date,
  end_date,
}: {
  maximum_amount_cents: number;

  start_date: Date | null;
  end_date: Date | null;
}) {
  const previewApproval = (): Promise<ApiResponse> =>
    api
      .get(`/ad_network/internal/disbursements/approve_all/preview`, {
        params: {
          maximum_amount_cents,
          start_date,
          end_date,
        },
      })
      .then((res) => res.data);

  return useQuery(
    ['ad_network', 'internal', 'disbursement', 'approve_all', 'preview', maximum_amount_cents],
    previewApproval,
    {
      keepPreviousData: true,
    }
  );
}
