import { ChangeEvent, useState } from 'react';

import { Button } from '@/ui/Button';

import useFilteringContext from '../filtering-context';
import { ConditionGroup, NumberOperator, NumberOperatorsList } from '../types';

const SentPostsCountFilterDetails = ({ group }: { group: ConditionGroup }) => {
  const operators = NumberOperatorsList;

  const [selectedOperator, setSelectedOperator] = useState<NumberOperator>(operators[0].value);
  const [sentPostsCountValue, setSentPostsCountValue] = useState('');
  const [secondsValue, setSecondsValue] = useState(0);
  const SECONDS_PER_DAY = 86400;

  const convertSecondsToDays = (seconds: string | number) => {
    if (typeof seconds === 'string') {
      const numSeconds = Number(seconds);
      return Math.floor(numSeconds / SECONDS_PER_DAY);
    }

    return Math.floor(seconds / SECONDS_PER_DAY);
  };

  const { addCondition, handleClose } = useFilteringContext();

  const handleApply = () => {
    addCondition(
      {
        type: 'measure',
        name: 'sent_posts_count',
        filters: { operator: selectedOperator, value: Number(sentPostsCountValue) },
        refine: { within: Number(secondsValue) },
      },
      group.uuid
    );

    handleClose();
  };

  const handleChangeInSecondsValue = (e: ChangeEvent<HTMLInputElement>) => {
    const changedValue = parseInt(e.target.value, 10);
    setSecondsValue(changedValue * SECONDS_PER_DAY);
  };

  return (
    <form
      className="absolute z-10 mt-2 bg-white border border-gray-300 shadow rounded-md text-sm left-0 w-max text-gray-600 p-4 space-y-2"
      onSubmit={handleApply}
    >
      <div className="space-x-2">
        <p className="inline">Posts sent</p>
        <select
          className="appearance-none outline-none border rounded-md text-xs border-gray-300"
          onChange={(e) => setSelectedOperator(e.target.value as any)}
          value={selectedOperator}
          required
        >
          {operators.map((operator) => {
            return (
              <option key={operator.value} value={operator.value}>
                {operator.label}
              </option>
            );
          })}
        </select>
        <input
          type="number"
          className="appearance-none outline-none border rounded-md text-xs border-gray-300 placeholder:text-gray-400"
          onChange={(e) => setSentPostsCountValue(e.target.value)}
          value={sentPostsCountValue}
          required
        />
      </div>
      <div className="space-x-2">
        <p className="inline">Within the last</p>
        <input
          type="number"
          className="appearance-none outline-none border rounded-md text-xs border-gray-300 placeholder:text-gray-400 w-16"
          onChange={handleChangeInSecondsValue}
          value={convertSecondsToDays(secondsValue)}
          required
        />
        <p className="inline">days</p>
      </div>
      <Button variant="primary-inverse" type="submit" size="xs">
        Apply
      </Button>
    </form>
  );
};

export default SentPostsCountFilterDetails;
