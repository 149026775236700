import { Outlet, useNavigate } from 'react-router-dom';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import { useReferralProgram } from '@/hooks/useReferralProgram';
import { useTutorial } from '@/hooks/useTutorials';
import { TutorialType } from '@/interfaces/tutorial';

import HeaderActions from './components/HeaderActions';

const Layout = () => {
  const { data: referralProgram } = useReferralProgram();
  const isReferralProgramActive = !referralProgram?.disabled_at;
  const navigate = useNavigate();
  const tutorial = useTutorial(TutorialType.REFERRAL_PROGRAM);

  return (
    <>
      <PageHeading
        title="Referral Program"
        description="Drive subscriber growth through audience referrals"
        badgeText={isReferralProgramActive ? 'Active' : 'Inactive'}
        badgeType={isReferralProgramActive ? 'success' : undefined}
        badgeTooltipText={
          !isReferralProgramActive
            ? 'Activate your Referral Program by toggling it on in the configuration tab'
            : undefined
        }
        onBadgeClick={() => navigate('/referral_program?tab=configuration')}
        tutorial={tutorial}
      >
        <HeaderActions />
      </PageHeading>
      <Outlet />
    </>
  );
};

export default Layout;
