import React, { FC } from 'react';
import { TrashIcon } from '@heroicons/react/20/solid';

import { Input } from '@/components/Form';
import Text from '@/components/Text';
import { Condition, Refine } from '@/interfaces/segment';

import PostSelect from '../../../components/PostSelect';

import { isEmailClick } from './operatorOptions';

const SECONDS_PER_DAY = 86400;

const convertSecondsToDays = (seconds: string | number) => {
  const numSeconds = Number(seconds);
  return Math.floor(numSeconds / SECONDS_PER_DAY);
};

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleRefineChange: (refine: Refine, blockIndex: number, subIndex?: number) => void;
  handleDeleteRefine: (blockIndex: number, subIndex?: number) => void;
}

interface RefineProps {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleRefineChange: (refine: Refine, blockIndex: number, subIndex?: number) => void;
}

const RefineClick: FC<RefineProps> = ({ condition, index, subIndex, handleRefineChange }) => {
  return (
    <>
      <Text className="mr-2" size="sm">
        with the link
      </Text>
      <Input
        className="w-56"
        name="value"
        type="text"
        placeholder="mycoolsite.com"
        required
        value={condition?.refine?.url || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const url = e.target.value || '';

          const newRefine = {
            ...condition.refine,
            url,
          };

          handleRefineChange(newRefine, index, subIndex);
        }}
      />
    </>
  );
};

const RefineWithin: FC<RefineProps> = ({ condition, index, subIndex, handleRefineChange }) => {
  return (
    <>
      <Text className="mr-2 ml-1" size="sm">
        within the last
      </Text>
      <Input
        className="w-16"
        name="value"
        type="number"
        min="0"
        required
        value={condition.refine?.within ? `${convertSecondsToDays(condition?.refine?.within)}` : ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const updatedRefine = {
            within: Number(e.target.value) * SECONDS_PER_DAY,
          };

          const newRefine = { ...condition.refine, ...updatedRefine };

          handleRefineChange(newRefine, index, subIndex);
        }}
      />
      <Text className="ml-2" size="sm">
        days
      </Text>
    </>
  );
};

const RefineFromPost: FC<RefineProps> = ({ condition, index, subIndex, handleRefineChange }) => {
  return (
    <>
      <Text className="mr-2 ml-1" size="sm">
        when sent in
      </Text>
      <PostSelect
        className="w-1/2"
        labelText=""
        postId={condition.refine?.fromPost || ''}
        onSelectPost={(postId: string) => {
          const newRefine = {
            ...condition.refine,
            fromPost: postId,
          };

          handleRefineChange(newRefine, index, subIndex);
        }}
        onClearPost={() => {
          const updatedRefine = {};
          const newRefine = { ...condition.refine, ...updatedRefine };
          handleRefineChange(newRefine, index, subIndex);
        }}
      />
    </>
  );
};

const ConditionRefine: FC<Props> = ({ condition, handleRefineChange, handleDeleteRefine, index, subIndex }) => {
  return (
    <div className="flex items-center">
      {isEmailClick(condition) && condition.name !== 'poll_response' && (
        <RefineClick condition={condition} handleRefineChange={handleRefineChange} index={index} subIndex={subIndex} />
      )}

      {!isEmailClick(condition) && condition.name !== 'poll_response' && (
        <RefineWithin condition={condition} index={index} subIndex={subIndex} handleRefineChange={handleRefineChange} />
      )}
      {condition.name === 'poll_response' && (
        <RefineFromPost
          condition={condition}
          index={index}
          subIndex={subIndex}
          handleRefineChange={handleRefineChange}
        />
      )}
      <button type="button" onClick={() => handleDeleteRefine(index, subIndex)} className="p-2">
        <TrashIcon className="text-gray-400 hover:text-gray-800 " width="15" height="15" />
      </button>
    </div>
  );
};

export default ConditionRefine;
