import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '../../services/swarm';

interface Props {
  keyId: string | undefined;
  confirmCharacters?: string;
}

const useDeleteApiKey = ({ keyId, confirmCharacters }: Props, publicationId: string) => {
  const queryClient = useQueryClient();

  const params = {
    publication_id: publicationId,
    confirm_characters: confirmCharacters,
  };

  return useMutation(() => api.delete(`/api_keys/${keyId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['api_keys', publicationId]);
      toast.success('Key removed!');
    },
  });
};

export default useDeleteApiKey;
