import { useCallback } from 'react';

import { Button } from '@/routes/website/_components/UI/Button';

import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { ActionSettings } from '../ActionSettings';
import { LinkToSettingsBlock } from '../LinkToSettingsBlock';
import { NewTabSettingsBlock } from '../NewTabSettingsBlock';
import { Disabled } from '../ui/Disabled';

const ButtonLinkSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const hasLink = activeNodeAttributes?.url !== undefined && activeNodeAttributes?.url !== null;
  const hasAction = !!activeNodeAttributes?.action;

  const onAddLink = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'url', '/');
        return true;
      });
    },
    [activeNodePos, activeNodeType, editor]
  );

  const onRemoveLink = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (activeNodePos === undefined || !activeNodeType) return;
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'url', undefined);
        return true;
      });
    },
    [activeNodePos, activeNodeType, editor]
  );

  const linkToSettingsBlock = hasAction ? (
    <Disabled tooltipText="Links are disabled when an action is set">
      <LinkToSettingsBlock editor={editor} activeNodeResult={activeNodeResult} />
    </Disabled>
  ) : (
    <LinkToSettingsBlock editor={editor} activeNodeResult={activeNodeResult} />
  );

  const newTabSettingsBlock = hasAction ? (
    <Disabled tooltipText="Links are disabled when an action is set">
      <NewTabSettingsBlock editor={editor} activeNodeResult={activeNodeResult} />
    </Disabled>
  ) : (
    <NewTabSettingsBlock editor={editor} activeNodeResult={activeNodeResult} />
  );

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['button']}>
      <SectionRenderer
        title="Link"
        actions={
          <Button variant="ghost" size="sm" className="text-wb-accent" onClick={hasLink ? onRemoveLink : onAddLink}>
            {hasLink ? 'Remove' : 'Add'}
          </Button>
        }
        collapsible={!!hasLink}
      >
        {hasLink && linkToSettingsBlock}
        {hasLink && newTabSettingsBlock}
        <ActionSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};

export { ButtonLinkSettings };
