import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { SubscriptionMilestone } from '@/interfaces/milestone';
import api from '@/services/swarm';

interface ApiResponse {
  subscription_milestones: SubscriptionMilestone[];
  pagination: Pagination;
}

interface Props {
  enabled?: boolean;
  actionRequired?: boolean;
  search?: string;
  statusFilter?: string;
  orderBy?: string;
}

const useSubscriptionMilestones = ({ enabled = true, actionRequired, search, statusFilter, orderBy }: Props = {}) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    [currentPublicationId, 'subscription_milestones', actionRequired, search, statusFilter, orderBy],
    ({ pageParam = 1 }) =>
      api
        .get('referral_program/subscription_milestones', {
          params: {
            publication_id: currentPublicationId,
            action_required: actionRequired,
            page: pageParam,
            per_page: 10,
            q: search,
            status: statusFilter,
            order: orderBy,
          },
        })
        .then((res) => res.data),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
    }
  );
};

export default useSubscriptionMilestones;
