import { useEffect, useMemo } from 'react';
import { Link, Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { HomeIcon, PencilIcon } from '@heroicons/react/20/solid';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { useUpsellEmail } from '@/hooks';
import { EmailMessage } from '@/interfaces/email_message';
import { Tab } from '@/interfaces/general';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import TabNavigation from '@/ui/TabNavigation';

const UpsellEmailView = () => {
  const { pathname } = useLocation();
  const { data: upsellEmail } = useUpsellEmail();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/upsell_email') {
      navigate('/upsell_email/analytics');
    }
  }, [navigate, pathname]);

  const constructedTabs = useMemo(
    (): Tab[] => [
      {
        name: 'overview',
        label: 'Overview',
        targetRoute: '/upsell_email/overview',
        selected: pathname === '/upsell_email/overview',
      },
      {
        name: 'analytics',
        label: 'Analytics',
        targetRoute: '/upsell_email/analytics',
        selected: pathname === '/upsell_email/analytics',
      },
    ],
    [pathname]
  );

  const badgeType = upsellEmail?.active ? 'success' : 'alert';
  const isEditing = pathname === '/upsell_email/edit';

  return (
    <div className="mx-auto sm:px-6 px-4 lg:px-8 mb-6">
      <div className="flex justify-between items-center pb-4">
        <div className="flex">
          <Breadcrumbs>
            <Breadcrumbs.Item to="/">
              <HomeIcon className="w-4 h-4" />
            </Breadcrumbs.Item>
            <Breadcrumbs.Item to="/settings/publication">Publication</Breadcrumbs.Item>
            <Breadcrumbs.Item to="/monetize/subscriptions/paid_tiers">Paid Subscriptions</Breadcrumbs.Item>
            <Breadcrumbs.Item to=".">Upsell Email</Breadcrumbs.Item>
            {isEditing && <Breadcrumbs.Item to="/upsell_email/edit">Edit</Breadcrumbs.Item>}
          </Breadcrumbs>
        </div>
        {!isEditing && (
          <div className="flex">
            <Link to="/upsell_email/edit">
              <Button type="button">
                <div className="flex">
                  <PencilIcon className="w-5 h-5 mr-2" />
                  <span>Edit</span>
                </div>
              </Button>
            </Link>
          </div>
        )}
      </div>

      {upsellEmail && (
        <>
          <div className="flex flex-col">
            <div className="mb-2">
              <Badge className="capitalize" type={badgeType}>
                {upsellEmail.status}
              </Badge>
            </div>
            <div className="flex items-center">
              <h1 className="text-2xl font-bold mr-2 max-w-4xl">Upgrade Reminder Email</h1>
            </div>
          </div>
          <p className="text-gray-600 max-w-3xl">
            The email that is sent out to users who have claimed a free trial that didn&apos;t require a credit card.
          </p>

          {!isEditing && (
            <TabNavigation tabs={constructedTabs} className="sm:border-b pt-4 mb-4 px-4 lg:px-0" variant="tertiary" />
          )}

          <Outlet context={{ upsellEmail }} />
        </>
      )}
    </div>
  );
};

type ContextType = { upsellEmail: EmailMessage };

export const useUpsellEmailContext = () => useOutletContext<ContextType>();

export default UpsellEmailView;
