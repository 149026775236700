import { useState } from 'react';
import { IconComponent } from '@heroicons/react';
import { ArrowsPointingOutIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';
import { AutomationStepStepType } from '@/interfaces/automations/automation_step';
import { Button } from '@/ui/Button';

import ExpandedMetricsModal, { AutomationStepExpandedMetric } from '../components/ExpandedMetricsModal';
import { COLORS_BY_AUTOMATION_STEP_TYPE } from '../constants';

export interface AutomationStepMetric {
  value: number | string;
  label: string;
  icon: IconComponent;
}

interface Props {
  stepType: AutomationStepStepType;
  metrics: AutomationStepMetric[];
  expandedMetrics: AutomationStepExpandedMetric[];
}

const AutomationStepMetrics = ({ stepType, metrics, expandedMetrics }: Props) => {
  const textColor = COLORS_BY_AUTOMATION_STEP_TYPE[stepType].text;
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex gap-x-1 justify-stretch">
        {metrics.map(({ value, label, icon }) => {
          const Icon = icon;
          return (
            <div
              className="flex flex-col items-center"
              style={{
                width: `${100 / metrics.length}%`,
              }}
              key={`automation-step-metric-${label}`}
            >
              <Icon className={cx('w-4 h-4', textColor)} />
              <TypographyStack gap="0" className="w-full items-center">
                <Typography size="sm" weight="semibold">
                  {value}
                </Typography>
                <Typography size="xs" colorWeight="500">
                  {label}
                </Typography>
              </TypographyStack>
            </div>
          );
        })}
      </div>
      {expandedMetrics.length > 0 && (
        <>
          <ExpandedMetricsModal
            isOpen={isExpanded}
            onClose={() => setIsExpanded(false)}
            expandedMetrics={expandedMetrics}
          />
          <div className="flex justify-center">
            <Button
              block
              variant="primary-inverse"
              size="xs"
              Icon={ArrowsPointingOutIcon}
              onClick={() => setIsExpanded(true)}
            >
              Expand
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AutomationStepMetrics;
