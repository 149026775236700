import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  backgroundColor: string;
  iconColor: string;
}>;

const IconContainer = ({ children, backgroundColor, iconColor }: Props) => {
  return (
    <div className={cx('w-fit h-fit rounded-full p-2.5', backgroundColor)}>
      <div className={cx('w-4 h-4', iconColor)}>{children}</div>
    </div>
  );
};

export default IconContainer;
