import { Moment } from 'moment-mini';

export interface UseCalendarsProps {
  start: Moment;
  end: Moment;
}

export enum CalendarType {
  POSTS = 'posts',
  AD_NETWORK = 'ad_network',
}

export interface Calendar {
  id: string;
  name: string;
  type: CalendarType;
  color: 'pink' | 'violet';
  categories: Array<Category>;
}

export interface Category {
  id: string;
  name: string;
  style: 'filled' | 'flush' | 'outline';
  time_relevant?: boolean;
}

export interface Event {
  id: string;
  calendar: CalendarType;
  category: string;
  title: string;
  time: string;
  metadata: Record<string, any>;
}

export interface PostPublishedEvent extends Event {
  calendar: CalendarType.POSTS;
  category: 'published';
  metadata: {
    post_id: string;
    title: string;
    subtitle: string;
    scheduled_at: string;
    platform: string;
    subject: string;
    content_tags: string;
    preview_text: string;
    author_profile_picture_urls: Array<string>;
  };
}

export interface PostScheduledEvent extends Event {
  calendar: CalendarType.POSTS;
  category: 'scheduled';
  metadata: {
    post_id: string;
    title: string;
    subtitle: string;
    scheduled_at: string;
    platform: string;
    subject: string;
    content_tags: string;
    preview_text: string;
    author_profile_picture_urls: Array<string>;
  };
}

export interface AdNetworkPublishedEvent extends Event {
  calendar: CalendarType.AD_NETWORK;
  category: 'published';
  metadata: {
    title: string;
    opportunity_id: string;
    date: string;
    payout_rate: string;
    post_title: string;
    report_id: string | null;
    post_id: string;
  };
}

export interface AdNetworkAcceptedEvent extends Event {
  calendar: CalendarType.AD_NETWORK;
  category: 'accepted';
  metadata: {
    title: string;
    opportunity_id: string;
    selected_date: string;
    payout_rate: string;
    creative_version: string;
  };
}

export interface AdNetworkPendingEvent extends Event {
  calendar: CalendarType.AD_NETWORK;
  category: 'pending';
  metadata: {
    title: string;
    opportunity_id: string;
    window_start_date: string;
    window_end_date: string;
    payout_rate: string;
    deadline: string;
  };
}

export interface ApiResponse {
  calendars: Array<Calendar>;
  events: Array<Event>;
}
