import { Edge, Node } from 'reactflow';

import { FormQuestion } from '@/interfaces/form';

export const RE_LAY_TIMEOUT = 100;
export const CUSTOM_NODE_TYPE = 'custom-node';
export const ADD_QUESTION_NODE_TYPE = 'add-question-node';
export const ADD_QUESTION_NODE_ID = 'add-question-node';
export const CUSTOM_EDGE_TYPE = 'custom-edge';
export const FORM_BUILDER_EDGE_WIDTH_CLASSNAME = 'w-130';
export const FORM_BUILDER_NODE_WIDTH_CLASSNAME = 'w-130';

export const translateFormQuestionToNode = (formQuestion: FormQuestion, previousFormQuestion?: FormQuestion): Node => ({
  id: formQuestion.id,
  parentNode: previousFormQuestion ? previousFormQuestion.id : undefined,
  type: CUSTOM_NODE_TYPE,
  position: { x: 0, y: 0 },
  data: {},
});

export const translateFormQuestionsToNodes = (formQuestions: FormQuestion[]): Node[] =>
  formQuestions.map((formQuestion: FormQuestion, index: number) =>
    translateFormQuestionToNode(formQuestion, formQuestions[index - 1])
  );

interface AddFormQuestionProps {
  automationId?: string;
  parentNode?: string;
  branchId?: string;
  branchArm?: string;
  position?: {
    x: number;
    y: number;
  };
}

export const makeNewAddFormQuestionNode = (props: AddFormQuestionProps) => {
  const { position, parentNode } = props;

  const node: Node = {
    id: ADD_QUESTION_NODE_ID,
    type: ADD_QUESTION_NODE_TYPE,
    data: { parentId: parentNode },
    position: position || { x: 0, y: 0 },
    parentNode,
  };

  return node;
};

const makeEdges = (nodes: Node[]) => {
  const edges: Edge[] = [];

  // Add edges between each question node
  nodes
    .filter((node) => !!node.parentNode)
    .forEach((node: Node) => {
      edges.push({
        id: `${node.parentNode}=>${node.id}`,
        source: node.parentNode || '',
        target: node.id,
        type: CUSTOM_EDGE_TYPE,
      });
    });

  return edges;
};

export const assignPositionToNodes = (laidOutNodes: Node[]) => (nodes: Node[]) => {
  return nodes.map((node) => {
    const laidOutNode = laidOutNodes.find(({ id }) => id === node.id) as Node;

    if (!laidOutNode) {
      return node;
    }

    return {
      ...node,
      position: laidOutNode.position,
    };
  });
};

export const getNodeDimensions = (nodeId: string) => {
  const nodeInDom = document.querySelector(`[data-id="${nodeId}"]`);
  const dimensions = nodeInDom?.getBoundingClientRect() || {
    width: 100,
    height: 100,
  };
  return dimensions;
};

export const getLaidOutElements = (nodes: Node[], edges: Edge[], zoomLevel = 1) => {
  if (nodes.length === 0) {
    return {
      nodes,
      edges,
    };
  }

  return {
    nodes: nodes.map((node) => {
      const { height: parentHeight } = node.parentNode ? getNodeDimensions(node.parentNode) : { height: 0 };
      const newY = node.parentNode ? parentHeight / zoomLevel + 100 : 0;

      return {
        ...node,
        hidden: false,
        position: {
          x: 0,
          y: newY,
        },
      };
    }),
    edges,
  };
};

export const getNodesAndEdges = (
  formQuestions: FormQuestion[],
  canAddNewQuestion: boolean
): {
  // nodes: Node<CustomNodeDataType>[];
  nodes: Node[];
  edges: Edge[];
} => {
  const nodes: Node[] = [];

  // Add the form question nodes
  if (formQuestions.length > 0) {
    const questionNodes = translateFormQuestionsToNodes(formQuestions);

    questionNodes.forEach((node) => {
      nodes.push({
        hidden: false,
        ...node,
      });

      // Add the addStep node if there are no children and it's not a branch node (branches are handled below)
      const hasChildNodes = questionNodes.find((questionNode) => questionNode.parentNode === node.id);
      if (!hasChildNodes && canAddNewQuestion) {
        const newNode = makeNewAddFormQuestionNode({
          parentNode: node.id,
        });
        nodes.push({
          hidden: false,
          ...newNode,
        });
      }
    });
  } else if (canAddNewQuestion) {
    // If there's no form questions yet, add a single addAutomationStep node
    const newNode = makeNewAddFormQuestionNode({});
    nodes.push({
      hidden: false,
      ...newNode,
    });
  }

  return {
    nodes,
    edges: makeEdges(nodes),
  };
};
