import { useState } from 'react';

import { Button } from '@/ui/Button';

import { ImageSelect, Input } from '../../components/Form';
import { useCurrentPublicationState } from '../../context/current-publication-context';
import useCreateGuest from '../../hooks/useCreateGuest';
import useUpdateGuest from '../../hooks/useUpdateGuest';
import { Author } from '../../interfaces/author';

interface Props {
  guestAuthor?: Author | null;
  onSubmit: () => void;
}

const Form = ({ guestAuthor, onSubmit }: Props) => {
  const { mutateAsync: handleSave } = useCreateGuest({ onSuccess: onSubmit });
  const { mutateAsync: handleUpdate } = useUpdateGuest({ onSuccess: onSubmit });
  const [profilePic, setProfilePic] = useState<string | File | any>(guestAuthor?.profile_picture?.url || null);
  const [publicationId] = useCurrentPublicationState();

  const handleFileChange = (file: File) => {
    setProfilePic(file);
  };

  const handleFileClear = () => {
    setProfilePic(null);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // get form data
    const formData = new FormData(e.currentTarget);

    // Only pass image if it's a binary or null.
    // If it's a string, it means the image didn't change
    // and we can leave it alone.
    if (typeof profilePic !== 'string') {
      formData.append('guest_author[profile_picture]', profilePic);
    }

    if (guestAuthor) {
      handleUpdate({
        id: guestAuthor.id,
        formData,
      });
    } else {
      handleSave({
        formData,
      });
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      <input type="hidden" value={publicationId} name="publication_id" />
      <Input
        type="text"
        defaultValue={guestAuthor?.name || ''}
        name="guest_author[name]"
        labelText="Full name"
        required
      />
      <Input
        type="text"
        defaultValue={guestAuthor?.twitter_handle || ''}
        name="guest_author[twitter_handle]"
        labelText="Twitter Handle"
      />
      <div className="w-40">
        <ImageSelect
          isRounded
          name="guest_author[profile_picture]"
          orientation="col"
          labelText="Profile Picture"
          dimensionSuggestion="800px x 800px recommended"
          onFileSelect={handleFileChange}
          onFileClear={handleFileClear}
          file={profilePic}
        />
      </div>
      <Button type="submit">{guestAuthor ? 'Update guest' : 'Save guest'}</Button>
    </form>
  );
};

export default Form;
