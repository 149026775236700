import React from 'react';
import ReactTooltip from 'react-tooltip';
import { cx } from 'class-variance-authority';

import { Styled } from './styled';
import { ButtonProps } from './types';

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      $active = false,
      children,
      $leftSlot = undefined,
      $rightSlot = undefined,
      $isIconButton = false,
      $isColorTileButton = false,
      $fullWidth = false,
      $size = 'default',
      $variant = 'primary',
      className = '',
      $muted = false,
      $isToggleButton = false,
      $isDisabled = false,
      $tooltip = '',
      type = 'button',
      as,
      ...rest
    },
    ref: React.ForwardedRef<HTMLButtonElement>
  ): JSX.Element | null => {
    const showText = (($isIconButton && !$leftSlot && !$rightSlot) || !$isIconButton) && children;

    return (
      <Styled.Button
        $active={$active}
        className={`${className}${$active ? ' is-active' : ''}`}
        $fullWidth={$fullWidth}
        $isIconButton={$isIconButton}
        $isColorTileButton={$isColorTileButton}
        $size={$size}
        $leftSlot={$leftSlot}
        $rightSlot={$rightSlot}
        $variant={$variant}
        $muted={$muted}
        $isToggleButton={$isToggleButton}
        disabled={$isDisabled}
        type={type}
        ref={ref}
        as={as}
        data-tip={$tooltip}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        <div className={cx('flex flex-row', $isDisabled ? 'cursor-not-allowed' : '', $fullWidth ? 'w-full' : '')}>
          {$leftSlot && $leftSlot}
          {showText && <Styled.Text>{children}</Styled.Text>}
          {$rightSlot && $rightSlot}
          {$tooltip && <ReactTooltip delayShow={500} place="bottom" />}
          {$tooltip && !children && <span className="sr-only">{$tooltip}</span>}
        </div>
      </Styled.Button>
    );
  }
);

export default Button;
