import { useMemo } from 'react';
import { ReactFlowState, useReactFlow, useStore } from 'reactflow';

import { useSettings } from '../../../../context/settings-context';

const useMaxAllowedSteps = () => {
  const { getNodes, getNode, setNodes, setEdges, getEdges } = useReactFlow();
  const nodeCountSelector = (state: ReactFlowState) => state.nodeInternals.size;
  const nodeCount = useStore(nodeCountSelector);
  const { settings } = useSettings();

  return useMemo(
    () => getNodes().filter((node) => node.type === 'automationStep').length >= Number(settings?.max_automation_steps),
    [nodeCount, getEdges, getNodes, getNode, setNodes, setEdges]
  );
};

export default useMaxAllowedSteps;
