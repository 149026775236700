/* eslint-disable no-console */
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';
import { PageParams } from '@customerio/cdp-analytics-browser/dist/types/core/arguments-resolver';

const createDummyAnalytics = () => ({
  track: (eventName: string, properties?: object) => {
    console.log(`Dummy analytics: track(${eventName}, ${JSON.stringify(properties)})`);
  },
  identify: (userId: string | undefined | null, traits?: object) => {
    console.log(`Dummy analytics: identify(${userId}, ${JSON.stringify(traits)})`);
  },
  alias: (userId: string | undefined | null, previousId: string) => {
    console.log(`Dummy analytics: alias(${userId}, ${previousId})`);
  },
  group: (groupId: string | undefined | null, traits?: object) => {
    console.log(`Dummy analytics: group(${groupId}, ${JSON.stringify(traits)})`);
  },
  reset: () => {
    console.log(`Dummy analytics: reset()`);
  },
  page: (...args: PageParams) => {
    console.log(`Dummy analytics: page($}, ${JSON.stringify(args)})`);
  },
});

const realAnalytics = AnalyticsBrowser.load(
  {
    writeKey: window?.env?.REACT_APP_CIO_CDP_KEY as string,
  },
  {
    integrations: {
      'Customer.io In-App Plugin': {
        siteId: window?.env?.REACT_APP_CIO_IN_APP_SITE_ID as string,
      },
    },
  }
);

// Check if the user is masquerading
const isMasquerading = !!localStorage.getItem('masqueradeToken');
const analytics = isMasquerading ? createDummyAnalytics() as AnalyticsBrowser : realAnalytics;

export default analytics;
