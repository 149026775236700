import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import LargeItemRadioGroup, { LargeItemRadioOption } from '@/components/Form/LargeItemRadioGroup';
import { useCurrentUser } from '@/context/current-user-context';
import { useOnboarding } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { OnboardingPreviousPlatforms } from '@/interfaces/onboarding';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import { Layout, LeftPane, RightPane } from '../_components/Layout';
import OnboardingStepForm from '../_components/OnboardingStepForm';

const MIGRATION_OPTIONS = [
  {
    name: 'Substack',
    value: OnboardingPreviousPlatforms.SUBSTACK,
    tooltip: 'One-click migration available',
  },
  {
    name: 'Ghost',
    value: OnboardingPreviousPlatforms.GHOST,
    tooltip: 'One-click migration available',
  },
  {
    name: 'WordPress',
    value: OnboardingPreviousPlatforms.WORDPRESS,
    tooltip: 'One-click migration available',
  },
  {
    name: 'Mailchimp',
    value: OnboardingPreviousPlatforms.MAILCHIMP,
    tooltip: 'One-click migration available',
  },
  {
    name: 'Campaign Monitor',
    value: OnboardingPreviousPlatforms.CAMPAIGN_MONITOR,
  },
  {
    name: 'ConvertKit',
    value: OnboardingPreviousPlatforms.CONVERT_KIT,
  },
  {
    name: 'MailerLite',
    value: OnboardingPreviousPlatforms.MAILER_LITE,
  },
  {
    name: 'Active Campaign',
    value: OnboardingPreviousPlatforms.ACTIVE_CAMPAIGN,
  },
  {
    name: 'Klaviyo',
    value: OnboardingPreviousPlatforms.KLAVIYO,
  },
];

const DROPDOWN_OPTIONS = [
  {
    name: 'AWeber',
    value: OnboardingPreviousPlatforms.AWEBER,
  },
  {
    name: 'Buttondown',
    value: OnboardingPreviousPlatforms.BUTTONDOWN,
  },
  {
    name: 'Benchmark',
    value: OnboardingPreviousPlatforms.BENCHMARK_EMAIL,
  },
  {
    name: 'Mailmodo',
    value: OnboardingPreviousPlatforms.MAILMODO,
  },
  {
    name: 'Mailercloud',
    value: OnboardingPreviousPlatforms.MAILERCLOUD,
  },
  {
    name: 'Omnisend',
    value: OnboardingPreviousPlatforms.OMNISEND,
  },
  {
    name: 'SendFox',
    value: OnboardingPreviousPlatforms.SENDFOX,
  },
  {
    name: 'Zoho Campaigns',
    value: OnboardingPreviousPlatforms.ZOHO_CAMPAIGNS,
  },
  {
    name: 'Other',
    value: OnboardingPreviousPlatforms.OTHER,
  },
];

const OnboardingPlatformMigration = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const publicationId = useCurrentPublicationId();
  const { data: currentPublication } = usePublication(publicationId);
  const { data: onboarding } = useOnboarding(publicationId, currentPublication?.onboarding_id);

  const [selectedOption, setSelectedOption] = useState<LargeItemRadioOption | null>(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const isSubmitButtonDisabled = !selectedOption;

  useEffect(() => {
    if (onboarding) {
      const foundOption = MIGRATION_OPTIONS.find((option) => option.value === onboarding?.migrating_from);
      if (foundOption) {
        setSelectedOption(foundOption);
      }
    }
  }, [onboarding]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsRequesting(true);
    api
      .patch(`/onboarding/${currentPublication?.onboarding_id}`, {
        migrating_from: selectedOption?.value,
        publication_id: currentPublication?.id,
      })
      .then(() => {
        if (currentUser?.id) {
          analytics.identify(currentUser?.id, {
            platform: selectedOption?.value,
            email: currentUser?.email,
          });
        }

        analytics.track('Selected Platform', {
          platform: selectedOption?.value,
          email: currentUser?.email,
        });
      })
      .finally(() => {
        setIsRequesting(false);
        navigate('/onboarding/publication_details');
      });
  };

  return (
    <>
      <Helmet>
        <title>Platform Migration - beehiiv</title>
      </Helmet>

      <Layout>
        <LeftPane>
          <OnboardingStepForm
            buttonsClassName="pb-8"
            title="Amazing! Now, what platform are you coming from?"
            subtitle="This will help us personalize your experience on beehiiv."
            onBack={() => navigate('/onboarding/personas')}
            onSubmit={handleSubmit}
            onSkip={() => navigate('/onboarding/publication_details')}
            isSubmitDisabled={isSubmitButtonDisabled}
            isProcessing={isRequesting}
          >
            <LargeItemRadioGroup
              selected={selectedOption}
              onSelected={setSelectedOption}
              options={MIGRATION_OPTIONS}
              dropdownOptions={DROPDOWN_OPTIONS}
              justifyItems="start"
              dropdownPlaceholder="Other"
            />
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingPlatformMigration;
