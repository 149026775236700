import React, { FC, useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { v4 as generateUuid } from 'uuid';

import { DROP_DOWN_Z_INDEX } from '@/components/zIndexes';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Option } from '../../interfaces/general';

interface Props {
  className?: string;
  labelClassName?: string;
  name: string;
  labelText?: string;
  placeholderText?: string;
  required?: boolean;
  value: String;
  staticValue?: String;
  onSelect: (name: string, value: string, selectedOption?: Option) => void;
  onClear?: (name: string) => void;
  onClearStatic?: (name: string) => void;
  staticOptions?: Option[];
  staticOptionsLabel?: string;
  onSelectStatic?: (name: string, value: string, selectedOption?: Option) => void;
  loadOptions: (inputValue?: string) => Promise<void | Option[]> | undefined;
  loadInitialValue?: () => Promise<void | Option> | undefined;
  disabled?: boolean;
  helperText?: string;
  dropdownClassnames?: string;
  emptyLabel?: string;
}

const SearchDropdown: FC<Props> = ({
  className,
  labelClassName,
  name,
  labelText,
  required,
  onSelect,
  onClear,
  onClearStatic,
  value,
  staticValue,
  staticOptions,
  onSelectStatic,
  loadOptions,
  loadInitialValue,
  disabled,
  helperText,
  placeholderText,
  dropdownClassnames,
  emptyLabel,
  staticOptionsLabel,
}: Props) => {
  const [options, setOptions] = useState<Option[]>();
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    options?.find((option) => option.value === value) || staticOptions?.find((option) => option.value === staticValue)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialValueFetched, setIsInitialValueFetched] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownEl = useRef<any>();
  const inputEl = useRef<any>();

  useEffect(() => {
    if (loadOptions) {
      setIsLoading(true);

      loadOptions(searchQuery)
        ?.then((loadedOptions) => {
          if (loadedOptions) {
            setOptions(loadedOptions);
            const foundValue = loadedOptions.find((option) => option.value === value);

            if (foundValue) {
              setSelectedOption(loadedOptions.find((option) => option.value === value));
            }
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [loadOptions, searchQuery, value]);

  // This should only run once
  useEffect(() => {
    if (loadInitialValue && !isInitialValueFetched) {
      loadInitialValue()?.then((option) => {
        if (option) {
          setSelectedOption(option);
        }
      });
      setIsInitialValueFetched(true);
    }
  }, [loadInitialValue, setSelectedOption, isInitialValueFetched, setIsInitialValueFetched]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownEl.current && !dropdownEl.current.contains(event.target)) {
        if (menuOpen) {
          setMenuOpen(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownEl, menuOpen]);

  const onSelectOption = (newValue: any) => {
    if (!name || !newValue) {
      return;
    }

    if (onSelect) {
      const optionByValue = options?.find((option) => option.value === newValue);
      onSelect(name, newValue, optionByValue);
      setSelectedOption(optionByValue);
    }

    setMenuOpen(false);
  };

  const onSelectStaticOption = (newValue: any) => {
    if (!name || !newValue) {
      return;
    }

    if (onSelectStatic) {
      const optionByValue = staticOptions?.find((option) => option.value === newValue);
      onSelectStatic(name, newValue, optionByValue);
      setSelectedOption(optionByValue);
    }

    setMenuOpen(false);
  };

  const onReset = () => {
    setSelectedOption(undefined);

    if (onClear) {
      onClear(name);
      setSearchQuery('');
    }

    if (onClearStatic) {
      onClearStatic(name);
      setSearchQuery('');
    }

    setMenuOpen(false);
  };

  const onMenuClick = (e: any) => {
    // This is so we don't falsely unfocus
    if (e?.type === 'keydown' && e?.key !== 'return') {
      return;
    }

    if (menuOpen) {
      setMenuOpen(false);
    } else {
      if (inputEl) {
        inputEl?.current?.focus();
      }
      setMenuOpen(true);
    }
  };

  const onKeyDownInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;

    if (key === 'Tab' && options) {
      onSelectOption(options[0].value);
    }
  };

  const onKeyDownControl = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;

    if (key === 'Backspace' && selectedOption && name) {
      setSelectedOption(undefined);

      if (onClear) {
        onClear(name);
      }

      if (onClearStatic) {
        onClearStatic(name);
      }

      setSearchQuery('');
    }
  };

  const renderOption = (option: Option, tabIndex: number, staticOp?: boolean) => {
    const selected = option.value === value || option.value === staticValue;

    if (option.isOptGroup) {
      return (
        <div
          key={`select-group-label-${option.label}-${generateUuid()}`}
          className={cx('py-3 px-4 pr-9 font-bold text-gray-400 text-xs', tabIndex > 0 && 'mt-1 pt-3 border-t')}
        >
          {option.label}
        </div>
      );
    }

    return (
      <li
        role="option"
        tabIndex={tabIndex}
        aria-selected={selected}
        key={option.value}
        className={cx(
          'select-none cursor-pointer relative py-3 px-4 text-gray-900 hover:bg-surface-100',
          selected && 'bg-surface-100'
        )}
        onClick={() => (staticOp && onSelectStatic ? onSelectStaticOption(option.value) : onSelectOption(option.value))}
        onKeyDown={() =>
          staticOp && onSelectStatic ? onSelectStaticOption(option.value) : onSelectOption(option.value)
        }
      >
        <div className="flex">
          <span className="truncate">{option.label}</span>
          {selected && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-3">
              <CheckIcon className="h-4 w-4" aria-hidden="true" />
            </span>
          )}
        </div>
      </li>
    );
  };

  const renderStaticOptions = () => {
    let staticOptionsElements;

    if (staticOptions && staticOptions.length > 0) {
      staticOptionsElements = (
        <ul role="listbox">{staticOptions.map((option, index) => renderOption(option, index, true))}</ul>
      );
    }

    return staticOptionsElements;
  };

  const renderOptions = () => {
    if (!options || options.length === 0) {
      return emptyLabel ? <div className="py-2 pl-3 pr-9 font-bold text-gray-400 text-sm">{emptyLabel}</div> : null;
    }

    return <ul role="listbox">{options.map((option, index) => renderOption(option, index))}</ul>;
  };

  return (
    <div className={cx('md:min-w-48', className)}>
      {labelText && (
        <div>
          <label htmlFor={name} className={cx('block text-sm font-medium', labelClassName || 'text-gray-700')}>
            {labelText}
            {required ? ' *' : ''}
          </label>
        </div>
      )}
      <div className={cx('relative w-full h-fit', labelText ? 'mt-1' : '')} ref={dropdownEl}>
        <div
          role="button"
          aria-disabled={disabled}
          tabIndex={0}
          onKeyDown={onKeyDownControl}
          className={cx(
            'text-md sm:text-sm h-full relative w-full rounded-md focus:outline-none text-900 shadow-sm bg-white border border-surface-200 py-2 pl-3',
            disabled ? 'bg-gray-50 cursor-not-allowed' : '',
            menuOpen ? 'border-surface-300 !shadow-[0px_0px_0px_4px_#E5E7EB,0px_1px_2px_0px_rgba(0,0,0,0.05)]' : ''
          )}
        >
          <div
            className="w-full"
            style={{
              height: 'inherit',
            }}
          >
            <div
              role="button"
              aria-disabled={disabled}
              className={cx('bg-white h-full', disabled && 'cursor-not-allowed')}
              tabIndex={0}
              onKeyDown={onKeyDownControl}
              onClick={!disabled ? onMenuClick : undefined}
            >
              {selectedOption && (
                <div className={cx('w-full focus:outline-none truncate pr-12', disabled && 'text-gray-400')}>
                  {selectedOption.label}
                </div>
              )}
              {!selectedOption && (
                <input
                  disabled={disabled}
                  type="text"
                  ref={inputEl}
                  name={name}
                  className={cx(
                    'min-h-5 w-full py-0 pl-0 pr-8 focus:outline-none focus:ring-transparent focus:border-transparent appearance-none block border-0 placeholder-gray-400 placeholder-shown:truncate sm:text-sm h-full',
                    disabled && 'cursor-not-allowed'
                  )}
                  placeholder={placeholderText}
                  onChange={debounce((e) => setSearchQuery(e.target.value), 500)}
                  onKeyDown={onKeyDownInput}
                  onClick={!disabled ? onMenuClick : undefined}
                />
              )}

              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                {isLoading && <LoadingSpinner className="mr-1" />}
                {selectedOption && !disabled && (
                  <button name="reset" type="button" onClick={onReset}>
                    <XMarkIcon className="h-5 w-5 text-surface-500 mr-1" aria-hidden="true" />
                  </button>
                )}
                <ChevronDownIcon className="h-5 w-5 text-surface-500" aria-hidden="true" />
              </span>
            </div>
          </div>
        </div>
        {menuOpen && (
          <div
            data-type="options"
            className={cx(
              'mt-3 absolute w-full bg-white shadow-md max-h-60 rounded-md py-2 text-md overflow-auto focus:outline-none sm:text-sm',
              dropdownClassnames,
              DROP_DOWN_Z_INDEX
            )}
          >
            {isLoading && <div className="text-center py-4">Loading...</div>}
            {staticOptions && renderStaticOptions()}
            {staticOptions && (
              <div
                key={`select-group-label-${staticOptionsLabel}-label-${generateUuid()}`}
                className="py-2 pl-3 pr-9 font-bold text-gray-400 text-sm mt-1 border-t"
              >
                {staticOptionsLabel}
              </div>
            )}
            {!isLoading && renderOptions()}
          </div>
        )}
      </div>

      {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
    </div>
  );
};

export default SearchDropdown;
