import { Link, useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import * as CardUI from '@/components/Card/CardUI';
import { TitleBase, TitleSm } from '@/components/Typography';
import { usePosts } from '@/hooks';
import { PostPreview } from '@/interfaces/post';
import PostSearch from '@/models/postSearch';
import { Button } from '@/ui/Button';

const query = new PostSearch({
  status: 'published',
});

const RecentPosts = () => {
  const navigate = useNavigate();
  const postsQuery = usePosts(query);
  const recentPosts = postsQuery?.data?.pages?.[0]?.posts?.slice(0, 3);
  const hasRecentPosts = Boolean(recentPosts?.length);

  if (!hasRecentPosts) {
    return null;
  }

  return (
    <div className="space-y-2">
      <TitleBase>Recent Posts</TitleBase>
      <CardUI.Card className="pt-3">
        <div className="flex flex-col divide-y divide-y-surface-200">
          {recentPosts?.map((post: PostPreview, index: number) => {
            const isFirstItem = index === 0;

            return (
              <Link
                key={post.id}
                to={`/posts/${post.id}`}
                className={cx('flex space-x-2 items-center py-3 hover:bg-surface-50', isFirstItem && 'pt-3')}
              >
                <TitleSm className="truncate">{post.title}</TitleSm>
              </Link>
            );
          })}

          <div className="pt-4">
            <Button
              size="xs"
              variant="primary-inverse"
              onClick={() => navigate(`/posts`)}
              Icon={ArrowRightIcon}
              iconRight
            >
              All Posts
            </Button>
          </div>
        </div>
      </CardUI.Card>
    </div>
  );
};

export default RecentPosts;
