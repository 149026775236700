import { memo, useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import { ToolbarButton } from '../buttons/ToolbarButton';
import { Toolbar } from '../ui/Toolbar';
import { Tooltip } from '../ui/Tooltip';

export const ListsToolbar = memo(
  ({
    isBulletList,
    isOrderedList,
    onBulletList,
    onOrderedList,
    onResetList,
  }: {
    isBulletList?: boolean;
    isOrderedList?: boolean;
    onBulletList?: () => void;
    onOrderedList?: () => void;
    onResetList?: () => void;
  }): JSX.Element => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const isActive = isBulletList || isOrderedList;

    return (
      <Tippy
        render={(attrs) => (
          <Toolbar
            tabIndex={-1}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attrs}
          >
            <ToolbarButton
              title="Bulleted List"
              icon="BulletList"
              is={isBulletList || false}
              can={!isBulletList}
              onClick={onBulletList}
            />
            <ToolbarButton
              title="Numbered List"
              icon="OrderedList"
              is={isOrderedList || false}
              can={!isOrderedList}
              onClick={onOrderedList}
            />
            <ToolbarButton
              title="Remove List"
              icon="Cancel"
              can={isOrderedList || isBulletList || false}
              is={false}
              onClick={onResetList}
            />
          </Toolbar>
        )}
        offset={[0, 8]}
        trigger="click"
        interactive
        onShow={() => {
          setIsPanelOpen(true);
        }}
        onHidden={() => {
          setIsPanelOpen(false);
        }}
      >
        <div>
          <Tooltip enabled={!isPanelOpen} title="Lists">
            <ToolbarButton
              title={!isPanelOpen ? 'Lists' : ''}
              can
              is={isActive || isPanelOpen || false}
              icon={isOrderedList ? 'OrderedList' : 'BulletList'}
              iconRight="ChevronDown"
              muted={isPanelOpen && !isBulletList && !isOrderedList}
            />
          </Tooltip>
        </div>
      </Tippy>
    );
  }
);

ListsToolbar.displayName = 'ListsToolbar';

export default ListsToolbar;
