import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';

import { JustifySettings } from './JustifySettings';
import { ShowSettings } from './ShowSettings';

export function NavItemMobileSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  return (
    <SectionRenderer title="Mobile">
      <ShowSettings />
      <JustifySettings />
    </SectionRenderer>
  );
}
