import { useState } from 'react';

import { BoostAgreement } from '@/interfaces/boosts/monetize/boost_agreement';

import CreateBoostsPlusModal from './CreateBoostsPlusModal';
import EmailBoostCard from './EmailBoostCard';

interface Props {
  boostAgreement: BoostAgreement;
  onApply: () => void;
}

const AvailableEmailBoost = ({ boostAgreement, onApply }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);

  const isCTADisabled = hasApplied;

  return (
    <>
      <CreateBoostsPlusModal
        isOpen={isModalOpen}
        boostAgreement={boostAgreement}
        onClose={() => setIsModalOpen(false)}
        onSuccess={() => {
          setHasApplied(true);
          onApply();
        }}
      />
      <EmailBoostCard
        boostedPublication={boostAgreement.boost_offer.boosted_publication}
        partnerSince={boostAgreement.boost_offer.boosted_publication.partner_since}
        ctaText={hasApplied ? 'Applied' : 'Apply to Email Boost'}
        onCTAClick={() => setIsModalOpen(true)}
        isCTADisabled={isCTADisabled}
        maxPayout={boostAgreement.calc_max_payout}
      />
    </>
  );
};

export default AvailableEmailBoost;
