import type { ReactNode } from 'react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import cx from 'classnames';

import IconButton from '../IconHelpers/IconButton';
import { Typography } from '../Typography';

const COLORS = {
  warning: 'bg-feedback-warning-50 text-feedback-warning-900 border border-feedback-warning-200',
  danger: 'bg-feedback-danger-50 text-feedback-danger-900 border border-feedback-danger-200',
  success: 'bg-feedback-success-50 text-feedback-success-900 border border-feedback-success-200',
  info: 'bg-feedback-info-50 text-feedback-info-900 border border-feedback-info-200',
};

const ICONS = {
  warning: <ExclamationTriangleIcon className={cx('h-5 w-5', 'text-feedback-warning-500')} />,
  danger: <ExclamationCircleIcon className={cx('h-5 w-5', 'text-feedback-danger-500')} />,
  success: <CheckCircleIcon className={cx('h-5 w-5', 'text-feedback-success-500')} />,
  info: <InformationCircleIcon className={cx('h-5 w-5', 'text-feedback-info-500')} />,
};

export interface BannerProps {
  title?: string | ReactNode;
  onClick?: () => void;
  onClose?: () => void;
  ctaText?: string;
  smallCTA?: boolean;
  className?: string;
  bodyText?: string | ReactNode;
  ctaIcon?: ReactNode;
  isCTADisabled?: boolean;
  isScreenWide?: boolean;
  variant?: 'danger' | 'warning' | 'info' | 'success';
}

const Banner: React.FC<BannerProps> = ({
  variant = 'info',
  title,
  bodyText,
  ctaText,
  onClick,
  onClose,
  ctaIcon,
  className = '',
  isCTADisabled = false,
  isScreenWide = true,
  smallCTA = false,
}: BannerProps) => {
  const themeClasses = variant && COLORS[variant];
  const icon = variant && ICONS[variant];

  return (
    <div className={cx('p-3 rounded-md', themeClasses, className, isScreenWide && 'w-screen')}>
      <div className="flex justify-between gap-x-4">
        <div className="flex gap-x-2">
          <div>{icon}</div>
          <div className="flex flex-col gap-y-[10px]">
            {title && (
              <Typography token="font-medium/text/sm" color={variant} colorWeight="900">
                {title}
              </Typography>
            )}
            {bodyText && (
              <Typography token="font-normal/text/xs" color={variant} colorWeight="700">
                {bodyText}
              </Typography>
            )}
            {smallCTA && ctaText && onClick && (
              <button
                type="button"
                onClick={onClick}
                className="inline-flex items-center bg-transparent border-none p-0 cursor-pointer"
              >
                <Typography
                  token="font-medium/text/xs"
                  color={variant || 'info'}
                  colorWeight="900"
                  className="underline"
                >
                  {ctaText}
                </Typography>
              </button>
            )}
          </div>
        </div>

        {!smallCTA && onClick && ctaText && (
          <button
            disabled={isCTADisabled}
            type="button"
            className="max-w-min whitespace-nowrap flex items-center gap-x-2 mr-4"
            onClick={onClick}
          >
            <Typography token="font-medium/text/sm" color={variant} colorWeight="900">
              {ctaText}
            </Typography>
            {ctaIcon && <div className={cx('h-5 w-5', `text-feedback-${variant}-900`)}>{ctaIcon}</div>}
          </button>
        )}

        {onClose ? (
          <IconButton onClick={onClose} backgroundColor="bg-transparent">
            <XMarkIcon />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};

export default Banner;
