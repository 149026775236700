import { useEffect, useState } from 'react';

import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import Checkbox from '@/components/TiptapEditor/components/ui/Checkbox';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { InputField } from '@/components/TiptapEditor/components/ui/Input';
import { Panel, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { Author } from '@/interfaces/author';
import { normalizeString } from '@/utils';

import { usePostContext } from '../../PostContext';

interface Props {
  onBack: () => void;
  users: Author[];
}

const AddAuthorMenu = ({ onBack, users }: Props) => {
  const { formData, onChange } = usePostContext();
  const [searchResults, setSearchResults] = useState<{ label: string; value: string }[]>(
    users.map((filteredUser) => ({ label: filteredUser.name, value: filteredUser.id })) || []
  );
  const [selectedAuthors, setSelectedAuthors] = useState<string[]>(formData?.user_ids || []);

  useEffect(() => {
    onChange({ user_ids: selectedAuthors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAuthors]);

  const onAuthorSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e?.target?.value;
    const results =
      users
        ?.filter((filteredUser) => normalizeString(filteredUser.name).includes(normalizeString(query)))
        .map((filteredUser) => ({ label: filteredUser.name, value: filteredUser.id })) || [];

    setSearchResults(results);
  };

  const onAuthorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const authorId = e.target.name;

    if (!checked) {
      setSelectedAuthors((prev) => prev.filter((author) => author !== authorId));
    } else {
      setSelectedAuthors((prev) => [...prev, authorId]);
    }
  };

  return (
    <Styled.ContentWrapper>
      <Panel>
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            Add Author
          </Button>
        </PanelHeader>
        <PanelSection>
          <Styled.PanelSectionInner>
            <InputField onChange={onAuthorSearch} placeholder="Search and select" />
          </Styled.PanelSectionInner>
          <Styled.PanelSectionInner>
            <div className="mt-2 h-full overflow-auto max-h-40">
              {searchResults?.length > 0 && (
                <ul>
                  {searchResults?.map((author) => {
                    return (
                      <li
                        key={author.value}
                        className="flex cursor-pointer hover:bg-white hover:bg-opacity-10 rounded-md mt-[.1rem] px-[0.3125rem] py-[0.375rem]"
                      >
                        <label
                          htmlFor={`author-${author.value}-label`}
                          className="flex text-sm text-white cursor-pointer w-full"
                        >
                          <div className="mr-2">
                            <Checkbox
                              id={`author-${author.value}-label`}
                              name={author.value}
                              onChange={onAuthorChange}
                              checked={selectedAuthors.includes(author.value)}
                            />
                          </div>
                          {author.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              )}
              {!searchResults ||
                (searchResults?.length === 0 && (
                  <ul>
                    <li className="flex rounded-md mt-[.1rem] px-[0.3125rem] py-[0.375rem]">No authors found.</li>
                  </ul>
                ))}
            </div>
          </Styled.PanelSectionInner>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default AddAuthorMenu;
