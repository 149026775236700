import { PropsWithChildren, Suspense, useCallback, useEffect, useState } from 'react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import useIsSettingPage from '@/hooks/useIsSettingPage';
import useIsWorkspaceSettingPage from '@/hooks/useIsWorkspaceSettingPage';
import { SETTINGS_ORGANIZATION_ID_PARAM_NAME } from '@/hooks/useOrganization/useCurrentOrganizationId';
import { usePublication } from '@/hooks/usePublications';
import { SETTINGS_PUBLICATION_ID_PARAM_NAME } from '@/hooks/usePublications/useCurrentPublicationId';
import Loading from '@/pages/Loading';

import { SWITCH_PUBLICATION_QUERY_PARAM } from '../SwitchPublicationViaQuery';

type Props = PropsWithChildren<{
  hasSettingsV2: boolean;
}>;

/*

  This component makes sure that the query parameters required to render
  settings v2 always exist in the URL. The required query parameters are the
  following;
  
  1. s_publication_id - Required for all pages in namespace `/settings/*`
  2. s_organization_id - Only required for pages in namespace `/settings/workspace/*`

  Scenario: A user lands on `https://app.beehiiv.com/settings/publication/general`
            and the url does not have the query param `s_publication_id`
  
  Solution: This component will assure that the said parameter is appended in the
            url based on user's currently selected publication id
*/

const REDIRECT_URLS: Record<string, string> = {
  // settings v0 routes
  '/admin': '/settings/workspace/overview',
  '/api_keys': '/settings/workspace/api',
  '/billing': '/settings/workspace/billing_and_plan',
  '/company/edit': '/settings/workspace/overview',
  '/content_imports/*': '/settings/publication/content_import',
  '/custom_domains': '/settings/publication/domain',
  '/domain_settings/edit': '/settings/publication/domain',
  '/export_data': '/settings/publication/export_data',
  '/publication/edit': '/settings/publication/general',
  '/publications/new': '/settings/workspace/overview#publications',
  '/settings/import_subscribers': '/settings/publication/subscribers_import',
  '/settings/rss_feeds': '/settings/publication/rss',
  '/users/:id': '/settings/workspace/team',

  // settings v1 routes
  '/settings': '/current_user/personal_info',
  '/settings/notifications': '/current_user/notification_preferences',
  '/settings/password': '/current_user/account_security',
  '/settings/company': '/settings/workspace/overview',
  '/settings/company/publications': '/settings/workspace/overview#publications',
  '/settings/company/team': '/settings/workspace/team',
  '/settings/billing': '/settings/workspace/billing_and_plan',
  '/settings/integrations/api': '/settings/workspace/api',
  '/settings/integrations/webhooks': '/settings/publication/webhooks',
  '/settings/integrations/imports': '/settings/publication/content_import',
  '/settings/publication': '/settings/publication/general',
  '/settings/publication#sender-name': '/settings/publication/emails',
  '/settings/publication#copyright': '/settings/publication/emails#footer-details',
  '/settings/publication#welcome-email': '/settings/publication/emails#preset',
  '/settings/publication#double-opt-in': '/settings/publication/emails#preset',
  '/settings/publication#analytics': '/settings/publication/general#analytics',
  '/settings/publication/domains': '/settings/publication/domain',
  '/settings/publication/premium': '/monetize/subscriptions/paid_tiers',
  '/settings/publication/rss_feed': '/settings/publication/rss',
  '/settings/publication/import_subscribers': '/settings/publication/subscribers_import',
  '/settings/publication/import_content': '/settings/publication/content_import',
  '/settings/publication/widgets': '/website_builder/settings/embed_recommendations_widget',
};

const findReplacedPath = (currentPath: string) => {
  const replacedPath = REDIRECT_URLS[currentPath];
  if (replacedPath) {
    return replacedPath;
  }

  if (currentPath.startsWith('/content_imports/')) {
    return REDIRECT_URLS['/content_imports/*'];
  }

  if (currentPath.startsWith('/users/')) {
    return REDIRECT_URLS['/user/:id'];
  }

  return null;
};

export default function EnsureSettingsQueryParams({ children, hasSettingsV2 }: Props) {
  const [isLoading, setIsLoading] = useState(hasSettingsV2);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const publicationIdInUrl = searchParams.get(SETTINGS_PUBLICATION_ID_PARAM_NAME);
  const organizationIdInUrl = searchParams.get(SETTINGS_ORGANIZATION_ID_PARAM_NAME);
  const switchablePublicationId = searchParams.get(SWITCH_PUBLICATION_QUERY_PARAM);

  const [currentPublicationId] = useCurrentPublicationState(false);
  const isASettingsPage = useIsSettingPage();
  const isAWorkspaceSettingPage = useIsWorkspaceSettingPage();
  const shouldFetchPublication = isAWorkspaceSettingPage && !organizationIdInUrl;
  const { data: publication, isSuccess } = usePublication(
    switchablePublicationId || currentPublicationId,
    shouldFetchPublication
  );

  const currentPath = `${window.location.pathname}${window.location.hash}`;
  const replacedPath = findReplacedPath(currentPath);
  const isOldSettingsRoute = !!replacedPath;

  const redirectIfRequired = useCallback(() => {
    if (!hasSettingsV2) {
      setIsLoading(false);
      return;
    }

    if (isOldSettingsRoute) {
      const redirectUrl = new URL(
        `${replacedPath}${switchablePublicationId ? `?spid=${switchablePublicationId}` : ''}`,
        window.location.origin
      );
      window.location.href = redirectUrl.toString();
      return;
    }

    if (!isASettingsPage) {
      setIsLoading(false);
      return;
    }

    if (isASettingsPage && !isAWorkspaceSettingPage && publicationIdInUrl) {
      setIsLoading(false);
      return;
    }

    if (isAWorkspaceSettingPage && organizationIdInUrl && publicationIdInUrl) {
      setIsLoading(false);
      return;
    }

    const redirectPath = replacedPath || currentPath;
    const redirectUrl = new URL(redirectPath, window.location.origin);

    redirectUrl.searchParams.set(SETTINGS_PUBLICATION_ID_PARAM_NAME, switchablePublicationId || currentPublicationId);
    if (isAWorkspaceSettingPage) {
      redirectUrl.searchParams.set(SETTINGS_ORGANIZATION_ID_PARAM_NAME, publication?.organization_id || '');
    }

    window.location.href = redirectUrl.toString();
  }, [
    currentPath,
    currentPublicationId,
    hasSettingsV2,
    isASettingsPage,
    isAWorkspaceSettingPage,
    isOldSettingsRoute,
    organizationIdInUrl,
    publication?.organization_id,
    publicationIdInUrl,
    replacedPath,
    switchablePublicationId,
  ]);

  useEffect(() => {
    if (!shouldFetchPublication) {
      redirectIfRequired();
    } else if (isSuccess) {
      redirectIfRequired();
    }
  }, [redirectIfRequired, isSuccess, replacedPath, shouldFetchPublication]);

  return <Suspense fallback={<Loading />}>{!isLoading ? children : null}</Suspense>;
}
