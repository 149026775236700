import { useNavigate } from 'react-router-dom';
import { PaintBrushIcon, PencilIcon, PencilSquareIcon, TagIcon } from '@heroicons/react/20/solid';

import { useCreateDraft } from '@/hooks';

import {
  AccordionCard,
  AccordionIconWrapper,
  AccordionListItem,
} from '../../../components/Accordion/variants/AccordionCard';

const WriteActions = () => {
  const navigate = useNavigate();
  const createDraftMutation = useCreateDraft();

  const handleStartWriting = () => {
    createDraftMutation.mutateAsync({});
  };

  return (
    <AccordionCard
      title={
        <div className="flex items-center">
          <AccordionIconWrapper>
            <PencilIcon className="w-4 h-4 text-feedback-info-500" />
          </AccordionIconWrapper>
          Perfect your newsletter
        </div>
      }
      subText="Create a beautiful newsletter that your subscribers will love."
    >
      <div className="space-y-1">
        <AccordionListItem
          title="Create a post"
          icon={<PencilSquareIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={handleStartWriting}
        />
        <AccordionListItem
          title="Design newsletter"
          icon={<PaintBrushIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/post_themes/edit')}
        />
        <AccordionListItem
          title="Add content tags"
          icon={<TagIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/content_tags')}
        />
      </div>
    </AccordionCard>
  );
};

export default WriteActions;
