import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const PanelHeadline = styled.div`
  color: ${colors.white[2]};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 0.5rem 0.375rem;
`;

export const Panel = styled.div.attrs({
  draggable: false,
  contentEditable: false,
})<{ spacing?: 'medium' | 'small'; $width?: string }>`
  background: ${colors.black[1]};
  border-radius: 10px;
  color: ${colors.white[3]};
  padding: ${(p) => (p.spacing === 'small' ? '0.19rem' : '0.5rem')};
  user-select: none;

  &,
  .react-colorful {
    max-width: 100%;
    width: ${(p) => p?.$width || '18rem'};
  }
`;

export const PanelDivider = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border: 0;
  height: 1px;
  margin: 0.5rem 0;
  padding: 0;
`;

export const PanelHeader = styled.div`
  border-bottom: 1px solid ${colors.white[7]};
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const PanelSection = styled.div`
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  ${PanelHeadline} {
    &:first-child {
      margin-top: 0.25rem;
    }
  }

  ${PanelHeader} + & {
    margin-top: 0.5rem;
  }

  ${PanelDivider} + & {
    margin-top: 0.5rem;
  }
`;

export const PanelFooter = styled.div`
  border-top: 1px solid ${colors.white[7]};
  font-size: 0.875rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
`;

export const Styled = {
  Panel,
  PanelHeader,
  PanelSection,
  PanelHeadline,
  PanelFooter,
  PanelDivider,
};

export default Styled;
