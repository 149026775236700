import { FC, useCallback } from 'react';
import toast from 'react-hot-toast';

import { Option } from '@/interfaces/general';
import { Form } from '@/interfaces/options';
import api from '@/services/swarm';

import { SearchDropdown } from '../Form';

interface Props {
  labelText?: string;
  helperText?: string;
  className?: string;
  labelClassName?: string;
  formId?: string;
  onSelectForm: (formId: string) => void;
  onClearForm: () => void;
  publicationId: string;
}

const FormSelect: FC<Props> = (props: Props) => {
  const {
    labelText = 'Form',
    helperText,
    className,
    labelClassName,
    formId,
    onSelectForm,
    onClearForm,
    publicationId,
  } = props;

  const fetchForms = useCallback(
    (inputValue?: string) => {
      const params = {
        publication_id: publicationId,
        q: inputValue,
      };

      return api
        .get(`/options/forms/`, { params })
        .then((res) => {
          const forms = res.data?.options || [];

          const options: Option[] = forms.map((form: Form) => {
            const val: Option = {
              label: form.title,
              value: form.id,
            };
            return val;
          });

          return options;
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId]
  );

  const loadInitialValue = useCallback(() => {
    if (!formId) {
      return undefined;
    }

    const params = {
      publication_id: publicationId,
      id: formId,
    };

    return api
      .get(`/options/forms/`, { params })
      .then((res) => {
        const forms = res.data?.options || [];
        const onlyForm: Form = forms.length > 0 ? forms[0] : null;
        const option = onlyForm && {
          label: onlyForm?.title,
          value: onlyForm?.id,
        };

        return option;
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [publicationId, formId]);

  const handleSelect = (name: string, value: string) => {
    onSelectForm(value);
  };

  const handleClear = () => {
    onClearForm();
  };

  return (
    <SearchDropdown
      name="form"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText="Select a Form"
      value={formId || ''}
      onSelect={handleSelect}
      onClear={handleClear}
      loadOptions={fetchForms}
      loadInitialValue={loadInitialValue}
      labelClassName={labelClassName}
      className={className}
    />
  );
};

export default FormSelect;
