/* eslint-disable react/no-unstable-nested-components */
import { FC, Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cx from 'classnames';

import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';

import Text from '../Text';

type ModalSizeTypes = 'sm' | 'md' | 'lg' | 'xl';

interface BaseAction {
  actionText: string;
  disabled?: boolean;
  helperText?: string;
  variant?: 'primary' | 'primary-inverse' | 'danger';
}

interface NextStepAction extends BaseAction {
  nextStep: number;
}

interface ActionWithOnClick extends BaseAction {
  isWorking?: boolean;
  onClick: (resourceId: string, setCurrentStepIndex: (ix: number) => void) => void;
}

type Action = NextStepAction | ActionWithOnClick;

const isNextStepAction = (action: Action): action is NextStepAction => {
  return (action as NextStepAction).nextStep !== undefined;
};

export interface Step {
  title: string;
  children: React.ReactNode;
  actions: Array<Action | Array<Action>>;
  actionsRowClassName?: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  resourceId: string;
  modalSize?: ModalSizeTypes;
  bodyId?: string;
  steps: Step[];
}

const MultiActionModal: FC<Props> = ({ isOpen, onClose, resourceId, modalSize = 'sm', bodyId, steps }: Props) => {
  const cancelRef = useRef(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = steps[currentStepIndex];

  const sizeClassName = {
    sm: '',
    md: 'md:my-6 md:max-w-xl',
    lg: 'md:my-6 md:max-w-xl lg:my-4 lg:max-w-2xl',
    xl: 'md:my-6 md:max-w-2xl lg:my-4 lg:max-w-3xl xl:my-4 xl:max-w-4xl',
  }[modalSize];

  const handleClose = () => {
    onClose();
    // unset current step after 200ms to allow the modal to close
    setTimeout(() => {
      setCurrentStepIndex(0);
    }, 300);
  };

  const ActionButton = ({ action }: { action: Action }) => (
    <Button
      key={action.actionText}
      type="button"
      disabled={action.disabled}
      loading={isNextStepAction(action) ? false : action.isWorking}
      variant={action.variant || 'primary-inverse'}
      onClick={() => {
        if (isNextStepAction(action)) {
          if (action.nextStep < 0) {
            handleClose();
          } else {
            setCurrentStepIndex(action.nextStep);
          }
        } else {
          action.onClick(resourceId, setCurrentStepIndex);
        }
      }}
    >
      {action.actionText}
    </Button>
  );

  const Buttons = () => {
    const { actions, actionsRowClassName } = currentStep;

    return (
      <div className={cx('space-x-2', actionsRowClassName)}>
        {actions.map((action) => {
          if (Array.isArray(action)) {
            return (
              <ButtonGroup key={action[0].actionText}>
                {action.map((a) => (
                  <ActionButton key={a.actionText} action={a} />
                ))}
              </ButtonGroup>
            );
          }

          return <ActionButton key={action.actionText} action={action} />;
        })}
      </div>
    );
  };

  const renderModal = (
    <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>
      {/* This element is to trick the browser into centering the modal contents. */}
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className={`inline-block align-bottom bg-white rounded text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${sizeClassName}`}
          id={bodyId}
        >
          <div className="bg-white rounded-t-lg py-6 px-4 space-y-3 sm:p-6">
            <div className="flex justify-between">
              <Text size="lg" type="bold" className="text-gray-800 w-fit">
                {currentStep.title}
              </Text>
            </div>
            <div className="space-y-6">{currentStep.children}</div>
          </div>
          <div className="px-4 py-4 bg-gray-50 rounded-b-lg border-t border-gray-200 text-right sm:px-6 flex align-items-center justify-end space-x-2">
            <Buttons />
          </div>
        </div>
      </Transition.Child>
    </div>
  );

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelRef} onClose={handleClose}>
        {renderModal}
      </Dialog>
    </Transition.Root>
  );
};

export default MultiActionModal;
