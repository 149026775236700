import isEqual from 'lodash.isequal';

import { PageRoute } from '@/interfaces/dream_builder/page_route';

import { deletePageRouteFromPath } from '../../_utils/deletePageRouteFromPath';
import { getPageRouteFromPath } from '../../_utils/getPageRouteFromPath';
import { insertPageRouteToPath } from '../../_utils/insertPageRouteToPath';

import { DROP } from './types';

export function movePath(
  route: PageRoute,
  originalPath: string[], // includes the slug of the page that we're dragging
  dropTargetPath: string[], // includes the slug of the page that we're dropping at
  direction: DROP
): PageRoute {
  // Cannot delete home page
  if (originalPath.length <= 0) {
    return route;
  }
  // Cannot move to it's current path or inside it's own children
  if (isEqual(originalPath, dropTargetPath) || isEqual(originalPath, dropTargetPath.slice(0, originalPath.length))) {
    return route;
  }

  // Dropping on home page
  if (dropTargetPath.length <= 0) {
    // Cannot drop on home page if home page has no children
    // (cos then who was dropped there?)
    if ((route.children_keys?.length || 0) <= 0) {
      return route;
    }

    // Dropping inside or on top of the home page doesn't do anything
    if (direction === DROP.INSIDE || direction === DROP.TOP) {
      return route;
    }
  }

  const newParentPath = dropTargetPath.slice(0, dropTargetPath.length - 1);
  const dropTargetSlug = dropTargetPath[dropTargetPath.length - 1];
  const originalParentPath = originalPath.slice(0, originalPath.length - 1);
  const originalSlug = originalPath[originalPath.length - 1];
  const originalPathRoute = getPageRouteFromPath(route, originalPath);
  if (!originalPathRoute) {
    throw new Error('Unable to find original path route');
  }

  // Remove the page from the original path
  const newRoute = deletePageRouteFromPath(route, originalParentPath, originalSlug);
  if (!newRoute) {
    throw new Error('Unable to delete page route');
  }

  // Insert the page to the new path
  return insertPageRouteToPath(newRoute, newParentPath, originalSlug, originalPathRoute, direction, dropTargetSlug);
}
