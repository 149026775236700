import { Desktop, DeviceMobile } from '@phosphor-icons/react';

import { Viewport } from './types';

const IFRAME_CONTENT_WIDTH = 1024; // px
const MOBILE_CONTENT_WIDTH = 375; // px

const VIEWPORTS: Viewport[] = [
  { type: 'desktop', label: 'Desktop', width: IFRAME_CONTENT_WIDTH, icon: Desktop },
  { type: 'mobile', label: 'Mobile', width: MOBILE_CONTENT_WIDTH, icon: DeviceMobile },
];

export { IFRAME_CONTENT_WIDTH, MOBILE_CONTENT_WIDTH, VIEWPORTS };
