import { useCallback, useMemo } from 'react';
import { Editor } from '@tiptap/core';

import ThemeColorButton from '@/components/TiptapEditor/components/DragHandleButton/panels/ThemeColorButton';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { PanelHeadline, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { useThemeData } from '@/components/TiptapEditor/lib/hooks/useThemeData';
import { EditorColor } from '@/components/TiptapEditor/lib/types';
import styles from '@/components/TiptapEditor/styled';
import { getBorderColors } from '@/utils/documentColors';

import { useImageBlockOptions } from '../hooks';

export const BorderColor = ({
  editor,
  onShowCustomBorderColorPanel,
}: {
  editor: Editor;
  onShowCustomBorderColorPanel: Function;
}) => {
  const attrs = editor.getAttributes('imageBlock');
  const themeColors = useThemeData('colors');

  const documentColors = editor.storage.documentColors?.colors as Array<EditorColor> | undefined;

  const borderColors = useMemo(() => {
    return getBorderColors(
      documentColors,
      themeColors.map((color) => color.value)
    );
  }, [documentColors, themeColors]);

  const onSetBorderColor = useCallback(
    (color: string) => {
      editor.commands.setImageBlockBorderColor(color);
    },
    [editor]
  );

  const onResetBorderColor = useCallback(() => {
    editor.commands.setImageBlockBorderColor(null);
  }, [editor]);

  const { isCustomBorderColor } = useImageBlockOptions(attrs, themeColors);

  return (
    <PanelSection>
      <PanelHeadline>Color</PanelHeadline>
      <div className="flex items-center gap-[1px]">
        {themeColors.map((themeColor) => {
          return (
            <ThemeColorButton
              key={themeColor.key}
              onClick={onSetBorderColor}
              active={attrs.borderColor === themeColor.value}
              themeColor={themeColor}
            />
          );
        })}
      </div>
      <div className="flex items-center gap-[1px] pt-0.5 border-t border-white border-opacity-10 mt-0.5">
        <Button
          $variant="quaternary"
          $isColorTileButton
          $active={isCustomBorderColor}
          onClick={onShowCustomBorderColorPanel}
          style={styles.customColorCSSVar}
        />
        <Button
          $variant="quaternary"
          $size="small"
          $isIconButton
          $leftSlot={<Icon name="Cancel" />}
          disabled={!attrs.borderColor}
          onClick={onResetBorderColor}
        />
        {borderColors.map((color) => (
          <ThemeColorButton
            key={color}
            onClick={onSetBorderColor}
            active={attrs.borderColor === color}
            themeColor={{ key: color, value: color, label: color }}
          />
        ))}
      </div>
    </PanelSection>
  );
};

export default BorderColor;
