import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment-mini';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import LoadingBox from '@/components/LoadingBox';
import ApplyTagsModal from '@/components/SubscriberTags/ApplyTagsModal';
import TableHeaders from '@/components/TableHeaders';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Order, Sort } from '@/interfaces/general';
import { SegmentFormQueryParams } from '@/interfaces/segment';
import SegmentSearch from '@/models/segmentSearch';
import { Button } from '@/ui/Button';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import EllipsisDropdown from '../../components/EllipsisDropdown';
import PageHeading from '../../components/Layout/PageLayout/PageHeading';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { UpgradeIntent } from '../../components/UpgradeIntent';
import { useCurrentPublicationState } from '../../context/current-publication-context';
import { useSettings } from '../../context/settings-context';
import { useSegments } from '../../hooks';
import { useTutorial } from '../../hooks/useTutorials';
import useUpgradeIntent from '../../hooks/useUpgradeIntent';
import { TutorialType } from '../../interfaces/tutorial';
import { IntentAction } from '../../interfaces/upgrades';
import api from '../../services/swarm';
import { Badge } from '../../ui/Badge';
import SearchInput from '../../ui/SearchInput';

import DeleteSegmentModal from './DeleteSegmentModal';
import NewSegmentAction from './NewSegmentAction';
import EmptyState from './NoResults';

const TABLE_HEADERS: Map<string, string> = new Map([
  ['segment.name', 'Segment Name'],
  ['status', 'Status'],
  ['type', 'Type'],
  ['size', 'Size'],
  ['open_rate', 'Open Rate'],
  ['ctr', 'CTR'],
  ['unsub_rate', 'Unsub Rate'],
  ['last_processed', 'Last Processed'],
  ['segment_options', ''],
]);

const SORTABLE_HEADERS = ['segment.name', 'type', 'status', 'size', 'open_rate', 'ctr', 'unsub_rate', 'last_processed'];

const COLUMN_SIDE_PADDING = 3;
const TEXT_COLUMN_CLASSES = `px-${COLUMN_SIDE_PADDING} py-4 whitespace-nowrap text-sm text-gray-500`;

const Segments: FC = () => {
  const tutorial = useTutorial(TutorialType.SEGMENTATION);

  const [shouldResetSearch, setShouldResetSearch] = useState(false);
  const [deleteSegmentId, setDeleteSegmentId] = useState<string | undefined>();
  const [taggableSegmentId, setTaggableSegmentId] = useState<string | undefined>();
  const [searchParams, setSearchParams] = useQueryParams<SegmentFormQueryParams>({
    search: withDefault(StringParam, ''),
    filterByDateCreated: withDefault(StringParam, TimePeriod.ALL_TIME),
    filterByDateLastUpdated: withDefault(StringParam, TimePeriod.ALL_TIME),
    order: withDefault(StringParam, 'created_at'),
    dir: withDefault(StringParam, Order.DESC),
  });

  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();
  const { settings } = useSettings();

  const query = new SegmentSearch(searchParams);
  const segmentsQuery = useSegments(query);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching, error, isError } =
    segmentsQuery;

  const segments = data?.pages.flatMap((page) => page.segments) || [];
  const totalSegmentCount = data?.pages[0]?.pagination?.total || 0;
  const showingSegmentsCount = segments?.length || 0;
  const canCreateSegment = totalSegmentCount < (settings?.max_segments || 0);

  const isNoResults = !isLoading && segments.length === 0;

  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
    }
  }, [error]);

  const handleSearch = (search: string) => {
    setSearchParams({ ...searchParams, search });
  };

  // Reset search when no results
  const handleResetSearch = () => {
    setSearchParams({
      ...searchParams,
      search: '',
      filterByDateCreated: TimePeriod.ALL_TIME,
      filterByDateLastUpdated: TimePeriod.ALL_TIME,
    });

    setShouldResetSearch(true);
  };

  const handleSortChange = (newSort: Sort) => {
    setSearchParams({ ...searchParams, ...newSort });
  };

  const handleDateCreatedChange = (filterByDateCreated: keyof typeof timePeriodLabels) => {
    setSearchParams({ ...searchParams, filterByDateCreated });
  };

  const handleDateLastUpdatedChange = (filterByDateLastUpdated: keyof typeof timePeriodLabels) => {
    setSearchParams({ ...searchParams, filterByDateLastUpdated });
  };

  const onApplyTagsModalClose = () => {
    setTaggableSegmentId(undefined);
  };

  const onDeleteSegmentModalClose = () => {
    setDeleteSegmentId(undefined);
  };

  const onDelete = () => {
    setDeleteSegmentId(undefined);
    refetch();
  };

  const { isOpen, handleOpen, handleClose } = useUpgradeIntent({
    publicationId: currentPublicationId,
    intentAction: IntentAction.CREATE_SEGMENT,
  });

  const newAction = <NewSegmentAction canCreateSegment={canCreateSegment} handleOpen={handleOpen} />;

  const { search, filterByDateCreated, filterByDateLastUpdated, order, dir } = searchParams;

  const { TimePeriodSelect: DateCreated } = useTimePeriodSelect({
    defaultValue: filterByDateCreated as TimePeriod,
    size: 'md',
    label: 'Date Created',
    labelClassName: '-mt-[18px]',
  });
  const { TimePeriodSelect: DateLastUpdated } = useTimePeriodSelect({
    defaultValue: filterByDateLastUpdated as TimePeriod,
    size: 'md',
    label: 'Date Last Updated',
    labelClassName: '-mt-[18px]',
  });

  return (
    <>
      <UpgradeIntent isOpen={isOpen} onClose={handleClose} intentAction={IntentAction.CREATE_SEGMENT} />
      <div>
        {deleteSegmentId && (
          <DeleteSegmentModal
            isOpen={!!deleteSegmentId}
            segmentId={deleteSegmentId}
            onClose={onDeleteSegmentModalClose}
            onDelete={onDelete}
          />
        )}
        {taggableSegmentId && (
          <ApplyTagsModal isOpen={!!taggableSegmentId} segmentId={taggableSegmentId} onClose={onApplyTagsModalClose} />
        )}

        <PageHeading
          title="Segmentation"
          description="Segments are a way to group your subscribers based on their attributes and activity."
          tutorial={tutorial}
        >
          {newAction}
        </PageHeading>

        <div className="mb-8 pb-8 pt-4 border-b border-gray-200">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full pt-4 md:pt-0 relative flex items-end space-x-1">
              <div className="w-full sm:w-fit">
                <SearchInput
                  defaultValue={search || ''}
                  shouldDebounce={false}
                  shouldReset={shouldResetSearch}
                  onClearSearch={handleResetSearch}
                  onSearch={handleSearch}
                  placeholder="Search Segments"
                  searchInputLineHeight={14}
                />
              </div>
              <DateCreated handleChange={handleDateCreatedChange} />
              <DateLastUpdated handleChange={handleDateLastUpdatedChange} />
              {(isLoading || isFetching) && (
                <div className="hidden md:block">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mb-3">
          <span className="text-xs font-semibold text-gray-600">{`Showing ${showingSegmentsCount} of ${totalSegmentCount} results`}</span>
        </div>

        <LoadingBox isLoading={isLoading} isError={isError}>
          <>
            {isNoResults ? (
              <EmptyState
                noSearchResults={!!(isNoResults && search)}
                searchQuery={search}
                isLoading={isLoading}
                handleResetSearch={handleResetSearch}
              >
                {newAction}
              </EmptyState>
            ) : (
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-hidden border rounded-md border-gray-200 relative">
                    <table className="min-w-full divide-y divide-gray-200 rounded-md">
                      <TableHeaders
                        headers={TABLE_HEADERS}
                        sort={{ order, dir: dir as Order }}
                        sortableHeaders={SORTABLE_HEADERS}
                        handleSortChange={handleSortChange}
                        headerSidePadding={COLUMN_SIDE_PADDING}
                      />

                      <tbody className="bg-white divide-y divide-gray-200">
                        {segments.map((segment) => {
                          const options = [
                            {
                              label: 'Edit',
                              onClick: () => navigate(`/segments/${segment.id}/edit`),
                            },
                            {
                              label: 'Duplicate',
                              onClick: () => {
                                api
                                  .post(`/segments/${segment.id}/duplicate`, { publication_id: currentPublicationId })
                                  .then(() => {
                                    toast.success('Segment duplicated');
                                    segmentsQuery.refetch();
                                  })
                                  .catch(() => {
                                    toast.error('Could not duplicate segment');
                                  });
                              },
                            },
                            ...(segment.paused
                              ? [
                                  {
                                    label: 'Reactivate',
                                    onClick: () => {
                                      api
                                        .patch(`/segments/${segment.id}/touch`, {
                                          publication_id: currentPublicationId,
                                        })
                                        .then(() => {
                                          toast.success('Segment activated');
                                          segmentsQuery.refetch();
                                        })
                                        .catch(() => {
                                          toast.error('Could not reactivate segment');
                                        });
                                    },
                                  },
                                ]
                              : []),
                            ...(segment.recalculatable
                              ? [
                                  {
                                    label: 'Recalculate',
                                    onClick: () => {
                                      api
                                        .patch(`/segments/${segment.id}/recalculate`, {
                                          publication_id: currentPublicationId,
                                        })
                                        .then(() => {
                                          toast.success('Segment recalculating');
                                          segmentsQuery.refetch();
                                        })
                                        .catch(() => {
                                          toast.error('Could not recalculate segment');
                                        });
                                    },
                                  },
                                ]
                              : []),
                            ...(segment?.taggable
                              ? [
                                  {
                                    label: 'Apply Tags to Subscribers',
                                    onClick: () => setTaggableSegmentId(segment.id),
                                  },
                                ]
                              : []),
                            ...(segment.deletable
                              ? [
                                  {
                                    label: 'Delete',
                                    onClick: () => setDeleteSegmentId(segment.id),
                                  },
                                ]
                              : []),
                          ];

                          return (
                            <tr key={segment.id}>
                              <td className={TEXT_COLUMN_CLASSES}>
                                <div className="flex flex-col w-full">
                                  <div className="font-medium">
                                    <Link
                                      to={`/segments/${segment.id}`}
                                      className="text-primary-600 hover:text-primary-900"
                                    >
                                      {segment.name}
                                    </Link>
                                  </div>
                                  {segment?.description && (
                                    <p className="text-gray-500 text-xs truncate py-1 w-52" title={segment.description}>
                                      {segment.description}
                                    </p>
                                  )}
                                </div>
                              </td>
                              <td className="text-sm text-gray-900">
                                <Badge type={segment.paused ? 'warning' : 'success'} className="ml-2">
                                  {segment.paused ? 'inactive' : 'active'}
                                </Badge>
                              </td>
                              <td className={TEXT_COLUMN_CLASSES}>{segment.segment_type}</td>
                              <td className={TEXT_COLUMN_CLASSES}>{segment.num_members.toLocaleString()}</td>
                              <td className={TEXT_COLUMN_CLASSES}>{(segment.metrics.open_rate || 0).toFixed(1)}%</td>
                              <td className={TEXT_COLUMN_CLASSES}>
                                {(segment.metrics.click_through_rate || 0).toFixed(1)}%
                              </td>
                              <td className={TEXT_COLUMN_CLASSES}>
                                {(segment.metrics.pct_unsubscribed || 0).toFixed(1)}%
                              </td>
                              <td className={TEXT_COLUMN_CLASSES}>
                                {segment.last_processed_at ? moment(segment.last_processed_at).fromNow() : 'never'}
                              </td>
                              <td className={TEXT_COLUMN_CLASSES}>
                                <EllipsisDropdown options={options} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            <div className="text-center mt-6">
              {hasNextPage && (
                <div>
                  <Button
                    variant="primary-inverse"
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                  >
                    {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                  </Button>
                </div>
              )}
            </div>
          </>
        </LoadingBox>
      </div>
    </>
  );
};

export default Segments;
