import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import api from '../services/swarm';

const useReleaseRefresh = () => {
  const [currentVersion, setCurrentVersion] = useState<string | undefined>();
  const [latestVersion, setLatestVersion] = useState<string | undefined>();
  const isOutdated = Boolean(currentVersion && latestVersion && currentVersion !== latestVersion);
  const location = useLocation();

  const responseInterceptor = (response: AxiosResponse<any, any>) => {
    const releaseVersion = response.headers['x-release-version'];
    if (!releaseVersion || releaseVersion === 'not_set') return response;

    if (!currentVersion) {
      setCurrentVersion(releaseVersion);
      return response;
    }

    if (currentVersion !== releaseVersion) {
      setLatestVersion(releaseVersion);
    }

    return response;
  };

  useEffect(() => {
    const interceptor = api.interceptors.response.use(responseInterceptor);

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [currentVersion]);

  useEffect(() => {
    if (isOutdated) {
      window.location.reload();
    }
  }, [location.pathname]);
};

export default useReleaseRefresh;
