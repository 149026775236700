import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import ImportForm from './ImportForm';

const NewSubscribersImport = () => {
  const publicationId = useCurrentPublicationId();
  return <ImportForm key={publicationId} publicationId={publicationId} />;
};

export default NewSubscribersImport;
