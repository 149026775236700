import { FC, useEffect, useState } from 'react';
import { TagsElement, TTagsAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import NotConnected from '../../components/NotConnected';

export const TagsView: FC<NodeViewProps> = (nodeViewProps) => {
  const { node, editor, getPos } = nodeViewProps;
  const { onDragStart } = useReactNodeView();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { insertedFromSidebar, hasFakeData } = node.attrs;

  useEffect(() => {
    if (insertedFromSidebar && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [insertedFromSidebar, isModalOpen]);

  const handleOpenSelectionModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
    setIsModalOpen(true);
  };

  return (
    <TagsElement
      className="relative"
      element={{
        type: 'tags',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        } as TTagsAttributes,
        content: [{ id: 'text', text: '' }],
      }}
      onDragStart={onDragStart}
      attributes={{
        'data-node-view-wrapper': '',
      }}
    >
      {null}
      {hasFakeData && <NotConnected text="Not connected to real tags" onClick={handleOpenSelectionModal} />}
    </TagsElement>
  );
};
