import { PropsWithChildren } from 'react';

import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

export const Disabled = ({ children }: PropsWithChildren) => {
  return (
    <Tooltip center="Switch to Desktop View to edit">
      <div className="opacity-50 pointer-events-none">{children}</div>
    </Tooltip>
  );
};
