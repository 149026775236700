import { Input } from '@/components/Form';
import { usePublications } from '@/hooks/usePublications';
import { assignableLevelDescriptions, assignableLevelLabels, AssignableLevels } from '@/interfaces/role';
import { Dropdown } from '@/ui/Dropdown';
import { MultiSelectDropdown } from '@/ui/MultiSelectDropdown';

import { InviteFormRowData, InviteFormRowErrors } from './_interfaces/invite';

interface Props {
  inviteData: InviteFormRowData;
  onInviteChange: (invite: InviteFormRowData) => void;
  errors?: InviteFormRowErrors;
  contributorEnabled?: boolean;
}

const InviteFormRow = ({ inviteData, onInviteChange, errors, contributorEnabled }: Props) => {
  const rowId = `invite-form-row-${inviteData.key}`;
  const roleOptions = (Object.keys(assignableLevelLabels) as AssignableLevels[]).map((level) => ({
    label: assignableLevelLabels[level],
    value: level,
    description: assignableLevelDescriptions[level],
    wrapDescription: true,
  }));

  // Filter out the contributor role if the feature flag is disabled
  const filteredRoleOptions = roleOptions.filter((role) => (
    (!contributorEnabled && role.value !== 'contributor') || contributorEnabled
  ));
  const { data: publications } = usePublications();
  const publicationOptions = publications?.map((publication: any) => ({
    label: publication.name,
    value: publication.id,
  }));

  const handlePermissionsChange = (value: 'workspace' | 'publication') => {
    if (value === 'workspace') {
      return onInviteChange({ ...inviteData, permissions: value, publications: [] });
    }

    return onInviteChange({ ...inviteData, permissions: value });
  }

  return (
    <div key={inviteData.key} id={rowId} className="grid grid-cols-2 md:grid-cols-4 gap-2 w-full">
      <div className="col-span-2">
        <Input
          required
          labelText="Email"
          name="email"
          placeholder="Email"
          defaultValue={inviteData.email}
          onBlur={(e) => onInviteChange({ ...inviteData, email: e.target.value.trim() })}
          errorText={errors?.email}
        />
      </div>
      <Dropdown
        required
        className="col-span-2 md:col-span-1"
        labelText="Permission Level"
        name="permissions"
        options={[
          { value: 'workspace', label: 'Workspace', description: 'User can access all publications under this account', wrapDescription: true },
          { value: 'publication', label: 'Publication', description: 'User can only access specific publication\'s content and info', wrapDescription: true },
        ]}
        optionsContainerClassNames={{ width: 'w-[450px]' }}
        value={inviteData.permissions}
        onSelect={(name, value) => handlePermissionsChange(value) }
        errorText={errors?.permissions}
      />
      {/* For small screens, show this directly below the Permission Level */}
      {inviteData.permissions === 'publication' && (
        <div className="col-span-2 md:hidden">
          <MultiSelectDropdown
            required
            labelText='Publications'
            name="publications"
            staticOptions={publicationOptions}
            values={inviteData.publications}
            maxVisibleSelectedOptions={0}
            portalMountedId={rowId}
            placeholderText="Select Publications"
            onSelect={(name, value) => onInviteChange({ ...inviteData, publications: value })}
            errorText={errors?.publications}
          />
        </div>
      )}
      <Dropdown
        required
        className="col-span-2 md:col-span-1"
        labelText="User Role"
        name="role"
        options={filteredRoleOptions}
        optionsContainerClassNames={{ width: 'w-[450px]' }}
        value={inviteData.role}
        onSelect={(name, value) => onInviteChange({ ...inviteData, role: value })}
        errorText={errors?.role}
      />
      {/* For larger screens, This appears on a second row */}
      {inviteData.permissions === 'publication' && (
        <>
          <div className="col-span-2" />
          <div className="col-span-2 hidden md:block">
            <MultiSelectDropdown
              required
              name="publications"
              staticOptions={publicationOptions}
              values={inviteData.publications}
              maxVisibleSelectedOptions={0}
              portalMountedId={rowId}
              placeholderText="Select Publications"
              onSelect={(name, value) => onInviteChange({ ...inviteData, publications: value })}
              errorText={errors?.publications}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default InviteFormRow;
