/* eslint-disable react-hooks/rules-of-hooks */

import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import api from '@/services/swarm';

const useSendPasswordResetAction = ({ subscriptionId, status }: { subscriptionId: string; status?: string }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const { settings } = useSettings();

  const sendPasswordResetEmail = useMutation(
    () => {
      setIsSending(true);
      return api
        .post(`/subscriptions/${subscriptionId}/password_reset_sender`, {
          publication_id: currentPublicationId,
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        toast.success('Reset Password email sent');
        setConfirmOpen(false);
      },
      onError: () => {
        toast.error('Something went wrong');
      },
      onSettled: () => {
        setIsSending(false);
      },
    }
  );

  const onSendPasswordResetEmail = () => {
    setIsSending(true);
    sendPasswordResetEmail.mutate();
  };

  if (status !== 'active' || (settings && !settings.subscription_password_reset)) {
    return [];
  }

  return [
    {
      label: 'Reset Password',
      helperText: 'Send a password reset email to this subscriber',
      onClick: () => setConfirmOpen(true),
      children: (
        <ActionModal
          isOpen={confirmOpen}
          onProceed={onSendPasswordResetEmail}
          onClose={() => setConfirmOpen(false)}
          resourceId={subscriptionId}
          isWorking={isSending}
          headerText="Send Password Reset Email"
          actionText="Send Email"
        >
          This will send an email to the subscriber prompting them to update their password. Do you wish to continue?
        </ActionModal>
      ),
    },
  ];
};

export default useSendPasswordResetAction;
