import { useQuery } from 'react-query';

import api from '../../../services/swarm';

interface Options {
  [key: string]: {
    label: string;
    value: string;
  }[];
}

export default function useCampaignOptions() {
  const fetchCampaignOptions = () =>
    api.get(`/ad_network/internal/opportunities/campaign_options`, {}).then((res) => res.data);

  return useQuery<Options>(['ad_network', 'internal', 'opportunities', 'campaign_options'], fetchCampaignOptions, {
    keepPreviousData: true,
  });
}
