import { Transition } from '@headlessui/react';

import AcceptedOpportunityCountFilterDetails from './AcceptedOpportunityCountFilter/Details';
import ActiveSubscriptionCountFilterDetails from './ActiveSubscriptionCountFilter/Details';
import DescriptionFilterDetails from './DescriptionFilter/Details';
import ExpiredOpportunityCountFilterDetails from './ExpiredOpportunityCountFilter/Details';
import HostnameFilterDetails from './HostnameFilter/Details';
import LanguageFilterDetails from './LanguageFilter/Details';
import NameFilterDetails from './NameFilter/Details';
import OpportunityGroupDetails from './OpportunityGroupFilter/Details';
import OwnerEmailFilterDetails from './OwnerEmailFilter/Details';
import PendingOpportunityCountFilterDetails from './PendingOpportunityCountFilter/Details';
import PlanNameFilterDetails from './PlanNameFilter/Details';
import ReceivedOpportunityCountFilterDetails from './ReceivedOpportunityCountFilter/Details';
import RejectedOpportunityCountFilterDetails from './RejectedOpportunityCountFilter/Details';
import SearchFilterDetails from './SearchFilter/Details';
import SentPostsCountFilterDetails from './SentPostsCountFilter/Details';
import TagFilterDetails from './TagFilter/Details';
import TierFilterDetails from './TierFilter/Details';
import useFilteringContext from './filtering-context';
import { ConditionGroup } from './types';

interface FilterBuilderProps {
  group: ConditionGroup;
}

const RenderFilter = ({ group }: FilterBuilderProps) => {
  const { selectedFilter: filter } = useFilteringContext();

  if (!filter) {
    return null;
  }

  switch (filter.id) {
    case 'name':
      return <NameFilterDetails group={group} />;
    case 'plan_name':
      return <PlanNameFilterDetails group={group} />;
    case 'description':
      return <DescriptionFilterDetails group={group} />;
    case 'hostname':
      return <HostnameFilterDetails group={group} />;
    case 'language':
      return <LanguageFilterDetails group={group} />;
    case 'owner_email':
      return <OwnerEmailFilterDetails group={group} />;
    case 'search':
      return <SearchFilterDetails group={group} />;
    case 'active_subscription_count':
      return <ActiveSubscriptionCountFilterDetails group={group} />;
    case 'accepted_opportunity_count':
      return <AcceptedOpportunityCountFilterDetails group={group} />;
    case 'received_opportunity_count':
      return <ReceivedOpportunityCountFilterDetails group={group} />;
    case 'rejected_opportunity_count':
      return <RejectedOpportunityCountFilterDetails group={group} />;
    case 'pending_opportunity_count':
      return <PendingOpportunityCountFilterDetails group={group} />;
    case 'expired_opportunity_count':
      return <ExpiredOpportunityCountFilterDetails group={group} />;
    case 'tag':
      return <TagFilterDetails group={group} />;
    case 'sent_posts_count':
      return <SentPostsCountFilterDetails group={group} />;
    case 'opportunity_group':
      return <OpportunityGroupDetails group={group} />;
    case 'tier':
      return <TierFilterDetails group={group} />;
    default:
      return null;
  }
};

const FilterDetails = ({ group }: FilterBuilderProps) => {
  return (
    <div>
      <Transition
        show
        enter="transition duration-200 transform ease-out"
        enterFrom="opacity-0 translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition duration-200 transform ease-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-2"
      >
        <RenderFilter group={group} />
      </Transition>
    </div>
  );
};

export default FilterDetails;
