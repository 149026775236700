const parseGoogleFont = (fontUrl: string) => {
  try {
    const url = new URL(fontUrl);
    return url.pathname.split('/').pop()?.replace(/\+/g, ' ').replace(/\d+$/, '').trim() || '';
  } catch (e) {
    return false;
  }
};

export default parseGoogleFont;
