import React, { useState } from 'react';

import { usePlanPrice } from '../../_hooks';

import ConfirmationModal from './ConfirmationModal';
import PricingModal from './PricingModal';

interface Props {
  isOpen: boolean;
  organizationId: string;
  publicationId: string;
  onClose: () => void;
  beforeConfirm?: () => Promise<void>;
}

const PlanPriceChangeModal: React.FC<Props> = ({ isOpen, onClose, organizationId, publicationId, beforeConfirm }) => {
  const { data: currentPlanPrice } = usePlanPrice({ organizationId, publicationId });

  const [selectedPlanPriceId, setSelectedPlanPriceId] = useState<string>();

  const handleClose = () => {
    setSelectedPlanPriceId(undefined);
    onClose?.();
  };

  return (
    <>
      {!selectedPlanPriceId && (
        <PricingModal
          isOpen={!selectedPlanPriceId && isOpen}
          onClose={handleClose}
          planPrice={currentPlanPrice}
          onSelect={setSelectedPlanPriceId}
        />
      )}
      {selectedPlanPriceId && currentPlanPrice && (
        <ConfirmationModal
          isOpen={!!selectedPlanPriceId && isOpen}
          onClose={handleClose}
          onCancel={() => setSelectedPlanPriceId(undefined)}
          organizationId={organizationId}
          publicationId={publicationId}
          currentPlanPrice={currentPlanPrice}
          newPlanPriceId={selectedPlanPriceId}
          beforeConfirm={beforeConfirm}
        />
      )}
    </>
  );
};

export default PlanPriceChangeModal;
