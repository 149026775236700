import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from '@/services/swarm';

interface Variables {
  paymentRequestId: string;
}

export default function useCampaignPaymentRequestDestroy({ campaignId }: { campaignId: string }) {
  const deletePaymentRequest = ({ paymentRequestId }: Variables): Promise<void> =>
    api
      .delete(`/ad_network/internal/campaigns/${campaignId}/payment_requests/${paymentRequestId}`, {})
      .then((res) => res.data);

  return useMutation<void, MutationErrorResponse, Variables>(deletePaymentRequest, {
    onSuccess: () => {
      toast.success('Payment Request Archived');
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
