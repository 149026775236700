import { useState } from 'react';
import { Link as PhosphorLink } from '@phosphor-icons/react';

import { AttributeSettingProps } from '../types';

import { ColorSettings } from './ColorSettings';

type Property = {
  title: string;
  property: string;
};

type Props = AttributeSettingProps & {
  title: string;
  properties: Property[];
  isUngroupable?: boolean;
};

const ColorGroupSettings = ({ editor, title, properties, activeNodeResult, isUngroupable = true }: Props) => {
  const [isGroupOpen, setIsGroupOpen] = useState(false);

  if (!isGroupOpen) {
    return (
      <ColorSettings
        title={title}
        editor={editor}
        activeNodeResult={activeNodeResult}
        property={properties[0].property}
        properties={properties.map((property) => property.property)}
        onUnGroup={() => {
          if (!isUngroupable) {
            return false;
          }
          setIsGroupOpen(true);
          return true;
        }}
        isUngroupable={isUngroupable}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <button
        type="button"
        className="flex items-center gap-2 text-wb-secondary hover:text-wb-primary transition-all"
        onClick={() => setIsGroupOpen(false)}
      >
        <PhosphorLink className="w-4 h-4" />
      </button>
      {properties.map((property) => (
        <ColorSettings
          key={property.property}
          title={property.title}
          editor={editor}
          activeNodeResult={activeNodeResult}
          property={property.property}
        />
      ))}
    </div>
  );
};

export default ColorGroupSettings;
