import { useSearchParams } from 'react-router-dom';

import { TimePeriod } from '@/interfaces/time_period';

import { TabTypes } from '../types';

const useDashboardUrlState = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSetTab = (tab: TabTypes) => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  };

  const handleSetPeriod = (period: TimePeriod) => {
    searchParams.set('period', period);
    setSearchParams(searchParams);
  };

  return {
    currentTab: searchParams.get('tab') || TabTypes.OVERVIEW,
    currentPeriod: searchParams.get('period') || TimePeriod.LAST_4_WEEKS,
    handleSetTab,
    handleSetPeriod,
  };
};

export default useDashboardUrlState;
