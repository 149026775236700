import { useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Panel, PanelFooter, PanelSection } from '../../ui/Panel';
import { Divider } from '../../ui/Toolbar';

import { actions } from './ChangeTextOptions/constants';
import { ChangeTextLanguagePanel } from './ChangeTextLanguagePanel';
import { ChangeTextOptionsPanel } from './ChangeTextOptions';
import { ChangeTextTonePanel } from './ChangeTextTone';
import Styled from './styled';

export const AiTextSettingsPanel = ({
  textToneValueCallback,
  textToneValue = undefined,
  isConsumptionExceeded,
  textLengthValueCallback,
  textLengthUnitCallback,
  textLengthValue,
  textLengthUnit,
  addHeadingCallback,
  addHeadingValue,
  textLanguageValueCallback,
  textLanguageValue = undefined,
}: {
  textToneValueCallback: Function;
  textToneValue?: string;
  isConsumptionExceeded: boolean;
  textLengthValueCallback: Function;
  textLengthUnitCallback: Function;
  textLengthValue?: string;
  textLengthUnit?: string;
  addHeadingCallback: Function;
  addHeadingValue: boolean;
  textLanguageValueCallback: Function;
  textLanguageValue?: string;
}): JSX.Element => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isTextTonePanelOpen, setIsTextTonePanelOpen] = useState(false);
  const [isTextOptionsPanelOpen, setIsTextOptionsPanelOpen] = useState(false);
  const [isTextLanguagePanelOpen, setIsTextLanguagePanelOpen] = useState(false);
  const [currentToneName, setCurrentToneName] = useState<string | undefined>(undefined);
  const [currentLanguageName, setCurrentLanguageName] = useState<string | undefined>(undefined);
  const [hasTextToneChanges, setHasTextToneChanges] = useState<boolean>(false);
  const [hasTextOptionsChanges, setHasTextOptionsChanges] = useState<boolean>(false);
  const [hasTextLanguageChanges, setHasTextLanguageChanges] = useState<boolean>(false);
  const [tone, setTone] = useState<string | undefined>(textToneValue);
  const [language, setLanguage] = useState<string | undefined>(textLanguageValue);
  const [lengthValue, setLengthValue] = useState<string | undefined>(textLengthValue);
  const [addHeading, setAddHeading] = useState<boolean>(addHeadingValue);
  const hasChanges = hasTextToneChanges || hasTextOptionsChanges || hasTextLanguageChanges;

  const resetTextOptions = () => {
    textLengthValueCallback(undefined);
    setLengthValue('');
    textLengthUnitCallback(actions[0].label);
    addHeadingCallback(false);
    setAddHeading(false);
    setHasTextOptionsChanges(false);
  };

  const resetTone = () => {
    textToneValueCallback(undefined);
    setTone(undefined);
    setCurrentToneName(undefined);
    setIsPanelOpen(false);
    setHasTextToneChanges(false);
    setIsPanelOpen(false);
  };

  const resetLanguage = () => {
    setLanguage(undefined);
    textLanguageValueCallback(undefined);
    setCurrentLanguageName(undefined);
    setHasTextLanguageChanges(false);
    setIsPanelOpen(false);
  };

  const resetAll = () => {
    resetLanguage();
    resetTone();
    resetTextOptions();
  };

  const renderDefaultPanel = (attrs: any) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Panel tabIndex={-1} {...attrs}>
        <PanelSection>
          <Styled.ButtonWrapper>
            <Button
              $variant="quaternary"
              $size="small"
              $leftSlot={<Icon name="AIExtend" />}
              $rightSlot={<Icon name="ChevronRight" />}
              onClick={() => {
                setIsTextOptionsPanelOpen(true);
              }}
              $fullWidth
              disabled={isConsumptionExceeded}
              $active={hasTextOptionsChanges}
            >
              Text options
            </Button>
            <Button
              $variant="quaternary"
              $size="small"
              $leftSlot={<Icon name="Translation" />}
              $rightSlot={<Icon name="ChevronRight" />}
              onClick={() => {
                setIsTextLanguagePanelOpen(true);
                setCurrentLanguageName((prev) => (prev === 'Language' ? undefined : prev));
              }}
              $fullWidth
              disabled={isConsumptionExceeded}
              $active={hasTextLanguageChanges}
            >
              {currentLanguageName || 'Language'}
            </Button>
            <Button
              $variant="quaternary"
              $size="small"
              $leftSlot={<Icon name="AIAdjustTone" />}
              $rightSlot={<Icon name="ChevronRight" />}
              onClick={() => {
                setIsTextTonePanelOpen(true);
                setCurrentToneName((prev) => (prev === 'Tone of voice' ? undefined : prev));
              }}
              $fullWidth
              disabled={isConsumptionExceeded}
              $active={hasTextToneChanges}
            >
              {currentToneName || 'Tone of voice'}
            </Button>
          </Styled.ButtonWrapper>
        </PanelSection>
        {hasChanges && (
          <PanelFooter>
            <Divider />
            <Button
              $variant="quaternary"
              $size="small"
              $rightSlot={<Icon name="Reset" />}
              onClick={() => {
                resetAll();
              }}
              $fullWidth
            >
              Reset
            </Button>
          </PanelFooter>
        )}
      </Panel>
    );
  };

  const renderTextOptionsPanel = (attrs: any) => {
    return (
      <ChangeTextOptionsPanel
        attrs={attrs}
        setIsPanelOpen={setIsTextOptionsPanelOpen}
        textLengthValueCallback={textLengthValueCallback}
        textLengthUnitCallback={textLengthUnitCallback}
        textLengthValue={textLengthValue}
        textLengthUnit={textLengthUnit}
        addHeadingCallback={addHeadingCallback}
        addHeadingValue={addHeadingValue}
        setHasTextOptionsChanges={setHasTextOptionsChanges}
        setLengthValue={setLengthValue}
        setAddHeading={setAddHeading}
        lengthValue={lengthValue}
        addHeading={addHeading}
        resetTextOptions={resetTextOptions}
      />
    );
  };

  const renderTextTonePanel = (attrs: any) => {
    return (
      <ChangeTextTonePanel
        attrs={attrs}
        valueCallback={textToneValueCallback}
        setIsPanelOpen={setIsTextTonePanelOpen}
        setCurrentToneName={setCurrentToneName}
        setHasTextToneChanges={setHasTextToneChanges}
        tone={tone}
        setTone={setTone}
        resetTone={resetTone}
      />
    );
  };

  const renderTextLanguagePanel = (attrs: any) => {
    return (
      <ChangeTextLanguagePanel
        attrs={attrs}
        valueCallback={textLanguageValueCallback}
        setIsPanelOpen={setIsTextLanguagePanelOpen}
        setCurrentLanguageName={setCurrentLanguageName}
        setHasTextLanguageChanges={setHasTextLanguageChanges}
        language={language}
        setLanguage={setLanguage}
        resetLanguage={resetLanguage}
      />
    );
  };

  return (
    <Tippy
      render={(attrs) => {
        if (isTextTonePanelOpen) {
          return renderTextTonePanel(attrs);
        }

        if (isTextOptionsPanelOpen) {
          return renderTextOptionsPanel(attrs);
        }

        if (isTextLanguagePanelOpen) {
          return renderTextLanguagePanel(attrs);
        }

        return renderDefaultPanel(attrs);
      }}
      offset={[0, 8]}
      placement="bottom-start"
      interactive
      visible={isPanelOpen}
      onShow={() => {
        setIsPanelOpen(true);
      }}
      onHidden={() => {
        setIsPanelOpen(false);
        setIsTextTonePanelOpen(false);
        setIsTextOptionsPanelOpen(false);
        setIsTextLanguagePanelOpen(false);
      }}
      onClickOutside={() => {
        setIsPanelOpen(false);
      }}
    >
      <div>
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          $variant="quaternary"
          $size="small"
          $showTooltip
          $leftSlot={<Icon name="Settings" />}
          $rightSlot={<Icon name="ChevronDown" $size="0.66rem" />}
          $active={isPanelOpen || hasTextToneChanges || hasTextOptionsChanges || hasTextLanguageChanges}
          $muted={!hasTextToneChanges && !hasTextOptionsChanges && !hasTextLanguageChanges}
          onClick={() => {
            setIsPanelOpen(!isPanelOpen);
          }}
        >
          Settings
        </Button>
      </div>
    </Tippy>
  );
};

export default AiTextSettingsPanel;
