import React, { useCallback, useState } from 'react';

export type UploadDropAreaProps = {
  children?: React.ReactNode;
  onUpload: (files: FileList) => void;
  accepted?: string[];
};

const fileListToArray = (files: DataTransfer['items'], accepted?: string[]) => {
  const items: File[] = [];

  for (let i = 0; i < files.length; i += 1) {
    const item = files[i];

    if (!accepted || accepted.includes(item.type)) {
      items.push(item.getAsFile() as File);
    }
  }

  return items;
};

export const UploadDropArea = React.forwardRef(
  ({ children, onUpload, accepted }: UploadDropAreaProps, ref: React.Ref<HTMLDivElement>) => {
    const [isEntered, setIsEntered] = useState(false);

    const handleMouseEnter = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (e.dataTransfer.items.length === 0) {
          return;
        }

        const items = fileListToArray(e.dataTransfer.items, accepted);

        if (items.length === 0) {
          return;
        }

        setIsEntered(true);
      },
      [accepted]
    );

    const handleMouseLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setIsEntered(false);
    }, []);

    const handleDrop = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsEntered(false);

        if (!e.dataTransfer.files) {
          return;
        }

        const items = fileListToArray(e.dataTransfer.items, accepted);

        const fileList = new DataTransfer();
        items.forEach((item) => {
          fileList.items.add(item);
        });

        onUpload(fileList.files);
      },
      [accepted, onUpload]
    );

    return (
      <div
        ref={ref}
        className="relative"
        onDrop={handleDrop}
        onDragOver={handleMouseEnter}
        onDragLeave={handleMouseLeave}
      >
        {isEntered && (
          <div className="absolute inset-0 z-20 flex items-start justify-center px-8 py-20 bg-white pointer-events-none bg-opacity-90">
            <div className="text-xl font-semibold text-gray-800">Drop to Upload</div>
          </div>
        )}
        {children}
      </div>
    );
  }
);

export default UploadDropArea;
