import styled from 'styled-components';

export const FieldSet = styled.fieldset`
  border: 0;
  display: grid;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export default FieldSet;
