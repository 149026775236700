import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { EmptyCard, ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { useDestroyExternalEmbed } from '@/hooks/useExternalEmbeds';
import { ExternalEmbed } from '@/interfaces/external_embed';
import { Card } from '@/ui/Card';

interface Props {
  externalEmbeds?: ExternalEmbed[];
}

const List = ({ externalEmbeds }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [deletableItem, setDeletableItem] = useState<ExternalEmbed | null>(null);

  const { mutateAsync: deleteExternalEmbed, isLoading: isDeleting } = useDestroyExternalEmbed({
    onSuccess: () => {
      setDeletableItem(null);
    },
  });

  if (!externalEmbeds || externalEmbeds.length === 0) {
    return <EmptyCard title="No Subscribe Forms" />;
  }

  const openEditForm = (externalEmbedId: string) => {
    searchParams.set('id', externalEmbedId);
    setSearchParams(searchParams);
  };

  const showViewExternalEmbedForm = (externalEmbedId: string) => {
    searchParams.set('id', externalEmbedId);
    searchParams.set('view', 'true');
    setSearchParams(searchParams);
  };

  return (
    <>
      <ActionModal
        isWorking={isDeleting}
        modalMessageType="danger"
        headerText="Are you sure?"
        descriptionText="Deleting this form will cause it to immediately stop working everywhere it's currently embedded. Are you sure you want to delete it?"
        buttonType="danger"
        buttonShade="dark"
        isOpen={deletableItem !== null}
        onClose={() => setDeletableItem(null)}
        onProceed={() => {
          if (deletableItem !== null) {
            deleteExternalEmbed(deletableItem);
          }
        }}
        resourceId={deletableItem?.id || ''}
      />
      <Card>
        <Items>
          <ItemHeaders>
            <ItemHeader align={AlignType.LEFT}>Name</ItemHeader>
            <ItemHeader align={AlignType.LEFT}>Description</ItemHeader>
            <ItemHeader align={AlignType.LEFT}>&nbsp;</ItemHeader>
          </ItemHeaders>
          <ItemsBody>
            {externalEmbeds.map((externalEmbed) => (
              <ItemRow key={externalEmbed.id}>
                <ItemColumn align={AlignType.LEFT} className="sm:w-56">
                  <Typography weight="medium" size="sm">
                    {externalEmbed.name}
                  </Typography>
                </ItemColumn>
                <ItemColumn align={AlignType.LEFT} className="sm:max-w-lg">
                  <Typography size="sm" colorWeight="700">
                    {externalEmbed.description}
                  </Typography>
                </ItemColumn>
                <ItemColumn align={AlignType.LEFT} className="space-x-1 space-y-1">
                  <EllipsisDropdown
                    options={[
                      {
                        label: 'View',
                        onClick: () => showViewExternalEmbedForm(externalEmbed.id),
                      },
                      {
                        label: 'Edit',
                        onClick: () => openEditForm(externalEmbed.id),
                      },
                      {
                        label: 'Delete',
                        onClick: () => setDeletableItem(externalEmbed),
                      },
                    ]}
                  />
                </ItemColumn>
              </ItemRow>
            ))}
          </ItemsBody>
        </Items>
      </Card>
    </>
  );
};

export default List;
