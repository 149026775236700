import {
  AdNetworkDisbursementPayoutPlatform,
  AdNetworkDisbursementStatusLabel,
} from '@/interfaces/ad_network/internal/disbursement/types';
import { Badge } from '@/routes/ad_network/_components/Badge';

import { LabelColorHash, StatusBadgeProps } from './types';

const StatusBadge = ({ status, payoutPlatform }: StatusBadgeProps) => {
  const isPaidManually =
    status === AdNetworkDisbursementStatusLabel.PAID && payoutPlatform !== AdNetworkDisbursementPayoutPlatform.BEEHIIV;
  const text = isPaidManually ? `${status} Manually` : status;

  return <Badge color={LabelColorHash[status]}>{text}</Badge>;
};

export default StatusBadge;
