import { IconComponent } from '@heroicons/react';
import cx from 'classnames';

import { getColor } from '../DesignTokens';
import { Typography } from '../Typography';

interface Props {
  label: string;
  value: number | string;
  icon?: IconComponent;
  valueSuffix?: string;
}

const LifetimeMetricCard = ({ label, value, icon, valueSuffix }: Props) => {
  const Icon = icon;

  return (
    <div
      className={cx(
        'flex flex-col w-full gap-y-2 justify-between items-center text-center py-6 px-4 bg-white border rounded-md',
        getColor('surface/200', 'border')
      )}
    >
      {Icon && <Icon className={cx('w-5 h-5', getColor('secondary/400', 'text'))} />}

      <div className="flex items-center gap-x-1">
        <Typography weight="semibold" size="2xl" colorWeight="900">
          {value}
        </Typography>
        {valueSuffix && (
          <Typography weight="semibold" size="xs" colorWeight="900">
            {valueSuffix}
          </Typography>
        )}
      </div>

      <Typography weight="medium" size="xs" colorWeight="700">
        {label}
      </Typography>
    </div>
  );
};

export default LifetimeMetricCard;
