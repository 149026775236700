import { createContext, useContext, useMemo, useState } from 'react';

export interface AttributesPanelNavbarFooterContextInterface {
  isNavbarSelected: boolean;
  setIsNavbarSelected: (isNavbarSelected: boolean) => void;
  isFooterSelected: boolean;
  setIsFooterSelected: (isFooterSelected: boolean) => void;
}

export const AttributesPanelNavbarFooterContext = createContext<
  AttributesPanelNavbarFooterContextInterface | undefined
>(undefined);

export const useAttributesPanelNavbarFooterContext = () => {
  const context = useContext(AttributesPanelNavbarFooterContext);
  if (!context) {
    return null;
  }
  return context;
};

export const AttributesPanelNavbarFooterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isNavbarSelected, setIsNavbarSelected] = useState(false);
  const [isFooterSelected, setIsFooterSelected] = useState(false);

  const value = useMemo(
    () => ({ isNavbarSelected, setIsNavbarSelected, isFooterSelected, setIsFooterSelected }),
    [isNavbarSelected, isFooterSelected]
  );

  return (
    <AttributesPanelNavbarFooterContext.Provider value={value}>{children}</AttributesPanelNavbarFooterContext.Provider>
  );
};
