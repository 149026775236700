export enum DateFilterTypes {
  BETWEEN = 'between',
  BEFORE = 'before',
  AFTER = 'after',
}

export enum BetweenDateEnum {
  START = 'start',
  END = 'end',
}

export type DateOptionsProps = {
  enabled: boolean;
  [DateFilterTypes.BETWEEN]: {
    [BetweenDateEnum.START]: Date;
    [BetweenDateEnum.END]: Date;
  };
  [DateFilterTypes.BEFORE]: Date;
  [DateFilterTypes.AFTER]: Date;
  dateFilterType: DateFilterTypes;
  datesEnabled: boolean;
  dateField: PostDateField | SubscriberDateField;
};

export enum PostDateField {
  SEND_COMPLETED_AT = 'send_completed_at',
}

export enum SubscriberDateField {
  CREATED_AT = 'created_at',
}

export type DateFields = PostDateField | SubscriberDateField;
