import { Fragment, ReactNode } from 'react';

import { useSettings } from '@/context/settings-context';
import usePostSendAndOpenStats from '@/hooks/usePostSendAndOpenStats';
import { PostClick, PostClickStats } from '@/interfaces/post';

interface Props {
  postId: string;
  postClick: PostClick;
  children: (stats: PostClickStats) => ReactNode;
}

const clickThroughRate = (totalUniqueEmailClicked?: number, totalUniqueOpened?: number) => {
  if (!totalUniqueOpened) {
    return 0;
  }

  return Number((((totalUniqueEmailClicked || 0) / totalUniqueOpened) * 100).toFixed(2));
};

const PostClickContainer = ({ postId, children, postClick }: Props) => {
  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;

  const { data: postSendAndOpenStatsData } = usePostSendAndOpenStats({ id: postId, isEnabled: canUseApiary });

  if (canUseApiary) {
    return (
      <>
        {children({
          email_click_through_rate: clickThroughRate(
            postClick?.total_unique_email_clicked,
            postSendAndOpenStatsData?.total_unique_opened
          ),
          total_clicked: postClick?.total_clicked || 0,
          total_email_clicked: postClick?.total_email_clicked || 0,
          total_unique_email_clicked: postClick?.total_unique_email_clicked || 0,
          total_unique_web_clicked: postClick?.total_unique_web_clicked || 0,
          total_web_clicked: postClick?.total_web_clicked || 0,
        })}
      </>
    );
  }

  return (
    <>
      {children({
        email_click_through_rate: postClick.email_click_through_rate,
        total_clicked: postClick.total_clicked,
        total_email_clicked: postClick.total_email_clicked,
        total_unique_email_clicked: postClick.total_unique_email_clicked,
        total_unique_web_clicked: postClick.total_unique_web_clicked,
        total_web_clicked: postClick.total_web_clicked,
      })}
    </>
  );
};

export default PostClickContainer;
