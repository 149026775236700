import toast from 'react-hot-toast';

import { useCurrentUser } from '../../../../context/current-user-context';
import useUpdateCurrentUser from '../../../../hooks/useUsers/useUpdateUser';
import api from '../../../../services/swarm';
import {
  EmailForm,
  ImageForm,
  InputIconForm,
  NameForm,
  Section,
  SettingsPageLayout,
  TextareaForm,
} from '../../Components';
import InputForm from '../../Components/InputForm';

type Name = {
  first_name: string;
  last_name: string;
};

const Profile = () => {
  const { currentUser } = useCurrentUser();
  const updateUserMutation = useUpdateCurrentUser();

  const handleUpdateProfileImg = (profilePic: any): void => {
    const formData = new FormData();
    formData.append('profile_picture', profilePic);

    api
      .patch('/current_user', formData)
      .then(() => {
        toast.success('Profile successfully updated!');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  };

  return (
    <SettingsPageLayout title="Profile">
      <Section
        title="Profile"
        description="Manage your profile settings below."
        scrollToId="profile"
        hasDivider={false}
        isLoading={!currentUser}
      >
        <ImageForm
          label="Profile Picture"
          value={currentUser?.profile_picture?.url || null}
          onSave={handleUpdateProfileImg}
          variant="circle"
          description="This will be the avatar that appears on your profile and in your posts."
        />
        <NameForm
          firstName={currentUser?.first_name || ''}
          lastName={currentUser?.last_name || ''}
          isSaving={updateUserMutation.isLoading}
          toastSuccessMessage="Profile updated successfully!"
          onSave={(val: Name) =>
            updateUserMutation.mutateAsync({ first_name: val.first_name, last_name: val.last_name })
          }
        />
        <TextareaForm
          label="Bio"
          value={currentUser?.bio || ''}
          onSave={(val: string) => updateUserMutation.mutateAsync({ bio: val })}
          helperText="Brief description for your profile."
          isSaving={updateUserMutation.isLoading}
          toastSuccessMessage="Profile updated successfully!"
        />
        <EmailForm value={currentUser?.email || ''} userId={currentUser?.id || ''} />
        <InputIconForm
          label="Twitter Handle"
          value={currentUser?.twitter_handle || ''}
          icon="@"
          showIconWithDescription
          passesValidation={(val) => {
            if (val.includes(' ')) {
              return false;
            }

            return true;
          }}
          toastErrorMessage="Twitter handle cannot contain spaces"
          onSave={(val: string) => updateUserMutation.mutateAsync({ twitter_handle: val })}
          isSaving={updateUserMutation.isLoading}
          toastSuccessMessage="Profile updated successfully!"
        />
        <InputForm
          label="Linkedin Url"
          placeholder="https://www.linkedin.com/your-profile"
          value={currentUser?.linkedin_url || ''}
          onSave={(val: string) => updateUserMutation.mutateAsync({ linkedin_url: val })}
          isSaving={updateUserMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
        <InputForm
          label="Facebook Url"
          placeholder="https://www.facebook.com/your-profile"
          value={currentUser?.facebook_url || ''}
          onSave={(val: string) => updateUserMutation.mutateAsync({ facebook_url: val })}
          isSaving={updateUserMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
        <InputForm
          label="Instagram Url"
          placeholder="https://www.instagram.com/your-profile"
          value={currentUser?.instagram_url || ''}
          onSave={(val: string) => updateUserMutation.mutateAsync({ instagram_url: val })}
          isSaving={updateUserMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
        <InputForm
          label="Tiktok Url"
          placeholder="https://www.tiktok.com/your-profile"
          value={currentUser?.tiktok_url || ''}
          onSave={(val: string) => updateUserMutation.mutateAsync({ tiktok_url: val })}
          isSaving={updateUserMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
        <InputForm
          label="Youtube Url"
          placeholder="https://www.youtube.com/your-profile"
          value={currentUser?.youtube_url || ''}
          onSave={(val: string) => updateUserMutation.mutateAsync({ youtube_url: val })}
          isSaving={updateUserMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
      </Section>
    </SettingsPageLayout>
  );
};

export default Profile;
