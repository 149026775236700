import { RefObject, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountUp from 'react-countup';
import cx from 'classnames';

import { PublicationRewind } from '@/interfaces/publication_rewind';

import { getDecimal, getSuffix } from '../helpers';

type Props = {
  currentViewIndex: number;
  data?: PublicationRewind;
  index?: number;
  setSlideRef: (ref: RefObject<HTMLDivElement>) => void;
};

const View5 = ({ setSlideRef, data, currentViewIndex, index }: Props) => {
  const [subscriptionsCount, setSubscriptionsCount] = useState(1.35);
  const [adsCount, setAdsCount] = useState(0);
  const [boostsCount, setBoostsCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [playAnimation, setPlayAnimation] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isCurrentView = currentViewIndex === index;

    if (isCurrentView) {
      setSlideRef(contentRef);
      setSubscriptionsCount(getDecimal(data?.subscription_revenue || 0));
      setAdsCount(getDecimal(data?.ads_revenue || 0));
      setBoostsCount(getDecimal(data?.boosts_revenue || 0));
      setTotalCount(
        getDecimal((data?.subscription_revenue || 0) + (data?.ads_revenue || 0) + (data?.boosts_revenue || 0))
      );
      setTimeout(() => {
        setPlayAnimation(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  return (
    <div ref={contentRef} className="w-full h-full relative overflow-hidden rewind-bg-blue">
      <div className="rewind-bg-burst top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] rewind-bg-pink rounded-[2rem] m-[5%_0_0_5%] flex flex-col rewind-border-cyber-ink border-2" />
      <div className="absolute top-0 left-0 w-[82%] h-[82%] rewind-bg-vivid-blush rounded-[1.8rem] m-[9%_0_0_9%] flex flex-col rewind-border-cyber-ink border-2" />
      <div className="absolute top-0 left-0 w-[74%] h-[74%] rewind-bg-light-peach rounded-[1.6rem] m-[13%_0_0_13%] flex flex-col rewind-border-cyber-ink border-2" />
      <div className="rewind-bg-texture top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[74%] h-[74%] m-[13%_0_0_13%] flex flex-col p-[0_35px]">
        <div className="flex justify-center items-center w-full mt-12 mb-10">
          <img className="h-6 w-auto" src="/rewind-2024/images/beehiiv-logo-wordmark.png" alt="beehiiv logo" />
        </div>
        <h1
          className={cx(
            'font-clash-grotesk-v rewind-text-cyber-ink uppercase font-bold w-full text-[4rem] leading-[3.2rem] text-center mb-6 transition-all duration-300',
            !playAnimation && 'translate-x-20 opacity-0',
            playAnimation && 'translate-x-0 opacity-1'
          )}
        >
          AND SO ARE YOUR EARNINGS.
        </h1>
        <ul className="mt-3 p-0 w-full">
          <li className="flex w-full justify-between items-center bg-white rounded-[132px] px-16 py-8 mb-4">
            <div className="font-satoshi-v rewind-text-cyber-ink text-3xl font-bold">Subscriptions</div>
            <div className="font-bold text-[5.5875rem] leading-[3.5rem] font-clash-grotesk-v uppercase">
              <CountUp start={0} end={subscriptionsCount} duration={3} separator=" " decimals={2} decimal=".">
                {({ countUpRef }) => (
                  <div>
                    <span>$</span>
                    <span ref={countUpRef} />
                    <span>{getSuffix(data?.subscription_revenue || 0)}</span>
                  </div>
                )}
              </CountUp>
            </div>
          </li>
          <li className="flex w-full justify-between items-center bg-white rounded-[132px] px-16 py-8 mb-4">
            <div className="font-satoshi-v rewind-text-cyber-ink text-3xl font-bold">Ads</div>
            <div className="font-bold text-[5.5875rem] leading-[3.5rem] font-clash-grotesk-v uppercase">
              <CountUp start={0} end={adsCount} duration={3} separator=" " decimals={2} decimal=".">
                {({ countUpRef }) => (
                  <div>
                    <span>$</span>
                    <span ref={countUpRef} />
                    <span>{getSuffix(data?.ads_revenue || 0)}</span>
                  </div>
                )}
              </CountUp>
            </div>
          </li>
          <li className="flex w-full justify-between items-center bg-white rounded-[132px] px-16 py-8 mb-4">
            <div className="font-satoshi-v rewind-text-cyber-ink text-3xl font-bold">Boosts</div>
            <div className="font-bold text-[5.5875rem] leading-[3.5rem] font-clash-grotesk-v uppercase">
              <CountUp start={0} end={boostsCount} duration={3} separator=" " decimals={2} decimal=".">
                {({ countUpRef }) => (
                  <div>
                    <span>$</span>
                    <span ref={countUpRef} />
                    <span>{getSuffix(data?.boosts_revenue || 0)}</span>
                  </div>
                )}
              </CountUp>
            </div>
          </li>
          <li className="flex w-full justify-between items-center bg-white rounded-[132px] px-16 py-8">
            <div className="font-satoshi-v rewind-text-cyber-ink text-3xl font-bold">Total</div>
            <div className="font-bold text-[5.5875rem] leading-[3.5rem] font-clash-grotesk-v uppercase">
              <CountUp start={0} end={totalCount} duration={3} separator=" " decimals={2} decimal=".">
                {({ countUpRef }) => (
                  <div>
                    <span>$</span>
                    <span ref={countUpRef} />
                    <span>
                      {getSuffix(
                        (data?.subscription_revenue || 0) + (data?.ads_revenue || 0) + (data?.boosts_revenue || 0)
                      )}
                    </span>
                  </div>
                )}
              </CountUp>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default View5;
