import { useMemo } from 'react';
import { Color } from '@tremor/react';

import { BarChart, ChartCard } from '@/components/Chartsv2';
import { useMilestoneBreakdown } from '@/hooks/useMilestoneBreakdown';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { timePeriodLabels } from '@/utils/timePeriods';

const CHART_COLORS: Color[] = ['violet', 'gray'];

const MilestoneBreakdownChart = () => {
  const { period } = useTimePeriodSelect();
  const { data, isLoading } = useMilestoneBreakdown({ period: period as keyof typeof timePeriodLabels });

  const mappedChartData = useMemo(() => {
    if (isLoading || typeof data !== 'object') {
      return [];
    }

    return data.milestones.map((row) => ({
      label: row.trimmed_name,
      redeemed: row.total_achieved,
    }));
  }, [data, isLoading]);

  return (
    <ChartCard
      className="space-y-2"
      noResultsText={isLoading ? 'Fetching...' : 'No data available within the chosen time range.'}
      noResults={mappedChartData.length === 0}
    >
      <BarChart
        index="label"
        data={mappedChartData}
        categories={['redeemed']}
        colors={CHART_COLORS}
        showLegend={false}
        showXAxis
        showYAxis
        showGridLines
      />
    </ChartCard>
  );
};

export default MilestoneBreakdownChart;
