import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { Downgrade } from '../../interfaces/downgrade';
import api from '../../services/swarm';

interface Props {
  downgradeId: string;
  organizationId: string | undefined;
  onSuccess: (response: any) => void;
}

const useUpdateDowngrade = ({ organizationId, downgradeId, onSuccess }: Props) =>
  useMutation(
    (downgrade: Downgrade) =>
      api.patch(`/organizations/${organizationId}/downgrades/${downgradeId}`, {
        organization_id: organizationId,
        downgrade: { ...downgrade },
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong downgrading your plan.');
      },
    }
  );

export default useUpdateDowngrade;
