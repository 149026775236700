import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';
import { currencyFormatter } from '@/utils';

interface Props {
  visible: boolean;
  amountDue: number;
  lines: { amount: number; description: string; discount_amounts: { amount: number }[] }[];
}

const PriceBreakdown: React.FC<Props> = ({ visible, amountDue, lines }) => {
  const [showDetails, setShowDetails] = useState(false);

  if (!visible) return null;

  return (
    <>
      <div className="flex justify-center">
        <Button
          size="xxs"
          variant="flush"
          iconRight
          Icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
          type="button"
          onClick={() => setShowDetails(!showDetails)}
        >
          Price Breakdown
        </Button>
      </div>
      {showDetails && (
        <>
          <div>
            <div className="flex justify-between">
              <Typography as="div" token="font-bold/text/xs" colorWeight="600">
                Description
              </Typography>
              <Typography as="div" token="font-bold/text/xs" colorWeight="600">
                Amount
              </Typography>
            </div>
            <hr />
          </div>
          {lines.map((line) => (
            <>
              <div className="flex justify-between space-x-4">
                <Typography as="div" token="font-normal/text/sm" colorWeight="600">
                  {line.description}
                </Typography>
                <Typography as="div" token="font-normal/text/sm" colorWeight="600">
                  {currencyFormatter(line.amount / 100, 'USD', 2, 2)}
                </Typography>
              </div>
              {line.discount_amounts
                .filter((discount) => discount.amount && discount.amount > 0)
                .map((discount) => (
                  <Typography as="div" token="font-normal/text/sm" colorWeight="600" className="text-right">
                    - {currencyFormatter(discount.amount / 100, 'USD', 2, 2)}
                  </Typography>
                ))}
            </>
          ))}
          <div className="ml-auto mr-0 w-fit space-y-6">
            <hr />
            <div className="flex justify-end space-x-2">
              <Typography as="div" token="font-normal/text/sm" colorWeight="600">
                Total:
              </Typography>
              <Typography as="div" token="font-normal/text/sm" colorWeight="600">
                {currencyFormatter(amountDue / 100, 'USD', 2, 2)}
              </Typography>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PriceBreakdown;
