import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { TimePeriod } from '@/interfaces/time_period';
import api from '@/services/swarm';

export enum AutomationMetricsEndpoints {
  TOP_LINE_METRICS = 'top_line_metrics',
  JOURNEY_METRICS = 'journey_metrics',
  PERFORMANCE_METRICS = 'email_performance_metrics',
  SEND_EMAIL_STEP_PERFORMANCE_METRICS = 'send_email_step_performance_metrics',
}

interface Props {
  automationId: string;
  endpoint: AutomationMetricsEndpoints;
  timePeriod?: TimePeriod;
  subject?: string;
}

const useMetrics = ({ automationId, endpoint, timePeriod, subject }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchInsights = () =>
    api
      .get(`/automations/${automationId}/${endpoint}`, {
        params: {
          period: timePeriod,
          publication_id: currentPublicationId,
          subject_line: subject,
        },
      })
      .then((res) => res.data);

  return useQuery(['automations', automationId, endpoint, timePeriod], fetchInsights, {
    retryOnMount: false,
  });
};

export default useMetrics;
