import { useQuery } from 'react-query';

import { AdNetworkIndustryGroup } from '@/interfaces/ad_network/internal/industry_group';

import api from '../../../services/swarm';

export default function useIndustryGroup({ id }: { id: string }) {
  return useQuery<AdNetworkIndustryGroup>(
    ['ad_network', 'internal', 'industry_groups', id],
    () => api.get(`/ad_network/internal/industry_groups/${id}`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
