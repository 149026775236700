import { AutomationConditionOperator } from '@/interfaces/automations/automation_condition';

export const needsConditionInput = (operator?: string) => {
  if (!operator) {
    return true;
  }

  if (
    [AutomationConditionOperator.EXISTS, AutomationConditionOperator.DOES_NOT_EXIST].includes(
      operator as AutomationConditionOperator
    )
  ) {
    return false;
  }

  return true;
};
