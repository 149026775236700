import { useCallback, useEffect, useMemo, useState } from 'react';
import { Palette } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { useSite } from '@/hooks/useSite';
import { useSiteThemes } from '@/hooks/useSiteThemes';
import { SiteTheme, WebTheme } from '@/interfaces/web_theme';

import { Button } from '../../../../../UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../UI/Popover';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../../../../UI/Select';
import { Text } from '../../../../../UI/Text';
import { Tooltip } from '../../../../../UI/Tooltip';
import { AttributeSettingProps } from '../../../types';

import { THEME_ATTRS } from './consts';

const ThemeSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { data: currentPublication } = useCurrentPublication();
  const { data: siteThemesData } = useSiteThemes();
  const { data: site } = useSite();
  const { activeNode } = activeNodeResult;

  const [selectedTheme, setSelectedTheme] = useState<SiteTheme | null>(null);

  const siteThemes = useMemo(() => siteThemesData?.pages.flatMap((page) => page.site_themes) || [], [siteThemesData]);

  const hasSiteThemes = siteThemes.length > 0;

  const primaryTheme = useMemo(() => {
    return siteThemes.find((siteTheme) => siteTheme.is_primary);
  }, [siteThemes]);

  useEffect(() => {
    const hoverStorage = editor.storage.hover;

    if (hoverStorage.primaryTheme && hoverStorage.themeRules) {
      return;
    }

    if (primaryTheme && editor && site?.theme_rules) {
      hoverStorage.primaryTheme = primaryTheme.data;
      hoverStorage.themeRules = site.theme_rules;
    }
  }, [primaryTheme, editor, site?.theme_rules]);

  useEffect(() => {
    if (currentPublication?.web_theme && hasSiteThemes && !selectedTheme) {
      const theme = siteThemes.find((siteTheme) => siteTheme.is_primary);
      if (theme) {
        setSelectedTheme(theme);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedTheme, selectedTheme, hasSiteThemes]);

  const onApplyTheme = useCallback(() => {
    if (!site?.theme_rules) return;
    if (!selectedTheme) return;
    if (!activeNode) return;

    editor
      .chain()
      .focus()
      .applyTheme({
        theme: selectedTheme.data,
        mapping: site?.theme_rules,
      })
      .run();
  }, [editor, site?.theme_rules, selectedTheme, activeNode]);

  return (
    <Popover>
      <Tooltip center="Apply Theme" delay={300}>
        <PopoverTrigger asChild>
          <Button variant="secondary" size="sm" LeftIcon={Palette} className="text-wb-secondary" onClick={() => {}} />
        </PopoverTrigger>
      </Tooltip>

      <PopoverContent className="w-[325px] p-0" align="start" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Theme
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Select
              defaultValue={selectedTheme?.name || 'Theme'}
              onValueChange={(value: string) => {
                const theme = siteThemes.find((siteTheme) => siteTheme.name === value);
                if (theme) {
                  setSelectedTheme(theme);
                }
              }}
            >
              <SelectTrigger className="w-full" id="comment_ordering">
                <SelectValue placeholder="Select an order" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {siteThemes.map((theme) => (
                    <SelectItem key={theme.id} value={theme.name}>
                      {theme.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>

            {THEME_ATTRS.map((attr) => {
              const normalizedAttr = attr.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
              const value = selectedTheme?.data?.[attr as keyof WebTheme];

              return (
                <div key={attr} className="flex items-center justify-between gap-2">
                  <Text size="xs" weight="medium" variant="secondary">
                    {normalizedAttr}
                  </Text>
                  <div className="flex items-center gap-2">
                    <Text size="xs" weight="medium">
                      {value}
                    </Text>
                    {value?.includes('#') && (
                      <div
                        className="w-4 h-4 rounded-md shadow-sm border border-wb-secondary"
                        style={{ backgroundColor: value }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <Button variant="primary" onClick={onApplyTheme}>
            Apply Theme
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ThemeSettings;
