import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkAdvertisement } from '../../../interfaces/ad_network/internal/advertisement';
import { MutationErrorResponse } from '../../../interfaces/ad_network/internal/mutation_error_response';
import api from '../../../services/swarm';

export default function useAdvertisementUpdate({ id }: { id: string }) {
  const navigate = useNavigate();

  const updateAdvertisement = (formData: FormData): Promise<AdNetworkAdvertisement> =>
    api.patch(`/ad_network/internal/advertisements/${id}`, formData).then((res) => res.data);

  const queryClient = useQueryClient();

  return useMutation<AdNetworkAdvertisement, MutationErrorResponse, FormData>(updateAdvertisement, {
    onSuccess: () => {
      toast.success('Advertisement updated successfully');
      navigate(`/ad_network/advertisements/${id}`);

      queryClient.invalidateQueries(['ad_network', 'internal', 'advertisements', id], { exact: false });
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
