import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';

import { API } from '@/components/TiptapEditor/lib/api';
import { Asset } from '@/interfaces/asset';

export const useUploader = ({
  publicationId,
  onUpload,
}: {
  publicationId: string;
  onUpload: (asset: Asset) => void;
}) => {
  const [loading, setLoading] = useState(false);

  const uploadFile = useCallback(
    async (file: File) => {
      setLoading(true);
      try {
        const res = await API.uploadPublicationAsset({ file, publicationId, alt: '', title: '' });
        onUpload({ ...res.data } as Asset);
      } catch (errPayload: any) {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      }
      setLoading(false);
    },
    [onUpload, publicationId]
  );

  return { loading, uploadFile };
};
