import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '../../services/swarm';
import useCurrentPublicationId from '../usePublications/useCurrentPublicationId';

const useDeleteCustomDomain = () => {
  const queryClient = useQueryClient();
  const currentPublicationId = useCurrentPublicationId();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation((customDomainId: string) => api.delete(`/custom_domains/${customDomainId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['custom_domains', currentPublicationId]);
      toast.success('Custom Domain Removed');
    },
  });
};

export default useDeleteCustomDomain;
