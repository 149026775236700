import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import { WebhookMessageAttempt } from '@/interfaces/webhooks/message_attempt';

import StatusBadge from './StatusBadge';

type ListRowProps = {
  messageAttempt: WebhookMessageAttempt;
};

const ListRow = ({ messageAttempt }: ListRowProps) => {
  const navigate = useNavigate();
  return (
    <tr key={messageAttempt.id}>
      <td className="whitespace-break-spaces py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <StatusBadge messageAttempt={messageAttempt} />
      </td>
      <td className="hidden whitespace-break-spaces px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {messageAttempt.message.event_type}
      </td>
      <td className="hidden whitespace-break-spaces px-3 py-4 text-sm text-gray-500 lg:table-cell">
        {messageAttempt.message.id}
      </td>
      <td className="whitespace-break-spaces px-3 py-4 text-sm text-gray-500">
        <time>{moment(messageAttempt.timestamp).format('LLL')}</time>
      </td>
      <td className="whitespace-break-spaces py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button
          type="button"
          className="text-gray-600 hover:text-gray-900"
          onClick={() =>
            navigate(
              `/settings/integrations/webhooks/endpoints/${messageAttempt.endpoint_id}/messages/${messageAttempt.message_id}/message_attempts/${messageAttempt.id}`
            )
          }
        >
          <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
        </button>
      </td>
    </tr>
  );
};

export default ListRow;
