import toast from "react-hot-toast";

import { PageRoute } from "@/interfaces/dream_builder/page_route";

import { updateRouteAtPath } from "./updateRouteAtPath";


export function renameSlugOnPath(route: PageRoute, parentPath: string[], originalSlug: string, newSlug: string): PageRoute | null {
  let error = false;
  const newRoute = updateRouteAtPath(route, parentPath, (foundRoute: PageRoute) => {

    if (!foundRoute.children) {
      toast.error('No children found');
      error = true;
      return foundRoute;
    }

    if (!(originalSlug in foundRoute.children)) {
      toast.error(`No child found at path ${parentPath.join('/')}/${originalSlug}`);
      error = true;
      return foundRoute;
    }

    if (newSlug in foundRoute.children) {
      toast.error('That slug is already taken');
      error = true;
      return foundRoute;
    }

    const updatedChildren = { ...foundRoute.children };
    updatedChildren[newSlug] = updatedChildren[originalSlug];
    delete updatedChildren[originalSlug];

    const updatedChildrenKeys = foundRoute.children_keys?.map((slug) =>
      (slug === originalSlug ? newSlug : slug)
    );

    return {
      ...foundRoute,
      children: updatedChildren,
      children_keys: updatedChildrenKeys,
    };
  });

  if (error) {
    return null;
  }

  return newRoute;
}
