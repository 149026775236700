import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LockClosedIcon, TrashIcon } from '@heroicons/react/20/solid';

import SegmentSelect from '@/components/SegmentSelect';
import { Skeleton } from '@/components/SkeletonLoader';
import Text from '@/components/Text';
import { UpgradeIntent } from '@/components/UpgradeIntent';
import { useSettings } from '@/context/settings-context';
import { useCreatePostTarget } from '@/hooks/usePostTargets';
import { PostTarget, PostTargetAction, PostTargetPlatform } from '@/interfaces/post_target';
import { IntentAction } from '@/interfaces/upgrades';
import { Button } from '@/ui/Button';
import { capitalize } from '@/utils';
import { PLAN } from '@/utils/plans';

import { useDeliveryContext } from '../context/DeliveryContext';

import SegmentListItem from './SegmentListItem';

const MAX_NUM_SEGMENT_TARGETS = 5;

interface SegmentOptionProps {
  sectionTitle: string;
  postSendTargets: PostTarget[];
  readOnly: boolean;
  action: PostTargetAction;
}

const LoadingPostTarget = () => (
  <li
    key="loading-post-send-target"
    className="flex justify-between py-2 items-center border-b border-b-gray-200 last:border-b-0 first:pt-0 last:pb-0"
  >
    <div className="space-y-2">
      <div className="w-36">
        <Skeleton className="h-4" />
      </div>
      <div className="w-20">
        <Skeleton className="h-4" />
      </div>
    </div>
    <button type="button" onClick={() => {}} className="text-gray-400 hover:text-gray-600 p-1">
      <TrashIcon width={16} height={16} />
    </button>
  </li>
);

const SegmentList: React.FC<SegmentOptionProps> = ({ sectionTitle, postSendTargets, readOnly, action }) => {
  const { postId } = useParams<'postId'>() as { postId: string };

  const segmentTargets = useMemo(() => postSendTargets.filter((t) => t.receiver_type === 'Segment'), [postSendTargets]);
  const filteredSegmentTargets = useMemo(
    () => postSendTargets.filter((t) => t.receiver_type === 'Segment' && t.action === action),
    [postSendTargets, action]
  );

  const createMutation = useCreatePostTarget(postId, PostTargetPlatform.EMAIL);

  const [showSelect, setShowSelect] = useState(false);
  const [upgradeIntentOpen, setUpgradeIntentOpen] = useState(false);
  const { isChanging, setIsChanging } = useDeliveryContext();

  const { settings } = useSettings();
  const upsellSegments = !settings?.send_to_segments;

  const showButton =
    !readOnly &&
    !showSelect &&
    segmentTargets.length < (createMutation.isLoading ? MAX_NUM_SEGMENT_TARGETS - 1 : MAX_NUM_SEGMENT_TARGETS);

  const onSegmentSelect = (segmentId: string) => {
    setIsChanging(true);
    createMutation
      .mutateAsync({
        params: {
          action,
          receiver_id: segmentId,
          receiver_type: 'Segment',
        },
      })
      .finally(() => setIsChanging(false));

    setShowSelect(false);
  };

  return (
    <div className="px-4 space-y-2">
      <UpgradeIntent
        isOpen={upgradeIntentOpen}
        intentAction={IntentAction.EMAIL_A_SEGMENT}
        onClose={() => setUpgradeIntentOpen(false)}
        preselectedPlan={PLAN.SCALE}
      />
      <Text size="sm" type="bold">
        {sectionTitle}
      </Text>
      {filteredSegmentTargets.length === 0 && !createMutation.isLoading ? (
        <Text size="sm" className="mt-2">
          None Selected
        </Text>
      ) : (
        <ul className="bg-gray-50 px-4 py-2 rounded-md">
          {filteredSegmentTargets.map((postTarget) => (
            <SegmentListItem postTarget={postTarget} readOnly={readOnly} />
          ))}
          {createMutation.isLoading && <LoadingPostTarget />}
        </ul>
      )}
      <div className="pt-2">
        {showSelect && <SegmentSelect labelText="" onSelect={onSegmentSelect} onClear={() => setShowSelect(false)} />}
        {showButton && (
          <Button
            type="button"
            variant="primary-inverse"
            className="disabled:cursor-not-allowed"
            onClick={() => (upsellSegments ? setUpgradeIntentOpen(true) : setShowSelect(true))}
            disabled={createMutation.isLoading || isChanging}
            Icon={upsellSegments ? LockClosedIcon : undefined}
          >
            {capitalize(action)} a segment
          </Button>
        )}
      </div>
    </div>
  );
};

export default SegmentList;
