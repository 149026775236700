import { useParams } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/20/solid';

import PostStatusBadge from '@/components/_domain/PostStatusBadge';
import { BackLink } from '@/components/BackLink';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import LoadingBox from '@/components/LoadingBox';
import { useSettings } from '@/context/settings-context';
import { usePost } from '@/hooks';
import { Post } from '@/interfaces/post';

import Details from './Details';

interface RouteParams {
  postId: string;
  factPostClickId: string;
}

const ClickDetails = () => {
  const { postId, factPostClickId } = useParams() as unknown as RouteParams;
  const postQuery = usePost({ id: postId });
  const { data: post, isLoading, isError } = postQuery;

  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  if (isV2) {
    return (
      <LoadingBox isLoading={isLoading} isError={isError}>
        <div>
          {post ? (
            <Breadcrumbs>
              <Breadcrumbs.Item to="/">
                <HomeIcon className="w-4 h-4" />
              </Breadcrumbs.Item>
              <Breadcrumbs.Item to="/posts">All Posts</Breadcrumbs.Item>
              <Breadcrumbs.Item to={`/posts/${post.id}`}>{post.web_title}</Breadcrumbs.Item>
              <Breadcrumbs.Item to={`/posts/${post.id}/analytics`}>Analytics</Breadcrumbs.Item>
              <Breadcrumbs.Item to={`/posts/${post.id}/analytics/${factPostClickId}`}>Click Details</Breadcrumbs.Item>
            </Breadcrumbs>
          ) : null}
          <Details post={post as Post} factPostClickId={factPostClickId} />
        </div>
      </LoadingBox>
    );
  }

  return (
    <>
      <div className="pb-6">
        <BackLink to={`/posts/${postId}/analytics/`}>Back to Analytics</BackLink>
      </div>
      <LoadingBox isLoading={isLoading} isError={isError}>
        <>
          <div className="mb-2">{post?.status && <PostStatusBadge status={post.status} />}</div>
          <div className="flex items-center">
            <h1 className="text-2xl font-bold mr-2 max-w-4xl">{post?.web_title}</h1>
          </div>
          <Details post={post as Post} factPostClickId={factPostClickId} />
        </>
      </LoadingBox>
    </>
  );
};

export default ClickDetails;
