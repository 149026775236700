import { Link } from 'react-router-dom';
import { CaretRight, DiamondsFour, Folder, Layout, Question } from '@phosphor-icons/react';

import { Button } from '../UI/Button';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

import { EDITING_BREADCRUMB_TEXT_BY_TYPE, EDITING_TOOLTIP_BREADCRUMB_TEXT_BY_TYPE, TYPE_NAMES } from './constants';
import { HeaderType } from './types';

type EditorHeaderBreadcrumbProps = {
  buttonClassName?: string;
  text: string;
  linkTo: string;
  iconType: 'component' | 'layout' | 'folder';
};

const ICON_BY_TYPE = {
  component: DiamondsFour,
  layout: Layout,
  folder: Folder,
};

export const EditorHeaderBreadcrumb = ({ buttonClassName, text, linkTo, iconType }: EditorHeaderBreadcrumbProps) => {
  return (
    <>
      <Link to={linkTo}>
        <Button
          variant="ghost"
          className={buttonClassName}
          size="sm"
          LeftIcon={ICON_BY_TYPE[iconType]}
          iconWeight="bold"
        >
          {text}
        </Button>
      </Link>
      <CaretRight size={12} weight="bold" className="text-wb-tertiary" />
    </>
  );
};

export const EditorHeader = ({
  type,
  name,
  children,
  iconType = 'component',
}: {
  type: HeaderType;
  name?: string;
  children?: React.ReactNode;
  iconType?: 'component' | 'layout';
}) => {
  return (
    <div className="flex flex-wrap items-center gap-2 py-4 w-full">
      {children}
      <Button variant="accent-soft" size="sm" LeftIcon={ICON_BY_TYPE[iconType]} iconWeight="fill">
        {name || TYPE_NAMES[type]}
      </Button>
      <div className="flex items-center gap-1 px-1">
        <Text size="xs" weight="medium" variant="secondary">
          {EDITING_BREADCRUMB_TEXT_BY_TYPE[type]}
        </Text>
        <Tooltip
          center={EDITING_TOOLTIP_BREADCRUMB_TEXT_BY_TYPE[type]}
          className="flex-1 flex items-center cursor-pointer"
        >
          <Question size={12} className="text-wb-secondary" weight="bold" />
        </Tooltip>
      </div>
    </div>
  );
};
