import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
// import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { url } from 'inspector';

import { embeds as services, EmbedService } from '../../lib/constants';

import { ServiceEmbedView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    serviceEmbed: {
      setServiceEmbed: (attributes?: { url: string; service?: string | undefined }) => ReturnType;
    };
  }
}

export const ServiceEmbed = Node.create({
  name: 'serviceEmbed',

  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      url: {
        parseHTML: (element) => element.getAttribute('data-url'),
        renderHTML: (attributes) => ({
          'data-url': attributes.url,
        }),
      },
      service: {
        parseHTML: (element) => element.getAttribute('data-service'),
        renderHTML: (attributes) => ({
          'data-service': attributes.service,
        }),
      },
      data: {
        rendered: false,
      },
      error: {
        rendered: false,
      },
      hasFetched: {
        rendered: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  // addPasteRules() {
  //   return [
  //     nodePasteRule({
  //       find: services.youtube.regex,
  //       type: this.type,
  //       getAttributes: (match) => {
  //         return { service: 'youtube', url: match.input };
  //       },
  //     }),
  //     nodePasteRule({
  //       find: services.tiktok.regex,
  //       type: this.type,
  //       getAttributes: (match) => {
  //         return { service: 'tiktok', url: match.input };
  //       },
  //     }),
  //     nodePasteRule({
  //       find: services.instagram.regex,
  //       type: this.type,
  //       getAttributes: (match) => {
  //         return { service: 'instagram', url: match.input };
  //       },
  //     }),
  //     nodePasteRule({
  //       find: services.twitter.regex,
  //       type: this.type,
  //       getAttributes: (match) => {
  //         return { service: 'twitter', url: match.input };
  //       },
  //     }),
  //   ];
  // },

  addCommands() {
    return {
      setServiceEmbed:
        (options: { url: string; service?: string } | undefined) =>
        ({ chain }) => {
          const embedUrl =
            options?.service === EmbedService.TWITTER ? options?.url.replace('x.com', 'twitter.com') : options?.url;

          return chain()
            .focus()
            .selectParentNode()
            .insertContent({
              type: 'serviceEmbed',
              attrs: {
                url: embedUrl,
                service: options?.service,
              },
            })
            .run();
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ServiceEmbedView);
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      new Plugin({
        key: new PluginKey('serviceEmbedPasteHandler'),
        props: {
          handleDOMEvents: {
            paste(_view, event) {
              const { selection } = editor.state;
              const { $anchor, empty } = selection;
              const isRootDepth = $anchor.depth === 1;
              const isEmptyTextBlock =
                $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && !$anchor.parent.textContent;
              const isParagraph = editor.state.selection.$head.parent.type.name === 'paragraph';
              const isInSection = editor.isActive('section');
              const isSectionDepth = $anchor.depth === 2;

              if (
                !(empty && isRootDepth && isEmptyTextBlock && isParagraph && editor.isEditable) &&
                !(isInSection && isSectionDepth && isEmptyTextBlock && isParagraph && editor.isEditable)
              ) {
                return false;
              }

              const pastedText = event.clipboardData?.getData('text/plain');
              const service =
                Object.entries(services).find(([, serviceSpecs]) => pastedText?.match(serviceSpecs.regex))?.[0] ||
                undefined;

              if (service && service !== 'generic') {
                event.preventDefault();

                if (!pastedText) {
                  return false;
                }

                editor.commands.setServiceEmbed({ url: pastedText, service });
              }
              return false;
            },
          },
        },
      }),
    ];
  },
});

export default ServiceEmbed;
