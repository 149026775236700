interface Props {
  className?: string;
}

const Unfold = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_34_3337)">
      <path
        d="M5.29289 11.7071C4.90237 11.3166 4.90237 10.6834 5.29289 10.2929L6.58579 9L5.29289 7.70711C4.90237 7.31658 4.90237 6.68342 5.29289 6.29289C5.68342 5.90237 6.31658 5.90237 6.70711 6.29289L8.70711 8.29289C9.09763 8.68342 9.09763 9.31658 8.70711 9.70711L6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H15C16.6569 18 18 16.6569 18 15V3C18 1.34315 16.6569 0 15 0H3ZM3 2C2.44772 2 2 2.44772 2 3V15C2 15.5523 2.44772 16 3 16H12C12.5523 16 13 15.5523 13 15V3C13 2.44772 12.5523 2 12 2H3Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_34_3337">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Unfold;
