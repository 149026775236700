import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import * as DialogModal from '@/components/DialogModal';
import IconButton from '@/components/IconHelpers/IconButton';
import { Asset } from '@/interfaces/asset';

import { CropModalContent } from '../components/CropModalContent';

export type CropModalProps = {
  asset: Asset;
  assetId: string;
  publicationId: string;
  open: boolean;
  onClose: () => void;
  onChange: (asset: Asset) => void;
  onCreate: (asset: Asset) => void;
};

export const CropModal = ({ asset, open, onClose, onChange, onCreate, assetId, publicationId }: CropModalProps) => {
  const assetFileName = asset.url.split('/').pop()?.split('?')[0];

  return (
    <DialogModal.Wrapper className="px-6 pt-3 pb-6" open={open} onClose={onClose}>
      <DialogModal.Header className="mb-4" title="Cropping" onClose={onClose}>
        <IconButton onClick={onClose}>
          <ArrowLeftIcon className="w-5 h-5" />
        </IconButton>
      </DialogModal.Header>
      {open ? (
        <CropModalContent
          assetId={assetId}
          onClose={onClose}
          fileName={assetFileName || Date.now().toString()}
          onChange={onChange}
          onCreate={onCreate}
          publicationId={publicationId}
        />
      ) : null}
    </DialogModal.Wrapper>
  );
};
