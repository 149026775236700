/**
 * Any changes here should also be reflected in `app/models/concerns/engagement/calculations.rb` and corresponding
 * calculations.
 */

import {
  ClickRateCalculation,
  ErrorRateCalculation,
  OpenRateCalculation,
  SpamRateCalculation,
  UnsubscribeRateCalculation,
} from './calculations.types';

export const openRate: OpenRateCalculation = ({ totalDelivered, totalUniqueOpened }) => {
  if (!totalDelivered) return 0;

  return Number((((totalUniqueOpened || 0) / totalDelivered) * 100).toFixed(2));
};

export const clickRate: ClickRateCalculation = ({ totalClicked, totalViewed }) => {
  if (!totalViewed) return 0;

  return Number((((totalClicked || 0) / totalViewed) * 100).toFixed(2));
};

export const errorRate: ErrorRateCalculation = ({ totalErrored, totalSent }) => {
  if (!totalSent) return 0;

  return Number((((totalErrored || 0) / totalSent) * 100).toFixed(2));
};

export const spamRate: SpamRateCalculation = ({ totalDelivered, totalSpamReported }) => {
  if (!totalDelivered) return 0;

  return Number((((totalSpamReported || 0) / totalDelivered) * 100).toFixed(2));
};

export const unsubscribeRate: UnsubscribeRateCalculation = ({ totalDelivered, totalUnsubscribes }) => {
  if (!totalDelivered) return 0;

  return Number((((totalUnsubscribes || 0) / totalDelivered) * 100).toFixed(2));
};
