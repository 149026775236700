interface Props {
  className?: string;
  stopColorOverride?: string;
}

const SvgLogo = ({ className, stopColorOverride }: Props) => (
  <svg
    viewBox="0 0 158.97 149.83"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="linear-gradient" x1="4.7" x2="139.4" y1="-39.46" y2="137.72">
        <stop offset={0} stopColor={stopColorOverride || '#f64f59'} />
        <stop offset="0.5" stopColor={stopColorOverride || '#c471ed'} />
        <stop offset={1} stopColor={stopColorOverride || '#12c2e9'} />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="22.55"
        x2="157.24"
        xlinkHref="#linear-gradient"
        y1="-53.03"
        y2="124.15"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="-12.58"
        x2="122.12"
        xlinkHref="#linear-gradient"
        y1="-26.33"
        y2="150.85"
      />
      <linearGradient
        id="linear-gradient-4"
        x1="-6.27"
        x2="128.42"
        xlinkHref="#linear-gradient"
        y1="-31.12"
        y2="146.06"
      />
      <linearGradient
        id="linear-gradient-5"
        x1="-55.58"
        x2="79.12"
        xlinkHref="#linear-gradient"
        y1="6.36"
        y2="183.54"
      />
    </defs>
    <g>
      <path
        d="M138.18,70.42H20.79A5.44,5.44,0,0,1,15.35,65V56.74a17,17,0,0,1,17-17h94.19a17,17,0,0,1,17,17V65A5.43,5.43,0,0,1,138.18,70.42Z"
        fill="url(#linear-gradient)"
      />
      <path
        d="M113.49,30.71h-68a5.54,5.54,0,0,1-5.54-5.54h0A25.17,25.17,0,0,1,65.11,0H93.86A25.17,25.17,0,0,1,119,25.17h0A5.54,5.54,0,0,1,113.49,30.71Z"
        fill="url(#linear-gradient-2)"
      />
      <path
        d="M143.61,79.42H15.35a15.36,15.36,0,0,0,0,30.71H61a18.86,18.86,0,0,1,37.06,0h45.6a15.36,15.36,0,1,0,0-30.71Z"
        fill="url(#linear-gradient-3)"
      />
      <path
        d="M138.13,119.13H98.35v30.7h30a15.35,15.35,0,0,0,15.35-15.35v-9.81A5.54,5.54,0,0,0,138.13,119.13Z"
        fill="url(#linear-gradient-4)"
      />
      <path
        d="M20.84,119.13a5.55,5.55,0,0,0-5.55,5.54v9.81a15.36,15.36,0,0,0,15.36,15.35h30v-30.7H20.84Z"
        fill="url(#linear-gradient-5)"
      />
    </g>
  </svg>
);

export default SvgLogo;
