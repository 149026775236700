import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { EmailMessage } from '@/interfaces/email_message';
import api from '@/services/swarm';

const useUpsellEmail = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<EmailMessage, Error>(
    ['upsell_email', currentPublicationId],
    () => api.get(`/upsell_email`, { params: { publication_id: currentPublicationId } }).then((res) => res.data),
    {
      enabled: !!currentPublicationId,
      keepPreviousData: true,
    }
  );
};

export default useUpsellEmail;
