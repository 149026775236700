import { useEffect, useMemo, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/react';

import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { FieldSet, InputField } from '../../../components/ui/Input';
import { Panel } from '../../../components/ui/Panel';
import { Tooltip } from '../../../components/ui/Tooltip';
import { BACKLINK_TYPE } from '../const';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const BacklinkDataPanel = ({
  parentRef,
  editor,
}: {
  parentRef: React.RefObject<HTMLElement>;
  editor: Editor;
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isPanelOpen) {
      const outsideClickHandler = (e: Event) => {
        if (
          !buttonRef.current?.contains(e.target as HTMLElement) &&
          !panelRef.current?.contains(e.target as HTMLElement)
        ) {
          setIsPanelOpen(false);
        }
      };

      document.addEventListener('click', outsideClickHandler);
      return () => {
        document.removeEventListener('click', outsideClickHandler);
      };
    }

    return () => {};
  }, [isPanelOpen]);

  const handleTitleChange = useMemo(() => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const { from } = editor.state.selection;

      editor.chain().updateAttributes(BACKLINK_TYPE, { title: e.currentTarget.value }).setNodeSelection(from).run();
    };
  }, [editor]);

  return (
    <Tippy
      placement="top-start"
      reference={parentRef.current}
      offset={[0, 4]}
      render={(attrs) =>
        isPanelOpen && (
          <Panel
            ref={panelRef}
            tabIndex={-1}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attrs}
          >
            <FieldSet>
              <InputField
                label="Backlink title"
                value={editor.getAttributes(BACKLINK_TYPE).title}
                onChange={handleTitleChange}
                placeholder="Title …"
              />
            </FieldSet>
          </Panel>
        )
      }
      interactive
      visible={isPanelOpen}
    >
      <div>
        <Tooltip title="Edit backlink information">
          <Button
            ref={buttonRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $active={isPanelOpen}
            $muted={isPanelOpen}
            $leftSlot={<Icon name="Edit" />}
            onClick={() => setIsPanelOpen(!isPanelOpen)}
          />
        </Tooltip>
      </div>
    </Tippy>
  );
};

export default BacklinkDataPanel;
