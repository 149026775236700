import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

const usePublicationUsers = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<
    Array<{
      bio: string | null;
      email: string;
      first_name: string;
      last_name: string;
      name: string;
      id: string;
      primary_publication_id: string;
      time_zone: string;
      twitter_handle: string | null;
      profile_picture?: {
        url: string;
        thumb: {
          url: string;
        };
      };
    }>,
    Error
  >(['publication_users', currentPublicationId], () =>
    api.get(`/publications/${currentPublicationId}/users`).then((res) => res.data)
  );
};

export default usePublicationUsers;
