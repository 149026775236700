import { useEffect, useRef } from 'react';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import { components } from '../constants';
import { ComponentKey } from '../types';

export const Draggable = ({ id, children }: React.PropsWithChildren<{ id: ComponentKey }>) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return () => {};

    return draggable({
      element: ref.current,
      getInitialDataForExternal: () => {
        return {
          'text/plain': JSON.stringify({ type: 'block', block: components[id].block() }),
        };
      },
    });
  }, [ref, id]);

  return (
    <div ref={ref} style={{ background: 'transparent', cursor: 'grab' }}>
      {children}
    </div>
  );
};
