import { Typography } from '@/components/Typography';
import FontPicker from '@/ui/Settings/FontPicker';

import { usePageContext } from '../Context/PageContext';
import GroupHeading from '../Helpers/GroupHeading';

interface Props {
  title: string;
  description: string;
  subTitle: string;
  label: string;
  onSave: (value: string) => void;
  value: string;
}

const GlobalTypographyForm = ({ title, description, subTitle, label, value, onSave }: Props) => {
  const { fontOptions } = usePageContext();

  return (
    <GroupHeading title={title} description={description}>
      <div className="flex flex-col space-y-3">
        <Typography token="font-semibold/text/xs" className="text-700">
          {subTitle}
        </Typography>
        <FontPicker
          label={label}
          searchPlaceholder="Search Font"
          value={value}
          noOptionsText="No Matching Fonts"
          options={fontOptions}
          onSave={(v: string) => onSave(v)}
        />
      </div>
    </GroupHeading>
  );
};

export default GlobalTypographyForm;
