import { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import ActionModal from "@/components/ActionModal";
import { Typography } from "@/components/Typography";
import { useCurrentPublication } from "@/hooks";
import { useUpdateAssignment } from "@/hooks/useAssignments";
import { Organization } from "@/interfaces/organization";
import { TeamMember } from "@/interfaces/team_member";
import analytics from '@/utils/analytics';

import { Assignment } from "./_interfaces/assignment";
import EditForm from "./EditForm";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
  contributorEnabled?: boolean;
  member: TeamMember;
}

const EditModal = ({isOpen, onClose, organization, contributorEnabled, member}: Props) => {
  const {data: publication} = useCurrentPublication();
  const publicationId = publication?.id || '';
  const queryClient = useQueryClient();

  const defaultAssignment: Assignment = {
    email: member.email,
    role: member.role_name,
    permission: member.permission === 'Organization' ? 'workspace' : 'publication',
    publicationId: member.assignable_type === 'Organization' ? '' : member.assignable_id,
    confirmation: false,
  };
  const [assignment, setAssignment] = useState<Assignment>(defaultAssignment);
  function hasAssignmentChanged(): boolean {
    const { confirmation: defaultConfirmation, ...defaultWithoutConfirmation } = defaultAssignment;
    const { confirmation: assignmentConfirmation, ...assignmentWithoutConfirmation } = assignment;

    return JSON.stringify(defaultWithoutConfirmation) !== JSON.stringify(assignmentWithoutConfirmation);
  }

  const assignmentId = parseInt(member.id, 10)
  const assignmentMutation = useUpdateAssignment(publicationId, assignmentId, member.user_id);
  const selectedAssignableType = assignment.permission === 'workspace' ? 'Organization' : 'Publication';
  const selectedAssignableId = assignment.permission === 'workspace' ? organization.id : assignment.publicationId;

  // Reset assignment when modal is closed
  useEffect(() => {
    setAssignment(defaultAssignment);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSubmit = async () => {
    try {
      await assignmentMutation.mutateAsync({
        assignment: {
          assignable_id: selectedAssignableId,
          assignable_type: selectedAssignableType,
        },
        role_name: assignment.role,
      });
      analytics.track('Edited Team Member Role');
      queryClient.invalidateQueries(['publication-team', publicationId]);
      onClose();
    } catch (err) {
      toast.error('There was an error updating the member role.');
    }
  };

  const handleAssignmentChange = (newAssignment: Assignment) => {
    setAssignment(newAssignment);
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      isWorking={false}
      onProceed={handleSubmit}
      resourceId={organization.id}
      headerText="Edit Team Member Permissions"
      actionText="Update Team Member Permissions"
      modalSize="lg"
      disabled={!assignment.confirmation || !hasAssignmentChanged()}
    >
      <Typography className="mb-4">
        Any changes will take effect immediately on you update. You can modify their permissions again at any time.{' '}
        <a href="/" target="_blank" className="text-primary-600 underline">
          Learn more about beehiiv team permissions
        </a>
      </Typography>
      <div className="my-6">
        <EditForm
          assignment={assignment}
          contributorEnabled={contributorEnabled}
          onAssignmentChange={handleAssignmentChange}
        />
      </div>
    </ActionModal>
  )
};

export default EditModal;
