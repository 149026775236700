import { FC, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import cx from 'classnames';
import styled from 'styled-components';

import { UpdateImagePanel } from '@/components/TiptapEditor/components/panels/UpdateImage';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Spinner } from '@/components/TiptapEditor/components/ui/Spinner';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { getFileSizeStringWithUnit } from '@/components/TiptapEditor/lib/utils/getFileSizeStringWithUnit';
import { Asset } from '@/interfaces/asset';

import { API } from '../../../lib/api';
import { ButtonContainer, ImageContainer, LoaderWrapper } from '../../GenericEmbed/views/GenericEmbedView.styled';
import { FILE_ATTACHMENT_TYPE } from '../constants';
import { IFileAttrs } from '../types';

import { Styled } from './FileAttachmentView.styled';
import { FileUploader } from './FileUploader';

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
`;

export const FileAttachmentView: FC<NodeViewProps> = ({ editor, node, getPos, updateAttributes }) => {
  const { publicationId } = usePublicationContext();

  const [isUploading, setIsUploading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const { type, size, id, src, title, description, thumbnailUrl, name } = node.attrs as IFileAttrs;

  const fileType = type?.split('/')?.[1]?.toUpperCase().split('+')[0];

  const onUpload = ({ file, fileAttachmentId }: { fileAttachmentId: string; file: File }) => {
    const from = getPos();

    const attrs: Partial<IFileAttrs> = {
      id: fileAttachmentId,
      src: '',
      size: getFileSizeStringWithUnit(file.size),
      type: file.type,
      title: title || file.name,
      name: '',
    };

    editor
      .chain()
      .deleteRange({ from: Math.max(0, from), to: from + 1 })
      .insertContentAt(from, {
        type: FILE_ATTACHMENT_TYPE,
        attrs,
      })
      .focus()
      .run();
  };

  const onUploadThumbnail = (file: File) => {
    setIsUploading(true);

    API.uploadPublicationAsset({
      file,
      publicationId,
    })
      .then((res) => {
        updateAttributes({ thumbnailUrl: String(res.data.url) });
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsUploading(false));
  };

  const onReplaceThumbnail = (asset: Asset) => updateAttributes({ thumbnailUrl: asset.url });

  const onRemoveThumbnail = () => updateAttributes({ thumbnailUrl: '' });

  useEffect(() => {
    const fetchFileAttachmentSrc = async () => {
      setIsLoading(true);

      try {
        const response = await API.getDownloadableData({
          downloadableId: id,
          publicationId,
        });

        const { url, name: fileName } = response.data;

        updateAttributes({ src: url, name: fileName });
      } catch (error) {
        toast.error('Failed to fetch file attachment');
      } finally {
        setIsLoading(false);
      }
    };

    if (!src && id) {
      fetchFileAttachmentSrc();
    }
  }, [src, id, updateAttributes, publicationId]);

  return (
    <NodeViewWrapper
      data-drag-handle
      data-type={FILE_ATTACHMENT_TYPE}
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <Wrapper data-drag-handle {...{ inert: editor.isEditable ? undefined : '' }}>
        {id ? (
          <Styled.DataContainer className="flex gap-2 bg-white">
            <ImageContainer>
              {isUploading ? (
                <LoaderWrapper>
                  <Spinner />
                </LoaderWrapper>
              ) : (
                <>
                  {thumbnailUrl ? (
                    <button
                      onClick={() => {
                        editor.commands.focus(getPos() + 2);
                      }}
                      type="button"
                      aria-label="Click to focus embed"
                    >
                      <img src={thumbnailUrl} alt="" contentEditable={false} />
                    </button>
                  ) : (
                    <Icon name="Attachment" $size="4rem" className="mt-1" />
                  )}

                  <ButtonContainer ref={buttonContainerRef}>
                    <UpdateImagePanel
                      tooltip="Upload or pick new file"
                      parentRef={buttonContainerRef}
                      onUpload={onUploadThumbnail}
                      onReplace={onReplaceThumbnail}
                      showRemoveOption={!!thumbnailUrl}
                      onRemove={onRemoveThumbnail}
                    />
                  </ButtonContainer>
                </>
              )}
            </ImageContainer>

            <Styled.TextContainer className="flex flex-col gap-2 flex-1">
              <Styled.Headline>{title}</Styled.Headline>

              <Styled.TextContainer>{description}</Styled.TextContainer>

              <Styled.TextContainer>
                {size} • {fileType} File
              </Styled.TextContainer>

              <Styled.Button
                href={src}
                target="_blank"
                className={cx('w-fit content-element-button', isLoading ? 'opacity-75' : '')}
                download={name}
                rel="noopener noreferrer"
                aria-label={`Download ${title}`}
              >
                Download
              </Styled.Button>
            </Styled.TextContainer>
          </Styled.DataContainer>
        ) : (
          <FileUploader onUpload={onUpload} />
        )}
      </Wrapper>
    </NodeViewWrapper>
  );
};

export default FileAttachmentView;
