import { useEffect } from 'react';

export const useEsc = (callback: () => void, ref?: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (!ref) {
        return;
      }

      const key = e.key || e.keyCode;

      if (key === 'Escape' || key === 'Esc' || key === 27) {
        callback();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [callback, ref]);
};
