import { forwardRef, useState } from 'react';
import { CaretRight, Cube, File, Icon as PhosphorIcon, Layout } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { Text } from '../../UI/Text';
import { Tooltip } from '../../UI/Tooltip';
import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';
import InsertTemplateModal from '../Templates/InsertTemplateModal';

import { Draggable } from './components/Draggable';
import { components, LABELS_BY_CATEGORY } from './constants';
import { ComponentKey } from './types';

const Item = forwardRef<HTMLDivElement, { id: ComponentKey }>(({ id, ...props }, ref) => {
  const IconComp = components[id].Icon;
  return (
    <Tooltip center={components[id].label} placement="bottom">
      <div ref={ref} {...props}>
        <div className="w-12 h-12 rounded-lg bg-wb-background flex items-center justify-center border border-wb-primary shadow-sm">
          <IconComp size={18} weight="bold" />
        </div>

        <Text
          as="p"
          weight="medium"
          variant="secondary"
          className="text-xs text-center text-ellipsis overflow-hidden whitespace-nowrap w-12 py-1"
        >
          {components[id].label}
        </Text>
      </div>
    </Tooltip>
  );
});

const CategorySection = ({ title, category }: { title: string; category: string }) => (
  <div className="flex flex-col gap-2">
    <Text size="xs" variant="secondary" weight="semibold">
      {title}
    </Text>
    <div className="flex gap-2 flex-wrap">
      {Object.keys(components)
        .filter((key) => components[key as ComponentKey].category === category)
        .map((key) => (
          <Draggable key={key} id={key as ComponentKey}>
            <Item id={key as ComponentKey} />
          </Draggable>
        ))}
    </div>
  </div>
);

const PublicationItem = ({
  icon: Icon,
  label,
  onClick,
}: {
  icon: PhosphorIcon;
  label: string;
  onClick: () => void;
}) => (
  <button
    type="button"
    className="flex items-center gap-2 w-full justify-between px-2 py-3 hover:bg-wb-secondary rounded-lg"
    onClick={onClick}
  >
    <div className="flex items-center gap-2">
      <div className="shadow w-6 h-6 rounded-md bg-wb-background border border-wb-primary border-solid flex items-center justify-center">
        <Icon size={16} weight="duotone" />
      </div>
      <Text size="xs" weight="medium">
        {label}
      </Text>
    </div>
    <CaretRight size={12} weight="bold" className="text-wb-secondary" />
  </button>
);

export const InsertPanel = ({
  editor,
  allowedCategories = ['templates', 'layout', 'widget', 'text', 'lists', 'media'],
}: {
  editor: Editor;
  allowedCategories?: string[];
}) => {
  const activeNodeResult = useActiveNode(editor);
  const [templateType, setTemplateType] = useState<'page' | 'section' | 'block'>('block');

  return (
    <div className="flex flex-col space-y-8 p-4">
      {allowedCategories.map((category) => {
        switch (category) {
          case 'templates':
            return (
              <>
                <div>
                  <Text size="xs" variant="secondary" weight="semibold">
                    Templates
                  </Text>
                  <InsertTemplateModal
                    shouldReplaceNode={false}
                    type={templateType as 'block' | 'section' | 'page'}
                    activeNodeResult={activeNodeResult}
                    actionButton={
                      <div>
                        <PublicationItem icon={File} label="Pages" onClick={() => setTemplateType('page')} />
                        <PublicationItem icon={Layout} label="Sections" onClick={() => setTemplateType('section')} />
                        <PublicationItem icon={Cube} label="Blocks" onClick={() => setTemplateType('block')} />
                      </div>
                    }
                    editor={editor}
                    insertPos={0}
                  />
                </div>
                <hr />
              </>
            );
          case 'layout':
          case 'widget':
          case 'text':
          case 'lists':
          case 'media':
            return <CategorySection title={LABELS_BY_CATEGORY[category]} category={category} />;
          default:
            return null;
        }
      })}
    </div>
  );
};
