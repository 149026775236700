import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import type { Blocker, History, Transition } from 'history';

/**
 * React Router v6 removed the ability to block navigation with a custom blocker/prompt. According to
 * some Github issues, they plan to add this back in a future release. When that happens, this hook can
 * be replaced with the hooks from react-router-dom.
 *
 * @see https://github.com/remix-run/react-router/blob/main/docs/upgrading/v5.md#prompt-is-not-currently-supported
 */
const useBlocker = (blocker: Blocker, when = true): void => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  useEffect(() => {
    if (!when) return () => {};

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return () => {
      unblock();
    };
  }, [navigator, blocker, when]);
};

export default useBlocker;
