import { useInfiniteQuery } from 'react-query';

import { Pagination } from '../../interfaces/general';
import { PollChoice } from '../../interfaces/poll';
import { ExtendedFeedback } from '../../interfaces/post_poll';
import api from '../../services/swarm';

interface ApiResponse {
  extended_feedback: ExtendedFeedback[];
  poll_choices: PollChoice[];
  pagination: Pagination;
}

export interface Filters {
  [key: string]: string;
}

export default function usePostPollFeedback(publicationId: string, { id, filters = {} }: { id: string; filters: Filters }) {
  const fetchFeedback = ({ pageParam = 1 }) =>
    api
      .get(`/post_polls/${id}/extended_feedback`, {
        params: {
          page: pageParam,
          per_page: 10,
          filters,
          publication_id: publicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['post_polls', id, 'extended_feedback', filters], fetchFeedback, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
