import cx from 'classnames';

import { Text } from '../UI/Text';

interface Props {
  title: string;
}

export const NavSectionTitle = ({ title }: Props) => {
  return (
    <div className={cx('p-2 space-x-2 w-full')}>
      <Text weight="medium" variant="secondary" size="xs">
        {title}
      </Text>
    </div>
  );
};
