import cx from 'classnames';

import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import { AutomationTriggerEvent } from '@/interfaces/automations/automation_trigger';
import Badge from '@/ui/Badge';
import pluralize from '@/utils/pluralize';

import ConditionsLabel from '../../components/ConditionsLabel';

import { AutomationTriggerDescriptions } from './constants';
import TierLabel from './TierLabel';

interface Props {
  index: number;
  event: AutomationTriggerEvent;
  mainConditions: AutomationConditions;
  isActive: boolean;
  conditionsCount?: number;
  hideDescription?: boolean;
  hideConditionsCount?: boolean;
}

const NewTriggerNode = ({
  index,
  event,
  mainConditions,
  isActive,
  conditionsCount = 0,
  hideConditionsCount = false,
  hideDescription = false,
}: Props) => {
  const conditionsCountText = pluralize('condition', conditionsCount);

  return (
    <div className={cx('flex gap-x-2', !isActive && 'opacity-50')}>
      <div className="flex-none w-8 text-right">
        <Badge size="sm" className="uppercase">
          {index === 0 ? 'If' : 'Or'}
        </Badge>
      </div>
      <div className="flex-1 space-y-2 pt-1">
        <div>
          <Text size="sm" type="label" className="text-gray-900">
            <TierLabel event={event} mainConditions={mainConditions} />
          </Text>
          {!hideDescription && AutomationTriggerDescriptions[event] && (
            <Text size="xs" type="body" className="text-gray-700">
              {AutomationTriggerDescriptions[event]}
            </Text>
          )}
        </div>
        {!hideConditionsCount && (
          <div className="w-fit">
            <Tooltip
              tooltipClass="text-center"
              id={`trigger-stat-${index}-conditions`}
              text={conditionsCountText}
              showIcon={false}
              autoWidth
            >
              <ConditionsLabel count={conditionsCount} showLabel={false} />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewTriggerNode;
