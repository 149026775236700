import { Recommendation } from '@shared/dream-components';

export const RECOMMENDATION_1 = {
  id: 'recommendation_1',
  name: 'The Daily Upside',
  description:
    'A free daily newsletter that breaks down the latest financial news, business insights, and investment tips for a smarter tomorrow.',
  logo_url:
    'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  sponsored: false,
};

export const RECOMMENDATION_2 = {
  id: 'recommendation_2',
  name: 'Market Maven',
  description:
    'A daily newsletter that provides deep insights into market trends, financial strategies, and emerging investment opportunities.',
  logo_url:
    'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  sponsored: false,
};

export const RECOMMENDATION_3 = {
  id: 'recommendation_3',
  name: 'The Financial Digest',
  description:
    'A weekly rundown of essential financial news, packed with insights and expert tips for savvy investors and finance enthusiasts.',
  logo_url:
    'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  sponsored: false,
};

export const RECOMMENDATION_4 = {
  id: 'recommendation_4',
  name: 'BizPulse',
  description:
    'Get the latest updates in business trends and economic insights delivered right to your inbox every weekday morning.',
  logo_url:
    'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  sponsored: true,
};

export const RECOMMENDATION_5 = {
  id: 'recommendation_5',
  name: 'Investry',
  description:
    'A monthly newsletter dedicated to helping new and seasoned investors make smarter decisions with easy-to-understand tips and trends.',
  logo_url:
    'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  sponsored: false,
};

export const RECOMMENDATION_6 = {
  id: 'recommendation_6',
  name: 'Insight Loop',
  description:
    'A curated weekly newsletter that compiles market data, economic shifts, and financial insights to keep you in the know.',
  logo_url:
    'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  sponsored: true,
};

export const dummyRecommendations: Recommendation[] = [
  {
    id: 'recommendation_1',
    name: 'The Daily Upside',
    description:
      'A free daily newsletter that breaks down the latest financial news, business insights, and investment tips for a smarter tomorrow.',
    logo_url:
      'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    site_url: '',
    recommended_publication_id: 'recommended_publication_1',
    sponsored: false,
  },
  {
    id: 'recommendation_2',
    recommended_publication_id: 'recommended_publication_2',
    name: 'Market Maven',
    description:
      'A daily newsletter that provides deep insights into market trends, financial strategies, and emerging investment opportunities.',
    logo_url:
      'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    site_url: '',
    sponsored: true,
  },
  {
    id: 'recommendation_3',
    recommended_publication_id: 'recommended_publication_3',
    name: 'The Financial Digest',
    description:
      'A weekly rundown of essential financial news, packed with insights and expert tips for savvy investors and finance enthusiasts.',
    logo_url:
      'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    site_url: '',
    sponsored: false,
  },
  {
    id: 'recommendation_4',
    recommended_publication_id: 'recommended_publication_4',
    name: 'BizPulse',
    description:
      'Get the latest updates in business trends and economic insights delivered right to your inbox every weekday morning.',
    logo_url:
      'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    site_url: '',
    sponsored: true,
  },
  {
    id: 'recommendation_5',
    recommended_publication_id: 'recommended_publication_5',
    name: 'Investry',
    description:
      'A monthly newsletter dedicated to helping new and seasoned investors make smarter decisions with easy-to-understand tips and trends.',
    logo_url:
      'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    site_url: '',
    sponsored: false,
  },
  {
    id: 'recommendation_6',
    recommended_publication_id: 'recommended_publication_6',
    name: 'Insight Loop',
    description:
      'A curated weekly newsletter that compiles market data, economic shifts, and financial insights to keep you in the know.',
    logo_url:
      'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    site_url: '',
    sponsored: true,
  },
];
