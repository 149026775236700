import { useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Input } from '@/components/Form';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import SniperLink from '@/components/SniperLink';
import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { Button } from '@/ui/Button';

import { Layout, LogoWrapper } from '../_components/Layout';
import useConfirmEmail from '../_hooks/useConfirmEmail';
import useRerequestConfirmationEmail from '../_hooks/useRequestConfirmationEmail';

const EmailConfirmation = () => {
  const localEmail = localStorage.getItem('email');
  const [email, setEmail] = useState(localEmail || '');
  const [confirmationEmailSent, setConfirmationEmailSent] = useState(false);

  const { isRequesting, errors } = useConfirmEmail();
  const rerequestConfirmationEmail = useRerequestConfirmationEmail();

  const handleSubmit = () => {
    rerequestConfirmationEmail
      .mutateAsync({ email })
      .then(() => {
        setConfirmationEmailSent(true);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        localStorage.setItem('email', email);
      });
  };

  return (
    <>
      <Helmet>
        <title>Registration - beehiiv</title>
      </Helmet>

      <Layout>
        <LogoWrapper
          title="Verify your email"
          subtitle={
            localEmail ? (
              <Typography token="font-normal/text/base" colorWeight="700">
                We sent an email to{' '}
                <Typography token="font-medium/text/base" colorWeight="900">
                  {email}
                </Typography>
                . Click the link inside to get started.
                <div className="mt-8">
                  <SniperLink recipient={email} sender="buzz@tx.beehiiv.com" />
                </div>
              </Typography>
            ) : (
              <Typography token="font-normal/text/base" colorWeight="700">
                Please enter your email below to resend the verification email.
              </Typography>
            )
          }
        >
          {isRequesting && !errors && (
            <TypographyRow gap="1" className="justify-center">
              <Typography weight="bold">Confirming signup...</Typography>
              <LoadingSpinner />
            </TypographyRow>
          )}

          {!isRequesting && errors && (
            <TypographyStack gap="2" className="text-center mb-5">
              <TypographyRow gap="4" align="center" className="justify-center">
                <XMarkIcon className="w-4 h-4 text-feedback-danger-600 shrink-0" />
              </TypographyRow>
            </TypographyStack>
          )}

          {!isRequesting &&
            localEmail &&
            (confirmationEmailSent ? (
              <Typography token="font-medium/text/sm" colorWeight="500">
                Check your email!
              </Typography>
            ) : (
              <TypographyRow>
                <Typography token="font-medium/text/sm" colorWeight="500">
                  Didn&apos;t receive an email?
                </Typography>
                <button type="button" onClick={handleSubmit}>
                  <Typography token="font-medium/text/sm" color="secondary" colorWeight="600">
                    Resend Email
                  </Typography>
                </button>
              </TypographyRow>
            ))}

          {!localEmail && (
            <div className="w-full space-y-5">
              <Input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="john.doe@email.com"
                className="w-full"
              />
              <Button
                variant="primary"
                type="button"
                onClick={handleSubmit}
                block
                loading={rerequestConfirmationEmail.isLoading}
              >
                Resend Email
              </Button>
            </div>
          )}

          {(!localEmail || errors) && (
            <TypographyStack gap="2" className="text-center pt-5">
              <Typography token="font-medium/text/sm" colorWeight="500">
                Please go back to{' '}
                <Link className="text-action-secondary-600 font-semibold" to="/signup">
                  create a new account
                </Link>{' '}
                if you have not already.
              </Typography>
            </TypographyStack>
          )}
        </LogoWrapper>
      </Layout>
    </>
  );
};

export default EmailConfirmation;
