import { useCallback, useEffect, useRef, useState } from 'react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import { EditLink } from '../../../components/menus';
import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { MenuProps } from '../../../components/menus/types';
import { forceUpdateTippy } from '../../../components/menus/utils/forceUpdateTippy';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const CaptionMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [editLink, setEditLink] = useState(false);
  const { id: imageId, captionUrl, captionTarget } = editor.getAttributes('imageBlock');

  const tippyInstance = useRef<Instance | null>(null);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'figcaption');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('imageBlock');

    return isActive;
  }, [editor]);

  const onClick = useCallback(
    (align: 'left' | 'center' | 'right') => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockCaptionAlign(align).run();
    },
    [editor]
  );

  // If the image changes, reset the "Edit link" panel
  useEffect(() => {
    setEditLink(false);
  }, [imageId]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`imageBlockMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, -12],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()} ref={menuRef}>
        {editLink ? (
          <EditLink
            link={captionUrl}
            target={captionTarget}
            onSetLink={(url: string) => {
              editor.commands.setImageBlockCaptionUrl(url);
              setEditLink(false);
            }}
            onSetTarget={(target: string) => {
              editor.commands.setImageBlockCaptionTarget(target);
            }}
            onUnsetLink={() => {
              editor.commands.setImageBlockCaptionUrl('');
              setEditLink(false);
              return null;
            }}
            onBack={() => {
              setEditLink(false);
              if (tippyInstance.current) {
                forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
              }
            }}
            editor={editor}
          />
        ) : (
          <>
            <Tooltip title="Link">
              <Button
                $leftSlot={<Icon name="Link" />}
                $active={captionUrl}
                onClick={() => {
                  setEditLink(true);
                  if (tippyInstance.current) {
                    forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
                  }
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Align left">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $active={editor.isActive('imageBlock', { captionAlign: 'left' })}
                $leftSlot={<Icon name="AlignCaptionLeft" />}
                onClick={() => {
                  onClick('left');
                }}
              />
            </Tooltip>
            <Tooltip title="Align center">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $active={editor.isActive('imageBlock', { captionAlign: 'center' })}
                $muted={editor.isActive('imageBlock', { captionAlign: 'center' })}
                $leftSlot={<Icon name="AlignCaptionCenter" />}
                onClick={() => {
                  onClick('center');
                }}
              />
            </Tooltip>
            <Tooltip title="Align right">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                $active={editor.isActive('imageBlock', { captionAlign: 'right' })}
                $leftSlot={<Icon name="AlignCaptionRight" />}
                onClick={() => {
                  onClick('right');
                }}
              />
            </Tooltip>
          </>
        )}
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default CaptionMenu;
