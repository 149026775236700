import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { DotsThree, Pencil, Trash } from '@phosphor-icons/react';

import { useDeleteTestimonial } from '@/hooks/useTestimonials';
import { Testimonial } from '@/interfaces/testimonial';

import { Button } from '../../../_components/UI/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../_components/UI/DropdownMenu';
import Modal from '../../../_components/UI/Modal';
import { TableCell, TableRow } from '../../../_components/UI/Table';
import { Text } from '../../../_components/UI/Text';

interface Props {
  testimonial: Testimonial;
  onEdit: (testimonial: Testimonial) => void;
  queryKey: string[];
}

const TestimonialRow = ({ testimonial, onEdit, queryKey }: Props) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);

  const deleteTestimonial = useDeleteTestimonial({
    testimonialId: testimonial?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const handleDelete = () => {
    deleteTestimonial.mutate();
  };

  return (
    <>
      <TableRow key={testimonial.id}>
        <TableCell className="font-medium">{testimonial.name}</TableCell>
        <TableCell className="italic">{`"${testimonial.message}"`}</TableCell>
        <TableCell className="text-right flex justify-end">
          <div className="w-10 h-10 rounded-full overflow-hidden">
            <img src={testimonial.picture_url} alt={testimonial.name} className="w-10 h-10 rounded-full object-cover" />
          </div>
        </TableCell>
        <TableCell className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <DotsThree className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={2} align="end" className="flex w-[150px] flex-col bg-wb-primary p-1">
              <DropdownMenuItem LeftIcon={Pencil} onSelect={() => onEdit(testimonial)}>
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem LeftIcon={Trash} onSelect={() => setIsOpen(true)}>
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Delete Signup Flow"
        onConfirm={handleDelete}
        isLoading={deleteTestimonial.isLoading}
        ctaVariant="danger"
        ctaText="Delete"
        isLoadingText="Deleting..."
      >
        <Text size="sm" weight="medium" variant="secondary" as="p" className="mb-4">
          Are you sure you want to delete this testimonial? This action cannot be undone.
        </Text>
      </Modal>
    </>
  );
};

export default TestimonialRow;
