interface Props {
  className?: string;
}

const WaitUntil = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4362_42796)">
      <path
        d="M5.91964 2.71419C6.88038 2.24339 7.93429 2 9 2C9.07977 2 9.15945 2.00136 9.239 2.00408C9.31772 2.00677 9.39648 2.01079 9.47524 2.01615C10.0262 2.05365 10.5033 1.63736 10.5408 1.08635C10.5783 0.535341 10.162 0.0582628 9.61102 0.0207677C9.51054 0.0139303 9.41006 0.00878661 9.30962 0.00532868L9.30727 0.00524586C9.21138 0.00197072 9.11536 0.000225848 9.01923 2.0533e-05L9 0C7.6298 -5.2577e-06 6.27477 0.312923 5.03954 0.918248C3.6209 1.61345 2.41024 2.66984 1.52927 3.98123C0.648287 5.29262 0.128024 6.8128 0.0207677 8.38898C-0.0864882 9.96517 0.223042 11.5418 0.918248 12.9605C1.61345 14.3791 2.66984 15.5898 3.98123 16.4707C5.29262 17.3517 6.8128 17.872 8.38898 17.9792C9.96517 18.0865 11.5418 17.777 12.9605 17.0818C14.3791 16.3866 15.5898 15.3302 16.4707 14.0188C16.5299 13.9307 16.5732 13.8366 16.6013 13.7399C16.6758 13.6721 16.7412 13.5919 16.7942 13.5C16.8455 13.4112 16.8952 13.3216 16.9433 13.2313C17.203 12.7438 17.0183 12.1382 16.5309 11.8785C16.0434 11.6189 15.4378 11.8035 15.1781 12.291C15.1407 12.3612 15.1021 12.4309 15.0622 12.5C15.021 12.5713 14.9896 12.6456 14.9676 12.7212C14.9094 12.7743 14.8565 12.8352 14.8106 12.9035C14.1254 13.9235 13.1838 14.7451 12.0804 15.2858C10.977 15.8265 9.75069 16.0673 8.52477 15.9839C7.29885 15.9004 6.11648 15.4958 5.09651 14.8106C4.07654 14.1254 3.25491 13.1838 2.71419 12.0804C2.17348 10.977 1.93273 9.75069 2.01615 8.52477C2.09957 7.29885 2.50422 6.11648 3.18943 5.09651C3.87464 4.07654 4.81625 3.25491 5.91964 2.71419Z"
        fill="currentColor"
      />
      <path
        d="M11.8785 1.46912C12.1382 0.981683 12.7438 0.797025 13.2313 1.05668C13.3216 1.10479 13.4112 1.15449 13.5 1.20577C13.5888 1.25705 13.6767 1.30978 13.7635 1.36395C14.2321 1.65626 14.375 2.27309 14.0827 2.74167C13.7904 3.21026 13.1735 3.35316 12.7049 3.06085C12.6374 3.01873 12.5691 2.97771 12.5 2.93782C12.4309 2.89794 12.3612 2.85928 12.291 2.82186C11.8035 2.5622 11.6189 1.95656 11.8785 1.46912Z"
        fill="currentColor"
      />
      <path
        d="M15.2583 3.91734C15.7269 3.62503 16.3437 3.76793 16.6361 4.23652C16.6902 4.32335 16.743 4.41119 16.7942 4.5C16.8455 4.58881 16.8952 4.6784 16.9433 4.76873C17.203 5.25617 17.0183 5.86181 16.5309 6.12146C16.0434 6.38111 15.4378 6.19646 15.1781 5.70902C15.1407 5.63877 15.1021 5.56908 15.0622 5.5C15.0223 5.43092 14.9813 5.3626 14.9391 5.29506C14.6468 4.82648 14.7897 4.20965 15.2583 3.91734Z"
        fill="currentColor"
      />
      <path
        d="M16.9612 7.72745C17.5132 7.7086 17.9759 8.14077 17.9948 8.69273C17.9982 8.79501 18 8.89745 18 9C18 9.10255 17.9982 9.20499 17.9948 9.30727C17.9759 9.85923 17.5132 10.2914 16.9612 10.2726C16.4092 10.2537 15.9771 9.79096 15.9959 9.239C15.9986 9.15945 16 9.07977 16 9C16 8.92023 15.9986 8.84055 15.9959 8.761C15.9771 8.20904 16.4092 7.7463 16.9612 7.72745Z"
        fill="currentColor"
      />
      <path
        d="M10 6.42859C10 5.8763 9.55229 5.42859 9 5.42859C8.44771 5.42859 8 5.8763 8 6.42859V9.00002C8 9.26523 8.10536 9.51959 8.29289 9.70712L10.8643 12.2786C11.2548 12.6691 11.888 12.6691 12.2785 12.2786C12.6691 11.888 12.6691 11.2549 12.2785 10.8643L10 8.5858V6.42859Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4362_42796">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WaitUntil;
