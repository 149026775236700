import { useNavigate } from 'react-router-dom';
import { ArrowTrendingUpIcon, BanknotesIcon, GiftIcon } from '@heroicons/react/20/solid';

import {
  AccordionCard,
  AccordionIconWrapper,
  AccordionListItem,
} from '../../../components/Accordion/variants/AccordionCard';

const EarnMoreActions = () => {
  const navigate = useNavigate();

  return (
    <AccordionCard
      title={
        <div className="flex items-center">
          <AccordionIconWrapper>
            <BanknotesIcon className="w-4 h-4 text-feedback-info-500" />
          </AccordionIconWrapper>
          Start earning more
        </div>
      }
      subText="Make more money with your list by monetizing your content."
    >
      <div className="space-y-1">
        <AccordionListItem
          title="Get an ad placement"
          icon={<ArrowTrendingUpIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/monetize/ads/pending')}
        />
        <AccordionListItem
          title="Find a publication to boost"
          icon={<GiftIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/monetize/boosts/marketplace')}
        />
        <AccordionListItem
          title="Set up paid subscriptions"
          icon={<BanknotesIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/monetize/subscriptions')}
        />
      </div>
    </AccordionCard>
  );
};

export default EarnMoreActions;
