import Modal from '../Modal';

interface VideoModalProps {
  videoUrl: string | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({ videoUrl, isOpen, onClose }) => {
  if (!videoUrl) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <iframe
        width={560 * 1.5}
        height={315 * 1.5}
        src={videoUrl}
        title="YouTube video player"
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Modal>
  );
};

export default VideoModal;
