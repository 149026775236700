import { Input } from '@/components/Form';
import TierSelect from '@/components/TierSelect';
import { AutomationConditionName } from '@/interfaces/automations/automation_condition';

import ChannelSelect from './ChannelSelect';
import { CONDITION_OPTIONS } from './constants';
import ExternalEmbedSelect from './ExternalEmbedSelect';
import SubscriberTagSelect from './SubscriberTagSelect';

interface ConditionInputProps {
  name: AutomationConditionName;
  value: string;
  hasErrors: boolean;
  onChange: (name: string, value: string | string[], resource_id?: string) => void;
}

const ConditionInput = ({ name, value, hasErrors, onChange }: ConditionInputProps) => {
  switch (name) {
    case 'channel':
      return <ChannelSelect value={value} onChange={onChange} />;
    case 'external_embed_id':
      return <ExternalEmbedSelect value={value} onChange={onChange} />;
    case 'tier_id':
      return <TierSelect tierId={value} includeFree onChange={(_n, v) => onChange('value', v)} />;
    case 'subscriber_tag_id':
      return <SubscriberTagSelect selectedTagId={value} onChange={(v) => onChange('value', v)} />;
    default:
      return (
        <Input
          type={CONDITION_OPTIONS.find((condition) => condition.value === name)?.dataType || 'text'}
          name="value"
          placeholderText="Set a value"
          value={value}
          onChange={(event) => onChange('value', event.target.value)}
          errorText={hasErrors ? 'This field is required' : undefined}
        />
      );
  }
};

export default ConditionInput;
