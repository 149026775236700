import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  paywallId: string;
  onSuccess: () => void;
}

const useDeletePaywall = ({ paywallId, onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation(() => api.delete(`/paywalls/${paywallId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.message || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['paywalls', currentPublicationId]);
      toast.success('Deleted!');
      onSuccess();
    },
  });
};

export default useDeletePaywall;
