import styled from 'styled-components';

export const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ScrollContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-height: 12rem;
    overflow-y: auto;
    padding-right: 4px;
    position: relative;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ::-webkit-scrollbar {
      height: 50px;
      width: 6px;
    }

    ::-webkit-scrollbar-button {
      height: 0;
      width: 0;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border: 0;
      border-radius: 70px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.3);
    }

    ::-webkit-scrollbar-thumb:active {
      background: rgba(255, 255, 255, 0.3);
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border: 0;
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  `,
  LoadingContainer: styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem 0 1rem 0;
  `,
  InputContainer: styled.div`
    margin-bottom: 0.5rem;
  `,
};

export default Styled;
