import { Typography, TypographyStack } from '@/components/Typography';
import { Card } from '@/ui/Card';

import CardHeader from '../../../../_components/CardHeader';
import getUsernameAndDomainFromEmail from '../../getUsernameAndDomainFromEmail';

import CodeBlock from './CodeBlock';
import { DEFAULT_EMAIL_DOMAIN } from './constants';

interface Props {
  publicationSubdomain?: string | undefined;
  shouldUseDefaultWebDomain?: boolean;
  webDomain?: string | null;
  redirectToWWW?: boolean;
  redirectDomainValue?: string;
  redirectToDomain?: string | null;
  shouldRedirectToDomain?: boolean;
  shouldUseCustomEmailDomain?: boolean;
  emailDomain?: string | null;
  shouldUseWebDomainAsEmailDomain?: boolean;
  shouldUseDefaultEmailDomain?: boolean;
  hideWebSection?: boolean;
  hideWeb?: boolean;
  hideWebRedirect?: boolean;
  hideEmailSection?: boolean;
}

const Summary = ({
  publicationSubdomain,
  shouldUseDefaultWebDomain,
  webDomain,
  redirectToWWW,
  redirectToDomain,
  redirectDomainValue,
  shouldRedirectToDomain,
  shouldUseCustomEmailDomain,
  emailDomain,
  shouldUseWebDomainAsEmailDomain,
  shouldUseDefaultEmailDomain,
  hideWebSection = false,
  hideWeb = false,
  hideWebRedirect = false,
  hideEmailSection = false,
}: Props) => {
  const { username: customEmailUsername, domain: customEmailDomain } = getUsernameAndDomainFromEmail(emailDomain || '');

  return (
    <Card className="flex flex-col gap-y-6">
      <CardHeader title="Summary" />

      {!hideWebSection ? (
        <>
          {!hideWeb && (
            <TypographyStack>
              <Typography token="font-normal/text/sm" colorWeight="600">
                Web Domain
              </Typography>
              <Typography token="font-medium/text/sm">
                {shouldUseDefaultWebDomain && <CodeBlock>{publicationSubdomain}.beehiiv.com</CodeBlock>}
                {!shouldUseDefaultWebDomain && webDomain && <CodeBlock>{webDomain}</CodeBlock>}
              </Typography>
            </TypographyStack>
          )}
          {!hideWebRedirect ? (
            <TypographyStack>
              <Typography token="font-normal/text/sm" colorWeight="600">
                Web Redirect Domain
              </Typography>
              <Typography token="font-normal/text/sm">
                {redirectToWWW || shouldRedirectToDomain ? (
                  <>
                    {shouldRedirectToDomain && redirectToDomain && <CodeBlock>{redirectToDomain}</CodeBlock>}
                    {!shouldRedirectToDomain && <CodeBlock>{redirectDomainValue}</CodeBlock>}
                  </>
                ) : (
                  <i>No redirect</i>
                )}
              </Typography>
            </TypographyStack>
          ) : null}
        </>
      ) : null}

      {!hideEmailSection ? (
        <>
          {(customEmailUsername || shouldUseDefaultEmailDomain) && (
            <TypographyStack>
              <Typography token="font-normal/text/sm" colorWeight="600">
                Email Username
              </Typography>
              <Typography token="font-medium/text/sm">
                {shouldUseCustomEmailDomain && customEmailUsername ? (
                  <CodeBlock>{customEmailUsername}</CodeBlock>
                ) : null}
                {shouldUseDefaultEmailDomain ? <CodeBlock>{publicationSubdomain}</CodeBlock> : null}
              </Typography>
            </TypographyStack>
          )}
          <TypographyStack>
            <Typography token="font-normal/text/sm" colorWeight="600">
              Email Domain
            </Typography>
            <Typography token="font-medium/text/sm">
              {shouldUseCustomEmailDomain && emailDomain ? <CodeBlock>{customEmailDomain}</CodeBlock> : null}
              {shouldUseWebDomainAsEmailDomain && webDomain ? <CodeBlock>{webDomain}</CodeBlock> : null}
              {shouldUseDefaultEmailDomain ? <CodeBlock>{DEFAULT_EMAIL_DOMAIN}</CodeBlock> : null}
            </Typography>
          </TypographyStack>
        </>
      ) : null}
    </Card>
  );
};

export default Summary;
