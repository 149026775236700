export const UrlPatterns = {
  patterns: {
    twitter: /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\?|\/|$)(\S+)?$/,
    youtube:
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
    generic: /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/,
    mailto: /^mailto:[\S]+$/,
    telephone: /^tel:[\d|\-|+]+$/,
    internal_links: /^{{ ?\w+ ?}}$/,
  },
  generic: 'generic',
  youtube: 'youtube',
  twitter: 'twitter',
};

export const validUrl = (url: string) =>
  UrlPatterns.patterns.generic.test(url) ||
  UrlPatterns.patterns.mailto.test(url) ||
  UrlPatterns.patterns.internal_links.test(url) ||
  UrlPatterns.patterns.telephone.test(url);
