import { useInfiniteQuery } from 'react-query';

import { AdNetworkCampaignPerformanceFilter, AdNetworkCampaignPerformanceOpportunity } from '@/interfaces/ad_network/internal/campaign_performance_opportunity';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  opportunities: AdNetworkCampaignPerformanceOpportunity[];
  pagination: Pagination;
}

export default function useCampaignOpportunities({
  advertiserId,
  campaignId,
  filter,
}: {
  advertiserId: string;
  campaignId: string;
  filter: AdNetworkCampaignPerformanceFilter;
}) {
  const fetchOpportunities = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/ad_network/advertiser_access/${advertiserId}/campaign_reports/${campaignId}/opportunities`, {
        params: {
          filter,
          page: pageParam,
          per_page: 25,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(
    ['ad_network', 'advertiser_access', advertiserId, 'campaign_reports', campaignId, 'opportunities'],
    fetchOpportunities,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
