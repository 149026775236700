import { Checkbox } from '../Form';

export type OptionProps = {
  label: string;
  value?: string;
  disabled?: boolean;
  color?: string;
  showCheckbox?: boolean;
  selected?: boolean;
};

type SelectCheckboxItemProps = {
  isLoading?: boolean;
  option: OptionProps;
  type?: 'label' | 'option';
  handleClick: (event: React.MouseEvent<HTMLButtonElement>, option: OptionProps) => void;
};

const SelectCheckboxItemLabel = ({ option }: { option: OptionProps }) => (
  <div className="p-2 mx-auto">{option.label}</div>
);

const SelectCheckboxItemOption = ({
  option,
  isLoading,
  handleClick,
}: {
  isLoading: boolean;
  option: OptionProps;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>, option: OptionProps) => void;
}) => (
  <button
    type="button"
    disabled={option.disabled || isLoading}
    onClick={(event) => handleClick(event, option)}
    className="flex w-full p-2 items-center"
  >
    {option.showCheckbox && (
      <Checkbox
        name={option.label}
        checked={option.selected as boolean}
        onChange={() => {}}
        className="mr-2 pointer-events-none"
        disabled={isLoading}
      />
    )}
    {option.label}
  </button>
);

const SelectCheckboxItem = ({ option, isLoading = false, handleClick, type = 'option' }: SelectCheckboxItemProps) => {
  return (
    <>
      {type === 'label' && <SelectCheckboxItemLabel option={option} />}
      {type === 'option' && (
        <SelectCheckboxItemOption isLoading={isLoading} option={option} handleClick={handleClick} />
      )}
    </>
  );
};

export default SelectCheckboxItem;
