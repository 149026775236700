import { List } from '../List';

import LoadingListItem from './LoadingListItem';

interface Props {
  rows?: number;
}

const LoadingList = ({ rows = 15 }: Props) => {
  return (
    <List>
      {Array.from(Array(rows).keys()).map((i) => (
        <LoadingListItem key={i} delay={500 + i * 500} />
      ))}
    </List>
  );
};

export default LoadingList;
