import { Editor } from '@tiptap/core';

import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';

export const RenderCondition = ({
  editor,
  allowedNodeTypes,
  children,
}: {
  editor: Editor;
  allowedNodeTypes: string[];
  children: React.ReactNode;
}) => {
  const { activeNodeType } = useActiveNode(editor);
  if (!activeNodeType || !allowedNodeTypes.includes(activeNodeType)) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
