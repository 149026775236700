import { useQuery } from 'react-query';

import { ExternalRssFeed } from '@/interfaces/external_rss_feed';
import api from '@/services/swarm';

import useCurrentPublicationId from '../usePublications/useCurrentPublicationId';

export default function useExternalRssFeeds() {
  const currentPublicationId = useCurrentPublicationId();

  return useQuery<ExternalRssFeed[]>([currentPublicationId, 'external_rss_feeds'], () =>
    api
      .get(`/publications/${currentPublicationId}/external_rss_feeds`, {})
      .then((res) => res.data.external_rss_feeds)
  );
}
