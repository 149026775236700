import { LoadingSpinner } from '@/components/LoadingSpinner';
import NoticeMessage from '@/components/NoticeMessage';
import { SelectOptions } from '@/components/SelectAllCheckbox';
import Text from '@/components/Text';

import AllSelectedNoFilter from './AllSelectedNoFilter';
import AllSelectedWithFilter from './AllSelectedWithFilter';
import NoneSelectedWithFilter from './NoneSelectedWithFilter';

type SelectedMessageProps = {
  currentSelectOptions: SelectOptions[];
  isLoading: boolean;
  selectedShowingPosts: string[];
  selectedPostCount: number;
  showingPosts: string[];
};

const SelectedMessage = ({
  currentSelectOptions,
  isLoading,
  selectedPostCount,
  showingPosts,
  selectedShowingPosts,
}: SelectedMessageProps) => {
  const selectMessages = {
    noneSelected: {
      noFilter: '',
      withFilter: <NoneSelectedWithFilter currentSelectOptions={currentSelectOptions} />,
    },
    hasSelected: {
      noFilter: (
        <AllSelectedNoFilter
          selectedPostCount={selectedPostCount}
          selectedShowingPosts={selectedShowingPosts}
          showingPosts={showingPosts}
        />
      ),
      withFilter: (
        <AllSelectedWithFilter
          selectedPostCount={selectedPostCount}
          currentSelectOptions={currentSelectOptions}
          selectedShowingPosts={selectedShowingPosts}
          showingPosts={showingPosts}
        />
      ),
    },
  };

  const hasFilter = currentSelectOptions.length > 0 && !currentSelectOptions.includes(SelectOptions.ALL);
  const noneSelectedMessage = selectMessages.noneSelected[hasFilter ? 'withFilter' : 'noFilter'];
  const hasSelectedMessage = selectMessages.hasSelected[hasFilter ? 'withFilter' : 'noFilter'];

  return (
    <NoticeMessage>
      <Text size="sm" className="flex flex-row gap-2 p-2">
        {selectedShowingPosts.length === 0 && noneSelectedMessage}

        {selectedShowingPosts.length > 0 && hasSelectedMessage}

        {isLoading && <LoadingSpinner />}
      </Text>
    </NoticeMessage>
  );
};

export default SelectedMessage;
