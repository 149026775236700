import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  onSuccess: () => void;
}

const useOrderNavLinks = ({ onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (newList: { id: string; order: number }[]) =>
      api
        .patch(`/nav_links/order`, {
          publication_id: currentPublicationId,
          new_ordered_list: newList,
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useOrderNavLinks;
