import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { Textarea } from '@/ui/Textarea';
import pluralize from '@/utils/pluralize';

export interface Props {
  applicationCount: number;
  isOpen: boolean;
  isWorking: boolean;
  onProceed: (rejectReason?: string) => void;
  onClose: () => void;
}

const ConfirmBoostAgreementsBulkRejectModal = ({ applicationCount, isOpen, isWorking, onProceed, onClose }: Props) => {
  const [rejectReason, setRejectReason] = useState('');

  return (
    <ActionModal
      resourceId="confirm-boost-agreemeents-bulk-reject"
      isOpen={isOpen}
      isWorking={isWorking}
      onClose={onClose}
      onProceed={() => onProceed(rejectReason)}
      modalMessageType="info"
      headerText={`Reject ${pluralize('application', applicationCount)}`}
      descriptionText={`Are you sure you want to reject ${pluralize('this application', applicationCount, 'these applications', true)}?`}
      actionText="Yes, reject"
    >
      <Textarea
        name="reject-reason"
        labelText="Share a reason for rejection (optional)"
        value={rejectReason}
        onChange={(e) => setRejectReason(e.target.value)}
        placeholderText="Not the right fit for our publication."
        helperText={`This will be shared with the ${pluralize('applicant', applicationCount, undefined, true)}.`}
        rows={3}
        className="mt-8"
      />
    </ActionModal>
  );
};

export default ConfirmBoostAgreementsBulkRejectModal;
