export enum AdNetworkPaymentRequestStatus {
  SCHEDULED = 'scheduled',
  SENT = 'sent',
  PAID = 'paid',
  ARCHIVED = 'archived',
}

export interface AdNetworkPaymentRequest {
  id: string;
  advertiser_message: string;
  amount_cents: number;
  amount: string;
  paid_at: string;
  hosted_invoice_url: string;
  status: AdNetworkPaymentRequestStatus;
  send_at: string;
  created_at: string;
  updated_at: string;
}
