import { Link } from 'react-router-dom';

import Text from '@/components/Text';
import { AutomationJourneyStatus } from '@/interfaces/automations/automation_journey';

import { useSubscriberContext } from '../../_context/subscriberContext';

import AutomationGroup from './AutomationGroup';

const AutomationHistory = () => {
  const activeStatuses = [AutomationJourneyStatus.INITIATED, AutomationJourneyStatus.RUNNING];
  const completedStatuses = [AutomationJourneyStatus.FAILED, AutomationJourneyStatus.COMPLETED];
  const { subscriber } = useSubscriberContext();

  return (
    <div className="space-y-5 mt-5">
      <AutomationGroup label="Currently Enrolled" statuses={activeStatuses} badgeType="success" />
      <AutomationGroup label="Previously Enrolled" statuses={completedStatuses} badgeType="information" />
      <div>
        <Link to={`/subscribers/${subscriber.id}/automations`}>
          <Text size="sm" className="font-medium text-gray-900 hover:text-gray-500">
            View All &rarr;
          </Text>
        </Link>
      </div>
    </div>
  );
};

export default AutomationHistory;
