import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

const HeaderActions = () => {
  const navigate = useNavigate();

  return (
    <div className="flex gap-x-5 items-center">
      <Button variant="primary" onClick={() => navigate('/referral_program/milestones/new')} Icon={PlusIcon}>
        New Referral
      </Button>
    </div>
  );
};

export default HeaderActions;
