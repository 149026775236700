interface SupportCardProps {
  title: string;
  description: string;
  button: React.ReactNode;
}

const SupportCard: React.FC<SupportCardProps> = ({
  title,
  description,
  button,
}) => {
  return (
    <div
      className="p-4 rounded-lg shadow-sm bg-center bg-cover border border-surface-200 bg-[#F9FAFB] flex flex-col justify-between"
    >
      <div>
        <h2 className="font-semibold mb-2 text-gray-900">{title}</h2>
        <p className="text-sm text-gray-700">{description}</p>
      </div>
      <div className="flex items-end mt-5">
        {button}
      </div>
    </div>
  );
};

export default SupportCard;