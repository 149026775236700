import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PostTargetPlatform } from '@/interfaces/post_target';

import api from '../../services/swarm';

type Params = {
  action: 'include' | 'exclude';
  receiver_type: string;
  receiver_id: string;
  tier_id?: string;
};

const useCreatePostTarget = (postId: string, platform: PostTargetPlatform) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  return useMutation(
    ({ params }: { params: Params }) =>
      api.post(`/posts/${postId}/post_targets`, {
        publication_id: currentPublicationId,
        platform,
        post_target: params,
      }),
    {
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Oops there was an issue updating the Post');
      },
      onSettled: async () => {
        queryClient.invalidateQueries(['post', postId, 'deliveryData']);
        return queryClient.invalidateQueries(['post', postId, 'postTargets']);
      },
    }
  );
};

export default useCreatePostTarget;
