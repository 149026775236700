import { Typography, TypographyStack } from '@/components/Typography';
import { Card } from '@/ui/Card';

import LeadersTable from './LeadersTable';
import MilestoneBreakdownChart from './MilestoneBreakdownChart';
import SubscriberBreakdownChart from './SubscriberBreakdownChart';

const Insights = () => {
  return (
    <>
      <Card className="space-y-5">
        <TypographyStack>
          <Typography token="font-bold/text/base">Breakdown by Rewards</Typography>
          <Typography token="font-normal/text/base" colorWeight="400">
            Shows how many rewards were redeemed through subscribers achieving milestones
          </Typography>
        </TypographyStack>
        <MilestoneBreakdownChart />
      </Card>
      <div className="flex flex-col sm:flex-row gap-x-5 gap-y-5">
        <Card className="w-full sm:w-1/2 space-y-5">
          <TypographyStack>
            <Typography token="font-bold/text/base">Subscribers Engagement</Typography>
            <Typography token="font-normal/text/base" colorWeight="400">
              Breakdown of your audience who have engaged with your referral program by having at least 1 referral
            </Typography>
          </TypographyStack>

          <SubscriberBreakdownChart />
        </Card>
        <Card className="w-full sm:w-1/2 space-y-5">
          <Typography token="font-bold/text/base">Referral Program Leaderboard</Typography>
          <LeadersTable />
        </Card>
      </div>
    </>
  );
};

export default Insights;
