import { ElementType } from 'react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

interface Props {
  text: string;
  Icon: ElementType;
  isOpen: boolean;
  onClose: () => void;
}

const CustomizeMoreButton = ({ Icon, isOpen, text, onClose }: Props) => {
  return (
    <Button
      className={cx(
        !isOpen && 'hover:!bg-surface-200 !border !border-surface-200',
        isOpen && '!border-surface-600 !bg-white '
      )}
      Icon={Icon}
      variant="flush"
      size="xs"
      type="button"
      onClick={onClose}
    >
      <Typography token="font-normal/text/xs">{text}</Typography>
    </Button>
  );
};

export default CustomizeMoreButton;
