import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import * as DialogModal from '@/components/DialogModal';
import IconButton from '@/components/IconHelpers/IconButton';
import { Asset } from '@/interfaces/asset';

import { ColorEditModalContent } from '../components/ColorEditModalContent';

export type ColorEditModalProps = {
  asset: Asset;
  assetId: string;
  publicationId: string;
  open: boolean;
  onClose: () => void;
  onChange: (asset: Asset) => void;
  onCreate: (asset: Asset) => void;
};

export const ColorEditModal = ({
  asset,
  open,
  onClose,
  onChange,
  onCreate,
  assetId,
  publicationId,
}: ColorEditModalProps) => {
  const assetFileName = asset.url.split('/').pop()?.split('?')[0];

  return (
    <DialogModal.Wrapper className="px-6 pt-3 pb-6" open={open} onClose={onClose}>
      <DialogModal.Header className="mb-4" title="Color Adjustments" onClose={onClose}>
        <IconButton onClick={onClose}>
          <ArrowLeftIcon className="w-5 h-5" />
        </IconButton>
      </DialogModal.Header>
      {open ? (
        <ColorEditModalContent
          assetId={assetId}
          fileName={assetFileName || Date.now().toString()}
          publicationId={publicationId}
          onChange={onChange}
          onCreate={onCreate}
          onClose={onClose}
        />
      ) : null}
    </DialogModal.Wrapper>
  );
};
