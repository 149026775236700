import { useState } from 'react';

import { TypeaheadMultiSelect } from '@/components/Form';
import { Option } from '@/interfaces/general';
import { normalizeString } from '@/utils';

interface Props {
  tags: Option[];
  selectedTagIds?: string[];
  setTagIds: (tags: string[]) => void;
}

export default function FilterByTags({ tags, selectedTagIds = [], setTagIds }: Props) {
  const [query, setQuery] = useState('');

  const handleSearchTags = (): Promise<Option[]> => {
    const options = tags.filter((tag: Option) => normalizeString(tag.label).includes(query)) || [];

    return new Promise((resolve) => {
      resolve(options);
    });
  };

  const handleSelectTag = (name: string, value: string) => {
    setTagIds([...selectedTagIds, value]);
  };

  const handleDeselectTag = (name: string, value: string) => {
    const newTagIds = selectedTagIds.filter((tagId) => tagId !== value);
    setTagIds(newTagIds);
  };

  const handleDeselectAll = () => {
    setTagIds([]);
  };

  const handleOnClear = () => setQuery('');

  return (
    <div className="flex flex-col gap-y-4">
      <TypeaheadMultiSelect
        emptyLabel="No categories found"
        name="boost-offers-tag-filter"
        onClear={handleOnClear}
        onDeselect={handleDeselectTag}
        onDeselectAll={handleDeselectAll}
        onSearch={handleSearchTags}
        onSearchQueryChange={setQuery}
        onSelect={handleSelectTag}
        placeholderText="Select Categories"
        values={selectedTagIds}
        showClearAll={false}
        shouldCloseOnSelection={false}
      />
    </div>
  );
}
