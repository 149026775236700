import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import useOpportunities from '@/hooks/useAdNetwork/publisher/useOpportunities';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import analytics from '@/utils/analytics';

import Opportunity from '../Opportunity';

const NoResults = () => {
  return (
    <Card>
      <div className="space-y-4">
        <div>
          <p className="text-lg font-bold">No available opportunities, yet.</p>
          <p className="text-gray-700">
            There are a few reasons why you might not have any pending opportunities at the moment.
          </p>
        </div>
        <hr />
        <div>
          <p className="font-bold text-gray-800">Audience overlap</p>
          <p className="text-gray-600">
            Opportunities are shown to only the newsletters with the most relevant audience for the specific advertiser.
          </p>
        </div>
        <div>
          <p className="font-bold text-gray-800">Lack of data </p>
          <p className="text-gray-600">
            We need a baseline of campaign and audience data in order to accurately distribute ads to the proper
            newsletters. If you are new to beehiiv, or send infrequently, we may not have enough data available yet, but
            that will change as you use beehiiv more.
          </p>
        </div>
        <div>
          <p className="font-bold text-gray-800">Timing</p>
          <p className="text-gray-600">
            We just launched this recently and are still in the process of moving our campaigns into the beehiiv
            platform. This process may take a few weeks.
          </p>
        </div>

        <div>
          <p className="font-bold text-gray-800">Inventory</p>
          <p className="text-gray-600">
            We are quickly ramping up ad campaigns and bringing on more advertisers that are relevant to more
            publishers, but this all takes time. We have steadily increased inventory each month for the past several
            months and anticipate considerably more ad inventory as we transition into Q4.
          </p>
        </div>
        <div>
          <p>
            Have more questions? Visit our{' '}
            <a
              href="https://support.beehiiv.com/hc/en-us/articles/17507491038231-Everything-you-need-to-know-about-the-Ad-Network-plus-FAQs"
              target="_blank"
              rel="noreferrer"
              className="hover:text-primary-500 underline"
            >
              FAQ
            </a>
            , or{' '}
            <a
              className="hover:text-primary-500 underline"
              href="mailto:preeya@beehiiv.com?subject=beehiiv+ad+network+interest"
            >
              contact our team
            </a>
            .
          </p>
        </div>
        <hr />
        <p className="text-sm text-gray-500">
          You will be notified via email when a new advertising opportunity is available to you.
        </p>
      </div>
    </Card>
  );
};

const PendingOpportunities = () => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } = useOpportunities({
    filter: 'pending',
  });
  const [viewedOpportunities, setViewedOpportunities] = useState<boolean>(false);

  const opportunities = data?.pages.flatMap((page) => page.opportunities);

  useEffect(() => {
    if (opportunities?.length && !viewedOpportunities) {
      analytics.track('Viewed an Ad opportunity');
      setViewedOpportunities(true);
    }
    // eslint-disable-next-line
  }, [opportunities?.length]);

  if (!isLoading && !opportunities?.length) return <NoResults />;

  return (
    <div className="space-y-6">
      <ul className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {opportunities?.map((opportunity) => (
          <li>
            <Link to={`/monetize/ads/opportunities/${opportunity.id}`}>
              <Opportunity key={opportunity.id} opportunity={opportunity} />
            </Link>
          </li>
        ))}
      </ul>
      {hasNextPage && (
        <Button onClick={() => fetchNextPage()} loading={isFetchingNextPage} variant="primary-inverse">
          Load More
        </Button>
      )}
    </div>
  );
};

export default PendingOpportunities;
