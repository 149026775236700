import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { cx } from 'class-variance-authority';
import styled from 'styled-components';

import { useAppLayout } from '@/context/app-layout-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';

import DesktopNavbar from '../DesktopNavbar';
import MobileNavbar from '../MobileNavbar';
import SettingsNav from '../SettingsNav';

const NavStyled: any = styled.nav`
  width: ${({ isWide, width }: any) => width || (isWide ? '230px' : '60px')};

  @media (max-width: 640px) {
    z-index: 30;
    width: ${({ isOpen }: any) => (isOpen ? '100vw' : '0px')};
    overflow: hidden;
    display: ${({ shouldHide }: any) => (shouldHide ? 'none' : 'block')};
  }
`;

const NavBody = styled.div`
  height: calc(100vh - 80px);
`;

const MobileDiv = styled.div`
  display: none;

  @media (max-width: 640px) {
    display: block;
    z-index: 31;
  }
`;

interface Props {
  isAdmin: boolean;
  largeNavOpen: boolean;
}

const MainNav = ({ isAdmin, largeNavOpen }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isSettingsPages } = useAppLayout();
  const { currentUser } = useCurrentUser();

  const navigate = useNavigate();
  const { settings } = useSettings();
  const hasPublicationSettingsV2 = currentUser?.has_settings_v2;

  const handleCloseSettings = () => {
    navigate('/');
  };

  return (
    <>
      {!hasPublicationSettingsV2 && (
        <SettingsNav isAdmin={isAdmin} isSettingsOpen={isSettingsPages} handleCloseSettings={handleCloseSettings} />
      )}
      <NavStyled
        className={cx(
          'border-r border-gray-200 bg-white h-screen absolute top-0 left-0 transition-all duration-200 ease-in-out',
          !isSettingsPages && 'z-50'
        )}
        shouldHide={isSettingsPages}
        isWide={largeNavOpen}
        isOpen={isOpen}
      >
        <NavBody>{settings?.navigation ? <DesktopNavbar largeNavOpen={largeNavOpen} /> : <MobileNavbar />}</NavBody>
        {!isSettingsPages && isOpen && (
          <MobileDiv className="fixed bottom-8 right-8 rounded-full overflow-hidden">
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="rounded-full bg-gray-900 text-white p-2 shadow-xl block md:hidden z-100"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </MobileDiv>
        )}
      </NavStyled>
      {!isSettingsPages && !isOpen && (
        <MobileDiv className="fixed bottom-8 right-8 rounded-full overflow-hidden">
          <button
            type="button"
            onClick={() => setIsOpen(true)}
            className="rounded-full bg-gray-900 text-white p-2 shadow-xl block md:hidden z-100"
          >
            <Bars3Icon className="h-4 w-4" />
          </button>
        </MobileDiv>
      )}
    </>
  );
};

export default MainNav;
