import { useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';

import { Button } from '@/ui/Button';

import { useAdvertiser } from '../../../../../hooks/useAdNetwork/internal';
import { AdNetworkCampaignStatus } from '../../../../../interfaces/ad_network/internal/campaign';
import { PageHeading } from '../../../_components/PageHeading';
import { Tabs } from '../../../_components/Tabs';

import { CampaignsList } from './_components/CampaignsList';

export default function Show() {
  const { advertiser_id: id } = useParams<'advertiser_id'>() as { advertiser_id: string };
  const { data } = useAdvertiser({ id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="../..">{data?.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Campaigns</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button to="../new" size="xs" variant="primary-inverse" Icon={PlusIcon}>
            New campaign
          </Button>
        </PageHeading.Side>
      </PageHeading>
      <div className="border-b border-gray-100 p-4 flex space-x-2">
        <div>
          <Tabs>
            <Tabs.Tab to="../all">All</Tabs.Tab>
            <Tabs.Tab to="../active">Active</Tabs.Tab>
            <Tabs.Tab to="../upcoming">Upcoming</Tabs.Tab>
            <Tabs.Tab to="">Past</Tabs.Tab>
          </Tabs>
        </div>
      </div>
      <CampaignsList status={AdNetworkCampaignStatus.PAST} advertiserId={id} />
    </>
  );
}
