import { AdNetworkCampaignStatus } from '../../../interfaces/ad_network/internal/campaign';
import { PageHeading } from '../_components/PageHeading';
import { Tabs } from '../_components/Tabs';

import CampaignsTable from './_components/CampaignsTable';

export default function Show() {
  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="">Campaigns</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="border-b border-gray-100 p-4 flex space-x-2">
        <div>
          <Tabs>
            <Tabs.Tab to="../all">All</Tabs.Tab>
            <Tabs.Tab to="">Active</Tabs.Tab>
            <Tabs.Tab to="../upcoming">Upcoming</Tabs.Tab>
            <Tabs.Tab to="../past">Past</Tabs.Tab>
          </Tabs>
        </div>
      </div>
      <CampaignsTable status={AdNetworkCampaignStatus.ACTIVE} />
    </>
  );
}
