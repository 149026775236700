import { CaretRight, ClockCountdown, Link as LinkIcon } from '@phosphor-icons/react';

import { useWebsiteContext } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';

import { dateToAgo } from '../../_utils/dateToAgo';
import { dateToDateString } from '../../_utils/dateToDateString';
import { Checkbox } from '../UI/Checkbox';
import { Label } from '../UI/Label';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

import { Section, SectionTitle } from './Section';

const Options = ({
  text,
  right,
  checked,
  onChange,
  onClick,
}: {
  text: string;
  right?: string;
  checked?: boolean;
  onChange?: () => void;
  onClick?: () => void;
}) => {
  return (
    <div className="px-2 py-1.5 gap-2 flex items-center">
      <Checkbox id={text} checked={checked} onCheckedChange={onChange} />
      <Label htmlFor={text} className="flex-1">
        <Text size="sm" weight="medium">
          {text}
        </Text>
      </Label>
      {right && (
        <div
          className="flex items-center gap-1 cursor-pointer"
          onClick={onClick}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick?.();
            }
          }}
        >
          <Text size="xs" weight="medium" variant="secondary">
            {right}
          </Text>
          <CaretRight size={16} weight="bold" className="text-wb-secondary" />
        </div>
      )}
    </div>
  );
};

export const PublishSettingsStep = ({
  goToSelectPagesStep,
  isSettingsSelected,
  setIsSettingsSelected,
  selectedPages,
  onSelectAllPages,
}: {
  goToSelectPagesStep?: () => void;
  isSettingsSelected: boolean;
  setIsSettingsSelected: (isSettingsSelected: boolean) => void;
  selectedPages: Record<string, boolean>;
  onSelectAllPages?: () => void;
}) => {
  const { site, pages } = useWebsiteContext();
  const { data: currentPublication } = useCurrentPublication();

  const isAllPagesSelected = pages && selectedPages && pages?.every((page) => selectedPages[page.id]);
  const isSelectedSomePages = Object.values(selectedPages)?.some((selected) => selected);
  const selectedPagesCount = Object.values(selectedPages)?.filter(Boolean).length;

  const selectPagesText = isAllPagesSelected ? 'All Pages' : `${selectedPagesCount} Pages`;

  const publishedAtAgo = dateToAgo(site?.published_site_version?.published_at || '');
  const publishedAtDate = dateToDateString(site?.published_site_version?.published_at || '');

  return (
    <>
      <Section>
        <SectionTitle title="Publishing" />
        <div className="flex flex-col gap-0">
          <Options
            text="Site Settings"
            checked={isSettingsSelected}
            onChange={() => setIsSettingsSelected(!isSettingsSelected)}
          />
          <Options
            text={selectPagesText}
            right="Select Pages"
            checked={isAllPagesSelected || isSelectedSomePages}
            onChange={onSelectAllPages}
            onClick={goToSelectPagesStep}
          />
        </div>
      </Section>
      <hr className="w-full h-px bg-wb-hr" />
      <Section>
        <SectionTitle title="Publish to" />
        <div className="flex flex-col gap-0">
          <a
            href={currentPublication?.url || ''}
            target="_blank"
            className="px-2 py-1.5 gap-2 flex items-center cursor-pointer group"
            rel="noreferrer"
          >
            <LinkIcon size={16} weight="bold" className="text-wb-secondary" />
            <Text size="xs" weight="medium" className="group-hover:text-wb-accent">
              {currentPublication?.hostname}
            </Text>
          </a>
          <div className="px-2 py-1.5 gap-2 flex items-center">
            <ClockCountdown size={16} weight="bold" className="text-wb-secondary" />
            {site?.published_site_version?.published_at ? (
              <Tooltip center={`Published at ${publishedAtDate}`}>
                <Text size="xs" weight="medium" variant="secondary" className="cursor-pointer">
                  {publishedAtAgo}
                </Text>
              </Tooltip>
            ) : (
              <Text size="xs" weight="medium" variant="secondary">
                Not Published
              </Text>
            )}
          </div>
        </div>
      </Section>
    </>
  );
};
