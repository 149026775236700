import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import { Badge } from '@/ui/Badge';

import ErrorRate from './Shared/ErrorRate';

interface ListRowProps {
  endpoint: WebhookEndpoint;
}

const ListRow = ({ endpoint }: ListRowProps) => {
  const navigate = useNavigate();

  const eventTypeText = endpoint.event_types.join(', ');
  const endpointTooltipId = `endpoint-${endpoint.id}`;
  const eventTypeTooltipId = `event-type-${endpoint.id}`;

  return (
    <li className="p-4 bg-white rounded-lg border relative group">
      <div className="space-y-2">
        <div className="grid grid-cols-12 gap-2 items-center justify-items-center">
          {/* Url/Description */}
          <div className="w-full col-span-11 xs:col-span-7 md:col-span-4 lg:col-span-5 justify-self-start">
            <div>
              <div className="text-gray-700 font-medium">{endpoint.description}</div>
              <div className="truncate text-sm text-gray-400" data-tip={endpoint.url} data-for={endpointTooltipId}>
                {endpoint.url}
              </div>
              <ReactTooltip id={endpointTooltipId} place="top" type="dark" effect="solid" />
            </div>
          </div>
          {/* Event Types */}
          <div className="hidden xs:inline-block xs:col-span-4 md:col-span-3 lg:col-span-2">
            <div className="flex flex-col space-y-1 items-center">
              <div data-tip={eventTypeText} data-for={eventTypeTooltipId}>
                <Badge type="info_blue">
                  {endpoint.event_types.length} Event type{endpoint.event_types.length > 1 ? 's' : ''}
                </Badge>
                <ReactTooltip id={eventTypeTooltipId} place="top" type="dark" effect="solid" />
              </div>
              <div className="md:hidden">
                <Badge type={endpoint.disabled ? 'alert' : 'success'}>
                  {endpoint.disabled ? 'Disabled' : 'Active'}
                </Badge>
              </div>
            </div>
          </div>
          {/* Status */}
          <div className="hidden md:inline-block col-span-2">
            <Badge type={endpoint.disabled ? 'alert' : 'success'}>{endpoint.disabled ? 'Disabled' : 'Active'}</Badge>
          </div>
          {/* Error Rate */}
          <div className="hidden md:inline-block md:col-span-2">
            <ErrorRate endpointId={endpoint.id} messageText="Errors" />
          </div>
          {/* Options */}
          <div className="justify-self-end">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-600"
              onClick={() => navigate(`/settings/integrations/webhooks/endpoints/${endpoint.id}`)}
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ListRow;
