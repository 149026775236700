import { useCallback } from 'react';

import { UnsplashImage } from '../../../../utils/unsplash';

export type ImageItemProps = {
  image: UnsplashImage;
  onSelect: (image: UnsplashImage) => void;
};

const ImageItem = ({ image, onSelect }: ImageItemProps) => {
  const handleClick = useCallback(() => {
    onSelect(image);
  }, [onSelect, image]);

  return (
    <div className="text-left">
      <button
        type="button"
        onClick={handleClick}
        className="relative block w-full bg-gray-100 border border-gray-200 rounded aspect-w-1 aspect-h-1"
      >
        <img className="absolute block object-contain w-full h-full" src={image.urls.thumb} alt={image.description} />
      </button>
      <a
        className="block w-full mt-1.5 text-gray-500 underline text-sm"
        href={`${image.user.links.html}?utm_source=${process.env.REACT_APP_UNSPLASH_APP_NAME}&utm_medium=referral`}
        target="_blank"
        rel="nofollow noreferrer"
      >
        {image.user.name}
      </a>
    </div>
  );
};

export default ImageItem;
