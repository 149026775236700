/* eslint-disable jsx-a11y/label-has-associated-control */

import { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { SideTab } from '../../interfaces/general';

interface Props {
  tabs: SideTab[];
  className?: string;
  selectedTab: string;
  children: React.ReactNode;
}

const SideTabContainer: FC<Props> = (props: Props) => {
  const { tabs, className = '', children, selectedTab } = props;
  return (
    <div className={className}>
      <div>
        <div className="bg-white overflow-hidden rounded transition-all border border-gray-200 rounded-md">
          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
            <aside className="py-6 lg:col-span-3">
              <nav className="space-y-1">
                {tabs.map((tab) => {
                  const isSelected = tab.name === selectedTab;
                  const Icon = tab.icon;

                  const tabClasses = cx(
                    isSelected
                      ? 'bg-primary-50 border-primary-500 text-primary-700 hover:bg-primary-50 hover:text-primary-700'
                      : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'px-3 py-2 flex items-center text-sm font-medium'
                  );

                  const tabContent = (
                    <>
                      <Icon
                        className={cx(
                          isSelected
                            ? 'text-primary-500 group-hover:text-primary-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                      />
                      <span className="truncate">{tab.label}</span>
                    </>
                  );
                  return tab.hardRedirect ? (
                    <a key={tab.name} href={tab.targetRoute} className={tabClasses}>
                      {tabContent}
                    </a>
                  ) : (
                    <Link key={tab.name} to={tab.targetRoute} aria-current={isSelected} className={tabClasses}>
                      {tabContent}
                    </Link>
                  );
                })}
              </nav>
            </aside>
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideTabContainer;
