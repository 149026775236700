import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { FootnoteItemAttrs } from '../types';

import { FootnoteItemView } from './views';

export const FootnoteItem = Node.create({
  name: 'footnoteItem',

  content: 'inline*',

  defining: true,

  addAttributes() {
    return {
      footnoteId: {
        default: null,
        renderHTML: (attributes) => {
          return {
            'data-footnote-id': attributes.footnoteId,
          };
        },
        parseHTML: (element) => {
          return element.getAttribute('data-footnote-id') || null;
        },
      },
      order: {
        default: null,
        renderHTML: (attributes) => {
          return {
            'data-order': attributes.order,
          };
        },
        parseHTML: (element) => {
          const order = element.getAttribute('data-order');
          const orderNum = order ? parseInt(order, 10) : null;

          return orderNum;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-node-type="footnoteItem"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { 'data-node-type': 'footnoteItem', ...HTMLAttributes }, 0];
  },

  addKeyboardShortcuts() {
    const { editor } = this;

    return {
      Enter: () => {
        if (!editor.isActive('footnoteItem')) return false;

        const footnoteAttrs = editor.getAttributes('footnoteItem') as FootnoteItemAttrs;

        editor.commands.focusFootnoteMarker(footnoteAttrs.footnoteId);

        return true;
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(FootnoteItemView, { className: 'relative mb-2 last-of-type:mb-0' });
  },
});
