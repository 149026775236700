import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';

import { useCurrentPublication } from '@/hooks';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import ActionModal from '../../../../../../components/ActionModal';
import { Input } from '../../../../../../components/Form';
import Text from '../../../../../../components/Text';
import { useUpdateSubdomain } from '../../../../../../hooks/useSubdomain';
import { CustomDomain, CustomDomainTypes } from '../../../../../../interfaces/custom_domain';
import { Publication } from '../../../../../../interfaces/publication';
import { Section } from '../../../../Components';
import NotEditing from '../../../../Components/NotEditing';
import SelectedDomainDetails from '../SelectedDomainDetails';
import SmartWarmingFaqModal from '../SmartWarmingFaqModal';

interface Props {
  publication: Publication;
  domain?: CustomDomain;
}

const secondaryEmailExplanation = 'During Smart Warming, additional rollover volume is sent from this address.';

const EmailDomainSection = ({ publication, domain }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSmartWarmingFaqModalOpen, setIsSmartWarmingFaqModalOpen] = useState(false);

  const [subdomain, setSubdomain] = useState<string>(publication.subdomain || '');
  const [newSubdomain, setNewSubdomain] = useState<string>(subdomain);
  const [emailUsername, setEmailUsername] = useState<string>(domain?.email_username || publication.subdomain || 'hi');
  const [newEmailUsername, setNewEmailUsername] = useState<string>(emailUsername);
  const subdomainMutation = useUpdateSubdomain();
  const actionText = domain?.warming_up ? 'Update emails' : 'Update email';
  const { data: currentPublication } = useCurrentPublication();

  const onSave = async () => {
    try {
      await subdomainMutation.mutateAsync({
        custom_email_domain_id: domain?.id,
        email_username: newEmailUsername,
        subdomain: newSubdomain,
      });

      analytics.track('Congifured email domain');

      if (newSubdomain) setSubdomain(newSubdomain);
      if (!newSubdomain) setNewSubdomain(subdomain);

      setEmailUsername(newEmailUsername || 'hi');
      if (!newEmailUsername) setNewEmailUsername('hi');

      setIsModalOpen(false);
      toast.success('Saved!');
    } catch (error) {
      toast.error('Error saving email username');
    }
  };

  useEffect(() => {
    setSubdomain(publication.subdomain || '');
    setNewSubdomain(publication.subdomain || '');

    setEmailUsername(domain?.email_username || publication.subdomain || 'hi');
    setNewEmailUsername(domain?.email_username || publication.subdomain || 'hi');
  }, [publication.subdomain, domain?.email_username]);

  if (!domain) {
    return (
      <Section
        title="Email Domain"
        description="Add a custom domain to send emails from, or use a customizable subdomain attached to beehiiv.com"
        scrollToId="email-domain"
        hasDivider
      >
        <div className="my-6">
          <SelectedDomainDetails type={CustomDomainTypes.EMAIL} />
          <div className="flex justify-between pt-4">
            <NotEditing label="Emails will be sent from:" value={`${subdomain}@mail.beehiiv.com`} />
            <Button Icon={PencilIcon} variant="primary-inverse" onClick={() => setIsModalOpen(true)}>
              Update email
            </Button>
          </div>
        </div>

        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isWorking={subdomainMutation.isLoading}
          onProceed={onSave}
          resourceId={subdomain}
          headerText="Update Email"
        >
          <div className="flex flex-col">
            <p className="block text-sm font-medium text-gray-700 mb-1">Email</p>
            <div className="flex">
              <Input
                className="w-full"
                name="subdomain"
                inputClassOverride="appearance-none block w-full px-3 py-2 border rounded-l-md placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed"
                value={newSubdomain}
                onChange={(e) => setNewSubdomain(e.target.value)}
              />
              <p className="pt-1.5 px-2 bg-gray-50 border border-gray-300 border-l-0 rounded-r-md text-gray-500">
                @mail.beehiiv.com
              </p>
            </div>
            <Text size="xs" className="mt-2 text-red-500">
              <b>NOTE:</b> If you are not using a custom domain, changing this value will also update your
              website&apos;s url.
            </Text>
          </div>
        </ActionModal>
      </Section>
    );
  }

  const description = currentPublication?.on_dedicated_ip
    ? 'You are on a dedicated IP which requires the use of a custom email domain. Please reach out to support if you need assistance changing this domain configuration'
    : 'You can use your own domain to send emails from your publication.';

  return (
    <Section title="Email Domain" description={description} scrollToId="email-domain" hasDivider>
      <div className="my-6">
        <SelectedDomainDetails domain={domain} type={CustomDomainTypes.EMAIL} />
        <SmartWarmingFaqModal
          isOpen={isSmartWarmingFaqModalOpen}
          onClose={() => setIsSmartWarmingFaqModalOpen(false)}
        />
        {domain.warming_up && (
          <p className="mt-4 mb-8 text-xs text-gray-600 pl-2 border-l-2 border-yellow-400">
            We are gradually increasing the sending volume on <b>{domain.domain}</b> to optimize deliverability. This
            happens automatically in the background, so nothing will be impacted for you. Depending on your
            publication&apos;s list size, this process typically takes 2-4 weeks to complete.
            <button
              type="button"
              onClick={() => setIsSmartWarmingFaqModalOpen(true)}
              className="mt-1 text-primary-700 font-medium hover:text-primary-800 flex"
            >
              <InformationCircleIcon className="w-4 h-4 self-center mr-1" />
              Frequently Asked Questions about Smart Warming
            </button>
          </p>
        )}
        {domain.verified && (
          <>
            <div className="mt-6 flex justify-between">
              <div className="space-y-4">
                <NotEditing label="Email Username" value={`${emailUsername}@${domain.domain}`} />
                {domain.warming_up && (
                  <NotEditing
                    label="Secondary Email Username"
                    value={`${subdomain}@mail.beehiiv.com`}
                    tooltip={secondaryEmailExplanation}
                  />
                )}
              </div>
              <div className="flex items-center">
                <Button variant="primary-inverse" Icon={PencilIcon} onClick={() => setIsModalOpen(true)}>
                  {actionText}
                </Button>
              </div>
            </div>
            <ActionModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              isWorking={subdomainMutation.isLoading}
              onProceed={onSave}
              resourceId={newEmailUsername}
              headerText={actionText}
            >
              <div className="flex flex-col space-y-4 w-full">
                <div className="flex flex-col">
                  <p className="block text-sm font-medium text-gray-700 mb-1">Email Username</p>
                  <div className="flex">
                    <Input
                      required
                      className="w-full"
                      name="email_username"
                      inputClassOverride="appearance-none block w-full px-3 py-2 border rounded-l-md placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed"
                      value={newEmailUsername}
                      onChange={(e) => setNewEmailUsername(e.target.value)}
                      placeholder="hi"
                    />
                    <p className="pt-1.5 px-2 bg-gray-50 border border-gray-300 border-l-0 rounded-r-md text-gray-500">
                      @{domain.domain}
                    </p>
                  </div>
                </div>

                {domain.warming_up && (
                  <div className="flex flex-col">
                    <p className="block text-sm font-medium text-gray-700 mb-1">Secondary Email Username</p>
                    <div className="flex">
                      <Input
                        required
                        className="w-full"
                        name="subdomain"
                        inputClassOverride="appearance-none block w-full px-3 py-2 border rounded-l-md placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed"
                        value={newSubdomain}
                        onChange={(e) => setNewSubdomain(e.target.value)}
                      />
                      <p className="pt-1.5 px-2 bg-gray-50 border border-gray-300 border-l-0 rounded-r-md text-gray-500">
                        @mail.beehiiv.com
                      </p>
                    </div>

                    <Text size="xs" className="my-2">
                      {secondaryEmailExplanation}
                    </Text>

                    <Text size="xs" className=" text-red-500">
                      <b>NOTE:</b> Changing this will also change your Website URL. Changing this will update all of
                      your posts to the new web URL. This means that URLs to previously published posts will be outdated
                      and no longer work.
                    </Text>
                  </div>
                )}
              </div>
            </ActionModal>
          </>
        )}
      </div>
    </Section>
  );
};

export default EmailDomainSection;
