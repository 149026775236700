export type TagsProps = {
  tags: string[];
  onTagSelect: (tag: string) => void;
};

const Tags = ({ onTagSelect, tags }: TagsProps) => {
  return (
    <div className="flex flex-wrap items-center gap-3">
      {tags.map((tag) => (
        <button
          onClick={() => onTagSelect(tag)}
          type="button"
          key={tag}
          className="px-2 py-1.5 text-sm text-gray-700 font-semibold bg-white border border-gray-300 rounded hover:text-gray-800 hover:border-gray-400"
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

export default Tags;
