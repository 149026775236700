import * as React from 'react';
import { MagnifyingGlass } from '@phosphor-icons/react';

import { cn } from '../../_utils/cn';

export interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
}

const Search = React.forwardRef<HTMLInputElement, SearchProps>(({ className, inputClassName, ...props }, ref) => {
  return (
    <div className={cn('flex items-center p-1.5 gap-2 rounded-lg bg-wb-secondary', className)}>
      <MagnifyingGlass size={20} className="text-wb-secondary" />
      <input
        type="text"
        className={cn(
          'flex h-[18px] p-0 m-0 w-full text-xs leading-[18px] bg-transparent border-none outline-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-wb-secondary focus:border-none focus:ring-0',
          inputClassName
        )}
        ref={ref}
        {...props}
      />
    </div>
  );
});
Search.displayName = 'Search';

export { Search };
