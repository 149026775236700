import styled from 'styled-components';

export const Styled = {
  ButtonWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  `,
};

export default Styled;
