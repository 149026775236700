import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { ScopeTypes } from '../../interfaces/comment_ban';
import { Pagination } from '../../interfaces/general';
import api from '../../services/swarm';

interface ApiResponse {
  comment_bans: any[];
  pagination: Pagination;
}

const useCommentBans = ({
  search,
  perPage = 5,
  all = false,
  level,
  scope,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
  level?: string;
  scope: ScopeTypes;
}) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchCommentBans = ({ pageParam = 1 }) =>
    api
      .get(`/comment_bans`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
          level,
          scope,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery<ApiResponse>(['comment_bans', currentPublicationId, search, level, scope], fetchCommentBans, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    retry: false,
    keepPreviousData: true,
    enabled: !!currentPublicationId,
    onError: (errPayload: any) => errPayload?.response?.data?.message || 'Something went wrong',
  });
};

export default useCommentBans;
