import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { ChatBubbleLeftRightIcon, PlusIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import SubscriberSelect from '@/components/SubscriberSelect';
import Text from '@/components/Text';
import { Typography } from '@/components/Typography';
import { useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { useCommentBans, useCreateCommentBan } from '@/hooks/useComments';
import { BanScopes, BanTypes, ScopeTypes } from '@/interfaces/comment_ban';
import CommentBanItem from '@/pages/Settings/Pages/Website/Comments/CommentBansSection/CommentBanItem';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import { Textarea } from '@/ui/Textarea';

import { SettingsContainer, SettingsGroup } from '../../../../ui/Settings/SettingsContainer';
import SwitchDescriptionInput from '../../../../ui/Settings/SwitchDescriptionInput';

import CommentsOrdering from './_components/CommentsOrdering';

const WebBuilderComments = () => {
  const queryClient = useQueryClient();
  const webTemplateMutation = useUpdateWebTemplate();
  const { data: webTemplate } = useWebTemplate();

  const [renderType, setRenderType] = useState<string>(BanScopes.ALL);
  const isAll = renderType === BanScopes.ALL;

  const [renderStatus, setRenderStatus] = useState<ScopeTypes>(BanScopes.ACTIVE);
  const isDefaultFilters = renderType === BanScopes.ALL && renderStatus === BanScopes.ALL;

  const paginatedCommentBansQuery = useCommentBans({
    level: isAll ? undefined : renderType,
    scope: renderStatus,
  });
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } = paginatedCommentBansQuery;
  const commentBans = data?.pages.flatMap((page: any) => page.comment_bans) || [];
  const totalCommentBansCount = data?.pages[0]?.pagination?.total;
  const showingCommentBansCount = commentBans?.length || 0;
  const isNoResults = !isLoading && commentBans.length === 0;

  const [commentBansOpen, setCommentBansOpen] = useState<boolean>(false);
  const [reason, setReason] = useState('');
  const [currentSubscriberId, setCurrentSubscriberId] = useState<string>();

  const hideFilters = isDefaultFilters && isNoResults;

  const createCommentBan = useCreateCommentBan({
    onSuccess: () => {
      toast.success('Saved!');
      queryClient.invalidateQueries(['comment_bans']);
      setReason('');
      setCurrentSubscriberId('');
      setCommentBansOpen(false);
    },
  });

  const handleCreateCommentBan = () => {
    createCommentBan.mutate({
      level: BanTypes.PUBLICATION,
      reason,
      subscriber_id: currentSubscriberId,
    });
  };

  return (
    <SettingsContainer>
      <SettingsGroup title="Comments" description="Manage comments on your publication site.">
        <div className="flex flex-col space-y-6">
          <SwitchDescriptionInput
            title="Post Comments Section"
            name="post_comments_section"
            value={webTemplate?.enable_comments || false}
            onSave={(val: boolean) => webTemplateMutation.mutateAsync({ enable_comments: val })}
            description="Allow users to engage in a comments section for a post. You can disable this feature on a post-by-post basis."
            Icon={ChatBubbleLeftRightIcon}
            toastSuccessMessage="Saved!"
          />
          {webTemplate?.enable_comments && (
            <>
              <hr className="w-full" />
              <CommentsOrdering />
              <hr className="w-full" />
              <div className="flex flex-col space-y-1">
                <Typography token="font-medium/text/base" className="text-900">
                  Comment Bans
                </Typography>
                <Typography token="font-light/text/xs" className="text-900">
                  Ban subscriber emails from commenting on posts below.{' '}
                </Typography>
              </div>
              <div className="space-y-6">
                <div className="flex space-x-2 w-full">
                  <div className="w-40">
                    <Dropdown
                      name="comment_ban_type"
                      labelText="Filter by level"
                      value={renderType}
                      onSelect={(_name: string, value: string) => setRenderType(value)}
                      options={[
                        {
                          label: 'All',
                          value: BanScopes.ALL,
                        },
                        {
                          label: 'Post',
                          value: BanTypes.POST,
                        },
                        {
                          label: 'Publication',
                          value: BanTypes.PUBLICATION,
                        },
                      ]}
                    />
                  </div>
                  <div className="w-40">
                    <Dropdown
                      name="comment_ban_status"
                      labelText="Filter by status"
                      value={renderStatus}
                      onSelect={(_name: string, value: any) => setRenderStatus(value)}
                      options={[
                        {
                          label: 'All',
                          value: BanScopes.ALL,
                        },
                        {
                          label: 'Active',
                          value: BanScopes.ACTIVE,
                        },
                        {
                          label: 'Inactive',
                          value: BanScopes.INACTIVE,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              {commentBans && commentBans?.length ? (
                <div>
                  <div>
                    {commentBans.map((commentBan: any) => {
                      return <CommentBanItem key={commentBan.id} commentBan={commentBan} />;
                    })}
                  </div>

                  <div className="flex justify-between pt-4 items-center">
                    <span className="text-xs font-semibold text-gray-400">{`Showing ${showingCommentBansCount} of ${totalCommentBansCount} results`}</span>

                    {hasNextPage && (
                      <div className="text-center my-6">
                        <div>
                          <Button
                            variant="primary-inverse"
                            onClick={() => fetchNextPage()}
                            disabled={!hasNextPage || isFetchingNextPage}
                          >
                            {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {hideFilters ? null : (
                    <Text type="body" size="md" className="text-gray-500">
                      No results
                    </Text>
                  )}
                </div>
              )}
              <Button
                Icon={PlusIcon}
                type="button"
                variant="primary-inverse"
                className="w-fit flex flex-row space-x-2"
                onClick={() => setCommentBansOpen(true)}
              >
                Add Comment Ban
              </Button>
            </>
          )}
        </div>
      </SettingsGroup>
      <ActionModal
        isOpen={commentBansOpen}
        onClose={() => setCommentBansOpen(false)}
        isWorking={createCommentBan.isLoading}
        onProceed={handleCreateCommentBan}
        headerText="Add Comment Ban"
        resourceId="comment_ban"
        actionText="Add Ban"
      >
        <div className="flex flex-col py-4 space-y-2">
          <SubscriberSelect
            onSelectSubscriber={(subscriberId: string) => setCurrentSubscriberId(subscriberId)}
            onClearSubscriber={() => setCurrentSubscriberId('')}
          />
        </div>
        <div className="flex flex-col space-y-2 w-full">
          <div className="flex flex-row justify-between w-full items-between">
            <Typography token="font-medium/text/sm">Reason for ban</Typography>
            <Typography token="font-normal/text/xs" className="my-auto text-gray-500">
              Optional
            </Typography>
          </div>
          <Textarea
            name="reason"
            placeholderText="This user was reported by multiple other users because of..."
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <Typography token="font-light/text/xs" className="text-gray-900">
            Giving an explanation is helpful for other admins to understand why you took this action to ban the
            subscriber(s) from commenting.
          </Typography>
        </div>
      </ActionModal>
    </SettingsContainer>
  );
};

export default WebBuilderComments;
