import { useMemo, useState } from 'react';

import BoostAgreementStatus from '@/interfaces/boosts/grow/boost_agreement_status';
import { BoostSendStatus } from '@/interfaces/boosts/grow/boost_send';

import { useBoostSends } from './useBoostSends';

interface Options {
  maxSpendCents?: number;
  perPage?: number;
}

const useEmailApplications = (boostOfferId?: string, options?: Options) => {
  const [search, setSearch] = useState<string>('');
  const {
    data,
    isLoading,
    isRefetching,
    isError,
    hasNextPage,
    refetch,
    isFetched,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useBoostSends({
    boostOfferId,
    boostAgreementStatus: BoostAgreementStatus.ACTIVE,
    status: BoostSendStatus.PENDING,
    search,
    perPage: options?.perPage,
    orderBy: 'created_at',
  });
  const boostSends = useMemo(() => data?.pages.flatMap((page) => page.boost_sends) || [], [data]);

  const noResults = !isRefetching && !isLoading && boostSends.length === 0;
  const noPendingApplications = !isRefetching && !isLoading && search === '' && boostSends.length === 0;
  const sendableBoostsDisabled = options?.maxSpendCents === 0;

  return {
    hasData: !!data,
    search,
    setSearch,
    boostSends,
    count: data?.pages[0]?.pagination?.total || 0,
    noResults,
    noPendingApplications,
    sendableBoostsDisabled,
    isLoading,
    isFetching,
    isFetched,
    isError,
    hasNextPage,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  };
};

export default useEmailApplications;
