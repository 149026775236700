import { useParams } from 'react-router-dom';

import { APP_LAYOUT_BODY_CONTAINER_ID } from '@/components/Layout/AppLayout/constants';
import { FormContextProvider } from '@/pages/ReferralProgram/Configure/FormContext';
import { ScrollOnMount } from '@/ui/ScrollOnMount';

import FormBreadCrumbs from '../../components/FormBreadcrumbs';
import ReferralForm from '../../components/ReferralForm';

const Edit = () => {
  const { milestoneId } = useParams() as unknown as { milestoneId: string };
  return (
    <FormContextProvider milestoneId={milestoneId}>
      <ScrollOnMount elementId={APP_LAYOUT_BODY_CONTAINER_ID}>
        <FormBreadCrumbs />
        <ReferralForm />
      </ScrollOnMount>
    </FormContextProvider>
  );
};

export default Edit;
