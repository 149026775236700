import ReactTooltip from 'react-tooltip';

import { badgeAttributesByType } from '@/components/_domain/Automations/utils';
import { AutomationState } from '@/interfaces/automations/automation';
import { Badge } from '@/ui/Badge';

const StatusBadge = ({ state }: { state: AutomationState }) => {
  const tooltipText = {
    running: 'This automation is currently live and subscribers who meet the trigger conditions will enter the flow.',
    finishing:
      'This automation will not be triggered for any subscribers but it is currently allowing in-flight subscribers to complete the flow.',
    inactive:
      'This automation will not be triggered for any subscribers and there are no subscribers currently in the flow.',
  }[state];

  return (
    <div>
      <p data-tip data-for="status-indicator" className="capitalize font-medium cursor-help">
        <Badge
          className="capitalize"
          type={badgeAttributesByType[state].type}
          leadingIcon={badgeAttributesByType[state].icon}
        >
          {state}
        </Badge>
      </p>
      <ReactTooltip id="status-indicator" place="bottom" type="dark" effect="solid">
        <p className="w-36">{tooltipText}</p>
      </ReactTooltip>
    </div>
  );
};

export default StatusBadge;
