import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import OptionsDropdown from '@/components/OptionsDropdown';
import { Typography } from '@/components/Typography';
import { usePremiumGifts } from '@/hooks';
import { PremiumGift } from '@/interfaces/premium_gift';
import Section from '@/routes/settings/_components/Section';
import { Button } from '@/ui/Button';

import useDeletePremiumGift from '../../_hooks/useDeletePremiumGift';

import EditPremiumGift from './EditPremiumGift';
import NewPremiumGift from './NewPremiumGift';

const PremiumGiftsSection = () => {
  const [page, setPage] = useState(1);
  const [deletePremiumGiftId, setDeletePremiumGiftId] = useState<string>();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [premiumGift, setPremiumGift] = useState<PremiumGift>();

  const { data, fetchNextPage, isFetchingNextPage } = usePremiumGifts({ search: '', perPage: 5 });
  const { mutate, isLoading: isDeleting } = useDeletePremiumGift(deletePremiumGiftId);

  const premiumGifts = data?.pages[page - 1]?.premiumGifts || [];
  const pagination = data?.pages[page - 1]?.pagination;

  const onFetchNextPage = () => {
    if (data?.pages[page + 1]) {
      setPage(page + 1);
    } else {
      fetchNextPage().then(() => setPage(page + 1));
    }
  };

  const onDeletePremiumGift = () => {
    mutate(undefined, {
      onSuccess: () => {
        setDeletePremiumGiftId(undefined);
        setPage(1);
      },
    });
  };

  return (
    <>
      <ActionModal
        isOpen={!!deletePremiumGiftId}
        onClose={() => setDeletePremiumGiftId(undefined)}
        onProceed={onDeletePremiumGift}
        resourceId={deletePremiumGiftId || ''}
        isWorking={isDeleting}
        headerText="Delete Premium Gift"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this paid subscription gift?
      </ActionModal>
      <EditPremiumGift
        premiumGift={premiumGift}
        isOpen={Boolean(premiumGift)}
        onClose={() => setPremiumGift(undefined)}
      />
      <NewPremiumGift isOpen={isPanelOpen} onClose={() => setIsPanelOpen(false)} />
      <Section
        scrollToId="gift-premium-subscription"
        title="Gift Paid Subscription"
        description="Create custom offers for your readers to get free access to paid subscription tiers"
        maxWidthClassName="max-w-4xl"
      >
        {premiumGifts && premiumGifts.length > 0 && (
          <>
            <Typography as="p" token="font-normal/text/xs" colorWeight="500" className="mb-2">
              Showing page {pagination?.page} of {pagination?.total_pages}
            </Typography>
            <ul className="space-y-2">
              {premiumGifts.map((premium_gift) => {
                return (
                  <li className="p-4 rounded bg-surface-50 border" key={premium_gift.id}>
                    <div className="flex justify-between items-center w-full">
                      <div className="w-2/3">
                        <Typography as="p" token="font-semibold/text/base" className="truncate">
                          {premium_gift.description}
                        </Typography>
                        <Typography as="p" token="font-normal/text/xs" className="truncate" colorWeight="500">
                          {premium_gift.tier_name}
                        </Typography>
                      </div>
                      <div>
                        <OptionsDropdown
                          variant="primary"
                          className="!bg-transparent !border-none !w-5 !h-5"
                          options={[
                            [
                              {
                                label: 'Edit',
                                onClick: () => setPremiumGift(premium_gift),
                              },
                              {
                                label: 'Delete',
                                onClick: () => setDeletePremiumGiftId(premium_gift.id),
                              },
                            ],
                          ]}
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        <div className="mt-6 flex justify-between">
          <Button onClick={() => setIsPanelOpen(true)}>Add New Gift</Button>

          {pagination && pagination.total_pages > 1 && (
            <div className="flex space-x-2">
              <Button
                size="xs"
                disabled={page <= 1}
                onClick={() => setPage(page - 1)}
                variant="flush"
                Icon={ChevronLeftIcon}
              >
                Prev
              </Button>
              <Button
                size="xs"
                disabled={page >= pagination.total_pages}
                loading={isFetchingNextPage}
                onClick={onFetchNextPage}
                variant="flush"
                Icon={ChevronRightIcon}
                iconRight
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </Section>
    </>
  );
};

export default PremiumGiftsSection;
