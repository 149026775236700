import { CaretDown, FilePlus, Plus } from '@phosphor-icons/react';

import { useCreatePage } from '@/hooks/usePages';

import { Button } from '../UI/Button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../UI/DropdownMenu';
import { Text } from '../UI/Text';

const AddPageButton = () => {
  const createPage = useCreatePage({
    routesType: 'custom',
  });

  const createPageMutation = async () => {
    await createPage.mutateAsync({ parentPath: [] });
  };

  return (
    <button
      type="button"
      className="flex items-center gap-3 px-4 py-2 hover:bg-wb-primary outline-none cursor-pointer rounded-lg border border-solid border-wb-primary"
      onClick={createPageMutation}
    >
      <FilePlus weight="bold" className="text-wb-accent" size={24} />
      <div className="flex flex-col w-full items-start">
        <Text weight="semibold" size="xs">
          Blank Page
        </Text>
        <Text variant="secondary" size="3xs">
          Start with a blank page
        </Text>
      </div>
    </button>
  );
};

const BrowseTemplatesButton = () => {
  return (
    <button
      type="button"
      className="opacity-70 relative flex items-center gap-3 px-4 py-2 bg-gradient-to-r from-violet-50 via-background-secondary  to-background-secondary hover:bg-wb-primary outline-none cursor-pointer rounded-lg border border-solid border-wb-primary overflow-hidden"
    >
      <div className="absolute -left-[10px] -top-[10px]">
        <img
          src="https://beehiiv-images-production.s3.amazonaws.com/dream/static_assets/add-templates.png"
          alt="add template"
          className="w-[100px]"
        />
      </div>
      <div className="flex flex-col w-full items-start ml-[70px]">
        <Text weight="semibold" size="xs">
          Templates &rarr;
        </Text>
        <Text variant="secondary" size="3xs">
          Coming Soon
        </Text>
      </div>
    </button>
  );
};

export const AddDropdown = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button LeftIcon={Plus} RightIcon={CaretDown} className="gap-2">
          <Text weight="semibold" size="sm" variant="on-accent" className="tracking-tight">
            New Page
          </Text>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        sideOffset={2}
        align="end"
        className="flex flex-col gap-1 min-w-[260px] bg-neutral-50 dark:bg-neutral-900 p-4"
      >
        <DropdownMenuItem asChild>
          <AddPageButton />
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <BrowseTemplatesButton />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
