import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { useSendInvite } from '@/hooks/useInvites';
import { Button } from '@/ui/Button';

import NotEditing from '../../../Components/NotEditing';

const ResendInvite = ({
  invite,
  organizationId,
  publicationId,
}: {
  invite: {
    id: string;
    invitable_name: string;
    invited_email: string;
    role: { id: string; name: string };
    invitable_type: string;
    invitable_id: string;
  };
  organizationId: string;
  publicationId: string;
}) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const resendMutation = useSendInvite(organizationId);

  const handleResendInvite = async () => {
    await resendMutation.mutateAsync({
      invite: {
        invited_email: invite.invited_email,
        role_id: invite.role.id,
        invitable_type: invite.invitable_type,
        invitable_id: invite.invitable_id,
      },
      publicationId,
    });
  };

  return (
    <>
      <div className="absolute">
        <ActionModal
          isOpen={isConfirming}
          onClose={() => setIsConfirming(false)}
          onProceed={() => handleResendInvite()}
          resourceId={invite.id}
          isWorking={resendMutation.isLoading}
          headerText="Resend Invite"
          actionText="Resend"
        >
          Are you sure you want to resend this team invite? You can always cancel it later.
        </ActionModal>
      </div>
      <div className="flex justify-between items-center border-b border-gray-100 pb-4">
        <NotEditing label={invite.invited_email} value={`${invite.role.name} — ${invite.invitable_name}`} />

        <div>
          <Button variant="primary-inverse" size="xs" onClick={() => setIsConfirming(true)}>
            Resend Invite
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResendInvite;
