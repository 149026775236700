import { useParams, useSearchParams } from 'react-router-dom';

import Card from '@/components/Card';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { PostPlatform, PostStatus } from '@/interfaces/post';
import Comments from '@/pages/Post/Comments';
import PostEngagement from '@/pages/Post/Engagement';
import TabNavigation from '@/ui/TabNavigation';

import NavContainer from '../NavContainer';
import Polls from '../Polls';

interface RouteParams {
  postId: string;
}

const TITLES_BY_TAB = {
  subscribers: 'Most Engaged Subscribers',
  polls: 'Polls',
  comments: 'Comments',
};

export enum TabType {
  SUBSCRIBERS = 'subscribers',
  POLLS = 'polls',
  COMMENTS = 'comments',
}

interface Props {
  defaultTab?: TabType;
}

const EngagementOverview = ({ defaultTab }: Props) => {
  const { postId } = useParams() as unknown as RouteParams;
  const [searchParams] = useSearchParams();
  const activeTabParam = (searchParams.get('tab') as TabType) || defaultTab || TabType.SUBSCRIBERS;

  const { settings } = useSettings();

  return (
    <NavContainer postId={postId}>
      {({ post }) => {
        const { platform, status } = post;
        const isPostOnWeb = platform !== PostPlatform.EMAIL;
        const isPostLive = status === PostStatus.PUBLISHED;

        const tabs = [
          {
            name: 'subscribers',
            label: 'Subscribers',
            selected: activeTabParam === 'subscribers',
            targetRoute: `/posts/${postId}/engagement-overview?tab=subscribers`,
          },
          {
            name: 'polls',
            label: 'Polls',
            selected: activeTabParam === 'polls',
            targetRoute: `/posts/${postId}/engagement-overview?tab=polls`,
            disabled: !settings?.polls,
            disableWith: 'Upgrade to access polls',
          },
          {
            name: 'comments',
            label: 'Comments',
            selected: activeTabParam === 'comments',
            targetRoute: `/posts/${postId}/engagement-overview?tab=comments`,
            disabled: !settings?.comments || !isPostOnWeb || !isPostLive,
            disableWith: 'Only available for published posts with a web version',
          },
        ];

        return (
          <div className="flex flex-col gap-y-4">
            <Card>
              <div className="flex flex-col gap-y-6">
                <div className="flex justify-between items-center">
                  <Typography token="font-medium/text/lg">{TITLES_BY_TAB[activeTabParam]}</Typography>

                  <div className="flex w-fit">
                    <TabNavigation tabs={tabs} variant="tertiary" size="sm" />
                  </div>
                </div>
                <div className="w-full">
                  {activeTabParam === TabType.SUBSCRIBERS ? <PostEngagement postId={post.id} /> : null}
                  {activeTabParam === TabType.POLLS ? <Polls post={post} /> : null}
                  {activeTabParam === TabType.COMMENTS ? <Comments postId={post.id} /> : null}
                </div>
              </div>
            </Card>
          </div>
        );
      }}
    </NavContainer>
  );
};

export default EngagementOverview;
