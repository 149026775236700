import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, ChartOptions, LinearScale, Tooltip, TooltipItem } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export interface Datum {
  label: string;
  tooltipTitle?: string;
  value: number;
}

interface Props {
  data: Datum[];
  options?: any;
}

const BarChart: FC<Props> = (props: Props) => {
  const { data, options } = props;

  const chartOptions: ChartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          title(tooltipItems: TooltipItem<'bar'>[]) {
            const tooltipItem = tooltipItems[0];
            const { dataIndex } = tooltipItem;
            const dataset = tooltipItem.dataset as any;

            if (dataset?.tooltipTitles && dataset?.tooltipTitles[dataIndex]) {
              return dataset?.tooltipTitles[dataIndex];
            }

            return tooltipItem.label;
          },
        },
      },
    },
    ...options,
  };

  const labels = data.map((datum) => datum.label);
  const tooltipTitles = data.map((datum) => (datum.tooltipTitle ? datum.tooltipTitle : datum.label));
  const datasetData = data.map((datum) => datum.value);
  const datasets = [
    {
      id: 1,
      label: '',
      tooltipTitles,
      data: datasetData,
      backgroundColor: [`rgb(236, 72, 153, 0.8)`, '#CBD5E1', '#E0D6CA'],
    },
  ];

  const dataProp = {
    labels,
    datasets,
  };

  return <Bar datasetIdKey="id" data={dataProp} options={chartOptions} />;
};

export default BarChart;
