import { useEffect } from 'react';

import { Button } from '../../../ui/Button';
import { Icon } from '../../../ui/Icon';
import { InputField } from '../../../ui/Input';
import { Panel, PanelDivider, PanelFooter, PanelHeader, PanelHeadline, PanelSection } from '../../../ui/Panel';
import { ToggleSwitch } from '../../../ui/ToggleSwitch';
import { ToggleWrapper } from '../../../ui/ToggleWrapper';
import { Divider } from '../../../ui/Toolbar';

import { Styled } from './styled';
import { TextUnitsSelect } from './TextUnits';

export const ChangeTextOptionsPanel = ({
  attrs,
  setIsPanelOpen,
  textLengthValueCallback,
  textLengthUnitCallback,
  textLengthValue = '',
  textLengthUnit,
  addHeadingCallback,
  addHeadingValue,
  setHasTextOptionsChanges,
  resetTextOptions,
  setLengthValue,
  setAddHeading,
  lengthValue,
  addHeading,
}: {
  attrs: any;
  setIsPanelOpen: Function;
  textLengthValueCallback: Function;
  textLengthUnitCallback: Function;
  textLengthValue?: string;
  textLengthUnit?: string;
  addHeadingCallback: Function;
  addHeadingValue: boolean;
  setHasTextOptionsChanges: Function;
  resetTextOptions: Function;
  setLengthValue: Function;
  setAddHeading: Function;
  lengthValue: string | undefined;
  addHeading: boolean;
}) => {
  const hasTextLengthChanges = textLengthValue !== '';
  const hasAddHeadingChanges = addHeadingValue !== false;
  const hasChanges = hasTextLengthChanges || hasAddHeadingChanges;

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setIsPanelOpen(false);
    }
  };

  useEffect(() => {
    if (hasChanges) {
      setHasTextOptionsChanges(true);
    } else {
      setHasTextOptionsChanges(false);
    }
  }, [lengthValue, addHeading, hasChanges, setHasTextOptionsChanges]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Panel tabIndex={-1} {...attrs}>
      <PanelHeader>
        <Button
          $variant="quaternary"
          $size="small"
          onClick={() => setIsPanelOpen(false)}
          $leftSlot={<Icon name="ChevronLeft" />}
          $fullWidth
        >
          Text options
        </Button>
      </PanelHeader>
      <PanelSection>
        <PanelHeadline>Length</PanelHeadline>
        <Styled.TextLengthWrapper>
          <Styled.TextLengthInputWrapper>
            <InputField
              min="1"
              onChange={(e) => {
                textLengthValueCallback((e.target as HTMLTextAreaElement).value);
                setLengthValue((e.target as HTMLTextAreaElement).value);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              placeholder="Number of"
              type="number"
              value={!lengthValue ? '1' : lengthValue}
            />
          </Styled.TextLengthInputWrapper>
          <TextUnitsSelect defaultUnit={textLengthUnit} setTextUnit={textLengthUnitCallback} />
        </Styled.TextLengthWrapper>
      </PanelSection>
      <PanelDivider />
      <PanelSection>
        <ToggleWrapper
          icon={<Icon name="Heading" />}
          switchEl={
            <ToggleSwitch
              isChecked={addHeading}
              onChange={() => {
                addHeadingCallback(!addHeading);
                setAddHeading(!addHeading);
              }}
            />
          }
        >
          Generate heading
        </ToggleWrapper>
      </PanelSection>
      {hasChanges && (
        <PanelFooter>
          <Divider />
          <Button
            $variant="quaternary"
            $size="small"
            $rightSlot={<Icon name="Reset" />}
            onClick={() => {
              resetTextOptions();
            }}
            $fullWidth
          >
            Reset
          </Button>
        </PanelFooter>
      )}
    </Panel>
  );
};

export default ChangeTextOptionsPanel;
