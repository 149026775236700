import LoadingBox from '@/components/LoadingBox';
import Text from '@/components/Text';
import { usePollPosts } from '@/hooks/usePolls';

import List from './List';

const PostList = ({ pollId }: { pollId: string }) => {
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = usePollPosts({ id: pollId });
  const posts = data?.pages.flatMap((page) => page.posts) || [];

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height="200px">
      {posts?.length > 0 ? (
        <List
          resources={posts}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      ) : (
        <Text size="sm">This poll has not appeared in any posts</Text>
      )}
    </LoadingBox>
  );
};

export default PostList;
