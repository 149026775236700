import { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { cn } from '@/utils/cn';

import { Icon } from '../TiptapEditor/components/ui/Icon';
import { Tooltip } from '../TiptapEditor/components/ui/Tooltip';
import { Shortcuts } from '../TiptapEditor/extensions/Shortcuts';

import { CommentEditorProps } from './CommentEditor.types';
import { suggestions } from './suggestions';

import './CommentEditor.css';

const CommentDocument = Document.extend({
  content: 'paragraph+',
});

export const CommentEditor = ({
  className,
  onSubmit,
  content,
  placeholder = 'Add a comment ...',
  autoFocus,
}: CommentEditorProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const getContainerRef = useCallback(() => containerRef.current || document.body, []);

  const editorRef = useRef<Editor | null>(null);

  const [hasContent, setHasContent] = useState(false);
  const hasContentRef = useRef(hasContent);

  const handleSubmit = useCallback(() => {
    if (!editorRef.current || !hasContentRef.current || !onSubmit) return;

    onSubmit({
      html: editorRef.current.getHTML(),
      json: editorRef.current.getJSON(),
    });

    editorRef.current.chain().setContent('').focus().run();
  }, [onSubmit]);

  const editor = useEditor({
    content,
    extensions: [
      CommentDocument,
      StarterKit.configure({
        document: false,
        blockquote: false,
        code: false,
        codeBlock: false,
        listItem: false,
        hardBreak: false,
        bulletList: false,
        orderedList: false,
      }),
      Underline.configure(),
      Mention.extend({
        priority: 200,
      }).configure({
        renderText({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
        },
        HTMLAttributes: {
          class: 'mention-suggestion',
        },
        suggestion: suggestions(getContainerRef),
      }),
      Placeholder.configure({
        placeholder,
      }),
      Shortcuts.extend({
        priority: 100,
      }).configure({
        additionalShortcuts: {
          'Mod-Enter': () => {
            handleSubmit();
            return true;
          },
          Enter: () => {
            handleSubmit();
            return true;
          },
          'Shift-Enter': ({ editor: e }) => {
            return e.commands.splitBlock();
          },
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: 'outline-none max-h-[8rem] overflow-y-auto comment-editor p-2',
      },
    },
    onCreate: ({ editor: e }) => {
      editorRef.current = e;
    },
    onUpdate: ({ editor: e }) => {
      const contentNonEmpty = !!e.getText().trim();
      setHasContent(contentNonEmpty);
      hasContentRef.current = contentNonEmpty;
    },
  });

  useEffect(() => {
    if (autoFocus && editor) {
      editor.commands.focus();
    }
  }, [autoFocus, editor]);

  return (
    <div ref={containerRef} className={cn('relative cursor-text', className)}>
      <EditorContent editor={editor} />

      <div className="absolute right-1 bottom-[4px] rounded-md shadow bg-transparent">
        <Tooltip enabled title="Add comment" shortcut={['Enter']} tippyOptions={{ placement: 'bottom' }}>
          <button
            type="button"
            className="flex bg-black w-fit h-auto p-1.5 rounded-md hover:bg-primary-500 items-center justify-center disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-black"
            onClick={handleSubmit}
            disabled={!hasContent}
          >
            <Icon name="ArrowSmallRight" className="text-white" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
