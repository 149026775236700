import { createContext, useContext, useMemo } from 'react';

import { ContentTag } from '@/interfaces/content_tag';

interface ContentTagsContextType {
  refetchContentTags: () => void;
  hasNextPage: boolean | undefined;
  isfetchingNextPage: boolean;
  fetchNextPage: () => void;
  contentTags: ContentTag[];
}

const ContentTagsContext = createContext<ContentTagsContextType | undefined>(undefined);

export const useContentTagsContext = () => {
  const context = useContext(ContentTagsContext);
  if (!context) {
    throw new Error('useContentTagsContext must be used within a ContentTagProvider');
  }
  return context;
};

interface ContentTagsProviderProps {
  children: React.ReactNode;
  refetchContentTags: () => void;
  hasNextPage: boolean | undefined;
  isfetchingNextPage: boolean;
  fetchNextPage: () => void;
  contentTags: ContentTag[];
}

export const ContentTagsProvider = ({
  children,
  refetchContentTags,
  hasNextPage,
  fetchNextPage,
  isfetchingNextPage,
  contentTags,
}: ContentTagsProviderProps) => {
  const memoizedValue = useMemo(
    () => ({
      contentTags,
      hasNextPage,
      fetchNextPage,
      refetchContentTags,
      isfetchingNextPage,
    }),
    [contentTags, fetchNextPage, hasNextPage, isfetchingNextPage, refetchContentTags]
  );
  return <ContentTagsContext.Provider value={memoizedValue}>{children}</ContentTagsContext.Provider>;
};
