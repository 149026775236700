import { Link } from 'react-router-dom';

import { PublicationLogo } from '@/components/PublicationLogo';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import useFormattedSendingMetrics from '@/hooks/boosts/grow/useFormattedSendingMetrics';
import useFormattedTopLineMetrics from '@/hooks/boosts/grow/useFormattedTopLineMetrics';
import useOfferStatus from '@/hooks/boosts/grow/useOfferStatus';
import useSendingMetrics from '@/hooks/boosts/grow/useSendingMetrics';
import { BoostAgreementCreateWithReferralCount, BoostAgreementStatus } from '@/interfaces/boosts/grow/boost_agreement';
import { BoostPublicationWithBoostAgreement } from '@/interfaces/boosts/grow/boost_publication_with_boost_agreement';

import BoostAgreementStatusBadge from '../Dashboard/components/BoostAgreementStatusBadge';

import BoostAgreementActionsDropdown from './BoostAgreementActionsDropdown';

interface Props {
  publication: BoostPublicationWithBoostAgreement;
  onStatusChange: () => void;
}

const RecommendingPublicationtListItem = ({ publication, onStatusChange }: Props) => {
  const boostAgreement = publication?.actionable_boost_agreement || publication?.latest_boost_agreement;
  const boostOffer = boostAgreement?.boost_offer;
  const { cost } = useOfferStatus(boostOffer);
  const { data: sendingMetricsData } = useSendingMetrics({
    recommendingPublicationId: publication.id,
  });
  const { openRate } = useFormattedSendingMetrics(sendingMetricsData);
  const { acceptanceRate } = useFormattedTopLineMetrics(publication.top_line_metrics);

  const handleAgreementStatusTransition = () => {
    onStatusChange();
  };

  return (
    <ItemRow>
      <ItemColumn align={AlignType.TOP}>
        <div className="w-64">
          <Link to={`/grow/boosts/recommending_publication/${publication.id}`} className="flex items-center gap-x-2">
            <PublicationLogo publication={publication} size="sm" />
            <Typography weight="medium" colorWeight="900">
              {publication.name}
            </Typography>
          </Link>
        </div>
      </ItemColumn>
      <ItemColumn>
        <BoostAgreementStatusBadge status={boostAgreement.status} />
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {cost}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography weight="medium" size="sm" colorWeight="700">
          {openRate}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography weight="medium" size="sm" colorWeight="700">
          {acceptanceRate}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_pending_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_accepted_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_pending_payments}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_payments}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        {[
          BoostAgreementStatus.ACTIVE,
          BoostAgreementStatus.PAUSED,
          BoostAgreementStatus.INACTIVE
        ].includes(boostAgreement.status) && (
          <BoostAgreementActionsDropdown
            boostAgreement={boostAgreement as BoostAgreementCreateWithReferralCount}
            onSuccess={handleAgreementStatusTransition}
          />
        )}
      </ItemColumn>
    </ItemRow>
  );
};

export default RecommendingPublicationtListItem;
