import { RefObject, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountUp from 'react-countup';
import cx from 'classnames';

import { Publication } from '@/interfaces/publication';
import { PublicationRewind } from '@/interfaces/publication_rewind';

import { getDecimal, getImageUrl, getSuffix } from '../helpers';

type Props = {
  currentViewIndex: number;
  data?: PublicationRewind;
  index?: number;
  currentPublication?: Publication;
  setSlideRef: (ref: RefObject<HTMLDivElement>) => void;
};

const View2 = ({ setSlideRef, currentPublication, data, currentViewIndex, index }: Props) => {
  const [wordsWrittenCount, setWordsWrittenCount] = useState(0);
  const [totalImpressionsCount, setTotalImpressionsCount] = useState(0);
  const [playAnimation, setPlayAnimation] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isCurrentView = currentViewIndex === index;

    if (isCurrentView) {
      setSlideRef(contentRef);
      setWordsWrittenCount(getDecimal(data?.total_words_written || 0));
      setTotalImpressionsCount(getDecimal(data?.total_impressions || 0));
      setTimeout(() => {
        setPlayAnimation(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  const logoUrl = getImageUrl(currentPublication?.logo?.url);

  return (
    <div ref={contentRef} className="w-full h-full relative overflow-hidden rewind-bg-blue">
      <div className="rewind-bg-burst top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] bg-white rounded-[2rem] m-[5%_0_0_5%] p-[35px_55px] flex flex-col border-2 rewind-border-cyber-ink" />
      <div className="rewind-bg-texture top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] m-[5%_0_0_5%] p-[35px] flex flex-col">
        <div className="flex justify-center items-center w-full mt-6 mb-10">
          <img className="h-6 w-auto" src="/rewind-2024/images/beehiiv-logo-wordmark.png" alt="beehiiv logo" />
        </div>
        <h1
          className={cx(
            `font-clash-grotesk-v rewind-text-cyber-ink uppercase font-bold w-full text-[5rem] leading-[4.2rem] text-center mb-6 transition-all duration-300`,
            !playAnimation && 'translate-x-20 opacity-0',
            playAnimation && 'translate-x-0 opacity-1'
          )}
        >
          YOU&apos;RE CRANKING OUT CONTENT…
        </h1>
        <div className="relative flex mt-24">
          <div className="relative flex flex-col w-[50%]">
            <div className="relative w-full h-[50%] rewind-bg-light-peach rounded-[0.75rem] border border-white p-6">
              <div className="pt-4 font-bold text-[6rem] leading-[5.5rem] font-clash-grotesk-v uppercase w-full">
                <CountUp
                  start={0}
                  end={wordsWrittenCount}
                  duration={3}
                  separator=" "
                  decimals={(data?.total_words_written || 0) > 1000 ? 1 : 0}
                  decimal="."
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <span>{getSuffix(data?.total_words_written || 0)}</span>
                    </div>
                  )}
                </CountUp>
              </div>
              <p className="font-satoshi-v rewind-text-cyber-ink text-3xl mt-6 font-medium">Words Written</p>
            </div>
            <div className="relative w-full h-[50%] rewind-bg-light-peach rounded-[0.75rem] border border-white p-6">
              <div className="pt-4 font-bold text-[6rem] leading-[5.5rem] font-clash-grotesk-v uppercase w-full">
                <CountUp start={0} end={totalImpressionsCount} duration={3} separator=" " decimals={1} decimal=".">
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <span>{getSuffix(data?.total_impressions || 0)}</span>
                    </div>
                  )}
                </CountUp>
              </div>
              <p className="font-satoshi-v rewind-text-cyber-ink text-3xl mt-6  font-medium">Total Impressions</p>
            </div>
          </div>
          <div className="relative flex flex-col w-[50%]">
            <div className="relative w-full rewind-bg-pink rounded-[0.75rem] border border-white p-8">
              <p className="font-satoshi-v rewind-text-cyber-ink text-3xl leading-[] font-bold w-full text-center">
                {currentPublication?.name}
              </p>
            </div>
            <div className="relative w-full rewind-bg-pink rounded-[0.75rem] border border-white p-8 h-full overflow-hidden">
              <div className="bg-white w-[200%] h-[2px] absolute rotate-45 top-1/2 -left-1/2" />
              <div className="bg-white w-[200%] h-[2px] absolute -rotate-45 top-1/2 -left-1/2" />
              {logoUrl && (
                <img
                  src={logoUrl}
                  alt={currentPublication?.name}
                  className="block w-full h-full relative border border-white"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View2;
