import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { WebTemplate } from '../../interfaces/web_template';
import api from '../../services/swarm';
import { usePublication } from '../usePublications';

const useWebTemplate = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: currentPublication } = usePublication(currentPublicationId);

  return useQuery<WebTemplate, Error>(
    ['web_template', currentPublicationId],
    () =>
      api
        .get(`/web_template/${currentPublication?.web_template_id}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!currentPublication?.web_template_id,
    }
  );
};

export default useWebTemplate;
