import { useQueryClient } from 'react-query';

import { useWebTemplate } from '@/hooks';
import { LandingPage } from '@/interfaces/landing_page';
import FeaturedPosts from '@/pages/Settings/Pages/Website/Advanced/FeaturedPosts';
import ColorInput from '@/routes/website_builder/_components/Form/ColorInput';
import FontForm from '@/routes/website_builder/_components/Form/PageSpecific/FontForm';
import SelectInput from '@/routes/website_builder/_components/Form/SelectInput';
import SwitchInput from '@/routes/website_builder/_components/Form/SwitchInput';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';
import {
  FeaturedPostsSectionIcon,
  SectionGroupingSlideOut,
} from '@/routes/website_builder/_components/Helpers/SectionGroupingSlideOut';
import { ALIGNMENT_OPTIONS, RADIUS_OPTIONS } from '@/routes/website_builder/_types/options';
import FontPicker from '@/ui/Settings/FontPicker';

import { usePageContext } from '../../Context/PageContext';
import FormHelper from '../../Form/PageSpecific/FormHelper';
import OverrideGlobalSettingNote from '../../Helpers/OverrideGlobalSettingNote';

interface Props {
  handleSave: (payload: any) => void;
  page: LandingPage;
}

const FeaturedPostsSection = ({ handleSave, page }: Props) => {
  const queryClient = useQueryClient();
  const webTemplateRequest = useWebTemplate();
  const { fontOptions } = usePageContext();

  const featuredPostsSectionWithDefaults = page.featured_posts_section_with_defaults;

  return (
    <SectionGroupingSlideOut
      title="Featured Posts Section"
      showPlanBadge
      sectionIcon={<FeaturedPostsSectionIcon />}
      highlightProps={{
        sectionId: 'featured-posts-section',
      }}
    >
      <PropertiesGrouping label="General">
        <SwitchInput
          label="Enabled"
          name="featured-posts-section-enabled"
          enabled={featuredPostsSectionWithDefaults.enabled}
          onSave={(value: boolean) => {
            handleSave({
              ...page?.featured_posts_section,
              enabled: value,
            });
          }}
        />
        {featuredPostsSectionWithDefaults.enabled && (
          <>
            <SwitchInput
              label="All Post Images Enabled"
              name="featured-posts-section-all-post-images-enabled"
              enabled={featuredPostsSectionWithDefaults.enable_all_post_images}
              onSave={(value: boolean) => {
                handleSave({
                  ...page?.featured_posts_section,
                  enable_all_post_images: value,
                });
              }}
            />
            <ColorInput
              label="Background"
              color={featuredPostsSectionWithDefaults.bg_color}
              onSave={(color: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  bg_color: color,
                });
              }}
            />
            <SelectInput
              label="Text Alignment"
              value={featuredPostsSectionWithDefaults.text_align}
              options={ALIGNMENT_OPTIONS}
              onSave={(value: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  text_align: value,
                });
              }}
            />
            <FontForm
              label="Overline"
              supportsMarkdown
              textValue={featuredPostsSectionWithDefaults.overline}
              onSaveText={(value) => {
                handleSave({
                  ...page?.featured_posts_section,
                  overline: value,
                });
              }}
              onSave={(key, value) => {
                handleSave({
                  ...page?.featured_posts_section,
                  [key]: value,
                });
              }}
              editableProps={{
                overline_color: {
                  type: 'colorInput',
                  value: featuredPostsSectionWithDefaults.overline_color,
                  resetCheckValue: page?.featured_posts_section?.overline_color,
                  resetLabel: 'Global Overline Color',
                },
                overline_font_family: {
                  type: 'fontFamilySelect',
                  value: featuredPostsSectionWithDefaults.overline_font_family,
                  resetCheckValue: page?.featured_posts_section?.overline_font_family,
                  resetLabel: 'Global Heading Font',
                },
                overline_font_size: {
                  type: 'fontSizeSelect',
                  value: featuredPostsSectionWithDefaults.overline_font_size,
                },
              }}
            />
            <FontForm
              label="Headline"
              textValue={featuredPostsSectionWithDefaults.headline}
              onSaveText={(value) => {
                handleSave({
                  ...page?.featured_posts_section,
                  headline: value,
                });
              }}
              onSave={(key, value) => {
                handleSave({
                  ...page?.featured_posts_section,
                  [key]: value,
                });
              }}
              editableProps={{
                headline_color: {
                  type: 'colorInput',
                  value: featuredPostsSectionWithDefaults.headline_color,
                  resetCheckValue: page?.featured_posts_section?.headline_color,
                  resetLabel: 'Global Headline Color',
                },
                headline_font_family: {
                  type: 'fontFamilySelect',
                  value: featuredPostsSectionWithDefaults.headline_font_family,
                  resetCheckValue: page?.featured_posts_section?.headline_font_family,
                  resetLabel: 'Global Heading Font',
                },
                headline_font_size: {
                  type: 'fontSizeSelect',
                  value: featuredPostsSectionWithDefaults.headline_font_size,
                },
              }}
            />
          </>
        )}
      </PropertiesGrouping>
      {featuredPostsSectionWithDefaults.enabled && (
        <>
          <PropertiesGrouping label="Featured Posts">
            <div className="pt-3">
              <FeaturedPosts
                webTemplateRequest={webTemplateRequest}
                autoSave
                onSuccess={() => {
                  queryClient.invalidateQueries(['landing_page', page?.id, 'featured_posts']);
                }}
              />
            </div>
          </PropertiesGrouping>
          <FormHelper
            label="Post Cards"
            description={<OverrideGlobalSettingNote to="/website_builder?sectionId=post-styles" />}
            actionLabel="Styles"
            alwaysShowingChildren={null}
          >
            <SwitchInput
              label="Fill Enabled"
              name="featured-posts-section-card-fill-enabled"
              enabled={featuredPostsSectionWithDefaults?.card?.fill_enabled || false}
              onSave={(value: boolean) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    fill_enabled: value,
                  },
                });
              }}
            />
            {featuredPostsSectionWithDefaults?.card?.tags_enabled && (
              <>
                <ColorInput
                  label="Background Color"
                  color={featuredPostsSectionWithDefaults?.card.bg_color}
                  onSave={(color: string) => {
                    handleSave({
                      ...page?.featured_posts_section,
                      card: {
                        ...page?.featured_posts_section.card,
                        bg_color: color,
                      },
                    });
                  }}
                />
                <ColorInput
                  label="Border Color"
                  color={featuredPostsSectionWithDefaults?.card.border_color}
                  onSave={(color: string) => {
                    handleSave({
                      ...page?.featured_posts_section,
                      card: {
                        ...page?.featured_posts_section.card,
                        border_color: color,
                      },
                    });
                  }}
                />
              </>
            )}
            <SelectInput
              label="Radius"
              value={featuredPostsSectionWithDefaults.card.border_radius}
              options={RADIUS_OPTIONS}
              resettable={{
                resetValue: null,
                check: page?.featured_posts_section.card.border_radius !== null,
                label: 'Global Radius',
              }}
              onSave={(value: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    border_radius: value,
                  },
                });
              }}
            />
            <ColorInput
              label="Heading Color"
              color={featuredPostsSectionWithDefaults.card.text_color}
              onSave={(color: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    text_color: color,
                  },
                });
              }}
            />
            <FontPicker
              label="Heading Font Family"
              searchPlaceholder="Search Font"
              value={featuredPostsSectionWithDefaults.card.heading_font}
              noOptionsText="No Matching Fonts"
              options={fontOptions}
              resettable={{
                check: page.featured_posts_section.card.heading_font !== null,
                label: 'Global Heading Font',
                resetValue: null,
              }}
              onSave={(value: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    heading_font: value,
                  },
                });
              }}
            />
            <ColorInput
              label="Body Color"
              color={featuredPostsSectionWithDefaults.card.body_text_color}
              onSave={(color: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    text_color: color,
                  },
                });
              }}
            />
            <FontPicker
              label="Body Font Family"
              searchPlaceholder="Search Font"
              value={featuredPostsSectionWithDefaults.card.font}
              noOptionsText="No Matching Fonts"
              options={fontOptions}
              resettable={{
                check: page.featured_posts_section.card.font !== null,
                label: 'Global Body Font',
                resetValue: null,
              }}
              onSave={(value: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    font: value,
                  },
                });
              }}
            />
            <ColorInput
              label="Premium Text Color"
              color={featuredPostsSectionWithDefaults.card.premium_text_color}
              onSave={(color: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    premium_text_color: color,
                  },
                });
              }}
            />
            <ColorInput
              label="Insights Color"
              color={featuredPostsSectionWithDefaults?.card?.timestamp_text_color}
              onSave={(color: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    timestamp_text_color: color,
                  },
                });
              }}
            />
            <SwitchInput
              label="Tags Enabled"
              name="featured-posts-section-card-tags-enabled"
              enabled={featuredPostsSectionWithDefaults?.card?.tags_enabled || false}
              onSave={(value: boolean) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    tags_enabled: value,
                  },
                });
              }}
            />
            <ColorInput
              label="Tags Background Color"
              color={featuredPostsSectionWithDefaults?.card?.tags_bg_color}
              onSave={(color: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    tags_bg_color: color,
                  },
                });
              }}
            />
            <ColorInput
              label="Tags Text Color"
              color={featuredPostsSectionWithDefaults?.card?.tags_text_color}
              onSave={(color: string) => {
                handleSave({
                  ...page?.featured_posts_section,
                  card: {
                    ...page?.featured_posts_section.card,
                    tags_text_color: color,
                  },
                });
              }}
            />
          </FormHelper>
        </>
      )}
    </SectionGroupingSlideOut>
  );
};

export default FeaturedPostsSection;
