import { Helmet } from 'react-helmet';

import KpisContainer from '@/pages/ReferralProgram/Overview/KpisContainer';

import Tabs from './components/Tabs';
import TopLineMetrics from './components/TopLineMetrics';

export interface Props {}

const ReferralProgramOverview = () => {
  return (
    <>
      <Helmet>
        <title>Referral Program - beehiiv</title>
      </Helmet>
      <div className="flex flex-col gap-y-5">
        <KpisContainer>{(kpiData, isLoading) => <TopLineMetrics data={kpiData} isLoading={isLoading} />}</KpisContainer>
        <Tabs />
      </div>
    </>
  );
};

export default ReferralProgramOverview;
