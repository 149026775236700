import { Typography } from '@/components/Typography';
import { Option } from '@/interfaces/general';
import { Dropdown } from '@/ui/Dropdown';
import { MultiSelectDropdown } from '@/ui/MultiSelectDropdown';
import TextInput from '@/ui/TextInput';

import { FILTERS_POPOVER_OPERATOR_LABELS } from './FiltersPopover.constants';
import {
  FiltersPopopoverOperator,
  FiltersPopoverNumberInputSection,
  FiltersPopoverSection as FiltersPopoverSectionProps,
  FiltersPopoverSectionType,
  FiltersPopoverSelectSection
} from './FiltersPopover.types';

const SectionTitle: React.FC<{ title: string }> = ({ title }) => (
  <Typography token="font-medium/text/sm">{title}</Typography>
);

const FiltersPopoverSection: React.FC<FiltersPopoverSectionProps> = (props) => {
  const { type, title } = props;

  if (type === FiltersPopoverSectionType.HEADER) {
    return (
      <div className="px-4 pt-4 mt-6 border-t border-surface-200">
        <SectionTitle title={title} />
      </div>
    );
  }

  const {
    name,
    labelText,
    placeholderText,
    badgeType,
    operatorValue,
    operators,
    onOperatorChange,
  } = props;

  const renderNumberInput = ({ value, min, max, onChange }: FiltersPopoverNumberInputSection) => (
    <div className="w-fit min-w-[116px]">
      <TextInput
        name={name}
        value={value?.toString()}
        onChange={(e) => onChange(parseInt(e.target.value, 10))}
        placeholder={placeholderText || 'Enter number'}
        type="number"
        min={min}
        max={max}
      />
    </div>
  );

  const renderSelectInput = ({ options, value, onSelect }: FiltersPopoverSelectSection) => (
    <MultiSelectDropdown
      name={name}
      placeholderText={placeholderText || 'Options'}
      staticOptions={options}
      selectedTagType={badgeType || 'info_blue'}
      values={options.filter((option) => value.includes(option.value))}
      shouldBindOptionAsValue
      onSelect={(_, newValue: Option[]) => {
        onSelect(newValue.map((option) => option.value));
      }}
      search
      emptyLabel="No options found"
      searchHandler={(query) => {
        return options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        );
      }}
      maxVisibleSelectedOptions={1}
      className="min-w-0"
      tooltipClass="max-w-[200px]"
      tooltipContainerClass="min-w-0 flex-1"
      optionsContainerClassNames={{ width: 'min-w-64 left-0' }}
      optionsPosition="bottom-start"
    />
  );

  const renderInput = () => {
    switch (type) {
      case FiltersPopoverSectionType.NUMBER:
        return renderNumberInput(props);
      case FiltersPopoverSectionType.SELECT:
        return renderSelectInput(props);
      default:
        throw new Error(`Unsupported input type: ${type}`);
    }
  };

  return (
    <div className="px-4 space-y-2">
      {title && <SectionTitle title={title} />}
      <div className="flex justify-start items-center space-x-3">
        {operators && (
          <Dropdown
            name={`${name}-operator`}
            placeholderText="Options"
            value={operatorValue}
            options={operators.map((operator) => ({
              label: FILTERS_POPOVER_OPERATOR_LABELS[operator],
              value: operator,
            }))}
            onSelect={(_, newValue) =>
              onOperatorChange?.(newValue as FiltersPopopoverOperator)
            }
            buttonClassNames={{ background: 'bg-surface-50' }}
            optionsContainerClassNames={{ width: 'min-w-36 left-0' }}
          />
        )}

        {labelText && (
          <Typography token="font-normal/text/sm" className="flex-none">
            {labelText}
          </Typography>
        )}

        {renderInput()}
      </div>
    </div>
  );
};

export default FiltersPopoverSection;
