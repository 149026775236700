import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import styled from 'styled-components';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { InputField } from '@/components/TiptapEditor/components/ui/Input';
import { Panel, PanelDivider, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';

import Styled from '../../../styled';

import useAnchorSettingsPanel from './AnchorSettingsPanel.hooks';

const StyledInputWrapper = styled.div`
  label {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  & + & {
    margin-top: 0.5rem;
  }
`;

export const AnchorSettingsPanel = ({
  editor,
  onBack,
  currentNode,
  currentNodePos,
  compact = false,
}: {
  editor: Editor;
  onBack: Function;
  currentNode: Node | null;
  currentNodePos: number;
  compact?: boolean;
}) => {
  const {
    onChangeToggleAnchor,
    onClickResetAnchorSync,
    onChangeToggleIncludeInToc,
    onChangeAnchorTitle,
    onChangeAnchorId,
    inputsData,
  } = useAnchorSettingsPanel(editor, currentNode, currentNodePos);

  const nodeAnchorEnabled = currentNode?.attrs.anchorEnabled;
  const nodeAnchorTitle = currentNode?.attrs.anchorTitle;
  const nodeAnchorTitleSync = currentNode?.attrs.anchorTitleSync;
  const nodeAnchorId = currentNode?.attrs.anchorId;
  const nodeAnchorIdSync = currentNode?.attrs.anchorIdSync;
  const nodeAnchorIncludeInToc = currentNode?.attrs.anchorIncludeInToc;

  return (
    <Styled.ContentWrapper>
      <Panel>
        {!compact && (
          <PanelHeader>
            <Button
              $variant="quaternary"
              $size="small"
              $leftSlot={<Icon name="ChevronLeft" />}
              onClick={onBack}
              $fullWidth
            >
              Anchor
            </Button>
          </PanelHeader>
        )}
        <PanelSection>
          <Styled.PanelSectionInner>
            {!compact && (
              <ToggleWrapper
                icon={<Icon name="Anchor" />}
                switchEl={<ToggleSwitch isChecked={nodeAnchorEnabled || false} onChange={onChangeToggleAnchor} />}
              >
                Set anchor
              </ToggleWrapper>
            )}
            {nodeAnchorEnabled && (
              <>
                {!compact && (
                  <>
                    <ToggleWrapper
                      icon={<Icon name="Show" />}
                      switchEl={
                        <ToggleSwitch
                          isChecked={nodeAnchorIncludeInToc !== false}
                          onChange={onChangeToggleIncludeInToc}
                        />
                      }
                    >
                      Show in table of contents
                    </ToggleWrapper>
                    <PanelDivider />
                  </>
                )}
                <StyledInputWrapper>
                  <InputField
                    label="Anchor title"
                    placeholder={nodeAnchorEnabled && nodeAnchorTitleSync && nodeAnchorTitle ? nodeAnchorTitle : ''}
                    onChange={onChangeAnchorTitle}
                    value={inputsData.title?.toString() || ''}
                  />
                </StyledInputWrapper>
                <StyledInputWrapper>
                  <InputField
                    label="Anchor id"
                    placeholder={nodeAnchorEnabled && nodeAnchorIdSync && nodeAnchorId ? nodeAnchorId : ''}
                    onChange={onChangeAnchorId}
                    value={inputsData.id?.toString() || ''}
                  />
                </StyledInputWrapper>
                {nodeAnchorEnabled && (!nodeAnchorTitleSync || !nodeAnchorIdSync) && (
                  <Button
                    $variant="quaternary"
                    $size="small"
                    onClick={onClickResetAnchorSync}
                    $fullWidth
                    $leftSlot={<Icon name="Reset" />}
                    $active
                    $muted
                    className="mt-1"
                  >
                    Reset to defaults
                  </Button>
                )}
                {/* TODO: Revise as we need the post URL  */}
                {/* <PanelDivider /> */}
                {/* <Button
                  $variant="quaternary"
                  $size="small"
                  onClick={() => copy({ text: `${window.location.href}#${anchorId}` })}
                  $fullWidth
                  $leftSlot={<Icon name="Copy" />}
                  $muted
                >
                  Copy anchor link
                </Button> */}
              </>
            )}
          </Styled.PanelSectionInner>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default AnchorSettingsPanel;
