import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { RewardPromoCode } from '@/interfaces/reward';
import api from '@/services/swarm';

interface ApiResponse {
  promo_codes: RewardPromoCode[];
  pagination: Pagination;
}

interface Arguments {
  rewardId?: string | null;
  params: {
    notAssigned?: boolean;
    allResults?: boolean;
    perPage?: number;
  };
  isEnabled?: boolean;
}

export default function useRewardPromoCodes({ rewardId, params, isEnabled }: Arguments) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPromoCodes = ({ pageParam = 1 }) =>
    api
      .get(`/referral_program/rewards/${rewardId}/promo_codes`, {
        params: {
          publication_id: currentPublicationId,
          not_assigned: params?.notAssigned,
          all_results: params?.allResults,
          per_page: params?.perPage || 5,
          page: pageParam,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    [currentPublicationId, 'referral_program', 'rewards', rewardId, 'promo_codes'],
    fetchPromoCodes,
    {
      enabled: isEnabled,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
    }
  );
}
