import moment from 'moment-mini';

import Text from '@/components/Text';
import { ActivityFeedEvent } from '@/interfaces/activity_feed_event';

import EventIcon from './EventIcon';
import EventMetadata from './EventMetadata';
import EventTitle from './EventTitle';

const ActivityFeedItem = ({ event }: { event: ActivityFeedEvent }) => {
  return (
    <div
      className="mb-0 sm:grid gap-2 grid-cols-2 grid-rows-2"
      style={{ gridTemplateColumns: 'min-content auto', gridTemplateRows: 'min-content auto' }}
    >
      <div className="hidden sm:block">
        <EventIcon type={event.type} />
      </div>
      <div className="flex items-center w-full justify-between">
        <EventTitle event={event} />
        <Text size="sm" className="text-gray-400 text-right">
          <time title={moment(event.timestamp).format('LL LTS')}>{moment(event.timestamp).format('ll - LTS')}</time>
        </Text>
      </div>
      <div className="min-h-[26px] w-[2px] bg-surface-200 col-span-2 mt-2 sm:mt-0 sm:mx-auto sm:col-span-1" />
      <EventMetadata event={event} />
    </div>
  );
};

export default ActivityFeedItem;
