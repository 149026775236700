/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import RcSlider, { SliderProps } from 'rc-slider';
import styled from 'styled-components';

import { Input } from '../Form';
import Text from '../Text';
import Tooltip from '../Tooltip';

import 'rc-slider/assets/index.css';

export const Slider = styled(RcSlider).attrs<SliderProps>({
  className: 'mt-2',
  trackStyle: { backgroundColor: '#F472B6' },
  handleStyle: {
    backgroundColor: '#FF73E3',
    border: 'none',
  },
})``;

interface Props extends SliderProps {
  onChange: (val: number) => void;
  label: string;
  max: number;
  min: number;
  step?: number;
  name: string;
  value: number;
  className?: string;
  tooltip?: string;
  onTooltip?: () => void;
  valueSuffix?: string;
  warningText?: string;
  disabled?: boolean;
  totalNumber?: number;
  portalMountedId?: string;
}

const PercentSliderField = ({
  onChange,
  label,
  value,
  name,
  className = '',
  tooltip,
  onTooltip = () => {},
  max,
  min,
  step = 1,
  valueSuffix,
  warningText,
  disabled = false,
  totalNumber,
  portalMountedId,
  ...sliderConfig
}: Props) => {
  const constrainedValue = (val: number) => Math.min(Math.max(val, min), max);

  const [currentValue, setCurrentValue] = useState(constrainedValue(value));

  const handleChange = (val: number) => {
    setCurrentValue(val);

    if (onChange) {
      onChange(constrainedValue(val));
    }
  };

  return (
    <div className={className}>
      <div className="flex justify-between">
        <div className="flex items-center space-x-2">
          <div className="font-medium text-sm">{label}</div>
          {tooltip && (
            <div>
              <Tooltip
                iconClass="text-gray-800"
                id={name}
                text={tooltip}
                onHover={onTooltip}
                portalMountedId={portalMountedId}
              />
            </div>
          )}
        </div>
        <div className="flex items-center space-x-2 text-sm">
          <Input
            name={name}
            value={currentValue.toString()}
            type="number"
            min={min}
            max={max}
            step={step}
            onChange={(e) => {
              handleChange(Number(e.target.value));
            }}
            disabled={disabled}
          />
          <Text>%</Text>
        </div>
      </div>
      <div className="">
        <div className="flex flex-row text-md text-gray-500 text-xs">
          {totalNumber && Math.ceil((currentValue * totalNumber) / 100).toLocaleString('en-US')}
          {valueSuffix && <Text className="ml-1">{valueSuffix}</Text>}
        </div>
        <Slider
          min={min}
          max={max}
          value={currentValue}
          onChange={handleChange}
          disabled={disabled}
          {...sliderConfig}
        />
        {warningText ? <div className="text-xs text-yellow-600">{warningText}</div> : <div className="mt-5" />}
      </div>
    </div>
  );
};

export default PercentSliderField;
