import { useNavigate, useSearchParams } from 'react-router-dom';

import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useMilestones } from '@/hooks/useMilestones';
import PromoCodesSlideOver from '@/pages/ReferralProgram/Configure/PromoCodesSlideOver';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import ReferralRow from './ReferralRow';

const ReferralsTable = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching, isLoading } = useMilestones({
    allResults: false,
    additionalParams: {
      per_page: 5,
    },
  });

  const milestones = data?.pages.flatMap((page) => page.milestones) || [];
  const noResults = !isRefetching && !isLoading && milestones.length === 0;

  const editablePromoCodesRewardId = searchParams.get('manage_promo_codes');
  const handleCloseEditPromoCodes = () => {
    searchParams.delete('manage_promo_codes');
    setSearchParams(searchParams);
  };

  return (
    <>
      <PromoCodesSlideOver
        isOpen={!!editablePromoCodesRewardId}
        onClose={handleCloseEditPromoCodes}
        publicationId={currentPublicationId}
        rewardId={editablePromoCodesRewardId}
      />

      {noResults ? (
        <EmptyCard
          onPrimaryActionClick={() => navigate('/referral_program/milestones/new')}
          primaryActionLabel="Add Your First Referral"
        />
      ) : null}

      {!noResults ? (
        <Items>
          {(isInViewport) => {
            return (
              <>
                <ItemHeaders isSticky={isInViewport}>
                  <ItemHeader align={AlignType.LEFT}>Reward Name</ItemHeader>
                  <ItemHeader align={AlignType.LEFT}>Auto Fulfill</ItemHeader>
                  <ItemHeader align={AlignType.LEFT}>Type</ItemHeader>
                  <ItemHeader align={AlignType.LEFT}>Milestone</ItemHeader>
                  <ItemHeader>&nbsp;</ItemHeader>
                </ItemHeaders>

                <ItemsBody>
                  {milestones.map((milestone) => (
                    <ReferralRow milestone={milestone} />
                  ))}
                </ItemsBody>
              </>
            );
          }}
        </Items>
      ) : null}
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </>
  );
};

export default ReferralsTable;
