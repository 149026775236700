import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { LightBulbIcon, RocketLaunchIcon, StarIcon } from '@heroicons/react/24/outline';

import LargeIconRadioGroup, { LargeIconRadioOption } from '@/components/Form/LargeIconRadioGroup';
import { useCurrentUser } from '@/context/current-user-context';
import { useOnboarding } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { OnboardingTypes } from '@/interfaces/onboarding';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import { Layout, LeftPane, RightPane } from '../_components/Layout';
import OnboardingStepForm from '../_components/OnboardingStepForm';

const PERSONA_OPTIONS: LargeIconRadioOption[] = [
  {
    name: 'Experienced',
    value: OnboardingTypes.MIGRATING,
    description: 'I have an existing newsletter',
    Icon: StarIcon,
    variant: 'secondary',
    size: 'md',
    padding: 'lg',
  },
  {
    name: 'Beginner',
    value: OnboardingTypes.STARTING,
    description: "I'm starting a newsletter",
    Icon: RocketLaunchIcon,
    variant: 'tertiary',
    size: 'md',
    padding: 'lg',
  },
  {
    name: 'Curious',
    value: OnboardingTypes.LOOKING,
    description: "I'm unsure, but interested in newsletters",
    Icon: LightBulbIcon,
    variant: 'warning',
    size: 'md',
    padding: 'lg',
  },
];

const OnboardingPersonas = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const publicationId = useCurrentPublicationId();
  const { data: currentPublication } = usePublication(publicationId);
  const { data: onboarding } = useOnboarding(publicationId, currentPublication?.onboarding_id);

  const [selectedOption, setSelectedOption] = useState<LargeIconRadioOption | null>(null);
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    analytics.track('Onboarding Started');
  }, []);

  useEffect(() => {
    if (onboarding) {
      const foundOption = PERSONA_OPTIONS.find((option) => option.value === onboarding?.onboarding_type);
      if (foundOption) {
        setSelectedOption(foundOption);
      }
    }
  }, [onboarding]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsRequesting(true);
    api
      .patch(`/onboarding/${currentPublication?.onboarding_id}`, {
        onboarding_type: selectedOption?.value,
        publication_id: currentPublication?.id,
      })
      .then(() => {
        if (currentUser?.id) {
          analytics.identify(currentUser?.id, {
            qualification: selectedOption?.value,
          });
        }

        analytics.track('Started Onboarding', {
          qualification: selectedOption?.value,
          email: currentUser?.email,
        });
      })
      .finally(() => {
        setIsRequesting(false);
        const isMigrating = selectedOption?.value === OnboardingTypes.MIGRATING;
        if (isMigrating) {
          return navigate('/onboarding/platform_migration');
        }

        return navigate('/onboarding/publication_details');
      });
  };

  return (
    <>
      <Helmet>
        <title>Personas - beehiiv</title>
      </Helmet>

      <Layout>
        <LeftPane>
          <OnboardingStepForm
            title="What is your newsletter experience level?"
            subtitle="This will help us personalize your newsletter setup."
            onSubmit={handleSubmit}
            onSkip={() => navigate('/onboarding/platform_migration')}
            isProcessing={isRequesting}
            isSubmitDisabled={!selectedOption}
          >
            <LargeIconRadioGroup
              selected={selectedOption}
              onSelected={setSelectedOption}
              options={PERSONA_OPTIONS}
              gap="0"
            />
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingPersonas;
