import { Button } from '../../../ui/Button';
import { Icon } from '../../../ui/Icon';
import { Panel, PanelFooter, PanelHeader, PanelSection } from '../../../ui/Panel';
import { Divider } from '../../../ui/Toolbar';
import { languages } from '../../AiTools/constants';
import { LanguageType } from '../../AiTools/types';
import Styled from '../styled';

export const ChangeTextLanguagePanel = ({
  attrs,
  setIsPanelOpen,
  valueCallback,
  setCurrentLanguageName,
  setHasTextLanguageChanges,
  language,
  setLanguage,
  resetLanguage,
}: {
  attrs: any;
  valueCallback: Function;
  setIsPanelOpen: Function;
  setCurrentLanguageName: Function;
  setHasTextLanguageChanges: Function;
  language: string | undefined;
  setLanguage: Function;
  resetLanguage: Function;
}) => {
  const currentLanguage = languages.find((l) => l.value === language);
  const currentLanguageName = currentLanguage?.label;
  const hasChanges = !!currentLanguageName;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Panel tabIndex={-1} {...attrs}>
      <PanelHeader>
        <Button
          $variant="quaternary"
          $size="small"
          onClick={() => setIsPanelOpen(false)}
          $leftSlot={<Icon name="ChevronLeft" />}
          $fullWidth
        >
          Language
        </Button>
      </PanelHeader>
      <PanelSection>
        <Styled.ButtonWrapper>
          {languages.map((l: LanguageType) => (
            <Button
              key={l.name}
              $variant="quaternary"
              $size="small"
              onClick={() => {
                setLanguage(l.value);
                valueCallback(l.value);
                setCurrentLanguageName(l.label);
                setHasTextLanguageChanges(true);
                setIsPanelOpen(false);
              }}
              $fullWidth
              $active={l.value === language}
            >
              {l.label}
            </Button>
          ))}
        </Styled.ButtonWrapper>
      </PanelSection>
      {hasChanges && (
        <PanelFooter>
          <Divider />
          <Button
            $variant="quaternary"
            $size="small"
            $rightSlot={<Icon name="Reset" />}
            onClick={() => {
              resetLanguage();
            }}
            $fullWidth
          >
            Reset
          </Button>
        </PanelFooter>
      )}
    </Panel>
  );
};

export default ChangeTextLanguagePanel;
