import { MouseEventHandler } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

import IconButton from '@/components/IconHelpers/IconButton';
import { LineDivider } from '@/components/LineDivider';
import Text from '@/components/Text';
import Switch from '@/ui/Switch';

interface Props {
  title: string;
  onClose: () => void;
  canActivate?: boolean;
  isStepActive?: boolean;
  onActivate?: (checked: boolean) => void;
}

const ConfigureHeader = ({ title, onClose, onActivate, canActivate = false, isStepActive = false }: Props) => {
  const handleClickClose: MouseEventHandler<HTMLButtonElement> = () => onClose();
  const handleSwitchChange = (name: string, checked: boolean) => onActivate && onActivate(checked);

  return (
    <div className="flex justify-between w-full">
      <Text size="lg" type="subtitle">
        {title}
      </Text>
      <div className="flex items-center">
        {canActivate && (
          <>
            <Switch
              name="activate-automation-step"
              onChange={handleSwitchChange}
              checked={isStepActive}
              variant="primary"
              size="small"
              prefixLabelText="Activate"
            />
            <LineDivider direction="vertical" className="mx-2" />
          </>
        )}
        <IconButton onClick={handleClickClose}>
          <XMarkIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ConfigureHeader;
