import { useState } from 'react';
import { useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { useDeletePaywall, usePaywalls } from '@/hooks/usePaywalls';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Paywall } from '@/interfaces/paywall';
import Section from '@/routes/settings/_components/Section';
import { Button } from '@/ui/Button';

import FormParent from './FormParent';
import PaywallModal from './PaywallModal';
import Table from './Table';

const PaywallSection = () => {
  const queryClient = useQueryClient();
  const currentPublicationId = useCurrentPublicationId();
  const queryKey = ['paywalls', currentPublicationId];

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const [payWallToEdit, setPaywallToEdit] = useState<Paywall | null>(null);
  const [paywallToDelete, setPaywallToDelete] = useState<Paywall | null>(null);

  const { data, hasNextPage, fetchNextPage, isLoading } = usePaywalls(currentPublicationId);
  const paywalls = data?.pages.flatMap((page) => page.paywalls) || [];
  const hasPaywalls = paywalls.length > 0;

  const handleInvalidateQueries = () => {
    queryClient.invalidateQueries(queryKey);
  };

  const deletePaywall = useDeletePaywall({
    paywallId: paywallToDelete?.id || '',
    onSuccess: () => {
      setPaywallToDelete(null);
    },
  });

  const handleDelete = () => {
    deletePaywall.mutate();
  };

  return (
    <Section
      scrollToId="paywall"
      title="Paywalls"
      description="Customize paywalls to pull into your newsletter and website"
      maxWidthClassName="max-w-4xl"
    >
      <ActionModal
        isOpen={!!paywallToDelete}
        onClose={() => setPaywallToDelete(null)}
        onProceed={handleDelete}
        resourceId={paywallToDelete?.id || ''}
        isWorking={deletePaywall.isLoading}
        headerText="Delete Paywall"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this paywall?
      </ActionModal>
      <PaywallModal
        isOpen={Boolean(payWallToEdit) || isPaywallModalOpen}
        onClose={() => {
          setPaywallToEdit(null);
          setIsPaywallModalOpen(false);
        }}
      >
        <FormParent
          paywall={payWallToEdit || undefined}
          onSuccess={() => {
            setPaywallToEdit(null);
            setIsPaywallModalOpen(false);
          }}
        />
      </PaywallModal>
      <div className="flex flex-col gap-4">
        {hasPaywalls && (
          <Table
            currentPublicationId={currentPublicationId}
            paywalls={paywalls}
            pagination={data?.pages[0]?.pagination}
            onEditSelected={setPaywallToEdit}
            onDeleteSelected={setPaywallToDelete}
            handleInvalidateQueries={handleInvalidateQueries}
            onLoadMore={() => {
              if (hasNextPage) {
                fetchNextPage();
              }
            }}
            isLoadingMore={isLoading}
            hasNextPage={Boolean(hasNextPage)}
          />
        )}
        <div>
          <Button onClick={() => setIsPaywallModalOpen(true)}>Add Paywall</Button>
        </div>
      </div>
    </Section>
  );
};

export default PaywallSection;
