import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PublicationSubscriberTag } from '@/interfaces/subscriber_tag';
import api from '@/services/swarm';

interface ApiResponse {
  subscriber_tag: PublicationSubscriberTag;
}

export default function useSubscriberTag(id: string) {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchSubscriberTag = () =>
    api
      .get(`/publication_subscriber_tags/${id}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<ApiResponse>(['subscriber_tag', id], fetchSubscriberTag, {
    keepPreviousData: true,
  });
}
