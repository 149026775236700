import { useQuery } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import api from '@/services/swarm';

const usePartnerDetails = (staleTime = 10000) => {
  const { currentUser } = useCurrentUser();

  const { data, isLoading, refetch } = useQuery<any>(
    ['partner_details', currentUser],
    () =>
      api
        .get(`/partner_details`, {
          params: {
            user_id: currentUser?.id,
          },
        })
        .then((res) => res.data),
    {
      staleTime,
    }
  );

  return { data, isLoading, refetch };
};

export default usePartnerDetails;
