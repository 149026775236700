import { RocketLaunchIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import Grid from '@/components/Grid';
import { Typography, TypographyRow } from '@/components/Typography';

interface UseLaunchOptionProps {
  onClick: () => void;
  selected: boolean;
}

const UseLaunchOption: React.FC<UseLaunchOptionProps> = ({ selected, onClick }) => {
  return (
    <button type="button" className="w-full" onClick={onClick}>
      <div className={cx(selected ? 'border-surface-500' : 'border-surface-200', 'rounded border')}>
        <TypographyRow className="bg-surface-50 rounded-t py-4 px-6 border-b border-surface-200 justify-between">
          <Typography token="font-semibold/text/base">Get started with Launch</Typography>
          <div className="p-4 bg-action-secondary-100 rounded">
            <RocketLaunchIcon className="text-action-secondary-500 w-6 h-6" />
          </div>
        </TypographyRow>
        <Grid gap={4} columns={2} rows={2} className="px-6 py-4">
          <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
            <span className="text-action-tertiary-600">&#10003;</span> Custom Newsletters
          </Typography>
          <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
            <span className="text-action-tertiary-600">&#10003;</span> Custom Website
          </Typography>
          <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
            <span className="text-action-tertiary-600">&#10003;</span> Campaign Analytics
          </Typography>
          <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
            <span className="text-action-tertiary-600">&#10003;</span> Start publishing in minutes
          </Typography>
        </Grid>
      </div>
    </button>
  );
};

export default UseLaunchOption;
