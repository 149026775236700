import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { ReferralProgram } from '@/interfaces/referral_program';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface UpdateParams {
  disabledAt?: Date | null;
  emailDescription?: string;
  blockLayout?: ReferralProgram['block_layout'];
}

const useUpdateReferralProgram = () => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (params: UpdateParams) => {
      let disabledAt;
      if (params.disabledAt === null) {
        disabledAt = null; // implies "enabled"
      } else if (params.disabledAt) {
        disabledAt = params.disabledAt?.toISOString(); // implies disabled
      }

      return api
        .patch('/referral_program/', {
          publication_id: currentPublicationId,
          disabled_at: disabledAt,
          email_description: params?.emailDescription,
          block_layout: params?.blockLayout,
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([currentPublicationId, 'referral_program']);
      },
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong!');
      },
    }
  );
};

export default useUpdateReferralProgram;
