import KPI from '@/components/KPI';
import KPIGroup from '@/components/KPIGroup';
import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import { useEngagement, useOverview } from '@/hooks/useDashboard';
import { EngagementEndpoints } from '@/hooks/useDashboard/useEngagement';
import { OverviewEndpoints } from '@/hooks/useDashboard/useOverview';
import { EngagementKPIs, ImpressionsKPIs } from '@/interfaces/dashboard';
import { TimePeriod } from '@/interfaces/time_period';

import getLocaleString from '../../../utils/getLocaleString';
import { PageProps } from '../types';
import getNormalizedTimePeriod from '../utils/getNormalizedTimePeriod';

const KPIs = () => {
  const { period } = usePageContext<PageProps>();
  const isAllTime = period === TimePeriod.ALL_TIME;

  const normalizeTimePeriod = getNormalizedTimePeriod(period);

  // Engagement KPIs
  const { isLoading: isLoadingEngagementKPI, data: engagementKPIData } = useOverview({
    endpoint: OverviewEndpoints.ENGAGEMENT_KPIS,
    timePeriod: period,
  });
  const engagementKPIs: EngagementKPIs | null = engagementKPIData?.engagement_kpis;

  // Impressions KPIs
  const { isLoading, data } = useEngagement({
    endpoint: EngagementEndpoints.IMPRESSIONS,
    timePeriod: period,
  });
  const impressionsKPIs: ImpressionsKPIs | null = data?.impressions;

  return (
    <KPIGroup>
      <KPI
        title="Open Rate"
        isLoading={isLoadingEngagementKPI}
        pctDelta={engagementKPIs ? engagementKPIs?.open_rate_delta : 0}
        value={`${engagementKPIs ? engagementKPIs?.open_rate : '0'}%`}
        isAllTime={isAllTime}
        previousValue={`${engagementKPIs ? engagementKPIs?.lag_open_rate : 0}% ${normalizeTimePeriod}`}
      />
      <KPI
        title="Click Through Rate"
        isLoading={isLoadingEngagementKPI}
        pctDelta={engagementKPIs ? engagementKPIs?.click_rate_delta : 0}
        value={`${engagementKPIs ? engagementKPIs?.click_rate : '0'}%`}
        isAllTime={isAllTime}
        previousValue={`${engagementKPIs ? engagementKPIs?.lag_click_rate : 0}% ${normalizeTimePeriod}`}
      />
      <KPI
        title="Impressions"
        isLoading={isLoading}
        pctDelta={impressionsKPIs ? impressionsKPIs?.impressions_delta : 0}
        value={getLocaleString(impressionsKPIs ? impressionsKPIs?.total_impressions : 0)}
        isAllTime={isAllTime}
        previousValue={`${getLocaleString(
          impressionsKPIs ? impressionsKPIs?.lag_total_impressions : 0
        )} ${normalizeTimePeriod}`}
      />
    </KPIGroup>
  );
};

export default KPIs;
