import { Editor, Extension } from '@tiptap/core';
import { isChangeOrigin } from '@tiptap/extension-collaboration';
import { Node } from '@tiptap/pm/model';
import UniqueID from '@tiptap-pro/extension-unique-id';
import kebabcase from 'lodash.kebabcase';

import { capitalize } from '@/utils';

import { DEFAULT_NODE_TITLES, FALLBACK_NODE_TITLE } from './constants';
import { AnchorItem, TableOfContentDataItem } from './types';
import { getAnchorLevel, getTextContentWithEmoji } from './utils';

export type AnchorOptions = {
  types: string[];
  onlyUniqueIdTypes: string[];
};

export * from './types';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    anchor: {
      enableAnchor: (node: Node) => ReturnType;
      disableAnchor: (node: Node) => ReturnType;
      toggleAnchor: (node: Node) => ReturnType;
    };
  }
}

const getAnchors = (options: { editor: Editor }) => {
  const { editor } = options;

  const anchors: AnchorItem[] = [];
  let sortedAnchors: TableOfContentDataItem[] = [];

  editor.state.doc.descendants((node, pos) => {
    if (!node.attrs.anchorEnabled) {
      return;
    }

    anchors.push({
      node,
      pos,
      index: 1,
    });
  });

  anchors.forEach((anchor, i) => {
    let level: number = 1;
    let index: number = 1;
    let originalLevel: number | undefined;

    const anchorIsHeading = anchor.node.type.name === 'heading';

    if (anchorIsHeading) {
      originalLevel = anchor.node.attrs.level;
    }

    const prevAnchor = anchors[i - 1];

    if (!prevAnchor) {
      sortedAnchors = [
        {
          node: anchor.node,
          pos: anchor.pos,
          originalLevel,
          level: 1,
          index,
          id: anchor.node.attrs.anchorId,
          title: anchor.node.attrs.anchorTitle,
          isCustom: !anchorIsHeading,
        },
      ];

      return;
    }

    const previousAnchors = sortedAnchors;

    level = getAnchorLevel(anchor, previousAnchors);

    const previousAnchorsOnLevelOrBelow = previousAnchors.filter((a) => a.level && a.level <= level);
    const previousAnchor = previousAnchorsOnLevelOrBelow.at(-1);

    // if there are two custom anchors following on each other
    // they will have the same level

    if (previousAnchor?.isCustom) {
      level = previousAnchor.level || 1;
    }

    if (previousAnchorsOnLevelOrBelow.at(-1)?.level === level) {
      index = (previousAnchorsOnLevelOrBelow.at(-1)?.index || 1) + 1;
    } else {
      index = 1;
    }

    sortedAnchors = [
      ...sortedAnchors,
      {
        node: anchor.node,
        pos: anchor.pos,
        originalLevel,
        level,
        index,
        id: anchor.node.attrs.anchorId,
        title: anchor.node.attrs.anchorTitle,
        isCustom: !anchorIsHeading,
      },
    ];
  });

  return sortedAnchors;
};

export const Anchor = Extension.create<AnchorOptions>({
  name: 'anchor',

  addStorage() {
    return {
      anchors: [],
    };
  },

  onUpdate() {
    const {
      editor,
      editor: {
        state: { doc },
      },
    } = this;

    doc.descendants((node, pos) => {
      const resolvedPos = doc.resolve(pos);
      const { depth } = resolvedPos;

      // Disable nested anchors
      if (depth > 0) {
        if (node.attrs.anchorEnabled) {
          editor
            .chain()
            .command(({ tr }) => {
              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                anchorEnabled: false,
              });

              tr.setMeta('addToHistory', false);

              return true;
            })
            .run();
        }

        return;
      }

      // Handle new headlines
      if (node.type.name === 'heading' && node.attrs.anchorEnabled === undefined) {
        editor
          .chain()
          .command(({ tr }) => {
            tr.setNodeMarkup(pos, undefined, {
              ...node.attrs,
              anchorEnabled: true,
              anchorTitleSync: true,
              anchorIdSync: true,
            });

            tr.setMeta('addToHistory', false);

            return true;
          })
          .run();
      }

      // Handle sync of anchor-enabled nodes
      const isEnabled = node.attrs.anchorEnabled;
      const syncTitle = node.attrs.anchorTitleSync !== false;
      const syncId = node.attrs.anchorIdSync !== false;

      let hasChanges = false;

      if (isEnabled) {
        let newTitleData = {};
        let newIdData = {};

        let newTitle = node.attrs.anchorTitle;
        let newId = node.attrs.anchorId;

        if (syncTitle) {
          if (DEFAULT_NODE_TITLES[node.type.name]) {
            newTitle = DEFAULT_NODE_TITLES[node.type.name];
          } else if (node.type.name === 'serviceEmbed') {
            newTitle = `${capitalize(node.attrs.service)} Embed`;
          } else if (node.textContent) {
            newTitle = getTextContentWithEmoji(node, editor.schema);
          } else if (node.type.name === 'heading') {
            newTitle = `Heading ${node.attrs.level}`;
          } else {
            newTitle = FALLBACK_NODE_TITLE;
          }

          newTitle = newTitle.length > 50 ? `${newTitle.substring(0, 50)} …` : newTitle;

          if (node.attrs.anchorTitle !== newTitle) {
            hasChanges = true;
            newTitleData = {
              anchorTitle: newTitle,
            };
          }
        }

        if (syncId) {
          newId = kebabcase(newTitle.replace(/[^\w\s]/g, '')).substring(0, 35);

          if (node.attrs.anchorId !== newId) {
            hasChanges = true;
            newIdData = {
              anchorId: newId,
            };
          }
        }

        if (hasChanges) {
          editor.commands.command(({ tr }) => {
            tr.setNodeMarkup(pos, undefined, {
              ...node.attrs,
              ...newTitleData,
              ...newIdData,
            });

            tr.setMeta('addToHistory', false);

            return true;
          });
        }
      }
    });

    this.storage.anchors = getAnchors({ editor: this.editor });
  },

  onCreate() {
    this.storage.anchors = getAnchors({ editor: this.editor });
  },

  addOptions() {
    return {
      types: [],
      onlyUniqueIdTypes: [],
    };
  },

  addExtensions() {
    const { types, onlyUniqueIdTypes } = this.options;

    return [
      UniqueID.configure({
        types: [...types, ...onlyUniqueIdTypes],
        filterTransaction: (transaction) => !isChangeOrigin(transaction),
      }),
    ];
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          anchorEnabled: {
            default: undefined,
            parseHTML: (element) => element.getAttribute('data-anchor'),
            renderHTML: (attributes) => {
              if (!attributes.anchorEnabled) {
                return {};
              }

              return {
                id: attributes.anchorId,
                'data-anchor': '',
              };
            },
            keepOnSplit: false,
          },
          anchorIncludeInToc: {
            default: undefined,
            parseHTML: (element) => element.getAttribute('data-anchor-include-in-toc'),
            renderHTML: (attributes) => {
              if (attributes.anchorIncludeInToc !== false) {
                return {};
              }

              return {
                'data-anchor-include-in-toc': false,
              };
            },
            keepOnSplit: false,
          },
          anchorTitle: {
            default: undefined,
            parseHTML: (element) => element.getAttribute('data-anchor-title'),
            renderHTML: (attributes) => ({
              'data-anchor-title': attributes.anchorTitle,
            }),
            keepOnSplit: false,
          },
          anchorId: {
            default: undefined,
            parseHTML: (element) => element.getAttribute('data-anchor-id'),
            renderHTML: (attributes) => ({
              'data-anchor-id': attributes.anchorId,
            }),
            keepOnSplit: false,
          },
          anchorTitleSync: {
            default: undefined,
            parseHTML: (element) => element.getAttribute('data-anchor-title-sync'),
            renderHTML: (attributes) => ({
              'data-anchor-title-sync': attributes.anchorTitleSync,
            }),
            keepOnSplit: false,
          },
          anchorIdSync: {
            default: undefined,
            parseHTML: (element) => element.getAttribute('data-anchor-id-sync'),
            renderHTML: (attributes) => ({
              'data-anchor-id-sync': attributes.anchorIdSync,
            }),
            keepOnSplit: false,
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      enableAnchor:
        (node) =>
        ({ chain }) => {
          const syncTitle = {
            anchorTitleSync: node.attrs.anchorTitleSync === undefined ? true : node.attrs.anchorTitleSync,
          };

          const syncId = {
            anchorIdSync: node.attrs.anchorIdSync === undefined ? true : node.attrs.anchorIdSync,
          };

          return chain()
            .updateAttributes(node.type.name, {
              ...node.attrs,
              anchorEnabled: true,
              ...syncTitle,
              ...syncId,
            })
            .run();
        },
      disableAnchor:
        (node) =>
        ({ chain }) => {
          const titleSync = node.attrs.anchorTitleSync !== false;
          const newAnchorTitle = titleSync ? undefined : node.attrs.anchorTitle;
          const newAnchorTitleSync = titleSync ? undefined : false;

          const idSync = node.attrs.anchorIdSync !== false;
          const newAnchorId = idSync ? undefined : node.attrs.anchorId;
          const newAnchorIdSync = idSync ? undefined : false;

          return chain()
            .updateAttributes(node.type.name, {
              anchorEnabled: false,
              anchorTitle: newAnchorTitle,
              anchorTitleSync: newAnchorTitleSync,
              anchorId: newAnchorId,
              anchorIdSync: newAnchorIdSync,
            })
            .run();
        },
      toggleAnchor:
        (node) =>
        ({ commands }) => {
          if (node.attrs.anchorEnabled === true) {
            return commands.disableAnchor(node);
          }

          return commands.enableAnchor(node);
        },
    };
  },
});
