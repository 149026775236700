import React, { FC } from 'react';

import FormSelect from '@/components/FormSelect';
import { useCurrentPublicationState } from '@/context/current-publication-context';

import PollSelect from '../../../components/PollSelect';
import { Condition, Filter } from '../../../interfaces/segment';

import { pollResponseBehaviorOption, surveyResponseBehaviorOption } from './nameOptions';
import { defaultOperatorByConditionName } from './operatorOptions';

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleFilterChange: (filters: Filter, blockIndex: number, subIndex?: number) => void;
}

const BehaviorTypeSelect: FC<Props> = ({ condition, handleFilterChange, index, subIndex }) => {
  const defaultOperator = defaultOperatorByConditionName(condition?.name);
  const [currentPublicationId] = useCurrentPublicationState();

  const handleChange = (resourceId?: string) => {
    const updatedFilters = {
      resource_id: resourceId,
      operator: condition?.filters?.operator || defaultOperator,
      value: '', // reset value of filter if poll changes
    };

    const newFilters = { ...condition.filters, ...updatedFilters };

    handleFilterChange(newFilters, index, subIndex);
  };

  if (condition?.name === pollResponseBehaviorOption.value) {
    return (
      <PollSelect
        className="w-full"
        labelText=""
        pollId={condition.filters?.resource_id || ''}
        onSelectPoll={(pollId: string) => handleChange(pollId)}
        onClearPoll={() => handleChange(undefined)}
        publicationId={currentPublicationId}
        allowAnyPoll
      />
    );
  }

  if (condition?.name === surveyResponseBehaviorOption.value) {
    return (
      <FormSelect
        className="w-full"
        labelText=""
        formId={condition.filters?.resource_id || ''}
        onSelectForm={(formId: string) => handleChange(formId)}
        onClearForm={() => handleChange(undefined)}
        publicationId={currentPublicationId}
      />
    );
  }

  return null;
};

export default BehaviorTypeSelect;
