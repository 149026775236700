import { Fragment, ReactNode } from 'react';

import { useSettings } from '@/context/settings-context';
import usePostFactPostClickMetrics from '@/hooks/usePostFactPostClickMetrics';
import { SubscriberPostClick } from '@/interfaces/subscriber_post_click';

interface Props {
  postId: string;
  factPostClickId: string;
  children: (
    clicks: SubscriberPostClick[],
    totalClicks: number | undefined,
    isLoading: boolean,
    isError: boolean,
    fetchNextPage: () => void,
    hasNextPage: boolean | undefined,
    isFetchingNextPage: boolean
  ) => ReactNode;
}

const PostFactPostClicksMetricsContainer = ({ postId, factPostClickId, children }: Props) => {
  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;

  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } = usePostFactPostClickMetrics({
    postId,
    factPostClickId,
    useApiary: canUseApiary,
  });
  const totalRecords = data?.pages[0]?.pagination?.total;
  const subscriberPostClicks = data?.pages?.flatMap((page) => page.subscriber_post_clicks) || [];

  return (
    <>
      {children(subscriberPostClicks, totalRecords, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage)}
    </>
  );
};

export default PostFactPostClicksMetricsContainer;
