import { Typography, TypographyStack } from '@/components/Typography';
import { Card } from '@/ui/Card';

import PageContainer from './PageContainer';

const NotAuthorized = () => (
  <PageContainer>
    <Card>
      <TypographyStack>
        <Typography token="font-medium/text/base">Not Authorized </Typography>
        <Typography token="font-normal/text/sm" colorWeight="500">
          Your current role cannot access this page. Contact an admin to change your permissions.
        </Typography>
      </TypographyStack>
    </Card>
  </PageContainer>
);

export default NotAuthorized;
