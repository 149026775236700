import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

import { Button } from '@/ui/Button';

import ActionModal from '../../../../components/ActionModal';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
// Contexts
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { Pagination } from '../../../../interfaces/general';
// Interfaces
import { Milestone } from '../../../../interfaces/milestone';
// Services
import api from '../../../../services/swarm';
// Components
import ConfigureContainer from '../ConfigureContainer';

import Table from './Table';

export default function ConfigureMilestonesIndex() {
  const [page, setPage] = useQueryParam<number>('page', withDefault(NumberParam, 1));
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteMilestoneId, setDeleteMilestoneId] = useState('');
  const [milestones, setMilestones] = useState<Milestone[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const navigate = useNavigate();
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchMilestones = (pageNum: number) => {
    const params = {
      publication_id: currentPublicationId,
      page: Number.isNaN(pageNum) ? 1 : pageNum,
    };

    setIsLoading(true);
    api
      .get('/referral_program/milestones', { params })
      .then((res) => {
        setMilestones(res.data?.milestones || []);
        setPagination(res.data?.pagination || {});
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (currentPublicationId) {
      fetchMilestones(page);
    }
  }, [currentPublicationId, page]);
  /* eslint-enable */

  const onAddMilestone = () => {
    navigate('/referral_program/configure/milestones/new');
  };

  const onEditMilestone = (milestoneId: string) => {
    navigate(`/referral_program/configure/milestones/${milestoneId}/edit`);
  };

  const onDeleteMilestoneRequested = (milestoneId: string) => {
    setDeleteMilestoneId(milestoneId);
  };

  const onActionModalClose = () => {
    setDeleteMilestoneId('');
  };

  const onDeleteMilestone = (milestoneId: string) => {
    const params = {
      publication_id: currentPublicationId,
    };

    setIsDeleting(true);
    api
      .delete(`/referral_program/milestones/${milestoneId}`, { params })
      .then(() => {
        setDeleteMilestoneId('');
        fetchMilestones(1);
        toast.success('Milestone deleted successfully');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const onPageSelected = (targetPage: number) => {
    setPage(targetPage);
  };

  return (
    <ConfigureContainer selectedTab="milestones">
      <div>
        <ActionModal
          isOpen={deleteMilestoneId !== ''}
          onClose={onActionModalClose}
          onProceed={onDeleteMilestone}
          resourceId={deleteMilestoneId}
          isWorking={isDeleting}
          headerText="Delete milestone"
          actionText="Delete"
        >
          Are you sure you want to delete this milestone?
        </ActionModal>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 inline-flex">
                  Milestones {isLoading && <LoadingSpinner className="ml-2" />}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  All of your milestones that are achievable by your subscribers when they reach specific levels of
                  referrals
                </p>
              </div>
              <div>
                <Button block={false} onClick={() => onAddMilestone()}>
                  Add Milestone
                </Button>
              </div>
            </div>
            <div>
              <Table
                milestones={milestones}
                pagination={pagination}
                onEditSelected={onEditMilestone}
                onPageSelected={onPageSelected}
                onDeleteSelected={onDeleteMilestoneRequested}
              />
            </div>
          </div>
        </div>
      </div>
    </ConfigureContainer>
  );
}
