import { HorizontalMetricsChart } from '@/components/charts';
import LoadingBox from '@/components/LoadingBox';
import { usePostPoll } from '@/hooks';

import FeedbackLoader from './FeedbackLoader';
import Nav from './Nav';

const PostPoll = ({ publicationId, postPollId }: { publicationId: string; postPollId: string }) => {
  const { data: postPoll, isLoading, isError } = usePostPoll(publicationId, { id: postPollId });

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      {postPoll && (
        <div className="grid grid-cols-3 gap-4 grid-rows-1 p-4 bg-white rounded">
          <div className="col-span-3">
            <Nav publicationId={publicationId} postPoll={postPoll} />
          </div>
          <div className="col-span-3 lg:col-span-2">
            <HorizontalMetricsChart title={postPoll.poll_question} metrics={postPoll.metrics} />
          </div>
          <div className="col-span-3 lg:col-span-1">
            <FeedbackLoader publicationId={publicationId} postPoll={postPoll} />
          </div>
        </div>
      )}
    </LoadingBox>
  );
};

export default PostPoll;
