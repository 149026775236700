import { TrashIcon } from '@heroicons/react/20/solid';

import { GroupButton } from '@/components/ConditionsEditor';
import { colorByLogicalOperator } from '@/components/ConditionsEditor/constants';
import ButtonWithOptions from '@/components/Form/ButtonWithOptions';
import { TreeFooter } from '@/components/Tree';
import { LogicalOperator, LogicalOperators } from '@/interfaces/condition';
import { Option } from '@/interfaces/general';

import { NameTypes } from './types';

interface Props {
  selectedLogicalOperator: LogicalOperator;
  onAddConditionType: (value: NameTypes, blockIndex?: number) => void;
  onSelectConditionName: (conditionOption: any, blockIndex?: number) => void;
  onAddGroup?: (value: LogicalOperator) => void;
  conditionSelectOptions: Option[];
  conditionGroupSelectOptions?: Option[];
  showDelete: boolean;
  index?: number;
  onDelete?: (blockIndex: number, subIndex?: number) => void;
}

const GroupFooter = ({
  onAddConditionType,
  onSelectConditionName,
  onAddGroup,
  conditionSelectOptions,
  conditionGroupSelectOptions,
  index,
  selectedLogicalOperator,
  showDelete,
  onDelete,
}: Props) => {
  const hasIndex = index !== undefined;

  const addConditionPlaceholderText = hasIndex ? 'Inner condition' : 'Condition';

  const handleAddTypeOrCondition = (name: string, optionData: any) => {
    if (optionData?.dataType) {
      onSelectConditionName(optionData, index);
    } else {
      onAddConditionType(optionData.value as NameTypes, index);
    }
  };

  return (
    <TreeFooter borderClassName={colorByLogicalOperator[selectedLogicalOperator].treeFooter}>
      <div className="flex items-center">
        <ButtonWithOptions
          name="add_condition"
          key="add-next-condition"
          options={conditionSelectOptions}
          onSelect={handleAddTypeOrCondition}
          placeholderText={addConditionPlaceholderText}
        />
        {!hasIndex && conditionGroupSelectOptions && onAddGroup && (
          <ButtonWithOptions
            name="add_condition_group"
            key="add-next-group"
            className="ml-1"
            onSelect={(name: string, value: any) => onAddGroup(value.value as LogicalOperators)}
            options={conditionGroupSelectOptions}
            placeholderText="Condition group"
          />
        )}
        {showDelete && onDelete && hasIndex ? (
          <GroupButton onClick={() => onDelete(index)}>
            <TrashIcon width="15" height="15" className="mr-1" />
            Delete group
          </GroupButton>
        ) : null}
      </div>
    </TreeFooter>
  );
};

export default GroupFooter;
