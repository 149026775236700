import { useInfiniteQuery } from 'react-query';

import { AdNetworkCampaignOpportunityGroup } from '@/interfaces/ad_network/internal/campaign_opportunity_group';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  campaign_opportunity_groups: AdNetworkCampaignOpportunityGroup[];
  pagination: Pagination;
}

interface Variables {
  campaignId: string;
}

export default function useCampaignOpportunityGroups({ campaignId }: Variables) {
  const fetchGroups = ({ pageParam = 1 }) =>
    api
      .get(`/ad_network/internal/campaigns/${campaignId}/campaign_opportunity_groups`, {
        params: {
          page: pageParam,
          per_page: 25,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_network', 'internal', 'campaign', campaignId, 'campaign_opportunity_groups'],
    fetchGroups,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
