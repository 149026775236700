import { useState } from 'react';
import { UseMutationResult } from 'react-query';

import { Input } from '@/components/Form';
import {
  AdNetworkAdvertisement,
  AdNetworkAdvertisementKind,
  AdNetworkAdvertisementSponsorText,
} from '@/interfaces/ad_network/internal/advertisement';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import { Editor } from '../Editor';

import getLinkHrefs from './utils/getLinkHrefs';

interface Props {
  advertisement?: AdNetworkAdvertisement;
  onSubmitMutation: UseMutationResult<any, any, any, any>;
}

const Form = ({ advertisement, onSubmitMutation }: Props) => {
  const { mutateAsync, isLoading } = onSubmitMutation;
  const [name, setName] = useState(advertisement?.name || '');
  const [description, setDescription] = useState(advertisement?.description || '');
  const [url, setUrl] = useState(advertisement?.url || '');
  const [sponsorText, setSponsorText] = useState(
    advertisement?.sponsor_text || AdNetworkAdvertisementSponsorText.SPONSORED_BY
  );
  const [tiptapState, setTiptapState] = useState(advertisement?.tiptap_state);
  const [kind, setKind] = useState(advertisement?.kind || AdNetworkAdvertisementKind.PRIMARY);

  const warningUrls = getLinkHrefs(tiptapState).filter((href) => href !== url);
  const linkMismatch = warningUrls.length > 0 && `Warning: Found mismatched URLs (${warningUrls.join(', ')})`;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    formData.set('advertisement[name]', name);
    formData.set('advertisement[description]', description);
    formData.set('advertisement[url]', url);
    formData.set('advertisement[sponsor_text]', sponsorText);
    formData.set('advertisement[tiptap_state]', JSON.stringify(tiptapState));
    formData.set('advertisement[kind]', kind);

    mutateAsync(formData);
  };

  return (
    <div className="h-full bg-gray-50">
      {warningUrls.length > 0 && <p className="bg-yellow-600 text-white p-2 text-center text-sm">{linkMismatch}</p>}
      <div className="grid grid-cols-1 md:grid-cols-3 h-full">
        <div className="col-span-2 p-4 bg-white">
          <Editor onChange={(val) => setTiptapState(val)} initialValue={tiptapState} />
        </div>
        <form className="col-span-1 space-y-6 p-4" onSubmit={onSubmit}>
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
            <p className="text-sm text-gray-500">
              Specify the general details for this advertisement. Use the editor on the right to customize the content.
            </p>
          </div>

          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="advertisement[name]"
            labelText="Name"
            helperText="A unique identifier for this advertisement (public)."
            required
          />
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name="advertisement[description]"
            labelText="Description"
            helperText="A brief description of this advertisement (public)."
          />
          <Dropdown
            name="advertisement[kind]"
            value={kind}
            onSelect={(_, val) => setKind(val as AdNetworkAdvertisementKind)}
            options={[
              { value: AdNetworkAdvertisementKind.PRIMARY, label: 'Primary' },
              { value: AdNetworkAdvertisementKind.SECONDARY, label: 'Secondary' },
            ]}
            labelText="Kind"
            helperText="The type of advertisement. Secondary ads do not include the logo at the top."
            required
          />
          <Dropdown
            name="advertisement[sponsor_text]"
            value={sponsorText}
            onSelect={(_, val) => setSponsorText(val as AdNetworkAdvertisementSponsorText)}
            options={[
              { value: AdNetworkAdvertisementSponsorText.PRESENTED_BY, label: 'Presented by' },
              { value: AdNetworkAdvertisementSponsorText.SPONSORED_BY, label: 'Sponsored by' },
              { value: AdNetworkAdvertisementSponsorText.TOGETHER_WITH, label: 'Together with' },
              { value: AdNetworkAdvertisementSponsorText.IN_PARTNERSHIP_WITH, label: 'In partnership with' },
              { value: AdNetworkAdvertisementSponsorText.TODAYS_SPONSOR, label: "Today's sponsor" },
            ]}
            labelText="Sponsor Text"
            helperText="The text that will be displayed next to the logo."
            required
          />
          <Input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            name="advertisement[url]"
            labelText="URL"
            helperText="The primary URL that this advertisement will link to. The logo at the top will use this URL. Note that any other URLs within the ad will not be tracked unless they match this exactly."
            type="url"
            required
            errorText={linkMismatch || undefined}
          />

          <div>
            <Button type="submit" loading={isLoading}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
