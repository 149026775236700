import { useParams } from 'react-router-dom';
import moment from 'moment-mini';

import { useCampaign } from '@/hooks/useAdNetwork/internal';
import useCampaignPaymentRequests from '@/hooks/useAdNetwork/internal/useCampaignPaymentRequests';
import { AdNetworkCampaign } from '@/interfaces/ad_network/internal/campaign';
import { AdNetworkPaymentRequest } from '@/interfaces/ad_network/internal/payment_request';
import { Pagination } from '@/interfaces/general';
import PaymentRequestStatusIndicator from '@/routes/ad_network/_components/StatusIndicator/PaymentRequestStatusIndicator';
import { Button } from '@/ui/Button';

import { LoadingListPage } from '../../../_components/LoadingListPage';
import { PageHeading } from '../../../_components/PageHeading';

import PaymentRequestAction from './_components/PaymentRequestAction';

interface Props {
  campaign: AdNetworkCampaign;
  paymentRequests: AdNetworkPaymentRequest[];
  hasNextPage: boolean;
  fetchNextPage: () => Promise<any>;
  isFetching: boolean;
  pagination: Pagination;
}

const CampaignPaymentRequests = ({
  campaign,
  paymentRequests,
  hasNextPage,
  fetchNextPage,
  isFetching,
  pagination,
}: Props) => {
  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{campaign.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Payment Requests</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button to="new" variant="primary-inverse" size="xs">
            New payment request
          </Button>
        </PageHeading.Side>
      </PageHeading>
      <table className="w-full text-sm divide-y divide-gray-100">
        <thead className="bg-gray-50">
          <tr>
            <th className="!font-normal px-4 py-2" align="left">
              Amount
            </th>
            <th className="!font-normal px-4 py-2" align="left">
              Send date
            </th>
            <th className="!font-normal px-4 py-2" align="left">
              Status
            </th>
            <th className="!font-normal px-4 py-2" align="left">
              Created
            </th>
            <th className="!font-normal px-4 py-2" align="left">
              Updated
            </th>
            <th className="!font-normal px-4 py-2" align="left" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100">
          {paymentRequests.map((paymentRequest) => {
            return (
              <tr key={paymentRequest.id}>
                <td className="px-4 py-2">
                  <p>{paymentRequest.amount}</p>
                </td>
                <td className="px-4 py-2">
                  <p>{moment.utc(paymentRequest.send_at).format('LL')}</p>
                </td>
                <td className="px-4 py-2">
                  <p>
                    <PaymentRequestStatusIndicator paymentRequest={paymentRequest} />
                  </p>
                </td>
                <td className="px-4 py-2">
                  <p>{moment(paymentRequest.created_at).fromNow()}</p>
                </td>
                <td className="px-4 py-2">
                  <p>{moment(paymentRequest.updated_at).fromNow()}</p>
                </td>
                <td className="px-4 py-2">
                  <div className="flex flex-row justify-end items-center mr-0 lg:mr-4">
                    <PaymentRequestAction paymentRequest={paymentRequest} campaign={campaign} />
                  </div>
                </td>
              </tr>
            );
          })}

          {hasNextPage && (
            <tr>
              <td colSpan={5} className="px-4 py-2">
                <Button onClick={fetchNextPage} loading={isFetching} variant="primary-inverse" size="xs">
                  Load more
                </Button>
              </td>
            </tr>
          )}

          <tr className="sticky bottom-0 bg-white">
            <td colSpan={5} className="px-4 py-2">
              <p>
                Showing {paymentRequests.length} of {pagination.total} payment requests
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default function Loader() {
  const { campaign_id: id } = useParams<'campaign_id'>() as { campaign_id: string };

  const campaignQuery = useCampaign({ id });
  const paymentRequestsQuery = useCampaignPaymentRequests({ campaignId: id });
  const isSuccess = campaignQuery.isSuccess && paymentRequestsQuery.isSuccess;
  const isError = campaignQuery.isError || paymentRequestsQuery.isError;
  const isLoading = !isError && (campaignQuery.isLoading || paymentRequestsQuery.isLoading);

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  const { data: campaign } = campaignQuery;
  const paymentRequests = paymentRequestsQuery.data.pages.flatMap((page) => page.payment_requests) ?? [];
  const pagination = paymentRequestsQuery.data?.pages[0].pagination;
  const sortedPaymentRequests = paymentRequests.sort((a, b) => moment(b.send_at).diff(moment(a.send_at)));
  const { hasNextPage, fetchNextPage, isFetching } = paymentRequestsQuery;

  return (
    <CampaignPaymentRequests
      campaign={campaign}
      paymentRequests={sortedPaymentRequests}
      hasNextPage={!!hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      pagination={pagination}
    />
  );
}
