/**
 * @see `app/views/api/v2/boosts/monetize/_boost_send.json.jbuilder`
 */

import { TopLineMetrics } from '../top_line_metrics';

import { BasicBoostAgreement } from './basic_boost_agreement';
import { BoostPublication } from './boost_publication';

export enum BoostSendStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
  ARCHIVED = 'archived',
}

// Defines possible monetize user actions on a BoostSend for each status.
export const BoostSendStatusTransitions: any = {
  [BoostSendStatus.PENDING]: [BoostSendStatus.CANCELED],
  [BoostSendStatus.REJECTED]: [],
  [BoostSendStatus.ACCEPTED]: [BoostSendStatus.CANCELED],
  [BoostSendStatus.CANCELED]: [],
};

// Defines the labels for each user action on a BoostSend for each status.
export const BoostSendTransitionLabels: any = {
  [BoostSendStatus.CANCELED]: 'Cancel',
};

export const BoostSendTransitionVerboseLabels: any = {
  [BoostSendStatus.CANCELED]: 'Cancel Email Boost',
};

export interface BoostSend {
  id: string;
  status: BoostSendStatus;
  max_payout: string;
  top_line_metrics: TopLineMetrics;
  boost_agreement: BasicBoostAgreement;
  boosted_publication: BoostPublication;
  send_by: Date | string;
  created_at: string;
  accepted_at: Date | string | null;
  rejected_at: Date | string | null;
}
