import { useRef } from 'react';

import { cn } from '@/utils/cn';

import IframeWrapper from '../../page/_components/Iframe';
import { useContainerRef } from '../../page/_hooks';
import { VIEWPORTS } from '../../page/constants';

interface Props {
  containerHeightClass?: string;
  children: React.ReactNode;
  fontHrefs: Set<string>;
}

const FontsPreviewer = ({ containerHeightClass = 'h-[80px]', children, fontHrefs }: Props) => {
  const { rect: previewRect, containerRef: previewContainerRef } = useContainerRef({
    enabled: true,
  });

  const iframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <div className={cn('w-full overflow-hidden pointer-events-none', containerHeightClass)}>
      <div
        className={cn(
          VIEWPORTS[1].type === 'desktop' ? 'w-full' : 'max-w-md',
          'h-full m-auto flex flex-col border-surface-200 overflow-hidden justify-center'
        )}
        ref={previewContainerRef}
      >
        <IframeWrapper
          width={previewRect.width || 0}
          height={previewRect.height || 0}
          contentWidth={150}
          fontHrefs={fontHrefs}
          ref={iframeRef}
        >
          {children}
        </IframeWrapper>
      </div>
    </div>
  );
};

export default FontsPreviewer;
