import { useCallback, useEffect, useMemo, useState } from 'react';
import { autoPlacement, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { CaretRight, TextT, X } from '@phosphor-icons/react';

import { Search } from '../../../_components/UI/Search';
import { Text } from '../../../_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../_components/UI/ToggleGroup';
import { getFontFamilies } from '../../../_utils/getFonts';

const DEFAULT_FONT_FAMILY = 'Inter';

interface Props {
  title: string;
  onSetFontFamily: (value: string) => void;
  value?: string;
}

export const FontSelection = ({ title, onSetFontFamily, value }: Props) => {
  const [fonts, setFonts] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const [group, setGroup] = useState<'all' | 'in-page'>('all');

  useEffect(() => {
    getFontFamilies().then((w) => setFonts(w));
  }, []);

  const filteredFonts = useMemo(() => {
    if (!query) return fonts;
    return fonts.filter((font) => font.toLowerCase().includes(query.toLowerCase()));
  }, [fonts, query]);

  const currentFont = value || DEFAULT_FONT_FAMILY;

  const handleSetFontFamily = useCallback(
    (val: string) => {
      onSetFontFamily(val);
    },
    [onSetFontFamily]
  );

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left-start',
    middleware: [autoPlacement(), offset(20)],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <div ref={refs.setReference} className="flex items-center justify-between gap-2">
        <Text className="w-full max-w-[150px]" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <div
          className="grow bg-wb-secondary rounded-lg shadow-sm  max-w-[150px] cursor-pointer"
          {...getReferenceProps()}
        >
          <div className="w-full justify-between flex items-center gap-2 p-2">
            <div className="flex items-center gap-1">
              <TextT className="text-wb-secondary" weight="bold" />
              <Text size="2xs" weight="medium">
                {currentFont || DEFAULT_FONT_FAMILY}
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          className="bg-wb-primary w-[255px] max-h-[400px] min-h-0 p-3 rounded-lg shadow-xl flex flex-col gap-2 z-20"
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Fonts
            </Text>

            <div
              className="p-1 text-wb-secondary bg-wb-secondary rounded-full"
              onClick={() => setIsOpen(false)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsOpen(false);
                }
              }}
            >
              <X className="cursor-pointer" />
            </div>
          </div>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm mb-3">
            <ToggleGroup
              className="p-[2px] grid grid-cols-2"
              type="single"
              defaultValue="left"
              value={group}
              onValueChange={(s) => setGroup(s as 'all' | 'in-page')}
            >
              <ToggleGroupItem value="all">All</ToggleGroupItem>
            </ToggleGroup>
          </div>

          <Search placeholder="Search font..." value={query} onChange={(e) => setQuery(e.target.value)} />

          <div className="grow min-h-0 overflow-y-scroll flex flex-col gap-2">
            {filteredFonts.map((font) => (
              <div
                key={font}
                className={`flex items-center px-3 py-2 rounded-lg cursor-pointer border hover:bg-wb-secondary ${
                  font === currentFont
                    ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                    : 'bg-wb-primary border-transparent'
                }`}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSetFontFamily(font);
                  }
                }}
                onClick={() => {
                  handleSetFontFamily(font);
                }}
              >
                {font}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
