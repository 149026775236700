import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import ColorField from '@/components/Form/ColorField';
import { useUpdateHomePage } from '@/hooks/useHomePages';
import { HomePage } from '@/interfaces/home_page';
import Switch from '@/ui/Switch';

import ThemeEditorDropdown from '../../../ThemeEditor/components/ThemeEditorDropdown';
import ThemeFormDrawer from '../../../ThemeEditor/components/ThemeFormDrawer';
import { FormContainer, FormInput, FormSection } from '../../../ThemeEditor/helpers';
import { usePageContext } from '../../components/PageContext';
import { RADIUS_OPTIONS, SHADOW_OPTIONS } from '../../utils/designOptions';

const Cards = () => {
  const queryClient = useQueryClient();
  const { pageData, colorPalette, setIsSaving, handleSaved, currentPublication, fontOptions } =
    usePageContext<HomePage>();

  const [card, setCard] = useState<any>(pageData?.posts_section?.card || {});

  useEffect(() => {
    setCard(pageData?.posts_section?.card || {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  const updatePostPage = useUpdateHomePage({
    pageId: pageData.id,
    onSuccess: () => {
      queryClient.invalidateQueries(['home_page', currentPublication?.id, 'draft']);
      handleSaved();
    },
  });

  const handleSavePostsSection = (payload: any) => {
    setIsSaving(true);
    updatePostPage.mutate({ home_page: { posts_section: JSON.stringify(payload) } });
  };

  return (
    <ThemeFormDrawer active={false} title="Cards" onClickHandler={() => {}}>
      <FormContainer>
        <FormSection
          title="Card Themes"
          protip="Updates in this section will override what was set in the Theme section. These changes will not impact posts in the News & Headliner headers."
        >
          <FormInput title="Background color">
            <ColorField
              placement="bottom"
              color={card?.bg_color || '#fff'}
              colorPalette={colorPalette}
              onColorChange={(color: any) =>
                setCard({
                  ...card,
                  bg_color: color?.hexString,
                })
              }
              onBlur={() => {
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    bg_color: card?.bg_color,
                  },
                });
              }}
            />
          </FormInput>
          <FormInput title="Border color">
            <ColorField
              placement="bottom"
              color={card?.border_color || '#fff'}
              colorPalette={colorPalette}
              onColorChange={(color: any) =>
                setCard({
                  ...card,
                  border_color: color?.hexString,
                })
              }
              onBlur={() => {
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    border_color: card?.border_color,
                  },
                });
              }}
            />
          </FormInput>
          <FormInput title="Text color">
            <ColorField
              placement="bottom"
              color={card?.text_color || '#fff'}
              colorPalette={colorPalette}
              onColorChange={(color: any) =>
                setCard({
                  ...card,
                  text_color: color?.hexString,
                })
              }
              onBlur={() => {
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    text_color: card?.text_color,
                  },
                });
              }}
            />
          </FormInput>
          {currentPublication?.is_premium_enabled && (
            <FormInput title="Premium text color">
              <ColorField
                placement="bottom"
                color={card?.premium_text_color || '#fff'}
                colorPalette={colorPalette}
                onColorChange={(color: any) =>
                  setCard({
                    ...card,
                    premium_text_color: color?.hexString,
                  })
                }
                onBlur={() => {
                  handleSavePostsSection({
                    ...pageData?.posts_section,
                    card: {
                      ...card,
                      premium_text_color: card?.premium_text_color,
                    },
                  });
                }}
              />
            </FormInput>
          )}
          <FormInput title="Timestamps & authors text color">
            <ColorField
              placement="bottom"
              color={card?.timestamp_text_color || '#fff'}
              colorPalette={colorPalette}
              onColorChange={(color: any) =>
                setCard({
                  ...card,
                  timestamp_text_color: color?.hexString,
                })
              }
              onBlur={() => {
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    timestamp_text_color: card?.timestamp_text_color,
                  },
                });
              }}
            />
          </FormInput>
          <FormInput title="Enable content tags">
            <Switch
              variant="primary"
              name="enable_content_tags"
              checked={card.tags_enabled}
              onChange={(_name: string, updatedValue: boolean) => {
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    tags_enabled: updatedValue,
                  },
                });
              }}
            />
          </FormInput>
          {card?.tags_enabled && (
            <>
              <FormInput title="Content tags background color">
                <ColorField
                  placement="bottom"
                  color={card?.tags_bg_color || '#fff'}
                  colorPalette={colorPalette}
                  onColorChange={(color: any) =>
                    setCard({
                      ...card,
                      tags_bg_color: color?.hexString,
                    })
                  }
                  onBlur={() => {
                    handleSavePostsSection({
                      ...pageData?.posts_section,
                      card: {
                        ...card,
                        tags_bg_color: card?.tags_bg_color,
                      },
                    });
                  }}
                />
              </FormInput>
              <FormInput title="Content tags text color">
                <ColorField
                  placement="bottom"
                  color={card?.tags_text_color || '#fff'}
                  colorPalette={colorPalette}
                  onColorChange={(color: any) =>
                    setCard({
                      ...card,
                      tags_text_color: color?.hexString,
                    })
                  }
                  onBlur={() => {
                    handleSavePostsSection({
                      ...pageData?.posts_section,
                      card: {
                        ...card,
                        tags_text_color: card?.tags_text_color,
                      },
                    });
                  }}
                />
              </FormInput>
            </>
          )}
          <FormInput title="Shadow size">
            <ThemeEditorDropdown
              currentValue={card?.shadow}
              onUpdate={(val: string) =>
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    shadow: val,
                  },
                })
              }
              options={SHADOW_OPTIONS}
            />
          </FormInput>
          <FormInput title="Hover shadow size">
            <ThemeEditorDropdown
              currentValue={card?.hover_shadow}
              onUpdate={(val: string) =>
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    hover_shadow: val,
                  },
                })
              }
              options={SHADOW_OPTIONS}
            />
          </FormInput>
          <FormInput title="Hover underline">
            <ThemeEditorDropdown
              currentValue={card?.hover_underline?.toString()}
              onUpdate={(val: string) =>
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    hover_underline: val,
                  },
                })
              }
              options={[
                {
                  label: 'None',
                  value: 'none',
                },
                { label: 'Underline', value: 'underline' },
              ]}
            />
          </FormInput>
          <FormInput title="Border radius">
            <ThemeEditorDropdown
              currentValue={card?.border_radius}
              onUpdate={(val: string) =>
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    border_radius: val,
                  },
                })
              }
              options={RADIUS_OPTIONS}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={card?.font}
              onUpdate={(val: string) =>
                handleSavePostsSection({
                  ...pageData?.posts_section,
                  card: {
                    ...card,
                    font: val,
                  },
                })
              }
              options={fontOptions}
            />
          </FormInput>
        </FormSection>
      </FormContainer>
    </ThemeFormDrawer>
  );
};

export default Cards;
