import { TimePeriod } from '@/interfaces/time_period';

export interface PageProps {
  isTrialActive: boolean;
  period: TimePeriod;
  isSubscriptionDataLoading: any;
  subscriptionKPIs: any;
  subscriptionEventsChart: any;
  activeSubscriptionsChart: any;
}

export enum TabTypes {
  OVERVIEW = 'overview',
  AUDIENCE = 'audience',
  ENGAGEMENT = 'engagement',
  MONETIZATION = 'monetization',
}
