import { useNavigate } from 'react-router-dom';

import { useDisableRefetchOnFocus, useWelcomeEmail } from '@/hooks';
import { Publication } from '@/interfaces/publication';
import useUpdateWelcomeEmail from '@/routes/welcome_email/_hooks/useUpdateWelcomeEmail';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import BodyContainer from '../../../../_components/BodyContainer';
import EditEmailForm from '../../components/EditEmailForm';

interface Props {
  publication: Publication;
}

const EditForm = ({ publication }: Props) => {
  useDisableRefetchOnFocus();

  const navigate = useNavigate();
  const { data: welcomeEmail } = useWelcomeEmail(publication.id);

  const { mutate: updateWelcomeEmail, isLoading } = useUpdateWelcomeEmail(publication.id, welcomeEmail?.id || '');

  return (
    <BodyContainer>
      <EditEmailForm
        currentPublicationId={publication.id}
        emailMessage={welcomeEmail}
        isLoading={isLoading}
        onUpdate={updateWelcomeEmail}
        onCancel={() => {
          navigate(appendSettingsPublicationId('/settings/publication/emails/welcome_email', publication.id));
        }}
      />
    </BodyContainer>
  );
};

export default EditForm;
