import { PlusIcon } from '@heroicons/react/20/solid';

import { Option } from '@/interfaces/general';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  name: string;
  className?: string;
  optionsWidth?: string;
  options: Option[];
  onSelect: (name: string, value: any) => void;
  placeholderText: string;
  disabled?: boolean;
}

const ButtonWithOptions = ({
  onSelect,
  options,
  placeholderText,
  className,
  optionsWidth,
  name,
  disabled = false,
}: Props) => {
  const buttonTextClassName = 'text-gray-400 font-semibold text-sm';
  const buttonPaddingClassName = 'py-1 px-2';
  const buttonClassNames = 'flex items-center rounded-md';
  const buttonHoverClassNames = 'hover:bg-gray-100 hover:text-gray-800';

  const selectButtonClassNames = {
    text: `text-left ${buttonTextClassName}`,
    padding: buttonPaddingClassName,
    cursor: 'cursor-pointer',
    border: 'border-none',
    shadow: 'shadow-none',
    hover: buttonHoverClassNames,
  };
  const selectOptionsContainerClassNames = {
    position: 'origin-top-left left-0',
    width: optionsWidth || 'w-52',
  };

  return (
    <Dropdown
      className={className}
      key="add-next-condition"
      name={name}
      buttonClassNames={selectButtonClassNames}
      optionsContainerClassNames={selectOptionsContainerClassNames}
      placeholderText={
        <span className={buttonClassNames}>
          <PlusIcon width="16" height="16" aria-hidden="true" className="mr-1" /> {placeholderText}
        </span>
      }
      onSelect={onSelect}
      options={options}
      suffixElement={null}
      shouldBindOptionAsValue
      value=""
      disabled={disabled}
    />
  );
};

export default ButtonWithOptions;
