import { Link, LinkProps } from 'react-router-dom';
import { ArrowLeft, CaretDown, ClockCounterClockwise, GearSix } from '@phosphor-icons/react';

import { SvgLogo } from '@/components/SvgLogo';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/routes/website/_components/UI/DropdownMenu';
import { Text } from '@/routes/website/_components/UI/Text';

import { Button } from '../UI/Button';

const DarkLogoIcon = () => <SvgLogo className="h-4" stopColorOverride="var(--color-text-primary)" />;

const MenuItem = ({ Icon, text, to }: { Icon: any; text: string; to: LinkProps['to'] }) => (
  <DropdownMenuItem asChild className="focus:bg-wb-secondary rounded-lg focus:outline-none">
    <Link to={to} className="flex items-center">
      <Button
        variant="ghost"
        className="h-full flex-1 justify-start gap-3"
        LeftIcon={Icon}
        iconWeight="regular"
        leftIconClassName="text-wb-primary-soft"
      >
        <Text weight="medium" size="2xs" variant="primary-soft">
          {text}
        </Text>
      </Button>
    </Link>
  </DropdownMenuItem>
);

interface Props {
  actionText?: string;
  backPath?: string;
}

export const LogoDropDown = ({ actionText = 'Go To Dashboard', backPath }: Props) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outlined"
          Icon={DarkLogoIcon}
          className="p-2.5"
          RightIcon={CaretDown}
          rightIconClassName="text-wb-secondary h-2.5"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        sideOffset={2}
        align="start"
        className="flex flex-col gap-0 min-w-[260px] w-fit bg-wb-primary p-1.5"
      >
        <MenuItem Icon={ArrowLeft} text={actionText} to={backPath || '/'} />

        <DropdownMenuSeparator />

        <MenuItem Icon={ClockCounterClockwise} text="Version History" to="/website_builder_v2" />

        <MenuItem Icon={GearSix} text="Site Settings" to="/website_builder_v2/settings" />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
