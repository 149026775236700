import { useCallback } from 'react';
import { Minus, Plus, Question } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';
import { cn } from '@/utils/cn';

import { SimpleInputWrapper } from '../../../UI/Input';
import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';

import { Disabled } from './ui/Disabled';

type GridColumnSettingProps = AttributeSettingProps & {
  title: string;
  disabledTooltip?: string;
  isDisabled?: boolean;
};

const parseColumns = (columns: string) => {
  try {
    return parseInt(columns, 10);
  } catch (error) {
    return 3;
  }
};

export function GridColumnSetting({
  editor,
  activeNodeResult,
  title,
  disabledTooltip,
  isDisabled,
}: GridColumnSettingProps) {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const columns = activeNodeAttributes?.columns || '3';

  const isRemoveable = parseColumns(columns) > 1;
  const isAddable = parseColumns(columns) < 6;

  const onAddColumn = useCallback(() => {
    try {
      if (!isAddable) return;
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'columns', `${parseColumns(columns) + 1}`);
        return true;
      });
    } catch (error) {
      console.error(error);
    }
  }, [columns, editor, isAddable, activeNodePos]);

  const onRemoveColumn = useCallback(() => {
    try {
      if (!isRemoveable) return;
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'columns', `${parseColumns(columns) - 1}`);
        return true;
      });
    } catch (error) {
      console.error(error);
    }
  }, [columns, editor, isRemoveable, activeNodePos]);

  const input = (
    <SimpleInputWrapper className="h-[32px] cursor-default py-1">
      <div
        className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {
          'cursor-pointer': isRemoveable,
          'opacity-20': !isRemoveable,
        })}
        onClick={onRemoveColumn}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onRemoveColumn();
          }
        }}
        role="none"
      >
        <Minus weight="bold" size={12} />
      </div>
      <Text size="2xs" weight="medium">
        {columns}
      </Text>
      <div
        className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {
          'cursor-pointer': isAddable,
          'opacity-20': !isAddable,
        })}
        onClick={onAddColumn}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onAddColumn();
          }
        }}
        role="none"
      >
        <Plus weight="bold" size={12} />
      </div>
    </SimpleInputWrapper>
  );

  return (
    <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
      <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
        <Text variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        {isDisabled && (
          <Tooltip placement="bottom-end" center={disabledTooltip}>
            <Question size={16} weight="bold" className="text-wb-secondary" />
          </Tooltip>
        )}
      </div>

      {isDisabled ? <Disabled>{input}</Disabled> : input}
    </div>
  );
}
