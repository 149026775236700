import { FC } from 'react';
import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/20/solid';
import { Grid } from '@tremor/react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { Badge } from '@/ui/Badge';
import { Card } from '@/ui/Card';

import Text from '../../../../components/Text';
import { SplitTestOption } from '../../../../interfaces/split_test_option';

interface OptionTableProps {
  splitTestOption: SplitTestOption;
  winner: boolean;
  inProgress: boolean;
}

const OptionTable: FC<OptionTableProps> = ({ splitTestOption, winner, inProgress }: OptionTableProps) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const formatter = new Intl.NumberFormat('en');

  if (isV2) {
    return (
      <Card density="none" className="overflow-hidden">
        <div className="flex flex-col gap-y-4 p-4">
          <div className="flex gap-x-2 items-center">
            <Typography token="font-medium/text/sm" colorWeight="700">
              {splitTestOption.control ? 'Control' : 'Variant'}
            </Typography>
            {winner ? <Badge type="success">Winner</Badge> : null}
            {inProgress ? <Badge type="information">Running</Badge> : null}
          </div>
          <Typography token="font-medium/text/sm">{splitTestOption.value}</Typography>
        </div>
        <Grid
          numCols={1}
          numColsSm={3}
          className={cx(
            'gap-4 py-2 px-4 border-t sm:py-0',
            winner ? 'bg-feedback-success-100' : 'bg-surface-50',
            winner ? 'border-feedback-success-200' : 'border-surface-200'
          )}
        >
          <div className={cx('border-r', winner ? 'border-feedback-success-200' : 'border-surface-200')}>
            <div className="flex gap-x-2 sm:my-5 items-center">
              <Typography token="font-medium/text/sm">Open Rate</Typography>
              <Typography token="font-medium/text/lg">{`${splitTestOption.open_rate}%`}</Typography>
            </div>
          </div>
          <div className={cx('border-r', winner ? 'border-feedback-success-200' : 'border-surface-200')}>
            <div className="flex gap-x-2 sm:my-5 items-center">
              <Typography token="font-medium/text/sm">Recipients</Typography>
              <Typography token="font-medium/text/lg">{formatter.format(splitTestOption.total_delivered)}</Typography>
            </div>
          </div>
          <div className="gap-x-2">
            <div className="flex gap-x-2 sm:my-5 items-center">
              <Typography token="font-medium/text/sm">Opens</Typography>
              <Typography token="font-medium/text/lg">
                {formatter.format(splitTestOption.total_unique_opened)}
              </Typography>
            </div>
          </div>
        </Grid>
      </Card>
    );
  }

  return (
    <section>
      <div className={`w-full rounded-lg ${winner ? 'border-green-400' : ''}`}>
        <div className="border-b border-gray-200 my-2 flex flex-row">
          <Text type="subtitle" size="md" className="text-gray-400">
            {splitTestOption.control ? 'Control' : 'Variant'}
          </Text>
          {winner && (
            <Text type="subtitle" size="md" className="text-green-400 ml-2">
              WINNER
            </Text>
          )}
        </div>

        <Text type="subtitle" size="md" className="text-gray-900 mb-6">
          {splitTestOption.value}
        </Text>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-3">
          <div className="flex md:w-auto w-full flex-col">
            <EnvelopeOpenIcon className="h-6 w-6 text-gray-300 mb-4" />
            <div className="mb-2">
              <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">OPENS</p>
              <p className="leading-none">
                <span className={`text-5xl font-black ${winner ? 'text-primary-500' : 'text-gray-400'}`}>
                  {formatter.format(splitTestOption.total_unique_opened)}
                </span>
                <span className="text-gray-400 tracking-tighter text-sm"> </span>
              </p>
            </div>
          </div>

          <div className="flex md:w-auto w-full flex-col justify-between">
            <EnvelopeIcon className="h-6 w-6 text-gray-300 mb-4" />
            <div className="mb-2">
              <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">RECIPIENTS</p>
              <p className="leading-none">
                <span className={`text-5xl font-black ${winner ? 'text-primary-500' : 'text-gray-400'}`}>
                  {formatter.format(splitTestOption.total_delivered)}
                </span>
                <span className="text-gray-400 tracking-tighter text-sm"> delivered</span>
              </p>
              <p className="leading-none invisible">
                <span className="text-gray-400 tracking-tighter text-sm"> . </span>
              </p>
            </div>
          </div>

          <div className="flex md:w-auto w-full flex-col justify-between">
            <EnvelopeOpenIcon className="h-6 w-6 text-gray-300 mb-4" />
            <div className="mb-2">
              <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">OPEN RATE</p>
              <p className="leading-none">
                <span className={`text-5xl font-black ${winner ? 'text-primary-500' : 'text-gray-400'}`}>
                  {splitTestOption.open_rate}
                </span>
                <span className="text-gray-400 tracking-tighter text-md"> %</span>
              </p>
              <p className="leading-none invisible">
                <span className="text-gray-400 tracking-tighter text-sm"> . </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default OptionTable;
