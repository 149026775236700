import { CursorArrowRaysIcon, EnvelopeIcon, SparklesIcon } from '@heroicons/react/20/solid';
import { Grid } from '@tremor/react';

import { useSettings } from '@/context/settings-context';
import { FactPostClick } from '@/interfaces/fact_post_click';
import { MetricCard } from '@/ui/MetricCard';
import { VERIFIED_CLICKS_EXPAND_METRIC_LABEL, VERIFIED_CLICKS_TOOLTIP } from '@/utils/verifiedClicksConstants';

interface Props {
  showEmailStats: boolean;
  showWebStats: boolean;
  metrics?: FactPostClick;
}

const MetricsBanner = ({ showEmailStats, showWebStats, metrics }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;
  const isVerifiedClicksV2 = settings?.verified_clicks_v2 === true;

  const formatter = new Intl.NumberFormat('en');

  if (!showEmailStats && !showWebStats) return null;

  const totalUniqueEmailClicksMetric = isVerifiedClicksV2 ? metrics?.total_unique_email_clicked_raw : metrics?.total_unique_email_clicked;
  const totalEmailClicksMetric = isVerifiedClicksV2 ? metrics?.total_email_clicked_raw : metrics?.total_email_clicked;
  const totalUniqueEmailClicks = showEmailStats ? formatter.format(totalUniqueEmailClicksMetric || 0) : 0;
  const totalEmailClicks = showEmailStats ? formatter.format(totalEmailClicksMetric || 0) : 0;
  const totalVerifiedUniqueEmailClicks = showEmailStats ? formatter.format(metrics?.total_unique_email_clicked || 0) : 0;
  const totalVerifiedEmailClicks = showEmailStats ? formatter.format(metrics?.total_email_clicked || 0) : 0;
  const totalClicksMetric = isVerifiedClicksV2 ? metrics?.total_clicked_raw : metrics?.total_clicked;

  const totalUniqueWebClicks = showWebStats ? formatter.format(metrics?.total_unique_web_clicked || 0) : 0;
  const totalWebClicks = showEmailStats ? formatter.format(metrics?.total_web_clicked || 0) : 0;

  const totalClicks = formatter.format(totalClicksMetric || 0);

  if (isV2) {
    return (
      <div className="flex flex-col gap-y-3">
        <Grid numCols={1} numColsSm={3} className="gap-4">
          <MetricCard
            variant="secondary"
            label="Unique Email Clicks"
            value={totalUniqueEmailClicks}
            expandComplementsLabel={VERIFIED_CLICKS_EXPAND_METRIC_LABEL}
            expandComplementsTooltip={VERIFIED_CLICKS_TOOLTIP}
            initialVisibleComplements={1}
            complements={[
              { label: 'Total Clicks', value: totalEmailClicks },
              ...(isVerifiedClicksV2
                ? [
                    { label: 'Verified Unique Email Clicks', value: totalVerifiedUniqueEmailClicks },
                    { label: 'Verified Total Clicks', value: totalVerifiedEmailClicks },
                  ]
                : []),
            ]}
          />
          <MetricCard
            variant="secondary"
            label="Unique Web Clicks"
            value={totalUniqueWebClicks}
            complements={[{ label: 'Total Clicks', value: totalWebClicks }]}
          />
          <MetricCard variant="secondary" label="Total Clicks" value={totalClicks} />
        </Grid>
      </div>
    );
  }

  return (
    <div className="w-full rounded-lg">
      <div className="grid gap-8 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 border-b border-gray-200 pb-8">
        {showEmailStats && (
          <div className="flex md:w-auto w-full flex-col">
            <EnvelopeIcon className="h-6 w-6 text-gray-300 mb-4" />
            <div>
              <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">EMAIL CLICKS</p>
              <p className="leading-none">
                <span className="text-5xl font-black text-indigo-500">{totalUniqueEmailClicks}</span>
                <span className="text-gray-400 tracking-tighter text-sm"> unique</span>
              </p>
              <p className="leading-none">
                <span className="text-gray-400 tracking-tighter text-sm">
                  vs. <strong className="text-gray-600 text-base">{totalEmailClicks}</strong> total
                </span>
              </p>
            </div>
          </div>
        )}

        {showWebStats && (
          <div className="flex md:w-auto w-full flex-col justify-between">
            <CursorArrowRaysIcon className="h-6 w-6 text-gray-300 mb-4" />
            <div>
              <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">WEB CLICKS</p>
              <p className="leading-none">
                <span className="text-5xl font-black text-indigo-500">{totalUniqueWebClicks}</span>
                <span className="text-gray-400 tracking-tighter text-sm"> unique</span>
              </p>
              <p className="leading-none">
                <span className="text-gray-400 tracking-tighter text-sm">
                  vs. <strong className="text-gray-600 text-base">{totalWebClicks}</strong> total
                </span>
              </p>
            </div>
          </div>
        )}

        <div className="flex md:w-auto w-full flex-col">
          <SparklesIcon className="h-6 w-6 text-gray-300 mb-4" />
          <div>
            <p className="text-xs uppercase font-extrabold text-gray-600 tracking-wider">TOTAL CLICKS</p>
            <p className="leading-none">
              <span className="text-5xl font-black text-primary-500">{totalClicks}</span>
              <span className="text-gray-400 tracking-tighter text-sm"> </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricsBanner;
