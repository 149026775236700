/* eslint-disable jsx-a11y/media-has-caption */

import { FC } from 'react';
import { NumberedListElement } from '@shared/dream-components';
import { NodeViewContent, NodeViewProps, useReactNodeView } from '@tiptap/react';

export const OrderedListView: FC<NodeViewProps> = ({ node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <NumberedListElement
      element={{
        type: 'orderedList',
        attrs: {
          id: node.attrs.id,
        },
        content: [],
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      <NodeViewContent />
    </NumberedListElement>
  );
};
