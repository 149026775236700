import { useQuery } from 'react-query';

import { RssFeed } from '../../interfaces/rss_feed';
import api from '../../services/swarm';

export default function useRssFeeds(publicationId: string) {
  return useQuery<RssFeed[]>([publicationId, 'rss_feeds'], () =>
    api
      .get(`/rss_feeds`, {
        params: {
          publication_id: publicationId,
        },
      })
      .then((res) => res.data)
  );
}
