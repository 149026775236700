import { useSettings } from '@/context/settings-context';
import usePlan from '@/hooks/usePlan';
import { useCurrentPublication } from '@/hooks/usePublications';

const useCreateBoostInvitationsRestrictions = () => {
  const { data: currentPublication } = useCurrentPublication();
  const { plan } = usePlan();
  const { settings } = useSettings();

  const canUpgrade = plan !== 'custom';

  if (typeof settings?.max_monthly_boost_invitations === 'undefined' || !currentPublication) {
    return {
      hasReachedInviteLimit: false,
      remainingInvites: 0,
      canUpgrade,
    };
  }

  const hasReachedInviteLimit =
    currentPublication.current_month_boost_invitations_count >= settings.max_monthly_boost_invitations;
  const remainingInvites = hasReachedInviteLimit
    ? 0
    : settings.max_monthly_boost_invitations - currentPublication.current_month_boost_invitations_count;

  return {
    hasReachedInviteLimit,
    remainingInvites,
    canUpgrade,
  };
};

export default useCreateBoostInvitationsRestrictions;
