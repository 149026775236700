import { useCallback } from 'react';

import { Section } from '../../extensions';
import { convertToSection } from '../DragHandleButton/utils';
import { Button } from '../ui/Button';
import { Icon } from '../ui/Icon';
import { Tooltip } from '../ui/Tooltip';

import { ButtonProps, Condition } from './types';

export const SetReferralsConditionsButton = ({
  editor,
  currentNode,
  currentNodePos,
  $showTooltip = false,
  condition,
  onClick,
  name,
  icon,
  title,
  ...rest
}: ButtonProps): JSX.Element => {
  const setReferralsCondition = useCallback(
    (newCondition: Condition) => {
      convertToSection(editor, currentNode, currentNodePos);

      editor.commands.updateAttributes(Section.name, { referralCondition: newCondition });
    },
    [editor, currentNode, currentNodePos]
  );

  return (
    <Tooltip enabled={$showTooltip} title={title}>
      <Button
        $fullWidth
        $leftSlot={<Icon name={icon} />}
        onClick={() => {
          setReferralsCondition(condition);

          onClick();
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export default SetReferralsConditionsButton;
