import { useCurrentPublication } from '@/hooks';

import { usePageContext } from '../Context/PageContext';

import IframePreviewer from './IframePreviewer';

interface Props {
  hideUrlBar?: boolean;
  overrideIframeChildren?: React.ReactNode;
}

const IframeRenderer = ({ overrideIframeChildren, hideUrlBar }: Props) => {
  const { data: currentPublication } = useCurrentPublication();
  const { pageData } = usePageContext<any>();
  const page = pageData?.currentPage;

  // Status was a reserved param on this route already for orchid so we are using version instead
  const iframeUrl = `${currentPublication?.url}${page?.path}?version=draft`;

  return (
    <IframePreviewer
      title={page?.title}
      url={iframeUrl}
      overrideIframeChildren={overrideIframeChildren}
      hideUrlBar={hideUrlBar}
      isHomePage={page?.path === ''}
      isSignupPage={page?.path === 'subscribe'}
      isUpgradePage={page?.path === 'upgrade'}
    />
  );
};

export default IframeRenderer;
