import { useState } from 'react';

import { Input } from '@/components/Form';
import { useMilestones } from '@/hooks/useMilestones';
import { AutomationCondition, AutomationConditions } from '@/interfaces/automations/automation_condition';
import { LogicalOperators } from '@/interfaces/condition';
import { Dropdown } from '@/ui/Dropdown';

import MilestoneSelect from '../../components/MilestoneSelect';

interface Props {
  mainConditions: AutomationConditions;
  onChange: (mainConditions: AutomationConditions) => void;
}

type ReferralAction = 'milestone' | 'count';
type ReferralActionValue = string | 'milestone_id';

export const ReferralActionTrigger = ({ mainConditions, onChange }: Props) => {
  const condition = mainConditions.conditions?.find((c) => c.name === 'referral_action');
  const milestoneId = condition?.resource_id;
  const value: ReferralActionValue = condition?.value || 'milestone_id';
  const [action, setAction] = useState<ReferralAction>(milestoneId ? 'milestone' : 'count');

  const {
    data: milestonesData,
    isRefetching: isRefetchingMilestones,
    isLoading: isLoadingMilestones,
  } = useMilestones({ allResults: true });
  const milestones = milestonesData?.pages.flatMap((page) => page.milestones) || [];
  const areMilestonesEmpty = !isRefetchingMilestones && !isLoadingMilestones && milestones.length === 0;

  const handleChange = (_name: string, selectedValue: ReferralAction) => {
    const newValue = selectedValue === 'milestone' ? 'milestone_id' : '';
    const newResourceId = selectedValue === 'milestone' && !areMilestonesEmpty ? milestones[0].id : '';

    setAction(selectedValue);
    onChange({
      logical_operator: LogicalOperators.AND,
      conditions: [{ ...condition, value: newValue, resource_id: newResourceId } as AutomationCondition],
    });
  };

  const handleMilestoneChange = (_name: string, selectedMilestoneId: string) => {
    onChange({
      logical_operator: LogicalOperators.AND,
      conditions: [{ ...condition, value: 'milestone_id', resource_id: selectedMilestoneId } as AutomationCondition],
    });
  };

  const handleCountChange = (newValue: string, hasErrors: boolean) => {
    onChange({
      logical_operator: LogicalOperators.AND,
      conditions: [{ ...condition, value: newValue, resource_id: '', hasErrors } as AutomationCondition],
    });
  };

  return (
    <div className="space-y-2">
      <Dropdown
        name="referral_action"
        value={action}
        onSelect={handleChange}
        options={[
          { label: 'Referral Milestone hit', value: 'milestone' },
          { label: 'Referrals achieved', value: 'count' },
        ]}
      />
      {action === 'milestone' && (
        <MilestoneSelect milestoneId={milestoneId || ''} milestones={milestones} onChange={handleMilestoneChange} />
      )}
      {action === 'count' && (
        <Input
          placeholderText="Number of referrals"
          type="number"
          className="w-40"
          name="value"
          value={value}
          onChange={(event) => {
            handleCountChange(event.target.value, event.target.value === '');
          }}
          errorText={condition?.hasErrors ? 'This field is required' : undefined}
          required
        />
      )}
    </div>
  );
};
