import { useInfiniteQuery } from 'react-query';

import { AdNetworkPaymentRequest } from '@/interfaces/ad_network/internal/payment_request';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  payment_requests: AdNetworkPaymentRequest[];
  pagination: Pagination;
}

interface Props {
  campaignId: string;
  allResults?: boolean;
}

const useCampaignPaymentRequests = ({ campaignId, allResults = false }: Props) => {
  const fetchPaymentRequests = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/ad_network/internal/campaigns/${campaignId}/payment_requests`, {
        params: {
          page: pageParam,
          all_results: allResults,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_network', 'internal', 'campaigns', campaignId, 'payment_requests', `all_results=${allResults}`],
    fetchPaymentRequests,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
};

export default useCampaignPaymentRequests;
