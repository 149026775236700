import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';
import { usePublication } from '../usePublications';

export enum Endpoints {
  UPDATE_LANDING_PAGES = 'update_landing_pages',
  UPDATE_UPGRADE_PAGES = 'update_upgrade_pages',
  UPDATE_HOME_PAGES = 'update_home_pages',
}

type ValidEndpoints = Endpoints.UPDATE_LANDING_PAGES | Endpoints.UPDATE_UPGRADE_PAGES | Endpoints.UPDATE_HOME_PAGES;

interface Props {
  endpoint: ValidEndpoints;
  onSuccess?: () => void;
}

const useUpdateWebTemplatePages = ({ onSuccess, endpoint }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: currentPublication } = usePublication(currentPublicationId);

  return useMutation(
    (payload: any) =>
      api.patch(`/web_templates/${currentPublication?.web_template_id}/${endpoint}`, {
        publication_id: currentPublicationId,
        ...payload,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
    }
  );
};

export default useUpdateWebTemplatePages;
