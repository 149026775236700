import React from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useWebsiteContext } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';
import api from '@/services/swarm';

import { ImageInput } from '../_components/UI/ImageInput';
import { Input } from '../_components/UI/Input';
import InputWrapper from '../_components/UI/InputWrapper';
import { Select, SelectTrigger, SelectValue } from '../_components/UI/Select';
import { Switch } from '../_components/UI/Switch';
import { Textarea } from '../_components/UI/Textarea';

import SettingsItem from './_components/SettingsItem';
import SettingsSection from './_components/SettingsSection';
import SettingsUrl from './_components/SettingsUrl';

const EditFromGlobalSettings = ({ publicationSettings }: { publicationSettings: string }) => {
  return (
    <span>
      Edit in{' '}
      <a href={publicationSettings} target="_blank" rel="noreferrer" className="underline text-wb-accent">
        global settings
      </a>
    </span>
  );
};

const WebsiteSettingsGeneral = () => {
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const { site } = useWebsiteContext();
  const updateSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  const publicationSettings: string = `${window.location.origin}/settings/publication`;

  const handleImageSave = (key: string, file: string | File | any) => {
    const formData = new FormData();
    const currentPubId = currentPublication?.id || '';
    formData.append('publication_id', currentPubId);
    formData.append(`site_version[settings][${key}]`, file);

    toast
      .promise(api.patch(`/site_versions/${site?.draft_site_version?.id}`, formData), {
        loading: 'Saving...',
        success: 'Saved!',
        error: 'Failed to upload image',
      })
      .finally(() => {
        queryClient.invalidateQueries(['publications', currentPubId, 'sites', 'null']);
      });
  };

  return (
    <div>
      <SettingsSection>
        <SettingsItem
          title="Name"
          description="The name of your site will be displayed in the browser tab and in search engine results."
        >
          <Input
            className="max-w-md"
            placeholder="Name"
            disabled
            value={currentPublication?.name || ''}
            helperText={<EditFromGlobalSettings publicationSettings={publicationSettings} />}
          />
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem
          title="Description"
          description="The description of your site will be displayed in search engine results."
        >
          <Textarea
            className="max-w-md"
            placeholder="Description"
            disabled
            value={currentPublication?.description || ''}
            helperText={<EditFromGlobalSettings publicationSettings={publicationSettings} />}
          />
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem title="Language" description="The language displayed on your site.">
          <InputWrapper name="language">
            <Select disabled>
              <SelectTrigger className="max-w-md" id="language">
                <SelectValue placeholder={currentPublication?.language} />
              </SelectTrigger>
            </Select>
          </InputWrapper>
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem title="Private" description="Make your site private to restrict access.">
          <InputWrapper helperText={<EditFromGlobalSettings publicationSettings={publicationSettings} />}>
            <Switch id="private" labelText="Private" checked={currentPublication?.private || false} disabled />
          </InputWrapper>
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem title="Domain" description="The domain of your site.">
          <InputWrapper helperText={<EditFromGlobalSettings publicationSettings={publicationSettings} />}>
            <SettingsUrl labelText="Site available at:" href={`${currentPublication?.url}`} />
          </InputWrapper>
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem
          title="Favicon"
          description="The description of your site will be displayed in search engine results."
        >
          <ImageInput
            value={site?.draft_site_version?.settings?.favicon_url || ''}
            onSave={(file) => {
              handleImageSave('favicon_url', file);
            }}
            width={100}
            height={100}
            onRemove={() => {
              updateSiteVersion.mutate({
                settings: {
                  favicon_url: null,
                } as any,
              });
            }}
          />
        </SettingsItem>
      </SettingsSection>
    </div>
  );
};

export default WebsiteSettingsGeneral;
