import { useQuery } from 'react-query';

import api from '../../services/swarm';

const useSecondFactor = () => {
  return useQuery<any, Error>(['second_factor'], () =>
    api
      .get('/current_user/second_factor', {
        params: {
        },
      })
      .then((res) => res.data)
  );
};

export default useSecondFactor;
