import { FC } from 'react';

import { Checkbox } from '../../components/Form';
import ColorField from '../../components/Form/ColorField';

import ExpandableSlider from './components/ExpandableSlider';
import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { Divider, FormContainer, FormInput, FormSection } from './helpers';
import { LineHeight, LineHeights, ThemeFormProps } from './types';

const BodyTextForm: FC<ThemeFormProps> = ({ theme, onUpdate, fonts, colorPalette }) => {
  return (
    <FormContainer>
      <FormSection title="Font Settings">
        <FormInput title="Font family">
          {fonts && (
            <ThemeEditorDropdown
              currentValue={theme.paragraph_font_family}
              onUpdate={(val) => onUpdate({ ...theme, paragraph_font_family: val })}
              options={fonts}
            />
          )}
        </FormInput>
        <FormInput title="Line height">
          <ThemeEditorDropdown
            currentValue={theme.paragraph_line_height_size}
            onUpdate={(val) => onUpdate({ ...theme, paragraph_line_height_size: val as LineHeight })}
            options={[
              { label: LineHeights.Less, value: LineHeights.Less },
              { label: LineHeights.Normal, value: LineHeights.Normal },
              { label: LineHeights.More, value: LineHeights.More },
            ]}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="font_size"
            label="Font size"
            onChange={(val: number) => onUpdate({ ...theme, paragraph_font_size: val.toString() })}
            initialValue={theme.paragraph_font_size}
            min={12}
            max={32}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <ExpandableSlider
            fields={{
              paragraph_padding_top: 'Top',
              paragraph_padding_bottom: 'Bottom',
            }}
            theme={theme}
            onUpdate={onUpdate}
            max={80}
            min={0}
            title="Space between paragraphs"
          />
        </FormInput>
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.paragraph_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, paragraph_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
      <Divider />
      <FormSection title="Inline Hyperlinks">
        <FormInput>
          <div className="lg:flex lg:flex-column lg:justify-between">
            <Checkbox
              name="bold"
              onChange={(checked: boolean) => onUpdate({ ...theme, inline_link_bold: checked.toString() })}
              labelText="Bold"
              checked={theme.inline_link_bold === 'true'}
              className="select-none"
            />
            <Checkbox
              name="italic"
              onChange={(checked: boolean) => onUpdate({ ...theme, inline_link_italic: checked.toString() })}
              labelText="Italic"
              checked={theme.inline_link_italic === 'true'}
              className="select-none"
            />
            <Checkbox
              name="underline"
              onChange={(checked: boolean) => onUpdate({ ...theme, inline_link_underline: checked.toString() })}
              labelText="Underline"
              checked={theme.inline_link_underline === 'true'}
              className="select-none"
            />
          </div>
        </FormInput>
        {theme.inline_link_underline === 'true' && (
          <FormInput title="Underline color">
            <ColorField
              placement="bottom"
              color={theme.inline_link_underline_color}
              colorPalette={colorPalette}
              onColorChange={(color: any) => onUpdate({ ...theme, inline_link_underline_color: color?.hexString })}
            />
          </FormInput>
        )}
        <FormInput title="Font color">
          <ColorField
            placement="bottom"
            color={theme.inline_link_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, inline_link_color: color?.hexString })}
          />
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default BodyTextForm;
