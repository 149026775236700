import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import truncate from 'lodash.truncate';

import { Button } from '../Button';
import { InputField } from '../Input';
import { Panel, PanelFooter, PanelHeader } from '../Panel';
import { Spinner } from '../Spinner';

import { Styled } from './styled';
import { SearchListItemProps, SearchListProps } from './types';

const SearchList = memo(
  ({
    items = [],
    hasMore = false,
    onLoadMore,
    searchable = true,
    searchPlaceholder = '',
    onSearch,
    header = undefined,
    footer = undefined,
    onSelect = () => null,
    onBack = () => null,
    ...rest
  }: SearchListProps): JSX.Element => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const loadMoreRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [selectedItem, setSelectedItem] = useState(0);

    useEffect(() => {
      setSelectedItem(0);
    }, [searchValue]);

    useEffect(() => {
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      });
    }, []);

    useEffect(() => {
      const scrollContainer = scrollContainerRef.current;
      const selectedElement = scrollContainerRef.current?.children?.[selectedItem];

      if (selectedElement instanceof HTMLElement && scrollContainer) {
        const containerTop = scrollContainer?.scrollTop;
        const containerBottom = containerTop + scrollContainer?.offsetHeight;
        const elementTop = selectedElement.offsetTop;
        const elementBottom = elementTop + selectedElement.offsetHeight;

        if (
          elementBottom < containerTop ||
          elementBottom > containerBottom ||
          elementTop < containerTop ||
          elementTop > containerBottom
        ) {
          scrollContainer.scrollTo({
            top: selectedElement.offsetTop,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    }, [selectedItem]);

    const filteredItems = useMemo(() => {
      if (onSearch) {
        return items;
      }
      return items.filter((item: { value: string; label: string }) => {
        return item.label.toLowerCase().includes(searchValue.toLowerCase());
      });
    }, [items, onSearch, searchValue]);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;

          if (entry.isIntersecting) {
            if (onLoadMore) {
              onLoadMore(searchValue);
            }
          }
        },
        {
          root: scrollContainerRef.current,
          threshold: 0.5,
          rootMargin: '0px 0px -10px 0px',
        }
      );

      const ref = loadMoreRef.current;

      if (ref) {
        observer.observe(ref);
      }

      return () => {
        if (ref) {
          observer.unobserve(ref);
        }
      };
    }, [loadMoreRef, onLoadMore, searchValue]);

    const handleKeyDown = (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'Enter': {
          const item = filteredItems[selectedItem];

          if (!item) return false;

          onSelect(item);

          return true;
        }
        case 'Escape': {
          onBack();

          return true;
        }
        case 'ArrowUp': {
          const selectIndex = selectedItem === 0 ? filteredItems.length - 1 : selectedItem - 1;

          setSelectedItem(selectIndex);

          return true;
        }
        case 'ArrowDown': {
          const selectIndex = selectedItem === filteredItems.length - 1 ? 0 : selectedItem + 1;

          setSelectedItem(selectIndex);

          return true;
        }
        default:
          return false;
      }
    };

    return (
      <Panel
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        <Styled.Container>
          {header && <PanelHeader>{header}</PanelHeader>}
          {searchable && (
            <Styled.InputContainer>
              <InputField
                ref={searchInputRef}
                type="text"
                autoFocus
                value={searchValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchValue(event.target.value);

                  if (onSearch) {
                    onSearch(event.target.value);
                  }
                }}
                onKeyDown={handleKeyDown}
                placeholder={searchPlaceholder}
              />
            </Styled.InputContainer>
          )}
          <Styled.ScrollContainer ref={scrollContainerRef}>
            {filteredItems.map((item: { value: string; label: string }, index) => {
              return (
                <Button
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  $variant="quaternary"
                  $size="small"
                  $fullWidth
                  $muted
                  $active={selectedItem === index}
                  onClick={() => {
                    onSelect(item);
                  }}
                  title={item.label}
                >
                  {truncate(item.label, { length: 35 })}
                </Button>
              );
            })}
            {hasMore && (
              <Styled.LoadingContainer>
                <Spinner ref={loadMoreRef} size={1} />
              </Styled.LoadingContainer>
            )}
          </Styled.ScrollContainer>
          {footer && <PanelFooter>{footer}</PanelFooter>}
        </Styled.Container>
      </Panel>
    );
  }
);

export { SearchList };
export type { SearchListItemProps };
