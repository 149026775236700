import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import Card from '@/components/Card';
import { Typography } from '@/components/Typography';

export const PartnerProgramDisabled = () => {
  return (
    <div className="w-full h-screen overflow-y-hidden flex items-center justify-center">
      <Card className="w-full h-full p-6 mx-auto my-auto">
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex flex-col gap-y-4 items-center">
            <div className="w-10 h-10 flex items-center justify-center bg-feedback-warning-50 rounded-full">
              <ExclamationTriangleIcon className="w-5 h-5 text-feedback-warning-600" />
            </div>
            <div className="w-72 text-center">
              <Typography token="font-medium/text/lg">Your access to our Partner Program has been disabled.</Typography>
            </div>
            <div className="w-full sm:w-96 text-center">
              <Typography token="font-normal/text/sm" colorWeight="700">
                Please contact{' '}
                <a href="mailto:partners@beehiiv.com" className="underline">
                  partners@beehiiv.com
                </a>{' '}
                for more assistance.
              </Typography>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PartnerProgramDisabled;
