import { useState } from 'react';

import { TypeaheadMultiSelect } from '@/components/Form';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';

import useFilteringContext from '../filtering-context';
import { ConditionGroup, TagOperator, TagOperatorsList } from '../types';

const TagFilterDetails = ({ group }: { group: ConditionGroup }) => {
  const operators = TagOperatorsList;
  const publicationId = useCurrentPublicationId();
  const { data, isLoading } = useOptions(publicationId, 'tag_names');

  const [selectedOperator, setSelectedOperator] = useState<TagOperator>(operators[0].value);
  const [selectedTags, setSelectedTags] = useState<Array<{ id: number; name: string }>>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const tags: Array<{ id: number; name: string }> = data?.options || [];

  const { addCondition, handleClose } = useFilteringContext();

  const handleSelect = (name: string, value: string) => {
    setSelectedTags([...selectedTags, { id: tags.find((tag) => tag.name === value)?.id || -1, name: value }]);
  };

  const handleDeselect = (name: string, value: string) => {
    setSelectedTags(selectedTags.filter((selectedTag) => selectedTag.name !== value));
  };

  const handleApply = () => {
    selectedTags.forEach((selectedTag) => {
      addCondition(
        {
          type: 'attribute',
          name: 'tag',
          filters: { operator: selectedOperator, value: selectedTag.name },
        },
        group.uuid
      );

      handleClose();
    });
  };

  const handleDeselectAll = () => {
    setSelectedTags([]);
  };

  const handleClear = () => {
    setSearchQuery('');
  };

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleSearch = async (): Promise<Option[]> => {
    return (
      tags.filter((tag) => tag.name.includes(searchQuery)).map((tag) => ({ label: tag.name, value: tag.name })) || []
    );
  };

  if (isLoading)
    return (
      <div className="absolute z-10 mt-2 bg-white border border-gray-300 shadow rounded-md text-sm left-0 w-max text-gray-600 p-4 space-y-2">
        <LoadingSpinner />
      </div>
    );

  return (
    <form
      className="absolute z-10 mt-2 bg-white border border-gray-300 shadow rounded-md text-sm left-0 w-max text-gray-600 p-4 space-y-2"
      onSubmit={handleApply}
    >
      <div className="space-y-2">
        <div className="space-x-2">
          <p className="inline">Tags</p>
          <select
            className="appearance-none outline-none border rounded-md text-xs border-gray-300"
            onChange={(e) => setSelectedOperator(e.target.value as any)}
            value={selectedOperator}
            required
          >
            {operators.map((operator) => {
              return (
                <option key={operator.value} value={operator.value}>
                  {operator.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className="h-64 w-56 space-y-2">
          <TypeaheadMultiSelect
            name="tags"
            placeholderText="Select Tag(s)"
            values={selectedTags.map((tag) => tag.name)}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            onClear={handleClear}
            onSearchQueryChange={handleSearchQueryChange}
            onDeselectAll={handleDeselectAll}
            onSearch={handleSearch}
            emptyLabel="No tag found"
            showSelectedOption
            showClearAll
            selectedPreview={selectedTags.map((tag) => tag.name)}
          />
        </div>
      </div>
      <Button variant="primary-inverse" type="submit" size="xs">
        Apply
      </Button>
    </form>
  );
};

export default TagFilterDetails;
