import { Typography } from '@/components/Typography';
import { Card } from '@/ui/Card';

import FulfillmentTable from './FulfillmentTable';

const FulfillmentTab = () => {
  return (
    <Card className="space-y-5">
      <Typography token="font-bold/text/base">Reward & Milestone Fulfillment</Typography>
      <FulfillmentTable />
    </Card>
  );
};

export default FulfillmentTab;
