import { Dispatch, FC, memo, SetStateAction, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

import { RedoButton, UndoButton } from '@/components/TiptapEditor/components/buttons';
import { SearchAndReplaceMenu } from '@/components/TiptapEditor/components/menus/search';
import { Avatar } from '@/components/TiptapEditor/components/ui/Avatar';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip';
import { EDITOR_TOP_BAR_Z_INDEX } from '@/components/zIndexes';
import { useSettings } from '@/context/settings-context';
import { usePostTextToSpeechConfig } from '@/hooks';
import { PostInformation } from '@/interfaces/post';
import { cn } from '@/utils/cn';

import { useEditorContext } from '../EditorContext';
import { usePostContext } from '../v2/PostContext';

import StatusBadge from './StatusBadge';
import WarningNotifier from './WarningNotifier';

const formatter = new Intl.NumberFormat('en-US');

interface Props {
  errors: { [key: string]: string };
  postInfo?: PostInformation;
  hitSizeThreshold: boolean;
  hitAudioNewsletterThreshold: boolean;
  showHistoryModal: boolean;
  setShowHistoryModal: Dispatch<SetStateAction<boolean>>;
  setShowSaveAsTemplateModal: Dispatch<SetStateAction<boolean>>;
}

export const EditorTopBar: FC<Props> = memo(
  ({
    errors,
    postInfo,
    hitSizeThreshold,
    hitAudioNewsletterThreshold,
    showHistoryModal,
    setShowHistoryModal,
    setShowSaveAsTemplateModal,
  }) => {
    const { editor, wordCount, users, openSidebar, setOpenSidebar, collaborationEnabled, unresolvedThreadsCount } =
      useEditorContext();

    const { formData: post } = usePostContext();

    const { data: textToSpeechConfig } = usePostTextToSpeechConfig(post!.id);

    const warnings = useMemo(() => postInfo?.warnings, [postInfo]);

    const { settings } = useSettings();

    // Sort collaboration users to put them in the appropriate order.
    const sortedUsers = useMemo(() => {
      if (!users) {
        return [];
      }

      return users.sort((a, b) => {
        if (!b.initials) {
          return -1;
        }

        if (!a.initials) {
          return 1;
        }

        if (a.initials < b.initials) {
          return -1;
        }

        if (a.initials > b.initials) {
          return 1;
        }

        if (a.clientId > b.clientId) {
          return -1;
        }

        return 0;
      });
    }, [users]);

    const handleToggleThreadsSidebar = useCallback(() => {
      if (openSidebar === 'threads') {
        setOpenSidebar?.(null);
      } else {
        setOpenSidebar?.('threads');
      }
    }, [setOpenSidebar, openSidebar]);

    const handleToggleBacklinksSidebar = useCallback(() => {
      if (openSidebar === 'backlinks') {
        setOpenSidebar?.(null);
      } else {
        setOpenSidebar?.('backlinks');
      }
    }, [setOpenSidebar, openSidebar]);

    return (
      <div className={cn('sticky top-0 flex-none md:block bg-white', EDITOR_TOP_BAR_Z_INDEX)}>
        <div className={cx('flex items-center justify-between w-full h-12 px-2 md:px-6 bg-white')}>
          <div className={cx('flex items-center justify-start w-full gap-2')}>
            <StatusBadge
              post={post!}
              errors={hitSizeThreshold ? { base: 'Post size limit reached' } : errors}
              warnings={
                textToSpeechConfig?.enabled && hitAudioNewsletterThreshold
                  ? { base: 'Audio Newsletter character limit reached' }
                  : {}
              }
              isV2
            />

            <WarningNotifier warnings={warnings} />
          </div>

          {sortedUsers.length > 0 && (
            <div className="flex items-center space-x-1">
              {sortedUsers.map((sortedUser) => (
                <Avatar key={sortedUser.clientId} $color={sortedUser.color} tooltipTitle={sortedUser.name}>
                  {sortedUser.initials}
                </Avatar>
              ))}
            </div>
          )}

          <div
            className={cx('hidden md:block text-xs text-gray-500 whitespace-nowrap ml-6', openSidebar ? '' : 'mr-6')}
          >
            {wordCount ? `${formatter.format(wordCount)} ${wordCount === 1 ? 'word' : 'words'}` : <>&nbsp;</>}
          </div>

          {!!editor && (
            <div className="flex items-center ml-2 space-x-1">
              <UndoButton $variant="tertiary" $size="small" editor={editor} $isIconButton $showTooltip />
              <RedoButton $variant="tertiary" $size="small" editor={editor} $isIconButton $showTooltip />
              <SearchAndReplaceMenu editor={editor} />
            </div>
          )}

          <div className={twMerge('flex mx-1 gap-1', openSidebar ? 'mr-1' : 'mr-8')}>
            <div className="ml-4">
              <Tooltip title="Backlinks">
                <Button
                  $leftSlot={<Icon name="Backlink" className="!size-4" />}
                  $isIconButton
                  $isToggleButton
                  $variant="tertiary"
                  $size="small"
                  $active={openSidebar === 'backlinks'}
                  onClick={handleToggleBacklinksSidebar}
                />
              </Tooltip>
            </div>
            {settings?.editor_threads && settings?.collaborative_editing && (
              <Tooltip title={`Comments ${unresolvedThreadsCount ? `- ${unresolvedThreadsCount} open` : ''}`}>
                <Button
                  $leftSlot={
                    <div className="relative w-4 h-4">
                      <Icon name="Comment" />
                      {unresolvedThreadsCount ? (
                        <span className="absolute -top-2 -right-2 w-2 h-2 bg-red-500 rounded-full" />
                      ) : null}
                    </div>
                  }
                  $isIconButton
                  $isToggleButton
                  $variant="tertiary"
                  $size="small"
                  $active={openSidebar === 'threads'}
                  onClick={handleToggleThreadsSidebar}
                />
              </Tooltip>
            )}
            {collaborationEnabled ? (
              <Tooltip title="Version history">
                <Button
                  $leftSlot={<Icon name="History" />}
                  $isIconButton
                  $isToggleButton
                  $variant="tertiary"
                  $size="small"
                  $active={showHistoryModal}
                  onClick={() => setShowHistoryModal(true)}
                />
              </Tooltip>
            ) : null}
            <Tooltip title="Save as template">
              <Button
                $leftSlot={<Icon name="FloppyDisk" />}
                $isIconButton
                $isToggleButton
                $variant="tertiary"
                $size="small"
                onClick={() => setShowSaveAsTemplateModal(true)}
              />
            </Tooltip>
            <Link to="/post_themes/edit?settings=true" target="_blank">
              <Tooltip title="Newsletter Builder">
                <Button
                  $leftSlot={<Icon name="Settings" />}
                  $isIconButton
                  $isToggleButton
                  $variant="tertiary"
                  $size="small"
                />
              </Tooltip>
            </Link>
            <a href="https://support.beehiiv.com/hc/en-us" target="_blank" rel="noreferrer">
              <Tooltip title="Knowledge Base">
                <Button
                  $leftSlot={<Icon name="Help" />}
                  $isIconButton
                  $isToggleButton
                  $variant="tertiary"
                  $size="small"
                />
              </Tooltip>
            </a>
          </div>
        </div>
      </div>
    );
  }
);
