/* eslint-disable */

import { useState } from 'react';
import toast from 'react-hot-toast';
import ColorPopover from '../../../components/ColorPopover';

import NotEditing from './NotEditing';

interface Props {
  label: string;
  value: string | undefined;
  description: string;
  onSave: (val: string) => Promise<any>;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
}

const ColorForm = ({ label, description, value, onSave, toastSuccessMessage, toastErrorMessage }: Props) => {
  const [inputValue, setInputValue] = useState<string>(value || '#000000');

  const handleSave = async () => {
    if (inputValue !== value) {
      await onSave(inputValue)
        .then(() => {
          if (toastSuccessMessage) {
            toast.success(toastSuccessMessage);
          }
        })
        .catch(() => {
          if (toastErrorMessage) {
            toast.error(toastErrorMessage);
          }
        });
    }
  };

  return (
    <div className="flex justify-between items-center border-b border-gray-100 pb-4 last:border-b-0">
      <NotEditing label={label} value={description} />

      <div className="pt-1">
        {inputValue && (
          <ColorPopover
            side="left"
            placement="top"
            color={inputValue}
            onClickOutside={() => handleSave()}
            onColorChange={(color: any) => setInputValue(color?.hexString)}
          />
        )}
      </div>
    </div>
  );
};

export default ColorForm;
