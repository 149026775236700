import { PostEngagementStatus } from '@/interfaces/post';
import { BadgeType } from '@/ui/Badge';

export const engagementTypeLabels: Record<PostEngagementStatus, string> = {
  clicked: 'Clicked',
  opened: 'Opened',
  delivered: 'Unopened',
  unsubscribed: 'Unsubscribed',
};

export const engagementBadgeTypeMap: Record<PostEngagementStatus, BadgeType> = {
  clicked: BadgeType.INFO,
  opened: BadgeType.SUCCESS,
  delivered: BadgeType.WARNING,
  unsubscribed: BadgeType.DEFAULT_LINED,
};
