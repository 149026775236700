import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import EllipsisDropdown from '../../../../../components/EllipsisDropdown';
import TablePagination from '../../../../../components/TablePagination';
import { ContentImport } from '../../../../../interfaces/content_import';
import { Pagination } from '../../../../../interfaces/general';

import StatusBadge from './StatusBadge';

interface Props {
  contentImports: ContentImport[] | undefined;
  pagination: Pagination | undefined;
  onPageSelected: (pageNum: number) => void;
}

const ContentImportTable: FC<Props> = (props: Props) => {
  const { contentImports, pagination, onPageSelected } = props;
  const navigate = useNavigate();

  if (!contentImports || !pagination) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow rounded-lg">
            <div className="bg-gray-50 grid grid-cols-9 md:grid-cols-12 border-b border-gray-200 rounded-t-lg">
              <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider col-span-3">
                Started At
              </div>
              <div className="hidden md:block px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider col-span-3">
                Finished At
              </div>
              <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider col-span-3">
                Status
              </div>
              <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider col-span-3">
                Posts Created
              </div>
            </div>
            <div className="bg-white max-h-72 overflow-y-auto rounded-b-lg">
              {contentImports.map((contentImport: ContentImport) => (
                <div key={contentImport.id} className="grid grid-cols-9 md:grid-cols-12 border-b border-gray-200">
                  <div className="px-6 py-4 col-span-3 text-left">
                    <div className="text-sm text-gray-900">
                      {contentImport.started_at ? moment(contentImport.started_at).format('lll') : '-'}
                    </div>
                  </div>
                  <div className="hidden md:block px-6 py-4 col-span-3 text-left">
                    <div className="text-sm text-gray-900">
                      {contentImport.finished_at ? moment(contentImport.finished_at).format('lll') : '-'}
                    </div>
                  </div>
                  <div className="px-6 py-4 whitespace-nowrap col-span-3 text-left">
                    <div className="text-sm text-gray-900">
                      <StatusBadge status={contentImport.status} />
                    </div>
                  </div>
                  <div className="px-6 py-4 whitespace-nowrap col-span-2 text-left">
                    <div className="text-sm text-gray-900">{contentImport.num_posts_created}</div>
                  </div>
                  <div className="col-span-1 flex items-center">
                    <EllipsisDropdown
                      options={[
                        {
                          label: 'View',
                          onClick: () => {
                            navigate(`/content_imports/${contentImport.id}`);
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <TablePagination className="mt-2" pagination={pagination} onPageSelected={onPageSelected} />
    </div>
  );
};

export default ContentImportTable;
