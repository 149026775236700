/* eslint-disable */

import { PencilIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';

interface Props {
  label: string;
  value: File | string | null;
  description?: string;
  imageSizeRecommendation?: string;
  onSave: Function;
  variant?: 'square' | 'landscape' | 'circle';
}

const ImageForm = ({ label, value, onSave, description, imageSizeRecommendation, variant = 'square' }: Props) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [image, setImage] = useState<string | File | any>(value || null);
  const [isEditingShowing, setIsEditShowing] = useState<boolean>(false);
  const isLandscape = variant === 'landscape';
  const isCircle = variant === 'circle';
  const isSquare = variant === 'square';

  const handleImageClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);

      if (onSave) {
        onSave(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    if (value && !image) {
      setImage(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  let fileUrl = null;
  if (image) {
    fileUrl = typeof image === 'string' ? image : URL.createObjectURL(image);
  }

  return (
    <div className="flex justify-between items-center pb-4 border-b border-gray-100 last:border-b-0">
      <div className="space-y-0.5">
        <p className="block text-sm font-semibold text-gray-700">{label}</p>
        <p className="block text-sm font-medium text-gray-400">{description}</p>
        {imageSizeRecommendation && (
          <p className="block text-xs font-light text-gray-400">({imageSizeRecommendation})</p>
        )}
      </div>
      <input
        name={label.split(' ').join('-')}
        ref={fileInput}
        type="file"
        onChange={handleFileInput}
        className="hidden"
      />

      <div
        className={cx(
          'inline-block rounded-sm relative',
          isLandscape ? 'h-14 w-24' : '',
          isCircle ? 'h-14 w-14 rounded-full' : '',
          isSquare ? 'h-14 w-14' : ''
        )}
      >
        <button
          type="button"
          onClick={handleImageClick}
          className={cx(
            'absolute flex justify-center items-center top-0 left-0 w-full h-full bg-black bg-opacity-0 transition-all hover:bg-opacity-40 overflow-hidden',
            isLandscape ? 'h-14 w-24' : '',
            isCircle ? 'h-14 w-14 rounded-full' : '',
            isSquare ? 'h-14 w-14' : ''
          )}
          onMouseOver={() => setIsEditShowing(true)}
          onMouseLeave={() => setIsEditShowing(false)}
        >
          {isEditingShowing && (
            <div
              className="h-6 w-6 flex justify-center items-center rounded-sm transition-all"
              onClick={() => setIsEditing(true)}
            >
              <PencilIcon className="h-4 w-4 text-white" />
            </div>
          )}
        </button>

        <img
          src={fileUrl || ''}
          alt=""
          className={cx(
            'object-cover border border-gray-200',
            isLandscape ? 'h-14 w-24' : '',
            isCircle ? 'h-14 w-14 rounded-full' : '',
            isSquare ? 'h-14 w-14' : ''
          )}
        />
      </div>
    </div>
  );
};

export default ImageForm;
