import { CheckIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';

import type { PollDetails } from '../../../components/_domain/PollForm/PollForm';
import { PollOrientation } from '../../../interfaces/poll';

interface Props {
  publicationId: string;
  pollDetails: PollDetails;
  className?: string;
  labelText?: string;
}

const Preview: React.FC<Props> = ({ publicationId, pollDetails, className, labelText }) => {
  const {
    question,
    description,
    choices,
    list_style: listStyle,
    orientation,
    poll_style: pollStyle,
    redirect_url: redirectUrl,
    trivia_answer: triviaAnswer,
  } = pollDetails;

  const LIST_CLASS_MAP = {
    disc: 'list-disc ml-4',
    decimal: 'list-decimal ml-4',
    none: 'list-none',
  };

  const { data: settings } = usePublicationSettings(publicationId);

  const options = choices?.map((choice) => ({
    name: choice,
    value: choice,
    correct: choice === triviaAnswer,
  }));

  const simplifiedRedirectUrl = redirectUrl
    ?.replace(/^https?:\/\//, '')
    .replace(/^www\./, '')
    .replace(/\/$/, '');

  return (
    <div className={cx('border rounded p-6 relative bg-white overflow-y-auto', className)}>
      {question && (
        <p>
          <strong>{question}</strong>
        </p>
      )}

      {description && <p>{description}</p>}

      {settings?.polls_v2 ? (
        <>
          {pollStyle === 'default' && (
            <table role="none" cellSpacing="4" cellPadding="0" className="min-w-[300px] border-0">
              {options.map((option) => (
                <tr key={`poll-choice-${option.value}`}>
                  <td>
                    <div className="w-full border-solid border border-[rgba(50, 50, 50, 0.17)] px-3 py-2 rounded my-1">
                      <p className="text-inherit text-sm relative truncate">
                        <span>{option.value}</span>
                        {option.correct && (
                          <span className="absolute right-2 top-1/2 -translate-y-1/2 transform">
                            <CheckIcon className="h-4 w-4 text-green-500" />
                          </span>
                        )}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          )}
          {pollStyle === 'bullet_points' && (
            <ul className="list-disc list-inside mt-4 ml-4 min-w-[300px]">
              {options.map((option) => (
                <li className="relative truncate" key={`poll-choice-${option.value}`}>
                  <span>{option.value}</span>
                  {option.correct && (
                    <span className="absolute top-1/2 -translate-y-1/2 transform pl-2">
                      <CheckIcon className="h-4 w-4 text-green-500" />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
          {pollStyle === 'numbered_list' && (
            <ul className="list-decimal list-inside mt-4 ml-4">
              {options.map((option) => (
                <li className="relative truncate" key={`poll-choice-${option.value}`}>
                  <span>{option.value}</span>
                  {option.correct && (
                    <span className="absolute top-1/2 -translate-y-1/2 transform pl-2">
                      <CheckIcon className="h-4 w-4 text-green-500" />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
          {pollStyle === 'unstyled' && (
            <ul className="list-none list-outside mt-4 ml-4">
              {options.map((option) => (
                <li className="relative truncate" key={`poll-choice-${option.value}`}>
                  <span>{option.value}</span>
                  {option.correct && (
                    <span className="absolute top-1/2 -translate-y-1/2 transform pl-2">
                      <CheckIcon className="h-4 w-4 text-green-500" />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
          {pollStyle === 'inline' && (
            <span className="inline">
              {options.map((option, idx) => (
                <span className="relative truncate" key={`poll-choice-${option.value}`}>
                  <span className="pr-4">{option.value}</span>
                  {option.correct && (
                    <span className="absolute top-1/2 -translate-y-1/2 transform -ml-4">
                      <CheckIcon className="h-4 w-4 text-green-500" />
                    </span>
                  )}
                  {choices.length !== idx + 1 && <span className="pr-4">|</span>}
                </span>
              ))}
            </span>
          )}
        </>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {orientation === PollOrientation.VERTICAL ? (
            <ul className={cx('list-outside mt-4', LIST_CLASS_MAP[listStyle])}>
              {choices.map((choice, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <span className="text-gray-800">{choice}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="mt-4">
              {choices.map((choice, index) => (
                <>
                  <span key={choice}>{choice}</span>
                  {index !== choices.length - 1 && <>&nbsp;|&nbsp;</>}
                </>
              ))}
            </p>
          )}
        </>
      )}
      <p className="absolute top-2 right-3 text-xs text-gray-400">{labelText || 'Preview'}</p>
      {redirectUrl && (
        <p className="text-xs text-gray-500 mt-4 truncate">
          On complete, redirect to{' '}
          <a className="text-gray-700" href={redirectUrl}>
            {simplifiedRedirectUrl}
          </a>
        </p>
      )}
    </div>
  );
};

export default Preview;
