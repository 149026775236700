import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useDeleteLandingPageTestimominal } from '@/hooks/useLandingPages';
import { useDeleteUpgradePageTestimominal } from '@/hooks/useUpgradePages';
import { LandingPage } from '@/interfaces/landing_page';
import { UpgradePage } from '@/interfaces/upgrade_page';
import UpgradeSelectTestimonial from '@/pages/DesignLab/UpgradePage/TestimonialsSection/SelectTestimonial';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import SelectTestimonial from './SelectTestimonial';
import TestimonialForm from './TestimonialForm';
import Testimonials from './Testimonials';

interface Props {
  handleSave: (payload: any) => void;
  page: LandingPage | UpgradePage | any;
  type: 'landing_page' | 'upgrade_page';
  onSave: () => void;
}

const TestimonialsForm = ({ page, handleSave, type, onSave }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const [isSavingTestimonial, setIsSavingTestimonial] = useState(false);
  const [testimonialToEdit, setTestimonialToEdit] = useState<any>(null);
  const [testimonialToRemove, setTestimonialToRemove] = useState<any>('');
  const [isAdding, setIsAdding] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const isUpgradePage = type === 'upgrade_page';

  const testimonials = isUpgradePage ? page?.upgrade_page_testimonials : page?.landing_page_testimonials;

  const handleRevalidate = () => {
    if (isUpgradePage) {
      queryClient.invalidateQueries(['upgrade_page', currentPublicationId, 'draft']);
    } else {
      queryClient.invalidateQueries(['landing_page', '/subscribe', 'draft']);
    }
  };

  const deleteLandingPageTestimonial = useDeleteLandingPageTestimominal({
    landingPageTestimonialId: testimonialToRemove,
    onSuccess: () => {
      queryClient.invalidateQueries(['landing_page', '/subscribe', 'draft']);
      queryClient.invalidateQueries(['testimonials', currentPublicationId]);
      toast.success('Deleted!');
      setTestimonialToRemove('');
      onSave();
    },
  });
  const deleteUpgradePageTestimonial = useDeleteUpgradePageTestimominal({
    upgradePageTestimonialId: testimonialToRemove,
    onSuccess: () => {
      queryClient.invalidateQueries(['upgrade_page', currentPublicationId, 'draft']);
      queryClient.invalidateQueries(['testimonials', currentPublicationId]);
      toast.success('Deleted!');
      setTestimonialToRemove('');
      onSave();
    },
  });
  const handleDelete = () => {
    if (isUpgradePage) {
      deleteUpgradePageTestimonial.mutate();
    } else {
      deleteLandingPageTestimonial.mutate();
    }
  };

  const handleAddTestimonial = (payload: any): void => {
    setIsSavingTestimonial(true);
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('testimonial[name]', payload.name);
    formData.append('testimonial[description]', payload.description);
    formData.append('testimonial[message]', payload.message);
    formData.append('testimonial[picture]', payload.picture);

    if (isUpgradePage) {
      formData.append('upgrade_page_id', page?.id || '');
    } else {
      formData.append('landing_page_id', page?.id || '');
    }

    api
      .post('testimonials', formData)
      .then(() => {
        handleRevalidate();
        toast.success(`Created!`);
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast(error);
      })
      .finally(() => {
        setIsSavingTestimonial(false);
        setIsAdding(false);
        onSave();
      });
  };

  const handleUpdateTestimonial = (payload: any): void => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('testimonial[name]', payload.name);
    formData.append('testimonial[description]', payload.description);
    formData.append('testimonial[message]', payload.message);

    if (typeof payload.picture !== 'string') {
      formData.append('testimonial[picture]', payload.picture);
    }

    api
      .patch(`/testimonials/${testimonialToEdit?.testimonial_id}`, formData)
      .then(() => {
        handleRevalidate();
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast(error);
      })
      .finally(() => {
        setIsSaving(false);
        setTestimonialToEdit(null);
        onSave();
      });
  };

  return (
    <div className="flex flex-col space-y-0.5">
      <Testimonials
        testimonialsOrder={page?.testimonials_section?.testimonials || []}
        testimonials={testimonials || []}
        setTestimonialToRemove={setTestimonialToRemove}
        setTestimonialToEdit={setTestimonialToEdit}
        page={page}
        handleSave={handleSave}
      />
      <TestimonialForm
        headerText="Edit Testimonial"
        ctaText="Update"
        isOpen={Boolean(testimonialToEdit)}
        testimonial={testimonialToEdit}
        onClose={() => setTestimonialToEdit(null)}
        isProcessing={isSaving}
        onProceed={handleUpdateTestimonial}
      />
      <TestimonialForm
        headerText="Add Testimonial"
        ctaText="Create"
        isOpen={isAdding}
        testimonial={null}
        onClose={() => setIsAdding(false)}
        isProcessing={isSavingTestimonial}
        onProceed={handleAddTestimonial}
      />
      <ActionModal
        isOpen={Boolean(testimonialToRemove)}
        onClose={() => setTestimonialToRemove('')}
        onProceed={handleDelete}
        resourceId=""
        isWorking={deleteLandingPageTestimonial.isLoading}
        headerText="Remove from page?"
        actionText="Remove"
        buttonType="danger"
      >
        <div className="space-y-2 text-sm">
          Don&apos;t worry, this testimonial will not be deleted. It will still exist, it will just be removed from the
          page. Are you sure you want to continue? You can always add it back.
        </div>
      </ActionModal>
      <div className="flex items-center space-x-1">
        <div>
          <Button variant="primary-inverse" size="xs" onClick={() => setIsAdding(true)} loading={false}>
            Add
          </Button>
        </div>
        {isUpgradePage ? (
          <UpgradeSelectTestimonial
            onSave={onSave}
            upgradePageId={page?.id || ''}
            upgradePageTestimonialIds={
              page?.upgrade_page_testimonials?.map((testimonial: any) => testimonial?.testimonial_id) || []
            }
          />
        ) : (
          <SelectTestimonial
            onSave={onSave}
            landingPageId={page?.id || ''}
            landingPageTestimonialIds={testimonials?.map((testimonial: any) => testimonial?.testimonial_id) || []}
          />
        )}
      </div>
    </div>
  );
};

export default TestimonialsForm;
