import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  publicationId: string;
  organizationId: string;
  planPriceId: string;
}

export interface StripePromotionCode {
  code: string;
  description: string;
  amount_off?: number;
  percent_off?: number;
  duration: 'once' | 'forever' | 'repeating';
  duration_in_months?: number;
}

const useApplyPromotionCode = ({ organizationId, publicationId, planPriceId }: Props) => {
  return useMutation<StripePromotionCode, unknown, { promoCode?: string }>(({ promoCode }) =>
    api
      .post(`/organizations/${organizationId}/promotion_codes`, {
        publication_id: publicationId,
        promo_code: promoCode,
        plan_price_id: planPriceId,
      })
      .then((res) => res.data)
  );
};

export default useApplyPromotionCode;
