import { Fragment, ReactNode } from 'react';

import { useSettings } from '@/context/settings-context';
import usePostEmailClickStats from '@/hooks/usePostEmailClickStats';
import usePostSendAndOpenStats from '@/hooks/usePostSendAndOpenStats';
import usePostWebStats from '@/hooks/usePostWebStats';
import { PostStatsOverview } from '@/interfaces/post';
import { clickRate, openRate } from '@/utils/engagement/calculations';

interface Props {
  postId: string;
  isPremiumEnabled: boolean;
  staleTime?: number;
  children: (stats: PostStatsOverview) => ReactNode;
}

const PostStatsOverviewContainer = ({ postId, isPremiumEnabled, staleTime = 1000 * 60, children }: Props) => {
  const {
    data: postWebStatsData,
    isLoading: isLoadingPostWebStats,
    isError: isErrorPostWebStats,
    isSuccess: isSuccessPostWebWebStats,
  } = usePostWebStats({ id: postId, staleTime });
  const {
    data: postEmailClickStatsData,
    isLoading: isLoadingPostEmailClickStats,
    isError: isErrorPostEmailClickStats,
    isSuccess: isSuccessPostEmailClickStats,
  } = usePostEmailClickStats({ id: postId, staleTime });
  const {
    data: postSendAndOpenStatsData,
    isLoading: isLoadingPostSendAndOpenStats,
    isError: isErrorPostSendAndOpenStats,
    isSuccess: isSuccessPostSendAndOpenStats,
  } = usePostSendAndOpenStats({ id: postId, staleTime });

  const isLoadingAllData = isLoadingPostWebStats || isLoadingPostEmailClickStats || isLoadingPostSendAndOpenStats;
  const hasErrorLoadingData = isErrorPostWebStats || isErrorPostEmailClickStats || isErrorPostSendAndOpenStats;
  const { settings } = useSettings();

  if (isLoadingAllData || hasErrorLoadingData) {
    return (
      <Fragment key={`post-stats-overview-container-${postId}`}>
        {children({
          click_rate: 0,
          open_rate: 0,
          total_sent: 0,
          total_upgrades: undefined,
          total_web_viewed: 0,
          total_unique_web_clicked: 0,
        })}
      </Fragment>
    );
  }

  if (!isSuccessPostWebWebStats || !isSuccessPostEmailClickStats || !isSuccessPostSendAndOpenStats) return null;
  const totalClicked = settings?.verified_clicks_v2 ? postEmailClickStatsData.total_unique_clicked : postEmailClickStatsData.total_unique_verified_clicked

  return (
    <Fragment key={`post-stats-overview-container-${postId}`}>
      {children({
        click_rate: clickRate({
          totalClicked,
          totalViewed: postSendAndOpenStatsData.total_unique_opened,
        }),
        open_rate: openRate({
          totalDelivered: postSendAndOpenStatsData.total_delivered,
          totalUniqueOpened: postSendAndOpenStatsData.total_unique_opened,
        }),
        total_sent: postSendAndOpenStatsData.total_sent,
        total_upgrades: isPremiumEnabled ? postWebStatsData.total_upgrades : undefined,
        total_web_viewed: postWebStatsData.total_web_viewed,
        total_unique_web_clicked: postWebStatsData.total_unique_web_clicked,
      })}
    </Fragment>
  );
};

export default PostStatsOverviewContainer;

