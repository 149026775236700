import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { SitePackage } from '@/interfaces/site_package';

import api from '../../services/swarm';

export default function useSitePackage({ sitePackageId }: { sitePackageId?: string | null }) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchSitePackage = () =>
    api
      .get(`/site_packages/${sitePackageId}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<SitePackage>({
    queryKey: ['publications', currentPublicationId, 'site_packages', sitePackageId],
    queryFn: fetchSitePackage,
    enabled: !!sitePackageId,
  });
}
