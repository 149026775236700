import moment from 'moment-mini';

import { TimePeriod } from '@/interfaces/time_period';

const getTimePeriodStartDate = (timePeriod: TimePeriod) => {
  if (timePeriod === TimePeriod.LAST_24_HOURS) {
    return moment().subtract(24, 'hours').startOf('day').format('DD MMM YYYY');
  }
  if (timePeriod === TimePeriod.LAST_7_DAYS) {
    return moment().subtract(7, 'days').startOf('day').format('DD MMM YYYY');
  }
  if (timePeriod === TimePeriod.LAST_4_WEEKS) {
    return moment().subtract(4, 'weeks').startOf('day').format('DD MMM YYYY');
  }
  if (timePeriod === TimePeriod.LAST_12_MONTHS) {
    return moment().subtract(12, 'months').startOf('day').format('DD MMM YYYY');
  }
  if (timePeriod === TimePeriod.MONTH_TO_DATE) {
    return moment().startOf('month').format('DD MMM YYYY');
  }
  if (timePeriod === TimePeriod.QUARTER_TO_DATE) {
    return moment().startOf('quarter').format('DD MMM YYYY');
  }
  if (timePeriod === TimePeriod.YEAR_TO_DATE) {
    return moment().startOf('year').format('DD MMM YYYY');
  }

  return null;
};

export default getTimePeriodStartDate;
