import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { NavGroup } from '../../interfaces/nav_group';
import api from '../../services/swarm';

const useNavGroups = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<NavGroup[]>(
    ['nav_groups', currentPublicationId],
    () =>
      api
        .get(`/nav_groups`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!currentPublicationId,
    }
  );
};

export default useNavGroups;
