import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { ApplicationScores } from '@/interfaces/boosts/grow/application_scores';
import { Score, SCORE_HEIGHT_CLASSNAME, SCORE_WIDTH_CLASSNAME, ScoreValue } from '@/ui/Score';

import { applicationScoresInsufficientData } from '../../utils/applicationScoresInsufficientData';
import { SCORE_ENGAGEMENT_LABELS, SCORE_GROWTH_LABELS, SCORE_QUALITY_LABELS, SCORE_SIZE_LABELS } from '../constants';

interface BoostApplicationScoresProps {
  publicationId: string;
  scores?: ApplicationScores;
  isLoading: boolean;
}

const BoostApplicationScores = ({ publicationId, scores, isLoading }: BoostApplicationScoresProps) => {
  const insufficientData = !scores || scores && applicationScoresInsufficientData(scores);

  const items = [
    {
      type: 'Size',
      labels: SCORE_SIZE_LABELS,
      score: !insufficientData ? scores?.size || 0 : 0 as ScoreValue
    },
    {
      type: 'Growth',
      labels: SCORE_GROWTH_LABELS,
      score: !insufficientData ? scores?.growth || 0 : 0 as ScoreValue
    },
    {
      type: 'Engagement',
      labels: SCORE_ENGAGEMENT_LABELS,
      score: !insufficientData ? scores?.engagement || 0 : 0 as ScoreValue
    },
    {
      type: 'Boost Quality',
      labels: SCORE_QUALITY_LABELS,
      score: !insufficientData ? scores?.referral || 0 : 0 as ScoreValue
    }
  ];

  return (
    <SkeletonLoader
      isLoading={isLoading}
      skeletons={
        <div className="w-fit grid grid-cols-2 items-start gap-4 md:grid-cols-4">
          <div className={SCORE_WIDTH_CLASSNAME}>
            <Skeleton className={SCORE_HEIGHT_CLASSNAME} />
          </div>
          <div className={SCORE_WIDTH_CLASSNAME}>
            <Skeleton className={SCORE_HEIGHT_CLASSNAME} />
          </div>
          <div className={SCORE_WIDTH_CLASSNAME}>
            <Skeleton className={SCORE_HEIGHT_CLASSNAME} />
          </div>
          <div className={SCORE_WIDTH_CLASSNAME}>
            <Skeleton className={SCORE_HEIGHT_CLASSNAME} />
          </div>
        </div>
      }
    >
      <div className="w-fit grid grid-cols-2 items-start gap-4 md:grid-cols-4">
        {items.map((item) => (
          <Score
            key={`${publicationId}-score-${item.type}`}
            publicationId={publicationId}
            type={item.type}
            labels={item.labels}
            score={item.score}
          />
        ))}
      </div>
    </SkeletonLoader>
  );
};

export default BoostApplicationScores;
