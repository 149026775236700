import KPI from '@/components/KPI';
import KPIGroup from '@/components/KPIGroup';
import { usePageContext } from '@/components/Layout/PageLayout/PageContext';
import { useOverview } from '@/hooks/useDashboard';
import { OverviewEndpoints } from '@/hooks/useDashboard/useOverview';
import { EngagementKPIs } from '@/interfaces/dashboard';
import { TimePeriod } from '@/interfaces/time_period';

import getLocaleString from '../../../utils/getLocaleString';
import { PageProps } from '../types';
import getNormalizedTimePeriod from '../utils/getNormalizedTimePeriod';

const KPIs = () => {
  const { period, isSubscriptionDataLoading, subscriptionKPIs } = usePageContext<PageProps>();
  const isAllTime = period === TimePeriod.ALL_TIME;

  // Engagement KPIs
  const { isLoading: isLoadingEngagementKPI, data: engagementKPIData } = useOverview({
    endpoint: OverviewEndpoints.ENGAGEMENT_KPIS,
    timePeriod: period,
  });
  const engagementKPIs: EngagementKPIs | null = engagementKPIData?.engagement_kpis;

  const normalizeTimePeriod = getNormalizedTimePeriod(period);

  return (
    <KPIGroup>
      <KPI
        title="Active Subscribers"
        isLoading={isSubscriptionDataLoading}
        pctDelta={subscriptionKPIs ? subscriptionKPIs?.active_subscriptions?.delta : 0}
        value={getLocaleString(subscriptionKPIs ? subscriptionKPIs?.active_subscriptions?.period : 0)}
        isAllTime={isAllTime}
        previousValue={`${getLocaleString(
          subscriptionKPIs ? subscriptionKPIs?.active_subscriptions?.previous_period : 0
        )} ${normalizeTimePeriod}`}
      />
      <KPI
        title="Open Rate"
        isLoading={isLoadingEngagementKPI}
        pctDelta={engagementKPIs ? engagementKPIs?.open_rate_delta : 0}
        value={`${engagementKPIs ? engagementKPIs?.open_rate : '0'}%`}
        isAllTime={isAllTime}
        previousValue={`${engagementKPIs ? engagementKPIs?.lag_open_rate : 0}% ${normalizeTimePeriod}`}
      />
      <KPI
        title="Click Through Rate"
        isLoading={isLoadingEngagementKPI}
        pctDelta={engagementKPIs ? engagementKPIs?.click_rate_delta : 0}
        value={`${engagementKPIs ? engagementKPIs?.click_rate : '0'}%`}
        isAllTime={isAllTime}
        previousValue={`${engagementKPIs ? engagementKPIs?.lag_click_rate : 0}% ${normalizeTimePeriod}`}
      />
    </KPIGroup>
  );
};

export default KPIs;
