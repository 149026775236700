import { Lock } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import { ColorSettings } from '../ColorSettings';
import { TransformSettings } from '../TransformSettings';

type Props = AttributeSettingProps & {};

export const PremiumPostSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <PopoverHelper title="Premium" text="Premium" popoverTitle="Premium" Icon={Lock}>
      <>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="premiumIconEnabled"
          title="Icon"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="premiumTextEnabled"
          title="Text"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="premiumBadgeEnabled"
          title="Badge"
        />
        <ColorSettings
          title="Color"
          property="premiumTextColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
          initialColor={activeNodeAttributes?.premiumTextColor}
        />
        {activeNodeAttributes?.premiumBadgeEnabled && (
          <ColorSettings
            title="Background"
            property="premiumBackgroundColor"
            editor={editor}
            activeNodeResult={activeNodeResult}
            initialColor={activeNodeAttributes?.premiumBackgroundColor}
          />
        )}
        {activeNodeAttributes?.premiumTextEnabled && (
          <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="premiumTextTransform" />
        )}
      </>
    </PopoverHelper>
  );
};

export default PremiumPostSettings;
