import { UserIcon } from '@heroicons/react/20/solid';

interface AvatarProps {
  src?: string;
}

const Avatar: React.FC<AvatarProps> = ({ src }: AvatarProps) => (
  <div className="flex justify-center items-center h-14 w-14 rounded-full overflow-hidden bg-gray-100">
    {src ? (
      <img className="inline-block h-14 w-14 rounded-full ring-2 ring-white" src={src} alt="subscriber-avatar" />
    ) : (
      <UserIcon className="h-8 w-8 text-gray-400" />
    )}
  </div>
);

export default Avatar;
