import { useCallback, useEffect, useState } from 'react';
import { CoinVertical, Link as LinkIcon, LinkBreak } from '@phosphor-icons/react';
import classNames from 'classnames';

import { cn } from '../../../../_utils/cn';
import { ColorPicker } from '../../../UI/ColorPicker';
import { SimpleInputWrapper } from '../../../UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';

import GradientSettings from './GradientSettings';
import { TokenizeColorSettings } from './TokenizeColorSettings';

type Props = AttributeSettingProps & {
  title: string;
  property: string;
  onOverrideSetColor?: (value: string | null) => void;
  properties?: string[];
  initialColor?: string | null;
  onUnGroup?: () => void;
  hasGradientPicker?: boolean;
  isFontGradient?: boolean;
  gradientProperty?: string;
  // if true, will set the color as a mark style instead of a node attribute
  isMarkStyle?: boolean;
  disabled?: boolean;
  isUngroupable?: boolean;
};

const DEFAULT_COLOR = '#000000';

const reformatColor = (color?: string) => {
  if (!color) return undefined;

  if (color.length === 9 && color.endsWith('FF')) {
    return color.slice(0, 7);
  }

  if (color.length === 4) {
    return `#${color.replace('#', '')}${color.replace('#', '')}`;
  }

  return color;
};

export const ColorSettings = ({
  editor,
  title,
  property,
  properties,
  activeNodeResult,
  onOverrideSetColor,
  initialColor,
  isMarkStyle = false,
  onUnGroup,
  isUngroupable = true,
  hasGradientPicker = false,
  isFontGradient = false,
  gradientProperty = 'background',
  disabled,
}: Props) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const [color, setColor] = useState<string | undefined>(initialColor || undefined);
  const [pickerType, setPickerType] = useState<'color' | 'gradient'>('color');
  const isTokenApplied = activeNodeAttributes?.tokens?.[property] || editor.getAttributes('textStyle')?.tokens?.color;
  const hasTokenAttribute = activeNodeAttributes?.tokens || editor.getAttributes('textStyle')?.tokens?.color;

  const onSetColor = useCallback(
    (value: string | null) => {
      if (isMarkStyle) {
        const isLinkActive = editor.isActive('link');

        if (isLinkActive) {
          editor.chain().extendMarkRange('link').updateAttributes('link', { color: value }).focus().run();
          return;
        }

        if (value) {
          editor?.chain().selectTextBlock().unsetFontGradient().setColor(value).focus().run();
        } else {
          editor?.chain().selectTextBlock().unsetColor().focus().run();
        }

        return;
      }

      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        // This is in tandem with ColorGroupSettings, where we set multiple properties at once
        if (properties) {
          properties.forEach((propertyType: string) => {
            tr.setNodeAttribute(activeNodePos, propertyType, value);
          });
        } else {
          tr.setNodeAttribute(activeNodePos, property, value);
        }
        return true;
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property, isMarkStyle]
  );

  useEffect(() => {
    let c: string;
    if (isMarkStyle) {
      const textStyleColor = editor.getAttributes('textStyle').color;
      c = textStyleColor || DEFAULT_COLOR;
    } else {
      c = activeNodeAttributes[property];
    }

    setColor(reformatColor(c));
  }, [activeNodeAttributes, editor, property, isMarkStyle]);

  const onReset = useCallback(() => {
    setColor(DEFAULT_COLOR);
    onSetColor(null);
  }, [onSetColor]);

  return (
    <Popover>
      <div
        className={cn(
          'flex items-center justify-stretch gap-2 select-none',
          disabled ? 'opacity-50 pointer-events-none' : ''
        )}
      >
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <PopoverTrigger asChild>
          <SimpleInputWrapper className="justify-start relative">
            {isTokenApplied && (
              <div className="p-0.5 border rounded-full absolute -left-3 -top-2 bg-wb-accent-soft text-wb-accent border-wb-accent">
                <CoinVertical className="w-2 h-2" />
              </div>
            )}
            {onUnGroup && (
              <button
                type="button"
                className={classNames(
                  'absolute -left-5 top-2.5 hover:cursor-pointer text-wb-secondary transition-all',
                  isUngroupable && 'hover:text-wb-primary'
                )}
                onClick={onUnGroup}
              >
                {isUngroupable ? (
                  <LinkBreak className="w-4 h-4" />
                ) : (
                  <Tooltip center="Sync color changes">
                    <LinkIcon className="w-4 h-4" />
                  </Tooltip>
                )}
              </button>
            )}
            {color ? (
              <div className="w-4 h-4 rounded-md border border-border box-content" style={{ backgroundColor: color }} />
            ) : null}
            <Text size="2xs" weight="medium" className="">
              {color?.toUpperCase() || 'Inherit'}
            </Text>
          </SimpleInputWrapper>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[280px] p-0" align="end" side="left" sideOffset={110}>
        <div className="max-h-[700px] overflow-y-auto p-3 flex flex-col gap-4">
          {hasGradientPicker && (
            <div className="w-full flex flex-col gap-2">
              <div className="grow flex items-center">
                <SimpleInputWrapper className="h-[38px] px-1">
                  <ToggleGroup
                    className="py-[1px] w-full"
                    type="single"
                    defaultValue={pickerType}
                    onValueChange={(type) => setPickerType(type as 'color' | 'gradient')}
                  >
                    <ToggleGroupItem asChild value="color">
                      <Text variant="inherit" size="2xs" weight="medium" className="grow">
                        Color
                      </Text>
                    </ToggleGroupItem>
                    <ToggleGroupItem asChild value="gradient">
                      <Text variant="inherit" size="2xs" weight="medium" className="grow">
                        Gradient
                      </Text>
                    </ToggleGroupItem>
                  </ToggleGroup>
                </SimpleInputWrapper>
              </div>
            </div>
          )}
          {hasGradientPicker && pickerType === 'gradient' && (
            <GradientSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property={gradientProperty}
              isFontGradient={isFontGradient}
            />
          )}
          {(pickerType === 'color' || !hasGradientPicker) && (
            <ColorPicker
              key={activeNodeAttributes.id}
              selectedColor={color}
              onSetColor={(value: string | null) => {
                setColor(value || DEFAULT_COLOR);

                // Used as an escape hatch to prevent this from submitting if it's consumed by a parent component like in BorderSettings
                if (onOverrideSetColor) {
                  onOverrideSetColor(value);
                } else {
                  onSetColor(value);
                }
              }}
              onReset={onReset}
            />
          )}

          {hasTokenAttribute && (
            <TokenizeColorSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property={property}
              title={title}
              isMarkStyle={isMarkStyle}
              color={color}
            />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
