/**
 * taken from:
 * @see https://github.com/Doist/typist/blob/main/src/extensions/rich-text/rich-text-link.ts
 *
 * @see https://github.com/ueberdosis/tiptap/discussions/1865
 */
//

import { InputRule, markInputRule, markPasteRule, PasteRule } from '@tiptap/core';

export const markdownInputRegex = /(?:^|\s)\[([^\]]*)?\]\((\S+)(?: ["“](.+)["”])?\)$/i;

export const markdownPasteRegex = /(?:^|\s)\[([^\]]*)?\]\((\S+)(?: ["“](.+)["”])?\)/gi;

export const linkInputRule = (config: Parameters<typeof markInputRule>[0]) => {
  const defaultMarkInputRule = markInputRule(config);

  return new InputRule({
    find: config.find,
    handler(props) {
      const { tr } = props.state;

      defaultMarkInputRule.handler(props);
      tr.setMeta('preventAutolink', true);
    },
  });
};

export const linkPasteRule = (config: Parameters<typeof markPasteRule>[0]) => {
  const defaultMarkPasteRule = markPasteRule(config);

  return new PasteRule({
    find: config.find,
    handler(props) {
      const { tr } = props.state;

      defaultMarkPasteRule.handler(props);
      tr.setMeta('preventAutolink', true);
    },
  });
};
