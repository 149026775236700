import { Extension, InputRule, JSONContent } from '@tiptap/core';
import { v4 as uuidV4 } from 'uuid';

import { FootnoteItem } from './FootnoteItem';
import { FootnoteMarker } from './FootnoteMarker';
import { FootnotesNode } from './FootnotesNode';
import { debouncedProcessFootnotes, processFootnotes } from './utils';

const insertNewFootnoteRegex = /\^\^/;

const insertFootnoteMarkerRegex = /\^(\d+)/;

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    footnotesKit: {
      insertFootnote: (id?: string) => ReturnType;
      focusFootnoteItem: (id: string) => ReturnType;
      focusFootnoteMarker: (id: string) => ReturnType;
      removeFootnoteItemAndMarker: (id: string) => ReturnType;
      toggleFootnote: () => ReturnType;
    };
  }
}

interface FootnotesStorage {
  footnotesData: Record<string, { order: number; footnoteText: string }>;
}

export const FootnotesKit = Extension.create<{}, FootnotesStorage>({
  name: 'footnotesKit',

  addExtensions() {
    return [FootnoteItem, FootnoteMarker, FootnotesNode];
  },

  addStorage() {
    return {
      footnotesData: {},
    };
  },

  onCreate() {
    setTimeout(() => {
      const { editor } = this;
      processFootnotes({ editor });
    });
  },

  onUpdate() {
    const { editor } = this;
    debouncedProcessFootnotes({ editor });
  },

  addCommands() {
    return {
      insertFootnote:
        (id) =>
        ({ chain, state: { doc } }) => {
          let footnotesNodeExists = false;
          let existingFootnoteEndPos = -1;
          let footnoteOrder = 1;

          doc.descendants((node, pos) => {
            switch (node.type.name) {
              case 'section':
                return true;
              case 'footnotesNode':
                footnotesNodeExists = true;
                existingFootnoteEndPos = pos + node.content.size + 1;
                footnoteOrder = node.childCount + 1;
                return false;
              default:
                return false;
            }
          });

          const footnoteItem: JSONContent = {
            type: 'footnoteItem',
            attrs: {
              order: footnoteOrder,
              footnoteId: id || `b-${uuidV4()}`,
            },
          };

          const commandChain = chain();

          if (footnotesNodeExists && existingFootnoteEndPos > 0) {
            return commandChain.insertContentAt(existingFootnoteEndPos, footnoteItem).run();
          }

          const footnotesNode: JSONContent = {
            type: 'footnotesNode',
            content: [footnoteItem],
          };

          return commandChain.insertContentAt(doc.content.size, footnotesNode).run();
        },
      focusFootnoteItem:
        (id) =>
        ({ state, commands }) => {
          let posToFocus = -1;

          state.doc.descendants((node, pos) => {
            switch (node.type.name) {
              case 'section':
                return true;
              case 'footnotesNode':
                node.descendants((child, childPos) => {
                  if (child.attrs.footnoteId === id) posToFocus = childPos + pos + 2;
                });

                return true;
              default:
                return false;
            }
          });

          if (posToFocus < 0) return commands.focus();

          return commands.focus(posToFocus);
        },
      focusFootnoteMarker:
        (id) =>
        ({ state, commands }) => {
          let posToFocus = -1;

          state.doc.descendants((node, pos) => {
            if (node.type.name !== 'footnoteMarker') return;

            if (node.attrs.footnoteId === id) posToFocus = pos;
          });

          if (posToFocus < 0) return false;

          return commands.focus(posToFocus + 1);
        },
      removeFootnoteItemAndMarker:
        (id) =>
        ({ tr, dispatch }) => {
          tr.doc.descendants((node, pos) => {
            if (['footnoteMarker', 'footnoteItem'].includes(node.type.name) && node.attrs.footnoteId === id) {
              tr.deleteRange(tr.mapping.map(pos), tr.mapping.map(pos + node.nodeSize));
            }
          });

          return dispatch?.(tr);
        },
    };
  },

  addInputRules() {
    const getStorage = () => this.storage;
    const { schema } = this.editor.state;

    return [
      new InputRule({
        find: insertNewFootnoteRegex,
        handler: ({ range, chain, match }) => {
          if (!match[0]) return;

          const newFootnoteItemId = `b-${uuidV4()}`;

          chain()
            .command(({ tr, dispatch }) => {
              const start = range.from;
              const end = range.to;

              tr.delete(tr.mapping.map(start), tr.mapping.map(end));

              return dispatch?.(tr);
            })
            .insertFootnote(newFootnoteItemId)
            .command(({ tr, dispatch }) => {
              const footnoteMarkerContent: JSONContent[] = [
                {
                  type: 'footnoteMarker',
                  attrs: {
                    footnoteId: newFootnoteItemId,
                  },
                },
              ];

              tr.insert(
                range.from,
                footnoteMarkerContent.map((n) => schema.nodeFromJSON(n))
              );

              return dispatch?.(tr);
            })
            .run();
        },
      }),
      new InputRule({
        find: insertFootnoteMarkerRegex,
        handler: ({ range, chain, match }) => {
          const order = parseInt(match[1], 10);

          let footnoteId = '';

          Object.keys(getStorage().footnotesData).forEach((id) => {
            if (getStorage().footnotesData[id].order === order) footnoteId = id;
          });

          const footnoteMarkerContent: JSONContent[] = [
            {
              type: 'footnoteMarker',
              attrs: {
                order,
                footnoteId,
              },
            },
          ];

          chain()
            .command(({ tr, dispatch }) => {
              tr.delete(range.from, range.to).insert(
                range.from,
                footnoteMarkerContent.map((n) => schema.nodeFromJSON(n))
              );

              return dispatch?.(tr);
            })
            .run();
        },
      }),
    ];
  },
});
