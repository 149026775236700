import { XMarkIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip';
import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';
import { Author } from '@/interfaces/author';

import { usePostContext } from '../../PostContext';
import AuthorsTooltipMenu from '../AuthorsTooltipMenu';

import AuthorsActionsMenu from './AuthorsActionsMenu';
import VisibilitySettings from './VisibilitySettings';

interface Props {
  users: Author[];
  guestAuthors: Author[];
  setShowCreateGuestAuthorModal: (value: boolean) => void;
}

const AuthorsMenu = ({ users, guestAuthors, setShowCreateGuestAuthorModal }: Props) => {
  const { formData, onChange } = usePostContext();
  const currentAuthors = users?.filter((user) => formData?.user_ids.includes(user.id));
  const currentGuestAuthors = guestAuthors?.filter((guestUser) => formData?.guest_author_ids.includes(guestUser.id));

  const deleteAuthor = (type: string, authorId: string) => {
    let newUserIDs = formData?.user_ids || [];
    let newGuestAuthorIDs = formData?.guest_author_ids || [];

    if (type === 'user') {
      newUserIDs = newUserIDs?.filter((id) => id !== authorId);
    }

    if (type === 'guest') {
      newGuestAuthorIDs = newGuestAuthorIDs?.filter((id) => id !== authorId);
    }

    onChange({ user_ids: newUserIDs, guest_author_ids: newGuestAuthorIDs });
  };

  return (
    <div className="relative flex">
      <div className="absolute top-0 left-0 w-full h-full -ml-10 z-0" />
      <div className="absolute -ml-10 z-50">
        <Tippy
          offset={[0, 8]}
          placement="bottom-start"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          trigger="click"
          interactive
          content={
            <div className="absolute">
              <VisibilitySettings />
            </div>
          }
          zIndex={EDITOR_MENUS_Z_INDEX}
        >
          <div>
            <Tooltip title={formData?.display_byline_in_email ? 'Visibility' : 'Hidden in email'}>
              <Button
                $leftSlot={
                  formData?.display_byline_in_email ? (
                    <div className="relative w-4 h-4 mt-[.1rem]">
                      <img src="/images/eye-icon.svg" alt="Eye icon" />
                    </div>
                  ) : (
                    <div className="relative w-4 h-4">
                      <img src="/images/eye-off-icon.svg" alt="Eye icon" />
                    </div>
                  )
                }
                $variant="tertiary"
                $size="small"
                $isIconButton
              />
            </Tooltip>
          </div>
        </Tippy>
      </div>
      <div className="relative flex">
        <ul className="flex w-full">
          {currentAuthors &&
            currentAuthors.length > 0 &&
            currentAuthors?.map((author) => (
              <li data-testid={`author-${author.id}`} key={author.id} className="cursor-pointer">
                <div className="flex items-center justify-center overflow-hidden text-ellipsis border border-1 px-4 py-2 rounded-2xl text-xs font-medium mr-2 text-violet-900 border-violet-300 bg-violet-100">
                  <Tippy
                    offset={[0, 8]}
                    placement="top-start"
                    popperOptions={{
                      modifiers: [{ name: 'flip' }],
                    }}
                    trigger="click"
                    interactive
                    key={`author-${author.id}`}
                    content={
                      <div className="absolute mt-[-3rem]">
                        <AuthorsTooltipMenu
                          userId={author.id}
                          users={users}
                          guestAuthors={guestAuthors}
                          setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
                        />
                      </div>
                    }
                    zIndex={EDITOR_MENUS_Z_INDEX}
                  >
                    <button type="button">{author.name}</button>
                  </Tippy>
                  <button
                    type="button"
                    aria-label="remove"
                    className="-mr-2"
                    onClick={() => {
                      deleteAuthor('user', author.id);
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-1" />
                  </button>
                </div>
              </li>
            ))}
          {currentGuestAuthors &&
            currentGuestAuthors.length > 0 &&
            currentGuestAuthors?.map((guestAuthor) => (
              <li data-testid={`author-${guestAuthor.id}`} key={guestAuthor.id} className="cursor-pointer">
                <div className="flex items-center justify-center overflow-hidden text-ellipsis border border-1 px-4 py-2 rounded-2xl text-xs font-medium mr-2 text-gray-900 border-gray-300 bg-gray-100">
                  <Tippy
                    offset={[0, 8]}
                    placement="top-start"
                    popperOptions={{
                      modifiers: [{ name: 'flip' }],
                    }}
                    key={`guest-author-${guestAuthor.id}`}
                    trigger="click"
                    interactive
                    content={
                      <div className="absolute mt-[-3rem]">
                        <AuthorsTooltipMenu
                          users={users}
                          guestAuthorId={guestAuthor.id}
                          guestAuthors={guestAuthors}
                          setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
                        />
                      </div>
                    }
                    zIndex={EDITOR_MENUS_Z_INDEX}
                  >
                    <button type="button">{guestAuthor.name}</button>
                  </Tippy>
                  <button
                    type="button"
                    aria-label="remove"
                    className="-mr-2"
                    onClick={() => {
                      deleteAuthor('guest', guestAuthor.id);
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-1" />
                  </button>
                </div>
              </li>
            ))}
        </ul>
      </div>
      {(currentAuthors.length > 0 || currentGuestAuthors.length > 0) && (
        <div className="relative mt-1">
          <Tippy
            offset={[0, 8]}
            placement="top-start"
            popperOptions={{
              modifiers: [{ name: 'flip' }],
            }}
            trigger="click"
            interactive
            content={
              <div className="absolute mt-[3rem]">
                <AuthorsActionsMenu
                  guestAuthors={guestAuthors}
                  users={users}
                  hideDelete
                  setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
                />
              </div>
            }
            zIndex={EDITOR_MENUS_Z_INDEX}
          >
            <div>
              <Button $leftSlot={<Icon name="Plus" />} $variant="tertiary" $size="small" $isIconButton />
            </div>
          </Tippy>
        </div>
      )}
    </div>
  );
};

export default AuthorsMenu;
