import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@/context/current-user-context';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { useSettings } from '../../../../../context/settings-context';
import { useCurrentPublication, useOrganization } from '../../../../../hooks';
import useOptions from '../../../../../hooks/useOptions';
import { useApproveAllSubscribers, useUpdatePublication } from '../../../../../hooks/usePublications';
import api from '../../../../../services/swarm';
import { ImageForm, Section, SelectForm, SwitchForm, TagForm } from '../../../Components';
import AddressForm from '../../../Components/AddressForm';
import InputForm from '../../../Components/InputForm';

import { isValidUtmParam } from './isValidUtmParam';
import RequireApprovalModal from './RequireApprovalModal';
import { utmParamValidationFailMessage } from './utmParamValidationFailMessage';
import WelcomeEmail from './WelcomeEmail';

const isValidUrl = (url: string) => {
  if (!url) return true;

  try {
    const check = new URL(url);
    return Boolean(check);
  } catch (_) {
    return false;
  }
};

const General = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { settings } = useSettings();
  const { currentUser } = useCurrentUser();

  const [isRequireApprovalModalOpen, setIsRequireApprovalModalOpen] = useState(false);

  const { data: currentPublication } = useCurrentPublication();
  const currentPublicationId = useCurrentPublicationId();
  const publicationMutation = useUpdatePublication(currentPublicationId);
  const approveAllMutation = useApproveAllSubscribers(currentPublicationId);

  const organizationQuery = useOrganization(currentPublication?.organization_id, currentPublicationId);
  const { organization } = organizationQuery?.data || {};

  // Set Tag Options
  const tags = useOptions(currentPublicationId, 'tags');
  const { data } = tags;
  const options =
    data?.options.map((o: Array<number | string>) => o.map((i: number | string) => String(i))) ||
    ([] as Array<Array<string>>);

  // Set Language Options
  const languages = useOptions(currentPublicationId, 'languages');
  const { data: languageData } = languages;
  const languageOptions =
    languageData?.options.map((option: any) => {
      return { label: option[1], value: option[0] };
    }) || [];
  const canUpdateLanguage = languageOptions.length > 1 && settings?.localization;
  const canUpdateUtmParams = settings?.utm_params;
  const canSetPrivatePub = settings?.private_publications;

  const handleUpdateAddress = (address: any): Promise<any> => {
    const newAddress = {
      id: address.id,
      street_address: address.street,
      city: address.city,
      state: address.state,
      zip_code: address.zip,
      country: address.country,
    };

    return publicationMutation.mutateAsync({ address_attributes: newAddress });
  };

  const handleDeleteAddress = (address: any): Promise<any> => {
    const newAddress = { id: address.id, _destroy: true };

    return publicationMutation.mutateAsync({ address_attributes: newAddress });
  };

  // Image updload helpers
  const handleUpdateLogo = (logo: any): void => {
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('publication[logo]', logo);

    api
      .patch(`/publications/${currentPublicationId}`, formData)
      .then(() => {
        analytics.identify(currentUser?.id, {
          email: currentUser?.email,
        });
        analytics.group(currentPublicationId, {
          logo,
          object_type_id: 1,
        });

        analytics.track('Added a Logo');
        queryClient.invalidateQueries(['publications', currentPublicationId]);
        toast.success(`Logo saved!`);
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast(error);
      });
  };

  const handleUpdateThumbnail = (thumbnail: any): void => {
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('publication[thumbnail]', thumbnail);

    api
      .patch(`/publications/${currentPublicationId}`, formData)
      .then(() => {
        analytics.identify(currentUser?.id, {
          email: currentUser?.email,
        });
        analytics.group(currentPublicationId, {
          thumbnail: `https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,format=auto,onerror=redirect,quality=80/uploads/publication/thumbnail/${currentPublicationId}/${thumbnail.name}`,
          object_type_id: 1,
        });

        analytics.track('Added a Thumbnail');
        queryClient.invalidateQueries(['publications', currentPublicationId]);
        toast.success(`Thumbnail saved!`);
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast(error);
      });
  };

  const handleSubscriberApprovalChange = (val: boolean) => {
    if (currentPublication && currentPublication.require_subscriber_approval) {
      setIsRequireApprovalModalOpen(true);

      return Promise.reject();
    }

    analytics.track('Require Subscriber Approval Toggled on');

    return publicationMutation.mutateAsync({ require_subscriber_approval: val });
  };

  const handleSubscriberApprovalModal = (type: string) => () => {
    switch (type) {
      case 'close':
        setIsRequireApprovalModalOpen(false);
        break;
      case 'proceed':
        approveAllMutation.mutateAsync().then(() => {
          publicationMutation.mutateAsync({ require_subscriber_approval: false });
          setIsRequireApprovalModalOpen(false);
        });

        break;
      default:
    }
  };

  const timezones = useOptions(currentPublicationId, 'timezones');
  const { data: timezoneData } = timezones;
  const timezoneOptions =
    timezoneData?.options.map((option: string) => {
      return { label: option, value: option };
    }) || [];
  const canUpdateTimeZone = timezoneOptions.length > 1;

  return (
    <>
      {currentPublication && (
        <RequireApprovalModal
          publication={currentPublication}
          isOpen={isRequireApprovalModalOpen}
          handleOnClose={handleSubscriberApprovalModal('close')}
          handleOnProceed={handleSubscriberApprovalModal('proceed')}
        />
      )}

      {/* Basic Section */}
      <Section
        title="Basic Info"
        description="Manage your general publication information below."
        scrollToId="basic"
        isLoading={!currentPublication}
      >
        <ImageForm
          label="Logo"
          value={currentPublication?.logo?.url || null}
          onSave={handleUpdateLogo}
          variant="square"
          description="The logo that will appear across your publication."
          imageSizeRecommendation="800px x 800px recommended"
        />
        <ImageForm
          label="Thumbnail"
          value={currentPublication?.thumbnail?.url || null}
          onSave={handleUpdateThumbnail}
          variant="landscape"
          description="The thumbnail that will appear across your publication."
          imageSizeRecommendation="1200px x 630px recommended"
        />
        <InputForm
          label="Publication Name"
          placeholder="My Publication"
          value={currentPublication?.name || ''}
          onSave={(val) => {
            analytics.identify(currentUser?.id, {
              email: currentUser?.email,
            });
            analytics.group(currentPublicationId, {
              name: val,
              object_type_id: 1,
            });

            return publicationMutation.mutateAsync({ name: val });
          }}
          isSaving={publicationMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
        <InputForm
          label="One-line description"
          placeholder="The last publication you'll ever need"
          value={currentPublication?.description || ''}
          onSave={(val) => {
            analytics.identify(currentUser?.id, {
              email: currentUser?.email,
            });
            analytics.group(currentPublicationId, {
              description: val,
              object_type_id: 1,
            });
            return publicationMutation.mutateAsync({ description: val });
          }}
          isSaving={publicationMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
        <InputForm
          label="Reply To Address"
          placeholder={currentPublication?.owner_email || ''}
          value={currentPublication?.email_reply_to_address || ''}
          helperText="Replies to all emails will be sent to this address."
          onSave={(val) => {
            analytics.identify(currentUser?.id, {
              email: currentUser?.email,
            });
            analytics.group(currentPublicationId, {
              email_reply_to_address: val,
              object_type_id: 1,
            });

            analytics.track('Set up Reply to Address');
            return publicationMutation.mutateAsync({ email_reply_to_address: val });
          }}
          isSaving={publicationMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
        <AddressForm
          label="Address"
          id={currentPublication?.address?.id || ''}
          street={currentPublication?.address?.street_address || ''}
          country={currentPublication?.address?.country || ''}
          countryName={currentPublication?.address?.country_name || ''}
          zip={currentPublication?.address?.zip_code || ''}
          state={currentPublication?.address?.state || ''}
          city={currentPublication?.address?.city || ''}
          onSave={handleUpdateAddress}
          onDelete={handleDeleteAddress}
          isSaving={publicationMutation.isLoading}
        />
        {canUpdateLanguage && (
          <SelectForm
            label="Default Language"
            description="Choose the default language for your publication."
            options={languageOptions}
            value={
              languageOptions.find((option: any) => option.value === currentPublication?.language) || {
                label: 'English',
                value: 'en',
              }
            }
            onSave={(val: Option) => {
              analytics.track('Default Language Selected');
              return publicationMutation.mutateAsync({ language: val.value });
            }}
            toastSuccessMessage="Saved!"
          />
        )}
        {canUpdateTimeZone && (
          <SelectForm
            label="Timezone"
            description="Choose the timezone for your publication."
            options={timezoneOptions}
            value={
              timezoneOptions.find((option: any) => option.value === currentPublication?.time_zone) || {
                label: 'Eastern Time (US & Canada)',
                value: 'Eastern Time (US & Canada)',
              }
            }
            onSave={(val: Option) => {
              analytics.track('Selected Timezone');
              return publicationMutation.mutateAsync({ time_zone: val.value });
            }}
            toastSuccessMessage="Saved!"
          />
        )}
        <InputForm
          label="Subscribe Button Text"
          placeholder="Subscribe"
          value={currentPublication?.cta_text || ''}
          helperText="This will be displayed on all of your publication's subscribe forms."
          onSave={(val) => publicationMutation.mutateAsync({ cta_text: val })}
          isSaving={publicationMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
        <TagForm
          name="tags"
          label="Tags"
          description="Choose up to 3 tags to describe your publication"
          options={options || []}
          currentSelections={currentPublication?.tags?.map((tag: any) => String(tag?.id)) || []}
          isSaving={publicationMutation.isLoading}
          onSave={(val) => {
            analytics.track('Choose tags');
            return publicationMutation.mutateAsync({ tag_ids: val });
          }}
          toastSuccessMessage="Saved!"
        />
        {canSetPrivatePub && (
          <>
            <SwitchForm
              name="private"
              label="Private Publication"
              value={currentPublication?.private || false}
              onSave={(val: boolean) => {
                analytics.track('Private Subsciption Toggled on');
                return publicationMutation.mutateAsync({ private: val });
              }}
              toastSuccessMessage="Saved!"
              description="Your site and publication are only accessible to logged in subscribers on your list. Anyone else who visits your site will see all posts as gated."
            />
            <SwitchForm
              name="require_subscriber_approval"
              label="Require Subscriber Approval"
              value={currentPublication?.require_subscriber_approval || false}
              onSave={handleSubscriberApprovalChange}
              toastSuccessMessage="Saved!"
              description="Allow any reader who visits your site to request access to this publication by entering their email, which you can approve or deny in the Audience &#8594; Subscribers section."
            />
          </>
        )}
        <SwitchForm
          name="white_labeled_at"
          label="Remove beehiiv branding"
          description="Toggle on to remove beehiiv branding"
          value={currentPublication?.white_labeled || false}
          onSave={(val: boolean) =>
            publicationMutation.mutateAsync({ white_labeled_at: val ? new Date().toISOString() : null })
          }
          toastSuccessMessage="Saved!"
          disabled={!settings?.white_labeling}
          helperText="Enterprise and Max customers can remove beehiiv branding from email and web."
        />
      </Section>

      {/* Sender Name Section */}
      <Section
        title="Sender Name"
        description="This is who subscribers will see the email is from in their inbox. Typically the name of your newsletter or the author. This should NOT be an email address."
        scrollToId="sender-name"
        isLoading={!currentPublication || !organization}
      >
        <SwitchForm
          name="display_authors_as_post_email_sender_namet"
          label="Use author as sender name for posts"
          description={
            currentPublication?.display_authors_as_post_email_sender_name
              ? 'Toggle off to disable'
              : 'Toggle on to enable'
          }
          value={currentPublication?.display_authors_as_post_email_sender_name || false}
          onSave={(val: boolean) => {
            analytics.track('Use author as sender name for posts');
            return publicationMutation.mutateAsync({ display_authors_as_post_email_sender_name: val });
          }}
          toastSuccessMessage="Saved!"
        />
        <InputForm
          label="Sender Name"
          value={currentPublication?.email_sender_name || ''}
          helperText="This is who subscribers will see the email is from in their inbox. Typically the name of your newsletter or the author. This should NOT be an email address."
          onSave={(val) => publicationMutation.mutateAsync({ email_sender_name: val })}
          isSaving={publicationMutation.isLoading}
          toastSuccessMessage="Saved!"
        />
      </Section>

      {/* Copyright Section */}
      <Section
        title="Copyright Text"
        description="Use custom text for a copyright message."
        scrollToId="copyright"
        isLoading={!currentPublication || !organization}
      >
        <div className="flex flex-col">
          <InputForm
            label="Custom Copyright Text"
            placeholder={currentPublication?.name || ''}
            value={
              currentPublication?.copyright_text || `${currentPublication?.copyright_text || currentPublication?.name}.`
            }
            helperText={`Preview: © ${new Date().getFullYear()} ${
              currentPublication?.copyright_text || currentPublication?.name
            }.`}
            onSave={(val) => publicationMutation.mutateAsync({ copyright_text: val })}
            toastSuccessMessage="Saved!"
            isSaving={publicationMutation.isLoading}
          />
        </div>
      </Section>

      {/* Welcome Email Section */}
      <Section
        title="Welcome Email"
        description="Enable or disable a welcome email that is sent to new subscribers."
        scrollToId="welcome-email"
        isLoading={!currentPublication || !organization}
      >
        <WelcomeEmail publication={currentPublication} organization={organization} />
      </Section>

      {/* Double Opt In Section */}
      <Section
        title="Double Opt-In"
        description="You can require new signups to confirm their email address before adding them to your list. This will not impact your existing subscribers or subscriber imports."
        scrollToId="double-opt-in"
        isLoading={!currentPublication}
      >
        {settings?.opt_in_email_editor && (
          <Button variant="primary-inverse" type="button" onClick={() => navigate('/opt_in_email/analytics')}>
            View and Update Confirmation Email
          </Button>
        )}
        <SwitchForm
          name="double_opt_in"
          label="Require Double Opt-In?"
          description="You can customize the double opt-in email by clicking 'view' and selecting 'edit' on the overview page."
          value={currentPublication?.double_opt_required || false}
          onSave={(val: boolean) => {
            analytics.track('Double Opt-in Toggled on');
            return publicationMutation.mutateAsync({ double_opt_required: val });
          }}
          toastSuccessMessage="Saved!"
          noBorder
        />
        {settings?.opt_in_email_editor && (
          <InputForm
            label="Opt-In Redirect URL"
            placeholder="https://www.example.com/"
            helperText="Redirect subscribers to this URL once they confirm their subscription (if not set, they will be redirected to your beehiiv homepage by default)."
            value={currentPublication?.opt_in_redirect_url || ''}
            onSave={(val) => publicationMutation.mutateAsync({ opt_in_redirect_url: val })}
            isSaving={publicationMutation.isLoading}
            validationCheck={isValidUrl}
            validationFailMessage="Please enter a valid URL"
            toastSuccessMessage="Saved!"
            noBorder
          />
        )}
        <hr />
        <SwitchForm
          name="auto_resend_opt_in"
          label="Smart Nudge"
          description="Send subscribers a reminder email if they have not confirmed their email address after 48 hours. This applies to both referral opt-ins and double opt-in signups."
          value={currentPublication?.auto_resend_opt_in || false}
          onSave={(val: boolean) => publicationMutation.mutateAsync({ auto_resend_opt_in: val })}
          toastSuccessMessage="Saved!"
        />
      </Section>

      {canUpdateUtmParams && (
        <Section
          title="Analytics"
          description="Enable and customize UTM parameters added to links in your posts."
          scrollToId="analytics"
          isLoading={!currentPublication || !organization}
        >
          <InputForm
            label="UTM Source"
            helperText={`Leave empty to use your publication domain: ${currentPublication?.hostname}`}
            value={currentPublication?.utm_source || ''}
            onSave={(val) => publicationMutation.mutateAsync({ utm_source: val })}
            isSaving={publicationMutation.isLoading}
            validationCheck={isValidUtmParam}
            validationFailMessage={utmParamValidationFailMessage('Source')}
            toastSuccessMessage="Saved!"
          />
          <InputForm
            label="UTM Medium"
            helperText={`Leave empty to use "newsletter" for emails and "referral" when read online`}
            value={currentPublication?.utm_medium || ''}
            onSave={(val) => publicationMutation.mutateAsync({ utm_medium: val })}
            isSaving={publicationMutation.isLoading}
            validationCheck={isValidUtmParam}
            validationFailMessage={utmParamValidationFailMessage('Medium')}
            toastSuccessMessage="Saved!"
          />
          <InputForm
            label="UTM Campaign"
            helperText="Leave empty to use a URL-friendly version of your post name, e.g. my-awesome-post"
            value={currentPublication?.utm_campaign || ''}
            onSave={(val) => publicationMutation.mutateAsync({ utm_campaign: val })}
            isSaving={publicationMutation.isLoading}
            validationCheck={isValidUtmParam}
            validationFailMessage={utmParamValidationFailMessage('Campaign')}
            toastSuccessMessage="Saved!"
          />
          <div className="flex flex-col">
            <SwitchForm
              name="utm_params_enabled"
              label="Enable UTM params"
              description="UTM params will be added to all links in your emails that don't already contain UTM params."
              value={currentPublication?.utm_params_enabled || false}
              onSave={(val: boolean) => publicationMutation.mutateAsync({ utm_params_enabled: val })}
              toastSuccessMessage="Saved!"
            />
          </div>
        </Section>
      )}
    </>
  );
};

export default General;
