import React, { FC } from 'react';

import { Dropdown } from '@/ui/Dropdown';

// Components
import { DatePicker, Input } from '../../../components/Form';
// Interfaces
import { Condition, Filter } from '../../../interfaces/segment';

// Values
import { defaultOperatorByConditionName } from './operatorOptions';

const SECONDS_PER_DAY = 86400;

const convertSecondsToDays = (seconds: string | number) => {
  if (typeof seconds === 'string') {
    const numSeconds = Number(seconds);
    return Math.floor(numSeconds / SECONDS_PER_DAY);
  }

  return Math.floor(seconds / SECONDS_PER_DAY);
};

const dateTypeOptions = [
  {
    label: 'a specific date',
    value: 'specific_date',
  },
  {
    label: 'a relative date',
    value: 'relative_date',
  },
];

const relativeDateOptions = [
  {
    label: 'ago',
    value: 'ago',
  },
  {
    label: 'from now',
    value: 'from_now',
  },
];

interface Props {
  condition: Condition;
  index: number;
  subIndex?: number;
  handleFilterChange: (filters: Filter, blockIndex: number, subIndex?: number) => void;
}

const ConditionDatetimeInput: FC<Props> = ({ condition, handleFilterChange, index, subIndex }) => {
  const dateType = condition?.filters?.relative ? 'relative_date' : 'specific_date';
  return (
    <>
      <Dropdown
        className="w-34"
        name="dateType"
        value={dateType}
        onSelect={(name: string, value: string) => {
          const updatedFilters: Filter = {
            ...condition.filters,
            value: '',
            operator: condition?.filters?.operator || defaultOperatorByConditionName(condition?.name),
          };

          if (value === 'specific_date') {
            delete updatedFilters.relative;
          } else if (value === 'relative_date') {
            updatedFilters.relative = 'ago';
            updatedFilters.value = SECONDS_PER_DAY;
          }

          handleFilterChange(updatedFilters, index, subIndex);
        }}
        options={dateTypeOptions}
      />
      {dateType === 'specific_date' && (
        <DatePicker
          inline
          displayTimezone={false}
          className="mt-2"
          value={condition.filters?.value || ''}
          pickerOptions={{
            minuteIncrement: 1,
          }}
          onChange={(date) => {
            if (!date) {
              return;
            }

            const updatedFilters = {
              operator: condition?.filters?.operator || defaultOperatorByConditionName(condition?.name),
              value: date.getTime(),
            };

            const newFilters = { ...condition.filters, ...updatedFilters };

            handleFilterChange(newFilters, index, subIndex);
          }}
        />
      )}
      {dateType === 'relative_date' && (
        <div className="flex items-center mt-2 text-sm">
          <p className="mr-2">of</p>
          <Input
            className="w-16"
            name="value"
            type="number"
            value={condition.filters?.value ? `${convertSecondsToDays(condition?.filters?.value)}` : '1'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const updatedFilters = {
                operator: condition?.filters?.operator || defaultOperatorByConditionName(condition?.name),
                value: Number(e.target.value) * SECONDS_PER_DAY,
              };

              const newFilters = { ...condition.filters, ...updatedFilters };

              handleFilterChange(newFilters, index, subIndex);
            }}
          />
          <p className="mx-2">days</p>
          <Dropdown
            className="w-34"
            name="relativeDate"
            value={condition?.filters?.relative || 'ago'}
            onSelect={(name: string, value: string) => {
              const updatedFilters = {
                ...condition.filters,
                relative: value,
                operator: condition?.filters?.operator || defaultOperatorByConditionName(condition?.name),
              };

              handleFilterChange(updatedFilters, index, subIndex);
            }}
            options={relativeDateOptions}
          />
        </div>
      )}
    </>
  );
};

export default ConditionDatetimeInput;
