import styled from 'styled-components';

import { colors } from '../../../lib/colors';

export const Styled = {
  Wrapper: styled.div<{ $isInline?: boolean }>`
    align-items: ${({ $isInline }) => ($isInline ? 'center' : 'space-between')};
    display: flex;
    flex-direction: ${({ $isInline }) => ($isInline ? 'row' : 'column')};
    gap: ${({ $isInline }) => ($isInline ? '0.5rem' : '0.25rem')};
    justify-content: center;
  `,
  Header: styled.span<{ label?: string; $isInline?: boolean }>`
    align-items: center;
    color: ${colors.white[2]};
    display: flex;
    flex: 1 0 auto;
    font-size: 0.625rem;
    font-weight: 700;
    gap: ${({ $isInline }) => ($isInline ? '0.25rem' : '0.5rem')};
    justify-content: ${({ label }) => (label ? 'space-between' : 'center')};
  `,
  Label: styled.span<{ $isInline?: boolean }>`
    color: ${colors.white[4]};
  `,
  ProgressNumeric: styled.span``,
  ProgressBar: styled.div<{ used: number; available: number }>`
    background-color: ${colors.white[6]};
    border-radius: 0.125rem;
    height: 0.3125rem;
    min-width: 3rem;
    overflow: hidden;
    width: 100%;

    &::after {
      content: '';
      background-color: ${colors.pink[6]};
      display: block;
      height: 100%;
      width: ${({ used, available }) => (used / available) * 100}%;
    }
  `,
};

export default Styled;
