import React, { FC } from 'react';
import { ArrowsUpDownIcon, ListBulletIcon, MapIcon, SparklesIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

interface Props {
  currentStep: number;
  className?: string;
}

const StepBar: FC<Props> = (props: Props) => {
  const { currentStep, className } = props;

  const steps = [
    {
      label: 'Migrate PAN Data',
      icon: ArrowsUpDownIcon,
      iconClasses: 'rotate-90',
    },
    {
      label: 'Enter Account Details',
      icon: ListBulletIcon,
    },
    {
      label: 'Map Products',
      icon: MapIcon,
    },
    {
      label: 'Preview Subscriptions',
      icon: UserGroupIcon,
    },
    {
      label: 'Finished',
      icon: SparklesIcon,
    },
  ];

  return (
    <div className={cx('w-full', className)}>
      <div className="flex">
        {steps.map((step, index) => (
          <div className="w-1/4">
            <div className="relative mb-2">
              {index > 0 && (
                <div
                  className="absolute flex align-center items-center align-middle content-center"
                  style={{ width: 'calc(100% - 2.5rem - 1rem)', top: '50%', transform: 'translate(-50%, -50%)' }}
                >
                  <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                    <div
                      className="w-0 bg-primary-300 py-1 rounded"
                      style={{ width: currentStep > index ? '100%' : '0%' }}
                    />
                  </div>
                </div>
              )}

              <div
                className={cx(
                  'w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center',
                  currentStep > index ? 'bg-primary-500' : 'bg-white border-2 border-gray-200'
                )}
              >
                <span className={cx('text-center w-full', currentStep > index ? 'text-white' : 'text-gray-600')}>
                  <step.icon className={cx('mx-auto w-6 h-6', step.iconClasses)} />
                </span>
              </div>
            </div>

            <div className="text-xs text-center md:text-base">{step.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepBar;
