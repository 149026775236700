import { ArrowRightIcon } from '@heroicons/react/20/solid';

import { useGoToBilling, useOrganization } from '../../../../hooks';
import Banner from '../../../Banner';

interface Props {
  organization_id: string;
  publicationId: string;
}

const WARNING_PERCENT = 0.9;

const SubscriberLimitBanner = ({ organization_id, publicationId }: Props) => {
  const { data } = useOrganization(organization_id, publicationId);
  const goToBilling = useGoToBilling();

  if (!data) return null;

  const { organization } = data;
  const {
    max_subscriptions: maxSubscriptions,
    active_subscriptions_count: activeSubscriptions,
    within_subscription_limit: withinLimit,
  } = organization;

  const shouldWarn = activeSubscriptions / maxSubscriptions >= WARNING_PERCENT;
  const shouldError = !withinLimit;

  if (!shouldWarn && !shouldError) return null;

  const variant = shouldError ? 'danger' : 'warning';
  const title = shouldError ? 'Subscriber limit exceeded!' : 'Subscriber limit approaching';
  const bodyText = shouldError
    ? `You have exceeded the maximum number of subscribers for your account. ${activeSubscriptions} / ${maxSubscriptions}`
    : `You are approaching the maximum allowed number of subscribers for your account. ${activeSubscriptions} / ${maxSubscriptions}`;

  return (
    <Banner
      variant={variant}
      title={title}
      bodyText={bodyText}
      ctaText="Go to billing"
      ctaIcon={<ArrowRightIcon className="w-4 h-4" />}
      onClick={goToBilling}
    />
  );
};

export default SubscriberLimitBanner;
