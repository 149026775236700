import { PropsWithChildren } from 'react';
import { cx } from 'class-variance-authority';

import { getColor } from '../DesignTokens';

type Props = PropsWithChildren<{}>;

export const ItemsBody = ({ children }: Props) => (
  <tbody className={cx('divide-y', getColor('surface/200', 'border'))}>
    {children}
  </tbody>
);
