import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { SparklesIcon } from '@heroicons/react/24/outline';

import ActionModal from '../../../components/ActionModal';
import EllipsisDropdown from '../../../components/EllipsisDropdown';
import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { useDeleteTestimonial } from '../../../hooks/useTestimonials';
import { Testimonial } from '../../../interfaces/testimonial';
import api from '../../../services/swarm';

import TestimonialForm from './TestimonialForm';

interface Props {
  testimonial: Testimonial;
  landingPageId?: string;
}

const TestimonialCard = ({ testimonial, landingPageId }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [testimonialToEdit, setTestimonialToEdit] = useState<Testimonial | null>(null);

  // For now we only support one landing page per publication.
  // In the future this might change and this value should be more dynamic.
  const hasOneLandingPageTestimonial = testimonial?.landing_page_testimonials?.length === 1;

  const handleUpdateTestimonial = (payload: any): void => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('testimonial[name]', payload.name);
    formData.append('testimonial[description]', payload.description);
    formData.append('testimonial[message]', payload.message);
    formData.append('testimonial[picture]', payload.picture);

    // For now we only support one landing page per publication.
    // In the future this might change and this value should be more dynamic.
    if (payload.addToLandingPage) {
      formData.append('landing_page_id', landingPageId || '');
    }

    api
      .patch(`/testimonials/${testimonial?.id}`, formData)
      .then(() => {
        queryClient.invalidateQueries(['testimonials', currentPublicationId]);
        toast.success('Saved!');
      })
      .catch((errPayload: any) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast(error);
      })
      .finally(() => {
        setIsSaving(false);
        setTestimonialToEdit(null);
      });
  };

  const deleteTestimonial = useDeleteTestimonial({
    testimonialId: testimonial?.id || '',
  });
  const handleDelete = () => {
    deleteTestimonial.mutate();
    setIsDeleting(false);
  };

  const options = [
    {
      label: 'Edit',
      onClick: () => setTestimonialToEdit(testimonial),
    },
    {
      label: 'Delete',
      onClick: () => setIsDeleting(true),
    },
  ];

  return (
    <>
      <TestimonialForm
        headerText="Edit Testimonial"
        ctaText="Save"
        isOpen={Boolean(testimonialToEdit)}
        testimonial={testimonialToEdit}
        onClose={() => setTestimonialToEdit(null)}
        isProcessing={isSaving}
        onProceed={handleUpdateTestimonial}
      />
      <ActionModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onProceed={handleDelete}
        resourceId={testimonial.id}
        isWorking={deleteTestimonial.isLoading}
        headerText="Delete Testimonial"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this testimonial?
      </ActionModal>
      <li className="p-4">
        <div className="flex justify-between items-start">
          <div className="w-2/3">
            <div className="space-y-2">
              <div className="flex space-x-2">
                <img
                  className="h-10 w-10 flex-none rounded-full bg-gray-50"
                  src={testimonial?.picture_url || ''}
                  alt="author of the testimonial"
                />
                <div>
                  <p className="text-gray-800 font-semibold truncate">
                    <div className="hover:text-gray-900">{testimonial?.name}</div>
                  </p>
                  <p className="flex text-gray-500 text-sm truncate">{testimonial?.description}</p>
                </div>
              </div>

              <p className="flex text-gray-500 text-sm truncate italic">&quot;{testimonial?.message}&quot;</p>
              {hasOneLandingPageTestimonial && (
                <div className="flex space-x-0.5 items-center mb-1">
                  <SparklesIcon className="text-blue-500 h-4 w-4" />
                  <span className="font-bold p-1 text-xs text-blue-500">Featured</span>
                </div>
              )}
            </div>
          </div>
          <div>
            <EllipsisDropdown options={options} />
          </div>
        </div>
      </li>
    </>
  );
};

export default TestimonialCard;
