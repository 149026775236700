import { useEffect, useRef, useState } from 'react';
import { cx } from 'class-variance-authority';

const ScrollableContent = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    let scrollTimeout: number | undefined;

    const handleScroll = () => {
      setIsScrolling(true);

      // Clear the timeout throughout the scroll
      if (scrollTimeout !== undefined) {
        window.clearTimeout(scrollTimeout);
      }

      // Set a timeout to run after scrolling ends
      scrollTimeout = window.setTimeout(() => {
        setIsScrolling(false);
      }, 500); // Adjust the timeout duration as needed
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    } else {
      // Return a no-op function to satisfy consistent-return
      return () => { };
    }

    // Clean up the event listener on component unmount
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
      if (scrollTimeout !== undefined) {
        window.clearTimeout(scrollTimeout);
      }
    };
  }, []);

  return (
    <div
      ref={scrollContainerRef}
      className={cx(
        'h-[536px] overflow-auto flex flex-col scrollbar-hidden',
        className,
        isScrolling ? 'scrolling' : '',
      )}
    >
      {children}
    </div>
  );
};

export default ScrollableContent;
