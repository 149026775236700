import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@/context/current-user-context';
import { AdNetworkAdvertiserPublisherListType } from '@/interfaces/ad_network/internal/advertiser_publisher_list';
import { AdNetworkAdvertiserPublisherListItem } from '@/interfaces/ad_network/internal/advertiser_publisher_list_item';
import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from '@/services/swarm';

export default function useAdvertiserPublisherListItemDestroy({
  advertiserId,
  publisherListItemId,
  listType,
}: {
  advertiserId: string;
  publisherListItemId: string;
  listType: AdNetworkAdvertiserPublisherListType;
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();

  const destroyAdvertiserPublisherListItem = (): Promise<AdNetworkAdvertiserPublisherListItem> =>
    api
      .patch(`/ad_network/internal/advertisers/${advertiserId}/publisher_list_items/${publisherListItemId}`, {
        list_type: listType,
        publisher_list_item: {
          removed_by_id: currentUser?.id,
          removed_by_type: currentUser ? 'User' : null,
        },
      })
      .then((res) => res.data);

  return useMutation<AdNetworkAdvertiserPublisherListItem, MutationErrorResponse>(destroyAdvertiserPublisherListItem, {
    onSuccess: () => {
      toast.success('Publisher List Item deleted successfully');
      queryClient.invalidateQueries([
        'ad_network',
        'internal',
        'advertisers',
        advertiserId,
        'publisher_list_items',
        publisherListItemId,
      ]);
      navigate(`/ad_network/advertisers/${advertiserId}/publisher_list`);
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
