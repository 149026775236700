import { NEW_IMAGE_PATH_HOST, OLD_IMAGE_PATH_HOST, PROXY_HOST } from './constants';

export const getSuffix = (number: number) => {
  if (number >= 1000000) {
    return 'M';
  }

  if (number >= 1000) {
    return 'K';
  }

  return '';
};

export const getDecimal = (number: number) => {
  if (number >= 1000000) {
    return number / 1000000;
  }

  if (number >= 1000) {
    return number / 1000;
  }

  return number;
};

export const getImageUrl = (url?: string) => {
  if (url) {
    if (url[0] === '/') {
      return url;
    }

    return `${PROXY_HOST}${url
      ?.split('/cdn-cgi/image/fit=scale-down,format=auto,onerror=redirect,quality=80')
      ?.join('')
      ?.split(OLD_IMAGE_PATH_HOST)
      ?.join(NEW_IMAGE_PATH_HOST)}`;
  }

  return url;
};
