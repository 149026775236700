import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';
import { Textarea } from '@/ui/Textarea';

import ActionModal from '../../components/ActionModal';
import CurrencyInput from '../../components/Form/CurrencyInput';
import useStripeConnectAccount, { StripeAccountStatus } from '../../hooks/boosts/monetize/useStripeConnectAccount';
import useCreateLedgerEntry from '../../hooks/boosts/useCreateLedgerEntry';
import useWallet from '../../hooks/boosts/useWallet';
import { LedgerEntryCategory, LedgerKind } from '../../interfaces/ledger_entry';

import AddFundsButton from './AddFundsButton';

const WalletActions = () => {
  const currentPublicationId = useCurrentPublicationId();
  const walletQuery = useWallet();

  const queryClient = useQueryClient();
  const [transferProcessing, setTransferProcessing] = useState(false);
  const [transferAmount, setTransferAmount] = useState(0);
  const [transferDescription, setTransferDescription] = useState('');
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const { data: stripeConnectAccount } = useStripeConnectAccount(currentPublicationId);
  const boostsAccountStatus = stripeConnectAccount?.boosts_account_status || StripeAccountStatus.MISSING;

  const { data: ledgerData } = walletQuery;
  const ledgerBalanceCents = ledgerData?.withdrawable_balance_cents || 0;

  const clearTransferModal = () => {
    setTransferProcessing(false);
    setTransferModalOpen(false);
    setTransferAmount(0);
    setTransferDescription('');
  };

  const createLedgerEntry = useCreateLedgerEntry({
    onSuccess: () => {
      toast.success('Withdrawal initiated!');
      queryClient.invalidateQueries([currentPublicationId, 'ledger_entries', LedgerKind.MONETIZATION]);
      clearTransferModal();
    },
    onError: (errPayload) => {
      clearTransferModal();
      const errors = errPayload?.response?.data?.message || 'Something went wrong';
      toast.error(errors);
    },
  });

  const handleCreateWithdrawlSession = () => {
    setTransferProcessing(true);

    createLedgerEntry.mutate({
      amount_cents: transferAmount * -1, // convert to negative cents
      description: transferDescription,
      category: LedgerEntryCategory.STRIPE_PAYOUT,
      ledger_kind: LedgerKind.MONETIZATION,
      prevent_negative_ledger_balance: true,
    });
  };

  const withdrawDisabled = boostsAccountStatus !== StripeAccountStatus.ACTIVE && ledgerBalanceCents <= 0;

  const onCurrencyChange = (value: number) => {
    setTransferAmount(value);
  };

  return (
    <>
      <ActionModal
        isOpen={transferModalOpen}
        isWorking={transferProcessing}
        onClose={() => clearTransferModal()}
        onProceed={handleCreateWithdrawlSession}
        resourceId={currentPublicationId}
        headerText="Withdraw Earnings"
        disabled={transferAmount <= 0}
      >
        <div className="flex flex-col space-y-4 mt-2">
          {ledgerData?.withdrawable_balance && (
            <div className="flex flex-col space-y-2">
              <dt className="text-sm font-semibold text-gray-500 tracking-normal">Available Funds</dt>
              <dd className="mt-1 truncate text-gray-500">
                <span className="text-3xl font-black tracking-tight text-gray-800">
                  {ledgerData?.withdrawable_balance}
                </span>
              </dd>
            </div>
          )}
          <CurrencyInput
            id="transfer_amount"
            name="transfer_amount"
            value={transferAmount}
            onChange={onCurrencyChange}
            labelText="Withdraw Amount"
            minCents={0}
            maxCents={1000000}
          />
          <Textarea
            name="transfer_description"
            value={transferDescription}
            onChange={(e) => setTransferDescription(e.target.value)}
            labelText="Description"
            placeholderText="This withdrawal is for..."
            helperText="Optional description for internal use"
          />
        </div>
      </ActionModal>
      <ButtonGroup variant="primary-inverse">
        <Button
          disabled={withdrawDisabled}
          loading={transferProcessing}
          type="button"
          onClick={() => setTransferModalOpen(true)}
        >
          Withdraw earnings
          {walletQuery.data?.withdrawable_balance && (
            <span className="font-mono ml-2 text-gray-500">({walletQuery.data.withdrawable_balance})</span>
          )}
        </Button>
        <AddFundsButton />
      </ButtonGroup>
    </>
  );
};

export default WalletActions;
