import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeftIcon, LockClosedIcon, PencilIcon } from '@heroicons/react/20/solid';

import Form from '@/components/_domain/PollForm';
import { PollDetails } from '@/components/_domain/PollForm/PollForm';
import { HorizontalMetricsChart } from '@/components/charts';
import Header from '@/components/Header';
import LoadingBox from '@/components/LoadingBox';
import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import SlideOver from '@/components/SlideOver';
import Stack from '@/components/Stack';
import Text from '@/components/Text';
import { usePostPollMetrics } from '@/hooks/usePolls';
import { PollListStyle, PollOrientation, PollStyle, PollType } from '@/interfaces/poll';
import { Setting } from '@/interfaces/setting';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { usePoll, useRequireSetting } from '../../../hooks';
import Preview from '../Preview';

import AppearsIn from './AppearsIn';
import ShareResults from './ShareResults';

interface RouteParams {
  pollId: string;
}

const Poll: React.FunctionComponent = () => {
  const { pollId: id } = useParams() as unknown as RouteParams;
  const [publicationId] = useCurrentPublicationState();
  const pollQuery = usePoll(publicationId, id, false);
  const { data: poll, isLoading, isError, isFetched } = pollQuery;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState('all');
  const [chartTitle, setChartTitle] = useState('All Posts & Emails');
  const [chartMetrics, setChartMetrics] = useState(poll?.metrics);
  const postPollMetricsQuery = usePostPollMetrics({ pollId: id, allResults: true });
  const { data: postPollMetricsData } = postPollMetricsQuery;
  const postPolls = postPollMetricsData?.pages.flatMap((page) => page.post_polls) || [];

  useEffect(() => {
    setChartMetrics(poll?.metrics);
  }, [setChartMetrics, poll]);

  const options = [
    { label: 'All Post & Emails', value: 'all' },
    ...postPolls.map((postPoll) => ({ label: postPoll.resource_title, value: postPoll.resource_id })),
  ];

  useRequireSetting({ setting: Setting.POLLS, message: '', redirectTo: '/polls/overview' });

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSuccess = () => {
    setIsOpen(false);
    pollQuery.remove();
  };

  if (isLoading || !isFetched || !poll) {
    return null;
  }

  const pollDetails: PollDetails = {
    name: poll.name || '',
    description: poll.description || '',
    question: poll.question || '',
    choices: poll.poll_choices.map((choice) => choice.label) || ['', ''],
    list_style: poll.list_style || PollListStyle.DISC,
    orientation: poll.orientation || PollOrientation.VERTICAL,
    poll_style: poll.poll_style || PollStyle.DEFAULT,
    redirect_url: poll.redirect_url || '',
    live_results_enabled: poll.live_results_enabled || false,
    notes: poll.notes || '',
    poll_type: poll.poll_type || PollType.VOTING,
    trivia_answer: poll.trivia_answer,
    extended_feedback_enabled: poll.extended_feedback_enabled || true,
  };

  const slideOverBodyId = 'manage-poll';

  return (
    <>
      <div>
        <div className="flex mb-4">
          <Link className="text-sm text-gray-500 hover:text-primary-500" to="/polls">
            <div className="flex items-center">
              <ArrowLeftIcon className="w-4 h-4 mr-2" />
              <span>Back to all polls</span>
            </div>
          </Link>
        </div>
        <div className="mb-4">
          <SkeletonLoader
            className="px-0 py-0"
            isLoading={isLoading}
            skeletons={
              <div className="flex justify-between">
                <div className="space-y-6 w-60">
                  <div className="space-y-2">
                    <Skeleton className="h-10 rounded" />
                  </div>
                </div>
                <div className="space-x-4 flex">
                  <div className="space-y-2 w-20">
                    <Skeleton className="h-10 rounded" />
                  </div>
                  <div className="space-y-2 w-32">
                    <Skeleton className="h-10 rounded" />
                  </div>
                </div>
              </div>
            }
          >
            {poll && (
              <div className="flex justify-between w-full items-end">
                <div>
                  <Header className="inline-flex items-center">
                    {poll.name}
                    {poll.is_locked && <LockClosedIcon className="h-5 w-5 ml-2 text-gray-300" />}
                  </Header>

                  {poll.is_locked && <Text>This poll is locked and can no longer be edited.</Text>}
                </div>

                <div className="flex space-x-2">
                  <ShareResults poll={poll} />

                  {!poll.is_locked && (
                    <Button variant="primary" onClick={handleOpen} Icon={PencilIcon}>
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            )}
          </SkeletonLoader>
        </div>
        <hr />
        <div className="my-4">
          <LoadingBox isLoading={isLoading} isError={isError}>
            <div className="grid grid-cols-12 gap-4">
              <Stack gap={2} direction="col" className="col-span-12 lg:col-span-5">
                <Preview publicationId={publicationId} pollDetails={pollDetails} />
                <AppearsIn pollId={poll.id} publishedCount={postPolls.length} />
              </Stack>
              <Stack gap={4} direction="col" className="col-span-12 lg:col-span-7">
                <Dropdown
                  name="post_metrics"
                  value={selectedPost}
                  options={options}
                  onSelect={(_name, value) => {
                    setSelectedPost(value);
                    setChartTitle(
                      postPolls.find((postPoll) => postPoll.resource_id === value)?.resource_title ||
                        'All Posts & Emails'
                    );
                    setChartMetrics(
                      postPolls.find((postPoll) => postPoll.resource_id === value)?.metrics || poll.metrics
                    );
                  }}
                />
                {chartMetrics && <HorizontalMetricsChart title={`Results for ${chartTitle}`} metrics={chartMetrics} />}
              </Stack>
            </div>
          </LoadingBox>
        </div>
      </div>
      <SlideOver bodyId={slideOverBodyId} headerText="Edit Poll" isOpen={isOpen} onClose={handleClose}>
        <Form
          onCancel={handleClose}
          pollId={poll?.id}
          onSuccess={handleSuccess}
          publicationId={publicationId}
          slideOverBodyId={slideOverBodyId}
        />
      </SlideOver>
    </>
  );
};

export default Poll;
