import { useMediaLibrary } from '../context/MediaLibraryContext';
import { MediaLibraryTabState } from '../MediaLibrary.types';
import CurrentPostView from '../views/CurrentPostView';
import GiphyView from '../views/GiphyView';
import UnsplashView from '../views/UnsplashView';

const Content = () => {
  const { openTab, onMediaSelect, onExternalMediaSelect } = useMediaLibrary();

  switch (openTab) {
    case MediaLibraryTabState.BEEHIIV:
      return <CurrentPostView onMediaSelect={onMediaSelect} />;

    case MediaLibraryTabState.GIPHY:
      return <GiphyView onMediaSelect={onExternalMediaSelect} />;

    case MediaLibraryTabState.UNSPLASH:
      return <UnsplashView onMediaSelect={onExternalMediaSelect} />;

    default:
      return null;
  }
};

export default Content;
