/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */
import { Node as PMNode, ResolvedPos } from '@tiptap/pm/model';
import { EditorState, Selection, SelectionRange } from '@tiptap/pm/state';
import { Mappable } from '@tiptap/pm/transform';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

export const MultiNodeSelectionJSONId = 'multiNode';

interface IMultiNodeSelectionJSON {
  type: typeof MultiNodeSelectionJSONId;
  posList: number[];
}

export class MultiNodeSelection extends Selection {
  constructor($posList: ResolvedPos[]) {
    const doc = $posList[0].node(0);

    const resovedNodeStartEndPosList = $posList.map(($pos) => {
      return {
        $pos,
        $end: doc.resolve($pos.pos + $pos.nodeAfter!.nodeSize),
      };
    });

    const ranges = resovedNodeStartEndPosList.map(({ $pos, $end }) => {
      return new SelectionRange($pos, $end);
    });

    super(resovedNodeStartEndPosList[0].$pos, resovedNodeStartEndPosList[0].$end, ranges);

    this.$posList = $posList;
    this.nodes = $posList.map(($pos) => $pos.nodeAfter!);
    this.posList = $posList.map(($pos) => $pos.pos);
  }

  $posList: ResolvedPos[];

  nodes: PMNode[];

  posList: number[];

  map(doc: PMNode, mapping: Mappable): Selection {
    const rangesMapResults = this.ranges.map((range) => mapping.mapResult(range.$from.pos));

    const nonDeletedRanges = rangesMapResults.filter((mapResult) => !mapResult.deleted);

    if (!nonDeletedRanges.length) {
      return Selection.near(doc.resolve(this.anchor));
    }

    const $mappedPosLis = this.ranges.map((_, index) => doc.resolve(rangesMapResults[index].pos));

    return new MultiNodeSelection($mappedPosLis);
  }

  eq(other: Selection): boolean {
    if (!(other instanceof MultiNodeSelection) || this.ranges.length !== other.ranges.length) {
      return false;
    }

    for (let i = 0; i < this.ranges.length; i += 1) {
      if (
        this.ranges[i].$from.pos !== other.ranges[i].$from.pos ||
        this.ranges[i].$to.pos !== other.ranges[i].$to.pos
      ) {
        return false;
      }
    }

    return true;
  }

  toJSON(): IMultiNodeSelectionJSON {
    return { type: MultiNodeSelectionJSONId, posList: this.ranges.map((range) => range.$from.pos) };
  }

  getBookmark() {
    return new MultiNodeBookmark(this.posList);
  }

  forEachNode(f: (node: PMNode, pos: number) => void): void {
    for (let i = 0; i < this.nodes.length; i += 1) {
      f(this.nodes[i], this.posList[i]);
    }
  }

  static fromJSON(doc: PMNode, json: IMultiNodeSelectionJSON): MultiNodeSelection {
    if (!json || !json.type || !json.posList) throw new RangeError('Invalid input for MultiNodeSelection.fromJSON');

    return new MultiNodeSelection(json.posList.map((pos: number) => doc.resolve(pos)));
  }

  static create(doc: PMNode, posList: number[]) {
    return new MultiNodeSelection(posList.map((pos) => doc.resolve(pos)));
  }
}

MultiNodeSelection.prototype.visible = false;

Selection.jsonID(MultiNodeSelectionJSONId, MultiNodeSelection);

export class MultiNodeBookmark {
  constructor(readonly posList: number[], readonly fallSelectionPos?: number) {}

  map(mapping: Mappable) {
    const rangesMapResults = this.posList.map((pos) => mapping.mapResult(pos));
    const nonDeletedRanges = rangesMapResults.filter((mapResult) => !mapResult.deleted);

    if (!nonDeletedRanges.length) {
      return new MultiNodeBookmark([], rangesMapResults[0].pos);
    }

    return new MultiNodeBookmark(rangesMapResults.map((mapResult) => mapResult.pos));
  }

  resolve(doc: PMNode): Selection {
    if (this.posList.length === 0 && typeof this.fallSelectionPos === 'number') {
      return Selection.near(doc.resolve(this.fallSelectionPos));
    }

    const $posList = this.posList.map((pos) => doc.resolve(pos));

    return new MultiNodeSelection($posList);
  }
}

export const drawMultinodeSelectionDecorations = (state: EditorState) => {
  if (!(state.selection instanceof MultiNodeSelection)) return null;

  const nodeDecorations: Decoration[] = [];

  state.selection.forEachNode((node, pos) => {
    nodeDecorations.push(Decoration.node(pos, pos + node.nodeSize, { class: 'multi-node-selected' }));
  });

  return DecorationSet.create(state.doc, nodeDecorations);
};
