import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

interface Props {
  onClick: () => void;
}

const BackButton = ({ onClick }: Props) => (
  <Button
    variant="flush"
    type="button"
    Icon={ArrowLeftIcon}
    className="!px-3 h-10 rounded-md text-gray-700"
    onClick={onClick}
  >
    Exit
  </Button>
);

export default BackButton;
