import moment from 'moment-mini';

import { AdNetworkCampaignPerformance } from '@/interfaces/ad_network/internal/campaign_performance';
import { MetricCard } from '@/ui/MetricCard';

interface Props {
  campaign: AdNetworkCampaignPerformance;
}

const formatter = new Intl.NumberFormat('en-US');
const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const CampaignPerformanceOverview = ({ campaign }: Props) => {
  const daysRemaining = moment(campaign.end_date).diff(moment(), 'days') || 0;
  const formattedCompleted = formatter.format(campaign.completed_clicks);
  const formattedContractedClicks = formatter.format(campaign.contracted_clicks);

  const advertisementDistribution = campaign.advertisement_distribution || [];
  const topPerformingAd = advertisementDistribution.sort((a, b) => b.click_distribution - a.click_distribution)[0];

  const progress = {
    current: campaign.completed_clicks,
    comparison: campaign.completed_clicks + campaign.expected_clicks_upcoming + campaign.expected_clicks_pending,
    max: campaign.contracted_clicks,
    completionLabel: 'filled',
  };

  return (
    <div className="grid lg:grid-cols-5 grid-cols-1 gap-4 p-4">
      <MetricCard
        variant="primary"
        label="Spend"
        value={`${currencyFormatter.format(campaign.spend_cents / 100)} / ${currencyFormatter.format(
          campaign.budget_cents / 100
        )}`}
        complements={[
          {
            label: 'Spend',
            value: `${currencyFormatter.format(campaign.spend_cents / 100)} (${percentFormatter.format(
              campaign.spend_cents / campaign.budget_cents
            )})`,
          },
          { label: 'Total Budget', value: currencyFormatter.format(campaign.budget_cents / 100) },
        ]}
        initialVisibleComplements={2}
      />
      <MetricCard
        variant="primary"
        label="Clicks"
        value={`${formattedCompleted} / ${formattedContractedClicks}`}
        slot={
          <>
            <div className="h-2 bg-gray-100 rounded-full relative">
              {Boolean(progress.comparison) && (
                <div
                  className="absolute h-full bg-feedback-info-200 rounded-full"
                  style={{ width: `${(Math.min(progress.comparison, progress.max) / progress.max) * 100}%` }}
                />
              )}
              <div
                className="absolute h-full bg-feedback-info-700 rounded-full"
                style={{ width: `${(Math.min(progress.current, progress.max) / progress.max) * 100}%` }}
              />
            </div>
            {progress.completionLabel && (
              <p className="text-xs text-gray-700">{`${percentFormatter.format(progress.current / progress.max)} ${
                progress.completionLabel
              }`}</p>
            )}
          </>
        }
        complements={[
          { label: 'Remaining', value: formatter.format(campaign.contracted_clicks - campaign.completed_clicks) },
          { label: 'Upcoming', value: formatter.format(campaign.expected_clicks_upcoming) },
          { label: 'Total', value: formatter.format(campaign.total_clicks) },
          { label: 'Pending', value: formatter.format(campaign.expected_clicks_pending) },
          { label: 'Days remaining', value: daysRemaining },
          { label: 'Contracted', value: formatter.format(campaign.contracted_clicks) },
          { label: 'Delivered', value: formatter.format(campaign.completed_clicks) },
        ]}
        initialVisibleComplements={2}
      />
      {campaign.engagement && (
        <MetricCard
          variant="primary"
          label="Impressions"
          value={formatter.format(campaign.engagement.total_impressions)}
          complements={[
            { label: 'Total Unique Opened', value: formatter.format(campaign.engagement.total_unique_opened) },
            { label: 'Total Web Views', value: formatter.format(campaign.engagement.total_web_viewed) },
            { label: 'Total Opened', value: formatter.format(campaign.engagement.total_opened) },
          ]}
          initialVisibleComplements={2}
        />
      )}
      <MetricCard
        variant="primary"
        label="Placements"
        value={formatter.format(campaign.placed_ad_count)}
        complements={[
          ...advertisementDistribution
            .sort((a, b) => b.placed_distribution - a.placed_distribution)
            .map((ad) => ({
              label: ad.name,
              value: `${formatter.format(ad.total_placed)} (${ad.placed_distribution}%)`,
            })),
          { label: 'Upcoming', value: formatter.format(campaign.accepted_ad_count) },
          { label: 'Pending', value: formatter.format(campaign.pending_ad_count) },
        ]}
        initialVisibleComplements={2}
      />
      <MetricCard
        variant="primary"
        label="Top performing ad"
        value={topPerformingAd?.name || 'N/A'}
        complements={[
          ...advertisementDistribution
            .sort((a, b) => b.click_distribution - a.click_distribution)
            .map((ad) => ({
              label: ad.name,
              value: `${formatter.format(ad.total_clicked)} (${ad.click_distribution}%)`,
            })),
        ]}
        initialVisibleComplements={2}
        tooltip="Percentages shown are based on the number of clicks for each ad version. A version with 50% means that half of all clicks from the campaign came from that version."
      />
    </div>
  );
};

export default CampaignPerformanceOverview;
