import moment from 'moment-mini';

import { AdNetworkOpportunityStatus } from '@/interfaces/ad_network/internal/opportunity';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { Badge, BadgeSize, BadgeType } from '@/ui/Badge';

interface OpportunityBadgeProps {
  opportunity: AdNetworkOpportunity;
}

const OpportunityBadge = ({ opportunity }: OpportunityBadgeProps) => {
  const expiringSoon =
    opportunity.status === AdNetworkOpportunityStatus.PENDING &&
    moment().add(7, 'day').isAfter(moment(opportunity.deadline));

  const expiringVerySoon = expiringSoon && moment().add(1, 'day').isAfter(moment(opportunity.deadline));

  const text = {
    [AdNetworkOpportunityStatus.DRAFT]: 'Draft',
    [AdNetworkOpportunityStatus.PENDING]: 'New',
    [AdNetworkOpportunityStatus.ACCEPTED]: 'Accepted',
    [AdNetworkOpportunityStatus.REJECTED]: 'Declined',
    [AdNetworkOpportunityStatus.CANCELED]: 'Canceled',
    [AdNetworkOpportunityStatus.EXPIRED]: 'Expired',
  }[opportunity.status as AdNetworkOpportunityStatus];

  const type = {
    [AdNetworkOpportunityStatus.DRAFT]: BadgeType.DEFAULT_FILLED,
    [AdNetworkOpportunityStatus.PENDING]: BadgeType.TERTIARY_LINED,
    [AdNetworkOpportunityStatus.ACCEPTED]: BadgeType.SUCCESS,
    [AdNetworkOpportunityStatus.REJECTED]: BadgeType.DANGER,
    [AdNetworkOpportunityStatus.CANCELED]: BadgeType.DANGER,
    [AdNetworkOpportunityStatus.EXPIRED]: BadgeType.DANGER,
  }[opportunity.status as AdNetworkOpportunityStatus];

  return (
    <div className="space-x-2">
      <Badge text={text} type={type} size={BadgeSize.SM} />
      {expiringSoon && (
        <Badge
          text={`Expires in ${moment(opportunity.deadline).fromNow(true)}`}
          type={expiringVerySoon ? BadgeType.WARNING : BadgeType.DEFAULT_LINED}
          size={BadgeSize.SM}
        />
      )}
    </div>
  );
};

export default OpportunityBadge;
