import { useMemo } from 'react';
import { ArrowTrendingUpIcon, FireIcon } from '@heroicons/react/20/solid';
import { Color } from '@tremor/react';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import ChartHeader from '@/components/Chartsv2/ChartHeader';
import { Color as DesignTokenColor } from '@/components/DesignTokens';
import useGrowthPerPeriod from '@/hooks/boosts/grow/useGrowthPerPeriod';
import usePublicationInsightsPerPeriod from '@/hooks/boosts/grow/usePublicationInsightsPerPeriod';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Option } from '@/interfaces/general';
import pluralize from '@/utils/pluralize';
import { timePeriodLabels } from '@/utils/timePeriods';

import KPI from './KPI';

const NEW_SUBSCRIBERS_CATEGORY: Option = {
  value: 'new_subscribers',
  label: 'New Subscribers',
};
const PREVIOUS_SUBSCRIBERS_CATEGORY: Option = {
  value: 'previous_subscribers',
  label: 'Previous Subscribers',
};

const CHART_CATEGORIES = ['New Subscribers', 'Previous Subscribers'];
const CHART_COLORS: Color[] = ['violet', 'gray'];

const GrowthPerPeriodChart = () => {
  const { period, periodLabel, TimePeriodSelect } = useTimePeriodSelect();
  const { data: growthData, isLoading: isGrowthDataLoading } = useGrowthPerPeriod(
    period as keyof typeof timePeriodLabels
  );
  const { data: publicationInsightsData } = usePublicationInsightsPerPeriod(period as keyof typeof timePeriodLabels);

  const totalNewSubscribers = useMemo(() => {
    return growthData?.metrics.reduce((pv, cv) => pv + cv.new_subscribers, 0);
  }, [growthData]);

  const mappedChartData = useMemo(() => {
    if (isGrowthDataLoading || typeof growthData !== 'object') {
      return [];
    }

    return growthData?.metrics.map((row) => {
      return {
        date: row.name,
        [NEW_SUBSCRIBERS_CATEGORY.label]: row.new_subscribers,
        [PREVIOUS_SUBSCRIBERS_CATEGORY.label]: row.previous_subscribers,
      };
    });
  }, [growthData, isGrowthDataLoading]);

  return (
    <ChartCard
      noResultsText={isGrowthDataLoading ? 'Fetching...' : 'No growth data available within the chosen time range.'}
      noResults={mappedChartData.length === 0}
    >
      <div className="flex flex-col gap-y-5">
        <ChartHeader
          title="New subscribers from Boosts"
          description="Break down over time of how you have grown your audience via Boosts."
          kpiLabel={pluralize('new subscriber', totalNewSubscribers || 0, undefined, true)}
          kpiValue={totalNewSubscribers || 0}
          timePeriodSelector={<TimePeriodSelect />}
          Icon={ArrowTrendingUpIcon}
          iconColor="text-action-tertiary-500"
          textColor={DesignTokenColor.TERTIARY}
        />
        <AreaChart
          index="date"
          data={mappedChartData}
          categories={CHART_CATEGORIES}
          colors={CHART_COLORS}
          showLegend={false}
          showXAxis
          showYAxis
          showGridLines
        />
        {publicationInsightsData?.highest_growth_driver && (
          <KPI
            Icon={FireIcon}
            title="Highest driver of growth"
            publicationName={publicationInsightsData.highest_growth_driver.name}
            periodLabel={periodLabel}
            iconColor="text-action-tertiary-900"
          />
        )}
      </div>
    </ChartCard>
  );
};

export default GrowthPerPeriodChart;
