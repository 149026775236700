import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  isLoading?: boolean;
}>;

const ChildLoader = ({ isLoading, children }: Props) => {
  return <div>{isLoading ? null : children}</div>;
};

export default ChildLoader;
