import RadiusIcon from '@/components/Icons/RadiusIcon';
import { useUpdateWebTemplate } from '@/hooks';
import { WebTheme } from '@/interfaces/web_template';

import { RADIUS_OPTIONS, SHADOW_OPTIONS } from '../../_types/options';
import { usePageContext } from '../Context/PageContext';
import SelectInput from '../Form/SelectInput';
import { SectionGroupingSlideOut } from '../Helpers/SectionGroupingSlideOut';

const RadiusSection = () => {
  const { handleSaved, webTemplate } = usePageContext();

  const webTemplateMutation = useUpdateWebTemplate(() => handleSaved());
  const webTheme: WebTheme | any | undefined = webTemplate?.web_theme_preview;
  const webThemWithDefaults: WebTheme | undefined = webTemplate?.web_theme_preview_with_defaults;

  const handleUpdateWebTheme = (key: string, value: string | boolean) => {
    webTemplateMutation.mutate({
      web_theme_preview: {
        ...(webTheme as WebTheme),
        [key]: value,
      },
    });
  };

  return (
    <SectionGroupingSlideOut
      title="Radius & Shadows"
      sectionIcon={<RadiusIcon className="h-3.5 w-3.5" />}
      highlightProps={{
        sectionId: 'global-radius',
      }}
    >
      <div className="flex flex-col space-y-3">
        <SelectInput
          label="Border Radius"
          value={webThemWithDefaults?.radius || ''}
          options={RADIUS_OPTIONS}
          onSave={(value: string) => handleUpdateWebTheme(`radius`, value)}
        />
        <SelectInput
          label="Shadow"
          tooltip={{
            id: 'shadow',
            text: 'Applied to logos across the site.',
          }}
          value={webThemWithDefaults?.shadow === 'none' ? '' : webThemWithDefaults?.shadow || ''}
          options={SHADOW_OPTIONS}
          onSave={(value: string) => handleUpdateWebTheme('shadow', value)}
        />
      </div>
    </SectionGroupingSlideOut>
  );
};

export default RadiusSection;
