import { Styled } from './styled';

export const LoadingDots = () => {
  return (
    <Styled.Dots>
      <Styled.Dot />
      <Styled.Dot />
      <Styled.Dot />
    </Styled.Dots>
  );
};

export default LoadingDots;
