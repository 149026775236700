import { useCallback } from 'react';
import { Editor } from '@tiptap/core';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';

import { FieldSet } from '../../ui/Input';
import { Panel, PanelHeader, PanelSection } from '../../ui/Panel/styled';

export const Attributes = ({
  editor,
  onBack,
  compact = false,
}: {
  editor: Editor;
  onBack: Function;
  compact?: boolean;
}) => {
  const currentSettings = editor.getAttributes('rss').settings;

  const handleToggle = useCallback(
    (settingName: string) => {
      const newSettings = { ...currentSettings, [settingName]: !currentSettings[settingName] };
      editor.chain().updateAttributes('rss', { settings: newSettings }).run();
    },
    [editor, currentSettings]
  );

  return (
    <Panel>
      {!compact && (
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            Attributes
          </Button>
        </PanelHeader>
      )}
      <PanelSection>
        <FieldSet>
          <ToggleWrapper
            icon={<Icon name="Heading" />}
            switchEl={
              <ToggleSwitch isChecked={currentSettings.displayTitle} onChange={() => handleToggle('displayTitle')} />
            }
          >
            Show title
          </ToggleWrapper>
          <ToggleWrapper
            icon={<Icon name="Calendar" />}
            switchEl={
              <ToggleSwitch
                isChecked={currentSettings.displayPubDate}
                onChange={() => handleToggle('displayPubDate')}
              />
            }
          >
            Show publication date
          </ToggleWrapper>
          <ToggleWrapper
            icon={<Icon name="Tag" />}
            noFill
            switchEl={
              <ToggleSwitch
                isChecked={currentSettings.displayCategories}
                onChange={() => handleToggle('displayCategories')}
              />
            }
          >
            Show categories
          </ToggleWrapper>
          <ToggleWrapper
            icon={<Icon name="Person" />}
            switchEl={
              <ToggleSwitch isChecked={currentSettings.displayAuthor} onChange={() => handleToggle('displayAuthor')} />
            }
          >
            Show author
          </ToggleWrapper>
          <ToggleWrapper
            icon={<Icon name="Subtitle" />}
            switchEl={
              <ToggleSwitch
                isChecked={currentSettings.displayDescription}
                onChange={() => handleToggle('displayDescription')}
              />
            }
          >
            Show description
          </ToggleWrapper>
          <ToggleWrapper
            icon={<Icon name="TextOnly" />}
            switchEl={
              <ToggleSwitch
                isChecked={currentSettings.displayContent}
                onChange={() => handleToggle('displayContent')}
              />
            }
          >
            Show full article
          </ToggleWrapper>
        </FieldSet>
      </PanelSection>
    </Panel>
  );
};
