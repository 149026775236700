import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PageVersion } from '@/interfaces/dream_builder/page_version';

import api from '../../services/swarm';

export default function usePageVersion({
  pageId,
  pageVersionId,
  enabled = true,
}: {
  pageId?: string;
  pageVersionId?: string;
  enabled?: boolean;
}) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPageVersion = () =>
    api
      .get(`/pages/${pageId}/page_versions/${pageVersionId}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<PageVersion>({
    queryKey: ['publications', currentPublicationId, 'page_versions', pageVersionId],
    queryFn: fetchPageVersion,
    enabled,
  });
}
