import { PermissionLevel, PermissionResource, Permissions } from '@/interfaces/permissions';

const useCheckPublicationPermissions = (
  permissions: Permissions,
  resource: PermissionResource,
  permission: PermissionLevel
) => {
  const keys = resource.split('/');

  // Walk down permissions tree and return permissions array (or empty if not found)
  const permissionValues = keys.reduce((obj: any, key: string) => {
    return obj && obj[key] !== undefined ? obj[key] : undefined;
  }, permissions);

  return Array.isArray(permissionValues) ? permissionValues.includes(permission) : false;
};

export default useCheckPublicationPermissions;
