import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentTimeZone } from '@/hooks';
import api from '@/services/swarm';
import { TimePeriod } from '@/utils';

interface ChartData {
  id: string;
  name: string;
  offer_type: string;
  redemptions: number;
}

interface ApiResponse {
  chart_data: ChartData[];
  period: TimePeriod;
  time_zone: string;
}

const useOfferEffectivenessByPeriod = (period: TimePeriod) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const currentTimeZone = useCurrentTimeZone();

  const fetchTierChangeData = () => {
    return api
      .get(`/premium_data/offer_effectiveness_per_period/${period}`, {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
        },
      })
      .then((res) => res.data);
  };

  return useQuery<ApiResponse>(
    ['premium_data', 'offerEffectivenessPerPeriod', currentPublicationId, period, currentTimeZone],
    fetchTierChangeData,
    {
      keepPreviousData: true,
    }
  );
};

export default useOfferEffectivenessByPeriod;
