import { RefObject, useEffect, useState } from 'react';
import { ArrowDownTrayIcon, ShareIcon } from '@heroicons/react/20/solid';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver';
// eslint-disable-next-line import/no-extraneous-dependencies
import JSZip from 'jszip';
import { domToBlob } from 'modern-screenshot';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Publication } from '@/interfaces/publication';
import { PublicationRewind } from '@/interfaces/publication_rewind';
import { Button } from '@/ui/Button';

import { End, Landing, View2, View3, View4, View5, View6, View7, View8, View9 } from './views';

import './style.css';
import 'flag-icons/css/flag-icons.min.css';

interface Props {
  onClose: () => void;
  data?: PublicationRewind;
  currentPublication?: Publication;
}

interface SlideRefObject {
  index: number;
  ref: RefObject<HTMLDivElement>;
}

const WhiteLoadingSpinner = () => {
  return <LoadingSpinner color="white" />;
};

const Rewind2024 = ({ currentPublication, data, onClose }: Props) => {
  const [viewIndex, setViewIndex] = useState(0);
  const [contentScale, setContentScale] = useState(1);
  const [slideRef, setSlideRef] = useState<RefObject<HTMLDivElement> | null>(null);
  const [slideRefObjects, setSlideRefObjects] = useState<SlideRefObject[]>([]);
  const [processing, setProcessing] = useState(false);
  const noRevenue = (data?.subscription_revenue || 0) + (data?.ads_revenue || 0) + (data?.boosts_revenue || 0) === 0;
  const noTopPosts = data?.top_posts?.length === 0;
  const noMostClickedPost = !data?.most_clicked_post;
  const noTopCountries = data?.top_countries?.length === 0;
  let indexOffset = noRevenue ? 1 : 0;

  if (noTopPosts) {
    indexOffset += 1;
  }

  if (noMostClickedPost) {
    indexOffset += 1;
  }

  if (noTopCountries) {
    indexOffset += 1;
  }

  const views = [];

  views.push(<Landing currentViewIndex={viewIndex} index={0} key="landing" setSlideRef={setSlideRef} />);
  views.push(
    <View2
      key="view-2"
      setSlideRef={setSlideRef}
      currentPublication={currentPublication}
      data={data}
      currentViewIndex={viewIndex}
      index={1}
    />
  );
  views.push(<View3 key="view-3" setSlideRef={setSlideRef} data={data} currentViewIndex={viewIndex} index={2} />);
  views.push(<View4 key="view-4" setSlideRef={setSlideRef} data={data} currentViewIndex={viewIndex} index={3} />);
  if (!noRevenue) {
    views.push(<View5 key="view-5" setSlideRef={setSlideRef} data={data} currentViewIndex={viewIndex} index={4} />);
  }
  if (!noTopPosts) {
    views.push(
      <View6
        key="view-6"
        setSlideRef={setSlideRef}
        data={data}
        currentViewIndex={viewIndex}
        index={5 - indexOffset}
        currentPublication={currentPublication}
      />
    );
  }

  if (!noMostClickedPost) {
    views.push(
      <View7
        key="view-7"
        setSlideRef={setSlideRef}
        data={data}
        currentViewIndex={viewIndex}
        index={6 - indexOffset}
        currentPublication={currentPublication}
      />
    );
  }

  if (!noTopCountries) {
    views.push(
      <View8 key="view-8" setSlideRef={setSlideRef} data={data} currentViewIndex={viewIndex} index={7 - indexOffset} />
    );
  }

  views.push(
    <View9 key="view-9" setSlideRef={setSlideRef} data={data} currentViewIndex={viewIndex} index={8 - indexOffset} />
  );

  views.push(<End key="view-end" setSlideRef={setSlideRef} currentViewIndex={viewIndex} index={9 - indexOffset} />);

  const handleNext = () => {
    if (viewIndex < views.length - 1) {
      setViewIndex(viewIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleShare = async () => {
    if (!slideRef?.current) return;

    if (processing) return;

    try {
      setProcessing(true);
      const blob = await domToBlob(slideRef.current, {
        width: 1080,
        height: 1080,
        quality: 1,
      });

      if (blob) {
        if ((window as any).saveAs) {
          (window as any).saveAs(blob, `beehiiv-rewind-slide-${viewIndex + 1}.png`);
        } else {
          saveAs(blob, `beehiiv-rewind-slide-${viewIndex + 1}.png`);
        }
      }
    } catch (error) {
      console.log('Error saving slide image: ', error);
    } finally {
      setProcessing(false);
    }
  };

  const handleDownloadSlides = async () => {
    if (processing) return;

    setProcessing(true);

    const zip = new JSZip();

    try {
      for (let i = 0; i < slideRefObjects.length; i += 1) {
        const slideRefObj = slideRefObjects[i];
        const curRef = slideRefObj.ref;

        if (curRef.current) {
          // eslint-disable-next-line no-await-in-loop
          const blob = await domToBlob(curRef.current, { width: 1080, height: 1080, quality: 1 });

          if (blob) {
            zip.file(`beehiiv-rewind-slide-${i + 1}.png`, blob);
          }
        }
      }

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'beehiiv-rewind-2024.zip');
      });
    } catch (error) {
      console.log('Error downloading slides: ', error);
    } finally {
      setProcessing(false);
    }
  };

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const isLandscape = screenWidth > screenHeight;

    if (isLandscape) {
      let newScale = (screenHeight - 150) / 1080;
      if (newScale > 1) {
        newScale = 1;
      }
      setContentScale(newScale);
    } else {
      let newScale = (screenWidth - 40) / 1080;
      if (newScale > 1) {
        newScale = 1;
      }

      setContentScale(newScale);
    }
  };

  useEffect(() => {
    if (slideRef && !slideRefObjects.find((sr) => sr.index === viewIndex)) {
      const newSlideRefs = [...slideRefObjects, { index: viewIndex, ref: slideRef }];
      setSlideRefObjects(newSlideRefs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="rounded-lg relative landscape:w-[calc(100vh-150px)] landscape:h-[calc(100vh-80px)] portrait:w-[calc(100vw-40px)] portrait:h-[calc(100vw+30px)] landscape:max-w-[1080px] landscape:max-h-[1080px] portrait:max-w-[1080px] portrait:max-h-[1080px] maxoverflow-hidden">
      <div
        className="absolute w-[1080px] h-[1080px] overflow-hidden origin-top-left top-0 left-0"
        style={{ transform: `scale(${contentScale})` }}
      >
        <div
          className="relative top-0 left-0 w-full h-full"
          onClick={handleNext}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleNext();
            }
          }}
        >
          <div
            className="absolute top-0 left-0 flex overflow-hidden h-full transition-transform"
            style={{
              transform: `translateX(calc(-${100 / views.length}%*${viewIndex}))`,
              width: `calc(100%*${views.length})`,
            }}
          >
            {views.map((view) => {
              return view;
            })}
          </div>
        </div>
      </div>
      <footer className="flex p-4 gap-1 md:gap-3 justify-between absolute bottom-0 w-full">
        <Button type="button" variant="primary-inverse" className="cursor-pointer" onClick={handleClose}>
          Close
        </Button>
        {viewIndex < views.length - 1 && (
          <Button
            Icon={processing ? LoadingSpinner : ShareIcon}
            type="button"
            variant="primary-inverse"
            className="cursor-pointer"
            onClick={handleShare}
          >
            Share slide
          </Button>
        )}
        {viewIndex > 0 && (
          <Button
            variant="primary-inverse"
            type="button"
            className={cx('cursor-pointer ml-auto')}
            onClick={handlePrevious}
          >
            <ArrowLeftIcon className="h-5 w-5 text-surface-400" />
          </Button>
        )}
        {viewIndex === views.length - 1 && (
          <Button
            Icon={processing ? WhiteLoadingSpinner : ArrowDownTrayIcon}
            type="button"
            variant="tertiary"
            className="cursor-pointer"
            onClick={handleDownloadSlides}
          >
            Download slides
          </Button>
        )}
        {viewIndex < views.length - 1 && (
          <Button type="button" className={cx(viewIndex === 0 && 'ml-auto', 'cursor-pointer')} onClick={handleNext}>
            <ArrowRightIcon className="h-5 w-5" />
          </Button>
        )}
      </footer>
    </div>
  );
};

export default Rewind2024;
