import { Check, File } from '@phosphor-icons/react';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useWebsiteContext } from '@/context/website-context';

import { Button } from '../../UI/Button';
import { Text } from '../../UI/Text';
import { SaveVersionNavbarPopup, SaveVersionPagePopup } from '../SaveVersionPopup';
import { SaveVersionFooterPopup } from '../SaveVersionPopup/SaveVersionFooterPopup';

export const SaveIndicator = ({
  changesMade,
  onSave,
  isSaveLoading,
  type,
}: {
  changesMade: boolean;
  onSave: () => void;
  isSaveLoading: boolean;
  type: 'page' | 'navbar' | 'footer' | 'template';
}) => {
  const { previewSiteVersion } = useWebsiteContext();

  if (previewSiteVersion) {
    return null;
  }

  const renderStatus = () => {
    if (isSaveLoading) {
      return (
        <div className="flex flex-1 items-center gap-1">
          <LoadingSpinner className="text-wb-secondary" />
          <Text variant="secondary" size="xs" weight="medium">
            Saving...
          </Text>
        </div>
      );
    }

    if (changesMade) {
      return (
        <div className="flex flex-1 items-center gap-1">
          <File size={16} weight="bold" className="text-wb-accent" />
          <Text variant="accent" size="xs" weight="medium">
            Draft
          </Text>
        </div>
      );
    }

    return (
      <div className="flex flex-1 items-center gap-1">
        <Check size={16} weight="bold" className="text-wb-success" />
        <Text variant="success" size="xs" weight="medium">
          Saved
        </Text>
      </div>
    );
  };
  return (
    <div className="flex items-center gap-0 w-full pl-3 pr-2 py-2 rounded-lg shadow-xl border border-solid border-border bg-wb-primary">
      {renderStatus()}
      <Button
        variant="ghost"
        size="sm"
        isDisabled={isSaveLoading}
        onClick={onSave}
        className="hover:text-wb-primary p-1"
      >
        Save
      </Button>

      {type === 'page' ? <SaveVersionPagePopup /> : null}
      {type === 'navbar' ? <SaveVersionNavbarPopup /> : null}
      {type === 'footer' ? <SaveVersionFooterPopup /> : null}
      {/* Template cannot save version, so no need to have a SaveVersionPopup */}
    </div>
  );
};
