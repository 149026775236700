import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/24/outline';

import { Button } from '@/ui/Button';

import ActionModal from '../../../../../../components/ActionModal';
import { Input } from '../../../../../../components/Form';
import Text from '../../../../../../components/Text';
import { useUpdateSubdomain } from '../../../../../../hooks/useSubdomain';
import { CustomDomain, CustomDomainTypes } from '../../../../../../interfaces/custom_domain';
import { Publication } from '../../../../../../interfaces/publication';
import { Section } from '../../../../Components';
import NotEditing from '../../../../Components/NotEditing';
import SelectedDomainDetails from '../SelectedDomainDetails';

interface Props {
  publication: Publication;
  domain?: CustomDomain;
}

const WebDomainSection = ({ publication, domain }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [subdomain, setSubdomain] = useState<string>(publication.subdomain || '');
  const [newSubdomain, setNewSubdomain] = useState<string>(subdomain);
  const subdomainMutation = useUpdateSubdomain();

  const onSave = async () => {
    try {
      await subdomainMutation.mutateAsync({ subdomain: newSubdomain });

      toast.success('Saved!');
      setIsModalOpen(false);
      setSubdomain(newSubdomain);
    } catch (error) {
      toast.error('Error saving subdomain');
    }
  };

  useEffect(() => {
    setSubdomain(publication.subdomain || '');
    setNewSubdomain(publication.subdomain || '');
  }, [publication.subdomain]);

  if (!domain?.verified) {
    return (
      <Section
        title="Web Domain"
        description="Add a custom domain for your website, or use a customizable subdomain attached to beehiiv.com"
        scrollToId="web-domain"
      >
        <SelectedDomainDetails domain={domain} type={CustomDomainTypes.WEB} />
        <div className="flex justify-between pt-4">
          <NotEditing label="Website URL" value={`${subdomain}.beehiiv.com`} />
          <Button Icon={PencilIcon} variant="primary-inverse" onClick={() => setIsModalOpen(true)}>
            Update url
          </Button>
        </div>

        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isWorking={subdomainMutation.isLoading}
          onProceed={onSave}
          resourceId={subdomain}
          headerText="Update Web Url"
        >
          <div className="flex flex-col">
            <p className="block text-sm font-medium text-gray-700 mb-1">Website URL</p>
            <div className="flex">
              <Input
                className="w-full"
                name="subdomain"
                inputClassOverride="appearance-none block w-full px-3 py-2 border rounded-l-md placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed"
                value={newSubdomain}
                onChange={(e) => setNewSubdomain(e.target.value)}
              />
              <p className="pt-1.5 px-2 bg-gray-50 border border-gray-300 border-l-0 rounded-r-md text-gray-500">
                .beehiiv.com
              </p>
            </div>
            <Text size="xs" className="mt-2 text-gray-500">
              This is your publication&apos;s live URL, accessible for anyone to visit.
            </Text>
            <Text size="xs" className="mt-2 text-red-500">
              <b>NOTE:</b> Changing this will update all of your posts to the new web URL. This means that URLs to
              previously published posts will be outdated and no longer work.
            </Text>
          </div>
        </ActionModal>
      </Section>
    );
  }

  return (
    <Section
      title="Web Domain"
      description="You can use your own domain to send emails from your publication. You can also use your own domain to host your publication’s website."
      scrollToId="web-domain"
    >
      <div className="my-6">
        <SelectedDomainDetails domain={domain} type={CustomDomainTypes.WEB} />
      </div>
    </Section>
  );
};

export default WebDomainSection;
