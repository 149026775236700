import { Fragment, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Dialog, Transition } from '@headlessui/react';
import { useDebounce } from 'use-debounce';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import useBoostInvites from '@/hooks/boosts/grow/useBoostInvites';
import usePublicationSearch from '@/hooks/boosts/grow/usePublicationSearch';
import { BoostInvite } from '@/interfaces/boosts/grow/boost_invite';
import { Tab } from '@/interfaces/general';
import { PublicationSearchWithBoostInvite } from '@/interfaces/publication_search';

import BoostInvitesHeader from './BoostInvitesHeader';
import SearchPublication from './SearchPublication';

export const castToPublicationSearch = (invite: BoostInvite): PublicationSearchWithBoostInvite<BoostInvite> => {
  return {
    id: invite.recommending_publication.id,
    name: invite.recommending_publication.name,
    description: invite.recommending_publication.description || '',
    logo_url: invite.recommending_publication.logo_url,
    hostname: invite.recommending_publication.hostname,
    tags: invite.recommending_publication.tags.map((tag) => tag.name),
    boost_invite: invite,
  };
};

interface Props {
  boostOfferId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSuccess: () => void;
}

const AddBoostInvite = ({ boostOfferId, isOpen, setIsOpen, onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const [currentTab, setCurrentTab] = useState('invite-publications');
  const [query, setQuery] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');
  const [debouncedQuery] = useDebounce(query, 450);
  const [debouncedCountry] = useDebounce(country, 450);
  const [debouncedLanguage] = useDebounce(language, 450);
  const { data: boostInvitesData } = useBoostInvites({ fetchAllResults: true }); // Maximum number of invites (50) in a month
  const { data, isFetching } = usePublicationSearch({
    query: debouncedQuery,
    country: debouncedCountry,
    language: debouncedLanguage,
  });
  const publications = data?.pages.flatMap((page) => page.publications) || [];
  const sentInvites = boostInvitesData?.pages.flatMap((page) => page.boost_invites) || [];
  const invitedPublications = sentInvites.map(castToPublicationSearch);

  const handleSubmitInviteForm = () => {
    onSuccess();
    queryClient.invalidateQueries([
      'publications',
      currentPublicationId,
      'boosts',
      'grow',
      'publication_search',
      query,
    ]);
  };

  const handleDeleteInvite = () => {
    queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_invites']);
  };

  const handleSwitchTab = (tab: string) => {
    setCurrentTab(tab);
    queryClient.invalidateQueries([
      'publications',
      currentPublicationId,
      'boosts',
      'grow',
      'publication_search',
      query,
    ]);
    queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_invites']);
  };

  const handleSetQuery = (value: string) => {
    setQuery(value);
    setCurrentTab('invite-publications');
  };

  const tabs = [
    {
      name: 'invite-publications',
      label: 'Invite Publications',
      onSelect: () => handleSwitchTab('invite-publications'),
      selected: currentTab === 'invite-publications',
    },
    {
      name: 'invites-sent',
      label: 'Invites Sent',
      onSelect: () => handleSwitchTab('invites-sent'),
      selected: currentTab === 'invites-sent',
    },
  ] as Tab[];

  return (
    <Transition.Root show={isOpen} as={Fragment} appear>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opcity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <SearchPublication
                key={`search-publications-${currentTab}`}
                boostOfferId={boostOfferId}
                header={<BoostInvitesHeader />}
                tabs={tabs}
                publications={currentTab === 'invite-publications' ? publications : invitedPublications}
                isFetchingPublications={isFetching}
                onSetQuery={handleSetQuery}
                onSetCountry={setCountry}
                onSetLanguage={setLanguage}
                query={query}
                language={language}
                country={country}
                onSubmitInvite={handleSubmitInviteForm}
                onDeleteInvite={handleDeleteInvite}
              />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddBoostInvite;
