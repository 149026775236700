import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import { Card } from '@/ui/Card';

import PageContainer from '../../../../_components/PageContainer';
import { usePublicationSettings } from '../../../context';
import Header from '../Header';

import EditForm from './EditForm';

const EditOptInEmail: React.FC = () => {
  const { currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);
  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId} isWide>
          <Header currentPublicationId={currentPublicationId} showEdit />
          <Card>
            <EditForm publication={publication} />
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default EditOptInEmail;
