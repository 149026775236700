import countries from '@/utils/countries.json';

const mappedCountries: { [key: string]: string } = countries.reduce((pv, cv) => {
  return {
    ...pv,
    [cv.code]: cv.name,
  };
}, {});

export default mappedCountries;
