/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import cx from 'classnames';

import { Input as BaseInput } from '../../../../../../components/Form';
import { useControlledInput } from '../../../../../../hooks';
import { Props } from '../../../../../../ui/TextInput';

interface InputProps extends Props {
  control: ReturnType<typeof useControlledInput>;
}

const Input = React.forwardRef(
  ({ className, control, ...props }: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => (
    <div className="mt-6 flex flex-col lg:flex-row">
      <div className="flex-grow space-y-6">
        <BaseInput
          {...props}
          className={cx(className || 'col-span-12 sm:col-span-6 w-4/5')}
          value={control.state}
          onChange={control.handleChange}
          ref={ref}
        />
      </div>
    </div>
  )
);

export default Input;
