import cx from 'classnames';

interface Props {
  labels: string[];
  stepProgressions: number[];
  currentStep: number;
}

const StepBar = (props: Props) => {
  const { labels, stepProgressions, currentStep } = props;
  const progression = stepProgressions[currentStep - 1];
  const width = `${progression}%`;
  const textLabels = labels.filter((string) => string.length > 0);
  const numTextLabels = textLabels.length;
  const numLabels = labels.length;
  const activeLabelIndex = Math.ceil((numLabels / 100) * progression);

  return (
    <div className="w-full mx-auto mb-12" aria-hidden="true">
      <div className="overflow-hidden rounded-full bg-action-secondary-100">
        <div className="h-1.5 rounded-full bg-primary-600 duration-500" style={{ width }} />
      </div>
      <div className={cx('mt-2 hidden text-xs sm:grid', `grid-cols-${numTextLabels}`)}>
        {textLabels.map((label) => {
          const labelIndex = labels.indexOf(label) + 1;

          return (
            <div
              key={label}
              className={cx(
                labelIndex === 1 && 'text-left',
                labelIndex === labels.length && 'text-right',
                labelIndex > 1 && labelIndex < numTextLabels && 'text-center',
                labelIndex <= activeLabelIndex ? 'font-medium text-primary-600' : 'font-normal text-gray-600',
                'text-xs'
              )}
            >
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepBar;
