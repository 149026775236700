import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { StripeMigrationPrice } from '@/interfaces/stripe';
import api from '@/services/swarm';

interface ApiResponse {
  prices: StripeMigrationPrice[];
}

const useTargetPrices = (stripeMigrationId?: string) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<ApiResponse>(
    ['stripe_migrations', stripeMigrationId, 'target_prices'],
    () =>
      api
        .get(`/stripe_migrations/${stripeMigrationId}/target_prices`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!stripeMigrationId,
    }
  );
};

export default useTargetPrices;
