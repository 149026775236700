import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import LoadingBox from '@/components/LoadingBox';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';

import useExportStripeMigrationPreview from '../_hooks/useExportStripeMigrationPreview';
import useStripeMigrationPreview from '../_hooks/useStripeMigrationPreview';

const PreviewSubscriptionsTable: FC = () => {
  const { stripeMigrationId } = useParams();
  const [page, setPage] = useState(1);

  const { data, isLoading, isError } = useStripeMigrationPreview(stripeMigrationId, page);
  const { downloadExport, isDownloadingExport } = useExportStripeMigrationPreview(stripeMigrationId);

  const subscriptions = useMemo(() => data?.records || [], [data]);
  const pagination = useMemo(() => data?.pagination || { total: 0, total_pages: 0 }, [data]);
  const hasResults = pagination && pagination.total > 0;

  const hasNextPage = page < pagination.total_pages;
  const hasPreviousPage = page > 1;

  if (!isLoading && !isError && !hasResults) {
    return <Typography>It looks like no subscriptions were found tied to the products you selected.</Typography>;
  }

  return (
    <LoadingBox
      isLoading={isLoading}
      isError={isError}
      errorText="The preview for your migration as not finished generating. You can try refreshing the page in a minute."
    >
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between items-center">
          <Typography as="h3" token="font-semibold/text/xl">
            {pagination.total} Subscriptions
          </Typography>
          <Button variant="primary-inverse" loading={isDownloadingExport} onClick={downloadExport}>
            Export Preview
          </Button>
        </div>

        <Items>
          <ItemHeaders>
            <ItemHeader>Customer Email</ItemHeader>
            <ItemHeader>Next Bill Date</ItemHeader>
            <ItemHeader>Cancel Date</ItemHeader>
            <ItemHeader>Source Product</ItemHeader>
            <ItemHeader>Beehiiv Product</ItemHeader>
            <ItemHeader>Tier</ItemHeader>
            <ItemHeader>Discounted?</ItemHeader>
            <ItemHeader>New?</ItemHeader>
          </ItemHeaders>
          <ItemsBody>
            {subscriptions.map((subscription) => (
              <ItemRow key={subscription.id}>
                <ItemColumn align={AlignType.MIDDLE}>{subscription.customer_email}</ItemColumn>
                <ItemColumn align={AlignType.MIDDLE}>
                  {subscription.is_canceled ? '-' : moment.unix(subscription.current_period_end).format('lll')}
                </ItemColumn>
                <ItemColumn align={AlignType.MIDDLE}>
                  {subscription.is_canceled ? moment.unix(subscription.cancel_at).format('lll') : '-'}
                </ItemColumn>
                <ItemColumn align={AlignType.MIDDLE}>{subscription.source_product_label}</ItemColumn>
                <ItemColumn align={AlignType.MIDDLE}>{subscription.target_product_label}</ItemColumn>
                <ItemColumn align={AlignType.MIDDLE}>{subscription.tier_name}</ItemColumn>
                <ItemColumn align={AlignType.MIDDLE}>
                  {subscription.coupon_id && <CheckCircleIcon className="h-6 w-6" />}
                </ItemColumn>
                <ItemColumn align={AlignType.MIDDLE}>
                  {subscription.is_new && <CheckCircleIcon className="h-6 w-6" />}
                </ItemColumn>
              </ItemRow>
            ))}
          </ItemsBody>
        </Items>
        <ButtonGroup variant="primary-inverse" className="!shadow-none flex justify-end">
          <Button variant="primary-inverse" type="button" onClick={() => setPage(page - 1)} disabled={!hasPreviousPage}>
            <ChevronRightIcon className="w-5 h-5 rotate-180" />
          </Button>
          <Button variant="primary-inverse" type="button" onClick={() => setPage(page + 1)} disabled={!hasNextPage}>
            <ChevronRightIcon className="w-5 h-5" />
          </Button>
        </ButtonGroup>
      </div>
    </LoadingBox>
  );
};

export default PreviewSubscriptionsTable;
