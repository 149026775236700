import { AutomationConditionName, AutomationConditionOperator } from '@/interfaces/automations/automation_condition';

import { OPERATOR_OPTIONS_BY_CONDITION_NAME } from '../components/ConditionsModal/constants';

// Returns the operator if it is valid for the condition, otherwise returns the first supported
// operator for the condition
const conditionOperator = (conditionName: AutomationConditionName, operator: AutomationConditionOperator) => {
  const operators = OPERATOR_OPTIONS_BY_CONDITION_NAME[conditionName];

  if (operators.find((o) => o.value === operator)) {
    return operator;
  }

  return operators[0].value;
};

export default conditionOperator;
