import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import API from '@/components/TiptapEditor/lib/api';
import { Asset, AssetSource } from '@/interfaces/asset';

import { GiphyGif } from '../../../utils/giphy.types';
import { UnsplashImage } from '../../../utils/unsplash';
import { LoadingDots } from '../../TiptapEditor/components/ui/Loader/LoadingDots';

import {
  MediaLibraryContextValue,
  MediaLibraryExternalMedia,
  MediaLibraryProviderProps,
} from './MediaLibraryContext.types';

const mediaIsGiphy = (media: MediaLibraryExternalMedia): media is GiphyGif => {
  return (media as GiphyGif).images !== undefined;
};

const mediaIsUnsplash = (media: MediaLibraryExternalMedia): media is UnsplashImage => {
  return (media as UnsplashImage).urls !== undefined;
};

const MediaLibraryContext = createContext<MediaLibraryContextValue>({
  publicationId: '',
  onClose: () => {},
  onExternalMediaSelect: () => {},
  onLibraryChange: () => {},
  onMediaSelect: () => {},
  onTabChange: () => {},
  openLibraryId: undefined,
  openTab: undefined,
});

export default MediaLibraryContext;

export const useMediaLibrary = () => useContext(MediaLibraryContext);

export const MediaLibraryConsumer = MediaLibraryContext.Consumer;

export const MediaLibraryProvider = ({
  children,
  onClose,
  onMediaSelect,
  initialLibraryId,
  initialTab,
  publicationId,
}: MediaLibraryProviderProps) => {
  const [uploading, setUploading] = useState(false);
  const [tab, setTab] = useState(initialTab);
  const [libraryId, setLibraryId] = useState(initialLibraryId);

  const onExternalMediaSelect = useCallback(
    async (media: MediaLibraryExternalMedia) => {
      setUploading(true);

      let url: string | null = null;
      let source: AssetSource | undefined;
      let id: string | undefined;
      let sourceLink: string | undefined;
      let sourceDisplay: string | undefined;
      let title: string | undefined;

      if (mediaIsGiphy(media)) {
        url = media.images.downsized.url;
        source = 'giphy';
        sourceDisplay = media.username ? `Gif by ${media.username} on Giphy` : 'Giphy';
        sourceLink = media.source_post_url;
        id = media.id;
        title = media.title;
      }

      if (mediaIsUnsplash(media)) {
        url = media.urls.regular;
        source = 'unsplash';
        sourceDisplay = `Photo by ${media.user.name} on Unsplash`;
        sourceLink = media.user.links.html;
        id = media.id;
        title = media.description;
      }

      if (!url || !source || !id) {
        return;
      }

      try {
        let res: { data: Asset } = { data: {} as Asset };

        if (mediaIsGiphy(media) || mediaIsUnsplash(media)) {
          res = await API.uploadPublicationExternalAsset({
            source,
            publicationId,
            title,
            sourceDisplay,
            sourceLink,
            external_id: id,
            external_url: url,
            alt: title,
          });
        } else {
          res = await API.uploadPublicationAssetFromUrl({
            publicationId,
            url,
            sourceLink,
            sourceDisplay,
            title,
            alt: title,
          });
        }

        setUploading(false);
        onMediaSelect({ media: res.data });
      } catch (e: any) {
        setUploading(false);
        throw new Error(e);
      }
    },
    [onMediaSelect, publicationId]
  );

  const onInternalMediaSelect = useCallback(
    (asset: Asset) => {
      onMediaSelect({ media: asset });
    },
    [onMediaSelect]
  );

  const onTabChange = useCallback((newTab: MediaLibraryContextValue['openTab']) => {
    setTab(newTab);
  }, []);

  const onLibraryChange = useCallback((newId: MediaLibraryContextValue['openLibraryId']) => {
    setLibraryId(newId);
  }, []);

  const providerValue = useMemo<MediaLibraryContextValue>(
    () => ({
      onMediaSelect: onInternalMediaSelect,
      onClose,
      publicationId,
      onExternalMediaSelect,
      onTabChange,
      onLibraryChange,
      openLibraryId: libraryId,
      openTab: tab,
    }),
    [onInternalMediaSelect, onClose, publicationId, onExternalMediaSelect, onTabChange, onLibraryChange, libraryId, tab]
  );

  return (
    <MediaLibraryContext.Provider value={providerValue}>
      {children}
      {uploading && (
        <div className="absolute inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-80">
          <LoadingDots />
        </div>
      )}
    </MediaLibraryContext.Provider>
  );
};
