import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getAudioDropPlugin } from './audioDropPlugin';
import { IAudioAttrs } from './types';
import { AudioView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    audio: {
      setAudio: (attrs?: IAudioAttrs | Record<string, any>) => ReturnType;
    };
  }
}

interface IAudioAttachmentOptions {
  publicationId: string;
  userId: string;
}

export const Audio = Node.create<IAudioAttachmentOptions, {}>({
  name: 'audio',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({ 'data-id': attributes.id }),
      },
      src: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-src'),
        renderHTML: (attributes) => ({ 'data-src': attributes.src }),
      },
      size: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-size'),
        renderHTML: (attributes) => ({ 'data-size': attributes.size }),
      },
      duration: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-duration'),
        renderHTML: (attributes) => ({ 'data-duration': attributes.duration }),
      },
      type: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-type'),
        renderHTML: (attributes) => ({ 'data-type': attributes.type }),
      },
      title: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-title'),
        renderHTML: (attributes) => ({ 'data-title': attributes.title }),
      },
      thumbnailUrl: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-thumbnail-url'),
        renderHTML: (attributes) => ({ 'data-thumbnail-url': attributes.thumbnailUrl }),
      },
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
      },
      backgroundTheme: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-background-theme'),
        renderHTML: (attributes) => ({ 'data-background-theme': attributes.backgroundTheme }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addCommands() {
    return {
      setAudio:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs,
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(AudioView);
  },

  addProseMirrorPlugins() {
    const { publicationId, userId } = this.options;

    return [
      getAudioDropPlugin({
        userId,
        publicationId,
      }),
    ];
  },
});

export default Audio;
