import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { useAuth } from '@/context/auth-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useCurrentPublication } from '@/hooks';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

interface NavComponentProps {
  name: string;
  link: string;
}

interface SubDropdownProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const NavComponent = ({ name, link }: NavComponentProps) => {
  return (
    <Menu.Item>
      <Button
        onClick={() => window.open(link, '_blank')}
        type="button"
        variant="flush"
        size="xs"
        className="flex flex-row w-full justify-between items-between"
      >
        <Typography token="font-normal/text/sm">
          <span className="text-gray-700">{name}</span>
        </Typography>
        <ArrowTopRightOnSquareIcon className="my-auto h-4 w-4 text-surface-400" />
      </Button>
    </Menu.Item>
  );
};

const SubDropdown = ({ open, setOpen }: SubDropdownProps) => {
  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className={cx(
          'z-50 absolute w-52 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          'right-52 flex flex-col'
        )}
      >
        <div className="p-2 flex flex-col space-y-2">
          <NavComponent name="Knowledge Base" link="https://support.beehiiv.com/hc/en-us" />
          <NavComponent name="Tutorials" link="https://www.youtube.com/channel/UCI80tsW3wYNGEJLxwJo42vw" />
        </div>

        <div className="p-2 flex flex-col space-y-2">
          <NavComponent name="Blog" link="https://blog.beehiiv.com/" />
          <NavComponent name="Newsletter XP" link="https://www.beehiiv.com/courses/newsletter-xp" />
        </div>

        <div className="p-2 flex flex-col space-y-2">
          <NavComponent name="Product Updates" link="https://product.beehiiv.com/" />
          <NavComponent name="API Documentation" link="https://developers.beehiiv.com/" />
        </div>
      </Menu.Items>
    </Transition>
  );
};

export const UserDropdown = () => {
  const { currentUser } = useCurrentUser();
  const { data: currentPublication } = useCurrentPublication();
  const navigate = useNavigate();

  const { logout } = useAuth();
  const handleLogout = () => {
    analytics.track('Signed Out');
    logout.mutateAsync().then(() => navigate('/'));
  };

  const [isMasquerading] = useState(!!localStorage.getItem('masqueradeToken'));
  const [learnOpen, setLearnOpen] = useState<boolean>(false);

  const showSystemAdmin = Boolean(currentUser?.isSystemAdmin()) && currentPublication && !isMasquerading;
  const showAdNetworkAdmin = Boolean(currentUser?.isAdNetworkAdmin());
  const showAccountantAdmin = Boolean(currentUser?.isBeehiivAccountant());
  const hasPublicationSettingsV2 = Boolean(currentUser?.has_settings_v2);

  const userBlock = (
    <>
      <div className="flex text-start flex-row justify-between items-between w-full">
        <Typography as="span" token="font-medium/text/sm" className="w-full">
          <span className="text-gray-500 w-full truncate">{currentUser?.fullName}</span>
        </Typography>
        {!hasPublicationSettingsV2 ? (
          <ChevronRightIcon className="my-auto h-4 w-4 text-surface-400 group-hover:text-surface-900" />
        ) : null}
      </div>
      <div className="flex w-full truncate text-gray-400">
        <Typography as="span" token="font-normal/text/sm">
          <span className="text-gray-400">{currentUser?.email}</span>
        </Typography>
      </div>
    </>
  );

  const menuButtonClassNames =
    'flex flex-col py-2 px-3 bg-transparent hover:bg-surface-100 text-gray-800 hover:text-gray-900 disabled:text-gray-500 disabled:bg-transparent border-transparent rounded-md';

  return (
    <Menu as="div" className="relative my-auto h-full justify-center items-center inline-block pl-2">
      <Menu.Button className="h-full my-auto">
        <div className="rounded-full ring-2 ring-offset-2 ring-gray-50 hover:shadow-sm my-auto bg-surface-100 w-6 h-6 overflow-hidden hover:bg-surface-200">
          <img src={currentUser?.profile_picture.url} alt="avatar" className="object-cover w-full" />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cx(
            'z-50 absolute mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            'right-0',
            hasPublicationSettingsV2 ? 'w-64' : 'w-52'
          )}
        >
          <div className="p-2 flex flex-col space-y-1 truncate">
            <div className="w-full truncate text-gray-400">
              {hasPublicationSettingsV2 ? (
                <div className="w-full group flex flex-col justify-start space-y-1 p-2">{userBlock}</div>
              ) : (
                <Button
                  type="button"
                  variant="flush"
                  size="sm"
                  className="w-full group flex flex-col justify-start space-y-1 hover:!bg-gray-100"
                  onClick={() => navigate(hasPublicationSettingsV2 ? '/current_user/personal_info' : '/settings')}
                >
                  {userBlock}
                </Button>
              )}
            </div>
          </div>

          {hasPublicationSettingsV2 ? (
            <div className="p-2 flex flex-col space-y-2 w-full">
              <Menu.Button onClick={() => navigate('/current_user/personal_info')} className={menuButtonClassNames}>
                <div className="w-full flex flex-row justify-between items-between text-start">
                  <Typography token="font-normal/text/sm" className="w-full text-start">
                    <span className="w-full">Personal Info</span>
                  </Typography>
                  <ChevronRightIcon className="my-auto h-4 w-4 text-surface-400" />
                </div>
              </Menu.Button>
              <Menu.Button
                onClick={() => navigate('/current_user/account_security')}
                type="button"
                className={menuButtonClassNames}
              >
                <div className="w-full flex flex-row justify-between items-between text-start">
                  <Typography token="font-normal/text/sm" className="w-full text-start">
                    <span className="w-full">Account Security</span>
                  </Typography>
                  <ChevronRightIcon className="my-auto h-4 w-4 text-surface-400" />
                </div>
              </Menu.Button>
              <Menu.Button
                onClick={() => navigate('/current_user/notification_preferences')}
                type="button"
                className={menuButtonClassNames}
              >
                <div className="w-full flex flex-row justify-between items-between text-start">
                  <Typography token="font-normal/text/sm" className="w-full text-start">
                    <span className="w-full">Notification Preferences</span>
                  </Typography>
                  <ChevronRightIcon className="my-auto h-4 w-4 text-surface-400" />
                </div>
              </Menu.Button>
            </div>
          ) : null}

          <div className="p-2 flex flex-col space-y-2 w-full">
            <Menu as="div" className="w-full">
              <Menu.Item>
                <Button
                  type="button"
                  variant="flush"
                  size="sm"
                  className="flex text-start flex-row justify-between items-between w-full group"
                  onMouseEnter={() => setLearnOpen(true)}
                  onMouseLeave={() => setLearnOpen(false)}
                >
                  <Typography token="font-normal/text/sm" className="w-full">
                    <span>Learn</span>
                  </Typography>
                  <ChevronRightIcon className="my-auto h-4 w-4 text-surface-400 group-hover:text-surface-900" />
                </Button>
              </Menu.Item>
              <SubDropdown open={learnOpen} setOpen={setLearnOpen} />
            </Menu>
            <Button
              onClick={() => window.open(currentPublication?.url, '_blank')}
              type="button"
              variant="flush"
              size="sm"
              className="flex flex-col"
            >
              <div className="w-full flex flex-row justify-between items-between text-start">
                <Typography token="font-normal/text/sm" className="w-full text-start">
                  <span className="w-full">View Site</span>
                </Typography>
                <ArrowTopRightOnSquareIcon className="my-auto h-4 w-4 text-surface-400" />
              </div>
            </Button>
          </div>

          <div className="p-2 w-full flex flex-col space-y-2">
            <Menu.Item>
              <Button
                onClick={handleLogout}
                type="button"
                variant="flush"
                size="sm"
                className="flex flex-col text-start justify-start items-start "
              >
                <Typography token="font-normal/text/sm" className="w-full">
                  <span className="text-gray-700 w-full">Logout</span>
                </Typography>
              </Button>
            </Menu.Item>
          </div>
          {(showAccountantAdmin || showAdNetworkAdmin || showSystemAdmin) && (
            <div className="p-2 w-full flex flex-col space-y-2">
              {showSystemAdmin && (
                <Menu.Item>
                  <Button
                    to="/system_admin/users"
                    type="button"
                    variant="flush"
                    size="sm"
                    className="flex flex-col text-start justify-start items-start "
                  >
                    <Typography token="font-normal/text/sm" className="w-full">
                      <span className="text-[#4338CA] w-full">System Admin</span>
                    </Typography>
                  </Button>
                </Menu.Item>
              )}
              {showAdNetworkAdmin && (
                <Menu.Item>
                  <Button
                    to="/ad_network"
                    type="button"
                    variant="flush"
                    size="sm"
                    className="flex flex-col text-start justify-start items-start "
                  >
                    <Typography token="font-normal/text/sm" className="w-full">
                      <span className="text-[#4338CA] w-full">Ad Network</span>
                    </Typography>
                  </Button>
                </Menu.Item>
              )}
              {showAccountantAdmin && (
                <Menu.Item>
                  <Button
                    to="/admin_reports/ledgers"
                    type="button"
                    variant="flush"
                    size="sm"
                    className="flex flex-col text-start justify-start items-start"
                  >
                    <Typography token="font-normal/text/sm" className="w-full">
                      <span className="text-[#4338CA] w-full">Admin Reports</span>
                    </Typography>
                  </Button>
                </Menu.Item>
              )}
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
