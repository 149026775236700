import useNotificationsUnreadCount from '@/hooks/useNotificationsUnreadCount';

const UnreadNotifications = () => {
  const { data } = useNotificationsUnreadCount();
  const unreadCount = data?.count || 0;

  if (unreadCount === 0) return null;

  return (
    <div className="absolute top-0 right-0 px-1 h-3.5 rounded-full bg-action-secondary-700">
      <p className="text-white text-[9px] font-semibold">{unreadCount}</p>
    </div>
  );
};

export default UnreadNotifications;
