export enum OrderBy {
  TOTAL_SUBSCRIBER_COUNT = 'total_subscriber_count',
  ACCEPTED_SUBSCRIBER_COUNT = 'accepted_subscriber_count',
  PENDING_SUBSCRIBER_COUNT = 'pending_subscriber_count',
  SENDABLE = 'sendable',
  CREATED_AT = 'created_at',
}

export enum SpendOrderBy {
  PENDING_SPEND = 'pending_spend',
  TOTAL_SPEND = 'total_spend',
}

export enum EarningsOrderBy {
  PENDING_EARNINGS = 'pending_earnings',
  TOTAL_EARNINGS = 'total_earnings',
}
