import { useState } from 'react';
import toast from 'react-hot-toast';

import { Typography } from '@/components/Typography';
import Switch from '@/ui/Switch';

interface Props {
  title: string;
  name: string;
  value: boolean;
  onSave?: any;
  Icon?: React.ElementType;
  description?: string | React.ReactNode;
  disabled?: boolean;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
  urlLink?: string;
}

const SwitchDescriptionInput = ({
  title,
  name,
  value,
  onSave,
  Icon,
  description,
  disabled,
  toastSuccessMessage,
  toastErrorMessage,
  urlLink,
}: Props) => {
  const [inputValue, setInputValue] = useState<boolean>(value);

  const handleSave = async (val: boolean) => {
    await onSave(val)
      .then(() => {
        setInputValue(val);

        if (toastSuccessMessage) {
          toast.success(toastSuccessMessage);
        }
      })
      .catch(() => {
        if (toastErrorMessage) {
          toast.error(toastErrorMessage);
        }
      });
  };

  return (
    <div className="flex flex-col space-y-1">
      <div className="w-full flex flex-row justify-between items-between">
        <div className="flex flex-row space-x-2">
          {Icon && <Icon className="h-5 w-5 my-auto text-surface-900" />}
          <Typography token="font-medium/text/base" className="text-gray-900">
            {title}
          </Typography>
        </div>
        <Switch
          variant="primary"
          name={name}
          checked={Boolean(inputValue)}
          onChange={(_name: string, updatedValue: boolean) => handleSave(updatedValue)}
          disabled={disabled}
        />
      </div>
      {urlLink && (
        <a href={urlLink} className="text-xs text-[#DB2777] underline">
          View URL
        </a>
      )}
      <Typography token="font-light/text/xs" className="w-4/5 text-gray-900">
        {description}
      </Typography>
    </div>
  );
};

export default SwitchDescriptionInput;
