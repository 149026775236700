import { ElementType } from 'react';
import { RadioGroup } from '@headlessui/react';
import cx from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';

export type LargeIconRadioOption = {
  name: string;
  value: string; // value to be passed to the server
  description?: string;
  Icon: ElementType;
  variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'warning'
    | 'success'
    | 'danger'
    | 'info'
    | 'tertiaryV2'
    | 'warningV2';
  size?: 'sm' | 'md' | 'lg';
  padding?: 'sm' | 'md' | 'lg';
  selectedText?: string | JSX.Element;
  onSubmitAction?: () => void;
};

interface Props {
  srOnlyLabel?: string;
  options: LargeIconRadioOption[];
  selected: LargeIconRadioOption | null;
  onSelected: (option: LargeIconRadioOption) => void;
  gap?: '0' | '1' | '2' | '3' | '4' | '5' | '6';
}

const ICON_SIZE_MAP = Object.freeze({
  sm: 'w-4 h-4',
  md: 'w-6 h-6',
  lg: 'w-7 h-7',
  xl: 'w-10 h-10',
});

const PADDING_MAP = Object.freeze({
  sm: 'p-2',
  md: 'p-4',
  lg: 'p-6',
});

const COLOR_VARIANT_MAP = Object.freeze({
  primary: {
    text: 'text-action-primary-900',
    bg: 'bg-action-primary-100',
  },
  secondary: {
    text: 'text-action-secondary-900',
    bg: 'bg-action-secondary-100',
  },
  tertiary: {
    text: 'text-action-tertiary-900',
    bg: 'bg-action-tertiary-100',
  },
  tertiaryV2: {
    text: 'text-action-tertiary-500',
    bg: 'bg-action-tertiary-100',
  },
  warning: {
    text: 'text-feedback-warning-900',
    bg: 'bg-feedback-warning-100',
  },
  warningV2: {
    text: 'text-feedback-warning-500',
    bg: 'bg-feedback-warning-100',
  },
  success: {
    text: 'text-feedback-success-900',
    bg: 'bg-feedback-success-100',
  },
  danger: {
    text: 'text-feedback-danger-900',
    bg: 'bg-feedback-danger-100',
  },
  info: {
    text: 'text-feedback-info-900',
    bg: 'bg-feedback-info-100',
  },
});

const LargeIconRadioGroup = ({ options, srOnlyLabel, selected, onSelected, gap }: Props) => {
  return (
    <RadioGroup value={selected} onChange={onSelected} className="w-full">
      {srOnlyLabel && <RadioGroup.Label className="sr-only">{srOnlyLabel}</RadioGroup.Label>}
      <div className="space-y-4 w-full">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ active }) =>
              cx(
                active || option?.value === selected?.value ? 'border-surface-600' : 'border-surface-300',
                'relative hover:bg-surface-100 block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
              )
            }
          >
            {({ checked }) => (
              <>
                <div className="flex flex-col w-full">
                  <div className="flex items-center justify-between space-x-8 w-full">
                    <span className="flex flex-col text-sm">
                      <TypographyStack gap={gap || '1'}>
                        <RadioGroup.Label as="span">
                          <Typography weight="bold" size="lg" as="span">
                            {option.name}
                          </Typography>
                        </RadioGroup.Label>
                        {option.description && (
                          <RadioGroup.Description as="span" className="text-gray-500">
                            <Typography weight="normal" size="lg" as="span">
                              {option.description}
                            </Typography>
                          </RadioGroup.Description>
                        )}
                      </TypographyStack>
                    </span>

                    <div>
                      <div
                        className={cx(
                          PADDING_MAP[option.padding || 'md'],
                          'rounded',
                          COLOR_VARIANT_MAP[option.variant].bg
                        )}
                      >
                        <option.Icon
                          className={cx(ICON_SIZE_MAP[option.size || 'sm'], COLOR_VARIANT_MAP[option.variant].text)}
                        />
                      </div>
                    </div>
                  </div>

                  {checked && option.selectedText && (
                    <RadioGroup.Description as="span" className="pt-8 text-gray-500">
                      <Typography token="font-normal/text/sm">{option.selectedText}</Typography>
                    </RadioGroup.Description>
                  )}
                </div>

                <span
                  className={cx(
                    'border',
                    checked ? 'border-surface-600' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default LargeIconRadioGroup;
