import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { SortableList, SortableListItem, useSortableList } from '@/components/SortableList';
import { useUpdateHomePage } from '@/hooks/useHomePages';
import ThemeEditorDropdown from '@/pages/ThemeEditor/components/ThemeEditorDropdown';
import ThemeFormDrawer from '@/pages/ThemeEditor/components/ThemeFormDrawer';
import { FormContainer, FormInput } from '@/pages/ThemeEditor/helpers';

import { HomePage } from '../../../../interfaces/home_page';
import { usePageContext } from '../../components/PageContext';

type WidgetItem = {
  id: string;
  value: string;
};

const WidgetsSection = () => {
  const queryClient = useQueryClient();
  const { pageData, setIsSaving, handleSaved, currentPublication } = usePageContext<HomePage>();

  const allowedOptions = pageData.widget_options?.filter((option) => option.enabled).map((option) => option.label);
  const widgets = pageData?.widgets_section?.widgets_enabled || [];
  const widgetLength = widgets.length;

  const { sortableList, setSortableList, reStringifyList } = useSortableList<WidgetItem>({
    list: widgets,
    addIdToListOfStrings: true,
  });
  const hasWidgets = sortableList.length > 0;

  const [availableOptions, setAvailableOptions] = useState<any>(allowedOptions || []);

  useEffect(() => {
    setAvailableOptions(
      allowedOptions
        .filter((option) => !widgets.includes(option))
        .map((option) => {
          return { label: option, value: option };
        })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetLength]);

  const updatePostPage = useUpdateHomePage({
    pageId: pageData.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['home_page', currentPublication?.id, 'draft']);
      handleSaved();
    },
  });

  const handleSaveWidgetsSection = (payload: any) => {
    setIsSaving(true);
    updatePostPage.mutate({ home_page: { widgets_section: JSON.stringify(payload) } });
  };

  return (
    <ThemeFormDrawer active={false} title="Side Panel Widgets" onClickHandler={() => {}}>
      <FormContainer>
        <FormInput
          title="Widgets"
          helperText="Select any widgets you would like to appear on the side panel of your site. You can drag and drop to reorder them."
        >
          <div className="space-y-4">
            {hasWidgets && (
              <SortableList
                listItems={sortableList}
                onItemsSorted={(sortedList: any[]) => {
                  const stringifiedList = reStringifyList(sortedList);
                  return handleSaveWidgetsSection({
                    ...pageData?.widgets_section,
                    widgets_enabled: stringifiedList,
                  });
                }}
              >
                {(widgetsEnabledList) => {
                  return widgetsEnabledList.map((item: WidgetItem) => {
                    return (
                      <SortableListItem
                        key={item.id}
                        listItem={item}
                        onRemoveItem={(widgetItem: WidgetItem) => {
                          const list = sortableList.filter((widget: WidgetItem) => widget.id !== widgetItem.id);
                          setSortableList(list);
                          const reStringifiedList = reStringifyList(list);
                          return handleSaveWidgetsSection({
                            ...pageData?.widgets_section,
                            widgets_enabled: [...reStringifiedList],
                          });
                        }}
                        text={item.value}
                      />
                    );
                  });
                }}
              </SortableList>
            )}
            {availableOptions.length ? (
              <ThemeEditorDropdown
                currentValue=""
                onUpdate={(val: any) => {
                  const newValue: WidgetItem = { id: String(sortableList.length + 1), value: val };
                  const newList = [...sortableList, newValue];
                  setSortableList(newList);
                  const stringifiedList = reStringifyList(newList);
                  return handleSaveWidgetsSection({
                    ...pageData?.widgets_section,
                    widgets_enabled: stringifiedList,
                  });
                }}
                options={availableOptions}
              />
            ) : null}
          </div>
        </FormInput>
      </FormContainer>
    </ThemeFormDrawer>
  );
};

export default WidgetsSection;
