import React, { useCallback } from 'react';

import { Range } from '@/components/UI/Range';

export type BrightnessControlProps = {
  brightness: number;
  setBrightness: (brightness: number) => void;
};

export const BrightnessControl = React.memo(({ brightness, setBrightness }: BrightnessControlProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setBrightness(parseFloat(e.target.value));
    },
    [setBrightness]
  );

  return <Range label="Brightness" min={-100} max={100} step="5" value={brightness} onChange={handleChange} />;
});

BrightnessControl.displayName = 'BrightnessControl';
