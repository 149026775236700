import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { Typography } from '@/components/Typography';

import PlaceholderIcon from './PlaceholderIcon';

type Props = PropsWithChildren<{
  label: string;
  className?: string;
  showPlaceholderIcon?: boolean;
  placeholderImage?: string;
}>;

const GroupBody = ({ className, showPlaceholderIcon, placeholderImage, label, children }: Props) => {
  const placeholderImageElement = placeholderImage ? (
    <img
      src={placeholderImage}
      className="w-4 h-4 object-cover rounded-sm border border-surface-200 mt-0.5"
      alt="publication logo"
    />
  ) : null;

  return (
    <div className={classNames('flex flex-row justify-between p-2 items-center', className)}>
      <div className="flex flex-row gap-x-2">
        {showPlaceholderIcon ? (
          <span className="mt-0.5 w-4 h-4 p-0.5 bg-action-tertiary-50 rounded-sm flex grow-0 shrink-0">
            <PlaceholderIcon />
          </span>
        ) : (
          placeholderImageElement
        )}
        <Typography token="font-medium/text/sm">{label}</Typography>
      </div>
      {children}
    </div>
  );
};

export default GroupBody;
