import { EnvelopeIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { LineDivider } from '@/components/LineDivider';
import { PublicationLogo } from '@/components/PublicationLogo';
import { Typography, TypographyStack } from '@/components/Typography';
import useBoostsFrozen from '@/hooks/boosts/useBoostsFrozen';
import { BoostPublication } from '@/interfaces/boosts/monetize/boost_publication';
import { Button, ButtonProps } from '@/ui/Button';

interface Props {
  boostedPublication: BoostPublication;
  ctaText: string;
  onCTAClick: () => void;
  isCTADisabled: boolean;
  maxPayout?: string;
  sendBy?: number;
  partnerSince?: string;
  ctaVariant?: ButtonProps['variant'];
}

const EmailBoostCard = ({
  boostedPublication,
  ctaText,
  onCTAClick,
  isCTADisabled,
  maxPayout,
  sendBy,
  partnerSince,
  ctaVariant = 'primary',
}: Props) => {
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen;

  return (
    <div className="flex flex-col gap-y-4 justify-between w-full sm:w-auto sm:basis-1/3 bg-surface-50 border border-surface-200 rounded-lg p-5">
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-2">
          <PublicationLogo
            size="md"
            publication={{ logo_url: boostedPublication.logo_url, name: boostedPublication.name }}
          />
          <TypographyStack gap="1">
            <Typography token="font-medium/text/lg">{boostedPublication.name}</Typography>
            {boostedPublication.description && (
              <Typography token="font-normal/text/sm" colorWeight="500">
                {boostedPublication.description}
              </Typography>
            )}
          </TypographyStack>
          {partnerSince && (
            <Typography token="font-normal/text/xs" colorWeight="400">
              Partner since {moment(partnerSince).fromNow(true)}
            </Typography>
          )}
        </div>

        <LineDivider />

        {maxPayout && (
          <div className="flex flex-col gap-y-2">
            <Typography token="font-medium/text/sm">Email Boost Available</Typography>

            <span className="inline-flex gap-x-2 items-center">
              <EnvelopeIcon className="w-5 h-5 text-surface-500" />
              <span className="inline-flex items-center gap-x-1">
                <Typography token="font-normal/text/xs" colorWeight="400">
                  Earn up to
                </Typography>
                <Typography token="font-medium/text/xs" colorWeight="600" color="success">
                  {maxPayout}
                </Typography>
                <Typography token="font-normal/text/xs" colorWeight="400">
                  per send!
                </Typography>
              </span>
            </span>
          </div>
        )}
        {typeof sendBy !== 'undefined' && (
          <div className="flex flex-col gap-y-2">
            <Typography token="font-medium/text/sm">Email Boost Approved</Typography>

            <span className="inline-flex gap-x-2 items-center">
              <EnvelopeIcon className="w-5 h-5 text-feedback-danger-600" />
              <Typography token="font-normal/text/xs" colorWeight="600" color="danger">
                {sendBy <= 0
                  ? 'Less than an hour left to earn revenue on this offer'
                  : `${sendBy} hours left to earn revenue on this offer`}
              </Typography>
            </span>
          </div>
        )}
      </div>
      <div className="flex justify-end">
        <Button
          variant={ctaVariant}
          type="button"
          className="w-fit"
          size="xs"
          onClick={onCTAClick}
          disabled={isCTADisabled || boostsFrozen}
        >
          {ctaText}
        </Button>
      </div>
    </div>
  );
};

export default EmailBoostCard;
