import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { cn } from '../../_utils/cn';

type Props = PropsWithChildren<{
  className?: HTMLElement['className'];
  isResizable?: boolean;
  isCollapsed?: boolean;
}>;

export const SideNav = ({ className, isResizable, children, isCollapsed }: Props) => {
  const [minWidth, maxWidth, defaultWidth] = isResizable ? [250, 500, 250] : [250, 250, 250];
  const [width, setWidth] = useState(defaultWidth);
  const [isResizing, setIsResizing] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const resize = useCallback(
    (e: MouseEvent) => {
      if (!isResizing) {
        return;
      }

      const sidebar = sidebarRef.current;
      const sidebarLeft = sidebar?.getBoundingClientRect()?.left || 0;
      const newWidth = sidebar ? e.clientX - sidebarLeft : 0;

      setWidth((previousWidth) => {
        const isWidthInRange = newWidth >= minWidth && newWidth <= maxWidth;
        return isWidthInRange ? newWidth : previousWidth;
      });
    },
    [isResizing, maxWidth, minWidth]
  );

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isResizable) {
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResizing);
      return () => {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResizing);
      };
    }
    return () => {};
  }, [isResizable, resize, stopResizing]);

  useEffect(() => {
    if (!isCollapsed) {
      // When sidebar opened, set width to default width
      setWidth(defaultWidth);
    }
  }, [defaultWidth, isCollapsed]);

  return (
    <div
      className={cn(
        'flex transition-transform duration-250',
        isCollapsed ? `-translate-x-[500px]` : 'translate-x-0',
        className
      )}
      ref={sidebarRef}
      style={{
        width: isCollapsed ? 0 : width,
        minWidth: isCollapsed ? 0 : minWidth,
      }}
      role="none"
    >
      <div className="overflow-y-auto p-3 flex flex-col gap-4 w-full h-full">{children}</div>
      {isResizable && (
        <div
          className={cn(
            'w-1 cursor-col-resize hover:bg-wb-tertiary -mr-0.5 transition-all duration-150',
            isResizing ? 'bg-wb-tertiary' : ''
          )}
          role="none"
          onMouseDown={() => setIsResizing(true)}
        />
      )}
    </div>
  );
};
