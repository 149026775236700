import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { NewInvite } from '@/interfaces/invites';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

const useSendInvite = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({invite, publicationId}: {invite: NewInvite; publicationId: string}) =>
      api.post(`/invites`, {
        publication_id: publicationId,
        invite,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (res) => {
        try {
          const { invite } = res.data;
          analytics.track('Account Added User', { role_id: invite?.role_id });
          queryClient.invalidateQueries(['invites', organizationId]);
          toast.success('Invite was sent successfully!');
        } catch {
          toast.error('Please try again');
        }
      },
    }
  );
};

export default useSendInvite;
