import { useInfiniteQuery } from 'react-query';

import { Pagination } from '@/interfaces/general';
import { PostPollMetrics } from '@/interfaces/post_poll';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface ApiResponse {
  post_polls: PostPollMetrics[];
  pagination: Pagination;
}

interface Props {
  pollId: string;
  allResults?: boolean;
}

export default function usePostPollMetrics({ pollId, allResults = false }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostPollMetrics = () =>
    api
      .get(`/polls/${pollId}/post_polls`, {
        params: {
          publication_id: currentPublicationId,
          all_results: allResults,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['polls', pollId, 'post_polls', allResults], fetchPostPollMetrics, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: false,
  });
}
