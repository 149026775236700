import { ArrowPathIcon, UsersIcon } from '@heroicons/react/24/solid';

import Banner from '@/components/Banner';
import LoadingBox from '@/components/LoadingBox';
import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import usePostEngagements from '@/hooks/usePostEngagements';
import { PostEngagementStatus } from '@/interfaces/post';
import { Button } from '@/ui/Button';
import { ResourceListFilters, useResourceListFilters } from '@/ui/ResourceListFilters';

import EngagementItem from './EngagementItem';

interface Props {
  postId: string;
}

enum SortingOptions {
  EMAIL = 'email',
  TIMESTAMP = 'timestamp',
  STATUS = 'status',
  TOTAL_CLICKED = 'total_clicked',
  TOTAL_OPENED = 'total_opened',
}

const filterOptions = [
  { label: 'Clicked', value: PostEngagementStatus.CLICKED },
  { label: 'Opened', value: PostEngagementStatus.OPENED },
  { label: 'Unopened', value: PostEngagementStatus.DELIVERED },
  { label: 'Unsubscribed', value: PostEngagementStatus.UNSUBSCRIBED },
];

const Subscribers = ({ postId }: Props) => {
  const {
    search,
    setSearch,
    filter,
    setFilter,
    hasFilter,
    resetAllFilters,
    orderBy,
    direction,
    handleClickOrderButton
  } = useResourceListFilters<string[], string>({
    defaultFilter: [],
    defaultSortOrder: SortingOptions.TIMESTAMP
  });

  const { data, hasNextPage, fetchNextPage, isLoading, isError } = usePostEngagements({
    postId,
    search,
    statuses: filter as string[],
    order: orderBy,
    dir: direction,
  });

  const postEngagements = data?.pages.flatMap((page) => page.subscribers) || [];
  const hasNoResults = postEngagements?.length === 0;

  const isFiltering = hasFilter && filter.length > 0;
  const title = isFiltering ? 'No results found for current filters' : 'No data available (yet)';
  const primaryActionLabel = isFiltering ? 'Reset Filters' : undefined;
  const onPrimaryActionClick = isFiltering ? resetAllFilters : undefined;
  const primaryActionIcon = isFiltering ? ArrowPathIcon : undefined;

  return (
    <div className="flex flex-col gap-y-6">
      <Banner
        isScreenWide={false}
        variant="info"
        title="The total clicks per subscriber can be inflated in certain cases due to link scanning and tracking mechanisms employed by organizations and email service providers."
        bodyText=""
      />

      <div className="flex flex-col gap-y-4">
        <div className="flex justify-end w-full">
          <ResourceListFilters
            search={search}
            setSearch={setSearch}
            searchPlaceholder="Search emails"
            filter={filter}
            setFilter={setFilter}
            filterOptions={filterOptions}
            filterLabel="All Statuses"
            allowMultipleFilters
          />
        </div>

        <LoadingBox isLoading={isLoading} isError={isError}>
          {hasNoResults && !isLoading && (
            <EmptyCard
              title={title}
              primaryIcon={UsersIcon}
              primaryActionIcon={primaryActionIcon}
              primaryActionLabel={primaryActionLabel}
              onPrimaryActionClick={onPrimaryActionClick}
            />
          )}
          {!hasNoResults && (
            <>
              <Items>
                <ItemHeaders isSticky>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.EMAIL}
                    isSorting={orderBy === SortingOptions.EMAIL}
                    currentDirection={direction}
                  >
                    Email
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.STATUS}
                    isSorting={orderBy === SortingOptions.STATUS}
                    currentDirection={direction}
                  >
                    Status
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.TIMESTAMP}
                    isSorting={orderBy === SortingOptions.TIMESTAMP}
                    currentDirection={direction}
                  >
                    Timestamp
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.TOTAL_OPENED}
                    isSorting={orderBy === SortingOptions.TOTAL_OPENED}
                    currentDirection={direction}
                  >
                    Total Opens
                  </ItemHeader>
                  <ItemHeader
                    onClick={handleClickOrderButton}
                    orderBy={SortingOptions.TOTAL_CLICKED}
                    isSorting={orderBy === SortingOptions.TOTAL_CLICKED}
                    currentDirection={direction}
                  >
                    Total Clicks
                  </ItemHeader>
                </ItemHeaders>
                <ItemsBody>
                  {postEngagements.map(engagement => <EngagementItem key={engagement.subscriberId} engagement={engagement} />)}
                </ItemsBody>
              </Items>

              {hasNextPage && (
                <div className="p-4 border-t border-gray-100">
                  <Button onClick={() => fetchNextPage()} variant="primary-inverse">
                    Load more
                  </Button>
                </div>
              )}
            </>
          )}
        </LoadingBox>
      </div>
    </div>
  );
};

export default Subscribers;
