import cx from 'classnames';

import { Checkbox } from '@/components/Form';
import { Typography } from '@/components/Typography';
import pluralize from '@/utils/pluralize';

import { ResourceListSearchTextProps } from './ResourceListSearchText.types';

const ResourceListSearchText = ({
  showingCount,
  totalCount,
  resourceName,
  className,
  isSelectAllActive,
  onSelectAll
}: ResourceListSearchTextProps) => {
  const disabled = totalCount === 0;
  const labelText = `Showing ${showingCount} of ${pluralize(resourceName || 'item', totalCount)}`;

  return onSelectAll ? (
    <Checkbox
      name="select-all"
      checked={!disabled && (isSelectAllActive || false)}
      onChange={onSelectAll}
      labelText={labelText}
      labelClassNames="text-xs !text-gray-500"
      disabled={disabled}
    />
  ) : (
    <Typography
      token="font-normal/text/xs"
      colorWeight="500"
      as="div"
      className={cx(className)}
    >
      {labelText}
    </Typography>
  );
};

export default ResourceListSearchText;
