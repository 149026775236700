import { FC } from 'react';
import { LoginElement } from '@shared/dream-components';
import { NodeViewProps, NodeViewWrapper, useReactNodeView } from '@tiptap/react';

import { useCurrentPublication } from '@/hooks';

import { getDataAttributes } from '../../utils';

export const LoginView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  const { onDragStart } = useReactNodeView();
  const { data: publication } = useCurrentPublication();

  return (
    <NodeViewWrapper data-drag-handle data-id={node.attrs.id}>
      <LoginElement
        element={{
          type: 'login',
          attrs: {
            id: node.attrs.id,
            ...node.attrs,
          },
          content: [{ id: 'text', text: '' }],
        }}
        onDragStart={onDragStart}
        attributes={{
          'data-node-view-wrapper': '',
          ...getDataAttributes(HTMLAttributes),
        }}
        className="relative"
        canLogInWithPassword={publication?.has_subscription_password || false}
      >
        {/* So signup can easily be selected, try removing and see what happens */}
        <div className="absolute top-0 left-0 w-full h-full bg-transparent" />
      </LoginElement>
    </NodeViewWrapper>
  );
};
