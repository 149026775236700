import useTierLabel from '@/hooks/useTierLabel';
import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import { AutomationTriggerEvent } from '@/interfaces/automations/automation_trigger';

import { LABEL_BY_EVENTS_TYPE } from './constants';

interface Props {
  event: AutomationTriggerEvent;
  mainConditions: AutomationConditions;
}

const TierName = (tierIdValue: string) => {
  return useTierLabel(tierIdValue);
};

const TierLabel = ({ event, mainConditions }: Props) => {
  const isTierChangedEvent = [AutomationTriggerEvent.DOWNGRADE, AutomationTriggerEvent.UPGRADE].includes(event);

  if (!isTierChangedEvent) {
    return <span>{LABEL_BY_EVENTS_TYPE[event]}</span>;
  }

  const tierIdValue = mainConditions?.conditions?.find((c) => c.name === 'tier_id')?.value;
  const tierName = tierIdValue ? TierName(tierIdValue) : 'Any Paid Tier';
  const particle = event === AutomationTriggerEvent.DOWNGRADE ? 'from' : 'to';

  return <span>{`${LABEL_BY_EVENTS_TYPE[event]} ${particle} ${tierName}`}</span>;
};

export default TierLabel;
