import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

import { ResourceListBulkActionsProps } from "./ResourceListBulkActions.types";

const ResourceListBulkActions = ({
  selectedCount,
  onDeselect,
  children,
}: ResourceListBulkActionsProps) => {
  if (selectedCount === 0) return null;

  return (
    <div className="flex items-center justify-between px-4 py-2 bg-white border rounded-lg">
      <div className="flex items-center gap-2">
        <Typography token="font-normal/text/xs" colorWeight="500">
          {selectedCount} selected
        </Typography>
        <Button
          size="xs"
          variant="primary-inverse"
          onClick={onDeselect}
          className="text-gray-600 hover:text-gray-900"
        >
          Deselect
        </Button>
      </div>
      <div className="flex space-x-3">
        {children}
      </div>
    </div>
  );
};

export default ResourceListBulkActions;
