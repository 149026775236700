import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkAdvertisement } from '../../../interfaces/ad_network/internal/advertisement';
import { MutationErrorResponse } from '../../../interfaces/ad_network/internal/mutation_error_response';
import api from '../../../services/swarm';

export default function useCampaignAdvertisementCreate({ campaignId }: { campaignId: string }) {
  const navigate = useNavigate();

  const updateCampaign = (formData: FormData): Promise<AdNetworkAdvertisement> =>
    api.post(`/ad_network/internal/campaigns/${campaignId}/advertisements`, formData).then((res) => res.data);

  return useMutation<AdNetworkAdvertisement, MutationErrorResponse, FormData>(updateCampaign, {
    onSuccess: () => {
      toast.success('Ad created successfully');
      navigate(`/ad_network/campaigns/${campaignId}/advertisements`);
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
