import { PostAudience } from '@/interfaces/post';

import { ALESSANDRO_ROSSI, CATALINA_VIDAL, LIAM_MCKENZIE, NATALIA_MIRESCU, OLIVER_BUCHANNON } from './authors';
import { DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG } from './tags';

export type PostData = {
  id: string;
  web_title: string;
  web_subtitle: string;
  image_url: string;
  slug: string;
  audience: PostAudience;
  comments_state: string;
  authors: {
    id: string;
    name: string;
    profile_picture: {
      url: string;
      landscape: string;
      thumb: string;
    };
  }[];
  content_tags: {
    id: string;
    display: string;
  }[];
  override_scheduled_at: string;
};

const posts: PostData[] = [
  {
    id: 'featured_post_1',
    web_title: 'Exploring the Ancient Ruins of Machu Picchu, Peru',
    web_subtitle:
      'Perched atop a remote mountain ridge in the Peruvian Andes, the extraordinary ruins of Machu Picchu will take your breath away.',
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/feature_post_1.png',
    slug: 'exploring-machu-picchu',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [OLIVER_BUCHANNON],
    content_tags: [DESTINATION_TAG],
    override_scheduled_at: '2024-03-12T00:13:27.632Z',
  },
  {
    id: 'featured_post_2',
    web_title: 'Trekking Through the Landscapes of Patagonia, Argentina',
    web_subtitle:
      'Shared between Argentina and Chile, the Patagonia region encompasses some of the most strikingly beautiful and remote wilderness areas on the planet.',
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/feature_post_2.png',
    slug: 'trekking-patagonia',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [OLIVER_BUCHANNON, LIAM_MCKENZIE],
    content_tags: [HIKING_TAG],
    override_scheduled_at: '2024-03-10T00:13:27.632Z',
  },
  {
    id: 'featured_post_3',
    web_title: "Savoring the Flavors of Bangkok's Vibrant Street Food Scene",
    web_subtitle: 'For foodies, Bangkok is a sprawling, sizzling paradise of street food delights.',
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/feature_post_3.png',
    slug: 'bangkok-street-food',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [ALESSANDRO_ROSSI],
    content_tags: [FOOD_AND_DRINK_TAG],
    override_scheduled_at: '2024-03-08T00:13:27.632Z',
  },
  {
    id: 'featured_post_4',
    web_title: 'Discovering the Iconic Landmarks and History of Rome, Italy',
    web_subtitle:
      'Rome is a city that beckons you to get delightfully lost in its labyrinth of narrow, cobblestoned streets.',
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/feature_post_4.png',
    slug: 'rome-landmarks',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [NATALIA_MIRESCU],
    content_tags: [HISTORY_AND_CULTURE_TAG],
    override_scheduled_at: '2024-03-08T00:13:27.632Z',
  },
  {
    id: 'featured_post_5',
    web_title: 'Embracing the Laid-Back Island Life in Bali, Indonesia',
    web_subtitle:
      "The mere name 'Bali' evokes visions of paradise. This Indonesian island stunning in its natural beauty is also an ancient land rich with artistic and spiritual traditions.",
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/feature_post_5.png',
    slug: 'bali-island-life',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [OLIVER_BUCHANNON, LIAM_MCKENZIE, ALESSANDRO_ROSSI],
    content_tags: [DESTINATION_TAG],
    override_scheduled_at: '2024-03-08T00:13:27.632Z',
  },
  {
    id: 'featured_post_6',
    web_title: 'Adventuring Through the Canadian Rockies',
    web_subtitle:
      "With their ice-capped peaks scraping the sky, the Canadian Rockies contain some of North America's most spectacular mountain scenery and abundant wildlife.",
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/feature_post_6.png',
    slug: 'canadian-rockies',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [CATALINA_VIDAL],
    content_tags: [HIKING_TAG, DESTINATION_TAG, FOOD_AND_DRINK_TAG, HISTORY_AND_CULTURE_TAG],
    override_scheduled_at: '2024-03-08T00:13:27.632Z',
  },
  {
    id: 'latest1',
    web_title: 'Uncovering Cultural Treasures in Marrakech, Morocco',
    web_subtitle:
      'Marrakech is a tantalizing feast for the senses - a journey behind the heavy wooden doors of medina leads you into a world of brilliant colors, chaotic markets, and enchanting street life.',
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/latest_post_1.png',
    slug: 'marrakech-cultural-treasures',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [CATALINA_VIDAL],
    content_tags: [HISTORY_AND_CULTURE_TAG],
    override_scheduled_at: '2024-02-28T00:13:27.632Z',
  },
  {
    id: 'latest2',
    web_title: 'Chasing the Northern Lights in Iceland',
    web_subtitle:
      'There are few natural phenomena as spellbinding as the Northern Lights shimmering across the arctic night sky.',
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/latest_post_2.png',
    slug: 'iceland-northern-lights',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [OLIVER_BUCHANNON, LIAM_MCKENZIE],
    content_tags: [HIKING_TAG],
    override_scheduled_at: '2024-02-26T00:13:27.632Z',
  },
  {
    id: 'latest3',
    web_title: 'Experiencing the Vibrant Energy of Tokyo, Japan',
    web_subtitle:
      'Tokyo is a megacity that defies all expectations - a supernova of urban magic where the ultramodern sits seamlessly beside ancient shrines and traditional tea houses.',
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/latest_post_3.png',
    slug: 'tokyo-vibrant-energy',
    audience: PostAudience.PREMIUM,
    comments_state: 'open',
    authors: [NATALIA_MIRESCU],
    content_tags: [DESTINATION_TAG],
    override_scheduled_at: '2024-02-24T00:13:27.632Z',
  },
  {
    id: 'post1',
    web_title: "Turquoise Waters of Thailand's Andaman Sea",
    web_subtitle:
      "The Andaman Sea off Thailand's west coast is a paradisiacal maritime playground of islands, hidden coves, and brilliantly clear waters.",
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/archive_post_1.png',
    slug: 'thailand-andaman-sea',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [OLIVER_BUCHANNON, LIAM_MCKENZIE, ALESSANDRO_ROSSI],
    content_tags: [DESTINATION_TAG],
    override_scheduled_at: '2024-02-14T00:13:27.632Z',
  },
  {
    id: 'post2',
    web_title: "Hiking Norway's Jaw-Dropping Fjord Landscapes",
    web_subtitle:
      "Norway's famously photogenic fjords are even more breathtaking when experienced from hiking trails winding through their carved, cliff-walled inlets.",
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/archive_post_2.png',
    slug: 'norway-fjord-hiking',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [LIAM_MCKENZIE],
    content_tags: [HIKING_TAG],
    override_scheduled_at: '2024-02-12T00:13:27.632Z',
  },
  {
    id: 'post3',
    web_title: 'Uncovering Ancient Wonders of Angkor Wat, Cambodia',
    web_subtitle:
      'Rising up from the clutches of the Cambodian jungle, the mesmerizing ancient ruins of Angkor Wat are the ultimate testament to the incredible architectural prowess of the Khmer Empire.',
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/archive_post_3.png',
    slug: 'angkor-wat-cambodia',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [OLIVER_BUCHANNON, LIAM_MCKENZIE],
    content_tags: [DESTINATION_TAG],
    override_scheduled_at: '2024-02-10T00:13:27.632Z',
  },
  {
    id: 'post4',
    web_title: 'Wine Tasting Through the Vineyard of Tuscany, Italy',
    web_subtitle:
      'With its verdant valleys, rolling hills, Renaissance cities, and Medieval hill towns, Tuscany is a vision of romantic, cinematic Italy.',
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/archive_post_4.png',
    slug: 'tuscany-wine-tasting',
    audience: PostAudience.PREMIUM,
    comments_state: 'open',
    authors: [ALESSANDRO_ROSSI],
    content_tags: [FOOD_AND_DRINK_TAG],
    override_scheduled_at: '2024-02-08T00:13:27.632Z',
  },
  {
    id: 'post5',
    web_title: 'Pura Vida in the Lush Forests of Costa Rica',
    web_subtitle:
      "With an astonishing diversity of landscapes packed into a small nation, Costa Rica is a nature lover's dream destination.",
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/archive_post_5.png',
    slug: 'costa-rica-pura-vida',
    audience: PostAudience.FREE,
    comments_state: 'open',
    authors: [OLIVER_BUCHANNON, LIAM_MCKENZIE],
    content_tags: [DESTINATION_TAG],
    override_scheduled_at: '2024-02-06T00:13:27.632Z',
  },
  {
    id: 'post6',
    web_title: 'Finding Solitude in the Vast Desert Landscapes of Namibia',
    web_subtitle:
      "Namibia is a country of bewitching and utterly surreal desert scenery that makes you feel as if you've landed on another planet.",
    image_url:
      'https://media.beehiiv.com/cdn-cgi/image/format=auto/static_assets/site_builder/template/archive_post_6.png',
    slug: 'namibia-desert-solitude',
    audience: PostAudience.PREMIUM,
    comments_state: 'open',
    authors: [CATALINA_VIDAL],
    content_tags: [HIKING_TAG],
    override_scheduled_at: '2024-02-04T00:13:27.632Z',
  },
];

const selectRandomPosts = ({ count = 3 }: { count: number }) => {
  return posts.sort(() => 0.5 - Math.random()).slice(0, count);
};

export { posts, selectRandomPosts };
