import moment from 'moment-mini';

import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import useLogExport from '@/hooks/useLogExport';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { DataExport, DataExportStatus, DataExportType } from '@/interfaces/data_export';
import Badge, { BadgeProps } from '@/ui/Badge';

const dataExportTypeLabel: { [K in DataExportType]: string } = {
  post_poll_responses: 'Post Poll Responses',
  poll_responses: 'Poll Responses',
  posts: 'Posts',
  referral_program_subscriber: 'Referrals',
  segment_subscriber: 'Subscriber Segment',
  subscribers: 'Subscribers',
  basic_subscriber: 'Basic Subscribers',
  basic_segment_subscriber: 'Basic Subscriber Segment',
  accounting_ledger: 'Ledgers',
};

const dataExportBadgeType: { [K in DataExportStatus]: BadgeProps['type'] } = {
  completed: 'success',
  failed: 'alert',
  pending: 'information',
  processing: 'info_blue',
};

interface ListItemProps {
  dataExport: DataExport;
}

const ListItem: React.FunctionComponent<ListItemProps> = ({ dataExport }) => {
  const { id, exportType, status, failureReason, createdAt, downloadUrl } = dataExport;
  const currentPublicationId = useCurrentPublicationId();
  const { mutate: logExport } = useLogExport();

  const badgeType = dataExportBadgeType[status];
  const label = dataExportTypeLabel[exportType];
  const date = moment(createdAt).format('ll LT');

  const handleClick = async () => {
    logExport({ currentPublicationId, id });
  };

  return (
    <ItemRow>
      <ItemColumn className="w-44" align={AlignType.TOP}>
        <Typography size="sm" colorWeight="700">
          <time dateTime={createdAt} aria-label={date}>
            {date}
          </time>
        </Typography>
      </ItemColumn>
      <ItemColumn align={AlignType.TOP}>
        <Typography size="sm" colorWeight="700">
          {label}
        </Typography>
      </ItemColumn>
      <ItemColumn align={AlignType.TOP}>
        <Badge className="capitalize" type={badgeType}>
          {status}
        </Badge>
        {failureReason && (
          <Typography size="xs" colorWeight="500" className="block mt-2">
            {failureReason}
          </Typography>
        )}
      </ItemColumn>
      <ItemColumn align={AlignType.LEFT}>
        {downloadUrl && (
          <Typography size="sm" weight="medium">
            <a
              onClick={handleClick}
              href={downloadUrl}
              className="px-2.5 py-0.5 text-primary-600 hover:text-primary-900 underline"
            >
              Download
            </a>
          </Typography>
        )}
      </ItemColumn>
    </ItemRow>
  );
};

export default ListItem;
