import { Grid } from '@tremor/react';

import SubscriptionStateEvents from '@/components/charts/SubscriptionStateEvents';
import { usePageContext } from '@/components/Layout/PageLayout/PageContext';

import { LeftPane, Main, RightPane } from '../components/Layout';
import { SectionTabPanel } from '../components/SectionTabs';
import { PageProps } from '../types';

import AnalyzeListActions from './AnalyzeListActions';
import GrowListActions from './GrowListActions';
import KPIs from './KPIs';
import TopAcquisitionSources from './TopAcquisitionSources';
import TopReferrers from './TopReferrers';

const Audience = () => {
  const { isSubscriptionDataLoading, subscriptionKPIs, subscriptionEventsChart } = usePageContext<PageProps>();

  return (
    <SectionTabPanel>
      <Main>
        <LeftPane>
          <KPIs isLoadingSubsKPI={isSubscriptionDataLoading} subscriberKPIs={subscriptionKPIs} />
          <SubscriptionStateEvents
            data={subscriptionEventsChart}
            isLoading={isSubscriptionDataLoading}
            isPremiumPublication={false} // We don't want to render premium sub data on the audience dashboard
          />

          <Grid className="gap-4 grid-cols-1 lg:grid-cols-2">
            <TopAcquisitionSources />
            <TopReferrers />
          </Grid>
        </LeftPane>

        <RightPane>
          <GrowListActions />
          <AnalyzeListActions />
        </RightPane>
      </Main>
    </SectionTabPanel>
  );
};

export default Audience;
