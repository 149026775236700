export type EmptyStateProps = {
  message?: string;
};

export const EmptyState = ({ message }: EmptyStateProps) => {
  return (
    <div className="flex items-center justify-center p-4 text-sm font-medium text-neutral-500">
      {message ?? 'No threads found'}
    </div>
  );
};
