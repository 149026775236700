import { TAccordionContentElement, TAccordionElement, TAccordionTitleElement } from '@shared/dream-components';
import { Node as PMNode } from '@tiptap/pm/model';

export const getAccordionElement = (node: PMNode) => {
  return {
    type: 'accordion',
    attrs: {
      id: node.attrs.id,
      ...node.attrs,
    },
    content: [
      {
        type: 'accordionTitle',
        content: [
          {
            type: 'paragraph',
            content: [],
          },
        ],
      },
      {
        type: 'accordionContent',
        content: [],
      },
    ],
  } as TAccordionElement;
};

export const getAccordionTitleElement = (node: PMNode) => {
  return {
    type: 'accordionTitle',
    attrs: {
      id: node.attrs.id,
      ...node.attrs,
    },
    content: [
      {
        type: 'paragraph',
        content: [],
      },
    ],
  } as TAccordionTitleElement;
};

export const getAccordionContentElement = (node: PMNode) => {
  return {
    type: 'accordionContent',
    attrs: {
      id: node.attrs.id,
      ...node.attrs,
    },
    content: [],
  } as TAccordionContentElement;
};
