import cx from 'classnames';

import Typography, { TypographyProps } from './Typography';
import TypographyRow from './TypographyRow';
import TypographyStack from './TypographyStack';

// Typography Variants
// Common variants used on repeat across the publication
// Before creating directly moving to use the typography component, check if the variant you need is already defined here

const Title2xl = ({ children, className, ...rest }: TypographyProps) => {
  return (
    <Typography {...rest} className={cx(className)} size="2xl" weight="bold" as="h4">
      {children}
    </Typography>
  );
};

const TitleBase = ({ children, className, ...rest }: TypographyProps) => {
  return (
    <Typography weight="semibold" size="base" as="h6" {...rest}>
      {children}
    </Typography>
  );
};

const TitleSm = ({ children, className, ...rest }: TypographyProps) => {
  return (
    <Typography weight="semibold" size="sm" as="h6" {...rest}>
      {children}
    </Typography>
  );
};

const BodyBase = ({ children, className, ...rest }: TypographyProps) => {
  return (
    <Typography weight="medium" size="base" colorWeight="500" as="p" {...rest}>
      {children}
    </Typography>
  );
};

const BodySm = ({ children, className, ...rest }: TypographyProps) => {
  return (
    <Typography weight="medium" size="sm" colorWeight="700" as="p" {...rest}>
      {children}
    </Typography>
  );
};

const BodyXs = ({ children, className, ...rest }: TypographyProps) => {
  return (
    <Typography weight="medium" size="xs" colorWeight="500" as="p" {...rest}>
      {children}
    </Typography>
  );
};

const HelperXs = ({ children, className, ...rest }: TypographyProps) => {
  return (
    <Typography weight="normal" size="xs" colorWeight="500" {...rest}>
      {children}
    </Typography>
  );
};

export { BodyBase, BodySm, BodyXs, HelperXs, Title2xl, TitleBase, TitleSm, Typography, TypographyRow, TypographyStack };
