import { BoltIcon } from '@heroicons/react/20/solid';

import { useSubscriptionLimitMeter } from '@/hooks/usePublicationDashboard';
import getLocaleString from '@/utils/getLocaleString';

import PlanCardBase from './PlanCardBase';

const ScalePlan = () => {
  const subscriptionLimitMeterQuery = useSubscriptionLimitMeter();
  const { data: subscriptionLimitMeter } = subscriptionLimitMeterQuery || [];
  const underLineText = subscriptionLimitMeter
    ? `${getLocaleString(subscriptionLimitMeter?.active_subscribers_count)} of ${getLocaleString(
        subscriptionLimitMeter?.plan_limit
      )} added`
    : '';

  return (
    <PlanCardBase
      title="Scale Plan"
      variant="secondary"
      tagText="Upgrade"
      overlineText="Total Subscribers"
      percentCompleted={Math.min(subscriptionLimitMeter?.percentage_complete, 100) || 0}
      underlineText={underLineText}
      TagIcon={BoltIcon}
    />
  );
};

export default ScalePlan;
