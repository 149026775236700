import { useEffect, useRef } from 'react';
import { NodeViewWrapper } from '@tiptap/react';

import { purifyHtml } from '@/utils/domPurify';

interface Props {
  url: string;
  service: 'bluesky';
  data: any;
  anchorEnabled: boolean;
  anchorId: string;
}

export const BlueskyEmbed = ({ url, service, data, anchorEnabled = false, anchorId }: Props) => {
  const blueskyEmbedRef = useRef(null);

  useEffect(() => {
    const renderBlueskyEmbed = () => {
      (window as any)?.bluesky?.scan(blueskyEmbedRef.current);
    };

    setTimeout(renderBlueskyEmbed, 0);
  }, [data?.oembed_data?.html]);

  return (
    <NodeViewWrapper
      data-url={url}
      data-service={service}
      data-drag-handle
      style={{ width: '100%', maxWidth: '520px' }}
      {...(anchorEnabled ? { 'data-anchor': '', id: anchorId } : {})}
      ref={blueskyEmbedRef}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: purifyHtml(data?.oembed_data?.html) }} />
    </NodeViewWrapper>
  );
};

export default BlueskyEmbed;
