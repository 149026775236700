import { useParams } from 'react-router-dom';

import { useCampaign } from '@/hooks/useAdNetwork/internal';
import useCampaignOpportunityGroupsCreate from '@/hooks/useAdNetwork/internal/useCampaignOpportunityGroupsCreate';
import { AdNetworkCampaign } from '@/interfaces/ad_network/internal/campaign';
import { LoadingListPage } from '@/routes/ad_network/_components/LoadingListPage';
import { PageHeading } from '@/routes/ad_network/_components/PageHeading';

import { Form } from './_components/Form';

interface Props {
  campaign: AdNetworkCampaign;
}

const CampaignOpportunityGroupsNew = ({ campaign }: Props) => {
  const onSubmitMutation = useCampaignOpportunityGroupsCreate({ campaignId: campaign.id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Provision</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="new">New</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <Form onSubmitMutation={onSubmitMutation} campaign={campaign} />
    </>
  );
};

export default function Loader() {
  const { campaign_id: id } = useParams<'campaign_id'>() as { campaign_id: string };
  const { data, isLoading, isSuccess, isError } = useCampaign({ id });

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;

  return <CampaignOpportunityGroupsNew campaign={data} />;
}
