import { Link } from 'react-router-dom';
import moment from 'moment-mini';

import { useAdvertiserCampaigns } from '@/hooks/useAdNetwork/internal';
import {
  AdNetworkCampaignPayoutMethodOptions,
  AdNetworkCampaignStatus,
} from '@/interfaces/ad_network/internal/campaign';
import { Button } from '@/ui/Button';

import { CampaignStatusIndicator } from '../../../../../_components/StatusIndicator';

interface Props {
  advertiserId: string;
  status?: AdNetworkCampaignStatus;
}

const CampaignsList = ({ status, advertiserId }: Props) => {
  const { data: campaignData, hasNextPage, fetchNextPage } = useAdvertiserCampaigns({ id: advertiserId, status });
  const campaigns = campaignData?.pages.flatMap((page) => page.campaigns) || [];

  return (
    <>
      <ul className="divide-y divide-gray-100">
        {campaigns.map((campaign) => (
          <li key={campaign.id} className="whitespace-nowrap text-gray-700 hover:bg-gray-50 cursor-pointer">
            <Link to={`/ad_network/campaigns/${campaign.id}`}>
              <div className="grid grid-cols-6 px-4 py-2">
                <div className="col-span-4">
                  <div className="flex items-center space-x-2 mb-2">
                    <div className="text-sm font-medium text-gray-900 truncate">{campaign.name}</div>
                    <CampaignStatusIndicator campaign={campaign} />
                    <div className="text-sm italic text-gray-500 truncate">
                      {AdNetworkCampaignPayoutMethodOptions.find((pm) => pm.value === campaign.payout_method)?.label}{' '}
                      payouts
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 truncate">
                    {moment(campaign.window_start_date).format('LL')} -{' '}
                    {campaign.window_always_on ? 'indefinitely' : moment(campaign.window_end_date).format('LL')}
                  </div>
                </div>
                <div className="col-span-2 text-right">
                  <div className="text-sm font-medium text-gray-900">
                    <time>Created {moment(campaign.created_at).fromNow()}</time>
                  </div>
                  <div className="text-sm text-gray-500">
                    <time>Updated {moment(campaign.updated_at).fromNow()}</time>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>

      {hasNextPage && (
        <div className="p-4 border-t border-gray-100">
          <Button onClick={() => fetchNextPage()} variant="primary-inverse">
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

export default CampaignsList;
