import React, { RefObject, useEffect, useState } from 'react';
import cx from 'classnames';

import { PublicationRewind } from '@/interfaces/publication_rewind';

import { cities } from '../data/cities';

type Props = {
  data?: PublicationRewind;
  setSlideRef: (ref: RefObject<HTMLDivElement>) => void;
  currentViewIndex: number;
  index?: number;
};

type CityData =
  | { maxSubs: number; name: string; image: string; description: string }
  | { maxSubs: number; name: string; image: string; description?: undefined };

const View9 = ({ setSlideRef, data, currentViewIndex, index }: Props) => {
  const subscriberCount = data?.total_subscribers || 0;
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    if (currentViewIndex === index) {
      setSlideRef(contentRef);
    }
    setTimeout(() => {
      setPlayAnimation(true);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewIndex]);

  const getCityBySubscriberCount = (count: number) => {
    const citiesClone = [...cities];
    return (citiesClone as CityData[]).reverse().find((city: CityData) => city.maxSubs <= count);
  };

  const city: CityData | undefined = getCityBySubscriberCount(subscriberCount) || cities[0];

  return (
    <div ref={contentRef} className="w-full h-full relative overflow-hidden rewind-bg-cyber-ink">
      <div className="absolute top-0 left-0 w-[90%] h-[90%] bg-white rounded-[2rem] m-[5%_0_0_5%] p-[35px_55px] flex flex-col" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] m-[5%_0_0_5%] p-[35px_55px] flex flex-col rewind-bg-postcard-border" />
      <div className="rewind-bg-texture top-0 left-0 absolute w-full h-full" />
      <div className="absolute top-0 left-0 w-[90%] h-[90%] m-[5%_0_0_5%] p-[35px] flex flex-col">
        <h1
          className={cx(
            'font-clash-grotesk-v rewind-text-cyber-ink uppercase font-bold w-full text-[4rem] leading-[3.2rem] text-center mt-16 transition-all duration-300',
            !playAnimation && 'translate-x-20 opacity-0',
            playAnimation && 'translate-x-0 opacity-1'
          )}
        >
          YOUR NEWSLETTER IS BIG ENOUGH TO
        </h1>
        <p className="font-satoshi-v rewind-text-cyber-ink text-5xl leading-[4rem] mt-6 font-medium mb-6 text-center">
          ...populate <span className="rewind-text-blue">{city?.name}</span>
          {city?.description && `, ${city.description}`}
        </p>
        <div className="w-[90%] ml-[5%] relative mt-6">
          <div className="flex flex-col w-full justify-between items-center p-6 mb-2 rewind-bg-postcard-image">
            <img src={city?.image} alt="Monowi, Nebraska" className="w-full h-auto mb-6" />
            <img
              src="/rewind-2024/images/stamp.webp"
              alt="Stamp"
              className="transition duration-300 ease-in data-[closed]:opacity-0 w-72 h-auto mb-6 absolute -right-6 -top-7 pointer-events-none"
            />
          </div>
        </div>
        <p className="font-satoshi-v rewind-text-cyber-ink text-2xl font-bold mt-12 text-center rewind-text-blue uppercase tracking-widest">
          Keep up the good work
        </p>
      </div>
    </div>
  );
};

export default View9;
