import React, { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { ChevronDoubleLeftIcon } from '@heroicons/react/20/solid';
import debounce from 'lodash.debounce';

import { usePrompt, useReloadAlert } from '../../../../hooks';
import { EmailMessage } from '../../../../interfaces/email_message';
import api from '../../../../services/swarm';
import ActionModal from '../../../ActionModal';
import { FakeProgress } from '../../../FakeProgress';

import LeftPane from './LeftPane';
import RightPane from './RightPane';

interface EmailMessageEditorParams {
  emailMessage: EmailMessage;
  onExit: () => void;
  onChange?: (data: EmailMessage) => void;
  onSave?: (data: EmailMessage) => void;
  schedulable?: boolean;
  onSchedule?: (emailMessage: EmailMessage, date: Date) => Promise<void>;
  showResourceSelect?: boolean;
  allowPolls?: boolean;
}

const EmailMessageEditor: React.FunctionComponent<EmailMessageEditorParams> = ({
  emailMessage,
  onExit,
  onChange,
  onSave,
  schedulable,
  onSchedule,
  showResourceSelect,
  allowPolls = true,
}: EmailMessageEditorParams) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [formData, setFormData] = useState(emailMessage);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(emailMessage.updated_at);
  const queryClient = useQueryClient();
  const abortControllerRef = useRef<AbortController | null>(null);

  const save = useMemo(
    () =>
      debounce(async (data: EmailMessage) => {
        abortControllerRef.current = new AbortController();
        setIsSaving(true);

        api
          .patch(
            `/email_messages/${emailMessage.id}`,
            {
              publication_id: emailMessage.publication_id,
              email_message: {
                ...data,
              },
            },
            { signal: abortControllerRef.current.signal }
          )
          .then(() => {
            setUnsavedChanges(false);
            setLastUpdated(new Date().toISOString());
            if (onSave) onSave(data);
          })
          .catch((errPayload: any) => {
            if (errPayload.response) {
              toast.error(errPayload?.response?.data?.error || 'Something went wrong, please try again');
            }
          })
          .finally(() => setIsSaving(false));
      }, 450),
    [emailMessage.id, emailMessage.publication_id, onSave]
  );

  const cancelSave = () => {
    abortControllerRef.current?.abort();
  };

  const handleChange = useMemo(
    () => (params: any) => {
      cancelSave();
      setUnsavedChanges(true);

      setFormData((prev) => {
        const newData = {
          ...prev,
          ...params,
        };

        if (onChange) onChange(newData);
        save(newData);

        return newData;
      });
    },
    [onChange, save]
  );

  const handleExit = () => {
    queryClient.invalidateQueries(['email_messages', 'preview', emailMessage?.id], { exact: true });
    queryClient.invalidateQueries(['email_messages', emailMessage?.id], { exact: true });
    onExit();
  };

  useEffect(() => {
    setFormData(emailMessage);
  }, [emailMessage]);

  const shouldPrompt = isSaving || unsavedChanges;
  const [isPrompting, confirmPrompt, cancelPrompt] = usePrompt(shouldPrompt);
  useReloadAlert(shouldPrompt, 'You have unsaved changes. Are you sure you want to leave this page?');

  useEffect(() => {
    if (isPrompting && !shouldPrompt) {
      confirmPrompt();
    }
  }, [isPrompting, shouldPrompt, confirmPrompt]);

  return (
    <>
      <ActionModal
        isOpen={isPrompting}
        onClose={cancelPrompt}
        onProceed={confirmPrompt}
        headerText="Hang tight..."
        resourceId="emailMessage"
        actionText="Leave anyway"
        isWorking={false}
      >
        <p className="mb-4">
          We&apos;re working on saving your latest changes. Once we&apos;re done, you&apos;ll be redirected
          automatically.
        </p>
        {isPrompting && <FakeProgress />}
      </ActionModal>
      <div className="relative md:fixed md:flex justify-end bg-white w-full h-full">
        <div className={`w-full ${showSidebar ? 'md:absolute inset-0 xl:relative xl:w-2/3' : ''}`}>
          <LeftPane
            emailMessage={formData}
            onChange={handleChange}
            onExit={handleExit}
            onSchedule={onSchedule}
            schedulable={schedulable || false}
            isSaving={isSaving}
            lastUpdated={lastUpdated}
            allowPolls={allowPolls}
          />
        </div>
        {showSidebar && (
          <div
            onClick={() => setShowSidebar(false)}
            onKeyPress={() => setShowSidebar(false)}
            role="button"
            tabIndex={0}
            aria-label="overlay-background"
            className="hidden md:block xl:hidden z-10 absolute inset-0 bg-gray-500 bg-opacity-75"
          />
        )}
        <div className={`relative bg-white z-20 h-full sm:border-l ${showSidebar ? 'md:w-2/3 lg:w-1/2 xl:w-1/3' : ''}`}>
          <button
            type="button"
            className="absolute -left-10 top-28 bg-gray-100 hover:bg-gray-200 border border-r-0 shadow-sm text-gray-800 h-16 w-10 rounded-l flex flex-col justify-center z-20 xl:flex"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <ChevronDoubleLeftIcon
              className={`text-gray-800 m-2 transform transition-all ${showSidebar && 'rotate-180'}`}
            />
          </button>
          <div className={`h-full ${showSidebar ? '' : 'md:hidden'}`}>
            <RightPane
              emailMessage={formData}
              onChange={handleChange}
              showResourceSelect={showResourceSelect || false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailMessageEditor;
