import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { HandRaisedIcon, RectangleStackIcon, SparklesIcon } from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCreateNavGroup } from '@/hooks/useNavGroups';
import useOptions from '@/hooks/useOptions';
import { NavGroup } from '@/interfaces/nav_group';
import { Button } from '@/ui/Button';

import NavGroupForm from './NavGroupForm';

interface Props {
  navGroups: NavGroup[] | undefined;
}

const CreateNavGroup = ({ navGroups }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const contentTags = useOptions(currentPublicationId, 'content_tags');
  const hasContentTags = contentTags?.data?.options.length > 0;
  const hasCategoryGroup = Boolean(navGroups?.find((group) => group.is_category_group));

  const [isAddCategoriesModalOpen, setIsAddCategoriesModalOpen] = useState(false);
  const [isCategoriesCreating, setIsCategoriesCreating] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');

  const createNavGroup = useCreateNavGroup({
    onSuccess: () => {
      toast.success('Created!');
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      setTitle('');
      setIsOpen(false);

      if (isAddCategoriesModalOpen) {
        setIsCategoriesCreating(false);
        setIsAddCategoriesModalOpen(false);
      }
    },
    onError: () => {
      if (isAddCategoriesModalOpen) {
        setIsCategoriesCreating(false);
        setIsAddCategoriesModalOpen(false);
      }
    },
  });

  const handleCreateNavGroup = () => {
    createNavGroup.mutate({ title, group_type: 'nav' });
  };

  const handleCreateCategoriesGroup = () => {
    setIsCategoriesCreating(true);
    createNavGroup.mutate({ title: 'Categories', group_type: 'category' });
  };

  const handleClose = () => {
    setTitle('');
    setIsOpen(false);
  };

  return (
    <>
      <NavGroupForm
        bodyId=""
        title={title}
        isOpen={isOpen}
        headerText="New Dropdown"
        ctaText="Create"
        loadingText="Creating..."
        handleTitleChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
        isLoading={createNavGroup.isLoading}
        handleSubmit={handleCreateNavGroup}
        handleClose={handleClose}
      />
      {hasContentTags ? (
        <ActionModal
          isOpen={isAddCategoriesModalOpen}
          onClose={() => setIsAddCategoriesModalOpen(false)}
          onProceed={handleCreateCategoriesGroup}
          resourceId=""
          isWorking={isCategoriesCreating}
          headerText="Add Categories Group"
          actionText="Add"
        >
          <div className="space-y-2 text-sm">
            <p>
              By adding a Categories Menu Item to your navbar we will automatically add a dropdown menu with links to
              all of your category-specific content.
            </p>
            <p>
              For example: If you have posts tagged as <span className="font-bold">Finance</span> via your
              publication&apos;s content tags then there will be a link to a new Finance-specific page on your site.
            </p>
            <div className="bg-blue-50 p-4 rounded space-y-2">
              <p className="text-xs text-blue-500">
                <span className="font-bold">So what?</span>
              </p>
              <p className="text-xs text-blue-500">
                These pages backlink across category-specific content as well as point to other content across your
                publication. This can have powerful SEO benefits and help your site + content pages rank in search.
              </p>
            </div>
          </div>
        </ActionModal>
      ) : (
        <ActionModal
          isOpen={isAddCategoriesModalOpen}
          onClose={() => setIsAddCategoriesModalOpen(false)}
          onProceed={() => navigate('/content_tags')}
          resourceId=""
          isWorking={isCategoriesCreating}
          headerText="You need to add Content Tags first"
          actionText="Add Content Tags"
        >
          <div className="space-y-2 text-sm">
            <div className="bg-blue-50 p-4 rounded space-y-2">
              <p className="text-xs text-blue-500">
                <span className="font-bold">How can I enable this feature?</span>
              </p>
              <p className="text-xs text-blue-500">
                The Categories Menu Item will be available to you after creating{' '}
                <span className="font-bold">at least one</span> Content Tag and linking it to a Post.
              </p>
              <p className="text-xs text-blue-500">
                Content Tags provide meaningful context for readers and help group your posts into logical categories.
              </p>
              <p className="text-xs text-blue-500">
                By then enabling our Categories feature, we will automatically add a dropdown Menu Item with links to
                all of your category-specific content.
              </p>
              <p className="text-xs text-blue-500">
                The combination of these two features can have powerful SEO benefits and help your site + content pages
                rank in search.
              </p>
              <p className="text-xs text-blue-500">Ready to get started?</p>
            </div>
          </div>
        </ActionModal>
      )}

      <div className="space-y-6">
        <div className="flex justify-between sm:flex-row flex-col space-y-2 sm:space-y-0">
          <Button Icon={RectangleStackIcon} type="button" variant="primary" onClick={() => setIsOpen(true)}>
            Add Menu Item
          </Button>

          {!hasCategoryGroup && (
            <Button
              Icon={SparklesIcon}
              type="button"
              variant="primary-inverse"
              onClick={() => setIsAddCategoriesModalOpen(true)}
            >
              Add Categories Menu Item
            </Button>
          )}
        </div>

        <div className="bg-blue-50 p-4 rounded space-y-2">
          <div className="flex space-x-1 items-center">
            <HandRaisedIcon className="h-3 w-3 font-bold text-xs text-blue-500" />
            <span className="font-bold text-xs text-blue-500">Helping Hand:</span>
          </div>
          <p className="text-xs text-blue-500">
            Menu Items are hyperlinks that appear across the top of your website&apos;s navbar. They are comprised of a
            collection of internal pages on your beehiiv-hosted site, or links to external webpages.
          </p>
          <p className="text-xs text-blue-500">
            You can assign a single Page to a Menu Item, or create a dropdown by adding multiple Pages.
          </p>
          <p className="text-xs text-blue-500">
            Please note, you can add up to <span className="font-bold text-xs text-blue-500">15 Menu Items</span> to
            your navbar. However, for best results, we recommend keeping 5 or 6 Menu Items in the navbar and nesting any
            number of pages within those Menu Items.
          </p>
          <p className="text-xs text-blue-500">To get started:</p>
          <div className="pl-4">
            <div className="space-x-1">
              <span className="font-bold text-xs text-blue-500">Step 1:</span>
              <span className="text-xs text-blue-500">Add a Menu Item</span>
            </div>
            <div className="space-x-1">
              <span className="font-bold text-xs text-blue-500">Step 2:</span>
              <span className="text-xs text-blue-500">Create pages and assign them to a Menu Item</span>
            </div>
            <div className="space-x-1">
              <span className="font-bold text-xs text-blue-500">Step 3:</span>
              <span className="text-xs text-blue-500">Preview how it appears on your live site</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNavGroup;
