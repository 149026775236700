import { useMemo } from 'react';

import { ChartCard } from '@/components/Chartsv2';
import BarList from '@/components/Chartsv2/BarList';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { TimePeriod } from '@/utils';
import { timePeriodLabels } from '@/utils/timePeriods';

import useAcquisitionSourceUpgradesByPeriod from '../_hooks/useAcquisitionSourceUpgradesByPeriod';

const TopAttributionSourcesCard = () => {
  const { period, TimePeriodSelect } = useTimePeriodSelect({ defaultValue: TimePeriod.LAST_4_WEEKS });
  const { data, isLoading, isError } = useAcquisitionSourceUpgradesByPeriod(period as keyof typeof timePeriodLabels);

  const valueFormatter = (num: number) => new Intl.NumberFormat('en').format(num);

  const parsedData = useMemo(
    () => (data?.chart_data ? data.chart_data.map((o) => ({ ...o, value: parseFloat(o.value) })) : []),
    [data?.chart_data]
  );

  return (
    <ChartCard
      title="Top Upgrade Attribution Sources"
      isLoading={isLoading}
      noResults={isError || (!isLoading && data?.chart_data.length === 0)}
      noResultsText="No attribution sources found for the selected time range."
      className="col-span-2 lg:col-span-1 !justify-start"
      actionChildren={<TimePeriodSelect />}
    >
      <BarList className="pt-5" color="neutral" data={parsedData} valueFormatter={valueFormatter} />
    </ChartCard>
  );
};

export default TopAttributionSourcesCard;
