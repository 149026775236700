import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { PollResourcePreview } from '@/interfaces/poll';
import api from '@/services/swarm';

interface ApiResponse {
  email_messages: PollResourcePreview[];
  pagination: Pagination;
}

const usePollEmailMessages = ({ id }: { id: string }) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPosts = ({ pageParam = 1 }) =>
    api
      .get(`/polls/${id}/email_messages`, {
        params: {
          page: pageParam,
          per_page: 3,
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['polls', id, 'email_messages'], fetchPosts, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
};

export default usePollEmailMessages;
