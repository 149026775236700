import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { FactPostClick } from '../interfaces/fact_post_click';
import api from '../services/swarm';

interface ApiResponse {
  fact_post_click: FactPostClick;
}

interface Arguments {
  postId: string;
  factPostClickId: string;
  useApiary?: boolean;
}

export default function usePostFactPostClick({ postId, factPostClickId, useApiary = false }: Arguments) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostFactPostClick = () =>
    api
      .get(`/posts/${postId}/fact_post_clicks/${factPostClickId}`, {
        params: {
          publication_id: currentPublicationId,
          use_apiary: useApiary === true ? '1' : undefined,
        },
      })
      .then((res) => res.data);
  return useQuery<ApiResponse>(['postFactPostClick', postId, factPostClickId], fetchPostFactPostClick, {
    staleTime: 30000,
    enabled: Boolean(factPostClickId) && Boolean(postId),
  });
}
