import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentIcon, LinkIcon, PlusIcon } from '@heroicons/react/24/outline';

import { useNavGroups } from '@/hooks/useNavGroups';
import OptionsDropdown from '@/routes/website_builder/_components/Helpers/OptionsDropdown';
import { Button } from '@/ui/Button';

import NewLinkPanel from './NewLinkPanel';

const AddNewPage = () => {
  const navigate = useNavigate();
  const navGroupsQuery = useNavGroups();
  const { data: navGroups } = navGroupsQuery;

  const [isLinkPanelOpen, setIsLinkPanelOpen] = useState<boolean>(false);

  return (
    <>
      <div className="w-full flex justify-between items-center">
        <OptionsDropdown
          title="Add Page"
          options={[
            {
              label: 'Internal Page',
              Icon: DocumentIcon,
              onClick: () => navigate('/website_builder/custom_pages/new'),
            },
            {
              label: 'External Page',
              Icon: LinkIcon,
              onClick: () => setIsLinkPanelOpen(true),
            },
          ]}
        >
          <Button onClick={() => {}} Icon={PlusIcon} size="xs" variant="primary-inverse">
            Add Page
          </Button>
        </OptionsDropdown>
      </div>
      {navGroups && (
        <NewLinkPanel isOpen={isLinkPanelOpen} handleClose={() => setIsLinkPanelOpen(false)} navGroups={navGroups} />
      )}
    </>
  );
};

export default AddNewPage;
