import { Fragment, ReactNode } from 'react';

import { useSettings } from '@/context/settings-context';
import usePostOpens from '@/hooks/usePostOpens';

interface Props {
  postId: string;
  showMore: boolean;
  children: (
    chartData: {
      labels: string[];
      datasets: { label: string; data: number[] }[];
    },
    isLoading: boolean,
    isError: boolean
  ) => ReactNode;
}

const PostOpensContainer = ({ postId, showMore, children }: Props) => {
  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;

  const { data, isLoading, isError } = usePostOpens({ id: postId, useApiary: canUseApiary });

  const opens = data?.opens || {};

  let labels = Object.keys(opens);
  let dataPoints = Object.values(opens);

  if (!showMore) {
    labels = labels.slice(0, 13);
    dataPoints = dataPoints.slice(0, 13);
  }

  return (
    <>
      {children(
        {
          labels,
          datasets: [
            {
              label: 'Unique Opens',
              data: dataPoints,
            },
          ],
        },
        isLoading,
        isError
      )}
    </>
  );
};

export default PostOpensContainer;
