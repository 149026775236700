import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  onClick: () => void;
}>;

const GroupButton = ({ onClick, children }: Props) => (
  <button
    type="button"
    onClick={onClick}
    className="ml-1 flex items-center rounded-md hover:bg-gray-100 hover:text-gray-800 text-gray-400 font-semibold text-sm py-1 px-2"
  >
    {children}
  </button>
);

export default GroupButton;
