import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import Button from '@/components/TiptapEditor/components/ui/Button';
import { Backlink as IBacklink } from '@/interfaces/backlink';
import { cn } from '@/utils/cn';

import { Icon } from '../ui/Icon';

interface Props {
  backlink: IBacklink;
  length: number;
  references: Array<{ id: string; title: string }>;
  onFocusReference?: (id: string) => void;
}

export const Backlink = ({ backlink, length, references, onFocusReference }: Props) => {
  const [showReferences, setShowReferences] = useState(false);

  const url = useMemo(() => {
    if (backlink.postToShow?.status === 'draft') {
      return backlink.postToShow.draft_url;
    }

    return backlink.postToShow?.url;
  }, [backlink.postToShow]);

  return (
    <div
      className={cn(
        'w-full flex flex-col transition-all',
        showReferences && 'bg-gray-200 bg-opacity-50 p-2 rounded-md'
      )}
    >
      <div className="flex items-center justify-between gap-2 w-full">
        <p className="flex items-center gap-2" title={backlink.postToShow?.web_title}>
          <Button
            $leftSlot={<Icon name="ChevronRight" className={cn('!size-3', showReferences && 'rotate-90')} />}
            $isIconButton
            $isToggleButton
            $variant="tertiary"
            $size="small"
            $active={showReferences}
            onClick={() => setShowReferences(!showReferences)}
          />

          <span className="truncate max-w-56">{backlink.postToShow?.web_title}</span>

          <div className="bg-gray-200 rounded-md text-sm tabular-nums flex justify-center items-center px-1.5 py-[2px]">
            {length}
          </div>
        </p>

        <div className="flex items-center gap-2">
          <Tippy
            content={<div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200 text-sm">Edit</div>}
            placement="left"
          >
            <Link
              to={`/posts/${backlink.postToShow?.id}/edit`}
              target="_blank"
              rel="noreferrer noopener nofollow"
              className="flex justify-center items-center"
            >
              <Icon name="Edit" className="w-4 h-4 text-gray-500" />
            </Link>
          </Tippy>

          <Tippy
            content={
              <div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200 text-sm">
                {backlink.postToShow?.status === 'draft' ? 'View draft' : 'View published'}
              </div>
            }
            placement="left"
          >
            <a
              href={url}
              target="_blank"
              rel="noreferrer noopener nofollow"
              className="flex justify-center items-center"
            >
              <Icon name="External" className="w-4 h-4 text-gray-500" />
            </a>
          </Tippy>
        </div>
      </div>

      {showReferences && (
        <div className="flex flex-col gap-px mt-2 px-1">
          <div className="flex items-center gap-1">
            <span className="text-sm font-bold">References:</span>

            <Tippy
              content={
                <div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200 text-sm w-44">
                  Click to focus the references in editor.
                </div>
              }
              placement="top"
            >
              <span>
                <Icon name="Info" className="!size-3" />
              </span>
            </Tippy>
          </div>

          {references.map((reference) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              key={reference.id}
              className="text-gray-800 text-sm cursor-pointer"
              onClick={() => onFocusReference?.(reference.id)}
            >
              {reference.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
