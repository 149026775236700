import { useState } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/24/outline';

import NotEditing from './NotEditing';
import SaveButton from './SaveButton';

interface Props {
  name?: string;
  label: string;
  value: string;
  placeholder?: string;
  icon: string | React.ReactNode;
  showIconWithDescription?: boolean;
  onSave: (val: string) => Promise<any>;
  passesValidation?: (val: string) => boolean;
  isSaving: boolean;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
}

const InputIconForm = ({
  name,
  label,
  value,
  icon,
  onSave,
  passesValidation,
  isSaving,
  placeholder,
  toastSuccessMessage,
  toastErrorMessage,
  showIconWithDescription,
}: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const showSaving = isSaving && isEditing;

  const handleSave = async () => {
    if (passesValidation && !passesValidation(inputValue)) {
      toast.error(toastErrorMessage || 'Invalid input');
      return;
    }

    if (inputValue !== value) {
      await onSave(inputValue)
        .then(() => {
          setIsEditing(false);

          if (toastSuccessMessage) {
            toast.success(toastSuccessMessage);
          }
        })
        .catch(() => {
          if (toastErrorMessage) {
            toast.error(toastErrorMessage);
          }
        });
    }
  };

  if (isEditing) {
    return (
      <div className="flex justify-between items-center pb-4 space-x-6 border-b border-gray-100 pb-4 last:border-b-0">
        <label htmlFor="company-website" className="block text-sm font-medium text-gray-700 w-full">
          {label}
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
              {icon}
            </span>
            <input
              type="text"
              name={name || label.split(' ').join('-')}
              className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              placeholder={placeholder}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        </label>
        <SaveButton
          isSaving={showSaving}
          isEditing={isEditing}
          closeEditing={() => setIsEditing(false)}
          changesDetected={Boolean(inputValue !== value)}
          type="button"
          onClick={handleSave}
        />
      </div>
    );
  }

  const showIcon = showIconWithDescription && inputValue;
  return (
    <div className="flex justify-between items-center pb-4 border-b border-gray-100 pb-4 last:border-b-0">
      <NotEditing label={label} value={showIcon ? `${icon}${inputValue}` : inputValue} />

      <div className="pt-4">
        <button
          className="h-6 w-6 flex justify-center items-center rounded-sm border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
          type="button"
          onClick={() => setIsEditing(true)}
        >
          <PencilIcon className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};

export default InputIconForm;
