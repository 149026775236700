import React, { memo } from 'react';

import { TiptapEditor } from '@/components/TiptapEditor';
import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';

import settings from './settings';

const MemoizedEditor = memo(TiptapEditor);

interface EditorProps {
  onChange: (data: any) => void;
  initialValue: any;
}

const Editor = ({ onChange, initialValue }: EditorProps) => {
  const onUpdate = (data: any) => {
    onChange(data.editor.getJSON());
  };

  const adNetworkPublicationId = window.env.REACT_APP_ANPID;
  if (!adNetworkPublicationId) {
    throw new Error('REACT_APP_ANPID is not defined');
  }

  return (
    <PublicationProvider id={adNetworkPublicationId}>
      <MemoizedEditor
        publicationId={adNetworkPublicationId}
        settings={settings}
        onUpdate={onUpdate}
        usesCollaboration={false}
        content={initialValue}
        allowPolls={false}
      />
    </PublicationProvider>
  );
};

export default Editor;
