import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

interface Payload {
  upgrade_page: {
    main_section?: string;
    testimonials_section?: string;
    faq_section?: string;
  };
}

interface Props {
  pageId: string;
  onSuccess: (response: any) => void;
}

const useUpdateUpgradePage = ({ onSuccess, pageId }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (payload: Payload) =>
      api.patch(`/upgrade_pages/${pageId}`, {
        publication_id: currentPublication?.id,
        ...payload,
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'There was an issue updating this Page.');
      },
    }
  );
};

export default useUpdateUpgradePage;
