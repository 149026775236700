import { useSettings } from '@/context/settings-context';

import { LeftPane, Main, RightPane } from '../components/Layout';
import { SectionTabPanel } from '../components/SectionTabs';

import ChartOptions from './ChartOptions';
import EarnMoreActions from './EarnMoreActions';
import KPIs from './KPIs';
import RecommendedBoosts from './RecommendedBoosts';

const Monetization = () => {
  const { settings } = useSettings();

  return (
    <SectionTabPanel>
      <Main>
        <LeftPane>
          <KPIs />
          <ChartOptions />
          {settings?.organization_boosts && <RecommendedBoosts showUpsell={!settings?.boosts} />}
        </LeftPane>
        <RightPane>
          <EarnMoreActions />
        </RightPane>
      </Main>
    </SectionTabPanel>
  );
};

export default Monetization;
