import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Segment } from '../interfaces/segment';
import api from '../services/swarm';

export default function useSegment({ segmentId }: { segmentId: string }) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<Segment>(
    ['segment', segmentId, currentPublicationId],
    () =>
      api
        .get(`/segments/${segmentId}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
