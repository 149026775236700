import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ArrowPathIcon, DocumentPlusIcon } from '@heroicons/react/24/outline';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import TableHeaders from '@/components/TableHeaders';
import { useSettings } from '@/context/settings-context';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { Order, Sort } from '@/interfaces/general';
import FormSearch from '@/models/formSearch';
import { Button } from '@/ui/Button';
import { TimePeriod } from '@/utils';

import PageHeading from '../../../components/Layout/PageLayout/PageHeading';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useCurrentPublication } from '../../../hooks';
import { useCreateForm, usePaginatedForms } from '../../../hooks/useForms';
import { useTutorial } from '../../../hooks/useTutorials';
import { Form, FormQueryParams } from '../../../interfaces/form';
import { TutorialType } from '../../../interfaces/tutorial';
import SearchInput from '../../../ui/SearchInput';

import FormCard from './FormCard';

const TABLE_HEADERS: Map<string, string> = new Map([
  ['form.title', 'Name'],
  ['status', 'Status'],
  ['type', 'Type'],
  ['response_count', '# of Responses'],
  ['most_recent_response', 'Latest Response'],
  ['created_at', 'Created'],
  ['poll_options', ''],
]);

const SORTABLE_HEADERS = ['form.title', 'status', 'response_count', 'most_recent_response', 'created_at'];

const COLUMN_SIDE_PADDING = 3;
const TEXT_COLUMN_CLASSES = `px-${COLUMN_SIDE_PADDING} py-4 whitespace-nowrap text-sm text-gray-500`;

const Forms = () => {
  const navigate = useNavigate();
  const tutorial = useTutorial(TutorialType.FORMS);
  const { settings } = useSettings();
  const hasSurveysV2Enabled = settings?.surveys_v2 === true;

  const [searchParams, setSearchParams] = useQueryParams<FormQueryParams>({
    search: withDefault(StringParam, ''),
    order: withDefault(StringParam, 'created_at'),
    dir: withDefault(StringParam, Order.DESC),
    filterByDateCreated: withDefault(StringParam, TimePeriod.ALL_TIME),
  });

  const handleSearch = (search: string | undefined) => {
    setSearchParams({ ...searchParams, search });
  };

  const [shouldResetSearch, setShouldResetSearch] = useState(false);

  const handleDateCreatedChange = (filterByDateCreated: TimePeriod) => {
    setSearchParams({ ...searchParams, filterByDateCreated });
  };

  const { search, order, dir, filterByDateCreated } = searchParams;

  const query = new FormSearch(searchParams);
  const paginatedFormsQuery = usePaginatedForms(query);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching } = paginatedFormsQuery;
  const { data: currentPublication } = useCurrentPublication();

  const forms = data?.pages.flatMap((page) => page.forms) || [];
  const totalFormsCount = data?.pages[0]?.pagination?.total;
  const showingFormsCount = forms?.length || 0;

  const { TimePeriodSelect: DateCreated } = useTimePeriodSelect({
    defaultValue: filterByDateCreated as TimePeriod,
    size: 'md',
    label: 'Date Created',
    labelClassName: '-mt-[18px]',
  });

  const isNoResults = !isLoading && forms.length === 0;

  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  // Reset search when no results
  const handleResetSearch = () => {
    setSearchParams({
      ...searchParams,
      search: undefined,
      filterByDateCreated: TimePeriod.ALL_TIME,
    });

    setShouldResetSearch(true);
  };

  const handleSortChange = (newSort: Sort) => {
    setSearchParams({ ...searchParams, ...newSort });
  };

  const createForm = useCreateForm({
    onSuccess: (result) => {
      toast.success('Form created successfully');
      if (hasSurveysV2Enabled) {
        navigate(`/forms/${result.form.id}/edit/settings`);
      } else {
        navigate(`/forms/${result.form.id}/edit`);
      }
    },
  });

  const handleCreateForm = () => {
    createForm.mutate({});
  };

  const renderNotResults = () => {
    const noSearchResults = isNoResults && search;

    const text = noSearchResults
      ? `No results found for search, '${search}'`
      : `Once you create your first form, it will show up here.`;

    return (
      <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500 text-center">{text}</p>
          {noSearchResults ? (
            <Button variant="primary-inverse" onClick={handleResetSearch}>
              <div className="flex">
                <ArrowPathIcon className="h-5 w-5 mr-2" />
                Reset Search
              </div>
            </Button>
          ) : (
            <Button variant="primary" onClick={handleCreateForm}>
              <div className="flex">
                <DocumentPlusIcon className="h-5 w-5 mr-2" />
                Create Survey
              </div>
            </Button>
          )}
        </div>
      </div>
    );
  };

  const updateFormList = () => {
    paginatedFormsQuery.refetch();
  };

  return (
    <div>
      <PageHeading
        title="Surveys"
        description="Create re-usable forms to collect data about your audience"
        tutorial={tutorial}
      >
        <div className="flex justify-end">
          <Button variant="primary" onClick={handleCreateForm} loading={createForm.isLoading}>
            <div className="flex">
              <DocumentPlusIcon className="h-5 w-5 mr-2" />
              {createForm.isLoading ? 'Creating...' : 'Create Survey'}
            </div>
          </Button>
        </div>
      </PageHeading>

      <div className="mb-8 pb-8 pt-4 border-b border-gray-200">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-full pt-4 md:pt-0 relative flex space-x-1 items-end">
            <div className="w-full sm:w-fit">
              <SearchInput
                defaultValue={search || ''}
                shouldDebounce={false}
                shouldReset={shouldResetSearch}
                onClearSearch={handleResetSearch}
                onSearch={handleSearch}
                placeholder="Search Surveys"
              />
            </div>
            <DateCreated handleChange={handleDateCreatedChange} />

            {(isLoading || isFetching) && (
              <div className="hidden md:block">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <span className="text-xs font-semibold text-gray-600">{`Showing ${showingFormsCount} of ${totalFormsCount} results`}</span>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-x-hidden border rounded-md border-gray-200 relative">
            {data && forms.length === 0 ? (
              renderNotResults()
            ) : (
              <table className="min-w-full divide-y divide-gray-200 rounded-md">
                <TableHeaders
                  headers={TABLE_HEADERS}
                  sort={{ order, dir: dir as Order }}
                  sortableHeaders={SORTABLE_HEADERS}
                  handleSortChange={handleSortChange}
                  headerSidePadding={COLUMN_SIDE_PADDING}
                />
                <tbody className="bg-white divide-y divide-gray-200">
                  {forms.map((form: Form) => {
                    const isPostSubscribeForm = currentPublication?.post_subscribe_form_id === form.id;
                    const isPostUnsubscribeForm = currentPublication?.post_unsubscribe_form_id === form.id;

                    return (
                      <FormCard
                        key={form.id}
                        form={form}
                        isPostSubscribeForm={isPostSubscribeForm}
                        isPostUnsubscribeForm={isPostUnsubscribeForm}
                        publicationId={currentPublication?.id || ''}
                        classNames={TEXT_COLUMN_CLASSES}
                        updateFormList={updateFormList}
                      />
                    );
                  })}
                </tbody>
              </table>
            )}

            {hasNextPage && (
              <div className="text-center my-6">
                <div>
                  <Button
                    variant="primary-inverse"
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                  >
                    {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forms;
