import { Node } from '@tiptap/pm/model';
import { NodeViewWrapper } from '@tiptap/react';

import { BreakWrapper } from '../../../components/ui/BreakWrapper';

export const SubscriberBreakView = ({ node }: { node: Node }) => {
  return (
    <NodeViewWrapper
      data-drag-handle
      {...(node.attrs.anchorEnabled ? { 'data-anchor': '', id: node.attrs.anchorId } : {})}
    >
      <BreakWrapper
        icon="SubscriberBreak"
        title={
          <>
            Subscriber break <br /> (only for website view)
          </>
        }
        description="This is for the website only. Only subscribers who are logged in on the web will have access to read below this point. Other visitors will need to either login or subscribe to your newsletter."
      />
    </NodeViewWrapper>
  );
};

export default SubscriberBreakView;
