import { EyeIcon } from '@heroicons/react/20/solid';

import { Form } from '@/interfaces/form';
import { Button } from '@/ui/Button';

interface Props {
  form: Form;
}

const PreviewFormButton = ({ form }: Props) => {
  if (!form.url || form.form_questions.length === 0) {
    return null;
  }
  return (
    <Button
      type="button"
      size="xs"
      onClick={() => window.open(`${form.url}?preview=true`)}
      variant="primary-inverse"
      Icon={EyeIcon}
      iconRight
    >
      Preview
    </Button>
  );
};

export default PreviewFormButton;
