import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

interface Payload {
  landing_page_id: string;
  testimonial_id: string;
}

interface Props {
  onSuccess: (response: any) => void;
}

const useCreateLandingPageTestimonial = ({ onSuccess }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (payload: Payload) =>
      api.post(`/landing_page_testimonials`, {
        publication_id: currentPublication?.id,
        landing_page_testimonial: payload,
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Could not create.');
      },
    }
  );
};

export default useCreateLandingPageTestimonial;
