import { useCallback, useEffect, useRef, useState } from 'react';

import { Shortcut } from '../Shortcut';

import useFilteringContext from './filtering-context';
import FilterSelectButton from './FilterSelectButton';
import { Filter, filters } from './types';

const FilterSelector = () => {
  const { setSelectedFilter } = useFilteringContext();
  const [filterTypeahead, setFilterTypeahead] = useState('');
  const [highlightedFilterIndex, setHighlightedFilterIndex] = useState(0);

  const onSelectFilter = useCallback(
    (filter: Filter) => {
      setSelectedFilter(filter);
    },
    [setSelectedFilter]
  );

  const filteredFilters = filters.map((groupFilters) => {
    return groupFilters.filter((filter) => {
      const label = filter.label.toLowerCase();

      if (label.includes(filterTypeahead.toLowerCase())) {
        return true;
      }

      return false;
    });
  });

  const handleTypeahead = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterTypeahead(event.target.value);
    setHighlightedFilterIndex(0);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  // autofocus on input after 200ms
  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();

        setHighlightedFilterIndex((prevIndex) => {
          if (prevIndex + 1 < filteredFilters.flat().length) {
            return prevIndex + 1;
          }

          if (prevIndex + 1 === filteredFilters.flat().length) {
            return 0;
          }

          return prevIndex;
        });
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();

        setHighlightedFilterIndex((prevIndex) => {
          if (prevIndex - 1 >= 0) {
            return prevIndex - 1;
          }

          if (prevIndex - 1 < 0) {
            return filteredFilters.flat().length - 1;
          }

          return prevIndex;
        });
      }
    };

    const handleEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        const filter = filteredFilters.flat()[highlightedFilterIndex];
        onSelectFilter(filter);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keyup', handleEnter);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keyup', handleEnter);
    };
  }, [filteredFilters, highlightedFilterIndex, onSelectFilter]);

  return (
    <div className="bg-white border border-gray-300 shadow-2xl rounded-md text-sm left-0 w-fit text-gray-600 font-medium divide-y">
      <div className="p-2 relative">
        <input
          type="text"
          className="px-2 py-1 text-sm appearance-none border-none ring-0 focus:outline-none focus:ring-0"
          placeholder="Filter..."
          value={filterTypeahead}
          onChange={handleTypeahead}
          ref={inputRef}
        />
        <div className="absolute flex transform top-1/2 right-2 -translate-y-1/2">
          <Shortcut>F</Shortcut>
        </div>
      </div>

      {filteredFilters.map((groupFilters, index) => {
        if (groupFilters.length === 0) {
          return null;
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="p-2" key={index}>
            {groupFilters.map((filter) => {
              const flatIndex = filteredFilters.flat().indexOf(filter);
              const highlighted = flatIndex === highlightedFilterIndex;

              return (
                <FilterSelectButton
                  key={filter.label}
                  highlighted={highlighted}
                  onHighlight={() => setHighlightedFilterIndex(flatIndex)}
                  filter={filter}
                  onSelectFilter={onSelectFilter}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default FilterSelector;
