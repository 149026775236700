import styled, { keyframes } from 'styled-components';

const spinnerAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<{
  size?: number;
}>`
  animation: ${spinnerAnimation} 1s linear infinite;
  border-radius: 100%;
  border: ${(p) => (p.size ?? 1) * 2 ?? 2}px solid currentColor;
  border-top-color: transparent;
  height: ${(p) => p.size ?? 1}rem;
  width: ${(p) => p.size ?? 1}rem;
`;

export default Spinner;
