import { useMutation } from 'react-query';

import { useCurrentPublication } from '@/hooks';
import api from '@/services/swarm';

const useCreateTrial = () => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(({ selectedPlan }: { selectedPlan: string }) =>
    api
      .post(`/organizations/${currentPublication?.organization_id}/trial`, {
        selected_plan: selectedPlan,
      })
      .then((res) => res.data)
  );
};

export default useCreateTrial;
