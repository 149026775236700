import { useEffect, useRef } from 'react';
import cx from 'classnames';

import { usePageContext } from './PageContext';

interface Props {
  url: string;
  title: string;
}

const PageIframe = ({ url, title }: Props) => {
  const { screenSize, status, currentPublication } = usePageContext();
  const isMobile = screenSize === 'mobile';
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Communicate to orchid to revalidate the child route on saves.
  // See hook useRefetchPreview in orchid.
  useEffect(() => {
    if (iframeRef?.current && status === 'saved') {
      iframeRef.current.contentWindow?.postMessage({ publicationId: currentPublication?.id, status: 'refresh' }, url);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, iframeRef]);

  return (
    <iframe
      ref={iframeRef}
      title={title}
      src={url}
      width=""
      height=""
      className={cx('w-full h-full', isMobile ? ' max-w-sm mx-auto' : '')}
    />
  );
};

export default PageIframe;
