import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface ApiResponse {
  id: string;
}

interface Arguments {
  onSuccess?: () => void;
}

interface Payload {
  id?: string;
  boostOfferId: string;
}

const useDeleteBoostInvite = ({ onSuccess }: Arguments) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<ApiResponse, unknown, Payload>(
    ({ id, boostOfferId }) =>
      api
        .delete(`/boosts/grow/boost_invites/${id}`, {
          params: {
            publication_id: currentPublicationId,
            boost_offer_id: boostOfferId,
          },
        })
        .then((res) => res.data),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useDeleteBoostInvite;
