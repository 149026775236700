import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { MutationErrorResponse } from '../../../interfaces/ad_network/internal/mutation_error_response';
import { AdNetworkOpportunity } from '../../../interfaces/ad_network/internal/opportunity';
import api from '../../../services/swarm';

export default function usePublicationOpportunitiesCreate({ publicationId }: { publicationId: string }) {
  const navigate = useNavigate();

  const updateCampaign = (formData: FormData): Promise<AdNetworkOpportunity> =>
    api.post(`/ad_network/internal/publications/${publicationId}/opportunities`, formData).then((res) => res.data);

  return useMutation<AdNetworkOpportunity, MutationErrorResponse, FormData>(updateCampaign, {
    onSuccess: () => {
      toast.success('Opportunity created successfully');
      navigate(`/ad_network/publications/${publicationId}/opportunities`);
    },
    onError: (error) => {
      const { errors } = error.response.data;

      Object.keys(errors).forEach((key) => {
        toast.error(`${key} ${errors[key][0]}`);
      });
    },
  });
}
