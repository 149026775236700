import { Link } from 'react-router-dom';

import { Typography } from '@/components/Typography';
import { usePermissions } from '@/context/permissions-context';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import appendSettingsWorkspaceId from '@/utils/appendSettingsWorkspaceId';

interface Props {
  publicationId: string;
  organizationId: string;
}

const DeleteAccountForm = ({ publicationId, organizationId }: Props) => {
  const { checkPermissions } = usePermissions();
  const canSeeWorkspace = checkPermissions('views/settings/company', 'read')

  if (!canSeeWorkspace) return null;
  return (
    <BodyContainer>
      <CardHeader
        title="Account Profile Deletion"
        description="Deleting your workspace automatically removes your account profile. These actions occur together to ensure all your data is properly managed."
      />
      <Link to={appendSettingsWorkspaceId('/settings/workspace/overview', organizationId, publicationId)}>
        <Typography token="font-medium/text/sm" color="secondary" colorWeight="600" className="underline">
          Take me to my workspace settings
        </Typography>
      </Link>
    </BodyContainer>
  );
};

export default DeleteAccountForm;
