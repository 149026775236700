import { CheckIcon } from '@heroicons/react/20/solid';

interface Props {
  totalSteps: 2 | 3 | 4;
  currentStep: number;
}

const LineFiller = () => <div className="h-1 w-16 bg-surface-200" />;
const LineFilled = () => <div className="h-1 w-16 bg-action-tertiary-600" />;
const FilledStep = () => (
  <div className="bg-action-tertiary-600 rounded-full p-1">
    <CheckIcon className="w-5 h-5 text-white" />
  </div>
);
const NextStep = () => <div className="w-7 h-7 bg-gray-200 border-2 border-surface-300 rounded-full" />;
const OnStep = () => (
  <div className="w-7 h-7 bg-gray-200 border-2 border-action-tertiary-600 rounded-full flex justify-center items-center">
    <div className="w-2 h-2 bg-action-tertiary-600 rounded-full" />
  </div>
);

const getStepComponent = (index: number, currentStep: number) => {
  if (index < currentStep) {
    return <FilledStep />;
  }
  if (index === currentStep) {
    return <OnStep />;
  }
  return <NextStep />;
};

const getLineComponent = (index: number, currentStep: number) => {
  return index < currentStep ? <LineFilled /> : <LineFiller />;
};

const ProgressStepper = ({ totalSteps, currentStep }: Props) => {
  const steps = [];
  for (let i = 1; i <= totalSteps; i += 1) {
    steps.push(
      <div key={`step-${i}`} className="flex items-center">
        {getStepComponent(i, currentStep)}
        {i < totalSteps && getLineComponent(i, currentStep)}
      </div>
    );
  }

  return <div className="flex items-center">{steps}</div>;
};

export default ProgressStepper;
