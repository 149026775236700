import styled, { createGlobalStyle } from 'styled-components';

import { Styled as StyledButton } from '../../components/ui/Button/styled';
import { colors } from '../../lib/colors';

export const ColumnCount = 3;

export const Styled = {
  Container: styled.div`
    background: ${colors.black[1]};
    border-radius: 0.5rem;
    color: ${colors.white[1]};
    display: grid;
    box-shadow: 0px 24px 156px rgba(123, 137, 167, 0.08), 0px 10.0266px 65.1731px rgba(123, 137, 167, 0.0575083),
      0px 5.36071px 34.8446px rgba(123, 137, 167, 0.0476886), 0px 3.00517px 19.5336px rgba(123, 137, 167, 0.04),
      0px 1.59602px 10.3742px rgba(123, 137, 167, 0.0323114), 0px 0.664142px 4.31692px rgba(123, 137, 167, 0.0224916);
    flex-wrap: wrap;
    gap: 0.5rem;
    grid-template-columns: repeat(${ColumnCount}, 1fr);
    margin-bottom: 2rem;
    padding: 0.5rem;

    ${StyledButton.Button} {
      white-space: nowrap;

      svg path,
      &.is-active svg path {
        fill: currentColor !important;
      }
    }

    ${StyledButton.Text} {
      color: ${colors.white[1]};
    }
  `,
  GroupTitle: styled.div`
    color: ${colors.white[3]};
    font-size: 0.75rem;
    grid-column: 1 / -1;
    margin: 0.125rem 0.5rem;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  `,
};

export const GlobalStyles = createGlobalStyle`
  [data-theme="slash-command"] {
    width: 1000vw;
  }
`;
