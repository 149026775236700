import { BoostOffer, VerificationMode } from '@/interfaces/boosts/grow/boost_offer';
import currencyFormatter from '@/pages/Grow/Boosts/utils/currencyFormatter';

const useOfferStatus = (offer: BoostOffer) => {
  const maxSpend = currencyFormatter.format(offer.max_spend_cents / 100);
  const cost = currencyFormatter.format(offer.cost_per_lead_cents / 100);
  const status = offer.status.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()); // Capitalized

  const maxSpendText =
    offer.max_spend_cents === 0
      ? 'This offer has not enabled sending'
      : `This offer pays a maximum of ${maxSpend} per send`;

  const verificationDaysText = offer.verification_mode === VerificationMode.STANDARD ? '10 - 17 days' : '48 hours';

  return {
    maxSpend,
    hasMaxSpend: offer.max_spend_cents > 0,
    maxSpendText,
    cost,
    costText: `This offer pays ${cost} per subscriber`,
    status,
    verificationDaysText,
  };
};

export default useOfferStatus;
