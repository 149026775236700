import { useCallback } from 'react';

import { PopoverHelper, PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';

type Props = AttributeSettingProps & {
  title: string;
  property: string;
  disabled?: boolean;
};

const PLACEMENT_OPTIONS = [
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Right',
    value: 'right',
  },
];

export const IconPlacementSettings = ({ editor, activeNodeResult, title, property, disabled }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const textValue =
    PLACEMENT_OPTIONS.find((option) => option.value === activeNodeAttributes?.[property])?.label || 'left';

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [editor, activeNodePos, activeNodeResult, property]
  );

  return (
    <PopoverHelper title={title} text={textValue} popoverTitle={title} disabled={disabled}>
      <div className="flex flex-col gap-2">
        {PLACEMENT_OPTIONS.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={false}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};
