import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  onSuccess?: () => void;
}
interface CreateSiteVersionPayload {
  version_type: 'autosave' | 'usersave';
  version_name?: string;
}

const useCreateSiteVersion = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (payload: CreateSiteVersionPayload) =>
      api.post(`/site_versions`, {
        publication_id: currentPublicationId,
        version_type: payload.version_type,
        version_name: payload.version_name,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['publications', currentPublicationId, 'sites', 'null']);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'site_versions']);
      },
    }
  );
};

export default useCreateSiteVersion;
