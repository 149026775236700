interface Props {
  className?: string;
}

const BinaryBranch = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 13.5H15L15 10.5C15 8.567 13.433 7 11.5 7C10.6716 7 10 6.32843 10 5.5V2C10 1.44771 9.55228 1 9 1C8.44772 1 8 1.44771 8 2V5.5C8 6.32843 7.32843 7 6.5 7C4.567 7 3 8.567 3 10.5L3 13.5H2.50003C2.09557 13.5 1.73093 13.7436 1.57615 14.1173C1.42137 14.491 1.50692 14.9211 1.79292 15.2071L3.29292 16.7071C3.68345 17.0976 4.31661 17.0976 4.70714 16.7071L6.20714 15.2071C6.49313 14.9211 6.57869 14.491 6.42391 14.1173C6.26913 13.7436 5.90449 13.5 5.50003 13.5H5L5 10.5C5 9.67157 5.67157 9 6.5 9C7.47934 9 8.36474 8.59777 9 7.94949C9.63526 8.59777 10.5207 9 11.5 9C12.3284 9 13 9.67157 13 10.5L13 13.5H12.5C12.0956 13.5 11.7309 13.7436 11.5761 14.1173C11.4214 14.491 11.5069 14.9211 11.7929 15.2071L13.2929 16.7071C13.6834 17.0976 14.3166 17.0976 14.7071 16.7071L16.2071 15.2071C16.4931 14.9211 16.5787 14.491 16.4239 14.1173C16.2691 13.7436 15.9045 13.5 15.5 13.5Z"
      fill="currentColor"
    />
  </svg>
);

export default BinaryBranch;
