import { useCallback, useMemo, useState } from 'react';
import { ArrowLeft, X } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

import useTags from '@/hooks/useDreamBuilder/useTags';

import { cn } from '../../../../../_utils/cn';
import { Button } from '../../../../UI/Button';
import { Checkbox } from '../../../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Label } from '../../../../UI/Label';
import { Switch } from '../../../../UI/Switch';
import { Text } from '../../../../UI/Text';
import { TAGS } from '../../../dummyData/tags';

const TagsSelectionModal = ({
  editor,
  node,
  isOpen,
  onClose,
  pos,
}: {
  editor: Editor;
  node: ProseMirrorNode;
  pos: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [selectedTags, setSelectedTags] = useState<{ id: string; display: string }[]>([]);
  const [isLayoutOpen, setIsLayoutOpen] = useState(false);
  const [showFakeData, setShowFakeData] = useState(false);

  const isSubmitDisabled = selectedTags.length === 0;
  const selectionCount = selectedTags.length;
  const selectionLabel = selectionCount <= 1 ? 'tag' : 'tags';

  const { data, isFetching, hasNextPage, fetchNextPage } = useTags({ enabled: isOpen });
  const tags = showFakeData ? TAGS : data?.pages.flatMap((page) => page.tags) || [];

  const selectedTagsIds = useMemo(() => selectedTags.map((tag) => tag.id), [selectedTags]);

  const handleSubmit = useCallback(() => {
    const updatedAttributes = {
      ...node?.attrs,
      insertedFromSidebar: false,
      data: selectedTags,
    };

    editor?.commands.command(({ tr }) => {
      tr.setNodeAttribute(pos, 'insertedFromSidebar', false);
      tr.setNodeAttribute(pos, 'hasFakeData', false);
      tr.setNodeAttribute(pos, 'data', updatedAttributes.data);
      return true;
    });

    onClose();
  }, [selectedTags, editor, node, pos, onClose]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              {isLayoutOpen && <Button variant="ghost" onClick={() => setIsLayoutOpen(false)} LeftIcon={ArrowLeft} />}
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                Select tags
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-2">
            {selectedTags.map((tag) => (
              <button
                type="button"
                key={tag.id}
                className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1"
                onClick={() => setSelectedTags(selectedTags.filter((p) => p.id !== tag.id))}
              >
                <Text
                  weight="regular"
                  variant="accent"
                  size="3xs"
                  as="span"
                  className="line-clamp-1 max-w-[150px] truncate"
                >
                  {tag.display}
                </Text>
                <X className="w-3 h-3" />
              </button>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full overflow-y-auto no-scrollbar">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-32">
              {tags.map((tag) => {
                const isSelected = selectedTagsIds.includes(tag.id);

                return (
                  <Label
                    key={tag.id}
                    htmlFor={tag.id}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md focus:outline-none relative overflow-hidden',
                      isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    <div className="w-full h-36 overflow-hidden flex items-center justify-center">
                      <Text
                        weight="regular"
                        variant="secondary"
                        size="2xs"
                        as="span"
                        className="line-clamp-3 max-w-[200px]"
                      >
                        {tag.display}
                      </Text>
                    </div>
                    <div className="flex justify-between items-center gap-2 p-3">
                      <div className="flex gap-2 items-center">
                        <div className="flex flex-col">
                          <Text weight="semibold" variant="primary" size="xs" as="span" className="line-clamp-1">
                            {tag.display}
                          </Text>
                        </div>
                      </div>
                      <Checkbox
                        id={tag.id}
                        className={cn(isSelected ? 'opacity-100' : 'opacity-0')}
                        checked={Boolean(isSelected)}
                        onCheckedChange={() => {
                          if (isSelected) {
                            setSelectedTags(selectedTags.filter((p) => p.id !== tag.id));
                          } else {
                            setSelectedTags([...selectedTags, tag]);
                          }
                        }}
                      />
                    </div>
                  </Label>
                );
              })}
              {hasNextPage && (
                <Button variant="outlined" onClick={() => fetchNextPage()} className="whitespace-nowrap" size="sm">
                  {isFetching ? 'Loading...' : 'Load more'}
                </Button>
              )}
            </div>
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
          <div className="flex justify-between items-center w-full">
            <div className="flex gap-2 items-center">
              <Switch
                id="show-fake-data"
                checked={showFakeData}
                onCheckedChange={() => setShowFakeData(!showFakeData)}
                labelText="Dummy data"
                labelClassName="whitespace-nowrap"
              />
            </div>
            <div className="flex gap-2 w-full justify-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit} isDisabled={isSubmitDisabled}>
                Select {selectionLabel}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TagsSelectionModal;
