import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@/context/current-user-context';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { PriceInterval } from '@/interfaces/tier';
import analytics from '@/utils/analytics';

import TierForm from '../../_components/TierForm';
import { useTierConfigurationContext } from '../../_context/tier_configuration_context';
import { useCreateTier } from '../../_hooks';

const TierConfigurationNew = () => {
  const {
    tierName,
    previewText,
    monthlyEnabled,
    monthlyIntervalDisplay,
    monthlyCents,
    monthlyCta,
    monthlyBenefits,
    annualEnabled,
    annualIntervalDisplay,
    annualCents,
    annualCta,
    annualBenefits,
    oneTimeEnabled,
    oneTimeIntervalDisplay,
    oneTimeCents,
    oneTimeCta,
    oneTimeBenefits,
    donationEnabled,
    donationIntervalDisplay,
    donationCta,
    donationBenefits,
    giftingEnabled,
    currency,
    isSaving,
    setIsSaving,
    setErrors,
  } = useTierConfigurationContext();
  const navigate = useNavigate();
  const publicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(publicationId);
  const isTierDisabled = !monthlyEnabled && !annualEnabled && !oneTimeEnabled;
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;

  const { mutate } = useCreateTier({
    onSuccess: () => {
      setIsSaving(false);
      if (hasSettingsV2) {
        navigate(`/monetize/subscriptions/paid_tiers?tiers=${isTierDisabled ? 'archived' : 'active'}`);
      } else {
        navigate(`/settings/publication/premium?tiers=${isTierDisabled ? 'archived' : 'active'}`);
      }
    },
    onError: (errors) => {
      setErrors(errors);
      setIsSaving(false);
    },
  });

  const onSave = () => {
    if (isSaving) {
      return;
    }

    analytics.track('Set a currency');

    if (monthlyCents && monthlyEnabled) analytics.track('set monthly plan price');

    if (annualCents && annualEnabled) analytics.track('set annual plan price');

    if (oneTimeCents && oneTimeEnabled) analytics.track('set one-time plan price');

    setIsSaving(true);

    mutate({
      name: tierName,
      description: previewText,
      gifting_enabled: giftingEnabled,
      prices_attributes: [
        {
          interval_display: monthlyIntervalDisplay,
          amount_cents: monthlyCents,
          cta: monthlyCta,
          enabled: monthlyEnabled && !donationEnabled,
          currency,
          interval: PriceInterval.MONTH,
          features: monthlyBenefits,
        },
        {
          interval_display: annualIntervalDisplay,
          amount_cents: annualCents,
          cta: annualCta,
          enabled: annualEnabled && !donationEnabled,
          currency,
          interval: PriceInterval.YEAR,
          features: annualBenefits,
        },
        ...(settings?.one_time_payments
          ? [
              {
                interval_display: oneTimeIntervalDisplay,
                amount_cents: oneTimeCents,
                cta: oneTimeCta,
                enabled: oneTimeEnabled && !donationEnabled,
                currency,
                interval: PriceInterval.ONE_TIME,
                features: oneTimeBenefits,
              },
            ]
          : []),
        ...(settings?.donation_payments
          ? [
              {
                interval_display: donationIntervalDisplay,
                amount_cents: 0,
                cta: donationCta,
                enabled: donationEnabled,
                currency,
                interval: PriceInterval.DONATION,
                features: donationBenefits,
              },
            ]
          : []),
      ],
    });
  };

  return <TierForm isSaving={isSaving} onSave={onSave} />;
};

export default TierConfigurationNew;
