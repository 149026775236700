import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowSmallLeftIcon, TrashIcon } from '@heroicons/react/20/solid';

import { AutomationConditions } from '@/interfaces/automations/automation_condition';
import { AutomationTriggerEvent } from '@/interfaces/automations/automation_trigger';
import { Button } from '@/ui/Button';

import ConditionsForm from '../components/ConditionsModal/ConditionsForm';
import { isConditionEmpty } from '../components/ConditionsModal/isConditionEmpty';

interface Props {
  mainConditions?: AutomationConditions;
  conditions: AutomationConditions;
  triggerEvent?: AutomationTriggerEvent;
  onChange: (conditions?: AutomationConditions) => void;
}

const ConfigureConditions = ({ conditions, mainConditions, triggerEvent, onChange }: Props) => {
  const navigate = useNavigate();
  const [conditionsWithErrors, setConditionsWithErrors] = useState({});

  const handleCloseConditionsForm = () => {
    let hasErrors = false;
    conditions?.conditions?.forEach((condition, index) => {
      const isEmpty = isConditionEmpty(condition);
      setConditionsWithErrors({
        ...conditionsWithErrors,
        [index]: isEmpty,
      });

      hasErrors = hasErrors === false ? isEmpty : hasErrors;
    });

    if (hasErrors) {
      return;
    }

    navigate(-1);
  };

  const handleDeleteAllConditions = () => {
    onChange();
    setTimeout(() => navigate(-1), 1);
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between mb-2">
        <Button size="sm" variant="flush" onClick={handleCloseConditionsForm} Icon={ArrowSmallLeftIcon}>
          Back
        </Button>
        <Button size="sm" variant="flush" onClick={handleDeleteAllConditions} Icon={TrashIcon} iconRight>
          Delete All Conditions
        </Button>
      </div>
      <ConditionsForm
        mainConditions={mainConditions}
        conditions={conditions || {}}
        triggerEvent={triggerEvent}
        onChange={onChange}
        conditionsWithErrors={conditionsWithErrors}
      />
    </div>
  );
};

export default ConfigureConditions;
