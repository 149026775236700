import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Order, Pagination } from '../interfaces/general';
import { DeviceType, GroupBy, PostClick } from '../interfaces/post';
import api from '../services/swarm';

interface ApiResponse {
  clicks: PostClick[];
  pagination: Pagination;
}

interface Arguments {
  postId: string;
  useApiary?: boolean;
  platform?: 'web' | 'email';
  direction?: Order;
  deviceType?: DeviceType;
  allResults?: boolean;
  groupBy?: GroupBy;
  staleTime?: number | undefined;
  enabled?: boolean;
}

export default function usePostClicks({ postId, platform, useApiary = false, direction, deviceType, allResults = false, groupBy, staleTime = 30000, enabled = true }: Arguments) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPostClicks = ({ pageParam = 1 }) =>
    api
      .get(`/posts/${postId}/clicks`, {
        params: {
          page: pageParam,
          per_page: 15,
          all_results: allResults,
          publication_id: currentPublicationId,
          use_apiary: useApiary === true ? '1' : undefined,
          platform,
          direction,
          device_type: deviceType,
          group_by: groupBy,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([postId, 'posts', 'clicks', platform, direction, deviceType, allResults ? 'all_results' : null, groupBy], fetchPostClicks, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    enabled: enabled && Boolean(postId),
    staleTime,
    keepPreviousData: true,
  });
}
