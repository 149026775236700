import { ChartContext, ChartGradientColors } from './styledChartData.types';

function createGradient({ chart, colors }: { chart: ChartContext; colors: ChartGradientColors }) {
  const { ctx, area } = chart;
  const { start: colorStart, mid: colorMid, end: colorEnd } = colors;

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.8, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

export default createGradient;
