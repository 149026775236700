import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { LandingPage } from '../../interfaces/landing_page';
import api from '../../services/swarm';

interface Props {
  slug: string;
  status: string;
}

const useLandingPage = ({ slug, status }: Props) => {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<LandingPage>(
    ['landing_page', slug, status],
    () =>
      api
        .get<any>(`/landing_pages/pageId`, {
          params: {
            slug,
            status,
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!slug && !!status && !!publicationId,
    }
  );
};

export default useLandingPage;
