import { useQuery } from 'react-query';

import api from '@/services/swarm';

interface ApiResponse {
  type: string;
}

const usePlanType = ({ organizationId }: { organizationId: string | undefined }) => {
  return useQuery<ApiResponse>(
    ['organization', organizationId, 'plan_type'],
    () =>
      api
        .get(`/organizations/${organizationId}/plan_type`)
        .then((res) => res.data),
    {
      enabled: Boolean(organizationId),
    }
  );
};

export default usePlanType;
