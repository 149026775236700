import { forwardRef, useCallback, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/core';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel } from '@/components/TiptapEditor/components/ui/Panel';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';

export interface OptionsPanelProps {
  parentRef?: React.RefObject<HTMLDivElement>;
}

type PanelContentProps = {
  opportunityId: string;
  editor: Editor;
};

const PanelContent = forwardRef<HTMLDivElement, PanelContentProps>(({ opportunityId, editor }, ref) => {
  const insertImage = useCallback(() => {
    editor.commands.insertContentAt(0, {
      type: 'advertisementOpportunityLogo',
      attrs: {
        id: opportunityId,
      },
    });
  }, [editor, opportunityId]);

  return (
    <Panel ref={ref}>
      <Button $size="small" $variant="quaternary" $fullWidth $leftSlot={<Icon name="Upload" />} onClick={insertImage}>
        Insert manually placed logo
      </Button>
    </Panel>
  );
});

export const OptionsPanel = ({ parentRef, editor }: { parentRef: React.RefObject<HTMLElement>; editor: Editor }) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { id: opportunityId } = editor.getAttributes('advertisementOpportunity');

  const togglePanel = useCallback(() => {
    setPanelOpen(!panelOpen);
  }, [panelOpen]);

  return (
    <Tippy
      render={() => panelOpen && <PanelContent opportunityId={opportunityId} editor={editor} ref={panelRef} />}
      reference={parentRef?.current}
      visible={panelOpen}
      offset={[0, 4]}
      placement="bottom-start"
      interactive
    >
      <div>
        <Tooltip title="Settings">
          <Button
            ref={buttonRef}
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="Settings" />}
            onClick={togglePanel}
            $active={panelOpen}
            $muted={panelOpen}
            $isIconButton
          />
        </Tooltip>
      </div>
    </Tippy>
  );
};

export default OptionsPanel;
