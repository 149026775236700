import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';

import PageContainer from '../../_components/PageContainer';
import { usePublicationSettings } from '../context';

import ExternalRssFeeds from './ExternalRssFeeds';
import ExternalRssFeedsTeaser from './ExternalRssFeedsTeaser';
import Feeds from './Feeds';

const PublicationRSSSendFeedSettings: React.FC = () => {
  const { currentPublicationId, settings } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);

  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId}>
          <Feeds publicationId={currentPublicationId} />
          {settings?.rss_to_send ? (
            <ExternalRssFeeds publicationId={currentPublicationId} />
          ) : (
            <ExternalRssFeedsTeaser />
          )}
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default PublicationRSSSendFeedSettings;
