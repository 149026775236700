import { Input, RadioSelect } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { Card } from '@/ui/Card';

import BodyContainer from '../../../../_components/BodyContainer';
import CardHeader from '../../../../_components/CardHeader';
import getRootDomain from '../../getRootDomain';

import CodeBlock from './CodeBlock';
import { DEFAULT_EMAIL_DOMAIN } from './constants';
import CustomOptionNode from './CustomOptionNode';
import { FormErrors } from './types';

interface Props {
  errors: FormErrors;
  webDomain: string;
  shouldUseCustomEmailDomain: boolean;
  setShouldUseCustomEmailDomain: (value: boolean) => void;
  emailDomain: string | null;
  setEmailDomain: (value: string | null) => void;
  shouldUseWebDomainAsEmailDomain: boolean;
  setShouldUseWebDomainAsEmailDomain: (value: boolean) => void;
  shouldUseDefaultEmailDomain: boolean;
  setShouldUseDefaultEmailDomain: (value: boolean) => void;
  shouldUseDefaultWebDomain: boolean;
}

const EmailForm = ({
  errors,
  webDomain,
  shouldUseCustomEmailDomain,
  setShouldUseCustomEmailDomain,
  emailDomain,
  setEmailDomain,
  shouldUseWebDomainAsEmailDomain,
  setShouldUseWebDomainAsEmailDomain,
  shouldUseDefaultEmailDomain,
  setShouldUseDefaultEmailDomain,
  shouldUseDefaultWebDomain,
}: Props) => {
  const radioValue = (() => {
    if (shouldUseCustomEmailDomain) {
      return 'should_use_custom_domain';
    }

    if (shouldUseWebDomainAsEmailDomain) {
      return 'should_use_web_domain_as_email_domain';
    }

    if (shouldUseDefaultEmailDomain) {
      return 'use_default_email_domain';
    }

    return 'should_use_custom_domain';
  })();

  return (
    <Card>
      <BodyContainer>
        <CardHeader
          title="Email Domain"
          description="If you are migrating to beehiiv and have been sending emails from a custom domain on your previous platform, we strongly recommend that you use that domain or subdomain. This ensures you will maintain domain reputation that you have built and minimize chance of deliverability issues."
        />
        <RadioSelect
          // eslint-disable-next-line no-nested-ternary
          value={radioValue}
          CustomOptionNode={CustomOptionNode}
          options={[
            {
              name: <Typography token="font-medium/text/sm">Enable a new domain or subdomain</Typography>,
              value: 'should_use_custom_domain',
              children: (
                <Input
                  labelText="Domain"
                  name="email_domain"
                  defaultValue={emailDomain || ''}
                  placeholder={`hi@mail.${getRootDomain(webDomain) || 'yourdomain.com'}`}
                  onBlur={(e) => setEmailDomain(e.target.value)}
                  errorText={errors.emailDomain ? 'Please enter a valid domain' : undefined}
                />
              ),
              customProps: {
                isRecommended: true,
                tooltip:
                  'If you use your domain for regular email, like on Google Workspace or Microsoft Outlook, we highly recommend you use a subdomain for health deliverability.',
              },
            },
            {
              name: (
                <Typography token="font-medium/text/sm">
                  Enable the same domain <CodeBlock>{webDomain}</CodeBlock>
                </Typography>
              ),
              value: 'should_use_web_domain_as_email_domain',
              isVisible: !shouldUseDefaultWebDomain,
            },
            {
              name: (
                <Typography token="font-medium/text/sm">
                  Use free beehiiv subdomain <CodeBlock>{DEFAULT_EMAIL_DOMAIN}</CodeBlock>
                </Typography>
              ),
              value: 'use_default_email_domain',
            },
          ]}
          onSelect={(value: string) => {
            if (value === 'should_use_custom_domain') {
              setShouldUseCustomEmailDomain(true);
              setShouldUseWebDomainAsEmailDomain(false);
              setShouldUseDefaultEmailDomain(false);
            } else if (value === 'should_use_web_domain_as_email_domain') {
              setShouldUseCustomEmailDomain(false);
              setShouldUseWebDomainAsEmailDomain(true);
              setShouldUseDefaultEmailDomain(false);
              setEmailDomain(null);
            } else if (value === 'use_default_email_domain') {
              setShouldUseCustomEmailDomain(false);
              setShouldUseWebDomainAsEmailDomain(false);
              setShouldUseDefaultEmailDomain(true);
              setEmailDomain(null);
            }
          }}
        />
      </BodyContainer>
    </Card>
  );
};

export default EmailForm;
