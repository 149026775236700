import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { useCreatePostTarget, useDeletePostTarget } from '@/hooks/usePostTargets';
import { PostTarget, PostTargetAction, PostTargetPlatform, PostTargetTier } from '@/interfaces/post_target';
import { TierData } from '@/interfaces/tier';
import pluralize from '@/utils/pluralize';

import Checkbox from '../Checkbox';
import { useDeliveryContext } from '../context/DeliveryContext';

interface ManagedAudienceProps {
  postSendTargets: PostTarget[];
  tier: TierData;
  platform: PostTargetPlatform;
  readOnly: boolean;
  disabled?: boolean;
}

const ManagedAudienceOption: React.FC<ManagedAudienceProps> = ({
  postSendTargets,
  tier,
  platform,
  readOnly,
  disabled,
}) => {
  const { name: tierName, id: tierId } = tier;
  const { postId } = useParams<'postId'>() as { postId: string };
  const { data: postDeliveryData, isLoading } = usePostDeliveryData(postId);
  const { publication_subscriptions: SubscriptionsCount } = postDeliveryData || {};
  const helperText = SubscriptionsCount && pluralize('subscriber', SubscriptionsCount[tierId]);
  const { setIsChanging } = useDeliveryContext();

  const [currentPublicationId] = useCurrentPublicationState();

  const postSendTarget = useMemo(
    () =>
      postSendTargets.find(
        (target) =>
          (target.receiver_type === 'Publication' && target.tier_id === tierId) ||
          (target.receiver_type === 'Publication' &&
            (tierId === PostTargetTier.FREE || tierId === PostTargetTier.PREMIUM) &&
            target.tier === tierId &&
            target.tier_id === tierId)
      ),
    [postSendTargets, tierId]
  );

  const deleteMutation = useDeletePostTarget(postId);
  const createMutation = useCreatePostTarget(postId, platform);
  const isMutating = deleteMutation.isLoading || createMutation.isLoading;

  // Considering whether the mutations are loading allows us to have optimistic UI while the mutation happens
  const isIncluded = () => {
    if (disabled) return true;
    if (!postSendTarget) return createMutation.isLoading;

    return postSendTarget.action === PostTargetAction.INCLUDE;
  };

  // Keeping support for displaying the minus checkbox if a managed audience option is excluded since we rolled the feature
  // out with this ability. They can only toggle between including or deleting the managed audience targets now though.
  const isExcluded = () => {
    if (!postSendTarget || deleteMutation.isLoading) return false;
    return postSendTarget.action === PostTargetAction.EXCLUDE;
  };

  const handleToggle = () => {
    if (postSendTarget) {
      setIsChanging(true);
      deleteMutation.mutateAsync(postSendTarget.id).finally(() => setIsChanging(false));
    }

    if (!postSendTarget) {
      setIsChanging(true);
      createMutation
        .mutateAsync({
          params: {
            action: PostTargetAction.INCLUDE,
            receiver_id: currentPublicationId,
            receiver_type: 'Publication',
            tier_id: tierId,
          },
        })
        .finally(() => setIsChanging(false));
    }
  };

  return (
    <Checkbox
      key={tierId}
      helperText={helperText}
      labelText={`All ${tierName} subscribers`}
      isChecked={isIncluded()}
      isMinus={isExcluded()}
      name={`${tierId}_audience`}
      onChange={handleToggle}
      isMutating={isMutating}
      debounce={800}
      placeholder={isLoading}
      readOnly={readOnly || disabled}
    />
  );
};

export default ManagedAudienceOption;
