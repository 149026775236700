import useTags from '@/hooks/useTags';
import { Tag } from '@/interfaces/tag';

interface Props {
  query: string;
  onChangeQuery: (value: string) => void;
}

const TagSelect = ({ query, onChangeQuery }: Props) => {
  const { data: tagData } = useTags();

  const tagsData = tagData?.tags;

  if (!tagsData || !query.match(/#\S*$/)) return null;
  const t = query.match(/#\S*$/)?.[0].replace('#', '');

  if (typeof t === 'undefined') return null;
  const tags = t ? tagsData?.filter((tag) => tag.name.toLowerCase().includes(t.toLowerCase())) : tagsData;

  const autoComplete = (completed: string) => {
    onChangeQuery(query.replace(/#\S*$/, `#${completed} `));
  };

  if (tags?.length === 1 && tags[0].name.toLowerCase() === t.toLowerCase()) return null;

  return (
    <ul className="bg-white shadow-xl absolute max-h-48 w-48 z-10 overflow-y-scroll rounded border left-6 top-10 py-2">
      {tags.map((tag: Tag) => (
        <li key={tag.id} className="hover:bg-gray-50" data-tag-selectable="true">
          <div>
            <button
              type="button"
              className="cursor-pointer p-2 w-full text-left text-sm text-gray-700 hover:text-gray-900"
              onClick={() => autoComplete(tag.name)}
            >
              #{tag.name}
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default TagSelect;
