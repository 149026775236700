import { useState } from 'react';
import { SparklesIcon } from '@heroicons/react/20/solid';

import Grid from '@/components/Grid';
import { Typography, TypographyRow } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { useCurrentPublication } from '@/hooks';
import PlanPriceChangeModal from '@/routes/settings/billing/_components/PlanPriceChangeModal';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import useUpdateOnboarding from '../../_hooks/useUpdateOnboarding';

interface UpgradeOptionProps {
  planName: string;
  upgradeOptions: string[];
}

const UpgradeOption: React.FC<UpgradeOptionProps> = ({ planName, upgradeOptions }) => {
  const updateOnboarding = useUpdateOnboarding();
  const { currentUser } = useCurrentUser();
  const { data: currentPublication } = useCurrentPublication();
  const [modalOpen, setModalOpen] = useState(false);

  const beforeUpgrade = () =>
    updateOnboarding.mutateAsync({ status: 'complete' }).then(() => {
      if (currentUser?.id) {
        analytics.identify(currentUser?.id, {
          email: currentUser?.email,
          currentPlan: 'launch',
        });
      }

      analytics.track('Completed Onboarding', { email: currentUser?.email });
      analytics.track('Clicked Upgrade', { email: currentUser?.email });
    });

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setModalOpen(true);
  };

  return (
    <>
      {currentPublication && (
        <PlanPriceChangeModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          publicationId={currentPublication?.id}
          organizationId={currentPublication?.organization_id}
          beforeConfirm={beforeUpgrade}
        />
      )}
      <div className="w-full">
        <div className="border-surface-200 rounded border">
          <TypographyRow className="bg-surface-50 rounded-t py-4 px-6 border-b border-surface-200 justify-between">
            <Typography token="font-semibold/text/base">Upgrade and unlock all features</Typography>
            <div className="p-4 bg-action-tertiary-100 rounded">
              <SparklesIcon className="text-action-tertiary-500 w-6 h-6" />
            </div>
          </TypographyRow>
          <Grid gap={3} columns={2} rows={2} className="px-6 py-4 auto-rows-fr items-center">
            {upgradeOptions.map((option) => (
              <TypographyRow key={option}>
                <Typography token="font-normal/text/sm" colorWeight="700">
                  <span className="text-action-tertiary-600">&#10003;</span> {option}
                </Typography>
              </TypographyRow>
            ))}
            <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
              <div className="flex flex-row gap-1">
                <span className="text-action-tertiary-600">&#10003;</span>
                <span>Sending limits lifted based on tier</span>
              </div>
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="700" as="div" className="text-left">
              <span className="text-action-tertiary-600">&#10003;</span> All other {planName} features
            </Typography>
          </Grid>
          <TypographyRow className="bg-surface-50 rounded-t py-4 px-6 border-b border-surface-200 justify-end">
            <Button onClick={onClick} variant="primary" size="sm">
              Upgrade
            </Button>
          </TypographyRow>
        </div>
      </div>
    </>
  );
};

export default UpgradeOption;
