import { useQuery } from 'react-query';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import api from '@/services/swarm';

interface ApiResponse {
  team_count: number;
  team_max: number;
  team_emails: string[];
}

const useFetchTeamCount = (organizationId: string) => {
  const publicationId = useCurrentPublicationId();
  return useQuery<ApiResponse>(
    [organizationId, 'team_count'],
    () =>
      api
        .get(`/organizations/${organizationId}/team_counts`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
};

export default useFetchTeamCount;
