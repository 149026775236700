import { ElementType, Fragment, ReactNode, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import useWebBuilderOverlay from '@/hooks/useWebBuilderOverlay';

import { Option } from '../../_types/options';
import { usePageContext } from '../Context/PageContext';

import { InputWrapperProps } from './InputWrapper';

interface Props extends Omit<InputWrapperProps, 'children'> {
  className?: string;
  options: Option[];
  value: string;
  onSave: (value: string) => void;
  noOptionsText: string;
  searchPlaceholder?: string;
  onClose?: () => void;
  Footer?: ElementType;
  FooterModal?: ElementType;
  prefix?: ReactNode | string;
}

const SelectWithSearch = ({
  label,
  options = [],
  className,
  onSave,
  noOptionsText,
  value,
  searchPlaceholder,
  Footer,
  FooterModal,
  prefix,
}: Props) => {
  const { handleSaved } = usePageContext();
  const initialValue = options.find((option) => option.value === value);
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const [search, setSearch] = useState<string>('');
  const { styles, attributes, setReferenceElement, setPopperElement } = useWebBuilderOverlay({
    align: 'end',
  });

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === value));
    // eslint-disable-next-line
  }, [value]);

  const filteredOptions = options.filter((option) => {
    if (!search) {
      return true;
    }
    return (
      option.value.toLowerCase().includes(search.toLowerCase()) ||
      option.label.toLowerCase().includes(search.toLowerCase())
    );
  });

  async function handleSave(selectionValue: string) {
    onSave(selectionValue);
  }

  return (
    <div className={cx(className, 'flex justify-between items-center')}>
      <Typography token="font-normal/text/xs" colorWeight="500">
        {label}
      </Typography>
      <div className="flex items-center space-x-1">
        {prefix}
        <div className="rounded-md bg-surface-100 border border-gray-200 w-28">
          <Listbox
            value={selectedOption}
            onChange={(selection) => {
              setSelectedOption(selection);
              handleSave(selection.value).then(() => {
                handleSaved();
              });
            }}
          >
            <div className="relative w-full">
              <Listbox.Button
                ref={setReferenceElement}
                className="w-full flex space-x-2 justify-between items-center px-2 py-1 relative"
              >
                <Typography token="font-normal/text/xs" className="truncate whitespace-nowrap w-full text-left">
                  {selectedOption?.label}
                </Typography>
                <div>
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="bg-white shadow-md rounded-sm z-50 border border-surface-200 absolute left-0 w-[260px] mt-1 h-60 overflow-auto"
                >
                  <div className="flex flex-row py-2 px-4 mt-1 space-x-2 border border-surface-100 w-full">
                    <MagnifyingGlassIcon width={12} height={12} className="my-auto" />
                    <input
                      className="font-normal w-full p-0 border-none outline-none focus:ring-0 text-xs text-400"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder={searchPlaceholder}
                      type="text"
                    />
                  </div>
                  <div className="mt-1 flex flex-col">
                    {filteredOptions.length > 0 ? (
                      <>
                        {filteredOptions.map((option) => (
                          <Listbox.Option
                            key={option.label}
                            value={option}
                            className={cx(
                              'px-4 py-1 hover:bg-surface-100 transition-all cursor-pointer',
                              selectedOption?.value === option.value && 'bg-surface-100'
                            )}
                          >
                            <div className="flex flex-row w-full justify-between items-between">
                              <span style={{ fontFamily: option.value }} className="text-900 font-normal text-sm">
                                {option.label}
                              </span>
                              {selectedOption?.value === option.value && (
                                <CheckIcon width={12} height={12} className="my-auto" />
                              )}
                            </div>
                          </Listbox.Option>
                        ))}
                      </>
                    ) : (
                      <Typography as="span" className="px-8 pt-3" token="font-medium/text/xs" colorWeight="900">
                        {noOptionsText}
                      </Typography>
                    )}
                    {Footer && (
                      <div className="sticky bottom-0">
                        <Footer />
                      </div>
                    )}
                  </div>
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
          {FooterModal && <FooterModal />}
        </div>
      </div>
    </div>
  );
};

export default SelectWithSearch;
