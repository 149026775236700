import { useMemo } from 'react';

import { useWebsiteContext } from '@/context/website-context';
import { useCreatePage } from '@/hooks/usePages';
import { TemplateCategory } from '@/interfaces/dream_builder/site';

import AddablePageHelper from './AddablePageHelper';

const AddableDefaultPages = () => {
  const { site, defaultRoutes } = useWebsiteContext();

  const createPage = useCreatePage({
    routesType: 'default',
  });

  const createPageMutation = (parentPath: string[], slug: string, name: string) => {
    createPage.mutate({ parentPath, slug, name });
  };

  const pageCategories = site?.template_categories?.page;

  const renderablePageCategories = useMemo(() => {
    return pageCategories?.filter((category) => !defaultRoutes?.children_keys?.includes(category?.slug || ''));
  }, [pageCategories, defaultRoutes]);

  return (
    <div className="flex flex-col">
      {renderablePageCategories?.map((category: TemplateCategory) => {
        return (
          <AddablePageHelper
            key={category.value}
            text={category.label}
            tooltipText="Create Default Page"
            onClick={() => createPageMutation([], category?.slug || '', category?.label || 'Untitled')}
          />
        );
      })}
    </div>
  );
};

export default AddableDefaultPages;
