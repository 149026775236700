import { useState } from 'react';
import toast from 'react-hot-toast';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import api from '@/services/swarm';

import useWallet from '../useWallet';

const useFunds = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: wallet } = useWallet() || {};
  const [isProcessingCheckout, setIsProcessingCheckout] = useState(false);
  const { settings } = useSettings();

  const handleCreateSessionRequest = () => {
    if (currentPublicationId) {
      const params = { publication_id: currentPublicationId };
      setIsProcessingCheckout(true);

      api
        .get('/boosts/grow/create_checkout_sessions', { params })
        .then((res) => {
          window.open(`${res.data.url}`, '_blank');
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.message || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setIsProcessingCheckout(false);
        });
    }
  };

  const availableBalanceFormatted = wallet?.available_balance || '$0';
  const escrowBalanceFormatted = wallet?.pending_boost_sends_spend || '$0';

  const addFundsButtonlabel = isProcessingCheckout ? 'One Moment...' : 'Add Funds';

  return {
    canAddFunds: !!settings?.organization_boosts,
    isProcessingCheckout,
    handleClickAddFunds: handleCreateSessionRequest,
    availableBalanceFormatted,
    escrowBalanceFormatted,
    hasBalance: !wallet ? true : !!wallet?.available_balance_cents,
    addFundsButtonlabel,
  };
};

export default useFunds;
