import { DOMSerializer, Node as PMNode, Schema } from '@tiptap/pm/model';

import { AnchorItem, TableOfContentDataItem } from './types';

export const getAnchorLevel = (anchor: AnchorItem, previousAnchors: TableOfContentDataItem[]): number => {
  const isCustomNode = anchor.node.type.name !== 'heading';
  const currentLevel = anchor.node.attrs.level;

  const previousAnchor = previousAnchors.at(-1);
  const previousHeadlines = previousAnchors.filter((a) => !a.isCustom);
  const previousHeadline = previousHeadlines.at(-1);

  const parentHeadline = previousHeadlines.filter((a) => a.originalLevel && a.originalLevel < currentLevel).at(-1);
  const parentLevel = parentHeadline?.level;

  if (isCustomNode) {
    return (previousHeadline?.level || 1) + 1;
  }

  if (previousAnchor?.isCustom) {
    return parentLevel ? parentLevel + 1 : 1;
  }

  if (!previousHeadline) {
    return parentLevel || 1;
  }

  const prevOriginalLevel = previousHeadline?.originalLevel || 1;
  const prevLevel = previousHeadline?.level || 1;

  if (currentLevel > prevOriginalLevel) {
    return prevLevel + 1;
  }

  if (currentLevel < prevOriginalLevel) {
    if (!parentLevel) {
      return 1;
    }

    if (parentLevel < currentLevel) {
      return parentLevel + 1;
    }

    return parentLevel;
  }

  if (currentLevel === prevOriginalLevel) {
    return prevLevel;
  }

  return 1;
};

export const getTextContentWithEmoji = (node: PMNode, schema: Schema) => {
  const documentFragment = DOMSerializer.fromSchema(schema).serializeFragment(node.content);

  const temporaryDocument = document.implementation.createHTMLDocument();
  const container = temporaryDocument.createElement('div');

  container.appendChild(documentFragment);

  return container.innerText;
};
