import { useEffect } from 'react';
import { Edge, Node, useReactFlow } from 'reactflow';
import { HierarchyNode, stratify } from 'd3-hierarchy';

import { Automation } from '@/interfaces/automations/automation';
import { AutomationStep, AutomationStepStepType } from '@/interfaces/automations/automation_step';
import { AutomationTrigger } from '@/interfaces/automations/automation_trigger';

import { AUTOMATION_BRANCH_WIDTH, AUTOMATION_NODE_HEIGHT, AUTOMATION_NODE_WIDTH } from '../constants';
import getNodesAndEdges, {
  REFERRING_AUTOMATIONS_NODE_TYPE,
  ROOT_NODE_ID,
  TRIGGERS_NODE_ID,
} from '../utils/getNodesAndEdges';

// Converts our node/edges structure into a format that D3 can understand for laying out the flow
const getHierarchy = (targetNodes: Node[], edges: Edge[]) =>
  stratify<Node>()
    .id((d: Node) => d.id)
    .parentId((d: Node) => edges.find((e: Edge) => e.target === d.id)?.source)(targetNodes)
    .sort((a: HierarchyNode<Node>, b: HierarchyNode<Node>) => {
      // Make sure the yes/no branches are always sorted to the left/right
      if (a.data?.data?.branchArm === 'yes') return -1;
      if (b.data?.data?.branchArm === 'yes') return 1;
      return 0;
    });

export const getNodeDimensions = (nodeId: string) => {
  const nodeInDom = document.querySelector(`[data-id="${nodeId}"]`);
  const dimensions = nodeInDom?.getBoundingClientRect() || {
    width: AUTOMATION_NODE_WIDTH,
    height: AUTOMATION_NODE_HEIGHT,
  };
  return dimensions;
};

//                                        root (hidden)                     |
//                               triggers ---- | ---- referring automations |
//                                   |                                      |
//                               step node                                  |
//                                   |                                      |
//                               step node                                  |
//                                   |                                      |
//                            branch step node                              |
//                  yes ------------ | ----------------- no                 |
//                   |               |                    |                 |
//                   |               |                    |                 |
//        branch step node --------  | ---------- branch step node          |
//                |                                       |                 |
//                |                                       |                 |
//                |                                       |                 |
//  yes --------  | ---------- no           yes --------  | ---------- no   |
//                              ...                                       __|
export const getLaidOutElements = (nodes: Node[], edges: Edge[], zoomLevel = 1) => {
  if (nodes.length === 0) {
    return {
      nodes,
      edges,
    };
  }

  const hierarchy = getHierarchy(nodes, edges);
  const rootHeight = getNodeDimensions(ROOT_NODE_ID).height;
  const triggersHeight = getNodeDimensions(TRIGGERS_NODE_ID).height;

  const newNodes = hierarchy
    .descendants()
    .map((d) => {
      const node = nodes.find(({ id }) => id === d.id);
      const isFirstStepNode = d.data?.id !== ROOT_NODE_ID && !d.data?.parentNode;

      if (!node) {
        return null;
      }

      const { height: parentHeight } = d?.parent?.id ? getNodeDimensions(d.parent.id) : { height: 0 };
      let newX = 0;
      let newY = d.parent?.id ? parentHeight / zoomLevel + 100 : 0;

      if (d.data?.type === TRIGGERS_NODE_ID) {
        newY -= (rootHeight + 120) / zoomLevel; // 120 is edge height
      }

      // Move the referring automations node to the right
      if (d.data?.type === REFERRING_AUTOMATIONS_NODE_TYPE) {
        newX = 375;
      }

      // Position the first step node below the triggers node, taking into account the hidden root node
      if (isFirstStepNode) {
        const edgeHeight = 120;
        newY = (rootHeight + edgeHeight + triggersHeight + edgeHeight) / zoomLevel;
      }

      if (d.parent?.data?.data?.stepType === 'branch') {
        const subBranchesCount = d.parent
          .descendants()
          .filter((descendant) => descendant.data.data.stepType === AutomationStepStepType.BRANCH).length;

        if (subBranchesCount > 1) {
          /*
            This block calculates the width of the yes/no arm based
            on the number of sub-branches in the tree to make space
            for sub-branches by expanding the sub-set of yes/no arms
          */
          newX = d?.data?.data?.branchArm === 'yes' ? -AUTOMATION_BRANCH_WIDTH : AUTOMATION_BRANCH_WIDTH;
          newX *= subBranchesCount;
        } else {
          newX = d?.data?.data?.branchArm === 'yes' ? -AUTOMATION_NODE_WIDTH : AUTOMATION_NODE_WIDTH;
        }
      }

      return {
        ...node,
        hidden: false,
        position: {
          x: newX,
          y: newY,
        },
      };
    })
    .filter((node) => node !== null);

  return { nodes: newNodes as Node[], edges };
};

const assignPositionToNodes = (laidOutNodes: Node[]) => (nodes: Node[]) => {
  return nodes.map((node) => {
    const laidOutNode = laidOutNodes.find(({ id }) => id === node.id) as Node;

    if (!laidOutNode) {
      return node;
    }

    return {
      ...node,
      position: laidOutNode.position,
    };
  });
};

const RE_LAY_TIMEOUT = 200; // ms

function useNewLayout(
  hasInitialised: boolean,
  automation: Automation,
  automationSteps: AutomationStep[],
  automationTriggers: AutomationTrigger[],
  changeLog: any[]
) {
  const { setNodes, setEdges, getNodes, getEdges, getZoom } = useReactFlow();

  // Re-paint layout when Automation steps are added/deleted
  useEffect(() => {
    if (hasInitialised) {
      const { nodes: translatedNodes, edges: translatedEdges } = getNodesAndEdges(automation, automationSteps);

      setNodes(translatedNodes);
      setEdges(translatedEdges);

      window.requestAnimationFrame(() => {
        setTimeout(() => {
          const { nodes: laidOutNodes, edges: laidOutEdges } = getLaidOutElements(
            translatedNodes,
            translatedEdges,
            getZoom()
          );

          setNodes(assignPositionToNodes(laidOutNodes));
          setEdges(laidOutEdges);
        }, RE_LAY_TIMEOUT);
      });
    }
  }, [hasInitialised, automation, automationSteps]);

  // Re-lay positions when Automation steps and triggers change
  useEffect(() => {
    window.requestAnimationFrame(() => {
      setTimeout(() => {
        const nodes = getNodes();
        const edges = getEdges();
        const { nodes: laidOutNodes, edges: laidOutEdges } = getLaidOutElements(nodes, edges, getZoom());

        setNodes(assignPositionToNodes(laidOutNodes));
        setEdges(laidOutEdges);
      }, RE_LAY_TIMEOUT);
    });
  }, [changeLog, automationTriggers]);
}

export default useNewLayout;
