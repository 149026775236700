import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Notification } from '@/interfaces/notification';
import api from '@/services/swarm';

interface ApiResponse {
  read: boolean;
}

export default function useNotificationReadStatusUpdate({ notification }: { notification: Notification }) {
  const [publicationId] = useCurrentPublicationState(false);

  const updateNotificationReadStatus = ({ read }: { read: boolean }): Promise<ApiResponse> => {
    if (notification.read_at) {
      return Promise.resolve({ read });
    }

    return api
      .patch(`/notifications/${notification.id}/read_status`, {
        publication_id: publicationId,
        notification: {
          read,
        },
      })
      .then((res) => res.data);
  };

  const queryClient = useQueryClient();

  return useMutation(updateNotificationReadStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([publicationId, 'notifications']);
    },
  });
}
