import React from 'react';
import { ArrowSmallUpIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

interface ProgressIndicatorProps {
  value: number;
  averageValue?: number;
  label?: string;
  showIconTracker?: boolean;
  color?: string;
}

const ProgressIndicator: React.FunctionComponent<ProgressIndicatorProps> = ({
  value,
  averageValue,
  label,
  showIconTracker = true,
  color = 'bg-primary-500',
}) => {
  const arrowUpStyle = {
    transform: `translateX(${value}%)`,
  };

  const percentageFormatter = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 });
  const formattedValue = percentageFormatter.format(value / 100);

  const isBelowAverage = averageValue && value < averageValue;

  const averageValueClass = isBelowAverage ? 'text-red-600' : 'text-green-600';

  return (
    <div className="relative my-2">
      <div className="flex absolute justify-between w-full bottom-2">
        {label && (
          <p className="font-extrabold uppercase leading-tight tracking-tighter text-xs text-gray-600">{label}</p>
        )}
        <p className={`${!averageValue && 'invisible'} text-xs text-right mb-1 ${averageValueClass}`}>
          <span>
            Avg. <strong>{averageValue}%</strong>
          </span>
        </p>
      </div>

      <div className="bg-gray-200 h-2 relative rounded">
        {!!averageValue && (
          <div
            className="absolute"
            style={{
              width: `${averageValue}%`,
            }}
          >
            <div className="bg-gray-300 h-2 rounded-full" />
          </div>
        )}
        <div
          className="relative duration-500"
          style={{
            width: `${value}%`,
          }}
        >
          <div className={cx(color, 'h-2 rounded-full')} />
          {showIconTracker && (
            <div className="absolute top-2 right-0" style={arrowUpStyle}>
              <div className="relative">
                <div className="absolute left-0 transform -translate-x-1/2">
                  <ArrowSmallUpIcon className="h-5 w-5 text-gray-400" />
                </div>
                <div className="absolute left-0 top-4 transform -translate-x-1/2">
                  <p className="text-xs text-center text-gray-600 font-bold">{formattedValue}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressIndicator;
