import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import cx from 'classnames';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Option } from '@/interfaces/general';
import { RewardPromoCode } from '@/interfaces/reward';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  subscriptionMilestoneId: string;
  rewardId: string;
  publicationId: string;
  className?: string;
  onSuccess: Function;
  slideOverBodyId?: string;
}

const AssignPromoCode: FC<Props> = (props: Props) => {
  const { subscriptionMilestoneId, rewardId, publicationId, className, onSuccess, slideOverBodyId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [promoCodes, setPromoCodes] = useState<Option[]>();
  const [promoCodeId, setPromoCodeId] = useState('');

  const fetchPromoCodes = useCallback(() => {
    const params = {
      publication_id: publicationId,
      not_assigned: true,
    };

    setIsLoading(true);
    api
      .get(`/referral_program/rewards/${rewardId}/promo_codes`, { params })
      .then((res) => {
        const options: Option[] = (res.data?.promo_codes || []).map((option: RewardPromoCode) => ({
          label: option.code,
          value: option.id,
        }));
        setPromoCodes(options);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [publicationId, rewardId]);

  useEffect(() => {
    fetchPromoCodes();
  }, [fetchPromoCodes]);

  const handleSelect = (name: string, value: string) => {
    if (name === 'promoCodeId') {
      setPromoCodeId(value);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const payload = {
      publication_id: publicationId,
    };

    setIsSaving(true);
    api
      .post(
        `/referral_program/subscription_milestones/${subscriptionMilestoneId}/assign_promo_code/${promoCodeId}`,
        payload
      )
      .then(() => {
        toast.success('Promo Code assigned successfully');
        onSuccess();
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (isLoading) {
    return <LoadingSpinner className={className} />;
  }

  return (
    <form className={cx('flex items-center', className)} onSubmit={handleSubmit}>
      <Dropdown
        name="promoCodeId"
        value={promoCodeId}
        onSelect={handleSelect}
        options={promoCodes || []}
        required
        portalMountedId={slideOverBodyId}
      />
      <Button variant="primary-inverse" className="ml-2" type="submit" loading={isSaving} disabled={promoCodeId === ''}>
        Assign
      </Button>
    </form>
  );
};

export default AssignPromoCode;
