import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/24/outline';

import Banner from '@/components/Banner';
import Card from '@/components/Card';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { Textarea } from '@/ui/Textarea';

import ActionModal from '../../../../components/ActionModal';
import { BackLink } from '../../../../components/BackLink';
import { Input } from '../../../../components/Form';
import PageHeading from '../../../../components/Layout/PageLayout/PageHeading';
import { useDeleteForm, useForm, useUpdateForm } from '../../../../hooks/useForms';
import usePublishForm from '../../../../hooks/useForms/usePublishForm';
import { Form, FormStatus } from '../../../../interfaces/form';

interface Props {
  isNewLayout?: boolean;
}

const FormSettings = ({ isNewLayout }: Props) => {
  const { formId } = useParams();
  const { data: form } = useForm(formId);
  const isFormLive = form?.status === FormStatus.LIVE;

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Form field states
  const [title, setTitle] = useState(form?.title || '');
  const [description, setDescription] = useState(form?.description || '');
  const [ctaText, setCtaText] = useState(form?.cta_text || '');
  const [thankYouMessage, setThankYouMessage] = useState(form?.thank_you_message || '');

  useEffect(() => {
    if (form) {
      setTitle(form.title);
      setDescription(form.description);
      setCtaText(form.cta_text);
      setThankYouMessage(form.thank_you_message);
    }
  }, [form]);

  // Hanndle saving form as draft
  const updateForm = useUpdateForm({
    formId: formId || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['forms', formId]);
    },
  });
  const handleSaveAsDraft = async (isSilentSave: boolean) => {
    await updateForm.mutateAsync({
      status: FormStatus.DRAFT,
      title,
      description,
      ctaText,
      thankYouMessage,
    });
    if (!isSilentSave) {
      toast.success('Survey saved!');
    }
  };

  const handleUpdateLiveForm = async () => {
    try {
      await updateForm.mutateAsync({
        status: FormStatus.LIVE,
        title,
        description,
        ctaText,
        thankYouMessage,
      });
      toast.success('Survey saved!');
    } catch (error) {
      toast.error('There was an error updating this survey');
    }
  };

  const handleUpdateSurvey = async () => {
    try {
      await updateForm.mutateAsync({
        status: form?.status,
        title,
        description,
        ctaText,
        thankYouMessage,
      });
      toast.success('Survey saved!');
    } catch (error) {
      toast.error('There was an error updating this survey');
    }
  };

  // Handle Publishing form
  const [isPublishing, setIsPublishing] = useState(false);
  const publishForm = usePublishForm({
    formId: formId || '',
    onSuccess: () => {
      toast.success('Survey published!');
      queryClient.invalidateQueries(['forms', formId]);
      navigate('/forms');
    },
  });
  const handlePublish = () => {
    publishForm.mutate({
      title,
      description,
      ctaText,
      thankYouMessage,
    });
  };

  // Handle Deleting form
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteForm = useDeleteForm({
    formId: form?.id || '',
  });
  const handleDelete = async () => {
    try {
      await deleteForm.mutateAsync();
      setIsDeleting(false);
      navigate('/forms');
    } catch (error) {
      toast.error('There was an error deleting this survey');
      setIsDeleting(false);
    }
  };

  const handlePublishForm = () => {
    setIsPublishing(true);
  };

  const renderActions = () => {
    if (isFormLive) {
      return (
        <Button onClick={handleUpdateLiveForm} variant="primary" loading={updateForm.isLoading}>
          {updateForm.isLoading ? 'Updating...' : 'Update Survey'}
        </Button>
      );
    }

    return (
      <div className="flex space-x-2 justify-end">
        <Button onClick={() => setIsDeleting(true)} variant="danger">
          Delete
        </Button>
        <Button onClick={() => handleSaveAsDraft(false)} variant="primary-inverse" loading={updateForm.isLoading}>
          {updateForm.isLoading ? 'Saving...' : 'Save as draft'}
        </Button>
        {form?.url && form?.form_questions?.length && !isFormLive ? (
          <Button
            type="button"
            onClick={() => window.open(`${form.url}?preview=true`)}
            variant="primary-inverse"
            Icon={EyeIcon}
          >
            Preview Survey
          </Button>
        ) : null}
        <Button onClick={handlePublishForm}>Publish</Button>
      </div>
    );
  };

  const renderedForm = (
    <div className="flex  flex-col space-y-8 w-full">
      <div className="w-full">
        <div className="flex flex-col space-y-3">
          <Input
            name="title"
            value={title}
            labelText="Title"
            placeholder="Website Survey"
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => {
              if (title !== form?.title) {
                updateForm.mutate({
                  status: form?.status,
                  title,
                  description: form?.description,
                  ctaText: form?.cta_text,
                  thankYouMessage: form?.thank_you_message,
                });
              }
            }}
          />
          <Textarea
            name="description"
            value={description}
            labelText="Description"
            placeholderText="This survey is to collect feedback on the new website design."
            helperText="Use this to set a helpful description or to provide a prompt to fill out the survey for your subscribers!"
            onChange={(e) => setDescription(e.target.value)}
            onBlur={() => {
              if (description !== form?.description) {
                updateForm.mutate({
                  status: form?.status,
                  title: form?.title,
                  description,
                  ctaText: form?.cta_text,
                  thankYouMessage: form?.thank_you_message,
                });
              }
            }}
          />
          <Input
            name="cta_text"
            value={ctaText}
            labelText="CTA Text"
            placeholder="Submit Survey"
            helperText="This is the text that will appear on the submit button for your survey."
            onChange={(e) => setCtaText(e.target.value)}
            onBlur={() => {
              if (ctaText !== form?.cta_text) {
                updateForm.mutate({
                  status: form?.status,
                  title: form?.title,
                  description: form?.description,
                  ctaText,
                  thankYouMessage: form?.thank_you_message,
                });
              }
            }}
          />
          <Input
            name="thank_you_text"
            value={thankYouMessage}
            labelText="Thank You Message"
            placeholder="Thanks for your submission, it will go towards improving the publication!"
            helperText="This is the message users will see after submitting the survey."
            onChange={(e) => setThankYouMessage(e.target.value)}
            onBlur={() => {
              if (thankYouMessage !== form?.thank_you_message) {
                updateForm.mutate({
                  status: form?.status,
                  title: form?.title,
                  description: form?.description,
                  ctaText: form?.cta_text,
                  thankYouMessage,
                });
              }
            }}
          />
        </div>
      </div>
      {isFormLive && (
        <Banner
          isScreenWide={false}
          title="This survey is already live!"
          bodyText="To prevent inconsistent data, we prevent users from updating live surveys. However, feel free to edit any of the details above."
        />
      )}
    </div>
  );

  return (
    <div
      className={
        isNewLayout
          ? 'flex flex-col gap-y-3 mt-40 py-5 px-4 md:px-10 md:mt-28 md:mb-36 lg:w-1/2 lg:px-0 lg:mx-auto'
          : 'mt-40 mx-40'
      }
    >
      <ActionModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onProceed={handleDelete}
        resourceId={formId || ''}
        isWorking={deleteForm.isLoading}
        headerText="Delete Survey"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this survey?
      </ActionModal>
      <ActionModal
        isOpen={isPublishing}
        onClose={() => setIsPublishing(false)}
        onProceed={handlePublish}
        resourceId={formId || ''}
        isWorking={publishForm.isLoading}
        headerText="Publish Survey"
        actionText="Publish"
      >
        <div className="space-y-2">
          <p>Are you sure you want to publish this survey?</p>
          <p>
            To protect the intregrity of the data you receive from subscribers,{' '}
            <span className="font-bold text-gray-900">
              you will NOT be able to edit any of the survey details once it is live.
            </span>
          </p>
          <p>
            Maybe you would like to preview this survey before turning it on?{' '}
            {form?.url && form?.form_questions?.length ? (
              <div className="text-sm text-primary-500 underline underline-primary-500 flex items-center">
                <a
                  href={`${form.url}?preview=true`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm text-primary-500 underline underline-primary-500 flex items-center"
                >
                  <span>Click to Preview</span>.
                </a>
              </div>
            ) : null}
          </p>
        </div>
      </ActionModal>

      {!isNewLayout ? (
        <div>
          <BackLink to={`/forms/${form?.id}/edit`}>Back</BackLink>
          <div className="flex flex-col items-end">
            <div className="w-full">
              {isFormLive && (
                <div className="mb-2">
                  <Badge type="success">
                    <span className="text-xs capitalize">Live</span>
                  </Badge>
                </div>
              )}
              <PageHeading title="Build a new survey" description="Add questions and publish a new survey below">
                {form ? renderActions() : <div className="h-8 w-40 bg-gray-200 animate-pulse rounded" />}
              </PageHeading>
            </div>
          </div>
        </div>
      ) : null}

      {isNewLayout ? <Card>{renderedForm}</Card> : renderedForm}

      {isNewLayout ? (
        <div className="flex justify-end w-full">
          <Button onClick={handleUpdateSurvey} variant="primary" loading={updateForm.isLoading}>
            {updateForm.isLoading ? 'Updating...' : 'Update Survey'}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

type ContextType = {
  form: Form;
};

export const useEditFormViewContent = () => useOutletContext<ContextType>();

export default FormSettings;
