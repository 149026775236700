import { useEffect, useState } from 'react';
import cx from 'classnames';

import ActionsDropdown from '@/components/ActionsDropdown';
import { FormQuestion } from '@/interfaces/form';

interface Props {
  question: FormQuestion;
}

const DropdownPreview = ({ question }: Props) => {
  const [selection, setSelection] = useState('');
  const [height, setHeight] = useState(0);
  const options = question.form_question_options.map((option) => {
    return {
      name: option.value,
      isCurrent: false,
      isVisible: () => true,
      onClick: () => {
        setSelection(option.value);
      },
    };
  });

  useEffect(() => {
    if (options) {
      setHeight(options.length * 80);
    }
  }, [options]);

  const buttonText =
    question.form_question_options.find((option) => option.value === selection)?.value || 'Please select a value';

  return (
    <div className="flex" style={{ height: `${height}px` }}>
      <ActionsDropdown
        buttonText={buttonText}
        size="md"
        actions={[options]}
        node={{}}
        buttonClass={cx(
          selection ? 'text-black' : 'text-gray-400 text-opacity-50',
          'wt-body-font w-full rounded-wt border-2 border-gray-200 text-base font-light shadow-md focus:border-wt-primary focus:outline-none sm:shadow-none'
        )}
        dropdownClass="left-0 wt-body-font text-base rounded-wt font-light text-black shadow-md"
        optionClass="text-left break-all"
      />
    </div>
  );
};

export default DropdownPreview;
