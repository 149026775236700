import type { CustomDomainTypes } from '@/interfaces/custom_domain';

export enum SetupMethod {
  MANUAL = 'manual',
  ENTRI = 'entri',
}

export interface CreateCustomDomainsCollectionPayload {
  web_domain?: string | null;
  web_redirect_domain?: string | null;
  email_domain?: string | null;
  email_username?: string | null;
}

export interface CreateCustomDomainsCollectionSuccessResponse {
  custom_domain_ids: string[];
  verifiable_domain_types: CustomDomainTypes[];
  entri_compatible?: boolean;
}

export interface CreateCustomDomainsCollectionErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

export interface UpdateCustomDomainErrorResponse {
  response: {
    data: {
      message: string;
    };
  };
}

export interface DNSRecord {
  type: string;
  host: string;
  value: string;
  ttl: number;
}

export interface CreateEntriModalRecordsSuccessResponse {
  applicationId: string;
  userId: string;
  token: string;
  prefilledDomain: string | string[];
  dnsRecords: Record<string, DNSRecord[]> | DNSRecord[];
}

export interface CheckExistingRecordsPayload {
  publication_id: string;
  domain: string;
  dmarc_check: boolean;
}
export interface CheckExistingRecordsSuccessResponse {
  domain: string;
  dmarc_check: boolean;
  result: boolean;
}

export interface CheckExistingRecordsErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}
