import { useCurrentPublication, useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { useUpdateWebTheme } from '@/hooks/useWebTheme';
import { Option } from '@/interfaces/general';
import { TutorialType } from '@/interfaces/tutorial';

import { ColorForm, Section, SelectForm } from '../../../Components';
import InputForm from '../../../Components/InputForm';
import VideoTutorial from '../../../Components/VideoTutorial';

const General = () => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: currentPublication } = useCurrentPublication();
  const webThemeMutation = useUpdateWebTheme(currentPublication?.web_theme_id);
  const webTemplateMutation = useUpdateWebTemplate();

  // Theme options
  const themeOptions = useOptions(currentPublicationId, 'themes');
  const themeOptionsFormatted = themeOptions?.data?.options?.map((theme: any) => {
    return {
      label: Object.values(theme)[0],
      value: Object.values(theme)[0],
    } as Option;
  });
  const subscribeTheme = currentPublication?.web_theme?.subscribe_theme || '';
  const contentTheme = currentPublication?.web_theme?.content_theme || '';

  // Border options
  const borderOptions = useOptions(currentPublicationId, 'borders');
  const borderOptionsFormatted = borderOptions?.data?.options?.map((theme: any) => {
    return {
      label: Object.values(theme)[0],
      value: Object.values(theme)[0],
    };
  });
  const borderStyle = currentPublication?.web_theme?.border_style || '';

  // Layout options
  const webTemplateRequest = useWebTemplate();
  const webTemplate = webTemplateRequest?.data;
  const webTemplateTheme = webTemplate?.theme || '';
  const options = webTemplate?.theme_options?.map((themeOption: string): Option => {
    if (themeOption === 'default') {
      return {
        value: themeOption,
        label: 'default',
      };
    }
    if (themeOption === 'news') {
      return {
        value: themeOption,
        label: 'newspaper',
      };
    }
    return {
      value: themeOption,
      label: themeOption,
    };
  });

  // Shadow options
  const shadowOptions = useOptions(currentPublicationId, 'shadows');
  const shadowOptionsFormatted = shadowOptions?.data?.options?.map((theme: any) => {
    return {
      label: Object.keys(theme)[0],
      value: Object.values(theme)[0],
    };
  });
  const logoShadowValue = currentPublication?.web_theme?.logo_shadow || '';
  const logoShadowName = shadowOptionsFormatted?.find((option: any) => option.value === logoShadowValue)?.label || '';

  return (
    <>
      {/* Color Palette Section */}
      <Section
        title="Color Palette"
        description={
          <div>
            <span className="mr-2">Customize the color scheme for your site.</span>
            <VideoTutorial type={TutorialType.THEME_EDITOR} />
          </div>
        }
        scrollToId="color-palette"
        isLoading={!currentPublication}
      >
        <ColorForm
          label="Primary Color"
          description="Color used for buttons and hyperlinks in text"
          value={currentPublication?.web_theme?.primary_color}
          toastSuccessMessage="Saved!"
          onSave={(val: any) =>
            webThemeMutation.mutateAsync({
              primary_color: val,
            })
          }
        />
        <ColorForm
          label="Secondary Color"
          description="Accent color that complements the primary color"
          value={currentPublication?.web_theme?.secondary_color}
          toastSuccessMessage="Saved!"
          onSave={(val: any) =>
            webThemeMutation.mutateAsync({
              secondary_color: val,
            })
          }
        />
        <ColorForm
          label="Tertiary Color"
          description="Color used for the navbar and footer"
          value={currentPublication?.web_theme?.tertiary_color}
          toastSuccessMessage="Saved!"
          onSave={(val: any) =>
            webThemeMutation.mutateAsync({
              tertiary_color: val,
            })
          }
        />
      </Section>

      {/* Layouts Section */}
      <Section
        title="Layouts"
        description="Choose a layout that best reflects your publications style."
        scrollToId="layouts"
        isLoading={!webTemplate}
      >
        <SelectForm
          label="Posts Layout"
          description="Choose between our classic template or newspaper template"
          options={options || []}
          value={{ label: webTemplateTheme === 'news' ? 'Newspaper' : webTemplateTheme, value: webTemplateTheme }}
          toastSuccessMessage="Saved!"
          onSave={async (val: Option) =>
            webTemplateMutation.mutate({
              theme: val.value,
            })
          }
        />
      </Section>

      {/* Themes Section */}
      <Section
        title="Themes"
        description="Different themes utilize your color palette differently. Light will utilize a white background, dark a black background, and secondary will use your secondary color as the background color."
        scrollToId="themes"
        isLoading={!currentPublication || !themeOptions}
      >
        <SelectForm
          label="Subscribe landing page"
          description="The color theme you will see on your landing page"
          options={themeOptionsFormatted}
          value={{ label: subscribeTheme, value: subscribeTheme }}
          toastSuccessMessage="Saved!"
          onSave={(val: Option) =>
            webThemeMutation.mutateAsync({
              subscribe_theme: val.label,
            })
          }
        />
        <SelectForm
          label="Content pages"
          description="The color theme you will see on your content pages"
          options={themeOptionsFormatted}
          value={{ label: contentTheme, value: contentTheme }}
          toastSuccessMessage="Saved!"
          onSave={(val: Option) =>
            webThemeMutation.mutateAsync({
              content_theme: val.label,
            })
          }
        />
      </Section>

      {/* Typography Section */}
      <Section
        title="Typography"
        description={
          <div>
            Fonts are case sensitive and should be chosen from{' '}
            <a href="https://fonts.google.com" target="_blank" className="text-primary-500" rel="noreferrer">
              Google Fonts
            </a>
            .
          </div>
        }
        scrollToId="fonts"
        isLoading={!currentPublication}
      >
        <InputForm
          name="header_font"
          label="Header Font"
          placeholder="Open Sans"
          value={currentPublication?.web_theme?.header_font || 'Open Sans'}
          isSaving={webThemeMutation.isLoading}
          toastSuccessMessage="Saved!"
          onSave={(val) =>
            webThemeMutation.mutateAsync({
              header_font: val,
            })
          }
        />
        <InputForm
          name="body_font"
          label="Body Font"
          placeholder="Open Sans"
          value={currentPublication?.web_theme?.body_font || 'Open Sans'}
          isSaving={webThemeMutation.isLoading}
          toastSuccessMessage="Saved!"
          onSave={(val) =>
            webThemeMutation.mutateAsync({
              body_font: val,
            })
          }
        />
        <InputForm
          name="button_font"
          label="Button Font"
          placeholder="Open Sans"
          value={currentPublication?.web_theme?.button_font || 'Open Sans'}
          isSaving={webThemeMutation.isLoading}
          toastSuccessMessage="Saved!"
          onSave={(val) =>
            webThemeMutation.mutateAsync({
              button_font: val,
            })
          }
        />
      </Section>

      {/* Buttons & Forms Section */}
      <Section
        title="Buttons & Forms"
        description="The style of the forms and buttons on your site"
        scrollToId="buttons-forms"
        hasDivider
        isLoading={!currentPublication || !borderOptions}
      >
        <SelectForm
          label="Roundness"
          description="Adjusts the roundness of the buttons and forms"
          options={borderOptionsFormatted}
          value={{ label: borderStyle, value: borderStyle }}
          toastSuccessMessage="Saved!"
          onSave={(val: Option) =>
            webThemeMutation.mutateAsync({
              border_style: val.label,
            })
          }
        />
      </Section>

      {/* Images Section */}
      <Section
        title="Images"
        description="The style of the images on your site"
        scrollToId="images"
        hasDivider={false}
        isLoading={!currentPublication || !borderOptions}
      >
        {logoShadowName && logoShadowValue && (
          <SelectForm
            label="Logo Shadow"
            description="Adjusts the shadow of the logo image"
            options={shadowOptionsFormatted}
            value={{ label: logoShadowName, value: logoShadowValue }}
            toastSuccessMessage="Saved!"
            onSave={(val: Option) =>
              webThemeMutation.mutateAsync({
                logo_shadow: val.value,
              })
            }
          />
        )}
      </Section>
      <div className="pb-20" />
    </>
  );
};

export default General;
