import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TrashIcon } from '@heroicons/react/24/outline';

import { Input } from '../../../../../../components/Form';
import { useCurrentPublicationState } from '../../../../../../context/current-publication-context';
import { SplitTestOption } from '../../../../../../interfaces/split_test_option';
import api from '../../../../../../services/swarm';

interface Props {
  labelText: string;
  postId: string;
  splitTestOption: SplitTestOption;
  deletable: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  locked?: boolean;
}

const OptionForm = ({ labelText, postId, splitTestOption, deletable, onSave, onDelete, locked }: Props) => {
  const [publicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const [value, setValue] = useState(splitTestOption.value);
  const [error, setError] = useState('');
  const name = splitTestOption?.id ? `split_test_option_${splitTestOption.id}` : 'new_split_test_option';

  const updateSplitTestOption = useMutation(
    (newValue: string) => {
      return api
        .patch(`/posts/${postId}/split_test_option`, {
          publication_id: publicationId,
          split_test_option: {
            id: splitTestOption.id,
            value: newValue,
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        if (onSave) {
          onSave();
        }
        setError('');
        return queryClient.invalidateQueries(['split_test', postId]);
      },
      onError: (err: any) => {
        setError(err?.response.data.error || "Couldn't save this option");
      },
    }
  );

  const deleteSplitTestOption = useMutation(
    () => {
      return api
        .delete(`/posts/${postId}/split_test_option`, {
          data: {
            publication_id: publicationId,
            split_test_option: {
              id: splitTestOption.id,
            },
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['split_test', postId]);
      },
    }
  );

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value !== splitTestOption.value) {
      updateSplitTestOption.mutate(event.target.value);
    }
  };

  const handleRemoveOption = () => {
    if (onDelete) onDelete();
    deleteSplitTestOption.mutate();
  };

  return (
    <div className="flex flex-row space-x-2">
      <Input
        className="w-full"
        labelText={labelText}
        onBlur={onBlurHandler}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            event.currentTarget.blur();
          }
        }}
        name={name}
        required
        value={value}
        errorText={error}
        disabled={locked}
      />
      {deletable && !locked ? (
        <div aria-hidden="true" className="cursor-pointer" onClick={handleRemoveOption}>
          <TrashIcon className="w-6 h-6 mt-7 text-gray-500 hover:text-primary-500" />
        </div>
      ) : (
        <div aria-hidden="true" className="cursor-not-allowed">
          <TrashIcon className="w-6 h-6 mt-7 text-gray-500" />
        </div>
      )}
    </div>
  );
};

export default OptionForm;
