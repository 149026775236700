import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

interface Props {
  enabled: boolean;
  title: string;
  url?: string;
  children?: React.ReactNode;
  onEdit?: () => void;
  onUpdateMessage?: () => void;
}

const FlowStep = ({ enabled, title, url, children, onEdit, onUpdateMessage }: Props) => {
  return (
    <div className="flex justify-between w-full">
      <div className={cx('space-y-0.5', enabled ? 'opacity-100' : 'opacity-40')}>
        <p className="block text-sm font-semibold text-gray-700">{title}</p>
        <div className="flex space-x-2 items-center">
          <a
            href={url}
            className={cx(
              'flex text-xs font-regular items-center',
              url ? 'text-primary-500' : 'text-gray-400 pointer-events-none'
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Url <ArrowTopRightOnSquareIcon className="ml-1 h-3 w-3" />
          </a>

          {onEdit && (
            <div className="border-l border-gray-200 pl-2">
              <button
                type="button"
                onClick={onEdit}
                className="flex text-xs font-regular text-primary-500 items-center"
              >
                Edit
              </button>
            </div>
          )}

          {onUpdateMessage && (
            <div className="border-l border-gray-200 pl-2">
              <button
                type="button"
                onClick={onUpdateMessage}
                className="flex text-xs font-regular text-primary-500 items-center"
              >
                Message
              </button>
            </div>
          )}
        </div>
      </div>

      {children}
    </div>
  );
};

export default FlowStep;
