import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { Option } from '@/interfaces/general';
import { Dropdown } from '@/ui/Dropdown';

import { SelectSubElementClassNames } from './types';

interface Props {
  options: Option[];
  value: string;
  onSelect: (value: any, index?: number) => void;
  index?: number;
  name: string;
  buttonClassNames?: SelectSubElementClassNames;
}

const GroupSelect = ({ name, value, onSelect, index, options, buttonClassNames }: Props) => (
  <Dropdown
    name={name}
    buttonClassNames={buttonClassNames}
    optionsContainerClassNames={{
      position: 'origin-top-left left-0',
      width: 'w-48',
    }}
    value={value}
    onSelect={(selectName: string, selectedValue: string) => onSelect(selectedValue, index)}
    options={options}
    suffixElement={<ChevronDownIcon className="h-3 w-3 text-gray-700" aria-hidden="true" />}
  />
);

export default GroupSelect;
