import React from 'react';
import { useParams } from 'react-router-dom';

import { usePublicationOpportunitiesCreate } from '../../../../../hooks/useAdNetwork/internal';
import { PageHeading } from '../../../_components/PageHeading';

import { Form } from './_components/Form';

const PublicationOpportunitiesNew = () => {
  const { publication_id: id } = useParams<'publication_id'>() as { publication_id: string };
  const onSubmitMutation = usePublicationOpportunitiesCreate({ publicationId: id });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Opportunities</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="new">New</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <Form onSubmitMutation={onSubmitMutation} />
    </>
  );
};

export default PublicationOpportunitiesNew;
