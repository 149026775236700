import cx from "classnames";

interface Props {
  className?: string;
}

const TwitterIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={cx(className)}
    >
      <path
        d="M13.5703 16.4958L3.89132 4.05129C3.66426 3.75935 3.8723 3.33398 4.24214 3.33398H6.07901C6.21616 3.33398 6.34563 3.3973 6.42983 3.50557L16.1088 15.95C16.3359 16.242 16.1279 16.6673 15.758 16.6673H13.9212C13.784 16.6673 13.6545 16.604 13.5703 16.4958Z"
        stroke="currentColor"
      />
      <path
        d="M15.9261 3.33398L4.07422 16.6673"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TwitterIcon;
