import { forwardRef, HTMLProps } from 'react';

import { cn } from '@/utils/cn';

export type LabelProps = {
  text: string;
  value?: string;
} & HTMLProps<HTMLDivElement>;

export const Label = forwardRef<HTMLDivElement, LabelProps>(({ text, value, className, ...rest }, ref) => {
  const labelClassName = cn('flex items-center justify-between gap-2 text-xs font-semibold text-gray-700', className);

  return (
    <div ref={ref} className={labelClassName} {...rest}>
      <span>{text}</span>
      <span>{value}</span>
    </div>
  );
});
