import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ClockCounterClockwise, Plus } from '@phosphor-icons/react';

import { useWebsiteContext, WebsiteProvider } from '@/context/website-context';
import { cn } from '@/utils/cn';

import {
  ATTRIBUTES_PANEL_ID,
  LAYERS_PANEL_ID,
  SIDE_INSERT_PANEL_ID,
  VERSION_HISTORY_PANEL_ID,
} from '../../_components/DreamEditor/constants';
import { Layout } from '../../_components/Layout';
import { ActionMenu } from '../../_components/Layout/ActionMenu';
import { NavSection } from '../../_components/SideNav/NavSection';
import { Button, CloseButton } from '../../_components/UI/Button';
import { Tooltip } from '../../_components/UI/Tooltip';
import VersionHistoryNotice from '../../_components/VersionHistoryNotice';
import { useContainerRef } from '../../page/_hooks';

import { Editor, EditorDataProvider } from './_components/Editor';

const EditorPage = () => {
  const navigate = useNavigate();
  const { previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);

  const { rect: editorRect, containerRef: editorContainerRef } = useContainerRef();
  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion]);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary pb-0 pt-0"
      isSidebarResizable
      rightChildren={
        <Tooltip center="Version History" delay={300}>
          <Button
            variant="secondary"
            Icon={ClockCounterClockwise}
            iconWeight="bold"
            onClick={() => setIsVersionHistoryPanelOpen((prev) => !prev)}
            className="h-full"
          />
        </Tooltip>
      }
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
        />
      }
      sidenavChildren={
        <>
          <Button variant="secondary" Icon={ArrowLeft} iconWeight="bold" onClick={() => navigate(-1)} />
          <NavSection>
            <div id={LAYERS_PANEL_ID} />
          </NavSection>
          <div
            id={SIDE_INSERT_PANEL_ID}
            className={cn(
              'absolute top-0 left-0 bottom-0 min-w-[200px] max-w-[250px] transition-transform duration-250 bg-wb-primary border-r border-solid overflow-y-auto',
              isInsertPanelOpen ? 'translate-x-0' : `-translate-x-[500px]`
            )}
          />
        </>
      }
      rightSideChildren={
        <>
          <div
            className="w-[266px] min-w-[266px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-auto"
            id={ATTRIBUTES_PANEL_ID}
            style={{
              scrollbarGutter: 'stable',
            }}
          />
          <div
            id={VERSION_HISTORY_PANEL_ID}
            className={cn(
              'absolute top-0 right-0 bottom-0 w-[250px] transition-transform duration-250 bg-wb-primary border-l border-solid overflow-y-auto p-3',
              isVersionHistoryPanelOpen ? 'translate-x-0' : `translate-x-[500px]`
            )}
          >
            <CloseButton
              onClose={() => setIsVersionHistoryPanelOpen(false)}
              className="-translate-x-1/2 translate-y-1/2"
            />
          </div>
        </>
      }
      titleType="page_name"
    >
      <div ref={editorContainerRef} className="h-full bg-transparent w-full relative">
        <Editor editorRect={editorRect} />
      </div>
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <EditorDataProvider>
      <EditorPage />
    </EditorDataProvider>
  </WebsiteProvider>
);
