import { useCallback, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelDivider, PanelFooter } from '@/components/TiptapEditor/components/ui/Panel/styled';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';
import { useThemeData } from '@/components/TiptapEditor/lib/hooks/useThemeData';

import { BordersPanel } from '../Borders/BordersPanel';
import { useImageBlockOptions } from '../hooks';

import { ImageAlignment } from './ImageAlignment';
import { ImageWidth } from './ImageWidth';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const OptionsPanel = ({ parentRef, editor }: { parentRef: React.RefObject<HTMLElement>; editor: Editor }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [isBordersPanelOpen, setIsBordersPanelOpen] = useState<boolean>(false);

  const imageBlockAttrs = editor.getAttributes('imageBlock');
  const themeColors = useThemeData('colors');
  const { hasBorderOverrides, hasOptionsOverrides } = useImageBlockOptions(imageBlockAttrs, themeColors);

  const onResetOptions = useCallback(() => {
    editor
      .chain()
      .setImageBlockWidth(100)
      .setImageBlockAlign('center')
      .setIndividualImageBlockBorderRadius(false)
      .setImageBlockBorderRadius(0)
      .setIndividualImageBlockBorderWidth(false)
      .setImageBlockBorderWidth(0)
      .setImageBlockBorderColor(null)
      .setImageBlockBorderStyle('solid')
      .run();
  }, [editor]);

  return (
    <Tippy
      placement="bottom-start"
      getReferenceClientRect={() => {
        return parentRef.current?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);
      }}
      render={(attrs) =>
        isPanelOpen &&
        (isBordersPanelOpen ? (
          <BordersPanel editor={editor} onBack={() => setIsBordersPanelOpen(false)} />
        ) : (
          <Panel
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attrs}
            tabIndex={-1}
            ref={panelRef}
          >
            <ImageWidth editor={editor} />
            <ImageAlignment editor={editor} />
            <PanelDivider />
            <Button
              $variant="quaternary"
              $size="small"
              $active={hasBorderOverrides}
              onClick={() => setIsBordersPanelOpen(true)}
              $fullWidth
              $leftSlot={<Icon name="Border" />}
              $rightSlot={<Icon name="ChevronRight" />}
            >
              Borders
            </Button>
            {hasOptionsOverrides && (
              <PanelFooter>
                <Button
                  $variant="quaternary"
                  $size="small"
                  $rightSlot={<Icon name="Reset" />}
                  onClick={onResetOptions}
                  $fullWidth
                  $active
                  $muted
                >
                  Reset all
                </Button>
              </PanelFooter>
            )}
          </Panel>
        ))
      }
      interactive
      offset={[0, 4]}
      visible={isPanelOpen}
      onShow={() => {
        setIsPanelOpen(true);
      }}
      onHide={() => {
        setIsPanelOpen(false);
        setIsBordersPanelOpen(false);
      }}
      onClickOutside={() => {
        setIsPanelOpen(false);
      }}
    >
      <div>
        <Tooltip title="Options">
          <Button
            ref={buttonRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $active={hasOptionsOverrides || isPanelOpen}
            $muted={!hasOptionsOverrides && isPanelOpen}
            $leftSlot={<Icon name="Settings" />}
            onClick={() => setIsPanelOpen(!isPanelOpen)}
          />
        </Tooltip>
      </div>
    </Tippy>
  );
};

export default OptionsPanel;
