import { useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel } from '@/components/TiptapEditor/components/ui/Panel/styled';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';

import { ImageAlignment } from './ImageAlignment';
import { ImageWidth } from './ImageWidth';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const LogoOptionsPanel = ({
  parentRef,
  editor,
  setWidthContext,
  setAlignContext,
}: {
  parentRef: React.RefObject<HTMLElement>;
  editor: Editor;
  setWidthContext: (overrideWidth: number) => void;
  setAlignContext: (overrideAlign: string) => void;
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  return (
    <Tippy
      placement="bottom-start"
      getReferenceClientRect={() => {
        return parentRef.current?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);
      }}
      render={(attrs) =>
        isPanelOpen && (
          <Panel
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attrs}
            tabIndex={-1}
            ref={panelRef}
          >
            <ImageWidth editor={editor} setWidthContext={setWidthContext} />
            <ImageAlignment editor={editor} setAlignContext={setAlignContext} />
          </Panel>
        )
      }
      interactive
      offset={[0, 4]}
      visible={isPanelOpen}
      onShow={() => {
        setIsPanelOpen(true);
      }}
      onHide={() => {
        setIsPanelOpen(false);
      }}
      onClickOutside={() => {
        setIsPanelOpen(false);
      }}
    >
      <div>
        <Tooltip title="Ad Logo Image Options">
          <Button
            ref={buttonRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $active={isPanelOpen}
            $muted={false}
            $leftSlot={<Icon name="AIImage" />}
            onClick={() => setIsPanelOpen(!isPanelOpen)}
          />
        </Tooltip>
      </div>
    </Tippy>
  );
};

export default LogoOptionsPanel;
