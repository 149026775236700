import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

// This hook does NOT delete the invite, it only sets hidden: true
const useDeleteInvite = (organizationId: string, publicationId: string, inviteId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => api.delete(`/invites/${inviteId}`, {
        data: {
          publication_id: publicationId,
        },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['invites', organizationId]);
        toast.success('Invite successfully deleted!');
      },
    }
  );
};

export default useDeleteInvite;
