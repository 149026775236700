import { forwardRef } from 'react';

import { cn } from '@/utils/cn';

export const Surface = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>((props, ref) => {
  const className = cn(
    'bg-panel-background p-2 text-panel-foreground rounded-[10px] shadow-lg overflow-hidden',
    props.className
  );

  return (
    <div {...props} ref={ref} className={className}>
      {props.children}
    </div>
  );
});

export const Item = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(
  ({ type = 'button', ...props }, ref) => {
    const className = cn(
      'text-left py-[3px] rounded px-1.5 cursor-pointer text-sm font-medium leading-none hover:bg-panel-highlight',
      props.className
    );

    return (
      <button
        {...props}
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={className}
      >
        {props.children}
      </button>
    );
  }
);

export const ScrollList = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>((props, ref) => {
  const className = cn('flex flex-col gap-1 overflow-y-auto max-h-32 pr-2', props.className);

  return (
    <div {...props} ref={ref} className={className}>
      {props.children}
    </div>
  );
});
