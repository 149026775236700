import { memo, useCallback, useMemo, useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import { EditorColor } from '@/components/TiptapEditor/lib/types';
import { getTextDocumentColors } from '@/utils/documentColors';

import { useThemeData } from '../../../lib/hooks/useThemeData';
import styles from '../../../styled';
import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Panel, PanelFooter, PanelHeadline, PanelSection } from '../../ui/Panel';
import { SearchList, SearchListItemProps } from '../../ui/SearchList';
import { Tooltip } from '../../ui/Tooltip';
import CustomColorPanel from '../CustomColor';

import { Styled } from './styled';

type TextStylesPanelProps = {
  color: string | null;
  fontFamily: string | null;
  fontSize: string | null;
  documentColors: Array<EditorColor>;
  onSetColor: (color: string | null) => void;
  onSetFontFamily: (fontFamily: string | null) => void;
  onSetFontSize: (fontSize: string | null) => void;
  isDisabled?: boolean;
  isLinkActive?: boolean;
};

export const TextStylesPanel = memo(
  ({
    color,
    fontFamily,
    fontSize,
    onSetColor,
    onSetFontFamily,
    onSetFontSize,
    isDisabled,
    documentColors,
  }: TextStylesPanelProps): JSX.Element => {
    const [isFontFamilyPanelOpen, setIsFontFamilyPanelOpen] = useState(false);
    const [isMainPanelOpen, setIsMainPanelOpen] = useState(false);
    const [isColorPanelOpen, setIsColorPanelOpen] = useState(false);

    const themeColors = useThemeData('colors');
    const fontSizes = useThemeData('font-sizes');
    const fontFamilies = useThemeData('font-families');

    const currentFontFamily = useMemo(
      () =>
        fontFamilies.find((_fontFamily) => {
          if (!fontFamily) {
            return _fontFamily.key === 'font-family-default';
          }
          return _fontFamily.value === fontFamily;
        }),
      [fontFamily, fontFamilies]
    );

    const currentFontSize = useMemo(
      () =>
        fontSizes.find((_fontSize) => {
          if (!fontSize) {
            return _fontSize.key === 'font-size-default';
          }
          return _fontSize.value === fontSize;
        }),
      [fontSize, fontSizes]
    );

    const textColors = useMemo(() => {
      return getTextDocumentColors(
        documentColors,
        themeColors.map((c) => c.value)
      );
    }, [documentColors, themeColors]);

    const isDefaultFontFamily = currentFontFamily?.key === 'font-family-default';
    const isDefaultFontSize = currentFontSize?.key === 'font-size-default';

    const isCustomColor = color && !themeColors.find((themeColor) => themeColor.value === color);

    const hasOverrides = !isDefaultFontFamily || !isDefaultFontSize || (color !== undefined && color !== null);

    const renderColorPanel = useCallback(
      () => (
        <CustomColorPanel
          selectedColor={color || ''}
          onBack={() => {
            setIsColorPanelOpen(false);
          }}
          onSelect={onSetColor}
        />
      ),
      [color, onSetColor]
    );

    const renderDefaultPanel = (attrs: any) => {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Panel tabIndex={-1} {...attrs}>
          <PanelSection>
            <PanelHeadline>Font family override</PanelHeadline>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => {
                setIsFontFamilyPanelOpen(true);
              }}
              $fullWidth
              $rightSlot={<Icon name="ChevronRight" />}
              $active
              $muted={isDefaultFontFamily}
            >
              {currentFontFamily?.label || 'None'}
            </Button>
          </PanelSection>
          <PanelSection>
            <PanelHeadline>Font size</PanelHeadline>
            <Styled.ButtonGroup>
              {fontSizes.map((_fontSize, index) => {
                const isDefaultButton = _fontSize.key === 'font-size-default';
                const isMuted = !!isDefaultButton;
                const isActive = isDefaultButton && isDefaultFontSize ? true : currentFontSize?.key === _fontSize.key;

                const onClick = () => (isDefaultButton ? onSetFontSize(null) : onSetFontSize(_fontSize.value));

                return (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    $variant="quaternary"
                    $size="small"
                    onClick={onClick}
                    $active={isActive}
                    $muted={isMuted}
                    $isToggleButton
                  >
                    {_fontSize.label}
                  </Button>
                );
              })}
            </Styled.ButtonGroup>
          </PanelSection>
          <PanelSection>
            <PanelHeadline className="flex gap-2 items-center">Text color</PanelHeadline>
            <div className="flex items-center gap-[1px]">
              {themeColors.map((themeColor, index) => {
                return (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    $variant="quaternary"
                    $isColorTileButton
                    $active={color === themeColor.value}
                    onClick={() => onSetColor(themeColor.value)}
                    style={{
                      '--background': themeColor.value,
                    }}
                  />
                );
              })}
            </div>
            <div className="flex items-center gap-[1px] pt-0.5 border-t border-white border-opacity-10 mt-0.5">
              <Button
                $variant="quaternary"
                $isColorTileButton
                $active={isCustomColor}
                onClick={() => {
                  setIsColorPanelOpen(true);
                }}
                style={styles.customColorCSSVar}
              />
              <Button
                $variant="quaternary"
                $size="small"
                $isIconButton
                $leftSlot={<Icon name="Cancel" />}
                disabled={!color}
                onClick={() => onSetColor(null)}
              />
              {textColors.map((textColor, index) => {
                return (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    $variant="quaternary"
                    $isColorTileButton
                    $active={color === textColor}
                    onClick={() => onSetColor(textColor)}
                    style={{
                      '--background': textColor,
                    }}
                  />
                );
              })}
            </div>
          </PanelSection>
          {hasOverrides && (
            <PanelFooter>
              <Button
                $variant="quaternary"
                $size="small"
                $rightSlot={<Icon name="Reset" />}
                onClick={() => {
                  onSetColor(null);
                  onSetFontFamily(null);
                  onSetFontSize(null);
                }}
                $fullWidth
                $active
                $muted
              >
                Reset all
              </Button>
            </PanelFooter>
          )}
        </Panel>
      );
    };

    const renderFontFamilyPanel = () => {
      const defaultFontFamily = fontFamilies.find((_fontFamily) => {
        return _fontFamily.key === 'font-family-default';
      });

      return (
        <SearchList
          items={fontFamilies}
          searchable
          searchPlaceholder="Filter font family"
          onSelect={(item: SearchListItemProps) => {
            if (item.value === defaultFontFamily?.value) {
              onSetFontFamily(null);
            } else {
              onSetFontFamily(item.value);
            }
          }}
          onBack={() => {
            setIsFontFamilyPanelOpen(false);
          }}
          header={
            <Button
              $variant="quaternary"
              $size="small"
              $leftSlot={<Icon name="ChevronLeft" />}
              onClick={() => {
                setIsFontFamilyPanelOpen(false);
              }}
              $fullWidth
            >
              Font family
            </Button>
          }
        />
      );
    };

    return (
      <Tippy
        render={(attrs) => {
          if (isColorPanelOpen) {
            return renderColorPanel();
          }

          if (isFontFamilyPanelOpen) {
            return renderFontFamilyPanel();
          }

          return renderDefaultPanel(attrs);
        }}
        offset={[0, 8]}
        placement="bottom"
        trigger="click"
        disabled={isDisabled}
        interactive
        onShow={() => {
          setIsMainPanelOpen(true);
        }}
        onHidden={() => {
          setIsMainPanelOpen(false);
        }}
      >
        <div>
          <Tooltip enabled={!isMainPanelOpen} title="Text styles">
            <Button
              $variant="quaternary"
              $size="small"
              $leftSlot={<Icon name="Settings" />}
              $rightSlot={<Icon name="ChevronDown" $size="0.66rem" />}
              $active={hasOverrides || isMainPanelOpen}
              $muted={isMainPanelOpen && !hasOverrides}
              $isIconButton
              disabled={isDisabled}
            />
          </Tooltip>
        </div>
      </Tippy>
    );
  }
);

TextStylesPanel.displayName = 'TextStylesPanel';

export default TextStylesPanel;
