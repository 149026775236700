import { getText, mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { embeds as services, EmbedService } from '../../lib/constants';

import { EmbedInputView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embedInput: {
      setEmbedInput: (attributes: { service?: EmbedService }) => ReturnType;
    };
  }
}

const validateInput = (url: string): any => {
  const service = Object.entries(services).find(([, serviceSpecs]) => url.match(serviceSpecs.regex))?.[0] || undefined;

  return service;
};

export const EmbedInput = Node.create({
  name: 'embedInput',

  group: 'block',

  content: 'text*',

  marks: '',

  draggable: true,

  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  addAttributes() {
    return {
      service: {
        parseHTML: (element) => element.getAttribute('data-service'),
        renderHTML: (attributes) => ({
          'data-service': attributes.service,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const {
          state: {
            selection: { $from, $anchor },
          },
        } = editor;

        if ($from.parent.type !== this.type) {
          return false;
        }

        const url = getText($anchor.parent);
        const service = validateInput(url);

        if (url && service && service === 'generic') {
          // const position = editor.state.selection.$from.start();

          return (
            editor
              .chain()
              .setGenericEmbed({ url })
              // .focus(position - 1)
              .run()
          );
        }

        if (url && service) {
          return editor.chain().setServiceEmbed({ url, service }).setMeta('addToHistory', false).run();
        }

        return true;
      },
    };
  },

  addCommands() {
    return {
      setEmbedInput:
        ({ service = undefined }) =>
        ({ commands }) =>
          commands.setNode(this.name, { service }),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmbedInputView);
  },
});

export default EmbedInput;
