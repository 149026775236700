import { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import IconButton from '@/components/IconHelpers/IconButton';
import Text from '@/components/Text';
import { useEmailMessage, useEmailMessagePreview } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';

import EditMessageModal from './EditMessageModal';

interface Props {
  id?: string;
}

const EmailMessagePreview = ({ id }: Props) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: emailMessage } = useEmailMessage(currentPublicationId, {
    emailMessageId: id,
  });
  const { data } = useEmailMessagePreview(currentPublicationId, id);
  const [showEditMessageModal, setShowEditMessageModal] = useState(false);

  const isEmailMessageLoading = !emailMessage;

  const hasCreated = emailMessage?.preview_text !== null || emailMessage?.subject_line !== null;

  const handleClickEdit = () => setShowEditMessageModal(true);

  const containerClassName =
    'flex flex-col w-full rounded-md bg-gray-50 border-dashed border-gray-100 gap-y-4 overflow-hidden';

  return (
    <>
      {!isEmailMessageLoading && (
        <EditMessageModal
          emailMessage={emailMessage}
          isOpen={showEditMessageModal}
          onClose={() => setShowEditMessageModal(false)}
        />
      )}
      {hasCreated && (
        <div className={containerClassName}>
          <div className="flex items-center justify-between bg-gray-100 p-2 gap-x-4">
            <Text type="label" size="xs" className="flex-grow truncate">
              Subject: {emailMessage?.subject_line}
            </Text>
            <IconButton onClick={handleClickEdit}>
              <PencilSquareIcon />
            </IconButton>
          </div>
          {data?.html && (
            <div className="px-10 min-h-40 max-h-80">
              <iframe
                title={`email-${id}-preview`}
                className="w-full h-full rounded-md overflow-hidden"
                srcDoc={data.html}
                style={{
                  minHeight: '320px',
                }}
              />
            </div>
          )}
        </div>
      )}
      {!hasCreated && !isEmailMessageLoading && (
        <div className={cx(containerClassName, 'justify-center items-center')}>
          <Button
            variant="primary-inverse"
            className="w-full h-fit"
            onClick={handleClickEdit}
            Icon={PencilSquareIcon}
            loading={isEmailMessageLoading}
          >
            Create New Email
          </Button>
        </div>
      )}
    </>
  );
};

export default EmailMessagePreview;
