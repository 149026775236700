import { useCallback, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Tippy from '@tippyjs/react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import CustomColorPanel from '@/components/TiptapEditor/components/panels/CustomColor';
import UpdateFilePanel from '@/components/TiptapEditor/components/panels/UpdateFile/UpdateFilePanel';
import { UpdateImagePanel } from '@/components/TiptapEditor/components/panels/UpdateImage';
import { Panel, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import API from '@/components/TiptapEditor/lib/api';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { useThemeData } from '@/components/TiptapEditor/lib/hooks/useThemeData';
import { EditorColor } from '@/components/TiptapEditor/lib/types';
import { getFileSizeStringWithUnit } from '@/components/TiptapEditor/lib/utils/getFileSizeStringWithUnit';
import { getThemeFromBgColor } from '@/components/TiptapEditor/lib/utils/getThemeFromBgColor';
import styles from '@/components/TiptapEditor/styled';
import { useCurrentUser } from '@/context/current-user-context';
import { Asset } from '@/interfaces/asset';
import { useEditorContext } from '@/pages/Post/Edit/EditorContext';
import { getBackgroundColors } from '@/utils/documentColors';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';
import { AudioDataPanel } from '../panels/AudioDataPanel';
import { IAudioAttrs } from '../types';
import { useAudioUploader } from '../views/hooks';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const AudioMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const { publicationId } = usePublicationContext();
  const { currentUser } = useCurrentUser();
  const { setLoadingNodes } = useEditorContext();

  const menuRef = useRef<HTMLDivElement>(null);

  const tippyInstance = useRef<Instance | null>(null);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-audio');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('audio');

    return isActive;
  }, [editor]);

  const removeNode = useCallback(() => {
    const { from, to } = editor.state.selection;
    editor.commands.deleteRange({ from, to });
  }, [editor]);

  const attrs = editor.getAttributes('audio') as IAudioAttrs;

  const { backgroundColor, src, id, title } = attrs;

  const downloadAudio = useCallback(() => {
    if (src) {
      window.open(src, '_blank');
    }
  }, [src]);

  const setAudioReplacing = useCallback(
    (isLoading: boolean) => {
      setLoadingNodes((prev) => ({ ...prev, [id]: isLoading }));
    },
    [setLoadingNodes, id]
  );

  const { uploadAudioFile } = useAudioUploader({ publicationId, userId: currentUser?.id });

  const updateAudioAttributes = useCallback(
    (newAttrs: Partial<IAudioAttrs>) => {
      editor.commands.updateAttributes('audio', newAttrs);
    },
    [editor]
  );

  const handleFileChanged = useCallback(
    async (file: File) => {
      try {
        setAudioReplacing(true);

        const assetId = await uploadAudioFile(file);

        if (assetId) {
          const audioAttrs: Partial<IAudioAttrs> = {
            id: assetId,
            size: getFileSizeStringWithUnit(file.size),
            type: file.type,
            title: !title ? file.name.split('.').slice(0, -1).join('.') : title,
            src: '',
          };

          updateAudioAttributes(audioAttrs);
        }
      } catch (error) {
        toast.error('Failed to upload audio file');
      } finally {
        setAudioReplacing(false);
      }
    },
    [uploadAudioFile, setAudioReplacing, updateAudioAttributes, title]
  );

  const handleRemoveFile = useCallback(() => {
    const fileAttrs: Partial<IAudioAttrs> = {
      id: undefined,
      src: undefined,
      type: undefined,
    };

    editor.commands.updateAttributes('audio', fileAttrs);
  }, [editor]);

  const [isColorPanelOpen, setIsColorPanelOpen] = useState(false);

  const [isColorSelecorOpen, setIsColorSelecorOpen] = useState(false);

  const openSelectColorMenu = useCallback(() => {
    setIsColorSelecorOpen(true);
  }, []);

  const closeSelectColorMenu = useCallback(() => {
    setIsColorSelecorOpen(false);
  }, []);

  const themeColors = useThemeData('colors');

  const documentColors = editor.storage.documentColors?.colors as EditorColor[] | undefined;

  const backgroundColors = useMemo(() => {
    return getBackgroundColors(
      documentColors,
      themeColors.map((c) => c.value)
    );
  }, [themeColors, documentColors]);

  const setBackgroundColor = useCallback(
    (color?: string) => {
      const theme = color ? getThemeFromBgColor(color) : 'light';
      editor.commands.updateAttributes('audio', { backgroundColor: color, backgroundTheme: theme });
    },
    [editor]
  );

  const hasOverrides = useMemo(() => !!backgroundColor, [backgroundColor]);

  const renderDefaultColorPanel = (tippyAttrs: any = {}) => {
    return (
      <Panel tabIndex={-1} {...tippyAttrs} $width="fit-content">
        <PanelSection>
          <div className="flex items-center gap-[1px]">
            <Button
              $variant="quaternary"
              $isColorTileButton
              $active={backgroundColor && !themeColors.some(({ value }) => value === backgroundColor)}
              onClick={openSelectColorMenu}
              style={styles.customColorCSSVar}
            />

            {backgroundColors.map((color) => {
              return (
                <Button
                  // eslint-disable-next-line react/no-array-index-key
                  key={color}
                  $variant="quaternary"
                  $isColorTileButton
                  $active={backgroundColor === color}
                  onClick={() => setBackgroundColor(color)}
                  style={{
                    '--background': color,
                  }}
                />
              );
            })}

            {themeColors.map((themeColor, index) => {
              return (
                <Button
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  $variant="quaternary"
                  $isColorTileButton
                  $active={backgroundColor === themeColor.value}
                  onClick={() => setBackgroundColor(themeColor.value)}
                  style={{
                    '--background': themeColor.value,
                  }}
                />
              );
            })}

            <Button
              $variant="quaternary"
              $size="small"
              $isIconButton
              $leftSlot={<Icon name="Cancel" />}
              disabled={!backgroundColor}
              onClick={() => setBackgroundColor()}
            />
          </div>
        </PanelSection>
      </Panel>
    );
  };

  const renderColorSelectorPanel = useCallback(
    () => (
      <CustomColorPanel selectedColor={backgroundColor} onBack={closeSelectColorMenu} onSelect={setBackgroundColor} />
    ),
    [backgroundColor, closeSelectColorMenu, setBackgroundColor]
  );

  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const onUploadThumbnail = (file: File) => {
    setAudioReplacing(true);

    API.uploadPublicationAsset({
      file,
      publicationId,
    })
      .then((res) => {
        updateAudioAttributes({ thumbnailUrl: String(res.data.url) });
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setAudioReplacing(false));
  };

  const onReplaceThumbnail = (asset: Asset) => updateAudioAttributes({ thumbnailUrl: asset.url });

  const onRemoveThumbnail = () => updateAudioAttributes({ thumbnailUrl: '' });

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`audioMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()} ref={menuRef}>
        <div ref={buttonContainerRef} className="rounded-lg">
          <UpdateImagePanel
            tooltip="Upload or pick new image"
            parentRef={buttonContainerRef}
            onUpload={onUploadThumbnail}
            onReplace={onReplaceThumbnail}
            showRemoveOption={!!attrs.thumbnailUrl}
            onRemove={onRemoveThumbnail}
            offset={[-4, 8]}
            iconName="Image"
          />
        </div>

        <AudioDataPanel attrs={attrs} updateAttributes={updateAudioAttributes} parentRef={menuRef} />

        <Tippy
          content={isColorSelecorOpen ? renderColorSelectorPanel() : renderDefaultColorPanel()}
          placement="top"
          trigger="click"
          interactive
          onShow={() => {
            setIsColorPanelOpen(true);
          }}
          onHidden={() => {
            setIsColorPanelOpen(false);
          }}
          hideOnClick
          maxWidth="none"
        >
          <div>
            <Tooltip enabled={!isColorPanelOpen} title="Background color">
              <Button
                {...buttonProps}
                $active={isColorPanelOpen || hasOverrides}
                $muted={isColorPanelOpen && !hasOverrides}
                $leftSlot={<Icon name="ColorBucket" />}
              />
            </Tooltip>
          </div>
        </Tippy>

        <Tooltip title="Download audio">
          <Button {...buttonProps} $leftSlot={<Icon name="Download" />} onClick={downloadAudio} />
        </Tooltip>

        <UpdateFilePanel
          tooltip="Upload a new audio file"
          parentRef={menuRef}
          onFileChange={handleFileChanged}
          onRemove={handleRemoveFile}
          showRemoveOption
          accept="audio/mpeg, audio/wav, audio/ogg, audio/flac, audio/aac, audio/webm"
        />

        <Divider />

        <Tooltip title="Remove audio">
          <Button {...buttonProps} $leftSlot={<Icon name="Trash" />} onClick={removeNode} />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default AudioMenu;
