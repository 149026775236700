interface Props {
  className?: string;
}

const Fold = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_34_3338)">
      <path
        d="M8.70711 6.29289C9.09763 6.68342 9.09763 7.31658 8.70711 7.70711L7.41421 9L8.70711 10.2929C9.09763 10.6834 9.09763 11.3166 8.70711 11.7071C8.31658 12.0976 7.68342 12.0976 7.29289 11.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289L7.29289 6.29289C7.68342 5.90237 8.31658 5.90237 8.70711 6.29289Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H15C16.6569 18 18 16.6569 18 15V3C18 1.34315 16.6569 0 15 0H3ZM3 2C2.44772 2 2 2.44772 2 3V15C2 15.5523 2.44772 16 3 16H12C12.5523 16 13 15.5523 13 15V3C13 2.44772 12.5523 2 12 2H3Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_34_3338">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Fold;
