import React, { ReactNode, useEffect, useState } from 'react';
import Frame, { useFrame } from 'react-frame-component';
import { SpinnerGap } from '@phosphor-icons/react';
import { StyleSheetManager } from 'styled-components';

interface IframeWrapperProps {
  children: ReactNode;
  width: number;
  height: number;
  contentWidth: number;
  fontHrefs: Set<string>;
  iframeProps?: React.IframeHTMLAttributes<HTMLIFrameElement>;
}

const StyleManager = ({ children }: { children: React.ReactNode }) => {
  const { document } = useFrame();
  return (
    <StyleSheetManager target={document?.head}>
      <>{children}</>
    </StyleSheetManager>
  );
};

const IframeWrapper = React.forwardRef<HTMLIFrameElement, IframeWrapperProps>(
  ({ children, width, height, contentWidth, iframeProps, fontHrefs }, ref) => {
    const [initialContent, setInitialContent] = useState('');

    useEffect(() => {
      fetch('/canvas/index._html')
        .then((d) => d.text())
        .then(setInitialContent);
    }, []);

    const links = Array.from(new Set(fontHrefs)).map((href) => ({ rel: 'stylesheet', href }));

    if (!initialContent)
      return (
        <div className="w-full min-h-[400px] flex flex-col justify-center items-center p-16 bg-white">
          <SpinnerGap size={24} className="animate-spin text-wb-accent" />
        </div>
      );

    const scale = width / contentWidth;

    return (
      <Frame
        ref={ref}
        initialContent={initialContent}
        {...iframeProps}
        head={
          <>
            {links.map((link) => (
              <link key={link.href} rel={link.rel} href={link.href} />
            ))}
          </>
        }
        title="Page Editor Content"
        mountTarget="body"
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          width: contentWidth,
          height: height / scale,
          border: 'none',
          outline: 'none',
          ...iframeProps?.style,
        }}
      >
        <StyleManager>{children}</StyleManager>
      </Frame>
    );
  }
);

export default IframeWrapper;
