import { ForwardedRef, forwardRef, useState } from 'react';
import moment from 'moment-mini';

import SplitButton from '@/components/SplitButton';
import useOpportunityStats from '@/hooks/useAdNetwork/internal/useOpportunityStats';
import {
  AdNetworkDisbursementStatus,
  AdNetworkDisbursementStatusLabelHash,
} from '@/interfaces/ad_network/internal/disbursement/types';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';

import { StatusBadge } from '../StatusBadge';

import DisbursementApproveModal from './DisbursementApproveModal';
import DisbursementImmediatePaymentModal from './DisbursementImmediatePaymentModal';
import DisbursementManualModal from './DisbursementManualModal';
import DisbursementPaidManuallyModal from './DisbursementPaidManuallyModal';
import { DisbursementRowProps } from './types';

const DisbursementRow = forwardRef((props: DisbursementRowProps, ref: ForwardedRef<HTMLTableRowElement>) => {
  const { disbursement } = props;
  const { opportunity } = disbursement;
  const { publication, advertiser, campaign } = opportunity;
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [manualModalOpen, setManualModalOpen] = useState(false);
  const [paidManuallyModalOpen, setPaidManuallyModalOpen] = useState(false);
  const [immediatePaymentModalOpen, setImmediatePaymentModalOpen] = useState(false);
  const numberFormatter = new Intl.NumberFormat();
  const { data: stats } = useOpportunityStats({ id: opportunity.id });
  const manualPayoutDisabled =
    disbursement.status !== AdNetworkDisbursementStatus.AWAITING_APPROVAL &&
    disbursement.status !== AdNetworkDisbursementStatus.APPROVED;
  const autoPayoutDisabled =
    disbursement.status !== AdNetworkDisbursementStatus.AWAITING_APPROVAL &&
    disbursement.status !== AdNetworkDisbursementStatus.MANUAL;

  const isCpc = opportunity.payout_model === AdNetworkPayoutModel.CPC;
  const payoutRate = isCpc ? opportunity.payout_per_click : opportunity.payout_per_mille;

  const approvalButtonActions = [
    ...(autoPayoutDisabled
      ? []
      : [
          {
            label: 'Approve',
            helperText: 'Approve for automatic payout',
            onClick: () => setApprovalModalOpen(true),
            defaultSelected: disbursement.status === AdNetworkDisbursementStatus.AWAITING_APPROVAL,
          },
        ]),
    ...(manualPayoutDisabled
      ? []
      : [
          {
            label: 'Manual Payout',
            helperText: 'Payment is being made outside of Stripe',
            onClick: () => setManualModalOpen(true),
          },
        ]),
    ...(disbursement.status === AdNetworkDisbursementStatus.APPROVED
      ? [
          {
            label: 'Pay Now',
            helperText: 'Immediately pay out this disbursement',
            onClick: () => setImmediatePaymentModalOpen(true),
          },
        ]
      : []),
    ...(disbursement.status === AdNetworkDisbursementStatus.MANUAL
      ? [
          {
            label: 'Mark as Paid',
            helperText: 'Mark as paid manually',
            onClick: () => setPaidManuallyModalOpen(true),
            defaultSelected: disbursement.status === AdNetworkDisbursementStatus.MANUAL,
          },
        ]
      : []),
  ];

  return (
    <tr key={disbursement.id} ref={ref}>
      {/* PUBLICATION */}
      <td className="px-4 py-2">
        <p>
          {publication.name}
          {publication.banned && <span className="text-red-500"> (banned)</span>}
        </p>
        <p className="text-xs text-gray-500">{publication.owner_email}</p>
        <p className="text-xs text-gray-500">
          {numberFormatter.format(publication.active_subscription_count)} Subscriptions
        </p>
      </td>
      {/* ADVERTISER */}
      <td className="px-4 py-2">
        <p>{advertiser.name}</p>
        <p className="text-xs text-gray-500">{campaign.name}</p>
      </td>
      {/* STATUS */}
      <td>
        <p className="text-xs text-gray-500">Date Sent</p>
        <p>{moment(disbursement.placement.date_sent).format('LL')}</p>
        <StatusBadge
          status={AdNetworkDisbursementStatusLabelHash[disbursement.status]}
          payoutPlatform={disbursement.payout_platform}
        />
      </td>
      {/* RATES */}
      <td className="px-4 py-2">
        <p>
          CTR: <strong>{stats?.opportunity_click_rate || 0}%</strong>
        </p>
        <p>
          Open: <strong>{stats?.post_open_rate || 0}%</strong>
        </p>
      </td>
      {/* CLICKS */}
      <td className="px-4 py-2">
        <p>
          Total: <strong>{numberFormatter.format(stats?.total_eligible_clicked || 0)}</strong>
        </p>
        <p>
          Unique: <strong>{numberFormatter.format(stats?.total_unique_eligible_clicked || 0)}</strong>
        </p>
        {stats?.total_unique_eligible_clicked !== disbursement.approved_clicks && (
          <p>
            Adjusted: <strong>{numberFormatter.format(disbursement.approved_clicks || 0)}</strong>
          </p>
        )}
      </td>
      {/* Opens */}
      <td className="px-4 py-2">
        <p>
          Unique: <strong>{numberFormatter.format(stats?.total_unique_eligible_opened || 0)}</strong>
        </p>
      </td>
      {/* PAYOUT */}
      <td className="px-4 py-2">
        <p>
          Type: <strong className='uppercase'>{opportunity.payout_model}</strong>
        </p>
        <p>
          Est Payout: <strong>{opportunity.estimated_payout}</strong>
        </p>
        <p>
          Publisher <span className="uppercase">{opportunity.payout_model}</span>: <strong>{payoutRate}</strong>
        </p>
        {disbursement.amount !== disbursement.approved_amount ? (
          <p>
            Adjusted Payout: <strong>{disbursement.approved_amount}</strong>
          </p>
        ) : (
          <p>
            Actual Payout: <strong>{disbursement.approved_amount}</strong>
          </p>
        )}
      </td>
      {/* ACTION */}
      <td className="px-4 py-2">
        <SplitButton variant="primary" buttons={approvalButtonActions} />
      </td>
      <DisbursementApproveModal
        disbursement={disbursement}
        modalOpen={approvalModalOpen}
        onClose={() => setApprovalModalOpen(false)}
      />
      <DisbursementManualModal
        disbursement={disbursement}
        modalOpen={manualModalOpen}
        onClose={() => setManualModalOpen(false)}
      />
      <DisbursementPaidManuallyModal
        disbursement={disbursement}
        modalOpen={paidManuallyModalOpen}
        onClose={() => setPaidManuallyModalOpen(false)}
      />
      <DisbursementImmediatePaymentModal
        disbursement={disbursement}
        modalOpen={immediatePaymentModalOpen}
        onClose={() => setImmediatePaymentModalOpen(false)}
      />
    </tr>
  );
});

export default DisbursementRow;
