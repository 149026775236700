import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { BLACK_COLOR, DEFAULT_BORDER_COLOR, DEFAULT_BORDER_RADIUS, WHITE_COLOR } from '../constants';

import { TagsView } from './views/TagsView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    tags: {
      setTags: () => ReturnType;
    };
  }
}

type TagsCardSchema = {
  node: string;
  className: string;
  children: TagsCardSchema[];
};

const cardSchema: TagsCardSchema = {
  node: 'div',
  className: 'flex flex-col gap-2',
  children: [
    {
      node: 'name',
      className: '',
      children: [],
    },
    {
      node: 'description',
      className: '',
      children: [],
    },
  ],
};

export const Tags = Node.create<{}, {}>({
  name: 'tags',

  group: 'block',

  draggable: true,

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      id: {
        default: '',
        renderHTML: (attributes) => ({ 'data-id': attributes.id }),
        parseHTML: (element) => element.getAttribute('data-id') || '',
      },
      insertedFromSidebar: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-inserted-from-sidebar'),
        renderHTML: (attributes) => ({ 'data-inserted-from-sidebar': attributes.insertedFromSidebar }),
      },
      hasFakeData: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-has-fake-data'),
        renderHTML: (attributes) => ({ 'data-has-fake-data': attributes.hasFakeData }),
      },
      gap: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      alignment: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({ 'data-alignment': attributes.alignment }),
      },
      shadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      columns: {
        default: '3',
        parseHTML: (element) => element.getAttribute('data-columns'),
        renderHTML: (attributes) => ({ 'data-columns': attributes.columns }),
      },
      borderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
        parseHTML: (element) => element.getAttribute('data-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      borderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '1px',
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_BORDER_COLOR,
      },
      borderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },
      padding: {
        default: '16px',
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
        parseHTML: (element) => JSON.parse(element.getAttribute('data-padding') || '4px'),
      },
      backgroundColor: {
        default: WHITE_COLOR,
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
        parseHTML: (element) => element.getAttribute('data-background-color') || WHITE_COLOR,
      },
      nameColor: {
        default: BLACK_COLOR,
        renderHTML: (attributes) => ({ 'data-name-color': attributes.nameColor }),
        parseHTML: (element) => element.getAttribute('data-name-color') || BLACK_COLOR,
      },
      nameFontFamily: {
        default: 'Inter',
        renderHTML: (attributes) => ({ 'data-name-font-family': attributes.nameFontFamily }),
        parseHTML: (element) => element.getAttribute('data-name-font-family') || 'Inter',
      },
      nameFontSize: {
        default: 'text-base',
        renderHTML: (attributes) => ({ 'data-name-font-size': attributes.nameFontSize }),
        parseHTML: (element) => element.getAttribute('data-name-font-size') || 'text-sm',
      },
      descriptionEnabled: {
        default: true,
        renderHTML: (attributes) => ({ 'data-description-enabled': attributes.descriptionEnabled }),
        parseHTML: (element) => element.getAttribute('data-description-enabled') || 'true',
      },
      descriptionColor: {
        default: BLACK_COLOR,
        renderHTML: (attributes) => ({ 'data-description-color': attributes.descriptionColor }),
        parseHTML: (element) => element.getAttribute('data-description-color') || BLACK_COLOR,
      },
      descriptionFontFamily: {
        default: 'Inter',
        renderHTML: (attributes) => ({ 'data-description-font-family': attributes.descriptionFontFamily }),
        parseHTML: (element) => element.getAttribute('data-description-font-family') || 'Inter',
      },
      descriptionFontSize: {
        default: 'text-sm',
        renderHTML: (attributes) => ({ 'data-description-font-size': attributes.descriptionFontSize }),
        parseHTML: (element) => element.getAttribute('data-description-font-size') || 'text-sm',
      },
      cardStructure: {
        default: cardSchema,
        parseHTML: (element) => element.getAttribute('data-card-structure'),
        renderHTML: (attributes) => ({ 'data-card-structure': attributes.cardStructure }),
      },
      data: {
        default: [],
        parseHTML: (element) => JSON.parse(element.getAttribute('data-data') || '[]'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setTestimonials:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TagsView);
  },
});

export default Tags;
