import Text from '../Text';

interface Props {
  value: string;
}

const Description = ({ value }: Props) => {
  return (
    <Text size="sm" type="body" className="text-gray-500">
      {value}
    </Text>
  );
};

export default Description;
