import cx from 'classnames';

import { Icon } from '../Icon';

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  checked?: boolean;
}

const Checkbox = ({ checked, id, name, onChange }: Props) => {
  return (
    <div className="relative">
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="opacity-0 absolute top-0 left-0"
      />
      <div
        className={cx('w-4 h-4 rounded-md flex items-center justify-center', {
          'bg-white bg-opacity-10': !checked,
          'bg-pink-500': checked,
        })}
      >
        {checked && <Icon name="Check" />}
      </div>
    </div>
  );
};

export default Checkbox;
