import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import ActionModal from '../../../../components/ActionModal';
import { Input } from '../../../../components/Form';
import { Button } from '../../../../ui/Button';

import FeaturesItem from './FeaturesItem';

interface Props {
  features: string[];
  onUpdate(newFeaturesList: string[]): void;
}

// This is a somewhat arbitrary number. We want to allow for flexibility in the number of features but want to have some sane limit on it.
const MAX_FEATURES = 20;

const Features = ({ features, onUpdate }: Props) => {
  const featuresCount = features.length;
  const canAddMoreFeatures = featuresCount < MAX_FEATURES;

  const [featureToEdit, setFeatureToEdit] = useState<any>('');
  const [possibleEdits, setPossibleEdits] = useState<any>('');
  const [featureToDelete, setFeatureToDelete] = useState<any>('');
  const [newFeature, setNewFeature] = useState<any>('');

  const [list, setList] = useState<any>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (features) {
      setList(features);
    }
  }, []);

  const handleDelete = () => {
    const newList = list.filter((feature: string) => feature !== featureToDelete);
    setList(newList);
    onUpdate(newList);
    setFeatureToDelete('');
  };

  const handleAddFeature = () => {
    // Prevent empty strings from being added
    if (!newFeature.trim()) {
      return;
    }

    if (newFeature) {
      const newList = [...list, newFeature];
      setList(newList);
      onUpdate(newList);
      setNewFeature('');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleEditFeature = () => {
    if (!possibleEdits.trim()) {
      return toast.error('Please enter a valid feature');
    }

    if (possibleEdits) {
      const newList = list.map((feature: string) => {
        if (feature === featureToEdit) {
          return possibleEdits;
        }
        return feature;
      });
      setList(newList);
      onUpdate(newList);
      setFeatureToEdit('');
      setPossibleEdits('');
    }
  };

  const onDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activePostitionId = list.find((position: any) => position === active.id);
      const overPostitionId = list.find((position: any) => position === over.id);
      const oldIndex = list.indexOf(activePostitionId);
      const newIndex = list.indexOf(overPostitionId);
      const newList = arrayMove(list, oldIndex, newIndex);
      const filteredList = newList.filter((item: any) => Boolean(item.trim())); // Remove empty strings
      setList(filteredList);
      onUpdate(filteredList as string[]);
    }
  };

  const renderAddFeatureForm = () => {
    if (canAddMoreFeatures) {
      return (
        <div className="pt-4 space-y-2">
          <Input
            name="new_feature"
            value={newFeature}
            onChange={(e) => setNewFeature(e.target.value)}
            placeholder="Add a new feature"
          />
          <Button variant="primary" onClick={handleAddFeature}>
            Add
          </Button>
        </div>
      );
    }

    return <span className="mt-2 text-xs text-red-500">Please remove at least 1 feature before adding another.</span>;
  };

  if (!list || list.length === 0) {
    return renderAddFeatureForm();
  }

  return (
    <>
      <ActionModal
        isOpen={Boolean(featureToDelete)}
        onClose={() => setFeatureToDelete('')}
        onProceed={handleDelete}
        resourceId=""
        isWorking={false}
        headerText="Delete Feature"
        actionText="Delete"
        buttonType="danger"
      >
        <div className="space-y-2 text-sm">
          Are you sure you want to delete this feature? You can always add it back.
        </div>
      </ActionModal>
      <ActionModal
        isOpen={Boolean(featureToEdit)}
        onClose={() => setFeatureToEdit('')}
        onProceed={handleEditFeature}
        resourceId=""
        isWorking={false}
        headerText="Edit Feature"
        actionText="Update"
      >
        <div className="space-y-2 text-sm">
          <Input
            name="new_feature"
            value={possibleEdits}
            onChange={(e) => setPossibleEdits(e.target.value)}
            placeholder="Edit Feature"
          />
        </div>
      </ActionModal>

      <div>
        <div className="bg-white border border-gray-200 shadow sm:rounded-md relative curser-pointer mt-2">
          <div className="divide-y divide-gray-200">
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
              <SortableContext items={list} strategy={verticalListSortingStrategy}>
                {list.map((item: string) => {
                  return (
                    <FeaturesItem
                      key={item}
                      lisItem={item}
                      onRemoveItem={() => setFeatureToDelete(item)}
                      setTestimonialToEdit={() => {
                        setFeatureToEdit(item);
                        setPossibleEdits(item);
                      }}
                    >
                      <div className="flex space-x-4">
                        <div className="flex items-center space-x-1">
                          <div className="w-4 h-4">
                            <svg
                              clipRule="evenodd"
                              fillRule="evenodd"
                              strokeLinejoin="round"
                              strokeMiterlimit="2"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              className="opacity-20"
                            >
                              <path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                              <path d="m20 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                            </svg>
                          </div>
                        </div>

                        <div>
                          <p className="text-sm font-medium text-gray-800 truncate max-w-sm">{item}</p>
                        </div>
                      </div>
                    </FeaturesItem>
                  );
                })}
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </div>

      {renderAddFeatureForm()}
    </>
  );
};

export default Features;
