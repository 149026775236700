import { useEffect, useState } from 'react';

import { Testimonial } from '@/interfaces/testimonial';

import { ImageInput } from '../../../_components/UI/ImageInput';
import { Input } from '../../../_components/UI/Input';
import Modal from '../../../_components/UI/Modal';
import { Textarea } from '../../../_components/UI/Textarea';

interface Props {
  testimonial?: Testimonial | null;
  isSubmitting: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (payload: { name: string; description: string; message: string; picture: string | File | any }) => void;
}

const TestimonialModal = ({ testimonial, onClose, onSubmit, isSubmitting, isOpen }: Props) => {
  const [nameValue, setNameValue] = useState(testimonial?.name || '');
  const [descriptionValue, setDescriptionValue] = useState(testimonial?.description || '');
  const [messageValue, setMessageValue] = useState(testimonial?.message || '');
  const [picture, setPicture] = useState<string | File | any>(testimonial?.picture_url || null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (testimonial && !mounted) {
      setNameValue(testimonial.name);
      setDescriptionValue(testimonial.description);
      setMessageValue(testimonial.message);
      setPicture(testimonial.picture_url);
    }
  }, [testimonial, mounted]);

  const handleClear = () => {
    setNameValue('');
    setDescriptionValue('');
    setMessageValue('');
    setPicture(null);
    setMounted(false);
  };

  const handleSubmit = () => {
    onSubmit({
      name: nameValue,
      description: descriptionValue,
      message: messageValue,
      picture,
    });
    handleClear();
  };

  const title = testimonial ? 'Edit Testimonial' : 'Create Testimonial';
  const ctaText = testimonial ? 'Save' : 'Create';
  const isLoadingText = testimonial ? 'Saving...' : 'Creating...';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      onConfirm={handleSubmit}
      isLoading={isSubmitting}
      ctaText={ctaText}
      isLoadingText={isLoadingText}
    >
      <div className="flex flex-col space-y-4">
        <ImageInput
          labelText="Image"
          value={picture}
          onSave={(file) => {
            setPicture(file);
          }}
          width={100}
          height={100}
          onRemove={() => {
            setPicture(null);
          }}
        />
        <Input
          name="testimonial_name"
          labelText="Name"
          placeholder="New testimonial"
          value={nameValue}
          onChange={(e) => {
            setNameValue(e.target.value);
          }}
        />
        <Input
          name="testimonial_description"
          labelText="Description"
          placeholder="Ex: CEO of Company Name"
          helperText="This can be a job title, description, or even a social media handle that gives credibility and context to the testimonial."
          value={descriptionValue}
          onChange={(e) => {
            setDescriptionValue(e.target.value);
          }}
        />
        <Textarea
          name="testimonial_message"
          labelText="Message"
          placeholder="Ex: I love this product because..."
          value={messageValue}
          onChange={(e) => {
            setMessageValue(e.target.value);
          }}
        />
      </div>
    </Modal>
  );
};

export default TestimonialModal;
