import { TopLineMetrics } from '@/interfaces/boosts/top_line_metrics';

const useFormattedTopLineMetrics = (topLineMetrics?: TopLineMetrics) => {
  if (!topLineMetrics) {
    return {
      acceptanceRate: '-',
      pendingSpend: '-',
      totalSpend: '-',
      averageCPA: '-',
    };
  }

  return {
    acceptanceRate: typeof topLineMetrics.acceptance_rate === 'number' ? `${topLineMetrics.acceptance_rate}%` : '-',
    pendingSpend: topLineMetrics.total_pending_payments || '-',
    totalSpend: topLineMetrics.total_payments || '-',
    averageCPA: topLineMetrics.average_cpa || '-',
  };
};

export default useFormattedTopLineMetrics;
