import { useQuery } from 'react-query';

import { IOnboarding } from '../interfaces/onboarding';
import api from '../services/swarm';

const useOnboarding = (publicationId: string, onboardingId?: string) => {
  return useQuery<IOnboarding>(
    ['onboarding', publicationId],
    () =>
      api
        .get(`/onboarding/${onboardingId}`, {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled: Boolean(onboardingId),
    }
  );
};

export default useOnboarding;
