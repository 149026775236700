import TierSelect from '@/components/TierSelect';
import { AutomationCondition, AutomationConditions } from '@/interfaces/automations/automation_condition';
import { LogicalOperators } from '@/interfaces/condition';

interface Props {
  mainConditions: AutomationConditions;
  onChange: (mainConditions: AutomationConditions) => void;
}

export const UpgradeTrigger = ({ mainConditions, onChange }: Props) => {
  const condition = mainConditions.conditions?.[0];

  const handleChange = (_name: string, selectedValue: string | string[]) => {
    onChange({
      logical_operator: LogicalOperators.AND,
      conditions: [{ ...condition, value: selectedValue } as AutomationCondition],
    });
  };

  return <TierSelect tierId={condition?.value || ''} onChange={handleChange} />;
};
