import React, { useState } from 'react';

import { Input } from '@/components/Form';
import RichContent from '@/components/RichContent';
import { useDisableRefetchOnFocus } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';

import useFetchPreview from '../_hooks/useFetchPreview';
import useUpdateOptInEmail from '../_hooks/useUpdateOptInEmail';
import { useOptInEmailContext } from '../_layout';

const OptInEmailEdit: React.FunctionComponent = () => {
  useDisableRefetchOnFocus();

  const currentPublicationId = useCurrentPublicationId();
  const { optInEmail } = useOptInEmailContext();

  const [subjectLine, setSubjectLine] = useState(optInEmail.subject_line || undefined);
  const [previewText, setPreviewText] = useState(optInEmail.preview_text || undefined);
  const [tiptapState, setTiptapState] = useState(optInEmail.tiptap_state);

  const updateMutation = useUpdateOptInEmail(currentPublicationId, optInEmail.id);
  const onFetchPreview = useFetchPreview(tiptapState);

  if (!currentPublicationId) {
    return null;
  }

  return (
    <div className="mx-auto">
      <div className="bg-white rounded-lg mt-12 py-6 px-4 sm:p-6">
        <div className="flex flex-col lg:flex-row">
          <div className="flex-grow space-y-6">
            <Input
              name="subject_line"
              labelText="Subject Line"
              value={subjectLine}
              onChange={(e) => setSubjectLine(e.target.value)}
            />
            <Input
              name="preview_text"
              labelText="Preview Text"
              value={previewText}
              onChange={(e) => setPreviewText(e.target.value)}
            />
            <RichContent
              name="content"
              labelText="Content"
              publicationId={currentPublicationId}
              initialValue={optInEmail.tiptap_state}
              onChange={setTiptapState}
              onFetchPreview={onFetchPreview}
              description="Double opt-in emails will always contain a button for subscription confirmation that redirects to your beehiiv homepage or custom redirect URL"
              allowPolls
            />
            <div className="flex justify-end">
              <Button
                loading={updateMutation.isLoading}
                onClick={() => {
                  updateMutation.mutate({
                    subject_line: subjectLine,
                    preview_text: previewText,
                    tiptap_state: tiptapState,
                  });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptInEmailEdit;
