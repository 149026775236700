import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  automationId: string;
  automationStepId: string;
  isEnabled?: boolean;
}

const useAutomationStepMetrics = ({ automationId, automationStepId, isEnabled = true }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchInsights = () =>
    api
      .get(`/automation_steps/${automationStepId}/performance_metrics`, {
        params: {
          automation_id: automationId,
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery(
    ['automations', automationId, 'automation_steps', automationStepId, 'performance_metrics'],
    fetchInsights,
    {
      retryOnMount: false,
      enabled: isEnabled,
    }
  );
};

export default useAutomationStepMetrics;
