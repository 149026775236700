import moment from 'moment-mini';

import pluralize from '@/utils/pluralize';

export const formatMinutes = (
  minutes: number | null
): {
  label: string;
  value: string;
} => {
  if (!minutes || minutes < 0) {
    return {
      label: 'minutes',
      value: '-',
    };
  }

  if (minutes < 60) {
    return {
      label: pluralize('minute', minutes, undefined, true),
      value: minutes.toFixed(2),
    };
  }

  const minutesDuration = moment.duration(minutes, 'minutes');

  if (minutes < 1440) {
    return {
      label: pluralize('hour', minutesDuration.asHours(), undefined, true),
      value: minutesDuration.asHours().toFixed(2),
    };
  }

  return {
    label: pluralize('day', minutesDuration.asDays(), undefined, true),
    value: minutesDuration.asDays().toFixed(2),
  };
};
