import { X } from '@phosphor-icons/react';

import { Text } from '../../../../UI/Text';

interface Props {
  id: string;
  name: string;
  onClick: (id: string) => void;
}

const SelectedRecommendationButton = ({ id, name, onClick }: Props) => {
  return (
    <button
      type="button"
      key={id}
      className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1"
      onClick={() => onClick(id)}
    >
      <Text weight="regular" variant="accent" size="3xs" as="span" className="line-clamp-1 max-w-[150px] truncate">
        {name}
      </Text>
      <X className="w-3 h-3" />
    </button>
  );
};

export default SelectedRecommendationButton;
