import { CustomFieldKind } from '@/interfaces/custom_field';

export const useDisplayCustomFieldKind = () => (kind: CustomFieldKind) => {
  switch (kind) {
    case CustomFieldKind.BOOLEAN:
      return 'True or False';
    case CustomFieldKind.DATE:
      return 'Date';
    case CustomFieldKind.DATETIME:
      return 'Date & Time';
    case CustomFieldKind.INTEGER:
      return 'Number';
    default:
      return 'Text';
  }
};
