import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

import ActionModal from '@/components/ActionModal';
import { Typography, TypographyStack } from '@/components/Typography';
import { Tier } from '@/interfaces/tier';

import useDeleteTier from '../../_hooks/useDeleteTier';

interface Props {
  tier: Tier;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteTierModal: React.FC<Props> = ({ tier, isOpen, onClose }) => {
  const { mutate, isLoading } = useDeleteTier(tier.id);

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      isWorking={isLoading}
      onProceed={() => mutate()}
      resourceId={tier.id}
      buttonType="danger"
      actionText="Delete Tier"
    >
      <div className="flex space-x-4">
        <div className="p-[10px] bg-feedback-danger-50 rounded-full w-fit h-fit">
          <ExclamationCircleIcon className="w-5 h-5 text-feedback-danger-600" />
        </div>
        <TypographyStack>
          <Typography token="font-medium/text/lg">Delete Tier</Typography>
          <Typography token="font-normal/text/sm">
            Are you sure you want to permanently delete this tier? You can&apos;t undo this action.
          </Typography>
        </TypographyStack>
      </div>
    </ActionModal>
  );
};

export default DeleteTierModal;
