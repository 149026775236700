import { Info } from "@phosphor-icons/react";

import { Text } from "@/routes/website/_components/UI/Text";

export default function VersionHistoryNotice() {
  return <div
    className="fixed bottom-[28px] left-1/2 -translate-x-1/2 flex items-center justify-center gap-2 px-4 py-3 rounded-2xl bg-wb-primary border border-wb-primary border-solid shadow-xl"
  >
    <Info size={16} weight="fill" className="text-wb-accent" />
    <Text size="sm" weight="medium">
      Previous versions are preview only, but you can still copy elements
    </Text>
  </div>
}
