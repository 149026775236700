import cx from 'classnames';

import { Typography, TypographyStack } from '@/components/Typography';
import { Card } from '@/ui/Card';

type Props = React.PropsWithChildren<{
  title: string;
  description?: React.ReactNode;
  scrollToId?: string;
  titleAction?: React.ReactNode;
  className?: string;
  maxWidthClassName?: string;
}>;

const Section: React.FC<Props> = ({
  title,
  titleAction,
  description,
  scrollToId,
  className,
  children,
  maxWidthClassName = 'max-w-2xl',
}) => {
  return (
    <Card id={scrollToId} className={cx('w-full p-8', className, maxWidthClassName)}>
      <TypographyStack gap="2" className="mb-6">
        <div className="flex justify-between">
          <Typography token="font-medium/text/lg">{title}</Typography>
          {titleAction && titleAction}
        </div>
        {description && (
          <Typography token="font-normal/text/sm" colorWeight="700">
            {description}
          </Typography>
        )}
      </TypographyStack>
      {children}
    </Card>
  );
};

export default Section;
