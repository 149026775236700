import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath, MarkerType, useReactFlow } from 'reactflow';
import cx from 'classnames';

import useResourcePermissions from '@/hooks/useResourcePermissions/useResourcePermissions';

import { AutomationStepStepType } from '../../../../interfaces/automations/automation_step';
import SelectNodeTypeButton from '../components/SelectNodeTypeButton';
import { AUTOMATION_EDGE_WIDTH_CLASSNAME, BRANCH_ARM_LABELS } from '../constants';
import { useNewAutoLayoutContext } from '../context/new-layout-context';
import useMaxAllowedSteps from '../hooks/useMaxAllowedSteps';
import { getNodeDimensions } from '../hooks/useNewLayout';
import { CustomNodeDataType } from '../types';
import { REFERRING_AUTOMATIONS_NODE_ID, TRIGGERS_NODE_ID } from '../utils/getNodesAndEdges';

const CustomEdge = ({
  source,
  sourceX,
  sourceY,
  target,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
}: EdgeProps) => {
  const { getNode } = useReactFlow<CustomNodeDataType>();
  const hasMaxSteps = useMaxAllowedSteps();
  const { automation } = useNewAutoLayoutContext();
  const {data: recordPermissions, isSuccess: recordPermissionSuccess} = useResourcePermissions({
    resourceType: 'Automation',
    resourceId: automation.id,
    policyClass: "Api::V2::AutomationPolicy",
  });

  const canAddNew = recordPermissionSuccess && recordPermissions.includes('update');

  const targetNode = getNode(target);
  const sourceNode = getNode(source);
  const { branchArm } = targetNode?.data || {};
  const isBetweenNodes = targetNode?.type !== AutomationStepStepType.ADD_AUTOMATION_STEP;
  const isSourceBranch = sourceNode?.data?.stepType === AutomationStepStepType.BRANCH;
  const isReferringAutomationsEdge = sourceNode?.type === 'referringAutomations';
  const shouldShowAddButton = canAddNew && !hasMaxSteps && isBetweenNodes && !isSourceBranch && !isReferringAutomationsEdge;

  // TODO: extract in new Edge component
  let newTargetY = targetY;
  if (isReferringAutomationsEdge) {
    const triggersHeight = getNodeDimensions(TRIGGERS_NODE_ID).height;
    const referringAutomationsHeight = getNodeDimensions(REFERRING_AUTOMATIONS_NODE_ID).height;

    const heightDiff = Math.abs(triggersHeight - referringAutomationsHeight);
    const buttonHeight = 18;
    const edgeHeight = 120 + buttonHeight;

    if (referringAutomationsHeight > triggersHeight) {
      newTargetY = sourceY - heightDiff + edgeHeight / 2;
    } else {
      newTargetY = targetY + heightDiff + buttonHeight - (edgeHeight / 4) * 3;
    }
  }

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY: newTargetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={MarkerType.Arrow}
        style={{ ...style, pointerEvents: 'none' }}
        labelX={labelX}
        labelY={labelY}
        interactionWidth={0}
      />

      <EdgeLabelRenderer>
        <div
          style={{ transform: `translate(-50%, 0%) translate(${labelX}px,${labelY}px)` }}
          className={cx(
            'absolute pointer-events-auto',
            'flex justify-center items-center',
            AUTOMATION_EDGE_WIDTH_CLASSNAME
          )}
        >
          {targetNode?.data.branchArm && isSourceBranch && (
            <div
              className={cx('py-1 px-2 w-fit text-white text-sm bg-gray-500 rounded-lg')}
              style={{
                transform: `translate(0%, -50%)`,
              }}
            >
              {BRANCH_ARM_LABELS[targetNode.data.branchArm]}
            </div>
          )}
          {shouldShowAddButton && (
            <SelectNodeTypeButton sourceNode={sourceNode} targetNode={targetNode} branchArm={branchArm} />
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
