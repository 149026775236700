import { useState } from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

import { StripeAccountStatus, StripeAccountType } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import ConnectToStripe from '@/pages/Monetize/Boosts/components/ConnectToStripe';
import { Button } from '@/ui/Button';

interface Props {
  publicationId: string;
  boostsAccountStatus: StripeAccountStatus | undefined;
  boostsAccountType: StripeAccountType | undefined | null;
}

const Connect = ({ publicationId, boostsAccountStatus, boostsAccountType }: Props) => {
  const [isConnectingToStripe, setIsConnectingToStripe] = useState<boolean>(false);
  const { data: settings } = usePublicationSettings(publicationId);

  if (boostsAccountType || boostsAccountStatus !== StripeAccountStatus.MISSING) return null;
  if (!settings?.wallet) return null;

  return (
    <>
      <ConnectToStripe
        publicationId={publicationId}
        boostsAccountStatus={boostsAccountStatus}
        isOpen={isConnectingToStripe}
        onClose={() => setIsConnectingToStripe(false)}
      />
      <Button
        className="w-fit"
        variant="primary"
        type="submit"
        size="xs"
        loading={isConnectingToStripe}
        disabled={isConnectingToStripe}
        onClick={() => setIsConnectingToStripe(true)}
        Icon={ArrowUpRightIcon}
        iconRight
      >
        Connect New Express Account
      </Button>
    </>
  );
};

export default Connect;
