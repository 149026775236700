import { ElementType, Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Cog8ToothIcon, ExclamationCircleIcon, FilmIcon, MegaphoneIcon, SwatchIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import IconWrapper from '@/components/IconHelpers/IconWrapper';
import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

interface Props {
  text: string;
  Icon: ElementType;
  href: string;
}

const SupportHelper = ({ text, Icon, href }: Props) => {
  return (
    <a href={href} target="_blank" rel="noreferrer noopener" className="cursor-pointer">
      <div className="mx-auto flex flex-col items-center space-y-2 bg-surface-100 hover:bg-surface-200 p-4 rounded-md">
        <div className="w-fit">
          <IconWrapper Icon={Icon} variant="secondary" type="solid" />
        </div>
        <Typography token="font-semibold/text/sm">{text}</Typography>
      </div>
    </a>
  );
};

const CommandPallete = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);

  const filterableOptionItems = useMemo(() => {
    return [
      {
        id: 1,
        name: 'Set My Global Styles',
        description: 'Create your own custom styles for your website.',
        url: '/website_builder?tab=styles',
        icon: SwatchIcon,
      },
      {
        id: 3,
        name: 'Style My Signup Page',
        description: 'Customize your Signup Page with ease.',
        url: '/website_builder?tab=layout&page=signup_page',
        icon: SwatchIcon,
      },
      {
        id: 4,
        name: 'Style My Home Page',
        description: 'Customize your Home Page with ease.',
        url: '/website_builder?tab=layout&page=home_page',
        icon: SwatchIcon,
      },
      {
        id: 5,
        name: 'Style My Upgrade Page',
        description: 'Customize your Upgrade Page with ease.',
        url: '/website_builder?tab=layout&page=upgrade_page',
        icon: SwatchIcon,
      },
      {
        id: 6,
        name: 'Prevent Spam Signups',
        description: 'Add CAPTCHA to your subscribe widget to prevent spam signups.',
        url: '/website_builder/settings/security',
        icon: Cog8ToothIcon,
      },
      {
        id: 7,
        name: 'Redirect Users After Signup',
        description: 'Check out our custom signup flow experience in settings.',
        url: '/website_builder/settings/signup_flow',
        icon: Cog8ToothIcon,
      },
      {
        id: 8,
        name: 'Add Tracking Pixels',
        description: 'See our Pixels section in settings to add tracking pixels.',
        url: '/website_builder/settings/pixels',
        icon: Cog8ToothIcon,
      },
      {
        id: 9,
        name: 'Enable/Disable Comments on My Posts',
        description: 'See our Comments section in settings to toggle comments on posts.',
        url: '/website_builder/settings/comments',
        icon: Cog8ToothIcon,
      },
    ];
  }, []);

  const filteredItems =
    query === ''
      ? filterableOptionItems
      : filterableOptionItems.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <>
      <Button
        type="button"
        variant="primary-inverse"
        className="w-[205px] flex flex-row space-x-3 items-start justify-start"
        onClick={() => setOpen(!open)}
      >
        <MagnifyingGlassIcon className="h-5 w-5 text-surface-500" />
        <p className="w-full text-start text-surface-500">Search...</p>
      </Button>
      <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="How do I..."
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4 p-3">
                    <SupportHelper
                      Icon={FilmIcon}
                      text="Video Tutorial"
                      href="https://www.youtube.com/watch?v=G9nJFQAnBUQ"
                    />
                    <SupportHelper
                      Icon={MegaphoneIcon}
                      text="Product Announcement"
                      href="https://product.beehiiv.com/p/new-website-builder"
                    />
                  </div>

                  {filteredItems.length > 0 && (
                    <Combobox.Options static className="max-h-80 transform-gpu scroll-py-3 overflow-y-auto p-3">
                      {filteredItems.map((item) => (
                        <Combobox.Option
                          key={item.id}
                          value={item}
                          onClick={() => {
                            navigate(item.url);
                            setOpen(false);
                          }}
                          className={({ active }) =>
                            cx('flex cursor-pointer select-none rounded-xl p-3', active && 'bg-gray-100')
                          }
                        >
                          {({ active }) => (
                            <>
                              <item.icon className="h-6 w-6 text-surface-600" aria-hidden="true" />

                              <div className="ml-4 flex-auto">
                                <p className={cx('text-sm font-medium', active ? 'text-gray-900' : 'text-gray-700')}>
                                  {item.name}
                                </p>
                                <p className={cx('text-sm', active ? 'text-gray-700' : 'text-gray-500')}>
                                  {item.description}
                                </p>
                              </div>
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}

                  {query !== '' && filteredItems.length === 0 && (
                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                      <ExclamationCircleIcon
                        type="outline"
                        name="exclamation-circle"
                        className="mx-auto h-6 w-6 text-gray-400"
                      />
                      <p className="mt-4 font-semibold text-gray-900">No results found</p>
                      <p className="mt-2 text-gray-500">No components found for this search term. Please try again.</p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CommandPallete;
