import toast from 'react-hot-toast';

import { BoostAgreement, BoostAgreementStatus } from '@/interfaces/boosts/grow/boost_agreement';

import useUpdateBoostAgreement from './useUpdateBoostAgreement';

interface Options {
  onRejectSuccess?: () => void;
  onAcceptSuccess?: () => void;
}

const useWebApplication = (boostAgreement: BoostAgreement, options?: Options) => {
  const { mutateAsync: rejectBoostAgreement, isLoading: isRejecting } = useUpdateBoostAgreement({
    boostOfferId: boostAgreement.boost_offer.id,
    onSuccess: () => {
      toast.success('Boost rejected');
      options?.onRejectSuccess?.();
    },
    shouldInvalidate: false,
  });
  const { mutateAsync: acceptBoostAgreement, isLoading: isAccepting } = useUpdateBoostAgreement({
    boostOfferId: boostAgreement.boost_offer.id,
    onSuccess: () => {
      toast.success('Boost accepted');
      options?.onAcceptSuccess?.();
    },
    shouldInvalidate: false,
  });

  const handleAccept = () => {
    acceptBoostAgreement({
      boostAgreementId: boostAgreement.id,
      transition_to: BoostAgreementStatus.ACTIVE,
    });
  };

  const handleReject = (rejectReason: string) => {
    rejectBoostAgreement({
      boostAgreementId: boostAgreement.id,
      transition_to: BoostAgreementStatus.REJECTED,
      rejected_reason: rejectReason,
    });
  };

  return {
    rejectBoostAgreement,
    handleAccept,
    handleReject,
    isRejecting,
    isAccepting,
  };
};

export default useWebApplication;
