import { useParams } from 'react-router-dom';

import useAdvertiserPublisherListItem from '@/hooks/useAdNetwork/internal/useAdvertiserPublisherListItem';
import useAdvertiserPublisherListItemDestroy from '@/hooks/useAdNetwork/internal/useAdvertiserPublisherListItemDestroy';
import useAdvertiserPublisherListItemUpdate from '@/hooks/useAdNetwork/internal/useAdvertiserPublisherListItemUpdate';
import { AdNetworkAdvertiserPublisherListItem } from '@/interfaces/ad_network/internal/advertiser_publisher_list_item';
import { MultiActionCard } from '@/routes/ad_network/_components/MultiActionCard';
import { PageHeading } from '@/routes/ad_network/_components/PageHeading';
import useConfirmDelete from '@/routes/ad_network/_hooks/useConfirmDelete';

import Form from '../_components/Form';

interface Props {
  publisherListItem: AdNetworkAdvertiserPublisherListItem;
}

const EditEntry = ({ publisherListItem }: Props) => {
  const deleteMutation = useAdvertiserPublisherListItemDestroy({
    advertiserId: publisherListItem.advertiser.id,
    publisherListItemId: publisherListItem.id,
    listType: publisherListItem.type,
  });
  const warning = 'Once you delete this list entry, there is no going back. Please be certain.';
  const onSubmitMutation = useAdvertiserPublisherListItemUpdate({
    advertiserId: publisherListItem.advertiser.id,
    publisherListItemId: publisherListItem.id,
  });

  const { ConfirmDeleteModal, modalProps, handleDelete, isDeleting } = useConfirmDelete({
    deleteMutation,
    warning,
    confirmWith: publisherListItem.publisher.name,
  });

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">{publisherListItem.publisher.name}</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">Edit</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
      </PageHeading>
      <div className="mx-auto max-w-lg pt-4">
        <h1 className="text-xl">Edit Publisher List Item</h1>
      </div>
      <Form
        onSubmitMutation={onSubmitMutation}
        defaultPublisher={publisherListItem.publisher}
        defaultListType={publisherListItem.type}
        defaultReasons={publisherListItem.reasons}
        defaultExpiresOn={publisherListItem.expires_on}
      />
      <MultiActionCard>
        <MultiActionCard.Item
          title="Delete this list entry"
          description={warning}
          loading={isDeleting}
          onClick={() => handleDelete()}
          danger
        />
      </MultiActionCard>
      <ConfirmDeleteModal {...modalProps} />
    </>
  );
};

export default function Loader() {
  const { advertiser_id: advertiserId } = useParams<'advertiser_id'>() as { advertiser_id: string };
  const { publisher_list_item_id: publisherListItemId } = useParams<'publisher_list_item_id'>() as {
    publisher_list_item_id: string;
  };
  const { data, isLoading, isError, isSuccess } = useAdvertiserPublisherListItem({ advertiserId, publisherListItemId });

  if (!isSuccess || isLoading || isError) return null;

  return <EditEntry publisherListItem={data} />;
}
