import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';

import { useNavLinks } from '@/hooks/useNavlinks';
import { NavLink } from '@/interfaces/nav_link';
import PropertiesGrouping from '@/routes/website_builder/_components/Helpers/PropertiesGrouping';
import { Button } from '@/ui/Button';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import LinkActions from '../Helpers/LinkActions';
import NonSortablePageGroup from '../Helpers/NonSortablePageGroup';
import NonSortablePageWrapper from '../Helpers/NonSortablePageWrapper';
import PageHelperTitle from '../Helpers/PageTitleHelper';

const StandalonePages = () => {
  const navigate = useNavigate();
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useNavLinks({
    search: '',
    externalLinks: false,
    pages: true,
    disabled: false,
    live: true,
  });
  const navLinks = data?.pages.flatMap((page) => page.nav_links) || [];
  const filteredLinks = navLinks.filter((link) => !link?.nav_group?.id);

  return (
    <PropertiesGrouping
      label="Standalone Pages"
      description="You can add internal pages to your website that do not live in your navigation bar."
    >
      <div className="space-y-5">
        <Button
          onClick={() => navigate('/website_builder/custom_pages/new?publish=true')}
          Icon={PlusIcon}
          size="xs"
          variant="primary-inverse"
        >
          Add Page
        </Button>
        <NonSortablePageGroup>
          {filteredLinks?.map((navLink: NavLink) => {
            return (
              <NonSortablePageWrapper key={navLink.id}>
                <PageHelperTitle text={navLink.cta_text} isExternalLink={navLink.external_link} />
                <LinkActions navLink={navLink} iconOrientation="vertical" />
              </NonSortablePageWrapper>
            );
          })}
        </NonSortablePageGroup>
        {hasNextPage && (
          <LoadMoreButton size="sm" isFetchingNextPage={isFetchingNextPage} fetchNextPage={fetchNextPage} />
        )}
      </div>
    </PropertiesGrouping>
  );
};

export default StandalonePages;
