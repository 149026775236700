import { PremiumGift } from '../interfaces/premium_gift';

export default function usePremiumGiftOptions<T>(premiumGifts: PremiumGift[], useLabel?: boolean) {
  return premiumGifts.map(
    (premiumGift) =>
      ({
        [useLabel ? 'label' : 'name']: `${premiumGift.description} - ${premiumGift.tier_name}`,
        value: premiumGift.id || '',
      } as T)
  );
}
