import { useCallback, useEffect, useState } from 'react';

import { TypeaheadMultiSelect } from '@/components/Form';
import { useAutomations } from '@/hooks/useAutomations';
import { Automation } from '@/interfaces/automations/automation';
import { Option } from '@/interfaces/general';
import pluralize from '@/utils/pluralize';

import Banner from '../Banner';

import SelectedAutomations from './SelectedAutomations';

interface Props {
  isEnrollingMultipleSubscribers?: boolean;
  selectedAutomations: string[];
  excludedAutomations?: string[];
  onSelect: (automationId: string) => void;
  onDeselect: (automationId: string) => void;
  onDeselectAll: () => void;
}

const SelectAutomation = ({
  isEnrollingMultipleSubscribers,
  onSelect,
  onDeselect,
  onDeselectAll,
  selectedAutomations,
  excludedAutomations,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [automationsInFormState, setAutomationsInFormState] = useState<Automation[]>([]);

  const { refetch, data: automations } = useAutomations({
    search: searchQuery,
    shouldFetchAll: true,
    order: 'automations.name',
    dir: 'asc',
  });

  const handleSelect = (name: string, value: string) => {
    onSelect(value);

    const automationItem = automations?.pages
      .flatMap((page) => page.automations)
      ?.find((automation: Automation) => automation.id === value);

    if (automationItem) {
      setAutomationsInFormState([automationItem, ...automationsInFormState]);
    }
  };

  const handleDeselect = (name: string, value: string) => {
    onDeselect(value);
    const deselectedAutomationIndex = automationsInFormState.findIndex(({ id }) => id === value);
    if (deselectedAutomationIndex > -1) {
      const newAutomationsInFormState = [...automationsInFormState];
      newAutomationsInFormState.splice(deselectedAutomationIndex, 1);
      setAutomationsInFormState(newAutomationsInFormState);
    }
  };

  const handleDeselectAll = () => {
    onDeselectAll();
    setAutomationsInFormState([]);
  };

  const handleRemoveAutomation = (automationId: string) => handleDeselect('', automationId);

  const handleClear = () => {
    setSearchQuery('');
  };

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleSearch = useCallback(
    async (): Promise<Option[]> => {
      try {
        const response = await refetch();
        return (
          response?.data?.pages
            .flatMap((page) => page.automations)
            .filter((automation: Automation) => !excludedAutomations?.includes(automation.id))
            .map((automation: Automation) => ({ label: automation.name, value: automation.id })) || []
        );
      } catch (error) {
        // do something
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchQuery]
  );

  useEffect(() => {
    if (selectedAutomations.length > 0) {
      const selectedAutomationItems = automations?.pages
        .flatMap((page) => page.automations)
        ?.filter((automation: Automation) => selectedAutomations.includes(automation.id));

      if (selectedAutomationItems) {
        setAutomationsInFormState(selectedAutomationItems);
      }
    }
  }, [selectedAutomations, automations]);

  return (
    <>
      <input type="hidden" name="automation_ids" value={selectedAutomations.join(', ')} />
      <TypeaheadMultiSelect
        name="automations"
        placeholderText="Select Automation(s)"
        values={selectedAutomations}
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        onClear={handleClear}
        onSearchQueryChange={handleSearchQueryChange}
        onDeselectAll={handleDeselectAll}
        onSearch={handleSearch}
        emptyLabel="No automation found"
        showSelectedOption
        showClearAll={false}
      />

      <div className="w-full">
        <SelectedAutomations items={automationsInFormState} onDeselect={handleRemoveAutomation} />
        {automationsInFormState.length > 0 && (
          <div className="mt-2 leading-none">
            <Banner
              title={`${pluralize(
                'The subscriber',
                isEnrollingMultipleSubscribers ? 2 : 1,
                undefined,
                true
              )} will be enrolled in
              the selected ${pluralize('automation flow', automationsInFormState.length, undefined, true)},
              bypassing any triggers and conditions.`}
              isScreenWide={false}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SelectAutomation;
