import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { Editor } from '@tiptap/core';
import { Transaction } from '@tiptap/pm/state';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import { CollabHistory, CollabOnUpdateProps } from '@tiptap-pro/extension-collaboration-history';

import { cn } from '@/utils/cn';

import { Settings } from '../../interfaces/setting';
import { IntentAction } from '../../interfaces/upgrades';
import { EditorContext } from '../../pages/Post/Edit/EditorContext';
import { PLAN, PREMIUM_PLANS } from '../../utils/plans';
import { UpgradeIntent as UpgradeIntentModal } from '../UpgradeIntent';

import { DragHandleButton } from './components/DragHandleButton';
import { LinkMenu, RemoveBlockMenu, TextMenu } from './components/menus';
import { ThreadComposer } from './components/panels/ThreadComposer';
import { ThreadComposerContext } from './components/panels/ThreadComposer/Context';
import { AdvertisementOpportunityMenu } from './extensions/AdvertisementOpportunity/menus/AdvertisementOpportunityMenu';
import { AdvertisementOpportunityLogoMenu } from './extensions/AdvertisementOpportunityLogo/menus/AdvertisementOpportunityLogoMenu';
import { AudioMenu } from './extensions/Audio/menus/AudioMenu';
import { BacklinkMenu } from './extensions/Backlink';
import { BlockquoteFigureMenu } from './extensions/BlockquoteFigure/menus';
import { ButtonMenu } from './extensions/Button/menus';
import { ExtensionKit } from './extensions/extension-kit';
import { FileAttachmentMenu } from './extensions/FileAttachment/menus/FileAttachmentMenu';
import { GenericEmbedMenu } from './extensions/GenericEmbed/menus';
import { CaptionMenu } from './extensions/ImageBlock/menus/CaptionMenu';
import { ImageBlockMenu } from './extensions/ImageBlock/menus/ImageBlockMenu';
import { ColumnsMenu } from './extensions/MultiColumn/menus/ColumnsMenu';
import { PollBlockMenu } from './extensions/Poll/menus/PollBlockMenu';
import { RSSBlockMenu } from './extensions/RSS/menus/RSSBlockMenu';
import { TableColumnMenu, TableRowMenu } from './extensions/Table/menus';
import { TableOfContentsMenu } from './extensions/TableOfContents/menus';
import { EditorUser } from './lib/types';
import { Styled } from './styled';

interface TiptapEditorProps {
  allowAds?: boolean;
  allowPolls?: boolean;
  className?: string;
  content?: Content;
  onBlur?: (props: { editor: Editor; event: Event }) => void;
  onUpdate?: (props: { editor: Editor; transaction: Transaction }) => void;
  onCreate?: (props: { editor: Editor; transaction: Transaction }) => void;
  onUsersUpdate?: (users: EditorUser[]) => void;
  provider?: TiptapCollabProvider;
  publicationId: string;
  settings: Settings;
  useCollabHistory?: boolean;
  userColor?: string;
  userId?: string;
  userName?: string;
  usesCollaboration?: boolean;
  onVersionUpdate?: (payload: CollabOnUpdateProps) => void;
  shouldAutoFocus?: boolean;
  isV2?: boolean;
}

interface UpgradeIntentModalState {
  isOpen: boolean;
  action?: IntentAction;
  plan?: keyof typeof PREMIUM_PLANS;
}

export const TiptapEditor = ({
  publicationId,
  settings,
  onBlur,
  onUpdate,
  onCreate,
  onUsersUpdate,
  userId,
  userName,
  userColor,
  usesCollaboration,
  provider,
  className,
  allowPolls = false,
  allowAds = false,
  content = '',
  useCollabHistory = false,
  onVersionUpdate,
  shouldAutoFocus = true,
  isV2 = false,
}: TiptapEditorProps): JSX.Element | null => {
  const [isThreadComposing, setThreadComposing] = useState(false);

  const menuContainerRef = useRef(null);

  const { setEditor, setShowSearchAndReplaceMenu, setShowThreadsSidebar, showThreadsSidebar, setOpenSidebar } =
    useContext(EditorContext);

  const [upgradeIntentModal, setUpgradeIntentModal] = useState<UpgradeIntentModalState>({
    isOpen: false,
    action: undefined,
  });

  const editor = useEditor({
    extensions: [
      ...(ExtensionKit({
        publicationId,
        userName,
        userId,
        userColor,
        usesCollaboration,
        provider,
        settings,
        allowPolls,
        allowAds,
        onToggleUpgradeIntentModal: (action: IntentAction, plan: keyof typeof PREMIUM_PLANS) => {
          setUpgradeIntentModal((prevState) => ({ isOpen: !prevState.isOpen, action, plan }));
        },
        additionalShortcuts: {
          'Mod-f': () => {
            setShowSearchAndReplaceMenu(true);

            setTimeout(() => document.querySelector<HTMLInputElement>('#search-input')?.focus());

            return true;
          },
          'Mod-Shift-M': () => {
            setThreadComposing(true);
            return true;
          },
          'Mod-Shift-X': ({ editor: e }) => e.commands.toggleStrike(),
        },
        isV2,
        openThreadsSidebar: () => (isV2 ? setOpenSidebar?.('threads') : setShowThreadsSidebar(true)),
      }) as any),
      useCollabHistory ? CollabHistory.configure({ provider, onUpdate: onVersionUpdate }) : null,
    ],
    editorProps: {
      attributes: {
        class: className || '',
      },
    },
    onCreate: ({ editor: tiptapEditor }) => {
      setEditor(tiptapEditor as any);

      onCreate?.({ editor: tiptapEditor, transaction: tiptapEditor.state.tr });
    },
    onBlur: (props) => {
      onBlur?.(props);
    },
    onUpdate,
    autofocus: shouldAutoFocus,
    ...(usesCollaboration ? {} : { content }),
  });

  const users = useMemo(() => {
    if (!editor?.storage.collaborationCursor?.users) {
      return [];
    }

    return editor.storage.collaborationCursor?.users.map((user: EditorUser) => {
      const names = user.name?.split(' ');
      const firstName = names?.[0];
      const lastName = names?.[names.length - 1];
      const initials = `${firstName?.[0] || '?'}${lastName?.[0] || '?'}`;

      return { ...user, initials: initials.length ? initials : '?' };
    });
  }, [editor?.storage.collaborationCursor?.users]);

  useEffect(() => {
    onUsersUpdate?.(users);
  }, [users, onUsersUpdate]);

  const threadComposerContextProviderValue = useMemo(
    () => ({
      isComposing: isThreadComposing,
      setComposing: setThreadComposing,
      openComposer: () => setThreadComposing(true),
      closeComposer: () => setThreadComposing(false),
    }),
    [isThreadComposing, setThreadComposing]
  );

  if (!editor) {
    return null;
  }

  return (
    <ThreadComposerContext.Provider value={threadComposerContextProviderValue}>
      <ThreadComposer editor={editor} />

      <div ref={menuContainerRef}>
        <DragHandleButton
          editor={editor}
          appendTo={menuContainerRef}
          usesCollaboration={!!(usesCollaboration && provider)}
        />
        <RemoveBlockMenu editor={editor} appendTo={menuContainerRef} />
        <LinkMenu editor={editor} appendTo={menuContainerRef} />
        <TextMenu editor={editor} appendTo={menuContainerRef} />
        <BlockquoteFigureMenu editor={editor} appendTo={menuContainerRef} />
        <GenericEmbedMenu editor={editor} appendTo={menuContainerRef} />
        <ButtonMenu editor={editor} appendTo={menuContainerRef} />
        <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
        <CaptionMenu editor={editor} appendTo={menuContainerRef} />
        <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
        <FileAttachmentMenu editor={editor} appendTo={menuContainerRef} />
        <AudioMenu editor={editor} appendTo={menuContainerRef} />
        <AdvertisementOpportunityMenu editor={editor} appendTo={menuContainerRef} />
        <AdvertisementOpportunityLogoMenu editor={editor} appendTo={menuContainerRef} />
        <PollBlockMenu editor={editor} appendTo={menuContainerRef} />
        <RSSBlockMenu editor={editor} appendTo={menuContainerRef} />
        <TableOfContentsMenu editor={editor} appendTo={menuContainerRef} />
        <TableRowMenu editor={editor} appendTo={menuContainerRef} />
        <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
        <BacklinkMenu editor={editor} appendTo={menuContainerRef} />
      </div>

      <Styled.Container isV2={isV2}>
        <EditorContent
          editor={editor}
          suppressContentEditableWarning
          className={cn({ 'threads-sidebar-open': !isV2 && showThreadsSidebar })}
        />
      </Styled.Container>

      <UpgradeIntentModal
        isOpen={upgradeIntentModal.isOpen}
        intentAction={upgradeIntentModal.action}
        preselectedPlan={upgradeIntentModal.plan || PLAN.SCALE}
        onClose={() => {
          setUpgradeIntentModal({ ...upgradeIntentModal, isOpen: false });
        }}
      />
    </ThreadComposerContext.Provider>
  );
};

export default Editor;
