import { useState } from 'react';
import cx from 'classnames';

import ActionModal from '@/components/ActionModal';
import { Button } from '@/ui/Button';

import { usePageContext } from '../../Context/PageContext';
import { SavingIndicator } from '../../Helpers/SavingIndicator';
import CommandPallete from '../CommandPallete';

interface Props {
  onPublish?: () => void;
  isPublishing?: boolean;
  disablePublishActions?: boolean;
  publishCtaText?: string;
  showPublishConfirmationModal?: boolean;
  publishConfirmationModalText?: string;
}

const PublishActions = ({
  onPublish = () => {},
  isPublishing = false,
  disablePublishActions = false,
  publishCtaText = 'Publish',
  showPublishConfirmationModal = false,
  publishConfirmationModalText = '',
}: Props) => {
  const { status } = usePageContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePublish = () => {
    if (showPublishConfirmationModal && publishConfirmationModalText !== '') {
      setIsModalOpen(true);
    } else {
      onPublish();
    }
  };

  const handleConfirmPublish = () => {
    setIsModalOpen(false);
    onPublish();
  };

  return (
    <div>
      <div
        className={cx(
          'flex space-x-2 items-center',
          disablePublishActions && 'opacity-50 pointer-events-none cursor-not-allowed'
        )}
      >
        <SavingIndicator status={status} />
        <CommandPallete />

        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onProceed={handleConfirmPublish}
          resourceId=""
          isWorking={isPublishing}
          headerText={publishCtaText}
          actionText="Publish"
        >
          <p>{publishConfirmationModalText}</p>
        </ActionModal>

        <Button variant="primary" onClick={handlePublish} loading={isPublishing} disabled={disablePublishActions}>
          {isPublishing ? 'Publishing...' : publishCtaText}
        </Button>
      </div>
    </div>
  );
};

export default PublishActions;
