import { FC } from 'react';
import { ListItemElement } from '@shared/dream-components';
import { NodeViewContent, NodeViewProps, useReactNodeView } from '@tiptap/react';

export const ListItemView: FC<NodeViewProps> = ({ node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <ListItemElement
      element={{
        type: 'listItem',
        id: node.attrs.id,
        attrs: {
          customAttributes: {
            'data-something': '',
          },
        },
        content: [],
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      <NodeViewContent />
    </ListItemElement>
  );
};
