import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { FootnoteMarkerView } from './views';

export const FootnoteMarker = Node.create({
  name: 'footnoteMarker',

  group: 'inline',

  inline: true,

  atom: true,

  selectable: false,

  addAttributes() {
    return {
      footnoteId: {
        default: null,
        renderHTML: (attributes) => {
          return {
            'data-footnote-id': attributes.footnoteId,
          };
        },
        parseHTML: (element) => {
          return element.getAttribute('data-footnote-id') || null;
        },
      },
      order: {
        default: null,
        renderHTML: (attributes) => {
          return {
            'data-order': attributes.order,
          };
        },
        parseHTML: (element) => {
          const order = element.getAttribute('data-order');
          const orderNum = order ? parseInt(order, 10) : null;

          return orderNum;
        },
      },
      footnoteText: {
        default: '',
        renderHTML: (attributes) => {
          return {
            'data-footnote-text': attributes.footnoteText,
          };
        },
        parseHTML: (element) => {
          return element.getAttribute('data-footnote-text') || '';
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'footnotemarker[data-footnote-id]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['footnotemarker', HTMLAttributes];
  },

  addNodeView() {
    return ReactNodeViewRenderer(FootnoteMarkerView, { className: 'inline' });
  },
});
