import { Link } from 'react-router-dom';
import { ArrowLeft, Stack } from '@phosphor-icons/react';

import { ContentTree } from '../ContentTree';
import { ContentTreeProvider } from '../ContentTree/context';
import { LAYERS_PANEL_ID } from '../DreamEditor/constants';
import { NavSection } from '../SideNav/NavSection';
import { NavSectionTitle } from '../SideNav/NavSectionTitle';
import { Button } from '../UI/Button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../UI/Tabs';
import { Text } from '../UI/Text';

import AddableDefaultPages from './AddableDefaultPages';
import AddablePage from './AddablePage';

interface Props {
  pagesRoutes: any;
  defaultRoutes: any;
  previewSiteVersion?: any;
  isTemplatePage?: boolean;
  sitePackageId?: string;
}

const SidePanelTabs = ({ pagesRoutes, defaultRoutes, previewSiteVersion, isTemplatePage, sitePackageId }: Props) => {
  const hasPageRoutes = pagesRoutes && Object.keys(pagesRoutes).length > 0;

  return (
    <Tabs defaultValue="layers" className="flex flex-col flex-1">
      {isTemplatePage && (
        <Link to={`/website_builder_v2/projects/${sitePackageId}`}>
          <Button variant="secondary" LeftIcon={ArrowLeft} className="w-full">
            All Templates
          </Button>
        </Link>
      )}
      {!isTemplatePage && (
        <TabsList className="p-2" defaultValue="layers">
          <TabsTrigger value="layers">
            <Text weight="medium" size="2xs" className="leading-0">
              Layers
            </Text>
          </TabsTrigger>
          <TabsTrigger value="pages">
            <Text weight="medium" size="2xs" className="leading-0">
              Pages
            </Text>
          </TabsTrigger>
          <TabsTrigger value="globals">
            <Text weight="medium" size="2xs" className="leading-0">
              Globals
            </Text>
          </TabsTrigger>
        </TabsList>
      )}
      <TabsContent value="layers" id={LAYERS_PANEL_ID} />
      <TabsContent value="pages" asChild>
        <div className="flex flex-col flex-1 gap-6">
          <NavSection>
            <NavSectionTitle title="Default Pages" />
            {defaultRoutes && (
              <ContentTreeProvider
                dndDisabledErrorMessage={
                  previewSiteVersion?.id
                    ? "You can't rearrange pages in preview mode."
                    : "You can't re-arrange default pages"
                }
                isAllowDnD={false}
                isShowOptions={!previewSiteVersion?.id}
                isShowAddPage={!previewSiteVersion?.id}
                pageLinkPrefix="/website_builder_v2/page"
              >
                <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
            <AddableDefaultPages />
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Pages" />
            {hasPageRoutes && (
              <ContentTreeProvider
                isAllowDnD={!previewSiteVersion?.id}
                isShowOptions={!previewSiteVersion?.id}
                isShowAddPage={!previewSiteVersion?.id}
                pageLinkPrefix="/website_builder_v2/page"
                dndDisabledErrorMessage={previewSiteVersion?.id ? "You can't rearrange pages in preview mode." : ''}
              >
                <ContentTree route={pagesRoutes} parentPath={[]} slug="" showHome={false} />
              </ContentTreeProvider>
            )}
            <AddablePage />
          </NavSection>
        </div>
      </TabsContent>
      <TabsContent value="globals" asChild>
        <div className="flex flex-col flex-1 gap-6">
          <NavSection>
            <NavSectionTitle title="Modals" />
            <Link
              to="/website_builder_v2/globals/signup_modal"
              className="flex items-center gap-1 cursor-pointer p-1.5 rounded-md hover:bg-wb-secondary"
            >
              <Stack className="text-wb-accent" weight="bold" />
              <span className="text-[13px] leading-5 font-normal text-inherit">Signup Modal</span>
            </Link>
            <Link
              to="/website_builder_v2/globals/login_modal"
              className="flex items-center gap-1 cursor-pointer p-1.5 rounded-md hover:bg-wb-secondary"
            >
              <Stack className="text-wb-accent" weight="bold" />
              <span className="text-[13px] leading-5 font-normal text-inherit">Login Modal</span>
            </Link>
            <Link
              to="/website_builder_v2/globals/recommendations_modal"
              className="flex items-center gap-1 cursor-pointer p-1.5 rounded-md hover:bg-wb-secondary"
            >
              <Stack className="text-wb-accent" weight="bold" />
              <span className="text-[13px] leading-5 font-normal text-inherit">Recommendations Modal</span>
            </Link>
          </NavSection>
        </div>
      </TabsContent>
    </Tabs>
  );
};

export default SidePanelTabs;
