import { AdNetworkAdvertiser } from '@/interfaces/ad_network/advertiser_access/ad_network_advertiser';
import { AdNetworkAdvertiserPublisherList, AdNetworkAdvertiserPublisherListType } from '@/interfaces/ad_network/advertiser_access/advertiser_publisher_list';
import { Publication } from '@/interfaces/publication';

export const AdNetworkAdvertiserPublisherListItemTypeOptions = [
  { value: AdNetworkAdvertiserPublisherListType.PREFERRED, label: 'Preferred' },
  { value: AdNetworkAdvertiserPublisherListType.EXCLUDED, label: 'Excluded' },
];

export enum AdNetworkAdvertiserPublisherListItemReason {
  ADVERTISER_FAVORITE = 'advertiser_favorite',
  HIGH_CTR = 'high_ctr',
  HIGH_CONVERSION = 'high_conversion',
  HIGH_IMPRESSIONS = 'high_impressions',
  QUALITY_LEADS = 'quality_leads',
  ADVERTISER_EXCLUSION = 'advertiser_exclusion',
  SUSPECTED_BOT_TRAFFIC = 'suspected_bot_traffic',
  COMPETITION = 'competition',
  PUBLISHER_NOT_INTERESTED = 'publisher_not_interested',
  LOW_CTR = 'low_ctr',
  LOW_CONVERSION = 'low_conversion',
  LOW_IMPRESSIONS = 'low_impressions',
  LOW_SESSION_LENGTH = 'low_session_length',
}

export const AdNetworkAdvertiserPublisherListItemReasonOptions = [
  {
    value: AdNetworkAdvertiserPublisherListItemReason.ADVERTISER_FAVORITE,
    label: 'Advertiser favorite',
    associatedWith: AdNetworkAdvertiserPublisherListType.PREFERRED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.HIGH_CTR,
    label: 'High click-through rate',
    associatedWith: AdNetworkAdvertiserPublisherListType.PREFERRED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.HIGH_CONVERSION,
    label: 'High conversion',
    associatedWith: AdNetworkAdvertiserPublisherListType.PREFERRED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.HIGH_IMPRESSIONS,
    label: 'High impressions',
    associatedWith: AdNetworkAdvertiserPublisherListType.PREFERRED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.QUALITY_LEADS,
    label: 'Quality leads',
    associatedWith: AdNetworkAdvertiserPublisherListType.PREFERRED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.ADVERTISER_EXCLUSION,
    label: 'Advertiser exclusion',
    associatedWith: AdNetworkAdvertiserPublisherListType.EXCLUDED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.SUSPECTED_BOT_TRAFFIC,
    label: 'Suspected bot traffic',
    associatedWith: AdNetworkAdvertiserPublisherListType.EXCLUDED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.COMPETITION,
    label: 'Competition',
    associatedWith: AdNetworkAdvertiserPublisherListType.EXCLUDED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.PUBLISHER_NOT_INTERESTED,
    label: 'Publisher not interested',
    associatedWith: AdNetworkAdvertiserPublisherListType.EXCLUDED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.LOW_CTR,
    label: 'Low click-through rate',
    associatedWith: AdNetworkAdvertiserPublisherListType.EXCLUDED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.LOW_CONVERSION,
    label: 'Low conversion',
    associatedWith: AdNetworkAdvertiserPublisherListType.EXCLUDED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.LOW_IMPRESSIONS,
    label: 'Low impressions',
    associatedWith: AdNetworkAdvertiserPublisherListType.EXCLUDED,
  },
  {
    value: AdNetworkAdvertiserPublisherListItemReason.LOW_SESSION_LENGTH,
    label: 'Low session length',
    associatedWith: AdNetworkAdvertiserPublisherListType.EXCLUDED,
  },
];

export enum AdNetworkAdvertiserPublisherListItemActorType {
  USER = 'User',
  ADVERTISER = 'Advertiser',
  AUTOMATION = 'Automation',
  EXPIRATION = 'Expiration',
}

export const AdNetworkAdvertiserPublisherListItemActorTypeOptions = [
  { value: AdNetworkAdvertiserPublisherListItemActorType.USER, label: 'User' },
  { value: AdNetworkAdvertiserPublisherListItemActorType.ADVERTISER, label: 'Advertiser' },
  { value: AdNetworkAdvertiserPublisherListItemActorType.AUTOMATION, label: 'Automation' },
  { value: AdNetworkAdvertiserPublisherListItemActorType.EXPIRATION, label: 'Expiration' },
];

export interface AdNetworkAdvertiserPublisherListItemActor {
  id: string;
  type: AdNetworkAdvertiserPublisherListItemActorType | null;
}

export interface AdNetworkAdvertiserPublisherListItem {
  id: string;
  type: AdNetworkAdvertiserPublisherListType;
  publisher: Publication;
  publisher_id: string;
  advertiser: AdNetworkAdvertiser;
  advertiser_id: string;
  expires_on: Date;
  reasons: AdNetworkAdvertiserPublisherListItemReason[];
  added_by: AdNetworkAdvertiserPublisherListItemActor;
  added_by_id: string;
  added_by_type: AdNetworkAdvertiserPublisherListItemActorType;
  removed_by: AdNetworkAdvertiserPublisherListItemActor;
  removed_by_id: string;
  removed_by_type: AdNetworkAdvertiserPublisherListItemActorType;
  created_at: Date;
  updated_at: Date;
  publisher_list: AdNetworkAdvertiserPublisherList;
}
