import { forwardRef, HTMLProps } from 'react';

import { cn } from '@/utils/cn';

export const Wrapper = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ className, children, ...rest }, ref) => {
    return (
      <div ref={ref} className={cn('flex items-center gap-1 mb-6 p-1 rounded-lg bg-gray-100', className)} {...rest}>
        {children}
      </div>
    );
  }
);

Wrapper.displayName = 'TabPills.Wrapper';

export const Item = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement> & { active?: boolean }>(
  ({ active, className, children, type, disabled, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          'flex flex-1 whitespace-nowrap justify-center items-center py-1.5 px-2 bg-transparent rounded-lg text-xs font-semibold text-gray-600 transition-all',
          !active && !disabled && 'hover:bg-gray-50 hover:text-gray-700 hover:shadow-sm',
          active && !disabled && 'bg-white text-gray-800 shadow-sm',
          disabled && 'text-gray-300 cursor-not-allowed',
          className
        )}
        disabled={disabled}
        type="button"
        {...rest}
      >
        {children}
      </button>
    );
  }
);

Item.displayName = 'TabPills.Item';

export const TabPills = {
  Wrapper,
  Item,
};
