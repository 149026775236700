import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import appendSettingsWorkspaceId from '@/utils/appendSettingsWorkspaceId';

import { BillingActions } from '../../interfaces/billing';
import api from '../../services/swarm';

interface ApiResponse {
  success: boolean;
  error?: string;
}

interface Props {
  organizationId: string;
  publicationId: string;
  action: BillingActions;
}

const useStripeApi = ({ organizationId, publicationId, action }: Props) => {
  const { currentUser } = useCurrentUser();
  const hasPublicationSettingsV2 = currentUser?.has_settings_v2;

  return useMutation<ApiResponse, unknown, { newPlanPriceId?: string }>(
    ({ newPlanPriceId }) =>
      api
        .post(`/organizations/${organizationId}/stripe_api`, {
          publication_id: publicationId,
          stripe_api: {
            action,
            new_plan_price_id: newPlanPriceId,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        window.location.href = hasPublicationSettingsV2
          ? appendSettingsWorkspaceId('/settings/workspace/billing_and_plan', organizationId, publicationId)
          : '/settings/billing';
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useStripeApi;
