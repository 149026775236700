import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { Option } from '@/interfaces/general';

interface Props {
  options: Option[];
  initialValue: Option;
  onChange: (val: Option) => void;
  className?: string;
}

const Select = ({ options, initialValue, onChange, className }: Props) => {
  const [selectedOption, setSelected] = useState(initialValue);

  return (
    <div className={className}>
      <Listbox
        value={selectedOption}
        onChange={(selection) => {
          setSelected(selection);
          onChange(selection);
        }}
      >
        {({ open }) => (
          <div className="relative">
            <Listbox.Button className="hover:bg-gray-100 relative text-xs w-full cursor-default rounded bg-white py-2 pl-3 pr-10 text-left border border-gray-200 focus:outline-none active:border-primary-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
              <span className="block truncate font-medium text-gray-800 capitalize">{selectedOption.label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 transition-all">
                <ChevronDownIcon
                  className={cx(
                    'transition-all duration-200 transform h-4 w-4 text-gray-400',
                    open ? 'rotate-180 ' : ''
                  )}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute mt-0.5 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base border border-gray-200 focus:outline-none sm:text-sm"
                style={{ zIndex: 99 }}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.label}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-8 pr-4 capitalize ${
                        active ? 'bg-primary-100 text-primary-900' : 'text-gray-900'
                      }`
                    }
                    value={option}
                  >
                    {(val) => (
                      <>
                        <span
                          className={`block truncate text-xs ${val.selected ? 'font-medium' : 'font-normal'}`}
                          title={option.label}
                        >
                          {option.label}
                        </span>
                        {selectedOption.label === option.label ? (
                          <span
                            className="absolute inset-y-0 left-0 flex items-center pl-2 text-primary-600"
                            title={option.label}
                          >
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default Select;
