import { BracketsCurly } from '@phosphor-icons/react';
import classNames from 'classnames';

import { CloseButton } from '../../../UI/Button';

type Props = {
  className?: string;
  onClose: () => void;
};

const MergeTag = ({ className, onClose }: Props) => {
  return (
    <div
      className={classNames(
        className || 'absolute -top-6 left-1/2 transform -translate-x-1/2',
        'bg-white border border-wb-hr rounded-xl shadow-lg p-2 gap-2 flex items-center group cursor-pointer'
      )}
    >
      <CloseButton
        onClose={onClose}
        className="absolute -top-0 -right-0 opacity-0 group-hover:opacity-100 transition-opacity duration-100"
        size={14}
      />
      <div>
        <BracketsCurly size={16} className="text-[#8B5CF6]" />
      </div>
    </div>
  );
};

export default MergeTag;
