import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/outline';

import IconButton from '@/components/IconHelpers/IconButton';
import Modal from '@/components/Modal';
import RaiseSubscriberImportLimitApprovalRequestForm from '@/components/RaiseSubscriberImportLimitApprovalRequestForm';
import Text from '@/components/Text';
import { Typography } from '@/components/Typography';
import { useOrganization } from '@/hooks/useOrganization';
import { ApprovalRequest } from '@/interfaces/approval_request';
import { Publication } from '@/interfaces/publication';
import IdentityVerification from '@/pages/Settings/Pages/Publication/ImportSubscribers/IdentityVerification';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import { usePublicationSettings } from '../context';

type ApprovalRequestApiParams = {
  publication_id: string;
  approval_request: ApprovalRequest;
};

interface Props {
  publication: Publication;
}

const RaiseImportLimitCard = ({ publication }: Props) => {
  const navigate = useNavigate();
  const { settings } = usePublicationSettings();
  const formEnabled = settings?.raise_subscriber_import_limit_form;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const organizationQuery = useOrganization(publication.organization_id, publication.id);
  const { organization } = organizationQuery?.data || {};
  const requiresIdentityVerification = Boolean(organization?.requires_stripe_identity_verification);

  const createApprovalRequest = useMutation(
    (approvalRequestData: ApprovalRequestApiParams) => api.post('/approval_requests', approvalRequestData),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        analytics.track('Increased Import Limit');
        toast.success('Request submitted successfully');
        setFormSubmitted(true);
      },
    }
  );

  const onFormSubmit = async (approvalRequestData: ApprovalRequest) => {
    const requestParams = {
      publication_id: publication.id,
      approval_request: approvalRequestData,
    };

    await createApprovalRequest.mutateAsync(requestParams);
    setFormSubmitted(true);
  };

  const onSupport = () => setIsModalOpen(true);

  const closeForm = () => {
    setIsModalOpen(false);
    setFormSubmitted(false);
  };

  return (
    <BodyContainer>
      <CardHeader
        title="Request to raise your import limit"
        description="Do you need to import more subscribers that whats currently allotted? Fill out the request form to get started"
      />
      {formEnabled && (
        <Modal
          isOpen={isModalOpen}
          includeCloseButton={false}
          onClose={() => setIsModalOpen(false)}
          className="bg-white rounded-t-lg py-6 px-4 space-y-3 max-w-lg w-full z-50"
        >
          {formSubmitted ? (
            <>
              <div className="flex justify-between mb-4">
                <Typography token="font-medium/text/lg">Limit increase requested</Typography>
                <IconButton onClick={closeForm}>
                  <XMarkIcon className="h-4 w-4" />
                </IconButton>
              </div>
              <Typography token="font-normal/text/sm" colorWeight="600">
                Your request has been created successfully. A member of our support staff will respond to your request
                within <b>48 - 72 hours</b> hours, pending your plan tier.
              </Typography>
              {requiresIdentityVerification && (
                <Typography token="font-normal/text/sm" colorWeight="600" className="mt-4">
                  Do you need your limit increased sooner? Go back, <b>Start Stripe Identity Verification</b> and your
                  limit will be increased within 5 minutes.
                </Typography>
              )}
            </>
          ) : (
            <>
              <div className="flex justify-between mb-6">
                <Text size="lg" type="bold" className="text-gray-800 w-fit">
                  Request import limit increase
                </Text>
                <div>
                  <button
                    className="rounded-sm p-1 border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                    type="button"
                    onClick={closeForm}
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
              <RaiseSubscriberImportLimitApprovalRequestForm onSubmit={onFormSubmit} />
            </>
          )}
        </Modal>
      )}

      {requiresIdentityVerification ? <IdentityVerification onSupport={onSupport} /> : null}
      {!requiresIdentityVerification ? (
        <Button
          type="button"
          className="w-fit"
          variant="primary-inverse"
          size="xs"
          onClick={formEnabled ? onSupport : () => navigate('/?new_support_ticket')}
        >
          {formEnabled ? 'Open Request Form' : 'Contact support'}
        </Button>
      ) : null}
    </BodyContainer>
  );
};

export default RaiseImportLimitCard;
