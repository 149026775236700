import React, { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '../../../../components/ActionModal';
import { Post } from '../../../../interfaces/post';
import api from '../../../../services/swarm';

interface Props {
  postData: Post;
  onUnconfirm: () => void;
}
const ScheduleModal: React.FunctionComponent<Props> = (props: Props) => {
  const { postData, onUnconfirm } = props;

  const [active, setActive] = useState(false);
  const [unconfirming, setUnconfirming] = useState(false);

  // Transition the post back to a draft
  const unconfirm = () => {
    setUnconfirming(true);
    api
      .patch(`/posts/${postData.id}/transition`, { status: 'draft' })
      .then(() => {
        toast.success('Post successfully unscheduled');
        setActive(false);
        onUnconfirm();
      })
      .catch((errPayload) => toast.error(errPayload.response.data.message))
      .finally(() => setUnconfirming(false));
  };

  return (
    <>
      <ActionModal
        isOpen={active}
        onClose={() => setActive(false)}
        isWorking={unconfirming}
        onProceed={unconfirm}
        resourceId={postData.id}
        actionText="Unschedule"
        headerText="Unschedule Post"
        modalSize="lg"
      >
        <p className="text-sm text-gray-500 mt-2 max-w-xl">
          Unscheduling this post will move it back to a draft state. It will not be sent via email and it will not be
          accessible via the web. You will need to publish or schedule the post again once it is ready.
        </p>
      </ActionModal>
      <button
        className="justify-center duration-200 py-1 px-3 border font-medium focus:outline-none focus:ring-1 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap text-sm hover:bg-gray-200 text-gray-800 disabled:text-gray-600 disabled:bg-gray-100 rounded-md shadow-sm border-gray-300"
        type="button"
        onClick={() => setActive(true)}
      >
        <span>Unschedule</span>
      </button>
    </>
  );
};

export default ScheduleModal;
