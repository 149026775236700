import { APP_LAYOUT_BODY_CONTAINER_ID } from '@/components/Layout/AppLayout/constants';
import TierConfigurationExisting from '@/routes/settings/publication/premium/tiers/$tier_id';
import { ScrollOnMount } from '@/ui/ScrollOnMount';

const MonetizeSubscriptionsPaidTiersEdit = () => {
  return (
    <ScrollOnMount elementId={APP_LAYOUT_BODY_CONTAINER_ID}>
      <TierConfigurationExisting />
    </ScrollOnMount>
  );
};

export default MonetizeSubscriptionsPaidTiersEdit;
