import { useQuery } from 'react-query';

import { ExternalRssFeedApiResponse } from '@/interfaces/external_rss_feed';
import api from '@/services/swarm';

export default function useExternalRssFeed(publicationId: string, externalRssFeedId: string) {
  return useQuery<ExternalRssFeedApiResponse>(
    ['publications', publicationId, 'external_rss_feeds', externalRssFeedId],
    () =>
      api
        .get(`/publications/${publicationId}/external_rss_feeds/${externalRssFeedId}`, {})
        .then((res) => res.data),
    {
      staleTime: 10000,
    }
  );
}
