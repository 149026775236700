import { useQuery } from 'react-query';

import { Settings } from '@/interfaces/setting';
import api from '@/services/swarm';

const usePublicationSettings = (publicationId: string) =>
  useQuery<Settings>(
    ['publications', publicationId, 'settings'],
    () =>
      api
        .get('/settings', {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          console.log('Error fetching publication settings', e);
        }),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60, // 1 minute
      enabled: !!publicationId,
    }
  );

export default usePublicationSettings;
