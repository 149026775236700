import BoostSendsList from '../Dashboard/components/BoostSendsList';

interface Props {
  boostedPublicationId: string;
}

const EmailTab = ({ boostedPublicationId }: Props) => {
  return <BoostSendsList boostedPublicationId={boostedPublicationId} showCPA showSearch={false} />;
};

export default EmailTab;
