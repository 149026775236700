import { ClockIcon, ShieldCheckIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';
import pluralize from '@/utils/pluralize';

import { Comment } from '../../../../interfaces/comment';
import CommentDisplay from '../../Comments/Comment/CommentDisplay';

interface Props {
  onTakeAction: (report: any) => void;
  onViewReports: (comment: any) => void;
  comment: Comment;
  isResolved?: boolean;
  isPending?: boolean;
}

const Report = ({ onTakeAction, onViewReports, comment, isResolved, isPending }: Props) => {
  const { settings } = useSettings();
  const isV2 = settings?.posts_dashboard_v2 === true;

  const reportedBy = pluralize('user', comment.reported_count, undefined, true);

  if (isV2) {
    return (
      <div className="flex flex-col gap-y-4">
        <CommentDisplay
          avatarUrl={comment.avatar_url}
          commenter={comment.commenter}
          time={comment.created_at}
          content={comment.content}
        />
        <div className="flex justify-between items-center">
          {!isResolved && (
            <Button
              variant={isPending ? 'primary-inverse' : 'warning'}
              Icon={isPending ? ClockIcon : ShieldCheckIcon}
              onClick={() => {
                onTakeAction(comment);
              }}
              disabled={isPending}
            >
              {isPending ? 'Pending' : 'Resolve Report'}
            </Button>
          )}
          <button
            type="button"
            onClick={() => {
              onViewReports(comment);
            }}
          >
            <Typography token="font-normal/text/xs" color="warning" colorWeight="700" className="underline">
              Reported by {comment.reported_count} {reportedBy}
            </Typography>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-2">
      <div className="flex justify-between items-center">
        <div className="space-y-4 w-full">
          <div className="w-full">
            <div className="flex justify-between w-full">
              <div>
                <CommentDisplay
                  avatarUrl={comment.avatar_url}
                  commenter={comment.commenter}
                  time={comment.created_at}
                  content={comment.content}
                />
                <div className="px-2">
                  <button
                    type="button"
                    className="transition-all font-regular text-xs text-gray-500 hover:text-gray-600 underline whitespace-nowrap flex items-center"
                    onClick={() => {
                      onViewReports(comment);
                    }}
                  >
                    Reported by {comment.reported_count} {reportedBy}
                  </button>
                </div>

                {!isResolved && (
                  <div className="px-2">
                    <button
                      type="button"
                      className="transition-all text-xs text-primary-500 hover:text-primary-600 whitespace-nowrap underline"
                      onClick={() => {
                        onTakeAction(comment);
                      }}
                    >
                      Take action
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
