import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';

import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import { Author } from '@/interfaces/author';
import { Post } from '@/interfaces/post';

export type PostContextContent = {
  authors: Author[];
  emailPreviewTextEdited: boolean;
  emailSubjectEdited: boolean;
  formData: Post | undefined;
  isSaving: boolean;
  publishPost?: UseMutationResult<AxiosResponse<any, any>, unknown, void, unknown>;
  publishUpdates: any;
  showIncludeExcludeSegments: boolean;
  showScheduleModal: boolean;
  thumbnail_id: string | null;
  tiersEdited: boolean;
  unsavedChanges: boolean;

  onChange: (data: any) => void;
  setEmailPreviewTextEdited: (edited: boolean) => void;
  setEmailSubjectEdited: (edited: boolean) => void;
  setIsSaving: (isSaving: boolean) => void;
  setPostEditorStep?: Dispatch<SetStateAction<PostEditorSteps>>;
  setShowIncludeExcludeSegments: React.Dispatch<React.SetStateAction<boolean>>;
  setShowScheduleModal: (value: boolean) => void;
  setTiersEdited: React.Dispatch<React.SetStateAction<boolean>>;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
};

export const PostContext = createContext<PostContextContent>({
  authors: [],
  emailPreviewTextEdited: false,
  emailSubjectEdited: false,
  formData: undefined,
  isSaving: false,
  showIncludeExcludeSegments: false,
  showScheduleModal: false,
  thumbnail_id: null,
  tiersEdited: false,
  unsavedChanges: false,

  onChange: () => {},
  publishUpdates: () => {},
  setEmailPreviewTextEdited: () => {},
  setEmailSubjectEdited: () => {},
  setIsSaving: () => {},
  setPostEditorStep: () => {},
  setShowIncludeExcludeSegments: () => {},
  setShowScheduleModal: () => {},
  setTiersEdited: () => {},
  setUnsavedChanges: () => {},
});

export const usePostContext = () => useContext(PostContext);
