import { useInfiniteQuery } from 'react-query';

import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  backlinks: any[];
  pagination: Pagination;
}

interface Props {
  postId: string;
  publicationId: string;
  type: 'incoming' | 'outgoing';
}

export const usePostBacklinks = ({ postId, publicationId, type }: Props) => {
  const fetchPostBacklinks = ({ pageParam = 1 }) =>
    api
      .get(`/posts/${postId}/backlinks`, {
        params: {
          publication_id: publicationId,
          page: pageParam,
          per_page: 20,
          type,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>([postId, 'posts', 'backlinks', publicationId, type], fetchPostBacklinks, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
    enabled: !!postId && !!publicationId,
    refetchOnWindowFocus: true,
  });
};
