import { Link } from 'react-router-dom';
import moment from 'moment-mini';

import MetricsBadge from '@/components/_domain/MetricsBadge';
import { PollResourcePreview } from '@/interfaces/poll';
import { Button } from '@/ui/Button';

interface Props {
  resources: PollResourcePreview[];
  hasNextPage: boolean | undefined;
  fetchNextPage: Function;
  isFetchingNextPage: boolean;
}

const List = ({ resources, hasNextPage, fetchNextPage, isFetchingNextPage }: Props) => {
  return (
    <>
      <ul className="divide-y border rounded">
        {resources.map((resource) => (
          <li className="p-4 " key={resource.id}>
            <div className="flex justify-between items-start mb-2">
              <div className="w-2/3">
                <p className="text-gray-800 font-semibold truncate">
                  <Link to={resource.redirect_path} target="_blank" className="hover:text-gray-900">
                    {resource.title}
                  </Link>
                </p>
                {resource.subtitle && <p className="text-gray-500 text-sm truncate mb-2">{resource.subtitle}</p>}
              </div>
              <p className="capitalize text-sm text-gray-500 italic">{resource.purpose}</p>
            </div>

            <div className="flex space-x-2 justify-between items-end">
              <div className="flex items-center flex-wrap gap-2">
                {resource.stats_overview && (
                  <div className="flex items-center">
                    <MetricsBadge
                      totalSent={resource.stats_overview.total_sent}
                      openRate={resource.stats_overview.open_rate}
                      clickRate={resource.stats_overview.click_rate}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="mt-2">
              <time className="text-xs text-gray-500" dateTime={resource.send_timestamp}>
                {moment(resource.send_timestamp).format('LLL')}
              </time>
            </div>
          </li>
        ))}
      </ul>

      {hasNextPage && (
        <Button
          block
          variant="primary-inverse"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage ? 'Loading more...' : 'Load more'}
        </Button>
      )}
    </>
  );
};

export default List;
