import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { cx } from 'class-variance-authority';

interface PaywallModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const PaywallModal = ({ isOpen, onClose, children }: PaywallModalProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className={cx('relative')} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed no-scrollbar overflow-y-auto inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <section className="modal z-50 fixed inset-0 h-screen w-screen overflow-y-scroll">
            <div className="flex min-h-full justify-center items-center">
              <Dialog.Panel
                className={cx(
                  'model-guts flex flex-col mx-auto bg-gray-100 rounded-lg w-[80vw] h-[90vh] text-left shadow-xl'
                )}
              >
                {children}
              </Dialog.Panel>
            </div>
          </section>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default PaywallModal;
