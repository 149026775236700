import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

// import { ReactNodeViewRenderer } from '@tiptap/react';
import { ListItemView } from './views/ListItemView';

export const ListItem = Node.create<{}, {}>({
  name: 'listItem',

  content: 'paragraph block*',

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['li', HTMLAttributes, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ListItemView);
  },
});

export default ListItem;
