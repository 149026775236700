import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { TableOfContentsView } from './views';

export enum ListType {
  Unordered = 'unordered',
  Ordered = 'ordered',
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    tableOfContents: {
      setTableOfContents: () => ReturnType;
      setListType: (type: ListType) => ReturnType;
      toggleShowTitle: () => ReturnType;
    };
  }
}

export const TableOfContents = Node.create({
  name: 'tableOfContents',

  group: 'block',

  atom: true,

  selectable: true,

  draggable: true,

  inline: false,

  addAttributes() {
    return {
      listType: {
        default: ListType.Unordered,
        parseHTML: (element) => element.getAttribute('data-list-type'),
        renderHTML: (attributes) => ({
          'data-list-type': attributes.listType,
        }),
      },
      showTitle: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-show-title'),
        renderHTML: (attributes) => ({
          'data-show-title': attributes.showTitle,
        }),
      },
      title: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-title'),
        renderHTML: (attributes) => ({
          'data-title': attributes.title,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': `div[data-type="${this.name}"]` })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TableOfContentsView);
  },

  addCommands() {
    return {
      setTableOfContents:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
          });
        },
      setListType:
        (listType: ListType) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { listType });
        },
      toggleShowTitle:
        () =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { showTitle: !this.editor.getAttributes(this.name).showTitle });
        },
    };
  },
});
