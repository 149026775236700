import { useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { Typography } from '@/components/Typography';
import UrlCopy from '@/components/UrlCopy';
import { usePost } from '@/hooks';
import useResetPostGiftSlug from '@/hooks/useResetPostGiftSlug';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  postId: string;
}

const GiftLinkModal = ({ isOpen, onClose, postId }: Props) => {
  const { data: post, refetch: refetchPost, isFetching: isFetchingPost, isRefetching } = usePost({ id: postId });
  const giftLinkUrl = post ? `${post.url}?gift_content=${post.gift_slug}` : '';
  const [resetLinkConfirming, setResetLinkConfirming] = useState(false);

  const handleResetSuccess = async () => {
    await refetchPost();
    setResetLinkConfirming(false);
    toast.success('Gift link reset');
  };

  const resetMutation = useResetPostGiftSlug({
    postId,
    onSuccess: () => handleResetSuccess(),
  });

  const handleClose = () => {
    onClose();
    setResetLinkConfirming(false);
  };

  const handleReset = async () => {
    await resetMutation.mutate();
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onProceed={() => (resetLinkConfirming ? handleReset() : handleClose())}
      onClose={handleClose}
      onCancel={() => (resetLinkConfirming ? setResetLinkConfirming(false) : handleClose())}
      hideCancelButton={!resetLinkConfirming}
      resourceId={postId}
      isWorking={resetMutation.isLoading}
      actionText={resetLinkConfirming ? 'Reset' : 'Close'}
      headerText={resetLinkConfirming ? 'Reset Gift Link' : 'Gift Link to Paid Content'}
      descriptionText={
        resetLinkConfirming
          ? 'Are you sure you want to reset the gift link?'
          : 'Share this link to provide readers with free access to all paid content in this post.'
      }
    >
      <div className="mt-4" />
      {resetLinkConfirming ? (
        <Typography token="font-light/text/xs" color="surface" colorWeight="900">
          Anyone using the old link will no longer have access to paid content.
        </Typography>
      ) : (
        <SkeletonLoader
          isLoading={isFetchingPost || isRefetching}
          skeletons={
            <div className="space-y-6 w-full">
              <div className="space-y-2">
                <Skeleton className="h-6 rounded" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-6 rounded" />
              </div>
            </div>
          }
        >
          <div className="flex flex-col gap-2">
            <UrlCopy url={giftLinkUrl} />
            <Typography token="font-light/text/xs" color="surface" colorWeight="900">
              Anyone who visits this link will be able to see paywalled content.{' '}
              <span
                role="button"
                tabIndex={0}
                className="cursor-pointer"
                onClick={() => setResetLinkConfirming(true)}
                onKeyDown={() => setResetLinkConfirming(true)}
              >
                <Typography token="font-medium/text/xs" color="secondary" colorWeight="600">
                  Reset link
                </Typography>
              </span>{' '}
              to revoke access.
            </Typography>
          </div>
        </SkeletonLoader>
      )}
    </ActionModal>
  );
};

export default GiftLinkModal;
