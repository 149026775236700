import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  publicationId: string;
  onSuccess?: (response: any) => void;
  onError?: (err: any) => void;
}

type RetrySvixMessageProps = {
  endpointId: string;
  messageId: string;
};

const useRetrySvixMessage = ({ publicationId, onSuccess, onError }: Props) => {
  const retrySvixMessage = ({ endpointId, messageId }: RetrySvixMessageProps) =>
    api
      .post(`/webhooks/endpoints/${endpointId}/svix_messages/${messageId}/retry`, {
        publication_id: publicationId,
      })
      .then((res) => res.data);

  return useMutation(retrySvixMessage, {
    onSuccess: (response) => {
      onSuccess?.(response);
    },
    onError: (err: any) => {
      if (onError) {
        onError(err);
      } else {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      }
    },
  });
};

export default useRetrySvixMessage;
