import { AutomationConditionName } from '@/interfaces/automations/automation_condition';
import { AutomationStepStepType } from '@/interfaces/automations/automation_step';
import { AutomationTriggerEvent } from '@/interfaces/automations/automation_trigger';
import { Option } from '@/interfaces/general';

import Webhook from './icons/Webhook';
import {
  BinaryBranch,
  EnrollInAutomation,
  Mail,
  ReferringAutomation,
  SubscriberUpdate,
  Trigger,
  WaitFor,
  WaitUntil,
} from './icons';

export const AUTOMATION_BRANCH_WIDTH = 180;
export const AUTOMATION_NODE_WIDTH = 320;
export const AUTOMATION_NODE_HEIGHT = 240;
export const AUTOMATION_NODE_WIDTH_CLASSNAME = 'w-80';
export const AUTOMATION_NODE_HEIGHT_CLASSNAME = 'h-60';
export const AUTOMATION_EDGE_WIDTH_CLASSNAME = 'w-80';
export const AUTOMATION_EDGE_HEIGHT_CLASSNAME = 'h-32';

export const DEFAULT_HANDLE_COLORS = {
  border: 'border-gray-400',
};

export const TRIGGERS_LABEL = 'Triggers';

export const REFERRING_AUTOMATIONS_LABEL = 'Enrolled via Automation';

export const ACTION_STEP_TYPE_LABELS: { [key in AutomationStepStepType]: string } = {
  [AutomationStepStepType.ADD_AUTOMATION_STEP]: 'Add Automation Step',
  [AutomationStepStepType.AUTOMATION_STEP]: 'Automation Step',
  [AutomationStepStepType.BRANCH]: 'True/False Branch',
  [AutomationStepStepType.ENROLL_IN_AUTOMATION]: 'Enroll in Automation',
  [AutomationStepStepType.SEND_EMAIL]: 'Send Email',
  [AutomationStepStepType.UPDATE_SUBSCRIPTION]: 'Update Subscription',
  [AutomationStepStepType.WAIT_FOR]: 'Time Delay',
  [AutomationStepStepType.WAIT_UNTIL]: 'Wait Until',
  [AutomationStepStepType.WEBHOOK]: 'Send Webhook',
};

const defaultTriggerConditions = Object.values(AutomationConditionName);

export const CONDITIONS_BY_AUTOMATION_TRIGGER: { [key in AutomationTriggerEvent]: AutomationConditionName[] } = {
  [AutomationTriggerEvent.API]: defaultTriggerConditions,
  [AutomationTriggerEvent.DOWNGRADE]: defaultTriggerConditions.filter(
    (condition) => ![AutomationConditionName.TIER_ID].includes(condition)
  ),
  [AutomationTriggerEvent.EMAIL_SUBMISSION]: defaultTriggerConditions,
  [AutomationTriggerEvent.FORM_SUBMISSION]: defaultTriggerConditions.filter(
    (condition) => ![AutomationConditionName.FORM_ID].includes(condition)
  ),
  [AutomationTriggerEvent.MANUAL]: defaultTriggerConditions,
  [AutomationTriggerEvent.POLL_SUBMISSION]: defaultTriggerConditions.filter(
    (condition) => ![AutomationConditionName.POLL_ID, AutomationConditionName.POLL_CHOICE_ID].includes(condition)
  ),
  [AutomationTriggerEvent.REFERRAL_ACTION]: defaultTriggerConditions.filter(
    (condition) => ![AutomationConditionName.REFERRAL_ACTION].includes(condition)
  ),
  [AutomationTriggerEvent.SEGMENT_ACTION]: defaultTriggerConditions.filter(
    (condition) => ![AutomationConditionName.SEGMENT_ID].includes(condition)
  ),
  [AutomationTriggerEvent.SIGNUP]: defaultTriggerConditions,
  [AutomationTriggerEvent.UNENGAGED]: defaultTriggerConditions.filter(
    (condition) =>
      ![
        AutomationConditionName.LAST_CLICKED_DAYS_AGO,
        AutomationConditionName.LAST_OPENED_DAYS_AGO,
        AutomationConditionName.LAST_OPENED_OR_CLICKED_DAYS_AGO,
      ].includes(condition)
  ),
  [AutomationTriggerEvent.UPGRADE]: defaultTriggerConditions.filter(
    (condition) => ![AutomationConditionName.TIER_ID].includes(condition)
  ),
};

export const CONDITIONS_DESCRIPTIONS_BY_AUTOMATION_STEP_TYPE: Record<
  AutomationStepStepType,
  { label: string; description: string }
> = {
  [AutomationStepStepType.ADD_AUTOMATION_STEP]: {
    label: '',
    description: '',
  },
  [AutomationStepStepType.AUTOMATION_STEP]: {
    label: '',
    description: '',
  },
  [AutomationStepStepType.BRANCH]: {
    label: 'Branch Conditions',
    description:
      "Specify the conditions on which to branch. Subscribers that match these conditions will flow into the Yes branch, while subscribers that don't match these conditions will flow into the No branch.",
  },
  [AutomationStepStepType.ENROLL_IN_AUTOMATION]: {
    label: '',
    description: '',
  },
  [AutomationStepStepType.SEND_EMAIL]: {
    label: 'Step Conditions',
    description: 'Add conditions to define which subscribers should receive this email.',
  },
  [AutomationStepStepType.UPDATE_SUBSCRIPTION]: {
    label: 'Step Conditions',
    description: 'Add conditions to define which subscribers should be updated.',
  },
  [AutomationStepStepType.WAIT_FOR]: {
    label: '',
    description: '',
  },
  [AutomationStepStepType.WAIT_UNTIL]: {
    label: '',
    description: '',
  },
  [AutomationStepStepType.WEBHOOK]: {
    label: '',
    description: '',
  },
};

export const TRIGGERS_COLORS = {
  background: 'bg-green-300',
  border: 'border-green-400',
  text: 'text-green-800',
  icon: 'text-green-600',
};

export const REFERRING_AUTOMATIONS_COLORS = {
  background: 'bg-orange-100',
  border: 'border-orange-500',
  text: 'text-orange-500',
  icon: 'text-orange-400',
};

export const COLORS_BY_AUTOMATION_STEP_TYPE: { [key in AutomationStepStepType]: any } = {
  [AutomationStepStepType.ADD_AUTOMATION_STEP]: {
    background: 'bg-gray-300',
    border: 'border-gray-900',
    text: 'text-gray-500',
    icon: 'text-gray-400',
  },
  [AutomationStepStepType.AUTOMATION_STEP]: {
    background: 'bg-gray-300',
    border: 'border-gray-900',
    text: 'text-gray-500',
    icon: 'text-gray-400',
  },
  [AutomationStepStepType.BRANCH]: {
    background: 'bg-orange-100',
    border: 'border-orange-500',
    text: 'text-orange-500',
    icon: 'text-orange-400',
  },
  [AutomationStepStepType.ENROLL_IN_AUTOMATION]: {
    background: 'bg-orange-100',
    border: 'border-orange-500',
    text: 'text-orange-500',
    icon: 'text-orange-400',
  },
  [AutomationStepStepType.UPDATE_SUBSCRIPTION]: {
    background: 'bg-violet-100',
    border: 'border-violet-500',
    text: 'text-violet-500',
    icon: 'text-violet-400',
  },
  [AutomationStepStepType.WAIT_UNTIL]: {
    background: 'bg-rose-100',
    border: 'border-rose-500',
    text: 'text-rose-500',
    icon: 'text-rose-400',
  },
  [AutomationStepStepType.WAIT_FOR]: {
    background: 'bg-rose-100',
    border: 'border-rose-500',
    text: 'text-rose-500',
    icon: 'text-rose-400',
  },
  [AutomationStepStepType.SEND_EMAIL]: {
    background: 'bg-blue-100',
    border: 'border-blue-500',
    text: 'text-blue-500',
    icon: 'text-blue-400',
  },
  [AutomationStepStepType.WEBHOOK]: {
    background: 'bg-violet-100',
    border: 'border-violet-500',
    text: 'text-violet-500',
    icon: 'text-violet-400',
  },
};

export const TRIGGERS_ICON = Trigger;

export const REFERRING_AUTOMATIONS_ICON = ReferringAutomation;

export const ICONS_BY_AUTOMATION_STEP_TYPE: { [key in AutomationStepStepType]: any } = {
  [AutomationStepStepType.ADD_AUTOMATION_STEP]: null,
  [AutomationStepStepType.AUTOMATION_STEP]: null,
  [AutomationStepStepType.BRANCH]: BinaryBranch,
  [AutomationStepStepType.ENROLL_IN_AUTOMATION]: EnrollInAutomation,
  [AutomationStepStepType.SEND_EMAIL]: Mail,
  [AutomationStepStepType.UPDATE_SUBSCRIPTION]: SubscriberUpdate,
  [AutomationStepStepType.WAIT_FOR]: WaitFor,
  [AutomationStepStepType.WAIT_UNTIL]: WaitUntil,
  [AutomationStepStepType.WEBHOOK]: Webhook,
};

export const ACTION_ITEMS: Option[] = [
  { label: 'Messages', isOptGroup: true, textColor: 'text-blue-500', value: '' },
  {
    label: ACTION_STEP_TYPE_LABELS[AutomationStepStepType.SEND_EMAIL],
    value: AutomationStepStepType.SEND_EMAIL,
    icon: ICONS_BY_AUTOMATION_STEP_TYPE[AutomationStepStepType.SEND_EMAIL],
  },
  { label: 'Data', isOptGroup: true, textColor: 'text-violet-500', value: '' },
  {
    label: ACTION_STEP_TYPE_LABELS[AutomationStepStepType.UPDATE_SUBSCRIPTION],
    value: AutomationStepStepType.UPDATE_SUBSCRIPTION,
    icon: ICONS_BY_AUTOMATION_STEP_TYPE[AutomationStepStepType.UPDATE_SUBSCRIPTION],
  },
  {
    label: ACTION_STEP_TYPE_LABELS[AutomationStepStepType.WEBHOOK],
    value: AutomationStepStepType.WEBHOOK,
    icon: ICONS_BY_AUTOMATION_STEP_TYPE[AutomationStepStepType.WEBHOOK],
  },
  { label: 'Delays', isOptGroup: true, textColor: 'text-rose-500', value: '' },
  {
    label: ACTION_STEP_TYPE_LABELS[AutomationStepStepType.WAIT_UNTIL],
    value: AutomationStepStepType.WAIT_UNTIL,
    icon: ICONS_BY_AUTOMATION_STEP_TYPE[AutomationStepStepType.WAIT_UNTIL],
  },
  {
    label: ACTION_STEP_TYPE_LABELS[AutomationStepStepType.WAIT_FOR],
    value: AutomationStepStepType.WAIT_FOR,
    icon: ICONS_BY_AUTOMATION_STEP_TYPE[AutomationStepStepType.WAIT_FOR],
  },
  { label: 'Flow control', isOptGroup: true, textColor: 'text-orange-500', value: '' },
  {
    label: ACTION_STEP_TYPE_LABELS[AutomationStepStepType.BRANCH],
    value: AutomationStepStepType.BRANCH,
    icon: ICONS_BY_AUTOMATION_STEP_TYPE[AutomationStepStepType.BRANCH],
  },
  {
    label: ACTION_STEP_TYPE_LABELS[AutomationStepStepType.ENROLL_IN_AUTOMATION],
    value: AutomationStepStepType.ENROLL_IN_AUTOMATION,
    icon: ICONS_BY_AUTOMATION_STEP_TYPE[AutomationStepStepType.ENROLL_IN_AUTOMATION],
  },
];

export const BRANCH_ARM_LABELS: {
  [key: string]: string;
} = {
  yes: 'True',
  no: 'False',
};
