import { useParams } from 'react-router-dom';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import LoadingBox from '@/components/LoadingBox';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useSvixEndpoint from '@/hooks/webhooks/useSvixEndpoint';
import useSvixMessageAttempt from '@/hooks/webhooks/useSvixMessageAttempt';

import Help from '../../../../components/Help';

import Details from './Details';
import Payload from './Payload';

const ShowMessage = () => {
  const currentPublicationId = useCurrentPublicationId();
  const { endpointId, messageId, messageAttemptId } = useParams();

  const {
    data: endpoint,
    isFetching: isFetchingEndpoint,
    isError: isErrorEndpoint,
  } = useSvixEndpoint(currentPublicationId, endpointId as string);

  const { data: messageAttempt } = useSvixMessageAttempt(
    currentPublicationId,
    endpointId as string,
    messageAttemptId as string,
    messageId as string
  );

  return (
    <div className="m-6">
      <div className="px-10 space-y-6">
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings/integrations/webhooks">All Endpoints</Breadcrumbs.Item>
          <Breadcrumbs.Item to={`/settings/integrations/webhooks/endpoints/${endpointId}`}>
            {endpoint?.description || endpointId}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to=".">{messageId}</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex flex-row items-end justify-between">
          <h2 className="text-2xl font-bold">Message Details</h2>
          <Help />
        </div>
        <LoadingBox isLoading={isFetchingEndpoint} isError={isErrorEndpoint}>
          {endpoint && messageAttempt && <Details endpoint={endpoint} messageAttempt={messageAttempt} />}
          {messageAttempt && <Payload payload={messageAttempt.message.payload} />}
        </LoadingBox>
      </div>
    </div>
  );
};

export default ShowMessage;
