import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import Tooltip from '@/components/Tooltip';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';

interface Props {
  to: string;
  text: string;
  hasAccess?: boolean;
}

const SettingsLink = ({ to, text, hasAccess=true }: Props) => {
  const location = useLocation();
  const selected = location.pathname === to || (location.pathname.startsWith(to) && to !== '/' && to !== '/settings');
  const tooltipId = `tooltip-${text}`;

  if(!hasAccess) {
    return (
      <Tooltip
        tooltipClass='text-center'
        id={tooltipId}
        text={NO_PERMISSION_MESSAGE}
        showIcon={false}
        autoWidth={false}
      >
        <div className="ml-6">
          <div
            className={cx(
              'rounded px-2 py-1 text-sm font-medium duration-200 whitespace-nowrap flex items-center space-x-2 w-full',
              'text-surface-400 cursor-not-allowed'
            )}
          >
            {text}
          </div>
        </div>
      </Tooltip>
    )
  }
  return (
    <div className="ml-6">
      <Link
        to={to}
        className={cx(
          'rounded px-2 py-1 text-sm font-medium duration-200 whitespace-nowrap flex items-center space-x-2 w-full',
          selected ? 'text-primary-500 bg-primary-50' : 'text-gray-500 hover:text-gray-900 hover:bg-gray-50'
        )}
      >
        {text}
      </Link>
    </div>
  );
};

export default SettingsLink;
