import cx from "classnames";

interface Props {
  className?: string;
}

const DiscordIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={cx(className)}
    >
      <path
        d="M4.58337 13.334C8.75004 15.4173 11.25 15.4173 15.4167 13.334"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9167 14.584L13.75 16.2507C13.75 16.2507 17.2261 15.1437 18.3333 13.334C18.3333 12.5007 18.7751 6.54514 15.8333 4.58398C14.5833 3.75065 12.5 3.33398 12.5 3.33398L11.6667 5.00065H10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.10698 14.584L6.27365 16.2507C6.27365 16.2507 2.79754 15.1437 1.69031 13.334C1.69031 12.5007 1.24857 6.54514 4.19031 4.58398C5.44031 3.75065 7.52365 3.33398 7.52365 3.33398L8.35696 5.00065H10.0236"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08337 11.6673C6.39302 11.6673 5.83337 10.9212 5.83337 10.0007C5.83337 9.08015 6.39302 8.33398 7.08337 8.33398C7.77373 8.33398 8.33337 9.08015 8.33337 10.0007C8.33337 10.9212 7.77373 11.6673 7.08337 11.6673Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9166 11.6673C12.2263 11.6673 11.6666 10.9212 11.6666 10.0007C11.6666 9.08015 12.2263 8.33398 12.9166 8.33398C13.607 8.33398 14.1666 9.08015 14.1666 10.0007C14.1666 10.9212 13.607 11.6673 12.9166 11.6673Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DiscordIcon;
