interface Props {
  nodeType: string | string[];
  activeNodeType: string;
  children: React.ReactNode | React.ReactNode[];
}

const ActionRenderer = ({ nodeType, activeNodeType, children }: Props) => {
  const isActive = Array.isArray(nodeType) ? nodeType.includes(activeNodeType) : nodeType === activeNodeType;

  if (!isActive) {
    return null;
  }

  return <div className="flex flex-col gap-4">{children}</div>;
};

export default ActionRenderer;
