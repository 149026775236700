import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { EmptyConditionsBlock, GroupButton } from '@/components/ConditionsEditor';
import { andOperatorOption, colorByLogicalOperator, orOperatorOption } from '@/components/ConditionsEditor/constants';
import GroupSelect from '@/components/Form/GroupSelect';
import Tree, { TreeFooter, TreeHeader, TreeItem } from '@/components/Tree';
import {
  AutomationCondition,
  AutomationConditionName,
  AutomationConditionOperator,
  AutomationConditions,
} from '@/interfaces/automations/automation_condition';
import { AutomationTriggerEvent } from '@/interfaces/automations/automation_trigger';
import { LogicalOperators } from '@/interfaces/condition';
import { deepClonePrimitiveValues } from '@/utils/deepClone';

import conditionOperator from '../../utils/conditionOperator';
import triggerEventConditionOptions from '../../utils/triggerEventConditionOptions';

import ConditionBlock from './ConditionBlock';
import TriggerWarnings from './TriggerWarnings';

interface Props {
  mainConditions?: AutomationConditions;
  conditions: AutomationConditions;
  conditionsWithErrors: any;
  triggerEvent?: AutomationTriggerEvent;
  onChange?: (conditions: AutomationConditions) => void;
  onChangeInConditions?: (newConditions: AutomationCondition[]) => void;
  onChangeInLogicalOperator?: (newLogicalOperator: LogicalOperators) => void;
}

const ConditionsForm = ({
  mainConditions,
  conditions,
  conditionsWithErrors,
  triggerEvent,
  onChange,
  onChangeInConditions,
  onChangeInLogicalOperator,
}: Props) => {
  const [currentConditions, setCurrentConditions] = useState<AutomationCondition[]>(conditions?.conditions || []);
  const [logicalOperator, setLogicalOperator] = useState<LogicalOperators>(
    conditions?.logical_operator || LogicalOperators.AND
  );
  const conditionOptions = triggerEventConditionOptions(triggerEvent);

  const addCondition = () => {
    const newConditions = [
      ...currentConditions,
      {
        name: AutomationConditionName.EMAIL,
        operator: AutomationConditionOperator.EQUAL,
        value: '',
      },
    ];
    setCurrentConditions(newConditions);
    onChangeInConditions?.(newConditions);
    onChange?.({
      logical_operator: logicalOperator,
      conditions: newConditions,
    });
  };

  const onChangeCondition = (index: number, name: string, value: string, resourceId?: string) => {
    const newConditions = [...currentConditions];
    const condition = currentConditions[index];
    const newCondition = {
      ...condition,
      [name]: value,
      ...(name === 'name' && { operator: conditionOperator(value as AutomationConditionName, condition.operator) }),
      ...(resourceId && { resource_id: resourceId }),
    };
    newConditions[index] = newCondition;
    setCurrentConditions(newConditions);
    onChangeInConditions?.(newConditions);

    onChange?.({
      logical_operator: logicalOperator,
      conditions: newConditions,
    });
  };

  const removeCondition = (index: number) => {
    const newConditions = [...currentConditions];
    newConditions.splice(index, 1);
    setCurrentConditions(newConditions);
    onChangeInConditions?.(newConditions);
    onChange?.({
      logical_operator: logicalOperator,
      conditions: newConditions,
    });
  };

  const duplicateCondition = (index: number) => {
    const newConditions = [...currentConditions];
    newConditions.splice(index + 1, 0, deepClonePrimitiveValues(newConditions[index]));
    setCurrentConditions(newConditions);
    onChangeInConditions?.(newConditions);
    onChange?.({
      logical_operator: logicalOperator,
      conditions: newConditions,
    });
  };

  const handleLogicalOperatorSelect = (value: LogicalOperators) => {
    setLogicalOperator(value);
    onChangeInLogicalOperator?.(value);
    onChange?.({
      logical_operator: value,
      conditions: currentConditions,
    });
  };

  return (
    <>
      <Tree backgroundClassName={colorByLogicalOperator[logicalOperator].tree}>
        <TreeHeader borderClassName={colorByLogicalOperator[logicalOperator].treeHeader}>
          <div className="flex items-center">
            <GroupSelect
              name="logical_operator"
              options={[andOperatorOption, orOperatorOption]}
              onSelect={handleLogicalOperatorSelect}
              value={logicalOperator}
              buttonClassNames={{
                hover: colorByLogicalOperator[logicalOperator].hoverBackground,
                focus: cx(
                  colorByLogicalOperator[logicalOperator].focusRing,
                  colorByLogicalOperator[logicalOperator].focusBorder
                ),
                cursor: 'cursor-pointer',
                text: 'text-left text-sm text-gray-700 font-bold',
                padding: 'py-1 pl-3 pr-10',
                background: colorByLogicalOperator[logicalOperator].background,
                border: cx('border', colorByLogicalOperator[logicalOperator].border),
              }}
            />
          </div>
        </TreeHeader>

        {currentConditions.length === 0 && <EmptyConditionsBlock />}
        {currentConditions.map((condition, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TreeItem key={index}>
            <ConditionBlock
              onChange={(name: string, value: string | string[], resourceId?: string) =>
                onChangeCondition(index, name, String(value), resourceId)
              }
              onRemove={() => removeCondition(index)}
              onDuplicate={() => duplicateCondition(index)}
              conditionOptions={conditionOptions}
              name={condition.name}
              value={condition.value}
              resourceId={condition.resource_id}
              hasErrors={conditionsWithErrors[index] || false}
              operator={condition.operator}
            />
          </TreeItem>
        ))}
        <TreeFooter borderClassName={colorByLogicalOperator[logicalOperator].treeFooter}>
          <GroupButton onClick={addCondition}>
            <PlusIcon width="15" height="15" className="mr-1" />
            Condition
          </GroupButton>
        </TreeFooter>
      </Tree>

      {triggerEvent && (
        <TriggerWarnings
          event={triggerEvent}
          mainConditions={mainConditions?.conditions || []}
          conditions={currentConditions}
        />
      )}
    </>
  );
};

export default ConditionsForm;
