import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { AllSelection, NodeSelection } from '@tiptap/pm/state';
import { Editor, useEditorState } from '@tiptap/react';

import { ActiveNodeResult } from '../types';

export const useActiveNode = (e: Editor): ActiveNodeResult => {
  return useEditorState({
    editor: e,
    selector: ({ editor }) => {
      const { selection } = editor.state;

      let activeNodeType = '';
      let activeNodePos = -1;
      let activeNodeAttributes: Record<string, any> = {};
      let activeNode: ProseMirrorNode | null = null;

      if (selection instanceof NodeSelection) {
        activeNode = selection.node;
        activeNodeType = activeNode.type.name;
        activeNodeAttributes = activeNode.attrs;
        activeNodePos = selection.$anchor.pos;
      } else if (selection instanceof AllSelection) {
        activeNode = selection.$anchor.parent;
        activeNodeType = selection.$anchor.parent.type.name;
        activeNodeAttributes = selection.$anchor.parent.attrs;
        activeNodePos = 0;
      } else {
        // leaf node, select the parent
        const parentNode = selection.$anchor.parent;
        activeNode = parentNode;
        activeNodeType = parentNode.type.name;
        activeNodeAttributes = parentNode.attrs;
        activeNodePos = selection.$head.start(selection.$head.depth) - 1;
      }

      return {
        activeNode,
        activeNodeType,
        activeNodePos,
        activeNodeEnd: activeNodePos + activeNode.nodeSize,
        activeNodeAttributes,
        selection,
      };
    },
    equalityFn: (a, b) => {
      if (!a || !b) return false;

      return a.selection.eq(b.selection) && a.activeNode.eq(b.activeNode);
    },
  });
};
