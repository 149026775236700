import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { useThemeData } from '../../../../../../lib/hooks/useThemeData';
import { Button } from '../../../../../ui/Button';
import { Icon } from '../../../../../ui/Icon';
import { Panel, PanelFooter, PanelHeader } from '../../../../../ui/Panel';
import { PanelSection } from '../../../../../ui/Panel/styled';
import { useCurrentNode } from '../../../../hooks';
import Styled from '../../../../styled';
import useVisualSettingsPanel from '../VisualSettingsPanel.hooks';

import { Margin } from './Margin';
import { Padding } from './Padding';

export const SpacingsPanel = ({
  editor,
  onBack,
  navigateToPanel,
  currentNode,
  currentNodePos,
}: {
  editor: Editor;
  onBack: Function;
  currentNode: Node | null;
  currentNodePos: number;
  navigateToPanel: Function;
}) => {
  const { onResetSpacings } = useVisualSettingsPanel(editor, currentNode, currentNodePos, navigateToPanel);
  const themeColors = useThemeData('colors');
  const { hasSpacingOverrides } = useCurrentNode(editor, currentNode, themeColors);

  const renderDefaultPanel = () => (
    <Styled.ContentWrapper>
      <Panel>
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            Spacings
          </Button>
        </PanelHeader>
        <PanelSection>
          <Padding editor={editor} currentNode={currentNode} currentNodePos={currentNodePos} />
          <Margin editor={editor} currentNode={currentNode} currentNodePos={currentNodePos} />
        </PanelSection>
        {hasSpacingOverrides && (
          <PanelFooter>
            <Button
              $variant="quaternary"
              $size="small"
              $rightSlot={<Icon name="Reset" />}
              onClick={onResetSpacings}
              $fullWidth
              $active
              $muted
            >
              Reset all
            </Button>
          </PanelFooter>
        )}
      </Panel>
    </Styled.ContentWrapper>
  );

  return renderDefaultPanel();
};

export default SpacingsPanel;
