import { LoadingSpinner } from '@/components/LoadingSpinner';

const LoadingMessage = () => (
  <div className="flex flex-col xl:flex-row h-[calc(100%_-_54px)] items-center justify-center">
    <LoadingSpinner className="mr-2" />
    <p className="text-gray-400 text-sm">Loading…</p>
  </div>
);

export default LoadingMessage;
