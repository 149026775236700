import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { Input, RadioSelect } from '@/components/Form';
import SlideOver from '@/components/SlideOver';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { CustomField } from '@/interfaces/custom_field';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { labels } from '@/utils/dataTypes';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (customField: CustomField) => void;
  isMultipleChoiceSelected: boolean;
  isDropdownSelected: boolean;
  isLongTextSelected: boolean;
}

const NewCustomField = ({
  isOpen,
  onClose,
  onSuccess,
  isMultipleChoiceSelected,
  isDropdownSelected,
  isLongTextSelected,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const [customFieldName, setCustomFieldName] = useState('');

  let dataTypeOptions = Object.entries(labels).map(([value, label]) => ({ value, name: label }));
  const [kind, setKind] = useState<any>(dataTypeOptions[0].value);

  if (isMultipleChoiceSelected) {
    dataTypeOptions = dataTypeOptions.filter((option) => option.value === 'string' || option.value === 'list');
  } else if (isDropdownSelected || isLongTextSelected) {
    dataTypeOptions = dataTypeOptions.filter((option) => option.value === 'string');
  } else {
    dataTypeOptions = dataTypeOptions.filter((option) => option.value !== 'list');
  }

  const createCustomFieldMutation = useMutation(
    () =>
      api.post('/custom_fields', {
        publication_id: currentPublicationId,
        custom_field: {
          display: customFieldName,
          kind,
        },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (res: any) => {
        toast.success('Custom Field created successfully');
        onClose();

        if (res?.data) {
          onSuccess(res.data);
        }
      },
    }
  );

  const handleSubmit = () => {
    createCustomFieldMutation.mutate();
  };

  const isDisabled = () => !customFieldName && !kind;
  return (
    <SlideOver bodyId="" isOpen={isOpen} onClose={onClose} headerText="New Custom Field" className="z-50">
      <div className="space-y-3">
        <div className="w-full">
          <div className="space-y-6">
            <Input
              name="name"
              labelText="Name"
              type="text"
              placeholder='e.g. "First Name"'
              value={customFieldName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomFieldName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="w-full">
          <div className="space-y-6 z-30">
            <RadioSelect required labelText="Data Type" value={kind} options={dataTypeOptions} onSelect={setKind} />
          </div>
        </div>
      </div>

      <div className="pt-8">
        <Button
          type="button"
          disabled={isDisabled()}
          loading={createCustomFieldMutation.isLoading}
          onClick={handleSubmit}
        >
          {createCustomFieldMutation.isLoading ? 'Creating...' : 'Create Custom Field'}
        </Button>
      </div>
    </SlideOver>
  );
};

export default NewCustomField;
