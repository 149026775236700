import AppliedFilter from '../AppliedFilter';
import { AttributeCondition, LanguageOperatorsList } from '../types';

const LanguageFilterApplied = ({ condition, onRemove }: { condition: AttributeCondition; onRemove: () => void }) => {
  const operatorLabel = LanguageOperatorsList.find((operator) => operator.value === condition.filters.operator)?.label;

  return (
    <AppliedFilter name="Language" label={operatorLabel || ''} value={condition.filters.value} onRemove={onRemove} />
  );
};

export default LanguageFilterApplied;
