import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import { AdNetworkAdvertiserPublisherListItem } from '@/interfaces/ad_network/internal/advertiser_publisher_list_item';
import { MutationErrorResponse } from '@/interfaces/ad_network/internal/mutation_error_response';
import api from '@/services/swarm';

interface Variables {
  advertiserId: string;
  publisherId: string;
  listType: string;
}

export default function usePublisherListItemCreate() {
  const { currentUser } = useCurrentUser();

  const createAdvertiserPublisherListItem = ({
    advertiserId,
    publisherId,
    listType,
  }: Variables): Promise<AdNetworkAdvertiserPublisherListItem> =>
    api
      .post(`/ad_network/internal/advertisers/${advertiserId}/publisher_list_items`, {
        list_type: listType,
        publisher_list_item: {
          publisher_id: publisherId,
          added_by_id: currentUser?.id,
          added_by_type: currentUser ? 'User' : null,
        },
      })
      .then((res) => res.data);

  return useMutation<AdNetworkAdvertiserPublisherListItem, MutationErrorResponse, Variables>(
    createAdvertiserPublisherListItem,
    {
      onError: (error) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          toast.error(`${key} ${errors[key][0]}`);
        });
      },
    }
  );
}
