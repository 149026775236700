import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';

import { usePostContext } from '../../PostContext';

const VisibilitySettings = () => {
  const { formData, onChange } = usePostContext();
  const displayThumbnailOnWeb = !!formData?.display_thumbnail_on_web;

  const onToggleShowInEmail = () => {
    onChange({ display_thumbnail_on_web: !displayThumbnailOnWeb });
  };

  return (
    <Styled.ContentWrapper>
      <Panel>
        <PanelHeader>
          <h3 className="px-2 p-1 pt-2 text-sm text-surface-200 font-medium">Visibility</h3>
          <p className="text-xs px-2">
            This image is used as the thumbnail for your web post and for SEO. It&rsquo;s also shown at the top of the
            web post, which you can toggle off.
          </p>
        </PanelHeader>
        <PanelSection>
          <Styled.PanelSectionInner>
            <ToggleWrapper
              icon={<Icon name="Globe" className="text-surface-200" />}
              switchEl={<ToggleSwitch isChecked={displayThumbnailOnWeb} onChange={onToggleShowInEmail} />}
            >
              Show at top of web post
            </ToggleWrapper>
          </Styled.PanelSectionInner>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default VisibilitySettings;
