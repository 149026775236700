import { Author } from '../../interfaces/author';

type AuthorsBadgeProps = {
  authors: Author[];
  maxDisplayed?: number;
};

const AuthorsBadge = ({ authors, maxDisplayed = 3 }: AuthorsBadgeProps) => {
  if (!authors || authors.length === 0) return null;

  return (
    <div className="inline-flex items-center space-x-2 text-gray-500">
      <div className="flex flex-shrink-0 -space-x-1 items-center">
        {authors.slice(0, maxDisplayed).map((author: any) => (
          <img
            key={author.id}
            className="max-w-none h-5 w-5 rounded-full ring-2 ring-gray-50"
            src={author.profile_picture.thumb.url}
            alt={author.name}
            title={author.name}
          />
        ))}
      </div>
      {authors.length > maxDisplayed && (
        <span className="flex-shrink-0 text-xs leading-5 font-medium">+ {authors.length - maxDisplayed} more</span>
      )}
    </div>
  );
};

export default AuthorsBadge;
