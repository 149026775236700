import AppliedFilter from '../AppliedFilter';
import { AttributeCondition, NumberOperatorsList } from '../types';

const SentPostsFilterApplied = ({ condition, onRemove }: { condition: AttributeCondition; onRemove: () => void }) => {
  const operator = NumberOperatorsList.find((o) => o.value === condition.filters.operator);
  if (!operator) return null;

  const operatorLabel = operator.shorthand;

  return <AppliedFilter name="Posts Sent" value={condition.filters.value} label={operatorLabel} onRemove={onRemove} />;
};

export default SentPostsFilterApplied;
