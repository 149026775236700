import { FC } from 'react';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
  columns?: 1 | 2 | 3 | 4 | 5 | 6;
}

const colClasses = {
  1: 'md:grid-cols-1',
  2: 'md:grid-cols-2',
  3: 'md:grid-cols-3',
  4: 'md:grid-cols-4',
  5: 'md:grid-cols-5',
  6: 'md:grid-cols-6',
};

const KPIGroup: FC<Props> = (props: Props) => {
  const { children, className, columns = 3 } = props;

  return (
    <div className={className}>
      <div
        className={cx(
          'dl grid grid-cols-1 rounded-lg bg-white overflow-hidden border border-surface-200 divide-y divide-surface-200 md:divide-y-0 md:divide-x',
          colClasses[columns || 3]
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default KPIGroup;
