import styled from 'styled-components';

const Styled = {
  ButtonWrapper: styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    & > *,
    button {
      flex: 1;
      justify-content: center;
      width: 100%;
    }
  `,
};

export default Styled;
