import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../../../context/current-publication-context';
import { BoostSend } from '../../../../interfaces/boosts/grow/boost_send';
import api from '../../../../services/swarm';

const useBoostSend = (boostSendId: string) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchBoostSend = (): Promise<BoostSend> =>
    api
      .get(`/boosts/grow/boost_sends/${boostSendId}`, {
        params: { publication_id: currentPublicationId },
      })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'boosts', 'grow', 'boost_sends', boostSendId], fetchBoostSend, {
    keepPreviousData: true,
    staleTime: 30000,
    enabled: !!boostSendId,
  });
};

export default useBoostSend;
