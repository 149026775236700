import { Quotes } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';

type Props = AttributeSettingProps & {};

export const MessageSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Message" text="Message" popoverTitle="Message" Icon={Quotes}>
      <>
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          property="messageFontFamily"
          title="Font"
        />
        <SimpleFontSizeSettings
          title="Size"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="messageFontSize"
        />
        <ColorSettings title="Color" property="messageColor" editor={editor} activeNodeResult={activeNodeResult} />
      </>
    </PopoverHelper>
  );
};

export default MessageSettings;
