import React from 'react';
import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { UpgradeIntentPlaceholder } from '../../components/ui/UpgradeIntentPlaceholder';

import { ReferralProgramView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    referralProgram: {
      setReferralProgram: () => ReturnType;
    };
  }
}

export const ReferralProgram = Node.create({
  name: 'referralProgram',

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      publicationId: '',
      enabled: false,
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div.node-${this.name}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setReferralProgram:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
            })
            .run(),
    };
  },

  addNodeView() {
    if (!this.options.enabled) {
      return ReactNodeViewRenderer(
        () => (
          <UpgradeIntentPlaceholder
            description="You need to upgrade to access the referral program."
            url="https://www.beehiiv.com/newsletter-solutions/grow"
            iconName="ReferralProgram"
          />
        ),
        { className: 'upgrade-intent' }
      );
    }

    return ReactNodeViewRenderer(ReferralProgramView);
  },
});

export default ReferralProgram;
