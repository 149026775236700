import { useQuery } from 'react-query';

import { PostOpens } from '../interfaces/post';
import api from '../services/swarm';

interface Arguments {
  id: string;
  useApiary?: boolean;
}

export default function usePostOpens({ id, useApiary = false }: Arguments) {
  return useQuery<PostOpens>(
    ['posts', 'opens', id],
    () =>
      api
        .get(`/posts/${id}/opens`, {
          params: {
            use_apiary: useApiary === true ? '1' : undefined,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
      enabled: !!id,
    }
  );
}
