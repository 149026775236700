import { useEffect, useState } from 'react';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

import { useCurrentUser } from '@/context/current-user-context';
import { useTeamMembers } from '@/hooks/useTeamMembers';
import { Button } from '@/ui/Button';

import useFetchTeamCount from '../_hooks/useFetchTeamCount';

import { InviteFormRowData, InviteFormRowErrors } from './_interfaces/invite';
import InviteFormRow from './InviteFormRow';

interface Props {
  onErrorChange: (errors: InviteFormRowErrors[]) => void;
  onInviteChange: (invites: InviteFormRowData[]) => void;
  organizationId: string;
  contributorEnabled?: boolean;
}

const InviteForm = ({ onErrorChange, onInviteChange, organizationId, contributorEnabled }: Props) => {
  const defaultInvite: InviteFormRowData = {
    email: '',
    role: 'member',
    permissions: 'workspace',
    publications: [],
    key: `invite-${Math.floor(Math.random() * 9999)}`,
  };
  const [inviteList, setInviteList] = useState<InviteFormRowData[]>([defaultInvite]);
  const [errors, setErrors] = useState<InviteFormRowErrors[]>([]);
  const { data: teamCountData, isSuccess } = useFetchTeamCount(organizationId);
  const {
    team_max: teamMax,
    team_emails: teamEmails
  } = teamCountData || {team_max: 0, team_emails: []};
  const combinedEmails = [
    ...inviteList.map((invite) => invite.email),
    ...teamEmails,
  ];

  const uniqueEmailCount = new Set(combinedEmails).size;
  const canAddMembers = isSuccess && uniqueEmailCount < teamMax;

  const handleAddNewInvite = () => {
    setInviteList([...inviteList, defaultInvite]);
  };

  const {data: teamMembersData} = useTeamMembers({ organizationId, allResults: true });
  const teamMembers = teamMembersData?.pages.flatMap((page) => page.team_members).filter(Boolean) || [];
  const activeTeamMembers = teamMembers.filter((member) => member.status === 'accepted' || member.status === 'pending');
  const { currentUser } = useCurrentUser();

  const handleRemoveInvite = (key: string) => {
    setInviteList(inviteList.filter((invite) => invite.key !== key));
    setErrors(errors.filter((error) => error.key !== key));
  };

  useEffect(() => {
    onInviteChange(inviteList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteList]);

  const checkForDuplicateRows = (newInviteList: InviteFormRowData[]) => {
    const viewedIdentifiers = new Set<string>();
    const inviteDuplicates = newInviteList.filter((invite) => {
      const identifier = `${invite.email}-${invite.role}-${invite.permissions}-${JSON.stringify(invite.publications)}`;

      // Don't show error on incomplete rows
      if (invite.email === '' || (invite.permissions === 'publication' && invite.publications.length === 0)) {
        return false;
      }

      if (viewedIdentifiers.has(identifier)) {
        return true;
      }

      viewedIdentifiers.add(identifier);
      return false;
    });

    inviteDuplicates.forEach((duplicate) => {
      setErrors((prev) => [
        ...prev,
        {
          email: 'Duplicate entry: This email and role combination has already been assigned',
          key: duplicate.key,
        },
      ]);
    });
  };

  const checkForExistingPublicationAssignments = (newInviteList: InviteFormRowData[]) => {
    const teamMemberDuplicates = newInviteList.filter((invite) => {
      // Don't show error on incomplete rows
      if (invite.email === '' || (invite.permissions === 'publication' && invite.publications.length === 0)) {
        return false;
      }

      if (invite.permissions === 'workspace') {
        return false;
      }

      return activeTeamMembers.some((member) => {
        return member.email === invite.email
          && member.role_name === invite.role
          && invite.permissions === 'publication'
          && invite.publications.includes(member.assignable_id);
      });
    });

    teamMemberDuplicates.forEach((duplicate) => {
      const matchingTeamMember = activeTeamMembers.find((member) => {
        return member.email === duplicate.email
          && member.role_name === duplicate.role
          && duplicate.permissions === 'publication'
          && duplicate.publications.includes(member.assignable_id);
      });

      setErrors((prev) => [
        ...prev,
        {
          publications: `Duplicate entry: This email already has this role in ${matchingTeamMember?.publication_name}`,
          key: duplicate.key,
        },
      ]);
    });
  };

  const checkForExistingWorkspaceAssignments = (newInviteList: InviteFormRowData[]) => {
    const teamMemberDuplicates = newInviteList.filter((invite) => {
      // Don't show error on incomplete rows
      if (invite.email === '' || invite.permissions === 'publication') {
        return false;
      }

      return activeTeamMembers.some((member) => {
        return member.email === invite.email && member.role_name === invite.role;
      });
    });

    teamMemberDuplicates.forEach((duplicate) => {
      setErrors((prev) => [
        ...prev,
        {
          permissions: 'Duplicate entry: This email already has this role in the workspace',
          key: duplicate.key,
        },
      ]);
    });
  };

  const checkForSelfInvites = (newInviteList: InviteFormRowData[]) => {
    const selfInvites = newInviteList.filter((invite) => {
      if (currentUser) { return invite.email === currentUser.email; }
      return false;
    });

    selfInvites.forEach((selfInvite) => {
      setErrors((prev) => [
        ...prev,
        {
          email: 'Cannot invite yourself',
          key: selfInvite.key,
        },
      ]);
    });
  }

  const handleInviteChange = (invite: InviteFormRowData) => {
    const newInviteList: InviteFormRowData[] = [...inviteList.map((i) => (i.key === invite.key ? invite : i))];
    setInviteList(newInviteList);
    setErrors([]);
    checkForDuplicateRows(newInviteList);
    checkForExistingPublicationAssignments(newInviteList);
    checkForExistingWorkspaceAssignments(newInviteList);
    checkForSelfInvites(newInviteList);
  };

  useEffect(() => {
    onErrorChange(errors);
  }, [errors, onErrorChange]);

  return (
    <div className="space-y-4 w-full">
      {inviteList.map((invite) => (
        <div key={invite.key} className="flex flex-row w-full space-x-2">
          <InviteFormRow
            key={invite.key}
            inviteData={invite}
            onInviteChange={handleInviteChange}
            errors={errors.find((error) => error.key === invite.key)}
            contributorEnabled={contributorEnabled}
          />
          {inviteList.length > 1 && (
            <div>
              <TrashIcon
                className="h-6 w-6 mt-8 text-red-500 cursor-pointer"
                onClick={() => handleRemoveInvite(invite.key)}
              />
            </div>
          )}
        </div>
      ))}
      <Button
        onClick={handleAddNewInvite}
        Icon={PlusIcon}
        variant="primary-inverse"
        disabled={!canAddMembers}
        tooltip={canAddMembers ? '' : 'You have reached the maximum number of team members for your plan. Upgrade to add more.'}
      >
        Add New User
      </Button>
    </div>
  );
};

export default InviteForm;
