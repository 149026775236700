import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, ClockCounterClockwise, Plus } from '@phosphor-icons/react';

import { useWebsiteContext, WebsiteProvider } from '@/context/website-context';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';
import { cn } from '@/utils/cn';

import { ATTRIBUTES_PANEL_ID, VERSION_HISTORY_PANEL_ID } from '../../_components/DreamEditor/constants';
import { EditorHeader, EditorHeaderBreadcrumb } from '../../_components/EditorHeader/EditorHeader';
import { Layout } from '../../_components/Layout';
import { ActionMenu } from '../../_components/Layout/ActionMenu';
import { NavSection } from '../../_components/SideNav/NavSection';
import { SaveNavbarIndicator } from '../../_components/SideNav/SaveIndicator';
import { Button, CloseButton } from '../../_components/UI/Button';
import { Tabs, TabsList, TabsTrigger } from '../../_components/UI/Tabs';
import { Text } from '../../_components/UI/Text';
import { Tooltip } from '../../_components/UI/Tooltip';
import VersionHistoryNotice from '../../_components/VersionHistoryNotice';
import { NavbarProvider } from '../../navbar/_components/NavbarEditor/NavbarContext';
import { NavbarEditor } from '../../navbar/_components/NavbarEditor/NavbarEditor';
import { useContainerRef } from '../../page/_hooks';

import { TemplateNavbarDataProvider } from './context';

const NavbarEditorPage = () => {
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const { previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'desktop' | 'mobile'>('desktop');

  const { sitePackageId } = useParams();
  const { data: sitePackage } = useSitePackage({ sitePackageId: sitePackageId as string });

  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion]);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { rect: editorRect, containerRef: editorContainerRef } = useContainerRef();
  const navigate = useNavigate();

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary pb-0 pt-0"
      isSidebarResizable
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
        />
      }
      rightChildren={
        <Tooltip center="Version History" delay={300}>
          <Button
            variant="secondary"
            Icon={ClockCounterClockwise}
            iconWeight="bold"
            onClick={() => setIsVersionHistoryPanelOpen((prev) => !prev)}
            className="h-full"
          />
        </Tooltip>
      }
      sidenavChildren={
        <>
          <div className="flex-1">
            <NavSection>
              <Button
                variant="secondary"
                size="sm"
                LeftIcon={ArrowLeft}
                className="w-full py-3"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </NavSection>
          </div>
          <NavSection>
            <SaveNavbarIndicator />
          </NavSection>
        </>
      }
      rightSideChildren={
        <>
          <div
            className="w-[266px] min-w-[266px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-auto"
            id={ATTRIBUTES_PANEL_ID}
            style={{
              scrollbarGutter: 'stable',
            }}
          />
          <div
            id={VERSION_HISTORY_PANEL_ID}
            className={cn(
              'absolute top-0 right-0 bottom-0 w-[250px] transition-transform duration-250 bg-wb-primary border-l border-solid overflow-y-auto p-3',
              isVersionHistoryPanelOpen ? 'translate-x-0' : `translate-x-[500px]`
            )}
          >
            <CloseButton
              onClose={() => setIsVersionHistoryPanelOpen(false)}
              className="-translate-x-1/2 translate-y-1/2"
            />
          </div>
        </>
      }
      titleType="template_navbar"
    >
      <div className="w-full h-full flex flex-col items-center">
        <EditorHeader type="navbar-template" iconType="component">
          {sitePackage?.id && (
            <EditorHeaderBreadcrumb text="Projects" linkTo="/website_builder_v2/projects" iconType="folder" />
          )}
          <EditorHeaderBreadcrumb
            text={sitePackage?.name || 'Templates'}
            iconType="folder"
            linkTo={`/website_builder_v2/projects/${sitePackage?.id}`}
          />
        </EditorHeader>
        <Tabs
          defaultValue="desktop"
          className="flex flex-col"
          value={selectedTab}
          onValueChange={(value) => setSelectedTab(value as 'desktop' | 'mobile')}
        >
          <TabsList className="p-2" defaultValue="desktop">
            <TabsTrigger value="desktop">
              <Text weight="medium" size="2xs" className="leading-0">
                Desktop
              </Text>
            </TabsTrigger>
            <TabsTrigger value="mobile">
              <Text weight="medium" size="2xs" className="leading-0">
                Mobile
              </Text>
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <div
          ref={editorContainerRef}
          className={cn('flex-1 min-h-0 bg-transparent relative', selectedTab === 'mobile' ? 'w-[300px]' : 'w-full')}
        >
          <NavbarProvider
            iframeRef={iframeRef}
            editorContainerRef={editorContainerRef}
            contentWidth={selectedTab === 'mobile' ? 400 : 1024}
          >
            <NavbarEditor
              iframeRef={iframeRef}
              editorRect={editorRect}
              contentWidth={selectedTab === 'mobile' ? 400 : 1024}
              isPreview={selectedTab === 'mobile'}
            />
          </NavbarProvider>
        </div>
      </div>
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <TemplateNavbarDataProvider>
      <NavbarEditorPage />
    </TemplateNavbarDataProvider>
  </WebsiteProvider>
);
