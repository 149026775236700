import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PollChoice } from '@/interfaces/poll';
import api from '@/services/swarm';

interface ApiResponse {
  poll_choices: PollChoice[];
}

interface Props {
  id: string;
  enabled?: boolean;
}

const usePollChoices = ({ id, enabled }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useQuery<ApiResponse>(
    ['polls', id, 'poll_choices'],
    () =>
      api
        .get(`/polls/${id}/poll_choices`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => res.data),
    {
      enabled,
    }
  );
};

export default usePollChoices;
