/* eslint-disable */

import cx from 'classnames';
import { useWebTemplate, useUpdateWebTemplate } from '../../../../hooks';
import { PartialPost, Post } from '../../../../interfaces/post';
import { useEffect, useState } from 'react';
import SortFeaturedPosts from '../../../../components/SortableList/SortableLists/SortFeaturedPosts';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

import { Checkbox } from '../../../../components/Form';
import Modal from '../../../../components/Modal';

type FeaturedPosts = Array<Post | PartialPost>;

interface FeaturedPostModalProps {
  isOpen: boolean;
  onClose: () => void;
  featuredPosts: FeaturedPosts[] | any;
  postToAppend: Post;
  handleItemRemoved: any;
}

const FeaturedPostModal = ({
  isOpen,
  onClose,
  featuredPosts,
  handleItemRemoved,
  postToAppend,
}: FeaturedPostModalProps) => {
  const removeItemRequired = Boolean(postToAppend);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="max-w-xl w-full">
      <div className="max-w-2xl w-full rounded">
        <div className="p-8">
          <h4 className="text-2xl text-gray-800 font-semibold">Featured Posts</h4>
          {postToAppend ? (
            <div className="flex flex-col space-y-2">
              <p className="text-sm text-gray-500 mt-2 max-w-xl">
                You already have 6 featured posts for your website. You must choose one post to unfeature before saving.
              </p>
              <SortFeaturedPosts
                featuredPosts={[...featuredPosts, postToAppend]}
                onItemRemoved={(newList) => handleItemRemoved(newList)}
              />
            </div>
          ) : (
            <div className="flex space-x-2 items-center">
              <CheckCircleIcon className="h-6 w-6 text-green-500 mt-1" />
              <p className="text-sm text-gray-500 mt-2 max-w-xl">
                Changes Saved! You can now reorder your featured posts in the Website Settings tab.
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

interface FeaturePostActionProps {
  post: Post;
}

const FeaturePostAction = ({ post }: FeaturePostActionProps) => {
  const webTemplateMutation = useUpdateWebTemplate();
  const { data } = useWebTemplate();
  const webTemplate = data;

  const featuredPosts = webTemplate?.feature_post_ids || [];
  const isPostFeatured = Boolean(featuredPosts.find((featuredPost: Post) => featuredPost?.id === post.id));
  const featuredPostsCount = featuredPosts.length;

  const isOnlyEmail = post.platform === 'email';
  const isPostPublished = post.status === 'published';

  const [isFeaturedPostModalOpen, setIsFeaturedPostModalOpen] = useState(false);
  const [postToAppend, setPostToAppend] = useState<any>(null);
  const [isChecked, setIsChecked] = useState(isPostFeatured);

  useEffect(() => {
    setIsChecked(isPostFeatured);
  }, [isPostFeatured]);

  useEffect(() => {
    if (!postToAppend && isFeaturedPostModalOpen && !featuredPosts.includes() && isChecked) {
      setIsChecked(false);
    }
  }, [postToAppend]);

  const buildPostsPayload = (value: boolean) => {
    let newFeaturedPosts = [...featuredPosts];

    if (value) {
      newFeaturedPosts.push(post);
    } else {
      newFeaturedPosts = newFeaturedPosts.filter((featuredPost) => featuredPost.id !== post.id);
    }

    return {
      feature_post_ids: newFeaturedPosts.map((featuredPost) => featuredPost.id),
    };
  };

  const onFeaturedSelected = async (value: boolean) => {
    setIsChecked(value);
    if (featuredPostsCount >= 6 && value) {
      setPostToAppend(post);
      setIsFeaturedPostModalOpen(true);
    } else {
      await webTemplateMutation.mutateAsync(buildPostsPayload(value));
    }
  };

  const handleItemRemoved = async (newList: string[]) => {
    setPostToAppend(null);
    await webTemplateMutation.mutateAsync({
      feature_post_ids: newList,
    });
  };

  const handleItemsOrdered = async (newList: string[]) => {
    await webTemplateMutation.mutateAsync({
      feature_post_ids: newList,
    });
  };

  const setPostText = () => {
    if (featuredPostsCount === 1) {
      return 'post';
    } else {
      return 'posts';
    }
  };

  return (
    <div className="w-full pr-4 relative">
      <FeaturedPostModal
        isOpen={isFeaturedPostModalOpen}
        onClose={() => setIsFeaturedPostModalOpen(false)}
        featuredPosts={featuredPosts}
        postToAppend={postToAppend}
        handleItemRemoved={handleItemRemoved}
      />
      <div
        className={cx(
          'flex flex-col space-y-2',
          isOnlyEmail ? 'opacity-50 pointer-events-none' : '',
          !isOnlyEmail && !isPostPublished ? 'opacity-50 pointer-events-none' : ''
        )}
      >
        <Checkbox
          labelText="Featured"
          name="feature_post_ids"
          checked={isChecked}
          onChange={(checked) => onFeaturedSelected(checked)}
          helperText={`You may feature up to 6 posts on your website's homepage. You have currently featured ${featuredPostsCount} ${setPostText()}.`}
        />
        {featuredPosts.length && featuredPosts.length > 0 ? (
          <SortFeaturedPosts
            featuredPosts={featuredPosts}
            onItemsOrdered={(newList) => handleItemsOrdered(newList)}
            onItemRemoved={(newList) => handleItemRemoved(newList)}
          />
        ) : null}
      </div>
      {isOnlyEmail && (
        <p className="mt-2 text-xs text-yellow-700">
          This feature is only available for posts published to your website.
        </p>
      )}
      {!isOnlyEmail && !isPostPublished && (
        <p className="mt-2 text-xs text-yellow-700">This feature will be available when the post is live.</p>
      )}
    </div>
  );
};

export default FeaturePostAction;
