import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import { Button } from '@/routes/website/_components/UI/Button';

import { useNavbarContext } from '../../../NavbarContext';

import { ActionSettingsReadOnly } from './ActionSettingsReadOnly';
import { LinkToSettings } from './LinkToSettings';
import { NewTabSettings } from './NewTabSettings';
import { VisibilitySettingsReadOnly } from './VisibilitySettingsReadOnly';

export function NavItemLinkSettings() {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;
  const itemType = (selectedContent?.attrs && 'type' in selectedContent.attrs && selectedContent.attrs.type) || '';
  if (itemType !== 'link' && itemType !== 'button' && itemType !== 'user_button' && itemType !== 'logo') return null;

  const hasLink =
    selectedContent?.attrs && 'href' in selectedContent.attrs
      ? selectedContent.attrs.href !== null && selectedContent.attrs.href !== undefined
      : false;

  const onRemoveLink = () => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { href: undefined });
  };

  const onAddLink = () => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { href: '' });
  };

  if (itemType === 'user_button') {
    return (
      <SectionRenderer title="Authentication">
        <ActionSettingsReadOnly />
        <VisibilitySettingsReadOnly />
      </SectionRenderer>
    );
  }

  return (
    <SectionRenderer
      title="Link"
      actions={
        <Button variant="ghost" size="sm" className="text-wb-accent" onClick={hasLink ? onRemoveLink : onAddLink}>
          {hasLink ? 'Remove' : 'Add'}
        </Button>
      }
      collapsible={hasLink}
    >
      <LinkToSettings />
      <NewTabSettings />
    </SectionRenderer>
  );
}
