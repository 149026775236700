import { Node } from '@tiptap/pm/model';
import { Editor, NodeViewContent, NodeViewWrapper } from '@tiptap/react';

import { Icon } from '../../../components/ui/Icon';
import { EmbedService } from '../../../lib/constants';

interface EmbedInputProps {
  node: Node;
  editor: Editor;
}

export const EmbedInputView = ({ node: { attrs }, editor }: EmbedInputProps) => {
  const service = attrs.service ?? undefined;

  return (
    <NodeViewWrapper data-service={service} data-drag-handle>
      <div {...{ inert: editor.isEditable ? undefined : '' }}>
        <NodeViewContent>
          <div contentEditable={false}>
            {(!service || service === EmbedService.INSTAGRAM) && <Icon name="Instagram" />}
            {(!service || service === EmbedService.YOUTUBE) && <Icon name="Youtube" />}
            {(!service || service === EmbedService.TIKTOK) && <Icon name="Tiktok" />}
            {(!service || service === EmbedService.TWITTER) && <Icon name="Twitter" />}
            {(!service || service === EmbedService.BLUESKY) && <Icon name="Bluesky" />}
          </div>
        </NodeViewContent>
      </div>
    </NodeViewWrapper>
  );
};

export default EmbedInputView;
