import React, { FC, PropsWithChildren } from 'react';

import { Title2xl, Typography } from '@/components/Typography';

import StepBar from './StepBar';

type Props = PropsWithChildren<{
  step: number;
}>;

const Wizard: FC<Props> = ({ step, children }) => {
  return (
    <div className="py-6 px-4 sm:p-6 lg:pb-8">
      <Title2xl>Stripe Migration</Title2xl>
      <Typography token="font-normal/text/base" colorWeight="500">
        Bring your paid subscribers over from Stripe in a few minutes
      </Typography>
      <StepBar currentStep={step} className="mt-12" />
      {children}
    </div>
  );
};

export default Wizard;
