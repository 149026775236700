import { useCallback } from 'react';
import { List } from '@phosphor-icons/react';

import { PopoverHelper, PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';

// Pulled from TailwindCSS
// https://tailwindcss.com/docs/line-clamp
const LINE_CLAMP_OPTIONS = [
  {
    label: 'All',
    value: 'line-clamp-none',
  },
  {
    label: '1 Line',
    value: 'line-clamp-1',
  },
  {
    label: '2 Lines',
    value: 'line-clamp-2',
  },
  {
    label: '3 Lines',
    value: 'line-clamp-3',
  },
  {
    label: '4 Lines',
    value: 'line-clamp-4',
  },
  {
    label: '5 Lines',
    value: 'line-clamp-5',
  },
];

type Props = AttributeSettingProps & {
  property: string;
  title: string;
};

export const LineClampSettings = ({ editor, activeNodeResult, property, title = 'Line Clamp' }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const textValue =
    LINE_CLAMP_OPTIONS.find((option) => option.value === activeNodeAttributes?.[property])?.label || 'All';

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodePos) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [editor, activeNodePos, property]
  );

  return (
    <PopoverHelper title={title} text={textValue} popoverTitle={title} Icon={List}>
      <div className="flex flex-col gap-2">
        {LINE_CLAMP_OPTIONS.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={false}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};
