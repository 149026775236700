import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  siteTemplateId: string;
  onSuccess?: () => void;
}

const useDeleteSiteTemplate = ({ siteTemplateId, onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const params = {
    publication_id: currentPublicationId,
  };

  return useMutation(() => api.delete(`/site_templates/${siteTemplateId}`, { params }), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.message || 'Something went wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['publications', currentPublicationId, 'site_templates']);
      toast.success('Deleted!');
      onSuccess?.();
    },
  });
};

export default useDeleteSiteTemplate;
