import { useNavigate } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';
import { AdjustmentsVerticalIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { AutomationTriggerState } from '@/interfaces/automations/automation_trigger';
import { Button } from '@/ui/Button';

import { AUTOMATION_NODE_WIDTH_CLASSNAME, TRIGGERS_COLORS, TRIGGERS_LABEL } from '../../constants';
import { useNewAutoLayoutContext } from '../../context/new-layout-context';
import { CustomNodeDataType } from '../../types';
import { TRIGGERS_NODE_ID, TRIGGERS_NODE_TYPE } from '../../utils/getNodesAndEdges';
import ContainerNode from '../ContainerNode';
import ContainerNodeBody from '../ContainerNodeBody';

import NewTriggerNode from './NewTriggerNode';

const TriggersNode = ({ sourcePosition, type }: NodeProps<CustomNodeDataType>) => {
  const navigate = useNavigate();
  const { isDrawerOpen, automation, automationTriggers } = useNewAutoLayoutContext();

  const hasActiveTriggers = automationTriggers.some((trigger) => trigger.state === AutomationTriggerState.PUBLISHED);
  const reversedItems = [...automationTriggers].reverse();

  const handleBorderColor = TRIGGERS_COLORS.border;
  const areTriggersBeingAdded = type === TRIGGERS_NODE_TYPE;

  const handleConfigureClick = () => {
    navigate(`/automations/${automation.id}/workflow/triggers/configure`);
  };

  return (
    <div className={cx('flex flex-col items-center rounded-md', AUTOMATION_NODE_WIDTH_CLASSNAME)}>
      <ContainerNode
        uniqueIdentifier={TRIGGERS_NODE_ID}
        isBeingConfigured={areTriggersBeingAdded}
        isActive={hasActiveTriggers}
        shouldDisable={!areTriggersBeingAdded && isDrawerOpen}
        stepType={TRIGGERS_NODE_TYPE}
        title={TRIGGERS_LABEL}
        onClickConfigure={handleConfigureClick}
      >
        <ContainerNodeBody>
          {automationTriggers.length === 0 && (
            <div className="flex justify-center items-center w-full">
              <Button
                type="button"
                variant="secondary"
                size="xs"
                Icon={AdjustmentsVerticalIcon}
                onClick={handleConfigureClick}
              >
                Add Trigger
              </Button>
            </div>
          )}
          {automationTriggers.length > 0 && (
            <ul className="flex flex-col w-full">
              {reversedItems.map((item, index) => {
                const conditionsCount = item.conditions.conditions?.length || 0;
                return (
                  <li
                    className="border-b border-gray-200 py-2 first-of-type:pt-0 last-of-type:border-0 last-of-type:pb-0"
                    key={`new-trigger-node-${item.id}`}
                  >
                    <NewTriggerNode
                      key={`new-trigger-node-${item.id}`}
                      index={index}
                      event={item.event}
                      mainConditions={item.main_conditions}
                      isActive={item.state === AutomationTriggerState.PUBLISHED}
                      conditionsCount={conditionsCount}
                      hideConditionsCount={conditionsCount === 0}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </ContainerNodeBody>
      </ContainerNode>
      <Handle
        className={cx('w-3.5 h-3.5 z-10 bg-gray-50 border-4', handleBorderColor)}
        style={{
          boxShadow: '0px 0px 0px 3px #F3F4F6',
        }}
        type="source"
        position={sourcePosition || Position.Bottom}
      />
    </div>
  );
};

export default TriggersNode;
