import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentTimeZone } from '@/hooks';
import api from '@/services/swarm';
import { TimePeriod } from '@/utils';

interface ChartData {
  name: string;
  Revenue: string;
}

interface ApiResponse {
  chart_data: ChartData[];
  categories: string[];
  period: TimePeriod;
  time_zone: string;
}

const useSubscriberRevenueByPeriod = (period: TimePeriod) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const currentTimeZone = useCurrentTimeZone();
  const excludedPeriods = [TimePeriod.LAST_24_HOURS, TimePeriod.LAST_4_WEEKS, TimePeriod.LAST_7_DAYS];

  const fetchTierChangeData = () => {
    return api
      .get(`/premium_data/subscriber_revenue_per_period/${period}`, {
        params: {
          publication_id: currentPublicationId,
          time_zone: currentTimeZone,
        },
      })
      .then((res) => res.data);
  };

  return useQuery<ApiResponse>(
    ['premium_data', 'subscriberRevenueByPeriod', currentPublicationId, period, currentTimeZone],
    fetchTierChangeData,
    {
      keepPreviousData: true,
      enabled: !excludedPeriods.includes(period),
    }
  );
};

export default useSubscriberRevenueByPeriod;
