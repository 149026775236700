import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';

import { useNavbarContext } from '../../../NavbarContext';
import { getParent } from '../../../utils';

export const StickyOptions = () => {
  const { content, selectedContent: rawSelectedContent, onUpdateNodeAttributes } = useNavbarContext();

  const selectedContent =
    rawSelectedContent?.type === 'navbar_menu_list' && content
      ? getParent(content, rawSelectedContent)
      : rawSelectedContent;

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const contentValue =
    selectedContent?.attrs && 'stickToTop' in selectedContent.attrs ? selectedContent.attrs.stickToTop : false;

  const handleStickyChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { stickToTop: value === 'true' });
  };

  const value = contentValue?.toString() || 'false';

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Stick to top
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="false"
          value={value}
          onValueChange={handleStickyChange}
        >
          <ToggleGroupItem value={true.toString()} className="grow">
            <Text size="2xs" weight="semibold" variant="inherit">
              YES
            </Text>
          </ToggleGroupItem>
          <ToggleGroupItem value={false.toString()} className="grow">
            <Text size="2xs" weight="semibold" variant="inherit">
              NO
            </Text>
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
