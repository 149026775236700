import { PropsWithChildren } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import cx from 'classnames';

import { colorByLogicalOperator } from '@/components/ConditionsEditor/constants';
import Tree from '@/components/Tree';
import { LogicalOperator } from '@/interfaces/condition';
import { Group } from '@/interfaces/segment';
import { NameTypes, SortableConditionBlockData } from '@/pages/Segments/ConditionsEditor/types';

import { conditionOptions } from './constants';
import GroupFooter from './GroupFooter';
import GroupHeader from './GroupHeader';

type Props = PropsWithChildren<{
  uniqueIdentifier: string;
  data?: SortableConditionBlockData;
  isDisabled?: boolean;
  block: Group;
  blockIndex: number;
  handleGroupLogicalOperatorChange: (value: LogicalOperator, blockIndex?: number) => void;
  handleAddConditionBlock: (type: NameTypes, blockIndex?: number) => void;
  handleSelectConditionNameInGroup: (conditionOption: any, blockIndex?: number) => void;
  handleDeleteCondition: (blockIndex: number, index?: number) => void;
}>;

const SortableGroupBlock = ({
  children,
  block,
  uniqueIdentifier,
  data,
  handleGroupLogicalOperatorChange,
  handleAddConditionBlock,
  handleSelectConditionNameInGroup,
  handleDeleteCondition,
  blockIndex,
  isDisabled = false,
}: Props) => {
  const { setNodeRef } = useSortable({
    id: uniqueIdentifier || '',
    disabled: isDisabled,
    data,
  });

  return (
    <div ref={setNodeRef}>
      <Tree isNested backgroundClassName={colorByLogicalOperator[block.logical_operator].tree}>
        <GroupHeader
          selectedLogicalOperator={block.logical_operator}
          onLogicalOperatorSelect={handleGroupLogicalOperatorChange}
          index={blockIndex}
        />
        {children}
        {block.conditions.length === 0 ? <div className={cx('h-2 ml-4 transition-all')}>&nbsp;</div> : null}
        <GroupFooter
          selectedLogicalOperator={block.logical_operator}
          onAddConditionType={handleAddConditionBlock}
          onSelectConditionName={handleSelectConditionNameInGroup}
          conditionSelectOptions={conditionOptions}
          index={blockIndex}
          showDelete
          onDelete={handleDeleteCondition}
        />
      </Tree>
    </div>
  );
};

export default SortableGroupBlock;
