import { ArrowPathIcon, CheckIcon, SunIcon } from '@heroicons/react/20/solid';

import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';
import { Badge } from '@/ui/Badge';

interface Props {
  status: 'pending' | 'valid';
  domain: CustomDomain;
  type: CustomDomainTypes;
}

const StatusBadge = ({ status, domain, type }: Props) => {
  if (status === 'pending') {
    return (
      <Badge type="information">
        <ArrowPathIcon className="w-4 h-4 text-blue-800 mr-2" />
        Verifying Configuration
      </Badge>
    );
  }

  if (type === CustomDomainTypes.EMAIL && domain.warming_up) {
    return (
      <Badge type="information">
        <SunIcon className="w-4 h-4 text-yellow-400 mr-2" />
        Smart Warming in progress
      </Badge>
    );
  }

  return (
    <Badge type="success">
      <CheckIcon className="w-4 h-4 text-green-800 mr-2" />
      Live
    </Badge>
  );
};

export default StatusBadge;
