import { useSearchParams } from 'react-router-dom';
import moment from 'moment-mini';

import { MultiActionModal } from '@/components/MultiActionModal';
import useApplicationMetrics from '@/hooks/boosts/grow/useApplicationMetrics';
import useBoostsFrozen from '@/hooks/boosts/useBoostsFrozen';
import { BoostAgreement } from '@/interfaces/boosts/grow/boost_agreement';
import BoostApplicationLevelIndicator from '@/pages/Grow/Boosts/Dashboard/components/BoostApplicationLevelIndicator';
import capitalize from '@/utils/capitalize';

import Comparison from './Comparision';
import PublicationDetails from './PublicationDetails';

interface Props {
  boostAgreement: BoostAgreement;
  isOpen: boolean;
  onClickReject: () => void;
  onClickAccept: () => void;
  onClickReport: () => void;
  isReporting: boolean;
}

const ReviewBoostModal = ({
  boostAgreement,
  isOpen,
  onClickReject,
  onClickAccept,
  onClickReport,
  isReporting,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { recommending_publication: recommendingPublication } = boostAgreement;
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen;

  const { data } = useApplicationMetrics(boostAgreement.id);
  const applicantMetrics = data?.metrics;
  const recentPostsCount = applicantMetrics?.most_recent_posts.length || 0;

  const handleClose = () => {
    searchParams.delete('boost_agreement_id');
    setSearchParams(searchParams);
  };

  const handleAccept = () => {
    onClickAccept();
    handleClose();
  };

  const handleReject = () => {
    onClickReject();
    handleClose();
  };

  const handleReport = () => {
    onClickReport();
    handleClose();
  };

  return (
    <MultiActionModal
      isOpen={isOpen}
      onClose={handleClose}
      steps={[
        {
          title: `Would you like ${recommendingPublication.name} to Boost your newsletter?`,
          actionsRowClassName: 'w-full flex flex-row justify-between',
          actions: [
            [
              {
                actionText: 'Report this publication',
                onClick: handleReport,
                disabled: isReporting || boostsFrozen,
                isWorking: isReporting,
                variant: 'danger',
              },
            ],
            [
              {
                actionText: 'Accept',
                onClick: () => handleAccept(),
                disabled: boostsFrozen,
              },
              {
                actionText: 'Reject',
                onClick: () => handleReject(),
                disabled: boostsFrozen,
              },
            ],
          ],
          children: (
            <div className="divide-y">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6">
                  <PublicationDetails
                    logoUrl={recommendingPublication.logo_url}
                    name={recommendingPublication.name}
                    description={recommendingPublication.description}
                    url={recommendingPublication.url}
                    createdAt={recommendingPublication.created_at}
                  />
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Social Links</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 space-x-2">
                    {Object.keys(recommendingPublication.social_links).length === 0 && '-'}
                    {Object.entries(recommendingPublication.social_links).map(([key, value]) => (
                      <a
                        key={key}
                        href={value}
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-500 hover:underline"
                      >
                        {capitalize(key)}
                      </a>
                    ))}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Website URL</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <ul className="flex flex-wrap gap-2 text-primary-500">
                      <a href={recommendingPublication.url} target="_blank" rel="noreferrer">
                        {recommendingPublication.url}
                      </a>
                    </ul>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Categories</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {recommendingPublication.tags.length > 0 ? (
                      <ul className="flex flex-wrap gap-2">
                        {recommendingPublication.tags.map((tag) => (
                          <li
                            key={tag.id}
                            className="inline-block bg-gray-100 rounded-full px-3 py-1 text-xs font-semibold text-gray-700"
                          >
                            #{tag.name}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      '-'
                    )}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    From {recommendingPublication.name}
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {boostAgreement.why_me || '-'}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Boost Quality Score</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 space-x-2">
                    <BoostApplicationLevelIndicator
                      referralQuality={
                        typeof applicantMetrics?.referral_quality === 'undefined'
                          ? null
                          : applicantMetrics?.referral_quality
                      }
                      alignLabel="left"
                    />
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Top Audience Demographics</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 space-x-2">
                    {applicantMetrics &&
                      applicantMetrics.subscribers_by_country.map((location) => (
                        <span className="shadow-xs inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium h-5.5 bg-gray-200">
                          {location.percentage === 0 ? '<1' : location.percentage}% {location.country}
                        </span>
                      ))}
                    {!applicantMetrics || (applicantMetrics.subscribers_by_country.length === 0 && '-')}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Recent Posts</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {recentPostsCount === 0 ? ("-") : (
                      <>
                        <span className="flex flex-row text-gray-500 mb-2 justify-end">
                          Showing {recentPostsCount} of {applicantMetrics ? applicantMetrics.post_count : '0'} posts
                        </span>
                        <div className="flex flex-col space-y-2">
                          {applicantMetrics && applicantMetrics.most_recent_posts.map((post) => (
                            <div className="flex flex-col">
                              { post.url && post.platform.includes('Web') ? (
                                <a
                                  key={post.id}
                                  href={post.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-primary-500 hover:underline break-all"
                                >
                                  {post.title}
                                </a>
                              ) : (
                                <span key={post.id} className="text-primary-500 break-all">
                                  {post.title}
                                </span>
                              )}
                              <span className="text-gray-500">{moment(post.published_at).format("LLLL")} | Audience: {post.platform}</span>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </dd>
                </div>
                <Comparison boostAgreement={boostAgreement} />
                {applicantMetrics && applicantMetrics.post_count < 5 && (
                  <div className="px-4 py-6 bg-yellow-50 rounded-md sm:px-0">
                    <p className="mx-4 text-sm font-medium leading-6 text-gray-600">
                      It looks like this publication is relatively new and has less than
                      <span className="text-primary-500"> 5 </span>pieces of content live.
                    </p>
                    <p className="mx-4 text-sm font-medium leading-6 text-gray-600">
                      We recommend vetting these types of publications a bit more thoroughly to avoid the risk of spam
                      or fraudulent subscribers.
                    </p>
                  </div>
                )}
              </dl>
            </div>
          ),
        },
      ]}
      resourceId={boostAgreement.id}
      modalSize="xl"
    />
  );
};

export default ReviewBoostModal;
