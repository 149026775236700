import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { SendingMetrics } from '../../../interfaces/boosts/sending_metrics';
import api from '../../../services/swarm';

interface Props {
  boost_offer_id?: string | null;
  boost_agreement_id?: string | null;
  boost_send_id?: string | null;
  recommendingPublicationId?: string | null;
  isEnabled?: boolean;
}

const useSendingMetrics = ({
  boost_offer_id,
  boost_agreement_id,
  boost_send_id,
  recommendingPublicationId,
  isEnabled = true,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchMetrics = (): Promise<SendingMetrics> =>
    api
      .get(`/boosts/grow/sending_metrics`, {
        params: {
          publication_id: currentPublicationId,
          ...(recommendingPublicationId && { recommending_publication_id: recommendingPublicationId }),
          ...(boost_offer_id && { boost_offer_id }),
          ...(boost_agreement_id && { boost_agreement_id }),
          ...(boost_send_id && { boost_send_id }),
        },
      })
      .then((res) => res.data);

  return useQuery(
    [
      currentPublicationId,
      'boosts',
      'grow',
      'sending_metrics',
      boost_offer_id,
      boost_agreement_id,
      boost_send_id,
      recommendingPublicationId,
    ],
    fetchMetrics,
    {
      keepPreviousData: true,
      enabled: isEnabled,
    }
  );
};

export default useSendingMetrics;
