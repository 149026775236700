import { FacebookLogo } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { SliderSettings } from '../SliderSettings';

type Props = AttributeSettingProps & {};

export const SocialsSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Socials" text="Socials" popoverTitle="Socials" Icon={FacebookLogo}>
      <>
        <SliderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="cardSocialSize"
          title="Size"
          type="number"
          min={4}
          max={12}
          step={1}
        />
        <ColorSettings title="Color" property="cardSocialFill" editor={editor} activeNodeResult={activeNodeResult} />
      </>
    </PopoverHelper>
  );
};

export default SocialsSettings;
