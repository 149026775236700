import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { LogicalOperator } from '@/interfaces/condition';
import { Condition, Group, Segment } from '@/interfaces/segment';
import api from '@/services/swarm';


interface ConditionsPayload {
  conditions: Condition[] | Group[];
  logical_operator: LogicalOperator;
}

export interface CreateSegmentRequestPayload {
  name?: string;
  description?: string;
  segment_type?: string;
  conditions?: ConditionsPayload;
  publication_id?: string;
}

const useCreateSegment = ({ onSuccess }: { onSuccess?: (result: Segment) => void } = {}) => {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation<Segment, unknown, CreateSegmentRequestPayload>(
    ({ name, description, segment_type, conditions, publication_id }) =>
      api
        .post(`/segments`, {
          name,
          description,
          conditions,
          segment_type,
          publication_id: publication_id || currentPublicationId,
        })
        .then((res) => res.data),
    {
      onSuccess: (response) => {
        onSuccess?.(response);
        toast.success('Segment created!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useCreateSegment;
