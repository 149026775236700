import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { AdNetworkAdvertiser } from '../../../interfaces/ad_network/internal/advertiser';
import api from '../../../services/swarm';

export default function useAdvertiser({ id }: { id?: string }) {
  return useQuery<AdNetworkAdvertiser, AxiosError>(
    ['ad_network', 'internal', 'advertisers', id],
    () => api.get(`/ad_network/internal/advertisers/${id}`).then((res) => res.data),
    {
      keepPreviousData: true,
      retry: (failureCount, error) => {
        if (error.response?.status === 404) return false;

        return failureCount < 3;
      },
      useErrorBoundary: true,
      enabled: !!id,
    }
  );
}
