import ProtectedAction from '@/components/ProtectedAction';
import { useDeleteOrganization } from '@/hooks/useOrganization';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublication from '@/hooks/usePublications/usePublication';

const DeleteOrganization = () => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: currentPublication } = usePublication(currentPublicationId);
  const deleteOrganization = useDeleteOrganization(currentPublication?.organization_id, currentPublicationId);

  const handleDeleteOrg = async () => {
    await deleteOrganization.mutateAsync();
  };

  return (
    <ProtectedAction
      buttonText="Delete Workspace"
      isSubmitting={deleteOrganization.isLoading}
      onProceed={handleDeleteOrg}
      resourceId="delete_organization"
      modalDescription="Are you sure you want to delete this Workspace? All of the related publications will be deleted as well."
    />
  );
};

export default DeleteOrganization;
