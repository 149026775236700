import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useSettings } from '../context/settings-context';
import { Settings } from '../interfaces/setting';

interface Props {
  setting: keyof Settings;
  message?: string;
  redirectTo?: string;
  enabled?: boolean;
}

const useRequireSetting = ({ setting, redirectTo = '/', message = 'Unauthorized', enabled = true }: Props) => {
  const { settings } = useSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (settings && !settings[setting] && enabled) {
      if (message) toast.error(message);
      navigate(redirectTo);
    }
  }, [settings, navigate, message, redirectTo, setting, enabled]);
};

export default useRequireSetting;
