import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { AreaChart, ChartCard } from '@/components/Chartsv2';
import type { SmartWarmingProgressData } from '@/interfaces/smart_warming_progress';
import { valueFormatter } from '@/utils/formatters';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface SmartWarmingChartProps {
  data?: SmartWarmingProgressData;
  isLoading: boolean;
}

const SmartWarmingChart: React.FC<SmartWarmingChartProps> = ({ data, isLoading }) => {
  return (
    <ChartCard
      noResultsText="No Smart Warming progress data available"
      noResults={!isLoading && !data}
      isLoading={isLoading}
    >
      <AreaChart
        className="h-72 mt-4"
        data={data?.data || []}
        index="name"
        categories={data?.labels || []}
        colors={['pink', 'violet']}
        valueFormatter={valueFormatter}
      />
    </ChartCard>
  );
};

export default SmartWarmingChart;
