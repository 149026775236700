import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { LinkIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';

import SlideOver from '../../../../../components/SlideOver';
import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { useCreateNavLink } from '../../../../../hooks/useNavlinks';
import { NavGroup } from '../../../../../interfaces/nav_group';
import { isValidUrl } from '../../../../../utils';

import ExternalLinkForm from './ExternalLinkForm';

interface Props {
  navGroups: NavGroup[];
  refetch: () => void;
  onAddExternalLink: (cb: () => void) => void;
}

const NewLinkPanel = ({ navGroups, refetch, onAddExternalLink }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const createNavLink = useCreateNavLink({
    onSuccess: () => {
      toast.success('Created!');
      queryClient.invalidateQueries(['nav_groups', currentPublicationId]);
      refetch();
      setIsOpen(false);
    },
  });

  const handleOnSubmit = (navLink: any) => {
    if (!isValidUrl(navLink.href)) {
      toast.error('Please include a valid URL');
      return;
    }

    createNavLink.mutate(navLink);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <SlideOver
        bodyId="new-external-link"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        headerText="New External Page"
      >
        {isOpen && (
          <ExternalLinkForm
            navLink={null}
            navGroups={navGroups}
            onSubmit={handleOnSubmit}
            onClose={() => setIsOpen(false)}
            isLoading={createNavLink.isLoading}
          />
        )}
      </SlideOver>

      <Button Icon={LinkIcon} type="button" variant="primary-inverse" onClick={() => onAddExternalLink(handleOpen)}>
        External Page
      </Button>
    </>
  );
};

export default NewLinkPanel;
