import { RefObject, useEffect, useRef } from 'react';

type UseClickOutsideReturn = RefObject<HTMLDivElement>;

export const useClickOutside = (
  iframeRef: RefObject<HTMLIFrameElement>,
  callback: () => void,
  whitelistClasses: string[] = []
): UseClickOutsideReturn => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !whitelistClasses.some((className) => (event.target as HTMLElement).closest(`.${className}`) !== null)
      ) {
        callback();
      }
    };

    const iframeRefCurrent = iframeRef.current;

    iframeRefCurrent?.contentDocument?.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      iframeRefCurrent?.contentDocument?.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, iframeRef, whitelistClasses]);

  return ref;
};
