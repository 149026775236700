import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import api from '@/services/swarm';

interface PartnerCommission {
  pending: number;
  due: number;
  paid: number;
}

const usePartnerCommissions = (staleTime = 150000): UseQueryResult<PartnerCommission, Error> => {
  const { currentUser } = useCurrentUser();

  return useQuery<PartnerCommission, Error>(
    ['partner_program/commissions', currentUser?.id],
    async () => {
      const response = await api.get('/partner_program/commissions', {
        params: {
          user_id: currentUser?.id,
        },
      });
      return response.data;
    },
    {
      staleTime,
      enabled: !!currentUser?.id,
    }
  );
};

export default usePartnerCommissions;
