import { PropsWithChildren } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

export type Props = PropsWithChildren<{
  label: string;
  onSave: () => void;
}>;

const AddInput = ({ label, onSave }: Props) => {
  return (
    <div className="flex space-between space-x-4 w-full items-center">
      <Typography token="font-normal/text/xs" className="w-full" colorWeight="500">
        {label}
      </Typography>
      <Button
        Icon={PlusIcon}
        onClick={onSave}
        size="xs"
        variant="flush"
        className="!rounded-md !bg-surface-100 !border !border-gray-200"
      >
        <Typography token="font-normal/text/xs" className="w-full" as="span">
          Add
        </Typography>
      </Button>
    </div>
  );
};

export default AddInput;
