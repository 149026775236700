import toast from "react-hot-toast";

import { PageRoute } from "@/interfaces/dream_builder/page_route";

export function updateRouteAtPath(route: PageRoute, path: string[], update: (route: PageRoute) => PageRoute): PageRoute | null {
  if (path.length === 0) {
    return update(route);
  }

  if (!route.children) {
    toast.error('No children found');
    return null;
  }

  if (!(path[0] in route.children)) {
    toast.error(`No child found at path ${path.join('/')}`);
    return null;
  }

  const newRoute = { ...route };
  newRoute.children = { ...route.children };
  const updatedChild = updateRouteAtPath(route.children[path[0]], path.slice(1), update);
  if (!updatedChild) {
    return null;
  }

  newRoute.children[path[0]] = updatedChild;

  return newRoute;
}


