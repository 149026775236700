import { ReactNode } from 'react';
import { ArrowTopRightOnSquareIcon, HomeIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { getColor } from '@/components/DesignTokens';
import { Typography } from '@/components/Typography';
import { BoostOffer } from '@/interfaces/boosts/grow/boost_offer';

interface Props {
  boostOffer?: BoostOffer;
  banner?: ReactNode;
}

const BoostOfferFormHeader = ({ boostOffer, banner }: Props) => {
  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.Item to="/">
          <HomeIcon className="w-4 h-4" />
        </Breadcrumbs.Item>
        <Breadcrumbs.Item to="/grow/boosts?tab=offer_history">Grow with Boosts</Breadcrumbs.Item>
        {boostOffer ? (
          <Breadcrumbs.Item to={`/grow/boosts/offers/${boostOffer?.id}/edit`}>{boostOffer?.name}</Breadcrumbs.Item>
        ) : (
          <Breadcrumbs.Item to="/grow/boosts/new">New Offer</Breadcrumbs.Item>
        )}
      </Breadcrumbs>
      {banner}
      <div className="flex flex-col gap-y-2 sm:items-center sm:justify-between sm:flex-row pt-2 pb-6">
        <div>
          <Typography weight="bold" size="xl">
            {boostOffer ? 'Edit your Boost Marketplace offer' : 'Create your Boost Marketplace offer'}
          </Typography>
        </div>
        <div className="flex gap-x-1">
          <Typography colorWeight="500">Need help?</Typography>
          <a
            href="https://blog.beehiiv.com/p/experts-guide-boosts"
            target="_blank"
            className="flex justify-center items-center"
            rel="noreferrer"
          >
            <Typography color="secondary" colorWeight="600">
              Expert Guide
            </Typography>
            <ArrowTopRightOnSquareIcon className={cx('w-5 h-5', getColor('secondary/600', 'text'))} />
          </a>
        </div>
      </div>
    </>
  );
};

export default BoostOfferFormHeader;
