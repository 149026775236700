import { AdNetworkCampaignStatus } from '../../../interfaces/ad_network/internal/campaign';
import { Tabs } from '../_components/Tabs';

import CampaignsHeader from './_components/CampaignsHeader';
import CampaignsTable from './_components/CampaignsTable';

export default function Show() {
  return (
    <>
      <CampaignsHeader />
      <div className="border-b border-gray-100 p-4 flex space-x-2">
        <div>
          <Tabs>
            <Tabs.Tab to="../all">All</Tabs.Tab>
            <Tabs.Tab to="../active">Active</Tabs.Tab>
            <Tabs.Tab to="">Upcoming</Tabs.Tab>
            <Tabs.Tab to="../past">Past</Tabs.Tab>
          </Tabs>
        </div>
      </div>
      <CampaignsTable status={AdNetworkCampaignStatus.UPCOMING} />
    </>
  );
}
