import { Typography, TypographyStack } from '@/components/Typography';

interface Props {
  title: string;
  Icon?: React.ElementType;
  description?: string;
}

const SettingsSectionHeader = ({ Icon, title, description }: Props) => {
  return (
    <TypographyStack>
      <div className="flex flex-row space-x-2">
        {Icon && <Icon className="h-5 w-5 my-auto text-surface-900" />}
        <Typography token="font-medium/text/base" className="text-gray-900">
          {title}
        </Typography>
      </div>
      <Typography token="font-light/text/xs" className="w-4/5 text-gray-900">
        {description}
      </Typography>
    </TypographyStack>
  );
};

export default SettingsSectionHeader;
