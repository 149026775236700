import SlideOver from '../../../../../../components/SlideOver';
import { useUpdateExternalEmbed } from '../../../../../../hooks/useExternalEmbeds';
import { ExternalEmbed } from '../../../../../../interfaces/external_embed';
import ExternalEmbedForm from '../Form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  externalEmbed: ExternalEmbed | undefined;
}

const ExternalEmbedEdit = ({ externalEmbed, isOpen, onClose }: Props) => {
  return (
    <SlideOver
      bodyId={externalEmbed?.id}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      headerText="Edit Subscribe Form"
    >
      {externalEmbed && (
        <ExternalEmbedForm
          title=""
          externalEmbed={externalEmbed}
          useMutation={useUpdateExternalEmbed}
          onSuccess={onClose}
        />
      )}
    </SlideOver>
  );
};

export default ExternalEmbedEdit;
