import { BoostSend, BoostSendStatus } from '@/interfaces/boosts/grow/boost_send';

import { useUpdateBoostSend } from './useBoostSends';

interface Options {
  onSuccess?: () => void;
}

const useEmailApplication = (boostSend: BoostSend, options?: Options) => {
  const { mutateAsync: updateBoostSend, isLoading } = useUpdateBoostSend({
    id: boostSend.id,
    onSuccess: options?.onSuccess,
    shouldInvalidate: false,
  });

  const handleReject = () => {
    updateBoostSend({ status: BoostSendStatus.REJECTED });
  };

  const handleAccept = () => {
    updateBoostSend({ status: BoostSendStatus.ACCEPTED });
  };

  return {
    handleReject,
    handleAccept,
    isRejecting: isLoading,
    isAccepting: isLoading,
  };
};

export default useEmailApplication;
