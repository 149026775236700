import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';
import Switch from '@/ui/Switch';
import { Textarea } from '@/ui/Textarea';

import { LoadingSpinner } from '../../../../components/LoadingSpinner';
// Contexts
import { useCurrentPublicationState } from '../../../../context/current-publication-context';
// Services
import api from '../../../../services/swarm';
// Components
import ConfigureContainer from '../ConfigureContainer';

interface IData {
  [key: string]: any;
}

export default function ConfigureSettings() {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referralProgram, setReferralProgram] = useState<IData>();
  const [currentPublicationId] = useCurrentPublicationState();

  function fetchReferralProgram(): Promise<IData> {
    const params = {
      publication_id: currentPublicationId,
    };
    return api.get(`/referral_program`, { params }).then((res) => res.data);
  }

  const loadReferralProgram = () => {
    setIsLoading(true);
    fetchReferralProgram()
      .then((r) => {
        const payload = {
          enabled: !r?.disabled_at,
          email_description: r?.email_description,
        };
        setReferralProgram(payload);
      })
      .finally(() => setIsLoading(false));
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (currentPublicationId) {
      loadReferralProgram();
    }
  }, [currentPublicationId]);
  /* eslint-enable */

  const onSwitch = (name: string, value: boolean) => {
    setReferralProgram({ ...referralProgram, [name]: value });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value, name },
    } = e;

    setReferralProgram({ ...referralProgram, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const currentTime = new Date();
    const payload = {
      disabled_at: referralProgram?.enabled ? null : currentTime.toISOString(),
      email_description: referralProgram?.email_description,
      publication_id: currentPublicationId,
    };

    setIsSaving(true);
    api
      .patch(`/referral_program`, payload)
      .then(() => {
        toast.success('Referral program updated successfully');
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <ConfigureContainer selectedTab="settings">
      <div className="sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900 inline-flex">
                Settings {isLoading && <LoadingSpinner className="ml-2" />}
              </h3>
              <p className="mt-1 text-sm text-gray-500">Control high level settings about your referral program</p>
            </div>
          </div>
          <div>
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <form onSubmit={handleSubmit}>
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <Switch
                      name="enabled"
                      labelText="Enabled?"
                      checked={referralProgram?.enabled}
                      onChange={onSwitch}
                    />
                    <Textarea
                      name="email_description"
                      labelText="Email Description"
                      helperText="The text displayed at the top of your referral card within your email posts"
                      className="mt-4"
                      value={referralProgram?.email_description || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="px-4 py-3 text-right sm:px-6">
                    <Button type="submit" loading={isSaving}>
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigureContainer>
  );
}
