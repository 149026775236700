import { ElementType } from 'react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';

interface Props {
  text: string;
  variant: 'primary' | 'secondary' | 'tertiary';
  Icon: ElementType;
}

const COLOR_MAP = Object.freeze({
  primary: {
    gradient: 'bg-gradient-to-r from-action-primary-300 to-action-primary-700',
  },
  secondary: {
    gradient: 'bg-gradient-to-r from-action-secondary-300 to-action-secondary-700',
  },
  tertiary: {
    gradient: 'bg-gradient-to-r from-action-tertiary-300 to-action-tertiary-700',
  },
});

const PlanPill = ({ variant, text, Icon }: Props) => {
  return (
    <div
      className={cx(
        'px-2 py-1 w-fit flex justify-center items-center space-x-1 rounded-lg',
        COLOR_MAP[variant].gradient
      )}
    >
      <Icon className="w-4 h-4 text-white" />
      <Typography size="xs" color="white" weight="semibold">
        {text}
      </Typography>
    </div>
  );
};

export default PlanPill;
