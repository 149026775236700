import React, { useCallback, useEffect, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import debounce from 'lodash.debounce';

interface SearchInputProps {
  onSearch: (value: string) => void;
  defaultValue?: string;
  onClearSearch?: () => void;
  placeholder?: string;
  shouldReset?: boolean;
  shouldDebounce?: boolean;
  debounceMilliseconds?: number;
  searchInputLineHeight?: number;
  block?: boolean;
}

const SearchInput = ({
  defaultValue,
  onClearSearch,
  shouldDebounce = true,
  shouldReset,
  onSearch,
  placeholder,
  debounceMilliseconds = 200,
  searchInputLineHeight,
  block,
}: SearchInputProps) => {
  const [query, setQuery] = useState(defaultValue || '');

  useEffect(() => {
    if (shouldReset) {
      setQuery('');
    }
  }, [shouldReset]);

  useEffect(() => {
    setQuery(defaultValue || '');
  }, [defaultValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(
    debounce((s) => onSearch(s), debounceMilliseconds),
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!shouldDebounce) e.preventDefault();

    setQuery(e.target.value);

    // Checks if value has been cleared so parent component can have clear callback
    if (!e.target.value && onClearSearch) {
      onClearSearch();
    }

    if (shouldDebounce) {
      handleSubmit.cancel();
      handleSubmit(e.target.value);
    }
  };

  return (
    <form
      className="w-full h-full"
      onSubmit={(e) => {
        e.preventDefault();

        onSearch(query);
      }}
    >
      <div className={block ? 'w-full' : 'flex-1 h-full flex justify-center lg:justify-end'}>
        <div className={block ? 'w-full' : 'max-w-lg h-full w-full lg:max-w-xs'}>
          <div className="relative h-full">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
            </div>
            <input
              className={cx(
                searchInputLineHeight && `line-height-[${searchInputLineHeight}px]`,
                'pl-9 appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:shadow-[0px_0px_0px_4px_#E5E7EB,0px_1px_2px_0px_rgba(0,0,0,0.05)] focus:border-surface-300 border-surface-200 focus:ring-0 text-sm text-gray-900 placeholder-gray-400 placeholder-shown:text-ellipsis ring-0'
              )}
              placeholder={placeholder}
              onChange={handleChange}
              value={query}
              type="search"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchInput;
