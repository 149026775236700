import { useState } from 'react';
import toast from 'react-hot-toast';

import { Option } from '@/interfaces/general';

import NotEditing from '../NotEditing';

import Select from './Select';

interface Props {
  label: string;
  value: Option;
  description: string;
  options: Option[];
  onSave: (val: Option) => Promise<any>;
  toastSuccessMessage?: string;
  toastErrorMessage?: string;
}

const SelectForm = ({ label, description, value, onSave, toastSuccessMessage, toastErrorMessage, options }: Props) => {
  const [inputValue, setInputValue] = useState<Option>(value);

  const handleSave = async (val: Option) => {
    setInputValue(val);
    await onSave(val)
      .then(() => {
        if (toastSuccessMessage) {
          toast.success(toastSuccessMessage);
        }
      })
      .catch(() => {
        if (toastErrorMessage) {
          toast.error(toastErrorMessage);
        }
      });
  };

  return (
    <div className="flex justify-between items-center border-b border-gray-100 pb-4 last:border-b-0">
      <NotEditing label={label} value={description} />

      <div className="pt-1">
        {options && <Select className="w-48" options={options} initialValue={inputValue} onChange={handleSave} />}
      </div>
    </div>
  );
};

export default SelectForm;
