import { Typography } from '@/components/Typography';
import { useEmailMessage } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

interface Props {
  emailMessageId?: string;
}

const NewSendEmailAutomationStepNode = ({ emailMessageId }: Props) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: emailMessage } = useEmailMessage(currentPublicationId, {
    emailMessageId,
  });

  return (
    <Typography size="xs">
      <strong>Subject:</strong> {emailMessage?.subject_line || '-'}
    </Typography>
  );
};

export default NewSendEmailAutomationStepNode;
