import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import Text from '@/components/Text';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCustomFields, useCustomFieldValues } from '@/hooks';
import { Button } from '@/ui/Button';

import { useSubscriberContext } from '../../_context/subscriberContext';

import CustomFieldItem from './CustomFieldItem';

const CustomFields = () => {
  const { subscription } = useSubscriberContext();

  const [publicationId] = useCurrentPublicationState();
  const {
    data: customFieldData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: customFieldsIsLoading,
    isError: customFieldsIsError,
  } = useCustomFields(publicationId, { perPage: 3 });
  const customFields = customFieldData?.pages.flatMap((page) => page.customFields) || [];

  const {
    data: customFieldValuesData,
    isLoading: customFieldValuesIsLoading,
    isError: customFieldValuesIsError,
  } = useCustomFieldValues(subscription.id);
  const customFieldValues = customFieldValuesData?.custom_field_values || [];

  const findCustomFieldValue = (customFieldId: string) =>
    customFieldValues.find((val) => val.custom_field_id === customFieldId);

  const isLoading = customFieldsIsLoading || customFieldValuesIsLoading;
  const isError = customFieldsIsError || customFieldValuesIsError;

  if (!isLoading && customFields.length === 0) {
    return (
      <div className="py-4 flex flex-col space-y-4">
        <Text className="text-gray-900">You have not set up any custom fields.</Text>
        <Link to="/subscribers/custom_fields/new" target="_blank">
          <Button variant="primary-inverse" Icon={PlusIcon} onClick={() => {}}>
            Create One
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <div>
      <Text size="sm" className="text-gray-500 mt-1 mb-5">
        Custom metadata for this subscriber.
      </Text>
      <LoadingBox isLoading={isLoading} isError={isError} height="400px">
        <div className="border border-gray-200 rounded-lg">
          {customFields.map((customField) => (
            <CustomFieldItem customField={customField} customFieldValue={findCustomFieldValue(customField.id)} />
          ))}
        </div>
        {hasNextPage && (
          <div className="flex justify-center mt-4">
            <Button
              variant="primary-inverse"
              onClick={() => fetchNextPage()}
              loading={isFetchingNextPage}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              Load more
            </Button>
          </div>
        )}
      </LoadingBox>
    </div>
  );
};

export default CustomFields;
