import { useParams } from 'react-router-dom';
import { DotOutline } from '@phosphor-icons/react';

import { useWebsitePageRouteGetter } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';
import { useSiteTemplate } from '@/hooks/useSiteTemplates';

import { Text } from '../UI/Text';

export const NavbarTitle = ({
  type = 'publication_name',
}: {
  type?: 'page_name' | 'publication_name' | 'template_name' | 'template_navbar' | 'template_footer';
}) => {
  const { pageId } = useParams() as unknown as { pageId: string };
  const pageRouteGetter = useWebsitePageRouteGetter();
  const page = pageRouteGetter?.getPageFromID(pageId);
  const pageRoute = pageRouteGetter?.getPageRouteFromID(pageId);
  const { data: currentPublication } = useCurrentPublication();
  const { templateId, sitePackageId } = useParams();
  const { data: template } = useSiteTemplate({ siteTemplateId: templateId as string });
  const { data: sitePackage } = useSitePackage({
    sitePackageId: template?.site_package_id || (sitePackageId as string),
  });
  const path = [currentPublication?.hostname, ...(pageRoute?.path || [])];

  const getTitleAndSubtitle = () => {
    switch (type) {
      case 'page_name':
        return {
          title: page?.draft_page_version?.name,
          subtitle: path?.join('/'),
        };
      case 'template_name':
        return {
          title: sitePackage?.name || 'Templates',
          subtitle: template?.name,
        };
      case 'template_navbar':
        return {
          title: sitePackage?.name,
          subtitle: 'Navbar',
        };
      case 'template_footer':
        return {
          title: sitePackage?.name,
          subtitle: 'Footer',
        };
      default:
        return {
          title: currentPublication?.name,
          subtitle: currentPublication?.hostname,
        };
    }
  };

  const { title, subtitle } = getTitleAndSubtitle();

  return (
    <div className="flex gap-1.5 items-center text-ellipsis overflow-hidden max-w-[200px] lg:max-w-[500px]">
      <Text weight="medium" size="sm" className="truncate">
        {title}
      </Text>
      <DotOutline size={12} className="text-wb-secondary min-h-[12px] min-w-[12px]" weight="fill" />
      <Text weight="medium" variant="secondary" size="sm" className="truncate">
        {subtitle}
      </Text>
      {(type === 'template_name' || type === 'template_navbar' || type === 'template_footer') && (
        <div className="flex items-center justify-center px-1 py-0.5 rounded-md bg-wb-accent-soft ml-1">
          <Text weight="semibold" size="2xs" variant="accent">
            Template
          </Text>
        </div>
      )}
    </div>
  );
};
