import { useNavigate } from 'react-router-dom';

import { usePermissions } from '@/context/permissions-context';

import { useAutomationViewContent } from '..';

import LifetimeMetrics from './LifetimeMetrics';
import TimeSeriesMetrics from './TimeSeriesMetrics';

const AutomationViewOverview = () => {
  const { automation } = useAutomationViewContent();
  const navigate = useNavigate();
  const { checkPermissions } = usePermissions();
  if (!checkPermissions('views/automations/overview', 'read')) {
    navigate(`/automations/${automation.id}/workflow`);
  }

  return (
    <div className="flex flex-col gap-y-6 w-full justify-center mt-40 py-5 px-4 md:px-10 md:mt-28 md:mb-36">
      <LifetimeMetrics automationId={automation.id} />
      <TimeSeriesMetrics automationId={automation.id} />
    </div>
  );
};

export default AutomationViewOverview;
