import { FC } from 'react';

import ColorField from '../../components/Form/ColorField';

import Slider from './components/Slider';
import ThemeEditorDropdown from './components/ThemeEditorDropdown';
import { FormContainer, FormInput, FormSection } from './helpers';
import { ThemeFormProps } from './types';

const ContentBreakForm: FC<ThemeFormProps> = ({ theme, onUpdate, colorPalette }) => {
  return (
    <FormContainer>
      <FormSection title="Style Settings">
        <FormInput title="Color">
          <ColorField
            placement="bottom"
            color={theme.content_break_color}
            colorPalette={colorPalette}
            onColorChange={(color: any) => onUpdate({ ...theme, content_break_color: color?.hexString })}
          />
        </FormInput>
        <FormInput title="Style">
          <ThemeEditorDropdown
            currentValue={theme.content_break_style}
            onUpdate={(val: string) => onUpdate({ ...theme, content_break_style: val })}
            options={[
              { label: 'Solid', value: 'Solid' },
              { label: 'Dotted', value: 'Dotted' },
              { label: 'Dashed', value: 'Dashed' },
            ]}
          />
        </FormInput>
        <FormInput>
          <Slider
            name="content_break_thickness"
            label="Thickness"
            onChange={(val: number) => onUpdate({ ...theme, content_break_thickness: val.toString() })}
            initialValue={theme.content_break_thickness}
            min={1}
            max={12}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="content_break_width"
            label="Width"
            onChange={(val: number) => onUpdate({ ...theme, content_break_width: val.toString() })}
            initialValue={theme.content_break_width}
            min={10}
            max={100}
            valueSuffix="px"
          />
        </FormInput>
        <FormInput>
          <Slider
            name="content_break_y_margin"
            label="Space around break"
            onChange={(val: number) => onUpdate({ ...theme, content_break_y_margin: val.toString() })}
            initialValue={theme.content_break_y_margin}
            min={5}
            max={50}
            valueSuffix="px"
          />
        </FormInput>
      </FormSection>
    </FormContainer>
  );
};

export default ContentBreakForm;
