import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from './routes/ad_network/_layout';
import AdvertisementShow from './routes/ad_network/advertisements/$advertisement_id';
import AdvertisementEdit from './routes/ad_network/advertisements/$advertisement_id/edit';
import Advertisers from './routes/ad_network/advertisers';
import AdvertisersShow from './routes/ad_network/advertisers/$advertiser_id';
import AdvertisersCampaignsActive from './routes/ad_network/advertisers/$advertiser_id/campaigns/active';
import AdvertisersCampaignsAll from './routes/ad_network/advertisers/$advertiser_id/campaigns/all';
import AdvertisersCampaignsNew from './routes/ad_network/advertisers/$advertiser_id/campaigns/new';
import AdvertisersCampaignsPast from './routes/ad_network/advertisers/$advertiser_id/campaigns/past';
import AdvertisersCampaignsUpcoming from './routes/ad_network/advertisers/$advertiser_id/campaigns/upcoming';
import AdvertisersEdit from './routes/ad_network/advertisers/$advertiser_id/edit';
import AdvertisersPublisherList from './routes/ad_network/advertisers/$advertiser_id/publisher_list';
import AdvertisersPublisherListItemShow from './routes/ad_network/advertisers/$advertiser_id/publisher_list/$publisher_list_item_id';
import AdvertisersPublisherListItemEdit from './routes/ad_network/advertisers/$advertiser_id/publisher_list/$publisher_list_item_id/edit';
import AdvertisersPublisherListNew from './routes/ad_network/advertisers/$advertiser_id/publisher_list/$publisher_list_item_id/new';
import AdvertisersNew from './routes/ad_network/advertisers/new';
import CampaignOpportunityGroup from './routes/ad_network/campaign_opportunity_groups/$campaign_opportunity_group_id';
import CampaignOpportunityGroupProvision from './routes/ad_network/campaign_opportunity_groups/$campaign_opportunity_group_id/provision';
import CampaignOpportunityGroupReview from './routes/ad_network/campaign_opportunity_groups/$campaign_opportunity_group_id/review';
import CampaignOpportunityGroupSelect from './routes/ad_network/campaign_opportunity_groups/$campaign_opportunity_group_id/select';
import CampaignsShow from './routes/ad_network/campaigns/$campaign_id';
import CampaignAdvertisements from './routes/ad_network/campaigns/$campaign_id/advertisements';
import CampaignAdvertisementsNew from './routes/ad_network/campaigns/$campaign_id/advertisements/new';
import CampaignOpportunityGroups from './routes/ad_network/campaigns/$campaign_id/campaign_opportunity_groups';
import CampaignOpportunityGroupsNew from './routes/ad_network/campaigns/$campaign_id/campaign_opportunity_groups/new';
import CampaignsEdit from './routes/ad_network/campaigns/$campaign_id/edit';
import CampaignPaymentRequests from './routes/ad_network/campaigns/$campaign_id/payment_requests';
import CampaignPaymentRequestsEdit from './routes/ad_network/campaigns/$campaign_id/payment_requests/edit';
import CampaignPaymentRequestsNew from './routes/ad_network/campaigns/$campaign_id/payment_requests/new';
import CampaignPerformanceLayout from './routes/ad_network/campaigns/$campaign_id/performance/_layout';
import CampaignPerformanceDeclinedOrExpired from './routes/ad_network/campaigns/$campaign_id/performance/declined_or_expired';
import CampaignPerformanceDelivered from './routes/ad_network/campaigns/$campaign_id/performance/delivered';
import CampaignPerformancePending from './routes/ad_network/campaigns/$campaign_id/performance/pending';
import CampaignPerformanceUpcoming from './routes/ad_network/campaigns/$campaign_id/performance/upcoming';
import CampaignsActive from './routes/ad_network/campaigns/active';
import CampaignsAll from './routes/ad_network/campaigns/all';
import CampaignsPast from './routes/ad_network/campaigns/past';
import CampaignsUpcoming from './routes/ad_network/campaigns/upcoming';
import Disbursements from './routes/ad_network/disbursements';
import Industries from './routes/ad_network/industries';
import IndustriesShow from './routes/ad_network/industries/$industry_id';
import IndustriesAdvertisers from './routes/ad_network/industries/$industry_id/advertisers';
import IndustriesEdit from './routes/ad_network/industries/$industry_id/edit';
import IndustriesNew from './routes/ad_network/industries/new';
import OpportunitiesShow from './routes/ad_network/opportunities/$opportunity_id';
import OpportunitiesEdit from './routes/ad_network/opportunities/$opportunity_id/edit';
import Publications from './routes/ad_network/publications';
import Publication from './routes/ad_network/publications/$publication_id';
import PublicationOpportunities from './routes/ad_network/publications/$publication_id/opportunities';
import PublicationOpportunitiesNew from './routes/ad_network/publications/$publication_id/opportunities/new';
import PublicationProfile from './routes/ad_network/publications/$publication_id/profile';
import WorkspaceViews from './routes/ad_network/workspace_views';
import WorkspaceViewsShow from './routes/ad_network/workspace_views/$workspace_view_id';
import WorkspaceViewsEdit from './routes/ad_network/workspace_views/$workspace_view_id/edit';
import WorkspaceViewsNew from './routes/ad_network/workspace_views/new';

const App = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Navigate to="advertisers" replace />} />
        <Route path="advertisers" element={<Advertisers />} />
        <Route path="advertisers">
          <Route path="new" element={<AdvertisersNew />} />
          <Route path=":advertiser_id" element={<AdvertisersShow />} />
          <Route path=":advertiser_id">
            <Route path="edit" element={<AdvertisersEdit />} />
            <Route path="campaigns">
              <Route index element={<Navigate to="all" replace />} />
              <Route path="new" element={<AdvertisersCampaignsNew />} />
              <Route path="all" element={<AdvertisersCampaignsAll />} />
              <Route path="active" element={<AdvertisersCampaignsActive />} />
              <Route path="upcoming" element={<AdvertisersCampaignsUpcoming />} />
              <Route path="past" element={<AdvertisersCampaignsPast />} />
            </Route>
            <Route path="publisher_list">
              <Route index element={<AdvertisersPublisherList />} />
              <Route path=":publisher_list_item_id" element={<AdvertisersPublisherListItemShow />} />
              <Route path=":publisher_list_item_id">
                <Route path="edit" element={<AdvertisersPublisherListItemEdit />} />
              </Route>
              <Route path="new" element={<AdvertisersPublisherListNew />} />
            </Route>
          </Route>
        </Route>
        <Route path="advertisements">
          <Route path=":advertisement_id" element={<AdvertisementShow />} />
          <Route path=":advertisement_id">
            <Route path="edit" element={<AdvertisementEdit />} />
          </Route>
        </Route>
        <Route path="campaigns">
          <Route index element={<Navigate to="all" replace />} />
          <Route path="all" element={<CampaignsAll />} />
          <Route path="active" element={<CampaignsActive />} />
          <Route path="upcoming" element={<CampaignsUpcoming />} />
          <Route path="past" element={<CampaignsPast />} />
          <Route path=":campaign_id" element={<CampaignsShow />} />
          <Route path=":campaign_id">
            <Route path="edit" element={<CampaignsEdit />} />
            <Route path="advertisements" element={<CampaignAdvertisements />} />
            <Route path="campaign_opportunity_groups" element={<CampaignOpportunityGroups />} />
            <Route path="campaign_opportunity_groups">
              <Route path="new" element={<CampaignOpportunityGroupsNew />} />
            </Route>
            <Route path="advertisements">
              <Route path="new" element={<CampaignAdvertisementsNew />} />
            </Route>
            <Route path="performance" element={<CampaignPerformanceLayout />}>
              <Route index element={<Navigate to="delivered" replace />} />
              <Route path="delivered" element={<CampaignPerformanceDelivered />} />
              <Route path="upcoming" element={<CampaignPerformanceUpcoming />} />
              <Route path="pending" element={<CampaignPerformancePending />} />
              <Route path="declined_or_expired" element={<CampaignPerformanceDeclinedOrExpired />} />
            </Route>
            <Route path="payment_requests" element={<CampaignPaymentRequests />} />
            <Route path="payment_requests">
              <Route path="new" element={<CampaignPaymentRequestsNew />} />
              <Route path=":payment_request_id/edit" element={<CampaignPaymentRequestsEdit />} />
            </Route>
          </Route>
        </Route>
        <Route path="campaign_opportunity_groups">
          <Route path=":campaign_opportunity_group_id" element={<CampaignOpportunityGroup />} />
          <Route path=":campaign_opportunity_group_id">
            <Route path="provision" element={<CampaignOpportunityGroupProvision />} />
            <Route path="select" element={<CampaignOpportunityGroupSelect />} />
            <Route path="review" element={<CampaignOpportunityGroupReview />} />
          </Route>
        </Route>
        <Route path="disbursements" element={<Disbursements />} />
        <Route path="industries" element={<Industries />} />
        <Route path="industries">
          <Route path="new" element={<IndustriesNew />} />
          <Route path=":industry_id" element={<IndustriesShow />} />
          <Route path=":industry_id">
            <Route path="edit" element={<IndustriesEdit />} />
            <Route path="advertisers" element={<IndustriesAdvertisers />} />
          </Route>
        </Route>
        <Route path="opportunities">
          <Route path=":opportunity_id" element={<OpportunitiesShow />} />
          <Route path=":opportunity_id">
            <Route path="edit" element={<OpportunitiesEdit />} />
          </Route>
        </Route>
        <Route path="publications" element={<Publications />} />
        <Route path="publications">
          <Route path=":publication_id" element={<Publication />} />
          <Route path=":publication_id">
            <Route path="profile" element={<PublicationProfile />} />
            <Route path="opportunities" element={<PublicationOpportunities />} />
            <Route path="opportunities">
              <Route path="new" element={<PublicationOpportunitiesNew />} />
            </Route>
          </Route>
        </Route>
        <Route path="workspace_views" element={<WorkspaceViews />} />
        <Route path="workspace_views">
          <Route path="new" element={<WorkspaceViewsNew />} />
          <Route path=":workspace_view_id" element={<WorkspaceViewsShow />} />
          <Route path=":workspace_view_id">
            <Route path="edit" element={<WorkspaceViewsEdit />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
