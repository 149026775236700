import { useState } from 'react';

import SlideOver from '@/components/SlideOver';
import { Button } from '@/ui/Button';

const ExplainMetrics = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} variant="primary-inverse" size="sm">
        Explain metrics
      </Button>
      <SlideOver isOpen={isOpen} onClose={() => setIsOpen(false)} headerText="Explain Boost Metrics">
        <div>
          <div className="relative mt-border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Total subscribers</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  The total number of subscribers that you have sent via Boosts.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Verified subscribers</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Subscribers who have been accepted by our verification process. You only get paid for verified
                  subscribers — low quality and/or fake subscribers are not accepted.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Pending verification</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Subscribers who you haven&apos;t yet been paid for, currently going through our verification process.
                  This process takes between 10-17 days.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Pending earnings</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  The amount tied to those subscribers currently going through the verification process (not available
                  for withdrawl). Earnings associated with subscribers who fail verification will be removed from
                  pending earnings and returned to the boosted publication.
                </dd>
              </div>
              <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Total earnings</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  This is the amount you have already been paid for verified subscribers.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </SlideOver>
    </>
  );
};

export default ExplainMetrics;
