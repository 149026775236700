import { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';

import { useDebounce } from '../../../../../lib/hooks/useDebounce';

const useAnchorSettingsPanel = (editor: Editor, currentNode: Node | null, currentNodePos: number) => {
  const onChangeToggleIncludeInToc = useCallback(() => {
    if (currentNode) {
      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeMarkup(currentNodePos, undefined, {
            ...currentNode.attrs,
            anchorIncludeInToc: currentNode.attrs?.anchorIncludeInToc === false,
          });

          return true;
        })
        .run();
    }
  }, [editor, currentNode, currentNodePos]);

  const onChangeToggleAnchor = useCallback(() => {
    if (currentNode) {
      editor.chain().setNodeSelection(currentNodePos).toggleAnchor(currentNode).run();
    }
  }, [editor, currentNode, currentNodePos]);

  const updateAnchor = useCallback(
    ({ title = null, id = null }: { title?: string | null; id?: string | null }) => {
      const syncTitle = !title;
      const newTitle = syncTitle ? undefined : title;
      const newTitleData = title !== null ? { anchorTitle: newTitle, anchorTitleSync: syncTitle } : {};

      const syncId = !id;
      const newId = syncId ? undefined : id;
      const newIdData = id !== null ? { anchorId: newId, anchorIdSync: syncId } : {};

      const skipUpdate =
        ((syncTitle && currentNode?.attrs.anchorTitleSync) || currentNode?.attrs.anchorTitle === newTitle) &&
        ((syncId && currentNode?.attrs.anchorIdSync) || currentNode?.attrs.anchorId === newId);

      if (currentNode && !skipUpdate) {
        editor
          .chain()
          .command(({ tr }) => {
            tr.setNodeMarkup(currentNodePos, undefined, {
              ...currentNode.attrs,
              ...newTitleData,
              ...newIdData,
            });

            return true;
          })
          .run();
      }
    },
    [editor, currentNode, currentNodePos]
  );

  const [inputsData, setInputsData] = useState<{ title: string | null; id: string | null }>({
    title: currentNode?.attrs.anchorTitleSync ? null : currentNode?.attrs.anchorTitle,
    id: currentNode?.attrs.anchorIdSync ? null : currentNode?.attrs.anchorId,
  });

  const debouncedInputsData = useDebounce(inputsData, 250);

  // On debounced input changes, save to node
  useEffect(() => {
    updateAnchor(debouncedInputsData);
  }, [debouncedInputsData, updateAnchor]);

  // Anchor title: On input changes, save to state
  const onChangeAnchorTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputsData((currentInputsData) => ({
      ...currentInputsData,
      title: e.target.value,
    }));
  }, []);

  // Anchor id: On input changes, save to state
  const onChangeAnchorId = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputsData((currentInputsData) => ({
      ...currentInputsData,
      id: e.target.value,
    }));
  }, []);

  //
  const onClickResetAnchorSync = useCallback(() => {
    setInputsData({
      title: '',
      id: '',
    });
  }, []);

  return {
    onChangeToggleAnchor,
    onClickResetAnchorSync,
    onChangeToggleIncludeInToc,
    onChangeAnchorTitle,
    onChangeAnchorId,
    inputsData,
  };
};

export default useAnchorSettingsPanel;
