import { CalendarType, Event, PostPublishedEvent, PostScheduledEvent } from '@/hooks/useCalendar/types';

import PostPublishedEventDetails from './PostPublishedEventDetails';
import PostScheduledEventDetails from './PostScheduledEventDetails';
import { EventDetailsProps } from './types';

const isPublished = (event: Event): event is PostPublishedEvent =>
  event.calendar === CalendarType.POSTS && event.category === 'published';

const isScheduled = (event: Event): event is PostScheduledEvent =>
  event.calendar === CalendarType.POSTS && event.category === 'scheduled';

const PostEventDetails = ({ event }: EventDetailsProps) => {
  if (isPublished(event)) {
    return <PostPublishedEventDetails event={event} />;
  }

  if (isScheduled(event)) {
    return <PostScheduledEventDetails event={event} />;
  }

  return null;
};

export default PostEventDetails;
