import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';

export const DividerSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['divider']}>
      <SectionRenderer title="Style">
        <ColorSettings editor={editor} activeNodeResult={activeNodeResult} title="Color" property="backgroundColor" />
      </SectionRenderer>
    </RenderCondition>
  );
};
