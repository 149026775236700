import { PropsWithChildren } from 'react';

import { DeviceGate } from '@/ui/DeviceGate';

import usePageNavigator from '../../_hooks/usePageNavigator';
import useTabNavigator from '../../_hooks/useTabNavigator';
import { usePageContext } from '../Context/PageContext';

import PageContainer from './PageContainer';
import PageSelect from './PageSelect';
import PublishActions from './PublishActions';
import SettingsTabs from './SettingsTabs';
import SidePanel from './SidePanel';
import TopNav from './TopNav';

type Props = PropsWithChildren<{
  sidePanelChildren: React.ReactNode;
  topNavChildren?: React.ReactNode;
  onPublish?: () => void;
  isPublishing?: boolean;
  publishCtaText?: string;
  removePadding?: boolean;
  disablePublishActions?: boolean;
  showPageAndScreenActions?: boolean;
  showPublishAndPreviewActions?: boolean;
}>;

const Layout = ({
  children,
  sidePanelChildren,
  topNavChildren,
  onPublish = () => {},
  isPublishing = false,
  removePadding = false,
  disablePublishActions = false,
  showPageAndScreenActions = true,
  showPublishAndPreviewActions = true,
  publishCtaText,
}: Props) => {
  const { isHomePage } = usePageNavigator();
  const { isLayoutTab } = useTabNavigator();
  const { currentPublication } = usePageContext();
  const showPublishConfirmationModal = isHomePage && isLayoutTab && !currentPublication?.has_live_web_posts;

  const publishConfirmationModalText = (): string => {
    if (isHomePage && isLayoutTab) {
      return 'Your home page will only be visible once you publish a post.';
    }

    return '';
  };

  return (
    <DeviceGate reason="The feature is currently not supported on mobile devices">
      <div className="h-screen w-screen flex flex-col">
        <TopNav>
          {topNavChildren || (
            <>
              <div className="w-1/3 flex justify-start">
                <SettingsTabs />
              </div>
              <div className="w-1/3 flex justify-center space-x-3 items-center">
                {showPublishAndPreviewActions && <PageSelect disablePublishActions={disablePublishActions} />}
              </div>
              <div className="w-1/3 flex justify-end items-center">
                {showPageAndScreenActions && (
                  <PublishActions
                    onPublish={onPublish}
                    isPublishing={isPublishing}
                    disablePublishActions={disablePublishActions}
                    publishCtaText={publishCtaText}
                    showPublishConfirmationModal={showPublishConfirmationModal}
                    publishConfirmationModalText={publishConfirmationModalText()}
                  />
                )}
              </div>
            </>
          )}
        </TopNav>

        <div className="bg-white flex-grow flex overflow-auto">
          <SidePanel>{sidePanelChildren}</SidePanel>
          <PageContainer removePadding={removePadding}>{children}</PageContainer>
        </div>
      </div>
    </DeviceGate>
  );
};

export default Layout;
