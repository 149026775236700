import cx from "classnames";

interface Props {
  className?: string;
}

const TiktokIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={cx(className)}
    >
      <path
        d="M17.5 6.66667V13.3333C17.5 15.6345 15.6345 17.5 13.3333 17.5H6.66667C4.36548 17.5 2.5 15.6345 2.5 13.3333V6.66667C2.5 4.36548 4.36548 2.5 6.66667 2.5H13.3333C15.6345 2.5 17.5 4.36548 17.5 6.66667Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33337 10C6.95267 10 5.83337 11.1192 5.83337 12.5C5.83337 13.8807 6.95267 15 8.33337 15C9.71412 15 10.8334 13.8807 10.8334 12.5V5C11.1111 5.83333 12.1667 7.5 14.1667 7.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TiktokIcon;
