import { useEffect } from 'react';
import { focusManager } from 'react-query';

// By deafult react-query will refetch queries when the window is refocused.
// This can cause unwanted side-effects and rerenders for components that
// open dialogs, for example, when a file input dialog is open.
//
// This hook disables this refetch behavior for the lifecycle of the component
// that uses it.
const useDisableRefetchOnFocus = () => {
  useEffect(() => {
    focusManager.setFocused(false);
    return () => focusManager.setFocused(undefined);
  }, []);
};

export default useDisableRefetchOnFocus;
