import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';

import { BooleanSettings } from './BooleanSettings';

export const NewTabSettingsBlock = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeAttributes } = activeNodeResult;

  const currentValue = activeNodeAttributes?.open_in_new_tab;

  return (
    <Tooltip center="Custom external links must open in a new tab" delay={300}>
      <BooleanSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        defaultValue={currentValue === '_blank'}
        title="New Tab"
        property="open_in_new_tab"
      />
    </Tooltip>
  );
};
