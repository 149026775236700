import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { Navigate } from 'react-router-dom';

interface Props {
  redirectPath?: string;
  errorMessage?: string;
}

const NoPermission = ({ redirectPath, errorMessage }: Props) => {
  const toastShown = useRef(false);

  useEffect(() => {
    if (!toastShown.current) {
      toast.error(errorMessage || 'You do not have permission to access that page');
      toastShown.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastShown.current]);

  return <Navigate to={redirectPath || '/'} />;
};

export default NoPermission;
