import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Recommendation } from '../../../../interfaces/recommendation';

interface Props {
  recommendation: Recommendation;
}

const Item = ({ recommendation }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: recommendation.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div style={style} ref={setNodeRef} {...attributes} {...listeners} className="group">
      <div className="flex items-center space-x-2">
        <div className="flex w-8 h-8 justify-center items-center rounded group-hover:bg-gray-50">
          <svg viewBox="0 0 20 20" width="12" className="w-4 h-4 text-gray-400" fill="currentColor">
            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z" />
          </svg>
        </div>
        <div className="w-6 h-6 border rounded overflow-hidden">
          <figure className="aspect-w-1 aspect-h-1 relative h-full w-full">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={recommendation.recommended_publication.logo_url}
              alt={recommendation.recommended_publication.name}
            />
          </figure>
        </div>
        <p>{recommendation.recommended_publication.name}</p>
      </div>
    </div>
  );
};

export default Item;
