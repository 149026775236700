import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { FILE_ATTACHMENT_TYPE } from './constants';
import { getFileDropPlugin } from './fileDropPlugin';
import { IFileAttrs } from './types';
import { FileAttachmentView } from './views';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fileAttachment: {
      setFileAttachment: (attrs?: IFileAttrs | Record<string, any>) => ReturnType;
    };
  }
}

interface IFileAttachmentOptions {
  publicationId: string;
  userId: string;
}

export const FileAttachment = Node.create<IFileAttachmentOptions, {}>({
  name: FILE_ATTACHMENT_TYPE,

  group: 'block',

  draggable: true,

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({ 'data-id': attributes.id }),
      },
      thumbnailUrl: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-thumbnail-id'),
        renderHTML: (attributes) => ({ 'data-thumbnail-id': attributes.thumbnailId }),
      },
      title: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-title'),
        renderHTML: (attributes) => ({ 'data-title': attributes.title }),
      },
      description: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-description'),
        renderHTML: (attributes) => ({ 'data-description': attributes.description }),
      },
      src: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-src'),
        renderHTML: (attributes) => ({ 'data-src': attributes.src }),
      },
      size: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-size'),
        renderHTML: (attributes) => ({ 'data-size': attributes.size }),
      },
      type: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-type'),
        renderHTML: (attributes) => ({ 'data-type': attributes.type }),
      },
      name: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-name'),
        renderHTML: (attributes) => ({ 'data-name': attributes.name }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addCommands() {
    return {
      setFileAttachment:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs,
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(FileAttachmentView);
  },

  addProseMirrorPlugins() {
    const { publicationId, userId } = this.options;

    return [
      getFileDropPlugin({
        publicationId,
        userId,
      }),
    ];
  },
});

export default FileAttachment;
