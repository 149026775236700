import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '../../services/swarm';
import { useCurrentPublication } from '../usePublications';

interface Payload {
  home_page: {
    general_section?: string;
    main_section?: string;
    widgets_section?: string;
    posts_section?: string;
  };
}

interface Props {
  pageId: string;
  onSuccess: (response: any) => void;
}

const useUpdateHomePage = ({ onSuccess, pageId }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (payload: Payload) =>
      api.patch(`/home_pages/${pageId}`, {
        publication_id: currentPublication?.id,
        ...payload,
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'There was an issue updating this Page.');
      },
    }
  );
};

export default useUpdateHomePage;
