import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { CommentReport } from '../../interfaces/comment_report';
import { Pagination } from '../../interfaces/general';
import api from '../../services/swarm';

interface ApiResponse {
  comment_reports: CommentReport[];
  pagination: Pagination;
}

const useCommentReports = ({
  search,
  perPage = 10,
  all = false,
  postId,
  resolved_reports = false,
  enabled,
  commentId,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
  postId: string;
  resolved_reports?: boolean;
  enabled?: boolean;
  commentId?: string;
}) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchCommentReports = ({ pageParam = 1 }) =>
    api
      .get(`/posts/${postId}/comment_reports`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
          resolved_reports,
          comment_id: commentId,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['comment_reports', commentId, currentPublicationId, search, resolved_reports],
    fetchCommentReports,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      retry: false,
      keepPreviousData: true,
      enabled: enabled && !!postId,
      onError: (errPayload: any) => errPayload?.response?.data?.message || 'Something went wrong',
    }
  );
};

export default useCommentReports;
