import { ReactNode } from 'react';
import { IconComponent } from '@heroicons/react';
import cx from 'classnames';

import { Color } from '@/components/DesignTokens';
import { Typography } from '@/components/Typography';

interface Props {
  title: string;
  description: string;
  timePeriodSelector: ReactNode;
  kpiValue: number | string;
  kpiLabel: string;
  Icon: IconComponent;
  iconColor: string;
  textColor: Color;
}

const ChartHeader = ({
  title,
  description,
  timePeriodSelector,
  kpiValue,
  kpiLabel,
  Icon,
  iconColor,
  textColor,
}: Props) => (
  <>
    <div className="flex justify-between">
      <div className="flex flex-col gap-y-2">
        <Typography token="font-bold/text/base">{title}</Typography>
        <Typography token="font-normal/text/base">{description}</Typography>
      </div>
      {timePeriodSelector}
    </div>
    <div className="flex items-center gap-x-2">
      <span className="inline-flex items-center gap-x-2">
        <Icon className={cx('w-6 h-6', iconColor)} />
        <Typography token="font-bold/text/3xl" color={textColor} colorWeight="600">
          {kpiValue}
        </Typography>
      </span>
      <Typography token="font-normal/text/base" colorWeight="700">
        {kpiLabel}
      </Typography>
    </div>
  </>
);

export default ChartHeader;
