import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { AdNetworkOpportunityAdvertisement } from '@/interfaces/ad_network/publisher/opportunity';
import api from '@/services/swarm';

interface Props {
  id: string;
}

export default function useOpportunityAdvertisements({ id }: Props) {
  const [publicationId] = useCurrentPublicationState();

  const fetchAdvertisements = () =>
    api
      .get(`/ad_network/publisher/opportunities/${id}/advertisements`, {
        params: {
          publication_id: publicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<AdNetworkOpportunityAdvertisement[]>(
    [publicationId, 'ad_network', 'opportunities', id, 'advertisements'],
    fetchAdvertisements,
    {
      keepPreviousData: true,
    }
  );
}
