import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Props<T> {
  list: T[] | string[];
  addIdToListOfStrings?: boolean;
  zeroBasedIndex?: boolean;
}

interface ReturnProps<T> {
  sortableList: T[];
  setSortableList: Dispatch<SetStateAction<T[]>>;
  addIdToList: (list: string[]) => T[];
  reStringifyList: (list: T[]) => string[];
}

const useSortableList = <T>({ list, addIdToListOfStrings, zeroBasedIndex = true }: Props<T>): ReturnProps<T> => {
  const [sortableList, setSortableList] = useState<T[]>([]);
  const [mounted, setMounted] = useState(false);
  const sortableListLength = sortableList.length;
  const listLength = list.length;
  const hasList = list && list.length > 0;
  const lengthMismatch = sortableListLength !== listLength;

  const addIdToList = (listToMap: string[]) => {
    return listToMap.map((item, index) => {
      return { id: zeroBasedIndex ? index : index + 1, value: item } as T;
    });
  };

  const reStringifyList = (listToMap: any[]) => {
    return listToMap.map((item) => item?.value);
  };

  useEffect(() => {
    if (lengthMismatch) {
      if (addIdToListOfStrings) {
        setSortableList(addIdToList(list as string[]));
      } else {
        setSortableList(list as T[]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lengthMismatch]);

  useEffect(() => {
    if (hasList && !mounted) {
      if (addIdToListOfStrings) {
        setSortableList(addIdToList(list as string[]));
      } else {
        setSortableList(list as T[]);
      }
      setMounted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, setMounted]);

  return {
    sortableList,
    setSortableList,
    addIdToList,
    reStringifyList,
  };
};

export default useSortableList;
