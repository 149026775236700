import { useParams } from 'react-router-dom';
import cx from 'classnames';

import Text from '@/components/Text';
import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { usePostTargetsDisplay } from '@/hooks/usePostTargets';
import { PostTargetPlatform } from '@/interfaces/post_target';

const EmailSummaryText = ({
  postId,
  forEmail,
  targetSubscriptions,
}: {
  postId: string;
  forEmail: boolean;
  targetSubscriptions: number;
}) => {
  const audienceDisplay = usePostTargetsDisplay(postId, PostTargetPlatform.EMAIL);

  if (forEmail) {
    return (
      <>
        <p>
          <b>{targetSubscriptions}</b> {targetSubscriptions === 1 ? 'recipient' : 'recipients'}
        </p>
        <p className="pt-2">
          <span className="ml-1 mr-2">&#8627;</span>
          {audienceDisplay}
        </p>
      </>
    );
  }

  return (
    <>
      <b>Not</b> published via email
    </>
  );
};

const WebSummaryText = ({ postId, forWeb }: { postId: string; forWeb: boolean }) => {
  const audienceDisplay = usePostTargetsDisplay(postId, PostTargetPlatform.WEB);

  if (forWeb) {
    return (
      <p className="pt-2">
        <span className="ml-1 mr-2">&#8627;</span>
        {audienceDisplay}
      </p>
    );
  }

  return (
    <>
      <b>Not</b> published to the web
    </>
  );
};

const SummarySection = () => {
  const { postId } = useParams<'postId'>() as { postId: string };
  const { data: postDeliveryData, isLoading, isFetching } = usePostDeliveryData(postId);

  return (
    <>
      <Text size="md" type="bold" className="text-gray-600">
        Summary
      </Text>
      <div className="py-4 bg-white rounded-md border border-gray-200">
        <div className="px-4 space-y-1">
          <Text size="sm" type="bold" className={cx(isFetching && 'animate-pulse')}>
            Email
          </Text>
          {isLoading || !postDeliveryData ? (
            <div className="mt-1 text-sm font-medium truncate bg-gray-200 rounded animate-pulse text-transparent w-36 h-4" />
          ) : (
            <p className="text-sm">
              <EmailSummaryText
                postId={postId}
                forEmail={postDeliveryData.for_email}
                targetSubscriptions={postDeliveryData.target_subscriptions}
              />
            </p>
          )}
        </div>
        <hr className="my-4" />
        <div className="px-4 space-y-1">
          <Text size="sm" type="bold" className={cx(isFetching && 'animate-pulse')}>
            Web
          </Text>
          {isLoading || !postDeliveryData ? (
            <div className="mt-1 text-sm font-medium truncate bg-gray-200 rounded animate-pulse text-transparent w-36 h-4" />
          ) : (
            <p className="text-sm">
              <WebSummaryText postId={postId} forWeb={postDeliveryData.for_web} />
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default SummarySection;
